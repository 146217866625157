import React, { useState, useEffect } from 'react';
import { Button } from '../hoc/button'
import { Gap } from '../gap';
import styled from 'styled-components';
import TextBox from '../hoc/textBox';
import ExclamationSvg from '../svg/exclamation';
import RippleEffect from '../hoc/ripple-effect';
import Overlay from '../helper/overlay';


const ContextEditorWrapper = styled.div`

        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        box-sizing: border-box;
        padding: 15px 3rem;
        background: white;
        min-width: 60rem;
        max-width: 60rem;
        min-height: 28.143rem;
        max-height: 35.143rem;
        border-radius: 5px;
        box-shadow: 0 3px 6px 0 rgba(0,0,0,0.25);
        border: solid 5px rgb(234,234,234);
        background-color: rgb(255,255,255);
        overflow: auto;

        &::-webkit-scrollbar {
            background-color:red;
            width:5px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }

        

        h1{
            font-size: 1.5rem;
            text-align: center;
            font-weight: 600;
            margin: auto;
            margin-top: 0px;
            margin-bottom: 1.5rem;
            text-transform: capitalize;
        }

        .hint_block{
            margin-bottom: 1rem;
            p{
                margin: 0px;
                font-size: 1rem;
                color: #222;
            }
        }


        table{
            width: 100%;
            border: 1px solid #ccc;
            border-collapse: collapse;
            thead{
                td{
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: .9rem;
                    color: #fff;
                    background-color: #008ac7a1;
                }
            }
            td{
                border: 1px solid #cccccc96;
                padding: 0rem .5rem;
                font-size: 1rem;
                height: 2.4rem;
                font-size: 1rem;
                position: relative;
                p{
                    margin: 0px;
                }
            }

            input{
                height: 2.2rem;
                width: 100%;
                border: 0px;
                font-size: 1rem;
                padding: 0rem .5rem;
                box-sizing: border-box;
            }
        }
`;


export const ErrorBox = styled.div`
        position: absolute;
        width: 100%;
        min-height: 2rem;
        background: #fff;
        top: ${props => props.top ? props.top : '10%'};
        padding: .2rem;
        box-sizing: border-box;
        font-size: .6rem;
        color: #000;
        box-shadow: 3px 3px 4px #ccc;
        border: 1px solid red;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        border-radius: 3px;
        width: 121px;
        text-align: center;
        left: ${props => props.left ? props.left : '-7rem'};
        /* top: -22px; */
`;


const DublicateHints = props => {

    const { close, option, popupKey, data } = props;
    const { hintData, activeTable, func, } = data;

    const { cached_db_info_id, saveHints } = func;
    const [localHintsData, setLocalHintsData] = useState(undefined)
    const [error, setErrors] = useState(undefined)
    const [context, setContext] = useState(undefined)
    const [showError, setShowError] = useState(undefined)
    const [contextError, setContextError] = useState(undefined)


    useEffect(() => {
        setLocalHintsData(hintData)
        hintData && hintData.length > 0 && hintData.map((hint) => {
            if (hint.context && hint.is_editable) {
                setContext(hint.context)
            }
        })
    }, [hintData])

    const _onChange = (index, key, value) => {
        let cloneHintsData = localHintsData ? [...localHintsData] : [];
        cloneHintsData[index][key] = value;
        setLocalHintsData(cloneHintsData)
    }


    const save = () => {

        let _errors_obj = {};
        let context_error = undefined;
        let clone_local_hints = localHintsData ? JSON.parse(JSON.stringify(localHintsData)) : [];

        clone_local_hints && clone_local_hints.length > 0 && clone_local_hints.map((hint) => {
            hint.values = Array.isArray(hint.values) ? hint.values : hint.values.split(',')
        })

        for (let i = 0; i < clone_local_hints.length; i++) {

            let currentElement = clone_local_hints[i];
            let nextElement = clone_local_hints[i + 1];

            const current_element_hints = currentElement && currentElement.values || [];
            const next_element_hints = nextElement && nextElement.values || []

            if (currentElement.context === null || currentElement.context === '' && currentElement.is_editable) {
                _errors_obj[i] = {
                    msg: `please enter context in ${currentElement.table_name}`,
                    column_name: 'context'
                };
            }

            if ((currentElement.table_name) === (nextElement && nextElement.table_name) &&
                currentElement.is_editable) {
                let f = false;
                current_element_hints && current_element_hints.forEach((h) => {
                    if (next_element_hints.indexOf(h) > -1) {
                        f = true;
                    }
                })
                if (f) {
                    _errors_obj[i] = {
                        msg: `duplicate hint found in ${currentElement.table_name}`,
                        column_name: 'hints'
                    };
                }
            }
        }

        const filter_edited_hints = clone_local_hints && clone_local_hints.length > 0 && clone_local_hints.filter((hint) => {
            if (hint.is_editable) {
                return true;
            } else return false;
        })

        if (filter_edited_hints && filter_edited_hints.length > 0) {
            filter_edited_hints && filter_edited_hints.forEach((h) => {
                h.modified = true;
            })
        }

        filter_edited_hints && filter_edited_hints.length > 0 && filter_edited_hints.map((hint) => {
            let h_values = hint.values && Array.isArray(hint.values) ? hint.values : hint.value.split(',');
            let prev_values = hint.prev_hints ? hint.prev_hints : [];
            let combind_hints = [...prev_values, ...h_values];
            let uniqueHints = [...new Set(combind_hints)];
            hint.values = uniqueHints;

        })
        if (!_errors_obj || (_errors_obj && Object.keys(_errors_obj).length === 0)) {
            saveHints(filter_edited_hints, activeTable, cached_db_info_id)
            close(option, popupKey)
        }
        else {
            setErrors(_errors_obj)
            _errors_obj = undefined;
            setContextError(context_error)
            context_error = undefined;
        }
    }



    return (
        <ContextEditorWrapper>
            {showError && <Overlay onClick={() => {
                setShowError(undefined)
            }} />}
            <h1>Hints Already Found</h1>
            <table>
                <thead>
                    <tr>
                        <td>Hint Name</td>
                        <td>Table Name</td>
                        <td>Column Name</td>
                        <td style={{ width: '10rem' }}>Context</td>
                    </tr>

                </thead>

                <tbody>
                    {localHintsData && localHintsData.length > 0 && localHintsData.map((hint, i) => {

                        const { column_name, table_name, values, context, is_editable } = hint;

                        const row_error = error && error[i];
                        const error_column_name = row_error && row_error.column_name;
                        const error_msg = row_error && row_error.msg;

                        if (is_editable) {
                            return (
                                <tr>
                                    <td style={{ padding: '0px' }}>
                                        {row_error && error_column_name === 'hints' ? <div style={{ position: 'absolute', left: '-3rem' }}>
                                            <RippleEffect
                                                icon={<ExclamationSvg size="1rem" height={"1rem"} color="red" />}
                                                Click={() => {
                                                    setShowError(i + 1)
                                                }}
                                            />
                                        </div> : null}
                                        {showError && (showError === i + 1) ? <ErrorBox left={"-54px"} top={"-37px"}>{error_msg}</ErrorBox> : null}
                                        <input type="text" name="context" value={values} onChange={(e) => {
                                            _onChange(i, 'values', e.target.value)
                                        }} />

                                    </td>
                                    <td>{table_name}</td>
                                    <td>{column_name}</td>
                                    <td style={{ padding: '0px' }}>
                                        {row_error && error_column_name === 'context' ? <ErrorBox>{error_msg}</ErrorBox> : null}
                                        <input type="text" name="context" value={context} onChange={(e) => {
                                            _onChange(i, 'context', e.target.value)
                                        }} />

                                    </td>
                                </tr>
                            )
                        } else {
                            return (
                                <tr>
                                    <td style={{ background: '#eaeaea96' }}>{values && typeof values === 'string' ? values : values.join(',')}</td>
                                    <td style={{ background: '#eaeaea96' }}>{table_name}</td>
                                    <td style={{ background: '#eaeaea96' }}>{column_name}</td>
                                    <td style={{ background: '#eaeaea96' }}>{context}</td>
                                </tr>
                            )
                        }

                    })}
                </tbody>
            </table>


            {/* <div style={{ width: '50%', marginTop: '1rem' }}>
               
                {contextError && <span style={{
                    color: ' red',
                    display: 'inline-block',
                    marginTop: '.5rem',
                    fontSize: '.8rem'

                }}>{contextError.msg ? contextError.msg : null}</span>}
            </div> */}

            <Gap h="25px" />
            <div style={{
                paddingTop: '25px',
                marginTop: 'auto',
                display: 'block',
                width: '100%'
            }}>
                <Button
                    primary={true}
                    mR="16px"
                    onClick={save}
                >
                    Save
                </Button>
                <Button
                    width='6rem'
                    bgColor={'#e6e6e6'}
                    style={{
                        marginBottom: 0
                    }}
                    onClick={() => {
                        close(option, popupKey)
                    }}
                >Cancel</Button>
            </div>
        </ContextEditorWrapper>
    );
};

export default DublicateHints;

