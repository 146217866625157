import React from 'react';

import { Wrapper } from './righter.styled';
import Overlay from '../helper/overlay';
import { hideRighter } from '../../utils';

const Righter = props => {

    const { showFull } = props;

    return (
        <React.Fragment>
            {showFull && <Overlay
                onClick={hideRighter}
                zIndex="10"
                bgColor="rgba(0, 0, 0, 0.4)" />}

            <Wrapper showFull={showFull}>
                {props.element ? props.element : <div></div>}
            </Wrapper>
        </React.Fragment>

    );
};

export default Righter;