
import styled from 'styled-components';


export const Wrapper = styled.div`

    width: 100%;;
    /* display: flex; */
    /* justify-content: space-between; */
    /* flex-direction: row; */


    .action_btn{
        width: 1.4rem;
        height: 1.4rem;
        /* border: 1px solid #ccc; */
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: #e2e2e2;
        border-radius: 4px;;
    }

     .add_meeting{
        display: inline;
        /* max-width: 10rem; */
        height: 2rem;
        padding: 5px 10px;
        box-sizing: border-box;
        /* border: 1px solid #ccc; */
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #0084c7;
        color: #fff;
        font-size: .9rem;
        font-weight: 600;
        cursor: pointer;
        border-radius: 3px;
        &:hover{
            opacity: .8;
        }
    }

`;



export const PlannedMettingWrapper = styled.div`
  
   width: 100%;
   overflow: auto;
   max-height: 40vh;
   min-height: 40vh;



    table{

        border: 1px solid #000;
        width: 100%;
        border-collapse: collapse;

        thead{
            td{
                color: rgb(255, 255, 255);
                font-weight: 600;
                background-color: rgb(0, 138, 199);
                border-bottom: 1px solid rgb(204, 204, 204);
                padding: 4px 10px;
                height: 2.1rem;

            }
        }

        tbody{
            td{
                padding: 8px;
                height: 2.1rem;
                text-align: left;
                border-right: 1px solid rgb(204, 204, 204);
                text-transform: capitalize;
            }
        }
        td{
            border: 1px solid #000;
            font-size: .8rem;
            box-sizing: border-box;

            input{
                width: 100%;
                box-sizing: border-box;
            }
        }
    }
`;




export const ActualMeeting = styled.div`
  
   width: 100%;
    /* max-height: 40vh; */
   position: relative;
   display: flex;
   flex-direction: column;
    /* overflow: auto; */



   .add_meeting_form{
    width: 60%;
    margin: auto
   }


    table{

        border: 1px solid #000;
        width: 100%;
        border-collapse: collapse;

        thead{
            td{
                color: rgb(255, 255, 255);
                font-weight: 600;
                background-color: rgb(0, 138, 199);
                padding: 4px 10px;
                height: 1.8rem;
                font-size: .8rem;

            }
        }

        tbody{
            td{
                padding: 8px;
                /* height: 2.1rem; */
                font-size: .8rem;
                text-align: left;
                text-transform: capitalize;
                color: #222;
            }
        }
        td{
                border: 1px solid #ccc;

        }
    }

    .add_btn{
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        margin: auto;
    }

    .__row{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;
    }


    .choose_meeting{
        text-align: center;
        font-weight: 600;
        font-size: 1rem;
        margin-top: 5rem;
        color: #222;
    }

    .back_btn{
        
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 1.7rem;
        border-radius: 3px;
        margin-bottom: 1rem;
        box-shadow: 1px 3px 8px -1px #00000057;
        cursor: pointer;    
        font-size: .9rem;
        font-weight: 500;
        color: #222;
        &:hover{
            background-color: #e2e2e23b;
        }

        .back_icon{
            display: inline-block;
            transform: rotate(92deg);
            margin-right: 10px
        }
    }
   
`;