import React, { Component } from 'react';
import ReactSpeedometer from "react-d3-speedometer";
import styled from 'styled-components';


const LableData = styled.div`
   display:flex !important;
   justify-content: space-between !important;
   align-items: center !important;
   padding: 0px 10px;
   margin: auto;
   bottom: 0px;
   right: 0px;
   left: 0px;
   font-size: 1rem;
   font-style: normal;
   line-height: 1.3rem;
   text-align: center;
   color: #1a1a1a;
   height: 50px;
`;

class Gauge extends Component {

    constructor(props) {
        super(props);

        this.state = {
            segmentColors: ["#63b6d6", "#0c5672"],
            label: undefined,
            numberOfSegments: undefined,
            totalValue: undefined,
            value: undefined
        }
    }

    static getDerivedStateFromProps(nextProps, currentState) {
        if (nextProps.chartId) {
            const { values, gaugeChartSettings } = nextProps;
            let formulaObject = nextProps.formula;

            let formula_key = Object.keys(formulaObject)[0];
            let formula = formulaObject[formula_key].formula;

            let startIndex = 97;
            let totalValue = 0;
            let tempValue = 0;

            const segmentColors = gaugeChartSettings && gaugeChartSettings.segments && gaugeChartSettings.segments.map(data => {
                return data.color
            })

            values.forELableDataach((data, index) => {
                const key = Object.keys(data)[0];

                const value = data[key];
                const variable = String.fromCharCode(startIndex);
                startIndex++;
                if (index === 0) { tempValue += value }

                totalValue += parseFloat(value);

                formula = formula.replace(new RegExp(variable, 'g'), parseFloat(value));
            })


            return {
                numberOfSegments: gaugeChartSettings && gaugeChartSettings.segments && gaugeChartSettings.segments.length,
                segmentColors,
                label: parseFloat((eval(formula) * 100)).toFixed(2),
                totalValue,
                value: tempValue
            }

        }
    }

    render() {

        const { widthOfChart, heightOfChart } = this.props

        return (

            <div className="speedo_meter">

                <ReactSpeedometer
                    needleHeightRatio={.9}
                    maxSegmentLabels={0}
                    value={this.state.value ? this.state.value : 1}
                    maxValue={this.state.totalValue ? this.state.totalValue : 2}
                    segments={this.state.numberOfSegments ? this.state.numberOfSegments : 2}
                    segmentColors={this.state.segmentColors}
                    needleTransitionDuration={6000}
                    needleColor="#334a53"
                    needleWidthRatio={2}
                    needleTransition="easeElastic"
                    currentValueText={this.state.label ? this.state.label.toString() + '%' : "#{value}"}
                    currentValuePlaceholderStyle="#{value}"
                    labelFontSize="10px"
                    valueTextFontSize="20px"
                    ringWidth={15}
                    width={(widthOfChart * .60) > (heightOfChart - 74) ? ((heightOfChart - 74) / .60) : widthOfChart}
                    height={(widthOfChart * .60) > (heightOfChart - 74) ? ((heightOfChart - 74) / .60) * .60 : widthOfChart * .60}
                    forceRender={true}
                    paddingVertical={0}
                />

                {/* <LableData style={{

                }}>
                    <div>
                        MTD (#)<br />
                        <strong>238,015</strong>
                    </div>

                    <div>
                        LTD (#)<br />
                        <strong>28,015</strong>
                    </div>
                </LableData> */}
            </div>
        );
    }
}

export default Gauge;