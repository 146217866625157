import React from 'react';

const Filter = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}

            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 419.6 480"
            space="preserve">
                <path d="M73.4,113.2V21.7C73.4,9.7,63.7,0,51.7,0l0,0C39.8,0,30,9.8,30,21.7v91.4c0,6.8-3.3,13.1-8.9,17.2C8.3,139.7,0,154.9,0,172
	s8.3,32.2,21.1,41.7c5.5,4.1,8.9,10.4,8.9,17.2v227.3c0,12,9.7,21.7,21.7,21.7l0,0c12,0,21.7-9.7,21.7-21.7V230.9
	c0-6.8,3.3-13.1,8.9-17.2c12.8-9.4,21.1-24.6,21.1-41.7s-8.3-32.2-21.1-41.7C76.8,126.4,73.4,120.1,73.4,113.2z M73.4,202
	c-6.1,4.5-13.6,7.1-21.7,7.1S36.1,206.5,30,202c-9.3-6.7-15.4-17.6-15.4-30s6.1-23.2,15.4-30c6.1-4.5,13.6-7.1,21.7-7.1
	s15.6,2.6,21.7,7.1c9.3,6.7,15.4,17.6,15.4,30C88.7,184.4,82.7,195.3,73.4,202z"/>
<path d="M231.5,225.6V21.7c0-12-9.7-21.7-21.7-21.7l0,0c-12,0-21.7,9.7-21.7,21.7v203.9c0,6.8-3.3,13.1-8.9,17.2
	c-12.8,9.4-21.1,24.6-21.1,41.7s8.3,32.2,21.1,41.7c5.5,4.1,8.9,10.4,8.9,17.2v114.9c0,12,9.7,21.7,21.7,21.7l0,0
	c12,0,21.7-9.7,21.7-21.7V343.4c0-6.8,3.3-13.1,8.9-17.2c12.8-9.4,21.1-24.6,21.1-41.7s-8.3-32.2-21.1-41.7
	C234.9,238.8,231.5,232.4,231.5,225.6z M231.5,314.5c-6.1,4.5-13.6,7.1-21.7,7.1s-15.6-2.6-21.7-7.1c-9.3-6.7-15.4-17.6-15.4-30
	s6.1-23.3,15.4-30c6.1-4.5,13.6-7.1,21.7-7.1s15.6,2.6,21.7,7.1c9.3,6.7,15.4,17.6,15.4,30S240.8,307.7,231.5,314.5z"/>
<path d="M389.6,152.6V21.7c0-12-9.7-21.7-21.7-21.7l0,0c-12,0-21.7,9.7-21.7,21.7v130.7c0,6.8-3.3,13.1-8.9,17.2
	c-12.8,9.4-21.1,24.6-21.1,41.7s8.3,32.2,21.1,41.7c5.5,4.1,8.9,10.4,8.9,17.2v188c0,12,9.7,21.7,21.7,21.7l0,0
	c12,0,21.7-9.7,21.7-21.7v-188c0-6.8,3.3-13.1,8.9-17.2c12.8-9.4,21.1-24.6,21.1-41.7s-8.3-32.2-21.1-41.7
	C393,165.7,389.6,159.4,389.6,152.6z M389.6,241.4c-6.1,4.5-13.6,7.1-21.7,7.1s-15.6-2.6-21.7-7.1c-9.3-6.7-15.4-17.6-15.4-30
	s6.1-23.3,15.4-30c6.1-4.5,13.6-7.1,21.7-7.1s15.6,2.6,21.7,7.1c9.3,6.7,15.4,17.6,15.4,30C405,223.8,398.9,234.7,389.6,241.4z"/>
        </svg>

    )

}


export default Filter;