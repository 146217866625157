export const MENU_GROUP_INIT = 'modules/menuGroup/init';

export const MENU_GROUP_DATA_REQUESTED = 'modules/menuGroup/request';
export const MENU_GROUP_DATA_SUCCESS = 'modules/menuGroup/success';

export const SAVE_MENU_SUCCESS = 'modules/menuGroup/save/success';
export const GET_MENU_GROUPS_SUCCESS = 'modules/menuGroup/get_menu_group/success';


// =================v1==========

export const GET_REPORT_MENUS_V1 = 'modules/menuGroup/getReportMenus_v1'