import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 18rem;
    height: 18rem;
    box-sizing: border-box;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);

    min-height: 300px;
    min-width: 300px;
    background: white;
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;


export const UpperWrapper = styled.div`
    width: 100%;
    height: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    position: relative;
`;

export const PictureWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background:
`;

export const LowerWrapper = styled.div`
    width: 100%;
    height: 15%;
    border-top: 1px solid #e1e1e1;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const LowerContentWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
`;
