import React from "react";
const TowerIcon = (props) => {
    return (
        <svg
            width={props.size ? props.size : "2.2rem"}
            height={props.height ? props.height : "2.2rem"}
            fill={props.color ? props.color : "#000"}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1620 2048"
            preserveAspectRatio="xMidYMid meet"
        >
            <path transform="translate(801)" d="m0 0h19l14 7 10 9 6 12 1 3v630h463l1 1v136h40l13 4 11 8 6 8 4 9 1 5v13l-4 12-6 8-7 7-8 4-7 2-10 1h-33v47h39l12 3 10 6 7 8 5 10 2 7v13l-3 10-6 9-8 8-8 4-7 2-8 1h-35l1 279 304 1v758l-1 1-1610 1-6-1-1-5v-753l1-1 302-1 1-279h-45l-11-3-10-6-8-9-5-10-2-13 2-11 4-10 9-10 7-5 9-3 5-1h45v-47h-43l-13-3-8-5-6-5-6-9-4-10-1-9 2-12 5-10 9-10 8-5 11-3h46v-136l1-1h463v-630l5-12 9-10 10-6z" fill="#D6E6F7" />
            <path transform="translate(801)" d="m0 0h19l14 7 10 9 6 12 1 3v630h463l1 1v136h40l13 4 11 8 6 8 4 9 1 5v13l-4 12-6 8-7 7-8 4-7 2-10 1h-33v47h39l12 3 10 6 7 8 5 10 2 7v13l-3 10-6 9-8 8-8 4-7 2-8 1h-35l1 279 304 1v758l-1 1-1610 1-6-1-1-5v-753l1-1 302-1 1-279h-45l-11-3-10-6-8-9-5-10-2-13 2-11 4-10 9-10 7-5 9-3 5-1h45v-47h-43l-13-3-8-5-6-5-6-9-4-10-1-9 2-12 5-10 9-10 8-5 11-3h46v-136l1-1h463v-630l5-12 9-10 10-6zm-751 1293-39 1v744h1599l1-3v-740l-1-1-82-1h-208l-93 1h-862l-120-1z" fill="#797679" />
            <path transform="translate(330,1006)" d="m0 0h949l27 1 1 4v272l-1 5h-992v-156l1-125z" fill="#D6E6F7" />
            <path transform="translate(315,668)" d="m0 0h955l36 1 1 1-1 128-36 1-88 1h-797l-70-1-1-130z" fill="#D6E6F7" />
            <path transform="translate(774,1457)" d="m0 0h62l90 1 10 2 8 6 6 8 2 10 1 40v283l-1 43-3 10-4 6-7 6-4 2-6 1h-184l-1-1v-415l1-1z" fill="#FDFDFD" />
            <path transform="translate(292,1457)" d="m0 0h62l90 1 10 2 8 6 6 9 1 3 1 11 1 72v208l-1 74-1 12-5 10-7 6-6 3-5 1h-185l-1-172v-78l1-167z" fill="#FDFDFD" />
            <path transform="translate(1257,1457)" d="m0 0h60l91 1 10 2 6 4 5 5 4 8 1 66v260l-1 53-4 8-7 7-6 3-5 1h-185l-1-64v-266l1-87z" fill="#FDFDFD" />
            <path transform="translate(312,806)" d="m0 0h925l118 1 10 3 8 6 6 10 2 6v13l-4 9-6 8-9 6-8 2-103 1h-903l-86-1-8-2-9-6-6-7-4-8-1-5v-7l3-10 6-8 7-6 11-4z" fill="#C2E7FD" />
            <path transform="translate(364,934)" d="m0 0h878l113 1 9 3 8 6 6 8 3 7v14l-3 7-6 9-8 6-5 2-6 1-82 1h-945l-66-1-10-4-8-7-6-9-2-9 1-11 4-8 8-9 9-5 6-1z" fill="#C2E7FD" />
            <path transform="translate(413,877)" d="m0 0h775l118 2v47l-1 1-112 1h-783l-94-1-1-1v-46l1-1 31-1z" fill="#D6E6F7" />
            <path transform="translate(805,9)" d="m0 0h13l9 4 8 7 6 8 1 4 1 22v608h-65v-582l1-47 4-9 7-8 8-5z" fill="#C2E7FD" />
            <path transform="translate(3,1287)" d="m0 0 26 1v2h-2v2l23 1v1h-39v744l1599-1v-742l-82-1h-289v-1l10-1h341v-2l-10-1v-1l10-1h30v758l-1 1-1610 1-6-1-1-5v-753z" fill="#7D7B7E" />
            <path transform="translate(1175,1458)" d="m0 0h38l1 1v415l-1 1h-38l-9-3-8-6-5-8-1-4-1-81v-219l1-76 4-8 8-8 6-3z" fill="#FCFDFD" />
            <path transform="translate(211,1458)" d="m0 0h37l1 1v415l-1 1h-37l-10-3-10-9-4-10-1-117v-158l1-99 5-10 9-8 4-2z" fill="#FCFDFD" />
            <path transform="translate(693,1458)" d="m0 0h37l1 1 1 116v173l-1 126-1 1h-37l-10-3-8-7-5-10-1-5v-366l2-10 7-9 7-5z" fill="#FDFDFD" />
            <path transform="translate(1171,1446)" d="m0 0h244l10 3 9 6 8 10 3 6 1 5v380l-3 9-6 9-9 8-10 4-10 1h-234l-10-2-10-6-6-5-6-10-2-5-1-10v-367l2-11 5-10 5-6 11-7zm86 11-31 1-1 87v266l1 64h185l9-3 8-7 4-6 1-3 1-53v-260l-1-66-4-8-5-5-6-4-10-2-91-1zm-82 1-9 3-8 7-5 7-1 3-1 76v219l1 81 3 8 6 7 9 5 5 1h38l1-1v-415l-1-1z" fill="#7A777A" />
            <path transform="translate(688,1446)" d="m0 0h245l10 3 8 6 8 9 4 9 1 5v376l-3 11-8 11-11 8-7 2-9 1h-235l-12-3-10-7-7-8-4-9-1-6v-377l4-11 6-9 9-7 7-3zm86 11-30 1-1 1v415l1 1h184l8-2 8-6 4-5 3-7 1-5 1-43v-283l-1-40-2-10-6-8-8-6-10-2-90-1zm-81 1-10 3-8 7-5 9-1 7v366l3 10 6 8 7 5 8 2h37l1-1 1-126v-173l-1-116-1-1z" fill="#7B787B" />
            <path transform="translate(206,1446)" d="m0 0h244l10 3 9 6 7 8 4 8 2 10v368l-2 12-5 10-9 9-9 5-14 2h-234l-12-3-10-7-7-8-4-9-1-4-1-16v-346l1-18 4-11 7-9 8-6 7-3zm86 11-31 1-1 167v78l1 172h185l9-3 8-6 5-8 1-3 1-12 1-74v-208l-1-72-1-11-5-10-8-7-5-2-7-1-90-1zm-81 1-8 2-8 6-6 8-2 5-1 99v158l1 117 4 10 7 7 8 4 5 1h37l1-1v-415l-1-1z" fill="#7B787B" />
            <path transform="translate(307,661)" d="m0 0h17v1h-16l1 72 1-4v-64l3-1v2h423v1l-421 1v130h70v1l-33 1 15 2 39 1 809 1v1l-903 1-51 1-11 4-8 7-5 8-2 8v7l3 10 7 9 9 6 8 2 86 1 897 1v1l-859 1 1 2 80 1v1l-120 2-31 1v46l94 1v1l-63 1 2 1 24 2 861 1v1l-870 1-102 1-8 2-9 6-7 9-2 5-1 11 2 9 6 9 8 7 9 3 66 1 908 1v1l-800 1 3 2 11 1h713v2l-846 1v282l-9 1-13-1v-1l12-2 1-279h-45l-11-3-10-6-8-9-5-10-2-13 2-11 4-10 9-10 7-5 9-3 5-1h45v-47h-43l-13-3-8-5-6-5-6-9-4-10-1-9 2-12 5-10 9-10 8-5 11-3h46v-136z" fill="#7F7C7F" />
            <path transform="translate(801)" d="m0 0h19l14 7 10 9 6 12 1 3v630h421v1l-405 1v2l-5 1h-26l-25-1-1-3h33l-1-608-1-22-3-7-7-8-7-5-6-2h-13l-11 4-8 7-4 6-2 6-1 47-1 536h-1l-1-423-2 6-2-1-1 454h-1v-621l5-12 9-10 10-6z" fill="#7B787B" />
            <path transform="translate(776,188)" d="m0 0h1l1 428v46h32l2 2 26 1h29l-7-2v-1h361l33 2-3 3-357 1-17 1h-138l-3-1-423-1v-2l-2 2v63l-1 4-1 46h-1v-118l16-1h446l1-471h1l1 8z" fill="#7A777A" />
            <path transform="translate(10,1287)" d="m0 0h292l4 2 9-1 1007 1-7-1v-1h275l-3 2 5 1v1l5 1v1h-348l-16 2h-868l-339-2 1-3h2v-2h-19z" fill="#645F62" />
            <path transform="translate(351,800)" d="m0 0h888l1 2-23 2-27 1h-784l-39-1-15-2z" fill="#1D191E" />
            <path transform="translate(386,872)" d="m0 0h832l3 2-13 1-4 1-65 1h-672l-80-1-2-3z" fill="#241F23" />
            <path transform="translate(423 1e3)" d="m0 0h768v1l-16 1-2 2-13 1h-712l-11-1-3-2-11-1z" fill="#453A3A" />
            <path transform="translate(358,1289)" d="m0 0h916v1l-53 2-94 1h-646l-85-1-28-1-10-1z" fill="#1D191E" />
            <path transform="translate(452,929)" d="m0 0h709l1 3-35 1h-647l-40-1v-1l12-1z" fill="#1D191E" />
            <path transform="translate(776,188)" d="m0 0h1v429h-1l-2-10-1 6v39h-2v-462h1l1 8z" fill="#615B5F" />
            <path transform="translate(335,662)" d="m0 0h377l28 1 17 2-1 3-402-1v-1l-16-2z" fill="#433839" />
            <path transform="translate(10,1287)" d="m0 0h292l4 2 9-1h28l25 2 28 1 85 1v1h-214l-186-1-21 1h-34l1-3h2v-2h-19z" fill="#605A5E" />
            <path transform="translate(943,662)" d="m0 0h278l33 2-3 3h-362v-1l14-1 2-2z" fill="#433939" />
            <path transform="translate(334,928)" d="m0 0h879l4 1-1 3-23 1h-66v-1l33-1v-1h-706v1l26 1v1h-107l-24-2-2-1-13-1z" fill="#615B5F" />
            <path transform="translate(308,1097)" d="m0 0 3 1 1 21 1 4v165l-4-2v2l-4-1 2-1z" fill="#5F585C" />
            <path transform="translate(712,662)" d="m0 0h45l12 1v2h8v1h18l10-1h62l-7-2v-1h83v1l-38 1 5 1v1l-16 1v1l-17 1h-138v-1l17-1 1-2-3 1-14-2-28-1z" fill="#645F62" />
            <path transform="translate(97,1289)" d="m0 0h130l78 1 5 1-4 2h-39l-187-1-8-1v-1z" fill="#453A3A" />
            <path transform="translate(324,661)" d="m0 0h66v1l-52 1 22 2v1l-6 1h-41v-2l-2 2v63l-1 4-1 46h-1v-118z" fill="#605A5E" />
            <path transform="translate(479,1597)" d="m0 0h1v150h-1l-2-19-1-18v-82l1-17z" fill="#615B5F" />
            <path transform="translate(1391,1289)" d="m0 0h122l19 1v1l-27 1h-123l-28-1v-1z" fill="#453A3A" />
            <path transform="translate(961,1604)" d="m0 0h1v138h-1l-2-25-1-45v-16l1-34z" fill="#615B5F" />
            <path transform="translate(372 1e3)" d="m0 0h51l11 1 3 2 11 1v1h-67l-7-2z" fill="#615B5F" />
            <path transform="translate(1274,1289)" d="m0 0h32l8 1v1l-33 1-3 1h-151v-1l94-1z" fill="#453A3A" />
            <path transform="translate(323,1289)" d="m0 0h35l10 1 28 1 85 1v1h-154v-1l5-1-9-1z" fill="#453A3A" />
            <path transform="translate(1221,662)" d="m0 0h51l22 1 4 1 11 1-1 3-4-1h-53l-6-1-4-2-20-1z" fill="#615B5F" />
            <path transform="translate(351,800)" d="m0 0h79v1l-26 1 4 1 72 1v1h-74l-39-1-15-2z" fill="#453A3A" />
            <path transform="translate(388,929)" d="m0 0h64l2 2 26 1v1h-69l-23-1z" fill="#453A3A" />
            <path transform="translate(1191 1e3)" d="m0 0h44l-4 2-7 2-19 1h-43v-1l11-1 2-2z" fill="#615B5F" />
            <path transform="translate(1161,929)" d="m0 0h56l-1 3-23 1h-66v-1l33-1z" fill="#453A3A" />
            <path transform="translate(712,662)" d="m0 0h45l12 1v2h8v1l66 1v1h-86v-3l-3 1-14-2-28-1z" fill="#5A5255" />
            <path transform="translate(1175,800)" d="m0 0h64l1 2-23 2-27 1h-57v-1l50-1 2-1-10-1z" fill="#453A3A" />
            <path transform="translate(386,872)" d="m0 0h48v1l7 1-1 2h-53l-2-3z" fill="#453A3A" />
            <path transform="translate(1235,928)" d="m0 0h35v1l-22 2-13 2h-42v-1l23-1 1-2z" fill="#615B5F" />
            <path transform="translate(367,872)" d="m0 0h19l1 3 80 1v1h-85l-16-1-1-3z" fill="#615B5F" />
            <path transform="translate(1167,872)" d="m0 0h51l3 2-13 1-4 1h-37l-2-2z" fill="#453A3A" />
            <path transform="translate(315,1288)" d="m0 0h28v1l-11 1 1 2-6 1h-21l2-3z" fill="#5E585C" />
            <path transform="translate(1306,1289)" d="m0 0h14l1 2h-2v2h-41l3-2 25-1z" fill="#615B5F" />
            <path transform="translate(1218,872)" d="m0 0h28l-3 2-18 2h-21l4-2 10-1z" fill="#615B5F" />
            <path transform="translate(1239,800)" d="m0 0h25v1l-29 3h-18v-1l22-2z" fill="#615B5F" />
            <path transform="translate(334,800)" d="m0 0h17l1 1 15 2v1h-18l-15-3z" fill="#615B5F" />
        </svg>
    );
};
export default TowerIcon;





