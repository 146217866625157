import styled from 'styled-components';
import { colors } from '../../theme/colors';

export const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    margin: auto;
    position: relative;
    padding-bottom: 40px;
    .entitys_list{
        list-style: none;
        margin: 0px;
        padding: 0px;
        max-Height: 28rem;
        overflow: auto;
            
       &::-webkit-scrollbar {
            background-color:red;
            width:5px
        }
        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }
        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }
        li{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-bottom: 1px solid #ccc;
            padding: 12px 4px;
            cursor: pointer;
        }
    }
`;


export const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    padding-bottom: 20px;
    box-sizing: border-box;  
    h2{
        font-size: 1.714rem;
        line-height: 1.29;
        text-align: left;
        margin: 0px;
        position: relative;
        width: 100%;
        font-weight: normal;
        span{
            padding-right: 30px;
            box-sizing: border-box;
            position: relative;;
            z-index: 1;
            background: #fff;
        }
        &::after{
            content: "";
            width: 100%;
            height: 1px;
            background:#eaeaea;
            position: absolute;
            right: 0px;
            top: 50%;
        }
    }
`;

export const EditorInnerWrapper = styled.div`
    width: 100%;
    padding: 25px;
    box-sizing: border-box;
    background: #fff;
    box-sizing: border-box;
    overflow: auto;
    height:${props => props.scroll ? " calc(100vh - 180px)" : '100%'};
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 165px);
    box-shadow: 0px 2px 6px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);

`;




export const DisplayFiledWrapper = styled.div`
    width: 56.429rem;
    max-height: 31.214rem;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
    border: solid 5px rgb(234, 234, 234);
    background-color: rgb(255, 255, 255);
    padding: 1.643rem 2.429rem;
    overflow: auto;

    .sort_title{
        font-size:1.714rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.214rem;
        letter-spacing: normal;
        text-align: left;
        /* text-transform: uppercase; */
        margin-top: 13px;

        &:nth-child(1){
            margin-top: 0px;
        }
}
`;


export const DisplayColumnData = styled.div`
    width: 100%;
    .from_group{
        width: 100%;
        margin-bottom: 2rem;
    }
`;

export const RankingWrapper = styled.div`  
    width: 100%;
    margin: auto;
`;

export const CardWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
`;

export const TableWrapper = styled.div`
    overflow-y: auto;
    width: 100%;
    height: 80vh;

    &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }

`;


export const CompareWrapper = styled.div`
    position: absolute;
    bottom: ${props => props.show ? '0' : '-5rem'};
    width: 100%;
    height: 4rem;
    background: white;
    left: 0;
    transition: all .5s linear;

    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 6px 0 rgb(0, 0, 0);
`;


export const CompareDetails = styled.div`
    width: 70%;

    display: flex;
    justify-content: space-between;
    align-items: center;
`;


export const CompareUserWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const CompareButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    background: ${colors.primary};
    align-items: center;
    padding: 9px 20px;
    color: white;
    border-radius: 21px;
    font-weight: 400;
    box-shadow: 2px 3px 4px #0000009e;

    &:hover{
        opacity: 0.8;
        cursor: pointer;
    }

`;

export const UserWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    padding: .2rem .8rem;
    box-sizing: border-box;
    min-width: 200px;
    position: relative;
`;

export const CloseWrapper = styled.div`
    position: absolute;
    top:-2px;
    right: -8px;
    background: #c1c1c2;
    border-radius: 50%;
    width: 1.2rem;
    height: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover{

        background-color: #318ac7;
        cursor: pointer;
    }
`;