import styled from 'styled-components';


export const EtlDetailsWrapper = styled.div`
	width: 100%;
	.column{
		width: 100%;
		border: 1px solid transparent;
		padding: 1rem 0px;
		box-sizing: border-box;
		&::-webkit-scrollbar {
			background-color:red;
			width:3px
		}
		&::-webkit-scrollbar-track {
			background-color:#b1cad5;
		}
		&::-webkit-scrollbar-thumb {
			background-color:#76919d;
			border-radius:0px;
			height: 3px;
		}
		&::-webkit-scrollbar:horizontal{
			background-color: #fff;
			height: 3px;
		}

		legend {
			font-size: 1.2rem;
			text-transform: capitalize;
			color: #555;
			font-weight: 300;
		}
	}


	.info_column_row{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		overflow: auto;
		width: 100%;
	}
	.info_row{
		width: 100%;
		margin-bottom: 1rem;
		label{
			font-size: 1rem;
			color: #308bc8;
			display: block;
			font-weight: 400;
			margin-bottom: 10px;
		}
	}

	.column_name{
		background: #eaeaeaab;
		padding: .3rem .6rem;
		border-radius: 3px;
		font-size: .9rem;
		margin-right: 9px;
		box-sizing: border-box;
		color: #555;
		margin-bottom: 9px;
	}
	.query_name{
		font-size: .9rem;
		color: #555;
		white-space: break-spaces;
	}




	.log_table_wrapper{
		width: 100%;
		max-height: calc(100vh - 220px);
		overflow: auto;
		&::-webkit-scrollbar {
			background-color:red;
			width:3px
		}
		&::-webkit-scrollbar-track {
			background-color:#b1cad5;
		}
		&::-webkit-scrollbar-thumb {
			background-color:#76919d;
			border-radius:0px;
			height: 3px;
		}
		&::-webkit-scrollbar:horizontal{
			background-color: #fff;
			height: 3px;
		}
	}



	.log_report_table{
		width: 100%;
		border: 1px solid #eaeaea;
		border-collapse: collapse;
		table-layout: fixed;

		.status_btn{
			padding: 3px 8px;
			text-transform: uppercase;
			font-size: .7rem;
			border-radius: 6px;
			font-weight: 700;
		}
		.active{
			background: #deeec7;
    			color: green;
		}
		.stopped{
			background: #ffe2b361;
 			color: #ff9b00;
		}
		thead{
			th{
				padding: .9rem 1rem;
				color: #fff;
				font-weight: 600;
				background: #308BC8;
				border-bottom: 1px solid #ccc;
				position: sticky;
				z-index: 5;
				top: -1px;
			}
		}
		th, td{
			border: 1px solid #eaeaea;
			padding: .1rem 1rem;
			box-sizing: border-box;
			font-size: .9rem;
			color: #000;
			text-align: center;
			cursor: pointer;
		}
	}






	.field_set{
        width: 100%;
        border: 1px solid #ccc;
    }
	.row_name{
		font-size: 1rem;
		margin-bottom: 7px;
		color: #555;
	}

	.frequncy_row{
		color: #555;
		font-size: .9rem;
		text-transform: capitalize;
		margin: 0px;
	}
	

	.detail_row{
		padding: 1rem 0px;
		box-sizing: border-box;
		/* border-bottom: 1px solid #cccccc80; */
		.table_name{
			background: #eaeaea8a;
			padding: .5rem 1rem;
			border-radius: 30px;
			font-size: .9rem;
			margin-right: 9px;
			box-sizing: border-box;
			color: #555;
		}
		label{
			font-size: 1.1rem;
			color: #308bc8;
			display: block;
			font-weight: 400;
		}
		p{
			font-size: .9rem;
			margin: 0px;
			color: #555;
			margin-top: 10px;
		}
	
	}

`;





export const JobInfoRow = styled.div`
	padding: 1rem 0px;
	width: 100%;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
    height: 7rem;

	.line_item{
		font-size: 1rem;
		margin-bottom: 7px;
		color: #555;
	}

`;




export const JobDetailsExpanded = styled.div`
	cursor: text;
    width: 100%;
	height: ${props => props.expanded ? "10rem" : '0px'};
	overflow: hidden;
	max-height: 10rem;
	overflow-y: auto;
	opacity: ${props => props.expanded ? "1" : '0'};;
    padding: 1rem;
    box-sizing: border-box;
	transition: all 0.10s;
    position: absolute;
    background-color: #fff;
    top: 100%;
    left: 0px;
    z-index: 4;
	box-shadow: rgb(0 0 0 / 20%) 0px 2px 6px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    border-top: 0px;

    &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }

	ul{
		padding: 0px;
		padding-left: 1rem;
		li{
			font-size: .9rem;
			margin: 0px;
			margin-top: 1rem;
			color: #555;
			
			span{
				color: #555;
			}
		
		}
		}

`;





export const ParentCollapse = styled.div`
	width: 23%;
	margin: 1rem  1.5rem;
	padding: 1rem;
    box-sizing: border-box;
    position: relative;
    border: 1px solid #eaeaea;
    box-shadow: 0px 0px 35px rgb(140 152 164 / 18%);
    border-radius: 6px;
	cursor: pointer;

	/* transition: all 0.1s ease-in-out; */

	/* &:hover{
		transform: ${props => props.active ? undefined : 'scale(1.05)'};
	} */

	.toggle_btn{
		position: absolute;
		top: 1rem;
		right: 1rem;
		z-index: 1;
	}

`;