import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { clearQuestionInfo, } from '../../../actions/etl';
import { RadioCheckMark } from '../../hoc/radiobox'
import TextBox from '../../hoc/textBox';
import { DivRow } from '../../styled-hoc';
import SelectOption from '../../select-option';
import EditSvg from '../../svg/edit'
import * as enums from '../../../utils/enums';
import { showPopup } from '../../../utils';
import RippleEffect from '../../hoc/ripple-effect'
import { getHintsByDbInfo } from '../../../actions/hints';
import JsonViewer from '../../train-your-polus/json-viewer';
import { getTargetTableData, getCollectionsOfMongo, viewDirectoryFileAction } from '../../../actions/etl';
import ViewSvg from '../../svg/view';
import ViewFileInfo from './view.file.popup';
import SharedReportBuilderV1 from '../../editor.shared/shared.editor.section';


const CardBox = styled.div`
    position: relative;
    padding: .5rem;
    box-sizing: border-box;
    margin-bottom: 2rem;

    &::before{
        content: '';
        height: 100%;
        width: 5px;
        background: ${props => props.bg ? props.bg : '#000'};
        position: absolute;
        left: -1rem;
        border-radius: 3px;
        top: 0px;
    }

    h2{ 
        margin: 0px;
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 2rem;
        color: #222;
    }

    .card_row{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }

    .or_style{
        font-style: italic;
        display: inline-block;
        font-weight: bold;
        background: rgb(48 139 200);
        color: #fff;
        width: 2re;
        width: 2.7rem;
        height: 2.7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid #000;
    }
`;


const Index = (props) => {

    const {
        clearQuestionInfo,
        getHintsByDbInfo,
        currentConfigInfo,
        setConfigType,
        config_type,
        setSelectedColumn,
        formData,
        setFormData,
        onChangeFunction,
        getAllInfoOfQuestion,
        entities,
        hintsByDbInfo,
        localHintsByEntity,
        selectedColumn,
        localQuestionInfo,
        SelectDisplayField,
        onDrop,
        clickOnDataSource,
        setActiveStep,
        activeClient,
        collectionsOfMongo,
        collectionsColumns,
        db_infos,
        getTargetTableNames,
        setSourceColumns,
        setTableData,
        setTransformationType,
        setPrimaryKeyValue,
        transformationType,

    } = props;


    const openAddEditPopup = (menus, displayItem, selectedFields, title, setSelectedFields, titleOnHover, titleHeading) => {
        showPopup(undefined, undefined, enums.default.popupType.element, SelectDisplayField, {
            menus: menus,
            displayItem: displayItem,
            selectedFields: selectedFields ? selectedFields : [],
            title: title,
            setSelectedFields: setSelectedFields,
            titleOnHover: titleOnHover,
            titleHeading: titleHeading
        })
    };
    const [collectionData, setCollectionData] = useState(undefined);
    const collection_name = collectionsColumns && collectionsColumns.collection_name;

    useEffect(() => {
        if (collectionsOfMongo && collectionsOfMongo.collection_data) {
            setCollectionData(collectionsOfMongo.collection_data)
        }
    }, [collectionsOfMongo])

    useEffect(() => {
        if (config_type === 'sync') {
            props.getCollectionsOfMongo()
        }
    }, [config_type])


    const open_report_builder = () => {
        showPopup(undefined, undefined, enums.default.popupType.element_with_header, SharedReportBuilderV1, {
            // db_info_id: 'demo_med_db_info_id',
            render_mode: "table",
            current_report: [],
            save_current_report: () => console.log("jjj"),
            report_type: "dashboard_config_query_builder",
            is_ETL: true,
        }
        ,
            undefined,
            undefined,
            undefined, {
            width: '95vw',
            height: '85vh'
        },
            true
        )

    }


    return (
        <div style={{ width: '100%' }}>
            {formData && formData['e_type'] === 'directory' && (
                <CardBox style={{ width: '100%', marginTop: '1rem' }} bg={"#03a9f4"}>
                    <h2>Data Source Inputs</h2>
                    <div className="card_row">
                        <div style={{ width: '35%', position: 'relative' }}>
                            <TextBox
                                type="text"
                                label={'Data Source Directory'}
                                value={formData && formData['main_directory'] ? formData['main_directory'] : undefined}
                                onChange={(e) => {
                                    onChangeFunction(e.target.value, 'main_directory')
                                }}
                            />
                            {formData && formData['main_directory'] && formData['main_directory'].length > 2 && (
                                <div style={{ position: 'absolute', right: '0px', top: '10px' }}>
                                    <RippleEffect
                                        icon={<ViewSvg size=".9rem" height=".9rem" color="#222" />}
                                        title="View"
                                        Click={() => {
                                            props.viewDirectoryFileAction(undefined, formData['main_directory'])
                                            showPopup(undefined, undefined, enums.default.popupType.element, ViewFileInfo, {
                                                headerTitle: 'Your File Information',
                                                file_path: formData['main_directory'],
                                                is_main_dir: true
                                            })
                                        }}
                                    />
                                </div>

                            )}

                        </div>
                        <div style={{ width: '20%' }}>
                            <SelectOption
                                width={"100%"}
                                label={"File Type"}
                                selections={["json", 'xml', 'csv']}
                                activeValue={formData && formData['extension'] ? formData['extension'] : undefined}
                                onChangeFunc={(value) => {
                                    onChangeFunction(value, 'extension')
                                }}

                            />
                        </div>

                        <div style={{ width: '30%' }}>
                            {/* <TextBox
                                label={"Choose Files To Process"}
                                type="text"
                                disabled={true}
                                value={formData && formData['landing_date'] ? formData['landing_date'] : undefined}
                                onChange={(e) => {
                                    onChangeFunction(e.target.value, 'landing_date')
                                }}
                            /> */}
                            <SelectOption
                                width={"100%"}
                                // disabled={true}
                                label={"Choose Files To Process"}
                                selections={["All", 'Choose Your Files']}
                                value={formData && formData['landing_date'] ? formData['landing_date'] : undefined}
                                onChange={(value) => {
                                    onChangeFunction(value, 'landing_date')
                                }}
                            />
                        </div>


                    </div>
                </CardBox>
            )}








          
            {formData && formData['e_type'] === 'dbs' && (
                <React.Fragment>
                    <p className="title_up" style={{ marginBottom: '1rem' }}>Select Your Preferences</p>
                    <div className="flex_row" style={{ justifyContent: 'flex-start' }}>
                        <div style={{ marginRight: '2rem', }}
                            onClick={() => {
                                if (!currentConfigInfo) {
                                    setConfigType('naturalLanguge')
                                }
                                if (config_type !== 'naturalLanguge' && !currentConfigInfo) {
                                    clearQuestionInfo()
                                    setSelectedColumn([])
                                    let tempFormData = JSON.parse(JSON.stringify(formData));
                                    tempFormData["entity"] = undefined;
                                    setFormData(tempFormData)
                                }
                            }}
                            className="top_check_box">
                            <RadioCheckMark disabled={currentConfigInfo} checked={config_type && config_type === 'naturalLanguge'} outerSize="1rem" /> <span>Natural Language</span>
                        </div>

                        <div
                            style={{ marginRight: '2rem', }}
                            onClick={() => {
                                if (!currentConfigInfo) {
                                    setConfigType('config')
                                }
                                if (config_type !== 'config' && !currentConfigInfo) {
                                    clearQuestionInfo()
                                    let tempFormData = JSON.parse(JSON.stringify(formData));
                                    tempFormData["question"] = undefined;
                                    setFormData(tempFormData)
                                }
                                open_report_builder()

                            }}
                            className="top_check_box">
                            <RadioCheckMark disabled={currentConfigInfo} checked={config_type && config_type === 'config'} outerSize="1rem" /> <span>Pick from Config</span>
                        </div>



                    </div>
                    {config_type === 'naturalLanguge' && (
                        <div className="flex_row" style={{ marginBottom: '2.5rem' }}>
                            <TextBox
                                labelStyle={{ fontSize: '1.1rem', marginTop: '-.2rem' }}
                                label='Your Transformation Rule'
                                value={formData && formData.question ? formData.question : undefined}
                                onChange={(e) => {
                                    onChangeFunction(e.target.value, 'question')
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && e.target.value && e.target.value.length > 0) {
                                        getAllInfoOfQuestion(e.target.value)
                                    }
                                }}
                            />


                        </div>
                    )}
                    {config_type === 'config' && (
                        <div className="flex_row">
                            <DivRow w="40%">
                                <SelectOption
                                    label='Select Business Entity'
                                    selections={(entities && entities.length > 0) ? entities : []}
                                    displayKey='name'
                                    valueKey='name'
                                    onChangeFunc={(value) => {
                                        onChangeFunction(value, 'entity')
                                        getHintsByDbInfo(value)
                                    }}
                                    activeValue={formData && formData['entity'] ? formData['entity'] : undefined}
                                    width="100%"
                                    border='1px solid #bbd1d9'
                                    color='#7c7c7c'
                                />
                            </DivRow>
                        </div>
                    )}



                    {config_type == 'config' && (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem', marginTop: '.2rem' }}>
                            <DivRow w="100%">
                                <div
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
                                ><span style={{ marginRight: '10px', fontSize: '1rem', color: '#0000008a', textTransform: 'capitalize' }}>Pick Source Column</span>
                                    {selectedColumn && selectedColumn.length > 0 && (
                                        <RippleEffect
                                            icon={<EditSvg size=".9rem" height=".9rem" color="#222" />}
                                            title="edit"
                                            Click={() => {
                                                let _title = "Select Column"
                                                let _title_on_hover = hintsByDbInfo && Object.keys(hintsByDbInfo).length > 0 ? hintsByDbInfo : undefined;
                                                if (formData.entity) {
                                                    openAddEditPopup(props.menus, localHintsByEntity, selectedColumn, _title, setSelectedColumn, _title_on_hover, "Table")
                                                }

                                            }}
                                        />

                                    )}
                                </div>

                                {(!selectedColumn || (selectedColumn && selectedColumn.length === 0)) && (
                                    <div className="add_btn_new_field" onClick={() => {
                                        let _title = "Select Column"
                                        let _title_on_hover = hintsByDbInfo && Object.keys(hintsByDbInfo).length > 0 ? hintsByDbInfo : undefined;
                                        if (formData.entity) {
                                            openAddEditPopup(props.menus, localHintsByEntity, selectedColumn, _title, setSelectedColumn, _title_on_hover, "Table")
                                        }
                                    }}>+&nbsp;Add Column</div>
                                )}



                                {selectedColumn && selectedColumn.length > 0 &&
                                    <div style={{ width: '100%', maxHeight: '15.071rem', display: 'flex', flexDirection: 'row', }}
                                        onDragOver={(event) => event.preventDefault()}
                                    >
                                        <ul className="fields_item_ul">
                                            {selectedColumn.map((item, index) => {
                                                return (
                                                    <li style={{ fontSize: '1rem', lineHeight: '1.714rem', marginRight: '2rem', }}
                                                        draggable={true}
                                                        onDragStart={(event) => {
                                                            event.dataTransfer.setData("source_column", index);
                                                            event.dataTransfer.effectAllowed = "move"
                                                        }}
                                                        onDrop={(event) => onDrop(event)}
                                                        key={index}><span>{item}</span></li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                }
                            </DivRow>
                        </div>
                    )}




                    {localQuestionInfo && Object.keys(localQuestionInfo).length > 0 &&
                        <div style={{ borderTop: config_type === 'config' ? "1px solid #ccc" : undefined, width: "100%", }}>
                            <h3 style={{ fontSize: '1.3rem', fontWeight: 600, color: '#222', margin: '0px', marginBottom: '1.8rem' }}>Details:</h3>
                            <React.Fragment>
                                {localQuestionInfo.db_type && localQuestionInfo.db_type.length > 0 && (
                                    <div className='info_row'>
                                        <label>Databases Types</label>
                                        <div className="info_column_row">
                                            {
                                                localQuestionInfo.db_type.map((name, i) => {
                                                    return (
                                                        <div key={"col" + i} title={name} className='column_name' style={{ textTransform: 'uppercase' }}>{name}</div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )}



                                {localQuestionInfo.table_names && localQuestionInfo.table_names.length > 0 && (
                                    <div className='info_row'>
                                        <label>Source Tables</label>
                                        <div className="info_column_row">
                                            {
                                                localQuestionInfo.table_names.map((row, i) => {
                                                    return (
                                                        <div onClick={() => {
                                                            if (row.db_type == 'mysql') {
                                                                clickOnDataSource(row.table_name)
                                                            }
                                                            if (row.db_type == 'mongo') {
                                                                showPopup(undefined, undefined, enums.default.popupType.element, JsonViewer, { table: row.table_name })
                                                            }
                                                        }} key={"col" + i} title={row.table_name} className='column_name clickable_name'>{row.table_name}</div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>)}
                            </React.Fragment>


                            <React.Fragment>
                                {localQuestionInfo.aggregate_columns && localQuestionInfo.aggregate_columns.length > 0 && (
                                    <div className='info_row'>
                                        <label>Aggregated columns</label>
                                        <div className="info_column_row">
                                            {
                                                localQuestionInfo.aggregate_columns.map((column, i) => {
                                                    let column_real_name = column.indexOf('.') > -1 ? column.split('.')[1] : column;
                                                    return (
                                                        <div key={"col" + i} title={column} className='column_name'>{column_real_name}</div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )}


                                {localQuestionInfo.group_columns && localQuestionInfo.group_columns.length > 0 && (
                                    <div className='info_row'>
                                        <label>Grouped by Columns</label>
                                        <div className="info_column_row">
                                            {
                                                localQuestionInfo.group_columns.map((column, i) => {
                                                    let column_real_name = column.indexOf('.') > -1 ? column.split('.')[1] : column;
                                                    return (
                                                        <div key={"col" + i} title={column} className='column_name'>{column_real_name}</div>
                                                    )
                                                })
                                            }
                                        </div>

                                    </div>
                                )}
                            </React.Fragment>



                            {localQuestionInfo.filterd_columns && localQuestionInfo.filterd_columns.length > 0 && (
                                <div className='info_row'>
                                    <label>Filtered Columns</label>
                                    <div className="info_column_row">
                                        {
                                            localQuestionInfo.filterd_columns.map((column, i) => {
                                                return (
                                                    <div key={"col" + i} title={column} className='column_name'>{column}</div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )}

                            {localQuestionInfo.columns_extracted && localQuestionInfo.columns_extracted.length > 0 && (
                                <div className='info_row'>
                                    <label>Selected Columns</label>
                                    <div className="info_column_row">
                                        {
                                            localQuestionInfo.columns_extracted.map((column, i) => {
                                                let column_real_name = column.name.indexOf('.') > -1 ? column.name.split('.')[1] : column.name;
                                                return (
                                                    <div style={{ background: '#9e9e9e', color: '#fff' }} key={"col" + i} title={column.original_name} className='column_name'>{column_real_name}</div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                </React.Fragment>
            )}



            <CardBox style={{ width: '100%' }} bg={"#548235"}>
                <h2>Processing</h2>
                <div className="card_row" style={{ justifyContent: 'flex-start' }}>
                    <DivRow w="20%" style={{ marginRight: '4rem' }}>
                        <SelectOption
                            label={"Select Target DB"}
                            selections={db_infos && db_infos.length > 0 ? db_infos : []}
                            onChangeFunc={(value) => {
                                let temp_form_data = JSON.parse(JSON.stringify(formData));
                                temp_form_data['target_schema'] = value;
                                getTargetTableNames(value)
                                setFormData(temp_form_data)
                            }}
                            displayKey={"connection_name"}
                            valueKey={"id"}
                            groupKey='db_type'
                            sortKey='desc'
                            activeValue={formData && formData['target_schema'] ? formData['target_schema'] : undefined}
                            isCheckBox={true}
                            isSearch={true}
                            width="100%"
                            height="10.857rem"
                            lineHeight="1.857rem"
                            borderColor='#eaeaea'
                            color='#7c7c7c'
                            addAction={() => {
                                props.history.push('/connection')
                            }}
                        />
                    </DivRow>
                    
                    {config_type == 'sync' && (formData && formData['e_type'] !== 'directory') && (
                        <DivRow w="25%" style={{ marginRight: '4rem' }}>
                            <SelectOption
                                label={"Select Target Collection"}
                                selections={(collectionData && Object.keys(collectionData).length > 0) ? Object.keys(collectionData) : []}
                                onChangeFunc={(value) => {
                                    let temp_form_data = JSON.parse(JSON.stringify(formData));
                                    temp_form_data['source_table'] = value;
                                    setFormData(temp_form_data)
                                    let temp_table_data = [];
                                    let temp_column = []
                                    collectionData[value] && collectionData[value].length > 0 && collectionData[value].forEach((item) => {
                                        temp_column.push(item)
                                        temp_table_data.push({
                                            source_column: item,
                                            target_table: undefined,
                                            target_column: undefined
                                        })
                                    })
                                    setSourceColumns(temp_column)
                                    setTableData(temp_table_data)
                                }}


                                displayKey={"connection_name"}
                                activeValue={formData && formData['source_table'] ? formData['source_table'] : undefined}
                                isSearch={true}
                                width="100%"
                                height="10.857rem"
                                lineHeight="1.857rem"
                                borderColor='#eaeaea'
                                color='#7c7c7c'
                            />
                        </DivRow>
                    )}

                    <DivRow w="25%">
                        <SelectOption
                            selections={['Append', 'Upsert']}
                            label="Data Loading Mode"
                            onChangeFunc={(value) => {
                                setTransformationType(value);
                                if (value === 'Append') {
                                    setPrimaryKeyValue(undefined);
                                }
                            }}
                            activeValue={transformationType}
                            width="100%"
                            lineHeight="2.857rem"
                            border='1px solid #bbd1d9'
                            color='#7c7c7c'
                        />
                    </DivRow>
                </div>
            </CardBox>

            {formData && formData['e_type'] === 'directory' && (
                <CardBox style={{ width: '100%' }} bg={'#851B6A'}>
                    <h2>Post Processing</h2>
                    <div className="card_row">
                        <div style={{ width: '40%', position: 'relative' }}>
                            <TextBox
                                type="text"
                                label="Directory where Processed Files will be Moved"
                                value={formData && formData['processed_directory'] ? formData['processed_directory'] : undefined}
                                onChange={(e) => {
                                    onChangeFunction(e.target.value, 'processed_directory')
                                }}
                            />
                            {formData && formData['processed_directory'] && formData['processed_directory'].length > 5 && (
                                <div style={{ position: 'absolute', right: '0px', top: '10px' }}>
                                    <RippleEffect
                                        icon={<ViewSvg size=".9rem" height=".9rem" color="#222" />}
                                        title="View"
                                        Click={() => {
                                            props.viewDirectoryFileAction(formData['processed_directory'])
                                            showPopup(undefined, undefined, enums.default.popupType.element, ViewFileInfo, {
                                                headerTitle: 'Your File Information',
                                                file_path: formData['processed_directory']
                                            })
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="or_style">OR</div>
                        <div style={{ width: '40%', }}>
                            <TextBox
                                type="text"
                                label="Suffix to Rename Processed Files"
                                value={formData && formData['rename_prefix'] ? formData['rename_prefix'] : undefined}
                                onChange={(e) => {
                                    onChangeFunction(e.target.value, 'rename_prefix')
                                }}
                            />
                        </div>
                    </div>
                </CardBox>
            )}



        </div>
    )
}





const mapStateToProps = state => ({
    collectionsOfMongo: state.EtlReducer.collectionsOfMongo,

})

export default connect(mapStateToProps, { clearQuestionInfo, getHintsByDbInfo, getTargetTableData, getCollectionsOfMongo, viewDirectoryFileAction })(Index)
