import styled from 'styled-components';
import { themes } from '../../../theme/theme.config';
import { getSelectedTheme, apply_styles } from '../../../utils';
import { constants } from '../../../utils/constants';
// import { themeObject } from '../../../theme'
// const theme = themeObject;

const selected = getSelectedTheme();

const { insightStyle } = themes[selected];

// label

export const FilterdItemRow = styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px 10px;
    box-sizing: border-box;
    flex-wrap: nowrap;
    overflow-x: auto;
    &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }
`;


export const Filterd = styled.div`
      max-width: 7.429rem;
     height: 1.571rem;
     border: solid 1px rgb(156, 174, 181);
     background-color: rgb(255, 255, 255);
     display: flex;
     align-items: center;
     justify-content: flex-start;
     padding: 0px 7px;
     margin-right: 10px;
     box-sizing: border-box;
     margin: 2px;

     .cross_mark{
        width: 20px;
        cursor: pointer;
        text-align: center;
        font-size: 1rem;

     }

     .filter_name{
        font-size: 0.714rem;
        font-weight: normal;
        letter-spacing: normal;
        text-align: center;
        color: rgb(0, 22, 31);
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0px 5px;
        text-transform: capitalize;
        white-space: nowrap;
     }
`;

export const MainWrapper = styled.div`
    position: relative;
    width: 100%;
    height: calc(100vh - 60px);
    margin: auto;
    margin-top: 60px;
    overflow: auto;
    width: 100vw;
    padding: ${props => props.isMobile ? "0px 10px" : "0px 20px"};
    box-sizing: border-box;
    background: ${insightStyle.color.mainBg};
    z-index: 0;
`;

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    margin-top: 30px;
    margin: auto;
`;


export const SingleDataMainCard = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const ActionWrapper = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    z-index: 2;

 .refresh_column{
        display: none;
    }
`;




const get_aligment_of_flex = (props, default_value) => {
    let value = props.theme && props.theme.font && props.theme.font.text_align && props.theme.font.text_align.toLowerCase();
    switch (value) {
        case "left":
            return "flex-left";
        case "right":
            return "flex-end";
        case "center":
            return "center";
        default:
            return default_value ? default_value : 'center'
    }
};








export const Title = styled.div`
    text-transform: uppercase;
    ${props => props.font ? apply_styles(props.theme, constants.STYLED_COMPONENTS.FONT) : null};
    ${props => (!props.hide_border && props.height) ? apply_styles(props.theme, constants.STYLED_COMPONENTS.BORDER) : null};
    ${props => props.height ? apply_styles(props.theme, constants.STYLED_COMPONENTS.BACKGROUND) : null};
    ${props => props.margin ? apply_styles(props.margin, constants.STYLED_COMPONENTS.MARGIN) : null};
    ${props => props.need_to_apply_height ? apply_styles(props.theme, constants.STYLED_COMPONENTS.HEIGHT_WIDTH) : null};
    display: block;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: ${props => get_aligment_of_flex(props, 'center')};
    overflow: hidden;
    padding: 0px 18px;

`;




export const InsightReportEditorWrapper = styled.div`
    position: relative;
    width: 34rem;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 6px 0px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(234, 234, 234);
    border-image: initial;
    padding: 20px;
    overflow-y: auto;
    max-height: 80vh;

    &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }


    .data_table{
        width: 100%;
        border: 1px solid #ccc;
        border-collapse: collapse;

        thead{
            td{
                font-weight: 500;
                background-color: #308bc8;
                padding: .3rem .3rem;
                color: #fff;
                width: 30%;
            }
        }

        td{
            border: 1px solid #ccc;
            padding: 0rem .3rem
        }
    }
    .title{
        font-size: 1rem;
        margin-bottom: 5px;
    }
  
`;



export const TitleWrapper = styled.div`
    font-size: 1.286rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.714rem;
    letter-spacing: normal;
    text-align: center;
    color: rgb(26, 26, 26);
    display: block;
    margin: 0px;
`;


export const CloseWrapper = styled.div`
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 7px;
`;

export const CenterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;


export const NoInsightWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 45px;
    box-sizing: border-box;
    width: fit-content;

    i {
        font-size: 1.2rem;
        font-weight: bold;
    }

    div {
        font-size: 1rem;
        font-weight: bold;
    }
`;


export const SelectorWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: calc(50% - 10px);
    left: 0;
`;


export const SelectorWrapperLeft = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: calc(50% - 10px);
    right: 0;
`;


export const AddNew = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d9dcde;
    border-radius: 5px;
    padding: 6px 6px;
    box-sizing: border-box;
    cursor: pointer;

    i {
        font-size: .8rem;
    }

    div {
        margin-left: 5px;
        font-size: 1rem;
        font-weight: normal;
    }
`;


export const SelectOption = styled.div`
    margin-left: 5px;
`;
export const HoverWrapper = styled.div`
    position: ${props => props.position ? props.position : 'absolute'};
    top: ${props => props.top || 0};
    left: ${props => props.left || 0};
    border: 1px solid transparent !important;
    border-radius: ${props => props?.theme?.border?.radius || '0px'};
    z-index: ${props => props?.zIndex};
    
    :hover{
        /* border: 1px solid #666666 !important; */
        border: ${props => props.isGroup ? '1px solid transparent !important' : '1px solid #666666 !important;'}
    }
`;

export const AbsoluteWrapper = styled.div`
    /* position: ${props => props.position ? props.position : 'absolute'};
    top: ${props => props.top || 0};
    left: ${props => props.left || 0}; */
    width: ${props => props.width || 0};
    height: ${props => props.height || 0};
    background-color: #fff;
    ${props => (props.theme && !props.hide_border) ? apply_styles(props.theme, constants.STYLED_COMPONENTS.BORDER) : null};
    ${props => props.theme ? apply_styles(props.theme, constants.STYLED_COMPONENTS.BACKGROUND) : null};
    .report_expand_icon{
        display: none;
    }
    .header_div_of_view_wrapper{
      display: none; 
    }

    &:hover{
        .refresh_column{
            display: inline-block;
        }
        .report_expand_icon{
            display: inline-block;
        }
        .header_div_of_view_wrapper{
            display: block;
        }
    }

    #report_inner_wrapper{
        &:hover{
            .refresh_column{
            display: inline-block;
        }
        .report_expand_icon{
            display: inline-block;
        }
        .header_div_of_view_wrapper{
            display: block;
        }
        }
    }
`;




export const SingleDataWrapper = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        flex-direction: column;

        .arrow-up {
            width: 0; 
            height: 0; 
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid green;
            display: inline-block;
    }

    .arrow-down {
        width: 0; 
        height: 0; 
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #f00;
        display: inline-block;
    }

    .arrow-right {
        width: 0; 
        height: 0; 
        border-top: 60px solid transparent;
        border-bottom: 60px solid transparent;
        border-left: 60px solid green;
        display: inline-block;
    }

    .arrow-left {
        width: 0; 
        height: 0; 
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent; 
        border-right:10px solid blue; 
        display: inline-block;
    }

    .value{
        font-size: 2rem;
        line-height: 1.938rem;
        font-weight: 700;
        color: '#222'
    }

`;



export const ChartApperanceSetting = styled.div`
        width: 100%;
        .flex_row{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 50%;
        }
`;



export const InsightBox = styled.div`
    width: 15.429rem;
    min-width: 200px;
    height : 7.714rem;
    min-height: 100px;
    background-color: ${insightStyle.color.insightBoxBg};
    padding: 20px;
    box-sizing: border-box;
    box-shadow: rgba(0,0,0,0.25) 0px 1px 4px 0px;
    display: flex;
    flex-direction: column;
    h2{
        font-size: 1.2rem;
        text-align: center;
        text-transform: capitalize;
        margin: 8px 0px;
    }

    .title{
        font-size: 1rem;
        color:${insightStyle.color.insightBoxTitle};
        text-transform: capitalize;
        font-weight: 500;
    }

    .data{
        margin-top: auto;
        margin-bottom: auto;
        text-align: left;
        font-size: 2rem;
        font-weight: 600;
    }
`;