import styled from 'styled-components';

import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils';
const selected = getSelectedTheme();


const { filter } = themes[selected];


export const ReportFilterHeader = styled.div`
        width: 100%;
        padding: 23px 21px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        font-size: 0.875rem;
        font-weight: bold;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: left;
        color: rgb(0, 22, 31);
        border-bottom: 1px solid #9a9a9a;

        .title{
            line-height: 1.286rem;
            font-size: 1rem;
        }

        .filter_icon{
            width: 18px;
            margin-right: 10px;
        }

        .close{
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            
            img{
                width: 15px;
            }
        }


        .inner_height_wrapper{
            position: relative;
            padding: 0px 1.2rem;
            box-sizing: border-box;
            /* max-height: ${props => props.maxHeight || undefined} */
        }


        

`;



export const ItemWrapper = styled.div`

    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #9a9a9a;
    padding: 10px 1rem;
    /* max-height: 17rem; */
    overflow-y: auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    background-color: #fff;
    border-radius: 12px;
    margin-bottom: 8px;


    .custom_date_options{
        .cell{
            width: 33%;;
            display: flex;
            align-items: center;
            /* border-right: 1px solid #ccc; */
            cursor: pointer;
               span{
                    font-size: .9rem;
                    color: #fff;
                    font-weight: 400;
                    display: inline-block;
                    margin-left: 6px;
          }
        }
    }

    .lbl{
            margin-bottom: -10px;
            font-size: 0.7rem;
            color: rgb(255, 255, 255);
            font-weight: bold;
            display: block;
    }


    .quick_date_options{
        max-height: 15rem;
        overflow-y: auto;
         &::-webkit-scrollbar {
            background-color:red;
            width: 3px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
        }

        .option{
            background: #1e66af;
            color: #fff;
            padding: 5px;
            box-sizing: border-box;
            border-bottom: 1px solid #ffffffd4;
            cursor: pointer;
            font-size: .9rem;
            font-weight: 600;

            &:hover{
                opacity: .9;
            }
        }
    }

    .filter_scroller{
        max-height: 18rem; //${props => props.filterItemMaxHeight ? props.filterItemMaxHeight : "17rem"};
        overflow-y: auto;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            background-color:red;
            width: 3px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
        }
    }
   

    .sub_item{
        cursor: pointer;
        display: block;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: .9rem;
        letter-spacing: normal;
        text-align: left;
        color: rgb(0, 22, 31);
        padding-bottom: ${props => props.show ? '10px' : '0px'};

        .clear_btn{
            position: absolute;
            right: 63px;
            color: blue;
            font-weight: 600;
            text-transform: uppercase;
            font-size: .6rem;
            cursor: pointer;
            display: inline-block;
            /* background: #eaeaea; */
            padding: 5px 7px;
            border-radius: 3px;
            text-decoration: underline;
        }
    }
`;

export const ItemRow = styled.div`
    width: 100%;
    padding:0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 2.7rem;
    box-sizing: border-box;

    .item_name{
        font-size: 0.75rem;
        line-height: 2.5;
        letter-spacing: normal;
        text-align: left;
        color: rgb(0, 22, 31);
        margin-left:5px;
        width: calc(100% - 30px);
        font-weight: 600;
        padding: 0px;
        cursor: pointer;
        text-align: left;
        font-weight: normal;
    }


`;


export const CloseDrawer = styled.div`
   
    width: 2.2rem;
    height: 2.2rem;
    font-size: 1.3rem;
    margin-top: -14px;
    display: inline-block;
    color: #8395af;
    cursor: pointer;
    position: absolute;
    top: 1.9rem;
    background: #f1f3f5;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover{
        color: #000;
    }


`;




export const ReportFilterWrapper = styled.div`

    width: ${props => props.width ? props.width + "px" : '252px'};
    max-height: ${props => props.maxHeight ? (props.maxHeight + 50) + "px" : "calc(100vh - 10rem)"};
    border-radius: 5px;
    overflow-y: auto;
    min-height: ${props => props.minHeight ? props.minHeight + "px" : undefined};
    display: flex;
    flex-direction: column;
    background: #1e66af;

    &::-webkit-scrollbar {
        background-color:red;
        width: 3px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
    }
    .drop_title {
        font-size: 0.9rem;
        text-transform: capitalize;
        font-weight: 500
    }

       .inner_height_wrapper{
            position: relative;
            padding: 0px 1.2rem;
            box-sizing: border-box;
            /* max-height: ${props => props.maxHeight || undefined} */
        }

    select {
        background: transparent;
        border: 1px solid #c1c1c2;
        padding: 7px 5px;
        box-sizing: border-box;
        border-radius: 3px;
        outline: none;
        width: 100%
    }

    input[type="text"] {
        background: transparent;
        border: 1px solid #c1c1c2;
        padding: 5px 5px;
        box-sizing: border-box;
        border-radius: 5px;
        outline: none;
        width: 200px;
    }

    input[type="date"] {
        margin-top: .2rem;
        width: 100%;
    } 
    .parent_div{
        width: 100%;
        padding: 3px 0px;
    }

    .parent_flex{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        justify-content: flex-start;

    }
    .toogle_btn{

        display: flex;
        align-items: center;
        width: 10px !important;
        height: 12px !important;
        justify-content: flex-start;
    }

    .tab_parent{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .tab_item{
                width: 32.33%;
                font-size: .9rem;
                border: 0px solid #fff;
                height: 2.3rem;
                font-weight: 600;
                background: #ccc;
                display: flex;
                /* margin-bottom: 2px; */
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border-top-right-radius: 15px;
                border-top-left-radius: 15px;
                color: #fff;
            }

            .active{
                background: #1e66af;
                color: #fff;
            }
        }
`;



export const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 1rem;
    margin-bottom: 0px;
    position: sticky;
    bottom: 0px;
    padding: 7px 15px;
    background-color: #194a7c;
    color: black;
    font-weight: bolder;
    box-sizing: border-box;
    z-index: 1;
    div {
        cursor: pointer;
    }
`;



export const ButtonNew = styled.div`
    width: 7.2rem;
    border: 0px;
    color: '#222';//${props => props.color || "#fff"};
    /* background-color: ${props => props.bgColor || "rgb(0, 138, 199)"}; */
    background-color: #fff;
    vertical-align: top;
    text-align: center;
    font-weight: normal;
    font-size: 1rem;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 2.125rem;
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16);
    border-radius: 5px;

    &:hover{
        opacity: 0.8;
    }


    &:last-child{
        border-right: 0px;
    }
`
