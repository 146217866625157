import React from 'react'
import styled from 'styled-components';


const Wrapper = styled.div`
    width: 100%;
    min-height: 10rem;
    background-color: #fff;

    p{
        margin-bottom: .7rem;
    }

    span{
        display: inline-block;
        background: #e2e2e2;
        padding: 3px 4px;
        border-radius: 3px;
        font-size: .9rem;
        margin: 2px;
        /* max-width: 14rem;; */
        text-overflow: ellipsis;
        overflow: hidden;
        
    }

    .flex{
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        flex-direction: row;
        margin-bottom: 1rem;
    }

`;


const AppliedFilterInfo = (props) => {

    const { data, options, close } = props;

    const { filter_applied, filter_not_applied } = data;

    return (
        <Wrapper>

            <p><strong>Applied Filter:</strong> </p>

            <div className='flex'>
                {(filter_applied || []).map((a) => {
                    return <span title={a}>{a}</span>
                })}
            </div>

            <p><strong>Not Applied Filter:</strong></p>

            <div className='flex'>
                {(filter_not_applied || []).map((a) => {
                    return <span title={a}>{a}</span>
                })}
            </div>

        </Wrapper>
    )
}

export default AppliedFilterInfo;
