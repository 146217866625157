export const COMPONENT_IDS = {
    MAIN_MENU: {
        CONFIGURE_TRAIN: {
            MAIN: 'configure_train',
            DATA_SOURCE: 'data_source',
            FACT_DIMENSIONS: 'fact_dimensions',
            TRAIN_YOUR_POLUS: 'train_your_polus',

            BUSINESS_RULES: {
                MAIN: 'business_rules',
                DERIVED_COLUMN: 'derived_column',
                RULES_OVER_VALUES: 'rules_over_values',
                HUMAN_INTERPRETATION: 'human_interpretation'
            },

            SECURE_ACCESS_LAYER: {
                MAIN: 'secure_access_layer',
                USERS: 'users',
                USER_ROLES: 'user_roles',
                DATA_MASK: 'data_mask',
                DATA_POLICY: 'data_policy',
            },
            IMPORT_DATA: 'import_data',
            RELATIONSHIP: 'relationship',
            FILE_LOGS: 'file_logs'
        },

        ETL_WIZ: {
            MAIN: 'etl_wiz',
            DATA_CAPTURE: {
                MAIN: 'data_capture',
                RDBMS: 'rdbms',
                NOSQL: 'nosql',
                CDC: 'cdc',
                FILES: 'files',
                HDFS_AWS: 'hdfs_aws',
                QUEUE: 'queue',
                API_INGESTION: 'api_ingestion',
                EVENT_CAPTURE: 'event_capture',
                STREAMING_DATA: 'streaming_data'
            },
            DATA_WRANGLING: {
                MAIN: 'data_wrangling',
                ETL_DATA_WRANGLING_JOBS: 'etl_data_wrangling_jobs',
                DATA_SYNC_UP: 'data_sync_up',
                SYSTEM_JOB: 'system_job',
            }
        },

        CONVERSATIX_BI: {
            MAIN: 'conversatix_bi',
            YOUR_PINNED_ITEMS: 'your_pinned_items',
            REPORTS: {
                MAIN: 'reports',
            },
            DATA_CACHE: {
                MAIN: 'data_cache',
            },
            DASHBOARDS: {
                MAIN: 'dashboards',
            },
            GEO_ANALYTICS_TITLE: 'geo_analytics_title',
            MAP: {
                MAIN: 'map',
            },
            TEXT_ANALYTICS_TITLE: 'text_analytics_title',
            TEXT_ANALYTICS: 'text_analytics',
        },
        INIGHTS_TO_ACTION: {

            MAIN: 'insights_to_action',

            YOUR_BUSINESS_RULE: {
                MAIN: 'your_business_rule',
                CREATE_BUSINESS_RULE: 'create_business_rule',
                VIEW_BUSINESS_RULES: 'view_business_rules',
            }
        }
    },

    SHARED_ACTIONS: {

        MAIN: 'shared_action',

        EDIT_REPORT: 'edit_reports',
        DELETE_REPORT: 'delete_report',
        CREATE_REPORT: 'create_report',
        SHARE_REPORT: 'share_report',
        CLONE_REPORT: 'clone_report',

        EDIT_DASHBOARD: 'edit_dashboard',
        DELETE_DASHBOARD: 'delete_dashboard',
        CREATE_DASHBOARD: 'create_dashboard',
        SHARE_DASHBOARD: 'share_dashboard',
        CLONE_DASHBOARD: 'clone_dashboard',

        CREATE_QUICK_DASHBOARD: 'create_quick_dashboard',

        EDIT_MAP: 'edit_map',
        DELETE_MAP: 'delete_map',
        CREATE_MAP: 'create_map',

    },


}




export const generate_element_id = (main_object, key) => {

    let id = "";
    const parent_keys = [];

    const create_element_id = (el_object, key) => {

        let all_keys = Object.keys(el_object);

        if (all_keys.length > 0) {

            for (let i = 0; i < all_keys.length; i++) {

                let k = all_keys[i];

                if (k === key) {
                    id = el_object[k].MAIN ? el_object[k].MAIN : el_object[k];
                    if (parent_keys.indexOf(el_object["MAIN"]) == -1) {
                        parent_keys.push(el_object["MAIN"])
                    }
                    break;
                }

                if (typeof el_object[k] === "object") {
                    if (parent_keys.indexOf(el_object["MAIN"]) == -1) {
                        parent_keys.push(el_object["MAIN"])
                    }
                    create_element_id(el_object[k], key)
                }
            }
        }
    }
    if (main_object) create_element_id(main_object, key)

    return parent_keys.join('__') + '__' + id
}


const get_access_by_page_path = (path, acc, client_id) => {

    // const element_id = '';

    // switch (path) {
    //     case 'train_polus':
    //         element_id = generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'TRAIN_YOUR_POLUS')
    //         break;
    //     case 'data_hierarchy':
    //         element_id = generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'FACT_DIMENSIONS')
    //         break;
    //     case value:
    //         element_id = generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'TRAIN_YOUR_POLUS')
    //         break;
    //     case value:
    //         element_id = generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'TRAIN_YOUR_POLUS')
    //         break;
    //     case value:
    //         element_id = generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'TRAIN_YOUR_POLUS')
    //         break;


    //     default:
    //         break;
    // }

}