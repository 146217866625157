import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';
import CloseSvg from '../../svg/close';
import { Radio } from '../../hoc/checkbox';
import { Button } from '../../hoc/button';




const Wrapper = styled.div`

    background: #fff;
    width: 50rem;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    box-sizing: border-box;
    border-radius: 12px;
    padding-bottom: 0px;
    overflow: hidden;

    .header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        margin-bottom: 2rem;

        h1{
            font-size: 1.5rem;
            font-weight: 500;
            margin: 0px;
            font-weight: bold;
            color: #2173F7;

        }

        .close{
            cursor: pointer;
            width: 1rem;
            height: 1rem;
            svg{
                fill: #333;
                
            }
        }
    }

    .schema_lists{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-height: 10rem;
        overflow-x: auto;

    }

    .schema{

        border: 1px solid #6b6b6b;
        border-radius: 30px;
        padding: 10px;
        display: flex;
        margin-right: 15px;
        margin-bottom: 15px;
        /* width: 200px; */
        align-items: center;
        cursor: pointer;

        &::-webkit-scrollbar {
            /* background-color:red; */
            width:10px;
            border-radius: 10px;
    }

        &::-webkit-scrollbar-track {
            background-color:#EFEDF9;
            border-radius: 20px;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#C1C1EA;
            border-radius:20px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }



        .name{
            max-width: 150px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 1rem;
            color: #6b6b6b;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;

;
        }
    }


    .schema_info{
        width: 50%;
        margin-top: 1rem;
        .title{
            font-size: 1.1rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #333232;
            margin: 0px;
        }
        .value{
            font-size: 1rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #6b6b6b;
            margin: 0px;
            margin-top: 4px;
        }
    }

    
    .buttons{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2rem 0px;
        background-color: #e2e2e2;
        padding: 1rem 2rem;
        background-color: #E8E8F9;
        margin: 0px -2rem;
        margin-top: 2rem;
    }

    .close_btn{
        margin-top: -32px;
        cursor: pointer;
    }



`;

const SchemaListPopup = (props) => {

    const { db_infos, popupKey, option, data, close } = props;

    const db_type = data?.db_type
    const save_fun = data?.save_fun;
    const selected_db_id = data?.selected_db_id;


    const [local_db_info, set_local_db_info] = useState(undefined);
    const [active_schema, set_active_schema] = useState(undefined)


    useEffect(() => {
        const active_db_info = selected_db_id && props.db_infos.find((d) => d.id === selected_db_id);
        set_active_schema(active_db_info)
    }, [selected_db_id])

    useEffect(() => {
        const clone_db_infos = db_infos ? [...db_infos] : [];
        const filterd_db = clone_db_infos?.filter((d) => {
            if (d.db_type === db_type) return true;
            else return false;
        })
        // const db_type
        set_local_db_info(filterd_db)
    }, [db_infos])


    return (
        <Wrapper>
            <div className='header'>
                <h1>List Of Schemas</h1>
                <div title="Close" className='close_btn' onClick={() => {
                    close(option, popupKey)
                }}>
                    <CloseSvg size="1.1rem" />
                </div>
            </div>
            <div className='search'></div>

            <div className='schema_lists'>

                {local_db_info || []?.length > 0 ? local_db_info.map((d) => {
                    return (
                        <div className='schema' onClick={() => {
                            set_active_schema(d)
                        }}>
                            <Radio
                                w="25px"
                                h="25px"
                                mR="5px"
                                top="5px"
                                right="8px"
                                checked={active_schema?.id === d.id}
                                bColor='#6b6b6b'
                            // radioBg={"green"}
                            />
                            <span className='name'>{d.connection_name}</span>
                        </div>
                    )
                }) : <p>Not Found</p>}
            </div>

            <div className='schema_info'>
                <div style={{
                    marginBottom: '1rem',
                    borderBottom: '1px solid #6b6b6b'
                }}>
                    <p className='title'>Database Name</p>
                    <p className='value'>{active_schema?.database_name}</p>
                </div>

                <div style={{
                    marginBottom: '1rem',
                    borderBottom: '1px solid #6b6b6b'
                }}>
                    <p className='title'>Server Name</p>
                    <p className='value'>{active_schema?.host}</p>
                </div>
            </div>

            <div className='buttons'>
                <Button
                    width={"7rem"}
                    bgColor={"#fff"}
                    style={{
                        color: '#222'
                    }}
                    onClick={() => {
                        close(option, popupKey)
                    }}

                >
                    Back
                </Button>
                <Button
                    width={"7rem"}
                    bgColor="#2173F7"
                    onClick={() => {
                        save_fun(undefined, undefined, active_schema?.id)
                        close(option, popupKey)
                    }}>
                    Next
                </Button>
            </div>

        </Wrapper>
    )
}



const mapStateToProps = (state) => ({
    db_infos: state.connectionReducer.db_infos,
})

export default connect(mapStateToProps, {})(SchemaListPopup);
