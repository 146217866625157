import React, { useState, useEffect } from 'react';
import { HintsWrapper, AddNewTag, ExistingTags, Filterd, FilterdItemRow } from './hint-editor.styled';
import { Button } from '../hoc/button'
import { Gap } from '../gap';
import CrossSvg from '../svg/close';
import EditSvg from '../svg/edit';
import TextBox from '../hoc/textBox';
import SelectOption from '../select-option';


import { insertAtSpecificLocationInArray, removeElementFromArray } from '../../utils';


const HintEditor = props => {

    const { close, option, popupKey, data } = props;
    const { hintData, func, columnInfo , platforms} = data;

    const [displayHint, setDisplayHint] = useState(undefined);
    const [filteredHints, setFilteredHints] = useState([]);
    const [editIndex, setEditIndex] = useState(undefined);
    const [hint, setHint] = useState(undefined);
    const [showFilterError, setFilterError] = useState(undefined);
    const [isInputShow, setisInputShow] = useState(false);
    const [inputValue, setinputValue] = useState('');
    const [columnName, setColumnName] = useState(undefined);
    const [dataType, setDataType] = useState(undefined);
    const [platformName, setPlatformName] = useState(undefined);
    const [isMainChange, setIsMainChange] = useState(false);
    const [originalColumnName, setOriginalColumnName] = useState(undefined);



    useEffect(() => {
        if (columnInfo && columnInfo.column_name) {
            setColumnName(columnInfo.column_name);
            setOriginalColumnName(columnInfo.column_name);
        }
        if (columnInfo && columnInfo.data_type) {
            setDataType(columnInfo.data_type);
        }
        if(columnInfo && columnInfo.platform){
            setPlatformName(columnInfo.platform)
        }
    }, [columnInfo])



    useEffect(() => {
        if (hintData && hintData && hintData.length > 0) {
            setFilteredHints(hintData);
        }
        setHint(hintData);
    }, [hintData]);


    const addHints = value => {
        const tempFilteredHints = [...filteredHints];
        const caseInSensitiveValue = typeof value === 'string' ? value.toLowerCase().trim() : value.trim();

        if (tempFilteredHints.indexOf(caseInSensitiveValue) === -1) {
            if (!editIndex) {
                tempFilteredHints.push(value);

                setFilteredHints(tempFilteredHints);

            }
            else {
                const newArray = insertAtSpecificLocationInArray(tempFilteredHints, editIndex, value);
                setEditIndex(undefined);
                setFilteredHints(newArray);
            }
            setDisplayHint(undefined);
            setinputValue("");
        }
        else {
            setFilterError(true);
        }
    };



    const removeSelectedHint = (indexOfRemovedHint) => {
        const tempFilteredHints = [...filteredHints];

        tempFilteredHints.splice(indexOfRemovedHint, 1);

        setFilteredHints(tempFilteredHints);
    };

    const _editHints = (value, index) => {
        let tempFilteredHints = removeElementFromArray(index, undefined, filteredHints);

        setinputValue(value);
        setEditIndex(index);
        setisInputShow(true);
        setFilteredHints(tempFilteredHints);
    }


    const _save = () => {
        const temp_object = Object.assign({}, columnInfo);
        
        temp_object.modified = true;
        temp_object.column_name = columnName;
        temp_object.data_type = dataType;
        temp_object.platform = platformName;
        temp_object.hints = [...filteredHints];
        temp_object.is_main_change = isMainChange;
        temp_object.original_name = originalColumnName;
        temp_object.is_new = columnInfo ? false : true;
        // temp_object.platform = (columnInfo && columnInfo.platform) ? columnInfo.platform : undefined;

        func.saveHints(temp_object, "Column Info Save Successfully");
        
        close(option, popupKey);
    }



    return (
        <HintsWrapper>
            {columnInfo ?
            <AddNewTag
                onClick={() => setisInputShow(true)}
            >
                <span style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <EditSvg size="1rem" height="1rem" />
                </span>
                <span className="add_title">Edit Column Info</span>
            </AddNewTag>

            :

                <AddNewTag
                    onClick={() => setisInputShow(true)}
                >
                    <span className="add" style={{ marginTop: '-4px' }}>+</span>
                    <span className="add_title">Add New Column Info</span> 
                </AddNewTag>
            }

            <div style={{ width: '100%', margin: '1rem 0rem' }}>
                <TextBox
                    width={'100%'}
                    label="Column Name"
                    autoFocus
                    value={columnName}
                    onChange={(e) => {
                        let _value = e.target.value;
                        setColumnName(_value);

                        if (!isMainChange) {
                            setIsMainChange(true);
                        }
                    }}
                />
            </div>

           

            <div style={{ width: '100%', margin: '0rem 0rem 1rem 0rem' }}>
            <SelectOption
                label="Data Type"
                width={"100%"}
                selections={[
                    {name: 'String', value: 'string'},
                    {name: 'currency', value: 'Currency'},
                    {name: 'number', value: 'Number'},
                    {name: 'epoch', value: 'Epoch'},
                    {name: 'date', value: 'Date'}


                ]}
                displayKey='name'
                valueKey='value'
                onChangeFunc={(value) => {
                    setDataType(value)
                }}
                activeValue={dataType}
                lineHeight="2.857rem"
                border='1px solid #bbd1d9'
                color='#7c7c7c'
            />
            </div>


            <div style={{ width: '100%', margin: '0rem 0rem 1rem 0rem' }}>
            <SelectOption
                label="Select Platform"
                width={"100%"}
                selections={(platforms && platforms.length > 0) ? platforms : []}
                onChangeFunc={(value) => {
                    setPlatformName(value)
                }}
                activeValue={platformName}
                lineHeight="2.857rem"
                border='1px solid #bbd1d9'
                color='#7c7c7c'
            />
            </div>


            <input
                className={"form_input_hint"}
                type="text"
                // autoFocus
                value={inputValue}
                placeholder="Enter Possible Column Name"
                onChange={(e) => setinputValue(e.target.value)}
                onKeyDown={(event) => {
                    let value = inputValue.trim()
                    if (event.key === 'Enter' && value && value.length > 0) {
                        addHints(value)
                        setisInputShow(false)
                        setinputValue("")
                    }
                }}
            />


            <Gap h="25px" />

            {filteredHints && filteredHints.length > 0 && (
                <ExistingTags>
                    <h2 className="e_tags">Existing Possible Column Names</h2>
                    <Gap h="14px" />
                    <FilterdItemRow>
                        {filteredHints.map((hint, index) => {
                            return (
                                <Filterd key={index}>
                                    {hint}
                                    <div className="filter_hover">
                                        <div onClick={() => removeSelectedHint(index)}><CrossSvg size="12px" height="12px" fill="#000" /></div>
                                        <div onClick={() => _editHints(hint, index)}><EditSvg size="12px" height="12px" fill="#000" /></div>
                                    </div>
                                </Filterd>
                            )
                        })}
                    </FilterdItemRow>
                </ExistingTags>
            )}



            <div style={{
                paddingTop: '25px',
                borderTop: '1px solid #ccc',
                marginTop: 'auto',
                display: 'block',
                width: '100%'
            }}>
                <Button
                    primary={true}
                    width='6rem'
                    mR="16px"
                    onClick={_save}
                >
                    Save
                </Button>
                <Button
                    width='6rem'
                    bgColor={'#e6e6e6'}
                    style={{
                        marginBottom: 0
                    }}
                    onClick={() => {
                        close(option, popupKey);
                    }}
                >Cancel</Button>
            </div>
        </HintsWrapper>
    );
};

export default HintEditor

