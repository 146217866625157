import { dispatchAction, simpleDispatch_post } from './helper';
import * as actionTypes from '../action-types/filter';
import { constants } from '../utils/constants';

import {

    save_filters_in_session,
    save_be_filters_in_session,
    get_be_filters_from_session,
    get_filters_from_session,
    save_validation_in_session

} from '../utils/session.helper';

import _ from 'lodash';


export const get_filters_options_v1 = (db_info_id) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.FILTERS_V1.POINT + constants.END_POINTS.FILTERS_V1.GET_FILTER_OPTIONS_V1;

    const post = {
        db_info_id: db_info_id
    };


    simpleDispatch_post(dispatch, url, post, actionTypes.GET_FILTER_OPTIONS_TABLE_COLUMNS_V1, true);


}

export const remove_widget_id_from_stored = (insight_id, id) => async dispatch => {
    dispatchAction(dispatch, actionTypes.REMOVE_GLOBAL_FILTER_WIDGETS_ID,{insight_id, id}, undefined, undefined);
}

export const apply_filters = (base_report_id, filters, is_backend_filter, drill_filter_cache, validation_key, insight_id, widegt_ids) => async dispatch => {

    dispatchAction(dispatch, actionTypes.V2_APPLY_FILTERS, { base_report_id, filters }, undefined, undefined);

    const filters_for_save_in_session = filters ? _.cloneDeep(filters) : {};

    filters_for_save_in_session && (Object.keys(filters_for_save_in_session) || [])?.forEach(key => {
        if (drill_filter_cache && drill_filter_cache[key]?.not_save_in_session) {
            delete filters_for_save_in_session[key];
        }
    })

    if(widegt_ids?.length > 0){
        // let's store all these id's inot state
        // STORE_GLOBAL_FILTER_WIDGETS_IDS
        dispatchAction(dispatch, actionTypes.STORE_GLOBAL_FILTER_WIDGETS_IDS, {insight_id, widegt_ids}, undefined, undefined);

    }

    if (validation_key) {
        save_validation_in_session(validation_key, base_report_id)
    }

    // save to session
    if (filters_for_save_in_session) {
        save_filters_in_session(filters_for_save_in_session, base_report_id);
    }

    if (is_backend_filter) {
        save_be_filters_in_session(filters, base_report_id);
        dispatchAction(dispatch, actionTypes.V2_RAISE_BACKEND_FILTER_FOR_STORE, { base_report_id, filters }, undefined, undefined);
    }

    if (!is_backend_filter) {
        const be_filter = get_be_filters_from_session(base_report_id)

        if (be_filter && Object.keys(be_filter).length > 0) {
            dispatchAction(dispatch, actionTypes.V2_RAISE_BACKEND_FILTER_FOR_STORE, { base_report_id, filters: be_filter }, undefined, undefined);
        }
    }
};


export const clear_all_filters = (base_report_id) => async dispatch => {
    dispatchAction(dispatch, actionTypes.V2_CLEAR_ALL_FILTERS, { base_report_id }, undefined, undefined);
    if (base_report_id) {
        save_filters_in_session(undefined, base_report_id);
    }
};


export const clear_single_filter = (base_report_id, filter_name, filter_value) => async dispatch => {
    dispatchAction(dispatch, actionTypes.V2_CLEAR_SINGLE_FILTER, { base_report_id, filter_name, filter_value }, undefined, undefined);
};


export const mark_mtd_ytd = (base_report_id, ytd_mtd_option) => async dispatch => {
    dispatchAction(dispatch, actionTypes.V2_MARK_YTD_MTD, { base_report_id, ytd_mtd_option }, undefined, undefined);

    // const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.FILTERS.FILTER_DATA_FOR_MTD_YTD;

    // const post = {
    //     report_item_id: base_report_id,
    //     ytd_mtd_option
    // };

    // simpleDispatch_post(dispatch, url, post, actionTypes.V2_MARK_YTD_MTD_DATA_RECEIVED, true);

};


export const expand_option = (base_report_id, option_expanded) => async dispatch => {
    dispatchAction(dispatch, actionTypes.V2_EXPAND_OPTION, { base_report_id, option_expanded }, undefined, undefined);
};


export const getFiltersForDropDowns = (
    scheduleReportId,
    client_info,
    question,
    report_type,
    report_item_id,
    existing_filters = {}) => async dispatch => {

        const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.FILTERS.GET_FILTERS;

        // const existing_be_filters_for_report = get_be_filters_from_session(report_item_id || scheduleReportId) || {};
        // const existing_session_filters_for_report = get_filters_from_session(report_item_id || scheduleReportId) || {};
        // const filters_to_take = Object.keys(get_filters_from_session).length > 0 ? existing_session_filters_for_report : existing_be_filters_for_report;

        const post = {
            scheduleReportId,
            client_info,
            question,
            report_type,
            report_item_id,
            widget_id: report_item_id,
            existing_filters: { ...existing_filters }
        };

        if (process.env.REACT_APP_DEV_LOGIN_KEY) {
            delete post['client_info'];
        }

        simpleDispatch_post(dispatch, url, post, actionTypes.V2_GET_FILTER_DROPDOWN_DATA_SUCCESS, true);
    };








/**
 * 
 * @param {*} id 
 * @param {*} report_type 
 * @returns 
 * this function will get all the filter for insight
 */
export const get_filters_dropdown_for_global_filter = (id, report_type, db_info_id) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.FILTERS.GET_FILTER_DATA_FOR_INSIGHT;
    // const existing_be_filters_for_report = get_be_filters_from_session(report_item_id || scheduleReportId) || {};
    // const existing_session_filters_for_report = get_filters_from_session(report_item_id || scheduleReportId) || {};
    // const filters_to_take = Object.keys(get_filters_from_session).length > 0 ? existing_session_filters_for_report :  existing_be_filters_for_report;

    const post = {
        report_id: id,
        report_type,
        db_info_id: db_info_id
    };

    simpleDispatch_post(dispatch, url, post, actionTypes.V2_GET_FILTER_DROPDOWN_DATA_SUCCESS, true);

};



export const get_filter_distinct_data = (id, report_type, key, value) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.FILTERS.GET_FILTER_DISTINCT_DATA;
    const post = {
        report_id: id,
        report_type,
        filter_key: key,
        value: value
    };
    simpleDispatch_post(dispatch, url, post, actionTypes.V2_GET_FILTER_DISTINCT_DATA, true);
}


/**
 * 
 * @param {*} insight_id 
 * @param {*} index 
 * @param {*} key 
 * @param {*} value 
 * @returns 
 */
export const remove_global_filter = (insight_id, index, key, value) => async dispatch => {
    dispatchAction(dispatch, actionTypes.DESTORY_GLOBAL_FILTER_RAISE, { insight_id, key, value, index })
}

export const mt_deleted_filter = () => async dispatch => {
    dispatchAction(dispatch, actionTypes.MT_DELETED_FILTER)
}

/**
 * 
 * @param {*} insight_id 
 * @param {*} report_id 
 * @param {*} index 
 * @param {*} filter_key 
 * @returns 
 */

export const raise_drill_filter_cache = (insight_id, report_id, filter_data) => async dispatch => {
    dispatchAction(dispatch, actionTypes.RAISE_DRILL_FILTER_CACHE, {
        insight_id,
        report_id,
        filter_data
    })
}


export const clear_drill_filter_cache = (insight_id, report_id, filter_key, delete_all) => async dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_DRILL_FILTER_CACHE, {
        insight_id: insight_id,
        report_id: report_id,
        filter_key: filter_key,
        delete_all: delete_all
    })
}


export const update_report_filter_alias = (filter_info, id) => async dispatch => {

    const filter_info_to_be_saved = [];

    filter_info && Object.keys(filter_info)?.length > 0 && Object.keys(filter_info).forEach(f => {
        filter_info_to_be_saved.push(filter_info[f])
    })

    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.FILTERS.UPDATE_REPORT_FILTER;
    const post = {
        filter_info: filter_info_to_be_saved
    };
    simpleDispatch_post(dispatch, url, post, actionTypes.UPDATE_REPORT_FILTER, true);

    dispatchAction(dispatch, actionTypes.UPDATE_REPORT_FILTER_INFO, {
        report_id: id,
        filter_info: filter_info
    })

}


export const apply_link_filter = (insight_id, report_id, filters) => async dispatch => {
    dispatchAction(dispatch, actionTypes.APPLY_LINK_FILTER, {
        insight_id,
        report_id,
        filters
    })
}
export const clear_link_filter = (insight_id, report_id, clear_all) => async dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_LINK_FILTER, {
        insight_id,
        report_id,
        clear_all: clear_all
    })
}





export const get_advance_date_config = (report_id) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.GET_ADVANCE_DATE_CONFIG;

    const post = { report_id: report_id };

    simpleDispatch_post(dispatch, url, post, actionTypes.GET_ADVANCE_DATE_CONFIG_SUCCESS, true);

}


export const save_advance_date_config = (report_id, report_item_id, advance_date_config) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.SAVE_ADVANCE_DATE_CONFIG;

    const post = {

        report_id: report_id,
        report_item_id: report_item_id,
        advance_date_config: advance_date_config
    };

    simpleDispatch_post(dispatch, url, post, actionTypes.SAVE_ADVANCE_DATE_CONFIG_SUCCESS, true);

}


export const delete_advance_date_config = (report_id, report_item_id, advance_date_config) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.SAVE_ADVANCE_DATE_CONFIG;

    const post = {

        report_id: report_id,
        report_item_id: report_item_id,
        advance_date_config: advance_date_config
    };

    simpleDispatch_post(dispatch, url, post, "DELETE", true);

}