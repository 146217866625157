// Tooltip.js
import React from 'react';

const Tooltip = ({ visible, position, content }) => {
  
  if (!visible) return null;

  const tooltipStyle = {
    position: 'fixed', // Fixed position to avoid movement during scrolling
    top: position.y,
    left: position.x,
    background: '#fff',
    color: '#000',
    padding: '8px 10px',
    borderRadius: '5px',
    fontSize: '.9rem',
    zIndex: 1000,
    fontWeight: 500,
    cursor: 'text', // Show text cursor for selection
    userSelect: 'text', // Allow text selection
    textAlign: 'left',
    LineHeight: '1.3rem',
    maxWidth: '800px',
    boxShadow: '2px 3px 4px #ccc'
  };

  return <div style={tooltipStyle}>{content}</div>;
};

export default Tooltip;
