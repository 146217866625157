import * as actions from '../action-types/success';

const initialState = {
    type: actions.SUCCESS_INT,
    success: undefined,
    downloadingStatus: undefined,
};

const successReducer = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        case actions.SHOW_SUCCESS:
            return {
                type: action.type,
                success: action.data,
            };

        case actions.HIDE_SUCCESS:
            return {
                type: action.type,
                success: undefined,
            }
        case actions.SHOW_FILE_DOWNLOADING_STATUS:
            return {
                type: action.type,
                downloadingStatus: action.data
            }
        case actions.HIDE_FILE_DOWNLOADING_STATUS:
            return {
                type: action.type,
                downloadingStatus: undefined
            }
        default:
            return {
                ...state,
            };
    }
};

export default successReducer;