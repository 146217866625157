import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { constants } from '../../utils/constants';
import * as util from '../../utils'
import axios from 'axios';

const ExcelSheetList = (props) => {



    const [workSheets, setWorkSheets] = useState(undefined);
    const [selectedSheet, setSelectedSheet] = useState(undefined);
    const [localUploadedFileList, setLocalUploadedFileList] = useState(undefined);

    const handleFileUpload = event => {
        // console.log("event.target.files", event.target.files)
        const files = event.target.files;
        const file = files?.[0];


        if (file) {
            const reader = new FileReader();

            reader.onload = e => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const excelData = XLSX.utils.sheet_to_json(worksheet);
                setWorkSheets(workbook.SheetNames)
                setSelectedSheet(workbook.SheetNames)
                setLocalUploadedFileList(files)
            };

            reader.readAsBinaryString(file);
        }
    };


    const on__change = (value) => {

        const clone_sheet = selectedSheet ? [...selectedSheet] : [];

        if (clone_sheet.indexOf(value) > -1) {
            // let's remove it 
            clone_sheet.splice(clone_sheet.indexOf(value), 1);
        }
        else {
            clone_sheet.push(value)
        }

        setSelectedSheet(clone_sheet)
    }




    const file_upload_to_server = () => {


        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
            },
            headers: util.getMandatoryRequestHeaders(undefined, "xls_data", "saroj_nf_test", undefined, undefined, "xls"),
            id: Math.random()
        }
        const data = new FormData();


        for (var x = 0; x < localUploadedFileList.length; x++) {
            data.append('nf_uploader', localUploadedFileList[x]);
        }

        data.append("workbook_sheets", selectedSheet)

        // data.append('refresh_data', refreshData);
        // data.append("file_seperator", (file_seperator || ''))

        const url = constants.END_POINTS.API + constants.END_POINTS.PDF_TO_QUESTION_ANSWER.SEND_FILE_TO_UPLOAD;


        axios.post(url, data, options).then(res => {


        })

    }



    return (
        <div>
            <input type="file" onChange={handleFileUpload} />

            <br>

            </br>

            {workSheets?.length > 0 && (
                <ul>
                    {workSheets.map((sheet) => {
                        return <li>
                            <input type="checkbox" onChange={((e) => on__change(sheet))} checked={selectedSheet?.indexOf(sheet) > -1} />
                            &nbsp;&nbsp;&nbsp;
                            {sheet}
                        </li>
                    })}
                </ul>
            )}


            <button onClick={file_upload_to_server}>UPLOAD</button>
        </div>
    );
}

export default ExcelSheetList;
