import * as actions from '../action-types/menu.config.righter';

const initialState = {
    type: actions.MENU_CONFIG_INIT,
    report_menu_cache: {},
};

const menuConfigRighter = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        case actions.MENU_CONFIG_RAISE:

            const clone_menu_cache = state.report_menu_cache ? JSON.parse(JSON.stringify(state.report_menu_cache)) : {};
            const report_id = action?.data?.report_id;
            clone_menu_cache[report_id] = {
                title: action.data.title,
                menu_render_mode: action.data.type,
            }

            return {
                type: action.type,
                report_menu_cache: clone_menu_cache
            };

        case actions.MENU_CONFIG_HIDE:
            const clone_menu_cache_one = state.report_menu_cache ? JSON.parse(JSON.stringify(state.report_menu_cache)) : {};
            const report_id_one = action?.data?.report_id;
            clone_menu_cache_one[report_id_one] = undefined;

            return {
                type: action.type,
                report_menu_cache: clone_menu_cache_one,
            };

        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
            return {
                ...state
            };
    }
};

export default menuConfigRighter;