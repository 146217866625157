import React from "react";

const MongoDbIcon = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      shape-rendering="geometricPrecision"
      text-rendering="geometricPrecision"
      image-rendering="optimizeQuality"
      fill-rule="evenodd"
      clip-rule="evenodd"
       viewBox="0 0 2048 2048"
    >
      <path
        transform="translate(463,2)"
        d="m0 0h1124l32 2 32 4 31 6 24 6 25 8 24 9 25 11 27 14 20 12 15 10 18 13 10 8 17 14 15 14 22 22 7 8 11 13 15 20 12 17 13 21 10 18 14 28 11 27 11 33 8 32 5 27 4 33 1 13v1160l-3 32-5 33-7 30-9 30-12 31-11 24-14 27-16 26-8 11-10 14-13 16-12 14-15 16-11 11-8 7-11 10-11 9-13 10-20 14-21 13-18 10-15 8-22 10-23 9-27 9-31 8-38 7-31 4h-1174l-44-6-31-6-30-8-29-10-26-11-29-14-24-14-24-16-16-12-14-11-10-9-8-7-15-14-16-17-13-15-12-15-13-18-13-20-11-19-12-23-11-24-10-27-8-25-7-28-5-26-5-42-1-15v-1144l4-39 5-32 8-34 10-32 11-28 16-33 12-22 16-25 14-19 8-10 9-11 9-10 11-12 19-19 8-7 14-12 13-10 18-13 20-13 21-12 23-12 25-11 30-11 32-9 27-6 35-5 25-2z"
        fill="#CFFBD3"
      />
      <path
        transform="translate(1026,215)"
        d="m0 0 2 1 4 13 8 20 10 18 12 19 10 15 11 13 8 7 12 13 9 10 11 11 7 8 12 14 12 17 11 14 14 19 10 15 12 22 13 23 13 26 13 30 15 36 12 35 9 35 8 34 6 37 4 40 1 15v53l-2 28-3 27-6 35-6 27-7 27-5 16-9 25-11 24-22 44-8 14-13 20-9 14-13 18-7 9-11 12-10 12-9 11-9 8-8 7-15 15-4 1-1 4-6 7-9 6-10 6-6 5-6 10-6 15-3 11-3 30v89l-12-1-16-4-2-5-6-64-5-35-5-21-5-13-5-6-15-11-11-9-15-14-16-17-8-8-8-7-9-9-9-11-10-12-11-14-12-15-11-15-13-22-17-26-11-21-25-55-7-19-9-31-9-39-6-35-3-26-1-22v-42l2-38 3-27 6-39 8-46 7-29 12-36 13-34 11-28 10-23 13-23 12-22 12-20 13-19 5-4 9-13 11-18h3l1-4h2l1-5 5-1 6-8 6-7 5-6 4-2 4-8 5-4 3-3 5-4 2-4 5-2 5-7 5-2 7-8 3-4h3v-2l4-3 3-5 2 2 7-8 5-7 6-15z"
        fill="#59A451"
      />
      <path
        transform="translate(1338,1499)"
        d="m0 0h84l16 2 17 5 14 6 16 10 14 11 7 8 6 9 6 17 1 7 2 34-1 20-2 17-3 7-2 9-4 10-4 1-2 6-2 4-8 1v4l-9 1v8h-6v2l-4 2 1 3-7 1v2l-7 3 5 1-4 2-18 6-8 2-15 3-11 1h-103l-5-2 1-14 2-3 18-2 3-2v-2l-4 2h-3v-3l7-1 1-4v-164l-1-4-28-7-3-3v-8l6-7z"
        fill="#947754"
      />
      <path
        transform="translate(1591,1499)"
        d="m0 0h60l21 2 15 5 14 8 9 8 5 10 1 4v22l-4 16-6 10-8 7-10 6-1 2 16 4 12 6 10 11 8 14 3 9v18l-4 11-6 11-7 5-2 4v2l-5 4-4 6-5 2-1 2-3 1v2l-5 1-2 2-12 3v3l-22 3-15 1h-62l-31-1-3-1v-13l3-2v11l3-1 1-12 17-3v-1h-9v-2l9-2 1-88 1-60h1l1 25 1 122h2v-149l-1-22-10-4-17-3-3-4v-8l2-5 3-1z"
        fill="#947755"
      />
      <path
        transform="translate(1006,1574)"
        d="m0 0h21l17 3 17 4v2h8v2l14-1 11-2v-2h2l1-3 3-1 2 5v12l-2 7-7 5-3 1h-18l1 3v29l-1 9h-2l-1 6-7 1-1 3h6l-2 3-5 3h-8l1 2 5 1v2h-5l-2 3-11 2 3 2-1 3-9 2-10 2-16 2-7 1-2 11h10l37 2 12 2-2 2-10-1h-42l22 2h34l15 2v2h9l1 2-1 2h-2v-2l-4 2 6 2v2h7l2 1-1 4-5-1 1 3h6l2 8v31l-2 9-3 2-4-1v3h-3l-1 2-2-1-1 3 4 1v2l-7 4h-3v3h2v2l-6 2-1 2-3 1 3 1v2h-2v2h-5l-1 2-7 2-12 2-2 1-10 1h-43l-16-5-9-6-5-5-5-14-1-5v-18l2-6 3-2 7-1-1-5 4-3h5l2-7 9-1-1-6-6-2v-2l4-1v-3h-4v-2h-6l-4-4-1-12 1-3 7-2 1-5 13-3 1-4-10-3-11-9-6-9-4-12-2-10v-17l4-13h2v-2l2-1 3-7 8-8 11-7 6-4z"
        fill="#493024"
      />
      <path
        transform="translate(1189,1573)"
        d="m0 0h19l12 2 8 3 10 4 3 3 7 3 7 8 5 5 3 6 5 5 4 20v24l-2 18-3 10-4 1v-3h-2v4h-2v8l-2 2h-3v2h-2v3h-2l1 4-6 2 1 3-5 1-1 2-12 5v3l-12 4-10 2h-16l-18-2-9-3-10-3-10-10v-2h-2l-4-5v-2h-2l-7-11-5-9-2-3-2-14v-28l2-11 1-3 4-4 2-5 3-5 5-1 2-5 2-1 2 1v-2l3-2 1-4 3-2 3 1-1-4 3-4h7l2-5 19-5z"
        fill="#482E22"
      />
      <path
        transform="translate(1373,1519)"
        d="m0 0h25l20 2 18 6 13 7 11 9 9 12 8 16 3 9 2 9 1 13v31l-2 13-2 7h-1v-17l-4 25-7 19-3 1h-4l-2 5-9 3-1 1 2 2-6 3h-2v-2h-2v3l-6 5-21 3h-13l-19-2v-4l-9-3-1-1-1-7-1-117-1 31-1 86h-3l-1-7v-139l1-15 4-1 2-5z"
        fill="#CFFBD3"
      />
      <path
        transform="translate(472,1574)"
        d="m0 0h19l7 2 6 3v3l6 1 8 10 9 15 3 13 1 83 13 2v3l8 1-1 4-1 5 1 4-1 2h-71l-4-2v-9l2-7 1-1 17-2v-80l-3-14-17-7-6-1h-10l-19 6-6 1v96l11 2 4 4 6 3 1 2v6l-3 2h-69l-4-1v-9l1-1 14-1 3-1-9-2 1-2 14-2h3l-1-88h-6l-1-9-5-1-13-5-4-1h-7l-10 2-8 3-10 2 1 98 19 1 2 7-1 10-3 1h-34l-36-1-3-1 1-8 7-4 3-1v-3l12-5v-96h-6l-9-4-9-3v-12l8-3 20-4 10-1 14-4h3v25l3-3 3-5 6-2v-2l5-2v-2l8-4 14-5h21l4 3 7 2v2l7 2 1 4h2v3h2l2 4v3l6-1 1-2h2l1-3 5-2v-2l3-1 3-3 14-6z"
        fill="#472E22"
      />
      <path
        transform="translate(638,1573)"
        d="m0 0h23l17 4 16 8 4 4v2l7 5 4 7v2h2l5 8 3 9 1 11v39l-1 7h-4l1-8h-2l-2 11-1 6-4 2-1 2 4-1v2l-5 4-1 6-8 3-1 4-1 2h-7l-2 4-4 1 3 2-8 3-16 5h-30l-14-3-8-4v-2h-2l-2-3-6-3v-2h-2l-5-5-3-2-3-7-3-3-6-13-2-9-1-21v-19l3-14h3v-4l2-4 3-2 6-11 9-7 6-4h2v-2l24-9z"
        fill="#493024"
      />
      <path
        transform="translate(805,1572)"
        d="m0 0 2 1v21h3v-2l5-2 1-2 3-1 5-5 4-1v-2l10-5 9-1h21l11 4 6 4 5 5 6 7 6 14 2 14v83l22 1 1 1 1 13-2 5-3 1h-71l-2-1v-15l1-3 16-1-1-66-1-23-2-8-5-3-11-3h-12l-16 4-11 3v95l20 3 2 12v5l-1 1h-75l-2-3 1-16 11-2 7-1 1-5 1-91-10-2-1-1-7-1-9-4v-13l5-2 31-6 13-3h7v-2z"
        fill="#493024"
      />
      <path
        transform="translate(1032,1145)"
        d="m0 0 3 1 3 20 4 32 8 21 5 10 10 13 6 7 3 4-7 14-5 16-2 11-2 22v89l-12-1-16-4-2-5-6-64-5-35-5-21-5-13-5-7 4 2 3 2 5-10 4-14 6-29 3-24 2-30z"
        fill="#93A578"
      />
      <path
        transform="translate(1184,1592)"
        d="m0 0h15l7 3 8 3 4 3v2l3 1v2l4 2v2h2l3 15 3 10 1 11v-7l2-4 2 1 1 10v37l-4 3-1-14v-22l-1 27-2 9-1 10-2 1-2 5-2 2-16 3-7 1-11-3-2-1-5-1-3-5-6-2-3-4-1-5-5-1-3-8-2-6 1-2h-4v-3l-3-1v-9l-1-6v-34h7v-4l-5 1 2-5 5-2v-4l9-3v-2l5-2 4-3z"
        fill="#CFFBD3"
      />
      <path
        transform="translate(628,1592)"
        d="m0 0h21l9 3 5 5 7 6 3 1 2 3v3h2l1 4 4 1v5l2 1 1 6 1 16v26l-1 19-3 6-7-1 1 6-3 3-6 1-10 2-6-1-16-2-5-2v-5l-6-2-5-2v-4h2v-2l-4-2-2-4v-3l-4 1-3-9-1-17v-31l1-15h6l1-3 3-3 3-1 1-3-3-1 3-3h6z"
        fill="#CFFAD2"
      />
      <path
        transform="translate(1615,1609)"
        d="m0 0 44 1 6 1 1 3-6-1 1 2 14 3 3 3 3 5 2 1 4 8 3 2 4 18v9l-2 17-1 3-5 2-3 1-3 8-5 3-6 1-1 2 7-1v2l-8 1-11 1h-21l-10-1-1-3h7l-6-2-1-1-8-1-1-2-1-22v-62z"
        fill="#CFFBD3"
      />
      <path
        transform="translate(997,1728)"
        d="m0 0h37l20 3 6 3v2l6 2 2 2 3 10v16l-3 8-6-2-3 9-1 2h-7l-4 4-17 3-10 1-13-2-2-1v-2l-5-1-9-2-5-4-1-4-4-5-2-10 5-21h3l1-6h2v-2l7-1z"
        fill="#CFFAD2"
      />
      <path
        transform="translate(1618,1520)"
        d="m0 0h19l17 3 14 5 7 6 4 9 2 11v9l-3 14-3 6-8 7-1 2-5 1h-47v-71z"
        fill="#D0FCD4"
      />
      <path
        transform="translate(1e3 1591)"
        d="m0 0h18v2l6 1 1 1v5h2l2 4 5 2 2 2 1 7v34l-2 4h-5l-3 8-7 2-15-1-6-2-2 1-4-4 2-2-2-2-7-2-1-6h-2l-2-6v-31l4-6 5-2v-2l-2-1 3-2 2-2z"
        fill="#CFFBD3"
      />
      <path
        transform="translate(519,1596)"
        d="m0 0 3 3 4 8 3 8 1 6 1 83 13 2v3l8 1-1 4-1 5 1 4-1 2h-71l-4-2v-9l2-7 1-1 8-1h9v1l-10 1 1 5-9 1v9l69 1 1-10h-7v-5l-10-2-2-3-5 1-1-9 1-78-2-12z"
        fill="#8E7251"
      />
      <path
        transform="translate(399,1613)"
        d="m0 0 1 4 4-1 3 3v86l-9 2-8 1 9 2-1 2-3 1-14 1-1 9h73l3-1-2-8-7-3-2-4-11-1-1-1v-9h1l1 8 18 2 6 3 1 9-2 5-2 2h-75l-2-1v-11l2-7 19-1z"
        fill="#93A175"
      />
      <path
        transform="translate(1368,1563)"
        d="m0 0h1l1 7 1 117 1 7 9 3 2 2h-2v3l19 1h13l1 2h-17l-13-1-6-2v-4l-7-2-1-3h-5l-2-5v-8h1v7h3v-86z"
        fill="#94A478"
      />
      <path
        transform="translate(1127,1614)"
        d="m0 0 1 4-2 17v11h-2v2h-2l-1-8v19l1 11 1-3-1-9h2v-3l-2-1 1-3h2l1 4 1 21 3 9 4 4 3 5 1 5 7 8 4 5 4 1v2h5l1 2h-5l2 2-4-1-9-9v-2h-2l-4-5v-2h-2l-7-11-5-9-2-3-2-14v-28l2-11 1-3 4-4z"
        fill="#949169"
      />
      <path
        transform="translate(1573,1549)"
        d="m0 0h1l1 25 1 122h3v2h-6v2h7v3h-9v-2h-9v-2l9-2 1-88z"
        fill="#94A679"
      />
      <path
        transform="translate(871,1620)"
        d="m0 0h2l1 5 1 28v50h-4l-1-9v-69z"
        fill="#947755"
      />
      <path
        transform="translate(529,1671)"
        d="m0 0h1l1 33 13 2v3l8 1-1 4-1 5 1 4-1 2h-71l-4-2v-9l2-7 1-1 8-1h9v1l-10 1 1 5-9 1v9l69 1 1-10h-7v-5l-10-2-1-1-1-17z"
        fill="#949269"
      />
      <path
        transform="translate(777,1608)"
        d="m0 0 4 1v84l-1 2h-2l-1-8-1-78z"
        fill="#947F5A"
      />
      <path
        transform="translate(310,1613)"
        d="m0 0h1v89l-2 2-11 3v3l-7 5h-3l-1 3v-7l1-3 14-4 1-4 1-59h1l1 8 1 48h2z"
        fill="#93A074"
      />
      <path
        transform="translate(403,1618)"
        d="m0 0h2l1 2 1 13v61l-1 10-2-2-1-6z"
        fill="#D0FCD4"
      />
      <path
        transform="translate(341,1609)"
        d="m0 0h1l1 97 19 1 2 7-1 10-3 1h-34l-36-1v-1h68l3-1v-6l-1-2-15-4-4-5z"
        fill="#949A70"
      />
      <path
        transform="translate(905,1704)"
        d="m0 0 21 1 1 1 1 13-2 5-3 1h-71l-2-1 1-3v2h62l8 1v-2l3-1-2-1-2-8v-5l-13-1z"
        fill="#949068"
      />
      <path
        transform="translate(1231,1631)"
        d="m0 0 2 4 1 11v-7l2-4 2 1 1 10v37l-4 3-1-14v-22l-1 27h-1z"
        fill="#919268"
      />
      <path
        transform="translate(399,1613)"
        d="m0 0 1 4 4-1 3 3v14h-1l-1-15h-2l1 78 1 6 1-8h1v11l-3 1-3-1-1-71-1-11z"
        fill="#948D66"
      />
      <path
        transform="translate(1168,1686)"
        d="m0 0 3 1 3 9 7 2 3 5h5l3 2 9 2 7-1 11-2 4 1-9 2-1 1 2 1v2l-6 1v-3l-3-1-1 4h-8l-7-2 2-2-9-3-3-2-5-1-3-6v-2h-5l-1-3 3-2z"
        fill="#92966C"
      />
      <path
        transform="translate(866,1573)"
        d="m0 0 11 3 8 5 5 5 6 7 6 14 1 4v10l-1-2h-2v-9l-6-12-9-11-3-3-2-1v-2l-5-1-10-5z"
        fill="#9EA67C"
      />
      <path
        transform="translate(998,1576)"
        d="m0 0 3 1-1 2 10 2 12 6-2 1-18-5-2-2-5 1-7 2-8 6-9 6-4 5-4 1 1-4 8-8 11-7 6-4z"
        fill="#78674A"
      />
      <path
        transform="translate(1445,1715)"
        d="m0 0 4 1-5 2-8 2-15 3-11 1h-103l-4-2h103l21-3z"
        fill="#94A679"
      />
      <path
        transform="translate(1615,1609)"
        d="m0 0 44 1 6 1 1 3-6-1v2l-23-1h-23z"
        fill="#94A679"
      />
      <path
        transform="translate(620,1694)"
        d="m0 0 4 1 7 3v4l4 2 16 2 7 1 7-1-6 6-12 1-8-2v-2l7-1-15-3-5-2-3-3v-3h-2z"
        fill="#9CA47B"
      />
      <path
        transform="translate(1127,1614)"
        d="m0 0 1 4-2 17v11h-2v2h-2l-1-8-1 23h-1v-28l2-11 1-3 4-4z"
        fill="#948F67"
      />
      <path
        transform="translate(985,1775)"
        d="m0 0 2 1v3l4 3 9 2 5 2v2l12 1 4 1 21-3 7-2 1 3-3 1-2-1v2l-9 1-5 1h-16l-16-2-5-2v-3l-5-2-4-2z"
        fill="#91A274"
      />
      <path
        transform="translate(955,1644)"
        d="m0 0 2 3 7 14 15 12 2 3 3 1 1 4-10-4-10-8-6-9-4-12z"
        fill="#94986E"
      />
      <path
        transform="translate(1523,1577)"
        d="m0 0h1l2 16v33l-1 11h-2l-1-33z"
        fill="#94A679"
      />
      <path
        transform="translate(529,1671)"
        d="m0 0h1l1 33 13 2v3l8 1-1 4-1 5 1 4-1 2-4-2 1-10h-7v-5l-10-2-1-1-1-17z"
        fill="#939B70"
      />
      <path
        transform="translate(1232,1580)"
        d="m0 0 6 2 3 3 7 3 7 8 5 5 3 7-5-2v-3l-5-2-3-3-2-4-10-7-3-3-4-2z"
        fill="#95906C"
      />
      <path
        transform="translate(638,1573)"
        d="m0 0h23l17 4 16 8 4 4-4 1-8-5v-2l-7-1-3-2-9-2-10-3-19-1z"
        fill="#908964"
      />
      <path
        transform="translate(905,1704)"
        d="m0 0 21 1 1 1 1 13-1 4h-6l3-2-2-1-2-8v-5l-13-1z"
        fill="#907B57"
      />
      <path
        transform="translate(433,1696)"
        d="m0 0h1l1 8 18 2 6 3 1 9-3-2v-2l-6-1-5-2-1-4-11-1-1-1z"
        fill="#95A277"
      />
      <path
        transform="translate(1266,1610)"
        d="m0 0 2 2 4 20v24l-1 12h-1v-16l-1-15-1-21-4 1-2-4h3z"
        fill="#948E66"
      />
      <path
        transform="translate(1050,1731)"
        d="m0 0 7 1 3 2v2l6 2 2 2 3 10v16l-2 7h-1l1-8v-12l-1-6-3-1 1-5-7-2-6-2z"
        fill="#8E9A6C"
      />
      <path
        transform="translate(1123,1651)"
        d="m0 0h2l1 4 1 23-5 1-2-8v-12h1l1 11 1-3-1-9h2v-3l-2-1z"
        fill="#908D65"
      />
      <path
        transform="translate(890,1588)"
        d="m0 0 4 2 7 14 2 7v10l-1-2h-2v-9l-6-12-4-6z"
        fill="#918962"
      />
      <path
        transform="translate(1615,1609)"
        d="m0 0 35 1 8 1-1 2-42-1z"
        fill="#D0FCD4"
      />
      <path
        transform="translate(1099,1729)"
        d="m0 0 3 1v23l-2 5h-2v-28z"
        fill="#918861"
      />
      <path
        transform="translate(751,1584)"
        d="m0 0h5l-1 3-2 1v10l-4 1-1-2-2 2v-13z"
        fill="#99A77C"
      />
      <path
        transform="translate(584,1695)"
        d="m0 0 3 3 1 5 4 2 4 3v2h2v2l6 2 2 4 2 1-5-1-6-4v-3l-4 1-4-4-4-7z"
        fill="#969F74"
      />
      <path
        transform="translate(1e3 1700)"
        d="m0 0h31v1l-5 1 17 1v1h-20l-22-2z"
        fill="#A8BE94"
      />
      <path
        transform="translate(663,1706)"
        d="m0 0 2 1-6 5-12 1-8-2v-2h17l1-2z"
        fill="#97986F"
      />
      <path
        transform="translate(1370,1518)"
        d="m0 0h32l8 1v1h-37l-3 1-2 4-3 1-1 15h-1v-17l4-1 2-4z"
        fill="#94A679"
      />
      <path
        transform="translate(956,1790)"
        d="m0 0 7 1 1 3 4 2 6 7-5-1-8-5-5-5z"
        fill="#7D7855"
      />
      <path
        transform="translate(486,1705)"
        d="m0 0h9v1l-10 1 1 5-9 1-1 2v-7l2-2z"
        fill="#95A477"
      />
      <path
        transform="translate(1523,1577)"
        d="m0 0h1l2 16v14h-4z"
        fill="#94A276"
      />
      <path
        transform="translate(1370,1694)"
        d="m0 0 11 3 2 2h-2v3l19 1h13l1 2h-17l-13-1-6-2v-4l-7-2z"
        fill="#94A679"
      />
      <path
        transform="translate(1681,1714)"
        d="m0 0h6l-3 2-4 2v2h-23l2-2 19-3z"
        fill="#94A679"
      />
      <path
        transform="translate(1099,1576)"
        d="m0 0 2 1 1 4v12l-2 7h-5v-2h3l-1-11h2l1 4v-7l-1-4h-3l1-3z"
        fill="#8D7453"
      />
      <path
        transform="translate(1033,1700)"
        d="m0 0h17l7 2v1l-12 1-17-1-1-2z"
        fill="#E7FDE9"
      />
      <path
        transform="translate(982,1586)"
        d="m0 0 2 1-10 7-6 5-3 3h-2l1-4 8-8 6-2z"
        fill="#9EAC82"
      />
      <path
        transform="translate(1048,1785)"
        d="m0 0 2 3-3 1-2-1v2l-9 1-5 1h-16l4-2 19-2 9-2z"
        fill="#94946B"
      />
      <path
        transform="translate(1236,1645)"
        d="m0 0 2 2v24l-1 8h-1l-1-26v-7z"
        fill="#D0FCD4"
      />
      <path
        transform="translate(455,1714)"
        d="m0 0h2l2 3v5l-3 3h-35v-1l32-1 3-1z"
        fill="#94956B"
      />
      <path
        transform="translate(1125,1677)"
        d="m0 0 3 3 2 5 4 4 2 4h-6l-7-14z"
        fill="#95966F"
      />
      <path
        transform="translate(519,1596)"
        d="m0 0 3 3 4 8 2 5v3h-2v2h-3l-2-12z"
        fill="#94835E"
      />
      <path
        transform="translate(290,1707)"
        d="m0 0h8v3l-7 5h-3l-1 3v-7l1-3z"
        fill="#918660"
      />
      <path
        transform="translate(963,1663)"
        d="m0 0 4 3v2l3 1 3 4 5 2 1-2 2 3 3 1 1 4-10-4-10-8-2-3z"
        fill="#877756"
      />
      <path
        transform="translate(1243,1706)"
        d="m0 0 2 1 1 3-5 1-1 2-12 5v3l-5 2-4-1 5-2 2-4 6-3 4-1h3l1-4z"
        fill="#949269"
      />
      <path
        transform="translate(1703,1582)"
        d="m0 0h3l-2 4-9 7-5 3-2-1 8-8z"
        fill="#94A477"
      />
      <path
        transform="translate(786,1577)"
        d="m0 0h4v2l-12 3-15 2h-7v-1l26-5z"
        fill="#938962"
      />
      <path
        transform="translate(684,1685)"
        d="m0 0 1 4-2 7h-4-3l3-9h5z"
        fill="#95A074"
      />
      <path
        transform="translate(1168,1686)"
        d="m0 0 3 1 3 9 6 1-2 5-4-1-2-7h-5l-1-3 3-2z"
        fill="#827756"
      />
      <path
        transform="translate(1478,1626)"
        d="m0 0h1v32l-2 2-2-3 1-13z"
        fill="#93A073"
      />
      <path
        transform="translate(968,1735)"
        d="m0 0 2 3-7 2-1 3v3l-9 3 1-4 5-5 4-3h5z"
        fill="#959E73"
      />
      <path
        transform="translate(1180,1698)"
        d="m0 0 3 2 1 3h5l3 2 9 2v2l-8-1-10-3-3-2-3-1z"
        fill="#948862"
      />
      <path
        transform="translate(682,1714)"
        d="m0 0 4 1-5 2 3 2-8 3-10 3-3-1 11-4 1-3z"
        fill="#94936A"
      />
      <path
        transform="translate(963,1703)"
        d="m0 0 2 4 1 5 8 2v2h4v3l-6 2-3-2v-2l4 1-2-2-4-1-4-4z"
        fill="#979970"
      />
      <path
        transform="translate(602,1625)"
        d="m0 0h1l1 26-3 1-1-12z"
        fill="#8C9769"
      />
      <path
        transform="translate(1027,1698)"
        d="m0 0h18l12 2-2 2-10-1h-10l-8-2z"
        fill="#89835E"
      />
      <path
        transform="translate(500,1578)"
        d="m0 0 4 1v3l6 1 8 10v3l-6-5-3-5v-2l-6-1-6-2v-2z"
        fill="#948D66"
      />
      <path
        transform="translate(1697,1650)"
        d="m0 0 3 3v21h-2l-1-3z"
        fill="#97AA7E"
      />
      <path
        transform="translate(999,1578)"
        d="m0 0 11 3 12 6-2 1-18-5-4-4z"
        fill="#947654"
      />
      <path
        transform="translate(979,1750)"
        d="m0 0h2l-1 17-5-1-1-3 4-5 1-4h-3l1-3z"
        fill="#918B63"
      />
      <path
        transform="translate(613,1581)"
        d="m0 0 3 1-4 4-8 5-3 1v-2h2l1-4 3-1v-2z"
        fill="#7E7253"
      />
      <path
        transform="translate(1548,1706)"
        d="m0 0h6v11l-4 1-1-10z"
        fill="#AAC69B"
      />
      <path
        transform="translate(1046,1617)"
        d="m0 0 2 2v23h-2l-1-24z"
        fill="#8C9164"
      />
      <path
        transform="translate(638,1573)"
        d="m0 0h23l9 2 2 3-8-1-7-2-19-1z"
        fill="#948E66"
      />
      <path
        transform="translate(1065,1792)"
        d="m0 0 3 2-3 1 3 1v2h-2v2h-5l-1 2h-5l4-1v-6z"
        fill="#949870"
      />
      <path
        transform="translate(1050,1669)"
        d="m0 0 1 2-11 2 3 2-1 3-2-1-3-1-4-1 1-3 6-2z"
        fill="#949068"
      />
      <path
        transform="translate(1123,1651)"
        d="m0 0h2l1 4v14l-3-2-1-9h2v-3l-2-1z"
        fill="#947653"
      />
      <path
        transform="translate(576,1617)"
        d="m0 0 3 2-1 4 1 1-2 4-2-1-1 15h-1v-11z"
        fill="#948D66"
      />
      <path
        transform="translate(676,1613)"
        d="m0 0 2 4 4 1v5l2 1v6l-2-3-3-1-3-10z"
        fill="#9DA77D"
      />
      <path
        transform="translate(381,1707)"
        d="m0 0h9v1l9 2-1 2-6 1 2-1v-2l-9 1-4-1z"
        fill="#947553"
      />
      <path
        transform="translate(1204,1595)"
        d="m0 0 8 2 6 4v2l3 1-3 1-9-6-6-2z"
        fill="#9AA379"
      />
      <path
        transform="translate(1232,1580)"
        d="m0 0 6 2 3 3 6 2v2h-7l-5-5-4-2z"
        fill="#7F7655"
      />
      <path
        transform="translate(403,1576)"
        d="m0 0 10 1 5 5-1 2-5-2v-2l-7-1z"
        fill="#979972"
      />
      <path
        transform="translate(905,1704)"
        d="m0 0 21 1-2 1-1 7-2-2-1-4-13-1z"
        fill="#9EAF84"
      />
      <path
        transform="translate(691,1656)"
        d="m0 0h2v13l-1 7h-1l-1-5v-12z"
        fill="#94825D"
      />
      <path
        transform="translate(674,1697)"
        d="m0 0 2 3-2 4-7 2-2-1 4-5z"
        fill="#95A074"
      />
      <path
        transform="translate(966,1664)"
        d="m0 0 4 2 8 6v3l-6-2-6-7z"
        fill="#9FB186"
      />
      <path
        transform="translate(1014,1665)"
        d="m0 0 8 2v-2l4 1v2l-8 1h-8l-2-1v-2z"
        fill="#8E9A6B"
      />
      <path
        transform="translate(1090,1579)"
        d="m0 0 3 1 1 2-11 3h-14v-1l13-1z"
        fill="#959A70"
      />
      <path
        transform="translate(1092,1717)"
        d="m0 0 2 4 1 9h-1l-1-8-6 1-2-4 5-1 1 1z"
        fill="#A5B48B"
      />
      <path
        transform="translate(985,1775)"
        d="m0 0 2 1v3l4 3 6 1v2l2 1-3 3-2-1v-3l-5-2-4-2z"
        fill="#928961"
      />
      <path
        transform="translate(1650,1610)"
        d="m0 0h9l6 1 1 3-6-1v2h-7l-1-2 6-2h-8z"
        fill="#98AC7F"
      />
      <path
        transform="translate(620,1694)"
        d="m0 0 4 1 7 3v4l-8-2v-3h-2z"
        fill="#8A8862"
      />
      <path
        transform="translate(1728,1626)"
        d="m0 0 3 4 3 6-1 4-4-5-2-3z"
        fill="#94A679"
      />
      <path
        transform="translate(751,1584)"
        d="m0 0h5l-1 3-4 2-3-1-1 11h-1v-13z"
        fill="#8E7956"
      />
      <path
        transform="translate(1216,1704)"
        d="m0 0h7l-2 2-7 1-1 1 2 1v2l-6 1v-3l-2-2z"
        fill="#948761"
      />
      <path
        transform="translate(1156,1667)"
        d="m0 0 1 3h4l2 10-6-2v-6z"
        fill="#938F68"
      />
      <path
        transform="translate(1251,1597)"
        d="m0 0 5 2v2h2l1-2 4 8h-3l-2-1v-3l-5-2z"
        fill="#A8BB91"
      />
      <path
        transform="translate(714,1671)"
        d="m0 0h2l1 3-1 5 2 1-1 3h-2l-2-2-2 1 2-8z"
        fill="#989C73"
      />
      <path
        transform="translate(1144,1707)"
        d="m0 0 5 5 4 1v2h5l1 2h-5l2 2-4-1-8-8z"
        fill="#948862"
      />
      <path
        transform="translate(1179,1592)"
        d="m0 0h5l-4 2-5 4-3 2-4 2 2-5 3-3z"
        fill="#929067"
      />
      <path
        transform="translate(1295,1507)"
        d="m0 0h1v6l6 1 4 3-8 1-3-3z"
        fill="#94A679"
      />
      <path
        transform="translate(486,1705)"
        d="m0 0h9v1l-10 1v2l-8 1 1-4z"
        fill="#948962"
      />
      <path
        transform="translate(1305,1705)"
        d="m0 0h10v1l-7 1 3 3-1 4h-2l-3-6z"
        fill="#94A679"
      />
      <path
        transform="translate(1122,1636)"
        d="m0 0h3l1 10h-2v2h-2z"
        fill="#947553"
      />
      <path
        transform="translate(450,1586)"
        d="m0 0 1 2-11 6h-5l4-4h2v-2l3 1z"
        fill="#897957"
      />
      <path
        transform="translate(987,1580)"
        d="m0 0 2 1-3 5-8 3h-3l9-7z"
        fill="#8D8660"
      />
      <path
        transform="translate(1079,1707)"
        d="m0 0 5 1-1 3h-2v-2l-4 2v1h-5l-1-3 1-1z"
        fill="#8F8B65"
      />
      <path
        transform="translate(1616,1696)"
        d="m0 0h8l2 2 6 1-1 2-7-1 1 3h-5l3-5-7-1z"
        fill="#94A679"
      />
      <path
        transform="translate(1e3 1591)"
        d="m0 0 4 1-8 3v2l-2 1v2l-3 1-2-1 2-4 5-4z"
        fill="#948F67"
      />
      <path
        transform="translate(866,1573)"
        d="m0 0 11 3 1 2-5 1-8-4z"
        fill="#91845F"
      />
      <path
        transform="translate(1050,1731)"
        d="m0 0 5 1v2l4 1-2 3-4-1z"
        fill="#A6BD93"
      />
      <path
        transform="translate(1099,1576)"
        d="m0 0 2 1 1 4v12h-1l-1-9-1-4h-3l1-3z"
        fill="#948F67"
      />
      <path
        transform="translate(920,1708)"
        d="m0 0 3 3 2 4v5h-3l-2-8z"
        fill="#94976D"
      />
      <path
        transform="translate(597,1711)"
        d="m0 0 8 4 1 3 2 1-5-1-6-4z"
        fill="#94976D"
      />
      <path
        transform="translate(1236,1635)"
        d="m0 0 2 1v9l-3 1-1-7z"
        fill="#97A478"
      />
      <path
        transform="translate(519,1596)"
        d="m0 0 3 3 4 8 1 4-5-3-2-6z"
        fill="#94946B"
      />
      <path
        transform="translate(1327,1693)"
        d="m0 0h1v9l-2-1v-2l-4 2h-3v-3l7-1z"
        fill="#94A679"
      />
      <path
        transform="translate(1144,1593)"
        d="m0 0 2 1-1 4-3 1v2h-4l2-4z"
        fill="#9AA87C"
      />
      <path
        transform="translate(668,1576)"
        d="m0 0h7l6 3-3 2-9-2z"
        fill="#847A58"
      />
      <path
        transform="translate(1459,1707)"
        d="m0 0 3 1-1 1 5 1-4 2-6 1 1-5z"
        fill="#94A679"
      />
      <path
        transform="translate(445,1707)"
        d="m0 0h6l1 4 2 2-5-1-3-1z"
        fill="#947553"
      />
      <path
        transform="translate(1124,1620)"
        d="m0 0 2 1-1 7-4-1 1-6z"
        fill="#A8C398"
      />
      <path
        transform="translate(1171,1577)"
        d="m0 0 3 1-3 3v-2l-4 1v4l-3-1-1-4z"
        fill="#83805C"
      />
      <path
        transform="translate(611,1719)"
        d="m0 0 9 3 4 3-8-1-6-3z"
        fill="#8F9669"
      />
      <path
        transform="translate(663,1706)"
        d="m0 0 2 1-6 5h-3v-5z"
        fill="#8F976B"
      />
      <path
        transform="translate(1168,1686)"
        d="m0 0 3 1 2 9-1-2h-5l-1-3 3-2z"
        fill="#948A63"
      />
      <path
        transform="translate(1267,1679)"
        d="m0 0 1 3-2 3h-3v-3h-2l-1 4-1-4 5-2z"
        fill="#948A63"
      />
      <path
        transform="translate(956,1790)"
        d="m0 0 5 2 4 6h-3l-6-6z"
        fill="#918862"
      />
      <path
        transform="translate(1062,1772)"
        d="m0 0 4 1 1 2h-2v4h-2l-1-2-2 1 1-4z"
        fill="#968D67"
      />
      <path
        transform="translate(1154,1612)"
        d="m0 0 1 2 5-2 1 3-1 2h-6z"
        fill="#888560"
      />
      <path
        transform="translate(890,1588)"
        d="m0 0 4 2 1 5-2 2-3-5z"
        fill="#917F5B"
      />
      <path
        transform="translate(343,1707)"
        d="m0 0 9 1v2l4 2-7-1-5-2z"
        fill="#947553"
      />
      <path
        transform="translate(1180,1698)"
        d="m0 0 3 2 1 3 4 2h-5l-3-2-3-1z"
        fill="#98A378"
      />
      <path
        transform="translate(978,1673)"
        d="m0 0 3 3 3 1 1 4-9-3v-3h2z"
        fill="#7FA369"
      />
      <path
        transform="translate(992,1652)"
        d="m0 0h5l3 4-5 2-1-5z"
        fill="#918C65"
      />
      <path
        transform="translate(298,1588)"
        d="m0 0 2 1-9 6-1-4z"
        fill="#8A8358"
      />
      <path
        transform="translate(887,1584)"
        d="m0 0h2l2 4v4l-4-2-2-4z"
        fill="#A4B58B"
      />
    </svg>
  );
};

export default MongoDbIcon;
