
import styled from 'styled-components';

import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils';
const selected = getSelectedTheme();

const { header } = themes[selected];

export const QueryWrapper = styled.div`
    width: 100%;
    transition: width 0.2s linear;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    transition: width 0.2s linear;
    box-sizing: border-box;
    position: relative; 
    border-top: 0px;

    .auto_suggestion_box {
        z-index: 12;
        overflow: scroll;
        position: absolute;
        top: 100%;
        left: 0;
        width: inherit;
        z-index: 7px;
        border-radius: 0 0 24px 24px;
        padding: 10px 0px;
        width: 100%;
        left: 0px;
        right: 0px;
        margin: auto;
        box-shadow: 0 4px 6px 0 rgba(32,33,36,0.28);
        padding-top: 5px;
        margin-top: -1px;
        box-sizing: border-box;
        border-top: ${props => props.hints ? '1px solid #eaeaea' : undefined};
        
    &::-webkit-scrollbar {
        width: 0 !important;
    }

    .search_icon{
        margin-right: 14px;
        
        svg{
            width: 17px;
            height: 14px;
        }
      }
    }

    .suggest_item{
        padding: 1px 25px;
        transition: all 0.2s;

        &:hover{
            background: #eaeaea;
        }
    }
`;






export const InputWrapperBox = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: all 0.3s;
    border: ${props => props.hints ? '0px' : 'solid 1px #778a918a'};
    border-radius: ${props => props.hints ? '24px 24px 0px 0px' : '33px'};
    box-shadow: ${props => props.hints ? '0 1px 6px 0 rgba(32,33,36,0.28)' : undefined};
    transition: all 0.3s;
    
    &:hover{
        box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28);
        border-color: rgba(223,225,229,0);
    }

    .clear_question{
        margin-right: .5rem;
        display: flex;
        cursor: pointer;
        
        &:hover{
            svg{
                fill: #0285fc;
            }
        }
    }
    &:focus{
            border: 2px solid #0285fc;
            outline: 2px;
            box-shadow: -2px 1px 3px #ccc;
        }

    input {
        width: 100%;
        border: none;
        outline: none;
        padding: 10px 25px;
        font-size: .9rem;
        box-sizing: border-box;
        background: transparent;
        outline: none;
        color: ${header.color.searchBoxPlacholder};
        position: relative;
        border: 0px;
        height: 40px;
        padding-right: 5px;
        padding-left: 20px;       

        &::placeholder {
            color: ${header.color.searchBoxText};
            font-size: 1rem;
            font-weight: normal;
            text-align: center;
            /* font-family: "DM Sans", sans-serif; */
            transition: all 0.4s;
        }

        &:focus{
            &::placeholder{
                /* color: transparent; */
                transform: scale(0)
            }
        }
    }



    @keyframes listen {
        0% {
            transform: scale(.5);
        }
        10%{
            transform: scale(1);

        }
        25% {
            transform: scale(1.2);
        }
        35% {
            transform: scale(1.4);
        }
        65% {
            transform: scale(1.5);
        }
        80% {
            transform: scale(1.6);
        }
        /* 90% {
            transform: scale(2);
        } */
        100% {
            transform: scale(1.8);
        }
    }

    .search_option{
        width: ${props => props.isMackShow ? '10rem' : '7rem'};;
        position: relative;
        height: 100%;
        display: flex;
        /* padding: 5px; */
        padding-right: 8px;
        box-sizing: border-box;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        
        .icon{
            width: ${props => props.isMackShow ? '33%' : '50%'};
            height: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            box-sizing: border-box;
            &:hover{
                 /* transform: scale(1.1); */
                svg{
                    fill: #166aff;
            }
        }


        &:nth-child(1){
            &:before{
                content: '';
                height: 22px;
                border: .5px solid #ccccccb8;
                position: absolute;
                right: -1px;
            }
        }

        &:nth-child(2){
            padding-left: 5px;
        }

    }




    .voice_btn{
        color: ${header.color.search};
        cursor: pointer;
        transition: all 0.3s;
        position: relative;

        .center_div{
            display: flex;
            background: white;
            width: 25px;
            height: 25px;
            padding: 2px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
        }

        .circle_div{
            display: flex;
            padding: 0px;
            position: relative;
            z-index: 1;
            &::after{
                z-index: -1;
                position: absolute;
                top: 0px;
                left: 0px;
                bottom: 0px;
                right: 0px;
                margin: auto;
                content: '';
                width: 25px;
                height: 25px;
                border-radius: 50%;
                background: #008ac75e;
                animation-name: listen;
                animation-duration: 1s;
                animation-iteration-count: infinite;
            }
        }
        }
    }

`;

