import React, { useState, useEffect, useRef, forwardRef } from 'react';
import styled from 'styled-components';
import AutoHints from '../auto-suggest/auto.hints';
import Tab from '../tab';
import { getHintsByDbInfo, } from '../../actions/hints'
import { connect } from 'react-redux'
import RippleEffect from '../hoc/ripple-effect';
import CloseSvg from '../svg/close';
import RecentSvg from '../svg/recent'
import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils';
import { calculatePositionForSuggestionBox } from '../../utils/calculatePositionAtCursor';
const selected = getSelectedTheme();
const { label, header } = themes[selected];


const HintsWrapper = styled.div`
    position: fixed;
    width: 100vw;
    left: 0px;
    right: 0px;
    margin: auto;
    z-index: 222;
    top: ${props => props.top ? props.top : '142px'};
    display: block;
    transition: all 0.3s;

    .hints_shadow{
        box-shadow: 0 4px 6px 0 rgb(32 33 36 / 28%);
        width: 100%;
        padding-top: .4rem;
        background-color: #fff;
    }
    .hint_row{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: .2rem 3rem;
        background: #fff;
        color: ${header.color.autoSuggestTxt};
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        
        &:hover{
            background: #eaeaea;
        }

    }
    .recent_icon{
        margin-right: 14px;
        svg{
            width: 18px;
            height: 15px;
            fill: ${header.color.autoSuggestIcon}
        }
      }

`;



const Wrapper = styled.div`
    position: fixed;
    width: '100vw';
    height:  ${props => props.isOverlayShow ? props.height ? props.height : "140px" : '0px'};
    transition: all .3s;
    overflow: hidden;
    left: 0px;
    right: 0px;
    top: 0px;
    margin: auto;
    /* bottom: 0px; */
    background-color: #fff;
    z-index: 222;
    top:  ${props => props.isOverlayShow ? "0px" : '-100px'};
    padding: 0px 55px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: baseline;
    box-shadow: 0 2px 6px rgba(0,0,0,.2);
    flex-direction: column;


     .label_top{
        width: 100%;
        text-align: left;
        color: #8e949a;
        font-family: 'Roboto', sans-serif;
        font-size: ${props => props.isLabelShow ? ".9rem" : '0px'};
        transition: all 0.2;
        padding-top: 10px;
        padding-left: 10px;
    }


    input{
        max-width: 100%;
        width: 100%;
        border: 0px;
        text-align: left;
        font-size: 1.3rem;
        /* height: 70px; */
        color: ${label.color};
        box-sizing: border-box;
        overflow-x: auto;

        &:focus{
            border: 0px;
            outline: none;
        }

        ::placeholder {
             color: #8e949a;
             font-family: 'Roboto', sans-serif;
             font-size: 1.5rem;
        }
    } 
`;



const MiniHintWrapper = styled.div`
    position: fixed;
    width: 100vw;
    left: 0px;
    right: 0px;
    margin: auto;
    z-index: 222;
    top: ${props => props.top ? props.top : '86px'};
    display: block;
    transition: all 0.3s;
    max-height: 20rem;
    overflow-x: auto;


    .hints_shadow{
        box-shadow: 0 4px 6px 0 rgb(32 33 36 / 28%);
        width: 100%;
        padding-top: .4rem;
        background-color: #fff;
     }
    .hint_row{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        padding-left: 2.6rem;
    }

    span{
        font-size: 1.3rem;
        padding: .2rem 6px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .prev_hint{
        opacity: 0;
        font-size: 1.3rem;
        max-width: calc(100% - 300px);
    }
    .next_hint{

        background: #fff;
        font-size: .9rem;
        height: 1.5rem;
        line-height: 1.5rem;
        border-bottom: 1px solid #cccccc85;
        color: #333;
        font-weight: 500;
        box-shadow: 0 4px 6px 0 rgb(32 33 36 / 28%);
        &:hover{
            background: #eaeaea;
        }
    }

`;

const SuggestionList = styled.ul`
    position: absolute;
    top: 86px;
    left:  ${props => props?.suggestionBoxPosition?.left + 'px'};
    z-index: 222;
    background: #fff;
    border: 1px solid #e7e7e7;
    list-style-type: none;
    padding: 0;
    margin: 0;
    z-index: 500;
    max-height: 200px;
    overflow-y: auto;
    font-size: 0.8rem;
    transition: all 0.3s;
    max-height: 20rem;
    &::-webkit-scrollbar {
        background-color: #3F3F3F;
        width:4px;
    }

    &::-webkit-scrollbar-track {
        background-color: #F3F3F3;
        border: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dbdbdb;
        border-radius: 10px;
        height: 4px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color:  #3F3F3F;
        height: 4px;
    }

    li {
        width: 220px;
        padding: 8px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-bottom: 1px solid #eaeaea;
        &:hover{
            background-color: #eaeaea;
        }
    }

    .selected{
        background-color: #eaeaea;
    }
`;



const OverlaySearch = forwardRef(({

    sesionHints, clearQuestion,
    value, onChange, onKeyDown,
    setIsOverlayShow, isOverlayShow,
    onBlur, hints, activeHint, hintsShow,
    setHints, setQuestion, replace_hint_with_word,
    getHintsByDbInfo, hintsByDbInfo, userTying,
    getDataFromReportingServer, setActiveHint, update_question_by_hint, containerValues,
    suggestionBoxPosition,currCursorPosition,
}, ref) => {

    // const _inputRef = useRef(null)
    const wrapperRef = useRef(null)
    const suggestionBoxRef = useRef(null)



    const find_longest_string_in_array = (hints) => {

        const longest_str = (hints || []).reduce((a, b) => {
            return a.length > b.length ? a : b
        }, '')

        return (longest_str || "")?.length;
    }

    const w_l = find_longest_string_in_array(hints)

    // on suggestion seection change it will scroll if suggestion is in last of container   
    useEffect(() => {
        if (activeHint >= 0 && suggestionBoxRef.current) {
            const selectedItem = suggestionBoxRef.current.children[activeHint];
            if (selectedItem) {
                const boxTop = suggestionBoxRef.current.scrollTop;
                const boxBottom = boxTop + suggestionBoxRef.current.clientHeight;
                const itemTop = selectedItem.offsetTop;
                const itemBottom = itemTop + selectedItem.clientHeight;
                if (itemBottom > boxBottom) {
                    suggestionBoxRef.current.scrollTop = itemBottom - suggestionBoxRef.current.clientHeight;
                } else if (itemTop < boxTop) {
                    suggestionBoxRef.current.scrollTop = itemTop;
                }
            }
        }
    }, [activeHint]);


    return (
        <React.Fragment>
            <Wrapper
                height={'85px'}
                isLabelShow={(value && value.length > 0) ? true : false}
                isOverlayShow={isOverlayShow}
                ref={wrapperRef}
            >
                {isOverlayShow && (
                    <React.Fragment>
                        {/* <label
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }} className="label_top">Ask anything here to your data</label> */}


                        <input
                            id="search_box"
                            ref={ref}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}

                            value={value}
                            autoFocus={true}
                            focus={true}
                            type="text"
                            placeholder="Type your query"
                            onChange={(e) => {
                                onChange(e, ref, wrapperRef)
                            }}
                            onKeyDown={(e) => {
                                // onKeyDown(e, setCursorPositionAtSamePositionOnHintChange)
                                onKeyDown(e)
                            }}
                            onBlur={(e) => {
                                onBlur(e)
                            }}
                            style={{
                                paddingRight: '2rem'
                            }}
                        />

                        {value && value.trim().length > 0 && (
                            <div style={{ position: 'absolute', right: '1rem', background: '#fff', top: '1.4rem' }}>
                                <RippleEffect
                                    title='Clear'
                                    icon={<CloseSvg size="1rem" height="1rem" />}
                                    Click={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation()
                                        clearQuestion()
                                        ref.current.focus()
                                    }}
                                />
                            </div>
                        )}
                    </React.Fragment>
                )}

            </Wrapper>


            {
                hints && hints.length > 0 && (
                    <SuggestionList 
                      suggestionBoxPosition={suggestionBoxPosition}
                      ref={suggestionBoxRef} 
                    >
                        {hints.map((hint, index) => (
                                <li key={index} title={hint} className={activeHint === index ? 'selected' : ''}
                                onClick={() => {
                                            replace_hint_with_word(index, undefined, ref?.current?.selectionStart);
                                            if (ref && ref.current) {
                                                ref?.current.focus();
                                            }
                                        }}
                                        >
                                    {hint}
                                </li>
                        ))}
                    </SuggestionList>
                    // <MiniHintWrapper
                    // ref={suggestionRef}
                    // suggestionBoxPosition={suggestionBoxPosition}
                    // >
                    //     {hints.map((hint, index) => {

                    //         let value_arr = value && value.split(" ");
                    //         const value_to_search = value_arr[value_arr.length - 1];
                    //         var regex = new RegExp(value_to_search, "i");

                    //         var __html = '';

                    //         if (hint.search(regex) != -1) {
                    //             __html = hint.replace(regex, "<b>$&</b>")
                    //         } else {
                    //             __html = hint
                    //         }

                    //         return (
                    //             <div 
                    //             // className="hint_row" 
                    //             index={"mini" + index}>
                    //                 {/* <span  */}
                    //                 {/* // className="prev_hint" */}
                    //                 {/* >{value}</span> */}
                    //                 <span 
                    //                 // className="next_hint"
                    //                     dangerouslySetInnerHTML={{ __html: __html }}
                    //                     onClick={() => {
                    //                         replace_hint_with_word(index);
                    //                         if (ref && ref.current) {
                    //                             ref.current.focus();
                    //                         }
                    //                     }}
                    //                     style={{
                    //                         width: w_l ? (w_l > 3) ? (w_l * 12) + "px" : undefined : undefined,
                    //                         backgroundColor: activeHint === index ? "#eaeaea" : undefined
                    //                     }}>

                    //                 </span>
                    //             </div>
                    //         )
                    //     })}
                    // </MiniHintWrapper>
                )
            }



            {
                !userTying && !value && isOverlayShow && sesionHints && sesionHints.length > 0 && (!hints || hints.length == 0) && (
                    <HintsWrapper top={'0px'} style={{ top: isOverlayShow ? '86px' : '0px' }}>
                        <div className="hints_shadow">
                            {sesionHints.map((hint, index) => {
                                return (
                                    <div
                                        key={index+hint}
                                        style={{ backgroundColor: activeHint === index ? "#eee" : undefined }}
                                        index={"hint" + hint} className="hint_row" onClick={() => {
                                            setQuestion(hint);
                                            ref.current.focus()
                                            setActiveHint(index)
                                        }}>
                                        <div className="recent_icon">
                                            <RecentSvg size="2rem" height="2rem" />
                                        </div>
                                        {hint}
                                    </div>
                                )
                            })}
                        </div>
                    </HintsWrapper>
                )
            }

        </React.Fragment>
    )

})



const mapStateToProps = (state) => ({
    hintsByDbInfo: state.hintsReducer.hintsByDbInfo,
    containerValues: state.reportReducer.containerValues
})
export default connect(mapStateToProps, { getHintsByDbInfo }, null, {forwardRef: true})(OverlaySearch);
