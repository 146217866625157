import * as actions from '../action-types/new-outler'


const initialState = {
    type: actions.OUTLIER_INIT,
    outlierData: undefined,
    outlinerScanData: undefined
};

const OutlierReducer = (state, action) => {


    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        case actions.GET_OUTLIERS_RESULT:
            return {
                type: action.type,
                outlierData: action.data
            };

        case actions.GET_OUTLINER_SCAN_DATA:
            return {
                type: action.type,
                outlinerScanData: action.data
            };

        default:
            return {
                type: actions.OUTLIER_INIT,
                outlierData: state.outlierData,
                outlinerScanData: state.outlinerScanData
            }
    }
}

export default OutlierReducer;