import React, { useEffect, useState } from 'react';
import { raise_text_to_voice } from '../../actions/voice.helper';
import { connect } from 'react-redux';

const SpeechRecognition = (props) => {

    const [listening, setListening] = useState(false);
    const [triggerDetected, setTriggerDetected] = useState(false);

    useEffect(() => {
        let recognition = null;

        const startRecognition = () => {
            recognition = new window.webkitSpeechRecognition(); // Create speech recognition instance
            recognition.continuous = true; // Set continuous mode
            recognition.lang = 'en-US'; // Set language
            recognition.onstart = () => console.log('Speech recognition started');
            recognition.onresult = handleSpeechResult;
            recognition.onerror = handleError;
            recognition.onend = () => {
                console.log('Speech recognition ended');
                if (listening && !triggerDetected) startRecognition(); // Restart recognition if it's still active and trigger not detected
            };

            recognition.start(); // Start speech recognition
        };

        const handleSpeechResult = (event) => {
            const transcript = event.results[event.results.length - 1][0].transcript.trim().toLowerCase();
            console.log('Transcript:', transcript);
            props.raise_text_to_voice(transcript)

            if (!triggerDetected && transcript.includes('hey police')) {
                // Trigger phrase detected
                console.log('Trigger phrase detected');
                // props.raise_text_to_voice(transcript)
                setTriggerDetected(true); // Set trigger detected flag
            } else if (triggerDetected) {
                // Handle further speech input after trigger
                // For example, you can process additional commands here
                console.log('Speech after trigger:', transcript);
                // Perform actions or set state accordingly
            }
        };

        const handleError = (event) => {
            console.error('Speech recognition error:', event.error);
            // Handle error if needed
        };

        // Start recognition when component mounts
        startRecognition();

        // Clean-up function (optional)
        return () => {
            if (recognition) {
                recognition.stop(); // Stop recognition if component unmounts
            }
        };
    }, [listening, triggerDetected]); // Dependencies: listening and triggerDetected flags

    return (
        <div>
            {/* Your component JSX */}
        </div>
    );
};



export default connect(undefined, { raise_text_to_voice }) (SpeechRecognition);
