import React, { useState } from 'react';
import { WrapperPopup } from './dimension.styled'
import { DivRow } from '../styled-hoc';
import { Button } from '../hoc/button';
import { getNewTable, getNewTableEdit } from '../../actions/new-table'
import { connect } from 'react-redux';
import TextBox from '../hoc/textBox'
import SelectOption from '../select-option'




const AddPopup = (props) => {

    const { close, popupKey, option, data} = props;
    // Edit data
    const { item } = data;

    const setDefault = () => {
        return item ? item : {
            id: '',
            entity: '',
            fact: '',
            dimension: '',
            common_filter: '',
            type: 'Projection'
        }
    }


    const typeGroup = [
        {name: 'Projection', value: 'Projection'},
        {name: 'Drill-Down', value: 'Drill-Down'}
    ]
    const [saveData, setSaveData] = useState(setDefault())

    const onTextChange = (value, key) => {
        setSaveData({ ...saveData, [key]: value });
    }


    const _save_newtable = () => {

        let data = {
            entity: saveData.entity,
            fact: saveData.fact,
            dimension: saveData.dimension,
            common_filter: saveData.common_filter,
            type: saveData.type,
            is_active: 1,
            is_modified: true
        }


        if (saveData.id)
            data.id = saveData.id;

        props.getNewTableEdit(data);

        setTimeout(() => {
            props.getNewTable()
        }, 1000)
    }



    return (
        <WrapperPopup>
            <div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <div
                        className="dismis"
                        title="close"
                        onClick={() => {
                            close(option, popupKey)
                        }}
                    >
                    </div>
                    <h3>Add/Edit Data</h3>
                </div>

                <DivRow w="100%" style={{ marginBottom: '1rem' }}>
                    <TextBox
                        label="Entity"
                        type="text"
                        value={saveData.entity}
                        onChange={(e) => onTextChange(e.target.value, 'entity')}
                    />
                </DivRow>

                <DivRow w="100%" style={{ marginBottom: '1rem' }}>
                    <TextBox
                        label="Fact"
                        type="text"
                        value={saveData.fact}
                        onChange={(e) => onTextChange(e.target.value, 'fact')}
                    />
                </DivRow>

                <DivRow w="100%" style={{ marginBottom: '1rem' }}>
                    <TextBox
                        label="Dimension"
                        type="text"
                        value={saveData.dimension}
                        onChange={(e) => onTextChange(e.target.value, 'dimension')}
                    />
                </DivRow>

                <DivRow w="100%" style={{ marginBottom: '1rem' }}>
                    <TextBox
                        label="Common Filter"
                        type="text"
                        value={saveData.common_filter}
                        onChange={(e) => onTextChange(e.target.value, 'common_filter')}
                    />
                </DivRow>

                <DivRow w="100%" style={{ marginBottom: '1rem' }}>
                    <SelectOption
                        label="Type"
                        onChangeFunc={onTextChange}
                        selections={typeGroup}
                        displayKey='name'
                        valueType="type"
                        valueKey='value'
                        activeValue={saveData && saveData.type ? saveData.type : '' }
                        width="100%"
                        padding="0px 0px"
                        paddingRight='25px'
                        lineHeight="2.857rem"
                        menuLineHeight="1.8rem"
                        hideCase='true'
                    />
                </DivRow>

                <Button
                    primary={true}
                    width="6rem"
                    mR="1.143rem"
                    // height="50rem"
                    onClick={() => {
                        _save_newtable()
                        close(option, popupKey);
                    }}
                >
                    Save
                    </Button>

                <Button
                    width="6rem"
                    // height="50px"
                    bgColor={'#e6e6e6'}
                    onClick={() => {
                        close(option, popupKey);
                    }}
                >
                    Cancel
                    </Button>
            </div>
        </WrapperPopup>

    )
}


const mapStateToProps = (state) => ({

})


export default connect(mapStateToProps, { getNewTable, getNewTableEdit })(AddPopup);