import * as actions from '../action-types/righter';

const initialState = {
    type: actions.RIGHTER_INIT,
    righter: undefined
};

const righterReducer = (state, action) => {
	if (!state || typeof action.type === 'undefined') {
		return initialState;
    }

    switch (action.type) {
        case actions.RIGHTER_RAISE:

            return {
                type: action.type,
                righter: action.data
            };

        case actions.RIGHTER_HIDE:
            return {
                type: action.type,
                righter: action.data
            };

            // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
			return {
                type: state.type,
                righter: state.righter
            };
        }
};

export default righterReducer;