import React, { useState, useEffect, useRef } from 'react'
import { MainWrapper, FolderRow, Folder, BackButton, UploadedFileList } from './styled.file.upload'
import {
    send_File, get_uploaded_files_list, setSelectedFile,
    get_category_folder_list, get_uploaded_file_info,
    get_file_grouped, delete_grouped_file,
    getAllFiles, getFilesByPlatform, getFilesByDimension
} from '../../actions/file.uploader';
import { connect } from 'react-redux'
import * as actionTypes from '../../action-types/file.uploader';
import FolderSvg from '../svg/folder'
import UploadSvg from '../svg/upload'
import { showPopup, getClientId } from '../../utils';
import * as enums from '../../utils/enums.js';
import SelectFilePopup from './choose.upload.popup'
import BackSvg from '../svg/back';
import FileExplorer from '../file explorer';
import { Button } from '../hoc/button';
import { withRouter } from 'react-router';
import SharedTable from '../shared-table'
import { constants } from '../../utils/constants';



const Index = (props) => {

    const {

        type, setSelectedFile,
        folderFromServer, filesFromServer,
        _extract_file_name, tabList, _viewFile,
        setLocalAnswer, setSecondAnswer, setActiveTab,
        activeFolder, setActiveFolder,
        uploadedFileInfo, fileGrouped, activePageType,
        getAllFiles, getFilesByPlatform, getFilesByDimension,
        allFiles, platformFiles, dimensionsFiles

    } = props;

    const [uploadedFileList, setUploadedFileList] = useState([])
    const [activeState, setActiveState] = useState(undefined);
    const [activeGroup, setActiveGroup] = useState(undefined);
    const [activeGroupId, setActiveFroupId] = useState(undefined);
    const [localUploadedFileInfo, setLocalUploadedFileInfo] = useState(undefined);



    const fileUploadInputRef = useRef(null);


    useEffect(() => {
        if (type === actionTypes.SINGLE_FILE_SUCCESS || type === actionTypes.MULTIPAL_FILE_SUCCESS) {
            setTimeout(() => {
                props.get_category_folder_list();
                if (activeFolder === 'importData') {
                    // props.get_uploaded_file_info()
                    // props.get_file_grouped()
                    // getAllFiles()
                    // getFilesByPlatform()
                    // getFilesByDimension()
                }
            }, 100)
        }
    }, [type]);



    const uploadNewFile = () => {

        showPopup(undefined, undefined, enums.default.popupType.element, SelectFilePopup, {
            setActiveTab: setActiveTab,
            tabList: tabList,
            activeFolder: activeFolder,
            uploadedFileList: uploadedFileList,
            setUploadedFileList: setUploadedFileList,
            redirectToTableViewPage: redirectToTableViewPage,
            redirect_to_tbl_edits: redirect_to_tbl_edits
        }, undefined, undefined, "#ccc", undefined, true);
    }

    useEffect(() => {
        if (uploadedFileList && uploadedFileList.length > 0) {
            uploadNewFile()
        }

    }, [uploadedFileList])





    const file_upload = (e) => {
        let temp_files = uploadedFileList ? [...uploadedFileList] : [];
        let files = e.target.files;
        if (files.length < 21) {
            for (var i = 0; i < files.length; i++) {
                temp_files.push(files[i]);
            }
            setUploadedFileList(temp_files)
            fileUploadInputRef.current.value = null;
        }
        else {
            alert("you can only select 20 files at a time")
        }
    };


    const redirectToTableViewPage = (folder) => {
        let client_id = getClientId();
        let db_name = client_id['client-id'] == 'hr_certi' ? 'nf_hr_certi' : 'nf_menus';
        let table_name = folder == 'certificate' ? 'marks' : 'menu';
        let _path = `demo_view?db_name=${db_name}&table_name=${table_name}&from_where=${'question_search'}`
        props.history.push(_path)
    };

    const redirect_to_tbl_edits = () => {
        props.history.push('tbl_edits')
    };

    useEffect(() => {
        if (activeFolder === 'importData') {
            // props.get_uploaded_file_info()
            // props.get_file_grouped()
            getAllFiles()
            getFilesByPlatform()
            getFilesByDimension()
        }
    }, [activeFolder])


    useEffect(() => {
        setLocalUploadedFileInfo(uploadedFileInfo)
    }, [uploadedFileInfo])


    let back_button_show = activeFolder === 'importData' ? (activeGroup ? true : false) : activeFolder ? true : false;
    let upload_section_show = activeFolder === 'importData' ? activeGroup ? false : true : activeFolder ? false : true;


    const handleDrop = (event) => {

        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files);
        setUploadedFileList(droppedFiles);

    };


    return (
        <MainWrapper>
            {back_button_show && (
                <BackButton>
                    <div className="back_btn"
                        style={{ display: 'flex', alignItems: 'center', }}
                        onClick={() => {
                            if (activeState === 'file_explorer') {
                                setActiveState(undefined);
                                setActiveGroup(undefined);
                            }
                            else {
                                setActiveFolder(undefined)
                            }
                        }}>

                        <BackSvg size="2.5rem" height="1.3rem" color='#ccdde3' />
                    </div>
                </BackButton>
            )}

            {/* is_etl_making_request */}
            {upload_section_show && (
                <React.Fragment>
                    {/* <FolderRow> */}

                    <Folder key={"folder"}
                        title={'Upload New File'}
                        onDrop={handleDrop}
                        onDragOver={(e) => e.preventDefault()}
                    >
                        <input
                            ref={fileUploadInputRef}
                            type="file"
                            id="imgupload"
                            multiple={false}
                            onClick={(e) => {
                                // fileUploadInputRef.current.value = null;
                            }}
                            onChange={(e) => {
                                file_upload(e)
                            }} />

                        <img height='5rem' src={constants.CONTEXT_PATH + './upload_cion_v1.png'}></img>

                        <p>Drag and drop files here</p>
                        <div className='__or__'>OR</div>
                        {/* <Button bgColor="#3D8EF5">Choose Files</Button> */}
                        <div style={{
                            background: '#3D8EF5',
                            padding: '8px 10px',
                            color: '#fff',
                            cursor: 'pointer',
                            borderRadius: '6px'
                        }}>Choose Files</div>
                    </Folder>

                    {/* </FolderRow> */}


                    {!activeFolder && folderFromServer && folderFromServer.folders && folderFromServer.folders.length > 0 && (
                        <React.Fragment>
                            <div style={{ borderTop: '1px solid #ccc', margin: '10px 0px' }}></div>
                            <div style={{ marginTop: '1rem' }}>
                                <h1 className="heading">Your Categories</h1>
                                <FolderRow >
                                    {folderFromServer.folders.map((fol, index) => {
                                        if (fol !== 'importData') {
                                            return (
                                                <Folder key={"folder" + index}
                                                    style={{
                                                        width: '150px',
                                                        height: '150px',
                                                        margin: '10px',
                                                        boxShadow: '3px 4px 5px #ccc',
                                                        border: '1px solid #ccc'
                                                    }}
                                                    title={fol}
                                                    active={activeFolder && activeFolder === fol}
                                                    onClick={() => {
                                                        setActiveFolder(fol)
                                                        props.get_uploaded_files_list(fol)
                                                    }}

                                                >
                                                    <FolderSvg size='5rem' height="5rem" color='#008ac7' />
                                                    <p>{fol?.replace(/_/g, ' ').toUpperCase()}</p>
                                                </Folder>
                                            )
                                        }
                                    })}
                                </FolderRow>
                            </div>
                        </React.Fragment>
                    )}

                </React.Fragment>
            )}



            {activeFolder && (
                <UploadedFileList>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                        {activeFolder !== 'importData' ? <h3 className="current_file_heading">Uploaded For {activeFolder} {activeGroup ? '/' + activeGroup : null}</h3> : null}

                        {/* {((activeFolder == 'food_menu') || (activeFolder == 'certificate')) && (
                            <Button onClick={() => {
                                redirectToTableViewPage(activeFolder)
                            }}>View Data</Button>
                        )} */}
                    </div>


                    {activeFolder !== 'importData' && filesFromServer && filesFromServer.files && filesFromServer.files.length > 0 && (
                        <FileExplorer
                            files={filesFromServer.files ? filesFromServer.files : []}
                            ViewFile={_viewFile}
                            tableHead={["Name", 'View', 'Created At', 'Size']}
                            columnsToShow={["name", 'view_action', 'table_name', 'size']}
                            _extract_file_name={_extract_file_name}
                            setActiveFile={(file) => {
                                setSelectedFile(file);
                                setLocalAnswer('');
                                setSecondAnswer('')
                                setActiveTab(tabList[1])
                            }}
                        />
                    )}



                    {activeFolder === 'importData' && (
                        <React.Fragment>
                            {/* {activeState !== 'file_explorer' && allFiles && allFiles.length > 0 && (
                                <FolderRow style={{ marginTop: '1rem' }}>
                                    <Folder key={"folder"}
                                        title={"All Files"}
                                        onClick={() => {
                                            setLocalUploadedFileInfo(allFiles)
                                            setActiveState("file_explorer")
                                            setActiveGroup('All Files')
                                        }}
                                    >
                                        <FolderSvg size='5rem' height="5rem" color='#008ac7' />
                                        <p>All Files</p>
                                    </Folder>
                                </FolderRow>
                            )} */}



                            {/* {activeState !== 'file_explorer' && dimensionsFiles && Object.keys(dimensionsFiles).length > 1 && (
                                <React.Fragment>
                                    <h3 className="current_file_heading">By Dimension </h3>

                                    <FolderRow style={{ marginTop: '1rem' }}>
                                        {Object.keys(dimensionsFiles).map((fol, index) => {
                                            if (fol !== 'response_session_id') {
                                                return (
                                                    <Folder key={"folder"}
                                                        title={fol}
                                                        onClick={() => {
                                                            setLocalUploadedFileInfo(dimensionsFiles[fol])
                                                            setActiveState("file_explorer")
                                                            setActiveGroup(fol)
                                                        }}
                                                    >
                                                        <FolderSvg size='5rem' height="5rem" color='#008ac7' />
                                                        <p>{fol}</p>
                                                    </Folder>
                                                )
                                            }
                                        })}

                                    </FolderRow>
                                </React.Fragment>
                            )} */}


                            {/* {activeState !== 'file_explorer' && (platformFiles && Object.keys(platformFiles).length > 1) && (
                                <React.Fragment>
                                    <h3 className="current_file_heading">By Platform </h3>
                                    <FolderRow style={{ marginTop: '1rem' }}>
                                        {Object.keys(platformFiles).map((fol, index) => {
                                            if (fol !== 'response_session_id') {
                                                return (
                                                    <Folder key={"folder"}
                                                        title={fol}
                                                        onClick={() => {
                                                            setLocalUploadedFileInfo(platformFiles[fol])
                                                            setActiveState("file_explorer")
                                                            setActiveGroup(fol)
                                                        }}
                                                    >
                                                        <FolderSvg size='5rem' height="5rem" color='#008ac7' />
                                                        <p>{fol}</p>
                                                    </Folder>
                                                )
                                            }
                                        })}
                                    </FolderRow>
                                </React.Fragment>
                            )} */}

                            {/* {activeState === 'file_explorer' && (
                                <FileExplorer
                                    files={localUploadedFileInfo && localUploadedFileInfo.length > 0 ? localUploadedFileInfo : []}
                                    ViewFile={_viewFile}
                                    delete_grouped_file={(table_name, id) => {
                                        props.delete_grouped_file(table_name, id)
                                        setTimeout(() => {
                                            props.get_uploaded_file_info(activeGroupId)
                                        }, 1000)
                                    }}
                                    tableHead={["Name", 'View', 'Action', 'Created At','Platform','Status', 'Size', 'Number Of Records']}
                                    columnsToShow={["file_name", 'view_action', 'delete_action', 'created_at', 'platform','status', 'size', 'number_of_records']}
                                    _extract_file_name={_extract_file_name}
                                    setActiveFile={(file) => {
                                    }}
                                />
                            )} */}


                        </React.Fragment>
                    )}
                </UploadedFileList>
            )}
        </MainWrapper>
    )
}



const mapStateToProps = (state) => ({
    data: state.fileUploaderReducer.data,
    type: state.fileUploaderReducer.type,
    folderFromServer: state.fileUploaderReducer.folderFromServer,
    filesFromServer: state.fileUploaderReducer.filesFromServer,
    uploadedFileInfo: state.fileUploaderReducer.uploadedFileInfo,
    fileGrouped: state.fileUploaderReducer.fileGrouped,
    allFiles: state.fileUploaderReducer.allFiles,
    platformFiles: state.fileUploaderReducer.platformFiles,
    dimensionsFiles: state.fileUploaderReducer.dimensionsFiles,
})



export default withRouter(connect(mapStateToProps, { send_File, get_uploaded_files_list, setSelectedFile, get_category_folder_list, get_uploaded_file_info, delete_grouped_file, getAllFiles, getFilesByPlatform, getFilesByDimension })(Index))

