import styled from 'styled-components';


export const FormattingWrapper = styled.div`

    width: 65rem;
    min-height: 25rem;
    max-height: 30rem;
    background-color: #fff;
    
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 1rem;

    h1{
        font-size: 1.3rem;
        text-align: center;
        margin-top: 0px;
        margin-bottom: 1rem;
        font-weight: 600;
        text-transform: capitalize;
    }


    .table_wrapper{
        width: 100%;
        max-height: 25rem;
        overflow-y: auto;
        &::-webkit-scrollbar {
            background-color:red;
            width:5px
        }
        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }
        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 5px;
        }
        table{
            width: 100%;
            border-collapse: collapse;
            position: relative;
            thead{
                td{
                    padding: .7rem 7px;
                    font-weight: 600;
                    position: sticky;
                    top: 0px;
                    background-color: #fff;
                    z-index: 2
                }
            }
            td{
                border-bottom: 1px solid #222;
                font-size: .9rem;
                color: #000;
                padding: .4rem 7px;
                box-sizing: border-box;
                position: relative;
                z-index: 1
            }
        }
        select{
            width: 90%;
            height: 30px;
            font-size: .8rem;
            padding: 5px;
            box-sizing: border-box;
            border: 1px solid #000;
            background: #fff;;
        }
        input{
            width: 100px;
            height: 30px;
            font-size: .8rem;
            padding: 5px;
            box-sizing: border-box;
            border: 1px solid #000;
        }
        input[type="color"]{
            width: 50px;
            padding: 0px;
            border: 1px solid #000;
        }
    }

    .add_btn{
        font-size: .9rem;
        font-weight: bold;
        margin: 1rem 0px;
        display: flex;
        color: #0081CA;
        div{
            cursor: pointer;
        }
        
        svg{
            fill: #0081CA;;
        }
    }

`;