// import styled from 'styled-components';



// export const Wrapper = styled.div`
//     width: 28.857rem;
//     background: white;
//     box-sizing: border-box;
//     padding: 1.643rem;
//     border-radius: 5px;
//     display: flex;
//     flex-direction: column;
//     padding: 10px;
//     box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
//     .scroll{

//         max-height: 120px;
//         overflow-y: auto;
//         &::-webkit-scrollbar {
//             background-color:red;
//             width:5px
//         }
//         &::-webkit-scrollbar-track {
//             background-color:#b1cad5;
//         }
//         &::-webkit-scrollbar-thumb {
//             background-color:#76919d;
//             border-radius:0px;
//             height: 5px;
//         }
//         &::-webkit-scrollbar:horizontal{
//             background-color: green;
//             height: 5px;
//         }
//     }
//     h1{
//         font-size: 1rem;
//         font-weight: bold;
//         font-stretch: normal;
//         font-style: normal;
//         /* line-height: 1.714rem; */
//         letter-spacing: normal;
//         text-align: left;
//         color: #008ac7;
//         margin-top: 0px;
//     }
//     p{
//         font-size: 1rem;
//         font-weight: normal;
//         font-stretch: normal;
//         font-style: normal;
//         line-height: 1.286re;
//         letter-spacing: normal;
//         text-align: left;
//         color: #122e39;
//         margin-top: 0px;
//     }
//     ul{
//         margin: 0px;
//         list-style: none;
//         padding: 0px;
//         overflow-y: auto;
//         /* margin-bottom: 1rem; */
//         height: auto;
//         max-height: 6.5rem;
//         &::-webkit-scrollbar {
//             background-color:red;
//             width:5px
//         }
//         &::-webkit-scrollbar-track {
//             background-color:#b1cad5;
//         }
//         &::-webkit-scrollbar-thumb {
//             background-color:#76919d;
//             border-radius:0px;
//             height: 5px;
//         }
//         &::-webkit-scrollbar:horizontal{
//             background-color: green;
//             height: 5px;
//         }
//     }
//     li{
//         display: flex;
//         align-items: center;
//         justify-content: flex-start;
//         flex-direction: row;
//         .item_name{
//             font-size: 1rem;
//             font-weight: normal;
//             font-stretch: normal;
//             font-style: normal;
//             line-height: 2.143rem;
//             letter-spacing: normal;
//             text-align: left;
//             color: #122e39;
//             text-overflow: ellipsis;
//             overflow: hidden;
//             margin-left: 10px;
//             text-transform: capitalize;
//         }
//     }
// `;

import styled from 'styled-components';



export const Wrapper = styled.div`
       /* width: 28.857rem; */
        background: red;
        box-sizing: border-box;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        position: relative;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
    

    .scroll{
        
        max-height: 120px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            background-color:red;
            width:5px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }
    }

    h1{
        font-size: 1rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #008ac7;
        margin-top: 0px;
    }
    p{
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.286re;
        letter-spacing: normal;
        text-align: left;
        color: #122e39;
        margin-top: 0px;
    }

    ul{
        margin: 0px;
        list-style: none;
        padding: 0px;
        overflow-y: auto;
        height: auto;
        max-height: 6.5rem;

        &::-webkit-scrollbar {
            background-color:red;
            width:5px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }
    }

    li{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;

        .item_name{
            font-size: 1rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.143rem;
            letter-spacing: normal;
            text-align: left;
            color: #122e39;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-left: 10px;
            text-transform: capitalize;
        }
    }


     .circle-container {
            position: relative;
            width: 180px;
            height: 180px;
            border-radius: 50%;
            background-color:transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;

        .inner-circle {
            position: absolute;
            width: 85px;
            height: 85px;
            border-radius: 50%;
            background-color: #fff;;
            z-index: 1;
        }

        }

        .pie-button {
            position: absolute;
            width: 100%;
            height: 100%;
            clip-path: polygon(50% 50%, 0 0, 100% 0);
            transform-origin: center;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: bold;
            color: white;
            cursor: pointer;
            background-color: #043c55;
            span {
                left: 48px;
                top: 12px;
                position: absolute;
                z-index: 333;
                font-size: 14px;
                text-transform: uppercase;
            }

           &:hover {
                background-color: rgba(0, 0, 0, 0.9) !important;
        }
        
    }

    .disabled{
        background-color: #71797d;
        cursor: not-allowed;
        &:hover {
                background-color: #71797d !important;;
        }
    }
    .pie-button:nth-child(1) {
            /* background-color: #007bff; */
            transform: rotate(0deg);
               span{
                left: 54px;
                top: 22px;
                transform: rotate(181deg);
            }
        }

        .pie-button:nth-child(2) {
            /* background-color: #00cc66; */
            transform: rotate(90deg);
            span{
                left: 50px;
                top: 22px;
                transform: rotate(182deg);

            }
        }

        .pie-button:nth-child(3) {
            /* background-color: #ff8800; */
            transform: rotate(180deg);
            span{
                top: 14px;
                left: 50px;

            }
        }

        .pie-button:nth-child(4) {
            /* background-color: #ff4444; */
            transform: rotate(270deg);

            span{
                top: 17px;
                left: 50px;
                transform: rotate(2deg);
            }
        }

        
        
`;
