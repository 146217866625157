import React, { useState, useEffect } from 'react';
import { DivRow, SaveButton } from '../styled-hoc';
import { Gap } from '../gap/'
import styled from 'styled-components';
import { getOutliearScanData } from '../../actions/new-outlier'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import CloseSvg from '../svg/close'



export const Wrapper = styled.div`
    position: relative;
    width: 70rem;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 6px 0px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(234, 234, 234);
    border-image: initial;
    padding: 1.643rem 1.429rem;
    overflow-y: auto;
    max-height: 80vh;

    &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }
    .title{
        font-size: 1rem;
        margin-bottom: 5px;
    }


    .back{
        svg{
            transform: rotate(180deg);
        }
        &:hover{
            svg{
                fill: rgb(0,138,199);
            }
        }
    }


    .dismis{
        position: absolute;
        right: 1rem;
        top: 1rem;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 4px;
        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        cursor: pointer;

        &:hover{
            background: #356d941c;
            svg{
                fill: rgb(0,138,199);
            }
        }
    }

    h3{
        font-size: 1.286rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.714rem;
        letter-spacing: normal;
        text-align: left;
        margin:0px;
        display:block;
        padding-bottom: 18px;
        position: relative;
/* 
        &::before{
            position: absolute;
            bottom: 0px;
            border: 0.5px solid #bbd1d9;
            width: 101%;
            left: -5px;
            content: '';
        } */
    }

    table{
        width: 100%;
        border: 1px solid #ccc;
        border-collapse: collapse;
    }
    td,th{
        padding: 5px;
        border: 1px solid #cccccc59;
        height: 2.1rem;

    }
    thead tr th{
        background-color: #eaeaea
    }


    .alert{
        background-color: red;
        display: block;
        color: #fff;
        padding: 0;
        border-radius: 5px;
        width: 18px;
        margin: auto;
        padding: 0px 5px;
    }

`;

export const TitleWrapper = styled.div`
    font-size: 1.286rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.714rem;
    letter-spacing: normal;
    text-align: center;
    color: rgb(26, 26, 26);
    display: block;
    margin: 0px;
`;


export const CloseWrapper = styled.div`
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 7px;
`;


const ScanDataButton = (props) => {

    const { close, popupKey, option, data, saveMenu, save_favourite, outlinerScanData } = props;
    const [isScan, setIsScan] = useState();
    const [localScanData, setScanData] = useState(undefined);


    useEffect(() => {
        props.getOutliearScanData()
    }, []);

        useEffect(() => {
            setScanData(outlinerScanData)
        }, [outlinerScanData]);


        return (
            <Wrapper>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <div
                        className="dismis"
                        title="close"
                        onClick={() => {
                            close(option, popupKey)
                        }}
                    >
                        <CloseSvg color="#bbd1d9" size="1rem" height="1rem" />
                    </div>
                    <h3>Outline Scaned Data</h3>
                </div>
                <Gap w="100%" />
                <div>
                    <DivRow >
                        <div>
                            {(localScanData && localScanData.length > 0) &&
                                <table style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th>Country</th>
                                            <th>Email Id</th>
                                            <th>Organization</th>
                                            <th>Pat Country</th>
                                            <th>Pat Email</th>
                                            <th>Pat Temp</th>
                                            <th>Organization</th>length
                                    </tr>
                                    </thead>
                                    <tbody style={{ alignItems: 'center' }}>
                                        {localScanData !== 'undefined' && localScanData.map((item, index) => {
                                            return (

                                                <tr key={"scan" + index} style={{ textAlign: 'center' }}>
                                                    <td style={{ background: item.nf_pat_country === 1 ? 'red' : '' }}>{item.country}</td>
                                                    <td style={{ backgroundColor: item.nf_pat_email === 1 ? 'red' : '' }}>{item.email_id}</td>
                                                    <td style={{ backgroundColor: item.nf_pat_temp === 1 ? 'red' : '' }}>{item.temperature}</td>
                                                    <td style={{ backgroundColor: item.nf_organization === 1 ? 'red' : '' }}>{item.organization}</td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>}
                        </div>
                    </DivRow>
                </div>
            </Wrapper>
        );
}

const mapStateToProps = (state) => ({
    outlinerScanData: state.OutlierReducer.outlinerScanData,
    outlierData: state.OutlierReducer.outlierData,
})
export default connect(mapStateToProps, { getOutliearScanData })(ScanDataButton);
