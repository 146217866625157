import * as actions from '../action-types/master.data'


const initialState = {
    type: actions.MASTER_DATA_INIT,
    tableData: undefined,
    storedActionData : undefined,
};

const MasterReducer = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        case actions.GET_DATA_BY_TABLE_NAME:
            return {
                ...state,
                type: action.type,
                tableData: action.data,
                storedActionData: undefined,

            };
        case actions.MASTER_DATA_STORE_ID:
            return {
                ...state,
                type: action.type,
                storedActionData: action.data

            }
        case actions.DELETE_TABLE_REDCORD:
            return {
                ...state,
                type: action.type,
                tableData: state.tableData.filter((item) => item.id !== state.storedActionData),
                storedActionData: undefined
            }
        
        default:
            return {
                ...state,
            };
    }
}

export default MasterReducer;