import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import SharedTable from '../shared-table'
import CloseSvg from '../svg/close';

const MainWrapper = styled.div`
    width: 80rem;
    height: 80vh;
    background-color: #fff;
    padding: .5rem;
    padding-top: 2.5rem;
    border: 3px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
    position: relative;
    .close_btn{
        background: #cccccc4f;
        top: 10px;
        right: 10px;
        width: 1.8rem;
        height: 1.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;

        &:hover{
            background-color: #eaeaea;
            svg{
                fill: rgb(0,138,199);
            }
        }

    }
`;


const TablePopup = (props) => {

    const { close, popupKey, option, viewTableData } = props;

    const [tableHead, setTableHead] = useState(undefined);
    const hide_these_columns = ['id', 'file_id']


    useEffect(() => {


        if (viewTableData && viewTableData.length > 0) {
            let keys = Object.keys(viewTableData[0]);
            const temp_keys = keys && keys.length > 0 && keys.filter(key => {
                if (hide_these_columns.indexOf(key) > -1) return false;
                else return true;
            })
            setTableHead(temp_keys)
        } else {
            setTableHead(undefined)
        }
    }, [viewTableData])


    return (
        <MainWrapper>
            <div className="close_btn" onClick={() => close(option, popupKey)}>
                <CloseSvg size="1rem" height="1rem" />
            </div>
            <div style={{ width: '100%', padding: '0px .5rem', boxSizing: 'border-box', borderBottom: '2px solid #ccc' }}>
                {/* <div className="flex_row" style={{ display: 'flex', alignItems: 'center' }}>
                {tablName ? <h1>Table Name: {tablName}</h1> : null}
            </div> */}
                {viewTableData && viewTableData.length > 0 ? <p style={{ fontSize: '1rem', marginTop: '0rem' }}>Sample Records: {viewTableData.length}</p> : null}
            </div>
            {viewTableData && viewTableData.length > 0 ?
                <SharedTable
                    height={'70vh'}
                    action={false}
                    tableData={(viewTableData && viewTableData.length > 0) ? viewTableData : undefined}
                    columnsToShow={tableHead}
                    tableHead={tableHead}
                /> : <p style={{ textAlign: 'center' }}>No Records Found.</p>}

        </MainWrapper>
    )
}



const mapStateToProps = (state) => ({
    viewTableData: state.fileUploaderReducer.viewTableData,
})
export default connect(mapStateToProps, {})(TablePopup)
