export const throttle = (func, wait, options = {}) => {
    let lastTime = 0;
    let timeout;
    let context;
    let args;
    let result;
    const { leading = true, trailing = true } = options;
  
    const later = () => {
      lastTime = leading === false ? 0 : Date.now();
      timeout = null;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    };
  
    return function(...rest) {
      const now = Date.now();
      if (!lastTime && leading === false) lastTime = now;
      const remaining = wait - (now - lastTime);
      context = this;
      args = rest;
  
      if (remaining <= 0 || remaining > wait) {
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
        }
        lastTime = now;
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      } else if (!timeout && trailing !== false) {
        timeout = setTimeout(later, remaining);
      }
  
      return result;
    };
  };