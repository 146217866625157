export const final_groups_list = (groups, parent_group_name = undefined) => {
    const final_groups = [];

    groups.forEach(group => {
        const this_group_name = parent_group_name ? parent_group_name + ' | ' + group.name : group.name;
        final_groups.push({
            id: group.id,
            name: this_group_name
        });

        if (group.groups && group.groups.length > 0) {
            const inner_groups = final_groups_list(group.groups, this_group_name);

            if (inner_groups && inner_groups.length > 0) {
                final_groups.push(...inner_groups);
            }
        }
    });


    return final_groups;
};