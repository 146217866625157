import styled from 'styled-components';


export const Wrapper = styled.div`
    width: 100%;


    table {
        border-collapse: collapse;
        width: 100%;

        th {
            padding-bottom: 5px;
            font-size: 1rem;
            font-weight: 400;
            background: #eaeaea;
            padding: 10px 0px 10px 0px;
        }

        tr {
            border-bottom: 1px solid #e1e1e1;
            padding: 10px 5px;
            box-sizing: border-box;
        }

        td {
            text-align: center;
            padding: 10px 0;
            font-size: .9rem;
        }
    }
`;