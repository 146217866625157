import React, { useState, useRef, useEffect } from 'react'
import AutoSearch from '../../auto.search';
import TablePopup from '../table.popup'
import ViewSvg from '../../svg/view';
import * as enums from '../../../utils/enums';
import { showPopup } from '../../../utils';
import ExclamationSvg from '../../svg/exclamation';
import TextBox from '../../hoc/textBox';
import { Button } from '../../hoc/button';
import SelectOption from '../../select-option';
import RippleEffect from '../../hoc/ripple-effect'
import CorrectSvg from '../../svg/correct';
import DropDownSvgUp from '../../svg/dropDownUp';
import DropDownSvgDown from '../../svg/dropDown'
import AutoSearchNew from '../../auto.search/auto.search.new'
import MappingTableRow from './mapping.table.row';



const MappingTable = (props) => {

    const {
        tableHead,
        tableData,
        targetColumnNames,
        targetTableNames,
        sourceColumns,
        autoSearchOnChange,
        formData,
        db_infos,
        getTargetTableNames,
        setFormData,
        setTransformationType,
        transformationType,
        setPrimaryKeyValue,
        primaryKeyValue,
        localError,
        ignoreError,
        clickOnApplyButton,
        clickOnIgnoreButton,
        errorPopupValue,
        isErrorOpen,
        setIsErrorOpen,
        sortMappingTable,
        sortOrder,
        wrapperRef,
        is_edit_mode
    } = props;


    const [errorValue, setErrorValues] = useState(undefined);


    const showTablePopupClicked = (name) => {
        let post_data = {
            database_id: props.target_schema,
            table_name: name
        }
        props.getTargetTableData(post_data)
        showPopup(undefined, undefined, enums.default.popupType.element, TablePopup, {
            tablName: name
        })
    }
    return (
        <div style={{ width: '100%', }} className="source_tbl_main">
            <table>
                <thead>
                    <tr>
                        {tableHead && tableHead.length > 0 && tableHead.map((row, i) => {
                            let orignal_name = row === 'direct_mapping' ? row.split('_').join(' ') + "?" : row.split('_').join(' ');
                            let sort_order = sortOrder && sortOrder[row]
                            let color_up_icon = (sort_order && sort_order && sort_order === 'za') ? "#222" : '#c1b8b8';
                            let color_up_down = (sort_order && sort_order && sort_order === 'az') ? "#222" : '#c1b8b8';
                            const info = {
                                dataKey: row,
                                type: 'string',
                            }
                            return (
                                <th
                                    style={{
                                        textTransform: 'capitalize',
                                        width: row === 'direct_mapping' ? '11rem' : undefined
                                    }}
                                    key={"tbl_column" + i}>{orignal_name}

                                    {row !== 'direct_mapping' && row !== 'criteria' && row !== 'target_table' && (
                                        <div className="dropdown_icon"
                                            onClick={() => {
                                                sortMappingTable(info)
                                            }}
                                            style={{ marginLeft: '20px', }}
                                        >
                                            <DropDownSvgUp size={".5rem"} height=".5rem" color={color_up_icon} />
                                            <DropDownSvgDown size={".5rem"} height=".5rem" color={color_up_down} />
                                        </div>
                                    )}
                                </th>
                            )
                        })}
                    </tr>
                </thead>

                <tbody>
                    {tableData && tableData.length > 0 && tableData.map((row, i) => {
                        return (
                            <MappingTableRow
                                tableHead={tableHead}
                                rowData={row}
                                row_index={i}
                                sourceColumns={sourceColumns}
                                errorPopupValue={errorPopupValue}
                                ignoreError={ignoreError}
                                target_schema={props.target_schema}
                                showTablePopupClicked={showTablePopupClicked}
                                tableData={tableData}
                                is_edit_mode={is_edit_mode}
                            />
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default MappingTable;

// let errorKeys = localError && Object.keys(localError);
//                         let is_error = errorKeys && errorKeys.indexOf(i.toString()) > -1 ? localError[i.toString()] : false;
//                         return (
//                             <tr key={"tbl" + i}>
//                                 {tableHead && tableHead.length > 0 && tableHead.map((cell, j) => {
//                                     const active_value = (typeof tableData[i][cell] === 'object') ? (tableData[i][cell] && tableData[i][cell].value) : tableData[i][cell];
//                                     let selectionsOfTargetColumnNames = targetColumnNames && targetColumnNames[tableData[i]['target_table']];
//                                     let selection = (cell == 'target_table') ?
//                                         targetTableNames : (cell == 'target_column') ?
//                                             selectionsOfTargetColumnNames : (cell == 'source_column') ?
//                                                 sourceColumns ?
//                                                     sourceColumns : undefined : undefined;

//                                     let is_new_value = undefined;
//                                     let which_option_render = (cell === 'direct_mapping') ? "selectOption" : (cell === 'criteria') ? "input" : 'auto_search_option';
//                                     let color_of_exc_btn = (errorPopupValue && Object.keys(errorPopupValue).length > 0 && Object.keys(errorPopupValue).indexOf(i.toString()) > -1) ? 'green' : (ignoreError && ignoreError.indexOf(i) > -1) ? '#f1db42' : "#E2574C"
//                                     if (!selection && active_value && typeof active_value === 'string' && active_value.trim().length > 0) is_new_value = true;
//                                     if (selection && active_value && typeof active_value === 'string' && selection.indexOf(active_value.trim()) === -1 && active_value && active_value.trim().length > 0) is_new_value = true;

//                                     return (
//                                         <td key={"cell" + j} style={{ position: 'relative' }}>
//                                             {which_option_render === 'auto_search_option' &&
//                                                 <AutoSearch
//                                                     selections={cell == 'target_column' ? (row.target_table && targetTableNames && targetTableNames.indexOf(row.target_table) > -1 ? selection : []) : selection}
//                                                     onItemClick={(val) => {
//                                                         autoSearchOnChange(i, cell, val, 'clicked');
//                                                     }}
//                                                     setActiveValue={(val) => {
//                                                         autoSearchOnChange(i, cell, val, 'typing');
//                                                     }}
//                                                     activeValue={active_value ? active_value : undefined}
//                                                     width="100%"
//                                                     borderNone={true}
//                                                     color='#7c7c7c'
//                                                     borderColor={"transparent"}
//                                                     dropdownShadow={"2px 3px 6px 2px #ccc"}
//                                                     wrapperRef={wrapperRef}
//                                                 />
//                                             }

//                                             {which_option_render === 'selectOption' &&
//                                                 <SelectOption
//                                                     selections={[
//                                                         { name: "Yes", value: true },
//                                                         { name: "No", value: false }
//                                                     ]}
//                                                     onChangeFunc={(value) => {
//                                                         autoSearchOnChange(i, cell, value, 'clicked');
//                                                     }}
//                                                     displayKey={"name"}
//                                                     valueKey={'value'}
//                                                     activeValue={active_value}
//                                                     width="100%"
//                                                     height="15.857rem"
//                                                     borderNone={true}
//                                                     removeInputHeight={true}

//                                                 />
//                                             }

//                                             {which_option_render === 'input' &&
//                                                 <TextBox
//                                                     borderColor='#eaeaea'
//                                                     value={(active_value && active_value) ? active_value : ''}
//                                                     borderNone={true}
//                                                     // value={active_value}
//                                                     onChange={(e) => {
//                                                         let _value = e.target.value
//                                                         autoSearchOnChange(i, cell, _value, 'typing');
//                                                     }}
//                                                 />

//                                             }


//                                             {cell == 'source_column' && is_error && (
//                                                 <React.Fragment>
//                                                     {is_error && is_error.error_type && is_error.error_type == "length_error" && (isErrorOpen == i) && (
//                                                         <div className="small_data_popup">
//                                                             <h2>Difference in column length found</h2>
//                                                             <p >
//                                                                 {row.source_column} Length = {is_error.current_length} <br></br>
//                                                                 {row.target_column} Length = {is_error.length_required} <br></br>
//                                                             </p>

//                                                             <div style={{ textAlign: 'left' }}>
//                                                                 <TextBox
//                                                                     label="Please Provide The Range"
//                                                                     value={(errorValue && errorValue[i]) ? errorValue[i] : undefined}
//                                                                     onChange={(e) => {
//                                                                         let temp_error_value = errorValue ? JSON.parse(JSON.stringify(errorValue)) : {};
//                                                                         let _value = e.target.value;
//                                                                         temp_error_value[i] = _value;
//                                                                         setErrorValues(temp_error_value)
//                                                                     }}
//                                                                 />
//                                                             </div>
//                                                             <p style={{ marginTop: '.8rem' }}>Example- <span className="tag">0-10</span> or <span className="tag">0-20</span></p>
//                                                             <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '1rem' }}>
//                                                                 <Button
//                                                                     mR="1rem"
//                                                                     primary={true}
//                                                                     onClick={() => {
//                                                                         let _value = errorValue && errorValue[i];
//                                                                         clickOnApplyButton(i, _value)
//                                                                         setIsErrorOpen(undefined);
//                                                                     }}>Apply</Button>
//                                                                 <Button bgColor="#9caeb5" onClick={() => {
//                                                                     clickOnIgnoreButton(i)
//                                                                     setIsErrorOpen(undefined);

//                                                                 }}>Ignore Error</Button>
//                                                             </div>
//                                                         </div>
//                                                     )}

//                                                     <div
//                                                         style={{
//                                                             position: 'absolute',
//                                                             marginTop: '0rem',
//                                                             top: '31%',
//                                                             left: '-14%',
//                                                         }} className={"view_error_btn"}>
//                                                         <RippleEffect
//                                                             zIndex={1}
//                                                             width={'1rem'}
//                                                             height={'1rem'}
//                                                             title="View Error"
//                                                             icon={
//                                                                 color_of_exc_btn === 'green' ? <CorrectSvg size={"1rem"} height={'1rem'} color={color_of_exc_btn} /> :
//                                                                     <ExclamationSvg size={"1rem"} height={'1rem'} color={color_of_exc_btn} />}
//                                                             Click={(e) => {
//                                                                 setIsErrorOpen(i);
//                                                             }}
//                                                         />
//                                                     </div>
//                                                 </React.Fragment>
//                                             )}


//                                             {is_new_value && (cell !== 'direct_mapping') && cell !== 'criteria' && (
//                                                 <div className={"new_value"} title={`New ${cell && cell.split('_').join(' ')}`}>{`New`}</div>
//                                             )}

//                                             {active_value && typeof active_value === 'string' && active_value.trim().length > 0 && cell === 'target_table' && selectionsOfTargetColumnNames && !is_new_value && (
//                                                 <div style={{ width: '2.1rem', position: 'absolute', right: '2.8rem', top: '.9rem', background: '#fff', zIndex: '1' }}>
//                                                     <RippleEffect
//                                                         zIndex={1}
//                                                         width={'1rem'}
//                                                         height={'1rem'}
//                                                         title="View Table Data"
//                                                         icon={<ViewSvg size='1rem' height='1rem' />}
//                                                         Click={() => {
//                                                             let post_data = {
//                                                                 database_id: formData['target_schema'],
//                                                                 table_name: active_value
//                                                             }
//                                                             props.getTargetTableData(post_data)
//                                                             showPopup(undefined, undefined, enums.default.popupType.element, TablePopup, {
//                                                                 tablName: active_value
//                                                             })
//                                                         }}
//                                                     />
//                                                 </div>
//                                             )}
//                                         </td>
//                                     )
//                                 })}
//                             </tr>
//                         )