// Function to get the alias of the x-axis

import { formatNumber, get_formated_cell_data } from ".";




const date_keys = ['month', 'week', 'date', 'year', 'quarter', 'qtr', 'quarter'];

const sortOrder = {
    year: 1,
    quarter: 2,
    qtr: 2,
    month: 3,
    week: 4,
    day: 5, // Include other date keys as needed
};



function separateKeys(keys) {
    const stringKeys = keys.filter(key => !date_keys.includes(key?.toLocaleLowerCase()));
    return {
        date: keys.filter(key => date_keys.includes(key?.toLocaleLowerCase())),
        string: stringKeys
    };
}

function aggregate_the_data(data, aggregateKeys, valueKeys) {
    const aggregatedResult = {};

    data.forEach(item => {
        // Create a key based on the aggregate keys
        const key = aggregateKeys.map(k => item[k]).join('|');

        // Initialize the aggregated object if it doesn't exist
        if (!aggregatedResult[key]) {
            aggregatedResult[key] = {};
            aggregateKeys.forEach(k => {
                aggregatedResult[key][k] = item[k];
            });
            valueKeys.forEach(k => {
                aggregatedResult[key][k] = 0;
            });
        }

        // Sum the values for the specified value keys
        valueKeys.forEach(k => {
            aggregatedResult[key][k] += item[k];
        });
    });

    // Convert the result back to an array
    return Object.values(aggregatedResult);
}

const getAliasOfXAxis = (value) => {

    switch (value?.toLocaleLowerCase()) {

        case 'year':
            return 'Yearly';
        case 'month':
            return 'Monthly';
        case 'week':
        case 'weekly':
            return 'Weekly';
        case 'quarter':
        case 'qtr':
            return 'Quarter';
        case 'date':
            return 'Daily';
        default:
            return value; // Return an empty string if no match
    }
};






const get_br_data = (data = [], x, yac = [], yac_total, config_meta) => {

    var result = '<ul>';

    const aggregateData = aggregate_the_data(data, [x], yac);

    aggregateData?.map((d, data_index) => {

        if (data_index > 14) return

        // const profit = false ;

        const xData = d?.[x];
        const p_change = d?.['Percentage Change'];
        const p_change_fm = formatNumber(p_change, true, true) + " %";

        var li = '<li>';

        li += `<strong>${xData}</strong>:  `

        yac?.map((y) => {

            const yData = d?.[y];
            const pData = (yData / yac_total[y]?.sum) * 100;
            const f_data = get_formated_cell_data(config_meta, y, yData);
            const f_p_c = formatNumber(pData, true, 2) + " %";

            li += ` ${f_data} (${f_p_c} of total ${y})`
        })

        if (d?.['Percentage Change']) {
            li += `Percentage Change from Previous Year: ${p_change_fm}`

        }
        li += `</li>`
        result += li;

    })
    result += '</ul>';

    return result;

}



const create_breakdown = (data = [], xac = [], yac = [], yac_total, config_meta) => {

    var x = xac?.[0];
    var string_x = undefined;

    var result = '<div>';

    result += get_br_data(data, x, yac, yac_total, config_meta)

    result += '</div>'

    return result;
}



const create_key_insight = (__data = [], xac = [], yac = [], yac_total, config_meta) => {

    const results = [];
    const totals = {};

    var data = aggregate_the_data(__data, [xac[0]], yac);

    yac.forEach(key => {
        totals[key] = 0;
    });

    data.forEach(item => {
        yac.forEach(key => {
            totals[key] += item[key] || 0;
        });
    });

    // Define rules for message generation

    // const f_data = get_formated_cell_data(config_meta, y, yData);
    // const f_p_c = formatNumber(pData, true, true) + " %";

    const rules = [
        {
            condition: (change) => change > 0,
            message: (prevValue, currentValue, change, key) => `${key} grew by ${formatNumber(change)}% from ${prevValue} to ${currentValue}.`
        },
        {
            condition: (change) => change < 0,
            message: (prevValue, currentValue, change, key) => `${key} decreased by ${formatNumber(change)}% from ${prevValue} to ${currentValue}.`
        },
        {
            condition: (prevContribution, currentContribution) => (prevContribution + currentContribution) >= 75,
            message: (prevValue, currentValue, prevContribution, currentContribution, key) =>
                `${prevValue} and ${currentValue} were the most profitable years, contributing to approximately ${formatNumber((prevContribution + currentContribution))}% of the total ${key}.`
        },
        {
            condition: (change) => change > 0,
            message: (prevValue, currentValue) => `${currentValue} had a slight increase compared to ${prevValue}.`
        },
        {
            condition: (change) => change < 0,
            message: (currentValue) => `There was a remarkable drop in ${currentValue} compared to the preceding years.`
        }
    ];

    for (let i = 1; i < data.length; i++) {

        const prev = data[i - 1] || 0;
        const current = data[i];

        // Check if percentageChange is provided, otherwise calculate it
        const percentageChange = current.percentageChange !== undefined
            ? current.percentageChange
            : ((current[yac[0]] - prev[yac[0]]) / prev[yac[0]]) * 100;

        let message = '';

        // Generate messages based on the defined rules
        if (rules[0].condition(percentageChange)) {
            message = rules[0].message(prev[xac[0]], current[xac[0]], percentageChange, yac[0]);
        } else if (rules[1].condition(percentageChange)) {
            message = rules[1].message(prev[xac[0]], current[xac[0]], percentageChange, yac[0]);
        }

        // If both revenue and discount are provided, analyze the data for additional messages
        if (current[yac[0]] !== undefined && current[yac[1]] !== undefined) {
            const currentRevenueContribution = (current[yac[0]] / totals[yac[0]]) * 100;
            const prevRevenueContribution = (prev[yac[0]] / totals[yac[0]]) * 100;

            // Check for significant contributions to overall revenue
            if (rules[2].condition(prevRevenueContribution, currentRevenueContribution)) {
                message += ` ${rules[2].message(prev[xac[0]], current[xac[0]], prevRevenueContribution, currentRevenueContribution, yac.join(' and '))}`;
            }

            if (rules[3].condition(percentageChange)) {
                message += ` ${rules[3].message(prev[xac[0]], current[xac[0]])}`;
            } else if (rules[4].condition(percentageChange)) {
                message += ` ${rules[4].message(current[xac[0]])}`;
            }

            // Mention the proportional relationship between revenue and discount
            message += ` The amount of ${yac[1]} given each year is proportional to the ${yac[0]} generated.`;
        }

        results.push(`<li>${message}</li>`);
    }

    console.log("results", results)
    return `<ul>${results.join('')}</ul>`;
}


const key_trend_v1 = (data, xacKey, [yacKey], yacTotal, configMeta) => {

    const totalRevenue = yacTotal?.[yacKey]?.sum || 0;

    const averageRevenue = totalRevenue / data.length;


    let maxRevenue = -Infinity;
    let minRevenue = Infinity;
    let maxDepartment = '';
    let minDepartment = '';


    const secondaryX = xacKey?.length > 1 ? xacKey[xacKey.length - 1] : undefined

    let maxDate = '';
    let minDate = '';


    const belowAverageDepartments = new Set();
    const aboveAverageDepartments = new Set();

    data.forEach(item => {

        const revenue = item[yacKey];

        if (revenue > maxRevenue) {
            maxRevenue = revenue;
            maxDepartment = item[xacKey[0]];
            maxDate = item[secondaryX]
        }

        if (revenue < minRevenue) {
            minRevenue = revenue;
            minDepartment = item[xacKey[0]];
            minDate = item[secondaryX]
        }
        // Average Comparison
        if (revenue < averageRevenue) {
            belowAverageDepartments.add(item[xacKey[0]]);
        } else if (revenue > averageRevenue) {
            aboveAverageDepartments.add(item[xacKey[0]]);
        }
    });


    const maxPercentage = ((maxRevenue / Math.abs(totalRevenue)) * 100).toFixed(2);
    const minPercentage = ((minRevenue / Math.abs(totalRevenue)) * 100).toFixed(2);


    const half_revenue = ((totalRevenue / 2) - maxRevenue) <= 0;

    var result = '<ul>';

    if (half_revenue) {

        result += `<li> <strong>${maxDepartment}</strong> is indeed the primary ${yacKey} source, contributing well over half of the total revenue at ${maxPercentage}%. `;

        if (maxDate) {
            result += ` This ${xacKey[0]} generated the highest ${yacKey} in the ${secondaryX} ${maxDate}</li>`
        } else {
            result += '</li>'
        }

    } else {
        result += `<li>The majority of ${yacKey} is coming from <strong>${maxDepartment},</strong> contributing over ${maxPercentage}% of the total ${yacKey}. This is significantly higher than any other ${xacKey[0]}.`

        if (maxDate) {
            result += ` This ${xacKey[0]} generated the highest ${yacKey} in the ${secondaryX} ${maxDate}</li>`
        } else {
            result += '</li>'
        }
    }

    result += `<li>The lowest ${yacKey} was generated by the <strong>${minDepartment},</strong> contributing ${minPercentage}% of the total ${yacKey}.</li>`

    if (belowAverageDepartments.size > 0) {
        result += `<li>These are the ${xacKey[0]} <strong>${Array.from(belowAverageDepartments).join(', ')}</strong> which are generating below-average ${yacKey} as follows.</li>`
    }

    else if (aboveAverageDepartments.size > 0) {
        result += `<li>These are the ${xacKey[0]} <strong>${Array.from(aboveAverageDepartments).join(', ')}</strong> which are generating above-average ${yacKey} as follows.</li>`
    }

    result += `</ul>`;

    return result;
};







// Function to create a data story

export const dataStoryCreateV1 = (data, XAxis = [], __y = [], yAxisTotals, columnMeta,) => {

    const YAxis = __y?.filter((y) => y !== 'Percentage Change')


    var result = ''


    const y = YAxis[0];
    const y_total = yAxisTotals?.[y]?.sum;
    const y_total_formatted = get_formated_cell_data(columnMeta, y, y_total)

    const dim_title = getAliasOfXAxis(XAxis[0]);

    var title = '';

    if (YAxis?.length > 1) {
        title += `<h1>${YAxis.join(" and ")} Analysis</h1>`;
    } else {
        title += `<h1>${YAxis[0]} Analysis by ${dim_title}</h1>`;
    };


    var total = `<h3>Total ${y} Summary</h3>`;
    var sub = `<p>The total ${y} across all ${dim_title} is approximately ${y_total_formatted}.</p>`;

    if (XAxis?.length === 1 && date_keys.indexOf(XAxis[0]?.toLocaleLowerCase()) > -1) {

        const min_date = data?.[0]?.[XAxis[0]];
        const max_date = data?.[data.length - 1]?.[XAxis[0]];

        sub = `The total ${y} generated from the ${XAxis[0]} ${min_date} to ${max_date} is approximately ${y_total_formatted}.`
    }



    result += title;
    result += total;
    result += sub;

    const breakdown_data = create_breakdown(data, XAxis, YAxis, yAxisTotals, columnMeta);

    if (breakdown_data) {
        result += '<div>'
        const dim_title = getAliasOfXAxis(XAxis[0]);
        if (YAxis?.length > 1) {
            result += `<h2> ${YAxis?.join(' and ')} Breakdown by ${dim_title} </h2><ul>`
        }
        else {
            result += `<h2> ${y} ${dim_title} Breakdown</h2><ul>`
        }
    }

    result += breakdown_data;
    result += '</div>'


    const key_insight = key_trend_v1(data, XAxis, YAxis, yAxisTotals, columnMeta);

    if (key_insight) {
        const dim_title = getAliasOfXAxis(XAxis[0]);
        result += '<div>'
        result += `<h2>Key Insights and Trends</h2><ul>`
    }
    result += key_insight;
    result += '</div>'

    return result;

};
