import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { Row } from './editor.styled';
import SqlEditor from '../sql.editor';
import PlaySvg from '../svg/play';
import SharedTable from '../shared-table';
import WarningSvg from '../svg/warning'
import { NormalButton } from '../hoc/normal.button';
import CheckedIcon from '../svg/checkMark';
import { get_matched_parm_from_query, replace_query_with_match_parm_value } from '../../utils'
import ParameterizedDataPopup from '../report-editor/p.field';
import * as enums from '../../utils/enums';
import { showPopup } from '../../utils';
import { connect } from 'react-redux';
import Close from '../svg/close';
import { getTimeDifferenceFormatted } from '../../utils/dateTime';
import StopIcon from '../svg/icons-v2/stopIcon';
import FormatIcon from '../svg/icons-v2/formatIcon';
import PlayCircledIcon from '../svg/icons-v2/playCircledIcon';

const SqlEditorSection = React.memo((props) => {

    const {

        initalValue,
        sql_query,
        set_sql_query,
        test_sql,
        sql_loader,
        sql_result,
        sql_error,
        show_sql_editor,
        db_info_id,
        setSelectedSql,
        selectedSql,
        localTableColumns

    } = props;

    const resizableDivRef = useRef(null);
    const [isResizing, setIsResizing] = useState(false);
    const [dimensions, setDimensions] = useState({ height: 320, bottomHeight: 170 });
    const [sqlQueryResult, setSqlQueryResult] = useState({});
    const [tblDataIndex, setTblDataIndex] = useState(0);
    const [timer, setTimer] = useState({startTime: '', endTime: ''}); // for request start and end time

    const [controller, setController] = useState(null);
    const [isQueryformat, setIsQueryformat] = useState(false)
  
    const startResizing = useCallback(() => setIsResizing(true), []);
    const stopResizing = useCallback(() => setIsResizing(false), []);
  
    const handleResize = useCallback((e) => {
        if (isResizing && resizableDivRef.current) {
            const containerHeight = resizableDivRef.current.clientHeight;
            const newTopHeight = e.clientY - resizableDivRef.current.getBoundingClientRect().top-50;
            const newBottomHeight = containerHeight - newTopHeight - 50;
            if (newTopHeight >= 10 && newBottomHeight >= 30) {
                setDimensions({height: newTopHeight, bottomHeight: newBottomHeight})
            }
          }
        },
    [isResizing]);

    const open_parametrize_popup = (query, parameters) => {
        const final_data_obj = parameters ? JSON.parse(JSON.stringify(parameters)) : {};
        const parameters_keys = Object.keys(parameters);
        showPopup(undefined, undefined, enums.default.popupType.element, ParameterizedDataPopup, {
            parameters_fields: parameters_keys,
            btn_text: 'View Result',
            report_name: 'test',
            view_function: (_field_data_) => {
                Object.keys(_field_data_).forEach(k => final_data_obj[k].value = _field_data_[k]);
                const final_q = replace_query_with_match_parm_value(query, final_data_obj)
                test_sql(final_q)
            },
        })
    }

    useEffect(()=> {
        setTimer(prev => ({...prev, endTime: new Date()}));
        if(sql_result && Array.isArray(sql_result[0])){
            setSqlQueryResult({...sql_result});
            setTblDataIndex(sql_result?.length-1)
        }else if(sql_result && !Array.isArray(sql_result)){
            setSqlQueryResult({0: [sql_result]});
            setTblDataIndex(0)
        }else if(sql_result){
            setSqlQueryResult({0: sql_result});
            setTblDataIndex(0)
        }else {
            setSqlQueryResult( sql_result );
            setTblDataIndex(0)
        }
    },[sql_result]);

    const handleTabIndexChangeOnClick =(key, event) => {
        event.stopPropagation();
        setTblDataIndex(key);
    };

    const handleRemoveQueryResult =(keyToRemove, event) => {
        event.stopPropagation();
        setSqlQueryResult(prevState => {
            const newObject = { ...prevState };
            delete newObject[keyToRemove];
            return newObject ;
        });
        if(keyToRemove === tblDataIndex ){
            const dataKeys = Object.keys(sqlQueryResult)?.filter(key => key != keyToRemove );
            setTblDataIndex(dataKeys?.[dataKeys.length-1] || 0);
        }
    };

    // this stops unnecessary re-rendering of table component
    const memoizedcolumnsToShowData = useMemo(() => sqlQueryResult?.[tblDataIndex] ? Object.keys(sqlQueryResult[tblDataIndex]?.[0] || {}) : undefined, [sqlQueryResult, sqlQueryResult?.[tblDataIndex]]);
    const memoizedtableHeadData = useMemo(() => sqlQueryResult?.[tblDataIndex] ? Object.keys(sqlQueryResult[tblDataIndex]?.[0] || {}) : undefined, [sqlQueryResult, sqlQueryResult?.[tblDataIndex]]);

    return (
        <div 
            ref={resizableDivRef} 
            style={{ height: '29rem' }}  
            onMouseMove={handleResize}
            onMouseUp={stopResizing}
            onMouseLeave={stopResizing}
        >
            <div
                style={{ marginBottom: '3rem', borderBottom: '2px solid gery', width: '100%', height: `${dimensions.height}px` }}
            >

                <Row style={{ flexDirection: 'column', height: '100%' }}>
                    <SqlEditor
                        set_sql_query={(value) => {
                            set_sql_query(value)
                        }}
                        initalValue={initalValue}
                        localTableColumns={localTableColumns}
                        placeholder='Write your SQL query'
                        setSelectedSql={setSelectedSql}
                        selectedSql={selectedSql}
                        isQueryformat={isQueryformat}
                    />
                </Row>

                <Row style={{ marginTop: '.5rem', justifyContent: 'space-between' }}>
                    <div style={{display: 'flex'}}>
                        {   sqlQueryResult &&
                            Object.keys(sqlQueryResult)?.length>0 && 
                            Object.keys(sqlQueryResult)?.map((key, index) => 
                            <div 
                                key={key}
                                className='query_tabs'
                                style={{border: key == tblDataIndex && '1px solid #337DFF', color: key == tblDataIndex && '#337DFF'}} 
                                onClick={(event)=>handleTabIndexChangeOnClick(key,event)}
                            >
                                {`Result ${Number(key)+1}`}
                                    <span className='remove_qry_result'
                                        onClick={(event)=>handleRemoveQueryResult(key,event)} 
                                        title='Delete'
                                    >
                                        <Close size={'0.8rem'} height={'0.6rem'} color={'#b00505'} />
                                    </span>
                            </div>) 
                        }
                    </div>
                    <div style={{display: 'flex'}}>
                    <NormalButton
                        width='7.5rem'
                        primary={true}
                        bgColor={'#e1e7ff'}
                        hoverBg={'#c6d0f7'}
                        color={'#000'}
                        borderRadius={'8px'}
                        onClick={() => {
                           !isQueryformat && setIsQueryformat(true);
                            setTimeout(()=>{
                            setIsQueryformat(false);
                           },10)
                        }}
                        icon={<FormatIcon size='1rem' height='1rem' color="#fff" />}
                        label="Format Query"
                    />
                    <NormalButton
                        width='7rem'
                        mL='0.5rem'
                        primary={true}
                        bgColor={'#ffe1e9'} 
                        hoverBg={'#f7c7d4'}
                        color={'#000'}
                        borderRadius={'8px'}
                        onClick={() => {
                            controller && controller.abort()
                        }}
                        icon={<StopIcon size='1rem' height='1rem' color="#fff" />}
                        label="Stop Query"
                    />
                    <NormalButton
                        width='7rem'
                        mL='0.5rem'
                        bgColor={'#ccffe3'} 
                        hoverBg={'#b3f9d2'}
                        color={'#000'}
                        borderRadius={'8px'}
                        onClick={() => {
                            // const found = get_matched_parm_from_query(initalValue)
                            // if (found && Object.keys(found).length > 0) {
                            //     console.log("found", found)
                            //     open_parametrize_popup(initalValue, found)
                            // } else {

                            const newController = new AbortController();
                            setController(newController);
                            test_sql(sql_query, newController.signal);
                            setTimer(prev => ({...prev, startTime: new Date()}))
                            // }
                            // test_sql(initalValue)
                        }}
                        icon={<PlayCircledIcon size='1rem' height='1rem' color="#fff" />}
                        label="Test Query"
                    />



                    {show_sql_editor && (
                        <NormalButton
                            bgColor='#41740d'
                            hoverBg="#2e520a"
                            mL=".5rem"
                            onClick={() => {
                                props.set_show_sql_editor(false)
                            }}
                            color="#fff"
                            icon={<CheckedIcon style={{ transform: 'rotate(178deg)' }} size='.9rem' height='.9rem' color="#fff" />}
                            label="Approve SQL"
                        />
                    )}
                    </div>
                </Row>
            </div>

            <Row style={{
                flexDirection: 'column'
            }}>
                <div onMouseDown={startResizing} style={{ cursor: 'row-resize', width: '100%', height: '3px', background: '#e2e2e2', marginBottom: '2px' }} ></div>
                {!sql_loader && (
                    <React.Fragment>
                        {sqlQueryResult && sqlQueryResult?.[tblDataIndex] && sqlQueryResult[tblDataIndex]?.length > 0 && (
                            <div>
                            {/* <div style={{ width: '100%', height: (490 - dimensions.height)+'px',overflow: 'hidden'  }}> */}
                            <div style={{ width: '100%', height: (dimensions?.bottomHeight)+'px',overflow: 'hidden'  }}>
                                <SharedTable
                                    // height={`${480 - dimensions.height}px`}
                                    height={`100%`}
                                    tableData={sqlQueryResult?.[tblDataIndex] || []}
                                    columnsToShow={memoizedcolumnsToShowData}
                                    tableHead={memoizedtableHeadData}
                                    // columnsToShow={sqlQueryResult[tblDataIndex] ? Object.keys(sqlQueryResult[tblDataIndex]?.[0] || {}) : undefined}
                                    // tableHead={sqlQueryResult[tblDataIndex] ? Object.keys(sqlQueryResult[tblDataIndex][0] || {}) : undefined}
                                    fontSize=".7rem"
                                    rowHeight="1.8rem"
                                />
                            </div>
                                <p style={{ margin: '4px 0', padding: '8px 4px', fontSize: '14px', borderRadius: '6px', background: '#EFEFEF' }}>
                                    Total Number Of Records :  <strong>{sqlQueryResult?.[tblDataIndex]?.length}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;
                                    Max Records Limit:  <strong>{200}</strong>
                                    &nbsp;&nbsp;|&nbsp;&nbsp;Query Time : <strong>{getTimeDifferenceFormatted(timer?.startTime, timer?.endTime)}</strong>
                                </p>
                            </div>
                        )}

                        {sqlQueryResult && sqlQueryResult[tblDataIndex] && sqlQueryResult[tblDataIndex].length == 0 && <p style={{ textAlign: 'center' }}>No Record Found</p>}
                        {sql_error && (
                            <div style={{
                                display: 'flex',
                                width: '100%',
                                alignItems: 'center',
                                flexDirection: 'column',
                                // height: '16rem',
                                justifyContent: 'center'
                            }}>
                                <WarningSvg size="2rem" height="2rem" />
                                <p style={{ fontSize: '.9rem' }}>{sql_error.message}</p>
                            </div>
                        )}
                    </React.Fragment>
                )}

                {sql_loader && <p style={{ textAlign: 'center', margin: 'auto' }}>Please wait while we are fetching the results....</p>}

            </Row>
        </div>
    )
})




const mapStateToProps = (state) => ({
    schema_table_info: state.reportBuilder.schema_table_info,
})

export default connect(mapStateToProps, {})(SqlEditorSection);