import React, { useState, useEffect } from 'react'
import { MainWrapper } from './module.access.styled';
import { PageInnerWrapper } from '../hoc/styled.hoc';
import SharedTable from '../shared-table';
import { getAccessGroup, clearGroupAccess } from '../../actions/access_group';
import { connect } from 'react-redux';
import { Button } from '../hoc/button';
import { withRouter } from 'react-router-dom';
import Loading from '../loading';




const Index = (props) => {

    const { accessGroupList, getAccessGroup } = props;
    const [isLoding, setIsloding] = useState(true)

    useEffect(() => {
        let _page_info = {
            title: 'ACCESS Control Layer',
            extraComponent: <Button primary={true} onClick={() => {
                props.history.push('/module_add_edit')
                props.clearGroupAccess()
            }}>
                Add New
            </Button>
        }
        props.dispatchHeaderInformation(_page_info)
        getAccessGroup()
    }, [])

    useEffect(()=>{
        if(accessGroupList?.length > -1){
            setIsloding(false);
        }
    },[accessGroupList])



    return (
        <MainWrapper>
            <PageInnerWrapper padding={'10px'} style={{ marginTop: '1rem' }}>
                <SharedTable
                    isLoding={isLoding}
                    height={"calc(100vh - 180px)"}
                    // actionOnHover={true}
                    editAction={(item) => {
                        props.history.push(`/module_add_edit?id=${item.id}`);
                    }}
                    // deleteAction={() => { }}
                    // duplicateAction={() => { }}
                    tableData={(accessGroupList && accessGroupList.length > 0) ? accessGroupList : undefined}
                    tableHead={["Access Group", "Description", "Actions"]}
                    columnsToShow={["name", "description",]}
                />
                {(accessGroupList == undefined || accessGroupList.length == 0) && !Loading ? <p style={{ textAlign: "center" }}>No Record Found</p> : null}
            </PageInnerWrapper>

        </MainWrapper>
    )

}



const mapStateToProps = (state) => ({
    accessGroupList: state.groupAccessReducer.accessGroupList
})

export default withRouter(connect(mapStateToProps, { getAccessGroup, clearGroupAccess })(Index))