import React from "react";

const SqlPageIcon = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1772 2048"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        transform="translate(300,1)"
        d="m0 0h1144l21 4 16 6 15 8 14 11 26 26 8 7 111 111 8 7 59 59 8 7 9 9 9 11 9 14 7 15 5 17 2 14 1 17v1577l-1 19-3 17-6 18-7 14-10 14-11 12-14 11-16 9-16 6-13 3-8 1h-1368l-17-3-15-5-19-10-16-13-7-7-10-13-9-16-6-16-4-20v-605l5-6 4-2 9 1 6 7 1 594 2 18 6 18 8 14 11 13 8 7 14 9 14 6 12 3 7 1h1362l18-4 16-7 13-9 9-8 8-10 8-14 6-16 2-10 1-12v-1607h-325l-1-1-2-57-3-105-3-112v-22h-1099l-15 1-16 4-17 8-12 9-12 12-9 14-7 16-3 13-1 10-1 675-4 6-4 2h-7l-6-3-3-5v-684l3-16 6-17 9-17 10-13 14-14 16-11 17-8 17-5zm1137 24v42l5 181 1 45 1 5h293l-2-6-9-13-12-13-22-22-8-7-59-59-8-7-112-112-8-7-10-9-13-8-15-6-20-4z"
        fill="#B4B4B4"
      />
      <path
        transform="translate(209,836)"
        d="m0 0h946v455h-946l-22-3-22-5-23-8-25-12-19-12-14-11-12-11-5-4-7-8-9-10-13-18-11-19-10-21-8-24-5-23-2-13-1-13v-26l3-26 6-25 7-21 13-27 11-17 12-16 14-15 12-12 14-11 13-9 19-11 17-8 27-9 23-5zm6 24-23 3-21 5-22 8-16 8-11 6-17 12-14 12-16 16-13 17-11 18-8 16-6 15-7 23-4 26-1 14v10l2 22 5 25 7 21 8 18 10 18 11 15 9 11 20 20 16 12 18 11 23 11 24 8 20 4 18 2h915v-406l-1-1z"
        fill="#B4B4B4"
      />
      <path
        transform="translate(610,947)"
        d="m0 0 18 1 21 4 19 7 14 8 14 11 8 8 9 12 10 19 5 17 2 13v26l-3 17-5 15-8 15-8 11-16 16-15 10-15 7-6 1 4 5 11 7 12 3h7l11-3 10-5 9-8 5 5 9 10 9 12-4 6-11 9-16 8-11 3-7 1h-21l-15-3-16-6-13-8-14-11-9-9-8-7-2-2-25-6-17-7-16-10-13-12-9-10-9-14-7-17-4-16-1-7v-26l3-17 6-17 9-16 11-14 11-10 15-10 17-8 13-4 17-3zm-10 44-15 4-11 6-10 8-9 11-7 14-3 11-1 9v13l3 15 5 13 7 10 6 7 11 8 13 6 12 3h20l16-4 13-7 11-9 8-11 6-12 3-12 1-8v-14l-3-15-5-12-6-9-9-10-14-9-14-5-5-1z"
        fill="#B4B4B4"
      />
      <path
        transform="translate(352,947)"
        d="m0 0 28 2 19 4 17 6 14 7-1 5-14 34-6-2-19-8-20-5h-31l-12 4-6 4-5 6-1 3v11l4 6 5 4 8 4 32 8 23 6 16 6 13 7 10 9 6 7 5 10 2 9v21l-3 12-7 13-11 12-13 8-16 7-19 4-8 1h-29l-21-3-23-6-15-6-15-9 1-5 15-33h4l16 9 19 7 20 4h27l15-4 8-6 4-8v-7l-4-8-9-6-18-6-40-10-17-6-13-7-10-8-6-8-5-10-2-8v-25l5-15 7-11 8-9 14-9 11-5 15-4 13-2z"
        fill="#B4B4B4"
      />
      <path
        transform="translate(800,951)"
        d="m0 0h50l1 1v175h107l1 1v39l-1 1h-158l-1-1v-214z"
        fill="#B4B4B4"
      />
      <path
        transform="translate(306,239)"
        d="m0 0h19l5 2v524l-2 1h-22l-1-1v-525z"
        fill="#B4B4B4"
      />
      <path
        transform="translate(305,1354)"
        d="m0 0h24l1 1v350l-1 1h-24z"
        fill="#B4B4B4"
      />
    </svg>
  );
};

export default SqlPageIcon;
