import React from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { TitleWrapper } from './title.styled';
import { saveReport } from '../../actions/report';
import { setActiveEditReport } from '../../actions/report.helper';
import { themeObject } from '../../theme'
const theme = themeObject;


const Title = (props) => {

    const { style, title, center, leftIcon, isMobile, height, textAlign } = props;
    const headerStyle = theme.insight.header;
    const titleStyle = headerStyle && headerStyle.title;

    return (
        <TitleWrapper _style={titleStyle} isMobile={isMobile} height={height}>
            {leftIcon && <div style={{ marginRight: '1rem' }}>{leftIcon}</div>}
            <h2
                style={{ textAlign: center ? "center" : "left" }}
            ><span>{title ? title : ""}</span></h2>
            {props.extraOption ? props.extraOption : null}
        </TitleWrapper>
    )
}



const mapStateToProps = state => {
    const { menus } = state.menuGroupReducer;
    return { menus };
};


export default withRouter(connect(mapStateToProps, { setActiveEditReport, saveReport })(Title));