import {  simpleDispatch_get, simpleDispatch_post} from './helper';
import * as actionTypes from '../action-types/predictive_analytics';
import { constants } from '../utils/constants';


export const getPredectiveData = (id) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.PREDICTIVE.POINT + constants.END_POINTS.PREDICTIVE.GET_PREDICTIVE_RESULT

    const post = {
        doc_id: id
    }

    await simpleDispatch_post(dispatch, url, post, actionTypes.GET_PREDICTIVE_RESULT, true);
};



export const getPredectiveDataByMonth = (id) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.PREDICTIVE.POINT + constants.END_POINTS.PREDICTIVE.GET_PREDICTIVE_SPECALITY_BY_MONTH

    const post = {
        pred_data: {
            dep_id: "1",
            month: "Jun"
        }
    }

    await simpleDispatch_post(dispatch, url, post, actionTypes.GET_PREDICTIVE_SPECALITY_BY_MONTH, true);

};


export const getPredectiveDataByYear = (id) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.PREDICTIVE.POINT + constants.END_POINTS.PREDICTIVE.GET_PREDICTIVE_SPECALITY_BY_YEAR

    const post = {
        dep_id: id,
    }

    await simpleDispatch_post(dispatch, url, post, actionTypes.GET_PREDICTIVE_SPECALITY_BY_YEAR, true);

};


export const getPredictivePatientsByYearly = (patients) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.PREDICTIVE.POINT + constants.END_POINTS.PREDICTIVE.GET_PREDICTIVE_PATIENTS_BY_YEARLY

    if (patients) {
        const post = {
            patients_numbers: parseInt(patients),
        }
        await simpleDispatch_post(dispatch, url, post, actionTypes.GET_PREDICTIVE_PATIENT_BY_YEARLY_SUCCESS, false);
    }
    else {
        await simpleDispatch_get(dispatch, url, actionTypes.GET_PREDICTIVE_PATIENT_BY_YEARLY_SUCCESS, false);

    }
};


export const getPredictivePatientsByMonthly = (post) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.PREDICTIVE.POINT + constants.END_POINTS.PREDICTIVE.GET_PREDICTIVE_PATIENTS_BY_MONTHLY
    await simpleDispatch_post(dispatch, url, post, actionTypes.GET_PREDICTIVE_PATIENT_BY_MONTHLY_SUCCESS, false);

};

export const getPredictivePatientsByQuarterly = (post) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.PREDICTIVE.POINT + constants.END_POINTS.PREDICTIVE.GET_QUATERLY_PATIENTS_REVENUE_PREDICTION;
    await simpleDispatch_post(dispatch, url, post, actionTypes.GET_PREDICTIVE_PATIENT_BY_QUATERLY_SUCCESS, false);

};
