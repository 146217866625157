import React, { useState, useEffect } from 'react';
import { Button } from '../../hoc/button'
import { Gap } from '../../gap';
import styled from 'styled-components';
import TextBoxV1 from '../../hoc/text.box.v1';
import SelectOptionsV1 from '../../select-option/select.option.v1.1';
import { constants } from '../../../utils/constants';


const ContextEditorWrapper = styled.div`

        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        box-sizing: border-box;
        padding: 15px 27px;
        background: white;
        min-width: 30.857rem;
        max-width: 30.857rem;
        border-radius: 5px;
        box-shadow: 0 3px 6px 0 rgba(0,0,0,0.25);
        border: solid 5px rgb(234,234,234);
        background-color: rgb(255,255,255);
        overflow-x: auto;

        h1{
            font-size: 1.5rem;
            margin: 0px;
            text-align: center;
            color: #222;
            width: 100%;
            font-weight: 500;
            margin-bottom: 1rem;

        }
        &::-webkit-scrollbar {
            background-color:red;
            width:5px
        }
        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }
        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }

`;



const ComplexDate = props => {

    const { close, option, popupKey, data } = props;
    const { save, hints, tableInfo } = data;
    const [local_date_format, set_local_date_format] = useState('')


    useEffect(() => {
        if (hints && hints.complex_date_format) {
            set_local_date_format(hints.complex_date_format)
        }
    }, [hints])


    /**
     * 
     */
    const __save = () => {
        if (local_date_format && local_date_format.length > 0) {
            const clone_hint = hints ? Object.assign({}, hints) : {};
            clone_hint['complex_date_format'] = local_date_format;
            save(clone_hint, tableInfo)
            close(option, popupKey)
        } else {
            alert('please add date format')
        }
    }


    return (
        <ContextEditorWrapper>
            <h1>Select Your Date Format</h1>
            {/* <TextBoxV1
                label="Type Your Date Format"
                value={local_date_format}
                onChange={(e) => {
                    set_local_date_format(e.target.value)
                }}
            /> */}

            <SelectOptionsV1
                label="Choose Your Date Format"
                value={local_date_format}
                options={constants.COMPLEX_DATE_FORMATS}
                on_change={(value) => {
                    set_local_date_format(value)
                }}
            />

            <Gap h="25px" />
            <div style={{
                paddingTop: '25px',
                marginTop: 'auto',
                display: 'block',
                width: '100%'
            }}>
                <Button
                    primary={true}
                    width='6rem'
                    mR="16px"
                    onClick={__save}
                >
                    Save
                </Button>
                <Button
                    width='6rem'
                    bgColor={'#e6e6e6'}
                    style={{
                        marginBottom: 0
                    }}
                    onClick={() => {
                        close(option, popupKey)
                    }}
                >Cancel</Button>
            </div>
        </ContextEditorWrapper>
    );
};

export default ComplexDate

