import React, { useEffect, useRef } from 'react';
import { DimensionWrapper, DimensionCard } from './dimensions.styled';
import { withRouter } from 'react-router-dom'
import { _getDataFromReportingServer } from '../../utils'

const Index = (props) => {

    const { 
        dimensions, question, isTrendFilters,
        wrapper_right_margin, wrapper_left_margin, yAxisLabelOffset, widthOfChart, widthOfYAxis, isShow
    } = props;
    

    const wrapperRef = useRef(null);

    useEffect(() => {
        if (question && isShow) {
            const regex = /\brevenue\b.*\bnephrology\b/;
            const str = question.toLowerCase();
            let m = regex.exec(str)
            // setButtonShow(m)
        }
    }, [question, isTrendFilters, isShow])


    return (
        <React.Fragment >
            <DimensionWrapper
                wrapper_right_margin={wrapper_right_margin}
                wrapper_left_margin={wrapper_left_margin + yAxisLabelOffset + widthOfYAxis}
                yAxisLabelOffset={yAxisLabelOffset}
                widthOfChart={widthOfChart}
                isTrendFilters={isTrendFilters}
                isShow={isShow}
            >

                <div className="dimansion_wrapper">

                    {dimensions && dimensions.length > 0 && dimensions.map((item, index) => {
                        return (
                            <DimensionCard
                                onClick={() => {
                                    _getDataFromReportingServer(item.question, props.history)
                                }}
                                ref={wrapperRef}
                                key={'dim_chart' + index}
                            >
                                <span className="top_title">{item.title} </span>
                            </DimensionCard>
                        )
                    })}
                </div>
            </DimensionWrapper>
        </React.Fragment>
    )
}

export default withRouter(Index)
