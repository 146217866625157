import React, { useState, useEffect } from 'react';
import { Button } from '../../hoc/button'
import { Gap } from '../../gap';
import CrossSvg from '../../svg/close';
import { connect } from 'react-redux';
import SearchSuggestion from './search.suggestion';
import styled from 'styled-components';
import { getDistinctDataForColumn } from '../../../actions/report'

export const HintsWrapper = styled.div`
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        box-sizing: border-box;
        padding: 15px 27px;
        background: white;
        min-width: 22.857rem;
        max-width: 22.857rem;
        min-height: 27.143rem;
        max-height: 27.143rem;
        border-radius: 8px;
        box-shadow: 0 3px 6px 0 rgba(0,0,0,0.25);
        background-color: rgb(255,255,255);
        overflow: auto;
        box-sizing: border-box;

`;


export const AddNewTag = styled.div`
    width: 100%;
    padding: ${props => props.padding || '12px 0rem'};
    /* cursor: pointer; */
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .add_title{
        font-size: 1rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1rem;
        letter-spacing: normal;
        text-align: left;
        color: #1a1a1a;
        margin: 0px;
        padding-left: 10px;
        text-transform: capitalize;
    }   

    .add{
        font-size: ${props => props.size || '35px'} ;
        line-height: 17px;
    }

`;


export const ExistingTags = styled.div`
        width: 100%;
        .e_tags{
            font-size: 1rem;
            font-weight: 500;
            font-stretch: normal;
            line-height: 1.286rem;
            text-align: left;
            color: #1a1a1a;
            margin: 0px;
        }
`;




export const FilterdItemRow = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: auto;
    box-sizing: border-box;
    max-width: 100%;
    max-height: 12rem;
    flex-wrap: wrap;
    border-radius: 6px;
    padding: 0.3rem;
    gap: 4px;
    &::-webkit-scrollbar {
        background-color: #3F3F3F;
        width:4px;
    }

    &::-webkit-scrollbar-track {
        background-color: #F3F3F3;
        border: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dbdbdb;
        border-radius: 10px;
        height: 4px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color:  #3F3F3F;
        height: 4px;
    }

`;


export const Filterd = styled.div`
        height: 1.571rem;
        border-radius: 6px;
        background-color: #dae9ff;
     
        box-sizing: border-box;
        position: relative;
        min-width: 35px;
        cursor: pointer;
        font-size: 0.714rem;
        font-weight: normal;
        text-align: center;
        color: rgb(0, 22, 31);
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0px 8px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.6rem;

        &:hover{
            .filter_hover{ 
                display: flex;
                
            }
        }

     .filter_hover{
         display: none;
         position: absolute;
         top: 0px;
         left: 0px;
         width: 100%;
         height: 100%;
         align-items: center;
         justify-content: center;
         padding: 0px 0px;
         box-sizing: border-box;
         background-color: #f3f3f3;

         div{
             cursor: pointer;
             width: 35px;
             display: flex;
             align-items: center;
             justify-content: center;
         }
     }

`;




const AddValue = props => {

    const { close, option, popupKey, data, distinct_data } = props;


    const { hintData, saveFun, db_info_id } = data;

    const [localExistingValue, setLocalExistingValue] = useState([]);

    const [columnValue, setColumnValues] = useState(undefined)


    useEffect(() => {
        const __key__ = hintData?.table_name + "." + hintData?.column_name
        const hint_values = props?.distinct_data?.[__key__]?.map((c) => c?.[hintData?.column_name]) //['opd', 'ipd', 'surgury', 'roomRent'];

        setColumnValues(hint_values)
    }, [distinct_data])


    useEffect(() => {

        const { table_name, column_name, table_column_values } = hintData;
        if (!props?.distinct_data[table_name] && table_name && column_name) {
            props.getDistinctDataForColumn(db_info_id, table_name, column_name)
        }
        if (table_column_values?.length > 0) setLocalExistingValue(table_column_values)

    }, [hintData])



    /**
     * 
     * @param {*} value 
     */
    const hintSuggestionClick = (value) => {

        const clone_localExistingValue = localExistingValue ? [...localExistingValue] : [];
        if (clone_localExistingValue.indexOf(value) === -1) {
            clone_localExistingValue.push(value);
            setLocalExistingValue(clone_localExistingValue)
        }
    }


    /**
     * 
     * @param {*} index 
     */
    const removeSelectedHint = (index) => {

        const clone_localExistingValue = localExistingValue ? [...localExistingValue] : [];
        clone_localExistingValue.splice(index, 1)
        setLocalExistingValue(clone_localExistingValue)
    }

    const _save = () => {
        saveFun(localExistingValue)
        close(option, popupKey)
    }


    return (
        <HintsWrapper>
            <AddNewTag
            >
                <span className="add_title">Search your value</span>
            </AddNewTag>


            <SearchSuggestion
                placeholder='Enter value'
                items={columnValue || []}
                suggestionOnClick={hintSuggestionClick}
                clear_after_click={true}
                margin={'0'}
            />

            <Gap h="25px" />

            {localExistingValue && localExistingValue.length > 0 && (
                <ExistingTags>
                    <h2 className="e_tags">Existing Value</h2>
                    <Gap h="14px" />

                    <div style={{ height: '12rem', border: '1px solid #ddd', borderRadius: '8px' }}>
                        <FilterdItemRow>
                            {localExistingValue?.map((hint, index) => {
                                return (
                                    <Filterd>
                                        {hint}
                                        <div className="filter_hover">
                                            <div onClick={() => removeSelectedHint(index)}><CrossSvg size="12px" height="12px" fill="#737373" /></div>
                                        </div>
                                    </Filterd>
                                )
                            })}
                        </FilterdItemRow>
                    </div>
                </ExistingTags>
            )}


            <div style={{
                marginTop: 'auto',
                width: '100%',
                textAlign: 'right'
            }}>
                <Button
                    primary={true}
                    bgColor={'linear-gradient(to right, #3E9EFF, #737DFF)'}
                    width='6rem'
                    mR="16px"
                    onClick={_save}
                >
                    Save
                </Button>
                <Button
                    width='6rem'
                    bgColor={'#e6e6e6'}
                    style={{
                        marginBottom: 0
                    }}
                    onClick={() => {
                        close(option, popupKey)
                    }}
                >Cancel</Button>
            </div>
        </HintsWrapper>
    );
};

const mapStateToProps = (state) => ({

    closeHintsPopup: state.hintsReducer.closeHintsPopup,
    distinct_data: state.reportReducer.distinct_data

})

export default connect(mapStateToProps, { getDistinctDataForColumn })(AddValue)

