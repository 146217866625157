import styled from 'styled-components';

export const Wrapper = styled.div`
    background: white;
    border-radius: 5px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);

    min-width: 45vw;
    min-height: 300px;
    padding: 10px;
    border: solid 5px rgb(234, 234, 234);
    position: relative;

    .dismiss{
        position: absolute;
        right: 1rem;
        top: 1rem;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 4px;
        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        cursor: pointer;

        &:hover{
            background: #356d941c;
            svg{
                fill: rgb(0,138,199);
            }
        }
    }
`;


export const HeadingWrapper = styled.div`
    font-size: 1.4rem;
    padding: 5px 10px;
    font-weight: bolder;
    box-sizing: border-box;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 10px;
`;


export const InformationWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 10px;
`;


export const Detail = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 5px 10px;
`;