import styled from 'styled-components';


export const EditorHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #DCE5F2;
    box-sizing: border-box;
    padding: 13px 0px;
    align-items: center;
    .schema_block{
        width: 20.1rem;
        padding: 0px 0px;
        padding-right: 1rem;
        border-right: 1px solid #DCE5F2;
        padding-left: 1rem;
        box-sizing: border-box;
    }
    

`;


export const Wrapper = styled.div`
    width: 100%;
    /* padding: 0 1rem; */
    /* background-color: #fff; */
    background-color: #f7f7f9;
    box-sizing: border-box;
    height : ${props => props.is_popup_window ? '85vh' : '88vh'};


    .row_header{

        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
       
        border-top: 3px dashed #1e5d866e;
        padding: 1rem 0px;
        box-sizing: border-box;

        select{
            font-size: 1.2rem;
            font-weight: bold;
            color: #308bc8;
            text-transform: uppercase;
            background: transparent;
             border: 0px;
        }

        option{
            font-size: 1rem;
        }
        .delete_btn{
            position: absolute;
            right: 0px;
        }

    }


    .add_formula{
        height: 1.9rem;
        font-size: .9rem;
        background: transparent;
        border: 1px solid #000;
        border-radius: 3px;
        cursor: pointer;
        &:hover{
            background: #9e9e9e4f;
        }
    }

    .add_btn_inside_box{
        margin: 5px;
        width: 90px;
        font-size: .9rem;
        font-weight: 400;
        color: #222;
        border: 1px solid #ccc;
        background: #fff;
        padding: 4px;
        box-sizing: border-box;
        border-style: dashed;
        cursor: pointer;

        &:hover{
            /* background: #ccc; */
        }

       
    }

    .input_d_field{
            cursor: pointer;
            border: 1px solid #C5D4EA;
            background-color: #fff;
            margin: 4px;
            padding: 2px 3px;
            vertical-align: middle;
            list-style: none;
            max-width: auto !important;
            min-width: auto !important;
            margin-right: 0px !important;
        }


    .row_box_right_side{
        width: 100%;
        margin-bottom: .8rem;
        display: flex;
        flex-direction: column;
        /* label{
            color: #374a66;
            text-transform: capitalize;
            font-size: .9rem;
            display: block;
            margin-bottom: .2rem;
        }

        input{
            width: 90%;
            height: 2.3rem;
            border: 1.5px solid #ccc;
            border-radius: 3px;
            padding: .4rem;
            box-sizing: border-box;
            font-size: .9rem;
            color: #374a66;

        }

        select{
            width: 100px;
            height: 1.8rem;
            border-radius: 3px;
            box-sizing: border-box;
            font-size: .9rem;
            color: #374a66;
            padding: .4rem;
            background-color: #fff;
        } */
    }


    .d_type_icon{
        width: .9rem;
        height: .9rem;
        border: 1px solid #b6b5b5;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .6rem;
        font-weight: bolder;
        border-radius: 3px;
        color: #b6b5b5;
        background-color: #f6f8fe;
    }


    /* .text_box_wrapper{
        width: 100%;
        position: relative;

        span{
            position: absolute;
            left: .5rem;
            top: .6rem;
        }

        input{
            width: 100%;
            height: 2.3rem;
            border: 1.5px solid #ccc;
            border-radius: 3px;
            padding: 1rem;
            box-sizing: border-box;
            padding-left: 1.8rem;
            font-size: .9rem;
            &:-webkit-placeholder{
                color: #ccc;
            }
            &:focus{

            }
        }
    } */


    .while_loading{
        text-align: center;
        margin-top: 5rem;
        font-weight: 500;
    }
    
    .normal_icon{
        transform: rotate(270deg)
    }


    .right_side_fixed{
        width: 25%;
        position: sticky;
        top: 300px;
        /* right: 0px;; */
    }
  
`;


export const LeftSection = styled.div`
    width: 20rem;
    padding: 1rem 0.5rem 2rem 1rem  ;
    margin-top: 1rem;
    background-color: #fff;
    /* background-color: #F3F3F3; */
    /* box-shadow: 4px 4px 5px 0px rgba(219,219,219,1); */
    box-sizing: border-box;
    box-shadow: 0 1px 2px rgba(56, 65, 74, .15);
    height: ${props => props.height ? (props.height + "px") : 'calc(100vh - 11rem)'};
    overflow: auto;
     /* overflow: hidden; */
        &::-webkit-scrollbar {
            background-color: #3F3F3F;
            width:4px;
        }

        &::-webkit-scrollbar-track {
            background-color: #F3F3F3;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#dbdbdb;
            border-radius:10px;
            height: 4px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color:  #3F3F3F;
            height: 2px;
        }

    .text_label{
        font-size: 1rem;
        margin-bottom: 10px;
        color: #308bc8;
        font-weight: 500;
        margin-top: 1.5rem;
        background: #f7f7f8;
        padding: 10px 1rem;
        box-sizing: border-box;
        border: 1px solid #d8d8d8;
        margin-left: -1rem;
    }
    .view_btn{
        opacity: 0;
    }  

    .report_lists{
        width: 100%;
        max-height: 200px;
        overflow: auto;
        &::-webkit-scrollbar {
            background-color: #3F3F3F;
            width:4px;
        }

        &::-webkit-scrollbar-track {
            background-color: #F3F3F3;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#dbdbdb;
            border-radius:10px;
            height: 4px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color:  #3F3F3F;
            height: 2px;
        }


    }
    
   

    .tbl_title{
        font-size: .9rem;
        font-weight: 600;
        text-transform: uppercase;
        margin: .5rem 1rem;
    }
  


    .tbl_name{
        font-size: .9rem;
        padding: .3rem 1rem;
        padding-left: 0rem;
        padding-right: 2rem;
        font-weight: 500;
        box-sizing: border-box;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
        color: #374a66;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        /* text-transform: capitalize; */
        &:hover{
            background-color: #f1f5fc;
            .tbl_view{
                opacity: 1;
            }
        }
        .tbl_view{
            opacity: 0;
        }
    }

    .active_tbl{
        background-color: #f1f5fc;
    }

    .tbl_name_no_wrap{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: calc(100% - 70px);
    }

    .collapse_icon{
        margin-left: auto;
    }
    .column_list{
        margin: .1rem 0px;
        list-style: none;
        padding-left: 0px;
        max-height: 25rem;
        overflow-y: auto;
        &::-webkit-scrollbar {
            background-color: #3F3F3F;
            width:4px;
        }

        &::-webkit-scrollbar-track {
            background-color: #F3F3F3;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#dbdbdb;
            border-radius:10px;
            height: 4px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color:  #3F3F3F;
            height: 2px;
        }



        li{
            font-size: .9rem;
            line-height: 1.5rem;
            cursor: pointer;
            display: flex;
            color: #374a66;
            padding-left: 1.5rem;
            align-items: center;
            justify-content: flex-start;
            /* text-transform: capitalize; */
            width: 100%;
            box-sizing: border-box;
            &:hover{
                background-color: #f1f5fc;
            }
        }
    }

    .column_name_title{
       margin: 0px;
        font-size: .9rem;
        font-weight: 600;
        color: #444;
    }


    .column_sorting{
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        .rotate_180deg {
            transform: rotate(90deg);
        }
    }
`;


export const RightSection = styled.div`
    width: calc(100% - 20rem);
    height: ${props => props.height ? (props.height + "px") : 'calc(100vh - 153px)'};
    /* padding: 1rem; */
    box-sizing: border-box;
    padding-top: ${props => props.paddingTop ? (props.paddingTop + "px") : '0rem'};

    .right_side_col{
        display: flex;
        flex-direction: column;

        p{
            margin: auto;
            text-align: center;
            font-size: .8rem;
            color: #7a96c3;
            font-weight: 400;
            text-transform: capitalize;
        }
    }
    .right_panel_header{
        display: flex;
        padding: 12px 16px;
        border-bottom: 1px solid #C5D4EA;
        color: #374a66;
        text-transform: capitalize;
        font-size: .9rem;
        position: relative;

        .header_close{
            position: absolute;
            right: 1rem;
            height: 100%;
            top: 0px;
            display: flex;
            align-items: center;
            cursor: pointer;

            svg{
                fill: #7a96c3;
            }
        }

        p{
            margin: 0px;
            text-overflow: ellipsis;
            overflow: hidden;
            font-weight: 600;
            font-size: 1rem;
        }

    }


    .right_section_scroll{
        padding: 1rem;
        width: 100%;
        padding-left: 1.5rem;
        box-sizing: border-box;
        overflow: auto;
        display: flex;
        flex-direction: column;
        /* height: calc(100vh - 10rem); */ 
        height: calc(100vh - 11rem); // for layout_editor popup add/cancel footer btn

        &::-webkit-scrollbar {
            background-color: #3F3F3F;
            width:4px;
        }

        &::-webkit-scrollbar-track {
            background-color: #F3F3F3;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#dbdbdb;
            border-radius:10px;
            height: 4px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color:  #3F3F3F;
            height: 2px;
        }


    }
    .column_info_container{
        width: 24%;
        max-height: 35.6rem;
        position: sticky;
        top: 0;
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
            background-color: #3F3F3F;
            width:4px;
        }

        &::-webkit-scrollbar-track {
            background-color: #F3F3F3;
            border: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #dbdbdb;
            border-radius: 10px;
            height: 4px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color:  #3F3F3F;
            height: 4px;
        }
    }

`;


export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    .add_extra_column{
        width: 16rem;
        position: absolute;
        right: 0px;
        display: flex;
        z-index: 2;
        justify-content: right;

        /* flex-direction: column;   */
    }

    .add_btn{
        /* width: 2rem;
        height: 2rem; */
        width: 1.8rem;
        height: 1.8rem;
        margin-left: 1rem;
        background: linear-gradient(to right, #3E9EFF, #737DFF);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        font-size: 1rem;
        cursor: pointer;
    }

    .add_column_section{
        width: 100%;
        /* height: 62px; */
        background: #fff;
        box-shadow: 2px 3px 4px 4px #00000014;
        margin-top: 4px;
        box-sizing: border-box;
        padding: 7px;
        display: flex;
        flex-direction: row;    
        align-items: center;
        justify-content: space-between;

        input{
            height: 2rem;
            border: 1px solid #ccc;
            padding: 3px;
            box-sizing: border-box;
            width: calc(100% - 3.1rem);
            border-radius: 6px;
            font-size: .9rem
        }

        button{
            height: 2rem;
            width: 3rem;
            border: 0px;
            font-size: .9rem;
            border-radius: 3px;
            cursor: pointer;
        }
    }
    .add_big_btn{
        text-align: center;
        display: flex;
        margin: auto;
        margin-top: 3rem;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        p{
            margin: 0px;
            margin-top: .9rem;
            font-size: .9rem;
            color: rgba(0,0,0,0.54);
            font-weight: 500;

        }
    }

    .add_btn_new_field {
        padding: 5px 9px;
        border-radius: 3px;
        display: inline-block;
        margin-top: 10px;
        box-shadow: rgb(0 0 0 / 53%) 0px 0px 2px 0px;
        font-size: .9rem;
        cursor: pointer;
        margin-left: .3rem;
    }

    .label{
        font-size: 1rem;
        color: #374a66;
        text-transform: capitalize;
        /* margin-top: 0rem; */
        margin-bottom: .5rem;
    }
    .cell_box{
        width: 30%;
        .label{
            margin-top: .8rem;
        }
    }
    .cell_box_border{
        height: 6rem;  
        border: 1px solid #e3e3e3;
        border-radius: 5px;
        width: 96%;
        /* background-color: #f6f8fe; */
        padding: 0.5rem;
        .cell_flex{
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            div{
                vertical-align: middle;
            }
        }

        ul{
            padding: 0rem;
            margin: 0;
            width: 99%;
            overflow: auto;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            max-height:6rem;
            height:6rem;
            flex-wrap: wrap;
            align-content: flex-start;
            box-sizing: border-box;
            /* padding-left: 1.5rem; */

            &::-webkit-scrollbar {
            background-color: ${props => props.bgColor || undefined };
            width:4px;
        }

        &::-webkit-scrollbar-track {
            background-color: #F3F3F3;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#dbdbdb;
            border-radius:10px;
            height: 4px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color:  #3F3F3F;
            height: 2px;
        }


        li{
            /* max-width: 11rem; */
            /* min-width: 11rem; */
            min-width: fit-content;
            padding: 2px 6px;
            margin: .1rem;
            cursor: pointer;
            list-style-type: none;
            /* background:red; */
            background: #E2FCF4;
            border-radius: 4px;

            &:hover{
                .close{
                    opacity: 1;
                    cursor: pointer;
                }
            }

            .column_name{
                text-align: left;
                font-size: .8rem;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                /* color: #FFEBDF; */
                text-transform: capitalize;
            }

            .close{
                margin-left: 10px;
                opacity: 0;
                padding-bottom: 0.2rem;
            }
            
        }
    }
        
    }



    h3{
        margin: 0px;
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 1rem;
        color: #222;;
    }

    .query_tabs{
        border-radius: 6px;
        padding: 6px 10px 0 10px;
        margin-right: 12px;
        box-sizing: border-box;
        cursor: pointer;
        font-size: 0.9rem;
        border: 1px solid #eaeaea;
        color: grey;
        &:hover{
            background-color: #f6f8fe;
            border: 1px solid #337DFF;
        }
    }
    .remove_qry_result{
        margin-left: 6px
    }

`;


export const RowWrapper = styled.div`
    /* position: relative; */
    padding: 1rem;
    margin:${(props)=>  props?.margin || '0'};
    box-sizing: border-box;
    /* margin-bottom: 2rem; */
    /* display: flex;
    flex-direction: row;
    justify-content: space-between; */
    background: #fff;
    /* box-Shadow: 4px 4px 5px 0px rgba(219,219,219,1); */
    box-Shadow: 0 1px 2px rgba(56, 65, 74, .15);


    width:${(props) => props.width || '100%' };
`;

export const WrapperBox = styled.div`
    background: #fff;
    box-Shadow: 4px 4px 5px 0px rgba(219,219,219,1);
    margin: 1rem;
`;