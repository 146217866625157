import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CloseSvg from '../svg/close'
import ElementCreator from './selection';
import BlockElement from './block.elements';
import { get_start_and_end_group_exp } from './shared.fun';

const Wrapper = styled.div`
    width: 100%;
    background: #f6f8fe;
    min-height: ${props => props.minHeight || "130px"};
    overflow: 'auto';
    margin-bottom: 1rem;

    

    .delete_row{   

        position: absolute;
        left: 0px;
        top: 10px;
        width: 1.3rem;
        height: 1.3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        cursor: pointer;

        svg{
            fill: red;

        }
        &:hover{
            opacity: .5;
        }
    }
    
    &::-webkit-scrollbar {
        background-color:red;
        width:2px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 2px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 2px;
    }
    ul{
        list-style: none;
        margin: 0px;;
        padding: 0px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 10px 0px;
        margin-left: 1rem;
        margin-top: 0px;
        li{
            box-sizing: border-box;
            display: flex;
            text-align: left;
            font-size: 0.8rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: rgb(55, 74, 102);
            border: 1px solid #9ea3aa69;
            background-color: rgb(255, 255, 255);
            margin: 7px;
            padding: 0px .9rem;
            height: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            vertical-align: middle;
            align-items: center;
            border-radius: 3px;
            text-align: ${props => props.is_opr ? "center" : undefined};
        }
    }
    .block_type{
        padding: 3px 8px;
        padding-right: 21px;
        position: relative;
        top: -12px;
        left: -9px;
        box-sizing: border-box;
        display: inline-block;
        background: #fff;
        border: 1px solid #5ebefffa;
        box-shadow: 2px 2px 3px #ccc;
        border-radius: 5px;
        font-weight: bold;
    }
`;




const enums = {
    IFS: {
        NONE: 'none',
        COLUMN: 'column',
        CONDITIONAL_OPERATOR: 'conditional',
        EXTENSION_OPERATOR: 'and_or_operator',
        MATHEMATICAL_OPERATOR: 'mathematical',
        THEN: 'then',
        TEXT: 'plain_text',
        NF_SP: 'nf_sp',

    },
    CONDITION_VALUES: {
        MATHEMATICAL_OPERATOR: ['+', '-', '*', '/'],
        CONDITIONAL_OPERATOR: ['>', '<', '<=', '>=', '=', 'IS NULL', 'IS NOT NULL', 'LIKE', 'NOT LIKE', 'IN', 'NOT IN'],
        CONDITIONS: ["AND", "OR"],
        IFS: ["Nested IF"],
        THEN: ["Then"]
    }
};


const icon_types = {
    'number': <span>#</span>,
    'date': <span>D</span>,
    'string': <span>T</span>,
    'boolean': <span>B</span>,
    "custom": <span>V</span>
}

const is_MYSQL_condition_line_brack = (value, next_element) => {

    if (next_element && next_element?.type === enums.IFS.THEN || next_element?.expr === enums.IFS.THEN || next_element?.expr === "Then") {
        return true
    }
    else if (!value) return false

    else {
        return ['and', "or", "if", "else if"].indexOf(value?.toLocaleLowerCase()) > -1
    }
}

export const renderIconByDataType = (data_type) => {

    const number_data_types = ['int', 'float', 'double', 'number', 'currency'];

    let dataType = undefined;
    const data_type_to_use = data_type && data_type.toLocaleLowerCase();

    if (number_data_types.indexOf(data_type_to_use) > -1) {
        dataType = 'number'
    }
    if (data_type_to_use === 'string' || data_type_to_use === 'varchar' || data_type_to_use === 'text' || data_type_to_use === 'varchar2' || data_type_to_use === "nvarchar") dataType = 'string'
    if (data_type_to_use === 'boolean' || data_type_to_use === 'tinyint') dataType = 'boolean'
    if (
        data_type_to_use === 'date' ||
        data_type_to_use === 'datetime' ||
        data_type_to_use === "datetime2" ||
        data_type_to_use === "timestamp" ||
        data_type_to_use === "seconds" ||
        data_type_to_use === "yyyymmdd" ||
        data_type_to_use === 'complex_date'

    ) dataType = 'date'

    if (data_type_to_use === 'formula') dataType = 'formula';
    if (!dataType) dataType = "string"

    return (
        <div className="d_type_icon">
            {icon_types[dataType]}
        </div>
    )
}



// this function will return the type of operator 
const __get_ifs_type_by_value_expr_ = (value) => {

    if (!value) return undefined;
    if (enums.CONDITION_VALUES.MATHEMATICAL_OPERATOR.indexOf(value) > -1) return enums.IFS.MATHEMATICAL_OPERATOR;
    if (enums.CONDITION_VALUES.CONDITIONS.indexOf(value) > -1) return enums.IFS.EXTENSION_OPERATOR;
    if (enums.CONDITION_VALUES.CONDITIONAL_OPERATOR.indexOf(value) > -1) return enums.IFS.CONDITIONAL_OPERATOR;
    if (value && value.toLocaleLowerCase() === "then") return "then"

}




// const get_editor_selection = (type) => {

//     if (type === enums.

// }



const show_next_element = (prev_data) => {

    const STATE = {

        IS_NULL: false,
        TEXT_EDITOR: false,
        EXT_OPERATORS: false,
        THEN: false,
        MATHEMATICAL_OPR: false,
        CONDITIONAL_OPERATOR: false,

    }

    // console.log("STATE", STATE, prev_data)

    STATE.TEXT_EDITOR = !prev_data || prev_data.length == 0;

    if (prev_data?.length > 0) {

        const LAST_STATMENT = prev_data[prev_data.length - 1]; //  this is is last element 

        STATE.IS_NULL = LAST_STATMENT.type === enums.IFS.COLUMN;

        // this is for text box editor
        STATE.TEXT_EDITOR = (
            (
                LAST_STATMENT.type === enums.IFS.EXTENSION_OPERATOR ||
                LAST_STATMENT.type === enums.IFS.CONDITIONAL_OPERATOR ||
                LAST_STATMENT.type === enums.IFS.THEN ||
                LAST_STATMENT.type === enums.IFS.MATHEMATICAL_OPERATOR

            )
            &&
            LAST_STATMENT.expr !== enums.IFS.IS_NULL
        )




        /// MATHEMATICAL_OPR

        STATE.MATHEMATICAL_OPR = (
            LAST_STATMENT?.type !== enums.IFS.IS_NULL
            &&
            LAST_STATMENT.type === enums.IFS.COLUMN ||
            LAST_STATMENT.type === enums.IFS.TEXT
        )


        ///  /// CONDITIONAL_OPERATOR


        STATE.CONDITIONAL_OPERATOR = (
            LAST_STATMENT?.type !== enums.IFS.IS_NULL
            &&
            LAST_STATMENT.type === enums.IFS.COLUMN ||
            LAST_STATMENT.type === enums.IFS.TEXT
        )


        if (prev_data?.length >= 2) {

            // ENABLE EXTENSION_OPERATOR 

            STATE.EXT_OPERATORS = (
                LAST_STATMENT.type === enums.IFS.IS_NULL ||
                (
                    (
                        LAST_STATMENT?.type === enums.IFS.TEXT ||
                        LAST_STATMENT?.type === enums.IFS.COLUMN
                    )
                    &&
                    (
                        prev_data[prev_data.length - 2].type === enums.IFS.CONDITIONAL_OPERATOR ||
                        // prev_data[prev_data.length - 2].type === enums.IFS.MATHEMATICAL_OPERATOR ||
                        prev_data[prev_data.length - 2].type === enums.IFS.THEN

                    )

                )
            )


            //  Enable Then
            STATE.THEN = (
                LAST_STATMENT.type === enums.IFS.IS_NULL ||
                (
                    (
                        LAST_STATMENT.type === enums.IFS.TEXT ||
                        LAST_STATMENT.type === enums.IFS.COLUMN
                    )
                    &&
                    (
                        prev_data[prev_data.length - 2].type == enums.IFS.CONDITIONAL_OPERATOR
                        // prev_data[prev_data.length - 2].type == enums.IFS.

                    )
                )
            )
        }

    }
    return STATE;
}



const get_selection_posibilities = (
    formulaType,
    current_index = 0,
    previous_index_type = enums.IFS.NONE,
    previous_expr = '',
    condition_index_in_row = -1,
    then_index_in_row = -1,
    condition_block_type = "if",
    hide_then

) => {


    // console.log("previous_index_type", previous_index_type);

    const ifs = ['Nested IF'];
    const thens = ['Then'];
    const manual = [enums.IFS.TEXT] /// ;


    if (formulaType === "nf_mathematical") {
        if (!previous_expr || previous_index_type === enums.IFS.MATHEMATICAL_OPERATOR) {
            return manual;

        } else {

            return enums.CONDITION_VALUES.MATHEMATICAL_OPERATOR

        }
    }



    if (condition_block_type === "else") {
        // this is only for else block
        if (previous_index_type === enums.IFS.TEXT) return enums.CONDITION_VALUES.MATHEMATICAL_OPERATOR;
        else return manual;
    }


    if (current_index === 0) {
        return manual;
    }
    else if (current_index === 1) {
        return enums.CONDITION_VALUES.MATHEMATICAL_OPERATOR.concat(...enums.CONDITION_VALUES.CONDITIONAL_OPERATOR);
    }

    if (previous_index_type === "wrapper") return []

    else if (current_index > 1) {
        switch (previous_index_type) {
            case enums.IFS.CONDITIONAL_OPERATOR:
            case enums.IFS.IS_NULL:
                if (previous_expr === 'IS NULL' || previous_expr === 'is_null' || previous_expr === "IS NOT NULL" || previous_expr === "is_not_null") {
                    // console.log("yyyy",)
                    // previous was IS NULL, all we can do is condition, and then
                    if (hide_then) {
                        return enums.CONDITION_VALUES.CONDITIONS //.concat(...thens);

                    } else {
                        return enums.CONDITION_VALUES.CONDITIONS.concat(...thens);

                    }
                }
                else {
                    return manual
                    // return [];
                }
            case enums.IFS.MATHEMATICAL_OPERATOR:
                // since it is mathematical operator, we will need to either enter the value or select a column
                return manual;
                return [];
            case enums.IFS.THEN:
            case "THEN":
            case "Then":

                // after then, we can either  do nested if or column/text
                return manual.concat(...ifs);
            case enums.IFS.TEXT:
            case enums.IFS.COLUMN:
            case enums.IFS.NF_SP:


                // since it is mathematical operator, we will need to either enter the value or select a column
                if (current_index > condition_index_in_row && condition_index_in_row !== -1) {
                    console.log("entry... 1")
                    if (then_index_in_row === -1 || then_index_in_row > current_index) {
                        console.log("entry... 2")
                        // since we have not yet met with then, we will allow user to choose then
                        if (hide_then) {
                            return enums.CONDITION_VALUES.MATHEMATICAL_OPERATOR.concat(...enums.CONDITION_VALUES.CONDITIONS) //.concat(...thens);

                        } else {
                            return enums.CONDITION_VALUES.MATHEMATICAL_OPERATOR.concat(...enums.CONDITION_VALUES.CONDITIONS).concat(...thens);

                        }
                    }
                    else if (then_index_in_row > current_index) {
                        console.log("entry... 3")
                        // we have surpassed then, we can use only mathematical operators
                        return enums.CONDITION_VALUES.MATHEMATICAL_OPERATOR;
                    }
                    else if (then_index_in_row > -1) {
                        return enums.CONDITION_VALUES.MATHEMATICAL_OPERATOR;
                    }
                }
                else {
                    if (then_index_in_row > -1) {
                        return enums.CONDITION_VALUES.MATHEMATICAL_OPERATOR;
                    }
                    else if (condition_index_in_row === -1) {
                        console.log("selection_posibilities1.1")
                        // conditional index is not present
                        return enums.CONDITION_VALUES.MATHEMATICAL_OPERATOR.concat(...enums.CONDITION_VALUES.CONDITIONAL_OPERATOR);
                    }
                    else {
                        // conditional column already exits... since it is already here, it may be the edit case, so lets not send the conditional options
                        return enums.CONDITION_VALUES.MATHEMATICAL_OPERATOR.concat();
                    }
                }
        }
    }

    return manual;
};



const FormulaEditorElement = (props) => {

    const { updated_inner_formula, inner_formula, onDragOver, area_key, type } = props;

    const {

        parent_id, 
        activeColumnId,
        formulaType,
        minHeight,
        hide_then,
        formulas,
        action_on_change,
        label,
        enable_editor,
        delete_formula_row,
        updateFormula,
        else_if_index,
        deleteFormulaBlock,
        set_grouped_items,
        grouped_items

    } = props;


    const [showElementEditMode, setElementEditMode] = useState('fm_lna9lpr8')
    const [shiftKeyIsPressed, setShiftKeyIsPressed] = useState(true);
    const [selectionRange, setSelectionRange] = useState({ start: -1, end: -1 });
    const [showDropdown, setShowDropdown] = useState(false);


    const handleKeyDown = (e) => {
        // e.preventDefault();
        // e.stopPropagation()

        if (e.key === 'Shift' && shiftKeyIsPressed !== true) {
            setShiftKeyIsPressed(true);
        }
    };

    const handleKeyUp = (e) => {
        // e.preventDefault();
        // e.stopPropagation()
        if (e.key === 'Shift' && shiftKeyIsPressed !== false) {
            setShiftKeyIsPressed(false);
        }
    };


    // useEffect(() => {

    //     window.addEventListener('keydown', handleKeyDown);
    //     window.addEventListener('keyup', handleKeyUp);

    //     return () => {

    //         window.removeEventListener('keydown', handleKeyDown);
    //         window.removeEventListener('keyup', handleKeyUp);

    //     };
    // }, []);


    const toggleItemSelection = (item) => {

        const item_id = item?.id;
        const index = formulas?.findIndex(f => f.id === item_id)

        if (shiftKeyIsPressed) {
            // If SHIFT key is pressed, update the selection range
            setSelectionRange((prevRange) => {
                if (prevRange.start === -1) {
                    // If there's no start index, set it to the clicked index
                    return { start: index, end: index };
                } else {
                    // console.log("selectionRange.11")
                    if (prevRange.start > index) {
                        return { start: index, end: -1 };
                    }
                    // Otherwise, update the end index
                    else {
                        return { start: prevRange.start, end: index };
                    }
                }
            });

        } else {

            // If SHIFT key is not pressed, reset the selection range and select the clicked item
            setSelectionRange({ start: index, end: index });
        }

        // Check if the last item is selected
        if (selectionRange?.start > -1 && index > -1) {
            setShowDropdown(true);
        } else {
            setShowDropdown(false);
        }


    };


    const is_this_selected_for_group = (item) => {

        const item_id = item?.id;
        const index = formulas?.findIndex(f => f.id === item_id);
        return (

            index >= selectionRange.start &&
            index <= selectionRange.end &&
            selectionRange.start !== -1 &&
            selectionRange.end !== -1
            // selectionRange.start !== selectionRange.end
        );
    }

    const get_actual_index = (item) => {
        const item_id = item?.id;
        const index = formulas?.findIndex(f => f.id === item_id);
        return index

    }


    const get_chunks = (formulas) => {

        const chunks = [];
        let prev_index = 0;

        // let's create formula chunks like this :- [[], [], []]
        // here we will assuming our end point is EXTENSION_OPERATOR for now
        // so we will split the formula into chunks by splitting the formula

        const cloned_formula = formulas ? [...formulas] : [];
        cloned_formula?.length > 0 && (formulas || []).forEach((f, index) => {
            if (f.type === enums.IFS.EXTENSION_OPERATOR) {
                const set = cloned_formula.slice(prev_index, (index + 1))
                chunks.push(set)
                prev_index = index + 1;
            }
        })
        if (prev_index > 0) {
            chunks.push(cloned_formula.slice(prev_index))
        } else {
            chunks.push(cloned_formula)
        }

        return chunks;
    }


    const chunks = get_chunks(formulas);
    const active_chunk = chunks?.[chunks.length - 1];


    const onDropColumn = (event, area_key, position_in_array, is_replace_existing_element, prev_data, force_type) => {

        const column_info = JSON.parse(event.dataTransfer.getData("column_info") || "{}");

        const { column_name, table_schema, table_name, schema_id, plugin, schema_name, db_type, connection_name, data_transfer_mode, data_transfer_from, data_type } = column_info;

        const __type__ = column_info?.type;

        if (!column_name) {
            alert("Please Drag column Only")
            return;
        }

        var value = "`" + table_name + "`" + "." + column_name;

        const column_other_values = {
            table_name: table_name,
            schema_id: schema_id,
            db_type: db_type,
            data_type: data_type,
            table_schema: table_schema,
            schema_name: schema_name,
            column_name: column_name,
            aggregation: null,
            t_name: null,
        }

        column_other_values.type = 'column'

        if (__type__ === "db_function") {
            value = column_name;
            column_other_values.type = __type__

        }



        if (is_replace_existing_element && prev_data) {

            const cloned_formula = formulas ? [...formulas] : {};
            const formula_index = cloned_formula?.findIndex((f) => f.id == prev_data?.id);

            const clone_prev_data = {
                ...prev_data,
                ...column_other_values,
                expr: value,
            }

            if (force_type) {
                clone_prev_data.type = force_type;
            }


            if (formula_index > -1) {
                // const current_formula = cloned_formula[formula_index]
                // current_formula[key] = value;
                updateFormula(parent_id, type, clone_prev_data, prev_data?.id)
                // setElementEditMode(undefined)
            }

        } else {
            action_on_change(parent_id, type, __type__, value, else_if_index, position_in_array, column_other_values, is_replace_existing_element)

        }

    }




    const updated_formula_local = (key, value, id) => {

        const cloned_formula = formulas ? [...formulas] : {};

        const formula_index = cloned_formula?.findIndex((f) => f.id == id)

        if (formula_index > -1) {

            const current_formula = cloned_formula[formula_index]
            current_formula[key] = value;

            if (value === "Then") {
                // if value is then then let's update type to 'then'
                current_formula['type'] = 'then';
            }
            if (value === "AND" || value === "OR") {
                // if value is then and or  let's update type to 'and_or_operator'
                current_formula['type'] = 'and_or_operator';
            }
            updateFormula(parent_id, type, current_formula, id)
            setElementEditMode(undefined)
        }
    }




    const get_edit_element_options = (type, value, is_then_required) => {
        let options = undefined;

        switch (type) {

            case enums.IFS.CONDITIONAL_OPERATOR:
                if (value === "IS NULL" || value === "IS NOT NULL") {
                    options = ["IS NULL", "IS NOT NULL"]
                }
                else options = ['>', '<', '<=', '>=', '=', 'LIKE', 'NOT LIKE', 'IN', 'NOT IN',]//enums.CONDITION_VALUES.CONDITIONAL_OPERATOR
                break;

            case enums.IFS.MATHEMATICAL_OPERATOR:
                options = enums.CONDITION_VALUES.MATHEMATICAL_OPERATOR
                break;

            case enums.IFS.EXTENSION_OPERATOR:
                if (is_then_required <= -1) {

                    options = [...enums.CONDITION_VALUES.CONDITIONS, 'Then']
                } else {
                    options = [...enums.CONDITION_VALUES.CONDITIONS]
                }
                break
            default:
                break;
        }
        return options
    }

    const RenderEditElement = ({ type, value }) => {

        let options = undefined;

        // if (value === "THEN" || value === "then" || value === "Then") return;

        switch (type) {
            case enums.IFS.CONDITIONAL_OPERATOR:
                if (value === "IS NULL" || value === "IS NOT NULL") {
                    options = ["IS NULL", "IS NOT NULL"]
                }
                else options = ['>', '<', '<=', '>=', '=']//enums.CONDITION_VALUES.CONDITIONAL_OPERATOR
                break;
            case enums.IFS.MATHEMATICAL_OPERATOR:
                options = enums.CONDITION_VALUES.MATHEMATICAL_OPERATOR
                break
            case enums.IFS.EXTENSION_OPERATOR:
                options = enums.CONDITION_VALUES.CONDITIONS
                break
            default:
                break;
        }

        console.log("options", options, type)

        return (
            <div className='__edit_container__'>
                {type === enums.IFS.TEXT && (
                    <li>
                        <input
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                            }}
                            defaultValue={value}
                            // onDragOver={onDragOver}
                            // onDrop={(e) => {
                            //     if (onDropColumn) {
                            //         onDropColumn(e, area_key);
                            //     }
                            // }}
                            onChange={(e) => {
                                // e.preventDefault()
                                // e.stopPropagation();
                            }}
                            autoFocus={true}
                            type="text"
                            placeholder='Type OR drop column here'
                            onKeyPress={(event) => {
                                const value = event.target.value
                                const eventKey = event.key;
                                if (eventKey === "Enter") {
                                    updated_formula_local("expr", value, showElementEditMode)

                                }
                            }}
                        />
                    </li>
                )}


                {type !== enums.IFS.TEXT && (
                    <li>
                        <select
                            value={value}
                            onChange={(e) => {
                                updated_formula_local("expr", e.target.value, showElementEditMode)
                            }}>
                            {options?.length > 0 && options.map((op) => {
                                return <option key={op} value={op}>{op}</option>
                            })}
                        </select>
                    </li>
                )}

            </div>
        )


    }


    const prev_index_element_type = active_chunk?.length > 0 && active_chunk[active_chunk.length - 1].type;
    const previous_expr = active_chunk?.length > 0 && active_chunk[active_chunk.length - 1].expr;
    const condition_index_in_row = active_chunk.findIndex(c => (c.type === enums.IFS.CONDITIONAL_OPERATOR))
    const then_index_in_row = active_chunk.findIndex(c => c.expr === enums.IFS.THEN || c.expr === "Then")
    const selection__ = get_selection_posibilities(formulaType, active_chunk.length, prev_index_element_type, previous_expr, condition_index_in_row, then_index_in_row, type, hide_then)
    const enable_text_editor = selection__.indexOf(enums.IFS.TEXT) > -1;
    const enable_selection = selection__?.length > 0 && selection__.indexOf(enums.IFS.TEXT) == -1;

    const enable_nested_if = selection__?.indexOf("Nested IF") > -1

    const then_index_in_row_main_formula = formulas.findIndex(c => c.expr === enums.IFS.THEN || c.expr === "Then")

    const find_actual_index_of_chunk_element = (id) => {
        console.log("formulas1.1",formulas)
        const el = formulas.findIndex((el) => el.id === id)
        return el
    }

    const last_element_id = formulas?.[formulas.length - 1]?.id;


    function getRandomColor() {
        var letters = "0123456789ABCDEF";
        var color = "#";
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }



    const group_selection = (group_type = "bracket") => {

        const clone_grouped_items = grouped_items ? { ...grouped_items } : {};

        const clone_selectionRange = selectionRange ? { ...selectionRange } : {};

        if (!clone_grouped_items[type]) clone_grouped_items[type] = [];

        const clone_formula = formulas?.slice(selectionRange?.start, selectionRange?.end)

        const is_any_then = clone_formula?.some((f) => {
            if (f.expr === "Then" || f.expr === "then") return true;
            else return false;
        })

        if (is_any_then) {
            alert("This is not a valid group selection")
            setSelectionRange({ start: -1, end: -1 })
        }
        else {
            clone_selectionRange.group_type = group_type;
            clone_grouped_items[type].push(clone_selectionRange)
            set_grouped_items(clone_grouped_items)
            setSelectionRange({ start: -1, end: -1 })
        }
        console.log("clone_grouped_items", clone_grouped_items)

    }


    const get_current_selection_state = () => {

        const clone_grouped_items = grouped_items ? { ...grouped_items } : {};
        const clone_condition_group = clone_grouped_items?.[type] || [];

        if (clone_condition_group?.length > 0) {
            return clone_condition_group.findIndex((e) => e?.start === selectionRange?.start && e?.end === selectionRange?.end);
        } else {
            return false;
        }

    }



    const ungroup_selection = () => {

        const clone_grouped_items = grouped_items ? { ...grouped_items } : {};
        const clone_condition_group = clone_grouped_items?.[type] || [];

        if (clone_condition_group?.length > 0) {

            const current_group_index = get_current_selection_state();

            clone_condition_group.splice(current_group_index, 1);

            clone_grouped_items[type] = clone_condition_group;
        }

        set_grouped_items(clone_grouped_items)
        setSelectionRange({ start: -1, end: -1 })

    }

    const is_grouped = get_current_selection_state();
    // console.log("showElementEditMode", showElementEditMode)

    console.log("selectionRange", selectionRange)

    return (
        <Wrapper
            minHeight={minHeight}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation()
                props.setActiveColumn && props.setActiveColumn(undefined)
                setShowDropdown(undefined)
                setSelectionRange({ start: -1, end: -1 })
            }}
            style={{ position: 'relative' }}
        >
            {/* <p>Shift Key Pressed: {shiftKeyIsPressed ? 'Yes' : 'No'}</p> */}
            {/* <p>{selectionRange?.start} || {selectionRange?.end}</p> */}

            {label && <div style={{
                position: 'relative',
            }}
                className='block_type'>{label || "IF"}</div>}

            {type === "else_ifs" || (parent_id && type == "if") && (
                <div
                    title="Delete This"
                    onClick={() => {
                        deleteFormulaBlock(parent_id, else_if_index)
                        // delete_formula_row(type, __index)
                    }}
                    style={{
                        left: '100px',
                        top: '-7px'
                    }}
                    className='delete_row'>
                    <CloseSvg size='1rem' height='1rem' />
                </div>
            )}





            {chunks && chunks?.length > 0 && chunks.map((_chunk, index) => {
                const actual_idx = find_actual_index_of_chunk_element(_chunk[_chunk.length - 1]?.id);
                return (
                    <div style={{ position: 'relative' }}>
                        {formulas?.length > 0 && _chunk?.length > 0 && (
                            <div
                                onClick={() => {
                                    console.log("bhai mere", parent_id, type, actual_idx, _chunk[_chunk.length - 1]?.id, else_if_index)
                                    delete_formula_row(parent_id, type, actual_idx, else_if_index)
                                    const clone_grouped_items = grouped_items ? { ...grouped_items } : {};
                                    clone_grouped_items[type] = [];
                                    set_grouped_items(clone_grouped_items)
                                }}
                                style={{
                                    position: 'absolute',
                                    left: '.6rem'
                                }}
                                title={"Delete This"}
                                className='delete_row'>

                                <CloseSvg size='1rem' height='1rem' />

                            </div>
                        )}

                        <ul style={{ marginLeft: '2.5rem' }}>

                            {(_chunk || []).map((condition, condition_index) => {

                                const is_last_element = condition?.id === last_element_id || formulas.length === 0;

                                const is_line_brack_condition = is_MYSQL_condition_line_brack(condition?.expr)


                                const then_prev_el_id = formulas?.[then_index_in_row_main_formula - 1]?.id;

                                const is_it_prev_of_then = _chunk?.[condition_index + 1]?.type === 'then' || then_prev_el_id == condition?.id;

                                const position_in_array = find_actual_index_of_chunk_element(condition?.id)
                                const need_line_brack = is_it_prev_of_then;

                                const need_background_color = is_MYSQL_condition_line_brack(condition.expr);

                                const __prev_index_element_type = condition?.type;
                                const __previous_expr = condition?.expr;


                                const __condition_index_in_row = _chunk.findIndex(c => (c.type === enums.IFS.CONDITIONAL_OPERATOR))
                                const __then_index_in_row = -1 //_chunk.findIndex(c => c.expr === enums.IFS.THEN || c.expr === "Then")

                                const __selection__ = is_it_prev_of_then && get_selection_posibilities(formulaType, _chunk.length, __prev_index_element_type, __previous_expr, __condition_index_in_row, __then_index_in_row, type, true) || [];

                                const __enable_text_editor = __selection__.indexOf(enums.IFS.TEXT) > -1;

                                const __enable_selection = __selection__?.length > 0 && __selection__.indexOf(enums.IFS.TEXT) == -1;

                                const ___enable_nested_if = __selection__?.indexOf("Nested IF") > -1;

                                const is__selected = is_this_selected_for_group(condition)
                                const condition_actual_index = get_actual_index(condition)
                                const group_info = get_start_and_end_group_exp(grouped_items?.[type], condition_actual_index);
                                const fontSize = '.9rem' //(condition?.type === "conditional" || condition?.type == "mathematical") ? '1.6rem' : '.9rem';
                                const fontWeight = '600'//(condition?.type === "conditional" || condition?.type == "mathematical") ? 'bold' : '500';

                                const bg_color = condition?.type === "mathematical" ? '#e2e2e25e' : (condition?.type === "conditional" || condition?.type === "and_or_operator") ? '#e2e2e25e' : condition?.type === "then" ? '#e6f9e6' : condition?.type === "column" ? '#D4FFF2' : '#d8d2f6';
                                const bg_color_selected = condition?.type === "mathematical" ? '#e2e2e25e' : (condition?.type === "conditional" || condition?.type === "and_or_operator") ? '#e2e2e25e' : condition?.type === "then" ? '#e6f9e6' : condition?.type === "column" ? '#BBEFE0' : '#CCE1FF';
                                const textT = (condition?.type === "conditional" || condition?.type === "and_or_operator" || condition?.type == "then") ? 'uppercase' : undefined;
                                const value_to_display = condition?.expr ? condition.expr?.split("__nf_comma__").join(",") : condition?.placholder

                                const options_v1 = get_edit_element_options(condition?.type, condition.expr, then_index_in_row_main_formula)

                                const is_sql_formula = condition?.type === "db_function"

                                return (
                                    <React.Fragment>

                                        {showElementEditMode !== condition.id && condition.type !== "wrapper" && (
                                            <li
                                                style={{
                                                    cursor: 'move',
                                                    userSelect: 'none',
                                                    // backgroundColor: is__selected ? bg_color_selected : bg_color, // need_background_color ? '#' : '#ada5a51c',
                                                    // borderColor: (activeColumnId && activeColumnId === condition.id) ? '#208de4' : undefined,
                                                    backgroundColor: is__selected ? '#bbdbf5' : bg_color, // need_background_color ? '#' : '#ada5a51c',
                                                    borderColor: (activeColumnId && activeColumnId === condition.id) ? '#208de4' : undefined,

                                                    border: 'none',
                                                    fontSize: fontSize,
                                                    fontWeight: fontWeight,
                                                    color: '#222',
                                                    textTransform: textT,
                                                    position: 'relative',
                                                    maxWidth: '100%'


                                                }}
                                                title={value_to_display}
                                                onDragOver={onDragOver}
                                                onDrop={(e) => {
                                                    if (onDropColumn && condition?.type === "column") {
                                                        onDropColumn(e, area_key, position_in_array, true, condition);
                                                    }
                                                }}
                                                key={"formula"}

                                                onClick={(e) => {
                                                    e.stopPropagation();

                                                    toggleItemSelection(condition)

                                                    if (condition.type == "column") {
                                                        props.setActiveColumn(condition)
                                                        props.setActiveEditorType(type)
                                                    }
                                                    else {
                                                        props.setActiveColumn(undefined)
                                                        props.setActiveEditorType(undefined)
                                                    }
                                                    // }
                                                }}

                                                onDoubleClick={(e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()

                                                    if (condition?.type == "nf_sp" || condition?.type == "db_function") {

                                                    } else {
                                                        if (condition.type !== enums.IFS.COLUMN && condition.type !== enums.IFS.THEN && condition.type !== "Then") {
                                                            console.log("DBL CLIKED")
                                                            setElementEditMode(condition.id)
                                                        }
                                                    }

                                                }}
                                            >
                                                <span style={{
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    maxWidth: '100%',
                                                    display: 'block',
                                                    boxSizing: 'border-box'
                                                }}>
                                                    {group_info?.start}
                                                    {value_to_display === "*" ? '×' : value_to_display}
                                                    {group_info?.end}
                                                </span>


                                                {selectionRange?.start > -1 && selectionRange?.end > 0 && (selectionRange?.end - selectionRange?.start) > 0 && (selectionRange.end == condition_actual_index) && (
                                                    <React.Fragment>
                                                        {(is_grouped > -1 && is_grouped !== false) ?
                                                            <div className="dropdown" onClick={() => {
                                                                ungroup_selection()
                                                                setShowDropdown(false)
                                                            }}>
                                                                <div>Ungroup These</div>
                                                            </div> : <div className="dropdown" onClick={() => {
                                                                group_selection()
                                                                setShowDropdown(false)
                                                            }}>
                                                                <div>Group These</div>
                                                            </div>}
                                                    </React.Fragment>
                                                )}
                                            </li>
                                        )}




                                        {showElementEditMode === condition.id && (


                                            <div className='__edit_container__'>
                                                {condition?.type === enums.IFS.TEXT && (
                                                    <li>
                                                        <input
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                            }}
                                                            defaultValue={condition.expr}
                                                            onDragOver={onDragOver}
                                                            onDrop={(e) => {
                                                                if (onDropColumn) {
                                                                    // onDropColumn(e, area_key);
                                                                    onDropColumn(e, area_key, position_in_array, true, condition, 'column');
                                                                    setElementEditMode(undefined)
                                                                }
                                                            }}

                                                            onChange={(e) => {
                                                                // e.preventDefault()
                                                                // e.stopPropagation();
                                                            }}
                                                            autoFocus={true}
                                                            type="text"
                                                            placeholder='Type OR drop column here'
                                                            onKeyPress={(event) => {
                                                                const value = event.target.value
                                                                const eventKey = event.key;
                                                                if (eventKey === "Enter") {
                                                                    if (value.length > 0) {
                                                                        updated_formula_local("expr", value, showElementEditMode)
                                                                    } else {

                                                                    }

                                                                }
                                                            }}
                                                        />
                                                    </li>
                                                )}



                                                {condition?.type !== enums.IFS.TEXT && (
                                                    <li>
                                                        <select
                                                            value={condition.expr}
                                                            onChange={(e) => {
                                                                updated_formula_local("expr", e.target.value, showElementEditMode)
                                                            }}>
                                                            {options_v1?.length > 0 && options_v1.map((op) => {
                                                                return <option key={op} value={op}>{op}</option>
                                                            })}
                                                        </select>
                                                    </li>
                                                )}

                                            </div>
                                        )}


                                        {(is_line_brack_condition) && <div style={{ width: '100%' }}></div>}

                                        {need_line_brack && (
                                            <ElementCreator
                                                enable_text_editor={__enable_text_editor}
                                                onDragOver={onDragOver}
                                                onDropColumn={onDropColumn}
                                                action_on_change={action_on_change}
                                                enable_selection={__enable_selection}
                                                __get_ifs_type_by_value_expr_={__get_ifs_type_by_value_expr_}
                                                selection__={__selection__}
                                                area_key={area_key}
                                                type={type}
                                                else_if_index={else_if_index}
                                                enums={enums}
                                                position_in_array={position_in_array}
                                                parent_id={parent_id}
                                                enable_nested_if={___enable_nested_if}
                                            />
                                        )}


                                        {is_last_element && (
                                            <ElementCreator
                                                enable_text_editor={enable_text_editor}
                                                onDragOver={onDragOver}
                                                onDropColumn={onDropColumn}
                                                action_on_change={action_on_change}
                                                enable_selection={enable_selection}
                                                __get_ifs_type_by_value_expr_={__get_ifs_type_by_value_expr_}
                                                selection__={selection__}
                                                area_key={area_key}
                                                type={type}
                                                else_if_index={else_if_index}
                                                enums={enums}
                                                parent_id={parent_id}
                                                enable_nested_if={enable_nested_if}
                                            />
                                        )}

                                        {(need_line_brack) && <div style={{ width: '100%' }}></div>}

                                        {condition.type === "wrapper" && (
                                            <div style={{ width: '100%', marginRight: '10px' }}>
                                                <BlockElement
                                                    addFormula={props.addFormula}
                                                    updateFormula={props.updateFormula}
                                                    deleteFormula={props.deleteFormula}
                                                    onDragOver={(event) => event.preventDefault()}
                                                    borderColor={getRandomColor()}
                                                    parent_id={condition?.id}
                                                    formulas={condition?.inner_formula}
                                                    setActiveColumn={props.setActiveColumn}
                                                    setActiveEditorType={props.setActiveEditorType}
                                                    deleteFormulaBlock={deleteFormulaBlock}
                                                    activeColumnId={props.activeColumn?.['id']}
                                                    deleteFormulaRow={delete_formula_row}
                                                />
                                            </div>
                                        )}
                                    </React.Fragment>
                                )
                            })}
                        </ul>
                    </div>
                )
            })}


            {formulas?.length === 0 && (
                <ul style={{ marginLeft: '5px', marginTop: '0px' }}>
                    <ElementCreator
                        enable_text_editor={enable_text_editor}
                        onDragOver={onDragOver}
                        onDropColumn={onDropColumn}
                        action_on_change={action_on_change}
                        enable_selection={enable_selection}
                        __get_ifs_type_by_value_expr_={__get_ifs_type_by_value_expr_}
                        selection__={selection__}
                        area_key={area_key}
                        type={type}
                        else_if_index={else_if_index}
                        enums={enums}
                        parent_id={parent_id}
                        enable_nested_if={enable_nested_if}
                        placeholder="Give your condition by Drag & Drop or Typing"
                        width="340px"
                    />
                </ul>
            )}
        </Wrapper >
    )
}


export default FormulaEditorElement;