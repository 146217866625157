import Control from 'ol/control/Control';
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { Draw, Modify, Snap } from 'ol/interaction';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import PolygonSvg from '../../../svg/polygon';
import { convertToLonLat, get_geo_json_form_coordinates } from '../../../../utils/map';
import { MAP_COORDINATE_TYPES } from '../../../../utils/mapConstants';

var draw, snap; // global so we can remove them later


export const clearDrawnPolygon = map => {
    if (map && map.getLayers()) {
        map.getLayers().getArray()[1].getSource().clear();
    }
};

export const drawPolygonButton = (map, completeAction, clear_polygon, level) => {
    var source = new VectorSource();
    map.nf_level = level;

    var vector = new VectorLayer({
        source: source,
        name: 'draw_poly',
        style: new Style({
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0.2)',
            }),
            stroke: new Stroke({
                color: '#ffcc33',
                width: 2,
            }),
            image: new CircleStyle({
                radius: 7,
                fill: new Fill({
                    color: '#ffcc33',
                }),
            }),
        }),
    });

    map.addLayer(vector)

    var modify = new Modify({ source: source });
    map.addInteraction(modify);



    var button = document.createElement('button');
    button.innerHTML = `<i class="fas fa-draw-polygon"></i>`
    var handleRotateNorth = function (e) {
        draw = new Draw({
            source: source,
            type: 'Polygon',
        });
        map.addInteraction(draw);
        snap = new Snap({ source: source });
        map.addInteraction(snap);

        draw.on('drawstart', function (e) {
            // map.getLayers().forEach(layer => {
            //     if (layer && layer.get('name') === 'draw_poly') {
            //       map.removeLayer(layer);
            //     }
            // });
            clear_polygon(undefined, map, true)
            clearDrawnPolygon(map);
        });

        draw.on('drawend', function (event) {
            const polygonCoordinates = event.feature.clone();
            const transformedPolygon = polygonCoordinates.getGeometry().transform('EPSG:3857', 'EPSG:4326').getCoordinates();

            const result = get_geo_json_form_coordinates(MAP_COORDINATE_TYPES.POLYGON, transformedPolygon);

            completeAction(result, level);

            map.removeInteraction(draw);
            map.removeInteraction(snap);
        })
    };


    button.addEventListener('click', handleRotateNorth, false);

    var element = document.createElement('div');
    element.className = 'rotate-north ol-unselectable ol-control polygon_over_write';
    element.appendChild(button);
    var myControl = new Control({ element: element });
    map.addControl(myControl)
};