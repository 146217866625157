import {  simpleDispatch_get,  simpleDispatch_put } from './helper';
import { constants } from '../utils/constants';
import * as actionTypes from '../action-types/interpretation'



export const get_Interpretation = () => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.POLUS_INTERPOLATION.POINT + constants.END_POINTS.POLUS_INTERPOLATION.GET_POLUS_INTERPOLATION_LIST
    await simpleDispatch_get(dispatch, url, actionTypes.GET_NTERPREATION_LIST_SUCCESS, true);
};


 export const save_Interpretation = (data) => async dispatch => {

      const post = {
        polus_interpretation: data
      };

     const url = constants.END_POINTS.API + constants.END_POINTS.POLUS_INTERPOLATION.POINT + constants.END_POINTS.POLUS_INTERPOLATION.SAVE_POLUS_INTERPOLATIONL;
     await simpleDispatch_put(dispatch, url, post, actionTypes.SAVE_NTERPREATION_SUCCESS, false);
 };


//  export const delete_Nf_Grammar = (id) => async dispatch => {
//    const url = constants.END_POINTS.API + constants.END_POINTS.NF_GRAMMAR.POINT + constants.END_POINTS.NF_GRAMMAR.DELETE_NF_GRAMMAR + '?id=' + id;
//    await simpleDispatch_delete(dispatch, url, actionTypes.DELETE_NF_GRAMMARS, false);
// };



