import React from "react";

const ThreeDot = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-6 0 16 16"
      preserveAspectRatio="xMidYMid meet"
    >
      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
    </svg>
  );
};

export default ThreeDot;
