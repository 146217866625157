import React, {useEffect } from 'react'
import { Wrapper } from './demo.request.styled.js';
import TitleHeader from '../title-header';
import { connect } from 'react-redux';
import { getDemoRequestList, approveDemoRequest, editDemoRequest, deleteDemoRequest, getTimeSlotList, getBookedInfyCurrentDate } from '../../actions/demo-request';
import SharedTable from '../shared-table';
import { PageInnerWrapper } from '../hoc/styled.hoc';
import { showPopup } from '../../utils';
import * as enums from '../../utils/enums.js';
import DemoRequestEdit from './edit.request';


const Index = (props) => {

    const {

        getDemoRequestList, approveDemoRequest, getTimeSlotList,
        deleteDemoRequest, editDemoRequest, requestList, timeSlotList,
        bookedList, getBookedInfyCurrentDate

    } = props;



    useEffect(() => {
        getDemoRequestList();
        getTimeSlotList()
        getBookedInfyCurrentDate()
        let _page_info = {title: 'DEMO REQUESTS'}
        props.dispatchHeaderInformation(_page_info)

    }, [])



    const localAction = (item, type) => {

        if (type === 'delete') {
            deleteDemoRequest(item)
        }

        if (type === 'edit') {
            editDemoRequest(item)
        }
        if (type === 'approved') {

            approveDemoRequest(item)
        }
    }


    const editRow = (data) => {

        showPopup(undefined, undefined, enums.default.popupType.element, DemoRequestEdit, {
            keys: ['name', 'company_email', 'company_name', 'mobile_number', 'linkedin'],
            info: data,
            bookedList: bookedList,
            timeSlotList: timeSlotList,
            save__func: (data) => localAction(data, 'edit')
        })
    }



    return (
        <Wrapper style={{marginTop: '1rem'}}>
         
            <PageInnerWrapper>
                <SharedTable
                    action={true}
                    approvedAction={(item) => localAction(item, 'approved')}
                    deleteAction={(item) => localAction(item, 'delete')}
                    editAction={(item) => editRow(item, 'edit')}
                    tableData={(requestList && requestList.length > 0) ? requestList : undefined}
                    tableHead={['Name', 'Company Email', 'Company Name', 'Mobile', 'Linkedin URL', 'Date', 'Time', 'Status', 'Actions']}
                    columnsToShow={['name', 'company_email', 'company_name', 'mobile_number', 'linkedin', 'demo_date', 'time_slot_id', 'is_approved']}
                    isDateColumn={"demo_date"}
                    fullTime={false}
                />

            </PageInnerWrapper>
        </Wrapper>
    )
}




const mapStateToProps = (state) => ({
    requestList: state.demoRequestReducer.requestList,
    timeSlotList: state.demoRequestReducer.timeSlotList,
    bookedList: state.demoRequestReducer.bookedList
})

export default connect(mapStateToProps, { getDemoRequestList, approveDemoRequest, deleteDemoRequest, editDemoRequest, getTimeSlotList, getBookedInfyCurrentDate })(Index);
