import React from "react";

const Duplicate = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 37.000000 36.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,36.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M77 354 c-4 -4 -7 -20 -7 -35 0 -24 -4 -28 -32 -31 l-33 -3 0 -140 0
            -140 145 0 145 0 3 32 c3 28 7 32 35 35 l32 3 0 140 0 140 -141 3 c-77 1 -143
            -1 -147 -4z m273 -134 l0 -130 -25 0 c-24 0 -24 1 -27 98 l-3 97 -102 3 -103
            3 0 29 0 30 130 0 130 0 0 -130z m-70 -70 l0 -130 -130 0 -130 0 0 130 0 130
            130 0 130 0 0 -130z"
        />
        <path
          d="M142 198 c-2 -37 -3 -38 -41 -38 -22 0 -43 -4 -46 -10 -4 -6 11 -10
            39 -10 l45 0 4 -42 4 -43 1 43 2 42 40 0 c22 0 40 5 40 10 0 6 -18 10 -39 10
            -39 0 -40 1 -44 38 l-4 37 -1 -37z"
        />
      </g>
    </svg>
  );
};

export default Duplicate;
