import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TextBoxV1 from '../hoc/text.box.v1';
import { Button } from '../hoc/button';
import DeleteSvg from '../svg/delete'
import AutoSearch from '../auto.search';
import CloseSvg from '../svg/close'
import { constants } from '../../utils/constants';
import { NormalButton } from '../hoc/normal.button';
import TextFileMappingTable from '../directory_upload/file.mapping.window'


const Wrapper = styled.div`

       
        position: fixed;
        top: 0;
        border: 0;
        margin: auto;
        left: 0;
        width: 65rem;
        border-radius: 6px;
        background: #fff;
        right: 0px;
        bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        /* padding: 1rem; */
        box-sizing: border-box;
        border: 2px solid #ccc;
        max-height: 35rem;
        height: 40rem;



    .required{
        font-size: 12px;
        /* color: #444; */
    }
    .action_btn{
        width: 1.4rem;
        height: 1.4rem;
        /* border: 1px solid #ccc; */
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: #e2e2e2;
        border-radius: 4px;;
        box-sizing: border-box;
    }

          .table_wrapper{
            overflow: auto;
            max-height: 25rem;
            width: 100%;

            &::-webkit-scrollbar {
                background-color:red;
                width:5px
            }

            &::-webkit-scrollbar-track {
                background-color:#b1cad5;
            }

            &::-webkit-scrollbar-thumb {
                background-color:#76919d;
                border-radius:0px;
                height: 5px;
            }
            &::-webkit-scrollbar:horizontal{
                background-color: green;
                height: 5px;
            }
        }


        h1{
            font-size: 1.286rem;
            font-weight: bold;
            line-height: 1.714rem;
            color: #1a1a1a;
            margin: 0px;
            display: block;
            position: relative;
            text-align: left;
            /* width: 100%; */
        }


        select {
            width: 106px;
            height: 31px;
            font-size: 1.1rem;
            margin-left: 12px
        }
        
        table{
            border: 0px;
            width: 100%;
            border-collapse: collapse;

            thead{
                td{
                    color: #000;
                    font-weight: 600;
                    padding: .8rem 1rem;
                    background-color: #f2f2f2fc;
                    position: sticky;
                    top: 0px;
                    z-index: 2;
                }
            }

            td{
                padding: 0rem 1rem;
                font-size: .9rem;
                color: #000;
                border: 0px;
                font-weight: 600;
                border-bottom: 1px solid #e2e2e2;

                input{
                     padding: 1rem 0rem;
                     border: 0px;;
                    width: 100%;
                    box-sizing: border-box;
                     
                }
            }
        }



`;



const file_mapping_struct = {
    column: '',
    db_column: '',
    postion: '',
    demilited_by: ''
}


const TextFileColumnMapping = (props) => {

    const { mappingData, json_file_path, table_name } = props;

    const [local_column_data, set_local_column_data] = useState(undefined);

    const [show_other_column, set_show_other_column] = useState(undefined);

    const [active_file_key, set_active_file_key] = useState(undefined);

    const [child_keys, set_child_keys] = useState();


    const get_mt_object = () => {
        return JSON.parse(JSON.stringify(file_mapping_struct))
    }


    useEffect(() => {

        const mt_value = get_mt_object()
        // file_info_form_java

        if ((mappingData?.extracted_db_columns && Object.keys(mappingData?.extracted_db_columns)?.length > 0) || (mappingData?.file_info_form_java && Object.keys(mappingData?.file_info_form_java)?.length > 0)) {

            const java_column_pref = mappingData?.file_info_form_java
            const extracted_db_columns = mappingData?.extracted_db_columns

            const mapping = extracted_db_columns && Object.keys(extracted_db_columns)?.length > 0 ? extracted_db_columns : java_column_pref || [];

            // mapping.push(mt_value)
            set_local_column_data(mapping)

            const keys = mapping && Object.keys(mapping)?.length > 0 && Object.keys(mapping);

            if (keys?.length > 0) keys.splice(keys.indexOf("other_keys"), 1)

            // const is_other_column = (mapping || []).some((c) => c.identified_separator_count)
            const active_key = keys?.length > 0 && keys[0];

            set_active_file_key(active_key)
            set_child_keys(keys)
            // set_show_other_column(is_other_column)

        }
        else {
            set_local_column_data([mt_value])
        }

    }, [mappingData])




    const on_change = (index, key, value) => {

        const clone_data = local_column_data ? { ...local_column_data } : {};
        const current_table_data = clone_data?.[active_file_key];
        const mapping_data = current_table_data?.['identified_attributes'];

        if (mapping_data) {

            mapping_data[index][key] = value;

            const last_row = mapping_data[mapping_data.length - 1];

            if (last_row && last_row["column"]) {
                mapping_data.push(get_mt_object())
            }

            current_table_data["identified_attributes"] = mapping_data;
            clone_data[active_file_key] = current_table_data;

            set_local_column_data(clone_data);

        }
    }


    const delete_row = (index) => {

        const clone_data = local_column_data ? { ...local_column_data } : {};
        const current_table_data = clone_data?.[active_file_key];
        const mapping_data = current_table_data?.['identified_attributes'];
        const id_of_mapping = mapping_data?.[index]?.id;

        if (id_of_mapping) {
            mapping_data[index].deleted = true;
        } else {
            mapping_data.splice(index, 1);

        }
        current_table_data['identified_attributes'] = mapping_data
        clone_data[active_file_key] = current_table_data;

        set_local_column_data(clone_data);
    }



    const local_save = () => {
        props.save(json_file_path, table_name, local_column_data)
    }

    const mapping_data = active_file_key && local_column_data?.[active_file_key]?.['identified_attributes'];



    return (
        <Wrapper>
            <div style={{ padding: '.5rem 1rem', boxSizing: 'border-box', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <img style={{ width: '70px' }} src={constants.CONTEXT_PATH + './finger.png'}></img>

                <h1>PLEASE PROVIDE REQUIRED ATTRIBUTES</h1>
                <div>
                    {child_keys?.length > 1 && (
                        <select onChange={(e) => set_active_file_key(e.target.value)} value={active_file_key || '-'}>
                            {child_keys.map((k) => {
                                return <option value={k}>{k}</option>
                            })}
                        </select>
                    )}
                </div>

                <div
                    className="dismiss"
                    title="Close"
                    onClick={() => {
                        props.close()
                    }}
                >
                    <CloseSvg color="#bbd1d9" size="1rem" height="1rem" />
                </div>
            </div>



            {active_file_key && (
                <TextFileMappingTable
                    on_change={on_change}
                    delete_row={delete_row}
                    mapping_data={mapping_data}
                    // db_column_hints={db_column_hints}
                    minHeight={'20rem'}
                    maxHeight={'20rem'}
                    showExampleColumn={show_other_column}
                    showPositionColum={show_other_column}
                />
            )}


            <div style={{ marginTop: 'auto', marginLeft: 'auto', padding: '1rem' }}>

                <NormalButton
                    height={'2.5rem'}
                    onClick={() => {
                        local_save();
                    }}
                    style={{ fontWeight: 'bold', border: '1px solid #000', borderRadius: '0px' }}
                    hoverBg={"#086f9d"}
                    label="SAVE & UPLOAD"
                    icon={<img src={constants.CONTEXT_PATH + './upload_sm.png'} style={{ width: '35px' }} />}
                />
            </div>
        </Wrapper>
    )
}

export default TextFileColumnMapping