import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { getHintsWithTableAndColumnName } from '../../actions/hints';
import { saveFormulas } from '../../actions/formulas';
import { Gap } from '../gap';

import { Wrapper, Content, ContentTitle, ButtonWrapper, InputWrapper, AutoComplete, InputLabel } from './styled';
import { get_hints_in_object, find_hint_in_array, check_brackets } from '../../utils';
import { Button } from '../hoc/button';
import Overlay from '../helper/overlay';
import TextBox from '../hoc/textBox';



const arrayOfOperators = ['+', '-', '*', '/', '%', '='];

let current_index = undefined;


const FormulaCreator = props => {

    const { 
        getHintsWithTableAndColumnName, hintsWithTableColumnsName, data, saveFormulas,
        close, option, popupKey, 
    } = props;

    const { setFormulas, id,formulas, string, index, formulaTitle, _hints, _hintsKeys, hint_from_props, db_info_id } = data;

    const mathematical_keywords_in_query = ["formula"]

    const [hints, setHints] = useState(undefined);
    const [hintsKeys, setHintsKeys] = useState(undefined);
    const [autoCompleteWords, setAutoCompleteWords] = useState(undefined);
    const [currentWord, setCurrentWord] = useState(undefined);
    const [title, setTitle] = useState('');
    const [currentInputValue, setCurrentInputValue] = useState('');
    const [inputFocus, setInputFocus] = useState(undefined);

    const [err, setErr] = useState({});
    const [titleError, setTitleError] = useState(undefined);
    const [scrollValue, setScrollValue] = useState(0);

    const [formData, setFormData] = useState({})

    useEffect(() => {
        if (_hints) {
            setHints(_hints);
            setHintsKeys(_hintsKeys);
        }
    }, [_hints, _hintsKeys])


    useEffect(() => {
        if(db_info_id) getHintsWithTableAndColumnName(['number', 'int', 'currency'], db_info_id);
    }, [db_info_id])


    useEffect(() => {
        setFormData({
            "formula": string
        })
    }, [string, index])

    useEffect(() => {
        setTitle(formulaTitle)
    }, [formulaTitle])


    const inputRef = useRef(null)


    useEffect(() => {
        if (hintsWithTableColumnsName && hintsWithTableColumnsName.length > 0 && !hint_from_props) {
            const _hints = get_hints_in_object(hintsWithTableColumnsName);
            setHints(_hints);
            setHintsKeys(Object.keys(_hints));
        }
    }, [hintsWithTableColumnsName]);



    const _onChange = (value, key) => {

        current_index = undefined;
        const tempErr = Object.assign({}, err);

        if (tempErr[key]) {
            delete tempErr[key];
        }  

        // const filter_hints = hintsKeys && hintsKeys.length > 0 && hintsKeys.filter((f) => {
        //     if(!value || value === '') return true;
        //     if(f && f.indexof(value) > -1) return true;
        //     else return false;
        // })
        const hints_from_array = find_hint_in_array(value, undefined, hintsKeys);
        setAutoCompleteWords(hints_from_array)
        // setFormData(tempFormData);
        setCurrentInputValue(value)
        setErr(tempErr)
    };



    const _setErr = (key, value) => {
        const tempErr = Object.assign({}, err);

        if (typeof tempErr[key] === 'undefined') {
            tempErr[key] = []
        }

        tempErr[key].push(value)

        setErr(tempErr);
    };


    const _saveFormulas = () => {
        const tempFormData = Object.assign({}, formData);
        const result = {}

        const shouldProceed = tempFormData && Object.keys(tempFormData).length > 0;

        let finalSave = true;

        if (shouldProceed) {
            Object.keys(tempFormData).forEach(key => {
                const temp_data = tempFormData[key];
                const data = temp_data && temp_data.split("nf_item").join(' ');

                const brackets_are_in_place = check_brackets(data);

                const data_without_brackets = data.replaceAll(/[\(\)\[\]\{\}]/gi, '');

                const data_without_number = data_without_brackets.replaceAll(/[0-9]/g, '');

                const data_without_maths_symbols = data_without_number.replaceAll(/[+*\/-]/g, '_nf_');

                const splitted_data = data_without_maths_symbols.split('_nf_').filter(f => f.trim().length > 0 && f);


                if (brackets_are_in_place) {
                    splitted_data && splitted_data.length > 0 && splitted_data.forEach(s => {
                        const word = typeof s === 'string' ? s.trim().toLocaleLowerCase() : s.trim();

                        if (word && word.trim().length > 0 && hints && typeof hints[word] === 'undefined') {

                            _setErr(key, '')

                            finalSave = false;
                        }
                        else {
                            result[key] = {
                                ...result[key],
                                [word]: {
                                    meta: hints[word],
                                    formula: data
                                }
                            }
                        }
                    })

                    if (finalSave) {
                        /*
                            Check arthematic operator after hints
                        */
                        const words_to_check = splitted_data.slice(0, splitted_data.length - 1);
                        let isErr = false;

                        words_to_check && words_to_check.length > 0 && words_to_check.forEach(w => {
                            const maths = ['+', '/', '*', '-'];

                            const index = data_without_brackets.indexOf(w);

                            let next_index_to_check = index + w.length;


                            while (true) {
                                if (data_without_brackets[next_index_to_check].trim().length > 0) {
                                    break;
                                }

                                next_index_to_check++;
                            }

                            if (maths.indexOf(data_without_brackets[next_index_to_check]) === -1) {
                                isErr = true;
                            }
                            else if ((maths.indexOf(data_without_brackets[next_index_to_check + 1]) > -1) || (maths.indexOf(data_without_brackets[next_index_to_check + 2]) > -1)) {
                                isErr = true
                            }

                        })

                        if (isErr) {
                            finalSave = false;
                            _setErr(key, 'Formula is not Correct')
                        }
                    }
                }
                else {
                    finalSave = false;
                    _setErr(key, 'Bracket are not in place');
                }

            })
        }


        if (finalSave) {
            if (title && title.length > 0) {
                let clone_formula = formulas ? JSON.parse(JSON.stringify(formulas)) : [];

                let temp_formuls = result["formula"];
                temp_formuls.nf_string_formula = formData["formula"];
                temp_formuls.formula_title = title;
                temp_formuls.modified = true;

                if (id || formulaTitle) {
                    let new_item = temp_formuls;
                    new_item.id = id ? id : undefined;

                    clone_formula && clone_formula.length > 0 && clone_formula.forEach((item, index) => {
                        if((item.formula_title === formulaTitle)){
                            Object.assign(item, {...new_item})
                        }
                    })
                }
                else {
                    clone_formula.push(temp_formuls)
                }

                setFormulas(clone_formula)
                close(option, popupKey)
            } else {
                setTitleError(true)
            }
        }
    };



    return (
        <Wrapper style={{ minHeight: '19rem' }}>
            <Content>
                <ContentTitle>
                    Formula Creator
                </ContentTitle>
                {autoCompleteWords && <Overlay onClick={() => setAutoCompleteWords(undefined)} />}

                <Gap h='2rem' />
                <TextBox
                    label="Title"
                    autoFocus={true}
                    value={title}
                    error={titleError}
                    onChange={(e) => {
                        setTitle(e.target.value)
                        if (e.target.value && e.target.value.length > 0) {
                            setTitleError(false)
                        }
                    }}

                />
                <Gap h='1.8rem' />

                {
                    mathematical_keywords_in_query && mathematical_keywords_in_query.length > 0 && mathematical_keywords_in_query.map(m => {
                        return (
                            <div style={{ position: 'relative', zIndex: '2' }}>
                                <InputLabel
                                    focused={inputFocus} top={(formData && formData[m] && formData[m].split('nf_item').length > 0) || inputFocus || currentInputValue}>Type Your Formula here</InputLabel>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <InputWrapper focused={inputFocus}>
                                        {formData && formData[m] && formData[m].split('nf_item').map((t, j) => {
                                            let _is_oprator = arrayOfOperators.indexOf(t) > -1;
                                            return (
                                                <div style={{ borderBottomColor: _is_oprator ? 'transparent' : '#000' }} className='tag'>{t}</div>
                                            )
                                        })}
                                        <div style={{ position: 'relative', flex: 1, minWidth: '100px' }}>
                                            <input
                                                ref={inputRef}
                                                onChange={(event) => _onChange(event.target.value, m)} value={formData[m]

                                                }
                                                onFocus={() => {
                                                    setInputFocus(true)
                                                }}
                                                onBlur={() => {
                                                    setInputFocus(false)
                                                }}
                                                // placeholder="Type Your Formula here"
                                                value={currentInputValue}
                                                onKeyDown={(event) => {

                                                    let keyCode = event.keyCode;
                                                    let _value = event.target.value;

                                                    let temp_formula = formData ? JSON.parse(JSON.stringify(formData)) : {};
                                                    let formula_array_value = temp_formula[m] ? temp_formula[m].split("nf_item") : [];



                                                    // when user press Enter add current value
                                                    if (keyCode === 13) {
                                                        formula_array_value.push(_value);
                                                        temp_formula[m] = formula_array_value.join("nf_item");
                                                        setFormData(temp_formula);
                                                        setCurrentInputValue('');
                                                        current_index = undefined;
                                                        // setAutoCompleteWords(undefined);
                                                        if (arrayOfOperators.indexOf(_value) === -1) {
                                                            setAutoCompleteWords(arrayOfOperators);
                                                        } else {
                                                            setAutoCompleteWords(undefined);
                                                            setCurrentInputValue('');
                                                            current_index = undefined;
                                                        }
                                                    }


                                                    // when user Click on BackSpace remove last word from string
                                                    if (keyCode === 8) {
                                                        if (!formula_array_value) {
                                                            setAutoCompleteWords(undefined);
                                                        }
                                                        if (!_value && formula_array_value && formula_array_value.length > 0) {
                                                            formula_array_value.pop();
                                                            temp_formula[m] = formula_array_value.join("nf_item");
                                                            setFormData(temp_formula);
                                                            setCurrentInputValue('');
                                                        }
                                                    }


                                                    let total_suggested_items_length = autoCompleteWords && autoCompleteWords.length;

                                                    // page down
                                                    if (keyCode === 40) {

                                                        let temp_index = (current_index == 0 || current_index) ? current_index + 1 : 0;
                                                        current_index = temp_index;
                                                        if (total_suggested_items_length === temp_index) {
                                                            current_index = 0;
                                                            temp_index = 0;
                                                        }
                                                        let current_item = autoCompleteWords && autoCompleteWords[temp_index]
                                                        setCurrentInputValue(current_item)

                                                    }
                                                    // page up 
                                                    if (keyCode === 38) {

                                                        let temp_index = current_index - 1;
                                                        if (current_index <= 0) {
                                                            temp_index = total_suggested_items_length
                                                        }
                                                        current_index = temp_index;

                                                        let current_item = autoCompleteWords && autoCompleteWords[temp_index]
                                                        if (current_item) {
                                                            setCurrentInputValue(current_item)
                                                        }
                                                    }
                                                }}
                                            />
                                            {
                                                autoCompleteWords && autoCompleteWords.length > 0 &&

                                                <AutoComplete
                                                    id="list_element"
                                                    gap={autoCompleteWords[0] === '+'}
                                                >
                                                    {autoCompleteWords && autoCompleteWords.map((word, i) => {

                                                        return (
                                                            <div
                                                                onClick={() => {
                                                                    let temp_formula = formData ? JSON.parse(JSON.stringify(formData)) : {};

                                                                    let formula_value_array = temp_formula[m] ? temp_formula[m].split("nf_item") : [];

                                                                    formula_value_array.push(word);
                                                                    temp_formula[m] = formula_value_array.join("nf_item");
                                                                    setFormData(temp_formula);
                                                                    setCurrentInputValue('');
                                                                    if (arrayOfOperators.indexOf(word) === -1) {
                                                                        setAutoCompleteWords(arrayOfOperators);
                                                                    } else {
                                                                        setAutoCompleteWords(undefined);
                                                                    }
                                                                    current_index = 0;
                                                                    inputRef.current.focus();
                                                                }}
                                                                className={`word ${(currentInputValue && currentInputValue === word) ? ' selected' : 'none_selected'}`}
                                                            >{word}</div>
                                                        )
                                                    })}
                                                </AutoComplete>
                                            }
                                        </div>
                                    </InputWrapper>
                                </div>
                                <div className="example">
                                    Example:- <span>Net Profit</span>&nbsp;=&nbsp;<span>revenue</span> <strong>&nbsp;-&nbsp;</strong><span>discount</span>
                                </div>


                                {
                                    err && err[m] &&
                                    <div className='q_error'>
                                        {
                                            err[m].join('').trim().length > 0 ? err[m] : 'Field is not present'
                                        }
                                    </div>
                                }
                            </div>
                        )
                    })
                }


            </Content>
            <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Button
                    primary={true}
                    mR="1rem"
                    onClick={_saveFormulas}
                >
                    Save Formula
                </Button>
                <Button
                    bgColor={'#e6e6e6'}
                    onClick={() => {
                        close(option, popupKey)
                    }}
                >
                    Cancel
                </Button>
            </div>
        </Wrapper>
    )
};


const mapStateToProps = state => {
    const { hintsWithTableColumnsName } = state.hintsReducer;

    return { hintsWithTableColumnsName }
};

export default connect(mapStateToProps, { getHintsWithTableAndColumnName, saveFormulas })(FormulaCreator);