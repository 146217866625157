import React, { useState, useEffect, useRef } from 'react'
import { Wrapper, RightSideSetting, LeftSideSetting, BottomDropDownMenu } from './right.side.panel.styled';
import { DivRow } from '../styled-hoc';
import SelectOption from '../select-option'
import { connect } from 'react-redux';
import TextBox from '../hoc/textBox';
import { CHART_TYPES, CHART_TYPES_WITH_ICONS, FREQUENCY_TYPE } from '../../shared-with-fe/constants';
import CheckBox from '../hoc/checkbox';
import { find_hint_in_array } from '../../utils';
import { getAllInfoOfQuestion } from '../../actions/insights';
import { getStringHintsFromServer } from '../../actions/report'
import { getHintsByDbInfo } from '../../actions/hints';
import { clearQuestionInfo } from '../../actions/etl';
import { getFromSession } from '../../utils/session.helper';
import { constants } from '../../utils/constants';
import { showPopup, generate_unique_key, is_value_exist_in_array } from '../../utils';
import * as enums from '../../utils/enums.js';
import RippalEffect from '../hoc/ripple-effect'
import ThemeSetting from './theme.setting'
import AngleDown from '../svg/angleDown';
import AngleUp from '../svg/angleUp';
import { setActiveInsight, getInsightsListByUser } from '../../actions/insights';
import { getAllReports } from '../../actions/report';
import { get_all_reports } from '../../actions/report.helper';
import { get_user_preferences } from '../../actions/account';
import SettingSvg from '../svg/setting';
import BackSvg from '../svg/back';
import styled from 'styled-components';
import QuestionPopup from './question.popup';
import FormulaEditorPopup from '../formatting/formula.editor.popup'
import CloseSvg from '../svg/close'
import SelectDisplayField from '../select-display-field'
import NoCodePopup from './no-code';
import DataModeTab from './data.mode.tab'
import ConfirmBox from '../confirm-box';
import LocalFilterChooser from './local.filter.popup';
import FilterSvg from '../svg/filter';
import SelectOptionV1 from '../select-option/v2/index.js';
import TextBoxV1 from '../hoc/v2/text.box.js';
import { change_render_mode_v1, get_pivot_field_in_proper_case } from '../editor.shared/builder.shared.functions';
import { NormalButton } from '../hoc/normal.button';
import Barchart from '../svg/barChart.js'
import { RadioCheckMark } from '../hoc/radiobox.js';
import { calculatePositionForSuggestionBox } from '../../utils/calculatePositionAtCursor.js';



const MiniHintWrapper = styled.div`

    position: absolute;
    width: 100%;
    left: 0px;
    right: 0px;
    margin: auto;
    z-index: 222;
    top: 2.8rem;
    display: block;
    transition: all 0.3s;
    max-height: 20rem;
    overflow-x: auto;
    &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }


    .hints_shadow{
        box-shadow: 0 4px 6px 0 rgb(32 33 36 / 28%);
        width: 100%;
        padding-top: .4rem;
        background-color: #fff;
     }
    .hint_row{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        padding-left: 0rem;
    }

    span{
        font-size: 1rem;
        padding: .2rem 3px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .prev_hint{
        background: #fff;
        color: #fff;
        max-width: calc(100% - 200px);
    }
    .next_hint{
        background: #fff;
        border-bottom: 1px solid #ccc;;
        &:hover{
            background: #eaeaea;
        }
    }
`;


const default_charts = [
    'bar_chart',
    'stacked_bar_chart',
    'line_chart',
    'area_chart',
    'line_bar_chart',
    'composed_bar_chart',
    'horizontal_bar_chart',
    'waterfall_chart',
    'horizontal_waterfall_chart',
    "dash_line_chart",
    "stacked_bar_chart_100_perc",
    "horizontal_stack_bar_chart",
    "horizontal_stack_bar_chart_100",
    "stacked_bar_chart_100_perc",
    "tree_map",
    'scatter_chart',
    'funnel_chart',
    'pareto_chart',
];


const pie_charts = [
    'pie_chart',
    'donut_chart',
    "gauge_chart",
]



const map_level = [
    { name: 'State Data', value: 'state_data' },
    { name: 'Districts', value: 'district_data' },
    { name: 'Divisions', value: 'division_data' },
    // { name: 'Mandalas', value: 'mandala_data' },
    // { name: 'Customer Data', value: 'customer_data' },
    // { name: 'Branch Data', value: 'branch_data' },
    // { name: 'HSL Data', value: 'hsl_data' },
];





const chart_axis_setting = [
    {
        name: "Hide X Axis Tick",
        value: 'hide_x_axis',
        render_mode: ['chart'],
        chart_type: [...default_charts, ...pie_charts]
    },
    {
        name: "Hide Primary X Axis Tick",
        value: 'hide_x_axis_secondry',
        render_mode: ['chart'],
        chart_type: [...default_charts, ...pie_charts]
    },
    {
        name: "Hide Y Axis Tick",
        value: 'hide_y_axis',
        render_mode: ['chart'],
        chart_type: [...default_charts, ...pie_charts]
    },
    {
        name: "Hide Y Axis Label",
        value: 'hide_y_axis_label',
        render_mode: ['chart'],
        chart_type: [...default_charts, ...pie_charts]
    },
    {
        name: "Hide X Axis Label ",
        value: 'hide_x_axis_label',
        render_mode: ['chart'],
        chart_type: [...default_charts, ...pie_charts]
    },
    {
        name: "Hide Y Axis Border ",
        value: 'hide_y_axis_border',
        render_mode: ['chart'],
        chart_type: [...default_charts, ...pie_charts]
    },
    {
        name: "Hide X Axis Border",
        value: 'hide_x_axis_border',
        render_mode: ['chart'],
        chart_type: [...default_charts, ...pie_charts]
    },
]


const appearance_type = [
    {
        name: "Hide Tooltip",
        value: 'hide_tooltip',
        render_mode: ['chart'],
        chart_type: [...default_charts, ...pie_charts,]
    },

    {
        name: "Hide Legend",
        value: 'hide_legend',
        render_mode: ['chart'],
        chart_type: [...default_charts, ...pie_charts,]
    },
    {
        name: "Hide Border ",
        value: 'hide_border',
        render_mode: ['chart', 'table', 'single_cell', 'group', 'label'],
        chart_type: [...default_charts, ...pie_charts, ...['single_cell', 'table', 'group', 'label']]
    },

    {
        name: "Hide Headers",
        value: 'hide_headers',
        render_mode: ['table'],
        chart_type: [...default_charts, ...pie_charts, ...['table']]
    },

    {
        name: "Hide Label",
        value: 'hide_label',
        render_mode: ['chart'],
        chart_type: [...default_charts]
    },

    {
        name: "Hide Grid",
        value: 'hide_grid',
        render_mode: ['chart'],
        chart_type: [...default_charts,]
    },
    {
        name: "Hide Grand Total",
        value: 'hide_grand_total',
        render_mode: ['table'],
        chart_type: [...default_charts, ...pie_charts, ...['table']]
    },
    // {
    //     name: "Show Percentage Total",
    //     value: 'show_percentage_total',
    //     render_mode: ['table'],
    //     chart_type: [...default_charts, ...pie_charts, ...['table']]
    // },
    // {
    //     name: 'Show Percentage in Data Labels',
    //     value: 'show_percentage_in_data_labels',
    //     render_mode: ['chart'],
    //     chart_type: [...pie_charts, ...default_charts]
    // },
    // {
    //     name: 'Show Percentage in Legends',
    //     value: 'show_prcentage_in_legends',
    //     render_mode: ['chart'],
    //     chart_type: [...pie_charts, ...default_charts]
    // },


    {
        name: "Show Trend",
        value: 'show_trend',
        render_mode: ['chart'],
        chart_type: ['single_cell']
    },
    {
        name: 'Show Data Label',
        value: 'show_data_label',
        options: ["none", 'sum', "count", "avg", "difference", "percentage", "percentage difference"],
        render_mode: ['chart'],
        chart_type: [...default_charts, ...pie_charts]
    },
    {
        name: "Show Brush",
        value: 'show_brush',
        render_mode: ['chart'],
        chart_type: ["bar_chart"]
    },
]




const default_number_data_types = ['number', 'int', 'double', 'currency', 'integer',]

const find_common_properties = (__object_1__, __object_2__) => {
    //assuming we have both object 1 and 2

    const object_1 = __object_1__ ? __object_1__ : {};
    const object_2 = __object_2__ ? __object_2__ : {};


    const keys_1 = Object.keys(object_1);
    const keys_2 = Object.keys(object_2);

    let final_object = {};

    const union_keys = Array.from(new Set([...keys_1, ...keys_2]));

    union_keys?.length > 0 && (union_keys.filter((r => typeof r !== null)) || []).forEach(key => {
        const property_1 = object_1[key];
        const property_2 = object_2[key];


        const type_1 = typeof property_1;
        const type_2 = typeof property_2;


        if (type_1 === type_2) {
            //    console.log("property_1", property_1, property_2, key)
            // type is same, so we will check if this is object or not, if it is object, we will push it to the recursive
            if (type_1 === 'object' && !Array.isArray(property_1)) {
                // lets push it recursively
                const recursive = find_common_properties(property_1, property_2);

                final_object = {
                    ...final_object,
                    [key]: {
                        ...recursive
                    }
                };
            }
            else if (Array.isArray(property_1)) {
                // console.log("init", property_1,property_2, key);
                // it is an array
                const max = (property_1?.length > property_2?.length) ? property_1?.length : property_2?.length;
                const object_to_merge = [];

                for (let index = 0; index < max; index++) {
                    const array_value_1 = property_1[index];
                    const array_value_2 = property_2[index];

                    if (array_value_1 === array_value_2) {
                        // same, lets push the values
                        object_to_merge[index] = array_value_1
                    }
                    else {
                        // not same, so lets keep it blank
                        object_to_merge[index] = '';
                    }
                }

                final_object[key] = object_to_merge;
            }
            else {
                // may be int, or string or whatever

                if (property_1 === property_2) {
                    // same, lets push the values
                    final_object[key] = property_1;
                }
                else {
                    // not same, so lets keep it blank
                    final_object[key] = '';
                }
            }
        }
        else {
            // since the type is not same, lets try to do a shallow comparison and see if it matches
            if (property_1 == property_2) {
                final_object[key] = property_1
            }
            else {
                // if anyone of 1 is object, we need to ensure that it goes to the recursive loop and we will ALWAYS give preference to object
                // so if you have two properties as font: { family: 'arial', size: '10px' } and as font: 'arial 10 px', then we will ignore the second font

                if (typeof property_1 === 'object') {
                    const recursive = find_common_properties(property_1, {});

                    final_object = {
                        ...final_object,
                        [key]: {
                            ...recursive
                        }
                    };
                }
                else if (typeof property_2 === 'object') {
                    const recursive = find_common_properties(property_2, {});

                    final_object = {
                        ...final_object,
                        [key]: {
                            ...recursive
                        }
                    };
                }
                else {
                    // just send blank
                    final_object[key] = ''
                }
            }
        }
    });
    return final_object;
};



const RightSidePanel = (props) => {

    const {

        db_infos,
        saveLocalReport,
        duplicateElement,
        rightPanelOpen,
        setRightPanelOpen,
        report_items,
        selected_ids,
        isOverlayShow,
        setIsOverlayShow,
        allReports,
        selection_for_filter

    } = props;


    const questionInfo = selected_ids && props.questionDetailsCache?.[selected_ids[0]];

    const [data_source_hints, set_data_source_hints] = useState(undefined)
    const is_developer_mode = false //(props.userPreference && props.userPreference.setting_value && props.userPreference.setting_value === 'enable')

    const [data_source_selection, set_data_source_selection] = useState(undefined)
    const [report_item_additional_info, set_report_item_additional_info] = useState(undefined)
    const [report_filter_info, set_report_filter_info] = useState(undefined)
    const [filterd_filter_list, set_filterd_filter_list] = useState(undefined)

    const [drill_down_list, set_drill_down_list] = useState(undefined)

    const [drill_down_values, set_drill_down_values] = useState(undefined)
    const [drill_down_input, set_drill_down_input] = useState('')

    const [render_mode_of_report, set_render_mode_of_report] = useState(undefined)
    const [chart_type_of_report, set_chart_type_of_report] = useState(undefined)

    const [selected_date_filter_columns, set_selected_date_filter_columns] = useState()
    const [global_filter_wtr, set_global_filter_wtr] = useState()


    const [cache_settings, set_cache_settings] = useState(undefined)


    const convert_hints_in_to_array = () => {
        const result = [];
        if (props.hintsByDbInfo && props.hintsByDbInfo['__nf__table_column_info']) {
            const table_column_info = props.hintsByDbInfo['__nf__table_column_info'];
            Object.keys(table_column_info).forEach(key => {
                const __info__ = table_column_info[key];
                __info__ && __info__.length > 0 && __info__.forEach(item => {
                    const str = key + "." + item.column_name;
                    result.push(str)
                });
            });
        }
        return result;
    }



    const create_data_source_selection = () => {

        const hints_tables = [];

        if (props.hintsByDbInfo && props.hintsByDbInfo['__nf__table_column_info']) {
            const table_column_info = props.hintsByDbInfo['__nf__table_column_info'];
            Object.keys(table_column_info).forEach(key => {
                const __info__ = table_column_info[key];
                const type = (__info__ && __info__.length > 0 && __info__[0].type) ? __info__[0].type : 'table'
                const _table_info = {
                    table_name: key,
                    type: type,
                    id: key,
                }
                if (!is_value_exist_in_array(hints_tables, "table_name", key)) {
                    hints_tables.push(_table_info)
                }
            });
        }
        set_data_source_selection(hints_tables)
    }

    // useEffect(() => {
    //     create_data_source_selection()
    // }, [props.hintsByDbInfo])

    useEffect(() => {
        // props.getAllReports(undefined, ['data_source', 'config_query_builder'])
        // props.get_all_reports()
    }, []);


    const [current_report, set_current_report] = useState({});
    const [questionFocus, setQuestionFocus] = useState(undefined,)


    /// new 

    // const [reporting_db_info_id, set_reporting_db_info_id] = useState(undefined)



    useEffect(() => {
        /**
         * VIKAS B on 10th Nov 2021: for selecting options in the window, we will use this logic:
         * 
         * when we select multiple controls, and if the types are different, we will only keep the common properties of the selected controls
         * 
         * for example, hide border is common for all controls, but hide legend is not. So if we select a table and a chart, we will show "Hide Border" option but not the hide legend option
         * 
         * so let's set up an object current_report which will ensure that it only contains the common item
         * 
         * 
         * 
         */

        let final_object = undefined;
        setHints([])

        report_items?.length > 0 && report_items.forEach(report_item => {

            if ((selected_ids && selected_ids[0] === report_item.id) && (selected_ids && selected_ids.length == 1)) {

                const report_to_use = (allReports || [])?.find((r) => r.id === report_item?.selected_report_id);
                const report_item_of_report = report_to_use?.report_items?.length > 0 && report_to_use?.report_items[0];

                set_render_mode_of_report(report_item_of_report?.render_mode)
                set_chart_type_of_report(report_item_of_report?.chart_type)


                if (report_item.question && report_item.question.length > 0) {
                    const sorted_additional_info = report_item.report_item_additional_info && report_item.report_item_additional_info.sort((a, b) => a.order - b.order);
                    const report_filter_info = report_item.parameters;
                    const final_parameters = [];

                    props.getAllInfoOfQuestion(report_item.question, report_item?.reporting_db_info_id, report_item.id)

                    set_report_item_additional_info(sorted_additional_info)
                    set_drill_down_values(report_item.drill_down_values)
                    set_selected_date_filter_columns(report_item.date_filter_columns)
                    set_global_filter_wtr(report_item?.global_filter_wtr)

                } else {
                    set_report_item_additional_info([])
                    set_report_filter_info([])
                    set_drill_down_values([])
                    set_selected_date_filter_columns(undefined)
                    set_global_filter_wtr(undefined)


                }
            }
        });


        report_items?.length > 0 && report_items.forEach(report_item => {
            if (selected_ids.indexOf(report_item.id) > -1) {
                if (typeof final_object === 'undefined') {
                    final_object = report_item;
                    // console.log("report_item", report_item)

                }
                else {
                    final_object = find_common_properties(final_object, report_item);
                }
            }
        });


        if (final_object && final_object.db_info_id) {
            // props.getStringHintsFromServer(final_object?.reporting_db_info_id || final_object.db_info_id);
            // props.getHintsByDbInfo(final_object?.reporting_db_info_id || final_object.db_info_id)
        }


        if (final_object && final_object.data_source) {
            setHintByDataSourceId(final_object.data_source)
        }
        if (final_object && final_object.question && (typeof final_object.question === 'string') && final_object.question.trim().length > 0) {
            setQuestion(final_object.question);
        }
        else setQuestion(undefined);


        if (final_object && !final_object?.report_item_type) final_object.report_item_type = "dashboard";
        if (final_object?.frequency) {
            const value = get_frequency_value('frequency_value', final_object)
            const type = get_frequency_value('frequency_type', final_object)
            final_object.frequency_value = value || '';
            final_object.frequency_type = type || '';
        }
        set_current_report(final_object);

        // console.log("final_object", final_object)
        set_report_modified_properties({});
        setTempQuestion(undefined);

    }, [selected_ids,])

    // const [localReport, saveLocalReport] = useState(undefined)

    // for hints 

    const [report_modified_properties, set_report_modified_properties] = useState({});

    const [question, setQuestion] = useState('');
    const [hints, setHints] = useState([]);
    const [activeHint, setActiveHint] = useState(-1);
    const [tempQuestion, setTempQuestion] = useState(undefined);
    const [scrollValue, setScrollValue] = useState(0);
    const [hintsShow, setHintShow] = useState(false)
    const [tableMapping, setTableMapping] = useState(undefined);
    const [default_number_columns, setDefaultNumberColumns] = useState([]);
    const [focus_on_filter_input, set_focus_on_filter_input] = useState(undefined);
    const [focus_on_drill_down, set_focus_on_drill_down] = useState(undefined);

    const [suggestionBoxPosition, setSuggestionBoxPosition] = useState({ top: 50, left: 0 });
    const [currCursorPosition, setCurrCursorPosition] = useState(undefined);

    const [setting_col, set_setting_col] = useState({
        "additional_setting": false,
        "interactive": false,
        "filter_setting": false,
        "date_setting": false,
        "widget_setting": true,
    });


    const _inputRef = useRef(null);
    const InputRef = useRef(null);
    const onClickInputRef = useRef(null);
    const filterInputRef = useRef(null);


    const update_question = (value) => {
        const new_param_object = {
            ...report_modified_properties,
            question: value,
            isQuestionModified: true,
        };

        set_report_modified_properties(new_param_object);
        set_current_report({ ...current_report, ...new_param_object })
        saveLocalReport(new_param_object);
    }



    const render_title_by_chart_type = (type) => {
        const charts_t = [...default_charts, ...pie_charts];
        if (charts_t.indexOf(type) > -1) {
            return 'Chart';
        }
        if (type === 'label') return 'Label';
        if (type === 'group') return 'Box';
        if (type === 'single_cell') return 'Single Cell';
        if (type === 'table') return 'Table';
    }

    // useEffect(() => {
    //     // saveLocalReport(current_report)
    //     // if (current_report && current_report.report_item_additional_info) {
    //     //     report_item_additional_info = current_report.report_item_additional_info;
    //     // }
    //     if (current_report && current_report.db_info_id) {
    //         props.getStringHintsFromServer(current_report.db_info_id);
    //     }
    //     if (current_report && current_report.question) {
    //         setQuestion(current_report.question)
    //     } else setQuestion(undefined);
    //     setTempQuestion(undefined)
    // }, [selected_ids])


    useEffect(() => {
        // if (report_item_additional_info) {
        setTableMapping(report_item_additional_info)
        // }
    }, [report_item_additional_info])


    const clientIdFromSession = getFromSession(constants.SESSION_KEYS.CLIENT_ID);

    useEffect(() => {
        if (clientIdFromSession && (clientIdFromSession === 'adskom' || clientIdFromSession === 'ads_yahoo')) {
            setDefaultNumberColumns(['cpc', 'cpm', 'ctr', 'cpl'])
        }
    }, [clientIdFromSession])


    const checkColumnNameExistInTable = (array, columnName) => {
        if (array && array.length > 0) {
            for (let index = 0; index < array.length; index++) {
                const element = array[index];
                if (element.column_name === columnName) {
                    return true;
                }
            }
        } else return false
    }


    const first_letter_cap = (letter) => {
        if (letter && letter.trim().length > 0) {
            let temp_letter = letter.split("")
            let first_letter = temp_letter[0].toUpperCase();
            temp_letter[0] = first_letter;
            return temp_letter.join("")
        }
    }

    useEffect(() => {

        {/* number should be y Axis Primary 
        string should be x axis */}
        {/* y axis primary and y axis only should be save in report_item_info */ }
        {/* Comparison should be save in report_item conprision column */ }
        {/* x axis should be save in x data key  */ }
        {/* y axis shoul also save in y data key  */ }


        let cloneTableMapping = (tableMapping && tableMapping.length > 0) ? [...tableMapping] : [];
        const stateTableMappingColumns = (cloneTableMapping && cloneTableMapping.length > 0) ? cloneTableMapping.map((column) => column.column_name) : [];
        let tempMapping = [];
        let columnsToMap = [];
        let numberDataTypeField = [];
        if (questionInfo && questionInfo.columns_extracted && questionInfo.columns_extracted.length > 0) {
            questionInfo.columns_extracted.map((column) => {
                if (default_number_data_types.map((k) => k.toLocaleLowerCase()).indexOf(column.data_type && column.data_type.toLocaleLowerCase()) > -1) {
                    numberDataTypeField.push({
                        column_name: column.column_name,
                        data_type: column.data_type,
                        column_table: column.column_table
                    })
                }
            })
        }
        if (questionInfo && questionInfo.columns_extracted && questionInfo.columns_extracted.length > 0 && (questionInfo.comparing_column_info.length === 0 || questionInfo.comparing_column_info === 'undefined')) {
            questionInfo.columns_extracted.map((column) => {
                columnsToMap.push({
                    column_name: column.column_name,
                    data_type: column.data_type,
                    column_table: column.column_table
                })
            })
        }
        if (questionInfo && questionInfo.comparing_column_info && questionInfo.comparing_column_info.length > 0) {
            questionInfo.comparing_column_info.forEach((comp) => {
                numberDataTypeField && numberDataTypeField.length > 0 && numberDataTypeField.forEach((column) => {
                    let orignal_name = first_letter_cap(comp.column_name)
                    let last_name = first_letter_cap(column.column_name)
                    let final_name = orignal_name + "-" + last_name;
                    columnsToMap.push({
                        column_name: final_name,
                        data_type: column.data_type,
                        column_table: column.column_table
                    })
                })
            })
        }
        if (questionInfo && questionInfo.columns_extracted) {
            if (columnsToMap && columnsToMap && columnsToMap.length > 0) {
                columnsToMap.map((column) => {
                    let temp_column = JSON.parse(JSON.stringify(column));
                    temp_column.data_type = temp_column.data_type ? temp_column.data_type : "abc";

                    if (report_item_additional_info && report_item_additional_info.length > 0 && checkColumnNameExistInTable(report_item_additional_info, temp_column.column_name)) {
                        report_item_additional_info.map((info) => {
                            if (info.column_name === temp_column.column_name) {
                                tempMapping.push({
                                    column_name: temp_column.column_name,
                                    chart_type: info.chart_type,
                                    axis_position: info.axis_position
                                })
                            }
                        })
                    }
                    else if ((default_number_data_types.map((k) => k.toLocaleLowerCase()).indexOf(temp_column.data_type && temp_column.data_type.toLocaleLowerCase()) > -1) || (default_number_columns.indexOf(temp_column.column_name) > -1)) {
                        tempMapping.push({
                            column_name: temp_column.column_name,
                            axis_position: "Y Axis Primary"
                        })
                    }
                    else {
                        tempMapping.push({
                            column_name: temp_column.column_name,
                            axis_position: "X Axis"
                        })
                    }
                })

            } else {
                tempMapping = [];
            }

            if (question && question.length > 0 && (clientIdFromSession === 'adskom' || clientIdFromSession === 'ads_yahoo')) {
                let _split_question = question.split(" ");
                _split_question && _split_question.length > 0 && _split_question.map((column) => {
                    if (!checkColumnNameExistInTable(tempMapping, column)) {
                        if ((default_number_columns && default_number_columns.indexOf(column) > -1)) {
                            tempMapping.push({
                                column_name: column,
                                axis_position: "Y Axis Primary"
                            })
                        } else {
                            tempMapping.push({
                                column_name: column,
                                axis_position: "X Axis"
                            })
                        }
                    }
                })
            }




            const uniqueArray = Array.from(new Set(tempMapping.map(a => a.column_name)))
                .map(id => {
                    return tempMapping.find(a => a.column_name === id)
                })

            const filter_x_axis = uniqueArray && uniqueArray.filter((a) => a.axis_position === 'X Axis');
            if (filter_x_axis && filter_x_axis.length === 2) {
                const last_element = filter_x_axis[filter_x_axis.length - 1];
                uniqueArray.map((k) => {
                    if (k.column_name === last_element.column_name) {
                        // k.axis_position = "Comparison"
                    }
                })
            }
            setTableMapping(uniqueArray)
        }




        const temp_parameters_filter = [];

        if (questionInfo && questionInfo.be_filters && questionInfo.be_filters.length > 0) {
            questionInfo.be_filters.forEach((f) => {
                temp_parameters_filter.push({
                    filter_name: f.column_name,
                    alias: f.alias,
                    filter_default_values: []
                })
            })
            set_report_filter_info(temp_parameters_filter)
        } else {
            set_report_filter_info(undefined)
        }

        // let's check for FILTER FIELDS



        // set_report_filter_info
    }, [questionInfo])


    // useEffect(() => {

    //     if (questionInfo?.tables) {

    //         // setTimeout(() => {
    //             const pre_parameters = current_report?.parameters;

    //             const post_parameters = (pre_parameters || []).filter((t) => {
    //                 const table_name = t.split('.')[0];
    //                 if (questionInfo?.tables.indexOf(table_name) > -1) {
    //                     return true;
    //                 }
    //             });

    //             const local_report_object = {};
    //             local_report_object.parameters = post_parameters;
    //             local_report_object.modified = true;

    //             set_report_modified_properties(local_report_object);
    //             set_current_report({ ...current_report, ...local_report_object })
    //             saveLocalReport(local_report_object);
    //         // }, 700)

    //     }
    // }, [questionInfo])


    const onDrpodownTextChange = (value, key) => {

        if (key === 'selected_report_id') {
            // set_render_mode_of_report
            const report_to_use = (allReports || [])?.find((r) => r.id === value);
            const report_item = report_to_use?.report_items?.length > 0 && report_to_use?.report_items[0];
            set_render_mode_of_report(report_item?.render_mode)
            set_chart_type_of_report(report_item?.chart_type)
        }

        const local_report_object = Object.assign({}, report_modified_properties);
        local_report_object[key] = value;

        if (key === 'question') {
            // props.getStringHintsFromServer(value);
            local_report_object.isQuestionModified = true;
        }


        // if (key === 'db_info_id') {
        //     props.getHintsByDbInfo(value);
        //     props.getStringHintsFromServer(value);
        // }

        if (key === 'data_source') {
            setHintByDataSourceId(value);
        }

        set_report_modified_properties(local_report_object);
        set_current_report({ ...current_report, ...local_report_object })


        saveLocalReport(local_report_object);
    };


    const onTextChange = key => event => {
        const value = event.target.value;
        const local_report_object = Object.assign({}, report_modified_properties);
        local_report_object[key] = value;
        if (key === 'question') {
            local_report_object['isQuestionModified'] = true;
        }

        set_report_modified_properties(local_report_object);
        set_current_report({ ...current_report, ...local_report_object })
        saveLocalReport(local_report_object);
    };



    // new version code
    const onTextChangeV1 = (key, value) => {
        const local_report_object = Object.assign({}, report_modified_properties);
        local_report_object[key] = value;
        if (key === 'question') {
            local_report_object['isQuestionModified'] = true;
        }

        set_report_modified_properties(local_report_object);
        set_current_report({ ...current_report, ...local_report_object })
        saveLocalReport(local_report_object);
    }


    const is_value_container_hint = (hint) => {

        if (!props.containerValues || props.containerValues.length === 0 || !hint) return false;

        else {
            return props.containerValues?.length > 0 && hint && props.containerValues.indexOf(hint) > -1;
        }

    }


    const replaceWordAtCursor = (inputString, _x, newWord, is_value_container_value) => {
        const cursorPosition = InputRef.current.selectionStart;
        const inputStringCurr = InputRef.current.value;

        const valueBeforeCursor = inputStringCurr.slice(0, currCursorPosition || cursorPosition);
        const words = valueBeforeCursor.split(' ');
        words.pop();
        !currCursorPosition && setCurrCursorPosition(words.join(" ")?.length + 1);
        const last_word = words?.[words.length - 1]
        if (newWord && (last_word === newWord.split(' ')[0] || newWord?.includes(last_word))) {
            words.pop();
        }

        const wordToConcate = is_value_container_value ? ("'" + newWord + "'") : newWord;
        const newValue = [...words, wordToConcate].join(' ') + inputStringCurr.slice(cursorPosition);
        const newCursorPosition = words.join(' ')?.length + wordToConcate?.length + 1;

        setTimeout(() => {
            if (!InputRef.current) return;
            if (!inputStringCurr?.slice(cursorPosition)?.trim()) {
                InputRef.current.scrollLeft = Math.max(0, InputRef.current.scrollWidth - InputRef.current.clientWidth);
            } else {
                InputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
            }
        }, 0);

        return {
            newString: newValue,
            newCursorPosition,
        };
    };


    const replace_hint_with_word = (index, setQues, cursorPosition, historyString) => {
        const local_report_object = Object.assign({}, report_modified_properties);
        local_report_object['isQuestionModified'] = true;
        if (question && question.length > 0) {
            // let temp_question = question;
            const active_index = (index || index === 0) ? index : activeHint;

            /* 
                * Splitting the words into the tokens.
                * Then Removing the last word.
            */

            // temp_question = temp_question ? temp_question.split(' ') : '';
            // temp_question.pop();

            // const last_word = temp_question[temp_question.length - 1];
            // const active_hint = hints[active_index]

            /* 
                * Checking again if the last word in the temp_question is equal to splitted hint at index of 0.
                * Then again pop it.
            */

            // if (active_hint && last_word === active_hint.split(' ')[0]) {
            //     temp_question.pop();
            // }

            /* 
                * Now joining the temp_question and adding the active hint into it.
            */

            // temp_question = temp_question.join(' ');
            // const new_question = temp_question + ' ' + hints[active_index];

            const active_hint = hints[active_index];
            const is_value_container_value = is_value_container_hint(active_hint)
            const updatedString = replaceWordAtCursor(question, cursorPosition, active_hint, is_value_container_value);

            if (setQues) {
                setTempQuestion(undefined);
                setQuestion(updatedString?.newString);
                local_report_object["question"] = updatedString?.newString;
                set_report_modified_properties(local_report_object);
                set_current_report({ ...current_report, ...local_report_object })
                saveLocalReport(local_report_object);
            }
            else {
                setTempQuestion(updatedString?.newString);
            }
        } else {
            setTempQuestion(historyString);
        }

    };


    const update_question_by_hint = (hint, __index__) => {
        let temp_question = question;
        const new_question = temp_question + ' ' + hint;
        setActiveHint(__index__)
        setTempQuestion(new_question);
    }


    const scroll = position => {
        // if (activeHint < hints.length - 1 && activeHint > 0) {
        //     const element = document.getElementById('li');
        //     const value = position === 'down' ? (30) + scrollValue : scrollValue - 30;
        //     element.scrollTop = value;

        //     setScrollValue(value);
        // }
    };


    // const hints_array = is_developer_mode ? ((props.filterOptions && Object.keys(props.filterOptions)?.length > 0 && Object.keys(props.filterOptions)) || []) : props.hints ? [...props.hints] : [];
    const hints_array = props.hints ? [...props.hints] : [];

    const on_change_question = (event, __value__, wrapperRef) => {
        const cursorPosition = event.target.selectionStart;
        currCursorPosition && setCurrCursorPosition(undefined)

        const value = event?.target?.value || __value__;
        const local_report_object = Object.assign({}, report_modified_properties);
        local_report_object["question"] = value;
        local_report_object['isQuestionModified'] = true;
        local_report_object['delete_from_storage'] = true;
        local_report_object['call_java_forcefully'] = true
        // when question will be changed we will change the filter key in db

        local_report_object["validation_keys"] = {
            "filter_key": generate_unique_key("nf_sr"),
        };

        setQuestion(value)
        setActiveHint(-1)
        setHints([])
        setTempQuestion(undefined)
        setScrollValue(0)


        if (value?.length === 0) {
            setHints([]);
            setTempQuestion(undefined);
        }
        else {

            // const full_question = value?.split(' ');
            // const last_word = full_question?.[full_question?.length - 1];
            // const currentWord = last_word;
            const words = value?.slice(0, cursorPosition)?.split(/\s+/);
            const currentWord = words?.[words?.length - 1];

            let temp_hints = [];
            let concatenate_word = '';
            setHints([])

            if (currentWord && currentWord?.length > 0) {
                /* 
                    * find_hint_in_array()
                        * This is pure magic of Regex.
                        * We are using the regex to get the values from the hints array.
                        * Please see the logic and comments of find_hint_in_array for better understanding
                */

                let final_hints_to_be_filter = hints_array;
                if (current_report?.data_source) {
                    final_hints_to_be_filter = data_source_hints || [''];
                }
                const result = find_hint_in_array(currentWord, hints, final_hints_to_be_filter);
                temp_hints = temp_hints?.concat(result);
            }

            if (temp_hints?.length > 0) {
                const unique_hints = Array.from(new Set(temp_hints)).filter(value => !(value === currentWord || value === concatenate_word));
                setHints(unique_hints)
                setHintShow(true)
                const position = calculatePositionForSuggestionBox(cursorPosition, InputRef, wrapperRef, 'nowrap');
                setSuggestionBoxPosition({ top: position?.top + 18, left: position?.left })
            }
        }

        setQuestion(value);
        set_report_modified_properties(local_report_object);
        set_current_report({ ...current_report, ...local_report_object })
        saveLocalReport(local_report_object);


    }


    const questionKeyDown = (event) => {

        const code = event.keyCode;
        const value = event.target.value;
        const cursorPosition = event.target.selectionStart;


        // if (event.key === 'Enter') {
        //     setQuestionFocus(undefined);

        //     if (value && value.length > 0) {
        //         props.getAllInfoOfQuestion(value, (current_report && current_report.reporting_db_info_id), selected_ids?.[0])
        //     }
        //     if (value.length === 0 || value.trim().length === 0) {
        //         return
        //     }
        //     else {
        //         // clear_all_filters();
        //         // props.clearBreadCrumb();

        //         // if (pathname !== '/analytics_map') {
        //         //     _getDataFromReportingServer(tempQuestion ? tempQuestion : question, props.history)
        //         // }
        //         // else {
        //         //     props.history.push(`?question=${tempQuestion ? tempQuestion : question}`)
        //         // }              
        //         update_question(value)
        //         replace_hint_with_word(activeHint, true, cursorPosition)
        //         // setQuestion(tempQuestion ? tempQuestion : question)
        //         // setTempQuestion(undefined)
        //         setActiveHint(-1)
        //         setHints([])
        //         setScrollValue(0)
        //         event.target.blur()
        //     }
        // }

        /* 
            * 38 is key code of up arrow key.
            * It will change the active hint by -1                                
        */

        if (code === 38) {
            if (activeHint > -1) {
                event.preventDefault()
                const index = activeHint - 1;
                if (index === -1) {
                    setTempQuestion(undefined)
                }
                else {
                    replace_hint_with_word(index, true, cursorPosition)
                }
                setActiveHint(index);
                scroll('up')
            }
        }

        /* 
            * 40 is key code of down arrow key.
            * It will change the active hint by +1                                
        */

        if (code === 40) {
            if (activeHint < hints.length - 1) {
                event.preventDefault()
                const index = activeHint + 1;
                replace_hint_with_word(index, true, cursorPosition)
                setActiveHint(index)
                scroll('down')
            }
        }

        /* 
            * 9 is key code of Tab key.
            * 32 is key code of Space key.
            * On Pressing Tab or Space => active hint join the question and setHintsArray to []
        */

        if (code === 9 || code === 32) {
            if (code === 9) {
                event.preventDefault()
            }
            setActiveHint(-1)
            setQuestion(tempQuestion ? tempQuestion : question, undefined, true, undefined)
            setTempQuestion(undefined)
            setHints([])
            setScrollValue(0)
            event.target.focus()
        }

        /* 
            * 8 is key code of BackSpace key.
            * 27 is key code of Esc key.
        */
        if (code === 8 || code === 27) {
            setHints([])
            setActiveHint(-1)
            setScrollValue(0)

            if (code === 27) {
                setTempQuestion(undefined)
            }
        }
    }


    const onChangeType = type => {
        const local_report_object = Object.assign({}, report_modified_properties);

        if (type === 'group') {
            local_report_object.chart_type = 'group';
            local_report_object.render_mode = 'group'
            local_report_object.is_group = true;
            local_report_object.question = '';
        }
        else if (type === 'table') {
            local_report_object.chart_type = 'table';
            local_report_object.render_mode = 'table'
            local_report_object.is_group = undefined;

        }
        else {
            local_report_object.chart_type = type;
            local_report_object.render_mode = 'chart'
            local_report_object.is_group = undefined

        }

        if (type !== 'group' && current_report?.report_item_type === 'dashboard_config_query_builder') {
            // then clear set auto pivot mode



            const union_report_items = current_report?.union_report_items?.length > 0 ? [...current_report?.union_report_items] : [];
            // change_render_mode_v1

            const union_results = change_render_mode_v1(union_report_items, type, "column_infos")

            const display_columns = (current_report?.column_infos ? JSON.parse(JSON.stringify(current_report?.column_infos)) : []).sort((a, b) => a.order - b.order);

            const y_data_type = ['int', 'float', 'double', 'number', 'currency'];

            if (type !== 'table') {
                // if render mode is not a table then we need to create pivot
                display_columns.forEach((f, i) => {

                    if (((y_data_type.indexOf(f?.data_type?.toLocaleLowerCase()) > -1) || f?.aggregation) && !f?.pivot_type) {
                        f.pivot_type = 'pivot_y';
                        f.fe_pivot_type = 'pivot_y';
                        f.pivot_field_alias = f?.alias;
                        f.pivot_field_column_name = f?.alias;
                        f.pivot_field_order = i + 1;
                        f.modified = true;
                    }

                    else if (!f?.pivot_type) {
                        // this will got to pivot y
                        f.pivot_type = 'pivot_x';
                        f.fe_pivot_type = 'pivot_x';
                        f.pivot_field_alias = f?.alias;
                        f.pivot_field_column_name = f?.alias;
                        f.pivot_field_order = i + 1;
                        f.modified = true;
                    }
                })
            }

            if (type === "table" || type === "single_cell") {
                display_columns.forEach((f, i) => {
                    f.pivot_type = null;;
                    f.fe_pivot_type = null;
                    f.modified = true;
                })
            }

            display_columns.forEach((f) => {
                if (!f.is_formula && !f.is_repeater) {
                    const proper_case = get_pivot_field_in_proper_case(f.pivot_field_column_name) || f.pivot_field_column_name
                    f.pivot_field_alias = proper_case;
                    f.pivot_field_column_name = proper_case;
                    f.modified = true;
                }
            })


            // console.log("display_columns", display_columns, type, union_results)

            local_report_object.column_infos = display_columns;
            local_report_object.modified = true;

            if (union_report_items?.length > 0 && union_results?.report_items) {
                local_report_object.union_report_items = union_results.report_items
            }
        }

        set_report_modified_properties(local_report_object);
        set_current_report({ ...current_report, ...local_report_object })
        saveLocalReport(local_report_object);
        // console.log("local_report_object", current_report)

    };


    const changeAppearance = (key, value) => {
        const local_report_object = Object.assign({}, report_modified_properties);
        if (key) {
            local_report_object[key] = value;
        }
        set_report_modified_properties(local_report_object);
        set_current_report({ ...current_report, ...local_report_object })
        saveLocalReport(local_report_object);
    };


    const onChnageTableMapping = (value, key, index) => {
        let temptableMapping = tableMapping ? JSON.parse(JSON.stringify(tableMapping)) : [];
        if (report_item_additional_info) {
            temptableMapping[index].modified = true;
        }
        temptableMapping[index][key] = value;
        setTableMapping(temptableMapping)

    }

    useEffect(() => {

        const local_report_object = Object.assign({}, report_modified_properties);

        let temp_report_filter_info = report_filter_info ? JSON.parse(JSON.stringify(report_filter_info)) : [];
        const __parameters__ = [];
        temp_report_filter_info && temp_report_filter_info.length > 0 && temp_report_filter_info.map((f) => {
            const _str__ = f.filter_name + "__nf__" + f.alias + "__nf__" + f.filter_default_values;
            __parameters__.push(_str__)
        })

        let temptableMapping = tableMapping ? JSON.parse(JSON.stringify(tableMapping)) : [];
        local_report_object['report_item_additional_info'] = temptableMapping;
        local_report_object['modified'] = true;
        // local_report_object['parameters'] = __parameters__;

        set_report_modified_properties(local_report_object);
        set_current_report({ ...current_report, ...local_report_object })
        saveLocalReport(local_report_object);
    }, [tableMapping, report_filter_info])



    // useEffect(() => {
    //     let temp_report_filter_info = report_filter_info ? JSON.parse(JSON.stringify(report_filter_info)) : [];
    //     const __parameters__ = [];
    //     temp_report_filter_info && temp_report_filter_info.length > 0 && temp_report_filter_info.map((f) => {
    //         const _str__ = f.filter_name + "__nf__" + f.alias + "__nf__" + f.filter_default_values;
    //         __parameters__.push(_str__)
    //     })
    //     const local_report_object = Object.assign({}, report_modified_properties);
    //     local_report_object['parameters'] = __parameters__;
    //     local_report_object['modified'] = true;
    //     set_report_modified_properties(local_report_object);
    //     set_current_report({ ...current_report, ...local_report_object })
    //     saveLocalReport(local_report_object);
    // }, [report_filter_info])



    useEffect(() => {


        let local_drill_down_values = drill_down_values ? JSON.parse(JSON.stringify(drill_down_values)) : [];
        const local_report_object = Object.assign({}, report_modified_properties);
        local_report_object['drill_down_values'] = local_drill_down_values;
        local_report_object['modified'] = true;
        set_report_modified_properties(local_report_object);
        set_current_report({ ...current_report, ...local_report_object })
        saveLocalReport(local_report_object);


    }, [drill_down_values])




    const _localduplicate = (e, ref) => {

        // let _cloned_report =
        const templete = {
            title: current_report.title ? "copy " + current_report.title : '',
            question: current_report.question ? current_report.question : '',
            chart_type: current_report.chart_type ? current_report.chart_type : '',
            render_mode: current_report.render_mode ? current_report.render_mode : '',
            criteria: current_report.criteria ? current_report.criteria : '',
            entity_name: current_report.entity_name ? current_report.entity_name : '',
            height: current_report.height ? current_report.height : '',
            width: current_report.width ? current_report.width : '',
            top: current_report.top ? current_report.top : '',
            left: current_report.left ? current_report.left : '',
            is_created: true,
            id: generate_unique_key("nf_sr"),
            report_id: current_report.report_id,
            db_info_id: current_report.db_info_id,
            whichFormat: current_report.whichFormat,
        }

        duplicateElement(templete)
    }


    const update_theme_value = (__values__) => {
        const local_report_object = Object.assign({}, report_modified_properties);
        local_report_object['theme_json_values'] = __values__;

        set_report_modified_properties(local_report_object);
        set_current_report({ ...current_report, ...local_report_object })
        saveLocalReport(local_report_object);
    };


    const weget_render_mode = current_report && current_report.render_mode;
    const weget_chart_type = current_report && current_report.chart_type

    const render_mode_to_use = (render_mode_of_report || weget_render_mode);
    const chart_type_to_use = (chart_type_of_report || weget_chart_type);


    const chart_axis_setting_to_use = ( !["funnel_chart"].includes(weget_chart_type) ) && chart_axis_setting?.length > 0 && chart_axis_setting.filter(item => {
        if (item.render_mode && item.render_mode.indexOf(render_mode_to_use) > -1 && item.chart_type.indexOf(chart_type_to_use) > -1) return true;
        else return false
    });


    const chart_apperance_to_use = appearance_type?.length > 0 && appearance_type.filter(item => {
        if (item.render_mode && item.render_mode.indexOf(render_mode_to_use) > -1 && item.chart_type.indexOf(chart_type_to_use) > -1) return true;
        else return false
    })



    const chart_type = weget_chart_type
    const render_mode = weget_render_mode
    const is_grouped = current_report && current_report.is_group;

    const final_render_type = render_mode ? render_mode : is_grouped ? is_grouped : chart_type;

    const show_label = final_render_type === 'label' || final_render_type === 'chart' || final_render_type === 'table' || final_render_type === 'report';
    const show_question = final_render_type === 'chart' || final_render_type === 'table'
    const show_db_source = final_render_type === 'chart' || final_render_type === 'table';



    const setHintByDataSourceId = (id) => {

        const __hints__ = [];

        if (id && typeof id !== 'undefined') {
            if (props.hintsByDbInfo && props.hintsByDbInfo['__nf__table_column_info']) {
                const table_column_info = props.hintsByDbInfo['__nf__table_column_info'];
                Object.keys(table_column_info).forEach(key => {
                    if ((key && key.toLowerCase()) === (id && typeof id !== 'undefined' && typeof id === 'string' && id?.toLowerCase())) {
                        const __info__ = table_column_info[key];
                        __info__ && __info__.length > 0 && __info__.forEach(item => {
                            const str = is_developer_mode ? (key + "." + item.column_name) : item.column_name;
                            __hints__.push(str)
                        });
                    }
                });
            }
        }
        set_data_source_hints(__hints__)
        setHints([])
    }

    const save_formula = (formula, index, report_index) => {

        const local_report_object = Object.assign({}, current_report);
        const tbl_ui_formulas = (local_report_object && local_report_object.tbl_ui_formulas) ? [...local_report_object.tbl_ui_formulas] : [];
        const __obj__ = {
            ...formula,

        }
        delete __obj__.nf_formula_conditions
        if (index > -1) {
            tbl_ui_formulas[index] = __obj__
        }
        else {
            tbl_ui_formulas.push(__obj__)
        }
        local_report_object.tbl_ui_formulas = tbl_ui_formulas;
        set_report_modified_properties(local_report_object);
        set_current_report({ ...current_report, ...local_report_object })
        saveLocalReport(local_report_object);
    }

    const remove_formula = (index) => {
        const local_report_object = Object.assign({}, current_report);
        const tbl_ui_formulas = (local_report_object && local_report_object.tbl_ui_formulas) ? [...local_report_object.tbl_ui_formulas] : [];
        tbl_ui_formulas[index].deleted = true;
        local_report_object.tbl_ui_formulas = tbl_ui_formulas;
        set_report_modified_properties(local_report_object);
        set_current_report({ ...current_report, ...local_report_object })
        saveLocalReport(local_report_object);
    }



    const open_formula_creator = (item, index) => {
        const clone_item = item ? Object.assign({}, item) : undefined;
        const __hints__ = data_source_hints;
        showPopup(undefined, undefined, enums.default.popupType.element, FormulaEditorPopup, {
            items: clone_item ? clone_item : undefined,
            index: index,
            save_formula: save_formula,
            columns: __hints__,
            hide_condition: true,
        })
    }



    const set_formula = (global_formula) => {
        const local_report_object = Object.assign({}, current_report);
        const tbl_ui_formulas = (local_report_object && local_report_object.tbl_ui_formulas) ? [...local_report_object.tbl_ui_formulas] : [];
        local_report_object.global_formula = global_formula;
        set_report_modified_properties(local_report_object);
        set_current_report({ ...current_report, ...local_report_object })
        saveLocalReport(local_report_object);
    }

    const remove_formula_global_formula = (index) => {
        const local_report_object = Object.assign({}, current_report);
        const global_formula = (local_report_object && local_report_object.global_formula) ? [...local_report_object.global_formula] : [];
        global_formula.splice(index, 1)
        local_report_object.global_formula = global_formula;
        set_report_modified_properties(local_report_object);
        set_current_report({ ...current_report, ...local_report_object })
        saveLocalReport(local_report_object);
    }

    // const open_formula_chooser = () => {

    //     const _array_of_hints = props.rulesHints && props.rulesHints.length > 0 && props.rulesHints.map((item) => item.column_name)

    //     showPopup(undefined, undefined, enums.default.popupType.element, SelectDisplayField,
    //         {
    //             menus: props.menus,
    //             displayItem: _array_of_hints ? _array_of_hints : [],
    //             selectedFields: current_report && current_report.global_formula ? current_report.global_formula : [],
    //             title: 'Select Formula',
    //             display_key: 'column_name',
    //             value_key: '',
    //             setSelectedFields: set_formula,
    //             width: '25rem'
    //         }
    //     );
    // }


    const change_report_filter_info = (key, value, index) => {
        const clone_report_filter_info = report_filter_info ? [...report_filter_info] : [];
        clone_report_filter_info[index]["modified"] = true;
        if (key === 'filter_default_values') {
            clone_report_filter_info[index][key] = [value];
        } else {
            clone_report_filter_info[index][key] = value;
        }
        set_report_filter_info(clone_report_filter_info)

    }

    const delete_filter = (index) => {
        const clone_report_filter_info = report_filter_info ? [...report_filter_info] : [];
        clone_report_filter_info.splice(index, 1)
        set_report_filter_info(clone_report_filter_info)
    }


    const setting_toggle = (key) => {
        const temp_setting_col = setting_col ? { ...setting_col } : {};
        temp_setting_col && Object.keys(setting_col).map((k) => {
            if (k !== key) temp_setting_col[k] = false;
        })
        temp_setting_col[key] = temp_setting_col[key] ? false : true;
        set_setting_col(temp_setting_col)
    }

    const hide_db_selections = db_infos?.length === 1;

    /**
     * 
     */


    const update_report_item = (modefied_properties) => {
        const local_report_object = modefied_properties ? Object.assign(report_modified_properties, modefied_properties) : {}
        set_report_modified_properties(local_report_object);
        set_current_report({ ...current_report, ...local_report_object })
        saveLocalReport(local_report_object);


    }


    const open_no_code_popup = (type, report_item_to_use) => {

        const temp_current_report = report_item_to_use ? JSON.parse(JSON.stringify(report_item_to_use)) : {};

        delete temp_current_report['top'];
        delete temp_current_report['left'];
        delete temp_current_report['top_px'];
        delete temp_current_report['left_px'];
        delete temp_current_report['width'];
        delete temp_current_report['height'];
        delete temp_current_report['width_px'];
        delete temp_current_report['height_px'];

        const report_item = report_items?.length > 0 && report_items[0];

        const report_item_title = type === 'dashboard_config_query_builder' ? 'Drag & Drop' : "NoteBook";

        showPopup(report_item_title, undefined, enums.default.popupType.element_with_header, NoCodePopup, {

            db_info_id: temp_current_report?.db_info_id,
            __reporting_db_info_id: temp_current_report?.reporting_db_info_id,
            chart_type: temp_current_report?.db_info_id,
            render_mode: temp_current_report?.chart_type,
            current_report: temp_current_report,
            report_type: type,
            save_current_report: update_report_item

        },
            undefined,
            undefined,
            undefined, {
            width: '95vw',
            height: '85vh'
        },
            true
        )
    }


    const change_report_item_type = (type, need_to_remove, need_to_show_popup = false) => {

        const local_report_object = report_modified_properties && Object.keys(report_modified_properties)?.length > 0 ? Object.assign({}, report_modified_properties) : {}

        const prev_report = { ...(current_report || {}), ...local_report_object }

        local_report_object['report_item_type'] = type;
        local_report_object['isQuestionModified'] = true;

        if (need_to_remove) {

            local_report_object['display_columns'] = []
            local_report_object['column_infos'] = [];
            local_report_object['parameters'] = [];
            local_report_object['json_data'] = '{}';
            local_report_object['criteria'] = '';
            local_report_object['delete_from_storage'] = true;
            local_report_object['call_java_forcefully'] = true;

        }

        set_report_modified_properties(local_report_object);
        set_current_report({ ...current_report, ...local_report_object })
        saveLocalReport(local_report_object);

        const report_item_to_use = { ...current_report, ...local_report_object }

        if (type === 'dashboard_sql' && prev_report?.report_item_type !== 'dashboard_sql') {
            open_no_code_popup(type, report_item_to_use)
        }

        // const report_columns = (report_item_to_use?.column_infos?.filter((r => !r.deleted)) || [])

        if (type === 'dashboard_config_query_builder' && prev_report?.report_item_type !== 'dashboard_config_query_builder') {
            open_no_code_popup(type, report_item_to_use)
        }

        if (type === 'dashboard' && prev_report?.report_item_type !== 'dashboard') {
            setQuestionFocus(true)
        }
    }


    const open_report_editor = (type) => {
        const local_report_object = report_modified_properties ? Object.assign(report_modified_properties) : {}
        const report_item_to_use = { ...current_report, ...local_report_object }
        open_no_code_popup(type, report_item_to_use)
    }


    const clear_report_fields = (type) => {

        let need_to_show_popup = !current_report.id;

        if (current_report && current_report.column_infos?.length > 0) {
            need_to_show_popup = true;
        }
        if (current_report?.report_item_type === type) {
            need_to_show_popup = false;
        }
        if (need_to_show_popup) {
            showPopup(undefined, 'Changing the mode we clear all the data', enums.default.popupType.element, ConfirmBox, { func: { setYes: () => change_report_item_type(type, true) } })

        }
        else {
            change_report_item_type(type, undefined)
        }
    };

    const convert_hints_in_to_tbl_column_array = () => {
        const result = [];
        props.hintsForFilter && Object.keys(props.hintsForFilter).length && Object.keys(props.hintsForFilter).map((h) => {
            if (h !== 'response_session_id') {
                const table_info = props.hintsForFilter[h].table_info;
                table_info && table_info.length > 0 && table_info.forEach((t) => {
                    const str = t + "." + h;
                    if (result.indexOf(str) === -1) {
                        result.push(str);
                    }
                })
            }
        })
        return result;
    }



    const choose_local_filter = (id) => {

        const table_to_use = (questionInfo?.tables || [])

        const filter_options_v1 = (props?.filterOptions && Object.keys(props.filterOptions)?.length > 0 && Object.keys(props.filterOptions) || []).filter((t) => {
            const table_name = t.split('.')[0];
            if (table_to_use.indexOf(table_name) > -1) {
                return true;
            }
        })


        showPopup('Select Local Filter', undefined, enums.default.popupType.element_with_header, LocalFilterChooser,
            {
                menus: props.menus,
                options_values: filter_options_v1 ? filter_options_v1 : [],
                selected_values: current_report.parameters || [],
                _save_: (filters) => {

                    const local_report_object = Object.assign({}, report_modified_properties);
                    local_report_object.parameters = filters;
                    local_report_object.modified = true;
                    set_report_modified_properties(local_report_object);
                    set_current_report({ ...current_report, ...local_report_object })
                    saveLocalReport(local_report_object);
                },

            }, undefined, undefined, undefined, {
            width: '62rem',
        }
        );
    }


    const updated_reporting_db_info_id = (db_info_id) => {

        const local_report_object = Object.assign({}, report_modified_properties);

        local_report_object.reporting_db_info_id = db_info_id;
        local_report_object.modified = true;
        local_report_object["call_java_forcefully"] = true;

        set_report_modified_properties(local_report_object);
        set_current_report({ ...current_report, ...local_report_object })
        saveLocalReport(local_report_object);

    }


    const options_for_drill_down = props.all_reports?.filter((r) => ['config_query_builder', 'insights', 'sql'].indexOf(r.type) > -1 && props.insightId !== r.id) || []
    const selections_of_od_reports = props.all_reports?.filter((r) => ['insights'].indexOf(r.type) > -1 && props.insightId !== r.id) || []


    const get_cache_setting = () => {
        const temp_cache_settings = {
            is_require_cache: current_report?.is_require_cache,
            frequency: current_report?.frequency,
            cache_table_name: current_report?.["cache_table_name"],
            job_start_time: current_report?.["job_start_time"],
            refresh_mode: current_report?.["refresh_mode"],
            incremental_date_columns: current_report?.incremental_date_columns && current_report?.incremental_date_columns.split("__nfx__")
        }

        return temp_cache_settings;
    }



    const get_frequency_value = (key, _current_report) => {

        const fq = _current_report?.frequency || '';

        if (key === 'frequency_value') {
            if (_current_report?.frequency_value) return _current_report?.frequency_value
            return (fq && typeof fq === "string" && fq?.split("____nfx___")?.[0]) || ''
        }
        if (key === 'frequency_type') {
            if (_current_report?.frequency_type) return _current_report?.frequency_type
            return (fq && typeof fq === "string" && fq?.split("____nfx___")?.length > 1) ? fq?.split("____nfx___")?.[1] : ''
        }

    }

    const on_change_frequency = (key, value) => {

        const local_report_object = Object.assign({}, report_modified_properties);
        local_report_object[key] = value;

        var fq = local_report_object?.frequency_value + "____nfx___" + local_report_object?.frequency_type

        local_report_object['frequency'] = fq;

        set_report_modified_properties(local_report_object);

        set_current_report({ ...current_report, ...local_report_object });

        saveLocalReport(local_report_object);

    };



    return (
        <Wrapper
            open={rightPanelOpen}
            width={props.width}
            hideBoxShadow={props.hideBoxShadow}
            zIndex={props.zIndex || 999999}
            height={props.height}
        >

            {questionFocus && (
                <QuestionPopup
                    InputRef={InputRef}
                    setQuestionFocus={setQuestionFocus}
                    question={tempQuestion ? tempQuestion : question}
                    setQuestion={(val) => update_question(val)}
                    on_change_question={on_change_question}
                    hints={hints}
                    replace_hint_with_word={replace_hint_with_word}
                    activeHint={activeHint}
                    suggestionBoxPosition={suggestionBoxPosition}
                    setSuggestionBoxPosition={setSuggestionBoxPosition}
                    onKeyDown={questionKeyDown}
                    getAllInfoOfQuestion={props.getAllInfoOfQuestion}
                    __set_selected_date_filter_columns={(value) => {
                        update_report_item({ date_filter_columns: value })
                        set_selected_date_filter_columns(value)
                    }}
                    __global_filter_wtr={global_filter_wtr}
                    __set_global_filter_wtr={(value) => {
                        update_report_item({ global_filter_wtr: value })
                        set_global_filter_wtr(value)
                    }}
                    __selected_date_filter_columns={selected_date_filter_columns}
                    db_info_id={current_report?.reporting_db_info_id || current_report && current_report.db_info_id}
                    cache_settings={get_cache_setting()}

                    set_cache_settings={(_cache_settings) => {
                        const local_report_object = Object.assign({}, report_modified_properties);
                        local_report_object.is_require_cache = _cache_settings?.is_require_cache;
                        local_report_object.cache_table_name = _cache_settings?.cache_table_name;
                        local_report_object.frequency = _cache_settings?.frequency;
                        local_report_object.job_start_time = _cache_settings?.job_start_time;
                        local_report_object.refresh_mode = _cache_settings?.refresh_mode;
                        local_report_object.incremental_date_columns = _cache_settings?.incremental_date_columns && _cache_settings?.incremental_date_columns?.length > 0 && _cache_settings?.incremental_date_columns?.join("__nfx__")
                        local_report_object.modified = true;
                        set_report_modified_properties(local_report_object);
                        set_current_report({ ...current_report, ...local_report_object })
                        saveLocalReport(local_report_object);
                        set_cache_settings(_cache_settings)
                    }}
                    selected_id={selected_ids?.[0]}
                    // set_reporting_db_info_id={updated_reporting_db_info_id}
                    reporting_db_info_id={current_report?.reporting_db_info_id}
                />
            )}


            {rightPanelOpen && (
                <React.Fragment>
                    {!props.hideHeader && (
                        <div className="header_top" >

                            <div style={{
                                marginRight: '10px'
                            }}>
                                <RippalEffect
                                    width={30}
                                    Click={() => {
                                        setRightPanelOpen(undefined);
                                    }}
                                    title={"Close"}
                                    icon={<BackSvg style={{ transform: 'rotate(-180deg)' }} size="1rem" height="1rem" fill="#222" />}
                                >
                                </RippalEffect>
                            </div>
                            Settings
                        </div>
                    )}

                    <LeftSideSetting>

                        {current_report && (
                            <React.Fragment>
                                <div onKeyDown={(e) => {
                                    e.stopPropagation()
                                }
                                } id='right_panel_div' className="scroll_div">

                                    <div style={{ width: '100%', boxSizing: 'border-box' }}>
                                        <DivRow w="100%"
                                            style={{
                                                padding: '10px 1rem',
                                                borderBottom: '1px solid #ccc',
                                                background: setting_col?.['widget_setting'] ? '#337dff' : undefined,
                                                color: setting_col?.['widget_setting'] ? '#fff' : '#333333',
                                                boxSizing: 'border-box'
                                            }}>
                                            <div
                                                style={{ display: 'flex', cursor: 'pointer', alignItems: 'center', justifyContent: 'space-between', width: '100%', fontSize: '.9rem', fontWeight: 600, }}
                                                onClick={() => {
                                                    setting_toggle("widget_setting")
                                                }}
                                            >
                                                Widget Inputs
                                                <RippalEffect
                                                    width="30"
                                                    title={setting_col['widget_setting'] ? "Close" : "Open"}
                                                    icon={setting_col['widget_setting'] ? <AngleUp size="0.8rem" height="0.8rem" /> : <AngleDown style={{ color: 'red' }} size="0.8rem" height="0.8rem" />}
                                                    Click={() => { }}
                                                />

                                            </div>
                                        </DivRow>

                                        {setting_col['widget_setting'] && (
                                            <div style={{ width: '100%', padding: '10px 1rem', boxSizing: 'border-box' }}>
                                                {current_report?.chart_type === "report" && (
                                                    <DivRow w="100%" style={{ marginBottom: '1rem' }}>
                                                        <SelectOptionV1
                                                            label="Select Your Report"
                                                            options={((allReports || []).filter((r) => (r?.type === 'config_query_builder' && r?.name))) || []}
                                                            value={current_report && current_report.selected_report_id ? current_report.selected_report_id : ''}
                                                            value_key="id"
                                                            display_key='name'
                                                            none_value={false}
                                                            is_search={true}
                                                            on_change={(value, item) => {
                                                                onDrpodownTextChange(value, 'selected_report_id')
                                                            }}
                                                        />
                                                    </DivRow>
                                                )}

                                                {
                                                    render_mode === 'map' && chart_type === 'map' && (
                                                        <React.Fragment>
                                                            <DivRow w="100%" style={{ marginBottom: '1rem' }}>
                                                                <SelectOption
                                                                    label="Map Level"
                                                                    onChangeFunc={(value) => {
                                                                        onDrpodownTextChange(value, 'question')
                                                                    }}
                                                                    selections={map_level}
                                                                    displayKey='name'
                                                                    valueType="question"
                                                                    valueKey='value'
                                                                    activeValue={current_report?.question || ''}
                                                                    width="100%"
                                                                    padding="0px 0px"
                                                                    paddingRight='25px'
                                                                    lineHeight="2.857rem"
                                                                    menuLineHeight="1.8rem"
                                                                    hideCase='true'
                                                                />
                                                            </DivRow>

                                                            <DivRow w="100%" style={{ marginBottom: '1rem' }}>
                                                                <SelectOption
                                                                    label="Marker Type"
                                                                    onChangeFunc={(value) => {
                                                                        onDrpodownTextChange(value, 'marker_type')
                                                                    }}
                                                                    selections={['Cluster', 'Bubble']}
                                                                    activeValue={current_report?.marker_type || ''}
                                                                    width="100%"
                                                                    padding="0px 0px"
                                                                    paddingRight='25px'
                                                                    lineHeight="2.857rem"
                                                                    menuLineHeight="1.8rem"
                                                                    hideCase='true'
                                                                />
                                                            </DivRow>

                                                        </React.Fragment>
                                                    )
                                                }
                                                {show_label && (
                                                    <DivRow w="100%" style={{ marginBottom: '1rem' }}>
                                                        <TextBoxV1
                                                            label="Name Your Widget"
                                                            type="text"
                                                            value={(current_report && current_report.title) ? current_report.title : ''}
                                                            onChange={(e) => {
                                                                onTextChangeV1('title', e.target.value)
                                                            }}
                                                        />
                                                    </DivRow>
                                                )}



                                                {
                                                    true && (
                                                        <DivRow w="100%" style={{ marginBottom: '1rem' }}>
                                                            <SelectOptionV1
                                                                label="Choose Your Chart Type"
                                                                options={CHART_TYPES_WITH_ICONS}
                                                                value={current_report && current_report.chart_type ? current_report.chart_type : undefined}
                                                                value_key="dataKey"
                                                                display_key='name'
                                                                icon_key={"icon"}
                                                                none_value={false}
                                                                is_search={true}
                                                                disabled={(current_report && (current_report.chart_type === "map" || current_report.chart_type === "report"))}
                                                                on_change={(value, item) => {
                                                                    onChangeType(value)
                                                                }}
                                                                is_chart_selection={true}
                                                            />
                                                        </DivRow>
                                                    )
                                                }


                                                {current_report && ['group'].indexOf(current_report.chart_type) == -1 && (
                                                    <DivRow w="100%" style={{ marginBottom: '1rem' }}>
                                                        <SelectOptionV1
                                                            label="Choose OD Details Report"
                                                            options={selections_of_od_reports}
                                                            display_key='title'
                                                            value_key='id'
                                                            is_search={true}
                                                            on_change={(v) => {
                                                                onDrpodownTextChange(v, "od_report_id")
                                                            }}
                                                            value={current_report && current_report.od_report_id ? current_report.od_report_id : ''}
                                                            isSearch={true}
                                                            sort_key='desc'
                                                            group_key='type'
                                                        />

                                                    </DivRow>
                                                )}

                                                {/* {current_report && ['bar_chart', 'single_cell', 'table'].indexOf(current_report.chart_type) > -1 && (
                                                    <DivRow w="100%" style={{ marginBottom: '1rem' }}>
                                                        <SelectOptionV1
                                                            label="Select Drill Down Type"
                                                            options={options_for_drill_down}
                                                            display_key='title'
                                                            value_key='id'
                                                            is_search={true}
                                                            on_change={(v) => {
                                                                onDrpodownTextChange(v, "drill_down_type")
                                                            }}
                                                            value={current_report && current_report.drill_down_type ? current_report.drill_down_type : ''}
                                                            isSearch={true}
                                                            sort_key='desc'
                                                            group_key='type'
                                                        />
                                                    </DivRow>
                                                )} */}


                                                {current_report && ['label', 'group', 'report', 'map'].indexOf(current_report.chart_type) == -1 && (
                                                    <div style={{ width: '100%', marginBottom: '0rem' }}>
                                                        <DataModeTab
                                                            active_report_item_type={current_report && current_report?.report_item_type ? current_report.report_item_type : undefined}
                                                            change_type={(type) => {
                                                                clear_report_fields(type);
                                                            }}
                                                        />
                                                    </div>
                                                )}


                                                {show_question && current_report?.report_item_type === 'dashboard' && (
                                                    <DivRow w="100%" style={{ marginBottom: '1rem', position: 'relative' }}>
                                                        <TextBoxV1
                                                            label="Ask Your Data Question"
                                                            type="text"
                                                            id={"input_box_question"}
                                                            refName={_inputRef}
                                                            value={tempQuestion ? tempQuestion : question}
                                                            onChange={(e) => {
                                                                on_change_question(e)
                                                            }}
                                                            onFocus={() => {
                                                                setQuestionFocus(true)
                                                            }}
                                                            onBlur={(e) => {
                                                                setTimeout(() => {
                                                                    setHintShow(undefined)
                                                                }, 1000)

                                                            }}
                                                            fontSize='0.9rem'
                                                        />
                                                    </DivRow>
                                                )}

                                                {show_question && current_report?.report_item_type === 'dashboard_sql' && (
                                                    <DivRow w="100%" style={{ marginBottom: '1rem', position: 'relative' }}>
                                                        <TextBoxV1
                                                            label="Your SQL"
                                                            type="text"
                                                            id={"input_box_question_ff"}
                                                            refName={_inputRef}
                                                            value={current_report?.sql_query}
                                                            onFocus={() => {
                                                                open_report_editor('dashboard_sql');
                                                            }}
                                                            fontSize='0.9rem'
                                                        />
                                                    </DivRow>
                                                )}


                                                {show_question && current_report?.report_item_type === 'dashboard_config_query_builder' && (
                                                    <DivRow w="100%" style={{ marginBottom: '1rem', position: 'relative' }}>

                                                        {(current_report?.column_infos && current_report?.column_infos.length > 0 && current_report?.column_infos.filter((f => !f.deleted)) || []).length > 0 ? (
                                                            <React.Fragment>
                                                                <label style={{
                                                                    display: 'block',
                                                                    fontSize: '0.9rem',
                                                                    fontWeight: '600',
                                                                    marginBottom: '7px'
                                                                }}>Selected Columns</label>
                                                                <p
                                                                    className="link2"
                                                                    onClick={() => {
                                                                        open_report_editor('dashboard_config_query_builder');
                                                                    }}

                                                                >
                                                                    {(current_report?.column_infos && current_report?.column_infos.length > 0 && current_report?.column_infos.filter((f => !f.deleted)) || []).map(c => c.alias).join(',')}
                                                                </p>
                                                            </React.Fragment>

                                                        ) : (
                                                            <p onClick={() => {
                                                                open_report_editor('dashboard_config_query_builder');
                                                            }}
                                                                className="link">Add Columns</p>
                                                        )}
                                                    </DivRow>
                                                )}


                                                {current_report && ['label', 'group', 'report', 'map'].indexOf(current_report.chart_type) == -1 && current_report?.report_item_type === "dashboard" && current_report?.question && (
                                                    <NormalButton
                                                        icon={<FilterSvg size='.9rem' height='.9rem' color="#fff" />}
                                                        label={"Add Local Filter"}
                                                        bgColor='#337dff'
                                                        color="#fff"
                                                        height={'2rem'}
                                                        hoverBg={"#0084c7"}
                                                        style={{
                                                            border: '1px solid #ccc'
                                                        }}
                                                        onClick={() => {
                                                            if (choose_local_filter && questionInfo?.tables?.length > 0) {
                                                                choose_local_filter(selected_ids[0])
                                                            } else {
                                                                alert("Please wait...")
                                                            }
                                                        }}></NormalButton>
                                                )}
                                            </div>
                                        )
                                        }
                                        {setting_col["date_setting"] && (
                                            <DivRow style={{ marginBottom: '1rem' }}>
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    {[{ value: 'show_mtd', name: 'MTD' }, { value: 'show_ytd', name: 'YTD' }].map((item, index) => {
                                                        const value = (current_report && current_report[item.value]);
                                                        return (
                                                            <div key={"Appearance" + index} className="flex_row" onClick={() => {
                                                                changeAppearance(item.value, value ? false : true)
                                                            }}>
                                                                <CheckBox
                                                                    size={"30px"}
                                                                    mR="10px" onClick={() => {
                                                                        changeAppearance(item.value, value ? false : true)
                                                                    }}
                                                                    checked={(current_report && current_report[item.value]) ? true : false}
                                                                />
                                                                {item.name}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </DivRow>
                                        )}

                                        {setting_col['interactive'] && (
                                            <DivRow w="100%" style={{ marginBottom: '1rem', position: 'relative' }}>
                                                <div className="drill_down_section">
                                                    <div className="input_with_button">
                                                        <input type="text"
                                                            style={{ width: '90%' }}
                                                            className="form-control_search"
                                                            onChange={(e) => {

                                                                const clone_suggestion = selection_for_filter ? [...selection_for_filter] : [];
                                                                const value = e.target.value;
                                                                const filterd_suggestion_list = (clone_suggestion && clone_suggestion.length > 0) && clone_suggestion.filter((f) => {
                                                                    if (value === '') return true;
                                                                    if (value && f && f.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1) return true;
                                                                    else return false;
                                                                }) || [];

                                                                report_filter_info && report_filter_info.length > 0 && report_filter_info.map((f) => {
                                                                    if (filterd_suggestion_list.indexOf(f.filter_name) > -1) {
                                                                        const i = filterd_suggestion_list.indexOf(f.filter_name);
                                                                        filterd_suggestion_list.splice(i, 1);
                                                                    }
                                                                })
                                                                set_drill_down_list(filterd_suggestion_list)
                                                            }}
                                                            onFocus={() => {
                                                                set_focus_on_drill_down(true);
                                                            }}
                                                            onBlur={() => {
                                                                setTimeout(() => {
                                                                    set_focus_on_drill_down(undefined);
                                                                }, 400);
                                                            }}
                                                            placeholder="Search The Column"
                                                            ref={onClickInputRef}
                                                            onKeyDown={(e) => {
                                                                const clone_drill_down_values = drill_down_values ? [...drill_down_values] : [];
                                                                const value = e.target.value;
                                                                const key_code = e.keyCode;
                                                                if (key_code === 13) {
                                                                    if (clone_drill_down_values.indexOf(value) === -1) {
                                                                        clone_drill_down_values.push(value);
                                                                    }
                                                                    if (onClickInputRef && onClickInputRef.current) {
                                                                        onClickInputRef.current.value = ''
                                                                    }
                                                                }
                                                                set_drill_down_values(clone_drill_down_values)

                                                            }}
                                                        />
                                                        {/* <button className="form-control_btn">Add</button> */}
                                                    </div>


                                                    {focus_on_drill_down && drill_down_list && drill_down_list.length > 0 && (
                                                        <div class="filter_suggestion_list">
                                                            {drill_down_list.map((f, j) => {
                                                                return (
                                                                    <div
                                                                        onClick={() => {
                                                                            const clone_drill_down_values = drill_down_values ? [...drill_down_values] : [];
                                                                            const is_exist = clone_drill_down_values?.length > 0 && clone_drill_down_values.filter((v) => {
                                                                                if (v && v?.toLocaleLowerCase() === f && f?.toLocaleLowerCase()) {
                                                                                    return true;
                                                                                } else return false;
                                                                            }) || [];
                                                                            if (is_exist && is_exist.length > 0) {
                                                                                set_focus_on_drill_down(undefined);
                                                                            }
                                                                            else {
                                                                                clone_drill_down_values.push(f)
                                                                            }
                                                                            set_drill_down_values(clone_drill_down_values)
                                                                            set_focus_on_drill_down(undefined);
                                                                            if (onClickInputRef && onClickInputRef.current) {
                                                                                onClickInputRef.current.value = ''
                                                                            }
                                                                        }}
                                                                        key={"f" + j}>{f}</div>
                                                                )
                                                            })}
                                                        </div>
                                                    )}


                                                    {drill_down_values && drill_down_values.length > 0 && (
                                                        <ul>
                                                            {drill_down_values.map((d, i) => {
                                                                return (
                                                                    <li key={"d" + i}>{d}
                                                                        <span
                                                                            title="Remove"
                                                                            onClick={() => {
                                                                                const clone_drill_down_values = drill_down_values ? [...drill_down_values] : [];
                                                                                clone_drill_down_values.splice(i, 1);
                                                                                set_drill_down_values(clone_drill_down_values)
                                                                            }}
                                                                            className="delete">
                                                                            <CloseSvg size="10px" height="10px" color="red" />
                                                                        </span>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    )}
                                                </div>



                                            </DivRow>
                                        )}

                                        {current_report && ['label', 'group', 'report', 'map'].indexOf(current_report.chart_type) == -1 && (
                                            <DivRow w="100%"
                                                style={{
                                                    padding: '10px 1rem',
                                                    borderBottom: '1px solid #ccc',
                                                    background: setting_col?.['agg_settings'] ? '#337dff' : undefined,
                                                    color: setting_col?.['agg_settings'] ? '#fff' : '#333333',
                                                    boxSizing: 'border-box'
                                                }}
                                            >
                                                <div
                                                    style={{ display: 'flex', cursor: 'pointer', alignItems: 'center', justifyContent: 'space-between', width: '100%', fontSize: '.9rem', fontWeight: 600, }}
                                                    onClick={() => {
                                                        setting_toggle("agg_settings")
                                                    }}
                                                >
                                                    Aggregate Settings
                                                    <RippalEffect
                                                        width="30"
                                                        title={setting_col["agg_settings"] ? "Close" : "Open"}
                                                        icon={setting_col["agg_settings"] ? <AngleUp size="0.8rem" height="0.8rem" /> : <AngleDown style={{ color: 'red' }} size="0.8rem" height="0.8rem" />}
                                                        Click={() => { }}
                                                    />
                                                </div>
                                            </DivRow>

                                        )}

                                        {setting_col["agg_settings"] && (
                                            <DivRow style={{ marginBottom: '0rem', padding: '10px 1rem' }}>
                                                <div>
                                                    {[{ value: 'show_min', name: 'MIN' }, { value: 'show_max', name: 'MAX' }, { value: 'show_avg', name: 'AVG' }, { value: 'show_st_dev', name: 'STDEV' }, { value: 'show_trend_line', name: 'TREND LINE' }].map((item, index) => {
                                                        const value = (current_report && current_report[item.value]);
                                                        return (
                                                            <div key={"Appearance" + index} className="flex_row" style={{
                                                                marginBottom: '10px',
                                                                color: '#333333',
                                                                cursor: 'pointer',
                                                                width: '100%'
                                                            }} onClick={() => {
                                                                changeAppearance(item.value, value ? false : true)
                                                            }}>
                                                                <RadioCheckMark
                                                                    outerSize={"25px"}
                                                                    innerSize={"15px"}
                                                                    dColor='#337dff'
                                                                    mR="10px" onClick={() => {
                                                                        changeAppearance(item.value, value ? false : true)
                                                                    }}
                                                                    checked={(current_report && current_report[item.value]) ? true : false}
                                                                />
                                                                <span className='____label'>{item.name}</span>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </DivRow>
                                        )}

                                        {current_report && ['label', 'group', 'report', 'map'].indexOf(current_report.chart_type) == -1 && (
                                            <DivRow w="100%" style={{
                                                padding: '10px 1rem',
                                                borderBottom: '1px solid #ccc',
                                                background: setting_col?.['advance_setting'] ? '#337dff' : undefined,
                                                color: setting_col?.['advance_setting'] ? '#fff' : '#333333',
                                                boxSizing: 'border-box'
                                            }}>
                                                <div
                                                    style={{ display: 'flex', cursor: 'pointer', alignItems: 'center', justifyContent: 'space-between', width: '100%', fontSize: '.9rem', fontWeight: 600 }}
                                                    onClick={() => {
                                                        setting_toggle("advance_setting")
                                                    }}
                                                >
                                                    Advanced Setting
                                                    <RippalEffect
                                                        width="30"
                                                        title={setting_col["advance_setting"] ? "Close" : "Open"}
                                                        icon={setting_col["advance_setting"] ? <AngleUp size="0.8rem" height="0.8rem" /> : <AngleDown style={{ color: 'red' }} size="0.8rem" height="0.8rem" />}
                                                        Click={() => { }}
                                                    />
                                                </div>
                                            </DivRow>

                                        )}



                                         {setting_col["advance_setting"] && current_report && ['label', 'group', 'report', 'map'].indexOf(current_report.chart_type) == -1 && (
                                            <div>
                                                <DivRow w="100%" style={{ padding: '5px 0px' }}>
                                                    <CheckBox
                                                        checked={current_report?.['no_filter_applicable']}
                                                        label="Global Date Filter Not Applicable" onClick={(e) => {
                                                            changeAppearance('no_filter_applicable', (current_report?.['no_filter_applicable'] ? false : true))
                                                        }} />

                                                    <CheckBox
                                                        checked={current_report?.['dimension_filter_not_applicable']}
                                                        label="Dimension Filter Not Applicable" onClick={(e) => {
                                                            changeAppearance('dimension_filter_not_applicable', (current_report?.['dimension_filter_not_applicable'] ? false : true))
                                                        }} />
                                                </DivRow>
                                                <DivRow w="100%" >
                                                    <div style={{ padding: '5px 12px' }}>
                                                        <span className='lable' >KPI Refresh Frequency</span>

                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row', marginTop: '10px', width: '100%', padding: '0px 12px' }}>
                                                        <div style={{ width: '30%', marginRight: '10px' }}>
                                                            <input
                                                                className='__input__'
                                                                value={get_frequency_value('frequency_value', current_report)}
                                                                // style={{ width: '100%', fontSize: '1rem', padding: '.8rem', boxSizing: 'border-box', color: '#000' }}
                                                                type="text" placeholder="Value"
                                                                onChange={(e) => {
                                                                    on_change_frequency('frequency_value', e.target.value)

                                                                }}
                                                            />
                                                        </div>

                                                        <div style={{ width: '50%', }}>
                                                            <select
                                                                className='__input__'

                                                                value={get_frequency_value('frequency_type', current_report)}
                                                                style={{ width: '100%', borderRadius: '2px', backgroundColor: '#fff' }}
                                                                onChange={(e) => {
                                                                    on_change_frequency('frequency_type', e.target.value)
                                                                }}>
                                                                <option value="">Select Unit</option>
                                                                {FREQUENCY_TYPE?.map((item) => {
                                                                    return (
                                                                        <option value={item.value}>{item.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </DivRow>
                                            </div>
                                        )}


                                        <DivRow w="100%"
                                            style={{
                                                padding: '10px 1rem',
                                                borderBottom: '1px solid #ccc',
                                                background: setting_col?.['additional_setting'] ? '#337dff' : undefined,
                                                color: setting_col?.['additional_setting'] ? '#fff' : '#333333',
                                                boxSizing: 'border-box'
                                            }}
                                        >
                                            <div
                                                style={{ display: 'flex', cursor: 'pointer', alignItems: 'center', justifyContent: 'space-between', width: '100%', fontSize: '.9rem', fontWeight: 600, }}
                                                onClick={() => {
                                                    setting_toggle("additional_setting")
                                                }}
                                            >
                                                Advanced Display Settings
                                                <RippalEffect
                                                    width="30"
                                                    title={setting_col["additional_setting"] ? "Close" : "Open"}
                                                    icon={setting_col["additional_setting"] ? <AngleUp size="0.8rem" height="0.8rem" /> : <AngleDown style={{ color: 'red' }} size="0.8rem" height="0.8rem" />}
                                                    Click={() => { }}
                                                />

                                            </div>
                                        </DivRow>
                                        {setting_col["additional_setting"] && (
                                            <React.Fragment>
                                                {chart_apperance_to_use && chart_apperance_to_use.length > 0 && (
                                                    <DivRow style={{ marginBottom: '1rem' }}>
                                                        <div className="setting_header">
                                                            {render_title_by_chart_type(current_report.chart_type)} Settings
                                                        </div>
                                                        <div style={{ display: 'flex', flexWrap: 'wrap', padding: '10px 1rem' }}>
                                                            {chart_apperance_to_use.map((item, index) => {
                                                                const is_select_option = item && item.options && item.options.length > 0;
                                                                const value = (current_report && current_report[item.value]);

                                                                return (
                                                                    <div key={"Appearance" + index} style={{
                                                                        marginBottom: '10px',
                                                                        color: '#333333',
                                                                        cursor: 'pointer',
                                                                    }} className="flex_row" onClick={() => {
                                                                        changeAppearance(item.value, value ? false : true);
                                                                    }}>
                                                                        <RadioCheckMark
                                                                            outerSize={"25px"}
                                                                            innerSize={"15px"}
                                                                            dColor='#337dff'
                                                                            mR="10px" onClick={() => {
                                                                                changeAppearance(item.value, value ? false : true)
                                                                            }}
                                                                            checked={(current_report && current_report[item.value]) ? true : false}
                                                                        />
                                                                        <span className='____label'>{item.name}</span>
                                                                    </div>
                                                                )
                                                                // }
                                                            })}
                                                        </div>
                                                    </DivRow>
                                                )}


                                                {chart_axis_setting_to_use && chart_axis_setting_to_use.length > 0 && (
                                                    <DivRow>
                                                        <div className="setting_header">Chart Axis Settings</div>
                                                        <div style={{ display: 'flex', flexWrap: 'wrap', padding: '10px 1rem' }}>
                                                            {chart_axis_setting_to_use.map((item, index) => {
                                                                const value = (current_report && current_report[item.value]);
                                                                return (
                                                                    <div key={"Appearance" + index} style={{
                                                                        marginBottom: '10px',
                                                                        color: '#333333',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                        className="flex_row" onClick={() => {
                                                                            changeAppearance(item.value, value ? false : true)
                                                                        }}>
                                                                        <RadioCheckMark
                                                                            outerSize={"25px"}
                                                                            innerSize={"15px"}
                                                                            dColor='#337dff'
                                                                            mR="10px" onClick={() => {
                                                                                changeAppearance(item.value, value ? false : true)
                                                                            }}
                                                                            checked={(current_report && current_report[item.value]) ? true : false}
                                                                        />
                                                                        <span className='____label'>{item.name}</span>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </DivRow>
                                                )}


                                                <DivRow w="100%" style={{ marginBottom: '1rem' }}>
                                                    <div className="setting_header">Other Settings</div>
                                                    <ThemeSetting
                                                        render_mode={render_mode === 'report' ? render_mode_of_report : render_mode}
                                                        chart_type={render_mode === 'report' ? chart_type_of_report : chart_type}
                                                        themeValues={current_report.theme_json_values}
                                                        report_item_id={current_report.id}
                                                        update_theme_value={update_theme_value}
                                                    />

                                                </DivRow>
                                            </React.Fragment>
                                        )}


                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </LeftSideSetting>
                </React.Fragment>
            )}

        </Wrapper>
    )
}


const mapStateToProps = (state) => ({
    db_infos: state.connectionReducer.db_infos,
    questionInfo: state.insightReducer.questionInfo,
    hints: state.reportReducer.hints,
    insightsMenu: state.insightReducer.insightsMenu,
    userPreference: state.accountReducer.userPreference,
    allReports: state.reportReducer.reports && state.reportReducer.reports.all_reports,
    all_reports: state.reportHelperReducer.all_reports,
    filterOptions: state?.filterReducer?.filterOptions,
    questionDetailsCache: state.insightReducer.questionDetailsCache,
    containerValues: state.reportReducer.containerValues,
})





export default connect(mapStateToProps, {
    get_all_reports,
    getAllReports,
    get_user_preferences,
    getHintsByDbInfo,
    getInsightsListByUser,
    getAllInfoOfQuestion,
    clearQuestionInfo,
    getStringHintsFromServer
})(RightSidePanel)