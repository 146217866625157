const aes = require('crypto-js/aes');
const cryptoJS = require('crypto-js');
const __salt__ = '108udhas7h367uehdyh1y36389jsjj^&^%$asdake049SSDJDLasdd2k129k4';


export const encryptText = (textToEncrypt, key='') => {
    if (key === '') key = __salt__;
    if (!textToEncrypt || textToEncrypt === '') return '';

    return aes.encrypt(textToEncrypt, key);
};



export const decryptText = (textToDecrypt, key='') => {
    if (key === '') key = __salt__;
    if (!textToDecrypt || textToDecrypt === '') return '';
    
    return aes.decrypt(textToDecrypt, key).toString(cryptoJS.enc.Utf8);
};