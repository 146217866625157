import React, { useEffect, useRef, useState } from "react";
import { Treemap, ResponsiveContainer, Tooltip, Legend, ComposedChart } from "recharts";
import { formatValueByDataType } from "../../../utils";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomToolTip from '../tooltip/tooltip.custom';
// import RenderLegend from "../legend/index.new";
// import { CHART_TYPES } from "../../../shared-with-fe/constants";
// import { colors } from "../../../utils/colors";

const SimpleBubbleChart = (props) => {
    const {
        yDataKeys,
        xDataKeys,
        Alignments,
        theme_json_values,
        heightOfChart,
        widthOfChart,
        colorForYData,
        chartType,
        hide_tooltip,
        tooltipTheme,
        show_title,
        title,
    } = props;

    const overlapPadding = 10; // Increased padding to avoid overlap
    const maxIterations = 2000; // More iterations for better resolution
    const minRadius = 10;
    const noOfBubble = props.data?.length || 0;
    let maxRadius = Math.min(widthOfChart, heightOfChart) / 5.5;
    maxRadius = noOfBubble > 100 ? maxRadius * 0.5 : noOfBubble > 45 ? maxRadius * 0.65 : noOfBubble > 20 ? maxRadius * 0.8 : maxRadius;
    maxRadius = yDataKeys.length>1 ? maxRadius /1.9 : maxRadius;
    const containerRef = useRef(null);

    const [chartData, setChartData] = useState(undefined);
    const [tooltip, setTooltip] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const prev_data = chartData && JSON.stringify(chartData);
        const upcomping_data = props.data && JSON.stringify(props.data);
        if (prev_data !== upcomping_data) {
            setChartData(calculateBubbles(props.data, yDataKeys, widthOfChart - 10, heightOfChart - 70));
        }
    }, [props.data, xDataKeys, widthOfChart, heightOfChart]);


    const calculateDistance = (bubble1, bubble2) => {
        const dx = bubble1.cx - bubble2.cx;
        const dy = bubble1.cy - bubble2.cy;
        return Math.sqrt(dx * dx + dy * dy);
    };

    const resolveOverlap = (bubbles, newBubble) => {
        for (let iteration = 0; iteration < maxIterations; iteration++) {
            let overlapDetected = false;
            for (const bubble of bubbles) {
                const distance = calculateDistance(bubble, newBubble);
                const minDistance = bubble.radius + newBubble.radius + overlapPadding;

                if (distance < minDistance) {
                    overlapDetected = true;
                    const angle = Math.atan2(
                        newBubble.cy - bubble.cy,
                        newBubble.cx - bubble.cx
                    );
                    const overlap = minDistance - distance;

                    newBubble.cx += Math.cos(angle) * overlap;
                    newBubble.cy += Math.sin(angle) * overlap;

                    // Ensure the bubble stays within bounds
                    newBubble.cx = Math.max(
                        newBubble.radius,
                        Math.min(newBubble.cx, widthOfChart - newBubble.radius)
                    );
                    newBubble.cy = Math.max(
                        newBubble.radius,
                        Math.min(newBubble.cy, heightOfChart - newBubble.radius)
                    );
                }
            }
            if (!overlapDetected) break; // Stop if no overlap
        }
    };
    // Final repositioning pass to ensure no overlaps remain
    const repositionBubbles = (bubbles, width, height) => {
        for (let iteration = 0; iteration < maxIterations; iteration++) {
            let overlapDetected = false;

            for (let i = 0; i < bubbles.length; i++) {
                for (let j = i + 1; j < bubbles.length; j++) {
                    const bubbleA = bubbles[i];
                    const bubbleB = bubbles[j];

                    const distance = calculateDistance(bubbleA, bubbleB);
                    const minDistance = bubbleA.radius + bubbleB.radius + overlapPadding;

                    if (distance < minDistance) {
                        overlapDetected = true;

                        const angle = Math.atan2(
                            bubbleB.cy - bubbleA.cy,
                            bubbleB.cx - bubbleA.cx
                        );
                        const overlap = minDistance - distance;
                        // Push bubbles away from each other based on overlap
                        const pushAmount = overlap / 2;

                        bubbleA.cx -= Math.cos(angle) * pushAmount;
                        bubbleA.cy -= Math.sin(angle) * pushAmount;

                        bubbleB.cx += Math.cos(angle) * pushAmount;
                        bubbleB.cy += Math.sin(angle) * pushAmount;

                        // Ensure bubbles stay within chart bounds
                        bubbleA.cx = Math.max(
                            bubbleA.radius,
                            Math.min(bubbleA.cx, width - bubbleA.radius)
                        );
                        bubbleA.cy = Math.max(
                            bubbleA.radius,
                            Math.min(bubbleA.cy, height - bubbleA.radius)
                        );

                        bubbleB.cx = Math.max(
                            bubbleB.radius,
                            Math.min(bubbleB.cx, width - bubbleB.radius)
                        );
                        bubbleB.cy = Math.max(
                            bubbleB.radius,
                            Math.min(bubbleB.cy, height - bubbleB.radius)
                        );
                    }
                }
            }

            if (!overlapDetected) {
                // If no overlaps are detected, exit the loop early
                break;
            }
        }
    };
    // Calculate bubbles for all yDataKeys
    const calculateBubbles = (chartData, yKeys, width, height) => {
        const bubbles = [];
        // Function to scale radius
        const scaleToRadius = (value, yKey) => {
            if (!chartData || chartData.length === 0) return 0;
            const minValue = Math.min(...chartData.map(d => d[yKey]));
            const maxValue = Math.max(...chartData.map(d => d[yKey]));
            if (minValue <= 0 || maxValue <= 0) return minRadius;
            const linearScale = (value - minValue) / (maxValue - minValue);
            value = Math.max(minValue, Math.min(value, maxValue));
            const logScale = (Math.log(value) - Math.log(minValue)) / (Math.log(maxValue) - Math.log(minValue));
            const blendFactor = 0.5;
            // const blendFactor = noOfBubble > 20 ? 0.9 : 0.5;
            return minRadius + (blendFactor * logScale + (1 - blendFactor) * linearScale) * (maxRadius - minRadius);
        };

        yKeys.forEach((yKey, keyIndex) => {
            const largestValue = Math.max(...chartData.map(d => d[yKey]));
            const largestRadius = scaleToRadius(largestValue, yKey);
            const largestVData = chartData?.find(el => el?.[yKey] === largestValue)
            let centerBubble = {
                cx: width / 2,
                cy: height / 2,
                radius: largestRadius,
                yKey: yKey,
                value: largestValue,
                ...largestVData,
            };
            bubbles.push(centerBubble);

            // Place other bubbles around the center
            chartData?.filter(el => el[yKey] !== largestValue).forEach((data, i) => {
                const radius = scaleToRadius(data[yKey], yKey);
                let angle = (i / chartData.length) * Math.PI * 2;
                let bubble = {
                    radius,
                    cx: centerBubble.cx + (largestRadius + radius + overlapPadding) * Math.cos(angle),
                    cy: centerBubble.cy + (largestRadius + radius + overlapPadding) * Math.sin(angle),
                    ...data,
                    yKey: yKey,
                };
                resolveOverlap(bubbles, bubble);
                bubbles.push(bubble);
            });
        });

        // Final repositioning pass
        repositionBubbles(bubbles, width, height);

        return bubbles;
    };

    const scaleFontSize = (radius) => {
        const minFontSize = 8; // Minimum font size
        const maxFontSize = 30; // Maximum font size      
        // Ensure radius is clamped between min and max radius
        const clampedRadius = Math.max(minRadius, Math.min(radius, maxRadius));
        // Linear scaling of font size based on radius
        return (
            minFontSize +
            ((clampedRadius - minRadius) / (maxRadius - minRadius)) *
            (maxFontSize - minFontSize)
        );
    };

    const truncateText = (text, fontSize, radius) => {
        const maxChars = Math.floor((radius * 2) / (fontSize * 0.6)); // Estimate max characters based on radius and font size
        return text.length > maxChars ? `${text.slice(0, maxChars)}...` : text;
    };

    const handleMouseMove = (event) => {
        // const tooltipWidth = 170; // Estimated width of your tooltip
        // const tooltipHeight = 170;  // Estimated height of your tooltip
        // // Logic to adjust tooltip position based on container
        const container = containerRef?.current;
        if (!container) return; // Check if container exists
        const containerRect = containerRef.current.getBoundingClientRect();
        let offsetX = event.clientX - containerRect.left;
        let offsetY = event.clientY - containerRect.top;

        // Boundary detection: adjust tooltip if near edges
        const tooltipWidth = 150;  // Estimate width based on your tooltip's design
        const tooltipHeight = 180;  // Estimate height

        if (offsetX + tooltipWidth > containerRect.width) {
            offsetX = containerRect.width - tooltipWidth;
        }
        if (offsetY + tooltipHeight > containerRect.height) {
            offsetY = containerRect.height - tooltipHeight;
        }
        if (offsetX < 0) offsetX = 0;
        if (offsetY < 0) offsetY = 0;

        setTooltip({ show: true, x: offsetX, y: offsetY });
    };

    const handleMouseEnter = (event) => {
        setTooltip({
            show: true,
            // content: `${bubble[xDataKeys[0]]}: ${bubble[bubble.yKey]}`,
        });
    };

    const handleMouseLeave = () => {
        setTooltip({
            ...tooltip, show: false, x: undefined,
            y: undefined,
        });
    };

    const CustomizedContent = (_props) => {
        const { index } = _props;
        const bubble = chartData[index];
        if (!bubble) return null;

        let d_type = Alignments?.[bubble.yKey]?.["type"];
        let currency_type = Alignments?.[bubble.yKey]?.["currency_type"];
        let num_format_type = Alignments?.[bubble.yKey]?.["num_format_type"];
        let use_decimal = Alignments?.[bubble.yKey]?.["use_decimal"] || 2;
        let use_percent = Alignments?.[bubble.yKey]?.["use_percent"];
        // if (!bubble || !bubble?.[bubble?.yKey]) return null;
        const formatted_value = formatValueByDataType(
            bubble[bubble?.yKey] || bubble?.value,
            d_type,
            undefined,
            undefined,
            undefined,
            undefined,
            currency_type,
            num_format_type,
            use_decimal,
            use_percent
        );
        const fontSize = scaleFontSize(bubble.radius);
        const yaxisIndex = yDataKeys.findIndex(el => el === bubble.yKey);
        return (
            <g >
                <circle
                    onMouseEnter={handleMouseEnter}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                    cx={bubble.cx}
                    cy={bubble.cy}
                    r={bubble.radius}
                    style={{
                        // fill: bubble.yKey === yDataKeys[0] ? fillColor[0] : fillColor[1],
                        fill: colorForYData[yaxisIndex],
                        // fill: colorForYData[index % colorForYData.length] || "#ccc",
                        stroke: "#fff",
                        strokeWidth: 2,
                        cursor: "pointer",
                        fillOpacity: 0.8,
                    }}
                />
                {bubble.radius > 50 && (
                    <g>
                        <text
                            x={bubble.cx}
                            y={bubble.cy - 12}
                            textAnchor="middle"
                            dominantBaseline="middle"
                            fill="#fff"
                            // fill="#000"
                            fontSize={fontSize}
                            onMouseEnter={handleMouseEnter}
                            onMouseMove={handleMouseMove}
                            onMouseLeave={handleMouseLeave}
                        >
                            {truncateText(formatted_value, fontSize, bubble.radius)}
                            {/* {bubble[bubble?.yKey] || bubble.value} Display the value associated with the bubble */}
                        </text>
                        <text
                            x={bubble.cx}
                            y={bubble.cy + 12}
                            textAnchor="middle"
                            dominantBaseline="middle"
                            fill="#fff"
                            fontSize={fontSize * 0.6}
                            onMouseEnter={handleMouseEnter}
                            onMouseMove={handleMouseMove}
                            onMouseLeave={handleMouseLeave}
                        >
                            {truncateText(bubble[xDataKeys[0]], fontSize, bubble.radius)}
                            {/* {bubble[xDataKeys[0]]} Display the value associated with the bubble */}
                        </text>
                    </g>
                )}
            </g>
        );
    };
    const renderLegend = () => (
        <div style={{ display: 'flex', justifyContent: 'center', margin: '0.6rem 0 0.8rem 0' }}>
            {yDataKeys.map((key, index) => (
                <div
                    key={key}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: 10,
                    }}
                >
                    <span
                        style={{
                            width: 12,
                            height: 12,
                            backgroundColor: colorForYData[index % colorForYData.length],
                            display: 'inline-block',
                            marginRight: 4,
                            borderRadius: '30%',
                        }}
                    ></span>
                    <span>{key}</span>
                </div>
            ))}
        </div>
    );

    return (
        <div ref={containerRef} style={{ height: heightOfChart, width: widthOfChart }}>
            {chartData && chartData.length > 0 && (
                <>
                    {renderLegend()}
                    <ResponsiveContainer width={widthOfChart - 10} height={heightOfChart - 40}>
                        <Treemap
                            dataKey={yDataKeys[0]}
                            data={chartData}
                            content={<CustomizedContent colorForYData={colorForYData} />}
                        >
                            {
                                !hide_tooltip && (
                                    <Tooltip
                                        // itemStyle={{ position: 'absolute' }}
                                        // wrapperStyle={{ position: 'absolute' }}
                                        position={{ ...tooltip }}
                                        content={(active) => {
                                            const _data = {};
                                            const payload = []
                                            active?.payload?.forEach((el , ind) => {
                                                const pdat = {};
                                                pdat['dataKey'] = xDataKeys[ind]
                                                pdat['stroke'] = colorForYData[ind]
                                                payload.push(pdat)
                                            });
                                            const _payload = active?.payload?.[0]?.payload;
                                            const array_of_keys = [...xDataKeys, ...yDataKeys];

                                            _payload && xDataKeys && xDataKeys.forEach((x, index) => {
                                                // const concatinatedAxisValue = _payload[x]?.split('__join__');
                                                const concatinatedAxisValue = _payload[x]?.split('__nfx__join__');
                                                const noofValCon = concatinatedAxisValue.length;
                                                if (noofValCon > 1) {
                                                    _data[x] = concatinatedAxisValue[noofValCon - 1];
                                                    _payload[x] = concatinatedAxisValue[noofValCon - 1];
                                                } else {
                                                    _data[x] = _payload[x];
                                                }
                                            })

                                            array_of_keys?.forEach(key => {

                                                if (_payload && _payload[key] !== undefined) {
                                                    const columnFormattingMeta = Alignments?.[key];
                                                    const currencyType = columnFormattingMeta?.currency_type;
                                                    const numFormatType = columnFormattingMeta?.num_format_type;
                                                    const useDecimal = columnFormattingMeta?.use_decimal;
                                                    const dataType = columnFormattingMeta?.type;
                                                    const use_percent = columnFormattingMeta?.use_percent

                                                    _data[key] = formatValueByDataType(_payload[key], dataType, undefined, undefined, undefined, undefined, currencyType, numFormatType, useDecimal, use_percent);
                                                }
                                            });

                                            return <CustomToolTip
                                                style={{
                                                    position: 'absolute',
                                                    left: tooltip.x,
                                                    top: tooltip.y,
                                                    background: 'rgba(0, 0, 0, 0.75)',
                                                    color: '#fff',
                                                    padding: '8px',
                                                    borderRadius: '5px',
                                                    maxWidth: '200px',
                                                    pointerEvents: 'none',
                                                }}
                                                data={_data} theme={tooltipTheme} xDataKeys={xDataKeys}
                                                // payload={payload} 
                                            />;
                                        }}
                                    />
                                )}
                        </Treemap>
                    </ResponsiveContainer>
                </>
            )
            }
        </div >
    );
};


const mapStateToProps = (state, props) => {
    const report_cell_clicked_info_cache =
        state.reportHelperReducer.report_cell_clicked_info_cache?.[
        props.insight_id
        ];
    const report_cell_click_info = report_cell_clicked_info_cache?.[props.id];
    const link_filter =
        state.filterReducer.link_filter_cache?.[props.insight_id]?.[props.id];
    const table_format_setting_cache =
        state.tableFormatReducer.table_format_setting_cache;
    const tbl_formatter =
        table_format_setting_cache && table_format_setting_cache[props.id];

    return { report_cell_click_info, link_filter, tbl_formatter };
};

export default withRouter(connect(mapStateToProps, {})(SimpleBubbleChart));
