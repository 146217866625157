import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const Cursor = styled.span`
  font-weight: bold;
  font-size: 1em;
  margin-left: 2px;
  animation: ${blink} 0.1s infinite;
`;


const TypingEffect = ({ text, onComplete }) => {
  const [isTyping, setIsTyping] = useState(false);
  const [displayedText, setDisplayedText] = useState('');
  const [currentChar, setCurrentChar] = useState(0);


  useEffect(() => {
    if (currentChar < text?.length) {
      setIsTyping(true)
      const timeout = setTimeout(() => {
        setDisplayedText(prev => prev + text[currentChar]);
        setCurrentChar(prev => prev + 1);
      }, 1); // Adjust typing speed here
      return () => {
        setIsTyping(false)
        clearTimeout(timeout)
      };
    } else {
      if (onComplete) {
        setTimeout(onComplete, 10); // Adjust delay before next line here
      }
    }
  }, [currentChar, text, onComplete]);

  return <span>
    {displayedText}
    {isTyping && <Cursor>|</Cursor>}
  </span>
};

export default TypingEffect;
