import React, { forwardRef, useEffect, useRef } from "react";
import styled from "styled-components";
import SearchIcon from '../svg/search';
import SendIcon from "../svg/icons-v2/sendMessageIcon";
import MicrophoneIcon from "../svg/icons-v2/microphoneIcon";
import { NormalButton } from "./normal.button";
import Add from "../svg/add";
import Popover from "./popover";
import GoogleDriveIcon from "../svg/icons-v2/googleDriveIcon";
import Datasource from "../svg/menu/datasource";
import UploadFromComputerIcon from "../svg/icons-v2/uploadFromComputerIcon";


const SearchContainer = styled.div`
  position: relative;
  border-radius: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  margin-left: 0.8rem;
  padding: ${props => props.padding || '0.2rem 1rem 0.2rem 1rem'} ;
  /* padding: ${props => props.padding || '0.5rem 0.5rem 0.5rem 1rem'} ; */
  background: #fff;
  width: 100%;
  max-width: ${props => props.width || '30rem'};
  box-shadow: 2px 2px 5px #c5cddd;
  box-sizing: border-box;


  &:focus-within {
    border-color: #337DFF; 
  };
  
  .microphone_btn{
    cursor: pointer;
    svg{
    margin: 0 0.8rem;
  };

};
  .Add_attachment_btn{
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.8rem 0 0;
    cursor: pointer;
    &:hover{
      background: #f2f2f2;
      border-radius: 50%;
    }
  }

  .send_btn_bg{
    background: ${props => props.isSearchText ? 'linear-gradient(to right, #3E9EFF, #737DFF)' : '#eaeaea'};
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.6rem;
    cursor: ${props => props.isSearchText ? 'pointer' : 'not-allowed'};

    svg{
      margin-left: 2px;
    }
  };
  .pre_btn {
    display: flex;
    align-items: end;
    height: 100%;
    margin-bottom: 1.6rem;
  }

  .post_btn{
    display: flex;
    align-items: end;
    height: 100%;
    margin-bottom: 1.6rem;

  };
  .btn_group{
    display: flex;
    position: absolute;
    right: 16px;
    bottom: 10px;
  }
`;


/* const SearchInput = styled.input` */
const SearchInput = styled.textarea`
  border: none;
  outline: none;
  flex: 1;
  font-size: 1.1rem;
  box-sizing: border-box;
  font-weight: 400;
  color: #3F3F3F;
  resize: none;
  overflow: auto;
  background: #fff;
  /* min-height: 2rem; */
  height: 2rem;
  max-height : 100px;
  box-sizing: border-box;
  /* padding: 0.9rem 0.8rem 0rem 0.8rem; */
  line-height: 1.6rem;
  /* line-height: 1.3rem; */
  &::placeholder {
    color: #5f5f5f;
    font-size: 1.2rem;
    font-weight: 500;
    /* padding-top: 0.8rem; */
  }

  &::-webkit-scrollbar {
        background-color: #3F3F3F;
        width:4px;
    }

    &::-webkit-scrollbar-track {
        background-color: #F3F3F3;
        border: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dbdbdb;
        border-radius: 10px;
        height: 4px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color:  #3F3F3F;
        height: 4px;
    }
`;

let textCountPerLine = undefined;

const SearchTextAreaBar = ({
  onChange = () => { },
  onSearch = () => { },
  onClick = () => { },
  onFocus = () => { },
  onSave = () => { },
  onCancelEdit = () => { },
  onBlur = () => { },
  handleAddAttachmentClick = () => { },
  showAddAttachment,
  handleMicClick,
  onKeyDown,
  onKeyUp,
  value,
  type,
  placeHolder,
  label,
  borderType = 'border-bottom',
  width,
  padding,
  showSearchIcon = true,
  showSendBtn,
  showSpeakBtn,
  rows,
  showBtnGroup,
  InputRef,
  wrapperRef
}) => {

  useEffect(() => {
    const textarea = InputRef?.current;
    const isValue = textarea?.value;
    // const strArr = textarea?.value?.split(/\r\n|\r|\n/);
    // const lines = strArr?.length;

    if(textarea){
      textarea.style.height = 'auto';
      if ( isValue && isValue?.length <= textCountPerLine ) {
        textarea.style.height = `${31}px`;
      }else if( isValue && textarea.scrollHeight <= 110 ) {
        textarea.style.height = `${textarea.scrollHeight}px`;
      }else if(isValue && textarea.scrollHeight > 110) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
          textarea.scrollTop = textarea.scrollHeight;
      }else if(!isValue){
        textarea.scrollTop = 0;
        textarea.style.height = '1.94rem';
        textarea.style.height = 'hidden';
      }
    }

    if(!textCountPerLine){
      textCountPerLine = calculateCharsPerLine()
    }

  }, [value, InputRef]);

  const calculateCharsPerLine = () => { // this function to adjust very first line of textarea as it adjust text to top of input
    if (!InputRef.current) return 0;
    const textarea = InputRef.current;
    const style = window.getComputedStyle(textarea);
    const fontSize = style.fontSize;
    const fontFamily = style.fontFamily;
    const paddingLeft = parseFloat(style.paddingLeft) || 0;
    const paddingRight = parseFloat(style.paddingRight) || 0;
    const textareaWidth = textarea.clientWidth - paddingLeft - paddingRight;
    // Create a canvas for precise measurement
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = `${fontSize} ${fontFamily}`;
    // Binary search to find the maximum characters per line
    let low = 1;
    let high = 1000; // Arbitrarily large value for testing
    let result = 1;
  
    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      const testString = 'W'.repeat(mid); // Use 'W' as it is the widest character in most fonts
      const stringWidth = context.measureText(testString).width;
  
      if (stringWidth <= textareaWidth) {
        result = mid; // Found a fit, try for more
        low = mid + 1;
      } else {
        high = mid - 1; // Too wide, try fewer characters
      }
    }
    return result*1.86;
  };

  const handlePaste = (event) => {
    event.preventDefault();
  
    // Get the pasted data
    let pastedData = (event.clipboardData || window.clipboardData).getData('text');
  
    // Sanitize the pasted data:
    pastedData = pastedData
      .replace(/[\r\n]{3,}/g, '\n\n') // Replace 3 or more consecutive line breaks with 2 line breaks
      .replace(/[ \t]{2,}/g, ' ')    // Replace multiple spaces or tabs with a single space
      .trim();                       // Trim leading and trailing whitespace
  
    // Insert the sanitized data at the cursor position
    const textarea = event.target;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const before = textarea.value.slice(0, start);
    const after = textarea.value.slice(end);
    
    textarea.value = before + pastedData + after;
  
    // Restore the cursor position
    const newCursorPos = before.length + pastedData.length;
    textarea.setSelectionRange(newCursorPos, newCursorPos);
    onChange(event)
  };
  // const autoResize = (e) => {
  //   const textarea = e.target;
  //   textarea.style.height = 'auto'; // Reset height
  //   textarea.style.height = textarea.scrollHeight + 'px'; // Set height to scrollHeight
  // }

  return (
    <SearchContainer ref={wrapperRef} borderType={borderType} width={width} showSendBtn={showSendBtn} isSearchText={!!value?.trim()} padding={padding} >
      <div className="pre_btn">

        {showSpeakBtn && <span onClick={handleMicClick} className="microphone_btn"><MicrophoneIcon height='1.6rem' size='1.6rem' color="#000" /></span>}
        {showAddAttachment && <span onClick={() => { }} >
          <Popover borderRadius={'8px'} boxShadow={'rgba(149, 157, 165, 0.3) 0px 8px 24px'} trigger="click" width={'auto'} xPosition={'-80%'} yPosition={'-138%'} content={
            <div>
              <NormalButton height={'2.6rem'} borderRadius={'6px'} label={'Recent File'} onClick={() => { handleAddAttachmentClick('Recent File') }} justify="left" color={'#000000'} bgColor={'#fff'} hoverBg={"#f2f2f2"} width={'100%'} isIconMarRt={true} icon={<Datasource size={'1rem'} height={'0.8rem'} color={'#000'} />} />
              <NormalButton height={'2.6rem'} borderRadius={'6px'} label={'Google Drive'} onClick={() => { handleAddAttachmentClick('Google Drive') }} justify="left" color={'#000000'} bgColor={'#fff'} hoverBg={"#f2f2f2"} width={'100%'} isIconMarRt={true} icon={<GoogleDriveIcon size={'1rem'} height={'0.8rem'} color={'#000'} />} />
              <NormalButton height={'2.6rem'} borderRadius={'6px'} label={'Upload from Computer'} onClick={() => { handleAddAttachmentClick('Upload from Computer') }} justify="left" color={'#000000'} bgColor={'#fff'} hoverBg={"#f2f2f2"} width={'100%'} isIconMarRt={true} icon={<UploadFromComputerIcon size={'1rem'} height={'0.8rem'} color={'#000'} />} />
            </div>
          }>
            <span className="Add_attachment_btn">
              <Add height='1.2rem' size='1.2rem' color="#000" />
            </span>
          </Popover>
        </span>}
      </div>

      <SearchInput
        ref={InputRef}
        value={value}
        onChange={onChange}
        type={type}
        placeholder={placeHolder || 'Search'}
        padding={padding}
        onKeyUp={onKeyUp}
        onClick={onClick}
        onFocus={onFocus}
        autoFocus={true}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        spellCheck={true}
        // onInput={autoResize}
        onPaste={handlePaste}
      />

      <div className="post_btn">
        {showSendBtn && <span onClick={() => {
          !!value?.trim() && onSearch()
        }} className="send_btn_bg">
          <SendIcon height='1.2rem' size='1.2rem' color="#fff" />
        </span>}
      </div>


      {showBtnGroup && <div className="btn_group">
        <NormalButton
          width={'6rem'}
          padding={'4px 1rem'}
          borderRadius={'50px'}
          bgColor={'#e6e6e6'}
          label="Cancel"
          color="#000"
          height="2.4rem"
          onClick={onCancelEdit}
          mR={'1rem'}
        />
        <NormalButton
          width={'6rem'}
          borderRadius={'50px'}
          primary={true}
          label="Save"
          color="#fff"
          height="2.4rem"
          onClick={onSave}
        />
      </div>}



    </SearchContainer>
  );
};

export default SearchTextAreaBar;