import * as actions from '../action-types/menu.group';
import { sort_report_list } from '../utils';

const initialState = {
    type: actions.MENU_GROUP_INIT,
    recordsCount: 0,
    menus: undefined,
    menu_groups_cache: undefined,
    report_menus: undefined,
};


const menuGroupReducer = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    // if (action && action.data && typeof action.data === 'object') {
    //     action.data.key = new Date();
    // }

    switch (action.type) {
        case actions.MENU_GROUP_DATA_SUCCESS:
            return {
                ...state,
                type: action.type,
                menus: action.data
            };

        case actions.GET_MENU_GROUPS_SUCCESS:
            return {
                ...state,
                type: action.type,
                menu_groups_cache: action.data
            }
        case actions.SAVE_MENU_SUCCESS:
            return {
                ...state,
                type: action.type
            };

        case actions.GET_REPORT_MENUS_V1:

            const payload = action.data;

            const clone_all_reports = payload?.all_reports ? JSON.parse(JSON.stringify(payload?.all_reports)) : [];
            const clone_all_menu_group = payload?.menu_groups ? JSON.parse(JSON.stringify(payload?.menu_groups)) : [];
            const menus_informations = {};

            if (clone_all_reports && clone_all_reports.length > 0) {
                const sorted_report = sort_report_list(clone_all_reports, 'last_updated');
                const report_without_group = sorted_report?.filter((r) => (!r.menu_group_id && (r.type === "report" || r.type === "config_query_builder" || r.type === "sql"))) || [];
                const dashboard_without_group = sorted_report?.filter((r) => (!r.menu_group_id && (r.type === "insights"))) || [];
                const maps_without_group = sorted_report?.filter((r) => (!r.menu_group_id && (r.type === "maps"))) || [];
                const cache_menu_without_group = sorted_report?.filter((r) => (!r.menu_group_id && (r.type === "cache_editor"))) || [];

                if (!menus_informations['dashboard_menu']) menus_informations['dashboard_menu'] = [];
                if (!menus_informations['report_menu']) menus_informations['report_menu'] = [];
                if (!menus_informations['map_menu']) menus_informations['map_menu'] = [];
                if (!menus_informations['cache_menu']) menus_informations['cache_menu'] = [];


                clone_all_menu_group?.forEach((a) => {
                    const sorted_report_for_this_group = sorted_report?.filter((r) => r.menu_group_id === a.id) || [];
                    const dashboard_menu = sorted_report_for_this_group?.filter((r) => r.type === 'insights') || [];
                    const map_menu = sorted_report_for_this_group?.filter((r) => r.type === 'maps') || [];
                    const cache_menu = sorted_report_for_this_group?.filter((r) => r.type === 'cache_editor') || [];

                    const report_menu = sorted_report_for_this_group?.filter((r) => (r.type === "report" || r.type === "config_query_builder" || r.type === "sql")) || [];
                    if (!menus_informations['dashboard_menu']) menus_informations['dashboard_menu'] = [];
                    if (!menus_informations['report_menu']) menus_informations['report_menu'] = [];

                    if (dashboard_menu && dashboard_menu.length > 0) {
                        menus_informations['dashboard_menu'].push({
                            ...a,
                            menus: dashboard_menu,
                        })
                    }
                    if (report_menu && report_menu.length > 0) {
                        menus_informations['report_menu'].push({
                            ...a,
                            is_parent: true,
                            menus: report_menu,

                        })
                    }

                    if (report_menu && report_menu.length > 0) {
                        menus_informations['map_menu'].push({
                            ...a,
                            is_parent: true,
                            menus: map_menu,

                        })
                    }

                    if (cache_menu?.length > 0) {
                        menus_informations['cache_menu'].push({
                            ...a,
                            is_parent: true,
                            menus: cache_menu,

                        })
                    }

                })


                if (report_without_group?.length > 0) {
                    menus_informations['report_menu'].push({
                        name: 'Other',
                        menus: report_without_group,
                        is_parent: true,

                    })
                }

                if (dashboard_without_group && dashboard_without_group?.length > 0) {
                    menus_informations['dashboard_menu'].push({
                        name: 'Other',
                        menus: dashboard_without_group,
                        is_parent: true,

                    })
                }

                if (maps_without_group && maps_without_group?.length > 0) {
                    menus_informations['map_menu'].push({
                        name: 'Other',
                        menus: maps_without_group,
                        is_parent: true,
                    })
                }
                if (cache_menu_without_group && cache_menu_without_group?.length > 0) {
                    menus_informations['cache_menu'].push({
                        name: 'Other',
                        menus: cache_menu_without_group,
                        is_parent: true,
                    })
                }
            }


            return {
                ...state,
                type: action.type,
                report_menus: menus_informations
            }

        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
            return {
                ...state,
                type: state.type,
                menus: state.menus,
                recordsCount: state.recordsCount,
            };
    }
};

export default menuGroupReducer;