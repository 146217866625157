import styled from 'styled-components';
import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils';
const selected = getSelectedTheme();

const { label } = themes[selected];

export const DisplayFiledWrapper = styled.div`
    width: 56.429rem;
    max-height: 31.214rem;
    padding: 0.2rem 2.429rem 0.2rem 2.3429rem;
    overflow: auto;
    
    &::-webkit-scrollbar {
        background-color: #3F3F3F;
        width:4px;
    }

    &::-webkit-scrollbar-track {
        background-color: #F3F3F3;
        border: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dbdbdb;
        border-radius: 10px;
        height: 4px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color:  #3F3F3F;
        height: 4px;
    }
    

    .sort_title{
        font-size:1.714rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.214rem;
        letter-spacing: normal;
        text-align: left;
        color: #222;
        text-transform: uppercase;
        margin-top: 13px;

        &:nth-child(1){
            margin-top: 0px;
        }
}



/* .sort_title:first-of-type + li {
     color: red !important;
} */



h3{
    font-size: 1.286rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.714rem;
    letter-spacing: normal;
    text-align: left;
    color: #222;
    margin:0px;
    display: inline-block
}


input{
    border: 0px;
    border-bottom: 1px solid #000;
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.286rem;
    letter-spacing: normal;
    text-align: left;
    color: rgb(116, 136, 145);
    width: 100%;
    height: 2.143rem;
    padding: 0.214rem;
    outline: none;
    font-family: "DM Sans", sans-serif;
    box-sizing: border-box;



    &&::placeholder{
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.286rem;
        color: rgb(116, 136, 145);
        font-family: "DM Sans", sans-serif;
    }
}

ul {
    list-style: none;
    padding: 0px;
    margin: 0px ;
    display: flex;
    flex-direction: column; 
    flex-wrap:wrap;
    width: 100%;
    padding-bottom: 20px;
    overflow: auto;
    align-content: flex-start;
    &::-webkit-scrollbar {
        background-color: #3F3F3F;
        width:4px;
    }

    &::-webkit-scrollbar-track {
        background-color: #F3F3F3;
        border: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dbdbdb;
        border-radius: 10px;
        height: 4px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color:  #3F3F3F;
        height: 4px;
    }
     
    /* width: 250px; */

    .item_name{
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.714rem;
        letter-spacing: normal;
        text-align: left;
        color: #222;
        margin-left: 10px;
        width: calc(100% - 30px);
        text-overflow: ellipsis;
        overflow: hidden;
        flex-wrap: nowrap;
        white-space: nowrap;
        text-transform: capitalize;
        
        &:hover{
            background: ${props => props.isCheckboxHide ? '#eaeaea' : undefined};
        }
    }
    
}

li{
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: ${props => props.width ? props.width : '15.2rem'};
    cursor: pointer;
    margin-right: 1.429rem;

    .hover_view{
        display: none;
        padding-right: 0.4rem;
        padding-top: 0.146rem;
        box-sizing: border-box;
    }
    &:hover{
        .hover_view{
            display: block;
        }         
    };

}
`;





export const DisplayColumnData = styled.div`
    display: flex;
    width: 100%;
    overflow: auto;
    height: 18.571rem;
/* padding-bottom: 20px; */
`;



export const ATozTabWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 80%;

    .tab_value{
        font-size: 1.286rem;
        line-height: 1.714rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        color: #b1cad5;
        display: inline-block;
        /* margin-right: 10px; */
        text-transform: uppercase;
        cursor: pointer;
        padding: 0px 0.5rem;

        &:hover{
            background: #eaeaea;
        }
    }

`;



