import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, LabelList, ResponsiveContainer, Legend, Label, ReferenceLine, Tooltip } from 'recharts';
import { getRemToPx, getSelectedTheme, yDataValueFormatter, formatValueByDataType, apply_styles, getChartXAxisLayout } from '../../../utils'
import { scaleLinear } from 'd3-scale'
import { DataFormaterFixed } from '../yaxis-label';
import CustomizedAxisTick from '../tick';
import { themes } from '../../../theme/theme.config';
import CustomToolTip from '../tooltip/tooltip.custom';
import RenderLegend from '../legend/index.new'
import { constants } from '../../../utils/constants';



class SimpleLineChart extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            toolTipData: undefined,
            lineX: undefined,
            lineY: undefined
        }
    }


    changeToolTip = (func, data) => {

    };


    on_mouse_move = (event) => {
        if (event) {
            this.setState({ lineY: event.chartY })
        }
    }

    renderLabel = (entry) => {

        const { x, y, width, value } = entry;
        const radius = 10;
        let aligment = this.props.Alignments && this.props.Alignments[this.props.yDataKeys && this.props.yDataKeys[0]];
        let type = (aligment && aligment.type);
        let formatted_value = formatValueByDataType(value, type, undefined, undefined, undefined, "insights");

        return (
            <g>
                <text
                    x={x}
                    y={y - radius}
                    fill="#333"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontSize="10px"
                >
                    {formatted_value}
                </text>
            </g>
        );
    }



    render() {

        const {
            yDataKeys, paddingOnXaxis, insights, xDataKeys, data, showReferenceLine, valueOfReferenceLine,
            widthOfChart, showDash, heightOfChart, colorForYData, isReport,
            hide_y_axis, hide_x_axis, hide_y_axis_border, hide_x_axis_border, hide_grid, hide_legend, isDimension,
            hide_x_axis_label, hide_y_axis_label, isDiscovery, staticYAxisLabel, hide_tooltip, Alignments, chartStyle, tooltipTheme, show_data_label
        } = this.props;


        const xAxisInterval = 0;





        function getYs() {
            return data.map(d => d[yDataKeys[0]]);
        }

        function getMinY() {
            return Math.min(...getYs());
        }

        function getMaxY() {
            return Math.max(...getYs());
        }

        let yDomain = [getMinY(), getMaxY()]
        let yScale = scaleLinear().range([heightOfChart, 0]).domain(yDomain);


        let chartMargin = {
            top: !hide_x_axis_label ? isDimension ? 0 : (insights || isDiscovery) ? 20 : 50 : heightOfChart < 100 ? 0 : 10,
            right: !hide_y_axis_label ? isDimension ? getRemToPx(1.25) : (insights || isDiscovery) ? getRemToPx(2.5) : getRemToPx(9.375) : 10,
            bottom: isDimension ? 0 : ((hide_x_axis_label && hide_x_axis) ? 0 : hide_x_axis_label ? 0 : hide_x_axis ? 20 : !insights ? (!isDiscovery ? 70 : 20) : 15),
            left: !hide_y_axis_label ? isDimension ? 0 : (insights || isDiscovery) ? getRemToPx(2.5) : getRemToPx(9.375) : 0
        }

        let yAxisLabelOffset = isDimension ? getRemToPx(1.25) : (insights || isDiscovery) ? getRemToPx(-1.5) : getRemToPx(-2.5);
        let xAxisLabelOffset = isDimension ? 7 : (insights || isDiscovery) ? -5 : -20;
        let heightOfXAxis = isDimension ? undefined : ((hide_x_axis_label && hide_x_axis) ? 7 : hide_x_axis_label ? getRemToPx(5.5) : hide_x_axis ? 20 : getRemToPx(insights ? 6 : 7));
        let widthOfYAxis = !hide_y_axis ? getRemToPx(4.1) : 5;

        const chart_xaxis_layout = getChartXAxisLayout(widthOfChart, heightOfChart, this.props.xDataKeys, this.props?.data?.length)

        const xAxisStyle = chartStyle.axis && chartStyle.axis.x;
        const yAxisStyle = chartStyle.axis && chartStyle.axis.y;

        const xAxisTickStyle = apply_styles((xAxisStyle && xAxisStyle['tick']), constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT)
        const yAxisTickStyle = apply_styles((yAxisStyle && yAxisStyle['tick']), constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT)
        const xAxisTitleStyle = apply_styles((xAxisStyle && xAxisStyle['title']), constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT)
        const yAxisTitleStyle = apply_styles((yAxisStyle && yAxisStyle['title']), constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT)
        const xAxisTick = !hide_x_axis ? <CustomizedAxisTick xDataKeys={xDataKeys} Alignments={Alignments} totalTick={this.props.data.length} _style={xAxisTickStyle} /> : null;
        const legendStyle = chartStyle && chartStyle.legend;

        let active_bar = undefined;

        // console.log("chart_xaxis_layout", chart_xaxis_layout)

        return (
            <div id='chart'
                style={{ position: 'relative', width: widthOfChart ? typeof widthOfChart === 'number' ? (widthOfChart - 0) : widthOfChart : '93.1vw', textTransform: 'capitalize', }}>
                <ResponsiveContainer width='100%' height={heightOfChart}  >
                    <LineChart
                        width={widthOfChart}
                        height={heightOfChart}
                        data={data}
                        // margin={chartMargin}
                        margin={{ top: show_data_label ? 30 : 10, left: show_data_label ? 30 : 10, right: show_data_label ? 30 : 10, bottom: 10 }}
                        onMouseMove={this.on_mouse_move}
                    >

                        {!hide_grid && !isDimension && (
                            <CartesianGrid strokeDasharray="2 1" vertical={false} horizontalY={false} />
                        )}

                        {!hide_legend && <Legend content={<RenderLegend legendStyle={legendStyle} />} verticalAlign="top" height={30} />}


                        <XAxis
                            {...chart_xaxis_layout}
                            // stroke={chartStyle.color.yAxisStock}
                            dataKey={xDataKeys && xDataKeys.length > 0 && xDataKeys[xDataKeys.length - 1]}
                            padding={{ right: (insights || isDiscovery) ? undefined : paddingOnXaxis, left: 10 }}
                            style={xAxisTickStyle}
                            height={heightOfXAxis}
                            textAnchor="end"
                            tick={xAxisTick}
                            axisLine={!hide_x_axis_border ? true : false}
                            interval={xAxisInterval}
                        >
                            {!hide_x_axis_label &&
                                <Label
                                    angle='0'
                                    position='insideBottom'
                                    offset={xAxisLabelOffset}
                                    style={xAxisTitleStyle}>
                                    {xDataKeys && xDataKeys.length > 0 && xDataKeys[0]}
                                </Label>
                            }
                        </XAxis>


                        <YAxis
                            // stroke={chartStyle.color.yAxisStock}
                            interval={0}
                            style={yAxisTickStyle}
                            tickFormatter={DataFormaterFixed}
                            tick={!hide_y_axis && !isDimension ? true : false}
                            width={widthOfYAxis}
                            axisLine={!hide_y_axis_border ? true : false}
                            domain={[dataMin => (Math.round(dataMin * 80 / 100)), dataMax => Math.round((dataMax * 1))]}
                            allowDecimals={false}


                        >
                            {!hide_y_axis_label &&
                                <Label
                                    angle='-90'
                                    position='insideLeft'
                                    offset={yAxisLabelOffset}
                                    style={yAxisTitleStyle}>

                                    {staticYAxisLabel ? staticYAxisLabel : yDataValueFormatter(yDataKeys[0])}

                                </Label>
                            }
                        </YAxis>

                        {/* {!hide_tooltip && (<Tooltip />)} */}
                        {/* {!hide_tooltip && (
                            <Tooltip
                                content={function (active) {
                                    {
                                        let _data = {};
                                        const _payload = active && active.payload;
                                        const _inner_payload = _payload && _payload[0] && _payload[0].payload;
                                        const payload_data = _inner_payload && _inner_payload;
                                        let array_of_keys = [...xDataKeys, ...yDataKeys];
                                        array_of_keys && array_of_keys.length > 0 && array_of_keys.map((key) => {
                                            if (payload_data && Object.keys(payload_data).length > 0 && Object.keys(payload_data).indexOf(key) > -1) {
                                                let _alignment_type = Alignments && Alignments[key] && Alignments[key].type;
                                                _data[key] = formatValueByDataType(payload_data[key], _alignment_type)
                                            }
                                        })
                                        return <CustomToolTip data={_data} theme={tooltipTheme} />
                                    }
                                }}
                            />
                        )} */}

                        {
                            colorForYData && yDataKeys && yDataKeys.length > 0 && yDataKeys.map((data, index) => {

                                const show_dash = showDash || data && data.toLowerCase().indexOf('projection') > -1;
                                const color_of_axis = data && data.toLowerCase().indexOf('projection') > -1 ? 'red' : colorForYData[index];

                                return <Line
                                    key={index}
                                    type="monotone"
                                    dataKey={data}
                                    // dot={{ onClick: this.clickDot }}
                                    dot={false}
                                    stroke={color_of_axis}
                                    strokeDasharray={show_dash ? "5 5" : ''}
                                    strokeOpacity="1"
                                    strokeWidth="1.9"
                                    onMouseOver={() => {
                                        console.log('OUCHHHHHH: ', data)
                                    }}
                                    onMouseEnter={() => {
                                        console.log('OUCHHHHHH: ', data)
                                    }}
                                // label={show_data_label}

                                // onMouseOver={(e) => {
                                //     active_bar = data;
                                // }}
                                // onMouseOut={(e) => {
                                //     // active_bar = undefined;
                                // }}
                                >
                                    {isReport && <LabelList dataKey={data} style={{ fontSize: '.8rem', fontWeight: 'bold' }} position="top" />}
                                    {show_data_label && <LabelList dataKey={data} position="top" content={this.renderLabel} />}

                                </Line>
                            })
                        }
                        {/* {
                            showReferenceLine &&
                            <ReferenceLine y={valueOfReferenceLine} stroke="#000" stockWidth="2" style={{ strokeDasharray: '4' }} />
                        } */}

                    </LineChart>
                </ResponsiveContainer>

            </div>
        );
    }
}

export default SimpleLineChart;