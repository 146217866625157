import * as actionTypes from '../action-types/file_upload_config';
import { dispatchAction, simpleDispatch_post, simpleDispatch_get, showError } from './helper';
import { constants } from '../utils/constants';
import * as loaderTypes from '../action-types/loader';
import { post } from '../data.service';




export const saveFileUploadConfig = (upload_config) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.FILE_UPLOAD_CONFIG.POINT + constants.END_POINTS.FILE_UPLOAD_CONFIG.SAVE_CONFIG;

    const data = await post(url, { upload_config: upload_config });

    if (data?.code === 200) {
        dispatchAction(dispatch, actionTypes.SAVE_FILE_UPLOAD_CONFIG)
        getUploadConfig()(dispatch)
    }
    // await simpleDispatch_post(dispatch, url, {
    //     upload_config: upload_config
    // }, actionTypes.SAVE_FILE_UPLOAD_CONFIG, undefined, undefined, { message: 'Saved Success' });
}



export const getUploadConfig = () => async (dispatch) => {

    const url = constants.END_POINTS.API + constants.END_POINTS.FILE_UPLOAD_CONFIG.POINT + constants.END_POINTS.FILE_UPLOAD_CONFIG.GET_CONFIG;

    await simpleDispatch_get(dispatch, url, actionTypes.GET_FILE_UPLOAD_CONFIG)

}
