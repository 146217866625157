import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Overlay from '../helper/overlay';
import LockSvg from '../svg/security';
import Ceartsvg from '../svg/chevron.down'


const Button = styled.div`
    width: 126px;
    /* border: 1px solid #ccc; */
    height: 1.7rem;
    letter-spacing: normal;
    text-align: center;
    font-size: 0.875rem;
    background: #e2e2e273;
    color: #374a66;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 3px;
    padding: 3px 11px;
    box-sizing: border-box;
    cursor: pointer;

    span{
        max-width: 79px;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-weight: 500;
    }

    &:hover{
        background: #308bc81f;
    }
`;




const BottomDropDownMenu = styled.div`
    background: #fff;
    position: absolute;
    top: 38px;
    width: 126px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 20%);
    right: 0px;
    padding: 8px 0px;
    box-sizing: border-box;
    z-index: 3;

    .item{
        padding: 7px 10px;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        svg{
            fill: #030303;
        }

        span{
            display: inline-block;
            /* margin-left: 10px; */
            color: #030303;
            font-size: .9rem;
            max-width: 79px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-weight: 500;

        }

        &:hover{
            background-color: rgba(0, 0, 0, 0.1);
            cursor: pointer;
        }
    }
`;

const AccessGroupPicker = (props) => {

    const { access_group_report_mapping, set_access_group_report_mapping, accessGroupList } = props;

    const [open, set_open] = useState(undefined)

    const access_group_click = (value) => {
        let clone_pre_access_group_report = access_group_report_mapping ? [...access_group_report_mapping] : [];
        if (clone_pre_access_group_report.indexOf(value) > -1) {
            clone_pre_access_group_report.splice(clone_pre_access_group_report.indexOf(value), 1);
            if (clone_pre_access_group_report.length === 0) {
                clone_pre_access_group_report.push('any')
            }
        } else {
            clone_pre_access_group_report.push(value)
            if (value !== 'any' && clone_pre_access_group_report.indexOf('any') > -1) {
                clone_pre_access_group_report.splice(clone_pre_access_group_report.indexOf('any'), 1)
            }
        }
        if (value === 'any') {
            clone_pre_access_group_report = ['any']
        }
        set_access_group_report_mapping(clone_pre_access_group_report)
    }


    const get_label_for_group_access = () => {
        if (access_group_report_mapping && access_group_report_mapping.length > 1) {
            // it's means it is multiple values ;\
            return 'Multiple';
        }
        if (accessGroupList && accessGroupList.length > 0 && access_group_report_mapping && access_group_report_mapping.length === 1 && access_group_report_mapping.indexOf('any') === -1) {
            // it's means it 's single value ;\
            return accessGroupList.find(c => c.id === access_group_report_mapping[0])?.['name'];
        }
        else {
            return 'Any'
        }
    }
    return (
        <div style={{ position: 'relative' }} className="btn_spe">
            {(open) ? (
                <Overlay zIndex={"2"}
                    onClick={() => {
                        set_open(undefined)
                    }} />
            ) : null}

            <Button title={get_label_for_group_access()} onClick={() => set_open(true)}>
                <LockSvg size="1rem" height="1rem" color="#008AC7" />&nbsp;&nbsp;
                <span>{get_label_for_group_access()}</span>
                &nbsp;&nbsp;
                <Ceartsvg size=".8rem" height=".8rem" color="#000" />
            </Button>

            {open && (
                <BottomDropDownMenu>
                    <div className="item" onClick={() => access_group_click("any")}>
                        <input checked={access_group_report_mapping?.indexOf('any') > -1} type="checkbox" /> &nbsp;&nbsp;<span>Any</span></div>
                    {accessGroupList?.length > 0 && accessGroupList?.map((group) => {
                        return (
                            <div className="item" onClick={() => {
                                access_group_click(group.id)
                            }}>
                                <input checked={access_group_report_mapping?.indexOf(group.id) > -1} type="checkbox" /> &nbsp;&nbsp;
                                <span>{group.name}</span>
                            </div>
                        )
                    })}
                </BottomDropDownMenu>
            )}

        </div>)
}


const mapStateToProps = (state) => ({
    accessGroupList: state.groupAccessReducer.accessGroupList

})

export default connect(mapStateToProps, {})(AccessGroupPicker)