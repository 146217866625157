import { constants } from './constants';
import * as encryption from './encryption.helper';

const shouldTextBeEncrypted = constants.USE_ENCRYPTION;
const sessionKeys = constants.SESSION_KEYS;
const localStorage = window.localStorage;
const sessionStorage = window.sessionStorage;

export const saveInSession = (key, value, isSession) => {
    if (isSession) {
        sessionStorage.setItem(key, (shouldTextBeEncrypted === true) ? encryption.encryptText(value) : value);

    } else {
        localStorage.setItem(key, (shouldTextBeEncrypted === true) ? encryption.encryptText(value) : value);

    }
};


export const getFromSession = (key, isSession) => {
    if (isSession) {
        const item = sessionStorage.getItem(key);

        return (shouldTextBeEncrypted === true ? encryption.decryptText(item) : item);
    } else {
        const item = localStorage.getItem(key);

        return (shouldTextBeEncrypted === true ? encryption.decryptText(item) : item);
    }
};

export const removeItemFromSession = (key) => {
    localStorage.removeItem(key);
}


export const getToken = () => {
    let tempToken = undefined;

    tempToken = getFromSession(constants.SESSION_KEYS.TOKEN);

    return tempToken;
};


export const getRefreshToken = () => {
    let tempToken = undefined;

    tempToken = getFromSession(constants.SESSION_KEYS.REFRESH_TOKEN);

    return tempToken;
};


export const saveInsightInSession = value => {
    saveInSession(sessionKeys.DEFAULT_INSIGHT, value);
};

export const getInsightFromSession = () => {
    return getFromSession(sessionKeys.DEFAULT_INSIGHT);
};

export const getLoggedUser = () => {
    const loggedUserJSON = getFromSession(sessionKeys.LOGGED_USER);

    if (loggedUserJSON && (typeof loggedUserJSON === "string")) {
        const parsedData = JSON.parse(loggedUserJSON);

        if (parsedData && parsedData.id) {
            return parsedData;
        }

        return null;
    }

    return null;
};


export const getLoggedUserId = () => {
    const user = getLoggedUser();
    if (!user) {
        return -1;
    }
    return user.id;
};


export const saveToken = (token) => {
    saveInSession(sessionKeys.TOKEN, token)
};


export const saveOprData = (opr_data) => {
    return saveInSession(sessionKeys.OPR_DATA, JSON.stringify(opr_data));
};


export const saveRefreshToken = (token) => {
    saveInSession(sessionKeys.REFRESH_TOKEN, token)
};


export const saveUser = (user) => {
    if (user.token) {
        saveToken(user.token);
        saveRefreshToken(user.refreshToken);
        saveInSession(sessionKeys.LOGGED_USER, JSON.stringify(user.user));
    }
    else {
        saveInSession(sessionKeys.LOGGED_USER, JSON.stringify(user));
    }
};

export const killUser = () => {
    localStorage.removeItem(sessionKeys.LOGGED_USER)
}

export const getOprData = () => {
    const opr_text = getFromSession(constants.SESSION_KEYS.OPR_DATA);

    if (opr_text) return JSON.parse(opr_text);
};



export const setKeysForDevLogin = () => {
    const currentRole = 'Facility Admin';
    const currentUser = {
        // id: "6ad4e820-ffcc-11e9-a0e6-96e83143993f-37",
        user_id: "e38aa9c0-1594-11ea-adbb-76bbf716ed55-66",
        mobile_no: "9873921174",
        first_name: "testshweta",
        password: "12345678",
        session_id: "1c381742-8092-11ea-a5ee-b27df2658323-64",
        user_type: "account_user",
        facilities: [
            {
                org_id: "b3ac2790-1055-11ea-a223-ba974415a2bf-21",
                currency: "india",
                language: "english",
                org_name: "Paras sh 29",
                time_zone: "ist"
            }
        ],
        user_roles: [
            "Facility Admin"
        ],
        domain: "karexpert",
        language: "English",
        header: {
            id: "6ad4e820-ffcc-11e9-a0e6-96e83143993f-37",
            name: "karexpert"
        }
    };
    const currentOrg = {
        org_id: "6e036140-547a-11ea-a676-c6b989256122-56",
        account_id: "6ad4e820-ffcc-11e9-a0e6-96e83143993f-37",
        currency: "india",
        language: "english",
        org_name: "Parassh29",
        time_zone: "ist"
    }
    const existingUser = localStorage.getItem('currentUser');

    if (!existingUser || existingUser === '') {
        localStorage.setItem('CURRENT_ROLE', JSON.stringify(currentRole));
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        localStorage.setItem('CURRENT_ORGANIZATION', JSON.stringify(currentOrg));
    }
};

export const whichUserFromSession = () => {
    let user = undefined;

    if (process.env.REACT_APP_DEV_LOGIN_KEY === constants.LOGIN_DEV_KEY) {
        user = getFromSession(constants.SESSION_KEYS.LOGGED_USER);
    }
    else {
        user = localStorage.getItem(constants.SESSION_KEYS.KX.CURRENT_USER);

    }

    if (user) {
        const tempUser = JSON.parse(user);

        tempUser.id = tempUser.user_id;


        return tempUser;
    }
};


export const save_filters_in_session = (filters, base_report_id) => {
    const filters_from_session = getFromSession(constants.SESSION_KEYS.REPORT_FILTERS);
    const existing_filters_str = filters_from_session ? filters_from_session : undefined;

    let existing_filters = {};

    if (existing_filters_str) {
        existing_filters = JSON.parse(existing_filters_str);
    }

    existing_filters[base_report_id] = filters;

    // console.log("=======saving filters normal========")
    // console.log('filters ====> ', filters);
    // console.log("=======================================")
    saveInSession(constants.SESSION_KEYS.REPORT_FILTERS, JSON.stringify(existing_filters));


    setTimeout(() => {
    }, 300);


};


// REPORT_PIVOT_INFO

export const save_pivot_column_info_in_session = (column_info, base_report_id) => {

    const pivot_c_info_form_session = getFromSession(constants.SESSION_KEYS.REPORT_PIVOT_INFO);
    const existing_filters_str = pivot_c_info_form_session ? pivot_c_info_form_session : undefined;

    let existing_info = {};

    if (existing_filters_str) {
        existing_info = JSON.parse(existing_filters_str);
    }
    existing_info[base_report_id] = column_info;
    // console.log("=======saving pivot normal========")
    // console.log(column_info)
    // console.log("=======================================")
    saveInSession(constants.SESSION_KEYS.REPORT_PIVOT_INFO, JSON.stringify(existing_info));
    setTimeout(() => {
    }, 300);
};


export const get_pivot_column_info_from_session = (base_report_id) => {

    const column_info_from_session = getFromSession(constants.SESSION_KEYS.REPORT_PIVOT_INFO);
    const existing_info_str = column_info_from_session ? column_info_from_session : undefined;

    if (existing_info_str) {
        const existing_info = JSON.parse(existing_info_str);

        // console.log("=======GETTING COLUMN INFO NORMAL ========")
        // console.log(existing_info[base_report_id]);
        if (!existing_info[base_report_id]) console.log('existing_info: ', existing_info, base_report_id);
        // console.log("=======================================")
        return existing_info[base_report_id];
    }

    return undefined;
};




export const get_filters_from_session = (base_report_id) => {

    const filters_from_session = getFromSession(constants.SESSION_KEYS.REPORT_FILTERS);
    const existing_filters_str = filters_from_session ? filters_from_session : undefined;


    if (existing_filters_str) {
        const existing_filters = JSON.parse(existing_filters_str);

        // console.log("=======GETTING filters NORMAL ========")
        // console.log(existing_filters[base_report_id]);
        if (!existing_filters[base_report_id]) console.log('existing_filters_str: ', existing_filters, base_report_id);
        // console.log("=======================================")

        return existing_filters[base_report_id];
    }

    return undefined;
};








export const save_be_filters_in_session = (filters, base_report_id) => {
    const filters_from_session = getFromSession(constants.SESSION_KEYS.REPORT_FILTERS);
    const existing_filters_str = filters_from_session ? filters_from_session : undefined;

    let existing_filters = {};

    if (existing_filters_str) {
        existing_filters = JSON.parse(existing_filters_str);
    }

    existing_filters[base_report_id] = filters;

    saveInSession(constants.SESSION_KEYS.REPORT_BE_FILTERS, JSON.stringify(existing_filters));

    setTimeout(() => {
    }, 300);


};





export const get_be_filters_from_session = (base_report_id) => {

    const filters_from_session = getFromSession(constants.SESSION_KEYS.REPORT_BE_FILTERS);
    const existing_filters_str = filters_from_session ? filters_from_session : undefined;

    if (existing_filters_str) {
        const existing_filters = JSON.parse(existing_filters_str);
        return existing_filters[base_report_id];
    }

    return undefined;
};





export const get_filter_validation_from_session = (base_report_id) => {

    const validations_from_session = getFromSession(constants.SESSION_KEYS.VALIDATION_KEYS);

    const existing_validations = validations_from_session ? validations_from_session : undefined;
    if (existing_validations) {
        const existing_filters = JSON.parse(existing_validations);
        return existing_filters[base_report_id];
    }
    return undefined;
};




export const save_validation_in_session = (validation, base_report_id) => {

    const val_from_sessions = getFromSession(constants.SESSION_KEYS.VALIDATION_KEYS);
    const existing_validations = val_from_sessions ? val_from_sessions : undefined;

    let existing_val = {};

    if (existing_validations) {
        existing_val = JSON.parse(existing_validations);
    }
    existing_val[base_report_id] = validation;

    // console.log("existing_val", existing_val)
    saveInSession(constants.SESSION_KEYS.VALIDATION_KEYS, JSON.stringify(existing_val));

};
