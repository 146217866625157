import { simpleDispatch_post, dispatchAction, simpleDispatch_get, simpleDispatch_delete, raiseSuccess } from './helper';
import * as actionTypes from '../action-types/streaming';
import { constants } from '../utils/constants';
import * as loaderTypes from '../action-types/loader';
import { get, post } from '../data.service';



export const getTheStreamingService = () => async dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_CURRENT_STREAMING_JOBS)
    const url = constants.END_POINTS.API + constants.END_POINTS.STREAMING_SERVICE.POINT + constants.END_POINTS.STREAMING_SERVICE.GET_THE_STREAMING_SERVICE;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_STREAMING_DATA_SERVICE);
}


export const saveTheStreamingData = (data, message) => async dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_CURRENT_STREAMING_JOBS)
    const url = constants.END_POINTS.API + constants.END_POINTS.ETL.POINT + constants.END_POINTS.ETL.SAVE_STREAMING_JOB;
    // await simpleDispatch_post(dispatch, url, data, actionTypes.SAVE_STREAMING_JOB, undefined, undefined, { message: message });

    try {
        const response = await post(url, data);
        if (response && response.code === 200) {
            getStreamingJobs()(dispatch)
            raiseSuccess(dispatch, {
                type: 'success',
                message: "Saved Successfully"
            })
            dispatchAction(dispatch, actionTypes.SAVE_STREAMING_JOB, undefined, undefined, undefined, 0)
            dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        }

    } catch (error) {
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        // showError(dispatch, error);
    }


};



export const getStreamingJobs = () => async dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_CURRENT_STREAMING_JOBS)
    const url = constants.END_POINTS.API + constants.END_POINTS.ETL.POINT + constants.END_POINTS.ETL.GET_STREAMING_JOBS;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_STREAMING_JOBS);
}



export const getCurrentStreamingJob = (id) => async dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_CURRENT_STREAMING_JOBS)
    const url = constants.END_POINTS.API + constants.END_POINTS.ETL.POINT + constants.END_POINTS.ETL.GET_STREAMING_JOB_BY_ID + `?id=${id}`;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_CURRENT_STREAMING_JOBS);
}


export const clearCurrentStreamingJob = () => async dispatch => {
    await dispatchAction(dispatch, actionTypes.CLEAR_CURRENT_STREAMING_JOBS)

}

export const pushEditedRowDataToStore = (data) => async dispatch => {
    await dispatchAction(dispatch, actionTypes.PUSH_EDITED_STREAM_ROW_TO_STORE, data)

}
