import React from "react";

const TrendingChartIcon = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      shape-rendering="geometricPrecision"
      text-rendering="geometricPrecision"
      image-rendering="optimizeQuality"
      fill-rule="evenodd"
      clip-rule="evenodd"
      viewBox="0 0 1368 888"
    >
      <path
        transform="translate(491,2)"
        d="m0 0h22l20 4 16 6 14 8 10 8 10 9 10 13 10 19 5 16 2 10v29l-4 18-1 3 12 10 16 12 14 11 17 13 13 10 18 14 26 20 16 12 14 11 17 13 13 10 19 14v2h5l10-6 15-6 18-4h32l18 4 16 7 9 5 9 6 9-5 15-10 19-12 25-16 20-13 22-14 34-22 22-14 23-15 19-12 12-8h2l-1-8-1-6v-28l4-19 7-16 7-12 9-11 14-14 16-10 11-5 12-4 20-3h17l18 3 15 5 17 9 13 10 12 12 10 15 8 17 6 21v32l-5 19-8 18-7 11-9 11-8 8-14 10-16 8-21 6-15 2h-11l-15-2-15-4-16-7-12-8-2-2h-5l-10 7-18 11-15 10-47 30-34 22-22 14-34 22-22 14-17 11v3l2 12v23l-3 17-6 18-8 14-7 9-9 10-12 10-10 6-19 8-17 4-10 1h-17l-18-3-12-4-16-8-13-9-12-11-10-13-9-17-5-14-3-16v-25l3-16 2-7-12-10-12-9-130-100-18-14-17-13-8-6-4 1-14 7-18 6-18 3h-18l-13-2-2 6-14 24-17 29-15 26-13 22-15 26-13 22-15 26-13 22-15 26-13 22-15 26-13 22-15 26-13 22-15 26-13 22-15 26-13 22-28 48 3 5 10 15 8 19 4 18v31l-4 16-6 16-9 15-9 11-8 8-14 10-16 8-14 5-15 3h-25l-21-5-16-6-14-9-11-9-9-9-10-14-7-14-5-15-3-17v-19l3-17 4-13 8-16 7-11 12-13 9-8 13-8 12-6 17-5 19-2 5-1 3 1 18 1 6-9 34-58 15-26 8-13 9-15 6-11 17-29 28-48 17-29 15-26 9-15 15-26 13-22 10-17 14-24 17-29 14-24 17-29 15-26 8-13-2-5-8-11-8-16-6-18-2-11v-24l4-20 7-17 8-13 9-12 8-7 7-6 16-10 15-6 16-4z"
        fill="#6F7AFD"
      />
      <path
        transform="translate(496,23)"
        d="m0 0h12l16 3 15 5 16 8 10 9 8 8 8 12 7 15 4 14v20l-3 14-3 10-8 16-6 9-9 10-11 8-16 8-11 4-15 3h-11l-19-3-20-8-11-7-8-7-9-11-7-11-5-10-5-17v-23l4-18 5-13 8-13 11-12 12-9 16-8 13-4z"
        fill="#2F97FC"
      />
      <path
        transform="translate(107,693)"
        d="m0 0 24 3 18 6 14 8 10 8 3 3v2h2l10 15 7 15 4 16v18l-3 16-6 15-7 12-8 10-7 7-13 9-10 5-10 4-11 3-7 1h-13l-22-5-16-6-13-9-6-6v-2h-2l-10-14-8-15-4-12-2-11v-13l5-21 6-16 7-11 8-10 15-11 14-7 12-4z"
        fill="#2F97FC"
      />
      <path
        transform="translate(859,301)"
        d="m0 0 14 1 16 4 13 5 15 9 12 11 9 12 7 15 4 15 1 7v17l-3 15-7 17-7 11-11 12-8 7-14 8-15 6-10 3-7 1h-13l-12-2-16-5-16-8-8-7-8-8-11-17-5-10-4-11-2-8v-23l4-16 5-11 6-11 8-11 10-9 14-9 9-4 9-3z"
        fill="#2F97FC"
      />
      <path
        transform="translate(1254,46)"
        d="m0 0 20 1 19 5 16 8 8 6 11 11 7 10 8 16 5 18v22l-5 19-8 16-7 10-2 3h-2v2h-2l-2 4h-2v2l-10 7-16 8-11 4-12 3h-21l-16-4-13-5-10-6-10-8-10-11-8-13-5-11-4-16v-26l4-17 5-12 6-10 12-13 13-10 13-7 15-4z"
        fill="#2F97FC"
      />
    </svg>
  );
};

export default TrendingChartIcon;
