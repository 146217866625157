import React from 'react';
import styled from 'styled-components';
import SuccessSvg from '../svg/success';
import ExclamationSvg from '../svg/exclamation'


const Wrapper = styled.div`
    width: 300px;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: fixed;
    color: #fff;
    pointer-events: none;
    z-index: 2001;
    left: 0;
    right: 0;
    top: ${props => props.show ? '0px' : '-100px'};
    transition: all 0.2s;
    background-color: ${props => props.type === 'success' ? '#308bc8' : 'red'};
    margin: auto;
    box-shadow: 0px 2px 6px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    border-radius: 4px;



    h1{
        margin: 0px;
        font-size: .9rem;
    }

    p{
        margin: 0px;
        font-size: .8rem;
        margin-top: 8px;
    }

`;


const Index = (props) => {

    const { success } = props;
    return (
        <Wrapper show={success} type={success && success.type} >
            {success && (
                <React.Fragment>
                    {success && success.type == 'success' ? <SuccessSvg size='1.4rem' height='1.4rem' color='#fff' /> : <ExclamationSvg size='1.4rem' height='1.4rem' color='#fff' />}
                    <div style={{ marginLeft: '1rem' }}>
                        <h1>{success.title ? success.title : 'Success'}</h1>
                        <p>{success.message}</p>
                    </div>

                </React.Fragment>
            )}

        </Wrapper>
    )
}

export default Index
