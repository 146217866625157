import React from 'react';

const Add = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 90 90"
            space="preserve">
            <path
                d="M72,56c-4.813,0-9.12,2.137-12.054,5.501L39.643,51.35C39.873,50.269,40,49.149,40,48s-0.127-2.269-0.357-3.349  l20.303-10.152C62.879,37.864,67.187,40,72,40c8.836,0,16-7.164,16-16S80.836,8,72,8s-16,7.164-16,16  c0,1.149,0.127,2.269,0.357,3.349L36.054,37.501C33.121,34.136,28.814,32,24,32c-8.836,0-16,7.164-16,16c0,8.836,7.164,16,16,16  c4.814,0,9.12-2.137,12.054-5.501l20.304,10.152C56.127,69.731,56,70.851,56,72c0,8.836,7.164,16,16,16s16-7.164,16-16  S80.836,56,72,56z M72,16c4.418,0,8,3.582,8,8s-3.582,8-8,8s-8-3.582-8-8S67.582,16,72,16z M24,56c-4.418,0-8-3.582-8-8  c0-4.418,3.582-8,8-8s8,3.582,8,8C32,52.418,28.418,56,24,56z M72,80c-4.418,0-8-3.582-8-8s3.582-8,8-8s8,3.582,8,8S76.418,80,72,80  z"
            />
        </svg>

    )

}


export default Add;