import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { getFiltersForDropDowns } from '../../actions/filter';
import SelectOption from '../select-option';
import styled from 'styled-components';
import { Gap } from '../gap'
import TextBox from '../hoc/textBox';

const Wrapper = styled.div`
     width: 100%;
    h1{
        font-size: 1.286rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.714rem;
        letter-spacing: normal;
        text-align: center;
        color: #1a1a1a;
        margin: 0px;
    }
    .row_label{
        color: #0000008a;
        font-size: 1rem;
    }
`;


const FilterScrollbar = styled.div`
    width: 100%;
    padding: 0px 0px;
    margin-top: 2rem;
    
`;

// doctor_name is "vikas bhandari" "saroj yadar" and age is 

const FilterDefaultValue = (props) => {

    const { dropDowns, filterValue, filterDefaultValue, setFilterDefaultValue } = props;

    useEffect(() => {
        props.getFiltersForDropDowns(filterValue)
    }, [])


    const changeCustomDate = (key, event) => {
        let tempFilters = filterDefaultValue ? JSON.parse(JSON.stringify(filterDefaultValue)) : {};
        if (tempFilters["date_filter"] === undefined) {
            tempFilters["date_filter"] = {
                "type": "custom_date"
            };
        }
        const tempQuickDate = tempFilters["date_filter"];
        tempQuickDate[key] = event.target.value;

        tempFilters['date_filter'] = tempQuickDate;
        setFilterDefaultValue(tempFilters);
    };




    return (
        <Wrapper>
            <h1>Select Default Values of Filters</h1>
            <FilterScrollbar>
                <div style={{ width: '100%', marginBottom: '1rem' }}>
                        <div style={{ margin: 'auto', display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: '.7rem', justifyContent: 'space-between', width: '70%' }}>
                        
                        <div style={{ width: '20%' }}>
                            <label className="row_label">Quick Date</label>
                        </div>

                        <div style={{ width: '35%' }}>
                            <TextBox
                                label={"Quick Dates - From Date"}
                                type="date"
                                value={filterDefaultValue && filterDefaultValue['date_filter'] && filterDefaultValue['date_filter']['dateFrom'] ? filterDefaultValue['date_filter']['dateFrom'] : undefined}
                                onChange={(e) => {
                                    changeCustomDate("dateFrom", e)
                                }} />
                        </div>

                        <div style={{ width: '35%' }}>
                            <TextBox
                                value={filterDefaultValue && filterDefaultValue['date_filter'] && filterDefaultValue['date_filter']['dateTo'] ? filterDefaultValue['date_filter']['dateTo'] : undefined}
                                label="Quick Dates - To Date"
                                type="date"
                                onChange={(e) => {
                                    changeCustomDate("dateTo", e)
                                }} />
                        </div>

                    </div>
                </div>


                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                    {dropDowns && Object.keys(dropDowns).length > 0 ? Object.keys(dropDowns).map((key, index) => {
                        return (
                           <div style={{  margin: 'auto',display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: '.7rem', justifyContent: 'space-between', width: '70%' }}>
                               
                               <div style={{ width: '20%' }}>
                                    <label className="row_label">{key}</label>
                               </div>

                                <div key={"default_value" + index} style={{ width: '75%', marginBottom: '1rem' }}>
                                    <SelectOption
                                        selections={dropDowns[key] ? dropDowns[key] : []}
                                        isMultiSelect={true}
                                        isCheckBox={true}
                                        // label={key}
                                        activeValue={filterDefaultValue && filterDefaultValue[key]}
                                        width="100%"
                                        lineHeight="2.857rem"
                                        color='#7c7c7c'
                                        onChangeFunc={(value, type) => {
                                            let clone_filter_Value = filterDefaultValue ? JSON.parse(JSON.stringify(filterDefaultValue)) : {};
                                            let _clone_selectd = clone_filter_Value && clone_filter_Value[key] ? clone_filter_Value[key] : [];
                                            let _index = _clone_selectd ? _clone_selectd.indexOf(value) : undefined;
                                            if (_clone_selectd && _index > -1) {
                                                _clone_selectd.splice(_index, 1)
                                            }
                                            else {
                                                _clone_selectd.push(value)
                                            }
                                            clone_filter_Value[key] = _clone_selectd;
                                            setFilterDefaultValue(clone_filter_Value)
                                        }}
                                    />
                            </div>
                           </div>
                        )
                    }) : <p style={{ margin: 'auto', marginTop: '2rem', textAlign: 'center', color: '#555', fontSize: '.9rem' }}>Please wait while we are fetching your filters</p>}
                </div>

                <Gap h="5rem" />
            </FilterScrollbar>
        </Wrapper>
    )
}


const mapStateToProps = (state) => ({
    dropDowns: state.filterReducer.dropDowns
})

export default connect(mapStateToProps, { getFiltersForDropDowns })(FilterDefaultValue)
