import styled from 'styled-components';

import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils';
const selected = getSelectedTheme();
const { selectBoxConfig } = themes[selected];



export const Wrapper = styled.div`
    position: relative;
    /* height: ${props => props.size === 'small' ? '1.8rem' : '2.5rem'};   */
    display: flex;
    flex-direction: column; 
    width: 100%;


    .mark_icon{
        position: absolute;
        right: 4px;
        top: 10px;
        width: 1rem;
        height: 1rem;
        svg{
            fill: #727c80;
        }
    }
    
    .none_user_input{
        height: 100%;
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        border: 0px;
        border: 0px !important;

        &:focus{
            outline: none;
            border: 0px;
        }
    }
`;


export const SelectLable = styled.div`
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: #000;
    margin-bottom: 5px;
    padding-left: 2px;
    box-shadow: ${props => props.isDropdown ? '0 0 0 0.2rem rgba(0,123,255,.25)' : undefined};
`;

export const SelectBox = styled.div`
    margin-top: auto;
    width: ${props => props.width || '160px'};
    height: 2rem;
    display: flex;
    align-items: center;
    border-radius: 3px;
    position: relative;
    font-size: .9rem;
    font-weight: 500;
    text-decoration: none;
    background-clip: padding-box;
    user-select: none;
    z-index: 3;
    cursor: pointer;
    box-sizing: border-box;
    .select_choose{
        margin-right: 26px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: .9rem;
        font-weight: normal;
        color:  ${props => props.disabled ? "#eaeaea" : '#374a66'};
        line-height: 1.7rem;
        display: flex;
        align-items: center;
    }

    .select_arrow{
        position: absolute;
        right: 10px;
        top: 0rem;
        height: 100%;
        color: #334a53;
        font-size: 1.2rem;
        text-align: center;
        transform: ${props => props.isDropdown ? "rotate(180deg)" : undefined};
        line-height: ${props => props.lineHeight || '2.2rem'};
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;



export const DefaultLabel = styled.div`
        /* position: absolute; */
        z-index: 1;
        transition: 0.2s;
        /* color: ${props => props.isOpen ? selectBoxConfig.color.labelFocus : props.LabelTop ? selectBoxConfig.color.label : selectBoxConfig.color.label}; */
        font-size: .9rem;
        color: #374a66;
        text-transform: capitalize;

        sup{
            color: red;
        }
`;


export const DropDownMenu = styled.div`
    width: 100%;
    background: #fff;
    position: absolute;
    top: 100%;
    left: 0px;
    right: 0px;
    box-shadow: ${props => props.dropdownShadow ? props.dropdownShadow : selectBoxConfig.shadow.dropdownBox};
    z-index: 10;
    
    .text_box_wrapper{
        width: 100%;
        position: relative;

        span{
            position: absolute;
            left: .5rem;
            top: .9rem;
        }

        input{
            width: 100%;
            height: 2.3rem;
            border: 0px;
            border-radius: 3px;
            padding: .9rem 0rem;
            box-sizing: border-box;
            padding-left: 1.8rem;
            font-size: .9rem;
            font-family: 'Lato', sans-serif;
            color: #374a66;
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: #374a66;
                font-size: .9rem;
                font-family: 'Lato', sans-serif;
            }
            &:focus{
                outline: none;
            }
        }
    }
    .scroll_div{
        overflow-y: auto;
        width: 100%;
        max-height: ${props => props.height ? props.height : '10rem'};
        &::-webkit-scrollbar {
            background-color:red;
            width:5px
        }
        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }
        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 5px;
        }
    }

    

    .select_result{
        padding: 3px 11px 5px 10px;
        text-transform: uppercase;
        letter-spacing: .06em;
        font-size: .8rem;
        color: #071c26;
        font-weight: 600;
    }
    .select_result_sub{
        font-size: .9rem;
        font-weight: 600;
        color: #727c80;
        padding: ${props => props.isCheckBox ? "0px" : '0px 1rem'};
        white-space: nowrap;
        line-height: ${props => props.menuLineHeight || '2.2rem'};
        cursor: pointer;
        margin-right: 0px;

        &:hover{
            color: #000;
        }
    }
    .add_btn{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        font-size: 1rem;
        font-weight: 400;
        cursor: pointer;
    }

`;

