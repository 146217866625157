import styled from 'styled-components';

export const Wrapper = styled.div`
    width: '100%';
    .while_loading{
        text-align: center;
        margin-top: 5rem;
        font-weight: 500;
    }
`;





export const DataSynchUpEditorWrapper = styled.div`
    
    width: 100%;
    .add_btn_new_field{
        padding: 5px 9px;
        border-radius: 3px;
        display: inline-block;
        margin-top: 10px;
        box-shadow: rgba(0, 0, 0, 0.53) 0px 0px 2px 0px;
        font-size: .9rem;
        cursor: pointer;
    }

    .fields_item_ul{
        padding: 0px;
        margin: 0px ;
        display: flex;
        flex-direction: column; 
        flex-wrap:wrap;
        width: 100%;
        overflow: auto;
        padding-left: 20px;
        align-content: flex-start;
        max-height: 9rem;
        padding-bottom: 1rem;
        
        &::-webkit-scrollbar {
            background-color:red;
            width:3px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 3px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 3px;
        }

        li{
           margin-right: 50px;
           line-height: 1.71;
           color: #222;
           &:hover{
               .hover_element{
                   opacity: 1;
               }

           }

           .hover_element{
              opacity: 0;
              display: inline-block;
               cursor: pointer;
               vertical-align: middle;
               :hover{
                svg{
                   fill: #76919d;
                 }
               }
           }
            span{
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                box-sizing: border-box;
                padding-right: 15px;
                display: block;
                text-transform: capitalize;

            }
        }
    }


    .field_set{
        width: 100%;
        padding: 0px;
        border: 0px;
        
    }

`;