import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { set_report_to_refresh, save_updated_drill_down_data } from '../../actions/report';
import { connect } from 'react-redux';
import BarChart from '../../components/svg/barChart';


const Wrapper = styled.div`

    width: 150%;
    display: flex;
    align-items: center;

    span{
        font-size: ${props => props.fontSize ? props.fontSize : '.7rem'};
        color: #222;
        font-weight: 500;
        text-transform: capitalize;
        white-space: nowrap;
    }

    .link{
        color: #308bc8;
        cursor: pointer;
        margin-left: 5px;
    }

`;


function SmallBreadCrumb(props) {

    const {  filters, id, getDataFromReportingServer } = props;


    const breadCrumbData = props.drill_down_data_cache?.[id];

    // useEffect(() => {
    //     setLocalBreadCrumbData(breadCrumbData);
    // }, [breadCrumbData])



    const bread_crumb_clicked = (index, type) => {

        let temp_filters = [];
        let previous_drill_down_data = breadCrumbData ? [...breadCrumbData] : [];

        let drill_down_data = undefined;
        const clicked_item = previous_drill_down_data[index];

        if (type === 'home') {
            previous_drill_down_data = [];

            props.save_updated_drill_down_data(id, previous_drill_down_data);

            setTimeout(() => {
                props.set_report_to_refresh(id);
            }, 200);

        }
        else if (clicked_item) {
            // it means we have a comparison

            if (type === 'main') {
                clicked_item.comparison = undefined;
                clicked_item.comparison_value = undefined;
            }
            else {
                //
            }

            previous_drill_down_data.splice(index + 1);

            props.save_updated_drill_down_data(id, previous_drill_down_data);

            setTimeout(() => {
                props.set_report_to_refresh(id);
            }, 200);

        }
    }


    return (
        <Wrapper fontSize={props?.fontSize}>

            {breadCrumbData && breadCrumbData.length > 0 && (
                <span onClick={() => bread_crumb_clicked(-1, 'home')}>
                    <BarChart className="link" size={props?.fontSize || '.8rem'} height={props?.fontSize || '.8rem'} color={'#308bc8'} />&nbsp;&nbsp;
                </span>
            )}

            {breadCrumbData && breadCrumbData.length > 0 && breadCrumbData.map((b, _index_) => {

                let value = '';
                let title = ''
                b?.data && Object.keys(b?.data).map((k) => {
                    if (value) {
                        value += ' And ' + b.data[k]['value']
                        title += " And " + b.data[k]['key'] + "=" + b.data[k]['value'];

                    }
                    else {
                        value += b.data[k]['value'];
                        title += b.data[k]['key'] + "=" + b.data[k]['value'];

                    }
                });

                return (
                    <React.Fragment>
                        <span>&nbsp;<img className="icon" style={{ width: '.5rem' }} src="next.png" />&nbsp;</span>
                        {
                            <span title={title} className={`b_item ${(_index_ < breadCrumbData.length - 1) ? "link" : ''}`} id={':' + _index_} onClick={() => {
                                bread_crumb_clicked(_index_, 'main')
                            }}>{(value || b.value)?.split('__nfx__join__')?.join(' ')}</span>}


                        {_index_ === (breadCrumbData.length - 1) && <span>
                            <span>&nbsp;<img style={{ width: '.5rem' }} src="next.png" />&nbsp;</span>
                            <span className="b_item">{b.to && b.to.split(".").length > 0 ? b.to.split(".")[1] : b.to}</span>
                        </span>}

                    </React.Fragment>
                )
            })}
        </Wrapper>
    )
}









const mapStateToProps = (state, props) => {

    const drill_down_data_cache = state.reportReducer.drill_down_data && state.reportReducer.drill_down_data;

    return { drill_down_data_cache }
}


export default connect(mapStateToProps, { save_updated_drill_down_data, set_report_to_refresh })(SmallBreadCrumb)
