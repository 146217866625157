import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import * as enums from '../../../utils/enums.js';
import { showPopup } from '../../../utils';
import ConfirmBox from '../../confirm-box';
import {
    TableMainDiv, RowDiv, TdDiv, FilterdItemRow, Filterd, ActionBtn,
    TableWrapper
} from './hint-table.styled';
import EditSvg from '../../svg/edit';
import CloseSvg from '../../svg/close';
import RippleEffect from '../../hoc/ripple-effect';
import ViewSvg from '../../svg/view';
import TableDataPopup from '../../table.data.popup';
import { connect } from 'react-redux';
import Checkbox from '../../hoc/checkbox';
import AddDefaultDatePopup from './add.default.date.popup';
import { saveHints, saveTableInfo } from '../../../actions/hints';
import JsonViewer from '../json-viewer';
import ComplexDate from './complex.date';
import Add from '../../svg/add.js';

const HintsTable = (props) => {

    const { _saveTblContent, cached_db_info_id, hintsState, showHintPopup, showContextPopup, _removeHint, showTblContextPopup, activeTable, save, getTableInfoByTblName, tableInfo, saveValueForCache } = props;
    useEffect(() => {
        getTableInfoByTblName(activeTable, cached_db_info_id);
    }, [activeTable])

    const [LocalHintsState, setLocalHintsState] = useState(undefined,)

    useEffect(() => {
        setLocalHintsState(hintsState)
    }, [hintsState])


    const open_table_view_popup = (table_name, db_info_id) => {
        showPopup(undefined, undefined, enums.default.popupType.element, TableDataPopup, {
            tableName: table_name,
            db_info_id: db_info_id
        })
    }

    let how_many_date_column_there = 0;
    LocalHintsState && LocalHintsState.length > 0 && LocalHintsState.forEach((f) => {
        if (f.data_type === 'date') {
            how_many_date_column_there += 1;
        }
    })

    const _local_save = (hints, tbl_info) => {

        props.saveHints(hints, activeTable)
        props.saveTableInfo([tbl_info], activeTable, cached_db_info_id);

    }


    let date_column_len = 0;

    LocalHintsState && LocalHintsState.length > 0 && LocalHintsState.forEach((f) => {
        if (f.data_type === 'date' || f.data_type === 'complex_date') {
            date_column_len += 1
        }
    })

    const change_data_type = (value, index) => {

        const clone_hints = LocalHintsState ? JSON.parse(JSON.stringify(LocalHintsState)) : [];
        const current_hints = clone_hints[index];
        current_hints['data_type'] = value;
        current_hints['modified'] = true;

        const filterd_date_hints = clone_hints && clone_hints.length > 0 && clone_hints.filter((h) => {
            if (h.data_type === 'date' || h.data_type === 'complex_date') return true;
            else return false;
        }) || [];

        showPopup(undefined, undefined, enums.default.popupType.element, AddDefaultDatePopup, {
            hintData: filterd_date_hints,
            tableInfo: props.tableInfo,
            activeTable: activeTable,
            _save: _local_save
        })
    }

    const open_complex_date_popup = (value, index) => {

        const clone_hints = LocalHintsState ? JSON.parse(JSON.stringify(LocalHintsState)) : [];
        const current_hints = clone_hints[index];
        current_hints['data_type'] = value;
        current_hints['modified'] = true;

        showPopup(undefined, undefined, enums.default.popupType.element, ComplexDate, {
            save: save,
            tableInfo: props.tableInfo,
            hints: current_hints
        })
    }



    const db_info_id = cached_db_info_id //hintsState?.length > 0 && hintsState[0]?.db_info_id


    return (
        <TableMainDiv>
            <RowDiv
                className="sticky_top"
                style={{ paddingBottom: '0px', paddingTop: '0px' }}
                hover={false}
            >
                <TdDiv className="table_name">{activeTable?.split("__db__id__")?.[0] || ''}
                    <div style={{ marginLeft: '1rem', marginTop: '.1rem' }}>
                        <RippleEffect
                            title="View Table Data"
                            icon={<ViewSvg size="1rem" height="1rem" color='#fff' />}
                            Click={() => {
                                open_table_view_popup(activeTable, db_info_id)

                            }}
                        />
                    </div>
                    {props.tableInfo && props.tableInfo.context ? <span className="tbl_context" title={props.tableInfo.context}> {props.tableInfo.context} </span> : null}
                </TdDiv>
                <TdDiv className="table_name" style={{ justifyContent: 'flex-end' }} >
                    {(props.tableInfo && Object.keys(props.tableInfo).length > 1) ?
                        <ActionBtn
                            hoverbgColor={"#eaeaea"}
                            hoverColor={"#161616"}
                            title='Edit Context'
                            bgColor='#fff'
                            bdrRadius='10px'
                            color={'#000'}
                            onClick={() => showTblContextPopup(tableInfo, activeTable)}>
                            <EditSvg
                                height="0.857rem"
                                size="0.857rem"
                                color={'#ccc'}
                            />
                            <span style={{
                                fontSize: '0.857rem',
                                color: '#000'
                            }}>Edit Context</span>
                        </ActionBtn>

                        : <ActionBtn
                            hhoverbgColor={"#eaeaea"}
                            hoverColor={"#161616"}
                            bgColor='#fff'
                            color='#000000'
                            title='Add Context'
                            bdrRadius='10px'
                            onClick={() => showTblContextPopup(tableInfo, activeTable)}>
                            {/* <i className="fas fa-plus"></i> */}
                            <span style={{
                                fontSize: '0.857rem'
                            }}>
                                <Add size={'0.8rem'} height={'0.8rem'} />Add Context</span>
                        </ActionBtn>
                    }

                </TdDiv>
            </RowDiv>
            <TableWrapper>
                <thead>
                    <tr>
                        <td style={{ maxWidth: '10rem' }}>Column Name</td>
                        <td style={{ maxWidth: '60%' }}>Hints</td>
                        <td>Context</td>
                    </tr>
                </thead>

                <tbody>
                    {
                        LocalHintsState && LocalHintsState.length > 0 && LocalHintsState.filter(hintData => !hintData.deleted).map((hintData, index) => {
                            return (
                                <tr>
                                    <td>
                                        {(hintData && hintData.column_name) ?
                                            typeof hintData.column_name === 'string' ?
                                                hintData.column_name
                                                :
                                                hintData.column_name
                                            :
                                            ''
                                        }
                                    </td>

                                    <td>
                                        <FilterdItemRow>
                                            {hintData.values && hintData.values.length > 0 && hintData.values.map((value, index) => {
                                                return (
                                                    <Filterd key={index} title={value}>
                                                        <div className="filter_name" title={value}>{value}</div>
                                                        <div
                                                            className="cross_mark"
                                                            onClick={() => {
                                                                showPopup(undefined, 'Are you sure you want to delete this ? ', enums.default.popupType.element, ConfirmBox, { func: { setYes: () => _removeHint(value, hintData.id) } })
                                                            }}
                                                        >
                                                            <CloseSvg size="8px" height="8px" color="#989898" />
                                                        </div>
                                                    </Filterd>
                                                )
                                            })}
                                        </FilterdItemRow>
                                    </td>

                                    <td>
                                        {hintData.context}
                                        <div className="action_on_hover">
                                            <div className='cache_the_value' onClick={() => {
                                                saveValueForCache(hintData)
                                            }}>
                                                <input checked={hintData?.isCached || false} type="checkbox" /> &nbsp;&nbsp;
                                                <span>Remember it</span>
                                            </div>
                                            {hintData.values && hintData.values.length > 0 ?
                                                <ActionBtn
                                                    title={'Edit Hint'}
                                                    onClick={() => showHintPopup(hintData)}>

                                                    <EditSvg
                                                        height="0.857rem"
                                                        size="0.857rem"
                                                        color="#222"
                                                    />
                                                    <span style={{
                                                        fontSize: '0.857rem'
                                                    }}>Edit Hint</span>
                                                </ActionBtn>

                                                :
                                                <ActionBtn
                                                    style={{ marginRight: '1rem' }}
                                                    title='Add Hint'
                                                    onClick={() => showHintPopup(hintData)}>

                                                    <i className="fas fa-plus"></i>
                                                    <span style={{
                                                        fontSize: '0.857rem'
                                                    }}>Add Hint</span>
                                                </ActionBtn>
                                            }



                                            {hintData.context && hintData.context.length > 0 ?

                                                <ActionBtn
                                                    style={{ marginRight: '1rem' }}
                                                    title={hintData.context ? hintData.context : 'Edit Context'}
                                                    onClick={() => showContextPopup(hintData)}>
                                                    <EditSvg
                                                        height="0.857rem"
                                                        size="0.857rem"
                                                        color="#222"
                                                    />
                                                    <span style={{
                                                        fontSize: '0.857rem'
                                                    }}>Edit Context</span>
                                                </ActionBtn>

                                                :
                                                <ActionBtn
                                                    style={{ marginRight: '1rem' }}
                                                    title='Add Context'
                                                    onClick={() => showContextPopup(hintData)}>
                                                    <i className="fas fa-plus"></i>
                                                    <span style={{
                                                        fontSize: '0.857rem'
                                                    }}>Add Context</span>
                                                </ActionBtn>
                                            }
                                            <select
                                                onChange={(event) => {
                                                    const tempHintData = hintData ? JSON.parse(JSON.stringify(hintData)) : {};
                                                    const value = event.target.value;
                                                    tempHintData.modified = true;
                                                    if (value === 'epoch') {
                                                        tempHintData.is_epoch = true;
                                                        tempHintData.data_type = 'epoch';
                                                    }
                                                    else {
                                                        tempHintData.is_epoch = false;
                                                        tempHintData.data_type = value;
                                                    }
                                                    // if ((value === 'date' || value === 'complex_date') && date_column_len >= 1) {
                                                    //     change_data_type(value, index)
                                                    // }
                                                    // else if (value === 'complex_date') {
                                                    //     open_complex_date_popup(value, index)
                                                    // }
                                                    // else {
                                                        save(tempHintData);
                                                    // }
                                                }}
                                                value={hintData.data_type}
                                                style={{
                                                    padding: '3px 5px',
                                                    boxSizing: 'border-box',
                                                    border: '1px solid #e1e1e1',
                                                    background: 'white',
                                                    borderRadius: '2px',
                                                    outline: 'none'
                                                }}
                                            >
                                                <option value=''>--Select--</option>
                                                <option value='string'>String</option>
                                                <option value='double'>Double</option>
                                                <option value='number'>Number</option>
                                                <option value='int'>Int</option>
                                                <option value='currency'>Currency</option>
                                                <option value='epoch'>Epoch</option>
                                                <option value='date'>Date</option>
                                                <option value='yyyymmdd'>YYYY-MM-DD</option>
                                                <option value='seconds'>seconds</option>
                                                <option value='complex_date'>Complex Date</option>
                                            </select>
                                        </div>
                                    </td>

                                </tr>
                            )
                        })
                    }

                </tbody>
            </TableWrapper>
        </TableMainDiv>
    )
}











const mapStateToProps = (state) => ({
    tableInfo: state.hintsReducer.tableInfo
})

export default connect(mapStateToProps, { saveHints, saveTableInfo })(HintsTable);
