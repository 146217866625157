import React, { memo } from "react";
import styled from "styled-components";
import { is_date } from "../../../utils";

const TooltipContainer = styled.div`
  border: 1px solid #e2e1e1;
  border-radius: 6px;
  background: white;
  font-size: .9rem;
  padding: 2px 6px;
  max-width: 200px;
  min-width: 200px;
  position: fixed;
  p{
    /* margin: 5px;; */
    font-size: .9rem !important;
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0px;

  }

  .row{
    margin-bottom: 10px;
  }
`;



const CustomTooltip = memo(({
  task,
  clientX,
  clientY,
  category,
  taskNameKey,
  yDataKeys,
  xDataKeys,
  planStartDateKey,
  planEndDateKey,
  startDateKey,
  endDateKey
}) => {


  return (
    <TooltipContainer style={{
      top: clientY + "px",
      left: clientX + "px"
    }}>

      {/* {category && <p className="no_wrap"><strong>{category}:  {task?.category}</strong></p>} */}

      {category && (
        <div className="row">
          <p>{taskNameKey}</p>
          <p><strong>{task?.category}</strong></p>
        </div>
      )}
      <div className="row">
        <p>{taskNameKey || "Task Name"}</p>
        <p><strong>{task?.name}</strong></p>
      </div>


      {task?.key === "actual" && <>
        <div className="row">
          <p>{startDateKey}</p>
          <p><strong>{task?.['start']?.toLocaleDateString()}</strong></p>
        </div>


        <div className="row">
          <p>{endDateKey}</p>
          <p><strong>{task?.['end']?.toLocaleDateString()}</strong></p>
        </div>
      </>}


      {task?.key === "plan" && (
        <>
          <div className="row">
            <p>{planStartDateKey}</p>
            <p><strong>{task?.['start']?.toLocaleDateString()}</strong></p>
          </div>


          <div className="row">
            <p>{planEndDateKey}</p>
            <p><strong>{task?.['end']?.toLocaleDateString()}</strong></p>
          </div>
        </>
      )}


      {yDataKeys.map((y) => {
        return (
          <div className="row">
            <p>{y}</p>
            <p><strong>{task?.[y]}</strong></p>
          </div>

        )
      })}
    </TooltipContainer>
  );
});

export default CustomTooltip;