import * as actions from '../action-types/error';

const initialState = {
    type: actions.ERROR_INIT,
    errors: [],
    key: undefined,
    dashboard_errors: {},
    report_errors: {},
};


const errorReducer = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    // if (action && action.data && typeof action.data === 'object') {
    //     action.data.key = new Date();
    // }

    switch (action.type) {
        case actions.ERROR_RAISE:
            const errors = JSON.parse(JSON.stringify(state.errors));

            errors.push({ key: action.data.indexKey, message: action.error.message, stack: action.error.stack });

            return {
                type: state.type,
                errors,
            };

        case actions.ERROR_HIDE:
            /* hide only the error matched with the key*/
            const _errors = JSON.parse(JSON.stringify(state.errors));
            const errorIndex = _errors.findIndex(error => error.key === action.data.indexKey);

            if (errorIndex > -1) {
                _errors.splice(errorIndex, 1);
            }

            return {
                type: state.type,
                errors: _errors,
                key: undefined
            };

        case actions.RAISE_DASHBOARD_ERROR:

            const clone_dashboard_errors = state.dashboard_errors ? JSON.parse(JSON.stringify(state.dashboard_errors)) : {};

            if (!clone_dashboard_errors[action.data.id]) clone_dashboard_errors[action.data.id] = ''
            clone_dashboard_errors[action.data.id] = action?.data?.error?.message || 'something went wrong'

            return {
                ...state,
                dashboard_errors: clone_dashboard_errors,
                type: action.type,

            }

        case actions.DELETE_DASHBOARD_ERROR:

            const clone_dashboard_errors1 = state.dashboard_errors ? JSON.parse(JSON.stringify(state.dashboard_errors)) : {};
            delete clone_dashboard_errors1[action.data.id];

            return {
                ...state,
                dashboard_errors: clone_dashboard_errors1,
                type: action.type,
            }


            case actions.RAISE_REPORT_ERROR:

            const clone_report_error = state.report_errors ? JSON.parse(JSON.stringify(state.report_errors)) : {};
            if (!clone_report_error[action.data.id]) clone_report_error[action.data.id] = ''
            clone_report_error[action.data.id] = action?.data?.error?.message || 'something went wrong'

            return {
                ...state,
                report_errors: clone_report_error,
                type: action.type,

            }

        case actions.DELETE_DASHBOARD_ERROR:

            const clone_report_error1 = state.report_errors ? JSON.parse(JSON.stringify(state.report_errors)) : {};
            delete clone_report_error1[action.data.id];

            return {
                ...state,
                report_errors: clone_report_error1,
                type: action.type,
            }

        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
            return {
                ...state,
                dashboard_errors: state.dashboard_errors,
                type: state.type,
                errors: state.errors,
                key: state.key
            };
    }
};

export default errorReducer;