
import React, { useState, useEffect, useRef } from 'react'
import { Wrapper } from './date-time-piker.styled';
import { convertDateInToDDMMYYYHHMM } from '../../../utils';
import CalenderSvg from '../../svg/calander'
import styled from 'styled-components';



/***********
 * This is our New Version of DateTimePicker
 * Author: saroj kumar
 * Date: June 2022
 * Organization: NewFangled Vision Pvt Ltd
 * 
 * 
 * accept props => 
 * 1. onChange => function
 * 2. value => date or date-time
 * 3. isTime => boolean 
 * 
 * 
 */

const OverlayMain = styled.div`
    width: 100vw;
    height: 100vh;
    z-index: 1200;
    position: fixed;
    top: 0px;
    left: 0px;
    background: #ff000000;
`;




const Index = (props) => {

    const { value, onChange, isTime, label, zIndex, labelStyle, defaultValue, bgColor, maxDate, minDate } = props;

    let d = value ? new Date(value) : new Date();
    let currentDateTimestamp = d.getFullYear() + '-' + d.getMonth() + '-' + d.getDate();
    let todayDate = new Date();
    let todayTimestamp = todayDate.getFullYear() + '-' + todayDate.getMonth() + '-' + todayDate.getDate();

    const [showDatePicker, setShowDataPicker] = useState(undefined);
    const [minuteArray, setMinuteArray] = useState(undefined);
    const [hoursArray, setHoursArray] = useState(undefined);
    const [monthDetails, setMonthDetails] = useState(undefined);
    const [selectedDay, setSelectedDay] = useState(undefined);
    const [currentHour, setCurrentHour] = useState(undefined);
    const [currentMinute, setCurrentMinute] = useState(undefined);
    const [currentYear, setCurrentYear] = useState(undefined);
    const [currentMonth, setCurrentMonth] = useState(undefined);
    const [currentDispayDate, setCurrentDispayDate] = useState(undefined);
    const [cuurentFullDate, setCurrentFullDate] = useState(undefined);
    const [showYearPicker, setShowYearPicker] = useState(undefined);
    const [yearsDetails, setYearsDetails] = useState(undefined);
    const [extraHeight, setExtraHeight] = useState(0);
    const [legendPosition, setLegendPosition] = useState('down');


    const [dropdown_position, set_dropdown_position] = useState(undefined);
    const [focused, set_focused] = useState(undefined);



    const sizeRef = useRef(null);


    const year_elements = useRef([]);
    year_elements.current = Array(200).fill().map((_, i) => year_elements.current[i] || React.createRef());


    const minutes_ref = useRef([]);
    minutes_ref.current = Array(59).fill().map((_, i) => minutes_ref.current[i] || React.createRef());


    const hours_ref = useRef([]);
    hours_ref.current = Array(59).fill().map((_, i) => hours_ref.current[i] || React.createRef());


    // useEffect(() => {
    //     if (sizeRef && showDatePicker) {
    //         const windowHeight = window.innerHeight;
    //         const elementData = sizeRef.current.getBoundingClientRect();
    //         const { height, y } = elementData;

    //         if ((parseInt(windowHeight) - parseInt(y)) < parseInt(height)) {
    //             setLegendPosition('up');
    //         }
    //     }
    // }, [showDatePicker]);



    useEffect(() => {
        if (value) {
            const d = new Date(value);
            let _mm = d.getMinutes();
            let _hh = d.getHours();
            setCurrentHour(_hh);
            setCurrentMinute(_mm)
            setCurrentFullDate(value)
            setSelectedDay(currentDateTimestamp)

        }
    }, [value])



    // let's create range
    useEffect(() => {

        let year = d.getFullYear();
        let month = d.getMonth();

        console.log("getMonthDetails(year, month)", getMonthDetails(year, month))
        setMonthDetails(getMonthDetails(year, month))
        setCurrentMonth(month)
        setCurrentYear(year);

        fillRange(0, 59, 'minutes')
        fillRange(0, 23, 'hours')

    }, [])





    const scrollToView = (refElement, index) => {
        const element = refElement.current[index];
        if (element && element.current) {
            element.current.scrollIntoView({ behavior: 'smooth', block: "center" });
        }
    };

    const scrollToView_new = (refElement, index) => {
        const element = refElement.current[index];

        if (element && element.current) {
            element.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }
    };

    const fillRange = (start, end, type) => {
        let arrayValue = Array(end - start + 1).fill().map((item, index) => start + index);
        if (type === 'hours') {
            setHoursArray(arrayValue)
        }
        if (type === 'minutes') {
            setMinuteArray(arrayValue)
        }
    };


    /**
    *  Core
    */


    let daysMap = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    let monthMap = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];


    if (process.env.REACT_APP_MODE === "barc") {
        daysMap = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    }


    // here we are genrating years for drowpdown 

    const generateYearsArray = () => {
        let _current = parseInt(currentYear);
        let previousYears = [];
        let upcomingYears = [];
        for (let index = 100; index > 0; index--) {
            let year = _current - index;
            previousYears.push(year);
        }
        for (let index = 0; index < 100; index++) {
            let year = _current + index;
            upcomingYears.push(year);
        }
        let meargYears = previousYears.concat(upcomingYears);
        setYearsDetails(meargYears)
    }
    useEffect(() => {
        if (yearsDetails && yearsDetails.length > 0) scrollToView(year_elements, 100)
    }, [yearsDetails])



    const getDayDetails = args => {
        let date = args.index - args.firstDay;
        let day = args.index % 7;
        let prevMonth = args.month - 1;
        let prevYear = args.year;
        if (prevMonth < 0) {
            prevMonth = 11;
            prevYear--;
        }
        // getDayDetails
        let prevMonthNumberOfDays = getNumberOfDays(prevYear, prevMonth);
        let _date = (date < 0 ? prevMonthNumberOfDays + date : date % args.numberOfDays) + 1;
        let month = date < 0 ? -1 : date >= args.numberOfDays ? 1 : 0;
        let timestamp = new Date(args.year, args.month, _date).getTime();

        return {
            index: args.index,
            date: _date,
            day,
            month,
            timestamp,
            dayString: daysMap[day],
            is_holiday: daysMap[day] === "Sunday" || daysMap[day] === "Saturday",
            fullDays: args.year + "-" + args.month + "-" + _date
        }
    }

    const getDayDetails2 = args => {
        let date = args.index - args.firstDay + 1; // Adjust for starting day offset and add 1 to align with calendar dates
        let day = (args.index + args.firstDay) % 7; // Adjust for starting day offset
        let prevMonth = args.month - 1;
        let prevYear = args.year;

        if (prevMonth < 0) {
            prevMonth = 11;
            prevYear--;
        }

        let prevMonthNumberOfDays = getNumberOfDays(prevYear, prevMonth);
        let timestamp;

        if (date <= 0) {
            // Previous month's days
            date = prevMonthNumberOfDays + date;
            timestamp = new Date(prevYear, prevMonth, date).getTime();
        } else if (date > args.numberOfDays) {
            // Next month's days
            date -= args.numberOfDays;
            timestamp = new Date(args.year, args.month + 1, date).getTime();
        } else {
            // Current month's days
            timestamp = new Date(args.year, args.month, date).getTime();
        }

        return {
            date,
            day,
            month: args.month,
            timestamp,
            dayString: daysMap[day],
            fullDays: args.year + "-" + (args.month + 1) + "-" + date // Adjust month index by adding 1
        };
    };


    const getNumberOfDays = (year, month) => {
        return 40 - new Date(year, month, 40).getDate();
    }

    const getMonthDetails1 = (year, month) => {
        let firstDay = (new Date(year, month))?.getDay();
        let numberOfDays = getNumberOfDays(year, month);
        let monthArray = [];
        let rows = 6;
        let currentDay = null;
        let index = 0;
        let cols = 7;

        for (let row = 0; row < rows; row++) {
            for (let col = 0; col < cols; col++) {
                currentDay = getDayDetails({
                    index,
                    numberOfDays,
                    firstDay,
                    year,
                    month
                });
                monthArray.push(currentDay);
                index++;
            }
        }

        // console.log("monthArray", monthArray)
        return monthArray;
    }

    const getMonthDetails = (year, month) => {

        let firstDay = (new Date(year, month))?.getDay();

        // firstDay = (firstDay === 0) ? 6 : firstDay - 1;
        if (process.env.REACT_APP_MODE === "barc") {
            firstDay = (firstDay + 1) % 7;
        }

        let numberOfDays = getNumberOfDays(year, month);
        let monthArray = [];
        let rows = 6;
        let currentDay = null;
        let index = 0;
        let cols = 7;

        for (let row = 0; row < rows; row++) {
            for (let col = 0; col < cols; col++) {
                currentDay = getDayDetails({
                    index,
                    numberOfDays,
                    firstDay,
                    year,
                    month
                });
                monthArray.push(currentDay);
                index++;
            }
        }
        // console.log("monthArray",  monthArray)

        return monthArray;
    }




    const isCurrentDay = day => {
        return day.fullDays === todayTimestamp;
    }

    const isSelectedDay = day => {
        return day.fullDays === selectedDay;
    }

    const getDateFromDateString = dateValue => {
        let dateData = dateValue.split('-').map(d => parseInt(d, 10));
        if (dateData.length < 3)
            return null;

        let year = dateData[0];
        let month = dateData[1];
        let date = dateData[2];
        return { year, month, date };
    }



    const getMonthStr = month => monthMap[Math.max(Math.min(11, month), 0)] || 'Month';

    const getDateStringFromTimestamp = timestamp => {
        let dateObject = new Date(timestamp);
        let month = dateObject.getMonth() + 1;
        let date = dateObject.getDate();
        return dateObject.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date);
    }




    const OnClanderClick = (_date) => {

        let string_date = getDateStringFromTimestamp(_date);

        const { year, month, date } = getDateFromDateString(string_date)
        let temp_month = month;
        let _display_date = string_date + ',';
        setCurrentFullDate(_display_date);
        let d = new Date();
        d.setYear(year);
        d.setMonth((temp_month));
        d.setDate(date);
        // onChange(d);
    }


    function isDateInRange(selectedDate, minDate, maxDate) {
        // Parse the input values as Date objects
        selectedDate = new Date(selectedDate);
        minDate = new Date(minDate);
        maxDate = new Date(maxDate);

        // Check if the selected date is within the range
        return selectedDate >= minDate && selectedDate <= maxDate;
    }


    const onDateClick = (day, hh, mm) => {

        let string_date = day ? getDateStringFromTimestamp(day.timestamp) : getDateStringFromTimestamp(selectedDay);

        const { year, month, date } = getDateFromDateString(string_date)
        let temp_month = month;
        let _hh = hh > -1 ? hh : currentHour;
        let _mm = mm > -1 ? mm : currentMinute;
        let hour = _hh > 9 ? _hh : _hh ? ('0' + _hh) : 0;
        let minute = _mm > 9 ? _mm : _mm ? ('0' + _mm) : 0;

        if (day) {
            temp_month = month - 1
            setSelectedDay(day.timestamp)
        }

        let _display_date = string_date + ',' + `${isTime ? hour + ':' + minute : ''}`;

        let d = new Date();
        d.setYear(year);
        d.setMonth(temp_month, 1);
        d.setDate(date);
        if (isTime) {
            d.setHours(hour);
            d.setMinutes(minute)
        }

        if (minDate && maxDate) {
            if (isDateInRange(d, minDate, maxDate)) {
                setCurrentFullDate(_display_date);
                onChange(d);
            } else {
                alert("not valid date")

            }
        } else {
            setCurrentFullDate(_display_date);
            onChange(d);
        }

    }



    const setYear = offset => {
        let year = currentYear + offset;
        let month = currentMonth;
        setCurrentYear(year)
        let _monthDetails = getMonthDetails(year, month)
        // console.log("")
        setMonthDetails(_monthDetails)
    }

    const setMonth = offset => {
        let year = currentYear;
        let month = currentMonth + offset;
        if (month === -1) {
            month = 11;
            year--;
        } else if (month === 12) {
            month = 0;
            year++;
        }

        setCurrentYear(year)
        setCurrentMonth(month)
        let _monthDetails = getMonthDetails(year, month)
        setMonthDetails(_monthDetails)
    }



    /**
     *  Renderers Calendar 
     */
    // is_holiday
    const renderCalendar = () => {
        let days = monthDetails && monthDetails.length > 0 && monthDetails.map((day, index) => {
            return (
                <div className={'c-day-container ' + (day.month !== 0 ? ' disabled' : '') +
                    (isCurrentDay(day) ? ' highlight' : '') + (isSelectedDay(day) ? ' highlight-green' : '')} key={index}>
                    <div className='cdc-day'>
                        <span onClick={() => onDateClick(day)}>
                            {day.date}
                        </span>
                    </div>
                </div>
            )
        })

        return (
            <div className='c-container'>
                <div className='cc-head'>
                    {
                        process.env.REACT_APP_MODE === "barc" ?
                            <>
                                {['SAT', 'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI'].map((d, i) => <div key={i} className='cch-name'>{d}</div>)}</>
                            :

                            <>
                                {['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map((d, i) => <div key={i} className='cch-name'>{d}</div>)}
                            </>
                    }


                </div>
                <div className='cc-body'>
                    {days}
                </div>
            </div>
        )
    }


    /**
     * Render Minutes
     */

    const renderHours = () => {
        return (
            <div className='cc-hours'>
                <div className='cch-title'>HH</div>
                {hoursArray && hoursArray.length > 0 && hoursArray.map((d, i) => <div key={i} onClick={(e) => {
                    e.stopPropagation();
                    setCurrentHour(d)
                    onDateClick(undefined, d, undefined)
                }} ref={hours_ref.current[i]} className={'cch-hour ' + (currentHour == d ? "highlight-green_active" : 'hi')}>
                    {d > 9 ? d : ('0' + d)}
                </div>)}
            </div>
        )
    }


    /**
* Render Seconds
*/

    const renderMinutes = () => {
        return (
            <div className='cc-hours'>
                <div className='cch-title'>MM</div>
                {minuteArray && minuteArray.length > 0 && minuteArray.map((d, i) => <div onClick={(e) => {
                    e.stopPropagation();
                    setCurrentMinute(d)
                    onDateClick(undefined, undefined, d)

                }} key={i} ref={minutes_ref.current[i]} className={'cch-hour ' + (currentMinute == d ? "highlight-green_active" : 'hi')}>
                    {d > 9 ? d : ('0' + d)}
                </div>)}
            </div>
        )
    }



    /**
     * Render Years
     */
    const renderYearsDropdown = () => {
        return (
            <div className='mdpch-year'>
                {yearsDetails && yearsDetails.length > 0 && yearsDetails.map((year, i) =>
                    <div
                        ref={year_elements.current[i]}
                        onClick={(e) => {
                            e.stopPropagation();
                            let temp_year = currentYear > year ? (currentYear - year) : year - (currentYear);
                            let final_year = currentYear > year ? '-' + temp_year : '+' + temp_year;
                            setYear(parseInt(final_year))
                            let _current_day = selectedDay && selectedDay.length > 0 ? getDateFromDateString(selectedDay) : [];
                            let { month, date } = _current_day;
                            let final_selected_date = year + '-' + month + '-' + date;
                            setSelectedDay(final_selected_date)
                            // OnClanderClick(final_selected_date)
                        }} className={"mdpch-name" + " " + (currentYear == year ? "highlight-green_active" : "hi")} >{year}</div>)}
            </div>
        )
    }



    /***
     * Render Months
     */
    const renderMonthsPicker = () => {
        return (
            <div className='mdpch-month'>
                {monthMap && monthMap.length > 0 && monthMap.map((month, i) =>
                    <div
                        // ref={year_elements.current[i]}
                        onClick={(e) => {
                            e.stopPropagation();
                            let temp_month = currentMonth > i ? (currentMonth - i) : (i - (currentMonth));
                            let final_month = currentMonth > i ? '-' + temp_month : '+' + temp_month;
                            setMonth(parseInt(final_month))
                            setShowYearPicker(false)
                            let _current_day = selectedDay && selectedDay.length > 0 ? getDateFromDateString(selectedDay) : [];
                            let { year, date } = _current_day;
                            let final_selected_date = year + '-' + i + '-' + date;
                            setSelectedDay(final_selected_date)

                        }} className={"mdpch-month-name" + " " + (currentMonth == i ? "highlight-green_active" : "hi")}>{month && month.length > 3 ? month.substring(0, 3) : month}</div>)}
            </div>
        )
    }


    const el_id = "input__id__uniqqq_auto_gen" + Math.random(100);

    const open_dropdown_menu = (e) => {
        // set_focused(true);
        const dropdown_height = 180;
        const element = document.getElementById(el_id);
        const el_bounding = element.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const element_height = el_bounding?.height;
        const element_left = el_bounding?.left;
        const element_top = el_bounding?.top;
        let drop_down_top_to_use = (element_top + element_height);
        if ((windowHeight - drop_down_top_to_use) <= dropdown_height) {
            drop_down_top_to_use = ((element_top + element_height) - dropdown_height) - 10
        }
        set_dropdown_position({
            top: drop_down_top_to_use,
            left: element_left
        })
        setShowDataPicker(!showDatePicker)
        // close_dropdown(!open_menu)
    }



    // console.log("=============", value, d)
    return (
        <React.Fragment>
            {/* {
                showDatePicker &&
                <OverLay onClick={() => {
                    setShowDataPicker(false)
                }} />
            } */}
            <Wrapper zIndex={zIndex} bgColor={bgColor}>
                {label ? <label style={labelStyle ? labelStyle : undefined}>{label ? label : undefined}</label> : null}
                <div id={el_id} className='mdp-input' onClick={(e) => {
                    // setShowDataPicker(!showDatePicker)
                    e.preventDefault();
                    e.stopPropagation();
                    open_dropdown_menu(e)


                }}>
                    <p>{cuurentFullDate ? convertDateInToDDMMYYYHHMM(cuurentFullDate, isTime) : `DD MM YYYY${isTime ? ' , HH:MM' : ''}`}</p>
                    <input onBlur={() => {
                        setShowDataPicker(false)
                    }} style={{ width: '0px', opacity: '0' }} />
                    <CalenderSvg size="1rem" height="1rem" color="#555" />
                </div>

                {showDatePicker && (
                    <OverlayMain onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation()
                        setShowDataPicker(false)
                    }}>
                        <div
                            ref={sizeRef}
                            style={{ top: (dropdown_position?.top + "px"), left: (dropdown_position?.left + "px") }}
                            className='mdp-container'>
                            <div className='mdp-flex_row'>
                                <div >
                                    <div className='mdpc-head'>
                                        {/* <div className='mdpch-button'> */}
                                        <div className='mdpchb-inner' onClick={(e) => {
                                            e.stopPropagation();
                                            setYear(-1)
                                        }}>
                                            <span className='mdpchbi-left-arrows'></span>
                                        </div>
                                        {/* </div> */}
                                        {/* <div className='mdpch-button'> */}
                                        <div className='mdpchb-inner' onClick={(e) => {
                                            e.stopPropagation();
                                            setMonth(-1)
                                        }}>
                                            <span className='mdpchbi-left-arrow'></span>
                                        </div>
                                        {/* </div> */}
                                        <div className='mdpch-container'>
                                            <div className='mdpchc-year' onClick={(e) => {
                                                e.stopPropagation();
                                                setShowYearPicker(true);
                                                generateYearsArray()
                                            }}>{currentYear}</div>
                                            <div className='mdpchc-month'>{getMonthStr(currentMonth)}</div>

                                        </div>
                                        {/* <div className='mdpch-button'> */}
                                        <div className='mdpchb-inner' onClick={(e) => {
                                            e.stopPropagation();
                                            setMonth(1)
                                        }
                                        }
                                        >
                                            <span className='mdpchbi-right-arrow'></span>
                                        </div>
                                        {/* </div> */}
                                        {/* <div className='mdpch-button' > */}
                                        <div className='mdpchb-inner' onClick={(e) => {
                                            e.stopPropagation()
                                            setYear(1)

                                        }}>
                                            <span className='mdpchbi-right-arrows'></span>
                                        </div>
                                        {/* </div> */}
                                    </div>
                                    {showYearPicker && (
                                        <div className='mdpch-dropdown'>
                                            {renderYearsDropdown()}
                                            {renderMonthsPicker()}
                                        </div>
                                    )}
                                    <div className='mdpc-body'>
                                        {renderCalendar()}
                                    </div>
                                </div>
                                {isTime && (
                                    <React.Fragment>
                                        {renderHours()}
                                        {renderMinutes()}
                                    </React.Fragment>
                                )}

                            </div>
                        </div>
                    </OverlayMain >
                )}
            </Wrapper>
        </React.Fragment>
    )
}

export default Index;
