/**
 * Vikas Bhandari
 * 27-06-2022. Right now, we contain a copy of keys for example
 * {
 * _nf_Group_nf_Count1_nf_Month_nf_December_nf_Payment Mode_nf_Cash: [ 21, 22, 23]
 * _nf_Group_nf_Count1_nf_Month_nf_December_nf_Payment Mode_nf_Check: [ 24, 20, 28]
 * }
 * 
 * now that is a lot of repeat. we will keep it secretly so the index maker doesn't have to keep the repeated data
 * 
 * 
 */

/**
 * 
 * @param {*} values values will always be like this:
 */
export const create_xac_index_keys = (xac_index, xac, value, prev_keeper_object = {}) => {

    let start_index = 0;

    if (prev_keeper_object && prev_keeper_object[xac]) {
        start_index = parseInt(((prev_keeper_object[xac].index_already_used || 0)));
    }


    let key = xac_index + '-';
    const new_keeper = Object.assign({}, prev_keeper_object);

    if (!new_keeper[xac]) {
            start_index++;
            new_keeper[xac] = {
            index_already_used: start_index,
            keys_already_used: {},
            keepers: {}
         };
    }

    if (!new_keeper[xac].keys_already_used[value]) {
        new_keeper[xac].keys_already_used[value] = 1;
        start_index++;
        key = key + (start_index);
        new_keeper[xac].keepers[key] = { xac, value };
        new_keeper[xac].keepers[value] = key;
        new_keeper[xac].index_already_used = start_index;
    }
    else {
        // already added
        key = new_keeper[xac].keepers[value];
    }


    return  { new_keeper, key} ;
};