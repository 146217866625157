import React from 'react';
import styled from 'styled-components';
import CheckMarkSvg from '../svg/correct'


const Wrapper = styled.div`

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px 1rem;
    box-sizing: border-box;
    padding-bottom: 1rem;
    background: #fff;
    width: 23rem;
    border-radius: 5px;
    text-align: center;

    .success_icon{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-top: -60px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn{
        width: 100%;
        height: 2.2rem;
        background: #008AC7;
        border: 0px;
        color: #fff;
        font-weight: 700;
        border-radius: 6px;
        text-align: center;
        font-size: 1rem;
        cursor: pointer;
        margin-top: 15px;
    }
    h1, p{
        margin: 15px 0px;
    }
    p{
        color: #222;
    }

`;

const SuccessPopup = (props) => {

    const {
        popupKey,
        close,
        option,
        title,
        message,
        icon
    } = props;

    return (
        <Wrapper>
            <div className="success_icon">
                <CheckMarkSvg size="6rem" height="6rem" color="#008AC7" />
            </div>
            <h1>{title || "Awesome !"}</h1>
            <p>{message || "Your dashboard is ready."}</p>
            <button className='btn' onClick={() => {
                close(option, popupKey)
            }}>Got it</button>
        </Wrapper>
    )
}

export default SuccessPopup