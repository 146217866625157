import {  simpleDispatch_get, simpleDispatch_post } from './helper';
import { constants } from '../utils/constants';
import * as actionTypes from '../action-types/new-outler'




 export const getOutliearData = (id) => async dispatch => {

     const url = constants.END_POINTS.API + constants.END_POINTS.OUTLIER.POINT + constants.END_POINTS.OUTLIER.GET_OUTLIER_RESULT
     await simpleDispatch_get(dispatch, url, actionTypes.GET_OUTLIERS_RESULT);
 };

// export const getOutliearData = (id) => async dispatch => {
        
//         await simpleDispatch_get(dispatch, 'http://127.0.0.1:29302/api/v1/nf_grammar/get_nf_grammar_list', actionTypes.GET_OUTLIERS_RESULT);
//      };
    


export const getOutliearScanData = () => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.OUTLIER.POINT + constants.END_POINTS.OUTLIER.GET_OUTLINER_SCAN_DATA
    await simpleDispatch_get(dispatch, url, actionTypes.GET_OUTLINER_SCAN_DATA);
};


export const save_outliner = (data) => async dispatch => {

    const post = {
        sample_outlier_correct_data: data
    };

    const url = constants.END_POINTS.API + constants.END_POINTS.OUTLIER.POINT + constants.END_POINTS.OUTLIER.SAVE_OUTLIER;
    await simpleDispatch_post(dispatch, url, post, actionTypes.SAVE_OUTLIER, true);
};



