import { simpleDispatch_post, dispatchAction } from './helper';
import { constants } from '../utils/constants';
import * as actionTypes from '../action-types/table_format';

export const save_tbl_format_setting = (settings = [], report_id, report_item_id) => dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.SAVE_TBL_FORMAT_SETTING;

    const post = {
        settings: settings,
        report_id: report_id,
        report_item_id: report_item_id
    }

    simpleDispatch_post(dispatch, url, post, actionTypes.SAVE_TBL_FORMAT_SETTING, true);
    dispatchAction(dispatch, actionTypes.ADD_SAVE_TBL_FORMAT_SETTING, {
        report_id: report_id,
        settings: settings
    })
    // get_tbl_format_settings(report_id, report_item_id)(dispatch)
}



export const dispatch_tbl_format_settings = (settings, report_id, report_item_id) => (dispatch) => {

    dispatchAction(dispatch, actionTypes.ADD_SAVE_TBL_FORMAT_SETTING, {
        report_id: report_id,
        settings: settings
    })
}



export const get_tbl_format_settings = (report_id, report_item_id) => dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.GET_TBL_FORMAT_SETTING;
    const post = {
        report_id: report_id,
        report_item_id: report_item_id
    }
    simpleDispatch_post(dispatch, url, post, actionTypes.GET_TBL_FORMAT_SETTING, true);
}