import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TextBoxV1 from '../../hoc/text.box.v1';
import { Button } from '../../hoc/button';
import SelectInput from '../../hoc/customInputSelect';
import styled from 'styled-components';
import { saveGaugeRange } from '../../../actions/gaugeSegmentRange';
import Close from '../../svg/close';

const Wrapper = styled.div`
.scroll_section{
    margin-top: 0px;
    padding-top: 0px;
    width: 100%;
    max-height: 17rem;
    overflow-y: auto;
    &::-webkit-scrollbar {
        background-color: #3F3F3F;
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background-color: #F3F3F3;
        border: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dbdbdb;
        border-radius: 10px;
        height: 4px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color:  #3F3F3F;
        height: 4px;
    }
}
table {
    thead{
        font-size: 0.9rem;
        font-weight: bold;
        margin-bottom: 0.6rem;
    }
    tbody tr:hover .delete_icon {
        display: block;
    }
}
.delete_icon{
    display: none;
}
`;

const Index = (props) => {

    const {
        close, popupKey, option, data, saveGaugeRange,
    } = props;

    const {

        gaugeSegmentRanges,
        setGaugeSegmentRanges,
        report_item_id,
        report_id

    } = data;

    const [manualRanges, setManualRanges] = useState(gaugeSegmentRanges || [{ value1: '0', value2: '', value3: undefined, color: '#ff0000' }])

    const on_change = (value, key, row_index) => {

        const clone_prev_json = manualRanges ? [...manualRanges] : [];

        const newEndValue = ['value3', 'value4'].includes(key) ? value : value;
        // const newEndValue = ['value3', 'value4'].includes(key) ? value : parseFloat(value);
        // const prevRow = manualRanges[Number(row_index) - 1];
        // const is_last = (clone_prev_json.length - 1) == row_index;

        // if (newEndValue > 100) {
        //     alert("End value must be greater than start and less than or equal to 100");
        //     return;
        // };
        // if ((prevRow?.endValue - prevRow?.value1) < 0) {
        //     alert("End range should not less then Start Range");
        //     return;
        // }

        clone_prev_json[row_index][key] = newEndValue;
        clone_prev_json[row_index]['modified'] = true;

        // if (clone_prev_json?.[row_index + 1] && key === 'value2') {
        //     clone_prev_json[row_index + 1]["value1"] = newEndValue;
        // };

        // const last_element = clone_prev_json[clone_prev_json.length - 1]

        // if (is_last && last_element["value1"] && last_element["value2"] && newEndValue < 100) {
        //     clone_prev_json.push({
        //         value1: newEndValue || '0',
        //         value2: undefined,
        //         value3: undefined,
        //         value4: '#02f50f',
        //         modified: true
        //     })
        // }
        // else if (newEndValue === 100) {
        //     clone_prev_json?.splice(row_index + 1) //removes element after 100 %
        // }
        setManualRanges(clone_prev_json)
    };

    const handleRemoveRow = (rowData, index, e) => {
        e.stopPropagation();
        setManualRanges(prev => {
            const updatedItems = [...prev];
            updatedItems.splice(Number(index), 1);
            return updatedItems;
        })

    };

    return (
        <Wrapper>
            <React.Fragment>
                <div className='scroll_section'>
                    <div >
                        <table>
                            <thead>
                                <tr>
                                    <td style={{ width: '4%' }}>    </td>
                                    <td style={{ width: '23%' }}>Start Range</td>
                                    <td style={{ width: '23%' }}>End Range</td>
                                    <td style={{ width: '23%' }}> Color</td>
                                    <td style={{ width: '23%' }}>Label</td>
                                    <td style={{ width: '4%' }}></td>
                                    <td></td>
                                </tr>
                            </thead>


                            <tbody>
                                {manualRanges?.length > 0 && manualRanges.map((range, index) => {
                                    const id = (index + 1) < 10 ? '0' + (index + 1) : (index + 1);
                                    const s_id = "rand_id" + id;
                                    return (
                                        <tr
                                            key={"random_key_" + id + s_id}
                                        // current_row_index={index}

                                        >
                                            <td>
                                                <div>
                                                    {index + 1}
                                                </div>
                                            </td>
                                            <td >
                                                <div style={{ width: '100%' }}>
                                                    <TextBoxV1
                                                        key={"random_key_" + id + s_id}
                                                        // readOnly={true}
                                                        hide_margin={true}
                                                        height={'3rem'}
                                                        background={'#F3F3F3'}
                                                        label={'Start'}
                                                        show_as_placholder={true}
                                                        hide_border={true}
                                                        onChange={(e) => {
                                                            on_change(e.target.value, "value1", index)
                                                        }}
                                                        // defultValue={range?.value1 || manualRanges?.[index - 1]?.['value2'] || '0'}
                                                        // defultValue={range?.value1 || manualRanges[index - 1]?.['value2'] || '0'}
                                                        value={range?.value1 || ''}
                                                        id={s_id}
                                                        style={{
                                                            position: 'relative',
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{ width: '100%' }}>
                                                    <TextBoxV1
                                                        hide_margin={true}
                                                        height={'3rem'}
                                                        background={'#F3F3F3'}
                                                        label={'End'}
                                                        show_as_placholder={true}
                                                        hide_border={true}
                                                        onChange={(e) => {
                                                            on_change(e.target.value, "value2", index)
                                                        }}
                                                        value={range?.value2 || manualRanges?.[index]?.['value2'] || ''}
                                                        // defultValue={range?.value2 || manualRanges?.[index]?.['value2'] || ''}
                                                        id={s_id}
                                                        style={{
                                                            position: 'relative',
                                                        }}

                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{ width: '100%' }}>
                                                    <TextBoxV1
                                                        type='color'
                                                        hide_margin={true}
                                                        height={'3rem'}
                                                        background={'#F3F3F3'}
                                                        label={'End'}
                                                        show_as_placholder={true}
                                                        hide_border={true}
                                                        onChange={(e) => {
                                                            on_change(e.target.value, "value4", index)
                                                        }}
                                                        value={range?.value4 || ''}
                                                        id={s_id}
                                                        style={{
                                                            position: 'relative',
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div style={{ width: '100%' }}>
                                                    <SelectInput
                                                        key={"random_key_" + id + s_id}
                                                        initialValue={range?.value3}
                                                        value={range?.value3}
                                                        placeholder='Type Label'
                                                        id={s_id}
                                                        options={["poor", "fair", "good", "great", "excellent"]}
                                                        onChange={(val) => {
                                                            on_change(val, "value3", index)
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                            <td>
                                                <div onClick={(e) => handleRemoveRow(range, index, e)} className='delete_icon' style={{ width: '100%' }}>
                                                    <Close color={'#ff0000'} size={'1rem'} height={'1rem'} />
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', marginRight: '1rem', marginTop: '1rem', paddingBottom: '0.6rem' }}>
                    {/* <div>
                        <input checked={isMaxRangeFromForm} type='checkbox' onChange={onCheckMaxValue} />
                        <label>Max Range from Form</label>
                    </div> */}
                    <Button
                        primary={true}
                        width="7rem"
                        mR="0.6rem"
                        onClick={(e) => {
                            e.stopPropagation();
                            setManualRanges(prev => [...prev, {
                                value1: undefined,
                                value2: undefined,
                                value3: "good",
                                value4: '#02f50f',
                                modified: true
                            }])
                        }}
                    >
                        Add Row
                    </Button>
                    <Button
                        primary={true}
                        width="7rem"
                        mR="0.6rem"
                        // disabled={disabled}
                        // disabled={manualRanges?.length && !manualRanges[manualRanges?.length - 1]?.['value2']}
                        onClick={(e) => {
                            // if (disabled) return
                            e.stopPropagation();
                            if (manualRanges?.length < 1) return;
                            setGaugeSegmentRanges(manualRanges)
                            saveGaugeRange(manualRanges, report_item_id, report_id)
                            // const maxValue = isMaxRangeFromForm && manualRanges?.length > 0 ? Math.max(...manualRanges?.map(item => item.value2)) : null;
                            // setMaxRange( maxValue )
                            close(option, popupKey);
                        }}>Save</Button>

                    <Button
                        width="7rem"
                        bgColor={'#e6e6e6'}
                        onClick={() => {
                            // setGaugeSegmentRanges(undefined)
                            close(option, popupKey);
                        }}>Cancel</Button>
                </div>
            </React.Fragment>

            {/* </Wrapper> */}
        </Wrapper >
    )
};




const mapStateToProps = (state, props) => {
    const drill_filters_cache = state?.filterReducer?.drill_filter_cache
    return {
        filters_cache: state.filterReducer.filters_cache,
        mtd_ytd_options_cache: state.filterReducer.mtd_ytd_options_cache,
        dimensionByEntity: state.NewTableReducer.dimensionByEntity,
        dimensionByHierarchy: state.dataHierarchy.dimensionByHierarchy,
        dataFromServer: state.reportReducer.dataFromServer,
        drill_down_data: state.reportReducer.drill_down_data,
        drill_filters_cache,
    }
};


const mapDispatchToProps = {
    // dispatch_report_cell_clicked_info,
    saveGaugeRange

}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index))