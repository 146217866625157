export const LOGIN_INIT = 'modules/accounts/LOGIN_INIT';
export const LOGIN_ERROR = 'modules/accounts/LOGIN_ERROR';
export const LOGIN_REQUEST = 'modules/accounts/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'modules/accounts/LOGIN_SUCCESS';

export const AUTHENTICATE_SUCCESS= 'moduled/accounts/AUTHENTICATE_SUCCESS';
export const DISPATCH_TOKEN= 'moduled/accounts/DISPATCH_TOKEN';

export const LOG_OUT_SUCCESS = 'moduled/accounts/LOG_OUT_SUCCESS'

export const STORE_OPR_CREDENTIALS = 'modules/accounts/opr_credentials';

export const GET_USER_LIST = 'moduled/accounts/get_users';
export const SAVE_USERS_SUCCESS = 'moduled/accounts/SAVE_USERS'

export const GET_USER_PREFERENCES = 'moduled/accounts/get_users_preferences';
export const SAVE_USER_PREFERENCES = 'moduled/accounts/SAVE_USER_PREFERENCES';

export const GET_USER_SESSION_SUCCESS = 'moduled/user_session/GET_USER_SESSION_SUCCESS'
