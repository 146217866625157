import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Button } from '../../hoc/button';
import TextArea from '../../hoc/textArea';
import CloseSvg from '../../svg/close'
import AddUserSvg from '../../svg/add.user'
import { connect } from 'react-redux';
import { sendEmail } from '../../../actions/helper';
import TextBox from '../../hoc/textBox';
import { validateEmailFormat } from '../../../utils';
import RippleEffect from '../../hoc/ripple-effect';
import SelectOptionV1 from '../../select-option/select.option.v1.1';
import { constants } from '../../../utils/constants';


const Wrapper = styled.div`

    width: 35rem;

    .sharing_link_wrapper{
        padding: 1rem;
        border-radius: 6px;
        background-color: #fff;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;;
    }

    .link_icon{
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 1rem;
    }

    .link_box{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .copy_btn{
        color: #1a73e8;
        white-space: nowrap;
        padding: .4rem;
        border-radius: 5px;
        font-size: .9rem;
        &:hover{
            cursor: pointer;
            background-color: #eaeaea8a;
        }
    }

    .link{

       width: calc(100% - 100px);

       input{

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-right: 1rem;
        color: #1e8e3e;
        background: #1e8e3e26;
        padding: .4rem;
        border-radius: 3px;
        font-size: .9rem;
        border: 0px;
        width: 100%;
        box-sizing: border-box;

        &:focus{
            border: 0px;
        }
       }
    }
    .header{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;

        h1{
            margin: 0px;
            padding: 0px;
            font-size: 1.2rem;
            font-weight: 400;
            color: #222;
        }
    }

    .text_box{
            width: 100%;
            min-height: 2.5rem;
            padding: .5rem;
            box-sizing: border-box;
            border: 0px;
            border-bottom: 1px solid #000;
            background: #f8f9fa;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
            flex-wrap: wrap;
            max-height: 10rem;
            overflow-y: auto;
            &::-webkit-scrollbar {
                background-color:red;
                width:5px
            }
            &::-webkit-scrollbar-track {
                background-color:#b1cad5;
            }
            &::-webkit-scrollbar-thumb {
                background-color:#76919d;
                border-radius:0px;
                height: 5px;
            }


            &:hover{
                background: #eef0f2;
                input{
                    background: #eef0f2;
                }
            }

            &:focus{
                outline: none;
                border-bottom: 2px solid #308bc8;
            }
            input{
                font-size: .9rem;
                border: 0px;
                width: ${props => props.isEmails ? "10px" : '100%'};
                background: #f8f9fa;
                &:focus{
                    outline: none;
                }
        }
        .email_batch{
            background: #fffcfc;
            padding: .4rem .7rem;
            border-radius: 19px;
            font-size: .9rem;
            border: 1px solid #ccc;
            cursor: pointer;
            color: #444;
            margin: .3rem .5rem;
        }
        .remove_btn{
            display: inline-block;
            margin-left: .3rem;
            width: 1rem;
            height: 1rem;
            text-align: center;
            line-height: 1rem;
            border-radius: 50%;

            &:hover{
                background: #dadada;
            }
        }

    }

    .close_btn{
        position: absolute;
        right: 1rem
    }
`;




const Index = (props) => {

    const { close, popupKey, option, data } = props;

    const { link, title = 'Vady Summery', report_id, report_type = "dashboard" } = data;

    const [sharingOption, setSharingOption] = useState('email');
    const [mode, setMode] = useState('input');

    const [email, setEmail] = useState(undefined);
    const [message, setMessage] = useState(`Hello!\r\n\r\nPlease find attached the updated report for ${title}. \r\n\r\nThanks,\nNewFangled Team\r\n\r\ncontact@newfangled.io`);
    const [emails, setEmails] = useState(undefined);
    const [subject, setSubject] = useState(`Report for ${title}`);
    const [frequency, set_frequency] = useState(undefined);


    const [localJobData, setLocalJobData] = useState(undefined);


    useEffect(() => {
        setLocalJobData(props?.ruleByReportId)
        console.log("ruleByReportId", props.ruleByReportId)
        const __email = props?.ruleByReportId?.recipients?.split(",");
        setEmails(__email)
        set_frequency(props?.ruleByReportId?.frequency)
    }, [props.ruleByReportId])




    async function copyTextToClipboard(text) {

        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }




    const onCopyText = async (copyText) => {
        await navigator.clipboard.writeText(copyText);
        alert("Link copied")
    }


    const id = 'link_id' + link;

    const _remove_email = (index) => {
        let tempEmails = emails ? JSON.parse(JSON.stringify(emails)) : [];
        tempEmails.splice(index, 1);
        setEmails(tempEmails)
    };


    const lets_save_rule_and_job_data = () => {


        const temp_localJobData = localJobData ? { ...localJobData } : {};

        const pre_job_data = temp_localJobData.job_data ? { ...temp_localJobData.job_data } : {};

        const job_data = {
            ...pre_job_data,
            name: subject,
            frequency_type: frequency,
            script_type: "api",
            job_type: "rule_alert",
            grouping_tag: "rule_alert"
        }



        const pre_rule_data = temp_localJobData.rule_data ? { ...temp_localJobData.rule_data } : {};

        const rule_data = {
            ...pre_rule_data,
            name: subject,
            frequency: frequency,
            report_id: report_id,
            report_type: report_type,
            alert_type: ["email"],
            recipients: emails,
            subject: subject,
            message: message
        }
        props.save_rule_job(job_data, rule_data)
    }


    return (
        <Wrapper isEmails={emails && emails.length > 0 ? true : false}>
            {<div className="sharing_wrapper">
                {<>
                    {/* <div className="sharing_link_wrapper">
                        <div className="header" onClick={() => {
                            setSharingOption("link")
                        }}>
                            <div className="link_icon" style={{ background: sharingOption && sharingOption === 'link' ? '#4285f4' : "#9aa0a6" }}>
                                <LinkSvg size="1rem" height="1rem" color="#fff" />
                            </div>
                            <h1>Link for Sharing the Report</h1>
                            {sharingOption && sharingOption === 'link' && (
                                <div className="close_btn">
                                    <RippleEffect
                                        icon={<CloseSvg size="1rem" height="1rem" />}
                                        title="Close"
                                        Click={() => close(option, popupKey)}
                                    />
                                </div>
                            )}

                        </div>

                        {sharingOption && sharingOption === 'link' && (
                            <React.Fragment>
                                <div className="link_box" style={{ marginTop: '1.5rem' }}>
                                    <div className="link" id={id}>
                                        <input type="text" id={id} value={link} />
                                    </div>

                                    <div title="copy" className="copy_btn" onClick={() => onCopyText(link)}>Copy Link</div>

                                </div>

                            </React.Fragment>
                        )}
                    </div> */}

                    <div className="sharing_link_wrapper">
                        <div className="header" onClick={() => {
                            setSharingOption("email")
                        }}>
                            <div className="link_icon" style={{ background: sharingOption && sharingOption === 'email' ? '#4285f4' : "#9aa0a6" }}>
                                <AddUserSvg size="1.2rem" height="1.2rem" color="#fff" />
                            </div>
                            <h1>Send report in Email </h1>
                            {sharingOption && sharingOption === 'email' && (
                                <div className="close_btn">
                                    <RippleEffect
                                        icon={<CloseSvg size="1rem" height="1rem" />}
                                        title="Close"
                                        Click={() => close(option, popupKey)}
                                    />
                                </div>
                            )}
                        </div>

                        {sharingOption && sharingOption === 'email' && (
                            <React.Fragment>
                                <div className="share_pepole" style={{ marginTop: '1.5rem' }}>

                                    <div className="text_box">
                                        {emails && emails.length > 0 && emails.map((email, index) => {
                                            return (
                                                <div key={"email" + index} className="email_batch">
                                                    <span>{email}</span>
                                                    <span onClick={() => {
                                                        _remove_email(index);
                                                    }} className="remove_btn">
                                                        <CloseSvg size=".5rem" height=".5rem" />
                                                    </span>
                                                </div>
                                            )
                                        })}
                                        <input value={email} type="text"
                                            placeholder={emails && emails.length > 0 ? "" : "Add people and group"}
                                            onChange={(e) => {
                                                setEmail(e.target.value)
                                            }}
                                            autoFocus={true}
                                            style={{ width: (emails && emails.length > 0 && email && email.length * 10) + "px" }}
                                            onKeyDown={(event) => {
                                                let keyCode = event.keyCode;
                                                let _value = event.target.value;
                                                let tempEmails = emails ? JSON.parse(JSON.stringify(emails)) : [];
                                                if (keyCode === 13 && _value && _value.trim() !== '' && validateEmailFormat(_value)) {
                                                    tempEmails.push(_value);
                                                    setEmail('')
                                                }
                                                setEmails(tempEmails)
                                            }}
                                            onBlur={(event) => {
                                                let _value = event.target.value;
                                                let tempEmails = emails ? JSON.parse(JSON.stringify(emails)) : [];
                                                if (_value && _value.trim() !== '' && validateEmailFormat(_value)) {
                                                    tempEmails.push(_value);
                                                    setEmail('')
                                                }
                                                setEmails(tempEmails)
                                            }}
                                        />
                                    </div>
                                    <div style={{ width: '100%', marginTop: '1rem' }}>
                                        <TextBox label="Subject" value={subject} onChange={(e) => {
                                            setSubject(e.target.value)
                                        }}

                                            style={{ fontSize: '12px!important' }}

                                        />
                                    </div>
                                    <div style={{ width: '100%', marginTop: '1rem' }}>
                                        <TextArea label="Message" value={message}
                                            style={{ fontSize: '12px!important' }}

                                            onChange={(e) => {
                                                setMessage(e.target.value)
                                            }} />
                                    </div>
                                </div>


                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '1.5rem' }}>
                                    <Button
                                        width="6rem"
                                        primary={true}
                                        onClick={() => {
                                            if (emails && emails.length > 0) {
                                                close(option, popupKey)
                                                if (data?.save) {
                                                    data.save({
                                                        subject: subject,
                                                        emails: emails,
                                                        message: message

                                                    })
                                                }
                                            }
                                            else alert("Please provide a valid email address")
                                        }}>send</Button>
                                </div>
                            </React.Fragment>
                        )}
                    </div></>}
            </div>}
        </Wrapper>
    )
}

const mapStateToProps = (state) => ({
    filters: state.filterReducer.filters_cache,
    ruleByReportId: state.rulesAlert.ruleByReportId,
})


export default connect(mapStateToProps, {})(Index)
