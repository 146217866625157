import React from 'react';


const OgivChart = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            style={props.style}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            fill={props.color ? props.color : '#000'}
             viewBox="0 0 800 832"
            space="preserve">
            <path transform="translate(0)" d="m0 0h65l1 1v732l-1 3 8-7 41-41 8-7 98-98h2v-2l8-7 97-97 8-7 98-98 8-7 98-98h2l1-3 8-7 98-98h2l1-3 4-1 22 22-2 4-5 4-3 4h-2l-2 4h-2l-2 4-12 12h-2v2l-8 7-101 101-8 7-98 98-8 7-97 97-8 7-98 98-8 7-100 100-8 7-18 18v1h715v65l-23 1h-777z" fill="#347BFF"/>
            <path transform="translate(653,155)" d="m0 0 4 2 20 20-2 4-5 4-3 4h-2l-2 4h-2l-2 4-12 12h-2v2l-8 7-101 101-8 7-98 98-8 7-97 97-8 7-98 98-8 7-100 100-8 7-18 18-1 2-17-1-1-1v-20l1-8-2-1 8-7 41-41 8-7 98-98h2v-2l8-7 97-97 8-7 98-98 8-7 98-98h2l1-3 8-7 98-98h2l1-3z" fill="#69A0FF"/>
        </svg>

    )

}


export default OgivChart;