import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Button } from '../hoc/button';
import SelectOption from '../select-option';
import CheckBox from '../hoc/checkbox';


const Wrapper = styled.div`
    width: 20.429rem;
    max-height: 18.214rem;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
    border: solid 5px rgb(234, 234, 234);
    background-color: rgb(255, 255, 255);
    padding: 1rem;
    overflow: auto;
    display: flex;
    flex-direction: column;

    h1{
        font-weight: 500;
        font-size: 1.3rem;
        color: #555;
        text-align: center;
        margin-top: 0px;
        margin-bottom: 2rem;
    }
`;


const SmallPopup = (props) => {

    const { close, popupKey, option, data } = props;

    const { saveFunction, item, index, type } = data;

    const [ currentValue, setCurrentValue ] = useState(undefined)

    useEffect(() => {
        setCurrentValue(type)
    },[type])

    return (
        <Wrapper>

            <h1>Select Aggregate Function</h1>
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                {["Min", "Max", "Average", 'Total'].map((value, index) => {
                    return(
                        <div key={"agg" + index} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '50%'}} >
                        <CheckBox onClick={() => {
                                setCurrentValue(value)
                            }}
                         checked={currentValue === value} /> 
                         <span onClick={() => {
                            setCurrentValue(value)
                        }}>{value}</span>
                     </div>
                    )
                })}
            </div>

            {/* <SelectOption 
                //  label="Platform"
                 selections={["Min", "Max", "Average", 'Total']}
                 activeValue={currentValue ? currentValue : undefined}
                 width="100%"
                 lineHeight="2.857rem"
                 border='1px solid #bbd1d9'
                 color='#7c7c7c'
                 onChangeFunc={(value, type) => {
                    setCurrentValue(value)
                 }}

            /> */}

            <div style={{marginTop: '2rem'}}>
                <Button
                    primary={true}
                    width="6rem"
                    mR="16px"
                    onClick={() => {
                       if(currentValue && currentValue.length > 0){
                        saveFunction(item, currentValue, index)
                        close(option, popupKey)
                       }else alert("Please select aggregate function")
                    }}>save</Button>
                <Button
                    width="6rem"
                    bgColor={'#e6e6e6'}
                    onClick={() => {
                        close(option, popupKey)
                    }}>Cancel</Button>
            </div>
        </Wrapper>
    )
}

export default SmallPopup;
