import styled from 'styled-components';

import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils'
const selected = getSelectedTheme();


const { label, dispayAToZFiledStyled } = themes[selected];



export const DisplayFiledWrapper = styled.div`
    width:100%;
    max-height: 27rem;
    border-radius: 5px;
    /* border: solid 5px rgb(234, 234, 234); */
    background-color: ${dispayAToZFiledStyled.color.wrapperBg};
    padding: 1rem .5rem;
    box-sizing: border-box;

    .sort_title{
        font-size:1.714rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.214rem;
        letter-spacing: normal;
        text-align: left;
        color: ${label.color};
        text-transform: uppercase;
        margin-top: 13px;

        &:nth-child(1){
            margin-top: 0px;
        }
}



h3{
    font-size: 1.286rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.714rem;
    letter-spacing: normal;
    text-align: left;
    color: ${label.color};
    margin:0px;
    display: inline-block
}


input{
    border: 0px;
    border-bottom: 1px solid #000;
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.286rem;
    letter-spacing: normal;
    text-align: left;
    color: ${dispayAToZFiledStyled.color.input};
    width: 100%;
    height: 2.143rem;
    padding: 0.214rem;
    outline: none;
    font-family: "DM Sans", sans-serif;
    box-sizing: border-box;



    &&::placeholder{
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.286rem;
        color: ${dispayAToZFiledStyled.color.placeholder};
        font-family: "DM Sans", sans-serif;
    }
}

ul {

    list-style: none;
    padding: 0px;
    margin: 0px ;
    display: flex;
    flex-direction: column; 
    flex-wrap:wrap;
    width: 100%;
    padding-bottom: 20px;
    overflow: auto;
    align-content: flex-start;
    &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }
     

    .item_name{
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.714rem;
        letter-spacing: normal;
        text-align: left;
        color: ${dispayAToZFiledStyled.color.textColor};
        /* margin-left: 10px; */
        width: calc(100% - 30px);
        text-overflow: ellipsis;
        overflow: hidden;
        flex-wrap: nowrap;
        white-space: nowrap;
        text-transform: capitalize;
        
        &:hover{
            background: ${props => props.isCheckboxHide ? '#eaeaea' : undefined};
        }
    }
    
}

li{
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 12.2rem;
    cursor: pointer;
    margin-right: 1.429rem;
    position: relative;

    .hover_action{
        display: flex;
        flex-direction: row;
        opacity: 0;
        position: absolute;
        right: 0px;
        background-color: #fff;
        top: 1px;
    }

    &:hover{
        .hover_action{
            opacity: 1;
        }
    }

}
`;





export const DisplayColumnData = styled.div`
    display: flex;
    width: 100%;
    overflow: auto;
    height: 14.571rem;
`;



export const ATozTabWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 80%;

    .tab_value{
        font-size: 1.286rem;
        line-height: 1.714rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        color: ${dispayAToZFiledStyled.color.azTabText};
        display: inline-block;
        text-transform: uppercase;
        cursor: pointer;
        padding: 0px 0.5rem;

        &:hover{
            background: ${dispayAToZFiledStyled.color.azTabHoverBg};
        }
    }

`;



export const HoverAction = styled.div`
        display: flex;
        flex-direction: row;
        opacity: 0;
`;