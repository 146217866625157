import React, { useState } from 'react'
import { Wrapper, MenuColumn } from './righter.menu.styled';
import BarChartSvg from '../svg/barChart';
import DownloadSvg from '../svg/download';
import FormatSvg from '../svg/format';
import FilterSvg from '../svg/filter';
import Setting from '../svg/setting'
import { connect } from 'react-redux';
import { raiseMenuConfig, hideMenuConfig } from '../../actions/menu.config.righter'
import { RIGHTER_MENU_MODE } from '../../shared-with-fe/constants';
import Tooltip from '../tooltip-hover'
import TableFormating from '../formatting/table.formatting';
import TableUiFormulas from '../formatting/table.ui.formula';
import { showPopup } from '../../utils';
import * as enums from '../../utils/enums';
import FormulaSvg from '../svg/formula';
import FilterTooltip from '../filter/filter.tooltip';
import FilterPreSvg from '../svg/filter.pre';
import FilterPostSvg from '../svg/filter.post';
import { check_filter_exist } from '../../utils/v1.1/index'


const Index = (props) => {

    const {

        raiseMenuConfig,
        id,
        hideMenuConfig,
        heightOfChart,
        scheduleReportId,
        xDataKeys = [],
        yDataKeys = [],
        righterMenuStyle,
        report_item_id,
        report_menu_cache,
        sortedData,
        pivot_data_axis,
        is_details_window,
        config,
        isPolusAIWindow

    } = props;


    const menu_cache = report_menu_cache && report_menu_cache[id]
    const menu_render_mode = menu_cache?.menu_render_mode

    const [show_filter, set_show_filter] = useState(undefined)
    const filters = props.filters_cache && props.filters_cache[id]
    const is_menu_have_filters = check_filter_exist(filters)

    const { pivot_xac, pivot_yac } = props;

    const columns = (pivot_xac || []).concat((pivot_yac || []))


    return (
        <Wrapper
            heightOfChart={heightOfChart} 
            isPolusAIWindow={isPolusAIWindow}
        >
            {config?.pivot_option && (
                <MenuColumn
                    _style={righterMenuStyle.menu}
                    onClick={() => {
                        if (menu_render_mode === RIGHTER_MENU_MODE.COLUMN_CHOOSER) {
                            hideMenuConfig(id)
                        } else raiseMenuConfig("PivotTable Fields", RIGHTER_MENU_MODE.COLUMN_CHOOSER, id)
                    }}
                    active={menu_render_mode === RIGHTER_MENU_MODE.COLUMN_CHOOSER}
                >
                    <img src='/pivot.png' height='20px'></img>
                    <Tooltip className="tooltiptext" title="Pivot" />
                </MenuColumn>
            )}

            {!props.insights && config?.chart_option && (
                <MenuColumn
                    _style={righterMenuStyle.menu}
                    onClick={() => {
                        if (menu_render_mode === RIGHTER_MENU_MODE.CHART_OPTION) {
                            hideMenuConfig(id)
                        } else raiseMenuConfig("Chart", RIGHTER_MENU_MODE.CHART_OPTION, id)
                    }}
                    active={menu_render_mode === RIGHTER_MENU_MODE.CHART_OPTION}
                >
                    <BarChartSvg
                        size="1.4rem"
                        height="1.3rem"
                    />
                    <Tooltip className="tooltiptext" title="Chart Options" />
                </MenuColumn>
            )}


            {(scheduleReportId || report_item_id) && !props.is_global_filters && props.filter_option && (
                <MenuColumn
                    _style={righterMenuStyle.menu}
                    onMouseEnter={() => set_show_filter(true)}
                    onMouseLeave={() => set_show_filter(false)}
                    onClick={() => {
                        if (menu_render_mode === RIGHTER_MENU_MODE.FILTER_OPTION) {
                            hideMenuConfig(id)
                        } else raiseMenuConfig("Filter", RIGHTER_MENU_MODE.FILTER_OPTION, id)
                    }}
                    active={menu_render_mode === RIGHTER_MENU_MODE.FILTER_OPTION}
                >
                    {is_menu_have_filters ? <FilterPostSvg size="1.4rem" height="1.3rem" color="#308bc8" /> : <FilterPreSvg size="1.4rem" height="1.3rem" />}

                    <Tooltip className="tooltiptext" title="Filter" />

                    {show_filter && is_menu_have_filters && (
                        <div className="filter_area" style={{
                            background: '#fff',
                            position: 'absolute',
                            right: '110%',
                            top: '2%',
                            border: '1px solid #ccc',
                            zIndex: 2,
                            borderRadius: '3px',
                            padding: '5px'
                        }}>
                            <FilterTooltip
                                id={id}
                                width={'300px'}
                                multi_line={true}
                            />
                        </div>
                    )}
                </MenuColumn>

            )}

            {config.download_option && (
                <MenuColumn
                    _style={righterMenuStyle.menu}
                    onClick={() => {
                        if (menu_render_mode === RIGHTER_MENU_MODE.DOWNLOAD_OPTION) {
                            hideMenuConfig(id)
                        } else raiseMenuConfig("Download Option", RIGHTER_MENU_MODE.DOWNLOAD_OPTION, id)
                    }}
                    active={menu_render_mode === RIGHTER_MENU_MODE.DOWNLOAD_OPTION}
                >
                    <DownloadSvg
                        size="1.4rem"
                        height="1.3rem"
                    />
                    <Tooltip className="tooltiptext" title="Download" />
                </MenuColumn>
            )}



            {!props.is_details_window && config?.formation && (
                <MenuColumn
                    _style={righterMenuStyle.menu}
                    onClick={() => {
                        showPopup("Conditional formatting", undefined, enums.default.popupType.element_with_header, TableFormating, {
                            columns: columns || [],
                            id: id,
                            sortedData: props.pivot_data || props.raw_data,
                            report_item_id: report_item_id,
                        }, undefined, undefined, undefined, {
                            width: '100%',
                            maxWidth: '55rem'
                        })
                        raiseMenuConfig(undefined, undefined, id)
                    }}
                    active={menu_render_mode === RIGHTER_MENU_MODE.TABLE_FORMATTING_OPTION}
                >
                    <FormatSvg
                        size="1.6rem"
                        height="1.6rem"
                    />
                    <Tooltip className="tooltiptext" title="Open Formatting" />
                </MenuColumn>
            )}

            {!props.is_details_window && config?.formula &&(
                <MenuColumn
                    _style={righterMenuStyle.menu}
                    onClick={() => {
                        showPopup("YOUR FORMULAS AND RULES", undefined, enums.default.popupType.element_with_header, TableUiFormulas, {
                            columns: yDataKeys.concat(xDataKeys),
                            id: id,
                            report_item_id: (report_item_id || id),
                            sortedData: props.pivot_data || props.raw_data,
                            pivot_data_axis: pivot_data_axis,
                        }, undefined, undefined, undefined, {
                            width: '100%',
                            maxWidth: '45rem'
                        });
                        raiseMenuConfig(undefined, undefined, id)
                    }}
                    active={menu_render_mode === RIGHTER_MENU_MODE.TABLE_FORMULA_EDITOR}
                >
                    <FormulaSvg
                        size="1.6rem"
                        height="1.6rem"
                    />
                    <Tooltip className="tooltiptext" title="View Formulas" />
                </MenuColumn>
            )}
        </Wrapper>
    )
}







const mapStateToProps = state => ({
    report_menu_cache: state.menuConfigRighterReducer.report_menu_cache,
    filters_cache: state.filterReducer.filters_cache

})

export default connect(mapStateToProps, { raiseMenuConfig, hideMenuConfig })(Index);