import React, { useState, useEffect } from 'react';
import { Button } from '../../hoc/button'
import { Gap } from '../../gap';
import styled from 'styled-components';
import Overlay from '../../helper/overlay';
import SelectOption from '../../select-option';
import SelectOptionV1 from '../../select-option/select.option.v1.1'
import SelectOptionsV1 from '../../select-option/select.option.v1.1';
import { constants } from '../../../utils/constants';


const ContextEditorWrapper = styled.div`

        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        box-sizing: border-box;
        padding: 15px 3rem;
        background: white;
        min-width: 60rem;
        max-width: 60rem;
        min-height: 28.143rem;
        max-height: 35.143rem;
        border-radius: 5px;
        box-shadow: 0 3px 6px 0 rgba(0,0,0,0.25);
        border: solid 5px rgb(234,234,234);
        background-color: rgb(255,255,255);
        overflow: auto;

        &::-webkit-scrollbar {
            background-color:red;
            width:5px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }

        

        h1{
            font-size: 1.5rem;
            text-align: center;
            font-weight: 600;
            margin: auto;
            margin-top: 0px;
            margin-bottom: 1.5rem;
            text-transform: capitalize;
        }

        .hint_block{
            margin-bottom: 1rem;
            p{
                margin: 0px;
                font-size: 1rem;
                color: #222;
            }
        }


        table{
            width: 100%;
            border: 1px solid #ccc;
            border-collapse: collapse;
            thead{
                td{
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: .9rem;
                    color: #fff;
                    background-color: #008ac7a1;
                }
            }
            td{
                border: 1px solid #cccccc96;
                padding: 0rem .5rem;
                font-size: 1rem;
                height: 2.4rem;
                font-size: 1rem;
                position: relative;
                p{
                    margin: 0px;
                }
            }

            input{
                height: 2.2rem;
                width: 100%;
                border: 0px;
                font-size: 1rem;
                padding: 0rem .5rem;
                box-sizing: border-box;
            }
        }
`;


export const ErrorBox = styled.div`
        position: absolute;
        width: 100%;
        min-height: 2rem;
        background: #fff;
        top: ${props => props.top ? props.top : '10%'};
        padding: .2rem;
        box-sizing: border-box;
        font-size: .6rem;
        color: #000;
        box-shadow: 3px 3px 4px #ccc;
        border: 1px solid red;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        border-radius: 3px;
        width: 121px;
        text-align: center;
        left: ${props => props.left ? props.left : '-7rem'};
        /* top: -22px; */
`;


const AddDefaultValuePopup = props => {

    const { close, option, popupKey, data } = props;
    const { hintData, tableInfo, _save, activeTable } = data;

    const [localHintsData, setLocalHintsData] = useState(undefined)
    const [error, setErrors] = useState(undefined)
    const [context, setContext] = useState(undefined)
    const [showError, setShowError] = useState(undefined)
    const [localTableInfo, setLocalTableInfo] = useState(undefined)
    const [default_column_error, set_default_column_error] = useState(undefined)


    useEffect(() => {
        setLocalHintsData(hintData)
    }, [hintData])


    useEffect(() => {
        setLocalTableInfo(tableInfo)
    }, [tableInfo])


    const _onChange = (index, key, value) => {
        let cloneHintsData = localHintsData ? [...localHintsData] : [];
        cloneHintsData[index][key] = value;
        cloneHintsData[index]["modified"] = true;
        setLocalHintsData(cloneHintsData)
    }


    const save = () => {

        let __error_ = {};

        let default_date_error = undefined;
        const clone_hints = localHintsData ? [...localHintsData] : [];

        for (let i = 0; i < clone_hints.length; i++) {
            let currentElement = clone_hints[i];
            let nextElement = clone_hints[i + 1];

            if ((currentElement && currentElement.context === null) || (currentElement && currentElement.context === '')) {
                __error_[i] = {
                    msg: `Please Enter Context for ${currentElement.column_name}`,
                    column_name: "context"
                }
            }
            // if((currentElement && currentElement.contextcurrentElement.context) === (nextElement && nextElement.context)){
            //     __error_[i] = {
            //         msg: 'Duplicate Context Found',
            //         column_name : "context"
            //     }
            // }
        }

        if (localTableInfo && !localTableInfo.default_date_column) {
            default_date_error = true;
        }

        if (!__error_ || (__error_ && Object.keys(__error_).length === 0 && !default_date_error)) {
            _save(clone_hints, localTableInfo)
            close(option, popupKey)
        }

        else {
            setErrors(__error_)
            __error_ = undefined;
            set_default_column_error(default_date_error)
            default_date_error = undefined;
        }
    }





    return (
        <ContextEditorWrapper>
            <h1>Add Context Over Your Column</h1>
            <table>
                <thead>
                    <tr>
                        <td>Table Name</td>
                        <td>Column Name</td>
                        <td style={{ width: '10rem' }}>Context</td>
                        <td style={{ width: '10rem' }}>Complex Date Format</td>
                    </tr>

                </thead>

                <tbody>

                    {localHintsData && localHintsData.length > 0 && localHintsData.map((hint, i) => {

                        const { column_name, table_name, values, context, is_editable } = hint;
                        const row_error = error && error[i];
                        const error_column_name = row_error && row_error.column_name;
                        const error_msg = row_error && row_error.msg;

                        return (
                            <tr>
                                <td>{table_name}</td>
                                <td>{column_name}</td>
                                <td style={{ padding: '0px' }}>
                                    {row_error && error_column_name === 'context' ? <ErrorBox>{error_msg}</ErrorBox> : null}
                                    <input type="text" name="context" value={context} onChange={(e) => {
                                        _onChange(i, 'context', e.target.value)
                                    }} />

                                </td>

                                <td style={{
                                    background: hint?.data_type !== 'complex_date' ? "#ddd" : undefined,
                                    padding: hint?.data_type === 'complex_date' ? '0px' : undefined,
                                }}>
                                    {hint?.data_type === 'complex_date' && (
                                        <SelectOptionV1
                                            value={hint?.complex_date_format || undefined}
                                            options={constants.COMPLEX_DATE_FORMATS}
                                            on_change={(value) => {
                                                _onChange(i, 'complex_date_format', value)
                                            }}
                                        />
                                    )}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            <Gap h="25px" />


            {/* <SelectOption
                width={"400px"}
                label={"Default Date Column"}
                selections={localHintsData ? localHintsData : []}
                displayKey="column_name"
                valueKey="column_name"
                activeValue={(localTableInfo && localTableInfo.default_date_column) ? localTableInfo.default_date_column : undefined}
                onChangeFunc={(value) => {
                    const _clone_localTableInfo = localTableInfo ? Object.assign({}, localTableInfo) : {};
                    _clone_localTableInfo['default_date_column'] = value;
                    _clone_localTableInfo['table_name'] = activeTable
                    _clone_localTableInfo["modified"] = true;
                    setLocalTableInfo(_clone_localTableInfo)
                }}
            /> */}

            <div style={{ width: '400px' }}>
                <SelectOptionV1
                    label={"Select Default Date Column"}
                    options={localHintsData ? localHintsData : []}
                    display_key="column_name"
                    value_key="column_name"
                    value={(localTableInfo && localTableInfo.default_date_column) ? localTableInfo.default_date_column : undefined}
                    on_change={(value) => {
                        const _clone_localTableInfo = localTableInfo ? Object.assign({}, localTableInfo) : {};
                        _clone_localTableInfo['default_date_column'] = value;
                        _clone_localTableInfo['table_name'] = activeTable
                        _clone_localTableInfo["modified"] = true;
                        setLocalTableInfo(_clone_localTableInfo)
                    }}
                />
            </div>

            {default_column_error && <p style={{ color: 'red' }}>Please Select Default Date Colummn</p>}

            <Gap h="25px" />
            <div style={{
                paddingTop: '25px',
                marginTop: 'auto',
                display: 'block',
                width: '100%'
            }}>
                <Button
                    primary={true}
                    mR="16px"
                    onClick={save}
                >
                    Save
                </Button>
                <Button
                    width='6rem'
                    bgColor={'#e6e6e6'}
                    style={{
                        marginBottom: 0
                    }}
                    onClick={() => {
                        close(option, popupKey)
                    }}
                >Cancel</Button>
            </div>
        </ContextEditorWrapper>
    );
};

export default AddDefaultValuePopup;

