import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';
import CloseSvg from '../svg/close';
import JobOccurencesTable from './job_occurences';


const Wrapper = styled.div`
    background: #fff;
    width: 70rem;
    display: flex;
    flex-direction: column;
    padding: 1.1rem;
    box-sizing: border-box;
    border-radius: 12px;
    overflow: hidden;

    .table_wrapper{
        width: 100%;
        max-height: 20rem;
        min-height: 18rem;
        overflow: auto;
        &::-webkit-scrollbar {
            width:10px;
            border-radius: 10px;
    }
        &::-webkit-scrollbar-track {
            background-color:#EFEDF9;
            border-radius: 20px;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#C1C1EA;
            border-radius:20px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }
    }

    .header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        margin-bottom: 1rem;
        width: 100%;
        position: relative;

        h1{
            font-size: 1.5rem;
            font-weight: 500;
            margin: 0px;
            font-weight: bold;
            color: #2173F7;

        }

        .close{
            cursor: pointer;
            width: 1rem;
            height: 1rem;
          
            svg{
                fill: #333;
                
            }
        }
    }
    .close_btn{
        /* margin-top: -32px; */
        cursor: pointer;
        position: absolute;
        right: 1rem;
        top: 5px;
    }



`;

const SchemaListPopup = (props) => {

    const { db_infos, popupKey, option, data, close } = props;

    const save_fun = data?.save_fun;
    const job_data = data?.job_data;


    return (
        <Wrapper>
            <div className='header'>
                <h1>Job Occurance of {job_data.name}</h1>
                <div title="Close" className='close_btn' onClick={() => {
                    close(option, popupKey)
                }}>
                    <CloseSvg size="1.1rem" />
                </div>
            </div>


            <div className='table_wrapper'>
                <JobOccurencesTable job_id={job_data?.id} job_name={job_data.name} />
            </div>


            {/* <div className='buttons'>
                <Button
                    width={"7rem"}
                    bgColor={"#fff"}
                    style={{
                        color: '#222'
                    }}
                    onClick={() => {
                        close(option, popupKey)
                    }}

                >
                    Back
                </Button>
                <Button
                    width={"7rem"}
                    bgColor="#2173F7"
                    onClick={() => {
                        close(option, popupKey)
                    }}>
                    Next
                </Button>
            </div> */}

        </Wrapper>
    )
}



const mapStateToProps = (state) => ({
    db_infos: state.connectionReducer.db_infos,
})

export default connect(mapStateToProps, {})(SchemaListPopup);
