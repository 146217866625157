import styled from 'styled-components';


export const SearchWrapper = styled.div`
    width: 99%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    /* transition: all 0.3s; */
    border: none;
    /* border-radius: ${props => props.isShow ? '6px 6px 0 0 ' : '6px'}; */
    border-radius:6px;
    transition: all 0.3s;
    z-index: 2;
    background: #F3F3F3;

    ul{
        list-style: none;
        padding: 0px;
        margin: 0px;

        li{
            cursor: pointer;
            font-size: 1rem;
            font-weight: 500;
        }
    }
    
    
    /* &:hover{
        box-shadow: ${props => props.isShow ? undefined : '0 1px 6px 0 rgba(32,33,36,0.28)'};
    } */


    &:focus{
            border: 2px solid #0285fc;
            outline: 2px;
            box-shadow: -2px 1px 3px #ccc;
        }

    input {
        width: calc(100% - 6.071rem);
        border: none;
        outline: none;
        padding: 2px 2px;
        font-size: 1rem;
        box-sizing: border-box;
        background: transparent;
        outline: none;
        position: relative;
        border: 0px;
        height: 2.857rem;
        font-family: "DM Sans", sans-serif;   
        border-bottom: ${props => props.isShow ? "1px solid #ccc" : undefined};   

        &::placeholder {
            font-size: 1rem;
            font-weight: normal;
            text-align: left;
            font-family: "DM Sans", sans-serif;
            transition: all 0.2s;
            color: #7c7c7c;
        }

        &:focus{
            &::placeholder{
                /* transform: scaleY(0) */
            }
        }
    }

    .search{
        width: 3.071rem;
        height: 100%;
        display: flex;
        height: 2.857rem;
        align-items: center;
        justify-content: center;

        svg{
            fill: #9caeb5;
        }
    }
`;



export const SearchSuggestion = styled.div`
        width: 100%;
        max-height: 8.571rem;
        overflow: auto;
        position: absolute;
        top: 100%;
        left: 0;
        width: inherit;
        z-index: 7px;
        border-radius:6px;
        padding: 10px 0px;
        width: calc(100% + 2px);
        left: -1px;
        right: 0px;
        margin: auto;
        /* border: solid 1px #eaeaea; */
        background-color: #ffffff;
        border-top: 0px;
        padding-top: 5px;
        box-sizing: border-box;
        overflow-x: hidden;
        margin-top: 4px;
        /* box-shadow: 2px 2px 4px 0px rgba(209,209,209,1); */
        box-shadow: 1px 1px 8px 0px rgba(209,209,209,1);
        
        &::-webkit-scrollbar {
            background-color: #3F3F3F;
            width:4px;
        }

        &::-webkit-scrollbar-track {
            background-color: #F3F3F3;
            border: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #dbdbdb;
            border-radius: 10px;
            height: 4px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color:  #3F3F3F;
            height: 4px;
        }


    .suggest_item{
        padding: 6px 0px;
        transition: all 0.2s;
        font-size: 1rem;
        font-weight: normal;
        line-height: 1.286rem;
        color:#1a1a1a;
        /* background: #F3F3F3; */
        margin-top: 4px;

        svg{
            fill: #9caeb5;
            vertical-align: bottom;
        }

        &:hover{
            background: #F3F3F3;
        }
    }
`;