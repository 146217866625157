import * as actions from '../action-types/lefter';

const initialState = {
    type: actions.LEFTER_INIT,
    lefter: undefined
};

const lefterReducer = (state, action) => {
	if (!state || typeof action.type === 'undefined') {
		return initialState;
    }

    switch (action.type) {
        case actions.LEFTER_RAISE:

            return {
                type: action.type,
                lefter: action.data
            };

        case actions.LEFTER_HIDE:
            return {
                type: action.type,
                lefter: action.data
            };

            // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
			return {
                type: state.type,
                lefter: state.lefter
            };
        }
};

export default lefterReducer;