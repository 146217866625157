import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { dispatchAutoLayoutData } from '../../../actions/layout';
import { CHART_TYPES, CHART_TYPES_WITH_ICONS } from '../../../shared-with-fe/constants';
import { Button } from '../../hoc/button';
import SelectOptionV1 from '../../select-option/select.option.v1.1';
import TextBoxV1 from '../../hoc/text.box.v1'
import { generate_unique_key } from '../../../utils';
import DragSvg from '../../svg/drag';
import CrossSvg from '../../svg/close'
import { get_history } from '../../../actions/favourite';
import RecentSvg from '../../svg/recent'
import { clear_voice_dashboard_data, create_dashboard_using_voice_command } from '../../../actions/voice.helper';
import TabSlider from '../../tab';
import SuggestionListHoc from '../../hoc/suggestionListHoc';
import { calculatePositionForSuggestionBox } from '../../../utils/calculatePositionAtCursor';






const GenerateCodeWrapper = styled.div`

    width: 90%;
    margin: 0rem auto;
    margin-bottom: 1rem;
    padding: 5rem 0px;

    .search_wrapper{

        width: 100%;
        margin: auto;

        .search_box{
            width: 100%;
            box-sizing: border-box;
            border-radius: 40px;
            height: 3.2rem;
            padding: 5px 1rem;
            overflow: hidden;
            position: relative;
            border: 1px solid #b3b3b3;
            box-shadow: 3px 4px 4px #cccccca3;


            .gen_btn{
                position: absolute;
                right: 6px;
                padding: 0.7rem 1rem;
                border-radius: 35px;
                font-size: 1rem;
                top: 0px;
                bottom: 0px;
                height: 1.2rem;
                margin: auto;
                color: #fff;
                background: #2173F7;
                cursor: pointer;
                font-weight: 500;
                &:hover{
                    opacity: .8;
                }
            }
        }
        p{
            text-align: center;
            font-size: 1.5rem;
            margin: 0px;
            margin-bottom: 9px;
            color: #000;

        }
        input{
            width: 100%;
            height: 100%;
            overflow: hidden;
            border: 0px;;
            font-size: 1.2rem;
            padding-right: 9rem;
            box-sizing: border-box;
            background-color: transparent;
            padding-left: 1rem;
            &:hover{
                outline: none;
            }
            &:focus{
                outline: none;
            }
            &::placeholder {
                text-align: left;
                font-size: 1rem;
                color: #b3b3b3;
            }

        }
    }

`;




const OverlayMain = styled.div`
    width: 100vw;
    height: 100vh;
    z-index: 1200;
    position: fixed;
    top: 0px;
    left: 0px;
    background: #ff000000;
`;



const gradient = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`


const PopupWrapper = styled.div`
    height: 35rem;
    background-color: #fff;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: -7px;
    margin-left: -10px;
    margin-right: -10px;
    border-radius: 11px 11px 0px 0px;



    .quick_dashboard_title{
        background: linear-gradient(to right, rgb(62, 158, 255), rgb(115, 125, 255));
        border-radius: 6px 6px 0px 0px;
        h6{
            text-align: center;
            font-size: 1.4rem;
            color: #fff;
            margin: 0;
            padding: 0.7rem 0;
        }
    }

    .voice_text_box{
        width: 100%;
        height: 2.4rem;
        margin-top: 4rem;
        margin: auto;
        margin-top: 3rem;
        font-size: 1rem;
    }


    .title_input{

        text-align: left;
        text-transform: uppercase;
        font-size: .9rem;
        font-weight: 600;
        box-sizing: border-box;
        color: #222;
        border: 0px;
        caret-color: #005d86;
        width: 100%;
        height: 2.5rem;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #222;
        /* background: #f0f0f0; */
         
        &:hover{
            outline: none;
            border: 0px;;
            border: 1px solid #EAEAEA;
        }
        &:focus{
            outline: none;
            /* border-bottom: 1px solid #008AC7; */
            border: 1px solid #ccc;

        }
    }

    .delete_btn{
        width:  1.2rem;
        height: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        cursor: pointer;
        svg{
            fill: red;
        }
    }

    .table_wrapper{
        max-height: 25rem;
        min-height: 22rem;
        overflow: auto;    
        width: 100%;
        &::-webkit-scrollbar {
            background-color:red;
            width:3px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 3px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 3px;
        }
    }
    table{
        width: 100%;
        border: 1px solid #ccc;
        border-collapse: collapse;
        border: 0px;

    }

    tbody{
            tr{
                position: relative;
                &:last-child{
                    input{
                    background-color: #DDF9EE;
                    /* color: yellow !important; */
                    }
                }
            }
        }

    
   
    th,td{
        box-sizing: border-box;
        text-align: left;
        padding: 0px;
        border: 0px;
        padding: 5px 10px;
        border-bottom: 1px solid #eaeaea;;
        border-top: 1px solid #eaeaea;;
        position: relative;

    }
  
    th{
        font-size: .8rem;
        font-weight : 600;
        border: 0px;
        background-color: #fff;
        color: #706b6b;
        position: sticky;
        padding: 12px 10px;
        font-weight: 600;
        z-index: 3;
        top: -1px; /* Don't forget this, required for the stickiness */
        text-transform: uppercase   ;
        
    }

    .cross_btn{
        background-color: transparent;
        position: absolute;
        top: 13px;
        right: 32px;
        z-index: 99999;
        border: none;
    }

    .delete_btn{
        opacity: 0;
    }
    tr{
        &:hover{
            .delete_btn{
                opacity: 1;
            }
        }
    }


    .popup-header{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: start;
        padding: 5px 0px;
        padding-bottom: 14px;
        width: 30%;
        margin-left: 2rem;
        flex-direction: column;

        h1{
            font-size: 1.3rem;
            text-align: center;
            margin: 0px;
            color: #333;
            font-weight: 500;

        }

        p{
            margin-bottom: 0.5rem;
            width: 100%;
        }

     .close {
        position: absolute;
        right: 10px;
        transition: all 200ms;
        font-size: 30px;
        font-weight: bold;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
            svg{
                fill: #333;
            }
        }

    .close:hover {
            svg{
                fill: #005d86
            }
            transform: rotate(90deg);
        }
    }

    .drag_icon{
        cursor: pointer;
    }

    
   
`;





const AutoSuggestionWrapper = styled.div`

        position: fixed;

        top: ${props => props.top ? props.top + "px" : '100px'};
        left: ${props => props.left ? props.left + "px" : '100px'};
        width: ${props => props.width ? props.width + "px" : '500px'};
        background-color: #fff;
        box-shadow: 1px 2px 7px #00000061;
        max-height: 10rem;
        overflow-y: auto;
        z-index: 5;

        &::-webkit-scrollbar {
            background-color:red;
            width:3px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 3px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 3px;
        }


    div{
        padding: 8px 14px;
        font-size: .9rem;
        box-sizing: border-box;
        border-bottom: 1px solid #eaeaea;
        cursor: pointer;
    
        display: flex;
        align-items: center;

        span{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            max-width: 94%;
        }
        svg{
            margin-right: 10px;
            margin-top: 5px;
            fill: #008AC7;
        }

        &:hover{
            background-color: #eaeaea;;
        }
    }

    h3{
        padding: 6px 16px;
        margin: 0px;
        font-size: 1.1rem;
        font-weight: 500;
        font-style: italic;
        position: sticky;
        background: #fff;
        top: 0px;
        border-bottom: 1px solid #ccc;
    }

`;




const AutoLayoutPopup = (props) => {

    const {
        popupKey,
        close,
        option,
        data,
        create_dashboard_using_voice_command,
        hints_data,
    } = props;

    const { hide_action = () => { }, closeParentPopup, onSave, forword, dashboard_name, dashboardQuestions = [] } = data || {};


    const [default_json, set_default_json] = useState(undefined);

    const [draged_element, set_draged_element] = useState(undefined);
    const [drop_element, set_drop_element] = useState(undefined);
    const [title, set_title] = useState((dashboard_name || ''));

    const [voiceCommand, setVoiceCommand] = useState('')
    const [localHints, setLocalHints] = useState(undefined)
    const [show_hint, set_show_hint] = useState(undefined)

    const [dropdown_position, set_dropdown_position] = useState(undefined);

    const [activeType, setActiveTab] = useState("Manual Dashboard")

    const InputRefs = useRef({});
    const wrapperRef = useRef();
    const [hintList, setHintsList] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [suggestionBoxPosition, setSuggestionBoxPosition] = useState({ top: 60, left: 0 });
    const [currCursorPosition, setCurrCursorPosition] = useState(undefined);
    const [editingRow, setEditingRow] = useState(undefined);

    const setRef = (id) => (el) => {
        if (el) InputRefs.current[id] = el; // Assign ref dynamically
    };

    const focusInput = (index) => {
        if (InputRefs?.current?.[index]) {
            InputRefs?.current?.[index].focus();
        }
    };

    const getSelectionStartOfInput = (index) => {
        if (InputRefs?.current?.[index]) {
            return InputRefs?.current?.[index].selectionStart;
        }
    };

    const logInputDimensions = (id) => {
        if (InputRefs?.current?.[id]) {
            return { scrollWidth: InputRefs?.current?.[id]?.scrollWidth, clientWidth: InputRefs?.current?.[id]?.clientWidth }
        }
    };

    const is_value_container_hint = (hint) => {
        if (!props.containerValues || props.containerValues.length === 0 || !hint) return false;
        else {
            return props.containerValues?.length > 0 && hint && props.containerValues.indexOf(hint) > -1;
        }
    };

    const handleSuggestionClick = (word) => {
        if (!default_json) return;
        const index = editingRow?.split("_key__")?.[0]

        var startPos = getSelectionStartOfInput(editingRow);
        // var endPos = InputRefs?.current?.selectionEnd;
        // const end_v = localQuestion?.substring(startPos)
        const start_v = default_json?.[index]?.question?.substring(0, currCursorPosition || startPos) || '';
        let splitted_value = start_v && start_v?.split(' ');
        splitted_value && splitted_value?.pop();
        !currCursorPosition && setCurrCursorPosition(splitted_value.join(" ")?.length + 1);
        let last_value = splitted_value && splitted_value[splitted_value.length - 1];

        if (word && (last_value === word.split(' ')[0] || word?.includes(last_value))) {
            splitted_value.pop();
        }
        const is_value_container_value = is_value_container_hint(word)
        const wordToConcate = is_value_container_value ? ("'" + word + "'") : word;
        const newValue = [...splitted_value, wordToConcate].join(' ') + InputRefs?.current?.[editingRow]?.value?.slice(startPos);
        const newCursorPosition = splitted_value.join(' ')?.length + wordToConcate?.length + 1;

        set_default_json(prev => {
            const updatedData = [...prev];
            updatedData?.splice(index, 1, { ...prev[index], question: newValue })
            return updatedData
        })

        if (InputRefs?.current) {
            setTimeout(() => {
                if (!InputRefs?.current?.[editingRow]?.value?.slice(startPos)?.trim()) {
                    const { scrollWidth, clientWidth } = logInputDimensions(editingRow);
                    InputRefs.current[editingRow].scrollLeft = Math.max(0, scrollWidth - clientWidth);
                } else {
                    InputRefs.current?.[editingRow].setSelectionRange(newCursorPosition, newCursorPosition);
                }
            }, 0)
        }
    };

    const onKeyDown = (e) => {
        e.stopPropagation()
        if (hintList?.length === 0) return;

        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setSelectedIndex((prevIndex) =>
                prevIndex < hintList.length - 1 ? prevIndex + 1 : 0
            );
            handleSuggestionClick(hintList[selectedIndex < hintList.length - 1 ? selectedIndex + 1 : 0]);
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setSelectedIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : hintList.length - 1
            );
            handleSuggestionClick(hintList[selectedIndex > 1 ? selectedIndex - 1 : hintList.length - 1]);
        }
    }


    useEffect(() => {

        // if (props?.historyData?.results?.length === 0 || !props?.historyData?.results) {
        props.get_history()
        // }

    }, [])

    useEffect(() => {
        reset_hints()
    }, [props.historyData?.results])




    useEffect(() => {
        const t = [];
        t.push({
            title: '',
            question: '',
            chart_type: ''
        })
        set_default_json(t);
    }, [])



    const filter_question_hints = (value) => {

        const clone_be_hints = props?.historyData?.results ? [...props?.historyData?.results] : [];

        const hints = clone_be_hints.map((hint) => hint.question) || [];

        const unique_hints = hints ? [...new Set(hints)] : [];

        const value_to_use = value && value.trim()

        const filterd_hints = unique_hints?.filter((hint) => {

            if (!value_to_use) return true;

            if (hint.toLocaleLowerCase().indexOf(value_to_use) > -1) return true;

            else return false;
        })

        setLocalHints(filterd_hints)

    }

    const reset_hints = () => {
        if (props?.historyData?.results?.length > 0) {

            const hints = props?.historyData?.results.map((hint) => hint.question) || [];
            const unique_hints = hints ? [...new Set(hints)] : [];

            setLocalHints(unique_hints)
        }
    }


    useEffect(() => {

        if (dashboardQuestions?.length > 0) {
            // let's create a dashboard 
            const clone_prev_json = [];

            dashboardQuestions.map((q) => {
                
                clone_prev_json.push({
                    title: '',
                    question: q,
                    chart_type: ''
                })
            })

            set_default_json(clone_prev_json)
        }
    }, [dashboardQuestions])


    const on_change = (value, key, row_index, is_hint) => {


        if (key === "question" && !is_hint) filter_question_hints(value)


        const clone_prev_json = default_json ? [...default_json] : [];

        const is_last = (clone_prev_json.length - 1) == row_index;
        clone_prev_json[row_index][key] = value;

        const last_element = clone_prev_json[clone_prev_json.length - 1]

        if (is_last && last_element["question"]) {
            clone_prev_json.push({
                title: '',
                question: '',
                chart_type: ''
            })
        }
        set_default_json(clone_prev_json)

    }


    const delete_row = (index) => {
        const clone_prev_json = default_json ? [...default_json] : [];
        clone_prev_json.splice(index, 1)
        set_default_json(clone_prev_json)
    }

    const generated_id = generate_unique_key("abcd");

    useEffect(() => {
        const dashboard_data = props.dashboard_data
        if (dashboard_data) {

            const widgets = dashboard_data?.widgets;

            const filterd_data = widgets?.filter((a) => {
                if (a.chart_type && a?.question?.length > 1) return true
                else return false
            }) || []


            if (filterd_data?.length > 0) {

                const data = {
                    "id": generated_id,
                    "title": dashboard_data?.dashboard_name,
                    "widgets": filterd_data
                }

                props.dispatchAutoLayoutData(data)
                const path = `layout_editor?auto_layout_id=${generated_id}`


                hide_action()
                close(option, popupKey)
                // closeParentPopup()
                if (forword) {
                    setTimeout(() => {
                        props.history.push(path)
                    }, 100)
                }
                props.clear_voice_dashboard_data()

            }
        }
    }, [props.dashboard_data])



    const _local_save = () => {

        const filterd_data = default_json?.filter((a) => {
            if (a?.question?.length > 1) return true
            else return false
        }) || []

        if (filterd_data?.length > 0) {

            const data = {
                "id": generated_id,
                "title": title,
                "widgets": [{ chart_type: "group", render_mode: "group", is_group: true, question: "", title: "" }, ...filterd_data]
            }

            props.dispatchAutoLayoutData(data)
            const path = `layout_editor?auto_layout_id=${generated_id}`
            hide_action()
            if (onSave) {
                console.log("CCCCCCCCCCCCCC")
                onSave(generated_id)
            }
            close(option, popupKey)
            if (closeParentPopup) closeParentPopup()
            if (forword) {
                setTimeout(() => {
                    props.history.push(path)
                }, 100)
            }



        } else {
            alert("Please Fill input")
        }
    }


    const change_row_order = (event) => {

        const p_clone_default_json = default_json ? [...default_json] : [];

        const clone_default_json = default_json ? [...default_json] : [];

        const drag_row_index = parseInt(event.dataTransfer.getData("row_index"));
        const current_row_index = parseInt(event.currentTarget.getAttribute("current_row_index"));


        if (drag_row_index > -1 && current_row_index > -1 && drag_row_index !== current_row_index) {

            const current_element = p_clone_default_json[current_row_index];
            const moving_element = p_clone_default_json[drag_row_index];
            const indexex = [parseInt(current_row_index), parseInt(drag_row_index)];

            const filterd_clone = clone_default_json.filter((item, index) => {
                return indexex.indexOf(index) == -1;
            })

            if (current_row_index < drag_row_index) {
                filterd_clone.splice(current_row_index, 0, moving_element)
                filterd_clone.splice((parseInt(current_row_index) + 1), 0, current_element);

            }
            if (drag_row_index < current_row_index) {
                filterd_clone.splice((current_row_index - 1), 0, current_element);
                filterd_clone.splice(parseInt(current_row_index), 0, moving_element)
            }
            set_default_json(filterd_clone)
        }

    }



    const open_dropdown_menu = (el_id) => {

        const dropdown_height = 180;
        const element = document.getElementById(el_id);
        const el_bounding = element.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const element_height = el_bounding?.height;
        const element_left = el_bounding?.left;
        const element_top = el_bounding?.top;
        const el_width = el_bounding?.width;
        // console.log("el_bounding", el_bounding)
        let drop_down_top_to_use = (element_top + element_height);
        if ((windowHeight - drop_down_top_to_use) <= dropdown_height) {
            drop_down_top_to_use = ((element_top + element_height) - dropdown_height) - 10
        }
        set_dropdown_position({
            top: drop_down_top_to_use,
            left: element_left,
            width: el_width
        })
        // close_dropdown(!open_menu)
    }


    return (
        <PopupWrapper>

            <div className='quick_dashboard_title'>
                <h6>Quick Dashboard Builder</h6>
            </div>

            {/* <TabSlider
                hideShadow={true}
                // tabList={["Manual Dashboard", "Voice-Based Dashboard"]}
                tabList={["Manual Dashboard"]}
                activeType={activeType}
                setActiveType={(value) => {
                    setActiveTab(value)
                }}
            /> */}

            {activeType === "Voice-Based Dashboard" && (
                <div>

                    {/* <input
                        autoFocus={true}
                        placeholder='Your Voice Command '
                        className="voice_text_box"
                        type="text"
                        value={voiceCommand}
                        onChange={(e) => {
                            setVoiceCommand(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                // console.log("ENTERD")
                                // Handle the Enter key press event here
                                // For example, you can call a function
                                create_dashboard_using_voice_command(e.target.value)
                            }
                        }}
                    /> */}

                    <GenerateCodeWrapper>

                        <div className='search_wrapper'>
                            <p className='title_up'>Provide Your Voice Command</p>
                            <div className='search_box'>
                                <input type="text" placeholder='Type your question' value={voiceCommand} onChange={(e) => {
                                    // on_change_v1(e.target.value, 'nlp_query')
                                    setVoiceCommand(e.target.value);


                                }} />
                                <div className='gen_btn' onClick={() => {
                                    // const db_id_to_use = reporting_db_info_id?.indexOf("__nf__db__cached__") > -1 ? reporting_db_info_id : reporting_db_info_id + "__nf__db__cached__";
                                    // if (extraction_state?.nlp_query?.length > 0) {
                                    //     props.getAllInfoOfQuestion(extraction_state?.nlp_query, db_id_to_use, true)
                                    // } else {
                                    //     alert("Please Provide Transformation Rule")
                                    // }

                                    create_dashboard_using_voice_command(voiceCommand)

                                }}>Generate Code</div>
                            </div>
                        </div>
                    </GenerateCodeWrapper>

                </div>
            )}


            {activeType === "Manual Dashboard" && (
                <>
                    <div className="popup-header">
                        <p>Please Provide a Dashboard Name</p>

                        <input
                            autoFocus={true}
                            // placeholder='Please Provide a Dashboard Name'
                            className="title_input"
                            type="text"
                            value={title}
                            onChange={(e) => {
                                set_title(e.target.value)
                            }} />

                    </div>

                    <div className="table_wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: '30px' }}>#</th>
                                    {/* <th>Name Your widget</th> */}
                                    {/* <th style={{ minWidth: '12rem', maxWidth: '12rem' }}>Choose Your Chart Type</th> */}
                                    <th style={{ width: '100%' }}>Ask Your Data Question</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {default_json?.length > 0 && default_json.map((json, index) => {

                                    const id = (index + 1) < 10 ? '0' + (index + 1) : (index + 1);
                                    const is_blank_element = (default_json.length - 1) == index;;

                                    // we did this way cuz i have some other planing to 
                                    const hide_title_bdr = !is_blank_element;
                                    const hide_chart_type_bdr = !is_blank_element;
                                    const hide_question_bdr = !is_blank_element;
                                    const s_id = "rand_id" + id;

                                    return (
                                        <tr
                                            // style={{
                                            //     backgroundColor: (draged_element === id) ? 'red' : (drop_element === id) ? 'green' : undefined,
                                            // }}
                                            className={(draged_element === id) ? 'draging' : (drop_element === id) ? 'drop' : undefined}
                                            key={"random_key_auto_layout" + id}
                                            draggable={!is_blank_element ? true : false}
                                            onDrop={(event) => {
                                                set_drop_element(undefined)
                                                set_draged_element(undefined)
                                                if (!is_blank_element) {

                                                    change_row_order(event)
                                                }
                                            }}
                                            onDragOver={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation()
                                                set_drop_element(id)
                                            }}
                                            current_row_index={index}
                                            onDragStart={(event) => {
                                                if (!is_blank_element) {
                                                    set_draged_element(id)
                                                    event.dataTransfer.setData("row_index", index)
                                                    event.dataTransfer.effectAllowed = "move"
                                                }
                                            }}

                                        >
                                            <td>
                                                <div className="drag_icon">
                                                    {/* <DragSvg size=".8rem" height=".8rem" color="#333" /> */}
                                                    {index + 1}
                                                </div>
                                            </td>
                                            {/* <td>
                                                <TextBoxV1
                                                    // autoFocus={index == 0 ? true : false}
                                                    hide_margin={true}
                                                    onChange={(e) => on_change(e.target.value, "title", index)}
                                                    value={json?.title || ''}
                                                    hide_border={hide_title_bdr}


                                                />
                                            </td> */}
                                            {/* <td>
                                                <SelectOptionV1
                                                    hide_margin={true}
                                                    is_search={true}
                                                    none_value={false}
                                                    // options={["table", "single_cell", ...Object.values(CHART_TYPES)]}
                                                    options={(CHART_TYPES_WITH_ICONS || []).filter((a) => (['group', 'label'].indexOf(a.dataKey) === -1) )}
                                                    value_key='dataKey'
                                                    display_key='name'
                                                    icon_key='icon'
                                                    on_change={(value) => on_change(value, "chart_type", index)}
                                                    value={json?.chart_type || ''}
                                                    hide_border={hide_chart_type_bdr}

                                                />

                                            </td> */}
                                            <td >
                                                <div ref={wrapperRef} style={{ width: '100%' }}>
                                                    <TextBoxV1
                                                        ref={setRef(index + '_key__')}
                                                        // ref={(el) => (InputRefs.current[index + '_key__'] = el)}
                                                        // ref_name={InputRefs}
                                                        hide_margin={true}
                                                        height={'3rem'}
                                                        background={index == show_hint ? '#DDF9EE' : '#F3F3F3'}
                                                        label={'Please Type Your Question here'}
                                                        show_as_placholder={true}
                                                        hide_border={true}
                                                        onKeyDown={index + '_key__' === editingRow && onKeyDown}
                                                        onChange={(e) => {
                                                            on_change(e.target.value, "question", index)

                                                            const value = e.target.value;
                                                            const startPos = e.target.selectionStart;

                                                            currCursorPosition && setCurrCursorPosition(undefined)
                                                            selectedIndex !== -1 && setSelectedIndex(-1)

                                                            const full_question = value.slice(0, startPos)?.split(' ');
                                                            const last_word = full_question[full_question.length - 1];
                                                            const currentWord = last_word;
                                                            if (hints_data?.length > 0) {
                                                                // const result = find_hint_in_array(currentWord, hints, hints);
                                                                const result = hints_data?.filter(el => el?.toLocaleLowerCase()?.startsWith(currentWord?.toLocaleLowerCase()))?.sort((a, b) => a?.localeCompare(b, 'en', { numeric: true, sensitivity: 'accent' }))
                                                                setHintsList([...result]);
                                                                const coordinates = calculatePositionForSuggestionBox(startPos, undefined, wrapperRef, InputRefs?.current[editingRow]);
                                                                setSuggestionBoxPosition(prev => ({ ...prev, left: coordinates?.left }))
                                                            }
                                                        }}
                                                        value={json?.question || ''}
                                                        id={s_id}
                                                        onClick={() => { setEditingRow(index + '_key__') }}
                                                        onFocus={() => {
                                                            set_show_hint(index)
                                                            open_dropdown_menu(s_id)
                                                            setEditingRow(index + '_key__')
                                                            setHintsList([])
                                                        }}
                                                        onBlur={() => {
                                                            setTimeout(() => {
                                                                // set_show_hint(undefined)
                                                            }, 300);
                                                        }}
                                                        style={{
                                                            position: 'relative',
                                                            zIndex: (show_hint > -1 && (show_hint == index)) ? 10 : 0,
                                                        }}

                                                    />
                                                </div>

                                                {index + '_key__' === editingRow && hintList && hintList?.length > 0 &&
                                                    <OverlayMain onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation()
                                                        // setEditingRow(undefined)
                                                        setHintsList([])
                                                    }}>
                                                        <SuggestionListHoc suggestionList={hintList} selectedIndex={selectedIndex} position={{
                                                            ...suggestionBoxPosition,
                                                            top: dropdown_position?.top < suggestionBoxPosition.top ? suggestionBoxPosition.top - 10 : dropdown_position?.top - 10,
                                                            left: suggestionBoxPosition.left + dropdown_position?.left,
                                                        }} handleSuggestionClick={handleSuggestionClick} />
                                                    </OverlayMain>
                                                }

                                                {false && show_hint > -1 && show_hint == index && localHints && localHints?.length > 0 && (
                                                    <OverlayMain onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation()
                                                        set_show_hint(undefined)

                                                    }}>
                                                        <AutoSuggestionWrapper
                                                            onClick={(e) => e.stopPropagation()}
                                                            top={dropdown_position?.top}
                                                            left={dropdown_position?.left}
                                                            width={dropdown_position?.width}

                                                        >
                                                            <h3>Recent History</h3>
                                                            {localHints.map((hint) => {
                                                                return (
                                                                    <div key={hint} title={hint} onClick={() => {
                                                                        on_change(hint, "question", index, true)
                                                                        set_show_hint(undefined)
                                                                        reset_hints()
                                                                    }}>
                                                                        <RecentSvg size="1rem" height="1rem" />
                                                                        <span>{hint}</span>
                                                                    </div>
                                                                )
                                                            })}
                                                        </AutoSuggestionWrapper>
                                                    </OverlayMain>
                                                )}

                                            </td>
                                            <td className='cross_btn'>
                                                {!is_blank_element && (

                                                    <div onClick={(e) => {
                                                        delete_row(index)
                                                        setHintsList([]);
                                                    }} title="Delete" className='delete_btn'>
                                                        <CrossSvg size='.8rem' height='.8rem' />
                                                    </div>

                                                )}
                                                {is_blank_element && (

                                                    <div style={{ opacity: 0 }} onClick={(e) => {
                                                        // delete_row(index)

                                                    }} title="Delete" className='delete_btn'>
                                                        <CrossSvg size='.8rem' height='.8rem' />
                                                    </div>

                                                )}
                                            </td>

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    <div style={{ marginTop: 'auto', marginLeft: 'auto', marginRight: '1rem', marginBottom: '15px' }}>

                        <Button
                            primary={true}
                            width="6rem"
                            mR="16px"
                            onClick={() => {
                                _local_save()
                            }}>save</Button>
                        <Button
                            width="6rem"
                            bgColor={'#e6e6e6'}
                            onClick={() => {
                                close(option, popupKey)

                            }}>Cancel</Button>
                    </div></>
            )}
        </PopupWrapper>
    )
}


const mapStateToProps = state => ({
    historyData: state.favouriteReducer.historyData,
    dashboard_data: state.voiceHelperReducer.dashboard_data,
    containerValues: state.reportReducer.containerValues,
    hints_data: state.reportReducer.hints,
})




export default withRouter(connect(mapStateToProps, { 
    create_dashboard_using_voice_command, 
    clear_voice_dashboard_data, 
    get_history, 
    dispatchAutoLayoutData 
})(AutoLayoutPopup))