/**
 * 1. Get the data from the reporting database
 * 
 * it will return the report items with following:
 *  top, left, height width
 * 
 *  which will be given in percentage with respect to the report height
 * 
 * for calculation and rendering purpose we will have to assign px so it can be fixed on a screen
 * 
 * on component load, we will get the height of the wrapper through ref
 * 
 * let's call it wrapper_height
 * 
 * wrapper height will be -> 100 vh - 70px (for the header)
 * 
 * it should be defined in a method like this:
 * 
 * const get_wrapper_height_from_ref = () => {
 *  bla bla bla
 * }
 * 
 * then, we will convert the report items to px from percentage
 * 
 * write a function to convert to px
 * 
 * const convert_percentage_to_px = ({ top_in_percentage, left_in_percentage, height_in_percentage, width_in_percentage }) => {
 *  
 * };
 * 
 * loop through all the report items, and update their top/left/h/w to px, using above method. So we will always have report_items with px values, not the percentage values
 * 
 * in the above function, we will take wrapper height and convert the percentage to px, for example like following top calculation
 * 
 * report_item_top = wrapper_height * top_in_percentage;
 * 
 * then assign the new dimensions & positions to report items
 * 
 * when we move the item/ or create a new item, it always will have a px value
 * 
 * when we resize/drag a component, we will always get a size object with the movement. { top, left, height, width }
 * 
 * after resize/drag is finished, call the following function
 * 
 * const change_wrapper_height_if_required = () => {
 *      loop through all report_items
 * 
 *      find the highest bottom point from all items 
 * 
 *      divide that highest point from wrapper_height
 * 
 *      parseInt(950 / 930)
 *      
 *      get the factor ==> 950 / 930 = 1 
 *      
 *      increase factor by 1 ==> 1 + 1 = 2
 * 
 *      set wrapper_height = factor * 930 ==> 930 x 2 = 1860
 *      
 * 
 *      2nd case: if a wrapper height is increased, but we moved out from page 2.
 * 
 *      in this case, we have to reduce the wrapper height
 * 
 *      find the highest bottom point from all items 
 * 
 *      divide that highest point from wrapper_height
 * 
 *      parseInt(920 / 930)
 *      
 *      get the factor ==> 920 / 930 = 0 
 *      
 *      increase factor by 1 ==> 0 + 1 = 1
 * 
 *      set wrapper_height = factor * 930 ==> 930 x 1 = 930
 *      
 * };
 * 
 * before save, we will convert the report items, to percentage
 * 
 * loop through all the items, and set the top/left/h/w to percent using wrapper_height
 * 
 * wrapper_height * top_in_px
 * const convert_px_to_percentage = ({ top_in_percentage, left_in_percentage, height_in_percentage, width_in_percentage }) => {
 *  
 * };
 * 
 * after updating the percentage to report items, set report_wrapper_height to report.height and save the report + items
 */



import React, { useState, useRef, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';

import { saveInsight, createForm, getHintsByClientId, setActiveInsight, get_question_info_for_dashboard_v2 } from '../../actions/insights';
import { setActiveEditLayout } from '../../actions/layout';
import { getReportById, setActiveEditReport, reset_data_from_server } from '../../actions/report';
import { getHintsForRules } from '../../actions/hints';

import { MainWrapper } from './layout.editor.styled';
import DraggableDiv from './draggable.div';
import LayoutHeader from './v2/layout.header';
import { getParameterByName, debounceWrapper, generate_unique_key, proper_case, getDbInfoId, isDefaultLiveDb, get_chart_type_based_on_column_response, hideLastPopup } from '../../utils';
import _, { find } from 'lodash'

import { showPopup } from '../../utils';
import * as enums from '../../utils/enums';
import LayoutPopup from './layout.popup';
import { defaultLayout } from '../../utils/default.layout';
import SelectDisplayField from './global.filter.popup';
import ConfirmBox from '../confirm-box';
import { get_db_info } from '../../actions/connection';
import Editor from '../open-route/insights/editor';
import Overlay from '../helper/overlay'
import RightSidePanel from './right.side.panel';
import PreRightOptions from './v2/right.side.small'
import { update_px_for_report_item, update_px_for_report_items, mark_reports_deleted, paste_items } from './v2';
import { GetDefaultObject, TemplateTypes } from './element.tempate';
import dimensions from '../dimensions';
import { get_user_preferences } from '../../actions/account';
import { getAccessGroupWithUsers } from '../../actions/access_group';
import { clearEditCurrentReport, getReportByReportId, get_all_reports } from '../../actions/report.helper';
import { getMenuGroups } from '../../actions/menu.group';
import { apply_color_sets_to_colors, bind_theme_data_to_object, themeObject } from '../../theme';
import { get_layout_json_data } from './auto.layout.editor';
import { get_layout_json_data_v1 } from './v1.2.1.1';
import { get_filters_options_v1 } from '../../actions/filter';
import { getFromSession } from '../../utils/session.helper';
import { constants } from '../../utils/constants';



let v2_wrapper_initial_height = 0;
let v2_wrapper_initial_width = 0;
let v2_wrapper_print_height = 0;

const line_color = '#6C8EBF';

let ctrlKey = 17;
let cmdKey = 91;
let vKey = 86;
let cKey = 67;
let keyUp = 38;
let keyDown = 40;
let keyLeft = 37;
let keyRight = 39;
let shiftKey = 16;

const resize_stages = {
    none: 0,
    resizing: 1,
};

let run_debounce = false;
let s_t = 0;
let s_l = 0;

let elementRef = undefined;
let elementStyle = undefined;
let activeIndex = undefined;
let resize_stage = resize_stages.none;

let wrapper_inital_Height = 0;
let wrapper_inital_Width = 0;

let moveElementStyle = undefined;
let moveElementIndex = undefined;
let moveElement_ref = undefined;
let moveElement_prev_style = undefined;;
let isStateSet = true;
let isLayoutFound = false;

let prev_page_x = 0;
let prev_page_y = 0;

let stop_counter_for_debounce = 0;

let prev_top_ruler = 0;
let prev_left_ruler = 0;

let move_min_if_called_once = .20;

let called_once = false;
let ruler_line_for_state = undefined;


let ctrlDown = false;
let shiftDown = false;

let copy_enabled = false;

const dimensions_to_save = {};




const group_report_items_with_union = (report_items) => {

    const primary_union_report = [];

    // console.log("=======report_items", report_items)
    const filterd_union_report_items = report_items?.filter((r) => r.connected_ids_tag) || [];
    const un_union_report_items = report_items.filter((r) => (!r.connected_ids_tag || r.connected_ids_tag === null)) || [];

    const uniq_connected_ids = {};

    filterd_union_report_items?.forEach((r) => {

        if (!uniq_connected_ids[r.connected_ids_tag]) uniq_connected_ids[r.connected_ids_tag] = [];

        uniq_connected_ids[r.connected_ids_tag].push(r);
    })


    uniq_connected_ids && Object.keys(uniq_connected_ids).forEach(key => {

        let primary = undefined;
        let un_primary = [];

        uniq_connected_ids[key]?.forEach((r) => {
            if (!r.union) primary = r;    // it is a priamry report item which hold all the information about the report item;
            else un_primary.push(r);   // it is a union report item
        })

        if (primary) {
            if (un_primary.length > 0) {
                primary.union_report_items = un_primary;

            }
            primary_union_report.push(primary)
        }
    })

    // console.log("un_union_report_items", report_items, un_union_report_items)

    const final_report_items_to_return = [...un_union_report_items, ...primary_union_report];
    return final_report_items_to_return;

}


const LayoutEditor = (props) => {

    //#region old
    const { saveInsight, close, popupKey, option, history, reportAccessGroupData } = props;
    const { activeEditLayout, user, activeInsight, all_reports } = props;

    const [scroll_postion, set_scroll_postion] = useState({
        top: 0,
        left: 0
    });


    // console.log("his", history)

    const location = history?.location;



    const [localReport, setReport] = useState(undefined);
    const [wrapperHeight, setWrapperHeight] = useState(undefined);
    const [wrapperWidth, setWrapperWidth] = useState(undefined);
    const [lineArray, setLineArray] = useState([]);
    const [separatorLine, setSeparatorLine] = useState([]);
    const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
    const [startY, setStartY] = useState(0);
    const [rulerLine, setRulerLine] = useState(false);
    const [moveElementId, setMoveElementId] = useState(undefined);
    const [groupId, setGroupId] = useState([]);
    const [boxBorder, setBoxBorder] = useState(undefined);
    const [shape_overlapId, set_shape_overlapId] = useState(undefined);
    const [localHintsByEntity, setLocalHintsByEntity] = useState(undefined);
    const [filteredItems, setfilteredItems] = useState([]);


    const [menu_group_id, set_menu_group_id] = useState('');
    const [is_menu_group_new, set_is_menu_group_new] = useState(false)

    const [filterDefaultValues, setFilterDefaultValues] = useState(undefined);
    const [positioningReportPage, setPositioningReportPage] = useState(undefined);



    const [openDropDown, setOpenDropDown] = useState(undefined)
    const [activeReport, setActiveReport] = useState(undefined)
    const containerRef = useRef(null);
    const mainWrapperRef = useRef(null);
    const container_width_control_ref = useRef(null);
    const [rightPanelOpen, setRightPanelOpen] = useState(undefined)
    const [copiedIndex, setCopiedIndex] = useState(undefined);

    // these array will be used to verify what is selected and what is not
    const [selected_ids, set_selected_ids] = useState([]);

    const [copied_ids, set_copied_ids] = useState([]);

    const [localActiveReport, setLocalActiveReport] = useState(undefined)
    const [isOverlayShow, setIsOverlayShow] = useState(undefined)

    const [anchorPoint, setAnchorPoint] = useState(undefined)
    const [showMenu, setShowMenu] = useState(undefined)

    const [access_group_report_mapping, set_access_group_report_mapping] = useState({})

    const [db_info_id, set_db_info_id] = useState();
    const [reporting_db_info_id, set_reporting_db_info_id] = useState(undefined);
    const [is_hierarchy_filter, set_is_hierarchy_filter] = useState(undefined);

    const [widgetsData, setWidgetsData] = useState(undefined)



    const [loading_db, set_loading_db] = useState(undefined);


    let is_form_editor = getParameterByName("form_editor");
    let is_map_editor = getParameterByName("map_editor");
    let temp_tbl_name = localReport ? 'dynamic_table ' + localReport.title : '';
    const reportId = getParameterByName('reportId');


    let new_temp_tbl_name = (temp_tbl_name && temp_tbl_name.length > 0) ? temp_tbl_name.trim().split(' ').join('_') : temp_tbl_name;
    let generated_table_name = generate_unique_key(new_temp_tbl_name);

    const IS_DEFAULT_LIVE_DB = isDefaultLiveDb()



    const get_selected_user_with_access = (access_menu, access_group_report_mapping, access_report_exclude_user_mapping) => {
        const selected_data = {};
        if (access_group_report_mapping) {
            access_menu?.forEach(acc => {
                access_group_report_mapping.forEach(inn => {
                    if (acc.id === inn.access_group_id) {
                        const filtered_users = (acc?.users?.filter(u =>
                            !access_report_exclude_user_mapping.some(u1 => u1.user_id === u.id)
                        ) || []).map((_u) => _u.id);
                        selected_data[acc.id] = filtered_users;
                    }
                });
            });
        }


        return selected_data;
    }


    const get_map_and_unmapped_user_or_role = (data, access_menu) => {

        const unmapped_users = [];
        const access_group_report_mapping_data = [];

        if (Object.keys(data).length > 0) {
            Object.keys(data).forEach(key => {
                if (data[key].length > 0) {
                    const user_ids = data[key];
                    const access_group_data = access_menu.find(a => a.id === key);

                    if (access_group_data?.users?.length > 0) {
                        access_group_data.users.forEach(u => {
                            if (!user_ids.includes(u.id)) {
                                unmapped_users.push(u.id);
                            }
                        });
                        access_group_report_mapping_data.push(key);
                    }
                }
            });
        }

        return { unmapped_users, access_group_report_mapping_data };
    }


    useEffect(() => {

        const db_info_id = getDbInfoId()

        const db_info_id_to_use = IS_DEFAULT_LIVE_DB ? db_info_id : (db_info_id && (db_info_id + "__nf__db__cached__"));

        if (db_info_id) set_db_info_id(db_info_id)

        if (!reporting_db_info_id) set_reporting_db_info_id(db_info_id_to_use);

    }, [])



    useEffect(() => {

        if (reporting_db_info_id) {
            props.get_filters_options_v1(reporting_db_info_id)
        }
    }, [reporting_db_info_id])


    useEffect(() => {

        if (!props.all_reports || props.all_reports.length === 0) {
            props.get_all_reports()
        }

        props.getAccessGroupWithUsers()
        props.getMenuGroups()

        if (containerRef.current && mainWrapperRef.current) {
            const __container__height__ = mainWrapperRef.current.offsetHeight - 80;
            const __container__width__ = mainWrapperRef.current.offsetWidth - 20;

            let height = containerRef.current.offsetHeight;
            let width = containerRef.current.offsetWidth;
            const diff = __container__width__ - width;


            const _ratio = diff / __container__width__;
            const _new_height = __container__height__ * (1 - _ratio);
            wrapper_inital_Height = _new_height;
            wrapper_inital_Width = __container__width__
            setWrapperHeight(_new_height);
            setWrapperWidth(width);
        }
    }, []);


    /**
     * 
     * @param {*} report 
     * @param {*} wrapper_height 
     * @param {*} wrapper_width 
     * 
     * A v2 update on handing state and modifying dimensions in a better way
     */
    const v2_update_report_dimensions_and_save_to_state = (report, wrapper_height, wrapper_width) => {
        const report_items = (report && report.report_items) || [];

        const report_items_with_px = update_px_for_report_items(report_items, wrapper_height, wrapper_width);
        // convert_report_percentage_to_px(clone_report)

        setReport({
            ...report,
            report_items: group_report_items_with_union(report_items_with_px)
        });


        const temp_reporting_db_info_id = report?.reporting_db_info_id;
        if (temp_reporting_db_info_id) set_reporting_db_info_id(temp_reporting_db_info_id);



        const _access_group_report_mapping = report?.access_group_report_mapping || [];
        const _access_report_exclude_user_mapping = report?.access_report_exclude_user_mapping || [];
        // const access_group_report_mapping_ids = _access_group_report_mapping?.length > 0 && _access_group_report_mapping?.map(a => a.access_group_id) || [];
        const selected_access_data = get_selected_user_with_access(reportAccessGroupData, _access_group_report_mapping, _access_report_exclude_user_mapping)
        console.log("selected_access_data", selected_access_data)
        if (selected_access_data && Object.keys(selected_access_data)?.length > 0) {
            set_access_group_report_mapping(selected_access_data)
        } else {
            set_access_group_report_mapping({})
        }

        if (report?.menu_group_id) set_menu_group_id(report.menu_group_id)
        else set_menu_group_id('')

    };


    /**
     * 
     * @param {*} report_item while sending the report item, please make sure you are not sending a mutated object, but it should be a clone copy
     * @param {*} report_id 
     * @param {*} is_new_item 
     * 
     * 
     */
    const v2_update_single_item_and_save_to_state = (report_item, report_id, is_new_item = false) => {
        if (is_new_item) {
            setReport({
                ...localReport,
                report_items: [...(localReport.report_items || []), report_item]
            });
        }
        else {
            setReport({
                ...localReport,
                report_items: (localReport.report_items || []).map(item => item.id === report_id ? report_item : item)
            });
        }
    };


    /**
     * 
     * @param {*} id 
     * @param {*} is_shift_key_pressed 
     * 
     * if shift key is pressed, just add to the previous selected ids
     */
    const v2_select_report_item = (id, is_shift_key_pressed) => {
        // we will make sure that if the id is already there, then we will remove from selection
        const existing_id_index = selected_ids.indexOf(id);

        if (is_shift_key_pressed) {
            if (existing_id_index > -1) {
                // id already here, lets remove to unselect
                const selected_ids_copy = [...selected_ids];

                selected_ids_copy.splice(existing_id_index, 1);
                set_selected_ids(selected_ids_copy);
            }
            else {
                // not existing, just add
                const selected_ids_copy = [...selected_ids, id];

                set_selected_ids(selected_ids_copy);
            }
        }
        else {
            // shift key not pressed, let's not remove others if we are clicking on already selected item

            if (selected_ids.indexOf(id) === -1) set_selected_ids([id]);
        }
    };


    useEffect(() => {
        const clone_report = localReport ? JSON.parse(JSON.stringify(localReport)) : {};
        localReport && v2_update_report_dimensions_and_save_to_state(clone_report, v2_wrapper_initial_height, v2_wrapper_initial_width);
    }, [])


    useEffect(() => {
        if (props.hintsForFilter && Object.keys(props.hintsForFilter).length > 0) {
            let tempArray = Object.keys(props.hintsForFilter);
            setLocalHintsByEntity(Array.from(new Set(tempArray)));
        }
    }, [props.hintsForFilter])





    useEffect(() => {
        props.get_db_info()
        props.get_user_preferences()
        props.getHintsByClientId()
        props.getHintsForRules("nf_rule")
    }, [])


    useEffect(() => {
        v2_read_and_assign_wrapper_dimensions();

    }, [containerRef])

    const get_wrapper_height_from_ref = () => {
        if (containerRef.current) {
            let _height = containerRef.current.offsetHeight
            return _height;
        }
    }


    const get_wrapper_width_from_ref = () => {
        if (containerRef.current) {
            let _width = containerRef.current.offsetWidth
            return _width;
        }
    }


    const getMoveElRef = (top, left, index) => {
        changeReportItemsValues(top, left, undefined, undefined, index);

    }


    const checkValueInArray = (array, value) => {
        let result = undefined;
        for (let i = 0; i < array.length; i++) {
            let element = array[i];
            if ((element && element.toLowerCase()) === (value && value.toLowerCase())) {
                result = true;
                break;
            }
        }
        return result;
    }


    /***
     * this function will change the height of wrapper
     * 
     */
    const change_wrapper_height_if_required = () => {

        if (localReport) {
            let biggest_element = 0;
            let biggest_element_height = 0;

            let reportItems = localReport["report_items"] ? localReport["report_items"] : [];

            reportItems && reportItems.length > 0 && reportItems.filter((r) => !r.deleted).map((report, index) => {
                let _height = parseInt(report.height_px);
                let _top = parseInt(report.top_px);
                let element_height = parseInt(_height + _top);
                if (element_height > biggest_element_height) {
                    biggest_element_height = element_height;
                    biggest_element = report;
                }
            });
            // console.log("biggest_element_height", biggest_element_height, wrapper_inital_Height)
            let factor = parseInt(biggest_element_height / wrapper_inital_Height) + 1;
            setWrapperHeight(wrapper_inital_Height * factor);
        };
    };

    /***
     * this function will return sorter arrray by position which is displaing
     * 
     */
    const assign_index_to_reportItem = (report) => {

        let final_report = {};
        let final_report_items = [];

        const tempReport = report ? { ...report } : {}

        const reportsItems_array = tempReport.report_items && tempReport.report_items.length > 0 && tempReport.report_items;

        const reportItems = reportsItems_array && reportsItems_array.length > 0 && reportsItems_array.filter(item => {
            return !item.deleted
        });

        let sortedReport = reportItems.sort((a, b) => {
            return parseFloat(a.top) - parseFloat(b.top);
        })

        for (let i = 0; i < sortedReport.length; i++) {
            sortedReport[i].index = i + 1
        }
        return sortedReport

    }



    const find_if_point_exist_within_shape = (shape, point) => {

        const min_left = Math.min(shape.p1.left, shape.p2.left, shape.p3.left, shape.p4.left)
        const max_left = Math.max(shape.p1.left, shape.p2.left, shape.p3.left, shape.p4.left)
        const min_top = Math.min(shape.p1.top, shape.p2.top, shape.p3.top, shape.p4.top);
        const max_top = Math.max(shape.p1.top, shape.p2.top, shape.p3.top, shape.p4.top);

        const top = point.top;
        const left = point.left;

        return min_top <= top && top <= max_top && min_left <= left && left <= max_left;

    }


    // getReportByReportId

    useEffect(() => {

        const isEdit = getParameterByName('edit', location.search);
        const reportId = getParameterByName('reportId', location.search);

        // if (reportId && (props?.editCurrentReport?.id !== reportId && props?.defaultInsight?.id !== reportId)) {

        if (reportId && (props?.editCurrentReport?.id !== reportId)) {

            reportId && props.getReportByReportId(reportId)
            reportId && set_loading_db(true)

            console.log("please wait....")

            props.clearEditCurrentReport()

        }
    }, [location?.search])


    useEffect(() => {
        return () => {
            // let's clear edited report 
            props.clearEditCurrentReport()
            console.log("cp killed here")
        }
    }, [])



    /**
     * v2 code
     * 16 Feb 2023
     * saroj kr
     */

    useEffect(() => {


        // we got the edited report from backend 

        // if (reportId && (props?.editCurrentReport?.id == reportId || props?.defaultInsight?.id == reportId)) {

        if (reportId && (props?.editCurrentReport?.id == reportId)) {

            const report_to_use = props?.editCurrentReport?.id === reportId ? props.editCurrentReport : props?.defaultInsight;
            const cloned_data = report_to_use ? JSON.parse(JSON.stringify(report_to_use)) : {};

            console.log("clone done enjoy :-", props.editCurrentReport)

            v2_update_report_dimensions_and_save_to_state(cloned_data, v2_wrapper_initial_height, v2_wrapper_initial_width);

            if (report_to_use && report_to_use.report_filter) {
                setfilteredItems(report_to_use.report_filter)
            }
            if (report_to_use && report_to_use.filter_default_value_json) {
                const filter_default_value_json_temp = report_to_use.filter_default_value_json;
                setFilterDefaultValues(JSON.parse(filter_default_value_json_temp))
            }
            if (report_to_use.is_hierarchy_filter) {
                set_is_hierarchy_filter(report_to_use.is_hierarchy_filter)
            }
            set_loading_db(false)

        }
        else if (reportId) {
            // do nothing 


        } else {

            console.log("i am else", reportId)
            const _title = is_form_editor ? 'Your\'s Today Prescription' : (is_map_editor ? 'Your\'s Today Quick Map' : '')

            const template = {
                name: 'Quick Insight',
                title: _title,
                type: is_form_editor ? 'dynamic_forms' : is_map_editor ? 'maps' : 'insights',
                user_id: user ? user.id : '',
                report_items: []
            };


            console.log('****** quick insight check started!!!!!****** ');


            if (is_form_editor) {

                console.log('*      Inside Block 1');
                template.report_items.push({
                    id: generate_unique_key("nf_sr"),
                    is_created: true,
                    question: '',
                    title: '',
                    is_active: 1,
                    width: .2,
                    height: 65,
                    element_type: "button",
                    option_values: '',
                    element_label: 'button',
                    tag: 'button',
                    top: wrapper_inital_Height - 150,
                    left: get_wrapper_width_from_ref() - 250,
                    whichFormat: "px",
                    is_group: false,
                    modified: true
                });
            }


            if (is_map_editor) {
                console.log('*      Inside MAP Block');
                const map_element = {
                    id: generate_unique_key("nf_sr"),
                    is_created: true,
                    question: '',
                    title: '',
                    is_active: 1,
                    width: .35,
                    height: .90,
                    chart_type: 'map',
                    render_mode: 'map',
                    top: 0,
                    left: 0,
                    is_group: false,
                    modified: true
                };

                const new_item_with_px = update_px_for_report_item(map_element, v2_wrapper_initial_height, v2_wrapper_initial_width);
                template.report_items.push(new_item_with_px);
            }
            console.log('*      setting template: ', template);
            setReport(template);
            setFilterDefaultValues(undefined)
        }
    }, [props.editCurrentReport, location?.search])




    const get_active_edit_report = (all_reports, id) => {
        const clone_all_reports = all_reports ? JSON.parse(JSON.stringify(all_reports)) : [];
        const report_to_use = clone_all_reports?.find(report => report.id === id);
        return report_to_use ? JSON.parse(JSON.stringify(report_to_use)) : undefined;
    }



    /***
     * test
     */
    // saroj
    useEffect(() => {
        // const isEdit = getParameterByName('edit');
        // const clone_report_to_use = get_active_edit_report(all_reports, reportId)
        // if (isEdit && (!activeEditLayout || (activeEditLayout && !activeEditLayout.id))) {
        //     v2_update_report_dimensions_and_save_to_state(clone_report_to_use, v2_wrapper_initial_height, v2_wrapper_initial_width);
        //     // convert_report_percentage_to_px(temp_clone_report);
        // }
        // if (clone_report_to_use && clone_report_to_use.report_filter && isEdit) {
        //     setfilteredItems(clone_report_to_use.report_filter)
        // }
        // if (clone_report_to_use && clone_report_to_use.filter_default_value_json && isEdit) {
        //     const filter_default_value_json_temp = clone_report_to_use.filter_default_value_json || '{}';
        //     setFilterDefaultValues(JSON.parse(filter_default_value_json_temp))
        // }

    }, [all_reports])




    useEffect(() => {
        change_wrapper_height_if_required();
        // change_wrapper_height_if_required_2()
    }, [localReport])





    useEffect(() => {

        const auto_layout_id = getParameterByName('auto_layout_id');
        const autoLayoutData = props?.autoLayoutData?.[auto_layout_id];

        if (autoLayoutData && autoLayoutData["widgets"]?.length > 0) {


            const db_info_id = getDbInfoId()

            const db_info_id_to_use = IS_DEFAULT_LIVE_DB ? db_info_id : (db_info_id && (db_info_id + "__nf__db__cached__"));

            // showPopup(undefined, "Seeking the Dashboard...", enums.default.popupType.loading)
            set_loading_db("Please wait while we are seeking the dashboard...")

            const widgets = autoLayoutData["widgets"]?.map((a) => {
                return {
                    ...a,
                    id: generate_unique_key("auto_design")
                }
            });

            props.get_question_info_for_dashboard_v2(widgets, db_info_id_to_use,);
            setWidgetsData(widgets)

        }
    }, [props.autoLayoutData])


    useEffect(() => {

        if (props.questionInfoDataV2 && widgetsData) {
            set_loading_db(false)

            const auto_layout_id = getParameterByName('auto_layout_id');
            const autoLayoutData = props?.autoLayoutData?.[auto_layout_id];

            const report_template = {
                name: 'Quick Insight',
                type: 'insights',
                user_id: user ? user.id : '',
                report_items: []
            }

            const title = autoLayoutData["title"];
            showPopup("Awesome !", "Your Dashboard layout is ready.", enums.default.popupType.sweet_alert)

            const clone_widgetsData = widgetsData ? [...widgetsData] : {};

            const question_based_meta = get_chart_type_based_on_column_response(props?.questionInfoDataV2)


            clone_widgetsData.map((w) => {
                if (w.question) {
                    const info = question_based_meta[w?.id];
                    w.chart_type = info?.chartType;
                    w.render_mode = 'chart'
                    w.title = info?.title || ''
                }
            })
            // let's create it automaticly
            // let's map the question and chart type with proper id

            console.log("clone_widgetsData", clone_widgetsData, question_based_meta)
            const report_items = get_layout_json_data_v1(clone_widgetsData, v2_wrapper_initial_width, v2_wrapper_initial_height)
            report_template["report_items"] = report_items;
            report_template["title"] = title;
            report_template["name"] = title;
            setReport(report_template);

        }

    }, [props.questionInfoDataV2])


    const _can_start = () => {
        // create_helping_line();
        // check_if_item_InsideGroup();
    }


    const dragOver = (e) => {

        e.preventDefault();
        e.stopPropagation();

        const this_page_x = e.pageX * 1;
        const this_page_y = e.pageY * 1;
        const diff_x = (Math.abs(this_page_x - prev_page_x) / this_page_x) * 100;
        const diff_y = (Math.abs(this_page_y - prev_page_y) / this_page_x) * 100;
        const abs_x = Math.abs(this_page_x - prev_page_x);
        const abs_y = Math.abs(this_page_x - prev_page_x);

        if (diff_x > 0 && diff_x < 0.20 && diff_y < 0.20) {
            if (!called_once) {
                called_once = true;
                _can_start();
            }
        }
        if (abs_x > 0 && (abs_x < 2 || abs_y < 2)) {
            stop_counter_for_debounce++;
            if (stop_counter_for_debounce > 5) {
                setRulerLine(ruler_line_for_state);
                stop_counter_for_debounce = 0
            }
        }
        else if (abs_x > 0) {
            setRulerLine(undefined);
        }
        if (diff_x > move_min_if_called_once || diff_x > move_min_if_called_once) {
            called_once = false;
        }
        prev_page_x = this_page_x * 1;
        prev_page_y = this_page_y * 1;
    }


    const mouse_moved = (e) => {
        set_current_position({ y: e.clientY, x: e.clientX });
    }

    const initResize = index => {
        activeIndex = index;
        resize_stage = resize_stages.resizing;
    };

    const initDrag = (index, x, y) => {
        activeIndex = index;
        resize_stage = resize_stages.none;
        setStartPosition({ x, y });
    };


    const stopDrag = (e) => {
        activeIndex = undefined;
        resize_stage = resize_stages.none;
        set_current_position({ x: 0, y: 0 });
    };

    const stopResize = (e) => {
        resize_stage = resize_stages.none;
        activeIndex = undefined;
    };

    const createGroupElement = () => {

        const new_group_item = GetDefaultObject(TemplateTypes.group);
        const new_group_item_px = update_px_for_report_item(new_group_item);

        v2_update_single_item_and_save_to_state(new_group_item_px, undefined, true);
    }

    const copyElement = () => {
        set_copied_ids([...selected_ids]);
    };

    const pasteElement = () => {

    };


    const createNewElement = (options, is_report = false) => {
        const new_item = {
            ...GetDefaultObject(TemplateTypes.chart),
            ...options,
            report_item_type: 'dashboard',
            render_mode: is_report ? 'report' : 'chart',
            reporting_db_info_id: reporting_db_info_id,
            hide_x_axis_label: true,
            hide_y_axis_label: true
        };

        // this is going to through menu, so it will always be one item, just try to add it where we added first element

        const selected_element_id = (selected_ids.length > 0) ? selected_ids[0] : -1;
        const selected_element = localReport.report_items && localReport.report_items.find(item => item.id === selected_element_id);

        const type = new_item.chart_type;


        const new_element_dimension_details = get_new_element_left_top();

        let width = .20;
        let height = .20;

        let top = .40;
        let left = .40;

        // here we are assign the first db info default
        if (props.db_infos && props.db_infos.length > 0) {
            new_item.db_info_id = props.db_infos[0].id
        }

        if (selected_element) {
            top = selected_element.top;
            left = selected_element.left;
        }
        if (type === 'pie_chart' || type === 'radar_chart' || type === 'donut_chart') {
            width = .20;
            height = .20;
        }
        if (type === 'label') {
            width = .20;
            height = .05;
            new_item.render_mode = 'label';

        }

        if (type === 'single_cell') {
            new_item.render_mode = 'chart'
        }

        if (type === 'table') {
            new_item.render_mode = 'table';

        }
        if (type === 'group') {
            new_item.is_group = true;
            new_item.render_mode = 'group';

        }
        new_item.width = width;
        new_item.height = height;
        new_item.top = top;
        new_item.left = left;

        const new_item_with_px = update_px_for_report_item(new_item, v2_wrapper_initial_height, v2_wrapper_initial_width);

        if (selected_element) {
            new_item_with_px.top_px = new_item_with_px.top_px + 20;
            new_item_with_px.left_px = new_item_with_px.left_px + 20;
        }

        new_item_with_px.top_px = new_element_dimension_details.top// ;new_item_with_px.top_px + 20;
        // console.log("new_item_with_px", new_item_with_px, new_element_dimension_details)
        v2_update_single_item_and_save_to_state(new_item_with_px, new_item.id, true);

        setTimeout(() => {
            v2_select_report_item(new_item_with_px.id, false);
            let temp_selected_ids = [];
            temp_selected_ids.push(new_item.id);
            set_selected_ids(temp_selected_ids)
            setRightPanelOpen(true);
        }, 100);
    };



    const convert_hints_in_to_tbl_column_array = () => {
        const result = [];
        props.hintsForFilter && Object.keys(props.hintsForFilter).length && Object.keys(props.hintsForFilter).map((h) => {
            if (h !== 'response_session_id') {
                const table_info = props.hintsForFilter[h].table_info;
                table_info && table_info.length > 0 && table_info.forEach((t) => {
                    const str = t + "." + h;
                    if (result.indexOf(str) === -1) {
                        result.push(str);
                    }
                })
            }
        })
        return result;
    }

    const FilterPopup = () => {

        // const is_developer_mode = (props.userPreference && props.userPreference.setting_value && props.userPreference.setting_value === 'enable')
        // const r = is_developer_mode ? convert_hints_in_to_tbl_column_array() : localHintsByEntity;

        const filter_options = (props?.filterOptions && Object.keys(props.filterOptions)?.length > 0 && Object.keys(props.filterOptions) || []).filter((f) => {
            if (f.indexOf("nf_human_interpretation_nf") == -1) return true;
        })
        // console.log("filter_options", filter_options)

        showPopup('Select Filter Column', undefined, enums.default.popupType.element_with_header, SelectDisplayField,
            {
                menus: props.menus,
                displayItem: filter_options ? filter_options : [],
                selectedFields: filteredItems ? filteredItems : [],
                setSelectedFields: setfilteredItems,
                filterDefaultValues: filterDefaultValues,
                setFilterDefaultValues: setFilterDefaultValues,
                smallPopup: undefined,
                is_hierarchy_filter: is_hierarchy_filter,
                set_is_hierarchy_filter: set_is_hierarchy_filter
            }, undefined, undefined, undefined, {
            width: '62rem',
        }
        );
    }


    const createElementForPrescription = type => {
        let width = .20;
        let height = .15;
        const top = .40;
        const left = .40;
        const reportItemTemplate = {
            id: generate_unique_key("nf_sr"),
            is_created: true,
            question: '',
            title: '',
            is_active: 1,
            width,
            height,
            element_type: type,
            option_values: '',
            element_label: '',
            tag: '',
            top,
            left,
            is_group: false
        }
        if (localReport && localReport.id) {
            reportItemTemplate.report_id = localReport.id;
        }

        let local_report_item = JSON.parse(JSON.stringify(reportItemTemplate));
        const tempReport = JSON.parse(JSON.stringify(localReport));

        if (tempReport.report_items) {
            tempReport.report_items.push(local_report_item);
        }

        v2_update_report_dimensions_and_save_to_state(tempReport, v2_wrapper_initial_height, v2_wrapper_initial_width);

        // convert_report_percentage_to_px(tempReport);
    };


    const findReportPageIndex = (report) => {
        let result = 0;
        const separatorLine_clone = separatorLine ? JSON.parse(JSON.stringify(separatorLine)) : [];
        const separatorLine_sorted = (separatorLine_clone?.length > 0 && separatorLine_clone?.sort((a, b) => b.index - a.index)) || [];
        if (separatorLine_sorted && separatorLine_sorted.length > 0) {
            for (let index = 0; index < separatorLine_sorted.length; index++) {
                const element = separatorLine_sorted[index];
                // 1210 > 1200 
                if (parseInt(report.top_px) > parseInt(element.top)) {
                    // if(report.id === 'rpt_l42ztzssl42zvuua' || report.id === 'rpt_l431dgr6l431dz88'){
                    //     console.log("hii", element, report.top_px, report.id)
                    // }
                    result = element.index;
                    break;
                }
            }
        }

        return result
    }

    const duplicateElement = (element) => {
        showPopup(undefined, 'Would you like to copy the selected items? ', enums.default.popupType.element, ConfirmBox, { func: { setYes: () => copyElement() && pasteElement() } })
    };


    const changeReportItemsValues = (top, left, height, width, index, any_other_params_to_add_to_report_item = undefined) => {
        const tempReport = Object.assign({}, localReport);
        const tempReportItems = (tempReport && tempReport.report_items) || [];;

        if (tempReportItems && tempReportItems[index]) {
            const modified_report_item = any_other_params_to_add_to_report_item ? { ...tempReportItems[index], ...any_other_params_to_add_to_report_item } : { ...tempReportItems[index] };

            modified_report_item.modified = true;

            if (typeof top !== undefined && top > 0) {
                modified_report_item.top = Math.max(1, top) / v2_wrapper_initial_height;
                modified_report_item.top_px = top;
            }

            if (typeof left !== undefined && left > 0) {
                modified_report_item.left = Math.max(1, left) / v2_wrapper_initial_width;
                modified_report_item.left_px = left;
            }

            if (typeof height !== undefined && height > 0) {
                modified_report_item.height = Math.max(1, height) / v2_wrapper_initial_height;
                modified_report_item.height_px = height;
            }

            if (typeof width !== undefined && width > 0) {
                modified_report_item.width = Math.max(1, width) / v2_wrapper_initial_width;
                modified_report_item.width_px = width;
            }

            if (typeof dimensions_to_save[reportId] === 'undefined') {
                dimensions_to_save[reportId] = {};
            }

            dimensions_to_save[reportId][index] = modified_report_item;

            const __report_len__ = (dimensions_to_save[reportId] && Object.keys(dimensions_to_save[reportId]).length > 0) ? Object.keys(dimensions_to_save[reportId]).length : 1;


            setTimeout(() => {
                apply_positions_to_controls();
            }, __report_len__ * 100);
        }
    };


    const apply_positions_to_controls = () => {

        const dimensions_for_report_id = dimensions_to_save[reportId];
        const indexes = (dimensions && Object.keys(dimensions_for_report_id)) || [];;

        if (indexes.length > 0) {
            const dimensions = Object.assign({}, dimensions_for_report_id);
            dimensions_to_save[reportId] = {};

            const tempReport = Object.assign({}, localReport);
            const tempReportItems = (tempReport && tempReport.report_items) || [];;

            indexes.forEach(i => {
                const _index = parseInt(i);
                tempReportItems[i] = dimensions[_index];
            });

            setReport({
                ...localReport,
                report_items: tempReportItems
            });
        }
    };


    const save = (is_run_report) => {

        if (localReport && localReport.title && localReport.title.length > 0) {

            const tempReport = localReport ? JSON.parse(JSON.stringify(localReport)) : {};
            // let process = check_is_any_shape_overlay();
            let process = true;
            const isEdit = getParameterByName('edit');

            if (process) {

                props.clearEditCurrentReport() // clear the current report

                const tempWidth = wrapperWidth;
                const tempHeight = wrapperHeight;
                const results = [];

                const tempReportItems = (tempReport.report_items && tempReport.report_items.length > 0) ? JSON.parse(JSON.stringify(tempReport.report_items)) : [];

                if (is_form_editor) {
                    let temp_tags = [];
                    tempReportItems && tempReportItems.length > 0 && tempReportItems.forEach(reportItem => {
                        if (!reportItem.id && reportItem.deleted) {
                            return false;
                        }
                        else {
                            if (reportItem.tag && reportItem.tag.length > 0) {
                                if (reportItem.modified) {
                                    reportItem.id = (reportItem && reportItem.id) ? (reportItem.id.indexOf('nf_sr') > -1 ? undefined : reportItem.id) : undefined;
                                    reportItem.xac = [];
                                    reportItem.yac = [];
                                }
                                if (reportItem.tag !== "button") {
                                    temp_tags.push(reportItem.tag)
                                }
                            }

                            results.push(reportItem);
                        }
                    });

                    let isFromDashboardList = getParameterByName("insightsList");
                    const dataToSave = [];
                    tempReport.report_items = results;
                    let temp_tbl_name = getParameterByName('edit') ? tempReport.table_name : generated_table_name;
                    tempReport.table_name = temp_tbl_name;
                    dataToSave.push(tempReport);
                    const id = tempReport && tempReport.id ? tempReport.id : undefined;
                    const path = isFromDashboardList ? `/insightsList?type=${isFromDashboardList}` : id ? `/?dynamic_formsId=${id}` : '/?new=true';

                    const prev_filter_items = localReport?.filter;
                    dataToSave[0].filter = filteredItems;


                    if (filterDefaultValues && Object.keys(filterDefaultValues).length > 0) {
                        let temp_filter_string = '';
                        Object.keys(filterDefaultValues).forEach((key, index) => {
                            if (key == 'date_filter') {
                                temp_filter_string = temp_filter_string + `date between "${filterDefaultValues[key]['dateFrom']}" And "${filterDefaultValues[key]['dateTo']}" ${index !== (Object.keys(filterDefaultValues).length - 1) ? 'And ' : ''}`
                            }
                            else {
                                if (filterDefaultValues[key] && filterDefaultValues[key].length > 0) {
                                    temp_filter_string = temp_filter_string + `${key} is "${filterDefaultValues[key].join('" "')}" ${index !== (Object.keys(filterDefaultValues).length - 1) ? 'And ' : ''}`
                                }
                            }
                        });

                        dataToSave[0].filter_default_value = temp_filter_string
                    }

                    dataToSave[0].filter_default_value_json = filterDefaultValues ? JSON.stringify((filterDefaultValues || {})) : undefined;


                    dataToSave[0].modified = true;

                    saveInsight(dataToSave, false, (id ? false : true), (user ? user.id : ''), close, option, popupKey, true, props.history, path, is_run_report);
                    setfilteredItems(undefined)
                    setFilterDefaultValues(undefined)
                    if (temp_tbl_name && temp_tags && temp_tags.length > 0) {
                        props.createForm(temp_tbl_name, temp_tags)
                    }
                } else {

                    tempReportItems && tempReportItems.length > 0 && tempReportItems.forEach(reportItem => {
                        reportItem.reporting_db_info_id = reporting_db_info_id;

                        reportItem.pageIndex = findReportPageIndex(reportItem);;
                        if (reportItem.deleted && reportItem.is_created) {
                            return false;
                        }
                        else {
                            if (reportItem.modified) {

                                if (reportItem?.frequency_value && reportItem?.frequency_type) {
                                    var fq = reportItem?.frequency_value + "____nfx___" + reportItem?.frequency_type
                                    reportItem.frequency = fq;
                                }

                                if (reportItem.report_item_type === 'dashboard') {
                                    // clear the criteria of the report item 
                                    // we don't need criteria for plain english
                                    reportItem.criteria = ''
                                }
                                const report_item_additional_info = reportItem.report_item_additional_info;

                                if (reportItem.render_mode === 'chart') {
                                    // const __axis__info = get_x_axis_y_axis_and_comparision_by_additional(report_item_additional_info)
                                    // reportItem.id = (reportItem && reportItem.id) ? (reportItem.id.indexOf('nf_sr') > -1 ? undefined : reportItem.id) : undefined;
                                    // reportItem.xac = __axis__info.x_axis;
                                    // reportItem.yac = __axis__info.y_axis;
                                    // reportItem.xac.push(...__axis__info.comparisons);

                                } else {
                                    reportItem.report_item_additional_info = [];
                                    reportItem.id = (reportItem && reportItem.id) ? (reportItem.id.indexOf('nf_sr') > -1 ? undefined : reportItem.id) : undefined;
                                    reportItem.xac = [];
                                    reportItem.yac = [];
                                    reportItem.comparisons = [];
                                }

                            }
                            results.push(reportItem);
                        }
                    });


                    const { unmapped_users, access_group_report_mapping_data } = get_map_and_unmapped_user_or_role(access_group_report_mapping, reportAccessGroupData);;

                    let isFromDashboardList = getParameterByName("insightsList");
                    const dataToSave = [];
                    tempReport.report_items = results;

                    tempReport.access_group_report_mapping = (access_group_report_mapping_data?.length > 0 ? access_group_report_mapping_data : (unmapped_users?.length > 0) ? [] : ["any"])

                    tempReport.access_report_exclude_user_mapping = unmapped_users;

                    tempReport.menu_group_id = menu_group_id;
                    tempReport.is_menu_group_new = is_menu_group_new;
                    tempReport['modified'] = true;
                    dataToSave.push(tempReport);

                    const prev_filters_item = (localReport?.report_filter || [])


                    if (JSON.stringify(prev_filters_item) !== JSON.stringify((filteredItems || []))) {
                        dataToSave[0]["validation_keys"] = {
                            "filter_key": generate_unique_key("nf_sr"),
                        };
                    }
                    dataToSave[0].report_filter = filteredItems;

                    const own_env = ['demo_med', 'sbi', 'hsl', 'tata_play', 'hrpl_new_olap'];

                    const year_by_c_id = {
                        'demo_med': '2021',
                        'hsl': '2022',
                        'sbi': '2021',
                        'tata_play' : '2023',
                        'hrpl_new_olap' : '2024'

                    }

                    const clientIdFromSession = getFromSession(constants.SESSION_KEYS.CLIENT_ID);
                    const year__ = year_by_c_id?.[clientIdFromSession] || '2021';

                    const default_filter = filterDefaultValues ? filterDefaultValues : (clientIdFromSession && own_env.indexOf(clientIdFromSession) > -1 ? { "year": year__ } : (filterDefaultValues || {}))

                    dataToSave[0].filter_default_value_json = JSON.stringify(default_filter)

                    dataToSave[0].modified = true;
                    dataToSave[0].reporting_db_info_id = reporting_db_info_id;
                    const id = tempReport && tempReport.id ? tempReport.id : undefined;
                    setfilteredItems(undefined)
                    setFilterDefaultValues(undefined)
                    dataToSave[0].is_hierarchy_filter = is_hierarchy_filter;
                    const path = (isFromDashboardList ? `/insightsList?type=${isFromDashboardList}` : id ? `/?insightId=${id}` : is_map_editor ? 'insightsList?type=maps' : 'insightsList?type=insights')
                    saveInsight(dataToSave, false, true, user ? user.id : '', close, option, popupKey, true, props.history, path, is_run_report);
                }
            }
        } else alert("Please Provide Dashboard Title")

    };

    

    const get_x_axis_y_axis_and_comparision_by_additional = (info) => {


        const x_axis = [];
        const y_axis = [];
        const comparisons = [];

        if (info && info.length > 0) {
            info.map((i) => {
                // const column_name = i.column_name && i.column_name.length > 0 && i.column_name.split('nf_').join(' ').split('_').join(' ').split(' ').map(v => v.substring(0, 1).toUpperCase() + v.substring(1, v.length)).join(' ').trim()
                const column_name = i.column_name && i.column_name.length > 0 && i.column_name.split('nf_').join(' ').split('_').join(' ').split(' ').map(v => proper_case(v)).join(' ').trim()
                if (i.axis_position === 'Y Axis Primary' || i.axis_position === "Y Axis Secondary") {
                    y_axis.push(column_name)
                }
                if (i.axis_position === 'X Axis') {
                    x_axis.push(column_name)
                }
                if (i.axis_position === "Comparison") {
                    x_axis.push(column_name)

                    // comparisons.push(column_name)
                }
            })
        }

        return {
            "x_axis": x_axis,
            "y_axis": y_axis,
            "comparisons": comparisons
        }
    }



    const _saveInsights = (reportItem, index) => {

        const tempReportItem = Object.assign({}, reportItem);
        const tempLocalReport = Object.assign({}, localReport);

        tempReportItem.modified = true;

        if (tempLocalReport && tempLocalReport.report_items && tempLocalReport.report_items[index]) {
            tempLocalReport.report_items[index] = tempReportItem;
        }

        setReport(tempLocalReport);
    };


    const setTitle = (title) => {

        const tempLocalReport = localReport ? JSON.parse(JSON.stringify(localReport)) : {};

        tempLocalReport.title = title;
        tempLocalReport.modified = true;

        setReport(tempLocalReport);
    };


    const deleteReportItem = (reportItem, index) => {
        const tempLocalReport = JSON.parse(JSON.stringify(localReport));
        const tempReportItems = tempLocalReport && tempLocalReport.report_items && tempLocalReport.report_items.length > 0 ? tempLocalReport.report_items : undefined;

        const final_reportsItems = [];

        if (tempReportItems) {
            tempReportItems.forEach((data, tempIndex) => {

                if ((reportItem.id && data.id === reportItem.id) || (tempIndex === index)) {

                    data.deleted = true;
                }
            })
            setReport(tempLocalReport);
        }
    };



    const _close_function = () => {
        props.history.goBack();
        setFilterDefaultValues(undefined)
        setfilteredItems(undefined)
    }
    const _close = () => {
        showPopup(undefined, 'Are you sure you want to close this? ', enums.default.popupType.element, ConfirmBox, {
            func: {
                setYes: () => {
                    _close_function()
                    props.reset_data_from_server()
                }
            }
        })
    };



    const A4_PAPER_CONFIG = {
        width: 3508,
        height: 2480
    }


    useEffect(() => {

        const print_height = v2_wrapper_print_height || wrapper_inital_Height;
        // ratio of a4 size 1.41;
        let result = [];
        let pre_h = 0
        let factor = wrapperHeight / print_height;

        for (let i = 1; i < factor; i++) {
            const title_height = (print_height / 100) * 3.5;
            const line_height = (print_height / 100) * 1.5;
            const v2_minus_height = (i === 1) ? title_height : 0;
            let template = {
                top: ((print_height + pre_h) - v2_minus_height) - ((print_height / 100) * 1.2),
                index: i,
                height: (i === 1) ? title_height + line_height : line_height
            }
            // let template = {
            //     top: (print_height + pre_h)  - line_height,
            //     index: i,
            //     height: line_height
            // }
            pre_h += print_height;
            result.push(template)
        }
        setSeparatorLine(result)

    }, [wrapperHeight])


    const saveLocalReport = report_modified_data => {

        let temp_report = localReport ? JSON.parse(JSON.stringify(localReport)) : {};
        let temp_report_items = temp_report && temp_report.report_items ? temp_report.report_items : [];

        selected_ids.forEach(selected_id => {
            const index = temp_report_items.findIndex(item => item.id === selected_id);
            if (index > -1) {

                const temp_report_item = temp_report_items[index];
                // if the dimension was changed, we have to convert for the state
                temp_report_items[index] = {
                    ...temp_report_items[index],
                    ...report_modified_data
                };

                temp_report_items[index].modified = true;
            }
        });
        temp_report.report_items = temp_report_items;
        setReport(temp_report);
    };


    const __onKeyDown__ = (event, index, elementRef) => {

        if (event.tag && event.tag === 'overlay_box') return

        event.preventDefault();

        let KeyID = event.keyCode;

        if (event.keyCode == cKey && (event.ctrlKey || event.metaKey)) {
            // fire copy code
            v2_copy_items(event);
        }
        else if (event.keyCode == vKey && (event.ctrlKey || event.metaKey)) {
            // fire paste code
            v2_paste_items(event);
        }
        else {
            v2_onKeyDown(event);
        }

        switch (KeyID) {
            case 8:
                showPopup(undefined, 'Are you sure you want to delete this ? ', enums.default.popupType.element, ConfirmBox,
                    {
                        func: {
                            setYes: () => {
                                v2_delete_items(event);
                                // fire delete code
                            }
                        }
                    })

                break;
            case 46:
                showPopup(undefined, 'Are you sure you want to delete this ? ', enums.default.popupType.element, ConfirmBox,
                    {
                        func: {
                            setYes: () => {
                                v2_delete_items(event);
                                // fire delete code
                            }
                        }
                    })
                break;
            default:
                break;
        }
    }
    //#endregion

    //#region new

    /**
     * key event will be broadcasted to the children if we are not doing control+c/v/z which are parent 
     * component functions
     */
    const [key_event_broadcaster, set_key_event_broadcaster] = useState({
        currentKeyCode: 0,
        shiftKeyPressed: 0,
        counter: 0
    });

    const [current_dragging_index, set_current_dragging_index] = useState(undefined);
    const [current_position, set_current_position] = useState({
        x: 0,
        y: 0
    });

    /**
     * the method to reset height and width of wrapper.
     */
    const v2_read_and_assign_wrapper_dimensions = () => {
        if (containerRef && containerRef.current) {
            v2_wrapper_initial_height = containerRef.current.offsetHeight;
            // v2_wrapper_print_height = (containerRef.current.offsetWidth || 0) * .7069555302166477;
            v2_wrapper_print_height = (containerRef.current.offsetHeight || 0) * 1.414516129032258;

            // v2_wrapper_print_height = (containerRef.current.offsetWidth || 0) * 1.346666666666;
            v2_wrapper_initial_width = containerRef.current.offsetWidth;

        };
    };


    const v2_onKeyDown = event => {
        set_key_event_broadcaster({
            currentKeyCode: event.keyCode,
            shiftKeyPressed: event.shiftKey,
            counter: key_event_broadcaster.counter + 1
        });
    };


    const v2_copy_items = event => {
        set_copied_ids([...selected_ids]);

    };


    const v2_paste_items = event => {
        const new_report_items = paste_items(localReport.report_items, copied_ids);

        if (new_report_items && new_report_items.length > 0) {
            setReport({
                ...localReport,
                report_items: [...localReport.report_items, ...new_report_items]
            });

            set_selected_ids([...new_report_items.map(o => o.id)]);
        }
    };

    const v2_delete_items = (event, index) => {

        const report_items = mark_reports_deleted(localReport.report_items, (index ? [index] : selected_ids));

        if (report_items && report_items.length > 0) {
            setReport({
                ...localReport,
                report_items
            });

            set_copied_ids([]);
            set_selected_ids([]);
        }
    };


    const delete_report_item = (index) => {
        showPopup(undefined, 'Are you sure you want to delete this ? ', enums.default.popupType.element, ConfirmBox,
            {
                func: {
                    setYes: () => {
                        v2_delete_items(undefined, index > 0 ? index : undefined);
                        // fire delete code
                    }
                }
            })
    }


    const update_scroll = (bounce) => {
        if (bounce === run_debounce && run_debounce > 1) {
            set_scroll_postion({ top: s_t, left: s_l });
            // console.log('actual: ', s_t, s_l, run_debounce);
        }

        run_debounce = 1;
    };


    const run_scroll_debouncer = (top, left) => {
        run_debounce++;

        setTimeout(() => {
            update_scroll(run_debounce);
        }, 400);


        s_t = top;
        s_l = left;

        // console.log('should be: ', top, left);
        // setTimeout(() => {
        //     if (run_debounce == 1.1) console.log('inside 200 run_debounce with 1.1: ', run_debounce);
        // }, 200);

        // set_scroll_postion({
        //     top,
        //     left,
        // });

    }


    const handleContextMenu = useCallback(
        (event, id) => {
            event.preventDefault();
            setAnchorPoint({ x: event.pageX, y: event.pageY });
            setShowMenu(id);
        },
        [setAnchorPoint, setShowMenu]
    );


    // useEffect(() => {
    //     document.addEventListener("contextmenu", handleContextMenu);
    //     return () => {
    //         // setShowMenu(false);
    //         document.removeEventListener("contextmenu", handleContextMenu);
    //     }
    // })

    //#endregion





    const _open_auto_layout_popup = () => {
        // showPopup('Create Auto Layout', undefined, enums.default.popupType.element_with_header, AutoLayoutPopup,
        //     {
        //         menus: props.menus,
        //         generate_auto_layout: generate_auto_layout
        //     }, undefined, undefined, undefined, {
        //     width: '32rem',
        //     // maxWidth: '35rem'
        // })
    }



    const get_report_item_additional_info = (selected_ids) => {
        const __id__ = selected_ids && selected_ids.length > 0 && (selected_ids.length === 1) ? selected_ids[0] : undefined;
        const report_items = localReport && localReport.report_items || [];
        for (let index = 0; index < report_items.length; index++) {
            const report_item = report_items[index];
            if (report_item.id === __id__) {
                return report_item.report_item_additional_info;
            }
        }

    }


    const get_new_element_left_top = () => {

        const clone_report_items = (localReport?.report_items) || [];
        let top = 0;
        let left = 0;
        clone_report_items.forEach((item) => {
            const _top = item?.top_px + item?.height_px;
            const _left = item?.left_px + item?.width_px;
            if (_top > top) {
                top = _top;
                left = _left;
            }
        })

        return {
            top: top,
            left: left,
        }

    }

    const is_developer_mode = true //(props.userPreference && props.userPreference.setting_value && props.userPreference.setting_value === 'enable')
    const selection_for_filter = is_developer_mode ? convert_hints_in_to_tbl_column_array() : localHintsByEntity;


    // access_group_report_mapping

    console.log("menu_group", props.menu_group, 'menu_group_id', menu_group_id, 'is_menu_group_new', is_menu_group_new)

    return (
        <MainWrapper
            id="main_wrapper"
            tabIndex={0}
            style={{
                border: '1px solid #ccc'
            }}
            onDragOver={(e) => dragOver(e)}
            onMouseMove={(e) => mouse_moved(e)}

            onMouseUp={(e) => {
                stopDrag(e);
            }}
            onKeyDown={(e) => {
                __onKeyDown__(e);
            }}
            onClick={(e) => {
                setShowMenu(undefined);
            }}
        >

            {loading_db &&
                <div className='loading_def'>
                    <div>We are creating dashboard for you</div>
                    <div>Please wait...</div>
                </div>

            }

            <LayoutHeader
                {...props}
                createElement={createNewElement}
                save={save}
                close={_close}
                reportTitle={localReport && localReport.title}
                setTitle={setTitle}
                is_form_editor={is_form_editor}
                is_map_editor={is_map_editor}
                createElementForPrescription={createElementForPrescription}
                FilterPopup={FilterPopup}
                createGroupElement={createGroupElement}
                isOverlayShow={isOverlayShow}
                accessGroupList={props.accessGroupList}
                access_group_report_mapping={access_group_report_mapping}
                set_access_group_report_mapping={set_access_group_report_mapping}
                menu_group={props.menu_group}
                menu_group_id={menu_group_id}
                set_menu_group_id={set_menu_group_id}
                set_is_menu_group_new={set_is_menu_group_new}
                is_menu_group_new={is_menu_group_new}
                create_auto_layout_by_json={_open_auto_layout_popup}
                set_reporting_db_info_id={set_reporting_db_info_id}
                reporting_db_info_id={reporting_db_info_id}
                db_info_id={db_info_id}
                db_switcher_disabled={props.editCurrentReport ? true : false}
            // onKeyDown={__onKeyDown__}
            />

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                position: 'relative',
                height: '100%',
                width: '100%',
                background: '#eeeeee',
            }}
                ref={mainWrapperRef}
                id="main_wrapper_ref"
            >

                <div

                    id='my_mn_dv'
                    className='this_parent'
                    style={{
                        width: 'calc(100% - 88px)',
                        height: '100%',
                        background: '#fff',
                        margin: '0.3rem',
                        boxShadow: '0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%)',
                        borderTopRightRadius: '6px'

                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setOpenDropDown(undefined);

                        if (e.target.id === 'wrpr_mn') set_selected_ids([]);

                    }}

                    ref={container_width_control_ref}

                >

                    {showMenu && (selected_ids && selected_ids.length > 0) && (
                        <ul
                            className="anchor_menu"
                            style={{
                                top: anchorPoint.y,
                                left: anchorPoint.x
                            }}
                        >
                            <li>Clone</li>
                            <li onClick={delete_report_item}>Delete</li>
                        </ul>
                    )}

                    <div
                        // id="remove_unq"
                        className="grid_custom"

                        style={{
                            position: 'relative',
                            height: '100%',
                            width: '100%',
                            padding: '5px',
                            boxSizing: 'border-box',
                            // backgroundImage: `url('./grid.png')`,
                            backgroundSize: '100px',
                            overflowY: 'auto',

                        }}
                        id="uniq_container"
                        onClick={(e) => {
                            e.stopPropagation()
                            setRightPanelOpen(false)
                        }}

                        onScroll={(e) => {
                            let elmnt = document.getElementById("uniq_container");
                            run_scroll_debouncer(elmnt.scrollTop, elmnt.scrollLeft);
                        }}
                    >
                        <div
                            id="wrpr_mn"
                            style={{
                                position: 'relative',
                                width: '100%',
                                boxSizing: 'border-box',
                                height: wrapperHeight ? wrapperHeight : 'calc(100vh - 80px)',
                            }}
                            ref={containerRef}
                        >

                            {separatorLine && separatorLine.length > 0 && separatorLine.map((line, index) => {
                                return (
                                    <div key={"line_sep" + index}
                                        style={{
                                            width: line.width + "%",
                                            height: line.height ? line.height + "px" : "10px",
                                            position: 'absolute',
                                            top: (line.top) + "px",
                                            left: '0px',
                                            right: '0px',
                                            background: 'red',
                                            display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '0.8rem',
                                            backgroundImage: 'url("https://i.stack.imgur.com/hgsp8.png")',
                                        }}>

                                        Print Break | Margin Space
                                    </div>
                                )
                            })}


                            {/* {lineArray && lineArray.length > 0 && lineArray.map((line, index) => {
                                return (
                                    <React.Fragment>
                                        <div key={"line" + index}
                                            style={{
                                                width: line.width,
                                                height: line.height,
                                                position: 'absolute',
                                                top: line.top + "px",
                                                left: line.left + "px",
                                                border: '1px dotted red',
                                                zIndex: 3,
                                            }}></div>
                                    </React.Fragment>
                                )
                            })} */}

                            {
                                wrapperHeight && wrapperWidth && localReport && localReport.report_items && localReport.report_items.length > 0 && localReport.report_items.map((reportItem, index) => {

                                    const type = reportItem.chart_type;
                                    const title = reportItem.title && reportItem.title.length > 0 ? reportItem.title : 'nf-title';
                                    const element_label = reportItem.element_label && reportItem.element_label.length > 0 ? reportItem.element_label : 'Label'
                                    const element_type = reportItem.element_type && reportItem.element_type.length > 0 ? reportItem.element_type : undefined;
                                    const option_values = reportItem.option_values && reportItem.option_values.length > 0 ? reportItem.option_values : '';
                                    const deleted = reportItem.deleted;

                                    const theme_json_values = (reportItem && reportItem.theme_json_values) ? reportItem.theme_json_values : {};
                                    const __theme__ = JSON.parse(JSON.stringify(themeObject));
                                    let theme = {};
                                    let chartType = reportItem && reportItem.chart_type;
                                    let render_mode = reportItem && reportItem.render_mode



                                    if (reportItem.render_mode) {
                                        if (reportItem.render_mode === 'chart' && chartType === 'single_cell') {
                                            theme = __theme__.insight["single_cell"];
                                        }
                                        else if (reportItem.render_mode === 'report') {
                                            theme = __theme__.insight['chart'];
                                        }
                                        else {
                                            theme = __theme__.insight[reportItem.render_mode];
                                        }
                                        bind_theme_data_to_object(theme, theme_json_values);
                                        apply_color_sets_to_colors(theme, __theme__.colorset);
                                    }

                                    return (
                                        <DraggableDiv
                                            theme={theme}
                                            render_mode={render_mode}
                                            // chart_
                                            setRightPanelOpen={setRightPanelOpen}
                                            key={"drag_div" + index}
                                            current_dragging_index={activeIndex}
                                            scroll_position={scroll_postion}
                                            current_position={current_position}
                                            startDragPosition={startPosition}
                                            shiftKeyPressed={key_event_broadcaster.shiftKeyPressed}
                                            currentKeyCode={key_event_broadcaster.currentKeyCode}
                                            key_event_counter={key_event_broadcaster.counter}
                                            element_label={element_label}
                                            option_values={option_values}
                                            element_type={element_type}
                                            is_form_editor={is_form_editor}
                                            is_map_editor={is_map_editor}
                                            id={reportItem.id}
                                            resize_stages={resize_stages}
                                            initDrag={initDrag}
                                            stopDrag={stopDrag}
                                            initResize={initResize}
                                            stopResize={stopResize}
                                            top={reportItem.top_px}
                                            left={reportItem.left_px}
                                            width={reportItem.width_px}
                                            type={type}
                                            height={reportItem.height_px}
                                            index={index}
                                            reportItem={reportItem}
                                            title={title}
                                            _saveInsights={_saveInsights}
                                            deleteReportItem={deleteReportItem}
                                            deleted={deleted}
                                            changeReportItemsValues={changeReportItemsValues}
                                            duplicateElement={duplicateElement}
                                            wrapperHeight={wrapperHeight}
                                            setWrapperHeight={setWrapperHeight}
                                            containerRef={containerRef}
                                            selected_ids={selected_ids}
                                            wrapper_inital_Height={wrapper_inital_Height}
                                            getMoveElRef={getMoveElRef}
                                            is_group={reportItem.is_group}
                                            groupId={groupId}
                                            shape_overlapId={shape_overlapId}
                                            db_infos={props.db_infos}
                                            onDragEnd={(e) => {
                                                // setGroupId([]);
                                                change_wrapper_height_if_required();
                                                setRulerLine(undefined)
                                            }}
                                            openDropDown={openDropDown}
                                            setOpenDropDown={(idx) => {
                                                setOpenDropDown(idx)
                                            }}
                                            boxBorder={boxBorder}
                                            select_report_item={(id, is_shift_key_pressed) => {
                                                v2_select_report_item(id, is_shift_key_pressed)
                                            }}

                                            saveLocalReport={saveLocalReport}

                                            activeReport={activeReport}
                                            // onKeyDown={__onKeyDown__}
                                            onKeyUp={() => { }}
                                            delete_report_item={delete_report_item}
                                            onContextMenu={handleContextMenu}
                                            setShowMenu={setShowMenu}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>


                {!rightPanelOpen && (
                    <PreRightOptions
                        createElement={createNewElement}
                        setRightPanelOpen={setRightPanelOpen}
                        FilterPopup={FilterPopup}
                        access_group_report_mapping={access_group_report_mapping}
                        set_access_group_report_mapping={set_access_group_report_mapping}
                        access_group_selections={props?.reportAccessGroupData}
                        menu_group={props.menu_group}
                        menu_group_id={menu_group_id}
                        set_menu_group_id={set_menu_group_id}
                        set_is_menu_group_new={set_is_menu_group_new}
                        is_menu_group_new={is_menu_group_new}
                    />
                )}

                <RightSidePanel
                    rightPanelOpen={rightPanelOpen}
                    isOverlayShow={isOverlayShow}
                    setIsOverlayShow={setIsOverlayShow}
                    report_items={localReport && localReport.report_items}
                    setRightPanelOpen={setRightPanelOpen}
                    activeReport={activeReport}
                    setActiveReport={(idx) => {
                        // setActiveReport(idx)
                    }}
                    insightId={localReport?.id}
                    selection_for_filter={selection_for_filter}
                    currentReport={localReport && localReport.report_items && localReport.report_items[1]}
                    saveLocalReport={(modified_data) => {
                        saveLocalReport(modified_data);
                    }}
                    duplicateElement={duplicateElement}
                    selected_ids={selected_ids}
                    reporting_db_info_id={reporting_db_info_id}
                    filterOptions={props.filterOptions}
                // createElement={createNewElement}
                />
            </div>
        </MainWrapper>
    )
};



const mapStateToProps = state => {

    const { activeEditLayout } = state.layoutReducer;
    const { autoLayoutData } = state.layoutReducer;
    const { activeInsight } = state.insightReducer;
    const { menus } = state.menuGroupReducer;
    const { hintsForFilter } = state.insightReducer;

    const reportFormulas = state.reportReducer.reportFormulas;
    const db_infos = state.connectionReducer.db_infos
    const all_reports = state.reportHelperReducer.all_reports;
    const insightsMenu = state.insightReducer.insightsMenu
    const userPreference = state.accountReducer.userPreference
    const accessGroupList = state.groupAccessReducer.accessGroupList
    const reportAccessGroupData = state?.groupAccessReducer?.reportAccessGroupData;
    const menu_group = state.menuGroupReducer?.menu_groups_cache?.['report'];

    const editCurrentReport = state.reportHelperReducer.editCurrentReport;
    const defaultInsight = state.reportHelperReducer.defaultInsight;
    const filterOptions = state?.filterReducer?.filterOptions;
    const questionInfoDataV2 = state?.insightReducer?.questionInfoDataV2

    return { questionInfoDataV2, reportAccessGroupData, filterOptions, defaultInsight, editCurrentReport, autoLayoutData, menu_group, accessGroupList, userPreference, activeEditLayout, activeInsight, all_reports, menus, hintsForFilter, reportFormulas, db_infos, insightsMenu }
};


const mapDispatchToProps = {
    getAccessGroupWithUsers,
    reset_data_from_server,
    getHintsForRules,
    get_user_preferences,
    setActiveInsight,
    get_db_info,
    saveInsight,
    getReportById,
    setActiveEditReport,
    createForm,
    getHintsByClientId,
    get_all_reports,
    getMenuGroups,
    getReportByReportId,
    clearEditCurrentReport,
    get_filters_options_v1,
    get_question_info_for_dashboard_v2
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LayoutEditor))