import React, { Children } from 'react'
import { useState } from 'react'
import styled from 'styled-components';
import { constants } from '../../../utils/constants';
import { CHART_TYPES, RENDER_MODE } from '../../../shared-with-fe/constants';
import MenuGroupIcon from '../../svg/icons-v2/menuGroupIcon';
import DropdownMenuList from '../../hoc/v2/dropdownMenuList';
import NestedSelectOption from '../../hoc/v2/nestedSelectOption';



const RightSideSetting = styled.div`
    width: 80px;
    height: ${props => props.height ? props.height : '100vh'};
    margin-top: 0rem;
    /* box-shadow: ${props => !props.hideBoxShadow ? '0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%)' : undefined}; */
    background-color: #fff;
    text-align: left;
    position: relative;
    z-index: ${props => props.zIndex ? props.zIndex : '5'};
    position: absolute;
    right: 0px;
    padding-top: .5rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    display: flex;
    padding: 1rem 5px;
    box-sizing: border-box;

    .item__{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-bottom: 1px solid #ccc;
        flex-direction: column;
        font-size: .7rem;
        font-weight: 400;
        padding-bottom: 1rem;
        padding-top: 1rem;
        cursor: pointer;
    
        &:hover{
            svg{
                fill: #005cff;
            }
        }

        .icon{
            margin-bottom: 10px;

            img{
                height: 30px;
            }
        }
    }
    .label{
        white-space: break-spaces;
        text-align: center;
    }
`;




const BottomDropDownMenu = styled.div`
    
    background: #fff;
    position: absolute;
    top: 0px;
    width: ${props => props.width || '200px'};
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 20%);
    right: 109%;
    padding: 8px 6px;
    box-sizing: border-box;
    z-index: 3;

    .item{
        padding: 7px 10px;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 1rem;

        svg{
            fill: #030303;
        }

        span{
            display: inline-block;
            margin-left: 10px;
            color: #030303;
            font-size: .9rem;

        }

        &:hover{
            background: #dee6ff;
            cursor: pointer;
            border-radius: 6px;
        }
    }
`;

const RightSidePrePanel = (props) => {

    const {
        setRightPanelOpen,
        createElement,
        FilterPopup,
        access_group_report_mapping,
        set_access_group_report_mapping,
        access_group_selections,
        menu_group,
        menu_group_id,
        set_menu_group_id,
        set_is_menu_group_new,
        is_menu_group_new,

    } = props;


    const fieldNames = { label: 'name', key: 'id', check: 'checked' };
    const [isOpen, setIsOpen] = useState({ isAccessLayerOpen: false, isMenuGroupOpen: false, isAddControlOpen: false });

    const handleAddMenuGroupList = (name) => {
        set_is_menu_group_new(true);
        set_menu_group_id(name)
    };

    const handleDelete = (data) => {
        
    };


    return (
        <RightSideSetting>
            <div className='item__' onClick={() => {
                props.createElement({ chart_type: CHART_TYPES.bar_chart })
                setIsOpen({ isAddControlOpen: false, isAccessLayerOpen: false, isMenuGroupOpen: false })
            }}>
                <div className='icon'>
                    <img src={constants.CONTEXT_PATH + '/editor/chart.png'} alt='chart icon' />
                </div>
                <div className='label'> Add Chart</div>
            </div>

            <div className='item__' onClick={() => {
                setIsOpen(prev => ({ ...prev, isAddControlOpen: !prev.isAddControlOpen, isAccessLayerOpen: false, isMenuGroupOpen: false }))
            }}>
                <div className='icon'>
                    <img src={constants.CONTEXT_PATH + '/editor/ctrl.png'} alt='ctrl icon' />
                </div>
                <div className='label'>Add a Control</div>


                {isOpen.isAddControlOpen && (
                    <BottomDropDownMenu onClick={() => setIsOpen({ isAddControlOpen: false, isAccessLayerOpen: false, isMenuGroupOpen: false })}>
                        <div className="item" onClick={() => {
                            createElement({ chart_type: RENDER_MODE.single_cell })
                        }}>Single Cell</div>
                        <div className="item" onClick={() => {
                            createElement({ chart_type: RENDER_MODE.label })
                        }}>Label</div>
                        <div className="item" onClick={() => {
                            createElement({ chart_type: RENDER_MODE.box })
                        }}>Box</div>

                    </BottomDropDownMenu>
                )}

            </div>

            <div className='item__' onClick={() => {
                FilterPopup("input");
                setIsOpen({ isAddControlOpen: false, isAccessLayerOpen: false, isMenuGroupOpen: false });
            }}>
                <div className='icon'>
                    <img src={constants.CONTEXT_PATH + '/editor/drildown_icon.png'} alt='drildown icon' />
                </div>
                <div className='label'>Global Filter</div>
            </div>
            <div className='item__' onClick={() => {
                setRightPanelOpen("add_chart");
                setIsOpen({ isAddControlOpen: false, isAccessLayerOpen: false, isMenuGroupOpen: false });
            }}>
                <div className='icon'>
                    <img src={constants.CONTEXT_PATH + '/editor/setting.png'} alt='setting' />
                </div>
                <div className='label'>Settings</div>
            </div>
            <div className='item__' onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                setIsOpen(prev => ({ ...prev, isAccessLayerOpen: !prev.isAccessLayerOpen, isMenuGroupOpen: false, isAddControlOpen: false }));
            }}>
                <div className='icon'>
                    <img src={constants.CONTEXT_PATH + '/editor/lock_color.png'} alt='lock color' />
                </div>
                <div className='label'> Access Layer</div>
                {isOpen.isAccessLayerOpen && <BottomDropDownMenu
                // width='220px'
                >
                    <NestedSelectOption
                        selection={access_group_selections}
                        display_key={"name"}
                        value_key={"id"}
                        onChange={set_access_group_report_mapping}
                        selected_values={access_group_report_mapping || {}}
                    />
                </BottomDropDownMenu>}
            </div>
            <div className='item__' onClick={(e) => {
                e.stopPropagation();
                setIsOpen(prev => ({ ...prev, isMenuGroupOpen: !prev.isMenuGroupOpen, isAccessLayerOpen: false, isAddControlOpen: false }));
            }}>
                <div className='icon'>
                    <MenuGroupIcon height='2.5rem' size='2.5rem' color='#2D7AFE' />
                </div>
                Menu Group
                <DropdownMenuList
                    fieldNames={fieldNames}
                    handleDelete={handleDelete}
                    dataList={menu_group}
                    isOpen={isOpen.isMenuGroupOpen}
                    setIsOpen={setIsOpen}
                    handleAdd={handleAddMenuGroupList}
                    onCheck={(id) => set_menu_group_id(prev => prev === id ? undefined : id)}
                    checkedKeys={[menu_group_id]}
                />
            </div>
        </RightSideSetting>
    )
}

export default RightSidePrePanel;