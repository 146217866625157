import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TitleHeader from '../title-header';
import { PageInnerWrapper } from '../hoc/styled.hoc';
import { getAdTechClients } from '../../actions/campaign'
import { getPlatformsAndCampaigns, saveTheMarkup } from '../../actions/markup'

import { Button } from '../hoc/button';
import TextBox from '../hoc/textBox';
import SelectOption from '../select-option';

const Index = (props) => {
    const { adTechClients, platformAndCampaigns, saveTheMarkup } = props;

    const [formValue, setFormValue] = useState({
        ad_tech_client: undefined,
        platform_name: undefined,
        campaign_name: undefined,
        mark_up_column: undefined,
        start_date: undefined,
    });


    useEffect(() => {
        let _page_info = {
            title: 'Add Markup'
        }
        props.dispatchHeaderInformation(_page_info)


        props.getPlatformsAndCampaigns()
        props.getAdTechClients();
    }, [])


    const onInputChange = (key, value, is_multi_select) => {
        let temp_form = formValue ? JSON.parse(JSON.stringify(formValue)) : {};
        temp_form[key] = value;
        setFormValue(temp_form)
    }

    const onSelectCheckBox = (key, value, type) => {

        let temp_form = formValue ? JSON.parse(JSON.stringify(formValue)) : {};
        let _clone_selectd = temp_form && temp_form[key] ? temp_form[key] : [];
        let _index = _clone_selectd ? _clone_selectd.indexOf(value) : undefined;

        if (_clone_selectd && _index > -1) {
            _clone_selectd.splice(_index, 1)
        }
        else {
            _clone_selectd.push(value)
        }
        if (type === 'selectAll') {
            _clone_selectd = [];
            let array_name = key === 'platform_name' ? "platforms" : 'campaigns';
            platformAndCampaigns && platformAndCampaigns[array_name] && platformAndCampaigns[array_name].length > 0 && platformAndCampaigns[array_name].forEach((obj) => {
                _clone_selectd.push(obj[key])
            })
        }

        if (type === 'unSelectAll') {
            _clone_selectd = [];
        }
        temp_form[key] = _clone_selectd;
        setFormValue(temp_form)
    }


    const local_save = () => {

        let local_save_data = formValue ? JSON.parse(JSON.stringify(formValue)) : {};
        let platform_name = local_save_data.platform_name;
        let campaign_name = local_save_data.campaign_name;

        if ((platform_name && platform_name.length) === (platformAndCampaigns && platformAndCampaigns.platforms && platformAndCampaigns.platforms.length)) {
            platform_name = ["all"];
        }

        if ((campaign_name && campaign_name.length) === (platformAndCampaigns && platformAndCampaigns.campaigns && platformAndCampaigns.campaigns.length)) {
            campaign_name = ["all"];
        }

        local_save_data.platform_name = platform_name;
        local_save_data.campaign_name = campaign_name;

        saveTheMarkup(local_save_data)
        setTimeout(() => {
            setFormValue({
                ad_tech_client: undefined,
                platform_name: undefined,
                campaign_name: undefined,
                mark_up: undefined,
                start_date: undefined,
                end_date: undefined
            });
        })
    }



    return (
        <div>
            {/* <TitleHeader title="Add Markup" /> */}
            <PageInnerWrapper padding={'2rem 1rem'} style={{marginTop: '1rem'}}>
                <div style={{ width: '80%', boxSizing: 'border-box', marginLeft: '2rem'  }}>
                <br /><br />
                    <span style={{fontWeight: 'bold', fontSize: '1rem', color: '#555' }}>Please choose the Client/Platforms/Campaigns for which you want to add the mark up: </span><br/><br />
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '2rem', }}>

                        <div style={{ width: '29%' }}>
                            <SelectOption
                                label="Client"
                                selections={adTechClients ? adTechClients : []}
                                onChangeFunc={(value) => {
                                    onInputChange('ad_tech_client', value,)
                                }}

                                activeValue={formValue && formValue['ad_tech_client']}
                                width="100%"
                                displayKey={"ad_tech_client"}
                                valueKey={"ad_tech_client"}
                                lineHeight="2.857rem"
                                border='1px solid #bbd1d9'
                                color='#7c7c7c'
                            />
                        </div>

                        <div style={{ width: '29%' }}>
                            <SelectOption
                                label="Platform"
                                selections={(platformAndCampaigns && platformAndCampaigns.platforms) ? platformAndCampaigns.platforms : undefined}
                                isMultiSelect={true}
                                isCheckBox={true}
                                activeValue={formValue && formValue['platform_name'] ? formValue['platform_name'] : undefined}
                                displayKey={"platform_name"}
                                valueKey="platform_name"
                                width="100%"
                                lineHeight="2.857rem"
                                border='1px solid #bbd1d9'
                                color='#7c7c7c'
                                isSelecctAll={true}
                                onChangeFunc={(value, type) => {
                                    onSelectCheckBox("platform_name", value, type);
                                }}
                            />
                        </div>

                        <div style={{ width: '29%' }}>
                            <SelectOption
                                label="Campaign"
                                selections={(platformAndCampaigns && platformAndCampaigns.campaigns) ? platformAndCampaigns.campaigns : undefined}
                                onChangeFunc={(value, type) => {
                                    onSelectCheckBox("campaign_name", value, type)
                                }}
                                displayKey={"campaign_name"}
                                valueKey="campaign_name"
                                activeValue={formValue && formValue['campaign_name'] ? formValue['campaign_name'] : undefined}
                                isCheckBox={true}
                                isMultiSelect={true}
                                isSelecctAll={true}
                                width="100%"
                                lineHeight="2.857rem"
                                border='1px solid #bbd1d9'
                                color='#7c7c7c'
                            />
                        </div>
                    </div>

                    <br /><br />
                    <span style={{fontWeight: 'bold', fontSize: '1rem', color: '#555' }}>What % and for what period you would like to apply the Mark-up? </span><br/><br />

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '2rem',  }}>
                        <div style={{ width: '29%' }}>
                            <TextBox
                                label="Mark Up (%)"
                                value={formValue && formValue['mark_up'] ? formValue['mark_up'] : undefined}
                                onChange={(e) => {
                                    onInputChange("mark_up", e.target.value)
                                }}

                            />
                        </div>

                        <div style={{ width: '29%' }}>
                            <TextBox
                                label="Start Date"
                                value={formValue && formValue['start_date'] ? formValue['start_date'] : undefined}

                                type="date"

                                onChange={(e) => {
                                    onInputChange("start_date", e.target.value)
                                }}

                            />
                        </div>
                        <div style={{ width: '29%' }}>
                            <TextBox
                                label="End Date"
                                type="date"
                                value={(formValue && formValue['end_date']) ? formValue['end_date'] : undefined}

                                onChange={(e) => {
                                    onInputChange("end_date", e.target.value)
                                }}

                            />
                        </div>

                    </div>


                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    </div>

                </div>

                <div style={{ marginBottom: '20rem', marginLeft: '2rem', marginTop: 'auto' }}>
                    <Button
                        onClick={() => {
                            local_save()
                        }}
                    >
                        Apply
                    </Button>

                </div>
            </PageInnerWrapper>
        </div>
    )
}



const mapStateToProps = (state) => ({
    adTechClients: state.CampaignReducer.adTechClients,
    platformAndCampaigns: state.markupReducer.platformAndCampaigns

})

export default connect(mapStateToProps, { getAdTechClients, getPlatformsAndCampaigns, saveTheMarkup })(Index);
