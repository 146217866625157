import React, { PureComponent } from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { withRouter } from 'react-router-dom';

import Legend from '../legend';
import { PositionWrapper } from '../legend/legend.styled';
import CustomizedAxisTick from '../tick';
import { DataFormater } from '../yaxis-label';
import { giveFilters } from '../../../utils';


class BiaxialChart extends PureComponent {

    render() {

        const { yDataKeys, paddingOnXaxis, xDataKeys,
            data, widthOfChart, heightOfChart, changeColorOfYData, colorForYData, question, filters, getDataFromReportingServer, insights,
            hide_y_axis, hide_x_axis, hide_grid, hide_legend, hide_tooltip,
          
        } = this.props;


        return (
            <div id='chart' style={{ position: 'relative' }}>
                <ComposedChart
                    width={widthOfChart}
                    height={heightOfChart}
                    data={data}
                    margin={{
                        top: 10, bottom: 10, right: 70, left: 10
                    }}
                >
                   {!hide_grid  && (
                            <CartesianGrid strokeDasharray="2 1" vertical={false} horizontalY={false} />
                        )}


                    {!hide_legend && <Legend verticalAlign="top" height={30} />}

                    {!hide_x_axis && (
                        <XAxis
                            stroke="#748891"
                            dataKey={xDataKeys && xDataKeys[xDataKeys.length - 1]}
                            padding={{ right: !insights && paddingOnXaxis }}
                            style={{ fontSize: this.props.data && this.props.data.length > 60 ? '.7rem' : '.8rem' }}
                            height={this.props.data && this.props.data.length > 50 ? 100 : 50}
                            tick={this.props.data && this.props.data.length > 50 ? <CustomizedAxisTick /> : true}
                            interval={this.props.data && this.props.data.length > 30 ? 2 : ''}
                        />
                    )}

                    {!hide_y_axis && (
                        <YAxis
                        stroke="#748891"
                            interval={0}
                            style={{ fontSize: '.65rem' }}
                            tickFormatter={DataFormater}
                            width={40}
                        />
                    )}

                    {hide_tooltip && (<Tooltip />)}
                    <Bar yAxisId="left" dataKey={yDataKeys && yDataKeys.length > 0 && yDataKeys[0]} barSize={20} fill={colorForYData[0]} onClick={(props) => {
                        const key = xDataKeys[xDataKeys.length - 1];
                        const values = giveFilters(key, props[key], question, filters);
                        getDataFromReportingServer(values.new_question, this.props.history);
                    }} />
                    <Line type='monotone' yAxisId="right" dataKey={yDataKeys && yDataKeys.length > 0 && yDataKeys[1]} stroke={colorForYData[1]} />
                </ComposedChart>
                <PositionWrapper style={{ position: 'absolute', bottom: 0 }}>
                    <Legend yDataKeys={yDataKeys} colors={colorForYData} changeColor={changeColorOfYData} />
                </PositionWrapper>
            </div>
        );
    }
}

export default withRouter(BiaxialChart);