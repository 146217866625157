import styled, { keyframes } from 'styled-components';



const shackAnimation = keyframes`
    
 0% {
    transform: translate(3px, 0);
  }
  50% {
    transform: translate(-3px, 0);
  }
  100% {
    transform: translate(0, 0);
  }

`;




export const CustomList = styled.li`
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: .5rem;
    &:before {
        transition: all .2s;
        content: ${props => props.checked ? "''" : undefined};
        width: .4rem;
        height: .8rem;
        border: 1px solid;
        border-width: 0 2px 3px 0;
        transform: rotate(45deg);
        position: absolute;
        left: -1rem;
        top: 0px;
        color: green;
        z-index: 3;
     }

     &:after {
        transition: all .2s;
        content: '';
        opacity: ${props => props.checked ? 0 : 1};
        width: 8px;
        height: 8px;
        position: absolute;
        left: -1rem;
        top: 50%;
        background: #222;
        border-radius: 50%;
        transform: translate(0px, -50%);
     }

`;


export const EditorMainWrapper = styled.div`
   
   width: 100%;
    position: relative;

    .fixed_wrapper{
        position: sticky;
        width: 100%;
        top: 0px;
        z-index: 8;
        background-color: rgb(248, 249, 253);
    }
    
     
    .go_back_btn_wrapper{
        width: 100%;
        padding: 5px 2rem;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .go_back_btn{
        border-radius: 8px;
        color: #ffff;
        display: flex;
        align-items: center;
        border: 1px solid #e2e2e2;
        box-shadow: 2px 3px 4px 4px #cccccc6e;
        cursor: pointer;
        border-radius: 33px;
        font-weight: 600;
        overflow: hidden;
        width: 120px;
        &:hover{
            opacity: .9;
        }

        .icon{
            width: 50px;
            background: #2173F7;
            align-items: center;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 2rem;
            margin-right: 10px;
            img{
                width: 25px;

            }
        }


            &:hover{

            }
        svg{
            fill: #222;
            transform: rotate(180deg);
            margin-right: 10px  ;
        }
        span{
            color: #222;
            font-size: .9rem;
        }

    }

    .inner_title{
        font-size: 1.1rem;
        margin: 0px;
        color: #333;
        font-weight: 500;
        /* margin-left: 10px; */
        border-bottom: 1px solid #ccc;
        padding-bottom: 7px;
        margin-bottom: 10px;
    }



  .list{
      list-style: none;
      padding: 0px;
  }

    .view_error_btn{
        animation: ${shackAnimation} 150ms 2 linear;
    }

   

    .flex_row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 1.5rem;
    }

    .field_set{
        width: 100%;
        padding: 0px;
        border: 0px;
        
    }

    .info_column_row{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		overflow: auto;
		width: 60rem;
        max-height: 7rem;
        overflow: auto;
        &::-webkit-scrollbar {
            background-color:red;
            width:3px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 3px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 3px;
        }
	}

    .info_row{
		width: 100%;
		margin-bottom: 1rem;
        display: flex;
        flex-direction: row;
		label{
            font-size: .9rem;
            text-transform: uppercase;
            color: #222;
            display: block;
            font-weight: 600;
            margin-bottom: 10px;
            width: 40rem;
		}
	}

	.column_name{
		background: #eaeaeaab;
		padding: .3rem .6rem;
		border-radius: 3px;
		font-size: .9rem;
		margin-right: 9px;
		box-sizing: border-box;
		color: #555;
		margin-bottom: 9px;
	}

    .clickable_name{
        cursor: pointer;

        &:hover{
            background: #308bc8;
            color: #fff;
        }
    }
	.query_name{
		font-size: .9rem;
		color: #555;
		white-space: break-spaces;
	}

    
    .title_up{
        font-size: 1.4rem;
        color: #000;
        text-transform: capitalize;
        margin: 0px;
        font-weight: 600;
    }

    .sub_title_e12{
        margin-top: 8px;
        margin-bottom: 2.4rem;
        font-size: 0.9rem;
        color: rgb(51, 51, 51);
    }

    .top_check_box{
        display: 'flex';
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        color: #000;
        cursor: pointer;
    }


    .add_btn_new_field{
        padding: 5px 9px;
        border-radius: 3px;
        display: inline-block;
        margin-top: 10px;
        box-shadow: rgba(0, 0, 0, 0.53) 0px 0px 2px 0px;
        font-size: .9rem;
        cursor: pointer;
    }

    .fields_item_ul{
        padding: 0px;
        margin: 0px ;
        display: flex;
        flex-direction: row; 
        flex-wrap:wrap;
        width: 100%;
        overflow: auto;
        padding-left: 20px;
        align-content: flex-start;
        max-height: 9rem;
        padding-bottom: 1rem;
        
        &::-webkit-scrollbar {
            background-color:red;
            width:3px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 3px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 3px;
        }

        li{
           margin-right: 50px;
           line-height: 1.71;
            span{
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                box-sizing: border-box;
                padding-right: 15px;
                display: block;
                text-transform: capitalize;
            }
        }
    }



    

`;



export const StepContentWrapper = styled.div`

    width: 95%;
    margin: 0px auto;
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
   // height: 100%;

    .back_button{
        margin-bottom: 1rem;
        width: 100%;

        svg{
            transform: rotate(180deg);
            fill: #444;

            &:hover{
                fill:  #308bc8;
            }
        }
    }


    .job_sc_table{
        border: 1px solid #ccc;
        border-collapse: collapse;
        width: 100%;
        td{
            border: 1px solid #ccc;
            padding: 1.1rem;
            box-sizing: border-box;
            text-align: center;
            font-size: .9rem;
        }

        thead{
            td{
               color: #000;
            }
        }
        tbody{
            td{
                text-transform: uppercase;
                color: #555;
            }
        }
    }
`;