import React, { useState, useEffect } from 'react';
import { Wrapper, EditorInnerWrapper } from '../relationships/relationships.styled'
import { SelectSet } from './dimension.styled'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import Edit from '../svg/edit'
import CloseSvg from '../svg/close'
import { getNewTable, delete_dimension } from '../../actions/new-table'
import { showPopup } from '../../utils';
import * as enums from '../../utils/enums';
import { Button } from '../hoc/button'
import AddEditDimension from './dimension-add-edit';
import { Gap } from '../gap'

const Newtable = (props) => {
    const { getNewTable, newTableData } = props;
    // const [isData, setIsData] = useState({

    // })

    useEffect(() => {
        getNewTable()
    }, [])

    // useEffect(() => {
    //     setIsData();
    // }, [])

    return (
        <div style={{ marginTop: '30px' }}>
            <Wrapper>
                <EditorInnerWrapper>
                    <SelectSet>
                        <div>
                            <div>
                                <Button
                                     primary={true}
                                    style={{
                                        float: 'right'
                                    }}
                                    onClick={() => {
                                        showPopup(undefined, undefined, enums.default.popupType.element, AddEditDimension, {});
                                    }} >Add</Button>
                            </div>
                            <Gap h="50px" />
                            {newTableData && newTableData !== undefined &&
                                <table className="table1">
                                    <thead className="thead1">
                                        <tr className="tr1">
                                            <th>Entity</th>
                                            <th>Fact</th>
                                            <th>Dimension</th>
                                            <th>Common-filter</th>
                                            <th>Type</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody style={{ alignItems: 'center' }}>
                                        {newTableData.map((item, index) => {
                                            return (
                                                <tr key={"out" + index} className="tr2">
                                                    {/* <td>{item.id}</td> */}
                                                    <td>{item.entity}</td>
                                                    <td>{item.fact}</td>
                                                    <td>{item.dimension}</td>
                                                    <td>{item.common_filter}</td>
                                                    <td>{item.type}</td>
                                                    <td>
                                                        <button className="btn1" onClick={() => {
                                                            showPopup(undefined, undefined, enums.default.popupType.element, AddEditDimension, {
                                                                item: item
                                                            });
                                                        }}>
                                                            <Edit size="1rem" height='1rem' color="white" />
                                                        </button>
                                                        <button
                                                            onClick={() => {
                                                                props.delete_dimension(item);
                                                                setTimeout(() => {
                                                                    getNewTable();
                                                                }, 100)
                                                            }}
                                                            className="btn2"
                                                        ><CloseSvg size="1rem" height='1rem' color="white" /></button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        }

                                    </tbody>
                                </table>
                            }


                        </div>
                    </SelectSet>
                </EditorInnerWrapper>
            </Wrapper>
        </div>
    );
}

const mapStateToProps = (state) => ({
    newTableData: state.NewTableReducer.newTableData
})


export default withRouter(connect(mapStateToProps, { getNewTable, delete_dimension })(Newtable))



