import React, { useState, useEffect } from 'react';
import { DivRow } from '../styled-hoc';
import { Button } from '../hoc/button';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { saveTaggedInfo, getTaggedInfo } from '../../actions/helper';
import TextInput from '../hoc/textBox';
import SelectOption from '../select-option'



export const Wrapper = styled.div`
    position: relative;
    width: 30rem;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 6px 0px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(234, 234, 234);
    border-image: initial;
    padding: 1.643rem 1.429rem;
    overflow-y: auto;
    max-height: 80vh;

    &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }
    .title{
        font-size: 1rem;
        margin-bottom: 5px;
    }
`;

export const TitleWrapper = styled.div`
    font-size: 1.286rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.714rem;
    letter-spacing: normal;
    text-align: center;
     color: rgb(26, 26, 26);
    display: block;
    margin: 0px;
`;





const AddData = (props) => {
    const { taggedData, save_Nf_Grammar } = props;
    const { close, popupKey, option, data, saveMenu, save_favourite, store } = props;
    const { item } = data;

    const [inputData, setInputData] = useState({
        document_type: '',
        context: '',
        tagged_to: ''
    })

    const onTextChange = (value, key) => {
        setInputData({ ...inputData, [key]: value });
    }

    useEffect(() => {
        if (item) {
            setInputData(item)
        }
    }, [item])


    const document_type = [
        { name: 'Acquisition', value: 'acquisition' },
        { name: 'Merger', value: 'merger' },
        { name: 'Investment', value: 'investment' },
        { name: 'Sale', value: 'sale' }
    ]

    const saveNfGrammar = () => {

        if (item && item.id) {
            let data = {
                document_type: inputData.document_type,
                context: inputData.context,
                tagged_to: inputData.tagged_to,
                is_modified: true,
                id: item ? item.id : undefined
            }

            props.saveTaggedInfo(data)
        }
        else {
            props.saveTaggedInfo(inputData)
        }

        setTimeout(() => {
            props.getTaggedInfo()
        }, 1000)
    }


    return (
        <Wrapper>
            <TitleWrapper>
                {item && item.id ? "Edit" : "Add"} Data
             </TitleWrapper>
            <div style={{
                padding: '10px'
            }}>
                <DivRow w="100%" style={{ marginBottom: '1rem' }}>
                    <SelectOption
                        label="Document Type"
                        onChangeFunc={onTextChange}
                        selections={document_type}
                        displayKey='name'
                        valueType="document_type"
                        valueKey='value'
                        activeValue={inputData && inputData.document_type ? inputData.document_type : ""}
                        width="100%"
                        padding="0px 0px"
                        paddingRight='25px'
                        lineHeight="2.857rem"
                        menuLineHeight="1.8rem"
                        hideCase='true'
                    />
                </DivRow>
                <DivRow w="100%" style={{ marginBottom: '1rem' }}>
                    <TextInput
                        label="Context"
                        type="text"
                        value={inputData.context}
                        onChange={(e) => onTextChange(e.target.value, 'context')}
                    />
                </DivRow>
                <DivRow w="100%" style={{ marginBottom: '1rem' }}>
                    <TextInput
                        label="Tagged To"
                        type="text"
                        value={inputData.tagged_to}
                        onChange={(e) => onTextChange(e.target.value, 'tagged_to')}
                    />
                </DivRow>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        width: '100%'
                    }}
                >
                    <Button
                        primary={true}
                        width="6rem"
                        mR="1.143rem"
                        onClick={() => {
                            saveNfGrammar()
                            close(option, popupKey);
                        }}
                    >
                        Save
                    </Button>

                    <Button
                        width="6rem"
                        bgColor={'#e6e6e6'}
                        onClick={() => {
                            close(option, popupKey);
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </Wrapper>
    )
}


const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps, { saveTaggedInfo, getTaggedInfo })(AddData);