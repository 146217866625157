import { simpleDispatch_post, dispatchAction, simpleDispatch_get } from './helper';
import * as actionTypes from '../action-types/map';
import { constants } from '../utils/constants';

// export const getHospitalMarkers = () => async dispatch => {
//     const url = constants.END_POINTS.API + constants.END_POINTS.MAP.POINT + constants.END_POINTS.MAP.GET_INITIAL_MARKERS;


//     simpleDispatch_post(dispatch, url, post, actionTypes.HOSPITAL_MARKERS_SUCCESS);
// };

export const getMarkerInfo = (question) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.GET_DATA_FROM_REPORTING_SERVER;

    const post = {
        question
    }

    if (question) {
        simpleDispatch_post(dispatch, url, post, actionTypes.GET_MARKER_INFO_SUCCESS, true);
    }
    else {
        dispatchAction(dispatch, actionTypes.GET_MARKER_INFO_SUCCESS, undefined);
    }
};


export const getZone = (zone_id, level) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.MAP.POINT + constants.END_POINTS.MAP.GET_ZONE;

    const post = {
        zone_id,
        level
    }

    simpleDispatch_post(dispatch, url, post, actionTypes.GET_ZONE_SUCCESS);
};


export const getMultiZone = (zone_ids, level, id) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.MAP.POINT + constants.END_POINTS.MAP.GET_THE_MULTI_ZONES;
    const post = {
        zone_ids,
        level,
        id
    }
    simpleDispatch_post(dispatch, url, post, actionTypes.GET_MULTI_ZONE_SUCCESS);
};


export const clearZone = () => async dispatch => {

    dispatchAction(dispatch, actionTypes.CLEAR_ZONE_SUCCESS)
}



export const getCustomZoneMarkers = (custom_zone, level) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.MAP.POINT + constants.END_POINTS.MAP.GET_ZONE_MARKERS;
    const post = {
        custom_zone,
        level
    }

    simpleDispatch_post(dispatch, url, post, actionTypes.GET_CUSTOM_ZONE_MARKERS);
};


export const getMarkersInRadius = (coordinates, radius) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.MAP.POINT + constants.END_POINTS.MAP.GET_MARKER_IN_RADIUS;

    const post = {
        coordinates,
        radius
    }

    simpleDispatch_post(dispatch, url, post, actionTypes.GET_MARKER_IN_RADIUS_SUCCESS);
};


export const getZoneName = () => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.MAP.POINT + constants.END_POINTS.MAP.GET_ZONE_NAMES;

    simpleDispatch_get(dispatch, url, actionTypes.GET_ZONE_NAME_SUCCESS);
};


export const getAllZoneByClient = () => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.MAP.POINT + constants.END_POINTS.MAP.GET_ALL_ZONE_BY_CLIENT;

    simpleDispatch_get(dispatch, url, actionTypes.GET_ALL_ZONE_BY_CLIENT);
};


export const changeMapFilter = filter => async dispatch => {
    dispatchAction(dispatch, actionTypes.MAP_FILTER_CHANGE, filter);
};


export const setForceZoneMarker = markers => async dispatch => {
    dispatchAction(dispatch, actionTypes.SET_FORCE_ZONE_MARKERS, markers);
};


export const setForceZone = zone => async dispatch => {
    dispatchAction(dispatch, actionTypes.SET_FORCE_ZONE, zone);
};


export const flushMap = () => async dispatch => {
    dispatchAction(dispatch, actionTypes.FLUSH_MAP);
};


export const getPredefinedMarker = (level, opr_token, opr_client_id) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.MAP.POINT + constants.END_POINTS.MAP.GET_PREDEFINED_MARKERS;

    const post = {
        level,
        token: opr_token,
        client_id: opr_client_id
    };

    simpleDispatch_post(dispatch, url, post, actionTypes.PREDEFINED_MARKERS_SUCCESS);

};


export const getAutocompleteNames = (name, level) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.MAP.POINT + constants.END_POINTS.MAP.GET_AUTOCOMPLETE_NAME;

    const post = {
        name,
        level
    };

    simpleDispatch_post(dispatch, url, post, actionTypes.GET_AUTOCOMPLETE_NAME_SUCCESS, true);
};


export const getSearchMarker = (id, level, columntoSearch) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.MAP.POINT + constants.END_POINTS.MAP.GET_SEARCH_MARKER;

    const post = {
        searchWhat: id,
        level,
        type: columntoSearch
    };

    simpleDispatch_post(dispatch, url, post, actionTypes.GET_SEARCH_MARKER_SUCCESS, true);
}


export const clearSearchMarker = () => async (dispatch) => {
    dispatchAction(dispatch, actionTypes.CLEAR_SEARCH_MARKER)
}