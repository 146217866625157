import React from 'react';

const AreaChart = (props) => {
	return (
		<svg
			width={props.size ? props.size : '2.2rem'}
			height={props.height ? props.height : '2.2rem'}
			fill={props.color ? props.color : '#000'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 1728 1728"
			space="preserve"
			{...props}
		>

			<path transform="translate(137,100)" d="m0 0h93l12 1 2 2-1 723-1 58-1 11 8-7 8-13 13-21 9-14 21-33 12-19 12-18 11-17 8-13 10-15 6-10 11-17 13-20 16-25 25-40 10-15 11-18 13-20 7-11 15-24 13-20 15-23 10-17 11-18 12-16 3-2 6 1 9 7 13 12 10 9 11 10 11 9 10 9 11 10 11 9 13 12 8 7 20 18 8 7 12 11 11 9 13 12 11 9 12 11 8 7 20 18 11 9 7 7 8 7 10 9 8 7 10 9 8 7 10 9 11 9 15 14 11 9 16 15 8 7 11 10 11 9 14 13 8 7 10 9 8 7 10 9 11 9 10 9 13 9 11 3 26 4 43 2 190 10 34 2 45 3 23 1 5 2 6 8 12 22 10 18 8 13 10 17 14 24 13 23 12 21 14 24 11 19 14 24 15 26 14 25 9 15 16 29 9 19 1 9v71l1 39 1 13 1 31v50l-1 28-3 15 1 9 1 5v43l-2 78-5 2-415 1h-1016l-4-2-1-2-1-48 1-751v-662l1-14z" fill="#1A60C2" />
			<path transform="translate(544,429)" d="m0 0 6 1 9 7 13 12 10 9 11 10 11 9 10 9 11 10 11 9 13 12 8 7 20 18 8 7 12 11 11 9 13 12 11 9 12 11 8 7 20 18 11 9 7 7 8 7 10 9 8 7 10 9 8 7 10 9 11 9 15 14 11 9 16 15 8 7 11 10 11 9 14 13 8 7 10 9 8 7 10 9 11 9 10 9 13 9 11 3 26 4 43 2 190 10 34 2 45 3 23 1 5 2 6 8 12 22 10 18 8 13 10 17 14 24 13 23 12 21 14 24 11 19 14 24 15 26 14 25 9 15 16 29 9 19 1 9v71l1 39 1 18-1-3-4-2-9-8-5-5-5-6v-2l-3-1-7-9v-2h-2l-3-4v-2h-2l-3-5-5-5v-2h-2l-8-11-4-4v-2l-3-1-1-4-3-1-2-4-4-4v-2h-2l-2-5-3-1-2-5h-2l-7-9v-2l-3-1-3-5-5-5v-2h-2l-3-4v-2h-2l-4-6-5-5-1-3-4-2-4-6-9-10-9-11-6-7-10-13-12-14-5-6v-2h-2l-6-7v-2h-2v-2h-3l-2 6-7 6-7 8-10 11-5 5-5 6-5 4-7 8-4 5-5 5-8 9-5 5-7 8-13 14-4 6-5 5-12 14-11 13-7 8-12 14h-2l-2 4-7 8-5 4-7 8-11 12-15 16-5 5-12 13-13 15-10 11-7 8-9 10-7 8-15 15-10 13-6 6-4-1-6-5-5-6v-2l-3-1-5-5-9-10-4-4v-2h-2l-7-8-5-6v-2l-4-2-5-6v-2h-2l-5-7-5-5-2-4-3-1-7-8-18-21v-2l-4-2-9-11-1-3h-2l-5-7-5-5-14-15-9-11-11-12v-2l-4-2-5-7-5-5-8-9v-2l-3-1-3-4v-2l-3-1-7-8-4-5v-2l-3-1-4-6-5-5-2-4-4-2-7-8-12-14-11-12-5-7-5-5-2-4-4-2-4-6-5-5-9-10v-2l-3-1-3-4v-2l-4-2-3-5-5-5-13-14-7-9-5-5-6-7v-2l-3-1-5-6v-2l-3-1-3-4v-2l-4-2-7-8-11-13-21-24-5-5-4-5v-2l-4-2-3-5-5-5-9-10-1-3-3-1-4-5v-2l-4-2-3-4v-2h-2l-8-10-8-8v-2l-4-2-5-6v-2l-4-2-4-5v-2l-4-2-9-11-3-3v-2l-4-2-5-6v-2l-4-2-3-4v-2l-4-2-9-11-2-4-4-2-6-8-7-6-7-8-6-8-4-4-4 1-16 15-14 14-7 8-7 7-7 8-3 3-1 3-5 5-14 15-7 8-9 10-2 4-4 4h-2l-2 4-14 15-5 5-24 26-11 11-7 8-8 9-8 7-9 10-12 13-9 11-7 7-9 11-10 11-9 11-10 10-7 8-12 13-16 17-11 11-2-1-1-38v-26l2-123 1-27v-14l7-9 10-17 11-17 11-18 7-10 10-16 12-19 10-15 11-17 8-13 10-15 6-10 11-17 13-20 16-25 25-40 10-15 11-18 13-20 7-11 15-24 13-20 15-23 10-17 11-18 12-16z" fill="#6EA1FD" />
			<path transform="translate(137,100)" d="m0 0h93l12 1 2 2-1 723-1 58-1 11 4-3h2v13l-1 27-2 123v26l1 38v3 159l1 100 2 79v15h870l184-1h236l39 1v27l-2 78-5 2-415 1h-1016l-4-2-1-2-1-48 1-751v-662l1-14z" fill="#2B76FE" />
			<path transform="translate(545,796)" d="m0 0 4 1 7 7 4 6v2l4 2 6 7 5 5 5 6 5 5 10 12 5 5 7 8 4 5 5 5 9 10 14 16 5 6 8 8v2l4 2 6 7v2h2l5 7 5 5 9 10 1 3 3 1 4 6 5 5 14 16 2 4 4 2 3 4v2l4 2 3 4v2l4 2 7 9 11 12 14 16 9 10 7 8 11 13 11 12v2l3 1 4 6 5 5 8 10 10 10 5 7 5 5 7 8 11 12 9 11 12 13 7 8 2 4 3 1 4 6 5 5 14 16 9 11 5 6 5 5 4 5 5 5 10 11 9 11 8 8 7 8v2l4 2 3 4v2h2l7 9 5 6 5 5 12 14 10 11 7 8 1 3 3 1 4 5v2h2l7 9 5 5 7 8 2 4h2v2l3 1 6 7v2l3 1 5 5 9 10v2l4 2 4 4-1 3-6-1-4-2-3-2v-2l-3-1-7-9-3-4-9-9-10-8v-2h-2l-6-7-5-5-2-5h-2v-2l-4-2-10-11v-2l-4-2v-2l-4-2v-2l-3-1-1-4-3-1-4-4v-2l-4-2-5-5v-2l-3-1-4-6-5-5-8-11-5-5v-2l-4-2-7-9v-2h-2l-3-5-2-1v-2l-4-2-5-6-12-12-4-5v-3h-2l-1-4h-3l-1-4-4-2-5-6v-2l-4-2-5-5-3-4v-2h-2l-2-5h-2l-9-9v-3l-4-2-1-3h-2l-5-7-3-3-11-14-5-5v-2h-2l-2-5-3-1-1-4h-2v-2h-2l-3-5-6-5-4-6-5-5-4-5v-2h-2l-3-4v-2h-2l-7-9-3-3v-2h-2l-7-8-4-4-3-4v-2h-2l-6-7-6-5-5-6-5-5-5-6-11-14-3-3-5-6-3-4v-2h-2l-3-4v-2h-2l-1-4h-2l-8-11-9-10-9-11-8-9-7-8-5-5v-2l-3-1-2-4-7-6-4-4-6-8-11-11v-2l-4-2-7-8v-2h-2v-2h-2l-3-6-6-7-6-5-2-4h-2l-2-2-1-3-3-1v-2h-2l-1-4h-2l-7-10-3-3-8-10-2-5-4-5v-2h-2l-4-6-4-5v-2l-3-1-3-3v-3l-5-1-7-1v2h-8l-6 3h-2v2l-7 6-1 2h-2v2h-2v2h-2v2h-2v2h-2v2h-2v2h-2v2h-2v2l-5 5-5 6h-2v3l-4 1-1 5h-2l-3 6h-2v2h-2v2h-2l-2 4-5 6h-2l-1 3h-2v3l-5 5-2 1-3 6h-2v2h-2v2h-2v2h-2l-2 4h-2l-2 4-4 6-4 3-4 2v3h-2v2h-2l-1 4-6 5-2 3h-2l-2 4-5 6h-2v2l-4 2v2l-4 2-3 3-1 3h-2v2l-4 2-2 4h-2v2h-2v2h-2v2h-2v2l-9 7-5 5h-2l-1 3-1-3 12-13 15-15 7-8 13-13 7-8 11-12 5-5 14-15 1-2h2l2-4h2l2-5 14-15 7-8 9-10 3-4h2l1-4 8-8 7-8 7-7 7-8 18-18z" fill="#0F53BB" />
			<path transform="translate(92,1721)" d="m0 0h34l25 1 1348 1-2 2h-13l-9 1-5 2v-3l-6 1h-3l-1 2h-2l-1-2h-6l-1 2h-4v-2l-7 1-5 1-1-2-3-1v3h-4l-5-1-8 1h-1160l-8-1h-35l-2-1-7 1-6-1-1 1-9 1-1-2-12-1h-56l-81-2v-1h57z" fill="#8A93A8" />
			<path transform="translate(1,380)" d="m0 0h1l1 78 2 3 1 72v301l-1 86h-1l-1 30-1 146-1 203h-1v-917zm2 369 1 3zm0 7 1 4z" fill="#B7B8BE" />
			<path transform="translate(1384,1100)" d="m0 0h3v2h2l6 7v2h2l1 5-7-4-7 1-4 4-7 4-9 9-5 7h-2v2h-2v2h-2v2h-2l-2 4h-2v3h-2v2h-2v2l-6 5-6 7-2 1v2h-2v2h-2v2h-2v2h-2l-2 4h-2v2h-2l-1 4h-2l-2 4h-2l-1 4h-2v2h-2v2h-2l-2 4-4 5-5 6-2 1-2 4-6 10-5 5-3 1-2 4-4 6h-2l-2 5-1 3-7 6h-2l-2 4-5 7-5 4h-2v2h-2v2h-2l-4 8h-2l-2 4-3-1v2h-2v2h-2v2h-2v2h-2l-1 3-5 7-4 2-2 3h-2v2h-2v2h-2v2h-2v2h-2l-1 3-8 7-3 5h-2v2h-2l-2 5-3 1v2h-2v2l-3 3-2 3h-3v2l-8 7-4 2 2-4 7-8 12-14 18-20 12-12 7-8 8-8 14-16 5-5 6-7 5-5 10-11 3-4h2l2-4 9-11 8-9 8-10h2l2-4 7-8 9-10 3-4h2l2-5 5-4 7-8 9-10 10-11h2l2-4 6-7 17-17 4-5h2l1-3h2l1-4z" fill="#1155BE" />
			<path transform="translate(1413,1134)" d="m0 0 4 1 8 11v2l4 2 9 11 12 14 4 6 5 5 7 8v2h2l3 4v2h2l3 4v2l3 1 3 5 4 4 5 6v2h2l3 5 4 4v2h2l2 5 3 1 2 4 3 3 5 6v2l3 1 6 8 1 3h2l3 5 5 5v2h2l3 4v2h2l8 11 10 10 4 5-2 5-6-2-2-3-5-4-3-7-3-3-6-4-6-8-2-4-2-1-3-5-5-5v-2l-3-1-7-8-10-12-7-8-6-8v-2l-3-1-9-11-4-7-7-6-8-13-5-6v-2l-4-2-1-4h-2l-9-11-7-8-6-9-1-5h-2l-1-4h-2l-4-7z" fill="#1054BC" />
			<path transform="translate(545,796)" d="m0 0 4 1 7 7 4 6v2l3 1-4 1v-2l-5-1-7-1v2h-8l-6 3h-2v2l-7 6-1 2h-2v2h-2v2h-2v2h-2v2h-2v2h-2v2h-2v2h-2v2l-5 5-5 6h-2v3l-4 1-1 5h-2l-3 6h-2v2h-2v2h-2l-2 4-5 6h-2l-1 3h-2v3l-5 5-2 1-3 6h-2v2h-2v2h-2v2h-2l-2 4h-2l-2 4-4 6-4 3-4 2v3h-2v2h-2l-1 4-6 5-2 3h-2l-2 4-5 6h-2v2l-4 2v2l-4 2-3 3-1 3h-2v2l-4 2-2 4h-2v2h-2v2h-2v2h-2v2l-9 7-5 5h-2l-1 3-1-3 12-13 15-15 7-8 13-13 7-8 11-12 5-5 14-15 1-2h2l2-4h2l2-5 14-15 7-8 9-10 3-4h2l1-4 8-8 7-8 7-7 7-8 18-18z" fill="#1256C0" />
			<path transform="translate(622,1470)" d="m0 0h69l12 1h28l3 1 9-1h9l2 1v2l-48 1-181-1v-1l11-2h48z" fill="#0F52BA" />
			<path transform="translate(322,1040)" d="m0 0 1 3-2 6-1 2h-2v2h-2l-2 4h-2l-2 4h-2l-2 4-2 3h-2l-2 4-9 11-5 5-6 7-5 3h-2l-2 4h-2l-1 5h-3l-1 4-4 2h-3v2l-5 1 1-4 12-12 7-8 8-8 7-8 12-13 11-12 8-7z" fill="#1154BD" />
			<path transform="translate(1721,625)" d="m0 0h1l1 78v196l-1 22h-1l-1-44v-62z" fill="#8A93A8" />
			<path transform="translate(1202,1470)" d="m0 0 9 1 7-1h28l9 1 8-1 9 1h15l8-1 13 1 4 2-36 1h-122v-2h16l8-1z" fill="#0F52BA" />
			<path transform="translate(3,18)" d="m0 0 3 1 1 4-1 123-1 26h-1v-77l1-2h-2l-1-52v-15l1-3h2v-3h-2zm1 6v7l2 1v-8z" fill="#B7B8BE" />
			<path transform="translate(2,163)" d="m0 0h1v43l-1 103-2 1v-121l1-23z" fill="#BBC1CC" />
			<path transform="translate(3,18)" d="m0 0 3 1 1 4v15l-1 24-1 2h-2l-1-23v-15l1-3h2v-3h-2zm1 6v7l2 1v-8z" fill="#B7B9C1" />
			<path transform="translate(344,1014)" d="m0 0 1 2-2 5-5 3-6 8-8 8-8 7-4 4-2-1 12-13 9-11 7-7z" fill="#155AC5" />
			<path transform="translate(13,1719)" d="m0 0h39l30 1v1h-60l-9-1z" fill="#F3F4F6" />
			<path transform="translate(5,62)" d="m0 0h1v33l-1-2h-2v-29h2z" fill="#B7B9C0" />
			<path transform="translate(1574,1453)" d="m0 0 2 1v18l-4-2-1-4 1-9z" fill="#1053BB" />
			<path transform="translate(1524,1723)" d="m0 0h48v1l-19 1h-30z" fill="#8A93A8" />
			<path transform="translate(1637,1722)" d="m0 0h59v1l-21 1h-14l-24-1z" fill="#8A93A8" />
			<path transform="translate(3,18)" d="m0 0 3 1 1 4v11l-3 4-1-4h-1v-8l1-3h2v-3h-2zm1 6v7l2 1v-8z" fill="#BCC3D0" />
			<path transform="translate(4,46)" d="m0 0 2 3v13l-1 2h-2v-14z" fill="#B8BAC2" />
			<path transform="translate(1,195)" d="m0 0 2 1v10l-2 15h-1v-19z" fill="#BCC3D0" />
			<path transform="translate(1501,1723)" d="m0 0h16l2 2h-19z" fill="#8A93A8" />
			<path transform="translate(1384,1100)" d="m0 0h3l-2 4-3 2 1-4zm-4 6 2 1-3 2zm-3 3 2 1-3 3-2-1z" fill="#1D64D7" />
			<path transform="translate(1,344)" d="m0 0h1v11h-2v-10z" fill="#BCC3D0" />
			<path transform="translate(1489,1726)" d="m0 0h9v2h-10z" fill="#8A93A8" />
			<path transform="translate(1,332)" d="m0 0 1 4v7h-2v-10z" fill="#BCC3D0" />
			<path transform="translate(165,1726)" d="m0 0h17v1h-17z" fill="#8A93A8" />
			<path transform="translate(1,368)" d="m0 0h1v11l-2-2z" fill="#BCC3D0" />
			<path transform="translate(1619,1722)" d="m0 0h16v1h-16z" fill="#8A93A8" />
			<path transform="translate(0,358)" d="m0 0 2 1-1 7h-1z" fill="#BCC3D0" />
			<path transform="translate(0,1305)" d="m0 0h1v8h-1z" fill="#B7B8BE" />
			<path transform="translate(0,327)" d="m0 0 2 4-2 1z" fill="#BCC3D0" />
			<path transform="translate(65,4)" d="m0 0h7v1h-7z" fill="#B7B8BE" />
			<path transform="translate(57,4)" d="m0 0h7v1h-7z" fill="#B7B8BE" />
			<path transform="translate(1482,1726)" d="m0 0 4 1-4 1z" fill="#8A93A8" />
			<path transform="translate(0,314)" d="m0 0h1v6h-1z" fill="#BCC3D0" />
			<path transform="translate(1588,1723)" d="m0 0h5v1h-5z" fill="#8A93A8" />
			<path transform="translate(2,156)" d="m0 0h1v5h-1z" fill="#BBC0CC" />
			<path transform="translate(1502,1726)" d="m0 0 3 1-3 1z" fill="#8A93A8" />
			<path transform="translate(1582,1723)" d="m0 0 4 1z" fill="#8A93A8" />
			<path transform="translate(1519,1723)" d="m0 0 4 1z" fill="#8A93A8" />
			<path transform="translate(0,1300)" d="m0 0 1 4z" fill="#B7B8BE" />
			<path transform="translate(29,4)" d="m0 0 4 1z" fill="#B7B8BE" />
			<path transform="translate(153,1726)" d="m0 0 3 1z" fill="#8A93A8" />
			<path transform="translate(1709,1723)" d="m0 0 3 1z" fill="#999FAF" />
			<path transform="translate(1479,1726)" d="m0 0" fill="#8A93A8" />
			<path transform="translate(1569,1726)" d="m0 0 2 1z" fill="#B7B8BE" />
			<path transform="translate(1431,1726)" d="m0 0 2 1z" fill="#8A93A8" />
			<path transform="translate(157,1726)" d="m0 0 2 1z" fill="#8A93A8" />
			<path transform="translate(1579,1723)" d="m0 0 2 1z" fill="#8A93A8" />
			<path transform="translate(1573,1723)" d="m0 0 2 1z" fill="#8A93A8" />
			<path transform="translate(1,356)" d="m0 0" fill="#BCC3D0" />
			<path transform="translate(51,4)" d="m0 0 2 1z" fill="#B7B8BE" />
			<path transform="translate(43,4)" d="m0 0 2 1z" fill="#B7B8BE" />
			<path transform="translate(39,4)" d="m0 0 2 1z" fill="#B7B8BE" />
			<path transform="translate(1526,1727)" d="m0 0" fill="#8A93A8" />
			<path transform="translate(1514,1727)" d="m0 0" fill="#8A93A8" />
			<path transform="translate(1500,1727)" d="m0 0" fill="#8A93A8" />
			<path transform="translate(1441,1727)" d="m0 0" fill="#8A93A8" />
			<path transform="translate(1582,1726)" d="m0 0" fill="#8A93A8" />
			<path transform="translate(1559,1726)" d="m0 0" fill="#8A93A8" />
			<path transform="translate(1529,1726)" d="m0 0" fill="#8A93A8" />
			<path transform="translate(1527,1726)" d="m0 0" fill="#8A93A8" />
			<path transform="translate(1515,1726)" d="m0 0" fill="#8A93A8" />
			<path transform="translate(1506,1726)" d="m0 0" fill="#8A93A8" />
			<path transform="translate(1465,1726)" d="m0 0" fill="#8A93A8" />
			<path transform="translate(163,1726)" d="m0 0" fill="#8A93A8" />
			<path transform="translate(1707,1723)" d="m0 0" fill="#8A93A8" />
			<path transform="translate(1602,1723)" d="m0 0" fill="#8A93A8" />
			<path transform="translate(33,1722)" d="m0 0" fill="#8A93A8" />
			<path transform="translate(0,1316)" d="m0 0" fill="#B7B8BE" />
			<path transform="translate(1,366)" d="m0 0" fill="#BCC3D0" />
			<path transform="translate(0,322)" d="m0 0" fill="#BCC3D0" />
			<path transform="translate(35,4)" d="m0 0" fill="#B7B8BE" />
		</svg>

	)

}


export default AreaChart;