import React from 'react';
import { store } from '../../store';
import * as helper from '../../actions/helper';

import { LOADER_SHOW, LOADER_HIDE} from  '../../action-types/loader';

import * as sessionHelper from '../../utils/session.helper';

class Wrapper extends React.Component {
    
    showError(error) {
        helper.showError(store.dispatch, error);
    }

    showPopup(title, message) {
        helper.showPopup(store.dispatch, title, message);
    }

    showLoader() {
        store.dispatch({
            type: LOADER_SHOW
        });
    }

    killLoader() {
        store.dispatch({
            type: LOADER_HIDE
        });
    }

    loggedUser = () => {
        return sessionHelper.getLoggedUser();
    }

    isLoggedIn = () => {
        return !!this.loggedUser();
    }

    render() {
        return <div />
    }
};


export default Wrapper;