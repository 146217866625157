export const REPORT_INIT = 'modules/report/init';
export const META_COLUMN_DATA_REQUESTED = 'modules/report/metaColumn/request';
export const META_COLUMN_DATA_SUCCESS = 'modules/report/metaColumn/success';

export const DATA_FROM_RS_REQUESTED = 'modules/report/data/request';
export const DATA_FROM_RS_SUCCESS = 'modules/report/data/success';
export const CLEAR_DATA_FROM_SERVER = 'module/report/CLEAR_DATA_FROM_SERVER'
export const DISTINCT_DATA_FROM_RS_REQUESTED = 'modules/report/distinct-data/request';
export const DISTINCT_DATA_FROM_RS_SUCCESS = 'modules/report/distinct-data/success';

export const HINTS_FROM_RS_SUCCESS = 'modules/report/hints/success';
export const CLEAR_HINTS_FROM_RS = 'modules/report/clear_hints_from_rs'
export const CHANGE_ACTIVE_DATA_SUCCESS = 'modules/report/changeActiveData/success';

export const CLEAR_ALL_DATA = 'modules/report/data/cleardata';

export const GET_ALL_REPORTS_REQUESTED = 'modules/report/data/allReports/requested';
export const GET_ALL_REPORTS_SUCCESS = 'modules/report/data/allReports/success';

export const SAVE_REPORT_REQUESTED = 'modules/report/data/saveReport/requested';
export const SAVE_REPORT_SUCCESS = 'modules/report/data/saveReport/success';
export const GET_FORMILAS_BY_REPORT_ID = 'modules/report/data/get_formula_by_report_id'
export const CLEAR_FORMULAS = 'modules/report/data/clear_formulas'
export const SHARE_REPORT = 'modules/report/sharedata/share_report';
export const SAVE_TBL_FORMAT_SETTING = 'modules/report/SAVE_TBL_FORMAT_SETTING'
export const GET_TBL_FORMAT_SETTING = 'modules/report/GET_TBL_FORMAT_SETTING'
export const ADD_SAVE_TBL_FORMAT_SETTING = 'modules/report/ADD_SAVE_TBL_FORMAT_SETTING'


export const SAVE_TBL_FORMULAS = 'modules/report/SAVE_tbl-formulas';
export const GET_TBL_FORMULAS = 'modules/report/GET_tbl-formulas';
export const GET_TBL_FORMULAS_FOR_EDITOR = 'modules/report/GET_tbl-formulas-for-editor';
export const ADD_TBL_FORMULAS = 'modules/report/ADD_tbl-formulas'

export const GET_TBL_FORMULA_FOR_EDITOR = 'modules/report/GET_tbl-form_for_editor';

export const GET_FUNCTIONS_CONFIG = 'modules/report/GET_functions_config'
export const GET_WEEK_CONFIG = 'modules/report/GET_WEEK_CONFIG'

export const SAVE_DRILL_DOWN_VALUES = 'modules/report/drill_down_data';

export const CLEAR_REPORT_DATA = 'modules/report/clear_report_data';


export const REPORT_TO_REFRESH = 'modules/report/refresh_report';
export const REPORT_REFRESHED = 'modules/report/report_refreshed';
export const RESET_DATA_FROM_SERVER = 'modules/report/RESET_DATA_FROM_SERVER_for_All';


// SLQ
export const TEST_SQL_SUCCESS = 'modules/report/TEST_SQL_SUCCESS';
export const RAISE_TEST_QUERY_ERROR = 'modules/report/RAISE_TEST_QUERY_ERROR';
export const CLEAR_TEST_QUERY_DATA = 'modules/clear/test_query_data';
export const RAISE_SQL_LOADER = 'modules/raise_sql_loader';
export const HIDE_SQL_LOADER = 'modules/hide_sql_loader';



export const DISPATCH_PIVOT_DATA_SUCCESS = 'modules/report/DISPATCH_PIVOT_DATA';
export const SAVE_PIVOT_DATA_SUCCESS = 'modules/report/SAVE_PIVOT_DATA_SUCCESS';
export const CLEAR_PIVOT_DATA_SUCCESS = 'modules/report/CLEAR_PIVOT_DATA';
export const CHANGE_DATA_MODE = 'modules/report/CHANGE_DATA_MODE';
export const UPDATE_PIVOT_DATA = 'modules/report/UPDATE_PIVOT_DATA';
export const UPDATE_PROCESS_DATA = 'modules/report/UPDATE_PROCESS_DATA';
export const SAVE_REPORT_ITEM = 'modules/report/SAVE_REPORT_ITEM';
export const UPDATE_REPORT_COLUMNS_INFO = 'modules/report/UPDATE_REPORT_COLUMNS_INFO'
export const SWITCH_ROWS_INTO_COLUMNS = 'modules/report/SWITCH_ROW_INTO_COLUMNS';
export const DISPATCH_FORMULA_DATA = 'modules/report/DISPATCH_FORMULA_DATA';

export const RAISE_DASHBOARD_WIDGETS_LOADER = 'modules/report/RAISE_DASHBOARD_WIDGETS_LOAD';
export const HIDE_DASHBOARD_WIDGETS_LOADER = 'modules/report/HIDE_DASHBOARD_WIDGETS_LOADER'




export const UPDATE_REPORT_COLUMN_STATE = 'modules/report/UPDATE_REPORT_COLUMN_STATE';


export const GET_ALL_DATA_SOURCE_SUCCESS = 'modules/report/GET_ALL_DATA_SOURCE_ SUCCESS';


export const GET_POLUS_AI_DATA_STORY = 'modules/report/GET_POLUS_AI_DATA_STORY';