import React, { useEffect, useState } from 'react';
import TitleHeader from '../title-header';
import { connect } from 'react-redux';
import { getStreamingJobs, pushEditedRowDataToStore, saveTheStreamingData } from '../../actions/streaming';
import { deleteConfig, saveMultipalConfig, updateJob } from '../../actions/etl'
import { withRouter } from 'react-router';
import SharedTable from '../shared-table'
import { PageInnerWrapper } from '../hoc/styled.hoc';
import { Button } from '../hoc/button';
import TextBox from '../hoc/textBox';
import styled from 'styled-components';
import RippleEffect from '../hoc/ripple-effect';
import PlaySvg from '../svg/play';
import PauseSvg from '../svg/pause';
import CalanderSvg from '../svg/calander';
import { showPopup } from '../../utils';
import * as enums from '../../utils/enums';
import SchedulePopup from '../etl/schedule.popup';
import ConfirmBox from '../confirm-box';
import StreamingEditorPopup from './streaming_editro/streaming.editor.popup'


const Wrapper = styled.div`
    width: 100%;
`;


const Index = (props) => {

    const { streamingJobData, getStreamingJobs, deleteConfig, currentEditedStream, updateJob } = props;

    const [localStreamData, setLocalStreamData] = useState(undefined);
    const [selected, setSelected] = useState(undefined);
    const [sortOrder, setSortOrder] = useState(undefined)
    const [dateValue, setDateValue] = useState('2021-04-08 18:10:01')


    useEffect(() => {
        setLocalStreamData(streamingJobData)
        if (streamingJobData && streamingJobData.length === 0) {
            props.history.push("/streaming_editor")
        }
    }, [streamingJobData])


    useEffect(() => {
            let _page_info = {
                title: 'STREAMNG DATA',
                extraComponent: <Button onClick={() => {
                    props.history.push('/streaming_editor');
    
                }}>Add Streaming</Button>
            }
            props.dispatchHeaderInformation(_page_info)
        getStreamingJobs()
    }, [])


    const historyAction = (job) => {
        if (job && job.id) {
            let _path = `etl_details?config_id=${job.id}`;
            props.history.push(_path)
        }
    }


    const editJobSave = (data) => {
        const save_data = {
            ...data,
            modified: true,
        }
        props.saveTheStreamingData(save_data)
    }

    const editJobClicked = (job) => {
        let { id } = job;
        showPopup(undefined, undefined, enums.default.popupType.element, StreamingEditorPopup, {
            config_id: id,
            serviceId: undefined,
            save_func: editJobSave
        }
        )
    }




    const searchJobFun = (value) => {

        let search_value = value && value.toLowerCase();
        let clone_jobs = JSON.parse(JSON.stringify(streamingJobData));
        let after_filterd = clone_jobs.filter((job) => {
            if (value === '') return true;
            else if (
                (job.name && job.name.toLowerCase().indexOf(search_value) > -1) ||
                (job.target_table_name && job.target_table_name.toLowerCase().indexOf(search_value) > -1) ||
                (job.status && job.status.toLowerCase().indexOf(search_value) > -1) ||
                (job.user_name && job.user_name.toLowerCase().indexOf(search_value) > -1)
            ) return true
            else return false;
        });
        setLocalStreamData(after_filterd)
    }







    const selectItemClickOnChecbox = (item, type) => {
        let clone_jobs = localStreamData ? JSON.parse(JSON.stringify(localStreamData)) : [];
        let temp_selected = selected ? JSON.parse(JSON.stringify(selected)) : [];
        if (type === 'all') {
            if (temp_selected.length === clone_jobs.length) {
                temp_selected = []
            }
            else {
                temp_selected = [];
                clone_jobs && clone_jobs.length > 0 && clone_jobs.map((j, i) => {
                    temp_selected.push(j.id)
                })
            }
        }
        if (type === 'single') {
            if (temp_selected.indexOf(item.id) > -1) {
                let _index = temp_selected.indexOf(item.id);
                temp_selected.splice(_index, 1)
            } else {
                temp_selected.push(item.id)
            }
        }
        setSelected(temp_selected)
    }


    const deleteJobClicked = (job) => {
        let { id } = job;
        deleteConfig(id, 'streaming')
        setSelected(undefined)
    }


    const localSaveAction = (data, field, value, j_type, message) => {
        updateJob(data, field, value, j_type, message)
        setSelected(undefined)
        setTimeout(() => {
            getStreamingJobs()
        }, 500)
    }


    const scheduleButtonClicked = () => {
        showPopup(undefined, undefined, enums.default.popupType.element, SchedulePopup, {
            selected: selected,
            _save: (value) => localSaveAction(selected, 'next_run_at', value, 'stream')
        })
    }



    const saveButtonClicked = () => {
        let keys = currentEditedStream ? Object.keys(currentEditedStream) : [];
        let final_data = [];
        keys && keys.forEach(key => {
            final_data.push(currentEditedStream[key])
        })
        props.saveMultipalConfig(final_data, undefined, 'streaming')

    }


    const stopJobClicked = () => {
        showPopup(undefined, 'Are you sure you want to Stop this ? ', enums.default.popupType.element, ConfirmBox, { func: { setYes: () => localSaveAction(selected, 'status', '0', 'stream', 'Jobs Stopped') } })

    }

    const startJobClicked = () => {
        showPopup(undefined, 'Are you sure you want to Start this ? ', enums.default.popupType.element, ConfirmBox, { func: { setYes: () => localSaveAction(selected, 'status', '1', 'stream', 'Jobs Started') } })

    }





    return (
        <Wrapper style={{marginTop: '1rem'}}>
            {/* <TitleHeader title={'Streaming Data'} extraOption={<Button onClick={() => {
                props.history.push('/streaming_editor');

            }}>Add Streaming</Button>} /> */}
            <PageInnerWrapper  padding="10px 25px 25px" overflow={"inheret"}>

                {/* <div style={{ width: '13rem' }}>
                    <DateTimePiker
                        value={dateValue}
                        onChange={(value) => {
                            setDateValue(value)
                        }}
                        isTime={true}
                    />
                </div> */}

                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        {selected && selected.length > 0 && (
                            <React.Fragment>
                                <div style={{ marginRight: '30px' }}>
                                    <RippleEffect
                                        position="top"

                                        Click={startJobClicked}
                                        icon={<PlaySvg size="1rem" height="1rem" color="#757575" />}
                                        tooltip="Start"
                                    />
                                </div>
                                <div style={{ marginRight: '30px' }}>
                                    <RippleEffect
                                        position="top"
                                        Click={stopJobClicked}
                                        icon={<PauseSvg size="1rem" height="1rem" color="#757575" />}
                                        tooltip="Stop"
                                    />
                                </div>
                                <div style={{ marginRight: '30px' }}>
                                    <RippleEffect
                                        position="top"

                                        Click={scheduleButtonClicked}
                                        icon={<CalanderSvg size="1rem" height="1rem" color="#757575" />}
                                        tooltip="Schedule change "
                                    />
                                </div>
                            </React.Fragment>
                        )}


                        {currentEditedStream && (
                            <div style={{ marginRight: '30px' }}>
                                <Button primary={true} onClick={saveButtonClicked} >Save</Button>

                            </div>
                        )}
                    </div>


                    <div style={{ margin: '1rem 0px', width: '30%' }}>
                        <TextBox
                            label="Search Jobs"
                            type="text"
                            placeholder="Search Job"
                            onChange={(e) => {
                                searchJobFun(e.target.value)
                            }}
                        />
                    </div>
                </div>





                {localStreamData && localStreamData.length > 0 ? (
                    <SharedTable
                        action={true}
                        historyAction={(item) => historyAction(item)}
                        editAction={(item) => editJobClicked(item)}
                        deleteAction={(item) => deleteJobClicked(item)}
                        selectAllCheckboxAction={() => {
                            selectItemClickOnChecbox(undefined, 'all')
                        }}
                        updateRecordAction={(item) => props.pushEditedRowDataToStore(item)}
                        checkBoxAction={(item) => selectItemClickOnChecbox(item, 'single')}
                        selected={selected}
                        sortOrder={sortOrder}
                        tableData={(localStreamData && localStreamData.length > 0) ? localStreamData : undefined}
                        tableHead={['Name', 'Last Ran Time', 'Next Run Time', 'Frequency', 'Status', 'Created By', 'Actions']}
                        columnsToShow={['name', 'last_ran_at', 'next_run_at', 'frequency_time', 'status', 'user_name']}
                        DateColumns={["next_run_at", 'last_ran_at']}
                        fullTime={true}
                        sortColumns={{
                            "Name": {
                                type: "string",
                                dataKey: 'name'
                            },
                            "Created By": {
                                type: "string",
                                dataKey: 'user_name'
                            },
                            "Created At": {
                                type: "date",
                                dataKey: 'created_at'
                            },
                            'Status': {
                                type: 'string',
                                dataKey: 'status'
                            }
                        }}
                        pointerRow={true}
                        maximumWidthOfColumn={{
                            "question": '20rem'
                        }}
                        editedColumns={[
                            { type: "text", name: "name" },
                            { type: "date", name: "next_run_at" }
                        ]}
                    />

                ) : <p style={{ textAlign: "center" }}>No Streaming Job Found...</p>}

            </PageInnerWrapper>
        </Wrapper>
    )
}


const mapStateToProps = (state) => ({
    streamingJobData: state.streamingReducer.streamingJobData,
    currentEditedStream: state.streamingReducer.currentEditedStream
})



export default withRouter(connect(mapStateToProps, { getStreamingJobs, deleteConfig, pushEditedRowDataToStore, saveMultipalConfig, updateJob, saveTheStreamingData })(Index))
