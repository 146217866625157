


const raw_data = [{ service: 'surgery', reference: 'i4', revenue: 8663, visits: 394 },
{ service: 'opd', reference: 'i2', revenue: 5399, visits: 186 },
{ service: 'opd', reference: 'i1', revenue: 7498, visits: 625 },
{ service: 'surgery', reference: 'i4', revenue: 6764, visits: 242 },
{ service: 'ipd', reference: 'i4', revenue: 4545, visits: 207 },
{ service: 'opd', reference: 'i1', revenue: 9669, visits: 483 },
{ service: 'opd', reference: 'i4', revenue: 1585, visits: 53 },
{ service: 'surgery', reference: 'i2', revenue: 9609, visits: 686 },
{ service: 'ipd', reference: 'i1', revenue: 5789, visits: 445 },
{ service: 'ipd', reference: 'i4', revenue: 7406, visits: 296 },
{ service: 'opd', reference: 'i3', revenue: 9973, visits: 344 },
{ service: 'ipd', reference: 'i3', revenue: 8220, visits: 433 },];


export const calculate_trend_line = (raw_data = [], ys = []) => {

    const n = raw_data.length;

    let multiplication_of_all = {};
    let summation_of_all = 0;
    let summation_of_x = {};
    let summation_of_x_square = {};
    let summation_of_y = {};

    raw_data.forEach((data_row, row_index) => {
        ys.forEach(y => {
            const y_value = data_row[y] || 0;

            if (!summation_of_y[y]) summation_of_y[y] = 0;
            if (!summation_of_x[y]) summation_of_x[y] = 0;
            if (!summation_of_x_square[y]) summation_of_x_square[y] = 0;
            if (!multiplication_of_all[y]) multiplication_of_all[y] = 0;

            multiplication_of_all[y] = (multiplication_of_all[y] || 0) + ((row_index + 1) * y_value);
            summation_of_y[y] = (summation_of_y[y] || 0) + y_value
            summation_of_x[y] = (summation_of_x[y] || 0) + row_index + 1;
            summation_of_x_square[y] = (summation_of_x_square[y] || 0) + ((row_index + 1) * (row_index + 1));
        });
    });


    const equations = {};

    ys.forEach(y => {


        /**
         * Let a equal n times the summation of all x-values multiplied by their corresponding y-values, like so:
         */
        const a = n * multiplication_of_all[y];

        /** * Let b equal the sum of all x-values times the sum of all y-values, like so: */
        const b = summation_of_x[y] * summation_of_y[y];
        /**Let c equal n times the sum of all squared x-values, like so: */
        const c = n * summation_of_x_square[y];
        /**Let d equal the squared sum of all x-values, like so:*/
        const d = summation_of_x[y] * summation_of_x[y];


        const slope_m = (a - b) / (c - d);

        /**Let e equal the sum of all y-values, like so: */
        const e = summation_of_y[y];

        /**Let f equal the slope times the sum of all x-values, like so: */
        const f = slope_m * summation_of_x[y];

        /**Plug the values you have calculated for e and f into the following equation for the y-intercept, b, of the trendline: */

        const y_interceptor_b = (e - f) / n;


        raw_data.forEach((data_row, row_index) => {
            data_row['nfx_' + y + '_trends'] = ((row_index * slope_m) + y_interceptor_b);
        });

        equations[y] = `${slope_m.toFixed(2)}x + ${y_interceptor_b.toFixed(2)}`;
    })

    return equations;
};


// calculate_trend_line(raw_data, ['revenue', 'visits']);

// console.log('raw_data: ', raw_data);
