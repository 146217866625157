import styled from 'styled-components';



export const BackButton = styled.div`
    margin-top: -25px;
    position: sticky;
    top: -25px;
    z-index: 2;
    min-height: 3.571rem;
    display: flex;
    align-items: center;

    .back_btn{
        cursor: pointer;
        svg{
            transform: rotate(180deg);
        }

        &:hover{
            svg{
                fill: #24a8d6;
            }
        }
    }

`;



export const MainWrapper = styled.div`
    .heading{
        font-size: 1.286rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.714rem;
        letter-spacing: normal;
        text-align: left;
        color: #1a1a1a;
        /* margin-bottom: 1.5rem; */
        margin-top: 0px;
    }

`;
export const Wrapper = styled.div`
    width: 61rem;
    min-height: 31rem;;
    max-height: 50rem;
    /* height: 40rem; */
    /* background: #f4f6f7; */
    background: #fff;
    margin: auto;
    position: relative;
    border: solid 2px #ccc;
    border-radius: 20px;
    padding: 3rem 3rem 3rem 1rem;
    /* padding-top: 0.2rem; */
    display: flex;
    flex-direction: column;


    .delete_file{
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        svg{
            fill: red;
        }
    }
    .dismiss{
        position: absolute;
        right: .5rem;
        top: .5rem;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 4px;
        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        cursor: pointer;
            svg{
                      fill: rgb(0,138,199);
            }

        &:hover{
            background: #356d941c;
            svg{
                fill: rgb(0,138,199);
            }
        }
    }


    .__label{
        font-weight: 500;
        font-size: 1.1rem;
        margin-bottom: 4px;
        display: block;
    }
    
    .file_name{
        font-size: 1.286rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.714rem;
        letter-spacing: normal;
        text-align: left;
        color: #1a1a1a;
        margin-right: 100px;
        display: flex;
        max-width: 295px;
        align-items: center;
    }

    .flex_box{
        display: flex;
        width: 100%;
    }
    .thumbnail{
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        min-height: 31rem;

    }
    .file_other_properties{
        
        width: 70%;
        .table_wrapper{
            border: 1px solid #ccc;
            border-radius: 12px;
            padding: 0.9rem;
        }
        .item_row{
            width: 100%;
            padding: 0.6rem 1rem;
            display: flex;
            border-radius: 6px;
            margin-bottom: 0.5rem;
            box-sizing: border-box;
        }
        .item_search{
        }
        .scroll_items_wrapper{
            position: relative;
            min-height: 3rem;
            max-height: 6rem;
            overflow-y: auto;
            &::-webkit-scrollbar {
            background-color: #3f3f3f;
            width: 4px;
            }
            &::-webkit-scrollbar-track {
            background-color: #f3f3f3;
            border: none;
            }
            &::-webkit-scrollbar-thumb {
            background-color: #dbdbdb;
            border-radius: 10px;
            height: 4px;
            }
            &::-webkit-scrollbar:horizontal {
            background-color: #3f3f3f;
            height: 4px;
            }
            &::-webkit-scrollbar-button {
            display: none;
            } 
        }
        .scroll_items_wrapper_sec{
            position: relative;
            min-height: 3rem;
            max-height: 12rem;
            overflow-y: auto;
            margin-bottom: 2rem;
            &::-webkit-scrollbar {
            background-color: #3f3f3f;
            width: 4px;
            }
            &::-webkit-scrollbar-track {
            background-color: #f3f3f3;
            border: none;
            }
            &::-webkit-scrollbar-thumb {
            background-color: #dbdbdb;
            border-radius: 10px;
            height: 4px;
            }
            &::-webkit-scrollbar:horizontal {
            background-color: #3f3f3f;
            height: 4px;
            }
            &::-webkit-scrollbar-button {
            display: none;
            } 
        }
        .select_more_file{
            color: #000;
            font-size: 1.2rem;
            cursor: pointer;
            background: #f2f2f2;
            height: 2.4rem;
            width: 38%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            margin:auto;
            margin-top: 0.6rem;

            &:hover{
                opacity: .8;
            }
        }
        .add_icon_outline{
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
            border: 1px solid #999999;
            height: 1.2rem;
            width: 1.2rem;
            margin-right: 1rem;
        }
        .table_scroll{
            max-height: 16rem;
            height: 16rem;
            overflow-x: auto;
            &::-webkit-scrollbar {
            background-color: #3f3f3f;
            width: 4px;
            }
            &::-webkit-scrollbar-track {
            background-color: #f3f3f3;
            border: none;
            }
            &::-webkit-scrollbar-thumb {
            background-color: #dbdbdb;
            border-radius: 10px;
            height: 4px;
            }
            &::-webkit-scrollbar:horizontal {
            background-color: #3f3f3f;
            height: 4px;
            }
            &::-webkit-scrollbar-button {
            display: none;
            }
        }

    }

`;



export const FileUploadButton = styled.div`
        width: 7rem;
        margin-right: 1rem;
        height: 2.125rem;
        /* border-radius: 5px; */
        background-color: #008ac7;
        position: relative;
        cursor: pointer;
        text-transform: uppercase;
        box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
        /* margin-top: 1.4rem; */
        border-radius: 4px;

        &:hover{
            cursor: pointer;
            opacity: .8;
        }
        .text{
            position: absolute;
            text-transform: uppercase;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            font-size: 0.875rem;
            left: 0px;
            right: 0px;
            margin: auto;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-weight: 500;
            font-family: "DM Sans",sans-serif;

            cursor: pointer;
            color: #ffffff;
        }
        input{ 
            opacity: 0;
            width: 100%;
            height: 2.571rem;
            cursor: pointer;

        }

`;




export const ProgressOverlay = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 6;
    background-color: ${props => props.bg ? props.bg : '#ffffff75'};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* opacity: .9; */

    .content{
        position: fixed;
        top: 0;
        border: 0;
        margin: auto;
        left: 0;
        width: 65rem;
        border-radius: 6px;
        background: #fffefe;
        right: 0px;
        bottom: 0px;
        height: 25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h1{
            font-size: 30px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 39px;
            letter-spacing: normal;
            text-align: center;
            color: #1a1a1a;
            margin: 0
        }

        h2{
            font-size: 48px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 63px;
            letter-spacing: normal;
            text-align: center;
            color: #1a1a1a;
            margin: 15px 0px;
        }
    }
`;


export const ProgressBar = styled.div`
    width: 32.429rem;
    background-color: #bbd1d9;
    height: 10px;
    border-radius: 5px;
    position: relative;

    &::before {
        content: '';
        width: ${props => props.progress ? props.progress + "%" : 0};
        height: 10px;
        z-index: 2;
        background: #008ac7;
        position: absolute;
        border-radius: 5px;

    }

`;


export const SelectCategoryWrapper = styled.div`
        width: 100%;
        position: relative;

        .list_view_wrapper{
          position: absolute;
          width: 100%;
          max-height: 200px;
          overflow-y: auto;
          background :#fff;
          z-index: 1;
          top: 100%;
          box-shadow: 0 4px 8px 0 rgba(0,0,0,.15);    
          border: 1px solid #eff3f6;
            &::-webkit-scrollbar {
                background-color:red;
                width:5px
            }

            &::-webkit-scrollbar-track {
                background-color:#b1cad5;
            }

            &::-webkit-scrollbar-thumb {
                background-color:#76919d;
                border-radius:0px;
                height: 5px;
            }
            &::-webkit-scrollbar:horizontal{
                background-color: green;
                height: 5px;
            }
      }

      .item{
          font-size: .9rem;
          padding: 7px 5px;
          box-sizing: border-box;
          cursor: pointer;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: #636363;

          &:hover{
              background-color: whitesmoke;
              color: #000;
          }
      }
`;


export const FolderRow = styled.div`

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    /* margin: 2rem 0rem; */

`;



export const Folder = styled.div`
      width: 70%;
      height: 28rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-right: 1rem;
      margin-bottom: 1rem;
      cursor: pointer;
      background: #f2f2f285;
      position: relative;
      border: 1px dashed #222;
      border-radius: 10px;
      margin: auto;
      margin-top: 5rem;

      .__or__{
            width: 400px;
            margin: 2rem auto;
            text-align: center;
            color: #8f8787;
            font-size: 1rem;
            position: relative;

            &::after{
                content: '';
                position: absolute;
                width: 170px;
                height: 1px;
                top: 50%;
                border-top: 1px solid #ccc;
                right: 0px;
            }
            &::before{
                content: '';
                position: absolute;
                width: 170px;
                height: 1px;
                top: 50%;
                border-top: 1px solid #ccc;
                left: 0px;
            }
      }

      input{
            width: 100%;
            height: 100%;
            position: absolute;
            opacity: 0;
            cursor: pointer;
      }

      img{
        width: 12rem;
        height: auto;
      }

      &:hover{
          svg{
              fill: #075c81;
          }
      }

      p{
        font-size: 1.2rem;
        /* text-transform: capitalize; */
        margin: 0px;
        margin-top: 13px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        white-space: nowrap;
        width: 91%;
        text-align: center;
        color: #444;
      }
      

`;


export const UploadedFileList = styled.div`
    /* border-top: 1px solid #008AC7; */
    width: 100%;
    /* border-top: 1px solid #008AC7; */
    /* max-height: 30rem; */
    /* overflow-y: auto; */
    /* padding: 0px 5px; */


    .current_file_heading{
        font-size: 1.286rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.714rem;
        letter-spacing: normal;
        text-align: left;
        margin: 0px;
        color: #748891;
        /* margin-bottom: 10px; */
        text-transform: capitalize;
    }

    /* &::-webkit-scrollbar {
            background-color:red;
            width:5px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }

    .file_view_btn{
        cursor: pointer;
        font-size: 0.9rem;
        margin-left: 10px;
    }

    .text_style{
        max-width: 25rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }


    .search_sort{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        margin-bottom: 1rem;


       
    }


    .toggle_btn{
        margin-top: 8px;
        width: 10rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 0px;
        font-size: 1rem; 
        padding: 8px 1rem;
        box-sizing: border-box;
        border-radius: 5px;
        cursor: pointer;

        &:hover{
            opacity: 0.9;
            background: #318ac745;
        }
    }

   

    .uploaded_file{
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.286rem;
        letter-spacing: normal;
        text-align: left;
        color: #1a1a1a;
        padding: 5px 2px;
        cursor: pointer;
        display: flex;
        align-items: center;

        &:hover{
            background: #eaeaeaa6;
            border: 7px;
        }




    } */
`;


export const DivRow = styled.div`
    width: ${props => props.w ? props.w : '100%'};
    

`;