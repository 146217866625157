import React, { useEffect, useState } from "react";
import { useFormula } from "./useFormula";
import { FormulaWrapper } from "./formula_builder.styled";
import SchemaTableWindow from "../editor.shared/left.side.panel";
import { connect } from "react-redux";
import { get_schema_table_info } from "../../actions/report_builder";
import {
    getClientId,
    getDbInfoId,
    getParameterByName,
    isDefaultLiveDb,
} from "../../utils";
import {
    search_table_and_column,
    sort_table_and_column,
} from "../editor.shared/builder.shared.functions";
import TextBoxV1 from "../hoc/text.box.v1";
import ColumnEditor from "./column.editor";
import BlockElements from "./block.elements";
import AggregationIfPopup from "./aggregation.if";
import {
    showPopup,
    generate_unique_key,
    is_value_exist_in_array,
} from "../../utils";
import * as enums from "../../utils/enums.js";
import { saveDbFormula, getDbFormulaById } from "../../actions/db.formula";
import { withRouter } from "react-router-dom";
import {
    SQL_FORMULA_TEMPLETE,
    column_proprty_update,
    get_data_into_saved_format,
    get_formula_in_formatted_input,
} from "./shared.fun";
import { PageInnerWrapper } from "../hoc/styled.hoc";
import { NormalButton } from "../hoc/normal.button";
import SaveSvg from "../svg/save.rect";
import { RadioCheckMark } from "../hoc/radiobox";
import SelectOptionV1 from "../select-option/select.option.v1.1";
import CloseSvg from "../svg/close";
import OtherInformationPopup from "./other.information.popup";
import { RowWrapper } from "../editor.shared/editor.styled.js";
import SaveIcon from "../svg/icons-v2/downloadIcon.js";

const Index = (props) => {
    const {
        get_schema_table_info,
        schema_table_info,
        location,
        data,
        close,
        popupKey,
        option,
    } = props;

    const {
        table_names,
        formulaId,
        saveFun,
        hide_db_changer,
        formula_data,
        need_to_validate_table = false,
        need_to_get_schema_info = true,
    } = props?.data || props;

    const IS_DEFAULT_LIVE_DB = isDefaultLiveDb();

    const editedFormula = props?.DbEditedFormula;

    const [db_info_ids, set_db_info_ids] = useState();

    const [localTableColumns, setLocalTableColumns] = useState();

    const [activeColumn, setActiveColumn] = useState(undefined);
    const [activeEditorType, setActiveEditorType] = useState(undefined);
    const [column_ifs_conditions, set_columns_ifs_conditions] = useState();

    const [reporting_db_info_id, set_reporting_db_info_id] = useState(undefined);

    const [formulaTemplate, setFormulaTemplate] = useState({
        name: "",
        formula_type: "nf_conditional",
    });

    const [inner_formula, set_inner_formula] = useState();

    const [grouped_items, set_grouped_items] = useState(undefined);

    const [hints, setHints] = useState();

    const [hint, setHint] = useState("");

    const {
        formulas,
        addFormula,
        updateFormula,
        deleteFormula,
        deleteFormulaRow,
        deleteFormulaBlock,
        setFormulas,
    } = useFormula();

    const { search } = location;

    useEffect(() => {
        formulaId && props.getDbFormulaById(formulaId);
    }, [formulaId]);

    const extractTableNames = (obj, tableNames = new Set()) => {
        if (typeof obj === "object") {
            if (obj?.hasOwnProperty("table_name")) {
                tableNames?.add(obj.table_name);
            }
            for (const key in obj) {
                if (Array.isArray(obj[key])) {
                    for (const item of obj[key]) {
                        extractTableNames(item, tableNames);
                    }
                } else if (typeof obj[key] === "object") {
                    extractTableNames(obj[key], tableNames);
                }
            }
        }
        return Array.from(tableNames);
    };

    useEffect(() => {
        var table_names = (
            (formulas && extractTableNames(formulas, table_names)) ||
            []
        ).filter((a) => a !== null);
        const clone_formulaTemplate = formulaTemplate ? { ...formulaTemplate } : {};
        clone_formulaTemplate["rule_tbl"] =
            table_names && table_names?.length > 0 ? table_names?.join(",") : "";
        setFormulaTemplate(clone_formulaTemplate);
    }, [formulas]);

    useEffect(() => {
        if (formula_data && !formulaId) {
            setFormulaTemplate(formula_data);
            setHints(formula_data?.hints);
            setFormulas(formula_data?.nf_formula_columns);
        } else if (
            formulaId &&
            editedFormula?.id &&
            editedFormula &&
            editedFormula?.nf_formula_columns?.length > 0
        ) {
            const cloned_edited_formula = editedFormula
                ? JSON.parse(JSON.stringify(editedFormula))
                : {};

            if (editedFormula?.db_info_id) {
                if (need_to_get_schema_info) {
                    get_schema_table_info([cloned_edited_formula?.db_info_id]);
                }
                set_reporting_db_info_id(cloned_edited_formula?.db_info_id);
            }

            if (cloned_edited_formula?.grouped_items) {
                set_grouped_items(JSON.parse(cloned_edited_formula.grouped_items));
            }

            delete cloned_edited_formula["nf_formula_columns"];
            const __formated_formula__ =
                get_formula_in_formatted_input(editedFormula);

            setFormulaTemplate(cloned_edited_formula);
            setHints(cloned_edited_formula?.hints);
            setFormulas(__formated_formula__?.main_formulas);
            if (__formated_formula__?.child_formulas) {
                set_columns_ifs_conditions(__formated_formula__?.child_formulas);
            } else {
                set_columns_ifs_conditions(undefined);
            }
        } else {
            set_columns_ifs_conditions(undefined);
            setFormulas({ if: [], else: [], else_ifs: [[]] });
            setFormulaTemplate({
                formula_type: "nf_conditional",
            });
            // setFormulaId(undefined)
        }
    }, [editedFormula, formula_data]);

    useEffect(() => {
        const db_id = getDbInfoId();

        set_db_info_ids([db_id]);

        const db_info_to_use = IS_DEFAULT_LIVE_DB
            ? db_id
            : db_id && db_id + "__nf__db__cached__";

        set_reporting_db_info_id(db_info_to_use);

        if (need_to_get_schema_info) {
            get_schema_table_info([db_info_to_use], db_id);
        }
    }, []);

    useEffect(() => {
        const client_id = getClientId();

        const sorted_schema_table = sort_table_and_column(
            schema_table_info,
            client_id?.["client-id"],
            table_names,
            need_to_validate_table
        );

        setLocalTableColumns(sorted_schema_table);
    }, [schema_table_info]);

    const searchTable = (value) => {
        setLocalTableColumns(search_table_and_column(value, schema_table_info));
    };

    const column_proprty_on_change = (key, value, __id) => {
        const current_column = activeColumn ? { ...activeColumn } : {}; //formulas?.find((f) => f.id === __id);

        if (current_column) {
            const new_info = column_proprty_update(
                props.functions_config,
                activeColumn,
                key,
                value
            );

            console.log("new_info", new_info, __id);
            setActiveColumn(new_info);

            updateFormula(undefined, activeEditorType, new_info, __id);
        }
    };

    const updated_column_ifs_conditions = (column_id, conditions) => {
        const cloned_column_ifs_conditions = column_ifs_conditions
            ? { ...column_ifs_conditions }
            : {};

        if (!cloned_column_ifs_conditions[column_id])
            cloned_column_ifs_conditions[column_id] = {};

        cloned_column_ifs_conditions[column_id] = conditions;

        set_columns_ifs_conditions(cloned_column_ifs_conditions);
    };

    const open_aggregation_if_popup = (column_info) => {
        const table_name = column_info?.table_name;
        const sorted_schema_table = search_table_and_column(
            undefined,
            schema_table_info,
            table_name
        );

        showPopup(
            "Aggregation Condition",
            undefined,
            enums.default.popupType.element_with_header,
            AggregationIfPopup,
            {
                sorted_schema_table: sorted_schema_table,
                column_info: column_info,
                prev_formulas: column_ifs_conditions?.[column_info?.id],
                __save: (conditions) => {
                    updated_column_ifs_conditions(column_info?.id, conditions);
                },
            },

            undefined,
            undefined,
            undefined,
            {
                width: "90vw",
                maxWidth: "90vw",
                maxHeight: "80vh",
                height: "80vh",
            }
        );
    };

    const open_other_information_popup = (column_info) => {
        showPopup(
            "Provide Other Details",
            undefined,
            enums.default.popupType.element_with_header,
            OtherInformationPopup,
            {
                popupData: formulaTemplate || {},
                _saveFun: (__data) => {
                    setFormulaTemplate(__data);
                },
            },

            undefined,
            undefined,
            undefined,
            {
                width: "28rem",
                maxWidth: "28rem",
                maxHeight: "80vh",
                height: "80vh",
            }
        );
    };

    const on_change = (key, value) => {
        const temp_formula_template = formulaTemplate ? { ...formulaTemplate } : {};

        temp_formula_template[key] = value;

        setFormulaTemplate(temp_formula_template);
    };

    const local_save = () => {
        // let's validate the formula template
        var process = true;
        var msg = "";

        const clone_formulaTemplate = formulaTemplate ? { ...formulaTemplate } : {};

        clone_formulaTemplate.hints = hints;

        if (clone_formulaTemplate["formula_type"] === "nf_mathematical") {
            if (formulas?.["nf_mathematical"]?.length == 0) {
                process = false;
                msg = "Please Provide Valid Condition";
            }
        } else {
            if (formulas?.["if"]?.length == 0) {
                process = false;
                msg = "Please Provide Valid If Condition";
            }

            if (formulas?.["if"]?.length > 0) {
                let is_valid_if = formulas?.["if"].some(
                    (v) => v.type === "THEN" || v.type === "Then" || v.type === "then"
                );
                const then_idx = formulas?.["if"].findIndex(
                    (v) => v.type === "THEN" || v.type === "Then" || v.type === "then"
                );
                const then_value = formulas?.["if"]?.[then_idx + 1];

                process = is_valid_if;

                if (!is_valid_if) {
                    msg = "Please Provide Valid If Condition";
                }
                if (!then_value) {
                    process = false;
                    msg = "Please Provide Then Value";
                }

                const mt_expr = formulas?.["if"]?.some((v) => v.expr === "");

                if (mt_expr) {
                    process = false;
                    msg = "Value Are Missing";
                }
            }
            if (formulas?.["else_ifs"]?.length > 0) {
                formulas?.["else_ifs"].map((else_if, index) => {
                    if (else_if?.length > 0) {
                        var is_valid_if = (else_if || []).some(
                            (v) => v.type === "THEN" || v.type === "Then" || v.type === "then"
                        );
                        process = is_valid_if;
                        if (!is_valid_if) {
                            msg = "Please Provide Valid else if Condition"; //+ (index + 1)
                        }
                    }
                });
            }
            if (formulas?.["else"]?.length == 0) {
                process = false;
                msg = "Please Provide Valid ELSE Condition";
            }
        }

        if (!clone_formulaTemplate["name"]) {
            process = false;
            msg = "Please Provide a Formula Name";
        }

        if (process) {
            const saved_data = get_data_into_saved_format(
                clone_formulaTemplate,
                formulas,
                column_ifs_conditions,
                grouped_items
            );

            // console.log("saved_data", saved_data)

            if (saveFun) {
                saveFun(saved_data, reporting_db_info_id);
            } else {
                props.saveDbFormula(saved_data, reporting_db_info_id, props.history);
            }
            if (close) {
                close(option, popupKey);
            }

            setHints(undefined);
        } else {
            alert(msg || "Please Provide a valid formula");
        }
    };

    const _remove_hint = (index) => {
        const clone_hints = hints ? [...hints] : [];
        clone_hints.splice(index, 1);
        setHints(clone_hints);
    };

    return (
        <PageInnerWrapper height={'calc(100vh - 6rem)'} padding={"0px"} style={{ marginTop: ".7rem" }}>
            <FormulaWrapper>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        background: "rgb(239, 244, 252)",
                        // height: "calc(100vh - 12rem)",
                        height: "calc(100vh - 10rem)", // change for formula_builder 
                    }}
                >
                    {/* left side */}
                    <div
                        style={{
                            width: "20%",
                            background: "#fff",
                            boxSizing: "border-box",
                            padding: "1rem 1rem",
                        }}
                        className="formula_scroll"

                    >
                        <SchemaTableWindow
                            connectionId={db_info_ids}
                            localTableColumns={localTableColumns}
                            height={"80vh"}
                            searchTable={searchTable}
                            set_reporting_db_info_id={set_reporting_db_info_id}
                            reporting_db_info_id={reporting_db_info_id}
                            get_schema_table_info={props.get_schema_table_info}
                            hide_db_changer={hide_db_changer}
                            table_names={table_names}
                            show_db_functions={true}
                        />
                    </div>
                    <div
                        style={{
                            width: "80%",
                            height: '100%',
                            background: "#eff4fc",
                            padding: "10px",
                            // boxSizing: "border-box",
                        }}
                    // className="formula_scroll"
                    >
                        <div style={{ marginBottom: "1rem" }}>
                            <NormalButton
                                bgColor="linear-gradient(to right, #3E9EFF, #737DFF)"
                                primary={true}
                                mR=".5rem"
                                onClick={() => {
                                    local_save();
                                }}
                                width="6rem"
                                disabled={props.type === "cache_editor"}
                                icon={<SaveIcon size="1rem" height="1rem" color="#fff" />}
                                label="SAVE"
                            />
                        </div>

                        <div className="formula_scroll" style={{ display: "flex", height: '100%' }}>
                            <div
                                style={{
                                    width: "calc(100% - 320px)",
                                    height: '100%'
                                }}
                            >
                                <RowWrapper>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            title="Conditional Formula"
                                            className="formula_switcher"
                                            style={{ marginRight: "1rem" }}
                                            onClick={() => {
                                                if (formulaId)
                                                    alert("You Can't Switch The Formula Type");
                                                else on_change("formula_type", "nf_conditional");
                                            }}
                                        >
                                            <RadioCheckMark
                                                checked={
                                                    formulaTemplate?.["formula_type"] === "nf_conditional"
                                                }
                                                outerSize="1rem"
                                            />
                                            <span className="l_bl">Conditional</span>
                                        </div>

                                        <div
                                            title="Switch To Mathematical Formula"
                                            className="formula_switcher"
                                            onClick={() => {
                                                if (formulaId)
                                                    alert("You Can't Switch The Formula Type");
                                                else on_change("formula_type", "nf_mathematical");
                                            }}
                                        >
                                            <RadioCheckMark
                                                checked={
                                                    formulaTemplate?.["formula_type"] ===
                                                    "nf_mathematical"
                                                }
                                                outerSize="1rem"
                                            />
                                            <span className="l_bl">Mathematical</span>
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "flex-start",
                                        }}
                                    >
                                        <div style={{ width: "24%" }}>
                                            <TextBoxV1
                                                value={formulaTemplate?.["name"] || ""}
                                                label={"Please Provide Formula Name"}
                                                show_as_placholder={true}
                                                onChange={(e) => {
                                                    on_change("name", e.target.value);
                                                }}
                                                background={"#F3F3F3"}
                                                inputStyle={{
                                                    borderRadius: "6px",
                                                    border: "none",
                                                    paddingLeft: '0.4rem',
                                                }}
                                                lablePostStyle={{
                                                    background: "#f0f5fc",
                                                }}
                                            />
                                        </div>

                                        <div style={{ width: "24%" }}>
                                            <SelectOptionV1
                                                background={"#F3F3F3"}
                                                value={formulaTemplate?.["data_type"] || ""}
                                                label={"Please Choose Data Type"}
                                                label_as_placeholder={true}
                                                // hide_post_label={true}
                                                options={[
                                                    "currency",
                                                    "number",
                                                    "string",
                                                    "date",
                                                    "percent",
                                                ]}
                                                width="100%"
                                                on_change={(value) => {
                                                    console.log("value", value);
                                                    on_change("data_type", value);
                                                }}
                                                inputStyle={{
                                                    borderRadius: "6px",
                                                    border: "none",
                                                    fontSize: "0.7rem",
                                                    paddingLeft: '0.6rem'
                                                }}
                                                lablePreStyle={{
                                                    fontSize: "0.9rem",
                                                }}
                                                none_value={false}
                                            />
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div style={{ width: "100%", marginTop: "15px" }}>
                                                <div
                                                    className="text_box"
                                                    style={{
                                                        padding: hints?.length > 0 ? "0px" : undefined,
                                                        border: "none",
                                                        background: "#F3F3F3",
                                                        borderRadius: "6px",
                                                    }}
                                                >
                                                    {/* { <span
                                                        className={
                                                            hints?.length > 0 || hint?.length > 0
                                                                ? "label_pre label_post"
                                                                : "label_pre dd"
                                                        }
                                                    >
                                                        Add Aliases
                                                    </span>} */}
                                                    {hints &&
                                                        hints.length > 0 &&
                                                        hints.map((hint, index) => {
                                                            return (
                                                                <div
                                                                    key={"hint" + index}
                                                                    className="email_batch"
                                                                >
                                                                    <span>{hint}</span>
                                                                    <span
                                                                        title="Delete"
                                                                        onClick={() => {
                                                                            _remove_hint(index);
                                                                        }}
                                                                        className="remove_btn"
                                                                    >
                                                                        <CloseSvg size=".5rem" height=".5rem" />
                                                                    </span>
                                                                </div>
                                                            );
                                                        })}

                                                    <input
                                                        value={hint}
                                                        type="text"
                                                        placeholder={"Add Aliases"}
                                                        onChange={(e) => {
                                                            console.log("keyCode", e.target.value);
                                                            setHint(e.target.value);
                                                        }}
                                                        autoFocus={true}
                                                        style={{
                                                            padding: "0.2rem 0.6rem",
                                                            width:
                                                                hints && hints.length > 0
                                                                    ? ((hint && hint.length) || 9) * 10 + "px"
                                                                    : hints?.length > 0
                                                                        ? "80px"
                                                                        : undefined,
                                                        }}
                                                        onKeyDown={(event) => {
                                                            const keyCode = event.keyCode;
                                                            const _value = event.target.value;
                                                            const tempHints = hints
                                                                ? JSON.parse(JSON.stringify(hints))
                                                                : [];
                                                            if (
                                                                keyCode === 13 &&
                                                                _value &&
                                                                _value.trim() !== ""
                                                            ) {
                                                                tempHints.push(_value);
                                                                setHint("");
                                                            }
                                                            setHints(tempHints);
                                                        }}
                                                        onBlur={(event) => {
                                                            const _value = event.target.value;
                                                            const tempHints = hints
                                                                ? JSON.parse(JSON.stringify(hints))
                                                                : [];
                                                            if (_value && _value.trim() !== "") {
                                                                tempHints.push(_value);
                                                                setHint("");
                                                            }
                                                            setHints(tempHints);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </RowWrapper>

                                <RowWrapper margin={"1rem 0"}>
                                    {formulaTemplate?.["formula_type"] && (
                                        <BlockElements
                                            addFormula={addFormula}
                                            updateFormula={updateFormula}
                                            deleteFormula={deleteFormula}
                                            deleteFormulaRow={deleteFormulaRow}
                                            onDragOver={(event) => event.preventDefault()}
                                            formulas={formulas}
                                            setActiveColumn={setActiveColumn}
                                            setActiveEditorType={setActiveEditorType}
                                            deleteFormulaBlock={deleteFormulaBlock}
                                            formulaType={formulaTemplate?.["formula_type"]}
                                            activeColumnId={activeColumn?.["id"]}
                                            inner_formula={inner_formula}
                                            set_grouped_items={set_grouped_items}
                                            grouped_items={grouped_items}
                                            updated_inner_formula={(p_id, data) => {
                                                const clone_inner_formula = inner_formula
                                                    ? { ...inner_formula }
                                                    : {};
                                                if (!clone_inner_formula[p_id])
                                                    clone_inner_formula[p_id] = data;
                                                set_inner_formula(inner_formula);
                                            }}
                                        />
                                    )}
                                </RowWrapper>
                            </div>

                            <div
                                style={{
                                    // minHeight: "28rem",
                                    // maxHeight: "28rem",
                                    height: formulaTemplate?.["formula_type"] === "nf_mathematical" ? '28rem' : '35rem',
                                    width: "300px",
                                    background: "#fff",
                                    marginLeft: "1rem",
                                    position: "sticky",
                                    top: '0rem',
                                }}
                            >
                                <ColumnEditor
                                    activeColumn={activeColumn}
                                    column_proprty_on_change={column_proprty_on_change}
                                    open_aggregation_if_popup={open_aggregation_if_popup}
                                    column_ifs_conditions={
                                        activeColumn && column_ifs_conditions?.[activeColumn?.id]
                                    }
                                    setActiveColumn={setActiveColumn}
                                    height={"20rem"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </FormulaWrapper>
        </PageInnerWrapper>
    );
};

const mapStateToProps = (state, props) => ({
    schema_table_info: state.reportBuilder.schema_table_info,
    DbEditedFormula: state.dbFormula.editedFormula,
    functions_config: state.reportReducer.functions_config,
});

export default withRouter(
    connect(mapStateToProps, {
        saveDbFormula,
        get_schema_table_info,
        getDbFormulaById,
    })(Index)
);
