import * as actions from '../action-types/table';
import { constants } from '../utils/constants';

import { splitTableData } from '../utils';

const initialState = {
    type: actions.TABLE_INIT,
    previousIndex: 0,
    currentIndex: constants.TABLE_LIMIT,
    changeIndexType: undefined,
    columnsData: [],
    editedColumns: [],
    additionalColumns: [],
    data: undefined,
    fullData: undefined,
    additionalHeaders: undefined,
    showPagination: false,
    disableCurrent: false,
    totalPages: undefined,
    currentPage: undefined,
    parentWindowToScrollTo: -1,
    sort_orders: {},
    width_additions_cache: {},
    download_excel_cache: undefined,

};


const tableReducer = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }


    switch (action.type) {
        case actions.SET_DATA_SUCCESS:
            return {
                ...state,
                type: action.type,
                data: action.data
            };

        case actions.SCROLL_PARENT_WINDOW_TO:
            return {
                ...state,
                type: action.type,
                parentWindowToScrollTo: action.data
            };

        case actions.RAISE_EXCEL_DOWNLOAD_REQUEST:
            return {
                ...state,
                type: action.type,
                download_excel_cache: action?.data?.id
            }



        case actions.SET_TABLE_STATE_SUCCESS:

            const data = action.data.data;
            const id = action.data.id;
            const columns = action.data.columns;
            const editedColumns = action.data.editedColumns;
            const updated_at = action.data.updated_at

            const additionalHeaders = action.data.additionalHeaders;
            const saveObject = {};
            const previousStateData = state.data ? JSON.parse(JSON.stringify(state.data)) : {};

            if (data && data.length > 0 && data.length > constants.TABLE_LIMIT) {
                const returnValue = splitTableData(undefined, data);
                saveObject.activeData = returnValue.data;
                saveObject.extraData = returnValue.extraData;
                saveObject.currentIndex = 1;
                saveObject.totalData = data
                saveObject.showPagination = true;
                saveObject.startDataIndex = 0;
                saveObject.endDataIndex = constants.TABLE_LIMIT;
                saveObject.totalPages = Math.ceil(data.length / constants.TABLE_LIMIT);

            }
            else {
                saveObject.activeData = data;
                saveObject.extraData = undefined;
                saveObject.totalData = data;
                saveObject.currentIndex = undefined;
                saveObject.showPagination = false;



            }

            saveObject.columns = columns;
            saveObject.editedColumns = editedColumns;
            saveObject.additionalHeaders = additionalHeaders;
            saveObject.last_updated_at = updated_at

            previousStateData[id] = saveObject;
            // console.log("updated_at", previousStateData)


            return {
                ...state,
                type: action.type,
                data: previousStateData,
            };



        case actions.UPDATE_SORT_ORDERS:

            const clone_prev_sort_orders = state.sort_orders ? JSON.parse(JSON.stringify(state.sort_orders)) : {};

            if (!clone_prev_sort_orders[action.data.id]) clone_prev_sort_orders[action.data.id] = [];
            clone_prev_sort_orders[action.data.id] = action.data.sort_orders;

            return {
                ...state,
                type: action.type,
                sort_orders: clone_prev_sort_orders
            }

        case actions.UPDATE_ACTIVE_TABLE_COLUMN:
            const payload = action.data;
            const _id_of_table_ = payload.id;
            const prev_clone_data = state.data ? JSON.parse(JSON.stringify(state.data)) : {};

            if (prev_clone_data && prev_clone_data[_id_of_table_]) {
                prev_clone_data[_id_of_table_]['editedColumns'] = payload.editedColumns
            }

            return {
                ...state,
                type: action.type,
                data: prev_clone_data,
            }
        case actions.SET_COLUMNS_SUCCESS:
            return {
                ...state,
                type: action.type,
                columnsData: action.data
            };




        case actions.NEXT_DATA_SUCCESS:

            const tempFullTableData = JSON.parse(JSON.stringify(state.data));
            const _id = action.data.id;
            const currentObject = tempFullTableData[_id] || {};
            const tempFullData = currentObject?.totalData;

            let tempCurrentIndex = currentObject?.currentIndex + 1;

            let tempNextDataIndex = parseInt(tempCurrentIndex) * constants.TABLE_LIMIT
            let tempDataCurrentIndex = parseInt((currentObject.currentIndex ? currentObject.currentIndex : 0)) * constants.TABLE_LIMIT
            const tempArray = tempFullData.slice(tempDataCurrentIndex, tempNextDataIndex);

            currentObject.startDataIndex = tempDataCurrentIndex;
            currentObject.endDataIndex = tempNextDataIndex;
            currentObject.activeData = tempArray;
            currentObject.currentIndex = tempCurrentIndex;
            tempFullTableData[_id] = currentObject;

            return {
                ...state,
                type: action.type,
                data: tempFullTableData,
            };



        case actions.PREV_DATA_SUCCESS:
            const tempFullTableDataForPrev = JSON.parse(JSON.stringify(state.data));

            const _idForPrev = action.data.id;
            const currentObjectForPrev = tempFullTableDataForPrev[_idForPrev]
            const tempFullDataForPrev = currentObjectForPrev.totalData;

            let tempCurrentIndexForPrev = (currentObjectForPrev.currentIndex - 1);

            let currentDataIndex = tempCurrentIndexForPrev * constants.TABLE_LIMIT
            let tempDataCurrentIndexForPrev = currentDataIndex - constants.TABLE_LIMIT

            const tempArrayForPrev = tempFullDataForPrev.slice(tempDataCurrentIndexForPrev, currentDataIndex);

            currentObjectForPrev.startDataIndex = tempDataCurrentIndexForPrev;
            currentObjectForPrev.endDataIndex = currentDataIndex
            currentObjectForPrev.activeData = tempArrayForPrev;
            currentObjectForPrev.currentIndex = tempCurrentIndexForPrev;
            tempFullTableDataForPrev[_idForPrev] = currentObjectForPrev;

            return {
                ...state,
                type: action.type,
                data: tempFullTableDataForPrev
            };


        case actions.SET_EDITED_COLUMNS_SUCCESS:

            return {
                ...state,
                type: action.type,
                editedColumns: action.data
            };

        case actions.CLEAR_TABLE_SUCCESS:

            return {
                ...state,
                type: action.type,
                editedColumns: undefined,
                data: undefined,
                columnsData: undefined,
                additionalHeaders: undefined
            };

        case actions.SET_BOTH_COLUMNS_SUCCESS:

            const tempEditedColumns = action.data.editedColumns;
            const tempId = action.data.id;
            const previousData = JSON.parse(JSON.stringify(state.data))
            previousData[tempId]['editedColumns'] = tempEditedColumns;

            return {
                ...state,
                type: action.type,
                data: previousData
            };

        case actions.LOAD_MORE_DATA_SUCCESS:
            const reducerState = Object.assign({}, state.data);
            const calledData = reducerState[action.data];

            let activeData = calledData['activeData'];
            let tempExtraData = calledData['extraData'];

            if (tempExtraData && tempExtraData.length > 0) {
                const spliceData = tempExtraData.splice(0, constants.TABLE_LIMIT);

                activeData = [...activeData, ...spliceData];
            }

            calledData.activeData = activeData;
            calledData.extraData = tempExtraData;

            reducerState[action.data] = calledData;

            return {
                ...state,
                type: action.type,
                data: reducerState
            };

        case actions.SET_SORTED_DATA_SUCCESS:
            const temporaryData = Object.assign({}, state.data);
            const newData = action.data;
            const idToChange = newData['id'];

            const dataToChange = temporaryData[idToChange];
            dataToChange['sortedData'] = newData.data;
            dataToChange['sortType'] = newData.sortType;
            dataToChange['sortBy'] = newData.sortBy;

            temporaryData[idToChange] = dataToChange;

            return {
                ...state,
                type: action.type,
                data: temporaryData
            };

        case actions.PUSH_SORT_DATA_TO_REDUCERS:

            const newSortData = action.data;
            const dataId = newSortData['id'];
            const sorted_data = newSortData.data;
            const prev_table_data_cache = state.data ? JSON.parse(JSON.stringify(state.data)) : {};
            const prev_table_data = prev_table_data_cache && prev_table_data_cache[dataId];

            if (sorted_data && sorted_data.length > 0 && sorted_data.length > constants.TABLE_LIMIT) {

                const active_data = sorted_data ? [...sorted_data] : [];

                // const returnValue = splitTableData(undefined, sorted_data);
                prev_table_data.activeData = active_data.splice(prev_table_data.startDataIndex)
                // prev_table_data.extraData = returnValue.extraData;
                prev_table_data.totalData = sorted_data
                prev_table_data.showPagination = true;
                prev_table_data.totalPages = Math.ceil(sorted_data.length / constants.TABLE_LIMIT);

            } else {
                prev_table_data.activeData = sorted_data;
                prev_table_data.totalData = sorted_data

            }
            prev_table_data_cache[dataId] = prev_table_data;

            return {
                ...state,
                type: action.type,
                data: prev_table_data_cache
            }


        case actions.UPDATE_WIDTH_ADDITIONS:

            const p_data = action.data;
            const clone_width_width_additions_cache = state.width_additions_cache ? JSON.parse(JSON.stringify(state.width_additions_cache)) : {};

            if (p_data?.id && !clone_width_width_additions_cache[p_data?.id]) clone_width_width_additions_cache[p_data?.id] = {};

            if (p_data?.id) {
                clone_width_width_additions_cache[p_data?.id] = p_data?.width_additions
            }

            return {
                ...state,
                type: action.type,
                width_additions_cache: clone_width_width_additions_cache

            }

        case actions.UPDATE_COLUMN_ORDER:
            const column_p_data = action?.data;

            const clone_columns_order_cache = state.columns_order_cache ? JSON.parse(JSON.stringify(state.columns_order_cache)) : {};

            if (column_p_data?.id && !clone_columns_order_cache[column_p_data?.id]) clone_columns_order_cache[column_p_data?.id] = {};

            if (column_p_data?.id) {
                clone_columns_order_cache[column_p_data?.id] = column_p_data?.columns_order;
            }

            return {
                ...state,
                type: action.type,

            }


        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
            return {
                type: state.type,
                previousIndex: state.previousIndex,
                currentIndex: state.currentIndex,
                changeIndexType: state.changeIndexType,
                data: state.data,
                columnsData: state.columnsData,
                editedColumns: state.editedColumns,
                additionalColumns: state.additionalColumns,
                fullData: state.fullData,
                additionalHeaders: state.additionalHeaders,
                showPagination: state.showPagination,
                disableCurrent: state.disableCurrent,
                currentPage: state.currentPage,
                totalPages: state.totalPages,
                sort_orders: state.sort_orders,
                width_additions_cache: state.width_additions_cache
            };
    }
};

export default tableReducer;