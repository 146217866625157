import React, { useState } from 'react';
import { DivRow } from '../styled-hoc';
import {Button} from '../hoc/button';
import {Gap} from '../gap/'
import styled from 'styled-components';
import { connect } from 'react-redux';
import {save_outliner, getOutliearData}  from '../../actions/new-outlier'
import TextBox from '../hoc/textBox';


export const Wrapper = styled.div`
    position: relative;
    width: 30rem;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 6px 0px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(234, 234, 234);
    border-image: initial;
    padding: 1.643rem 1.429rem;
    overflow-y: auto;
    max-height: 80vh;

    &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }
    .title{
        font-size: 1rem;
        margin-bottom: 5px;
    }
`;

export const TitleWrapper = styled.div`
    font-size: 1.286rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.714rem;
    letter-spacing: normal;
    text-align: center;
    color: rgb(26, 26, 26);
    display: block;
    margin: 0px;
`;


export const CloseWrapper = styled.div`
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 7px;
`;

const AddOutlierForm = (props) => {


    const { close, popupKey, option} = props;

  const [inputData, setInputData] = useState({
    patient_id: '',
    Pat_temp: '',
    Pat_email: '',
    Pat_country: '',
    Organization: ''
  })

  const onTextChange = (e, key) => {
    let value = e.target.value;
    setInputData({ ...inputData, [key]: value }); 
  }


 const  _save_outliner = () => {

    let data = {
        patient_id: inputData.patient_id,
        Pat_temp: inputData.Pat_temp,
        Pat_email: inputData.Pat_email,
        Pat_country: inputData.Pat_country,
        Organization: inputData.Organization,
        is_active: 1,
        is_modified: true
    }

    props.save_outliner(data);

    setTimeout(() => {
       props.getOutliearData()
    }, 1000)
 }

    return (
        <Wrapper>
            <TitleWrapper>
                Add New Outlier
                
            </TitleWrapper>
            <Gap h="1.3rem" />
            <div
                style={{
                    padding: '10px'
                }}
            >
                   <DivRow w="100%" style={{marginBottom: '1rem'}}>
                        <TextBox 
                            label="Patient Id"
                            type="text"
                            value={inputData.patient_id} 
                            onChange={(e) => onTextChange(e, 'patient_id') }
                        />
                    </DivRow>
                   <DivRow w="100%" style={{marginBottom: '1rem'}}>
                        <TextBox 
                            label="Patient Temp"
                            type="text"
                            value={inputData.Pat_temp} 
                            onChange={(e) => onTextChange(e, 'Pat_temp') }
                        />
                    </DivRow>

                    <DivRow w="100%" style={{marginBottom: '1rem'}}>
                        <TextBox 
                            label="Patient Email"
                            type="email"
                            value={inputData.Pat_email} 
                            onChange={(e) => onTextChange(e, 'Pat_email')}
                        />
                    </DivRow>

                    <DivRow w="100%" style={{marginBottom: '1rem'}}>
                        <TextBox 
                            label="Patient Country"
                            type="text"
                            value={inputData.Pat_country} 
                            onChange={(e) => onTextChange(e, 'Pat_country') }
                        />
                    </DivRow>

                    <DivRow w="100%" style={{marginBottom: '1rem'}}>
                        <TextBox 
                            label="Organization"
                            type="text"
                            value={inputData.Organization} 
                            onChange={(e) => onTextChange(e, 'Organization') }
                        />
                    </DivRow>


                <Gap h="20px" />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        width: '100%'
                    }}
                >
                    <Button
                         primary={true}
                        width="6rem"
                        mR="1.143rem"
                        onClick={() => {
                            _save_outliner()
                            close(option, popupKey);
                        }}
                    >
                        Save
                    </Button>

                    <Button
                        width="6rem"
                        bgColor={'#e6e6e6'}
                        onClick={() => {
                            close(option, popupKey);
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </Wrapper>
    );
};



const mapStateToProps =(state) => ({
})

export default connect(mapStateToProps, {save_outliner,getOutliearData}) (AddOutlierForm);