import React from 'react';

const Revenue = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}

            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 2048 2048"
            space="preserve">

            <path transform="translate(202,178)" d="m0 0h41l73 1 5 2v26h1518l3 1v10l-2 1h-1465l-53-1v301h1097l2-10 4-10 9-13 11-9 12-6 10-2h12l14 3 12 6 10 9 7 10 5 11 3 10 299 2 13 1 2 5-2 3-43 1h-270l-1 9-3 9-9 12-9 9-8 5-15 5-7 1h-9l-15-4-12-6-9-8-9-15-5-16h-265l-831-1v302l43-1h552l4-1 6-16 8-12 8-9 14-9 10-3h21l12 3 11 6 8 7 6 9 4 9 3 11v5h143l5-16 6-12 11-12 15-8 7-2h23l13 4 11 7 7 7 7 11 6 14 1 7h499l55 1 1 1v9l-2 1-553 1-5 13-8 12-9 10-5 4-8 4-12 3h-18l-13-3-11-6-9-9-7-10-6-17v-3l-4 1-139 1-3 12-6 11-9 10-8 7-10 4-10 2h-21l-13-4-11-7-8-9-6-10-6-15v-2h-594l-6-1v270l-1 29h83l143-1 3-3 4-12 5-9 8-10 9-7 10-4 5-1h28l10 3 9 5 8 7 7 11 5 11 2 9h164l1-5 9-17 8-10 9-8 8-4 8-2h27l10 3 10 6 12 12 7 14 3 10 1 1h36l18 1 4-1 3-9 7-14 8-10 7-6 12-6 6-1h26l11 3 10 5 10 10 7 12 6 16h732l1 2v10l-1 2-689 1-38-1-4-3-3 9-8 16-7 10-10 8-11 5-14 3h-12l-13-3-11-6-12-11-8-13-8-19-3 3-1 2-43-1-8-2-3 8-5 14-7 10-7 7-16 8-13 3h-12l-11-2-14-7-10-9-6-8-10-21-2-2-4-1v3l-56-1h-78l-22-1-1 5-4 10-10 15-10 10-14 7-12 2h-13l-13-3-10-5-10-9-6-8-6-12-4-11v-2l-3 2h-203l-20-1v234l-1 66h130l1-19 4-13 6-11 6-7 14-9 13-5 7-1 16 1 14 5 10 7 7 7 8 13 4 13 1 16-1 2 130 1-7-21v-18l5-14 7-11 11-9 11-5 8-2h14l12 3 12 6 10 10 7 12 4 11v15l-4 12-5 10 34 1h1029l3 1 1 6-2 4-2 1h-1074l-8 6-4 1h-34l-9-2-7-4-5-1h-84l-56-1-2 5-7 10-9 10-8 5-13 3-16 1-16-2-14-7-10-10-8-11-2-2-126-1-5-1v247l-1 34 205-1h1236l79 1 1 1v31l-1 87-1 1-1530 1h-106l-4-2-1-11v-1670l1-4z" fill="#2F79FE" />
            <path transform="translate(867,1094)" d="m0 0h27l10 3 10 6 12 12 7 14 3 10 1 1h36l18 1 4-1 3-9 7-14 8-10 7-6 12-6 6-1h26l11 3 10 5 10 10 7 12 6 16h732l1 2v10l-1 2-689 1-38-1-4-3-3 9-8 16-7 10-10 8-11 5-14 3h-12l-13-3-11-6-12-11-8-13-8-19-3 3-1 2-43-1-8-2-3 8-5 14-7 10-7 7-16 8-13 3h-12l-11-2-14-7-10-9-6-8-10-21-2-2-4-1v3l-56-1h-78l-22-1 1-3 4 1h45l84-1 2-3 11-1v-4l-144-1v-1h160l1-5 9-17 8-10 9-8 8-4z" fill="#357EFE" />
            <path transform="translate(4)" d="m0 0h2022l4 2v-2h4v2038l-6-2-52-3-30-1-99-1-36-1v-1h64l102 1 51 2-1-159v-1763l1-70v-34h-2e3l-25-1-4-2z" fill="#7E7984" />
            <path transform="translate(1467,469)" d="m0 0h12l14 3 12 6 10 9 7 10 5 11 3 10 299 2 13 1 2 5-2 3-43 1h-270l-1 9-3 9-9 12-9 9-8 5-15 5-7 1h-9l-15-4-12-6-9-8-9-15-5-16h-265l-693-1v-1l36-1 6-1-6-1v-1l-50-1-13-2-109-1v-1l53-1h1042l2-10 4-10 9-13 11-9 12-6z" fill="#2F79FE" />
            <path transform="translate(1526,207)" d="m0 0h313l3 1v10l-2 1h-1465l-13-2-17-1-10-4-1-2-6-1v-1z" fill="#126CFE" />
            <path transform="translate(2,7)" d="m0 0 4 2 1 2024 2 1v2h-9v-1944l1-83z" fill="#B0AEB7" />
            <path transform="translate(867,1094)" d="m0 0h27l10 3 10 6 12 12 7 14 3 10 1 1h36l18 1 4-1 3-9 3-7h6l1 5 5 1 11-3h5l-5 10 2 9 1 5-4 4-7 1-3 6h-2l-4-4v2h-2l-2 10 2 1-2 3-7-14-4-10-3 3-1 2-43-1-8-2-3 8-5 14-7 10-7 7-16 8-13 3h-12l-11-2-14-7-10-9-6-8-10-21-2-2-4-1v3l-56-1h-78l-22-1 1-3 4 1h45l84-1 2-3 11-1v-4l-144-1v-1h160l1-5 9-17 8-10 9-8 8-4z" fill="#2F79FE" />
            <path transform="translate(1837,1453)" d="m0 0 5 1 1 6-2 4-2 1h-402l-660-1-2-1 1-4 4-4 2-1z" fill="#3079FE" />
            <path transform="translate(421,1525)" d="m0 0h20l12 3 14 7 9 8 7 11 5 12 2 15-1 13-5 14-9 13-7 7-7 5-13 4-17 2-14-1-13-4-10-7-7-7-8-12-5-13-1-6v-15l5-16 6-10 9-10 10-7 9-4z" fill="#2F79FE" />
            <path transform="translate(1421,512)" d="m0 0 1 3h2l-1 8-2 15-2-3-1-4h-265l-693-1v-1l36-1 6-1-6-1v-1l-50-1-13-2-109-1v-1l53-1h1042l1-6z" fill="#408BFE" />
            <path transform="translate(641,1232)" d="m0 0h26l12 4 11 7 8 9 6 12 3 10 1 15-3 16-7 14-8 9-10 6-11 5-9 2h-12l-13-3-14-7-9-8-7-11-6-14-2-10 1-12 5-14 6-11 7-8 11-7 9-3z" fill="#3079FE" />
            <path transform="translate(819,1232)" d="m0 0h23l12 3 11 6 9 9 8 13 4 11 1 6v13l-4 13-6 11-9 10-8 6-13 6-10 2h-13l-11-3-14-7-10-9-6-8-4-9-3-13v-13l4-15 6-10 9-10 9-7 11-4z" fill="#2F79FE" />
            <path transform="translate(1463,272)" d="m0 0h19l12 3 12 6 10 9 6 8 4 9 2 11v20l-3 11-6 10-9 10-8 6-13 5-6 1h-18l-12-3-10-5-10-9-6-8-6-13-2-6-1-9 2-14 5-13 10-13 10-8 14-7z" fill="#3079FE" />
            <path transform="translate(726,1373)" d="m0 0h14l12 3 12 6 10 10 7 12 4 11v15l-4 12-5 10 34 1v1l-28 1-4 2-3 6 661 1v1h-671l-8 6-4 1h-34l-9-2-7-4-5-1h-84v-1l54-1 7-1 5-4v-4l4-1h7l-7-21v-18l5-14 7-11 11-9 11-5z" fill="#6FA6FE" />
            <path transform="translate(1094,939)" d="m0 0h17l13 3 13 7 9 9 7 11 3 9 1 7v13l-3 12-5 10-8 10-9 8-16 6-10 2-13-1-12-4-10-6-8-7-7-10-5-13-1-5v-16l4-15 9-14 9-8 10-5z" fill="#6FA6FE" />
            <path transform="translate(1038,1094)" d="m0 0h26l11 3 10 5 10 10 7 12 6 16 1 4 32 3 4 1v2l14 2v2h17v1h-25l-38-1-4-3-3 9-8 16-7 10-10 8-11 5-14 3h-12l-13-3-11-6-12-11-4-6v-5l-1-5 2-6h2v-2l4 2v2h2l2-6 6-2h2l2-3h2l-4-11 1-5 4-7-6 1-11 2-3-2-1-5-5 1 2-6 8-11 8-7 12-6z" fill="#2F79FE" />
            <path transform="translate(1237,570)" d="m0 0h18l13 3 11 6 9 9 7 12 3 11v17l-2 9-8 15-8 8-16 8-10 2h-15l-12-3-10-6-8-7-7-10-4-10-2-12v-7l4-13 4-8 8-11 11-8 9-4z" fill="#6FA6FE" />
            <path transform="translate(1634,355)" d="m0 0h18l13 4 12 7 8 9 6 12 3 14v9l-2 11-6 12-9 10-9 7-13 4-8 1h-11l-16-4-11-7-8-9-7-14-2-9v-13l4-14 6-10 7-8 12-8z" fill="#6FA6FE" />
            <path transform="translate(13)" d="m0 0h1854l140 2h13l1 3h-95l-1917-1 1-3z" fill="#5E5765" />
            <path transform="translate(1524,503)" d="m0 0 2 2 4 13 299 2 13 1 2 5-2 3-43 1h-270l-1 9-3 9-1-4 3-14-4-7 1-5 1-2z" fill="#408BFE" />
            <path transform="translate(4)" d="m0 0 6 1v2l1916 1h103l-1 2h-2e3l-25-1-4-2z" fill="#D3D2D6" />
            <path transform="translate(1e3 1124)" d="m0 0h6l1 5 5 1 11-3h5l-5 10 2 9 1 5-4 4-7 1-3 6h-2l-4-4v2h-2l-2 10 2 1-2 3-7-14-4-10-3 3-1 2-43-1-8-2-1-1-1-12 2 1h36l18 1 4-1 3-9z" fill="#3F8AFE" />
            <path transform="translate(416,832)" d="m0 0h128l28 4v2l-29 3-25 1h-117v-1l15-1-1-5z" fill="#0A67FF" />
            <path transform="translate(343,1142)" d="m0 0h124l1 3-6 2v3h-2v3l-42 1h-43l-10-5-1 3-9-2-4-1v-2l-6-2-2-1z" fill="#0A67FF" />
            <path transform="translate(869,214)" d="m0 0h144l77 1 50 1 31 1v1h-379v-1l30-2z" fill="#2F79FE" />
            <path transform="translate(2,7)" d="m0 0 4 2v39l-1 24-1 142-1 2-1 47h-1v-254z" fill="#4C4453" />
            <path transform="translate(831,1232)" d="m0 0h11l12 3 11 6 9 9 8 13 4 11 1 6v13l-4 13-4 8h-1l-1-5v-21l-5-14-1-10-3-4v-10l-5-2-11-7-6-3-3-3-12-2z" fill="#3F8AFF" />
            <path transform="translate(928,1455)" d="m0 0h166v1l-97 4-31 1-5 1h-29l-1-1-28-3 1-2z" fill="#0A67FF" />
            <path transform="translate(664,1140)" d="m0 0h156l-1 2h-2l1 3-5 4h-2v2h11v3l-5-2v3l-56-1h-78l-22-1 1-3 4 1h45l84-1 2-3 11-1v-4l-144-1z" fill="#5094FF" />
            <path transform="translate(1196,1142)" d="m0 0h116v1l-17 2-31 2-6 1h-47l-15-3z" fill="#0A67FF" />
            <path transform="translate(1,1834)" d="m0 0h1l1 47 1 12 1 127-2 3-1-5v-24l-1-25z" fill="#4C4453" />
            <path transform="translate(1378,832)" d="m0 0h55v2h-2v2h19l3 2-9 1h-31l-9 1h-11l-14-4z" fill="#0A67FE" />
            <path transform="translate(670,834)" d="m0 0h9l16 2v2l-2 1-21 2h-76l2-2 24-1 8-1 4-1 14 1 2-1h18z" fill="#0A67FF" />
            <path transform="translate(1108,1141)" d="m0 0 1 3 32 3 4 1v2l14 2v2h17v1h-25l-38-1-5-3z" fill="#458EFF" />
            <path transform="translate(661,1150)" d="m0 0 5 1h156v3l-5-2v3l-56-1h-78l-22-1z" fill="#6FA6FF" />
            <path transform="translate(4,1964)" d="m0 0h1l1 26 1 43 2 1v2h-9v-67h2l1 26v23l1 2z" fill="#B3B1B9" />
            <path transform="translate(1868)" d="m0 0h158l1 2h-3v2l-4-1-86-1-66-1z" fill="#9794A1" />
            <path transform="translate(2,7)" d="m0 0 4 2v39h-1v-7h-2l-1 46h-1v-78z" fill="#9996A3" />
            <path transform="translate(680,1454)" d="m0 0h8l3 4 1 5h-5v2h-73v-1l54-1 7-1 5-4z" fill="#4E93FF" />
            <path transform="translate(1299,839)" d="m0 0h8l5 2 114 1v1h-135l3-2z" fill="#3E89FF" />
            <path transform="translate(1946,2031)" d="m0 0h62l19 1 3 3 3 2-5-1-52-3-30-1z" fill="#7B7683" />
            <path transform="translate(1708,1455)" d="m0 0h60v2l-11 1h-51l-13-1v-1z" fill="#0A67FF" />
            <path transform="translate(782,1454)" d="m0 0h9l-4 9 4 1h-14l-2-1 1-4 4-4z" fill="#3B88FF" />
            <path transform="translate(776,1279)" d="m0 0 2 4 2 5h1l2 18-2 4-4-12-1-6z" fill="#468EFF" />
            <path transform="translate(1824,1145)" d="m0 0h11v3l-5 1h-26l-2-2h12z" fill="#3B88FF" />
            <path transform="translate(695,1250)" d="m0 0 5 5 3 9-6 1-3-6v-8z" fill="#408BFF" />
            <path transform="translate(4)" d="m0 0 6 1v2l53 1v1h-56l-4-2z" fill="#E1E1E2" />
            <path transform="translate(923,1113)" d="m0 0 5 5 6 14v2l-4-2-6-12z" fill="#438DFF" />
            <path transform="translate(1,2018)" d="m0 0h2l2 2 1 11 3 3v2h-9l2-7z" fill="#9A97A3" />
            <path transform="translate(831,1124)" d="m0 0h1v11l-2 3h-4l-1 4-5-1 4-1 1-5z" fill="#418BFF" />
            <path transform="translate(1984)" d="m0 0h42l1 2h-3v2l-4-1h-15l-22-1z" fill="#9794A1" />
            <path transform="translate(676,1148)" d="m0 0 16 2 19 1v1h-35v-3z" fill="#3B88FF" />
            <path transform="translate(1101,1124)" d="m0 0 2 3 3 10h-5l-3-3 1-7h2z" fill="#418BFF" />
            <path transform="translate(826,1156)" d="m0 0 4 1 2 9h-4l-3-6z" fill="#408BFF" />
            <path transform="translate(938,1142)" d="m0 0h2v2h2l1 4-2 3-4-1v-7z" fill="#307AFE" />
            <path transform="translate(975,1149)" d="m0 0 5 1 8 3v2h-13l4-2z" fill="#6FA6FF" />
            <path transform="translate(0,2007)" d="m0 0h1v17l2 1-1 7-2 2z" fill="#E4E4E4" />
            <path transform="translate(2016)" d="m0 0h10l1 2h-3v2l-10-2z" fill="#A3A1AC" />
        </svg>

    )

}


export default Revenue;

