import React from 'react'
import styled from 'styled-components';


const Wrapper = styled.div`
    position: relative;
    padding: 15px 0 0;
    margin-top: 10px;
    width: 100%;


  .form {
      width: 100%;
      border: 0;
      border-bottom: 1px solid #d2d2d2;
      outline: 0;
      font-size: 16px;
      color: #212121;
      padding: 7px 0;
      background: transparent;
      transition: border-color 0.2s;

      &::-webkit-scrollbar {
          background-color: #3F3F3F;
          width:4px;
      }

      &::-webkit-scrollbar-track {
          background-color: #F3F3F3;
          border: none;
      }

      &::-webkit-scrollbar-thumb {
          background-color: #dbdbdb;
          border-radius: 10px;
          height: 4px;
      }
      &::-webkit-scrollbar:horizontal{
        background-color:  #3F3F3F;
        height: 4px;
      }
    
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
    font-size: 1rem;
    cursor: text;
    top: 20px;
}

label,
.form__field:focus ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: .8rem;
    color: #9b9b9b;
}

.form__field:focus ~ .form__label {
  color: #008AC7;
}

.form__field:focus {
  padding-bottom: 6px;
  /* border-bottom: 2px solid #008AC7; */
}


`;


const TextArea = (props) => {
  const { label, value, onChange, label_as_placeholder } = props;
  return (
    <Wrapper >
      <textarea id="message" className={ label_as_placeholder ? 'form' : "form"} placeholder={label} rows="6" value={value}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          }
        }}></textarea>
      { !label_as_placeholder && <label for="message" className="form__label">{label}</label> }
    </Wrapper>
  )
}

export default TextArea;
