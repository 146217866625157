import React from "react";
import styled from "styled-components";
import { showPopup } from "../../../utils";
import * as enums from "../../../utils/enums";
import tableDataPopup from "../../table.data.popup";
import View from "../../svg/view";


const Container = styled.div`
  width: 100%;
`;

const TableMainDiv = styled.div`
  width: 100%;
  margin-bottom: 40px;
  .tbl_body {
    overflow: auto;
    height: 13.5rem;
    &::-webkit-scrollbar {
      background-color: #3f3f3f;
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: #f3f3f3;
      border: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #dbdbdb;
      border-radius: 10px;
      height: 4px;
    }
    &::-webkit-scrollbar:horizontal {
      background-color: #3f3f3f;
      height: 4px;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
  }
`;

const RowDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  border:  "1px solid #eaeaea";
  border-top: none;
  box-sizing: border-box;
  justify-content: ${(props) => props.justify || undefined};
  cursor: pointer;
  &:nth-child(odd) {
      background-color: #f6f8fe;
  }
  &:hover {
    .remove_column {
      opacity: 1;
    }
  }
  .tbl_after_bdr::after{
    content: '';
    position: absolute;
    background-color: #ccc;
    width: 1px;
    height: 16px;
    margin-left: 2.2rem;
  }

  .table_name {
    height: 2.214rem;
    color: #fff;
    background: #24a8d6;
    font-size: 1.286rem;
    text-transform: capitalize;
    display: flex;
    align-items: center;
  }
`;

const TdDiv = styled.div`
  font-size: 0.9em;
  border: 0px;
  border-collapse: collapse;
  box-sizing: border-box;
  padding: 10px 10px 10px 10px;
  width: ${(props) => props.width || "12%"};
  text-align: ${(props) => props.align || "left"};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;

`;

const CombinationTable = (props) => {
  const { db_info_id, table_column_data } = props;

  const rowWidth = {
    viewIcon: "10%",
    tableName: "45%",
    columnName: "45%",
  };

  const view_table_data = (table_name) => {
    showPopup(
      undefined,
      undefined,
      enums.default.popupType.element,
      tableDataPopup,
      {
        tableName: table_name,
        db_info_id: db_info_id,
      }
    );
  };


  return (
    <Container>
      <TableMainDiv>
        <RowDiv
          style={{
            background: "linear-gradient(to right, #3E9EFF, #737DFF)",
            borderRadius: "6px",
            padding: "0.3rem 0",
          }}

        >
          <TdDiv width={rowWidth.viewIcon} style={{ color: "#fff" }}></TdDiv>
          <TdDiv width={rowWidth?.tableName} className="tbl_after_bdr" style={{ color: "#fff" }}>
            Table Name
          </TdDiv>
          <TdDiv width={rowWidth?.columnName} className="tbl_after_bdr" style={{ color: "#fff" }}>
            Column Name
          </TdDiv>
          <TdDiv width={rowWidth?.columnName} style={{ color: "#fff" }}>
            Context
          </TdDiv>
        </RowDiv>
        <div className="tbl_body">
          {table_column_data?.length > 0 &&
            table_column_data?.map((table, row_index) => {
              return (
                <RowDiv key={row_index}>
                  <TdDiv width={rowWidth.viewIcon}>
                    <span className="tbl_name" onClick={(e) => view_table_data(table?.tableName)}>
                      <View height={"1rem"} size={"1rem"} color={"grey"} />
                    </span>
                  </TdDiv>
                  <TdDiv
                    width={rowWidth?.tableName}
                  >
                    <div title={table?.tableName} >
                      {table?.tableName}
                    </div>
                  </TdDiv>
                  <TdDiv title={table?.columnName} width={rowWidth?.columnName}>
                    {table?.columnName}
                  </TdDiv>
                  <TdDiv title={table?.columnName} width={rowWidth?.columnName}>
                    {table?.context}
                  </TdDiv>
                </RowDiv>
              );
            })}
        </div>
      </TableMainDiv>
    </Container>
  );
};

export default CombinationTable;
