import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { clear_all_filters } from '../../actions/filter';
import { clearBreadCrumb } from '../../actions/bread-crumb';
import { QueryWrapper, InputWrapperBox } from '../auto-suggest/auto.suggest.styled';
import { save_favourite, get_favourite_list_by_user_id, toggleMessage } from '../../actions/favourite'
import SearchSvg from '../svg/search';
import MackSvg from '../svg/speak';
import Overlay from '../helper/overlay';
import { startRecord } from '../../utils/voice';
import { _getDataFromReportingServer, getParameterByName, find_hint_in_array, get_dashboard_for_home_page, find_command_type } from '../../utils';
import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils'
import OverlaySearch from './overlay.search';
import { getReport } from '../../actions/report'
import { TitleWrapper, HomeButton, HamburgerButton } from './header.styled';
import MenuIcon from '../svg/menu';
import HomeIcon from '../svg/home';
import SearchIcon from '../svg/search';
import { showLefter, } from '../../utils';
import LefterMenu from '../lefter/v1/menu.v1.1';
import CloseSvg from '../svg/close'
import { getFromSession, saveInSession } from '../../utils/session.helper';
import { constants } from '../../utils/constants'
import PinnedPreSvg from '../svg/pre.pinned';
import PinnedPostSvg from '../svg/post.pinned';
import { dispatch_voice_command } from '../../actions/voice.helper';
import { calculatePositionForSuggestionBox } from '../../utils/calculatePositionAtCursor';


const selected = getSelectedTheme();




const hide_search_icon_path = [
    '/report_list'
]
const SearchBox = (props) => {
  const _inputRef = useRef(null)
    const { clear_all_filters, location, favouriteData, main_question, getReport, user, } = props;
    const { search, pathname } = location;

    const hints_array = props.hints?.length > 0 ? [...props.hints] : [];

    const [question, setQuestion] = useState('');
    const [isSchedule, setSchedule] = useState(true);
    const [hints, setHints] = useState([]);
    const [activeHint, setActiveHint] = useState(-1);

    // eslint-disable-next-line
    const [showFull, setShowFull] = useState(false)
    const [tempQuestion, setTempQuestion] = useState(undefined);
    const [scrollValue, setScrollValue] = useState(0);
    const [isOverlayShow, setIsOverlayShow] = useState(false)
    const [localFavData, setFavouriteData] = useState(undefined);
    const [checked, setChecked] = useState(undefined);
    const [favName, setFavName] = useState('');
    const [category_name, set_category_name] = useState('');

    const [favouritModal, setFavouritModal] = useState(undefined);
    const [sesionHints, setSessionHints] = useState(undefined);
    const [userTyping, setUserType] = useState(undefined);
    const [voiceAnimation, setVoiceAnimation] = useState(false)
    const scheduleReportId = getParameterByName("schedule_report_id", search)
    const reportId = getParameterByName("report_id", search)
    const [suggestionBoxPosition, setSuggestionBoxPosition] = useState({ top: 30, left: 0 });
    const [currCursorPosition, setCurrCursorPosition] = useState(undefined);



    useEffect(() => {
        if (search && search.length > 0) {
            const question = getParameterByName('question', search);
            const srId = getParameterByName('schedule_report_id', search);
            if (!srId) {
                setQuestion(question);
            }
            setSchedule(srId);

            if (pathname === '/analytics_map') {
                getReport(question, undefined, 'map_asdfnsdjf', undefined, undefined, undefined, undefined, undefined, undefined, props.history, undefined, true,);
            }
        }


    }, [search]);


    useEffect(() => {
        props.get_favourite_list_by_user_id();
    }, [])


    /*****
     * 
     * this use effect will check either favourite query 
     * match with our current question
     * if match then then it mark the start
     */
    useEffect(() => {
        let _question = getParameterByName('question', search);
        // setTempQuestion(_question)
        if (favouriteData) {
            if (favouriteData.results && favouriteData.results.length > 0) {
                setFavouriteData(favouriteData.results)
                let favouriteResult = favouriteData.results;
                for (let i = 0; i < favouriteResult.length; i++) {
                    if ((favouriteResult[i].query && favouriteResult[i].query.trim()) == (_question && _question.trim())) {
                        setChecked(true)
                        setFavouriteData(favouriteResult[i])
                        break;
                    } else {
                        setChecked(false)
                    }
                }
            }
            else {
                setChecked(false)
            }
        }
    }, [favouriteData, search])





    const remove_favourite = () => {
        let data = {
            id: localFavData && localFavData.id,
            name: localFavData.name,
            query: question,
            is_active: 0,
            is_modified: true
        }
        props.save_favourite(data, false)
        setChecked(false)
        setTimeout(() => {
            props.get_favourite_list_by_user_id();
        }, 100)
    }



    const is_value_container_hint = (hint) => {

        if (!props.containerValues || props.containerValues.length === 0 || !hint) return false;

        else {
            return props.containerValues?.length > 0 && hint && props.containerValues.indexOf(hint) > -1;
        }

    }


    const replaceWordAtCursor = (inputString, _x, newWord, is_value_container_value) => {
        const cursorPosition = _inputRef.current.selectionStart;
        const inputStringCurr =  _inputRef.current.value;
        
        const valueBeforeCursor = inputStringCurr.slice(0, currCursorPosition || cursorPosition);
        const words = valueBeforeCursor.split(' ');
        words.pop();
        !currCursorPosition && setCurrCursorPosition(words.join(" ")?.length+1);
        const last_word = words?.[words.length-1]
        if (newWord && (last_word === newWord.split(' ')[0] || newWord?.includes(last_word)) ) {
            words.pop();
        }

        const wordToConcate = is_value_container_value ? ("'" +newWord +"'"): newWord;
        const newValue = [...words, wordToConcate].join(' ') + inputStringCurr.slice(cursorPosition);
        const newCursorPosition = words.join(' ')?.length + wordToConcate?.length+1;
 
        setTimeout(() => {
            if(!_inputRef.current) return;
            if(!inputStringCurr?.slice(cursorPosition)?.trim()){
                _inputRef.current.scrollLeft = Math.max(0, _inputRef.current.scrollWidth - _inputRef.current.clientWidth);
            }else{
                _inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
            }
          }, 0);

        return {
            newString: newValue,
            newCursorPosition,
        };
    };
    /* 
        * replace_hint_with_word()
            * As name suggests it replace the current word with the hint.
    */

    const replace_hint_with_word = (index, setQues, cursorPosition ) => {

        if (!userTyping && sesionHints && sesionHints.length > 0) {
            const active_hint = sesionHints[index];
            setQuestion(active_hint)
        }
        if (question && question.length > 0 && userTyping) {
            // let temp_question = question;
            const active_index = (index || index === 0) ? index : activeHint;
            /* 
                * Splitting the words into the tokens.
                * Then Removing the last word.
            */ 
        //    temp_question = temp_question ? temp_question.split(' ') : '';
        //    temp_question.pop();
           
        //    const last_word = temp_question[temp_question.length - 1];
           
           const hints_array = userTyping ? hints : sesionHints
           const active_hint = hints_array[active_index];

           const is_value_container_value = is_value_container_hint(active_hint)
           const updatedString = replaceWordAtCursor(question, cursorPosition, active_hint, is_value_container_value);
        //    setCurrCursorPosition({cursorPosition: updatedString?.newCursorPosition, shouldNotUpdate: false})
            /**
             * as discuss with RICHA or Prajjawal we have implement the code for "'" single quotes will append automatically
             * at the beginning or end of the any value which are exist in value constainers.
             * 01 Feb 2023
             * for VOLVO
             */

            /* 
                * Checking again if the last word in the temp_question is equal to splitted hint at index of 0.
                * Then again pop it.
            */
            // if (active_hint && last_word === active_hint.split(' ')[0]) {
            //     temp_question.pop();
            // }
            /* 
                * Now joining the temp_question and adding the active hint into it.
            */

            // temp_question = temp_question.join(' ');
            // const new_question = temp_question + ' ' + (is_value_container_value ? (" '" + hints_array[active_index] + "' ") : hints_array[active_index])

            if (setQues) {
                setTempQuestion(undefined);
                // setQuestion(new_question);
                setQuestion(updatedString?.newString);
            }
            else {
                // setTempQuestion(new_question);
                setTempQuestion(updatedString?.newString);
            }
        };
    };

    /* 
        * scroll()
            * In Ui when auto suggest box appears user can choose the value by using the keyup and keydown buttons.
            * When user uses above key it should scroll the view to according to the keypress.
            * This function does the same thing
    */

    // const scroll = position => {
        // if (activeHint < hints.length - 1 && activeHint > 0) {
        //     const element = document.getElementById('li');

        //     const value = position === 'down' ? (30) + scrollValue : scrollValue - 30;
        //     element.scrollTop = value;

        //     setScrollValue(value);
        // }
    // };


    const regex = /\b(chart|cht|chat|bar|area|excel|csv|pdf|pie|treee map|download|bar\s?chart|pie\s?chart)\b/i;
    const containsKeyword = (input) => {
        return regex.test(input);
    };


    const query_submit = tQuestion => {
        const _question = tQuestion ? tQuestion : question;
        if (_question && _question.length > 0 && _question.trim().length > 0) {
            setQuestion(tempQuestion ? tempQuestion : _question);
            clear_all_filters();
            props.clearBreadCrumb();
            const rpt_id = undefined;
            const question_to_submit = (tempQuestion ? tempQuestion : _question).replace(/\.\s+/g, '').trim();;

            _getDataFromReportingServer(question_to_submit, undefined, rpt_id, undefined, 'plain_english', undefined, props.history);
            setTempQuestion(undefined)
            setActiveHint(-1)
            setHints([])
            setScrollValue(0);
            setVoiceAnimation(false)
        }
        else {
            return;
        }
    };

    const clear_question = () => {
        setQuestion(undefined);
        setTempQuestion(undefined);
    }


    const saveRecentQuestionInSession = async (question) => {
        let final_question = [];
        let questionsFromSession = await getFromSession(constants.SESSION_KEYS.RECENT_QUESTIONS)
        final_question = questionsFromSession ? JSON.parse(questionsFromSession) : [];
        final_question.unshift(question);
        if (final_question && final_question.length > 5) {
            final_question.length = 5;
        }

        const _uniq_question = [...new Set(final_question)];
        await saveInSession(constants.SESSION_KEYS.RECENT_QUESTIONS, JSON.stringify(_uniq_question))
    }



    useEffect(() => {
        if (isOverlayShow) {
            const session_hints = getFromSession(constants.SESSION_KEYS.RECENT_QUESTIONS);
            setSessionHints(session_hints ? JSON.parse(session_hints) : [])
        }
    }, [isOverlayShow, question])



    const update_question_by_hint = (hint, __index__) => {
        let temp_question = question + " " + hint;
        setTempQuestion(temp_question)
        setQuestion(temp_question)
        setActiveHint(__index__)
    }


    // console.log("props", question)

    // console.log("hints", hints)

    return (
        <React.Fragment>
            {isOverlayShow &&
                <Overlay top='71px' bgColor="#00000069" zIndex="12" onClick={() => setIsOverlayShow(false)} />
            }

            <OverlaySearch
                ref={_inputRef}
                hints={hints}
                update_question_by_hint={update_question_by_hint}
                setHints={setHints}
                replace_hint_with_word={replace_hint_with_word}
                activeHint={activeHint}
                setActiveHint={setActiveHint}
                suggestionBoxPosition={suggestionBoxPosition}
                currCursorPosition={currCursorPosition}
                setCurrCursorPosition={setCurrCursorPosition}
                isOverlayShow={isOverlayShow}
                clearQuestion={clear_question}
                getDataFromReportingServer={(value) => {
                    saveRecentQuestionInSession(value)
                    const rpt_id = (main_question && main_question.trim().toLocaleLowerCase()) === (question && question.trim().toLocaleLowerCase()) ? reportId : undefined;
                    _getDataFromReportingServer(value, undefined, rpt_id, undefined, undefined, undefined, props.history);
                    setIsOverlayShow(false);
                }}
                userTyping={userTyping}
                setIsOverlayShow={setIsOverlayShow}
                sesionHints={sesionHints}
                setQuestion={setQuestion}
                value={(tempQuestion ? tempQuestion : (question ? question : ''))}
                onChange={(event, inputRef, wrapperRef) => {
                    const value = event.target.value;
                    const cursorPosition = event.target.selectionStart;
                    currCursorPosition && setCurrCursorPosition(undefined)
                    // setCurrCursorPosition({cursorPosition, shouldNotUpdate: true}) //setting cursor position for leter use while selecting suggestion
                    setUserType(true)
                    setSchedule(false)
                    /* 
                        * onChange we are setting the default value for activeHint, hints, tempQuestion, scrollValue
                    */
                    setActiveHint(-1)
                    setHints([])
                    setTempQuestion(undefined)
                    setScrollValue(0)

                    if (value.length === 0) {
                        // setHints([]);
                        setTempQuestion(undefined);
                    }
                    else {
                        // const full_question = value.split(' ');
                        // const last_word = full_question[full_question.length - 1];
                        // const currentWord = last_word;
                        const words = value.slice(0, cursorPosition).split(/\s+/);
                        const currentWord = words[words.length - 1]; 

                        let temp_hints = [];
                        let concatenate_word = '';
                        setHints([])

                        if (currentWord && currentWord.length > 0) {
                            /* 
                                * find_hint_in_array()
                                    * This is pure magic of Regex.
                                    * We are using the regex to get the values from the hints array.
                                    * Please see the logic and comments of find_hint_in_array for better understanding
                            */
                            // console.log('find_hint_in_array', currentWord)
                            const result = find_hint_in_array(currentWord, hints, hints_array);
                            temp_hints = temp_hints.concat(result);
                        }
                        if (temp_hints.length > 0) {
                            const unique_hints = Array.from(new Set(temp_hints)).filter(value => !(value === currentWord || value === concatenate_word));

                            setHints(unique_hints)
                            const position = calculatePositionForSuggestionBox(cursorPosition, inputRef, wrapperRef, 'nowrap');
                            setSuggestionBoxPosition(position)
                        }
                    }

                    setQuestion(value);
                }}
                onFocus={() => {
                    setIsOverlayShow(true)
                }}

                onKeyDown={(event) => {
                    // onKeyDown={(event, setCursorAtSamePosition) => {
                    const code = event.keyCode;
                    const value = event.target.value;

                    const cursorPosition = event.target.selectionStart;

                    if (event.key === 'Enter') {
                        setUserType(undefined)
                        if (value.length === 0 || value.trim().length === 0) {
                            return
                        }
                        else {
                            clear_all_filters();
                            props.clearBreadCrumb();

                            if (pathname !== '/analytics_map') {
                                saveRecentQuestionInSession(tempQuestion ? tempQuestion : question)
                                const rpt_id = (main_question && main_question.trim().toLocaleLowerCase()) === (question && question.trim().toLocaleLowerCase()) ? reportId : undefined;
                                _getDataFromReportingServer((tempQuestion ? tempQuestion : question), undefined, rpt_id, undefined, undefined, undefined, props.history)
                                setActiveHint(undefined)
                            }
                            else {

                                saveRecentQuestionInSession(tempQuestion ? tempQuestion : question)
                                props.history.push(`?question=${tempQuestion ? tempQuestion : question}`)
                            }

                            replace_hint_with_word(activeHint, undefined,cursorPosition )
                            setQuestion(tempQuestion ? tempQuestion : question)
                            setTempQuestion(undefined)
                            setActiveHint(-1)
                            setHints([])
                            setScrollValue(0)
                            event.target.blur()
                            setIsOverlayShow(false)
                        }
                    }

                    /* 
                        * 38 is key code of up arrow key.
                        * It will change the active hint by -1                                
                    */

                    if (code === 38) {
                        if (activeHint > -1) {
                            event.preventDefault()
                            const index = activeHint - 1;
                            if (index === -1) {
                                setTempQuestion(undefined)
                            }
                            else {
                                replace_hint_with_word(index, undefined,cursorPosition)
                                // update_question_by_hint(index)
                            }
                            setActiveHint(index);
                            // scroll('up')
                        }
                    }

                    /* 
                        * 40 is key code of down arrow key.
                        * It will change the active hint by +1                                
                    */

                    if (code === 40) {
                        event.preventDefault()
                        event.stopPropagation()
                        let hint_index = (!userTyping && sesionHints && sesionHints.length > 0) ? sesionHints.length - 1 : hints.length - 1
                        if (activeHint < hint_index) {
                            const index = activeHint + 1;
                            replace_hint_with_word(index , undefined,cursorPosition)
                            setActiveHint(index)
                            // scroll('down')
                            // _inputRef.current.setSelectionRange(position, position)
                        }
                    }

                    /* 
                        * 9 is key code of Tab key.
                        * 32 is key code of Space key.
                        * On Pressing Tab or Space => active hint join the question and setHintsArray to []
                    */

                    // if (code === 9 || code === 32) {
                    //     if (code === 9) {
                    //         event.preventDefault()
                    //     }
                    //     setActiveHint(-1)
                    //     setQuestion(tempQuestion ? tempQuestion : question, undefined, true, undefined)
                    //     setTempQuestion(undefined)
                    //     setHints([])
                    //     setScrollValue(0)
                    //     event.target.focus()
                    // }

                    /* 
                        * 8 is key code of BackSpace key.
                        * 27 is key code of Esc key.
                    */
                    if (code === 8 || code === 27) {
                        setHints([])
                        setActiveHint(-1)
                        setScrollValue(0)

                        if (code === 27) {
                            setTempQuestion(undefined)
                            setIsOverlayShow(false)
                        }
                    }
                }}
                onBlur={() => {
                    setTimeout(() => {
                        // setIsOverlayShow(false)
                        setHints([])
                        setUserType(undefined)
                    }, 200)
                }}
            />

            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', cursor: 'pointer', boxSizing: 'border-box', }}>
                <HamburgerButton
                    title="Open Menu"
                    onClick={() => showLefter(<LefterMenu user={user} />)}
                >

                    <MenuIcon
                        color={themes[selected].header.color.home}
                        size="1.286rem"
                        height="1.286rem"
                    />

                </HamburgerButton>

                <HomeButton
                    title="Home"
                    onClick={() => {

                        const sorted_insight_list = get_dashboard_for_home_page(props.all_reports);

                        const id = sorted_insight_list?.length > 0 && sorted_insight_list[0].id;
                        const path = id ? `/?insightId=${id}` : '/'
                        props.history.push(path);
                        // props.resetStore();
                    }}
                >
                    <HomeIcon
                        color={themes[selected].header.color.home}
                        size="1.571rem"
                        height="1.571rem"
                    />

                </HomeButton>

                {((pathname !== '/dashboard') || scheduleReportId) && hide_search_icon_path.indexOf(pathname) == -1 && (
                    <HomeButton
                        title="Ask anyting here to your Data"
                        onClick={() => {
                            setIsOverlayShow(true)
                            setHints([])
                            // props.history.push('/');
                            // props.resetStore();
                        }}
                    >
                        <SearchIcon
                            color={themes[selected].header.color.home}
                            size="1.571rem"
                            height="1.571rem"
                        />


                    </HomeButton>
                )}


            </div>

            {(pathname == '/dashboard' && !scheduleReportId) && (
                <TitleWrapper marginLeft={props.marginLeft || undefined}>
                    <QueryWrapper showFull={showFull} hints={hints && hints.length > 0}>
                        <div style={{
                            width: '100%',
                            minWidth: '600px',
                            alignItems: 'center',
                            borderRadius: '10px',
                            transition: 'all 0.2s linear',
                            boxSizing: 'border-box',
                            background: 'white',
                            display: 'relative',
                            borderBottomLeftRadius: hints.length === 0 ? '10px' : '0',
                            borderBottomRightRadius: hints.length === 0 ? '10px' : '0',
                        }}
                        >
                            <InputWrapperBox isMackShow={tempQuestion}>
                                <input
                                    value={isSchedule ? '' : (tempQuestion ? tempQuestion : (question ? question : ''))}
                                    placeholder='Ask anything here to your data'
                                    onFocus={() => {
                                        setIsOverlayShow(true)
                                        setHints([])
                                    }}
                                />
                                {question && question.length > 0 && (
                                    <div title="Clear" className="clear_question" onClick={() => {
                                        setIsOverlayShow(true)
                                        setHints([])
                                        setQuestion(undefined)
                                    }}>
                                        <CloseSvg size=".7rem" height=".9rem" />
                                    </div>
                                )}

                                <div className="search_option">
                                    <div
                                        className="voice_btn icon"
                                        onClick={() => {
                                            setTimeout(() => {
                                                setVoiceAnimation(true);
                                            }, 10)

                                            startRecord((text) => {

                                                const __information_ = find_command_type(text)
                                                // console.log("VOICE TEXT", text, __information_);

                                                if (__information_?.type) {
                                                    props.dispatch_voice_command(__information_, text)
                                                    setVoiceAnimation(false)
                                                }
                                                
                                                else {
                                                    setQuestion(text);
                                                    setTempQuestion(text)
                                                    setTimeout(() => {
                                                        // query_submit(text);
                                                        // console.log("CHART", text)
                                                        query_submit(text);
                                                    }, 1000);
                                                }
                                            });
                                        }}
                                    >
                                        <div className={`${voiceAnimation ? 'circle_div' : undefined}`}>
                                            <div className="center_div"><MackSvg color={themes[selected].header.color.voice} size="1.2rem" height="1.3rem" /></div>
                                        </div>
                                    </div>

                                    <div className="icon" title="Search" onClick={() => query_submit(undefined)}>
                                      
                                        <SearchSvg color={themes[selected].header.color.search} size="1rem" height="1rem" />

                                    </div>
                                </div>
                            </InputWrapperBox>
                        </div>
                    </QueryWrapper>
                </TitleWrapper>
            )}
        </React.Fragment>
    );
};


const mapStateToProps = state => ({
    favouriteData: state.favouriteReducer.favouriteData,
    insightsListData: state.insightReducer.insightsListData,
    all_reports: state.reportHelperReducer.all_reports,
    containerValues: state.reportReducer.containerValues
})


export default withRouter(connect(mapStateToProps, { dispatch_voice_command, clear_all_filters, clearBreadCrumb, save_favourite, get_favourite_list_by_user_id, toggleMessage, getReport })(SearchBox));