import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import 'jspdf-autotable';
import * as _ from 'lodash';

import * as sessionManager from './session.helper';
import { store } from '../store';
import { LOADER_SHOW, LOADER_HIDE } from '../action-types/loader';
import { SHOW_SUCCESS, HIDE_SUCCESS, SHOW_FILE_DOWNLOADING_STATUS, HIDE_FILE_DOWNLOADING_STATUS } from '../action-types/success'
import * as dataTypes from '../shared-with-fe/data.types';
import { constants } from './constants';
import * as helper from '../actions/helper';
import { getTokens } from '../actions/account';
import * as client_specific_workarounds from '../client.specific.workarounds';
import * as DataType from '../shared-with-fe/data.types';
import { TABLE_CONFIG } from '../shared-with-fe/keys';

// import { sortInitialData, getCsvHeader } from './chart.helper';
import { sortInitialData, getCsvHeader, groupTabularData } from './chart.helper';
import { CHART_CONFIG } from '../shared-with-fe/keys';
import { CHART_TYPES, FULL_MONTHS, MONTHS, MONTHS_INDEXED, MONTH_NAMES } from '../shared-with-fe/constants';
import { get_grand_total_with_formula, s_p } from './v1.1';
import { check_is_condition_matched } from './v1.1';
import { quick_date_options } from '../components/filter/shared.function.js';
import { CHART_ALIASES, DOWNLOAD_ALIASES } from './voice.config.js';
import { replaceValueInTitle } from './insight.utils.js'


const { CONDITIONS } = constants

export const find_command_type = (text) => {


    const switchTableRegex = /\b(table(\s+data)?|tabular)\b/i;
    const switchTableMatch = switchTableRegex.exec(text);
    if (switchTableMatch) {
        return {
            type: 'table',
            subtype: 'table'
        };
    }


    for (const [chartType, aliases] of Object.entries(CHART_ALIASES)) {
        for (const alias of aliases) {
            const regex = new RegExp(`\\b${alias}\\b`, 'i');
            if (regex.test(text)) {
                return {
                    type: 'chart',
                    subtype: chartType
                };
            }
        }
    }

    if (/\b(chart|charts|graph)\b/i.test(text)) {
        return {
            type: 'chart',
        };
    }

    for (const [downloadType, aliases] of Object.entries(DOWNLOAD_ALIASES)) {
        for (const alias of aliases) {
            const regex = new RegExp(`\\b${alias}\\b`, 'i');
            if (regex.test(text)) {
                return {
                    type: 'download',
                    subtype: 'excel'
                };
            }
        }
    }

    // Check for data label commands
    const dataLabelRegex = /\b(datlabel|data label|label|datalabel|data labal|datalabal)\b/gi;
    const dataLabelMatch = dataLabelRegex.exec(text);
    if (dataLabelMatch) {
        // const action = dataLabelMatch[0].toLowerCase(); // Get the matched phrase
        return {
            type: 'data_label',
            //   subtype: action // Directly use the matched phrase as subtype
        };
    }

    // Check for tooltip commands
    const tooltipRegex = /\b(show|hide)\b.*?\b(tool\s?tip)\b/gi;
    const tooltipMatch = tooltipRegex.exec(text);
    if (tooltipMatch) {
        const [, action] = tooltipMatch;
        return {
            type: 'tooltip',
            subtype: action.toLowerCase() // Convert action to lowercase
        };
    }

    // Check for legend commands
    const legendRegex = /\b(show|hide)\b.*?\b(legend)\b/gi;
    const legendMatch = legendRegex.exec(text);
    if (legendMatch) {
        const [, action] = legendMatch;
        return {
            type: 'legend',
            subtype: action.toLowerCase() // Convert action to lowercase
        };
    }

    // Check for data label commands related to percentage
    const percentageDataLabelRegex = /\b(show|hide)\b.*?\b(percentage\s?in\s?data\s?labels?|percentage)\b/gi;
    const percentageDataLabelMatch = percentageDataLabelRegex.exec(text);
    if (percentageDataLabelMatch) {
        const [, action] = percentageDataLabelMatch;
        return {
            type: 'show_percentage_in_data_labels',
            subtype: action.toLowerCase() // Convert action to lowercase
        };
    }
    return null; // Neither chart nor download type found
};



const logos = {
    "hubbler": 'hubbler.jpeg',
    "hyperverge": 'hubbler.jpeg',
    "client_1": 'xxxx',
    "client_2": 'xxxx',
    "client_3": 'xxxx',
    "client_4": 'xxxx',
    "client_5": 'xxxx',
    "client_6": 'xxxx',
    "client_7": 'xxxx',
    "client_8": 'xxxx',
    "client_9": 'xxxx',
    "client_10": 'xxxx',
    "client_11": 'xxxx',
}


const months = {
    SHORT: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    FULL: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December',],
    SHORT_CAPITAL: [],
    FULL_CAPITAL: [],
    INDEXES_FULL: {
        'january': 1,
        'february': 2,
        'march': 3,
        'april': 4,
        'may': 5,
        'june': 6,
        'july': 7,
        'august': 8,
        'september': 9,
        'october': 10,
        'november': 11,
        'december': 12,
    }
};

months.FULL_CAPITAL = months.FULL.map(f => f.toLocaleUpperCase());
months.SHORT_CAPITAL = months.SHORT.map(f => f.toLocaleUpperCase());



/**
 * this function will return us a header with token info.
 * 
 * @param {*} guarded the boolean to decide if we will returning the header with open route key or the auth token
 * @returns the header object with the respective auth token/key
 */
export const getTokenHeader = guarded => {

    return {
        'x-access-token': true ? sessionManager.getToken() : process.env.OPR_KEY,
        'refresh-token': true ? sessionManager.getRefreshToken() : process.env.OPR_KEY
    };
};


/**
 * 
 * @param {*} object_to_scan 
 * @param {*} params 
 * 
 * lot of times we need to find the value going deep in an object, for example, theme.body.colors.foreground, now in order to ensure there is no undefined values in here, we do something like this:
 * 
 * theme && theme.body && theme.body.colors
 * 
 * Now its very inconvenient. Lets write a loop for this, and get rid of this for once and for all :)
 * 
 * Example steps for theme.body.colors.foreground
 * 
 * Step1: we have to check if theme is not undefined
 * Step2: we have to check if theme.body is not undefined
 * Step3: we have to check if theme.body.colors is not undefined
 * Step4: we have to check if theme.body.colors.foreground is not undefined
 * Step5: return theme.body.colors.foreground
 */



const check_is_valid_id = (id, t) => {
    if (id !== "undefined" && id !== null && typeof id !== 'null' && typeof id !== 'undefined') {
        return true;
    } else return false;

}
export const get_valid_id_and_report_type = (reportId, scheduleReportId, reportItemId, force_report_type) => {

    let id = '';
    let type = '';

    if (reportId && check_is_valid_id(reportId, 'r')) {
        id = reportId;
        type = "plain_english"
    }
    if (scheduleReportId && check_is_valid_id(scheduleReportId, 's')) {
        id = scheduleReportId;
        type = "config_query_builder"
    }
    if (reportItemId && check_is_valid_id(reportItemId, 'ins')) {
        id = reportItemId;
        type = "dashboard"
    }
    return {
        id: id,
        type: force_report_type ? force_report_type : type,
    }
}


export const value_finder = (object_to_scan, properties = []) => {

    if (properties.length > 0) {
        if (typeof object_to_scan === 'undefined') return undefined;

        const first_property = object_to_scan[properties[0]];
        const remaining_properties = properties.slice(1);

        return value_finder(first_property, remaining_properties);
    }

    return object_to_scan;
};


/**
 * VERY DIFFICULT to repeat all the styling as per the themes. Lets just get it from theme here and then returns the string literal
 * @param {*} obj_formatting_info 
 * @param {*} style_to_apply 
 * @returns 
 */
export const apply_styles = (obj_formatting_info, style_to_apply, element_type, style_type = constants.STYLE_TYPE.STRING) => {
    const style = {};

    let value_to_return = '';


    const helper_function_to_define_style_string = (css_key, value) => {
        if (typeof value !== 'undefined') {
            value_to_return = value_to_return + '\n' + `${css_key}: ${value};`;
        }
    };

    const embed_values_into_object_if_not_null = (object_to_embed_in, value_to_embed, css_key) => {
        if (typeof value_to_embed !== 'undefined') {
            object_to_embed_in[css_key] = value_to_embed;
        };
    };

    switch (style_to_apply) {
        case constants.STYLED_COMPONENTS.BORDER:
            /**
                border-left-style: ${() => theme.resizer.border.left.style};
                border-left-width: ${() => theme.resizer.border.left.width};
                border-left-color: ${() => theme.resizer.border.left.color};
             */

            helper_function_to_define_style_string('border-left-style', obj_formatting_info && obj_formatting_info.border && obj_formatting_info.border.left && obj_formatting_info.border.left.style);
            helper_function_to_define_style_string('border-left-color', obj_formatting_info && obj_formatting_info.border && obj_formatting_info.border.left && obj_formatting_info.border.left.color);
            helper_function_to_define_style_string('border-left-width', obj_formatting_info && obj_formatting_info.border && obj_formatting_info.border.left && obj_formatting_info.border.left.width);

            helper_function_to_define_style_string('border-right-style', obj_formatting_info && obj_formatting_info.border && obj_formatting_info.border.right && obj_formatting_info.border.right.style);
            helper_function_to_define_style_string('border-right-color', obj_formatting_info && obj_formatting_info.border && obj_formatting_info.border.right && obj_formatting_info.border.right.color);
            helper_function_to_define_style_string('border-right-width', obj_formatting_info && obj_formatting_info.border && obj_formatting_info.border.right && obj_formatting_info.border.right.width);

            helper_function_to_define_style_string('border-top-style', obj_formatting_info && obj_formatting_info.border && obj_formatting_info.border.top && obj_formatting_info.border.top.style);
            helper_function_to_define_style_string('border-top-color', obj_formatting_info && obj_formatting_info.border && obj_formatting_info.border.top && obj_formatting_info.border.top.color);
            helper_function_to_define_style_string('border-top-width', obj_formatting_info && obj_formatting_info.border && obj_formatting_info.border.top && obj_formatting_info.border.top.width);

            helper_function_to_define_style_string('border-bottom-style', obj_formatting_info && obj_formatting_info.border && obj_formatting_info.border.bottom && obj_formatting_info.border.bottom.style);
            helper_function_to_define_style_string('border-bottom-color', obj_formatting_info && obj_formatting_info.border && obj_formatting_info.border.bottom && obj_formatting_info.border.bottom.color);
            helper_function_to_define_style_string('border-bottom-width', obj_formatting_info && obj_formatting_info.border && obj_formatting_info.border.bottom && obj_formatting_info.border.bottom.width);
            helper_function_to_define_style_string('border-radius', obj_formatting_info && obj_formatting_info.border && obj_formatting_info.border.radius);

            // console.log("value_to_return", value_to_return, obj_formatting_info)
            return value_to_return;

        case constants.STYLED_COMPONENTS.HEIGHT_WIDTH:

            helper_function_to_define_style_string('height', obj_formatting_info && obj_formatting_info.size && obj_formatting_info.size.height);
            helper_function_to_define_style_string('min-height', obj_formatting_info && obj_formatting_info.size && obj_formatting_info.size.min_height);
            helper_function_to_define_style_string('max-height', obj_formatting_info && obj_formatting_info.size && obj_formatting_info.size.max_height);

            helper_function_to_define_style_string('width', obj_formatting_info && obj_formatting_info.size && obj_formatting_info.size.width);
            helper_function_to_define_style_string('min-width', obj_formatting_info && obj_formatting_info.size && obj_formatting_info.size.min_width);
            helper_function_to_define_style_string('max-width', obj_formatting_info && obj_formatting_info.size && obj_formatting_info.size.max_width);

            return value_to_return;

        case constants.STYLED_COMPONENTS.PADDING:
            helper_function_to_define_style_string('padding-top', obj_formatting_info && obj_formatting_info.padding && obj_formatting_info.padding.top);
            helper_function_to_define_style_string('padding-bottom', obj_formatting_info && obj_formatting_info.padding && obj_formatting_info.padding.bottom);
            helper_function_to_define_style_string('padding-right', obj_formatting_info && obj_formatting_info.padding && obj_formatting_info.padding.right);
            helper_function_to_define_style_string('padding-left', obj_formatting_info && obj_formatting_info.padding && obj_formatting_info.padding.left);

            return value_to_return;

        case constants.STYLED_COMPONENTS.FONT:
            if (element_type == constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG) {
                if (style_type === constants.STYLE_TYPE.OBJECT) {

                    embed_values_into_object_if_not_null(style, obj_formatting_info && obj_formatting_info && obj_formatting_info.font && obj_formatting_info.font.size, 'fontSize');
                    embed_values_into_object_if_not_null(style, obj_formatting_info && obj_formatting_info && obj_formatting_info.font && obj_formatting_info.font.weight, 'fontWeight');
                    embed_values_into_object_if_not_null(style, obj_formatting_info && obj_formatting_info && obj_formatting_info.font && obj_formatting_info.font.color, 'fill');
                    embed_values_into_object_if_not_null(style, obj_formatting_info && obj_formatting_info && obj_formatting_info.font && obj_formatting_info.font.text_anchor, 'textAnchor');

                    return style;
                } else {

                    helper_function_to_define_style_string('font-size', obj_formatting_info && obj_formatting_info && obj_formatting_info.font && obj_formatting_info.font.size);
                    helper_function_to_define_style_string('fill', obj_formatting_info && obj_formatting_info && obj_formatting_info.font && obj_formatting_info.font.color);
                    helper_function_to_define_style_string('line-height', obj_formatting_info && obj_formatting_info && obj_formatting_info.font && obj_formatting_info.font.line_height);

                    return value_to_return;
                }
            }
            else {
                if (style_type === constants.STYLE_TYPE.OBJECT) {
                    embed_values_into_object_if_not_null(style, obj_formatting_info && obj_formatting_info && obj_formatting_info.font && obj_formatting_info.font.size, 'font-size');
                    embed_values_into_object_if_not_null(style, obj_formatting_info && obj_formatting_info && obj_formatting_info.font && obj_formatting_info.font.weight, 'font-weight');
                    embed_values_into_object_if_not_null(style, obj_formatting_info && obj_formatting_info && obj_formatting_info.font && obj_formatting_info.font.color, 'color');

                    return style;
                } else {

                    helper_function_to_define_style_string('font-family', obj_formatting_info && obj_formatting_info && obj_formatting_info.font && obj_formatting_info.font.family);
                    helper_function_to_define_style_string('font-size', obj_formatting_info && obj_formatting_info && obj_formatting_info.font && obj_formatting_info.font.size);
                    helper_function_to_define_style_string('color', obj_formatting_info && obj_formatting_info && obj_formatting_info.font && obj_formatting_info.font.color);
                    helper_function_to_define_style_string('line-height', obj_formatting_info && obj_formatting_info && obj_formatting_info.font && obj_formatting_info.font.line_height);
                    helper_function_to_define_style_string('text-transform', obj_formatting_info && obj_formatting_info && obj_formatting_info.font && obj_formatting_info.font.text_transform);
                    helper_function_to_define_style_string('font-weight', obj_formatting_info && obj_formatting_info && obj_formatting_info.font && obj_formatting_info.font.weight);
                    helper_function_to_define_style_string('text-align', obj_formatting_info && obj_formatting_info && obj_formatting_info.font && obj_formatting_info.font.text_align);

                    return value_to_return;
                }
            }
        // }
        case constants.STYLED_COMPONENTS.BACKGROUND:
            helper_function_to_define_style_string('background-color', obj_formatting_info && obj_formatting_info && obj_formatting_info.colors && obj_formatting_info.colors.background);

            return value_to_return;
        case constants.STYLED_COMPONENTS.MARGIN:
            helper_function_to_define_style_string('margin-top', obj_formatting_info && obj_formatting_info.margin && obj_formatting_info.margin.top);
            helper_function_to_define_style_string('margin-right', obj_formatting_info && obj_formatting_info.margin && obj_formatting_info.margin.right);
            helper_function_to_define_style_string('margin-left', obj_formatting_info && obj_formatting_info.margin && obj_formatting_info.margin.left);
            helper_function_to_define_style_string('margin-bottom', obj_formatting_info && obj_formatting_info.margin && obj_formatting_info.margin.bottom);

            return value_to_return;

        case constants.STYLED_COMPONENTS.SHADOW:
            helper_function_to_define_style_string('box-shadow', obj_formatting_info && obj_formatting_info.shadow && obj_formatting_info.margin.default);

            return value_to_return;
        case constants.STYLED_COMPONENTS.ALL:
            let final_values = '';
            const border = apply_styles(obj_formatting_info, constants.STYLED_COMPONENTS.BORDER, element_type);
            const background = apply_styles(obj_formatting_info, constants.STYLED_COMPONENTS.BACKGROUND, element_type);
            const ht_wd = apply_styles(obj_formatting_info, constants.STYLED_COMPONENTS.HEIGHT_WIDTH, element_type);
            const margin = apply_styles(obj_formatting_info, constants.STYLED_COMPONENTS.MARGIN, element_type);
            const font = apply_styles(obj_formatting_info, constants.STYLED_COMPONENTS.FONT, element_type);
            const shadow = apply_styles(obj_formatting_info, constants.STYLED_COMPONENTS.SHADOW, element_type);
            const padding = apply_styles(obj_formatting_info, constants.STYLED_COMPONENTS.PADDING, element_type);


            if (border && border.length > 0) {
                final_values = final_values + ';\n' + border;
            }

            if (background && background.length > 0) {
                final_values = final_values + ';\n' + background;
            }

            if (ht_wd && ht_wd.length > 0) {
                final_values = final_values + ';\n' + ht_wd;
            }

            if (margin && margin.length > 0) {
                final_values = final_values + ';\n' + margin;
            }

            if (font && font.length > 0) {
                final_values = final_values + ';\n' + font;
            }
            if (shadow && shadow.length > 0) {
                final_values = final_values + ';\n' + shadow;
            }
            if (padding && padding.length > 0) {
                final_values = final_values + ';\n' + padding;
            }
            return final_values;
    };

    return ``;
};


export const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];



export const getSelectedTheme = () => {
    const selectedTheme = sessionManager.getFromSession(constants.SESSION_KEYS.THEME)
    return selectedTheme ? selectedTheme : "primaryTheme"
    // return process.env.THEME_NAME;

}


let temp_aligment = {
    [dataTypes.none]: {
        alignment: 'center',
        type: dataTypes.none,
    },
    [dataTypes.number]: {
        alignment: "right",
        type: dataTypes.number
    },
    [dataTypes.number_with_decimal]: {
        alignment: "right",
        type: dataTypes.number_with_decimal
    },
    [dataTypes.percent]: {
        alignment: "right",
        type: dataTypes.percent
    },
    [dataTypes.mobile]: {
        alignment: "left",
        type: dataTypes.mobile
    },
    [dataTypes.currency]: {
        alignment: "right",
        type: dataTypes.currency
    },
    [dataTypes.epoch]: {
        alignment: "left",
        type: dataTypes.epoch
    },
    [dataTypes.date]: {
        alignment: "left",
        type: dataTypes.date
    },
    [dataTypes.date_time]: {
        alignment: "left",
        type: dataTypes.date_time
    },
    [dataTypes.string]: {
        alignment: "left",
        type: dataTypes.string
    },
    [dataTypes.email]: {
        alignment: "center",
        // type: "/email"
    }
}

export const returnDateObject = (date) => {

    if (date) {
        let d = new Date(date);
        let h = d.getHours();
        let m = d.getMinutes();
        let s = d.getSeconds();

        return {
            hh: h,
            mm: m,
            ss: s
        }
    }
}


export const convert_report_px_to_percentage = (report, wrapper_initial_width, wrapper_initial_height) => {
    let finalReport = Object.assign({}, report);

    if (report) {

        let wrapper_width = wrapper_initial_width;
        let tempReport = Object.assign({}, report);

        tempReport['report_items'] && tempReport['report_items'].map((reportItem, index) => {

            const top = reportItem.top ? parseFloat(reportItem.top / wrapper_initial_height) : 0;
            const left = reportItem.left ? parseFloat(reportItem.left / wrapper_width) : 0;
            const width = reportItem.width ? parseFloat(reportItem.width / wrapper_width) : 0;
            const height = reportItem.height ? parseFloat(reportItem.height / wrapper_initial_height) : 0;

            reportItem.top = top;
            reportItem.left = left;
            reportItem.width = width;
            reportItem.height = height;
            // reportItem.which_format = 'px'
        })

        finalReport = tempReport;
    };


    return finalReport
};

export const checkDateAndSetDateTimeTypeToData = (data, key) => {

    let _is_date = true;
    let _prev_time = undefined;

    data && data.length > 0 && data.forEach((d, i) => {
        //    if(i < 50){
        d && Object.keys(d).length > 0 && Object.keys(d).forEach((k) => {
            if ((k && k.toLocaleLowerCase()) === (key && key.toLocaleLowerCase()) && d[k]) {
                let { hh, mm, ss } = returnDateObject(d[k])
                if (_prev_time === undefined) {
                    _is_date = true;
                    _prev_time = {
                        hh: hh,
                        mm: mm,
                        ss: ss
                    }
                }
                if (_prev_time && _prev_time.mm == mm && _prev_time.ss == ss && _prev_time.hh == hh) {
                    _is_date = true;
                    _prev_time = {
                        hh: hh,
                        mm: mm,
                        ss: ss
                    }
                }
                if (_prev_time && (_prev_time.mm !== mm || _prev_time.ss !== ss || _prev_time.hh !== hh)) {
                    _is_date = false;
                    _prev_time = {
                        hh: hh,
                        mm: mm,
                        ss: ss
                    }
                }
            }
        })
        //    }
    })

    if (_is_date) {
        return dataTypes.date
    } else return dataTypes.date_time

}



export const checkDataTypesForNumberAndDecimal = (data, key) => {

    let decimals_found = undefined;
    for (let index = 0; index < 50; index++) {
        const element = data[index];
        element && Object.keys(element).length > 0 && Object.keys(element).forEach((k) => {
            if ((k && k.toLocaleLowerCase()) === (key && key.toLocaleLowerCase()) && element[k]) {
                if ((parseFloat(element[k]) % 1) !== 0) {
                    decimals_found = true;

                }
            }
        })
        if (decimals_found) break;
    }


    if (decimals_found) {
        return DataType.number_with_decimal
    }
    if (!decimals_found) {
        return DataType.number
    }
}


export const yDataValueFormatter = (values) => {
    let value = values && values.length > 0 && values;
    if (value) {
        let _include = value.indexOf('_nfx_');
        if (_include !== -1) {
            let array = value.split('_nfx_');

            return array ? array[array.length - 1] : null;
        }
        else {
            return value;
        }
    }
}



export const formatMonthYearForChart = (dateString) => {

    if (!dateString) return '';

    const validMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const regex = /^([A-Za-z]{3})-(\d{4})$/;

    const match = dateString?.match(regex);

    if (match) {
        const [_, month, year] = match;

        // Check if the month is valid and the year is within the range (1900-2099)
        if (validMonths?.includes(month) && Number(year) >= 1900 && Number(year) <= 2099) {
            // Get the last two digits of the year
            const shortYear = year.slice(-2);

            // Return the formatted string
            return `${month}'${shortYear}`;
        }
    }

    // Return the input unchanged if it doesn't match a valid month-year pattern
    return dateString;
}


export const get_required_attributes_for_chart_click_option = (__event, data_key, payload, xDataKeys, columnMeta, __width, current_key, render_type, pivot_data_columns) => {

    var filter_data = undefined;

    const dimension = xDataKeys?.length > 0 && xDataKeys[xDataKeys.length - 1];
    const dimension_value = payload?.[dimension];
    const fact = data_key;



    let comparison = undefined;
    let comparison_value = undefined;

    if (xDataKeys && xDataKeys.length > 1) {
        comparison = xDataKeys[1];
        comparison_value = payload?.[comparison]
    }



    //


    // console.log("payload", payload, xDataKeys, columnMeta)

    xDataKeys?.length > 0 && xDataKeys?.forEach(x_key => {

        const filter_key1 = columnMeta?.[x_key]?.db_column;
        const data_type_db = columnMeta?.[x_key]?.db_data_type || 5;

        const column_name_to_use_v1 = filter_key1 + "__nfx__db__" + data_type_db; // column data data type with column name

        console.log("column_name_to_use_v1", column_name_to_use_v1)
        if (!filter_data) filter_data = {};
        const __value = typeof payload?.[x_key] == "number" ? payload?.[x_key] : payload?.[x_key]?.trim();

        if (render_type == "table" && current_key && x_key === current_key) {
            if (column_name_to_use_v1 && column_name_to_use_v1.indexOf(".") > -1) filter_data[column_name_to_use_v1] = { key: x_key, value: __value }
        }

        if ((!render_type || render_type !== "table")) {
            if (column_name_to_use_v1 && column_name_to_use_v1.indexOf(".") > -1) filter_data[column_name_to_use_v1] = { key: x_key, value: __value }
        }

    })



    // console.log("filter_data", filter_data, render_type)

    const comparision_values = data_key && data_key?.length > 0 && data_key?.split("_nfx_");

    // this is for pivot comparison columns 
    (pivot_data_columns || []).map((c, index) => {

        const filter_key1 = columnMeta?.[c]?.db_column;
        const data_type_db = columnMeta?.[c]?.db_data_type || 5;

        const column_name_to_use_v1 = filter_key1 + "__nfx__db__" + data_type_db; // column data data type with column name

        if (!filter_data) filter_data = {};

        const __value = typeof comparision_values?.[index] == "number" ? comparision_values?.[index] : comparision_values?.[index]?.trim();

        if (render_type == "table" && current_key && c === current_key) {
            if (column_name_to_use_v1 && column_name_to_use_v1.indexOf(".") > -1) filter_data[column_name_to_use_v1] = { key: c, value: __value }
        }

        if (render_type !== "table") {
            if (column_name_to_use_v1 && column_name_to_use_v1.indexOf(".") > -1) filter_data[column_name_to_use_v1] = { key: c, value: __value }
        }

    })




    const fact_to_use = fact?.length > 0 && fact?.split("_nfx_")[fact?.split("_nfx_").length - 1]
    const fact_db_name = fact && columnMeta?.[fact_to_use]?.db_column;

    const dimension_db_name = dimension && columnMeta?.[dimension]?.db_column;
    const width = __width || 210;

    console.log("pivot_data_columns", filter_data, pivot_data_columns, fact, fact_db_name)

    let top = (__event?.clientY || __event?.cy) - 150
    const temp_left = (__event?.clientX || __event?.cx) - (width / 2);
    const left = temp_left < 0 ? 0 : temp_left;
    const popup_bg = "#0000";


    return {
        containerWidth: width,
        top: top,
        left: left,
        fact: fact,
        fact_db_name: fact_db_name,
        dimension: dimension,
        dimension_db_name: dimension_db_name,
        filter_data: filter_data,
        popup_bg: popup_bg,
        dimension_value: dimension_value,
        comparison: comparison,
        comparison_value: comparison_value
    }


}


function calculateMaxCharacters(width, data = [], c = 8) {

    // Calculate how many characters can fit in the given width
    var how_many_ch_can_fit = width / c;

    // console.log("how_many_ch_can_fit", how_many_ch_can_fit, data?.length)
    // Calculate max characters per data item
    var max_ch = how_many_ch_can_fit / data.length;

    console.log("max_ch", max_ch)
    return max_ch;
}


export const getChartXAxisLayout = (data, wrapperWidth, wrapperHeight, axis, tickCount, chartType) => {

    const isAngleLayout = (
        wrapperWidth > 400 &&
        wrapperHeight >= 300 &&
        // axis?.length === 1 &&
        tickCount > 8 &&
        tickCount < 100
    );

    const isMutliAxis = axis?.length > 1;
    const isLimitedWithSingleAxis = (axis?.length === 1 && tickCount <= 5 && wrapperWidth > 640 && wrapperWidth < 1000);
    const max_value = calculateMaxCharacters(wrapperWidth, data)

    const more_then_100 = (data?.length >= 100 || max_value <= 2);

    return isAngleLayout
        ? {
            height: 100,
            textAnchor: 'start',
            verticalAnchor: 'start',
            angle: 90,
            interval: 0,
            max_value: 7,
            maxLen: 5,
            style: {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
            }
        }
        :

        isMutliAxis ?
            {
                height: 70,
                textAnchor: 'middle',
                verticalAnchor: 'start',
                width: 2,
                interval: more_then_100 ? 'preserveStart' : 0,
                maxLen: more_then_100 ? undefined : max_value,
            }
            :
            isLimitedWithSingleAxis ?
                {
                    height: 50,
                    textAnchor: 'middle',
                    verticalAnchor: 'start',
                    width: 2,
                    interval: 0,
                    fontSize: '1.1rem',
                    interval: more_then_100 ? 'preserveStart' : 0,
                    maxLen: more_then_100 ? undefined : max_value,

                }
                :

                {
                    height: 50,
                    textAnchor: 'middle',
                    verticalAnchor: 'start',
                    width: 2,
                    interval: more_then_100 ? 'preserveStart' : 0,
                    maxLen: more_then_100 ? undefined : max_value,
                };
};




export const find_cell_format = (tbl_formatter, sorted_columns, cell_values, __index__, show_precentage) => {

    let beautify_data = undefined;

    const sorted_tbl_formatter = tbl_formatter?.length > 0 && tbl_formatter.filter((c) => !c.deleted && c.apply_to_chart).sort((a, b) => a.field_order - b.field_order) || [];

    if (sorted_tbl_formatter && sorted_tbl_formatter.length > 0) {
        for (let index = 0; index < sorted_tbl_formatter.length; index++) {
            const element = sorted_tbl_formatter[index];
            for (let j = 0; j < sorted_columns.length; j++) {
                const cell_key = sorted_columns[j];
                const cell_value = show_precentage ? cell_values[cell_key + '_nfsum'] : cell_values[cell_key];
                if (element.column_name === cell_key) {
                    if (check_is_condition_matched(element.condition, cell_value, element.value)) {
                        // if (!beautify_data) 
                        beautify_data = element;
                        // beautify_data =  element
                        break;
                    }
                }
            }

        }
        return beautify_data
    } else return false;
}


export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const smallFirstLetter = (string) => {
    return string.charAt(0).toLowerCase() + string.slice(1);
}


export const encryptDisplayData = (word, encryptType = 'encrypt') => {
    if (word && word.length > 0) {
        let _word_len = word.length;
        let final_data = '';
        for (let i = 0; i < _word_len; i++) {
            final_data += 'x';
        }
        return final_data
    }
}



export const splitByNf = (name) => {
    if (name && name.length > 0 && name.indexOf('nf_') > -1) {
        return name.split('nf_')[1].split('_').join(' ')
    }
    else if (name && name.length > 0) {
        // console.log('breakwa: ', name.split('_nfx_').join(' '));
        return name.split('_nfx_').join(' ')
    } else return name
}


export const find_column__alias = (axis_info, name) => {
    let final_value = name;
    if (axis_info && axis_info?.length > 0) {
        const found = axis_info.find(item => item.pivot_field_alias && item?.pivot_field_column_name === name)
        if (found) {
            final_value = found.pivot_field_alias
        } else {
            final_value = name;
        }
    }
    return final_value ? splitByNf(final_value) : final_value;

}

export function checkYearAndMakeFy(targetYear) {
    // Ensure that targetYear is a number, even if it's passed as a string
    const targetYearNum = parseInt(targetYear, 10);

    // Check if targetYearNum is a valid year
    if (isNaN(targetYearNum) || targetYearNum < 1900 || targetYearNum > 2100) {
        return false;
    }

    // Add 1 to the target year
    const nextYear = targetYearNum + 1;

    // Get the last two digits of the next year
    const lastTwoDigits = nextYear.toString().slice(-2);

    // Return the year in FY'XX format
    return `FY'${lastTwoDigits}`;
}

export const formatValueByDataType = (value, type, renderMode, symbolHide, key, from_where, currency_type, num_format_type, use_decimal, use_percent, other_format_config, source) => {


    if (value === "") return '';

    if (type == -1) {
        return value;
    }

    const currency_sing = currency_type ? (currency_type + " ") : (constants.CURRENCY_TYPES.RUPEE + " ");

    if (key && typeof key === 'string' && (key.toLocaleLowerCase() === 'week' || key.toLocaleLowerCase() === 'nf_week')) {
        return '' + value;
    }

    if (key && ((key + '').toLocaleLowerCase() === 'quarter')) {
        return value;
    }

    if (key && ((key + '').toLocaleLowerCase() === 'year')) {
        if (checkYearAndMakeFy(value)) return checkYearAndMakeFy(value)
    }

    if (key && ((key + '').toLocaleLowerCase() === 'month')) {
        if (formatMonthYearForChart(value)) {
            return formatMonthYearForChart(value)
        }
    }

    let returning_value = value;

    if (value === 'XXXX') return 'XXXX';

    // if (from_where === 'insights' && type === DataType.currency) {
    //     if (value === 'null' || !value) return 0;
    //     returning_value = currency_sing + DataFormatter(value, use_decimal)
    //     return returning_value;

    // }

    // if (from_where === 'insights' && type === DataType.number) {
    //     returning_value = DataFormatter(value, use_decimal)
    //     return returning_value;
    // }

    // let's format number and currency here
    if ((type === DataType.number || type === DataType.currency || type === DataType.percent || type === DataType.number_with_decimal)) {

        // only we will use default currency type when our datatype is currency
        const currency_sing = currency_type ? (currency_type + "  ") : type === DataType.currency ? (constants.CURRENCY_TYPES.RUPEE + "  ") : '';

        // const temp_use_decimal = type === DataType.number_with_decimal ? 2 : use_decimal

        if (num_format_type === "date_time_hour") {

            returning_value = format_date_into_d_h_m_v1(value, "minutes")

        }
        else if (use_percent) {

            if (value === 'null' || !value) return 0 + "%";

            returning_value = formatNumber(value, true, use_decimal, num_format_type) + " %"
        }
        else {

            if (value === 'null' || !value) return !symbolHide ? ((source === "PDF" ? "Re " : currency_sing) + 0) : 0

            returning_value = (source === "PDF" ? "Re " : currency_sing) + formatNumber(value, true, use_decimal, num_format_type)
        }

    }


    if (type === dataTypes.date && (is_date(value))) {
        const date_obj = parse_any_date_into_date_object(value)
        returning_value = convert_date_to_dd_mm_yyyy_v1(date_obj, false, false)
        return returning_value;
    }

    if (type === dataTypes.date_time && is_date(value)) {
        const date_obj = parse_any_date_into_date_object(value)
        returning_value = convert_date_to_dd_mm_yyyy_v1(date_obj, true, false)
        return returning_value;
    }
    return returning_value;
}



export const find_month_index_by_month_name = (month_name) => {

    var found = -1;

    const { SHORT, FULL } = MONTH_NAMES;

    found == -1 && Object.keys(SHORT).forEach((k, i) => {
        if (found == -1 && k === month_name) {
            found = i + 1;
        }
    })

    found == -1 && Object.keys(FULL).forEach((k, i) => {
        if (found == -1 && k === month_name) {
            found = i + 1;
        }
    })

    return found;

}


export const convert_date_into_date_object = (date) => {

    let dd = 1
    let mm = 1
    let yyyy = 2001

    let hours = 0
    let minutes = 0
    let seconds = 0

    // 2019-01-29T00: 00:00.000Z

    if (date && typeof date && is_date(date)) {
        const new_date = new Date(date);
        return new_date;
    }



    // console.log("date====", date)

    if (date && typeof date !== "undefined" && typeof date === "string") {

        if (is_date_month(date)) {

            const month = date.split('-')?.length > 0 && date.split('-')[0];
            const year = date.split('-')?.length > 1 && date.split('-')[1];

            mm = find_month_index_by_month_name(month);

            yyyy = year;

        }

        else {

            if (date && date.toString().indexOf('-') > -1) {


                let d = date.split('-')?.length > 0 && date.split('-')[0];
                let time = date.split('-')?.length > 0 && date.split('-')[1];

                dd = d.split('/')?.length > 0 && d.split('/')[0]
                mm = (d.split('/')?.length > 1 && d.split('/')[1] || - 1)
                yyyy = d.split('/')?.length > 2 && d.split('/')[2]

                hours = time?.split(':')?.length > 0 && time?.split(':')[0];
                minutes = time?.split(':')?.length > 1 && time?.split(':')[1];
                seconds = time?.split(':')?.length > 2 && time?.split(':')[2];

            }
        }
    }

    const new_date = new Date(yyyy, mm, dd, hours, minutes, seconds, 0);
    return new_date;


}



export const is_date_month = (_value, separator = '-') => {

    try {
        if (_value && typeof _value === "string" && (_value + '')?.indexOf(separator) > -1) {
            const values = (_value + '').split(separator);
            if (values.length === 2 && !isNaN(values[1].trim())) {

                const year = parseInt(values[1].trim());
                const month = values[0].trim().toLocaleUpperCase();

                if (((year > 2000 && year < 2050) || (year > 0 && year < 50))) {
                    let index = months.SHORT_CAPITAL.indexOf(month);

                    if (index > -1) {

                    }
                    else {
                        index = months.FULL_CAPITAL.indexOf(month);
                    }

                    if (index > -1) {
                        return true;
                    }
                }
            }
        }

        if (separator === '-') return is_date_month(_value, '\'');
    } catch (error) {

        console.log("i am error ", error, _value)
    }
};



var __year = 2021;

export const get_short_month_with_value = (_value) => {

    if (_value) {

        const month = _value.trim().toLocaleUpperCase();
        let index = months.SHORT_CAPITAL.indexOf(month);

        if (index > -1) {

        }
        else {
            index = months.FULL_CAPITAL.indexOf(month);
        }
        if (index > -1) {
            if (index > -1 && index <= 2) {
                return months.SHORT[index] + '-2022'
            } else {
                return months.SHORT[index] + '-2021'
            }
        }
    } else {
        return _value;
    }
}

export const is_month = (_value) => {

    if (_value) {
        const month = _value.trim().toLocaleUpperCase();
        let index = months.SHORT_CAPITAL.indexOf(month);

        if (index > -1) {

        }
        else {
            index = months.FULL_CAPITAL.indexOf(month);
        }
        if (index > -1) {
            return true;
        }
    } else {
        return false;
    }
}

export const is_date = _value => {
    if (_value === null) return false;

    if (typeof _value === 'undefined') return false;
    if (typeof _value === 'object') {
        // object
        const possible_date = new Date(_value)
        if (possible_date) return true;
    }


    const value = (() => {
        if ((typeof _value === 'number' || typeof _value === 'bigint' || typeof _value === 'string')) {
            return _value + '';
        }

        return _value;
    })();

    if (value == parseInt(value).toString()) return false;

    const date = Date.parse(value);
    const condition_1 = /[a-zA-Z]/.test(value);
    const condition_2 = condition_1 && isNaN(date);

    if (condition_1) {
        // string found. lets check if this is ISO or not, otherwise it is not a date
        // for json dates like 2021-07-01T18:30:00.000Z
        const regex = new RegExp('[0-2][0-9][0-9][0-9]-[0-1][0-9]-[0-9][0-9]T[0-9][0-9]:[0-9][0-9]', 'g');
        const m = value.match(regex);

        return m !== null && m && m.length > 0;
    }

    // return value.indexOf('nf-') === -1 && !isNaN(date) && date > -19800000 && date < 1893456000000 && (value.indexOf('-') > -1 

    // fixed on 28-5-2020 for olap date fix.....it was not returning date so did a change added a or condition in return statement || value.indexOf(' ')>-1 
    /*commented because type of value was object and index of only takes value as
    string or other format not as date object
    that's why taken new variable values and return everything by using values
    */

    return value.indexOf('nf-') === -1 && !isNaN(date) && (!isNaN(date) && date > -19800000 && date < 1893456000000 && (value.trim().indexOf('-') > 1 || value.indexOf(' ') > -1));
    //     return values.indexOf('nf-') === -1 && !isNaN(date) && date > -19800000 && date < 1893456000000 && (values.indexOf('-') > -1 || values.indexOf(' ')>-1) ;
};


export const is_date_time = value => {
    if (is_date(value)) {
        const date = new Date(value);
        // let's check the 
        // console.log("yess", date.toLocaleTimeString() === '05:30:00')
        // saroj kr added 05:30:00 for BARC but we removed it
        return !(date.toLocaleTimeString() === '12:00:00 AM' || date.toLocaleTimeString() === '00:00:00')
    }

    return false;
};



export const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0');
}

const formatDate = (date, is_increment_month) => {

    if (date) {
        return [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('/');
    }

}




export const is_valid_dd_mm_yyyy_date = (___date__) => {



    // const dateStr = ___date__ && ___date__.toString().split("-")?.length > 0 && ___date__.toString().split('-')[0] && ___date__.toString().split('-')[0].trim();


    const str_date = (___date__ + " ")


    const dateStr = str_date.split("-")?.length > 0 && str_date.split('-')[0].trim()

    const regex = /^\d{2}\/\d{2}\/\d{4}$/;

    // console.log("dateStr", dateStr)
    if (dateStr && dateStr.toString().match(regex) === null) {
        return false;
    }

    const [day, month, year] = dateStr.split('/');

    // 👇️ format Date string as `yyyy-mm-dd`
    const isoFormattedStr = `${year}-${month}-${day}`;

    console.log("isoFormattedStr", isoFormattedStr)
    const date = new Date(isoFormattedStr);
    const timestamp = date.getTime();

    if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
        return false;
    }

    return date.toISOString().startsWith(isoFormattedStr);
}


export const is_valid_date_v2 = (date) => {

    if (typeof date !== null && date !== undefined) {
        if (is_valid_dd_mm_yyyy_date(date)) {
            return "dd_mm_yyyy"
        }
        else if (is_date_month(date)) return "month_year";
        else if (is_date(date)) return "date";
        else return false
    }
}




const toDaysMinutesSeconds = (totalSeconds) => {

    if (!totalSeconds && totalSeconds !== 0) return 'N/N';

    if (parseFloat(totalSeconds) === 0 || parseFloat(totalSeconds) < 59) return parseFloat(totalSeconds) + " S";

    const minutes = Math.floor((totalSeconds % 3600) / 60) || 0;
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600) || 0;
    const days = Math.floor(totalSeconds / (3600 * 24)) || 0;

    const minutesStr = makeHumanReadable(minutes, 'Mins');
    const hoursStr = makeHumanReadable(hours, 'H');
    const daysStr = makeHumanReadable(days, 'D');

    if (minutes === 0 && hours === 0 && days === 0) return 0;

    return (days > 0 ? daysStr : "") + (hours > 0 ? (" " + hoursStr) : "") + (minutes > 0 ? (hours > 0 ? " and " : " ") + minutesStr : "");
}

function makeHumanReadable(num, singular) {
    return num + singular
    // ? num + (num === 1 ? ` ${singular}, ` : ` ${singular}s, `)
    // : '';
}
export const format_date_into_d_h_m_v1 = (seconds, type) => {

    // const days = pared_seconds / (1000 * 3600 * 24) || 0;
    // var days = Math.floor(pared_seconds / (3600 * 24));
    // const hours = Math.floor(pared_seconds / 3600) || 0;
    // const minutes = Math.floor(pared_seconds / 60) || 0;


    return toDaysMinutesSeconds(seconds) //days + "D " + hours + "H " + "and " + minutes + "Mins";

    // if (type === "years") {
    //     return seconds / 
    // }

    // else if (type === "months") {
    //     return seconds
    // }

    // if (type === "weeks") {
    //     return seconds;
    // }
    // else if (type === "days") {

    //     const days = seconds / (1000 * 3600 * 24);

    //     return (days > 0 ? days + " Days" : days + " Day")
    // }
    // else if (type === "hours") {

    //     var hours = Math.floor(seconds / 3600);

    //     return (hours > 0 ? hours + " Hours" : hours + " Hour")
    // }
    // else if (type === "minutes") {

    //     var minutes = Math.floor(seconds / 60);

    //     return (minutes > 0 ? minutes + " Minutes" : minutes + " Minute")
    // }

    // else return seconds;

}

export const parse_any_date_into_date_object = (date) => {

    let dd = 0
    let mm = 0
    let yyyy = 2001
    let hours = 0
    let minutes = 0
    let seconds = 0

    const is_valid_date = is_valid_date_v2(date);

    if (!is_valid_date) {
        return 0;
    }


    if (is_valid_date === "dd_mm_yyyy") {

        let d = date.split('-')?.length > 0 && date.split('-')[0];
        let time = date.split('-')?.length > 0 && date.split('-')[1];

        dd = d.split('/')?.length > 0 && d.split('/')[0]
        mm = ((d.split('/')?.length > 1 && d.split('/')[1])) - 1
        yyyy = d.split('/')?.length > 2 && d.split('/')[2]

        hours = time?.split(':')?.length > 0 && time?.split(':')[0];
        minutes = time?.split(':')?.length > 1 && time?.split(':')[1];
        seconds = time?.split(':')?.length > 2 && time?.split(':')[2];

    }
    else if (is_valid_date === "month_year") {

        const month = date.split('-')?.length > 0 && date.split('-')[0];
        const year = date.split('-')?.length > 1 && date.split('-')[1];
        mm = find_month_index_by_month_name(month);
        yyyy = year;
        // dd = new Date(year, padTo2Digits(mm), 0);


    }
    else if (is_valid_date === "date") {
        return new Date(date);
    }

    return new Date(yyyy, padTo2Digits(mm), padTo2Digits(dd), hours, minutes, seconds, 0);;
}



const full_months_converted = Object.values(FULL_MONTHS).map((a => a.toLocaleLowerCase())) || [];
const short_months_converted = Object.values(MONTHS).map((a) => a.toLocaleLowerCase()) || [];


function isValidDate_V2(dateString) {
    const isoDateRegex = /^(?:\d{4}-\d{2}-\d{2}(?:T\d{2}:\d{2}:\d{2}(?:\.\d{3})?Z)?)$/;
    const mdyDateRegex = /^(?:\d{2}\/\d{2}\/\d{4})$/;

    return isoDateRegex.test(dateString) || mdyDateRegex.test(dateString);
}


export const convert_normal_date_filter_to_JAVA = (base_data) => {

    const clone_base_data = base_data ? { ...base_data } : {};

    var value = base_data["value"];
    var type = base_data["type"];
    var year = base_data["year"];
    var month = base_data["month"] || 1;
    var single_date = clone_base_data["single_date"];

    const start_date = clone_base_data["start_date"] || clone_base_data["range_start_date"];
    const end_date = clone_base_data["end_date"] || clone_base_data["range_end_date"];


    if (!type && value) {

        const month = value?.split("-")?.[0];
        const year = value?.split("-")?.[1];

        if (year && month && (full_months_converted.indexOf(month.toLocaleLowerCase()) > -1 || short_months_converted.indexOf(month.toLocaleLowerCase()) > -1)) {
            // passed it means it is a month-year ;
            type = "month_year";
            value = month + " " + year;

        }
        else if (isValidDate_V2(value)) {
            type = "single_date";
            value = parse_any_date_into_date_object(value)
        }
        else {
            type = "year"
        }
    }



    switch (type) {
        case "month":
        case "month_year":
            return {
                "type": "month_year",
                "month_year": value || (MONTHS[month] + " " + year)
            };
        case "year":
            return {
                "type": "year",
                "year": value || year
            };
        case "range":
            return {
                "type": "range",
                "range_start_date": start_date,
                "range_end_date": end_date,
            };
        default:
            return {
                "type": "single",
                "single_date": value || single_date || new Date(),
            }
    }


}



export const convert_date_to_dd_mm_yyyy_v1 = (_date, full_time, is_increment_month = false) => {


    if (_date) {

        const pre_date = _date// && JSON.parse(JSON.stringify(_date || '')).replace("Z", "")

        // we will remove this replace [z] in future releases

        // const pre_date = _date && JSON.stringify(_date).replace(/\"/g, "").replace("Z", "");
        const new_date = new Date(pre_date);

        let date_to_return = formatDate(new_date, is_increment_month)

        if (full_time) {

            const hours = new_date?.getHours()
            const minutes = new_date?.getMinutes()
            const seconds = new_date?.getSeconds()

            const __time__ = [padTo2Digits(hours), padTo2Digits(minutes), padTo2Digits(seconds)].join(":")

            date_to_return = formatDate(new_date, is_increment_month) + " - " + __time__;
        }

        return date_to_return;
    }
}




export const convertDateInToHtmlDate = (time, key, not_need_time) => {

    if (time) {

        let d = new Date(time)

        let month = d.getMonth()
        let day = d.getDate();
        let year = d.getFullYear();
        let hours = d.getHours();
        let minutes = d.getMinutes();



        if ((month || month === 0) && (day || day === 0) && year && (hours || hours === 0) && (minutes || minutes === 0)) {
            let temp_month = parseInt(month) + 1;
            let temp_day = parseInt(day);;
            let temp_minute = parseInt(minutes);

            if (temp_minute < 10) {
                temp_minute = '0' + temp_minute
            }

            if (temp_day < 10) {
                temp_day = '0' + temp_day
            }

            if (temp_month < 10) {
                temp_month = '0' + temp_month
            }


            if (hours < 10) {
                hours = '0' + hours
            }

            if (!not_need_time) {
                return year + '-' + temp_month + '-' + temp_day + 'T' + hours + ':' + temp_minute;
            } else {
                return year + '-' + temp_month + '-' + temp_day;
            }

        }
        else {
            return time
        }
    }
}



export const convertDateInToDDMMYYYHHMM = (_date, fullTime) => {

    if (_date) {

        let d = new Date(_date)
        let month = d.getMonth()
        let day = d.getDate();
        let year = d.getFullYear();
        let hours = d.getHours();
        let minutes = d.getMinutes();

        if ((month || month === 0) && (day || day === 0) && year && (hours || hours === 0) && (minutes || minutes === 0)) {
            let temp_month = parseInt(month) + 1;
            let temp_day = parseInt(day);;
            let temp_minute = parseInt(minutes);
            let temp_hour = parseInt(hours)

            if (temp_minute < 10) {
                temp_minute = temp_minute ? ('0' + temp_minute) : undefined;
            }
            if (temp_hour < 10) {
                temp_hour = temp_hour ? ('0' + temp_hour) : undefined;
            }

            if (temp_day < 10) {
                temp_day = '0' + temp_day
            }
            if (temp_month < 10) {
                temp_month = '0' + temp_month
            }
            const full_date = temp_day + '-' + temp_month + '-' + year + (fullTime ? ((temp_hour ? (' , ' + temp_hour) : '') + (temp_minute ? (':' + temp_minute) : '')) : '');

            return full_date
        }
    }
    else return _date;
}


// export const get_chart_type_based_on_column_response = (data) => {
//     // Object to hold the resulting chart types for each dataset.
//     const chartTypes = {};

//     // Iterate over each key in the input data object.
//     Object.keys(data).forEach((key) => {
//         if (key !== 'response_session_id') {
//             // Extract the columns for the current dataset.
//             const columns = data[key].columns_extracted;

//             // If there are more than 4 columns, return 'table'.
//             if (columns.length > 4) {
//                 chartTypes[key] = "table";
//             }
//             // If there is only one column, return 'single_cell'.
//             else if (columns.length === 1) {
//                 chartTypes[key] = "single_cell";
//             }
//             else {
//                 // Initialize counters for different types of columns.
//                 let factCount = 0; // Counts columns with data types 'currency', 'number', 'int'.
//                 let otherCount = 0; // Counts columns with other data types.

//                 // Iterate over each column in the current dataset.
//                 columns.forEach((column) => {
//                     // Check if the column is a fact (numeric) type.
//                     if (["currency", "number", "int"].includes(column.data_type)) {
//                         factCount++;
//                     } else {
//                         // Increment the counter for non-numeric types.
//                         otherCount++;
//                     }
//                 });

//                 // Determine the chart type based on the column type counts.
//                 if (factCount >= 2) {
//                     // If there are 2 or more fact columns, suggest a stack chart.
//                     chartTypes[key] = CHART_TYPES.stacked_bar_chart
//                 } else if (factCount === 1 && otherCount >= 1) {
//                     // If there's 1 fact column and at least 1 other type, suggest a bar chart.
//                     chartTypes[key] = CHART_TYPES.bar_chart
//                 } else {
//                     // For all other cases, suggest an 'other_chart'.
//                     chartTypes[key] = CHART_TYPES.bar_chart
//                 }
//             }
//         }

//     });

//     // Return the chart types determined for each dataset.
//     return chartTypes;
// };

const regexToFindTop = /top/i;

export const get_chart_type_based_on_column_response = (data) => {
    // Object to hold the resulting chart types and titles for each dataset.
    const results = {};


    const chart_list = Object.values(CHART_TYPES);

    let previousChartType = null;

    // Function to get the next chart type from the list, avoiding repetition.
    const getNextChartType = (currentType) => {
        const currentIndex = chart_list.indexOf(currentType);
        return chart_list[(currentIndex + 1)];
    };

    // Iterate over each key in the input data object.
    Object.keys(data).forEach((key) => {
        if (key !== 'response_session_id') {
            // Extract the columns for the current dataset.
            const columns = data[key].columns_extracted;
            const query = (data[key]?.query || '')?.toString()?.toLocaleLowerCase();

            // console.log("query", query)
            const is_comparison = query?.includes("vs") || query?.includes("comparison");
            const is_top_query = regexToFindTop.test(query);

            // Initialize variables for chart type and title.
            let chartType;
            let title;

            // If there are more than 4 columns, return 'table'.
            if (columns.length > 4) {
                chartType = "table";
                title = `Overview of ${columns.map(col => col.alias).join(", ")}`;
            }
            // If there is only one column, return 'single_cell'.

            else if (columns.length === 1) {
                chartType = "single_cell";
                title = `Summary of ${columns[0].alias}`;
            }
            else {

                // Initialize lists to track fact and dimension columns.
                const factColumns = [];
                const dimensionColumns = [];

                // Classify columns based on their data types.
                columns.forEach((column) => {
                    if (["currency", "number", "int"].includes(column.data_type)) {
                        factColumns.push(column.alias);
                    } else {
                        dimensionColumns.push(column.alias);
                    }
                });

                // Determine the chart type and title based on the column type counts.
                if (factColumns.length >= 2) {
                    chartType = CHART_TYPES.stacked_bar_chart;
                    if (dimensionColumns.length >= 2) {
                        title = `${is_comparison ? 'Comparison' : ''} ${dimensionColumns.slice(0, 2).join(", ")} wise ${factColumns.join(" And ")}`;
                    } else {
                        title = `${is_comparison ? 'Comparison' : ''} ${dimensionColumns[0] || "Dimension"} wise ${factColumns.join(" And ")}`;
                    }
                } else if (factColumns.length === 1 && dimensionColumns.length && is_top_query) {
                    chartType = "pie_chart";
                    title = `Top of ${columns.map(col => col.alias).join(", ")}`;
                }
                else if (factColumns.length === 1 && dimensionColumns.length >= 2) {
                    chartType = previousChartType ? getNextChartType(previousChartType) : chart_list[4];
                    title = `${dimensionColumns.slice(0, 2).join(", ")} wise ${factColumns[0]}`;
                } else if (factColumns.length === 1 && dimensionColumns.length === 1) {
                    chartType = previousChartType ? getNextChartType(previousChartType) : chart_list[0];
                    title = `${dimensionColumns[0]} wise ${factColumns[0]}`;
                } else {
                    chartType = "table";
                    title = `${columns.map(col => col.alias).join(", ")} Overview`;
                }
            }
            // Assign the chart type and title to the results object.
            results[key] = { chartType, title };
        }
    });

    // Return the chart types and titles determined for each dataset.
    return results;
};





/**
 * @returns the header object with user-id
 */
export const getUserHeader = () => {
    return {
        'user-id': sessionManager.getLoggedUserId()
    };
};


/**
 * @returns the header representing header
 */
export const getJSONHeader = () => {
    return {
        'content-type': 'application/json'
    };
};



export const isDefaultLiveDb = () => {
    // return process.env.IS_DEFAULT_LIVE_DB
    return process.env?.REACT_APP_DEFAULT_DB_SELECTION == 'live'
}



/**
 * @returns the opr header. opr header is the token header with the opr key. opr key will be used to validate the request  
 */
export const getOPRHeader = () => {
    return {
        'x-access-token': process.env.OPR_KEY
    };
};



export const getClientId = () => {
    const clientId = sessionManager.getFromSession(constants.SESSION_KEYS.CLIENT_ID);

    return {
        'client-id': clientId
    }
};

export const getDatabaseHeader = () => {
    const db_id = sessionManager.getFromSession(constants.SESSION_KEYS.DATABASE_ID);
    return {
        'database-id': db_id
    }
}


export const getUserSessionId = () => {
    const session_id = sessionManager.getFromSession(constants.SESSION_KEYS.SESSION_ID);
    return {
        'user-session-id': session_id
    }
}


export const getDbInfoId = () => {
    const db_id = sessionManager.getFromSession(constants.SESSION_KEYS.DATABASE_ID);
    return db_id;
}




/**
 * 
 * @param {*} guarded guarded means we need to include the header for the requests that needs authentication. The open requests will need opr header instead
 * 
 * @returns the header object with all the mandatory headers like token, json, and user header
 */

export const getMandatoryRequestHeaders = (guarded, folderName, tableName, collection_name, db_id, platform_name, email, subject, message) => {

    const mandatoryUserHeader = getUserHeader();
    const mandatoryTokenHeader = getTokenHeader(guarded);
    const mandatoryJSONHeader = getJSONHeader();
    const mandatoryClientHeader = getClientId();
    const mandatoryDatabaseHeader = getDatabaseHeader();
    const user_session_id = getUserSessionId()

    const __header___ = {

        'random-name': folderName,
        'table-name': tableName,
        'collection-name': collection_name,
        'db-id': db_id,
        'platform-name': platform_name,
        'time-zone-offset': new Date().getTimezoneOffset(),
        'emails': email,
        'subject': subject,
        'message': message,

    }
    // inject opr key to avoid any malicious call (weak but some security)
    // return guarded ? Object.assign({}, mandatoryTokenHeader, mandatoryUserHeader, mandatoryClientHeader)
    //     : Object.assign({}, mandatoryTokenHeader, mandatoryClientHeader);

    // inject opr key to avoid any malicious call (weak but some security)
    return guarded ? Object.assign({}, mandatoryTokenHeader, mandatoryUserHeader, mandatoryJSONHeader, mandatoryClientHeader, mandatoryDatabaseHeader)
        : Object.assign({}, mandatoryTokenHeader, mandatoryJSONHeader, mandatoryClientHeader, mandatoryDatabaseHeader, user_session_id, __header___);
};



export const getMandatoryRequestHeadersForMultipart = (guarded) => {
    const mandatoryUserHeader = getUserHeader();
    const mandatoryTokenHeader = getTokenHeader(guarded);
    const mandatoryClientHeader = getClientId();

    // inject opr key to avoid any malicious call (weak but some security)
    // return guarded ? Object.assign({}, mandatoryTokenHeader, mandatoryUserHeader, mandatoryClientHeader)
    //     : Object.assign({}, mandatoryTokenHeader, mandatoryClientHeader);

    // inject opr key to avoid any malicious call (weak but some security)
    return guarded ? Object.assign({}, mandatoryTokenHeader, mandatoryUserHeader, mandatoryClientHeader)
        : Object.assign({}, mandatoryTokenHeader, mandatoryClientHeader);
};


/**
 * 
 * @param {*} headers 
 * 
 * @returns includes the headers to a header object,
 */
export const includeMandatoryRequestHeaders = headers => {
    const newHeaderObject = Object.assign({}, headers);
    return Object.assign(newHeaderObject, getMandatoryRequestHeaders());
};


export const validateEmailFormat = email => {
    // eslint-disable-next-line
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
};


export const validateNumber = number => {
    return !isNaN(number);
};


export const validateDate = date => {
    return !isNaN(Date.parse(date)) && Date.parse(date) > -19800000 && Date.parse(date) < 1893456000000;
};


/**
 * 
 * @param {*} password the password which needs to be validated
 * 
 * returns a boolean confirming if the supplied password is a valid password or not. Ideally, the password should be minimum 8 characters and should consist 1 capital case, 1 lowe case letter, 1 number, and 1 special character
 */
export const validatePassword = password => {
    const regex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    return password.length > 7 && regex.test(String(password).toLowerCase());
};


/**
 * 
 * @param {*} file file will be the object retreived from the input type = file tag
 * 
 * returns if the selected file is a valid image or not
 */
export const isValidImage = file => {
    const typeArray = file.type.split('/');
    return typeArray.length === 2 && typeArray[0] === 'image';
};


/**
 * 
 * @param {*} file file will be the object retreived from the input type = file tag
 * 
 * returns the extension of the file
 */
export const getExtension = file => {
    const lastDotIndex = file.name.lastIndexOf('.');
    return file.name.substring(lastDotIndex + 1);
};


export const generateError = (message, code, title) => {
    const error = new Error(message);
    error.code = code;
    error.title = title;

    return error;
};


/**
 * 
 * @param {*} prefix the prefix to be attached to the unique key
 * 
 * returns a 8 digit unique key
 */
export const generateUniqueKey = prefix => {
    if (prefix) {
        return prefix + '_' + (new Date().getTime()).toString(36) + (new Date().getTime() + Math.floor(Math.random() * 100000)).toString(36);
    }
    else {
        return (new Date().getTime()).toString(36) + (new Date().getTime() + Math.floor(Math.random() * 100000)).toString(36);
    }
};

let runWhich = -1;

export function debounceWrapper(func, delay, id) {
    let timer;
    runWhich++;
    let index = runWhich;

    return function () {
        clearTimeout(timer);

        timer = setTimeout(() => {
            if (index === runWhich) {
                func.apply(this, arguments);
                runWhich = -1;
            }
        }, delay);
    }
};

export const debouncev1 = (func, delay) => {
    let timer;
    return (...args) => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            func(...args);
        }, delay);
    };
};


/* Save Recent Color Cookie in the Session */
export const saveRecentColorInSession = colors => {
    const colorsString = JSON.stringify(colors);
    sessionManager.saveInSession(constants.SESSION_KEYS.RECENT_COLORS, colorsString);
};

export const showLoader = () => {
    store.dispatch({
        type: LOADER_SHOW
    });
};

export const killLoader = () => {
    store.dispatch({
        type: LOADER_HIDE
    });
};

export const showSnackbar = (data) => {
    store.dispatch({
        type: SHOW_SUCCESS,
        data: data
    })
}

export const showFileDownloadingStatus = (data) => {
    store.dispatch({
        type: SHOW_FILE_DOWNLOADING_STATUS,
        data: data
    })
}


export const hideFileDownloadingStatus = () => {
    store.dispatch({
        type: HIDE_FILE_DOWNLOADING_STATUS,
    })
}


export const hideSnackbar = () => {
    store.dispatch({
        type: HIDE_SUCCESS,
    })
}


const return_date_string_from_generic_date_v1 = (g_object) => {

    const single_date = g_object?.['single_date'];
    const start_date = g_object?.['date_from'];
    const end_date = g_object?.['date_to'];
    const month = g_object?.['month'];
    const year = g_object?.['year'];
    const type = g_object?.['type'];

    if (month > -1 && year && type === 'month') {
        // if we have month and year then create new nf_date
        return [MONTHS[month] + " " + year]
    }
    if (year && type === 'year') {
        // if we have month and year then create new nf_date
        return [year]
    }
    else if (start_date && end_date) {
        return [start_date + " To " + end_date]
    }
    else if (single_date) {
        var d = formatValueByDataType(single_date, 4)
        return [d]
    }
    return undefined;
}



export const get_filter_str_for_print = (filters) => {

    let _f1_text = '';
    let _f2_text = '';

    filters && Object.keys(filters).length > 0 && Object.keys(filters).forEach(key => {
        if (key
            && (
                key.toLocaleLowerCase() === 'creator designation' ||
                key.toLocaleLowerCase() === 'creator_designation' ||
                key.toLocaleLowerCase() === 'creator designations' ||
                key.toLocaleLowerCase() === 'creator_designations'
            )
        ) return;

        if (key === 'date_filter') {

            const date_filters = filters[key];
            date_filters && Object.keys(date_filters).length > 0 && Object.keys(date_filters).forEach(d => {
                if (d === 'generic_date') {
                    const d_key = return_date_string_from_generic_date_v1(date_filters[d])
                    if (d_key && d_key !== 'undefined' && d_key !== null) {
                        _f1_text += d_key;
                    }
                }
                if (d !== 'generic_date') {
                    const type = date_filters[d].type;
                    if (type && type !== '') {
                        let str = ''
                        if (date_filters[d]['type'] && date_filters[d]['type'] === 'custom_date') {
                            str = date_filters[d]['dateFrom'] + " To " + date_filters[d]['dateTo']
                        } else {
                            str = _.startCase(_.toLower(date_filters[d]['type']))
                        }
                        if (str && str.length > 0) {
                            _f1_text = _f1_text ? _f1_text + ', ' + str : str
                        }
                    }
                }

            })

        }

        if (key !== 'reportId' && key !== 'date_filter') {
            if (key.toLocaleLowerCase() === 'month') {
                let str = typeof filters[key] !== 'undefined' && filters[key] && (Array.isArray(filters[key]) && filters[key].length > 0) ? filters[key].join(', ') : '';
                _f1_text = _f1_text ? _f1_text + ', ' + str : str

            }
            else {
                let str = typeof filters[key] !== 'undefined' && filters[key] && (Array.isArray(filters[key]) && filters[key].length > 0) ? filters[key].join(', ') : ''
                _f2_text = _f2_text ? _f2_text + ', ' + str : str
            }
        }
    })

    return {
        f1: _f1_text,
        f2: _f2_text
    }
}




const send_insight_email = (filename, xyz) => {
    const EMAIL_URL = 'http://127.0.0.1:10990/send';


    const email_Data = {
        to: ["vikas@newfangled.io", 'saroj.k@newfangled.io'],
        cc: undefined,
        subject: new Date() + "Insight Mail Alert",
        html: "<h1>Insight Mail Alert " + `${filename} and time is ${new Date()} || ${xyz}` + "</h1>",
        attachments: undefined,
        source: "alerts@newfangled.io"
    };

    console.log("=========email_Data========", email_Data)


    try {

        const result = fetch(EMAIL_URL, {
            method: 'POST',
            body: JSON.stringify(email_Data)
        });

        console.log('email sent: ', result);

    }
    catch (error) {

        console.log("error while sending emailsss...")
    };
}


function chunkArray(arr, chunkSize) {
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        result.push(arr.slice(i, i + chunkSize));
    }
    return result;
}



const get_formated_data_for_pdf = (data, columnsAligments, key, is_total) => {

    const aligment = columnsAligments && columnsAligments[key];
    const cell_data_type = aligment?.['type'] // (aligment_of_column && Object.keys(aligment_of_column).length > 0) ? aligment_of_column['type'] : undefined;
    const currency_type = aligment?.['currency_type'];
    const num_format_type = aligment?.['num_format_type'];
    const use_decimal = aligment?.['use_decimal'];
    const use_percent = aligment?.['use_percent'];
    const other_format_config = aligment?.["other_format_config"];

    if (is_total && use_percent) {
        return ''
    }
    else {
        return formatValueByDataType(data, cell_data_type, 'table', undefined, undefined, undefined, currency_type, num_format_type, use_decimal, use_percent, other_format_config, 'PDF');
    }
}



export const generatePDFFromElement = async (elementId, pdfTitle = "Document", downloadOnly = true, extraData = {}) => {
    const element = document.getElementById(elementId);

    if (!element) {
        console.error(`Element with ID "${elementId}" not found!`);
        return null;
    }

    // Capture the element as a canvas
    const canvas = await html2canvas(element, {
        scale: 1, // Lower scale to reduce resolution and file size
        allowTaint: true,
        useCORS: true,
    });
    const imgData = canvas.toDataURL("image/jpeg", 0.7); // Use JPEG format with 70% quality to reduce size
    const pdf = new jsPDF("p", "mm", "a4");
    const padding = 10;

    const pdfWidth = pdf.internal.pageSize.getWidth() - padding * 2;
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    const pageHeight = pdf.internal.pageSize.getHeight() - padding * 2;

    let y = 20; // Initial y position on the PDF page

    // Set title to bold and center
    pdf.setFont("helvetica", "bold");
    const titleWidth = pdf.getTextWidth(pdfTitle);
    const titleX = (pdf.internal.pageSize.getWidth() - titleWidth) / 2;
    pdf.text(pdfTitle, titleX, 15);

    if (pdfHeight <= pageHeight) {
        // If the content fits on one page, add the image as-is
        pdf.addImage(imgData, "PNG", padding, y, pdfWidth, pdfHeight);
    } else {
        // If the content is larger than one page, split across pages
        let remainingHeight = pdfHeight;

        while (remainingHeight > 0) {
            const heightToRender = Math.min(remainingHeight, pageHeight);

            // Add the portion of the image to the page
            pdf.addImage(imgData, "PNG", padding, y, pdfWidth, heightToRender, undefined, 'FAST');

            remainingHeight -= heightToRender;

            if (remainingHeight > 0) {
                pdf.addPage();
                y = 10; // Reset y position for the next page
            }
        }
    }

    if (downloadOnly) {
        // Download the PDF
        pdf.save(`${pdfTitle}.pdf`);
        return null; // No need to return anything if downloaded
    } else {
        // Return PDF as Blob for further use
        const arrayBuffer = pdf.output("arraybuffer");
        const pdfBlob = new Blob([arrayBuffer], { type: "application/pdf" });
        return pdfBlob;
    }


};





export const generatePdf = (
    type,
    renderType,
    title,
    element_id_to_print,
    isDashboard,
    __file_name__,
    add_top_offset,
    xDataKeys,
    yDataKeys,
    tableData,
    _headers,
    columnsAligments,
    filters,
    grandTotalColumns,
    page_height,
    is_auto_print = false
) => {

    const client_id = sessionManager.getFromSession(constants.SESSION_KEYS.CLIENT_ID);

    try {

        const filename = __file_name__ ? __file_name__ + ".pdf" : ('nf_report_' + new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString() + '.pdf');
        const id = element_id_to_print ? element_id_to_print : (type === 'table' ? 'tableReport' : 'chart');

        const is_dashboard_print = (page_height && id === "inner_wrapper");

        console.log("page_height",)

        const filter_str_object = filters ? get_filter_str_for_print(filters) : undefined;
        const targetElement = document.getElementById(id);

        console.log("targetElement", targetElement)
        if (targetElement) targetElement.style.backgroundColor = "#fff";
        let current_date = new Date();
        let element_offset_height = targetElement.offsetHeight;
        let totalHeight = is_dashboard_print ? (Math.ceil((element_offset_height / page_height)) * 2480) : targetElement.offsetHeight;

        let OPTIONS = {
            'jsPDF': {
                'orientation': 'l',
                'unit': 'px',
                'format': 'a4',
                'putOnlyUsedFonts': true,
            },
        }

        if (is_dashboard_print) {
            OPTIONS.jsPDF.format = [3508, 2480]
        }

        var pdf = new jsPDF(OPTIONS.jsPDF.orientation, OPTIONS.jsPDF.unit, OPTIONS.jsPDF.format, true);


        const removeLastPage = (doc) => {
            const totalPages = doc.internal.getNumberOfPages();
            if (totalPages > 1) {
                // Create a new jsPDF instance
                const newDoc = new jsPDF();

                // Copy content from all pages except the last one
                for (let i = 1; i < totalPages; i++) {
                    doc.setPage(i);
                    const pageContent = doc.output('datauristring', { page: i });

                    // Add a new page in the new document if it's not the first page
                    if (i > 1) newDoc.addPage();
                    newDoc.setPage(i);

                    // Copy text content (simplified example)
                    // You can add more content types (images, shapes) as needed
                    newDoc.text(pageContent, 10, 10);
                }

                return newDoc;
            }
            return doc; // Return the original doc if there's only one page
        };



        pdf.setFont('verdana');
        pdf.setFontSize(10);

        const pdfWidth = (is_dashboard_print ? 3508 : pdf.internal.pageSize.getWidth()) || 1175 //fallack;
        const pdfHeight = (is_dashboard_print ? 2480 : pdf.internal.pageSize.getHeight()) || 1500 //fallback;

        html2canvas(targetElement, {
            scale: is_dashboard_print ? 2 : undefined,
            ignoreElements: (element) => {
                if (element.id === 'printHide') {
                    return true;
                }
                if (element.id === 'footer_ignore_in_print') {
                    return true;
                }

                if (element.id === 'hide_filter_from_print') {
                    return true;
                }

                if (element.id === 'filterHide') {
                    element.style.display = 'none';
                }
                if (element.id === 'filterShow') {
                    element.style.display = 'inline-block';
                    return false;
                }
                if (element.id == 'bread_crumb_wrapper') {
                    return true;
                }
                if (element.id === 'header_right') {
                    return true;
                }

            }

        }).then((canvas) => {


            const widthRatio = pdfWidth / (canvas.width) || 0.36540364583333335 //fallback width ratio;
            const heightRatio = pdfHeight / (canvas.height);
            const sX = 0;
            const sWidth = canvas.width || 1175// fallback width;

            const sHeight = is_dashboard_print ?
                (page_height * 2) :
                (pdfHeight + ((pdfHeight - pdfHeight * widthRatio) / widthRatio))



            const dX = 0;
            let dY = add_top_offset ? add_top_offset : 0;

            const dWidth = sWidth;
            const dHeight = sHeight;
            let pageCnt = 1;
            const __logo__ = logos[client_id];
            const __logo_path__ = `./${__logo__}`


            const style_config_1 = {
                footer_title_font: 50,
                logo: { width: 300, height: 70 },
                pdf_title_font: 80,
                pdf_title_y: 60,

            }
            const style_config_2 = {
                footer_title_font: 9,
                logo: { width: 70, height: 20 },
                pdf_title_font: 10,
                pdf_title_y: 20,
            }

            const config_v1 = is_dashboard_print ? style_config_1 : style_config_2;

            if (type === 'chart') {


                pdf.setFont('Helvetica-Bold', 'bold');
                pdf.setFontSize(config_v1?.pdf_title_font);

                let _print_title = title ? title.toLocaleUpperCase() : "Dashboard";

                pdf.text(_print_title, (pdfWidth / 2), config_v1?.pdf_title_y, { align: 'center' });

                while ((totalHeight - (isDashboard ? 1000 : 300)) > 0) {

                    const margin = is_dashboard_print ? ((sHeight / 100) * 3.5) : 35;

                    const top_margin = (pageCnt === 1) ? margin : 0
                    const pdf_y = top_margin;
                    const s_height_v1 = sHeight;
                    totalHeight -= s_height_v1;
                    let sY = s_height_v1 * (pageCnt - 1);

                    const childCanvas = document.createElement('CANVAS');
                    childCanvas.setAttribute('width', sWidth);
                    childCanvas.setAttribute('height', s_height_v1);

                    const childCanvasCtx = childCanvas.getContext('2d');

                    childCanvasCtx.drawImage(canvas, sX, sY, sWidth, s_height_v1, dX, dY, dWidth, s_height_v1);
                    if (pageCnt > 1) {
                        pdf.addPage();
                    }
                    pdf.setPage(pageCnt);

                    const canvas_to_print = childCanvas.toDataURL('image/png')
                    const page_height = is_dashboard_print ? 2480 : 0;
                    pdf.addImage(canvas_to_print, 'PNG', 1, pdf_y, (canvas.width) * widthRatio, page_height, undefined, 'FAST');
                    pageCnt++;

                }

                pdf.setFont('Helvetica-Regular', 'regular');
                pdf.setFontSize(config_v1?.footer_title_font);
                pdf.text(("Printed at:- " + convert_date_to_dd_mm_yyyy_v1(current_date, true)), 10, pdfHeight - (config_v1?.logo?.height || 0) / 2)
                var img = new Image(config_v1?.logo?.width, config_v1?.logo?.height);
                img.src = './logo.png';
                pdf.addImage(img, 'JPEG', (pdfWidth - config_v1?.logo.width), pdfHeight - (config_v1?.logo?.height || 0 * 2), config_v1?.logo?.width, config_v1?.logo?.height, 'FAST');


            }


            if (type === 'table') {

                var tableWrapper = document.getElementById('tableReport');
                var clonedDiv = tableWrapper.cloneNode(true);

                var img = new Image(10, 10);
                img.src = __logo_path__;
                pdf.addImage(img, 'JPEG', 10, 2, 20, 20);

                var tempRemove = Array.prototype.slice.call(clonedDiv.getElementsByClassName('removed_while_loading'));

                if (tempRemove && tempRemove.length > 0) {
                    for (let i = 0; i < tempRemove.length; i++) {
                        tempRemove[i].remove()
                    }
                }


                /// CODE START FROM HERE OF TABLE

                const chunk_size = 5;
                const actual_headers = xDataKeys.concat(yDataKeys);

                const final_headers = chunkArray(actual_headers, chunk_size)


                for (let index = 0; index < final_headers.length; index++) {

                    const _header = []
                    final_headers[index] && final_headers[index].length > 0 && final_headers[index].map((k) => {
                        _header.push({
                            key: k,
                            displayKey: k && k.split("_nfx_").join(" ")
                        })
                    });

                    const data_length = (tableData && tableData.length > 1000) ? 1000 : tableData.length;
                    let final_data = [];

                    const _print_grand_total = [
                        {
                            content: 'Grand Total',
                            styles: { fillColor: ['#ffecb3'], height: '40px', textColor: '#000' }
                        }
                    ];



                    for (let index = 0; index < data_length; index++) {

                        const element = tableData[index];

                        if (!element[TABLE_CONFIG.group_row_identifier]) {

                            let d_object = {};
                            let value_exist = true;

                            _header && _header.length > 0 && _header.map((d) => {
                                const value = get_formated_data_for_pdf(element[d.key], columnsAligments, d.key) //formatValueByDataType(finalDatatoShow, cell_data_type, 'table', undefined, undefined, undefined, currency_type, num_format_type, use_decimal, use_percent, other_format_config, 'PDF');
                                d_object[d.key] = (value + '') === '0' ? '' : value;
                            })
                            // value_exist means if any column have  then row will be print 
                            if (value_exist) final_data.push(d_object)
                        }
                    }


                    _header && _header.length > 0 && _header.forEach((d, i) => {

                        if (i !== 0) {

                            const aligment = columnsAligments && columnsAligments[d.key];
                            const data_type = aligment && aligment.type;
                            const temp_value = (d.key && grandTotalColumns && Object.keys(grandTotalColumns).length > 0 && grandTotalColumns[d.key]) ? grandTotalColumns[d.key] : '---'
                            const _value = (data_type && temp_value) ? get_formated_data_for_pdf(temp_value, columnsAligments, d.key, true) : temp_value;
                            _print_grand_total.push({
                                content: _value,
                                styles: { fillColor: ['#ffecb3'], height: '40px', textColor: '#000' }
                            })
                        }
                    })


                    var body = [...final_data.map(el => {
                        let d_array = [];
                        Object.keys(el).forEach(key => d_array.push(el[key]))
                        return d_array
                    }),
                        _print_grand_total
                    ]


                    if (index === 0) {


                        const pageHeight = pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
                        const pageWidth = pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();

                        let _print_title = title;

                        if (filter_str_object?.f1) {
                            _print_title = _print_title + ' for ' + filter_str_object?.f1;
                        }
                        if (filter_str_object?.f2) {
                            _print_title = _print_title + ' - ' + filter_str_object?.f2;
                        }

                        pdf.setFont('Helvetica-Bold', 'bold');
                        pdf.setFontSize(15);
                        pdf.setFillColor('#6333')
                        pdf.text((_print_title || 'Title'), pageWidth / 2, 15, { align: 'center', maxWidth: 200 });

                    }

                    console.log("final_headers", _header, body)

                    if (index > 0) {
                        pdf.addPage();
                    }

                    pdf.autoTable({
                        headStyles: {
                            fontSize: 10, fillColor: '#3a9bd0', textColor: '#fff'
                        },
                        bodyStyles: { fontSize: 9 },
                        alternateRowStyles: { fillColor: '#fff', },
                        head: [_header && _header.length > 0 && _header.map((h) => h.displayKey)],
                        body: body,
                        startY: 30
                    });
                }

            }
            showFileDownloadingStatus({
                title: "File Download SuccessFully",
                image: './upload.loader.gif'
            })
            if (renderType === 'download') {
                if (clonedDiv)
                    clonedDiv.remove();
                pdf.save(filename, { returnPromise: true }).then(() => {

                    if (is_auto_print) {
                        // send_insight_email(filename)
                    }
                    setTimeout(() => {
                        hideFileDownloadingStatus()
                    }, 1000);
                });
            }
            else {
                pdf.autoPrint();
                window.open(pdf.output('bloburl'), '_blank');
            }

            if (targetElement) targetElement.style.backgroundColor = null;
            document.getElementById("filterShow") && (document.getElementById("filterShow").style.display = 'none');
            document.getElementById("filterHide") && (document.getElementById("filterHide").style.display = 'inline-block');

        });


    } catch (error) {
        showSnackbar({
            type: "error",
            message: error.message ? error.message : "Somthing went wrong."
        })
        setTimeout(() => {
            hideSnackbar()
        }, 2000);
    }
};


/**
 * The algo goes like this:
 *  1. Check if value is undefined / null
 *  2. Check if value is number
 *  3. Check if value is date 
 *  4. Check if value is object
 *  5. If not, value is definitely a string
 * @param {any} value 
 */

export const getDataType = value => {

    if (typeof value === 'undefined' || value === null) {
        return dataTypes.none;
    }

    else if (validateNumber(value)) {
        return dataTypes.number;
    }

    else if (validateDate(value)) {
        return dataTypes.date;
    }

    else if (typeof value === 'object') {
        return dataTypes.object;
    }

    else {
        return dataTypes.string;
    }
};

export const giveFilters = (key, value, question, filters) => {
    const old_value = filters ? filters[key] : undefined;

    const regex_string = `(\\b${old_value}\\b)`;
    const regex = new RegExp(regex_string);
    let new_question = undefined;
    let new_filters = filters ? { ...filters } : {};
    const filter_length = filters ? Object.keys(filters).length : undefined;

    if (((!old_value && !filter_length) || question.indexOf(' where'))) {
        new_question = question + ' where ' + key.toLowerCase() + ' is ' + value;
        new_filters[key] = value;
    }
    else if (!old_value && filter_length > 0) {
        new_question = question + ' and ' + key.toLowerCase() + ' is ' + value;
        new_filters[key] = value;
    }
    else {
        new_question = question.replace(regex, value);
        new_filters[key] = value;
    }

    return {
        new_filters,
        new_question
    }
}

export const getDateFilterFromSession = () => {
    const result = sessionManager.getFromSession(constants.SESSION_KEYS.DATE_FILTER);

    return result ? JSON.parse(result) : undefined
};

export const saveDateFilterInSession = filters => {
    const dateFilters = JSON.stringify(filters);

    sessionManager.saveInSession(constants.SESSION_KEYS.DATE_FILTER, dateFilters);
};

export const legendChange = (yDataKeys, xDataKeys) => {
    /**
     * we need to find out if we need to split data for columns
     */

    const ifHyphenExists = yDataKeys.reduce((acc, yDataKey) => acc = acc + (yDataKey.indexOf('-') > -1 ? 1 : 0), 0);
    let howManyRowsForHeader = 0;

    const key_occurences = {};

    if (ifHyphenExists > 0 && ifHyphenExists > (yDataKeys.length - 5)) {
        // hyphen exists. let's split the data
        yDataKeys.forEach(yDataKey => {
            const result = yDataKey.split('-');

            if (result.length > howManyRowsForHeader) howManyRowsForHeader = result.length;

            result.forEach((columnHeader, index) => {
                if (typeof key_occurences[index] === 'undefined') {
                    key_occurences[index] = {};
                }

                key_occurences[index][columnHeader] = (key_occurences[index][columnHeader] || 0) + 1;
            });
        });

        let keys_to_loop = Array.from(Object.keys(key_occurences));

        keys_to_loop.forEach(key => {
            const inside_keys_to_loop = Array.from(Object.keys(key_occurences[key]));

            if (inside_keys_to_loop.length === 1) {
                // ok, this is only one column. We can get rid of it
                delete key_occurences[key];
            }
        });

        // now let's sort the keys

        const remaining_keys = Array.from(Object.keys(key_occurences));

        remaining_keys.sort((a, b) => {
            const object_a_length = Object.keys(key_occurences[a]).length;
            const object_b_length = Object.keys(key_occurences[b]).length;

            return object_a_length - object_b_length;
        });

        const new_data_to_render = [];

        yDataKeys.forEach((yDataKey, yIndex) => {
            const result = yDataKey.split('-');
            result.forEach((columnHeader, index) => {
                if (typeof new_data_to_render[index] === 'undefined') new_data_to_render[index] = [];
                if (remaining_keys.indexOf('' + index) > -1) {
                    // ok, we got the index, it means we need to render this
                    new_data_to_render[index].push(columnHeader);
                }
            });

        });

        const a_headers = [];

        new_data_to_render.forEach((yColumnData, index) => {
            if (yColumnData.length > 0) {
                const headerRow = [];
                if (index === 0) {
                    headerRow.push(...xDataKeys);
                }
                else {
                    headerRow.push(...xDataKeys.map(x => ''));
                }

                headerRow.push(...yColumnData);

                a_headers.push(headerRow);
            }
        });
    }
}

export const find_hint_in_array = (word, hints, hints_array) => {
    /* 
        * Removing the unnecessary letters in the word.
    */
    // eslint-disable-next-line
    const invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g;
    const removed_invalid_words = word
    // const removed_invalid_words = word.replace(invalid, '');


    try {

        if (removed_invalid_words && removed_invalid_words.length > 0) {
            const word_in_lower_case = removed_invalid_words.toLowerCase();

            /* 
                * regex_array is important because we defining in what order values are pushed in the temp_hints.
                * Ist Priority :- Full Word => 'This'
                * 2nd Priority :- Sentence Start with word and it is Full => 'This is me'
                * 3rd Priority :- Sentence Start with word => 'Thishsd me'
                * 4th Priority :- Word can be anywhere in sentence or even in word => 'Hello this is me'
            */

            const regex_array = [`\\b${word_in_lower_case}\\b`, `^${word_in_lower_case}\\b`, `^${word_in_lower_case}`, `${word_in_lower_case}`];
            const temp_hints = [];

            regex_array && regex_array.forEach(regex_string => {
                const regex = new RegExp(regex_string);

                hints_array && hints_array.length > 0 && hints_array.forEach(hint => {
                    if (hint && regex.test(hint.toLowerCase()) && temp_hints.indexOf(hint) === -1) {
                        temp_hints.push(hint.trim())
                    }
                })
            });

            return temp_hints
        }
    } catch (e) {
        console.log('error in hints regex: ', e);
    }

    return []
}





/***
 * 1. question
 * 2. scheduleReportId
 * 3. reportId
 * 4. reportItemId
 * 5. reportType
 * 6. filters
 * 7. history,
 * 8. isInsights
 * 9. dbInfoId
 * 10. parameterized_values
 * 
 */

export const _getDataFromReportingServer = (

    question,
    scheduleReportId,
    reportId,
    reportItemId,
    reportType,
    filters = {},
    history,
    isInsights,
    dbInfoId,
    parameterized_values,
    report_filter_default_values,
    is_pinned_question

) => {

    let location = history?.location
    let pathname = history?.location?.pathname;

    if ((pathname !== '/analytics_map') && !isInsights) {
        let string = '';
        const question_encoded = encodeURIComponent(question && question.trim());
        const id = reportId ? reportId : generate_unique_key("gen");
        string = string + `/dashboard?question=${question_encoded}&report_id=${id}&db_info_id=${dbInfoId}`;
        if (scheduleReportId) {
            string = string + `&schedule_report_id=${scheduleReportId}&report_type=${reportType}`
        }
        if (is_pinned_question) {
            string = string + `&is_pinned_question=true`

        }
        if (parameterized_values) {
            string = string + `&parameterized_values=${JSON.stringify(parameterized_values)}`
        }
        if (report_filter_default_values && Object.keys(report_filter_default_values)?.length > 0) {
            string = string + `&filter_default_values=${JSON.stringify(report_filter_default_values || {})}`
        }

        history?.push(string);
    }
};


export const _getDataFromReportingServer1 = (
    question,
    history,
    filters = {},
    filterFunction,
    scheduleReportId,
    reportIndex,
    reportId,
    isInsights,
    report_type,
    db_info_id,
    process_question,
    report_filters,
    __redirect_filter__,
    open_new_tab,
    parameterized_values
) => {
    let { location } = history && history;
    let { pathname } = location && location;

    if ((pathname !== '/analytics_map') && !isInsights) {
        let string = '';
        const report_id = scheduleReportId;
        const id = generate_unique_key();

        let tempIndex = reportIndex ? reportIndex : '0'

        string = string + `/dashboard?question=${question && question.trim()}&report_id=${report_id}&id=${id}&reportIndex=${tempIndex}&db_info_id=${db_info_id}&process_question=${process_question}&redirect_filters=${__redirect_filter__}`;

        if (scheduleReportId) {
            string = string + `&srId=${scheduleReportId}&report_type=${report_type}&report_filters=${report_filters}`
        }

        if (parameterized_values) {
            string = string + `&parameterized_values=${JSON.stringify(parameterized_values)}`
        }

        if (open_new_tab) {
            window.open(string, '_blank')
        }

        if (!open_new_tab) {
            if (filterFunction) {
                filters.reportId = id;
                if ((filters && !filters.date_filter)) {
                }
                else {
                    if (filters && Object.keys(filters.date_filter).length === 0) {
                        delete filters.date_filter;
                    }
                }
                filterFunction(filters);
            }
            history.push(string);
        }

    } else {

        const id = reportId ? reportId : generate_unique_key();
        if (filterFunction) {
            filters.reportId = id;

            if ((filters && !filters.date_filter)) {
            }
            else {
                if (filters && Object.keys(filters.date_filter).length === 0) {
                    delete filters.date_filter;
                }
            }
            filterFunction(filters);
        }
    }
};

const short_ids = [];

export const generate_unique_key = prefix => {
    // if (prefix) {
    //     return prefix + '_' + (new Date().getTime()).toString(36) + (new Date().getTime() + Math.floor(Math.random() * 100000)).toString(36);
    // }
    // else {
    //     return (new Date().getTime()).toString(36) + (new Date().getTime() + Math.floor(Math.random() * 100000)).toString(36);
    // }


    if (short_ids.length < 100) {
        Array(10000).fill(0).forEach((_, index) => short_ids.push((new Date().getTime() + index).toString(36)));
    }

    const id = short_ids.shift();

    if (prefix) {
        return prefix + '_' + id;
    }
    else {
        return id;
    }
};

export const is_value_exist_in_array = (array, key, value) => {
    let exist = false;
    if (array && array.length > 0 && key && value) {
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            if (element[key] === value) {
                exist = true;
                break;
            }

        }
    }
    return exist
}


export const find_data_type_in_config_meta_by_display_key = (d_key, config_meta) => {

    // const keys = config_meta && Object.keys(config_meta) || [];

    // for (let index = 0; index < keys.length; index++) {
    //     const key = keys[index];
    //     if((config_meta[key] && config_meta[key].display_key && config_meta[key].display_key.toLocaleLowerCase()) === (key && key.toLocaleUpperCase())){
    //         return config_meta[key].data_type;
    //     }

    // }

}

export const showPopup = (title, message, type, element, data, top, left, overlay_color, other_properties, disable_overlay_click, hide_header) => {
    helper.showPopup(store.dispatch, title, message, type, element, data, top, left, overlay_color, other_properties, disable_overlay_click, hide_header);
};

export const showSuccess = (data) => {
    helper.showPopup(store.dispatch, data);
};

export const hideLastPopup = () => {
    helper.hideLastPopup(store.dispatch)
}
export const showRighter = element => {
    helper.showRighter(store.dispatch, element);
};

export const hideRighter = () => {
    helper.hideRighter(store.dispatch);
};


export const showLefter = element => {
    helper.showLefter(store.dispatch, element);
};

export const hideLefter = () => {
    helper.hideLefter(store.dispatch);
};

export const hideOverlay = func => {
    helper.hideOverlay(store.dispatch, func);
};

export const raiseOverlay = func => {
    helper.raiseOverlay(store.dispatch, func);
};

export const saveRecentMenu = object => {
    helper.saveRecentOpenMenu(store.dispatch, object);
};

export const saveWhichTableDropDown = data => {
    helper.saveWhichTableDropDown(store.dispatch, data);
};

export const saveRecentMainMenu = data => {
    helper.saveRecentOpenMainMenu(store.dispatch, data);
};

export const _getTokens = options => {
    getTokens(options)(store.dispatch);
};

export const killUser = (history, dispatchUserInfo, resetStore) => {
    dispatchUserInfo(undefined);
    sessionManager.saveUser({});

    if (resetStore) resetStore();

    history.push('/');
};


export const getParameterByName = (name, url) => {

    if (!url) url = window.location.href;

    // eslint-disable-next-line
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';

    const str_to_use = results[2].replace(/\+/g, ' ');
    // this is a patch code we have only added for chart_type

    // if(name === 'chartType'){
    //     return str_to_use;
    // }

    const d = decodeURIComponent(str_to_use);

    return d
}




export const getTableAndChartHeight = () => {
    const innerWidth = window.innerWidth;

    if (innerWidth > 768 && innerWidth < 1300) {
        return {
            tableHeight: 240,
            chartHeight: 280
        }
    }
    else if (innerWidth >= 1300 && innerWidth < 1500) {
        return {
            tableHeight: 240,
            chartHeight: 280
        }
    }
    else if (innerWidth >= 1500 && innerWidth < 1800) {
        return {
            tableHeight: 230,
            chartHeight: 290
        }
    }
    else if (innerWidth >= 1800) {
        return {
            tableHeight: 220,
            chartHeight: 290
        }
    }
    else {
        return {
            tableHeight: 150,
            chartHeight: 150
        }
    }
};

export const insertAtSpecificLocationInArray = (arr, index, newItem) => [
    ...arr.slice(0, index),

    newItem,

    ...arr.slice(index)
];


export const is_logged_in = async () => {
    const loginKeyFromEnv = process.env.REACT_APP_DEV_LOGIN_KEY;

    if (loginKeyFromEnv === constants.LOGIN_DEV_KEY || process.env.REACT_APP_APP_MODE === 'DPAAS') {
        const nf = await import('../client_info/nf');
        return nf.is_logged_in();
    }
    else {
        switch (constants.CLIENT_ID) {
            case 'kx.mis':
                const kx = await import('../client_info/kx');
                return kx.is_logged_in();

            default: break;
        };

        return false;
    }
};

export const remove_logged_in_from_session = () => {
    localStorage.removeItem(constants.SESSION_KEYS.LOGGED_USER);
    localStorage.removeItem(constants.SESSION_KEYS.OPR_DATA);
    localStorage.removeItem(constants.SESSION_KEYS.LOGGED_USER_EMAIL);
    localStorage.removeItem(constants.SESSION_KEYS.TOKEN);
    localStorage.removeItem(constants.SESSION_KEYS.NF_TOKEN);
    localStorage.removeItem(constants.SESSION_KEYS.REFRESH_TOKEN);
    localStorage.removeItem(constants.SESSION_KEYS.USER_ID);
    localStorage.removeItem(constants.SESSION_KEYS.PASSWORD_TOKEN);
    localStorage.removeItem(constants.SESSION_KEYS.RECENT_COLORS);
    localStorage.removeItem(constants.SESSION_KEYS.USER_NAME);
    localStorage.removeItem(constants.SESSION_KEYS.DATABASE_ID);
    localStorage.removeItem(constants.SESSION_KEYS.CLIENTS);
    localStorage.removeItem(constants.SESSION_KEYS.PERMISSIONS);
    localStorage.removeItem('currentUser');
    localStorage.removeItem(constants.SESSION_KEYS.CLIENT_ID);
    localStorage.removeItem(constants.SESSION_KEYS.REDIRECT_TO_DB_LIST);



}


export const _whatToDoIfTokenNotFound = (reload = false) => {
    const workarounds = client_specific_workarounds;

    if (workarounds && workarounds.whatToDoIfTokenNotFound) {
        workarounds.whatToDoIfTokenNotFound()
    }
    else if (workarounds && workarounds.redirectToLogin) {
        workarounds.redirectToLogin();
    }
    else {
        remove_logged_in_from_session()
        reload && window.location.reload();
    }
};

export const makeCopyReport = report => {


    var stored_union_ids = {};


    const get_new_union_ids = (id) => {

        const new_connected_ids_tag = generateUniqueKey("union_clone_new")

        if (!stored_union_ids[id]) {
            stored_union_ids[id] = new_connected_ids_tag;
            return new_connected_ids_tag

        }
        return stored_union_ids[id];

    }

    let tempCopiedReport = JSON.parse(JSON.stringify(report));


    if (tempCopiedReport.name) {
        tempCopiedReport.name = 'copy ' + tempCopiedReport.name;
    }

    if (tempCopiedReport.title) {
        tempCopiedReport.title = 'copy ' + tempCopiedReport.title;
    }

    delete tempCopiedReport.id;
    delete tempCopiedReport.created_at;
    delete tempCopiedReport.created_by;
    delete tempCopiedReport.modified_at;
    delete tempCopiedReport.modified_by;

    // let is_union_report = tempCopiedReport.report_items.length > 0 && tempCopiedReport.report_items.some((item) => item.connected_ids_tag);

    // let connected_ids_tag = generateUniqueKey("union_clone")

    if (tempCopiedReport.report_items && tempCopiedReport.report_items.length > 0) {

        for (let i = 0; i < tempCopiedReport.report_items.length; i++) {

            if (tempCopiedReport.report_items[i].title) {
                tempCopiedReport.report_items[i].title = tempCopiedReport.report_items[i].title;
            }

            if ((tempCopiedReport.report_items[i].connected_ids_tag)) {
                tempCopiedReport.report_items[i].connected_ids_tag = get_new_union_ids(tempCopiedReport.report_items[i].connected_ids_tag);
            }
            else {
                delete tempCopiedReport.report_items[i].connected_ids_tag;
            }

            tempCopiedReport.report_items[i].xac = [];
            tempCopiedReport.report_items[i].yac = [];
            delete tempCopiedReport.report_items[i].id;
            delete tempCopiedReport.report_items[i].report_id;
            delete tempCopiedReport.report_items[i].created_at;
            delete tempCopiedReport.report_items[i].created_by;
            delete tempCopiedReport.report_items[i].modified_at;
            delete tempCopiedReport.report_items[i].modified_by;

            // column infos
            for (let c = 0; c < tempCopiedReport.report_items[i]['column_infos'].length; c++) {

                delete tempCopiedReport.report_items[i]['column_infos'][c]['report_id'];
                delete tempCopiedReport.report_items[i]['column_infos'][c]['report_item_id'];
                delete tempCopiedReport.report_items[i]['column_infos'][c]['id'];
                delete tempCopiedReport.report_items[i]['column_infos'][c]['created_at'];
                delete tempCopiedReport.report_items[i]['column_infos'][c]['created_by'];
                delete tempCopiedReport.report_items[i]['column_infos'][c]['modified_at'];
                delete tempCopiedReport.report_items[i]['column_infos'][c]['modified_by'];

                // let remove from nf_formula_conditions
                for (let f = 0; f < tempCopiedReport.report_items[i]['column_infos'][c].nf_formula_conditions.length; f++) {

                    delete tempCopiedReport.report_items[i]['column_infos'][c]['nf_formula_conditions'][f]['id'];
                    delete tempCopiedReport.report_items[i]['column_infos'][c]['nf_formula_conditions'][f]['column_info_id'];
                    delete tempCopiedReport.report_items[i]['column_infos'][c]['nf_formula_conditions'][f]['report_item_id'];
                    delete tempCopiedReport.report_items[i]['column_infos'][c]['nf_formula_conditions'][f]['created_at'];
                    delete tempCopiedReport.report_items[i]['column_infos'][c]['nf_formula_conditions'][f]['created_by'];
                    delete tempCopiedReport.report_items[i]['column_infos'][c]['nf_formula_conditions'][f]['modified_at'];
                    delete tempCopiedReport.report_items[i]['column_infos'][c]['nf_formula_conditions'][f]['modified_by'];
                }
            }
        }
    }

    return tempCopiedReport;
};



export const deleteReportFormat = report => {
    const tempReport = JSON.parse(JSON.stringify(report));

    tempReport.deleted = true;

    if (tempReport.report_items && tempReport.report_items.length > 0) {
        tempReport.report_items.forEach(reportItem => {
            reportItem['deleted'] = true;
        })
    }

    return tempReport;
};

export const reportFormatToSave = report => {
    const tempReport = JSON.parse(JSON.stringify(report));
    const dataToSave = [];

    dataToSave.push(tempReport);

    return dataToSave;
};

export const giveErrorTypeForInsights = (processData, columnMeta, error, question) => {

    if (error) {
        return 'error'
    }
    else {
        if ((!processData || (processData && processData.length === 0)) && !columnMeta && question && question.length > 0) {
            return 'loading'
        }
        else if ((processData && processData.length > 0 && columnMeta)) {
            const checkingFirstObject = processData[0];

            if (checkingFirstObject && Object.keys(checkingFirstObject).length === 0) {
                return 'no data'
            }
        }
        else if (!question || (question && question.length === 0)) {
            return 'no data'
        }
    }


};

export const editedColumnsPlacement = (value, editColumns, columns) => {
    const temp = Object.assign({}, editColumns);
    let tempColumns = [...columns];

    const showNow = temp[value]['show'];

    if (!showNow) {
        temp[value]['show'] = true;

        const ori = Object.keys(temp);

        let before = undefined;
        let after = undefined;
        const startIndex = ori.indexOf(value);

        for (let i = startIndex - 1; i < ori.length; i++) {
            if (tempColumns.indexOf(ori[i]) > -1 && i === startIndex - 1) {
                before = i;
            }
            else if (tempColumns.indexOf(ori[i]) > -1) {
                after = i;
                break;
            }
        };

        const indexInTemp = tempColumns.indexOf(ori[after]);

        tempColumns = insertAtSpecificLocationInArray(tempColumns, after ? indexInTemp : before + 1, value);
    }
    else {
        temp[value]['show'] = false;
        const indexOf = tempColumns.indexOf(value);
        _.pullAt(tempColumns, indexOf);
    }

    return {
        editColumns: temp,
        columns: tempColumns
    }
};


export function convertToMilliseconds(__value = 5, __unit = 'minutes') {
    // Define the number of milliseconds in each unit
    const msInSecond = 1000;
    const msInMinute = msInSecond * 60;
    const msInHour = msInMinute * 60;
    const msInDay = msInHour * 24;
    const msInWeek = msInDay * 7;
    const msInMonth = msInDay * 30.44;
    const msInYear = msInDay * 365.25;
    const msInQuarter = msInMonth * 3;

    var unit = __unit;
    var value = __value;
    if (unit === "undefined" || unit === '') {
        unit = "minute"
    }

    if (value === "undefined" || value === '') {
        value = 5
    }

    console.log(value, unit)
    // Determine the conversion based on the unit
    switch (unit.toLowerCase()) {
        case 'second':
        case 'seconds':
            return value * msInSecond;
        case 'minute':
        case 'minutes':
            return value * msInMinute;
        case 'hour':
        case 'hourly':
            return value * msInHour;
        case 'day':
        case 'daily':
            return value * msInDay;
        case 'weekly':
        case 'weeks':
            return value * msInWeek;
        case 'month':
        case 'monthly':
            return value * msInMonth;
        case 'year':
        case 'yearly':
            return value * msInYear;
        case 'quarter':
        case 'quarterly':
            return value * msInQuarter;
        default:
            return ''
    }
}




export const splitTableData = (data = [], fullData, __limit__) => {

    const limit = constants.TABLE_LIMIT;

    let activeData = [...data];
    let temp = [...fullData];
    let sliced_array = [];

    if (fullData && fullData.length > 0) {
        sliced_array = temp.splice(0, limit);
        temp = [...temp];
    }

    sliced_array = activeData ? [...activeData, ...sliced_array] : [...sliced_array];

    return {
        data: sliced_array,
        extraData: temp
    }
};

export const removeElementFromArray = (index, value, arr) => {
    const tempArr = [...arr];

    if (value && value.length > 0) {
        const valueIndex = tempArr.indexOf(value);

        tempArr.splice(valueIndex, 1);
    }
    else {
        tempArr.splice(index, 1)
    }

    return tempArr;

};


export const todayDateTemplate = () => {
    return {
        dateFrom: -1,
        dateTo: -1,
        type: "today",
        value: ""
    }
};

export const isSingleDataEmpty = data => {
    if (data && data.length === 1) {
        if (data && Object.keys(data[data.length - 1] === 0)) {
            return true
        }
        else {
            return false;
        }
    }

    return false;
};

export const getParamByName = (search, key) => {
    let params = new URLSearchParams(search);
    return params.get(key);
};



// export const getParamByName = (search, key) => {
//     let __search__ = search.trim();

//     let return_me = undefined;;

//     if (search[0] === '?') __search__ = search.substring(1);

//     const all_params = __search__.split('&');

//     all_params.forEach(param => {
//         const splitter = param.split('=');
//         if (splitter.length === 2 && splitter[0].toLocaleLowerCase() === key.toLocaleLowerCase()) {
//             return_me = splitter[1];
//         }
//     });

//     return return_me;
// };


export const isDate = (s) => {
    if (isNaN(s) && !isNaN(Date.parse(s)))
        return true;
    else return false;
};




export const get_pivot_formatted_axis = (__data__, pivot_columns_state) => {

    // .filter((v) => pivot_columns_state?.[v]?.['show'] === true) || []
    const xac = ((__data__ && __data__?.xac && __data__?.xac.length > 0) ? __data__.xac.map((r) => (r.pivot_field_column_name)) : []).filter((v) => pivot_columns_state?.[v]?.['show'] === true) || []
    const yac = ((__data__ && __data__?.yac && __data__?.yac.length > 0) ? __data__.yac.filter((v) => (v.visible_type !== 0)).map((r) => r.pivot_field_alias ? r.pivot_field_alias.split('_nfrptr_').join(' ') : r.pivot_field_column_name) : []).filter((v) => pivot_columns_state?.[v]?.['show'] === true) || []

    return {
        xac: xac || [],
        yac: yac || []
    }

}


export const get_pivot_formatted_axis_chart_types = (key, __data__, pivot_columns_state, prev_chart_type, insightChartType, chart_Type) => {



    if (key === "chart_types") {
        // const __d = (__data__ && __data__.yac && __data__.yac.length > 0)
        //     ? __data__.yac
        //         .filter(v => v.visible_type !== 0)
        //         .filter(v => pivot_columns_state?.[v.pivot_field_alias ? v.pivot_field_alias.split('_nfrptr_').join(' ') : v.pivot_field_column_name]?.['show'] === true)
        //         .map(c => (prev_chart_type !== insightChartType ? insightChartType : c.chart_type))
        //     : [];


        // return __d
        const __d = (__data__ && __data__.yac && __data__.yac.length > 0)
            ? __data__.yac
                .filter(v => v.visible_type !== 0)
                .filter(v => pivot_columns_state?.[v.pivot_field_alias ? v.pivot_field_alias.split('_nfrptr_').join(' ') : v.pivot_field_column_name]?.['show'] === true)
                .map(c => {
                    const alias = c.pivot_field_alias ? c.pivot_field_alias.split('_nfrptr_').join(' ') : c.pivot_field_column_name;
                    return chart_Type === "ogive_chart" ? "line_chart" : alias === 'Percentage Change' ? 'line_chart' : (prev_chart_type !== insightChartType ? insightChartType : c.chart_type);
                })
            : [];

        return __d;

    }
    else {
        const __d = (__data__ && __data__.yac && __data__.yac.length > 0)
            ? __data__.yac
                .filter(v => v.visible_type !== 0)
                .filter(v => pivot_columns_state?.[v.pivot_field_alias ? v.pivot_field_alias.split('_nfrptr_').join(' ') : v.pivot_field_column_name]?.['show'] === true)
                .map(c => c.axis || 'primary')
            : [];

        return __d
    }

}





export const get_raw_data_axis = (xac, yac, raw_data_column_state) => {
    return {
        xac: xac?.filter((k => raw_data_column_state?.[k]?.['show'])) || [],
        yac: yac?.filter((k => raw_data_column_state?.[k]?.['show'])) || []
    }
};


export const get_chart_type_and_other_config_data = (
    data,
    scheduleReportId,
    currentState,
    sort_data_or_not = true,
    forceRenderOneTime,
    is_insights,
    set_table_data_mode,
    tableDataMode
) => {


    const tempData = JSON.parse(JSON.stringify(data));

    let reportType = tempData.report_type;
    const error = tempData.error;
    const force_render_mode = tempData.force_render_mode;
    const urlChartType = getParameterByName('chartType');
    const urlRenderMode = getParameterByName('renderMode');
    const schedule_report_id = getParameterByName('schedule_report_id')
    const configMeta = tempData ? tempData.config_meta : {};
    const is_details = tempData && tempData.is_details;

    const pivot_data_information = data?.pivot_data_information;
    const raw_data_information = data?.raw_data_information;
    const report_item = tempData?.config_meta || undefined;
    const responseId = tempData ? tempData.response_session_id : undefined;
    const sqlQuery = tempData ? tempData.sql_query : undefined;

    const pivot_axis = get_pivot_formatted_axis(pivot_data_information?.pivot_data_axis);

    let xDataKeys = configMeta && configMeta?.xac;
    let yDataKeys = configMeta && configMeta.yac && configMeta.yac;

    let renderMode = tempData.config_meta && tempData.config_meta.render_mode;
    let chartType = tempData.config_meta && tempData.config_meta.chart_type;
    let singleCellData = undefined;
    let showSingleCell = false;
    let title = configMeta ? configMeta.title : 'Polus - Next Gen BI';
    let csvHeader = [];
    const theme_json_values = report_item?.theme_json_values;

    csvHeader = getCsvHeader(xDataKeys, yDataKeys);

    const data_for_use = pivot_data_information?.data?.length > 0 ? pivot_data_information?.data : raw_data_information?.data;
    const processData = raw_data_information?.data;

    const yaxis = pivot_axis?.yac?.length > 0 ? pivot_axis?.yac : yDataKeys;

    if (data_for_use && data_for_use.length === 1 && (Object.keys(data_for_use[data_for_use.length - 1]).length <= 2 || yaxis.length <= 2)) {

        renderMode = 'table';
        showSingleCell = true;
        showSingleCell = (() => {
            // if chart is single_cell then we will not check anything we will keep it is as a single_cell
            if (chartType === 'single_cell') {
                return true;
            }

            else {

                const unique_column_key = [];

                data_for_use && Object.keys(data_for_use[0])?.map((v) => {
                    const n_v = v && v.split("_nf")[0];
                    if (unique_column_key.indexOf(n_v) == -1) unique_column_key.push(n_v);
                })

                if ((data_for_use && data_for_use.length === 1)) {
                    const first_item = Object.keys(data_for_use[0]);
                    if (data_for_use[0][first_item[0]] && data_for_use[0][first_item[0]] === 'No Values Found') {
                        return false;
                    }
                    if (processData && processData.length === 1 && Object.keys(processData[0]).length > 2) {
                        return false;
                    }
                    else if (data_for_use && data_for_use.length === 1 && unique_column_key.length > 2) {
                        return false;
                    }

                    else {
                        return true;
                    }
                }
                return true;
            }



        })()
        singleCellData = data_for_use[data_for_use.length - 1];
        yDataKeys = configMeta.yac;
    }
    else {

        if (forceRenderOneTime) {
            renderMode = forceRenderOneTime
        }
        if (tempData && configMeta) {
            /* 
                * If tempData has comparison_keys as key, They must be yDataKeys else one should pick the yDataKeys from the configMeta Object. 
            */
            if (tempData.comparison_keys && tempData.comparison_keys.length > 0) {
                // yDataKeys = tempData.comparison_keys;
                // groupedData = groupTabularData(data_for_use, xDataKeys, yDataKeys);

                /**
                 * reform keys to include total columns
                 */

                const unique_keys = {};
                const should_push_total = (() => {
                    let return_value = false;
                    const keys = {};
                    tempData.comparison_keys.filter(key => !!key).forEach((key, index) => {
                        const splitter = key.split('_nfx_');

                        if (index > 0) {
                            if (typeof keys[splitter[0]] === 'undefined') {
                                // found provision for total
                                return_value = true;
                            }
                        }

                        keys[splitter[0]] = 1;
                    });

                    return return_value;
                })();

                if (renderMode === 'table') {
                    should_push_total && tempData.comparison_keys.forEach(key => {
                        const splitter = key.split('_nfx_');
                        const last_item = splitter && splitter[1];
                        const actual_key = 'total_nfx_' + splitter[1];
                        if (last_item && typeof unique_keys[actual_key] === 'undefined') {
                            yDataKeys.push(actual_key);
                            unique_keys[actual_key] = 1;
                        }
                    });
                }
            }
            else if (configMeta.yac) {
                if (configMeta.xac.length === 0) {
                    // if we have come here, it means we have 
                    // row(s) with data, but no x data key
                    configMeta.xac.push('Value');
                    data_for_use.forEach(data => {
                        data['Value'] = 'Value';
                    })

                }
            }
        }

        showSingleCell = false;
    }

    if (!pivot_axis?.yac || pivot_axis?.yac.length === 0 && is_insights) {
        renderMode = "table"
        chartType = "table"
    }




    return {
        chartType: urlChartType && urlChartType.length > 0 ? urlChartType : chartType,
        renderMode: urlRenderMode && urlRenderMode.length > 0 ? urlRenderMode : renderMode,
        is_details,
        report_item,
        responseId,
        showSingleCell,
        singleCellData,
        title,
        csvHeader,
        sqlQuery,
        reportType,
        theme_json_values,
        pivot_axis
    }
}



// 'pivot_data'
export const return_required_state_for_report_wrapper_v1 = (data, scheduleReportId, currentState, sort_data_or_not = true, forceRenderOneTime, is_insights, set_table_data_mode, tableDataMode) => {



    console_logger("return_required_state_for_report_wrapper_v1 INIT")
    const tempData = JSON.parse(JSON.stringify(data));
    let reportType = tempData.report_type;

    const error = tempData.error;
    const force_render_mode = tempData.force_render_mode;
    const urlChartType = getParameterByName('chartType');
    const urlRenderMode = getParameterByName('renderMode');
    const schedule_report_id = getParameterByName('schedule_report_id')
    let processData = tempData ? tempData.process_data : undefined;
    const configMeta = tempData ? tempData.config_meta : {};
    let xDataKeys = configMeta && configMeta?.xac;

    const sqlQuery = tempData ? tempData.sql_query : undefined;
    const reportId = tempData ? tempData.reportId : undefined;
    const responseId = tempData ? tempData.response_session_id : undefined;
    const isSchedule = scheduleReportId ? true : undefined;
    let columnMeta = tempData ? tempData.column_meta_data : undefined;
    const comparisonKeys = tempData ? tempData.comparison_keys : undefined;
    const chartXDataKeys = configMeta && configMeta?.chart_xdata_keys;
    const chartYDataKeys = configMeta && configMeta?.chart_ydata_keys;
    const comparisons = configMeta && configMeta?.comparisons;
    const parentReport = configMeta && configMeta?.report_id;
    const dbType = tempData ? tempData.db_type : undefined;
    const trendFilters = tempData ? tempData.trend_filters : undefined;
    const hide_columns = tempData ? tempData.hide_columns : undefined;
    const is_details = tempData && tempData.is_details;
    const ultimate_yacs = tempData && tempData.ultimate_yacs;
    const pivot_data_ultimate_yacs = tempData && tempData.pivot_data_ultimate_yacs || {};
    const aggregate_values = tempData?.aggregate_values


    let sortedData = [];
    let csvHeader = [];
    let yDataKeys = configMeta && configMeta.yac && configMeta.yac;
    let renderMode = tempData.config_meta && tempData.config_meta.render_mode;
    let chartType = tempData.config_meta && tempData.config_meta.chart_type;
    let singleCellData = undefined;
    let showSingleCell = false;
    let title = configMeta ? configMeta.title : 'Polus - Next Gen BI';
    let groupedData = undefined;
    let grandTotalColumns = {};

    let report_item_id = tempData?.config_meta?.id;
    const report_item = tempData?.config_meta || undefined;
    const process_data_updated_at = tempData?.process_data_updated_at;
    const tbl_ui_formulas = report_item?.tbl_ui_formulas;
    const theme_json_values = report_item?.theme_json_values;
    const report_formula = ((tempData?.config_meta?.column_infos || []).filter((f => f?.is_formula)) || []).sort((a, b) => a.order - b.order);

    const report_formula_for_grand_total = report_formula?.filter((f) => f.grand_total_value_type === "formula") || [];
    const hide_column_from_grand_total = ((report_formula?.filter((f) => !f.grand_total_value_type) || []).map(a => a.alias)) || [];

    const raw_data_grand_total = {};
    const pivot_data_grand_total = {}
    const pivot_xac_indexes_count = tempData?.pivot_xac_indexes_count;

    const column_row_wise_data_types = tempData?.column_row_wise_data_types;

    // console.log("column_row_wise_data_types_utils_v1.1", tempData)


    // if (tempData?.raw_data_grand_sum_total?.length > 0) {
    //     raw_data_grand_total["grand_total_sum_column"] = tempData?.raw_data_grand_sum_total[0];
    // }

    // if (tempData?.pivot_data_grand_sum_total?.length > 0) {
    //     pivot_data_grand_total["grand_total_sum_column"] = tempData?.pivot_data_grand_sum_total[0];
    // }


    /***
     * here we are writing Pivot Data 
     */

    const raw_data_column_state = tempData?.raw_data_column_state || {};
    const disabled_to_call_single_cell = tempData?.disabled_to_call_single_cell || (schedule_report_id !== "undefined" && schedule_report_id !== null && schedule_report_id?.length > 0) ? true : false;


    const pivot_data = tempData && tempData?.pivot_data?.length > 0 ? [...(tempData.pivot_data || [])] : [];

    const pivot_columns_state = tempData?.pivot_columns_state;
    // column_row_wise_data_types

    const pivot_data_axis = tempData && tempData?.pivot_data_axis && { ...(tempData.pivot_data_axis || {}) };

    const pivot_xac = ((pivot_data_axis && pivot_data_axis?.xac && pivot_data_axis?.xac.length > 0) ? pivot_data_axis.xac.map((r) => (r.pivot_field_column_name)) : []).filter((v) => pivot_columns_state?.[v]?.['show'] === true) || []
    let pivot_yac = ((pivot_data_axis && pivot_data_axis?.yac && pivot_data_axis?.yac.length > 0) ? pivot_data_axis.yac.filter((v) => (v.visible_type !== 0)).map((r) => r.pivot_field_alias ? r.pivot_field_alias.split('_nfrptr_').join(' ') : r.pivot_field_column_name) : []).filter((v) => pivot_columns_state?.[v]?.['show'] === true) || []

    const pivot_target_yac = ((pivot_data_axis && pivot_data_axis?.yac && pivot_data_axis?.yac.length > 0) ? pivot_data_axis.yac.filter((v) => (v.visible_type !== 0 && v.is_gauge_trend)).map((r) => r.pivot_field_alias ? r.pivot_field_alias.split('_nfrptr_').join(' ') : r.pivot_field_column_name) : []).filter((v) => pivot_columns_state?.[v]?.['show'] === true) || []

    const d_chart_types = ['bar_chart', 'line_chart', 'area_chart']

    const chart_type_to_use = (urlChartType && urlChartType.length > 0 ? urlChartType : chartType) || 'bar_chart'
    const yac_chart_types = (pivot_data_axis && pivot_data_axis?.yac && pivot_data_axis?.yac?.length > 0) ? pivot_data_axis.yac.filter((v) => (v.visible_type !== 0)).map((r) => (d_chart_types.indexOf(r.chart_type) > -1 ? r.chart_type : 'bar_chart') || (['bar_chart', 'line_chart', 'area_chart'].indexOf(chart_type_to_use) > -1 ? chart_type_to_use : 'bar_chart')) : [];

    if (!is_insights) {
        yac_chart_types.map((c, index) => {
            yac_chart_types[index] = chart_type_to_use || 'bar_chart'
        })
    }

    const yac_axises = (pivot_data_axis && pivot_data_axis?.yac && pivot_data_axis.yac.length > 0) ? pivot_data_axis.yac.filter((v) => (v.visible_type !== 0)).map((r) => r.axis || 'primary') : [];

    let pivot_yac_info = (pivot_data_axis && pivot_data_axis?.yac && pivot_data_axis?.yac?.length > 0) ? pivot_data_axis.yac.filter((v) => (v.visible_type < 3)) : [];
    const pivot_data_columns = (pivot_data_axis && pivot_data_axis?.pivot_data_columns?.length > 0 && pivot_data_axis?.pivot_data_columns.map(c => c.pivot_field_column_name)) || []

    const temp_yac_totals = tempData?.yac_totals;

    // console.log("pivot_data_axis", pivot_data_axis)
    // add this code cuz we nedd yac_totals in pivot alias
    const yac_totals = {}
    pivot_data_axis?.yac?.length > 0 && pivot_data_axis?.yac?.forEach((y) => {
        if (temp_yac_totals && y.pivot_field_column_name && (temp_yac_totals[y.pivot_field_column_name] || temp_yac_totals[y.pivot_field_alias])) {
            yac_totals[y.pivot_field_alias] = (temp_yac_totals[y.pivot_field_column_name] || temp_yac_totals[y.pivot_field_alias])
        }
    })






    const data_for_use = pivot_data?.length > 0 ? pivot_data : processData;

    const yaxis = pivot_yac?.length > 0 ? pivot_yac : yDataKeys;


    if (!disabled_to_call_single_cell) {

        if (data_for_use && data_for_use.length === 1 && (Object.keys(data_for_use[data_for_use.length - 1]).length <= 2 || yaxis.length <= 2)) {

            renderMode = 'table';
            showSingleCell = true;

            showSingleCell = (() => {

                // if chart is single_cell then we will not check anything we will keep it is as a single_cell
                if (chartType === 'single_cell') {
                    return true;
                }

                else {

                    const unique_column_key = []

                    data_for_use && Object.keys(data_for_use[0])?.map((v) => {

                        const n_v = v && v.split("_nf")[0];

                        if (unique_column_key.indexOf(n_v) == -1) unique_column_key.push(n_v);
                    })


                    if ((data_for_use && data_for_use.length === 1)) {

                        const first_item = Object.keys(data_for_use[0]);

                        if (data_for_use[0][first_item[0]] && data_for_use[0][first_item[0]] === 'No Values Found') {
                            return false;
                        }
                        if (processData && processData.length === 1 && Object.keys(processData[0]).length > 2) {
                            return false;
                        }
                        else if (data_for_use && data_for_use.length === 1 && unique_column_key.length > 2) {
                            return false;
                        }

                        else {
                            return true;
                        }
                    }
                    return true;
                }



            })()
            singleCellData = data_for_use[data_for_use.length - 1];
            yDataKeys = configMeta.yac;
        }
        else {

            if (forceRenderOneTime) {
                renderMode = forceRenderOneTime
            }
            if (tempData && configMeta) {
                /* 
                    * If tempData has comparison_keys as key, They must be yDataKeys else one should pick the yDataKeys from the configMeta Object. 
                */
                if (tempData.comparison_keys && tempData.comparison_keys.length > 0) {
                    // yDataKeys = tempData.comparison_keys;
                    // groupedData = groupTabularData(data_for_use, xDataKeys, yDataKeys);

                    /**
                     * reform keys to include total columns
                     */

                    const unique_keys = {};
                    const should_push_total = (() => {
                        let return_value = false;
                        const keys = {};
                        tempData.comparison_keys.filter(key => !!key).forEach((key, index) => {
                            const splitter = key.split('_nfx_');

                            if (index > 0) {
                                if (typeof keys[splitter[0]] === 'undefined') {
                                    // found provision for total
                                    return_value = true;
                                }
                            }

                            keys[splitter[0]] = 1;
                        });

                        return return_value;
                    })();

                    if (renderMode === 'table') {
                        should_push_total && tempData.comparison_keys.forEach(key => {
                            const splitter = key.split('_nfx_');
                            const last_item = splitter && splitter[1];
                            const actual_key = 'total_nfx_' + splitter[1];
                            if (last_item && typeof unique_keys[actual_key] === 'undefined') {
                                yDataKeys.push(actual_key);
                                unique_keys[actual_key] = 1;
                            }
                        });
                    }
                }
                else if (configMeta.yac) {
                    // yDataKeys = configMeta.yac;

                    if (configMeta.xac.length === 0) {
                        // if we have come here, it means we have 
                        // row(s) with data, but no x data key
                        configMeta.xac.push('Value');
                        data_for_use.forEach(data => {
                            data['Value'] = 'Value';
                        })

                    }
                }
            }

            showSingleCell = false;
        }
    }



    sortedData = processData;
    csvHeader = getCsvHeader(xDataKeys, yDataKeys);




    // #
    // this block is only for pivot 
    // values column 
    pivot_yac?.length > 0 && pivot_yac.forEach((y) => {

        if (!pivot_data_ultimate_yacs || !pivot_data_ultimate_yacs[y]) {
            pivot_data_ultimate_yacs[y] = {
                type: dataTypes.number,
            }
        }

        if (pivot_data_ultimate_yacs && pivot_data_ultimate_yacs?.[y]) {

            const config = pivot_data_ultimate_yacs?.[y];

            if (
                config?.type === dataTypes.number ||
                config?.type === dataTypes.currency ||
                config?.type === dataTypes.percent ||
                config?.type === dataTypes.number_with_decimal
            ) {
                // do noting 
            } else {
                // add number dataType default
                pivot_data_ultimate_yacs[y].type = dataTypes.number
            }
        }

    })



    /**
     * grand total for raw data
     */
    sortedData && sortedData.length > 0 && sortedData.forEach((d) => {

        if (!d[TABLE_CONFIG.group_row_identifier]) {
            d && Object.keys(d).length > 0 && Object.keys(d).forEach(key => {
                if (hide_column_from_grand_total.indexOf(key) == -1) {
                    if ((hide_column_from_grand_total.indexOf(key) == -1) && !raw_data_grand_total["grand_total_sum_column"]) raw_data_grand_total["grand_total_sum_column"] = {};
                    if (ultimate_yacs?.[key] && (ultimate_yacs[key].type === DataType.number || ultimate_yacs[key].type === DataType.number_with_decimal || ultimate_yacs[key].type === DataType.currency)) {
                        if (!raw_data_grand_total["grand_total_sum_column"][key]) raw_data_grand_total["grand_total_sum_column"][key] = 0
                        if (raw_data_grand_total["grand_total_sum_column"][key] !== undefined) {
                            const __v__ = d[key] || 0;
                            raw_data_grand_total["grand_total_sum_column"][key] += (!isNaN(__v__) ? (typeof parseFloat(__v__) === 'number') ? parseFloat(__v__) : 0 : 0)
                        }
                    }
                }

            })
        }
    })


    // here we are calculation pivot data grand total 
    pivot_data && pivot_data.length > 0 && pivot_data.forEach((d) => {
        if (!d[TABLE_CONFIG.group_row_identifier]) {
            d && Object.keys(d).length > 0 && Object.keys(d).forEach(key => {
                if (pivot_xac.indexOf(key) == -1) {
                    if (hide_column_from_grand_total.indexOf(key) == -1) {
                        if (!pivot_data_grand_total["grand_total_sum_column"]) pivot_data_grand_total["grand_total_sum_column"] = {}
                        if (

                            pivot_data_ultimate_yacs[key] && !pivot_data_ultimate_yacs[key].hide_from_grand_total &&
                            (pivot_data_ultimate_yacs[key].type === DataType.number || pivot_data_ultimate_yacs[key].type === DataType.number_with_decimal || pivot_data_ultimate_yacs[key].type === DataType.currency)) {

                            if (pivot_data_grand_total["grand_total_sum_column"][key] === undefined) {
                                pivot_data_grand_total["grand_total_sum_column"][key] = 0;
                            }

                            if (pivot_data_grand_total["grand_total_sum_column"][key] !== undefined) {
                                pivot_data_grand_total["grand_total_sum_column"][key] += (d[key] !== null && d[key] !== 'undefined' && !isNaN(d[key]) ? (typeof parseFloat(d[key]) === 'number') ? parseFloat(d[key]) : 0 : 0)
                            }

                        }
                    }
                }


            })
        }
    })


    const new_raw_grand_total_sum_column = get_grand_total_with_formula(
        raw_data_grand_total["grand_total_sum_column"],
        yDataKeys,
        xDataKeys,
        report_formula_for_grand_total,
        columnMeta
    )

    if (new_raw_grand_total_sum_column) {
        raw_data_grand_total["grand_total_sum_column"] = new_raw_grand_total_sum_column?.[0]
    }


    const c_to_use = {
        ...(pivot_data_grand_total["grand_total_sum_column"] || {})

    }

    const new_grand_total_sum_column = get_grand_total_with_formula(
        c_to_use,
        pivot_yac,
        pivot_xac,
        report_formula_for_grand_total,
        columnMeta
    )

    if (new_grand_total_sum_column) {

        pivot_data_grand_total["grand_total_sum_column"] = new_grand_total_sum_column?.[0]
    }


    // here we will calculate the percentage of the grand total

    const grand_total_sum_column = pivot_data_grand_total && pivot_data_grand_total["grand_total_sum_column"];
    const formula_columns = tbl_ui_formulas?.length > 0 && tbl_ui_formulas.map((f) => f.name) || [];
    const total_data_key = grand_total_sum_column && Object.keys(grand_total_sum_column).find((k) => k && k.startsWith('Total_nfx_'));


    grand_total_sum_column && Object.keys(grand_total_sum_column).length > 0 && Object.keys(grand_total_sum_column).forEach(key => {
        // we will ignore the formula column for percentage
        if (formula_columns.indexOf(key) === -1) {
            const d = grand_total_sum_column[key];
            const total_d = grand_total_sum_column[total_data_key];
            const p = parseFloat(d) / parseFloat(total_d) * 100;
            if (!pivot_data_grand_total["grand_total_percentage_column"]) pivot_data_grand_total["grand_total_percentage_column"] = {}
            if (!pivot_data_grand_total["grand_total_percentage_column"][key]) {
                pivot_data_grand_total["grand_total_percentage_column"][key] = p || 100
            }
        }
    })


    // this the patch code of render table 
    if (!pivot_yac || pivot_yac.length === 0 && is_insights) {
        renderMode = "table"
        chartType = "table"
    }

    // here we are dispatching the pivot mode
    if (pivot_yac?.length > 0 && pivot_data?.length > 0 && !showSingleCell) {
        if (!tableDataMode) {
            set_table_data_mode("pivot_data", tempData.reportId)
        }

    }


    // temporery code added
    if (pivot_yac.length === 0 && pivot_xac.length === 0 && !showSingleCell) {
        // let's set raw data mode
        set_table_data_mode("raw_data", tempData.reportId)
    }


    // this is only for details
    if (is_details) {
        if (!tableDataMode) {
            set_table_data_mode("raw_data", tempData.reportId)
        }
    }

    const raw_data_y_axis = ((yDataKeys || []).filter((k => raw_data_column_state?.[k]?.['show']))) || [];
    const raw_data_x_axis = (xDataKeys || []).filter((k => raw_data_column_state?.[k]?.['show'])) || []


    // console.log("pivot_columns_state", pivot_columns_state)
    // let's add some other info into data formating object
    // console.log("pivot_data_ultimate_yacs", JSON.parse(JSON.stringify(pivot_data_ultimate_yacs)))

    const post_pivot_data_column_meta = pivot_data_ultimate_yacs ? { ...pivot_data_ultimate_yacs } : {};

    pivot_data_ultimate_yacs && Object.keys(pivot_data_ultimate_yacs).forEach((k) => {

        const use_percent = post_pivot_data_column_meta[k]?.type === dataTypes.percent;

        const num_format_type = 'crores';

        post_pivot_data_column_meta[k] = {
            ...post_pivot_data_column_meta[k],
            use_percent: use_percent,
            num_format_type: showSingleCell ? num_format_type : undefined
        }

        if (pivot_columns_state?.[k]?.['number_config']) {
            post_pivot_data_column_meta[k] = {
                ...post_pivot_data_column_meta[k],
                ...pivot_columns_state?.[k]?.['number_config'],
            }
        }
    })


    const post_raw_data_column_meta = ultimate_yacs ? { ...ultimate_yacs } : {};




    // columnMeta

    ultimate_yacs && Object.keys(ultimate_yacs).forEach((k) => {

        const use_percent = post_raw_data_column_meta[k]?.type === dataTypes.percent;
        post_raw_data_column_meta[k] = {
            ...post_raw_data_column_meta[k],
            use_percent: use_percent,

        }

        columnMeta && Object.keys(columnMeta).length > 0 && Object.keys(columnMeta).forEach((k) => {

            if (columnMeta[k] && columnMeta[k].data_type == dataTypes.string || columnMeta[k].data_type == dataTypes.date || columnMeta[k].data_type == dataTypes.date_time) {
                if (post_raw_data_column_meta[k]) {
                    post_raw_data_column_meta[k] = {
                        aligment: 'left',
                        type: columnMeta[k].data_type
                    }
                } else {
                    post_raw_data_column_meta[k] = {
                        aligment: 'left',
                        type: columnMeta[k].data_type
                    }
                }
            }
        })



        if (raw_data_column_state?.[k]?.['number_config']) {
            post_raw_data_column_meta[k] = {
                ...post_raw_data_column_meta[k],
                ...raw_data_column_state?.[k]?.['number_config'],
            }
        }
    })



    console_logger("return_required_state_for_report_wrapper_v1 DONE")


    // console.log("pivot_data_ultimate_yacs", pivot_data_ultimate_yacs)




    return {
        pivot_data_axis,
        pivot_target_yac,
        pivot_data,
        pivot_columns_state,
        raw_data_column_state,
        pivot_xac,
        pivot_yac,
        sortedData,
        yac_chart_types,
        pivot_yac_info,
        pivot_xac_info: pivot_data_axis?.xac,
        yac_axises,
        chartType: urlChartType && urlChartType.length > 0 ? urlChartType : chartType,
        showSingleCell,
        singleCellData,
        csvHeader,
        reportId,
        title,
        sqlQuery,
        renderMode: urlRenderMode && urlRenderMode.length > 0 ? urlRenderMode : renderMode,
        xDataKeys: raw_data_x_axis,
        yDataKeys: raw_data_y_axis,
        comparisons,
        reportType,
        responseId,
        columnMeta,
        comparisonKeys,
        isSchedule,
        chartYDataKeys,
        chartXDataKeys,
        parentReport,
        scheduleReportId,
        dbType,
        trendFilters,
        error,
        ultimate_yacs: post_raw_data_column_meta,
        columnsAligments: post_raw_data_column_meta,
        pivot_data_ultimate_yacs: post_pivot_data_column_meta,
        force_render_mode,
        is_details,
        grandTotalColumns,
        pivot_data_grand_total: pivot_data_grand_total,
        hide_columns,
        report_item_id,
        report_item,
        process_data_updated_at,
        raw_data_grand_total,
        pivot_data_columns,
        aggregate_values: aggregate_values,
        yac_totals,
        theme_json_values,
        pivot_xac_indexes_count,
        column_row_wise_data_types
    }
}




export const process_column_meta_and_data_type = (post_column_meta_data, pivotColumnsState, showSingleCell = false) => {

    const columnMeta = post_column_meta_data ? { ...post_column_meta_data } : {};

    post_column_meta_data && Object.keys(post_column_meta_data).forEach((key) => {

        var use_percent = columnMeta[key]?.type === dataTypes.percent;
        var use_decimal = 2//(columnMeta[key]?.type === dataTypes.percent || columnMeta[key]?.type === dataTypes.number_with_decimal) ? 2 : 0
        var numFormatType = 'crores';
        // if (process.env.REACT_APP_MODE === "hrpl") {
        //     use_decimal = 0;
        // }

        if (key === 'Ratio') {
            use_decimal = 2;
        }
        const pre_info = pivotColumnsState?.[key] || {};
        columnMeta[key] = {
            use_decimal,
            ...pre_info,
            ...columnMeta[key],
            use_percent,
            // num_format_type: showSingleCell ? numFormatType : undefined,
            num_format_type: numFormatType,

        };

        const numberConfig = pivotColumnsState?.[key]?.['number_config'];
        if (numberConfig) {
            columnMeta[key] = {
                use_decimal,
                ...columnMeta[key],
                ...numberConfig,
            };
        }
    });

    return columnMeta;
}



export const returnRequiredState = (data, scheduleReportId, currentState, sort_data_or_not = true, forceRenderOneTime, running_total, isGroupedData, dataMode = 'pivot_data') => {

    const tempData = JSON.parse(JSON.stringify(data));
    let reportType = tempData.report_type;
    const error = tempData.error;
    const force_render_mode = tempData.force_render_mode;
    const urlChartType = getParameterByName('chartType');
    const urlRenderMode = getParameterByName('renderMode');

    let processData = tempData ? tempData.process_data : undefined;
    const configMeta = tempData ? tempData.config_meta : {};
    let xDataKeys = configMeta && configMeta.xac;

    const pivot_data = tempData && { ...tempData.pivot_data };
    const pivot_data_axis = tempData && { ...tempData.pivot_data_axis };

    const sqlQuery = tempData ? tempData.sql_query : undefined;
    const mathsAggregateMeta = tempData ? tempData.maths_aggregate_result : undefined;
    const reportId = tempData ? tempData.reportId : undefined;
    const responseId = tempData ? tempData.response_session_id : undefined;
    const isSchedule = scheduleReportId ? true : undefined;
    let columnMeta = tempData ? tempData.column_meta_data : undefined;
    const comparisonKeys = tempData ? tempData.comparison_keys : undefined;
    const chartXDataKeys = configMeta && configMeta.chart_xdata_keys;
    const chartYDataKeys = configMeta && configMeta.chart_ydata_keys;
    const comparisons = configMeta && configMeta.comparisons;
    const parentReport = configMeta && configMeta.report_id;
    const dbType = tempData ? tempData.db_type : undefined;
    const trendFilters = tempData ? tempData.trend_filters : undefined;
    const valuesToRemove = tempData ? tempData.value_to_remove : undefined;
    const reportFormula = tempData ? tempData.report_formula : undefined;
    const hide_columns = tempData ? tempData.hide_columns : undefined;
    const is_details = tempData && tempData.is_details;

    const is_running_total = running_total ? running_total : configMeta && configMeta['is_running_total'];
    let sortedData = [];
    let csvHeader = [];
    let yDataKeys = configMeta && configMeta.yac && configMeta.yac;
    let renderMode = undefined;
    let chartType = undefined;
    let singleCellData = undefined;
    let showSingleCell = false;
    let title = configMeta ? configMeta.title : 'Polus - Next Gen BI';
    let groupedData = undefined;
    let columnsAligments = {}
    let grandTotalColumns = {};

    /* 
        * If processData having the length of one and have only one key, It means it is singleCellData.
        * This means no chart, table
        * Setting the state for show singleCell Data.
    */


    if (processData && processData.length === 1 && Object.keys(processData[processData.length - 1]).length <= 2) {
        renderMode = 'table';
        // if(Object.keys(processData[processData.length - 1]).length === 1){
        showSingleCell = true;
        showSingleCell = (() => {

            if ((processData && processData.length === 1)) {

                // ok we may need to show but lets check
                const first_item = Object.keys(processData[0]);
                console.log("Object.keys(processData[0])", Object.keys(processData[0]))
                if (processData[0][first_item[0]] && processData[0][first_item[0]] === 'No Values Found') {
                    return false;
                }
                if (processData[0] && Object.keys(processData[0])?.length >= 3) {
                    return false;
                }
                return true;
            }
            return true;
        })()


        singleCellData = processData[processData.length - 1];
        yDataKeys = configMeta.yac;
    }
    /* 
        * If Length is more than one than it is not of singleCell.
    */

    else {
        if (processData && processData.length >= 0) {

            /* 
                * As per discussion with Vikas Sir, We have lock the UI to table if xDataKeys are more than or equal to 2
                * If xDataKeys are less than 2 we can change the UI to different type of charts or table.
            */

            if (xDataKeys && xDataKeys.length >= 2) {
                renderMode = 'table';
                chartType = renderMode && renderMode === 'chart' ? 'bar' : 'table';
            }
            else {
                /* 
                    Using Last State To Check Render Mode
                */

                if (tempData && tempData.filters) {
                    renderMode = currentState.renderMode;
                    chartType = currentState.chartType;
                }
                else {
                    renderMode = configMeta ? configMeta[CHART_CONFIG.render_mode] : 'chart';
                    chartType = (configMeta && CHART_CONFIG && renderMode && renderMode === 'chart') ? configMeta[CHART_CONFIG.chart_type] : 'table';
                }
            }
        }

        if (forceRenderOneTime) {
            renderMode = forceRenderOneTime
        }



        if (tempData && configMeta) {
            /* 
                * If tempData has comparison_keys as key, They must be yDataKeys else one should pick the yDataKeys from the configMeta Object. 
            */

            if (tempData.comparison_keys && tempData.comparison_keys.length > 0 && isGroupedData) {
                yDataKeys = tempData.comparison_keys;
                groupedData = groupTabularData(processData, xDataKeys, yDataKeys);

                /**
                 * reform keys to include total columns
                 */


                const unique_keys = {};


                const should_push_total = (() => {
                    let return_value = false;
                    const keys = {};

                    tempData.comparison_keys.filter(key => !!key).forEach((key, index) => {
                        const splitter = key.split('_nfx_');

                        if (index > 0) {
                            if (typeof keys[splitter[0]] === 'undefined') {
                                // found provision for total
                                return_value = true;
                            }
                        }

                        keys[splitter[0]] = 1;
                    });

                    return return_value;
                })();

                if (renderMode === 'table') {
                    should_push_total && tempData.comparison_keys.forEach(key => {
                        const splitter = key.split('_nfx_');
                        const last_item = splitter && splitter[1];
                        const actual_key = 'total_nfx_' + splitter[1];
                        if (last_item && typeof unique_keys[actual_key] === 'undefined') {
                            yDataKeys.push(actual_key);
                            unique_keys[actual_key] = 1;
                        }
                    });
                }

            }
            else if (configMeta.yac) {
                yDataKeys = configMeta.yac;

                if (configMeta.xac.length === 0) {
                    // if we have come here, it means we have 
                    // row(s) with data, but no x data key
                    configMeta.xac.push('Value');
                    processData.forEach(data => {
                        data['Value'] = 'Value';
                    })

                }
            }
        }

        showSingleCell = false;
    }


    /* 
        * sortedData is the variable which is passed to both chart-wrapper and table-wrapper so it necessary to initialize in  parent component
        * csvHeader is used for react-csv library. It returns the data in format needed for react-csv
    */
    const whichDataToSort = (groupedData && Object.keys(groupedData).length > 0) ? Object.entries(groupedData).map(([key, value]) => value) : processData;

    if (pivot_data && pivot_data.length > 0 && dataMode === 'pivot_data') {
        sortedData = pivot_data;
        const pivot_data_xac = pivot_data_axis && [...pivot_data_axis.xac];
        const pivot_data_yac = pivot_data_axis && pivot_data_axis.yac && pivot_data_axis.yac.length > 0 && pivot_data_axis.yac.map((r) => r.value)
        yDataKeys = pivot_data_yac;
        xDataKeys = pivot_data_xac
    }


    if (dataMode !== 'pivot_data') {
        sortedData = processData;
        yDataKeys = configMeta && configMeta.yac && configMeta.yac;
        xDataKeys = configMeta && configMeta.xac && configMeta.xac;
    }


    const running_totals = {};

    const fetch_running_totals = sorted_data => {
        const xac = (xDataKeys || []).filter((_, index) => index < xDataKeys.length - 1);
        const yac = yDataKeys || [];
        const final_array = [];

        sorted_data && sorted_data.forEach((row, index) => {
            const row_clonned = JSON.parse(JSON.stringify(row));

            let key = undefined;

            if (!row['nf-reserved-row-group']) {
                xac && xac.forEach(x => {
                    key = key ? key + '__nf__' + row[x] : row[x];
                });

                if (typeof running_totals[key] === 'undefined') {
                    running_totals[key] = {};
                }
                yac && yac.forEach(y => {
                    if (typeof running_totals[key][y] === 'undefined') {
                        running_totals[key][y] = 0;
                    }
                    const this_data = parseFloat(row_clonned[y]);
                    row_clonned[y] = running_totals[key][y] + this_data;

                    running_totals[key][y] = row_clonned[y];
                });
            }
            final_array.push(row_clonned);
        });

        return final_array;
    };


    const list_with_running_total = is_running_total ? fetch_running_totals(sortedData) : {};

    if (is_running_total) {
        sortedData = list_with_running_total;
    }

    /* 
        Patch for formulas
    */
    const formulaMeta = tempData['formula_meta'];

    let mainFormulas = undefined;


    if (valuesToRemove && valuesToRemove.length > 0 && yDataKeys.length > 1) {
        xDataKeys && xDataKeys.length > 0 && xDataKeys.forEach((x, index) => {
            if (valuesToRemove.indexOf(x) > -1) {
                xDataKeys.splice(index, 1);
            }
        });
    }


    // if (mathsAggregateMeta && Object.keys(mathsAggregateMeta).length > 0) {
    //     Object.keys(mathsAggregateMeta).forEach(key => {
    //         const dataOfMathsAggregate = mathsAggregateMeta[key];

    //         const typeOfMathsAggregation = dataOfMathsAggregate['type'];
    //         const meta = dataOfMathsAggregate['meta']

    //         const result = processCalculation({ sortedData, xDataKeys, yDataKeys, key, columnMeta, type: typeOfMathsAggregation, formulaMeta: meta });

    //         sortedData = result['data'];
    //     })
    // }

    if (valuesToRemove && valuesToRemove.length > 0) {
        xDataKeys && xDataKeys.length > 0 && xDataKeys.forEach((x, index) => {
            if (valuesToRemove.indexOf(x) > -1) {
                xDataKeys.splice(index, 1);
            }
        })
    }


    // if (formulaMeta) {

    //     const fomulaResult = formulaMain({ sortedData, yDataKeys: yDataKeys, xDataKeys, formulaMeta, columnMeta, isComparisonKeysPresent: comparisonKeys && comparisonKeys.length > 0 ? true : false });

    //     if (fomulaResult) {
    //         sortedData = fomulaResult.sortedData;
    //         yDataKeys = fomulaResult.yDataKeys;
    //         xDataKeys = fomulaResult.xDataKeys;
    //         mainFormulas = fomulaResult.main_formulas;
    //     }
    // }


    // this is a new formula patch
    // if (reportFormula && isSchedule) {

    // const formula_result = apply_formula({ sortedData, yDataKeys: yDataKeys, xDataKeys, reportFormula, columnMeta })

    // if (formula_result) {
    //     sortedData = formula_result.sortedData;
    //     yDataKeys = formula_result.yDataKeys;
    //     mainFormulas = formula_result.mainFormulas;
    //     // console.log("mainFormulas", mainFormulas)
    // }
    // }

    let tempXDataKeys = xDataKeys && xDataKeys.length > 0 ? xDataKeys : [];
    let tempYDataKeys = yDataKeys && yDataKeys.length > 0 ? yDataKeys : [];
    const tempColumns = [...tempXDataKeys, ...tempYDataKeys];

    tempColumns && tempColumns.length > 0 && tempColumns.forEach((data_key) => {
        if (columnMeta && Object.keys(columnMeta).length > 0) {
            Object.keys(columnMeta).forEach(metaKey => {
                let display_value = (columnMeta[metaKey] && columnMeta[metaKey].display_value) ? columnMeta[metaKey].display_value : undefined;
                let typeOfData = (columnMeta[metaKey] && columnMeta[metaKey].data_type) ? columnMeta[metaKey].data_type : undefined;

                if ((comparisonKeys && comparisonKeys.length > 0)) {
                    let local_temp_algment = {};
                    comparisonKeys.map((compare_key, i) => {
                        local_temp_algment = temp_aligment[dataTypes.number];
                        if (!columnsAligments[compare_key]) {
                            columnsAligments[compare_key] = local_temp_algment;
                        }
                    })
                }


                if ((data_key && data_key.toLowerCase()) == (display_value && display_value.toLowerCase())) {
                    let local_temp_algment = {};
                    local_temp_algment = typeOfData === 4 ? temp_aligment[checkDateAndSetDateTimeTypeToData(data, display_value)] : temp_aligment[typeOfData];
                    if (!columnsAligments[display_value]) {
                        columnsAligments[display_value] = local_temp_algment;
                    } else {
                        columnsAligments[display_value] = local_temp_algment;
                    }

                }
            })
        }
    })

    mainFormulas && Object.keys(mainFormulas).length > 0 && Object.keys(mainFormulas).map((key) => {
        let data_type = mainFormulas[key].data_type ? dataTypes[mainFormulas[key].data_type] : 5;
        columnsAligments[key] = temp_aligment[data_type]
    })


    if (columnsAligments && Object.keys(columnsAligments).length > 0) {
        Object.keys(columnsAligments).forEach(key => {
            if (columnsAligments[key] && columnsAligments[key].type === dataTypes.number) {
                let _d_type = checkDataTypesForNumberAndDecimal(data, key)
                columnsAligments[key] = temp_aligment[_d_type]
            }
        })
    }


    sortedData && sortedData.length > 0 && sortedData.forEach((d) => {
        if (!d[TABLE_CONFIG.group_row_identifier]) {
            d && Object.keys(d).length > 0 && Object.keys(d).forEach(key => {

                if (columnsAligments[key] && (columnsAligments[key].type === DataType.number || columnsAligments[key].type === DataType.percent || columnsAligments[key].type === DataType.number_with_decimal || columnsAligments[key].type === DataType.currency)) {
                    if (grandTotalColumns[key] === undefined) {
                        grandTotalColumns[key] = 0;
                    }

                    if (grandTotalColumns[key] !== undefined) {
                        grandTotalColumns[key] += (!isNaN(d[key]) ? (typeof parseFloat(d[key]) === 'number') ? parseFloat(d[key]) : 0 : 0)
                    }

                }
            })
        }
    })

    csvHeader = getCsvHeader(xDataKeys, yDataKeys);

    return {
        sortedData,
        chartType: urlChartType && urlChartType.length > 0 ? urlChartType : chartType,
        showSingleCell,
        singleCellData,
        csvHeader,
        reportId,
        title,
        sqlQuery,
        renderMode: urlRenderMode && urlRenderMode.length > 0 ? urlRenderMode : renderMode,
        xDataKeys,
        yDataKeys,
        comparisons,
        reportType,
        responseId,
        columnMeta,
        comparisonKeys,
        isSchedule,
        chartYDataKeys,
        chartXDataKeys,
        parentReport,
        scheduleReportId,
        dbType,
        trendFilters,
        error,
        mainFormulas,
        columnsAligments,
        force_render_mode,
        is_details,
        grandTotalColumns,
        hide_columns,
    }
};


const processCalculation = ({ sortedData, xDataKeys, yDataKeys = [], key, columnMeta, type, formulaMeta }) => {
    let yIndex = undefined;

    const tempData = [...sortedData];


    yDataKeys && yDataKeys.length > 0 && yDataKeys.forEach((y, index) => {
        const _t = columnMeta[key]

        const _displayValue = _t ? _t['display_value'] : key;

        if (_displayValue.toLowerCase() === y.toLowerCase()) {
            yIndex = index;
        }
    });


    const tempYDataKeys = [...yDataKeys];

    if (yIndex || yIndex === 0) {

        const valueToChange = yDataKeys[yIndex];

        const _y = tempYDataKeys[yIndex];

        let valueToGroup = undefined;


        if (xDataKeys.length > 1) {
            valueToGroup = xDataKeys[xDataKeys.length - 2];
        }

        let avg_factor = undefined;

        if (type === 'avg') {
            const { column } = formulaMeta;

            const { avg_calcluation_factor } = column

            if (avg_calcluation_factor) {
                const displayValue = columnMeta && columnMeta['nf_' + avg_calcluation_factor] ? columnMeta['nf_' + avg_calcluation_factor]['display_value'] : avg_calcluation_factor;

                avg_factor = displayValue;
            }
        }

        let lastGroupByName = undefined;
        let lastGroupByValue = 0;
        let lastGroupByCount = 0;
        let changeStartIndex = 0;
        let avg_factor_sum = 0;

        let min_value = 100000000000000000000000000000000000000000000000000;
        let max_value = 0;


        if (!valueToGroup) {
            tempData && tempData.length > 0 && tempData.forEach((data, index) => {
                const valueToAdd = data[valueToChange];

                if (type === 'avg') {
                    lastGroupByValue += valueToAdd;
                    lastGroupByCount += 1;
                }

                if (type === 'min') {
                    min_value = Math.min(min_value, valueToAdd);
                }

                if (type === 'max') {
                    max_value = Math.max(max_value, valueToAdd);
                }
            });



            tempData && tempData.length > 0 && tempData.forEach((data, index) => {

                let _avg_sum = 0;

                if (data[avg_factor]) {
                    _avg_sum = data[avg_factor];
                }

                if (type === 'avg') {

                    tempData[index][_y] = lastGroupByValue / (Math.max(_avg_sum, lastGroupByCount));
                }

                if (type === 'min') {
                    tempData[index][_y] = min_value;
                }

                if (type === 'max') {
                    tempData[index][_y] = max_value;
                }
            });
        }
        else {
            tempData && tempData.length > 0 && tempData.forEach((data, index) => {
                const valueToGroupFromData = data[valueToGroup];
                const valueToAdd = data[valueToChange];

                let _avg_sum = 0;

                if (data[avg_factor]) {
                    _avg_sum = data[avg_factor];
                }

                if (!lastGroupByName) {
                    lastGroupByName = valueToGroupFromData;
                }

                if (lastGroupByName !== valueToGroupFromData) {
                    const which_factor = avg_factor_sum ? avg_factor_sum : _avg_sum;


                    for (let i = changeStartIndex; i < index; i++) {
                        if (type === 'avg') {
                            tempData[i][_y] = lastGroupByValue / which_factor
                        }

                        if (type === 'min') {
                            tempData[i][_y] = min_value;
                        }

                        if (type === 'max') {
                            tempData[i][_y] = max_value;
                        }
                    };

                    lastGroupByName = valueToGroupFromData;
                    changeStartIndex = index;

                    if (type === 'avg') {
                        lastGroupByValue = valueToAdd;
                        lastGroupByCount = 0;
                        avg_factor_sum = 0;
                    }

                    if (type === 'min') {
                        min_value = 100000000000000000000000000000000000000000000000000;
                    }

                    if (type === 'min') {
                        max_value = 0;
                    }

                }
                else if (index < tempData.length - 1) {
                    if (type === 'avg') {
                        lastGroupByValue += valueToAdd;
                        lastGroupByCount += 1;
                        avg_factor_sum += _avg_sum;
                    }

                    if (type === 'min') {
                        min_value = Math.min(min_value, valueToAdd);
                    }

                    if (type === 'max') {
                        max_value = Math.max(max_value, valueToAdd);
                    }
                }
                else {
                    lastGroupByValue += tempData[tempData.length - 1][valueToChange] || 0;

                    for (let i = changeStartIndex; i <= index; i++) {

                        if (type === 'avg') {
                            tempData[i][_y] = lastGroupByValue / (tempData.length - changeStartIndex)
                        }

                        if (type === 'min') {
                            tempData[i][_y] = min_value;
                        }

                        if (type === 'max') {
                            tempData[i][_y] = max_value;
                        }
                    };
                }
            })
        }
    }

    return {
        data: tempData,
    };
};


const formulaMain = ({ sortedData, columnMeta, formulaMeta, yDataKeys, isComparisonKeysPresent, xDataKeys }) => {
    let mainFormulas = undefined;
    if (formulaMeta) {
        const { formula_columns, main_formulas } = formulaMeta;


        if (formula_columns && Object.keys(formula_columns).length > 0) {
            Object.keys(formula_columns).forEach(key => {
                const nameToFindInConfig = 'nf_' + key;

                const columnName = columnMeta[nameToFindInConfig] ? columnMeta[nameToFindInConfig]['display_value'] : undefined;

                if (columnName && yDataKeys) {
                    const index = yDataKeys.indexOf(columnName);


                    if (index > -1) {
                        yDataKeys.splice(index, 1);
                    }
                }

                if (columnName && xDataKeys) {
                    const index = xDataKeys.indexOf(columnName);


                    if (index > -1) {
                        xDataKeys.splice(index, 1);
                    }
                }
            })
        }

        if (main_formulas && yDataKeys && Array.isArray(yDataKeys) && Object.keys(main_formulas).length > 0) {
            const keys = Object.keys(main_formulas);

            yDataKeys = [...yDataKeys, ...keys];
            mainFormulas = main_formulas;
        }


        let i = 0;

        sortedData && sortedData.length > 0 && sortedData.forEach(data => {
            yDataKeys && yDataKeys.length > 0 && yDataKeys.forEach(column => {
                if (mainFormulas && mainFormulas[column]) {
                    const _data = formulaHandler(data, mainFormulas[column], columnMeta, isComparisonKeysPresent);

                    data[column] = _data
                }
            })
        });

        return {
            sortedData,
            xDataKeys,
            yDataKeys,
            main_formulas
        }
    }

    return undefined;
}


export const get_display_key_by_data_key = (data_key, columnMeta, index) => {

    const __keys__ = columnMeta ? Object.keys(columnMeta) : [];

    for (let i = 0; i < __keys__.length; i++) {
        const d_key = __keys__[i] && __keys__[i].split('nf_')[1];
        if ((d_key && d_key.toLocaleLowerCase()) === (data_key && data_key.toLocaleLowerCase())) {
            return columnMeta[__keys__[i]].display_value;
        }
    }
}


export const handle_formula = (formula_column, rowItem, index, formula, columnMeta) => {

    const operator = ['-', "+", "/", "*", "(", ")"];
    let formula_str = '';
    formula && formula.length > 0 && formula.forEach((k) => {

        const cell_key = get_display_key_by_data_key(k, columnMeta, index) || k;
        let cell_value = (cell_key && rowItem[cell_key]) || rowItem["total_nfx_" + cell_key] || 0;

        let v = parseFloat(cell_key);
        if (cell_key && typeof v === 'number' && !isNaN(v)) {
            cell_value = parseFloat(cell_key)
        }

        const is_operator = operator.indexOf(cell_key) > -1;
        if (!is_operator) {
            if (typeof cell_value === 'number') {
                formula_str += cell_value;
            } else {
                formula_str += cell_key
            }
        } else {
            formula_str += cell_key;
        }
    })
    // if (index === 0) {
    //     console.log("formula_str", formula_str, rowItem)
    // }
    return (formula_str && formula_str.length > 0) ? eval(formula_str) : 'N/A';
}



export const handle_conditional_formula = (formula_column, rowItem, index, formula, columnMeta) => {

    let display_value = '';
    let need_to_go_else = false;

    const conditions_from_be = formula.conditions;

    const conditions = conditions_from_be && conditions_from_be.length > 0 && conditions_from_be.sort((a, b) => a.order - b.order) || [];
    const columns = formula && formula.formula_columns;
    const column = columns && columns.length > 0 && columns[0];

    const cell_key = get_display_key_by_data_key(column, columnMeta, index) || column;
    const cell_value = (cell_key && rowItem[cell_key]) || rowItem["total_nfx_" + cell_key] || 0;

    //    if(index === 1){
    //         console.log('conditions', conditions)
    //    }

    for (let i = 0; i < conditions.length; i++) {
        const _c_ = conditions[i];

        if (_c_.condition !== CONDITIONS.ELSE.VALUE_KEY) {
            if (_c_.condition === CONDITIONS.LESS_THEN.VALUE_KEY) {
                if (_c_.value > cell_value) {
                    display_value = _c_.display_value;
                    break;
                } else need_to_go_else = true;
            }
            if (_c_.condition === CONDITIONS.GREATER_THAN.VALUE_KEY) {
                if (_c_.value < cell_value) {
                    display_value = _c_.display_value;
                    break;
                } else need_to_go_else = true;
            }
            if (_c_.condition === CONDITIONS.EQUAL_TO.VALUE_KEY) {
                if (isNaN(_c_.value)) {
                    if ((_c_.value && _c_.value.toLocaleLowerCase()) === (cell_value && cell_value.toLocaleLowerCase())) {
                        display_value = _c_.display_value;
                        break;
                    } else need_to_go_else = true;
                }
                else if (parseFloat(_c_.value) === parseFloat(cell_value)) {
                    display_value = _c_.display_value;
                    break;
                } else need_to_go_else = true;
            }
            if (_c_.condition === CONDITIONS.LESS_THEN_OR_EQUAL_TO.VALUE_KEY) {
                if (parseFloat(cell_value) <= parseFloat(_c_.value)) {
                    display_value = _c_.display_value;
                    break;

                } else need_to_go_else = true;
            }
            if (_c_.condition === CONDITIONS.GREATER_THAN_OR_EQUAL_TO.VALUE_KEY) {
                if (parseFloat(cell_value) >= parseFloat(_c_.value)) {
                    display_value = _c_.display_value;
                    break;

                } else need_to_go_else = true;
            }
            if (_c_.condition === CONDITIONS.CONTAINS.VALUE_KEY) {
                if ((cell_value && cell_value.toString().length > 0) && cell_value.toString().toLocaleLowerCase().indexOf(_c_.value.toString().toLocaleLowerCase()) > -1) {
                    display_value = _c_.display_value;
                    break;
                } else need_to_go_else = true;
            }
            if (_c_.condition === CONDITIONS.DOES_NOT_CONTAIN.VALUE_KEY) {
                if ((cell_value && cell_value.toString().length > 0) && cell_value.toString().toLocaleLowerCase().indexOf(_c_.value.toString().toLocaleLowerCase()) == -1) {
                    display_value = _c_.display_value;
                    break;
                } else need_to_go_else = true;
            }

            if (_c_.condition === CONDITIONS.NOT_EQUAL.VALUE_KEY) {
                if (isNaN(_c_.value)) {
                    if ((_c_.value && _c_.value.toLocaleLowerCase()) !== (cell_value && cell_value.toLocaleLowerCase())) {
                        display_value = _c_.display_value;
                        break;
                    } else need_to_go_else = true;
                }
                else if (parseFloat(_c_.value) !== parseFloat(cell_value)) {
                    display_value = _c_.display_value;
                    break;
                } else need_to_go_else = true;
            }
        }
        if (need_to_go_else && _c_.condition === CONDITIONS.ELSE.VALUE_KEY) {
            display_value = _c_.display_value;
            break;
        }
    }
    // if(index === 0){
    //     console.log("sarojqa", need_to_go_else)
    // }
    return display_value ? display_value : ''
}




export const apply_formula = ({ sortedData, yDataKeys = [], xDataKeys, reportFormula, columnMeta }) => {

    console.log('formula: ', sortedData);

    const _keys__ = [];

    const mainFormulas = {};

    if (reportFormula && reportFormula.length > 0) {
        reportFormula.forEach((formula) => {
            _keys__.push(formula.name)
            mainFormulas[formula.name] = {
                formula_columns: formula.column_name,
                show_formula_condition: formula.show_formula_condition,
                conditions: formula.nf_formula_conditions,
                data_type: formula.data_type
            }
        })
    }

    yDataKeys = [...yDataKeys, ..._keys__];

    sortedData && sortedData.length > 0 && sortedData.forEach((data, index) => {
        yDataKeys && yDataKeys.length > 0 && yDataKeys.forEach(column => {
            if (mainFormulas && mainFormulas[column]) {
                const payload_formula = mainFormulas[column];
                const __formula__columns = payload_formula && payload_formula.formula_columns;
                const __show_condition__ = payload_formula.show_formula_condition;
                if (__show_condition__) {
                    const _data = handle_conditional_formula(column, data, index, payload_formula, columnMeta);
                    data[column] = _data;
                } else {
                    const _data = handle_formula(column, data, index, __formula__columns, columnMeta);
                    data[column] = _data
                }
            }
        })
    });
    return {
        sortedData: sortedData,
        yDataKeys: yDataKeys,
        mainFormulas: mainFormulas
    }
}

export const updateUrlParameter = (url, param, value) => {
    var regex = new RegExp('(?<=[?|&])(' + param + '=)[^\&]+', 'i');
    // return url.replace(regex, param + '=$1' + value);
    return url.replace(regex, param + '=' + value);
};


export const getNameInitials = name => {
    const tempName = name;

    const splittedName = tempName.split(' ');


    const initials = (splittedName[0].charAt(0).toUpperCase()) + ((splittedName.length > 1) ? splittedName[splittedName.length - 1].charAt(0).toUpperCase() : '');

    return initials;

};
export const getRemToPx = (rem, dim) => {
    const windowSize = dim && dim === 'vh' ? window.innerHeight : window.innerWidth;

    if (dim === 'vh') {
        let base_size = 6;

        if (windowSize < 680) {
            base_size = 6
        }
        else if (windowSize >= 680 && windowSize < 700) {
            base_size = 7
        }
        else if (windowSize >= 700 && windowSize < 800) {
            base_size = 7.5
        }
        else if (windowSize >= 800 && windowSize < 900) {
            base_size = 8.5
        }
        else if (windowSize >= 900 && windowSize < 1000) {
            base_size = 9
        }

        return base_size * rem
    }
    else {

        let base_size = 12;
        if (windowSize < 1280) {
            base_size = 12
        }
        else if (windowSize >= 1280 && windowSize < 1400) {
            base_size = 14
        }
        else if (windowSize >= 1400 && windowSize < 1600) {
            base_size = 15
        }
        else if (windowSize >= 1600 && windowSize < 1800) {
            base_size = 17
        }
        else if (windowSize >= 1800 && windowSize < 2000) {
            base_size = 18
        }
        else if (windowSize >= 2000) {
            base_size = 20
        }
        return base_size * rem

    }
};


export const getPxToRem = (px, dim) => {
    const windowSize = dim && dim === 'vh' ? window.innerHeight : window.innerWidth;

    if (dim === 'vh') {
        let base_size = 6;

        if (windowSize < 680) {
            base_size = 6
        }
        else if (windowSize >= 680 && windowSize < 700) {
            base_size = 7
        }
        else if (windowSize >= 700 && windowSize < 800) {
            base_size = 7.5
        }
        else if (windowSize >= 800 && windowSize < 900) {
            base_size = 8.5
        }
        else if (windowSize >= 900 && windowSize < 1000) {
            base_size = 9
        }

        // return base_size * rem
        return px/base_size
    }
    else {

        let base_size = 12;
        if (windowSize < 1280) {
            base_size = 12
        }
        else if (windowSize >= 1280 && windowSize < 1400) {
            base_size = 14
        }
        else if (windowSize >= 1400 && windowSize < 1600) {
            base_size = 15
        }
        else if (windowSize >= 1600 && windowSize < 1800) {
            base_size = 17
        }
        else if (windowSize >= 1800 && windowSize < 2000) {
            base_size = 18
        }
        else if (windowSize >= 2000) {
            base_size = 20
        }
        // return base_size * rem
        return px/base_size

    }
};



export const numberFormatterWithCommas = (number, extraLabel) => {
    let temp_value = number;
    if (typeof temp_value === 'number') {
        let _num = new Number(temp_value).toLocaleString("US-EN");
        let finalValue = undefined;
        if (extraLabel) {
            finalValue = extraLabel + " " + _num
            return finalValue;
        } else {
            return _num
        }
    }
    return number
}

export function formatNumber(value, is_decimal, up_to_decimal = 0, num_format_type) {

    // var up_to_decimal = __up_to_decimal ? __up_to_decimal : 0;

    // if (value < .9) {
    //     up_to_decimal = 2;
    // }

    const use_comma = (!num_format_type) ? true : num_format_type === "comma";

    const is_crores = num_format_type === "crores"
    const is_million = num_format_type === "million"


    if (value === '-') return value;


    if (is_million || is_crores) {

        const d = DataFormatter(value, up_to_decimal, is_million, is_crores)
        // console.log("BBBU", d, up_to_decimal)
        return d

    } else {

        const up_to = up_to_decimal > -1 && typeof up_to_decimal !== "undefined" && up_to_decimal !== "undefined" ? up_to_decimal : 2;

        let number = value && value.toString().indexOf(",") > -1 ? value.split(",").join("") : value;

        const comma_replacer = use_comma ? '$1,' : '$1';

        if (number && number.toString().length > 0) {

            let n1 = parseFloat(number).toFixed(up_to)
            let n2 = n1.toString().split('.')[0];
            let n3 = n1.toString().split('.')?.length > 1 ? n1.toString().split('.')[1] : undefined;
            let n4 = n2.replace(/(\d)(?=(\d{3})+(?!\d))/g, comma_replacer)
            let final_n = (n4 + (n3 ? ('.' + n3) : ''))

            return final_n
        }

        else return value;

    }


}



export const DataFormatter1 = (
    number1,
    up_to_decimal = 2,
    is_million = false,
    is_crores = false
) => {
    var number = '93795119'

    // Ensure the provided number is valid and a number
    let temp_n = parseFloat(number);
    if (isNaN(temp_n)) return "Invalid number";

    const upto = typeof up_to_decimal === "number" && up_to_decimal >= 0 ? up_to_decimal : 2;

    const is_negative = temp_n < 0;
    const number_to_use = Math.abs(temp_n);
    const prefix = is_negative ? "-" : "";

    const formatNumber = (value, suffix) =>
        `${prefix}${value.toFixed(upto).toLocaleString()} ${suffix}`;

    if (is_million) {
        if (number_to_use >= 1e9) return formatNumber(number_to_use / 1e9, "B");
        if (number_to_use >= 1e6) return formatNumber(number_to_use / 1e6, "M");
        if (number_to_use >= 1e3) return formatNumber(number_to_use / 1e3, "K");
        return formatNumber(number_to_use, "");
    } else if (is_crores) {
        if (number_to_use >= 1e7) return formatNumber(number_to_use / 1e7, "cr");
        if (number_to_use >= 1e5) return formatNumber(number_to_use / 1e5, "L");
        if (number_to_use >= 1e3) return formatNumber(number_to_use / 1e3, "K");
        return formatNumber(number_to_use, "");
    }

    // Default formatting if no specific format is selected
    return number_to_use.toFixed(upto);
};


export const DataFormatter = (number, up_to_decimal = 2, is_million, is_crores) => {


    const upto = up_to_decimal > -1 && typeof up_to_decimal !== "undefined" && up_to_decimal !== "undefined" ? up_to_decimal : 2;


    let temp_n = parseFloat(number);

    var is_negative = false;

    var number_to_use = temp_n && Math.abs(temp_n);

    if (temp_n < 0) is_negative = true;

    var prefix = is_negative ? '-' : ''


    if (is_million) {

        if (number_to_use > 1000000000) {
            return prefix + (number_to_use / 1000000000).toFixed(upto).toString() + ' B';
        }
        else if (number_to_use > 1000000) {
            return prefix + (number_to_use / 1000000).toFixed(upto).toString() + ' M';
        }
        else if (number_to_use > 10000) {
            return prefix + (number_to_use / 1000).toFixed(upto).toString() + ' K';
        }
        else {
            return prefix + number_to_use.toFixed(upto).toString();
        }

    } else {

        if (number_to_use > 10000000) {
            return prefix + (number_to_use / 10000000).toFixed(upto).toString() + ' cr';
        }
        else if (number_to_use > 100000) {
            return prefix + (number_to_use / 100000).toFixed(upto).toString() + ' L';
        }
        else if (number_to_use > 10000) {
            return prefix + (number_to_use / 1000).toFixed(upto).toString() + ' K';
        }
        else {
            return prefix + (number_to_use > 0 ? number_to_use.toFixed(upto).toString() : number_to_use);
        }

    }



};

export const isFloat = num => {
    return Number(num) === num && num % 1 !== 0;
}


export const percentFormatter = (n, up_to_decimal = 2) => {

    let number = parseFloat(n)

    const up_to = up_to_decimal > -1 && typeof up_to_decimal !== "undefined" && up_to_decimal !== "undefined" ? up_to_decimal : 2;

    return typeof number === 'number' ? number.toFixed(up_to) : n
}

export const bytesToSize = (bytes) => {

    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};



export const createTitleFromDataKeys = (xDataKeys, yDataKeys) => {
    let y_values = '';
    let x_values = '';
    let title = '';

    if (xDataKeys && xDataKeys.length === 1) {
        x_values = "by " + xDataKeys[0];
    }

    if (xDataKeys && xDataKeys.length > 1) {
        x_values = xDataKeys?.[0]?.split(' ')[0] + ' data ';
    }
    if (yDataKeys && yDataKeys.length > 0) {
        let last_value = yDataKeys[yDataKeys.length - 1];
        if (last_value.indexOf('-') !== -1) {
            let temp_title = last_value.split('-');
            title = temp_title ? temp_title[temp_title.length - 1] : temp_title
        }
        else {
            title = (yDataKeys ? yDataKeys.join(',') : '');
        }
    }

    // return  (yDataKeys ? yDataKeys.join(',') : '') + " " + ( (xDataKeys && ("by" + " " + xDataKeys.join(","))));
    return title + " " + x_values;
};


export const check_data_tile_server = () => {
    const client_id = getClientId()['client-id'];

    fetch('http://127.0.0.1:8345/check_data_in_tile_server', {
        method: 'post',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ client_id })
    })
        .then(res => res.json())
        .then(res => {
            if (res && res.code === 200) {
                return true;
            }
            else {
                return false;
            }
        })
        .catch(error => {
            return false;
        })
};



export const getCoordinteColumnFromColumnMeta = columnMeta => {
    let locationColumn = [];

    columnMeta && Object.keys(columnMeta).length > 0 && Object.keys(columnMeta).forEach(key => {
        const data = columnMeta[key];
        if (data['data_type'] === 8) {
            locationColumn.push(data['display_value'])
        }
    });

    return locationColumn;
};



export const getDataInCoordinateFrom = (data, coordinateColumn) => {
    const tempData = [...data];
    tempData.forEach(d => {
        d['coordinates'] = {}
        coordinateColumn.forEach(t => {
            const whichColumn = t.toLowerCase();
            d['coordinates'][whichColumn] = d[t];
        })
    });
    return tempData;
};


export const get_hints_in_object = (hints) => {
    const obj = {}

    hints.forEach(h => {
        if (h.column_name) {
            obj[h.column_name] = {
                table_name: h.table_name,
                column: h.column_name
            }
        }
        h.values && h.values.length > 0 && h.values.forEach(v => {
            obj[v] = {
                table: h.table_name,
                column: h.column_name
            }
        })
    })

    return obj;
};


export const check_brackets = (expr) => {
    const holder = []
    const openBrackets = ['(', '{', '[']
    const closedBrackets = [')', '}', ']']
    for (let letter of expr) { // loop trought all letters of expr
        if (openBrackets.includes(letter)) { // if its oppening bracket
            holder.push(letter)
        } else if (closedBrackets.includes(letter)) { // if its closing
            const openPair = openBrackets[closedBrackets.indexOf(letter)] // find its pair
            if (holder[holder.length - 1] === openPair) { // check if that pair is the last element in the array
                holder.splice(-1, 1) // if so, remove it
            } else { // if its not
                holder.push(letter)
                break // exit loop
            }
        }
    }
    return (holder.length === 0) // return true if length is 0, otherwise false
}


export const getPermissionById = (id, permissions, client_id) => {

    if (client_id === 'ads_yahoo') return true;

    if (!permissions) {
        return undefined;
    }

    let show = false;
    let temp_id = id;

    if (client_id) {
        temp_id += '_' + client_id;
    }

    return permissions[id] || permissions[temp_id];
};

let i = 0;

export const formulaHandler = (rowItem, formula, columnMeta, isComparisonKeysPresent) => {
    const { formula_text } = formula;

    if (i == 0) {
    }

    let temp_formula = formula_text.replace(/["'\(\)]/g, "").split(/([-+*\/])/g);

    if (i == 0) {
    }

    if (temp_formula && temp_formula.length > 0) {
        temp_formula.forEach((c, index) => {
            let _c = c.trim().split(' ').join('_');

            const display_value = columnMeta[_c] ? columnMeta[_c]['display_value'] : undefined;

            if (display_value) {
                const which_display_value = isComparisonKeysPresent ? ('total-' + display_value) : display_value;

                if (i == 0) {
                }

                temp_formula[index] = rowItem[which_display_value];
            }
        })
    }

    let rightValue = temp_formula[temp_formula.length - 1];

    for (let index = temp_formula.length - 2; index >= 0; index = index - 2) {
        const operator = temp_formula[index];
        const leftItem = validateNumber(temp_formula[index - 1]) ? temp_formula[index - 1] : rowItem[temp_formula[index - 1]]

        switch (operator) {
            case '+':
                rightValue = handleAddition(rightValue, leftItem);
                break;
            case '-':
                rightValue = handleSubtraction(rightValue, leftItem);
                break;
            case '*':
                rightValue = handleMultiplication(rightValue, leftItem);
                break;
            case '/':
                rightValue = handleDivision(leftItem, rightValue);
                break;
        }
    }



    i++;

    return rightValue ? rightValue : 'N/A';
};

const handleAddition = (rightItem, leftItem) => {
    return rightItem + leftItem;
};

const handleMultiplication = (rightItem, leftItem) => {
    return rightItem * leftItem;
};

const handleSubtraction = (rightItem, leftItem) => {
    return leftItem - rightItem;
};

const handleDivision = (numerator, denominator) => {
    return (numerator / denominator);
};


export const url_for_share = (prefix, client_id, report_id, filters) => {
    const main_protocol = window.location.protocol;
    let host = window.location.host;

    // if (host.indexOf('localhost') === -1) host = host + '/app';

    let final_url = main_protocol + '//' + host + '/' + prefix + `?client_id=${client_id}&report_id=${report_id}`;

    if (filters) {
        const filter_keys = Object.keys(filters);
        let all_filters = [];

        filter_keys && filter_keys.forEach(key => {
            const filter_values = filters[key];


            if (filter_values && Array.isArray(filter_values) && filter_values.length > 0) {
                all_filters.push(key);
                final_url = final_url + '&' + key.toLocaleLowerCase() + '=' + filter_values.join('_xnfx_');
            }
        });

        if (filter_keys.length > 0) {
            final_url = final_url + '&nf_filters=' + all_filters.join(',').toLocaleLowerCase();
        }
    }

    return final_url
    // return 'http://127.0.0.1:3000/' + prefix + `?client_id=${client_id}&report_id=${report_id}&opr_token=${opr_token}`;
};


export const get_data_by_report = (report, xDataKeys, current_key, history) => {

    const report_type = report && report.type;
    let __redirect_filter__ = `${[xDataKeys[0]]} = ${current_key}`

    if (report_type === 'report') {
        const report_item = report.report_items[0];
        const main_report_id = report.id;
        const whichField = report_item.display_columns ? report_item.display_columns.join(' ') : report_item.question;
        const db_info_id = report_item.db_info_id ? report_item.db_info_id : undefined;
        const report_filters = report_item.parameters && report_item.parameters.join(',').toLocaleUpperCase();
        _getDataFromReportingServer(whichField, history, undefined, undefined, report_item.id, undefined, main_report_id, undefined, undefined, db_info_id, undefined, report_filters, __redirect_filter__, true)
    } else {
        const __url__ = `?insightId=${report.id}&redirect_filters=${__redirect_filter__}`;
        window.open(__url__, '_blank')
    }

}




/**
 * 
 * @param {*} data 
 * 
 * !!!VERY IMPORTANT FUNCTION!!!
 * 
 * when we receive data, we will run some operations on data to make it look better
 * 
 * for example, if data is like { a: 100, b: 102, c: 150 }, here no xac, but we will need to show charts
 */

export const data_set_processing = (data, xac, yac, column_meta) => {
    if (!data) return { data, column_meta };
    const meta = Object.assign({}, column_meta);

    // step 1: we will sum the rows, if the xac keys are same, for example: 
    // [ { abc: 100, d: 100 }, { abc: 188 }, { abc: 101 }, ]
    // here, we will just add all abc and return one row as [{ abc: 389, d: 100}]
    if (xac.length === 0) {
        const temp_obj = {};

        data.forEach((item) => {
            const keys = item && Object.keys(item)

            keys && keys.length > 0 && keys.forEach((key) => {
                if (typeof temp_obj[key] === 'undefined') {
                    temp_obj[key] = 0;
                }

                if (!isNaN(item[key])) {
                    temp_obj[key] += item[key];
                }
            });
        });

        if (Object.keys(temp_obj).length > 0) {
            // we were able to merge rows, yayyyyy
            data = [temp_obj];
        }

    }

    // step 2, if no xac, then push the "Value Keyword"

    if (xac.length === 0) {
        xac.push('Value');

        meta['Value'] = {
            data_type: 5,
            display_value: '_',
            column_order: 1
        };

        data.forEach(d => d['Value'] = 'Value');
    }

    return {
        data,
        column_meta: meta,
        xac
    };
};



export const remove_repetitive_keys = (payload) => {

    const final_payload = [];
    const final_data_keys = {};

    let repetitive_key_f = undefined;
    let repetitive_key_s = undefined;

    let index_being_checked = -1;

    payload && Array.isArray(payload) && payload.forEach(item => {
        if (item.dataKey && item.dataKey.indexOf('_nfx_') > -1) {
            if (typeof repetitive_key_f === 'undefined') {
                repetitive_key_f = item.dataKey.split('_nfx_')[0];
                repetitive_key_s = item.dataKey.split('_nfx_')[1];
            }
            else {
                // lets see if it is same or not
                const this_repetitive_key_f = item.dataKey.split('_nfx_')[0];
                const this_repetitive_key_s = item.dataKey.split('_nfx_')[1];

                if (this_repetitive_key_f === repetitive_key_f && (index_being_checked === 0 || index_being_checked === -1)) {
                    index_being_checked = 0;
                }
                else if (this_repetitive_key_s === repetitive_key_s && (index_being_checked === 1 || index_being_checked === -1)) {
                    index_being_checked = 1;
                }
                else {
                    // lets just assume that we don't have any item which is repetitive
                    index_being_checked = 100;
                }
            }
        }
    });

    if (index_being_checked === 0 || index_being_checked === 1) {
        const which_index_to_pick = index_being_checked === 0 ? 1 : 0;

        payload.forEach(p_item => {
            const object_to_push = {
                ...p_item
            };

            if (typeof p_item.dataKey === 'string' && p_item.dataKey.indexOf('_nfx_') > -1) {
                object_to_push['dataKey'] = p_item.dataKey.split('_nfx_')[which_index_to_pick];

                final_data_keys[p_item.dataKey] = object_to_push['dataKey'];

            }

            if (typeof p_item.value === 'string' && p_item.value.indexOf('_nfx_') > -1) {
                object_to_push['value'] = p_item.value.split('_nfx_')[which_index_to_pick];
            }

            if (typeof p_item.name === 'string' && p_item.name.indexOf('_nfx_') > -1) {
                object_to_push['name'] = p_item.name.split('_nfx_')[which_index_to_pick];
            }

            final_payload.push(object_to_push);
        });

        return {
            final_payload,
            final_data_keys
        };
    }
    else {
        return {
            final_payload: (payload && payload.length > 0) ? [...payload] : []
        };
    }
};


export const get_data_from_server_action = (
    question,
    report_id,
    filters_cache,
    mtd_ytd_options_cache,
    drill_down_cache,
    report_type = 'dashboard',
    show_loader = false,
    render_mode,
    chart_type,
    schedule_report_id,
    groups,
    get_data_from_reporting_server_action_reference,
) => {

    const ytd_or_mtd = mtd_ytd_options_cache && mtd_ytd_options_cache[report_id];
    const drill_down_data = drill_down_cache && drill_down_cache[report_id];
    const filters = filters_cache && filters_cache[report_id];

    get_data_from_reporting_server_action_reference &&
        get_data_from_reporting_server_action_reference(question, undefined, report_id, filters, chart_type, render_mode, schedule_report_id, undefined, show_loader, report_type, {
            drill_down_data,
            ytd_or_mtd,
            groups
        });
};




/**
 * Credits:
 *      user: kip - https://stackoverflow.com/users/18511/kip
 *      url: https://stackoverflow.com/a/1214753/18511
 * Adds time to a date. Modelled after MySQL DATE_ADD function.
 * Example: dateAdd(new Date(), 'minute', 30)  //returns 30 minutes from now.
 * 
 * 
 * @param date  Date to start with
 * @param interval  One of: year, quarter, month, week, day, hour, minute, second
 * @param units  Number of units of the given interval to add.
 */
export const date_add = (date, interval, units) => {
    var ret = new Date(date); //don't change original date
    var checkRollover = function () { if (ret.getDate() != date.getDate()) ret.setDate(0); };

    switch (interval.toLowerCase()) {
        case 'year': ret.setFullYear(ret.getFullYear() + units); checkRollover(); break;
        case 'quarter': ret.setMonth(ret.getMonth() + 3 * units); checkRollover(); break;
        case 'month': ret.setMonth(ret.getMonth() + units); checkRollover(); break;
        case 'week': ret.setDate(ret.getDate() + 7 * units); break;
        case 'day': case 'week-day': ret.setDate(ret.getDate() + units); break;
        case 'hour': ret.setTime(ret.getTime() + units * 3600000); break;
        case 'minute': ret.setTime(ret.getTime() + units * 60000); break;
        case 'second': ret.setTime(ret.getTime() + units * 1000); break;
        default: ret = undefined; break;
    }
    return ret;
};




export const format_date = (_date, include_seconds = false) => {

    const append_zero_if_required = value => {
        if (value < 10) return '0' + value;
        else return value;
    }

    const date = new Date(_date);

    return date.getFullYear() + '-' + (append_zero_if_required(date.getMonth() + 1)) + '-' + append_zero_if_required(date.getDate()) + ' ' + append_zero_if_required(date.getHours()) + ':' + append_zero_if_required(date.getMinutes()) + (include_seconds ? ':' + append_zero_if_required(date.getSeconds()) : '');
};

const comparison_wrapper = {}

export const get_the_difference = (this_value, previous_value, this_key) => {

    let changed = false;
    let keys = []

    const type_of_1 = typeof this_value;
    const type_of_2 = typeof previous_value;

    if (type_of_1 !== type_of_2) {
        keys.push({ changed: true, this_key, type: 'unmatched_types', type_of_1, type_of_2 });
    }

    else if (typeof this_value === 'object') {
        // array or object

        if (!previous_value) {
            // changed_completely.
            // keys.push ({ changed: true, this_key, type: 'no previous values' });
        }
        else {
            if (Array.isArray(previous_value)) {
                if (Array.isArray(this_value)) {
                    // if array, lets just compare the length, and go back to sleep, it is fu**ing 5 AM :(
                    if (previous_value?.length !== this_value?.length) {
                        keys.push({ type: 'unmatched length', this_key, changed: true, this_value_l: this_value?.length, prev_value_l: previous_value?.length, });
                    }
                }
                else {
                    // different

                    keys.push({ this_key, changed: true, this_value_l: 0, prev_value_l: previous_value?.length, });
                }
            }
            else {
                // object
                this_value && Object.entries(this_value).forEach(entry => {
                    const key = this_key + ' ====> ' + entry[0];
                    const changed_wrapper = get_the_difference(entry?.[1], previous_value?.[entry[0]], key);

                    if (changed_wrapper.length > 0) {
                        keys.push(...changed_wrapper);
                    }
                });
            }
        }
    }
    else {
        // value type

        const t_value = (this_value + '').toLocaleLowerCase();
        const p_value = (previous_value + '').toLocaleLowerCase();

        if (t_value !== p_value) {
            keys.push({ type: 'unmatched values', changed: true, this_key, this_value, previous_value });
        }
    }

    return keys;
};


export const compare_different_states_of_an_object = (object, unique_key, print = false) => {
    if (!comparison_wrapper[unique_key]) {
        print && console.log('ok, no previous props, so chill');

        comparison_wrapper[unique_key] = {
            object: object,
            index: 0
        };

        return [];
    }
    else if (object) {
        comparison_wrapper[unique_key].index = comparison_wrapper[unique_key].index + 1;
        print && console.log('CHEKING AT INDEX: ', comparison_wrapper[unique_key].index);
        const c = get_the_difference(object, comparison_wrapper[unique_key].object, 'Entry Point');

        comparison_wrapper[unique_key].object = object;

        if (print) {
            console.log('RESULT: ', (c && c.length > 0) ? c : 'No Changes')
        }

        return c;
    };

};



/**
 * 
 * @param {*} report 
 * @param {*} key 
 * @returns 
 */
export const sort_report_list = (report, key) => {

    const clone = report ? JSON.parse(JSON.stringify(report)) : [];
    if (clone.length < 2) return clone;
    const sortedReports = clone && clone.length > 0 && clone.sort((left, right) => {
        if (key === 'last_updated') {
            let left_modified_at = new Date(left.modified_at ? left.modified_at : null);
            let left_created_at = new Date(left.created_at ? left.created_at : null);
            let right_modified_at = new Date(right.modified_at ? right.modified_at : null);
            let right_created_at = new Date(right.created_at ? right.created_at : null)
            let left_last_edit_date = left_created_at > left_modified_at ? left_created_at : left_modified_at;
            let right_last_edit_date = right_created_at > right_modified_at ? right_created_at : right_modified_at;
            return right_last_edit_date - left_last_edit_date
        }

        if (key === 'az') {
            let leftValue = left.name ? left.name.trim().toLowerCase() : '';
            let rightValue = right.name ? right.name.trim().toLowerCase() : '';
            return leftValue.localeCompare(rightValue);
        }

        if (key === 'za') {
            let leftValue = left.name ? left.name.trim().toLowerCase() : '';
            let rightValue = right.name ? right.name.trim().toLowerCase() : '';
            return rightValue.localeCompare(leftValue);
        }
    })
    return sortedReports;
}



/**
 * 
 * @param {*} dashboard_list 
 */
export const get_dashboard_for_home_page = (insightsListData) => {

    const dashboard_list_to_use = ((insightsListData?.length > 0 && insightsListData.filter((insight) => (insight.type === 'insights' || insight.type === "maps"))) || []).filter((insight => insight?.access_group_report_mapping?.[0]?.access_group_id !== 'test')) || []
    const sorted_insight_list = sort_report_list(dashboard_list_to_use, 'last_updated');
    // console.log("sorted_insight_list", sorted_insight_list)
    return sorted_insight_list
}







/***
 * sql builder
 */

export const get_table_names_from_query = (query, db_type) => {

    // let pattern = /from/gi;
    let pattern = /from (.*?) where/gi;
    let match = 0;
    var t_obj = {};

    while ((match = pattern.exec(query)) != null) {
        var matched_string = match[0].replace(/from|where/gi, '').trim();
        matched_string.split(',').forEach(w => {
            var w_to_use = w && w.trim();
            if (w_to_use && w_to_use.split(' ').length > 1) {
                t_obj[w_to_use.split(' ')[1]] = w_to_use.split(' ')[0]
            } else {
                t_obj[w.trim()] = w.trim();
            }
        })
    }

    return t_obj;
}



export const get_matched_parm_from_query = (query, db_type, need_table_alias, need_column_name) => {

    // ":s*id|:s*doctor_name\/g";

    // let pattern = /(?!:MI|:SS|:[0-9]):\s*[a-z||0-9||_-]*/gim;
    let pattern = /(?!:MI|:SS|:[0-9]):\s*[a-z||0-9||_-]*/gm
    // let pattern = /(?!:MI|:SS|:[0-9])s*:/gm;

    const matched_str = {};
    let match = 0;

    while ((match = pattern.exec(query)) != null) {

        let param = (match[0] || '').replace(/[:]/g, ' ').trim();

        // here we are finding the real column_name 
        let word_v1 = query?.substring(0, match.index);
        let word_v1_splitted = word_v1.replace(/[=<>:]/g, '').trim()?.split(" ");
        let column_name_v1 = word_v1_splitted[word_v1_splitted.length - 1].trim();
        let table_alias_v1 = column_name_v1 && column_name_v1.split('.').length >= 2 ? column_name_v1.split('.')[0] : undefined
        let column_name_to_use_v1 = column_name_v1 && column_name_v1.split('.').length >= 2 ? column_name_v1.split('.')[1] : column_name_v1

        matched_str[param] = {};

        if (need_table_alias) {
            matched_str[param].table_alias = table_alias_v1
        }
        if (need_column_name) {
            matched_str[param].column_name = (column_name_to_use_v1 || column_name_to_use_v1).replace(/[=<>:]/g, '')
        }
    }
    return matched_str;
}



export const replace_param_value_with_prefix = (matched_obj, matched_key) => {

    const matched_key_to_use = matched_key && matched_key.replace(/[=<>:]/g, '').trim();

    let value = matched_obj?.[matched_key_to_use]?.value;
    let data_type = matched_obj?.[matched_key_to_use]?.data_type;

    switch (data_type) {
        case 'number':
            return value
        case 'string':
            return `'${value}'`
        case 'date':
            return value
        default:
            return `'${value}'`
    }

}


export const replace_query_with_match_parm_value = (__query__, matched_obj, db_type) => {

    let query = __query__;

    const all_keys = Object.keys(matched_obj) || [];
    let str_query = all_keys.map(k => ":\s*" + k).join("|")
    const ignoreRegex = new RegExp(str_query, 'gi');

    // console.log("ignoreRegex", ignoreRegex, all_keys, matched_obj)
    if (all_keys && all_keys.length > 0) {
        query = query.replace(ignoreRegex, matched => replace_param_value_with_prefix(matched_obj, matched));

    }
    return query
}









export const find_column_info_by_column_name = (current_schema_to_use, column_name) => {

    const all_tables = current_schema_to_use ? Object.keys(current_schema_to_use) : [];

    for (let index = 0; index < all_tables.length; index++) {

        const t = all_tables[index];
        const all_column = current_schema_to_use[t];
        const all_column_keys = all_column ? Object.keys(all_column) : []

        for (let j = 0; j < all_column_keys.length; j++) {
            const c_key = all_column_keys[j];
            if (c_key && c_key.toLocaleLowerCase() === column_name && column_name.toLocaleLowerCase()) {
                return {
                    column_name: column_name,
                    data_type: return_data_type_of_column(all_column?.[c_key]?.['type']),
                    table_name: t
                }
            }
        }
    }
}


export const find_sql_query_table_column_information = (db_info_id, db_type, schema_table_info, query) => {

    const current_schema_to_use = schema_table_info?.[db_info_id];
    const matched_column_info = get_matched_parm_from_query(query, db_type, true, true);


    const final_sql_column_info = {};

    if (matched_column_info && Object.keys(matched_column_info).length > 0) {
        const all_matched_tables = get_table_names_from_query(query, db_type);
        let all_column_keys = Object.keys(matched_column_info);

        // console.log("all_matched_tables", current_schema_to_use, matched_column_info, all_matched_tables, all_column_keys)
        all_column_keys.forEach(c_key => {

            const table_alias = matched_column_info[c_key]?.table_alias;
            const column_name = matched_column_info[c_key]?.column_name;

            if (table_alias) {

                let table_name_to_use = all_matched_tables?.[table_alias];
                // console.log("table_name_to_use", table_name_to_use)
                let current_table_info = current_schema_to_use?.[table_name_to_use?.toLocaleUpperCase()];
                let current_column_info = current_table_info?.[column_name?.toLocaleUpperCase()];

                let data_type_to_use = return_data_type_of_column(current_column_info?.['type'])

                final_sql_column_info[c_key] = {
                    table_name: table_name_to_use,
                    column_name: column_name,
                    data_type: data_type_to_use,
                }
            }

            if (!table_alias && column_name) {
                const founded_info = find_column_info_by_column_name(current_schema_to_use, column_name, db_type)
                final_sql_column_info[c_key] = {
                    table_name: founded_info?.table_name,
                    column_name: column_name,
                    data_type: founded_info?.data_type,
                }

            }
        })
    }

    // console.log("final_sql_column_info", final_sql_column_info)
    return final_sql_column_info
}




/**
 * 
 * @param {*} __data_type__ 
 * @returns 
 */
const return_data_type_of_column = (__data_type__) => {
    let data_type = 'string';
    switch (__data_type__ && __data_type__.toLowerCase()) {
        case 'int':
        case 'double':
        case 'currency':
        case 'float':
        case 'number':
            data_type = 'number';
            break;
        case 'datetime':
        case 'date':
            data_type = 'date';
            break;
    }
    return data_type;
}




export const get_display_report_type = (type) => {

    if (type === 'config_query_builder') return 'No Code';
    else if (type === 'dashboard') return 'NLP';
    else if (type === 'sql') return 'Code';
    else return 'aa';


}

export const get_formated_cell_data = (column_formatting, cell_key, value) => {

    const column_style = column_formatting?.[cell_key];
    const cell_data_type = column_style?.['type']
    const currency_type = column_style?.['currency_type'];
    const num_format_type = column_style?.['num_format_type'];
    const use_decimal = column_style?.['use_decimal'];
    const use_percent = column_style?.['use_percent'];
    const other_format_config = column_style?.["other_format_config"];
    const finalDatatoShow = value // (cellValue + '') === '0' ? '-' : cellValue;
    const afterFormatted = formatValueByDataType(finalDatatoShow, cell_data_type, 'table', undefined, undefined, undefined, currency_type, num_format_type, use_decimal, use_percent, other_format_config);

    return afterFormatted;
}




export const generate_data_story_v1 = (data, XAxis, YAxis, columnMeta) => {
    // Calculate total revenue
    const totalRevenue = data.reduce((acc, item) => acc + item[YAxis], 0);

    // Calculate average revenue
    const averageRevenue = totalRevenue / data.length;

    // Initialize variables for maximum and minimum values
    let maxRevenue = 0;
    let minRevenue = Infinity;
    let maxDepartment = '';
    let minDepartment = '';
    let belowAverageCount = 0;
    let aboveAverageCount = 0;
    let belowAverageDepartments = [];
    let aboveAverageDepartments = [];

    data.forEach(item => {
        if (item[YAxis] > maxRevenue) {
            maxRevenue = item[YAxis];
            maxDepartment = item[XAxis];
        }
        if (item[YAxis] < minRevenue) {
            minRevenue = item[YAxis];
            minDepartment = item[XAxis];
        }
        if (item[YAxis] < averageRevenue) {
            belowAverageCount++;
            belowAverageDepartments.push(item[XAxis]);
        } else if (item[YAxis] > averageRevenue) {
            aboveAverageCount++;
            aboveAverageDepartments.push(item[XAxis]);
        }
    });


    const generate_html_list_code = (listTitle, childrenData) => {
        let html = `<ul>\n`;
        if (listTitle) {
            html = `<h3>${listTitle}:</h3>\n<ul>\n`;
        }

        childrenData.forEach(item => {
            html += `  <li>${item}</li>\n`;
        });

        html += `</ul>`;

        return html;
    };

    // Calculate percentage contribution
    const maxPercentage = ((maxRevenue / totalRevenue) * 100).toFixed(2);
    const minPercentage = ((minRevenue / totalRevenue) * 100).toFixed(2);
    const avg_below_data = generate_html_list_code(`The following ${XAxis} generating below the average`, belowAverageDepartments)
    const avg_above_data = generate_html_list_code(`The following ${XAxis} generating above the average`, aboveAverageDepartments)

    var result = `
        <h4>Your Total ${YAxis} is ${get_formated_cell_data(columnMeta, YAxis, totalRevenue)}</h4>
        <h4>Your Average ${YAxis} is ${get_formated_cell_data(columnMeta, YAxis, averageRevenue)}</h4>
        <p>Maximum ${YAxis} generated by '<strong>${maxDepartment}</strong>' ${XAxis} and this contributes <strong>${maxPercentage}%</strong> of ${YAxis}</p>
        <p>Minimum ${YAxis} generated by '<strong>${minDepartment}</strong>' ${XAxis} and this contributes <strong>${minPercentage}%</strong> of ${YAxis}</p>
    `;

    result += avg_below_data;
    result += avg_above_data;


    return result;
}


export const generate_ai_table = (data, question, id) => {

    var result = '';


    result += `
    <h2 style="color: #0073e6; font-size: 1.1rem; margin-top: 20px;">Here’s a Summary for Your Quick Reference:</h2>
    <table style="width: 100%; border-collapse: collapse; margin-top: 10px;">
        <thead>
            <tr style="background-color: #0073e6; color: #fff; text-align: left;">
`;

    // Check headers and generate table headers
    const headers = data?.length > 0 ? Object.keys(data[0]) : ['none'];
    
    headers.forEach(header => {
        result += `<th style="padding: 10px; border: 1px solid #ddd; text-transform:capitalize">${header}</th>`;
    });

    result += '</tr></thead><tbody>';

    // Generate table rows
    data?.forEach(row => {
        result += '<tr style="background-color: #f9f9f9; color: #333;">';
        headers.forEach(column => {
            var is_revenue = 'cost per lead' === column?.toLocaleLowerCase();
            const r_value = row[column];

            let value = r_value; // Default to r_value if not a number

            if (!isNaN(r_value) && r_value !== null && r_value !== undefined) {
                value = (column === 'Leads' || column === 'Bookings') ? r_value : (is_revenue ? '₹ ' + parseFloat(r_value).toFixed(2) : parseFloat(r_value).toFixed(2));
            }
            result += `<td style="padding: 8px; border: 1px solid #ddd; font-size: .9rem;background-color:#fff">${value}</td>`;

        });
        result += '</tr>';
    });

    result += `
        </tbody>
    </table>
`;

    console.log('Generated Summary:', result);
    return result;
}


export const generate_ai_summary = (data, question, id) => {



    let reg1 = /\b(source|sources|resource|resources)\b/i;
    let reg2 = /\b(campaign|campaigns)\b/i;
    let reg3 = /\b(consider)\b/i;

    let containsSourceOrResource = reg1.test(question);
    let containsCampings = reg2.test(question);
    let isConsider = reg3.test(question);

    if (isConsider) {


        let result = `<div id="${id}" style="padding: 0px;">`;

        result += `
            <h3 style="color: #333; font-size: 1.1rem; margin: 0px;font-weight: 300">
               By Analysing the YTD data, the Digital source stands out as the top resource for generating as per conversion booking ratio.
Why you should invest in Digital source:
            </h3>
            <h2 style="color: #0073e6; font-size: 1.1rem; margin-top: 20px;">Key Points of Digital source:</h2>
        `;

        // Define some statistics for display

        const digital_data = data?.find((d) => d?.['Source']?.toLocaleLowerCase() === "digital")
        var bookingPercentage = 20.46;
        var costPerLead = 57;
        var leadCount = 5069;
        var minRate = 2.05;
        var siteVisit = 0;

        if (digital_data) {
            // is_digital
            bookingPercentage = digital_data?.['Booking Rate'];
            costPerLead = digital_data?.['Cost Per Lead']
            leadCount = digital_data?.['Leads']
            minRate = digital_data?.['Lost Rate'];
            siteVisit = digital_data?.['Site Visit Conversion'];



        }

        // Bullet points with statistics
        result += `
            <ul style="list-style-type: disc; padding-left: 20px; color: #444;">
           
        `;

        if (bookingPercentage) {
            result += `<li>Booking Conversion Rate: <strong>${bookingPercentage?.toFixed(2)}%</strong></li>`
        }
        if (costPerLead) {
            result += ` <li>Cost per Lead (CPL): <strong>₹ ${costPerLead?.toFixed(2)}</strong></li>`
        }
        if (leadCount) {
            result += `<li>Total Leads: <strong>${leadCount}</strong></li></li>`
        }
        if (minRate) {
            result += `<li>Overall Lost: <strong>${minRate?.toFixed(2)}%</strong></li>`
        }
        if (siteVisit) {
            result += `<li>Site Visit Conversion: <strong>${siteVisit?.toFixed(2)}%</strong></li>`

        }

        result += ` </ul>`


        result += `
            <h2 style="color: #0073e6; font-size: 1.1rem; margin-top: 20px;">Key Strengths of Digital source:</h2>
            <ul style="list-style-type: disc; padding-left: 20px; color: #444;">
                <li>High <strong>Booking Conversion Percentage (${bookingPercentage?.toFixed(2)}%):</strong> This is a significant indicator of lead quality. A high booking rate means that the leads generated from digital channels are highly qualified and are converting into customers at a strong rate.</li>
                <li><strong>Low Lost Rate (${minRate?.toFixed(2)}%):</strong> Digital leads are not being lost in large numbers, showing that once leads enter your pipeline, they're well-targeted and more likely to convert.</li>
                <li>Scalability: Digital marketing allows you to scale efficiently across different platforms (Google Ads, social media, display ads, etc.), which gives you the ability to reach a large audience with the potential to optimize targeting and messaging for higher engagement and conversion.</li>
                <li>Measurable and Optimizable: Digital campaigns can be tracked in real time, so you can continually optimize ads, creatives, and landing pages to improve conversion rates. This flexibility is a huge advantage.</li>
            </ul>
        `;

        // Summary table with styling
        result += `
            <h2 style="color: #0073e6; font-size: 1.1rem; margin-top: 20px;">Here’s a Summary for Your Quick Reference:</h2>
            <table style="width: 100%; border-collapse: collapse; margin-top: 10px;">
                <thead>
                    <tr style="background-color: #0073e6; color: #fff; text-align: left;">
        `;

        // Check headers and generate table headers
        const headers = data?.length > 0 ? Object.keys(data[0]) : ['none'];
        headers.forEach(header => {
            result += `<th style="padding: 10px; border: 1px solid #ddd;">${header}</th>`;
        });

        result += '</tr></thead><tbody>';

        // Generate table rows
        data.forEach(row => {
            result += '<tr style="background-color: #f9f9f9; color: #333;">';
            headers.forEach(column => {
                var is_revenue = 'cost per lead' === column?.toLocaleLowerCase();
                const r_value = row[column];

                let value = r_value; // Default to r_value if not a number

                if (!isNaN(r_value) && r_value !== null && r_value !== undefined) {
                    value = (column === 'Leads' || column === 'Bookings') ? r_value : (is_revenue ? '₹ ' + parseFloat(r_value).toFixed(2) : parseFloat(r_value).toFixed(2));
                }
                result += `<td style="padding: 8px; border: 1px solid #ddd; font-size: .9rem;background-color:#fff">${value}</td>`;

            });
            result += '</tr>';
        });

        result += `
                </tbody>
            </table>
        `;

        result += '</div>';
        console.log('Generated Summary:', result);

        return result;

    }
    if (containsSourceOrResource) {


        let result = `<div id="${id}" style="padding: 0px;">`;

        result += `
            <h3 style="color: #333; font-size: 1.1rem; margin: 0px;font-weight: 300">
                By analyzing the YTD data, <strong>Digital</strong> resources stand out as the top performers in generating a high number of leads and their respective conversion booking ratios.
            </h3>
            <h2 style="color: #0073e6; font-size: 1.1rem; margin-top: 20px;">Key Points:</h2>
        `;

        // Define some statistics for display

        const digital_data = data?.find((d) => d?.['Source']?.toLocaleLowerCase() === "digital")
        var bookingPercentage = 20.46;
        var costPerLead = 57;
        var leadCount = 5069;
        var minRate = 2.05;

        if (digital_data) {
            // is_digital
            bookingPercentage = digital_data?.['Booking Rate'];
            costPerLead = digital_data?.['Cost Per Lead']
            leadCount = digital_data?.['Leads']
            minRate = digital_data?.['Lost Rate']

        }

        // Bullet points with statistics
        result += `
            <ul style="list-style-type: disc; padding-left: 20px; color: #444;">
                <li>Booking Conversion Percentage: <strong>${bookingPercentage?.toFixed(2)}%</strong></li>
                <li>Cost per Lead (CPL): <strong>₹ ${costPerLead?.toFixed(2)}</strong></li>
                <li>High Number of Leads: <strong>${leadCount}</strong></li>
                <li>Minimum Lost Rate: <strong>${minRate?.toFixed(2)}%</strong></li>
            </ul>
        `;

        // Additional Key Points section
        result += `
            <h2 style="color: #0073e6; font-size: 1.1rem; margin-top: 20px;">Additional Insights:</h2>
            <ul style="list-style-type: disc; padding-left: 20px; color: #444;">
                <li><strong>Digital</strong> is the top-performing channel for booking rate, lost rate, and leads. Despite a moderate CPL, its high booking rate and lead generation make it a strong investment.</li>
                <li><strong>IVR</strong> shows a strong booking rate and could be a valuable investment opportunity, especially if the lost rate is reduced and the lead process optimized.</li>
                <li><strong>Direct site</strong> walk-ins are cost-effective and, with optimizations to reduce the lost rate, could yield high returns.</li>
            </ul>
        `;

        // Summary table with styling
        result += `
            <h2 style="color: #0073e6; font-size: 1.1rem; margin-top: 20px;">Here’s a Summary for Your Quick Reference:</h2>
            <table style="width: 100%; border-collapse: collapse; margin-top: 10px;">
                <thead>
                    <tr style="background-color: #0073e6; color: #fff; text-align: left;">
        `;

        // Check headers and generate table headers
        const headers = data?.length > 0 ? Object.keys(data[0]) : ['none'];
        headers.forEach(header => {
            result += `<th style="padding: 10px; border: 1px solid #ddd;">${header}</th>`;
        });

        result += '</tr></thead><tbody>';

        // Generate table rows
        data.forEach(row => {
            result += '<tr style="background-color: #f9f9f9; color: #333;">';
            headers.forEach(column => {

                var is_revenue = 'cost per lead' === column?.toLocaleLowerCase();
                const r_value = row[column];

                let value = r_value; // Default to r_value if not a number

                if (!isNaN(r_value) && r_value !== null && r_value !== undefined) {
                    value = column === 'Leads' ? r_value : (is_revenue ? '₹ ' + parseFloat(r_value).toFixed(2) : parseFloat(r_value).toFixed(2));
                }

                result += `<td style="padding: 8px; border: 1px solid #ddd; font-size: .9rem;background-color:#fff">${value}</td>`;

            });
            result += '</tr>';
        });

        result += `
                </tbody>
            </table>
        `;

        result += '</div>';
        console.log('Generated Summary:', result);

        return result;
    } else if (containsCampings) {



        let result = `<div id="${id}" style="padding: 0px;">`;

        result += `
            <h3 style="color: #333; font-size: 1.1rem; margin: 0px;font-weight: 300">
               Best Digital Campaign: WhatsApp Bot (Digital Source)
            </h3>
            <h2 style="color: #0073e6; font-size: 1.1rem; margin-top: 20px;">Key Points:</h2>
        `;

        // Define some statistics for display

        const digital_data = data?.find((d) => d?.['Campaigns']?.toLocaleLowerCase() === "whatsapp bot")
        var bookingPercentage = 20.46;
        var costPerLead = 57;
        var leadCount = 5069;
        var minRate = 2.05;

        if (digital_data) {
            // is_digital
            bookingPercentage = digital_data?.['Booking Rate'];
            costPerLead = digital_data?.['Cost Per Lead']
            leadCount = digital_data?.['Leads']
            minRate = digital_data?.['Lost Rate']

        }

        // Bullet points with statistics
        result += `
            <ul style="list-style-type: disc; padding-left: 20px; color: #444;">
                <li>Booking Conversion Rate: <strong>${bookingPercentage?.toFixed(2)}%</strong></li>
                <li>Cost per Lead (CPL): <strong>₹ ${costPerLead?.toFixed(2)}</strong></li>
                <li>Total Leads Generated: <strong>${leadCount}</strong></li>
                <li>Moderate Lost Rate <strong>${minRate?.toFixed(2)}%</strong></li>
            </ul>
        `;


        result += `<p>The <strong>WhatsApp Bot</strong> campaign stands out as the best performer due to its strong booking conversion rate, low CPL, and high volume of leads. It demonstrates efficiency and scalability, making it a top choice for future investment</p>`
        // Additional Key Points section


        result += `
        <h2 style="color: #0073e6; font-size: 1.1rem; margin-top: 20px;">Worst Campaigns:</h2>
        <ul style="list-style-type: disc; padding-left: 20px; color: #444;">
            <li>Chatbot</li>
            <li>IVR Services</li>
        </ul>
    `;
        result += `
            <h2 style="color: #0073e6; font-size: 1.1rem; margin-top: 20px;">Reasons for Underperformance:</h2>
            <ul style="list-style-type: disc; padding-left: 20px; color: #444;">
                <li><strong>High CPL:</strong> Both campaigns have excessively high cost per leads, making them unsustainable for scaling or long-term investment.</li>
                <li><strong>Low Lead Volume:</strong> The campaigns generated very few leads (3 for Chatbot, 1 for IVR), limiting their potential for broader targeting or larger-
scale efforts.</li>
            </ul>
        `;

        // Summary table with styling
        result += `
            <h2 style="color: #0073e6; font-size: 1.1rem; margin-top: 20px;">Here’s a Summary for Your Quick Reference:</h2>
            <table style="width: 100%; border-collapse: collapse; margin-top: 10px;">
                <thead>
                    <tr style="background-color: #0073e6; color: #fff; text-align: left;">
        `;

        // Check headers and generate table headers
        const headers = data?.length > 0 ? Object.keys(data[0]) : ['none'];
        headers.forEach(header => {
            result += `<th style="padding: 10px; border: 1px solid #ddd;">${header}</th>`;
        });

        result += '</tr></thead><tbody>';

        // Generate table rows
        data.forEach(row => {
            result += '<tr style="background-color: #f9f9f9; color: #333;">';
            headers.forEach(column => {
                var is_revenue = 'cost per lead' === column?.toLocaleLowerCase();
                const r_value = row[column];

                let value = r_value; // Default to r_value if not a number

                if (!isNaN(r_value) && r_value !== null && r_value !== undefined) {
                    value = (column === 'Leads' || column === 'Bookings') ? r_value : (is_revenue ? '₹ ' + parseFloat(r_value).toFixed(2) : parseFloat(r_value).toFixed(2));
                }
                result += `<td style="padding: 8px; border: 1px solid #ddd; font-size: .9rem;background-color:#fff">${value}</td>`;

            });
            result += '</tr>';
        });

        result += `
                </tbody>
            </table>
        `;

        result += '</div>';
        console.log('Generated Summary:', result);

        return result;

    }

};


export const get_hardcoded_story = () => {

    const HTML1 = `
  <div>
        <h2 style="color: #2c3e50;margin-top:0px">📊 EBIT declination in Hiking Backpack </h2>
        <div style="marginBottom: '20px'">
          <p style="font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
            In August 2023, Hiking Backpack encountered significant drop in Earnings Before Interest and Taxes (EBIT).
          </p>
          <p>
            The most alarming trends include a surge in overall costs, increased depreciation, a drop in active subscribers, and substantial declines in key financial indicators such as Return on Capital Employed (ROCE) and operating margins.
          </p>
        </div>
        
           <h1 style="font-size: 24px; color: #000; border-bottom: 2px solid #000; padding-bottom: 10px;">
        📈 Detailed Analysis of Key Metrics
    </h1>

    <h2 style="font-size: 20px; color: #2C3E50;">
        📊 1. Earnings Before Interest and Taxes (EBIT)
    </h2>
    
     <ul style="font-size: 1rem; color: #333; list-style-type: disc; padding-left: 50px;">
        <li style="margin-bottom: 10px; font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
          The continuous decline in EBIT underscores the worsening profitability of businesses in Hiking Backpack. In August 2023, the decrease was notably sharp.
        </li>
    </ul>

    <h2 style="font-size: 20px; color: #2C3E50;">
        📈 2. Rising Overall Costs
    </h2>
    
     <ul style="font-size: 1rem; color: #333; list-style-type: disc; padding-left: 50px;">
        <li style="margin-bottom: 10px; font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
          In August 2023, the overall costs in Hiking Backpack increased by 30.10% compared to August 2022
        </li>

        <li style="margin-bottom: 10px; font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
          When comparing with the previous month, July 2023, costs rose by 0.61%.
        </li>
    </ul>

    <h2 style="font-size: 20px; color: #2C3E50;">
        💰 3. Increased Depreciation
    </h2>
    
     <ul style="font-size: 1rem; color: #333; list-style-type: disc; padding-left: 50px;">
        <li style="margin-bottom: 10px; font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
          The depreciation in Hiking Backpack rose by 15.86% when comparing August 2023 with August 2022.
        </li>

        <li style="margin-bottom: 10px; font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
          This increase in depreciation indicates potential challenges for asset management and operational costs.
        </li>
    </ul>

   


     <h2 style="font-size: 20px; color: #2C3E50;">
        📉 4. ROCE Growth Decline
    </h2>
    
     <ul style="font-size: 1rem; color: #333; list-style-type: disc; padding-left: 50px;">
        <li style="margin-bottom: 10px; font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
          The Return on Capital Employed (ROCE) witnessed a dramatic decrease of 316.66% in August 2023 compared to August 2022.
        </li>

        <li style="margin-bottom: 10px; font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
          This decline signals severe financial strain on capital efficiency.
        </li>
    </ul>


    <h2 style="font-size: 20px; color: #2C3E50;">
       📉  5. Operating Margin Decrease
    </h2>

    <ul style="font-size: 1rem; color: #333; list-style-type: disc; padding-left: 50px;">
        <li style="margin-bottom: 10px; font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
          The operating margin also saw a significant drop of 75.38% in August 2023 relative to August 2022.
        </li>

        <li style="margin-bottom: 10px; font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
          This decline suggests critical challenges in maintaining profitability amidst rising costs.
        </li>
    </ul>
      </div>
  `;


    return HTML1;
}

export const generate_auto_description = (data = [], XAxis, YAxis, columnMeta) => {
    // Calculate total revenue
    const totalRevenue = data?.reduce((acc, item) => acc + item[YAxis], 0);

    // Calculate average revenue
    const averageRevenue = totalRevenue / data?.length;

    // Initialize variables for maximum and minimum values
    let maxRevenue = 0;
    let minRevenue = Infinity;
    let maxDepartment = '';
    let minDepartment = '';
    let belowAverageCount = 0;
    let aboveAverageCount = 0;
    let belowAverageDepartments = [];
    let aboveAverageDepartments = [];

    data?.forEach(item => {
        if (item[YAxis] > maxRevenue) {
            maxRevenue = item[YAxis];
            maxDepartment = item[XAxis];
        }
        if (item[YAxis] < minRevenue) {
            minRevenue = item[YAxis];
            minDepartment = item[XAxis];
        }
        if (item[YAxis] < averageRevenue) {
            belowAverageCount++;
            belowAverageDepartments.push(item[XAxis]);
        } else if (item[YAxis] > averageRevenue) {
            aboveAverageCount++;
            aboveAverageDepartments.push(item[XAxis]);
        }
    });


    const generate_html_list_code = (listTitle, childrenData) => {
        let html = `<ul>\n`;
        if (listTitle) {
            html = `<h3>${listTitle}:</h3>\n<ul>\n`;
        }

        childrenData.forEach(item => {
            html += `  <li>${item}</li>\n`;
        });

        html += `</ul>`;

        return html;
    };


    // Calculate percentage contribution
    const maxPercentage = ((maxRevenue / Math.abs(totalRevenue)) * 100).toFixed(2);
    const minPercentage = ((minRevenue / Math.abs(totalRevenue)) * 100).toFixed(2);

    // const avg_below_data = generate_html_list_code(`The following ${XAxis} generating below the average`, belowAverageDepartments)
    // const avg_above_data = generate_html_list_code(`The following ${XAxis} generating above the average`, aboveAverageDepartments)

    var result = `<div  style="color: #333; line-height: 1.6rem;">`;

    result += `
    <h4  style="margin-bottom: 0.6rem; margin-top:0.74rem; color: #2c3e50; font-weight: 300;" >
            Your total <strong>${YAxis?.split("_nfx_")?.join("-")}</strong> is ${get_formated_cell_data(columnMeta, YAxis, totalRevenue)} 
            and your average <strong>${YAxis?.split("_nfx_")?.join("-")} is ${get_formated_cell_data(columnMeta, YAxis, averageRevenue)}.
        </h4>
        <p style="margin-bottom: 15px; font-weight: 300">
            The highest ${YAxis?.split("_nfx_")?.join("-")} was generated by the '<strong style="color: #2980b9;">${maxDepartment}</strong> contributing 
            <strong style="color: #2980b9;">${maxPercentage}%</strong> of the total. 
            The lowest ${YAxis?.split("_nfx_")?.join("-")} was generated by the '<strong style="color: #e74c3c;">${minDepartment}</strong>, 
            contributing just <strong style="color: #e74c3c;">${minPercentage}%</strong> of the total.
        </p>
    `;

    // result += `
    //     <p style="margin-bottom: 10px; font-weight: 300">
    //         The following ${XAxis} are generating **below** the average ${YAxis}: 
    //         <strong style="color: #e74c3c;">${belowAverageDepartments?.join(", ")}</strong>.
    //     </p>
    // `;

    //     result += `
    //     <p style="margin-bottom: 10px; font-weight: 300">
    //         The following ${XAxis} are generating <strong>below</strong> the average ${YAxis}: 
    //     </p>
    //     <ul style="list-style-type: disc; margin-left: 20px; padding-left: 10px; color: #e74c3c; font-weight: 400;">
    //         ${belowAverageDepartments?.map(department => `<li style="margin-bottom: 5px;">${department}</li>`).join("")}
    //     </ul>
    // `;


    // result += `
    //     <p style="margin-bottom: 10px; font-weight: 300">
    //         The following ${XAxis} are generating **above** the average ${YAxis}: 
    //         <strong style="color: #27ae60;">${aboveAverageDepartments?.join(", ")}</strong>.
    //     </p>
    // `;

    //     result += `
    //     <p style="margin-bottom: 10px; font-weight: 300">
    //         The following ${XAxis} are generating <strong>above</strong> the average ${YAxis}: 
    //     </p>
    //     <ul style="list-style-type: disc; margin-left: 20px; padding-left: 10px; color: #27ae60; font-weight: 400;">
    //         ${aboveAverageDepartments?.map(department => `<li style="margin-bottom: 5px;">${department}</li>`).join("")}
    //     </ul>
    // `;

    result += `</div>`;
    return result;
}




export const get_report_in_list_formatted_mode = (reports = []) => {

    const clone_reports = reports ? JSON.parse(JSON.stringify(reports)) : [];

    (clone_reports || []).filter((r) => !r.deleted).forEach(report => {

        report.created_user_name = report.created_user?.name;
        report.report_type = get_display_report_type(report.type)

        const report_item = report?.report_items?.length > 0 ? report?.report_items[0] : undefined;
        const report_item_types = ['dashboard', 'dashboard_sql', 'dashboard_config_query_builder'];
        const filter_valid_report_items = report?.report_items?.length > 0 && report?.report_items.filter((r => report_item_types.indexOf(r.report_item_type) > -1 && ['label', 'group'].indexOf(r.render_mode) == -1))

        report.number_of_report_item = filter_valid_report_items?.length || 0

        if (report_item) {
            if (report.type === 'sql') {
                report.display_fields = report_item.sql_query
            }
            if (report.type === 'config_query_builder') {
                report.display_fields = report_item.display_columns
            }
        }
    })
    return clone_reports;
}







const return_date_string_from_generic_date = (g_object, type) => {

    let date_to_use = '';

    const single_date = g_object?.['single_date'];
    const start_date = g_object?.['date_from'];
    const end_date = g_object?.['date_to'];
    const month = g_object?.['month'];
    const year = g_object?.['year'];

    if (month > -1 && year) date_to_use = format_date(new Date(year, (month - 1), 1, 0, 0, 0, 0));
    else if (start_date && end_date) date_to_use = ''
    else if (single_date) date_to_use = single_date
    else date_to_use = new Date()

    if (date_to_use) {

        const new_date = new Date(date_to_use);
        const month = new_date?.getMonth();
        const year = new_date?.getFullYear();

        if (type === 'nf_month') return FULL_MONTHS[month + 1];
        if (type === 'nf_year') return year;
        if (type === 'nf_date') return formatValueByDataType(date_to_use, 4);

    }
}




export const create_report_nf_date_title = (filter_data, advance_date_config, generic_date, why_addition_filter, title = '') => {


    if (why_addition_filter) {

        if (why_addition_filter && Object.keys(why_addition_filter)?.length > 0) {

            var title = title || '';

            const date_filter_text = why_addition_filter?.['dim_date'];
            let ui_filter_text = why_addition_filter?.dim?.join(" and ");


            if (title?.indexOf("__UI_FILTER__") > -1) {
                title = title?.replace("__UI_FILTER__", ui_filter_text);
            }

            if (title?.indexOf("__py__") > -1 && date_filter_text) {
                const previousYear = date_filter_text - 1;
                title = title?.replace("__py__", previousYear);
            }
            if (title?.indexOf("__ppy__") > -1 && date_filter_text) {
                const previousYear = date_filter_text - 2;
                title = title?.replace("__ppy__", previousYear);
            }

            if (title?.indexOf("__cy__") > -1) {
                title = title?.replace("__cy__", date_filter_text);
            }

            // if (final_question.indexOf("__cy__") > -1) {
            //     const date_filter_text = why_addition_filter?.['dim_date']
            //     title = title.replace("__DATE__FILTER__", date_filter_text);
            // }
            return title
            // why_addition_filter
        }
    }
    if (advance_date_config?.length > 0) {
        advance_date_config?.forEach((config, index) => {
            title = title?.replace(`:nf_period${index + 1}`, config.display_value);
        });
        return title;
    }
    else if (filter_data) {

        if (title?.length > 0) {
            return replaceValueInTitle(title, filter_data)
        }
    }

    else if (generic_date) {

        if (title && title.indexOf(':nf_date') > -1) {
            const d = return_date_string_from_generic_date(generic_date, 'nf_date')
            return title.split(':nf_date').join(d);
        }
        if (title && title.indexOf(':nf_month') > -1) {
            const m = return_date_string_from_generic_date(generic_date, 'nf_month')
            return title.split(':nf_month').join(m);
        }
        if (title && title.indexOf(':nf_year') > -1) {
            const y = return_date_string_from_generic_date(generic_date, 'nf_year')
            return title.split(':nf_year').join(y);
        } else {
            return title;
        }

    } else return title;

}



export const console_logger = (msg, is_return) => {
    const d = new Date();
    const m = d.getMinutes()
    const s = d.getSeconds();
    const h = d.getHours()
    const m_s = d.getMilliseconds()

    const d_string = h + "-" + m + ":" + s + ":" + m_s;
    const full_msg = "___NF__LOG__ " + msg + "--" + d_string;
    if (is_return) return d_string;
    console.log(full_msg)

}

export const get_time_format_v1 = (date) => {
    const d = new Date(date);
    const m = d.getMinutes()
    const s = d.getSeconds();
    const h = d.getHours()
    const m_s = d.getMilliseconds()

    return {
        hour: h,
        minutes: m,
        seconds: s,
        milli_seconds: m_s

    }
}


export const proper_case = text => {
    let return_me = '';
    /**
     * LOGIC for PROPER CASE, do it only when we don't have an alias with a proper case already.
     * 
     * if we have a proper case stored in alias, for example, POS, MLC etc, it means that it is already 
     */

    let capital_letter_found = false;

    text.split('').forEach(letter => {
        const ascii = (letter + '').charCodeAt();

        if (ascii >= 65 && ascii <= 90) {
            // capital found
            capital_letter_found = true;
        }
    });

    if (capital_letter_found) return text;


    text.split(' ').forEach(word => {
        if (word.trim().length === 0) return;
        return_me = return_me + ' ' + word.trim()[0].toLocaleUpperCase() + word.trim().toLocaleLowerCase().substring(1, word.trim().length);
    });

    return return_me.trim();
};


export const get_date_filter_value = (base_data) => {

    const clone_base_data = base_data ? { ...base_data } : {};

    var type = base_data["type"];

    const year = base_data["year"];
    const month = base_data["month"] || 1;
    const single_date = clone_base_data["single_date"];
    const single_type = clone_base_data["single_type"];

    const start_date = clone_base_data["start_date"] || clone_base_data["range_start_date"];
    const end_date = clone_base_data["end_date"] || clone_base_data["range_end_date"];

    const quick_date = clone_base_data["quick_date"]


    if (single_type === "year") {
        type = "year"
    }
    if (single_type === "month") {
        type = "month"
    }
    // if (single_type == "year") {
    //     type = "year"
    // }


    switch (type) {
        case "quick_date":
            console.log("clone_base_data", clone_base_data)

            const value_key = quick_date;
            const display_key = quick_date_options?.find((o) => o.value === value_key)?.label;
            return {
                "value": display_key
            }
        case "month":
        case "month_year":
            return {
                "value": MONTHS[month] + " " + year
            };
        case "year":
            return {
                "value": year
            };
        case "range":
            return {
                "value": start_date + " To " + end_date
            };
        default:
            return {
                "type": "single",
                "value": single_date || new Date(),
            }
    }
}

export const compareArrays = (array1, array2, ignore_keys = ["modified", "nf_formula_conditions"]) => {
    if (array1?.length !== array2?.length) {
        return ['changed']
    };

    let differences = [];
    array1.forEach((obj1, index) => {
        Object.keys(obj1).forEach(key => {
            if (ignore_keys.indexOf(key) == -1 && !(key in array2[index])) {
                differences.push({ key: key, value1: obj1[key], value2: undefined });
            } else if (ignore_keys.indexOf(key) == -1 && obj1[key] !== array2[index][key]) {
                differences.push({ key: key, value1: obj1[key], value2: array2[index][key] });
            }
        });
        Object.keys(array2[index]).forEach(key => {
            if (ignore_keys.indexOf(key) == -1 && !(key in obj1)) {
                differences.push({ key: key, value1: undefined, value2: array2[index][key] });
            }
        });
    });
    return differences;

}



export const objectsAreEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    const ignoredKey = 'nf_formula_conditions';

    // Remove ignored key from comparison
    const filteredKeys1 = keys1.filter(key => key !== ignoredKey);
    const filteredKeys2 = keys2.filter(key => key !== ignoredKey);

    // Check if number of keys (excluding ignored key) is the same
    if (filteredKeys1.length !== filteredKeys2.length) {
        return filteredKeys1.concat(filteredKeys2); // Return all keys as changed if number of keys (excluding ignored key) is different
    }

    // Check if values of each key (excluding ignored key) are the same
    for (let key of filteredKeys1) {
        if (obj1[key] !== obj2[key]) {
            return [key]; // Return key that has changed
        }
    }

    // Return empty array if no differences found
    return [];
}




export const get_display_value_of_filter_v1 = (text) => {

    if (text) {
        const v1 = text?.toString()?.split("__nfx__n__");
        const v2 = v1 && v1[v1.length - 1]?.toString()?.split("__nfx__v__");
        const v3 = v2 && v2[v2.length - 1];
        return v3

    } else {
        return ''
    }
}
