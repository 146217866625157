import React from "react";

const Edit = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color :"#008000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 46.000000 40.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,40.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M206 268 c-74 -114 -86 -139 -86 -175 0 -47 2 -48 47 -32 24 8 50 40
            118 145 93 143 93 144 37 177 l-30 17 -86 -132z m128 87 c17 -13 14 -20 -59
            -135 -75 -116 -105 -150 -132 -150 -31 0 -9 57 68 175 45 69 86 125 93 125 6
            0 19 -7 30 -15z"
        />
        <path
          d="M15 10 c-4 -7 70 -10 215 -10 145 0 219 3 215 10 -8 14 -422 14 -430
            0z"
        />
      </g>
    </svg>
  );
};

export default Edit;
