import * as actions from '../action-types/connection';

const initialState = {
    type: actions.CONNECTION_INIT,
    db_infos: undefined,
    tablesOfDb: undefined,
    tableData: undefined,
    tableColumns: undefined,
    sqlQueryResult: undefined,
};


const ConnectionReducer = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {

        case actions.TEST_CONNECTION_SUCCESS:
            return {
                ...state,
                type: action.type,
            }

        // case actions.TEST_CONNECTION_SUCCESS: 
        //     return {
        //         type: action.type
        //     }

        case actions.GET_DB_INFO_SUCCESS:
            return {
                ...state,
                type: action.type,
                db_infos: action.data
            }

        case actions.GET_DB_OF_TABLE_SUCCESS:
            return {
                ...state,
                type: action.type,
                tablesOfDb: action.data
            }
        case actions.CLEAR_DB_OF_TABLE_SUCCESS:
            return {
                ...state,
                type: action.type,
                tablesOfDb: undefined
            }

        case actions.GET_DB_OF_TABLE_DATA_SUCCESS:
            return {
                ...state,
                type: action.type,
                tableData: action.data
            }

        case actions.CLEAR_DB_OF_TABLE_DATA:
            return {
                ...state,
                type: action.type,
                tableData: undefined
            }
        case actions.GET_TABLE_AND_COLUMNS_OF_DB:
            return {
                ...state,
                type: action.type,
                tableColumns: action.data && action.data.table_name ? action.data.table_name : undefined
            }
        case actions.CLEAR_TABLE_AND_COLUMNS_OF_DB:
            return {
                ...state,
                type: action.type,
                tableColumns: undefined
            }
        
        case actions.GET_SQL_QUERY_SUCCESS:
            return {
                ...state,
                type: action.type,
                sqlQueryResult: action.data
            }

        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
            return { ...state };
    }
};

export default ConnectionReducer;