import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '../hoc/button';


const MainWrapper = styled.div`
    width: 100%;
    height: 13rem;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 20rem;
    overflow-y: auto;

    textarea{
        width: 100%;
        height: 8rem;
        border: 1px solid #ccc;;
        padding: 10px;
        box-sizing: border-box;
        font-size: 1rem;
    }

    .close{
        position: absolute;
        right: .5rem;
        top: .5rem;
    }

    p{
        text-align: center;
    }

    table{
        width: 100%;
        td{
            padding: 10px 0px;
        }
    }
`;

const OptimizerPopup = (props) => {

    const { close, popupKey, option, data } = props;
    const { optimizer_hints, set_optimizer_hints } = data;

    const [localFieldData, setLocalFieldData] = useState(undefined)

    useEffect(() => {
        setLocalFieldData(optimizer_hints)
    }, [optimizer_hints])



    return (
        <MainWrapper>
            <div>
                <textarea
                    placeholder={"Type Your Compiler Here"}
                    value={localFieldData ? localFieldData : ''}
                    onChange={(e) => {
                        setLocalFieldData(e.target.value)
                    }}
                />
            </div>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: 'auto',
                marginLeft: 'auto'

            }}>
                <Button
                    primary={true}
                    bgColor={'linear-gradient(to right, #3E9EFF, #737DFF)'}
                    onClick={() => {
                        set_optimizer_hints(localFieldData)
                        close(option, popupKey)
                    }}
                // mR="1rem"
                >
                    Save
                </Button>
                {/* <Button
                    primary={true}
                    onClick={() => {
                        close(option, popupKey)
                    }}
                    bgColor="#9caeb5"
                    mR="1rem"
                >
                    Cancel
                </Button> */}
            </div>

        </MainWrapper>
    )
}

export default OptimizerPopup;
