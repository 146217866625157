import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import { getHintByTableName, saveHints, clearHints, getTablesForHints, getHintsWithTableAndColumnName, clearEditorState, getTableInfoByTblName, saveTableInfo, saveTableColumnValues } from '../../actions/hints';
import { getMongoDbTableData } from '../../actions/helper';

import SearchBox from './search-box/search-box';
import Footer from '../footer'
import PolusSvg from '../svg/polus';
import BackSvg from '../svg/back';
import SelectDisplayField from '../select-display-field';
import HintTable from './hint-table/hint-table';
import TagTable from './tag/tag-table';
import HintEditor from './hint-table/hint.edit';
import TagEditor from './tag/tag.edit';
import * as enums from '../../utils/enums.js';
import { Wrapper, EditorInnerWrapper, RowWrapper, TableAnimationWrapper, TagTableAnimationWrapper } from './train.your.polus.styled';
import { saveInSession, getFromSession } from '../../utils/session.helper';
import { constants } from '../../utils/constants';
import { getDbInfoId, isDefaultLiveDb, showPopup } from '../../utils';
import { Button } from '../hoc/button';
import TitleHeader from '../title-header'
import { dispatchHeaderInformation } from '../../actions/common';
import ContextPopup from './hint-table/add.context.popup';
import DublicateHintPopup from './dublicate.hint.popup';
import DbSwitcher from '../db.switcher';
import TableSkeleton from '../Skeleton/table-skeleton.js';
import ConfirmBox from '../confirm-box/index.js';
import { getStringHintsFromServer } from '../../actions/report.js';


const ConfigEditor = props => {

    const { getHintByTableName, hints, getTablesForHints, tableNameOfHints, getHintsWithTableAndColumnName, hintsWithTableColumnsName, clearEditorState, getMongoDbTableData, db_infos } = props;

    const [hintsState, setHintsState] = useState(undefined);

    const [tables, setTables] = useState(undefined);
    const [activeTable, setActiveTable] = useState(undefined);
    const [activeTag, setActiveTag] = useState(undefined);
    const [whichFieldHide, setWhichFieldHide] = useState(undefined);
    const [tags, setTags] = useState(undefined);
    const [tagList, setTagList] = useState(undefined);
    const [tagTables, setTagTables] = useState(undefined);
    const [tableSessionValue, setTableSessionValue] = useState([]);
    const [recentTableSearch, setRecentTableSearch] = useState(undefined);
    const [dbType, setDbType] = useState(undefined);

    const [tagSessionValue, setTagSessionValue] = useState([]);
    const [recentTagSearch, setRecentTagSearch] = useState(undefined);

    const [firstBlock, setFirstBlock] = useState(undefined);
    const [secondBlock, setSecondBlock] = useState(undefined);


    const [cached_db_info_id, set_cached_db_info_id] = useState(undefined);

    const [active_db_info, set_active_db_info] = useState(undefined);
    const [isLoding, setIsloding] = useState(false);



    const IS_DEFAULT_LIVE_DB = isDefaultLiveDb()



    const [firstBlockRefStyle, setFirstBlockRefStyle] = useState({
        width: undefined,
        height: undefined
    })

    const firstBlockRef = useRef(null);

    useEffect(() => {
        if (firstBlockRef.current) {
            let firstEl = firstBlockRef.current.getBoundingClientRect();
            setFirstBlockRefStyle({
                width: firstEl.width,
                height: firstEl.height
            })
        }
    }, [props])

    useEffect(() => {
        if (props.dublicateHints && props.dublicateHints.length > 0) {
            // console.log("props.dublicateHints", props.dublicateHints)
            showDublicateHintPopup(props.dublicateHints)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dublicateHints])





    // saroj kr added this code For BARC 
    // Jan 5 


    useEffect(() => {

        if (cached_db_info_id) {

            getTablesForHints(cached_db_info_id);
            getHintsWithTableAndColumnName(undefined, cached_db_info_id);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cached_db_info_id])




    useEffect(() => {
        let _page_info = {
            title: 'TRAIN YOUR POLUS',
            isBack: true,
        }
        props.dispatchHeaderInformation(_page_info)

        getTablesForHints();
        getHintsWithTableAndColumnName();

        let recentTable = getFromSession(constants.SESSION_KEYS.RECENT_TABLE);
        let recentTag = getFromSession(constants.SESSION_KEYS.RECENT_TAG);

        if (recentTag) {
            setTagSessionValue(JSON.parse(recentTag))
        }

        if (recentTable) {
            setTableSessionValue(JSON.parse(recentTable))
        }
        return () => {
            clearEditorState();
        }
        // eslint-disable-next-line
    }, []);





    useEffect(() => {
        if (tableNameOfHints && tableNameOfHints.length > 0) {
            const tempTables = [...tableNameOfHints];
            const names = [];

            tempTables.sort((a, b) => {
                if (a.table_name < b.table_name) { return -1; }
                if (a.table_name > b.table_name) { return 1; }
                return 0;
            }).forEach(table => names.push(table.table_name));

            setTables(names);

        }
    }, [tableNameOfHints]);


    useEffect(() => {
        if (hintsWithTableColumnsName && hintsWithTableColumnsName.length > 0) {
            const tempObject = {};
            const tempTagList = [];

            hintsWithTableColumnsName.forEach(d => {
                const tableName = d.table_name;
                const columnName = d.column_name;
                const id = d.id;

                const values = d.values;

                if (values && values.length > 0) {
                    const tempValues = [...values];

                    tempValues.forEach(v => {
                        const trimmedValue = typeof v === 'string' ? v.trim().toLowerCase() : v.trim();

                        tempTagList.push(v);

                        if (typeof tempObject[trimmedValue] === 'undefined') {
                            tempObject[trimmedValue] = [];
                        }

                        const tempTableColumn = {
                            columnName,
                            tableName,
                            id,
                            values: tempValues
                        }

                        tempObject[trimmedValue].push(tempTableColumn)
                    });
                }
            });

            setTags(tempObject);
            setTagList(Array.from(new Set(tempTagList)));
            setIsloding(false);

        }
    }, [hintsWithTableColumnsName]);



    useEffect(() => {
        if (hints && hints.results && hints.results.length > 0) {
            const tempColumns = [];

            const tempHints = [...hints.results];

            tempHints.sort((a, b) => {
                if (a.column_name < b.column_name) { return -1; }
                if (a.column_name > b.column_name) { return 1; }
                return 0;
            });

            tempHints.forEach(data => {
                if (typeof data.column_name !== 'undefined') {
                    tempColumns.push(data.column_name);
                }
            });

            // setColumns(tempColumns);
            setHintsState(tempHints);
            setDbType(hints.db_type);
            setIsloding(false);
        }
        else if (hints && hints.length === 0) {
            setHintsState([]);
            setIsloding(false);
        }

        // if(hints){
        // }

    }, [hints]);

    console.log('hints?.results', hints)
    console.log('isLoding', isLoding)
    console.log(' hintsState={hintsState', hintsState)
    console.log('tagTables={tagTables', tagTables)

    const _getHintsFromServer = value => {
        if (value) {
            setIsloding(true);
            let tempSessionVal = tableSessionValue ? tableSessionValue : [];

            let tempRecent = [...tempSessionVal];

            if (tempRecent.indexOf(value) === -1) {
                tempRecent.unshift(value)
            }

            getHintByTableName(value, undefined, cached_db_info_id);
            getMongoDbTableData(undefined);
            setActiveTable(value);

            // recent table search save in session 
            setRecentTableSearch(tempRecent);

            saveInSession(constants.SESSION_KEYS.RECENT_TABLE, JSON.stringify(tempRecent));

            let recentTable = getFromSession(constants.SESSION_KEYS.RECENT_TABLE);

            if (recentTable) {
                setTableSessionValue(JSON.parse(recentTable))
            }
            FocusOnInput("tag");
        }
    };

    const _saveHintsFromEditor = hint => {
        // console.log("hint", hint);
        const tempHint = hint;
        // const tempHintsState = [...hintsState]
        // tempHintsState && tempHintsState.length > 0 && tempHintsState.forEach((hintData, index) => {
        //     if (hintData.id === hint.id) {
        //         tempHintsState[index] = hint;
        //     }
        // });

        // setHintsState(tempHintsState);
        props.saveHints([tempHint], activeTable, cached_db_info_id);
        

    };

    const _saveTag = (value, isDelete, hintData) => {
        // console.log("value", isDelete, hintData)
        const tempActiveTag = typeof activeTag === 'string' ? activeTag.trim().toLowerCase() : activeTag;
        const tag = hintData ? hintData : JSON.parse(JSON.stringify(tags[tempActiveTag]));
        const newValue = value;
        const tempHintWithTable = hintsWithTableColumnsName && hintsWithTableColumnsName.length > 0 ? [...hintsWithTableColumnsName] : [];
        const editedValues = [];


        if (tempHintWithTable && tempHintWithTable.length > 0) {

            tempHintWithTable.forEach(data => {
                const tableName = data.table_name;
                const columnName = data.column_name;
                const values = data.values;
                const id = data.id

                tag && Array.isArray(tag) && tag.length > 0 && tag.forEach(t => {
                    if (id === t.id) {
                        let tempValues = values;

                        tempValues && tempValues.length > 0 && tempValues.forEach((tempValue, index) => {
                            const valueToCheck = typeof tempValue === 'string' ? tempValue.trim().toLowerCase() : tempValue;

                            if (valueToCheck === tempActiveTag && !isDelete) {
                                tempValues[index] = newValue
                            }
                            else if (valueToCheck === tempActiveTag && isDelete) {
                                tempValues.splice(index, 1);
                            }
                        })

                        data.values = tempValues.join(',');

                        const returnObj = {
                            values: tempValues,
                            table_name: tableName,
                            column_name: columnName,
                            modified: true,
                            id
                        };

                        editedValues.push(returnObj);
                    }
                })

            });
        }

        props.saveHints(editedValues, activeTable, cached_db_info_id);


        if (!isDelete) {
            setActiveTag(value);
            setTagTables(editedValues);
        }
        else {
            const tempTags = Object.assign({}, tags);
            const tempTagList = [...tagList];

            const index = tempTagList.indexOf(tempActiveTag);

            tempTagList.splice(index, 1);

            delete tempTags[tempActiveTag];

            setTags(tempTags);
            setActiveTag(undefined);
            setTagTables(undefined);
            setTagList(tempTagList);
        }
    };

    const _saveContext = (hints) => {
        let temp_hints = hints;
        props.saveHints([temp_hints], activeTable, cached_db_info_id);
    }


    const _saveTblContent = (info) => {
        let temp_info = info;
        props.saveTableInfo([temp_info], undefined, cached_db_info_id)
    }



    const showHintPopup = hint => showPopup(undefined, undefined, enums.default.popupType.element, HintEditor, { hintData: hint, func: { saveHints: _saveHintsFromEditor } })


    const showContextPopup = (hint, tableName) => showPopup(undefined, undefined, enums.default.popupType.element, ContextPopup, {
        hintData: hint,
        label: 'Type Column Context',
        func: { _saveFunction: _saveContext }
    })

    const showDublicateHintPopup = hint => showPopup(undefined, undefined, enums.default.popupType.element, DublicateHintPopup, { hintData: hint, activeTable: activeTable, func: { saveHints: props.saveHints, db_info_id: cached_db_info_id } })



    const showTblContextPopup = (hint, tableName) => showPopup(undefined, undefined, enums.default.popupType.element, ContextPopup, {
        hintData: hint,
        tableName: tableName,
        label: 'Type Table Context',

        func: { _saveFunction: _saveTblContent }
    })


    const saveValueForCache = (_hint_) => {

        const msg = _hint_?.isCached ? 'Wanna clear the Cache for this Attribute?' : 'Wanna Cache this Attribute?';

        const __yes = (_hint_) => {
            props.saveTableColumnValues(_hint_?.table_name, _hint_?.column_name, _hint_?.values, _hint_?.db_info_id, _hint_?.isCached)
            props.getStringHintsFromServer()

        }

        showPopup(undefined, msg, enums.default.popupType.element, ConfirmBox, { func: { setYes: () => __yes(_hint_) } })
    }


    const showEditTagPopup = tag => showPopup(undefined, undefined, enums.default.popupType.element, TagEditor, { tagData: tag, func: { saveHints: _saveTag } })

    // const [gar, setGar] = useState(undefined);



    useEffect(() => {

        if (db_infos?.length > 0) {

            const db_id = getDbInfoId()

            const db_info_id_to_use = IS_DEFAULT_LIVE_DB ? db_id : (db_id && db_id + "__nf__db__cached__");

            set_active_db_info(db_id)
            set_cached_db_info_id(db_info_id_to_use);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.db_infos])




    const FocusOnInput = (value) => {

        if (value === 'tag') {
            setSecondBlock('s1');
            setTimeout(() => {
                setSecondBlock('s2')
            }, 300);
        }

        if (value === 'table') {
            setTimeout(() => {
                setFirstBlock('s1');
            }, 200);

        }
    };




    const deleteSingleTag = (table, index) => {
        const tempObject = Object.assign({}, table);
        const tempTagList = tagList && tagList.length > 0 ? [...tagList] : [];
        const tempTags = Object.assign({}, tags);
        const tempTagTables = [...tagTables];
        const valueToCheck = typeof activeTag === 'string' ? activeTag?.trim().toLowerCase() : activeTag?.trim();

        const values = tempObject.values;

        values && values.length > 0 && values.forEach((v, valuesIndex) => {
            const tempValue = typeof v === 'string' ? v.trim().toLowerCase() : v.trim();

            if (valueToCheck === tempValue) {
                values.splice(valuesIndex, 1);
            }
        });


        const tempTagValue = tempTags[valueToCheck];

        tempTagValue && tempTagValue.length > 0 && tempTagValue.forEach((data, index) => {
            if (data.id === table.id) {
                tempTagValue.splice(index, 1);
            }
        });

        tempTags[activeTag] = tempTagValue;

        tempTagTables.splice(index, 1);
        tempTagList.splice(index, 1);

        tempObject.values = values;

        tempObject.modified = true;

        setTagList(tempTagList);
        setTags(tempTags);
        setTagTables(tempTagTables);

        const dataToSave = [];

        dataToSave.push(tempObject);

        props.saveHints(dataToSave, activeTable, cached_db_info_id);
    };



    const _removeHint = (hint, columnId) => {

        let result = undefined
        let tempArray = [...hintsState];

        tempArray && tempArray.forEach((data, index) => {
            if (data.id === columnId) {

                let final_data = Object.assign({}, data)
                let tempValues = [...data.values]

                tempValues && tempValues.length > 0 && tempValues.forEach((val, index) => {
                    if (val === hint) {
                        tempValues.splice(index, 1);
                    }

                });

                final_data['values'] = tempValues;
                final_data.modified = true;

                result = final_data;
            }

        })
        props.saveHints([result], activeTable, cached_db_info_id);
    }


    const _convertTagsToTable = value => {


        let tempArray = [];

        const _value = Array.isArray(value) ? value.join(' ').trim() : value;

        const valueToCheck = typeof _value === 'string' ? _value.trim().toLowerCase() : _value.trim();

        if (valueToCheck) {

            let tempSessionVal = tagSessionValue ? tagSessionValue : []
            let tempRecent = [...tempSessionVal];
            if (tempRecent.indexOf(valueToCheck) === -1) {
                tempRecent.unshift(valueToCheck)
            }

            setRecentTagSearch(tempRecent);

            saveInSession(constants.SESSION_KEYS.RECENT_TAG, JSON.stringify(tempRecent));

            let recentTag = getFromSession(constants.SESSION_KEYS.RECENT_TAG);

            if (recentTag) {
                setTagSessionValue(JSON.parse(recentTag))
            }

            if (tags[valueToCheck]) {
                tempArray = [...tags[valueToCheck]]
            }

            setActiveTag([_value])
            setTagTables(tempArray)
            FocusOnInput("table");

        }

    }



    return (
        <div
            style={{
                marginTop: '1rem',
                // boxShadow: '0px 2px 6px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                backgroundColor: '#fff',
                height: 'calc(100vh - 60px)',
                boxSizing: 'border-box',
                paddingTop: '10px'
            }}>
            <div style={{ marginTop: '1rem', paddingLeft: '1rem' }}>


                {active_db_info && (
                    <DbSwitcher
                        db_info_id={[active_db_info]}
                        reporting_db_info_id={cached_db_info_id}
                        set_reporting_db_info_id={(value) => {

                            // let's celar state 
                            setTables(undefined)
                            setTagList(undefined)
                            setActiveTable(undefined)

                            set_cached_db_info_id(value)
                            setTagTables(undefined);
                            setWhichFieldHide(undefined);
                            setHintsState(undefined);
                            setSecondBlock();
                            setFirstBlock();

                        }}
                    />
                )}

            </div>

            {
                tables && tables.length > 0 &&
                <Wrapper style={{ marginTop: '0rem', borderTop: '1px solid #e3e3e3' }} onClick={() => {
                    if ((!hintsState && !tagTables)) {
                        setFirstBlock('s3');
                        setSecondBlock('s3');
                    }
                    else {
                    }
                }
                }>

                    {/* <TitleHeader leftIcon={<PolusSvg size="1.286rem" height="1.286rem" />} title={"Train your Polus"} /> */}

                    <EditorInnerWrapper

                        style={{
                            height: 'calc(100vh - 100px)'
                        }}
                        scroll={true}>
                        {((tagTables && tagTables.length > 0) || (hintsState && hintsState.length > 0)) &&
                            <div style={{
                                marginTop: '-25px',
                                position: 'sticky',
                                top: '-25px',
                                background: '#fff',
                                zIndex: '2',
                                minHeight: '3.571rem',
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <div className="back_btn"
                                    style={{ display: 'flex', alignItems: 'center', }}
                                    onClick={() => {
                                        setTagTables(undefined);
                                        setWhichFieldHide(undefined);
                                        setHintsState(undefined);
                                        setSecondBlock('s3');
                                        setFirstBlock('s3');
                                    }}>
                                    <BackSvg size="1rem" height="1rem" color='#fff' />
                                </div>
                            </div>
                        }



                        <TableAnimationWrapper
                            ref={firstBlockRef}
                            animationState={firstBlock}
                            {...firstBlockRefStyle}
                        >
                            <RowWrapper justify="flex-start">
                                <div style={{
                                    width: '58.4%',
                                    display: 'inline-block',
                                    marginRight: '7px'
                                }}>
                                    <label className="label" style={{ marginBottom: '1rem' }}>Enter name of the table</label>
                                    <SearchBox
                                        placeholder="Start typing here"
                                        checkArray={tables}
                                        pressOnSuggest={_getHintsFromServer}
                                        setAnimationDefaultState={setSecondBlock}
                                        FocusOnInput={FocusOnInput}
                                        hideFieldName="tag"
                                        sessionValue={recentTableSearch ? recentTableSearch : tableSessionValue}
                                    />
                                </div>


                                <div
                                    className="view_complete_list"
                                    onClick={() => {
                                        if (tables) {

                                            const table_selections = [];
                                            (tableNameOfHints || []).forEach((item) => {
                                                const tbl_name = item.table_name //+ "__db__id__" + item.db_info_id
                                                table_selections.push(tbl_name)
                                            })
                                            showPopup(undefined, undefined, enums.default.popupType.element_with_header, SelectDisplayField, {
                                                menus: props.menus,
                                                displayItem: table_selections,
                                                selectedFields: activeTable ? activeTable : [],
                                                title: "Table list",
                                                isCheckboxHide: true,
                                                hideSearchbox: true,
                                                buttonText: 'View',
                                                hideSelectAll: true,
                                                hidePrimeryBtn: true,
                                                itemClickAction: _getHintsFromServer,
                                                setSelectedFields: _getHintsFromServer
                                            }, undefined, undefined, undefined, {
                                                width: '62rem',
                                                // maxWidth: '35rem'
                                            })
                                        }
                                    }}
                                >View complete table list</div>



                            </RowWrapper>
                            <div
                                style={{
                                    width: '100%',
                                    transition: 'all 0.3s',
                                    marginTop: '20px',
                                    opacity: secondBlock === 's1' ? '0' : '1',
                                    display: secondBlock === 's2' ? 'none' : 'block'

                                }}>

                                <RowWrapper height="8.571rem" align="center" justify="flex-start">
                                    <div style={{
                                        width: '70%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        background: '#e3e3e3',
                                        height: '1px',

                                    }}>
                                        <div className="or_style">OR</div>
                                    </div>

                                </RowWrapper>

                            </div>
                        </TableAnimationWrapper>

                        {!isLoding ? <>
                            {!tagTables && hintsState && hintsState.length > 0 && activeTable &&
                                <HintTable
                                    _removeHint={_removeHint}
                                    showHintPopup={showHintPopup}
                                    hintsState={hintsState}
                                    tagTables={tagTables}
                                    activeTable={activeTable}
                                    save={_saveHintsFromEditor}
                                    showContextPopup={showContextPopup}
                                    showTblContextPopup={showTblContextPopup}
                                    saveValueForCache={saveValueForCache}
                                    _saveTblContent={_saveTblContent}
                                    getTableInfoByTblName={props.getTableInfoByTblName}
                                    cached_db_info_id={cached_db_info_id}
                                />
                            }
                        </>
                            :
                            <TableSkeleton />
                        }

                        <TagTableAnimationWrapper
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation();
                            }}
                            animationState={secondBlock}
                        >
                            <RowWrapper justify="flex-start">
                                <div
                                    style={{
                                        width: '70%',
                                        display: 'inline-block',
                                        marginRight: '7px'
                                    }}>

                                    <label className="label" style={{ marginBottom: '1rem' }}>Search Hint</label>
                                    <SearchBox
                                        placeholder='Start typing here'
                                        checkArray={tagList}
                                        pressOnSuggest={_convertTagsToTable}
                                        setAnimationDefaultState={setFirstBlock}
                                        FocusOnInput={FocusOnInput}
                                        hideFieldName="table"
                                        sessionValue={recentTagSearch ? recentTagSearch : tagSessionValue}
                                    />
                                </div>


                                <div className="view_complete_list"
                                    onClick={() => {
                                        if (tagList) {
                                            showPopup(undefined, undefined, enums.default.popupType.element_with_header, SelectDisplayField, {
                                                menus: props.menus,
                                                displayItem: tagList,
                                                selectedFields: activeTag ? activeTag : [],
                                                title: "Tag list",
                                                isCheckboxHide: true,
                                                hideSearchbox: true,
                                                buttonText: 'View',
                                                hideSelectAll: true,
                                                hidePrimeryBtn: true,
                                                itemClickAction: _convertTagsToTable,
                                                setSelectedFields: _convertTagsToTable
                                            }, undefined, undefined, undefined, {
                                                width: '100%',
                                                maxWidth: '35rem'
                                            });
                                        }
                                    }}> View complete hints list </div>
                            </RowWrapper>
                        </TagTableAnimationWrapper>

                        {
                            !hintsState && tagTables && tagTables.length > 0 &&

                            <TagTable
                                hintsState={hintsState}
                                tagTables={tagTables}
                                activeTag={activeTag}
                                showEditTagPopup={showEditTagPopup}
                                _saveTag={_saveTag}
                                deleteSingleTag={deleteSingleTag}
                            />
                        }


                    </EditorInnerWrapper>
                </Wrapper>
            }
            <Footer />
        </div>
    );
};





const mapStateToProps = state => {

    const { hints, tableNameOfHints, hintsWithTableColumnsName, dublicateHints } = state.hintsReducer;

    const db_infos = state.connectionReducer.db_infos

    return { hints, tableNameOfHints, hintsWithTableColumnsName, dublicateHints, db_infos }
}

export default connect(mapStateToProps, { getStringHintsFromServer, saveTableColumnValues, dispatchHeaderInformation, getHintByTableName, saveHints, clearHints, getTablesForHints, getHintsWithTableAndColumnName, clearEditorState, getMongoDbTableData, getTableInfoByTblName, saveTableInfo })(ConfigEditor);