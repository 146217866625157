import styled from 'styled-components';

export const MainWrapper = styled.div`

        width: 100%;

        table{
            width: 100%;
            border: 0px;
            border-collapse: collapse;
            table-layout: fixed ;

            thead{
                td{
                    padding-bottom: .8rem;
                    border-bottom: 1px solid #ccc;
                    
                }
            }

            td{
                padding-right: 1rem;
                font-size: .9rem;
                font-weight: 500;
            }
        }
`;