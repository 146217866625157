import React from "react";

const DataCache = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2015 2048"
    >
      <path transform="translate(1539,367)" d="m0 0h2l1 237 36 7 31 8 29 9 27 10 28 12 16 8 21 11 23 14 25 17 19 14 16 13 11 9 16 15 28 28 9 11 8 9 9 11 15 20 10 15 12 19 12 21 14 27 14 32 11 30 10 34 9 38 5 31 3 34v87l-3 28-5 30-10 43-10 33-9 25-11 25-12 25-10 19-12 20-8 12-14 20-14 19-9 10-9 11-9 9-7 8-22 22-8 7-10 9-14 11-11 9-14 10-24 16-24 14-22 12-26 12-33 13-27 9-29 8-47 11-5 2v103l-1 19-3 13-5 10-12 16-9 10-7 7-20 15-15 10-24 14-23 12-25 12-36 15-41 15-28 9-39 11-52 13-44 9-49 9-81 12-58 7-49 5-33 2-10-1-1 1h-209l-8-1-3 1-29-2-19-2-68-8-58-7-65-10-52-10-55-13-41-11-38-12-35-12-42-17-28-13-19-10-23-13-15-10-12-9-10-8-10-9-16-16-7-10-6-12-2-5-1-14v-35l1-250v-101l10 4 27 14 16 8 21 10 35 15 35 13 50 17 34 10 41 11 49 11 51 10 60 10 76 10 64 6 60 4 66 3 31 1h80l76-3 84-5 54-5 43-5 17-3v-3l-6-7-8-7-19-19-10-13-4-6-17 1-42 4-74 5-70 4-34 1h-79l-45-2-64-4-51-4-53-6-45-6-61-10-54-11-60-15-41-12-36-12-28-10-29-12-24-11-17-8-18-10-16-10-19-13-13-10-13-11-9-9-13-17-7-13-1-4v-400l9 3 18 10 30 15 28 13 31 13 30 11 47 16 37 11 42 11 53 12 47 9 56 9 66 9 51 5 67 5 60 3 35 1h152l46-2 3-12 13-51 3-12-31 2-49 2h-146l-48-2-74-5-34-3-74-9-78-13-52-11-52-13-31-9-32-10-37-13-32-13-30-14-24-12-21-12-22-15-13-10-11-10-8-7-11-12-7-10-5-10-2-8-1-398 5 1 15 8 19 10 19 9 16 8 32 14 42 16 35 12 40 12 41 11 43 10 48 10 65 11 57 8 54 6 58 5 62 4 66 3h100l67-3 61-4 71-6 64-8 41-6 52-9 54-11 54-13 44-12 16-5 37-12 28-10 27-11 26-11 24-11 27-14 17-10z" fill="#07345C" />
      <path transform="translate(1441,646)" d="m0 0h42l42 4 30 5 27 6 34 10 39 15 26 12 27 14 24 15 15 10 15 11 17 13 13 11 17 16 10 10v2l4 2 9 10 12 14 9 11 13 16 24 36 14 25 16 32 10 24 14 41 6 22 6 28 5 35 2 25v67l-4 33-5 31-6 26-9 30-10 28-15 33-8 17-10 17-7 12-6 10-12 18-14 18-9 12-9 11-15 16-11 12-4 2v2l-8 7-12 11-14 11-12 10-11 8-24 16-22 13-33 17-25 11-30 11-34 10-23 5-32 6-26 3-60 1h-22l-31-3-38-7-26-6-26-8-26-9-26-11-23-11-22-12-18-11-34-24-17-14-14-12-38-38-9-11-11-13-13-18-13-19-8-13-9-15-14-26-10-22-13-33-11-34-8-34-5-28-4-33-2-30 1-38 3-32 6-37 6-26 7-25 8-24 11-27 11-24 11-21 10-17 24-36 10-13 12-14 11-13 7-8 11-12 10-10 11-9 11-10 14-11 15-12 15-10 20-13 23-13 32-16 24-10 28-10 32-9 38-8 20-3 26-2z" fill="#D5D9DC" />
      <path transform="translate(648)" d="m0 0 9 1 9-1h233l1 1 89 9 50 6 63 9 52 9 53 11 44 11 48 14 47 16 30 12 28 12 25 12 20 11 24 15 14 10 10 8 15 13 11 11 10 15 5 12 2 8v14l-5 12-7 12-9 11-9 10-14 12-17 12-15 10-24 14-27 14-32 14-33 13-34 12-32 10-40 11-37 9-48 10-43 8-45 7-56 7-63 6-63 4-42 2-41 1h-66l-45-1-75-4-41-3-49-5-55-7-57-9-60-12-54-13-36-10-38-12-29-10-30-12-25-11-35-17-25-14-15-10-12-9-14-11-16-15-11-14-8-16-4-10 1-11 7-17 6-10 9-11 16-16 11-9 15-11 19-12 25-14 34-17 36-15 30-11 36-12 33-10 56-14 42-9 55-10 60-9 95-11 19-2 35-2z" fill="#07345C" />
      <path transform="translate(1590,943)" d="m0 0 5 1 13 14 2 1v2l4 2 10 9v2l4 2 8 8 8 7 12 11 8 8 2 1v2l4 2 9 9 8 7 13 12 7 7 8 7 12 11 9 9 8 7 15 14 8 7 11 11 8 7 11 11 8 7 11 11 5 4v2l3 1-6 7-9 9-8 7-12 12-8 7-16 15-8 8-8 7-13 12-8 7-10 9-16 15-15 14-7 7-8 7-7 7-8 7-12 11-9 9-8 7-14 12-9 9h-2l-2 4-7 7-7-2-3-5-5-3-18-18-6-5-1-6 7-8 16-17 11-10 8-7 10-9 8-7 14-14 11-9 9-9 8-7 8-8 8-7 15-14v-2h-230l-302-1-5-1-1-54 1-40 2-1 50-2 132-1h201l127 1 18 1 7 1v-4l-4-2-5-6-9-7-17-16-9-9-11-9-7-7-5-4-15-15-8-7-15-14-20-18-6-8-2-3 1-5 11-9 11-11 6-7 5-5z" fill="#07345C" />
      <path transform="translate(988,1333)" d="m0 0 3 4 9 21 10 22 8 13 8 14 7 11 4 5 6 10 6 7 4 6v2h2l3 5 7 10 6 7 7 8 2 4 4 2 7 8 8 9v2h2v2l3 1 5 5 6 7 2 1v2l4 2 14 12 16 12 8 7 6 4 4 4 17 11 20 12 22 12 16 7 14 7 29 11 21 7 30 8 24 5 46 6h59l33-1 25-2 42-6 22-5 28-8 31-11 27-11 19-10 22-12 13-8 12-8 11-8 17-13 13-11h2v-2l13-10 10-8 19-19 2 1-16 17-3 1v2l-8 7-12 11-14 11-12 10-11 8-24 16-22 13-33 17-25 11-30 11-34 10-23 5-32 6-26 3-60 1h-22l-31-3-38-7-26-6-26-8-26-9-26-11-23-11-22-12-18-11-34-24-17-14-14-12-38-38-9-11-11-13-13-18-13-19-8-13-9-15-14-26-10-22-7-17z" fill="#B1BAC4" />
      <path transform="translate(1676,1098)" d="m0 0 4 2 5 6 2 1v4l-25-1-146-1h-202l-112 1-52 2v40h-1v-41l5-3 14-2 46-2 126-2 42-1 66-1h126l50 1 31 2h18l3-1z" fill="#B1BAC4" />
      <path transform="translate(1687,1107)" d="m0 0 3 2v4l-3 1h-27l-12 1-71 2h-63l-129-1-225-2-9-1-1 4v-5l2-1 50-2 132-1h201l127 1 18 1 7 1z" fill="#4C6C8D" />
      <path transform="translate(1149,1158)" d="m0 0h1v48l343 1h194v4l-7 1-9 1h-11l-19 2h-159l-173-2h-24l-114-1-13-1-9-3-1-4z" fill="#B1BAC4" />
      <path transform="translate(1150,1197)" d="m0 0h1v7h510l24 1 5 3-2 2-1-2h-230l-302-1-5-1z" fill="#4C6C8D" />
      <path transform="translate(1807,1516)" d="m0 0h2l-1 3-8 7-14 13-11 9-14 11-18 13-16 10-20 12-18 10-19 9-28 12-28 10-30 9-34 8-16 3-35 4h-71l-29-3-30-5-31-7v-1l9 1 37 7 23 3 14 1h22l60-1 26-3 43-8 26-7 29-9 28-11 26-12 29-15 23-14 19-13 13-10 10-8 11-9 14-12z" fill="#4C6C8D" />
      <path transform="translate(1870,861)" d="m0 0 4 4 16 24 14 25 16 32 10 24 14 41 6 22 6 28 5 35 2 25v67l-4 33-5 31-6 26-9 30-9 25-1-3 12-36 7-27 4-20 1-2 5-35 1-14v-83l-3-31-5-26-8-36-11-35-5-13-8-21-7-14-10-21-5-10-7-11-4-8-7-10-9-14z" fill="#B1BAC4" />
      <path transform="translate(1441,646)" d="m0 0h42l42 4 30 5 27 6 34 10 39 15 11 5-3 1-10-4-12-5-16-6-11-4-21-5-17-5-22-4-30-4-4-1-16-1-30-3h-45l-5 1-17 1-37 5-25 5-35 9-24 8-21 8-7 3-3-1 21-9 28-10 32-9 38-8 20-3 26-2z" fill="#B1BAC4" />
      <path transform="translate(1889,887)" d="m0 0 3 3 12 20 16 32 8 17 10 26 11 34 8 35 3 15 4 34 1 15v61l-2 23-6 41-8 36-3 10h-1l1-8 6-25 4-22 5-36 1-10v-67l-3-34-5-30-6-27-7-25-13-38-12-27-16-32-11-19z" fill="#4C6C8D" />
      <path transform="translate(993,954)" d="m0 0 1 4-10 26-8 26-6 23-7 36-5 37-2 25v37l2 23 3 28 2 18 5 28 9 33-1 4-7-24-8-36-5-33-3-31-1-17 1-38 3-32 6-37 6-26 7-25 8-24z" fill="#B1BAC4" />
      <path transform="translate(1453,644)" d="m0 0h29l33 3 38 6 30 7 31 9 2 2-10-2-32-9-29-6-28-4-34-3h-42l-47 4-30 5-32 7-31 9-9 3-3-1 30-10 28-7 43-8 32-3z" fill="#4C6C8D" />
      <path transform="translate(981,982)" d="m0 0 1 3-8 24-9 35-5 27-4 28-2 24-1 38 3 40 5 36 2 10v5l-2-4-6-34-3-26-1-14v-40l2-31 4-32 9-43 12-39z" fill="#4C6C8D" />
      <path transform="translate(1592,941)" d="m0 0 4 2 11 8 5 4 9 11 7 6v2l5 2 30 30 11 9 9 9v2h2l7 8h-3l-8-8-8-7-7-6v-2l-4-2-14-14-8-7-12-11-5-5-10-9-3-2v-2l-4-2-12-13-4-2z" fill="#B1BAC4" />
      <path transform="translate(1655,1238)" d="m0 0 2 1-7 7-8 7-12 11-8 7-14 14-8 7-10 9-11 9-10 10-7 8-6 5 2-4 8-8 7-8 18-18 4-2v-2l8-7 25-25 9-7 6-2 1-3h2v-2z" fill="#B1BAC4" />
      <path transform="translate(1700,1043)" d="m0 0 4 2 10 8 11 10v2h2v2h2l4 4v2h2l4 4 8 7 11 9v2l4 2 22 22 16 14 7 8 3 3-1 2-10-10-8-7-11-11-8-7-11-11-8-7-13-12-12-11-9-9-8-7-11-10z" fill="#B1BAC4" />
      <path transform="translate(1648,1246)" d="m0 0 2 1-15 15-8 7-7 7-8 7-10 10-11 9-15 14-19 19-1 7-2-2 1-6 12-12 7-8 14-13 8-7 10-9 8-7 14-14 11-9z" fill="#446687" />
      <path transform="translate(1921,1352)" d="m0 0 1 3-9 20-12 23-10 17-11 17-2 1 2-5 15-25 11-19 13-28z" fill="#4C6C8D" />
      <path transform="translate(1912,1369)" d="m0 0 1 2-8 16-14 25-7 11-9 14-1-2 7-13 9-13 9-17 5-8 7-14z" fill="#B1BAC4" />
      <path transform="translate(1603,1031)" d="m0 0 4 2 15 15 10 9 15 13 4 5-4-1-5-6-9-6-4-5-6-4-7-8-13-13z" fill="#B1BAC4" />
      <path transform="translate(1863,1449)" d="m0 0h2l-2 4-7 9-8 11-6 8-1-3 12-16 9-11z" fill="#B1BAC4" />
      <path transform="translate(1648,1246)" d="m0 0 2 1-15 15-8 7-7 7-2-1 17-17 8-7z" fill="#4C6C8D" />
      <path transform="translate(1,230)" d="m0 0h2l-1 2z" fill="#4C6C8D" />
    </svg>
  );
};

export default DataCache;
