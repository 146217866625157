import React, { useState, useEffect } from 'react'
import { MainWrapper } from './user.styled';
import { PageInnerWrapper } from '../hoc/styled.hoc';
import SharedTable from '../shared-table';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get_user_session } from '../../actions/account';
import TextBoxV1 from '../hoc/text.box.v1';
import SelectOption from '../select-option/select.option.v1.1';
import { Button } from '../hoc/button';


const Index = (props) => {


    const { user_sessions } = props;


    const [localActivity, setlocalActivity] = useState(undefined);
    const [filter, set_filter] = useState(undefined);
    const [user_distinct, set_user_distinct] = useState(undefined);


    function getDistinctUsers(data = []) {

        const distinctUsers = {};

        data.forEach(entry => {
            const { name, user_id } = entry;
            if (!distinctUsers[user_id]) {
                distinctUsers[user_id] = { user_name: name, user_id };
            }
        });

        return Object.values(distinctUsers);
    }

    useEffect(() => {

        const local_session = user_sessions ? JSON.parse(JSON.stringify(user_sessions)) : [];
        const new_session = [];

        local_session.map((s) => {

            const loginTime = new Date(s.login_time);

            // Get logout time or set it to current time if logout time is not available
            const logoutTime = s.logout_time ? new Date(s.logout_time) : new Date(); // If logout_time is not available, use current time
            
            // Calculate duration in seconds
            const durationInSeconds = Math.floor((logoutTime - loginTime) / 1000);
            
            new_session.push({
                ...s,
                duration: durationInSeconds + " sec"
            })
        })

        setlocalActivity(new_session)
        if (!user_distinct || user_distinct?.length === 0) set_user_distinct(getDistinctUsers(props.user_sessions));

    }, [props.user_sessions]);


    const __on_change = (key, value) => {
        const clone_filter = filter ? { ...filter } : {};
        clone_filter[key] = value;
        set_filter(clone_filter)
    }


    useEffect(() => {
        let _page_info = {
            title: 'User Activity',
        }
        props.dispatchHeaderInformation(_page_info)
        props.get_user_session()
    }, [])


    return (
        <MainWrapper>
            <PageInnerWrapper padding={'10px'} style={{ marginTop: '1rem' }}>

                <div style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center', }}>
                    <div style={{ width: '250px', marginRight: '1rem' }}>
                        <TextBoxV1 label="Choose Date" value={filter?.['date'] || '-'} type='date' onChange={(e) => {
                            __on_change("date", e.target.value)
                        }} />
                    </div>
                    <div style={{ width: '250px', marginRight: '1rem' }}>
                        <SelectOption
                            label="Choose User Name"
                            options={user_distinct}
                            display_key="user_name"
                            value_key={'user_id'}
                            value={filter?.['user_id'] || ''}
                            on_change={(v) => {
                                __on_change("user_id", v)
                            }}
                        />
                    </div>
                    <div style={{ width: '250px', marginTop: '10px' }}>
                        <Button onClick={() => {
                            props.get_user_session(filter)
                        }}>
                            Apply Filter
                        </Button>
                    </div>
                </div>

                <SharedTable
                    height={"calc(100vh - 200px)"}
                    tableData={(localActivity && localActivity.length > 0) ? localActivity : undefined}
                    tableHead={['User Name', 'Login Time', 'Logout Time', "Current Status", "Duration"]}
                    columnsToShow={['name', 'login_time', 'logout_time', "status", 'duration']}
                    sortColumns={{
                        "status": {
                            type: "string",
                            dataKey: 'status'
                        },
                        "user_name": {
                            type: "string",
                            dataKey: 'user_name'
                        },
                        "Login Time": {
                            type: "date",
                            dataKey: 'login_time'
                        },

                        "Logout Time": {
                            type: "date",
                            dataKey: 'logout_time'
                        },

                    }}
                    date_columns={['login_time', 'logout_time']}
                    fullTime={true}



                />
                {localActivity == undefined || localActivity.length == 0 ? <p style={{ textAlign: "center" }}>No Record Found</p> : null}
            </PageInnerWrapper>

        </MainWrapper>
    )
}

// export default Index
const mapStateToProps = (state) => ({
    user_sessions: state.accountReducer.user_sessions
})

export default withRouter(connect(mapStateToProps, { get_user_session })(Index))