import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import ReportWrapper from '../../report-wrapper';

const ReportSubWrapper = ({
    id,
    response,
    ReportData,
    style,
    expandChart,
    outerChartAction,
    isDeepDrive,
    deepDiveClick
}) => {


    const memoizedExpandChart = useCallback(() => {
        expandChart(response?.id);
    }, [expandChart, response?.id]);



    // State for managing commands and chart configuration
    const [expandCommand, setExpandCommand] = useState(null);
    const [downloadCommand, setDownloadCommand] = useState(null);
    const [chartConfig, setChartConfig] = useState(null);
    const [showDataLabel, setShowDataLabel] = useState(undefined)



    useEffect(() => {

        if (outerChartAction) {

            const { type, subtype } = outerChartAction;

            switch (type) {
                case 'chart':
                    if (subtype) {
                        setChartConfig({
                            chartType: subtype,
                            renderMode: type,
                        });
                    }
                    setExpandCommand({
                        subtype: type
                    });
                    break;

                case 'table':

                    setExpandCommand({
                        subtype: type
                    });
                    break;
                case 'data_label':
                    setShowDataLabel(showDataLabel ? false : true);
                    break;
                case 'download':
                    setDownloadCommand(outerChartAction);
                    break;
                default:
                    break;
            }
        }
    }, [outerChartAction]);




    const viewMode = useMemo(() => (response?.expanded ? 'expanded' : 'collapsed'), [response?.expanded]);
    const height = useMemo(() => style.height + (response?.expanded ? 0 : 60), [style.height, response?.expanded]);

    return (
        <ReportWrapper
            isPolusAIWindow={true}
            id={id}
            width={style?.width}
            height={height}
            isDeepDrive={isDeepDrive}
            view_mode={viewMode}
            report_type="dashboard"
            reportWrapperRender={false}
            dataFromServer={ReportData}
            // sort_data={false}
            staticYAxisLabel="Revenue"
            expanded={response?.expanded}
            expandChart={memoizedExpandChart}
            insightChartType={chartConfig?.chartType}
            insightRenderMode={chartConfig?.renderMode}
            show_data_label={showDataLabel}
            expandCommand={expandCommand}
            downloadCommand={downloadCommand}
            question={response?.question}
            hide_x_axis_label={true}
            hide_y_axis_label={true}
            deepDiveClick={deepDiveClick}
            disable_options={{
                refresh: true,
                edit_widget: true,
                filter: true,
                voice_action: true,
                filter_info: true,
            }}
        />
    );
};


const MemoizedReportSubWrapper = memo(ReportSubWrapper, (prevProps, nextProps) => {
    return (
        prevProps.id === nextProps.id &&
        prevProps.response?.expanded === nextProps.response?.expanded &&
        prevProps.ReportData === nextProps.ReportData &&
        prevProps.style.height === nextProps.style.height &&
        prevProps.style.width === nextProps.style.width &&
        prevProps.expandChart === nextProps.expandChart &&
        prevProps.outerChartAction?.type === nextProps.outerChartAction?.type && // Compare specific fields
        prevProps.outerChartAction?.subtype === nextProps.outerChartAction?.subtype // Compare specific fields
    );
});





const mapStateToProps = (state, props) => {

    const outerChartActionCache = state.polusChatReducer.outerChartActionCache;

    const outerChartAction = outerChartActionCache?.[props.id];


    return { outerChartAction }
}


export default connect(mapStateToProps)(MemoizedReportSubWrapper);
