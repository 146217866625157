import React, { useState, useEffect } from 'react';
import { DivRow } from '../../styled-hoc';
import TextBox from '../../hoc/textBox';
import { convertDateInToHtmlDate, getParamByName } from '../../../utils';
import SelectOption from '../../select-option';
import FrequencySelector from '../../frequency-selector';
import TitleHeader from '../../title-header';
import { EditorMainWrapper, Row, Column } from './streaming_editor_styled';
import { PageInnerWrapper } from '../../hoc/styled.hoc';
import { Button } from '../../hoc/button'
import { connect } from 'react-redux';
import { getTheStreamingService, saveTheStreamingData, getCurrentStreamingJob, clearCurrentStreamingJob } from '../../../actions/streaming';
import { withRouter } from 'react-router-dom';
import { constants } from '../../../utils/constants';

import { showPopup } from '../../../utils';
import * as enums from '../../../utils/enums';
import StreamingEditorPopup from './streaming.editor.popup'


const Index = (props) => {


    const { streamingServiceData, getTheStreamingService, getCurrentStreamingJob, history } = props;
    const { location } = history;

    const [serviceId, setServiceId] = useState(undefined)



    useEffect(() => {
        let job_id = getParamByName(location.search, 'config_id');
        if (job_id && job_id !== undefined && job_id !== null) getCurrentStreamingJob(job_id)
    }, [location])


    useEffect(() => {
        let _page_info = {
            title: 'PICK YOU STREAM DATA SOURCE',
            // isBack: true,
        }
        props.dispatchHeaderInformation(_page_info)
        getTheStreamingService()
    }, [])



    const localSaveAction = (data) => {
        let _save_data = {
            ...data,
        }
        props.saveTheStreamingData(_save_data)
        setTimeout(() => {
            props.history.push("/streaming")
        }, 1000)
    }



    const openStreamingEditorPopup = (service_id) => {
        showPopup(undefined, undefined, enums.default.popupType.element, StreamingEditorPopup, {
            config_id: undefined,
            serviceId: service_id,
            save_func: localSaveAction
        });
    }



    return (
        <EditorMainWrapper style={{marginTop: '1rem'}}>
            {/* <TitleHeader
                title={'pick you stream data source'}
            /> */}
            <PageInnerWrapper>
                <Row>
                    {streamingServiceData && streamingServiceData.length > 0 && streamingServiceData.map((stream, index) => {
                         return (
                            <Column
                                title={stream.service_name}
                                // active={serviceId === stream.id}
                                onClick={() => {
                                    setServiceId(stream.id)
                                    openStreamingEditorPopup(stream.id)
                                }}>
                                {/* <div className="icon"> */}
                                    <img src={constants.CONTEXT_PATH + `./${stream.icon_name}`} alt={stream.icon_name}></img>
                                {/* </div> */}
                                {/* <p title={stream.service_name}>{stream.service_name}</p> */}
                            </Column>

                        )
                    })}

                </Row>



            </PageInnerWrapper>
        </EditorMainWrapper>
    )
}


const mapStateToProps = (state) => ({
    streamingServiceData: state.streamingReducer.streamingServiceData,
})

export default withRouter(connect(mapStateToProps, { getTheStreamingService, saveTheStreamingData, getCurrentStreamingJob, clearCurrentStreamingJob })(Index))
