import { dispatchAction, simpleDispatch_post, simpleDispatch_put, simpleDispatch_get, showError, raiseSuccess } from './helper';
import * as actionTypes from '../action-types/hints';
import { constants } from '../utils/constants';
import { get, post, put } from '../data.service';
import * as loaderTypes from '../action-types/loader';
import { getStringHintsFromServer } from './report';

export const getHintByTableName = (tableName, column, db_info_id) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.HINTS.POINT + constants.END_POINTS.HINTS.GET_HINTS;

    const post = {
        table_name: tableName,
        column,
        db_info_id: db_info_id
    }

    await simpleDispatch_post(dispatch, url, post, actionTypes.HINTS_SUCCESS);

};


export const getHints = (db_info_id) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.HINTS.POINT + constants.END_POINTS.HINTS.GET_HINTS;
    const post = {
        db_info_id
    }

    await simpleDispatch_post(dispatch, url, post, actionTypes.HINTS_SUCCESS);
};





export const getHintsByDbInfo = db_info_id => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.HINTS.POINT + constants.END_POINTS.HINTS.GET_HINTS_BY_ENTITY;

    const post = {
        db_info_id: db_info_id
    }

    await simpleDispatch_post(dispatch, url, post, actionTypes.HINTS_BY_ENTITY_SUCCESS, true);
};


export const saveHints = (hints, tableName, db_info_id) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.HINTS.POINT + constants.END_POINTS.HINTS.SAVE_HINTS;
    const post = {
        hints,
        db_info_id: db_info_id
    };
    // await simpleDispatch_put(dispatch, url, post, actionTypes.SAVE_HINTS_SUCCESS, true);
    try {
        
        dispatchAction(dispatch, actionTypes.MT_DUBLICATE_HINTS_FOUND, undefined, undefined, undefined, 0);
        
        dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);
        
        const response = await put(url, post);
        
        if (response && response.code === 200 && response.data) {

            if (response.message === 'dublicate hints found') {
                dispatchAction(dispatch, actionTypes.RAISE_DUBLICATE_HINTS_FOUND, response.data)

            } else {
                dispatchAction(dispatch, actionTypes.SAVE_HINTS_SUCCESS)
                dispatchAction(dispatch, actionTypes.CLOSE_HINTS_POPUP)
                if (tableName) {
                    getHintByTableName(tableName, undefined, db_info_id)(dispatch)
                }
            }

            getStringHintsFromServer()(dispatch);
            
            dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        }


    } catch (error) {
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
    }
};



export const clearHints = () => dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_HINTS_SUCCESS);
};

export const clearEditorState = () => dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_CONFIG_EDITOR_STATE);
};

export const clearHintsByEntity = () => dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_HINTS_BY_ENTITY_SUCCESS);
};


export const getTablesForHints = (db_info_id) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.HINTS.POINT + constants.END_POINTS.HINTS.GET_TABLES_FOR_HINTS;

    const post = {
        table_name: '',
        db_info_id: db_info_id,
    }

    await simpleDispatch_post(dispatch, url, post, actionTypes.TABLES_HINTS_SUCCESS);
};

export const getHintsWithTableAndColumnName = (data_type, db_info_id) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.HINTS.POINT + constants.END_POINTS.HINTS.GET_HINTS_WTIH_TABLE_COLUMN_NAME;
    const post = {
        data_type,
        db_info_id
    }
    await simpleDispatch_post(dispatch, url, post, actionTypes.HINTS_WITH_TABLE_COLUMN_NAME_SUCCESS);
};




/*****
 * rules hints actions
 */


export const getHintsForRules = (table_name, db_info_id) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.HINTS.POINT + constants.END_POINTS.HINTS.GET_RULES_HINTS + `?table_name=${table_name}`;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_HINTS_FOR_RULES);
};


export const saveHintOfRules = (hints, table_name) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.HINTS.POINT + constants.END_POINTS.HINTS.SAVE_RULES_HINTS;
    dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);

    try {
        let _post = {
            hints: hints,
            table_name: table_name
        }
        const response = await post(url, _post);
        if (response && response.code === 200) {
            raiseSuccess(dispatch, { type: 'success', message: 'Updated Successfully' })
            getHintsForRules(table_name)(dispatch)
            dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        }
    } catch (error) {
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        // showError(dispatch, error);
        showError(dispatch, error);

    }

};


export const getTableColumnNameForHints = () => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.HINTS.POINT + constants.END_POINTS.HINTS.GET_TABLE_COLUMN_NAME_HINTS;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_TABLE_COLUMN_NAME_HINTS);
};




export const getTableInfoByTblName = (tableName, db_info_id) => async dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_TBL_INFO)
    const url = constants.END_POINTS.API + constants.END_POINTS.TBL_INFO.POINT + constants.END_POINTS.TBL_INFO.GET_TBL_INFO_BY_TBL_NAME + `?tableName=${tableName}&db_info_id=${db_info_id}`;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_TBL_INFO_BY_TBL_NAME);
};



export const saveTableInfo = (tbl_info, activeTable, db_info_id) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.TBL_INFO.POINT + constants.END_POINTS.TBL_INFO.SAVE_TBL_INFO;
    const table_name = tbl_info && tbl_info.length > 0 ? tbl_info[0].table_name : undefined;
    const post = {
        tblInfo: tbl_info,
        db_info_id: db_info_id
    }
    await simpleDispatch_post(dispatch, url, post, actionTypes.SAVE_TBL_INFO);
    getTableInfoByTblName(table_name, db_info_id)(dispatch)
}


export const saveTableColumnValues = (table_name, column_name, values, db_info_id, isDeleted) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.HINTS.POINT + constants.END_POINTS.HINTS.SAVE_VALUE_CONTAINER;

    // await simpleDispatch_post(dispatch, url, post, actionTypes.SAVE_VALUE_CONTAINER);
    // getHintByTableName(value, undefined, cached_db_info_id);

    try {
        const _post = {
            table_name: table_name,
            column_name: column_name,
            values: values,
            db_info_id: db_info_id,
            isDeleted: isDeleted
        }
        const response = await post(url, _post);
        if (response && response.code === 200) {

            raiseSuccess(dispatch, { type: 'success', message: 'Updated Successfully' })
            getHintByTableName(table_name, undefined, db_info_id)(dispatch)
        }
    } catch (error) {
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        showError(dispatch, error);
    }

}


export const getTableColumnDistinctData = (tableName, column_name, db_info_id) => async dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_TBL_INFO)
    const url = constants.END_POINTS.API + constants.END_POINTS.TBL_INFO.POINT + constants.END_POINTS.TBL_INFO.GET_TBL_INFO_BY_TBL_NAME + `?tableName=${tableName}&columnName=${column_name}&db_info_id=${db_info_id}`;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_TBL_INFO_BY_TBL_NAME);
};


