import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import CloseSvg from '../svg/close';
import { hideMenuConfig } from '../../actions/menu.config.righter';
import ChartOptions from './chart.option';
import { RIGHTER_MENU_MODE } from '../../shared-with-fe/constants';
import DownloadOptions from './download.option';
import DashboardOption from './dashboard.option';
import FilterOption from './filter.option';
import ColumnChooser from './column.chooser';
import ReportSetting from './report.setting';

import OtherConfig from './other.config';
import { apply_styles } from '../../utils'
import { constants } from '../../utils/constants';

import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils'
const selected = getSelectedTheme();



const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: ${themes[selected].righterMenuConfig.chartOptions.border.headerBorderBottom};
    height: 37px; //${props => props.insights ? "80px" : '50px'}; 
    padding: 0px 20px;
    /* padding-top: ${props => props.insights ? '25px' : undefined}; */
    box-sizing: border-box;
    margin-bottom: 4px;
    ${props => apply_styles(props._style, constants.STYLED_COMPONENTS.BACKGROUND)};

    .title{
        ${props => apply_styles(props._style, constants.STYLED_COMPONENTS.FONT)}
    }
`;



const Close = styled.div`
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

`;



const MenuElement = (props) => {

    const {

        dateFilter,
        id,
        scheduleReportId,
        question,
        filters,
        changeChartType,
        chartType,
        renderMode,
        trendFilters,
        numberOfData,
        columnsAligments,
        showSingleCell,
        reportTitle,
        data,
        header,
        user,
        getDataFromReportingServer,
        mainReportId,
        grandTotalColumns,
        righterMenuStyle,
        report_item_id,
        report_menu_cache,
        heightOfChart

    } = props;


    const menu_cache = report_menu_cache && report_menu_cache[id];
    const menu_render_mode = menu_cache?.menu_render_mode;
    const title = menu_cache?.title;


    return (
        <div>
            <Header insights={props.insights} _style={righterMenuStyle.drawer && righterMenuStyle.drawer.header}>
                <span className="title" >{title ? title : ""}</span>
                <Close title="close" style={{
                    cursor: 'pointer'
                }}
                    onClick={() => props.hideMenuConfig(id)}
                >
                    <CloseSvg
                        color={themes[selected].righterMenuConfig.chartOptions.color.headerClose}
                        size="12px"
                        height="12px"
                    />
                </Close>
            </Header>

            {menu_render_mode === RIGHTER_MENU_MODE.CHART_OPTION && (
                <ChartOptions
                    {...props}
                    changeChartType={changeChartType}
                    chartType={chartType}
                    renderMode={renderMode}
                    trendFilters={trendFilters}
                    numberOfData={numberOfData}
                    showSingleCell={showSingleCell}
                    user={user}
                    columnsAligments={columnsAligments}
                    drawerMenuStyle={righterMenuStyle && righterMenuStyle.drawer && righterMenuStyle.drawer.menu}
                />
            )}

            {/* {menu_render_mode === RIGHTER_MENU_MODE.REPORT_SETTINGS && (
                <ReportSetting
                    {...props}
                    changeChartType={changeChartType}
                    chartType={chartType}
                    renderMode={renderMode}
                    trendFilters={trendFilters}
                    numberOfData={numberOfData}
                    showSingleCell={showSingleCell}
                    user={user}
                    columnsAligments={columnsAligments}
                    heightOfChart={heightOfChart}
                    drawerMenuStyle={righterMenuStyle && righterMenuStyle.drawer && righterMenuStyle.drawer.menu}
                />
            )} */}


            {menu_render_mode === RIGHTER_MENU_MODE.DOWNLOAD_OPTION && (
                <DownloadOptions
                    {...props}
                    changeChartType={changeChartType}
                    chartType={chartType}
                    renderMode={renderMode}
                    trendFilters={trendFilters}
                    numberOfData={numberOfData}
                    showSingleCell={showSingleCell}
                    reportTitle={reportTitle}
                    data={data}
                    header={header}
                    user={user}
                    columnsAligments={columnsAligments}
                    xDataKeys={props.xDataKeys}
                    yDataKeys={props.yDataKeys}
                    filters={filters}
                    grandTotalColumns={grandTotalColumns}
                    drawerMenuStyle={righterMenuStyle && righterMenuStyle.drawer && righterMenuStyle.drawer.menu}


                />
            )}


            {menu_render_mode === RIGHTER_MENU_MODE.COLUMN_CHOOSER && (
                <ColumnChooser
                    // {...props}
                    pivot_columns_state={props.pivot_columns_state}
                    changeChartType={changeChartType}
                    chartType={chartType}
                    renderMode={renderMode}
                    trendFilters={trendFilters}
                    numberOfData={numberOfData}
                    showSingleCell={showSingleCell}
                    reportTitle={reportTitle}
                    data={data}
                    heightOfChart={heightOfChart}
                    header={header}
                    user={user}
                    columnsAligments={columnsAligments}
                    xDataKeys={props.xDataKeys}
                    yDataKeys={props.yDataKeys}
                    filters={filters}
                    grandTotalColumns={grandTotalColumns}
                    drawerMenuStyle={righterMenuStyle && righterMenuStyle.drawer && righterMenuStyle.drawer.menu}
                    report_id={id}
                    report_item_id={report_item_id}
                    change_report_setting_on_apply_pivot={props.change_report_setting_on_apply_pivot}
                    is_details_window={props.is_details_window}


                />
            )}


            {menu_render_mode === RIGHTER_MENU_MODE.DASHBOARD_OPTION && (
                <DashboardOption
                    changeChartType={changeChartType}
                    chartType={chartType}
                    renderMode={renderMode}
                    trendFilters={trendFilters}
                    numberOfData={numberOfData}
                    showSingleCell={showSingleCell}
                    reportTitle={reportTitle}
                    data={data}
                    header={header}
                    user={user}
                    filters={filters}
                    filterFromURL={props.filterFromURL}
                    drawerMenuStyle={righterMenuStyle && righterMenuStyle.drawer && righterMenuStyle.drawer.menu}



                />
            )}


            {menu_render_mode === RIGHTER_MENU_MODE.FILTER_OPTION && (
                <FilterOption
                    changeChartType={changeChartType}
                    chartType={chartType}
                    renderMode={renderMode}
                    trendFilters={trendFilters}
                    numberOfData={numberOfData}
                    showSingleCell={showSingleCell}
                    reportTitle={reportTitle}
                    data={data}
                    header={header}
                    user={user}
                    filters={filters}
                    getDataFromReportingServer={getDataFromReportingServer}
                    question={question}
                    scheduleReportId={scheduleReportId}
                    id={props.id}
                    dateFilter={dateFilter}
                    history={props.history}
                    closeFilter={props.hideMenuConfig}
                    mainReportId={mainReportId}
                    filterFromURL={props.filterFromURL}
                    drawerMenuStyle={righterMenuStyle && righterMenuStyle.drawer && righterMenuStyle.drawer.menu}


                />
            )}

            {menu_render_mode === RIGHTER_MENU_MODE.OTHER_CONFIG && (
                <OtherConfig
                    localRunningTotal={props.localRunningTotal}
                    setLocalRunningTotal={props.setLocalRunningTotal}
                    active_chart={props.active_chart}
                />
            )}

        </div>
    )
}














const mapStateToProps = state => ({
    report_menu_cache: state.menuConfigRighterReducer.report_menu_cache
})

export default connect(mapStateToProps, { hideMenuConfig })(MenuElement);
