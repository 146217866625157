import styled from 'styled-components';
import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils';
const selected = getSelectedTheme();
const { selectBoxConfig } = themes[selected];


export const SelectLable = styled.div`
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: #000;
    margin-bottom: 5px;
    padding-left: 2px;
    box-shadow: ${props => props.isDropdown ? '0 0 0 0.2rem rgba(0,123,255,.25)' : undefined};
`;



export const SelectBox = styled.div`
    width: ${props => props.width || '160px'};
    position: relative;
    white-space: nowrap;
    font-size: .9rem;
    font-weight: 500;
    text-decoration: none;
    background-clip: padding-box;
    user-select: none;
    z-index: 2;
    cursor: pointer;
    box-sizing: border-box;

    
    
    input{
        border-radius: 3px;
        padding: 0px;
        box-sizing: border-box;
        padding-left: 10px;
        font-size: .9rem;
        font-weight: normal;
        color: rgb(55,74,102);
        &:hover{
            border: 1px solid #afc4e3;
        }
        &:hover{
            border: 1px solid #afc4e3 !important;
        }
        &:focus{
            outline: none;
            border: ${props => props?.focused ? '2px solid #007FFF' : '1px solid #0000003b'} !important;
        }
    }
    .select_choose{
        margin-right: 26px;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 1rem;
        font-weight: normal;
        color: #071c26;
        line-height: 1.7rem;
    }

    .select_arrow{
        position: absolute;
        right: 0px;
        top: 0px;
        height: 95%;
        color: #334a53;
        font-size: 1.2rem;
        text-align: center;
        display: flex;
        background-color: transparent;
        align-items: center;
        width: 2rem;
        justify-content: center;
        transform: ${props => props.isDropdown ? "rotate(180deg)" : undefined};
    }
`;


export const OverlayMain = styled.div`
    width: 100vw;
    height: 100vh;
    z-index: 1200;
    position: fixed;
    top: 0px;
    left: 0px;
    background: #ff000000;
`;




export const DefaultLabel = styled.div`
        position: absolute;
        top: ${props => props.LabelTop ? "-.5rem" : '.5rem'};
        z-index: 1;
        transition: 0.2s;
        color: ${props => props.isOpen ? selectBoxConfig.color.labelFocus : props.LabelTop ? selectBoxConfig.color.label : selectBoxConfig.color.label};
        font-size: ${props => props.LabelTop ? ".8rem" : '1rem'};
        /* background: #fff; */
        padding: 0px 0px;
        left: 0px;
        cursor: text;
       
        &:before {
            content: '';
            background: ${props => props.labelBg ? props.labelBg : 'transparent'};
            position: absolute;
            top: 0px;
            width: 100%;
            right: 0px;
            height: ${props => props.LabelTop ? '9px' : '0px'};
            z-index: -1;
        }
`;




export const DropDownMenu = styled.div`
        width: 100%;
        max-height: 10rem;
        background: #fff;
        position: absolute;
        /* top: ${props => props.postionType === 'up' ? "-100px" : '100%'}; */
        top : ${props => props?.top + "px" || "0px"};
        left : ${props => props?.left + "px" || "0px"};
        width : ${props => props?.width + "px" || "4rem"};
        box-shadow: ${props => props.dropdownShadow ? props.dropdownShadow : '1px 3px 4px #ccc'};
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 10;

        &::-webkit-scrollbar {
            background-color:red;
            width:5px
        }
        &::-webkit-scrollbar-track {
            background-color: #F3F3F3;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #dbdbdb;
            border-radius:0px;
            height: 5px;
        }

        .select_result{
            padding: 3px 11px 5px 10px;
            text-transform: uppercase;
            letter-spacing: .06em;
            font-size: .8rem;
            color: #071c26;
            font-weight: 600;
        }
        .select_result_sub{
            font-size: .9rem;
            font-weight: 400;
            color: #63636b;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
            padding: ${props => props.isCheckBox ? "0px" : '0px 1rem'};
            white-space: nowrap;
            line-height: ${props => props.menuLineHeight || '2.2rem'};
            cursor: pointer;
            margin-right: 0px;

            &:hover{
                color: #000;
            }
        }

`;

