import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { FULL_MONTHS } from '../../shared-with-fe/constants';
import { Gap } from '../gap';
import { Button } from '../hoc/button';
import CustomDatePicker from '../hoc/date-time-piker/index';
import SelectOptionV1 from '../select-option/select.option.v1.1';


const DefaultDateWrapper = styled.div`
    height: 20rem;
    display: flex;
    flex-direction: column;


    .tab_parent{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .tab_item{
                width: 33.33%;
                font-size: 1rem;
                border: 1px solid #fff;
                height: 2.2rem;
                background: #f5f5f5;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;                
            }

            .active{
                background: #008ac7;
                color: #fff;
            }
        }

`;


const DefaultFilterChooser = (props) => {


    const { close, popupKey, option, data } = props;

    const {
        filter_default_value,
        set_filter_default_value,
    } = data;



    const [defaultValue, setDefaultValue] = useState({
        year: undefined,
        month: undefined,
        single_date: undefined,
        date_from: undefined,
        date_to: undefined,

    })

    const [open_block_key, set_open_block_key] = useState('single_date')




    useEffect(() => {
        if (filter_default_value) {
            if (filter_default_value) {
                if (filter_default_value?.month && filter_default_value?.year) {
                    set_open_block_key('month')
                }
                else if (filter_default_value?.date_from && filter_default_value?.date_to) {
                    set_open_block_key('custom_date')
                } else {
                    set_open_block_key('single_date')

                }
            }
            setDefaultValue(filter_default_value)
        } else setDefaultValue(undefined)
    }, [filter_default_value])



    const months_selections = [];
    Object.keys(FULL_MONTHS).forEach((m) => {
        months_selections.push({
            key: FULL_MONTHS[m],
            value: m
        })
    })

    console.log("defaultValue", defaultValue)

    return (
        <DefaultDateWrapper>
            <div style={{ width: '100%', marginBottom: '1rem' }}>
                <div style={{ margin: 'auto', marginTop: '.7rem', width: '24rem' }}>

                    <div className='tab_parent'>
                        <div className={open_block_key === 'single_date' ? "active tab_item" : "tab_item"} onClick={(e) => set_open_block_key('single_date')}>Single</div>
                        <div className={open_block_key === 'custom_date' ? "active tab_item" : "tab_item"} onClick={(e) => set_open_block_key('custom_date')}>Range</div>
                        <div className={open_block_key === 'month' ? "active tab_item" : "tab_item"} onClick={(e) => set_open_block_key('month')}>Month</div>
                    </div>
                    <div style={{ marginTop: '10px' }} >
                        {open_block_key === 'single_date' && <React.Fragment>
                            <div>
                                <CustomDatePicker
                                    label={"Select Your Date"}
                                    onChange={(value) => {
                                        const new_value_object = {
                                            single_date: value
                                        };
                                        setDefaultValue(new_value_object);

                                    }}
                                    value={defaultValue?.['single_date']}
                                />
                            </div>
                        </React.Fragment>}
                        {open_block_key === 'custom_date' && <React.Fragment>
                            <div>
                                <CustomDatePicker
                                    label={"Select Start Date"}
                                    type='date'
                                    onChange={(value) => {
                                        const new_value_object = {
                                            ...defaultValue,
                                            date_from: value,
                                            single_date: undefined,
                                        };
                                        setDefaultValue(new_value_object);
                                    }} value={defaultValue?.date_from} />
                            </div>

                            <div style={{ marginTop: '.6rem' }}>
                                <CustomDatePicker type='date'
                                    label='Select End Date'
                                    onChange={(value) => {
                                        const new_value_object = {
                                            ...defaultValue,
                                            date_to: value,
                                            single_date: undefined,
                                        };
                                        setDefaultValue(new_value_object);
                                    }} value={defaultValue?.date_to} />
                            </div>
                        </React.Fragment>}

                        {open_block_key === 'month' && <React.Fragment>
                            <div>
                                <SelectOptionV1
                                    label='Select Year'
                                    options={['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023']}
                                    value={defaultValue?.year}
                                    on_change={(value) => {
                                        const new_value_object = {
                                            ...defaultValue,
                                            date_to: undefined,
                                            single_date: undefined,
                                            date_to: undefined,
                                            year: value
                                        };
                                        setDefaultValue(new_value_object)
                                    }}
                                    none_value={false}
                                />
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <SelectOptionV1
                                    label='Select Month'
                                    options={months_selections}
                                    value={defaultValue?.month}
                                    display_key={"key"}
                                    value_key={"value"}
                                    on_change={(value) => {
                                        const new_value_object = {
                                            ...defaultValue,
                                            date_to: undefined,
                                            single_date: undefined,
                                            date_to: undefined,
                                            month: value
                                        };
                                        setDefaultValue(new_value_object)
                                    }}
                                    none_value={false}
                                />
                            </div>
                        </React.Fragment>}
                    </div>
                </div>
            </div>


            <div style={{ marginTop: 'auto' }}>
                <Button
                    primary={true}
                    width="6rem"
                    mR="16px"
                    onClick={() => {
                        if (defaultValue) {
                            const temp_default_value = defaultValue ? defaultValue : {};
                            set_filter_default_value(temp_default_value)
                        }
                        close(option, popupKey)
                    }}>{'Save'}</Button>

                <Button
                    width="6rem"
                    bgColor="#9caeb5"
                    mR="16px"
                    onClick={() => {
                        close(option, popupKey)
                    }}>Close</Button>
            </div>
        </DefaultDateWrapper>
    )
}

export default DefaultFilterChooser;