import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const PopoverContainer = styled.div`
  position: relative;
  display: inline-block;
  margin: 0 0.3rem;
`;

const PopoverContent = styled.div`
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  min-width: ${(props) => props.width || '120px'};
  color: ${(props) => props.textColor || '#fff'};
  background-color: ${(props) => props.bgColor || '#fff'};
  box-shadow: ${props => !props.hideBoxShadow ? props.boxShadow ? props.boxShadow : "0 0 10px rgba(0, 0, 0, 0.4)" : undefined};
  border-radius: ${(props) => props.borderRadius || '6px'};
  padding: 10px;
  font-size: 0.8rem;
  font-weight: 400;
  position: absolute;
  z-index: 1;
  top: calc(100% + 5px);
  transform: ${(props) => `translate(${props.xPosition||'-96%'}, ${props.yPosition || '0%' })`};
  /* transform: ${(props) => props.xPosition || 'translateX(-96%)'}; */
  /* transform: ${(props) => props.yPosition || 'translateY(-140%)'}; */
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 0.3s, visibility 0.3s;
`;

const Popover = ({
  content,
  children,
  width,
  bgColor,
  textColor,
  xPosition,
  yPosition,
  trigger = 'hover', // default trigger is 'hover'
  hideBoxShadow,
  boxShadow,
  borderRadius,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const popoverRef = useRef(null);

  // For hover trigger
  const handleMouseEnter = () => {
    if (trigger === 'hover') {
      setIsVisible(true);
    }
  };

  const handleMouseLeave = () => {
    if (trigger === 'hover') {
      setIsVisible(false);
    }
  };

  // For click trigger
  const handleClick = () => {
    if (trigger === 'click') {
      setIsVisible((prevVisible) => !prevVisible);
    }
  };

  // Hide on blur (clicking outside)
  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    if (trigger === 'click') {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [trigger]);

  return (
    <PopoverContainer
      onMouseEnter={handleMouseEnter} // Only relevant if trigger is 'hover'
      onMouseLeave={handleMouseLeave} // Only relevant if trigger is 'hover'
      onClick={handleClick} // Only relevant if trigger is 'click'
      ref={popoverRef}
    >
      {children}
      {content && (
        <PopoverContent
        borderRadius={borderRadius}
          boxShadow={boxShadow}
          hideBoxShadow={hideBoxShadow}
          isVisible={isVisible}
          bgColor={bgColor}
          width={width}
          textColor={textColor}
          xPosition={xPosition}
          yPosition={yPosition}
        >
          {content}
        </PopoverContent>
      )}
    </PopoverContainer>
  );
};

export default Popover;
