import styled from 'styled-components';

export const FullWidthDiv = styled.div`
    width: ${props => props.isMobile ? 'calc(100vw - 15px)' : props.width ? props.width : 'calc(100vw - 40px)'};
    height: 100%;
    margin: auto;
`;

export const Overlay = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: red;
    top: 0;
    left: 0;
`;