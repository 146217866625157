import React, { useState, useEffect } from 'react';
import { Wrapper, SelectionItemRow, SelectionColumn } from './demo.style';
import TitleHeader from '../title-header';
import { PageInnerWrapper } from '../hoc/styled.hoc'
import ShoppingBagSvg from '../svg/shoppingBag';
import EmployeesSvg from '../svg/employees';
import CallCenterSvg from '../svg/callCenter';
import UploadSvg from '../svg/upload'
import { showPopup } from '../../utils';
import * as enums from '../../utils/enums.js';
import TablesListPopup from './tables.list.popup'
import ActionCard from '../action-card';




const _simple_demo = [

    {
        id: 1,
        name: 'Employee Schema',
        icon: 'employee',
        client_id: 'med.solution',
        database_name: "nf_med_solution",
        db_type: "mysql"

    },
    {
        id: 2,
        name: 'Sales Schema',
        icon: 'sales',
        client_id: 'sbi',
        database_name: "nf_sbi",
        db_type: "mysql"

    },
    {
        id: 2,
        name: 'Customer Care Schema',
        icon: 'customer',
        client_id: 'kx.mis'

    },

]


const Index = (props) => {

    const { dispatchHeaderInformation } = props;

    const [localSamples, setLocalSamples] = useState([]);

    useEffect(() => {
        let _page_info = {title: 'SAMPLE SCHEMAS'}
        dispatchHeaderInformation(_page_info)
        setLocalSamples(_simple_demo)
    })


    const openTableInfoPopup = (data) => {
        showPopup(undefined, undefined, enums.default.popupType.element, TablesListPopup, {
            info: data
        })

    }


    return (
        <Wrapper style={{marginTop: '1rem'}}>


            <SelectionItemRow>
            <ActionCard
                    // cardStyle={{
                    //     width: '25rem',
                    //     height: '19rem',
                    //     margin: '1rem 0rem',
                    // }}
                    // iconStyle={{
                    //     height: '15rem'
                    // }}
                    // titleFontSize={'1.5rem'}
                    parentClick={() => {

                    }}
                    // overlayStyle={{
                    //     fontSize: '2rem'
                    // }}
                    action={false}
                    title={"Upload Your Data"}
                    titleCenter={true}
                    isOverlay={false}
                    Image={<UploadSvg size="5rem" height="5rem" color="#5f5f5f" />}
                />
                {localSamples && localSamples.length > 0 && localSamples.map((item, index) => {
                    let _icon = (item.icon === 'sales') ?
                        <ShoppingBagSvg size="5rem" height="5rem" color="#5f5f5f" />
                        : item.icon == 'customer' ? <CallCenterSvg size="5rem" height="5rem" color="#5f5f5f" />
                            : item.icon == 'employee' ? <EmployeesSvg size="5rem" height="5rem" color="#5f5f5f" /> : <ShoppingBagSvg size="5rem" height="5rem" color="#5f5f5f" />
                    return (
                        <ActionCard
                            // cardStyle={{
                            //     width: '25rem',
                            //     height: '19rem',
                            //     margin: '1rem 0rem',
                            // }}
                            // iconStyle={{
                            //     height: '15rem'
                            // }}
                            // overlayStyle={{
                            //     fontSize: '2rem'
                            // }}
                            // titleFontSize={'1.5rem'}
                            key={"res" + index}
                            data={item}
                            isOverlay={true}
                            action={true}
                            title={item.name}
                            titleCenter={true}
                            Image={_icon}
                            numberOfAction={2}
                            viewAction={openTableInfoPopup}
                        />
                    )
                })}
            </SelectionItemRow>

            {/* <div style={{ textAlign: 'center', padding: '10px 0px' }}><span style={{ fontSize: '1.5rem', backgroundColor: '#ccc', padding: '4px' }}>OR</span></div> */}
            <SelectionItemRow>
                
            </SelectionItemRow>
        </Wrapper>
    )
}

export default Index;
