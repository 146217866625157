import React from 'react';
import { constants } from '../../utils/constants';

const DisabledFunctionMessage = () => {


  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const containerStyle = {
    backgroundColor: '#ffffff',
    color: '#000',
    textAlign: 'center',
    padding: '3rem 0px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    width: '35rem',
    boxSizing: 'border-box'
  };

  const iconStyle = {
    fontSize: '50px',
    color: '#ff4d4f',
    marginBottom: '20px',
  };

  const buttonStyle = {
    backgroundColor: '#1890ff',
    border: 'none',
    borderRadius: '5px',
    color: 'white',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    marginTop: '20px',
  };

  const pStyle = {
    fontSize: '1rem',
    color: '#3d3b3b',
    textAlign: 'center',
    margin: '0px',
    lineHeight: '1.4rem'
  };

  const headingStyle = {
    fontSize: '2rem',
    color: '#000',
    fontWeight: 700,
    marginBottom: '3rem'
  }

  return (
    // <div style={overlayStyle}>
    <div style={containerStyle}>
      <div style={iconStyle}>
        <img height='90px' alt='loading information' src={constants.CONTEXT_PATH + './error_icon.png'}></img>
      </div>
      <h1 style={headingStyle}>We're Sorry!</h1>
      <p style={pStyle}>This module has been disabled for this user. </p>
        <p style={pStyle}>Please contact your administrator at <a href="mailto:admin@newfangled.io">admin@newfangled.io</a></p> 
        <p style={pStyle}>for assistance.</p>
      {/* <button style={buttonStyle}>Contact Support</button> */}
    </div>
    // </div>
  );
};

export default DisabledFunctionMessage;
