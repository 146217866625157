import React, { useState, useEffect } from 'react';
import { HintsWrapper, } from './add.columns.styled';
import { Button } from '../hoc/button'
import { connect } from 'react-redux';
import Checkbox from '../hoc/checkbox';
import SelectOption from '../select-option';
import { saveColumnInfo, getColumnsInfo } from '../../actions/import.error';


const AddColumnsPopup = props => {

    const { file_information } = props;

    const { columnsInfo, columns_from_db, closeFunction, } = (props && props.data && props.data.info) ? props.data.info : props;

    const { saveColumnInfo } = props;

    const normalError = (props && props.data && props.data.normalError) ? props.data.normalError : false;

    const [render, set_render] = useState(1);

    const [local_file_information, set_local_file_information] = useState(undefined);


    const [checkedColumns, setCheckedColumns] = useState();
    const [selectedValue, setSelectedValue] = useState();
    const [active_file_id, set_active_file_id] = useState("Sheet1");
    const [distinct_of_file_id, set_distinct_of_file_id] = useState(undefined);
    const [active_file_info, set_active_file_info] = useState(undefined)





    useEffect(() => {
        set_render(render + 2)
    }, [selectedValue, checkedColumns])

    useEffect(() => {


        if (file_information?.length > 0) {
            set_active_file_id(file_information[0].file_id);
            set_active_file_info(file_information[0]);
            const distinct_of_file_id = file_information?.map(s => s.file_id) || [];
            set_distinct_of_file_id(distinct_of_file_id)
            set_local_file_information(file_information)

        } else {
            file_information(undefined)
        }

    }, [file_information])

    useEffect(() => {
        if (file_information?.length > 0) {
            const file_info = file_information?.find((f) => f.file_id == active_file_id);
            set_active_file_info(file_info);
        }

    }, [active_file_id])





    useEffect(() => {

        let columns = [];
        const columns_keys = {};

        columnsInfo && columnsInfo.length > 0 && columnsInfo.forEach((column) => {
            columns_keys[column.column_name] = 1;
            columns.push({
                column_name: column.column_name,
                data_type: column.data_type,
                hints: column.hints,
            })
        });


        columns_from_db && columns_from_db.length > 0 && columns_from_db.forEach((column) => {
            if (typeof columns_keys[column] === 'undefined') {
                columns_keys[column] = 1;
                columns.push({
                    column_name: column,
                })
            }
        })
        // setDropdownColumns(columns)
    }, [columnsInfo, columns_from_db]);



    const _find_hints_and_data_type_in_array = (array, column_name) => {

        let hints = undefined;
        let data_type = undefined;

        for (let i = 0; i < array.length; i++) {
            let element = array[i];
            if (element.column_name === column_name) {
                hints = element.hints;
                data_type = element.data_type;
                break;
            }
        }

        return {
            hints: hints,
            data_type: data_type
        }
    }


    const _save = () => {

        const file_information_for_save = [];
        local_file_information?.map((file_info) => {

            const new_columns = file_info?.new_columns || [];
            const _column_meta_data = [];
            const checked_columns = file_info?.checkedColumns || [];
            const selected_values = file_info?.selectedValue || {};

            const __dropdownColumns = file_info?.old_columns?.length > 0 && file_info?.old_columns?.map((c) => {
                return {
                    column_name: c,
                }
            }) || [];

            new_columns && new_columns.length > 0 && new_columns.map((column) => {

                const { hints, data_type } = _find_hints_and_data_type_in_array(__dropdownColumns, column);

                let is_checked_column = checked_columns && checked_columns.length > 0 && checked_columns.indexOf(column) > -1;
                let mapping_column = selected_values && selected_values[column];

                const extra_col_obj = {
                    column_name: column,
                    type: is_checked_column ? 'is_new' : 'mapping',
                    map_column: mapping_column,
                    hints,
                    data_type
                };
                _column_meta_data.push(extra_col_obj)
            })

            file_information_for_save.push({
                file_id: file_info?.file_id,
                sheet_name: file_info?.sheet_name,
                column_meta_data: _column_meta_data,
            })
        })


        console.log("file_information_for_save", file_information_for_save)

        saveColumnInfo(file_information_for_save);
        setTimeout(() => {
            if (closeFunction) {
                closeFunction();
            }
            else {
                const closePopUp = props && props.close;

                if (closePopUp) {
                    closePopUp(props.option, props.popupKey);
                }
            }
        }, 1000)

    }


    const onCheckBoxChange = (key) => {

        const cloned_file_info = local_file_information ? [...local_file_information] : [];
        const file_info = local_file_information?.find((f) => f.file_id === active_file_id);
        const active_file_idx = local_file_information?.findIndex(f => f.file_id === active_file_id);

        let _clone_checked_columns = file_info?.checkedColumns ? file_info?.checkedColumns : [];

        if (_clone_checked_columns.indexOf(key) > -1) {
            let _index = _clone_checked_columns.indexOf(key)
            _clone_checked_columns.splice(_index, 1);
        } else {
            _clone_checked_columns.push(key);
        }
        file_info['checkedColumns'] = _clone_checked_columns;
        cloned_file_info[active_file_idx] = file_info;

        set_local_file_information(cloned_file_info)
    }



    const onTextChange = (value, key) => {

        const cloned_file_info = local_file_information ? [...local_file_information] : [];
        const file_info = local_file_information?.find((f) => f.file_id === active_file_id);
        const active_file_idx = local_file_information?.findIndex(f => f.file_id === active_file_id);

        let clone_selected = file_info?.selectedValue ? file_info?.selectedValue : {};
        clone_selected[key] = value;
        file_info.selectedValue = clone_selected;
        cloned_file_info[active_file_idx] = file_info;

        set_local_file_information(cloned_file_info)
    }



    const dropdownColumns = active_file_info?.old_columns?.length > 0 && active_file_info?.old_columns?.map((c) => {
        return {
            column_name: c,
        }
    }) || [];


    const type = active_file_info?.tbl_config_result ? active_file_info?.tbl_config_result.type : '';
    const current_sheet_index = distinct_of_file_id?.indexOf(active_file_id);
    const is_next_sheet_available = distinct_of_file_id?.[current_sheet_index + 1];
    const title__ = 'Hey! Found Following new attributes For ' + active_file_info?.name;


    console.log("render add column")

    return (
        <HintsWrapper>
            <h1>{type && type === 'column_required' ? 'Required Columns' : title__}</h1>

            <div className="table_wrapper">

                {active_file_info && (
                    <table>
                        <thead>
                            <tr>
                                <td>{type && type === 'column_required' ? 'Column Not Found' : 'New Column'}</td>
                                {
                                    type && type !== 'column_required' &&
                                    <td style={{ textAlign: 'center' }}>Is it New?</td>
                                }

                                {
                                    !normalError &&
                                    <td>
                                        {
                                            type && type === 'column_required' ? 'CSV Columns' : 'If Not new, then which column is this ?'
                                        }
                                    </td>
                                }
                            </tr>
                        </thead>

                        {active_file_info?.new_columns && active_file_info?.new_columns.length > 0 && active_file_info?.new_columns.map((key, i) => {

                            let is_checked = active_file_info?.checkedColumns && active_file_info?.checkedColumns.indexOf(key);
                            let active_value = active_file_info?.selectedValue && active_file_info?.selectedValue[key];

                            let bg_color = (i % 2) ? "#f6f6f6" : '#fff';
                            console.log("active_value", is_checked, active_value, dropdownColumns, key)

                            return (
                                <tr>
                                    <td style={{ background: bg_color }}>{key}</td>
                                    {
                                        type && type !== 'column_required' &&
                                        <td style={{ textAlign: 'center', background: bg_color }}>
                                            <Checkbox
                                                style={{ margin: 'auto' }}
                                                onClick={() => {
                                                    onCheckBoxChange(key)
                                                }} checked={is_checked > -1} />
                                        </td>
                                    }

                                    {
                                        !normalError &&
                                        <td style={{ background: bg_color }}>
                                            {dropdownColumns && (
                                                <SelectOption
                                                    backgroundColor={bg_color}
                                                    removeInputHeight={true}
                                                    disabled={is_checked > -1}
                                                    onChangeFunc={(value) => {
                                                        onTextChange(value, key)
                                                    }}
                                                    valueKey='column_name'
                                                    displayKey='column_name'
                                                    selections={dropdownColumns && dropdownColumns.length > 0 ? dropdownColumns : []}
                                                    activeValue={active_value ? active_value : undefined}
                                                    width="100%"
                                                    padding="0px 0px"
                                                    height="10rem"
                                                    lineHeight="2.857rem"
                                                    menuLineHeight="1.8rem"
                                                />
                                            )}

                                        </td>
                                    }
                                </tr>
                            )
                        })}
                    </table>
                )}

            </div>
            <div style={{
                paddingTop: '25px',
                marginTop: 'auto',
                display: 'block',
                width: '100%'
            }}>

                {distinct_of_file_id?.length > 1 && (
                    <React.Fragment>
                        {is_next_sheet_available && (
                            <Button
                                primary={true}
                                width='6rem'
                                mR="16px"
                                onClick={() => {
                                    set_active_file_id(is_next_sheet_available)
                                }}
                            >
                                Next
                            </Button>
                        )}

                        {current_sheet_index > 0 && (
                            <Button
                                primary={true}
                                width='6rem'
                                mR="16px"
                                onClick={() => {
                                    set_active_file_id(distinct_of_file_id[current_sheet_index - 1])
                                }}
                            >
                                Prev
                            </Button>
                        )}

                    </React.Fragment>
                )}


                {!is_next_sheet_available && (
                    <Button
                        primary={true}
                        width='6rem'
                        mR="16px"
                        onClick={_save}
                    >
                        Save
                    </Button>
                )}


                <Button
                    width='6rem'
                    bgColor={'#e6e6e6'}
                    style={{
                        marginBottom: 0
                    }}
                    onClick={() => {
                        if (closeFunction) {
                            closeFunction()
                        }
                        else {
                            const closePopUp = props && props.close;

                            if (closePopUp) {
                                closePopUp(props.option, props.popupKey);
                            }
                        }
                    }}
                >Cancel</Button>
            </div>
        </HintsWrapper>
    );
};


const mapStateToProps = (state) => ({
    columnsInfo: state.ImportErrorReducer.columnsInfo
})


export default connect(mapStateToProps, { saveColumnInfo, getColumnsInfo, getColumnsInfo })(AddColumnsPopup)

