import styled from 'styled-components';

import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils';
const selected = getSelectedTheme();


const { label } = themes[selected];

export const MenuWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    height: 90%;
`;

export const RowWrapper = styled.div`
    width: ${props => props.width || '100%'};
    align-items: ${props => props.align || "flex-end"};
    display: flex;
    justify-content: ${props => props.justify || 'space-between'};
    padding: ${props => props.padding || '0px'};
    height : ${props => props.height || undefined};

    
`;

export const TagTableAnimationWrapper = styled.div`
    width: 50%;
    margin-bottom: 20px;
    transition: all 0.3s;
    opacity: ${props => props.animationState === "s1" ? '0' : '1'};
    display: ${props => props.animationState === "s2" ? 'none' : 'block'};
    
`;
export const TableAnimationWrapper = styled.div`
    width: 60%;
    margin-bottom: 20px;
    transition: all 0.2s;
    
     margin-top: ${props => (props.animationState === 's1' || props.animationState === 's2') ? (`-${props.height + 27}px`) : '0px'};

`;


export const EditorInnerWrapper = styled.div`
    width: 100%;
    padding: 25px;
    box-sizing: border-box;
    background: #fff;
    /* border-radius: 10px; */
    box-sizing: border-box;
    /* border: 10px solid #eaeaea; */
    overflow: auto;
    height:${props => props.scroll ? " calc(100vh - 170px)" : '100%'};
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    /* box-shadow: 0px 2px 6px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12); */
    
    &::-webkit-scrollbar {
        background-color: #3F3F3F;
        width:4px;
    }

    &::-webkit-scrollbar-track {
        background-color: #F3F3F3;
        border: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dbdbdb;
        border-radius: 10px;
        height: 4px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color:  #3F3F3F;
        height: 4px;
        }


        .label{
            font-size: 1.286rem;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.714rem;
            letter-spacing: normal;
            text-align: left;
            color: #1a1a1a;
            display: block;
            text-transform: capitalize;
        }

        /* .or_box{
            width: 48px;
            height: 30px;
            background-color: #eaeaea;
            font-size: 1rem;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 18px;
            letter-spacing: normal;
            text-align: center;
            color: #1a1a1a;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
        } */

        .or_style{
            font-style: italic;
            display: inline-block;
            font-weight: bold;
            background: linear-gradient(to right, #3E9EFF, #737DFF);
            color: #fff;
            width: 2rem;
            width: 2.7rem;
            height: 2.7rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 1rem solid #fff;
        }

        .view_complete_list{
            padding: .8rem 1rem;
            cursor: pointer;
            box-sizing: border-box;
            text-transform: capitalize;
            background: #cde0fffa;
            border-radius: 6px;

            &:hover{
                opacity: 0.8;
                /* text-decoration: underline; */
            }
        }

        .show_on_hover{
            opacity: 0;
            transition: all 0.3s;
        }

        .back_btn{
            cursor: pointer;
            background: linear-gradient(to right, #3E9EFF, #737DFF);
            border-radius: 100%;
            padding: 0.5rem;
            svg{
                transform: rotate(180deg);
            }

            &:hover{
                svg{
                    /* fill: #24a8d6; */
                }
            }
        }
`;



export const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 10px;
    padding-bottom: 20px;
    box-sizing: border-box;  

    h2{
        font-size: 1.714rem;
        line-height: 1.29;
        text-align: left;
        color: ${label.color};
        margin: 0px;
        position: relative;
        width: 100%;
        font-weight: normal;
        span{
            padding-right: 30px;
            box-sizing: border-box;
            position: relative;;
            z-index: 1;
            background: #fff;
        }
        &::after{
            content: "";
            width: 100%;
            height: 1px;
            background:#eaeaea;
            position: absolute;
            right: 0px;
            top: 50%;
        }
    }

`;



export const CloseWrapper = styled.div`
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 20px;
`;


export const ColumnWrapper = styled.div`
    width: 300px;
    border-right: 1px solid #ccc;
    height: 500px;
    overflow: hidden;

    div {
        line-height: 2.5rem;
        background: ${props => props.active ? '#c1c1c2' : '#ffffff'};
        border:  ${props => props.active ? '1px solid #c1c1c2' : '0'};width: 100%;
        border: 0;
        display: block;
        padding: 10px;
        box-sizing: border-box;
        border-bottom: 1px solid #ccc;
        font-size: 1rem;
        cursor: pointer;
        padding-left: 15px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        &:first-child{
            border-top: 0px;
        }
    }
    
    span {
        display: block;
        padding: 10px;
        box-sizing: border-box;
        font-size: 1.2rem;
        color: #222;
        position: sticky;
        top: 0;
        background: #fff;
        border-bottom: 1px solid #ccc;
        font-weight: bold;
    }


    input {
        width: 95%;
        margin-left: 2.5%;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-top: 5px;
        padding: 5px;
        box-sizing: border-box;
        outline: none;
        margin-bottom: 5px;
    }
`;


export const MiniEditorWrapper = styled.div`
    width: 49vw;
    padding-top: 10px !important;
    padding-bottom: 0 !important;
    border-radius: 6px;
    box-sizing: border-box;
    background: #fff;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 490px;
    padding-bottom: 0px;

    select {
        background: transparent;
        font-size: 1rem;
        width: 200px;
        margin-right: 20px;
        border: 1px solid #E0E0E0;
        padding: 5px 8px;
        border-radius: 2px;
        box-sizing: border-box;
        height: 2.9rem;
        font-family: 'Titillium Web', sans-serif;
        background-color: #fff;
        border: 1.4px solid #ccc;
    }

`;



export const TableWarning = styled.div`
    padding: 10px;
    width: 100%;
    text-align: center;
    font-weight: bolder;
    font-size: 1.2rem;
    box-sizing: border-box;
    height: 200px;
`;




export const Wrapper = styled.div`
     box-sizing: border-box;
    width: 100%;
    margin: auto;
    position: relative;
    /* background: red; */
`;

export const TableSelection = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;

    select {
        background: transparent;
        font-size: .8rem;
        margin-left: 10px;
        border: 0;
        border-bottom: 1px solid #cec7c7;
        box-sizing: border-box;
        padding: 5px 0;
        width: fit-content;
        font-family: 'Titillium Web', sans-serif;
        background-color: #fff;
        outline: none;

        &:focus {
            border-bottom: 1px solid black;
        }
    }

    .select_table {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .select_table_heading {
        font-weight: bold;
        font-size: 1rem;
    }
`;

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
`;

