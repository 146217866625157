import React from 'react';
import styled from 'styled-components';
import { REPORT_TYPES } from '../../shared-with-fe/constants';





const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border: 1px solid #008AC7; */
    /* border-radius: 3px; */
    margin-top : ${props => props.marginTop ? props.marginTop : undefined};
`;


const Tab = styled.div`
    width: 32%;
    /* border-right: 1px solid #008AC7; */
    cursor: pointer;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    color: ${props => props.active ? '#fff' : '#333'};
    font-size: 1rem;
    font-weight: 500;
    background-color: ${props => props.active ? '#337dff' : '#f3f3f3'};
    border-radius: 30px;;

    &:hover{
        opacity: .8;
    }
`;


const Label = styled.label`
    color: rgba(0,0,0,0.6);
    font-size: 0.8rem;
    /* font-family: Roboto,Helvetica,Arial,sans-serif; */
    display: block;
    margin-bottom: 8px;
`;



const mode = [
    {
        name: 'NLP',
        key: REPORT_TYPES.DASHBOARD
    },
    {
        name: 'Drag & Drop',
        key: REPORT_TYPES.DASHBOARD_CONFIG_QUERY_BUILDER
    },
    {
        name: 'NoteBook',
        key: REPORT_TYPES.DASHBOARD_SQL
    },
]


const DataModeTab = (props) => {

    const { active_report_item_type, change_type } = props;
    return (
        <React.Fragment>
            <Label>
                Choose Your DB Interaction Mode
            </Label>
            <Wrapper>
                {mode?.map((m, i) => {
                    return (
                        <Tab active={active_report_item_type === m.key} key={i}
                            onClick={() => {
                                change_type(m.key)
                            }}
                        >
                            {m.name}
                        </Tab>
                    )
                })}
            </Wrapper>
        </React.Fragment>
    )
}

export default DataModeTab;
