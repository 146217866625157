import React from 'react';
import { constants } from '../../utils/constants';
import { FooterWrapper } from './footer.style';


export default function index({id, height, alignItem, padding, childPadding, position, bottom, isMobile}) {
    return (
        <FooterWrapper id={id || "test_footer"} height={height} isMobile={isMobile} alignItem={alignItem} padding={padding} childPadding={childPadding} position={position} bottom={bottom}>
            <div>
                <p className="powerd_by">Powered by:</p>
                <img src={constants.CONTEXT_PATH + './logo.png'} width='117' alt='newfangled_vision_logo' />
            </div>
        </FooterWrapper>
    )
}
