import React from 'react';

const PieChart = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1728 1728"
            space="preserve">
            <path transform="translate(837,128)" d="m0 0h58l54 4 34 4 37 6 11 4 1 5-6 20-13 50-15 58-11 41-11 42-14 54-12 45-8 29-18 69-15 56-35 133-10 36-11 43-10 36-8 23-1 3 10 4 48 13 121 30 75 19 42 11 45 11 146 36 62 16 46 12 63 15 88 22 2 2-2 9-8 27-13 37-15 34-16 32-10 19-15 25-9 14-13 19-13 18-10 14-13 16-12 14-11 12-7 8-16 17-17 17-8 7-14 13-14 11-14 12-11 8-12 9-27 18-17 11-15 9-21 12-29 15-29 14-37 15-28 10-34 10-36 9-42 8-42 6-33 3-4-1h-8l-15 3-13 1h-19l-21-2-8-1h-13l-36-3-31-4-40-8-31-7-30-9-36-12-36-14-29-13-19-9-22-12-21-12-21-13-22-15-21-16-16-12-14-12-11-9-13-12-8-7-16-15-14-14-7-8-10-11-7-8-12-14-11-13-12-16-14-20-8-12-13-20-12-20-13-24-19-38-12-28-14-38-8-24-12-43-6-27-7-43-5-44-2-27v-66l2-33 5-43 4-26 9-43 7-26 11-36 13-35 10-24 11-25 11-22 10-18 12-21 12-19 10-15 13-19 10-13 8-10 10-13 11-13 9-11 14-15 7-8 15-16 8-7 10-9 11-9 13-11 11-9 16-13 19-14 27-18 19-12 22-13 23-12 16-8 29-14 35-14 38-13 32-9 41-9 41-7 34-4z" fill="#357BFD" />
            <path transform="translate(1572,647)" d="m0 0h6l4 9 10 36 5 27v7l-8 3-62 12-123 25-76 16-93 19-66 14-121 25-80 16-20 3h-18l-13-4-6-4-1-3v-11l8-38 4-8 3-3 26-6 359-77 44-10 147-31 49-11z" fill="#71A6FD" />
            <path transform="translate(1516,519)" d="m0 0h7l7 8 8 16 10 22 8 18 3 10-5 3-15 4-123 26-46 10-156 34-37 8-198 43-30 6h-9l1-11 13-50 6-24 3-5 15-4 379-80 87-18 64-14z" fill="#71A6FD" />
            <path transform="translate(1433,398)" d="m0 0h11l6 4 13 18 13 19 12 16 6 10-1 3-26 6-326 69-113 24-31 6-7 1h-11l2-10 7-26 10-40 4-9 5-3 37-8 99-20 165-35 64-13 55-11z" fill="#71A6FD" />
            <path transform="translate(1600,783)" d="m0 0h6l2 1 2 9 2 66-1 2-21 6-40 9-64 13-79 16-134 28-15 4-6-1-5-1-8-1-78-20-34-9-29-7-20-5-3-3 15-4 35-7 43-9 224-46 113-23 81-16z" fill="#71A6FD" />
            <path transform="translate(1315,281)" d="m0 0h7l10 6 14 12 8 7 11 10 11 9 10 9 8 7 3 3v3l-17 5-49 11-50 10-107 23-43 9-51 11-43 9-11 2h-6l1-10 16-64 5-12 5-3 67-14 96-20 70-15z" fill="#71A6FD" />
            <path transform="translate(1090,162)" d="m0 0 8 1 44 16 29 11 36 16 23 13 19 10 8 6-1 3-40 9-64 13-54 12-34 7h-6l1-9 8-29 9-33 11-40z" fill="#71A6FD" />
            <path transform="translate(1607,918)" d="m0 0h4v14l-4 39-9 50-1 2-8-1-25-7-42-11-34-8-49-13-48-11-7-3v-2l42-10 57-12 103-21 18-5z" fill="#71A6FD" />
            <path transform="translate(27,725)" d="m0 0h1l1 995 160 2 380 1-1 2-14 1 82 1v1h-466l-35-1v-1l-75-1h-33v3h-7l1-543 2-114h1v45h2l-1-4 1-255zm115 999v1h5v-1zm382 1v1h5v-1z" fill="#919197" />
            <path transform="translate(1544,22)" d="m0 0h184v5h-808l-198-1v-1l14-1h47l54-1h67z" fill="#939399" />
            <path transform="translate(26,105)" d="m0 0h1l1 66v402l-1 37h-2l-2 3-1-44-1-221v-227l1-1v-6l2-5zm-1 57m0 441 1 3z" fill="#FEFEFE" />
            <path transform="translate(27,725)" d="m0 0h1v994h-1l-1-13-2-91v-441l1-62 1-255z" fill="#FEFEFE" />
            <path transform="translate(19,18)" d="m0 0 4 1 46 1 80 1 225 1 128 1 70 1v1l-54 1-114 1h-159l-139-1h-76l-2 1-2 3v-3l-3-1v-2h-4l-1-4z" fill="#9A9A9F" />
            <path transform="translate(1282,1721)" d="m0 0 5 1v-1h32l104 1 221 1 47 1 23 1-1 2-3 1h-321l-61-1v-1l26-1 2 1v-2l-49 1h-43l-1-2h19zm11 1m3 0 4 1z" fill="#909096" />
            <path transform="translate(461,1723)" d="m0 0h108l-1 2-14 1 82 1v1h-466l-35-1-2-3h305zm63 2v1h5v-1z" fill="#BDBCBF" />
            <path transform="translate(1723,36)" d="m0 0 1 4v356l-1 133-1 33-2-1v-430l1-83 1-10z" fill="#8F8F95" />
            <path transform="translate(1722,1273)" d="m0 0h1l1 146v263l1 19v23l3 1-1 3h-7l-5-1v-1h6l-1-232v-163l1-44z" fill="#96969B" />
            <path transform="translate(24,109)" d="m0 0h1l-1 504h-1l-1-44-1-221v-227l1-1v-6z" fill="#A2A2A6" />
            <path transform="translate(23,1071)" d="m0 0h1l1 45v58l-1 410h-1l-1-361h-1v-38z" fill="#BDBCBF" />
            <path transform="translate(1666,1724)" d="m0 0h25l23 1-1 2-3 1h-123l-3-3z" fill="#B8B7BA" />
            <path transform="translate(1722,1663)" d="m0 0h1l1 16 1 22v23l3 1-1 3h-7l-5-1v-1h6v-60z" fill="#BDBCBF" />
            <path transform="translate(23,945)" d="m0 0h1v111h-1z" fill="#BDBCBF" />
            <path transform="translate(1690,1724)" d="m0 0 24 1-1 2-3 1h-27l-5-1 2-2z" fill="#9A9A9F" />
            <path transform="translate(1282,1721)" d="m0 0 5 1v-1h9v2h2v2h-34l-1-2h19z" fill="#97969C" />
            <path transform="translate(1667,1724)" d="m0 0h22v1l-9 1 3 2h-17z" fill="#ADADB1" />
            <path transform="translate(1584,1725)" d="m0 0h23l-1 3h-19z" fill="#BBBBBE" />
            <path transform="translate(1607,1725)" d="m0 0h12l-1 3h-12l-4-2z" fill="#AFAEB2" />
            <path transform="translate(1250,234)" d="m0 0h6v4l-10 1 1-3z" fill="#4183FE" />
            <path transform="translate(636,24)" d="m0 0h32v1l-17 1-15-1z" fill="#96959B" />
            <path transform="translate(596,24)" d="m0 0h39v1h-39z" fill="#8F8F95" />
            <path transform="translate(1725,1724)" d="m0 0 3 1-1 3h-7l-5-1v-1z" fill="#9E9EA3" />
            <path transform="translate(26,34)" d="m0 0h1v19h-1z" fill="#FEFEFE" />
            <path transform="translate(581,24)" d="m0 0h14v1h-14z" fill="#8F8F95" />
            <path transform="translate(681,24)" d="m0 0h7l-1 2-6-1z" fill="#8F8F95" />
            <path transform="translate(702,24)" d="m0 0h6l-1 2h-5z" fill="#8F8F95" />
            <path transform="translate(696,24)" d="m0 0h5l-1 2-4-1z" fill="#8F8F95" />
            <path transform="translate(638,1727)" d="m0 0h7v1h-7z" fill="#BDBCBF" />
            <path transform="translate(1720,562)" d="m0 0 2 3-2 3z" fill="#95959B" />
            <path transform="translate(709,24)" d="m0 0 6 1v1h-5z" fill="#8F8F95" />
            <path transform="translate(573,24)" d="m0 0h7v1h-7z" fill="#8F8F95" />
            <path transform="translate(649,1727)" d="m0 0h6v1h-6z" fill="#BDBCBF" />
            <path transform="translate(572,1723)" d="m0 0 2 1-5 1z" fill="#BDBCBF" />
            <path transform="translate(1720,569)" d="m0 0h1v6h-1z" fill="#8F8F95" />
            <path transform="translate(709,1727)" d="m0 0h5v1h-5z" fill="#BDBCBF" />
            <path transform="translate(677,1727)" d="m0 0h5v1h-5z" fill="#BDBCBF" />
            <path transform="translate(660,1727)" d="m0 0h5v1h-5z" fill="#BDBCBF" />
            <path transform="translate(575,1724)" d="m0 0h5v1h-5z" fill="#BDBCBF" />
            <path transform="translate(23,1065)" d="m0 0h1v5h-1z" fill="#BDBCBF" />
            <path transform="translate(23,1057)" d="m0 0h1v5h-1z" fill="#BDBCBF" />
            <path transform="translate(27,717)" d="m0 0h1v5h-1z" fill="#FEFEFE" />
            <path transform="translate(718,24)" d="m0 0 3 2-4-1z" fill="#8F8F95" />
            <path transform="translate(676,24)" d="m0 0 3 1-3 1z" fill="#8F8F95" />
            <path transform="translate(1323,1726)" d="m0 0 4 1z" fill="#8F8F95" />
            <path transform="translate(587,1724)" d="m0 0 4 1z" fill="#BDBCBF" />
            <path transform="translate(581,1723)" d="m0 0h2v2h-2z" fill="#BDBCBF" />
            <path transform="translate(23,938)" d="m0 0 1 4z" fill="#BDBCBF" />
            <path transform="translate(690,24)" d="m0 0 3 1h-3z" fill="#8F8F95" />
            <path transform="translate(669,24)" d="m0 0 4 1z" fill="#8F8F95" />
            <path transform="translate(666,1727)" d="m0 0 3 1z" fill="#BDBCBF" />
            <path transform="translate(596,1724)" d="m0 0 3 1z" fill="#BDBCBF" />
            <path transform="translate(720,1727)" d="m0 0 2 1z" fill="#BDBCBF" />
            <path transform="translate(584,1723)" d="m0 0" fill="#BDBCBF" />
            <path transform="translate(1720,576)" d="m0 0" fill="#8F8F95" />
            <path transform="translate(24,106)" d="m0 0" fill="#BDBCBF" />
            <path transform="translate(26,54)" d="m0 0" fill="#FEFEFE" />
            <path transform="translate(26,31)" d="m0 0" fill="#FEFEFE" />
            <path transform="translate(694,24)" d="m0 0" fill="#8F8F95" />
            <path transform="translate(674,24)" d="m0 0" fill="#8F8F95" />
            <path transform="translate(733,1727)" d="m0 0" fill="#BDBCBF" />
            <path transform="translate(723,1727)" d="m0 0" fill="#BDBCBF" />
            <path transform="translate(701,1727)" d="m0 0" fill="#BDBCBF" />
            <path transform="translate(698,1727)" d="m0 0" fill="#BDBCBF" />
            <path transform="translate(692,1727)" d="m0 0" fill="#BDBCBF" />
            <path transform="translate(687,1727)" d="m0 0" fill="#BDBCBF" />
            <path transform="translate(671,1727)" d="m0 0" fill="#BDBCBF" />
            <path transform="translate(610,1724)" d="m0 0" fill="#BDBCBF" />
            <path transform="translate(592,1723)" d="m0 0" fill="#BDBCBF" />
            <path transform="translate(23,932)" d="m0 0" fill="#BDBCBF" />
            <path transform="translate(23,930)" d="m0 0" fill="#BDBCBF" />
            <path transform="translate(23,912)" d="m0 0" fill="#BDBCBF" />
            <path transform="translate(1720,584)" d="m0 0" fill="#8F8F95" />
            <path transform="translate(1720,579)" d="m0 0" fill="#8F8F95" />
            <path transform="translate(1722,32)" d="m0 0" fill="#8F8F95" />
        </svg>

    )

}


export default PieChart;