import React, { useState } from 'react';
import { constants } from '../../utils/constants';
import { Button } from '../hoc/button';
import { Wrapper } from './entity.styled';
import { PageInnerWrapper } from '../hoc/styled.hoc'
import { DivRow } from '../styled-hoc';
import { connect } from 'react-redux';
import { saveEntities } from '../../actions/entity';
import { getFromSession } from '../../utils/session.helper';
import TextBox from '../hoc/textBox'
import TitleHeader from '../title-header'


function Index(props) {


    const [entityValue, setInputVal] = useState({
        entity_name: '',
        table_name: '',
        remarks: '',

    });


    const onChange = (e, key) => {
        let value = e.target.value;

        setInputVal({ ...entityValue, [key]: value });
    };

    const clientIdFromSession = getFromSession(constants.SESSION_KEYS.CLIENT_ID);
    const clientId = process.env.REACT_APP_DEV_LOGIN_KEY ? (clientIdFromSession ? clientIdFromSession : constants.CLIENT_ID) : constants.CLIENT_ID;

    const onSave = () => {

        const finalValue = []

        const tempEntity = Object.assign({}, entityValue);

        tempEntity.client_id = clientId;

        finalValue.push(tempEntity)

        props.saveEntities(finalValue);

        setInputVal({
            entity_name: '',
            table_name: '',
            remarks: ''
        });
    };


    return (
        <div>
            <Wrapper>
                <TitleHeader title="Create Entity"/>
                <PageInnerWrapper>
                    <div style={{ width: '75%' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '2.571rem' }}>
                            <DivRow w="47%">
                                <TextBox 
                                    label="Entity Name"
                                    type="text"
                                    value={entityValue.entity_name} 
                                    onChange={(e) => onChange(e, "entity_name")}
                                />
                            </DivRow>

                            <DivRow w="47%">
                                <TextBox 
                                    label="Table Relationship"
                                    type="text"
                                    value={entityValue.table_name} 
                                    onChange={(e) => onChange(e, "table_name")} 
                                />
                            </DivRow>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '2.571rem' }}>
                            <DivRow w="47%">
                                <label>Remark</label>
                                <div style={{
                                    position: 'relative'
                                }}>
                                    <textarea value={entityValue.remarks} onChange={(e) => onChange(e, "remarks")} />
                                </div>
                            </DivRow>
                        </div>

                    </div>

                    <div
                        style={{
                            padding: '25px 0px',
                            borderTop: '1px solid #ccc',
                            marginTop: 'auto',
                            display: 'flex'
                        }}>
                        <Button
                            width="8rem"
                            primary={true}
                            mR="1.143rem"
                            onClick={onSave}
                        >Save Entity</Button>
                        <Button
                            width="8rem"
                            bgColor={'#e6e6e6'}
                            onClick={() => {
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </PageInnerWrapper>
            </Wrapper>
        </div >
    )
}


const mapStateToProps = (state) => {
    return {

    }
}

export default connect(mapStateToProps, { saveEntities })(Index)
