import styled from 'styled-components';

import { themes } from '../../../theme/theme.config';
import { getSelectedTheme } from '../../../utils';

const selected = getSelectedTheme();

const { connectStyle } = themes[selected];



export const EditorMainWrapper = styled.div`
    width: 100%;
`;



export const Row = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`;


export const Column = styled.div`
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 20px;
    /* border-radius: 3px; */
    box-shadow: 0px 2px 6px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    background:${props => props.active ? connectStyle.color.iconBoxActive : undefined};
    padding: .3rem;
    transition: all 0.2s;
    overflow: hidden;
    position: relative;
    

    &:hover{
        img{
            transform: scale(1.1);
        }
    }

    img{
            max-width: 100%;
            max-height: 100%;
            transition: all 0.2s;
            /* transform: scale(.8); */

        }

    /* &:hover{
        background:${connectStyle.color.iconBoxHover}
    } */
    /* .icon{
        width: 95px;
        height: 95px;
        display: flex;
        align-items: center;
        justify-content:center;
        background: ${connectStyle.color.iconBg};
        overflow: hidden;
        img{
            max-width: 100%;
            max-height: 100%;
        }
    }
    p{
        margin: 0px;
        font-size: .8rem;
        margin-top: 10px;
        text-transform: uppercase;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    } */
`;

