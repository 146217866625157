import {  simpleDispatch_get, simpleDispatch_post } from './helper';
import * as actionTypes from '../action-types/demo-request';
import { constants } from '../utils/constants';


export const getDemoRequestList = () => async dispatch => {
    const url = constants.END_POINTS.DEMO_REQUEST_API + constants.END_POINTS.DEMO_BOOKING.POINT + constants.END_POINTS.DEMO_BOOKING.GET_DEMO_REQUEST_LIST;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_DEMO_REQUEST_LIST);
}



export const approveDemoRequest = (item) => async dispatch => {
    const url = constants.END_POINTS.DEMO_REQUEST_API + constants.END_POINTS.DEMO_BOOKING.POINT + constants.END_POINTS.DEMO_BOOKING.APPROVED_DEMO_REQUEST;
    let body = {
        id: item.id,
    }
    await simpleDispatch_post(dispatch, url, body, actionTypes.APPROVE_DEMO_REQUEST)
}


export const deleteDemoRequest = (item) => async dispatch => {

    const url = constants.END_POINTS.DEMO_REQUEST_API + constants.END_POINTS.DEMO_BOOKING.POINT + constants.END_POINTS.DEMO_BOOKING.DELETE_DEMO_REQUEST;
    let body = {
        id: item.id,
    }

    await simpleDispatch_post(dispatch, url, body, actionTypes.DELETE_DEMO_REQUEST)
}



export const editDemoRequest = (item) => async dispatch => {

    const url = constants.END_POINTS.DEMO_REQUEST_API + constants.END_POINTS.DEMO_BOOKING.POINT + constants.END_POINTS.DEMO_BOOKING.EDIT_DEMO_REQUEST;
    let body = {
        data: item
    }

    await simpleDispatch_post(dispatch, url, body, actionTypes.EDIT_DEMO_REQUEST)
}



export const getTimeSlotList = () => async dispatch => {

    const url = constants.END_POINTS.DEMO_REQUEST_API + constants.END_POINTS.DEMO_BOOKING.POINT + constants.END_POINTS.DEMO_BOOKING.GET_ALL_TIME_SLOT_LIST
    await simpleDispatch_get(dispatch, url, actionTypes.GET_ALL_TIME_SLOT_LIST);
}



export const getBookedInfyCurrentDate = () => async dispatch => {

    const url = constants.END_POINTS.DEMO_REQUEST_API + constants.END_POINTS.DEMO_BOOKING.POINT + constants.END_POINTS.DEMO_BOOKING.GET_BOOKED_INFO_BY_CURRENT_DATE;

    await simpleDispatch_get(dispatch, url, actionTypes.GET_BOOKED_INFO_BY_CURRENT_DATE)
}
