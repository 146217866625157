import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux'
import SelectOption from './db.select.option';
import { NormalButton } from '../hoc/normal.button';
import RefreshSvg from '../svg/refresh'
import CloneSvg from '../svg/duplicate';
import PlaySvg from '../svg/play'
import ThemeSvg from '../svg/theme'
import SaveSvg from '../svg/save.rect';
import Tab from '../tab'
import { showPopup } from '../../utils';
import { get_schema_table_info } from '../../actions/report_builder';
import ToggleButton from '../hoc/toogle.btn';
import RippleEffect from '../hoc/ripple-effect';
import * as enums from '../../utils/enums.js';
import ConfirmBox from '../confirm-box';
import BackSvg from '../svg/back'
import SelectOptionV1 from '../select-option/select.option.v1.1';
import AccessGroupPicker from '../hoc/access_group.picker';
import Duplicate from '../svg/duplicate';
import ReloadIcon from '../svg/icons-v2/reloadIcon.js';
import DuplicatIcon from '../svg/icons-v2/duplicateIcon.js';
import SaveIcon from '../svg/icons-v2/downloadIcon.js';
import AzureSynapseIcon from '../svg/icons-v2/azure_synapse.js';
import HiveIcon from '../svg/icons-v2/hiveIcon.js';
import MongoDbIcon from '../svg/icons-v2/mongodbIcon.js';
import SqlServeIcon from '../svg/icons-v2/sqlserverIcon.js';
import MySqlServeIcon from '../svg/icons-v2/mySqlserverIcon.js';


export const EditorHeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding: 13px 0px;
    align-items: center;
    background: #EFEFEF;
    /* margin-bottom: 1rem; */
    /* box-shadow: 4px 4px 5px 0px rgba(219,219,219,1); */
    box-shadow: 0 1px 2px rgba(56, 65, 74, .15);
    .schema_block{
        width: 20.1rem;
        padding: 0px 0px;
        padding-right: 1rem;
        padding-left: 1rem;
        box-sizing: border-box;
        position: relative;
        .none_user_input{
            /* height: 2.2rem; */
            margin: 0;
            border-radius: 6px;
            border: 2px solid #5562f9 !important;
        }   
        .select_arrow{
            height: 74%;
            margin: auto;
        }
        .select_choose{
            margin-bottom: 0.5rem;
        }
    }
    

    .selected_schema{
        /* transition: 0.2s; */
        font-size: 0.9rem;
        /* color: #374a66; */
        text-transform: capitalize;
        margin: 0px;
        margin-bottom: 4px;
        color: #222;;
        font-weight: 500;
        /* position: absolute;
        z-index: 1; */
        /* top: -.5rem; */
        /* background: #fff; */
        margin-left: 10px;
    }

   

`;


export const TabWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: 2rem;
    width: 18rem;
`;

// export const Tab = styled.div`
//     padding: .5rem 1rem;
//     border: ${props => props.active ? "1px solid #308bc8" : '1px solid #eaeaea'};
//     font-size: 1rem;
//     font-weight: 500;
//     border-radius:3px;
//     width: 4rem;
//     text-align: center;
//     cursor: pointer;
//     text-transform: capitalize;
//     background-color: ${props => props.active ? '#308bc8' : undefined};
//     color: ${props => props.active ? "#fff" : undefined};
//     margin-right: 5px;

//     &:hover{
//         border-color: #308bc8;
//     }

// `;


const EditorHeader = (props) => {


    // here we are destructuring the props object 
    const {
        db_infos,
        setConnectionId,
        setShowColumns,
        setLocalTableColumns,
        connectionId,
        onSave,
        reset_all_data,
        activeReportId,
        set_active_editor,
        active_editor,
        open_theme_editor,
        change_data_mode,
        show_sql_editor,
        set_access_group_report_mapping,
        access_group_report_mapping,
        hide_theme_editor,

    } = props;


    // const { menu_group, set_menu_group_id, menu_group_id }  = props;

    // console.log("active_editor", active_editor)


    const get_active_mode = () => {
        if (active_editor === 'sql') {
            return "sql"
        }
        if (active_editor === 'config_query_builder') {
            return "code"
        }
        else return active_editor
    }


    const grpupIcon = (type) => {
        let icon = '';
        switch (type) {
            case 'azure_synapse':
                icon = <AzureSynapseIcon size='1.6rem' height='1.6rem' color="#afc4e3" />
                break;
            case 'hive':
                icon = <HiveIcon size='1.6rem' height='1.6rem' color="#000000" />
                break;
            case 'mongo':
                icon = <MongoDbIcon size='1.6rem' height='1.6rem' color="#000000" />
                break;
            case 'sqlServer':
                icon = <SqlServeIcon size='1.6rem' height='1.6rem' color="#8f4949" />
                break;
            case 'mysql':
                icon = <MySqlServeIcon size='1.6rem' height='1.6rem' color="#8f4949" />
                break;
            case 'oracle':
                icon = <SqlServeIcon size='1.6rem' height='1.6rem' color="#8f4949" />
                break;
            default:
                break;
        };
        return icon;
    }


    return (
        <EditorHeaderWrapper>
            <div className="schema_block">
                {connectionId?.length > 0 && (
                    <p className='selected_schema'>Your Selected Schema</p>
                )}
                <SelectOption
                    // label={"Select Schema"}
                    selections={db_infos && db_infos.length > 0 ? db_infos : []}
                    inpContHeight='2.4rem'
                    // removeInputHeight={true}
                    onChangeFunc={(value) => {
                        var selected_ids = connectionId ? [...connectionId] : [];
                        if (selected_ids.indexOf(value) > -1) {
                            // let's remove from selection
                            selected_ids = [];
                        } else {
                            selected_ids[0] = value
                        }
                        setConnectionId(selected_ids)
                        props.set_reporting_db_info_id(selected_ids)

                    }}
                    displayKey={"connection_name"}
                    valueKey={"id"}
                    groupKey='db_type'
                    sortKey='desc'
                    activeValue={connectionId ? connectionId : undefined}
                    isSearch={true}
                    isCheckBox={true}
                    isMultiSelect={true}
                    applyFunction={(values) => {
                        props.get_schema_table_info(values)
                    }}
                    width="100%"
                    height="14rem"
                    lineHeight="1.857rem"
                    borderColor='#eaeaea'
                    color='#7c7c7c'
                    overlay_z_index={3}
                    groupIcon={true}
                    groupIconFn={grpupIcon}

                />
            </div>
            <div
                style={{
                    display: 'flex',
                    width: '35rem',
                    marginLeft: '1rem',
                    paddingTop: '1rem',
                }}>

                <NormalButton
                    padding='.4rem'
                    height='auto'
                    bgColor='#fff'
                    hoverBg={'#e2e2e2'}
                    mR=".5rem"
                    onClick={() => {
                        reset_all_data()
                    }}
                    disabled={show_sql_editor}
                    color="#374a66"
                    icon={<ReloadIcon size='1.4rem' height='1.4rem' color="#afc4e3" />}
                    title="Reset"
                />
                {/* <NormalButton
                    bgColor='#fff'
                    mR=".5rem"

                    onClick={() => {
                        if (props.type !== "cache_editor") {
                            onSave()

                        }
                    }}
                    color="#374a66"
                    iconStyle={{
                        marginTop: '7px'
                    }}
                    disabled={props.type === "cache_editor"}
                    icon={<SaveSvg size='1.2rem' height='1.2rem' color="#afc4e3" />}
                    // label="Save"
                /> */}

                {/* <NormalButton
                    padding='.4rem'
                    height='auto'
                    bgColor='#fff'
                    hoverBg={'#e2e2e2'}
                    mR=".5rem"
                    onClick={() => {
                        // onSave(true)
                    }}
                    // disabled={true}
                    title="This is not enabled"
                    color="#374a66"
                    icon={<DuplicatIcon size='1.2rem' height='1.2rem' color="#afc4e3" />}
                // title="Clone"
                /> */}

                {/* {active_editor === 'config_query_builder' && ( */}
                <React.Fragment>
                    {!show_sql_editor && (
                        <NormalButton
                            padding='.4rem'
                            primary={true}
                            height='auto'
                            bgColor={'#FFF'}
                            hoverBg={'#e2e2e2'}
                            mR=".5rem"
                            onClick={() => {
                                if (props.type !== "cache_editor") {
                                    onSave()

                                }
                            }}
                            disabled={props.type === "cache_editor"}
                            color="#374a66"
                            icon={<SaveIcon size='1.2rem' height='1.2rem' />}
                            title="Save"
                        />
                    )}
                </React.Fragment>
                {/* )} */}

                <NormalButton
                    padding='.4rem'
                    height='auto'
                    width='6rem'
                    mR=".5rem"
                    primary={true}
                    onClick={() => {

                        onSave(true)
                    }}
                    icon={<PlaySvg size='.8rem' height='.8rem' color="#fff" />}
                    label="Execute"
                />
            </div>

            {/* <AccessGroupPicker
                access_group_report_mapping={access_group_report_mapping}
                set_access_group_report_mapping={set_access_group_report_mapping}
            /> */}

            {props.change_report_type && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 'auto', marginRight: '30px' }}>
                    <div
                        style={{ color: get_active_mode() === "code" ? '#222' : '#374a6694', fontSize: '.8rem' }}>Drag & Drop</div>&nbsp;&nbsp;
                    <div>
                        <ToggleButton bothSide={true}
                            activeColor="linear-gradient(to right, #3E9EFF, #737DFF)"
                            checked={active_editor === "sql"}
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                const mode = get_active_mode()
                                if (activeReportId) {
                                    // showPopup(undefined, 'Changing the mode we clear all the pivot data', enums.default.popupType.element, ConfirmBox, { func: { setYes: () => change_data_mode((mode === 'sql' ? 'config_query_builder' : 'sql')) } })
                                    // showPopup(undefined, "You can't switch the report mode", enums.default.popupType.element, ConfirmBox)
                                    alert("You can't change the report mode please create fresh report.")

                                } else {
                                    set_active_editor(mode === 'sql' ? 'config_query_builder' : 'sql')
                                }
                            }}
                        />
                    </div>&nbsp;&nbsp;
                    <div style={{ color: get_active_mode() === "sql" ? '#222' : '#374a6694', fontSize: '.8rem' }}> Code</div>
                </div>
            )}


            {!hide_theme_editor && (
                <div style={{ marginRight: '10px' }}>
                    <RippleEffect
                        tooltip="Open Theme Editor"
                        icon={<ThemeSvg size='1.2rem' height='1.2rem' color="#374a66" />}
                        Click={() => {
                            open_theme_editor()
                        }}
                    />
                </div>
            )}

        </EditorHeaderWrapper>
    );
}










const mapStateToProps = (state) => ({
    db_infos: state.connectionReducer.db_infos,
    accessGroupList: state.groupAccessReducer.accessGroupList,

})



export default connect(mapStateToProps, { get_schema_table_info })(EditorHeader);
