import styled from 'styled-components';


import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils'
const selected = getSelectedTheme();


const { lefterMenuConfig } = themes[selected]


export const Version = styled.div`
    position: absolute;
    bottom: 10px;
    right: 20px;
    color: ${lefterMenuConfig.color.version};
    font-size: 12px;
    font-weight: bold;
`;

export const MainWrapper = styled.div`
    width: 24.143rem;
    overflow: hidden;
    left: ${props => props.showFull ? "0px" : "-30rem"};
    height: 100vh;
    position: fixed;
    background: ${lefterMenuConfig.color.wrapperBg};
    z-index: 12;
    transition: left .6s;
    top: 0px;
    
    .rotate_180deg{
        transform: rotate(90deg);
    }
    .not_rotate{
        transform: rotate(0deg);
    }
`;



export const FlexWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 1rem;
    box-sizing: border-box;
`;



export const MenuWrapper = styled.div`
    padding-top: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 2.5rem;    overflow: hidden;
    overflow: hidden;
    height: 100%;
    
`;


export const MenuTab = styled.div`
    position: relative;
    transition: all .3s ease-in;
    &:hover{
        transform: ${props => props.active ? undefined : 'scale(1.1)'};
        div{
            color : ${props => props.active ? undefined : '#d8d1d1'};
        }
    }
    
    &::after{
        content: '';
        width: 100%;
        height: 80%;
        background: ${props => props.active ? `${lefterMenuConfig.color.backgroundActive}` : 'transparent'};
        position: absolute;
        left: 0px;
        top: 0;
        z-index: -1;
        transform: skewy(-40deg);
    }
    
    &::before{
        content: '';
        width: 100%;
        height: 80%;
        background: ${props => props.active ? `${lefterMenuConfig.color.backgroundActive}` : 'transparent'};
        position: absolute;
        left: 0px;
        bottom: 0px;
        z-index: -1;
        transform: skewy(40deg);
    } 
    div{
        transform: rotate(-90deg);
        padding: ${props => props.p || '5.357rem'};
        transform-origin: center;
        font-size: .8rem;
        font-weight: bold;
        white-space: nowrap;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29rem;
        letter-spacing: normal;
        text-align: left;
        color: ${lefterMenuConfig.color.text};
        text-transform: uppercase;
        cursor: pointer;
        position: relative;
        
    }
`;



export const NavigationWrapper = styled.div`
    background: ${lefterMenuConfig.color.backgroundRight};
    width: calc(100% - 50px);
    height: 100%;
    padding-left: 2.143rem;
    padding-right: 1.786rem;
    overflow: auto;
    height: ${props => props.height + "px" || '100%'};
  
`;


export const MenuHeader = styled.div`
    width: 100%;
    display: flex;
    align-items:center;
    justify-content: flex-end;
    height: 65px;
    box-sizing: border-box;
    border-bottom: ${props => props.borderBottom ? '1px solid #fff' : undefined};
    img{
        max-height: 1.143rem;
        cursor: pointer;
    }
`;




export const IconTitle = styled.div`
     display: flex;
     flex-direction: row;
     align-items:center;
     justify-content: flex-start;
     font-size: .9rem;
     font-weight: 300;
     font-stretch: normal;
     font-style: normal;
     line-height: 1.29rem;
     letter-spacing: normal;
     text-align: left;
     color: ${lefterMenuConfig.color.text};
    cursor: pointer;
    .action{
            margin-left: 6px;
            img{
                width: 0.857rem;
                vertical-align: middle;
            }
    }
    .main_title_transform{
        transition: all .3s ;
       
    }
    &:hover{
        .main_title_transform{ 
            text-decoration: underline;
        }
    }
     
`;





export const NavHolder = styled.div`
    width: 100%;
    padding: ${props => props.padding ? props.padding : '0.857rem 0px'};
    border-bottom: ${props => props.border ? props.border : '1px solid #fff'};
    border-top: ${props => props.borderTop ? '1px solid #fff' : undefined};
    display: flex;
    flex-direction: column;
    padding-bottom: ${props => (!props.isMain && props.isOpen) ? '1.5rem' : undefined};

    .last_element{
        position: relative;

        &:after {
            content: '';
            position: absolute;
            left: 0px;
            top: .8rem;
            width: 1rem;
            height: 100%;
            background: #308bc8;
        }
    }

    .edit_icon{
        
    }

.secondery_menu >
    .right_menu_ul{
        &::before{
            height: 100% !important;
        }
    }
     .icon{
        width: 1.214rem;
        margin-right: 0.857rem;
    }
    .parent_node{
        width: 100%;
        padding-left: 38px;
        box-sizing: border-box;
        height: auto;
        overflow: ${props => props.isOpen ? 'inherit' : 'hidden'};
        .action{
            margin-left: 6px;
            img{
                width: 0.714rem;
                vertical-align: middle;
            }
        }
    }
    .secondery_menu{
        font-size: 0.857rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5rem;
        letter-spacing: normal;
        text-align: left;
        color: ${lefterMenuConfig.color.text};
        padding-left: 1.071rem;
        position: relative;
    }
    .element_scroll{
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 10px;
        max-height: ${props => props.isOpen ? '22rem' : '0px'};
      &::-webkit-scrollbar {
                width: 3px;
        }
        &::-webkit-scrollbar-track {
            background-color:#fff;
        }
        &::-webkit-scrollbar-thumb {
            background-color:#1f5d86;
            border-radius:0px;
            height: 0.357rem;
        }
    }
   
    .child_node{
        width: 100%;
        box-sizing: border-box;
        position: relative;
        ul{
            list-style: none;
            margin: 0px;
            padding: 0px 0px;
            padding-left: 0px;
            position: relative;
            &::before{
                content: '';
                position: absolute;
                left: 3px;
                top: -5px;
                height: calc(100% - 9px);
                border: 0.5px dotted #ffffff99;
            }
            .main_title_transform{
                    transition: all .3s ;
                    &:hover{
                        text-decoration: underline;
                    }
                }

            .no_border_class{
               position: relative;
               &::before{
                     border: 0px !important;
                    width: 0 !important;
               }
               .right_menu_ul{
                    &::before{
                        border: 0px !important;
                        width: 0 !important;
                }
               }

            }
            
            li{
                font-size: 0.857rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5rem;
                letter-spacing: normal;
                text-align: left;
                color: ${lefterMenuConfig.color.text};
                padding-left: 1.071rem;
                position: relative;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                cursor: pointer;
                transition: all 0.2s;
                &::before{
                    content: '';
                    position: absolute;
                    left: 3px;
                    top: 0.7rem;
                    border: 0.5px dotted #ffffff99;
                    width: 0.714rem;
                }
            }
        }
    }
`;





export const InnerChild = styled.div`
    width: '100%';
    height: auto;


`;

export const ClientWrapper = styled.div`
    position: absolute;
    bottom: 40px;
    right: 20px;

    select {
        background: transparent;
        border: 1px solid white;
        border-radius: 3px;
        padding: 3px 5px;
        color: white;

        option {
            color: black;
        }
    }
`;