import * as actions from '../../action-types/v3/data';
import { constants } from '../../utils/constants';

const initialState = {
    type: actions.DATA_INIT,
    records_count: 0,
    data_from_server: {},
};


const v3_dataReducer = (state, action) => {
	if (!state || typeof action.type === 'undefined') {
		return initialState;
    }

    switch (action.type) {
        case actions.DATA_RECEIVED:
            const data = action.data;

            return {
                ...state,
                type: action.type,
                data_from_server: data
            };

        case actions.DATA_REQUESTING:
            return {
                ...state,
                type: action.type,
            };

            // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
			return state
        }
};

export default v3_dataReducer;