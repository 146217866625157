import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { clearQuestionInfo, getAllInfoOfQuestion, } from '../../../actions/etl';
import TextBox from '../../hoc/textBox';
import { DivRow } from '../../styled-hoc';
import SelectOption from '../../select-option';
import { convertDateInToHtmlDate, showPopup } from '../../../utils';
import RippleEffect from '../../hoc/ripple-effect'
import { getHintsByDbInfo } from '../../../actions/hints';
import { getTargetTableData, getCollectionsOfMongo, viewDirectoryFileAction } from '../../../actions/etl';
import ViewSvg from '../../svg/view';
import ViewFileInfo from './view.file.popup';
import ReportBuilder from '../../editor.shared/ediotr.v1.1';
import { frequencyTypes } from '../constants';
import Checkbox from '../../hoc/checkbox';
import SelectOptionV1 from '../../select-option/select.option.v1.1';
import ExclamationSvg from '../../svg/exclamation_i';
import { constants } from '../../../utils/constants';
import enums from '../../../utils/enums';



const Wrapper = styled.div`

    width: 100%;

    .step_one_wrapper{
        width: 70%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    img{
        max-width: 100%;
    }

    .center_icon{
        margin: auto;
        margin-bottom: 1rem;
        width: 5rem;
    }

    .sub_title{
        margin-top: 8px;
        margin-bottom: 2.4rem;
        font-size: .9rem;
        color: #333;
    }
    

`;


export const CardBox = styled.div`
    position: relative;
    padding: .5rem;
    box-sizing: border-box;
    margin-bottom: 2rem;

    &::before{
        content: '';
        height: 100%;
        width: 5px;
        background: ${props => props.bg ? props.bg : '#000'};
        position: absolute;
        left: -1rem;
        border-radius: 3px;
        top: 0px;
    }

    h2{ 
        margin: 0px;
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 2rem;
        color: #222;
    }

    .card_row{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }

    .or_style{
        font-style: italic;
        display: inline-block;
        font-weight: bold;
        background: rgb(48 139 200);
        color: #fff;
        width: 2re;
        width: 2.7rem;
        height: 2.7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid #000;
    }
`;



const Row = styled.div`

    width: 100%;;
    display: flex;
    flex-direction: row;

`;

const Card = styled.div`
    width: 17rem;
    height: 20rem;
    border-radius: 8px;
    margin-right: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
    box-sizing: border-box;
    box-shadow: ${props => props.active ? '1px -1px 15px 4px #cccccca6' : '1px 2px 3px 1px #cccccca6'};
    cursor: pointer;
    transition: all 0.2s;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-bottom: ${props => props.active ? '15px solid #2173F7' : undefined};

    &:hover{
        /* background-color: #66bee6; */
    }

    svg{
        /* color: ${props => props.active ? '#fff' : undefined}; */
        fill: #fff;;

    }
    /* p{
        text-align: ;
    } */

    /* .active{
        background-color: #0084be;
    } */

    .__icon{
        width: 5rem;
        height: 5rem;
        margin: 0px auto;
        text-align: center;
        background-color: #96c0d3;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
        
    }
    .title{
        font-size: 1.2em;
        font-weight: 600;
    }
    p{
        font-size: .9rem;
        color: #333;
        margin-top: 9px;

    }
`;



const GenerateCodeWrapper = styled.div`

    width: 60%;
    margin: 0rem auto;
    margin-bottom: 1rem;

    .search_wrapper{

        width: 100%;
        margin: auto;

        .search_box{
            width: 100%;
            box-sizing: border-box;
            border-radius: 40px;
            height: 3.2rem;
            padding: 5px 1rem;
            overflow: hidden;
            position: relative;
            border: 1px solid #b3b3b3;
            box-shadow: 3px 4px 4px #cccccca3;


            .gen_btn{
                position: absolute;
                right: 6px;
                padding: 0.7rem 1rem;
                border-radius: 35px;
                font-size: 1rem;
                top: 0px;
                bottom: 0px;
                height: 1.2rem;
                margin: auto;
                color: #fff;
                background: #2173F7;
                cursor: pointer;
                font-weight: 500;
                &:hover{
                    opacity: .8;
                }
            }
        }
        p{
            text-align: center;
            font-size: 1.5rem;
            margin: 0px;
            margin-bottom: 9px;
            color: #000;

        }
        input{
            width: 100%;
            height: 100%;
            overflow: hidden;
            border: 0px;;
            font-size: 1.2rem;
            padding-right: 9rem;
            box-sizing: border-box;
            background-color: transparent;
            padding-left: 1rem;
            &:hover{
                outline: none;
            }
            &:focus{
                outline: none;
            }
            &::placeholder {
                text-align: left;
                font-size: 1rem;
                color: #b3b3b3;
            }

        }
    }

`;



const IncrementalWrapper = styled.div`

    width: 100%;
    position: relative;
    left: ${props => props.active ? '0%' : '-110%'};
    transition: all 2.2s;

    .lable___{
        font-size: 1rem;
    }

    .incremental_box{
        display: flex;
        align-items: center;
        background: #008ac7;
        width: 9rem;
        border-radius: 30px;
        color: #fff;
        padding: 6px 6px;
    }

`;




const QuestionInfoWrapper = styled.div`

    width: 50%;
    margin: 0rem auto;
    box-shadow: 3px 4px 4px #cccccca3;
    padding: 1.5rem;
    box-sizing: border-box;
    border-radius: 3px;
    background-color: #fff;
    position: relative;
    left: ${props => props.active ? '0%' : '-110%'};
    transition: all 1s;
    /* border-right: 16px solid #008ac7; */

    .title{
        width: 100%;
        font-size: 1rem;
        color: #555;
        border-bottom: 1px solid #ccc;
        margin-bottom: 1rem;
        padding-bottom: 15px;
        text-transform: uppercase;
    }

    ._row{
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-bottom: 1rem;

        .lf_label{
            width: 250px;
            text-transform: uppercase;
            color: #2173F7;
            font-size: .9rem;
        }

        .rh_value{
            display: flex;
        }

        .batch{
            margin-right: 10px;
            padding: 4px 12px;
            font-size: .9rem;
            border-radius: 9px;
            background: #F2F2F2;
            color: rgba(0, 0, 0, 0.6);
            box-shadow: 2px 2px 3px 1px #ccc;
            font-weight: 600;
        }

        .query_icon{
            background: #008ac7;
            padding: 6px;
            box-sizing: border-box;
            border-radius: 50%;
            width: 1.2rem;
            height: 1.2rem;
            display: flex;
            animation: c;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover{
                opacity: .8;
            }

        }
    }

`;



const Index = (props) => {


    const {

        extraction,
        extraction_state,
        set_extraction_state,
        data_source_type,
        set_data_souce_type,
        db_interaction_type,
        set_db_interaction_type,
        local_report,
        set_local_report,
        setActiveStep,
        activeStep,
        set_report_date_columns,
        report_date_columns,
        setInnerStep,
        innerStep,
        reporting_db_info_id,
        local_questionInfo,
        date_column_selection
    } = props;




    const [show_incremental, set_show_incremental] = useState(true);


    const updated_report = (report, is_run_report, other_data) => {
        const date_columns = other_data?.['date_columns'] || [];
        set_local_report(report)
        set_report_date_columns(date_columns);
        props.set_date_column_selection(date_columns)
        setInnerStep(innerStep + 1)

    }


    const update_sync = (value) => {
        on_change_v1(value, "sync")
    }

    const open_report_builder = () => {

        showPopup("Prepare Your Extraction and Transformation", undefined, enums.popupType.element_with_header, ReportBuilder, {

            db_info_id: reporting_db_info_id ? reporting_db_info_id : 'demo_med_db_info_id',
            render_mode: "table",
            current_report: local_report,
            save_function: updated_report,
            update_sync : update_sync,
            report_type: "etl_config_query_builder",
            report_field_title: 'Extraction Fields',
            hide_header: true,
            hide_connection_selector: true,
            hide_user_data_source: false,
            hide_compiler_hints: true,
            hide_report_inputs: true,
            hide_filter_fields: true,
            hide_others_btn: true,
            is_etl: true,
            sync: extraction_state?.['sync']
        }
            ,
            undefined,
            undefined,
            undefined, {
            width: '95vw',
            height: '85vh'
        },
            true
        )

    }


    const on_change_v1 = (value, key) => {
        const clone_extraction_state = extraction_state ? { ...extraction_state } : {};
        clone_extraction_state[key] = value;
        clone_extraction_state["modified"] = true;
        set_extraction_state(clone_extraction_state);
    }

    console.log("extraction_state", reporting_db_info_id)

    return (
        <Wrapper style={{ width: '100%' }}>
            {data_source_type === 'directory' && (
                <CardBox style={{ width: '100%', marginTop: '1rem' }} bg={"#03a9f4"}>
                    <h2>Data Source Inputs</h2>
                    <div className="card_row">
                        <div style={{ width: '35%', position: 'relative' }}>
                            <TextBox
                                type="text"
                                label={'Data Source Directory'}
                                value={extraction_state && extraction_state['main_directory'] ? extraction_state['main_directory'] : undefined}
                                onChange={(e) => {
                                    on_change_v1(e.target.value, 'main_directory')
                                }}
                            />
                            {extraction_state && extraction_state['main_directory'] && extraction_state['main_directory'].length > 2 && (
                                <div style={{ position: 'absolute', right: '0px', top: '10px' }}>
                                    <RippleEffect
                                        icon={<ViewSvg size=".9rem" height=".9rem" color="#222" />}
                                        title="View"
                                        Click={() => {
                                            props.viewDirectoryFileAction(undefined, extraction_state['main_directory'])
                                            showPopup(undefined, undefined, enums.popupType.element, ViewFileInfo, {
                                                headerTitle: 'Your File Information',
                                                file_path: extraction_state['main_directory'],
                                                is_main_dir: true
                                            })
                                        }}
                                    />
                                </div>

                            )}

                        </div>
                        <div style={{ width: '20%' }}>
                            <SelectOption
                                width={"100%"}
                                label={"File Type"}
                                selections={["json", 'xml', 'csv']}
                                activeValue={extraction_state && extraction_state['extension'] ? extraction_state['extension'] : undefined}
                                onChangeFunc={(value) => {
                                    on_change_v1(value, 'extension')
                                }}

                            />
                        </div>

                        <div style={{ width: '30%' }}>
                            <SelectOption
                                width={"100%"}
                                label={"Choose Files To Process"}
                                selections={["All", 'Choose Your Files']}
                                value={extraction_state && extraction_state['landing_date'] ? extraction_state['landing_date'] : undefined}
                                onChange={(value) => {
                                    on_change_v1(value, 'landing_date')
                                }}
                            />
                        </div>


                    </div>
                </CardBox>
            )}

            {innerStep === 1 && db_interaction_type == "etl_natural_language" && (
                <div >
                    <GenerateCodeWrapper>

                        <div className='search_wrapper'>
                            <p className='title_up'>Provide Your Transformation Rule</p>
                            <div className='search_box'>
                                <input type="text" placeholder='Type your question' value={extraction_state?.nlp_query} onChange={(e) => {
                                    on_change_v1(e.target.value, 'nlp_query')

                                }} />
                                <div className='gen_btn' onClick={() => {
                                    const db_id_to_use = reporting_db_info_id?.indexOf("__nf__db__cached__") > -1 ? reporting_db_info_id : reporting_db_info_id + "__nf__db__cached__";
                                    if (extraction_state?.nlp_query?.length > 0) {
                                        props.getAllInfoOfQuestion(extraction_state?.nlp_query, db_id_to_use, true)
                                    } else {
                                        alert("Please Provide Transformation Rule")
                                    }
                                }}>Generate Code</div>
                            </div>
                        </div>
                    </GenerateCodeWrapper>

                    <QuestionInfoWrapper active={(local_questionInfo && Object.keys(local_questionInfo)?.length > 0)}>
                        <div className='title'>Here we Go </div>
                        <div className='_row'>
                            <div className='lf_label'>Tables </div>
                            {local_questionInfo?.tables?.length > 0 && (
                                <div className='rh_value'>
                                    {local_questionInfo?.tables?.map((v, idx) => {
                                        return <div key={idx} className='batch'>{v}</div>
                                    })}
                                </div>
                            )}
                        </div>

                        <div className='_row'>
                            <div className='lf_label'>Columns </div>
                            {local_questionInfo?.columns_extracted?.length > 0 && (
                                <div className='rh_value'>
                                    {local_questionInfo?.columns_extracted?.map((v, idx) => {
                                        console.log("columns_extracted", local_questionInfo.columns_extracted)
                                        return <div title={v.name} className='batch' key={"jjj" + idx}>{v?.table_column}</div>

                                    })}
                                </div>
                            )}
                        </div>

                        <div className='_row'>
                            <div className='lf_label' style={{ display: 'flex' }}>Generated query&nbsp;&nbsp;
                                <div className='query_icon' title={local_questionInfo?.query} onClick={() => {
                                    showPopup('SQL Query', local_questionInfo?.query, enums.popupType.sqlQuery, undefined, undefined)
                                }}>
                                    <ExclamationSvg color="#fff" size={'.6rem'} />
                                </div>
                            </div>

                        </div>
                    </QuestionInfoWrapper>
                </div>
            )}

            {data_source_type === 'RDBMS' && (
                <React.Fragment>

                    {innerStep < 1 && (
                        <div className='step_one_wrapper'>
                            <div className='center_icon'>
                                <img src={constants.CONTEXT_PATH + `./etl/engine.png`}></img>
                            </div>
                            <p className="title_up" >Hey, Pick Your Data Engineering Engine</p>
                            <p className='sub_title_e12'>You can change your selection any time</p>
                            <Row style={{ justifyContent: 'center' }}>
                                <Card active={db_interaction_type === "etl_natural_language"} onClick={() => {
                                    set_db_interaction_type("etl_natural_language")
                                }}>
                                    <div className='__icon'>
                                        {/* <NlpSvg size={'3.2rem'} />. */}
                                        <img src={constants.CONTEXT_PATH + `./etl/gen.png`}></img>

                                    </div>
                                    <div className='title'>GenAI</div>
                                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p> */}

                                </Card>
                                <Card active={db_interaction_type === "etl_config_query_builder"} onClick={() => {
                                    set_db_interaction_type('etl_config_query_builder')
                                    open_report_builder()
                                }}>
                                    <div className='__icon'>
                                        {/* <CodeSvg size={'2.8rem'} /> */}
                                        <img src={constants.CONTEXT_PATH + `./etl/no_cod.png`}></img>

                                    </div>
                                    <div className='title'>NO-CODE</div>
                                    {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p> */}
                                </Card>
                            </Row>
                        </div>
                    )}
                </React.Fragment>
            )}


            {innerStep === 1 && (
                <IncrementalWrapper active={((local_questionInfo && Object.keys(local_questionInfo)?.length > 0) || db_interaction_type !== "etl_natural_language")}>
                    <div style={{
                        width: '86%',
                        background: '#fff',
                        margin: 'auto',
                        padding: '1rem 2rem',
                        boxSizing: 'border-box',
                        borderRadius: '12px',
                        marginBottom: '1rem',
                        marginTop: '2rem',
                        boxShadow: '3px 4px 4px #cccccca3',
                        borderRight: '20px solid rgb(232, 232, 249)'

                    }}>
                        <div style={{ width: '50%' }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '.5rem' }} className='incremental_box'>
                                <Checkbox
                                    width="30px"
                                    height="30px"
                                    size="30px"
                                    style={{
                                        background: '#fff'
                                    }}
                                    checked={show_incremental} onClick={() => {
                                        set_show_incremental(!show_incremental)
                                    }} />

                                &nbsp;
                                <span className='lable___'>Incremental</span>
                            </div>
                            <h2 className='inner_title'>Need Incremental Data Movement?</h2>


                            {show_incremental && (
                                <div>
                                    <DivRow w="48%">
                                        <div style={{ boxShadow: '0px 0px 6px 1px #ccc', borderRadius: '6px' }}>

                                            <SelectOptionV1
                                                label={"Specify Incremental Column"}
                                                options={(date_column_selection || [])}
                                                multiple={true}
                                                checkbox={true}
                                                hide_border={true}
                                                none_value={false}
                                                inputStyle={{ borderRadius: '6px' }}
                                                value={extraction_state?.incremental_date_column || undefined}
                                                on_change={(v) => {
                                                    const clone_extraction_state = extraction_state ? { ...extraction_state } : {};
                                                    const clone_incremental_date_column = extraction_state?.incremental_date_column || [];
                                                    if (clone_incremental_date_column?.indexOf(v) == -1) {
                                                        clone_incremental_date_column.push(v)
                                                    } else {
                                                        clone_incremental_date_column.splice(clone_incremental_date_column.indexOf(v), 1)
                                                    }
                                                    clone_extraction_state.incremental_date_column = clone_incremental_date_column;
                                                    set_extraction_state(clone_extraction_state)
                                                }}
                                                hide_margin={true}
                                            />
                                        </div>
                                    </DivRow>
                                </div>
                            )}
                            {show_incremental && (
                                <div style={{ marginTop: '1.2rem' }} >
                                    <h2 className='inner_title'>Wanna Control Data Movement Volume by Giving Date Range?</h2>

                                    <div className="flex_row">
                                        <DivRow w="48%">
                                            <label className='job_form_label'>Start Date</label>
                                            <input
                                                type="datetime-local"
                                                className='job_form'
                                                style={{
                                                    background: '#E8E8F9'
                                                }}
                                                value={extraction_state && extraction_state.incremental_start_date ? convertDateInToHtmlDate(extraction_state.incremental_start_date) : undefined}
                                                onChange={(e) => {
                                                    on_change_v1(e.target.value, 'incremental_start_date')

                                                }}
                                            />
                                        </DivRow>

                                        <DivRow w="48%">
                                            <label className='job_form_label'>End Date</label>
                                            <input
                                                type="datetime-local"
                                                className='job_form' t
                                                style={{
                                                    background: '#E8E8F9'
                                                }}
                                                value={extraction_state?.incremental_end_date ? convertDateInToHtmlDate(extraction_state.incremental_end_date, 'incremental_end_date') : undefined}
                                                onChange={(e) => {
                                                    // onChangeFunction(e.target.value, 'next_run_at')
                                                    on_change_v1(e.target.value, 'incremental_end_date')

                                                }}

                                            />

                                        </DivRow>

                                    </div>
                                    <div className='flex_row'>
                                        <DivRow w="70%">
                                            <DivRow w="100%">
                                                <label className='job_form_label'>Frequency</label>
                                            </DivRow>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row' }}>
                                                <span style={{ padding: '.7rem 1rem', fontWeight: '500', backgroundColor: '#fff', fontSize: '.8rem', borderRadius: '7px', color: '#666666', boxShadow: '#cccccc 0px 0px 6px 1px', width: '100px' }}>Every</span>
                                                <div style={{ width: '25%' }}>
                                                    <input
                                                        className='job_form'
                                                        value={extraction_state?.incremental_frequency_value ? extraction_state.incremental_frequency_value : undefined}
                                                        type="text" placeholder="Value"
                                                        onChange={(e) => {
                                                            on_change_v1(e.target.value, 'incremental_frequency_value')

                                                        }}
                                                    />
                                                </div>

                                                <div style={{ width: '40%', }}>
                                                    <select
                                                        className='job_form'
                                                        value={extraction_state?.incremental_frequency_type ? extraction_state.incremental_frequency_type : undefined}
                                                        style={{ width: '100%', height: '2.3rem', backgroundColor: '#fff' }}
                                                        onChange={(e) => {
                                                            on_change_v1(e.target.value, 'incremental_frequency_type')
                                                        }}>
                                                        <option value="">--Select Type--</option>
                                                        {frequencyTypes?.map((item) => {
                                                            return (
                                                                <option value={item.value}>{item.name}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </DivRow>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>



                </IncrementalWrapper>
            )}


            {data_source_type === 'directory' && (
                <CardBox style={{ width: '100%' }} bg={'#851B6A'}>
                    <h2>Post Processing</h2>
                    <div className="card_row">
                        <div style={{ width: '40%', position: 'relative' }}>
                            <TextBox
                                type="text"
                                label="Directory where Processed Files will be Moved"
                                value={extraction_state && extraction_state['processed_directory'] ? extraction_state['processed_directory'] : undefined}
                                onChange={(e) => {
                                    on_change_v1(e.target.value, 'processed_directory')
                                }}
                            />
                            {extraction_state && extraction_state['processed_directory'] && extraction_state['processed_directory'].length > 5 && (
                                <div style={{ position: 'absolute', right: '0px', top: '10px' }}>
                                    <RippleEffect
                                        icon={<ViewSvg size=".9rem" height=".9rem" color="#222" />}
                                        title="View"
                                        Click={() => {
                                            props.viewDirectoryFileAction(extraction_state['processed_directory'])
                                            showPopup(undefined, undefined, enums.default.popupType.element, ViewFileInfo, {
                                                headerTitle: 'Your File Information',
                                                file_path: extraction_state['processed_directory']
                                            })
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="or_style">OR</div>
                        <div style={{ width: '40%', }}>
                            <TextBox
                                type="text"
                                label="Suffix to Rename Processed Files"
                                value={extraction_state && extraction_state['rename_prefix'] ? extraction_state['rename_prefix'] : undefined}
                                onChange={(e) => {
                                    on_change_v1(e.target.value, 'rename_prefix')
                                }}
                            />
                        </div>
                    </div>
                </CardBox>
            )}
        </Wrapper>
    )
}


const mapStateToProps = state => ({
    collectionsOfMongo: state.EtlReducer.collectionsOfMongo,
    extraction: state.dataWrangler.extraction,
    db_infos: state.connectionReducer.db_infos,
    questionInfo: state.EtlReducer.questionInfo,
})



export default connect(mapStateToProps, {
    clearQuestionInfo,
    getHintsByDbInfo,
    getTargetTableData,
    getCollectionsOfMongo,
    viewDirectoryFileAction,
    getAllInfoOfQuestion
})(Index)
