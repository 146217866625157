import { dispatchAction } from './helper';
import * as actionTypes from '../action-types/window';

export const windowIsClicked = () => async dispatch => {
    dispatchAction(dispatch, actionTypes.WINDOW_IS_CLICKED, undefined, undefined, undefined, 0);
};

export const windowWidthChange = width => async dispatch => {
    dispatchAction(dispatch, actionTypes.WINDOW_WIDTH_CHANGE, width, undefined, undefined, 0);
};
export const windowHeightChange = height => async dispatch => {
    dispatchAction(dispatch, actionTypes.WINDOW_HEIGHT_CHANGE, height, undefined, undefined, 0);
};
export const windowIsScrolled= () => async dispatch => {
    dispatchAction(dispatch, actionTypes.WINDOW_IS_SCROLLED, undefined, undefined, undefined, 0);
};
