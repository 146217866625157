import styled from 'styled-components'



export const SelectSet = styled.div`

 .add1 {
   float: right;
   width: 60px;
   height: 32px;
   background-color: #0679fb;
   color: white;
   border: none;
   font-size: 1rem;
   font-weight: 800;
   text-align: center;
   margin-bottom: 5px;
   box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

}
button{
    cursor: pointer;
}

.action{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

 .btn1 {
    border: none;
    /* margin-left: 1.25rem; */
    /* padding-top: 4px; */
    background-color: #0679fb;
    text-align: center;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    height: 1.8rem;
    width: 1.8rem;
  }
  .btn2 {
    border: none;
    margin-left: 1.25rem;
    /* padding-top: 4px; */
    background-color: #dc3d45;
    text-align: center;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    height: 1.8rem;
    width: 1.8rem;
  }

 

  .table1 {
    width: 100%;
    border: 1px solid #ffffff;
    border-collapse: collapse;
 }

 .thead1 {
   background-color: #f9f9f9;
   border-bottom: 1px solid black;
 }

 .tr1 {
    font-size: 1rem;
    font-weight: 800;
    font-style: normal;
 }

 

 th, td {
    border: 1px solid #eaeaea;
    padding: 10px;
    text-align: left;
 }
 th {
    border-bottom: 1px solid black;
 }

 .tr4 {
   border-bottom: 1px solid black;
 }

 
 

  
`;

export const WrapperPopup = styled.div`
    display: block;
    position: relative;
    width: 35rem;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 6px 0px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(234, 234, 234);
    border-image: initial;
    padding: 1.643rem 1.429rem;
    overflow-y: auto;
    max-height: 80vh;

    &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }
    .title{
        font-size: 1rem;
        margin-bottom: 5px;
    }


    .back{
        svg{
            transform: rotate(180deg);
        }
        &:hover{
            svg{
                fill: rgb(0,138,199);
            }
        }
    }


    .dismis{
        position: absolute;
        right: 1rem;
        top: 1rem;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 4px;
        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        cursor: pointer;

        &:hover{
            background: #356d941c;
            svg{
                fill: rgb(0,138,199);
            }
        }
    }

    h3{
        font-size: 1.286rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.714rem;
        letter-spacing: normal;
        text-align: left;
        margin:0px;
        display:block;
        padding-bottom: 18px;
        position: relative;
/* 
        &::before{
            position: absolute;
            bottom: 0px;
            border: 0.5px solid #bbd1d9;
            width: 101%;
            left: -5px;
            content: '';
        } */
    }

    table{
        width: 100%;
        border: 1px solid #ccc;
        border-collapse: collapse;
    }
    td,th{
        padding: 5px;
        border: 1px solid #cccccc59;
        height: 2.1rem;

    }
    thead tr th{
        background-color: #eaeaea
    }


    .alert{
        background-color: red;
        display: block;
        color: #fff;
        padding: 0;
        border-radius: 5px;
        width: 18px;
        margin: auto;
        padding: 0px 5px;
    }

    .sel1 {
      background-color: white;
      font-weight: 500;
    }

`;