import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Tooltip from '../tooltip-hover';
import { themes } from '../../theme/theme.config';
import { getSelectedTheme } from '../../utils';

const selected = getSelectedTheme();
const { rippleButton } = themes[selected];

const Wrapper = styled.div`
    display: inline-block;
    position: relative;

    .button_main {
        width: ${props => (props.width + "px") || '40px'};
        height: ${props => (props.width + "px") || '40px'};
        border-radius: 50%;
        background: transparent;
        cursor: pointer;
        border: none;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: ${props => props.zIndex || 'auto'};
        transition: 'background 0.3s';

        &:hover {
            background: rgb(226, 226, 232);
            svg {
                fill: ${rippleButton.color.iconHover};
            }
            .tooltip {
                visibility: visible;
            }
        }

        &:focus {
            outline: none;
        }
    }

    .ripple {
        border-radius: 50%;
        background: #bdbdbd;
        position: absolute;
        top: 50%;
        left: 50%;
        //width: ${props => props.rippleSize || '0'}px;
        //height: ${props => props.rippleSize || '0'}px;
        transform: translate(-50%, -50%);
        transition: width 0.3s, height 0.3s, opacity 0.3s;
        opacity: ${props => props.rippleVisible ? 1 : 0};
        pointer-events: none;
    }
`;

const RippleEffect = (props) => {
    const { position, width, zIndex, Click, icon, tooltip } = props;
    const [rippleSize, setRippleSize] = useState(0);
    const [rippleVisible, setRippleVisible] = useState(false);
    const isMounted = useRef(true); // Track if the component is mounted

    useEffect(() => {
        // Cleanup function to set the flag when unmounting
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleMouseDown = (event) => {
        event.stopPropagation();
        event.preventDefault();

        const button = event.currentTarget;
        const rect = button.getBoundingClientRect();
        const size = Math.max(rect.width, rect.height);

        setRippleSize(size);
        setRippleVisible(true);

        if (Click) {
            Click(event, { clientX: event.clientX, clientY: event.clientY });
        }

        // Use requestAnimationFrame to schedule hiding the ripple effect
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                if (isMounted.current) {
                    setRippleVisible(false);
                }
            });
        });
    };

    return (
        <Wrapper width={width} zIndex={zIndex} rippleSize={rippleSize} rippleVisible={rippleVisible}>
            <button
                className="button_main"
                onMouseDown={handleMouseDown}
            >
                <div className="content">
                    {icon}
                </div>
                <span className="ripple" />
                {tooltip && <Tooltip position={position} className="tooltip" title={tooltip} />}
            </button>
        </Wrapper>

    );
};

export default RippleEffect;
