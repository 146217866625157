import React, { useState, useEffect } from "react";
import styled, { keyframes } from 'styled-components';

const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const Cursor = styled.span`
  font-weight: bold;
  font-size: ${props => props.cursorSize || '30px'};
  margin-left: 2px;
  animation: ${blink} 0.75s infinite;
  color: black; 
    display: inline-block;
`;

const TypingEffect = ({ htmlContent, scrollToBottom = () => { }, typingSpeed = 5, cursorSize, cursorIcon, setIsDatastoryTyping }) => {

    const [isTyping, setIsTyping] = useState(false);
    const [typedContent, setTypedContent] = useState("");

    const [local_htmlContent, set_htmlContent] = useState('')


    useEffect(() => {

        set_htmlContent(htmlContent)
        setTypedContent('')

    }, [htmlContent, typingSpeed]);


    useEffect(() => {
        if(!htmlContent)return;
        let charIndex = 0;
        let typingTimer;
        setIsTyping(true);
        setIsDatastoryTyping && setIsDatastoryTyping(true)
        setTypedContent('')

        const typeNextCharacter = () => {
            if (charIndex < local_htmlContent?.length) {
                let nextChar = local_htmlContent[charIndex];

                // Check for complete HTML tags using regex to ensure tags aren't split
                const htmlTagRegex = /(<[^>]+>)/g;
                const remainingContent = local_htmlContent.slice(charIndex);
                const tagMatch = remainingContent.match(htmlTagRegex);

                if (tagMatch && remainingContent.startsWith(tagMatch[0])) {
                    // If we detect a full HTML tag, append the entire tag at once
                    setTypedContent((prev) => prev + tagMatch[0]);
                    charIndex += tagMatch[0].length;
                } else {
                    // Otherwise, append the next character
                    setTypedContent((prev) => prev + nextChar);
                    charIndex++;
                }

                typingTimer = setTimeout(typeNextCharacter, typingSpeed);
                if (scrollToBottom) {
                    scrollToBottom();
                }
            } else {
                setIsTyping(false);
                setIsDatastoryTyping && setIsDatastoryTyping(false)
            }
        };

        typeNextCharacter();

        if (scrollToBottom) {
            scrollToBottom();
        }

        return () => {
            setIsTyping(false);
            clearTimeout(typingTimer);
            if (scrollToBottom) {
                scrollToBottom();
            }
        };
    }, [local_htmlContent, typingSpeed]);

    console.log("typedContent======", typedContent)
    return (
        <div>
            <span style={{ display: 'inline-block' }} dangerouslySetInnerHTML={{ __html: typedContent }} />
            {isTyping && <Cursor cursorSize={cursorSize}>{cursorIcon || '•'}</Cursor>}
        </div>
    );
};

export default TypingEffect;
