import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { find_command_type, showPopup } from '../../../utils';
import { interpretCommandAction } from '../../../actions/helper.v2';
import { saveInnerChat, dispatchActionCommand, toggleIsWaiting } from '../../../actions/polus_chat';
import { MessageContainer, MessageWrapper, SentMessageWrapper } from './chat.styled';
import PolusLogoHead from '../../svg/icons-v2/polusLogoHead';
// import SearchTextAreaBar from '../../hoc/searchTextArea';
import { constants } from '../../../utils/constants';
import SearchTextAreaBar from '../../hoc/searchTextAreaWithDynamicHeight';
import ProgressBar from '../../hoc/progressBar';
import enums from '../../../utils/enums';
import VoiceSearchWrapper from '../../voice-search-wrapper';
import { generateFileMetaHTML } from '../utils';

// Styled Components
const ChatWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 380px;
    min-width: 380px;
    height: 100%;
    overflow: hidden;
    background: linear-gradient(to right, #f3faff, #d4e2ff);
    /* border-left: 1px solid #cacaca; */
    box-shadow: rgb(0 0 0 / 10%) 0px 3px 8px;

    box-sizing: border-box;
    .chat_header{
      background: #fff;
      border-radius: 6px;
      margin:auto;
      margin-top: 2rem;
      padding: 0.6rem 1rem
    }
`;

const ChatBody = styled.div`
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    scrollbar-width: thin;
    scrollbar-color: #ddd #fff;
    
    &::-webkit-scrollbar {
        width: 8px;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #ddd;
        border-radius: 5px;
    }
`;

const ChatInputWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    padding: 0px 0px 6px 0px;
`;

const ChatMessage = styled.div`
    background-color: ${(props) => (props.isUser ? '#daf8e3' : '#fff')};
    max-width: 300px;
    align-self: ${(props) => (props.isUser ? 'flex-end' : 'flex-start')};
    color: #333;
    font-size: 14px;
    /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
    word-wrap: break-word;
`;

const ChatWindow = (props) => {
  const { id, innerMessage, saveInnerChat, interpretCommandAction, dispatchActionCommand, isWaiting } = props;

  const chatBodyRef = useRef(null);
  const fileInputRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const [localinnerMessage, setinnerMessage] = useState([]);

  useEffect(() => {
    setinnerMessage(innerMessage || []);
  }, [innerMessage]);

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [localinnerMessage]);

  const handleSendMessage = (text) => {
    const question = (text || inputValue).trim();
    if (question) {
      props.toggleIsWaiting(true);
      saveInnerChat(id, { text: question, isUser: true });

      const commandInfo = find_command_type(question);
      if (commandInfo?.type) {
        props.toggleIsWaiting(false);
        dispatchActionCommand(id, commandInfo);
        saveInnerChat(id, { text: "Done." });
      } else {
        interpretCommandAction(question, id, id);
      }

      setInputValue('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault()
      handleSendMessage();
    }
  };

  const voiceAction = () => {

    showPopup('', undefined, enums.popupType.element, VoiceSearchWrapper, {
      save_fun: (text) => {
        // call the get data function here to fetch the data from the server 
        handleSendMessage(text);
      }
    },
      undefined,
      undefined,
      undefined, {
    },
    )
  };

  const handleAddAttachmentClick = (key) => {
    switch (key) {
      case 'Recent File':

        break;
      case 'Google Drive':

        break;
      case 'Upload from Computer':
        if (fileInputRef.current) {
          fileInputRef.current.click(); // Trigger the file input click automatically
        }
        break;

      default:
        break;
    }
  };

  // const onFileChange = (e) => {
  //   const files = e.target.files;
  //   const { id, combind_id } = otherActionData || {};
  //   const localFiles = []

  //   if (files.length < 21) {
  //     for (var i = 0; i < files.length; i++) {
  //       localFiles.push(files[i]);
  //     }
  //   }

  //   showPopup(undefined, undefined, enums.popupType.element, SelectFilePopup, {
  //     setActiveTab: () => {

  //     },
  //     tabList: ['test'],
  //     activeFolder: 'test',
  //     uploadedFileList: localFiles,
  //     setUploadedFileList: () => {

  //     },
  //     onCompleted: (data) => {
  //       if (data?.data) {
  //         const formatted_data = generateFileMetaHTML(data?.data)
  //         setinnerMessage(prev =>
  //           updateOrAddResponse(prev, id, formatted_data, combind_id)
  //         );

  //       } else {
  //         setMainResponseHolder(prev =>
  //           updateOrAddResponse(prev, id, 'something went wrong', combind_id)
  //         );
  //       }

  //       setOtherActionData();
  //       setAction(undefined)
  //       setIsInitialPageInput(false)

  //     },

  //     onError: (error) => {

  //       console.log("file upload error...", error)
  //       setMainResponseHolder(prev =>
  //         updateOrAddResponse(prev, id, error, combind_id)
  //       );

  //       setOtherActionData();
  //       setAction(undefined)
  //       setIsInitialPageInput(false)


  //     },
  //     onclose: () => {
  //       setMainResponseHolder(prev =>
  //         updateOrAddResponse(prev, id, 'The process has been terminated.', combind_id)
  //       );
  //       setIsInitialPageInput(false)

  //     }
  //   }, undefined, undefined, "#ccc", undefined, true);

  //   // Reset the file input to allow the same file to be selected again
  //   e.target.value = null;
  //   // Handle the uploaded file here
  // };

  console.log("localinnerMessage", localinnerMessage);
  
  return (
    <ChatWrapper>
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
        // onChange={onFileChange} 
      />
      {!localinnerMessage?.length && <div className='chat_header'>Hey! Deep dive your KPIs with VADY</div>}

      <ChatBody ref={chatBodyRef}>
        <div className='body_sub_wrapper'>
          {/* {localinnerMessage?.length} */}
          {localinnerMessage.map((msg, index) => (
            msg.isUser ? (
              <div key={index} style={{ marginRight: '0.6rem', display: 'flex', justifyContent: 'flex-end', width: '94%', marginBottom: '0.6rem' }}>
                <SentMessageWrapper tailDimensions={{right: '-6px', border: '8px'}} padding={'8px 12px'} width={'80%'}>
                  {msg.text}
                </SentMessageWrapper>
              </div>
            ) : (
              <MessageContainer key={index}>
                <div className='msg_bg'>
                  <MessageWrapper iconbgSize={'1.1rem'} padding={'0.4rem'} marginRightIcon={'0.6rem'} iconBg={'#fff'} style={{ justifyContent: 'flex-start', width: '100%' }}>
                    <div className="polus_icon_bg1">
                      <span>
                        <PolusLogoHead height="1.2rem" size="1.2rem" />
                      </span>
                    </div>
                    <ChatMessage isUser={msg.isUser}>
                      {msg.text}
                    </ChatMessage>
                  </MessageWrapper>
                </div>
              </MessageContainer>
            )
          ))}

          {isWaiting && (
          // {true && (
            <MessageContainer>
              <div className='msg_bg'>
                <MessageWrapper iconbgSize={'1.8rem'} padding={'0.4rem'} marginRightIcon={'0.6rem'} iconBg={'#fff'} style={{ justifyContent: 'flex-start', width: '100%' }}>
                  <div className="polus_icon_bg1">
                    <span>
                      <PolusLogoHead height="1.2rem" size="1.2rem" />
                    </span>
                  </div>
                  <ChatMessage width={'100%'}>
                    <span style={{ marginLeft: '0.2rem' }}>VADY is on it...</span>
                    <ProgressBar loading={true}  width={'280px'} />
                    {/* <img height='20px' alt='loading information' src={constants.CONTEXT_PATH + './loader.gif'} /> */}
                  </ChatMessage>
                </MessageWrapper>
              </div>
            </MessageContainer>
          )}
        </div>
      </ChatBody>

      <ChatInputWrapper>
        <SearchTextAreaBar
          rows={4}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          onSearch={handleSendMessage}
          placeHolder="Type and ask any data question here"
          type="text"
          width='19rem'
          padding={'0.2rem 0.5rem 0.2rem 1rem'}
          showSearchIcon={false}
          showSendBtn={true}
          showSpeakBtn={true}
          showAddAttachment={true}
          handleMicClick={voiceAction}
          handleAddAttachmentClick={handleAddAttachmentClick}
          borderType={'border-radius'}
        />
      </ChatInputWrapper>
    </ChatWrapper>
  );
};

const mapStateToProps = (state, props) => {
  const innerMessageCache = state.polusChatReducer.innerMessageCache;
  const isWaiting = state?.polusChatReducer?.isWaiting;
  return { innerMessage: innerMessageCache?.[props.id] || [], isWaiting };
};

export default connect(mapStateToProps, { toggleIsWaiting, interpretCommandAction, saveInnerChat, dispatchActionCommand })(ChatWindow);
