import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import { DivRow } from '../styled-hoc';
import AutoComplete from '../auto-complete';
import { find_hint_in_array } from '../../utils';
import { showPopup } from '../../utils';
import * as enums from '../../utils/enums';
import SelectDisplayField from '../select-display-field';
import { Wrapper } from './drag.styled'
// import IconButton from '../hoc/iconButton';
import RippleEffect from '../hoc/ripple-effect'
import EditSvg from '../svg/edit'
import CloseSvg from '../svg/close'


const DragDrop = props => {


    const {
        inputLabel,
        inputLabelEx,
        selectedFieldsLabel,
        selectedFields,
        setSelectedFields,
        inputField,
        setInputField,
        addFunction,
        onDropIndexName,
        // removeFunction,
        maxHeightOfSelectedField,
        showError,
        errorMessage,
        setShowError,
        autoSuggestArray,
        filteredDisplayItems,
        titleOnHover,
        titleHeading,
        remove_pirtual_element_from_array
    } = props;

    const [removedItemIndex, setRemovedItemIndex] = useState(undefined);
    const [suggestion, setSuggestion] = useState(undefined);
    const [lowerSuggestion, setLowerSuggetion] = useState(undefined);

    useEffect(() => {
        if (autoSuggestArray && autoSuggestArray.length > 0) {
            const tempArray = JSON.parse(JSON.stringify(autoSuggestArray));

            const result = [];

            autoSuggestArray.indexOf('display')

            tempArray.forEach(data => {
                if (data) {
                    if (typeof data === 'string') {
                        result.push(data.toLowerCase().trim());
                    }
                    // else {
                    //     result.push(data.trim());
                    // }
                }
            })

            setLowerSuggetion(result);
        }
    }, [autoSuggestArray]);



    const insert = (arr, index, newItem) => [
        ...arr.slice(0, index),

        newItem,

        ...arr.slice(index)
    ];


    const onDrop = event => {
        const index = event.dataTransfer.getData(onDropIndexName);
        let currentIndex = undefined;
        let tempFilteredItems = [...selectedFields]

        const value = event.currentTarget.textContent;

        for (let i = 0; i < tempFilteredItems.length; i++) {
            if ((tempFilteredItems[i] && typeof tempFilteredItems[i] === 'string' ? tempFilteredItems[i].toLowerCase().trim() : tempFilteredItems[i]) === (value && typeof value === 'string' ? value.toLowerCase().trim() : value)
            ) {
                currentIndex = i;
                break;
            }
        }

        const deletedItem = _.pullAt(tempFilteredItems, index);

        tempFilteredItems = insert(tempFilteredItems, currentIndex, deletedItem[0]);

        setSelectedFields(tempFilteredItems);
    };

    const _addFunction = value => {

        if (!removedItemIndex && removedItemIndex !== 0) {
            addFunction(value);
        }
        else {
            const newArray = insert(selectedFields, removedItemIndex, value);

            setSelectedFields(newArray);
            setRemovedItemIndex(undefined);
            setInputField(undefined);
        }

        setSuggestion(undefined);
    };


    const checkIfValid = value => {
        const tempSuggestionArray = JSON.parse(JSON.stringify(lowerSuggestion));
        const _value = value;

        const allowed_items = ['sum of ', 'min of ', 'max of ', 'average of ', 'count of ', 'sum ', 'total ', 'previous ', 'upcoming ', 'last ', 'current ', 'min ', 'max ', 'average ', 'count '];

        let tempValue = _value;
        let removedItem = undefined;


        allowed_items.forEach((allowed_item, index) => {
            if (tempValue.indexOf(allowed_item) === 0) {
                removedItem = allowed_items[index];
                const regexString = `\\b${allowed_item}\\b`;
                const regex = new RegExp(regexString);
                tempValue = tempValue.replace(regex, '').trim();
            }
        });

        const tt = tempValue && tempValue.length > 0 && typeof tempValue === 'string' ? tempValue.toLowerCase().trim() : tempValue.trim()

        if (tempSuggestionArray.indexOf(tt) > -1 && suggestion && suggestion.length > 0) {
            const valueInSuggestion = suggestion.indexOf(value);

            if (valueInSuggestion > -1) {
                _addFunction(suggestion[valueInSuggestion]);
            }
            else {
                _addFunction(suggestion[0])
            }

            if (valueInSuggestion > -1) {
                _addFunction(suggestion[valueInSuggestion]);
            }
            else {
                _addFunction(suggestion[0])
            }
        }
        else if (tempSuggestionArray.indexOf(tt) > -1 && suggestion && suggestion.length === 0) {
            const valueToAdd = removedItem ? removedItem.trim() + ' ' + tempValue : tempValue;

            _addFunction(valueToAdd);
        }
        else {
            setSuggestion(undefined);
            setShowError(true);
        }

    };


    return (
        <Wrapper>
            {props.showFilter && (
                <DivRow
                    style={{
                        position: 'relative'
                    }}
                >
                    <label>
                        {inputLabel}
                        <span style={{ fontSize: '1.286rem', color: '#949494' }}>
                            &nbsp;&nbsp;{inputLabelEx}
                        </span>
                    </label>

                    <input type='text'
                        value={inputField ? inputField : ''}
                        onChange={(event) => {
                            setSuggestion(undefined);

                            const value = event.target.value;

                            setInputField(event.target.value);
                            setShowError(false);

                            if (autoSuggestArray && value.length > 0) {
                                const newSuggestions = find_hint_in_array(value.trim(), undefined, autoSuggestArray);

                                setSuggestion(newSuggestions);
                            }
                        }}
                        onKeyDown={(event) => {
                            let value = event.target.value;

                            value = value.trim();

                            if (event.key === 'Enter' && value && value.length > 0) {
                                if (autoSuggestArray && autoSuggestArray.length > 0) {
                                    checkIfValid(value);
                                }
                                else {
                                    _addFunction(value)
                                }
                            }
                        }}
                        style={{
                            outline: 'none',
                            border: showError ? '1.4px solid #F44336' : '',
                            borderRadius: '5px'
                        }}
                        disabled={autoSuggestArray && autoSuggestArray.length === 0}
                    />
                    {
                        showError &&
                        <span
                            style={{
                                color: '#F44336'
                            }}
                        >
                            <i className="fas fa-exclamation-triangle"
                                style={{
                                    margin: '0 5px',
                                    fontSize: '0.8rem'
                                }}
                            />
                            <span
                                style={{
                                    fontSize: '0.8rem'
                                }}
                            >{errorMessage}</span>
                        </span>
                    }
                    {
                        autoSuggestArray && suggestion && suggestion.length > 0 &&
                        <AutoComplete words={suggestion} setWord={_addFunction} />
                    }
                </DivRow>

            )}

            <DivRow style={{ marginBottom: '0px' }}>
                <div className="one_line_items">
                    <label style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', }}>
                        <div className="top_label" style={{
                            marginRight: '10px',
                            fontSize: '1rem',
                            color: 'rgba(0, 0, 0, 0.54)',
                            textTransform: 'capitalize'
                        }}>{selectedFieldsLabel}</div>

                        {selectedFields && selectedFields.length > 0 && (
                            <RippleEffect
                                icon={<EditSvg size=".9rem" height=".9rem" color="#222" />}
                                title="edit"
                                Click={() => {
                                    showPopup(undefined, undefined, enums.default.popupType.element, SelectDisplayField, {
                                        menus: props.menus,
                                        displayItem: filteredDisplayItems,
                                        selectedFields: selectedFields,
                                        title: "Select Fields to display",
                                        setSelectedFields: setSelectedFields,
                                        titleHeading,
                                        titleOnHover
                                    })
                                }
                                }
                            />
                        )}
                    </label>


                    {(!selectedFields || (selectedFields && selectedFields.length === 0)) && (
                        <div className="add_btn_new_field"
                            onClick={() => {
                                if (filteredDisplayItems && filteredDisplayItems.length > 0 && titleOnHover) {
                                    showPopup(undefined, undefined, enums.default.popupType.element, SelectDisplayField, {
                                        menus: props.menus,
                                        displayItem: filteredDisplayItems,
                                        selectedFields: selectedFields,
                                        title: "Select Fields to display",
                                        setSelectedFields: setSelectedFields,
                                        titleHeading,
                                        titleOnHover
                                    })
                                }
                            }}
                        >+&nbsp;Add</div>
                    )}
                    <div className="batch_row"
                        onDragOver={(event) => event.preventDefault()}
                        style={{
                            maxHeight: (maxHeightOfSelectedField || '')
                        }}
                    >
                        {
                            selectedFields && selectedFields.length > 0 && selectedFields.map((item, index) => {
                                return <div
                                    className="batch"
                                    draggable
                                    key={'drp' + item + index}
                                    onDragStart={(event) => {
                                        event.dataTransfer.setData(onDropIndexName, index);
                                        event.dataTransfer.effectAllowed = "move"
                                    }}
                                    onDrop={(event) => onDrop(event)}
                                >
                                    <span title={item}>{item} </span>&nbsp;&nbsp;
                                    <div  title="remove" className="hover_element" onClick={(e) => {
                                        remove_pirtual_element_from_array('selected_filter_field', index, item);
                                    }}>
                                        <CloseSvg size=".6rem" height=".6rem" color="#222" />
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </DivRow>
        </Wrapper>
    );
};

export default DragDrop