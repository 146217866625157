import * as actions from '../action-types/voice.helper';

const initialState = {
    type: actions.VOICE_HELPER_INIT,
    polusCommand: {
        "COLOR_FORMATING_FORMULA": {
            column: 'Service',
            condition: 'equal to',
            value: 'IPD',
            color: 'red'
        },
    }
};


const interpretCommand = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    };


    switch (action.type) {
        case actions.RAISE_INTERPRET_COMMAND:
            return {
                type: action.type,
                command: action.data
            };

        case actions.CLEAR_INTERPRET_COMMAND:
            return {
                type: action.type,
                command: undefined
            };

        default:
            return { ...state };
    }
};

export default interpretCommand;