import React from "react";

const TrainYourPolus = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1300 868"
    >
      <path transform="translate(43,412)" d="m0 0h572l13 4 10 6 7 7 6 10 4 12v104l-4 14-7 10-4 5-10 7-13 4-11 1h-259l1 129 3 14 5 12 6 9 8 8 10 5 8 2 293 1v-44l4-9 6-9 7-7 11-6 11-3h537l13 3 10 6 7 6 7 11 3 8 1 7v83l-2 13-4 9-7 9-10 8-11 4-10 1h-534l-12-3-8-4-10-9-7-12-2-6v-16h-291l-17-2-14-5-11-6-13-11-9-11-8-14-5-12-5-19-1-10v-130h-259l-16-3-9-5-10-9-5-7-5-14-1-6v-92l2-15 6-12 9-10 10-7 9-3zm2 30-6 3-6 9-1 4v91l3 9 7 6 6 2h563l8-4 5-7 1-5v-94l-4-8-6-5-3-1zm678 286-8 4-4 6v88l3 5 4 4 3 1h534l7-3 4-5 2-6v-80l-3-7-5-5-5-2z" fill="#06325F" />
      <path transform="translate(40)" d="m0 0h563l16 1 12 5 9 7 7 8 7 14 1 5v103l-2 9-6 12-9 10-7 5-11 4-9 1h-568l-12-3-9-5-8-7-8-13-3-9-1-7v-90l2-16 8-16 8-8 10-6zm4 31-5 3-4 4-3 8v92l4 10 4 4 6 2h566l7-4 5-6 1-5v-95l-3-6-8-7z" fill="#05325F" />
      <path transform="translate(42,206)" d="m0 0h572l12 3 10 6 8 7 7 11 4 13v104l-5 15-7 10-8 7-9 5-12 3h-570l-10-2-12-6-10-10-6-10-4-16v-92l2-14 8-16 8-8 11-7zm4 30-6 3-6 7-2 7v91l3 8 8 7 3 1h565l5-2 5-4 3-5 1-6v-92l-3-7-4-5-7-3z" fill="#06335F" />
      <path transform="translate(716,514)" d="m0 0h545l10 3 10 6 7 7 6 9 3 9 1 6v84l-2 13-6 12-8 9-10 6-10 3-10 1h-532l-12-3-10-6-6-5-8-12-3-9v-99l5-12 8-10 8-6 10-5zm5 30-5 3-5 6v88l3 5 6 5 3 1h531l7-3 5-5 2-5v-83l-5-8-6-4z" fill="#05325E" />
      <path transform="translate(721,329)" d="m0 0h536l13 3 9 5 9 8 6 10 3 9 1 6v83l-2 14-6 11-9 10-10 6-6 2-7 1h-540l-13-4-10-7-7-8-6-12-1-3v-100l4-10 6-8 7-7 11-6 5-2zm1 30-8 5-3 5v88l4 6 6 4h535l7-4 4-6 1-4v-81l-4-8-5-4-4-1z" fill="#04315E" />
      <path transform="translate(76,77)" d="m0 0h296l8 3 4 5 2 5-1 7-4 7-7 3h-300l-5-3-4-6-1-7 3-8 5-4z" fill="#04315E" />
      <path transform="translate(74,489)" d="m0 0h300l8 4 4 8-1 8-4 6-4 3-8 1h-294l-6-4-3-4-2-9 3-7z" fill="#04315E" />
      <path transform="translate(75,283)" d="m0 0h298l7 3 5 6 1 9-5 8-3 3-6 1h-297l-5-3-4-5-1-2v-10l4-6z" fill="#04315E" />
      <path transform="translate(751,767)" d="m0 0h279l7 2 6 7 1 8-3 7-5 5-8 1h-279l-5-3-5-8v-7l4-7 5-4z" fill="#04315E" />
      <path transform="translate(750,398)" d="m0 0h281l7 3 4 5 2 6-1 7-7 8-9 1h-278l-5-3-4-6-1-3v-7l4-6 5-4z" fill="#073360" />
      <path transform="translate(749,583)" d="m0 0h283l7 3 4 6 1 8-3 6-5 5-10 2h-275l-6-3-5-6-1-9 3-6 5-5z" fill="#04315E" />
      <path transform="translate(534,58)" d="m0 0h16l10 4 8 7 7 14 1 12-2 10-5 9-4 5-9 6-6 2h-15l-10-4-8-7-6-10-2-7v-14l4-10 4-6 9-8z" fill="#04315E" />
      <path transform="translate(535,469)" d="m0 0h14l12 5 8 8 5 10 1 3v18l-4 9-6 8-8 6-10 3h-9l-10-3-6-4-7-7-5-10-1-4v-14l4-11 6-8 10-7z" fill="#D7DEE3" />
      <path transform="translate(536,263)" d="m0 0h10l13 4 10 9 5 10 2 10-1 11-4 10-9 10-10 5-5 1h-11l-11-4-8-7-5-8-3-9v-14l4-10 4-6 9-8z" fill="#D7DEE3" />
      <path transform="translate(1183,751)" d="m0 0h13l11 4 9 8 5 10 1 3v12l-4 10-6 8-7 5-11 3h-8l-10-3-8-6-6-8-3-8v-13l4-10 7-8 10-6z" fill="#D7DEE3" />
      <path transform="translate(1183,382)" d="m0 0h14l10 4 8 7 5 8 2 7v11l-3 9-7 9-8 5-12 3-10-1-9-4-8-7-5-10-1-3v-13l4-10 7-8 8-5z" fill="#D7DEE3" />
      <path transform="translate(1182,567)" d="m0 0h17l9 4 7 6 6 12 1 3v11l-4 11-9 10-9 4-4 1h-12l-10-4-8-7-5-8-2-6v-12l4-10 6-8 10-6z" fill="#04315E" />
    </svg>
  );
};

export default TrainYourPolus;
