require('dotenv').config();



export const constants = {
    USE_ENCRYPTION: true,
    ENCRYPTION_SALT: '108udhas7h367uehdyh1y36389jsjj^&^%$asdake049SSDJDLasdd2k129k4',
    LOGIN_DEV_KEY: 'iiqywebbiufr5@978fb2',
    DEFAULT_ROWS_LIST: 30,
    TOP_TEN_ROWS_LIST: 10,
    APP_NAME: 'nf-ui',
    NUMBER_OF_ROWS: 1000,
    LIMIT_OF_DATA_IN_REDUX_STATE: 50,
    TIME_LIMIT_OF_DATA_IN_REDUX_STATE: 60,
    TABLE_LIMIT: 100,
    HEADER_WORD_LIMIT: 3,

    CONTEXT_PATH: '',

    NO_IMAGE: 'no-image.jpeg',
    JOB_FREQUENCYS: [
        { name: 'Monthly', id: 'monthly' },
        { name: 'Daily', id: 'daily' },
        { name: 'Weekly', id: 'weekly' },
        { name: 'Hourly', id: 'hourly' },
        { name: 'Minute', id: 'minutes' },
    ],
    STYLED_COMPONENTS: {
        ALL: 'sc.all.1',
        BORDER: 'sc.brdr.1',
        MARGIN: 'sc.mrgn.1',
        HEIGHT_WIDTH: 'sc.htwd.1',
        BACKGROUND: 'sc.bcgrd.1',
        FONT: 'sc.fnt.1',
        SHADOW: 'sc.sha.1',
        PADDING: 'sc.pd.1'
    },
    COMPLEX_DATE_FORMATS: [
        "MM/DD/YY",
        "DD/MM/YY",
        "YY/MM/DD",
        "M/D/YY",
        "D/M/YY",
        "YY/M/D",
        "bM/bD/YY",
        "bD/bM/YY",
        "MMDDYY",
        "DDMMYY",
        "YYMMDD",
        "MonDDYY",
        "DDMonYY",
        "YYMonDD",
        "day/YY",
        "YY/day",
        "D Month, Yr",
        "Yr, Month D",
        "Mon-DD-YYYY",
        "DD-Mon-YYYY",
        "YYYY-Mon-DD",
        "Mon DD,YYYY",
        "DD Mon,YYYY",
        "YYYY, Mon DD",
    ],

    CONDITIONS: {
        LESS_THEN: { DISPLAY_KEY: 'Less Than <', VALUE_KEY: 'less than', DATA_TYPE: 'number' },
        GREATER_THAN: { DISPLAY_KEY: 'Greater Than >', VALUE_KEY: 'greater than', DATA_TYPE: 'number' },
        EQUAL_TO: { DISPLAY_KEY: 'Equal To =', VALUE_KEY: 'equal to', DATA_TYPE: 'all' },
        BETWEEN: { DISPLAY_KEY: 'Between', VALUE_KEY: 'between', DATA_TYPE: 'number' },
        LESS_THEN_OR_EQUAL_TO: { DISPLAY_KEY: '<=', VALUE_KEY: 'less than or equal to', DATA_TYPE: 'number' },
        GREATER_THAN_OR_EQUAL_TO: { DISPLAY_KEY: '>=', VALUE_KEY: 'greater than or equal to', DATA_TYPE: 'number' },
        CONTAINS: { DISPLAY_KEY: 'Contains', VALUE_KEY: 'contains', DATA_TYPE: 'string' },
        NOT_EQUAL: { DISPLAY_KEY: 'Not Equal !==', VALUE_KEY: "not_equal", DATA_TYPE: 'all' },
        DOES_NOT_CONTAIN: { DISPLAY_KEY: 'Does not contain', VALUE_KEY: 'does_not_contain', DATA_TYPE: 'string' },
        START_WITH: { DISPLAY_KEY: 'Start with', VALUE_KEY: 'start_with', DATA_TYPE: 'string' },
        END_WITH: { DISPLAY_KEY: 'End with', VALUE_KEY: 'end_with', DATA_TYPE: 'string' },
        ELSE: { DISPLAY_KEY: 'Else', VALUE_KEY: 'else', DATA_TYPE: 'string' },

    },
    CURRENCY_TYPES: {
        DOLLER: '$',
        RUPEE: '₹',
        EURO: '€'
    },
    DATE_FORMAT: {
        YEARS: 'years',
        MONTHS: 'months',
        WEEKS: 'weeks',
        HOURS: 'hours',
        MINUTES: 'minutes',
        SECONDS: 'seconds',

    },
    STYLE_COMPONENTS_ELEMENT_TYPES: {
        SVG: 'sc.svg.1',
        TEXT: 'sc.text.1',
    },
    STYLE_TYPE: {
        STRING: 'sc.string.1',
        OBJECT: 'sc.object.1',
    },

    END_POINTS: {

        API: process.env.REACT_APP_MODE === 'development' ?
            process.env.REACT_APP_DEMO_URL_DEV :
            (process.env.REACT_APP_MODE === 'staging' ?
                process.env.REACT_APP_DEMO_URL_STAGING :
                (process.env.REACT_APP_MODE === 'mlc' ?
                    process.env.REACT_APP_DEMO_URL_STAGING_MLC :
                    (process.env.REACT_APP_MODE === 'staging-dev' ?
                        process.env.REACT_APP_DEMO_URL_STAGING_DEV : (
                            process.env.REACT_APP_MODE === 'hsl' ?
                                process.env.REACT_APP_DEMO_URL_HSL : (
                                    process.env.REACT_APP_MODE === 'mlc-vdh' ?
                                        process.env.REACT_APP_DEMO_URL_MLC_VDH : (
                                            process.env.REACT_APP_MODE === 'hsl-vdh' ?
                                                process.env.REACT_APP_DEMO_URL_HSL_VDH : (
                                                    process.env.REACT_APP_MODE === 'prod-staging' ?
                                                        process.env.REACT_APP_DEMO_URL_PROD1_API :
                                                        process.env.REACT_APP_MODE === 'hrpl' ?
                                                            process.env.REACT_APP_DEMO_URL_HRPL :
                                                            process.env.REACT_APP_MODE === 'srpl' ?
                                                                process.env.REACT_APP_DEMO_URL_STAGING_SRPL :
                                                                process.env.REACT_APP_MODE === 'barc' ?
                                                                    process.env.REACT_APP_DEMO_URL_STAGING_BARC :
                                                                    process.env.REACT_APP_MODE === 'lnt' ?
                                                                        process.env.REACT_APP_DEMO_URL_STAGING_LNT :
                                                                        process.env.REACT_APP_MODE === 'cmr' ?
                                                                            process.env.REACT_APP_DEMO_URL_STAGING_CMR :
                                                                            process.env.REACT_APP_MODE === 'lg_cop' ?
                                                                                process.env.REACT_APP_DEMO_URL_STAGING_LG_COP :
                                                                                process.env.REACT_APP_MODE === 'godrej' ?
                                                                                    process.env.REACT_APP_DEMO_URL_STAGING_GODREJ :
                                                                                    process.env.REACT_APP_MODE === 'uniqus' ?
                                                                                        process.env.REACT_APP_DEMO_URL_STAGING_UNIQUS :
                                                                                        process.env.REACT_APP_MODE === 'mbl' ?
                                                                                            process.env.REACT_APP_DEMO_URL_STAGING_MBL :
                                                                                            process.env.REACT_APP_MODE === 'tata_cap' ?
                                                                                                process.env.REACT_APP_DEMO_URL_STAGING_TATA_CAP :
                                                                                                process.env.REACT_APP_DEMO_URL_PROD)
                                        )
                                )
                        )))),

        AUTH_API: process.env.REACT_APP_MODE === 'development' ?
            process.env.REACT_APP_DEMO_AUTH_URL_DEV :
            (process.env.REACT_APP_MODE === 'staging' ?
                process.env.REACT_APP_DEMO_AUTH_URL_STAGING :
                (process.env.REACT_APP_MODE === 'mlc' ? process.env.REACT_APP_DEMO_AUTH_URL_STAGING_MLC :
                    (process.env.REACT_APP_MODE === 'staging-dev' ?
                        process.env.REACT_APP_DEMO_AUTH_URL_STAGING :
                        (
                            process.env.REACT_APP_MODE === 'hsl' ?
                                process.env.REACT_APP_DEMO_AUTH_URL_HSL :
                                (
                                    process.env.REACT_APP_MODE === 'mlc-vdh' ?
                                        process.env.REACT_APP_DEMO_AUTH_URL_STAGING_MLC : (
                                            process.env.REACT_APP_MODE === 'hsl-vdh' ?
                                                process.env.REACT_APP_DEMO_AUTH_URL_HSL_VDH : (
                                                    process.env.REACT_APP_MODE === 'prod-staging' ?
                                                        process.env.REACT_APP_DEMO_AUTH_URL_PROD :
                                                        process.env.REACT_APP_MODE === 'hrpl' ?
                                                            process.env.REACT_APP_DEMO_AUTH_URL_HRPL :
                                                            process.env.REACT_APP_MODE === 'srpl' ?
                                                                process.env.REACT_APP_DEMO_AUTH_URL_STAGING_SRPL :
                                                                process.env.REACT_APP_MODE === 'barc' ?
                                                                    process.env.REACT_APP_DEMO_AUTH_URL_STAGING_BARC :
                                                                    process.env.REACT_APP_MODE === 'lnt' ?
                                                                        process.env.REACT_APP_DEMO_AUTH_URL_STAGING_LNT :
                                                                        process.env.REACT_APP_MODE === 'cmr' ?
                                                                            process.env.REACT_APP_DEMO_AUTH_URL_STAGING_CMR :
                                                                            process.env.REACT_APP_MODE === 'lg_cop' ?
                                                                                process.env.REACT_APP_DEMO_AUTH_URL_STAGING_LG_COP :
                                                                                process.env.REACT_APP_MODE === 'godrej' ?
                                                                                    process.env.REACT_APP_DEMO_AUTH_URL_STAGING_GODREJ :
                                                                                    process.env.REACT_APP_MODE === 'uniqus' ?
                                                                                        process.env.REACT_APP_DEMO_AUTH_URL_STAGING_UNIQUS :
                                                                                        process.env.REACT_APP_MODE === 'mbl' ?
                                                                                            process.env.REACT_APP_DEMO_AUTH_URL_STAGING_MBL :
                                                                                            process.env.REACT_APP_MODE === 'tata_cap' ?
                                                                                                process.env.REACT_APP_DEMO_AUTH_URL_STAGING_TATA_CAP :
                                                                                                process.env.REACT_APP_DEMO_AUTH_URL_PROD
                                                )

                                        )
                                )
                        )))
            ),


        // DATA_WRANGLER_API: process.env.REACT_APP_MODE === "staging" ?
        //     process.env.REACT_APP_DATA_WRANGLER_URL_STAGING
        //     :
        //     process.env.REACT_APP_DATA_WRANGLER_URL_DEV,

        DATA_WRANGLER_API: process.env.REACT_APP_MODE === "development" ?
            process.env.REACT_APP_DATA_WRANGLER_URL_DEV
            :
            (
                process.env.REACT_APP_MODE === "staging" ?
                    process.env.REACT_APP_DATA_WRANGLER_URL_STAGING :
                    (process.env.REACT_APP_MODE === "hsl" ?
                        process.env.REACT_APP_DATA_WRANGLER_URL_HSL :
                        process.env.REACT_APP_MODE === "hrpl" ?
                            process.env.REACT_APP_DATA_WRANGLER_URL_HRPL :
                            process.env.REACT_APP_MODE === "lnt" ?
                                process.env.REACT_APP_DATA_WRANGLER_URL_LNT :
                                process.env.REACT_APP_MODE === "barc" ?
                                    process.env.REACT_APP_DATA_WRANGLER_URL_BARC :
                                    process.env.REACT_APP_MODE === "srpl" ?
                                        process.env.REACT_APP_DATA_WRANGLER_URL_SRPL :
                                        process.env.REACT_APP_MODE === "cmr" ?
                                            process.env.REACT_APP_DATA_WRANGLER_URL_CMR :
                                            process.env.REACT_APP_MODE === "lg_cop" ?
                                                process.env.REACT_APP_DATA_WRANGLER_URL_LG_COP :
                                                process.env.REACT_APP_MODE === "godrej" ?
                                                    process.env.REACT_APP_DATA_WRANGLER_URL_GODREJ :
                                                    process.env.REACT_APP_MODE === "uniqus" ?
                                                        process.env.REACT_APP_DATA_WRANGLER_URL_UNIQUS :
                                                        process.env.REACT_APP_MODE === "mbl" ?
                                                            process.env.REACT_APP_DATA_WRANGLER_URL_MBL :
                                                            process.env.REACT_APP_MODE === "tata_cap" ?
                                                                process.env.REACT_APP_DATA_WRANGLER_URL_TATA_CAP :
                                                                process.env.REACT_APP_DATA_WRANGLER_URL_PROD)
            ),


        JOB_API: process.env.REACT_APP_MODE === "development" ?
            process.env.REACT_APP_JOB_URL_DEV
            :
            (
                process.env.REACT_APP_MODE === "staging" ?
                    process.env.REACT_APP_JOB_URL_STAGING :
                    (process.env.REACT_APP_MODE === "hsl" ?
                        process.env.REACT_APP_JOB_URL_HSL :
                        process.env.REACT_APP_MODE === "hrpl" ?
                            process.env.REACT_APP_JOB_URL_HRPL :
                            process.env.REACT_APP_MODE === "lnt" ?
                                process.env.REACT_APP_JOB_URL_LNT :
                                process.env.REACT_APP_MODE === "barc" ?
                                    process.env.REACT_APP_JOB_URL_BARC :
                                    process.env.REACT_APP_MODE === "srpl" ?
                                        process.env.REACT_APP_JOB_URL_SRPL :
                                        process.env.REACT_APP_MODE === "cmr" ?
                                            process.env.REACT_APP_JOB_URL_CMR :
                                            process.env.REACT_APP_MODE === "lg_cop" ?
                                                process.env.REACT_APP_JOB_URL_LG_COP :
                                                process.env.REACT_APP_MODE === "godrej" ?
                                                    process.env.REACT_APP_JOB_URL_GODREJ :
                                                    process.env.REACT_APP_MODE === "uniqus" ?
                                                        process.env.REACT_APP_JOB_URL_UNIQUS :
                                                        process.env.REACT_APP_MODE === "mbl" ?
                                                            process.env.REACT_APP_JOB_URL_MBL :
                                                            process.env.REACT_APP_MODE === "tata_cap" ?
                                                                process.env.REACT_APP_JOB_URL_TATA_CAP :
                                                                process.env.REACT_APP_JOB_URL_PROD)
            ),

        DEMO_REQUEST_API: process.env.REACT_APP_MODE === 'development' ? process.env.REACT_APP_DEMO_REQUEST_URL_DEV : process.env.REACT_APP_DEMO_REQUEST_URL_DEV,

        CONFIG: {
            POINT: 'config/',
            GET_DBS: 'configs/db',
            GET_TABLES: 'configs/table?db=<db-name>',
            GET_COLUMNS: 'configs/column?db=<db-name>&table=<table-name>',
            SAVE: 'configs',
            GET: 'configs'
        },



        DATA_WRANGLER_CONFIG_V1: {
            POINT: 'etl_config/',
            SAVE_CONFIG: 'save',
            GET_CONFIG_BY_JOB_ID: 'etl_config_by_job_id',
        },


        DATA_WRANGLER_EXTRACTION: {
            POINT: 'extraction/',
            GET_EXTRACTION_BY_ID: 'get_by_id',
            SAVE_EXTRACTION: 'save',
            GET_EXCEL_META_INFO: 'get_excel_meta_info'
        },

        DATA_WRANGLER_LOAD: {
            POINT: 'load/',
            SAVE_LOAD: 'save'
        },
        DATA_WRANGLER_LOAD_MAPPING: {
            POINT: 'load_mapping/',
            GET_LOAD_MAPPING_BY_ID: 'get_by_id'
        },
        DATA_WRANGLER_EXCEPTIONS_LOGS: {
            POINT: 'exceptions_logs/',
            GET_ERRORS_RECORDS: 'get_error_records'
        },
        DATA_WRANGLER_FILE_MAPPING: {
            POINT: 'file_mapping/',
            SAVE_FILE_MAPPING: 'save_file_mapping'
        },
        JOB_V1: {
            POINT: 'jobs/',
            SAVE_JOB: 'save_job',
            GET_ALL_JOBS: 'get_all_jobs',
            GET_JOB_BY_ID: 'get_job_by_id',
            RUN_JOB: 'run_job',
            UPDATE_JOB_STATUS: 'update_job_status',
            GET_JOB_OCCURENCES_BY_JOB_ID: 'get_jobs_occurences_by_job_id',
            GET_JOBS_LOGS_BY_OCCUURANCE_ID: 'get_jobs_logs_by_occurance_id',
            GET_FILE_LOGS_BY_DATE: 'get_file_logs_by_date',
            DELETE_FILE_LOG: 'delete_db_data_by_file_log_id',
            GET_JOB_STATUS: 'get_job_status',
            SAVE_EXCEL_DATA: 'save_excel_data',
        },

        DATA_SHARE: {
            GET_SHAREABLE_TOKEN: 'data_share/create_shareable_key',
            AUTHENTICATE_TOKEN: 'data_share/authenticate_token',
        },
        ACTUAL_PLAN: {
            POINT: 'actual_plan/',
            GET_ALL_PLANNED_MEETINGS: 'get_all_planned_meetings',
            SAVE_PLANNED_MEETING: 'save_planned_meeting',
            GET_ACTUAL_BY_PLAN_ID: 'get_actual_by_plan_id',
            SAVE_ACTUAL_PLAN: 'save_actual_plan'
        },
        JOB: {
            SAVE: 'jobs',
            GET: 'jobs'
        },
        REPORT: {
            POINT: 'report/',
            GET_DATA_FROM_REPORTING_SERVER: 'report/data_from_reporting_server',
            GET_DISTINCT_DATA_FOR_A_COLUMN: 'report/distinct_data',
            GET_DATA_FROM_REPORTING_SERVER_SECOND_API: 'report/data_from_reporting_server',
            GET_CSV_DATA_FROM_REPORTING_SERVER: 'report/csv_data_of_report',
            GET_ENTITY_VALUES: 'report/get_entity_values',
            GET_ALL_REPORTS: 'get_all_reports',
            DELETE_REPORT: 'delete_report',
            CLONE_REPORT: 'clone_report',
            SAVE_REPORT: 'save_report',
            SAVE_TBL_FORMAT_SETTING: 'save_tbl_format_setting',
            GET_TBL_FORMAT_SETTING: 'get_tbl_format_settings',
            SAVE_TBL_FORMULAS: 'save_tbl_formulas',
            GET_TBL_FORMULAS: 'get_tbl_formulas',
            TEST_SQL_QUERY: 'test_sql_query',
            SAVE_PIVOT: 'report/save_pivot_info',
            SAVE_REPORT_ITEMS: 'save_report_items',
            SAVE_REPORT_COLUMNS_INFO: 'save_report_columns_info',
            GET_REPORT_COLUMNS_INFO: 'get_report_columns_info',
            GET_REPORT_SQL_QUERY: 'get_report_sql_query',
            UPDATE_REPORT_USER_PREFERANCE: 'update_report_user_preferance',
            GET_DEFAULT_INSIGHT_FOR_DASHBOARD: 'get_default_insight_for_dashboard_v1',
            SAVE_REPORT_V1: 'save_report_v1',
            GET_REPORT_BY_ID_V1: 'get_report_by_id_v1',
            GET_ADVANCE_DATE_CONFIG: 'get_advance_date_config',
            SAVE_ADVANCE_DATE_CONFIG: 'save_advance_date_config',
            DELETE_ADVA: 'delete_advance_date_config',
            VOCIE_DASHBOARD_DETAILS_FROM_JAVA: 'voice_dashboard_details_from_java',
            JAVA_STRING_PARSER: 'report/java_string_parser',
            GET_POLUS_GPT_AI_DATA_STORY: 'get_polus_gpt_ai_data_story'

        },
        PIVOT: {
            POINT: 'pivot/',
            GET_PIVOT_DATA: 'get_pivot_data',
            APPLY_FORMULA: 'apply_formula'
        },
        EMAIL_JOBS: {
            POINT: 'email_jobs/',
            PRINT_AND_EMAIL: 'print_and_email'
        },
        KPI: {
            REFRESH_KPI_DATA: 'kpi/refresh_kpi_data'
        },
        MULTI_REPORT: {
            GET_REPORT_DATA: 'multi_report/get_report_data'
        },
        MENU_GROUP: {
            POINT: 'menu_group/',
            GET_MENUS: 'get_menus',
            SAVE_MENU: 'save_menu',
            GET_MENU_GROUPS: 'get_menu_groups',
            GET_REPORT_MENUS_V1: 'get_report_menus'
        },
        ALIASES: {
            POINT: 'aliases/',
            GET_ALIASES: 'get_aliases'
        },
        SORT_PRIORITIES: {
            POINT: 'sort_priorities/',
            GET_SORT_PRIORITIES: 'get_sort_priorities'
        },
        HINTS: {
            POINT: 'hints/',
            GET_HINTS: 'get_hints',
            SAVE_HINTS: 'save_hints',
            GET_TABLES_FOR_HINTS: 'get_table_for_hints',
            GET_HINTS_BY_ENTITY: 'get_hints_by_entity',
            GET_HINTS_WTIH_TABLE_COLUMN_NAME: 'get_all_hints_with_tables_columns',
            GET_HINTS_BY_CLIENT_ID: 'get_hints_by_client_id',
            GET_HINTS_BY_DB_INFO_ID: 'get_hints_by_db_info_id',
            GET_RULES_HINTS: 'get_rules_hints',
            SAVE_RULES_HINTS: 'save_rules_hints',
            GET_TABLE_COLUMN_NAME_HINTS: 'get_table_column_name_hints',
            SAVE_VALUE_CONTAINER: 'save_value_container'
        },
        USER_PREFERENCE: {
            POINT: 'user_preference/',
            GET_PREFERENCES: 'get_preferences',
            SAVE_PREFERENCE: 'save_preference'
        },
        ENTITIES: {
            POINT: 'entity/',
            GET_ENTITIES: 'get_entities',
            SAVE_ENTITIES: 'save_entity',
        },

        FORMULAS: {
            POINT: 'formulas/',
            SAVE_FORMULAS: 'save_formulas',
            GET_FORMULA_BY_REPORT_ID: 'get_formulas_by_report_id'

        },

        NF_COLUMN_INFO_AD_TECH: {
            POINT: 'nf_import_columns_preferences/',
            GET_NF_COLUMN_INFO_AD_TECH: 'get_nf_import_columns_preferences',
            SAVE_NF_COLUMN_INFO_AD_TECH: 'save_nf_import_columns_preferences'
        },
        INSIGHTS: {
            POINT: 'report/',
            GET_INSIGHTS_DATA: 'get_insights_data',
            GET_INSIGHTS_LIST_BY_USER: 'get_insights_list_by_user',
            GET_INSIGHTS_LIST_BY_INSIGHT_ID: 'get_insights_list_by_insight_id',
            SAVE_INSIGHTS_DATA: 'save_insights',
            REFRESH_INSIGHTS_DATA: 'refresh_insight',
            GET_COLUMN_INFO_BY_QUESTION: 'get_column_info_by_question',
            GET_CHART_CONFIG: 'get_chart_config',
            SAVE_CHART_CONFIG: 'save_chart_config',

        },
        FILTERS: {
            GET_FILTERS: 'get_filters_for_report',
            GET_FILTERS_FOR_DASHBOARD: 'get_filters_for_dashboard',
            FILTER_DATA_FOR_MTD_YTD: 'filter_data_for_mtd_ytd',
            GET_FILTER_DATA_FOR_INSIGHT: 'get_filter_data_for_insight',
            GET_FILTER_DISTINCT_DATA: 'get_filter_distinct_data',
            UPDATE_REPORT_FILTER: 'update_report_filter'
        },
        FILTERS_V1: {
            POINT: 'filter/',
            GET_FILTER_OPTIONS_V1: 'get_filter_options_v1'
        },
        CLIENT_INFO: {
            POINT: 'client_info/',
            GET_CLIENTS_INFO: 'get_clients_info',
            SAVE_CLIENT_INFO: 'save_client_info',
            TEST_CONNECTION: 'test_connection',
            GET_DB_INFO: 'get_db_info',
            DELETE_DB_INFO: 'delete_db_info'
        },
        DB_INFO: {
            POINT: 'db_info/',
            GET_TABLE_OF_DB: 'get_tables_of_db',
            GET_COLUMNS_OF_TABLE: 'get_columns_of_table',
            GET_TABLE_DATA: 'get_table_data',
            GET_TABLE_BY_CLIENT_ID: 'get_tables_by_client_id',
            GET_COLUMNS_OF_TABLES_BY_CLIENT_ID: 'get_columns_of_tables_by_client_id',
            GET_TABLE_AND_COLUMNS_OF_DB: 'get_table_and_columns_of_db',
            SAVE_TABLE_ADDITIONAL_COLUMN: 'save_table_additional_column',
            GET_SQL_QUERY: 'get_sql_query',
        },
        HELPERS: {
            POINT: 'helper/',
            GET_MONGO_DB_TABLE_DATA: 'get_mongo_table_data'
        },
        ETL: {
            POINT: 'etl/',
            SAVE_ETL_CONFIG: 'save_etl_config',
            GET_ALL_INFO_OF_QUESTION: 'get_all_info_of_question',
            GET_CONFIG_REPORTS: 'get_config_reports',
            DELETE_CONFIG: 'delete_config',
            GET_CONFIG_INFO: 'get_config_info',
            GET_JOB_INFO: 'get_job_info',
            SAVE_MULTIPAL_CONFIG: 'save_multipal_config',
            GET_TABLE_DATA_OF_EXTRACTED_COLUMN: 'get_table_data_of_extracted_column',
            UPDATE_JOB: 'update_job',
            VALIDATE_ETL_CONFIG: 'validate_etl_configration',
            SAVE_STREAMING_JOB: 'save_streaming_job',
            GET_STREAMING_JOBS: 'get_streaming_jobs',
            GET_STREAMING_JOB_BY_ID: 'get_streaming_jobs_by_id',
            GET_COLLECTIONS_FROM_MONGO_BY_CLIENT: 'get_collections_from_mongo_by_client',
            LOAD_FILE_IN_MONGO: 'load_files_in_mongo',
            GET_THE_FILE_IN_FOLDER: 'get_the_files_in_folder',
            GET_THE_FILE_DATA_FROM_FILE: 'get_the_file_data_from_file',
        },
        RELATIONSHIP_DETAILS: {
            POINT: 'relationship_details/',
            GET_TABLES_FOR_RELATIONSHIP: 'get_table_for_relationship',
            GET_COLUMNS_FOR_TABLE: 'get_columns_for_table',
            SAVE_RELATIONSHIP_DETAILS: 'save_relationship_details',
            GET_ALL_RELATIONSHIPS: 'get_all_relationships',
            GET_TABLE_AND_COLUMNS: 'get_all_table_and_columns',
        },
        AUTHETICATE: {
            POINT: 'account/',
            GET_AUTHENTICATE: 'authenticate',
            REFRESH_TOKEN: 'refresh_token'
        },
        ENTITY_RELATIONSHIP_WITH_TABLE: {
            POINT: 'entity_relationship_with_table/',
            GET_ENTITY_RELATIONSHIP_WITH_TABLE: 'get_entity_relationship_with_table',
            SAVE_ENTITY_RELATIONSHIP_WITH_TABLE: 'save_entity_relationship_with_table'
        },
        RANKING: {
            POINT: 'ranking/',
            GET_CONFIGS: 'get_config',
            SAVE_CONFIG: 'save_config',
            GET_RESULT: 'get_result',
            GET_COMPARISON_DATA: 'get_chart_data_comparison'
        },
        RULES_ALERT: {
            POINT: 'rules_alert/',
            GET_ALL_RULES_INFO: 'get_all_rule_info',
            SAVE_RULE_INFO: 'save_rule_info',
            SAVE_TICKET_ACTION: "save_ticket_action",
            GET_TICKET_BY_RULE: "get_ticket_by_rule",
            RUN_RULE_JOB: 'run_rule_job',
            GET_RULE_BY_REPORT_ID: 'get_rule_by_report_id',
            SEND_GPT_MAIL: 'send_gpt_mail'
        },
        SENSITIVE: {
            POINT: 'sensitive/',
            SAVE_SENSITIVE: 'save_sensitive',
            GET_ACCESS_GROUP: 'get_access_group'
        },
        PREDICTIVE: {
            POINT: 'predictive/',
            GET_PREDICTIVE_RESULT: 'get_result',
            GET_PREDICTIVE_SPECALITY_BY_MONTH: 'specialty_prediction_by_month',
            GET_PREDICTIVE_SPECALITY_BY_YEAR: 'specialty_prediction_by_year',
            GET_PREDICTIVE_PATIENTS_BY_YEARLY: 'yearly_patients_prediction',
            GET_PREDICTIVE_PATIENTS_BY_MONTHLY: 'monthly_patients_revenue_prediction',
            GET_QUATERLY_PATIENTS_REVENUE_PREDICTION: 'quaterly_patients_revenue_prediction'
        },
        OUTLIER: {
            POINT: 'outlier/',
            GET_OUTLIER_RESULT: 'get_outliers',
            SAVE_OUTLIER: 'save_outlier',
            GET_OUTLINER_SCAN_DATA: 'get_outliers_scan_data'
        },
        USER_SESSION: {
            POINT: 'user_session/',
            GET_USER_SESSION: 'get_user_session',
            REMOVE_USER_SESSION: 'remove_user_session'
        },
        DB_FUNCTIONS_CONFIG: {
            POINT: 'db_functions_config/',
            GET_FUNCTIONS_CONFIG: 'get_functions_config',
            GET_WEEK_CONFIG: 'get_week_config'
        },
        NF_GRAMMAR: {
            POINT: 'nf_grammar/',
            GET_NF_GRAMMAR_LIST: 'get_nf_grammar_list',
            SAVE_NF_GRAMMAR: 'save_nf_grammar',
            DELETE_NF_GRAMMAR: 'delete_nf_grammar'
        },

        NEWTABLE: {
            POINT: 'dimension_co_relation/',
            GET_DIMENSION_RELATION: 'get_dimension_relation',
            SAVE_DIMENSION_RELATION: 'save_dimension_relation',
            DELETE_DIMENSION_RELATION: 'delete_dimension_relation',
            GET_DIMENSION_BY_ENTITY: 'get_dimension_by_entity',
            GET_DATA_HIERARCHY: 'data_hierarchy/get_data_hierarchy'
        },
        DATA_HIERARCHY: {
            POINT: 'data_hierarchy/',
            GET_DATA_HIERARCHY: 'get_data_hierarchy',
            SAVE_DATA_HIERARCHY: 'save_data_hierarchy',
            GET_DIMENSION_BY_HIERARCHY: 'get_dimension_by_hierachy'
        },
        DB_FORMULA: {
            POINT: 'db_formula/',
            SAVE_DB_FORMULA: 'save_db_formula',
            SAVE_MULTIPLE_FORMULAS: 'save_multiple_formuls',
            GET_DB_FORMULA_BY_ID: 'get_db_formula_by_id',
            GET_ALL_DB_FORMULA: 'get_all_db_formula',
            DELETE_FORMULA: 'delete_formula'
        },
        PDF_TO_QUESTION_ANSWER: {
            LIST_OF_UPLOADER_FILES: 'ask-from-text/list-of-uploaded-files',
            LIST_OF_CATEGORY_FOLDER: 'ask-from-text/list-of-category-folder',
            SELECT_FILE: 'ask-from-text/select-file',
            SEND_FILE_TO_UPLOAD: 'upload/ask-from-text',
            ASK_QUESTION_TO_PDF: 'ask-from-text/askquestion',
            ASK_QUESTION_TO_PDF_SECOND_API: 'ask-from-text/askquestion-node',
            CLEAR_CASHED_INFO: 'ask-from-text/clear_cashed_inf',
            UPLOADED_TABLE_LIST: 'ask-from-text/list_of_table_uploaded',
            LIST_OF_FILE_INFO: 'ask-from-text/list_of_uploaded_file_info',
            GET_FILE_GROUPED: 'ask-from-text/list_of_file_group',
            DELETE_GROUPED_FILE: 'ask-from-text/delete_grouped_file',
            APPROVE_TABLE_REQUEST: 'ask-from-text/approve_table_request',
            SAVE_GOOGLE_DRIVE_DATA: 'ask-from-text/save_google_drive_data',
            UPLOAD_SEND_MAIL: 'upload/sent_mail'

        },
        FILE_UPLOAD_INFO: {
            POINT: 'file_upload_info/',
            GET_ALL_FILES: 'get_all_files',
            GET_FILES_BY_PLATFORMS: 'get_files_by_platform',
            GET_FILE_BY_DIMENSION: 'get_files_by_dimension',
            GET_THE_SAMPLE_FILE_DETAILS: 'get_the_sample_file_details',
            GET_THE_META_DETAILS: 'get_the_meta_details',
            DOWNLOAD_THE_CSV: 'download_csv_file',
            PROCESS_THE_FILE: 'process_the_file',
            GET_NF_IMPORT_RULES: 'get_nf_import_rules',
            SAVE_REQUIRED_JSON_COLUMNS: 'save_required_json_columns',
            SAVE_TEXT_FILE_REQUIRED_DATA: "save_text_file_required_data",
            SCAN_DIRECTORY_GET_FILES: 'scan_directory_get_files',
            SAVE_FOLDER_FILE_MAPPING_DATA: 'save_folder_file_mapping_data',

        },
        IMPORT_ERROR: {
            POINT: 'import_error/',
            GET_IMPORT_INFO: 'get_import_info',
            GET_COLUMNS_INFO: 'get_columns_info',
            SAVE_COLUMNS_INFO: 'file_upload_dimension_info/save_the_columns_in_dimension_info'
        },

        TAGGING: {
            GET_TAGGING_INFO: 'nf_tagging_info/',
            SAVE_FAVOURITE: 'save_favourite',
            GET_FAVOURITE_BY_USER_ID: 'get_favourite_list_by_user_id'
        },
        FAVOURITE: {
            POINT: 'favourite/',
            SAVE_FAVOURITE: 'save_favourite',
            GET_FAVOURITE_BY_USER_ID: 'get_favourite_list_by_user_id'
        },
        HISTORY: {
            POINT: 'history/',
            GET_HISTORY: 'get_history',
            DELETE_HISTORY: 'delete_history',
            GET_SQL_HISTORY: 'get_sql_history'
        },
        BREADCRUMB: {
            POINT: 'breadCrumb/',
            BREAD_CRUMB_ADD: 'bread_crumb_add',
            BREAD_CRUMB_CLEAR: 'bread_crumb_clear',
            BREAD_CRUMB_SWITCH: 'bread_crumb_switch'
        },
        POLUS_INTERPOLATION: {
            POINT: 'interpretation/',
            GET_POLUS_INTERPOLATION_LIST: 'get_polus_interpretation_list',
            SAVE_POLUS_INTERPOLATIONL: 'save_polus_interpretation'
        },
        CREATE_FORM_UI: {
            POINT: 'create_ui/',
            CREATE_TABLE: 'create_table',
            SAVE_ANSWERS: 'answer',
            GET_ANSWERS_BY_TABLE_NAME: 'get_answer',
            DELETE_ANSWER_BY_ID: 'delete_answer'
        },
        PDF_QUESTIONS: {
            POINT: 'pdf_question/',
            GET_PDF_QUESTIONS: 'get_questions',
            SAVE_PDF_QUESTIONS: 'save_questions',
            SAVE_SET_OF_QUESTIONS: 'save_set_of_questions',
            GET_JOBS_LIST: 'get_job_list',
            DELETE_JOB_BY_ID: 'delete_job',
            GET_ANSWERS_BY_JOB_ID: 'get_answers_by_job'
        },
        MAP: {
            POINT: 'map/',
            GET_ZONE: 'get_the_zones',
            GET_THE_MULTI_ZONES: 'get_the_multi_zones',
            GET_ZONE_MARKERS: 'get_the_marker_in_zone',
            GET_ZONE_NAMES: 'get_zones_name',
            GET_MARKER_IN_RADIUS: 'find_the_markers_in_radius',
            GET_PREDEFINED_MARKERS: 'get_predefined_markers',
            GET_AUTOCOMPLETE_NAME: 'get_autocomplete_names',
            GET_SEARCH_MARKER: 'get_search_marker',
            GET_ALL_ZONE_BY_CLIENT: 'get_all_zone_by_client_id',
        },
        TBL_INFO: {
            POINT: 'tbl_info/',
            GET_TBL_INFO_BY_TBL_NAME: 'get_tbl_info',
            SAVE_TBL_INFO: 'save_tbl_info',
        },
        HEATMAP: {
            POINT: 'heatmap/',
            GET_HEATMAP_CONFIGRATION: 'get_heatmap_configration'
        },
        DEMO_SAMPLE: {
            POINT: 'demo_sample/',
            GET_DB_TABLE_INFO: 'get_db_table_info',
            GET_TABLE_DATA: 'get_table_data',
            SAVE_NEW_RECORD: 'save_record'
        },
        MASTER_DATA: {
            POINT: 'master_data/',
            GET_TABLE_DATA: 'get_table_data',
            SAVE_TABLE_DATA: 'save_table_Data',
            DELETE_TABLE_REDCORD: 'delete_table_record',
            UPDATE_TABLE_RECORD: 'update_table_record'
        },
        DEMO_BOOKING: {
            POINT: 'demo_booking/',
            GET_DEMO_REQUEST_LIST: 'get_demo_request_list',
            APPROVED_DEMO_REQUEST: 'approve_demo_request',
            DELETE_DEMO_REQUEST: 'delete_demo_request',
            EDIT_DEMO_REQUEST: 'edit_demo_request',
            GET_ALL_TIME_SLOT_LIST: 'get_all_time_slot',
            GET_BOOKED_INFO_BY_CURRENT_DATE: 'get_booked_info_by_current_date'
        },
        AD_TECH_CAMPAIGN_MAPPING: {
            POINT: 'ad_tech_campaign_mapping/',
            GET_AD_TECH_CAMPAIGN_MAPPING: 'get_ad_tech_campaign_mappings',
            SAVE_THE_MAPPING: 'save_the_mapping',
            GET_THE_MAPPING_BY_AD_TECH_CLIENT_AND_PLATFORM: 'get_the_mapping_by_ad_tech_client_and_platform'
        },
        AD_TECH_CLIENT: {
            POINT: 'ad_tech_client/',
            GET_AD_TECH_CLIENTS: 'get_ad_tech_clients'
        },
        AD_TECH_CAMPAIGN: {
            POINT: 'ad_tech_campaign/',
            GET_AD_TECH_CAMPAIGN: 'get_ad_tech_campaigns',
            GET_PLATFORM_AND_CAMPAIGNS: 'get_platforms_and_campaigns'
        },
        MARK_UP_AD_TECH: {
            POINT: 'mark_up_ad_tech/',
            SAVE_THE_MARK_UP: 'save_the_mark_up'
        },

        NF_FILE_UPLOAD_INFO: {
            POINT: 'file_upload_info/',
            DELETE_THE_FILE: 'delete_the_file'
        },
        DOMAIN_CONFIG: {
            POINT: 'domain_config/',
            GET_DOMAIN_CONFIG_BY_PAGE_NAME: 'get_domain_config_by_page_name'
        },

        SQOOP_CONFIGRATION: {
            POINT: 'sqoop_configration/',
            SAVE_THE_CONFIGRATION: 'save_the_configuration',
            GET_THE_CONFIGRATION: 'get_the_configuration',
            GET_THE_CONFIGRATION_DATA_BY_ID: 'get_the_configuration_data_by_id',
            DELETE_THE_CONFIGRATION_DATA_BY_ID: 'delete_the_configuration_data'
        },
        TBL_ERRORS: {
            POINT: 'tbl_errors/',
            GET_TBL_ERRORS: 'get_tbl_errors',
            INSERT_ERROR_QUERIES: 'insert_error_queries'
        },
        STREAMING_SERVICE: {
            POINT: 'streaming_services/',
            GET_THE_STREAMING_SERVICE: 'get_the_streaming_services'
        },
        ACCESS_GROUP: {
            POINT: 'access_group/',
            GET_ACCESS_GROUP: 'get_access_group',
            GET_ACCESS_GROUP_WITH_USERS: 'get_access_group_with_users',
            GET_GROUP_ACCESS: 'get_group_access',
            SAVE_GROUP_ACCESS: 'save_group_access',
            GET_GROUP_ACCESS_FOR_MENU: 'get_group_access_for_menu',
            SAVE_MENU_GROUP_ACCESS: 'save_menu_group_access',
            GET_USER_LIST: 'get_user_list',
            GET_ACCESS_GROUP_INFO: 'get_access_group_info',
            SAVE_ACCESS_GROUP_INFO: 'save_access_group_info',
            GET_PERMISSIONS_FOR_USER: 'get_permissions_for_user',
            GET_ALL_DATA_POLICY: 'get_all_data_policy',
            GET_POLICY_BY_ID: 'get_data_policy_by_id',
            SAVE_DATA_POLICY: 'save_data_policy'
        },
        USERS: {
            POINT: 'users/',
            GET_USERS: 'get_users',
            SAVE_USERS: 'save_users',
            SAVE_USER: 'save_user'
        },
        USER: {
            POINT: 'user/',
            SAVE_USER: 'save_user'
        },
        FILE_UPLOAD_CONFIG: {
            POINT: 'file_upload_config/',
            SAVE_CONFIG: 'save_config',
            GET_CONFIG: 'get_config'
        },


    },

    REPORT_TYPES: {
        DASHBOARD: 'dashboard',
        SELF: 'self',
    },

    SESSION_KEYS: {
        LOGGED_USER: 'nf_v2_lu',
        OPR_DATA: 'nf_v2_sjdj',
        LOGGED_USER_EMAIL: 'nf_v2_lue',
        TOKEN: 'nf_v2_vvpsrp',
        SESSION_ID: 'nf_session_id',
        NF_TOKEN: 'nf_v2_vvspsrpv',
        REFRESH_TOKEN: 'nf_v2_rtkn',
        USER_ID: 'nf_v2_ui',
        PASSWORD_TOKEN: 'nf_v2_pt',
        RECENT_COLORS: 'nf_v2_rc',
        DATE_FILTER: 'nf_v2_df',
        RECENT_TABLE: 'nf_v2_tab',
        RECENT_TAG: 'nf_v2_tag',
        PERMISSIONS: 'nf_v2_per',
        CLIENTS: 'nf_v2_CLTS',
        TRAKING_SESSION_ID: 'nf_v2_tr_s_id',
        FORM_TITLE: 'nf_v2_form_title',
        DATABASE_ID: 'nf_v2_database_id',
        USER_NAME: 'nf_v2_user_name_',
        RECENT_QUESTIONS: 'nf_v2_questions',
        REPORT_FILTERS: 'nf_v2_report_filters',
        REPORT_BE_FILTERS: 'nf_v2_report_be_filters',
        THEME: 'nf_v2_theme',
        TABLE_HIDDEN_COLUMNS: 'nf_v2_table_hidden_columns',
        REPORT_PIVOT_INFO: 'nf_v2_report_pivot_info',
        AGGREGATE_OPTIONS: 'nf_v2_report_aggregate_options',
        VALIDATION_KEYS: 'nf_v2_validation_keys',
        REDIRECT_TO_DB_LIST: 'nf_v2_redirect_db_list',
        KX: {
            CURRENT_USER: 'currentUser',
            FACILITY: 'CURRENT_ORGANIZATION',
            ROLE: 'CURRENT_ROLE'
        },
        DEFAULT_INSIGHT: 'nf_v2_di',
        CLIENT_ID: 'nf_v2_ci',

    },

    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,

};

