import styled from 'styled-components';





// checkbox checkmark radion 
export const RadioCheckMark = styled.span`
    position: relative;
    top: 0;
    left: 0;
    height: ${props => props.outerSize || "12px"};
    width: ${props => props.outerSize || "12px"};
    background-color: ${props => props.disabled ? '#ccc' : 'transparent'};
    border: 1px solid ${props => props.bColor || '#9caeb5'};
    border-radius: 50%;
    /* box-sizing: border-box; */
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    display: inline-block;

      &::after {
        content: '';
        visibility: ${props => props.checked ? "none" : 'hidden'};
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        margin: auto;
        width: ${props => props.innerSize || "8px"};
        height: ${props => props.innerSize || "8px"};
        border-radius: 50%;
        /* background: #0084be; */
        /* transform: translate(-50%, -50%); */
        background: ${props => props.dColor || '#0084be'};
  }
`;