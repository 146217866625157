import React, { useState, useEffect } from 'react';
import { Wrapper } from './chart.filter.styled';
import { Button } from '../hoc/button';
import { Gap } from '../gap'
import { RadioCheckMark } from '../hoc/radiobox';
import { withRouter } from 'react-router-dom';
import { apply_filters, apply_link_filter, clear_all_filters, raise_drill_filter_cache } from '../../actions/filter';
import { connect } from 'react-redux';
import { filter } from 'lodash';
import { getDimensionByEntity, hide_popus_iq, getDimensionByHierarchy } from '../../actions/new-table'
import { addBreadCrumb } from '../../actions/bread-crumb'
import { getDataFromSecondApi, save_updated_drill_down_data, set_report_to_refresh } from '../../actions/report';
import { get_dimension_by_hierarchy } from '../../actions/data-hierarchy';
import { _getDataFromReportingServer, getParameterByName, generate_unique_key, get_data_from_server_action, showPopup, is_date_month, convert_normal_date_filter_to_JAVA } from '../../utils';
import { dispatch_report_cell_clicked_info } from '../../actions/report.helper'
import { parameterized_keys } from '../../utils/v1.1/parameterized.config'
import * as dataTypes from '../../shared-with-fe/data.types';
import { getFromSession, saveInSession } from '../../utils/session.helper';
import { constants } from '../../utils/constants';

const Index = (props) => {

    const {
        dimensionByHierarchy,
        getDataFromReportingServer,
        close, popupKey, option, data,
        filters_cache,
        drill_down_data,
        mtd_ytd_options_cache
    } = props;

    const {
        is_single_cell,
        insights, insight_id,
        question,
        dimension,
        fact,
        dimension_value,
        comparison,
        comparison_value,
        _id,
        drill_down_values,
        close_popup,
        drill_down_type,
        drill_down_report,
        db_columns,
        od_report_id,
        od_report,
        reporting_db_info_id
    } = data;


    const { filter_key, containerWidth, filter_data, hide_drill_down = false } = data;

    const { fact_db_name, dimension_db_name } = data;

    const [selectedValue, setSelectedValue] = useState('');

    const [local_drill_down_values, set_local_drill_down_values] = useState(undefined);
    const [action_name, set_acction_name] = useState('');



    useEffect(() => {
        if (!insights) {
            set_acction_name('drill_down')
        }
    }, [])


    const drill_filters = props?.drill_filters_cache?.[insight_id];

    const filter_data_v1 = {};

    const pre___filter__keys__ = filter_data ? Object.keys(filter_data) : [];

    pre___filter__keys__.map((k) => {
        if (!filter_data_v1[k.split("__nfx__db__")[0]]) filter_data_v1[k.split("__nfx__db__")[0]] = filter_data[k]
    })


    const post_filter__keys__ = (filter_data_v1 ? Object.keys(filter_data_v1) : []).join(",")?.toLocaleLowerCase()?.trim()?.split(",");
    const post_filter__keys___pre = (filter_data_v1 ? Object.keys(filter_data_v1) : []);

    // const title = `"${dimension_value}" ${dimension} ${comparison ? (' and "' + comparison_value + '" ' + comparison) : ''}`;

    console.log("post_filter__keys__", filter_data_v1)


    let title = '';

    post_filter__keys___pre.map((k) => {

        if (title) title += " and " + '"' + filter_data_v1?.[k]?.['value'] + '"' + " " + filter_data_v1?.[k]?.['key']
        else title += '"' + filter_data_v1?.[k]?.['value'] + '"' + " " + filter_data_v1?.[k]?.['key']
    })



    const getRequireData = () => {

        if (selectedValue) {

            props.hide_popus_iq(true);

            // push to breadcrumb
            props.addBreadCrumb({
                name: dimension,
                question: question,
                _id
            });

            props.addBreadCrumb({
                name: dimension_value
            });

            if (comparison) {
                props.addBreadCrumb({
                    name: comparison,
                    question: question,
                    _id
                });

                props.addBreadCrumb({
                    name: comparison_value
                });
            }
        }
    }



    const get_data_from_server = () => {

        if (selectedValue) {

            getRequireData();

            const previous_drill_down_data = (props.drill_down_data && props.drill_down_data[_id]) || [];

            // filter_data.map((filter) => {})

            previous_drill_down_data.push({
                from: dimension,
                to: selectedValue,
                value: dimension_value,
                comparison,
                comparison_value,
                fact,
                fact_db_name: fact_db_name,
                dimension_db_name: dimension_db_name,
                data: filter_data_v1
            });


            props.save_updated_drill_down_data(_id, previous_drill_down_data);

            setTimeout(() => {
                props.set_report_to_refresh(_id);
            }, 200);
        }
    }



    /*** 
     * this is for report
     */

    useEffect(() => {
        if (!is_single_cell) {
            const f_fact = typeof fact === 'string' && fact.indexOf("_nfx_") > -1 ? fact.split("_nfx_")[1] : fact;
            // f_fact, dimension, 
            props.get_dimension_by_hierarchy(fact_db_name, dimension_db_name, reporting_db_info_id)
        }
    }, [])



    useEffect(() => {

        // if (!is_single_cell) {

            const date_dimensions = process.env.REACT_APP_MODE === 'hrpl' ? ["year", "month", "week", "day"] : ["year", "month", "week", "day", "hour", "minute"];

            const previous_drill_down_data = (props.drill_down_data && props.drill_down_data[_id]) || [];

            const previous_drill_down_from = []

            previous_drill_down_data && previous_drill_down_data.length > 0 && previous_drill_down_data.map((d) => {

                if (date_dimensions.indexOf(d?.from?.toLocaleLowerCase()) > -1) {
                    previous_drill_down_from.push(d?.from?.trim().toLocaleLowerCase())
                    previous_drill_down_from.push(d?.to?.trim().toLocaleLowerCase())


                }
                else if (d.data && Object.keys(d.data).length > 0) {
                    const __keys__ = Object.keys(d?.data) || []
                    __keys__.forEach((k) => previous_drill_down_from.push(k?.trim().toLocaleLowerCase()))
                }
                else {
                    previous_drill_down_from.push(d?.dimension_db_name?.trim()?.toLocaleLowerCase() || d?.from?.trim()?.toLocaleLowerCase())
                }
            })

            const temp_dimensionByHierarchy = dimensionByHierarchy ? [...dimensionByHierarchy] : [];

            date_dimensions.forEach((d) => {
                temp_dimensionByHierarchy.push({ go_to: d })
            })

            const temp_dim_h = [];

            temp_dimensionByHierarchy && temp_dimensionByHierarchy.length > 0 && temp_dimensionByHierarchy.forEach((dim) => {

                const splitted_go_to = dim.go_to && dim.go_to.split(',');
                temp_dim_h.push(...splitted_go_to)

            })
            temp_dim_h.push(...(drill_down_values || []))

            let filterd_dim_h = temp_dim_h?.filter((d) => previous_drill_down_from?.indexOf(d?.trim()?.toLocaleLowerCase()) === -1 && (post_filter__keys__?.indexOf(d?.trim()?.toLocaleLowerCase()) === -1));


            // console.log("filterd_dim_h", filterd_dim_h);

            filterd_dim_h = (filterd_dim_h || [])?.filter((d) => {
                if (date_dimensions?.indexOf(d.toLocaleLowerCase()) > -1 && d?.toLocaleLowerCase() === (dimension && dimension?.trim().toLocaleLowerCase())) return false;
                else return true;
            }) || []


            // console.log("filterd_dim_h", previous_drill_down_from, filterd_dim_h, post_filter__keys__)

            set_local_drill_down_values(filterd_dim_h)
        // }

    }, [dimensionByHierarchy, drill_down_values])



    const get_data_form_second_api = () => {

        const filters = (filters_cache && filters_cache[_id]) || {};

        let __question__ = question;

        const previous_drill_down_data = (props.drill_down_data && props.drill_down_data[_id]) || [];


        previous_drill_down_data && previous_drill_down_data.length > 0 && previous_drill_down_data.forEach((option) => {
            const replace_what = option.from;
            const replace_with = option.to;
            const replacer_regex = new RegExp(`\\b${replace_what}\\b`, 'gi');
            __question__ = __question__.replace(replacer_regex, replace_with);
        })

        filters[dimension] = [dimension_value];
        __question__ = __question__ + ' ' + dimension + ' ' + `"${dimension_value}"`;

        const response_session_id = props.dataFromServer && props.dataFromServer[_id] && props.dataFromServer[_id].response_session_id;
        const __url__ = `/dashboard?question=${__question__}&report_id=${_id}&is_details_screen=true&id=${_id}&response_session_id=${response_session_id}&details_filters_from_url=${JSON.stringify(filters)}`
        window.open(__url__, '_blank')
        //
    }


    useEffect(() => {
        return () => {
            // Anything in here is fired on component unmount.
            // close_popup()
        }
    }, [])


    const loading = typeof local_drill_down_values === 'undefined';
    const disabled = typeof local_drill_down_values === 'undefined' || local_drill_down_values.length === 0;



    const apply_local_filter = () => {

        const prev_filters = filters_cache[insight_id] ? JSON.parse(JSON.stringify(filters_cache[insight_id])) : {};
        const filter_data_to_store = {};


        // let's iterate the filters_data and push it into filter object
        filter_data && Object.keys(filter_data).length > 0 && Object.keys(filter_data).forEach((filter_k) => {

            if (!prev_filters[filter_k]) prev_filters[filter_k] = [];

            prev_filters[filter_k] = [filter_data[filter_k].value];

            prev_filters[filter_k].not_save_in_session = true;

            filter_data_to_store[filter_k] = [filter_data[filter_k].value];
        })


        console.log("filter_data_to_store", filter_data_to_store)
        props.apply_link_filter(insight_id, _id, filter_data_to_store)
        props.set_report_to_refresh(insight_id)

        close(option, popupKey)
    }

    const is_slice_dice_enable = filter_data && Object.keys(filter_data).length > 0 && insights && Object.keys(filter_data)[0].indexOf(".") > -1;



    const lets_build_parameterized_data = () => {

        const preapred_filters = {}

        filter_data && Object.keys(filter_data).length > 0 && Object.keys(filter_data).map((key) => {

            const tbl_column_name = key && key.split("__nfx__db__")[0];
            const data_type = key && key.split("__nfx__db__")[1];

            if (data_type == dataTypes.date || data_type == dataTypes.date_month || data_type == dataTypes.date_time) {

                // it is a date filter 

                if (!preapred_filters["date_filter"]) preapred_filters["date_filter"] = {};
                if (!preapred_filters["date_filter"][tbl_column_name]) preapred_filters["date_filter"][tbl_column_name] = []
                preapred_filters["date_filter"][tbl_column_name] = convert_normal_date_filter_to_JAVA({ value: filter_data[key]?.value })

            }
            else {
                if (!preapred_filters[tbl_column_name]) preapred_filters[tbl_column_name] = []
                preapred_filters[tbl_column_name] = [filter_data[key]?.value]
            }
        })

        const global_filters = filters_cache && filters_cache?.[insight_id];
        const local_filters = filters_cache && filters_cache?.[_id];


        // const details_data = {

        //     filter: preapred_filters,
        //     report_filter: global_filters,
        //     report_item_filter: local_filters,
        //     widget_id: _id,
        //     insight_id: insight_id, //"rpt_lcnegxdw",
        //     response_session_id: response_session_id, //"Dfknd",
        //     report_type: report_type,
        //     is_detail_query: true
        // }
        return {
            filter: preapred_filters,
            report_filter: global_filters,
            // report_item_filter: local_filters,
        }

    }



    const go_for_details_v1 = () => {


        const preapred_filters = {}

        filter_data && Object.keys(filter_data).length > 0 && Object.keys(filter_data).map((key) => {

            const tbl_column_name = key && key.split("__nfx__db__")[0];
            const data_type = key && key.split("__nfx__db__")[1];

            if (data_type == dataTypes.date || data_type == dataTypes.date_month || data_type == dataTypes.date_time) {

                // it is a date filter 

                if (!preapred_filters["date_filter"]) preapred_filters["date_filter"] = {};
                if (!preapred_filters["date_filter"][tbl_column_name]) preapred_filters["date_filter"][tbl_column_name] = []
                preapred_filters["date_filter"][tbl_column_name] = convert_normal_date_filter_to_JAVA({ value: filter_data[key]?.value })

            }
            else {
                if (!preapred_filters[tbl_column_name]) preapred_filters[tbl_column_name] = []
                preapred_filters[tbl_column_name] = [filter_data[key]?.value]
            }
        })




        const response_session_id = (props.dataFromServer && props.dataFromServer[_id].data && props.dataFromServer[_id].data.response_session_id);

        const report_type = (props.dataFromServer && props.dataFromServer[_id].data && props.dataFromServer[_id].data.report_type) || "dashboard";

        const global_filters = filters_cache && filters_cache?.[insight_id];
        const local_filters = filters_cache && filters_cache?.[_id];

        const details_data = {
            filter: preapred_filters,
            report_filter: global_filters,
            report_item_filter: local_filters,
            widget_id: _id,
            insight_id: insight_id, //"rpt_lcnegxdw",
            response_session_id: response_session_id, //"Dfknd",
            report_type: report_type,
            is_detail_query: true
        }

        let __url__ = `dashboard?question=undefined&schedule_report_id=${_id}&report_type=${report_type}&details_data=${details_data && JSON.stringify(details_data)}`
        window.open(__url__, '_blank')

    }



    const redirect_to_od_details = () => {

        if (od_report) {
            let report_type = od_report.type;

            console.log("od_report", od_report, drill_down_report, report_type)
            const parameterize_data = {} //lets_build_parameterized_data();
            let __url__ = '';

            if (report_type === 'insights') {
                __url__ = `/?insightId=${od_report?.id || drill_down_report.id}`
            }
            if (report_type === 'config_query_builder' || report_type === 'sql') {
                __url__ = `/dashboard?question=undefined&report_id=${generate_unique_key("rpt_gen")}&schedule_report_id=${od_report?.id}&report_type=${od_report?.type}&details_data=${parameterize_data && JSON.stringify(parameterize_data)}`
            }
            window.open(__url__, '_blank')

            close(option, popupKey);
        }
    }


    const clientIdFromSession = getFromSession(constants.SESSION_KEYS.CLIENT_ID);
    const is_barc = clientIdFromSession && clientIdFromSession.indexOf("barc") > -1;
    const disable_details = is_barc;


    console.log("od_report", od_report)
    return (
        <div>
            <Wrapper style={{
                maxWidth: action_name === 'drill_down' ? '350px' : undefined,
                backgroundColor: action_name === "drill_down" ? "#fff" : undefined,
                boxShadow: action_name === "drill_down" ? 'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px' : undefined,
                padding: action_name === "drill_down" ? '10px' : undefined

            }}>

                {action_name !== "drill_down" && insights && (
                    <div className="circle-container">

                        <div className={disable_details ? "pie-button disabled" : "pie-button"}
                            style={{ transform: 'rotate(88deg)' }}
                            onClick={() => {
                                if (!disable_details) {
                                    go_for_details_v1()
                                    close(option, popupKey);
                                }
                            }}
                        ><span>Details</span></div>


                        <div className={od_report?.id ? "pie-button" : "pie-button disabled"}
                            style={{ transform: 'rotate(178deg)' }}
                            onClick={() => {
                                redirect_to_od_details()
                                close(option, popupKey);
                            }}
                        ><span>OD Details</span></div>


                        <div className={!hide_drill_down ? "pie-button" : "pie-button disabled"}
                            style={{ transform: 'rotate(268deg)' }}
                            onClick={() => {
                                // if (!is_single_cell && !hide_drill_down) {
                                if (!hide_drill_down) {
                                    set_acction_name("drill_down")
                                }
                            }}><span>Drilldown</span></div>


                        <div className="pie-button" style={{
                            transform: 'rotate(358deg)',
                            cursor: !is_slice_dice_enable ? "not-allowed" : undefined
                        }}
                            onClick={() => {
                                if (is_slice_dice_enable) {
                                    apply_local_filter()
                                }
                            }}
                            disabled={!is_slice_dice_enable}
                        ><span>Slice & Dice</span></div>

                        <div className="inner-circle"></div>
                    </div>
                )}


                {action_name !== "drill_down" && insights && false && (

                    <React.Fragment>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>

                            <Button
                                width="130px"
                                primary={true}
                                disabled={!is_slice_dice_enable}
                                onClick={() => {
                                    if (is_slice_dice_enable) {
                                        apply_local_filter()
                                    }
                                }}>Slice & Dice</Button>


                            {!hide_drill_down && (
                                <Button
                                    width="130px"
                                    primary={true}
                                    // disabled={true}
                                    onClick={() => {
                                        if (!is_single_cell) {
                                            set_acction_name("drill_down")
                                        }
                                    }}>Drilldown</Button>
                            )}


                            {!drill_down_type && !drill_down_report &&
                                // (process.env.REACT_APP_MODE === 'hrpl_olap' || process.env.REACT_APP_MODE === 'development' || process.env.REACT_APP_MODE === 'staging') 
                                (
                                    <Button
                                        width="130px"
                                        primary={true}
                                        disabled={disable_details}
                                        onClick={() => {
                                            if (disable_details) {
                                            go_for_details_v1()
                                            close(option, popupKey);
                                            }
                                        }}>Details</Button>
                                )}


                            {drill_down_type && drill_down_report && (
                                <Button
                                    width="180px"
                                    disabled={!drill_down_type && !drill_down_report}
                                    bgColor="#6f8997"
                                    color='#fff'
                                    title={'Jump To' + ' ' + (drill_down_report?.title || '')}
                                    onClick={() => {
                                        if (drill_down_report) {
                                            let report_type = drill_down_report.type;

                                            const parameterize_data = lets_build_parameterized_data();
                                            console.log("parameterize_data", parameterize_data,)

                                            let __url__ = '';
                                            if (report_type === 'insights') {
                                                __url__ = `/?insightId=${drill_down_report.id}`
                                            }
                                            if (report_type === 'config_query_builder' || report_type === 'sql') {
                                                __url__ = `/dashboard?question=undefined&report_id=${generate_unique_key("rpt_gen")}&&schedule_report_id=${drill_down_report?.id}&report_type=${drill_down_report?.type}&details_data=${parameterize_data && JSON.stringify(parameterize_data)}`
                                            }
                                            window.open(__url__, '_blank')

                                            close(option, popupKey);

                                        }

                                    }}>Jump To {drill_down_report?.title?.length > 8 ? (drill_down_report?.title.substring(0, 8) + '..') : (drill_down_report?.title || '')}</Button>
                            )}

                        </div>
                    </React.Fragment>
                )}



                {(action_name === "drill_down" || !insights) && (
                    <React.Fragment>
                        <h1>Analyze {fact} {title?.split('__nfx__join__')?.join(' ')} </h1>
                        <div style={{ marginTop: '0px', paddingTop: '0px', width: '100%' }}>
                            {/* <div className="scroll"> */}
                            {loading && <p>Loading data, please wait.</p>}
                            {loading === false && disabled && <p>No options found for drilling down further.</p>}
                            <ul>
                                {local_drill_down_values && local_drill_down_values.length > 0 && local_drill_down_values.map((item, index) => {
                                    return (
                                        <li key={"chat_popup_item" + index}>
                                            <RadioCheckMark
                                                outerSize="10px"
                                                innerSize="6px"
                                                bColor="#000"
                                                type='checkbox'
                                                checked={selectedValue === item ? true : false}
                                                onClick={() => {
                                                    setSelectedValue(item)
                                                }}

                                            />
                                            <div className="item_name">By {item && item.split(".")?.length > 1 ? item.split(".")[1] : item}</div>
                                        </li>
                                    )
                                })}
                            </ul>
                            {/* </div> */}
                        </div>
                        <Gap h="1.643rem" />
                        <div style={{ marginTop: 'auto', display: 'flex', flexDirection: 'row' }}>
                            <Button
                                primary={true}
                                width="8rem"
                                mR="1rem"
                                disabled={disabled}
                                onClick={() => {
                                    if (disabled) return
                                    get_data_from_server();
                                    close(option, popupKey);
                                }}>Show Graph</Button>

                            <Button
                                width="8rem"
                                bgColor={'#e6e6e6'}
                                onClick={() => {
                                    set_acction_name(undefined);
                                    close(option, popupKey);
                                }}>Cancel</Button>
                        </div>
                    </React.Fragment>
                )}
            </Wrapper>
        </div >
    )
};




const mapStateToProps = (state, props) => {
    const drill_filters_cache = state?.filterReducer?.drill_filter_cache
    return {
        filters_cache: state.filterReducer.filters_cache,
        mtd_ytd_options_cache: state.filterReducer.mtd_ytd_options_cache,
        dimensionByEntity: state.NewTableReducer.dimensionByEntity,
        dimensionByHierarchy: state.dataHierarchy.dimensionByHierarchy,
        dataFromServer: state.reportReducer.dataFromServer,
        drill_down_data: state.reportReducer.drill_down_data,
        drill_filters_cache,
    }
};


const mapDispatchToProps = {
    dispatch_report_cell_clicked_info,
    get_dimension_by_hierarchy,
    apply_filters,
    getDimensionByEntity,
    hide_popus_iq,
    addBreadCrumb,
    getDataFromSecondApi,
    getDimensionByHierarchy,
    save_updated_drill_down_data,
    set_report_to_refresh,
    raise_drill_filter_cache,
    apply_link_filter
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index))