import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
    get_sql_history,
    // delete_sql_history 
} from '../../actions/sqlHistory';
import { dispatchHeaderInformation } from '../../actions/common';
import { _getDataFromReportingServer } from '../../utils'
import SharedTable from '../shared-table';

import { PageInnerWrapper } from '../hoc/styled.hoc'


const Wrapper = styled.div`
   width: 100%;
   box-sizing: border-box;
   padding: 0px 10px;
`;


const Index = (props) => {

    const { historyData } = props;
    console.log("🚀 ~ Index ~ historyData:", historyData)

    const [localData, setLocalData] = useState([]);

    useEffect(() => {
        let _page_info = {
            title: 'Your History List'
        }
        props.dispatchHeaderInformation(_page_info)
        props.get_sql_history();
    }, []);


    useEffect(() => {
        if (historyData) {
            if (historyData.results) {
                setLocalData(historyData.results)
            }
        }
    }, [historyData])


    const questionClick = (item) => {
        console.log("🚀 ~ questionClick ~ item:", item)
        // if (item) {
        //     _getDataFromReportingServer(item.question, undefined, undefined, undefined, undefined, undefined, props.history)
        // }
    }



    return (
        <Wrapper style={{ marginTop: '1rem' }}>
            <PageInnerWrapper padding="0px">
                <SharedTable
                notTextWrap={true}
                    isLoding={false}
                    // deleteAction={deleteHistory}
                    tableData={(localData && localData.length > 0) ? localData : []}
                    // tableData={(localData && localData.length > 0) ? localData : undefined}
                    // tableHead={["Question", "Created At", "Action"]}
                    tableHead={["Query", "Remarks", "Created At"]}
                    columnsToShow={["question", "remarks", "created_at",]}
                    isDateColumn={"created_at"}
                    maximumWidthOfColumn={{
                        "question": '60rem'
                    }}
                    columnAction={{
                        "question": questionClick
                    }}
                    column_body_styles={{
                        "question": {
                            // whiteSpace: 'break-spaces' ,
                            whiteSpace: 'normal' ,
                            maxWidth: '56rem'
                        }
                    }}
                />
            </PageInnerWrapper>
        </Wrapper>
    )
}


const mapStateToProps = state => {
    console.log("🚀 ~ mapStateToProps ~ state:", state)
    return {
        // historyData: state.favouriteReducer.historyData,
        historyData: state.sqlHistoryReducer.historyData,
    }
}

export default withRouter(connect(mapStateToProps, {
    dispatchHeaderInformation, get_sql_history,
    //  delete_sql_history
})(Index));
