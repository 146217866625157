import { dispatchAction, simpleDispatch_get ,processPostRequest, getDataFromURL, simpleDispatch_post } from './helper';
import * as actionTypes from '../action-types/domain_config';
import { constants } from '../utils/constants';




export const getDomainConfigByPageName = (pageName) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.DOMAIN_CONFIG.POINT + constants.END_POINTS.DOMAIN_CONFIG.GET_DOMAIN_CONFIG_BY_PAGE_NAME + `?pageName=${pageName}`;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_DOMAIN_CONFIG_BY_PAGE_NAME);

};
