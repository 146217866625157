
export const V2_GET_FILTER_DROPDOWN_DATA_SUCCESS = 'modules/v2/filter/get_dropdown_data';
export const V2_APPLY_FILTERS = 'modules/v2/filter/set_filters';
export const V2_CLEAR_SINGLE_FILTER = 'modules/v2/filter/clear_single_filter';
export const V2_CLEAR_ALL_FILTERS = 'modules/v2/filter/clear_all_filters';
export const V2_MARK_YTD_MTD = 'modules/v2/filter/mark_mtd_ytd';
export const V2_MARK_YTD_MTD_DATA_RECEIVED = 'modules/v2/filter/ytd_received';
export const V2_EXPAND_OPTION = 'modules/v2/filter/expand_option';
export const V2_RAISE_BACKEND_FILTER_FOR_STORE = 'modules/v2/filter/raise_backend_filter';

export const DESTORY_GLOBAL_FILTER_RAISE = 'modules/v2/filter/destroy_global_filter_raise';
export const MT_DELETED_FILTER = 'modules/v2/filter/destroy_deleted_filter';
export const RAISE_DRILL_FILTER_CACHE = 'modules/v2/filter/raise_drill_filter_cache';
export const CLEAR_DRILL_FILTER_CACHE = 'modules/v2/filter/clear_drill_filter_cache'
export const V2_GET_FILTER_DISTINCT_DATA = 'modules/v2/filter/get_filter_distinct_data'
export const UPDATE_REPORT_FILTER = 'modules/v2/filter/update_report_filter';
export const UPDATE_REPORT_FILTER_INFO = 'modules/v2/filter/update_report_filter_info';


export const GET_FILTER_OPTIONS_TABLE_COLUMNS_V1 = 'modules/v2/filter/get_filter_options_table_columns_v1';

// V2 link filter implementation 
// 2 June 2022
export const APPLY_LINK_FILTER = 'modules/v2/filter/apply_link_filter';
export const CLEAR_LINK_FILTER = 'modules/v2/filter/clear_link_filter';


//
export const GET_ADVANCE_DATE_CONFIG_SUCCESS = 'module/v2/get_ADVANCE_date_config_success';
export const SAVE_ADVANCE_DATE_CONFIG_SUCCESS = 'module/v2/save_ADVANCE_date_config_suceess';


export const STORE_GLOBAL_FILTER_WIDGETS_IDS =  'module/v2/STORE_GLOBAL_FILTER_WIDGETS_IDS';

export const REMOVE_GLOBAL_FILTER_WIDGETS_ID =  'module/v2/REMOVE_GLOBAL_FILTER_WIDGETS_ID';
