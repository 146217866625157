/**
 * this module will provide the keys used at the backend so we can use the same keys at front end too. What are the keys? Key is the property variable used
 * at the backend, for example, column meta data is in format { name: { data_type: 1, sort_order: 12 }}, here, data_type and sort_order are the keys
 */

module.exports = {

    COLUMN_META_DATA: {
        DATA_TYPE: 'data_type',
        DB_DATA_TYPE: 'db_data_type',
        COLUMN_ORDER: 'column_order',
        DB_COLUMN: 'db_column',
        ACTUAL_VALUE: 'actual_value',
        /** the display value means the value which will be shown to user */
        DISPLAY_VALUE: 'display_value',

    },

    CHART_CONFIG: {
        parameters: 'parameters',
        xac: 'xac',
        yac: 'yac',
        chart_type: 'chart_type',
        render_mode: 'render_mode',
        comparisons: 'comparisons',
        title: 'title',
        identifier: 'identifier',
        id: 'id',
        question: 'question'
    },

    TABLE_CONFIG: {
        group_row_identifier: 'nf-reserved-row-group'
    },

    IGNORED_COLUMN_NAME: 'nfIgnoredColumn',

    QUERY_GENERATOR_CONFIG: {

    }
};