import * as actions from '../action-types/demo-request'


const initialState = {
    type: actions.DEMO_REQUEST_INIT,
    requestList: undefined,
    timeSlotList: undefined,
    bookedList: undefined,

};


const DemoRequestReducer = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }


    switch (action.type) {

        case actions.GET_DEMO_REQUEST_LIST:
            return {
                ...state,
                type: action.type,
                requestList: action.data
            };

        case actions.GET_ALL_TIME_SLOT_LIST:
            return {
                ...state,
                type: action.type,
                timeSlotList: action.data
            }
        
        case actions.GET_BOOKED_INFO_BY_CURRENT_DATE:
                return {
                    ...state,
                    type: action.type,
                    bookedList: action.data
                }
        case actions.DELETE_DEMO_REQUEST:
                return {
                    ...state,
                    type: action.type,
                    requestList: state.requestList.filter((item) => item.id !== action.data)
                }
        case actions.APPROVE_DEMO_REQUEST:
        case actions.EDIT_DEMO_REQUEST:
            return {
                ...state,
                type: action.type,
                requestList: state.requestList.filter((item) => item.id !== action.data)
            }

        default:
            return {...state}
    }
}

export default DemoRequestReducer;