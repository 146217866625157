import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as _ from 'lodash';
import { getAllReports, set_report_to_refresh } from '../../actions/report';
import { clone_report, delete_report, get_all_reports } from '../../actions/report.helper';
import { getMenus } from '../../actions/menu.group';
import { saveReport } from '../../actions/report';
import { clear_all_filters, apply_filters, } from '../../actions/filter';
import { setActiveEditReport, clearActiveEditReport } from '../../actions/report.helper';
import { constants } from '../../utils/constants';
import NewAddSvg from '../svg/add.circle'
import { _getDataFromReportingServer, makeCopyReport, sort_report_list, get_matched_parm_from_query, reportFormatToSave, get_report_in_list_formatted_mode, getPermissionById, getParameterByName } from '../../utils';
import { Wrapper, ReportItemWrapper, TableHeaderWrapper } from './report.editor.styled';
import DropDown from '../drop-down';
import CardAction from '../action-card';
import TitleHeader from '../title-header';
import { dispatchHeaderInformation } from '../../actions/common';
import ParameterizedPopup from './p.field'
import * as enums from '../../utils/enums';
import { showPopup, } from '../../utils';
import { set_active_report_for_edit, clear_active_edit_report } from '../../actions/report_builder';
import { REPORT_TYPES } from '../../shared-with-fe/constants';
import SharedTable from '../shared-table';
import { PageInnerWrapper } from '../hoc/styled.hoc';
import ReportListHeader from './header';
import styled from 'styled-components';
import { getFromSession, saveInSession } from '../../utils/session.helper';
import { COMPONENT_IDS } from '../../utils/elementids';
import { getMenuGroups } from '../../actions/menu.group'
import RippleEffect from '../hoc/ripple-effect';
import ToggleIcon from '../svg/v2-icons/toggle';
import SearchBar from '../hoc/SearchBar';
import ReportIcon from '../svg/icons-v2/reportIcon';
import DashboardListSkeleton from '../Skeleton/dashoarad-skeleton-grid';




const AddButton = styled.div`
    width: 3rem;
    height: 3rem;
    font-size: 2rem;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #008AC7;
    cursor: pointer;
    box-shadow: 2px 3px 4px #cfc0c0;
    svg{
        fill: #fff;
    }
`;

const ReportEditor = props => {

    const { saveReport, setActiveEditReport, getAllReports, clear_all_filters, apply_filters, menuPermissions } = props;

    const { history } = props;
    const { location } = history;


    const [allReports, setAllReports] = useState(undefined);

    const [parameterized_fields_data, set_parameterized_fields_data] = useState(undefined);

    const [view_report_type, set_view_report_type] = useState('grid');

    const [local_all_report, set_local_all_report] = useState(undefined);
    const [searchValue, setSearchValue] = useState(undefined);
    const [isLoding, setIsloding] = useState(true);


    const isGridView = view_report_type === 'grid';

    // const clone_all_reports = props.all_reports ? JSON.parse(JSON.stringify(props.all_reports)) : [];


    useEffect(() => {
        if( !props.all_reports) return;
        const report_type = getParameterByName("type", location.search) || "report"

        // let's add menu group name for each report
        const cloned_all_report = props.all_reports ? JSON.parse(JSON.stringify(props.all_reports)) : [];

        //;let's filter only reports
        const filterd_reports = (cloned_all_report || []).filter(report => {
            if (report_type === "cache_editor") {
                if (report.type === "cache_editor") return true;
                else return false;
            }
            else {
                if (report.type === REPORT_TYPES.CONFIG_QUERY_BUILDER || report.type === REPORT_TYPES.SQL) return true;
                else return false;
            }
        }) || [];

        const get_menu_name_by_id = (menu_id) => {
            const m = props.menu_group?.find(m => m.id === menu_id);
            if (m && m.name) {
                return m.name;
            } else {
                return "other"
            }
        }

        filterd_reports.forEach((report) => {
            const menu_id = report.menu_group_id ? report.menu_group_id : 'nfx__other';
            const menu_name = get_menu_name_by_id(menu_id)
            report.menu_group_name = menu_name;
        })
        // if (props.all_reports?.length > -1) {
        //     setIsloding(false);
        // }

        set_local_all_report(filterd_reports)
    }, [props.all_reports, props.menu_group])

    useEffect(()=>{
        if (allReports?.length > -1) {
            setIsloding(false);
        }
    },[allReports])


    useEffect(() => {
        if(!local_all_report) return;
        local_sort('last_updated', local_all_report)
    }, [local_all_report]);

    const local_sort = (key, report) => {
        const report_list = report ? report : allReports;
        setAllReports(sort_report_list(report_list, key) || [])
    }


    const toggle_view_mode = async () => {
        const mode_to_use = view_report_type === 'list' ? 'grid' : 'list';
        saveInSession('DASHBOARDS_LIST_MODE', mode_to_use)
        set_view_report_type(mode_to_use)
    }



    const on_search = (e) => {

        const report_type = getParameterByName("type", location.search) || "report"

        const value = e.target.value;
        const s_value = value && value.toLocaleLowerCase().trim();

        const filterd_report = ((local_all_report || []).filter(report => {
            if (report_type === "cache_editor") {
                if (report.type === "cache_editor") return true;
                else return false;
            }
            else {
                if (report.type === REPORT_TYPES.CONFIG_QUERY_BUILDER || report.type === REPORT_TYPES.SQL) return true;
                else return false
            }
        }) || []).filter((report) => {
            if ((report?.name && report?.name?.toLocaleLowerCase().indexOf(s_value) > -1) || report?.menu_group_name?.toLocaleLowerCase().indexOf(s_value) > -1) return true;
            else return false
        });
        setAllReports(filterd_report)
    }



    useEffect(() => {
        const report_type = getParameterByName("type", location.search) || "report"
        const TITLE = report_type === "cache_editor" ? "YOUR DATA CACHE" : "Your Reports"
        const header = {
            title: TITLE,
            // extraComponent: <ReportListHeader
            //     view_mode={view_report_type}
            //     on_search={on_search}
            //     search_title={report_type === "cache_editor" ? "Search Cache" : "Search Report"}
            //     addNewAction={addNewAction}
            //     toggle_view_mode={toggle_view_mode} />
        }
        props.dispatchHeaderInformation(header)
    }, [local_all_report, view_report_type]);


    useEffect(() => {
        const dashboard_type_from_session = getFromSession('DASHBOARDS_LIST_MODE')
        if (dashboard_type_from_session) {
            set_view_report_type(dashboard_type_from_session)
        }
    }, [])




    useEffect(() => {
        // getAllReports();
        if ((!props.all_reports || props.all_reports.length === 0)) {
            props.get_all_reports();
            props.getMenuGroups()
        }
    }, [])

    const removeReportItem = (index, id) => {

        const tempReports = [...allReports];
        const deleted = [];

        tempReports && tempReports.forEach(report => {
            if (id && (report.id === id) && !report['deleted']) {
                deleted.push(report);
                const _index = tempReports.findIndex((r) => r.id == id);
                // console.log("_index", _index)
                tempReports[_index]['deleted'] = true
            }
        });

        if (deleted && deleted.length > 0) {
            props.delete_report(id);
        }
        setAllReports(tempReports);
    };


    const addNewAction = () => {

        const report_type = getParameterByName("type", location.search) || "report";
        const path = report_type === "cache_editor" ? "/cache_editor" : "/config_editor";

        props.history.push(path)
        props.clearActiveEditReport()
        props.clear_active_edit_report()
    }


    const copyReport = reportToBeCopied => {

        // let tempCopiedReport = makeCopyReport(reportToBeCopied);
        // props.saveReport(reportFormatToSave(tempCopiedReport));

        props.clone_report(reportToBeCopied?.id, 'report')

    };


    const view_report = (report, parameterized_values, filter_default_value_json) => {

        const report_item = report.report_items[0];
        const scheduleReportId = report.id;
        const db_info_id = report_item.db_info_id ? report_item.db_info_id : undefined;
        clear_all_filters();
        if (report.type === 'config_query_builder' || report.type === 'data_source' || report.type === 'cache_editor' || report.type === 'sql') {
            _getDataFromReportingServer(undefined, scheduleReportId, undefined, report_item.id, report.type, undefined, props.history, undefined, db_info_id, parameterized_values, filter_default_value_json)
        }
        props.data && props.data.func && props.data.func.setShowFull && props.data.func.setShowFull(false)

    }


    const open_parameterized_fields_popup = (report, fields, filter_default_value_json) => {
        showPopup('Please fill out the below form', undefined, enums.default.popupType.element_with_header, ParameterizedPopup, {
            parameters_fields: fields ? Object.keys(fields) : undefined,
            report_name: report.name,
            view_function: (_field_data_) => {
                view_report(report, _field_data_, filter_default_value_json)
                if (props.dataFromServer && props.dataFromServer[report.id]) {
                    props.set_report_to_refresh(report.id)
                }
            },
        },
            undefined,
            undefined,
            undefined,
            {
                width: '27rem'
            })
    }


    const open_parametrize_popup_sql = (report, query, parameters, filter_default_value_json) => {

        const final_data_obj = parameters ? JSON.parse(JSON.stringify(parameters)) : {};
        const parameters_keys = Object.keys(parameters);

        showPopup('Please fill out the below form', undefined, enums.default.popupType.element_with_header, ParameterizedPopup, {
            parameters_fields: parameters_keys,
            report_name: report.name,
            view_function: (_field_data_) => {
                Object.keys(_field_data_).forEach(k => final_data_obj[k].value = _field_data_[k]);
                view_report(report, final_data_obj, filter_default_value_json)

                if (props.dataFromServer && props.dataFromServer[report.id]) {
                    props.set_report_to_refresh(report.id)
                }
            },
        },
            undefined,
            undefined,
            undefined,
            {
                width: '27rem'
            }
        )
    }


    const view_action = (report) => {

        const report_type = report.type;
        const report_item = report?.report_items?.length > 0 && report?.report_items?.[0];
        const criteria = report_item?.criteria;
        const filter_default_value_json = JSON.parse(report?.filter_default_value_json || "{}");
        const parameterized_fields = criteria && get_matched_parm_from_query(criteria);
        const sql_parameterized_fields = report_item.sql_query && get_matched_parm_from_query(report_item.sql_query);

        if (parameterized_fields && Object.keys(parameterized_fields).length > 0) {
            open_parameterized_fields_popup(report, parameterized_fields, filter_default_value_json)
        }
        else if (sql_parameterized_fields && Object.keys(sql_parameterized_fields).length > 0 && report_type === 'sql') {
            open_parametrize_popup_sql(report, report_item.sql_query, sql_parameterized_fields, filter_default_value_json)
        }
        else {
            view_report(report, undefined, filter_default_value_json)
        }
    }



    const edit_action = (report) => {


        if (report.type === "cache_editor") {
            props.history.push(`/cache_editor?report_id=${report.id}`)
            setActiveEditReport(report);

        }
        else if (report.type === 'data_source') {
            props.history.push(`/data_source_editor?report_id=${report.id}`)
            setActiveEditReport(report);
        } else {
            props.history.push(`/config_editor?report_id=${report.id}`)
            setActiveEditReport(report);
        }

        props.set_active_report_for_edit(report.id, report)
    }




    const edit_report_id = COMPONENT_IDS.SHARED_ACTIONS.EDIT_REPORT;
    const share_report_id = COMPONENT_IDS.SHARED_ACTIONS.SHARE_REPORT;
    const create_report_id = COMPONENT_IDS.SHARED_ACTIONS.CREATE_REPORT;
    const delete_report_id = COMPONENT_IDS.SHARED_ACTIONS.DELETE_REPORT;
    const clone_report_id = COMPONENT_IDS.SHARED_ACTIONS.CLONE_REPORT;

    const show_edit_report = getPermissionById(edit_report_id, menuPermissions);
    const show_clone_report = getPermissionById(clone_report_id, menuPermissions);
    const show_delete_report = getPermissionById(delete_report_id, menuPermissions);
    const show_add_report = getPermissionById(create_report_id, menuPermissions);
    const report_list_to_use = get_report_in_list_formatted_mode(allReports)
    const report_type = getParameterByName("type", location.search) || "report"
    const add_new_report_title = report_type === "cache_editor" ? "Add New Data Cache" : "Add New Report";


    return (
        <Wrapper>

            {!isLoding && <TableHeaderWrapper backgroundColor={'#fff'} >
                <div style={{ margin: isGridView ? '0.6rem auto 0.5rem' : 0 }}>
                    <SearchBar
                        value={searchValue}
                        type="text"
                        placeHolder={'Search your report'}
                        onChange={(e) => {
                            setSearchValue(e.target.value)
                            on_search(e)
                        }}
                        borderType={isGridView ? 'border-radius' : 'border-bottom'}
                    />
                </div>

                <RippleEffect
                    icon={<ToggleIcon size="1.4rem" height="1.4rem" />}
                    Click={() => toggle_view_mode()}
                    tooltip={view_report_type === 'list' ? "Toggle to grid view" : "Toggle to list view"}
                />
            </TableHeaderWrapper>}

            <PageInnerWrapper height={isGridView ? 'calc(100vh - 9.8rem)' : 'calc(100vh - 8.6rem)'} padding='5px'>
                {isGridView &&
                    <>
                        {!isLoding ? <ReportItemWrapper>

                            {show_add_report && (
                                <CardAction
                                    parentClick={() => {
                                        addNewAction()
                                    }}
                                    title={add_new_report_title || "Add New Report"}
                                    action={false}
                                    titleCenter={true}
                                    Image={<NewAddSvg size='3.9rem' height='3.9rem' color='#fff' />}
                                    addTitleColor={"#fff"}
                                    isOverlay={false}
                                    titleFontSize={'1.2rem'}
                                />
                            )}



                            {
                                allReports && allReports.length > 0 && allReports.filter(data => !data.deleted).map((report, index) => {

                                    return (
                                        <CardAction
                                            key={"report_list" + index}
                                            isOverlay={true}
                                            deleteAction={show_delete_report ? () => removeReportItem(index, report.id) : undefined}
                                            copyAction={show_clone_report ? () => copyReport(report) : undefined}
                                            editAction={show_edit_report ? () => {
                                                edit_action(report)
                                            } : undefined}
                                            viewAction={() => {
                                                // const parameterized_fields = report.parameterized_fields;
                                                view_action(report)
                                            }}

                                            title={report && report.name ? report.name : null}
                                            date={report.created_at ? report.created_at : null}
                                            action={true}
                                            // Image={<img src={constants.CONTEXT_PATH + "./icon/report_bg.png"} width="50px" />}
                                            Image={<ReportIcon size="3rem" height="3rem" color="#fff" />}
                                        />
                                    )
                                })
                            }


                        </ReportItemWrapper> : <DashboardListSkeleton />}

                    </>

                }


                {view_report_type === 'list' && (
                    <>
                        <SharedTable
                            isLoding={isLoding}
                            searchValueonSearch={searchValue}
                            actionsTitles={{
                                "deleteAction": {
                                    title: "Delete The Report",
                                    message: "Are you sure you want to delete this report ?"
                                },

                            }}

                            pointerRow={true}
                            height={'calc(100vh - 118px)'}
                            action={false}
                            fullTime={true}
                            // actionOnHover={true}
                            viewAction={(report) => {
                                view_action(report)

                            }}
                            cloneAction={show_clone_report ? (report) => {
                                copyReport(report)
                            } : undefined}
                            editAction={show_edit_report ? (report) => {
                                edit_action(report);
                            } : undefined}
                            deleteAction={show_delete_report ? (report, index) => {
                                removeReportItem(index, report.id)
                            } : undefined}

                            rowClickAction={(report) => {
                                view_action(report)
                            }}

                            tableHead={["Group Name", "Report Name", "DB Mode", "Display Fields", 'Author', 'Created At', 'Modified At', 'Actions']}
                            columnsToShow={["menu_group_name", "name", 'report_type', 'display_fields', 'created_user_name', 'created_at', 'modified_at']}
                            maximumWidthOfColumn={{
                                "Group Name": '6rem',
                                "menu_group_name": '6rem',
                                'Report Name': '8rem',
                                'report_type': '8rem',
                                "name": '6rem',
                                "DB Mode": '6rem',
                                'Display Fields': '16rem',
                                'display_fields': '16rem',
                                'Author': '8rem',
                                'created_user_name': '8rem',
                                'Created At': '8rem',
                                'created_at': '8rem',
                                'Modified At': '8rem',
                                'modified_at': '8rem',
                                'Actions': '10rem',
                            }}
                            columnTextWithWrapper={['created_user_name', 'Action']}
                            sortColumns={{
                                "Group Name": {
                                    type: "string",
                                    dataKey: 'menu_group_name'
                                },
                                "Report Name": {
                                    type: "string",
                                    dataKey: 'name'
                                },
                                "DB Mode": {
                                    type: "string",
                                    dataKey: 'report_type'
                                },
                                "Author": {
                                    type: "string",
                                    dataKey: 'created_user_name'
                                },
                                "Created At": {
                                    type: "date",
                                    dataKey: 'created_at'
                                },

                                "Modified At": {
                                    type: "date",
                                    dataKey: 'modified_at'
                                },
                            }}
                            column_body_styles={{
                                "name": {
                                    fontWeight: 'bold',
                                    fontSize: '.8rem',
                                    textTransform: 'uppercase',

                                }
                            }}
                            tableData={((report_list_to_use || []).filter(data => !data.deleted)) || []}
                            isDateColumn={"created_at"}
                            date_columns={['created_at', 'modified_at']}
                        />

                        {show_add_report && (
                            <div style={{ position: 'absolute', zIndex: 4, bottom: '5rem', right: '4rem' }}>
                                <AddButton title="Create New Report" onClick={() => {
                                    addNewAction()
                                }}>
                                    <NewAddSvg size="2rem" height="2rem" color="#fff" />
                                </AddButton>
                            </div>
                        )}
                    </>
                )}



                {/* {!props.reports && !allReports && <p style={{ textAlign: 'center' }}>Please wait...</p>} */}
            </PageInnerWrapper>
        </Wrapper>
    );
};

const mapStateToProps = state => {

    const dataFromServer = state.reportReducer.dataFromServer;
    const { reports } = state.reportReducer;
    const { menus } = state.menuGroupReducer;
    const all_reports = state.reportHelperReducer.all_reports;
    const menuPermissions = state.permissionReducer?.permissions;
    const menu_group = state.menuGroupReducer?.menu_groups_cache?.['report'];

    return { reports, menus, dataFromServer, all_reports, menuPermissions, menu_group };

};

export default withRouter(connect(mapStateToProps, { clone_report, delete_report, getMenuGroups, get_all_reports, set_report_to_refresh, clear_active_edit_report, set_active_report_for_edit, clearActiveEditReport, dispatchHeaderInformation, saveReport, getAllReports, getMenus, setActiveEditReport, clear_all_filters, apply_filters })(ReportEditor));