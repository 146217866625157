import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ChevronDown from "../svg/chevron.down";

// Wrapper for the whole component
const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

// Input wrapper to align the icon
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  padding-right: 30px; /* Space for icon */
  border: 1px solid #ccc;
  border-radius: 6px;
  box-sizing: border-box;
  text-transform: capitalize;
  box-sizing: border-box;
  &:hover {
    border: 1px solid #afc4e3;
  }
  &:focus {
    outline: none;
    border: 2px solid #007fff;
  }
`;

// Dropdown icon styling
const DropdownIcon = styled.div`
  position: absolute;
  right: 6px;
  cursor: pointer;
  font-size: 10px;
  color: #888;
`;

const Dropdown = styled.div`
  position: absolute;
  width: ${(props) => props?.width + "px" || "100%"};
  top: ${(props) => props?.top + "px" || "0px"};
  left: ${(props) => props?.left + "px" || "0px"};
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 6px 6px;
  background-color: white;
  max-height: 150px;
  overflow-y: auto;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  /* z-index: 10; */
  z-index: 1220;
  text-transform: capitalize;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    background-color: #3f3f3f;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f3f3f3;
    border: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #dbdbdb;
    border-radius: 10px;
    height: 4px;
  }
  &::-webkit-scrollbar:horizontal {
    background-color: #3f3f3f;
    height: 4px;
  }
`;

const DropdownItem = styled.div`
  padding: 4px 8px;
  cursor: pointer;
  background-color: ${({ isHighlighted }) =>
    isHighlighted ? "#f2f2f2" : "white"};

  &:hover {
    background-color: #eaeaea;
  }
`;

const OverlayMain = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 1200;
  position: fixed;
  top: 0px;
  left: 0px;
  /* background: #ff0000; */
  background: #ff000000;
`;

const SelectInput = ({ options, onChange, placeholder, initialValue }) => {
  const select_input_ref = useRef(null);
  const [inputValue, setInputValue] = useState(initialValue || '');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [dropdown_position, set_dropdown_position] = useState(undefined);

  useEffect(() => {setInputValue(initialValue ? initialValue : undefined)},[initialValue])

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    onChange(value); // Pass the input value to the parent component
    if (value) {
      setFilteredOptions(
        options?.filter((option) =>
          option.toLowerCase().includes(value.toLowerCase())
        )
      );
      setIsDropdownVisible(true);
    } else {
      setFilteredOptions([...options]);
      setIsDropdownVisible(true);
    }
  };

  const handleOptionClick = (option) => {
    setInputValue(option);
    onChange(option); // Pass the selected option to the parent component
    setIsDropdownVisible(false);
  };

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const open_dropdown_menu = (e, id) => {
    const element = select_input_ref?.current;
    if (!element) return;

    const dropdown_height = 180;
    // const element = document.getElementById("id");
    const el_bounding = element?.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const element_height = el_bounding?.height;
    const element_left = el_bounding?.left;
    const element_top = el_bounding?.top;
    let drop_down_top_to_use = element_top + element_height;
    if (windowHeight - drop_down_top_to_use <= dropdown_height) {
      drop_down_top_to_use =
        element_top + element_height - dropdown_height - 10;
    }
    set_dropdown_position({
      top: drop_down_top_to_use,
      left: element_left,
    });
  };

  return (
    <Wrapper ref={select_input_ref}>
      <InputWrapper>
        <Input
          id={"custom_select_cum_input"}
          placeholder={placeholder || ""}
          initialValue={initialValue || ""}
          value={inputValue || ""}
          onChange={handleInputChange}
          onBlur={() => setTimeout(() => setIsDropdownVisible(false), 150)} // Delay to allow item selection
          onFocus={(e) => {
            setIsDropdownVisible(true);
            open_dropdown_menu(e);
          }}
          // onClick={(e) => {
          //     const dropdown_height = 180;
          //     const element = document.getElementById('custom_select_cum_input');
          //     const el_bounding = element.getBoundingClientRect();
          //     const windowHeight = window.innerHeight;
          //     const element_width = el_bounding?.width;
          //     const element_height = el_bounding?.height;
          //     const element_left = el_bounding?.left;
          //     const element_top = el_bounding?.top;
          //     let drop_down_top_to_use = (element_top + element_height);
          //     if ((windowHeight - drop_down_top_to_use) <= dropdown_height) {
          //         drop_down_top_to_use = ((element_top + element_height) - dropdown_height) - 10
          //     }
          //     set_dropdown_position({
          //         top: drop_down_top_to_use,
          //         left: element_left,
          //         width: element_width - 14,
          //     })
          // }}
        />
        {/* Dropdown icon */}
        {/* <DropdownIcon onClick={toggleDropdown}>▼</DropdownIcon> */}
        <DropdownIcon onClick={toggleDropdown}>
          <ChevronDown size={".7rem"} height={".7rem"} color="#bbbaba" />
        </DropdownIcon>
      </InputWrapper>
      {isDropdownVisible && filteredOptions?.length > 0 && (
        <OverlayMain
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDropdownVisible(false);
          }}
        >
          <Dropdown
            top={dropdown_position?.top}
            left={dropdown_position?.left}
            width={select_input_ref?.current?.clientWidth}
          >
            {filteredOptions.map((option, index) => (
              <DropdownItem
                width={dropdown_position?.width + "px"}
                key={index}
                onMouseDown={() => handleOptionClick(option)}
              >
                {option}
              </DropdownItem>
            ))}
          </Dropdown>
        </OverlayMain>
      )}
    </Wrapper>
  );
};

export default SelectInput;
