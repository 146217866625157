import React, { PureComponent } from 'react';
import { RadialBarChart, RadialBar, Tooltip } from 'recharts';
import { withRouter } from 'react-router-dom';

class SimpleRadialBarChart extends PureComponent {

    render() {

        const { data, yDataKeys, widthOfChart, colorForYData, heightOfChart, hide_tooltip } = this.props;


        return (
            <div id='chart' style={{ position: 'relative', width: widthOfChart ? (widthOfChart - 30) : '93.1vw', textTransform: 'capitalize' }}>

                {
                    colorForYData && yDataKeys && yDataKeys.length > 0 && yDataKeys.map((yData, mainIndex) => {
                        return (
                            <RadialBarChart
                                width={widthOfChart}
                                height={heightOfChart}
                                cx={widthOfChart / 2}
                                cy={(heightOfChart / 2)}
                                innerRadius={20}
                                outerRadius={widthOfChart / 7}
                                barSize={10}
                                data={data}
                            >
                                {!hide_tooltip && (<Tooltip />)}

                                <RadialBar
                                    minAngle={15}
                                    endAngle={-135}
                                    startAngle={135}
                                    cornerRadius={10}
                                    maxAngle={255}
                                    label={{ position: 'insideStart', fill: '#000' }}
                                    background
                                    clockWise={true}
                                    dataKey={yData}
                                />

                                {/* <Legend 
                                     displayKey="Speciality" 
                                     align="left" 
                                     iconType={"circle"} 
                                     iconSize={10} 
                                     width={120}  
                                     layout='vertical' 
                                     verticalAlign='middle' 
                                     wrapperStyle={style} 
                                     /> */}

                            </RadialBarChart>
                        )
                    })
                }

            </div>
        );
    }
}

export default withRouter(SimpleRadialBarChart);
