import React, { useState } from 'react';
import styled from 'styled-components'
import TextBox from '../hoc/textBox';
import { Button } from '../hoc/button';
import SpreadsheetEditor from '../spreadsheet';
import Tab from '../tab'



const tabList = [
    "Add Single Record",
    "Add Multiple Records"
]



const Wrapper = styled.div`
    width: 35rem;
    background-color: #fff;
    border: 3px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 1rem;


    h1{

        font-size: 1.286rem;
        font-weight: 500;
        text-align: center;
        color: #1a1a1a;
        margin: 0px;
        display: block;
        width: 100%;
        margin-bottom: 18px;
    }
`;


const FormWrapper = styled.div`

    width: 100%;
    max-height: 30rem;
    overflow-y: auto;
    &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }
`;





const AddPopup = (props) => {

    const { close, popupKey, option, dbName, tableName, data } = props;
    const { addNewRecord, keys } = data;

    const [formValue, setFormValue] = useState({});
    const [activeTab, setActiveTab] = useState(tabList[0]);

    const onChange = (key, value) => {
        let _clone_form_value = JSON.parse(JSON.stringify(formValue))
        _clone_form_value[key] = value;
        setFormValue(_clone_form_value)


    }


    return (
        <Wrapper>
            <div style={{ marginBottom: '30px', width: '100%' }}>
                <Tab
                    tabList={tabList}
                    activeType={activeTab}
                    setActiveType={setActiveTab}
                    numberOfTab={(tabList && tabList.length > 0) ? tabList.length : undefined}
                />
            </div>


            <div style={{ padding: '0px 10px', boxSizing: 'border-box', width: '100%' }}>

                {activeTab === tabList[0] && (

                    <FormWrapper>
                        {keys && keys.length > 0 && keys.map((key) => {
                            return (
                                <div style={{ width: '100%', marginBottom: '10px' }}>
                                    <TextBox
                                        key={key}
                                        label={key}
                                        value={formValue && formValue[key] ? formValue[key] : undefined}
                                        onChange={(e) => {
                                            onChange(key, e.target.value)
                                        }}
                                    />
                                </div>
                            )
                        })}
                    </FormWrapper>

                )}


                {activeTab == tabList[1] && (

                    <SpreadsheetEditor
                        keys={keys}
                        save_function={(data) => {
                            addNewRecord(data)
                            close(option, popupKey)
                        }}
                        topTextBoxHide={true}
                        requiredKey={(keys && keys.length > 0) ? keys[0] : undefined}
                        close_function={() => {
                            close(option, popupKey)
                        }}
                    />
                )}


                {activeTab == tabList[0] && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '2rem', marginLeft: 'auto' }}>
                        <Button
                            width="6rem"
                            mR="1rem"
                            primary={true}
                            onClick={() => {
                                addNewRecord(formValue)
                                close(option, popupKey)

                            }}>Save</Button>
                        <Button width="6rem" bgColor={'#e6e6e6'}
                            onClick={() => {
                                close(option, popupKey)
                            }}
                        >Cancel</Button>
                    </div>
                )}
            </div>

        </Wrapper>
    )
}

export default AddPopup
