import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { hideSnackbar, showSnackbar } from "../../utils";

const Container = styled.div`
  &:focus-visible {
    outline: none;
  }
`;
const Menu = styled.ul`
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  z-index: 1000;
`;

const MenuItem = styled.li`
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 6px;
  background: ${({ selected }) => (selected ? "linear-gradient(to right, #3E9EFF, #737DFF)" : "#fff")};
  color: ${({ selected }) => (selected ? "#fff" : "#000")};

  &:hover {
    background: linear-gradient(to right, #3E9EFF, #737DFF);
    color: #fff;
  }
`;
const items = [
    // "Copy all Tables",
    // "Copy This Table Name",
    "Copy All Columns"];

const RightClickMenu = ({
    children,
    menuVisible,
    setMenuVisible,
    menuPosition,
    rightClickedData,
}) => {

    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
    const wrapperRef = useRef(null);

    const keysSeperatedByComma = (dataObj) => {
        const res = dataObj && Object.keys(dataObj)?.join(", ");
        return res;
    };

    const handleKeyDown = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (!menuVisible) return;

        if (event.key === "ArrowDown") {
            setSelectedItemIndex((prevIndex) => (prevIndex + 1) % items.length);
        } else if (event.key === "ArrowUp") {
            setSelectedItemIndex(
                (prevIndex) => (prevIndex - 1 + items.length) % items.length
            );
        } else if (event.key === "Enter" && selectedItemIndex !== -1) {
            handleItemClick(items[selectedItemIndex]);
        }
    };

    const handleItemClick = async (item) => {
        let data = "";
        switch (item) {
            // case "Copy all Tables":
            //     data = keysSeperatedByComma(rightClickedData?.tableData);
            //     break;
            // case "Copy This Table Name":
            //     data = rightClickedData?.selectedTable;
            //     break;
            case "Copy All Columns":
                data = keysSeperatedByComma(
                    rightClickedData?.tableData?.[rightClickedData?.selectedTable]
                );
                break;
            default:
                break;
        };

        try {
            if ('clipboard' in navigator) {
                await navigator.clipboard.writeText(data);
            } else {
                // Fallback for environments where clipboard API is not available
                const textArea = document.createElement('textarea');
                textArea.value = data;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand('copy');
                document.body.removeChild(textArea);
            }
        } catch (error) {
            console.error('Failed to copy text:', error);
        }

        // navigator.clipboard.writeText(data);
        setMenuVisible(false);
        setSelectedItemIndex(-1);
        showSnackbar({
            type: "success",
            message: "Copied Text To Clipboard"
        })
        setTimeout(() => {
            hideSnackbar()
        }, 2000);
    };

    const handleChildClick = (event) => {
        event.stopPropagation();
        setMenuVisible(false);
        setSelectedItemIndex(-1);
    };

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setMenuVisible(false);
            setSelectedItemIndex(-1);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <Container
            ref={wrapperRef}
            onKeyDown={handleKeyDown}
            tabIndex={0} // To make the div focusable for keyboard events
            onClick={handleClickOutside}
        >
            <div className="childWrapper" onClick={handleChildClick}>
                {children}
            </div>

            {menuVisible && (
                <Menu style={{ top: menuPosition.y, left: menuPosition.x }}>
                    {items.map((item, index) => (
                        <MenuItem
                            key={item}
                            selected={index === selectedItemIndex}
                            onClick={() => handleItemClick(item)}
                        >
                            {item}
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </Container>
    );
};

export default RightClickMenu;
