import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { apply_filters, clear_single_filter } from '../../actions/filter';
import { set_report_to_refresh } from '../../actions/report';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import { DATE_FILTER, MONTHS, FULL_MONTHS, YEAR, MONTHS_INDEXED } from '../../shared-with-fe/constants';
import { convert_date_to_dd_mm_yyyy_v1, get_date_filter_value, get_display_value_of_filter_v1, } from '../../utils';
import { quick_date_options } from './shared.function.js';


export const FilterdItemRow = styled.div`
    max-width: ${props => props.scrollHide ? undefined : props.width ? props.width : '30rem'};
    display: flex;
    padding: 0px 10px;
    box-sizing: border-box;
    flex-wrap: ${props => props.multi_line ? "wrap" : "nowrap"};
    overflow-x: auto;
    align-items: center;
    padding-left: 0px;
    
    &::-webkit-scrollbar {
        background-color:red;
        width:2px
    }
    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }
    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 2px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 2px;
    }
    .sub_text{
        color: #005d86;
        white-space: nowrap;
        font-size: .8rem;
        font-weight: 600;
    }
`;


export const Filterd = styled.div`
     max-width: 40rem;
     display: flex;
     align-items: center;
     justify-content: flex-start;
     box-sizing: border-box;
     margin: 2px 1px;
     font-weight: 500;
     position: relative;
     border-bottom: 1px solid #3a383847;
     padding: 2px 4px;
     background: #fff;
    
     .filter_name{
        font-size: .7rem;
        font-weight: 400;
        letter-spacing: normal;
        text-align: left;
        color: #005d86;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-bottom: 5px;
        min-width: 270px;
        strong{
            font-size: .7rem;
        }
     }
`;



const FilterDisplay = (props) => {

    const {
        id,
        width
    } = props;


    const filters = props.filters_cache && props.filters_cache[id];
    const [localFilter, setLocalFilter] = useState(undefined);

    const return_date_string_from_generic_date = (g_object) => {

        const custom_date_config = g_object?.['custom_date_config'];
        const single_date = g_object?.['single_date'];
        const range_start_date = g_object?.['range_start_date'];
        const range_end_date = g_object?.['range_end_date'];
        const month = g_object?.['month'];
        const year = g_object?.['year'];
        const type = g_object?.['type'];
        const single_type = g_object?.['single_type'];

        if (type === "custom_date" && single_type) {

            if (month > -1 && year && single_type === 'month') {
                return [MONTHS[month] + " " + year]
            }

            if (year && single_type === 'year') {
                return [year]
            }

            if (single_date && single_type === 'single_date') {
                var d = convert_date_to_dd_mm_yyyy_v1(single_date)
                return [d]
            }
        }

        if (type === "quick_date") {

            const value_key = g_object["quick_date"];
            const display_key = quick_date_options?.find((o) => o.value === value_key)?.label;

            return [display_key]
        }

        else if (range_start_date && range_end_date && type === "range") {
            const string = convert_date_to_dd_mm_yyyy_v1(range_start_date) + " To " + convert_date_to_dd_mm_yyyy_v1(range_end_date)
            return [string]
        }
        else if (custom_date_config) {
            var d = convert_date_to_dd_mm_yyyy_v1(custom_date_config)
            return [d]
        }
        return undefined;
    }



    useEffect(() => {

        const post_filter_data = {};

        if (filters && Object.keys(filters).length > 0) {
            Object.keys(filters).forEach(key => {

                if (key === 'date_filter') {
                    const dateFilters = filters["date_filter"];
                    Object.keys(dateFilters) && Object.keys(dateFilters).length > 0 && Object.keys(dateFilters).forEach(date_f_key => {
                        if (!post_filter_data[date_f_key]) post_filter_data[date_f_key] = {};
                        if (date_f_key === 'generic_date') {
                            if (dateFilters[date_f_key] && Object.keys(dateFilters[date_f_key]).length > 0) {
                                post_filter_data[date_f_key] = return_date_string_from_generic_date(dateFilters[date_f_key])
                            } else delete post_filter_data[date_f_key];
                        } else {
                            post_filter_data[date_f_key] = return_date_string_from_generic_date(dateFilters[date_f_key]);
                        }
                    })
                } else {
                    post_filter_data[key] = filters[key];
                }
            })
        }
        setLocalFilter(post_filter_data)

    }, [filters])



    return (
        <React.Fragment>
            {localFilter && Object.keys(localFilter).length > 0 &&
                <FilterdItemRow id="ddd" multi_line={props.multi_line} width={width}>
                    {localFilter && Object.keys(localFilter).map((key, index) => {
                        let __inner_f__ = localFilter[key] && Object.keys(localFilter[key]).length > 0 ? Object.keys(localFilter[key]) : [];

                        if (localFilter[key] && Object.keys(localFilter[key]).length > 0 && Object.keys(localFilter[key]) && !Array.isArray(localFilter[key])) {
                            return (
                                <React.Fragment>
                                    {__inner_f__ && __inner_f__.length > 0 && __inner_f__.map((j) => {
                                        let __v__ = localFilter[key][j] && Array.isArray(localFilter[key][j]) ? localFilter[key][j].join(",  ") : localFilter[key][j];
                                        if (j === "custom_date" && localFilter[key][j]) {
                                            __v__ = localFilter[key][j]["date_from"] + " To " + localFilter[key][j]["date_to"];
                                        }
                                        if (__v__ && __v__.length > 0) {
                                            const __key__for_display = (key && key.split(".").length > 1) ? key.split(".")[1].toLocaleUpperCase() : key.toLocaleUpperCase()
                                            return (
                                                <Filterd >
                                                    <div className="filter_name" >
                                                        <strong>{__key__for_display}</strong> {`: `} <br></br>{get_display_value_of_filter_v1(__v__)}
                                                    </div>
                                                </Filterd>
                                            )
                                        }
                                    })}
                                </React.Fragment>
                            )
                        }


                        if (localFilter[key] && Array.isArray(localFilter[key]) && localFilter[key].length > 0) {
                            const __v__ = localFilter[key] && localFilter[key].length > 0 && localFilter[key].join(",  ")
                            const __key__for_display = (key && key.split(".").length > 1) ? key.split(".")[1].toLocaleUpperCase() : key.toLocaleUpperCase();
                            if(props?.advance_date_setting?.length > 0 && key === "generic_date"){
                                return <></>
                            }
                            else{
                                return (
                                    <Filterd >
                                        <div className="filter_name" >
                                            <strong>{__key__for_display}</strong> {`: `} {get_display_value_of_filter_v1(__v__)}
                                        </div>
                                    </Filterd>
                                )
                            }
                            
                        }
                    })}
                </FilterdItemRow>
            }
        </React.Fragment>
    )
}


const mapStateToProps = (state, props) => ({
    filters_cache: state.filterReducer.filters_cache,
    advance_date_setting: state?.filterReducer?.advance_date_setting_cache?.[props?.id]

})

export default withRouter(connect(mapStateToProps, { apply_filters, set_report_to_refresh, clear_single_filter })(FilterDisplay))