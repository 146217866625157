
import styled from 'styled-components';






export const FooterWrapper = styled.div`
    width:  ${props => props.isMobile ? "100%" : 'calc(100vw - 50px)'} ;
    padding: ${props => props.padding || '5px 0px'};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: ${props => props.isMobile ? 'flex-start' : 'flex-end'};
    margin-top: auto;
    position:  ${props => props.isMobile ? 'relative' : props.position || undefined};
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-top: auto;
    max-height: ${props => props.isMobile ? '130px' : props.height ? props.height : '50px'};
    bottom: ${props => props.isMobile ? undefined : props.bottom || undefined};


    & > div{
        cursor: pointer;
        width: ${props => props.isMobile ? '100%' : '220px'};
        display: ${props => props.isMobile ? "block" : 'flex'};
        flex-direction: row;
        justify-content: flex-end;
        align-items: ${props => props.alignItem || 'center'};
        height: 100%;
        padding: ${props => props.childPadding || undefined};
        box-sizing: border-box;
        
    .powerd_by{
        font-size: 0.714rem;
        line-height: 0.929rem;
        padding-left: 9px;
        margin-bottom: 3px;
        color: rgb(0, 22, 31);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        margin: 0px;
    }
}

`;