import {  simpleDispatch_get, simpleDispatch_post, dispatchAction, raiseSuccess } from './helper';
import * as loaderTypes from '../action-types/loader';
import { constants } from '../utils/constants';
import * as actionTypes from '../action-types/nf_column_info_ad_tech'
import { post } from '../data.service';



export const getNfColumnInfoAdTech = (id) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.NF_COLUMN_INFO_AD_TECH.POINT + constants.END_POINTS.NF_COLUMN_INFO_AD_TECH.GET_NF_COLUMN_INFO_AD_TECH
    await simpleDispatch_get(dispatch, url, actionTypes.GET_NF_COLUMN_INFO_SUCCESS);
};


export const saveNfColumnInfo = (data, message) => async dispatch => {

    const post_data = {
        column_info : data
    }
    const url = constants.END_POINTS.API + constants.END_POINTS.NF_COLUMN_INFO_AD_TECH.POINT + constants.END_POINTS.NF_COLUMN_INFO_AD_TECH.SAVE_NF_COLUMN_INFO_AD_TECH

    try {
        dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);

        const response = await post(url, post_data);

        if (response && response.code === 200) {

            await dispatchAction(dispatch, actionTypes.SAVE_NF_COLUMN_INFO)
            await raiseSuccess(dispatch, {
                type: 'success',
                message: message ? message : "success"
            })
            await getNfColumnInfoAdTech()(dispatch)
        }

    } catch (error) {
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        const _message = typeof error === 'object' ? (error.custom_message ? error.custom_message : '') : error;

        raiseSuccess(dispatch, {
            type: 'error',
            title: 'ERROR',
            message: _message,
        })
    }

}