import * as actions from '../action-types/group_access';

const initialState = {

    type: actions.ACCESS_GROUP_INIT,
    accessGroupList: undefined,
    groupAccessList: undefined,
    access_group_info: undefined,
    reportAccessGroupData: undefined,
    dataPolicies: undefined, //GET_ALL_DATA_POLICY_SUCCESS,
    editDataPolicy: undefined//GET_DATA_POLICY_BY_ID_SUCCESS
};


const groupAccess = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }


    switch (action.type) {
        case actions.GET_ACCESS_GROUP_WITH_USERS:
            return {
                ...state,
                type: action.type,
                reportAccessGroupData: action.data
            }

        case actions.GET_ALL_DATA_POLICY_SUCCESS:
            return {
                ...state,
                type: action.type,
                dataPolicies: action.data
            }

        case actions.GET_DATA_POLICY_BY_ID_SUCCESS:
            return {
                ...state,
                type: action.type,
                editDataPolicy: action.data
            }

        case actions.CLEAR_EDIT_DATA_POLICY:
            return {
                ...state,
                type: action.type,
                editDataPolicy: undefined
            }

        case actions.GET_ACCESS_GROUP:
            return {
                ...state,
                type: action.type,
                accessGroupList: action.data
            }
        case actions.GET_GROUP_ACCESS_GROUP:
            return {
                ...state,
                type: action.type,
                groupAccessList: action.data
            }

        case actions.CLEAR_GROUP_ACCESS_GROUP:
            return {
                ...state,
                type: action.type,
                groupAccessList: undefined
            }


        case actions.GET_ACCESS_GROUP_INFO:
            return {
                ...state,
                type: action.type,
                access_group_info: action.data
            }

        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
            return { ...state }
    }
};

export default groupAccess;