import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const SuggestionList = styled.ul`
/* .suggestions-list { */
    position: absolute;
    top: ${props => (props?.position?.top + 10) + 'px'};
    left:  ${props => props?.position?.left + 'px'};
    background: #fff;
    /* border: 1px solid #ddd; */
    box-sizing: border-box;
    border: 1px solid #e7e7e7;
    list-style-type: none;
    padding: 0;
    margin: 0;
    z-index: 500;
    max-height: ${props => props.height || '200px'};
    overflow-y: auto;
    font-size: 0.8rem;
    border-radius: 6px;
    overflow-x: hidden;
    box-shadow:  ${props => props.boxShadow || '1px 2px 2px 4px #dbe8ffab'};
    border: 7px;
    padding: 6px;
    box-sizing: border-box;
    &::-webkit-scrollbar {
        background-color: #3F3F3F;
        width:3px;
    }

    &::-webkit-scrollbar-track {
        background-color: #F3F3F3;
        border: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dbdbdb;
        border-radius: 10px;
        height: 4px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color:  #3F3F3F;
        height: 4px;
    }

    li{
        padding: 8px;
        height: 30px;
        box-sizing: border-box;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        ${props => ({...props.style})}
        border-radius: 5px;
        max-width: 260px;
        /* width: 100%; */
        &:hover {
            background: #F3F3F3;
        }
    }
    .selected {
     background-color: #F3F3F3;
    }
    
`;

const SuggestionListHoc = (props) => {

    const { suggestionList, selectedIndex, position, handleSuggestionClick, prefix, height, style, boxShadow } = props;

    const suggestionBoxRef = useRef(null);

    useEffect(() => {
        if (selectedIndex >= 0 && suggestionBoxRef.current) {
            const selectedItem = suggestionBoxRef.current.children[selectedIndex];
            if (selectedItem) {
                const boxTop = suggestionBoxRef.current.scrollTop;
                const boxBottom = boxTop + suggestionBoxRef.current.clientHeight;
                const itemTop = selectedItem.offsetTop;
                const itemBottom = itemTop + selectedItem.clientHeight;
                if (itemBottom > boxBottom) {
                    suggestionBoxRef.current.scrollTop = itemBottom - suggestionBoxRef.current.clientHeight;
                } else if (itemTop < boxTop) {
                    suggestionBoxRef.current.scrollTop = itemTop;
                }
            }
        }
    }, [selectedIndex]);



    return (
        <SuggestionList ref={suggestionBoxRef} position={position} height={height} boxShadow={boxShadow} style={style || {}}>
            {suggestionList?.map((item, index) => (
                <li title={item} key={index} className={index === selectedIndex ? 'selected' : ''} onClick={() => handleSuggestionClick(item, true, index)} onMouseDown={(e) => e.preventDefault()} >
                    {/* {tagType === tagTypeKeys.TABLE
                ? <Table height='0.6rem' size='1.6rem' color='#8a0303' />
                : tagType === tagTypeKeys.COLUMN ? <ColumnIcon height='0.6rem' size='1.6rem' color='#8a0303' />
                    : <span className='tag'>SQL</span>
            } */}
                    {prefix}   {/* can be icon or some text   */}
                    {item}
                </li>
            ))}
            {/* </ul> */}
        </SuggestionList>
    )
}

export default SuggestionListHoc;