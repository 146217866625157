import React, { useEffect } from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';
import CloseSvg from '../svg/close';
import { _getDataFromReportingServer } from '../../utils';
import FilterComp from '../filter'
import { getFiltersForDropDowns } from '../../actions/filter';
import { connect } from 'react-redux'

const FilterdItemRow = styled.div`
    width: '100%';
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px 10px;
    box-sizing: border-box;
`;


export const Filterd = styled.div`
    /* max-width: 560px; */
     height: 1.571rem;
     border: solid 1px rgb(156, 174, 181);
     background-color: rgb(255, 255, 255);
     display: flex;
     align-items: center;
     justify-content: flex-start;
     padding: 0px 7px;
     margin-right: 10px;
     box-sizing: border-box;
     margin: 2px;

     .cross_mark{
        width: 20px;
        cursor: pointer;
        text-align: center;

     }

     .filter_name{
        font-size: 0.714rem;
        font-weight: normal;
        letter-spacing: normal;
        text-align: center;
        color: rgb(0, 22, 31);
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0px 5px;
        text-transform: capitalize;
        white-space: nowrap;
     }
`;




const FilterOption = (props) => {

    const { id, filters, getDataFromReportingServer, question, scheduleReportId, dateFilter, mainReportId, filterFromURL } = props;

    return (
        <div style={{ background: '#1e66af', padding: '5px 0px', boxSizing: 'border-box' }}>
            <FilterComp
                id={props.id}
                showFilter={true}
                filters={filters}
                dateFilter={dateFilter}
                getDataFromReportingServer={getDataFromReportingServer}
                question={question}
                report_id={scheduleReportId}
                closeFilter={props.closeFilter}
                width={'350'}
            />
        </div>
    )
}


export default connect(undefined, { getFiltersForDropDowns })(FilterOption)
