import { isDate, handle_conditional_formula, handle_formula } from '../utils/index.js';
import * as DataType from '../shared-with-fe/data.types';
import { checkDateAndSetDateTimeTypeToData, checkDataTypesForNumberAndDecimal, formulaHandler } from './index'
import { TABLE_CONFIG } from '../shared-with-fe/keys';
import { getFromSession } from './session.helper';
import { constants } from './constants'

export const temp_aligment = {
    [DataType.none]: {
        alignment: 'center',
        type: DataType.none,
    },
    [DataType.number]: {
        alignment: "right",
        type: DataType.number
    },
    [DataType.number_with_decimal]: {
        alignment: "right",
        type: DataType.number_with_decimal
    },
    [DataType.percent]: {
        alignment: "right",
        type: DataType.percent
    },
    [DataType.mobile]: {
        alignment: "left",
        type: DataType.mobile
    },
    [DataType.currency]: {
        alignment: "right",
        type: DataType.currency
    },
    [DataType.epoch]: {
        alignment: "left",
        type: DataType.epoch
    },
    [DataType.date]: {
        alignment: "left",
        type: DataType.date
    },
    [DataType.date_time]: {
        alignment: "left",
        type: DataType.date_time
    },
    [DataType.string]: {
        alignment: "left",
        type: DataType.string
    },
    [DataType.email]: {
        alignment: "center",
        // type: "/email"
    }
}


export const getFormulaColumns = mainFormulas => {

    const clone_formulas = mainFormulas ? [...mainFormulas] : []
    let columns = [];
    clone_formulas && clone_formulas.length > 0 && clone_formulas.forEach((formula) => {
        if (formula.name) {
            columns.push(formula.name)
        }
    })
    return columns;
}


export const get_formula_condition = (mainFormulas) => {

    const clone_formula = mainFormulas ? [...mainFormulas] : []
    const condition = {};

    clone_formula && clone_formula.length > 0 && clone_formula.forEach((formula) => {
        if (formula.name && !formula.deleted) {
            const inner_condition = [];
            const nf_formula_conditions = formula.nf_formula_conditions;
            nf_formula_conditions && nf_formula_conditions.length > 0 && nf_formula_conditions.forEach((item) => {
                if (!item.deleted) {
                    const __condition__ = {
                        column_name: formula.column_name && formula.column_name.length > 0 && formula.column_name[0],
                        value: item.value,
                        condition: item.condition,
                        display_value: item.display_value,
                        name: formula.name
                    }
                    inner_condition.push(__condition__)
                }
            })
            condition[formula.name] = inner_condition
        }
    })

    return condition
}



const get_formula_column_name_for_aligment = (tbl_formulas) => {
    const columns = {};
    tbl_formulas && tbl_formulas.length > 0 && tbl_formulas.map((f) => {
        if (!f.show_formula_condition) {
            const c = f.column_name && f.column_name[0];
            columns[f.name] = c;
        }
    })
    return columns;
}





export const getTableData = (data, xDataKeys = [], yDataKeys = [], hide_columns) => {


    const tempDataArray = [];
    let tempColumns = [...xDataKeys, ...yDataKeys];
    let columnsMaxWidth = {};
    let headerMaxWidth = {};
    const dateColumns = [];
    const tempEditedColumns = {};
    const sortedData = data ? [...data] : [];

    sortedData && sortedData.length > 0 && sortedData.forEach(row => {
        if (row && Object.keys(row).length > 0) {
            Object.keys(row).forEach(key => {
                const sortedData = row[key];
                if (isDate(sortedData)) {
                    if (dateColumns.indexOf(key) === -1) {
                        dateColumns.push(key);
                    }
                }
                const length = calculatedLength(sortedData);
                if (typeof columnsMaxWidth[key] === 'undefined') {
                    columnsMaxWidth[key] = length;
                }
                else if (columnsMaxWidth[key] < length) {
                    columnsMaxWidth[key] = length;
                }

            });
        }
    });



    Object.keys(columnsMaxWidth).length > 0 && Object.keys(columnsMaxWidth).forEach(key => {
        if (dateColumns.indexOf(key) > -1) {
            columnsMaxWidth[key] = getWidthByRatio('date', columnsMaxWidth[key]);
        }
        else {
            columnsMaxWidth[key] = getWidthByRatio(undefined, columnsMaxWidth[key], false, Number.isInteger(columnsMaxWidth[key]));
        }
    });


    Object.keys(columnsMaxWidth).forEach(key => {
        const length = calculatedLength(key);

        if (typeof headerMaxWidth[key] === 'undefined') {
            headerMaxWidth[key] = length;
        }
        else if (headerMaxWidth[key] < length) {
            headerMaxWidth[key] = length;
        }
    });


    Object.keys(columnsMaxWidth).length > 0 && Object.keys(columnsMaxWidth).forEach(key => {
        headerMaxWidth[key] = getWidthByRatio(undefined, headerMaxWidth[key], true, undefined);
    });


    const _hidden_columns_from_session = getFromSession(constants.SESSION_KEYS.TABLE_HIDDEN_COLUMNS);
    const hidden_columns = _hidden_columns_from_session ? JSON.parse(_hidden_columns_from_session) : [];

    tempColumns && tempColumns.length > 0 && tempColumns.forEach((data_key, index) => {
        if (typeof tempEditedColumns[data_key.toLowerCase()] === 'undefined') {
            tempEditedColumns[data_key] = {}
            tempEditedColumns[data_key]['index'] = index;
            if (hidden_columns.indexOf(data_key) == -1) {
                tempEditedColumns[data_key]['show'] = true;
            } else {
                tempEditedColumns[data_key]['show'] = false;
            }
        }
    });


    const a_headers = getAdditionalHeader(yDataKeys, xDataKeys)

    const totalWidth = {};

    Object.keys(columnsMaxWidth).length > 0 && Object.keys(columnsMaxWidth).forEach(key => {
        totalWidth[key] = Math.max(columnsMaxWidth[key], headerMaxWidth[key]);
    });

    delete totalWidth['sortKey'];
    hide_columns && hide_columns.length > 0 && hide_columns.forEach((column) => {
        if (column.alias && tempEditedColumns[column.alias]) {
            tempEditedColumns[column.alias].show = false;
        }
    })



    // console.log("a_headers", yDataKeys, a_headers)
    return {
        data: tempDataArray,
        columns: tempColumns,
        editedColumns: tempEditedColumns,
        additionalHeader: a_headers,
        totalWidth,
        dateColumns,
        sortedData: sortedData ? sortedData : []
    }
};


export const getAdditionalHeader = (yDataKeys, xDataKeys) => {

    const a_headers = [];

    const ifHyphenExists = yDataKeys.reduce((acc, yDataKey) => acc = acc + (yDataKey.indexOf('_nfx_') > -1 ? 1 : 0), 0);
    let howManyRowsForHeader = 0;

    const key_occurences = {};



    if (ifHyphenExists > 0 && ifHyphenExists > (yDataKeys.length - 5)) {

        // hyphen exists. let's split the data

        yDataKeys.forEach(yDataKey => {
            const result = yDataKey.split('_nfx_');
            if (result.length > howManyRowsForHeader) howManyRowsForHeader = result.length;
            result.forEach((columnHeader, index) => {
                if (typeof key_occurences[index] === 'undefined') {
                    key_occurences[index] = {};
                }
                key_occurences[index][columnHeader] = (key_occurences[index][columnHeader] || 0) + 1;
            });
        });

        let keys_to_loop = Array.from(Object.keys(key_occurences));

        keys_to_loop.forEach(key => {
            const inside_keys_to_loop = Array.from(Object.keys(key_occurences[key]));

            if (inside_keys_to_loop.length === 1) {
                // ok, this is only one column. We can get rid of it
                delete key_occurences[key];
            }
        });

        // now let's sort the keys

        const remaining_keys = Array.from(Object.keys(key_occurences));

        remaining_keys.sort((a, b) => {
            const object_a_length = Object.keys(key_occurences[a]).length;
            const object_b_length = Object.keys(key_occurences[b]).length;

            return object_a_length - object_b_length;
        });

        const new_data_to_render = [];

        yDataKeys.forEach((yDataKey, yIndex) => {

            const result = yDataKey.split('_nfx_');

            result.forEach((columnHeader, index) => {

                if (typeof new_data_to_render[index] === 'undefined') new_data_to_render[index] = [];

                if (remaining_keys.indexOf('' + index) > -1) {

                    // const key = result?.length > 2 ? columnHeader + 
                    // ok, we got the index, it means we need to render this

                    if (result?.length > 2 && index > 0 && index < (result.length - 1)) {
                        const k = result.slice(0, (index + 1))?.join("_nfx_");
                        new_data_to_render[index].push(k);

                    } else {
                        if (result?.length > 1 && index > 0) {
                            new_data_to_render[index].push("___");

                        } else {
                            new_data_to_render[index].push(columnHeader);
                        }
                    }
                }
            });

        });


        // console.log("new_data_to_render", new_data_to_render)
        new_data_to_render.forEach((yColumnData, index) => {
            if (yColumnData.length > 0) {
                const headerRow = [];
                if (index === 0) {
                    headerRow.push(...xDataKeys);
                }
                else {
                    headerRow.push(...xDataKeys.map(x => ''));
                }

                headerRow.push(...yColumnData);

                a_headers.push(headerRow);
            }
        });
    }

    if (a_headers?.length > 2) {
        const firstArrayLength = a_headers[0].length;
        for (let i = 1; i < a_headers.length; i++) {
            const currentArray = a_headers[i];
            const currentArrayLength = currentArray.length;

            if (currentArrayLength < firstArrayLength) {
                const valuesToPush = firstArrayLength - currentArrayLength;

                for (let j = 0; j < valuesToPush; j++) {
                    currentArray.push("___");
                }
            }
        }


    }
    // console.log("a_headers", a_headers)


    return a_headers;
}

export const merge_headers = (headers, how_much_column) => {
    if (!headers || (headers && headers.length === 0)) {
        return undefined;
    }

    const temp_headers = [...headers];
    const header_to_replace = temp_headers[1];
    const header_to_check_for_replacement = temp_headers[0];

    header_to_replace.forEach((h, index) => {
        if (h.trim().length === 0) {
            header_to_replace[index] = temp_headers[0][index];
            header_to_check_for_replacement[index] = "";
        }
    })

    var count = [];
    header_to_check_for_replacement.forEach(function (i) {
        // count[i] = (count[i] || 0) + 1; 
        count.push(i)
    });
    temp_headers[1] = header_to_replace;
    temp_headers[0] = count;

    if (how_much_column) {
        temp_headers[0] = temp_headers[0].length > how_much_column ? temp_headers[0].splice(0, how_much_column) : temp_headers[0]
        temp_headers[1] = temp_headers[1].length > how_much_column ? temp_headers[1].splice(0, how_much_column) : temp_headers[1]

    }
    return temp_headers;
};


const getWidthByRatio = (type, length, isColumn, isNumber) => {
    const ratio = !isColumn ? (isNumber ? 11 : 9.8) : 14;

    return (length === 50) ? 50 : (type != 'date' ? (ratio * length) : ((ratio + 0.9) * length));
};


const calculatedLength = word => {
    const wordString = word ? word + '' : '';

    const splittedWord = wordString.trim().split(' ').join('-').split('-');

    if (splittedWord) {
        if (splittedWord.length === 1) {
            return splittedWord[0].length;
        }
        else if (splittedWord.length === 2) {
            const firstWord = splittedWord[0];
            const secondWord = splittedWord[1];

            if (firstWord.length > secondWord.length) {
                return firstWord.length;
            }
            else {
                return secondWord.length;
            }
        }
        else {
            const howManyWords = Math.abs(splittedWord.length) / 2;

            let string = '';

            for (let i = 0; i < howManyWords; i++) {
                string = string + splittedWord[i];
            }

            return string.length;
        }
    }
};

export const sortTableData = (data, type, sortByWhat) => {
    const tempData = JSON.parse(JSON.stringify(data));

    if (type === -1) {
        tempData && tempData.length > 0 && tempData.sort((a, b) => b[sortByWhat] - a[sortByWhat]);
    }
    else if (type === 1) {
        tempData && tempData.length > 0 && tempData.sort((a, b) => a[sortByWhat] - b[sortByWhat]);
    }

    return tempData;
};

export const getRemSize = () => {
    const windowSize = window.innerWidth;
    const paddingTemp = {
        right: 0,
        left: 0
    }

    if (windowSize < 1280) {
        paddingTemp.right = 12;
        paddingTemp.left = 12;
    }
    else if (windowSize >= 1280 && windowSize < 1400) {
        paddingTemp.right = 14;
        paddingTemp.left = 14;
    }
    else if (windowSize >= 1400 && windowSize < 1600) {
        paddingTemp.right = 15;
        paddingTemp.left = 15;
    }
    else if (windowSize >= 1600 && windowSize < 1800) {
        paddingTemp.right = 16;
        paddingTemp.left = 16;
    }
    else if (windowSize >= 1800) {
        paddingTemp.right = 17;
        paddingTemp.left = 17;
    }

    return {
        th: paddingTemp,
        td: paddingTemp
    };
};

export const isCellNecessary = (tableWidth, columnsWidth, totalWidth) => {
    //     const paddingToInlcude = (getRemSize() * 2);
    //     let calculatedWidth = 0;
    //     const widthToBeAddedPerColumn = totalWidth / Object.keys(columnsWidth);

    //     columnsWidth && Object.keys(columnsWidth).length > 0 && Object.keys(columnsWidth).forEach(column => {
    //         const width = columnsWidth[column];
    //         const widthWithPadding = (width + paddingToInlcude + widthToBeAddedPerColumn);

    //         if (widthWithPadding <= 250) {
    //             calculatedWidth += widthWithPadding;
    //         }
    //         else {
    //             calculatedWidth += 250;
    //         }
    //     });

    if (parseInt(tableWidth) > parseInt(totalWidth + 20)) {
        return true;
    }
    else {
        return false;
    }

};

