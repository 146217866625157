import React from "react";

const StopIcon = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#3E9DFF"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 968 968"
      preserveAspectRatio="xMidYMid meet"
    >
      <path transform="translate(445)" d="m0 0h79l41 6 35 7 32 9 26 9 26 11 19 9 22 12 17 10 22 15 12 9 13 10 14 12 12 11 13 12 14 15 9 10 9 11 11 14 13 18 14 22 12 21 10 19 14 32 11 31 7 24 8 37 5 35 2 24 1 28-1 26-2 24-5 34-7 34-8 28-9 26-11 26-12 25-13 23-13 21-13 18-9 12-11 13-9 11-15 16-16 16-8 7-14 12-14 11-18 13-20 13-22 13-15 8-28 13-28 11-28 9-32 8-34 6-28 3-34 2h-14l-43-3-36-5-29-6-35-10-25-9-26-11-17-8-22-12-18-11-12-8-17-12-10-8-16-13-12-11-10-9-19-19-7-8-12-14-11-14-13-18-10-15-13-22-12-22-14-31-9-24-9-28-6-24-6-31-4-32-1-12v-63l4-40 6-33 8-32 12-36 12-29 8-16 12-23 14-23 11-16 12-16 14-18 15-16 7-8 16-16 8-7 11-10 14-11 17-13 27-18 24-14 15-8 29-13 29-11 33-10 32-7 36-6z" fill="#FD8999" />
      <path transform="translate(362,285)" d="m0 0h238l14 1 15 4 14 7 13 10 7 7 7 10 7 14 5 19 1 10 1 99v98l-1 35-2 14-4 13-7 14-8 11-10 10-14 9-14 6-13 3-8 1h-239l-15-3-14-5-10-6-10-8-9-9-7-10-8-17-3-11-2-23v-213l2-21 4-15 7-14 10-13 9-9 14-9 12-5 11-3z" fill="#FEFEFE" />
    </svg>
  );
};

export default StopIcon;
