import React, { useState, useEffect } from 'react';
import Delete from '../svg/delete';
import { connect } from 'react-redux';
import { save_tbl_formulas, save_columns_info } from '../../actions/report';
import { Button } from '../hoc/button'
import styled from 'styled-components'
import FormulaEditorPopup from './formula.editor.popup';
import enums from '../../utils/enums';
import { generate_unique_key, showPopup } from '../../utils';
import ConfirmBox from '../confirm-box';
import EditSvg from '../svg/edit';
import { constants } from '../../utils/constants';
import AddSvg from '../svg/add.circle';
import { change_column_order_v1 } from '../editor.shared/shared.pivot';
import Tab from '../tab'

const { CONDITIONS } = constants

export const FormattingWrapper = styled.div`
    width: 40rem;
    min-height: 25rem;
    max-height: 40rem;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    .table_wrapper{
        width: 100%;
        max-height: 23rem;
        overflow-y: auto;

        &::-webkit-scrollbar {
            background-color:red;
            width:5px
        }
        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }
        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }
    }


    table{
        width: 100%;
        border-collapse: collapse;

        td{
            border-bottom: 1px solid #e1e1e1;
            padding: .5rem .1rem;
            box-sizing: border-box;
            font-size: .9rem;
        }

        thead{
            td{
                font-weight: 600;
            }
        }
    }

    .delete_btn{
        width: 1.4rem;
        height: 1.4rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: auto;

        &:hover{
            background-color: #eaeaea;
        }
    }

    h1{        
        font-size: 1.3rem;
        text-align: left;
        margin-top: 0px;
        margin-bottom: 1rem;
        font-weight: 600;
        text-transform: capitalize;
    }
    .add_btn{
        font-size: .9rem;
        font-weight: bold;
        margin: 1rem 0px;
        display: flex;
        color: #0081CA;
        div{
            cursor: pointer;
        }
        svg{
            fill: #0081CA;;
        }
    }

`;





const ReportFormulaEditor = (props) => {

    const { close, popupKey, option, data, column_meta_data, columns_info } = props;
    const { columns, id, report_item_id, sortedData, pivot_data_axis } = data;

    const [active_row, set_active_row] = useState(0);


    const [formData, setFormData] = useState([{

        column_name: undefined,
        name: 'test column',
        show_formula_condition: false,
        nf_formula_conditions: [
            {
                condition: '',
                value: '',
                display_value: ''
            },
        ]
    },
    ]);


    const [activeFormulaType, setActiveFormulaType] = useState("Raw Data")

    const report_viewer_formula = (columns_info || []).filter((f => (f?.is_formula && f?.formula_type === "report_viewer"))).sort((a, b) => b.order - a.order);


    const create_pivot_data_column_as_hints = () => {

        const final_hints = [];


        const x = pivot_data_axis?.xac;
        const y = pivot_data_axis?.yac;
        const unique_y = pivot_data_axis?.yac;
        const z = pivot_data_axis?.pivot_data_columns;

        const pivot_yac_axis_strings = ((y?.length > 0) ? y.filter((v) => (v.visible_type !== 0)).map((r) => r.pivot_field_alias ? r.pivot_field_alias.split('_nfrptr_').join(' ') : r.pivot_field_column_name) : []) || [];
        const pivot_z_axis_strings = ((z?.length > 0) ? z.filter((v) => (v.visible_type !== 0)).map((r) => r.pivot_field_alias ? r.pivot_field_alias.split('_nfrptr_').join(' ') : r.pivot_field_column_name) : []) || [];
        const pivot_x_axis_strings = ((x?.length > 0) ? x.filter((v) => (v.visible_type !== 0)).map((r) => r.pivot_field_alias ? r.pivot_field_alias.split('_nfrptr_').join(' ') : r.pivot_field_column_name) : []) || [];

        // sum([Revenue], [gender] = "female")

        if (pivot_yac_axis_strings?.length > 0 && pivot_z_axis_strings?.length > 0) {

            pivot_yac_axis_strings.forEach((p) => {
                const is_comp_column = p?.split("_nfx_")?.length > 1;
                if (is_comp_column) {

                    // it's means comp column presented in data
                    const value = p.split("_nfx_")[0];
                    const y_field_name = p.split("_nfx_")[1];

                    const value_str = `[${y_field_name}], [${pivot_z_axis_strings[0]}]="${value}"`;
                    const display_str = p?.split("_nfx_").join("-");

                    if (value !== "% Share") {

                        final_hints.push({
                            value: value_str,
                            display_value: display_str
                        })
                    }
                }

            })
        }
    }



    // create_pivot_data_column_as_hints()


    const find_next_order_index = (__formData__) => {
        let order = 1;
        const sorted = (__formData__ || []).sort((a, b) => b.order - a.order);
        if (sorted && sorted?.length > 0 && sorted[0].order >= order) order = sorted[0].order + 1;
        return order;
    }


    useEffect(() => {
        // const let's assign index to formula index could be anything 
        const final_formula_to_use = []
        report_viewer_formula.forEach((f, index) => {
            const prev_f_object = f;
            if (f.order === null || !f.order) prev_f_object.order = find_next_order_index(final_formula_to_use);
            prev_f_object.index = generate_unique_key("index")
            final_formula_to_use.push(prev_f_object);
        })

        setFormData(final_formula_to_use)
    }, [columns_info])




    const delete_formula = (formula_index) => {
        const clone_formulas = formData ? [...formData] : [];
        clone_formulas[formula_index].deleted = true;
        const target_formula = clone_formulas[formula_index]
        setFormData(clone_formulas)
        save_formula(target_formula)

    }


    const save_formula = (data, index) => {
        if (data) {
            data["formula_type"] = 'report_viewer';
            data["is_formula"] = true
            data["visible_type"] = 1
        }

        console.log("data", data)
        props.save_columns_info([data], (report_item_id ? report_item_id : id), id, props.be_file_path)
    }


    const next_element_order = find_next_order_index(formData)
    const open_editor = (index) => { 
        showPopup((formData[index] ? 'Edit Your Formula' : 'Add Your Formula'), undefined, enums.popupType.element, FormulaEditorPopup, {
            items: formData[index] ? formData[index] : undefined,
            sortedData: sortedData,
            index: index,
            report_item_id: report_item_id,
            order: next_element_order,
            save_formula: save_formula,
            columns: columns,
            column_meta_data: column_meta_data,
            formula_applicable_to: (activeFormulaType && activeFormulaType.split(" ").join("_").toLocaleLowerCase())

        },
            undefined,
            undefined,
            undefined,
            {
                width: '53rem'
            })
    }


    const sorted_formula_to_view = (formData || []).sort((a, b) => a.order - b.order)

    // this is a patch code for handle orders 
    sorted_formula_to_view.map((f, index) => {
        const next_formula = sorted_formula_to_view[index + 1];
        if (next_formula && next_formula?.order <= f.order) {
            sorted_formula_to_view[index + 1].order = f.order + 1;
        }
    })

    const change_formula_order = (event) => {
        const final_form_data = change_column_order_v1(sorted_formula_to_view, event, 'order')

        setFormData(final_form_data)

        props.save_columns_info(final_form_data, (report_item_id ? report_item_id : id), id)

    }



    return (
        <FormattingWrapper>
            <Tab
                tabList={["Raw Data", "Pivot Data",]}
                activeType={activeFormulaType}
                setActiveType={setActiveFormulaType}
                hideShadow={true}
            />
            <div style={{ marginLeft: 'auto', marginTop: '1rem' }}>
                <Button
                    primary={true}
                    onClick={() => {
                        open_editor()
                    }}
                ><AddSvg size={'1rem'} height={'1rem'} color="#fff" />&nbsp;&nbsp;New Formula</Button>
            </div>

            <div className="table_wrapper" style={{ width: '100%', marginTop: '1rem' }}>

                {activeFormulaType && (formData || []).filter((f) => f.formula_applicable_to?.toLocaleLowerCase() === (activeFormulaType && activeFormulaType.split(" ").join("_").toLocaleLowerCase()))?.length > 0 ? (
                    <table>
                        <thead>
                            <tr>
                                <td>Formula Name</td>
                                <td style={{ width: '100px', textAlign: 'center' }}>Action</td>
                            </tr>
                        </thead>

                        <tbody>
                            {sorted_formula_to_view.map((formula, index) => {
                                if (formula.formula_applicable_to?.toLocaleLowerCase() === (activeFormulaType && activeFormulaType.split(" ").join("_").toLocaleLowerCase())) {
                                    if (!formula.deleted) {
                                        return (
                                            <tr
                                                column_index={formula?.['index']}
                                                key={"formula list" + index}
                                                draggable={true}
                                                onDragStart={(event) => {
                                                    event.dataTransfer.setData("column_info_index", formula?.['index']);
                                                    event.dataTransfer.effectAllowed = "move"
                                                }}
                                                onDragOver={(event) => {
                                                    event.stopPropagation();
                                                    event.preventDefault();
                                                }}

                                                onDrop={(event) => {
                                                    change_formula_order(event)
                                                }}

                                            >
                                                <td
                                                    title="Click to view details"
                                                    style={{ cursor: 'pointer' }}
                                                    onMouseOver={() => {
                                                        set_active_row(index + 1);
                                                    }}
                                                    onMouseOut={() => {
                                                        set_active_row(0);
                                                    }}
                                                    onClick={() => {
                                                        // open_editor(index)
                                                    }}>
                                                    {formula.alias}
                                                </td>

                                                <td style={{ width: '100px', textAlign: 'center' }}>

                                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                                        <div title="Edit" className="delete_btn" onClick={(e) => {
                                                            open_editor(index)
                                                        }}>
                                                            <EditSvg size=".8rem" height=".8rem" />
                                                        </div>

                                                        <div title="Delete" className="delete_btn" onClick={(e) => {
                                                            e.stopPropagation()
                                                            showPopup(undefined, 'Are you sure you want to delete this ? ', enums.popupType.element, ConfirmBox,
                                                                { func: { setYes: () => delete_formula(index) } })
                                                        }}>
                                                            <Delete size=".8rem" height="1rem" />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                }
                            })}
                        </tbody>
                    </table>
                ) : <p style={{ textAlign: "center", fontSize: ".9rem", marginTop: "4rem" }}>No Formula Found</p>}

            </div>
        </FormattingWrapper>
    )
}



const mapStateToProps = (state, props) => {

    const __id__ = props?.data?.id
    const tbl_formulas_from_cache = state.reportReducer.report_viewer_formula;
    const dataFromServer = state.reportReducer.dataFromServer;
    const report_data = dataFromServer && dataFromServer[__id__] && dataFromServer[__id__];
    const config_meta = report_data?.config_meta;
    const columns_info = config_meta?.column_infos
    const column_meta_data = report_data?.column_meta_data;

    const be_file_path = report_data?.file_path;

    

    return { tbl_formulas_from_cache, column_meta_data, report_data, columns_info, be_file_path }
}

export default connect(mapStateToProps, { save_columns_info, save_tbl_formulas })(ReportFormulaEditor)
