import 'ol/ol.css';
import Feature from 'ol/Feature';
import Map from 'ol/Map';
import Point from 'ol/geom/Point';
import View from 'ol/View';
import { constants } from '../../../../utils/constants';

import {
    Circle as CircleStyle,
    ImageStyle,
    Fill,
    Stroke,
    Style,
    Text,
    Icon,

} from 'ol/style';

import { Cluster, OSM, Vector as VectorSource } from 'ol/source';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { transform } from 'ol/proj';


const ZONE_TYPE = {
    "MAIN": 1.1,
    "SUB": 1.2,
    "INNER": 1.3
}

const ZONE_COLORS = {
    "1.1": {
        "size": 20,
        "color": 'pink'
    },
    "1.2": {
        "size": 20,
        "color": 'red'
    },
    "1.3": {
        "size": 20,
        "color": 'green'
    },
}


var clusters;
var subZone;
var innerZone;

export const render_cluster = (coors, map, clear, marker_type) => {


    if (clear && clusters) {
        map.removeLayer(clusters);
        map.removeLayer(subZone)
        map.removeLayer(innerZone)

        return;
    }

    const distance = marker_type === 'Bubble' ? 40 : 40
    const subZoneDistance = 20;
    const innerZoneDistance = 10;
    const features = [] //coors && coors.length > 0 && new Array(coors.length);

    const sub_zone_features = [];
    const inner_zone_features = [];


    if (clusters && map) {
        map.removeLayer(clusters)
        map.removeLayer(subZone)
        map.removeLayer(innerZone)

    }

    let highest_radius = 0;


    const __create_feature___ = (zone, index) => {

        if (!zone) return;

        const coordinate = [zone.coordinates['lng'], zone.coordinates['lat']];
        const t = transform(coordinate, 'EPSG:4326', 'EPSG:3857');
        const t_feature = new Feature(new Point(t));
        const temp_rad = Math.floor(Math.random() * (100 - 1 + 1) + 1);
        const rad = zone && zone.tooltip_html && zone.tooltip_html[zone.radius_property] || temp_rad
        t_feature.setProperties({ 'name': 'cluster', 'meta': zone, 'radius': rad });

        return t_feature;


    }



    coors && coors.length > 0 && coors.forEach((coor_obj, index) => {

        const zone = __create_feature___(coor_obj)
        features.push(zone);

        if (coor_obj?.inner_data?.length > 0) {

            const sub_zones = coor_obj?.inner_data;

            sub_zones.forEach((sub_zone, sub_index) => {
                const sub_zone_data = __create_feature___(sub_zone)
                sub_zone_features.push(sub_zone_data)

                if (sub_zone?.inner_data?.length > 0) {
                    const inner_zones = sub_zone?.inner_data;

                    inner_zones.forEach((inner_zone) => {

                        const inner_zone_data = __create_feature___(inner_zone)
                        inner_zone_features.push(inner_zone_data)

                    })

                }
            })
        }
    });

    const createNewMarker = (source, styleCached, zone_type) => {

        const __color = ZONE_COLORS[zone_type]?.color || "yellow";
        const __size = ZONE_COLORS[zone_type]?.size || 30;


        return new VectorLayer({
            source: source,
            style: function (feature) {
                const features = feature.get('features');

                const size = features.length;
                const values = features[0]?.values_;

                //console.log("size new version", size, features?.length, features)

                const have_inner_data = values?.meta?.inner_data?.length > 0;

                let style = styleCache[size];

                if (have_inner_data) {

                    style = new Style({
                        image: new CircleStyle({
                            radius: __size,// feature.values_.radius * radius_ratio,
                            stroke: new Stroke({
                                color: '#2168d1',
                            }),
                            fill: new Fill({
                                color: __color
                            }),
                        }),
                        text: new Text({
                            fill: new Fill({
                                color: '#fff',
                            }),
                        }),
                    });
                    styleCached[size] = style;
                }

                else if (size === 1 && (marker_type !== 'Bubble' || !marker_type)) {
                    style = new Style({
                        image: new Icon({
                            imgSize: [25, 25],
                            src: constants.CONTEXT_PATH + './marker.png',
                        }),
                    });
                    styleCached[size] = style;
                }

                else if (size === 1 && marker_type && marker_type === 'Bubble') {
                    const feature = features[0];
                    style = new Style({
                        image: new CircleStyle({
                            radius: feature.values_.radius * radius_ratio,
                            stroke: new Stroke({
                                color: '#2168d1',
                            }),
                            fill: new Fill({
                                color: '#528ee7ab'
                            }),
                        }),
                        text: new Text({
                            fill: new Fill({
                                color: '#fff',
                            }),
                        }),
                    });
                    styleCached[size] = style;
                }
                else {
                    if (!style) {
                        style = new Style({
                            image: new CircleStyle({
                                radius: 10,
                                stroke: new Stroke({
                                    color: '#fff',
                                }),
                                fill: new Fill({
                                    color: '#3399CC',
                                }),
                            }),
                            text: new Text({
                                text: size.toString(),
                                fill: new Fill({
                                    color: '#fff',
                                }),
                            }),
                        });
                        styleCached[size] = style;
                    }
                }
                return style;
            },
        });
    }



    /// CLUSTER 
    const source = new VectorSource({
        features: features,
    });


    const clusterSource = new Cluster({
        distance,
        source: source,
    });


    const styleCache = {};
    const radius_ratio = highest_radius > 0 ? (40 / highest_radius) : 1;
    clusters = createNewMarker(clusterSource, styleCache, ZONE_TYPE.MAIN)

    // SUB ZONE 
    const sub_zone_source = new VectorSource({
        features: sub_zone_features,
    });
    const subZoneSource = new Cluster({
        subZoneDistance,
        source: sub_zone_source,
    });
    const styleCacheSubZone = {};
    subZone = createNewMarker(subZoneSource, styleCacheSubZone, ZONE_TYPE.SUB)

    // INNER  ZONE 

    const inner_zone_source = new VectorSource({
        features: inner_zone_features,
    });
    const innerZoneSource = new Cluster({
        innerZoneDistance,
        source: inner_zone_source,
    });
    const innerZoneStyleCached = {};

    innerZone = createNewMarker(innerZoneSource, innerZoneStyleCached, ZONE_TYPE.INNER)

    // ZONE => subZone => innerZone
    map.addLayer(clusters)
    // map.addLayer(subZone)
    // map.addLayer(innerZone)


}