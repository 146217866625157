import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TitleHeader from '../../title-header';
import { PageInnerWrapper } from '../../hoc/styled.hoc';
import { Button } from '../../hoc/button';
import TextBox from '../../hoc/textBox';
import { saveTheConfigration, getTheConfigurationById, clearEditedData, testConnectionOne, testConnectionTwo } from '../../../actions/sqoop_configration';
import { Radio } from '../../hoc/checkbox'
import Checkbox from '../../hoc/checkbox'
import { withRouter } from 'react-router-dom'
import { getParamByName, getSelectedTheme } from '../../../utils'
import { constants } from '../../../utils/constants';
import { themes } from '../../../theme/theme.config'

// checkbox_tick.png

const selected = getSelectedTheme()

const Index = (props) => {


    const { saveTheConfigration, editedData, getTheConfigurationById, history, test_connection } = props;
    const { location } = history;


    const [formValue, setFormValue] = useState(undefined);


    const onInputChange = (key, value) => {
        let temp_form = formValue ? JSON.parse(JSON.stringify(formValue)) : {};
        temp_form[key] = value;
        setFormValue(temp_form)
    }

    useEffect(() => {
        let _id = getParamByName(location.search, "id")
        if (_id && _id !== null) {
            getTheConfigurationById(_id)
        } else {
            props.clearEditedData()
        }

    }, [location.search])


    useEffect(() => {
        setFormValue(editedData)
    }, [editedData])


    const local_save = () => {
        saveTheConfigration(formValue)
        setTimeout(() => {
            props.history.push("/rdbms_ingestion")
            setFormValue(undefined)
        }, 1000)
    }


    useEffect(() => {
        let _page_info = { title: editedData ? "CAPTURE RDBMS DATA" : "Capture RDBMS Data", isBack: true }
        props.dispatchHeaderInformation(_page_info)
    }, [editedData])



    return (
        <div>
            {/* <TitleHeader title={editedData ? "CAPTURE RDBMS DATA" : "Capture RDBMS Data"} /> */}
            <PageInnerWrapper padding={'2rem 1rem'} style={{ marginTop: '1rem' }}>
                <div style={{ width: '100%', boxSizing: 'border-box' }}>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1.5rem', width: '70%' }}>
                        <div style={{ width: '44%' }}>
                            <TextBox
                                isMainLabel={true}
                                label="Source DataBase"
                                value={formValue && formValue['source_db'] ? formValue['source_db'] : ""}
                                onChange={(e) => {
                                    onInputChange("source_db", e.target.value)
                                }}

                            />
                        </div>
                        <div style={{ width: '44%' }}>
                            <TextBox
                                isMainLabel={true}

                                label="Connection URL"
                                value={formValue && formValue['connection_string'] ? formValue['connection_string'] : ""}

                                onChange={(e) => {
                                    onInputChange("connection_string", e.target.value)
                                }}

                            />
                        </div>
                    </div>


                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1.5rem', width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1.5rem', width: '70%' }}>
                            <div style={{ width: '44%' }}>
                                <TextBox
                                    isMainLabel={true}

                                    label="User Name"
                                    // type={"hidden}
                                    value={formValue && formValue['username'] ? formValue['username'] : ""}
                                    // autoFill={false}
                                    onChange={(e) => {
                                        onInputChange("username", e.target.value)
                                    }}

                                />
                            </div>
                            <div style={{ width: '44%' }}>
                                <TextBox
                                    isMainLabel={true}

                                    label="Password"
                                    type="password"
                                    value={formValue && formValue['pwd'] ? formValue['pwd'] : ""}
                                    onChange={(e) => {
                                        onInputChange("pwd", e.target.value)
                                    }}

                                />
                            </div>
                        </div>
                        {formValue && formValue['username'] && formValue['pwd'] && formValue['connection_string'] && formValue['source_db'] && (
                            <div style={{ width: '30%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                <div style={{ marginRight: '1.5rem' }}>
                                    {props.test_connection_one &&
                                        <img src={constants.CONTEXT_PATH + './loader.gif'} style={{ width: '2.5rem' }}></img>
                                    }

                                </div>

                                <Button
                                    onClick={() => {
                                        let data = {
                                            host: formValue['connection_string'],
                                            user_name: formValue['username'],
                                            pwd: formValue['pwd'],
                                            db_type: 'mysql',
                                            database_name: formValue['source_db']
                                        }
                                        props.testConnectionOne(data)
                                    }}
                                >
                                    Test Connection
                                </Button>
                            </div>
                        )

                        }
                    </div>

                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1.5rem' ,  width: '70%'}}>
                   
                    </div> */}


                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1.5rem', width: '70%' }}>
                        <div style={{ width: '44%' }}>
                            <TextBox
                                isMainLabel={true}

                                label="Extract Your Data"
                                value={formValue && formValue['query_in_natural_language'] ? formValue['query_in_natural_language'] : ""}
                                onChange={(e) => {
                                    onInputChange("query_in_natural_language", e.target.value)
                                }}

                            />
                        </div>
                        <div style={{ width: '44%' }}>
                            <TextBox
                                isMainLabel={true}

                                disabled={true}
                                label="Degree Of Parallelism"
                                value={formValue && formValue['no_of_mappers'] ? formValue['no_of_mappers'] : ""}
                                onChange={(e) => {
                                    onInputChange("no_of_mappers", e.target.value)
                                }}

                            />
                        </div>
                    </div>



                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem', width: '70%' }}>
                        <div style={{ width: '44%' }}>
                            <h4 style={{ fontSize: '1rem', fontWeight: 'normal', color: themes[selected].label.color }}>Incremental Data Movement<span>?</span> (Yes/No)</h4>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '.5rem' }}>
                                <div style={{ marginRight: '1rem', display: 'flex', alignItems: 'center' }}
                                    onClick={() => {
                                        onInputChange('is_transfer', true);
                                    }}
                                >
                                    <Radio
                                        checked={formValue && formValue['is_transfer'] === true}
                                    />
                                    <label style={{ marginLeft: '.5rem' }}>Yes</label>
                                </div>
                                <div style={{ marginRight: '1rem', display: 'flex', alignItems: 'center' }}
                                    onClick={() => {
                                        onInputChange('is_transfer', false);
                                    }}
                                >
                                    <Radio
                                        checked={formValue && formValue['is_transfer'] === false}

                                    />
                                    <label style={{ marginLeft: '.5rem' }}>No</label>

                                </div>
                            </div>
                        </div>
                        <div style={{ width: '44%' }}>
                            <h4 style={{ fontSize: '1rem', fontWeight: 'normal', color: themes[selected].label.color }}>Is Compression Required? (Yes/No)</h4>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '.5rem' }}>
                                <div style={{ marginRight: '1rem', display: 'flex', alignItems: 'center' }}
                                    onClick={() => {
                                        onInputChange('compression_required', 1);
                                    }}
                                >
                                    <Radio
                                        checked={formValue && formValue['compression_required'] === 1}
                                    />
                                    <label style={{ marginLeft: '.5rem' }}>Yes</label>
                                </div>
                                <div style={{ marginRight: '1rem', display: 'flex', alignItems: 'center' }}
                                    onClick={() => {
                                        onInputChange('compression_required', 0);
                                    }}
                                >
                                    <Radio
                                        checked={formValue && formValue['compression_required'] === 0}

                                    />
                                    <label style={{ marginLeft: '.5rem' }}>No</label>

                                </div>
                            </div>
                        </div>
                    </div>



                    {(formValue && formValue["is_transfer"] === true) && (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem', width: '70%' }}>
                            {/* <div style={{ width: '4%' }}> */}
                            <TextBox
                                isMainLabel={true}

                                label="On Which Field You Want To Check For New Data Arrival"
                                value={formValue && formValue['incremental_field'] ? formValue['incremental_field'] : ""}
                                onChange={(e) => {
                                    onInputChange("incremental_field", e.target.value)
                                }}

                            />
                        </div>
                        // </div>
                    )}


                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem', width: '70%' }}>
                        <div style={{ width: '44%', }}>
                            <h4 style={{ fontSize: '1rem', fontWeight: 'normal', color: themes[selected].label.color }}>Is Encryption Required ? (Yes/No)</h4>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '.5rem' }}>
                                <div style={{ marginRight: '1rem', display: 'flex', alignItems: 'center', cursor: 'not-allowed' }}
                                    onClick={() => {
                                        // onInputChange('is_encryption', 1);
                                    }}
                                >
                                    <Radio
                                        disabled={true}
                                        checked={formValue && formValue['is_encryption'] === 1}
                                    />
                                    <label style={{ marginLeft: '.5rem' }}>Yes</label>
                                </div>
                                <div style={{ marginRight: '1rem', display: 'flex', alignItems: 'center', cursor: 'not-allowed' }}
                                    onClick={() => {
                                        // onInputChange('is_encryption', 0);
                                    }}
                                >
                                    <Radio
                                        disabled={true}
                                        checked={formValue && formValue['is_encryption'] === 0}

                                    />
                                    <label style={{ marginLeft: '.5rem' }}>No</label>

                                </div>
                            </div>
                        </div>
                    </div>



                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1.5rem', width: '70%' }}>
                        <div style={{ width: '44%' }}>
                            <TextBox
                                isMainLabel={true}

                                label="Target To Store The Data"
                                value={formValue && formValue['target_path'] ? formValue['target_path'] : ""}
                                onChange={(e) => {
                                    onInputChange("target_path", e.target.value)
                                }}

                            />
                        </div>
                        {/* <div style={{ width: '44%' }}>
                            <TextBox
                                label="Password"
                                value={formValue && formValue['end_date'] ? formValue['end_date'] : ""}

                                onChange={(e) => {
                                    onInputChange("end_date", e.target.value)
                                }}

                            />
                        </div> */}
                    </div>


                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1.5rem', width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1.5rem', width: '70%' }}>
                            <div style={{ width: '44%' }}>
                                <TextBox
                                    isMainLabel={true}

                                    label="Target Database UserName"
                                    value={formValue && formValue['target_username'] ? formValue['target_username'] : ""}
                                    onChange={(e) => {
                                        onInputChange("target_username", e.target.value)
                                    }}

                                />
                            </div>
                            <div style={{ width: '44%' }}>
                                <TextBox
                                    isMainLabel={true}

                                    label="Target Database Password"
                                    value={formValue && formValue['target_pwd'] ? formValue['target_pwd'] : ""}
                                    type="password"
                                    onChange={(e) => {
                                        onInputChange("target_pwd", e.target.value)
                                    }}

                                />
                            </div>
                        </div>
                        {formValue && formValue['target_username'] && formValue['target_pwd'] && formValue['target_path'] && (
                            <div style={{ width: '30%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ marginRight: '1rem' }}>
                                    {props.test_connection_two &&
                                        // <img src={constants.CONTEXT_PATH + './checkbox_tick.png'} style={{width: '3rem'}}></img>
                                        <img src={constants.CONTEXT_PATH + './loader.gif'} style={{ width: '2.5rem' }}></img>
                                    }

                                </div>
                                <Button
                                    onClick={() => {
                                        let data = {
                                            host: formValue['target_path'],
                                            user_name: formValue['target_username'],
                                            pwd: formValue['target_pwd'],
                                            db_type: 'mysql',
                                            database_name: formValue['source_db']
                                        }
                                        props.testConnectionTwo(data)
                                    }}
                                >
                                    Test Connection
                                </Button>
                            </div>
                        )

                        }
                    </div>

                </div>

                <div style={{ marginBottom: '1rem', marginTop: 'auto' }}>
                    <Button
                        width="8rem"
                        primary={true}
                        mR='1rem'
                        onClick={() => {
                            local_save()
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        width="8rem"
                        bgColor={'#e6e6e6'}
                        onClick={() => {
                            props.history.push("/rdbms_ingestion")
                        }}>Cancel</Button>

                </div>
            </PageInnerWrapper>
        </div>
    )
}



const mapStateToProps = (state) => ({
    editedData: state.sqoopConfigration.editedData,
    test_connection_two: state.sqoopConfigration.test_connection_two,
    test_connection_one: state.sqoopConfigration.test_connection_one


})

export default withRouter(connect(mapStateToProps, { saveTheConfigration, getTheConfigurationById, clearEditedData, testConnectionOne, testConnectionTwo })(Index));
