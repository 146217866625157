import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import SharedTable from '../shared-table'
import CloseSvg from '../svg/close';
import { get_table_data, get_table_of_db, clearTableNameAndTableData, get_db_info } from '../../actions/connection';
import { Wrapper, LeftSection, RightSection } from './db.explorer.styled';
import TitleHeader from '../title-header'
import { PageInnerWrapper } from '../hoc/styled.hoc';
import { withRouter } from 'react-router-dom';
import { getParamByName } from '../../utils'
import TableSvg from '../svg/table';
import RippleEffect from '../hoc/ripple-effect'
import LeftArrow from '../svg/leftArrow';
import Checkbox from '../hoc/checkbox'
import CheveronIcon from '../svg/dropDown'
import SelectOption from '../select-option';


const Index = (props) => {

    const { history, get_table_data, get_table_of_db, location, tablesOfDb, tableData, get_db_info } = props;

    const { search } = location;

    const [activeTable, setActiveTable] = useState(undefined);
    const [connectionId, setConnectionId] = useState(undefined);
    const [connectionName, setConnectionName] = useState(undefined);
    const [tableHead, setTableHead] = useState(undefined);
    const [tableBody, setTableBody] = useState(undefined);
    const [localTableData, setLocalTableData] = useState(undefined);
    const [showColumns, setShowColumns] = useState(undefined);
    const [isColumnOpen, setIsColumnOpen] = useState(undefined);
    const [sortOrder, setSortOrder] = useState(undefined);


    useEffect(() => {
        let _page_info = {
            title: 'Sample data',
            isBack: true,
        }
        get_db_info()
        props.dispatchHeaderInformation(_page_info)
    }, [])



    useEffect(() => {
        const connection_id = getParamByName(search, 'connection_id');
        const connection_name = getParamByName(search, 'sh_name');

        if (connection_id) {
            get_table_of_db(connection_id)
        }
        setConnectionId(connection_id)
        setConnectionName(connection_name)
    }, [search])


    const sort_column_name = () => {
        let order = sortOrder ? sortOrder == 'az' ? 'za' : 'az' : 'az';
        let sorted_column_name = showColumns && showColumns.length > 0 && showColumns.sort((left, right) => {
            if (order == 'az') {
                let leftValue = left['key'] ? left['key'].trim().toLowerCase() : '';
                let rightValue = right['key'] ? right['key'].trim().toLowerCase() : '';
                return leftValue.localeCompare(rightValue);
            }
            if (order == 'za') {
                let leftValue = left['key'] ? left['key'].trim().toLowerCase() : '';
                let rightValue = right['key'] ? right['key'].trim().toLowerCase() : '';
                return rightValue.localeCompare(leftValue);
            }
        })
        setSortOrder(order)
        setShowColumns(sorted_column_name)
    }

    useEffect(() => {
        if (tableData && tableData.length > 0) {
            let keys = Object.keys(tableData[0]);

            let temp_key_for_header = [];
            let temp_show_columns = [];
            keys && keys.length > 0 && keys.forEach((k) => {
                let final_key = k.split("_").join(" ")
                temp_key_for_header.push(final_key)
                temp_show_columns.push({
                    is_show: true,
                    key: k
                })
            })
            setShowColumns(temp_show_columns)
            setTableBody(keys)
            setTableHead(temp_key_for_header)

        }
        setLocalTableData(tableData)
    }, [tableData])



    const onColumnCheckboxClicked = (key, index) => {
        let temp_column_show = showColumns ? [...showColumns] : [];
        temp_column_show && temp_column_show.length > 0 && temp_column_show.map((item, i) => {
            if (item.key === key) {
                item.is_show = !item.is_show;
            }
        })
        setShowColumns(temp_column_show)
    }

    const table_key = [];

    showColumns && showColumns.length > 0 && showColumns.map((c) => {
        if (c.is_show) {
            table_key.push(c.key)
        }
    })


    return (
        <Wrapper>
            <div className="schema_block">
                <label>Schema Name</label>
                <select
                    value={connectionId}
                    onChange={(e) => {
                        setConnectionId(e.target.value)
                        let _url = `/db_explorer?connection_id=${e.target.value}`
                        props.history.push(_url)
                        setShowColumns(undefined)

                    }}
                >
                    {props.db_infos && props.db_infos.length > 0 && props.db_infos.map((db, i) => {
                        return <option value={db.id}>{db.connection_name}</option>
                    })}
                </select>
            </div>


            <PageInnerWrapper padding={'0px'} overflow={"hidden"} height={'calc(100vh - 200px)'}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}>
                    <LeftSection>
                        <h2 className="tbl_title">Tables</h2>

                        {tablesOfDb && tablesOfDb.length > 0 && tablesOfDb.map((table) => {
                            return (
                                <div
                                >
                                    <div className="tbl_name"
                                        onClick={() => {
                                            setActiveTable(table)
                                            let _post_data = {
                                                database_id: connectionId,
                                                table_name: table
                                            }
                                            setShowColumns(undefined)
                                            get_table_data(_post_data)
                                            setSortOrder(undefined)
                                        }}
                                        style={{
                                            backgroundColor: (activeTable && activeTable === table) ? "#7d7c7c" : undefined,
                                            color: (activeTable && activeTable === table) ? "#fff" : undefined,
                                        }}
                                    >
                                        <div style={{ marginRight: '.5rem' }} onClick={() => {
                                            setIsColumnOpen(!isColumnOpen)
                                        }}>
                                            {
                                                isColumnOpen && activeTable === table ?
                                                    <div ><CheveronIcon size='.7rem' height='.7rem' color={'#222'} /></div> :
                                                    <div className="normal_icon"><CheveronIcon size='.7rem' height='.7rem' color={'#222'} /></div>
                                            }
                                        </div>
                                        <TableSvg size=".9rem" height=".9rem" color={(activeTable && activeTable === table) ? "#fff" : '#222'} />
                                        &nbsp;&nbsp; <div title={table} className="tbl_name_no_wrap">{table}</div>

                                    </div>
                                    {isColumnOpen && activeTable === table && showColumns &&
                                        <div>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0rem 1rem', paddingTop: '.2rem', boxSizing: 'border-box' }}>
                                                <p className="column_name_title">Columns</p>

                                                <div className="column_sorting" onClick={() => {
                                                    sort_column_name()
                                                }}>
                                                    <CheveronIcon style={{ transform: 'rotate(180deg)' }} size='.5rem' height='.5rem' color={sortOrder === 'az' ? '#000' : '#8b8787'} />
                                                    <CheveronIcon size='.5rem' height='.5rem' color={sortOrder === 'za' ? '#000' : '#8b8787'} />
                                                </div>
                                            </div>
                                            <ul className="column_list">

                                                {showColumns && showColumns.length > 0 && showColumns.map((col, i) => {
                                                    return (
                                                        <li>
                                                            <Checkbox
                                                                size={'35px'}
                                                                onClick={() => {
                                                                    onColumnCheckboxClicked(col.key, i)
                                                                }}

                                                                checked={col.is_show} label={col.key} />                                                 </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    }

                                </div>
                            )
                        })}


                    </LeftSection>
                    <RightSection>
                        <SharedTable
                            height={'calc(100% - .5rem)'}
                            action={false}
                            tableData={(localTableData && localTableData.length > 0) ? localTableData : undefined}
                            columnsToShow={table_key}
                            tableHead={table_key}
                        />

                        {activeTable && localTableData === undefined && <p className="while_loading">Please wait while we are loading the data...</p>}
                        {!activeTable && <p className="while_loading">Please select the table to view the data</p>}
                        {localTableData && localTableData.length == 0 && <p className="while_loading">No Records Found</p>}

                    </RightSection>
                </div>
            </PageInnerWrapper>
        </Wrapper>
    )
}


const mapStateToProps = (state) => ({
    tablesOfDb: state.connectionReducer.tablesOfDb,
    tableData: state.connectionReducer.tableData,
    db_infos: state.connectionReducer.db_infos

})

export default withRouter(connect(mapStateToProps, { get_db_info, get_table_data, get_table_of_db, clearTableNameAndTableData })(Index))
