import * as actions from '../action-types/relationship.detail';

const initialState = {
    type: actions.RELATIONSHIP_DETAIL_INIT,
    tablesForRelationship: undefined,
    sourceColumns: undefined,
    targetColumns: undefined,
    relationships: undefined,
    columnsName: undefined,
    table_column_info : undefined,
};


const relationshipDetail = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    // if (action && action.data && typeof action.data === 'object') {
    //     action.data.key = new Date();
    // }

    switch (action.type) {

        case actions.GET_TABLE_FOR_RELATIONSHIP_DETAIL_SUCCESS:
            return {
                ...state,
                type: action.type,
                tablesForRelationship: action.data
            };
        case actions.GET_SOURCE_TABLE_COLUMNS_SUCCESS:
            return {
                ...state,
                type: action.type,
                sourceColumns: action.data
            };
        case actions.GET_TARGET_TABLE_COLUMNS_SUCCESS:
            return {
                ...state,
                type: action.type,
                targetColumns: action.data
            };
        case actions.GET_ALL_RELATIONSHIPS_SUCCESS:
            return {
                ...state,
                type: action.type,
                relationships: action.data
            };
        case actions.GET_COLUMNS_BY_TABLE_NAME:

            let prev_data = state.columnsName ? state.columnsName : {};
            const table_name = action.data.table_name;
            const column_name = action.data.column_name
            prev_data[table_name] = column_name

            return {
                ...state,
                type: action.type,
                columnsName: prev_data
            }

        case actions.GET_TABLE_AND_COLUMNS:
            return {
                ...state,
                type: action.type,
                table_column_info: action.data
            }
        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
            return {
                ...state
            };
    }
};

export default relationshipDetail;