import { s_p } from ".";
import { convert_date_into_date_object, is_date, is_valid_date_v2, parse_any_date_into_date_object } from "../index";
import * as dataTypes from "../../shared-with-fe/data.types";

// const function_def = 'sum(100 + [revenue] + [discount], [service]="IPD", [department]="lepro") + 1029301 + sum([revenue] + 200, [service]="OPD (D)")';
const function_def = '500 + sum([revenue], [year]) * 10/100';
const functions = ['sum', 'avg', 'min', 'max'];

// const operators = ['+', '-', '*', '/', '>=', '<=', '=', '>', '<' ];
const calculations_operators = ['+', '-', '*', '/'];
const conditonal_operators = ['>=', '<=', '=', '<', '>'];

const generate_key = () => {
    return (new Date().getTime()).toString(36) + (new Date().getTime() + Math.floor(Math.random() * 100000)).toString(36);
};

// step 1, lets get rid of all the double quotes

const replace_double_quotes_with_a_key = (formula_text, comparisons = []) => {

    let text_to_search_in = formula_text + '';
    const replaced_values = {};

    const format = {
        key: '',
        value_replaced: '',
        quotient: ''
    };

    // first, get rid of empty double quotes

    if (text_to_search_in.indexOf('""') > -1) {
        // double quotes found
        const to_replace = Object.assign({}, format);

        to_replace.key = generate_key();
        to_replace.quotient = '"';
        replaced_values[to_replace.key] = to_replace;

        text_to_search_in = text_to_search_in.split('""').join(to_replace.key);
    }

    const regex = new RegExp('\\"(.+?)\\"', 'g');
    const matches = text_to_search_in.match(regex);

    matches && matches.forEach(match => {
        const to_replace = Object.assign({}, format);

        to_replace.key = generate_key();
        text_to_search_in = text_to_search_in.split(match).join(to_replace.key);
        to_replace.value_replaced = match?.trim();
        replaced_values[to_replace.key] = to_replace;
        to_replace.quotient = '"';
    });

    // console.log("matches1.1", matches[0].split('') )


    const regex_bracket = new RegExp('\\[(.+?)\\]', 'g');
    const matches_bracket = text_to_search_in.match(regex_bracket);


    matches_bracket && matches_bracket.forEach(match => {
        const to_replace = Object.assign({}, format);

        to_replace.key = generate_key();

        if (comparisons.length > 0) {
            text_to_search_in = text_to_search_in.split(match).join(to_replace.key);
        }
        else {
            text_to_search_in = text_to_search_in.split(match).join(to_replace.key);
        }
        to_replace.value_replaced = match;
        replaced_values[to_replace.key] = to_replace;
        to_replace.quotient = '[';
    });

    return {
        replaced_values,
        formula_text: text_to_search_in
    };
};



/**
 * 
 * @param {*} possible_column_name 
 */
const step_2_1_1_extract_column_names_without_operators = (_possible_column_name = '', replaced_keys) => {

    // console.log('request received for extracting column name: ', _possible_column_name);

    let possible_column_name = _possible_column_name + '';
    let column_names = [];

    [...conditonal_operators, ...calculations_operators].forEach(operator => {
        if (possible_column_name.indexOf(operator) > -1) {
            // found it
            const values = possible_column_name.split(operator);

            values.forEach((value) => {
                // it is either a value, or an integer

                // lets check if this is an integer

                if (!isNaN(value)) {
                    // this is number
                    // lets put the next one as the column
                    // lets not do anything
                }
                else {
                    // this one is not a number. Now it will either be a column or a string. we will find out
                    // with the keys we had saved earlier

                    // lets find out here
                    const possible_column_object = replaced_keys[value.trim()];

                    if (possible_column_object && possible_column_object.quotient === '[') {
                        column_names.push(value.trim());
                    }

                }
            });
        }
    });


    if (column_names.length === 0) {
        const possible_column_object = replaced_keys[possible_column_name?.trim()];

        if (possible_column_object && possible_column_object.quotient === '[') {
            column_names.push(possible_column_name.trim());
        }
    }


    // console.log('Output ==> column names extracted: ', column_names);


    return column_names;
};



/**
 * 
 * @param {*} formula_text SAMPLE: sum(100 + l0ibp7r6l0ibq4d6, l0ibp7r6l0ibpu6m=l0ibp7r5l0ibpiha)
 */
const step_2_1_extract_xacs_yacs_from_formula = (formula_text = '', replaced_keys) => {
    const format_to_return = {
        xac: [],
        yac: undefined,
    };

    const formula_splitted = formula_text.split(',');

    if (formula_splitted.length === 0) {
        // no xac: only yac. no need for grouping. OUCHHHH
        format_to_return.yac = formula_text;
    }
    else {
        // console.log('------------------------------------------------------');
        // console.log('initiating yac extraction: ', formula_splitted[0])
        // console.log('------------------------------------------------------');
        format_to_return.yac = step_2_1_1_extract_column_names_without_operators(formula_splitted[0], replaced_keys);
        // the first variable is yac

        formula_splitted.slice(1).forEach(formula => {
            // console.log('------------------------------------------------------');
            // console.log('initiating xac extraction: ', formula)
            // console.log('------------------------------------------------------');
            const xacs_generated = step_2_1_1_extract_column_names_without_operators(formula, replaced_keys) || [];
            format_to_return.xac.push(...xacs_generated);
        })
    }

    return format_to_return;
};


const step_2_2_convert_into_calculation_formula = (function_name, formula_text, inside_formula_text, replaced_keys) => {
    const final_format = {
        function_name,
        calcultation_field: undefined,
        conditions: []
    };

    // lets split the inner text
    const formula_splitted = inside_formula_text.split(',');

    formula_splitted.forEach((formula, index) => {
        const operators = [...conditonal_operators];

        const fetch_conditions = (formula_text) => {
            let found = false;

            conditonal_operators.forEach(c_or => {
                if (formula.indexOf(c_or) > -1) {
                    const splitted = formula.split(c_or);

                    final_format.conditions.push({
                        lefts: splitted[0].trim(),
                        rights: splitted[1].trim(),
                    });

                    found = true;
                }
            });

            if (!found) {
                final_format.conditions.push({
                    lefts: formula.trim(),
                    rights: undefined
                });
            }
        }

        if (index === 0) {
            final_format.calcultation_field = formula.trim();
            return;
        }
        else {
            // conditions...
            fetch_conditions(formula.trim());
        }
    });


    return final_format;
};



const step_2_extract_functions = (step_1_output) => {
    let text_to_search_in = step_1_output.formula_text;
    let final_formula_text = text_to_search_in + '';
    const outputs = [];
    let final_formula_info_to_apply = {};


    const function_testing = (function_def) => {

        const regex_formula = new RegExp(function_def + '\\((.+?)\\)', 'gi');

        let matches = true;



        const process_match = (match_main, match_inner) => {
            // now before going ahead, 
            // we will just check whether the inside we do have a match or not
            const regex_formula_i = new RegExp(function_def + '\\((.+?)\\((.+?)\\)', 'gi');

            const re_verifiction_matches = regex_formula_i.exec(match_main);

            console.log("re_verifiction_matches", re_verifiction_matches)

            if (re_verifiction_matches && re_verifiction_matches.length > 2) {
                process_match(function_def + '(' + re_verifiction_matches[2] + ')', re_verifiction_matches[2]);
                return;
            }

            const key = 'arr_f_' + generate_key();



            outputs.push({
                key,
                function_def,
                ...step_2_1_extract_xacs_yacs_from_formula(match_inner, step_1_output.replaced_values),
                formula_inside_text: match_inner,
                formula_text: match_main
            });


            final_formula_text = final_formula_text.split(match_main).join(key);

            final_formula_info_to_apply[key] = step_2_2_convert_into_calculation_formula(function_def, match_main, match_inner, step_1_output.replaced_values);
        };

        while (matches) {
            const regex_formula = new RegExp(function_def + '\\((.+?)\\)', 'gi');

            matches = regex_formula.exec(final_formula_text);

            if (matches && matches.length > 1) {
                process_match(matches[0], matches[1]);
            }
        }

    };



    function_testing('sum');
    function_testing('count');
    function_testing('min');
    function_testing('max');
    function_testing('avg');
    function_testing('stdev');
    function_testing('med');
    function_testing('mod');
    // for just plain formulas
    function_testing('');


    return {
        formula_text: final_formula_text,
        outputs,
        final_formula_info_to_apply
    };
};



const step_3_combine_xacs_and_yacs = (step_2_output, replaced_keys) => {
    const o_xacs = {};
    const o_yacs = {};


    /**
     * 
     * @param {*} column_text SAMPLE: "[Premium]"
     */
    const convert_o_to_column = key => {
        // drop the first and last
        const o = replaced_keys[key];
        const column_text = o?.value_replaced;
        const v = column_text.substring(1, column_text.length - 1);
        // console.log("fm_bhai", column_text, key, v)
        return v
    };


    (step_2_output?.outputs || []).forEach(output => {
        const xacs = output.xac || [];
        const yacs = output.yac || [];

        xacs.forEach(x => o_xacs[convert_o_to_column(x)] = 1);
        yacs.forEach(y => o_yacs[convert_o_to_column(y)] = 1);
    });



    return {
        xacs: Object.keys(o_xacs),
        yacs: Object.keys(o_yacs)
    };
};


export const get_all_outputs = (__function_def__) => {

    let function_def = __function_def__;

    const step_1_output = replace_double_quotes_with_a_key(function_def);

    const step_2_output = step_2_extract_functions(step_1_output);
    const step_3_output = step_3_combine_xacs_and_yacs(step_2_output, step_1_output.replaced_values);

    console.log("step_1_output", step_1_output)
    console.log("step_2_output", step_2_output)
    console.log("step_3_output", step_3_output)


    return {
        column_definitions: step_1_output,
        column_outputs: step_2_output,
        unique_outputs: step_3_output,
        final_formula_info: step_2_output.final_formula_info_to_apply,
        final_formula_text: step_2_output.formula_text
    };
};

// const step_1_output = replace_double_quotes_with_a_key(function_def);
// const step_2_output = step_2_extract_functions(step_1_output);
// const step_3_output = step_3_combine_xacs_and_yacs(step_2_output, step_1_output.replaced_values);

// console.log('step_3_output: ', step_1_output);

// after the steps are completed, we will merge and create a single set of xacs and yacs


export const prepare_formula_calculation_logic = (column_outputs, replaced_keys, is_comparison) => {
    const formulas = column_outputs?.final_formula_info_to_apply;
    const formula_text = column_outputs?.formula_text;

    const final_outputs = {}

    Object.entries(formulas).forEach(info => {
        const formula = info[1];


        if (formula) {
            const calcultation_field = formula.calcultation_field;
            const calcuation_column = replaced_keys[calcultation_field];
            const column_name = calcuation_column?.value_replaced;
            let column_name_without_brackets_and_quotes = is_comparison ? column_name : column_name?.substring(1, column_name?.length - 1);
            const function_name = formula.function_name;


            const get_rid_of_quotes_and_brackets = (column_name) => {
                return column_name?.substring(1, (column_name?.length || 0) - 1);
            };


            // lets find if conditions are there
            const conditions = formula?.conditions || [];

            if (!column_name_without_brackets_and_quotes) {
                // this may be a calculation without an array formula

                const texts = [];

                const lets_split_and_find = (text) => {
                    calculations_operators.forEach(o => {
                        const splitted = text.split(o);

                        if (splitted.length > 1) {
                            // operator found in text
                            splitted.forEach(s => {
                                const trimmed = s.trim();

                                if (!isNaN(trimmed)) {
                                    // number.
                                    texts.push(parseFloat(trimmed));
                                    texts.push(o);
                                }
                                else {
                                    const column_from_calculation = replaced_keys[trimmed];

                                    if (column_from_calculation) {
                                        // found the column
                                        const column_name = column_from_calculation?.value_replaced;
                                        const name_to_use = is_comparison ? column_name : column_name?.substring(1, column_name?.length - 1);

                                        texts.push(name_to_use);
                                        texts.push(o);
                                    }
                                    else {
                                        const column_from_formula = formulas[trimmed];

                                        if (column_from_formula) {
                                            // ok, this is an internal formula
                                            texts.push(trimmed);
                                            texts.push(o);
                                        }
                                        else {
                                            // not a column, lets drill it found further
                                            lets_split_and_find(trimmed);
                                        }

                                    }
                                }

                            });
                        }
                    })
                }

                console.log('sdfe', calcultation_field);

                lets_split_and_find(calcultation_field);

                const final_name_to_use = texts.slice(0, texts.length - 1).join(' ').trim();
                // console.log('s & f: !', final_name_to_use);

                column_name_without_brackets_and_quotes = final_name_to_use
            }

            const final_output = {
                what_to_do: function_name,
                on_which_field: column_name_without_brackets_and_quotes,
                conditions: [],
                formula_text
            };

            if (function_name && function_name.trim().length > 0) {
                if (conditions.length === '0') {
                    // no conditons, only summation 
                }
                else {
                    conditions.forEach(c => {
                        const left_side_column = replaced_keys[c.lefts];
                        const right_side_column = replaced_keys[c.rights || ''];


                        if (typeof c.rights === 'undefined') {
                            // ok, that's a loner
                            if (left_side_column?.quotient === '[') {
                                // that is a column
                                final_output.conditions.push({
                                    compare_field_name: get_rid_of_quotes_and_brackets(left_side_column.value_replaced)
                                });
                            }
                        }
                        else {
                            if (left_side_column?.quotient === '[') {
                                // that is a column
                                final_output.conditions.push({
                                    compare_field_name: get_rid_of_quotes_and_brackets(left_side_column?.value_replaced),
                                    compare_value: get_rid_of_quotes_and_brackets(right_side_column?.value_replaced)
                                });
                            }
                            else if (right_side_column?.quotient === '[') {
                                // that is a column
                                final_output.conditions.push({
                                    compare_field_name: get_rid_of_quotes_and_brackets(right_side_column?.value_replaced),
                                    compare_value: get_rid_of_quotes_and_brackets(left_side_column?.value_replaced)
                                });
                            }
                        }
                    });
                }
            }

            final_outputs[info[0]] = final_output;

        }
        else {

        }
    });

    return final_outputs;


    // lets replace the formulas
};


export const calc_date_from_two_date = (d1, d2) => {

    // console.log("d1", d1)
    const date1 = new Date(parse_any_date_into_date_object(d1));
    const date2 = new Date(parse_any_date_into_date_object(d2));

    const diff_in_time = date1.getTime() - date2.getTime();
    const diff_in_seconds = parseFloat(diff_in_time) / 1000;

    // const difference_in_days = diff_in_time / (1000 * 3600 * 24);

    return diff_in_seconds;

}


export const is_valid_date = () => {


}



const getValueIgnoreCase = (obj, key) => {
    key = key?.toLocaleLowerCase(); // Convert the key to lowercase
    for (const objKey in obj) {
        if (objKey.toLocaleLowerCase() === key) {
            return obj[objKey]; // Return the value for the matching key
        }
    }
    return undefined; // Key not found
}


export const parse_mathematical_formulas = (formula_text, row_to_find_value_in, yac_field_names, formula_results_before_parsing, index = 0, columnMeta, result_data_type) => {

    let final_value = undefined;
    let entered = false;

    const date_or_not = (v) => {

        try {

            // console.log("enterd in to 1", v)
            // lets check if this is the date
            let sep = undefined;
            if (v?.split('-')?.length === 3) {
                sep = '-';
            }
            else if (v?.split('/')?.length === 3) {
                sep = '/';
            }

            // console.log("enterd in to 2", sep)

            if (sep) {

                // console.log("enterd in to 3", v)

                const entries = v.split(sep);
                const first = parseInt(entries[0]) || 0;
                const second = parseInt(entries[1]) || 0;
                const third = parseInt(entries[2]) || 0;

                const is_valid_month = (v) => v > 0 && v < 13;
                const is_valid_day = (v) => v > 0 && v < 32;
                const is_valid_year = (v) => v > 1950 && v < 2050;

                let is_it_date = false;

                if (is_valid_month(first) && is_valid_day(second) && is_valid_year(third)) is_it_date = true;
                if (is_valid_month(second) && is_valid_day(third) && is_valid_year(first)) is_it_date = true;
                if (is_valid_day(first) && is_valid_day(second) && is_valid_year(third)) is_it_date = true;


                if (is_it_date) {
                    return true;
                }

            }
        } catch (error) {

        }
    }



    if (date_or_not(formula_text?.trim())) return formula_text?.trim();

    // return '18/08/2022';


    const apply_parsing = (value1 = 0, value2 = 0, operator, is_date) => {

        console.log("formula input", value1, value2, operator);
        const v1_parse = parseFloat(value1);
        const v2_parse = parseFloat(value2);

        switch (operator) {

            case '+':
                return v1_parse + v2_parse;

            case '/':
                return v1_parse / v2_parse;

            case '-':
                if (is_valid_date_v2(value1) && is_date && is_valid_date_v2(value2)) {

                    console.log("yess i am")
                    // if both value will be date then we will process with date formula otherwise no no no.. 

                    return calc_date_from_two_date(value1, value2)
                }

                return v1_parse - v2_parse;

            case '*':
                return v1_parse * v2_parse;

        }
    };


    let previous_operator = undefined;
    let previous_column = '';

    if (index > 100) return 0;

    const assign = (value, operator) => {

        // console.log("assign saroj", value, operator)

        if (!isNaN(value)) {
            // number. 

            if (typeof operator === 'undefined') {
                // console.log('recursing assign 1: ', value, ' <<<>>>> ', operator, ' and final: ', final_value, ' with index: ', index);

                final_value = parseFloat(value)
                // console.log('recursing assigned 1: ', final_value);
            }
            else {
                console.log('recursing assign 2: ', value, ' <<<>>>> ', operator, ' and final: ', final_value, ' with index: ', index);
                final_value = apply_parsing(final_value, parseFloat(value), operator);
                // console.log('recursing assigned 2: ', final_value);
            }
        }



        else {

            let v = undefined;

            // console.log('previous_column: ', row_to_find_value_in, previous_column, '=======>',row_to_find_value_in[value]);
            // lets see if this column somehow
            const ignore_case_value = getValueIgnoreCase(row_to_find_value_in, value);

            if (typeof ignore_case_value !== 'undefined') {

                // yes it is date column we will not parse it

                if (
                    columnMeta?.[value]?.data_type === dataTypes.date_time ||
                    columnMeta?.[value]?.data_type === dataTypes.date_month ||
                    columnMeta?.[value]?.data_type === dataTypes.date
                ) {
                    v = ignore_case_value
                }
                else {
                    v = parseFloat(ignore_case_value);

                }
            }

            else if (yac_field_names[value]) {
                // console.log('finalllll: 1');
                v = parse_mathematical_formulas(yac_field_names[value], row_to_find_value_in, yac_field_names, formula_results_before_parsing, index + 1);
            }
            else {
                // console.log('finalllll: 2');
                v = parse_mathematical_formulas(value, row_to_find_value_in, yac_field_names, formula_results_before_parsing, index + 1);
            }

            if (typeof operator === 'undefined') {
                // console.log('recursing assign 3: ', v, ' <<<>>>> ', operator, ' and final: ', final_value, ' with index: ', index);
                // final_value = parseFloat(v)
                final_value = v;
                // console.log('recursing assigned 3: ', final_value);

            }
            else {
                // console.log('recursing assign 4: ', v, ' <<<>>>> ', operator, ' and final: ', final_value, ' with index: ', index);
                final_value = apply_parsing(final_value, v, operator, true);
                // final_value = apply_parsing(final_value, parseFloat(v), operator);
                // console.log('recursing assigned 4: ', final_value);

            }
        }
    };

    // console.log("formula_text v1.1", formula_text)

    formula_text.split('').forEach(character => {
        const char_index = calculations_operators.indexOf(character);
        if (char_index > -1) {
            // got it
            // console.log("character", previous_column, previous_operator, character)

            assign(previous_column.trim(), previous_operator);

            previous_operator = character;
            previous_column = '';
        }
        else {
            previous_column = previous_column + character;
        }
    });


    if (previous_column?.length > 0) {
        console.log("character1.1", previous_column, previous_operator)
        assign(previous_column.trim(), previous_operator);
    }

    // console.log('recursing returning: ', final_value);
    // console.log('mathe:', final_value);

    return final_value;
};




export const calculate_formula = (fm, this_row, index, formula_column_name, columnMeta, yDataKeys, comparisons, comparision_unique_values) => {

    const { formula_outputs, final_formula_info_to_apply, new_pv, yac_totals, data_type } = fm;

    const result_data_type = data_type;

    // const formula_info = final_formula_info_to_apply;

    let final_value = 0;

    // index < 2 && console.log("saroi bhai", fm)
    // index < 10 && console.log('value_to_compare: ', this_row, formula_column_name);

    // index < 10 && console.log('formula_outputs: ', formula_outputs, formula_column_name, final_formula_info_to_apply);


    const need_to_print = true//formula_column_name == "S3";

    const pivot_data = new_pv;
    const yac_field_names = {};



    let final_formula_text = formula_outputs?.final_formula_text;

    // need_to_print && console.log("encrypted_keys two", final_formula_text);

    const formula_results_before_parsing = {};

    Object.entries(final_formula_info_to_apply).forEach(entry => {

        const formula_info = entry[1];
        let formula_result = 0;

        const yac_field_name = formula_info?.on_which_field;
        const function_name = formula_info?.what_to_do;


        if (formula_info?.conditions?.length === 0 && yac_totals[yac_field_name]) {

            // need_to_print && console.log("encrypted_keys 1.1");
            const ignore_case_value_step_1 = getValueIgnoreCase(yac_totals, yac_field_name);
            const ignore_case_value_step_2 = ignore_case_value_step_1 && getValueIgnoreCase(ignore_case_value_step_1, formula_info?.what_to_do);

            formula_result = ignore_case_value_step_2 || (yac_totals[yac_field_name] && yac_totals[yac_field_name][formula_info?.what_to_do]);
            final_formula_text = final_formula_text.split(entry[0]).join(formula_result);
            formula_results_before_parsing[entry[0]] = formula_result;
        }
        else if (!yac_totals[yac_field_name] && yac_field_name) {

            // need_to_print && console.log("encrypted_keys 1.2");

            // looks like it is not an array formula. lets build it here
            // lets keep it as it is, it will be handled in parsing

            yac_field_names[entry[0]] = yac_field_name;

            if (comparisons?.length > 0) {
                const splits = formula_column_name.split('_nfx_').filter(f => f.toLocaleLowerCase() !== fm.original_formula_name?.toLocaleLowerCase());

                yDataKeys.forEach(y => {
                    const k1 = '[' + y + ']';
                    const k2 = '' + splits.join('_nfx_') + '_nfx_' + y;

                    console.log('haylo: ', yac_field_names[entry[0]], '>>>>', k1, '<><><><>', k2);

                    yac_field_names[entry[0]] = yac_field_names[entry[0]].split(k1).join(k2);
                });
            }

        }
        else {
            // conditional formula
            const keys = [];
            // need_to_print && console.log("encrypted_keys 1.3", pivot_data);

            (formula_info?.conditions || []).forEach((c, idx) => {

                const idx_a = formula_outputs?.final_formula_info?.[entry[0]]?.conditions?.[idx]?.rights
                index > 2 & need_to_print && console.log("king", c, idx_a)
                let is_it_a_column = false;

                if (idx_a) {

                    const quotient = formula_outputs?.column_definitions?.replaced_values?.[idx_a]?.quotient;
                    is_it_a_column = quotient === "[";

                    // saroj kr changed this code
                    const ignore_case_value = getValueIgnoreCase(this_row, (c.compare_field_name + ''))
                    const comp_f_value = (ignore_case_value?.toLocaleLowerCase());
                    const comp_value = (c.compare_value + '')?.toLocaleLowerCase()
                    if (comp_f_value && comp_value) {
                        keys.push(comp_value)
                    }

                    // if (this_row?.[c.compare_field_name] === this_row?.[c.compare_value]) {
                    // keys.push((this_row?.[c.compare_field_name + ''])?.toLocaleLowerCase());
                    // keys.push((this_row?.[c.compare_value + ''])?.toLocaleLowerCase());
                    // keys.push((this_row?.[c.compare_value + ''])?.toLocaleLowerCase());
                    // keys.push('delhi')
                    // }

                }
                else {
                    const ignore_case_value = getValueIgnoreCase(this_row, (c.compare_field_name + ''))

                    const value_to_compare = typeof c.compare_value !== 'undefined' ? c.compare_value : ignore_case_value;

                    keys.push((value_to_compare + '').toLocaleLowerCase());
                }

                // either pick the compare value from the row, or from the condition itself


            });


            keys.sort();
            const k = keys.join('^.^');

            need_to_print && console.log("king2", k, pivot_data);

            formula_result = (pivot_data && pivot_data[k]) ? pivot_data[k][yac_field_name + '_nf' + function_name] : 0;
            final_formula_text = final_formula_text.split(entry[0]).join(formula_result);
            formula_results_before_parsing[entry[0]] = formula_result;
        }

        // final_value = final_value + parseFloat(formula_result);
    });

    // replace any keys remaining
    const encrypted_keys = fm?.formula_outputs?.column_definitions?.replaced_values || {};

    // need_to_print && console.log("encrypted_keys", encrypted_keys, final_formula_text);


    Object.entries(encrypted_keys).forEach(entry => {

        if (entry[1] && entry[1].quotient === '[') {
            const column_name = entry[1]?.value_replaced || '';

            if (comparisons?.length > 0) {
                // lets not split brackets
                final_formula_text = final_formula_text.split(entry[0]).join(column_name);
            }
            else {
                final_formula_text = final_formula_text.split(entry[0]).join(column_name.substring(1, column_name.length - 1));
            }

        }
    });

    // need_to_print && console.log('encrypted_keys row', s_p(this_row))


    // need_to_print && console.log('encrypted_keys before', final_formula_text, formula_results_before_parsing)



    //patch for comparison formulas

    if (comparisons?.length > 0) {
        const splits = formula_column_name.split('_nfx_').filter(f => f.toLocaleLowerCase() !== fm.original_formula_name?.toLocaleLowerCase());
        console.log('sda: >>>>>> ' + final_formula_text, '>>>>>', splits, ' >>>>>> ', fm.original_formula_name);

        yDataKeys.forEach(y => {
            const k1 = '[' + y + ']';
            const k2 = '' + splits.join('_nfx_') + '_nfx_' + y;


            final_formula_text = final_formula_text.split(k1).join(k2);

        });
    }

    console.log("final_formula_text", final_formula_text)

    final_value = parse_mathematical_formulas(final_formula_text, this_row, yac_field_names, formula_results_before_parsing, undefined, columnMeta, formula_column_name, comparisons?.length > 0);

    return final_value;

};