import * as util from './utils'
import download from 'js-file-download';
import axios from 'axios';
import { getOprData, getFromSession, saveToken, saveRefreshToken } from './utils/session.helper';
import * as constants from './utils/constants';
import { decryptText } from './encryption';
import codes from './shared-with-fe/codes';


const validateResponse = async response => {
    const promise = new Promise((resolve, reject) => {
        response.text().then((responseText) => {
            let responseData = undefined;
            try { responseData = JSON.parse(responseText); } catch { }

            if (!responseData) {
                return reject(new Error(responseText));
            }

            const dispatch_token_info = (data) => {

                if (data && data.updated_token_info && data.updated_token_info.refresh_token) {
                    saveToken(data.updated_token_info.token);
                    saveRefreshToken(data.updated_token_info.refresh_token);
                }
                else if (data && data.error && data.error.updated_token_info && data.error.updated_token_info.refresh_token) {
                    saveToken(data.error.updated_token_info.token);
                    saveRefreshToken(data.error.updated_token_info.refresh_token);
                }
                else {
                }
            };

            // lets try to decrytp, if required:
            let decrypted_text = typeof responseData.__nf__ext__ !== 'undefined' ? decryptText(responseData.__nf__ext__) : undefined;
            const data = decrypted_text ? JSON.parse(decrypted_text) : responseData;

            dispatch_token_info(data);

            if ((data && data.error && data.error.message)) {

                return reject(new Error(data.error.message));
            }

            if (data && data.data && data.data.error && data.data.error_message) {
                return reject(new Error(data.data.error_message));
            }

            if (data && data.data && data.data.error && (data.data.error.message || data.data.message)) {
                return reject(new Error(data.data.error.message || data.data.message));
            }

            return resolve(data);
        });
    });

    return promise;
};

/**
 * This function will be used for retrieving the collection of objects. In real world, we show lot of data, for example, list of users, list of 
 * payments etc etc, or, may be data for dropdowns
 * 
 * @param {*} url 
 * @param {*} postData 
 * @param {*} isDropdownRequest 
 * @param {*} dropdownSortFieldName 
 */
export const getList = async (url, postData, isDropdownRequest, dropdownSortFieldName = 'Name') => {
    const headers = util.getMandatoryRequestHeaders(true);

    // we are cloning the object
    let body = {};

    if (!postData || postData === {}) {
        // let's initialize with default values
        body = {
            where: [],
            order: [],
            pageIndex: 0
        };
    }
    else {
        body = JSON.parse(JSON.stringify(postData));
    }

    // if 'where' is not defined, then let's add a default where with Active = 1
    if (!(body.where && Array.isArray(body.where) && body.where.length > 0)) {
        body.where = [{
            Active: 1
        }];
    }

    // if 'order' is not defined, let's send a blank one
    if (!(body.order && Array.isArray(body.order) && body.order.length > 0)) {
        body.order = [];
    }

    // if 'page index' is not definied, let's show the first page
    if (!(body.pageIndex && body.pageIndex > 0))
        body.pageIndex = 0;

    // for dropdown data, we will be sending a specific format so the server can identify that we are requesting
    // a dropdown data. Obviously, we don't 
    if (isDropdownRequest) {
        body.rowsToReturn = 10000;
        body.NameField = dropdownSortFieldName;
        body.order = [dropdownSortFieldName];
    }

    const requestOptions = {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
    };

    if (!dont_process_guarded_urls_if_no_client_id(url, body)) return { skip: true };

    const response = await fetch(url, requestOptions);

    const data = await validateResponse(response);

    // check if the request was successfull from it
    if (data.success === true) {
        // the response was successfull

        // we should return the json back to the action.
        return data;
    }
    else {
        console.warn(`1. faulty data received from the server: `, data, url);

        if (data.code === codes.ERRORS.SUPPRESS) {
            // need to suppress as we should not do anything
            return [];
        }

        if (data.code === codes.ERRORS.AUTHENTICATION.TOKEN.EXPIRED) {
            // lets try to refresh
        }

        if (data.do_log_out) {
            // auth couldn't found
            setTimeout(() => {
                util._whatToDoIfTokenNotFound(true);
            }, 2000);
        }

        throw util.generateError(data.message, data.code, 'Invalid request');
    }
};


const refresh_token_if_required_or_throw_error = async (data) => {


};


const refresh_token = async () => {
    const user = getFromSession(constants.constants.SESSION_KEYS.LOGGED_USER);
    const refresh_token = getFromSession(constants.constants.SESSION_KEYS.REFRESH_TOKEN);

    if (!user) return undefined;
    const url = constants.constants.END_POINTS.AUTH_API + constants.constants.END_POINTS.AUTHETICATE.POINT + constants.constants.END_POINTS.AUTHETICATE.REFRESH_TOKEN;

    const data = await post(url, {
        user_id: user.id,
        refresh_token
    }, false);


};


const post_override = async (url, requestOptions) => {
    console.warn(`requesting: ${url} with options ==> `, requestOptions);

    if (!dont_process_guarded_urls_if_no_client_id(url, requestOptions)) return { skip: true };
        // const controller = new AbortController();
        // const signal = controller.signal;
        // controller.abort();

    const response = await fetch(url, requestOptions);

    const data = await validateResponse(response);

    // check if the request was successfull from it
    if (data.success === true) {
        // the response was successfull
        // we should return the json back to the action.
        return data;
    }
    else {
        console.warn(`2. faulty data received from the server: `, data, url);

        if (data.code === codes.ERRORS.SUPPRESS) {
            // need to suppress as we should not do anything
            return [];
        }

        if (data.do_log_out) {
            // auth couldn't found
            setTimeout(() => {
                util._whatToDoIfTokenNotFound(true);
            }, 2000);
        }


        throw util.generateError(data.message, data.code, 'Invalid request');
    }
};


/**
 * @param {String} url url to save the data to
 * @param {String} body the data to be saved
 * @param {Boolean} guarded mostly true, it will mean that we will need to include the auth token from session manager. If false, means it's a request where we don't need auth, for example, forgot password or register
 */
export const post = async (url, body, guarded, notContentType, signal) => {
    const __body__ = JSON.parse(JSON.stringify(body));
    const clientIdFromSession = getFromSession(constants.constants.SESSION_KEYS.CLIENT_ID);

    const requestOptions = {
        method: 'POST',
        headers: util.getMandatoryRequestHeaders(guarded, notContentType),
        body: JSON.stringify(__body__),
        signal,
    };

   return await post_override(url, requestOptions);
};

export const axiosPostDownloadReq = async (url, body, guarded = false, notContentType) => {
    const requestOptions = {
        headers: util.getMandatoryRequestHeaders(guarded, notContentType),
    };
    const { file_name } = body;

    await axios.post(url, body, requestOptions)
        .then(response => {
            download(response.data, file_name);
        })
        .catch(error => {
            throw error
        })
};


export const postMultipart = async (url, body, guarded, notContentType) => {
    const requestOptions = {
        method: 'POST',
        headers: util.getMandatoryRequestHeadersForMultipart(guarded, notContentType),
        body: body
    };

    return post_override(url, requestOptions);
};


export const _delete = async (url, guarded) => {
    const requestOptions = {
        method: 'DELETE',
        headers: util.getMandatoryRequestHeaders(guarded),
    };

    if (!dont_process_guarded_urls_if_no_client_id(url)) return { skip: true }

    const response = await fetch(url, requestOptions);
    const data = await validateResponse(response);

    // check if the request was successfull from it
    if (data.success === true) {
        // the response was successfull
        // we should return the json back to the action.
        return data;
    }
    else {
        console.warn(`3. faulty data received from the server: `, data,);

        if (data.code === codes.ERRORS.SUPPRESS) {
            // need to suppress as we should not do anything
            return [];
        }

        if (data.do_log_out) {
            // auth couldn't found
            setTimeout(() => {
                util._whatToDoIfTokenNotFound(true);
            }, 2000);
        }

        throw util.generateError(data.message, data.code, 'Invalid request');
    }
};

/**
 * @param {String} url url to save the data to
 * @param {String} body the data to be saved
 * @param {Boolean} guarded mostly true, it will mean that we will need to include the auth token from session manager. If false, means it's a request where we don't need auth, for example, forgot password or register
 */
export const put = async (url, body, guarded) => {
    const requestOptions = {
        method: 'PUT',
        headers: util.getMandatoryRequestHeaders(guarded),
        body: JSON.stringify(body)
    };

    return post_override(url, requestOptions);
};



export const get = async (url, guarded) => {

    // const fetch = require('node-fetch');

    const requestOptions = {
        method: 'GET',
        headers: util.getMandatoryRequestHeaders(guarded ? guarded : false)
    };

    // lets not fetch if there are no session values

    if (!dont_process_guarded_urls_if_no_client_id(url)) return { skip: true };

    const response = await fetch(url, requestOptions);
    const data = await validateResponse(response);

    // check if the request was successfull from it
    if (data.success === true) {
        // the response was successfull
        // we should return the json back to the action.
        return data;
    }
    else {
        console.warn(`4. faulty data received from the server: `, data, url, requestOptions);

        if (data.code === codes.ERRORS.SUPPRESS) {
            // need to suppress as we should not do anything
            return [];
        }

        if (data.do_log_out) {
            // auth couldn't found
            setTimeout(() => {
                util._whatToDoIfTokenNotFound(true);
            }, 2000);
        }

        throw util.generateError(data.message, data.code, 'Invalid request');
    }
};


const urls_allowed_without_client_id = ['/authenticate', 'get_insights_list_by_insight_id'];

const dont_process_guarded_urls_if_no_client_id = (url, body) => {
    let client_id = getFromSession(constants.constants.SESSION_KEYS.CLIENT_ID)


    if (typeof client_id === 'undefined' || client_id === '') {

        if (body && body.body && typeof body.body === 'string') {
            const __body__ = JSON.parse(body.body);

            if (__body__.client_id) return true;
        }

        let allowed = false;
        urls_allowed_without_client_id.forEach(c => {
            if (url.indexOf(c) > -1) allowed = true;
        });

        return allowed;
    }

    return true;
};