import { simpleDispatch_post, simpleDispatch_get, raiseSuccess, dispatchAction } from './helper';
import * as actionTypes from '../action-types/relationship.detail';
import { constants } from '../utils/constants';
import { get, post, put, } from '../data.service';
import * as loaderTypes from '../action-types/loader';

export const getTablesForHints = () => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.RELATIONSHIP_DETAILS.POINT + constants.END_POINTS.RELATIONSHIP_DETAILS.GET_TABLES_FOR_RELATIONSHIP;

    await simpleDispatch_get(dispatch, url, actionTypes.GET_TABLE_FOR_RELATIONSHIP_DETAIL_SUCCESS);
};


export const getColumnsByTableName = tableName => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.RELATIONSHIP_DETAILS.POINT + constants.END_POINTS.RELATIONSHIP_DETAILS.GET_COLUMNS_FOR_TABLE;

    await simpleDispatch_post(dispatch, url, { tableName }, actionTypes.GET_COLUMNS_BY_TABLE_NAME);
};


export const get_table_and_columns = (db_info_id) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.RELATIONSHIP_DETAILS.POINT + constants.END_POINTS.RELATIONSHIP_DETAILS.GET_TABLE_AND_COLUMNS;
    await simpleDispatch_post(dispatch, url, { db_info_id: db_info_id }, actionTypes.GET_TABLE_AND_COLUMNS);
};



export const getSourceTableColumns = tableName => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.RELATIONSHIP_DETAILS.POINT + constants.END_POINTS.RELATIONSHIP_DETAILS.GET_COLUMNS_FOR_TABLE;

    await simpleDispatch_post(dispatch, url, { tableName }, actionTypes.GET_SOURCE_TABLE_COLUMNS_SUCCESS);
};


export const getTargetTableColumns = tableName => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.RELATIONSHIP_DETAILS.POINT + constants.END_POINTS.RELATIONSHIP_DETAILS.GET_COLUMNS_FOR_TABLE;

    await simpleDispatch_post(dispatch, url, { tableName }, actionTypes.GET_TARGET_TABLE_COLUMNS_SUCCESS);

};


export const saveRelationshipDetails = (relationshipDetails, db_info_id) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.RELATIONSHIP_DETAILS.POINT + constants.END_POINTS.RELATIONSHIP_DETAILS.SAVE_RELATIONSHIP_DETAILS;

    const post_data = {
        relationshipDetails,
        db_info_id: db_info_id
    }
    // await simpleDispatch_put(dispatch, url, post , actionTypes.SAVE_RELATIONSHIP_DETAILS_SUCCESS);
    dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);
    try {
        const response = await put(url, post_data);
        if (response && response.code === 200) {

            dispatchAction(dispatch, actionTypes.SAVE_RELATIONSHIP_DETAILS_SUCCESS, undefined, undefined, undefined, 0)
            dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
            getAllRelationships(db_info_id)(dispatch)

            raiseSuccess(dispatch, {
                type: 'success',
                message: 'Updated SuccessFully'
            })
        }

    } catch (error) {
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
    }

};


export const getAllRelationships = (db_info_id) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.RELATIONSHIP_DETAILS.POINT + constants.END_POINTS.RELATIONSHIP_DETAILS.GET_ALL_RELATIONSHIPS + `?db_info_id=${db_info_id}`;

    await simpleDispatch_get(dispatch, url, actionTypes.GET_ALL_RELATIONSHIPS_SUCCESS);
};
