import { simpleDispatch_post, dispatchAction, simpleDispatch_get, simpleDispatch_delete, raiseSuccess } from './helper';
import * as actionTypes from '../action-types/data-sync-up';
import { constants } from '../utils/constants';
import { get, post } from '../data.service';
import * as loaderTypes from '../action-types/loader';




export const getTablesByClientId = () => async dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_COLUMNS_NAMES)
    const url = constants.END_POINTS.API + constants.END_POINTS.DB_INFO.POINT + constants.END_POINTS.DB_INFO.GET_TABLE_BY_CLIENT_ID;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_TABLES_LIST_SUCCESS);

};


export const getColumnsByTableName = (tableName) => async dispatch => {
    let post_body = {
        table_name: tableName,
    }

    const url = constants.END_POINTS.API + constants.END_POINTS.DB_INFO.POINT + constants.END_POINTS.DB_INFO.GET_COLUMNS_OF_TABLES_BY_CLIENT_ID;
    await simpleDispatch_post(dispatch, url,post_body, actionTypes.GET_COLUMNS_BY_TABLE_NAME);

};


