import React from 'react';

const RightArrow = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}

            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 32 32"
            space="preserve">
            <g>
                <path d="M19.101,29.377c-0.25,0-0.499-0.093-0.693-0.279c-0.397-0.383-0.41-1.016-0.027-1.414l10.521-10.941L1.009,17.003   C0.43,16.968,0.005,16.564,0,16.013c-0.005-0.553,0.438-1.004,0.991-1.01l27.415-0.256L18.38,4.316   c-0.383-0.398-0.37-1.031,0.027-1.414c0.399-0.382,1.031-0.37,1.414,0.028l11.615,12.083c0.012,0.012,0.024,0.025,0.036,0.038   l0.248,0.259c0.372,0.387,0.372,0.999,0,1.386L19.821,29.07C19.625,29.274,19.363,29.377,19.101,29.377z" />
            </g>
        </svg>

    )

}


export default RightArrow;