import React from "react";

const VerifyRelationship = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1104 892"
    >
      <path transform="translate(421)" d="m0 0h258l9 2 10 5 7 6 6 10 1 4v211l-1 9-4 8-7 8-6 4-6 2-7 1h-112v46h109l12 2 9 5 5 4 6 9 2 7v92l70-1v-88l3-10 6-8 8-7 9-4 8-1h253l11 2 10 5 8 9 6 12v201l-2 15-4 9-7 9-8 5-12 3h-258l-13-4-10-9-5-8-2-7v-90h-71v86l-1 9-6 10-5 5-7 5-7 2-8 1h-101v151h205v-88l4-11 6-8 8-6 9-4h267l10 4 9 7 6 10 3 7v204l-3 15-6 10-8 7-9 4-10 1h-253l-11-2-10-6-6-7-4-9-1-4v-91h-238l-1-1v-183h-121l-9-2-10-6-6-7-4-8-1-3-1-92h-70v87l-2 9-5 8-5 6-8 5-13 3h-257l-13-4-9-8-6-10-2-7-1-148-1-1v-58l4-13 6-9 7-6 10-5 7-1h253l10 1 12 6 7 7 5 10 2 7v56l-1 32h71l-1-24v-68l6-12 4-5 9-6 7-3 8-1h110v-46h-117l-10-4-8-7-6-9-2-5-1-150v-67l4-10 9-10zm0 94v144l2 3 4 1h251l4-2 2-5v-141zm-391 315v142l5 6 2 1h250l5-3 2-5v-141zm391 0v143l4 5 3 1h248l5-3 3-4v-142zm390 0v143l4 5 2 1h250l7-6 1-2v-141zm0 303v143l4 5 2 1h251l5-3 2-7v-139z" fill="#07315D" />
      <path transform="translate(28,620)" d="m0 0h266l9 3 9 6 6 8 4 9 1 4v54l-1 154-2 9-6 10-9 8-8 3-11 1h-250l-12-2-8-4-6-5-6-10-2-7-1-149-1-1v-56l3-12 4-8 3-4h2l1-3 10-6zm2 92v142l3 5 3 2h251l5-3 2-8v-138z" fill="#08325E" />
      <path transform="translate(820,345)" d="m0 0h248l5 3 2 3v29l-3 1h-260l-1-1v-29l2-4z" fill="#D9DADB" />
      <path transform="translate(39,345)" d="m0 0h249l6 6v29l-1 1h-262l-1-1v-29l3-5z" fill="#D9DADB" />
      <path transform="translate(433,30)" d="m0 0h241l7 2 2 2 1 8v23l-1 1h-262v-32l3-3z" fill="#D9DADB" />
      <path transform="translate(428,345)" d="m0 0h250l5 5 1 5v25l-1 1h-262v-30l4-5z" fill="#D9DADB" />
      <path transform="translate(819,649)" d="m0 0h250l5 4 1 2v28l-1 1h-262l-1-1v-28l4-5z" fill="#D9DADB" />
      <path transform="translate(36,649)" d="m0 0h252l5 5 1 2v27l-1 1h-262l-1-1v-27l3-5z" fill="#D9DADB" />
    </svg>
  );
};

export default VerifyRelationship;
