import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '../hoc/button';
import AddSvg from '../svg/add';
import DeleteSvg from '../svg/delete';


const MainWrapper = styled.div`
    width: 100%;
    max-height: 18rem;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 20rem;
    overflow-y: auto;
    table{
        width: 100%;
        thead{
            td{
                background-color: #0081CA;
                color: #fff;
                border: 0px;
                padding: 5px;
            }
        }
        td{
            border: 1px solid #ccc;
        }
        td{
            padding: 10px 0px;
        }
    }
     .add_btn{
        font-size: .9rem;
        font-weight: bold;
        margin: 1rem 0px;
        display: flex;
        color: #0081CA;
        div{
            cursor: pointer;
        }
        svg{
            fill: #0081CA;;
        }
    }
    tbody{
        td{
            padding: 0px;;
        }
    }
    input{
        width: 100%;
        height: 30px;
        box-sizing: border-box;
        padding: 5px;
        border: 0px;
    }
    .delete_btn{
        
        text-align: center;
        cursor: pointer;
        svg{
            fill: red;
        }
    }
`;


const HiveParameters = (props) => {

    const { close, popupKey, option, data } = props;
    const { hive_parameters, set_hive_parameters } = data;

    const [table_data, set_table_data] = useState();


    useEffect(() => {


        const final_hive_p = [];
        const splitted_hive_parameters = hive_parameters?.split(";");

        splitted_hive_parameters?.length > 0 && splitted_hive_parameters.map((h) => {

            const pre_p = h.split("=")[0];
            const post_p = pre_p?.split("__nfx__")?.length > 0 && pre_p?.split("__nfx__")[1];
            const v = h.split("=")?.length > 0 && h.split("=")[1];

            final_hive_p.push({
                parameter: post_p,
                value: v,
            })
        })

        if (final_hive_p.length === 0) {
            final_hive_p.push([
                {
                    parameter: '',
                    value: ''
                }
            ])
        }
        set_table_data(final_hive_p)

    }, [hive_parameters])


    const add_new = () => {
        const clone_prev_data = table_data ? [...table_data] : [];
        clone_prev_data.push({ parameter: '', value: '' })
        set_table_data(clone_prev_data)
    }


    const save_hive_parameters = () => {

        const clone_hive_parameters = table_data ? [...table_data] : [];
        const filterd_data = clone_hive_parameters?.filter((h) => {
            if (h?.parameter?.length > 0 && h.value) return true;
            else return false;
        })
        let str = '';
        filterd_data.map((h) => str += "set__nfx__" + h.parameter + '=' + h.value + ";")
        // console.log("str", str)
        set_hive_parameters(str)
    }


    const _onChange = (value, key, row_index) => {

        const clone_hive_p = table_data ? [...table_data] : [];
        const current_row = clone_hive_p?.[row_index];

        current_row[key] = value;
        clone_hive_p[row_index] = current_row;

        set_table_data(clone_hive_p)
    }


    const delete_row = (index) => {

        const clone_hive_p = table_data ? [...table_data] : [];
        clone_hive_p.splice(index, 1);
        set_table_data(clone_hive_p)

    }


    return (
        <MainWrapper>
            <table>
                <thead>
                    <tr>
                        <td>Parameter</td>
                        <td>Value</td>
                        <td>Action</td>
                    </tr>
                </thead>

                <tbody>
                    {table_data?.length > 0 && table_data?.map((row, index) => {
                        return (
                            <tr key={"p_index" + index}>
                                <td>
                                    <input type="text"
                                        value={row?.parameter || ''}
                                        onChange={(e) => {
                                            _onChange(e.target.value, "parameter", index)
                                        }}

                                    />
                                </td>
                                <td>
                                    <input type="text"
                                        value={row?.value || ''}
                                        onChange={(e) => {
                                            _onChange(e.target.value, "value", index)
                                        }}

                                    />
                                </td>
                                <td>
                                    <div className="delete_btn" onClick={() => {
                                        delete_row(index)
                                    }}>
                                        <DeleteSvg size=".9rem" height=".9rem" />
                                    </div>
                                </td>

                            </tr>
                        )
                    })}
                </tbody>
            </table>

            <div class="add_btn">
                <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => {
                    add_new()
                }}>
                    <AddSvg size=".9rem" height=".9rem" /> &nbsp; Add New
                </div>
            </div>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: 'auto',
                marginLeft: 'auto'

            }}>
                <Button
                    primary={true}
                    onClick={() => {
                        save_hive_parameters()
                        close(option, popupKey)
                    }}
                >
                    Save
                </Button>
            </div>

        </MainWrapper>
    )
}

export default HiveParameters;