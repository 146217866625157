import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import SharedTable from '../shared-table'
import CloseSvg from '../svg/close';
import { convert_date_to_dd_mm_yyyy_v1, } from '../../utils';



const MainWrapper = styled.div`
    width: 27rem;
    max-height: 17rem;
    overflow: hidden;
    background-color: #fff;
    padding: 2rem;
    padding-top: 1rem;
    border: 3px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
    position: relative;

    h1{
        font-size: 1.7rem;
        font-weight: 300;
        color: #555;
        text-align: center;
        margin: 0px;

    }

    p{
        font-size: .9rem;
        margin: 0px;
        margin-bottom: .8rem;
        color: #444;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .close_btn{
        background: #cccccc4f;
        top: 10px;
        right: 10px;
        width: 1.8rem;
        height: 1.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        cursor: pointer;

        &:hover{
            background-color: #eaeaea;
            svg{
                fill: rgb(0,138,199);
            }
        }

    }
`;


const TablePopup = (props) => {

    const { close, popupKey, option, fileMetaInfo } = props;
    const [fileInfo, setFileInfo] = useState(undefined);

    const _extract_file_name = (path) => {
        if (path) {
            let temp_path = path.split('-');
            let temp_len = temp_path.length - 1;
            let _lastIndex = temp_path[temp_len].split('.');
            let _finalPath = temp_path.slice(0, temp_len).join('-') + "." + _lastIndex[_lastIndex.length - 1];

            return _finalPath
        }
        return null;
    }



    useEffect(() => {
        if (fileMetaInfo && fileMetaInfo[0]) {
            setFileInfo(fileMetaInfo[0])
        } else {
            setFileInfo(undefined)
        }
    }, [fileMetaInfo])

    return (
        <MainWrapper>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' }}>
                <h1> File Properties</h1>
                <div className="close_btn" onClick={() => close(option, popupKey)}>
                    <CloseSvg size="1rem" height="1rem" />
                </div>


            </div>
            <div style={{ marginTop: '2rem' }}>
                <p>File Name - <strong>{fileInfo && fileInfo.file_name ? _extract_file_name(fileInfo.file_name) : null}</strong></p>
                <p>Date Range - <strong>{fileInfo && fileInfo.campaign_date && fileInfo.campaign_date.start_date ? convert_date_to_dd_mm_yyyy_v1(fileInfo.campaign_date.start_date) : null}  {fileInfo && fileInfo.campaign_date && fileInfo.campaign_date.end_date ? ' - ' + convert_date_to_dd_mm_yyyy_v1(fileInfo.campaign_date.end_date) : null}</strong></p>
                <p>Clients - <strong>{fileInfo && fileInfo.clients && fileInfo.clients.join(',')}</strong></p>
                <p>Campaigns - <strong>{fileInfo && fileInfo.campaign_names && fileInfo.campaign_names.join(",")}</strong> </p>
            </div>

        </MainWrapper>
    )
}



const mapStateToProps = (state) => ({
    fileMetaInfo: state.fileUploaderReducer.fileMetaInfo,
})
export default connect(mapStateToProps, {})(TablePopup)
