import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ChevronDown from "../svg/chevron.down";
import Sheet from "../svg/sheet";
import { Button } from "../hoc/button";
import { convertDateInToHtmlDate, generateUniqueKey, getMandatoryRequestHeaders, } from "../../utils";
import { constants } from "../../utils/constants";
import axios from "axios";
import GoogleSheetIcon from "../svg/icons-v2/google_sheet_icon";
import ArrowIcon from "../svg/menu/arrowIcon";
import { getAllFiles } from "../../actions/file.uploader";
import { connect } from "react-redux";

const PopupContainer = styled.div`

    display: block;
    width: 50vw;
    margin: auto;
    background-color: #fff;
    padding: 2rem 2rem;
    border-radius: 10px;
    position: relative;
    box-shadow: 2px 1px 7px 0px #00000047;


    .file_details_title{
        padding-left: 5px;
        margin: 0px;
        font-size: 1.2rem;
        color: #333;
        margin-bottom: 10px;
        margin-top: 1.3rem;
    }
    
    .popup_main {
        width: 100%;
        margin: auto;

        .label{
          font-weight: 600;
          margin-bottom: 0.6rem;
          text-align: center;
          font-size: 1.5rem;
          border-bottom: 1px solid #ccc;
          padding-bottom: 1rem;
        }
        .loader_box{
          width: 100%;
          margin: auto;
          text-align: center;
          font-size: 1.4rem;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #ffffffc7;
          z-index: 2;
            P{
                margin: 0;
                margin-bottom: 1rem;
                padding-top: 0.3rem;
            }
            div{
                width: 8%; 
                margin: auto;
            }
            img{
                max-width: 100%;
            }
        }
    }
    .Schedule_Your_Job{
        padding: 1rem 0;
        display: flex;
        justify-content: left;
        align-items: center;
        input{
            margin-right: 0.8rem;
        }
        label{
            font-weight: bold;
        }
    }
    .buttons {
        display: flex;
        align-items: center;
        justify-content: end;
        padding: 1rem 0 0 0;
        margin: 0 0rem;
        button{
            color: #fff;
            &:hover{
                background-color: #253cf5;
                color: #fff;
            }
           
        }
        .hover_effect{
              &:hover{
                background-color: #f2f2f2;
              }
        }
    }

    .close_btn {
        margin-top: -32px;
        cursor: pointer;
    }

    .upload_google {
        border-bottom: 1px solid #ccc;
        margin-bottom: 1.4rem;
        text-align: center;
        width: 100%;
        padding: 1.2rem 0;

        h3 {
            margin: 0;
            color: #000;
            font-size: 1.4rem;
        }
    }

    .file_link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:0.7rem;
        margin-top: 1rem;
    }
`;


const Input = styled.input`
    width: 67%;
    padding: 15px 20px;
    border: 0px solid #ddd;
    background-color: #f2f2f2;
    border-radius: 8px;
    font-size: 1rem;
    color: #333;

    /* &:focus {
        outline: none;
        border-color: #007bff;
    } */
`;

const ButtonGen = styled.button`
    width: 28%;
    padding: 14px;
    background: linear-gradient(to right, rgb(62, 158, 255), rgb(115, 125, 255));
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    border: 1px solid #449bff;

    &:hover {
        background-color: #253cf5;
    }
`;


const Googledrivefile = styled.div`
    width: 100%;
    height: 16rem;
    overflow-y: scroll;

    &::-webkit-scrollbar {
          background-color: #fff;
          width: 16px;
      }
      &::-webkit-scrollbar-track {
          background-color: #fff;
      }
      &::-webkit-scrollbar-track:hover {
          background-color: #f4f4f4;
      }
      &::-webkit-scrollbar-thumb {
          background-color: #babac0;
          border-radius: 16px;
          border: 5px solid #fff;
      }
      &::-webkit-scrollbar-thumb:hover {
          background-color: #a0a0a5;
          border: 4px solid #f4f4f4;
      }
      &::-webkit-scrollbar-button {
          display: none;
      }
      .tab_pane{
          display: flex;
          flex-direction: column;
          border: 1px solid #ccc;
          border-radius: 13px;
          padding: 0rem;
          margin-bottom: 0.8rem;
      }
      .arrowIcon{
          transform: rotate(90deg);
          padding: 0.5rem;
          border-radius: 32px;
          border: 1px solid #ccc;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
      }
       
    .table_name {
        margin-left: 1rem;
        width: 46%;
        input {
            width: 100%;
            padding: 12px 7px;
            border: 1px solid #ddd;
            border-radius: 4px;
            font-size: 11px;
        } 
    }

    .three_inputs{
        input[type=checkbox] {
            transform: scale(1.7);
        }
    }

    .tab_title {

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 1rem;
        box-sizing: border-box;
        border-bottom: 1px solid #e2e2e2;

        .three_inputs {
            display: flex;
            align-items: center;
            font-size: 1.2rem;
            font-weight: bold;
            color: #333;
        }
        .GoogleSheetIcon{
          padding: 0 1rem;
        }
    }

    .tab_content {

        width: 100%;
        height: 10rem;
        overflow-y: scroll;
        padding: 1rem 1rem;
        box-sizing: border-box;

        &::-webkit-scrollbar {
            background-color: #fff;
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            background-color: #f8f9fd;
        }
        &::-webkit-scrollbar-track:hover {
            background-color: #f4f4f4;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #babac0;
            border-radius: 50px;
            border: 1px solid #fff;
        }
        &::-webkit-scrollbar-thumb:hover {
            background-color: #a0a0a5;
            border: 0px solid #f4f4f4;
        }
        &::-webkit-scrollbar-button {
            display: none;
        }
    }

    .file_and_checkbox {
          padding: 0.5rem;
          display: flex;
          align-items: center;
        input[type=checkbox] {
            transform: scale(1.5);
        }
        span{
            padding: 0 1rem ;
            width: 8rem;
            max-width: 8rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 1.1rem;
        }

        .input_type{
            width: 60%;
            background-color: #f2f2f2;
            padding: 0.7rem;
            border: none;
            border-radius: 5px;
            font-size: 0.9rem;
        }
    }
`;

const ToggleSwitchContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Switch = styled.span`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-right: 10px;
  background-color: ${props => props.isActive ? '#598dfe' : '#ccc'};
  border-radius: 34px;
  transition: background-color 0.3s;

  &::before {
    content: '';
    position: absolute;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: white;
    top: 3px;
    left: ${props => props.isActive ? '22px' : '3px'};
    transition: 0.3s;
  }
`;

const Label = styled.span`
  font-size: 16px;
  color: #333;
`;


const UploadGoogleDrive = (props) => {

    const { setActiveTab, getAllFiles } = props;

    const [link, setLink] = useState("");


    const [workbookData, setWorkbookData] = useState([]);
    const [selectedIndexes, setSelectedIndexes] = useState([]);
    const [isLoading, setIsLoading] = useState(undefined);
    const [errorMsg, setErrorMsg] = useState('');




    // useEffect(() => {
    //     if (fileMapping?.length > 0) {

    //         const transform_fileMapping = fileMapping.reduce((acc, item) => {
    //             const [sheetName, , fileName] = item.file_identifier.split('__');
    //             if (!acc[sheetName]) {
    //                 acc[sheetName] = [];
    //             }

    //             acc[sheetName].push({
    //                 name: fileName,
    //                 isSelected: true,
    //                 target_table_name: item.target_table_name,
    //                 job_id: item.job_id,
    //             });

    //             return acc;
    //         }, {});

    //         setWorkbookData(transform_fileMapping)
    //     }
    // }, [fileMapping])



    const toggleIndex = (index) => {
        setSelectedIndexes((prev) => (prev[0] === index ? [] : [index]));
    };


    const handleGenerate = async () => {

        if (!link) alert("please provide the link..")
        setIsLoading('Extracting the information...');
        setErrorMsg('');

        try {

            const url = `${constants.END_POINTS.DATA_WRANGLER_API}${constants.END_POINTS.DATA_WRANGLER_EXTRACTION.POINT}${constants.END_POINTS.DATA_WRANGLER_EXTRACTION.GET_EXCEL_META_INFO}`;
            const options = { headers: getMandatoryRequestHeaders(undefined) };
            const response = await axios.post(url, { googleSheetUrl: link }, options);

            const data = response?.data?.data?.data;

            //   const data = {
            //     "Project Data": [
            //       "user_info",
            //       "user_address"
            //   ],
            //   "Empolyee Data": [
            //     "user_info",
            //     "user_address"
            // ]
            //   }

            // const clone_workbookData = workbookData ? { ...workbookData } : {};

            // const newData = Object.entries(data || {}).reduce((acc, [key, values]) => {
            //   acc[key] = values.map(value => ({
            //     name: value,
            //     isSelected: false,
            //     target_table_name: value?.trim()?.split(' ')?.join("_")?.split("-")?.join("_")?.toLocaleLowerCase(),
            //   }));
            //   return acc;
            // }, {});

            const clone_workbookData = workbookData ? { ...workbookData } : {};

            const newData = Object.entries(data || {}).reduce((acc, [key, values]) => {

                acc[key] = values.map(value => {

                    // Check if it already exists in clone_workbookData
                    if (clone_workbookData[key]?.find(item => item.name === value)) {
                        return clone_workbookData[key].find(item => item.name === value);
                    }
                    // If not, create a new entry
                    return {
                        name: value,
                        isSelected: false,
                        target_table_name: value?.trim()?.split(' ')?.join("_")?.split("-")?.join("_")?.toLocaleLowerCase(),
                    };
                });
                return acc;
            }, {});

            setWorkbookData(newData);
            setSelectedIndexes([0])

        } catch (error) {

            console.error('Error fetching data:', error);
            setErrorMsg('Something went wrong..');

        } finally {
            setIsLoading(false);
        }
    };





    const uploadGoogleDriveData = async () => {

        try {


            const convertedDataArray = [];
            const workbookData_selected = {};

            for (const sheetName in workbookData) {
                workbookData[sheetName].forEach(item => {
                    // Apply the filter condition
                    if (item.isSelected) { // Include only active items
                        const file_identifier = `${sheetName}__nfx__${item.name}`
                        convertedDataArray.push({
                            id: item.id,
                            file_identifier: file_identifier,
                            target_table_name: item.target_table_name,
                        });

                        if (!workbookData_selected[sheetName]) {
                            workbookData_selected[sheetName] = [];
                        }
                        workbookData_selected[sheetName].push(item.name)
                    }
                });
            }

            setIsLoading('Please wait while we upload the data..');

            const url = `${constants.END_POINTS.API}${constants.END_POINTS.PDF_TO_QUESTION_ANSWER.SAVE_GOOGLE_DRIVE_DATA}`;
            const options = { headers: getMandatoryRequestHeaders(undefined) };

            const response = await axios.post(url, {
                googleSheetUrl: link,
                mapping_data: workbookData_selected,
                mapping_data_node: convertedDataArray
            }, options);

            const data = response?.data?.data?.data;

            props.getAllFiles()
            setWorkbookData(undefined)
            setLink('')
            setActiveTab("Manage Your Files")

        } catch (error) {

            setErrorMsg('Something went wrong..');

        } finally {

            setIsLoading(false);
        }
    }



    const onChange = (itemIndex, valueKey, value, workBookName = 'user') => {
        const updatedDataset = { ...workbookData };
        if (updatedDataset[workBookName]) {
            if (itemIndex >= 0 && itemIndex < updatedDataset[workBookName].length) {
                updatedDataset[workBookName][itemIndex][valueKey] = value;
            } else {
                updatedDataset[workBookName].forEach(item => {
                    item[valueKey] = value;
                });
            }
        }
        setWorkbookData(updatedDataset);
    };


    const isUploadEnabled = link && workbookData && Object.keys(workbookData).length > 0;

    const workBookKeys = workbookData && Object.keys(workbookData);

    return (
        <PopupContainer show={true}>
            <div className="popup_main">
                {/* <div className="label">Upload Google Drive File</div> */}
                <div className="file_link">
                    <Input
                        type="text"
                        placeholder="Enter Google Drive file link"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                    />
                    <ButtonGen onClick={handleGenerate}>Pull From Google Drive</ButtonGen>
                </div>


                {isLoading && (
                    <div className="loader_box">
                        <p>{isLoading}</p>
                    </div>
                )}

                {workBookKeys?.length > 0 && (
                    <div>
                        <p className="file_details_title">Here are your Drive Files</p>
                        <Googledrivefile>
                            {workBookKeys.map((name, idx) => {
                                const isAllSelected = workbookData?.[name]?.every((d) => d.isSelected)
                                return (
                                    <div key={idx}>
                                        <div className="tab_pane">
                                            <div>
                                                <div className="tab_title">
                                                    <div className="three_inputs">
                                                        <input
                                                            type="checkbox"
                                                            checked={isAllSelected}
                                                            onChange={(e) => onChange(undefined, 'isSelected', (isAllSelected ? false : true), name)}
                                                        />
                                                        <div className="GoogleSheetIcon">
                                                            <GoogleSheetIcon size='2rem' height='2rem' />
                                                        </div>
                                                        <span onClick={() => toggleIndex(idx)}>{name}</span>
                                                    </div>
                                                    <div onClick={() => toggleIndex(idx)} className="arrowIcon" style={{
                                                        transform: selectedIndexes.includes(idx) ? 'rotate(270deg)' : 'rotate(90deg)'
                                                    }}>
                                                        <ArrowIcon size='0.9rem' height='0.9rem' color="#4e4646" />
                                                    </div>
                                                </div>
                                            </div>

                                            {selectedIndexes.includes(idx) && (
                                                <div className="tab_content">
                                                    {workbookData?.[name]?.map((d, index) => (
                                                        <div key={index} className="file_and_checkbox">
                                                            <input
                                                                type="checkbox"
                                                                checked={d?.isSelected}
                                                                onChange={(e) => onChange(index, 'isSelected', (d?.isSelected ? false : true), name)}
                                                            />
                                                            <span title={d.name}>{d.name}</span>
                                                            <input
                                                                className="input_type"
                                                                type="text"
                                                                value={d?.target_table_name || ''}
                                                                placeholder="Please provide the corresponding table name"
                                                                onChange={(e) => onChange(index, 'target_table_name', e.target.value, name)}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </Googledrivefile>
                    </div>
                )}




                {errorMsg && <div className="error_msg">{errorMsg}</div>}


                <div className='buttons' style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '1rem'
                }}>
                    <ButtonGen onClick={() => {
                        if (isUploadEnabled) {
                            uploadGoogleDriveData()
                        }
                    }}>Upload The File</ButtonGen>

                </div>


            </div>
        </PopupContainer>
    );
};

export default connect(undefined, { getAllFiles })(UploadGoogleDrive);
