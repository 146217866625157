import * as dataTypes from "../../shared-with-fe/data.types";

export const switch_rows_columns = (data, x_acs, yacs, column_meta_data) => {

    // yac become rows

    let xacs = x_acs ? [...x_acs] : [];

    const column_row_wise_data_types = {
        // "Values": {
        //     "0": {
        //         type: 1,
        //         alignment: "right"
        //     },
        //     "1": {
        //         type: 1,
        //         alignment: "right"
        //     },
        //     "2": {
        //         type: 2.1,
        //         alignment: "right"
        //     },
        //     "3": {
        //         type: 2.1,
        //         alignment: "right"
        //     },
        //     "4": {
        //         type: 2.1,
        //         alignment: "right"
        //     }
        // }
    };


    const output_rows = [];

    const new_xac = [{
        pivot_field_column_name: "Data",
        pivot_field_alias: "Data",
        alias: "Data"
    }];

    const new_yac = {};

    const new_column_meta_data = {};

    if (xacs?.length === 0) {
        xacs = ['_nf_value_']
    }


    yacs.forEach((yac, yac_index) => {
        const y_to_use = yac.pivot_field_column_name;
        // this becomes row
        const row = {};
        row['Data'] = y_to_use;

        // const f_axis = "Values"
        // // console.log("f_axis", y_to_use)
        // if (!column_row_wise_data_types[f_axis]) column_row_wise_data_types[f_axis] = {};
        // if (!column_row_wise_data_types[f_axis][yac_index]) column_row_wise_data_types[f_axis][yac_index] = {};

        // column_row_wise_data_types[f_axis][yac_index] = {
        //     type: column_meta_data && column_meta_data[y_to_use]?.data_type,
        //     alignment: column_meta_data && column_meta_data[y_to_use]?.data_type === dataTypes.string ? 'left' : 'right'
        // }

        // new_column_meta_data["data"] = {
        //     data_type: column_meta_data && column_meta_data[y_to_use]?.data_type,
        //     db_column: column_meta_data && column_meta_data[y_to_use]?.db_column,
        //     display_value: 'Data'
        // }

        // new_column_meta_data["Data"] = new_column_meta_data["data"];



        xacs?.length > 0 && xacs.forEach(xac => {

            data?.length > 0 && data.forEach((prev_data_row, row_index) => {
                // this becomes columns now

                const x_values = (prev_data_row[xac] || prev_data_row[xac && xac.toLocaleLowerCase()]) + '';

                if (x_values && (x_values + '').trim().length > 0) {

                    // this one becomes column

                    row[x_values] = (row[x_values] || 0) + (prev_data_row[y_to_use] || prev_data_row[y_to_use && y_to_use.toLocaleLowerCase()]);

                    new_yac[x_values] = {
                        ...yac,
                    };

                    new_column_meta_data[x_values] = {
                        data_type: column_meta_data && column_meta_data[y_to_use]?.data_type,
                        db_column: column_meta_data && column_meta_data[xac]?.db_column,
                        display_value: x_values
                    };

                    new_column_meta_data[x_values && x_values.toLocaleLowerCase()] = new_column_meta_data[x_values];
                }

            });

        });
        output_rows.push(row);
    });

    const new_yac_to_return = [];

    new_yac && Object.keys(new_yac).forEach(k => {
        new_yac_to_return.push({
            ...new_yac[k],
            pivot_field_column_name: k,
            pivot_field_alias: k
        })
    })




    yacs.forEach((yac, yac_index) => {

        const y_to_use = yac.pivot_field_column_name;
        new_yac_to_return?.forEach((x) => {

            const f_axis = (x?.pivot_field_column_name || x?.pivot_field_alias || x?.alias || "Values")
            if (!column_row_wise_data_types[f_axis]) column_row_wise_data_types[f_axis] = {};
            if (!column_row_wise_data_types[f_axis][yac_index]) column_row_wise_data_types[f_axis][yac_index] = {};

            column_row_wise_data_types[f_axis][yac_index] = {

                type: column_meta_data && column_meta_data[y_to_use]?.data_type,
                alignment: column_meta_data && column_meta_data[y_to_use]?.data_type === dataTypes.string ? 'left' : 'right',
                use_percent: column_meta_data && column_meta_data[y_to_use]?.data_type === dataTypes.percent,
            }
        })

    });


    return {
        new_column_meta_data,
        output_rows,
        new_xac,
        new_yac: new_yac_to_return,
        column_row_wise_data_types: column_row_wise_data_types
    };
};