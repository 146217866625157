import React from 'react';

import { Wrapper, MessageWrapper, ButtonWrapper } from './confim.box.styled';
import { Button } from '../hoc/button'

const ConfirmBox = props => {

    const { close, popupKey, option, data, message } = props
    const { func } = data;
    const { setYes } = func;

    return (
        <Wrapper>
            <MessageWrapper>{message}</MessageWrapper> 
            <ButtonWrapper>
                <Button
                    primary={true}
                    width="6rem"
                    mR="1.143rem"
                    onClick={() => {

                        close(option, popupKey);
                        setTimeout(() => {
                            setYes();
                        }, 500)
                    }}
                >
                    Yes
                </Button>

                <Button
                    width="6rem"
                    bgColor="#e6e6e6"
                    color="#000000"
                    onClick={() => {
                        close(option, popupKey);
                    }}
                >
                    No
                </Button>
            </ButtonWrapper>
        </Wrapper>
    );
};

export default ConfirmBox;