import React from 'react';
import * as _ from 'lodash';

import { Wrapper, RankWrapper, RightWrapper, LeftWrapper, LowerContentWrapper, ViewWrapper, MainWrapper, SubCategoryWrapper } from './rank.cell.styled';
import RotatingArrows from '../rotating-arrows';
import RankTable from '../rank-table';

import { getNameInitials } from '../../utils';
import { constants } from '../../utils/constants';

const RankCell = props => {

    const {
        data,
        columnMeta,
        nameFields,
        comparisonFields,
        rankIndex,
        isSelected,
        rankTableFields,
        rankField,
        showArrow,
        showPrimary,
        primaryName,
        cellClicked,
        extraData,
        compareData,
        _setCompareData
    } = props;


    // const whichField = showPrimary ? primaryName : 'specialization';
    // const name = (data && data[whichField]) ? getNameInitials(data[whichField]) : undefined;

    return (
        <MainWrapper>
            <Wrapper
                style={{ cursor: 'pointer' }}
                onClick={() => cellClicked(rankIndex)}>
                <LeftWrapper>
                    {
                        showPrimary &&
                        <RankWrapper>
                            <div>
                                {rankIndex + 1}
                            </div>
                        </RankWrapper>
                    }

                    {/* {
                        isSelected &&
                        <SelectedWrapper>
                            {position}
                        </SelectedWrapper>
                    } */}
                    <div
                        style={{
                            display: 'flex'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            {
                                nameFields && nameFields.length > 0 && nameFields.map((field, index) => {
                                    const itemToDisplay = columnMeta && columnMeta[field] ? columnMeta[field]['display_value'] : field;
                                    const displayValue = data[itemToDisplay];

                                    if (displayValue) {
                                        return (
                                            <div
                                                style={{
                                                    textTransform: 'capitalize',
                                                    fontWeight: index === 0 ? 'bolder' : '',
                                                    marginRight: '5px',
                                                    verticalAlign: 'middle',
                                                    paddingBottom: isSelected ? '5px' : undefined,
                                                    borderBottom: isSelected ? '2px solid #e1e1e1' : undefined
                                                }}
                                            >
                                                {displayValue}
                                            </div>
                                        )
                                    }
                                    else {
                                        //
                                    }
                                })
                            }
                        </div>
                    </div>
                </LeftWrapper>


                <RightWrapper>
                    {
                        showPrimary && comparisonFields && comparisonFields.length > 0 && comparisonFields.map((com, index) => {
                            const nameToDisplay = columnMeta && columnMeta[com['name']] ? columnMeta[com['name']]['display_value'] : com.name;
                            const icon = com['icon'];

                            return (
                                <LowerContentWrapper>
                                    <div
                                        style={{
                                            display: 'flex',
                                            width: '20%',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                        title={_.startCase(_.lowerCase(nameToDisplay))}
                                    >
                                        <img
                                            src={constants.CONTEXT_PATH + './' + icon}
                                            style={{ width: '1.2rem' }}
                                            alt='nf'
                                        />
                                        <div
                                            style={{
                                                fontSize: '.8rem'
                                            }}
                                        >
                                            {data[nameToDisplay]}
                                        </div>
                                    </div>
                                </LowerContentWrapper>
                            )
                        })
                    }


                    <ViewWrapper
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexDirection: 'row'
                        }}
                    >
                        {
                            showArrow ?
                                <RotatingArrows
                                    onClick={() => cellClicked(rankIndex)}
                                    isExpand={isSelected}
                                />
                                :
                                <div
                                    style={{
                                        fontSize: '.8rem'
                                    }}
                                >
                                    Recommendations
                                </div>
                        }

                    </ViewWrapper>
                </RightWrapper>
            </Wrapper>


            {
                isSelected && extraData && extraData.length > 0 &&
                <SubCategoryWrapper>
                    <RankTable
                        data={extraData}
                        nameFields={nameFields}
                        comparisonFields={comparisonFields}
                        showPrimary={true}
                        primaryName={primaryName}
                        rankTableFields={rankTableFields}
                        rankField={rankField}
                        _setCompareData={_setCompareData}
                        compareData={compareData}
                    />
                </SubCategoryWrapper>
            }


        </MainWrapper>
    )
};

export default RankCell;