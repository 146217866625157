import * as actions from '../action-types/data-hierarchy'

const initialState = {
    type: actions.DATA_HIERARCHY_INIT,
    data_hierarchy_data: undefined,
    dimensionByHierarchy: undefined,
    table_column_hints: undefined,
    hints_other_information: undefined,
};


const dataHierarchy = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }


    switch (action.type) {
        case actions.GET_DATA_HIERARCHY:
            return {
                ...state,
                type: action.type,
                data_hierarchy_data: action.data
            }
        case actions.GET_DIMENSION_BY_HIERARCHY:
            return {
                ...state,
                type: action.type,
                dimensionByHierarchy: action.data
            }
        case actions.GET_HINTS_FOR_DATA_HIEARCY:

            const hints_ = [];
            const _hints_other_information = {};

            if (action?.data?.length > 0) {
                action.data.map((h) => {
                    const str = h.table_name + "." + h.column_name
                    if (hints_.indexOf(str) == -1) hints_.push(str)
                    _hints_other_information[str] = h;
                })
            }


            return {
                ...state,
                type: action.type,
                table_column_hints: hints_, //action.data?.
                hints_other_information: _hints_other_information


            }
        default:
            return {
                ...state,
            }
    }
}

export default dataHierarchy;