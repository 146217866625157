import React, { useEffect, useState } from 'react'
import DirectoryChooser from './directory.chooser';
import TextBoxV1 from '../hoc/text.box.v1';
import { Wrapper, ProgressOverlay } from './directory.upload.styled'
import { Button } from '../hoc/button';
import { connect } from 'react-redux';
import { scanDirectoryFiles, clear_scanned_dir_files, save_mapping_or_upload_data } from '../../actions/directory_upload'
import TextFileColumnMapping from './file.mapping.window';
import { constants } from '../../utils/constants';
import { NormalButton } from '../hoc/normal.button';
import axios from 'axios';
import * as util from '../../utils'
import Overlay from '../helper/overlay';
import { getAllFiles } from '../../actions/file.uploader';


const file_mapping_struct = {
    column: '',
    db_column: '',
    postion: '',
    demilited_by: ''
}


const DirectoryUpload = (props) => {


    const { directory_files, setActiveTab } = props;


    const get_mt_object = () => {
        return JSON.parse(JSON.stringify(file_mapping_struct))
    }


    // const [directory_path, set_directory_path] = useState('/home/sarojkumar/Downloads/text_files');
    const [directory_path, set_directory_path] = useState('/vol1/r_data/vdh/text_files');
    // const [directory_path, set_directory_path] = useState('/vol1/r_data/vdh/text_files');


    const [active_file_id, set_active_file_id] = useState(undefined);
    const [local_dir_files, set_local_dir_files] = useState([]);

    const [active_file_key, set_active_file_key] = useState(undefined);


    const [uploadingData, setUploadingData] = useState(undefined);
    const [uploadFailed, setUploadFailed] = useState(undefined);
    const [complete, setComplete] = useState(undefined);



    // const [show_other_column, set_show_other_column] = useState(undefined);


    useEffect(() => {
        // set_local_dir_files(directory_files)

        // console.log("directory_files", directory_files)


        if (directory_files?.length > 0) {

            const new_directory_files = [];

            var first_file_id = undefined;
            var first_file_key = undefined;

            var is_other_column_show = undefined;

            directory_files.map((file) => {

                const file_mapping = file.file_mapping;
                const predefined_mappings = file.predefined_mappings || [];

                const mapping = file.is_mapping_exist ? file_mapping : (predefined_mappings || []);

                if (mapping && Object.keys(mapping).length > 0) {

                    Object.keys(mapping).forEach((k) => {

                        const identified_attributes = (mapping[k]?.identified_attributes || []).map((a) => {
                            return {
                                ...a,
                                identified_separator_count: mapping[k]?.identified_separator_count,
                                separator: mapping[k]?.separator,
                            }
                        });

                        identified_attributes.push(get_mt_object())

                        mapping[k].identified_attributes = identified_attributes;

                    })

                }
                // let's push mt object for each mapping

                if (!first_file_id) {
                    first_file_id = file.__id;
                    if (file?.child_keys?.length > 0 && !first_file_key) {
                        first_file_key = file?.child_keys[0];
                    }

                }


                // mapping.push(get_mt_object())

                file.file_mapping = mapping;

                if (!file.table_name) {
                    file.table_name = file?.file_name?.split(".")[0];
                }
                new_directory_files.push(file);

            })
            // set_show_other_column(is_other_column_show)
            set_active_file_id(first_file_id)
            set_active_file_key(first_file_key)
            set_local_dir_files(new_directory_files)
        } else {

            set_local_dir_files(undefined)
        }

    }, [directory_files])





    const selected_file = local_dir_files?.length > 0 && local_dir_files.find((f) => active_file_id === f.__id)
    const active_file_mapping_index = local_dir_files?.length > 0 && local_dir_files?.findIndex((file) => file.__id === active_file_id);


    const on_change = (index, key, value) => {

        if (!active_file_key) return;

        const clone_data = local_dir_files ? [...local_dir_files] : [];
        const active_mapping_file = local_dir_files[active_file_mapping_index];
        const mapping_data = selected_file?.file_mapping?.[active_file_key]?.identified_attributes;


        mapping_data[index][key] = value;
        mapping_data[index]['modified'] = true;


        const last_row = mapping_data[mapping_data.length - 1];

        if (last_row && last_row["column"]) {
            mapping_data.push(get_mt_object())
        }

        active_mapping_file.file_mapping[active_file_key]['identified_attributes'] = mapping_data
        clone_data[active_file_mapping_index] = active_mapping_file;
        set_local_dir_files(clone_data);

    }




    const delete_row = (index) => {

        const clone_data = local_dir_files ? [...local_dir_files] : [];


        const active_mapping_file = local_dir_files[active_file_mapping_index];

        const active_mapping_table = selected_file?.file_mapping?.[active_file_key];

        const mapping_data = active_mapping_table?.identified_attributes;

        const id_of_mapping = mapping_data?.[index]?.id;

        if (id_of_mapping) {

            mapping_data[index].deleted = true;
        } else {
            mapping_data.splice(index, 1);


        }
        active_mapping_file.file_mapping[active_file_key]["identified_attributes"] = mapping_data
        clone_data[active_file_mapping_index] = active_mapping_file;

        set_local_dir_files(clone_data);

    }


    const change_table_name = (file_index, table_name) => {
        const clone_data = local_dir_files ? [...local_dir_files] : [];
        const active_mapping_file = local_dir_files[file_index];
        active_mapping_file['table_name'] = table_name;
        set_local_dir_files(clone_data);

    }


    const upload_file_data_v3 = (file_path, mapping_files_data) => {

        const options = {
            headers: util.getMandatoryRequestHeaders(),
        }

        const body = {
            file_path: file_path,
            mapping_files: mapping_files_data,
        }

        setUploadingData(true)
        setComplete(undefined)
        setUploadFailed(undefined)

        const url = constants.END_POINTS.API + constants.END_POINTS.FILE_UPLOAD_INFO.POINT + constants.END_POINTS.FILE_UPLOAD_INFO.SAVE_FOLDER_FILE_MAPPING_DATA;
        axios.post(url, body, options).then(res => {

            if (res.status == 200 && res.data && res.data.code == 200) {
                setUploadingData(false)
                setUploadFailed(undefined)
                setComplete(true)
                setTimeout(() => {
                    setComplete(false)

                }, 1000)

            } else {
                setUploadingData(false)
                setUploadFailed(true)
                setComplete(false)
                setTimeout(() => {
                    setUploadingData(false)
                    setUploadFailed(false)
                    setComplete(false)

                }, 1000)
            }

            setTimeout(() => {

                props.getAllFiles()
                props.clear_scanned_dir_files()
                setActiveTab("Manage Your Files")
                set_local_dir_files(undefined)
                set_directory_path('')
                set_active_file_id(undefined)

            }, 1000);
        })

    }



    const db_column_hints = [] //get_db_columns_hint();

    const mapping_data = active_file_key && selected_file?.file_mapping?.[active_file_key]?.identified_attributes;

    const show_other_column = mapping_data?.some((a) => a.sample_value)


    return (
        <Wrapper>


            {(complete || uploadingData || uploadFailed) && (

                <ProgressOverlay>
                    <div className="content">

                        {uploadingData && !complete && !uploadFailed && (
                            <React.Fragment>
                                <img src={constants.CONTEXT_PATH + './loader.gif'} style={{ width: '110px', }} />
                                <h1 style={{ marginTop: '1rem' }}>File Processing...</h1>
                            </React.Fragment>
                        )}


                        {uploadFailed && (
                            <React.Fragment>
                                <img src={constants.CONTEXT_PATH + './error.png'} style={{ width: '150px', }} />
                                <h1 style={{ marginTop: '1rem' }}>Uploaded Failed</h1>
                            </React.Fragment>
                        )}

                        {complete && (
                            <React.Fragment>
                                <img src={constants.CONTEXT_PATH + './upload.loader.gif'} style={{ width: '150px', }} />
                                <h1 style={{ marginTop: '1rem' }}>Data Uploaded SuccessFully</h1>
                            </React.Fragment>
                        )}
                    </div>
                </ProgressOverlay>
            )}


            <div
                style={{
                    position: directory_files ? 'relative' : 'absolute',
                    margin: directory_files ? '1rem 0px' : undefined,
                }}
                className='path_wrapper'>
              
                <input type='text' value={directory_path || ''}
                    placeholder={"Provide Your Directory Path here"}
                    onChange={(e) => set_directory_path(e.target.value)} />
                    <button className='view_files'  onClick={() => {
                            props.scanDirectoryFiles(directory_path)
                        }}>
                        View Files
                    </button>
              
            </div>
            {directory_files?.length == 0 && (
                <p>No Files Found... </p>
            )}

            {local_dir_files?.length > 0 && (
                <React.Fragment>
                    <div className='file_container_flex'>

                        <div className='file_list'>

                            <h2>All Different Files</h2>

                            <div className='file_table_wrapper'>
                                <table>
                                    <thead>
                                        <tr>
                                            <td>#</td>
                                            <td>File Name</td>
                                            <td>Mapping Found</td>
                                            {/* <td>Table Name </td> */}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {local_dir_files?.map((file, file_index) => {

                                            const is_active = active_file_id === file.__id;
                                            const mapping_found = file?.is_mapping_exist;

                                            return (
                                                <tr
                                                    className={is_active ? 'active' : '--'}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        if (!is_active) {
                                                            if (mapping_found) {
                                                                const result = window.confirm('Are you sure you want to edit mapping?');
                                                                if (result) {
                                                                    set_active_file_id(file.__id)
                                                                    set_active_file_key(file?.child_keys?.[0])
                                                                    // Code to execute if the user clicks "OK"
                                                                    // Add your logic or actions here.
                                                                } else {
                                                                    // Code to execute if the user clicks "Cancel"
                                                                    // Add your logic or actions here, if any.
                                                                    set_active_file_id(file.__id)
                                                                    set_active_file_key(file?.child_keys?.[0])

                                                                }
                                                            } else {
                                                                // Code to execute if the user clicks "Cancel"
                                                                // Add your logic or actions here, if any.
                                                                set_active_file_id(file.__id)
                                                                set_active_file_key(file?.child_keys?.[0])

                                                            }


                                                        }
                                                    }}>
                                                    <td>{file_index + 1}
                                                    </td>

                                                    <td>

                                                        <div style={{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                                                            <div>{file.file_name}</div>
                                                            {file?.child_keys?.length > 1 && (
                                                                <select value={active_file_key || "--"} onChange={(e) => {
                                                                    e.preventDefault();

                                                                    e.stopPropagation();
                                                                    set_active_file_key(e.target.value);
                                                                }}>
                                                                    {(file?.child_keys || [])?.map((k) => {
                                                                        return (
                                                                            <option value={k}>{k}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            )}

                                                        </div>
                                                    </td>
                                                    <td>{file.is_mapping_exist ? "Yes" : "No"}</td>
                                                    {/* <td style={{ padding: '0px 10px' }}>
                                                        <input
                                                            type="text"
                                                            style={{
                                                                width: '100%',
                                                                height: '2rem',
                                                                boxSizing: 'border-box',
                                                                padding: '.2rem'
                                                            }}
                                                            value={file?.["table_name"]}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();

                                                            }}
                                                            disabled={mapping_found}
                                                            onChange={(e) => {
                                                                if (!mapping_found) {
                                                                    change_table_name(file_index, e.target.value)
                                                                }
                                                            }}
                                                        />
                                                    </td> */}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="mapping_wrapper">
                            <div style={{ display: 'flex', alignItems: 'center', }}>
                                {/* <img style={{ width: '50px' }} src={constants.CONTEXT_PATH + './finger.png'}></img> */}
                                <h2>Please Provide Required Attributes

                                    {selected_file?.file_name ? <span> For <strong > ({selected_file?.file_name?.split(".")[0]})</strong></span> : ''}

                                </h2>
                            </div>

                            {!active_file_id && (
                                <div className='choose_file_for_mapping'>Please Choose File For Mapping</div>
                            )}

                            {active_file_id && selected_file && (
                                <TextFileColumnMapping
                                    db_column_hints={db_column_hints}
                                    on_change={on_change}
                                    delete_row={delete_row}
                                    mapping_data={mapping_data || []}
                                    minHeight="25rem"
                                    maxHeight="25rem"
                                    showExampleColumn={show_other_column}
                                // showPositionColum={show_other_column}
                                />
                            )}

                        </div>
                    </div>

                    <div style={{
                        marginTop: 'auto',
                        marginLeft: 'auto',
                        // marginRight: '0px',
                        padding: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>

                        <NormalButton
                            height={'2.5rem'}
                            onClick={() => {

                                // let's validate the inputs first 
                                // const new_directory_files = [];
                                // if (local_dir_files?.length > 0) {
                                //     local_dir_files.forEach(file => {
                                //         const valid_file_mappings = file.file_mapping.filter((f) => {
                                //             if (f.column) {
                                //                 return true;
                                //             } else return false;
                                //         })
                                //         new_directory_files.push({
                                //             ...file,
                                //             file_mapping: valid_file_mappings || []
                                //         })
                                //     })
                                // }

                                // if (new_directory_files?.length > 0) {

                                //     const errors = [];

                                //     console.log("new_directory_files", new_directory_files)

                                //     new_directory_files.map((f, index) => {

                                //         if (f.file_mapping.length == 0 || !f.file_mapping) {
                                //             errors.push(`Please Provide Mapping for ${f.file_name}`)
                                //         }
                                //     })

                                //     if (errors?.length > 0) {
                                //         alert(errors.join('\n'))

                                //     } else {
                                //         // props.save_mapping_or_upload_data(directory_path, new_directory_files)
                                //         upload_file_data_v3(directory_path, local_dir_files)

                                //     }


                                // } else {
                                //     alert('please provide mapping for all files')
                                // }
                                upload_file_data_v3(directory_path, local_dir_files)



                            }}
                            style={{ fontWeight: 'bold', border: '1px solid #000', borderRadius: '0px' }}
                            hoverBg={"#086f9d"}
                            label="SAVE & UPLOAD"
                            icon={<img src={constants.CONTEXT_PATH + './upload_sm.png'} style={{ width: '35px' }} />}
                        />
                    </div>
                </React.Fragment>
            )}


        </Wrapper>
    )
}




const mapStateToProps = (state) => ({

    directory_files: state.DirectoryUpload?.directory_files

})


export default connect(mapStateToProps, { clear_scanned_dir_files, getAllFiles, scanDirectoryFiles, save_mapping_or_upload_data })(DirectoryUpload);