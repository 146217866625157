import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  hr{
    background: #ccc;
    border: none;
    height: 1px;
  }
  `;

export const TableMainDiv = styled.div`
  width: 100%;
  margin-bottom: 40px;

  &::-webkit-scrollbar {
    background-color: #3f3f3f;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f3f3f3;
    border: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #dbdbdb;
    border-radius: 10px;
    height: 4px;
  }
  &::-webkit-scrollbar:horizontal {
    background-color: #3f3f3f;
    height: 4px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  .column_list {
    font-size: 1rem;
    color: #969696;
    line-height: 3.286rem;
    font-weight: 500;
    padding: 0px 10px;
  }

  .sticky_top {
    position: sticky;
    z-index: 0;
    margin-top: 25px;
    background: #fff;
    top: calc(3.517rem - 25px);
    background: #fff;
  }
  .addBtn {
    margin-top: 1rem;
  }
`;


export const RowDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  border-bottom: ${(props) => (props.border ? undefined : "1px solid #eaeaea")};
  box-sizing: border-box;
  justify-content: ${(props) => props.justify || undefined};
  cursor: pointer;
  &:hover {
    .remove_column {
      opacity: 1;
    }
  }

  .table_name {
    height: 2.214rem;
    color: #fff;
    background: #24a8d6;
    font-size: 1.286rem;
    text-transform: capitalize;
    display: flex;
    align-items: center;
  }

  .tag_name {
    padding: 1.4rem 1rem;
    box-sizing: border-box;
    height: 36px;
    border: none;
    background: #cde0fffa;
    opacity: 1;
    font-size: 1rem;
    font-weight: normal;
    line-height: 2.1rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
  }

  .tag_action {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    div {
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #969696;
      svg {
        margin-right: 6px;
        fill: #969696;
      }
      &:hover {
        svg {
          fill: #1a75bb;
        }
        color: #1a75bb;
      }
    }

    .edit {
      margin-right: 15px;
    }
  }
  .remove_column {
    padding: 0px 1rem;
    opacity: 0;
    svg {
      fill: #fff;
    }
  }
`;

export const TdDiv = styled.div`
  font-size: 0.9em;
  border: 0px;
  border-collapse: collapse;
  box-sizing: border-box;
  padding: 10px 20px;
  width: ${(props) => props.width || "12%"};
  text-align: ${(props) => props.align || "left"};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;


  .__placholder{
      height: 2.6rem;
      width: 100%;
      background: #e3eeff;
      border-radius: 8px;
      padding: 2px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      
      &:hover{
        opacity: 0.9;
      }
  }
  .selectedItems{
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 9px;
    padding-right: 6px;
  }
 .accessGroupopen{
  /* text-align: right; */
  width: 9%;
  svg {
        transform:${(props) => props.isOpenaccess ? 'rotate(270deg)' : 'rotate(90deg)'};
    }
 }
  .value_selected{
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 4px;
    /* flex-wrap: wrap; */
    height: 100%;
    padding-bottom: 0.14rem;
    overflow-y: hidden;
    overflow-x: auto;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        background-color: #3F3F3F;
        width:3px;
    }

    &::-webkit-scrollbar-track {
        background-color: #F3F3F3;
        border: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dbdbdb;
        border-radius: 10px;
        height: 3px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color:  #3F3F3F;
        height: 4px;
    }
  }
  
  .value_item{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.6rem;
      background: #eaeaee;
      border-radius: 6px;
      padding: 0 0.5rem;
  }
  .remove_item_icon{
    margin-left: 8px;
    margin-right: 4px;
  }

`;


export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

export const OverlayMain = styled.div`
    width: 100vw;
    height: 100vh;
    z-index: 1200;
    position: fixed;
    top: 0px;
    left: 0px;
    background: #ff000000;
`;

export const SelectionWrapper = styled.div`
    width: ${props => (props?.width + "px") || '100%'};
    top : ${props => props?.top + "px" || "0px"};
    left : ${props => props?.left + "px" || "0px"};
    box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
    background-color: #fff;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-top: 0px;
    max-height: 180px;
    min-height: 180px;
    overflow: hidden;
    position: absolute;

    &::-webkit-scrollbar {
        background-color: #3F3F3F;
        width:4px;
    }

    &::-webkit-scrollbar-track {
        background-color: #fff;
        border: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dbdbdb;
        border-radius: 10px;
        height: 4px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color:  #3F3F3F;
        height: 4px;
    }

`;
