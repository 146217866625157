import { simpleDispatch_post, simpleDispatch_put , simpleDispatch_get} from './helper';
import * as actionTypes from '../action-types/menu.group';
import { constants } from '../utils/constants';

export const getMenus = (c_id) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.MENU_GROUP.POINT + constants.END_POINTS.MENU_GROUP.GET_MENUS;
    const post = {
        c_id
    }

    simpleDispatch_post(dispatch, url, post, actionTypes.MENU_GROUP_DATA_SUCCESS, true);
};



export const getMenuGroups = (c_id) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.MENU_GROUP.POINT + constants.END_POINTS.MENU_GROUP.GET_MENU_GROUPS;
    simpleDispatch_get(dispatch, url, actionTypes.GET_MENU_GROUPS_SUCCESS, true);
    
    
};


export const saveMenu = menu => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.MENU_GROUP.POINT + constants.END_POINTS.MENU_GROUP.SAVE_MENU;
    
    const post = {
        menu
    };

    await simpleDispatch_put(dispatch, url, post, actionTypes.MENU_GROUP_DATA_SUCCESS, true);
    getMenus()(dispatch);
};






export const get_report_menus = (hideLoader) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.MENU_GROUP.POINT + constants.END_POINTS.MENU_GROUP.GET_REPORT_MENUS_V1;
    simpleDispatch_get(dispatch, url, actionTypes.GET_REPORT_MENUS_V1, hideLoader);

};