
/**
 * 
 * @param {*} report_items 
 * @param {*} wrapper_height 
 * @param {*} wrapper_width 
 * 
 * this function will assume that the report_items have a height/width stored as percentage
 * 
 * the percent height and width will never change, except ofcourse if we change the height and width
 * of control
 * 
 * otherwise, the percentage will remain same, only the px_height and px_width will change
 * 
 * here, we will pick up the dimensions according to the wrapper
 */

import { generate_unique_key } from "../../utils";

export const update_px_for_report_items = (report_items, wrapper_height, wrapper_width) => {
    const local_report_items = report_items.map(report_item => {
        const new_report_item = update_px_for_report_item(report_item, wrapper_height, wrapper_width);

        console.log("new_report_item", new_report_item)
        return new_report_item;
    });

    return local_report_items;
};


export const update_px_for_report_item = (report_item, wrapper_height, wrapper_width) => {
    console.log("wrapper_height", wrapper_width + " * " + wrapper_height)
    const local_report_item = JSON.parse(JSON.stringify(report_item));

    if (!local_report_item.top) local_report_item.top = 0.02;
    if (!local_report_item.left) local_report_item.left = 0.02;
    if (!local_report_item.width) local_report_item.width = 0.02;
    if (!local_report_item.height) local_report_item.height = 0.02;

    local_report_item.top_px = parseFloat((local_report_item.top || 0.02)) * wrapper_height;
    local_report_item.height_px = parseFloat(local_report_item.height || 0.02) * wrapper_height;
    local_report_item.left_px = parseFloat(local_report_item.left || 0.02) * wrapper_width;
    local_report_item.width_px = parseFloat(local_report_item.width || 0.02) * wrapper_width;

    return local_report_item;
};



/**
 * 
 * @param {*} report_items 
 * @param {*} selected_ids 
 * 
 * @returns the new collection object marked as deleted
 * 
 * marks an item as deleted, if found in selected ids
 * 
 */
export const mark_reports_deleted = (report_items, selected_ids) => {
    const ids = {};
    const local_copy_report_items = [...report_items];

    selected_ids && selected_ids.forEach(id => ids[id] = true);

    local_copy_report_items.forEach(item => {
        if (ids[item.id.toLocaleLowerCase()]) {
            item.deleted = true;
            if (item?.union_report_items?.length > 0) {
                // let's mark union deleted
                item.union_report_items?.forEach(union => {
                    union.deleted = true;
                })
            }
        }
    });

    return local_copy_report_items;
};



export const paste_items = (report_items, copied_ids) => {

    const ids = {};

    const local_copy_report_items = [...report_items];

    const new_items = [];

    copied_ids && copied_ids.forEach(id => ids[id] = true);

    local_copy_report_items.forEach(item => {

        const clone_column_infos = item?.column_infos ? JSON.parse(JSON.stringify(item.column_infos)) : [];

        if (ids[item?.id?.toLocaleLowerCase()]) {

            const new_item = {
                ...item,
                id: generate_unique_key('rpt'),
                top: item.top + 0.05,
                left: item.left + 0.05,
                top_px: item.top_px + (item.top_px * 0.05),
                left_px: item.left_px + (item.left_px * 0.05)
            }

            delete new_item["job_id"];
            delete new_item["incremental_date_columns"];

            const new_connected_ids_tag = generate_unique_key('c_tag');

            // let's change the connected_ids_tag 

            if (item?.connected_ids_tag) {

                new_item.connected_ids_tag = new_connected_ids_tag;

                if (item.union_report_items?.length > 0) {

                    const cloned_union_report_items = item.union_report_items ? JSON.parse(JSON.stringify(item.union_report_items)) : [];
                    // union_report_items

                    const new_union_report_items = [];

                    cloned_union_report_items.forEach((r_item) => {

                        const union_copy_item = make_copy_report_item(r_item);

                        union_copy_item.id = generate_unique_key('rpt_union');
                        union_copy_item.modified = true;
                        union_copy_item.connected_ids_tag = new_connected_ids_tag;

                        new_union_report_items.push(union_copy_item)

                    })

                    new_item.union_report_items = new_union_report_items;
                }

            } else {

                new_item.connected_ids_tag = undefined;
            }

            const new_column_infos = [];

            if (clone_column_infos && clone_column_infos?.length > 0) {
                clone_column_infos.forEach(c => {

                    const new_nf_formula_conditions = [];

                    const nf_formula_conditions = c?.nf_formula_conditions ? JSON.parse(JSON.stringify(c.nf_formula_conditions)) : [];

                    if (nf_formula_conditions && nf_formula_conditions?.length > 0) {
                        nf_formula_conditions?.forEach((f_c) => {
                            new_nf_formula_conditions.push({
                                ...f_c,
                                id: undefined,
                                column_info_id: undefined,
                                report_item_id: undefined
                            })
                        })
                    }

                    new_column_infos.push({
                        ...c,
                        report_item_id: undefined,
                        id: undefined,
                        nf_formula_conditions: new_nf_formula_conditions
                    })
                })
            }
            new_item.column_infos = new_column_infos;
            new_items.push(new_item);
        }
    });

    return new_items;
};


export const make_copy_report_item = (report_item) => {


    const local_copy_report_items = report_item ? JSON.parse(JSON.stringify(report_item)) : {};



    const clone_column_infos = local_copy_report_items?.column_infos ? JSON.parse(JSON.stringify(local_copy_report_items.column_infos)) : [];


    const new_item = {
        ...local_copy_report_items,
        id: generate_unique_key('rpt'),
        top: local_copy_report_items.top + 0.05,
        left: local_copy_report_items.left + 0.05,
        top_px: local_copy_report_items.top_px + (local_copy_report_items.top_px * 0.05),
        left_px: local_copy_report_items.left_px + (local_copy_report_items.left_px * 0.05)
    }

    delete new_item["job_id"];
    delete new_item["incremental_date_columns"];

    const new_column_infos = [];

    if (clone_column_infos && clone_column_infos?.length > 0) {
        clone_column_infos.forEach(c => {

            const new_nf_formula_conditions = [];

            const nf_formula_conditions = c?.nf_formula_conditions ? JSON.parse(JSON.stringify(c.nf_formula_conditions)) : [];

            if (nf_formula_conditions && nf_formula_conditions?.length > 0) {
                nf_formula_conditions?.forEach((f_c) => {
                    new_nf_formula_conditions.push({
                        ...f_c,
                        id: undefined,
                        column_info_id: undefined,
                        report_item_id: undefined
                    })
                })
            }

            new_column_infos.push({
                ...c,
                report_item_id: undefined,
                id: undefined,
                nf_formula_conditions: new_nf_formula_conditions
            })
        })
    }
    new_item.column_infos = new_column_infos;

    return new_item;

}