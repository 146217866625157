import React from 'react';

const AngleUp = (props) => {
    return (
        <svg
            
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 447 248.2"
            space="preserve">
            <path d="M7.1,241c9.6,9.6,25.2,9.6,34.8,0L223.6,59.3L405.3,241c9.6,9.6,25.2,9.6,34.8,0c9.6-9.6,9.6-25.2,0-34.8l-199-199
	            C236.3,2.4,230,0,223.7,0l0,0l0,0c-6.3,0-12.6,2.4-17.4,7.2l-199,199C-2.5,215.8-2.5,231.4,7.1,241z"/>
        </svg>

    )

}


export default AngleUp;