import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    /* height: 100vh; */
    position: relative;
    padding: 0rem;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    z-index: 4;
    background: linear-gradient(to right, #f3faff, #d4e2ff);
    font-family: 'DMSans';
    /* font-weight: 300; */

    input{
        font-family: 'DMSans';
        font-weight: 300;

        &::placeholder{
            font-family: 'DMSans';
            font-weight: 300;
        }
    }
    

`;



export const HeaderOptions = styled.div`

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0px;
        z-index: 4;



        .option_name{

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: .9rem;
            margin-right: 1.4rem;
            cursor: pointer;
            padding: 5px 10px;
            border-radius: 5px;
            background-color: #fff;
            margin-top: 1rem;

            span{
                display: block;
                font-size: .8rem;
            }

            &:hover{
                .__icon{
                     background: linear-gradient(to right, #3E9EFF, #737DFF);
                     svg{
                        fill: #fff;
                     }

                }
            }
        }
        

        .__icon{
            width: 2.5rem;
            height: 2.5rem;
            margin-bottom: 10px;
            border-radius: 50%;
            background-color: #fff;
             box-shadow: 2px 2px 3px #caccce;
             display: flex;
             align-items: center;
             justify-content: center;
             padding: 15px;
             box-sizing: border-box;
             img{
                max-width: 100%;
            }
        }

`;

export const HeaderContainer = styled.header`
    height: 60px;
    display: flex;
    align-items: start;
    justify-content: flex-start;

`;

export const BodyContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: baseline;
    width: 100%;
    margin: 0 auto;
    max-height: calc(100vh - 10rem);
    padding-bottom: 2rem;
    overflow: auto;
    scroll-behavior: smooth;
    position: relative;
    z-index: 2;
    &::-webkit-scrollbar {
        background-color: #3F3F3F;
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background-color: #F3F3F3;
        border: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dbdbdb;
        border-radius: 10px;
        height: 4px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color:  #3F3F3F;
        height: 4px;
    }
`;
export const BackgroundIconContainer = styled.div`
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.07;
    z-index: 0; 
`;
export const SearchWrapper = styled.footer`
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    flex-direction: column;
    bottom: 6px;
    width: 70%;
    margin: 0px auto;
    padding-right: 2rem;
    right: 0px;
    left: 0px;
    box-sizing: border-box;
    transition: transform 0.2s ease-in-out;
    transform: ${(props) => (props.isInitialPageInput ? 'translateY(-30rem)' : 'translateY(-1rem)')};
    z-index: 2;
`;


