import { colors, pallets } from './colors';
import { getSelectedTheme } from '../utils'

const s = getSelectedTheme()
const selected = pallets[s];

export const themes = {
    primaryTheme: {

        label: {
            color: selected.text_colors.primary
        },
        bodyStyle: {
            color: {
                bgColor: selected.background_colors.secondary
            }
        },
        textbox: {
            border: {
                radius: '5px',
                color: colors.gray,
                thickness: '1.2px',
                hoverColor: colors.secondary,
                focusColor: colors.secondary,
            },
            color: colors.codGray,
            background: colors.white,
        },
        selectBoxConfig: {
            color: {
                label: selected.text_colors.tertiary,
                labelFocus: selected.color_sets.primary,
                selectValue: selected.text_colors.tertiary,
                dropdownIcon: selected.text_colors.tertiary
            },
            border: {
                defaultBottom: `1px solid ${selected.border_colors.primary}`,
                HoverBottom: `1px solid ${selected.border_colors.forth}`,
            },
            shadow: {
                dropdownBox: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
            }
        },
        textFieldStyle: {
            color: {
                label: selected.text_colors.tertiary,
                labelFocus: selected.color_sets.primary,
            },
            border: {
                defaultBottom: `1px solid ${selected.border_colors.primary}`,
                HoverBottom: `1px solid ${selected.border_colors.forth}`,
                focusBorder: `2px solid ${selected.color_sets.primary}`
            },
        },

        header: {
            color: {
                background: selected.background_colors.primary,
                hamburger: selected.color_sets.primary,
                home: selected.color_sets.primary,
                search: selected.color_sets.primary,
                voice: selected.color_sets.primary,
                searchBoxBorder: selected.border_colors.primary,
                searchBoxPlacholder: selected.text_colors.primary,
                searchBoxText: selected.text_colors.primary,
                autoSuggestTxt: selected.text_colors.primary,
                autoSuggestTxtHover: selected.color_sets.primary,
                autoSuggestIcon: selected.color_sets.primary,
                iconHover: selected.color_sets.secondary,
                settingDefault: selected.color_sets.primary,
                logoutHoverBg: selected.color_sets.primary,
                logoutColorOnHover: selected.text_colors.sixth,
            },
            shadow: {
                headerMain: '0 2px 4px 0 rgba(0, 0, 0, 0.16)'
            }
        },

        defaultSvgStyle: {
            color: {
                icon: selected.color_sets.primary
            }
        },

        defaultChartSvgStyle: {
            color: {
                icon: selected.chart_colors.primary
            }
        },

        righterMenuConfig: {

            menu: {
                color: {
                    icon: selected.color_sets.primary,
                    iconWrapperActive: selected.color_sets.seventh,
                },

                border: {
                    iconWrapper: selected.border_colors.fifth
                }
            },
            chartOptions: {

                color: {
                    icon: selected.color_sets.primary,
                    iconActive: selected.color_sets.seventh,
                    iconDisable: selected.color_sets.nine,
                    iconWrapperActive: selected.color_sets.seventh,
                    iconWrapper: selected.color_sets.tenth,
                    wrapperBg: selected.color_sets.tenth,
                    headerClose: selected.color_sets.primary,
                    labelColor: selected.text_colors.primary
                },

                border: {
                    wrapperBorderLeft: `1px solid ${selected.border_colors.secondary}`,
                    headerBorderBottom: `1px solid ${selected.border_colors.secondary}`
                },

                shadow: {
                    iconWrapperActive: '1px 1px 3px #00000054',
                    iconWrapperHover: '1px 1px 6px 2px #cccccc75'
                },

                chartIcon: {
                    color: {
                        icon: selected.chart_colors.primary,
                        iconDisable: selected.color_sets.nine,
                        iconActive: selected.chart_colors.primary,
                    }
                },
            },
            favouritStyle: {
                color: {
                    rowBg: selected.color_sets.seventh,
                }
            }
        },


        chartOptionsConfig: {
            color: {
                icon: colors.primary,
                iconActive: colors.primary,
                iconDisable: colors.disableColor,
            }
        },

        chartStyle: {
            color: {
                AxisLable: selected.text_colors.primary,
                outerLabel: selected.text_colors.primary,
                yAxisStock: colors.secondary,
                chart_fill: selected.chart_colors.primary,

            }
        },

        polusIqStyle: {
            color: {
                iqWrapperBg: selected.color_sets.tertiary,
                iqTextColor: selected.text_colors.sixth
            }
        },
        lefterMenuConfig: {
            color: {
                backgroundRight: selected.background_colors.fifth,
                text: selected.text_colors.sixth,
                backgroundActive: selected.background_colors.fifth,
                backIcon: selected.color_sets.tenth,
                version: selected.text_colors.sixth,
                wrapperBg: selected.background_colors.sixth
            }
        },

        table: {
            color: {
                thBackground: selected.color_sets.forth,
                thText: selected.text_colors.primary,
                tdText: selected.text_colors.primary,
                tdBackgroundEven: selected.color_sets.tenth,
                tdBackgroundOdd: 'rgb(234 234 234 / 32%)',
                tdHoverBackground: '#add7eb9e'
                // background: 'red'
            }
        },

        grandTotalColumns: {
            color: {
                text: selected.text_colors.primary,
            },
            font: {
                textFontSize: '.9rem',
                textFontWeight: 600,
            },
            padding: {
                tdPadding: '.4rem 10px'
            }
        },

        tableNextPrev: {
            color: {
                disabled: '#c1c1c2',
                normal: '#0185fc'
            }
        },
        filter: {
            color: {
                background: colors.seventh
            }
        },


        trendFilterStyle: {
            color: {
                monthCardBg: selected.background_colors.primary
            }
        },
        titleHeader: {
            color: {
                titleText: selected.color_sets.secondary,
                titleBg: '#f7f7f8',
                options: selected.text_colors.primary,
                optionsHover: selected.color_sets.primary,
                icon: selected.color_sets.primary,
                iconHover: selected.color_sets.tenth,
                iconWrapper: selected.color_sets.seventh,
                iconWrapperHover: selected.color_sets.tertiary,
                rippleColor: selected.color_sets.tertiary,

                iqWrapperBg: selected.color_sets.primary,
                iqIcon: selected.color_sets.tenth,
            },
        },


        reportList: {
            color: {
                actionBackground: colors.lightGray,
                hover: colors.white,
            },

        },

        breadcrumbStyle: {
            color: {
                home: selected.color_sets.primary,
                wrapperBg: selected.background_colors.primary,
                text: selected.text_colors.primary,
                selectedText: selected.color_sets.primary,
            }

        },

        actionCardStyle: {
            color: {
                cardBg: selected.background_colors.primary,
                overlayBg: selected.background_colors.forth,
                overlayText: selected.text_colors.primary,
                iconWrapperHover: selected.color_sets.tenth,
                icon: selected.color_sets.tenth,
                iconHover: selected.color_sets.primary,
                mainTitle: selected.text_colors.primary,
                date: selected.text_colors.tertiary,
            },
            shadow: {
                card: '0px 2px 6px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
            }
        },

        checkboxStyle: {
            color: {
                wrapperHoverBg: 'rgba(220, 0, 78, 0.04)',
                ripple: '#2d95e72e',
                checkBoxBorder: colors.primary,
                checkBoxBg: colors.primary,
                radioBg: colors.primary,
            },
            border: {
                checkBox: colors.primary,
                tick: colors.white,
                unTick: colors.black,
                radio: colors.primary,
                radioChecked: colors.white,
                radioUnchecked: colors.black,
            }
        },

        buttonStyle: {
            color: {
                secondryColor: '#555',
                primaryBg: selected.color_sets.primary,
                disableBg: colors.disableColor,
                textColor: colors.white,
                focusBg: colors.tertiary,
                hoverBg: selected.color_sets.secondary,
                textHoverColor: selected.color_sets.primary

            },
            shadow: {
                btnShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
                activeShadow: 'box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
                hoverShadow: ' 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
                focusShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
            }
        },

        dispayAToZFiledStyled: {
            color: {
                wrapperBg: selected.background_colors.primary,
                azTabText: selected.text_colors.seventh,
                azTabHoverBg: selected.color_sets.eight,
                textColor: selected.text_colors.primary
            },
            border: {
                inputBorder: selected.border_colors.primary,
            }
        },

        connectStyle: {
            color: {
                popupBg: selected.background_colors.primary,
                iconBoxActive: selected.color_sets.sixth,
                iconBoxHover: selected.color_sets.sixth,
                innerBoxBg: selected.background_colors.primary,
            },
            border: {
                // activeBorder: colors.primary
            }
        },

        hocTitleHeader: {
            color: {
                lable: selected.text_colors.primary,
            }
        },

        insightStyle: {
            color: {
                title: selected.text_colors.primary,
                titleBg: selected.background_colors.primary,
                chartBoxBg: selected.background_colors.primary,
                mainBg: selected.background_colors.secondary
                // insightBoxBg: 'red',
                // insightBoxTitle: 'red',
            }
        },

        report: {
            color: {

            }
        },

        chart: {
            color: {
                ...selected.chart_colors
            }
        },
        rippleButton: {
            color: {
                iconColor: '#bdbdbd',
                iconHover: selected.color_sets.primary,
            }
        },
        singleCell: {
            color: {
                textColor: selected.text_colors.primary,
            }
        }
    },

    secondaryTheme: {
        label: {
            color: selected.text_colors.primary
        },

        bodyStyle: {
            color: {
                bgColor: selected.background_colors.secondary
            }
        },

        textbox: {
            border: {
                radius: '5px',
                color: colors.gray,
                thickness: '1.2px',
                hoverColor: colors.secondary,
                focusColor: colors.secondary,
            },
            color: colors.codGray,
            background: colors.white,
        },

        selectBoxConfig: {
            color: {
                label: selected.text_colors.tertiary,
                labelFocus: selected.color_sets.primary,
                selectValue: selected.text_colors.tertiary,
                dropdownIcon: selected.text_colors.tertiary
            },
            border: {
                defaultBottom: `1px solid ${selected.border_colors.primary}`,
                HoverBottom: `1px solid ${selected.border_colors.forth}`,
            },
            shadow: {
                dropdownBox: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
            }
        },


        textFieldStyle: {
            color: {
                label: selected.text_colors.tertiary,
                labelFocus: selected.color_sets.primary,
            },
            border: {
                defaultBottom: `1px solid ${selected.border_colors.primary}`,
                HoverBottom: `1px solid ${selected.border_colors.forth}`,
                focusBorder: `2px solid ${selected.color_sets.primary}`
            },
        },

        header: {
            color: {
                background: selected.background_colors.primary,
                hamburger: selected.color_sets.primary,
                home: selected.color_sets.primary,
                search: selected.color_sets.primary,
                voice: selected.color_sets.primary,
                searchBoxBorder: selected.border_colors.primary,
                searchBoxPlacholder: selected.text_colors.primary,
                searchBoxText: selected.text_colors.primary,
                autoSuggestTxt: selected.text_colors.primary,
                autoSuggestTxtHover: selected.color_sets.primary,
                autoSuggestIcon: selected.color_sets.primary,
                iconHover: selected.color_sets.secondary,
                settingDefault: selected.color_sets.primary,
                logoutHoverBg: selected.color_sets.primary,
                logoutColorOnHover: selected.text_colors.sixth,
            },
            shadow: {
                headerMain: '0 2px 4px 0 rgba(0, 0, 0, 0.16)'
            }
        },

        defaultSvgStyle: {
            color: {
                icon: selected.color_sets.primary
            }
        },

        defaultChartSvgStyle: {
            color: {
                icon: selected.chart_colors.primary
            }
        },

        righterMenuConfig: {

            menu: {
                color: {
                    icon: selected.color_sets.primary,
                    iconWrapperActive: selected.color_sets.seventh,
                },

                border: {
                    iconWrapper: selected.border_colors.fifth
                }
            },
            chartOptions: {

                color: {
                    icon: selected.color_sets.primary,
                    iconActive: selected.color_sets.seventh,
                    iconDisable: selected.color_sets.nine,
                    iconWrapperActive: selected.color_sets.seventh,
                    iconWrapper: selected.color_sets.tenth,
                    wrapperBg: selected.color_sets.tenth,
                    headerClose: selected.color_sets.primary,
                    labelColor: selected.text_colors.primary
                },

                border: {
                    wrapperBorderLeft: `1px solid ${selected.border_colors.secondary}`,
                    headerBorderBottom: `1px solid ${selected.border_colors.secondary}`
                },

                shadow: {
                    iconWrapperActive: '1px 1px 3px #00000054',
                    iconWrapperHover: '1px 1px 6px 2px #cccccc75'
                },

                chartIcon: {
                    color: {
                        icon: selected.chart_colors.primary,
                        iconDisable: selected.color_sets.nine,
                        iconActive: selected.chart_colors.primary,
                    }
                },
            },
            favouritStyle: {
                color: {
                    rowBg: selected.color_sets.seventh,
                }
            }
        },


        chartOptionsConfig: {
            color: {
                icon: colors.primary,
                iconActive: colors.primary,
                iconDisable: colors.disableColor,
            }
        },

        chartStyle: {
            color: {
                AxisLable: selected.text_colors.primary,
                outerLabel: selected.text_colors.primary,
                yAxisStock: colors.secondary,
                chart_fill: selected.chart_colors.primary,

            }
        },

        polusIqStyle: {
            color: {
                iqWrapperBg: selected.color_sets.tertiary,
                iqTextColor: selected.text_colors.sixth
            }
        },
        lefterMenuConfig: {
            color: {
                backgroundRight: selected.background_colors.fifth,
                text: selected.text_colors.sixth,
                backgroundActive: selected.background_colors.fifth,
                backIcon: selected.color_sets.tenth,
                version: selected.text_colors.sixth,
                wrapperBg: selected.background_colors.sixth
            }
        },

        table: {
            color: {
                thBackground: selected.color_sets.forth,
                thText: selected.text_colors.primary,
                tdText: selected.text_colors.primary,
                tdBackgroundEven: selected.color_sets.tenth,
                tdBackgroundOdd: 'rgb(234 234 234 / 32%)',
                tdHoverBackground: '#add7eb9e'
                // background: 'red'
            }
        },

        grandTotalColumns: {
            color: {
                text: selected.text_colors.primary,
            },
            font: {
                textFontSize: '.8rem',
                textFontWeight: 600,
            },
            padding: {
                tdPadding: '.4rem 10px'
            }
        },

        tableNextPrev: {
            color: {
                disabled: '#c1c1c2',
                normal: '#0185fc'
            }
        },
        filter: {
            color: {
                background: colors.seventh
            }
        },


        trendFilterStyle: {
            color: {
                monthCardBg: selected.background_colors.primary
            }
        },
        titleHeader: {
            color: {
                titleText: selected.color_sets.secondary,
                titleBg: '#f7f7f8',
                options: selected.text_colors.primary,
                optionsHover: selected.color_sets.primary,
                icon: selected.color_sets.primary,
                iconHover: selected.color_sets.tenth,
                iconWrapper: selected.color_sets.seventh,
                iconWrapperHover: selected.color_sets.tertiary,
                rippleColor: selected.color_sets.tertiary,

                iqWrapperBg: selected.color_sets.primary,
                iqIcon: selected.color_sets.tenth,
            },
        },


        reportList: {
            color: {
                actionBackground: colors.lightGray,
                hover: colors.white,
            },

        },

        breadcrumbStyle: {
            color: {
                home: selected.color_sets.primary,
                wrapperBg: selected.background_colors.primary,
                text: selected.text_colors.primary,
                selectedText: selected.color_sets.primary,
            }

        },

        actionCardStyle: {
            color: {
                cardBg: selected.background_colors.primary,
                overlayBg: selected.background_colors.forth,
                overlayText: selected.text_colors.primary,
                iconWrapperHover: selected.color_sets.tenth,
                icon: selected.color_sets.tenth,
                iconHover: selected.color_sets.primary,
                // mainTitle: selected.text_colors.primary,
                mainTitle: selected.text_colors.tertiary,
                // date: selected.text_colors.tertiary,
                date: selected.text_colors.primary,
            },
            shadow: {
                card: '0px 2px 6px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
            }
        },

        checkboxStyle: {
            color: {
                wrapperHoverBg: 'rgba(220, 0, 78, 0.04)',
                ripple: '#2d95e72e',
                checkBoxBorder: colors.primary,
                checkBoxBg: colors.primary,
                radioBg: colors.primary,
            },
            border: {
                checkBox: colors.primary,
                tick: colors.white,
                unTick: colors.black,
                radio: colors.primary,
                radioChecked: colors.white,
                radioUnchecked: colors.black,
            }
        },

        buttonStyle: {
            color: {
                secondryColor: '#555',
                primaryBg: selected.color_sets.primary,
                disableBg: colors.disableColor,
                textColor: colors.white,
                focusBg: colors.tertiary,
                hoverBg: selected.color_sets.secondary,
                textHoverColor: selected.color_sets.primary

            },
            shadow: {
                btnShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
                activeShadow: 'box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
                hoverShadow: ' 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
                focusShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
            }
        },

        dispayAToZFiledStyled: {
            color: {
                wrapperBg: selected.background_colors.primary,
                azTabText: selected.text_colors.seventh,
                azTabHoverBg: selected.color_sets.eight,
                textColor: selected.text_colors.primary
            },
            border: {
                inputBorder: selected.border_colors.primary,
            }
        },

        connectStyle: {
            color: {
                popupBg: selected.background_colors.primary,
                iconBoxActive: selected.color_sets.sixth,
                iconBoxHover: selected.color_sets.sixth,
                innerBoxBg: selected.background_colors.primary,
            },
            border: {
                // activeBorder: colors.primary
            }
        },

        hocTitleHeader: {
            color: {
                lable: selected.text_colors.primary,
            }
        },

        insightStyle: {
            color: {
                title: selected.text_colors.primary,
                titleBg: selected.background_colors.primary,
                chartBoxBg: selected.background_colors.primary,
                mainBg: selected.background_colors.secondary
                // insightBoxBg: 'red',
                // insightBoxTitle: 'red',
            }
        },

        report: {
            color: {

            }
        },
        chart: {
            color: {
                ...selected.chart_colors
            }
        },
        rippleButton: {
            color: {
                iconColor: '#bdbdbd',
                iconHover: selected.color_sets.primary,
            }
        },
        singleCell: {
            color: {
                textColor: selected.text_colors.primary,
            }
        }

    },
    thirdTheme: {
        label: {
            color: selected.text_colors.primary
        },

        bodyStyle: {
            color: {
                bgColor: selected.background_colors.secondary
            }
        },

        textbox: {
            border: {
                radius: '5px',
                color: colors.gray,
                thickness: '1.2px',
                hoverColor: colors.secondary,
                focusColor: colors.secondary,
            },
            color: colors.codGray,
            background: colors.white,
        },

        selectBoxConfig: {
            color: {
                label: selected.text_colors.tertiary,
                labelFocus: selected.color_sets.primary,
                selectValue: selected.text_colors.tertiary,
                dropdownIcon: selected.text_colors.tertiary
            },
            border: {
                defaultBottom: `1px solid ${selected.border_colors.primary}`,
                HoverBottom: `1px solid ${selected.border_colors.forth}`,
            },
            shadow: {
                dropdownBox: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
            }
        },


        textFieldStyle: {
            color: {
                label: selected.text_colors.tertiary,
                labelFocus: selected.color_sets.primary,
            },
            border: {
                defaultBottom: `1px solid ${selected.border_colors.primary}`,
                HoverBottom: `1px solid ${selected.border_colors.forth}`,
                focusBorder: `2px solid ${selected.color_sets.primary}`
            },
        },

        header: {
            color: {
                background: selected.background_colors.primary,
                hamburger: selected.color_sets.primary,
                home: selected.color_sets.primary,
                search: selected.color_sets.primary,
                voice: selected.color_sets.primary,
                searchBoxBorder: selected.border_colors.primary,
                searchBoxPlacholder: selected.text_colors.primary,
                searchBoxText: selected.text_colors.primary,
                autoSuggestTxt: selected.text_colors.primary,
                autoSuggestTxtHover: selected.color_sets.primary,
                autoSuggestIcon: selected.color_sets.primary,
                iconHover: selected.color_sets.secondary,
                settingDefault: selected.color_sets.primary,
                logoutHoverBg: selected.color_sets.primary,
                logoutColorOnHover: selected.text_colors.sixth,
            },
            shadow: {
                headerMain: '0 2px 4px 0 rgba(0, 0, 0, 0.16)'
            }
        },

        defaultSvgStyle: {
            color: {
                icon: selected.color_sets.primary
            }
        },

        defaultChartSvgStyle: {
            color: {
                icon: selected.chart_colors.primary
            }
        },

        righterMenuConfig: {

            menu: {
                color: {
                    icon: selected.color_sets.primary,
                    iconWrapperActive: selected.color_sets.seventh,
                },

                border: {
                    iconWrapper: selected.border_colors.fifth
                }
            },
            chartOptions: {

                color: {
                    icon: selected.color_sets.primary,
                    iconActive: selected.color_sets.seventh,
                    iconDisable: selected.color_sets.nine,
                    iconWrapperActive: selected.color_sets.seventh,
                    iconWrapper: selected.color_sets.tenth,
                    wrapperBg: selected.color_sets.tenth,
                    headerClose: selected.color_sets.primary,
                    labelColor: selected.text_colors.primary
                },

                border: {
                    wrapperBorderLeft: `1px solid ${selected.border_colors.secondary}`,
                    headerBorderBottom: `1px solid ${selected.border_colors.secondary}`
                },

                shadow: {
                    iconWrapperActive: '1px 1px 3px #00000054',
                    iconWrapperHover: '1px 1px 6px 2px #cccccc75'
                },

                chartIcon: {
                    color: {
                        icon: selected.chart_colors.primary,
                        iconDisable: selected.color_sets.nine,
                        iconActive: selected.chart_colors.primary,
                    }
                },
            },
            favouritStyle: {
                color: {
                    rowBg: selected.color_sets.seventh,
                }
            }
        },


        chartOptionsConfig: {
            color: {
                icon: colors.primary,
                iconActive: colors.primary,
                iconDisable: colors.disableColor,
            }
        },

        chartStyle: {
            color: {
                AxisLable: selected.text_colors.primary,
                outerLabel: selected.text_colors.primary,
                yAxisStock: colors.secondary,
                chart_fill: selected.chart_colors.primary,

            }
        },

        polusIqStyle: {
            color: {
                iqWrapperBg: selected.color_sets.tertiary,
                iqTextColor: selected.text_colors.sixth
            }
        },
        lefterMenuConfig: {
            color: {
                backgroundRight: selected.background_colors.fifth,
                text: selected.text_colors.sixth,
                backgroundActive: selected.background_colors.fifth,
                backIcon: selected.color_sets.tenth,
                version: selected.text_colors.sixth,
                wrapperBg: selected.background_colors.sixth
            }
        },

        table: {
            color: {
                thBackground: selected.color_sets.forth,
                thText: selected.text_colors.primary,
                tdText: selected.text_colors.primary,
                tdBackgroundEven: selected.color_sets.tenth,
                tdBackgroundOdd: 'rgb(234 234 234 / 32%)',
                tdHoverBackground: '#add7eb9e'
                // background: 'red'
            }
        },

        grandTotalColumns: {
            color: {
                text: selected.text_colors.primary,
            },
            font: {
                textFontSize: '.8rem',
                textFontWeight: 600,
            },
            padding: {
                tdPadding: '.4rem 10px'
            }
        },

        tableNextPrev: {
            color: {
                disabled: '#c1c1c2',
                normal: '#0185fc'
            }
        },
        filter: {
            color: {
                background: colors.seventh
            }
        },


        trendFilterStyle: {
            color: {
                monthCardBg: selected.background_colors.primary
            }
        },
        titleHeader: {
            color: {
                titleText: selected.color_sets.secondary,
                titleBg: '#f7f7f8',
                options: selected.text_colors.primary,
                optionsHover: selected.color_sets.primary,
                icon: selected.color_sets.primary,
                iconHover: selected.color_sets.tenth,
                iconWrapper: selected.color_sets.seventh,
                iconWrapperHover: selected.color_sets.tertiary,
                rippleColor: selected.color_sets.tertiary,

                iqWrapperBg: selected.color_sets.primary,
                iqIcon: selected.color_sets.tenth,
            },
        },


        reportList: {
            color: {
                actionBackground: colors.lightGray,
                hover: colors.white,
            },

        },

        breadcrumbStyle: {
            color: {
                home: selected.color_sets.primary,
                wrapperBg: selected.background_colors.primary,
                text: selected.text_colors.primary,
                selectedText: selected.color_sets.primary,
            }

        },

        actionCardStyle: {
            color: {
                cardBg: selected.background_colors.primary,
                overlayBg: selected.background_colors.forth,
                overlayText: selected.text_colors.primary,
                iconWrapperHover: selected.color_sets.tenth,
                icon: selected.color_sets.tenth,
                iconHover: selected.color_sets.primary,
                mainTitle: selected.text_colors.primary,
                date: selected.text_colors.tertiary,
            },
            shadow: {
                card: '0px 2px 6px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
            }
        },

        checkboxStyle: {
            color: {
                wrapperHoverBg: 'rgba(220, 0, 78, 0.04)',
                ripple: '#2d95e72e',
                checkBoxBorder: colors.primary,
                checkBoxBg: colors.primary,
                radioBg: colors.primary,
            },
            border: {
                checkBox: colors.primary,
                tick: colors.white,
                unTick: colors.black,
                radio: colors.primary,
                radioChecked: colors.white,
                radioUnchecked: colors.black,
            }
        },

        buttonStyle: {
            color: {
                secondryColor: '#555',
                primaryBg: selected.color_sets.primary,
                disableBg: colors.disableColor,
                textColor: colors.white,
                focusBg: colors.tertiary,
                hoverBg: selected.color_sets.secondary,
                textHoverColor: selected.color_sets.primary
            },
            shadow: {
                btnShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
                activeShadow: 'box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
                hoverShadow: ' 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
                focusShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
            }
        },

        dispayAToZFiledStyled: {
            color: {
                wrapperBg: selected.background_colors.primary,
                azTabText: selected.text_colors.seventh,
                azTabHoverBg: selected.color_sets.eight,
                textColor: selected.text_colors.primary
            },
            border: {
                inputBorder: selected.border_colors.primary,
            }
        },

        connectStyle: {
            color: {
                popupBg: selected.background_colors.primary,
                iconBoxActive: selected.color_sets.sixth,
                iconBoxHover: selected.color_sets.sixth,
                innerBoxBg: selected.background_colors.primary,
            },
            border: {
                // activeBorder: colors.primary
            }
        },

        hocTitleHeader: {
            color: {
                lable: selected.text_colors.primary,
            }
        },

        insightStyle: {
            color: {
                title: selected.text_colors.primary,
                titleBg: selected.background_colors.primary,
                chartBoxBg: selected.background_colors.primary,
                mainBg: selected.background_colors.secondary
                // insightBoxBg: 'red',
                // insightBoxTitle: 'red',
            }
        },

        report: {
            color: {

            }
        },
        chart: {
            color: {
                ...selected.chart_colors
            }
        },
        rippleButton: {
            color: {
                iconColor: '#bdbdbd',
                iconHover: selected.color_sets.primary,
            }
        },
        singleCell: {
            color: {
                textColor: selected.text_colors.primary,
            }
        }

    },

}