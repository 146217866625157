import * as actions from '../action-types/popup';

const initialState = {
    type: actions.POPUP_INIT,
    popups: [],
    buttonClicked: undefined,
};

const popupReducer = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    // if (action && action.data && typeof action.data === 'object') {
    //     action.data.key = new Date();
    // }

    switch (action.type) {
        case actions.POPUP_RAISE:
            
            const _popups_ = [...state.popups];

            _popups_.push({ 
                    key: action.data.indexKey, 
                    type: action.data.popupType, 
                    message: action.data.message, 
                    title: action.data.title, 
                    data: action.data.data,
                    element: action.data.element, 
                    top: action.data.top, 
                    left: action.data.left,
                    overlay_color: action?.data?.overlay_color ,
                    other_properties: action?.data?.other_properties,
                    disable_overlay_click: action?.data?.disable_overlay_click,
                    hide_header: action?.data.hide_header
                });

            return {
                type: state.type,
                popups: _popups_,
                buttonClicked: undefined
            };

        case actions.POPUP_HIDE:
            /* hide only the error matched with the key*/
            const _popups = [...state.popups];
            const popupIndex = _popups.findIndex(popup => popup.key === (action.data.indexKey));

            if (popupIndex > -1) {
                _popups.splice(popupIndex, 1);
            }

            return {
                type: state.type,
                popups: _popups,
                buttonClicked: action.data.optionClicked,
            };
        case actions.HIDE_LAST_POPUP:
            let temp_popups = [...state.popups];
            temp_popups.pop()

            return {
                ...state,
                type: action.type,
                popups: temp_popups
            }

        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
            return {
                type: state.type,
                popups: [...state.popups],
            };
    }
};

export default popupReducer;