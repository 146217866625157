import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils';
import Ceartsvg from '../svg/chevron.down'

let selected = getSelectedTheme();

const { buttonStyle } = themes[selected]


const ripple = keyframes`
    0% {
        transform: scale(0, 0);
        opacity: 1;
    }
    20% {
        transform: scale(25, 25);
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: scale(40, 40);
    }
`;



export const CheckBox = styled.div`
    width: 1.3rem;
    height: 1.3rem;
    border: 2px solid #fff;
    border-radius: 50%;
    position: relative;
    display: inline-block;
    margin-right: 1rem;
    transition: all .2s;
    &:before {
        transition: all .2s;
        content: '';
        width: .4rem;
        height: .8rem;
        border: 1px solid;
        border-width: 0 2px 3px 0;
        transform: rotate(45deg);
        position: absolute;
        right: .3rem;
        top: 0px;
     }

`;

export const ButtonStyled = styled.button`
        display: ${props => props.display || 'flex'};
        align-items: center;
        padding: ${props => props.padding || "2px .5rem"};
        box-sizing: border-box;
        height: ${props => props.height || '2rem'};
        line-height: ${props => props.lineHeight || '1rem'};
        width: ${props => props.width || undefined};
        background: ${props => props.disabled ? undefined : props.bgColor || 'linear-gradient(to right, #3E9EFF, #737DFF);'};
        border-radius: ${props => props.borderRadius || '4px'};
        border: 0px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        font-size: 0.875rem;
        color: ${props => props.color || buttonStyle.color.textColor};
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        cursor: ${props => props.disabled ? undefined : 'pointer'};
        white-space: nowrap;
        margin-right: ${props => props.mR ? props.mR : "0px"};
        margin-left: ${props => props.mL ? props.mL : "0px"};
        font-family: 'Lato', sans-serif;
        position: relative;
        overflow: hidden;
        transition: all 0.2s;
        text-transform: ${props => !props.not_apply_text_transform ? 'capitalize' : undefined};
        font-weight: 500;
        box-shadow: ${props => props.boxShadow ? props.boxShadow : undefined};

        .icon{
            margin-right:${(props) => props.isIconMarRt ? '5px' : 0};
            display: flex;
        }


        /* svg{ } */

        
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 5px;
            height: 5px;
            background: rgb(255 255 255 / 32%);
            opacity: 0;
            border-radius: 50%;
            transform: scale(1, 1) translate(-50%);
            transform-origin: 50% 50%;
        }

        &:hover{
            background: ${props => props.disabled ? '#d9d9d9' : props.hoverBg ? props.hoverBg : props.primary ? 'linear-gradient(to right, #2A8BEF, #5966FF)' : '#d6d6d8'};    
            cursor: ${props => props.disabled ? "not-allowed" : undefined};
            color: ${props => (props.bgColor === 'transparent') ? buttonStyle.color.textHoverColor : undefined};

                fill: ${props => (props.bgColor === 'transparent') ? buttonStyle.color.textHoverColor : undefined};

        }

`;



export const NormalButton = (props) => {

    const { hoverBg, justify, not_apply_text_transform, icon, rightIcon, onClick, label, iconStyle, title, dropDownOption, optionClicked } = props;

    return (
        <ButtonStyled
            not_apply_text_transform={not_apply_text_transform}
            title={title ? title : label}
            {...props}
            hoverBg={hoverBg}
            disabled={props.disabled}
            isIconMarRt={label || rightIcon}
            onClick={(event) => {
                if (!dropDownOption) {
                    setTimeout(() => {
                        props.onClick(event);
                    }, 400);
                }

            }}
        >
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: justify || 'center' }} className="txt" onClick={(event) => {
                if (dropDownOption) {
                    setTimeout(() => {
                        props.onClick(event);
                    }, 400);
                }
            }}>
                {icon ? <span className="icon" style={iconStyle ? iconStyle : undefined}>{icon}</span> : ''}
                {label && <span style={{ display: 'inline-block', marginTop: '-2px', }}>{label}</span>}
                {rightIcon ? <span style={{ display: 'flex', marginLeft: '8px', }}>{rightIcon}</span> : null}
            </div>
            {dropDownOption ? <div onClick={(event) => {
                optionClicked(event);
            }} style={{ width: '1.5rem', height: '2rem', marginLeft: '10px', borderLeft: '1px solid #fff', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <Ceartsvg size=".8rem" height=".8rem" color="#fff" />
            </div> : null}
        </ButtonStyled>
    )
}


