import { dispatchAction } from './helper';
import * as actionTypes from '../action-types/overlay';

export const raiseOverlay = element => dispatch => {
    dispatchAction(dispatch, actionTypes.OVERLAY_RAISE, element, undefined, undefined, 0);
};

export const hideOverlay = () => dispatch => {
    dispatchAction(dispatch, actionTypes.OVERLAY_HIDE, undefined, undefined, undefined, 0)
};

