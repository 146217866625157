import React from 'react';

const Gauge = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}

            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 2048 1074"
            space="preserve">

            <path transform="translate(975,2)" d="m0 0 4 1v5l12-1h64l29 1 48 4 40 5 37 6 35 7 37 9 36 10 38 12 33 12 37 15 36 16 31 15 44 24 25 15 22 14 22 15 17 12 19 14 18 14 16 13 13 11 14 12 12 11 6 4 7-6 3 1-1 5-4 4 2 4 8 8 2 1v2h2v2l4 2 14 14 7 8 12 12 7 8 10 11 9 11 9 10 8 10 11 14 14 18 13 18 16 23 11 17 12 19 17 29 16 29 10 19 11 23 17 38 16 41 14 40 12 40 13 52 9 45 6 38 5 43 3 41v58l-1 1h-123l-4-1-19-1-190-1-2-51-4-37-6-38-8-37-8-30-12-37-14-36-12-26-10-21-12-22-17-28-18-27-13-18-10-13-8-10-9-11-10-11-7-8-9-10-30-30-11-9-13-12-28-22-18-13-33-22-22-13-22-12-21-11-24-11-27-11-28-10-28-9-31-8-14-3-1 12-10 63-24 153-16 100-24 153-14 88-10 63-4 16-8 17-8 11-11 12-14 10-17 8-21 5h-23l-18-4-13-5-14-8-13-12-9-10-8-14-5-12-4-16-1-9v-11l3-19 7-19 19-42 18-41 14-31 48-108 19-43 20-45 17-38 16-36 17-38 64-144 4-11-32-5-38-4-44-2h-15l-65 3-32 4-38 7-34 8-40 12-33 12-26 11-28 13-27 14-27 16-22 14-17 12-19 14-14 11-14 12-11 9-17 16-13 12-7 7-7 8-15 16-9 11-11 13-13 17-13 18-7 10-15 23-13 22-12 22-8 15-15 33-13 33-12 36-9 32-8 36-6 37-4 35-2 32-1 27-190 1-19 1-1 1h-127v-32l2-42 4-45 7-50 9-48 10-42 10-36 10-32 14-39 12-30 14-32 16-33 11-22 10-18 15-26 15-24 24-36 13-18 12-16 10-13 13-16 9-11 11-13 11-12 7-8 7-7 7-8 39-39 8-7 14-13 8-7 14-12 14-11 12-10 20-15 18-13 17-12 15-10 22-14 15-9 19-11 18-10 29-15 24-12 36-16 33-13 36-13 38-12 37-10 33-8 46-9 48-7 51-5 13-1h12v-5z" fill="#B5FDFE" />
            <path transform="translate(926,11)" d="m0 0h9v10l11 127 13 147 4 44v8l-30 3-37 6-37 8-36 10-33 11-33 13-33 15-31 16-27 16-22 14-17 12-19 14-14 11-14 12-11 9-17 16-13 12-7 7-7 8-15 16-9 11-11 13-13 17-13 18-7 10-15 23-13 22-12 22-8 15-15 33-13 33-12 36-9 32-8 36-6 37-4 35-2 32-1 27-190 1-19 1-1 1h-127v-32l2-42 4-45 7-50 9-48 10-42 10-36 10-32 14-39 12-30 14-32 16-33 11-22 10-18 15-26 15-24 24-36 13-18 12-16 10-13 13-16 9-11 11-13 11-12 7-8 7-7 7-8 39-39 8-7 14-13 8-7 14-12 14-11 12-10 20-15 18-13 17-12 15-10 22-14 15-9 19-11 18-10 29-15 24-12 36-16 33-13 36-13 38-12 37-10 33-8 46-9 48-7z" fill="#A9CCFD" />
            <path transform="translate(975,2)" d="m0 0 4 1v5l12-1h64l29 1 48 4 40 5 7 1v2l11 1 37 7 41 9 31 8 39 12 35 12 26 10 36 15 18 8 1 4-11 22-8 18-7 14-26 56-16 34-13 28-14 30-13 28-16 34-9 20-7 15v4l-6-2-30-13-30-11-34-11-31-8-14-3-1 12-10 63-24 153-16 100-24 153-14 88-10 63-4 16-8 17-8 11-11 12-14 10-17 8-21 5h-23l-18-4-13-5-14-8-13-12-9-10-8-14-5-12-4-16-1-9v-11l3-19 7-19 19-42 18-41 14-31 48-108 19-43 20-45 17-38 16-36 17-38 64-144 4-11-32-5-38-4-44-2h-15l-45 2h-8l-4-40-9-103-15-170-1-22 3-2 25-2h12v-5z" fill="#FDFDFD" />
            <path transform="translate(1181,277)" d="m0 0 1 4-2 9-2 17-8 54-9 55-10 64-17 109-16 100-24 153-14 88-10 63-4 16-8 17-8 11-11 12-14 10-17 8-21 5h-23l-18-4-13-5-14-8-13-12-9-10-8-14-5-12-4-16-1-9v-11l3-19 7-19 19-42 18-41 14-31 48-108 19-43 20-45 17-38 16-36 17-38 72-162 13-27 13-30z" fill="#CCCDCD" />
            <path transform="translate(974,903)" d="m0 0 16 1 14 4 15 8 10 8 7 7 8 12 5 13 2 10v25l-5 17-6 11-9 11-7 7-15 9-13 5-10 2h-18l-17-4-16-8-11-9-8-9-8-14-5-15-1-5v-21l4-16 9-17 11-12 8-7 15-8 15-4z" fill="#FEFEFE" />
            <path transform="translate(975,2)" d="m0 0 4 1v126h-5v-120h-6v-1h6v-5z" fill="#CCCDCD" />
            <path transform="translate(1727,272)" d="m0 0 3 1-1 5-7 7-8 7-43 43-8 7-22 22h-3l1-4 77-77 7-8z" fill="#CCCDCD" />
            <path transform="translate(359,254)" d="m0 0 4 1 8 7 74 74 6 7v2l-4-1-11-11-8-7-68-68z" fill="#CCCDCD" />
            <path transform="translate(1917,1027)" d="m0 0h128l-1 4h-123l-4-1z" fill="#D8D8D8" />
            <path transform="translate(23,1027)" d="m0 0h108l-2 4h-127v-3z" fill="#D8D9D9" />
        </svg>

    )

}


export default Gauge;

