import styled from 'styled-components';


export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;



    .expand_icon{
        position: absolute;
        right: 10px;
        cursor: pointer;
        transition: all 0.5s ease-out;
        &:hover{
            transform: scale(1.3);
            color: rgb(0, 138, 199);
        }
    }
`;