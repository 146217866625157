/**
    * This will be used for containers like Popup clicks, and showing error messages etc;
    * Errors, PopUp are of Array Type
*/

import React from 'react';
import { connect } from 'react-redux';
import { raiseError, hideError } from '../actions/error';
import { popupClicked } from '../actions/popup';
import Popup from './helper/popup';
import { LOADER_SHOW, LOADER_SHOW_V1 } from '../action-types/loader';
import styled from 'styled-components';
import enums from '../utils/enums';
import Righter from './righter';
import { OVERLAY_RAISE } from '../action-types/overlay';
import Lefter from './lefter';
import { constants } from '../utils/constants';
import OverlayCustom from '../components/helper/overlay';
import Snackbar from './snack-bar';
import { hideOverlay } from '../utils';
import FileDownloadingStatus from './extra/downloading.status'


const Overlay = styled.div`
    width: 100vw;
    height: 100vh;
    overflow-y: hidden;
    background: rgba(255, 255, 255, 0.73);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: ${props => props.zIndex ? props.zIndex : 100};
`;

class Container extends React.Component {
    render() {
        const { errors, popups, loaderType, righter, lefter, overlayType, onClickFunc, menuConfig, success } = this.props;

        let totalZIndexForPopUp = 100;
        console.log('error found: ', errors);
        // if (errors && errors.length > 0)

        totalZIndexForPopUp = totalZIndexForPopUp + errors.length + 2000;

        if (popups && popups.length > 0)
            totalZIndexForPopUp = totalZIndexForPopUp + popups.length + 2000;

        totalZIndexForPopUp = totalZIndexForPopUp + 1;


        return (
            <React.Fragment>
                <div>
                    {errors && errors.map(error => {

                        return <Popup
                            totalZIndexForPopUp={totalZIndexForPopUp}
                            key={error.key}
                            type={enums.popupType.ok}
                            popupKey={error.key}
                            title={error.title || 'Error found'}
                            message={error.message}
                            closeHandler={(optionClicked, key) => {
                                this.props.hideError(key)
                            }}

                            top={this.props.top}
                            left={this.props.left}
                        />
                    })}


                    {popups && popups.map((popup, index) => {
                        return <Popup key={'popup_' + index}
                            popupKey={popup.key} type={popup.type}
                            title={popup.title}
                            message={popup.message}
                            data={popup.data}
                            element={popup.element}
                            top={popup.top}
                            overlay_color={popup?.overlay_color}
                            left={popup?.left}
                            disable_overlay_click={popup?.disable_overlay_click}
                            other_properties={popup?.other_properties}
                            hide_header={popup?.hide_header}

                        />
                    })}


                    <Righter showFull={righter} element={righter} />
                    <Lefter showFull={lefter} element={lefter} />

                     {loaderType === LOADER_SHOW &&
                        <Overlay
                            zIndex={totalZIndexForPopUp}
                        >
                            <img height='90px' alt='loading information' src={constants.CONTEXT_PATH + './loader.gif'}></img>
                        </Overlay>
                    } 

                    {loaderType === LOADER_SHOW_V1 &&
                        <Overlay
                            zIndex={totalZIndexForPopUp}
                        >
                            <div style={{
                                width: '20rem',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                fontSize: '1rem',
                                textAlign: 'center',
                                // border: '1px solid #ccc'
                            }}>
                                <img height='90px' alt='loading information' src={constants.CONTEXT_PATH + './loader.gif'}></img>
                                <h1 style={{ fontSize: '1rem', fontWeight: 600 }}>Please wait while we are processing your request...</h1>

                            </div>
                        </Overlay>
                    }

                    {
                        overlayType === OVERLAY_RAISE &&
                        <OverlayCustom
                            global={true}
                            onClick={onClickFunc ? onClickFunc : () => {
                                alert('dr: ',)
                                hideOverlay();
                            }}
                        />
                    }
                </div>
                <Snackbar success={success} />
                <FileDownloadingStatus status={this.props.downloadingStatus} />
            </React.Fragment>
        )
    }
};


const mapStateToProps = state => {

    const success = state.successReducer ? state.successReducer.success : undefined;
    const downloadingStatus = state?.successReducer?.downloadingStatus
    const errorType = state.errorReducer ? state.errorReducer.type : undefined;
    const errors = state.errorReducer ? state.errorReducer.errors : [];
    const popups = state.popupReducer ? state.popupReducer.popups : [];
    const popupType = state.popupReducer ? state.popupReducer.type : undefined;
    const loaderType = state.loaderReducer ? state.loaderReducer.type : undefined;
    const righterType = state.righterReducer ? state.righterReducer.type : undefined;
    const righter = state.righterReducer ? state.righterReducer.righter : undefined;
    const lefter = state.lefterReducer ? state.lefterReducer.lefter : undefined;
    const lefterType = state.lefterReducer ? state.lefterReducer.type : undefined;
    const menuConfig = state.menuConfigRighterReducer ? state.menuConfigRighterReducer.menuConfig : undefined;
    const overlayType = state.overlayReducer ? state.overlayReducer.type : undefined;
    const onClickFunc = state.overlayReducer ? state.overlayReducer.onClickFunc : undefined;

    return { downloadingStatus, errors, errorType, popupType, popups, loaderType, righter, righterType, lefter, lefterType, overlayType, onClickFunc, menuConfig, success };
};


export default connect(mapStateToProps, { raiseError, hideError, popupClicked })(Container);