import React, { useState } from 'react';
import styled from 'styled-components';
import { FaMicrophone, FaEye, FaEdit, FaFilter, FaExpandArrowsAlt } from 'react-icons/fa';
import { BiExpandAlt } from "react-icons/bi";
import { GrRefresh } from "react-icons/gr";
import { getPermissionById } from '../../utils';
import { COMPONENT_IDS } from '../../utils/elementids';

const ButtonGroups = (props) => {

    const [hoveredIcon, setHoveredIcon] = useState(null);


    const icons = [
        { id: 'mick', icon: <FaMicrophone />, alt: "Microphone" },
        { id: 'view_sql_query', icon: <FaEye />, alt: "View" },
        { id: 'edit', icon: <FaEdit />, alt: "Edit" },
        { id: 'refresh', icon: <GrRefresh />, alt: "Edit" },
        { id: 'filter', icon: <FaFilter />, alt: "Filter" },
        { id: 'expand', icon: <BiExpandAlt />, alt: "Expand" }
    ];





    const user_id = props?.user?.id || props?.user?.user_id;

    
    const show_eye_db = getPermissionById("view_sql_button", props.menuPermissions);
    const show_eye = props?.user?.email && props?.user?.email?.indexOf("newfangled.io") > -1 || show_eye_db;
    const show_r_btn = getPermissionById("refresh_widget_data", props.menuPermissions);
    const edit_widget = getPermissionById(COMPONENT_IDS.SHARED_ACTIONS.EDIT_DASHBOARD, props.menuPermissions);
    const show_refresh_btn = (user_id === 'nf_kc2yq6inkc2yqpn2' || show_r_btn) && props.is_cache_required;
    const show_edit_btn = (user_id === 'nf_kc2yq6inkc2yqpn2' || edit_widget);



    return (
        <ButtonContainer>
            {icons.map(({ id, icon, alt }) => {
                if(!show_eye && id === 'view_sql_query') return ;
                if(!show_refresh_btn && id === 'refresh') return ;
                if(!show_edit_btn && id === 'edit') return ;

                return (
                    <IconButton
                        key={id}
                        onMouseEnter={() => setHoveredIcon(id)}
                        onMouseLeave={() => setHoveredIcon(null)}
                        isHovered={hoveredIcon === id}
                    >
                        {icon}
                    </IconButton>
                )
            })}
        </ButtonContainer>
    );
};

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 10px;
`;

const IconButton = styled.div`
    font-size: 1rem;
    color: ${({ isHovered }) => (isHovered ? '#234eb7' : '#4d4d4d')};
    cursor: pointer;
    transition: color 0.3s ease;
    width: 2rem;
    font-weight: 200;
    height: 2rem;
    background: red;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-left: 5px;
    background-color: ${({ isHovered }) => (isHovered ? '#e6ebfc' : '#fff')};
`;

export default ButtonGroups;
