import React, { useEffect, useState, Suspense } from 'react'
import { connect } from 'react-redux'
import { get_Interpretation, save_Interpretation } from '../../actions/interpretation'
import { Wrapper, EditorInnerWrapper, TableWrapper, NewWrapper } from './polus.interpretation.styled'
import { Gap } from '../gap'
import { Button } from '../hoc/button';
import { showPopup } from '../../utils';
import * as enums from '../../utils/enums';
import EditPopup from './edit_Popup'


const TableRow = React.lazy(() => import('./row'));

const Index = (props) => {
    const { data, get_Interpretation, save_Interpretation } = props;
    const [localData, setData] = useState(undefined)

    useEffect(() => {
        get_Interpretation()
    }, [])

    useEffect(() => {
        if (data) {
            setData(data)
        }
    }, [data])

    


    return (
        <NewWrapper>
            <Gap h="1rem" />
            <div style={{
                display: 'flex',
                justifyContent: 'flex-end'
            }}>
                <Button 
                 primary={true}
                 onClick={() => {
                    showPopup(undefined, undefined, enums.default.popupType.element, EditPopup, {
                        item: undefined
                    });
                }}
                >Add</Button>
            </div>
            <Gap h="1rem" />
            
            <Wrapper>
                <EditorInnerWrapper>
                    <Gap h="1rem" />
                    <TableWrapper>
                        {localData && localData !== undefined &&

                            <table className="table">
                                <thead className="thead">
                                    <tr>
                                        <th>keyword</th>
                                        <th>polus interpretation</th>
                                        <th>keyword</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody style={{ alignItems: 'center' }}>
                                    {localData.map((item, index) => {
                                        return (
                                            <Suspense fallback={<p>Loading</p>}>
                                                <TableRow key={"word_row" + index}
                                                    item={item}
                                                />
                                            </Suspense>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        }
                        {!localData && <p style={{ textAlign: 'center', marginTop: '5rem' }}>Loading...</p>}
                    </TableWrapper>
                </EditorInnerWrapper>
            </Wrapper>
        </NewWrapper>
    )
    
}


const mapStateToProps = (state) => ({
    data: state.InterpretationReducer.data
})
export default connect(mapStateToProps, { get_Interpretation })(Index);
