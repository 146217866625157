import React, { useState, useEffect, useRef } from 'react'
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer, Legend, Label, LabelList, Text, Sector } from 'recharts';
import styled from 'styled-components';
import * as dataTypes from '../../../shared-with-fe/data.types';
import CustomToolTip from '../tooltip/tooltip.custom';
import { apply_styles, checkYearAndMakeFy, find_cell_format, formatMonthYearForChart, formatValueByDataType, getRemToPx, get_required_attributes_for_chart_click_option, showPopup } from '../../../utils';
import { constants } from '../../../utils/constants';
import { connect } from 'react-redux';
import Index from '../../shared-table';
import ChartFilterPopup from '../../chart-filter-popup';
import enums from '../../../utils/enums';
import RenderLegend from '../legend/index.new';
import RightClickMenu from '../../hoc/rigntClickMenuOptions';
import GaugeRangeSettingsPopup from './gaugeRangeSettingsPopup';
import { getGaugeSegmentRangeData } from '../../../actions/gaugeSegmentRange';

const Wrapper = styled.div`

   width: 100%;
   
.recharts-wrapper .recharts-legend-wrapper{
    right: 0px;
    left: auto !important;
    width: ${props => props.insights ? 'auto' : '10rem !important'};
}
`;

const LegendText = styled.span`
    ${props => apply_styles(props._style, constants.STYLED_COMPONENTS.ALL)}
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
`;


const initialGaugeRanges = [
    {
        value1: '0',
        value2: 20,
        value4: "#ff0000",
        value3: 'poor'
    },
    {
        value1: 20,
        value2: 40,
        value4: "#ff6600",
        value3: 'fair'
    },
    {
        value1: 40,
        value2: 60,
        value4: "#FFCC00",
        value3: 'good'
    },
    {
        value1: 60,
        value2: 80,
        value4: "#99ff00",
        value3: 'great'
    },
    {
        value1: 80,
        value2: 100,
        value4: "#33ff00",
        value3: 'excellent'
    },
]



let isAnimationActive = true;

const SimplePieChart = (props) => {

    const {

        xDataKeys,
        data,
        yDataKeys,
        widthOfChart,
        colorForYData,
        heightOfChart,
        question,
        getDataFromReportingServer,
        isDonut,
        hide_label,
        hide_legend,
        hide_tooltip,
        insights,
        Alignments,
        chartStyle,
        show_data_label,
        show_percentage_in_data_labels,
        show_prcentage_in_legends,
        tooltipTheme,
        isGauge,
        yac_totals,
        getGaugeSegmentRangeData

    } = props;


    const isSingleDataGauge = isGauge && props.data?.length === 1 && yDataKeys?.length > 1;
    const show_label_in_legend = 1;
    const heightOfWindow = window.innerHeight;
    const yDataKeyLength = yDataKeys.length;
    const legendStyle = chartStyle && chartStyle.legend;

    const hide_local_legend = (props.view_mode !== 'expanded' && props.isPolusAIWindow) ? true : (!insights && data?.length >= 40) ? true : hide_legend
    const show_percentage_in_data_labels_local = (!insights && data?.length <= 40) ? true : show_percentage_in_data_labels;
    const show_data_label_local = (!insights && data?.length <= 40) ? true : show_data_label;

    const dataLabelStyle = apply_styles(chartStyle?.data_label, constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT)

    const [localData, setLocalData] = useState(undefined);
    const [localXDataKeys, setLocalXDataKeys] = useState(undefined);
    const [localYDataKeys, setLocalYDataKeys] = useState(undefined);
    const [value_angle, set_value_angle] = useState(0);

    const [is_single_cell, set_is_single_cell] = useState(false);

    const [target_column, set_target_column] = useState(undefined);


    const [sum_of_data, set_sum_of_data] = useState(undefined);
    const [sum_of_target, set_sum_of_target] = useState(undefined);
    const [activeIndex, setActiveIndex] = useState(undefined);
    const [gaugeSegmentRanges, setGaugeSegmentRanges] = useState(initialGaugeRanges);
    
    const maxRange =  gaugeSegmentRanges?.length > 0 ? Math.max(...gaugeSegmentRanges?.map(item => item?.value2)) : 100;

    const convertValueToPercent = (clonData) => {
        let total = {};
        clonData && clonData.length > 0 && clonData.forEach((data_row) => {
            yDataKeys && yDataKeys.length > 0 && yDataKeys.forEach((key) => {
                if (typeof total[key] === 'undefined') {
                    total[key] = 0;
                }
                total[key] += data_row[key] || 0
            })
        })


        clonData && clonData.length > 0 && clonData.forEach((data_row) => {
            yDataKeys && yDataKeys.length > 0 && yDataKeys.forEach((key, i) => {
                if (data_row[key] > 0) {
                    if (show_percentage_in_data_labels_local) {
                        let _v = (data_row[key] / total[key]) * 100;
                        data_row[key] = _v
                        data_row['p_value'] = _v;
                        data_row['p_value_key'] = key

                    }
                }
            })
        })
    }

    useEffect(() => {
        if (!props?.gaugeDataRanges && props?.id && isGauge) {
            getGaugeSegmentRangeData("gauge_chart", props?.id, (props?.insight_id || props?.reportId || 'sss'))
        }
    }, [props])


    useEffect(() => {
        if (props?.gaugeDataRanges?.length > 0 && isGauge) {
            setGaugeSegmentRanges(props?.gaugeDataRanges)
        }
    }, [props?.gaugeDataRanges])


    useEffect(() => {
        if (props.data) {
            isAnimationActive = true
        }
        let clonData = props.data ? JSON.parse(JSON.stringify(props.data)) : [];
        // convertValueToPercent(clonData)
        setLocalData(clonData)

        if (isGauge) {


            // L1 rule
            if (props.data?.length === 1 && yDataKeys?.length > 1) {

                const temp_chart_data = [];

                let sum_of_y1 = data?.length > 0 && data?.[0]?.[yDataKeys[0]];
                let sum_of_y2 = data?.length > 0 && data?.[0]?.[yDataKeys[1]];

                let y_axis1 = yDataKeys[0]
                let y_axis2 = yDataKeys[1]

                let x_key = ["_nf_name_"]

                // temp_chart_data.push(
                //     { [x_key]: '__Nf1__', [y_axis1]: 0, p_value: '0' },
                //     { [x_key]: '__Nf2__', [y_axis1]: 25, p_value: '25%' },
                //     { [x_key]: '__Nf3__', [y_axis1]: 25, p_value: '50%' },
                //     { [x_key]: '__Nf4__', [y_axis1]: 25, p_value: '75%' },
                //     { [x_key]: '__Nf5_', [y_axis1]: 25, p_value: '100%' },
                // )

                gaugeSegmentRanges?.forEach(el => {
                    temp_chart_data.push({ [x_key]: `${el?.value3} `, [y_axis1]: el.value2 - el.value1, p_value: `${el?.value1} - ${el?.value2} %`, color: el.value4 });
                });

                setLocalData(temp_chart_data)
                setLocalYDataKeys([y_axis1])
                set_target_column(y_axis2)

                const valuePercentage = ( sum_of_y1/sum_of_y2)*100;
                // const t_value = (180 /  sum_of_y2) * sum_of_y1; 
                const t_value = (180 / maxRange) * valuePercentage; 
                // const t_value = (180 / (maxRange ? maxRange : sum_of_y2)) * (maxRange ? valuePercentage : sum_of_y1); 

                // const parsed = (t_value > 180 ? 180 : t_value < 0 ? 0 : t_value)
                const parsed =  t_value;

                set_value_angle(parsed)
                set_is_single_cell(false)
                set_sum_of_data(sum_of_y1)
                set_sum_of_target(sum_of_y2)
            }

            else {

                let sum_of_y1 = yDataKeys && yac_totals?.[yDataKeys?.[0]]?.['sum']; // y1
                let sum_of_y2 = yDataKeys?.length > 1 && yac_totals?.[yDataKeys?.[1]]?.['sum']; //y2

                set_sum_of_data(sum_of_y1)
                set_sum_of_target(sum_of_y2)

                setLocalXDataKeys(xDataKeys)
                const k = yDataKeys[0];
                setLocalYDataKeys([k]);

                const k1 = yDataKeys?.length > 1 ? yDataKeys[1] : undefined;
                set_target_column(k1)
                const t_value = (sum_of_y2 / sum_of_y1) * 180;
                const parsed = (t_value > 180 ? 180 : t_value < 0 ? 0 : t_value)
                set_value_angle(parsed)
                set_is_single_cell(false)
            }

        } else {
            setLocalXDataKeys(xDataKeys)
            setLocalYDataKeys(yDataKeys)
            set_is_single_cell(false)
        }

    }, [props, gaugeSegmentRanges])



    const renderLabel = function (entry) {

        const column_formating_meta = Alignments && Alignments[yDataKeys && yDataKeys[0]];

        const currency_type = column_formating_meta?.['currency_type'];

        const num_format_type = column_formating_meta?.['num_format_type'] || 'crores';
        const use_decimal = column_formating_meta?.['use_decimal'];

        const d_type = column_formating_meta?.['type'];
        const use_percent = column_formating_meta?.['use_percent'];

        const temp_value = formatValueByDataType(entry.value, d_type, undefined, undefined, undefined, "insights", currency_type, num_format_type, use_decimal, use_percent);
        const entry_value = entry?.name;

        const p_value = entry?.p_value || 0;

        const label = entry?._nf_name_

        const entry_value_new = isSingleDataGauge ? entry_value : checkYearAndMakeFy(entry_value) || formatMonthYearForChart(entry_value)

        const __v__ = (entry_value_new?.length > 12 ? (entry_value_new?.substring(0, 12) + "..") : entry_value_new) + " , " + temp_value;

        return (
            <Text
                x={entry.x}
                // width={insights ? 155 : undefined}
                textOverflow="ellipsis"
                y={entry.y}
                {...dataLabelStyle}
                {...entry}
                fill='#222'
                fontSize={'.9rem'}
                fontWeight={900}
                position="end"
                textAnchor={entry.textAnchor}
            >
                {isSingleDataGauge ? label + " " + p_value : __v__}   {/* label value  */}
                {/* {is_single_cell ? p_value : __v__} */}
            </Text>
        )

    }


    const renderColorfulLegendText = (value, entry) => {

        const { color } = entry;
        const { label, rect } = legendStyle;
        const p_v = show_prcentage_in_legends ? (entry && entry.payload && entry.payload.percent && entry.payload.percent * 100) : null;
        const __value__ = value + " " + (show_prcentage_in_legends ? formatValueByDataType(p_v, dataTypes.percent, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true) : '')

        return <LegendText _style={label} title={__value__}>{__value__}</LegendText>;

    };



    const open_chart_options = (event, data_key, payload) => {

        if (process.env.REACT_APP_MODE === "barc") {
            return;
        }

        const options_required_data = get_required_attributes_for_chart_click_option(event, data_key, payload, xDataKeys, props.columnMeta,)

        const { left, top, width, popup_bg } = options_required_data;

        showPopup(undefined, undefined, enums.popupType.element, ChartFilterPopup, {
            ...options_required_data,
            question: props.question,
            drill_down_values: props.drill_down_values,
            column_meta_details: props.columnMeta,
            b_history: props.history,
            _id: props.id,
            insight_id: props.insight_id,
            insights: props.insights,
            containerWidth: width,
            reporting_db_info_id: props.reporting_db_info_id,
            hide_drill_down: !props.is_drill_down_applicable


        },
            top + "px",
            left + "px",
            popup_bg
        )
    }




    const arrowData = [
        { value: 1 },
    ];

    let pieProps = {};

    let pieRadius = {
        innerRadius: '',
        outerRadius: ''
    };

    if (isGauge) {
        pieProps = {
            startAngle: 180,
            endAngle: 0,
            cy: '60%',
            // stroke: colorForYData?.[0]
        }
        pieRadius = {
            innerRadius: "50%",
        };
    }

    if (isDonut) {
        pieRadius = {
            innerRadius: '60%',
            outerRadius: '80%'
        };
        pieProps = {};
    }




    const formatTotalValue = () => {

        const column_formating_meta = localYDataKeys?.indexOf(localYDataKeys?.[0]) > -1 && Alignments && Alignments?.[localYDataKeys?.[0]] && Alignments?.[localYDataKeys?.[0]]
        const currency_type = column_formating_meta?.['currency_type'];
        const num_format_type = column_formating_meta?.['num_format_type'];
        const use_decimal = column_formating_meta?.['use_decimal'];
        const d_type = column_formating_meta?.['type']
        const use_percent = column_formating_meta?.["use_percent"];

        const formattedTotal = formatValueByDataType(yac_totals?.[yDataKeys?.[0]]?.['sum'], d_type, undefined, undefined, undefined, undefined, currency_type, num_format_type, use_decimal, use_percent)
        return formattedTotal
    };



    const get_width_by_letter = (letter, font_size) => {
        const len = letter.length || 0;
        return len * font_size;
    }


    const Arrow = ({ cx, cy, midAngle, outerRadius, middleRadius }) => {

        //eslint-disable-line react/no-multi-comp

        const RADIAN = Math.PI / 180;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const mx = cx + (outerRadius + widthOfChart * 0.03) * cos;
        const my = cy + (outerRadius + widthOfChart * 0.03) * sin;

        let font_size = 10;

        if (widthOfChart > 500) {
            font_size = 11;
        }
        if (widthOfChart > 700) {
            font_size = 12;
        }
        if (widthOfChart > 800) {
            font_size = 14;
        }

        const column_formating_meta1 = Alignments && Alignments?.[target_column];;

        const currency_type1 = column_formating_meta1?.['currency_type'];
        const use_comma1 = column_formating_meta1?.['num_format_type'];
        const use_decimal1 = column_formating_meta1?.['use_decimal'];
        const d_type1 = column_formating_meta1?.['type'];

        const target_value = formatValueByDataType(sum_of_target, d_type1, undefined, undefined, undefined, 'insights', currency_type1, use_comma1, use_decimal1,)

        const column_formating_meta2 = localYDataKeys?.length > 0 && Alignments && Alignments?.[localYDataKeys[0]];
        const currency_type2 = column_formating_meta2?.['currency_type'];
        const use_comma2 = column_formating_meta2?.['num_format_type'];
        const use_decimal2 = column_formating_meta2?.['use_decimal'];
        const d_type2 = column_formating_meta2?.['type'];

        const data_value = formatValueByDataType(sum_of_data, d_type2, undefined, undefined, undefined, 'insights', currency_type2, use_comma2, use_decimal2)

        const __x1__ = ((localYDataKeys && localYDataKeys.join(' ')) + " = " + data_value)

        return (
            <g>
                <text
                    fill="#000"
                    fontWeight="bold"
                    x={cx - 25}
                    width="100"
                    fontSize={font_size + "px"}
                    y={cy + 25}>
                    {target_column} =
                    {/* {((value_angle / 180) * 100).toFixed(1)} % </text>  */}
                    {/* {((value_angle / 180) * (maxRange ? maxRange : 100)).toFixed(1)} % </text>  */}
                    {((value_angle / 180) * maxRange).toFixed(1)} % </text> 

                <circle cx={cx} cy={cy} r={10} fill="#000" stroke="none" />
                <path
                    d={`M${cx},${cy}L${mx},${my}`}
                    strokeWidth="6"
                    stroke="red"
                    fill="none"
                    strokeLinecap="round"
                />

                {!hide_local_legend && (
                    <>
                        <text
                            x={0}
                            fill="#333"
                            fontWeight="bold"
                            fontSize={font_size + "px"}
                            y={cy + 20}>
                            {__x1__}
                            {/* {sum_of_target && sum_of_target.toFixed(2)} / {sum_of_data && sum_of_data.toFixed(2)} */}
                        </text>

                        <text
                            x={0}
                            fill="#333"
                            fontWeight="bold"
                            fontSize={font_size + "px"}
                            y={cy + 40}>
                            {target_column && target_column} = {target_value}
                        </text>

                    </>
                )}

            </g>
        );
    };

    const CustomActiveShape = (props) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, yData } = props;
        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius + 10}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                    stroke={'#fff'}
                    strokeWidth={1}
                    onContextMenu={(event) => handleRightClick(event, data)}
                    onClick={(e) => {
                        open_chart_options(e, yData, localData[activeIndex])
                    }}
                />
            </g>
        );
    };



    const CustomLegend = ({ payload }) => {

        const uniqueData = Array.from(new Set(payload.map((entry) => entry.payload.name)));

        const uniqueDataWithColors = uniqueData.map((name) => {
            const entry = payload.find((item) => item.payload.name === name);
            return { name, color: entry.payload.fill, };
        });

        return (
            <ul>
                {uniqueDataWithColors.map((item, index) => (
                    <li
                        title={item.name}
                        key={index}
                        style={{
                            display: 'flex',
                            color: item.color,
                            fontSize: '.9rem',
                            alignItems: 'center',
                            lineHeight: '1.2rem'
                        }}
                    >
                        {/* Freezing the width of the first span */}
                        <span
                            style={{
                                width: '.8rem',
                                minWidth: '.8rem',
                                height: '.8rem',
                                background: item.color,
                                display: 'inline-block',
                                marginRight: '5px',
                                borderRadius: '5px'
                            }}
                        ></span>
                        <span
                            style={{
                                display: 'inline-block',
                                color: '#222',
                                maxWidth: '200px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            {item.name}
                        </span>
                    </li>
                ))}

            </ul >
        );
    };


    const GaugeChartLegend = () => {
        return (
            <ul style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0px',
                padding: '0px',
                // marginTop: heightOfChart + "px"
            }}>
                {gaugeSegmentRanges?.map((item, index) => (
                    <li
                        title={item.name}
                        key={index}
                        style={{
                            display: 'flex',
                            color: item.color,
                            fontSize: '.9rem',
                            alignItems: 'center',
                            lineHeight: '1.2rem',
                            marginRight: '10px'
                        }}
                    >
                        {/* Freezing the width of the first span */}
                        <span
                            style={{
                                width: '.8rem',
                                minWidth: '.8rem',
                                height: '.8rem',
                                background: item?.value4,
                                display: 'inline-block',
                                marginRight: '5px',
                                borderRadius: '5px'
                            }}
                        ></span>
                        <span
                            style={{
                                display: 'inline-block',
                                color: '#222',
                                maxWidth: '200px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            {item?.value1}-{item?.value2} &nbsp; {item?.value3}
                        </span>
                    </li>
                ))}

            </ul >
        )
    }


    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const chartRef = useRef(null);

    const handleRightClick = (event, data) => {
        event.preventDefault();
        const boundingRect = chartRef.current.getBoundingClientRect();
        const xPosition = event.clientX - boundingRect.left;
        const yPosition = event.clientY - boundingRect.top;

        setMenuPosition({ x: xPosition, y: yPosition });
        setMenuVisible(true);
    };


    const handleItemClick = async (item) => {
        switch (item) {
            case "Chart Settings":
                open_gauge_range_edit_popup()
                break;
            default:
                break;
        };
    };

    const open_gauge_range_edit_popup = () => {
        showPopup("Configure Your Gauge Chart", undefined, enums.popupType.element_with_header, GaugeRangeSettingsPopup, {
            setGaugeSegmentRanges,
            gaugeSegmentRanges,
            report_item_id: props?.id,
            report_id: (props?.insight_id || props?.reportId)
        }, undefined, undefined, undefined, {
            width: '35rem',
            maxWidth: '35rem'
        })
    }



    return (
        <Wrapper ref={chartRef} insights={insights} id='chart' style={{ position: 'relative', width: widthOfChart ? (widthOfChart - 30) : '93.1vw', textTransform: 'capitalize', margin: 'auto' }}>
            {/* right click functionality */}
            <RightClickMenu
                menuItems={["Chart Settings"]}
                handleItemClick={handleItemClick}
                menuVisible={menuVisible}
                setMenuVisible={setMenuVisible}
                menuPosition={menuPosition}
            />
            <ResponsiveContainer width='100%' height={heightOfChart}>

                {localData && localData.length > 0 && localYDataKeys && (
                    <PieChart>
                        {!hide_tooltip && !is_single_cell && !isSingleDataGauge && (
                            <Tooltip
                                content={function (active) {

                                    let _data = {};
                                    const _payload = active && active.payload;
                                    const _inner_payload = _payload && _payload[0] && _payload[0].payload;
                                    const payload_data = _inner_payload && _inner_payload.payload;
                                    let array_of_keys = [...xDataKeys, ...localYDataKeys];

                                    array_of_keys && array_of_keys.length > 0 && array_of_keys.map((key) => {
                                        if (payload_data && Object.keys(payload_data).length > 0 && Object.keys(payload_data).indexOf(key) > -1) {

                                            const column_formating_meta = localYDataKeys.indexOf(key) > -1 && Alignments && Alignments[key] && Alignments[key]
                                            const currency_type = column_formating_meta?.['currency_type'];
                                            const num_format_type = column_formating_meta?.['num_format_type'];
                                            const use_decimal = column_formating_meta?.['use_decimal'];
                                            const d_type = column_formating_meta?.['type']
                                            const use_percent = column_formating_meta?.["use_percent"];

                                            _data[key] = formatValueByDataType(payload_data[key], d_type, undefined, undefined, undefined, undefined, currency_type, num_format_type, use_decimal, use_percent)
                                        }
                                    })

                                    if (!_data || (_data && Object.keys(_data)?.length === 0)) return null

                                    return <CustomToolTip payload_data={payload_data} data={_data} theme={tooltipTheme} xDataKeys={xDataKeys} />
                                    // }
                                }}
                            />
                        )}

                        {/* {!hide_local_legend && !isGauge && localYDataKeys && yDataKeys?.length > 0 && (
                            <Legend
                                layout={(legendStyle && legendStyle.position) ? legendStyle.position : "vertical"}
                                verticalAlign={(legendStyle && legendStyle.verticalAlign) ? legendStyle.verticalAlign : "middle"}
                                align={(legendStyle && legendStyle.horizontalAlign) ? legendStyle.horizontalAlign : "right"}
                                formatter={renderColorfulLegendText}
                            />
                        )} */}


                        {!hide_local_legend && !isGauge && localYDataKeys && yDataKeys?.length > 0 && (
                            <Legend layout={(legendStyle && legendStyle.position) ? legendStyle.position : "vertical"}
                                verticalAlign={(legendStyle && legendStyle.verticalAlign) ? legendStyle.verticalAlign : "middle"}
                                align={(legendStyle && legendStyle.horizontalAlign) ? legendStyle.horizontalAlign : "right"}
                                content={<CustomLegend />}
                            />
                        )}


                        {
                            localYDataKeys && colorForYData && yDataKeys && yDataKeys.length > 0 && localData && localData.length > 0 && localYDataKeys.map((yData, mainIndex) => {
                                return (
                                    mainIndex == 0 &&
                                    <Pie
                                        onMouseEnter={(_, index) => { setActiveIndex(index); isAnimationActive = false }}
                                        onMouseLeave={() => setActiveIndex(undefined)}
                                        activeShape={(p) => CustomActiveShape({ ...p, yData: yData })}
                                        isAnimationActive={isAnimationActive}
                                        activeIndex={mainIndex == 0 && activeIndex}
                                        key={yData}
                                        data={localData}
                                        nameKey={xDataKeys && xDataKeys[xDataKeys.length - 1]}
                                        dataKey={yData}
                                        labelLine={true}
                                        label={(mainIndex == 0 && show_data_label_local && !is_single_cell) ? renderLabel : null}
                                        {...pieProps}
                                        {...pieRadius}
                                        paddingAngle={0}
                                    // innerRadius={isDonut ? '60%' : mainIndex === 0 ? 0 : heightOfWindow > 900 ? (yDataKeys.length <= 4) ? (100 + (40 * mainIndex)) : (yDataKeyLength > 4 && yDataKeyLength <= 6) ? (100 + (23 * mainIndex)) : (100 + (8 * mainIndex)) : (100 + (20 * mainIndex))}
                                    >

                                        {
                                            localData.map((entry, index) => {

                                                const all_keys = [...xDataKeys];

                                                all_keys.push(yData)

                                                const conditional_data = find_cell_format(props.tbl_formatter, all_keys, entry, undefined, show_percentage_in_data_labels_local);
                                                const cell_color = conditional_data?.cell_color;
                                                let colorsLength = (colorForYData && colorForYData?.length > 0) ? colorForYData.length : undefined;
                                                let colorForCell = entry?.color || colorForYData[index % colorsLength];

                                                return <Cell key={index} fill={cell_color || colorForCell} onContextMenu={(event) => handleRightClick(event, data)} onClick={(e) => {
                                                    open_chart_options(e, yData, entry)

                                                }} />
                                            })
                                        }

                                        {isDonut &&
                                            <Label
                                                value={`Total:  ${formatTotalValue()}`}
                                                position="center"
                                                style={{ fontSize: '1rem', fontWeight: 'bold', fill: '#000' }}
                                            />}
                                    </Pie>
                                )
                            })
                        }

                        {isGauge && is_single_cell && (
                            <Pie
                                startAngle={180}
                                endAngle={0}
                                data={localData}
                                dataKey={localYDataKeys[0]}
                                cy={"60%"}
                                animationBegin={false}
                                label={false}
                                legend={false}
                                innerRadius='80%'
                                outerRadius={"90%"}
                                isAnimationActive={true}
                            >
                                {(localData || localData)?.map((cell, index) => <Cell key={"pie_1" + Index} fill='none' />)}

                                {
                                    <LabelList
                                        dataKey={'p_value'}
                                        position="end"
                                        offset={-2}
                                        style={{ fontSize: '.8rem', fontWeight: 'bold', fill: '#000' }}
                                    />
                                }

                            </Pie>
                        )}


                        {isGauge && (
                            <Pie
                                stroke="none"
                                activeIndex={0}
                                activeShape={Arrow}
                                data={arrowData}
                                // animationBegin={500}
                                outerRadius={pieRadius.innerRadius}
                                fill="none"
                                label={true}
                                {...pieProps}
                                startAngle={180 - value_angle}
                                endAngle={(180 - value_angle) + 0.1}
                            />
                        )}

                        {isGauge && !hide_local_legend && (
                            <Legend
                                layout='horizontal'
                                verticalAlign={"bottom"}
                                align={'center'}
                                height={50}
                                content={<GaugeChartLegend />}
                            />
                        )}
                    </PieChart>

                )}

            </ResponsiveContainer>
        </Wrapper >
    )
}




const mapStateToProps = (state, props) => {

    const table_format_setting_cache = state.tableFormatReducer.table_format_setting_cache;
    const tbl_formatter = table_format_setting_cache && table_format_setting_cache[props.id];

    const __id__ = (props?.id || (props?.insight_id || props?.reportId));

    const gaugeDataRanges = state.gaugeReducer?.gaugeDataRangesCache?.[__id__];

    return { tbl_formatter, gaugeDataRanges }

}
const mapDispatchToProps = {
    getGaugeSegmentRangeData

}


export default connect(mapStateToProps, mapDispatchToProps)(SimplePieChart);