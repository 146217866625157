import React from 'react';
import styled from 'styled-components';

const getTopLeftByPosition = (position) => {

    if(position == 'top') {
        return {
            right: '0px',
            left: undefined,
            top: '-30px' 
        }
    }
       
    if(position == 'bottom') {
        return {
            right: '0px',
            left: undefined,
            top: '-30px' 
        }
    }
    if(position == 'right') {
        return {
            right: undefined,
            left: '109%',
            top: '18%' ,
            content: undefined
        }
    }else{
        return {
            right: '8px',
            left: undefined,
            top: '113%' 
        }
    }
}


const TooltopWrapper = styled.div`
        visibility: hidden;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 10px;
        position: absolute;
        z-index: 5;
        top: ${props => props.top ? props.top : undefined};
        left: ${props => props.left ? props.left : undefined};
        bottom: ${props => props.bottom ? props.bottom : undefined};
        right: ${props => props.right ? props.right : undefined};
        /* top: ${props => props.position === "top" ? "-30px" : '8px'}; */
        
        /* right: ${props => props.position == "top" ? '0px' : '113%'}; */
        /* right: ${props => props.tootopDirections === 'right' ? "-120%" : undefined}; */
        font-size: 12px;
        white-space: nowrap;    
        
    &::after {
        content: ${props => props.content ? props.content : undefined};
        position: absolute;
        top: 50%;
        left: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent transparent black;
        /* border-color: ${props => props.tootopDirections === 'right' ? 'black transparent transparent transparent' : undefined}; */
    }
`;


const index = (props) => {

    const { title, className, position } = props;

    let styled_object = getTopLeftByPosition(position)

    return (
        <React.Fragment>
            {title && <TooltopWrapper {...styled_object} position={position} className={className}>{title}</TooltopWrapper>}
        </React.Fragment>
    )
}

export default index
