import React from "react";

const Xpress = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 187 167"
    >
      <path
        d="M0 0 C1.00289062 0.74765625 2.00578125 1.4953125 3.0390625 2.265625 C9.75400127 7.37276153 9.75400127 7.37276153 10.82421875 10.1015625 C11 12 11 12 10 14 C6.83527807 14.34423291 5.43922982 14.33062027 2.8515625 12.3828125 C1.74941406 11.26519531 1.74941406 11.26519531 0.625 10.125 C-22.49259123 -11.65690819 -55.16257944 -21.75886718 -86.52734375 -21.21484375 C-113.53477649 -20.2457783 -141.54471782 -10.98472633 -162.21875 6.72265625 C-162.75701416 7.18357666 -163.29527832 7.64449707 -163.84985352 8.11938477 C-165.3522317 9.4258508 -166.83121267 10.75908192 -168.30859375 12.09375 C-171.23358531 14.16544214 -172.50607879 14.45655595 -176 14 C-176.36096507 10.81795411 -176.35872322 9.45494544 -174.33984375 6.89453125 C-125.25215363 -35.64813352 -51.89884188 -40.82531007 0 0 Z "
        fill="#06315B"
        transform="translate(176,28)"
      />
      <path
        d="M0 0 C0.66263855 0.00149048 1.3252771 0.00298096 2.00799561 0.0045166 C11.79363654 0.04412142 20.8293985 0.49728791 30.25 3.3125 C31.23613281 3.57933594 32.22226563 3.84617188 33.23828125 4.12109375 C49.52070036 8.97347031 64.54831267 18.14091433 77.25 29.3125 C78.1875 31.5 78.1875 31.5 78.25 33.3125 C77.92 33.9725 77.59 34.6325 77.25 35.3125 C74.22945974 35.60616364 72.7402284 35.67251148 70.25 33.84375 C69.26 32.83828125 69.26 32.83828125 68.25 31.8125 C46.84294956 11.78313103 18.82256103 6.32785238 -9.52441406 7.13330078 C-31.52479461 8.35554414 -53.0854156 18.63110743 -69.12890625 33.40625 C-72.01313082 35.50386787 -73.27350559 35.7651358 -76.75 35.3125 C-77.05022032 32.31029676 -77.14724456 30.82954847 -75.2734375 28.390625 C-53.66904921 10.0072546 -28.48857738 -0.12200676 0 0 Z "
        fill="#06315B"
        transform="translate(92.75,33.6875)"
      />
      <path
        d="M0 0 C4.4327003 2.97075263 7.30483611 5.92569985 8.47265625 11.28125 C9.3044493 17.00849174 8.98472974 20.86775632 5.97265625 25.98828125 C2.45064626 30.05213893 -0.42359568 32.6764117 -5.83203125 33.32421875 C-12.0660864 33.40353243 -16.23052461 33.42533896 -21.02734375 28.98828125 C-25.85841152 23.50436649 -26.37759118 20.18288726 -26.30859375 12.97265625 C-25.81634329 7.74933191 -23.32150166 5.21432559 -19.65234375 1.73828125 C-13.48600722 -2.37260977 -6.771643 -3.37929776 0 0 Z "
        fill="#08335C"
        transform="translate(102.02734375,134.01171875)"
      />
      <path
        d="M0 0 C1.37620988 1.19576749 2.70694412 2.44522537 3.99609375 3.734375 C4.12109375 6.359375 4.12109375 6.359375 3.99609375 8.734375 C-0.48975856 9.10244493 -0.48975856 9.10244493 -2.75 7.484375 C-3.58724609 6.618125 -3.58724609 6.618125 -4.44140625 5.734375 C-19.58056458 -8.29704004 -40.66325973 -14.03565557 -60.91796875 -13.515625 C-79.25166851 -12.64466539 -96.42356932 -5.48331591 -110.234375 6.49609375 C-113.00390625 8.734375 -113.00390625 8.734375 -115.87890625 9.046875 C-116.58015625 8.94375 -117.28140625 8.840625 -118.00390625 8.734375 C-118.29296875 6.50390625 -118.29296875 6.50390625 -118.00390625 3.734375 C-107.95988605 -7.68301123 -92.33421155 -14.31519486 -78.00390625 -18.265625 C-77.11703125 -18.51183594 -76.23015625 -18.75804688 -75.31640625 -19.01171875 C-48.93331746 -24.9891373 -20.56740864 -16.98045257 0 0 Z "
        fill="#06315B"
        transform="translate(150.00390625,87.265625)"
      />
      <path
        d="M0 0 C1.421875 1.2421875 1.421875 1.2421875 3.171875 3.6796875 C2.984375 6.4921875 2.984375 6.4921875 2.171875 8.6796875 C-1.93636577 8.1343458 -4.25463754 6.88434642 -7.515625 4.3671875 C-17.85787158 -3.1502365 -28.21321813 -5.11506608 -40.828125 -4.3203125 C-49.62334699 -2.83692181 -58.52011535 0.52490436 -65.203125 6.5546875 C-67.828125 8.6796875 -67.828125 8.6796875 -70.203125 8.4921875 C-70.739375 8.2240625 -71.275625 7.9559375 -71.828125 7.6796875 C-72.158125 6.3596875 -72.488125 5.0396875 -72.828125 3.6796875 C-51.44837876 -15.01019913 -23.37730572 -16.88360969 0 0 Z "
        fill="#06315B"
        transform="translate(127.828125,113.3203125)"
      />
      <path
        d="M0 0 C3.75212276 2.01502889 5.63644095 3.45466142 8 7 C8.76768116 11.6864257 8.40885085 15.19690011 6.25 19.4375 C2.86468889 23.2929932 1.28994853 23.83082642 -3.8125 24.5 C-8.22361901 24.40913425 -10.4949332 22.51834181 -13.65234375 19.60546875 C-15.71006082 17.15410146 -16.0156376 14.88383497 -16.4375 11.75 C-15.8560227 6.76590888 -13.62718402 4.32648483 -10 1 C-6.78734554 -0.60632723 -3.48478188 -0.47931944 0 0 Z "
        fill="#D3D7DD"
        transform="translate(97,138)"
      />
    </svg>
  );
};

export default Xpress;
