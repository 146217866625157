import styled from 'styled-components';
import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils';
const selected = getSelectedTheme();

const { label, reportList, svgConfig } = themes[selected]



export const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    margin: auto;
    position: relative;

    .formula_main{
        max-height: 8rem;
        margin-top: 1rem;
        width: 100%;
        /* overflow: auto; */
        &::-webkit-scrollbar {
            background-color:red;
            width:5px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }
    }

    .table_formula{
        width: 100%;
        border: 1px solid #eaeaea;
        border-collapse: collapse;

        thead{
            td{
                font-weight: 500;
            }
        }
        td{
            border: 1px solid #ccc;
            padding: .5rem;
            font-size: .9rem;

            .btn_new_1{
                cursor: pointer;
            }
        }
    }
`;




export const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 20px 10px; */
    /* padding-bottom: 20px; */
    box-sizing: border-box; 

`;


export const DataFields = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
    width: 100%;

    label {
        margin-right: 10px;
        font-size: 14px;
    }

    input {
        background: transparent;
        border: 0;
        border-bottom: 1px solid #c1c1c2;
    }

    select {
        background: transparent;
        border: 1px solid #c1c1c2;
        border-radius:5px;
        margin-left: 5px;
        box-sizing: border-box;
    }
`;


export const CloseWrapper = styled.div`
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 20px;
`;
 

export const ReportItemWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: start;
    width: 86%;
    gap: 1.4rem;
    margin: 0 auto;
`;

export const ButtonWrapper = styled.div`
    position: absolute ;
    bottom:0;
    left: 0;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
`;


export const ButtonWrapperRow = styled.div`    
    border: 1px solid #ccc;
    padding: 7px;
    height: 20px;
    width: 27px;
    text-align: center;
    font-size: 14px;
    color: #222;
    width: 100%;
    padding: 10px 0px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex-direction: row;
`;


export const ViewerWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
`;


export const ViewerMainWrapper = styled.div`
    background: white;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
    padding: 10px;
    border-radius: 5px;
    height: fit-content;
    min-width: 400px;

    @media screen and (max-width: 1400px) {
        height: 500px;
        overflow-y: scroll;
    }
`;


export const Report = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 18px;
`;


export const HeadingWrapper = styled.div`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
`;





export const EditorInnerWrapper = styled.div`
    width: 100%;
    padding: 0px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 0px;
    box-sizing: border-box;
    /* border: 10px solid #eaeaea; */
    box-shadow: 0px 2px 6px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    overflow: auto;
    height:${props => props.scroll ? "calc(100vh - 180px)" : 'calc(100vh - 120px)'};
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;

    .add_btn_new_field{
        padding: 5px 9px;
        border-radius: 3px;
        display: inline-block;
        margin-top: 10px;
        box-shadow: rgba(0, 0, 0, 0.53) 0px 0px 2px 0px;
        font-size: .9rem;
        cursor: pointer;
    }

    .scrolled_div{
        padding: 2.1rem;
        box-sizing: border-box;
        height:${props => props.scroll ? "calc(100vh - 180px)" : 'calc(100vh - 120px)'};
        overflow: auto;
        width: 100%;
        &::-webkit-scrollbar {
            background-color:red;
            width:5px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }

    }
    
      &::-webkit-scrollbar {
            background-color:red;
            width:5px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }


    .add_fields_btn{
        font-size: 1rem;
        /* margin-bottom: 20px; */
        cursor: pointer;
        color: #000;
    }

    .fields_item_ul{
        padding: 0px;
        margin: 0px ;
        display: flex;
        flex-direction: column; 
        flex-wrap:wrap;
        width: 100%;
        overflow: auto;
        padding-left: 20px;
        align-content: flex-start;
        max-height: 9rem;
        padding-bottom: 1rem;
        
        &::-webkit-scrollbar {
            background-color:red;
            width:3px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 3px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 3px;
        }

        li{
           margin-right: 50px;
           line-height: 1.71;
           color: ${label.color};
           position: relative;
           &:hover{
               .hover_element{
                   opacity: 1;
               }

           }

           .hover_element{
              opacity: 0;
              display: inline-block;
               cursor: pointer;
               position: absolute;
               right: .5rem;
               top: 2px;
               vertical-align: middle;
               :hover{
                svg{
                   fill: #76919d;
                 }
               }
           }
            span{
                width: 90%;
                text-overflow: ellipsis;
                overflow: hidden;
                box-sizing: border-box;
                padding-right: 15px;
                display: block;
                text-transform: capitalize;


            }
        }
    }
`;


export const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 32px;
`;

export const TableHeaderWrapper = styled.div`
    width: 100%;
    margin-top: 10px;
    padding-top: 0.7rem;
    background: ${props => props.backgroundColor || 'transparent'};
    display: flex;
    justify-content: space-between;
    /* box-shadow: 0px -4px 4px 0px rgba(186,186,186,.6); */
    button {
        margin-right: 12px;
        vertical-align: center;
    }
`;
