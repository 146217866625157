import React from "react";

const FormatIcon = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#3E9DFF"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 968 968"
      preserveAspectRatio="xMidYMid meet"
    >
      <path transform="translate(476,2)" d="m0 0 38 1 30 3 32 5 34 8 27 8 25 9 27 12 16 8 21 11 19 12 19 13 12 9 14 11 15 13 13 12 22 22 9 11 8 9 13 17 11 15 11 17 12 20 14 27 9 19 9 23 11 33 8 32 5 27 4 33 2 32v23l-2 32-5 38-6 30-8 30-10 29-12 29-10 21-13 23-9 15-10 15-10 14-11 14-11 13-7 8-9 10-24 24-11 9-9 8-17 13-18 13-19 12-21 12-23 12-25 11-24 9-25 8-28 7-26 5-35 5-22 2h-38l-29-2-36-5-31-6-36-10-26-9-26-11-27-13-23-13-20-13-17-12-14-11-11-9-10-9-8-7-12-11-16-17-10-11-11-14-12-15-20-30-14-24-12-23-11-25-11-30-9-30-8-36-5-35-3-30v-46l5-43 5-30 7-30 9-29 10-27 12-27 10-19 10-18 12-19 11-16 13-17 14-17 11-12 34-34 11-9 16-13 19-14 22-14 26-15 28-14 38-15 29-9 28-7 35-6 37-4z" fill="#7D90FA" />
      <path transform="translate(387,261)" d="m0 0 186 1 9 6 14 11 14 12 11 9 15 13 2 1v2l4 2 5 4 1 3-5-2-13-10-15-13-10-8-14-12-14-11-4-3 1 60 75 1 1 1v341l-1 24-3 6-6 6-5 2-8 1h-304l-10-4-7-8-1-4-1-20v-394l4-6 8-7 7-3z" fill="#FEFEFE" />
      <path transform="translate(370,509)" d="m0 0h215l3 2v13l-4 2h-212l-5-2-2-4 2-9z" fill="#7D90FA" />
      <path transform="translate(370,562)" d="m0 0h216l2 2v13l-6 2h-205l-9-1-3-5 1-8z" fill="#7D90FA" />
      <path transform="translate(376,456)" d="m0 0h209l3 2v13l-1 1-8 1h-205l-7-2-2-3 1-8 3-3z" fill="#7D90FA" />
      <path transform="translate(384,403)" d="m0 0h201l3 3v12l-1 1-10 1h-201l-8-1-3-4v-6l2-4 2-1z" fill="#7D90FA" />
    </svg>
  );
};

export default FormatIcon;
