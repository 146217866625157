import React from 'react';

const Next = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 147 342.5"
            space="preserve">
            <g>
               <path class="fil0" d="M3 17c-4,-4 -4,-10 0,-14 4,-4 10,-4 14,0l127 127c4,4 4,10 0,14l-127 127c-4,4 -10,4 -14,0 -4,-4 -4,-10 0,-14l120 -120 -120 -120z" />
            </g>
        </svg>

    )

}


export default Next;