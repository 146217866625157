import * as actions from '../action-types/sqoop_configration';

const initialState = {
    type: actions.SQOOP_INIT,
    configrationData: undefined,
    editedData: undefined,
    test_connection_one: undefined,
    test_connection_two: undefined

};

const sqoopConfigration = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {

        case actions.GET_THE_CONFIGRATION:
            return {
                ...state,
                type: action.type,
                configrationData: action.data
            }

        case actions.GET_EDITED_CONFIGRATION_DATA:
            return {
                ...state,
                type: action.type,
                editedData: action.data
            }
        case actions.SAVE_SQOOP_CONFIGRATION:
            return {
                ...state,
                type: action.type,
                editedData: undefined
            }
        case actions.CLEAR_EDITED_CONFIGRATION_DATA:
            return {
                ...state,
                type: action.type,
                editedData: undefined
            }
        case actions.TEST_CONNECTION_ONE_SUCCESS:
            return {
                ...state,
                type: action.type,
                test_connection_one: true
            }
        case actions.TEST_CONNECTION_TWO_SUCCESS:
            return {
                ...state,
                type: action.type,
                test_connection_two: true
            }
        case actions.RESET_CONNECTION_TWO_SUCCESS:
            return {
                ...state,
                type: action.type,
                test_connection_two: undefined
            }
        case actions.RESET_CONNECTION_ONE_SUCCESS:
            return {
                ...state,
                type: action.type,
                test_connection_one: undefined
            }
        default:
            return {
                ...state,
            };
    }
};

export default sqoopConfigration;