import React from "react";

const SaveIcon = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#3E9DFF"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 43 44"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M0 0 C0.99 0 1.98 0 3 0 C3 6.27 3 12.54 3 19 C14.55 19 26.1 19 38 19 C38 13.06 38 7.12 38 1 C39.32 1 40.64 1 42 1 C42.02887814 4.47914941 42.04675687 7.95826831 42.0625 11.4375 C42.07087891 12.43072266 42.07925781 13.42394531 42.08789062 14.44726562 C42.09111328 15.39150391 42.09433594 16.33574219 42.09765625 17.30859375 C42.10289307 18.18314209 42.10812988 19.05769043 42.11352539 19.95874023 C42 22 42 22 41 23 C37.97022196 23.08871273 34.96332978 23.1151935 31.93359375 23.09765625 C31.02497086 23.0962413 30.11634796 23.09482635 29.18019104 23.09336853 C26.26592779 23.08775193 23.35174019 23.07519665 20.4375 23.0625 C18.46679798 23.05748714 16.49609476 23.05292386 14.52539062 23.04882812 C9.68354086 23.03862617 4.84185553 23.01905409 0 23 C0 15.41 0 7.82 0 0 Z "
        transform="translate(1,21)"
      />
      <path
        d="M0 0 C1.32 0.33 2.64 0.66 4 1 C4 8.92 4 16.84 4 25 C5.32 25.33 6.64 25.66 8 26 C6.68775452 29.93673644 5.08089673 32.22719294 2 35 C-1.53586014 31.82679218 -3.58578031 29.83256305 -4 25 C-3.01 25 -2.02 25 -1 25 C-0.67 16.75 -0.34 8.5 0 0 Z "
        transform="translate(20,1)"
      />
    </svg>
  );
};

export default SaveIcon;
