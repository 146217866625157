import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { themeObject, bind_theme_data_to_object, apply_color_sets_to_colors } from '../../theme/index';
import { CHART_TYPES, RENDER_MODE } from '../../shared-with-fe/constants';
import ColorPicker from '../hoc/color.picker.new'

const Wrapper = styled.div`
    padding: 0px 10px;
    box-sizing: border-box;
    width: 100%;
    .setting_wrapper{
        width: 100%;
    }
    .theme_s_tbl{
        width: 100%;
        border-collapse: collapse;
        thead {
            td{
                font-weight: bold;
                padding: 10px 5px;
            }
        }
        td{
            border: 1px solid #ccc;
            padding: 0px 5px;
            font-size: .8rem;

            input{
                width: 114px;
                box-sizing: border-box;
                height: 31px;
                border: 0px;

                &:focus {
                    outline: none;
                }

            }
        }
    }

    .child_0{
        &::before{
            position:absolute;
            /* content: ''; */
            height: 100%;
            left: 0px;
            top: 0px;
        }
    }

    .child_1{
        padding-left: 1.3rem;
        .action_icon{
            left: 13px;
        }
        &::before{
            position:absolute;
            /* content: ''; */
            height: 100%;
            border: 1px dotted #0000005c;
            left: 7px;
            top: 0px;
        }
        &::after{
            position:absolute;
            /* content: ''; */
            /* height: 100%; */
            border: 1px dotted #0000005c;
            left: 7px;
            top: 18px;
              width: 20px;
        }
    }

    .child_2{
        padding-left: 2rem;
        &::before{
            position:absolute;
            /* content: ''; */
            height: 100%;
            border: 1px dotted #0000005c;
            left: 21px;
            top: 0px;
        }
         &::after{
            position:absolute;
            /* content: ''; */
            border: 1px dotted #0000005c;
            left: 21px;
            top: 18px;
            width: 20px;
        }
    }
   
`;


const chart_types = Object.values(CHART_TYPES);

export const Tab = styled.div`
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: .8rem;
    text-overflow: ellipsis;
    text-transform: capitalize;
    color: ${props => props.active ? "#fff" : '#222'};
    position: relative;
    padding-right: 0px;
    padding-left: ${props => ((props.indentation + 1) * 20) + "px"};


    &::before{
        position:absolute;
        /* content: ''; */
        height: 100%;
        opacity: ${props => (props.indentation === 0) && '0'};
        border: 1px dotted #0000005c;
        left: ${props => (props.indentation === 1 ? '7px' : (props.indentation * 12 + 'px'))};
        top: 0px;
    }
    &::after{
        position:absolute;
        /* content: ''; */
        opacity: ${props => (props.indentation === 0 || props.is_child) && '0'};
        border: 1px dotted #0000005c;
        left: ${props => (props.indentation === 1 ? '10px' : (props.indentation * 12 + 'px'))};
        top: 15px;
        width: ${props => (props.indentation === 1 ? '20px' : (props.indentation * 12 + 'px'))};
    }
    
    .action_icon{
        width: 1.2rem;
        height: 1.2rem;
        background: #fff;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: ${props => ((props.indentation) * 15) + "px"};
        top: 7px;
        border: 1px solid #333;
        font-size: 1rem;
        padding-bottom: .1rem;
        box-sizing: border-box;
        border-radius: 50%;
        margin-right: 10px;

    }
    .label{
        font-size: .8rem;
        font-weight: 400;
        padding-left: 12px;
        color: #333333;
        
    }
`;


const color_box_key = ['color', 'Color', 'background_color', 'background', 'chart_colors']

const ThemeSetting = (props) => {

    const { themeValues, update_theme_value, render_mode, chart_type, report_item_id, theme_key = 'insight' } = props;
    const [openMenu, setOpenMenu] = useState(undefined);
    const [localProperty, setLocalProperty] = useState(undefined);
    const [local_theme, set_local_theme] = useState(undefined);
    const [text_box_values, set_text_box_values] = useState(undefined);
    const [theme_values_from_db, set_theme_values_from_db] = useState({});
    const [local_report_item_id, set_local_report_item_id] = useState(undefined);
    const [color, setColor] = useState(undefined);

    // this object will be bound with theme values from DB

    const main_theme = JSON.parse(JSON.stringify(themeObject));

    useEffect(() => {
        if (typeof local_report_item_id !== 'undefined') {
            if (local_report_item_id === report_item_id) {
                // don't process it is a re-request
                return;
            }
        }

        const control_type = (chart_type === "tower_chart") ? chart_type : (render_mode === 'chart' && chart_types.indexOf(chart_type) > -1 ? render_mode : chart_type);
        const theme_settings_for_this_control = main_theme[theme_key][control_type || ''];
        let __themeValues__ = {};

        if (themeValues) {
            set_theme_values_from_db(themeValues);
            __themeValues__ = themeValues;
        } else {
            set_theme_values_from_db({})
        }

        const temp_array = [];

        if (theme_settings_for_this_control) {
            bind_theme_data_to_object(theme_settings_for_this_control, __themeValues__);
            apply_color_sets_to_colors(theme_settings_for_this_control, main_theme.colorset);

            const entries = Object.keys(theme_settings_for_this_control);

            entries && entries.length > 0 && entries.forEach(key => {
                let __obj__ = {
                    name: key,
                    indentation: 0,
                    children: undefined
                }
                if (theme_settings_for_this_control[key] && Object.keys(theme_settings_for_this_control[key]).length > 0 && typeof theme_settings_for_this_control[key] === 'object') {
                    __obj__.children = theme_settings_for_this_control[key];
                }
                temp_array.push(__obj__)
            })
        }

        set_local_theme(theme_settings_for_this_control);
        setLocalProperty(temp_array)
        set_local_report_item_id(report_item_id);
    }, [themeValues, report_item_id])


    const click_on_open_tab = (parent_name, current_name, child, index, indentation) => {
        let temp_array = localProperty ? [...localProperty] : [];
        const child_keys = localProperty[index] && localProperty[index].children && Object.keys(localProperty[index].children);
        const current_element = localProperty[index];

        const find_items_to_delete = () => {
            const this_index = index;
            const this_indentation = current_element.indentation;
            let items_to_delete = 0;

            // loop to next
            for (let p_index = index + 1; p_index < localProperty.length; p_index++) {
                const next_property = localProperty[p_index];
                if (next_property && next_property.indentation > this_indentation) items_to_delete = items_to_delete + 1;
                else break;
            }

            return items_to_delete;
        };

        const items_to_delete = find_items_to_delete();

        if (current_element && current_element.open) {
            temp_array[index].open = false;
            temp_array.splice((index + 1), items_to_delete)
            setLocalProperty(temp_array)

        } else {
            temp_array[index].open = true;

            const slice_prev_element_with_current = temp_array.slice(0, index + 1);
            const sliced_element_next = temp_array.slice((index + 1))
            const child_array = [];

            child && Object.keys(child).length > 0 && Object.keys(child).map((c) => {
                let _obj_to_push_ = {
                    name: c,
                    parent_name: parent_name ? parent_name + '_nf_' + current_name : current_name,
                    indentation: indentation + 1,
                    children: undefined,
                };
                if (child[c] && Object.keys(child[c]).length > 0 && typeof child[c] === 'object') {
                    _obj_to_push_.children = child[c];
                }
                child_array.push(_obj_to_push_)
            })
            const final_array = [...slice_prev_element_with_current, ...child_array, ...sliced_element_next];
            setLocalProperty(final_array)
            setOpenMenu(current_name)
        }
    }


    return (
        <Wrapper>
            <div className="setting_wrapper">
                <table className="theme_s_tbl">
                    <tbody>
                        {localProperty && localProperty.length > 0 && localProperty.map((property, index) => {
                            let value = undefined;
                            let parent_object = {};
                            let parent_name = undefined;

                            // console.log('property: ', property)

                            if (property && typeof property.children === 'undefined') {
                                // ok this one is the value we need to pick and assign the value to;

                                parent_name = property.parent_name;

                                if (parent_name) {
                                    // nested property
                                    const properties = parent_name.split('_nf_');

                                    let final_object = undefined;

                                    properties.forEach(p => {
                                        if (!final_object) {
                                            final_object = local_theme[p];
                                        }
                                        else {
                                            final_object = final_object[p];
                                        }
                                    });

                                    value = final_object && final_object[property.name];

                                }
                                else {
                                    // not a nested one. safe and sound :)
                                    value = local_theme[property.name];
                                }
                            }

                            let is_it_color_box = color_box_key.indexOf(property.name) > -1;

                            if (!is_it_color_box) {
                                if (!isNaN(property.name) && property.parent_name && color_box_key.indexOf(property.parent_name.toLocaleLowerCase()) > -1) {
                                    is_it_color_box = true;
                                }
                            }

                            return (
                                <tr>
                                    <td style={{ width: '70%' }}>
                                        <Tab
                                            indentation={property.indentation}
                                            is_child={property.children}
                                            // className={_class}
                                            onClick={() => {
                                                if (property.children) {
                                                    click_on_open_tab(property.parent_name, property.name, property.children, index, property.indentation);
                                                }
                                            }}>
                                            {property.children && (
                                                <div className="action_icon">
                                                    {property.open ? '-' : '+'}
                                                </div>
                                            )}
                                            <div className="label">{property.name}</div>
                                        </Tab>
                                    </td>

                                    <td>
                                        {!property.children && (
                                            <React.Fragment>

                                                {is_it_color_box ?
                                                    <ColorPicker
                                                        value={value}
                                                        onChange={(color) => {

                                                            const local_theme_clonned = JSON.parse(JSON.stringify(local_theme));
                                                            const values_from_db_clonned = JSON.parse(JSON.stringify(theme_values_from_db)) || {};

                                                            const properties = property.parent_name ? property.parent_name.split('_nf_') : [];
                                                            let final_object = local_theme_clonned;
                                                            let final_theme_to_update = values_from_db_clonned;

                                                            properties.forEach(p => {
                                                                if (final_object) {
                                                                    final_object = final_object[p];
                                                                }
                                                                if (typeof final_theme_to_update[p] === 'undefined') {
                                                                    final_theme_to_update[p] = {};
                                                                }

                                                                final_theme_to_update = final_theme_to_update[p];
                                                            });

                                                            if (final_object[property.name]) final_object[property.name] = color;

                                                            final_object[property.name] = color;
                                                            final_theme_to_update[property.name] = color;
                                                            set_theme_values_from_db(values_from_db_clonned);
                                                            set_local_theme(local_theme_clonned);
                                                            update_theme_value(values_from_db_clonned)

                                                        }} />
                                                    :
                                                    <input onChange={(event) => {

                                                        const local_theme_clonned = JSON.parse(JSON.stringify(local_theme));
                                                        const values_from_db_clonned = JSON.parse(JSON.stringify(theme_values_from_db)) || {};

                                                        const properties = property.parent_name ? property.parent_name.split('_nf_') : [];
                                                        let final_object = local_theme_clonned;
                                                        let final_theme_to_update = values_from_db_clonned;

                                                        properties.forEach(p => {
                                                            if (final_object) {
                                                                final_object = final_object[p];
                                                            }

                                                            if (typeof final_theme_to_update[p] === 'undefined') {
                                                                final_theme_to_update[p] = {};
                                                            }

                                                            final_theme_to_update = final_theme_to_update[p];
                                                        });

                                                        if (final_object[property.name]) final_object[property.name] = event.target.value;

                                                        final_object[property.name] = event.target.value;
                                                        final_theme_to_update[property.name] = event.target.value;
                                                        set_theme_values_from_db(values_from_db_clonned);
                                                        set_local_theme(local_theme_clonned);
                                                        update_theme_value(values_from_db_clonned)
                                                        // console.log("theme_values_from_db", values_from_db_clonned)

                                                    }} type={is_it_color_box ? 'color' : 'text'} value={value} />}

                                                {/* } */}
                                                {/* <div>{JSON.stringify(theme_values_from_db)}</div> */}
                                            </React.Fragment>
                                        )}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </Wrapper>
    )
}

export default ThemeSetting;
