import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../header';
import Insights from '../open-route/insights';
import Dashboard from '../dashboard';
import { FullWidthDiv } from '../full-width-div';
import { MainWrapper } from './home.styled';
import ReportList from '../report-editor';
import ReportEditor from '../report-editor/editors/mini.editor';
import TrainYourPolus from '../train-your-polus';
import LayoutEditor from '../layout-editor';
import PolusLogin from '../login';
import Entity from '../entity';
import EntityInfo from '../entity-info';
import RankingConfig from '../ranking/ranking.config'
import DoctorRanking from '../ranking/doctor.ranking'
import Discovery from '../discovery';
import PredictiveAnalytics from '../predictive-analytics'
import QuestionSearch from '../question-search'
import OutlierComp from '../outlier-comp'
import DimensionCoRelation from '../dimension-co-relation'
import NF_GRAMMAR from '../nf_grammar'
import PredictiveWithInput from '../predictive-analytics/predictive_with_input';
import POLUS_INTERPRETATION from '../polus_interpretation';
import Context from '../context'
import DasboardList from '../dashboard-list'
import FormList from '../form-list';
import PolusSearch from '../polus-search'
import DbConnection from '../db-connection';
import Favourites from '../favourtite/index';
import History from '../history/index';
import SqlHistory from '../sqlHistory/index';
import Security from '../security';
import DemoSample from '../demo';
import DemoView from '../demoView';
import MasterData from '../master-data';
import DemoRequest from '../demo-request';
import POC from '../POC'
import ETL from '../etl';
import ETLDetails from '../etl/etl.details';
import EtlEditor from '../etl/etl.add.edit';
import AnalyticsMap from '../analytics-map';
import MultiDisplay from '../multi-display';
import TblEditInformation from '../tbl_edits';
import Campaign from '../campaign';
import AddMarkup from '../add_markup';
import SqoopConfigration from '../etl/sqoop_configration'
import SqoopConfigrationAddEdit from '../etl/sqoop_configration/add.edit'
import NfColumnInfoAdTech from '../nf_column_info_ad_tech';
import { themes } from '../../theme/theme.config';
import { getMandatoryRequestHeaders, getPermissionById, getSelectedTheme } from '../../utils';
import TblErrors from '../tbl_errors';
import Streaming from '../streaming_data';
import StreamingEditor from '../streaming_data/streaming_editro';
import ShareableInsights from '../open-route/open-share/shareable.insights';
import ShareableReport from '../open-route/open-share/shareable.report';
import { dispatchHeaderInformation } from '../../actions/common'
import DbExplorer from '../db.explorer';
import DataSyncUpEditor from '../data_sync_up/data.sync.up.editor';
import { constants } from '../../utils/constants';
import { getFromSession, saveInSession } from '../../utils/session.helper';
import ConfigEditor from '../config.editor';
import GroupAccess from '../access-group';
import ModuleAddEdit from '../access-group/module.add.edit';
import Rules from '../rules.ui';
import UsersList from '../users-list';
import DataSourceEditor from '../data.source.editor'
import CacheEditor from '../cache.editor';
import RulesOverValues from '../rules.over.values'
import DataHierarchy from '../data-hierarchy';
import MenuAccess from '../menu-access';
import MenuAddEdit from '../menu-access/module.add.edit';
import { useHistory } from 'react-router-dom'
import RelationshipPage from '../relationship-page';
import HumanInterpretation from '../human-interpretation'



import RulesAlert from '../rules_alert';
import RulesAlertTickets from '../rules_alert/rules.tickets';
import FileUploadConfig from '../file.upload.config';
import FormulaEditorV1 from '../formula_builder_v1'
import DbFormulas from '../formula_builder_v1/pages/main';
import ActualPlainMetting from '../actual_plan_metting';
import FileLogs from '../file.logs';
import XLSViwer from '../xls'
import UserActivity from '../userActivity/index'
import { COMPONENT_IDS, generate_element_id } from '../../utils/elementids';
import DataPolicy from '../data-policy-v1/index.view';
import DataPolicyEditor from '../data-policy-v1';
import axios from 'axios';
import TestPage from '../PolusV2/Test'
import DefaultPage from '../default'
import ChatPolusAi from '../chatPolusAi';


const Home = props => {

    const selected = getSelectedTheme()
    const { bodyStyle } = themes[selected];



    const clientIdFromSession = getFromSession(constants.SESSION_KEYS.CLIENT_ID);
    const clientId = clientIdFromSession;

    const { user, location, history, hints, width, height, db_infos, menuPermissions } = props;
    const { pathname, search } = location;




    /**
     * This code is responsible for handling redirection logic based on fetched reports and session values.
     */
    useEffect(async () => {
        // Construct the URL for the API endpoint to fetch all reports
        const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.GET_ALL_REPORTS;

        // Define the options for the axios request, including mandatory headers
        const options = {
            headers: getMandatoryRequestHeaders(undefined),
        }

        // Send a POST request to fetch all reports
        await axios.post(url, undefined, options).then(res => {

            // Extract the data from the response
            const data = res?.data?.data;

            const report_list = data?.all_reports?.filter((f) => f.type === "insights") || [];

            // Retrieve the redirection flag from the session
            const REDIRECT_TO_DB_LIST = getFromSession(constants.SESSION_KEYS.REDIRECT_TO_DB_LIST);
            // console.log("pathname", pathname, search)xzs
            // Check if the redirection flag indicates redirection to the database list

            console.log("BHAI****", search, 'D---', REDIRECT_TO_DB_LIST);

            if (REDIRECT_TO_DB_LIST === "__nf__yes__" || (search === '' && (pathname === '/' || pathname === ''))) {
                // Update the session to avoid repeated redirection
                saveInSession(constants.SESSION_KEYS.REDIRECT_TO_DB_LIST, "just_kill_it_due")

                // Redirect to the appropriate page based on the presence of reports
                if (report_list?.length > 0) {
                    props.history.push("/insightsList?type=insights")
                } else {
                    // Redirect to the default view page if no reports are found
                    props.history.push("/default_view")
                }
            }
        })
    }, [])



    const mainRef = useRef(null);

    if (props.parentWindowToScrollTo > 0 && mainRef.current) {
        mainRef.current.scrollTo({
            top: props.parentWindowToScrollTo,
            left: 0,
            behavior: 'smooth'
        });
    }


    const isMobile = width < 700;



    const is_db_info_exist = (db_id) => {
        let exist = false;
        if (db_infos && db_infos.length > 0 && db_id) {
            db_infos.forEach((d) => {
                if (d.id === db_id) {
                    exist = true;
                }
            })
        }
        return exist;
    }


    let db_id = getFromSession(constants.SESSION_KEYS.DATABASE_ID)


    useEffect(() => {
        if (db_infos && db_infos.length > 0 && !is_db_info_exist(db_id)) {
            saveInSession(constants.SESSION_KEYS.DATABASE_ID, db_infos[0].id)
            props.history.push('/')
            window.location.reload(false);
        }

    }, [db_infos, pathname])



    useEffect(() => {
        if (props?.all_reports?.length > 0 && pathname === "/") {
            // return <>HELLO</>
            // props.history.push('/insightsList?type=insights')
        }
    }, [])




    return (
        <div>
            {
                pathname === '/layout_editor' && getPermissionById(COMPONENT_IDS.SHARED_ACTIONS.EDIT_DASHBOARD, menuPermissions) &&
                <LayoutEditor user={user} />
            }

            {
                pathname === '/client-view' &&
                <ShareableInsights
                    width={width}
                    height={height}
                />

            }

            {
                pathname === '/client-view-report' &&
                <ShareableReport
                    width={width}
                    height={height}
                    text="abcd"
                />
            }

            {
                pathname === '/polus_login' &&
                <PolusLogin />
            }

            {
                pathname === '/polus_ai' && getPermissionById('POLUS_AI', menuPermissions, clientId) &&
                <ChatPolusAi
                    user={user}
                    hints={hints}
                    width={width}
                    height={height}
                    isMobile={isMobile}
                    dispatchHeaderInformation={props.dispatchHeaderInformation}

                />
            }


            {pathname !== "/layout_editor" && pathname !== '/polus_ai' && 

                <React.Fragment>

                    <Header
                        history={history}
                        user={user}
                        hints={hints}
                        pathname={pathname}
                        isMobile={isMobile}
                        width={width}
                        height={height}
                        shadowHide={pathname === '/dashboard'}
                        style={{
                            width: ((pathname === '/config_editor') || (pathname === '/data_source_editor') || (pathname === '/data_cache_editor')) ? '100%' : undefined,
                            padding: ((pathname === '/config_editor') || (pathname === '/data_source_editor') || (pathname === '/data_cache_editor')) ? '0px 1rem' : undefined,
                            boxSizing: ((pathname === '/config_editor') || (pathname === '/data_source_editor') || (pathname === '/data_cache_editor')) ? 'border-box' : undefined,
                        }}
                    />

                    {
                        pathname === '/' &&
                        <Insights
                            user={user}
                            hints={hints}
                            width={width}
                            height={height}
                            isMobile={isMobile}
                            dispatchHeaderInformation={props.dispatchHeaderInformation}
                        />
                    }



                    <MainWrapper
                        ref={mainRef}
                        padding={
                            (
                                pathname === '/config_editor' ||
                                pathname === '/dashboard' ||
                                pathname === '/data_source_editor'
                            )
                                ? !props.toggleHeader ? '0px 15px' : "0px" : undefined
                        }
                        style={{
                            overflow: pathname === '/doctor_ranking' || pathname === '/dashboard' ? 'hidden' : '',
                            backgroundColor: bodyStyle.color.bgColor,
                            marginTop: '60px'
                        }}
                    >
                        {
                            pathname === '/default_view' &&
                            <DefaultPage
                                user={user}
                                hints={hints}
                                width={width}
                                height={height}
                                isMobile={isMobile}
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        }
                        {
                            pathname === '/dashboard' &&
                            <Dashboard
                                user={user}
                                hints={hints}
                                width={width}
                                height={height}
                                history={history}
                                location={location}
                            />
                        }

                        {
                            pathname === '/insightsList' &&
                            <DasboardList
                                user={user}
                                hints={hints}
                                width={width}
                                height={height}
                                history={history}
                                location={location}
                            />
                        }

                        {
                            pathname === '/report_list' &&
                            <ReportList />
                        }
                        {
                            pathname === '/test' &&
                            <TestPage />
                        }

                        {
                            pathname === '/train_polus' &&
                            <TrainYourPolus />
                        }

                        {
                            pathname === '/predictive_analytics' &&
                            <PredictiveAnalytics />
                        }

                        {
                            pathname === '/question_search' &&
                            <QuestionSearch user={user}/>
                        }

                        {
                            pathname === '/form_list' &&
                            <FormList />
                        }
                        {
                            pathname === '/connection' && getPermissionById('DATA_SOURCE', menuPermissions, clientId) &&
                            <DbConnection />
                        }
                        {
                            pathname === '/favourites' &&
                            <Favourites />
                        }
                        {
                            pathname === '/history' &&
                            <History />
                        }
                        {
                            pathname === '/sql_history' &&
                            <SqlHistory />
                        }
                        {
                            pathname === '/security' && getPermissionById(generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'DATA_MASK'), menuPermissions, clientId) &&
                            <Security />
                        }
                        {
                            pathname === '/demo' &&
                            <DemoSample
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        }

                        {
                            pathname === '/demo_view' &&
                            <DemoView
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        }

                        {
                            pathname === '/user_activity' &&
                            <UserActivity
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        }


                        {
                            pathname === '/master_data' &&
                            <MasterData
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        }

                        {
                            pathname === '/users_list' && getPermissionById(generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'USERS'), menuPermissions, clientId) &&
                            <UsersList
                                user={user}
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        }

                        {
                            pathname === '/demo_request' &&
                            <DemoRequest
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        }

                        {
                            pathname === '/etl_jobs' &&
                            <ETL
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        }
                        {
                            pathname === '/data_sync_up' && // TODO
                            <DataSyncUpEditor
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        }
                        {
                            pathname === '/etl_details' &&
                            <ETLDetails
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        }


                        {pathname === '/etl_editor' &&

                            <EtlEditor
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        }
                        {
                            pathname === '/rdbms_ingestion' &&
                            <SqoopConfigration
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        }

                        {
                            pathname === '/rdbms_ingestion_editor' &&
                            <SqoopConfigrationAddEdit
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        }

                        {
                            pathname === '/analytics_map' &&
                            <AnalyticsMap
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        }
                        {
                            pathname === '/multi_display' &&
                            <MultiDisplay
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        }

                        {
                            pathname === '/tbl_edits' &&
                            <TblEditInformation
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        }

                        {
                            pathname === '/campaign' &&
                            <Campaign
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        }
                        {
                            pathname === '/add_markup' &&
                            <AddMarkup
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        }

                        {
                            pathname === '/column_info' &&
                            <NfColumnInfoAdTech
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        }

                        {
                            pathname === '/tbl_errors' &&
                            <TblErrors
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        }
                        {
                            pathname === '/streaming' &&
                            <Streaming
                                dispatchHeaderInformation={props.dispatchHeaderInformation}

                            />
                        }
                        {
                            pathname === '/streaming_editor' &&
                            <StreamingEditor
                                dispatchHeaderInformation={props.dispatchHeaderInformation}

                            />
                        }

                        {pathname === '/db_explorer' && (
                            <DbExplorer
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        )}

                        {pathname === '/module_access' && getPermissionById(generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'USER_ROLES'), menuPermissions) && (
                            <GroupAccess
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        )}

                        {pathname === '/module_add_edit' && getPermissionById(generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'USER_ROLES'), menuPermissions) && (
                            <ModuleAddEdit
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        )}

                        {pathname === '/rules' && (
                            <Rules
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        )}

                        {pathname === '/rules_over_values' && (
                            <RulesOverValues
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        )}


                        {pathname === '/config_editor' && getPermissionById(COMPONENT_IDS.SHARED_ACTIONS.EDIT_REPORT, menuPermissions) && (
                            <ConfigEditor
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                                user={user}
                            />
                        )}


                        {pathname === '/data_source_editor' && (
                            <DataSourceEditor
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        )}


                        {pathname === '/cache_editor' && (
                            <CacheEditor
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        )}


                        {pathname === '/data_hierarchy' && (
                            <DataHierarchy
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        )}


                        {pathname === '/menu_access' && (
                            <MenuAccess
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        )}


                        {pathname === '/menu_add_edit' && (
                            <MenuAddEdit
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        )}

                        {pathname === '/relationship' && (
                            <RelationshipPage
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        )}

                        {pathname === '/human_interpretation' && (
                            <HumanInterpretation
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        )}


                        {pathname === '/rule_alert' && (
                            <RulesAlert
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        )}

                        {pathname === '/rule_alert_tickets' && (
                            <RulesAlertTickets
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        )}


                        {pathname === '/file_upload_config' && (
                            <FileUploadConfig
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        )}


                        {pathname === '/formula_builder' && (
                            <FormulaEditorV1
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        )}


                        {pathname === '/db_formula' && (
                            <DbFormulas
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        )}


                        {pathname === '/actual_plan' && (
                            <ActualPlainMetting
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        )}


                        {pathname === '/file_logs' && (
                            <FileLogs
                                user={user}
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        )}


                        {pathname === '/xls' && (
                            <XLSViwer
                                user={user}
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        )}


                        {pathname === '/data_policy' && (
                            <DataPolicy
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        )}
                        {pathname === '/data_policy_editor' && (
                            <DataPolicyEditor
                                dispatchHeaderInformation={props.dispatchHeaderInformation}
                            />
                        )}

                    </MainWrapper>
                </React.Fragment>
            }
        </div>
    );
};


const mapStateToProps = state => {

    const parentWindowToScrollTo = state.tableReducer.parentWindowToScrollTo;
    const db_infos = state.connectionReducer.db_infos;
    const toggleHeader = state.helperReducer.toggleHeader
    const menuPermissions = state.permissionReducer?.permissions
    const all_reports = state.reportHelperReducer?.all_reports;

    return { parentWindowToScrollTo, db_infos, toggleHeader, menuPermissions, all_reports }
};


export default withRouter(connect(mapStateToProps, { dispatchHeaderInformation })(Home));