/***
 * 
 * 
 * This is our view wrapper component 
 * here we render the component base on the props data  
 * here we have two view 
 *  .1 restricted_view
 *      this view is for insight 
 *      
 *  2. normal view this view is available for all the report 
 * 
 * by saroj kr
 * 19 jan 2021 -
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setDashboardState } from '../../actions/dashboard';
import { mark_mtd_ytd } from '../../actions/filter';
import { set_report_to_refresh, save_report_items } from '../../actions/report';
import SettingSvg from '../svg/setting';
import RightArrow from '../svg/right.arrow';
import { ScrollWrapper } from './styled';
import { getPermissionById, getRemToPx, showPopup, _getDataFromReportingServer, get_pivot_formatted_axis } from '../../utils';
import RippleEffect from '../hoc/ripple-effect';
import ExpandSvg from '../svg/expand'
import ShrinkSvg from '../svg/shrink'
import ActionsButton from './action.buttons'
import styled from 'styled-components';
import FilterSvg from '../svg/filter';
import SmallFilterDisplay from '../filter/filter.display.small'
import { Helmet } from 'react-helmet';
import AddToPingModal from '../favourtite/add.modal';
import { get_favourite_list_by_user_id, save_favourite } from '../../actions/favourite'
import GreaterThenSvg from '../svg/grater.then';
import RestrictedView from './restricted_view';
import NormalView from './normal.view';
import SettingChart from './chart.setting.popup';
import Overlay from '../helper/overlay';
import FilterPreSvg from '../svg/filter.pre'
import FilterPostSvg from '../svg/filter.post'
import FilterElement from '../filter';
import enums from '../../utils/enums';
import FilterTooltip from '../filter/filter.tooltip';
import FormulaSvg from '../svg/formula';
import YtdMtdComponent from './ytd.mtd';
import { check_filter_exist } from '../../utils/v1.1/index'
import { set_table_data_mode } from '../../actions/helper'
import EyeSvg from '../svg/view'
import { getFromSession, saveInSession } from '../../utils/session.helper';
import { constants } from '../../utils/constants';
import RefreshSvg from '../svg/refresh'
import { RIGHTER_MENU_MODE } from '../../shared-with-fe/constants';
import ShareLinkPopup from '../share-link';
import AppliedFilterInfo from './applied.filter.info';
import InfoSvg from '../svg/exclamation'
import SmallBreadCrumb from '../bread-crumb/small.breadCrumb';
import EditSvg from '../svg/editDashboard';
import VoiceSvg from '../svg/speak'
import { COMPONENT_IDS } from '../../utils/elementids';
import HoverButtonGroup from './hover.button.group';
import { FaEdit, FaEye, FaFilter, FaMicrophone } from 'react-icons/fa';
import { GrRefresh } from 'react-icons/gr';
import { BiCollapseAlt, BiEdit, BiExpandAlt } from 'react-icons/bi';

import DeepDiveIcon from '../svg/icons-v2/deepDiveIcon';


const FilterBox = props => {

    const { report_section_height, id } = (props && props.data || 0);

    const { close, popupKey, option } = props;

    return (
        <div style={{ width: '350px', right: '10px', padding: '10px 0px 0px 0px ', zIndex: 10, background: '#fff', boxShadow: ' 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)', borderRadius: '10px', background: '#1e66af' }}>
            <h1 style={{ fontSize: '1rem', margin: '0px', textAlign: 'center', fontWeight: 'bold', color: '#fff', marginBottom: '.7rem' }}>Local Filter</h1>

            <FilterElement
                width={350}
                minHeight={300}
                maxHeight={300}
                filterItemMaxHeight={"10rem"}
                id={id}
                closeFilter={() => {
                    close(option, popupKey)
                    // toggle_open_menu(undefined, true);
                }}
            />
        </div>
    )
};





const IconButton = styled.div`
    font-size: 1rem;
    color: '#4d4d4d';
    cursor: pointer;
    transition: color 0.3s ease;
    width: 1.7rem;
    font-weight: 200;
    height: 1.7rem;
    background: red;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-left: 5px;
    cursor: pointer;
    background-color: #fff;
    margin: 2px;
    &:hover{
        background-color: #e6ebfc;
        color: '#234eb7'
    }
`;



const SmallGButton = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #fff;

    svg{
        fill: #222;
    }

    &:hover{
        background-color: #eaeaea;
    }
`;



const ViewerWrapper = props => {


    /**
     * destructuring the props here 
     */



    const {

        row_data_information,
        pivot_data_information,
        yac_axises
    } = props;

    // const yac_axises = pivot_data_information?.pivot_data_axis?.yac.filter((v) => (v.visible_type !== 0)).map((r) => r.axis || 'primary') || [];

    const {

        report_section_width,
        report_section_height,
        insights,
        xDataKeys,
        yDataKeys,
        question,
        title,
        id,
        childDimension,
        dispatchHeaderInformation,
        scheduleReportId,
        drill_down_data,
        restricted_view,
        pivot_data_ultimate_yacs,
        show_mtd,
        show_ytd,
        is_drill_down_applicable,
        pivot_data,
        raw_data,
        pivot_axis,
        showSingleCell,
        singleCellData

    } = props;




    const show_min = props.show_min;
    const show_max = props.show_max;
    const show_avg = props.show_avg;
    const show_st_dev = props.show_st_dev;
    const show_trend_line = props.show_trend_line;

    /**
     * initilasing the component state here
     */
    const [chartXDataKeys, setChartXDataKeys] = useState([]);
    const [chartYDataKeys, setChartYDataKeys] = useState([]);
    const [tempChartXDataKeys, setTempChartXDataKeys] = useState({});
    const [tempChartYDataKeys, setTempChartYDataKeys] = useState({});
    const [newSortedData, setNewSortedData] = useState([]);
    const [ytd_mtd_option_clicked, set_ytd_mtd_option_clicked] = useState(undefined);
    const [open_pinned_modal, set_open_pinned_modal] = useState(undefined);
    const [pinned_item, set_pinned_item] = useState(undefined);
    const [openSetting, setOpenSetting] = useState(false);
    const [chart_option_selected_value, set_chart_option_selected_value] = useState({});
    const [open_filter, set_open_filter] = useState(undefined);


    useEffect(() => {
        set_ytd_mtd_option_clicked(props.mtd_ytd_options_cache)
    }, [props.mtd_ytd_options_cache])

    const [aggregate_options, set_aggregate_options] = useState({});


    const [open_other_setting, set_open_other_setting] = useState({
        setting: false,
        filters: false,
    });


    const [filter_mouse_enter, set_filter_mouse_enter] = useState(undefined);

    /***
     * this code is used for pinned functions
     */
    useEffect(() => {
        if (!insights) {
            props.get_favourite_list_by_user_id()
        }
    }, [])

    useEffect(() => {
        const favouriteData = props.favouriteData && props.favouriteData.results;;
        let found = false
        if (favouriteData && favouriteData.length > 0) {
            for (let index = 0; index < favouriteData.length; index++) {
                const element = favouriteData[index];
                if ((element.query && element.query.toLocaleLowerCase()) === (question && question.toLocaleLowerCase()) && props.reportId === element.report_id) {
                    found = true;
                    set_pinned_item(element);
                    break;
                }
            }
        }
        if (!found) {
            set_pinned_item(undefined);
        }
    }, [props.favouriteData, props.question])



    const remove_from_pinned = (data) => {
        const clone_data = data ? { ...data } : {};
        clone_data.deleted = true;
        props.save_favourite(clone_data)
        set_pinned_item(undefined);
    }


    useEffect(() => {
        const agg_settings = {
            is_min_checked: props?.report_item?.is_min_checked,
            is_max_checked: props?.report_item?.is_max_checked,
            is_avg_checked: props?.report_item?.is_avg_checked,
            is_st_dev_checked: props?.report_item?.is_st_dev_checked,
            is_trend_line_checked: props?.report_item?.is_trend_line_checked
        }
        if (insights) {
            const agg_settings = {
                is_min_checked: show_min, //props?.report_item?.is_min_checked,
                is_max_checked: show_max,//props?.report_item?.is_max_checked,
                is_avg_checked: show_avg, //props?.report_item?.is_avg_checked,
                is_st_dev_checked: show_st_dev,//props?.report_item?.is_st_dev_checked,
                is_trend_line_checked: show_trend_line, //props?.report_item?.is_trend_line_checked
            }
            if (props.report_item) set_aggregate_options(agg_settings)

        } else {
            if (props.report_item) set_aggregate_options(agg_settings)

        }
    }, [props.report_item, props])


    useEffect(() => {
        // applyKeysDuringUseEffect();
        // eslint-disable-next-line
    }, [id, xDataKeys, yDataKeys]);

    const filters = props.filters_cache && props.filters_cache[id];

    /***
     * here we are dispatching the header 
     * with title and button 
     */

    useEffect(() => {
        let _page_info = {
            title: scheduleReportId ? title ? title : 'Polus - Next Gen BI' : undefined,
            extraComponent: <ActionsButton
                {...props}
                set_open_pinned_modal={set_open_pinned_modal}
                open_pinned_modal={open_pinned_modal}
                edit_report={props.scheduleReportId}
                pinned_item={pinned_item}
                is_details_window={props?.is_details_window}
            />
        }
        if (dispatchHeaderInformation) {
            dispatchHeaderInformation(_page_info)
        }
    }, [title, id, open_pinned_modal, pinned_item, props.sqlQuery, props.responseId])


    const applyKeysDuringUseEffect = () => {

        const { dashboardDataCache } = props;

        if (props.dashboardDataCache && props.dashboardDataCache.length > 0 && !insights) {

            const reportId = id;
            let proceed = true

            dashboardDataCache.forEach(data => {
                const keyWhichIsId = Object.keys(data)[0];
                if (reportId === keyWhichIsId) {
                    proceed = false;
                    const tempData = data[keyWhichIsId];
                    setChartXDataKeys(tempData.chartXDataKeys);
                    setChartYDataKeys(tempData.chartYDataKeys);
                    setTempChartXDataKeys(tempData.tempChartXDataKeys);
                    setTempChartYDataKeys(tempData.tempChartYDataKeys);
                    setNewSortedData(tempData.newSortedData);
                }
            })

            if (proceed) {
                setChartXDataKeys([])
                setChartYDataKeys([])
                setTempChartXDataKeys({})
                setTempChartYDataKeys({})
                setNewSortedData([])
            }
        }
        else {
            setChartXDataKeys([])
            setChartYDataKeys([])
            setTempChartXDataKeys({})
            setTempChartYDataKeys({})
            setNewSortedData([])
        }
    };


    const toggle_aggregate_option = async (key) => {

        const clone_aggregate_option = aggregate_options ? { ...aggregate_options } : {};
        if (!clone_aggregate_option[key]) clone_aggregate_option[key] = true;
        else clone_aggregate_option[key] = !aggregate_options[key];

        const temp_r = {
            id: props.report_item.id,
            // ...props.report_item,
            ...clone_aggregate_option,
            modified: true
        }
        if (insights) props.save_report_items([temp_r])
        set_aggregate_options(clone_aggregate_option)

    }


    const change_ytd_mtd = (clicked_what) => {
        if (clicked_what === 'ytd') {
            set_ytd_mtd_option_clicked('ytd');
        }
        else if (clicked_what === 'mtd') {
            set_ytd_mtd_option_clicked('mtd');
        }
        else {
            set_ytd_mtd_option_clicked(undefined);
        }
        // we dont need question for mtd and ytd for dashboards
        props.mark_mtd_ytd(id, clicked_what);

        setTimeout(() => {
            props.set_report_to_refresh(id);
        }, 200);
    };




    const is_report_have_applied_filter = check_filter_exist(filters)

    const toggle_open_menu = (key, close_all) => {
        const clone_open_other_setting = open_other_setting ? { ...open_other_setting } : {};
        clone_open_other_setting && Object.keys(open_other_setting).length > 0 && Object.keys(open_other_setting).map((k) => {
            if (close_all) clone_open_other_setting[k] = false;
            if (!close_all && k === key) clone_open_other_setting[k] = !clone_open_other_setting[k]
            else clone_open_other_setting[k] = false;

        })

        set_open_other_setting(clone_open_other_setting)
    }

    const any_other_setting_open = Object.keys(open_other_setting).length > 0 && Object.keys(open_other_setting).filter((k) => {
        if (open_other_setting[k]) return true
        else return false
    }) || [];


    const menu_cache = props.report_menu_cache && props.report_menu_cache[id]
    const menu_render_mode = menu_cache?.menu_render_mode;


    const option_window_width = menu_render_mode === RIGHTER_MENU_MODE.COLUMN_CHOOSER ? '480px' : "320px";
    const right_margin = props.expanded ? (menu_render_mode ? option_window_width : '4rem') : '4rem';
    const __header_overlay_restricted__ = { position: 'absolute', top: "0px", left: '0px', right: '0px', width: '100%', }
    const _header_overlay_unrestricted_view = { position: 'absolute', right: right_margin, width: '50%', top: props.reportTitle ? '0px' : undefined, }
    const __header__overlay__style = restricted_view ? __header_overlay_restricted__ : _header_overlay_unrestricted_view;

    const user_id = props?.user?.id || props?.user?.user_id;

    const show_eye_db = getPermissionById("view_sql_button", props.menuPermissions);
    const show_eye = props?.user?.email && props?.user?.email?.indexOf("newfangled.io") > -1 || show_eye_db;
    const show_r_btn = getPermissionById("refresh_widget_data", props.menuPermissions);
    const edit_widget = getPermissionById(COMPONENT_IDS.SHARED_ACTIONS.EDIT_DASHBOARD, props.menuPermissions);
    const show_refresh_btn = (user_id === 'nf_kc2yq6inkc2yqpn2' || show_r_btn) //&& props.is_cache_required;
    const show_edit_btn = (user_id === 'nf_kc2yq6inkc2yqpn2' || edit_widget);



    const extraStyle = !props?.isPolusAIWindow ? {
        background: props.expanded ? 'transparent' : '#fff',
        position: props.expanded ? '' : 'absolute',
        top: props.expanded ? '' : '-15px',
        right: props.expanded ? '' : '10px',
        border: props.expanded ? '' : '1px solid #666666',
        borderRadius: props.expanded ? '' : '10px',
    } : undefined


    return (
        <ScrollWrapper insights={insights} dimensions={childDimension} id='report_inner_wrapper'>

            {!insights && !restricted_view && (
                <Helmet>
                    <title>{title ? title : 'Polus - Next Gen BI'}</title>
                    <meta name="description" content="App Description" />
                    <meta name="theme-color" content="#008f68" />
                </Helmet>
            )}

            {
                (any_other_setting_open && any_other_setting_open.length > 0) &&
                <Overlay onClick={() => {
                    toggle_open_menu(undefined, true);
                }} />
            }

            {props.toggleHeader && !restricted_view && (
                <SmallGButton
                    title="Show Menu"
                    style={{ position: 'absolute', top: '0px', zIndex: 11, right: '10px' }}
                    onClick={(e) => {
                        props.toogleHeader()
                    }}>
                    <GreaterThenSvg size="1rem" height="1rem" style={{ transform: 'rotate(90deg)' }} />
                </SmallGButton>
            )}

            {!restricted_view && (insights || props.isPolusAIWindow) && (props.reportTitle || "test") && (
                <div style={{
                    width: '100%',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#222',
                    fontSize: '1.1rem',
                    textTransform: 'uppercase',
                    overflow: 'hidden',
                }}>{props.reportTitle}</div>
            )}


            {open_pinned_modal && !restricted_view && (
                <AddToPingModal
                    {...props}
                    pinned_item={pinned_item}
                    set_open_pinned_modal={set_open_pinned_modal}
                    remove_from_pinned={remove_from_pinned}
                />
            )}




            <div className="header_div_of_view_wrapper"
                style={{
                    ...__header__overlay__style,
                    height: '0px',
                    zIndex: 2
                }}>
                <div style={{ width: '100%', minHeight: '30px', maxHeight: '50px', alignItems: 'flex-start', position: 'relative', display: 'flex', justifyContent: 'space-between', backgroundColor: 'transparent', top: !insights ? props?.isPolusAIWindow ? '0px' : "-50px" : "0px", alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '70%', }}>
                        {insights && restricted_view &&
                            <div style={{ marginTop: '3px', width: '100%', }}>
                                <SmallBreadCrumb id={id} />
                            </div>
                        }
                    </div>


                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center', marginLeft: 'auto', alignItems: 'center', width: 'auto',
                        paddingRight: props.expanded ? '15px' : '0.4rem',
                        ...extraStyle
                    }}>
                        {/* {insights &&
                            <YtdMtdComponent
                                show_mtd={show_mtd}
                                show_ytd={show_ytd}
                                ytd_mtd_option_clicked={ytd_mtd_option_clicked}
                                change_ytd_mtd={change_ytd_mtd}
                                toggle_aggregate_option={toggle_aggregate_option}
                                aggregate_options={aggregate_options}
                                show_aggregate_option={props.renderMode === 'chart'}
                                show_min={false}
                                show_max={false}
                                show_avg={false}
                                show_st_dev={false}
                                show_trend_line={false}
                            />
                        } */}

                        {show_edit_btn && !props.disable_options?.voice_action && (
                            <IconButton
                                title="Voice Action"
                                onClick={() => {
                                    props.change_layout_using_voice()
                                }}>
                                <FaMicrophone />
                            </IconButton>
                        )}

                        {show_edit_btn && !props.disable_options?.edit_widget && (
                            <IconButton
                                title="Edit Widget"
                                onClick={() => {
                                    props.open_layout_editor()
                                }}>
                                <BiEdit />
                            </IconButton>
                        )}

                        {show_refresh_btn && !props.disable_options?.refresh && (
                            <IconButton
                                title="Refresh"
                                onClick={() => {
                                    props.set_report_to_refresh(id, true);
                                }}>
                                <GrRefresh />
                            </IconButton>
                        )}


                        {false && !props.disable_options?.filter_info && (
                            <RippleEffect
                                icon={<InfoSvg size="1rem" height="1rem" color="#000" />}
                                title="Filter Info"
                                width="30"
                                Click={(e, data) => {
                                    showPopup("Filter Info", undefined, enums.popupType.element_with_header,
                                        AppliedFilterInfo,
                                        {
                                            filter_not_applied: props.filter_not_applied,
                                            filter_applied: props.filter_applied
                                        }
                                    )
                                }}
                            />
                        )}


                        {/* !props.is_global_filters && insights && restricted_view && (!drill_down_data || (drill_down_data && drill_down_data.length === 0)) && !showSingleCell  */}
                        {true && !props.disable_options?.filter && (
                            <div
                                style={{ position: 'relative' }}
                                onMouseEnter={() => set_filter_mouse_enter(true)}
                                onMouseLeave={() => set_filter_mouse_enter(undefined)}
                                className={"filter_div"}>
                                {/* <RippleEffect
                                    icon={is_report_have_applied_filter ? <FilterPostSvg size="1rem" height="1rem" color="#308bc8" /> : <FilterPreSvg size="1rem" height="1rem" color="#222" />}
                                    title="Filter"
                                    width="30"
                                    Click={(e, data) => {
                                        const popup_width = 350;
                                        const win_height = window.innerHeight;
                                        const el_pos = data.clientY + 350;
                                        const popup_top_margin = win_height - el_pos < 100 ? 100 : 10;
                                        const popup_top = data.clientY - popup_top_margin + "px";
                                        const popup_left = (data.clientX - popup_width);
                                        const popup_left_v1 = (popup_left <= 0 ? 0 : popup_left) + "px"
                                        showPopup(undefined, undefined, enums.popupType.element,
                                            FilterBox,
                                            { report_section_height, id },
                                            popup_top,
                                            popup_left_v1
                                        )
                                    }}
                                /> */}

                                <IconButton
                                    title="Filter"
                                    onClick={(event) => {
                                        const clientX = event.clientX;
                                        const clientY = event.clientY
                                        const popup_width = 350;
                                        const win_height = window.innerHeight;
                                        const el_pos = clientY + 350;
                                        const popup_top_margin = win_height - el_pos < 100 ? 100 : 10;
                                        const popup_top = clientY - popup_top_margin + "px";
                                        const popup_left = (clientX - popup_width);
                                        const popup_left_v1 = (popup_left <= 0 ? 0 : popup_left) + "px"
                                        showPopup(undefined, undefined, enums.popupType.element,
                                            FilterBox,
                                            { report_section_height, id },
                                            popup_top,
                                            popup_left_v1
                                        )
                                    }}>
                                    <FaFilter />
                                </IconButton>


                                {filter_mouse_enter && is_report_have_applied_filter && (
                                    <div className="filter_area" style={{ background: '#fff', position: 'absolute', right: '10px', top: '100%', border: '1px solid #ccc', zIndex: 2, borderRadius: '3px', padding: '5px' }}>
                                        <FilterTooltip id={id} report_id={id} width={'300px'} multi_line={true} />
                                    </div>
                                )}
                            </div>
                        )}




                        {/* {props?.isPolusAIWindow && (
                            <IconButton
                                title="Deep Dive"
                                onClick={() => {
                                    props?.deepDiveClick()
                                }}>
                                <DeepDiveIcon size={'1.3rem'} />
                            </IconButton>
                        )} */}


                        {props.expandChart && (insights || props.isPolusAIWindow) && !props.hide_table_header && (
                            <React.Fragment>

                                {show_eye && !props.isPolusAIWindow && (
                                    <IconButton
                                        title="View SQL"
                                        onClick={() => {
                                            showPopup('SQL Query', props.sqlQuery, enums.popupType.sqlQuery, undefined, undefined)
                                        }}>
                                        <FaEye />
                                    </IconButton>
                                )}
                                {/* AiOutlineShrink */}

                                {props.expanded ?

                                    <IconButton
                                        title="Shrink"
                                        onClick={() => {
                                            props.expandChart(id)
                                        }}>
                                        <BiCollapseAlt />
                                    </IconButton>
                                    :
                                    <React.Fragment>
                                        <IconButton
                                            className={ props.isPolusAIWindow && "show"}
                                            title="Expand"
                                            onClick={() => {
                                                props.expandChart(id)
                                            }}>
                                            <BiExpandAlt />
                                        </IconButton>
                                    </React.Fragment>}
                            </React.Fragment>
                        )}




                    </div>
                </div>
            </div>

            {restricted_view ? (
                <RestrictedView
                    {...props}
                    aggregate_options={aggregate_options}
                    height={report_section_height}
                    width={report_section_width}
                    restricted_view={restricted_view}
                    pivot_data_ultimate_yacs={pivot_data_ultimate_yacs}
                    columnsAligments={props.columnsAligments}
                    row_data_information={row_data_information}
                    pivot_data_information={pivot_data_information}
                    yac_axises={yac_axises}
                    singleCellData={singleCellData}
                    showSingleCell={showSingleCell}
                    pivot_axis={pivot_axis}
                    raw_axis={props.raw_axis}
                    pivot_column_aligments={props.pivot_column_aligments}
                    raw_column_aligments={props.raw_column_aligments}

                />
            ) : <NormalView
                {...props}
                aggregate_options={aggregate_options}
                report_section_width={report_section_width}
                report_section_height={report_section_height}
                restricted_view={restricted_view}
                pivot_data_ultimate_yacs={pivot_data_ultimate_yacs}
                toggle_aggregate_option={toggle_aggregate_option}
                row_data_information={row_data_information}
                pivot_data_information={pivot_data_information}
                yac_axises={yac_axises}
                singleCellData={singleCellData}
                showSingleCell={showSingleCell}
                pivot_axis={pivot_axis}
                raw_axis={props.raw_axis}
                pivot_column_aligments={props.pivot_column_aligments}
                raw_column_aligments={props.raw_column_aligments}
                show_data_label={props.show_data_label}

            />
            }

        </ScrollWrapper >
    )
};





const mapStateToProps = (state, props) => {

    const expanded_section_cache = state.helperReducer.expanded_section_cache;
    const expandedSection = expanded_section_cache && expanded_section_cache[props.id];
    const mtd_ytd_options_cache = state.filterReducer.mtd_ytd_options_cache?.[props.id]
    const { dashboardData, dashboardDataCache } = state.dashboardReducer;
    const toggleHeader = state.helperReducer.toggleHeader;
    const { type, hide_popus_iq } = state.NewTableReducer
    const { filters_cache, dropdowns_cache } = state.filterReducer;
    const { favouriteData } = state.favouriteReducer;
    const menuPermissions = state.permissionReducer?.permissions

    const report_menu_cache = state.menuConfigRighterReducer.report_menu_cache;

    return { menuPermissions, report_menu_cache, mtd_ytd_options_cache, expandedSection, dropdowns_cache, dashboardData, dashboardDataCache, type, hide_popus_iq, filters_cache, favouriteData, toggleHeader }
};



export default connect(mapStateToProps, { save_report_items, set_table_data_mode, save_favourite, get_favourite_list_by_user_id, setDashboardState, set_report_to_refresh, mark_mtd_ytd })(ViewerWrapper);
