import React from 'react';
import { connect } from 'react-redux';
import { check_is_condition_matched } from '../../../utils/v1.1';
import { TableHeaderRow } from '../table.styled';
import Cell from '../cell';
import { TABLE_CONFIG } from '../../../shared-with-fe/keys';
import { beautify_cell_data_v1 } from './helper';
import { DEPARTMENTS } from '../../../utils/department'
import { withRouter } from 'react-router-dom';

let rendered_for = 0;




const Row = (props) => {


    /**
     * this is the props section
     * 
     */

    const {
        pivot_yac_info,
        rowData,
        rowIndex,
        totalTableHeaders,
        virtualization,
        xDataKeys,
        yDataKeys,
        showCell,
        insights,
        tableStyle,
        apply_local_cell_clicked_filter,
        is_this_cell_clicked,
        columnMeta,
        tableData,
        tableDataMode,
        columnsAligment,
        totalofYData,
        editColumns,
        sorted_columns,
        columnsMaxWidth,
        width_additions,
        column_prev_value,
        column_row_wise_data_types,
        __rowSpan,
        __rowSpanKey

    } = props;




    /** this is the component state section */



    // rendered_for++;

    // console.log(' row compo is rendered ===> ', rendered_for);




    let style_non_repeated_column = {
        "border": {
            "bottom": null,
            "top": {
                ...(tableStyle?.body?.column?.border?.top || {}),
                "color": tableStyle?.body?.column?.highlgt_col_color?.border_color || tableStyle?.body?.column?.border?.top?.color
            },
            "right": {
                ...(tableStyle?.body?.column?.border?.right || {}),
                "color": tableStyle?.body?.column?.highlgt_col_color?.border_color
            },
            "left": {
                ...(tableStyle?.body?.column?.border?.left || {}),
                "color": tableStyle?.body?.column?.highlgt_col_color?.border_color
            }
        },
    };


    let style_repeated_column = {
        "border": {
            "bottom": null,
            "top": {
                ...(tableStyle?.body?.column?.border?.top || {}),
                "width": 0,
                "color": tableStyle?.body?.column?.border?.top?.color || "#e2e2e2"
            },
            "right": {
                ...(tableStyle?.body?.column?.border?.right || {}),
                "color": tableStyle?.body?.column?.highlgt_col_color?.border_color || "#e2e2e2"
            },
            "left": {
                ...(tableStyle?.body?.column?.border?.left || {}),
                "color": tableStyle?.body?.column?.highlgt_col_color?.border_color || "#e2e2e2"
            }
        },
    };


    /**
     * 
     * @param {*} value_key 
     * @param {*} row_data 
     * @returns 
     */
    const get_column_shade_info = (value_key, row_data) => {
        let shade_info = undefined;
        pivot_yac_info?.length > 0 && pivot_yac_info.find((c) => {
            const d_field = c.pivot_field_alias || c.pivot_field_column_name;
            const v_key_splited = (value_key && value_key.indexOf("_nfx_") > -1) ? value_key.split("_nfx_")[1] : value_key;
            const p_key = value_key + "_nfpercentage";
            if (d_field && d_field.toLocaleLowerCase() === v_key_splited.toLocaleLowerCase() && c.is_shade) {
                const p_value = (value_key && value_key.indexOf('% Share_nfx_') > -1) ? (row_data && row_data[value_key]) : (row_data && row_data[p_key] || 0)
                shade_info = {
                    percentage: p_value || 0
                }
                return true;
            }
            else return false;
        });
        return shade_info
    }




    const condition_formating_data = beautify_cell_data_v1(props.tbl_formatter, sorted_columns, rowData, rowIndex);
    const beautify_data_beging = (condition_formating_data && Object.keys(condition_formating_data) && Object.keys(condition_formating_data).length > 0) ? Object.keys(condition_formating_data)[0] : undefined;
    const is_row_level = beautify_data_beging && condition_formating_data && condition_formating_data[beautify_data_beging].row_level;
    const row_level_style = (beautify_data_beging && condition_formating_data && condition_formating_data[beautify_data_beging].row_level) ? condition_formating_data[beautify_data_beging].data : undefined;


    const rowStyle = (tableStyle && tableStyle.body && tableStyle.body.row);
    const evenRowStyle = (rowStyle && rowStyle.even && rowStyle.even.colors);
    const oddRowStyle = (rowStyle && rowStyle.odd && rowStyle.odd.colors);

    const background_color = rowIndex % 2 == 0 ? (oddRowStyle && oddRowStyle.background) : (evenRowStyle && evenRowStyle.background);
    const hover_background_color = rowIndex % 2 == 0 ? (oddRowStyle && oddRowStyle.hover_background) : (evenRowStyle && evenRowStyle.hover_background);


    // if (rowIndex === 1) {
    //     console.log("rowData", rowData)
    // }
    // console.log("need_to_use_virtualization", props.need_to_use_virtualization)


    return (
        <TableHeaderRow
            hoverDisable={condition_formating_data}
            background={background_color}
            hoverBackgroundColor={hover_background_color}
            key={'k-' + rowIndex}
            // title={hover_background_color}
            bgolor='#87AFC6'
        >
            {
                totalTableHeaders && totalTableHeaders.length > 0 && totalTableHeaders.map((column, columnIndex) => {

                    // if (!props?.need_to_use_virtualization) {

                    if (columnIndex < virtualization.lower) {

                        if (columnIndex === 0) {
                            return <td colSpan={virtualization.lower}></td>
                        }

                        return null;
                    }

                    if (virtualization.upper > 0 && (columnIndex) > virtualization.upper) {
                        if (columnIndex === virtualization.upper) {
                            const span_required = totalTableHeaders.length - virtualization.upper;
                            return <td colSpan={span_required}></td>
                        }

                        return null;
                    }
                    // }


                    const __value__key = column.value_key;
                    const beautify_data = condition_formating_data && condition_formating_data[__value__key];

                    const is_department_value = DEPARTMENTS?.[rowData?.[__value__key]];

                    if (__value__key && editColumns?.[__value__key] && editColumns?.[__value__key]?.show) {
                        if (yDataKeys && xDataKeys && (yDataKeys?.includes(__value__key) || columnIndex > xDataKeys.length - 1)) {
                            if (typeof totalofYData[__value__key] === 'undefined') {
                                totalofYData[__value__key] = (rowData[__value__key] && !rowData[TABLE_CONFIG.group_row_identifier]) ? parseFloat(rowData[__value__key]) : 0;
                            }
                            else if (rowData[__value__key] && !rowData[TABLE_CONFIG.group_row_identifier]) {
                                totalofYData[__value__key] = parseFloat(totalofYData[__value__key]) + parseFloat(rowData[__value__key]);
                            }
                        }

                        let whichWidth = columnsMaxWidth && (columnsMaxWidth[__value__key] - 22);
                        whichWidth = whichWidth + (width_additions[__value__key] || 0)

                        const aligment_of_column = column_row_wise_data_types?.[__value__key]?.[rowIndex] || ((__value__key && columnsAligment?.[__value__key]) || undefined);

                        let data = rowData[__value__key];
                        let is_repeater = true;

                        const cell_shade_info = get_column_shade_info(__value__key, rowData);

                        const no_need_to_use_repeter = (xDataKeys[columnIndex + 1])

                        const prev_data = tableData?.[rowIndex - 1]

                        if (no_need_to_use_repeter) {
                            if (column_prev_value[xDataKeys[columnIndex]] !== data) {
                                is_repeater = false;
                                column_prev_value[xDataKeys[columnIndex]] = data
                            }
                            else if (rowData?.[xDataKeys[columnIndex - 1]] !== prev_data?.[xDataKeys[columnIndex - 1]] && rowData?.[xDataKeys[columnIndex]] === prev_data?.[xDataKeys[columnIndex]]) {
                                is_repeater = false;
                                column_prev_value[xDataKeys[columnIndex]] = data
                            }
                            else {
                                data = ''
                            }
                        }

                        const repeater_style = (!is_repeater || !no_need_to_use_repeter) ? style_non_repeated_column : style_repeated_column

                        const repeated_border_theme_style = {
                            __column__style: {
                                ...tableStyle.body.column,
                                ...repeater_style
                            }
                        };

                        const filter_key = columnMeta?.[column?.display_key]?.db_column || undefined;

                        const column_db_data_type = columnMeta?.[column?.display_key]?.db_data_type || 5;

                        const value_to_use_in_filter = rowData[__value__key];
                        const is_this_cell_in_filter = is_this_cell_clicked(column?.display_key, filter_key, value_to_use_in_filter, rowIndex)

                        const is_trend = pivot_yac_info?.length > 0 && pivot_yac_info?.find(yinfo => {
                            const value_key_to_find_trend = (__value__key && __value__key.indexOf("_nfx_") > -1) ? __value__key.split("_nfx_")[1] : __value__key;
                            const d_key = yinfo.pivot_field_alias || yinfo.pivot_field_column_name;
                            if (yinfo.is_trend && value_key_to_find_trend?.toLocaleLowerCase() === d_key?.toLocaleLowerCase()) return true;
                        })


                        if (__value__key === __rowSpanKey && !rowData["rowSpan"]) return null;


// console.log('tableStyle',tableStyle);

                        return (
                            <Cell
                                title={filter_key}
                                rowSpan={(__value__key === __rowSpanKey) && rowData["rowSpan"] > 0 ? rowData['rowSpan'] : undefined}
                                _style={xDataKeys[columnIndex + 1] && is_repeater ? repeated_border_theme_style?.__column__style : {...tableStyle?.body?.column, border:{...tableStyle?.body?.column?.border, bottom: null, top:tableStyle?.body?.column?.border?.bottom } }}
                                key={__value__key || "test"}
                                fourcePaddingLeft={(!insights && columnIndex === 0) ? "2rem" : undefined}
                                aligment_of_column={aligment_of_column}
                                cellValue={data !== 'null' ? data : ''}
                                wordWidth={whichWidth}
                                insights={insights}
                                isTextBold={false}
                                value_key={__value__key}
                                showTrend={(props.tableDataMode === 'pivot_data') ? is_trend : undefined}
                                background={is_this_cell_in_filter ? "#ff00008c" : !data ? '#fff' : (no_need_to_use_repeter) ? tableStyle?.body?.column?.highlgt_col_color?.background : ""}
                                on_filter_click={(event) => {

                                    const fact_key = yDataKeys[0];
                                    console.log("fact key: " + fact_key, __value__key)
                                    if (is_department_value) {
                                        const prediction_url = `/predictive_analytics?spec=${is_department_value}&spec_name=${rowData?.[__value__key]}`
                                        window.open(prediction_url, '_blank')
                                    }
                                    else {
                                        if (column_db_data_type && filter_key && filter_key.length > 0 && xDataKeys.indexOf(__value__key) > -1 && insights && filter_key?.indexOf(".") > -1) {
                                            props.open_chart_options(event, fact_key, rowData, __value__key)
                                            // apply_local_cell_clicked_filter(event, filter_key, column_db_data_type, value_to_use_in_filter, __value__key)
                                        }
                                    }
                                }}
                                cell_shade_info={cell_shade_info}
                                beautify_data={(beautify_data && beautify_data.data) ? beautify_data.data : is_row_level ? row_level_style : undefined}
                            />
                        )
                    }
                })
            }

            {
                // showCell && !insights &&
                showCell &&
                <Cell
                    _style={tableStyle && tableStyle.body && tableStyle.body.column}
                    key={'extra_column'}
                    id="extra"
                    style={{ width: '100%' }}
                    hideSpan={true}
                />
            }

        </TableHeaderRow>
    )
}



const mapStateToProps = (state, props) => {

    const id = props.id;
    const table_format_setting_cache = state.tableFormatReducer.table_format_setting_cache;
    const tbl_formatter = table_format_setting_cache && table_format_setting_cache[id];

    return { tbl_formatter };

}



export default withRouter(connect(mapStateToProps, {})(Row))

