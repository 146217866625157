import * as actions from '../action-types/chart.helper';

const initialState = {
    type: actions.CHART_HELPER_INIT,
    recentColors: undefined,
};


const chartHelperReducer = (state, action) => {
	if (!state || typeof action.type === 'undefined') {
		return initialState;
    }

    switch (action.type) {
        case actions.RECENT_COLOR_SUCCESS:
			return {
                ...state,
                type: action.type,
                recentColors: action.data
            };

        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
			return {
                type: state.type,
                recentColors: state.recentColors
            };
        }
};

export default chartHelperReducer;