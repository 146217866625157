function parseDataString(data) {
    data = data.trim();

    const conditionOperators = {
        '=': 'equal',
        '>': 'greater_than',
        '<': 'less_than',
        '!=': 'not_equal',
        'containsAny': 'containsAny',
        'in': 'in',
        'not in': 'not in'
    };

    // Identify the condition operator in the string
    let operator;
    for (const [key, name] of Object.entries(conditionOperators)) {
        if (data.includes(key)) {
            operator = key;
            break;
        }
    }

    if (!operator) {
        throw new Error('No valid condition operator found in input string');
    }

    // Extract the condition part and the else part
    const [conditionPart, elsePart] = data.split(' ? ');
    if (!elsePart) {
        throw new Error('Else part not found in input string');
    }

    // Split the condition part based on the operator
    const [keyPart, remainder] = conditionPart.split(operator);
    if (!keyPart || !remainder) {
        throw new Error('Invalid format for the condition part');
    }

    // Clean up the key part by removing the leading '[' and trailing ']' characters
    const key = keyPart.substring(1).trim().replace(/\]$/, '').trim();
    const rest = remainder.trim();

    // Extract the values part
    const valuesStartIndex = rest.indexOf('[') + 1;
    const valuesEndIndex = rest.indexOf(']');
    if (valuesStartIndex === 0 || valuesEndIndex === -1) {
        throw new Error('Values section not found in input string');
    }

    const valuesString = rest.substring(valuesStartIndex, valuesEndIndex).trim();
    const values = valuesString.split(/\s*,\s*/).map(v => v.trim().replace(/^'|'$/g, ''));

    // Extract the result and else parts
    const resultPart = elsePart.split(' : ');
    if (resultPart.length !== 2) {
        throw new Error('Invalid format for the else part');
    }
    
    const result = resultPart[0].trim().replace(/^'|'$/g, '');
    const elseResult = resultPart[1].trim().replace(/^'|'$/g, '');

    // Construct the structured data object
    return {
        column: { value: key, type: 'column' },
        condition: { value: values, type: 'condition', condition_name: conditionOperators[operator] },
        result: { value: result, type: 'result' },
        else: { value: elseResult, type: 'result' }
    };
}

/**
 * Evaluates the formula result based on the provided parsed data and column data.
 * @param {Object} parsedData - The parsed data object from `parseDataString`.
 * @param {Object} columnData - An object containing column values as arrays or single values.
 * @returns {string} - The result based on the condition evaluation.
 */
function calculateFormulaResult(parsedData, columnData) {
    const { column, condition, result, else: elseResult } = parsedData;

    // Get the actual values for the column from columnData
    let columnValues = columnData[column.value];

    // console.log("columnValues", parsedData, columnData)
    if (!Array.isArray(columnValues)) {
        // Convert single value to array
        columnValues = [columnValues];
    }

    // Helper function to evaluate 'containsAny' condition
    const evaluateContainsAny = (values, list) => list.some(value => values.includes(value));

    // Condition evaluation based on the condition type
    let conditionMet = false;
    switch (condition.condition_name) {
        case 'equal':
            conditionMet = columnValues.includes(condition.value[0]);
            break;
        case 'greater_than':
            conditionMet = columnValues.some(val => val > condition.value[0]);
            break;
        case 'less_than':
            conditionMet = columnValues.some(val => val < condition.value[0]);
            break;
        case 'not_equal':
            conditionMet = columnValues.some(val => val !== condition.value[0]);
            break;
        case 'containsAny':
            conditionMet = evaluateContainsAny(columnValues, condition.value);
            break;
        case 'in':
            conditionMet = columnValues.some(val => condition.value.includes(val));
            break;
        case 'not in':
            conditionMet = columnValues.every(val => !condition.value.includes(val));
            break;
        default:
            throw new Error(`Unsupported condition type: ${condition.condition_name}`);
    }

    // Return result based on the condition evaluation
    return conditionMet ? result.value : elseResult.value;
}

/**
 * Replaces placeholders in the template with evaluated results.
 * @param {string} template - The template string with placeholders.
 * @param {Object} filter - An object containing column values.
 * @returns {string} - The template with placeholders replaced by evaluated results.
 */
export function replaceValueInTitle(template, filter={}) {
    const conditionRegex = /\{([^}]*)\}/g; // 'g' for global search

    let foundCondition;
    const conditions = [];

    // Extract all conditions from the template
    while ((foundCondition = conditionRegex.exec(template)) !== null) {
        conditions.push(foundCondition[1]);
    }

    let finalResult = template;


    // console.log("conditions", conditions, filter)
    // Replace each condition in the template with the evaluated result
    
    conditions.forEach(c => {
        const parsedData = parseDataString(c);
        const result = calculateFormulaResult(parsedData, filter);
        finalResult = finalResult.replace(`{${c}}`, result);
    });

    return finalResult;
}