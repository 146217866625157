import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import Edit from '../svg/edit'
import Delete from '../svg/delete'
import { DivRow } from '../styled-hoc';
import { Button } from '../hoc/button';
import { Gap } from '../gap'
import { connect } from 'react-redux'
import { get_Interpretation, save_Interpretation } from '../../actions/interpretation'
import TextBox from '../hoc/textBox'

export const Wrapper = styled.div`
    position: relative;
    width: 40rem;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 6px 0px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(234, 234, 234);
    border-image: initial;
    padding: 1.643rem 1.429rem;
    overflow-y: auto;
    max-height: 80vh;
    table{
        width: 100%;
        border: 0px;
        border-collapse: collapse;
        background-color: #fff;
    }

   td,th{
      padding: 5px;
      height: 2.1rem;
      font-size: 1rem;
   }
   
   td{
      border-bottom: 1px solid #eaeaea;
      span{
        max-width: 40rem;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
   }

    thead {
       /* background: rgb(36, 168, 214); */
       color: #fff;
       text-align: left;
       /* font-family: inherit; */
       /* background-color: cadetblue; */
   }
   th{
        position: sticky;
        top: 0px;
        z-index: 1;
        background: rgb(36,168,214);

        &:nth-last-child(1){
            text-align: center;
        }
   }

   tbody tr:hover td{
      background-color: rgb(204, 221, 227);
   }

    &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }
    .title{
        font-size: 1rem;
        margin-bottom: 5px;
    }


    .input_row{
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-bottom: 10px;

        label{
            margin-bottom: 5px;
        }
        input{
            height: 30px;
            padding: 10px;
            box-sizing: border-box;
        }
    }
`;

export const TitleWrapper = styled.div`
    font-size: 1.286rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.714rem;
    letter-spacing: normal;
    text-align: center;
    color: rgb(26, 26, 26);
    display: block;
    margin: 0px;
    margin-bottom: 15px;
`;



const EditPopup = (props) => {
    const { close, popupKey, option, data} = props;
    const { item } = data;

    const [localItem, setItem] = useState({
        keyword: '',
        polus_interpretation: ''

    });

    const [innerEditRow, setInnerData] = useState(undefined)

    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        if (item) {
            setItem(item)
            if (item.business_nouns && item.business_nouns.length > 0) {
                setInnerData(item.business_nouns)
            }
        }
    }, [item])


    const _onTextchange = (value, name, inner, _index) => {

        if (!inner) {
            setItem({ ...localItem, [name]: value })
        }

        if (inner) {
            let tempData = innerEditRow ? [...innerEditRow] : inner;
            tempData[_index][name] = value
            setInnerData(tempData)
        }
    }


    const _save_data = () => {

        let temp_outer_row = localItem ? { ...localItem } : [];
        let temp_inner_row = innerEditRow ? [...innerEditRow] : [];
        let final_filterd_inner_array = temp_inner_row && temp_inner_row.length > 0 && temp_inner_row.filter((row, index) => {
            if (row.keyword && row.keyword.length > 1) {
                return row
            }
        })
        final_filterd_inner_array && final_filterd_inner_array.length > 0 && final_filterd_inner_array.map((row, index) => {
            row["modified"] = true;
        })

        if (temp_outer_row) {
            temp_outer_row['business_nouns'] = final_filterd_inner_array ? final_filterd_inner_array : undefined;
            temp_outer_row["modified"] = true;
            temp_outer_row["is_active"] = true;
        }



        props.save_Interpretation(temp_outer_row)
        setTimeout(() => {
            props.get_Interpretation()
            close(option, popupKey);
        }, 100)

    }


    const _add_more = () => {

        let temp_inner_row = innerEditRow ? [...innerEditRow] : [];

        temp_inner_row.push({
            keyword: '',
            type: '',
            is_active: true,
            polus_interpretation_id: localItem.id,
            edit_mode: true,
        })
        setInnerData(temp_inner_row)

    }


    return (
        <Wrapper>
            <TitleWrapper> {item ? "Edit" : "Add"} </TitleWrapper>

            <DivRow w="100%" style={{ marginBottom: '1rem' }}>
                <TextBox 
                    label="keyword"
                    type="text"
                    value={localItem.keyword} 
                    onChange={(e) => _onTextchange(e.target.value, "keyword")}
                />
            </DivRow>


            <DivRow w="100%" style={{ marginBottom: '1rem' }}>
                <label className="title">polus interpretation:</label>
                <select
                    value={localItem.polus_interpretation}
                    onChange={(e) => _onTextchange(e.target.value, "polus_interpretation")}>
                    <option value="">--select--</option>
                    <option value="purchase">purchase</option>
                    <option value="acquisition">acquisition</option>
                    <option value="investment">investment</option>
                    <option value="merger">merger</option>
                </select>

            </DivRow>

            {item && (
                <React.Fragment>
                    <table className="table">
                        <thead className="thead">
                            <tr>
                                <th>keyword</th>
                                <th>type</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody style={{ alignItems: 'center' }}>

                            {innerEditRow && innerEditRow && innerEditRow.length > 0 && innerEditRow.map((row, index) => {
                                return (
                                    <tr className="tr2" key={"edit_popup" + index}>
                                        <td title={row.keyword}>
                                            {((isEditMode === index) || (row && row.edit_mode && row.edit_mode)) ?
                                                <DivRow>
                                                    <input style={{ height: '30px' }} type="text" value={row.keyword} onChange={(e) => _onTextchange(e.target.value, "keyword", true, index)} />
                                                    {/* <TextBox 
                                                        label="keyword"
                                                        type="text"
                                                        value={row.keyword} onChange={(e) => _onTextchange(e.target.value, "keyword", true, index)} 
                                                    /> */}
                                                </DivRow>
                                                :
                                                <span>{row.keyword}</span>
                                            }

                                        </td>
                                        <td title={row.type}>
                                            {((isEditMode === index) || (row && row.edit_mode && row.edit_mode)) ?
                                                <DivRow>
                                                    <select style={{ height: '30px' }} value={row.type}
                                                        onChange={(e) => _onTextchange(e.target.value, "type", true, index)}>
                                                        <option value='number'>Number</option>
                                                        <option value='string'>String</option>
                                                        <option value='percent'>Percent</option>
                                                        <option value='currency'>Currency</option>
                                                    </select>
                                                </DivRow>
                                                :
                                                <span>{row.type}</span>
                                            }
                                        </td>

                                        <td className="button_td">
                                            {row && !row.edit_mode && (
                                                <React.Fragment>
                                                    <button
                                                        title="Edit"
                                                        onClick={() => {
                                                            setIsEditMode(index)
                                                        }}
                                                        className="btn_one"><Edit height="16px" color="black" />
                                                    </button>

                                                    <button title="Delete"
                                                        // onClick={() => {
                                                        //     showPopup(undefined, 'Are you sure you want to delete this ? ', enums.default.popupType.element, ConfirmBox,
                                                        //         { func: { setYes: () => _delete_row(item.id) } })

                                                        // }} 
                                                        className="btn_two"><Delete height="16px" color="black" /></button>
                                                </React.Fragment>
                                            )

                                            }
                                        </td>
                                    </tr>
                                )
                            })
                            }

                        </tbody>

                    </table>
                    <Gap h="2rem" />
                    <Button onClick={() => _add_more()}>Add</Button>

                </React.Fragment>
            )}

            <Gap h={item ? "2rem" : "5rem"} />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '100%'
                }}
            >
                <Button
                     primary={true}
                    width="6rem"
                    mR="1.143rem"
                    onClick={() => {
                        _save_data()

                    }}
                >
                    Save
                    </Button>

                <Button
                    width="6rem"
                    bgColor={'#e6e6e6'}
                    onClick={() => {
                        close(option, popupKey);
                    }}
                >
                    Cancel
                    </Button>
            </div>
        </Wrapper>
    )
}

export default connect(undefined, { save_Interpretation, get_Interpretation })(EditPopup)
