import * as errorTypes from '../action-types/error';
import * as loaderTypes from '../action-types/loader';
import { generateUniqueKey, _whatToDoIfTokenNotFound } from '../utils'
import { POPUP_RAISE, HIDE_LAST_POPUP } from '../action-types/popup';
import { RIGHTER_RAISE } from '../action-types/righter';
import { LEFTER_RAISE } from '../action-types/lefter';
import { SHOW_SUCCESS, HIDE_SUCCESS, RAISE_SWEET_ALERT } from '../action-types/success';
import { OVERLAY_RAISE, OVERLAY_HIDE } from '../action-types/overlay';
import { SET_TABLE_DATA_MODE_SUCCESS, EXPAND_SECTION_SUCCESS, TOGGLE_HEADER, SAVE_RECENT_OPEN_MENU, SAVE_WHICH_TABLE_DROP_DOWN, SAVE_RECENT_OPEN_MAIN_MENU, GET_MONGO_TABLE_DATA_SUCCESS, GET_TAGGING_INFO_SUCCESS, SAVE_TAGGING_INFO_SUCCESS, CLEAR_MONGO_TABLE_DATA_SUCCESS } from '../action-types/helper';
import codes from '../shared-with-fe/codes.js';

import * as service from '../data.service';
import enumns from '../utils/enums';
import { constants } from '../utils/constants';


/**
 * 
 * @param {*} dispatch 
 * @param {*} type 
 * @param {*} data 
 * @param {*} error 
 * @param {*} message 
 * @param {*} recordsCount 
 */

export const dispatchAction = (dispatch, type, data, error, message, recordsCount) => {


    // if (typeof data === 'undefined') throw new Error('brazil')


    dispatch({
        type,
        message,
        data,
        error,
        recordsCount
    });
};


export const simpleDispatch_get = async (dispatch, url, successType, hideLoader, guarded = false, successNotification) => {

    !hideLoader && dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);

    try {
        const data = await getDataFromURL(dispatch, url, guarded);
        processPostRequest(dispatch, data, successType);
        if (successNotification) {
            raiseSuccess(dispatch, {
                type: 'success',
                ...successNotification
            })
        }
    }
    catch (error) {
        if (successNotification) {
            raiseSuccess(dispatch, {
                type: 'error',
                title: 'ERROR',
                message: error.message,
            })
        }
        showError(dispatch, error);
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);

    }
};


export const simpleDispatch_post = async (dispatch, url, body, successType, hideLoader, notContentType, successNotification) => {
    !hideLoader && dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);

    try {
        const data = await postData(dispatch, url, body, notContentType);

        successType && processPostRequest(dispatch, data, successType, notContentType);

        if (successNotification) {
            raiseSuccess(dispatch, {
                type: 'success',
                ...successNotification
            })
        }


        return data;
    }
    catch (error) {
        if (successNotification) {
            raiseSuccess(dispatch, {
                type: 'error',
                title: 'ERROR',
                message: error.message,
            })
        }
        showError(dispatch, error);
        !hideLoader && dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
    }
};


export const simpleDispatch_multipart_post = async (dispatch, url, body, successType, hideLoader) => {
    !hideLoader && dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);

    try {
        const data = await postMultipartData(dispatch, url, body);
        processPostRequest(dispatch, data, successType,);
        return data;
    }
    catch (error) {
        showError(dispatch, error);
        !hideLoader && dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
    }
};

export const simpleDispatch_put = async (dispatch, url, body, successType, hideLoader) => {
    !hideLoader && dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);

    try {
        const data = await putData(dispatch, url, body);
        processPutRequest(dispatch, data, successType);
    }
    catch (error) {
        showError(dispatch, error);
        !hideLoader && dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
    }
};

export const simpleDispatch_delete = async (dispatch, url, successType, hideLoader) => {
    !hideLoader && dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);

    try {
        const data = await deleteData(dispatch, url);
        processDeleteRequest(dispatch, data, successType);
    }
    catch (error) {
        showError(dispatch, error);
        !hideLoader && dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
    }
};


export const processPostRequest = (dispatch, data, successType, notContentType) => {

    if (data && !data.errorMessage) {
        dispatchAction(dispatch, successType, data.data, undefined, data.message, data.recordsCount);
    }
    else {
        showError(dispatch, new Error(data.errorMessage));
    }

    dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
};

export const processPutRequest = (dispatch, data, successType) => {
    if (data && !data.errorMessage) {
        dispatchAction(dispatch, successType, data.data, undefined, data.message, data.recordsCount);
    }
    else {
        showError(dispatch, new Error(data.errorMessage));
    }

    dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
};

export const processDeleteRequest = (dispatch, data, successType) => {
    if (data && !data.errorMessage) {
        dispatchAction(dispatch, successType, data.data, undefined, data.message, data.recordsCount);
    }
    else {
        showError(dispatch, new Error(data.errorMessage));
    }

    dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
};



export const getDataFromURL = async (dispatch, url, guarded = false) => {
    // dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);
    return await service.get(url, guarded);
};


export const postData = async (dispatch, url, body, notContentType) => {
    // dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);
    return await service.post(url, body, false, notContentType);
};
export const postMultipartData = async (dispatch, url, body, notContentType) => {
    // dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);
    return await service.postMultipart(url, body, false, notContentType);
};

export const putData = async (dispatch, url, body) => {
    // dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);
    return await service.put(url, body, true);
};

export const deleteData = async (dispatch, url) => {
    // dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);
    return await service._delete(url, true);
};


export const showLoader = dispatch => {
    dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);
};


export const hideLoader = dispatch => {
    dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
};




export const showError = (dispatch, error) => {
    if ((error.code === codes.ERRORS.AUTHENTICATION.DEFAULT
        || error.code === codes.ERRORS.AUTHENTICATION.TOKEN.CORRUPTED
        || error.code === codes.ERRORS.AUTHENTICATION.TOKEN.EXPIRED
        || error.code === codes.ERRORS.AUTHENTICATION.TOKEN.INVALID
        || error.code === codes.ERRORS.AUTHENTICATION.TOKEN.MISSING)) {

        _whatToDoIfTokenNotFound();
    }
    else {
        const uniqueKey = generateUniqueKey('err');

        dispatchAction(dispatch, errorTypes.ERROR_RAISE, { indexKey: uniqueKey }, error, undefined, 0);

        return uniqueKey;
    }
};

export const showPopup = (dispatch, title, message, type, element, data, top, left, overlay_color, other_properties, disable_overlay_click, hide_header) => {
    const uniqueKey = generateUniqueKey('ppup');

    dispatchAction(dispatch, POPUP_RAISE, {
        indexKey: uniqueKey,
        title,
        message,
        popupType: type ? type : enumns.popupType.ok,
        element,
        data,
        top,
        left,
        overlay_color,
        other_properties,
        disable_overlay_click,
        hide_header
    }, undefined, undefined, 0);

    return uniqueKey;
};




// export const showMenu = (dispatch, title, message, type, element, data) => {
//     const uniqueKey = generateUniqueKey('ppup');

//     dispatchAction(dispatch, POPUP_RAISE, {
//         indexKey: uniqueKey,
//         title,
//         message,
//         popupType: type ? type : enumns.popupType.ok,
//         element,
//         data
//     }, undefined, undefined, 0);

//     return uniqueKey;
// };




export const hideLastPopup = dispatch => {
    dispatchAction(dispatch, HIDE_LAST_POPUP, undefined, undefined, undefined, 0)
}

export const raiseSuccess = (dispatch, data) => {
    dispatchAction(dispatch, SHOW_SUCCESS, data, undefined, undefined, 0)

    setTimeout(() => {
        dispatchAction(dispatch, HIDE_SUCCESS, undefined, undefined, undefined, 0)
    }, data.duration ? data.duration : 3000)
}



export const showRighter = (dispatch, element) => {
    dispatchAction(dispatch, RIGHTER_RAISE, element, undefined, undefined, 0);
};



export const hideRighter = dispatch => {
    dispatchAction(dispatch, RIGHTER_RAISE, undefined, undefined, undefined, 0);
};


export const showLefter = (dispatch, element) => {
    dispatchAction(dispatch, LEFTER_RAISE, element, undefined, undefined, 0);
};


export const hideLefter = dispatch => {
    dispatchAction(dispatch, LEFTER_RAISE, undefined, undefined, undefined, 0);
};

export const hideOverlay = (dispatch, func) => {
    dispatchAction(dispatch, OVERLAY_HIDE, func, undefined, undefined, 0);
};

export const raiseOverlay = (dispatch, func) => {
    dispatchAction(dispatch, OVERLAY_RAISE, func, undefined, undefined, 0);
};

export const saveRecentOpenMenu = (dispatch, object) => {
    dispatchAction(dispatch, SAVE_RECENT_OPEN_MENU, object, undefined, undefined, 0);
};

export const saveRecentOpenMainMenu = (dispatch, name) => {
    dispatchAction(dispatch, SAVE_RECENT_OPEN_MAIN_MENU, name, undefined, undefined, 0);
};

export const saveWhichTableDropDown = (dispatch, data) => {
    dispatchAction(dispatch, SAVE_WHICH_TABLE_DROP_DOWN, data, undefined, undefined, 0);
};


export const killError = (dispatch, uniqueKey) => {
    dispatchAction(dispatch, errorTypes.ERROR_HIDE, { indexKey: uniqueKey }, undefined, undefined, 0);
};


export const getMongoDbTableData = (tableName, db_info_id) => async dispatch => {
    if (tableName) {
        const url = constants.END_POINTS.API + constants.END_POINTS.HELPERS.POINT + constants.END_POINTS.HELPERS.GET_MONGO_DB_TABLE_DATA;
        const post = {
            table_name: tableName,
            db_info_id: db_info_id
        }
        dispatchAction(dispatch, CLEAR_MONGO_TABLE_DATA_SUCCESS)

        await simpleDispatch_post(dispatch, url, post, GET_MONGO_TABLE_DATA_SUCCESS);
    }
    else {
        dispatchAction(dispatch, GET_MONGO_TABLE_DATA_SUCCESS, undefined, undefined, undefined, 0);
    }

};



export const getTaggedInfo = () => async dispatch => {

    const URL = constants.END_POINTS.API + constants.END_POINTS.TAGGING.GET_TAGGING_INFO;

    await simpleDispatch_get(dispatch, URL, GET_TAGGING_INFO_SUCCESS);
}

export const sendEmail = (email, subject, message, link) => async dispatch => {
    const URL = constants.END_POINTS.API + constants.END_POINTS.EMAIL_JOBS.POINT + constants.END_POINTS.EMAIL_JOBS.PRINT_AND_EMAIL;
    let _body = {
        subject: subject,
        message: message,
        emails: email,
        link: link
    }
    await simpleDispatch_post(dispatch, URL, _body, "EMAIL SENT SUCCESS", undefined, undefined, { type: "success", message: "Email sent successfully" });
}


export const saveTaggedInfo = (data) => async dispatch => {

    const URL = constants.END_POINTS.API + constants.END_POINTS.TAGGING.GET_TAGGING_INFO;

    let body = {
        nf_tagging_info: {
            ...data
        }
    }

    await simpleDispatch_put(dispatch, URL, body, SAVE_TAGGING_INFO_SUCCESS);
}

export const toogleHeader = (data) => async dispatch => {
    await dispatchAction(dispatch, TOGGLE_HEADER,);
}


export const set_expanded_section = (data, id) => async dispatch => {
    dispatchAction(dispatch, EXPAND_SECTION_SUCCESS, {
        data: data,
        id: id
    })
}

export const set_table_data_mode = (mode, id) => async dispatch => {
    dispatchAction(dispatch, SET_TABLE_DATA_MODE_SUCCESS, {
        mode: mode,
        id: id
    })
}


