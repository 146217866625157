export const grammarType = [
    { value: 'noun', title: 'Noun' },
    { value: 'pronoun', title: 'ProNoun' },
    { value: 'verb', title: 'Verb' },
    { value: 'adjective', title: 'Adjective' },
    { value: 'adverb', title: 'Adverb' },
    { value: 'determiner', title: 'Determiner' },
    { value: 'preposition', title: 'Preposition' },
    { value: 'conjunction', title: 'Conjunction' },
    { value: 'interjuction', title: 'Interjuction' },
    { value: 'asset', title: 'Assets' },
    { value: 'aux', title: 'Aux Verb' },
    { value: 'progressive_pronoun', title: 'Progressive Noun' },
]