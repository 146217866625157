import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import CloseSvg from '../svg/close';
import { convert_date_to_dd_mm_yyyy_v1 } from '../../utils';


const PopupWrapper = styled.div`

    ._header{
        background: #cccccc3d;
        margin-top: -7px;
        margin-left: -10px;
        margin-right: -10px;
        text-align: center;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 1.2rem;
        border-bottom: 1px solid #ccc;
        color: #222c;
        /* margin-bottom: 1rem; */
        position: relative;
}
    width: 100%;
    /* min-height: 30rem; */
    display: flex;
    flex-direction: column;
    background-color: #fff;
    /* max-height: 80rem; */
    box-sizing: border-box;
    padding-bottom: 1rem;

    .close_btn{
        /* background: #cccccc4f; */
        width: 1.8rem;
        height: 1.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position:  absolute;
        right: 1rem;
        svg{
            fill: red;
        }

        &:hover{
            /* background-color: #eaeaea; */
            svg{
                fill: rgb(0,138,199);
            }
        }

    }

    .row{
        width: 100%;
        /* margin-bottom: 1rem; */
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 1rem 0px;
        border-bottom: 1px solid #e2e2e2;

        div{
            font-size: .9rem;
            color: #222;;
            font-weight: 500;
            text-transform: capitalize;
        }
        
    }

    .label{
        width: 40%;
        font-weight: 600 !important;
    }
    .value{
        width: 60%;
    }

    h1{
        font-size: 1.5rem;
        font-weight: 500;
        text-align: center;
        margin-top: 0px;
    }
`;

const AddEditPopup = (props) => {

    // poops and popup data here
    const { close, popupKey, option, data } = props;
    const { popupData, _saveFun, } = data;


    // here are state of the component 
    const [formData, setFormData] = useState(undefined)
    const [recipients, set_recipients] = useState(undefined)
    const [view_rule_access, set_view_rule_access] = useState(undefined)
    const [assigned_to, set_assigned_to] = useState(undefined)
    const [notification_methods, set_notification_methods] = useState(undefined)





    useEffect(() => {
        setFormData(popupData)
        if (popupData && popupData.view_rule_access) {
            set_view_rule_access(popupData.view_rule_access)
        }
        if (popupData && popupData.assigned_to) {
            set_assigned_to(popupData.assigned_to)
        }
        if (popupData && popupData.recipients) {
            set_recipients(popupData.recipients)
        }
        if (popupData && popupData.alert_type) {
            set_notification_methods(popupData.alert_type)
        }

    }, [popupData])




    const _onChange = (key, value) => {
        let cloneFormData = formData ? Object.assign({}, formData) : {};
        cloneFormData[key] = value;
        setFormData(cloneFormData)
    }


    const _save = () => {

        let clone_form_data = formData ? Object.assign({}, formData) : {};
        clone_form_data.recipients = recipients;
        clone_form_data.view_rule_access = view_rule_access
        clone_form_data.assigned_to = assigned_to
        clone_form_data.alert_type = notification_methods
        clone_form_data.modified = true;
        _saveFun(clone_form_data)
        close(option, popupKey)
    }


    return (
        <PopupWrapper>

            <div className='_header'>
                Ticket Info #{popupData.id}

                <div title="Close" className='close_btn' onClick={() => {
                    close(option, popupKey)
                }}>
                    <CloseSvg size='1rem' height='1rem' />
                </div>
            </div>

            <div className="row">
                <div className="label">
                    Ticket Name:
                </div>
                <div className="value">
                    {popupData.ticket_name}
                </div>
            </div>
            <div className="row">
                <div className="label">
                    Ticket Created At:
                </div>
                <div className="value">
                    {popupData.ticket_created_at && convert_date_to_dd_mm_yyyy_v1(popupData.ticket_created_at, true)}
                </div>
            </div>
            <div className="row">
                <div className="label">
                    Ticket Status:
                </div>
                <div className="value">
                    {popupData.ticket_status}
                </div>
            </div>
            <div className="row" style={{ borderBottom: '0px' }}>
                <div className="label">
                    Ticket Description:
                </div>
                <div className="value">
                    {popupData.ticket_description}
                </div>
            </div>



        </PopupWrapper>
    )
}


const mapStateToProps = (state, props) => ({
    // hints: state.reportReducer.hints,
    // accessGroupList: state.groupAccessReducer.accessGroupList,
    // usersList: state.accountReducer.usersList
})

export default connect(mapStateToProps, {})(AddEditPopup);
