import * as actions from '../action-types/file.uploader';


const initialState = {
    type: actions.FILE_UPLOADER_INIT,
    data: undefined,
    filesFromServer: undefined,
    selectedFile: '',
    isSingleFile: false,
    answer: undefined,
    secondAnswer: undefined,
    metaData: undefined,
    pdfQuestionsData: undefined,
    setOfAnswers: undefined,
    jobsList: undefined,
    folderFromServer: undefined,
    uploaded_table: undefined,
    uploadedFileInfo: undefined,
    fileGrouped: undefined,
    tableEditsData: undefined,

    allFiles: undefined,
    platformFiles: undefined,
    dimensionsFiles: undefined,
    viewTableData: undefined,
    fileMetaInfo: undefined,
    processFileData: undefined,
    tableOfDb: undefined,

};


const fileUploaderReducer = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        case actions.SEND_FILE_SUCCESS:
            return {
                ...state,
                type: action.type,
                data: action.data
            };
        case actions.SINGLE_FILE_SUCCESS:
            return {
                ...state,
                type: action.type,
                isSingleFile: true
            };
        case actions.MULTIPAL_FILE_SUCCESS:
            return {
                ...state,
                type: action.type,
                isSingleFile: false
            };
        case actions.SET_CURRENT_FILE_NAME:
            return {
                ...state,
                type: action.type,
                selectedFile: action.data
            };
        case actions.GET_META_DATA:
            return {
                ...state,
                type: action.type,
                metaData: action.data
            };
        case actions.ASK_PDF_QUESTION:
            return {
                ...state,
                type: action.type,
                answer: action.data ? action.data.data : undefined
            };
        case actions.ASK_PDF_QUESTION_PDF_SECOND:
            return {
                ...state,
                type: action.type,
                secondAnswer: action.data ? action.data.data : undefined
            };
        case actions.GET_FILE_LIST:
            return {
                ...state,
                type: action.type,
                filesFromServer: action.data
            };
        case actions.LIST_OF_UPLOADED_FILE_INFO:
            return {
                ...state,
                type: action.type,
                uploadedFileInfo: action.data
            }
        case actions.MT_ANSWER_SUCCESS:
            return {
                ...state,
                type: action.type,
                answer: undefined,
                secondAnswer: undefined
            };
        case actions.GET_PDF_QUESTIONS:
            return {
                ...state,
                type: action.type,
                pdfQuestionsData: action.data

            }
        case actions.SAVE_SET_OF_QUESTIONS:
            return {
                ...state,
                type: action.type,
                setOfAnswers: action.data
            }

        case actions.GET_JOBS_LIST_SUCCESS:
            return {
                ...state,
                type: action.type,
                jobsList: action.data
            }
        case actions.LIST_OF_CATEGORY_FOLDER:
            return {
                ...state,
                type: action.type,
                folderFromServer: action.data
            }
        case actions.LIST_OF_UPLOADED_TABLE_LIST:
            return {
                ...state,
                type: action.type,
                uploaded_table: action.data
            }
        case actions.LIST_OF_FILE_GROUPED:
            return {
                ...state,
                type: action.type,
                fileGrouped: action.data
            }
        case actions.GET_TABLE_EDITS_INFORMATION:
            return {
                ...state,
                type: action.type,
                tableEditsData: action.data
            }

        case actions.GET_ALL_FILES:
            return {
                ...state,
                type: action.type,
                allFiles: action.data
            }
        case actions.GET_FILES_BY_DIMENSION:
            return {
                ...state,
                type: action.type,
                dimensionsFiles: action.data
            }
        case actions.GET_FILE_BY_PLATFORM:
            return {
                ...state,
                type: action.type,
                platformFiles: action.data
            }
        case actions.GET_THE_SIMPALE_FILE_DETAILS_SUCCESS:
            return {
                ...state,
                type: action.type,
                viewTableData: action.data
            }
        case actions.CLEAR_THE_SIMPALE_FILE_DETAILS:
            return {
                ...state,
                type: action.type,
                viewTableData: undefined
            }
        case actions.GET_THE_META_DETAILS_OF_FILE:
            return {
                ...state,
                type: action.type,
                fileMetaInfo: action.data
            }
        case actions.CLEAR_THE_META_DETAILS_OF_THE_FILE:
            return {
                ...state,
                type: action.type,
                fileMetaInfo: undefined
            }
        case actions.PROCESS_THE_FILE:
            return {
                ...state,
                type: action.type,
                processFileData: action.data
            }
        case actions.CLEAR_PROCESS_THE_FILE:
            return {
                ...state,
                type: action.type,
                processFileData: undefined
            }
        case actions.GET_TABLE_FROM_DB_SUCCESS:
            return {
                ...state,
                type: action.type,
                tableOfDb: action.data
            }
        case actions.CLEAR_TABLE_FROM_DB:
            return {
                ...state,
                type: action.type,
                tableOfDb: undefined
            }
        default:
            return {
                ...state,

            }
    }
};

export default fileUploaderReducer;