import React, { useState, useEffect } from 'react';
import { HintsWrapper, ExistingTags, Filterd, FilterdItemRow } from './hints.add.styled';
import { Gap } from '../gap';
import { insertAtSpecificLocationInArray, removeElementFromArray } from '../../utils';
import CrossSvg from '../svg/close';
import EditSvg from '../svg/edit';
import TextBox from '../hoc/textBox';
import TextBoxV1 from '../hoc/text.box.v1';
import styled from 'styled-components';


const MainWrapper = styled.div`

    width: 100%;
    max-height: 9rem;
    overflow: auto;
    background: #fff;
    border: 1px solid #ccc;
    border-top: 0px;
    margin-top: 1px;;
    position: absolute;
    top: 100%;
    z-index: 3;

    &::-webkit-scrollbar {
        background-color:red;
        width: 2px;
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height:  2px;;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height:  2px;;
    }

    .hint{
        padding: 5px;
        color: #222;
        font-size: .9rem;
        cursor: pointer;
        &:hover{
            background: #eaeaea;
        }
    }
    

`;


const HintEditor = props => {

    const { hints, prevent_enter_hint, setHints, label, existing_label, height, searchHint, is_new_text_box, text_box_height, labelStyle,hide_border, show_as_placholder, background } = props;
    const [editIndex, setEditIndex] = useState(undefined);
    const [isInputShow, setisInputShow] = useState(false);
    const [inputValue, setinputValue] = useState('');

    const [localHints, setLocalHints] = useState(undefined);
    const [showLocalHints, setShowLocalHints] = useState(undefined);


    useEffect(() => {
        setLocalHints(searchHint)
    }, [searchHint])


    const addHints = value => {

        const tempFilteredHints = hints ? [...hints] : [];
        const caseInSensitiveValue = typeof value === 'string' ? value.toLowerCase().trim() : value.trim();
        if (tempFilteredHints.indexOf(caseInSensitiveValue) === -1) {
            if (!editIndex) {
                tempFilteredHints.push(value);
                setHints(tempFilteredHints);
            }
            else {
                const newArray = insertAtSpecificLocationInArray(tempFilteredHints, editIndex, value);
                setEditIndex(undefined);
                setHints(newArray);
            }
            setinputValue("");
        }
    };



    const removeSelectedHint = (indexOfRemovedHint) => {
        const tempFilteredHints = hints ? [...hints] : [];
        tempFilteredHints.splice(indexOfRemovedHint, 1);
        setHints(tempFilteredHints);
    };


    const _editHints = (value, index) => {
        let tempFilteredHints = removeElementFromArray(index, undefined, hints);
        setinputValue(value);
        setEditIndex(index);
        setisInputShow(true);
        setHints(tempFilteredHints);
    }





    const click_on_hint = (hint) => {
        addHints(hint)
        setisInputShow(false)
        setinputValue("")
        setShowLocalHints(false);
    }


    return (
        <HintsWrapper>

            <div style={{ position: 'relative', width: '100%', }}>

                {is_new_text_box ? <TextBoxV1
                    label={label ? label : "Type and Enter Hint"}
                    value={inputValue}
                    height={text_box_height}
                    labelStyle={labelStyle}
                    show_as_placholder={show_as_placholder}
                    hide_border={hide_border}
                    background={background}
                    onFocus={() => {
                        setShowLocalHints(true);
                    }}
                    onBlur={() => {
                        setTimeout(() => {
                            setShowLocalHints(false);
                        }, 500)
                    }}
                    onChange={(e) => {

                        const value = e.target.value;
                        const clone_suggestion = searchHint ? [...searchHint] : [];

                        const filterd_suggestion = clone_suggestion && clone_suggestion.filter((f) => {
                            if (value === '') return true;
                            if (value && f && f.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1) return true;
                            else return false;
                        })

                        setLocalHints(filterd_suggestion)
                        setShowLocalHints(true)
                        setinputValue(e.target.value)
                    }}
                    onKeyDown={(event) => {

                        let value = inputValue.trim()

                        if (event.key === 'Enter' && value && value.length > 0) {
                            if (!prevent_enter_hint) {
                                addHints(value)
                                setisInputShow(false)
                                setinputValue("")
                            }
                        }
                    }}

                /> : <TextBox
                    label={label ? label : "Type and Enter Hint"}
                    value={inputValue}
                    onFocus={() => {
                        setShowLocalHints(true);
                    }}
                    onBlur={() => {
                        setTimeout(() => {
                            setShowLocalHints(false);
                        }, 500)
                    }}
                    onChange={(e) => {

                        const value = e.target.value;
                        const clone_suggestion = searchHint ? [...searchHint] : [];

                        const filterd_suggestion = clone_suggestion && clone_suggestion.filter((f) => {
                            if (value === '') return true;
                            if (value && f && f.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1) return true;
                            else return false;
                        })

                        setLocalHints(filterd_suggestion)
                        setShowLocalHints(true)
                        setinputValue(e.target.value)
                    }}


                    onKeyDown={(event) => {
                        let value = inputValue.trim()
                        if (event.key === 'Enter' && value && value.length > 0) {
                            if (!prevent_enter_hint) {
                                addHints(value)
                                setisInputShow(false)
                                setinputValue("")

                            }
                        }
                    }}

                />}


                {showLocalHints && localHints && localHints.length > 0 && (
                    <MainWrapper>
                        {localHints.map((item) => {
                            return (
                                <div onClick={() => click_on_hint(item)} className="hint">{item}</div>
                            )
                        })}
                    </MainWrapper>
                )}
            </div>

            {hints && hints.length > 0 ? <Gap h=".5rem" /> : null}
            {hints && hints.length > 0 && (
                <ExistingTags height={height}>
                    <h2 className="e_tags">{existing_label ? existing_label : 'Existing hints'}</h2>
                    <Gap h=".3rem" />
                    <FilterdItemRow height={height}>
                        {hints.map((hint, index) => {
                            return (
                                <Filterd>
                                    {hint}
                                    <div className="filter_hover">
                                        <div onClick={() => removeSelectedHint(index)}><CrossSvg size="12px" height="12px" fill="#000" /></div>
                                        <div onClick={() => _editHints(hint, index)}><EditSvg size="12px" height="12px" fill="#000" /></div>
                                    </div>
                                </Filterd>
                            )
                        })}
                    </FilterdItemRow>
                </ExistingTags>
            )}

        </HintsWrapper>
    );
};

export default HintEditor;

