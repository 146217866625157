import * as actions from '../action-types/data_wrangler';



const initialState = {

    type: actions.DATA_WRANGLER_INIT,
    report: undefined,
    extraction: undefined,
    load_mapping: undefined,
    etl_config: undefined,
    wrangler_jobs: undefined,

    wrangler_config_cache: {},

    job_occurrences_cache: {},
    job_logs_cache: {},
    job_error_records_cache: {},

};




const DataWrangler = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }


    switch (action.type) {

        case actions.GET_DATA_WRANGLER_CONFIG:
            return {
                ...state,
                type: action.type,
                etl_config: action.data?.etl_config,
            };

        case actions.GET_EXTRACTION_BY_ID:
            return {
                ...state,
                type: action.type,
                extraction: action.data?.extraction_data,
            };

        case actions.GET_REPORT_BY_ID:
            return {
                ...state,
                type: action.type,
                report: action.data?.report,
            };

        case actions.GET_LOAD_MAPPING_BY_ID:
            return {
                ...state,
                type: action.type,
                load_mapping: action.data?.load_mapping_data,
            };
        case actions.GET_DATA_WRAGLING_JOBS:
            return {
                ...state,
                type: action.type,
                wrangler_jobs: action?.data
            }

        case actions.GET_DATA_WRANGLER_CONFIG_BY_JOB_ID:

            const job_id = action.data?.etl_config?.job_id;

            const clone_wrangler_config_cache = state?.wrangler_config_cache ? JSON.parse(JSON.stringify(state.wrangler_config_cache)) : {};

            if (job_id && action.data) {
                clone_wrangler_config_cache[job_id] = action.data?.etl_config;
            }

            return {
                ...state,
                type: action.type,
                wrangler_config_cache: clone_wrangler_config_cache
            }

        case actions.GET_JOB_OCCURENCES_BY_JOB_ID_SUCCESS:

            const job_id1 = action.data?.job_id;

            const clone_job_occurrences_cache = state?.job_occurrences_cache ? JSON.parse(JSON.stringify(state.job_occurrences_cache)) : {};

            if (job_id1 && action.data?.job_occurences) {

                clone_job_occurrences_cache[job_id1] = action.data?.job_occurences;
            }

            return {
                ...state,
                type: action.type,
                job_occurrences_cache: clone_job_occurrences_cache
            };


        case actions.GET_JOB_LOGS_BY_OCCURANCE_ID_SUCCESS:
            const occ_id = action.data?.occurence_id;
            const clone_job_logs = state?.job_logs_cache ? JSON.parse(JSON.stringify(state.job_logs_cache)) : {};
            if (occ_id && action.data?.job_logs) {
                clone_job_logs[occ_id] = action.data?.job_logs;
            }

            return {
                ...state,
                type: action.type,
                job_logs_cache: clone_job_logs
            };

        case actions.GET_JOB_ERRORS_RECORDS:

            const unique_id = action.data?.unique_id;
            const clone_error_records_cache = state?.job_error_records_cache ? JSON.parse(JSON.stringify(state.job_error_records_cache)) : {};

            if (unique_id && action.data?.error_records) {
                clone_error_records_cache[unique_id] = action.data?.error_records;
            }

            return {
                ...state,
                type: action.type,
                job_error_records_cache: clone_error_records_cache
            };

        default:
            return {
                ...state
            }
    }
};

export default DataWrangler;