import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TextBox from '../hoc/textBox';
import TextArea from '../hoc/textArea';
import { Button } from '../hoc/button';
import HintsAdd from '../hints.add';
import HintSearch from '../hints.search';
import SelectOption from '../select-option';


const PopupWrapper = styled.div`

    width: 30rem;
    min-height: 20rem;
    padding: 0 .8rem 0.8rem 0.8rem;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    max-height: 50rem;
    /* overflow: hidden; */
    /* overflow-y: auto; */


    .row{
        width: 100%;
        margin-bottom: 1.5rem;
    }

    h1{
        font-size: 1.5rem;
        font-weight: 500;
        text-align: center;
        margin-top: 0px;
    }
`;

const _data_types = [
    {
        name: 'String',
        id: 'string',
    },
    {
        name: 'Date',
        id: 'date',
    },
    {
        name: 'Currency',
        id: 'currency',
    },
    {
        name: 'Number',
        id: 'number',
    }
]


const AddEditPopup = (props) => {

    // poops and popup data here
    const { close, popupKey, option, data } = props;
    const { popupData, _saveFun, hintsData, table_name } = data;


    // here are starte 
    const [formData, setFormData] = useState(undefined)
    const [hints, setHints] = useState(undefined)
    // const [hintsCol, setHintsCol] = useState(undefined)



    useEffect(() => {
        setFormData(popupData)
        if (popupData && popupData.values) {
            setHints(popupData.values)
        }
        // if (popupData && popupData.hintscol) {
        //     setHintsCol(popupData.hintscol)
        // }
    }, [popupData])


    const _onChange = (key, value) => {
        let cloneFormData = formData ? Object.assign({}, formData) : {};
        cloneFormData[key] = value;
        setFormData(cloneFormData)
    }


    const _save = () => {

        let temp_saved_data = formData ? Object.assign({}, formData) : {};
        temp_saved_data.table_name = "nf_rule"
        temp_saved_data.values = hints;
        // temp_saved_data.hintscol = hintsCol;
        temp_saved_data.modified = true;
        _saveFun(temp_saved_data, table_name)
        close(option, popupKey)
    }

    const disabled = !formData || formData && !formData.column_name;

    return (
        <PopupWrapper>
            {/* <h1>
                {popupData ? 'Edit Rules' : 'Add Rules'}
            </h1> */}
            <div className="row">
                <TextBox
                    label="Rule Name"
                    type="text"
                    value={formData && formData.column_name ? formData.column_name : ''}
                    onChange={(e) => _onChange('column_name', e.target.value)}
                />
            </div>

             
            <div className="row">
                {/* <HintSearch
                    hintValue={hintsCol}
                    setHintValue={setHintsCol}
                    searchHints={hintsData ? hintsData : []}
                /> */}
                {/* if 'paid" word found then filter records for tbl.revenue _type = "payment" else if "o/s" word found then filter records for tbl.revenue_type "outstanding payment" */}
                <TextArea
                    label="Define Your Rule"
                    type="text"
                    value={formData && formData.hintscol ? formData.hintscol : ''}
                    onChange={(e) => _onChange('hintscol', e.target.value)}
                />

            </div>
            

            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: 'auto',
                marginLeft: 'auto'

            }}>
                <Button
                    primary={true}
                    disabled={disabled}
                    onClick={() => _save()}
                    mR="1rem"
                >
                    Save
                </Button>

                <Button
                    onClick={() => {
                        setFormData(undefined);
                        close(option, popupKey)
                    }}
                    bgColor={'#e6e6e6'}
                    mR="1rem"
                >
                    Cancel
                </Button>
            </div>

        </PopupWrapper>
    )
}

export default AddEditPopup;
