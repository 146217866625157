import React from "react";

const PieChartSliceIcon = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      shape-rendering="geometricPrecision"
      text-rendering="geometricPrecision"
      image-rendering="optimizeQuality"
      fill-rule="evenodd"
      clip-rule="evenodd"
      viewBox="0 0 1156 1124"
    >
      <path
        transform="translate(523,15)"
        d="m0 0h60l35 3 34 5 33 7 2 1-1 8-41 177-24 103-15 65-19 82-18 77-5 21v8l20 100 82 414 3 16v6l-11 3-33 5-29 3-34 2h-18l-45-3-38-5-26-5-32-8-25-8-28-10-25-11-25-12-20-11-20-12-21-14-19-14-14-11-14-12-20-18-24-24-7-8-13-15-9-11-15-20-11-16-14-22-12-21-14-27-11-25-11-29-10-31-8-32-6-32-5-39-2-19v-57l6-52 5-28 5-23 10-35 10-29 10-24 12-25 11-22 10-17 14-22 14-20 10-13 13-16 9-10 7-8 14-15 17-17 8-7 11-10 11-9 13-10 19-14 15-10 21-13 25-14 33-16 33-13 30-10 30-8 35-7 37-5z"
        fill="#6D79FE"
      />
      <path
        transform="translate(1133,489)"
        d="m0 0 3 1 4 17 5 34 2 21 1 17v43l-2 31-4 31-6 31-8 31-12 36-11 27-12 26-13 24-13 21-8 12-12 17-10 13-8 10-12 14-14 15-23 23-8 7-14 12-17 13-15 11-15 10-13 8-21 12-23 12-29 13-27 10-29 9-37 9h-4l-4-15-18-93-26-134-19-99-16-82-15-77-1-10 114-25 306-67 69-15z"
        fill="#3099FE"
      />
      <path
        transform="translate(523,15)"
        d="m0 0h60l35 3 34 5 33 7 2 1-1 8-41 177-24 103-15 65-19 82-18 77-5 21v8l20 100 82 414 3 16v6l-11 3-33 5-29 3-34 2h-18l-45-3-38-5-26-5-32-8-25-8-28-10-25-11-25-12-20-11-20-12-21-14-19-14-14-11-14-12-20-18-24-24-7-8-13-15-9-11-15-20-11-16-14-22-12-21-14-27-11-25-11-29-10-31-8-32-6-32-5-39-2-19v-57l6-52 5-28 5-23 10-35 10-29 10-24 12-25 11-22 10-17 14-22 14-20 10-13 13-16 9-10 7-8 14-15 17-17 8-7 11-10 11-9 13-10 19-14 15-10 21-13 25-14 33-16 33-13 30-10 30-8 35-7 37-5zm18 17-26 1-20 2-29 4-32 6-23 6-28 9-19 7-26 11-29 14-23 13-21 13-12 8-11 8-16 12-13 11-11 9-12 11-7 7-6 5v2l-4 2-14 15-9 10-8 9-20 25-10 14-7 11-6 9-13 22-14 27-8 17-7 17-9 24-9 29-7 26-6 33-2 10-2 21-2 18v78l5 43 8 36 4 17 7 24 8 24 12 29 15 31 11 19 10 17 9 14 8 12 14 19 8 10 24 28 9 9 6 7 7 6 12 12 11 9 11 10 17 13 16 12 17 11 20 12 23 13 19 9 15 7 29 12 33 11 25 7 20 4 16 2 21 1 47 4 8 1h23l44-2 27-4 7-2 1-4-3-23-8-43-5-24-2-7-3-16-3-17-3-11-2-10-2-9-1-9-2-7-3-16-2-9-1-9-3-12-2-10-3-18-3-13-4-20-6-31-7-37-6-28-3-16-4-21-4-20-5-22-3-16-3-19-2-10-1-8v-14l2-16 5-24 12-53 15-65 10-43 7-29 7-32 10-42 21-91 13-57 10-42 4-18v-11l-8-2-21-3-42-5-24-1z"
        fill="#3099FE"
      />
      <path
        transform="translate(740,4)"
        d="m0 0 9 1 28 7 25 8 22 8 22 9 22 10 22 12 22 13 24 16 19 14 16 13 14 12 13 12 26 26 7 8 12 14 13 17 14 19 11 17 9 15 10 18 12 23 13 30 11 30 9 30 7 28v3l-49 12-145 35-92 22-49 12-67 16-53 13-63 15-27 6 1-9 9-37 30-122 15-62 14-57 31-126 24-98 8-32zm25 41-2 6-16 64-36 147-20 82-18 74-12 49-8 32v3l18-4 199-48 199-48 38-9 4-2-12-37-13-32-14-29-10-18-14-23-11-16-16-21-14-17-24-26-9-9-8-7-11-10-11-9-18-14-20-14-26-16-24-13-29-14-34-13-25-8z"
        fill="#6C79FE"
      />
    </svg>
  );
};

export default PieChartSliceIcon;
