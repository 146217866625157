import { themeObject } from '../../theme'

const theme = themeObject
export class DashboardThemeStyle {
    header = undefined;
    right_menu = undefined;

    constructor(type = 'report') {
        switch (type) {
            case "report" :
                this.header = theme.report.display_area.header;
                this.right_menu = theme.report.right_menu;

                break;

            case 'dashboard' :

                break;

            default :
        }
    }

}



