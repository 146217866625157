import * as actions from '../action-types/common';

const initialState = {
    type: actions.COMMON_INIT,
    headerInfo: undefined,
};


const commonReducer = (state, action) => {
	if (!state || typeof action.type === 'undefined') {
		return initialState;
    }

    // if (action && action.data && typeof action.data === 'object') {
    //     action.data.key = new Date();
    // }

    switch (action.type) {
        case actions.RESET_STORE_SUCCESS:
            return {
                type: action.type
            };

        case actions.DISPATCH_PAGE_INFORMATION:
            return {
                ...state,
                type: action.type,
                headerInfo: action.data
            }
        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
			return {...state};
        }
};

export default commonReducer;