import styled, { keyframes } from 'styled-components';
import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils';
import { constants } from '../../utils/constants';
import { apply_styles } from '../../utils';

const selected = getSelectedTheme();

const { table, grandTotalColumns, tableNextPrev } = themes[selected];

const myMove = keyframes`
    from{
		background: #fff;
	}

	to{
		background: red;
	}

`;




export const TableWrapper = styled.div`
    max-width: ${props => props.maxWidth ? `${props.maxWidth}px` : ''};
    width: calc(100vw - 120px);
    max-height: ${props => props.insights ? "initial" : " calc(100vh - 335px)"};
    height: ${props => props.insights ? "initial" : " calc(100vh - 335px)"};
    overflow-y: auto;
    overflow-x: auto;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        background-color:red;
        width: 4px;
        &:hover{
            width: 6px;
        }
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 4px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
        &:hover{
            height: 8px;
        }
    }

    .main_scroll_div{
        max-width: ${props => props.maxWidth ? `${props.maxWidth}px` : ''};
        width: calc(100vw - 120px);
        /* max-height: ${props => props.insights ? "initial" : " calc(100vh - 335px)"}; */
        /* height: ${props => props.insights ? "initial" : " calc(100vh - 335px)"}; */
        overflow-y: auto;
        overflow-x: auto;
        scroll-behavior: smooth;
    &::-webkit-scrollbar {
        background-color:red;
        width: 4px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 4px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 3px;
    }

    }

    tr{
        text-align: center;
        display: ${props => props.display || 'table-row !important'};
        background: ${props => props.background ? props.background : undefined};
        &:hover{
            td{ 
                background: ${props => props.hoverDisable ? undefined : props.hoverBackgroundColor};
            }
        }
    }

  


 .table_row {
    display: table-row;
    width: 80%;

    &:hover {
        background-color: #f8f8f9;
    }
}

.no_hover {
  &:hover {
      box-shadow: 0 0 0 transparent;
    background-color: transparent;
  }
}

`;



export const OddEvenWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const OddEvenContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ColorOptionWrapper = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2px;
    margin-right: 5px;
    font-size: .9rem;
    border: 1px solid #ccc;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;

    input {
        border: 1px solid #ccc;
        background: transparent;
        margin: 0;
        margin-left: 4px;
    }
`;

export const ColorCell = styled.div`
    width: 10px; 
    height: 10px; 
    background-color: ${props => props.color}; 
    border: 1px solid black ;
    /* margin-left: 5px; */
    border-radius: 1px;
    cursor: pointer;
`;

export const TableOptions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    fieldset {
        font-size: 1rem;
        /* margin-right: 20px; */
        border: 1px solid #c1c1c2;
        border-radius: 5px;
    }
`;

export const ExportWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 150px;

    img {
        cursor: pointer;
    }
`;

export const Wrapper = styled.div`
    width: calc(100vw - 120px);
    display: block;
    
    fieldset {
        width: fit-content;
        margin-bottom: 10px;
        border-radius: 5px;
        box-sizing: border-box;
        border: 1px solid #ccc;
    }
`;


export const IconSmall = styled.div`
    position: absolute;
    top: .4rem;
    right: .5rem;
    font-size: .9rem;
    cursor: pointer;
    display: none;
`;



export const DropDownBox = styled.div`
    position: absolute;
    top: 100%;
    right: ${props => props.left === false ? '5px' : undefined};
    left: ${props => props.left || undefined};
    width: 200px;
    min-width: 200px;
    max-height: 340px;
    overflow-y: hidden;
    overflow-x: hidden;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.15);
    z-index: 10;
    border-radius: 4px;
    box-sizing: border-box;
    display: ${props => props.isShow ? "" : 'none'};
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
    background-color: #BBD1D9;


    .dropDown_content{
        min-height: 200px;
        max-height: 200px;
        overflow: auto;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            background-color:red;
            width:6px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 10px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 7px;
        }   
    }



    

    .item_row{
        font-size: 0.857rem;
        font-weight: normal;
        color: rgb(0,22,31);
        padding: 0px 10px;
        line-height:1.9rem;
        cursor: pointer;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content:flex-start;
        transition: all .3s;


        &:hover{
            color: #000;
            font-weight : 500;

        }

       .title{
           margin-left: 10px;
           white-space: nowrap;
           display: inline-block;
           width: calc(100% - 30px);
           text-overflow: ellipsis;
           text-transform: capitalize;
           overflow: hidden;
       }
    }


    .sort_btn{
        padding: 7px 10px;
        box-sizing: border-box;
        color: #000;
        cursor: pointer;
        font-weight: 500;
        position: relative;
        padding-left: 29px;

        i{
            position: absolute;
            left: 10px;
            font-size: 13px;
            top: 10px;
        }

        &:hover{
            background: #246a9938;
        }
    }

    .button-group{
        display: flex;
        margin-top: 20px;
    }
`;

export const TabWrapper = styled.div`
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px !important;
        padding-bottom: 10px !important;

`;

export const Tab = styled.div`
        width: 50%;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${props => props.active ? 'transpatrent' : '#008AC7'};
        color: ${props => props.active ? 'rgb(0,22,31)' : '#fff'};
        font-weight: 500;
        cursor: pointer;

        &:hover{
            background-color: #008ac769;
        }

`;



export const NextPrevBtn = styled.div`
        width: 30px;
        height: 30px;
        background-color: ${props => props.disable ? tableNextPrev.color.disable : tableNextPrev.color.normal};
        color: ${props => props.disable ? "gray" : "#fff"};
        cursor: ${props => props.disable ? "not-allowed" : "pointer"};
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

        &:hover{
            opacity: 0.7;
        }
`;




export const TableMain = styled.table`
    display: ${props => props.display || 'table'};
    position: ${props => props.position || 'relative}'};
    width: initial;
    /* border: ${props => props.border || '1px solid #e1e1e4'}; */
    border: ${props => !props.hide_border ? '1px solid #e1e1e4' : '0px'};
    background-color: ${props => props.bgColor || '#fff'};

    .footer_g_total{
        position: sticky;
        bottom: -2px;
        z-index: 1;
        ::before{
            content: '';
            border-top: 2px solid #e2e2e2;
            width: 100%;
            height: 0px;
            position: absolute;
        }
        ::after{
            content: '';
            background-color: #fff;
            width: 100%;
            height: 97.5%;
            position: absolute;
            top: 2px;
            z-index: -1;
        }
         td{
            padding: 0rem 9.5px;
            font-size: ${grandTotalColumns.font.textFontSize};
            font-weight: ${grandTotalColumns.font.textFontWeight};
            color: ${grandTotalColumns.color.text};
            text-overflow: ellipsis;
            overflow: hidden;
            height: 30px;
        }
    }
`;




export const TableHeaderRow = styled.tr`
    font-weight: bold;
    text-align: center;
    display: ${props => props.display || 'table-row !important'};
    background: ${props => props.background ? props.background : undefined};
    &:hover{
        td{ 
            background: ${props => props.hoverDisable ? undefined : props.hoverBackgroundColor};
        }
    }
`;


export const GrandFooter = styled.div`
    width: 100%;
    padding: .5rem 0px;
    border-top: ${props => !props.hide_border ? '1px solid #ccc' : '0px'};
    table{
        width: 100%;
        border-collapse: collapse;

        tr{
            display: table;
        }
    }
    .table-scroll{
        width: 100%;
        overflow: auto;
        &::-webkit-scrollbar {
            background-color:red;
            width: 0px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 0px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 0px;
        }
    }

    

    td{
        padding: 0rem 9.5px;
        font-size: ${grandTotalColumns.font.textFontSize};
        font-weight: ${grandTotalColumns.font.textFontWeight};
        color: ${grandTotalColumns.color.text};
        /* border: 2px solid transparent; */
        text-overflow: ellipsis;
        overflow: hidden;
        height: 30px;

    }

.footer_btn_section{
    display: flex;
    width: 15rem;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 2rem;
    align-items: center;
    margin-left: auto;
    padding-right: .8rem;

    button{
        border: 0px;
        height: 1re;
        height: 2rem;
        width: 2rem;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .disable{
        cursor: not-allowed;
        background: #ccc;
    }
    .number_of_records{
        font-size: .8rem;
        font-weight: 600;
        color: #222;
    }
}
`;



export const TableCell = styled.td`
    ${props => {
        return apply_styles(props._style, constants.STYLED_COMPONENTS.ALL);
    }};
    background-color: ${props => props.force_bg_color ? props.force_bg_color : undefined};
    white-space: ${props => props.whiteSpace || 'normal'};
    box-sizing: border-box;
    position: ${props => props.position || "relative"};
    top: ${props => props.top || undefined};
    z-index: ${props => props.zIndex || undefined};
    letter-spacing: normal;  
    transition: all .5s ease-in-out;
    width: ${props => (props.Newwidth || props.width) + "px" || undefined};
    box-sizing: border-box;
    cursor: pointer;

    .dropdown_icon {
        cursor: pointer;
        line-height: 4rem;
        vertical-align: middle;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translate(0px, -50%);
            svg{
                display: block;
            }
         }

    &:hover{
        .on_hover_show{
            display: flex;
        }
    }

    .th_data
    {
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        position: relative;
        word-break: initial;
        cursor: pointer;
        text-align: center;
        max-height: 4.2rem;
        padding-right: 30px;
        box-sizing: border-box;
    }

    .td_span{
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        width: 100%;
        vertical-align: middle;
        white-space: nowrap;
        ////////////////////////////////
        position: relative;
        /* z-index:1; */
    }


    .gradient_div{
        /* width: 50%; */
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 0;
    }
`;