
import { isDate } from 'lodash';
import { is_date, is_date_month, is_month } from '..';
import { post, get } from '../../data.service';
import * as dataTypes from '../../shared-with-fe/data.types';
import { constants } from '../constants';

const short_months_cy = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'total', '% share'];
const full_months_cy = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december', 'total', '% share'];

const short_months_fy = ['apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'jan', 'feb', 'mar', 'total', '% share'];
const full_months_fy = ['april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december', 'january', 'february', 'march', 'total', '% share'];

const short_months = process.env.YEAR_FORMAT === 'fy' ? short_months_fy : short_months_cy;
const full_months = process.env.YEAR_FORMAT === 'fy' ? full_months_fy : full_months_cy;

const proper_case_months = full_months.map(month => {
    return month.substring(0, 1).toUpperCase() + month.substring(1, month.length).toLowerCase();
});


export let sort_priorities = {};

export let weeks_config = {};


export const get_week_config = async () => {

    const url = constants.END_POINTS.API + constants.END_POINTS.DB_FUNCTIONS_CONFIG.POINT + constants.END_POINTS.DB_FUNCTIONS_CONFIG.GET_WEEK_CONFIG;

    try {
        const data = await get(url, {}, undefined, undefined,)

        if (data && data.code && data.data) {
            const __data__ = data?.data || [];
            weeks_config = __data__;
        }
        // console.log("sort_priorities", sort_priorities)
    } catch (error) {
        console.log("errr", error)
    }

}


export const get_sort_priorities = async () => {

    const url = constants.END_POINTS.API + constants.END_POINTS.SORT_PRIORITIES.POINT + constants.END_POINTS.SORT_PRIORITIES.GET_SORT_PRIORITIES;

    try {
        const data = await post(url, {})

        if (data && data.code && data.data) {
            const __data__ = data?.data || [];
            __data__?.forEach((d) => sort_priorities[d.concatenated_key] = d.key_to_appear_first)
        }
        inject_months_to_column_configs()
    } catch (error) {
        console.log("errr", error)
    }

}


/**
 * because the months cannot sort itself, we will be injecting custom sort config 
 */
const inject_months_to_column_configs = () => {
    // let's inject short months first

    const create_combinations = (months) => {
        months.forEach((month, index) => {
            if (index < months.length) {
                for (let next_month_index = index + 1; next_month_index < months.length; next_month_index++) {
                    const next_month = months[next_month_index];
                    const month_array = [month, next_month];

                    month_array.sort();
                    // console.log('comparing 1 ====> ', month_array);
                    sort_priorities[month_array.join('-')] = month;
                }
            }
        });
    };

    // console.log("process.env.YEAR_FORMAT", process.env.YEAR_FORMAT, short_months, full_months)
    create_combinations(short_months);
    create_combinations(full_months);
};


let s_index = 0;
let entered_index = 0;

export const sort_using_priorities = (array_to_sort, data_type, where) => {

    entered_index++;

    array_to_sort.sort((_a_, _b_) => {

        if (_a_?.toLocaleLowerCase().indexOf("week") > -1 && _b_.toLocaleLowerCase().indexOf("week") > -1) {
            // this is added for Week sorting
            // by sk
            const week_a = _a_.split(" ")[1];
            const week_b = _b_.split(" ")[1];
            const week_a_num = week_a && parseInt(week_a);
            const week_b_num = week_b && parseInt(week_b);
            return week_a_num - week_b_num;
        }
        if (_a_?.toLocaleLowerCase().indexOf("hour") > -1 && _b_.toLocaleLowerCase().indexOf("hour") > -1) {
            // this is added for Week sorting
            // by sk
            const hour_a = _a_.split(" ")[1];
            const hour_b = _b_.split(" ")[1];
            const hour_a_num = hour_a && parseInt(hour_a);
            const hour_b_num = hour_b && parseInt(hour_b);
            return hour_a_num - hour_b_num;
        }
        if (_a_?.toLocaleLowerCase().indexOf("minute") > -1 && _b_.toLocaleLowerCase().indexOf("minute") > -1) {
            // this is added for Week sorting
            // by sk
            const minute_a = _a_.split(" ")[1];
            const minute_b = _b_.split(" ")[1];
            const minute_a_num = minute_a && parseInt(minute_a);
            const minute_b_num = minute_b && parseInt(minute_b);
            return minute_a_num - minute_b_num;
        }


        if (_a_.toLocaleLowerCase().indexOf("quarter") > -1 && _b_.toLocaleLowerCase().indexOf("quarter") > -1) {
            // THIS IS FOR QUATER SORTING

            const qtr__a = _a_.split(" ")[1];
            const qtr_b = _b_.split(" ")[1];
            const qtr__a_num = qtr__a && parseInt(qtr__a);
            const qtr_b_num = qtr_b && parseInt(qtr_b);
            return qtr__a_num - qtr_b_num;
        }

        s_index++;

        const a = _a_ && _a_.trim().toLocaleLowerCase();
        const b = _b_ && _b_.trim().toLocaleLowerCase();

        let keys = [a.toLocaleLowerCase(), b.toLocaleLowerCase()];

        // console.log("keys.... 1.1", s_index, '>>>>>>>', array_to_sort.length, 'entered in function for ==>', entered_index);
        var sort_priority = sort_priorities[keys[0] + "-" + keys[1]];
        
        const a1 = _a_.split('_nfx_')[0];
        const b1 = _b_.split('_nfx_')[0];


        if (data_type === dataTypes.date_month || is_date_month(_a_) || is_month(_a_)) {
            const new_a = Date.parse(1 + " " + a1)
            const new_b = Date.parse(1 + " " + b1)
            if (new_a > 0 && new_b > 0) {
                return new_a - new_b
            }
        }

        keys.sort();

        const sort_priority_1 = sort_priorities['*' + "-" + keys[1]];
        const sort_priority_2 = sort_priorities[keys[1] + "-" + '*'];

        const sort_priority_3 = sort_priorities['*' + "-" + keys[0]];
        const sort_priority_4 = sort_priorities[keys[0] + "-" + '*'];

        // if (where === "pivot") {
        //     console.log("sort_prioritiesv1", keys, sort_priorities, sort_priority, sort_priority_1, sort_priority_2, sort_priority_3, sort_priority_4)
        // }

        if (sort_priority) {
            // console.log('sort ====> ', _a_, _b_, sort_priority);
            if (sort_priority === a.toLocaleLowerCase()) {
                // console.log('compar 1: ', a, b, '===>', keys[0]);
                return -1;
            }
            else if (sort_priority === b.toLocaleLowerCase()) {
                // console.log('compar -1: ', a, b, '===>', keys[1]);
                return 1;
            }
            else {

                return 0;
            }
        }
        else if (sort_priority_1 || sort_priority_2) {
            // key[0] is star
            // console.log('sort_priority_1: ', sort_priority_1, sort_priority_2, keys);
            if (sort_priority_1 === '*' || sort_priority_2 === '*') {
                // whatever at the star, comes first
                // console.log('compar bombard: ', a, b, 'a - b', sort_priority_1 || sort_priority_2);

                if (keys[1] === a.toLocaleLowerCase()) {

                    // console.log('compar 1: ', a, b, '===>', keys[0]);
                    return 1;
                }
                else if (keys[1] === b.toLocaleLowerCase()) {
                    // console.log('compar -1: ', a, b, '===>', keys[1]);
                    return -1;
                }
                else {
                    // console.log('compar 0: ', a, b);

                    return 0;
                }

                return 1;
            }

            return -1;
        }
        else if (sort_priority_3 || sort_priority_4) {
            // console.log('sort_priority_1: ', sort_priority_3, sort_priority_4, keys);

            if (sort_priority_3 === '*' || sort_priority_4 === '*') {
                // whatever at the star, comes first
                // console.log('compar bombard: ', a, b, 'b - a', sort_priority_3 || sort_priority_4);

                if (keys[0] === a.toLocaleLowerCase()) {

                    // console.log('compar 1: ', a, b, '===>', keys[0]);
                    return 1;
                }
                else if (keys[0] === b.toLocaleLowerCase()) {
                    // console.log('compar -1: ', a, b, '===>', keys[1]);
                    return -1;
                }
                else {
                    // console.log('compar 0: ', a, b);

                    return 0;
                }

            }

            return 1;
        }
        else {
            return a.localeCompare(b);
        }
    });
};
