import styled from 'styled-components';
import { configs } from '../../theme/configs'

const { label } = configs

export const Wrapper = styled.div`
    width: 100%;

    .one_line_items {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    
        .batch_row {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
        }
        .batch {
            box-shadow: rgba(0,0,0,0.53) 0px 0px 2px 0px;
            padding: 5px 8px;
            font-size: .9rem;
            font-weight: normal;
            line-height: 1.286rem;
            letter-spacing: normal;
            text-align: center;
            color: rgb(26, 26, 26);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            margin-right: 16px;
            margin-bottom: 16px;
            color: ${label.color};

            .hover_element{
                opacity: 0;
                cursor: pointer;
            }
            &:hover{
               .hover_element{
                  opacity: 1;
               }
            }
            
        }
    }
    
    .icon_small {
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 1rem;
        right: 0px;
        font-size: 1rem;
        cursor: pointer;
        color: #727c80;
    
        &:hover {
          background: #c7c9cc;
        }
  }
  `