import React from 'react';
import styled from 'styled-components';
import { constants } from '../../utils/constants';


const Wrapper = styled.div`

    width: 400px;
    height: 200px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 6px;
    

    h1{
        font-size: 1.3rem;
        font-weight: 500;
        text-align: center;
    }

`;

const Loading = () => {

    return (
        <Wrapper>
            <img src={constants.CONTEXT_PATH + './loader.gif'} style={{ width: '110px', }} />
            <h1 style={{ marginTop: '1rem' }}>File Processing...</h1>
        </Wrapper>
    );
}

export default Loading;
