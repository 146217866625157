import React, { useState, useEffect, createRef } from 'react';
import styled from 'styled-components';
import RippleEffect from '../hoc/ripple-effect';
import CloseSvg from '../svg/close';
import TextBoxv1 from '../hoc/text.box.v1';
import { Button } from '../hoc/button';
import Delete from '../svg/delete'


const MainWrapper = styled.div`
    /* width: 27rem; */
    min-height: 10rem;
    max-height: 35rem;
    border-radius: 3px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 35rem;
    overflow-y: auto;
    margin-top: 10px;
    &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }

    .close{
        position: absolute;
        right: .5rem;
        top: .5rem;
    }

    h1{
        text-align: center;
        margin: 0px;
        font-size: 1.2rem;
        font-weight: 500;
        color: rgb(55, 74, 102);
        margin-bottom: 1rem;
        /* text-transform: capitalize; */
    }

    p{
        text-align: center;
    }

    table{
        width: 100%;
        td{
            padding: 10px 0px;
        }
    }
`;

const ParameterizedPopup = (props) => {

    const { close, popupKey, option, data } = props;
    const { parameters_fields, view_function, report_name, btn_text = "View Report" } = data;


    const [elRefs, setElRefs] = React.useState([]);


    const arrLength = parameters_fields?.length;

    React.useEffect(() => {
        // add or remove refs
        setElRefs((elRefs) =>
            Array(arrLength)
                .fill()
                .map((_, i) => elRefs[i] || createRef()),
        );
    }, [arrLength]);


    const [localFieldData, setLocalFieldData] = useState({})
    const [errors, set_errors] = useState({})

    useEffect(() => {
        const __ob__ = parameters_fields && parameters_fields.length > 0 && parameters_fields.reduce((a, v) => ({ ...a, [v]: "" }), {})
        setLocalFieldData(__ob__)
    }, [parameters_fields])


    const _on_change = (key, value) => {
        const clone_local_data = localFieldData ? Object.assign({}, localFieldData) : {};
        clone_local_data[key] = value;
        setLocalFieldData(clone_local_data)
    }


    const local_view = () => {
        const error = {};
        localFieldData && Object.keys(localFieldData).length > 0 && Object.keys(localFieldData).map((k) => {
            if (localFieldData[k] === '' || typeof localFieldData[k] === null) {
                error[k] = "Please Enter Value"
            }
        })
        set_errors(error)
        if (!error || Object.keys(error).length === 0) {
            view_function(localFieldData)
            close(option, popupKey)
        }
    }

    return (
        <MainWrapper>
            {/* <h1>please fill the below form{report_name}</h1> */}
            {/* <h1>Please fill out the below form</h1> */}
            <div style={{ width: '100%', marginBottom: '1rem' }}>
                {localFieldData && Object.keys(localFieldData).length > 0 && Object.keys(localFieldData).map((f, i) => {
                    return (
                        <div style={{ width: '100%', marginBottom: '.7rem' }}>
                            <TextBoxv1
                                ref_name={elRefs[i]}
                                label={f}
                                error={errors && errors[f]}
                                labelStyle={{
                                    textTransform: 'upperCase'
                                }}
                                value={localFieldData[f] ? localFieldData[f] : ''}
                                onChange={(e) => {
                                    _on_change(f, e.target.value)
                                }}
                                onKeyDown={(e) => {
                                    const key_code = e.keyCode;
                                    if (key_code === 13) {
                                        const el = elRefs[i + 1];
                                        if (el && el.current) {
                                            el.current.focus()
                                        }
                                    }
                                }}
                            />
                        </div>
                    )
                })}
            </div>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: 'auto',
                marginLeft: 'auto',
                paddingBottom: '5px'

            }}>
                <Button
                    primary={true}

                    onClick={() => {
                        // _save()
                        local_view()
                    }}
                    mR="1rem"
                // disabled={errors && Object.keys(errors).length > 0}
                >
                    {btn_text}
                </Button>
                <Button
                    onClick={() => {
                        close(option, popupKey)
                    }}
                    bgColor={'#e6e6e6'}
                >
                    Cancel
                </Button>
            </div>

        </MainWrapper>
    )
}

export default ParameterizedPopup;
