export const TABLE_INIT = 'modules/table/init';
export const SET_ACTIVE_DATA_SUCCESS = 'modules/activeData/success';
export const SET_DATA_SUCCESS = 'modules/data/success';
export const SET_FULL_DATA_SUCCESS = 'modules/fullData/success';
export const SET_EDITED_COLUMNS_SUCCESS = 'modules/editedColumns/success';
export const SET_COLUMNS_SUCCESS = 'modules/columns/success';
export const SET_BOTH_COLUMNS_SUCCESS = 'modules/columns/both/success';
export const CHANGE_INDEX_SUCCESS = 'modules/index/success';
export const CHANGE_PAGE_TYPE_SUCCESS = 'modules/changePageType/success';
export const SET_TABLE_STATE_SUCCESS = 'modules/tableState/success';
export const CLEAR_TABLE_SUCCESS = 'modules/table/clear/success';
export const LOAD_MORE_DATA_SUCCESS = 'modules/table/loadMore/success';
export const NEXT_DATA_SUCCESS = 'modules/changeData/next/success';
export const PREV_DATA_SUCCESS = 'modules/changeData/prev/success';
export const SET_SORTED_DATA_SUCCESS = 'moduled/table/SET_SORTED_DATA_SUCCESS';
export const SCROLL_PARENT_WINDOW_TO = 'modules/table/parent/scroll';
export const PUSH_SORT_DATA_TO_REDUCERS = 'modules/table/push_sort_data_to_reducer';
export const UPDATE_ACTIVE_TABLE_COLUMN = 'modules/table/update_active_table_column'
export const UPDATE_SORT_ORDERS = 'modules/table/update_sort_order';

export const UPDATE_WIDTH_ADDITIONS = 'modules/table/update_width_additions';
export const UPDATE_COLUMN_ORDER = 'modules/table/update_column_order'

export const RAISE_EXCEL_DOWNLOAD_REQUEST = 'modules/table/raise_excel_download_request';
