import React from 'react';
import { TableMainDiv, RowDiv, TdDiv, } from './tag-table.styled';
import EditSvg from '../../svg/edit';
import CloseSvg from '../../svg/close';
import DeleteSvg from '../../svg/delete';

import { showPopup } from '../../../utils';
import * as enums from '../../../utils/enums';
import ConfirmBox from '../../confirm-box';



const TagTable = (props) => {

    const { tagTables, activeTag, showEditTagPopup, _saveTag, deleteSingleTag } = props;
console.log('tagTables',tagTables)
    return (
        <TableMainDiv>
            <RowDiv
                hover={false}
                className="sticky_top" 
                justify="space-between" 
                style={{ paddingLeft: '5px', paddingBottom: '20px', paddingTop: '0px' }}>
                    
                <div className="tag_name">
                    {activeTag || ''}
                </div>

                <div 
                className="tag_action"
                >
                    <div title='Edit'
                        onClick={() => showEditTagPopup(activeTag)}>
                        <EditSvg
                            height="0.857rem"
                            size="0.857rem"
                        />
                        <span style={{
                            fontSize: '0.857rem'
                        }}>Edit Hints</span>
                    </div>


                    <div
                        title='delete'
                        onClick={() => {
                            showPopup(undefined, 'Are you sure you want to delete this ? ', enums.default.popupType.element, ConfirmBox, { func: { setYes: () => _saveTag(activeTag, true)} })
                        }}
                        
                    >
                        <DeleteSvg
                            height="0.857rem"
                            size="0.857rem"
                        />
                        <span style={{
                            fontSize: '0.857rem'
                        }}>Delete Hints</span>
                    </div>
                </div>
            </RowDiv>



            <RowDiv style={{  background: 'linear-gradient(to right, #3E9EFF, #737DFF)', borderRadius: '6px' }}>
                <TdDiv className="column" style={{ color: '#fff' }}>Table Name</TdDiv>
                <TdDiv className="column" style={{ color: '#fff' }}>Column Name</TdDiv>
                <div
                    className="remove_column"
                    title="remove"
                >
                    <CloseSvg size="0.9rem" height="0.9rem" color="transparent" />
                </div>
            </RowDiv>




            {
                tagTables.map((table, index) => {
                    return (
                        <RowDiv key={index}>
                            <TdDiv className="column">{table.tableName || table.table_name}</TdDiv>
                            <TdDiv className="column">{table.columnName || table.column_name}</TdDiv>
                            <div
                                className="remove_column"
                                title="remove"
                                onClick={() => {
                                    showPopup(undefined, 'Are you sure you want to delete this ? ', enums.default.popupType.element, ConfirmBox, { func: { setYes: () => deleteSingleTag(table, index) } })
                                }}
                            >
                                <CloseSvg size="1rem" height="1rem" />
                            </div>
                        </RowDiv>
                    )
                })
            }

        </TableMainDiv>
    )
}

export default TagTable;
