import React from 'react'
import Skeleton from 'react-loading-skeleton';
import './skeletion.css'


export default function DashboardListSkeleton({width, height, count, isHeader,}) {
    return (
        <div className='wrapper'>
            <div className='flex' style={{ marginBottom: '17px', marginTop: '29px' }}>
                <Skeleton width={310} height={46} borderRadius={40} className='center' />
                <Skeleton width={25} height={30} className='right' />
            </div>
            <Skeleton width={width || 300} height={height || 200} count={count || 20} containerClassName='grid' className='margin' />
        
        </div>
    )
}
