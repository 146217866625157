import React from 'react';


const WaterFallChart = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1728 1728"
            {...props}
            space="preserve">

<path transform="translate(147,144)" d="m0 0h105l4 2v80l-1 1294h1330l5 2 1 2v98l-3 3-3 1-1356 1h-79l-2-3-1-12-1-44v-1423z" fill="#2774FD"/>
<path transform="translate(454,776)" d="m0 0h24l33 1 11 2 4 2 3 9 1 6 1 21v422l-1 58-2 25-3 5-6 2-27 1-53 1h-30l-9-2-4-4-4-16-1-6-1-16v-452l1-34 3-15 3-6 5-2 12-1z" fill="#649DFC"/>
<path transform="translate(639,727)" d="m0 0h56l22 2 11 4 8 7 6 13 1 5v357l-2 9-6 9-10 6-16 3-24 1h-50l-13-2-7-4-6-7-6-14-1-11v-341l2-12 7-9 7-7 9-6 5-2z" fill="#6FEFFE"/>
<path transform="translate(1121,572)" d="m0 0h69l16 2 7 3 6 8 3 6 1 4v356l-2 13-6 12-7 6-13 4-18 1h-49l-21-2-9-5-6-7-4-10-1-4-1-15-1-91v-95l1-92 1-55 1-19 3-12 6-5 10-2z" fill="#6FEFFE"/>
<path transform="translate(896,849)" d="m0 0h87l10 2 5 6 3 6 1 7 1 62v268l-1 41-3 8-5 6-9 5-4 1-26 1h-58l-13-2-8-7-6-10-3-16-1-20v-288l1-45 4-10 7-9 8-5z" fill="#649DFC"/>
<path transform="translate(1303,428)" d="m0 0h57l25 2 12 3 3 3 2 6 1 48v210l-1 66-3 5-5 4-7 2-8 1-46 1h-21l-20-1-13-2-6-4-5-10-2-12v-251l1-44 2-11 3-6 5-5 6-3z" fill="#659DFC"/>
<path transform="translate(26,7)" d="m0 0h1l1 108 1 435v1170l85 1 65 1 135 1h1241v1l-10 1-25 1-2 2h-1306l-5-1h-9-2l-3 1v-2l-6 1v-1l-12-1-2 2h-7l2-2h-141v2l-3-1-3 1v-1698l1-8h2l1-9zm-1 1713m147 4m-1 1m51 0m160 0m1125 0v1h9v-1z" fill="#969399"/>
<path transform="translate(26,7)" d="m0 0h1l1 108 1 435v283l-1 883h-1l-2-68-1-78-1-1261 1-203 1-92z" fill="#FDFDFD"/>
<path transform="translate(24,18)" d="m0 0 2 1-1 87-1 229 1 1235 1 78 2 68 1 4 85 1 53 1 5 1v4h-6l2-2h-141v2l-3-1-3 1v-1698l1-8h2z" fill="#97959A"/>
<path transform="translate(253,145)" d="m0 0 3 1v80l-1 1294h159v1l-88 1-45 1h-25l-2-2-1-83-1-773v-257z" fill="#FEFEFE"/>
<path transform="translate(1423,1723)" d="m0 0h132v1l-10 1-25 1-2 2h-558l-8-1 1-3zm84 2v1h9v-1z" fill="#97959A"/>
<path transform="translate(1091,580)" d="m0 0h1v53l-2 109-1 146-1 71h-1l-1-15-1-91v-95l1-92 1-55 1-19z" fill="#FEFEFE"/>
<path transform="translate(24,18)" d="m0 0 2 1-1 87-2 417h-1l-1-179v-315l1-8h2z" fill="#9092A1"/>
<path transform="translate(174,1722)" d="m0 0 140 1 99 1 17 1-1 3h-217l-5-1h-9-2l-3 1v-2l-6 1v-1l-12-1-2 2-1-4z" fill="#98959A"/>
<path transform="translate(23,1563)" d="m0 0h1l1 7 1 78 2 68 1 4 85 1 53 1 5 1v4h-6l2-2h-141v2l-3-1-3 1 1-163z" fill="#98959A"/>
<path transform="translate(364,1724)" d="m0 0h49l17 1-1 3h-170v-2l16-1h6l3 1 6-1h55z" fill="#A19FA1"/>
<path transform="translate(413,1724)" d="m0 0h87v2l-4 2h-67l-2-2-14-1z" fill="#969399"/>
<path transform="translate(500,1724)" d="m0 0h54l-2 4h-56l4-2z" fill="#97949A"/>
<path transform="translate(376,1724)" d="m0 0h37l17 1-1 3h-54z" fill="#A09EA0"/>
<path transform="translate(372,4)" d="m0 0h159v1h-159z" fill="#9A989C"/>
<path transform="translate(413,1724)" d="m0 0h34l6 1-3 2-2 1h-19l-2-2-14-1z" fill="#99969B"/>
<path transform="translate(39,4)" d="m0 0h26l58 1v1h-85z" fill="#9E9C9F"/>
<path transform="translate(1506,1725)" d="m0 0h12l2 2-2 1h-59l4-2h37z" fill="#A2A0A2"/>
<path transform="translate(489,1724)" d="m0 0h11v2l-4 2h-17v-3h7l3 1z" fill="#9B989C"/>
<path transform="translate(268,1725)" d="m0 0h13l3 3h-25v-2z" fill="#A2A0A2"/>
<path transform="translate(457,1725)" d="m0 0h17l-2 1v2h-15z" fill="#9F9DA0"/>
<path transform="translate(1720,1636)" d="m0 0h1v43h-1z" fill="#99969B"/>
<path transform="translate(1521,1726)" d="m0 0h22l-4 2h-19z" fill="#A2A0A2"/>
<path transform="translate(1556,1723)" d="m0 0h29v1l-20 1-9-1z" fill="#969399"/>
<path transform="translate(432,1725)" d="m0 0h10l-1 3h-10z" fill="#A2A0A2"/>
<path transform="translate(1555,1726)" d="m0 0h19l-4 2h-14z" fill="#A2A0A2"/>
<path transform="translate(353,4)" d="m0 0h16v1h-16z" fill="#A2A0A2"/>
<path transform="translate(1544,1726)" d="m0 0h10v1l-10 1z" fill="#A2A0A2"/>
<path transform="translate(136,1726)" d="m0 0h14v1h-14z" fill="#A2A0A2"/>
<path transform="translate(1685,1723)" d="m0 0h11v1h-11z" fill="#A2A0A2"/>
<path transform="translate(160,1725)" d="m0 0 1 2h-9v-1z" fill="#A2A0A2"/>
<path transform="translate(31,4)" d="m0 0h6v1h-6z" fill="#969399"/>
<path transform="translate(75,3)" d="m0 0h6v1h-6z" fill="#A2A0A2"/>
<path transform="translate(68,3)" d="m0 0h6v1h-6z" fill="#A2A0A2"/>
<path transform="translate(1581,1726)" d="m0 0h5v1h-5z" fill="#A2A0A2"/>
<path transform="translate(532,4)" d="m0 0h5v1h-5z" fill="#A2A0A2"/>
<path transform="translate(345,4)" d="m0 0 4 1z" fill="#A2A0A2"/>
<path transform="translate(27,4)" d="m0 0 3 1z" fill="#969399"/>
<path transform="translate(185,1726)" d="m0 0" fill="#A2A0A2"/>
<path transform="translate(23,1727)" d="m0 0 2 1z" fill="#969399"/>
<path transform="translate(1594,1726)" d="m0 0 2 1z" fill="#A2A0A2"/>
<path transform="translate(1712,1724)" d="m0 0 2 1z" fill="#A2A0A2"/>
<path transform="translate(1586,1723)" d="m0 0 2 1z" fill="#969399"/>
<path transform="translate(23,16)" d="m0 0" fill="#969399"/>
<path transform="translate(65,3)" d="m0 0 2 1z" fill="#A2A0A2"/>
<path transform="translate(1719,1727)" d="m0 0" fill="#A2A0A2"/>
<path transform="translate(183,1726)" d="m0 0" fill="#A2A0A2"/>
<path transform="translate(179,1726)" d="m0 0" fill="#A2A0A2"/>
<path transform="translate(1675,1723)" d="m0 0" fill="#A2A0A2"/>
<path transform="translate(1606,1723)" d="m0 0" fill="#969399"/>
<path transform="translate(1595,1723)" d="m0 0" fill="#969399"/>
<path transform="translate(24,14)" d="m0 0" fill="#969399"/>
<path transform="translate(26,5)" d="m0 0" fill="#969399"/>
<path transform="translate(88,3)" d="m0 0" fill="#A2A0A2"/>
<path transform="translate(85,3)" d="m0 0" fill="#A2A0A2"/>
<path transform="translate(83,3)" d="m0 0" fill="#A2A0A2"/>
<path transform="translate(49,2)" d="m0 0" fill="#969399"/>
        </svg>
    )

}


export default WaterFallChart;