import React, { PureComponent, useState } from 'react';
import { LabelList, Line, Area, ComposedChart, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, Cell, ResponsiveContainer, Legend, Label } from 'recharts';
import { withRouter } from 'react-router-dom';
import CustomToolTip from '../tooltip/tooltip.custom';
import CustomizedAxisTick from '../tick';
import { getRemToPx, yDataValueFormatter, splitByNf, formatValueByDataType, apply_styles, get_data_by_report, remove_repetitive_keys, returnRequiredState } from '../../../utils';
import { constants } from '../../../utils/constants';
import { DataFormaterFixed } from '../yaxis-label';
import enums from '../../../utils/enums';
import { showPopup } from '../../../utils';
import ChartFilterPopup from '../../chart-filter-popup'
import RenderLegend from '../legend/index.new'
import DrillDownPopup from '../../chart-filter-popup/drill.down.popup'
import ToolTipWrapper from '../tooltip/tool.tip.wrapper';
import {CHART_TYPES} from '../../../shared-with-fe/constants';
import { Component } from 'react';
import { max, round } from 'lodash';
import * as DataTypes from '../../../shared-with-fe/data.types';
import ReferenceLineLabel from '../label/referenceline.label'


class HorizontalBarChart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedBar: undefined,
            toolTipData: undefined,
            active_bar: undefined,
            multiAxisColorkey: props.xDataKeys[0],
            bar: this.props.yDataKeys,
            xDataKeys: [],
            barColors: [],
            data: props.data,
            xAxisMediansData: [],
            axis_settings: {
                color_preferences: {},
                uniques: {},
                should_custom_color_be_used: false,
                axis_tick_styles: {},
                x_axises: []
            }
        }
    }


    static getDerivedStateFromProps(props, state) {
        const hoist_the_state = () => {
            let counter = 0;
            // when we have two x axis, we will color the axises together to form a consistency and differentiation
            const x_data_keys = props.xDataKeys;
            const chart_style = props.chartStyle;
            const x_axis_style = chart_style && chart_style.axis && chart_style.axis.x;

            if (x_data_keys && x_data_keys.length > 1) {
                const axis_tick_styles = {};
                const axis_settings = Object.assign({}, state.axis_settings);
                const color_preferences = {};

                // if we are having two axis, 
                const data_rows = JSON.parse(JSON.stringify(props.data));
                const uniques = {};

                let color_to_use = 'alternate';

                data_rows.forEach((row, index) => {
                    if (!uniques[row[x_data_keys[0]]]) {
                        uniques[row[x_data_keys[0]]] = 1;

                        // new item found for row zero

                        if (color_to_use === 'alternate') color_to_use = 'main'
                        else color_to_use = 'alternate';

                        color_preferences[row[x_data_keys[0]]] = color_to_use;
                    }

                    uniques[row[x_data_keys[0]]]++;


                    if (x_axis_style) {
                        const x_axis_tick_style = apply_styles((x_axis_style && x_axis_style['tick']), constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT)

                        const tick_color_main = x_axis_style && x_axis_style.tick && x_axis_style.tick.font && x_axis_style.tick.font.color;
                        const tick_color_alternate = x_axis_style && x_axis_style.tick && x_axis_style.tick.font && x_axis_style.tick.font.alternate;

                        const effective_color = (color_to_use === 'main' ? tick_color_main : tick_color_alternate) || '#000000'
                        const effective_transparency = (color_to_use === 'main' ? 0 : 65);
                        const effective_bold = color_to_use === 'main' ? 400 : 600;
                        const tick_font = x_axis_style && x_axis_style.tick && x_axis_style.tick.font && x_axis_style.tick.font.size;

                        if (x_axis_tick_style) {
                            axis_tick_styles[index] = { ...x_axis_tick_style };
                        }

                        if (!axis_tick_styles[index]) {
                            axis_tick_styles[index] = {};
                        }

                        axis_tick_styles[index]['fill'] = effective_color;
                        axis_tick_styles[index]['transparency'] = effective_transparency;
                        axis_tick_styles[index]['fontWeight'] = effective_bold;
                    }

                    // uniques[row[x_data_keys[0]]][row[x_data_keys[1]]] = 1;
                });


                axis_settings.color_preferences = color_preferences;
                axis_settings.uniques = uniques;
                axis_settings.axis_tick_styles = axis_tick_styles;


                return axis_settings;
            } else {
                const blank_setting = {
                    color_preferences: {},
                    uniques: {},
                    should_custom_color_be_used: false,
                    axis_tick_styles: {},
                    x_axises: []
                }
                return blank_setting
            }
        };

        const xac_from_props = props && props.xDataKeys;
        const xac_from_state = state && state.xDataKeys;

        let axis_settings = undefined;

        if (
            (!(xac_from_state && xac_from_state.length > 0)) ||
            (xac_from_props && xac_from_props.length !== xac_from_state.length)) {
            axis_settings = hoist_the_state();
        }
        else if (xac_from_props && xac_from_state && xac_from_props.length === xac_from_state.length && xac_from_state.length > 0) {
            let all_same = true;

            xac_from_props.forEach((x_p, index) => {
                all_same = all_same && x_p === xac_from_state[index];
            });

            if (!all_same) axis_settings = hoist_the_state();
        }


        if (axis_settings) {
            return {
                ...state,
                axis_settings
            }
        }
        else {
            return state;
        }
    };


    renderLabel = (entry) => {
        const { x, y, width, value } = entry;
        const radius = 10;
        let aligment = this.props.Alignments && this.props.Alignments[this.props.yDataKeys && this.props.yDataKeys[0]];
        let type = (aligment && aligment.type);
        let formatted_value = formatValueByDataType(value, type, undefined, undefined, undefined, "insights");

        return (
            <g>
                {/* <circle cx={x + width} cy={y - radius} r={width} fill="#8884d8" /> */}
                <text
                    x={x + width / 2}
                    y={y - radius}
                    fill="#333"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontSize="10px"
                >
                    {formatted_value}
                </text>
            </g>
        );

    }

    _openPopup = (dimension, dimension_value, comparison, comparison_value, info_wrapper) => {
        showPopup(undefined, undefined, enums.popupType.element, ChartFilterPopup, {
            question: this.props.question,
            dimension, dimension_value, comparison, comparison_value,
            drill_down_values: this.props.drill_down_values,
            ...info_wrapper,
            b_history: this.props.history,
            _id: this.props.id
        })
    }


    _open_drill_down_popup = (dimension, dimension_value, comparison, comparison_value, drill_down_report, history) => {

        showPopup(undefined, undefined, enums.popupType.element, DrillDownPopup, {
            id: this.props.id,
            drill_down_report: drill_down_report,
            history: history,
            question: this.props.question,
            dimension,
            dimension_value,
            comparison,
            comparison_value
        })
    }


    render() {
        const {
            yac_chart_types, showDash, colorForYData, yac_axises,
            yDataKeys, paddingOnXaxis, xDataKeys,
            showReferenceLine, valueOfReferenceLine,
            showStacked, heightOfChart, widthOfChart,
            id, insights, isDimension, isDiscovery,
            hide_y_axis, hide_x_axis, hide_y_axis_border, hide_x_axis_border, hide_grid, hide_legend, hide_tooltip,
            hide_x_axis_label, hide_y_axis_label, showRightYAxis, Alignments, chartStyle, drill_down_type,
            drill_down_report, tooltipTheme, drill_down_values, comparisons, show_data_label,
            data, isReport, chartType,
        } = this.props;



        // these are the new props
        const { aggregate_options, yac_totals } = this.props

        let tick_counter_for_secondary_x_axis = 0;

        const local_x_data_keys = xDataKeys;
        const local_y_data_keys = yDataKeys && yDataKeys.filter(f => f.indexOf('Total_nfx_') === -1 && f.indexOf('% Share_nfx_') === -1);

        const different_colors_for_primary = true;

        const widthTemp = widthOfChart && isDimension ? (widthOfChart - 0) : widthOfChart ? (widthOfChart - 0) : '93.1vw';

        const xAxisInterval = 0;

        let chartMargin = {
            top: !hide_x_axis_label ? isDimension ? 0 : (insights || isDiscovery) ? 20 : 50 : heightOfChart < 100 ? 0 : 10,
            right: !hide_y_axis_label ? isDimension ? getRemToPx(1.25) : (insights || isDiscovery) ? getRemToPx(2.5) : getRemToPx(9.375) : 10,
            bottom: isDimension ? 0 : ((hide_x_axis_label && hide_x_axis) ? 0 : hide_x_axis_label ? 0 : hide_x_axis ? 20 : !insights ? (!isDiscovery ? 70 : 20) : 15),
            left: !hide_y_axis_label ? isDimension ? 0 : (insights || isDiscovery) ? getRemToPx(2.5) : getRemToPx(9.375) : 0
        };

        let yAxisLabelOffset = isDimension ? getRemToPx(1.25) : (insights || isDiscovery) ? getRemToPx(-1.5) : getRemToPx(-2.5);
        let xAxisLabelOffset = isDimension ? 7 : (insights || isDiscovery) ? -5 : -20;
        let heightOfXAxis = isDimension ? undefined : ((hide_x_axis_label && hide_x_axis) ? 7 : hide_x_axis_label ? getRemToPx(5.5) : hide_x_axis ? 20 : getRemToPx(insights ? 6 : 7));
        let widthOfYAxis = !hide_y_axis ? getRemToPx(4.1) : 5;

        const xAxisStyle = chartStyle.axis && chartStyle.axis.x;
        const yAxisStyle = chartStyle.axis && chartStyle.axis.y;
        const xAxisTickStyle = apply_styles((xAxisStyle && xAxisStyle['tick']), constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT)
        const yAxisTickStyle = apply_styles((yAxisStyle && yAxisStyle['tick']), constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT)
        const xAxisTitleStyle = apply_styles((xAxisStyle && xAxisStyle['title']), constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT)
        const yAxisTitleStyle = apply_styles((yAxisStyle && yAxisStyle['title']), constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT)
        const legendStyle = chartStyle && chartStyle.legend;


        //global vars
        const multiAxisCondition = local_x_data_keys[local_x_data_keys.length - 2];
        const secondary_available = (yac_axises || []).filter(y => y === 'secondary').length > 0;

        let min_x = 0;
        let max_x = 0;

        let prev_axis_value = undefined;

        const axises_info_from_first_axis = {};

        let previous = {
            x: -1,
            value: undefined,

            min_x_from_first: -1,
            max_x_from_first: -1,
        };

        let max_words_allowed = 0;

        const render_tick = (first_axis) => (props) => {
            const { x, y, payload, index, width } = props;

            // console.log('render-tick props', this.state.axis_settings.axis_tick_styles[index]);

            const total_ticks = this.state.axis_settings.uniques[payload.value];

            if (!first_axis) {
                if (max_words_allowed === 0) {
                    const total_items = Object.keys(this.state.axis_settings.uniques).length;

                    const x_allowed = previous.max_x_from_first - previous.min_x_from_first;

                    max_words_allowed = parseInt(x_allowed / total_items / 13);


                    if (max_words_allowed < 4) max_words_allowed = 4
                }

                const axis_tick_style = this.state.axis_settings.axis_tick_styles[index - 1] || xAxisTickStyle;

                if (prev_axis_value !== payload.value || (index === this.props.data.length - 1)) {
                    if (prev_axis_value) {
                        // second time

                        max_x = previous.x;

                        if (max_x < min_x) max_x = axises_info_from_first_axis[index].x - axises_info_from_first_axis[index].width;

                        // const x_position = min_x + (0.1 * (max_x - min_x));
                        // const value =  ((prev_axis_value) && (prev_axis_value.length > 8 ? prev_axis_value.substring(0, 8) + '..' : prev_axis_value));
                        const value = ((prev_axis_value && prev_axis_value.length) > max_words_allowed) ? prev_axis_value.substring(0, max_words_allowed) + '..' : prev_axis_value;
                        const x_position = (((max_x + min_x) / 2));

                        prev_axis_value = payload.value;

                        const tag = `tick ${payload.value} <> ${prev_axis_value} === ${payload.value === prev_axis_value}, min: ${min_x}, max: ${max_x} and calc: ${x_position} and this_x ===> ${x} `;

                        min_x = axises_info_from_first_axis[index]?.x - axises_info_from_first_axis[index]?.width;

                        if ((index === this.props.data.length - 1)) prev_axis_value = undefined;


                        return <g>
                            <text
                                // onClick={() => alert(tag)}
                                style={{ ...axis_tick_style, fontSize: '0.95rem' }}
                                // x={x_position} y={y} textAnchor='left'>{value}</text>
                                x={60} y={y} textAnchor='left'>{value}</text>


                            {/* <text
                        style={{ ...axis_tick_style, fontSize: '0.95rem' }}
                        x={x} y={y+30} textAnchor='middle'>{round(x)}</text> */}
                        </g>
                        // return <text
                        //     style={{ ...axis_tick_style, fontSize: '0.95rem' }}
                        //     x={x_position} y={y} textAnchor='middle'>{value}</text>
                    }
                    else {

                        prev_axis_value = payload.value;
                        previous.x = axises_info_from_first_axis[index]?.x - axises_info_from_first_axis[index]?.width;
                        min_x = previous.x;

                        return null;

                        return <text
                            style={{ ...axis_tick_style, fontSize: '0.95rem' }}
                            x={x} y={y + 30} textAnchor='middle'>{round(x)}</text>
                    }
                }
                else {

                    prev_axis_value = payload.value;
                    previous.x = axises_info_from_first_axis[index]?.x - axises_info_from_first_axis[index]?.width;

                    return null;
                    return <text
                        style={{ ...axis_tick_style, fontSize: '0.95rem' }}
                        x={x} y={y + 30} textAnchor='middle'>{round(x)}</text>
                }
            }

            const axis_tick_style = this.state.axis_settings.axis_tick_styles[index] || xAxisTickStyle;
            let aligment = Alignments && xDataKeys && xDataKeys.length > 0 && xDataKeys && xDataKeys.length > 0 && Alignments[xDataKeys[xDataKeys.length - 1]];
            let _type = aligment && aligment.type;
            const _value = (_type && (_type === DataTypes.date || _type === DataTypes.date_time)) ? formatValueByDataType(payload.value, _type) : payload.value;
            let _word = (_value && _value.length) > 8 ? _value.substring(0, 8) + ".." : _value;

            axises_info_from_first_axis[index] = { x, width: (_word || '').length * 6 };


            if (previous.min_x_from_first === -1) {
                previous.min_x_from_first = x;
            }

            previous.max_x_from_first = x;

            const xAxisTick = !hide_x_axis ?
                <CustomizedAxisTick
                    {...props}
                    xDataKeys={local_x_data_keys}
                    Alignments={Alignments}
                    totalTick={this.props.data.length}
                    _style={axis_tick_style} /> : null;

            return xAxisTick;
        }

        //MA code End--->>>

        const all_yacs_with_pivot_info = this.props.pivot_yac_info || [];
        const primaries_header = all_yacs_with_pivot_info.filter(f => f.axis === 'primary' && f.visible_type < 3).map(m => m.pivot_field_alias).join(',');
        const secondaries_header = all_yacs_with_pivot_info.filter(f => f.axis === 'secondary' && f.visible_type < 3).map(m => m.pivot_field_alias).join(',');


           /**
         * new version of code 
         * 20 june 2022
         */


            const is_multi_x_axis = local_x_data_keys && local_x_data_keys.length > 1;
            const x_axis_height_v1 = (hide_x_axis && hide_x_axis_label) ? 0 : is_multi_x_axis ? 30 : (hide_x_axis || hide_x_axis_label) ? 20 : 60;
            const x_axis_height_v2 = (hide_x_axis && hide_x_axis_label) ? 0 : (hide_x_axis || hide_x_axis_label) ? 20 : 60;
            const width_of_y_axis_v1 = (hide_y_axis && hide_y_axis_label) ? 0 : !hide_y_axis ? getRemToPx(4.5) : 20;
    


        return (
            <div id={id ? id : 'chart'} style={{ position: 'relative', width: widthTemp, textTransform: 'capitalize' }}>
                <ResponsiveContainer width='100%' height={isDimension ? heightOfChart : heightOfChart}>
                    <ComposedChart
                        data={this.props.data}
                        margin={{
                            top: 10,
                            left: 30,
                            right: 30,
                            bottom: 10
                        }}
                        layout="vertical"
                    >
                        {!hide_grid && !isDimension && (
                            <CartesianGrid strokeDasharray="2 1" vertical={true} horizontal={false} />
                        )}

                        <XAxis
                            type="number"
                            orientation="bottom"
                            xAxisId="bottom"
                            // primary axis this one is
                            height={heightOfXAxis - 30}
                            angle='0'
                            position="insideBottom"
                            style={xAxisTickStyle}
                            tickFormatter={DataFormaterFixed}
                            axisLine={!hide_x_axis_border ? true : false}
                            interval={xAxisInterval}

                        >

                            {!hide_x_axis_label && local_x_data_keys && local_x_data_keys.length > 0 &&
                                <Label
                                    className="y_axis_label"
                                    angle='0'
                                    position='inside'
                                    // offset={xAxisLabelOffset}
                                    style={xAxisTitleStyle}>
                                    {splitByNf(yDataValueFormatter(primaries_header))}
                                </Label>
                            }
                        </XAxis>


                        {(showRightYAxis || secondary_available) && (
                            <XAxis
                                xAxisId="top"
                                orientation="top"
                                angle='0'
                                style={xAxisTickStyle}
                                height={heightOfXAxis - 70}
                                // tickFormatter={DataFormaterFixed}
                                axisLine={!hide_x_axis_border ? true : false}
                                interval={xAxisInterval}
                                type="number"
                            >

                                {!hide_y_axis_label &&
                                    <Label
                                        className="y_axis_label"
                                        angle='0'
                                        position='insideTop'
                                        // offset={xAxisLabelOffset}
                                        style={xAxisTitleStyle}
                                    >
                                        {splitByNf(yDataValueFormatter(secondaries_header))}
                                    </Label>
                                }
                            </XAxis>
                        )}


                        <YAxis
                            dataKey={local_x_data_keys && local_x_data_keys.length > 0 && local_x_data_keys[local_x_data_keys.length - 1]}
                            first_axis={true}
                            padding={{ bottom: 8, top: 10 }}
                            angle='0'
                            // tick={render_tick(true)}
                            // interval={xAxisInterval}
                            style={yAxisTickStyle}
                            tick={!hide_y_axis && !isDimension ? true : false}
                            width={width_of_y_axis_v1}
                            type="category"

                        />

                        {!hide_y_axis_label &&
                            <YAxis
                                dataKey={local_x_data_keys && local_x_data_keys.length > 0 && (multiAxisCondition || [])}
                                style={yAxisTickStyle}
                                width={width_of_y_axis_v1}
                                // tick={render_tick(false)}
                                interval={xAxisInterval}
                                orientation="left"
                                axisLine={false}
                                tickLine={false}
                                yAxisId="left"
                                type="category"
                            >
                                {!hide_x_axis_label &&
                                    <Label
                                        className="y_axis_label"
                                        angle='-90'
                                        position='insideLeft'
                                        // offset={yAxisLabelOffset - 70}
                                        style={yAxisTitleStyle}
                                    >
                                        {local_x_data_keys && local_x_data_keys.length > 0 && splitByNf(local_x_data_keys.join(' > '))}
                                    </Label>
                                }

                            </YAxis>}


                        {!hide_tooltip && <ToolTipWrapper cursor={false} active_bar={this.state.active_bar} local_x_data_keys={local_x_data_keys} local_y_data_keys={local_y_data_keys} Alignments={Alignments} tooltipTheme={tooltipTheme} />}

                        {
                            colorForYData && local_y_data_keys && local_y_data_keys.length > 0 && local_y_data_keys.map((data, index) => {
                                const on_secondary = yac_axises[index] === 'secondary';

                                if (yac_chart_types && (yac_chart_types[index] === CHART_TYPES.bar_chart || yac_chart_types[index] === CHART_TYPES.stacked_bar_chart || yac_chart_types[index] === CHART_TYPES.horizontal_bar_chart)) {

                                    return <Bar
                                        key={data}
                                        xAxisId={on_secondary ? 'top' : 'bottom'}
                                        dataKey={data}
                                        stackId={(chartType === CHART_TYPES.stacked_bar_chart) && 'karma'}
                                        maxBarSize={100}
                                        fill={colorForYData[index]}

                                    >
                                        {
                                            colorForYData && this.props.data && this.props.data.length > 0 && this.props.data.map((entry, _index) => {
                                                const opacity = (this.state.axis_settings.axis_tick_styles[_index] && this.state.axis_settings.axis_tick_styles[_index].transparency) || 100;

                                                return (
                                                    <Cell
                                                        style={{ cursor: 'pointer' }}
                                                        key={`cell-${_index}`}
                                                        fill={colorForYData[index]}
                                                        opacity={`${opacity}%`}
                                                        onClick={(ev) => {
                                                            // /**
                                                            //  * two cases: 1. with nfx, and without nfx
                                                            //  */
                                                            // const fact = data;
                                                            // const dimension = local_x_data_keys[0];


                                                            // let current_key = entry[dimension];
                                                            // let comparison = undefined;
                                                            // let comparison_value = undefined;

                                                            // if (xDataKeys && xDataKeys.length > 1) {
                                                            //     comparison = local_x_data_keys[1];
                                                            //     comparison_value = entry[comparison]
                                                            // }

                                                            // /** we are removing comparison, but keeping the block to be safe if we need it in future */
                                                            // if (this.state.active_bar.indexOf('_nfx_') > -1) {
                                                            //     comparison = this.props.comparisons && this.props.comparisons[0];
                                                            //     comparison_value = this.state.active_bar.split('_nfx_')[0];
                                                            // }


                                                            // if (drill_down_report && drill_down_type) {
                                                            //     // this._open_drill_down_popup(dimension, dimension_value, comparison, comparison_value, drill_down_report, this.props.history);

                                                            //     // get_data_by_report(drill_down_report, local_x_data_keys, current_key, this.props.history)
                                                            // } else {
                                                            //     this._openPopup(dimension, current_key, comparison, comparison_value, {
                                                            //         dimension,
                                                            //         fact
                                                            //     });
                                                            // }

                                                        }}


                                                        onMouseOver={(e) => {
                                                            this.setState({
                                                                active_bar: data
                                                            });
                                                        }}
                                                        onMouseOut={(e) => {
                                                            this.setState({
                                                                active_bar: undefined
                                                            });
                                                        }}

                                                    />
                                                )
                                            })
                                        }
                                        {show_data_label && <LabelList dataKey={data} position="top" content={this.renderLabel} />}
                                    </Bar>
                                }

                                const color_of_axis = data.toLowerCase().indexOf('projection') > -1 ? 'red' : colorForYData[index];
                                const show_dash = showDash || data.toLowerCase().indexOf('projection') > -1;

                                if (yac_chart_types && yac_chart_types[index] === CHART_TYPES.line_chart) {
                                    return <Line
                                        xAxisId={on_secondary ? "top" : "bottom"}
                                        key={index}
                                        type="monotone"
                                        dataKey={data}
                                        dot={false}
                                        stroke={color_of_axis}
                                        strokeDasharray={show_dash ? "5 5" : ''}
                                        strokeOpacity="1"
                                        strokeWidth="1.9"
                                        label={show_data_label}
                                    >
                                        {isReport && <LabelList dataKey={data} style={{ fontSize: '.8rem', fontWeight: 'bold' }} position="top" />}
                                    </Line>
                                }

                                if (yac_chart_types && yac_chart_types[index] === CHART_TYPES.area_chart) {

                                    return <Area
                                        xAxisId={on_secondary ? "top" : "bottom"}
                                        key={index}
                                        type="monotone"
                                        dataKey={data}
                                        stackId="a"
                                        stroke={colorForYData[index]}
                                        fill={colorForYData[index]}
                                        label={show_data_label}
                                        onMouseOver={(e) => {
                                            this.setState({ active_bar: data })

                                        }}
                                        onMouseOut={(e) => {
                                            this.setState({ active_bar: data })
                                        }}
                                    >
                                        {isReport && <LabelList dataKey={data} style={{ fontSize: '.8rem', fontWeight: 'bold' }} position="top" />}
                                    </Area>
                                }

                            })
                        }



                        {local_y_data_keys && local_y_data_keys.length > 0 && local_y_data_keys.map((k, index) => {
                            const on_secondary = yac_axises[index] === 'secondary';
                            return (
                                aggregate_options && Object.keys(aggregate_options).length > 0 && Object.keys(aggregate_options).map((a) => {
                                    if (aggregate_options[a]) {
                                        const label = (a === 'is_st_dev_checked') ? 'sd' : a?.split('_')?.[1]
                                        return (
                                            <ReferenceLine
                                                // x={yac_totals?.[k]?.[`agg_${label}`]}
                                                y={yac_totals?.[k]?.[`agg_${label}`]}
                                                stroke={colorForYData[index]}
                                                label={<ReferenceLineLabel index={index} label={k + ' ' + label} vertical={true} />}
                                                xAxisId={on_secondary ? "top" : "bottom"}
                                                stockWidth="2"
                                                style={{ strokeDasharray: '4', font: '.1rem' }} />
                                        )
                                    } else return null
                                })
                            )
                        })}


                        {!hide_legend && <Legend content={<RenderLegend payload={data} legendStyle={legendStyle} hello="Saroj" />} verticalAlign="top" height={40} />}

                    </ComposedChart>
                </ResponsiveContainer>

            </div>
        );
    }
}




export default withRouter(HorizontalBarChart);