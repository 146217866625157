import React, { useState, useEffect } from "react";
import {
    ResponsiveContainer,
    FunnelChart,
    Funnel,
    Tooltip,
    LabelList,
    Cell,
} from "recharts";
import { withRouter } from "react-router-dom";
import {
    apply_styles,
    formatValueByDataType,
    getRemToPx,
} from "../../../utils";
import CustomToolTip from "../tooltip/tooltip.custom";
import { connect } from "react-redux";
import { constants } from "../../../utils/constants";

const SimpleFunnelChart = (props) => {
    const {
        yDataKeys,
        data,
        widthOfChart,
        heightOfChart,
        xDataKeys,
        Alignments,
        hide_tooltip,
        tooltipTheme,
        chartType,
        show_data_label,
        colorForYData,
        hide_x_axis_secondry,
        chartStyle
    } = props;
    const yKey = yDataKeys[0];
    const xKey = xDataKeys[0];

    const labelStyle = chartStyle?.data_label?.font;

    const font_size = labelStyle?.size?.includes("rem") ? getRemToPx(labelStyle?.size?.split('rem')?.[0]) : labelStyle?.size?.split('px')?.[0] ;

    const [localData, setLocalData] = useState([]);

    useEffect(() => {
        if (data && xDataKeys && yDataKeys && !localData?.length) {
            // const xdataKey = local_x_data_keys[local_x_data_keys.length - 1];
            // const tickLabelAddedData = data?.map(el => ({ ...el, [xdataKey]: el?.[xDataKeys?.[0]] + (el?.[xDataKeys?.[1]] ? ("__nfx__join__" + el?.[xDataKeys?.[1]]) : '') }))
            // const tickLabelAddedData = props.data?.map((el) => ({
            //     ...el,
            //     [xdataKey]: xDataKeys
            //         ?.map(
            //             (key, inde) =>
            //                 el?.[key] + (el?.[xDataKeys?.[inde + 1]] ? "__nfx__join__" : "")
            //         )
            //         ?.join(""),
            // }));
            setLocalData(
                data?.sort((a, b) => b[yKey] - a[yKey])
            );
        }
    }, [data, xDataKeys, yDataKeys]);

    const column_formating_meta =
        Alignments &&
        Alignments[yKey] &&
        Alignments[yKey];
    const currency_type = column_formating_meta?.["currency_type"];
    const num_format_type = column_formating_meta?.["num_format_type"];
    const use_decimal = column_formating_meta?.["use_decimal"];
    const d_type = column_formating_meta?.["type"];
    const use_percent = column_formating_meta?.["use_percent"];

    // Function to truncate text with ellipsis
    const truncateText = (text, maxWidth, fontSize) => {
        const averageCharWidth = fontSize * 0.8; // Approximate average character width
        let maxChars = Math.floor(maxWidth / averageCharWidth); // Calculate max characters based on width
        maxChars = maxChars > 2 ? maxChars : 3;
        if (text.length > maxChars) {
            return `${text.slice(0, maxChars)}...`;
        }
        return text;
    };

    const CustomLabel = (props) => {
        const { x, y, width, height, index, value, angle } = props;
        const valueObj = localData?.[index]
        const label = valueObj?.[yKey];
        const textWidth = angle === 180 ? width : height;
        const truncatedLabel = truncateText(value, textWidth, font_size);

        const formattedValue = formatValueByDataType(
            label,
            d_type,
            undefined,
            undefined,
            undefined,
            undefined,
            currency_type,
            num_format_type,
            use_decimal,
            use_percent
        );

        return (
            <g transform={`rotate(${angle}, ${x + width / 2}, ${y + height / 2})`}>
                <text
                    x={x + width / 2}
                    y={y + height / 2}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    // fill="#000"
                    fontSize={labelStyle?.size || 14}
                    fill={labelStyle?.color || "#000"}
                >
                    {`${truncatedLabel} - ${formattedValue}`}
                </text>
            </g>
        );
    };

    return (
        <div
            style={{
                width: widthOfChart ? widthOfChart - 30 : "93.1vw",
                height: heightOfChart,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
            }}
        >
            <div
                id="chart"
                style={{
                    width: heightOfChart - 60,
                    height: widthOfChart - 60,
                    transform: "rotate(270deg)", // Rotate the entire chart
                    transformOrigin: "center",
                }}
            >
                <ResponsiveContainer width={heightOfChart - 60} height={widthOfChart - 30}>
                    <FunnelChart dataKey={yKey} nameKey={xKey} data={localData}>
                        {!hide_tooltip &&
                            <Tooltip
                                content={function (active) {
                                    let _data = {};
                                    const _payload = active && active.payload;
                                    const _inner_payload = _payload && _payload[0] && _payload[0].payload;
                                    const payload_data = _inner_payload && _inner_payload.payload;
                                    let array_of_keys = [...xDataKeys, ...yDataKeys];

                                    array_of_keys &&
                                        array_of_keys.length > 0 &&
                                        array_of_keys.map((key) => {
                                            if (
                                                payload_data &&
                                                Object.keys(payload_data).length > 0 &&
                                                Object.keys(payload_data).indexOf(key) > -1
                                            ) {
                                                const column_formating_meta =
                                                    yDataKeys.indexOf(key) > -1 &&
                                                    Alignments &&
                                                    Alignments[key] &&
                                                    Alignments[key];
                                                const currency_type = column_formating_meta?.["currency_type"];
                                                const num_format_type = column_formating_meta?.["num_format_type"];
                                                const use_decimal = column_formating_meta?.["use_decimal"];
                                                const d_type = column_formating_meta?.["type"];
                                                const use_percent = column_formating_meta?.["use_percent"];

                                                _data[key] = formatValueByDataType(
                                                    payload_data[key],
                                                    d_type,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    currency_type,
                                                    num_format_type,
                                                    use_decimal,
                                                    use_percent
                                                );
                                            }
                                        });

                                    if (!_data || (_data && Object.keys(_data)?.length === 0))
                                        return null;

                                    return (
                                        <div
                                            style={{
                                                transform: "rotate(90deg)",
                                            }}
                                        >
                                            <CustomToolTip
                                                payload_data={payload_data}
                                                data={_data}
                                                theme={{ ...tooltipTheme }}
                                                xDataKeys={xDataKeys}
                                            />
                                        </div>
                                    );
                                }}
                            />
                        }
                        <Funnel dataKey={yKey} data={localData} isAnimationActive>
                            {localData?.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={colorForYData[index % colorForYData?.length]}
                                />
                            ))}
                            {show_data_label &&
                                <LabelList
                                    content={<CustomLabel />}
                                    angle={localData?.length > 5 ? 180 : 90}
                                    position={localData?.length > 8 ? "center" : "center"}
                                    fill="#000"
                                    stroke="none"
                                    dataKey={xKey}
                                />
                            }
                        </Funnel>
                    </FunnelChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

const mapStateToProps = (state, props) => {
    const report_cell_clicked_info_cache =
        state.reportHelperReducer.report_cell_clicked_info_cache?.[
        props.insight_id
        ];
    const report_cell_click_info = report_cell_clicked_info_cache?.[props.id];
    const link_filter =
        state.filterReducer.link_filter_cache?.[props.insight_id]?.[props.id];
    const table_format_setting_cache =
        state.tableFormatReducer.table_format_setting_cache;
    const tbl_formatter =
        table_format_setting_cache && table_format_setting_cache[props.id];

    return { report_cell_click_info, link_filter, tbl_formatter };
};

export default withRouter(connect(mapStateToProps, {})(SimpleFunnelChart));
