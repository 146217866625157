import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getTblErrors, insertErrorQueries, pushRowDataToStore, removeRowDataFromStore, clearRowDataEditedStore } from '../../actions/tbl_errors';
import SharedTable from '../shared-table';
import TitleHeader from '../title-header';
import { PageInnerWrapper } from '../hoc/styled.hoc';
import { withRouter } from 'react-router-dom';
import { getParameterByName } from '../../utils'
import RippleEffect from '../hoc/ripple-effect';
import PlaySvg from '../svg/play';
import ProcessSvg from '../svg/process';
import LeftArrow from '../svg/leftArrow';



const Index = (props) => {

    const { getTblErrors, tblErrorsData, history, insertErrorQueries, currentEditedError, clearRowDataEditedStore } = props;
    const { location } = history
    const { search, pathname } = location;
    const [localTableData, setTableData] = useState(undefined);
    const [tableHeader, setTableHeader] = useState(undefined);
    const [tableColumns, setTableColumns] = useState(undefined);
    const [selected, setSelected] = useState(undefined);
    const [editedColumns, setEditedColumns] = useState(undefined);
    const [currentJobId, setCurrentJobId] = useState(undefined);


    useEffect(() => {
        if (search && search.length > 0) {
            const job_id = getParameterByName('job_id', search);
            setCurrentJobId(job_id);
            getTblErrors(job_id)
        }
    }, [search]);


    useEffect(() => {
        setTableData(tblErrorsData)
    },[tblErrorsData])

    let page_title = `Logs for Job Id:- ${currentJobId}`

    useEffect(() => {
        let _page_info = {
            title: page_title,
            isBack: true,
        }
        props.dispatchHeaderInformation(_page_info)

    },[currentJobId])

    // useEffect(() => {

    //     let tempTableData = [];
    //     let tempColumns = ["db_err_message", "db_err_code", 'insert_query'];
    //     let tempHeaderColumns = ['Error Message', "Error Code", 'query'];
    //     let temp_edited_columns = [];
    //     let final_edited_columns = []

    //     if (tblErrorsData && tblErrorsData.length > 0) {
    //         tblErrorsData.forEach((row, i) => {
    //             let _outter_object = row ? JSON.parse(JSON.stringify(row)) : {};
    //             let temp_row = row.columns_data ? row.columns_data.split("_nf_deli_") : [];

    //             temp_row && temp_row.length > 0 && temp_row.forEach((column) => {
    //                 let temp_columns = column && column.split("_nf_val_");
    //                 let _value = temp_columns && temp_columns.length > 0 ? temp_columns[1] : undefined;
    //                 _outter_object[temp_columns[0]] = _value;
    //                 if (temp_edited_columns.indexOf(temp_columns[0] === -1)) {
    //                     temp_edited_columns.push(temp_columns[0])
    //                 }
    //             })
    //             tempTableData.push(_outter_object)
    //         })
    //     }


    //     if (temp_edited_columns && temp_edited_columns.length > 0) {
    //         temp_edited_columns.forEach((column, _i) => {
    //             final_edited_columns.push({
    //                 type: 'text',
    //                 name: column
    //             })
    //         })
    //     }
    //     if (tempTableData && tempTableData.length > 0) {
    //         let keys = tempTableData[0] ? Object.keys(tempTableData[0]) : {};
    //         keys && keys.length > 0 && keys.map((key) => {
    //             if (key !== 'job_id' && key !== 'id' && key !== 'db_target_name' && key !== 'db_err_message' && key !== 'columns_data' && key !== "insert_query" && key !== "is_active" && key !== "db_err_code") {
    //                 tempColumns.push(key)
    //                 tempHeaderColumns.push(key)
    //             }
    //         })
    //     }

    //     setTableHeader(tempHeaderColumns)
    //     setTableColumns(tempColumns)
    //     setTableData(tempTableData)
    //     setEditedColumns(final_edited_columns)

    // }, [tblErrorsData])


    const _checkBoxClicked = (item, type) => {

        let clone_errors = localTableData ? JSON.parse(JSON.stringify(localTableData)) : [];
        let temp_selected = selected ? JSON.parse(JSON.stringify(selected)) : [];

        if (type === 'all') {
            if (temp_selected.length === clone_errors.length) {
                temp_selected = []
            }
            else {
                temp_selected = [];
                clone_errors && clone_errors.length > 0 && clone_errors.map((j, i) => {
                    temp_selected.push(j.id)
                })
            }
        }

        if (type === 'single') {
            if (temp_selected.indexOf(item.id) > -1) {
                let _index = temp_selected.indexOf(item.id);
                temp_selected.splice(_index, 1)
                props.removeRowDataFromStore(item.id)

            } else {
                temp_selected.push(item.id)
            }
        }
        setSelected(temp_selected)
    }





    useEffect(() => {

        let temp_selected = selected ? JSON.parse(JSON.stringify(selected)) : [];
        let keys = currentEditedError && Object.keys(currentEditedError);
        keys && keys.length > 0 && keys.length > 0 && keys.forEach((key) => {
            let item = currentEditedError[key];
            if (temp_selected.indexOf(item.id) === -1) {
                temp_selected.push(item.id)
            }
        })
        setSelected(temp_selected)
    }, [currentEditedError])



    const _save_function = () => {
        let final_data = [];
        let keys = currentEditedError && Object.keys(currentEditedError);
        selected && selected.length > 0 && selected.forEach((id) => {
            if (keys && keys.indexOf(id.toString()) > -1) {
                let editedItem = currentEditedError[id];
                final_data.push(editedItem)
            }
            else {
                localTableData && localTableData.length > 0 && localTableData.forEach((item) => {
                    if (item.id === id) {
                        final_data.push(item)
                    }
                })
            }
        })

        setSelected(undefined)
        props.insertErrorQueries(final_data, currentJobId)
        props.clearRowDataEditedStore()
    }




    return (
        <div>
            {/* <TitleHeader title={page_title ? page_title : 'UPLOAD ERROR'}   extraOption={
                    <RippleEffect
                        title="Back"
                        icon={<LeftArrow size='1.5rem' height='1.4rem' />}
                        Click={() => {
                            if (props.history && props.history.goBack) {
                                props.history.goBack();
                            }
                        }}
                    />
                }/> */}
            <PageInnerWrapper padding={'10px'} style={{marginTop: '1rem'}}>
                {localTableData && localTableData.length > 0 ? 
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginBottom: '.7rem' }}>
                    <React.Fragment>
                        <div style={{ marginLeft: '0rem' }}>
                            <RippleEffect
                                Click={() => {
                                    if (selected && selected.length > 0) {
                                        _save_function()
                                    } else {
                                        alert("please Select Error")
                                    }
                                }}
                                icon={<ProcessSvg size="1rem" height="1rem" color="#757575" />}
                                tooltip="Process Error"
                                position={"right"}
                            />
                        </div>
                    </React.Fragment>
                </div> : null}

                <SharedTable
                    height={"calc(100vh - 180px)"}
                    actionOnHover={true}
                    selected={selected}
                    selectAllCheckboxAction={() => {
                        _checkBoxClicked(undefined, 'all')

                    }}
                    updateRecordAction={(item) => {
                        props.pushRowDataToStore(item)
                    }}
                    updateRecordActionStoredKey={"column_changed"}
                    checkBoxAction={(item) => {
                        _checkBoxClicked(item, 'single')
                    }}
                    deleteAction={(item) => {
                        let temp_jobs = []
                        let temp_item = JSON.parse(JSON.stringify(item));
                        temp_item.deleted = true;
                        temp_jobs.push(temp_item)
                        setSelected(undefined)
                        props.insertErrorQueries(temp_jobs, currentJobId, 'Deleted SuccessFully')
                        props.clearRowDataEditedStore()
                    }}
                    tableData={(localTableData && localTableData.length > 0) ? localTableData : undefined}
                    tableHead={['Target DB', "Error Code", ' Error Message', 'Insert Statement']}
                    columnsToShow={ ["db_target_name", "db_err_code",'db_err_message', 'insert_query']}
                    // notTextWrap={true}
                    maximumWidthOfColumn={{
                        "db_err_message": '10rem',
                        "insert_query" : '28rem'
                    }}
                />
                {localTableData == undefined || localTableData.length == 0 ? <p style={{ textAlign: "center" }}>No Record Found</p> : null}
            </PageInnerWrapper>
        </div>
    )
}



const mapStateToProps = (state) => ({
    tblErrorsData: state.tblErrorReducer.tblErrorsData,
    currentEditedError: state.tblErrorReducer.currentEditedError
})


export default withRouter(connect(mapStateToProps, { getTblErrors, insertErrorQueries, pushRowDataToStore, removeRowDataFromStore, clearRowDataEditedStore })(Index));
