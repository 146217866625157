import React from 'react';

const PrePinned = (props) => {

    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}

            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 316 543"
            space="preserve"
        >
            <defs>
                <symbol overflow="visible" id="t">
                    <path id="svg_1" d="m3.6562,-0.21875c-0.1875,0.09375 -0.38672,0.16797 -0.59375,0.21875c-0.19922,0.05078 -0.40625,0.07813 -0.625,0.07813c-0.66797,0 -1.1992,-0.17969 -1.5938,-0.54689c-0.38672,-0.375 -0.57812,-0.87891 -0.57812,-1.5156c0,-0.64452 0.19141,-1.1484 0.57812,-1.51559c0.39453,-0.375 0.92578,-0.5625 1.5938,-0.5625c0.21875,0 0.42578,0.02734 0.625,0.07812c0.20703,0.05469 0.40625,0.125 0.59375,0.21875l0,0.82812c-0.1875,-0.125 -0.375,-0.21875 -0.5625,-0.28125c-0.17969,-0.0625 -0.37109,-0.09375 -0.57812,-0.09375c-0.36719,0 -0.65625,0.12109 -0.875,0.35938c-0.21094,0.23047 -0.3125,0.55469 -0.3125,0.96875c0,0.40625 0.10156,0.73047 0.3125,0.96875c0.21875,0.23047 0.50781,0.34375 0.875,0.34375c0.20703,0 0.39844,-0.02344 0.57812,-0.07812c0.1875,-0.0625 0.375,-0.16016 0.5625,-0.29688l0,0.82808z" />
                </symbol>
                <symbol overflow="visible" id="d">
                    <path id="svg_2" d="m2.6875,-2.1719c-0.08594,-0.03906 -0.16797,-0.07031 -0.25,-0.09375c-0.08594,-0.01953 -0.16797,-0.03125 -0.25,-0.03125c-0.25,0 -0.44531,0.08594 -0.57812,0.25c-0.125,0.15625 -0.1875,0.38281 -0.1875,0.67188l0,1.375l-0.96875,0l0,-2.9844l0.96875,0l0,0.48438c0.11328,-0.19531 0.25,-0.33594 0.40625,-0.42188c0.16406,-0.09375 0.35938,-0.14062 0.57812,-0.14062l0.10938,0c0.03906,0 0.09375,0.00781 0.15625,0.01562l0.01562,0.87502z" />
                </symbol>
                <symbol overflow="visible" id="a">
                    <path id="svg_3" d="m3.4375,-1.5l0,0.26562l-2.2344,0c0.03125,0.23047 0.11328,0.40234 0.25,0.51562c0.13281,0.10547 0.32812,0.15625 0.57812,0.15625c0.20703,0 0.41406,-0.02344 0.625,-0.07812c0.20703,-0.0625 0.42188,-0.15625 0.64062,-0.28125l0,0.73437c-0.21875,0.09375 -0.44531,0.16406 -0.67188,0.20312c-0.23047,0.03907 -0.45312,0.0625 -0.67188,0.0625c-0.54297,0 -0.96484,-0.13281 -1.2656,-0.40625c-0.30469,-0.28125 -0.45312,-0.67187 -0.45312,-1.1719c0,-0.47656 0.14453,-0.85938 0.4375,-1.1406c0.30078,-0.28125 0.70703,-0.42188 1.2188,-0.42188c0.46875,0 0.84375,0.14062 1.125,0.42188s0.42188,0.66406 0.42188,1.1406l-0.00004,0.00004zm-0.96875,-0.32812c0,-0.17578 -0.05859,-0.31641 -0.17188,-0.42188c-0.10547,-0.11328 -0.24219,-0.17188 -0.40625,-0.17188c-0.1875,0 -0.33984,0.05469 -0.45312,0.15625c-0.11719,0.10547 -0.1875,0.25 -0.21875,0.4375l1.25,0.00001z" />
                </symbol>
                <symbol overflow="visible" id="l">
                    <path id="svg_4" d="m1.7969,-1.3438c-0.19922,0 -0.35156,0.03906 -0.45312,0.10938c-0.09375,0.0625 -0.14062,0.16406 -0.14062,0.29688c0,0.11719 0.03516,0.21094 0.10938,0.28125c0.08203,0.0625 0.19531,0.09375 0.34375,0.09375c0.17578,0 0.32812,-0.0625 0.45312,-0.1875s0.1875,-0.28516 0.1875,-0.48438l0,-0.10938l-0.50001,0zm1.4688,-0.35938l0,1.7031l-0.96875,0l0,-0.4375c-0.125,0.17969 -0.27344,0.30859 -0.4375,0.39062c-0.15625,0.08203 -0.35156,0.125 -0.57812,0.125c-0.3125,0 -0.57031,-0.08594 -0.76562,-0.26562c-0.1875,-0.1875 -0.28125,-0.42188 -0.28125,-0.70312c0,-0.35156 0.11719,-0.61328 0.35938,-0.78125c0.23828,-0.16406 0.61719,-0.25 1.1406,-0.25l0.5625,0l0,-0.0625c0,-0.15625 -0.0625,-0.26562 -0.1875,-0.32812c-0.11719,-0.07031 -0.29688,-0.10938 -0.54688,-0.10938c-0.21094,0 -0.40234,0.02344 -0.57812,0.0625c-0.17969,0.04297 -0.33984,0.10156 -0.48438,0.17188l0,-0.71875c0.19531,-0.05078 0.39844,-0.08594 0.60938,-0.10938c0.20703,-0.03125 0.41406,-0.04687 0.625,-0.04687c0.53906,0 0.92969,0.10937 1.1719,0.32812c0.23828,0.21094 0.35938,0.55469 0.35938,1.0312l-0.00002,0.00007z" />
                </symbol>
                <symbol overflow="visible" id="c">
                    <path id="svg_5" d="m1.5,-3.8438l0,0.85938l0.98438,0l0,0.67188l-0.98438,0l0,1.2812c0,0.13672 0.02344,0.23047 0.07813,0.28125c0.0625,0.04297 0.17578,0.0625 0.34375,0.0625l0.48438,0l0,0.6875l-0.8125,0c-0.38672,0 -0.65625,-0.07813 -0.8125,-0.23438s-0.23438,-0.42188 -0.23438,-0.79688l0,-1.2812l-0.46875,0l0,-0.67188l0.46875,0l0,-0.85938l0.95312,0.00001z" />
                </symbol>
                <symbol overflow="visible" id="f">
                    <path id="svg_6" d="m2.5,-2.5469l0,-1.6094l0.95312,0l0,4.1562l-0.95312,0l0,-0.4375c-0.13672,0.17969 -0.28125,0.30859 -0.4375,0.39062c-0.15625,0.08203 -0.33984,0.125 -0.54688,0.125c-0.375,0 -0.68359,-0.14453 -0.92188,-0.4375c-0.23047,-0.28906 -0.34375,-0.67188 -0.34375,-1.1406c0,-0.45703 0.11328,-0.83203 0.34375,-1.125c0.23828,-0.28906 0.54688,-0.4375 0.92188,-0.4375c0.19531,0 0.375,0.04297 0.53125,0.125c0.16406,0.08594 0.31641,0.21484 0.45312,0.39062l0.00001,0.00006zm-0.64062,1.9375c0.20703,0 0.36328,-0.07031 0.46875,-0.21875c0.11328,-0.15625 0.17188,-0.37891 0.17188,-0.67188c0,-0.28125 -0.05859,-0.49219 -0.17188,-0.64062c-0.10547,-0.15625 -0.26172,-0.23438 -0.46875,-0.23438c-0.19922,0 -0.35547,0.07813 -0.46875,0.23438c-0.10547,0.14844 -0.15625,0.35938 -0.15625,0.64062c0,0.29297 0.05078,0.51562 0.15625,0.67188c0.11328,0.14844 0.26953,0.21875 0.46875,0.21875z" />
                </symbol>
                <symbol overflow="visible" id="k">
                    <path id="svg_7" d="m2.0469,-0.60938c0.20703,0 0.36328,-0.07031 0.46875,-0.21875c0.11328,-0.15625 0.17188,-0.37891 0.17188,-0.67188c0,-0.28125 -0.05859,-0.49219 -0.17188,-0.64062c-0.10547,-0.15625 -0.26172,-0.23438 -0.46875,-0.23438c-0.19922,0 -0.35547,0.07813 -0.46875,0.23438c-0.10547,0.14844 -0.15625,0.35938 -0.15625,0.64062c0,0.29297 0.05078,0.51562 0.15625,0.67188c0.11328,0.14844 0.26953,0.21875 0.46875,0.21875zm-0.625,-1.9375c0.125,-0.17578 0.26562,-0.30469 0.42188,-0.39062c0.16406,-0.08203 0.35156,-0.125 0.5625,-0.125c0.36328,0 0.66406,0.14844 0.90625,0.4375c0.23828,0.29297 0.35938,0.66797 0.35938,1.125c0,0.46875 -0.12109,0.85156 -0.35938,1.1406c-0.24219,0.29297 -0.54297,0.4375 -0.90625,0.4375c-0.21094,0 -0.39844,-0.04297 -0.5625,-0.125c-0.15625,-0.08203 -0.29688,-0.21094 -0.42188,-0.39062l0,0.4375l-0.96875,0l0,-4.1562l0.96875,0l0,1.60934z" />
                </symbol>
                <symbol overflow="visible" id="j">
                    <path id="svg_8" d="m0.0625,-2.9844l0.95312,0l0.8125,2.0156l0.6875,-2.0156l0.95312,0l-1.2656,3.2656c-0.125,0.33203 -0.27344,0.56641 -0.4375,0.70312c-0.16797,0.13281 -0.39062,0.20312 -0.67188,0.20312l-0.54688,0l0,-0.64062l0.29688,0c0.16406,0 0.28516,-0.02734 0.35938,-0.07813c0.07031,-0.05468 0.12891,-0.14061 0.17188,-0.26562l0.03125,-0.09375l-1.34377,-3.09372z" />
                </symbol>
                <symbol overflow="visible" id="e">
                    <path id="svg_9" d="m0.45312,-2.9844l0.96875,0l0,2.9844l-0.96875,0l0,-2.9844zm0,-1.1719l0.96875,0l0,0.78125l-0.96875,0l0,-0.78125z" />
                </symbol>
                <symbol overflow="visible" id="i">
                    <path id="svg_10" d="m2.875,-2.8906l0,0.76562c-0.125,-0.08203 -0.25781,-0.14453 -0.39062,-0.1875c-0.13672,-0.03906 -0.27344,-0.0625 -0.40625,-0.0625c-0.27344,0 -0.48047,0.07813 -0.625,0.23438c-0.14844,0.15625 -0.21875,0.37109 -0.21875,0.64062c0,0.28125 0.07031,0.5 0.21875,0.65625c0.14453,0.15625 0.35156,0.23438 0.625,0.23438c0.14453,0 0.28516,-0.01953 0.42188,-0.0625c0.13281,-0.03906 0.25781,-0.10938 0.375,-0.20312l0,0.78125c-0.14844,0.0625 -0.29688,0.10156 -0.45312,0.125c-0.15625,0.03125 -0.3125,0.04688 -0.46875,0.04688c-0.54297,0 -0.96484,-0.13281 -1.2656,-0.40625c-0.30469,-0.28125 -0.45312,-0.67188 -0.45312,-1.1719c0,-0.48828 0.14844,-0.8672 0.45312,-1.1406c0.30078,-0.28125 0.72266,-0.42189 1.2656,-0.42189c0.15625,0 0.3125,0.01563 0.46875,0.04688c0.15625,0.02344 0.30469,0.0625 0.45312,0.125l-0.00001,0z" />
                </symbol>
                <symbol overflow="visible" id="b">
                    <path id="svg_11" d="m1.875,-2.375c-0.21094,0 -0.37109,0.07812 -0.48438,0.23438c-0.10547,0.14844 -0.15625,0.35938 -0.15625,0.64062c0,0.29297 0.05078,0.51562 0.15625,0.67188c0.11328,0.14844 0.27344,0.21875 0.48438,0.21875c0.20703,0 0.36719,-0.07031 0.48438,-0.21875c0.11328,-0.15625 0.17188,-0.37891 0.17188,-0.67188c0,-0.28125 -0.05859,-0.49219 -0.17188,-0.64062c-0.11719,-0.15625 -0.27734,-0.23438 -0.48438,-0.23438zm0,-0.6875c0.51953,0 0.92188,0.14062 1.2031,0.42188c0.28906,0.27344 0.4375,0.65234 0.4375,1.1406c0,0.5 -0.14844,0.89062 -0.4375,1.1719c-0.28125,0.27344 -0.68359,0.40625 -1.2031,0.40625c-0.51172,0 -0.91406,-0.13281 -1.2031,-0.40625c-0.29297,-0.28125 -0.4375,-0.67188 -0.4375,-1.1719c0,-0.48828 0.14453,-0.86719 0.4375,-1.1406c0.28906,-0.28125 0.69141,-0.42188 1.2031,-0.42188z" />
                </symbol>
                <symbol overflow="visible" id="h">
                    <path id="svg_12" d="m3.4688,-1.8281l0,1.8281l-0.96875,0l0,-1.3906c0,-0.25781 -0.00781,-0.4375 -0.01563,-0.53125c-0.01171,-0.10156 -0.03125,-0.17578 -0.0625,-0.21875c-0.03125,-0.0625 -0.08593,-0.10938 -0.15625,-0.14062c-0.0625,-0.03906 -0.13281,-0.0625 -0.20312,-0.0625c-0.21094,0 -0.37109,0.07812 -0.48438,0.23438c-0.10547,0.15625 -0.15625,0.37109 -0.15625,0.64062l0,1.4688l-0.96875,0l0,-2.9844l0.96875,0l0,0.4375c0.13281,-0.17578 0.28516,-0.30469 0.45312,-0.39062c0.16406,-0.08203 0.34375,-0.125 0.53125,-0.125c0.34375,0 0.60156,0.10938 0.78125,0.32812c0.1875,0.21094 0.28125,0.51172 0.28125,0.90625l0.00001,-0.00003z" />
                </symbol>
                <symbol overflow="visible" id="g">
                    <path id="svg_13" d="m2.4219,-4.1562l0,0.625l-0.51562,0c-0.13672,0 -0.23438,0.02734 -0.29688,0.07812c-0.05469,0.05469 -0.07813,0.13672 -0.07813,0.25l0,0.21875l0.82812,0l0,0.67188l-0.82812,0l0,2.3125l-0.95312,0l0,-2.3125l-0.46875,0l0,-0.67188l0.46875,0l0,-0.21875c0,-0.32031 0.08594,-0.5625 0.26562,-0.71875c0.1875,-0.15625 0.47266,-0.23437 0.85938,-0.23437l0.71875,0z" />
                </symbol>
                <symbol overflow="visible" id="s">
                    <path id="svg_14" d="m0.45312,-4.1562l0.96875,0l0,4.1562l-0.96875,0l0,-4.1562z" />
                </symbol>
                <symbol overflow="visible" id="r">
                    <path id="svg_15" d="m3.2344,-2.5c0.11328,-0.17578 0.25391,-0.3125 0.42188,-0.40625c0.16406,-0.10156 0.35156,-0.15625 0.5625,-0.15625c0.33203,0 0.58594,0.10938 0.76562,0.32812c0.1875,0.21094 0.28125,0.51172 0.28125,0.90625l0,1.8281l-0.96875,0l0,-1.5625c0.00781,-0.01953 0.01563,-0.03906 0.01563,-0.0625l0,-0.10938c0,-0.21875 -0.03125,-0.375 -0.09375,-0.46875s-0.16406,-0.14062 -0.29688,-0.14062c-0.1875,0 -0.33594,0.07813 -0.4375,0.23438c-0.09375,0.14844 -0.14062,0.35938 -0.14062,0.64062l0,1.4688l-0.96875,0l0,-1.5625c0,-0.33203 -0.03125,-0.54688 -0.09375,-0.64062c-0.05469,-0.09375 -0.15234,-0.14062 -0.29688,-0.14062c-0.17969,0 -0.32031,0.07813 -0.42188,0.23438c-0.09375,0.14844 -0.14062,0.35938 -0.14062,0.64062l0,1.4688l-0.96875,0l0,-2.9844l0.96875,0l0,0.4375c0.11328,-0.17578 0.24219,-0.30469 0.39062,-0.39062c0.15625,-0.08203 0.32812,-0.125 0.51562,-0.125c0.20703,0 0.39062,0.05469 0.54688,0.15625c0.15625,0.09375 0.27344,0.23047 0.35938,0.40625l0,-0.00006z" />
                </symbol>
                <symbol overflow="visible" id="q">
                    <path id="svg_16" d="m3.4688,-1.8281l0,1.8281l-0.96875,0l0,-1.3906c0,-0.25781 -0.00781,-0.4375 -0.01563,-0.53125c-0.01171,-0.10156 -0.03125,-0.17578 -0.0625,-0.21875c-0.03125,-0.0625 -0.08593,-0.10938 -0.15625,-0.14062c-0.0625,-0.03906 -0.13281,-0.0625 -0.20312,-0.0625c-0.21094,0 -0.37109,0.07812 -0.48438,0.23438c-0.10547,0.15625 -0.15625,0.37109 -0.15625,0.64062l0,1.4688l-0.96875,0l0,-4.1562l0.96875,0l0,1.6094c0.13281,-0.17578 0.28516,-0.30469 0.45312,-0.39062c0.16406,-0.08203 0.34375,-0.125 0.53125,-0.125c0.34375,0 0.60156,0.10938 0.78125,0.32812c0.1875,0.21094 0.28125,0.51172 0.28125,0.90625l0.00001,-0.00013z" />
                </symbol>
                <symbol overflow="visible" id="p">
                    <path id="svg_17" d="m0.5,-3.9844l1.1562,0l1.4375,2.7344l0,-2.7344l0.98438,0l0,3.9844l-1.1562,0l-1.4375,-2.7344l0,2.7344l-0.98438,0l0,-3.9844z" />
                </symbol>
                <symbol overflow="visible" id="o">
                    <path id="svg_18" d="m0.42188,-1.1719l0,-1.8125l0.96875,0l0,0.29688l0,0.60938l0,0.48438c0,0.24219 0.00391,0.41797 0.01563,0.53125c0.00781,0.10547 0.03125,0.17969 0.0625,0.21875c0.03906,0.0625 0.09375,0.11719 0.15625,0.15625c0.0625,0.03125 0.13281,0.04688 0.21875,0.04688c0.1953,0 0.35155,-0.07813 0.46874,-0.23438c0.11328,-0.15625 0.17188,-0.36719 0.17188,-0.64063l0,-1.4688l0.95312,0l0,2.9844l-0.95312,0l0,-0.4375c-0.14844,0.17969 -0.30469,0.30859 -0.46875,0.39062c-0.15625,0.08204 -0.33594,0.125 -0.53125,0.125c-0.34375,0 -0.60938,-0.10156 -0.79688,-0.3125c-0.17969,-0.21875 -0.26562,-0.53125 -0.26562,-0.9375l0,0.00002z" />
                </symbol>
                <symbol overflow="visible" id="n">
                    <path id="svg_19" d="m0.5,-3.9844l1.7031,0c0.50781,0 0.89844,0.11719 1.1719,0.34375c0.26953,0.21875 0.40625,0.53906 0.40625,0.95312c0,0.41797 -0.13672,0.74219 -0.40625,0.96875c-0.27344,0.21875 -0.66406,0.32812 -1.1719,0.32812l-0.67188,0l0,1.3906l-1.0312,0l-0.00002,-3.98434zm1.0312,0.75l0,1.1094l0.5625,0c0.19531,0 0.34766,-0.04688 0.45312,-0.14062c0.11328,-0.10156 0.17188,-0.24219 0.17188,-0.42188c0,-0.17578 -0.05859,-0.3125 -0.17188,-0.40625c-0.10547,-0.09375 -0.25781,-0.14062 -0.45312,-0.14062l-0.5625,-0.00003z" />
                </symbol>
                <symbol overflow="visible" id="m">
                    <path id="svg_20" d="m0.45312,-2.9844l0.96875,0l0,2.9375c0,0.39453 -0.10156,0.69531 -0.29688,0.90625c-0.1875,0.21875 -0.46484,0.32812 -0.82812,0.32812l-0.48438,0l0,-0.64062l0.17188,0c0.17578,0 0.29688,-0.04297 0.35938,-0.125c0.07031,-0.07422 0.10938,-0.23047 0.10938,-0.46875l-0.00001,-2.9375zm0,-1.1719l0.96875,0l0,0.78125l-0.96875,0l0,-0.78125z" />
                </symbol>
            </defs>
            <g>
                <title>Layer 1</title>
                <g id="svg_21">
                    <path id="svg_22" d="m306.27,256.35c-18.793,-28.742 -44.977,-51.137 -76.043,-65.125l0,-54.414l2.6328,0c19.426,0 35.23,-15.805 35.23,-35.23l-0.00391,-62.434c0,-19.426 -15.805,-35.23 -35.23,-35.23l-151.71,0.00391c-19.426,0 -35.23,15.805 -35.23,35.23l0,62.434c0,19.426 15.805,35.23 35.23,35.23l2.6328,0l0,54.414c-31.066,13.988 -57.254,36.383 -76.043,65.125c-7.6016,11.625 -8.2305,26.414 -1.6406,38.594c6.6406,12.273 18.949,19.602 32.926,19.602l85.719,0l10.465,200.77c0.66406,12.77 11.203,22.777 23.992,22.777c12.789,0 23.328,-10.004 23.992,-22.777l10.465,-200.77l81.34,0c13.977,0 26.285,-7.3281 32.926,-19.602c6.5859,-12.184 5.957,-26.973 -1.6445,-38.598l-0.00559,0.00009zm-238.48,-217.2c0,-7.3633 5.9922,-13.355 13.355,-13.355l151.71,0.00391c7.3633,0 13.355,5.9883 13.355,13.355l0,62.434c0,6.5078 -4.6836,11.938 -10.855,13.109l-156.71,0c-6.1719,-1.1719 -10.855,-6.6016 -10.855,-13.109l0,-62.43791zm93.547,475.02c-0.05859,1.1445 -1.0039,2.0391 -2.1484,2.0391s-2.0859,-0.89453 -2.1484,-2.0391l-10.406,-199.62l25.105,0l-10.4022,199.62zm127.33,-229.64c-2.7539,5.0938 -7.8711,8.1367 -13.684,8.1367l-235.97,0c-5.8125,0 -10.93,-3.0391 -13.684,-8.1367c-2.8047,-5.1836 -2.5391,-11.246 0.71094,-16.215c17.695,-27.066 42.848,-47.715 72.746,-59.715c4.1445,-1.6641 6.8633,-5.6836 6.8633,-10.148l0,-61.887l102.7,0l0,61.887c0,4.4688 2.7148,8.4844 6.8633,10.148c29.898,12.004 55.051,32.652 72.746,59.715c3.2461,4.9688 3.5117,11.031 0.70703,16.215l0.00143,0z" />
                    <use id="svg_23" y="567.40625" x="-123" />
                    <use id="svg_24" y="567.40625" x="-118.98828" />
                    <use id="svg_25" y="567.40625" x="-116.28906" />
                    <use id="svg_26" y="567.40625" x="-112.58203" />
                    <use id="svg_27" y="567.40625" x="-108.89063" />
                    <use id="svg_28" y="567.40625" x="-106.27734" />
                    <use id="svg_29" y="567.40625" x="-102.56641" />
                    <use id="svg_30" y="567.40625" x="-96.75" />
                    <use id="svg_31" y="567.40625" x="-92.83203" />
                    <use id="svg_32" y="567.40625" x="-87.36328" />
                    <use id="svg_33" y="567.40625" x="-85.49219" />
                    <use id="svg_34" y="567.40625" x="-82.25" />
                    <use id="svg_35" y="567.40625" x="-78.49219" />
                    <use id="svg_36" y="567.40625" x="-74.59766" />
                    <use id="svg_37" y="567.40625" x="-72.21875" />
                    <use id="svg_38" y="567.40625" x="-70.34375" />
                    <use id="svg_39" y="567.40625" x="-66.63672" />
                    <use id="svg_40" y="567.40625" x="-64.76172" />
                    <use id="svg_41" y="572.875" x="-123" />
                    <use id="svg_42" y="572.875" x="-120.62109" />
                    <use id="svg_43" y="572.875" x="-117.92188" />
                    <use id="svg_44" y="572.875" x="-114.16797" />
                    <use id="svg_45" y="572.875" x="-106.5625" />
                    <use id="svg_46" y="572.875" x="-103.94922" />
                    <use id="svg_47" y="572.875" x="-100.05859" />
                    <use id="svg_48" y="572.875" x="-94.44531" />
                    <use id="svg_49" y="572.875" x="-89.86719" />
                    <use id="svg_50" y="572.875" x="-86.10938" />
                    <use id="svg_51" y="572.875" x="-82.21484" />
                    <use id="svg_52" y="572.875" x="-76.41797" />
                    <use id="svg_53" y="572.875" x="-72.41016" />
                    <use id="svg_54" y="572.875" x="-69.71484" />
                    <use id="svg_55" y="572.875" x="-65.95703" />
                    <use id="svg_56" y="572.875" x="-64.08203" />
                    <use id="svg_57" y="572.875" x="-60.375" />
                    <use id="svg_58" y="572.875" x="-57.13281" />
                </g>
            </g>
        </svg>

    )

}

export default PrePinned;