import styled from 'styled-components';
import { themes } from '../../theme/theme.config'
import { getSelectedTheme, apply_styles } from '../../utils';
import { constants } from '../../utils/constants';

const selected = getSelectedTheme();
const { titleHeader, svgConfig, textConfig } = themes[selected];






export const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box; 
    height: ${props => props.height ? props.height : '4rem'};
    padding-left: ${props => (props.style && props.style.paddingLeft) ? props.style.paddingLeft : undefined};
    margin-bottom: ${props => (props.style && props.style.marginBottom) ? props.style.marginBottom : undefined};
    svg{
        fill: ${titleHeader.color.titleText};
    }

    h2{
        ${props => apply_styles(props._style, constants.STYLED_COMPONENTS.ALL)}
        margin: 0px;
        position: relative;
        width: 100%;
    }
    .action_group{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            box-sizing: border-box;
            position: relative;
            z-index: 1;
    }
    .download{
        border: 1px solid transparent;
        &:hover{
            border: solid 1px #eaeaea !important;
            background-color: rgb(255, 255, 255) !important;
            border-radius: 5px 5px 0px 0px !important;
            box-shadow: 0 0 0 !important;
            
        }
    }
    .action_btn{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-sizing: border-box;
        padding: 10px;
        padding-right: 1rem;
        position: relative;
        transition: all 0.2s;
        height: 2.3rem;
        z-index: 3;
        &:hover{
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
            border-radius: 4px;
           svg{
                fill: ${titleHeader.color.optionsHover};
          }
            span{
                color: ${titleHeader.color.optionsHover};
            }
        }
        &:last-child{
            padding-right: 7px;
        }
        &:hover{
          
            .drop_down_menu{
                display: block;
            }
        }
        
        svg{
            margin-right: 0.714rem;
            fill: ${titleHeader.color.options};
        }
        span{
            font-size: 0.8rem;
            color: ${titleHeader.color.options};
            font-weight: normal;
        }
    }
    .drop_down_menu{
            position: absolute;
            display: none;
            top: 100%;
            left: 0px;
            width: 100%;
            background: #fff;
            border: solid 1px #eaeaea;
            background-color: rgb(255, 255, 255);
            border-radius: 0  0 5px 5px;
            z-index: 12;
         
            li{
                list-style: none;
                display: block;
                font-size: 0.779rem;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content:flex-start;
                padding: 9px 10px;
                background: #fff;
                color: #333333;
                font-weight: normal;
                text-align: center;
                &:hover{
                    span{
                        color: ${titleHeader.color.optionsHover} !important;
                     }
                    svg{
                        fill: ${titleHeader.color.optionsHover} !important;
                     }
                }
                
                a{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    color: #000;
                    text-decoration: none;
                }
              
                img{
                    margin-right: 10px;
                    width: 1.071rem;
                }
                i{
                    margin-right: 10px;
                    font-size: 1.2rem
                }
                span{
                    font-size: 0.779rem;
                    color: #333333 !important;
                }
                svg{
                    fill: #333333 !important;
                }
            }
        }
`;