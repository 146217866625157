import {  simpleDispatch_get, simpleDispatch_post } from './helper';
import * as actionTypes from '../action-types/markup';
import { constants } from '../utils/constants';


export const  getPlatformsAndCampaigns = () => async dispatch => {
    
    const url = constants.END_POINTS.API + constants.END_POINTS.AD_TECH_CAMPAIGN.POINT + constants.END_POINTS.AD_TECH_CAMPAIGN.GET_PLATFORM_AND_CAMPAIGNS;

    await simpleDispatch_get(dispatch, url, actionTypes.GET_PLATFORMS_AND_CAMPAIGNS);
};




export const  saveTheMarkup = (data) => async dispatch => {
    
    const url = constants.END_POINTS.API + constants.END_POINTS.MARK_UP_AD_TECH.POINT + constants.END_POINTS.MARK_UP_AD_TECH.SAVE_THE_MARK_UP;

    await simpleDispatch_post(dispatch, url, data, actionTypes.SAVE_THE_MARKUP, undefined, undefined, { message: "Save Markup Successfully"});
};







