import { dispatchAction } from './helper';
import * as actionTypes from '../action-types/menu.config.righter';

export const raiseMenuConfig = (title, type, id) => dispatch => {
    const _data = {
        title,
        type,
        id,
        report_id: id,
    }
    dispatchAction(dispatch, actionTypes.MENU_CONFIG_RAISE, _data, undefined, undefined, 0);
};

export const hideMenuConfig = (report_id) => dispatch => {
    dispatchAction(dispatch, actionTypes.MENU_CONFIG_HIDE, {report_id: report_id}, undefined, undefined, 0)
};

