import React, { useState, useEffect, useRef } from 'react';
import { DisplayFiledWrapper, DisplayColumnData, ATozTabWrapper } from '../select-display-field/select.display.field.styled';
import { RadioCheckMark } from '../hoc/radiobox';
import { Button } from '../hoc/button';
import { Gap } from '../gap/index';
import TextBox from '../hoc/textBox';
import SelectOptionV1 from '../select-option/select.option.v1.1';
import { FULL_MONTHS } from '../../shared-with-fe/constants';
import CustomDatePicker from '../hoc/date-time-piker/index'
import styled from 'styled-components';
import { format_date } from '../../utils';
import { quick_date_options } from '../filter/shared.function.js';



const DefaultDateWrapper = styled.div`
    height: 250px;
  .tab_parent{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .tab_item{
                width: 25%;
                font-size: 1rem;
                border: 1px solid #fff;
                height: 2.2rem;
                background: #f5f5f5;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                box-sizing: border-box;

                
            }

            .active{
                background: #008ac7;
                color: #fff;
            }
        }

`;

const SelectDisplayField = (props) => {

    const { close, popupKey, option, data } = props;

    const {

        displayItem, title, selectedFields, setSelectedFields,
        isCheckboxHide, hideSearchbox, buttonText, titleOnHover,
        titleHeading,
        hidePrimeryBtn,
        filterDefaultValues,
        setFilterDefaultValues,
        width,
        is_hierarchy_filter,
        set_is_hierarchy_filter

    } = data;

    const [searchValue, setSearchValue] = useState("")
    const [tempSelectedVal, setTempSelectedVal] = useState([]);
    const [isSelectFilterValue, setIsSelectFilterValue] = useState(undefined);
    const [tempFilterDefaultValues, setTempFilterDefaultValues] = useState(undefined);
    const [is_go_next, set_is_go_next] = useState(undefined);


    const [local_is_hierarchy_filter, set_local_is_hierarchy_filter] = useState(undefined);

    const [defaultValue, setDefaultValue] = useState({
        year: undefined,
        month: undefined,
        single_date: undefined,
        date_from: undefined,
        date_to: undefined,

    })

    const [open_block_key, set_open_block_key] = useState(undefined)


    useEffect(() => {
        set_local_is_hierarchy_filter(is_hierarchy_filter)
    }, [is_hierarchy_filter])


    let prevLetter = '';
    let aTozArray = [];


    const click_over_filter_mode = (mode) => {

        const temp_selected_data = defaultValue ? JSON.parse(JSON.stringify(defaultValue)) : {};

        if (mode === "year") {

            delete temp_selected_data['month'];
            setDefaultValue(temp_selected_data);
        }

        set_open_block_key(mode)
    }


    useEffect(() => {
        if (filterDefaultValues) {
            if (filterDefaultValues) {
                if (filterDefaultValues.custom_date_config) {
                    set_open_block_key('custom_date_config')
                }
                else if (filterDefaultValues?.month && filterDefaultValues?.year) {
                    set_open_block_key('month')
                }
                else if (filterDefaultValues?.year && !filterDefaultValues?.month) {
                    set_open_block_key('year')
                }
                else if (filterDefaultValues?.date_from && filterDefaultValues?.date_to) {
                    set_open_block_key('custom_date')
                }
                else if (filterDefaultValues.range_start_date && filterDefaultValues.range_end_date) {
                    set_open_block_key('range')

                }
                else if (filterDefaultValues.quick_date) {
                    set_open_block_key('quick_date')
                }
                else {
                    set_open_block_key('single_date')

                }
            }
            setDefaultValue(filterDefaultValues)
        } else setDefaultValue(undefined)
    }, [filterDefaultValues])

    useEffect(() => {
        if (selectedFields) {
            setTempSelectedVal([...selectedFields])
        }
    }, [selectedFields]);


    const compare_value_with_selected_item = (value, array) => {
        return array && (array.filter((d => !d.deleted)) || []).find((item) => item.column_name === value)
    }

    const compare_value_with_selected_itemv1 = (value, array) => {
        return array.find((item) => item.column_name === value)
    }


    const sorted_display_items = JSON.parse(JSON.stringify(displayItem)).filter((data) => {
        if (typeof data === 'string') {
            if (compare_value_with_selected_itemv1(data, selectedFields)) {
                return false;
            }
            if (data === '_id') return false;
            if (data === '') return false;
            if (searchValue === '') {
                return true;
            }
            else if (data.toLowerCase().includes(searchValue.toLowerCase())) {
                return true;
            }
        } else return false;
    });

    sorted_display_items.sort(function (a, b) {
        let left = typeof a === 'string' ? a.trim().toLowerCase() : a;
        let right = typeof b === 'string' ? b.trim().toLowerCase() : b;

        return left.localeCompare(right);
    });

    const genCharArray = (charA, charZ) => {
        let a = [];
        let i = charA.charCodeAt(0);
        let j = charZ.charCodeAt(0);

        for (i; i <= j; i++) {
            a.push(String.fromCharCode(i));
        }

        return a
    }

    aTozArray = genCharArray("a", "z");

    const scrollToView = (index) => {
        const element = elRefs.current[index];

        if (element && element.current) {
            element.current.scrollIntoView({ block: 'end', behavior: 'smooth', inline: 'start' });
        }
    };

    const elRefs = useRef([]);

    // add or remove refs
    elRefs.current = Array(26).fill().map((_, i) => elRefs.current[i] || React.createRef());



    const on_item_click_v2 = (name) => {

        const temp_selected = tempSelectedVal ? [...tempSelectedVal] : [];
        const is_exist = temp_selected.findIndex(item => item.column_name === name);

        if (is_exist > -1 && !temp_selected[is_exist]['deleted']) {
            // temp_selected.splice(is_exist, 1)
            temp_selected[is_exist]['deleted'] = true;
        }
        else if (is_exist > -1 && temp_selected[is_exist]['deleted']) {
            delete temp_selected[is_exist]['deleted'];
        }
        else {
            temp_selected.push({
                column_name: name
            })
        }
        setTempSelectedVal(temp_selected)
    }


    const DefaultFilterValuePicker = (props) => {

        const months_selections = [];
        Object.keys(FULL_MONTHS).forEach((m) => {
            months_selections.push({
                key: FULL_MONTHS[m],
                value: m
            })
        })




        return (

            <DefaultDateWrapper>
                <h1 style={{ fontSize: '1.2rem', textAlign: 'center', fontWeight: 400, color: '#333' }}>Select Default Date Filter</h1>

                <div style={{ width: '100%', marginBottom: '1rem' }}>
                    <div style={{ margin: 'auto', marginTop: '.7rem', width: '40rem' }}>

                        <div className='tab_parent'>
                            <div className={open_block_key === 'year' ? "active tab_item" : "tab_item"} onClick={(e) => click_over_filter_mode('year')}>Year</div>

                            <div className={open_block_key === 'month' ? "active tab_item" : "tab_item"} onClick={(e) => click_over_filter_mode('month')}>Month</div>
                            <div className={open_block_key === 'single_date' ? "active tab_item" : "tab_item"} onClick={(e) => click_over_filter_mode('single_date')}>Single</div>
                            <div className={open_block_key === 'range' ? "active tab_item" : "tab_item"} onClick={(e) => click_over_filter_mode('range')}>Range</div>
                            {/* <div className={open_block_key === 'custom_date_config' ? "active tab_item" : "tab_item"} onClick={(e) => {
                                click_over_filter_mode('custom_date_config')
                            }}>Custom Date</div> */}
                            <div className={open_block_key === 'quick_date' ? "active tab_item" : "tab_item"} onClick={(e) => {
                                click_over_filter_mode('quick_date')
                            }}>Quick Date</div>


                        </div>
                        <div style={{ marginTop: '10px' }} >

                            {open_block_key === 'quick_date' && (
                                <div>
                                    <SelectOptionV1
                                        label='Choose Quick Date'
                                        options={quick_date_options}
                                        value_key="value"
                                        display_key="label"
                                        value={defaultValue?.quick_date}
                                        on_change={(value) => {
                                            const new_value_object = {
                                                quick_date: value
                                            };
                                            setDefaultValue(new_value_object)
                                        }}
                                        none_value={false}
                                    />
                                </div>
                            )}

                            {/* {open_block_key === 'custom_date_config' && (
                                <div>
                                    <SelectOptionV1
                                        label='Choose Custom Date'
                                        options={[
                                            {
                                                name: 'Today',
                                                value: "today"
                                            },
                                            {
                                                name: 'Yesterday',
                                                value: "yesterday"
                                            },
                                            {
                                                name: 'Day Before Yesterday',
                                                value: "day_before_yesterday"
                                            }
                                        ]}
                                        value_key="value"
                                        display_key="name"
                                        value={defaultValue?.custom_date_config}
                                        on_change={(value) => {
                                            const new_value_object = {
                                                custom_date_config: value
                                            };
                                            setDefaultValue(new_value_object)
                                        }}
                                        none_value={false}
                                    />
                                </div>
                            )} */}
                            

                            {open_block_key === 'single_date' && <React.Fragment>
                                <div>
                                    <CustomDatePicker
                                        label={"Select Your Date"}
                                        onChange={(value) => {
                                            const new_value_object = {
                                                single_date: value
                                            };
                                            setDefaultValue(new_value_object);

                                        }}
                                        value={defaultValue?.['single_date']}
                                    />
                                </div>
                            </React.Fragment>}

                            {open_block_key === 'range' && <React.Fragment>
                                <div>
                                    <div style={{ marginBottom: '10px' }}>
                                        <CustomDatePicker
                                            label={"Start Date"}
                                            onChange={(value) => {
                                                const new_value_object = {
                                                    range_start_date: value,
                                                    range_end_date: defaultValue?.range_end_date
                                                };
                                                setDefaultValue(new_value_object);

                                            }}
                                            value={defaultValue?.['range_start_date']}
                                        />
                                    </div>
                                    <CustomDatePicker
                                        label={"End Date"}
                                        onChange={(value) => {
                                            const new_value_object = {
                                                range_start_date: defaultValue?.range_start_date,
                                                range_end_date: value
                                            };
                                            setDefaultValue(new_value_object);

                                        }}
                                        value={defaultValue?.['range_end_date']}
                                    />
                                </div>
                            </React.Fragment>}


                            {/* {open_block_key === 'custom_date' && <React.Fragment>
                                <div>
                                    <CustomDatePicker
                                        label={"Select Start Date"}
                                        type='date'
                                        onChange={(value) => {
                                            const new_value_object = {
                                                ...defaultValue,
                                                date_from: value,
                                                single_date: undefined,
                                            };
                                            setDefaultValue(new_value_object);
                                        }} value={defaultValue?.date_from} />
                                </div>

                                <div style={{ marginTop: '.6rem' }}>
                                    <CustomDatePicker type='date'
                                        label='Select End Date'
                                        defaultValue={defaultValue?.['date_to']}
                                        onChange={(value) => {
                                            const new_value_object = {
                                                ...defaultValue,
                                                date_to: value,
                                                single_date: undefined,
                                            };
                                            setDefaultValue(new_value_object);
                                        }} value={defaultValue?.date_to} />
                                </div>
                            </React.Fragment>} */}

                            {open_block_key === 'month' && <React.Fragment>
                                <div>
                                    <SelectOptionV1
                                        label='Select Year'
                                        options={['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025']}
                                        value={defaultValue?.year}
                                        on_change={(value) => {
                                            const new_value_object = {
                                                ...defaultValue,
                                                date_to: undefined,
                                                single_date: undefined,
                                                date_to: undefined,
                                                range_end_date: undefined,
                                                range_start_date: undefined,
                                                year: value
                                            };
                                            setDefaultValue(new_value_object)
                                        }}
                                        none_value={false}
                                    />
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <SelectOptionV1
                                        label='Select Month'
                                        options={months_selections}
                                        value={defaultValue?.month}
                                        display_key={"key"}
                                        value_key={"value"}
                                        on_change={(value) => {
                                            const new_value_object = {
                                                ...defaultValue,
                                                date_to: undefined,
                                                single_date: undefined,
                                                date_to: undefined,
                                                range_end_date: undefined,
                                                range_start_date: undefined,
                                                month: value
                                            };
                                            setDefaultValue(new_value_object)
                                        }}
                                        none_value={false}
                                    />
                                </div>
                            </React.Fragment>}

                            {open_block_key === 'year' && <React.Fragment>
                                <div>
                                    <SelectOptionV1
                                        label='Select Year'
                                        options={['2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025']}
                                        value={defaultValue?.year}
                                        on_change={(value) => {
                                            const new_value_object = {
                                                ...defaultValue,
                                                date_to: undefined,
                                                single_date: undefined,
                                                date_to: undefined,
                                                year: value,
                                                range_end_date: undefined,
                                                range_start_date: undefined,
                                                month: undefined
                                            };
                                            setDefaultValue(new_value_object)
                                        }}
                                        none_value={false}
                                    />
                                </div>
                            </React.Fragment>}
                        </div>
                    </div>
                </div>
            </DefaultDateWrapper>
        )

    }




    return (
        <DisplayFiledWrapper isCheckboxHide={isCheckboxHide} width={width}>

            {!is_go_next && (
                <React.Fragment>
                    {!isSelectFilterValue && (
                        <React.Fragment>
                            <div style={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                justifyContent: `${hideSearchbox ? 'flex-start' : 'space-between'}`,
                                flexDirection: 'row',
                                marginBottom: '27px',
                            }}>


                                {!hideSearchbox && (
                                    <div style={{ width: '55%', padding: '0px 15px', boxSizing: 'border-box' }}>
                                        <TextBox
                                            type="text"
                                            label="Type here to quick search"
                                            value={searchValue}
                                            onChange={(e) => setSearchValue(e.target.value)}
                                        />
                                    </div>
                                )}

                                {hideSearchbox && (
                                    <ATozTabWrapper >
                                        {aTozArray && aTozArray.length > 0 && aTozArray.map((value, index) => {
                                            let IsActive = searchValue && searchValue === value;
                                            return (
                                                <div
                                                    className="tab_value"
                                                    style={{ backgroundColor: IsActive ? '#eaeaea' : undefined }}
                                                    key={index}
                                                    onClick={() => scrollToView(index)}
                                                >
                                                    {value}
                                                </div>
                                            )
                                        })}
                                    </ATozTabWrapper>
                                )}
                            </div>

                            <DisplayColumnData isCheckboxHide={isCheckboxHide} >
                                <ul>
                                    {selectedFields && Array.isArray(selectedFields) && selectedFields.length > 0 && (
                                        <React.Fragment>
                                            <li className="sort_title">✓</li>
                                            {selectedFields && selectedFields.map((selected, _index) => {
                                                return (
                                                    <React.Fragment key={"selected" + _index}>
                                                        <li
                                                            className="item"
                                                            onClick={() => {
                                                                on_item_click_v2(selected.column_name)
                                                            }}
                                                        >
                                                            <RadioCheckMark checked={compare_value_with_selected_item(selected.column_name, tempSelectedVal)} />
                                                            <span
                                                                title={selected.column_name}
                                                                className="item_name"
                                                            // title={titleOnHover && titleHeading ? `${titleHeading + ':- ' + (Array.isArray(titleOnHover[selected]) ? titleOnHover[selected].join(', ') : titleOnHover[selected])}` : selected}
                                                            >
                                                                {selected.column_name}
                                                            </span>
                                                        </li>
                                                    </React.Fragment>
                                                )
                                                // }
                                            })}
                                        </React.Fragment>
                                    )}


                                    {sorted_display_items && sorted_display_items.length > 0 &&
                                        sorted_display_items.map((item, index) => {

                                            const boo = prevLetter === item.trim().substring(0, 1).toLowerCase() ? true : false;
                                            prevLetter = item.trim().substring(0, 1).toLowerCase();
                                            const firstLetter = item.trim().substring(0, 1).toLowerCase();

                                            return (
                                                <React.Fragment
                                                >
                                                    {boo === false &&
                                                        <li
                                                            className="sort_title"
                                                            ref={elRefs.current[aTozArray.indexOf(firstLetter)]}
                                                        >
                                                            {item.substring(0, 1)}
                                                        </li>
                                                    }

                                                    <li
                                                        className="item"
                                                        onClick={() => {
                                                            on_item_click_v2(item)
                                                        }}
                                                    >

                                                        <RadioCheckMark
                                                            checked={compare_value_with_selected_item(item, tempSelectedVal)} />
                                                        <span
                                                            className="item_name"
                                                            title={item}
                                                        >
                                                            {item}
                                                        </span>
                                                    </li>

                                                </React.Fragment>
                                            )
                                        })
                                    }


                                    {!(sorted_display_items && sorted_display_items.length > 0) &&
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%',
                                                marginTop: '30px'

                                            }}
                                        >No related fields found</div>
                                    }

                                </ul>

                            </DisplayColumnData>
                        </React.Fragment>

                    )}
                </React.Fragment>
            )}





            {is_go_next && (
                <DefaultFilterValuePicker />
            )}


            <Gap h="1.643rem" />
            <div>


                {!is_go_next && (
                    <Button
                        primary={true}
                        width="6rem"
                        mR="16px"
                        onClick={() => set_is_go_next(true)}>Next</Button>

                )}

                {is_go_next && (
                    <Button
                        primary={true}
                        width="6rem"
                        mR="16px"
                        onClick={() => {
                            setSelectedFields(tempSelectedVal);
                            if (defaultValue) {
                                const temp_default_value = defaultValue ? defaultValue : {};
                                setFilterDefaultValues(temp_default_value)
                            }
                            set_is_hierarchy_filter(local_is_hierarchy_filter)
                            close(option, popupKey)
                        }}>{buttonText ? buttonText : 'Save'}</Button>
                )}

                {is_go_next && (
                    <Button
                        width="6rem"
                        bgColor="#9caeb5"
                        mR="16px"
                        onClick={() => set_is_go_next(false)}>Back</Button>
                )}
            </div>

        </DisplayFiledWrapper>
    )
}
export default SelectDisplayField;
