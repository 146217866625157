import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TextBox from '../hoc/textBox';
import TextAreaV1 from '../hoc/text.area.v1';
import { Button } from '../hoc/button';
import HintsAdd from '../hints.add';
import HintSearch from '../hints.search';
import SelectOption from '../select-option';
import { connect } from 'react-redux';
import TextBoxV1 from '../hoc/text.box.v1';
import SelectOptionV1 from '../select-option/select.option.v1.1';
import { constants } from '../../utils/constants';


const PopupWrapper = styled.div`

    ._header{
        background: #cccccc3d;
        margin-top: -7px;
        margin-left: -20px;
        margin-right: -20px;
        text-align: center;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 1.2rem;
        border-bottom: 1px solid #ccc;
        color: #222c;
        margin-bottom: 1rem;
}
    width: 100%;
    min-height: 30rem;
    /* padding: 0 0.8rem 0.8rem 0.8rem; */
    display: flex;
    flex-direction: column;
    background-color: #fff;
    max-height: 80rem;
    box-sizing: border-box;
    padding: 0px 10px;
    padding-bottom: 1rem;

    .row{
        width: 100%;
        margin-bottom: 1rem;
    }

    h1{
        font-size: 1.5rem;
        font-weight: 500;
        text-align: center;
        margin-top: 0px;
    }
`;

const _data_types = [
    {
        name: 'String',
        id: 'string',
    },
    {
        name: 'Date',
        id: 'date',
    },
    {
        name: 'Currency',
        id: 'currency',
    },
    {
        name: 'Number',
        id: 'number',
    }
]

const _frequency = [
    { name: 'Monthly', id: 'monthly' },
    { name: 'Daily', id: 'daily' },
    { name: 'Weekly', id: 'weekly' },
    { name: 'Hourly', id: 'hourly' },
    { name: 'Minutely', id: 'minutes' },
    { name: 'Event Based', id: 'event_based' },
]

const _notifications = [
    { name: 'Email', id: 'email' },
    { name: 'SMS', id: 'sms' },
    { name: 'WhatsApp', id: 'whatsapp' },
]



const AddEditPopup = (props) => {

    // poops and popup data here
    const { close, popupKey, option, data } = props;
    const { popupData, _saveFun, } = data;


    // here are state of the component 
    const [formData, setFormData] = useState(undefined)
    const [recipients, set_recipients] = useState(undefined)
    const [view_rule_access, set_view_rule_access] = useState(undefined)
    const [assigned_to, set_assigned_to] = useState(undefined)
    const [notification_methods, set_notification_methods] = useState(undefined)





    useEffect(() => {
        setFormData(popupData)
        if (popupData && popupData.view_rule_access) {
            set_view_rule_access(popupData.view_rule_access)
        }
        if (popupData && popupData.assigned_to) {
            set_assigned_to(popupData.assigned_to)
        }
        if (popupData && popupData.recipients) {
            set_recipients(popupData.recipients)
        }
        if (popupData && popupData.alert_type) {
            set_notification_methods(popupData.alert_type)
        }

    }, [popupData])




    const _onChange = (key, value) => {
        let cloneFormData = formData ? Object.assign({}, formData) : {};
        cloneFormData[key] = value;
        setFormData(cloneFormData)
    }


    const _save = () => {

        let clone_form_data = formData ? Object.assign({}, formData) : {};
        clone_form_data.recipients = recipients;
        clone_form_data.view_rule_access = view_rule_access
        clone_form_data.assigned_to = assigned_to
        clone_form_data.alert_type = notification_methods;
        // clone_form_data.rule_query = "give me departments which generated less than 10000 in revenue for consecutive 2 months";
        clone_form_data.modified = true;
        _saveFun(clone_form_data)
        close(option, popupKey)
    }

    const disabled = false
    // console.log("assigned_to", assigned_to)


    return (
        <PopupWrapper>

            <div className='_header'>
                Business Rule
            </div>

            <div className="row">
                <TextBoxV1
                    label="Rule Name"
                    type="text"
                    value={formData && formData.name ? formData.name : ''}
                    onChange={(e) => _onChange('name', e.target.value)}
                    height={"2.6rem"}
                    labelStyle={{
                        color: 'rgb(0 0 0 / 39%)'
                    }}
                />
            </div>
            <div className="row">
                <TextAreaV1
                    height={"5rem"}
                    label="Define Your Rule"
                    type="text"
                    value={formData && formData.rule_query ? formData.rule_query : ''}
                    onChange={(e) => _onChange('rule_query', e.target.value)}
                    labelStyle={{
                        color: 'rgb(0 0 0 / 39%)'
                    }}
                />
            </div>


            <div className="row" style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>

                <div style={{ width: '47%' }}>
                    <SelectOptionV1
                        width="100%"
                        label="How often it should Run"
                        options={constants.JOB_FREQUENCYS}
                        display_key="name"
                        value_key="id"
                        none_value={false}
                        value={formData && formData.frequency_type ? formData.frequency_type : undefined}
                        on_change={(value) => {
                            _onChange('frequency_type', value)
                        }}
                        height={"2.6rem"}
                        labelStyle={{
                            color: 'rgb(0 0 0 / 39%)'
                        }}
                    />
                </div>

                <div style={{ width: '47%' }}>
                    <SelectOptionV1
                        width="100%"
                        label="Notification Method"
                        options={_notifications}
                        display_key="name"
                        multiple={true}
                        checkbox={true}
                        value_key="id"
                        none_value={false}
                        value={notification_methods?.length > 0 && notification_methods || undefined}
                        on_change={(value) => {
                            const clone_notification_methods = notification_methods ? [...notification_methods] : [];
                            if (clone_notification_methods.indexOf(value) > -1) {
                                clone_notification_methods.splice(clone_notification_methods.indexOf(value), 1);
                            }
                            else clone_notification_methods.push(value);
                            set_notification_methods(clone_notification_methods)
                        }}
                        height={"2.6rem"}
                        labelStyle={{
                            color: 'rgb(0 0 0 / 39%)'
                        }}


                    />
                </div>
            </div>

            <div className="row" style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
                <div style={{ width: '47%' }}>
                    <SelectOptionV1
                        width="100%"
                        label="Action Owner"
                        options={props.usersList?.users || []}
                        display_key="name"
                        multiple={true}
                        checkbox={true}
                        none_value={false}
                        value_key="id"
                        value={assigned_to?.length > 0 && assigned_to || undefined}
                        on_change={(value) => {
                            const clone_assigned_to = assigned_to ? [...assigned_to] : [];
                            if (clone_assigned_to.indexOf(value) > -1) {
                                clone_assigned_to.splice(clone_assigned_to.indexOf(value), 1);
                            }
                            else clone_assigned_to.push(value);
                            set_assigned_to(clone_assigned_to)
                        }}
                        height={"2.6rem"}
                        labelStyle={{
                            color: 'rgb(0 0 0 / 39%)'
                        }}


                    />
                </div>
                <div style={{ width: '47%' }}>
                    <SelectOptionV1
                        width="100%"
                        label="Rule Access"
                        options={props.accessGroupList || []}
                        display_key="name"
                        multiple={true}
                        checkbox={true}
                        none_value={false}
                        value_key="id"
                        value={view_rule_access?.length > 0 && view_rule_access || undefined}
                        on_change={(value) => {
                            const clone_view_rule_access = view_rule_access ? [...view_rule_access] : [];
                            if (clone_view_rule_access.indexOf(value) > -1) {
                                clone_view_rule_access.splice(clone_view_rule_access.indexOf(value), 1);
                            }
                            else clone_view_rule_access.push(value);
                            set_view_rule_access(clone_view_rule_access)
                            // _onChange('data_type', value)
                        }}
                        height={"2.6rem"}
                        labelStyle={{
                            color: 'rgb(0 0 0 / 39%)'
                        }}

                    />
                </div>
            </div>

            <div className="row">
                <HintsAdd
                    hints={recipients}
                    setHints={set_recipients}
                    label="Who else Should be Notified"
                    existing_label="Existing Recipients"
                    is_new_text_box={true}
                    text_box_height={"2.6rem"}
                    labelStyle={{
                        color: 'rgb(0 0 0 / 39%)'
                    }}
                />
            </div>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: 'auto',
                marginLeft: 'auto'

            }}>
                <Button
                    primary={true}
                    disabled={disabled}
                    onClick={() => _save()}
                    mR="1rem"
                    width={'100px'}

                >
                    Save
                </Button>

                <Button
                    width={'100px'}
                    onClick={() => {
                        setFormData(undefined);
                        close(option, popupKey)
                    }}
                    bgColor={'#e6e6e6'}
                    mR="0rem"

                >
                    Cancel
                </Button>
            </div>

        </PopupWrapper>
    )
}


const mapStateToProps = (state, props) => ({
    hints: state.reportReducer.hints,
    accessGroupList: state.groupAccessReducer.accessGroupList,
    usersList: state.accountReducer.usersList
})

export default connect(mapStateToProps, {})(AddEditPopup);
