import React, { useEffect } from 'react'
import { PageInnerWrapper } from '../hoc/styled.hoc';
import { showPopup } from '../../utils';
import enums from '../../utils/enums';
import SharedTable from '../shared-table';
import { getHintsForRules, saveHintOfRules, getTableColumnNameForHints } from '../../actions/hints';
import { connect } from 'react-redux';
import { Button } from '../hoc/button';
import { withRouter } from 'react-router-dom';
import AddEditPopup from './add.edit.popup';



const Index = (props) => {

    const { rulesHints, getHintsForRules, tableColumnsHints, _hints_ } = props;

    const raiseAddEditPopup = (item) => {
        showPopup((item ? 'Edit Interpretation' : 'Add Interpretation'), undefined, enums.popupType.element_with_header, AddEditPopup, {
            popupData: item ? item : undefined,
            _saveFun: props.saveHintOfRules,
            hintsData: _hints_,
            table_name: 'nf_human_interpretation_nf'
        }, undefined, undefined, undefined, {
            width: '33rem',
        });
    }



    useEffect(() => {
        getHintsForRules("nf_human_interpretation_nf")
        props.getTableColumnNameForHints()
    }, [])




    useEffect(() => {
        let _page_info = {
            title: 'Define Human Interpretation',
            extraComponent: <Button primary={true} onClick={() => {
                raiseAddEditPopup()
            }}>
                Add New
            </Button>
        }
        props.dispatchHeaderInformation(_page_info)

    }, [_hints_])


    return (
        <div>
            <PageInnerWrapper padding={'10px'} style={{ marginTop: '1rem' }}>
                <SharedTable
                    height={"calc(100vh - 180px)"}
                    editAction={(item) => {
                        raiseAddEditPopup(item)
                    }}
                    deleteAction={(item) => {
                        let tempItem = item ? Object.assign({}, item) : {}
                        tempItem.is_deleted = true;
                        props.saveHintOfRules(tempItem, "nf_human_interpretation_nf")
                    }}
                    duplicateAction={() => { }}
                    tableData={(rulesHints && rulesHints.length > 0) ? rulesHints : undefined}
                    tableHead={["  #  ", "Your Phrase", "Your Interpretation", 'Action']}
                    columnsToShow={["row_index", "column_name", "values",]}
                />
                {rulesHints == undefined || rulesHints.length == 0 ? <p style={{ textAlign: "center" }}>No Record Found</p> : null}
            </PageInnerWrapper>

        </div>
    )

}



const mapStateToProps = (state) => ({
    rulesHints: state.hintsReducer.rulesHints,
    tableColumnsHints: state.hintsReducer.tableColumnsHints,
    _hints_: state.reportReducer.hints

})

export default withRouter(connect(mapStateToProps, { getHintsForRules, saveHintOfRules, getTableColumnNameForHints })(Index))