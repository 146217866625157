import React from 'react';

const Table = (props) => {
    return (
        <svg
            {...props}
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            space="preserve">
            <g>
                <g id="svg_1">
                    <path id="svg_2" d="m21.0885,23.829l-18.301,0c-1.477,0 -2.679,-1.201 -2.679,-2.678l0,-18.301c0,-1.477 1.202,-2.678 2.679,-2.678l18.301,0c1.477,0 2.678,1.201 2.678,2.678l0,18.3c0,1.477 -1.201,2.679 -2.678,2.679zm-18.301,-22.358c-0.76,0 -1.379,0.619 -1.379,1.378l0,18.301c0,0.76 0.619,1.378 1.379,1.378l18.301,0c0.76,0 1.378,-0.619 1.378,-1.378l0,-18.3c0,-0.76 -0.618,-1.378 -1.378,-1.378l-18.301,0l0,-0.001z" />
                    <path id="svg_3" d="m21.8815,5.329l-19.888,0c-1.04,0 -1.885,-0.845 -1.885,-1.884c0,-0.359 0.291,-0.65 0.65,-0.65s0.65,0.291 0.65,0.65c0,0.322 0.263,0.584 0.585,0.584l19.888,0c0.322,0 0.585,-0.262 0.585,-0.584c0,-0.359 0.291,-0.65 0.65,-0.65s0.65,0.291 0.65,0.65c0,1.038 -0.845,1.884 -1.885,1.884z" />
                    <g id="svg_4">
                        <path id="svg_5" d="m6.5245,23.651c-0.359,0 -0.65,-0.291 -0.65,-0.65l0,-18.192c0,-0.359 0.291,-0.65 0.65,-0.65s0.65,0.291 0.65,0.65l0,18.192c0,0.359 -0.291,0.65 -0.65,0.65z" />
                        <path id="svg_6" d="m11.9435,23.651c-0.359,0 -0.65,-0.291 -0.65,-0.65l0,-18.192c0,-0.359 0.291,-0.65 0.65,-0.65s0.65,0.291 0.65,0.65l0,18.192c0,0.359 -0.291,0.65 -0.65,0.65z" />
                        <path id="svg_7" d="m17.3635,23.651c-0.359,0 -0.65,-0.291 -0.65,-0.65l0,-18.192c0,-0.359 0.291,-0.65 0.65,-0.65s0.65,0.291 0.65,0.65l0,18.192c-0.001,0.359 -0.292,0.65 -0.65,0.65z" />
                    </g>
                    <g id="svg_8">
                        <path id="svg_9" d="m22.6595,19.273l-21.444,0c-0.359,0 -0.65,-0.291 -0.65,-0.65s0.291,-0.65 0.65,-0.65l21.444,0c0.359,0 0.65,0.291 0.65,0.65s-0.291,0.65 -0.65,0.65z" />
                        <path id="svg_10" d="m22.6595,14.534l-21.444,0c-0.359,0 -0.65,-0.291 -0.65,-0.65s0.291,-0.65 0.65,-0.65l21.444,0c0.359,0 0.65,0.291 0.65,0.65s-0.291,0.65 -0.65,0.65z" />
                        <path id="svg_11" d="m22.6595,9.794l-21.444,0c-0.359,0 -0.65,-0.291 -0.65,-0.65s0.291,-0.65 0.65,-0.65l21.444,0c0.359,0 0.65,0.291 0.65,0.65s-0.291,0.65 -0.65,0.65z" />
                    </g>
                </g>
            </g>
        </svg>

    )

}


export default Table;