import React from 'react';

const ThreeDot = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}

            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 384 384"
            space="preserve"

        >

            <g>
                <g>
                    <circle cx="192" cy="42.667" r="42.667" />
                </g>
            </g>
            <g>
                <g>
                    <circle cx="192" cy="192" r="42.667" />
                </g>
            </g>
            <g>
                <g>
                    <circle cx="192" cy="341.333" r="42.667" />
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )

}


export default ThreeDot;