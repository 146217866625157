import React, { useEffect, useState } from 'react'
import { PageInnerWrapper } from '../../hoc/styled.hoc';
import SharedTable from '../../shared-table';
import { connect } from 'react-redux';
import { Button } from '../../hoc/button';
import { withRouter } from 'react-router-dom';
import { getAllDbFormula, deleteDbFormula } from '../../../actions/db.formula'


const Main = (props) => {
    const [isLoding, setIsloding] = useState(true);


    const { dbFormulas, getAllDbFormula } = props;

    const [localDbFormulas, setLocalDbFormulas] = useState(undefined)

    useEffect(() => {
        if (!dbFormulas || dbFormulas.length == 0) {
            props.getAllDbFormula()
        }
    }, [])



    useEffect(() => {
        let _page_info = {
            title: 'Formula List',
            extraComponent: <Button primary={true} onClick={() => {
                //
                props.history.push("/formula_builder")

            }}>
                Add New Formula
            </Button>
        }
        props.dispatchHeaderInformation(_page_info)
    }, [])




    useEffect(() => {

        const clone_db_formula = dbFormulas ? [...dbFormulas] : [];
        clone_db_formula.map((formula) => {
            formula.created_by = formula?.created_user?.name
        })
        setLocalDbFormulas(clone_db_formula)

        if(dbFormulas?.length > -1){
            setIsloding(false);
        }

    }, [dbFormulas])




    return (
        <div>
            <PageInnerWrapper padding={'10px'} style={{ marginTop: '1rem' }}>
                <SharedTable
                    isLoding={isLoding}
                    height={"calc(100vh - 180px)"}
                    editAction={(item) => {
                        props.history.push(`/formula_builder?formula_id=${item.id}`)
                    }}

                    deleteAction={(item) => {
                        props.deleteDbFormula(item?.id)
                    }}

                    duplicateAction={() => { }}
                    tableData={(localDbFormulas && localDbFormulas.length > 0) ? localDbFormulas : undefined}
                    tableHead={["Name", "Conditions", "Author", "Created At", "Action"]}
                    sortColumns={{
                        "Name": {
                            type: "string",
                            dataKey: 'name'
                        },
                        "Created At": {
                            type: "date",
                            dataKey: 'created_at'
                        },

                        "Author": {
                            type: "string",
                            dataKey: 'sss'
                        },
                    }}
                    columnsToShow={["name", "case_statement", "created_by", "created_at"]}
                    date_columns={['created_at', 'modified_at']}
                    column_body_styles={{
                        "name": {
                            fontWeight: 'bold',
                            fontSize: '.8rem'
                        }
                    }}
                />
                {(typeof dbFormulas == 'undefined' || dbFormulas.length == 0 ) && !isLoding ? <p style={{ textAlign: "center" }}>No Record Found</p> : null}
            </PageInnerWrapper>
        </div>
    )

}



const mapStateToProps = (state) => ({
    dbFormulas: state.dbFormula.dbFormulas
})

export default withRouter(connect(mapStateToProps, { getAllDbFormula, deleteDbFormula })(Main))