import styled from 'styled-components';



export const DropDownWrapper = styled.div`
    width: ${props => props.width || '15rem'};
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

`;

export const Label = styled.div`
    padding: 6px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    border-radius: 2px;
    font-size: 1rem;
    /* background: ${props => props.active ? '#dbd9db' : 'transparent'}; */
    cursor: pointer;
    color: #554d56;
    background-color: #eaeaea;


    &:hover{
        background: #eaeaea;
    }

    & > span{
        display: inline-block;
        margin-left: 10px;
    }
`;

export const DropDownMenu = styled.div`
    width: ${props => props.width || '100%'};
    position: absolute;
    top: 37px;
    left: 0px;
    background-color: #fff;
    /* box-shadow: 0px 4px 4px #ccc; */
    box-shadow:0 2px 4px 0 #554d5680;
    
    & > div{
        font-size: 1rem;
        padding: 7px 10px;
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #554d56;


        /* span{

        } */
        i{
            color: #008ac7;
            font-size: 1rem;
        }

        &:hover{
            background-color: #f7f7f7;
        }
    }
`;