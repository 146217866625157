import React from 'react';
import styled from 'styled-components';
import { constants } from '../../utils/constants';
import Overlay from '../helper/overlay'


const Wrapper = styled.div`

    width: 400px;
    height: 200px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 6px;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0;
    bottom: 0;
    z-index: 11;
    margin: auto;
    

    h1{
        font-size: 1.2rem;
        font-weight: 500;
        text-align: center;
    }

`;



const DownloadingStatus = ({ status }) => {

    return (
        <React.Fragment>
            {status && (
                <React.Fragment>
                    <Overlay zIndex={10} bgColor="rgb(0 0 0 / 59%)" />
                    <Wrapper>
                        <img src={constants.CONTEXT_PATH + status.image} style={{ width: '110px', }} />
                        <h1 style={{ marginTop: '1rem' }}>{status.title}</h1>
                    </Wrapper>
                </React.Fragment>
            )}

        </React.Fragment>
    );
}

export default DownloadingStatus;
