import React, { useEffect } from 'react'
import InsightSingleData from '../insight-single-data';
import ChartWrapper from '../chart-wrapper';
import Table from '../table-wrapper';
import { CHART_TYPES, RENDER_MODE } from '../../shared-with-fe/constants';
import { set_table_data_mode } from '../../actions/helper'
import { connect } from 'react-redux';
import TowerChart from '../charts/tower-chart';
import { CHART_CONSTANTS } from '../../utils/chart.config';



const Restricted_view = (props) => {


    /**
     * destructuring the props here 
     */




    const {
        row_data_information,
        pivot_data_information,
    } = props;




    const {

        showSingleCell,
        isTrendFilters,
        width,
        height,
        insights,
        ultimate_yacs,
        loading,
        isDiscovery,
        question,
        renderMode,
        getDataFromReportingServer,
        dimensions,
        chartXDataKeys,
        childDimension,
        reportItem,
        singleCellData,
        title,
        pivot_data_ultimate_yacs,
        tableDataMode,
        pivot_axis,
        raw_axis,
        pivot_data,
        pivot_column_aligments,
        raw_column_aligments

    } = props;




    let table_data_force_mode = (pivot_data?.length > 0 && pivot_axis?.yac?.length > 0) ? "pivot_data" : "raw_data"


    const { raw_data_grand_total } = props;

    const table_data = tableDataMode === 'pivot_data' ? pivot_data : row_data_information?.data;
    const table_xac = tableDataMode === 'pivot_data' ? pivot_axis?.xac : raw_axis?.xac;
    const table_yac = tableDataMode === 'pivot_data' ? pivot_axis?.yac : raw_axis?.yac;

    const table_alignments = tableDataMode === 'pivot_data' ? pivot_column_aligments : raw_column_aligments;

    const table_grand_total_columns = table_data_force_mode === "pivot_data" && pivot_data ? props.pivot_data_grand_total : raw_data_grand_total;


    let render_mode_to_use = renderMode || 'table';

    const pivot_xac_indexes_count = (table_data?.length || 0) * (table_xac?.length || 0);

    if (props?.chartType === CHART_TYPES.scatter_chart && pivot_xac_indexes_count > CHART_CONSTANTS.MAX_SCATTER_DATA_LIMIT) {
        render_mode_to_use = 'table'
    }
    if (pivot_xac_indexes_count > CHART_CONSTANTS.MAX_CHART_DATA_LIMIT && props?.chartType !== CHART_TYPES.scatter_chart) {
        render_mode_to_use = 'table'
    }

    const process_data_updated_at = tableDataMode === 'pivot_data' ? undefined : props.process_data_updated_at




    return (
        <div>

            {
                props.chartType === CHART_TYPES.tower_chart && (
                    <TowerChart
                        data={row_data_information?.data}
                        xAxis={[...raw_axis?.xac, ...raw_axis?.yac]}
                        width={width}
                        height={height}
                        columnMeta={table_alignments}
                        id={props.id}
                        theme_json_values={props.theme_json_values}

                    />
                )
            }


            {
                render_mode_to_use === RENDER_MODE.table && (!showSingleCell) && !isTrendFilters &&
                <Table
                    {...props}
                    sortedData={table_data}
                    insights={insights || isDiscovery}
                    // columnsAligments={ultimate_yacs}
                    // table_grand_total_columns={props.raw_data_grand_total}
                    xDataKeys={table_xac}
                    yDataKeys={table_yac}
                    getDataFromReportingServer={getDataFromReportingServer ? getDataFromReportingServer : () => { }}
                    columnsAligments={table_alignments}
                    table_data_force_mode={table_data_force_mode}
                    table_grand_total_columns={table_grand_total_columns}
                    process_data_updated_at={process_data_updated_at}

                />
            }

            {
                props.chartType !== "tower_Chart" && render_mode_to_use === RENDER_MODE.chart && (!showSingleCell || props.chartType === "gauge_chart") && pivot_data &&
                <ChartWrapper
                    {...props}
                    data={pivot_data}
                    yDataKeys={pivot_axis?.yac}
                    xDataKeys={pivot_axis?.xac}
                    widthOfChart={width}
                    getDataFromReportingServer={getDataFromReportingServer ? getDataFromReportingServer : () => { }}
                    heightOfChart={height}
                    insights={insights}
                    dimensions={(dimensions && dimensions.length > 0) ? dimensions : undefined}
                    isTop={chartXDataKeys && chartXDataKeys.length > 0 ? true : undefined}
                    ultimate_yacs={ultimate_yacs}
                    columnsAligments={pivot_column_aligments}
                />
            }


            {
                (showSingleCell && props.chartType !== "gauge_chart") && !loading && insights && !childDimension &&
                <InsightSingleData
                    {...props}
                    od_report={props?.od_report}
                    sortedData={table_data}
                    yDataKeys={pivot_axis?.yac}
                    xDataKeys={pivot_axis?.xac}
                    hideTitle={reportItem.hide_title}
                    singleData={singleCellData}
                    title={title}
                    isDiscovery={isDiscovery}
                    question={question}
                    heightOfChart={height}
                    width={width}
                    columnsAligments={pivot_column_aligments || ultimate_yacs}
                />
            }

        </div>
    )
}


const mapStateToProps = (state, props) => {
    const t_data_mode = state.helperReducer.tableDataMode;
    const tableDataMode = t_data_mode && t_data_mode[props.id];
    return { tableDataMode }
}

export default connect(mapStateToProps, { set_table_data_mode })(Restricted_view);
