import React from "react";

const AskYourQuest = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2026 2048"
    >
      <path
        transform="translate(29)"
        d="m0 0h9l9 1 50 1h754l187-1h619l141 1h67l68-1 49 1 21 1 12 2 11 7 7 6 4 8 2 11v1933l-1 12-4 12-7 9-8 4h-2l-2 4-59 1-175 1-846 1h-606l-251-1-50-2-10-6-5-5-7-9-3-6-2-2-1-39v-1906l3-1 1-6 5-12 8-9 10-7zm821 79-770 1v321l1 1h1878l1-1 1-17v-303l-1-1-121-1zm-769 399-1 9v1447h1881v-1451l-2-5z"
        fill="#07325A"
      />
      <path
        transform="translate(900,808)"
        d="m0 0h27l31 3 23 4 19 5 26 9 31 15 21 13 18 13 13 11 8 7 20 20 9 11 12 16 13 21 9 17 9 20 7 20 6 21 5 26 2 16 1 15v34l-3 24-4 23-8 28-10 25-9 19-11 19-14 20-9 12-11 13-17 17-14 11-13 10-20 13-18 10-17 8-27 10-26 7-24 4-23 2h-44l-27-3-24-5-33-11-25-11-20-11-12-8-16-12-14-12-31-31-11-14-10-14-11-19-10-19-10-25-9-29-6-29-3-28v-32l5-35 6-27 8-24 9-21 8-16 9-16 15-22 22-26 17-17 11-9 17-13 17-11 22-12 22-10 27-9 25-6 21-3zm-5 83-22 3-19 5-21 7-17 8-20 12-16 12-13 11-15 15-10 13-12 19-11 21-9 22-7 30-3 19-1 22 2 21 5 26 6 20 10 23 11 19 12 16 12 14 17 17 11 9 14 10 26 14 23 9 23 6 16 3 21 2h17l24-3 28-7 27-11 17-9 18-12 13-10 15-13 10-11 14-19 11-18 8-17 8-22 6-25 2-14v-47l-2-15-6-24-7-19-10-21-12-19-8-11-11-13-15-15-17-13-18-11-14-8-24-10-21-6-22-4-7-1z"
        fill="#D7DCE0"
      />
      <path
        transform="translate(1196,1292)"
        d="m0 0h15l8 4 10 9 14 14 7 8 8 8 7 8 15 16 7 8 12 13 9 11 9 9 7 8 11 12 12 13 7 8 18 20 12 13 7 8 13 14 7 8 12 13 10 13 4 9v11l-5 10-9 10-12 12-8 7-15 14-11 9-7 7-8 7-10 9-13 11-17 10-4-1-9-4-10-7-9-9-7-8-18-20-11-12-9-10-9-9-7-8-9-10-14-15-7-8-11-12-7-8-10-11-7-8-14-15-7-8-14-15-11-12-7-8-12-13-9-13-3-9 1-13 6-10 4-4h2l2-4 10-9 15-14 10-8 15-14 8-7 24-22 6-4z"
        fill="#D7DCE0"
      />
      <path
        transform="translate(678,142)"
        d="m0 0h24l17 3 15 6 11 6 13 10 11 11 9 14 7 14 6 19 1 5v27l-4 16-5 13-9 16-10 13-12 11-16 9-16 6-10 2-10 1h-26l-17-3-15-6-11-7-10-8-9-9-8-11-9-17-6-18-2-10v-22l4-17 5-14 6-11 8-11 9-10 13-11 14-8 15-6zm11 78-9 2-9 6-4 7-1 4v8l3 8 8 8 5 3 4 1h9l11-7 6-7 1-2v-13l-4-8-5-5-8-4z"
        fill="#07315A"
      />
      <path
        transform="translate(220,142)"
        d="m0 0h25l17 3 14 5 18 10 12 11 7 7 9 13 8 16 5 18 2 14v13l-3 15-5 15-9 17-10 13-8 9-14 10-16 8-17 4-10 1h-28l-16-3-15-6-14-9-10-9-9-10-12-20-7-19-3-16v-17l3-16 6-17 6-11 8-11 9-10 11-9 15-9 17-7zm9 78-12 6-5 7-1 4v12l5 10 10 7 3 1h9l8-4 8-7 4-8v-8l-4-9-4-5-8-5-3-1z"
        fill="#06315A"
      />
      <path
        transform="translate(446,142)"
        d="m0 0h28l17 3 16 6 15 9 15 13 11 15 9 17 5 16 2 16v14l-2 16-5 16-6 11-10 14-12 13-12 9-16 8-13 4-16 2h-27l-16-3-16-7-11-7-14-12-9-11-9-15-6-15-4-16v-28l3-14 6-16 9-16 12-14 12-10 16-9 11-5 11-3zm15 78-10 3-8 6-4 8-1 4v7l3 8 7 7 8 4h11l9-5 7-6 2-4v-13l-4-9-5-5-10-5z"
        fill="#07315A"
      />
      <path
        transform="translate(1963,505)"
        d="m0 0 4 1 2 3 1 16v255l1 9-1 11 1 8v90l-1 36-1 2h2v9l-1 9 1 12-1 7 1 16v15l-1 9 1 14v322l-1 82v141l1 7v15l-1 8 1 2v15l-1 7v42l-1 11-2 55 1 105 1 30v23l-1 14h-2l-1 5-2 3-1-14v-1364z"
        fill="#052552"
      />
      <path
        transform="translate(890,402)"
        d="m0 0h1052l-1 2-20 2-16 1h-8l-2 1h-17l-16 1h-15l-45 1h-452l-26-1-32-1-52-1h-22l-52-1h-252l-44-2v-1z"
        fill="#052351"
      />
      <path
        transform="translate(1772,1934)"
        d="m0 0h141v1l-22 2-4 1-17 1-36 1h-65l-16-1h-53l-27-1h-51l-5 1-7-1h-145l-39 1h-56l-31 1h-129l-102-1h-19l-25-1h-53l-73-2v-1z"
        fill="#052351"
      />
      <path
        transform="translate(1961,487)"
        d="m0 0h1l1 5v84l-1 1350h-1l-1-29v-1380z"
        fill="#E4E7EA"
      />
      <path
        transform="translate(1,37)"
        d="m0 0h3v336l-1 206-1 119h-1l-1-13v-647z"
        fill="#95A7B8"
      />
      <path
        transform="translate(1963,112)"
        d="m0 0 4 2 1 7 1 31v54l-1 45v49l2 26v48l-6 18h-1l-1-14v-239z"
        fill="#052351"
      />
      <path
        transform="translate(1961,88)"
        d="m0 0h1l1 10v41l-1 258-2 3v-269z"
        fill="#D3DAE0"
      />
    </svg>
  );
};

export default AskYourQuest;
