import React, { PureComponent } from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine, Legend, Label } from 'recharts';
import { withRouter } from 'react-router-dom';
import { getRemToPx, yDataValueFormatter, apply_styles, formatValueByDataType, getChartXAxisLayout } from '../../../utils'
import CustomizedAxisTick from '../tick';
import { DataFormaterFixed } from '../yaxis-label';
import CustomToolTip from '../tooltip/tooltip.custom';
import RenderLegend from '../legend/index.new';
import { constants } from '../../../utils/constants';
import { connect } from 'react-redux';




class LineBarChart extends PureComponent {

    render() {

        const {
            yDataKeys, paddingOnXaxis, xDataKeys, insights, showReferenceLine, valueOfReferenceLine,
            data, widthOfChart, heightOfChart, changeColorOfYData, colorForYData, question, getDataFromReportingServer,
            hide_label, hide_title, hide_y_axis, hide_x_axis, hide_y_axis_border, hide_x_axis_border, hide_grid, hide_legend, hide_border, hide_tooltip,
            hide_x_axis_label, hide_y_axis_label, isDimension, dimensions, isDiscovery, showRightYAxis, Alignments, chartStyle, tooltipTheme, show_data_label
        } = this.props;

        //new props
        const { aggregate_options, yac_totals } = this.props;

        const yDataKeyLength = yDataKeys.length;
        const xAxisInterval = 0;
        let chartMargin = {
            top: !hide_x_axis_label ? isDimension ? 0 : (insights || isDiscovery) ? 20 : 50 : heightOfChart < 100 ? 0 : 10,
            right: !hide_y_axis_label ? isDimension ? getRemToPx(1.25) : (insights || isDiscovery) ? getRemToPx(2.5) : getRemToPx(9.375) : 10,
            bottom: isDimension ? 0 : ((hide_x_axis_label && hide_x_axis) ? 0 : hide_x_axis_label ? 0 : hide_x_axis ? 20 : !insights ? (!isDiscovery ? 70 : 20) : 15),
            left: !hide_y_axis_label ? isDimension ? 0 : (insights || isDiscovery) ? getRemToPx(2.5) : getRemToPx(9.375) : 0
        }



        const chart_xaxis_layout = getChartXAxisLayout(widthOfChart, heightOfChart, this.props.xDataKeys, this.props?.data?.length)

        let yAxisLabelOffset = isDimension ? getRemToPx(1.25) : (insights || isDiscovery) ? getRemToPx(-1.5) : getRemToPx(-2.5);
        let xAxisLabelOffset = isDimension ? 7 : (insights || isDiscovery) ? -5 : -20;
        let heightOfXAxis = isDimension ? undefined : ((hide_x_axis_label && hide_x_axis) ? 7 : hide_x_axis_label ? getRemToPx(5.5) : hide_x_axis ? 20 : chart_xaxis_layout?.height);
        let widthOfYAxis = !hide_y_axis ? getRemToPx(4.1) : 5;



        const xAxisStyle = chartStyle.axis && chartStyle.axis.x;
        const yAxisStyle = chartStyle.axis && chartStyle.axis.y;

        const xAxisTickStyle = apply_styles((xAxisStyle && xAxisStyle['tick']), constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT)
        const yAxisTickStyle = apply_styles((yAxisStyle && yAxisStyle['tick']), constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT)
        const xAxisTitleStyle = apply_styles((xAxisStyle && xAxisStyle['title']), constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT)
        const yAxisTitleStyle = apply_styles((yAxisStyle && yAxisStyle['title']), constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT)
        const xAxisTick = !hide_x_axis ? <CustomizedAxisTick xDataKeys={xDataKeys} Alignments={Alignments} totalTick={this.props.data.length} _style={xAxisTickStyle} /> : null;
        const legendStyle = chartStyle && chartStyle.legend;

        console.log("chart_xaxis_layout", chart_xaxis_layout)


        return (
            <div id='chart' style={{ position: 'relative', width: widthOfChart ? (widthOfChart - 0) : '93.1vw', textTransform: 'capitalize' }}>
                <ResponsiveContainer width='100%' height={heightOfChart}>
                    <ComposedChart
                        width={widthOfChart}
                        height={heightOfChart}
                        data={data}
                        margin={chartMargin}
                    >

                        {!hide_grid && !isDimension && (
                            <CartesianGrid strokeDasharray="2 1" vertical={false} horizontalY={false} />
                        )}

                        <XAxis
                            // stroke={chartStyle.color.yAxisStock}
                            dataKey={xDataKeys && xDataKeys.length > 0 && xDataKeys[xDataKeys.length - 1]}
                            padding={{ right: (insights || isDiscovery) ? undefined : paddingOnXaxis, left: 10 }}
                            style={xAxisTickStyle}
                            height={heightOfXAxis}
                            tick={xAxisTick}
                            axisLine={!hide_x_axis_border ? true : false}
                            interval={xAxisInterval}
                            // {...chart_xaxis_layout}
                        >
                            {!hide_x_axis_label &&
                                <Label
                                    angle='0'
                                    position='insideBottom'
                                    offset={xAxisLabelOffset}
                                    style={xAxisTitleStyle}>
                                    {xDataKeys && xDataKeys.length > 0 && xDataKeys[0]}
                                </Label>
                            }
                        </XAxis>

                        <YAxis
                            yAxisId={showRightYAxis ? 'left' : undefined}
                            orientation={showRightYAxis ? 'left' : undefined}
                            // stroke={chartStyle.color.yAxisStock}
                            interval={0}
                            style={yAxisTickStyle}
                            tickFormatter={DataFormaterFixed}
                            tick={!hide_y_axis && !isDimension ? true : false}
                            width={widthOfYAxis}
                            axisLine={!hide_y_axis_border ? true : false}
                            allowDecimals={false}
                            domain={[dataMin => (Math.round(dataMin * 80 / 100)), dataMax => Math.round((dataMax * 1))]}
                        >
                            {!hide_y_axis_label &&
                                <Label
                                    angle='-90'
                                    position='insideLeft'
                                    offset={yAxisLabelOffset}
                                    style={yAxisTitleStyle}>

                                    {yDataValueFormatter(yDataKeys[0])}
                                </Label>
                            }
                        </YAxis>

                        {showRightYAxis && (
                            <YAxis
                                yAxisId="right"
                                orientation="right"
                                // stroke={chartStyle.color.yAxisStock}
                                interval={0}
                                style={yAxisTickStyle}
                                tickFormatter={DataFormaterFixed}
                                tick={!hide_y_axis && !isDimension ? true : false}
                                width={widthOfYAxis}
                                axisLine={!hide_y_axis_border ? true : false}
                                allowDecimals={false}
                                domain={[dataMin => (Math.round(dataMin * 80 / 100)), dataMax => Math.round((dataMax * 1))]}
                            >
                            </YAxis>
                        )}


                        {!hide_tooltip && (
                            <Tooltip
                                content={function (active) {
                                    {
                                        let _data = {};
                                        const _payload = active && active.payload;
                                        const _inner_payload = _payload && _payload[0] && _payload[0].payload;
                                        const payload_data = _inner_payload && _inner_payload;
                                        let array_of_keys = [...xDataKeys, ...yDataKeys];
                                        array_of_keys && array_of_keys.length > 0 && array_of_keys.map((key) => {
                                            if (payload_data && Object.keys(payload_data).length > 0 && Object.keys(payload_data).indexOf(key) > -1) {
                                                let _alignment_type = Alignments && Alignments[key] && Alignments[key].type;
                                                _data[key] = formatValueByDataType(payload_data[key], _alignment_type)
                                            }
                                        })
                                        return <CustomToolTip data={_data} theme={tooltipTheme} />
                                    }
                                }}
                            />
                        )}



                        {
                            colorForYData && yDataKeys && yDataKeys.length > 0 && yDataKeys.map((data, index) => {

                                // const all_keys_use_for_cell = [...xDataKeys];
                                // all_keys_use_for_cell.push(data)
                                // const conditional_data = find_cell_format(this.props.tbl_formatter, all_keys_use_for_cell, entry);
                                // const cell_color = conditional_data?.cell_color;


                                return <Bar
                                    dataKey={showRightYAxis ? index === 0 ? data : undefined : data}
                                    yAxisId={showRightYAxis ? index === 0 ? "left" : "right" : undefined}
                                    barSize={showRightYAxis ? index === 0 ? 20 : 0 : 20}
                                    fill={colorForYData[index]}
                                    key={data}
                                    label={show_data_label}
                                    legendType={showRightYAxis ? index === 0 ? '' : 'none' : undefined}
                                    onClick={(props) => {

                                        const key = xDataKeys[xDataKeys.length - 1];
                                        const tempFilters = props.filters ? Object.assign({}, props.filters) : {};

                                        if (typeof tempFilters[key.toLowerCase()] === 'undefined') {
                                            tempFilters[key.toLowerCase()] = {}
                                        }
                                        else {
                                            //pass
                                        }
                                    }}
                                    onMouseOver={() => {

                                    }}
                                />
                            })
                        }


                        {
                            colorForYData && yDataKeys && yDataKeys.length > 0 && yDataKeys.map((data, index) => {
                                return (
                                    <Line
                                        dataKey={showRightYAxis ? index === 1 ? data : undefined : data}
                                        key={index}
                                        type="monotone"
                                        legendType={showRightYAxis ? index === 1 ? 'line' : 'none' : "none"}
                                        stroke={colorForYData[index]}
                                        yAxisId={showRightYAxis ? index === 0 ? "left" : "right" : undefined}
                                    // onMouseEnter={() => {
                                    // }}
                                    />
                                )
                            })
                        }
                        {
                            aggregate_options && aggregate_options["is_min_checked"] && yDataKeys?.length > 0 &&
                            <ReferenceLine y={yac_totals?.[yDataKeys[0]]?.["agg_min"]} stroke="red" label="min" yAxisId="left" stockWidth="2" style={{ strokeDasharray: '4' }} />
                        }
                        {
                            aggregate_options && aggregate_options["is_max_checked"] && yDataKeys?.length > 0 &&
                            <ReferenceLine y={yac_totals?.[yDataKeys[0]]?.["agg_max"]} stroke="red" label="max" yAxisId="left" stockWidth="2" style={{ strokeDasharray: '4' }} />
                        }
                        {
                            aggregate_options && aggregate_options["is_avg_checked"] && yDataKeys?.length > 0 &&
                            <ReferenceLine y={yac_totals?.[yDataKeys[0]]?.["agg_avg"]} stroke="red" label="avg" yAxisId="left" stockWidth="2" style={{ strokeDasharray: '4' }} />
                        }
                        {
                            aggregate_options && aggregate_options["is_st_dev_checked"] && yDataKeys?.length > 0 &&
                            <ReferenceLine y={yac_totals?.[yDataKeys[0]]?.["agg_sd"]} stroke="red" label="stdiv" yAxisId="left" stockWidth="2" style={{ strokeDasharray: '4' }} />
                        }

                        {!hide_legend && <Legend content={<RenderLegend legendStyle={legendStyle} />} verticalAlign="top" height={30} />}

                    </ComposedChart>

                </ResponsiveContainer>
                {/* <PositionWrapper>
                        <Legend yDataKeys={yDataKeys} heightOfChart={heightOfChart} colors={colorForYData} changeColor={changeColorOfYData} />
                </PositionWrapper> */}
            </div>
        );
    }
}


const mapStateToProps = (state, props) => {

    const table_format_setting_cache = state.tableFormatReducer.table_format_setting_cache;
    const tbl_formatter = table_format_setting_cache && table_format_setting_cache[props.id];

    return { tbl_formatter }
}

export default withRouter(connect(mapStateToProps, {})(LineBarChart));
