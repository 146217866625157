import { formatValueByDataType } from "../../../utils";

export const DataFormater = (number) => {
    if (number > 1000000000) {
        return (number / 1000000000).toString() + 'B';
    }
    else if (number > 1000000) {
        return (number / 1000000).toString() + 'M';
    }
    else if (number > 1000) {
        return (number / 1000).toString() + 'K';
    }
    else {
        return number?.toString();
    }
};



export const DataFormaterFixed = (number, key, column_meta) => {

    const d_type = column_meta?.['type'];
    const currency_type = column_meta?.['currency_type'];
    const num_format_type = 'million' //column_meta?.['num_format_type'];
    const use_decimal =  0;
    const use_percent = column_meta?.['use_percent'];
    var is_negative = false;
    var number_to_use = number && Math.abs(number);

    if (number < 0) is_negative = true;

    // var prefix = is_negative ? '-' : '';

    const __data = formatValueByDataType(number, d_type, undefined, undefined, key, undefined, currency_type, num_format_type, use_decimal, use_percent);
    return (''+ __data)?.toString();

    // }
    // else if (number_to_use > 1000000000) {
    //     return prefix + (number_to_use / 1000000000).toFixed(2).toString() + 'B';
    // }
    // else if (number_to_use > 1000000) {
    //     return prefix + (number_to_use / 1000000).toFixed(2).toString() + 'M';
    // }
    // else if (number_to_use > 1000) {
    //     return prefix + (number_to_use / 1000).toFixed(2).toString() + 'K';
    // }else if(number_to_use > 0){
    //     return prefix + number_to_use.toFixed(2);
    // }
    // else {
    //     return prefix + number_to_use.toString();
    // }
};
