import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TextBox from '../hoc/textBox';
import { Button } from '../hoc/button';


const Wrapper = styled.div`
    width: 30rem;
    border-radius: 6px;
    border: 2px solid #ccc;
    background-color: #fff;
    padding: 1rem;

    h1{
        font-size: 1.2rem;
        font-weight: 400;
        margin-top: 0px;
        margin-bottom: 1rem;
        text-align: center;
    }

`;


function AddEditPopup(props) {

    const { close, popupKey, option, data } = props;
    const { keys, info, save__func, tableName } = data;
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState(undefined)


    useEffect(() => {
        if (info) {
            setFormData(info)
        }
    }, [info])



    const on_Save_function = () => {

        let temp_data = JSON.parse(JSON.stringify(formData));
        let _process = keys.every(key => {
            return temp_data[key] && temp_data[key].length > 1
        })

        if (_process) {
            if (info) {
                save__func(temp_data)
                close(option, popupKey)
            }
            else {
                temp_data.id = undefined;
                save__func(temp_data)
                close(option, popupKey)
            }
        }
        else{
            alert("please Enter All Fields")
        }
    }


    const onChange = (value, key) => {
        let cloneFormData = JSON.parse(JSON.stringify(formData));
        cloneFormData[key] = value;
        setFormData(cloneFormData)
    }


    return (
        <Wrapper>
            <h1>
                {info ? `Edit` : "Add New"}
            </h1>

            {keys && keys.length > 0 && keys.map((key, i) => {
                return (
                    <div style={{ width: '100%', marginBottom: '10px' }}>
                        <TextBox
                            label={key}
                            labelStyle={{
                                textTransform: 'capitalize'
                            }}
                            onChange={(e) => {
                                onChange(e.target.value, key)
                            }}
                            value={(formData && formData[key] !== undefined) ? formData[key] : undefined}
                        />
                    </div>
                )
            })}




            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '2rem' }}>
                <Button
                    width="6rem"
                    mR="1rem"
                    primary={true}
                    onClick={() => {
                        on_Save_function()

                    }}>Save</Button>
                <Button width="6rem" bgColor={'#e6e6e6'} onClick={() =>
                    close(option, popupKey)
                }>Cancel</Button>
            </div>

        </Wrapper>
    )
}

export default AddEditPopup
