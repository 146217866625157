import styled from 'styled-components';
import {configs} from '../../theme/configs';
const {report}  = configs

export const ScrollWrapper = styled.div`
    overflow: hidden;
    max-width: ${props => props.maxWidth ? `${props.maxWidth}px` : ''};
    width:100%;
    padding-top: ${props => props.insights ? undefined : '20px'};
    background: #fff;
   
    &::-webkit-scrollbar {
        background-color:#fff;
        width:16px
    }
        &::-webkit-scrollbar-track {
        background-color:#fff
    }
    &::-webkit-scrollbar-track:hover {
        background-color:#f4f4f4
    }
    
       &::-webkit-scrollbar-thumb {
        background-color:#babac0;
        border-radius:16px;
        border:5px solid #fff
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color:#a0a0a5;
        border:4px solid #f4f4f4
    }
    
    &::-webkit-scrollbar-button {display:none}

`;


export const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
`;

export const ChartSideWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    .apply_btn{
            border: 1px solid #9c8d8d;
            padding: 0 10px;
            margin-bottom: 6px;
            background: #d0d8e0;
            font-size: 0.8rem;
            color: #222;
            cursor: pointer;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover{
                opacity: 0.8;
                color: green;
            }
       }

       .i_btn{
          cursor: pointer;
            color: rgba(64, 62, 62, 0.79);
            font-size: 11px;
            background: #f1f3f5;
            width: 1rem;
            height: 1rem;
            text-align: center;
            line-height: 1rem;
            border-radius: 50%;
            border: 1px solid #ccc;

            &:hover{
                opacity: 0.8;
            }
       }

       .filter_btn{
           position: absolute;
           right: 15px;
       }


       
`;


export const RportHeaderWrapper = styled.div`
       width: 100%;
       display: flex;
       align-items: center;
       justify-content: space-between;
       position: relative;
       box-sizing: border-box;
`;



export const DownloadBtn = styled.button`
        border: 1px solid #d9dcde;
        padding: 0px 10px;
        height: 40px;
        width: 55px;
        text-align: center;
        font-size: 1rem;
        color: rgb(131,150,175);
        background-color: transparent;
        cursor: pointer;
        border-right: 0px;
        position: relative;
        border-right: 1px solid #d9dcde;
        
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        margin-right: 100px;
        
        &:hover{
            background-color: #efefef;
        }

        .download_dropdown_menu{
            width: 52px;
            height: auto;
            top: 100%;
            margin: auto;
            background: #fff;
            border: 1px solid #d9dcde;
            position: absolute;
            left: 0px;
            right: 0px;
            z-index: 1;
            overflow: hidden;
            font-family: 'Titillium Web', sans-serif;

            &:focus{
                outline-offset: 0;
                outline: 0;
            }

            div{
                width: 100%;
                padding: 8px;
                font-size: 17px;
                color: #222;
                box-sizing:border-box;
                border-bottom: 1px solid #d9dcde;
                a{
                    display: block;
                    color: #222;
                }
                &:last-child{
                    border-bottom: 0px;
                }
                &:hover{
                    background-color: #efefef;
                }
            }
        }
`;


export const SelectLable = styled.div`
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: #000;
    margin-bottom: 5px;
    padding-left: 2px;
`;



export const FilterBtn = styled.div`
    display: flex;
    align-items: center;
    padding: 4px 7px;
    border-radius: 6px;
    cursor: pointer;
    box-sizing: border-box;

    span{
        margin-left: 10px;
        font-size: 0.875rem;
        font-weight: bold;
        line-height: 1.29;
        letter-spacing: normal;
        text-align: left;
        color: rgb(0, 22, 31);
    }

    &:hover{
        
        svg{
            fill: rgb(0, 138, 199);
        }
        span{
            color: rgb(0, 138, 199);;
        }
    }

`;





export const NextPrevBtn = styled.div`
        width: 1.786rem;
        height: 1.786rem;
        color: ${props => props.disable ? "gray" : "#fff"};
        cursor: ${props => props.disable ? "not-allowed" : "pointer"};
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        border-radius: 4px;

        img{
            width: 1.786rem;
        }

        &:last-child{
            margin-left: 10px;
        }

        &:hover{
            opacity: 0.7;
        }
        
        i {
            color: ${props => props.disable ? "gray" : "initial"};
        }
`;










export const RightSideWrapper = styled.div`
    width: 50%;
    padding: 0px 20px;
    /* position: absolute; */
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: -7px;



    .filter_open{
        cursor: pointer;

        &:hover{
            svg{
                fill: rgb(0, 138, 199);
            }
        }
    }
`;

export const FilterdItemRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    padding: 5px 10px;
    overflow-y: hidden;
    box-sizing: border-box;
    max-width: calc(100% - 150px);

    &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }


`;


export const Filterd = styled.div`
      max-width: 7.429rem;
     height: 1.571rem;
     border: solid 1px rgb(156, 174, 181);
     background-color: rgb(255, 255, 255);
     display: flex;
     align-items: center;
     justify-content: flex-start;
     padding: 0px 7px;
     margin-right: 10px;
     box-sizing: border-box;


     .cross_mark{
        width: 20px;
        cursor: pointer;
        /* height: 20px; */
        text-align: center;

     }

     .filter_name{
        font-size: 0.714rem;
        font-weight: normal;
        letter-spacing: normal;
        text-align: center;
        color: rgb(0, 22, 31);
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0px 5px;
        text-transform: capitalize;
        white-space: nowrap;
     }

`; 


export const LeftSection = styled.div`

`;
export const RightSection = styled.div`
     display: flex;
     align-items: center;
     flex-direction: row;
`;