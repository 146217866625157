import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { change_data_mode } from '../../actions/report';
import NextSvg from '../svg/next'
import PrevSvg from '../svg/prev';

const Wrapper = styled.div`
    width: 100%;
    background-color: #f1f3f4a6;
    flex-direction: row;
    display: flex;
    overflow: hidden;

`;

const Tab = styled.div`
    height: 40px;
    padding: 0px 1rem;
    font-size: .9rem; 
    font-weight: 500;
    color: ${props => props.active ? "#005d86" : '#666'};
    background-color: ${props => props.active ? "#fff" : 'transparent'};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-right: 1px solid #e8eaed;
    box-shadow: 0 1px 3px 1px rgb(60 64 67 / 15%);
    &:hover{
        background-color: ${props => !props.active ? '#e8eaed' : undefined}
    }
`;



const BottomTab = (props) => {


    const {
        set_table_data_mode,
        tableDataMode,
        activeTableData,
        nextButtonEnable,
        prevButtonEnable,
        previousData,
        nextData,
        insights,
        restricted_view,
        id
    } = props;


    const data_mode_options = [
        {
            value: 'raw_data',
            name: 'Raw Data'
        },
        {
            value: 'pivot_data',
            name: 'Pivot Data'
        }
    ]



    return (
        <Wrapper>

            {!restricted_view && data_mode_options && data_mode_options.length > 0 && data_mode_options.map((d, index) => {
                return (
                    <Tab key={"tableDataMode" + index} onClick={() => set_table_data_mode(d.value, id)} active={tableDataMode && tableDataMode === d.value}>{d.name}</Tab>
                )
            })}

            {activeTableData && activeTableData.showPagination && (
                <div className="footer_btn_section" style={{ paddingLeft: insights ? '.9rem' : undefined }}>
                    <button type="button"
                        title="Prev"
                        className={!prevButtonEnable ? 'disable' : 'enable'}
                        onClick={() => {
                            if (prevButtonEnable) {
                                props.previousData(props.table_id_to_use)
                            }
                        }}
                        style={{ marginRight: '1rem' }}
                    >
                        <PrevSvg size={"1rem"} height="1rem" color={'#000'} />
                    </button>

                    <div className="number_of_records" style={{ marginRight: '1rem' }}>
                        {activeTableData.startDataIndex} -
                        {activeTableData.endDataIndex > activeTableData.totalData.length ? activeTableData.totalData.length : activeTableData.endDataIndex} /
                        {activeTableData.totalData && activeTableData.totalData.length}
                    </div>

                    <button
                        title={"Next"}
                        className={!nextButtonEnable ? 'disable' : 'enable'}
                        onClick={() => {
                            if (nextButtonEnable) {
                                props.nextData(props.table_id_to_use)
                            }
                        }}
                        type="button">
                        <NextSvg size={"1rem"} height="1rem" color={'#000'} />
                    </button>
                </div>
            )}
        </Wrapper>
    )
}




const mapStateToProps = (state, props) => {
    const table_d_mode = state.helperReducer.tableDataMode;
    const tableDataMode = table_d_mode && table_d_mode[props.id];
    return { tableDataMode }
}

export default connect(mapStateToProps, { change_data_mode })(BottomTab);
