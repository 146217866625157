import * as actions from '../action-types/markup';

const initialState = {
    type: actions.MARKUP_INIT,
    platformAndCampaigns: undefined,
   
};


const markupReducer = (state, action) => {

	if (!state || typeof action.type === 'undefined') {
		return initialState;
    }

    switch (action.type) {
        
        case actions.GET_PLATFORMS_AND_CAMPAIGNS:
            return {
                ...state,
                type: action.type,
                platformAndCampaigns: action.data
            }
        default:
			return{
                ...state,
            }
        }
};

export default markupReducer;