import React, { useEffect, useState } from 'react';
import TitleHeader from '../title-header';
import { EtlWrapper } from './etl.styled';
import { connect } from 'react-redux';
import { saveEtlConfig, getConfigReports, deleteConfig, pushRowDataToStore, saveMultipalConfig, updateJob, clearJobInformation } from '../../actions/etl';
import { withRouter } from 'react-router';
import SharedTable from '../shared-table'
import { PageInnerWrapper } from '../hoc/styled.hoc';
import { Button } from '../hoc/button';
import TextBox from '../hoc/textBox';
import RippleEffect from '../hoc/ripple-effect';
import PlaySvg from '../svg/play';
import PauseSvg from '../svg/pause';
import CalanderSvg from '../svg/calander';
import { getParamByName, showPopup } from '../../utils';
import * as enums from '../../utils/enums';
import SchedulePopup from './schedule.popup';
import ConfirmBox from '../confirm-box'
import ChooseSourceTypePopup from './chooseSourceType';
import { dispatchHeaderInformation } from '../../actions/common'
import { get_data_wrangler_jobs, run_job_forcfully, delete_job, update_job_status } from '../../actions/data_wrangler';
import JobOccurencesTable from './job_occurences';
import JobOccurancePopup from './job.occurance.popup';

const Index = (props) => {

    const { getConfigReports, wrangler_jobs, parent_jobs, deleteConfig, pushRowDataToStore, currentEditedJob, updateJob } = props;


    const location = props?.history?.location?.search;


    const [localJobs, setLocalJobs] = useState(undefined);
    const [selected, setSelected] = useState(undefined);
    const [sortOrder, setSortOrder] = useState(undefined);
    const [sourceType, setSourceType] = useState(undefined);

    const [jobType, setJobType] = useState(undefined)

    const clone_wrangler_jobs = wrangler_jobs ? [...wrangler_jobs] : [];
    clone_wrangler_jobs.map((job) => {
        job.frequency_time = job.frequency_value + "-" + job.frequency_type
    })

    // is_new
    // nf_l7uc6hyz

    const [active_job_id, set_active_job_id] = useState();
    const [active_expanded_index, set_active_expanded_index] = useState(-1)

    useEffect(() => {
        setLocalJobs(clone_wrangler_jobs)
    }, [wrangler_jobs])



    useEffect(() => {

        const job_type = getParamByName(location, 'job_type');
        const title = job_type === "system_job" ? 'SYSTEM JOBS' : job_type === "google_drive_upload" ? "FILE JOBS" : 'DATA WRANGLING JOBS'
        const type = job_type === 'system_job' ? 'script' : job_type === "google_drive_upload" ? "google_drive_upload" : 'etl';

        let page_info = {
            title: title || 'DATA WRANGLING JOBS',
            extraComponent:
                <Button onClick={() => {
                    // openChooseSourceTypePopup()
                    props.clearJobInformation()
                    const path = '/etl_editor' + (type ? ('?job_type=' + type) : '');
                    props.history.push(path);

                }}>Create Job</Button>
        };

        props.dispatchHeaderInformation(page_info)
        props.get_data_wrangler_jobs(type)
        setJobType(type)


    }, [location])



    const editJobClicked = (job) => {
        let { id } = job;
        let _path = `etl_editor?config_id=${id}`;
        props.history.push(_path)
    }




    const searchJobFun = (value) => {

        let search_value = value && value.toLowerCase();
        let clone_jobs = JSON.parse(JSON.stringify(clone_wrangler_jobs));
        let after_filterd = clone_jobs.filter((job) => {
            if (value === '') return true;
            else if (
                (job.name && job.name.toLowerCase().indexOf(search_value) > -1) ||
                (job.target_table_name && job.target_table_name.toLowerCase().indexOf(search_value) > -1) ||
                (job.status && job.status.toLowerCase().indexOf(search_value) > -1) ||
                (job.user_name && job.user_name.toLowerCase().indexOf(search_value) > -1)
            ) return true
            else return false;
        });
        setLocalJobs(after_filterd)
    }




    const sortJobs = (key, _order) => {

        let order = _order ? _order : (sortOrder && sortOrder[key]) ? ((sortOrder[key] == 'az') ? 'za' : 'az') : 'az';
        const sorted_job = localJobs && localJobs.length > 0 && localJobs.sort((left, right) => {
            if (key === 'Name') {
                if (order == 'az') {
                    let leftValue = left.name ? left.name.trim().toLowerCase() : '';
                    let rightValue = right.name ? right.name.trim().toLowerCase() : '';
                    return leftValue.localeCompare(rightValue);
                }
                if (order == 'za') {
                    let leftValue = left.name ? left.name.trim().toLowerCase() : '';
                    let rightValue = right.name ? right.name.trim().toLowerCase() : '';

                    return rightValue.localeCompare(leftValue);
                }
            }


            if (key === 'Status') {
                if (order == 'az') {
                    let leftValue = left.status ? left.status.trim().toLowerCase() : '';
                    let rightValue = right.status ? right.status.trim().toLowerCase() : '';
                    return leftValue.localeCompare(rightValue);
                }
                if (order == 'za') {
                    let leftValue = left.status ? left.status.trim().toLowerCase() : '';
                    let rightValue = right.status ? right.status.trim().toLowerCase() : '';

                    return rightValue.localeCompare(leftValue);
                }
            }


            if (key === 'Created By') {
                if (order == 'az') {
                    let leftValue = left.user_name ? left.status.trim().toLowerCase() : '';
                    let rightValue = right.user_name ? right.status.trim().toLowerCase() : '';
                    return leftValue.localeCompare(rightValue);
                }
                if (order == 'za') {
                    let leftValue = left.user_name ? left.user_name.trim().toLowerCase() : '';
                    let rightValue = right.user_name ? right.user_name.trim().toLowerCase() : '';

                    return rightValue.localeCompare(leftValue);
                }
            }
        })

        setLocalJobs(sorted_job)
        setSortOrder({ [key]: order })
    }






    const localSaveAction = (data, field, value, j_type, message) => {
        updateJob(data, field, value, j_type, message)
        setSelected(undefined)
        setTimeout(() => {
            // props.get_data_wrangler_jobs(jobType)
            // getConfigReports()
        }, 500)
    }


    const updateTableRow = (data) => {
        pushRowDataToStore(data)
    }



    const saveButtonClicked = () => {
        let keys = currentEditedJob ? Object.keys(currentEditedJob) : [];
        let final_data = [];
        keys && keys.forEach(key => {
            final_data.push(currentEditedJob[key])
        })
        props.saveMultipalConfig(final_data)
        setTimeout(() => {
            getConfigReports()
        }, 1000)
    }




    const scheduleButtonClicked = () => {
        showPopup(undefined, undefined, enums.default.popupType.element, SchedulePopup, {
            selected: selected,
            _save: (value) => localSaveAction(selected, 'next_run_at', value, 'e')
        })
    }


    const stopJobClicked = () => {
        showPopup(undefined, 'Are you sure you want to Stop this ? ', enums.default.popupType.element, ConfirmBox, { func: { setYes: () => localSaveAction(selected, 'status', '0', 'e', 'Jobs Stopped') } })

    }

    const startJobClicked = () => {
        showPopup(undefined, 'Are you sure you want to Start this ? ', enums.default.popupType.element, ConfirmBox, { func: { setYes: () => localSaveAction(selected, 'status', '1', 'e', 'Jobs Started') } })

    }


    const viewOccurance = (job) => {
        console.log("job_id", job)
        showPopup(undefined, 'Are you sure you want to Start this ? ', enums.default.popupType.element, JobOccurancePopup, {
            job_data: job
        })
    }

    const addNewJob = (_path) => {
        props.clearJobInformation()
        props.history.push(_path);
    }


    const openChooseSourceTypePopup = () => {
        showPopup(undefined, undefined, enums.default.popupType.element_with_header, ChooseSourceTypePopup, {
            addNewJob: addNewJob
        },
            undefined,
            undefined,
            undefined,
            {
                width: '25rem'
            },
            undefined,
            true
        )
    }



    useEffect(() => {
        if (location) {
            const should_we_open = getParamByName(location, 'add_new');
            if (should_we_open) {
                openChooseSourceTypePopup()
            }
        }
    }, [location])



    const toggle_job_occurances = (item, row_index) => {

        const job_id = item.id;

        if (active_job_id !== job_id) set_active_job_id(job_id)
        else set_active_job_id(undefined)
    }


    const update_job_status = (job_id, status) => {
        const st = (status == "ready" || status == "active") ? "paused" : "ready";
        props.update_job_status([job_id], st, jobType)
    }




    return (
        <EtlWrapper style={{ marginTop: '1rem' }}>
            <PageInnerWrapper height={"calc(100vh - 110px)"} padding="0px 0px 0px 10px" overflow={"inheret"}>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        {selected && selected.length > 0 && (
                            <React.Fragment>
                                <div style={{ marginRight: '30px' }}>
                                    <RippleEffect
                                        position="top"
                                        Click={startJobClicked}
                                        icon={<PlaySvg size="1rem" height="1rem" color="#757575" />}
                                        tooltip="Start"
                                    />
                                </div>
                                <div style={{ marginRight: '30px' }}>
                                    <RippleEffect
                                        position="top"
                                        Click={stopJobClicked}
                                        icon={<PauseSvg size="1rem" height="1rem" color="#757575" />}
                                        tooltip="Stop"
                                    />
                                </div>
                                <div style={{ marginRight: '30px' }}>
                                    <RippleEffect
                                        position="top"
                                        Click={scheduleButtonClicked}
                                        icon={<CalanderSvg size="1rem" height="1rem" color="#757575" />}
                                        tooltip="Schedule change "
                                    />
                                </div>


                            </React.Fragment>
                        )}


                        {currentEditedJob && (
                            <div style={{ marginRight: '30px' }}>
                                <Button onClick={saveButtonClicked} >Save</Button>

                            </div>
                        )}

                    </div>


                    {/* <div style={{ margin: '1rem 0px', width: '30%' }}>
                        <TextBox
                            label="Search Jobs"
                            type="text"
                            placeholder="Search Job"
                            onChange={(e) => {
                                searchJobFun(e.target.value)
                            }}
                        />
                    </div> */}
                </div>


                {/* run_job_forcfully */}
                {localJobs && localJobs.length > 0 ? (

                    <SharedTable
                        action={true}
                        none_even_odd_color={true}
                        editAction={(item) => editJobClicked(item)}
                        deleteAction={(item) => {
                            props.delete_job(item, jobType)
                        }}
                        processAction={(item) => {
                            props.run_job_forcfully(item.id, jobType);
                        }}
                        selected={selected}
                        sortOrder={sortOrder}

                        tableData={(localJobs && localJobs.length > 0) ? localJobs : undefined}
                        tableHead={[
                            'Name',
                            'Last Ran Time',
                            'Frequency',
                            'Status',
                            "Last Running Stauts",
                            'created by',
                            'Actions'
                        ]}
                        columnsToShow={[
                            'name', 
                            'last_ran_at', 
                            'frequency_time', 
                            'job_status', 
                            "last_running_status", 
                            'created_by'
                        ]}
                        DateColumns={["next_run_at", 'last_ran_at']}
                        fullTime={true}
                        customActionIconAndStyle={
                            {
                                "processAction": {
                                    icon: <PlaySvg size=".8rem" height=".8rem" color="green" />
                                }
                            }
                        }
                        update_job_status={update_job_status}
                        job_status={""}
                        actionsTitles={{
                            "processAction": {
                                title: "Run",
                                message: "Are you sure you want to Run The Job?",
                            },

                        }}
                        column_header_styles={{
                            // backgroundColor: '#f4f4f4',
                            textTransform: 'uppercase',
                            fontSize: '.8rem'

                        }}
                        column_body_styles={{
                            "name": {
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                // color: '#222',

                            },
                            "frequency_time": {
                                fontSize: '.8rem',
                                textTransform: 'capitalize',
                                // color: '#353232',

                            },
                            "job_status": {
                                fontSize: '.8rem',
                                textTransform: 'capitalize',
                                // color: '#353232',
                            },
                            "created_by": {
                                // color: '#353232',
                            },

                        }}
                        sortColumns={{
                            "Name": {
                                type: "string",
                                dataKey: 'name'
                            },
                            // "Created By": {
                            //     type: "string",
                            //     dataKey: 'user_name'
                            // },
                            "Created At": {
                                type: "date",
                                dataKey: 'created_at'
                            },
                            'Status': {
                                type: 'string',
                                dataKey: 'status'
                            }
                        }}
                        pointerRow={true}
                        maximumWidthOfColumn={{
                            "question": '20rem'
                        }}
                        columnAction={{
                            "last_ran_at": viewOccurance
                        }}
                        is_expandable={true}
                        active_expanded_value={active_job_id}
                        active_expanded_value_key={"id"}
                    // Expanded_row_data_component={<JobOccurencesTable job_id={active_job_id} />}
                    />

                ) : <p style={{ textAlign: "center" }}>No Job Found...</p>}

            </PageInnerWrapper>
        </EtlWrapper>
    )
}


const mapStateToProps = (state) => ({
    config: state.EtlReducer.config,
    parent_jobs: state.EtlReducer.parent_jobs,
    currentEditedJob: state.EtlReducer.currentEditedJob,
    wrangler_jobs: state.dataWrangler.wrangler_jobs
})



export default withRouter(connect(mapStateToProps, {

    run_job_forcfully,
    delete_job,
    update_job_status,
    get_data_wrangler_jobs,
    dispatchHeaderInformation,
    saveEtlConfig,
    getConfigReports,
    deleteConfig,
    pushRowDataToStore,
    saveMultipalConfig,
    updateJob,
    clearJobInformation

})(Index))
