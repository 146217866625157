import React, { useState, useEffect } from 'react'
import AutoSearch from '../../auto.search';
import TablePopup from '../table.popup'
import ViewSvg from '../../svg/view';
import * as enums from '../../../utils/enums';
import { showPopup } from '../../../utils';
import ExclamationSvg from '../../svg/exclamation';
import TextBox from '../../hoc/textBox';
import { Button } from '../../hoc/button';
import SelectOption from '../../select-option';
import RippleEffect from '../../hoc/ripple-effect'
import CorrectSvg from '../../svg/correct';
import AutoSearchNew from '../../auto.search/auto.search.new'
import { getTargetColumnNames, pushSourceMappingTableRowToStore, pushSourceMappingDataToStore } from '../../../actions/etl';
import { connect } from 'react-redux';
import TextBoxV1 from '../../hoc/text.box.v1';
import SelectOptionV1 from '../../select-option/select.option.v1.1'
import ReportBuilder from '../../editor.shared/ediotr.v1.1';
import { constants } from '../../../utils/constants';
import { post } from '../../../data.service';
import FreeSelectOptionV1 from '../../select-option/free.select.box.v1.1';


const MappingTableRow = (props) => {

    const {

        tableHead,
        rowData,
        row_index,
        targetColumnNames,
        targetTableNames,
        sourceColumns,
        errorPopupValue,
        ignoreError,
        target_schema,
        showTablePopupClicked,
        pushSourceMappingTableRowToStore,
        mappingTableData,
        is_edit_mode,
        suggest_table,
        on_change_v1,
        target_db_id,
        schema_table_info

    } = props;

    const [localData, setLocalData] = useState(undefined);

    useEffect(() => {
        setLocalData(rowData)
    }, [rowData])

    const get_plain_row_object = (__table__head__) => {
        const table_head = __table__head__ ? __table__head__ : tableHead;

        const object_to_return = {
            id: undefined,
            source_column: undefined,
            target_column: undefined,
            target_table: undefined,
            direct_mapping: undefined,
            criteria: undefined,
        };

        const existing_keys = Object.keys(object_to_return);

        table_head && table_head.forEach(head => {
            if (existing_keys.indexOf(head) === -1) {
                object_to_return[head] = [];
            }
        });

        return object_to_return;
    };

    const autoSearchOnChange = (key, value, type) => {

        let clone_table_data = mappingTableData ? JSON.parse(JSON.stringify(mappingTableData)) : []
        let temp_local_data = localData ? JSON.parse(JSON.stringify(localData)) : {};
        temp_local_data[key] = value;

        if (key === 'target_table' && type == 'clicked') {
            let post_data_for_target_column = {
                database_id: target_schema,
                table_name: value
            }
            props.getTargetColumnNames(post_data_for_target_column)
        }

        if (key === 'target_table' && type === 'typing') {
            temp_local_data.target_column = ' ';


        }

        if (type === 'typing') {
            if (key === 'criteria') {
                temp_local_data[key] = value;
            } else {
                temp_local_data[key] = {
                    is_new: true,
                    value: value
                };
            }
        }


        if (type === 'clicked') {
            temp_local_data[key] = value;

        }

        if (row_index === (clone_table_data.length - 1)) {
            clone_table_data.push(get_plain_row_object())
            clone_table_data[row_index] = temp_local_data
            props.pushSourceMappingDataToStore(clone_table_data)
        };
        setLocalData(temp_local_data)
    }




    const DATA_TYPES = [
        {
            name: 'String',
            value: 'string'
        },
        {
            name: 'Int',
            value: 'int'
        },
        {
            name: 'date',
            value: 'date'
        },
        {
            name: 'Currency',
            value: 'currency'
        },
        {
            name: 'Epoch',
            value: 'epoch'
        },
        {
            name: 'YYYY-MM-DD',
            value: 'yyyymmdd'
        },
        {
            name: 'Seconds',
            value: 'seconds'
        },


    ]



    const save_report = async (report, row_index) => {

        const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.SAVE_REPORT_V1;

        const response = await post(url, { report: report, save_type: 'ETL', get_sql_query: true });

        if (response && response.code === 200 && response.data.report_id) {
            const report_id = response.data.report_id;
            const sql_query = response.data.sql;
            // console.log("we got the response", response.data)
            on_change_v1('report_id', report_id, row_index)
            on_change_v1('criteria', sql_query, row_index)

        }
    }


    const open_report_builder = (report_id, row_index) => {
        // console.log("edit_report_id", report_id)
        showPopup("Your Business logic", undefined, enums.default.popupType.element_with_header, ReportBuilder, {
            db_info_id: props?.target_db_id, //'demo_med_db_info_id',
            render_mode: "table",
            edit_report_id: report_id,
            save_function: (report) => {
                save_report(report, row_index)
                // on_change_v1('local_report', report, row_index)
            },
            report_type: "etl_config_query_builder",
            report_field_title: 'Report Display Fields',
            hide_header: true,
            hide_connection_selector: true,
            hide_user_data_source: false,
            hide_compiler_hints: true,
            hide_report_inputs: true,
            hide_filter_fields: true,
            hide_others_btn: true,
            is_etl: true
        }
            ,
            undefined,
            undefined,
            undefined, {
            width: '95vw',
            height: '85vh'
        },
            true
        )

    }

    // console.log("schema_table_info", props, props.schema_table_info)



    return (
        <React.Fragment>
            {localData && (
                <tr key={"tbl" + row_index}>

                    {tableHead && tableHead.length > 0 && tableHead.map((cell, j) => {
                        const active_value = (typeof localData[cell] === 'object') ? (localData[cell] && localData[cell].value) : localData[cell];
                        let selectionsOfTargetColumnNames = schema_table_info?.[target_db_id] && schema_table_info?.[target_db_id]?.[localData['target_table']] && Object.keys(schema_table_info?.[target_db_id]?.[localData['target_table']]);

                        let selection = (cell == 'target_table') ?
                            suggest_table : (cell == 'target_column') ?
                                selectionsOfTargetColumnNames : (cell == 'source_column') ?
                                    sourceColumns ?
                                        sourceColumns : undefined : undefined;

                        let is_new_value = undefined;
                        const which_element_should_render = cell === "criteria" ? "btn" : (cell === "target_table" || cell === "target_column") ? 'auto_search_option' : cell == 'is_direct_mapping' ? 'selectOption' : 'input'
                        let color_of_exc_btn = (errorPopupValue && Object.keys(errorPopupValue).length > 0 && Object.keys(errorPopupValue).indexOf(row_index.toString()) > -1) ? 'green' : (ignoreError && ignoreError.indexOf(row_index) > -1) ? '#f1db42' : "#E2574C"
                        if (!selection && active_value && typeof active_value === 'string' && active_value.trim().length > 0) is_new_value = true;
                        if (selection && active_value && typeof active_value === 'string' && selection.indexOf(active_value.trim()) === -1 && active_value && active_value.trim().length > 0) is_new_value = true;

                        console.log("selection", j, selection, cell)

                        return (
                            <td
                                onBlur={() => {
                                    // pushSourceMappingTableRowToStore(row_index, localData)

                                }}

                                key={"cell" + j} style={{
                                    position: 'relative',
                                    maxWidth: cell === "criteria" ? '10rem' : undefined,
                                    padding: cell === "criteria" ? '0px' : undefined,
                                }}>

                                {which_element_should_render === 'auto_search_option' &&
                                    <div style={{ boxShadow: 'rgb(204 204 204 / 82%) 1px 1px 4px 1px' }}>
                                        <FreeSelectOptionV1
                                            options={selection}
                                            none_value={false}
                                            on_change={(val) => {
                                                on_change_v1(cell, val, row_index)
                                            }}
                                            // setActiveValue={(val) => {
                                            //     on_change_v1(cell, val, row_index)
                                            // }}
                                            value={active_value ? active_value : ' '}
                                            hide_border={true}
                                            disabled={cell === "source_column"}
                                            hide_add_btn={true}
                                            hide_margin={true}
                                        />
                                    </div>
                                }

                                {which_element_should_render === 'selectOption' &&
                                    <div style={{ boxShadow: 'rgb(204 204 204 / 82%) 1px 1px 4px 1px' }}>

                                        <SelectOptionV1
                                            display_key="name"
                                            value_key="value"
                                            hide_margin={true}
                                            hide_case={true}
                                            none_value={false}
                                            options={cell === "target_data_type" ? DATA_TYPES :
                                                [
                                                    { name: "Yes", value: 'yes' },
                                                    { name: "No", value: 'no' }
                                                ]
                                            }
                                            hide_border={true}
                                            on_change={(value) => {
                                                on_change_v1(cell, value, row_index)
                                            }}
                                            value={active_value}


                                        />
                                    </div>

                                }

                                {which_element_should_render === 'input' &&
                                    <div style={{ boxShadow: 'rgb(204 204 204 / 82%) 1px 1px 4px 1px' }}>
                                        <TextBoxV1
                                            hide_border={true}
                                            hide_margin={true}
                                            onChange={(e) => {
                                                let _value = e.target.value
                                                on_change_v1(cell, _value, row_index)
                                            }}
                                            disabled={cell === "source_column"}
                                            value={(active_value && active_value) ? active_value : ''}
                                        />
                                    </div>

                                }

                                {which_element_should_render === "btn" && localData?.is_direct_mapping === "no" && (
                                    <div>
                                        {localData?.criteria &&
                                            <p style={{
                                                textOverflow: 'ellipsis',
                                                width: '100%',
                                                overflow: 'hidden',
                                                fontSize: '.8rem',
                                                color: '#222',
                                                maxHeight: '2rem',
                                                fontWeight: '600',


                                            }}
                                                title={localData?.criteria}
                                            >{localData?.criteria}</p>
                                        }
                                        <button className='add_edit' onClick={() => {
                                            open_report_builder(localData?.report_id, row_index)
                                        }}>{localData?.report_id ? 'Edit' : 'Add'}</button>
                                    </div>
                                )}

                                {(cell === "target_table" || cell === "target_column") && active_value?.length > 0 && (
                                    <div className={"new_value"} title={`New ${cell && cell.split('_').join(' ')}`}>{`New`}</div>
                                )}

                            </td>
                        )
                    })}
                </tr>
            )}
        </React.Fragment>
    )
}





const mapStateToProps = (state) => ({

    targetColumnNames: state.EtlReducer.targetColumnNames,
    targetTableNames: state.EtlReducer.targetTableNames,
    mappingTableData: state.EtlReducer.mappingTableData,
    schema_table_info: state.reportBuilder.schema_table_info,

});



export default connect(mapStateToProps, { getTargetColumnNames, pushSourceMappingTableRowToStore, pushSourceMappingDataToStore })(MappingTableRow)
