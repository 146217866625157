import * as actions from '../action-types/report';
import * as commonActions from '../action-types/common';
import { constants } from '../utils/constants';
import { data_set_processing, is_date, capitalizeFirstLetter, get_display_key_by_data_key, smallFirstLetter } from '../utils';
import { history } from '../components/history';
import { get_final_process_data, get_pivot_data, step_1_loop_through_rows_and_sort, return_formula_with_updated_data, apply_case_on_data, apply_filter_on_data, calculate_total_for_row, get_updated_yacs_total, apply_everything_on_pivot_data, apply_everything_on_raw_data, covert_normal_keys_to_display_values, s_p } from '../utils/v1.1/index';
import { store } from '../store';

const initialState = {
    type: actions.REPORT_INIT,
    recordsCount: 0,
    metaColumnsData: undefined,

    dataFromServer: {},
    pivotDataCache: {},

    drill_down_data: {},
    hints: [],
    distinct_data: {},
    report_status_cache: {},
    reports: undefined,
    reportFormulas: undefined,
    shareReportData: undefined,
    reportSavedData: undefined,
    tbl_formatter: undefined,
    tbl_formulas: undefined,
    tbl_formulas_form_editor: undefined,
    functions_config: undefined,
    testSqlResult: undefined,
    testSqlError: undefined,
    pivotData: undefined,
    dataMode: undefined,
    prev_columns_selected: undefined,

    sql_result_cache: undefined,
    sql_error_cache: undefined,
    sql_loader_cache: undefined,

    report_loader_cache: undefined,
    containerValues: undefined,
    week_config: undefined,
    polusAiChatErrors: {},
    gptDataStoryCache: {},

};


const assign_pivot_data_ids = (pivot_x, pivot_y, pivot_comparison, results) => {

    results && results.x && results.x.forEach((result, index) => {
        if (result) {
            pivot_x.forEach(x => {
                if (x.key === result.key) {
                    x.id = result.id;
                }
            });
        };
    });

    results && results.y && results.y.forEach((result, index) => {
        if (result) {
            pivot_y.forEach(y => {
                if (y.key === result.key) {
                    y.id = result.id;
                }
            });
        };
    });


    results && results.columns && results.columns.forEach((result, index) => {
        if (result) {
            pivot_comparison.forEach(column => {
                if (column.key === result.key) {
                    column.id = result.id;
                }
            });
        };
    });


    return {
        pivot_x,
        pivot_y,
        pivot_comparison,
    };

};


/**
 * 
 * @param {*} state 
 * @param {*} report_id 
 * @param {*} report_item_id 
 * @returns gets the report data object complete wrapper using id of report or report item, whichever the case may be
 */
const get_previous_object_from_state = (state, report_id, report_item_id) => {
    const prev_report_data = state.dataFromServer ? JSON.parse(JSON.stringify(state.dataFromServer)) : {};
    const id = (prev_report_data && prev_report_data[report_item_id]) ? report_item_id : report_id;

    if (prev_report_data && prev_report_data[id] && prev_report_data[id].data) {

        return prev_report_data[id].data;
    }

    return;
};


const post_pivot_saved_data = (state, type, report_item_id, results, report_id) => {
    const prev_report_data = get_previous_object_from_state(state, report_id, report_item_id);

    if (prev_report_data) {
        const pivot_x = (prev_report_data.pivot_data_axis && prev_report_data.pivot_data_axis.xac) || [];
        const pivot_y = (prev_report_data.pivot_data_axis && prev_report_data.pivot_data_axis.yac) || [];
        const pivot_comparison = (prev_report_data.pivot_data_axis && prev_report_data.pivot_data_axis.pivot_data_columns) || [];

        assign_pivot_data_ids(pivot_x || [], pivot_y || [], pivot_comparison || [], results);

        prev_report_data.pivot_data_axis = {
            xac: pivot_x,
            yac: pivot_y,
            pivot_data_columns: pivot_comparison,
        };
    }

    return {
        ...state,
        type,
        dataFromServer: update_report_data_in_cache(state, prev_report_data, report_item_id, report_id)
    };
};

/**
 * 
 * @param {*} state 
 * @param {*} report_data 
 * @param {*} report_item_id 
 * @param {*} report_id 
 * @returns 
 */
const update_report_data_in_cache = (state, report_data, report_item_id, report_id) => {
    const prev_reports_data = state.dataFromServer ? JSON.parse(JSON.stringify(state.dataFromServer)) : {};
    const id = (prev_reports_data && prev_reports_data[report_item_id]) ? report_item_id : report_id;

    if (id) {
        prev_reports_data[id].data = report_data;
        // console.log('pivot check #1: ', JSON.parse(JSON.stringify(report_data)));

    }

    // console.log('pivot check #2: ', JSON.parse(JSON.stringify(prev_reports_data[id])));

    return prev_reports_data;
};


const create_pivot_data = (state, type, report_item_id, pivot_x, pivot_y, pivot_comparison, results, column_infos, report_id, need_to_switch_row_column, report_user_column_preferance) => {

    const prev_report_data = get_previous_object_from_state(state, report_id, report_item_id);

    // pivot_y = (pivot_y || []).filter(y => !y.deleted);
    // pivot_x = (pivot_x || []).filter(x => !x.deleted);

    // column_infos

    if (prev_report_data) {

        const __data__ = prev_report_data;
        const raw_data = __data__.clone_process_data || __data__.process_data;
        const columnMeta = __data__.column_meta_data;

        const prev_xacs = (__data__ && __data__.pivot_data_axis && __data__.pivot_data_axis.xac) || [];
        const prev_yacs = (__data__ && __data__.pivot_data_axis && __data__.pivot_data_axis.yac) || [];

        if (__data__.config_meta.column_infos) {
            __data__.config_meta.column_infos = column_infos
        }

        const new_xacs = pivot_x.filter(x => {
            let found = false;

            prev_xacs.forEach(prev_xac => {
                if (prev_xac.pivot_field_column_name === x.pivot_field_column_name) {
                    found = true;
                }
            });

            return !found;
        });

        const new_yacs = pivot_y

        let is_old_sufficient = (new_xacs.length === 0) && (pivot_x.length === prev_xacs.length) && !need_to_switch_row_column

        // is_old_sufficient

        pivot_x && prev_xacs.forEach((x, index) => {
            is_old_sufficient = is_old_sufficient && x.pivot_field_column_name === pivot_x[index].pivot_field_column_name;
        });


        const yacs_to_use = is_old_sufficient ? new_yacs : pivot_y;
        const prev_xac_indexes = is_old_sufficient ? __data__.prev_xac_indexes : undefined;

        // console.log("is_old_sufficient", is_old_sufficient, JSON.parse(JSON.stringify(__data__)))
        // console.log("bhai state", prev_xac_indexes && Object.keys(prev_xac_indexes)[0])

        const report_formula = ((__data__?.config_meta?.column_infos || []).filter((f => f?.is_formula)) || []).sort((a, b) => a.order - b.order);

        const __pivot__data = get_pivot_data(raw_data, columnMeta, pivot_x, pivot_y, pivot_comparison, yacs_to_use, prev_xac_indexes, __data__.pivot_data, undefined, undefined, need_to_switch_row_column, report_user_column_preferance, report_formula,);


        __data__.pivot_data = __pivot__data?.pivot_data || undefined;
        __data__.clone_pivot_data = __pivot__data?.pivot_data || undefined;
        __data__.pivot_data_axis = {
            xac: __pivot__data?.pivot_x_axis || pivot_x,
            yac: __pivot__data?.pivot_yac || pivot_y,
            pivot_data_columns: pivot_comparison,
        };

        __data__.need_to_switch_row_column = need_to_switch_row_column
        __data__.pivot_data_ultimate_yacs = __pivot__data?.pivot_data_ultimate_yacs || undefined;
        __data__.prev_xac_indexes = __pivot__data?.xac_indexes;
        __data__.pivot_columns_state = __pivot__data?.pivot_columns_state;
        __data__.pivot_xac_indexes_count = __pivot__data?.xac_indexes ? Object.keys(__pivot__data?.xac_indexes).length : 0;
        __data__.pivot_data_grand_sum_total = __pivot__data?.pivot_data_grand_sum_total;
        __data__.column_row_wise_data_types = __pivot__data?.column_row_wise_data_types

        console.log("__pivot__data1.1", __data__)

        return {
            ...state,
            type,
            dataFromServer: update_report_data_in_cache(state, __data__, report_item_id, report_id)
        }
    }
    else {
        // console.log(',,,, => ', prev_report_data,);
    }

    return {
        ...state,
        type,
        dataFromServer: update_report_data_in_cache(state, {}, report_item_id, report_id)
    }
}


const reportReducer = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    // if (action && action.data && typeof action.data === 'object') {
    //     action.data.key = new Date();
    // }


    let { report_item_id, pivot_x, pivot_y, results, report_id, pivot_comparison, column_infos, need_to_switch_row_column } = (action && action.data) ? action.data : {};




    // console.log("ACTION", action?.type)
    switch (action.type) {
        case actions.META_COLUMN_DATA_SUCCESS:
            return {
                ...state,
                type: action.type,
                metaColumnsData: action.data,
            };


        case actions.RAISE_DASHBOARD_WIDGETS_LOADER:

            const clone_report_loader_cache = state.report_loader_cache ? JSON.parse(JSON.stringify(state.report_loader_cache)) : {};
            if (!clone_report_loader_cache[action.data.report_id]) clone_report_loader_cache[action.data.report_id] = true;

            return {
                ...state,
                type: action.type,
                report_loader_cache: clone_report_loader_cache
            }

        case actions.HIDE_DASHBOARD_WIDGETS_LOADER:

            const clone_report_loader_cache1 = state.report_loader_cache ? JSON.parse(JSON.stringify(state.report_loader_cache)) : {};
            if (clone_report_loader_cache1[action.data.report_id]) {
                delete clone_report_loader_cache1[action.data.report_id];
            }

            return {
                ...state,
                type: action.type,
                report_loader_cache: clone_report_loader_cache1
            }
        case actions.TEST_SQL_SUCCESS:

            const payload_state = action.data;
            const clone_sql_result_cache = state.sql_result_cache ? JSON.parse(JSON.stringify(state.sql_result_cache)) : {};
            const clone_sql_error_cache = state.sql_error_cache ? JSON.parse(JSON.stringify(state.sql_error_cache)) : {};

            if (!clone_sql_result_cache[payload_state.report_id]) clone_sql_result_cache[payload_state.report_id] = [];

            clone_sql_result_cache[payload_state.report_id] = payload_state.sqlResult;
            delete clone_sql_error_cache[payload_state.report_id]

            return {
                ...state,
                type: action.type,
                testSqlResult: action.data,
                testSqlError: undefined,
                sql_result_cache: clone_sql_result_cache,
                sql_error_cache: clone_sql_error_cache
            }


        case actions.CLEAR_TEST_QUERY_DATA:

            const payload_state3 = action.data;
            const rpt_id = payload_state3?.report_id;

            const clone_sql_result_cache3 = state.sql_result_cache ? JSON.parse(JSON.stringify(state.sql_result_cache)) : {};
            const clone_sql_error_cache4 = state.sql_error_cache ? JSON.parse(JSON.stringify(state.sql_error_cache)) : {};

            delete clone_sql_result_cache3[rpt_id]
            delete clone_sql_error_cache4[rpt_id]


            return {
                ...state,
                type: action.type,
                testSqlResult: undefined,
                testSqlError: action.data,
                sql_result_cache: clone_sql_result_cache3,
                sql_error_cache: clone_sql_error_cache4
            }



        case actions.RAISE_TEST_QUERY_ERROR:

            const payload_state1 = action.data;
            const clone_sql_result_cache1 = state.sql_result_cache ? JSON.parse(JSON.stringify(state.sql_result_cache)) : {};
            const clone_sql_error_cache1 = state.sql_error_cache ? JSON.parse(JSON.stringify(state.sql_error_cache)) : {};

            if (!clone_sql_error_cache1[payload_state1.report_id]) clone_sql_error_cache1[payload_state1.report_id] = [];
            clone_sql_error_cache1[payload_state1.report_id] = payload_state1.error;
            delete clone_sql_result_cache1[payload_state1.report_id];

            return {
                ...state,
                type: action.type,
                testSqlResult: undefined,
                testSqlError: action.data,
                sql_result_cache: clone_sql_result_cache1,
                sql_error_cache: clone_sql_error_cache1
            }

        case actions.CHANGE_DATA_MODE:
            return {
                ...state,
                type: action.type,
                dataMode: action.data
            }


        case actions.SAVE_PIVOT_DATA_SUCCESS:
            return post_pivot_saved_data(state, action.type, report_item_id, results, report_id);

        case actions.CLEAR_PIVOT_DATA_SUCCESS:

            const report_base_id = action.data && action.data.base_report_id;
            const __prev_report_data__ = state.dataFromServer ? JSON.parse(JSON.stringify(state.dataFromServer)) : {};
            if (__prev_report_data__ && __prev_report_data__[report_base_id]) {
                __prev_report_data__[report_base_id].pivot_data = undefined;
                __prev_report_data__[report_base_id].clone_pivot_data = undefined;
                __prev_report_data__[report_base_id].pivot_data_axis = undefined;
                __prev_report_data__[report_base_id].pivot_data_ultimate_yacs = undefined;
            }
            return {
                ...state,
                type: action.type,
                dataFromServer: __prev_report_data__
            }


        case actions.CLEAR_DATA_FROM_SERVER:

            const ds_clone = state?.dataFromServer ? { ...state?.dataFromServer } : {};
            delete ds_clone[action?.data?.id];

            return {
                ...state,
                type: action.type,
                dataFromServer: ds_clone
            }

        case actions.DISPATCH_PIVOT_DATA_SUCCESS:

            const __data____ = action.data;

            const __tempDataFromServerCache = state.dataFromServer ? JSON.parse(JSON.stringify(state.dataFromServer)) : {};
            const _id__ = __data____.reportId;

            if (__tempDataFromServerCache[_id__]) {
                __tempDataFromServerCache[_id__]["pivot_data"] = {
                    ...__data____["pivot_data"],

                }
                __tempDataFromServerCache[_id__]['config_meta'] = __data____?.config_meta;
                __tempDataFromServerCache[_id__]['columns_info'] = __data____?.columns_info;
                __tempDataFromServerCache[_id__]['response_session_id'] = __data____?.response_session_id;
            }


            return {
                ...state,
                type: action.type,
                dataFromServer: __tempDataFromServerCache,
            };

        case actions.DATA_FROM_RS_SUCCESS:

            const data = action.data;
            const tempDataFromServerCache = state.dataFromServer ? JSON.parse(JSON.stringify(state.dataFromServer)) : {};
            const id = data.reportId;

            var clone_server_data = data ? JSON.parse(JSON.stringify(data)) : {};

            if (clone_server_data?.raw_data?.data) {
                clone_server_data = apply_everything_on_raw_data(clone_server_data, clone_server_data?.raw_data?.pivot_columns_state)

            };

            if (clone_server_data?.pivot_data?.data) {
                clone_server_data = apply_everything_on_pivot_data(clone_server_data, clone_server_data?.pivot_data?.pivot_columns_state)
                // console.log("clone_server_data", clone_server_data)

            };

            if (!tempDataFromServerCache[id]) tempDataFromServerCache[id] = {};
            tempDataFromServerCache[id] = clone_server_data;

            return {
                ...state,
                type: action.type,
                dataFromServer: tempDataFromServerCache,
            };

        case actions.DISTINCT_DATA_FROM_RS_SUCCESS:
            const payload = action.data;
            const table_name = payload.table_name;
            const pivot_field_column_name = payload?.column_name;

            let distinct_values = payload.distinct_values;
            if (distinct_values && distinct_values.length > 1000) {
                distinct_values = distinct_values.slice(0, 100)
            }

            let _prev_stored_value = state.distinct_data ? state.distinct_data : {};
            let _str = table_name + '.' + pivot_field_column_name;
            _prev_stored_value[_str] = distinct_values;


            return {
                ...state,
                type: action.type,
                distinct_data: _prev_stored_value
            };

        case actions.CHANGE_ACTIVE_DATA_SUCCESS:
            return {
                ...state,
                type: action.type,
                dataFromServer: action.data
            };

        case actions.SHARE_REPORT:
            let temp_state = JSON.parse(JSON.stringify(state));

            temp_state = {
                ...temp_state,
                type: action.type,
                shareReportData: action.data
            }

            return temp_state;

        case actions.HINTS_FROM_RS_SUCCESS:

            const value_container = action?.data?.value_container || [];
            const entities = action?.data?.entities || []
            const mearged_array = [...value_container, ...entities];
            const uniqueHints = [...new Set(mearged_array)];



            return {
                ...state,
                type: action.type,
                containerValues: (value_container || []),
                hints: uniqueHints

            };

        case actions.CLEAR_HINTS_FROM_RS:
            return {
                ...state,
                type: action.type,
                hinsts: []
            }
        case actions.GET_ALL_REPORTS_SUCCESS:
            return {
                ...state,
                type: action.type,
                reports: action.data
            };

        case actions.SAVE_REPORT_SUCCESS:
            return {
                ...state,
                type: action.type,
                reportSavedData: action.data
            };

        case actions.RESET_DATA_FROM_SERVER:
            return {
                ...state,
                type: action.type,
                dataFromServer: {},
            }

        case commonActions.RESET_STORE_SUCCESS:
            return {
                ...state,
                type: actions.REPORT_INIT,
                dataFromServer: {},
                metaColumnsData: undefined,
                recordsCount: undefined,
                reports: undefined,
                hints: state.hints
            };

        case actions.GET_FORMILAS_BY_REPORT_ID:
            return {
                ...state,
                type: action.type,
                reportFormulas: action.data
            };

        case actions.CLEAR_FORMULAS:
            return {
                ...state,
                type: action.type,
                reportFormulas: undefined
            }
        case actions.ADD_TBL_FORMULAS:

            const return_updated_data = return_formula_with_updated_data(action.data, state.dataFromServer, state.tbl_formulas)

            return {
                ...state,
                type: action.type,
                dataFromServer: undefined
            }

        case actions.GET_TBL_FORMULAS:

            const formula_payload = action.data;
            const formula_report_id = formula_payload && formula_payload.report_id;

            const clone_prev_formula = state.tbl_formulas ? JSON.parse(JSON.stringify(state.tbl_formulas)) : {};
            if (!clone_prev_formula[formula_report_id]) clone_prev_formula[formula_report_id] = [];
            clone_prev_formula[formula_report_id] = (formula_payload && formula_payload.formulas);

            return {
                ...state,
                type: action.type,
                tbl_formulas: clone_prev_formula
            }


        // we will remove this code in future versions
        case actions.GET_TBL_FORMULAS_FOR_EDITOR:

            const __formula_payload__ = action.data;
            const __formula_report_id__ = __formula_payload__ && __formula_payload__.report_id;

            const __clone_prev_formula__ = state.tbl_formulas ? JSON.parse(JSON.stringify(state.tbl_formulas)) : {};
            if (!__clone_prev_formula__[__formula_report_id__]) __clone_prev_formula__[__formula_report_id__] = [];
            __clone_prev_formula__[__formula_report_id__] = (__formula_payload__ && __formula_payload__.formulas);

            return {
                ...state,
                type: action.type,
                tbl_formulas_form_editor: __clone_prev_formula__
            }

        case actions.GET_FUNCTIONS_CONFIG:
            return {
                ...state,
                type: action.type,
                functions_config: action.data
            }

        case actions.GET_WEEK_CONFIG:
            return {
                ...state,
                type: action.type,
                week_config: action.data
            }
        case actions.REPORT_TO_REFRESH:

            const __id__ = action.data && action.data.base_report_id;
            const clonned_report_status_cache = JSON.parse(JSON.stringify(state.report_status_cache));
            // const need_to_force_refresh_be = action?.data?.is_cache_refresh_required_forcefully

            if (__id__) {
                clonned_report_status_cache[__id__] = {
                    is_cache_refresh_required_forcefully: action?.data?.is_cache_refresh_required_forcefully
                };
            }
            return {
                ...state,
                type: action.type,
                clonned_report_status_cache
            };


        case actions.CLEAR_REPORT_DATA:

            const base_report_id_1 = action.data && action.data.base_report_id;
            const prev_report_data_1 = state.dataFromServer ? JSON.parse(JSON.stringify(state.dataFromServer)) : {};

            if (base_report_id_1 && prev_report_data_1[base_report_id_1]) {
                delete prev_report_data_1[base_report_id_1];
            }

            return {
                ...state,
                type: action.type,
                dataFromServer: prev_report_data_1
            };

        case actions.UPDATE_PIVOT_DATA:

            const base_r_id = action?.data?.base_report_id;
            const new_pivot_data_v1 = action?.data?.sorted_pivot_data;
            const sort_orders_2 = action?.data?.sort_orders;
            const prev_data_from_server_data_1 = state.dataFromServer ? JSON.parse(JSON.stringify(state.dataFromServer)) : {};
            const target_report_1 = prev_data_from_server_data_1?.[base_r_id];
            if (base_r_id && new_pivot_data_v1 && target_report_1) {
                target_report_1.pivot_data['data'] = new_pivot_data_v1;
                prev_data_from_server_data_1[base_r_id] = target_report_1;
            }
            return {
                ...state,
                type: action.type,
                dataFromServer: prev_data_from_server_data_1
            }
        case actions.UPDATE_PROCESS_DATA:

        // const base_report_id_3 = action?.data?.base_report_id;
        // const process_data_3 = action?.data?.process_data;
        // const sort_orders_3 = action?.data?.sort_orders;

        // const prev_data_from_server_data_3 = state.dataFromServer ? JSON.parse(JSON.stringify(state.dataFromServer)) : {};
        // const target_report_3 = prev_data_from_server_data_3?.[base_report_id_3]?.data;

        // if (base_report_id_3 && process_data_3 && target_report_3) {
        //     target_report_3.process_data = process_data_3
        //     prev_data_from_server_data_3[base_report_id_3].data = target_report_3
        // }
        // return {
        //     ...state,
        //     type: action.type,
        //     dataFromServer: prev_data_from_server_data_3
        // }

        case actions.REPORT_REFRESHED:

            const _id = action.data && action.data.base_report_id;
            const clonned_report_status_cache_1 = JSON.parse(JSON.stringify(state.report_status_cache));

            if (_id) {

                delete clonned_report_status_cache_1[_id];
            }

            return {
                ...state,
                type: action.type,
                clonned_report_status_cache: clonned_report_status_cache_1
            }        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying

        case actions.SAVE_DRILL_DOWN_VALUES:
            const _id_ = action.data && action.data.base_report_id;

            const clonned_drill_data__ = JSON.parse(JSON.stringify(state.drill_down_data));

            if (action.data && action.data.drill_down_data) {
                if (typeof clonned_drill_data__[_id_] === 'undefined') {
                    clonned_drill_data__[_id_] = [];
                }

                clonned_drill_data__[_id_] = action.data.drill_down_data;
            }
            else {
                clonned_drill_data__[_id_] = [];
            }

            return {
                ...state,
                type: action.type,
                drill_down_data: clonned_drill_data__
            }

        case actions.DISPATCH_FORMULA_DATA:

            const __payload__ = action.data;

            const clone_data_from_server = state.dataFromServer ? JSON.parse(JSON.stringify(state.dataFromServer)) : {};

            clone_data_from_server[__payload__?.reportId] = __payload__

            return {
                ...state,
                type: action.type,
                dataFromServer: clone_data_from_server

            }

        case actions.RAISE_SQL_LOADER:
            const clone_sql_loader = state.sql_loader_cache ? JSON.parse(JSON.stringify(state.sql_loader_cache)) : {};
            clone_sql_loader[action?.data?.id] = true;
            return {
                ...state,
                type: action.type,
                sql_loader_cache: clone_sql_loader
            }

        case actions.HIDE_SQL_LOADER:
            const clone_sql_loader1 = state.sql_loader_cache ? JSON.parse(JSON.stringify(state.sql_loader_cache)) : {};
            delete clone_sql_loader1[action?.data?.id];
            return {
                ...state,
                type: action.type,
                sql_loader_cache: clone_sql_loader1
            }


        case actions.UPDATE_REPORT_COLUMN_STATE:

            const clone_d_s_1 = state.dataFromServer ? JSON.parse(JSON.stringify(state.dataFromServer)) : {};

            const current_report_data_to_use = clone_d_s_1?.[action?.data?.report_id];
            const columns_state = action?.data?.columns_state;
            const apply_on = action?.data?.apply_on;

            if (apply_on === 'raw_data') {
                // do anything on raw data
                if (current_report_data_to_use) {
                    current_report_data_to_use.raw_data.raw_data_column_state = action.data.columns_state;
                    current_report_data_to_use.process_data_updated_at = new Date()
                    clone_d_s_1[action?.data?.report_id] = apply_everything_on_raw_data(current_report_data_to_use, columns_state);
                    // clone_d_s_1[action?.data?.report_id].not_apply_single_cell = true;
                }
            }

            if (apply_on === 'pivot_data') {
                ///do any on pivot data
                // console.log("columns_state", columns_state)
                if (current_report_data_to_use) {
                    current_report_data_to_use.pivot_data.pivot_columns_state = action.data.columns_state;
                    clone_d_s_1[action?.data?.report_id] = apply_everything_on_pivot_data(current_report_data_to_use, columns_state);
                    // clone_d_s_1[action?.data?.report_id].not_apply_single_cell = false;

                };
            }
            return {
                ...state,
                type: action.type,
                dataFromServer: clone_d_s_1
            }


        case actions.GET_POLUS_AI_DATA_STORY:

            const clone_get_data_story = state.gptDataStoryCache ? JSON.parse(JSON.stringify(state.gptDataStoryCache)) : {};

            if (!clone_get_data_story[action?.data?.report_id]) {
                clone_get_data_story[action?.data?.report_id] = action?.data?.data;
            } else {
                clone_get_data_story[action?.data?.report_id] = action?.data?.data
            }

            return {
                ...state,
                type: action.type,
                gptDataStoryCache: clone_get_data_story
            }

        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
            return {
                ...state,
                type: state.type,
                metaColumnsData: state.metaColumnsData,
                recordsCount: state.recordsCount,
                dataFromServer: state.dataFromServer,
                hints: state.hints,
                reports: state.reports,
                reportFormulas: state.reportFormulas,
                shareReportData: state.shareReportData,
                reportSavedData: state.reportSavedData,
                distinct_data: state.distinct_data,
                tbl_formatter: state.tbl_formatter,
                tbl_formulas: state.tbl_formulas,
                tbl_formulas_form_editor: state.tbl_formulas_form_editor,
                functions_config: state.functions_config,
                testSqlResult: state.testSqlResult,
                testSqlError: state.testSqlError,
                dataMode: state.dataMode,
                report_loader_cache: state.report_loader_cache
            };
    }
};

export default reportReducer;