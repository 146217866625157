
import styled from 'styled-components';
import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils';
const selected = getSelectedTheme();


const { textbox } = themes[selected];



export const HintsWrapper = styled.div`
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        box-sizing: border-box;
        background: white;
        width: 100%;

    /* input{
        height: 2.857rem;
        border-radius: 0px;
        border: 0px;
        background-color: #eaeaea;
        width: 100%;
        font-family: "DM Sans", sans-serif;
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.286rem;
        letter-spacing: normal;
        text-align: left;
        color: ${textbox.color};
        padding: 0px 16px;
        padding-right: 25px;
        box-sizing: border-box;

        &:hover{
            border-color:${textbox.border.hoverColor};
            outline: none;
            box-shadow: 0 0 0;
        }

        &:focus{
            box-shadow: 0 0 0;
            outline: none;
        }

    } */
`;


export const ExistingTags = styled.div`
    width: 100%;
    .e_tags{
        font-size: .8rem;
        font-weight: 600;
        font-stretch: normal;
        line-height: 1.286rem;
        text-align: left;
        color: #222;
        margin: 0px;
    }
`;




export const FilterdItemRow = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: auto;
    box-sizing: border-box;
    max-width: 100%;
    max-height: ${props => props.height ? props.height : '8rem'};
    flex-wrap: wrap;

    &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }


`;


export const Filterd = styled.div`
    height: 1.571rem;
    /* border: solid 1px #9caeb5; */
    border-radius: 6px;
    background-color: #e3eeff;
    padding: 0px 7px;
    margin-bottom: 5px;
    margin-right: 6px;
    box-sizing: border-box;
    position: relative;
    min-width: 35px;
    cursor: pointer;
    font-size: 0.714rem;
    font-weight: normal;
    text-align: center;
    color: rgb(0, 22, 31);
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0px 8px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.6rem;

    &:hover{
        .filter_hover{ 
            display: flex;
            
        }
    }

 .filter_hover{
     display: none;
     position: absolute;
     top: 0px;
     left: 0px;
     width: 100%;
     height: 100%;
     align-items: center;
     justify-content: center;
     padding: 0px 0px;
     box-sizing: border-box;
     background-color: #e3eeff;

     div{
         cursor: pointer;
         width: 35px;
         display: flex;
         align-items: center;
         justify-content: center;
     }
 }

`;
