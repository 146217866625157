import styled from "styled-components";
import { themes } from "../../theme/theme.config";
import { getSelectedTheme } from "../../utils";

const selected = getSelectedTheme();
const { table } = themes[selected];

export const EditorInnerWrapper = styled.div`
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
`;

export const TableWrapper = styled.div`
    width: 99%;
    padding: 0 0.4rem;
    height: ${(props) => (props.height ? props.height : "calc(100vh - 140px)")};
    overflow: auto;
    .status_btn{
        padding: 3px 8px;
        text-transform: uppercase;
        font-size: .7rem;
        border-radius: 6px;
        background: red;
    }
    .active{
        background: #deeec7;
        color: green;
    }
    .stopped{
        background: #fdeacc;
         color: #f6bb5f;
    }
    .approved{
        background: #deeec7;
        color: green;
    }
    .request{
        background: #fdeacc;
         color: #f6bb5f;
    }
    &::-webkit-scrollbar {
        background-color:white;
        width:5px
    }
    &::-webkit-scrollbar-track {
        background-color:#ffffff;
    }
    &::-webkit-scrollbar-thumb {
        background-color:#d9d9d9;
        border-radius:10px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color:white;
        height: 5px;
    }
 table{
        width: 100%;
        border: 0px;
        border-collapse: collapse;
        background-color: #fff;
        position: relative;
        
    }
    
    .form-control-input{
        line-height: 1.5rem;
        border: 1px solid transparent;
        border-radius: 3px;
        font-size: 0.875rem;
        &:hover{
              border: 1px solid #000;
              ::-webkit-calendar-picker-indicator{
                opacity:1;
                cursor: pointer;
            }
        }
    }
    thead{
        position: sticky;
        z-index: 3;
        top: 0;
        background: linear-gradient(to right, #3E9EFF, #737DFF);
        th{
            color: #fff;
            font-weight: 600;
            /* background-color:  #337DFF; */
            font-size: .8rem;
            border-bottom: 1px solid #ccc;
            text-align: left;
            /* padding: 0.26rem; */
            padding: 0.26rem 1rem;
        }
        .thead_column{
            div{
                display: inline-block;
            }
        }
        .dropdown_icon {
            cursor: pointer;
            line-height: 2rem;
            vertical-align: middle;
            color: white;
            svg{
                display: block;
            }
         }
    }
   td,th{
      height: ${(props) => (props.rowHeight ? props.rowHeight : "2.1rem")};
      font-size: ${(props) => (props.fontSize ? props.fontSize : "0.875rem")};
      text-align: left;
      text-transform: capitalize;
      text-align: left;
   }
   
   td{
        color: #334a8e;
        padding: 0.426rem 1rem;
      span{
        max-width: 40rem;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: flex;
      } 
      .form-control-input::-webkit-calendar-picker-indicator {
            color: rgba(0, 0, 0, 0);
            opacity: 0
     }
   }
   th{
        position: sticky;
        top: 0px;
        z-index: 3;
        
   }
   tbody tr{
       position: relative;
        &:nth-child(odd) {
            background-color: #f6f8fe;
        }
       &:focus{
           outline: none;
       } 
    }
    tbody>tr:hover{
        box-shadow: 4px 3px 10px 0px #a3a3d1;
        z-index: 1;

        .action_on_hover{

            visibility: visible;
        }
    }

   .action_on_hover{
         position: absolute;
         right: 1px;
         height: 2rem;
         padding:7px 5px;
         visibility: hidden;
         /* background-color: #f3f9fc; */ //where it isused
         margin-top: 2px;
         border-radius: 0px 35px 0px 35px;
         top: 0px
        /* box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px, rgb(0 0 0 / 23%) 0px 3px 6px; */
   
   }
   .action{
        display: flex;
        align-items: center;
        justify-content: center;
        
       
    button{
        width: 1.8rem;
        height: 1.8rem;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0px 1.3px;
        position: relative;
        &:hover{
            .options{
                display: block;
            }
        }
        .options{
                width: 2rem;
                position: absolute;
                top: 100%;
                display: none;
                z-index: 2;
                overflow: hidden;
                background: #fff;
                box-shadow: 0px 3px 3px #0f0f0f2b;
                .name{
                    padding: 5px 0px;
                    box-sizing: border-box;
                    &:hover{
                        background: #efefef;
                        svg{
                            fill: #308bc8;
                        }
                    }
                }
            }
    }
   }
`;

export const ContentWrapper = styled.div`
    border-radius: 50px;
    padding: 4px 6px;
    border: 1px solid #f3f3f3;
    text-align: center;
    background: ${(props) => (props.color ? props.color : props?.isBtn ? "#f3f3f3" : "#dee5fa")};
`;
