
/***
 * title: this is a select-option component 
 * author: saroj kumar
 * date: 13 jan 2022
 * 
 * accept props =>
 * 
 *      select_option_style = string
 *              ["type1", "type2", "type3", "type4"]
 *      on_change = function
 *      value = string
 *      options = array
 *      value_key = string
 *      display_key = string
 *      label = string
 *          
 */


import React, { useState, useEffect, useRef, forwardRef } from 'react';
import styled, { keyframes } from 'styled-components'
import * as _ from 'lodash';

const ripple = keyframes`
  to{
       transform: scale(2.5);
       opacity: 0;
     }
 `;


const MainDiv = styled.div`
     width: 100%;
     margin-top: ${props => props.hide_margin ? "0px" : "15px"};
     position: relative;
     /* padding-top: 10px; */
     /* height: 100px; */
     .none_user_input{
         opacity: 0;
         width: 0px;
         height: 0px;
     }
 `;

const OverlayMain = styled.div`
     width: 100vw;
     height: 100vh;
     z-index: 1200;
     position: fixed;
     top: 0px;
     left: 0px;
     background: #ff000000;
 `;

const SuggestionList = styled.div`
 
     width: ${props => props?.width || '100%'};
     top : ${props => props?.top + "px" || "0px"};
     left : ${props => props?.left + "px" || "0px"};
     box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
     background-color: red;
     background-color: #fff;
     box-sizing: border-box;
     border: 1px solid #ccc;
     border-top: 0px;
     max-height: 180px;
     min-height: 180px;
     overflow: auto;
     position: absolute;
 
     &::-webkit-scrollbar {
         background-color:red;
         width: 3px
     }
     &::-webkit-scrollbar-track {
         background-color:#b1cad5;
     }
     &::-webkit-scrollbar-thumb {
         background-color:#76919d;
         border-radius:0px;
         height: 3px;
     }
     &::-webkit-scrollbar:horizontal{
         background-color: green;
         height: 3px;
     }
     .suggestion_item{
         font-size: .9rem;
         padding: .5rem 17px;
         box-sizing: border-box;
         color: #222;
         cursor: pointer;
         overflow: hidden;
         display: flex;
         align-items: center;
         text-transform: capitalize;
         &:hover{
             background-color: rgba(0, 0, 0, 0.04);
         }
     }
 
     .ripple{
         border-radius: 50%;
         background-color: rgba(255, 255, 255, 0.7);
         position: absolute;
         transform: scale(0);
         animation: ${ripple} 0.6s linear;
     }
     .active{
 
         background-color: rgba(25, 118, 210, 0.08);
 
         &:hover{
             background-color: rgba(25, 118, 210, 0.08);
         }
     }
     .suggestion_search{
         padding: 0px .2rem;
         margin-top: .5rem;
         margin-bottom: .3rem;
         
         input{
             width: 100%;
             box-sizing: border-box;
             height: 1.8rem;
             font-size: .9rem;
             color: #222;
             border: 1px solid;
             border-color: #ccc;
             padding: .5rem;
         }
     }
     .no_record_found{
 
         text-align: center;
         font-size: .6rem;
         padding: .5rem;
         box-sizing: border-box;
         color: #222;
     }
     .checkbox{
 
         width: 13px;
         height: 13px;
         margin-right: 10px;
         cursor: pointer;
     }
     .group_key{
 
         text-transform: capitalize;
         box-sizing: border-box;
         line-height: 31px;
         list-style: none;
         color: rgba(0, 0, 0, 0.6);
         font-weight: 500;
         font-size: 0.9rem;
         padding-left: 16px;
         padding-right: 16px;
         position: sticky;
         top: 0px;
         z-index: 1;
         background-color: rgb(255, 255, 255);
     }
 `;




const UserInput = styled.div`
 
     width: 100%;
     height: ${props => props.height || '2.4rem'};
     background: ${props => props.background ? props.background : '#fff'};
     position: relative;
     display: flex;
     align-items: center;
     cursor: pointer;
     box-sizing: border-box;
     border-radius: 6px;

     input{
         width: 100%;
         height: 100%;
         border: 0px;
         border-radius: 2px;
         border: ${props => props.hide_border ? '0px' : props?.focused ? '2px solid #007FFF' : '1px solid #0000003b'};
         /* border: 2px solid #f6f8fe; */
         border-radius: 6px;
         padding: 0px 10px;
         box-sizing: border-box;
         font-size: .9rem;
         font-weight: normal;
         color: rgb(55, 74, 102);
         background-color: transparent;
         z-index: 2;
         box-shadow: ${props => props.enable_shadow ? "5px 2px 2px 2px #eaeaea" : undefined};

        &:hover{
            border: 1px solid #afc4e3;
        }
        &:focus{
            outline: none;
            border: ${props => props?.focused ? '2px solid #007FFF' : '1px solid #0000003b'};
            /* border: ${props => props?.focused ? '0px solid #007FFF' : '1px solid #0000003b'}; */
        }
     }
     
     
     .value_txt{
         white-space: nowrap;
         text-overflow: ellipsis;
         font-size: .9rem;
         font-weight: normal;
         color: rgb(55, 74, 102);
     }
     .label_pre{
         white-space: nowrap;
         text-overflow: ellipsis;
         font-size: .9rem;
         color: #646464;
         font-weight: 300;   
         text-transform: capitalize;
         color: rgba(0, 0, 0, 0.6);
         /* font-family: Roboto, Helvetica, Arial, sans-serif; */
         font-weight: 400;
         font-size: .9rem;
         line-height: 1.4375em;
         letter-spacing: 0.00938em;
         transition: all 0.2s;
         position: absolute;
         left: 10px;
         background-color: ${props => props.background ? props.background : '#fff'};
         z-index: 1;
         ${props => props.labelStyle ? { ...props.labelStyle } : undefined}

      }
      
 
      .label_post{
         color: rgba(0, 0, 0, 0.6);
         font-weight: 400;
         /* font-family: Roboto, Helvetica, Arial, sans-serif; */
         font-size: .9rem;
         transform: translate(-11%, -100%) scale(0.8);
         background-color: ${props => props.background ? props.background : '#fff'};
         padding: 0px 7px;
         z-index: 3;
         ${props => props.labelStyle ? { ...props.labelStyle } : undefined}
         /* position: relative; */

         &::before{
            content: '';
            height: 14px;
            width: 100%;
            position: absolute;
            background: ${props => props.labelBgColor ? props.labelBgColor : props.background};
            ${props => props.labelStyle ? { ...props.labelStyle } : undefined}

            top: 0px;
            left: 0px;
            z-index: -1;
         }

      }
 
     .select_arrow{
         position: absolute;
         right: 0px;
         top: 0px;
         bottom: 0px;
         height: 100%;
         width: 30px;
         display: flex;
         align-items: center;
         justify-content: center;
         transform: ${props => props.is_open ? "rotate(180deg)" : undefined};
     }
 `;



const TextBoxV1 = forwardRef((props, ref) => {


    /**
     * here we are destructuring the props
     */

    const {

        value,
        defultValue,
        type,
        label,
        onChange,
        onFocus,
        onBlur,
        background,
        labelStyle,
        hide_case,
        error,
        autoFocus,
        hide_margin,
        hide_border,
        height,
        id,
        designType = "border",
        enable_shadow = "2px 1px 3px 2px #cccccc4d",
        inputStyle,
        labelBgColor,
        readOnly,

    } = props;



    /// State is here of the component

    const [focused, set_focused] = useState(undefined)


    const el_id = "input__id__uniqqq_auto_gen" + Math.random(100)
    const is_input_have_value = value && (value.length > 0 || defultValue?.length > 0);
    const lable_class = "label_pre" + " " + ((focused || is_input_have_value) ? "label_post" : 'dd');

    const is_label_post = (focused || is_input_have_value)
    const label_style_temp = is_label_post ? (props.lablePostStyle || {}) : (props.lablePreStyle || {})
    const label_style = { ...(labelStyle || {}), ...label_style_temp }
    // 

    return (
        <MainDiv height={height} hide_border={hide_border} hide_margin={hide_margin}>

            {/* this is input but not visible for user */}

            {/* this is active value placholder  */}

            <UserInput
                hide_border={hide_border}
                background={background}
                labelBgColor={labelBgColor}
                height={height}
                id={id}
                // id={el_id}
                // ref={props.ref_name}
                focused={focused}
                onBlur={() => {
                    set_focused(false)
                }}

                labelStyle={label_style}
                style={inputStyle}


            >
                {props.label && !props?.show_as_placholder && <span className={lable_class} style={{ color: focused ? "#007FFF" : undefined }}>{!hide_case ? _.startCase(_.toLower(label)) : label}</span>}

                <input
                    placeholder={props?.show_as_placholder && (!hide_case ? _.startCase(_.toLower(label)) : label)}
                    autoFocus={autoFocus}
                    ref={ref || props.ref_name}
                    value={value}
                    defultValue={defultValue}
                    onFocus={(e) => {
                        if (onFocus) onFocus(e)
                        set_focused(true)
                    }}
                    onBlur={(e) => {
                        if (onBlur) onBlur(e)
                        set_focused(false)
                    }}
                    onChange={(e) => {
                        onChange(e)
                    }}
                    onKeyDown={(e) => {
                        if (e && props.onKeyDown) {
                            props.onKeyDown(e)
                        }
                    }}
                    type={type || "text"}
                    style={inputStyle}
                    readOnly={readOnly}
                />

            </UserInput>
        </MainDiv>
    );
})
export default TextBoxV1;