import React from 'react';
import { Wrapper } from './tooltip.styled';
import { throttle } from '../../../utils/throttle';
import { checkYearAndMakeFy } from '../../../utils';

const getSortedKeys = (obj, xDataKeys) => {
    return Object.keys(obj).sort((k1, k2) => {
        // Prioritize xDataKeys first (like 'Month', 'Service')
        if (xDataKeys.includes(k1) && !xDataKeys.includes(k2)) return -1;
        if (!xDataKeys.includes(k1) && xDataKeys.includes(k2)) return 1;
        return 0;
    });
};

const truncateWord = (word='', limit = 70) => {
    
    if(checkYearAndMakeFy(word)){
        return checkYearAndMakeFy(word)
    }

    const nw = word?.length > 1 ? word?.split('_nfx_').join(' ') : word;
    if (nw?.length > limit) {
        return nw?.substring(0, limit) + "...";
    }
    return nw;
};

const ToolTipCustom = throttle((props) => {

    const { data, theme, xDataKeys=[], payload } = props;

    const sorted_keys = data && Object.keys(data)//getSortedKeys(data, xDataKeys);

    return (
        <React.Fragment>
            {data && (
                <Wrapper theme={theme}>
                    <div className="tooltip-content">

                        {xDataKeys?.map((key, j) => {
                            return (
                                <div className="label" style={{
                                    marginBottom: '10px'
                                }} key={"tooltip-xaxis-" + j}>
                                    <div className='__key'>{truncateWord(key)}</div>
                                    <div className='__value'> {truncateWord(data[key])}</div>
                                </div>
                            );
                        })}
                    </div>

                    {xDataKeys?.length > 0 && <div className="divider" />}

                    {sorted_keys.map((key, j) => {

                        if (xDataKeys?.includes(key)) return null;

                        const current = payload?.find(k => k?.dataKey == key)
                        // console.log("current", payload, current, key)

                        return (
                            <div key={"tooltip-other-" + j} className="bottom_data">
                                <div className="dot" style={{
                                    backgroundColor: current?.stroke || current?.fill || "#009596"
                                }} /> {/* Colored Dot */}
                                <div className='label'>
                                    <div className='__key'>{truncateWord(key)}</div>
                                    <div className='__value'> {truncateWord(data[key])}</div>
                                </div>
                            </div>
                        );
                    })}
                </Wrapper>
            )}
        </React.Fragment>
    );
}, 100);

export default ToolTipCustom;
