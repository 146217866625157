import * as actions from '../action-types/bread-crumb'


const initialState = {
    type: actions.BREAD_CRUMB_INIT,
    items: [{
        reportId: undefined,
        name: 'Home',
        question: undefined
    }]
};

/**
 * let's keep the format like this:
 * 
 * {
 *      filterId: '',
 *      name: '',
 *      reportId: ''
 * }
 */

const BreadCrumbReducer = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }
    

    switch (action.type) {
        case actions.BREAD_CRUMB_ADD:
            const _itemsFromState = JSON.parse(JSON.stringify(state.items));
            _itemsFromState.push(action.data);

            return {
                type: action.type,
                items: _itemsFromState
            };

        case actions.BREAD_CRUMB_CLEAR:
            return {
                type: action.type,
                items: [{
                    reportId: undefined,
                    name: 'Home',
                    question: undefined
                }]
            };

        case actions.BREAD_CRUMB_SWITCH:
            /**
             * when we click on another Breadcrumb
             */
            const itemsFromState = JSON.parse(JSON.stringify(state.items));
            const index = action.data.index;

            return {
                type: action.type,
                items: itemsFromState.slice(0, index)
            };

        default:
            return {
                type: state.type,
                items: state.items,
            };
    }
}

export default BreadCrumbReducer;