import styled from 'styled-components';
import { colors } from '../../theme/colors'
import { themes } from '../../theme/theme.config';
import { getSelectedTheme } from '../../utils';

const selected = getSelectedTheme();
const { polusIqStyle } = themes[selected];


export const DimensionWrapper = styled.div`
    width: ${props => props.isShow ? "30rem" : "0px"};
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-wrap: 'wrap';
    flex-direction: row;
    box-sizing: border-box;
    margin : auto;
    overflow: hidden;
    transition: all .3s;



    .axis_title {
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.286rem;
        font-size: 1.286rem;
        letter-spacing: normal;
        text-align: left;
        color: #1a1a1a;
        
    }

    .dimansion_wrapper{
        border: 1px solid #c2e3ef;
        border-radius: 3px;
        width: auto;;
        margin-left: auto;
        align-items: flex-start;
        display: flex;
        justify-content: flex-end;
        flex-wrap: 'wrap';
        flex-direction: row;
        overflow: hidden;
    }
`;



export const DimensionCard = styled.div`
    padding: 0rem 1.5rem;
    cursor: pointer;
    background-color: ${props => props.active ? ' #c2e3ef' : polusIqStyle.color.iqWrapperBg};
    transition: all 0.2s;
    margin-left: 0px;
    height: 2.1rem;
    display: flex;
    align-items: center;
    position: relative;
    border-right: 1px solid #fff;
    transition: all .2s;
    &:last-child {
        border-right: 0px;
    }
    .top_title{
        text-transform: capitalize;
        font-size: ${props => props.active ? '0.857rem' : '1rem'};
        font-stretch: normal;
        font-style: normal;
        line-height: 18px;
        font-weight: ${props => props.active ? 'bold' : 'normal'};
        letter-spacing: normal;
        text-align: center;
        color: ${props => props.active ? 'rgb(64, 89, 99)' : polusIqStyle.color.iqTextColor};
        width: 100%;
        text-overflow: ellipsis;;
        overflow: hidden;
        white-space: nowrap;
    }

    &:hover{
        background-color: ${props => props.active ? '#c2e3ef' : '#308bc8'};
        .top_title{
            color: #fff;
        }
        cursor: ${props => props.active ? 'default' : 'pointer'};
    }
`;


export const DimTitle = styled.div`
    font-size: 1.2rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;   
    text-align: left;
    color: ${colors.primary};
    position: relative;
    height: 45px;
    display: flex;
    align-items: center;
    margin-right: 20px;
    
    &::after {
        content: '';
        display: block;
        background: gray;
        height: 1em;
        width: 100%;
        position: absolute;
        left: .4em;
        top: .4em;
        z-index: -1;
    }
    span{
        display: inline-block;
        padding: 4px 0px;
    }
`;