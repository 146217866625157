export const RULES_ALERT_INIT = 'modules/rulesAlert/rules_alert_init'
export const SAVE_RULE_ALERT_SUCCESS = 'modules/rulesAlert/save_rule_alert_success'
export const GET_ALL_RULES_ALERT_SUCCESS = 'modules/rulesAlert/get_all_rules_alert_success';

export const GET_TICKETS_OF_RULE_SUCCESS = 'modules/rulesAlert/get_tickets_of_rule_alert_success';
export const SAVE_TICKET_ACTION_SUCCESS = 'modules/rulesAlert/save_rule_alert_success';


export const RUN_RULE_ALERT_SUCCESS = 'modules/rulesAlert/run_rule_job '


export const GET_ALL_RULES_JOBS = 'modules/rulesAlert/get_all_rules_jobs';

export const GET_JOB_BY_REPORT_ID = 'modules/rulesAlert/get_job_by_report_id';
export const CLEAR_JOB_BY_REPORT_ID = 'modules/rulesAlert/clear_job_by_report_id';