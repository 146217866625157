import React, { useEffect, useState } from 'react';

import BarChart from '../charts/bar-charts/bar.chart';
import PieChart from '../charts/pie-charts/simple.pie.chart';
import LineChart from '../charts/bar-charts/line.chart';
import AreaChart from '../charts/bar-charts/stacked.area.chart';
import LineBarChart from '../charts/bar-charts/line.bar.chart';
import RadarChart from '../charts/radar-charts/simple.radar.chart';
import BiaxialChart from '../charts/biaxial';
import MultiReport from '../charts/multi-report-table';
import Gauge from '../charts/gauge-chart';
import GuageChart from '../charts/pie-charts/guage_chart';
import RadialBarChart from '../charts/radial-chart/simple.radial.bar.chart';
import ScatterChart from '../charts/scatter-chart/simple.scatter.chart';
import ComposedBarChart from '../charts/composed-chart/composed.bar.chart';
import WaterFallChartWrapper from '../charts/bar-charts/waterfall.chart'
import HorizontalWaterFallChart from '../charts/bar-charts/horizontal.waterfall.chart'
import HorizontalBarChart from '../charts/bar-charts/horizontal.bar.chart'
import TreeMap from '../charts/tree.map'

import { getRandomColor, getPaddingOnXAxis, getChartDataWithType } from '../../utils/chart.helper';
import { CHART_TYPES, RENDER_MODE } from '../../shared-with-fe/constants';
import { themeObject, bind_theme_data_to_object, apply_color_sets_to_colors } from '../../theme';
import { calculate_trend_line } from '../../utils/v1.2/trendlines';
import { CHART_CONSTANTS } from '../../utils/chart.config';
import { console_logger, formatValueByDataType } from '../../utils';
import * as dataTypes from '../../shared-with-fe/data.types';

import GanttChart from '../charts/GanttChart/index';
// import BubbleChart from '../charts/bubbleChart/BubbleChartSimple'; // single
import BubbleChart from '../charts/bubbleChart/BubbleChart';
import ParetoChart from '../charts/paretoChart/paretoChart';
import FunnelChart from '../charts/funnel-chart/index';



let render = 0;

const ChartWrapper = (props) => {

    // console.log('brilliant amigo: ', new Date());

    const {

        data,
        xDataKeys,
        renderMode,
        heightOfChart,
        widthOfChart,
        chartType,
        getDataFromReportingServer,
        filters,
        question,
        recentColors,
        addRecentColor,
        gaugeChartSettings,
        showReferenceLine,
        label, values, formula,
        apply_filters, isTop, mainFormulas, id,
        chartId, isReport, showTwoAxis, formulaMeta, insights, isDimension, dimensions, isDiscovery, chartAdditionalInfo,
        hide_label, hide_title, hide_y_axis, hide_x_axis, hide_y_axis_border, hide_x_axis_border, hide_grid, hide_legend, hide_border, hide_tooltip, hide_x_axis_label, hide_y_axis_label, isPredictive, staticYAxisLabel,
        showRightYAxis, show_legend_as_percent, show_label_in_legend, windowWidth, isMobile, theme_json_values, drill_down_type,
        hide_x_axis_secondry

    } = props;

    let chart_type = props.chartType ? props.chartType : undefined;


    const [test, setTest] = useState([])

    let theme_data = {};

    var theme_key_to_use = (insights || props.isPolusAIWindow) ? 'insight' : 'report';

    if (props.view_mode === 'expanded' && props.isPolusAIWindow) {
        theme_key_to_use = 'report'
    }


    const apply_theme = () => {
        const __theme__ = JSON.parse(JSON.stringify(themeObject));
        // after we have run this function, we will have the values from client theme loaded into the default theme
        if (__theme__?.[theme_key_to_use]?.['chart']) {
            theme_data = __theme__?.[theme_key_to_use]?.['chart'];
            bind_theme_data_to_object(theme_data, theme_json_values);
            apply_color_sets_to_colors(theme_data, __theme__.colorset);
        } else {
            theme_data = __theme__
        }
    }

    apply_theme()

    const chartStyle = theme_data
    const colors = chartStyle && chartStyle.chart_colors;
    const tooltipTheme = chartStyle.tooltip && chartStyle.tooltip;
    const barChartHoverColor = chartStyle.hover_color;

    let yDataKeys = props.yDataKeys;

    const [colorForYData, setColorForYData] = useState([]);
    const [colorForPieChart, setColorForPieChart] = useState([]);
    const [paddingOnXaxis, setPaddingOnXaxis] = useState([]);
    const [dataOfRadialBar, setDataOfRadialBar] = useState([]);
    const [dataofScaterChart, setDataOfScaterChart] = useState([]);
    const [valueOfReferenceLine, setValueOfReferenceLine] = useState(undefined);
    const [Alignments, setAlignments] = useState(undefined);

    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(0);
    const [avg_value, set_avg_value] = useState(0);
    const [stdeviation, setStdeviation] = useState(0);


    const chart_limit = props.isPredictive ? 1000000 : (chart_type === CHART_TYPES.scatter_chart ? CHART_CONSTANTS.MAX_SCATTER_DATA_LIMIT : CHART_CONSTANTS.MAX_CHART_DATA_LIMIT);
    const pivot_xac_indexes_count = (props?.data?.length || 0) * (xDataKeys?.length || 0)

    console.log("chart_limit", chart_limit, pivot_xac_indexes_count);


    useEffect(() => {

        if (props?.data && yDataKeys?.length > 0 &&
            pivot_xac_indexes_count < chart_limit &&
            yDataKeys?.length < CHART_CONSTANTS.MAX_Y_AXIS &&
            xDataKeys?.length < CHART_CONSTANTS.MAX_X_AXIS
        ) {

            const colorForYDataLocal = Object.values(colors);
            const donutColors = [
                '#006BB3',
                '#e60000',
                '#00e600',
                '#e60073',
                '#d9ac2a',
                '#d96b0c'
            ]
            let tempDataofRadialChart = [];
            let tempDataofScatterChart = [];
            let tempValueofReferenceLine = 0;
            let dataLen = props.data ? props.data.length : undefined;


            props.data && props.data.length > 0 && props.data.map((d, i) => {

                if (yDataKeys && yDataKeys.length > 0) {
                    let key = yDataKeys[0];
                    tempValueofReferenceLine = tempValueofReferenceLine + d[key];
                }


                let keys = Object.keys(d);
                let outer_obj = {
                    fill: colors[i]
                };
                let _outer_obj_for_scatter = {}
                keys && keys.length > 0 && keys.map((key, j) => {
                    let _object = {
                        [key]: d[key],
                    };
                    Object.assign(outer_obj, _object);

                    let _obj = {}

                    if (key === yDataKeys[0]) {
                        _obj[key] = d[yDataKeys[0]]
                    }
                    if (key === yDataKeys[1]) {
                        _obj[key] = d[yDataKeys[1]]
                    }
                    Object.assign(_outer_obj_for_scatter, _obj)
                })
                tempDataofScatterChart.push(_outer_obj_for_scatter)
                tempDataofRadialChart.push(outer_obj)
            })



            /* 
                * Creating colors for donut and piechart based on the chartType
            */

            const colorForPieChart = chartType === 'donut-chart' ? donutColors : getRandomColor(props.data.length);


            const temp_aligment = getChartDataWithType(data, props.xDataKeys, props.yDataKeys, props.columnMeta, props.comparisonKeys, props.comparisons, mainFormulas)

            setAlignments(temp_aligment)

            /*  
                * getPaddingOnXAxis()
                    *This return the padding value on the chart
            */

            const paddingOnXaxis = !isReport ? getPaddingOnXAxis(props.data.length) : 0;

            setColorForPieChart(colorForPieChart);
            setColorForYData(colorForYDataLocal);
            // setPaddingOnXaxis(paddingOnXaxis);
            setDataOfRadialBar(tempDataofRadialChart)
            setDataOfScaterChart(tempDataofScatterChart)
            setValueOfReferenceLine(tempValueofReferenceLine / dataLen)
        }

    }, [props.data, theme_json_values]);


    /* 
        * This function change the color of bar or pie or line or area on the chart     
    */

    const changeColorOfYData = (color, index) => {
        const tempColors = [...colorForYData];

        tempColors[index] = color;

        setColorForYData(tempColors);
    };


    const _chart_width = isMobile ? (windowWidth - 20) : widthOfChart;
    let difference = undefined;
    let tempChartInfo = chartAdditionalInfo ? chartAdditionalInfo : [];


    chartAdditionalInfo && chartAdditionalInfo.length > 0 && chartAdditionalInfo.sort((a, b) => {
        const a_value = a.axis_position === 'Y Axis Primary' ? 1 : 0;
        const b_value = b.axis_position === 'Y Axis Primary' ? 1 : 0;
        return a_value - b_value;
    });


    if (chartType === CHART_TYPES.composed_bar_chart) {
        tempChartInfo = [];

        chartAdditionalInfo && chartAdditionalInfo.length > 0 && chartAdditionalInfo.forEach((item, index) => {
            tempChartInfo.push(item);
        })
    }

    /***
     * composed bar chart will only render on insight 
     * as vikas sir told me to change this code for only composed_bar_chart
     * 28 jun 2022
     */

    if (yDataKeys && yDataKeys.length > 1 && props.chartType === CHART_TYPES.composed_bar_chart && (tempChartInfo.length === 0 || tempChartInfo.length < 2)) {
        const max_of_first = Math.max.apply(Math, data.map(function (o) { return o[yDataKeys[0]] }))
        const max_of_second = Math.max.apply(Math, data.map(function (o) { return o[yDataKeys[1]] }))
        if (max_of_first > max_of_second) {
            difference = (max_of_second / max_of_first)
            if (difference < .2) {
                chart_type = CHART_TYPES.composed_bar_chart
                tempChartInfo = []
                tempChartInfo.push({
                    axis_position: 'Y Axis Primary',
                    chart_type: 'bar',
                    column_name: yDataKeys[0],
                })
                tempChartInfo.push({
                    axis_position: 'Y Axis Secondary',
                    chart_type: 'line',
                    column_name: yDataKeys[1],
                })
            } else {
                difference = undefined;
                chart_type = CHART_TYPES.bar_chart

            }
        }

        if (max_of_second > max_of_first) {
            difference = (max_of_first / max_of_second)
            if (difference < .2) {
                chart_type = CHART_TYPES.composed_bar_chart
                tempChartInfo = [];
                tempChartInfo.push({
                    axis_position: 'Y Axis Primary',
                    chart_type: 'bar',
                    column_name: yDataKeys[1],
                })
                tempChartInfo.push({
                    axis_position: 'Y Axis Secondary',
                    chart_type: 'line',
                    column_name: yDataKeys[0],
                })
            } else {
                difference = undefined;
                chart_type = CHART_TYPES.bar_chart

            }
        }
    }


    const need_to_overwrite_legend_style = !props.restricted_view && insights;

    if (need_to_overwrite_legend_style && theme_data) {
        if (theme_data.legend && theme_data.legend.label && theme_data.legend.label.font) {
            // theme_data.legend.label.font.size = ".9rem";
        }
        if (theme_data.tooltip && theme_data.tooltip.font) {
            // theme_data.tooltip.font.size = ".9rem"
        }
    }




    // let's format the 
    // clone_data_to_use


    const convert_normal_date_into_dd_mm_yyyy = (data, column_meta) => {

        data && data.forEach(row => {
            row && Object.keys(row).forEach(column => {

                const data_from_row = row?.[column];
                const meta = column_meta?.[column];
                const data_type = meta?.['data_type'];

                if (meta && (data_type === dataTypes.date || data_type === dataTypes.date_time)) {
                    const data_to_use = formatValueByDataType(data_from_row, data_type)
                    // here we are formating the date 
                    if (row[meta.display_value]) {
                        row[meta.display_value] = data_to_use;
                    }
                }
            });
        });
    };


    const clone_data_to_use = data ? JSON.parse(JSON.stringify(data)) : [];

    // this is a patch code only we added this for chart
    if (clone_data_to_use?.length > 0 && chart_type !== 'gantt_chart') convert_normal_date_into_dd_mm_yyyy(clone_data_to_use, props.columnMeta)

    const equations = calculate_trend_line(clone_data_to_use, yDataKeys)


    if (yDataKeys?.length > 0 && clone_data_to_use && (clone_data_to_use.length * yDataKeys.length) < 5 && chart_type !== "tree_map") {
        // clone_data_to_use.push({})
        // clone_data_to_use.push({})
        // clone_data_to_use.unshift({})
    }


    const reporting_db_info_id = props?.report_item?.reporting_db_info_id;
    const is_require_cache = props?.report_item?.is_require_cache;
    const is_drill_down_applicable = props?.is_drill_down_applicable;


    return (
        <div
            style={{
                marginTop: isTop ? '10px' : '0',
                marginBottom: isTop ? '10px' : '0', marginLeft: 'auto',
                marginRight: 'auto', width: _chart_width + "px",
                minHeight: heightOfChart + "px"
            }}>


            {

                (pivot_xac_indexes_count > chart_limit ||
                    yDataKeys?.length > CHART_CONSTANTS.MAX_Y_AXIS ||
                    xDataKeys?.length > CHART_CONSTANTS.MAX_X_AXIS) &&

                <p style={{
                    margin: 'auto',
                    position: 'absolute',
                    left: '0px',
                    right: '0px',
                    bottom: '0px',
                    top: '0px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '1.4rem',
                    fontWeight: '500',
                    fontStyle: 'italic',
                    textAlign: 'center',
                    height: '50%'
                }}>Too many columns...</p>
            }

            {
                (chart_type === 'gauge_chart'  || (pivot_xac_indexes_count < chart_limit &&
                yDataKeys?.length < CHART_CONSTANTS.MAX_Y_AXIS &&
                xDataKeys?.length < CHART_CONSTANTS.MAX_X_AXIS &&
                colorForYData &&
                ((!chart_type !== 'multi_report' &&
                    props.data &&
                    props.data.length > 0 &&
                    xDataKeys.length > 0) || (values && values.length > 0 &&
                        (chart_type === 'gauge_chart' || chart_type === 'multi_report'))))) &&
                _chart_width && heightOfChart && !props.loading &&

                <React.Fragment>
                    {
                        <div>
                            {

                                (
                                    renderMode === RENDER_MODE.chart &&
                                    data && data.length > 0 &&
                                    (
                                        chart_type === CHART_TYPES.bar_chart ||
                                        chart_type === CHART_TYPES.brush_bar_chart ||
                                        chart_type === CHART_TYPES.stacked_bar_chart ||
                                        chart_type === CHART_TYPES.line_chart ||
                                        chart_type === CHART_TYPES.area_chart ||
                                        chart_type === CHART_TYPES.stacked_bar_chart_100 ||
                                        chart_type === CHART_TYPES.area_chart_100 ||
                                        chart_type === CHART_TYPES.waterfall_chart ||
                                        chart_type === CHART_TYPES.horizontal_waterfall_chart ||
                                        chart_type === "horizontal_waterfall_chart" ||
                                        chart_type === CHART_TYPES.horizontal_bar_chart ||
                                        chart_type === CHART_TYPES.horizontal_stack_bar_chart ||
                                        chart_type === CHART_TYPES.horizontal_stack_bar_chart_100 ||
                                        chart_type === CHART_TYPES.ogive_chart ||
                                        chart_type === CHART_TYPES.line_bar_chart ||
                                        chart_type === CHART_TYPES.dash_line_chart ||
                                        chart_type === CHART_TYPES.predictive_chart

                                    )
                                ) &&

                                <BarChart
                                    {...props}
                                    id={id}
                                    equations={equations}
                                    chartType={chartType === CHART_TYPES.dash_line_chart ? CHART_TYPES.line_chart : chartType}
                                    Alignments={props.columnsAligments}
                                    showDash={chart_type === CHART_TYPES.dash_line_chart}
                                    data={clone_data_to_use}
                                    xDataKeys={xDataKeys}
                                    yDataKeys={yDataKeys}
                                    colorForYData={colorForYData}
                                    paddingOnXaxis={paddingOnXaxis}
                                    widthOfChart={_chart_width}
                                    showBrush={chart_type === CHART_TYPES.brush_bar_chart}
                                    showReferenceLine={showReferenceLine}
                                    valueOfReferenceLine={valueOfReferenceLine}
                                    showStacked={chart_type === CHART_TYPES.stacked_bar_chart}
                                    heightOfChart={heightOfChart}
                                    recentColors={recentColors}
                                    addRecentColor={addRecentColor}
                                    changeColorOfYData={changeColorOfYData}
                                    filters={filters}
                                    getDataFromReportingServer={getDataFromReportingServer}
                                    question={question}
                                    showLegend={true}
                                    isReport={isReport}
                                    showTwoAxis={showTwoAxis}
                                    insights={insights}
                                    isDimension={isDimension}
                                    isDiscovery={isDiscovery}
                                    dimensions={dimensions}
                                    hide_label={hide_label}
                                    hide_y_axis={hide_y_axis}
                                    hide_x_axis={hide_x_axis}
                                    hide_x_axis_secondry={hide_x_axis_secondry}
                                    hide_title={hide_title}
                                    hide_x_axis_border={hide_x_axis_border}
                                    hide_y_axis_border={hide_y_axis_border}
                                    hide_grid={hide_grid}
                                    hide_legend={insights ? hide_legend : false}
                                    hide_border={hide_border}
                                    hide_tooltip={hide_tooltip}
                                    hide_x_axis_label={hide_x_axis_label}
                                    hide_y_axis_label={hide_y_axis_label}
                                    isPredictive={isPredictive}
                                    staticYAxisLabel={staticYAxisLabel}
                                    showRightYAxis={showRightYAxis}
                                    chartStyle={chartStyle}
                                    drill_down_type={drill_down_type}
                                    drill_down_report={props.drill_down_report}
                                    od_report={props.od_report}
                                    od_report_id={props.od_report_id}
                                    tooltipTheme={tooltipTheme}
                                    barChartHoverColor={barChartHoverColor}
                                    drill_down_values={props.drill_down_values}
                                    comparisonKeys={props.comparisonKeys}
                                    comparisons={props.comparisons}
                                    yac_totals={props.yac_totals}
                                    _data={test}
                                    reporting_db_info_id={reporting_db_info_id}
                                    is_require_cache={is_require_cache}
                                    is_drill_down_applicable={is_drill_down_applicable}
                                    show_data_label={props.show_data_label}
                                />

                            }

                            {renderMode === RENDER_MODE.chart && data && data.length > 0 && chart_type === "gantt_chart" && (
                                <GanttChart
                                    widthOfChart={_chart_width}
                                    heightOfChart={heightOfChart}
                                    colorForYData={colorForYData?.length > 0 ? colorForYData : Object.values(colors)}
                                    data={clone_data_to_use}
                                    xDataKeys={xDataKeys}
                                    yDataKeys={yDataKeys}
                                />
                            )}

                            {renderMode === RENDER_MODE.chart && data && data.length > 0 && chart_type === "tree_map" &&
                                <TreeMap
                                    {...props}
                                    id={id}
                                    equations={equations}
                                    chartType={chartType}
                                    Alignments={props.columnsAligments}
                                    data={clone_data_to_use}
                                    xDataKeys={xDataKeys}
                                    yDataKeys={yDataKeys}
                                    colorForYData={colorForYData}
                                    paddingOnXaxis={paddingOnXaxis}
                                    widthOfChart={_chart_width}
                                    showBrush={chart_type === CHART_TYPES.brush_bar_chart}
                                    showReferenceLine={showReferenceLine}
                                    valueOfReferenceLine={valueOfReferenceLine}
                                    showStacked={chart_type === CHART_TYPES.stacked_bar_chart}
                                    heightOfChart={heightOfChart}
                                    recentColors={recentColors}
                                    addRecentColor={addRecentColor}
                                    changeColorOfYData={changeColorOfYData}
                                    filters={filters}
                                    getDataFromReportingServer={getDataFromReportingServer}
                                    question={question}
                                    showLegend={true}
                                    isReport={isReport}
                                    showTwoAxis={showTwoAxis}
                                    insights={insights}
                                    isDimension={isDimension}
                                    isDiscovery={isDiscovery}
                                    dimensions={dimensions}
                                    hide_label={hide_label}
                                    hide_y_axis={hide_y_axis}
                                    hide_x_axis={hide_x_axis}
                                    hide_title={hide_title}
                                    hide_x_axis_border={hide_x_axis_border}
                                    hide_y_axis_border={hide_y_axis_border}
                                    hide_grid={hide_grid}
                                    hide_legend={insights ? hide_legend : false}
                                    hide_border={hide_border}
                                    hide_tooltip={hide_tooltip}
                                    hide_x_axis_label={hide_x_axis_label}
                                    hide_y_axis_label={hide_y_axis_label}
                                    isPredictive={isPredictive}
                                    staticYAxisLabel={staticYAxisLabel}
                                    showRightYAxis={showRightYAxis}
                                    chartStyle={chartStyle}
                                    drill_down_type={drill_down_type}
                                    drill_down_report={props.drill_down_report}
                                    tooltipTheme={tooltipTheme}
                                    drill_down_values={props.drill_down_values}
                                    comparisonKeys={props.comparisonKeys}
                                    comparisons={props.comparisons}
                                    yac_totals={props.yac_totals}
                                    _data={test}
                                    reporting_db_info_id={reporting_db_info_id}
                                    is_require_cache={is_require_cache}
                                    is_drill_down_applicable={is_drill_down_applicable}


                                />
                            }



                            {
                                (
                                    renderMode === RENDER_MODE.chart &&
                                    data && data.length > 0 &&
                                    (chart_type === CHART_TYPES.composed_bar_chart)
                                ) &&
                                <ComposedBarChart
                                    id={id}
                                    Alignments={props.columnsAligments}
                                    data={data}
                                    xDataKeys={xDataKeys}
                                    yDataKeys={yDataKeys}
                                    colorForYData={colorForYData}
                                    paddingOnXaxis={paddingOnXaxis}
                                    widthOfChart={_chart_width}
                                    showBrush={chart_type === CHART_TYPES.brush_bar_chart}
                                    showReferenceLine={showReferenceLine}
                                    valueOfReferenceLine={valueOfReferenceLine}
                                    showStacked={chart_type === CHART_TYPES.stacked_bar_chart}
                                    heightOfChart={heightOfChart}
                                    recentColors={recentColors}
                                    addRecentColor={addRecentColor}
                                    changeColorOfYData={changeColorOfYData}
                                    filters={filters}
                                    getDataFromReportingServer={getDataFromReportingServer}
                                    question={question}
                                    showLegend={true}
                                    isReport={isReport}
                                    showTwoAxis={showTwoAxis}
                                    insights={insights}
                                    isDimension={isDimension}
                                    isDiscovery={isDiscovery}
                                    dimensions={dimensions}
                                    hide_label={hide_label}
                                    hide_y_axis={hide_y_axis}
                                    hide_x_axis={hide_x_axis}
                                    hide_title={hide_title}
                                    hide_x_axis_border={hide_x_axis_border}
                                    hide_y_axis_border={hide_y_axis_border}
                                    hide_grid={hide_grid}
                                    hide_legend={insights ? hide_legend : false}
                                    hide_border={hide_border}
                                    hide_tooltip={hide_tooltip}
                                    hide_x_axis_label={hide_x_axis_label}
                                    hide_y_axis_label={hide_y_axis_label}
                                    isPredictive={isPredictive}
                                    staticYAxisLabel={staticYAxisLabel}
                                    showRightYAxis={showRightYAxis}
                                    chartAdditionalInfo={tempChartInfo}
                                    chartStyle={chartStyle}
                                    drill_down_type={drill_down_type}
                                    tooltipTheme={tooltipTheme}
                                    comparisons={props.comparisons}
                                    yac_totals={props.yac_totals}
                                    reporting_db_info_id={reporting_db_info_id}
                                    is_require_cache={is_require_cache}
                                    is_drill_down_applicable={is_drill_down_applicable}



                                />
                            }
                            {
                                ((chart_type === CHART_TYPES.radial_bar_chart)) &&
                                <RadialBarChart
                                    Alignments={props.columnsAligments}
                                    data={dataOfRadialBar ? dataOfRadialBar : data}
                                    xDataKeys={xDataKeys}
                                    yDataKeys={yDataKeys}
                                    colorForPieChart={colorForPieChart}
                                    colorForYData={colorForYData}
                                    widthOfChart={_chart_width}
                                    recentColors={recentColors}
                                    addRecentColor={addRecentColor}
                                    changeColorOfYData={changeColorOfYData}
                                    heightOfChart={heightOfChart}
                                    filters={filters}
                                    showReferenceLine={showReferenceLine}
                                    valueOfReferenceLine={valueOfReferenceLine}
                                    getDataFromReportingServer={getDataFromReportingServer}
                                    question={question}
                                    showLegend={true}
                                    isDonut={chart_type === CHART_TYPES.donut_chart}
                                    isReport={isReport}
                                    isDimension={isDimension}
                                    dimensions={dimensions}
                                    isDiscovery={isDiscovery}
                                    isPredictive={isPredictive}
                                    staticYAxisLabel={staticYAxisLabel}

                                    hide_label={hide_label}
                                    hide_y_axis={hide_y_axis}
                                    hide_x_axis={hide_x_axis}
                                    hide_title={hide_title}
                                    hide_x_axis_border={hide_x_axis_border}
                                    hide_y_axis_border={hide_y_axis_border}
                                    hide_grid={hide_grid}
                                    hide_legend={insights ? hide_legend : false}
                                    hide_border={hide_border}
                                    hide_tooltip={hide_tooltip}
                                    hide_x_axis_label={hide_x_axis_label}
                                    hide_y_axis_label={hide_y_axis_label}
                                    chartStyle={chartStyle}
                                    drill_down_type={drill_down_type}
                                    tooltipTheme={tooltipTheme}
                                    yac_totals={props.yac_totals}
                                    reporting_db_info_id={reporting_db_info_id}
                                    is_require_cache={is_require_cache}
                                    is_drill_down_applicable={is_drill_down_applicable}


                                />
                            }
                            {
                                ((chart_type === CHART_TYPES.scatter_chart)) &&
                                <ScatterChart
                                    {...props}
                                    Alignments={props.columnsAligments}
                                    data={data}
                                    xDataKeys={xDataKeys}
                                    yDataKeys={yDataKeys}
                                    colorForYData={colorForYData}
                                    paddingOnXaxis={paddingOnXaxis}
                                    widthOfChart={_chart_width}
                                    showDash={chart_type === CHART_TYPES.dash_line_chart}
                                    heightOfChart={heightOfChart}
                                    recentColors={recentColors}
                                    addRecentColor={addRecentColor}
                                    changeColorOfYData={changeColorOfYData}
                                    showLegend={true}
                                    showReferenceLine={showReferenceLine}
                                    valueOfReferenceLine={valueOfReferenceLine}
                                    isReport={isReport}

                                    insights={insights}
                                    isDimension={isDimension}
                                    dimensions={dimensions}
                                    isDiscovery={isDiscovery}
                                    isPredictive={isPredictive}
                                    staticYAxisLabel={staticYAxisLabel}


                                    hide_label={hide_label}
                                    hide_y_axis={hide_y_axis}
                                    hide_x_axis={hide_x_axis}
                                    hide_title={hide_title}
                                    hide_x_axis_border={hide_x_axis_border}
                                    hide_y_axis_border={hide_y_axis_border}
                                    hide_grid={hide_grid}
                                    hide_legend={insights ? hide_legend : false}
                                    hide_border={hide_border}
                                    hide_tooltip={hide_tooltip}
                                    hide_x_axis_label={hide_x_axis_label}
                                    hide_y_axis_label={hide_y_axis_label}
                                    showRightYAxis={showRightYAxis}

                                    chartStyle={chartStyle}
                                    drill_down_type={drill_down_type}
                                    drill_down_report={props.drill_down_report}
                                    tooltipTheme={tooltipTheme}
                                    yac_totals={props.yac_totals}
                                    reporting_db_info_id={reporting_db_info_id}
                                    is_require_cache={is_require_cache}
                                    is_drill_down_applicable={is_drill_down_applicable}
                                    hide_x_axis_secondry={hide_x_axis_secondry}



                                />
                            }
                            {/* {
                                ((chart_type === CHART_TYPES.bubble_chart)) &&
                                <BubbleScatter
                                    {...props}
                                    Alignments={props.columnsAligments}
                                    data={data}
                                    xDataKeys={xDataKeys}
                                    yDataKeys={yDataKeys}
                                    colorForYData={colorForYData}
                                    paddingOnXaxis={paddingOnXaxis}
                                    widthOfChart={_chart_width}
                                    showDash={chart_type === CHART_TYPES.dash_line_chart}
                                    heightOfChart={heightOfChart}
                                    recentColors={recentColors}
                                    addRecentColor={addRecentColor}
                                    changeColorOfYData={changeColorOfYData}
                                    showLegend={true}
                                    showReferenceLine={showReferenceLine}
                                    valueOfReferenceLine={valueOfReferenceLine}
                                    isReport={isReport}

                                    insights={insights}
                                    isDimension={isDimension}
                                    dimensions={dimensions}
                                    isDiscovery={isDiscovery}
                                    isPredictive={isPredictive}
                                    staticYAxisLabel={staticYAxisLabel}


                                    hide_label={hide_label}
                                    hide_y_axis={hide_y_axis}
                                    hide_x_axis={hide_x_axis}
                                    hide_title={hide_title}
                                    hide_x_axis_border={hide_x_axis_border}
                                    hide_y_axis_border={hide_y_axis_border}
                                    hide_grid={hide_grid}
                                    hide_legend={insights ? hide_legend : false}
                                    hide_border={hide_border}
                                    hide_tooltip={hide_tooltip}
                                    hide_x_axis_label={hide_x_axis_label}
                                    hide_y_axis_label={hide_y_axis_label}
                                    showRightYAxis={showRightYAxis}

                                    chartStyle={chartStyle}
                                    drill_down_type={drill_down_type}
                                    drill_down_report={props.drill_down_report}
                                    tooltipTheme={tooltipTheme}
                                    yac_totals={props.yac_totals}
                                    reporting_db_info_id={reporting_db_info_id}
                                    is_require_cache={is_require_cache}
                                    is_drill_down_applicable={is_drill_down_applicable}
                                    hide_x_axis_secondry={hide_x_axis_secondry}



                                />
                            } */}

                            {
                                (chart_type === CHART_TYPES.bubble_chart) &&
                                <BubbleChart
                                    {...props}
                                    Alignments={props.columnsAligments}
                                    data={data}
                                    xDataKeys={xDataKeys}
                                    yDataKeys={yDataKeys}
                                    colorForYData={colorForYData}
                                    paddingOnXaxis={paddingOnXaxis}
                                    widthOfChart={_chart_width}
                                    showDash={chart_type === CHART_TYPES.dash_line_chart}
                                    heightOfChart={heightOfChart}
                                    recentColors={recentColors}
                                    addRecentColor={addRecentColor}
                                    changeColorOfYData={changeColorOfYData}
                                    showLegend={true}
                                    showReferenceLine={showReferenceLine}
                                    valueOfReferenceLine={valueOfReferenceLine}
                                    isReport={isReport}

                                    insights={insights}
                                    isDimension={isDimension}
                                    dimensions={dimensions}
                                    isDiscovery={isDiscovery}
                                    isPredictive={isPredictive}
                                    staticYAxisLabel={staticYAxisLabel}


                                    hide_label={hide_label}
                                    hide_y_axis={hide_y_axis}
                                    hide_x_axis={hide_x_axis}
                                    hide_title={hide_title}
                                    hide_x_axis_border={hide_x_axis_border}
                                    hide_y_axis_border={hide_y_axis_border}
                                    hide_grid={hide_grid}
                                    hide_legend={insights ? hide_legend : false}
                                    hide_border={hide_border}
                                    hide_tooltip={hide_tooltip}
                                    hide_x_axis_label={hide_x_axis_label}
                                    hide_y_axis_label={hide_y_axis_label}
                                    showRightYAxis={showRightYAxis}

                                    chartStyle={chartStyle}
                                    drill_down_type={drill_down_type}
                                    drill_down_report={props.drill_down_report}
                                    tooltipTheme={tooltipTheme}
                                    yac_totals={props.yac_totals}
                                    reporting_db_info_id={reporting_db_info_id}
                                    is_require_cache={is_require_cache}
                                    is_drill_down_applicable={is_drill_down_applicable}
                                    hide_x_axis_secondry={hide_x_axis_secondry}



                                />
                            }


                            {
                                false && ((chart_type === CHART_TYPES.line_bar_chart)) &&
                                <LineBarChart
                                    Alignments={props.columnsAligments}
                                    data={data}
                                    id={id}
                                    xDataKeys={xDataKeys}
                                    yDataKeys={yDataKeys}
                                    colorForYData={colorForYData}
                                    paddingOnXaxis={paddingOnXaxis}
                                    widthOfChart={_chart_width}
                                    heightOfChart={heightOfChart}
                                    recentColors={recentColors}
                                    addRecentColor={addRecentColor}
                                    changeColorOfYData={changeColorOfYData}
                                    filters={filters}
                                    getDataFromReportingServer={getDataFromReportingServer}
                                    question={question}
                                    showReferenceLine={showReferenceLine}
                                    valueOfReferenceLine={valueOfReferenceLine}
                                    showLegend={true}
                                    isReport={isReport}
                                    showTwoAxis={showTwoAxis}

                                    insights={insights}
                                    isDimension={isDimension}
                                    dimensions={dimensions}
                                    isDiscovery={isDiscovery}
                                    isPredictive={isPredictive}
                                    staticYAxisLabel={staticYAxisLabel}


                                    hide_label={hide_label}
                                    hide_y_axis={hide_y_axis}
                                    hide_x_axis={hide_x_axis}
                                    hide_title={hide_title}
                                    hide_x_axis_border={hide_x_axis_border}
                                    hide_y_axis_border={hide_y_axis_border}
                                    hide_grid={hide_grid}
                                    hide_legend={insights ? hide_legend : false}
                                    hide_border={hide_border}
                                    hide_tooltip={hide_tooltip}
                                    hide_x_axis_label={hide_x_axis_label}
                                    hide_y_axis_label={hide_y_axis_label}
                                    showRightYAxis={showRightYAxis}
                                    yac_totals={props.yac_totals}

                                    chartStyle={chartStyle}
                                    tooltipTheme={tooltipTheme}
                                    reporting_db_info_id={reporting_db_info_id}
                                    is_require_cache={is_require_cache}
                                    is_drill_down_applicable={is_drill_down_applicable}


                                />
                            }
                            {
                                ((chart_type === CHART_TYPES.pareto_chart)) &&
                                <ParetoChart
                                    {...props}
                                    chartType={chartType === CHART_TYPES.dash_line_chart ? CHART_TYPES.line_chart : chartType}
                                    yac_totals={{ ...props?.yac_totals, agg_pareto: 80 }}
                                    id={id}
                                    equations={equations}
                                    Alignments={props.columnsAligments}
                                    showDash={chart_type === CHART_TYPES.dash_line_chart}
                                    data={clone_data_to_use}
                                    xDataKeys={xDataKeys}
                                    yDataKeys={yDataKeys}
                                    colorForYData={colorForYData}
                                    paddingOnXaxis={paddingOnXaxis}
                                    widthOfChart={_chart_width}
                                    showBrush={chart_type === CHART_TYPES.brush_bar_chart}
                                    showReferenceLine={showReferenceLine}
                                    valueOfReferenceLine={valueOfReferenceLine}
                                    showStacked={chart_type === CHART_TYPES.stacked_bar_chart}
                                    heightOfChart={heightOfChart}
                                    recentColors={recentColors}
                                    addRecentColor={addRecentColor}
                                    changeColorOfYData={changeColorOfYData}
                                    filters={filters}
                                    getDataFromReportingServer={getDataFromReportingServer}
                                    question={question}
                                    showLegend={true}
                                    isReport={isReport}
                                    showTwoAxis={showTwoAxis}
                                    insights={insights}
                                    isDimension={isDimension}
                                    isDiscovery={isDiscovery}
                                    dimensions={dimensions}
                                    hide_label={hide_label}
                                    hide_y_axis={hide_y_axis}
                                    hide_x_axis={hide_x_axis}
                                    hide_x_axis_secondry={hide_x_axis_secondry}
                                    hide_title={hide_title}
                                    hide_x_axis_border={hide_x_axis_border}
                                    hide_y_axis_border={hide_y_axis_border}
                                    hide_grid={hide_grid}
                                    hide_legend={insights ? hide_legend : false}
                                    hide_border={hide_border}
                                    hide_tooltip={hide_tooltip}
                                    hide_x_axis_label={hide_x_axis_label}
                                    hide_y_axis_label={hide_y_axis_label}
                                    isPredictive={isPredictive}
                                    staticYAxisLabel={staticYAxisLabel}
                                    showRightYAxis={showRightYAxis}
                                    chartStyle={chartStyle}
                                    drill_down_type={drill_down_type}
                                    drill_down_report={props.drill_down_report}
                                    od_report={props.od_report}
                                    od_report_id={props.od_report_id}
                                    tooltipTheme={tooltipTheme}
                                    barChartHoverColor={barChartHoverColor}
                                    drill_down_values={props.drill_down_values}
                                    comparisonKeys={props.comparisonKeys}
                                    comparisons={props.comparisons}
                                    // yac_totals={props.yac_totals}
                                    _data={test}
                                    reporting_db_info_id={reporting_db_info_id}
                                    is_require_cache={is_require_cache}
                                    is_drill_down_applicable={is_drill_down_applicable}
                                    show_data_label={props.show_data_label}
                                />
                            }
                            {
                                (chart_type === CHART_TYPES.biaxial_chart) &&
                                <BiaxialChart
                                    Alignments={props.columnsAligments}
                                    data={data}
                                    xDataKeys={xDataKeys}
                                    yDataKeys={yDataKeys}
                                    colorForYData={colorForYData}
                                    paddingOnXaxis={paddingOnXaxis}
                                    widthOfChart={_chart_width}
                                    heightOfChart={heightOfChart}
                                    recentColors={recentColors}
                                    addRecentColor={addRecentColor}
                                    changeColorOfYData={changeColorOfYData}
                                    filters={filters}
                                    getDataFromReportingServer={getDataFromReportingServer}
                                    question={question}
                                    showLegend={true}
                                    isReport={isReport}

                                    isDimension={isDimension}
                                    dimensions={dimensions}
                                    isDiscovery={isDiscovery}
                                    isPredictive={isPredictive}
                                    staticYAxisLabel={staticYAxisLabel}
                                    showReferenceLine={showReferenceLine}
                                    valueOfReferenceLine={valueOfReferenceLine}

                                    hide_label={hide_label}
                                    hide_y_axis={hide_y_axis}
                                    hide_x_axis={hide_x_axis}
                                    hide_title={hide_title}
                                    hide_x_axis_border={hide_x_axis_border}
                                    hide_y_axis_border={hide_y_axis_border}
                                    hide_grid={hide_grid}
                                    hide_legend={insights ? hide_legend : false}
                                    hide_border={hide_border}
                                    hide_tooltip={hide_tooltip}
                                    hide_x_axis_label={hide_x_axis_label}
                                    hide_y_axis_label={hide_y_axis_label}

                                    chartStyle={chartStyle}
                                    tooltipTheme={tooltipTheme}
                                    reporting_db_info_id={reporting_db_info_id}
                                    is_require_cache={is_require_cache}
                                    is_drill_down_applicable={is_drill_down_applicable}



                                />
                            }

                            {
                                (chart_type === CHART_TYPES.radar_chart) &&
                                <RadarChart
                                    Alignments={props.columnsAligments}
                                    data={data}
                                    xDataKeys={xDataKeys}
                                    yDataKeys={yDataKeys}
                                    colorForYData={colorForYData}
                                    paddingOnXaxis={paddingOnXaxis}
                                    widthOfChart={_chart_width}
                                    heightOfChart={heightOfChart}
                                    showLegend={true}

                                    isTop={isTop}
                                    isDimension={isDimension}
                                    dimensions={dimensions}
                                    isDiscovery={isDiscovery}
                                    isPredictive={isPredictive}
                                    staticYAxisLabel={staticYAxisLabel}

                                    hide_label={hide_label}
                                    hide_y_axis={hide_y_axis}
                                    hide_x_axis={hide_x_axis}
                                    hide_title={hide_title}
                                    hide_x_axis_border={hide_x_axis_border}
                                    hide_y_axis_border={hide_y_axis_border}
                                    hide_grid={hide_grid}
                                    hide_legend={insights ? hide_legend : false}
                                    hide_border={hide_border}
                                    hide_x_axis_label={hide_x_axis_label}
                                    hide_y_axis_label={hide_y_axis_label}

                                    chartStyle={chartStyle}
                                    tooltipTheme={tooltipTheme}
                                    reporting_db_info_id={reporting_db_info_id}
                                    is_require_cache={is_require_cache}
                                    is_drill_down_applicable={is_drill_down_applicable}


                                />
                            }
                            {
                                ((chart_type === CHART_TYPES.funnel_chart)) &&
                                <FunnelChart
                                    {...props}
                                    chartType={chartType === CHART_TYPES.dash_line_chart ? CHART_TYPES.line_chart : chartType}
                                    yac_totals={{ ...props?.yac_totals, agg_pareto: 80 }}
                                    id={id}
                                    equations={equations}
                                    Alignments={props.columnsAligments}
                                    showDash={chart_type === CHART_TYPES.dash_line_chart}
                                    data={clone_data_to_use}
                                    xDataKeys={xDataKeys}
                                    yDataKeys={yDataKeys}
                                    colorForYData={colorForYData}
                                    paddingOnXaxis={paddingOnXaxis}
                                    widthOfChart={_chart_width}
                                    showBrush={chart_type === CHART_TYPES.brush_bar_chart}
                                    showReferenceLine={showReferenceLine}
                                    valueOfReferenceLine={valueOfReferenceLine}
                                    showStacked={chart_type === CHART_TYPES.stacked_bar_chart}
                                    heightOfChart={heightOfChart}
                                    recentColors={recentColors}
                                    addRecentColor={addRecentColor}
                                    changeColorOfYData={changeColorOfYData}
                                    filters={filters}
                                    getDataFromReportingServer={getDataFromReportingServer}
                                    question={question}
                                    showLegend={true}
                                    isReport={isReport}
                                    showTwoAxis={showTwoAxis}
                                    insights={insights}
                                    isDimension={isDimension}
                                    isDiscovery={isDiscovery}
                                    dimensions={dimensions}
                                    hide_label={hide_label}
                                    hide_y_axis={hide_y_axis}
                                    hide_x_axis={hide_x_axis}
                                    hide_x_axis_secondry={hide_x_axis_secondry}
                                    hide_title={hide_title}
                                    hide_x_axis_border={hide_x_axis_border}
                                    hide_y_axis_border={hide_y_axis_border}
                                    hide_grid={hide_grid}
                                    hide_legend={insights ? hide_legend : false}
                                    hide_border={hide_border}
                                    hide_tooltip={hide_tooltip}
                                    hide_x_axis_label={hide_x_axis_label}
                                    hide_y_axis_label={hide_y_axis_label}
                                    isPredictive={isPredictive}
                                    staticYAxisLabel={staticYAxisLabel}
                                    showRightYAxis={showRightYAxis}
                                    chartStyle={chartStyle}
                                    drill_down_type={drill_down_type}
                                    drill_down_report={props.drill_down_report}
                                    od_report={props.od_report}
                                    od_report_id={props.od_report_id}
                                    tooltipTheme={tooltipTheme}
                                    barChartHoverColor={barChartHoverColor}
                                    drill_down_values={props.drill_down_values}
                                    comparisonKeys={props.comparisonKeys}
                                    comparisons={props.comparisons}
                                    // yac_totals={props.yac_totals}
                                    _data={test}
                                    reporting_db_info_id={reporting_db_info_id}
                                    is_require_cache={is_require_cache}
                                    is_drill_down_applicable={is_drill_down_applicable}
                                    show_data_label={props.show_data_label}
                                />
                            }


                            {
                                (chart_type === CHART_TYPES.multi_report) &&
                                <MultiReport
                                    values={values}
                                    chartId={chartId}
                                    formula={formula}
                                    widthOfChart={_chart_width}
                                    heightOfChart={heightOfChart}
                                    formulaMeta={formulaMeta}
                                    isDimension={isDimension}
                                    dimensions={dimensions}
                                    reporting_db_info_id={reporting_db_info_id}
                                    is_require_cache={is_require_cache}
                                    is_drill_down_applicable={is_drill_down_applicable}


                                />
                            }




                            {
                                ((chart_type === CHART_TYPES.pie_chart || chart_type === CHART_TYPES.donut_chart || chart_type === CHART_TYPES.gauge_chart)) &&
                                <PieChart
                                    {...props}
                                    Alignments={props.columnsAligments}
                                    data={data}
                                    id={id}
                                    xDataKeys={xDataKeys}
                                    yDataKeys={yDataKeys}
                                    colorForPieChart={colorForYData}
                                    colorForYData={colorForYData} // set same color for all segmet in gauge chart
                                    // colorForYData={chart_type === CHART_TYPES.gauge_chart ? [colorForYData?.[0]] : colorForYData} // set same color for all segmet in gauge chart
                                    insights={insights}
                                    widthOfChart={_chart_width}
                                    recentColors={recentColors}
                                    addRecentColor={addRecentColor}
                                    changeColorOfYData={changeColorOfYData}
                                    heightOfChart={heightOfChart}
                                    filters={filters}
                                    getDataFromReportingServer={getDataFromReportingServer}
                                    question={question}
                                    showLegend={true}

                                    isDonut={chart_type === CHART_TYPES.donut_chart}

                                    isGauge={chart_type === CHART_TYPES.gauge_chart}
                                    chart_type={chart_type}
                                    isPie={chart_type == CHART_TYPES.pie_chart}
                                    show_data_label={props.show_data_label}
                                    // show_data_label={chart_type === CHART_TYPES.gauge_chart ? true : props.show_data_label}
                                    show_percentage_in_data_labels={chart_type === CHART_TYPES.gauge_chart ? true : props.show_percentage_in_data_labels}
                                    show_prcentage_in_legends={chart_type === CHART_TYPES.gauge_chart ? true : props.show_prcentage_in_legends}

                                    isReport={isReport}
                                    isDimension={isDimension}
                                    dimensions={dimensions}
                                    isDiscovery={isDiscovery}
                                    isPredictive={isPredictive}
                                    staticYAxisLabel={staticYAxisLabel}

                                    hide_label={hide_label}
                                    hide_y_axis={hide_y_axis}
                                    hide_x_axis={hide_x_axis}
                                    hide_title={hide_title}
                                    hide_x_axis_border={hide_x_axis_border}
                                    hide_y_axis_border={hide_y_axis_border}
                                    hide_grid={hide_grid}
                                    hide_legend={insights ? hide_legend : false}
                                    hide_border={hide_border}
                                    hide_tooltip={hide_tooltip}
                                    hide_x_axis_label={hide_x_axis_label}
                                    hide_y_axis_label={hide_y_axis_label}

                                    show_label_in_legend={show_label_in_legend}
                                    show_legend_as_percent={show_legend_as_percent}

                                    chartStyle={chartStyle}
                                    tooltipTheme={tooltipTheme}
                                    yac_totals={props.yac_totals}
                                    columnMeta={props.columnMeta}
                                    reporting_db_info_id={reporting_db_info_id}
                                    is_require_cache={is_require_cache}
                                    is_drill_down_applicable={is_drill_down_applicable}


                                />
                            }


                            {/* {
                                ((chart_type === CHART_TYPES.predictive_chart || chart_type === CHART_TYPES.dash_line_chart)) &&
                                <LineChart
                                    {...props}
                                    Alignments={props.columnsAligments}
                                    data={data}
                                    xDataKeys={xDataKeys}
                                    yDataKeys={yDataKeys}
                                    colorForYData={colorForYData}
                                    paddingOnXaxis={paddingOnXaxis}
                                    widthOfChart={_chart_width}
                                    showDash={chart_type === CHART_TYPES.dash_line_chart}
                                    heightOfChart={heightOfChart}
                                    recentColors={recentColors}
                                    addRecentColor={addRecentColor}
                                    changeColorOfYData={changeColorOfYData}
                                    showLegend={true}
                                    showReferenceLine={showReferenceLine}
                                    valueOfReferenceLine={valueOfReferenceLine}
                                    isReport={isReport}

                                    insights={insights}
                                    isDimension={isDimension}
                                    dimensions={dimensions}
                                    isDiscovery={isDiscovery}


                                    hide_label={hide_label}
                                    hide_y_axis={hide_y_axis}
                                    hide_x_axis={hide_x_axis}
                                    hide_title={hide_title}
                                    hide_x_axis_border={hide_x_axis_border}
                                    hide_y_axis_border={hide_y_axis_border}
                                    hide_grid={hide_grid}
                                    hide_legend={insights ? hide_legend : false}
                                    hide_border={hide_border}
                                    hide_tooltip={hide_tooltip}
                                    hide_x_axis_label={hide_x_axis_label}
                                    hide_y_axis_label={hide_y_axis_label}

                                    chartStyle={chartStyle}
                                    tooltipTheme={tooltipTheme}
                                    reporting_db_info_id={reporting_db_info_id}
                                    is_require_cache={is_require_cache}
                                    is_drill_down_applicable={is_drill_down_applicable}




                                />
                            } */}



                        </div>
                    }
                </React.Fragment>

            }
        </div>
    );
};


export default ChartWrapper;