
import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';
import CloseSvg from '../../svg/close';
import { Radio } from '../../hoc/checkbox';
import { Button } from '../../hoc/button';




const Wrapper = styled.div`

    background: #fff;
    width: 50rem;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    box-sizing: border-box;
    border-radius: 12px;
    padding-bottom: 0px;
    overflow: hidden;

    .header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        margin-bottom: 1rem;

        h1{
            font-size: 1.5rem;
            font-weight: 500;
            margin: 0px;
            font-weight: bold;
            color: #2173F7;

        }

        .close{
            cursor: pointer;
            width: 1rem;
            height: 1rem;
            svg{
                fill: #333;
                
            }
        }
    }

    .schema_lists{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-height: 17rem;
        overflow-x: auto;
        
        &::-webkit-scrollbar {
            /* background-color:red; */
            width:10px;
            border-radius: 10px;
    }

        &::-webkit-scrollbar-track {
            background-color:#EFEDF9;
            border-radius: 20px;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#C1C1EA;
            border-radius:20px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }

    }

    .schema{
        border: 1px solid #6b6b6b;
        border-radius: 30px;
        padding: 10px;
        display: flex;
        margin-right: 15px;
        margin-bottom: 15px;
        /* width: 200px; */
        align-items: center;
        cursor: pointer;


        .name{
            max-width: 250px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 1rem;
            color: #6b6b6b;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;

;
        }
    }


    .schema_info{
        width: 50%;
        margin-top: 1rem;
        .title{
            font-size: 1.1rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #333232;
            margin: 0px;
        }
        .value{
            font-size: 1rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #6b6b6b;
            margin: 0px;
            margin-top: 4px;
        }
    }


    .buttons{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2rem 0px;
        background-color: #e2e2e2;
        padding: 1rem 2rem;
        background-color: #E8E8F9;
        margin: 0px -2rem;
        margin-top: 2rem;
    }

    .close_btn{
        margin-top: -32px;
        cursor: pointer;
    }


    .search_wrapper{

        margin-bottom: 1.3rem;
        width: 100%;

        input{
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #222;
            font-size: 1rem;
            width: 62%;
            height: 2rem;
            border: 0px;
            font-size: 1.1rem;
            border-bottom: 2px solid #e2e2e2;
            padding: 4px;

            &:focus{
                outline: none;
                border-bottom: 2px solid #2173F7 !important;
            }

            &::placeholder{
                font-size: 1.1rem;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: rgb(153, 153, 153);
            }
        }

    }

`;

const TableChooser = (props) => {

    const { close, popupKey, option, data } = props;

    const table_list = data?.table_list;

    const { save_fun } = data;

    const [local_table_list, set_local_table_list] = useState(undefined)

    const [selected_table, set_selected_table] = useState(undefined)

    const __onchange = (value) => {

        const clone_selected_table = selected_table ? [...selected_table] : [];

        if (clone_selected_table?.indexOf(value) == -1) {
            clone_selected_table.push(value)
        } else {
            clone_selected_table.splice(clone_selected_table.indexOf(value), 1)
        }

        set_selected_table(clone_selected_table)
    }

    useEffect(() => {
        set_local_table_list(table_list)
    }, [table_list]);


    const filter_table_list = (value) => {

        const filterd_list = (table_list || []).filter((a) => {
            if (a.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                return true;
            } else {
                return false;
            }
        })

        set_local_table_list(filterd_list)
    }


    return (
        <Wrapper>
            <div className='header'>
                <h1>List Of Tables</h1>
                <div title="Close" className='close_btn' onClick={() => {
                    close(option, popupKey)
                }}>
                    <CloseSvg size="1.1rem" />
                </div>
            </div>
            <div className='search_wrapper'>
                <input type="text" placeholder='Type here to quick search' onChange={(e) => {
                    filter_table_list(e.target.value)
                }} />
            </div>

            <div className='schema_lists'>

                {local_table_list || []?.length > 0 ? local_table_list.map((table) => {
                    return (
                        <div title={table} className='schema' onClick={() => {
                            // set_active_schema(d)
                            __onchange(table)

                        }}>
                            <Radio
                                w="25px"
                                h="25px"
                                mR="5px"
                                top="5px"
                                right="8px"
                                checked={selected_table?.indexOf(table) > -1}
                                bColor='#6b6b6b'
                            // radioBg={"green"}
                            />
                            <span className='name'>{table}</span>
                        </div>
                    )
                }) : <p>Not Found</p>}
            </div>

            <div className='buttons'>
                <Button
                    width={"7rem"}
                    bgColor={"#fff"}
                    style={{
                        color: '#222'
                    }}
                    onClick={() => {
                        close(option, popupKey)
                    }}

                >
                    Back
                </Button>
                <Button
                    width={"7rem"}
                    bgColor="#2173F7"
                    onClick={() => {
                        close(option, popupKey)
                        if (save_fun) {
                            save_fun(selected_table)
                        }
                    }}>
                    Next
                </Button>
            </div>

        </Wrapper>
    )
}



const mapStateToProps = (state) => ({
    db_infos: state.connectionReducer.db_infos,
})

export default connect(mapStateToProps, {})(TableChooser);
