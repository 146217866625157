import React, { useEffect, useState, useRef } from 'react'
import { Wrapper, Close } from './discovery.popup.styled';
import ReportWrapper from '../report-wrapper';
import { withRouter } from 'react-router-dom';
import CloseSvg from '../svg/close';
import { Gap } from '../gap'

const DiscoveryPopup = (props) => {


    const { data, close, popupKey, option } = props;
    const { question, graph, index, hideMenuConfig, title } = data;

    const [localQuestion, setQuestion] = useState(undefined)
    const [wrapperWidth, setWrapperWidth] = useState(undefined);
    const [wrapperHeight, setWrapperHeight] = useState(undefined);


    const wrapperRef = useRef(null)

    useEffect(() => {
        if (wrapperRef && wrapperRef.current) {
            setWrapperWidth(wrapperRef.current.offsetWidth);
            setWrapperHeight(wrapperRef.current.offsetHeight);
        }
    }, [])



    useEffect(() => {
        setQuestion(question)
    }, [question])


    return (
        <Wrapper
            ref={wrapperRef}
        >
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
            }}>
                <h2>{title}</h2>
                <Close onClick={() => {
                    close(option, popupKey)
                    hideMenuConfig()
                }}>
                    <CloseSvg size="1rem" width="1rem" />
                </Close>
            </div>

            {localQuestion && wrapperWidth && wrapperHeight && (
                <ReportWrapper
                    question={localQuestion}
                    insightWidth={wrapperWidth}
                    insightHeight={wrapperHeight - 150}
                    id={'disc_' + index}
                    hide_legend={true}
                    hideReportHeader={true}
                    forceRenderOneTime={graph}
                    isPolusIqHide={true}
                    history={props.history}
                    // {...props}

                />
            )}

        </Wrapper>
    )
}

export default withRouter(DiscoveryPopup)
