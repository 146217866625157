import React from "react";

const Pdf = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2026 2048"
    >
      <path
        transform="translate(239,4)"
        d="m0 0h865l245 1 17 1 10 3 12 7 14 12 17 16 38 38 8 7 7 7 8 7 32 32 2 1v2l4 2 23 23 8 7 12 12 8 7 38 38 8 7 17 16 18 18 8 7 26 26 8 7 17 16 18 18 2 1v2l3 1v2l4 2 26 26 8 7 10 9 11 11 8 7 12 12 7 8 7 7 9 11 6 9 3 8v330h48l20 2 16 4 15 6 17 9 15 11 13 13 12 17 10 19 5 15 3 18v444l-2 19-5 16-11 23-10 14-11 12-11 10-14 9-17 8-19 5-23 3-16 1-34 1-1 488-1 46-3 10-6 10-9 9-9 6-5 5h-1604l-7-9-10-8-8-8-3-9-1-5-1-22-1-515-7 1h-49l-14-2-16-5-12-5-16-9-12-9-12-11-10-11-7-11-11-21-6-15-3-11 1-5-1-2v-447l3-12 8-22 9-17 10-14 9-11 11-10 15-10 17-9 18-6 10-2 10-1 55-1 1-750 3-9 8-11 12-12 10-6 3-1zm68 98-32 1-1 23v669l1331 1h132l7-1 1-235v-34l-1-1-381-1-16-1-12-3-10-7-8-9-6-12-2-8-1-10-1-29-1-342-27-1zm1097 68v257l28 1h130l108-1-2-4-6-7-8-7-3-1v-2l-8-7-50-50-8-7-15-14-12-11-29-29-8-7-10-10-8-7-14-14-8-7-37-37-8-7-9-9h-2v-2l-8-7-7-7zm-1053 1302-76 1-1 63v414h1470v-477l-20-1z"
        fill="#F3F3F4"
      />
      <path
        transform="translate(239,4)"
        d="m0 0h865l245 1 17 1 10 3 12 7 14 12 17 16 38 38 8 7 7 7 8 7 32 32 2 1v2l4 2 23 23 8 7 12 12 8 7 38 38 8 7 17 16 18 18 8 7 26 26 8 7 17 16 18 18 2 1v2l3 1v2l4 2 26 26 8 7 10 9 11 11 8 7 12 12 7 8 7 7 9 11 6 9 3 8v330h48l20 2 16 4 15 6 17 9 15 11 13 13 12 17 10 19 5 15 3 18v444l-2 19-5 16-11 23-10 14-11 12-11 10-14 9-17 8-19 5-23 3-16 1-34 1-1 488-1 46-3 10-6 10-9 9-9 6-5 5h-1604l-7-9-10-8-8-8-3-9-1-5-1-22-1-515-7 1h-49l-14-2-16-5-12-5-16-9-12-9-12-11-10-11-7-11-11-21-6-15-3-11 1-5-1-2v-447l3-12 8-22 9-17 10-14 9-11 11-10 15-10 17-9 18-6 10-2 10-1 55-1 1-750 3-9 8-11 12-12 10-6 3-1zm68 98-32 1-1 23v669l1331 1h132l7-1 1-235v-34l-1-1-381-1-16-1-12-3-10-7-8-9-6-12-2-8-1-10-1-29-1-342-27-1zm1097 68v257l28 1h130l108-1-2-4-6-7-8-7-3-1v-2l-8-7-50-50-8-7-15-14-12-11-29-29-8-7-10-10-8-7-14-14-8-7-37-37-8-7-9-9h-2v-2l-8-7-7-7zm198 698-76 1h-1402l-13 4-11 7-10 9-7 9-6 13-4 17v405l3 21 3 10 6 11 11 12 9 7 9 4 10 2h1484l231 1h44l15-1 13-4 8-5 10-9 8-10 5-11 3-13 1-24v-400l-1-11-5-12-8-11-8-8-16-10-13-3-19-1zm-1251 604-76 1-1 63v414h1470v-477l-20-1z"
        fill="#042F5A"
      />
      <path
        transform="translate(903,996)"
        d="m0 0h101l28 2 18 3 20 6 15 6 21 11 17 12 10 9 13 13 12 17 9 16 8 20 4 15 2 20v12l-2 19-4 17-6 15-8 15-9 13-8 10-8 9-8 7-16 12-19 11-19 8-22 7-18 4-17 2h-110l-23-1-2-6v-303z"
        fill="#042F5A"
      />
      <path
        transform="translate(583,996)"
        d="m0 0h120l27 2 16 3 19 7 14 7 14 10 12 11 10 13 10 19 4 14 2 12v20l-4 18-8 16-8 12-9 10-11 11-15 10-16 8-14 5-20 4-26 2h-52l-1 5v70l-1 21-7 1h-67l-5-2-1-2v-287l1-19z"
        fill="#042F5A"
      />
      <path
        transform="translate(1248,996)"
        d="m0 0h227v27l-2 41-2 1-157 2-1 2-1 25v29l1 6 3 1h129l1 5-1 41-1 17-2 4-7 1-121 1-2 1-1 106-8 1h-65l-7-2-1-20v-288z"
        fill="#042F5A"
      />
      <path
        transform="translate(965,1065)"
        d="m0 0h39l21 3 15 4 16 7 11 7 12 11 9 13 6 13 3 9 2 10v20l-3 15-7 16-9 12-8 9-14 10-15 7-13 4-15 3-22 2h-26l-4-2-1-3v-120l1-44z"
        fill="#F2F2F4"
      />
      <path
        transform="translate(1934,894)"
        d="m0 0 4 4 6 12 1 3 1 11v400l-1 24-4 16-5 9-7 9-7 7-9 6-10 4-5 1-15 1h-44l-231-1h-1484l-13-3-9-5-10-9-7-8v-2l3 1 1 4 4 2 5 5v2l4 1 6 3 13 3h14l16-1 74-1h347l266 3h119l319-1 57-1h37l56 1h28l40-1h40l29 1h42l53-2 81-1h131l25 1 13-2 7-3 11-8 5-8 3-12 2-17v-234l1-31 1-71v-96l-4-13z"
        fill="#C4C9CF"
      />
      <path
        transform="translate(1938,900)"
        d="m0 0 3 3 4 10 1 11v400l-1 24-4 16-5 9-7 9-7 7-9 6-10 4-5 1-15 1h-44l-231-1h-1484l-13-3-9-5-1-2 15 6h1544l102-1h61l44 1h29l12-2 8-4 11-9 9-13 3-9 1-6 1-120 1-63v-131l-1-127z"
        fill="#7F92A8"
      />
      <path
        transform="translate(651,1065)"
        d="m0 0h48l18 2 10 3 10 6 7 8 4 7 2 15-3 10-6 9-7 7-11 5-12 3-8 1-21 1h-29l-6-2-1-2v-43l1-27 1-2z"
        fill="#F0F0F2"
      />
      <path
        transform="translate(1602,868)"
        d="m0 0h274l19 1 13 3 6 4-2 1-6-3-8-1-16-1h-55l-7 1-13-1h-524l-627-1v-1l200-1h670z"
        fill="#95A3B4"
      />
      <path
        transform="translate(78,909)"
        d="m0 0h1l-1 10-1 13v130l-1 73 1 64 2 62v78l1 16 4 13 1 5-3-3-5-11-3-16-1-10v-405l4-17z"
        fill="#C3C8CF"
      />
      <path
        transform="translate(1812,869)"
        d="m0 0h55l34 1 8 3 5 3-2 1-6-3-8-1-16-1h-55l-7 1-13-1h-524v-1l289-1z"
        fill="#C5CAD0"
      />
      <path
        transform="translate(75,919)"
        d="m0 0h1l-1 180v96l1 87v30l1 32 2 17-2-2-3-16-1-10v-405z"
        fill="#7F92A8"
      />
      <path
        transform="translate(1230,1093)"
        d="m0 0h1v192l1 20 6 1h73v-106h1l1 104-1 3-9 2-23 1-37-1-10-1-3-2-1-6v-126z"
        fill="#9DAAB9"
      />
      <path
        transform="translate(964,1066)"
        d="m0 0h1l1 37-1 68-1 41-1 23h-1v-120l1-44z"
        fill="#A2AEBC"
      />
      <path
        transform="translate(1437,994)"
        d="m0 0 39 1 1 28-1 13-2 3v-16l1-27-3 1h-241l4-2z"
        fill="#9AA8B7"
      />
      <path
        transform="translate(647,1210)"
        d="m0 0h1l1 12v82l-1 3-10 2-32 1-29-1-10-2 1-2 4 1h74v-91z"
        fill="#A5B0BE"
      />
      <path
        transform="translate(1824,869)"
        d="m0 0h43l34 1 8 3 5 3-2 1-6-3-8-1-16-1h-55l-7 1-7-1-1-2z"
        fill="#C5CAD0"
      />
      <path
        transform="translate(647,1210)"
        d="m0 0h1l1 12v54l-2 1-1-62z"
        fill="#A3AFBC"
      />
      <path
        transform="translate(648,1276)"
        d="m0 0h1v28l-1 3h-9v-1h7v-21l1-8z"
        fill="#ACB6C2"
      />
      <path
        transform="translate(1882,870)"
        d="m0 0h19l8 3 5 3-2 1-6-3-8-1-16-1z"
        fill="#B5BDC6"
      />
      <path
        transform="translate(85,1373)"
        d="m0 0 3 1 1 4 4 2 8 9h-3l-10-10-3-4z"
        fill="#A0ACBB"
      />
    </svg>
  );
};

export default Pdf;
