import * as actions from '../action-types/dashboard';
import { constants } from '../utils/constants';

const initialState = {
    type: actions.DASHBOARD_INIT,
    dashboardData: undefined,
    dashboardDataCache: []
};


const dashboardReducer = (state, action) => {
	if (!state || typeof action.type === 'undefined') {
		return initialState;
    }

    // if (action && action.data && typeof action.data === 'object') {
    //     action.data.key = new Date();
    // }

    switch (action.type) {
        case actions.SET_DASHBOARD_STATE:

            const data = action.data;

            let tempDashboardDataCache = state.dashboardDataCache ?  [...state.dashboardDataCache] : [];
            const id = data.reportId;
            // const limit = constants.LIMIT_OF_DATA_IN_REDUX_STATE;

            const tempObject = {
                [id]: action.data
            }

            tempDashboardDataCache.push(tempObject);

            return {
                ...state,
                type: action.type,
                dashboardData: action.data,
                dashboardDataCache: [...tempDashboardDataCache]
            };

        // case actions.CHANGE_ACTIVE_DATA_SUCCESS:
        //     return {
        //         ...state,
        //         type: action.type,
        //         dataFromServer: action.data
        //     };


        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
			return {
                type: state.type,
                dashboardData: state.dashboardData,
                dashboardDataCache: state.dashboardDataCache
            };
        }
};

export default dashboardReducer;