import React from 'react';
import { connect } from 'react-redux';
import { raiseError } from '../actions/error';
import { windowWidthChange, windowHeightChange } from '../actions/window';
import { dispatchUserInfo, dispatchToken } from '../actions/account';
import { dispatchPermissionInfo } from '../actions/permission';
import { getStringHintsFromServer } from '../actions/report';
import { saveRecentColors } from '../actions/chart.helper';
import { getClientsInfo } from '../actions/client.info';
import Wrapper from './helper/wrapper';
import Container from './container';
import Home from './home';
import { debounceWrapper, is_logged_in, remove_logged_in_from_session, _whatToDoIfTokenNotFound } from '../utils';
import { getFromSession, whichUserFromSession, saveInSession } from '../utils/session.helper';
import { constants } from '../utils/constants';
import Login from './login';
import { withRouter } from 'react-router-dom';
import ShareableInsights from './open-route/open-share/shareable.insights';
import ShareableReport from './open-route/open-share/shareable.report';
import { get_db_info } from '../actions/connection'
import { get_sort_priorities, get_week_config } from '../utils/v1.1/sort_priorities';
import { get_permissions_for_user } from '../actions/permission'
import { get_report_menus } from '../actions/menu.group';
import 'react-loading-skeleton/dist/skeleton.css';

function generateSessionId() {
    // Create a random alphanumeric string
    return 'session_' + Math.random().toString(36).substr(2, 9);
}



class EntryPoint extends Wrapper {
    constructor(props) {
        super(props);

        try {
            const token = getFromSession(constants.SESSION_KEYS.TOKEN);
            // const permissions = getFromSession(constants.SESSION_KEYS.PERMISSIONS);
            const user = whichUserFromSession();

            // let's save sessio id 

             saveInSession(constants.SESSION_KEYS.SESSION_ID, generateSessionId())


            if (token) {
                this.props.dispatchToken(token);
            }

            // if (permissions) {
            //     this.props.dispatchPermissionInfo(JSON.parse(permissions));
            // }

            if (user) {
                this.props.dispatchUserInfo(user);
            }

            this.state = {
                token,
                user,
                // permissions
            }
        }
        catch (e) {
            console.log('error in entry point ', e);
            // if error, probably something to do with encryption or bad settings
            localStorage.clear();
            window.location.reload();
        }
    }


    async componentDidMount() {
        const client_id = getFromSession(constants.SESSION_KEYS.CLIENT_ID);
        if (client_id) {
            this.props.get_db_info()
            this.props.get_permissions_for_user()
            this.props.get_report_menus()
            
        }

        if (window && window.addEventListener) {
            const _this = this;
            window.addEventListener('resize', function () {
                _this.debounceResize();
            });
        }

        this.debounceResize();
        /* 
            * As EntryPoint extends the Wrapper, Wrapper have some its own function and one of them is isLoggedIn(). 
            * isLoggedIn() returns the user if it is present in the session and if is is true then it runs actions which change the value of user variable in the accountReducer
        */
        const token = await is_logged_in();
        const _permissions = getFromSession(constants.SESSION_KEYS.PERMISSIONS);

        if (token) {
            let _user = whichUserFromSession();

            if (_user) {
                let user = _user ? _user : undefined;

                if (user && user.user_id && !this.props.user) {
                    this.props.dispatchUserInfo(user);
                }
            }


            if (!this.props.token) {
                this.props.dispatchToken(token);
            }


            this.props.getStringHintsFromServer();

            if (process.env.REACT_APP_DEV_LOGIN_KEY) {
                this.props.getClientsInfo();
            }
        }
        else {
            let _user = whichUserFromSession();

            if (_user) {
                let user = _user ? _user : undefined;

                if (user && user.user_id && !this.props.user) {
                    this.props.dispatchUserInfo(user);
                }
            }

            if (process.env.REACT_APP_DEV_LOGIN_KEY !== constants.LOGIN_DEV_KEY) {
                _whatToDoIfTokenNotFound();
            }
        }

        // if (!this.props.permissions && _permissions) {
        //     this.props.dispatchPermissionInfo(JSON.parse(_permissions));
        // }


        const recentColors = getFromSession(constants.SESSION_KEYS.RECENT_COLORS);

        this.props.saveRecentColors(recentColors ? JSON.parse(recentColors) : []);
        await get_sort_priorities()
        // await get_week_config()

    }

    componentWillUnmount() {
        if (window.removeEventListener) {
            window.removeEventListener('resize', this.debounceResize());
        };
    }

    /* 
        * debounceResize
            * This function runs windowResized after 500ms.
            * Debouncing is necessary for this as we don't want to invoke a function on every change.
            * E.G. :- When user changes the window size [height, width]. This function will invoke after 500ms of final change.
    */

    debounceResize = () => {
        const debounceFunction = debounceWrapper(this.windowResized, 500);
        debounceFunction();
    };

    /* 
        * windowResized
            * This function raise two Actions which are responsible for changing the value of Height and Width Variable in windowReducer
    */

    windowResized = () => {
        if (window.innerHeight && window.innerWidth) {
            this.props.windowWidthChange(window.innerWidth);
            this.props.windowHeightChange(window.innerHeight);
        }
    };

    reload = () => {
        this.componentDidMount();
    }


    render() {


        const { width, height, hints, recentColors, permissions, location } = this.props;
        const { user, token } = this.state;
        const { pathname } = location;

        const is_client_view = pathname && pathname.indexOf('/client-view') > -1;
        const is_client_view_report = pathname && pathname.indexOf('/client-view-report') > -1;

        const loggedIn = (!token || !user) ? this.props.token && this.props.user && this.props.user.id ? true : false : token && user && user.id ? true : false;
        const showComponent = (width && height && hints && recentColors) ? true : false;

        return (
            <div>
                {/* The container contains the code for showing messages, sticky etc */}
                <Container />

                {
                    loggedIn ?
                        showComponent &&
                        <Home
                            user={user || this.props.user}
                            hints={hints}
                            width={width}
                            height={height}
                            permissions={permissions}
                        />
                        :

                        (
                            is_client_view_report ? (
                                <ShareableReport width={width} height={height} share_mode={true} location={this.props.history.location} />
                            ) :
                                is_client_view ? <ShareableInsights share_mode={true} />

                                    : (process.env.REACT_APP_APP_MODE === 'DPAAS' ?
                                        <Login
                                            reload={this.reload}
                                        />
                                        :
                                        <h2>Unauthorized. Please login to continue</h2>
                                    )


                        )
                }
            </div>
        )
    }
}


const mapStateToProps = state => {
    const { user, token } = state.accountReducer;
    const { permissions } = state.permissionReducer;
    const { width, height } = state.windowReducer;
    const { hints } = state.reportReducer;
    const { recentColors } = state.chartHelperReducer;

    return { width, height, hints, recentColors, user, token, permissions };
};


export default withRouter(connect(mapStateToProps, {get_report_menus, get_permissions_for_user, get_db_info, raiseError, windowWidthChange, windowHeightChange, dispatchUserInfo, getStringHintsFromServer, saveRecentColors, getClientsInfo, dispatchToken, dispatchPermissionInfo })(EntryPoint));