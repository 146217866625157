import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import SearchIcon from '../svg/search';
import SendIcon from "../svg/icons-v2/sendMessageIcon";
import MicrophoneIcon from "../svg/icons-v2/microphoneIcon";
import { NormalButton } from "./normal.button";
import Popover from "./popover";
import Add from "../svg/add";
import Datasource from "../svg/menu/datasource";
import GoogleDriveIcon from "../svg/icons-v2/googleDriveIcon";
import UploadFromComputerIcon from "../svg/icons-v2/uploadFromComputerIcon";


const SearchContainer = styled.div`
${props => props.borderType === 'border-bottom' ? 'border-bottom: 1px solid #bcbaba;' : 'border: 1px solid #bcbaba;'}
${props => props.borderType === 'border-bottom' ? 'border-radius: 0 ;' : 'border-radius: 20px;'}
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
  padding: 0.4rem;
  /* padding: 0.2rem 0.3rem 0.2rem 0.4rem; */
  background: #fff;
  width: 100%;
  /* height: 100%; */
  max-width: ${props => props.width || '30rem'};
  &:focus-within {
    /* border-color: #337DFF;  */
  };
  /* svg{
    margin: 0 0.8rem;
  }; */

  .btn_group{
    display: flex;
    justify-content: space-between;
    align-items: end;
    /* position: absolute; */
    /* right: 24px;
    bottom: 40px; */
    width: 100%;
    padding: 0.2rem 0.1rem;
  }
  .microphone_btn{
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    margin-left: 0.3rem;
  };
  .Add_attachment_btn{
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.8rem 0 0;
    cursor: pointer;
    &:hover{
      background: #f2f2f2;
      border-radius: 50%;
    }
  }
  .send_btn_bg{
    background: ${props => props.isSearchText ? 'linear-gradient(to right, #3E9EFF, #737DFF)' : '#eaeaea'};
    border-radius: 50%;
    height: 1.9rem;
    width: 1.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 4px 6px 8px;
    box-sizing: border-box;
    cursor: ${props => props.isSearchText ? 'pointer' : 'not-allowed'};
  };
`;


const SearchInput = styled.textarea`
  border: none;
  outline: none;
  flex: 1;
  padding: 0.2rem 1rem 0.2rem 0.8rem;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.3rem;
  resize: none;
  overflow: auto;
  /* border-radius: 30px; */
  min-height: 60px;
  max-height: 80px; 
  /* overflow: hidden; */
  &::placeholder {
    color: #999999;
    opacity: 1;
    font-size: 1rem;
  }

  &::-webkit-scrollbar {
        background-color: #3F3F3F;
        width:4px;
    }

    &::-webkit-scrollbar-track {
        background-color: #F3F3F3;
        border: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dbdbdb;
        border-radius: 10px;
        height: 4px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color:  #3F3F3F;
        height: 4px;
    }
`;

const SearchTextAreaBar = ({
  onChange = () => { },
  onSearch = () => { },
  onKeyDown = () => { },
  value,
  type,
  placeHolder,
  label,
  borderType = 'border-bottom',
  width,
  padding,
  showSearchIcon = true,
  showSendBtn,
  showSpeakBtn,
  handleMicClick,
  showAddAttachment,
  handleAddAttachmentClick,
}) => {

  const textareaRef = useRef(null);
  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea && textarea.scrollHeight <= 200) {
      textarea.style.height = 'auto';
      // Set the height based on the scroll height of the content
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [value]);

  return (
    <SearchContainer borderType={borderType} width={width} showSendBtn={showSendBtn} isSearchText={!!value?.trim()} >
      {label && <label>{label}</label>}
      {showSearchIcon && <SearchIcon
        size='1.2rem'
        height='1.2rem'
        color='#a6a6a6'
        onClick={onSearch}
      />}
      <SearchInput
        ref={textareaRef}
        rows={1}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        type={type}
        placeholder={placeHolder}
        padding={padding}
      />
      <div className="btn_group">
        <div className="left_group">
          {showSpeakBtn && <span onClick={handleMicClick} className="microphone_btn"><MicrophoneIcon height='1.1rem' size='1.1rem' color="#000" /></span>}
          {showAddAttachment && <span onClick={() => { }} >
            <Popover borderRadius={'8px'} boxShadow={'rgba(149, 157, 165, 0.3) 0px 8px 24px'} trigger="click" width={'auto'} xPosition={'0%'} yPosition={'-130%'} content={
              <div>
                <NormalButton height={'2.6rem'} borderRadius={'6px'} label={'Recent File'} onClick={() => { handleAddAttachmentClick('Recent File') }} justify="left" color={'#000000'} bgColor={'#fff'} hoverBg={"#f2f2f2"} width={'100%'} isIconMarRt={true} icon={<Datasource size={'1rem'} height={'0.8rem'} color={'#000'} />} />
                <NormalButton height={'2.6rem'} borderRadius={'6px'} label={'Google Drive'} onClick={() => { handleAddAttachmentClick('Google Drive') }} justify="left" color={'#000000'} bgColor={'#fff'} hoverBg={"#f2f2f2"} width={'100%'} isIconMarRt={true} icon={<GoogleDriveIcon size={'1rem'} height={'0.8rem'} color={'#000'} />} />
                <NormalButton height={'2.6rem'} borderRadius={'6px'} label={'Upload from Computer'} onClick={() => { handleAddAttachmentClick('Upload from Computer') }} justify="left" color={'#000000'} bgColor={'#fff'} hoverBg={"#f2f2f2"} width={'100%'} isIconMarRt={true} icon={<UploadFromComputerIcon size={'1rem'} height={'0.8rem'} color={'#000'} />} />
              </div>
            }>
              <span className="Add_attachment_btn">
                <Add height='0.9rem' size='0.9re' color="#000" />
              </span>
            </Popover>
          </span>}
        </div>
        {showSendBtn && <div onClick={()=>!!value?.trim() && onSearch()} className="send_btn_bg">
          <SendIcon height='1.6rem' size='1.6rem' color="#fff" />
        </div>}
      </div>

    </SearchContainer>
  );
};

export default SearchTextAreaBar;