import * as DataType from '../shared-with-fe/data.types';
import { convert_date_to_dd_mm_yyyy_v1, formatValueByDataType } from './index';
import { getFormulaColumns } from './table.helper';

const calculate_width = () => {
};


const factor_for_calculating_width = (value, is_insight) => {
    const is_number = Number.isInteger(value);

    if (is_number) {
        return is_insight ? 12 : 13.4;
    }

    return is_insight ? 6.8 : 8.4;
};


const numberWithCommas = (value) => {
    let temp_value = value;
    if (typeof temp_value === 'number') {
        return new Number(temp_value).toLocaleString("US-EN");
    }
    return value
}

// f2
export const getColumnWidths = (dataArray, maxWidthAllowed, totalWidth, columnsAligments, title, xDataKeys = [], yDataKeys = [], is_insight, tbl_formulas) => {
    const formulaColumns = getFormulaColumns(tbl_formulas);


    const all_widths = {};

    const tempHeaders = [...xDataKeys, ...yDataKeys, ...formulaColumns]

    const headers = {};

    tempHeaders.forEach(k => headers[k] = k);

    const text_widths = getMaxWidthForAllColumns([headers, ...dataArray], columnsAligments, is_insight);

    const items_with_valid_width = {};
    const items_with_higher_width = {};

    let total_width_valid_items = 0;
    let total_width_higher_items = 0;

    const text_width_keys = text_widths && Object.keys(text_widths);

    text_width_keys.forEach((width, index) => {
        // if (width > (maxWidthAllowed * 2)) {
        //     items_with_higher_width[index] = maxWidthAllowed * 2;
        //     total_width_higher_items = total_width_higher_items + (maxWidthAllowed * 2);
        // }
        if (text_widths[width] > maxWidthAllowed) {
            items_with_higher_width[width] = text_widths[width];
            total_width_higher_items = total_width_higher_items + text_widths[width];
        }
        else {
            items_with_valid_width[width] = text_widths[width];
            total_width_valid_items = total_width_valid_items + text_widths[width];
        }
    });

    const remaining_width = totalWidth - total_width_valid_items;

    if (remaining_width > total_width_higher_items) {
        // it means that the space left on screen after accommodating columns that has width within the max width allowed
        // do nothing, coz we already have the max width for each column
    }
    else {
        // we don't have the sufficient width, so we will only be accomodate the maximum for each column

        if (remaining_width > (Object.keys(items_with_higher_width).length * 250)) {
            // there is some space left if assigned max
            const higher_widths = {};

            Object.keys(items_with_higher_width).forEach((key, index) => {
                higher_widths[items_with_higher_width[key]] = index;
            });

            const widths_sordted = Object.keys(higher_widths);

            widths_sordted.sort((a, b) => a - b);

            const sorted_indexes = widths_sordted.map(width => higher_widths[width]);

            let remaining_items = widths_sordted.map(width => parseFloat(width));
            let remaining_item_width = remaining_width;
            let final_widths = [];

            const assign_width = () => {

                const width_factors = assign_width_as_per_minimum_width(remaining_items, remaining_items[0], maxWidthAllowed, remaining_item_width);

                if (width_factors.all_done) {
                    final_widths.push(...width_factors.items);
                    remaining_items = [];
                }
                else {
                    final_widths.push(width_factors.items[0]);
                    remaining_items.shift();
                    remaining_item_width = remaining_item_width - width_factors.items[0];

                    if (remaining_items.length > 0) {
                        assign_width();
                    }
                }
            };


            assign_width();

            sorted_indexes.map((sorted_index, index) => {
                items_with_higher_width[sorted_index] = final_widths[index]
            });
        }
        else {
            Object.keys(items_with_higher_width).forEach(key => items_with_higher_width[key] = maxWidthAllowed);
        }
    }


    let final_calculated_width = 0;
    Object.keys(items_with_higher_width).forEach(key => {
        if (items_with_higher_width[key] > maxWidthAllowed * 1) items_with_higher_width[key] = maxWidthAllowed * 1;
    });


    const final_widths = {};
    for (let width_index = 0; width_index < text_width_keys.length; width_index++) {
        final_widths[text_width_keys[width_index]] = (items_with_valid_width[text_width_keys[width_index]] || items_with_higher_width[text_width_keys[width_index]])
    }



    final_widths && Object.keys(final_widths).forEach(width => {
        // if(is_insight){
        //     final_calculated_width+= final_widths[width];
        // }
        if (items_with_higher_width[width] > maxWidthAllowed * 1) items_with_higher_width[width] = maxWidthAllowed * 1;
    });

    const ratio = totalWidth / final_calculated_width;

    // console.log('width called: ', totalWidth, final_calculated_width) ;


    // if(is_insight){
    //     final_widths && Object.keys(final_widths).forEach((width, index) => {
    //         final_widths[width] = (ratio * final_widths[width])
    //         // if (items_with_higher_width[key] > maxWidthAllowed * 2) items_with_higher_width[key] = maxWidthAllowed * 2;
    //     });
    // }
    return final_widths;
};


/**
 * 
 * @param {*} items object { '1': 2898, '0': 9128 }
 * @param {*} minimum_item_width 
 * @param {*} remaining_width 
 * 
 * assigns width as per the miminum width required
 */
const assign_width_as_per_minimum_width = (items_left_to_assign_width, minimum_item_s_width, max_width_allowed, remaining_width) => {
    // lets check if total width allows the width to all

    if (remaining_width >= minimum_item_s_width * (items_left_to_assign_width.length)) {
        const difference_from_minimum_width = minimum_item_s_width - max_width_allowed;
        const items = items_left_to_assign_width.map(items_left_to_assign_width => items_left_to_assign_width + difference_from_minimum_width);

        items[0] = minimum_item_s_width;

        return {
            items,
            all_done: false
        };
    }
    else {
        const ratio_of_total_and_remaining_width = (remaining_width / (items_left_to_assign_width.reduce((a, b) => a + b, 0)));

        return {
            all_done: true,
            items: items_left_to_assign_width.map(items_left_to_assign_width => items_left_to_assign_width * ratio_of_total_and_remaining_width),
        }
    }
};


// f1
export const getMaxWidthForAllColumns = (__dataArray, columnsAligments, is_insight) => {
    const widths = {};

    // get factors for each column first
    const factors = [];

    const dataArray = __dataArray?.length > 0 ? JSON.parse(JSON.stringify(__dataArray)) : []

    Object.keys(dataArray[0]).forEach(column_key => {
        const value = dataArray[0][column_key];
        const __value__ = value ? value.split('_nfx_')[0] : value;
        factors.push(factor_for_calculating_width(__value__, is_insight));
    });



    // factors received, lets use factors to find max width

    let which_index_to_pick_for_nfx = 0;

    const find_which_index_to_pick_for_nfx_case = (__row__) => {
        const nfxs_found = {};

        Object.keys(__row__).forEach(r => {
            if (r.indexOf('_nfx_') > -1) {
                nfxs_found[r.split('_nfx_')[1]] = 1;
            }
        });

        if (Object.keys(nfxs_found).length > 1) which_index_to_pick_for_nfx = 1;
    };


    find_which_index_to_pick_for_nfx_case(dataArray[0]);


    Object.keys(dataArray[0]).forEach((column_key, col_index) => {
        let data_type = columnsAligments && columnsAligments[column_key] && columnsAligments[column_key].type && columnsAligments[column_key].type;


        if (column_key !== 'sortKey') {
            let max_length = 0;

            dataArray.forEach((row, row_index) => {

                const column_info = formatValueByDataType(row[column_key], data_type, 'table');

                let final_column_info = typeof column_info === 'number' ? column_info.toString() : column_info;

                if (row_index === 0) {

                    const base_value = row[column_key] + '';
                    const the_base_string = (base_value.indexOf('_nfx') > -1) ? base_value.split('_nfx_')[which_index_to_pick_for_nfx] : base_value;

                    // find highest word

                    const words = the_base_string.split(' ');

                    words.sort((a, b) => {
                        return b.length - a.length;
                    });

                    max_length = words[0].length * 1.4;
                }

                else if (typeof final_column_info !== 'undefined' && final_column_info !== null) {
                    max_length = Math.max(max_length, (final_column_info.length * ((row_index === 0 && row[column_key] && row[column_key].indexOf(' ') > -1) ? 0.7 : 1)))
                }
            });

            widths[column_key] = (max_length * factors[col_index] || 0) + 50;
        }
    });


    return widths;
};


const sample_object = [
    {
        speciality: 'Cardiothoracic And Vascular Surgery',
        revenue: '282,239.4'
    },
    {
        speciality: 'Clinical Haematology And BMT',
        revenue: '211,215.3'
    },
    {
        speciality: 'Ear Nose Throat Head And Neck Surgery',
        revenue: '324,159.4'
    },
];


// getMaxWidthForAllColumns(sample_object);
// getColumnWidths(sample_object, 250, 1002);