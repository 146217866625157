import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { generate_unique_key, getPermissionById } from '../../utils';
import { Button } from '../hoc/button';
import { CheckBox } from '../hoc/checkbox';
import TextBoxV1 from '../hoc/text.box.v1';
import IncrementalColumnSelector from '../incremental_column_selections'
import { connect } from 'react-redux';



const frequencyTypes = [
    { name: 'Seconds', value: 'seconds' },
    { name: 'Minutes', value: 'minutes' },
    { name: 'Hour', value: 'hourly' },
    { name: 'Day', value: 'daily' },
    { name: 'Week', value: 'weekly' },
    { name: 'Month', value: 'monthly' },
    { name: 'Year', value: 'yearly' },
    { name: 'Quarter', value: 'quarterly' },
    // { name: 'Quarterly', value: 'first_week_day_of_quarter' },
    // { name: 'Quarterly', value: 'first_week_day_of_month' },
    // { name: 'One Time', value: 'one_time' },
]

const MainWrapper = styled.div`
    width: 30rem;
    height: 30rem;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 35rem;
    overflow-y: auto;


    .__input__{
        height: 2rem;
        box-sizing: border-box;
        font-size: .9rem;
        width: 100%;
    }

    .line_item{
        width: 100%;
        margin-bottom: 1rem;

        span{
            margin-right: 10px;
        }
    }

    .lable{
        font-size: 1rem;
        color: #000 ;
        font-weight: 500;
        display: inline-block;
        width: 140px;
    }
`;





const CacheSettingPopup = (props) => {

    const { close, popupKey, option, data } = props;

    const { cache_settings, save_cache_setting, date_columns } = data;

    const [local_cache_settings, set_local_cache_settings] = useState()


    useEffect(() => {
        const temp_cache_settings = cache_settings || {}
        const frequency = cache_settings?.frequency;

        if (frequency?.indexOf("__nfx__") > -1 && frequency?.split("__nfx__")?.length > 1) {

            temp_cache_settings.frequency_type = typeof frequency.split("__nfx__")[0] !== "undefined" ? frequency.split("__nfx__")[0] : ''
            temp_cache_settings.frequency_value = typeof frequency.split("__nfx__")[1] !== 'undefined' ? frequency.split("__nfx__")[1] : ''

        }
        // generate_unique_key

        if (!temp_cache_settings?.cache_table_name) {
            temp_cache_settings.cache_table_name = generate_unique_key("tbl_") + "_cached";
        }

        set_local_cache_settings(temp_cache_settings)

    }, [cache_settings])


    const onChange = (key, value) => {

        const clone_cahce_setting = local_cache_settings ? JSON.parse(JSON.stringify(local_cache_settings)) : {};
        clone_cahce_setting[key] = value;
        set_local_cache_settings(clone_cahce_setting);
    }



    const local_save = () => {

        const temp_local_cache_settings = local_cache_settings ? { ...local_cache_settings } : {};
        temp_local_cache_settings.frequency = (local_cache_settings?.['frequency_type'] || "") + "__nfx__" + (local_cache_settings?.['frequency_value'] || "")

        save_cache_setting(temp_local_cache_settings)

    }


    const show_tbl_input_be = props.menuPermissions && getPermissionById("show_tbl_input", props.menuPermissions);
    const show_tbl_input = false //props?.user?.email && props?.user?.email?.indexOf("newfangled.io") > -1 || show_tbl_input_be;


    return (
        <MainWrapper>

            <div className='line_item' style={{
                alignItems: 'center',
                display: 'flex',
                marginTop: '1rem'
            }} onClick={() => {
                const is_require_cache_value = local_cache_settings?.["is_require_cache"] ? false : true;
                onChange("is_require_cache", is_require_cache_value);
            }}>
                <CheckBox checked={local_cache_settings?.["is_require_cache"]} />
                <span style={{ cursor: 'pointer' }} className='lable'>Enable Cache</span>
            </div>

            {show_tbl_input && (
                <div className='line_item'>

                    <TextBoxV1
                        label="Cache Table Name"
                        value={local_cache_settings?.["cache_table_name"]}
                        onChange={(e) => {
                            onChange("cache_table_name", e.target.value);
                        }}
                    />
                </div>


            )}

            <div className='line_item'>
                <span className='lable'>When To Refresh</span>
                <input
                    type="time"
                    className='__input__'
                    style={{ width: '25%' }}
                    value={local_cache_settings?.['job_start_time']}
                    onChange={(e) => {
                        onChange("job_start_time", e.target.value);
                    }}
                />

            </div>

            <div className="line_item">
                {/* <div w="100%" style={{ marginBottom: '.1rem' }}>
                    <label className='lable'>When To Refresh</label>
                </div> */}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row', marginTop: '.3rem' }}>
                    <span className='lable' >Frequency</span>
                    <div style={{ width: '25%', marginRight: '10px' }}>
                        <input
                            className='__input__'
                            value={local_cache_settings?.frequency_value ? local_cache_settings.frequency_value : undefined}
                            // style={{ width: '100%', fontSize: '1rem', padding: '.8rem', boxSizing: 'border-box', color: '#000' }}
                            type="text" placeholder="Value"
                            onChange={(e) => {
                                onChange('frequency_value', e.target.value)

                            }}
                        />
                    </div>

                    <div style={{ width: '25%', }}>
                        <select
                            className='__input__'

                            value={local_cache_settings?.frequency_type ? local_cache_settings.frequency_type : undefined}
                            style={{ width: '100%', borderRadius: '2px', backgroundColor: '#fff' }}
                            onChange={(e) => {
                                onChange('frequency_type', e.target.value)
                            }}>
                            <option value="">--Select Type--</option>
                            {frequencyTypes?.map((item) => {
                                return (
                                    <option value={item.value}>{item.name}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>
            </div>


            <div className='line_item'>
                <span className='lable'>Refresh Mode</span>
                <select
                    style={{ width: '25%', borderRadius: '2px', backgroundColor: '#fff' }}
                    className='__input__'

                    value={local_cache_settings?.['refresh_mode']}
                    onChange={(e) => {
                        onChange("refresh_mode", e.target.value);
                    }}>
                    <option value="">Append</option>
                    <option value="complete_refresh">Complete Refresh</option>
                    <option value="incremental_refresh">Incremental Refresh</option>

                </select>
            </div>

            {local_cache_settings?.['refresh_mode'] === "incremental_refresh" && (
                <div>

                    <IncrementalColumnSelector
                        options_date_columns={date_columns || []}
                        incremental_date_column={local_cache_settings?.["incremental_date_columns"]}
                        on_save={(__data) => {
                            console.log(__data)
                            onChange('incremental_date_columns', __data)

                        }}
                    />

                </div>
            )}

            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: 'auto',
                marginLeft: 'auto'

            }}>
                <Button
                    primary={true}
                    onClick={() => {
                        close(option, popupKey)
                        local_save()
                    }}
                >
                    Save
                </Button>
            </div>

        </MainWrapper>
    )
}


const mapStateToProps = (state) => ({

    user: state.accountReducer?.user,
    menuPermissions: state?.permissionReducer?.permissions

})

export default connect(mapStateToProps, {})(CacheSettingPopup);
