
import styled from 'styled-components';



export const TableMainDiv = styled.div`
    width: 100%;
    margin-bottom: 40px;

    &::-webkit-scrollbar {
            background-color: #3F3F3F;
            width:4px;
        }

        &::-webkit-scrollbar-track {
            background-color: #F3F3F3;
            border: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #fff;
            border-radius: 10px;
            height: 4px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color:  #3F3F3F;
            height: 4px;
        }


    .column_list{
        font-size: 1rem;
        color: #969696;
        line-height: 3.286rem;
        font-weight: 500;
        padding: 0px 10px;
    }


    .sticky_top{
        position: sticky;
        z-index: 1;
        margin-top: 25px;
        background: #fff;
        top: calc(3.517rem - 25px);
        background: linear-gradient(to right, #3E9EFF, #737DFF);
    }
`;




export const TableWrapper = styled.table`

    width: 100%;
    border-collapse: collapse;

    thead {
        position: relative;
        background: #e0edff;
        color: #414141;
        td{
            height: 2.7rem;
            font-weight: 600;
        }
    }
    tbody tr{
        transition: all 0.2s;
        position:  relative;
        border-bottom: 1px solid #ccc; 
    }
    tbody tr:hover{
        border: 2px solid #3E9EFF;
   }
   tbody tr:hover .action_on_hover{
       visibility: visible;

   }

    .action_on_hover{
        position: absolute;
        right: 0px;
        height: 2.6rem;
        padding: 0px 10px;
        background: #fff;
        top: 0px;
        display: flex;
        z-index:1;
        align-items: center;
        visibility: hidden;
        justify-content: space-between;
        flex-direction: row;

        .cache_the_value{
            white-space: nowrap;
            margin-right: 1rem;
        }
   }

    td{
        padding: 0px .5rem;
        height: 3rem;
        box-sizing: border-box;
        font-size: 1rem;
        border: none;
        position: relative;
    }

`;






export const ActionBtn = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* border: 1px solid #ccc; */
    padding: .4rem .7rem;
    border-radius: ${props => props.bdrRadius || '30px'};
    background: ${props => props.bgColor || '#e0edff'};
    color: ${props => props.color || undefined};
    white-space: nowrap;
    
    svg{
        margin-right: 6px;
    }

    i{
        margin-right: 6px;
    }
    &:hover{
        background: ${props => props.hoverbgColor || undefined};
        svg{
            fill:${props => props.hoverColor ? props.hoverColor : '#24a8d6'};
        }
        i{
            color: ${props => props.hoverColor ? props.hoverColor : '#24a8d6'};
        }
        span{
            color: ${props => props.hoverColor ? props.hoverColor : '#24a8d6'};
        }
    }

`



export const Filterd = styled.div`
     max-width: 7.429rem;
     height: 1.571rem;
     /* border: solid 1px rgb(156, 174, 181); */
     background-color: #e9e9e9;
     display: flex;
     align-items: center;
     justify-content: flex-start;
     padding: 0px 7px;
     margin-right: 10px;
     box-sizing: border-box;
     border-radius: 6px;

     .cross_mark{
        width: 20px;
        cursor: pointer;
        height: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

     }

     .filter_name{
        font-size: 0.714rem;
        font-weight: normal;
        text-align: center;
        color: rgb(0, 22, 31);
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0px 5px;
        /* text-transform: capitalize; */
        white-space: nowrap;
     }

`;


export const FilterdItemRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* max-width: 500px; */
    overflow-x: auto;
    padding: 5px 10px;
    overflow-y: hidden;
    box-sizing: border-box;
    max-width: 70rem;

    /* &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    } */

        &::-webkit-scrollbar {
            background-color: #3F3F3F;
            width:4px;
        }

        &::-webkit-scrollbar-track {
            background-color: #F3F3F3;
            border: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #dbdbdb;
            border-radius: 10px;
            height: 3px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color:  #3F3F3F;
            height: 3px;
        }


`;



export const TdDiv = styled.div`
    font-size: 0.9em;
    border: 0px;
    border-collapse: collapse;
    box-sizing: border-box;
    padding: 2px 25px;
    width: ${props => props.w || '100%'};
    flex:${props => props.w ? undefined : 1};
    text-align: ${props => props.align || 'left'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
`;


export const RowDiv = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: ${props => props.border ? undefined : '1px solid #eaeaea'};
    box-sizing: border-box;
    justify-content: ${props => props.justify || undefined};

    &:hover{
        .show_on_hover{
            opacity: 1;
        }
        .remove_column{
            opacity: 1;
        }
    }

    cursor: pointer;

    &:hover{
        background-color: ${props => props.hover === false ? 'transparent' : '#ccdde3'};

    }

    .table_name{
        height: 3.214rem;
        color: #fff;
        /* background: #24a8d6; */
        font-size: 1.286rem;
        text-transform: capitalize;
        display: flex;
        align-items: center;
    
    }

    .tbl_context{
        font-size: .9rem;
        margin-left: 1rem;
        font-weight: 400;
        display: inline-block;
        max-width: 20rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .column{
        font-size: 1rem;
        color: #1a1a1a;
        line-height: 2.857rem;
        font-weight: normal;
    }

`;



