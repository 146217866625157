import React, { useEffect, useState } from 'react'
import { PageInnerWrapper } from '../hoc/styled.hoc';
import SharedTable from '../shared-table';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get_file_logs, delete_file_log } from '../../actions/etl'
import TextBoxV1 from '../hoc/text.box.v1';
import { showPopup } from '../../utils';
import enums from '../../utils/enums';
import TableViewPopup from '../editor.shared/table.view';


const FileLogs = (props) => {

    const { file_logs } = props;

    const [localFileLogs, setLocalFileLogs] = useState(undefined)
    const [file_date, set_file_date] = useState('2024-10-15')

    useEffect(() => {
        // set_file_date
        const _page_info = {
            title: 'File Logs',

        }
        props.dispatchHeaderInformation(_page_info)
        props.get_file_logs(file_date)

    }, [])

    useEffect(() => {
        props.get_file_logs(file_date)
    }, [file_date])

    useEffect(() => {
        setLocalFileLogs(file_logs)
    }, [file_logs])


    const enable_delete_btn = props?.user?.email && props?.user?.email?.indexOf("newfangled.io") > -1;



    const openTablePopup = (item) => {

        showPopup(undefined, undefined, enums.popupType.element, TableViewPopup, {
            tableName: item?.db_table_name,
            connectionId: 'srpr_db_id__nf__db__cached__',
            db_type: 'mysql',
        })

    }


    return (
        <PageInnerWrapper padding={'10px'} style={{ marginTop: '1rem' }}>
            <div style={{ marginBottom: '1rem' }}>
                <div style={{ width: '250px' }}>
                    <TextBoxV1 label="Choose File Process Date" value={file_date || '-'} type='date' onChange={(e) => {
                        set_file_date(e.target.value)
                    }} />
                </div>
            </div>
            <SharedTable
                height={"calc(100vh - 200px)"}
                duplicateAction={() => { }}
                tableData={(localFileLogs && localFileLogs.length > 0) ? localFileLogs : undefined}
                tableHead={['job id', 'job occurance id', "File Date", "File Name", "Db Table Name", "Status", "Total Records", "Success Records", "Failed Records", "Error Description", "Actions"]}
                sortColumns={{
                    "Db Table Name": {
                        type: "string",
                        dataKey: 'db_table_name'
                    },
                    "Status": {
                        type: "string",
                        dataKey: 'status'
                    },
                }}
                columnsToShow={['job_id', 'job_occurence_id', "file_date", "full_file_name", "db_table_name", "status", "total_records", "success_records", "failed_records", "error_description"]}
                date_columns={['file_date', 'file_process_date']}
                column_body_styles={{
                    "name": {
                        fontWeight: 'bold',
                        fontSize: '.8rem'
                    }
                }}
                maximumWidthOfColumn={{
                    "full_file_name": '3rem',
                    "error_description": "6rem"
                }}
                deleteAction={(item) => {
                    if (enable_delete_btn) {
                        props.delete_file_log(item?.id)
                        setTimeout(() => {
                            props.get_file_logs(file_date)
                        }, 100)
                    } else {
                        alert("You don't have permission to delete")
                    }
                }}
                viewAction={(item) => {
                    openTablePopup(item)
                }}
            />
            {typeof file_logs == 'undefined' || file_logs.length == 0 ? <p style={{ textAlign: "center" }}>No Record Found</p> : null}
        </PageInnerWrapper>
    )
}


const mapStateToProps = (state) => ({
    file_logs: state.EtlReducer.file_logs
})

export default withRouter(connect(mapStateToProps, { get_file_logs, delete_file_log })(FileLogs))