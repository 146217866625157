import React, { useState, useEffect, useRef } from 'react';
import { TabWrapper, Tab, Indicator } from './tabs-slider.styled'
import { connect } from 'react-redux';
import { get_Nf_Grammar, save_Nf_Grammar } from '../../actions/nf_grammar'
import { grammarType } from '../../utils/grammar'




const TabSlider = (props) => {

    const { acceptValue, save_Nf_Grammar } = props;
    const [activeReport, setActiveReport] = useState('noun');
    const [isHidden, setIsHidden] = useState("")

    const [listArray, setListArray] = useState(undefined)


    const [indicatorStyle, setIndicatorStyle] = useState({
        left: 0,
        width: 0
    });

    useEffect(() => {
        if (grammarType) {
            setListArray(grammarType)
        }
    }, [grammarType])


    const tabWrapperRef = useRef(null);
    const [elRefs, setElRefs] = useState([]);
    const arrLength = grammarType ? grammarType.length : 10;

    useEffect(() => {
        // add or remove refs
        setElRefs(elRefs => (
            Array(arrLength).fill().map((_, i) => elRefs[i] || React.createRef())
        ));

    }, [arrLength]);

    useEffect(() => {
        if (elRefs[0]) {
            setIndicatorProperty(elRefs[0])
        }
    }, [elRefs[0]])

    useEffect(() => {
        setTimeout(() => {
            setIsHidden('noun');
        }, 200);
    }, [])


    const onTabClick = (refName, tabName) => {

        if (tabName) {
            setIsHidden(tabName)
            setIndicatorProperty(refName)

            setTimeout(() => {
                setActiveReport(tabName)
            }, 200);

        }
    }

    const setIndicatorProperty = (refName) => {
        if (refName) {
            let wrapper = tabWrapperRef.current.getBoundingClientRect();
            let wrapperWidth = wrapper.width;
            let currentEl = refName.current.getBoundingClientRect();
            let width = currentEl.width;
            let left = currentEl.x - wrapper.x;

            setIndicatorStyle({
                width,
                left
            })
        }
    }


    useEffect(() => {
        props.acceptValue(isHidden);
    }, [isHidden])


    return (
        <div>
            <TabWrapper ref={tabWrapperRef}>
                {listArray && listArray.length > 0 && listArray.map((item, i) => {
                    return (
                        <Tab className="ripple"
                            ref={elRefs[i]}
                            active={activeReport === item.value}
                            onClick={() => onTabClick(elRefs[i], `${item.value}`)}
                        >
                            {item.title}
                        </Tab>
                    )
                })}
                <Indicator {...indicatorStyle} color="#122e39" />
            </TabWrapper>
        </div>
    )
}




const mapStateToProps = (state) => ({

})


export default connect(mapStateToProps, {})(TabSlider);