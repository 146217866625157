import styled from 'styled-components';


export const FormulaWrapper = styled.div`

    width: 100%;

    .db_function_wrapper{

        ul{
            margin: 0px;
            padding: 0px;
            list-style: none;

            li{
                
            }
        }

    }



     .text_box{
            width: 100%;
            min-height: 2.4rem;
            padding: .5rem 2px;
            box-sizing: border-box;
            border: 0px;
            border: 1px solid #0000003b;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
            flex-wrap: wrap;
            max-height: 10rem;
            /* overflow-y: auto; */
            position: relative;
            border-radius: 6px;


           .label_pre {
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: .9rem;
                color: #646464;
                font-weight: 300;
                text-transform: capitalize;
                color: rgba(0,0,0,0.6);
                font-weight: 400;
                font-size: .9rem;
                line-height: 1.4375em;
                transition: all 0.2s;
                position: absolute;
                left: 10px;
                /* background-color: #f0f5fc; */
                z-index: 0;
    }

        .label_post {
            color: rgba(0,0,0,0.6);
            font-weight: 400;
            font-size: .9rem;
            -webkit-transform: translate(-11%,-100%) scale(0.8);
            -ms-transform: translate(-11%,-100%) scale(0.8);
            transform: translate(-11%,-100%) scale(0.8);
            background-color: #f0f5fc;
            padding: 0px 7px;
            z-index: 3;
            top: 7px;
        }
        
            &::-webkit-scrollbar {
                background-color:red;
                width:5px
            }
            &::-webkit-scrollbar-track {
                background-color:#b1cad5;
            }
            &::-webkit-scrollbar-thumb {
                background-color:#76919d;
                border-radius:0px;
                height: 5px;
            }


            &:hover{
                /* background: #eef0f2;
                input{
                    background: #eef0f2;
                } */
            }

            &:focus{
                outline: none;
                border-bottom: 2px solid #308bc8;
            }
            input{
                font-size: .9rem;
                border: 0px;
                width: ${props => props.isEmails ? "10px" : '100%'};
                background: transparent;
                position: relative;
                color: rgb(55,74,102);
                z-index: 1;
                padding: 0 0.5rem;
                &:focus{
                    outline: none;
                }
        }
        .email_batch{
               background: #d1e3ff;
                padding: 3px 10px;
                border-radius: 19px;
                font-size: .8rem;
                cursor: pointer;
                color: rgb(1, 130, 254);
                margin: 0.1rem 0.2rem;
                font-weight: 800;
        }
        .remove_btn{
            display: inline-block;
            margin-left: .3rem;
            width: 1rem;
            height: 1rem;
            text-align: center;
            line-height: 1rem;
            border-radius: 50%;

            /* &:hover{
                background: #dadada;
            } */
        }

    }

    .close_btn{
        position: absolute;
        right: 1rem
    }
    
    .tbl_name{
        font-size: 1rem;
    }

    .dropdown{
        background: #fff;
        padding: 4px 4px;
        box-sizing: border-box;
        display: inline-block;
        font-size: .9rem;
        box-shadow: 3px 4px 4px 3px #ccc;
        cursor: pointer;
        border-radius: 4px;
        font-weight: 800;
        color: #222;
        position: absolute;
        left: -8px;
        top: -30px;
    }

    .__edit_container__{

        li{
            padding: 0px;;
             border: 0px;

        }

       select,
        input {
            border: 0px;
            height: 2rem;
            background-color: #fff;
            border: 1px solid #0008ff;
            border-radius: 4px;
            font-size: 1rem;
            padding: 0 0.5rem;
            box-sizing: border-box;
            &:focus-visible{
                outline: 1px solid #0008ff;
            }
        }
        select{
            /* width: 100px; */
        }
    }
    
    
    .formula_switcher{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .l_bl{
            display: inline-block;
            margin-left:.5rem;
            font-size: .9rem;
            color: #374a66;
        }
    }
    .formula_scroll{
        width: 100%;
        max-height: 100vh;
        overflow: auto;
        &::-webkit-scrollbar {
        background-color:white;
        width:5px
        }
        &::-webkit-scrollbar-track {
            background-color:#ffffff;
        }
        &::-webkit-scrollbar-thumb {
            background-color:#d9d9d9;
            border-radius:10px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color:white;
            height: 5px;
        }
    }


`;

