import { simpleDispatch_get, simpleDispatch_put, simpleDispatch_delete } from './helper';
import { constants } from '../utils/constants';
import * as actionTypes from '../action-types/nf_grammar'



export const get_Nf_Grammar = (id) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.NF_GRAMMAR.POINT + constants.END_POINTS.NF_GRAMMAR.GET_NF_GRAMMAR_LIST
    await simpleDispatch_get(dispatch, url, actionTypes.GET_NF_GRAMMAR_RESULTS, true);
};


 export const save_Nf_Grammar = (data) => async dispatch => {

      const post = {
        nf_grammar: data
      };

     const url = constants.END_POINTS.API + constants.END_POINTS.NF_GRAMMAR.POINT + constants.END_POINTS.NF_GRAMMAR.SAVE_NF_GRAMMAR;
     await simpleDispatch_put(dispatch, url, post, actionTypes.SAVE_NF_GRAMMARS, false);
 };


 export const delete_Nf_Grammar = (id) => async dispatch => {
   const url = constants.END_POINTS.API + constants.END_POINTS.NF_GRAMMAR.POINT + constants.END_POINTS.NF_GRAMMAR.DELETE_NF_GRAMMAR + '?id=' + id;
   await simpleDispatch_delete(dispatch, url, actionTypes.DELETE_NF_GRAMMARS, false);
};



