export const getTimeDifferenceFormatted = (date1Str, date2Str) => {
    if (!date1Str || !date2Str) return undefined;
    const time1 = date1Str.getTime();
    const time2 = date2Str.getTime();

    let differenceInMilliseconds = time2 - time1;

    const differenceInMinutes = Math.floor(differenceInMilliseconds / 60000);
    differenceInMilliseconds %= 60000;

    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
    differenceInMilliseconds %= 1000;

    // return `${differenceInMinutes} minutes, ${differenceInSeconds} seconds, ${differenceInMilliseconds} milliseconds`;
    return (differenceInMinutes > 0 ? differenceInMinutes + " " + "min ," : '') + (differenceInSeconds > 0 ? differenceInSeconds + " " + 'sec ,' : '') + " " + (differenceInMilliseconds > 0 ? differenceInMilliseconds + " " + 'ms' : '')
};