import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import TextBoxV1 from '../hoc/textBox';
import SelectOptionV1 from '../select-option';
import HintsAdd from '../hints.add';
import { Button } from '../hoc/button';
import { useEffect } from 'react';


const Wrapper = styled.div`
    width: 100%;
    height: 20rem;
    max-height: 50rem;
    padding: .5rem;
    box-sizing: border-box;
    .__row__{
        width: 100%;;
        margin-bottom: 1rem;
    }

    
    display: flex;
    flex-direction: column;

`;

const OtherInformation = (props) => {



    const { close, popupKey, option, data } = props;
    const { popupData, _saveFun, } = data;



    const [localData, setLocalData] = useState({

    })

    const [hints, setHints] = useState()



    useEffect(() => {

        if (popupData) {
            setLocalData(popupData)
            setHints(popupData.hints || [])
        } else {
            setLocalData()
            setHints()

        }
    }, [popupData])


    const on_change = (key, value) => {

        const cloned_data = localData ? { ...localData } : {};
        cloned_data[key] = value;
        setLocalData(cloned_data)


    }


    const onSave = () => {

        const __data = localData ? { ...localData } : {};
        __data.hints = hints;
        _saveFun(__data)
    }


    return (
        <Wrapper>
            {/* <div className='__row__'>
                <TextBoxV1
                    value={localData?.["rule_tbl"] || ''}
                    label={"Please Provide Rule Table Name"}
                    onChange={(e) => {
                        on_change("rule_tbl", e.target.value)
                    }}
                />
            </div> */}

            <div className='__row__'>
                <SelectOptionV1
                    activeValue={localData?.["data_type"] || ''}
                    label={"Please Choose Data Type"}
                    selections={[
                        "currency",
                        "number",
                        "string",
                        "date",
                        "percent"
                    ]}
                    width="100%"
                    onChangeFunc={(value) => {
                        on_change("data_type", value)
                    }}
                    // background={'#f0f5fc'}
                    none_value={false}
                />
            </div>


            <div className='__row__'>
                <HintsAdd
                    hints={hints}
                    setHints={setHints}
                />
            </div>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: 'auto',
                marginLeft: 'auto'

            }}>
                <Button
                    primary={true}
                    // disabled={disabled}
                    onClick={() => {
                        onSave()
                        close(option, popupKey)
                    }}
                >
                    Save
                </Button>


            </div>

        </Wrapper>
    )
}


export default OtherInformation;