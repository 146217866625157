import { dispatchAction, simpleDispatch_post, simpleDispatch_delete } from './helper';
import { constants } from '../utils/constants';
import * as actionTypes from '../action-types/master.data'



export const get_table_data = (table_name) => async dispatch => {

    const post_data = {
        table_name: table_name
    }

    const url = constants.END_POINTS.API + constants.END_POINTS.MASTER_DATA.POINT + constants.END_POINTS.MASTER_DATA.GET_TABLE_DATA
    await simpleDispatch_post(dispatch, url, post_data, actionTypes.GET_DATA_BY_TABLE_NAME,);
};




export const save_table_data = (table_name, data) => async dispatch => {
    const post_data = {
        table_name: table_name,
        data: data
    }
    const url = constants.END_POINTS.API + constants.END_POINTS.MASTER_DATA.POINT + constants.END_POINTS.MASTER_DATA.SAVE_TABLE_DATA
    await simpleDispatch_post(dispatch, url, post_data, actionTypes.SAVE_TABLE_DATA,);

}




export const delete_table_record = (table_name, id) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.MASTER_DATA.POINT + constants.END_POINTS.MASTER_DATA.DELETE_TABLE_REDCORD + `?id=${id}&table_name=${table_name}`

    await dispatchAction(dispatch, actionTypes.MASTER_DATA_STORE_ID, id)
    await simpleDispatch_delete(dispatch, url, actionTypes.DELETE_TABLE_REDCORD, true);

}



// export const update_table_record = (table_name, data) => async dispatch => {
//     const post_data = {
//         table_name: table_name,
//         data: data
//     }
//     const url = constants.END_POINTS.API + constants.END_POINTS.MASTER_DATA.POINT + constants.END_POINTS.MASTER_DATA.UPDATE_TABLE_RECORD
//     await simpleDispatch_put(dispatch, url, post_data, actionTypes.UPDATE_TABLE_RECORD);

// }