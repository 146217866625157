import React from "react";

const CopyIcon = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1412 1664"
      preserveAspectRatio="xMidYMid meet"
    >
      <path transform="translate(422,4)" d="m0 0h857l16 2 21 6 16 7 16 10 13 10 15 15 11 15 12 23 6 19 3 13 1 9v1152l-2 14-5 19-6 15-9 17-10 13-9 10-7 7-13 10-17 10-19 8-20 5-16 2h-156v107l-1 17-4 20-7 19-8 16-10 14-11 13-12 11-13 9-16 9-21 8-20 5-10 1h-856l-22-5-21-7-16-8-13-9-13-11-10-10-11-15-8-14-5-11-6-18-3-14-1-7v-1153l3-19 4-14 6-15 7-13 12-17 12-13 8-7 15-11 21-11 19-6 15-3 10-1 156-1 1-111 2-18 5-20 8-19 6-11 12-17 6-7 8-7 10-9 15-10 19-9 15-5 15-3z" fill="#FEFEFE" />
      <path transform="translate(133,303)" d="m0 0h157l1 1v963l2 26 4 18 6 17 8 16 8 12 9 11 14 14 15 11 16 9 15 6 13 4 18 3 650 1 1 7-1 108-3 15-6 16-8 13-11 13-12 10-10 6-16 6-18 4-64 1h-729l-55-1-20-4-16-7-11-7-12-11-7-8-7-11-5-10-5-17-2-12-1-144v-854l1-138 2-14 5-16 6-12 8-11 11-12 14-10 14-7 15-4z" fill="#FEFEFE" />
      <path transform="translate(422,4)" d="m0 0h857l16 2 21 6 16 7 16 10 13 10 15 15 11 15 12 23 6 19 3 13 1 9v1152l-2 14-5 19-6 15-9 17-10 13-9 10-7 7-13 10-17 10-19 8-20 5-16 2h-156v107l-1 17-4 20-7 19-8 16-10 14-11 13-12 11-13 9-16 9-21 8-20 5-10 1h-856l-22-5-21-7-16-8-13-9-13-11-10-10-11-15-8-14-5-11-6-18-3-14-1-7v-1153l3-19 4-14 6-15 7-13 12-17 12-13 8-7 15-11 21-11 19-6 15-3 10-1 156-1 1-111 2-18 5-20 8-19 6-11 12-17 6-7 8-7 10-9 15-10 19-9 15-5 15-3zm136 50-137 1-17 4-12 5-15 10-12 11-10 14-7 14-5 17-2 20-1 142v821l1 159 3 21 5 15 6 12 9 12 7 8 13 10 15 8 16 5 14 2 170 1h283l338-1 59-1 16-4 13-5 14-9 12-11 9-11 8-14 5-15 2-10 1-31v-1067l-1-55-4-16-8-16-7-11-7-8-11-9-13-8-11-5-16-4-142-1zm-425 249-18 4-14 6-13 9-12 11-9 12-7 12-5 15-2 9-1 9-1 138v854l1 144 3 17 7 19 7 11 8 10 9 9 13 9 13 6 13 4 12 2 55 1h729l64-1 18-4 16-6 11-7 13-11 10-12 9-16 5-16 2-11 1-112-1-3-650-1-18-3-21-7-16-8-14-9-11-9-14-14-11-15-9-16-7-19-4-16-2-14-1-17v-963l-1-1z" fill="#242424" />
    </svg>
  );
};

export default CopyIcon;
