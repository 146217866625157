import React from 'react'
import styled from 'styled-components';
import ThemeSetting from '../layout-editor/theme.setting';
import RippleEffect from '../hoc/ripple-effect';
import CloseSvg from '../svg/close'
import { hideRighter } from '../../utils';

const MainWrapper = styled.div`
    width: 300px;
    height: 98vh;
    background-color: #fff;
    padding: 0px 10px;
    box-sizing: border-box;
    position: relative;

    h1{
        font-size: 1.2rem;
        /* text-align: center; */
        font-weight: 600;
    }
`;

const ThemeEditorWrapper = styled.div`
    width: 300px;
    height: calc(100vh - 80px);
    overflow: auto;
    &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }
`;


const ThemePanel = (props) => {

    const { theme_json_values, report_item_id, update_theme_value, render_mode, chart_type } = props;

    return (
        <MainWrapper>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                margin: '10px 0px',
                justifyContent: 'space-between'
            }}>
                <h1>Edit Your Report Theme</h1>
                <div>
                    <RippleEffect
                        tooltip="Close"
                        icon={<CloseSvg size='.9rem' height='.9rem' color="#374a66" />}
                        Click={() => {
                            hideRighter()
                        }}
                    />
                </div>
            </div>

            <ThemeEditorWrapper>
                <ThemeSetting
                    render_mode={render_mode}
                    chart_type={chart_type}
                    themeValues={theme_json_values}
                    report_item_id={report_item_id}
                    theme_key={'report'}
                    update_theme_value={update_theme_value}
                />
            </ThemeEditorWrapper>
        </MainWrapper>
    )
}

export default ThemePanel
