import GeoJSON from 'ol/format/GeoJSON';
import Map from 'ol/Map';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Polygon from 'ol/geom/Polygon';
import { MultiPolygon } from 'ol/geom';
import View from 'ol/View';
import Feature from 'ol/Feature';
import { Fill, Stroke, Style } from 'ol/style';
import { clearDrawnPolygon } from '../Controls/polygon_draw';
import { transform } from 'ol/proj';
import d from './d.json';

let polygon_layer = undefined;
let polygon_layer_grp = [];

// Function to generate a unique color with transparency for each polygon based on index
const getStyleForPolygon = (index) => {
    const r = (index * 70) % 256;
    const g = (index * 150) % 256;
    const b = (index * 200) % 256;
    const color = `rgba(${r}, ${g}, ${b}, 0.5)`; // Adding 0.5 alpha for transparency

    return new Style({
        stroke: new Stroke({
            color: '#3399CC', // Stroke color for boundaries
            width: 1,
        }),
        fill: new Fill({
            color: color, // Unique fill color with transparency per index
        }),
    });
};

// Main function to render polygons
export const render_polygon = (geojson, map, clear, type) => {
    // Clear previous polygons if requested
    clearDrawnPolygon(map);

    if (clear) {
        if (polygon_layer) {
            polygon_layer.getSource().clear();
            map && map.removeLayer(polygon_layer);
            polygon_layer = undefined;
        }

        if (polygon_layer_grp && polygon_layer_grp.length > 0) {
            polygon_layer_grp.forEach(p => {
                p.getSource().clear();
                map && map.removeLayer(p);
            });
            polygon_layer_grp = [];
        }
        return;
    }

    const coordinates = geojson.coordinates?.length > 0 ? geojson.coordinates : geojson.m_coordinate;
    const vectorSource = new VectorSource();

    // Set the view based on the geometry type and coordinates
    if (geojson?.type === "MultiPolygon") {
        map.setView(new View({
            center: transform(coordinates[0][0][0], 'EPSG:4326', 'EPSG:3857'),
            zoom: 7,
        }));
    } else {
        map.setView(new View({
            center: transform(coordinates[0][0], 'EPSG:4326', 'EPSG:3857'),
            zoom: 7,
        }));
    }

    // Loop through each polygon in MultiPolygon and add it with unique style
    if (geojson?.type === "MultiPolygon") {
        coordinates.forEach((polygonCoords, index) => {
            const polygon = new Polygon(polygonCoords);
            polygon.transform('EPSG:4326', 'EPSG:3857'); // Transform coordinates for map projection
            const feature = new Feature(polygon);
            feature.setStyle(getStyleForPolygon(index)); // Apply unique style with transparency
            vectorSource.addFeature(feature); // Add each polygon as a separate feature
        });
    } else {
        const polygon = new Polygon(coordinates);
        polygon.transform('EPSG:4326', 'EPSG:3857');
        const feature = new Feature(polygon);
        feature.setStyle(getStyleForPolygon(0));
        vectorSource.addFeature(feature);
    }

    // Create vector layer and add it to the map
    polygon_layer = new VectorLayer({
        source: vectorSource,
    });

    map.addLayer(polygon_layer);
};
