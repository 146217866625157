import React from 'react'
import ToolTip from "../../hoc/toolTip";
import DeepDiveIcon from '../../svg/icons-v2/deepDiveIcon';
import IndepthStoryIcon from '../../svg/icons-v2/indepthStoryIcon';
import SendMail from '../../svg/icons-v2/send_mail';
import DownloadPDFSvg from '../../svg/icons-v2/download_pdf';
import { generatePdf, generatePDFFromElement, getMandatoryRequestHeaders, showPopup } from '../../../utils';
import enums from '../../../utils/enums';
import ShareLinkPopup from './mail.popup'
import axios from 'axios';
import { constants } from '../../../utils/constants';

const OptionsButtonsGroup = (props) => {

    const {

        setDeepDrive,
        is_why_case,
        id

    } = props;




    async function sendPDFToBackend(pdfBlob, pdfTitle, extraData) {

        const formData = new FormData();
        formData.append("nf_uploader", pdfBlob, `${pdfTitle}.pdf`);

        // Append additional data to FormData
        // Object.keys(extraData).forEach((key) => {
        //     formData.append(key, extraData[key]);
        // });

        console.log("formData", extraData)

        try {

            // Define the options for the axios request, including mandatory headers
            const url = `${constants.END_POINTS.API}${constants.END_POINTS.PDF_TO_QUESTION_ANSWER.SEND_FILE_TO_UPLOAD}`;

            const options = {
                headers: getMandatoryRequestHeaders(
                    undefined, 'upload_and_mail',
                    undefined, undefined, undefined,
                    undefined,
                    extraData?.emails?.join(","),
                    extraData?.subject,
                    extraData?.message?.replace(/\s+/g, ' ').trim()
                ),
            }

            const response = await axios.post(url, formData, options)

            if (response.status === 200) {
                console.log("PDF and extra data sent to backend successfully!");
            } else {
                console.error("Failed to store PDF and extra data on backend.");
            }
        } catch (error) {
            console.error("Error sending PDF and extra data to backend:", error);
        }
    }


    const upload_and_share = async (extraData) => {

        const blob_data = await generatePDFFromElement(id, "VADY AI Summary", false);

        await sendPDFToBackend(blob_data, "VADY AI Summary", extraData);
    }



    const openShareLinkPopup = () => {
        showPopup(undefined, undefined, enums.popupType.element, ShareLinkPopup, {
            report_type: "nf_dashboard",
            save: upload_and_share
        })
    }



    return (
        <div className='button_group' style={{
            borderTop: '1px solid #ccc',
            paddingTop: '13px'
        }}>

            {!is_why_case && (
                <React.Fragment>
                    <ToolTip text={'AI Summary'} bgColor="#000" positionLeft={'30%'}>
                        <span className={true ? "btn" : "disabled"} onClick={() => {
                            props?.get_detaild_data_story()
                        }}>
                            <IndepthStoryIcon size={"1.6rem"} height="1.6rem" color={'#000'} />
                        </span>
                    </ToolTip>

                    <ToolTip text={'Deep Dive'} bgColor="#000" positionLeft={'30%'}>
                        <span className={true ? "btn" : "disabled"} onClick={() => {
                            setDeepDrive(true);
                        }}>
                            <DeepDiveIcon size={"1.4rem"} height="1.4rem" color={'#000'} />
                        </span>
                    </ToolTip>
                </React.Fragment>
            )}

            {is_why_case && (
                <React.Fragment>
                    <ToolTip text={'Download PDF'} bgColor="#000" positionLeft={'30%'}>
                        <span className='btn' onClick={() => {
                            generatePDFFromElement(id, 'VADY AI Summary')
                            // generatePdf('chart', 'download', ("Dashboard"), 'ai_summery', undefined, ("ai_summery" + ".pdf"), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 500, true);
                        }}>
                            <DownloadPDFSvg size={"1.6rem"} height="1.6rem" color={'#000'} />
                        </span>
                    </ToolTip>

                    <ToolTip text={'Send Mail'} bgColor="#000" positionLeft={'30%'}>
                        <span className='btn' onClick={() => {
                            openShareLinkPopup()
                        }}>
                            <SendMail size={"1.4rem"} height="1.4rem" color={'#000'} />
                        </span>
                    </ToolTip>
                </React.Fragment>
            )}
        </div>
    )
}


export default OptionsButtonsGroup;