import { console_logger, formatValueByDataType, generate_unique_key, get_short_month, get_short_month_with_value, is_date, is_month, is_valid_date_v2, proper_case } from '../index';
import * as dataTypes from '../../shared-with-fe/data.types';
import { CHART_TYPES, FULL_MONTHS, MONTHS } from '../../shared-with-fe/constants';
import { sort_priorities, sort_using_priorities, weeks_config } from './sort_priorities';
import keys from '../../shared-with-fe/keys';
import { store } from '../../store';
import { SORT_VALUES } from '../config';
import { is_date_time, format_date, date_add, is_date_month } from '../index';
import { SET_TABLE_DATA_MODE_SUCCESS } from '../../action-types/helper'
import { get_all_outputs, prepare_formula_calculation_logic, calculate_formula, } from './formula.helper';
import { switch_rows_columns } from '../v1.2';
import { create_xac_index_keys } from './keys_keeper';
import { LOGS } from './logs.constant';


const data = require('./data.json');

const { constants } = require('../constants');
const { CONDITIONS } = constants;



const convertDateToWeek = (raw_data, key_to_convert_week, xDataKeys, yDataKeys) => {

    const clone_raw_data = raw_data ? [...raw_data] : [];

    clone_raw_data.forEach(entry => {

        const dateToCheck = new Date(entry[key_to_convert_week]);

        for (const config of weeks_config) {
            const startDate = new Date(config.date_range.split(" to ")[0]);
            const endDate = new Date(config.date_range.split(" to ")[1]);

            if (dateToCheck >= startDate && dateToCheck <= endDate) {
                entry[key_to_convert_week + "pre"] = entry[key_to_convert_week];
                entry[key_to_convert_week] = config.week + " Week";

            }
        }
    });

    return clone_raw_data;

};




const calculate_everything = (array = [], yac_names = ['no_yac'], debug) => {

    const yac_outputs = {};
    const uniques = {};


    const calculate_row = (_value_, yac) => {
        yac_outputs[yac].count = yac_outputs[yac].count + 1;

        if (!uniques[yac]) uniques[yac] = {};

        const value = _value_ + '';

        if (!uniques[yac][value]) {
            uniques[yac][value] = 1;
            yac_outputs[yac].u_count = yac_outputs[yac].u_count + 1;
        }
    };


    array?.length > 0 && array.forEach(a => {
        let value = undefined;
        let value_type = 'number';

        // console.log("calc init 1", a)
        yac_names && yac_names?.length > 0 && yac_names.forEach(yac => {
            if (!yac_outputs[yac]) {
                yac_outputs[yac] = {
                    sum: 0,
                    product: 1,
                    min: 1000000000,
                    max: -1000000000,
                    dev: 0,
                    count: 0,
                    u_count: 0,
                };
            }

            if (!isNaN(a)) {
                // a number
                // console.log("calc init 3")

                value = parseFloat(a)
                value_type = 'number';
                calculate_row(value, yac, 'number');
            }
            else if (typeof a === 'object') {
                // console.log("calc init 2")

                // yac.toLowerCase() === 'mins' && console.log('wirlo ', a);

                // debug === 'raw_data_total' && console.log(':::', yac, a);

                if (typeof a[yac] !== 'undefined' && !isNaN(a[yac])) {
                    value = parseFloat(a[yac]);
                    // debug && console.log("calc init 4", value)
                    calculate_row(value, yac, 'number');
                }
                else {
                    if (typeof a[yac] !== 'undefined' && a[yac] !== null) {
                        // console.log("calc init 5")

                        // lets find if this is date

                        // if ((a[yac] + '').split('/').length === 3 || (a[yac] + '').split('-').length === 3) {
                        if (is_valid_date_v2(a[yac])) {
                            // console.log("calc init 6")
                            // it may be a date
                            // count here
                            value = a[yac];
                            value_type = 'date';
                            calculate_row(a[yac], yac, 'date');
                        }
                        else {
                            // count here
                            // console.log("calc init 7")

                            calculate_row(a[yac], yac, 'string');
                        }

                    }
                }
            }


            if (typeof value !== 'undefined') {
                // console.log("calc init 8")

                // console.log('summing up: ', value, value_type);

                if (value_type === 'number' && !isNaN(value)) {
                    // console.log("calc init 9 nnn", yac_outputs[yac].sum, value)

                    yac_outputs[yac].sum = parseFloat(yac_outputs[yac].sum) + parseFloat(value);
                    yac_outputs[yac].product = parseFloat(yac_outputs[yac].product) * parseFloat(value);

                    if (value < yac_outputs[yac].min) {
                        // console.log("calc init 10")

                        yac_outputs[yac].min = value
                    }

                    if (value > yac_outputs[yac].max) {
                        // console.log("calc init 11")

                        yac_outputs[yac].max = value
                    }
                }
                else if (value_type === 'date') {
                    // console.log("calc init 12")


                    if (yac_outputs[yac].min === 1000000000) {
                        // console.log("calc init 13")

                        yac_outputs[yac].min = (value + '');
                        yac_outputs[yac].max = (value + '');
                    }
                    else {
                        // console.log("calc init 14", value)

                        const d_value_this = parseInt((value + '')?.split('/').join().split('-').join('').split(' ')[0]);
                        const d_value_min = parseInt(yac_outputs[yac].min.split('/').join().split('-').join('').split(' ')[0]);
                        const d_value_max = parseInt(yac_outputs[yac].max.split('/').join().split('-').join('').split(' ')[0]);

                        if (d_value_this < d_value_min) {
                            // console.log("calc init 15")

                            yac_outputs[yac].min = (value + '');
                        }

                        if (d_value_this > d_value_max) {
                            // console.log("calc init 16")

                            yac_outputs[yac].max = (value + '');
                        }
                    }
                }

            }
            else {

            }

        });
    });



    array?.length > 0 && yac_names.forEach(yac => {
        yac_outputs[yac].avg = (yac_outputs?.[yac]?.sum || 0) / (array || []).length;
    });

    array?.length > 0 && array.forEach(a => {
        yac_names && yac_names?.length > 0 && yac_names.forEach(yac => {

            let value = undefined;

            if (!yac_outputs[yac]) {
                yac_outputs[yac] = {
                    sum: 0,
                    product: 1,
                    min: 100000000,
                    max: -100000000,
                    dev: 0,
                    count: 0,
                };
            }

            if (!isNaN(a)) {
                // a number
                value = parseFloat(a);
            }
            else if (typeof a === 'object') {
                if (typeof a[yac] !== 'undefined' && !isNaN(a[yac])) {
                    value = parseFloat(a[yac]);
                }
            }

            if (typeof value !== 'undefined') {
                yac_outputs[yac].dev += (value - (yac_outputs?.[yac]?.avg || 0)) * (value - (yac_outputs?.[yac]?.avg || 0));
            }
        });
    });

    array?.length > 0 && yac_names.forEach(yac => {
        yac_outputs[yac].variance = (yac_outputs[yac]?.dev || 0) / ((array || []).length - 1);
        yac_outputs[yac].sd = Math.sqrt((yac_outputs[yac]?.dev || 0) / ((array || []).length - 1));
    });

    return yac_outputs;
};

const sum = array => {
    return (array || []).reduce((previous_value, current_value) => {
        return previous_value + parseFloat(current_value);
    }, 0);
};

// using this variable to calculate previous difference
const start_data_for_difference = {};
const prev_data_for_difference = {};

const alignments_config = {
    [dataTypes.none]: {
        alignment: 'center',
        type: dataTypes.none,
    },
    [dataTypes.number]: {
        alignment: "right",
        type: dataTypes.number
    },
    [dataTypes.number_with_decimal]: {
        alignment: "right",
        type: dataTypes.number_with_decimal
    },
    [dataTypes.percent]: {
        alignment: "right",
        type: dataTypes.percent
    },
    [dataTypes.mobile]: {
        alignment: "left",
        type: dataTypes.mobile
    },
    [dataTypes.currency]: {
        alignment: "right",
        type: dataTypes.currency
    },
    [dataTypes.epoch]: {
        alignment: "left",
        type: dataTypes.epoch
    },
    [dataTypes.date]: {
        alignment: "left",
        type: dataTypes.date
    },
    [dataTypes.date_time]: {
        alignment: "left",
        type: dataTypes.date_time
    },
    [dataTypes.string]: {
        alignment: "left",
        type: dataTypes.string
    },
    [dataTypes.email]: {
        alignment: "center",
        // type: "/email"
    }
};


export const apply_comparison_on_data = (data, __xac__ = [], __yac__ = [], __comparisons__ = [], __column_meta__) => {

    const flatData = data ? [...data] : [];
    const keys_without_nfx = {};

    const yac_with_out_formula = __yac__?.length > 0 && __yac__?.filter((item) => (!item.is_formula || item.formula_applicable_to !== 'pivot_data')) || [];

    const yac_with_formula = __yac__?.length > 0 && __yac__?.filter((item) => (item.is_formula && item.formula_applicable_to === 'pivot_data')) || [];

    const get_yac_info = (c_name) => {
        for (let index = 0; index < __yac__.length; index++) {
            const element = __yac__[index];
            if (element.pivot_field_alias === c_name) return element;
        }
    }

    const yac = JSON.parse(JSON.stringify(__yac__))


    console.log("yacyac", yac);

    const xac = __xac__ ? [...__xac__] : [];
    let comparisons = __comparisons__ ? __comparisons__.map((k) => k.pivot_field_column_name) : [];

    const data_type_enum = dataTypes.date;

    const date_columns = [];

    const column_meta = __column_meta__ ? JSON.parse(JSON.stringify(__column_meta__)) : {};

    column_meta && Object.keys(column_meta).length > 0 && Object.keys(column_meta).forEach(key => {
        const column_m = column_meta[key];
        if (column_m.data_type === data_type_enum) {
            date_columns.push(key);
        }
    });



    let main_obj = [];
    const row_obj = {};

    const comp_keys = [];
    const comp_keys_v1 = [];
    const comp_other_keys = []

    const display_values = {};
    const data_types = {};

    let c_key = {};

    const formula_existence = {};



    if (!(comparisons && Array.isArray(comparisons) && comparisons.length > 0)) {
        return {
            main_obj: data,
            unique_comp_keys: [],
            column_meta
        };
    }



    flatData && flatData.length > 0 && flatData.forEach((data, index) => {

        let key = undefined;
        let comp_key = undefined;
        let row = data;
        const temp_obj = {};



        if (index === 0) {
            /****
             * if any column have empty values then assign them to temp value for comparisons
             * by saroj kumar
             * 9 Dec 2021
             */
            row && Object.keys(row).length > 0 && Object.keys(row).forEach((key) => {
                if (row[key] === undefined || row[key] === '' || row[key] === null) {
                    if (comparisons && comparisons.indexOf(key) > -1) {
                        row[key] = "----";
                    }
                }
            });

            // let's push the key values
            Object.keys(row).forEach(_key => {
                const key = _key + '';
                const display_value = column_meta[key] ? column_meta[key][keys.COLUMN_META_DATA.DISPLAY_VALUE] : key;
                const data_type = column_meta[key] ? column_meta[key][keys.COLUMN_META_DATA.DATA_TYPE] : undefined;

                display_values[display_value] = key;
                data_types[display_value] = data_type;
            });

            // console.log("row---", row, display_values, data_types)
        }

        let x_key = '';

        xac && xac.length > 0 && xac.forEach(x_column_name_flat => {

            const x_column_name = x_column_name_flat.trim();
            const data_type = data_types[x_column_name];
            const cell_value = row[x_column_name] || row[display_values[x_column_name]];

            if (typeof cell_value !== 'undefined') {

                if (index === 0) {

                    // console.log("here bhai1.1", is_date_time(cell_value), cell_value)

                }
                if (data_type === dataTypes.date && is_date_time(cell_value)) {

                    const d = format_date(date_add(new Date(cell_value), 'hour', 5.5), true);
                    temp_obj[x_column_name] = d;

                    // saroj kr added this code
                    // for barc on jan 5
                    // it not shoud be mt
                    key = key ? key + '-nf-' + d : d;

                }
                else {

                    const index_1 = comparisons.indexOf(x_column_name);
                    const index_2 = comparisons.indexOf(display_values[x_column_name]);

                    if (index_1 === -1 && index_2 === -1) {
                        temp_obj[x_column_name] = cell_value;
                        key = key ? key + '-nf-' + cell_value : cell_value;
                    }
                }
            }
            else {
                // column not found. what can cause this?
                // console.log('------------------------------------------------------------')
                // console.log('I could not find the column information for: ', x_column_name);
                // console.log('------------------------------------------------------------')
            }
        });


        if (typeof row_obj[key] === 'undefined') {
            row_obj[key] = temp_obj;
        }
        else {

        }


        comparisons && comparisons.length > 0 && comparisons.forEach(comparison_flat => {
            let cell_value = row[comparison_flat] || row[display_values[comparison_flat]];
            const data_type = data_types[comparison_flat] || data_types[display_values[comparison_flat]]
            if (comparison_flat === 'Quarter' && !isNaN(cell_value)) {
                cell_value = 'Q' + cell_value;
            }

            if (comparison_flat === 'Week' && !isNaN(cell_value)) {
                cell_value = 'Week' + cell_value;
            }
            if (comparison_flat === 'Hour' && !isNaN(cell_value)) {
                cell_value = 'Hour' + cell_value;
            }
            if (comparison_flat === 'Minute' && !isNaN(cell_value)) {
                cell_value = 'Minute' + cell_value;
            }
            if (data_type === dataTypes.date || data_type === dataTypes.date_time) {
                cell_value = formatValueByDataType(cell_value, data_type)
            }

            comp_key = comp_key ? (comp_key + '_nfx_' + cell_value) : cell_value;
        });


        yac && yac.length > 0 && yac.forEach((y, index) => {

            // console.log("echo", y)
            const yColumn_flat = y.pivot_field_column_name;
            const yColumn_flat_display_name = y.pivot_field_alias.trim();
            const yColumn = yColumn_flat.trim();

            const is_percentage = y?.pivot_aggregation === "percentage";

            let cell_value = row[yColumn] || row[display_values[yColumn]] || 0;

            if (y.is_formula && typeof cell_value === 'undefined') {

                cell_value = 0;
            }

            // we will NOT use comparisons for formulas

            if (y.visible_type === 2) {
                row_obj[key][yColumn_flat] = (row_obj[key][yColumn_flat] || 0) + parseFloat(cell_value);
                return;
            }

            if (yColumn === 'Quarter') {
                cell_value = 'Q' + cell_value;
            }

            if (yColumn === 'Week') {
                cell_value = 'Week' + cell_value;
            }
            if (yColumn === 'Hour') {
                cell_value = 'Hour' + cell_value;
            }
            if (yColumn === 'Minute') {
                cell_value = 'Minute' + cell_value;
            }

            if (typeof cell_value !== 'undefined') {

                const concat = comp_key ? comp_key + '_nfx_' + yColumn_flat_display_name : yColumn_flat_display_name;
                const total_concat = 'Total_nfx_' + yColumn_flat_display_name;
                const total_perc_concat = '% Share_nfx_' + yColumn_flat_display_name;

                // if (yac_with_out_formula?.length === 1) {

                // yac_with_formula?.forEach((item) => {

                //     if (!formula_existence?.[key]?.[comparisons[0]]) {

                //         const formula_cell_value = row[item?.pivot_field_column_name];
                //         keys_without_nfx[item?.pivot_field_alias] = 1;
                //         const formula_concat = item.pivot_field_column_name //+ '_nfx_' + yColumn_flat_display_name;

                //         row_obj[key][formula_concat] = (row_obj[key][formula_concat] || 0) + parseFloat(formula_cell_value);

                //         if (!formula_existence[key]) formula_existence[key] = {};

                //         formula_existence[key][comparisons[0]] = 1;
                //     }
                // })


                keys_without_nfx[comp_key] = 1;
                keys_without_nfx['Total'] = 1;
                keys_without_nfx['% Share'] = 1;

                if (cell_value === 'XXXX') {
                    row_obj[concat] = 'XXXX';
                }
                else {

                    row_obj[key][concat] = (row_obj[key][concat] || 0) + parseFloat(cell_value);
                    row_obj[key][total_concat] = (row_obj[key][total_concat] || 0) + parseFloat(cell_value);
                    row_obj[key][total_perc_concat] = 0;
                    console.log("echo", total_perc_concat, row_obj[key][total_perc_concat] )

                    // if (concat !== yColumn) {
                    //     row_obj[key][yColumn] = (row_obj[key][yColumn] || 0) + parseFloat(cell_value);
                    // }

                    // if (yColumn_flat_display_name !== yColumn) {
                    //     row_obj[key][yColumn_flat_display_name] = (row_obj[key][yColumn_flat_display_name] || 0) + parseFloat(cell_value);
                    // }

                }

                // if (!column_meta[concat]) {
                //     column_meta[concat] = {
                //         display_value: concat,
                //         data_type: data_types[yColumn],
                //         column_order: data_types[yColumn]
                //     };
                // }
                // console.log("concat", concat)

                const is_exist = comp_keys_v1.findIndex(c => c.d === concat);
                const is_total_key_exist = comp_other_keys.findIndex(c => c.d === total_concat);
                const is_total_perc_exist = comp_other_keys.findIndex(c => c.d === total_perc_concat);

                if (comp_key !== undefined && comp_key !== null && comp_key !== "null" && typeof comp_key !== "null" && typeof comp_key !== "undefined") {
                    if (is_exist === -1) comp_keys_v1.push({ d: concat, v: comp_key + '_nfx_' + yColumn_flat })
                }

                if (is_total_key_exist === -1) comp_other_keys.push({ d: total_concat, v: total_concat, })
                if (is_total_perc_exist === -1) comp_other_keys.push({ d: total_perc_concat, v: total_perc_concat, })

            }
            else {
                console.log("i am else wala bhai")

            }
        });
    });


    if (comparisons && comparisons.length > 0) {
        Object.keys(row_obj).forEach(key => {
            const row = row_obj[key];
            main_obj.push(row);
        });

        let final_comp_keys = [];

        const temp_comp_keys = [
            {
                d: 'Google Pay_nfx_Net Amount',
                v: "Google Pay_nfx_Net Amount"
            },
            {
                d: 'Google Pay_nfx_Net Amount 1',
                v: "Google Pay_nfx_Net Amount"
            },
            {
                d: 'NEFT_nfx_Net Amount',
                v: "NEFT_nfx_Net Amount"
            },
            {
                d: 'NEFT_nfx_Net Amount 1',
                v: "NEFT_nfx_Net Amount"
            }
        ]

        // debugger;

        comp_keys_v1?.length > 0 && comp_keys_v1.forEach((k) => {

            let field_name = (k.d.split('_nfx_')?.length > 0 && k.d.split('_nfx_')[k.d.split('_nfx_').length - 1]) || k.d;

            const column_information = get_yac_info(field_name)
            let comparison_name = k.d.split('_nfx_')[0];

            final_comp_keys.push({
                ...column_information,
                visible_type: 3,
                pivot_aggregation: comparison_name === '% Share' ? 'percentage' : (column_information?.pivot_aggregation || "sum"),
                pivot_field_column_name: comparison_name === '% Share' ? 'Total_nfx_' + field_name : k.v,
                pivot_field_alias: k.d
            })
        })


        const final_comp_key_v1 = [];
        comp_other_keys?.length > 0 && comp_other_keys.forEach((k) => {
            let field_name = (k.d.split('_nfx_')?.length > 0 && k.d.split('_nfx_')[k.d.split('_nfx_').length - 1]) || k.d;
            const column_information = get_yac_info(field_name);

            // console.log("column_information", field_name, column_information,)
            let comparison_name = k.d.split('_nfx_')[0];
            final_comp_key_v1.push({
                ...column_information,
                visible_type: 3,
                pivot_aggregation: comparison_name === '% Share' ? 'percentage' : (column_information?.pivot_aggregation || "sum"),
                pivot_field_column_name: comparison_name === '% Share' ? 'Total_nfx_' + field_name : k.v,
                pivot_field_alias: k.d
            })
        })


        // final_comp_keys = final_comp_keys?.filter((item) => (!item.is_formula || item.formula_applicable_to !== 'pivot_data')) || [];

        // yac_with_formula?.length > 0 && yac_with_formula.forEach((item) => {

        //     const key = item.pivot_field_alias //+ "_nfx_" + yac_with_out_formula[0]?.pivot_field_alias

        //     keys_without_nfx[item.pivot_field_alias] = 1;

        //     const clone_item = item ? { ...item } : {};

        //     clone_item.pivot_field_alias = key;
        //     clone_item.pivot_field_column_name = key;

        //     final_comp_keys.push(clone_item)
        // })


        const unique_comp_keys_v1 = [...(final_comp_keys || []), ...(final_comp_key_v1 || [])];
// 
        console.log("main_obj", main_obj)

        return {
            main_obj,
            unique_comp_keys: unique_comp_keys_v1,
            column_meta,
            keys_without_nfx
        };
    }

    return {
        main_obj,
        unique_comp_keys: []
    }
};

export const get_display_key_by_data_key = (data_key, columnMeta, index) => {

    const keys = columnMeta ? Object.keys(columnMeta) : [];
    for (let i = 0; i < keys.length; i++) {
        const d_key = (keys[i] && keys[i].indexOf("nf_") > -1) ? keys[i].split('nf_')[1] : keys[i];
        if ((d_key && d_key.toLocaleLowerCase()) === (data_key && data_key.toLocaleLowerCase())) {
            return columnMeta[keys[i]].display_value
        }
    }
}


export const get_display_key_by_table_column_name = (tbl_col_name, columnMeta, index) => {
    const keys = columnMeta ? Object.keys(columnMeta) : [];
    for (let i = 0; i < keys.length; i++) {
        if (columnMeta[keys[i]]?.db_column === tbl_col_name)
            return columnMeta[keys[i]].display_value
    }
}


const func = (v) => {
    let f = func, //this needs to match the name of the function itself, since arguments.callee.name is defunct
        o = {
            "undefined": () => 0,
            "boolean": () => 4,
            "number": () => 8,
            "string": i => 2 * i.length,
            "object": i => !i ? 0 : Object
                .keys(i)
                .reduce((t, k) => f(k) + f(i[k]) + t, 0)
        };
    return o[typeof v](v);
};


const replacer = {
    quarter: {
        1: 'Q1',
        2: 'Q2',
        3: 'Q3',
        4: 'Q4',
    },
    week: {
    },
    month: {
    }
};

Array(53).fill(1).forEach((_, index) => replacer.week[index] = 'Week ' + index);
Object.keys(FULL_MONTHS).forEach(m => replacer.month[FULL_MONTHS[m].toLocaleLowerCase()] = MONTHS[m]);


export const it_is_a_mt_value = (value) => {
    if (typeof value === "undefined" || value === "undefined" || value === null || value === "null" || value === "Infinity" || value === "-Infinity" || value === "--Infinity") {
        return true
    }
}




const get_quarter_by_FY = (qtr) => {

    if (!qtr) return '';
    const qt = parseInt(qtr);

    switch (qt) {
        case 1:
            return 4
        case 2:
            return 1
        case 3:
            return 2
        case 4:
            return 3;
        default:
            break;
    }



}

export const step_1_loop_through_rows_and_sort = (__data_to_process__, column_meta_data, keys_to_sort = [], debug_identifier = undefined) => {
    //lets loop through json
    // lets also make sure to assign the display values to column headers

    try {

        const data_to_process = __data_to_process__;

        console.log("going to sort data", keys_to_sort)

        /**
         * for sorting at multiple level, it is important that we will need to 
         * store the unique values so we can always mark asc, desc;
         * 
         * but uniques needed only for strings
         */

        const unique_values = {};
        const highests = {
            value: -1,
            length: 0
        };

        const data_types = {};

        const sort_keys = keys_to_sort.length === 0 ? data_to_process?.length > 0 && Object.keys(data_to_process[0]).map((m, i) => ({ name: m, order_by: 'asc' })) : keys_to_sort;
        

        // sort_keys.push('extra'); // for anything that has "-"
        // sort_keys.push('extra_1');



        console.log("sort_keys", sort_keys)
        data_to_process?.length > 0 && data_to_process.forEach((data_row, row_index) => {

            sort_keys.forEach(k => {

                const pre_data_type = column_meta_data?.[k.name]?.data_type;

                let value_to_sort_pre = data_row[k.name] + '';
                let value_to_sort = data_row[k.name] + '';

                if (pre_data_type === dataTypes.number_with_decimal || pre_data_type === dataTypes.number || pre_data_type === dataTypes.percent || pre_data_type === dataTypes.currency) {
                    if (it_is_a_mt_value(data_row[k.name])) {
                        value_to_sort = 0 + "";
                    }
                }


                if (!unique_values[k.name + '-' + k.order_by]) unique_values[k.name + '-' + k.order_by] = {};

                // this is the number sorting code
                const spec_ch = ['-', '_', '@', '=', '+', '~', '>', '<', '>=', '<='];

                const ch_by_split = value_to_sort_pre && spec_ch.find((c => value_to_sort_pre && value_to_sort_pre.indexOf(c) > -1));
                
                // console.log("ch_by_split", ch_by_split)
                // if (value_to_sort_pre && isNaN(value_to_sort_pre) && ch_by_split && value_to_sort.indexOf(ch_by_split) > -1) {
                //     const splited_vl = value_to_sort_pre && value_to_sort_pre.trim().split(ch_by_split);

                //     if ( splited_vl && splited_vl[0] && !isNaN(splited_vl[0])) {
                //     if (splited_vl && splited_vl[0] && typeof parseFloat(splited_vl[0]) === 'number' && ch_by_split !== '>' && ch_by_split !== '<') {
                //         // console.log("came here 1",splited_vl, value_to_sort )
                //         value_to_sort = parseFloat(splited_vl[0])
                //     }
                //     else if (splited_vl && splited_vl.length > 0 && splited_vl[splited_vl.length - 1] && typeof parseFloat(splited_vl[splited_vl.length - 1]) === 'number') {
                //         // console.log("came here 2",splited_vl, value_to_sort )
                //         value_to_sort = parseFloat(splited_vl[splited_vl.length - 1])
                //     }}
                //     else {
                //     }
                // }


                if (ch_by_split) {

                }


                let data_type = dataTypes.none;

                if (is_date(value_to_sort + '') || is_date_month(value_to_sort + '') || is_month(value_to_sort + '')) {

                    // do nothing as dates can be converted to number to sort themselves
                    data_type = dataTypes.date;

                    const short_month = is_month(value_to_sort + '') ? get_short_month_with_value(value_to_sort) : value_to_sort;
                    const parsed = is_month(value_to_sort + '') ?  Date.parse(short_month) * 2 : Date.parse(value_to_sort) * 2;


                    if (!highests[k.name]) highests[k.name] = { length: 0, value: 0 };

                    if (highests[k.name].length < (parsed + '').length) {
                        highests[k.name].length = (parsed + '').length;
                    }

                    if ((highests[k.name].value) < parsed) {
                        highests[k.name].value = parsed;
                    }
                }

                else if (!isNaN(value_to_sort)) {

                    // do nothing as integers can sort themselves
                    // do nothing as dates can be converted to number to sort themselves
                    data_type = dataTypes.number_with_decimal;

                    const v_to_use = it_is_a_mt_value(value_to_sort) ? 0 : value_to_sort
                    const parsed = parseFloat((parseFloat((v_to_use || 0)).toFixed(2))) * 2;

                    // console.log("ye i am here", value_to_sort, parsed, highests)

                    if (!highests[k.name]) highests[k.name] = { length: 0, value: 0 };

                    if (highests[k.name].length < (parseInt(parsed) + '').length) {
                        highests[k.name].length = (parseInt(parsed) + '').length;
                    }

                    if ((highests[k.name].value) < parsed) {
                        highests[k.name].value = parsed;
                    }

                }
                else {
                    // lets assume it is string
                    data_type = dataTypes.string;

                    if (!unique_values[k.name + '-' + k.order_by][value_to_sort]) {
                        unique_values[k.name + '-' + k.order_by][value_to_sort] = 1000;
                    }
                }

                if (!data_types[k.name] || data_types[k.name] < data_type) {
                    data_types[k.name] = data_type || pre_data_type;
                }
            });

        });


        const final_unique_values_with_asc_desc = {};

        // lets sort unique values accordingly
        console.log("unique_values", unique_values, highests, data_types)

        Object.keys(unique_values).forEach(column => {

            let values = JSON.parse(JSON.stringify(Object.keys(unique_values[column])));
            const splits = column.split('-');
            const asc_or_desc = splits[splits.length - 1];

            const counter = asc_or_desc === 'asc' ? 1 : -1;

            // for sorting using hyphen
            if (values && values.length > 0 && values[0].indexOf('-') > -1) {
                const pres = {};

                values.forEach(v => {
                    v.split('-').forEach((c, index) => {
                        if (!pres[index]) pres[index] = [];
                        pres[index].push(c);
                    });
                });


                Object.keys(pres).forEach(p => {
                    const values_ = pres[p];
                    sort_using_priorities(values_);
                });

                const l = Object.keys(pres).length;
                const first = Array.from(new Set(pres[0])).map(m => m.toLocaleLowerCase());
                const second = Array.from(new Set(pres[1])).map(m => m.toLocaleLowerCase());

                // console.log('index: ', 'sorting start', first, second);

                values.sort((_a_, _b_) => {
                    const a = _a_?.trim()?.toLocaleLowerCase();
                    const b = _b_?.trim()?.toLocaleLowerCase();

                    const a_s = a.split('-');
                    const b_s = b.split('-');

                    const index_a_1 = first.indexOf(a_s[0]);
                    const index_a_2 = second.indexOf(a_s[1]);

                    const index_b_1 = first.indexOf(b_s[0]);
                    const index_b_2 = second.indexOf(b_s[1]);

                    // console.log('decided: ', a_s[0], '====>' , index_a_1, a_s[1], '====>', index_a_2, b_s[0], '====>', index_b_1, b_s[1], '====>',index_b_2);


                    if (index_b_1 > index_a_1) { // a is before
                        // console.log('decided at first: ', a, b);
                        return -1;
                    }
                    else if (index_b_1 < index_a_1) { // a is before
                        // console.log('decided at second: ', a, b);
                        return 1;
                    }
                    else {

                        if (index_b_2 > index_a_2) { // a is before
                            // console.log('decided at 3.1: ', a, b);
                            return -1;
                        }
                        else if (index_b_2 < index_a_2) { // a is before
                            // console.log('decided at 3.2: ', a, b);
                            return 1;
                        }

                        else {
                            // console.log('decided at 3.0: ', a, b);

                            return 0;
                        }

                    }
                });



            }
            else {


                sort_using_priorities(values);
            }

            final_unique_values_with_asc_desc[column] = {};

            (values || []).forEach((v, index) => {
                if (final_unique_values_with_asc_desc[column][v]) final_unique_values_with_asc_desc[column][v] = 100000;

                let final_value = (55555 + parseFloat(counter * index)) + '';

                const characters_to_keep = 5;

                if (characters_to_keep.length > final_value.length) {
                    const difference = characters_to_keep.length - final_value.length;

                    final_value = Array(difference).fill('0').join() + final_value;
                }

                final_unique_values_with_asc_desc[column][v] = final_value;
            });
        });



        const assign_zero_if_required = (name, value, data_type) => {
            const word_length = ((data_type === dataTypes.number ? parseInt(value) : value) + '').length;
            const highest = ((highests[name] && highests[name].length) || word_length);

            if (highest > word_length) {
                const difference = highest - word_length;

                return Array(difference).fill(0).join('') + '' + value;
            }


            return value;
        };


        // let is_quarter_or_week_available = false;

        // column_meta_data && Object.keys(column_meta_data).length > 0 && Object.keys(column_meta_data).forEach(s => {

        //     if (column_meta_data[s] && column_meta_data[s].display_value === 'Week') {

        //         is_quarter_or_week_available = true;
        //     }
        //     else if (column_meta_data[s] && column_meta_data[s].display_value === 'Quarter') {
        //         is_quarter_or_week_available = true;
        //     }
        //     else if (column_meta_data[s] && column_meta_data[s].display_value === 'Qtr') {
        //         is_quarter_or_week_available = true;
        //     }
        // });


        // console.log("is_quarter_or_week_available", is_quarter_or_week_available)


        data_to_process?.length > 0 && data_to_process.forEach((data_row, row_index) => {

            let sort_key = '';

            (sort_keys || []).forEach(k => {

                const value_to_sort = data_row[k.name] + '';
                const asc_or_desc = k.order_by;
                const counter = asc_or_desc === 'asc' ? 1 : -1;

                if (data_types[k.name] === dataTypes.date || data_types[k.name] === dataTypes.date_time || data_types[k.name] === dataTypes.date_month) {

                    const short_month = is_month(value_to_sort + '') ? get_short_month_with_value(value_to_sort) : value_to_sort

                    const d = is_month(value_to_sort) ? ((Date.parse(short_month) * counter) + parseInt(highests?.[k.name]?.value / 2)) : (Date.parse(value_to_sort) * counter) + parseInt(highests?.[k.name]?.value / 2);
                    const final_value = assign_zero_if_required(k.name, d, dataTypes.date);
                    sort_key = sort_key + '-' + final_value;
                }

                else if (data_types[k.name] === dataTypes.number || data_types[k.name] === dataTypes.number_with_decimal || data_types[k.name] === dataTypes.percent || data_types[k.name] === dataTypes.currency) {

                    const value_to_use = it_is_a_mt_value(value_to_sort) ? 0 : value_to_sort;

                    const d = (parseFloat(value_to_use) * counter) + parseInt(highests[k.name].value / 2);
                    // we got a number
                    const final_value = assign_zero_if_required(k.name, d, dataTypes.number);

                    sort_key = sort_key + '-' + final_value;

                    // console.log("we got abcd", k.name, "key()", "value_to_sort", value_to_sort, sort_key, final_value, d, row_index)

                }
                else {

                    sort_key = sort_key + '-' + final_unique_values_with_asc_desc[k.name + '-' + asc_or_desc][value_to_sort];
                }

                const key = k.name;

                /**
                 * 
                 */
                if (column_meta_data && column_meta_data[key]) {

                    if (column_meta_data[key].data_type === dataTypes.date_month) {

                    }

                    if (key !== column_meta_data[key].display_value && data_row[key]) {

                        data_row[column_meta_data[key].display_value] = data_row[key]
                        delete data_row[key];
                    }
                }

            });


            // if (is_quarter_or_week_available) {
            //     if (data_row['Quarter'] && !isNaN(data_row['Quarter'])) {
            //         const ___v___ = get_quarter_by_FY(data_row['Quarter'])
            //         data_row['Quarter'] = 'Q' + ___v___//data_row['Quarter'];
            //     }

            //     if (data_row['Week'] && !isNaN(data_row['Week'])) {
            //         data_row['Week'] = 'Week ' + data_row['Week'];
            //     }
            // }

            data_row.sort_key = sort_key;
        });


        data_to_process?.length > 0 && data_to_process.sort((a, b) => {
            return a.sort_key.localeCompare(b.sort_key);
        });

        data_to_process?.length > 0 && data_to_process.forEach(d => {
            delete d['sort_key']
        });


        return data_to_process;

    } catch (error) {

        console.log("errorsorting", error);
    }
};



export const covert_normal_keys_to_display_values = (data, column_meta) => {

    data && data.forEach(row => {
        row && Object.keys(row).forEach(column => {

            const data_from_row = row[column];
            const meta = column_meta[column];
            const data_type = meta?.['data_type'];


            if (meta) {

                // const data_to_use = (data_type === dataTypes.date || data_type === dataTypes.date_time) ? formatValueByDataType(data_from_row, data_type) : data_from_row;

                const data_to_use = data_from_row;

                if (column !== meta.display_value) {
                    row[meta.display_value] = data_to_use;
                    delete row[column];
                }

                if (data_type === dataTypes.number || data_type === dataTypes.currency) {
                    row[meta.display_value] = data_from_row === null ? 0 : data_from_row
                }


                // here we are formating the date 
                if (row[meta.display_value]) {
                    // row[meta.display_value] = data_to_use;
                }

            }
        });
    });


};


/**
 * 
 * @param {*} xacs 
 * @param {*} yacs 
 * 
 * suppose if we have data like this:
 * [
 * { service: 'ipd', month: 'jan', value: '3445' },
 * { service: 'ipd', month: 'feb', value: '342' },
 * { service: 'ipd', month: 'mar', value: '4542' },
 * { service: 'opd', month: 'jan', value: '2387' },
 * { service: 'opd', month: 'feb', value: '4873' },
 * { service: 'opd', month: 'mar', value: '1737' },
 * { service: 'surgery', month: 'jan', value: '8474' },
 * { service: 'surgery', month: 'feb', value: '2863' },
 * { service: 'surgery', month: 'mar', value: '1834' },
 * { service: 'pharmacy', month: 'jan', value: '3874' },
 * ]
 * 
 * the return would be
 * 
 * { _nf_service_ipd_nf_jan: [1,2,3,4] } 
 * it is handy when we have lot of repetitive rows, so rather looping everytime, we just have all the indexes of existence of xac
 * for example, all the rows where ipd = service, etc etc.
 * 
 * @returns array with indexes of relevant rows
 */
const get_row_indexes_for_data = (sorted_data, __xacs__) => {
    const xac_indexes = {};
    const xac_indexes_uncombined = {};
    const xac_indexes_with_keeper_logic = {};
    const previous = undefined;

    let keys_keeper = {};


    const xacs = [...__xacs__];

    // why we are sorting the xacs []
    // saroj kr commenting the code
    // cuz it sort the key and our keys mismatch
    // 12 july 2022
    // xacs.sort();

    sorted_data.forEach((data_row, row_index) => {
        let this_combination = '';
        let this_combination_prev_logic = '';

        xacs.forEach((xac, index) => {
            const temp_keys_keeper = create_xac_index_keys(index, xac, data_row[xac] || '', keys_keeper);

            keys_keeper = temp_keys_keeper.new_keeper;

            this_combination = this_combination + '_nf_' + temp_keys_keeper.key;
            this_combination_prev_logic = this_combination_prev_logic + '_nf_' + xac + '_nf_' + data_row[xac] || '';

            if (typeof xac_indexes_uncombined[xac] === 'undefined') {
                xac_indexes_uncombined[xac] = {};
            }

            if (typeof xac_indexes_uncombined[xac][temp_keys_keeper.key] === 'undefined') {
                xac_indexes_uncombined[xac][temp_keys_keeper.key] = [];
            }

            xac_indexes_uncombined[xac][temp_keys_keeper.key].push(row_index);
        });


        if (typeof xac_indexes[this_combination_prev_logic] === 'undefined') {
            xac_indexes[this_combination_prev_logic] = [];
        }

        xac_indexes[this_combination_prev_logic].push(row_index);

        if (typeof xac_indexes_with_keeper_logic[this_combination] === 'undefined') {
            xac_indexes_with_keeper_logic[this_combination] = [];
        }

        xac_indexes_with_keeper_logic[this_combination].push(row_index);
        // xac_indexes[this_combination].push(row_index);
    });

    return {
        combined: {
            xac_indexes,
            xac_indexes_with_keeper_logic,
        },
        uncombined: {
            xac_indexes: xac_indexes_uncombined
        },

        keys_keeper,
    };
};


const get_row_indexes_for_data_uncombined = (sorted_data, xacs) => {



};


/**
 * 
 * @param {*} sorted_data 
 * @param {*} xacs 
 * @param {*} new_yacs 
 * @param {*} date_field 
 * @param {*} prev_xac_indexes_if_xac_not_changed 
 * 
 * PLEASE NOT: this is important for the performance, that we don't keep on looping
 * 
 * when we sum/avg, it will always be required for columns that are changed in yac, so if we are not
 * changing the cax, it is not required to loop the indexes again, we can use the same old xac indexes
 * 
 * @returns 
 */

export const get_column_info_state = (yAxis = [], xAxis = [], pre_stored_data,) => {

    // const yAxisnames = yAxis.filter((v => v.visible_type !== 0))?.map((v) => v.pivot_field_alias) || [];
    // const xAxisac_names = xAxis?.map((v) => v.pivot_field_alias) || [];

    const pivot_axis = [...xAxis, ...yAxis];


    // console.log("pivot_axis", pivot_axis, pre_stored_data)
    const column_preferance_data = pre_stored_data?.preferance_data || {};

    const pivot_stored_column_info = {};

    pivot_axis?.forEach((v) => {

        // const v_to_use = v.split("_nfx_")[v.split("_nfx_").length - 1];

        if (column_preferance_data[v]) {
            // console.log("v_to_use", v, v_to_use, column_preferance_data[v_to_use])
            pivot_stored_column_info[v] = column_preferance_data[v] //|| column_preferance_data[v];
        }
        else {
            if (!pivot_stored_column_info[v]) pivot_stored_column_info[v] = {};
            pivot_stored_column_info[v]['show'] = true;
            pivot_stored_column_info[v]['filter'] = {};
            pivot_stored_column_info[v]['case'] = undefined;
        }
    });
    if (column_preferance_data['other_info']) {
        pivot_stored_column_info['other_info'] = column_preferance_data['other_info']
    }
    else {
        if (!pivot_stored_column_info['other_info']) pivot_stored_column_info["other_info"] = {};
        if (!pivot_stored_column_info["other_info"]["applied_filter_keys"]) pivot_stored_column_info["other_info"]["applied_filter_keys"] = []
    }

    // console.log("pivot_stored_column_info")
    return pivot_stored_column_info;
}




export const apply_formula_over_pivot_data_v1 = (pivot_data_pre, pivot_pre_y_axis, pivot_pre_x_axis, pivot_pre_columns, formula_to_use_in_pivot_data, columnMeta, comparision_unique_values) => {


    console.log('sortedData: ', formula_to_use_in_pivot_data)
    /**
     * there is a validation if pivot formula will be exist in 
     * pivot value, column , row then we will use that formula otherwise
     * we will not use the formula
     * 
     */

    if (formula_to_use_in_pivot_data && formula_to_use_in_pivot_data.length > 0) {

        const is_exist_in_array = (array = [], name) => array.some(item => item.alias === name);

        const filterd_formula = (formula_to_use_in_pivot_data?.filter((f) => {
            if (is_exist_in_array(pivot_pre_y_axis, f.alias) || is_exist_in_array(pivot_pre_x_axis, f.alias) || is_exist_in_array(pivot_pre_columns, f.alias)) return true;
            else return false;
        })) || []


        const y_axis = pivot_pre_y_axis?.filter(y => (!y.is_formula || y.formula_applicable_to !== 'pivot_data'))?.map((v) => (v.pivot_field_alias_column || v.pivot_field_alias) || []);


        const comparisons = pivot_pre_columns?.map((v) => v.pivot_field_alias_column || v.pivot_field_alias) || [];

        if (filterd_formula?.length > 0) {
            const formula_applied_data = apply_formula({
                sortedData: pivot_data_pre,
                yDataKeys: y_axis,
                xDataKeys: pivot_pre_x_axis,
                report_formula: filterd_formula,
                columnMeta,
                comparisons: comparisons,
                comparision_unique_values: comparision_unique_values
            })

            // console.log("i am done", formula_applied_data)
            return formula_applied_data;
        }
    }
}


export const extract_all_uniques_comp_values = (sorted_data, comparisions = []) => {

    const data = [];
    const keeper = {};

    comparisions.forEach((comparison, comp_index) => {
        const d_key = comparison.pivot_field_column_name;

        data.push({
            comparison: comparison.pivot_field_alias || comparison.alias,
            values: []
        });

        sorted_data?.forEach((row) => {
            const v = row?.[d_key] && isNaN(row?.[d_key]) && row?.[d_key]?.length > 0 ? row?.[d_key] + '' : row?.[d_key];

            if (typeof keeper[v + ''] === 'undefined') {
                data[comp_index].values.push(v);
                keeper[v + ''] = 1;
            }
        })

    })

    return data;


}

// const sort_for_multi_level_transponse = (a, b) => {
//     const aParts = a?.pivot_field_alias?.split('_nfx_');
//     const bParts = b?.pivot_field_alias?.split('_nfx_');

//     if (a?.pivot_field_alias?.indexOf("% Share") > -1 || a?.pivot_field_alias.indexOf("Total_nfx_") > -1) {
//         return 1; // Place a at the end
//     } else if (b?.pivot_field_alias?.indexOf("% Share") > -1 || b?.pivot_field_alias.indexOf("Total_nfx_") > -1) {
//         return -1; // Place b at the end
//     }

//     for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
//         if (aParts[i] !== bParts[i]) {
//             return aParts[i].localeCompare(bParts[i]);
//         }
//     }
//     return a?.pivot_field_alias.localeCompare(b?.pivot_field_alias);
// }




// this is the patch code for mantain the transponse column order

const sort_for_multi_level_transponse = (data = [], y_data_keys) => {

    (data || []).sort((a, b) => {

        const aParts = a?.pivot_field_alias?.split('_nfx_');
        const bParts = b?.pivot_field_alias?.split('_nfx_');

        const specialTerms = (y_data_keys?.map((y) => y.pivot_field_alias || y.pivot_field_column_name)) || [] //["Revenue", "Discount",];

        if (a?.pivot_field_alias.indexOf("Total_nfx_") > -1) {
            return 1; // Place a at the end
        } else if (b?.pivot_field_alias.indexOf("Total_nfx_") > -1) {
            return -1; // Place b at the end
        }

        for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
            const aIndex = specialTerms.indexOf(aParts[i]);
            const bIndex = specialTerms.indexOf(bParts[i]);

            if (aIndex !== -1 && bIndex !== -1) {
                if (aIndex !== bIndex) {
                    return aIndex - bIndex;
                }
            } else if (aParts[i] !== bParts[i]) {

                const a1 = aParts[i]?.split("-")[0];
                const b1 = bParts[i]?.split("-")[0];
                // console.log("yess i am", aParts[i], is_date_month(aParts[i]))

                if (is_date_month(aParts[i])) {
                    const new_a = Date.parse(1 + " " + aParts[i])
                    const new_b = Date.parse(1 + " " + bParts[i])
                    if (new_a > 0 && new_b > 0) {
                        return new_a - new_b
                    }
                }

                let keys = [a1.toLocaleLowerCase(), b1.toLocaleLowerCase()];

                const sort_priority = sort_priorities[keys[0] + "-" + keys[1]];

                keys.sort();

                const sort_priority_1 = sort_priorities['*' + "-" + keys[1]];
                const sort_priority_2 = sort_priorities[keys[1] + "-" + '*'];

                const sort_priority_3 = sort_priorities['*' + "-" + keys[0]];
                const sort_priority_4 = sort_priorities[keys[0] + "-" + '*'];


                if (sort_priority) {
                    // console.log('sort ====> ', _a_, _b_, sort_priority);
                    if (sort_priority === a1.toLocaleLowerCase()) {
                        // console.log('compar 1: ', a, b, '===>', keys[0]);
                        return -1;
                    }
                    else if (sort_priority === b1.toLocaleLowerCase()) {
                        // console.log('compar -1: ', a, b, '===>', keys[1]);
                        return 1;
                    }
                    else {

                        return 0;
                    }
                }
                else if (sort_priority_1 || sort_priority_2) {
                    // key[0] is star
                    // console.log('sort_priority_1: ', sort_priority_1, sort_priority_2, keys);
                    if (sort_priority_1 === '*' || sort_priority_2 === '*') {
                        // whatever at the star, comes first
                        // console.log('compar bombard: ', a, b, 'a - b', sort_priority_1 || sort_priority_2);

                        if (keys[1] === a1.toLocaleLowerCase()) {

                            // console.log('compar 1: ', a, b, '===>', keys[0]);
                            return 1;
                        }
                        else if (keys[1] === b1.toLocaleLowerCase()) {
                            // console.log('compar -1: ', a, b, '===>', keys[1]);
                            return -1;
                        }
                        else {
                            // console.log('compar 0: ', a, b);

                            return 0;
                        }

                        return 1;
                    }

                    return -1;
                }
                else if (sort_priority_3 || sort_priority_4) {
                    // console.log('sort_priority_1: ', sort_priority_3, sort_priority_4, keys);

                    if (sort_priority_3 === '*' || sort_priority_4 === '*') {
                        // whatever at the star, comes first
                        // console.log('compar bombard: ', a, b, 'b - a', sort_priority_3 || sort_priority_4);

                        if (keys[0] === a1.toLocaleLowerCase()) {

                            // console.log('compar 1: ', a, b, '===>', keys[0]);
                            return 1;
                        }
                        else if (keys[0] === b1.toLocaleLowerCase()) {
                            // console.log('compar -1: ', a, b, '===>', keys[1]);
                            return -1;
                        }
                        else {
                            // console.log('compar 0: ', a, b);
                            return 0;
                        }
                    }
                    return 1;
                }
                else {
                    return a1.localeCompare(b1);
                }
            }
        }
        return a?.pivot_field_alias.localeCompare(b?.pivot_field_alias);
    })
        .sort((a, b) => {
            const aParts = a?.pivot_field_alias?.split('_nfx_');
            const bParts = b?.pivot_field_alias?.split('_nfx_');

            if (aParts.includes("% Share") && !bParts.includes("% Share")) {
                return 1; // Move strings containing "% share" to the end
            } else if (!aParts.includes("% Share") && bParts.includes("% Share")) {
                return -1; // Move strings containing "% share" to the end
            } else {
                return 0; // Maintain the order for other strings
            }

        })
}




/**
 * 
 * @param {*} sorted_data 
 * @param {*} columnMeta 
 * @param {*} _xacs_ 
 * @param {*} yacs 
 * @param {*} pivot_columns 
 * @param {*} new_yacs 
 * @param {*} prev_xac_indexes_if_xac_not_changed 
 * @param {*} prev_pivot_data 
 * @param {*} sort_order_values 
 * @param {*} be_chart_type 
 * @param {*} need_to_switch_row_column 
 * @param {*} report_user_column_preferance 
 * @param {*} report_formula 
 * @param {*} sort_fun_disabled 
 * @returns 
 */


export const get_pivot_data = (sorted_data, columnMeta, _xacs_, yacs, pivot_columns, new_yacs, prev_xac_indexes_if_xac_not_changed, prev_pivot_data, sort_order_values, be_chart_type, need_to_switch_row_column, report_user_column_preferance, report_formula, sort_fun_disabled) => {

    const pivot_data_report_formula = ((report_formula || [])?.filter((f) => f.formula_applicable_to === "pivot_data")) || [];

    const pivot_int_time = new Date();

    // this is pivot column means comperision columns
    const comparisons = (pivot_columns || []).filter((c) => !c.deleted)
    const xacs = _xacs_.filter((x) => !x.deleted).map(x => typeof x === 'object' ? x.pivot_field_column_name : x);
    let __yacs__ = new_yacs?.length > 0 ? JSON.parse(JSON.stringify(new_yacs)) : JSON.parse(JSON.stringify(yacs));


    // let's assign default agg to yacs column
    __yacs__.forEach(y => {
        if (!y.pivot_aggregation || y.pivot_aggregation === 'none') y.pivot_aggregation = 'sum'
    })


    // prepare pivot data   
    if (prev_xac_indexes_if_xac_not_changed && prev_pivot_data && !(comparisons && comparisons.length > 0)) {

        return add_yacs_to_prev_pivot_data(sorted_data, columnMeta, prev_pivot_data, prev_xac_indexes_if_xac_not_changed, xacs, new_yacs || [], yacs, comparisons, report_user_column_preferance, pivot_data_report_formula);
    }



    let __sorted_data__ = sorted_data ? [...sorted_data] : [];


    if (comparisons?.length > 0) {


        const comp_data_type = columnMeta?.[comparisons?.[0]?.alias]?.data_type;

        const comparision_unique_values = extract_all_uniques_comp_values(sorted_data, comparisons)

        // console.log("__yacs__", __yacs__)
        const d = apply_comparison_on_data(sorted_data, xacs, __yacs__, comparisons, columnMeta)

        // console.log('yac_with_out_formula: ', d);

        const formula_applied_data = apply_formula_over_pivot_data_v1(d?.main_obj, __yacs__, xacs, comparisons, pivot_data_report_formula, columnMeta, comparision_unique_values)

        // console.log("bhai saroj 1.1", formula_applied_data)

        let sorted_yac_keys = Object.keys(d.keys_without_nfx);

        sort_using_priorities(sorted_yac_keys, comp_data_type, "pivot")

        const sorted_indexes = {};

        sorted_yac_keys.forEach((yac, index) => {
            sorted_indexes[yac] = index;
        });


        const sorted_unique_comp_keys = d.unique_comp_keys?.map((v) => {
            return {
                ...v,
                visible_type: typeof v.visible_type == null || !v.visible_type & v.visible_type == "undefined" ? 0 : v.visible_type
            }
        });



        if (comparisons?.length > 0) {
            sort_for_multi_level_transponse(sorted_unique_comp_keys, __yacs__)
        }


        // console.log("sorted_unique_comp_keys", sorted_unique_comp_keys)

        if (d.unique_comp_keys && d.unique_comp_keys.length > 0) {
            d.unique_comp_keys.sort((a, b) => {
                const without_nfx_a = a?.pivot_field_alias?.split('_nfx_')[0];
                const without_nfx_b = b?.pivot_field_alias?.split('_nfx_')[0];
                return sorted_indexes[without_nfx_a] - sorted_indexes[without_nfx_b];
            });
        }

        __sorted_data__ = (formula_applied_data?.data?.length > 0 && pivot_data_report_formula?.length > 0) ? formula_applied_data?.data : d.main_obj;
        // console.log("__sorted_data__", __sorted_data__)

        __yacs__.forEach(y => {
            if (y.visible_type === 1) {
                y.visible_type = 0;
            }
        })
        __yacs__ = [...(__yacs__.filter(y => (y.visible_type || 0) < 2)), ...(sorted_unique_comp_keys || []), ...(__yacs__.filter(y => y.visible_type === 2)),];

        if (__yacs__ && __yacs__?.length > 0 && get_data_type_by_aggregation(__yacs__[0].pivot_aggregation) === dataTypes.percent) {

            // yeha it's percentage let's remove [% share] from yaxis
            // console.log("yes", __yacs__[0].pivot_aggregation)

            __yacs__ = __yacs__.filter((y) => y.pivot_field_alias.indexOf('% Share_nfx_') == -1) || [];

        }

    }
    else {

        // we may have fixed the yac as visible type = 0 previous, so lets make sure that it is not 0 as we may have removed
        // the comparison
        __yacs__.forEach(y => {
            if (y.visible_type === 0) y.visible_type = 1;
        })
    }

    const xac_indexes_wrapper = (prev_xac_indexes_if_xac_not_changed && comparisons.length === 0) || get_row_indexes_for_data(__sorted_data__, xacs);
    const xac_indexes = xac_indexes_wrapper.combined.xac_indexes;
    let pivot_data = [];


    let yac_totals = get_totals_for_yacs(__sorted_data__, __yacs__, 'raw_data_total', "ram");

    console.log("yac_totals", yac_totals, __sorted_data__)
    const unique_key = generate_unique_key('unq');


    start_data_for_difference[unique_key] = {};
    prev_data_for_difference[unique_key] = {};

    // console.log("xac_indexes", xac_indexes)
    Object.keys(xac_indexes).forEach(xac_key => {

        const key_data = xac_key.split('_nf_');
        const row_data = {};
        const rows_indexes = xac_indexes[xac_key] || '';

        for (let key_index = 1; key_index < key_data.length; key_index = key_index + 2) {

            // console.log("i am done")
            // this is a patch code for removeing null or undefined values from the pivot data

            if (key_data[key_index + 1] && key_data[key_index + 1] !== 'null' && key_data[key_index + 1] !== 'undefined') {

                row_data[key_data[key_index]] = key_data[key_index + 1];

            } else row_data[key_data[key_index]] = ''

        }

        // console.log("row_data", row_data, __sorted_data__)

        const all_yac_values = get_values_from_row_indexes(__sorted_data__, rows_indexes, __yacs__);
        // console.log("all_yac_values", all_yac_values)
        get_pivot_data_row_with_yac_values(row_data, all_yac_values, __yacs__, yac_totals, xacs, unique_key, comparisons, columnMeta, yacs.map(y => y.pivot_field_alias || y.pivot_field_column_name || y.alias));

        pivot_data.push(row_data);

    });
    // }

    delete start_data_for_difference[unique_key];
    delete prev_data_for_difference[unique_key];
    const sort_key = sort_order_values || xacs.map(x => ({ name: x, order_by: 'asc' }))



    pivot_data = sort_fun_disabled ? pivot_data : step_1_loop_through_rows_and_sort(pivot_data, columnMeta, sort_key);

    const pivot_data_ultimate_yacs = get_ultimate_yacs_along_with_alignments_and_formatting(__yacs__, xacs, columnMeta);


    // lets' assign chart type
    __yacs__?.length > 0 && __yacs__.forEach((y) => {

        if (!y.chart_type) {
            y.chart_type = be_chart_type || "bar_chart"
        }
    })

    // here we are calculating yac_totals based on pivot data
    let pivot_yac_totals = get_totals_for_yacs(pivot_data, __yacs__, 'pivot_t_total');

    let yac_totals_to_use = {};

    yac_totals && Object.keys(yac_totals)?.length > 0 && Object.keys(yac_totals).forEach((key) => {
        if (!yac_totals_to_use[key]) yac_totals_to_use[key] = {};
        // lets loop all those keys like min max avg
        if (Object.keys(yac_totals[key])?.length > 0) {
            Object.keys(yac_totals[key]).forEach(k => {
                yac_totals_to_use[key][k] = yac_totals[key][k];
                // let's add agg for chart min max
                if (pivot_yac_totals[key][k]) yac_totals_to_use[key][`agg_${k}`] = pivot_yac_totals[key][k];
            })
        }
    })


    // let pivot_data_grand_sum_total = get_grand_total_with_formula(pivot_yac_totals, __yacs__, _xacs_, report_formula, columnMeta)

    // console.log("pivot_data_ultimate_yacs1.1", pivot_data)

    const obj_to_return = {
        pivot_yac: __yacs__,
        pivot_data: pivot_data,
        xac_indexes: xac_indexes,
        pivot_data_ultimate_yacs: pivot_data_ultimate_yacs,
        yac_totals: yac_totals_to_use,
        pivot_x_axis: _xacs_,
    }

    const formula_applied_data = (!comparisons || comparisons?.length === 0) ? apply_formula_over_pivot_data_v1(obj_to_return.pivot_data, obj_to_return.pivot_yac, obj_to_return.pivot_x, pivot_columns, pivot_data_report_formula, columnMeta) : undefined;;


    if (formula_applied_data?.data?.length > 0) {
        obj_to_return.pivot_data = formula_applied_data?.data || [];
    }


    if (need_to_switch_row_column) {

        const formula_meta_data = formula_applied_data?.formula_column_meta_data || {};
        // we will use this column info for switch rows columns

        const new_column_meta_data = { ...(columnMeta || {}), ...formula_meta_data }

        const switched_output = switch_rows_columns(pivot_data, _xacs_.map((c => c.pivot_field_column_name)), __yacs__, new_column_meta_data);


        if (switched_output) {

            const row_column_pivot_data_ultimate_yacs = get_ultimate_yacs_along_with_alignments_and_formatting(switched_output.new_yac, switched_output.new_xac, switched_output.new_column_meta_data);
            obj_to_return.pivot_x_axis = switched_output.new_xac;
            obj_to_return.pivot_yac = switched_output.new_yac;
            obj_to_return.columnMeta = switched_output.new_column_meta_data;
            obj_to_return.pivot_data = switched_output.output_rows;
            obj_to_return.pivot_data_ultimate_yacs = row_column_pivot_data_ultimate_yacs;
            obj_to_return.column_row_wise_data_types = switched_output?.column_row_wise_data_types;
        }
    }


    const pivot_x_for_column = obj_to_return.pivot_x_axis?.map((v) => (v.pivot_field_column_name || v.pivot_field_alias || v.alias)) || []
    const pivot_y_for_column = obj_to_return.pivot_yac?.filter((v => v.visible_type !== 0))?.map((v) => (v.pivot_field_alias || v.pivot_field_column_name || v.alias)) || [];


    obj_to_return.pivot_columns_state = get_column_info_state(pivot_y_for_column, pivot_x_for_column, report_user_column_preferance);
    obj_to_return.pivot_int_time = pivot_int_time;
    obj_to_return.pivot_res_time = new Date();

    console_logger("RETURN PIVOT DATA SUCCESS")


    // console.log("obj_to_return", obj_to_return)

    return obj_to_return;


};


/**
 * 
 * @param {*} sorted_data 
 * @param {*} rows_indexes row_indexes are the indexes with the valid xacs. 
 * @param {*} yacs the yacs for which we need the values for
 * 
 * row_ indexes are te 
 * @returns 
 */
const get_values_from_row_indexes = (sorted_data, rows_indexes, yacs) => {

    const values = {};

    yacs && yacs.length > 0 && yacs.forEach(yac => {
        if (typeof values[yac.pivot_field_column_name] === 'undefined') {
            values[yac.pivot_field_column_name] = [];
        }
        else {
            // repeater?
            return;
        }

        rows_indexes && rows_indexes.length > 0 && rows_indexes.forEach(row_index => {
            const v_to_push = sorted_data[row_index] && (sorted_data[row_index][yac.pivot_field_alias] || sorted_data[row_index][yac.pivot_field_column_name]);

            if (typeof v_to_push !== 'undefined') {
                values[yac.pivot_field_column_name].push((v_to_push + '').split(',').join(''));
            }
        });
    });

    // console.log("values", values)
    return values;
};


/**
 * 
 * @param {*} values_for_yacs 
 * @param {*} yacs 
 * 
 * mutates the row data and assigns the values of yacs in the row data
 * 
 * for example, if row_data is { Patient Name: 'Raj' } and Yac is ['net amount', 'price'] then the updated
 * row_data will be { Patient Name: 'Raj', Net Amount': 1000, Price: '10' };
 */

let idx = 0;

const get_pivot_data_row_with_yac_values = (row_data, all_yacs_values, yacs, yac_totals, xacs, unique_id, comparisons, column_meta_data, old_yacs_needed_for_comparison_sases) => {
    // let date_column = 'Date' || xacs[0];
    let date_column = xacs[0];

    console.log("ababu", yacs)



    let key = '';
    let skip_this_row = false;

    let date_value = row_data[date_column];
    let first_date = start_data_for_difference[unique_id]?.['date_value'];


    // if (!row_data && row_data.length == 0 && Object.keys(row_data).length)

    if (date_value) {

        if (typeof start_data_for_difference[unique_id]['date_value'] === 'undefined') {

            start_data_for_difference[unique_id]['date_value'] = date_value;
            first_date = date_value;

        };


        // we will need to create a key with all the columns

        // we are still storing
        xacs.forEach(x => {
            if (x !== date_column) {
                key = key + '-' + row_data[x];
            }

            if (first_date && first_date === date_value) {
                if (typeof start_data_for_difference[unique_id]?.[key] === 'undefined') {
                    start_data_for_difference[unique_id][key] = {};
                }
            }

            if (typeof prev_data_for_difference[unique_id]?.[key] === 'undefined') {
                // console.log('start_data_for_difference: ', prev_data_for_difference, start_data_for_difference);
                prev_data_for_difference[unique_id][key] = {};
            }

        });
    }



    // yacs && yacs.length > 0 && yacs.filter(y => !y.is_repeater)

    // saroj kr remove this for now
    // cuz i dont't have any idea why we filter this
    // 22 march 2022

    let sum_of_all_yac_columns = 0;

    yacs && yacs.length > 0 && yacs.filter(y => !y.is_repeater).forEach((yac, index) => {
        const value_to_use = yac.pivot_field_column_name;
        const pivot_field_alias = yac.pivot_field_alias || yac.pivot_field_column_name;
        const the_nfx_alias = yac?.alias;

        idx++;


        if (typeof start_data_for_difference?.[unique_id]?.[key]?.[value_to_use] === 'undefined') {

            if (!start_data_for_difference[unique_id][key]) start_data_for_difference[unique_id][key] = {}
            if (!start_data_for_difference[unique_id][key][value_to_use]) start_data_for_difference[unique_id][key][value_to_use] = {}
        }
        if (typeof prev_data_for_difference?.[unique_id]?.[key]?.[value_to_use] === 'undefined') {

            if (!prev_data_for_difference[unique_id][key]) prev_data_for_difference[unique_id][key] = {};
            if (!prev_data_for_difference[unique_id][key][value_to_use]) prev_data_for_difference[unique_id][key][value_to_use] = {}

        }
        // console.log("bhai mere set")

        const total_yac = yac_totals && yac_totals[value_to_use] && yac_totals[value_to_use]['sum'];
        const yac_values = all_yacs_values[value_to_use];

        const calculations = calculate_everything(yac_values);
        console.log("calculations", calculations)
        const calculation_results = calculations['no_yac'];

        const this_sum = (calculation_results?.sum || 0);
        const this_count = (calculation_results?.count || 0);

        const row_cwise_sum = the_nfx_alias ? all_yacs_values['Total_nfx_' + the_nfx_alias]?.[0] : 0;

        // if (idx === 1) console.log('ca: ', calculation_results, row_data, yac_values);

        // if(pivot_field_alias === '% Share_nfx_Revenue')
        row_data[value_to_use + '_nfsum'] = calculation_results?.sum || 0;
        row_data[value_to_use + '_nfavg'] = (calculation_results?.sum || 0) / yac_values.length;
        row_data[value_to_use + '_nfcount'] = yac_values.length;
        row_data[value_to_use + '_nfmin'] = calculation_results?.min;
        row_data[value_to_use + '_nfmax'] = calculation_results?.max;
        row_data[value_to_use + '_nfstdDev'] = calculation_results?.sd;
        row_data[value_to_use + '_nfvar'] = calculation_results?.variance;
        row_data[value_to_use + '_nfpercentage'] = (this_sum / total_yac) * 100;

        row_data[value_to_use + '_nfpercentage_cwise'] = row_cwise_sum > 0 ? (this_sum / row_cwise_sum) * 100 : 0;

        // row_data[value_to_use + '_std'] = std()

        sum_of_all_yac_columns += row_data[value_to_use + '_nfsum'];
        // console.log("bhai mere 1.0")

        if (date_value === first_date) {
            // console.log("bhai mere 1.1")
            start_data_for_difference[unique_id][key][value_to_use]['_nfsum'] = parseFloat(start_data_for_difference[unique_id][key][value_to_use]['_nfsum'] || 0) + this_sum;
        }

        prev_data_for_difference[unique_id][key][value_to_use]['_nfsum'] = parseFloat(prev_data_for_difference[unique_id][key][value_to_use]['_nfsum'] || 0) + this_sum;

        // sum_of_all_yac_columns = yac_totals[value_to_use]?.["sum"]
        // console.log("bhai mere 1.2")

        const start_data = start_data_for_difference[unique_id][key][value_to_use]['_nfsum'];
        const prev_data = prev_data_for_difference[unique_id][key][value_to_use]['_nfsum'];

        row_data[value_to_use + '_nfdiff_start'] = this_sum - start_data;
        row_data[value_to_use + '_nfperc_diff_start'] = ((this_sum - start_data) / start_data) * 100;

        row_data[value_to_use + '_nfdiff'] = this_sum - prev_data;
        row_data[value_to_use + '_nfperc_diff'] = ((this_sum - prev_data) / prev_data) * 100;

        if (yac.pivot_field_alias === '% Share_nfx_Revenue') {
            // console.log("this_sum", calculation_results, this_sum, "total_yac", total_yac, 'total=>>', ((this_sum / total_yac) * 100))

        }
        // console.log("bhai mere 1.3")

        row_data[value_to_use + '_nfcumulative'] = (prev_data_for_difference[unique_id][key][value_to_use]['_nfcumulative'] || 0) + this_sum;
        prev_data_for_difference[unique_id][key][value_to_use]['_nfcumulative'] = row_data[value_to_use + '_nfcumulative'];

        row_data[value_to_use + '_nfcumulative_count'] = (prev_data_for_difference[unique_id][key][value_to_use]['_nfcumulative_count'] || 0) + this_count;
        prev_data_for_difference[unique_id][key][value_to_use]['_nfcumulative_count'] = row_data[value_to_use + '_nfcumulative_count'];



        row_data[pivot_field_alias] = row_data[value_to_use + '_nf' + yac.pivot_aggregation];


        // this code is only applicabe for percantage case 
        if (yac && get_data_type_by_aggregation(yac.pivot_aggregation) === dataTypes.percent) {
            row_data['Total_nfx_' + the_nfx_alias] = row_data["Total_nfx_" + the_nfx_alias + "_nfsum"];

            console.log("yes", row_data['Total_nfx_' + the_nfx_alias])

        }

        // Total_nfx_Revenue_nfsum
        // console.log("pivot_field_alias", pivot_field_alias, "--", value_to_use)
        // prev_data_for_difference[unique_id][key][yac] = row_data[value_to_use + '_nfsum'];
    });


    /** adding values for stacked chart */

    yacs && yacs.length > 0 && yacs.filter(y => !y.is_repeater).forEach((yac, index) => {

        const value_to_use = yac.pivot_field_column_name;
        const sum_of_column = yac_totals?.[value_to_use]?.['sum']
        // const v = (row_data[value_to_use + '_nfsum'] / sum_of_column) * 100;

        const v = (row_data[value_to_use + '_nfsum'] / sum_of_all_yac_columns) * 100;
        row_data[value_to_use + '_nfstackshare'] = v;

        // 
    });

    yacs && yacs.length > 0 && yacs.filter(y => y.is_repeater).forEach(yac => {
        // repeaters only
        const value_to_use = yac.pivot_field_column_name;
        const pivot_field_alias = yac.pivot_field_alias;
        row_data[pivot_field_alias] = row_data[value_to_use + '_nf' + yac.pivot_aggregation];
    });



    // console.log("row_data", row_data)
    // // /here we will push the comparisons key data in data row
    // comparisons?.length > 0 && comparisons.forEach((c) => {
    //     const pivot_field_alias = c.column_name;
    //     row_data[pivot_field_alias] = row_data[c.column_name]
    // })

    // console.log("final row data:", row_data)
    // start_data_for_difference[unique_id][key]['nf_key'] = key;

};

/**
 * 
 * @param {*} sorted_data 
 * @param {*} pivot_data 
 * @param {*} xac_indexes 
 * @param {*} xacs 
 * @param {*} new_yacs the new yacs which are added. 
 * @param {*} date_field this field will be responsible for the difference option
 * @returns 
 */


export const add_yacs_to_prev_pivot_data = (sorted_data, columnMeta, pivot_data, xac_indexes, xacs, new_yacs, yacs, comparisons, report_user_column_preferance, pivot_data_report_formula) => {

    const clonned_pivot_data = [...pivot_data];

    const yac_totals = get_totals_for_yacs(sorted_data, new_yacs);
    const unique_key = generate_unique_key('unq');
    start_data_for_difference[unique_key] = {};
    prev_data_for_difference[unique_key] = {};


    clonned_pivot_data && clonned_pivot_data.length > 0 && clonned_pivot_data.forEach((row_data, index) => {

        //  _nf_Patient Name_nf_Kanika Dani

        let key = '';

        xacs.forEach(xac => {

            if (typeof xac === 'object') {
                key = key + '_nf_' + xac.pivot_field_column_name + '_nf_' + (row_data[xac.pivot_field_column_name] ? row_data[xac.pivot_field_column_name] : null);
            }
            else {
                key = key + '_nf_' + xac + '_nf_' + (row_data[xac] ? row_data[xac] : null);
            }
        });


        const row_indexes_to_loop = xac_indexes[key];
        const all_yac_values = get_values_from_row_indexes(sorted_data, row_indexes_to_loop, new_yacs);

        // console.log('case 7', yacs.map(y => y.pivot_field_alias || y.pivot_field_column_name || y.alias))

        get_pivot_data_row_with_yac_values(row_data, all_yac_values, new_yacs, yac_totals, xacs, unique_key, comparisons, columnMeta, yacs.map(y => y.pivot_field_alias || y.pivot_field_column_name || y.alias))

    });


    delete start_data_for_difference[unique_key];
    delete prev_data_for_difference[unique_key];

    const pivot_data_ultimate_yacs = get_ultimate_yacs_along_with_alignments_and_formatting(yacs, xacs, columnMeta);

    // console.log('case 6111', clonned_pivot_data, yac_totals)

    const pivot_columns_state = get_column_info_state((yacs?.map((y) => (y.pivot_field_alias || y.pivot_field_column_name || y.alias)) || []), xacs, report_user_column_preferance);

    // console.log("pivot_columns_state", pivot_columns_state, report_user_column_preferance)
    // let pivot_data_grand_sum_total = get_grand_total_with_formula(yac_totals, yacs, xacs, report_formula, columnMeta)

    const formula_applied_data = apply_formula_over_pivot_data_v1(clonned_pivot_data, yacs, xacs, comparisons, pivot_data_report_formula, columnMeta);


    // console.log("clonned_pivot_data", clonned_pivot_data, pivot_columns_state)

    if (formula_applied_data?.data?.length > 0) {

        return {
            pivot_data: formula_applied_data?.data,
            xac_indexes, pivot_data_ultimate_yacs,
            pivot_columns_state: pivot_columns_state,
            pivot_data_ultimate_yacs: pivot_data_ultimate_yacs,
            yac_totals: yac_totals,
            // pivot_data_grand_sum_total: pivot_data_grand_sum_total
        };
    } else {

        return {
            pivot_data: clonned_pivot_data,
            xac_indexes, pivot_data_ultimate_yacs,
            pivot_columns_state: pivot_columns_state,
            pivot_data_ultimate_yacs: pivot_data_ultimate_yacs,
            yac_totals: yac_totals,
            // pivot_data_grand_sum_total: pivot_data_grand_sum_total
        };
    }



};

const get_totals_for_yacs = (sorted_data, yacs, w) => {

    // const yacs_to_use = (yacs?.length > 0 && yacs.map(y => (y.pivot_field_column_name || y.pivot_field_alias))) || [];

    const yacs_to_use = (yacs?.length > 0 && (yacs || []).filter((a) => (a.pivot_field_alias.indexOf("Total_nfx") == -1)).map(y => (y.pivot_field_column_name || y.pivot_field_alias))) || [];

    const calculations = calculate_everything(sorted_data, yacs_to_use, w);

    return calculations;
};


const assign_difference_values_to_data = (sorted_data, date_field) => {

};


export const handle_formula = (formula_column, rowItem, index, formula, columnMeta) => {

    try {
        const operator = ['-', "+", "/", "*", "(", ")"];
        let formula_str = '';

        formula && formula.length > 0 && formula.forEach((k) => {
            const cell_key = get_display_key_by_data_key(k, columnMeta, index) || k;

            let cell_value = (cell_key && rowItem[cell_key]) || rowItem["total_nfx_" + cell_key] || 0;

            let v = parseFloat(cell_key);

            if (cell_key && typeof v === 'number' && !isNaN(v)) {
                cell_value = parseFloat(cell_key)
            }

            const is_operator = operator.indexOf(cell_key) > -1;

            if (is_operator) {
                formula_str += cell_key;
            }
            else {
                formula_str += cell_value ? cell_value : 0;
            }
        })

        if (index == 0) {
        }

        return (formula_str && formula_str.length > 0) ? eval(formula_str) : 'N/A';
    } catch (error) {
        // console.log("err", error)
        return 'N/A';
    }

}



export const handle_formula_condition = (formula_column, __rowItem, index, formula, columnMeta, _cell_value) => {

    const rowItem = __rowItem ? JSON.parse(JSON.stringify(__rowItem)) : {};
    let display_value = '';
    let need_to_go_else = false;

    const conditions_from_be = formula?.conditions || [];
    const conditions_with_else = conditions_from_be.sort((a, b) => a.order - b.order) || [];
    const with_out_else_condition = conditions_with_else.filter((k) => k.condition !== CONDITIONS.ELSE.VALUE_KEY)
    const else_condition = conditions_with_else?.length > 0 && conditions_with_else.find((k) => k.condition === CONDITIONS.ELSE.VALUE_KEY);


    const columns_pre = formula?.formula_columns;
    const columns = columns_pre && columns_pre.split(']')[0];
    const column = columns && columns.replace('[', '');


    let cell_key = get_display_key_by_data_key(column, columnMeta, index) || column;

    const formula_regx_data_type = formula?.db_data_type;

    console.log("===formula_regx_data_type===", formula)
    let cell_value = (cell_key && rowItem[cell_key]) //|| rowItem["total_nfx_" + cell_key];

    if (typeof cell_value === "undefined") {

        cell_key = rowItem["total_nfx_" + cell_key]
    }

    for (let i = 0; i < with_out_else_condition.length; i++) {

        const conition_el = with_out_else_condition[i];
        const c_value = conition_el.value;
        const value_to_use = !isNaN(c_value) ? parseFloat(c_value) : c_value;
        const cell_value_to_use = !isNaN(cell_value) ? parseFloat(cell_value) : cell_value;

        const display_value_pre = conition_el.display_value;
        const display_value_pre1 = display_value_pre && display_value_pre.split(']')[0];
        const display_column = display_value_pre1 && display_value_pre1.replace('[', '');

        const need_to_pick_display_value_form_row = display_value_pre && display_value_pre.indexOf('[') > -1 && display_value_pre.indexOf(']') > -1;

        const display_value_to_use = get_display_key_by_table_column_name(display_column, columnMeta, index) || display_column;


        if (conition_el.condition !== CONDITIONS.ELSE.VALUE_KEY) {

            if (check_is_condition_matched(conition_el.condition, cell_value, value_to_use, conition_el?.value2, index, formula_regx_data_type)) {
                display_value = (need_to_pick_display_value_form_row && display_value_to_use) ? rowItem[display_value_to_use] : (isNaN(conition_el.display_value) ? conition_el.display_value : parseFloat(conition_el.display_value));
                need_to_go_else = false;
                break;
            } else need_to_go_else = true;
        }
    }


    if (need_to_go_else && else_condition && else_condition.condition === CONDITIONS.ELSE.VALUE_KEY) {
        const else_columns_pre = else_condition.display_value;
        const else_columns = else_columns_pre && else_columns_pre.split(']')[0];
        const else_c = else_columns && else_columns.replace('[', '');
        const cell_key_to_use_in_else = get_display_key_by_table_column_name(else_c, columnMeta, index) || else_c;
        display_value = (else_condition.display_value && else_condition.display_value.indexOf("[")) > -1 ? (cell_key_to_use_in_else && rowItem[cell_key_to_use_in_else] || '') : else_condition.display_value;
    }


    return display_value !== "" ? (isNaN(display_value) ? display_value : parseFloat(display_value)) : ''
}

/**
 * 
 * @param {*} data_rows 
 * @param {*} formulas 
 * 
 * write the function and apply the formulas
 */


const find_text_between_two_strings = (find_in, string_1, string_2, start_index = -1) => {

    const matches = [];

    const index_1 = find_in.indexOf(string_1, start_index);
    const index_2 = index_1 > -1 ? find_in.indexOf(string_2, index_1 + 1) : -1;

    if (index_2 > -1) {

        matches.push(find_in.substring(index_1, index_2 + 1));

        const new_matches = find_text_between_two_strings(find_in, string_1, string_2, index_2 + 1);
        matches.push(...new_matches.matches);
    }

    return { matches, index_2 };

};


export const is_date_formula = (formula_str, columnMeta) => {

    const { matches } = find_text_between_two_strings(formula_str, "[", "]", -1);


    if (matches?.length > 0) {

        return matches.every((match) => {

            const pre_str = match.substring(1, match.length - 1);
            return columnMeta?.[pre_str]?.data_type === dataTypes.date ||
                columnMeta?.[pre_str]?.data_type === dataTypes.date_time ||
                columnMeta?.[pre_str]?.data_type === dataTypes.date_month
        });
    }
}



export const handle_date_formula = (formula_str, this_row, index, formula_column_name) => {

    const { matches } = find_text_between_two_strings(formula_str, "[", "]", -1);

    var value;

    const only_formatted_columns = matches?.map((match) => match.substring(1, match.length - 1)) || [];

    only_formatted_columns?.forEach((match) => {

        const current_value = this_row[match];
        // const upcoming_value =
        // const 

    })


}

export const apply_formula = ({ sortedData, yDataKeys = [], xDataKeys = [], report_formula, columnMeta, delete_key, comparisons, comparision_unique_values = [] }) => {

    const clone_sorted_data = sortedData ? [...sortedData] : [];
    const formula_keys = [];

    // delete_key

    if (delete_key && delete_key.length > 0) {
        delete_key.forEach((k) => {
            if (xDataKeys && xDataKeys.length > 0 && xDataKeys.indexOf(k) > -1) {
                const i = xDataKeys.indexOf(k);
                xDataKeys.splice(i, 1);
            }
            if (yDataKeys && yDataKeys.length > 0 && yDataKeys.indexOf(k) > -1) {
                const i = yDataKeys.indexOf(k);
                yDataKeys.splice(i, 1);
            }
        })
    }


    const mainFormulas = {};
    const column_meta_data = {};
    let new_keys = [];

    if (report_formula && report_formula.length > 0) {

        report_formula.forEach((_formula) => {

            let str_formula = _formula?.formula_str;

            let formula_key = _formula.alias;

            // if (comparisons?.length > 0) {

            //     const { matches } = find_text_between_two_strings(_formula.formula_str, "[", "]", -1);

            //     console.log("matches", matches)
            //     formula_key = _formula.alias //+ "_nfx_" + yDataKeys[0];

            //     matches?.forEach((match) => {

            //         const pre_str = match.substring(1, match.length - 1);
            //         const pre_str_splitted_with_hyphen = pre_str.split("-");

            //         // [Male-Revenue]
            //         if (xDataKeys.indexOf(pre_str) === -1) {

            //             // let's check if the match is comparision value then then do it lowecase
            //             // otherwise don't need to do it lowecase
            //             // console.log("comparision_unique_values", comparision_unique_values, pre_str)

            //             const is_exist_in_comp = comparision_unique_values.indexOf(pre_str.toLocaleLowerCase()) > -1;

            //             const pre_str_1 = is_exist_in_comp ? pre_str.toLocaleLowerCase() : pre_str;

            //             const is_source_formula_column = report_formula?.find((sc) => {
            //                 if (sc.alias === pre_str) return true;
            //                 else return false;
            //             })

            //             // yDataKeys.forEach((y_key) => {

            //             let post_str = "[" + pre_str_1 + "_nfx_" + yDataKeys[0] + "]";

            //             if (is_source_formula_column) {

            //                 post_str = "[" + pre_str_1 + "]";

            //             }
            //             if (pre_str.indexOf('-') > -1) {
            //                 post_str = "[" + pre_str.split('-')[0].toLocaleLowerCase() + "_nfx_" + pre_str.split('-')[1] + "]";
            //             }
            //             // formula_key = pre_str + "_nfx_" + yDataKeys[0];
            //             // console.log("pre_str", str_formula, pre_str, post_str)

            //             str_formula = str_formula.split(match).join(post_str)

            //             // console.log("str_formula", str_formula)

            //             // })
            //         }

            //     })
            //     // '[male_nfx_Revenue] / [Total_nfx_Revenue]'
            //     // console.log("matches:-", matches)

            // }



            const formula = _formula ? { ..._formula, formula_str: str_formula } : {};

            const formula_text = formula.formula_str;

            const formula_outputs = get_all_outputs(formula_text, yDataKeys, comparision_unique_values);


            let pivot_data = undefined;
            let aggregate_found = false;
            let yac_totals = undefined;
            const new_pv = {};

            const { column_definitions, column_outputs, unique_outputs } = formula_outputs;


            if (unique_outputs?.yacs?.length > 0) {

                const xacs_for_pivot = [...unique_outputs.xacs];
                const yacs_for_pivot = unique_outputs.yacs.map(yac => ({ pivot_field_alias: yac, pivot_field_column_name: yac, visible_type: 0 }));

                if (xacs_for_pivot.length > 0) {

                    // console.log('--------------------------inside--------------------------')

                    pivot_data = get_pivot_data(s_p(sortedData), columnMeta, xacs_for_pivot, yacs_for_pivot, undefined, yacs_for_pivot);
                    yac_totals = pivot_data.yac_totals;

                    pivot_data?.pivot_data?.forEach(row => {
                        let keys = [];
                        xacs_for_pivot.forEach(x => {
                            keys.push((row[x] + '').toLocaleLowerCase());
                        });

                        keys.sort();

                        new_pv[keys.join('^.^').trim()] = row;
                    });

                }
                else {

                    yac_totals = get_totals_for_yacs(sortedData, yacs_for_pivot);
                    console.log('finalllll: 3', yac_totals);
                }
                aggregate_found = true;
            };


            formula_keys.push(formula_key);
            column_meta_data[formula_key] = {
                data_type: formula.data_type ? dataTypes[formula.data_type] : 1,
                display_value: formula_key,
            };


            const final_formula_info_to_apply = prepare_formula_calculation_logic(formula_outputs?.column_outputs, formula_outputs.column_definitions.replaced_values, comparisons?.length > 0);

            if (comparisons?.length > 0) {
                const formula_name = formula?.pivot_field_alias || formula?.alias;
                let n_key = []

                const form_key = (existing_index, previous_key) => {
                    const current_item = comparision_unique_values[existing_index];
                    const new_keys = [];

                    if (current_item) {
                        current_item.values.forEach(v => {
                            let this_key = v + '';

                            if (previous_key) {
                                this_key = previous_key + '_nfx_' + this_key;
                            }
                            else {
                                // lets do nothing
                            }

                            // next item
                            const next_item = comparision_unique_values[existing_index + 1];

                            if (next_item) {
                                // the next item is there
                                const new_keys_from_recursive = form_key(existing_index + 1, this_key);
                                // console.log('1: ');
                                new_keys.push(...new_keys_from_recursive);
                            }
                            else {
                                new_keys.push(this_key + '_nfx_' + formula_name);
                            }
                        });
                    }

                    return new_keys;
                };

                n_key = form_key(0);

                new_keys = [...new_keys, ...n_key];

                n_key.forEach(n_key_i => {
                    console.log('assigning: ', n_key_i, ' to formula', formula_name);

                    mainFormulas[n_key_i] = {
                        original_formula_name: formula_name,
                        formula_columns: formula.formula_str,
                        show_formula_condition: formula.show_formula_condition,
                        conditions: formula.nf_formula_conditions,
                        data_type: formula.data_type,
                        formula_outputs,
                        new_pv,
                        yac_totals,
                        final_formula_info_to_apply
                    };

                });

                // mainFormulas['female_nfx_Dai'] = {
                //     original_formula_name: 'Dai',
                //     formula_columns: '[Revenue]*0.2',
                //     show_formula_condition: formula.show_formula_condition,
                //     conditions: formula.nf_formula_conditions,
                //     data_type: formula.data_type,
                //     formula_outputs,
                //     new_pv,
                //     yac_totals,
                //     final_formula_info_to_apply
                // };

                // mainFormulas['male_nfx_Dai'] = {
                //     original_formula_name: 'Dai',
                //     formula_columns: '[Revenue]*0.2',
                //     show_formula_condition: formula.show_formula_condition,
                //     conditions: formula.nf_formula_conditions,
                //     data_type: formula.data_type,
                //     formula_outputs,
                //     new_pv,
                //     yac_totals,
                //     final_formula_info_to_apply
                // };

            }

            else {
                mainFormulas[formula_key] = {
                    formula_columns: formula.formula_str,
                    show_formula_condition: formula.show_formula_condition,
                    conditions: formula.nf_formula_conditions,
                    data_type: formula.data_type,
                    db_data_type: formula?.db_data_type,
                    formula_outputs,
                    new_pv,
                    yac_totals,
                    final_formula_info_to_apply
                };
            }

        })
    }


    console.log("mainFormulas", mainFormulas)

    const combind_all_keys = [...xDataKeys, ...yDataKeys, ...formula_keys, ...new_keys];


    const unique_comp_keys = [...new Set(combind_all_keys)]

    // console.log("unique_comp_keys", formula_keys)

    clone_sorted_data && clone_sorted_data.length > 0 && clone_sorted_data.forEach((data, index) => {

        const clone_row_data = data;
        // here we are deleting the deleted keys form data 
        if (delete_key && delete_key.length > 0) {
            delete_key.forEach((key, index) => {
                delete data[key];
            })
        }

        console.log('mathe: ', comparisons, comparision_unique_values, sortedData[3]);


        // here we are applying formula
        unique_comp_keys && unique_comp_keys.length > 0 && unique_comp_keys.forEach(column => {

            if (mainFormulas && mainFormulas[column]) {
                const fm = mainFormulas[column]
                const show_cond = fm.show_formula_condition;

                // console.log("is_date_fm", fm)

                if (show_cond) {

                    // if (index === 0) {
                    //     console.log("saroj", s_p(data))
                    // }
                    const _data_ = handle_formula_condition(column, data, index, fm, columnMeta,);
                    // if (column === "Total Opportunities") console.log("_data_", _data_, column)
                    data[column] = _data_

                }

                else {

                    let c = null;
                    const { formula_outputs, final_formula_info_to_apply, new_pv, yac_totals, data_type } = fm;

                    let final_formula_text = formula_outputs?.final_formula_text;

                    if (data_type === "string" && typeof final_formula_text === "string") {
                        // let's return notmal string value
                        c = final_formula_text;
                    }
                    else {
                        console.log("Invalid final_formula_text", fm)
                        c = calculate_formula(fm, data, index, column, columnMeta, yDataKeys, comparisons, comparision_unique_values);

                        if (index < 5) {

                        }
                        // if (data["leadId2"] == 9240) {
                        //     console.log("formula_text", fm, data)
                        // }
                    }

                    /**
                     * we won't be able to get formula column count(), sum(), cuz we calculate these things 
                     * 
                     */


                    // if (index < 10) {

                    //     if (column === "Total Opportunities") console.log("_data_", fm, c, column, JSON.parse(JSON.stringify(data)))
                    // }

                    // if (index === 0) {
                    // console.log("hey_we_got", JSON.parse(JSON.stringify(data)), c, fm)
                    // }
                    console.log('columnwa: ', data, '<<<<<>>>>>>>', column);
                    data[column] = c
                }

            }
        })

    });


    // we moved this logic at the end of calculation cuz 
    // it was create order error 

    mainFormulas && Object.keys(mainFormulas).length > 0 && Object.keys(mainFormulas).forEach((k) => {
        if (mainFormulas[k].show_formula_condition) {
            if (xDataKeys && Array.isArray(xDataKeys) && xDataKeys.indexOf(k) === -1) xDataKeys.push(k)
        } else {
            if (yDataKeys && Array.isArray(yDataKeys) && yDataKeys.indexOf(k) === -1) yDataKeys.push(k)
        }
    });


    // console.log("column_meta_datav1", clone_sorted_data)
    return {
        data: clone_sorted_data,
        yDataKeys: yDataKeys,
        xDataKeys: xDataKeys,
        mainFormulas: mainFormulas,
        formula_column_meta_data: column_meta_data
    }
}


// const sorted_data = step_1_loop_through_rows_and_sort(data);

// const pivot_data = get_pivot_data(sorted_data, ['Sold To Party', 'Truck No.'],  [ { value: 'Net Value', pivot_aggregation: 'count' } ]);

const weightage = {
    // 'year': 21,
    // 'years': 21,
    // 'sales office': 20,
    // 'sales-office': 20,
    // 'sales_office': 20,
    // 'zone': 20,
    // 'quarter': 19.5,
    // 'qtr': 19.5,
    // 'month': 19,
    // 'months': 19,
    // 'week': 18,
    // 'weeks': 18,
    // 'customer': 17,
    // 'grade': 16,
};



// const get_column_info_plain_object = {

// }



export const s_p = (d) => {
    return JSON.parse(JSON.stringify(d))
}



const get_pivot_field_in_proper_case = (_v_) => {
    // if (_v_) return _v_.split('nf_').join(' ').split('_').join(' ').split(' ').map(v => v.substring(0, 1).toUpperCase() + v.substring(1, v.length)).join(' ').trim()
    if (_v_) return _v_.split('nf_').join(' ').split('_').join(' ').split(' ').map(v => proper_case(v)).join(' ').trim()
    else return undefined
}


const is_num = (data_type) => {
    if (data_type == "number") return true;
    else if (data_type == "currency") return true;
    else if (data_type == "number_with_decimal") return true;
    else if (data_type == "integer") return true;
    else return false;

}

const get_column_info_object = (column_name, pivot_order, pivot_type, axis, chart_type, prev_property) => {
    const column_info = {
        // id: generate_unique_key("column_info_id"),
        t_name: undefined,
        report_item_id: undefined,
        column_name: undefined,
        alias: column_name,
        pivot_field_column_name: column_name,
        pivot_field_alias: column_name,
        is_shade: undefined,
        pivot_field_order: pivot_order + 1,
        fe_pivot_type: pivot_type,
        is_repeater: undefined,
        axis: axis,
        chart_type: chart_type || 'bar_chart',
        ...prev_property,
        visible_type: (prev_property?.visible_type || 1),
        data_type: pivot_type === "pivot_y" ? (is_num(prev_property?.data_type) ? prev_property?.data_type : "number") : prev_property?.data_type,
        pivot_aggregation: prev_property?.pivot_aggregation ? prev_property.pivot_aggregation : 'sum',

    }

    return column_info;
}



const let_build_columns_info = (xacs = [], yacs = [], comparisons = [], use_pivot_type = true, prev_columns_info = [], chart_type) => {

    const columns_info = [];

    const check_this_is_already_exist = (name) => {
        for (let i = 0; i < prev_columns_info.length; i++) {
            const el = prev_columns_info[i];
            const alias_to_use = get_pivot_field_in_proper_case(el.alias)
            if (alias_to_use.toLocaleLowerCase() === name.toLocaleLowerCase()) return el;
        }
    }

    // push xac
    xacs.forEach((x, index) => {
        const is_exist = check_this_is_already_exist(x)
        columns_info.push(get_column_info_object(x, index, (use_pivot_type ? "pivot_x" : undefined), undefined, chart_type, is_exist))
    })

    // push yac
    yacs.forEach((y, index) => {
        const is_exist = check_this_is_already_exist(y)
        columns_info.push(get_column_info_object(y, index, (use_pivot_type ? "pivot_y" : undefined), undefined, chart_type, is_exist))
    })
    // push comparisons
    comparisons.forEach((c, index) => {
        const is_exist = check_this_is_already_exist(c)
        columns_info.push(get_column_info_object(c, index, (use_pivot_type ? "pivot_comparison" : undefined), undefined, chart_type, is_exist))
    })

    return columns_info;
}






const find_column_info_and_update = (columns_info, name, type, chart_type, axis, pivot_order) => {

    for (let i = 0; i < columns_info.length; i++) {

        const el = columns_info[i];

        const alias_to_use = el?.formula_type !== 'report_viewer' ? get_pivot_field_in_proper_case(el.alias) : el.alias;

        // console.log("alias_to_use", el.alias, alias_to_use, el?.formula_type)

        if (alias_to_use.toLocaleLowerCase() === name.toLocaleLowerCase()) {
            el.fe_pivot_type = type
            el.pivot_field_alias = alias_to_use
            el.pivot_field_column_name = alias_to_use
            el.axis = axis
            el.chart_type = chart_type
            if (pivot_order > -1) {
                el.pivot_field_order = pivot_order + 1
            }

        }
    }
}


const update_columns_info = (columns_info, xacs = [], yacs = [], comparisons = []) => {
    // push xac
    xacs.forEach((x, index) => {
        find_column_info_and_update(columns_info, x, 'pivot_x', undefined, undefined, index)
    })

    // push yac
    yacs.forEach((y, index) => {
        find_column_info_and_update(columns_info, y, 'pivot_y', undefined, undefined, index)
    })
    // push comparisons
    comparisons.forEach((c, index) => {
        find_column_info_and_update(columns_info, c, 'pivot_comparison', undefined, undefined, index)
    })
    return columns_info;
}


const format_week_quarter = (process_data, column_meta_data) => {

    const cloned_data = process_data ? [...process_data] : [];


    (cloned_data || []).map((data_row) => {

        if (data_row && Object.keys(data_row).length > 0) {

            Object.keys(data_row).forEach((s) => {

                const s_lower_case = s?.toLocaleLowerCase() || s;

                const data_type = column_meta_data?.[s]?.['data_type'] || 5;

                if ((s_lower_case === "quarter" || s_lower_case === "quarters" || s_lower_case === "qtr") && data_row[s] && !isNaN(data_row[s])) {

                    const ___v___ = data_row[s] //get_quarter_by_FY(data_row[s])

                    console.log("___v___", ___v___, data_row[s])

                    if (data_type === dataTypes.string) {
                        data_row[s] = 'Q' + ___v___//data_row['Quarter'];

                    } else {
                        data_row[s] = ___v___//data_row['Quarter'];

                    }
                }

                if (s_lower_case === "week" && !isNaN(data_row[s])) {
                    data_row[s] = 'Week ' + data_row[s];
                }
                if (s_lower_case === "hour" && !isNaN(data_row[s])) {
                    data_row[s] = 'Hour ' + data_row[s];
                }

                if (s_lower_case === "minute" && !isNaN(data_row[s])) {
                    data_row[s] = 'Minute ' + data_row[s];
                }
            })
        }
    })


    return cloned_data
}


export const get_final_process_data = (d, disable_sorting) => {


    const data = d ? JSON.parse(JSON.stringify(d)) : d;

    const type_of_query = data?.sql_query?.indexOf("RANK()") > -1 ? "rank" : "normal";

    const sort_fun_disabled = disable_sorting || type_of_query === "rank";

    const config_meta = data.config_meta;
    let columns_info = (config_meta?.column_infos || []).filter((c) => (c.alias !== '' && c.alias !== null));
    let columnMeta = data?.column_meta_data || {};

    const unformatted_process_data = data.clone_process_data || data.process_data;

    const formatted_process_data = format_week_quarter(unformatted_process_data, columnMeta)
    let process_data = formatted_process_data //data.clone_process_data || data.process_data;


    console_logger("GET FINAL PROCESS DATA INIT")

    const is_switch_row_column = config_meta?.is_switch_row_column

    const report_formula = ((data?.config_meta?.column_infos || []).filter((f => f?.is_formula)) || []).sort((a, b) => a.order - b.order);

    const report_editor_formula_v1 = [] //((report_formula || []).filter((f => f.formula_type === 'report_editor'))) || [];
    const report_viewer_formula_v1 = ((report_formula || []).filter((f => f.formula_type === 'report_viewer'))) || []

    // here we are giving the priority to builder formula
    const combind_formula_to_use_v1 = [...report_editor_formula_v1, ...report_viewer_formula_v1]

    const formula_to_use_in_raw_data = ((combind_formula_to_use_v1 || [])?.filter((f) => f.formula_applicable_to !== "pivot_data")) || [];
    const formula_to_use_in_pivot_data = ((combind_formula_to_use_v1 || [])?.filter((f) => f.formula_applicable_to === "pivot_data")) || [];

    const sort_order_values = config_meta?.sort_values || undefined;
    const be_chart_type = config_meta?.chart_type !== "table" ? config_meta?.chart_type : 'bar_chart';
    const comparisons = config_meta?.comparisons || [];

    /**
     * column report_user_column_preferance user for hide show column and filter over pivot data
     */
    const report_user_preferance = config_meta?.report_user_preferance || [];

    const raw_data_columns_preferance_pre = report_user_preferance?.filter((r) => r.preferance_name === 'user_column_preferance_raw_data');
    const raw_data_columns_preferance = raw_data_columns_preferance_pre?.length > 0 ? raw_data_columns_preferance_pre[raw_data_columns_preferance_pre.length - 1] : undefined;

    const report_user_column_preferance_pre = report_user_preferance?.filter((r) => r.preferance_name === 'user_column_preferance');
    const report_user_column_preferance = report_user_column_preferance_pre?.length > 0 ? report_user_column_preferance_pre[report_user_column_preferance_pre.length - 1] : undefined


    // let update column info
    columns_info?.length > 0 && columns_info.forEach(c => {
        // let change pivot_aggregation we will set it to default sum
        if (!c.pivot_aggregation) {
            c.pivot_aggregation = 'sum'
        }
    });

    let xac = config_meta && config_meta.xac || [];
    let yac = config_meta && config_meta.yac;

    if (xac) {
        xac.sort((a, b) => {
            const weightage_a = weightage[a.toLocaleLowerCase()] || 15;
            const weightage_b = weightage[b.toLocaleLowerCase()] || 15;

            return weightage_b - weightage_a;
        });
    }

    //console.log('brilliant 1.6: ', new Date());

    // switch_rows_columns
    // const switched_output = switch_rows_columns(process_data, xac, yac, columnMeta);

    // if (switched_output) {
    //     xac = switched_output.new_xac;
    //     yac = switched_output.new_yac;

    //     columnMeta = switched_output.new_column_meta_data
    //     process_data = switched_output.output_rows;
    //     data.process_data = process_data;
    // }

    /***
       * we have theree type of pivot axis 
       * 1. pivot_x
       * 2. pivot_y
       * 3. pivot_comparison
    */

    const pivot_x = (columns_info.filter((c) => c?.fe_pivot_type === "pivot_x") || []).sort((a, b) => a.pivot_field_order - b.pivot_field_order);
    const pivot_y = (columns_info.filter((c) => c?.fe_pivot_type === "pivot_y") || []).sort((a, b) => a.pivot_field_order - b.pivot_field_order);
    const pivot_comparison = (columns_info.filter((c) => c?.fe_pivot_type === "pivot_comparison") || []).sort((a, b) => a.pivot_field_order - b.pivot_field_order);

    const old_yacs = yac;

    console.log('brilliant 1.9: ', pivot_x, pivot_y, columns_info);

    let final_data = process_data;

    const yac_which_pushes_others_to_secondary_axis = {
        'total_amount_in_inr': 10,
        'premium': 10,
        'netpremium': 10,
        'gwp': 10,
        'grosspremium': 10,
        'total amount in inr': 10,
        'revenue': 10,
        'net_weight': 9,
        'net weight': 9,
        'weight': 9,
        'qty': 9,
        'quantity': 9,
        'transactions': 8,
        'transaction': 8,
        others: 4
    };

    const keys_to_filter = Object.keys(columnMeta).filter(l => columnMeta[l].data_type > 3).map(m => ({ name: m, order_by: 'asc' }));


    let sorted_data = sort_fun_disabled ? process_data : step_1_loop_through_rows_and_sort(process_data, columnMeta, keys_to_filter, 'a');

    sorted_data && covert_normal_keys_to_display_values(process_data, columnMeta);

    console_logger("RAW DATA SORTING DONE")


    if (sorted_data && sorted_data.length > 0) final_data = sorted_data;

    final_data.column_infos = final_data.column_infos || final_data.report_column_infos;

    let formula_column_meta_data = {};
    const pivot_formula_column_meta = {};

    const updated_yacs = get_updated_yacs_in_comparison_case(old_yacs, yac, data.comparison_keys);

    // console.log("==combind_formula_to_use_v1==", combind_formula_to_use_v1)
    // lets' create pivot formula column meta info;

    if (formula_to_use_in_pivot_data?.length > 0) {
        formula_to_use_in_pivot_data.forEach((formula) => {
            pivot_formula_column_meta[formula.alias] = {
                data_type: formula.data_type ? dataTypes[formula.data_type] : 1,
                display_value: formula.alias,
            };
        })
    }


    //console.log('brilliant 1.8: ', new Date());

    if (formula_to_use_in_raw_data && formula_to_use_in_raw_data.length > 0) {
        const formula_applied_data = apply_formula({ sortedData: sorted_data, yDataKeys: updated_yacs, xDataKeys: xac, report_formula: formula_to_use_in_raw_data, columnMeta })
        formula_column_meta_data = formula_applied_data.formula_column_meta_data;
        final_data = formula_applied_data.data;
        console.log('crackd: ', formula_to_use_in_raw_data);
        console_logger("FORMULA APPLIED OVER RAW DATA")

    }

    // console.log("final_data_1.1", s_p(final_data)?.length)

    // console.log("final_column_meta", formula_column_meta_data, columnMeta)
    const final_column_meta = { ...columnMeta, ...formula_column_meta_data, ...pivot_formula_column_meta };

    const final_yac = pivot_y.length > 0 ? pivot_y : ((updated_yacs && updated_yacs.length > 0) && updated_yacs.map((k) => ({ pivot_field_column_name: k, pivot_field_alias: k, pivot_aggregation: 'sum' })) || []);
    const raw_data_yac = yac && yac.length > 0 ? yac.map((k) => ({ pivot_field_column_name: k, pivot_field_alias: k, pivot_aggregation: 'sum' })) : []
    const ultimate_yacs = get_ultimate_yacs_along_with_alignments_and_formatting(raw_data_yac, xac, final_column_meta);
    let raw_yac_totals = get_totals_for_yacs(final_data, raw_data_yac, 'raw_data_total');


    const __pivot_data__ = {};
    let totals = undefined;

    // if we don't have column info then we will create column info whithout assign pivot type

    if (!columns_info || columns_info.filter((f) => !f?.is_formula).length === 0) {
        columns_info = let_build_columns_info(xac, updated_yacs, comparisons, false, columns_info, be_chart_type)
    }

    // we will calculate pivot either we have pivotX or pivotY
    if ((pivot_x.length > 0 || pivot_y.length > 0)) {

        // let's check if x or y is not exist in column info then insert that into column info
        // columns_info = let_build_columns_info(xac, updated_yacs, comparisons, false, columns_info, be_chart_type)

        const {
            pivot_data_grand_sum_total,
            pivot_data,
            xac_indexes,
            pivot_yac,
            pivot_x_axis,
            pivot_data_ultimate_yacs,
            yac_totals,
            pivot_columns_state,
            pivot_int_time,
            pivot_res_time,
            column_row_wise_data_types
        } = get_pivot_data(final_data, final_column_meta, pivot_x, final_yac, pivot_comparison, undefined, undefined, undefined, sort_order_values, be_chart_type, is_switch_row_column, report_user_column_preferance, report_formula, sort_fun_disabled);

        __pivot_data__.data = pivot_data
        __pivot_data__.pivot_data_axis = {
            xac: pivot_x_axis,
            yac: pivot_yac,
            pivot_data_columns: pivot_comparison
        };
        __pivot_data__.pivot_columns_state = pivot_columns_state;
        __pivot_data__.pivot_data_ultimate_yacs = pivot_data_ultimate_yacs
        __pivot_data__.prev_xac_indexes = xac_indexes;
        __pivot_data__.yac_totals = yac_totals;
        __pivot_data__.pivot_data_grand_sum_total = pivot_data_grand_sum_total;
        __pivot_data__.column_row_wise_data_types = column_row_wise_data_types;
        __pivot_data__.temp_info = {
            pivot_int_time: pivot_int_time,
            pivot_res_time: pivot_res_time
        }

        // lets update charttype of column info
        columns_info?.length > 0 && columns_info.forEach((c) => {
            if (c.fe_pivot_type === 'pivot_y' && !c.chart_type) {
                c.chart_type = be_chart_type || 'bar_chart'
            }
        })


    }


    /// this will create pivot data automatically  when we don't have any pivot data
    else if (xac && xac.length <= 2 && updated_yacs && updated_yacs.length > 0 && updated_yacs.length <= 20) {

        const x_axis_for_pivot = xac ? JSON.parse(JSON.stringify(xac)) : []
        comparisons.forEach((k) => xac.push(k))

        // we don't create comparisons automatically we will push comparisons into xDataKeys
        update_columns_info(columns_info, x_axis_for_pivot, updated_yacs, comparisons)

        const pivot_x = (columns_info.filter((c) => c?.fe_pivot_type === "pivot_x") || []).sort((a, b) => a.pivot_field_order - b.pivot_field_order);
        const pivot_y = (columns_info.filter((c) => c?.fe_pivot_type === "pivot_y") || []).sort((a, b) => a.pivot_field_order - b.pivot_field_order);
        const pivot_comparison = (columns_info.filter((c) => c?.fe_pivot_type === "pivot_comparison") || []).sort((a, b) => a.pivot_field_order - b.pivot_field_order);


        const {
            pivot_data_grand_sum_total,
            pivot_data, xac_indexes,
            pivot_yac,
            pivot_x_axis,
            yac_totals,
            pivot_int_time,
            pivot_res_time,
            pivot_columns_state,
            column_row_wise_data_types
        } = get_pivot_data(final_data, final_column_meta, pivot_x, pivot_y, pivot_comparison, undefined, undefined, undefined, sort_order_values, undefined, is_switch_row_column, report_user_column_preferance, report_formula, sort_fun_disabled);

        __pivot_data__.yac_totals = yac_totals
        __pivot_data__.pivot_columns_state = pivot_columns_state
        __pivot_data__.pivot_data_grand_sum_total = pivot_data_grand_sum_total;
        __pivot_data__.temp_info = {
            pivot_int_time: pivot_int_time,
            pivot_res_time: pivot_res_time
        }
        // console.log("auto_pivot_mode:")

        __pivot_data__.column_row_wise_data_types = column_row_wise_data_types;


        const ultimate_yacs_pivot = get_ultimate_yacs_along_with_alignments_and_formatting(pivot_yac, pivot_x, final_column_meta);

        // MA CODE
        // pivot_yac && pivot_yac.map(v => Object.assign(v, { visible_type: 1 }))

        const axises = Array(10).fill('primary');

        // lets find if xac can push others to secondary
        let prev_xac_weighage = -1;
        let xac_shifted = 'primary';

        yac.forEach((y, index) => {
            const lower_y = y.toLocaleLowerCase();
            if (prev_xac_weighage > -1) {
                const this_weightage = yac_which_pushes_others_to_secondary_axis[lower_y] || 4;
                if (this_weightage > prev_xac_weighage) {
                    // big weightage found after wards

                    axises[index] = 'secondary';

                    xac_shifted = 'secondary';
                }
                else if (this_weightage === prev_xac_weighage) {
                    axises[index] = (axises[index] - 1) || xac_shifted;
                }
                else if (this_weightage < prev_xac_weighage) {
                    axises[index] = xac_shifted === 'secondary' ? 'primary' : 'secondary';
                }
            }
            prev_xac_weighage = yac_which_pushes_others_to_secondary_axis[lower_y] || 4;
        });


        /** Vikas B 2nd March 2022
         * 
         * if we have two yac and the values between yacs are huge, then lets just shift the yacs to secondary
         * 
         */

        let axis_same = true;
        let prev_axis = undefined;

        final_yac.forEach((y, index) => {
            if (index > 0) {
                axis_same = axis_same && axises[index] === prev_axis
            }

            prev_axis = axises[index];
        }, true);


        __pivot_data__.data = pivot_data
        __pivot_data__.pivot_data_axis = {
            xac: pivot_x,
            yac: pivot_yac.map((y, index) => {
                find_column_info_and_update(columns_info, y?.pivot_field_column_name, 'pivot_y', be_chart_type, axises[index])
                return {
                    ...y,
                    field_order: index,
                    chart_type: be_chart_type,
                    axis: axises[index],
                };
            }),
            pivot_data_columns: pivot_comparison
        };

        __pivot_data__.pivot_data_ultimate_yacs = ultimate_yacs_pivot
        __pivot_data__.prev_xac_indexes = xac_indexes;

    }


    //console.log('brilliant 1.10: ', new Date());
    console.log("GetReport PERFORMANCE ===> Step , Response Received", '=====>', new Date().toLocaleTimeString());

    const raw_data_column_state = get_column_info_state(xac, yac, raw_data_columns_preferance)

    // let raw_data_grand_sum_total = get_grand_total_with_formula(raw_yac_totals, updated_yacs, xac, report_formula, columnMeta)

    // console.log("final_column_meta", final_column_meta, ultimate_yacs)

    console_logger("FINAL PROCCESS DATA RETURN SUCCESSFUL")

    // console.log("hack1.1", s_p(__pivot_data__))

    return {
        sorted_data: final_data,
        raw_data_column_state: raw_data_column_state,
        yac: yac,
        xac: xac,
        ultimate_yacs: ultimate_yacs,
        pivot_data: __pivot_data__,
        column_meta_data: final_column_meta,
        columns_info: columns_info,
        // raw_data_grand_sum_total: raw_data_grand_sum_total
    };
};




const get_data_type_by_aggregation = (agg) => {
    switch (agg) {
        case "percentage":
        case "perc_diff":
        case "percentage_cwise":
            return dataTypes.percent;
        case "sum":
            return dataTypes.number;
        case "avg":
            return dataTypes.number;
        case "count":
            return dataTypes.number;
        case "diff":
            return dataTypes.number;
        case "cumulative":
            return dataTypes.number;
        default:
            return dataTypes.string
    }
}


export const get_grand_total_with_formula = (raw_yac_totals, yData, xData, report_formula, columnMeta) => {

    const report_editor_formula_v1 = ((report_formula || []).filter((f => f.formula_type === 'report_editor'))) || [];
    const report_viewer_formula_v1 = ((report_formula || []).filter((f => f.formula_type === 'report_viewer'))) || []

    // here we are giving the priority to builder formula
    const combind_formula_to_use_v1 = [...report_editor_formula_v1, ...report_viewer_formula_v1];

    const yDataKeys = yData && JSON.parse(JSON.stringify(yData)) || [];
    const xDataKeys = xData && JSON.parse(JSON.stringify(xData)) || [];

    let raw_data_grand_sum_total = [];
    const pre_raw_data_yac_grand_total = [];
    raw_yac_totals && Object.keys(raw_yac_totals).forEach(key => {
        if (pre_raw_data_yac_grand_total.length == 0) pre_raw_data_yac_grand_total.push({})
        if (!pre_raw_data_yac_grand_total[0][key]) pre_raw_data_yac_grand_total[0][key] = raw_yac_totals[key]
    })

    raw_data_grand_sum_total = pre_raw_data_yac_grand_total;

    if (combind_formula_to_use_v1 && combind_formula_to_use_v1.length > 0) {
        const formula_applied_data = apply_formula({ sortedData: raw_data_grand_sum_total, yDataKeys: yDataKeys, xDataKeys: xDataKeys, report_formula: combind_formula_to_use_v1, columnMeta })
        // formula_column_meta_data = formula_applied_data.formula_column_meta_data;
        raw_data_grand_sum_total = formula_applied_data.data;
    }

    return raw_data_grand_sum_total;
}



const get_valid_data_type_for_y_axis = (data_type) => {

    let d = data_type;
    if (!data_type) d = dataTypes.number;

    if (data_type !== dataTypes.number && data_type !== dataTypes.currency && data_type !== dataTypes.percent && data_type !== dataTypes.number_with_decimal) {
        d = dataTypes.number
    }
    return d;
}


// const get_valid_data_type_for_x_axis = (data_type) => {

//     let d = data_type;

//     if (!data_type) d = dataTypes.string;
//     if (data_type !== dataTypes.date && data_type !== dataTypes.date_month && data_type !== dataTypes.date_time) {
//         d = dataTypes.number
//     }
//     return d;
// }

const get_ultimate_yacs_along_with_alignments_and_formatting = (yacs, xacs, column_meta_data) => {

    const meta_with_display_values = {};
    column_meta_data && Object.keys(column_meta_data).forEach(meta_key => {
        meta_with_display_values[column_meta_data[meta_key].display_value] = column_meta_data[meta_key];
    });


    const ultimate_yacs = {};



    /**
     * we wrote this code for x axis formating calculating
     * by- saroj KUMAR
     * 24 jan 2022
     */

    xacs && xacs.length > 0 && xacs.forEach(_x_ => {

        let xac = _x_;

        if (typeof xac === 'object') xac = _x_?.pivot_field_column_name;
        const yac_to_use = (xac && xac.indexOf('_nfx_') > -1) ? xac.split('_nfx_')[1] : xac;
        const meta_data = (column_meta_data && column_meta_data[yac_to_use]) || meta_with_display_values[yac_to_use];
        const alignment = alignments_config && alignments_config[meta_data && meta_data.data_type];

        ultimate_yacs[xac] = {
            type: meta_data?.data_type || dataTypes.string,
            alignment: (alignment && alignment.alignment) || 'left',
            hide_from_grand_total: true
        }
    });


    yacs.forEach(yac => {

        const display_key = yac.pivot_field_alias;
        const yac_column_name = yac?.pivot_field_column_name;
        const yac_to_use = (yac_column_name && yac_column_name.indexOf('_nfx_') > -1) ? yac_column_name.split('_nfx_')[yac_column_name.split('_nfx_').length - 1] : yac_column_name;
        const data_type = yac?.data_type;
        const data_type_number = data_type && dataTypes?.[data_type];

        const pivot_aggregation = yac.pivot_aggregation;

        const _data_type = get_data_type_by_aggregation(pivot_aggregation, data_type);

        const meta_data = (column_meta_data && column_meta_data[yac_to_use]) || meta_with_display_values[yac_to_use];

        const use_data_type_for_total = display_key.indexOf("Total_nfx_") > -1 && meta_data?.data_type

        if (pivot_aggregation === 'sum' || pivot_aggregation === 'avg' || pivot_aggregation === 'diff' || pivot_aggregation === 'cumulative') {

            // console.log("meta_data", yac, meta_data)
            // const alignment = alignments_config && alignments_config[meta_data && meta_data.data_type];
            ultimate_yacs[display_key] = {
                type: get_valid_data_type_for_y_axis(((meta_data && meta_data.data_type) || data_type_number || dataTypes.number)),
                alignment: 'right',
                hide_from_grand_total: pivot_aggregation === 'avg' ? true : false
            }
        } else {
            // const alignment = alignments_config && alignments_config[_data_type];
            ultimate_yacs[display_key] = {
                type: get_valid_data_type_for_y_axis(use_data_type_for_total || _data_type),
                alignment: 'right',
                hide_from_grand_total: pivot_aggregation === 'percentage' ? true : false
            }
        }
    });
    return ultimate_yacs;
};



const get_updated_yacs_in_comparison_case = (original_yacs, new_yacs, comparison_keys) => {

    if (comparison_keys && comparison_keys.length > 0) {
        const only_new_yacs = new_yacs.filter(f => original_yacs.indexOf(f) === -1);
        return [
            ...only_new_yacs,
            ...comparison_keys
        ];
    }
    else {
        return new_yacs;
    }

};


// const final_pivot_data = get_pivot_data(pivot_data, []);

// require('fs').writeFileSync('./pivot.json', JSON.stringify(pivot_data));




export const return_formula_with_updated_data = (payload, data_from_server, tbl_formulas) => {

    /***
     * this function will return the actual formula name 
     * from formula array by formula id
     */


    const get_prev_formula_column_name = (id, f_array) => {
        let column_name = undefined;
        if (f_array && f_array.length > 0) {
            f_array.forEach((f) => {
                if (f.id === id) column_name = f.name
            })
        }
        return column_name;
    }

    const payload_data = payload ? { ...payload } : {};
    const payload_formula = payload_data.formula;
    const report_id = payload_data && payload_data.report_id;

    const clone_data_from_server = data_from_server ? JSON.parse(JSON.stringify(data_from_server)) : {};
    const target_report = clone_data_from_server && clone_data_from_server[report_id] && clone_data_from_server[report_id].data;

    const prev_formulas = tbl_formulas ? JSON.parse(JSON.stringify(tbl_formulas)) : {};
    const current_report_formula = prev_formulas && prev_formulas[report_id];

    const edited_formula = payload_formula && payload_formula.length > 0 && payload_formula.filter((f) => (f.modified && !f.deleted));
    const deleted_formula_columns = [];

    payload_formula && payload_formula.length > 0 && payload_formula.map((f => {
        const c = get_prev_formula_column_name(f.id, current_report_formula)
        if (f.deleted && f.id) {
            deleted_formula_columns.push(c)
        }
        if (f.modified && !f.deleted && f.id) {
            if (c) deleted_formula_columns.push(c)
        }
    }))
    // 

    if (target_report && target_report) {

        const process_data = target_report.clone_process_data || target_report.process_data;
        const pre_xac = target_report.config_meta.xac;
        const pre_yac = target_report.config_meta.yac;

        const formula_applied_data = apply_formula(
            {
                sortedData: process_data,
                yDataKeys: pre_yac,
                report_formula: edited_formula,
                xDataKeys: pre_xac,
                delete_key: deleted_formula_columns
            });

        if (formula_applied_data) {
            target_report.process_data = formula_applied_data?.data || (target_report.clone_process_data || target_report.process_data);
            target_report.clone_process_data = formula_applied_data?.data || (target_report.clone_process_data || target_report.process_data);
            if (target_report.config_meta) {
                target_report.config_meta.xac = formula_applied_data?.xDataKeys || target_report.config_meta.xac;
                target_report.config_meta.yac = formula_applied_data?.yDataKeys || target_report.config_meta.yac;
                target_report.raw_data_column_state = get_column_info_state(target_report.config_meta.yac, target_report.config_meta.xac)
            }

            const post_yac = target_report?.config_meta?.yac || [];
            const post_xac = target_report?.config_meta?.xac || [];
            const raw_data_yac_formatted = post_yac && post_yac.length > 0 ? post_yac.map((k) => ({ pivot_field_column_name: k, pivot_field_alias: k, pivot_aggregation: 'sum' })) : []

            const formula_column_meta_data = formula_applied_data?.formula_column_meta_data || {};
            const report_pre_column_meta = target_report?.column_meta_data || {};

            const report_post_column_meta = { ...report_pre_column_meta, ...formula_column_meta_data }
            const ultimate_yacs = get_ultimate_yacs_along_with_alignments_and_formatting(raw_data_yac_formatted, post_xac, report_post_column_meta);


            target_report.ultimate_yacs = ultimate_yacs || target_report.ultimate_yacs;
            target_report.column_meta_data = report_post_column_meta?.report_post_column_meta || target_report.column_meta_data;


            /***
             *   ***
             *   *** here we will update the pivot data
             *   *** 
             * *********
             */


            const pivot_axis = target_report.pivot_data_axis;
            const pre_pivot_x_axis = pivot_axis?.xac || [];
            const pre_pivot_y_axis = pivot_axis?.yac || [];
            const pre_pivot_columns = pivot_axis?.pivot_data_columns || [];

            const new_x_axis = formula_applied_data?.xDataKeys
            const new_y_axis = formula_applied_data?.yDataKeys;

            const __pivot__data = get_pivot_data(
                target_report.process_data,
                target_report.column_meta_data,
                pre_pivot_x_axis,
                pre_pivot_y_axis,
                pre_pivot_columns,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                edited_formula,
            );

            target_report.pivot_data = __pivot__data?.pivot_data || undefined;
            target_report.pivot_data_axis = {
                xac: pre_pivot_x_axis,
                yac: __pivot__data.pivot_yac ? __pivot__data.pivot_yac : pre_pivot_y_axis,
                pivot_data_columns: pre_pivot_columns
            };

            target_report.pivot_data_ultimate_yacs = __pivot__data?.pivot_data_ultimate_yacs || undefined;
            target_report.prev_xac_indexes = __pivot__data?.xac_indexes;
        }
    }

    clone_data_from_server[report_id].data = target_report;
    return clone_data_from_server;
};


export const stringify_and_parse_json = obj => {
    return obj && JSON.parse(JSON.stringify(obj));
}



/**
 * 
 * @param {*} filters 
 * @returns 
 */
export const check_filter_exist = (filters) => {

    let is_exist = false;

    const clone_filters = filters ? JSON.parse(JSON.stringify(filters)) : {};
    const filter_keys = clone_filters && Object.keys(clone_filters) || [];

    for (let index = 0; index < filter_keys.length; index++) {
        const key = filter_keys[index];
        if (key === "date_filter") {
            const date_filters = clone_filters["date_filter"];
            if (date_filters && Object.keys(date_filters).length > 0) {
                for (let j = 0; j < Object.keys(date_filters).length; j++) {
                    const date_filter_key = Object.keys(date_filters)[j];
                    is_exist = true;
                    break;
                }
            }
        }
        if (clone_filters[key] && Array.isArray(clone_filters[key]) && clone_filters[key].length > 0) {
            is_exist = true;
            break;
        }
    }

    return is_exist;
}




const is_mt_value = (value, comp_value) => {


    if (
        typeof value === "undefined" ||
        value === "null" ||
        value === "undefined" ||
        value === null ||
        value === "__nfx_null__" ||
        value === "-"
    ) {

        return true;
    }
}


function isDateBetween(checkDate, startDate, endDate) {
    checkDate = new Date(checkDate);
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    return checkDate >= startDate && checkDate <= endDate;
}

export const check_is_condition_matched = (condition, compare_value, compare_from = '', compare_from2 = '', index, formula_regx_data_type) => {


    const is_equal_to_v1 = (comp_to_value, comp_value) => {


        if (is_mt_value(compare_value, comp_value) && compare_from === "__nfx_null__") return true;
        else if (isNaN(comp_value) && (comp_to_value && comp_to_value.length > 0 && comp_to_value.trim().toLocaleLowerCase()) === (comp_value && comp_value.trim().toLocaleLowerCase())) return true;
        else if (parseFloat(comp_to_value) === parseFloat(comp_value)) return true;

        else return undefined;
    }

    const is_not_equal_to_v1 = (comp_to_value, comp_value) => {
        if (isNaN(comp_value) && (comp_to_value && comp_to_value.length > 0 && comp_to_value.trim().toLocaleLowerCase()) === (comp_value && comp_value.trim().toLocaleLowerCase())) {
            return false;
        }
        else if (isNaN(comp_value) && (comp_to_value && comp_to_value.length > 0 && comp_to_value.trim().toLocaleLowerCase()) !== (comp_value && comp_value.trim().toLocaleLowerCase())) return true;
        else if (parseFloat(comp_to_value) !== parseFloat(comp_value)) return true
        else return undefined;
    }


    if (condition) {

        // if condition then match the both value

        const all_conditions_keys = Object.keys(CONDITIONS);

        for (let index = 0; index < all_conditions_keys.length; index++) {

            const element = CONDITIONS[all_conditions_keys[index]];
            const key = element.VALUE_KEY;

            const data_type_of_condition = element.DATA_TYPE;

            // console.log("data_type_of_condition", data_type_of_condition, key, element, compare_value, compare_from)
            // console.log("data_type_of_condition", formula_regx_data_type, compare_value, compare_from, compare_from2)
            // console.log("i am here", key, condition, compare_from, compare_value, compare_from2, data_type_of_condition)


            if (key === condition) {
                // if condition match then go ahead 
                if (data_type_of_condition === 'string') {
                    // only string comparisons

                    if (key === CONDITIONS.START_WITH.VALUE_KEY && compare_value && isNaN(compare_value) && compare_value.indexOf(compare_from) === 0) return key;
                    else if (key === CONDITIONS.DOES_NOT_CONTAIN.VALUE_KEY && compare_value && isNaN(compare_value) && compare_value.indexOf(compare_from) === -1) return key;
                    else if (key === CONDITIONS.CONTAINS.VALUE_KEY && compare_value && isNaN(compare_value) && compare_value.indexOf(compare_from) > -1) return key;
                    else if (key === CONDITIONS.END_WITH.VALUE_KEY && compare_value && isNaN(compare_value) && compare_value.trim().toLowerCase().endsWith(compare_from.trim().toLowerCase())) {
                        return key;
                    }
                    else return undefined;
                }
                if (data_type_of_condition === 'number') {
                    // only number comparisons                    
                    // formula_regx_data_type

                    // if()

                    if (key === CONDITIONS.BETWEEN.VALUE_KEY) {
                        // let's check between date
                        if (formula_regx_data_type === "date" && isDateBetween(compare_value, compare_from, compare_from2)) return key;
                        // let's check between condition 
                        else if (parseFloat(compare_value) > parseFloat(compare_from) && parseFloat(compare_value) < parseFloat(compare_from2)) return key;
                    }

                    else if (formula_regx_data_type === "date" && key === CONDITIONS.LESS_THEN.VALUE_KEY && new Date(compare_value) < new Date(compare_from)) return key;
                    else if (formula_regx_data_type === "date" && key === CONDITIONS.LESS_THEN_OR_EQUAL_TO.VALUE_KEY && new Date(compare_value) <= new Date(compare_from)) return key;
                    else if (formula_regx_data_type === "date" && key === CONDITIONS.GREATER_THAN.VALUE_KEY && new Date(compare_value) > new Date(compare_from)) return key;
                    else if (formula_regx_data_type === "date" && key === CONDITIONS.GREATER_THAN_OR_EQUAL_TO.VALUE_KEY && new Date(compare_value) >= new Date(compare_from)) return key;


                    else if (key === CONDITIONS.LESS_THEN.VALUE_KEY && parseFloat(compare_value) < parseFloat(compare_from)) return key;
                    else if (key === CONDITIONS.LESS_THEN_OR_EQUAL_TO.VALUE_KEY && parseFloat(compare_value) <= parseFloat(compare_from)) return key;
                    else if (key === CONDITIONS.GREATER_THAN.VALUE_KEY && parseFloat(compare_value) > parseFloat(compare_from)) return key;
                    else if (key === CONDITIONS.GREATER_THAN_OR_EQUAL_TO.VALUE_KEY && parseFloat(compare_value) >= parseFloat(compare_from)) return key;

                    else return undefined;
                }

                if (data_type_of_condition === 'all') {
                    // console.log("ALL1.1", condition, compare_value, compare_from, is_not_equal_to_v1("ipd", "IPD"))
                    // number and string both comparisons
                    if (key === CONDITIONS.EQUAL_TO.VALUE_KEY && is_equal_to_v1(compare_value, compare_from)) return key;
                    else if (key === CONDITIONS.NOT_EQUAL.VALUE_KEY && is_not_equal_to_v1(compare_value, compare_from)) return key;
                    else return undefined;
                }
                break;
            }
        }
    }
}



export const apply_case_on_data = (sorted_data, column_name, __case__, data_type) => {

    if (data_type === dataTypes.string && __case__) {
        sorted_data.forEach((row) => {
            if (row[column_name]) {
                const v = row[column_name];
                // let's change their value by case
                if (__case__ === 'upper_case') {
                    row[column_name] = v.toString().toUpperCase();
                }
                else if (__case__ === 'lower_case') {
                    row[column_name] = v.toString().toLocaleLowerCase();
                }
                else if (__case__ === 'proper_case') {
                    row[column_name] = v.toString().replace(
                        /\w\S*/g,
                        function (txt) {
                            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                        }
                    );
                }
            }
        })
    }

}

/**
 * 
 * @param {*} clone_sorted_data 
 * @param {*} column_name 
 * @param {*} filter 
 * @param {*} data_type 
 * @returns 
 */

export const filter_table_data_V1 = (clone_sorted_data, column_name, filter, _data_type) => {


    let data_type = _data_type;

    if(column_name?.toLocaleLowerCase() === 'month' || column_name?.toLocaleLowerCase() === 'week' || column_name?.toLocaleLowerCase() === 'year'){
        data_type = dataTypes.string;
    }
    const new_data = clone_sorted_data?.filter((row) => {

        const column_value = row[column_name];
        const filter_type = filter?.filter_type;
        const formula_value = filter?.formula_value;
        const formula_value2 = filter?.formula_value2;

        const single_date = is_date(filter?.single_date) ? new Date(filter?.single_date) : undefined;
        const start_date = is_date(filter?.start_date) ? new Date(filter?.start_date) : undefined;
        const end_date = is_date(filter?.end_date) ? new Date(filter?.end_date) : undefined;

        console.log("column_value", column_name, column_value    )
        // string formula here
        if (data_type === dataTypes.string) {

            if (filter_type === 'equal_to') {
                if (column_value?.toString()?.toLocaleLowerCase().trim() === formula_value?.toString()?.toLocaleLowerCase().trim()) return true
            }
            else if (filter_type === 'not_equal_to') {
                if (column_value?.toString()?.toLocaleLowerCase().trim() !== formula_value?.toString()?.toLocaleLowerCase().trim()) return true
            }
            else if (filter_type === 'contains') {
                if (column_value?.toString()?.toLocaleLowerCase().trim().indexOf(formula_value?.toString()?.toLocaleLowerCase().trim()) > -1) return true
            }
            else if (filter_type === 'not_contains') {
                if (column_value?.toString()?.toLocaleLowerCase().trim().indexOf(formula_value?.toString()?.toLocaleLowerCase().trim()) === -1) return true
            }
            else if (filter_type === 'start_with') {
                if (column_value?.toString()?.toLocaleLowerCase().trim().startsWith(formula_value?.toString()?.toLocaleLowerCase().trim())) return true

            }
            else if (filter_type === 'not_start_with') {
                if (column_value?.toString()?.toLocaleLowerCase().trim().startsWith(formula_value?.toString()?.toLocaleLowerCase().trim()) === false) return true

            }
            else if (filter_type === 'end_with') {
                if (column_value?.toString()?.toLocaleLowerCase().trim().endsWith(formula_value?.toString()?.toLocaleLowerCase().trim())) return true
            }
            else if (filter_type === 'not_end_with') {
                if (column_value?.toString()?.toLocaleLowerCase().trim().endsWith(formula_value?.toString()?.toLocaleLowerCase().trim()) === false) return true
            }
            else return true;
        }

        if (data_type === dataTypes.number || data_type === dataTypes.currency || data_type === dataTypes.percent) {

            if (filter_type === 'less_than') {
                if (parseFloat(column_value) < parseFloat(formula_value)) return true
            }
            else if (filter_type === 'less_than_equal_to') {
                if (parseFloat(column_value) <= parseFloat(formula_value)) return true
            }
            else if (filter_type === 'greater_than') {
                if (parseFloat(column_value) > parseFloat(formula_value)) return true
            }
            else if (filter_type === 'greater_than_equal_to') {
                if (parseFloat(column_value) >= parseFloat(formula_value)) return true

            }
            else if (filter_type === 'equal_to') {
                if (parseFloat(column_value) === parseFloat(formula_value)) return true

            }
            else if (filter_type === 'not_equal_to') {
                if (parseFloat(column_value) !== parseFloat(formula_value)) return true

            }
            else if (filter_type === 'between') {
                if (parseFloat(formula_value) <= parseFloat(column_value) && parseFloat(formula_value2) >= parseFloat(column_value)) return true

            }
            else return true;

        }
        if (data_type === dataTypes.date || data_type === dataTypes.date_time) {

            // do date calculations here
            var dateParts = column_value?.split("/");

            // month is 0-based, that's why we need dataParts[1] - 1
            // const column_value_in_date = new Date(+dateParts?.[2], dateParts?.[1] - 1, +dateParts?.[0]);
            const column_value_in_date = new Date(column_value);

            // here we are ignoring the time
            if (single_date) single_date.setHours(0, 0, 0, 0);
            if (column_value_in_date) column_value_in_date.setHours(0, 0, 0, 0);
            if (start_date) start_date.setHours(0, 0, 0, 0);
            if (end_date) start_date.setHours(0, 0, 0, 0);

            if (filter_type === 'single') {
                if (column_value_in_date.getTime() === single_date.getTime()) return true;
                else return false
            }

            if (filter_type === 'between') {
                if (column_value_in_date >= start_date && column_value_in_date <= end_date) return true;
                else return false
            }
        }
    })

    return new_data;
}



export const apply_filter_on_data = (sorted_data, columns_info) => {


    // console.log("columns_info", columns_info)
    let clone_sorted_data = sorted_data ? JSON.parse(JSON.stringify(sorted_data)) : [];
    const filter_order_info = columns_info?.['other_info'];
    const applied_filter_keys_in_order = filter_order_info?.["applied_filter_keys"];

    if (applied_filter_keys_in_order && applied_filter_keys_in_order.length > 0) {
        applied_filter_keys_in_order.forEach((column_key) => {

            if (columns_info[column_key]) {
                const filter = columns_info[column_key]?.filter
                const data_type = columns_info[column_key]?.data_type;
                if (filter?.filter_type) {
                    clone_sorted_data = filter_table_data_V1(clone_sorted_data, column_key, filter, data_type)
                }

            }
        })
    }

    if (clone_sorted_data && clone_sorted_data.length == 0) {
        clone_sorted_data[0] = {
            key: "No Values Found"
        }
    }
    return clone_sorted_data;
}



export const calculate_total_for_row = (data, columns, __yac__) => {

    const all_data_keys = columns && Object.keys(columns)?.length > 0 && Object.keys(columns).filter((k => columns[k]["show"] === true));
    const final_keys_to_use = ((all_data_keys || [])?.filter((k => k.indexOf('% Share_nfx_') == -1 && k.indexOf('Total_nfx_') == -1))) || [];
    const total_field_name = all_data_keys?.find((k) => k.indexOf("Total_nfx_") > -1);


    const need_to_calculate_total_for_row = __yac__?.length > 0 && get_data_type_by_aggregation(__yac__[0].pivot_aggregation) !== dataTypes.percent

    total_field_name && need_to_calculate_total_for_row && data && data.length > 0 && data.forEach((row) => {

        let totals = {};

        final_keys_to_use?.length > 0 && final_keys_to_use.forEach((key) => {

            let total_key = key && key.split("_nfx_").length > 1 && key.split("_nfx_")[1];
            let total_key_to_use = "Total_nfx_" + total_key;

            if (total_key && row[key] && typeof row[key] === 'number') {
                if (!totals[total_key_to_use]) totals[total_key_to_use] = 0;
                totals[total_key_to_use] += row[key]

            }
        })

        if (totals && Object.keys(totals).length > 0) {
            Object.keys(totals).forEach(key => {
                // if (row[key]) row[key] = totals[key]
            })
        }
    })
}




export const get_updated_yacs_total = (pivot_data, yac, columns_info) => {


    let pivot_yac = yac && yac?.length > 0 && yac.filter((v) => (v.visible_type !== 0) && (columns_info?.[v.pivot_field_alias]?.['show'] === true))

    // here we are calculating yac_totals based on pivot data

    let pivot_yac_totals = get_totals_for_yacs(pivot_data, pivot_yac, 'pivot_t_total');

    let yac_totals_to_use = {};

    pivot_yac_totals && Object.keys(pivot_yac_totals)?.length > 0 && Object.keys(pivot_yac_totals).forEach((key) => {
        if (!yac_totals_to_use[key]) yac_totals_to_use[key] = {};
        // lets loop all those keys like min max avg
        if (Object.keys(pivot_yac_totals[key])?.length > 0) {
            Object.keys(pivot_yac_totals[key]).forEach(k => {
                yac_totals_to_use[key][k] = pivot_yac_totals[key][k];
                // let's add agg for chart min max
                if (pivot_yac_totals[key][k]) yac_totals_to_use[key][`agg_${k}`] = pivot_yac_totals[key][k];
            })
        }
    })

    return yac_totals_to_use
}



export const apply_everything_on_pivot_data = (current_report_data_to_use, column_info) => {

    const pivot_data_clone = current_report_data_to_use?.pivot_data?.clone_data || current_report_data_to_use?.pivot_data?.data;

    const __yac__ = current_report_data_to_use?.pivot_data?.pivot_data_axis?.yac;

    if (column_info && Object.keys(column_info).length > 0) {
        Object.keys(column_info).forEach((k) => {
            if (k !== 'other_info' && column_info[k]?.data_type === dataTypes.string && column_info[k].case) {
                apply_case_on_data(pivot_data_clone, k, column_info[k].case, column_info[k].data_type);
            }
        })
    }


    const new_pivot_data = apply_filter_on_data(pivot_data_clone, column_info);

    calculate_total_for_row(new_pivot_data, column_info, __yac__);

    const new_yac_totals = __yac__.length > 0 ? get_updated_yacs_total(new_pivot_data, __yac__, column_info) : {}

    if (new_yac_totals && Object.keys(new_yac_totals).length > 0) {
        current_report_data_to_use.yac_totals = new_yac_totals;
    }
    if (!current_report_data_to_use?.pivot_data?.clone_data) {
        current_report_data_to_use.pivot_data.clone_data = pivot_data_clone
    }
    if (new_pivot_data) {
        current_report_data_to_use.pivot_data.data = new_pivot_data;
    }
    if (pivot_data_clone && pivot_data_clone.length > 1 && column_info?.['other_info']?.['applied_filter_keys']?.length > 0) {
        current_report_data_to_use.disabled_to_call_single_cell = true;
    }
    // console.log("current_report_data_to_use",current_report_data_to_use)
    return current_report_data_to_use;
}



export const apply_everything_on_raw_data = (current_report_data_to_use, column_info) => {

    const process_data = current_report_data_to_use?.raw_data?.clone_data || current_report_data_to_use?.raw_data?.data;


    // console.log("column_infov1", JSON.parse(JSON.stringify(column_info)))
    if (column_info && Object.keys(column_info).length > 0) {
        Object.keys(column_info).forEach((k) => {
            if (k !== 'other_info' && column_info[k]?.data_type === dataTypes.string && column_info[k].case) {
                apply_case_on_data(process_data, k, column_info[k].case, column_info[k].data_type);
            }
        })
    }

    const new_processed_data = apply_filter_on_data(process_data, column_info);

    if (!current_report_data_to_use?.raw_data?.clone_data) {
        current_report_data_to_use.raw_data.clone_data = process_data
    }
    if (new_processed_data) {
        current_report_data_to_use.raw_data.data = new_processed_data;
    }

    if (new_processed_data && process_data.length > 1 && column_info?.['other_info']?.['applied_filter_keys']?.length > 0) {
        current_report_data_to_use.disabled_to_call_single_cell = true;
    }
    // if (disabled_to_call_single_cell && new_processed_data) current_report_data_to_use.disabled_to_call_single_cell = disabled_to_call_single_cell;
    return current_report_data_to_use;
}