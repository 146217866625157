import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import FormulaCreator from '../formula-creator/mini.formula';
import { CheckBox } from '../hoc/checkbox';
import RippleEffect from '../hoc/ripple-effect';
import Delete from '../svg/delete';
import { Button } from '../hoc/button'
import { constants } from '../../utils/constants'
import * as dataTypes from '../../shared-with-fe/data.types';
import Tab from '../tab'
import CustomDatePicker from '../hoc/date-time-piker/index'
import CloseSvg from '../svg/close';
import InputBoxSuggestion from '../hoc/inputWithSUgestion';


const { CONDITIONS } = constants



export const AutoComplete = styled.div`
    position: absolute;
    top: 100%;
    left: -2px;
    max-height: 8rem;
    overflow-y: auto;
    box-sizing: border-box;
    /* box-shadow: 0px 0px 4px #ccc; */
    margin-top: 1px;
    /* background-color: #fff; */
    z-index: 2;


    &::-webkit-scrollbar {
        background-color:red;
        width:2px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 2px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 2px;
    }

    .word{
        box-sizing: border-box;
        font-size: .8rem;
        color: #555;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        /* &:hover{
            background-color: #eeeeee;
            color: #222;
            cursor: pointer;
        } */

        span{
            display: inline-block;
            padding: ${props => props.gap ? "7px 1.5rem" : '7px 8px'};
        }
    }

    .prev_word{
        opacity: 0;
        padding: 0px !important;
    }
    .current_word{
        background-color: #ffffff;
        cursor: pointer;
        &:hover{
            background-color: #eeeeee;
            color: #222;
            cursor: pointer;
        }
        
    }
    .selected{
        background-color: #eeeeee;
        color: #222;
    }

`;


export const Wrapper = styled.div`
    min-height: 35rem;
    max-height: 42rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 55rem;
    border-radius: 20px;
    padding: 1.5rem;
    box-sizing: border-box;

    .header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        margin-bottom: 1rem;
        position: relative;

        h1{
            font-size: 1.5rem;
            font-weight: 500;
            margin: 0px;
            font-weight: bold;
            color: #2173F7;
            width: 80%;
            /* text-align: center; */

        }

        .close_btn{
            cursor: pointer;
            width: 1rem;
            height: 1rem;
            position: absolute;
            right: 10px;
            top: -.5rem;
            svg{
                fill: #c85149; 
            }
            :hover{
                fill: '#e71b0e'
            }
        }
    }

    

    ._label_{
        width: 30%; 
        font-size: 0.9rem;
        font-weight: 600;
        color: #333;
        sup{
            color: red;
            font-size: 1rem;
        }
    }

    ._row{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 1rem;

        .input{
            font-size: 1rem;
            width: 100%;
            height: 2.3rem;
            box-sizing: border-box;
            background-color: #F0F3FF;
            border: 0px;;
            padding: 10px 10px;
            border-radius: 6px;
            border: 1px solid #e2e2e2;

            &:focus{
                outline: none;
            }
        }

        .option{
            font-size: 1rem;
            width: 100%;
            height: 2.3rem;
            box-sizing: border-box;
            background-color: #F0F3FF;
            border: 0px;;
            padding: 0px 10px;
            border-radius: 6px;
            border: 1px solid #e2e2e2;
        }
    }

    .formula_checkbox{
        display: flex;
        width: 70%;
        font-size: .8rem;
        color: #222;
        align-items: center;
        justify-content: flex-start;
        padding: 5px;
        /* margin-left: 19%; */
        text-transform: uppercase;
        font-weight: 500;
    }

    .drop_down_suggestion{
        position: absolute;
        width: 90%;
        background-color: #fff;
        z-index: 1;
        border: 1px solid #eaeaea;
        
        .item{
            width: 100%;
            padding: 5px;
            font-size: .9rem;
            box-sizing: border-box;
            cursor: pointer;

            &:hover {
                background-color: #eaeaea;
            }
        }
    }

    .table_wrapper{
        width: 100%;
        max-height: 22rem;
        margin-top: 1rem;
        overflow: auto;
        &::-webkit-scrollbar {
            background-color:red;
            width:5px
        }
        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }
        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }
        table{
            width: 100%;
            border-collapse: collapse;
            thead{
                td{
                    padding: .7rem 7px;
                    font-weight: 600;
                    background-color: #4D9DF4;
                    color: #fff
                }
            }
            td{
                border-bottom: 1px solid #222;
                font-size: .9rem;
                color: #000;
                padding: .4rem 7px;
                box-sizing: border-box;
                :hover{
                    fill: '#e71b0e'
                }
            }

            tbody{
                background-color: #F0F3FF;
            }
        }
        .input_wrapper{
            background-color: #fff;
            height: 2.1rem;
            border: 1px solid #e2e2e2;
            border-radius: 6px;
            box-shadow: 1px 2px 2px -1px #ccc;

            select,
            input{
                border: 0px;
                background-color: #fff;
                height: 100%;
                width: 100%;
                padding: 0px 10px;
                box-sizing: border-box;
                font-size: 1rem;

                &:focus{
                    outline: none;
                }

            }
    }
        /* select{
            width: 100%;
            height: 30px;
            font-size: .8rem;
            padding: 5px;
            box-sizing: border-box;
            border: 1px solid #000;
            background: #fff;;
        }
        input{
            width: 100%;
            height: 30px;
            font-size: .8rem;
            padding: 5px;
            box-sizing: border-box;
            border: 1px solid #000;
        } */
    
    }


`;


const TabContent = styled.div`
    width: 100%;
    padding: 5px;
    padding-top: 1rem;
    box-sizing: border-box;
`;


const FormulaEditorPopup = (props) => {

    const { close, popupKey, option, data, } = props;

    const { items, index, save_formula, columns, report_index, hide_condition, sortedData, column_meta_data, order, formula_applicable_to } = data;

    const [localData, setLocalData] = useState(undefined);
    const [show_dropdown, set_show_dropdown] = useState();
    const [columns_for_else, set_columns_for_else] = useState(undefined);

    const [formulaApplicabeType, setFormulaApplicableType] = useState('raw Data');


    const [hintOpen, setHintsOpen] = useState(false);

    const [autoSuggestList, setAutoSuggestList] = useState([
        {
            "value": "[Revenue], [Gender]='female'",
            "display_value": "female-Revenue"
        },
        {
            "value": "[TEST], [Gender]='female'",
            "display_value": "female-TEST"
        },
        {
            "value": "[Revenue], [Gender]='male'",
            "display_value": "male-Revenue"
        },
        {
            "value": "[TEST], [Gender]='male'",
            "display_value": "male-TEST"
        },
        {
            "value": "[Revenue], [Gender]='Total'",
            "display_value": "Total-Revenue"
        },
        {
            "value": "[TEST], [Gender]='Total'",
            "display_value": "Total-TEST"
        }
    ])

    const formulaSuggestions = [
        'sum()', 'count()', 'max()', 'avg()', 'abs()'
    ];



    const inputRef = useRef(null)


    useEffect(() => {
        set_columns_for_else(columns)
    }, [columns])

    const __get_plan__object = () => {
        return {
            condition: '',
            value: '',
            display_value: ''
        }
    }

    const _get_plan_formula = (order) => {
        return {
            formula_str: undefined,
            name: '',
            order: order,
            nf_formula_conditions: [
                {
                    condition: '',
                    value: '',
                    display_value: ''
                },
            ]
        }
    }




    useEffect(() => {

        if (items) {
            const clone_item = items ? JSON.parse(JSON.stringify(items)) : {};

            let formula_conditions = (clone_item.nf_formula_conditions ? [...clone_item.nf_formula_conditions] : []).sort((a, b) => a.order - b.order);
            const last_condition = formula_conditions[formula_conditions.length - 1];
            const else_condition = formula_conditions.find(item => item.condition === CONDITIONS.ELSE.VALUE_KEY);
            const else_index = formula_conditions.findIndex(item => item.condition === CONDITIONS.ELSE.VALUE_KEY);



            if (else_condition && else_index > -1) {
                const before_else_c = formula_conditions.slice(0, else_index);
                const before_else_last_el = before_else_c?.length > 0 ? before_else_c[before_else_c.length - 1] : undefined;
                formula_conditions = before_else_c;
                if (before_else_last_el && before_else_last_el.condition && before_else_last_el.display_value) {
                    formula_conditions.push(__get_plan__object())
                }
                formula_conditions.push(else_condition)
            }

            if (!else_condition && last_condition && last_condition.condition !== '' && last_condition.display_value !== '' || formula_conditions.length == 0) {
                formula_conditions.push(__get_plan__object())
            }
            clone_item.nf_formula_conditions = formula_conditions;
            setLocalData(clone_item)

        } else setLocalData(_get_plan_formula(order))

    }, [items]);






    const _onChange = (key, value, formula_index, item_index) => {

        const target_formula = localData ? { ...localData } : {};
        const formula_items = target_formula && target_formula.nf_formula_conditions ? [...target_formula.nf_formula_conditions] : [];
        let filterd_items = formula_items.filter((a) => !a.deleted).sort((a, b) => a.order - b.order) || [];
        const deleted_items = formula_items.filter((a) => a.deleted).sort((a, b) => a.order - b.order) || [];
        const formula_item = filterd_items[item_index];

        target_formula['modified'] = true;
        formula_item[key] = value;
        formula_item["modified"] = true;
        filterd_items[item_index] = formula_item;

        const last_condition = filterd_items[filterd_items.length - 1];
        const else_condition = filterd_items.find(item => item.condition === CONDITIONS.ELSE.VALUE_KEY);
        const else_index = filterd_items.findIndex(item => item.condition === CONDITIONS.ELSE.VALUE_KEY);

        if (else_condition && else_index > -1 && items && formula_item.condition !== CONDITIONS.ELSE.VALUE_KEY) {
            const before_else_c = filterd_items.slice(0, else_index);
            const before_else_last_el = before_else_c?.length > 0 ? before_else_c[before_else_c.length - 1] : undefined;
            filterd_items = before_else_c;
            if (before_else_last_el.condition !== '' && before_else_last_el.value !== '' && before_else_last_el.display_value !== '') {
                filterd_items.push(__get_plan__object())
            }
            filterd_items.push(else_condition)
        }

        if (!else_condition && last_condition && last_condition.condition !== '' && last_condition.display_value !== '' || filterd_items.length == 0) {
            filterd_items.push(__get_plan__object())
        }

        const joined_both = [...deleted_items, ...filterd_items]
        target_formula.nf_formula_conditions = joined_both;
        setLocalData(target_formula)
    }


    const delete_row = (formula_index, item_index) => {
        const target_formula = localData ? { ...localData } : {};
        const formula_items = target_formula && target_formula.nf_formula_conditions ? [...target_formula.nf_formula_conditions] : [];

        const filterd_items = formula_items.filter((a) => !a.deleted).sort((a, b) => a.order - b.order) || [];
        const deleted_items = formula_items.filter((a) => a.deleted).sort((a, b) => a.order - b.order) || [];
        target_formula['modified'] = true;

        filterd_items[item_index].deleted = true;
        const joined_both = [...deleted_items, ...filterd_items]
        target_formula.nf_formula_conditions = joined_both;
        setLocalData(target_formula)

    }

    const setFormulaValue = (value, formula_index) => {

        const target_formula = localData ? { ...localData } : {};
        target_formula["formula_str"] = value;
        target_formula["modified"] = true;

        if (value) {

            let key_to_use_for_d_type = value.replace(/[a-zA-Z]+\(|\)|\[|\]/g, '')?.trim();
            const data_type = column_meta_data && column_meta_data?.[key_to_use_for_d_type] && column_meta_data?.[key_to_use_for_d_type]?.data_type;
            const final_d_type = dataTypes.convert_to_string(data_type)
            if (!localData?.data_type) {
                target_formula.data_type = final_d_type;
            }
            target_formula['db_data_type'] = final_d_type
        }

        setLocalData(target_formula)
    }


    const toggle_condition = (value, formula_index) => {
        const target_formula = localData ? { ...localData } : {};
        target_formula["show_formula_condition"] = value;
        target_formula["modified"] = true;
        setLocalData(target_formula)
    }

    const on_change_title = (value) => {
        const target_formula = localData ? { ...localData } : {};
        target_formula["alias"] = value;
        target_formula["modified"] = true;
        setLocalData(target_formula)
    }


    const on_change = (key, value) => {
        const target_formula = localData ? { ...localData } : {};
        target_formula[key] = value;
        target_formula["modified"] = true;
        setLocalData(target_formula)
    }


    const _local_save = () => {

        setTimeout(() => {

            const filterd_local_item = localData ? JSON.parse(JSON.stringify(localData)) : {};

            if (formula_applicable_to) filterd_local_item.formula_applicable_to = formula_applicable_to;

            const formula_items = filterd_local_item && filterd_local_item.nf_formula_conditions ? [...filterd_local_item.nf_formula_conditions] : [];

            const filterd_items = formula_items.filter((f) => {
                if (f.condition && f.condition.length > 0) {
                    if (!f.id && f.deleted) return false;
                    else return true;
                }
                else return false;
            })

            filterd_local_item.nf_formula_conditions = filterd_items;
            save_formula(filterd_local_item, (items ? index : undefined), report_index,)
            close(option, popupKey)
        }, 300)
    }


    const tbl_items = localData && localData.nf_formula_conditions;
    const formula_str = localData && localData.formula_str;
    const showList = localData && localData.show_formula_condition;

    const filterd_tbl_items = tbl_items && tbl_items.length > 0 && tbl_items.filter((a) => !a.deleted).sort((a, b) => a.order - b.order)


    const _get_dist_values = (str) => {

        const k = str && str.split(']')[0];
        const __key__ = k.replace('[', '');

        const __dist__values__ = [];
        if (sortedData && sortedData.length > 0 && __key__) {
            for (let index = 0; index < sortedData.length; index++) {
                const element = sortedData[index];
                const __v__ = element[__key__];
                if (__dist__values__.indexOf(__v__) === -1 && __v__ !== '' && typeof __v__ !== "undefined") {
                    __dist__values__.push(__v__);
                }

            }
        }
        return __dist__values__;
    }



    const toggle_dropdown = (index, value) => {
        const temp_dropdown = show_dropdown ? { ...show_dropdown } : {};
        temp_dropdown[index] = value;
        set_show_dropdown(temp_dropdown)
    }




    const find_biggest_word_in_hints = (hints) => {
        let len = 0;
        hints && hints.length > 0 && hints.forEach((h) => {
            if (h && h.display_value.length > len) {
                len = h.display_value.length;
            }
        })
        return len;
    }
    const w_l = find_biggest_word_in_hints(autoSuggestList)


    const is_letter_upper_case = (ch) => {
        if (!isNaN(ch * 1)) {
            return false
        }
        else {
            if (ch == ch.toUpperCase()) {
                return true
            }
            if (ch == ch.toLowerCase()) {
                return false
            }
        }
    }


    return (
        <Wrapper onClick={(e) => {
            e.stopPropagation()
            set_show_dropdown(undefined);
        }}>
            <div className='header'>
                <h1>{items ? 'Edit Your Formula' : 'Add Your Formula'}</h1>
                <div title="Close" className='close_btn' onClick={() => {
                    close(option, popupKey)
                }}>
                    <CloseSvg size="1.1rem" />
                </div>
            </div>
            <TabContent>
                <div className="_row">
                    <div className="_label_">
                        Formula Name<sup>*</sup>
                    </div>
                    <div style={{ width: '70%' }}>
                        <input
                            value={localData && localData.alias ? localData.alias : ''}
                            className="input"
                            type="text"
                            onChange={(e) => {
                                on_change_title(e.target.value)
                            }}

                        ></input>
                    </div>
                </div>

                <div className="_row">

                    <div className="_label_"> Type Your Formula<sup>*</sup></div>

                    <div style={{ width: '70%', position: 'relative' }}>
                        {/* <InputBoxSuggestion
                            valueHints={sortedData}
                            value={localData?.formula_str}
                            onChange={(e, value) => {
                                setFormulaValue(e?.target?.value || value , index);
                            }}
                            background='#F0F3FF'
                        /> */}
                        <input
                            refName={inputRef}
                            style={{ width: '100%' }}
                            value={formula_str}
                            className="input"
                            type="text"
                            onChange={(e) => {
                                // const value = e.target.value;
                                // if (value?.trim()?.endsWith('[')) {
                                //     setHintsOpen(true);
                                // } else {
                                //     setHintsOpen(false);
                                // }
                                setFormulaValue(e.target.value, index);
                            }}

                        ></input>

                        {/* <AutoComplete
                            id="list_element"
                        >
                            {hintOpen && autoSuggestList && autoSuggestList.length > 0 && autoSuggestList.map((item, i) => {

                                const width_to_use = is_letter_upper_case(item.display_value) ? 10 : 7

                                return (
                                    <div className={`word`} key={"auto" + i}>
                                        {formula_str && formula_str.length > 0 ? <span className={"prev_word"}>{formula_str}</span> : null}
                                        <span
                                            style={{ width: w_l ? (w_l > 3) ? (w_l * width_to_use) + "px" : undefined : undefined }}
                                            className={"current_word"}
                                            onClick={(e) => {

                                                var prev_input_value = formula_str;
                                                prev_input_value += item?.value;

                                                if (inputRef?.current) inputRef.current.focus();

                                                setFormulaValue(prev_input_value)
                                                setHintsOpen(false)

                                            }}
                                        >{item?.display_value}</span>
                                    </div>
                                )
                            })}
                        </AutoComplete> */}
                    </div>

                </div>


                <div className="_row">
                    <div className="_label_" >
                        Preferred Data Type
                    </div>
                    <div style={{ width: '70%' }}>
                        <select
                            // style={{
                            //     width: '50%',
                            // }}
                            className='option'
                            value={localData && localData.data_type ? localData.data_type : ''}
                            onChange={(e) => {
                                on_change("data_type", e.target.value)
                            }}>
                            <option value="">{`None`}</option>
                            <option value="number">Number</option>
                            <option value="string">String</option>
                            <option value="date">Date</option>
                            <option value="currency">Currency</option>
                            <option value="percent">Percent</option>
                        </select>
                    </div>
                </div>


                {/* <div className="_row">
                    <div className="_label_" >
                        Formula Applicable On
                    </div>
                    <div style={{ width: '70%' }}>
                        <select
                            style={{
                                width: '50%',
                                height: '30px'
                            }}
                            value={localData?.formula_applicable_to ? localData?.formula_applicable_to : ''}
                            onChange={(e) => {
                                on_change("formula_applicable_to", e.target.value)
                            }}>
                            <option value="raw_data">Raw Data</option>
                            <option value="pivot_data">Pivot Data</option>
                        </select>
                    </div>
                </div> */}

                <div className="_row">
                    <div className="_label_" >
                        Preferred Grand Total
                    </div>
                    <div style={{ width: '70%' }}>
                        <select
                            // style={{
                            //     width: '50%',
                            // }}
                            className='option'
                            value={localData && localData.grand_total_value_type ? localData.grand_total_value_type : ''}
                            onChange={(e) => {
                                on_change("grand_total_value_type", e.target.value)
                            }}>
                            <option value="">{`None`}</option>
                            <option value="sum">Sum</option>
                            <option value="formula">Formula</option>
                        </select>
                    </div>
                </div>



                {!hide_condition && (
                    <div className={["_label_ formula_checkbox"]} style={{ marginTop: '1rem' }}>
                        <CheckBox
                            onClick={() => {
                                toggle_condition((showList ? false : true), index);
                            }}
                            checked={showList}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;Create Conditions for Formula &nbsp;<strong>{(localData && localData.alias) ? `"${localData.alias}"` : ''}</strong>
                    </div>
                )}



                {!hide_condition && showList && filterd_tbl_items && filterd_tbl_items.length > 0 && (
                    <div className="table_wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <td>#</td>
                                    <td>Condition</td>
                                    <td>Value</td>
                                    <td>Display Value</td>
                                    <td>Action</td>
                                </tr>
                            </thead>

                            <tbody>
                                {filterd_tbl_items.map((item, j) => {
                                    const is_last_element = (j == (tbl_items.length - 1))
                                    const __distance_values__ = formula_str && formula_str.length > 0 && _get_dist_values(formula_str)
                                    return (
                                        <tr>
                                            <td>{j + 1}</td>
                                            <td>
                                                <div className='input_wrapper'>
                                                    <select
                                                        value={item.condition ? item.condition : '--select--'}
                                                        onChange={(e) => {
                                                            _onChange("condition", e.target.value, index, j);
                                                        }}
                                                    >
                                                        <option value="">{`--select--`}</option>
                                                        {CONDITIONS && Object.keys(CONDITIONS).map((key) => {
                                                            if (key === "ELSE" && j > 0 && ((filterd_tbl_items.length - 1) == j)) {
                                                                return (
                                                                    <option value={CONDITIONS[key].VALUE_KEY}>{CONDITIONS[key].DISPLAY_KEY}</option>
                                                                )
                                                            }
                                                            if (key !== "ELSE") {
                                                                return (
                                                                    <option value={CONDITIONS[key].VALUE_KEY}>{CONDITIONS[key].DISPLAY_KEY}</option>
                                                                )
                                                            }
                                                        })}

                                                    </select>
                                                </div>
                                            </td>

                                            <td style={{
                                                display: item.condition === CONDITIONS.BETWEEN.VALUE_KEY ? 'flex' : undefined,
                                                justifyContent: 'space-between'
                                            }}>
                                                {item.condition !== CONDITIONS.ELSE.VALUE_KEY && (
                                                    <React.Fragment>
                                                        {item.condition === CONDITIONS.EQUAL_TO.VALUE_KEY && __distance_values__ && __distance_values__.length > 0 ? (
                                                            <div className='input_wrapper'>
                                                                <select value={item.value ? item.value : '--select--'} onChange={(e) => {
                                                                    _onChange("value", e.target.value, index, j);
                                                                }}>
                                                                    <option value={'--select--'}>--select--</option>
                                                                    {__distance_values__.map((op) => {
                                                                        return (
                                                                            <option value={op}>{op}</option>
                                                                        )
                                                                    })}
                                                                    {__distance_values__?.length > 0 && (
                                                                        <option value={"__nfx_null__"}>NULL</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                        ) : <>
                                                            {localData?.db_data_type === "date" ? (
                                                                <>
                                                                    <div className='input_wrapper'>
                                                                        <CustomDatePicker
                                                                            labelStyle={{
                                                                                marginBottom: "4px",
                                                                                fontSize: ".7rem",
                                                                                color: "#fff",
                                                                                fontWeight: 'bold'
                                                                            }}
                                                                            bgColor={"#fff"}

                                                                            type='date'
                                                                            onChange={(value) => {
                                                                                console.log("CALLING", value)
                                                                                _onChange("value", value, index, j);

                                                                            }} value={item?.['value']} />
                                                                    </div>

                                                                    {item.condition === CONDITIONS.BETWEEN.VALUE_KEY && (
                                                                        <div className='input_wrapper'
                                                                            style={{
                                                                                width: item.condition === CONDITIONS.BETWEEN.VALUE_KEY ? '47%' : undefined
                                                                            }}
                                                                        >
                                                                            <CustomDatePicker
                                                                                labelStyle={{
                                                                                    marginBottom: "4px",
                                                                                    fontSize: ".7rem",
                                                                                    color: "#fff",
                                                                                    fontWeight: 'bold'
                                                                                }}
                                                                                bgColor={"#fff"}

                                                                                type='date'
                                                                                onChange={(value) => {
                                                                                    
                                                                                    _onChange("value2", value, index, j);

                                                                                }} value={item?.['value2']} />
                                                                        </div>
                                                                    )}</>
                                                            ) : <>
                                                                <div className='input_wrapper' style={{
                                                                    width: item.condition === CONDITIONS.BETWEEN.VALUE_KEY ? '47%' : undefined
                                                                }}>
                                                                    <input
                                                                        value={item.value ? item.value : ''}
                                                                        type="text"
                                                                        style={{ width: item.condition === CONDITIONS.BETWEEN.VALUE_KEY ? '100%' : undefined, }}
                                                                        placeholder={item.condition === CONDITIONS.BETWEEN.VALUE_KEY ? "From" : ''}
                                                                        onChange={(e) => {
                                                                            _onChange("value", e.target.value, index, j);
                                                                        }} />
                                                                </div>

                                                                {item.condition === CONDITIONS.BETWEEN.VALUE_KEY && (
                                                                    <div className='input_wrapper'
                                                                        style={{
                                                                            width: item.condition === CONDITIONS.BETWEEN.VALUE_KEY ? '47%' : undefined
                                                                        }}>
                                                                        <input
                                                                            style={{ marginTop: '0rem', width: '100%' }}
                                                                            value={item?.value2 ? item.value2 : ''}
                                                                            placeholder='To'
                                                                            type="text" onChange={(e) => {
                                                                                _onChange("value2", e.target.value, index, j);
                                                                            }} />
                                                                    </div>
                                                                )}</>}

                                                        </>
                                                        }
                                                    </React.Fragment>


                                                )}

                                            </td>

                                            <td style={{ position: "relative" }}>
                                                <div className='input_wrapper'>
                                                    <input
                                                        value={item.display_value ? item.display_value : ''}
                                                        onChange={(e) => {
                                                            const v = e.target.value;
                                                            _onChange("display_value", e.target.value, index, j);
                                                            const temp_columns = columns ? [...columns] : [];

                                                            const filterd_columns = temp_columns && temp_columns.filter((c) => {
                                                                if (v === '') return true
                                                                if ((c && c.toLocaleLowerCase()).indexOf((v && v.toLocaleLowerCase())) > -1) return true
                                                                else return false
                                                            })
                                                            set_columns_for_else(filterd_columns)

                                                        }}
                                                        onFocus={(e) => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            toggle_dropdown(j, true)
                                                        }}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            toggle_dropdown(j, true)
                                                        }}
                                                        onBlur={() => {
                                                            // setTimeout(() => {
                                                            //     toggle_dropdown(j, false);
                                                            // }, 1000)
                                                        }}
                                                        type="text"
                                                    />
                                                </div>



                                                {show_dropdown && show_dropdown[j] && item.condition === CONDITIONS.ELSE.VALUE_KEY && columns_for_else && columns_for_else.length > 0 && (
                                                    <div className="drop_down_suggestion">
                                                        {columns_for_else.map((c) => {
                                                            return (
                                                                <div key={"c" + c}
                                                                    className="item"
                                                                    onClick={(e) => {
                                                                        const b_value = `[${c}]`
                                                                        _onChange("display_value", b_value, index, j);
                                                                        toggle_dropdown(j, false);
                                                                    }}
                                                                >{c}</div>
                                                            )
                                                        })}
                                                    </div>
                                                )}
                                            </td>

                                            <td>
                                                {/* {!is_last_element && ( */}
                                                <RippleEffect
                                                    width="25"
                                                    icon={<Delete size=".8rem" height=".8rem" color="#c85149" />}
                                                    title={"delete"}
                                                    Click={
                                                        (e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            delete_row(index, j);
                                                        }
                                                    }
                                                />
                                                {/* )} */}
                                            </td>
                                        </tr>
                                    )

                                })}
                            </tbody>

                        </table>
                    </div>
                )}
            </TabContent>


            <div
                style={{
                    padding: '.5rem',
                    paddingBottom: '0px',
                    // borderTop: '1px solid #ccc',
                    marginTop: 'auto',
                    display: 'flex',
                    // width: '100%',
                    marginLeft: 'auto',
                    paddingBottom: '.7rem'
                }}>

                <Button
                    primary={true}
                    // mR="1.143rem"
                    // bgColor='#4D9DF4'
                    width="7rem"
                    onClick={() => {
                        _local_save()

                    }}
                >Save</Button>

            </div>
        </Wrapper>
    )
}

export default FormulaEditorPopup;
