import styled from 'styled-components';
import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils'

const selected = getSelectedTheme();


export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;
    box-sizing: border-box;
`;


export const LogoWrapper = styled.div`
        z-index: 3;
        display: flex;
        align-items: center;
        flex-direction: row;
        cursor: pointer;
        /* width: 200px; */
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        /* padding-right: 1.2rem; */
        position: relative;
        align-items:center;
        .select_db{
            width: 100px;
            display: inline-block;
            margin-right: 1rem;
            height: 1.5rem;
            text-transform: capitalize;
            font-size: .8rem;
        }
        .setting{
                svg{
                    fill: ${themes[selected].header.color.settingDefault};
                }
        }
        
        
        .main_dropdown_menu{
            display: ${props => props.isMenuOpen ? "block" : "none"};
            width: 15rem;
            position: absolute;
            background: #fff;
            box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
            top: 100%;
            border-radius: 6px;
            z-index: 2;

            .change_db_link{
                color: #0c65a0;
                font-weight: 500;
                text-align: center;
                margin: auto;
                margin-bottom: .5rem;
                padding: .5rem;
                display: inline-block;
                border-radius: 3px;
                &:hover{
                    background: #308bc817;
                }


            }

            .selected_db_name{
                font-size: .8rem;
                text-transform: uppercase;
                font-weight: bold;
                text-align: center;
                padding: .4rem .3em;
                width: 100%;
                box-sizing: border-box;
                text-overflow: ellipsis;
                overflow: hidden;

            }
            ul{
                padding: 0px;
                margin: 0px;
                list-style: none;
                // border-bottom: 1px solid #ccc;
                // padding-bottom: 1rem;

                li{
                    font-size: .9rem;
                    padding: 0.7rem .7rem;
                    position: relative;
                    text-transform: capitalize;
                    .drop_icon{
                        transform: rotate(-92deg);
                        svg{
                            fill: #6f6f6f;
                        }
                    }


                    &:hover{
                        background: #f6f8fe;
                        .inner_dropdown{
                            display: block;
                            
                        }
                    }
                }
            }            
            .log_out{
                list-style: none;
                font-size: .9rem;
                padding: .5rem .7rem;
                border-top:1px solid #ccc;
                &:hover{
                    background: ${themes[selected].header.color.logoutHoverBg};
                    color: ${themes[selected].header.color.logoutColorOnHover};
                    svg{
                        fill: ${themes[selected].header.color.logoutColorOnHover};
                    }
                }
            }
        }



        /* inner dropdown menu  */

        .inner_dropdown{
            display: none;
            position: absolute;
            right: 100%;
            width: 17rem;
            margin-top:1rem;
            border-radius: 6px;
            background: #fff;
            box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
            top: -1rem;
            z-index: 2;

            p{
                padding: 13px 12px;
                font-size: .9rem;
                font-weight: 600;
                border-bottom: 1px solid #ccc;
                margin: 0px;
                background: linear-gradient(to right, #3E9EFF, #737DFF);
                color:#fff;
                border-radius: 6px 0px 0px 0px;
            }

            ul{
                margin: 0px;
                padding: 0px;
                list-style: none;

                li{
                    div{
                        overflow-x: hidden;
                        padding-right: 0.6rem;
                    }
                }
            }
        }
    .inner_dropdown::after{
   content: '';
    position: absolute;
    top: 4%;
    right: -17px;
    width: 0;
    height: 0;
    border-left: 20px solid #6a82ff0f;
    border-right: 17px solid transparent;
    border-top: 17px solid #6d80ff;
    clear: both;
    transform: rotate(269deg);
}

        

`;




export const TitleWrapper = styled.div`
    font-size: 2rem;
    border-bottom: 0;
    color: ${themes[selected].header.color.autoSuggestTxt};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: ${props => props.marginLeft ? props.marginLeft : undefined};

`;



export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    box-shadow: ${props => props.shadowHide ? undefined : 'rgb(0 0 0 / 20%) 0px 2px 6px'};
    position: relative;
    z-index: 4;
    background-color: ${themes[selected].header.color.background};
`;

export const ExportWrapper = styled.div`

`;


export const ReportWrapper = styled.div`
    width: fit-content;
    margin-right: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
`;

export const MenuWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start; 
    flex-direction: column;
`;

export const ScWrapper = styled.div`
    position: absolute;
    top: 0px;
    left: 20px;
    width: 150px;
    background: white;
    border-radius: 5px;
    background: white;
    z-index: 90;
    box-sizing: border-box;
    box-shadow:0px 2px 5px 0px rgba(0,0,0,0.75);
`;

export const ExtraWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
`;

export const HamburgerButton = styled.div`
    cursor: pointer;
    display: inline-block;
    color: ${themes[selected].header.color.home};
    transition: all .3s;
    svg{
        vertical-align: bottom;
    }
    &:hover{
        svg{
            /* fill: ${themes[selected].header.color.iconHover}; */
            /*fill: #337DFF; */
        }
        transform: scale(1.1);
    }

`;



export const HomeButton = styled.div`
    display: inline-block;
    color: ${themes[selected].header.color.home};
    margin-left: 12px; 
    transition: all .3s;

    svg{
        vertical-align: bottom;
    }

    &:hover{
        svg{
            /* fill: ${themes[selected].header.color.iconHover}; */
        }
        transform: scale(1.1);

    }
`;



export const SearchButton = styled.div`
    display: inline-block;
    color: ${themes[selected].header.color.home};
    margin-left: 20px; 
    transition: all .3s;

    svg{
        vertical-align: bottom;
    }

    &:hover{
        svg{
            fill: ${themes[selected].header.color.iconHover};
        }
        transform: scale(1.1);

    }
`;



