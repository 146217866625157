import React, { useState, useEffect, useRef } from 'react';
import { DisplayFiledWrapper, DisplayColumnData, } from './ranking.styled';
import { Button } from '../hoc/button';
import { Gap } from '../gap/index';
import { DivRow } from '../styled-hoc';
import TextBox from '../hoc/textBox'


const AddEdit = (props) => {
    const { close, popupKey, option, data } = props;
    const { config, func } = data;
    const { saveConfig } = func;

    const [localconfig, setConfig] = useState({
        param: '',
        priority: '',
        linear: ''
    })

    const onChange = (e, key) => {
        let value = e.target.value;

        setConfig({ ...localconfig, [key]: value });
    };

    useEffect(() => {
       if(config){
        let temconfig = config;
        setConfig(temconfig);
       }
    }, [config])



    return (
        <DisplayFiledWrapper>
            <h3 className="sort_title">{config ? 'Edit Config' : 'Add Config'}</h3>
            <DisplayColumnData>
                <div className="from_group">
                    <DivRow>
                        <TextBox 
                            label="Param"
                            type="text"
                            value={localconfig.param} 
                            onChange={(e) => onChange(e, 'param')}
                        />
                    </DivRow>
                </div>


                <div className="from_group">
                    <DivRow>
                        <TextBox 
                            label="Priority"
                            type="number"
                            value={localconfig.priority} 
                            onChange={(e) => onChange(e, 'priority')}
                        />
                    </DivRow>
                </div>


                <div className="from_group">
                    <DivRow>
                        <TextBox 
                            label="Linear"
                            type="number"
                            value={localconfig.linear} 
                            onChange={(e) => onChange(e, 'linear')} 
                        />
                    </DivRow>
                </div>


            </DisplayColumnData>
            <Gap h="1.643rem" />
            <div>
                <Button
                     primary={true}
                    width="6rem"
                    mR="16px"
                    onClick={() => {
                        saveConfig(localconfig)
                        close(option, popupKey)
                    }}
                >
                    Save
                </Button>
                <Button
                    width="6rem"
                    bgColor={'#e6e6e6'}
                    onClick={() => {
                        close(option, popupKey)
                    }}>Cancel</Button>
            </div>

        </DisplayFiledWrapper>
    )
}
export default AddEdit
