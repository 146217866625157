import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Styled component for the progress bar container
const ProgressBarContainer = styled.div`
  width: ${props => props.width || ""};
  background-color: ${({ backgroundColor }) => backgroundColor || '#e0e0df'};
  border-radius: 50px;
  overflow: hidden;
  height: ${({ height }) => height || '20px'};
  margin: 10px 0;
  position: relative;
`;

const ProgressIndicator = styled.div`
  width: ${({ progress }) => progress || '0%'};
  background: ${({ color }) => color || 'linear-gradient(0deg, #193cf7, #88b0ff)'};
  height: 100%;
  transition: width 0.5s ease-in-out;
  border-radius: inherit;
`;

const ProgressBarLabel = styled.div`
  text-align: center;
  font-size: 14px;
  color: ${({ labelColor }) => labelColor || '#fff'};
  position: absolute;
  width: 100%;
  line-height: ${({ height }) => height || '20px'};
`;

const ProgressBar = ({
  height = '10px',
  width,
  color,
  backgroundColor = '#e0e0df',
  showLabel = true,
  labelColor = '#fff',
  loading = false,
  progress,
}) => {
  const [progressPercent, setProgressPercent] = useState(0);

  useEffect(() => {
    if( progress > -1 ) return;
    if (loading) {
      setProgressPercent(90);
    } else {
      setProgressPercent(100);
    }
  }, [loading]);

  return (
    <ProgressBarContainer height={height} width={width} backgroundColor={backgroundColor}>
      <ProgressIndicator progress={`${progressPercent || progress}%`} color={color} />
      {showLabel && (
        <ProgressBarLabel height={height} labelColor={labelColor}>
          {progressPercent || progress}%
        </ProgressBarLabel>
      )}
    </ProgressBarContainer>
  );
};

export default ProgressBar;
