import React from 'react';

const List = (props) => {

    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}

            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 507 472"
            space="preserve"
        >
            <defs>
                <symbol overflow="visible" id="g">
                    <path id="svg_1" d="m18.766,-1.125c-0.96875,0.5 -1.9805,0.875 -3.0312,1.125c-1.043,0.25781 -2.1367,0.39062 -3.2812,0.39062c-3.3984,0 -6.0898,-0.94531 -8.0781,-2.8438c-1.9922,-1.9062 -2.9844,-4.4844 -2.9844,-7.7344c0,-3.2578 0.99219,-5.8359 2.9844,-7.7344c1.9883,-1.9062 4.6797,-2.8594 8.0781,-2.8594c1.1445,0 2.2383,0.13281 3.2812,0.39062c1.0508,0.25 2.0625,0.625 3.0312,1.125l0,4.2188c-0.98047,-0.65625 -1.9453,-1.1406 -2.8906,-1.4531c-0.94922,-0.3125 -1.9492,-0.46875 -3,-0.46875c-1.875,0 -3.3516,0.60547 -4.4219,1.8125c-1.0742,1.1992 -1.6094,2.8555 -1.6094,4.9688c0,2.1055 0.53516,3.7617 1.6094,4.9688c1.0703,1.1992 2.5469,1.7969 4.4219,1.7969c1.0508,0 2.0508,-0.14844 3,-0.45312c0.94531,-0.3125 1.9102,-0.80078 2.8906,-1.4688l0,4.21873z" />
                </symbol>
                <symbol overflow="visible" id="a">
                    <path id="svg_2" d="m13.734,-11.141c-0.4375,-0.19531 -0.87109,-0.34375 -1.2969,-0.4375c-0.41797,-0.10156 -0.83984,-0.15625 -1.2656,-0.15625c-1.2617,0 -2.2305,0.40625 -2.9062,1.2188c-0.67969,0.80469 -1.0156,1.9531 -1.0156,3.4531l0,7.0625l-4.8906,0l0,-15.312l4.8906,0l0,2.5156c0.625,-1 1.3438,-1.7266 2.1562,-2.1875c0.82031,-0.46875 1.8008,-0.70312 2.9375,-0.70312c0.16406,0 0.34375,0.01172 0.53125,0.03125c0.19531,0.01172 0.47656,0.03906 0.84375,0.07813l0.0156,4.43699z" />
                </symbol>
                <symbol overflow="visible" id="b">
                    <path id="svg_3" d="m17.641,-7.7031l0,1.4062l-11.453,0c0.125,1.1484 0.53906,2.0078 1.25,2.5781c0.70703,0.57422 1.7031,0.85938 2.9844,0.85938c1.0312,0 2.082,-0.14844 3.1562,-0.45312c1.082,-0.3125 2.1914,-0.77344 3.3281,-1.3906l0,3.7656c-1.1562,0.4375 -2.3125,0.76562 -3.4688,0.98438c-1.1562,0.22656 -2.3125,0.34375 -3.4688,0.34375c-2.7734,0 -4.9297,-0.70312 -6.4688,-2.1094c-1.5312,-1.4062 -2.2969,-3.3789 -2.2969,-5.9219c0,-2.5 0.75391,-4.4609 2.2656,-5.8906c1.5078,-1.4375 3.582,-2.1562 6.2188,-2.1562c2.4062,0 4.332,0.73047 5.7812,2.1875c1.4453,1.4492 2.1719,3.3828 2.1719,5.7969l0.0001,0.00001zm-5.0312,-1.625c0,-0.92578 -0.27344,-1.6719 -0.8125,-2.2344c-0.54297,-0.57031 -1.25,-0.85938 -2.125,-0.85938c-0.94922,0 -1.7188,0.26562 -2.3125,0.79688s-0.96484,1.2969 -1.1094,2.2969l6.3594,0z" />
                </symbol>
                <symbol overflow="visible" id="e">
                    <path id="svg_4" d="m9.2188,-6.8906c-1.0234,0 -1.793,0.17188 -2.3125,0.51562c-0.51172,0.34375 -0.76562,0.85547 -0.76562,1.5312c0,0.625 0.20703,1.1172 0.625,1.4688c0.41406,0.34375 0.98828,0.51562 1.7188,0.51562c0.92578,0 1.7031,-0.32812 2.3281,-0.98438c0.63281,-0.66406 0.95312,-1.4922 0.95312,-2.4844l0,-0.5625l-2.5469,0.00004zm7.4688,-1.8438l0,8.7344l-4.9219,0l0,-2.2656c-0.65625,0.92969 -1.3984,1.6055 -2.2188,2.0312c-0.82422,0.41406 -1.8242,0.625 -3,0.625c-1.5859,0 -2.8711,-0.45703 -3.8594,-1.375c-0.99219,-0.92578 -1.4844,-2.1289 -1.4844,-3.6094c0,-1.7891 0.61328,-3.1016 1.8438,-3.9375c1.2383,-0.84375 3.1797,-1.2656 5.8281,-1.2656l2.8906,0l0,-0.39062c0,-0.76953 -0.30859,-1.332 -0.92188,-1.6875c-0.61719,-0.36328 -1.5703,-0.54688 -2.8594,-0.54688c-1.0547,0 -2.0312,0.10547 -2.9375,0.3125c-0.89844,0.21094 -1.7305,0.52344 -2.5,0.9375l0,-3.7344c1.0391,-0.25 2.0859,-0.44141 3.1406,-0.57812c1.0625,-0.13281 2.125,-0.20312 3.1875,-0.20312c2.7578,0 4.75,0.54688 5.9688,1.6406c1.2266,1.0859 1.8438,2.8555 1.8438,5.3125l0.00008,0.00004z" />
                </symbol>
                <symbol overflow="visible" id="d">
                    <path id="svg_5" d="m7.7031,-19.656l0,4.3438l5.0469,0l0,3.5l-5.0469,0l0,6.5c0,0.71094 0.14062,1.1875 0.42188,1.4375s0.83594,0.375 1.6719,0.375l2.5156,0l0,3.5l-4.1875,0c-1.9375,0 -3.3125,-0.39844 -4.125,-1.2031c-0.80469,-0.8125 -1.2031,-2.1797 -1.2031,-4.1094l0,-6.5l-2.4219,0l0,-3.5l2.4219,0l0,-4.3438l4.90622,0z" />
                </symbol>
                <symbol overflow="visible" id="k">
                    <path id="svg_6" d="m12.766,-13.078l0,-8.2031l4.9219,0l0,21.281l-4.9219,0l0,-2.2188c-0.66797,0.90625 -1.4062,1.5703 -2.2188,1.9844s-1.7578,0.625 -2.8281,0.625c-1.8867,0 -3.4336,-0.75 -4.6406,-2.25c-1.2109,-1.5 -1.8125,-3.4258 -1.8125,-5.7812c0,-2.3633 0.60156,-4.2969 1.8125,-5.7969c1.207,-1.5 2.7539,-2.25 4.6406,-2.25c1.0625,0 2,0.21484 2.8125,0.64062c0.82031,0.42969 1.5664,1.0859 2.2344,1.9688l0,0.00018zm-3.2188,9.9219c1.0391,0 1.8359,-0.37891 2.3906,-1.1406c0.55078,-0.76953 0.82812,-1.8828 0.82812,-3.3438c0,-1.457 -0.27734,-2.5664 -0.82812,-3.3281c-0.55469,-0.76953 -1.3516,-1.1562 -2.3906,-1.1562c-1.043,0 -1.8398,0.38672 -2.3906,1.1562c-0.55469,0.76172 -0.82812,1.8711 -0.82812,3.3281c0,1.4609 0.27344,2.5742 0.82812,3.3438c0.55078,0.76172 1.3477,1.1406 2.3906,1.1406z" />
                </symbol>
                <symbol overflow="visible" id="j">
                    <path id="svg_7" d="m10.5,-3.1562c1.0508,0 1.8516,-0.37891 2.4062,-1.1406c0.55078,-0.76953 0.82812,-1.8828 0.82812,-3.3438c0,-1.457 -0.27734,-2.5664 -0.82812,-3.3281c-0.55469,-0.76953 -1.3555,-1.1562 -2.4062,-1.1562c-1.0547,0 -1.8594,0.38672 -2.4219,1.1562c-0.55469,0.77344 -0.82812,1.8828 -0.82812,3.3281c0,1.4492 0.27344,2.5586 0.82812,3.3281c0.5625,0.77344 1.3672,1.1562 2.4219,1.1562l0,0.0001zm-3.25,-9.9219c0.67578,-0.88281 1.4219,-1.5391 2.2344,-1.9688c0.82031,-0.42578 1.7656,-0.64062 2.8281,-0.64062c1.8945,0 3.4453,0.75 4.6562,2.25c1.207,1.5 1.8125,3.4336 1.8125,5.7969c0,2.3555 -0.60547,4.2812 -1.8125,5.7812c-1.2109,1.5 -2.7617,2.25 -4.6562,2.25c-1.0625,0 -2.0078,-0.21094 -2.8281,-0.625c-0.8125,-0.42578 -1.5586,-1.0859 -2.2344,-1.9844l0,2.2188l-4.8906,0l0,-21.281l4.8906,0l0,8.20292z" />
                </symbol>
                <symbol overflow="visible" id="i">
                    <path id="svg_8" d="m0.34375,-15.312l4.8906,0l4.125,10.391l3.5,-10.391l4.8906,0l-6.4375,16.766c-0.64844,1.6953 -1.4023,2.8828 -2.2656,3.5625c-0.86719,0.6875 -2,1.0312 -3.4062,1.0312l-2.8438,0l0,-3.2188l1.5312,0c0.83203,0 1.4375,-0.13672 1.8125,-0.40625c0.38281,-0.26172 0.67969,-0.73047 0.89062,-1.4062l0.14062,-0.42188l-6.82804,-15.90657z" />
                </symbol>
                <symbol overflow="visible" id="h">
                    <path id="svg_9" d="m20.922,-1.5156c-1.3125,0.63672 -2.6797,1.1172 -4.0938,1.4375c-1.4062,0.3125 -2.8672,0.46875 -4.375,0.46875c-3.3984,0 -6.0898,-0.94531 -8.0781,-2.8438c-1.9922,-1.9062 -2.9844,-4.4844 -2.9844,-7.7344c0,-3.2891 1.0078,-5.8789 3.0312,-7.7656c2.0312,-1.8828 4.8047,-2.8281 8.3281,-2.8281c1.3516,0 2.6484,0.13281 3.8906,0.39062c1.25,0.25 2.4258,0.625 3.5312,1.125l0,4.2188c-1.1484,-0.64453 -2.2812,-1.125 -3.4062,-1.4375c-1.125,-0.32031 -2.25,-0.48438 -3.375,-0.48438c-2.1055,0 -3.7266,0.58984 -4.8594,1.7656c-1.125,1.168 -1.6875,2.8398 -1.6875,5.0156c0,2.1562 0.54688,3.8242 1.6406,5c1.0938,1.1797 2.6445,1.7656 4.6562,1.7656c0.53906,0 1.0469,-0.03125 1.5156,-0.09375c0.47656,-0.07031 0.89844,-0.17969 1.2656,-0.32812l0,-3.9688l-3.2031,0l0,-3.5156l8.2031,0l0.0003,9.81258z" />
                </symbol>
                <symbol overflow="visible" id="u">
                    <path id="svg_10" d="m12.766,-2.5938c-0.66797,0.88672 -1.4062,1.543 -2.2188,1.9688c-0.8125,0.41797 -1.7578,0.625 -2.8281,0.625c-1.8672,0 -3.4062,-0.73438 -4.625,-2.2031c-1.2188,-1.4766 -1.8281,-3.3516 -1.8281,-5.625c0,-2.2891 0.60938,-4.1641 1.8281,-5.625c1.2188,-1.4688 2.7578,-2.2031 4.625,-2.2031c1.0703,0 2.0156,0.21484 2.8281,0.64062c0.8125,0.41797 1.5508,1.0742 2.2188,1.9688l0,-2.2656l4.9219,0l0,13.766c0,2.457 -0.77734,4.3359 -2.3281,5.6406c-1.5547,1.3008 -3.8086,1.9531 -6.7656,1.9531c-0.94922,0 -1.8711,-0.07422 -2.7656,-0.21875c-0.89844,-0.14844 -1.7969,-0.37109 -2.7031,-0.67188l0,-3.8125c0.86328,0.48828 1.7031,0.85156 2.5156,1.0938c0.82031,0.23828 1.6484,0.35938 2.4844,0.35938c1.6016,0 2.7734,-0.35156 3.5156,-1.0469c0.75,-0.69922 1.125,-1.7969 1.125,-3.2969l-0.0001,-1.04737zm-3.2188,-9.5312c-1.0117,0 -1.8047,0.375 -2.375,1.125c-0.5625,0.74219 -0.84375,1.7969 -0.84375,3.1719c0,1.3984 0.26953,2.4609 0.8125,3.1875c0.55078,0.71875 1.3516,1.0781 2.4062,1.0781c1.0195,0 1.8125,-0.36719 2.375,-1.1094c0.5625,-0.75 0.84375,-1.8008 0.84375,-3.1562c0,-1.375 -0.28125,-2.4297 -0.84375,-3.1719c-0.5625,-0.75 -1.3555,-1.125 -2.375,-1.125l0.00005,0z" />
                </symbol>
                <symbol overflow="visible" id="c">
                    <path id="svg_11" d="m9.6406,-12.188c-1.0859,0 -1.9141,0.39062 -2.4844,1.1719c-0.57422,0.78125 -0.85938,1.9062 -0.85938,3.375s0.28516,2.5938 0.85938,3.375c0.57031,0.77344 1.3984,1.1562 2.4844,1.1562c1.0625,0 1.875,-0.38281 2.4375,-1.1562c0.57031,-0.78125 0.85938,-1.9062 0.85938,-3.375s-0.28906,-2.5938 -0.85938,-3.375c-0.5625,-0.78125 -1.375,-1.1719 -2.4375,-1.1719zm0,-3.5c2.6328,0 4.6914,0.71484 6.1719,2.1406c1.4766,1.418 2.2188,3.3867 2.2188,5.9062c0,2.5117 -0.74219,4.4805 -2.2188,5.9062c-1.4805,1.418 -3.5391,2.125 -6.1719,2.125c-2.6484,0 -4.7148,-0.70703 -6.2031,-2.125c-1.4922,-1.4258 -2.2344,-3.3945 -2.2344,-5.9062c0,-2.5195 0.74219,-4.4883 2.2344,-5.9062c1.4883,-1.4258 3.5547,-2.1406 6.2031,-2.1406z" />
                </symbol>
                <symbol overflow="visible" id="t">
                    <path id="svg_12" d="m14.312,-14.828l0,3.7188c-1.043,-0.4375 -2.0547,-0.76562 -3.0312,-0.98438c-0.98047,-0.21875 -1.9023,-0.32812 -2.7656,-0.32812c-0.92969,0 -1.6211,0.11719 -2.0781,0.34375c-0.44922,0.23047 -0.67188,0.58984 -0.67188,1.0781c0,0.38672 0.17188,0.68359 0.51562,0.89062c0.34375,0.21094 0.95703,0.36719 1.8438,0.46875l0.85938,0.125c2.5078,0.32422 4.1953,0.85156 5.0625,1.5781c0.86328,0.73047 1.2969,1.8711 1.2969,3.4219c0,1.6367 -0.60547,2.8672 -1.8125,3.6875c-1.1992,0.8125 -2.9922,1.2188 -5.375,1.2188c-1.0234,0 -2.0742,-0.07812 -3.1562,-0.23438c-1.0742,-0.15625 -2.1797,-0.39453 -3.3125,-0.71875l0,-3.7188c0.96875,0.48047 1.9609,0.83984 2.9844,1.0781c1.0312,0.23047 2.0781,0.34375 3.1406,0.34375c0.95703,0 1.6758,-0.12891 2.1562,-0.39062c0.47656,-0.26953 0.71875,-0.66406 0.71875,-1.1875c0,-0.4375 -0.16797,-0.75781 -0.5,-0.96875c-0.33594,-0.21875 -0.99609,-0.38281 -1.9844,-0.5l-0.85938,-0.10938c-2.1797,-0.26953 -3.7031,-0.77344 -4.5781,-1.5156c-0.875,-0.73828 -1.3125,-1.8594 -1.3125,-3.3594c0,-1.625 0.55078,-2.8281 1.6562,-3.6094c1.1133,-0.78906 2.8203,-1.1875 5.125,-1.1875c0.89453,0 1.8359,0.07422 2.8281,0.21875c1,0.13672 2.082,0.35156 3.25,0.64062l-0.00009,0.00004z" />
                </symbol>
                <symbol overflow="visible" id="f">
                    <path id="svg_13" d="m17.75,-9.3281l0,9.3281l-4.9219,0l0,-7.1406c0,-1.3203 -0.03125,-2.2344 -0.09375,-2.7344s-0.16797,-0.86719 -0.3125,-1.1094c-0.1875,-0.3125 -0.44922,-0.55469 -0.78125,-0.73438c-0.32422,-0.17578 -0.69531,-0.26562 -1.1094,-0.26562c-1.0234,0 -1.8242,0.39844 -2.4062,1.1875c-0.58594,0.78125 -0.875,1.8711 -0.875,3.2656l0,7.5312l-4.8906,0l0,-15.312l4.8906,0l0,2.2344c0.73828,-0.88281 1.5195,-1.5391 2.3438,-1.9688c0.83203,-0.42578 1.75,-0.64062 2.75,-0.64062c1.7695,0 3.1133,0.54688 4.0312,1.6406c0.91406,1.0859 1.375,2.6562 1.375,4.7188l0,-0.00038z" />
                </symbol>
                <symbol overflow="visible" id="s">
                    <path id="svg_14" d="m12.422,-21.281l0,3.2188l-2.7031,0c-0.6875,0 -1.1719,0.125 -1.4531,0.375c-0.27344,0.25 -0.40625,0.6875 -0.40625,1.3125l0,1.0625l4.1875,0l0,3.5l-4.1875,0l0,11.812l-4.8906,0l0,-11.812l-2.4375,0l0,-3.5l2.4375,0l0,-1.0625c0,-1.6641 0.46094,-2.8984 1.3906,-3.7031c0.92578,-0.80078 2.3672,-1.2031 4.3281,-1.2031l3.73435,-0.0001z" />
                </symbol>
                <symbol overflow="visible" id="r">
                    <path id="svg_15" d="m16.547,-12.766c0.61328,-0.94531 1.3477,-1.6719 2.2031,-2.1719c0.85156,-0.5 1.7891,-0.75 2.8125,-0.75c1.7578,0 3.0977,0.54688 4.0156,1.6406c0.92578,1.0859 1.3906,2.6562 1.3906,4.7188l0,9.3281l-4.9219,0l0,-7.9844l0,-0.35938c0.00781,-0.13281 0.01562,-0.32031 0.01562,-0.5625c0,-1.082 -0.16406,-1.8633 -0.48438,-2.3438c-0.3125,-0.48828 -0.82422,-0.73438 -1.5312,-0.73438c-0.92969,0 -1.6484,0.38672 -2.1562,1.1562c-0.51172,0.76172 -0.77344,1.8672 -0.78125,3.3125l0,7.5156l-4.9219,0l0,-7.9844c0,-1.6953 -0.14844,-2.7852 -0.4375,-3.2656c-0.29297,-0.48828 -0.8125,-0.73438 -1.5625,-0.73438c-0.9375,0 -1.6641,0.38672 -2.1719,1.1562c-0.51172,0.76172 -0.76562,1.8594 -0.76562,3.2969l0,7.5312l-4.9219,0l0,-15.312l4.9219,0l0,2.2344c0.60156,-0.86328 1.2891,-1.5156 2.0625,-1.9531c0.78125,-0.4375 1.6406,-0.65625 2.5781,-0.65625c1.0625,0 2,0.25781 2.8125,0.76562c0.8125,0.51172 1.4258,1.2305 1.8438,2.1562l0.00003,-0.00023z" />
                </symbol>
                <symbol overflow="visible" id="q">
                    <path id="svg_16" d="m17.75,-9.3281l0,9.3281l-4.9219,0l0,-7.1094c0,-1.3438 -0.03125,-2.2656 -0.09375,-2.7656s-0.16797,-0.86719 -0.3125,-1.1094c-0.1875,-0.3125 -0.44922,-0.55469 -0.78125,-0.73438c-0.32422,-0.17578 -0.69531,-0.26562 -1.1094,-0.26562c-1.0234,0 -1.8242,0.39844 -2.4062,1.1875c-0.58594,0.78125 -0.875,1.8711 -0.875,3.2656l0,7.5312l-4.8906,0l0,-21.281l4.8906,0l0,8.2031c0.73828,-0.88281 1.5195,-1.5391 2.3438,-1.9688c0.83203,-0.42578 1.75,-0.64062 2.75,-0.64062c1.7695,0 3.1133,0.54688 4.0312,1.6406c0.91406,1.0859 1.375,2.6562 1.375,4.7188l0,-0.00008z" />
                </symbol>
                <symbol overflow="visible" id="p">
                    <path id="svg_17" d="m2.5781,-20.406l5.875,0l7.4219,14l0,-14l4.9844,0l0,20.406l-5.875,0l-7.4219,-14l0,14l-4.9844,0l0,-20.406z" />
                </symbol>
                <symbol overflow="visible" id="o">
                    <path id="svg_18" d="m2.1875,-5.9688l0,-9.3438l4.9219,0l0,1.5312c0,0.83594 -0.00781,1.875 -0.01563,3.125c-0.01171,1.25 -0.01562,2.0859 -0.01562,2.5c0,1.2422 0.03125,2.1328 0.09375,2.6719c0.07031,0.54297 0.17969,0.93359 0.32812,1.1719c0.20703,0.32422 0.47266,0.57422 0.79688,0.75c0.32031,0.16797 0.69141,0.25 1.1094,0.25c1.0195,0 1.8203,-0.39062 2.4062,-1.1719c0.58203,-0.78125 0.875,-1.8672 0.875,-3.2656l0,-7.5625l4.8906,0l0,15.312l-4.8906,0l0,-2.2188c-0.74219,0.89844 -1.5234,1.5586 -2.3438,1.9844c-0.82422,0.41406 -1.7344,0.625 -2.7344,0.625c-1.7617,0 -3.1055,-0.53906 -4.0312,-1.625c-0.92969,-1.082 -1.3906,-2.6602 -1.3906,-4.7344l0,0.0006z" />
                </symbol>
                <symbol overflow="visible" id="n">
                    <path id="svg_19" d="m2.5781,-20.406l8.7344,0c2.5938,0 4.582,0.57812 5.9688,1.7344c1.3945,1.1484 2.0938,2.7891 2.0938,4.9219c0,2.1367 -0.69922,3.7812 -2.0938,4.9375c-1.3867,1.1562 -3.375,1.7344 -5.9688,1.7344l-3.4844,0l0,7.0781l-5.25,0l0,-20.4063zm5.25,3.8125l0,5.7031l2.9219,0c1.0195,0 1.8047,-0.25 2.3594,-0.75c0.5625,-0.5 0.84375,-1.2031 0.84375,-2.1094c0,-0.91406 -0.28125,-1.6172 -0.84375,-2.1094c-0.55469,-0.48828 -1.3398,-0.73438 -2.3594,-0.73438l-2.9219,0.00008z" />
                </symbol>
                <symbol overflow="visible" id="m">
                    <path id="svg_20" d="m2.3594,-15.312l4.8906,0l0,15.031c0,2.0508 -0.49609,3.6172 -1.4844,4.7031c-0.98047,1.082 -2.4062,1.625 -4.2812,1.625l-2.4219,0l0,-3.2188l0.85938,0c0.92578,0 1.5625,-0.21094 1.9062,-0.625c0.35156,-0.41797 0.53125,-1.2461 0.53125,-2.4844l0.00007,-15.0309zm0,-5.9688l4.8906,0l0,4l-4.8906,0l0,-4z" />
                </symbol>
                <symbol overflow="visible" id="l">
                    <path id="svg_21" d="m14.719,-14.828l0,3.9844c-0.65625,-0.45703 -1.3242,-0.79688 -2,-1.0156c-0.66797,-0.21875 -1.3594,-0.32812 -2.0781,-0.32812c-1.3672,0 -2.4336,0.40234 -3.2031,1.2031c-0.76172,0.79297 -1.1406,1.9062 -1.1406,3.3438c0,1.4297 0.37891,2.543 1.1406,3.3438c0.76953,0.79297 1.8359,1.1875 3.2031,1.1875c0.75781,0 1.4844,-0.10938 2.1719,-0.32812c0.6875,-0.22656 1.3203,-0.56641 1.9062,-1.0156l0,4c-0.76172,0.28125 -1.5391,0.48828 -2.3281,0.625c-0.78125,0.14453 -1.5742,0.21875 -2.375,0.21875c-2.7617,0 -4.9219,-0.70703 -6.4844,-2.125c-1.5547,-1.4141 -2.3281,-3.3828 -2.3281,-5.9062c0,-2.5312 0.77344,-4.5039 2.3281,-5.9219c1.5625,-1.4141 3.7227,-2.125 6.4844,-2.125c0.80078,0 1.5938,0.07422 2.375,0.21875c0.78125,0.13672 1.5547,0.35156 2.3281,0.64062l0,-0.00018z" />
                </symbol>
            </defs>
            <g>
                <g id="svg_22">
                    <path id="svg_23" d="m1,420.8c0,-13.367 5.3086,-26.188 14.762,-35.641c9.4531,-9.4492 22.27,-14.762 35.637,-14.762c13.367,0 26.188,5.3125 35.641,14.762c9.4492,9.4531 14.762,22.273 14.762,35.641c0,13.367 -5.3125,26.188 -14.762,35.637c-9.4531,9.4531 -22.273,14.762 -35.641,14.762c-13.367,0 -26.184,-5.3086 -35.637,-14.762c-9.4531,-9.4492 -14.762,-22.27 -14.762,-35.637zm50.398,-319.2c13.367,0 26.188,-5.3125 35.641,-14.762c9.4492,-9.4531 14.762,-22.273 14.762,-35.641c0,-13.367 -5.3125,-26.188 -14.762,-35.637c-9.4531,-9.4531 -22.273,-14.762 -35.641,-14.762c-13.367,0 -26.184,5.3086 -35.637,14.762c-9.4531,9.4492 -14.762,22.27 -14.762,35.637c0,13.367 5.3086,26.188 14.762,35.641c9.4531,9.4492 22.27,14.762 35.637,14.762zm0,184.8l0,-0.00391c13.367,0 26.188,-5.3086 35.641,-14.762c9.4492,-9.4492 14.762,-22.27 14.762,-35.637s-5.3125,-26.188 -14.762,-35.637c-9.4531,-9.4531 -22.273,-14.762 -35.641,-14.762c-13.367,0 -26.184,5.3086 -35.637,14.762c-9.4531,9.4492 -14.762,22.27 -14.762,35.637s5.3086,26.188 14.762,35.637c9.4531,9.4531 22.27,14.762 35.637,14.762l0,0.00391zm168,-201.6l252,0c12.004,0 23.098,-6.4062 29.102,-16.801c6,-10.395 6,-23.203 0,-33.602c-6.0039,-10.395 -17.098,-16.797 -29.102,-16.797l-252,0c-12.004,0 -23.094,6.4023 -29.098,16.797c-6,10.398 -6,23.207 0,33.602c6.0039,10.395 17.094,16.801 29.098,16.801zm0,184.8l252,0c12.004,0 23.098,-6.4062 29.102,-16.801c6,-10.398 6,-23.203 0,-33.602c-6.0039,-10.395 -17.098,-16.801 -29.102,-16.801l-252,0c-12.004,0 -23.094,6.4062 -29.098,16.801c-6,10.398 -6,23.203 0,33.602c6.0039,10.395 17.094,16.801 29.098,16.801zm0,184.8l252,-0.00391c12.004,0 23.098,-6.4023 29.102,-16.797c6,-10.398 6,-23.207 0,-33.602c-6.0039,-10.395 -17.098,-16.801 -29.102,-16.801l-252,0c-12.004,0 -23.094,6.4062 -29.098,16.801c-6,10.395 -6,23.203 0,33.602c6.0039,10.395 17.094,16.797 29.098,16.797l0,0.00391z" />
                    <use id="svg_24" y="600" x="-27" />
                    <use id="svg_25" y="600" x="-6.44922" />
                    <use id="svg_26" y="600" x="7.35938" />
                    <use id="svg_27" y="600" x="26.34766" />
                    <use id="svg_28" y="600" x="45.24219" />
                    <use id="svg_29" y="600" x="58.62891" />
                    <use id="svg_30" y="600" x="77.61719" />
                    <use id="svg_31" y="600" x="107.41016" />
                    <use id="svg_32" y="600" x="127.45313" />
                    <use id="svg_33" y="600" x="155.45313" />
                    <use id="svg_34" y="600" x="178.43359" />
                    <use id="svg_35" y="600" x="192.24219" />
                    <use id="svg_36" y="600" x="211.23438" />
                    <use id="svg_37" y="600" x="231.27344" />
                    <use id="svg_38" y="600" x="250.51172" />
                    <use id="svg_39" y="600" x="274.07031" />
                    <use id="svg_40" y="600" x="294.61719" />
                    <use id="svg_41" y="600" x="308.42578" />
                    <use id="svg_42" y="600" x="327.41406" />
                    <use id="svg_43" y="600" x="344.08203" />
                    <use id="svg_44" y="600" x="364.01563" />
                    <use id="svg_45" y="600" x="382.91016" />
                    <use id="svg_46" y="628" x="-27" />
                    <use id="svg_47" y="628" x="-14.81641" />
                    <use id="svg_48" y="628" x="-1.00781" />
                    <use id="svg_49" y="628" x="18.22656" />
                    <use id="svg_50" y="628" x="57.15234" />
                    <use id="svg_51" y="628" x="70.53516" />
                    <use id="svg_52" y="628" x="90.46875" />
                    <use id="svg_53" y="628" x="119.20703" />
                    <use id="svg_54" y="628" x="142.64063" />
                    <use id="svg_55" y="628" x="161.87891" />
                    <use id="svg_56" y="628" x="181.8125" />
                    <use id="svg_57" y="628" x="211.49219" />
                    <use id="svg_58" y="628" x="232.01563" />
                    <use id="svg_59" y="628" x="245.82031" />
                    <use id="svg_60" y="628" x="265.05859" />
                    <use id="svg_61" y="628" x="274.65625" />
                    <use id="svg_62" y="628" x="293.64844" />
                    <use id="svg_63" y="628" x="310.24219" />
                </g>
            </g>
        </svg>

    )

}

export default List;