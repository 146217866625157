// const getTextWidth = (text, font) => {
//     const canvas = document.createElement('canvas');
//     const context = canvas.getContext('2d');
//     context.font = font;
//     return context.measureText(text).width;
// };

// export const calculatePositionForSuggestionBox = (position, inputRef, wrapperRef , current) => {
//     const textarea = current  ? current : inputRef?.current;
//     console.log("🚀 ~ calculatePositionForSuggestionBox ~ textarea:", textarea)
//     const container = wrapperRef.current;
//     console.log("🚀 ~ calculatePositionForSuggestionBox ~ container:", container)

//     if (!textarea || !container) return { top: 0, left: 0 };

//     // Get computed styles
//     const style = window.getComputedStyle(textarea);
//     const font = style.font;
//     const lineHeight = parseFloat(style.lineHeight);
//     const paddingLeft = parseFloat(style.paddingLeft);
//     const paddingTop = parseFloat(style.paddingTop);
    
//     // Measure the text up to the cursor position
//     const textBeforeCursor = textarea.value.substring(0, position);
//     const textWidth = getTextWidth(textBeforeCursor, font);

//     // Measure the text area dimensions and scroll position
//     const textareaRect = textarea.getBoundingClientRect();
//     const containerRect = container.getBoundingClientRect();
//     console.log("🚀 ~ calculatePositionForSuggestionBox ~ containerRect:", containerRect)
    
//     // Calculate cursor position relative to the textarea
//     const cursorLeft = textWidth + paddingLeft;
//     const cursorTop = lineHeight * (textarea.value.slice(0, position).split('\n').length - 1) + paddingTop;

//     // Apply scroll offsets
//     const adjustedLeft = cursorLeft - textarea.scrollLeft;
//     const adjustedTop = cursorTop - textarea.scrollTop;

//     // Calculate the position of the suggestion box relative to the container
//     let top = adjustedTop + textareaRect.top - containerRect.top;
//     let left = adjustedLeft + textareaRect.left - containerRect.left;

//     // Define the size of the suggestion box
//     const suggestionBoxHeight = 200;
//     const suggestionBoxWidth = 200;
//     const viewportBottom = window.innerHeight;
//     const viewportRight = window.innerWidth;
//     console.log("🚀 ~ calculatePositionForSuggestionBox ~ viewportRight:", viewportRight)
//     const boxBottom = top + suggestionBoxHeight;
//     const boxRight = left + suggestionBoxWidth;

//     // Adjust position if the suggestion box goes out of the viewport
//     if (boxBottom > viewportBottom) {
//         top -= boxBottom - viewportBottom; // Move up
//     }
//     if (boxRight > viewportRight) {
//         left -= boxRight - viewportRight; // Move left
//         console.log("🚀 ~ calculatePositionForSuggestionBox ~ left:", left)
//     }

//     // Ensure the suggestion box stays within bounds
//     if (left < 0) left = 0;
//     if (top < 0) top = 0;

//     return { top, left };
// };


const getTextWidth = (text, font) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;
    return context.measureText(text).width;
};

export const calculatePositionForSuggestionBox = (position, inputRef, wrapperRef, current) => {
    const textarea = inputRef?.current ? inputRef?.current : current;
    const container = wrapperRef.current;

    if (!textarea || !container) return { top: 0, left: 0 };

    // Get computed styles
    const style = window.getComputedStyle(textarea);
    const font = style.font;
    const lineHeight = parseFloat(style?.lineHeight) || 0;
    const paddingLeft = parseFloat(style.paddingLeft);
    const paddingTop = parseFloat(style.paddingTop);
    
    // Measure the text up to the cursor position
    const textBeforeCursor = textarea?.value?.substring(0, position);
    const textWidth = getTextWidth(textBeforeCursor, font);

    // Measure the text area dimensions and scroll position
    const textareaRect = textarea.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    
    // Calculate cursor position relative to the textarea
    const cursorLeft = textWidth + paddingLeft;
    const cursorTop = lineHeight * (textarea?.value?.slice(0, position)?.split('\n')?.length - 1) + paddingTop;

    // Apply scroll offsets
    const adjustedLeft = cursorLeft - textarea.scrollLeft;
    const adjustedTop = cursorTop - textarea.scrollTop;

    // Calculate the position of the suggestion box relative to the modal container
    let top = adjustedTop + textareaRect.top - containerRect.top;
    let left = adjustedLeft + textareaRect.left - containerRect.left;

    // Define the size of the suggestion box (or dynamically adjust based on content if needed)
    const suggestionBoxHeight = 200; // Or dynamically measure if needed
    const suggestionBoxWidth = 200;

    // Get modal container boundaries (use container dimensions instead of viewport)
    const containerBottom = containerRect.top + containerRect.height;
    const containerRight = containerRect.left + containerRect.width;
    
    // Adjust position if the suggestion box goes out of the modal container bounds
    const boxBottom = top + suggestionBoxHeight;
    const boxRight = left + suggestionBoxWidth;

    if (boxBottom > containerBottom) {
        top -= (boxBottom - containerBottom); // Move up to stay within modal
    }
    if (boxRight > containerRight) {
        left -= (boxRight - containerRight); // Move left to stay within modal
    }

    // Ensure the suggestion box stays within the modal's left and top bounds
    if (left < 0) left = 0;
    if (top < 0) top = 0;

    // Adjust if the suggestion box is too close to the modal edges (optionally add some padding)
    const padding = 10; // Add padding for better UX
    if (left + suggestionBoxWidth + padding > containerRect.width) {
        left = containerRect.width - suggestionBoxWidth - padding;
    }
    if (top + suggestionBoxHeight + padding > containerRect.height) {
        top = containerRect.height - suggestionBoxHeight - padding;
    }

    return { top, left };
};


export const calculatePositionForSuggestionBoxTextBox = (cursorPosition, textAreaRef, wrapperRef) => {
    const textarea = textAreaRef.current;
    const container = wrapperRef.current;

    if (!textarea || !container) return { top: 0, left: 0 };

    const text = textarea.value;
    textarea.focus();

    // Create a range and mirror div for accurate cursor positioning
    const mirrorDiv = document.createElement('div');
    mirrorDiv.style.position = 'absolute';
    mirrorDiv.style.visibility = 'hidden';
    mirrorDiv.style.whiteSpace = 'pre-wrap';
    mirrorDiv.style.wordWrap = 'break-word';
    mirrorDiv.style.font = window.getComputedStyle(textarea).font;
    mirrorDiv.style.lineHeight = window.getComputedStyle(textarea).lineHeight;
    mirrorDiv.style.width = `${textarea.clientWidth - 20}px`;
    mirrorDiv.style.padding = window.getComputedStyle(textarea).padding;
    mirrorDiv.style.border = window.getComputedStyle(textarea).border;
    mirrorDiv.textContent = text.substring(0, cursorPosition) || '.';
    container.appendChild(mirrorDiv);

    const span = document.createElement('span');
    span.textContent = '|';  // Temporary marker for cursor position
    mirrorDiv.appendChild(span);

    const spanRect = span.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    let top = spanRect.top - containerRect.top + container.scrollTop;
    let left = spanRect.left - containerRect.left + container.scrollLeft;

    container.removeChild(mirrorDiv);

    // Get viewport dimensions
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Suggestion box dimensions (adjust dynamically if needed)
    const suggestionBoxHeight = 200;  // Adjust height if necessary
    const suggestionBoxWidth = 180;

    // Place the suggestion box below the cursor, if possible
    const padding = 10;
    const boxBottomPosition = top + suggestionBoxHeight + padding;

    if (boxBottomPosition > viewportHeight) {
        // If not enough space below, place it above the cursor
        top = top - suggestionBoxHeight - padding;
    } else {
        // Otherwise, position it below the cursor
        top += padding;
    }

    // Ensure the suggestion box stays within the viewport horizontally
    if (left + suggestionBoxWidth > viewportWidth) {
        left = viewportWidth - suggestionBoxWidth - padding ;
    } else if (left < 0) {
        left = padding;
    }

    // Ensure the suggestion box remains within the textarea's visible area
    const visibleTop = container.scrollTop;
    const visibleBottom = visibleTop + container.clientHeight;

    if (top + suggestionBoxHeight > visibleBottom) {
        top = visibleBottom - suggestionBoxHeight - padding;
    } else if (top < visibleTop) {
        top = visibleTop + padding;
    }

    return { top, left };
};

