import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { getFromSession, removeItemFromSession, saveInSession } from "../../../utils/session.helper";
import { constants } from "../../../utils/constants";
import { COMPONENT_IDS, generate_element_id } from "../../../utils/elementids";
import { _getDataFromReportingServer, getPermissionById, get_matched_parm_from_query, hideLefter, saveRecentMainMenu, showPopup } from "../../../utils";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { getMenuGroups, getMenus } from "../../../actions/menu.group";
import { connect } from "react-redux";
import autoLayoutPopup from '../../layout-editor/auto.layout/form.popup';
import enums from "../../../utils/enums";
import MenuIcon from "../../svg/menu";
import Configure from "../../svg/menu/configure";
import Map from "../../svg/menu/map";
import Pinned from "../../svg/menu/pinned";
import Reports from "../../svg/menu/reports";
import ConverBi from "../../svg/menu/conver_bi";
import Xpress from "../../svg/menu/xpress";
import LogQuest from "../../svg/menu/log_quest";
import CxSentiment from "../../svg/menu/cx_sentiment";
import Decision from "../../svg/menu/decision";
import CdataProfile from "../../svg/menu/c_data_profile";
import EtlWiz from "../../svg/menu/etl_wiz";
import CreateBusinessRule from "../../svg/menu/create_business_rule";
import DataWrangler from "../../svg/menu/data_wrangling";
import DrillDown from "../../svg/menu/drillDown";
import SystemJob from "../../svg/menu/systemJob";
import TrainYourPolus from "../../svg/menu/trainYourPolus";
import VerifyRelationship from "../../svg/menu/verifyRelationship";
import DataCapture from "../../svg/menu/dataCapture";
import DataCache from "../../svg/menu/dataCache";
import ImportData from "../../svg/menu/importData";
import AskYourQuest from "../../svg/menu/askYourQuest";
import Pdf from "../../svg/menu/pdf";
import ArrowIcon from "../../svg/menu/arrowIcon";
import Dashboards from "../../svg/menu/dashboards";
import Datasource from "../../svg/menu/datasource";
import DisabledPopup from '../../helper/alert';
import EditMenuIcon from "../../svg/icons-v2/editMenuIcon";
import Add from "../../svg/add";
import PolusLogoHead from "../../svg/icons-v2/polusLogoHead";

const dropdownOptions = [
    {
        value: "Demo med",
        label: "Demo med",
    },
];




const ClientWrapper = styled.div`
    select {
        /* background: transparent; */
        background: #F3F3F3;
        /* border: 1px solid #000; */
        border: none;
        /* border-radius: 3px; */
        padding: 0.4rem;
        color: #000;
        width: 6rem;
        border-radius: 5px;

        option {
            color: #000;
        }
    }
`;


const slideInAnimation = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const MenuContainer = styled.div`
  padding: 0 1rem;
  height: 100vh;
  /* background: ${({ isRootMenuList }) => isRootMenuList ? "#fff" : "#f6f8fe"}; */
  background: #fff;
 
`;
const MainHeader = styled.div`
    /* background: #337dff; */
    background: #fff;
    height: 3rem;
    padding: 0.5rem 1rem 0.5rem 0.5rem; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg, img {
        cursor: pointer;
    }
`;

const MenuHeader = styled.div`
    background: linear-gradient(to right, #3E9EFF, #737DFF);
    color: #fff;
    height: 3rem;
    border-radius: 6px;
    text-transform: uppercase;
    font-size: 1rem;
    animation: ${slideInAnimation} 0.3s ease-in-out forwards;
    display: flex;
    align-items: center;
    padding: 10px 1.2rem;
    box-sizing: border-box;
    font-weight: 600;
    margin-bottom: 0.8rem;
    width: 99%;
`;

const MenuListContainer = styled.div`
    height: calc(100vh - 10rem);
    font-size: 0.9rem;
    font-weight: 600;
    overflow-y: auto;
    padding-right: 4px;
    &::-webkit-scrollbar {
        background-color: #3F3F3F;
        width:4px;
    }

    &::-webkit-scrollbar-track {
        background-color: #F3F3F3;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#dbdbdb;
        border-radius:10px;
        height: 4px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color:  #3F3F3F;
        height: 2px;
    }
`;
const SubMenuHeader = styled.div`
    font-weight: 600;
    padding: 1.2rem;
    box-sizing: border-box;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    animation: ${slideInAnimation} 0.3s ease-in-out forwards;
`;

const CarretIcon = styled.img`
    height: 10px;
    position: absolute;
    right: 28px;
    margin-top: 4px;
`;

const EditIcon = styled.div`
    position: absolute;
    right: 28px;
    margin-top: 4px;
    width: 1rem;
    height: 1rem;
    cursor: pointer;

    img{
        width: 100%;
    }
`;



const MenuItem = styled.div`
  padding: .9rem .5rem;
  padding-left: 1.3rem;
  margin-bottom: .7rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-transform: ${({ isRootMenuList }) => isRootMenuList && "uppercase"};
  animation: ${slideInAnimation} 0.3s ease-in-out forwards;
  box-sizing: border-box;
  border-radius: 6px;
  color: ${props => props.isDisabled ? "#a19f9f" : '#363030'};
  /* color: ${props => props.isDisabled ? "#a19f9f" : '#4e4646'}; */
  background: ${(props) => props.isSelectedMenu && '#EBECEF'};

  .menuArrowIcon{
    height: 10px;
    position: absolute;
    right: 10px;
    /* margin-top: 4px; */
    svg {
        transform:${(props) => props.isSelectedMenu ? 'rotate(270deg)' : 'rotate(90deg)'};
    }
  }

  svg{
    margin-right: 1rem;
  }

 
  &:hover {
    background: ${props => props.isDisabled ? '#fff' : 'linear-gradient(to right, #3E9EFF, #737DFF)'};
    color: ${props => props.isDisabled ? '#a19f9f' : '#fff !important'};
    & span{
        border-color: #fff !important;
    }
    svg {
      path {
        stroke: ${props => props.isDisabled ? "#a19f9f" : "#fff"};
        fill: ${props => props.isDisabled ? "#a19f9f" : "#fff"}
      }  
    }
  }
  

  /* color: #4e4646; */
  /* .main_menu_lb{
    font-size: .9rem;
    font-weight: 600;
  } */
`;

const Icon = styled.div`
  width: 1.3rem;
  height: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: auto;
  img{
    max-width: 100%;
    max-height: 100%;
  }
`;

const SubMenu = styled.div`
  /* padding-left: 20px; */
  margin-top: 0.8rem; 
  font-size: 0.84rem;
`;

const Separator = styled.hr`
  border: none;
  border-top: 1px solid #9e9a9abf;
  margin-left: -10px;
`;

const Footer = styled.div`
  position: absolute;
  bottom: 10px;
  width: 100%;
`;
const FooterWrapper = styled.div`
  padding: 12px;
  display: flex;
  justify-content: end;
  align-items: center;
  width: 88%;
`;



const Logo = styled.img`
  width: 50%;
  margin-right: 10px;
`;

const DropdownSelect = styled.select`
    padding: 2px;
    width: 40%;
    font-size: 1em;
    background: #f3f3f3;
    border-radius: 4px;
`;


const static_menu_items = [
    {
        label: "Data source connector",
        icon: 'datasource',
        path: 'connection',
        elementId: "DATA_SOURCE"
    },
    {
        label: "Vady",
        icon: 'chat',
        path: 'polus_ai',
        elementId: "POLUS_AI",
        hide: (process.env.REACT_APP_MODE === "srpl" || process.env.REACT_APP_MODE === "hrpl" || process.env.REACT_APP_MODE === "brac"),

    },
    {
        label: "Configure and train",
        icon: 'configure',
        elementId: "configure_train",
        children: [
            {
                label: "Train Your PolusAI",
                elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'TRAIN_YOUR_POLUS'),
                path: 'train_polus',
                icon: 'train_polus',

            },
            {
                label: "Configure Drill Down",
                elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'FACT_DIMENSIONS'),
                path: 'data_hierarchy',
                icon: 'drill_down'

            },
            {
                label: "Create Business Rules",
                icon: 'create_business_rule',
                path: "db_formula",
                elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'BUSINESS_RULES'),
                // children: [
                // {
                //     label: "Derived Column",
                //     path: "rules",
                //     elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'DERIVED_COLUMN')
                // },
                // {
                //     label: "Rules Over Values",
                //     path: "rules_over_values",
                //     elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'RULES_OVER_VALUES')
                // },
                // {
                //     label: "Human Interpretation",
                //     path: "human_interpretation",
                //     elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'HUMAN_INTERPRETATION')
                // },
                // {
                //     label: "Formula Builder",
                //     path: "db_formula",
                //     elementId: "FORMULA_BUILDER"
                // },
                // ]


            },
            {
                label: "Rule",
                elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'BUSINESS_RULES'),
                path: 'rule_alert',
                icon: 'create_business_rule'

            },
            {
                label: "Secure Access Layer",
                icon: 'data_cylinder',
                elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'SECURE_ACCESS_LAYER'),
                children: [
                    {
                        label: "Users",
                        path: "users_list",
                        elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'USERS')
                    },
                    {
                        label: "User Roles",
                        path: "module_access",
                        elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'USER_ROLES')
                    },
                    {
                        label: "Data Mask",
                        path: "security",
                        elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'DATA_MASK')
                    },
                    {
                        label: "Data Policy",
                        path: "data_policy",
                        elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'DATA_POLICY')
                    }
                ]

            },
            {
                label: "View Table Relationship",
                // label: "Verify Relationship",
                elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'RELATIONSHIP'),
                path: 'relationship',
                icon: 'verify_relationship'

            },
            {
                label: "Data Cache",
                elementId: 'data_cache',//generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'BUSINESS_RULES'),
                icon: 'data_cache'

            },
            {
                label: "System Jobs",
                elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'BUSINESS_RULES'),
                icon: 'system_job'

            },


        ],
    },
    {
        label: "Conversatix BI",
        icon: 'conver_bi',
        elementId: "conversatix_bi",
        children: [
            {
                label: "Dashboards",
                elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.CONVERSATIX_BI, 'DASHBOARDS'),
                icon: 'dashboards',
                children: [
                    {
                        label: "Create Dashboards",
                        icon: "add_icon",
                        elementId: COMPONENT_IDS.SHARED_ACTIONS.CREATE_DASHBOARD,
                        path: 'layout_editor',
                    },
                    {
                        label: "Quick Dashboard",
                        icon: "add_icon",
                        elementId: COMPONENT_IDS.SHARED_ACTIONS.CREATE_QUICK_DASHBOARD,
                        function: "quick_dashboard"

                    },
                    {
                        label: "View Dashboard",
                        icon: "view_eye_icon",
                        elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.CONVERSATIX_BI, 'DASHBOARDS'),
                        editId: COMPONENT_IDS.SHARED_ACTIONS.EDIT_DASHBOARD,
                        children: [

                        ],
                        value: 'view_dashboards'

                    }
                ]

            },
            {
                label: "Reports",
                elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.CONVERSATIX_BI, 'REPORTS'),
                icon: 'reports',
                children: [
                    {
                        label: "Create Report",
                        icon: "add_icon",
                        elementId: COMPONENT_IDS.SHARED_ACTIONS.CREATE_REPORT,
                        path: 'config_editor',
                    },

                    {
                        label: "View Reports",
                        icon: "view_eye_icon",
                        elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.CONVERSATIX_BI, 'REPORTS'),
                        editId: COMPONENT_IDS.SHARED_ACTIONS.EDIT_REPORT,
                        // path: 'report_list'
                        children: [

                        ],
                        value: 'view_reports'
                    }
                ]

            },
            {
                label: "Your pinned Insights",
                elementId: 'PINNED_ITEMS',
                // path: 'train_polus',
                icon: 'pinned',
                children: [

                ],
                value: 'pinned_insights'


            },
            {
                label: "Map",
                elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.CONVERSATIX_BI, 'MAP'),
                path: 'train_polus',
                main_title: 'GEO ANALYTICS',
                icon: 'map',
                bdr: true,
                children: [
                    {
                        label: "Create Map",
                        elementId: COMPONENT_IDS.SHARED_ACTIONS.CREATE_MAP,
                        path: 'layout_editor?map_editor=true',

                    },
                    {
                        label: "View Map List",
                        elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.CONVERSATIX_BI, 'MAP'),
                        path: 'insightsList?type=maps',
                        editId: COMPONENT_IDS.SHARED_ACTIONS.EDIT_MAP

                    },

                ],

            },
            {
                label: "Connect insights to Action",
                icon: 'map',
                elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.CONVERSATIX_BI, 'MAP'),
                path: 'rule_alert',

            },
        ],
    },
    {
        label: "Text Quester",
        icon: 'text queste',
        elementId: 'TEXT_QUESTER',
        children: [
            {
                label: "Upload Your PDF",
                icon: 'upload_pdf',
                elementId: 'UPLOAD_PDF',
                path: 'question_search',

            },
            {
                label: "Ask a Data question",
                icon: 'ask_question',
                elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.CONVERSATIX_BI, 'TEXT_ANALYTICS'),
                path: 'question_search',

            },
            {
                label: "Import Data",
                icon: 'import_data',
                elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.CONFIGURE_TRAIN, 'IMPORT_DATA'),
                path: 'question_search?type=importData',
            }
        ],
    },

    {
        label: "File Jobs",
        elementId: 'ANY',
        icon: 'data_wrangling',
        path: '',
        children: [
            { label: "Create File Job", path: "etl_editor?job_type=google_drive_upload", elementId: 'ANY' },
            { label: "View File Jobs", path: "etl_jobs?job_type=google_drive_upload", elementId: 'ANY' },
            { label: "View File Job Logs", path: "file_logs", elementId: 'ANY' },

        ],

    },
    {
        label: "ETL Wiz",
        icon: 'etl_wiz',
        elementId: 'etl_wiz',
        hide: process.env.REACT_APP_MODE === "srpl",
        children: [
            {
                label: "Data Capture",
                elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.ETL_WIZ, 'DATA_CAPTURE'),
                icon: 'view_eye_icon',
                children: [
                    { label: "RDBMS", path: "rdbms_ingestion", elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.ETL_WIZ, 'RDBMS') },
                    { label: "NoSQL", path: "", elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.ETL_WIZ, 'NOSQL') },
                    { label: "CDC", path: "", elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.ETL_WIZ, 'CDC') },
                    { label: "Files", path: "question_search?type=importData", elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.ETL_WIZ, 'FILES') },
                    { label: "HDFS/AWS(S3)", path: "", elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.ETL_WIZ, 'HDFS_AWS') },
                    { label: "Queue", path: "", elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.ETL_WIZ, 'QUEUE') },
                    { label: "API Ingestion", path: "", elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.ETL_WIZ, 'API_INGESTION') },
                    { label: "Event Capture", path: "", elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.ETL_WIZ, 'EVENT_CAPTURE') },
                    { label: "Streaming Data", path: "streaming", elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.ETL_WIZ, 'STREAMING_DATA') },
                ]

            },
            {
                label: "Data Wrangler",
                elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.ETL_WIZ, 'DATA_WRANGLING'),
                icon: 'data_wrangling',
                path: '',
                children: [
                    { label: "Create Wrangling Job", path: "etl_editor", elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.ETL_WIZ, 'ETL_DATA_WRANGLING_JOBS') },
                    { label: "View Data Wrangling Jobs", path: "etl_jobs", elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.ETL_WIZ, 'ETL_DATA_WRANGLING_JOBS') },
                    { label: "Data Sync Up", path: "data_sync_up", elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.ETL_WIZ, 'DATA_SYNC_UP') },
                    { label: "System Jobs", path: "etl_jobs?job_type=system_job", elementId: generate_element_id(COMPONENT_IDS.MAIN_MENU.ETL_WIZ, 'DATA_SYNC_UP') }
                ],

            },
        ],
    },
    {
        label: "Xpresss stream",
        icon: 'xpress',
        elementId: 'xpress'
    },
    {
        label: "Log quester",
        icon: 'log_quest',
        elementId: 'LOG_QUESTER'
    },
    {
        label: "Sentiment quester",
        icon: 'cx_sentiment',
        elementId: 'SENTIMENT_QUESTER'
    },
    {
        label: "Decision enabler",
        icon: 'desision',
        elementId: 'DECISION_ENABLE'
    },
    {
        label: "Customer data profile",
        icon: 'c_data_profile',
        elementId: 'CUSTOMER_DATA_PROFILE'
    },
];


const getIconFn = (iconType) => {
    let icon = '';

    switch (iconType) {
        case 'dashboards':
            icon = <Dashboards size='1.3rem' height='1.3rem' color="#afc4e3" />
            break;
        case 'datasource':
            icon = <Datasource size='1.3rem' height='1.3rem' color="#afc4e3" />
            break;
        case 'chat':
            icon = <PolusLogoHead size='1.3rem' height='1.3rem' color="#afc4e3" />
            break;
        case 'data_wrangling':
            icon = <DataWrangler size='1.3rem' height='1.3rem' color="#afc4e3" />
            break;
        case 'upload_pdf':
            icon = <Pdf size='1.3rem' height='1.3rem' color="#afc4e3" />
            break;
        case 'ask_question':
            icon = <AskYourQuest size='1.3rem' height='1.3rem' color="#afc4e3" />
            break;
        case 'import_data':
            icon = <ImportData size='1.3rem' height='1.3rem' color="#afc4e3" />
            break;
        case 'data_cache':
            icon = <DataCache size='1.3rem' height='1.3rem' color="#afc4e3" />
            break;
        case 'etl_wiz':
            icon = <EtlWiz size='1.3rem' height='1.3rem' color="#afc4e3" />
            break;
        case 'map':
            icon = <Map size='1.3rem' height='1.3rem' color="#afc4e3" />
            break;
        case 'pinned':
            icon = <Pinned size='1.3rem' height='1.3rem' color="#afc4e3" />
            break;
        case 'reports':
            icon = <Reports size='1.3rem' height='1.3rem' color="#afc4e3" />
            break;
        case 'conver_bi':
            icon = <ConverBi size='1.3rem' height='1.3rem' color="#afc4e3" />
            break;
        case 'text queste':
            icon = <AskYourQuest size='1.3rem' height='1.3rem' color="#afc4e3" />
            break;
        case 'data_cylinder':
            icon = <DataCache size='1.3rem' height='1.3rem' color="#afc4e3" />
            break;
        case 'verify_relationship':
            icon = <VerifyRelationship size='1.3rem' height='1.3rem' color="#afc4e3" />
            break;
        case 'create_business_rule':
            icon = <CreateBusinessRule size='1.3rem' height='1.3rem' color="#afc4e3" />
            break;
        case 'drill_down':
            icon = <DrillDown size='1.3rem' height='1.3rem' color="#afc4e3" />
            break;
        case 'train_polus':
            icon = <TrainYourPolus size='1.3rem' height='1.3rem' color="#afc4e3" />
            break;
        case 'configure':
            icon = <Configure size='1.3rem' height='1.3rem' color="#afc4e3" />
            break;
        case 'connection':
            // icon = <Conne size='1.3rem' height='1.3rem' color="#afc4e3" />
            break;
        case 'view_eye_icon':
            icon = <DataCapture size='1.3rem' height='1.3rem' color="#afc4e3" />
            break;
        case 'c_data_profile':
            icon = <CdataProfile size='1.3rem' height='1.3rem' color="#afc4e3" />
            break;
        case 'desision':
            icon = <Decision size='1.3rem' height='1.3rem' color="#000000" />
            break;
        case 'cx_sentiment':
            icon = <CxSentiment size='1.3rem' height='1.3rem' color="#000000" />
            break;
        case 'log_quest':
            icon = <LogQuest size='1.3rem' height='1.3rem' color="#8f4949" />
            break;
        case 'system_job':
            icon = <SystemJob size='1.3rem' height='1.3rem' color="#8f4949" />
            break;
        case 'xpress':
            icon = <Xpress size='1.3rem' height='1.3rem' color="#8f4949" />
            break;
        case 'add_icon':
            icon = <Add size='1.3rem' height='1.3rem' color="#afc4e3" />
            break;
        default:
            break;
    };
    return icon;
}


const MainMenu = (props) => {  // height: 20px;
    const { whichMenuOpen, changeMenu, menuPermissions } = props;
    const { getMenus } = props;

    const clientIdFromSession = getFromSession(constants.SESSION_KEYS.CLIENT_ID);

    const [openMenus, setOpenMenus] = useState({});
    const [isRootMenuList, setIsRootMenuList] = useState(true);

    const [currentMenuList, setcurrentMenuList] = useState([...static_menu_items]);

    const [activeMenu, setActiveMenu] = useState(undefined);

    const [local_report_menus, set_local_report_menus] = useState();
    const [pinnedItems, setPinnedItems] = useState()


    const [openRootMenuId, setOpenRootMenuId] = useState(undefined)



    console.log("process.env.REACT_APP_MODE", process.env.REACT_APP_MODE)


    useEffect(() => {

        const clone_report_menus = props?.report_menus ? JSON.parse(JSON.stringify(props?.report_menus)) : {};

        const dashboard_menu = (clone_report_menus?.['dashboard_menu'] || []).map((e) => {
            return {
                ...e,
                elementId: "groups"
            }
        });
        dashboard_menu.push({ label: "View All", path: 'insightsList?type=insights', elementId: 'view_all_dashboard' })

        const report_menu = (clone_report_menus?.['report_menu'] || []).map((e) => {
            return {
                ...e,
                elementId: "groups"
            }
        });
        report_menu.push({ label: "View All", path: 'report_list', elementId: 'view_all_dashboard' })

        clone_report_menus['dashboard_menu'] = dashboard_menu;
        clone_report_menus['report_menu'] = report_menu;

        set_local_report_menus(clone_report_menus)

    }, [props?.report_menus])


    useEffect(() => {
        const clone_pinned_items = props.menus ? [...props.menus] : [];
        const pinned_items = clone_pinned_items.map((item) => {
            return {
                ...item,
                elementId: 'groups'
            }
        })
        setPinnedItems(pinned_items)
    }, [props.menus])

    useEffect(() => {
        getMenus();
        // if (recentMainMenu && recentMainMenu.length > 0) {
        //     setWhichMenuOpen(recentMainMenu);
        // }
        props.getMenuGroups()
        if (props.recentMainMenu) {
            // setOpenMenus(props.recentMainMenu)
        }
        // eslint-disable-next-line 
    }, []);



    const toggleMenuItem = (index, children, isRootMenuList, rootId) => {
        // If it's a root menu list, update current menu list state
        if (isRootMenuList) {
            setcurrentMenuList({ ...children });
        }
        // Set flag indicating it's not a root menu list
        setIsRootMenuList(false);
        setActiveMenu(index)
        // Clone the openMenus state to avoid mutating the original state
        const clonedOpenMenus = openMenus ? { ...openMenus } : {};
        // Initialize a new object to store updated openMenus state
        const newOpenMenus = {};

        // If there are open menus in the state
        if (clonedOpenMenus && Object.keys(clonedOpenMenus)?.length > 0) {
            // Iterate through each key in the cloned openMenus state
            Object.keys(clonedOpenMenus).forEach((key) => {
                // Split the index by '__nf__' delimiter to get the hierarchy levels
                const splitIndex = index?.split("__nf__");
                // If there are multiple levels, remove the last level
                if (splitIndex?.length >= 1) splitIndex.pop();
                // Join the remaining levels to compare with the key
                const keyToCompare = Array.isArray(splitIndex) ? splitIndex.join("__nf__") : splitIndex;
                // Get the first menu from the split index
                const firstMenu = splitIndex?.length > 0 && splitIndex?.[0];
                // If the key matches the index or the first menu, copy it to the new state
                if (key === keyToCompare || key === firstMenu) {
                    newOpenMenus[key] = clonedOpenMenus[key];
                }
            });
        }

        // Update the openMenus state with the new state, toggling the menu item
        setOpenMenus({
            ...newOpenMenus,
            [index]: !isRootMenuList ? !openMenus[index] : false,
        });

        saveRecentMainMenu({
            ...newOpenMenus,
            [index]: !isRootMenuList ? !openMenus[index] : false,
            rootMenuId: (rootId || openRootMenuId)
        })
    };

    const handleReturnMainMenu = (e) => {
        e.stopPropagation();
        setcurrentMenuList([...static_menu_items]);
        setIsRootMenuList(true);
        setOpenMenus({});
        saveRecentMainMenu()
    };



    const handleMenuClick = (main_type, sub_type, action_type, data) => {
        hideLefter();
        if (main_type === "insight" && data) {
            const id = data && data.id;

            if (action_type === "view") {
                const path = `/?insightId=${id}`;
                props.history.push(path);
            } else if (action_type == "edit") {

                const path = `/layout_editor?edit=true&reportId=${id}`;
                props.history.push(path);

            }

        }

        if (main_type === "report" && data) {
            const report = data;
            const id = data && data.id;

            if (action_type === "view") {

                const report_type = report.type;
                const report_item = report?.report_items?.length > 0 && report?.report_items?.[0];
                const sql_parameterized_fields = report_item.sql_query && get_matched_parm_from_query(report_item.sql_query);

                const criteria = report_item?.criteria;
                const parameterized_fields = criteria && get_matched_parm_from_query(criteria);
                const filter_default_value_json = JSON.parse(report?.filter_default_value_json || '{}')

                if (parameterized_fields && Object.keys(parameterized_fields).length > 0) {
                    // open_parameterized_fields_popup(report, Object.keys(parameterized_fields), filter_default_value_json)
                }
                else if (sql_parameterized_fields && Object.keys(sql_parameterized_fields).length > 0 && report_type === 'sql') {
                    // open_parametrize_popup_sql(report, report_item.sql_query, sql_parameterized_fields, filter_default_value_json)
                }

                else if (report.type === 'config_query_builder' || report.type === 'data_source' || report.type === 'cache_editor' || report.type === 'sql') {
                    const db_info_id = report_item?.db_info_id ? report_item?.db_info_id : undefined;

                    _getDataFromReportingServer(undefined, id, undefined, report_item.id, data.type, undefined, props.history, undefined, db_info_id, parameterized_fields, filter_default_value_json)
                }

            } else if (action_type == "edit") {
                props.history.push(`config_editor?report_id=${id}`)

            }

        }
        if (main_type === 'pinned_item') {
            _getDataFromReportingServer(data.query, undefined, data?.report_id, undefined, undefined, undefined, props.history, undefined, undefined, undefined, undefined, true)
        }
    }



    const open_layout_editor_popup = () => {

        showPopup('Dashboard Input Form', undefined, enums.popupType.element_with_header, autoLayoutPopup,
            {
                hide_action: hideLefter,
                forword: true

            }, undefined, undefined, '#515355d6', {
            width: '65rem',
        },
            true,
            true
        )
    }

    const show_disabled_popup = () => {
        showPopup(undefined, undefined, enums.popupType.element, DisabledPopup,)
    }



    const navigateTo = (type, param) => {
        hideLefter();
        if (type) {
            const path = param ? `/${type}${param}` : `/${type}`;
            props.history.push(path);
        }
    };



    const click_event = (type) => {

        if (type === 'quick_dashboard') {
            open_layout_editor_popup()
        }
    }

    const clients_string = getFromSession(constants.SESSION_KEYS.CLIENTS);

    let clients = [];

    if (clients_string && clients_string.length > 0) {
        clients = JSON.parse(clients_string);
    }


    const clientId = clientIdFromSession;


    const renderMenuItems = (data, parentIndex = '', parent_value, isParentSelected, isSiblinghasSubMenu) => {

        const items = Array.isArray(data) ? data : data?.children;
        const checkChildrenHaveSubMenu = items.filter(el => el?.children)?.length > 0;

        return items?.map((item, index) => {

            if (item?.hide) {
                return <></>
            }
            var isDisabled = !(getPermissionById(item?.elementId, menuPermissions, clientId));
            if (item?.elementId === "ANY") {
                isDisabled = false
            }
            // if (!getPermissionById(item?.elementId, menuPermissions, clientId) && item?.elementId) {
            //     return <></>
            // }

            if (item?.type === "insights" || item?.type === "config_query_builder" || item?.type === 'sql' || item?.menu_group_id && item?.query || item?.value === 'pinned_insights') {
                isDisabled = false
            }
            const itemIndex = parentIndex + index;
            var child_data = item?.children || item?.menus || item?.favorites;
            if (item?.value === "view_dashboards") {
                child_data = local_report_menus['dashboard_menu'];
            }
            if (item?.value === "view_reports") {
                child_data = local_report_menus['report_menu'];
            }
            if (item?.value === 'pinned_insights') {
                child_data = pinnedItems
            }


            const hasSubMenu = child_data?.length > 0;

            const value = (item?.value ? item?.value : '') + (parent_value ? parent_value : '')
            const element_type = item?.type;
            const show_edit_btn = (element_type === "insights" || element_type === "config_query_builder" || element_type === 'sql');
            const isSelectedMenu = openMenus?.[itemIndex];
            let isLeaf = !isRootMenuList && !hasSubMenu && (Object.keys(openMenus)[Object.keys(openMenus)?.length - 1]) + '__nf__' === parentIndex;

            return (
                <div
                    key={itemIndex}
                    className="scrollY"
                // className="scrollY"
                // style={{ background: (activeMenu === itemIndex && openMenus[itemIndex]) ? '#e2e2e2' : undefined }}
                >
                    <MenuItem
                        isDisabled={isDisabled}
                        isRootMenuList={isRootMenuList}
                        isSelectedMenu={isSelectedMenu}
                        onClick={(e) => {

                            if (isDisabled) {
                                show_disabled_popup()
                            }
                            else {
                                e.stopPropagation()
                                e.preventDefault()
                                if (isRootMenuList) {
                                    setOpenRootMenuId(item?.elementId)
                                }

                                hasSubMenu && toggleMenuItem(itemIndex, item, isRootMenuList, (isRootMenuList ? item?.elementId : undefined));

                                if (!hasSubMenu) {
                                    if (item?.type === "insights") {
                                        // handle dashboard 
                                        handleMenuClick("insight", undefined, "view", item)
                                    }
                                    else if (item?.type === "config_query_builder" || item?.type === 'sql') {
                                        // handle report 
                                        handleMenuClick("report", item?.type, "view", item)

                                    }
                                    else if (item?.menu_group_id && item?.query) {
                                        handleMenuClick("pinned_item", undefined, "view", item)

                                    }
                                    else if (item?.function) {
                                        click_event(item?.function)
                                    }
                                    else {
                                        navigateTo(item?.path)
                                    }
                                }
                            }
                        }}
                    >
                        {item.icon && getIconFn(item.icon)}

                        {
                            !item.icon && ((!isSiblinghasSubMenu) && isParentSelected
                                ? <span style={{ width: "8px", height: '8px', borderRadius: '50%', background: 'grey', marginRight: '1rem' }}></span>
                                : <span style={{ width: "4px", height: '4px', borderRadius: '50%', border: '1px solid grey', marginRight: '1rem' }}></span>
                            )}

                        <span className="main_menu_lb">{item.label || item.title || item.name}</span>
                        <span className='menuArrowIcon'>
                            {hasSubMenu && (
                                <ArrowIcon size='0.9rem' height='0.9rem' color="#4e4646" />
                            )}
                        </span>
                        {!hasSubMenu && show_edit_btn && (
                            <EditIcon title="Edit" onClick={(e) => {
                                e.stopPropagation()
                                if (!hasSubMenu) {
                                    if (item?.type === "insights") {
                                        handleMenuClick("insight", undefined, "edit", item)
                                    }
                                    else if (item?.type === "config_query_builder" || item?.type === 'sql') {
                                        handleMenuClick("report", undefined, "edit", item)
                                    }
                                }
                            }}>
                                <EditMenuIcon height={'1rem '} size={'1rem'} color={'#afc4e3'} />
                                {/* <img src={constants.CONTEXT_PATH + `menu/edit.png`} alt="edit icon" /> */}
                            </EditIcon>
                        )}

                    </MenuItem>

                    {hasSubMenu && openMenus[itemIndex] && (
                        <SubMenu isSelectedMenu={isSelectedMenu} >{renderMenuItems(child_data, `${itemIndex}__nf__`, value, isSelectedMenu, checkChildrenHaveSubMenu)}</SubMenu>
                    )}
                </div>
            );
        });
    };



    useEffect(() => {
        // this block will open recent menu automatically
        const clone_recent_menu = props.recentMainMenu ? { ...props.recentMainMenu } : {};
        const rootMenuId = clone_recent_menu?.rootMenuId;
        delete clone_recent_menu[rootMenuId];

        if (rootMenuId) {
            const menu_data = static_menu_items?.find((e) => e.elementId === rootMenuId);
            setIsRootMenuList(false);
            setOpenRootMenuId(rootMenuId)
            setcurrentMenuList(menu_data)
            setOpenMenus(clone_recent_menu)
        }
    }, [])




    return (
        <>
            <MenuContainer isRootMenuList={isRootMenuList} >
                <MainHeader>
                    <Logo src={constants.CONTEXT_PATH + `/logo.png`} />
                    {!isRootMenuList &&
                        // ? <MenuIcon color={'grey'} size="1rem" height="1rem" />
                        <Icon title="Back to main menu" onClick={handleReturnMainMenu} >
                            <img src={constants.CONTEXT_PATH + `menu/bk_arrow.png`} alt="arrow icon" />
                        </Icon>}
                </MainHeader>

                {!isRootMenuList && (<>
                    <MenuHeader isRootMenuList={isRootMenuList}>
                        {!isRootMenuList && currentMenuList.label}
                    </MenuHeader>
                </>
                )}

                <MenuListContainer>
                    {renderMenuItems(currentMenuList)}
                </MenuListContainer>

                {isRootMenuList && (
                    <Footer>
                        <Separator />
                        <FooterWrapper>
                            {getPermissionById("APP_CLIENT_ID", menuPermissions, clientId) && (
                                <ClientWrapper>
                                    <select
                                        onChange={(event) => {
                                            saveInSession(constants.SESSION_KEYS.CLIENT_ID, event.target.value);
                                            removeItemFromSession(constants.SESSION_KEYS.THEME)
                                            removeItemFromSession(constants.SESSION_KEYS.DATABASE_ID)
                                            constants.CLIENT_ID = event.target.value;
                                            props.history.push('/')
                                            window.location.reload(false);
                                        }}
                                        value={clientIdFromSession}
                                    >
                                        {
                                            clients && clients.length > 0 && clients.map((client, index) => {
                                                return <option key={'client' + index} value={client.client_id}>{client.client_id}</option>
                                            })
                                        }
                                    </select>
                                </ClientWrapper>
                            )}

                        </FooterWrapper>
                    </Footer>
                )}
            </MenuContainer>
        </>
    );
};






const mapStateToProps = (state) => {

    const { menus } = state.menuGroupReducer;
    const { recentMainMenu } = state.helperReducer;
    const menuPermissions = state.permissionReducer?.permissions
    const { insightsMenu, activeInsight } = state.insightReducer;
    const { clientsInfo } = state.clientInfoReducer;
    const dataFromServer = state.reportReducer.dataFromServer;
    const menu_group = state.menuGroupReducer?.menu_groups_cache?.['report'];
    const report_menus = state.menuGroupReducer?.report_menus;
    return { report_menus, menu_group, menus, recentMainMenu, insightsMenu, activeInsight, clientsInfo, menuPermissions, dataFromServer }
}

export default withRouter(connect(mapStateToProps, { getMenuGroups, getMenus })(MainMenu));
