import * as actions from '../action-types/streaming'


const initialState = {
    type: actions.STREAMING_INIT,
    streamingServiceData: undefined,
    streamingJobData: undefined,
    currentStreamingJob: undefined,
    currentEditedStream: undefined
};



const streamingReducer = (state, action) => {


    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        case actions.GET_STREAMING_DATA_SERVICE:
            return {
                ...state,
                type: action.type,
                streamingServiceData: action.data
            };

        case actions.GET_STREAMING_JOBS:
            return {
                ...state,
                type: action.type,
                streamingJobData: action.data
            }
        case actions.GET_CURRENT_STREAMING_JOBS:
            return{
                ...state,
                type: action.type,
                currentStreamingJob: action.data
            }
        case actions.CLEAR_CURRENT_STREAMING_JOBS:
            return{
                ...state,
                type: action.type,
                currentStreamingJob: undefined
            }
        case actions.PUSH_EDITED_STREAM_ROW_TO_STORE:
            let payload = action.data;
            let existing_edited_row = state.currentEditedStream ? JSON.parse(JSON.stringify(state.currentEditedStream)) : {};
            existing_edited_row[payload.id] = payload
            return {
                ...state,
                type: action.type,
                currentEditedStream: existing_edited_row,

            }

        default:
            return {
                ...state,
            }
    }
}

export default streamingReducer;