import React, { useEffect } from 'react';
import ReactJson from 'react-json-view';
import { connect } from 'react-redux';

import { getMongoDbTableData } from '../../../actions/helper';

import styled from 'styled-components';
import CloseSvg from '../../svg/close';


const MainWrapper = styled.div`
    width: 56.429rem;
    height: 31.214rem;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.25);
    border: solid 5px rgb(234,234,234);
    background-color: rgb(255,255,255);
    padding: 1.643rem 1.429rem;
    overflow-y: auto;
    overflow-x: hidden;

    h1{
        margin: 0px;
        text-transform: capitalize;
        font-size: 1.5rem;
        text-align: center;
        margin: auto;
    }
`;


const TableViewer = (props) => {

    const { option, popupKey, close, data, getMongoDbTableData, mongoDbTableData } = props;

    const { table, db_info_id } = data;


    useEffect(() => {
        // if (!mongoDbTableData) {
        getMongoDbTableData(table, db_info_id);
        // }
    }, [table]);


    return (
        <MainWrapper>
            <div style={{
                position: "sticky",
                top: '-30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                backgroundColor: 'white',
                height: '50px',
                marginTop: '-30px',
                zIndex: 3
            }}>
                {table ? <h1>{table}</h1> : null}
                <div
                    title="close"
                    onClick={() => {
                        close(option, popupKey)
                    }}

                    style={{
                        cursor: 'pointer'
                    }}
                >
                    <CloseSvg size="1rem" height="1rem" />
                </div>
            </div>
            {
                mongoDbTableData && mongoDbTableData.results && mongoDbTableData.results.length > 0 ?
                    <ReactJson src={mongoDbTableData} />
                    :
                    undefined
            }
        </MainWrapper>
    )
}

const mapStateToProps = state => {

    const { mongoDbTableData } = state.helperReducer;

    return { mongoDbTableData };
};

export default connect(mapStateToProps, { getMongoDbTableData })(TableViewer);
