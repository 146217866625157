import React, { useState, useEffect, useRef } from 'react';
import { Button } from '../hoc/button';
import { Wrapper, } from './relationships.styled';
import { connect } from 'react-redux';
import { getTablesForHints, getAllRelationships, getColumnsByTableName, getSourceTableColumns, getTargetTableColumns, saveRelationshipDetails } from '../../actions/relationship.detail'
import Select from '../select';
import TableSkeleton from '../Skeleton/table-skeleton';



const Index = (props) => {

    const {

        getTablesForHints,
        tablesForRelationship,
        getSourceTableColumns,
        getTargetTableColumns,
        saveRelationshipDetails,
        getColumnsByTableName,
        columnsName,
        relationships

    } = props;

    const wrapperRef = useRef(null)

    const joinTypeJson = [
        {
            name: 'inner join'
        },
        {
            name: 'left'
        },
        {
            name: 'right '
        },
        {
            name: 'outer'
        },
    ]

    const [selectedTablesData, setSelectedTablesData] = useState(undefined);
    const [allTableName, setAllTableName] = useState(undefined);
    const [isLoding, setIsloding] = useState(true);

    useEffect(() => {
        props.getAllRelationships()
        getTablesForHints();
    }, [])


    useEffect(() => {
        if (relationships && relationships.length > 0) {
            const temp_relationships = relationships ? [...relationships] : [];
            temp_relationships.push({
                source_column: "",
                target_column: '',
                target_table: '',
                target_column: '',

            })
            setSelectedTablesData(temp_relationships)
            setIsloding(false)
        } else {
            setSelectedTablesData([
                {
                    source_column: "",
                    target_column: '',
                    target_table: '',
                    target_column: '',

                },
            ]);
        }
    }, [relationships])




    useEffect(() => {
        setAllTableName(tablesForRelationship)
    }, [tablesForRelationship])


    const onChangeTableRowColumn = (value, type, rowIndex) => {
        let tempTableData = selectedTablesData ? [...selectedTablesData] : []
        let obj = {
            target_table: '',
            source_table: '',
            source_column: '',
            target_column: '',
            joinType: 'inner join',
        }

        if (type === "source") {
            getSourceTableColumns(value);
        }

        if (type === "target") {
            getTargetTableColumns(value);
        }

        tempTableData && tempTableData.length > 0 && tempTableData.map((table, index) => {
            if (index === rowIndex) {
                table.modified = true
                if (type === 'source_column') {
                    table.source_column = value
                }
                if (type === 'target_column') {
                    table.target_column = value
                }
                if (type === "source") {
                    table.source_column = ''
                    table.source_table = value;
                    getColumnsByTableName(value)
                }

                if (type === "target") {
                    table.target_column = ''
                    table.target_table = value;
                    getColumnsByTableName(value)
                }
            }
        })

        let lastElement = tempTableData[tempTableData.length - 1];
        if (lastElement.source_table !== '' && lastElement.target_table !== '' && lastElement.source_column !== "" && lastElement.target_column !== "") {
            tempTableData.push(obj)
        }
        setSelectedTablesData(tempTableData)
    }



    const onSave = () => {
        const finalData = [];
        const tempSelectedData = selectedTablesData ? [...selectedTablesData] : selectedTablesData;

        tempSelectedData && tempSelectedData.length > 0 && tempSelectedData.map((row, index) => {
            if (row.source_table !== '' && row.target_table !== '' && row.source_column !== "" && row.target_column !== '') {
                finalData.push(row)
            }
        })
        saveRelationshipDetails(finalData)
    }

console.log('setIsloding', isLoding);
    return (
        <div style={{ marginTop: '1rem' }}>
           {!isLoding ?  <Wrapper>
                <div className="tableDiv" ref={wrapperRef}>
                    <table className="table">
                        <thead>
                            <tr>
                                <td>Source Table</td>
                                <td>Joining Column of Source Table</td>
                                <td>Target Table</td>
                                <td>Joining Column of Target Table</td>
                            </tr>

                        </thead>
                        <tbody>
                            {selectedTablesData && selectedTablesData.length > 0 && selectedTablesData.map((table, index) => {

                                const source_column_selection = columnsName && columnsName[table.source_table];
                                const target_column_selection = columnsName && columnsName[table.target_table];

                                return (
                                    <tr key={index}>
                                        <td>
                                            <div style={{ width: '100%' }}>
                                                <Select
                                                    // label='Source Table'
                                                    selections={allTableName}
                                                    onChangeFunc={(value) => {
                                                        onChangeTableRowColumn(value, 'source', index);
                                                    }}
                                                    activeValue={table && table.source_table}
                                                    type='--select--'
                                                    width="100%"
                                                    height="2.857rem"
                                                    lineHeight="2.857rem"
                                                    border='1px solid #bbd1d9'
                                                    color='#7c7c7c'
                                                    dropDownHeight={'150px'}
                                                    wrapperRef={wrapperRef}

                                                />
                                            </div>
                                        </td>

                                        <td>
                                            <Select
                                                selections={source_column_selection}
                                                displayKey='column_name'
                                                valueKey='column_name'
                                                onChangeFunc={onChangeTableRowColumn}
                                                activeValue={table.source_column}
                                                width="100%"
                                                height="2.857rem"
                                                lineHeight="2.857rem"
                                                border='1px solid #bbd1d9'
                                                color='#7c7c7c'
                                                dropDownHeight={'150px'}

                                                multiple={true}
                                                rowIndex={index}
                                                type="source_column"
                                                wrapperRef={wrapperRef}


                                            />
                                        </td>

                                        <td>
                                            <div style={{ width: '100%' }}>
                                                <Select
                                                    // label="Target Table"
                                                    selections={allTableName}
                                                    onChangeFunc={(value) => {
                                                        onChangeTableRowColumn(value, 'target', index)
                                                    }}
                                                    activeValue={table && table.target_table}
                                                    type='--select--'
                                                    width="100%"
                                                    height="2.857rem"
                                                    lineHeight="2.857rem"
                                                    border='1px solid #bbd1d9'
                                                    color='#7c7c7c'
                                                    dropDownHeight={'150px'}
                                                    wrapperRef={wrapperRef}

                                                />

                                            </div>
                                        </td>

                                        <td>
                                            <Select
                                                selections={target_column_selection}
                                                displayKey='column_name'
                                                valueKey='column_name'
                                                onChangeFunc={onChangeTableRowColumn}
                                                activeValue={table.target_column}
                                                width="100%"
                                                height="2.857rem"
                                                lineHeight="2.857rem"
                                                border='1px solid #bbd1d9'
                                                color='#7c7c7c'
                                                multiple={true}
                                                dropDownHeight={'150px'}

                                                rowIndex={index}
                                                type="target_column"
                                                wrapperRef={wrapperRef}


                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div
                    style={{
                        padding: '25px 0px',
                        borderTop: '1px solid #ccc',
                        marginTop: 'auto',
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                    <Button
                        width="6rem"
                        primary={true}
                        // mR="1.143rem"
                        onClick={onSave}
                    >Save</Button>
                    {/* <Button
                        width="6rem"
                        bgColor="#9caeb5"
                        // mR="1.143rem"
                        onClick={() => {
                        }}
                    >
                        Cancel
                    </Button> */}

                </div>
            </Wrapper>
        : <TableSkeleton />    
        }
        </div >
    )
}


const mapStateToProps = (state) => {
    const { tablesForRelationship, targetColumns, sourceColumns, columnsName, relationships } = state.relationshipDetail;

    return { tablesForRelationship, targetColumns, sourceColumns, columnsName, relationships }
}

export default connect(mapStateToProps, { getAllRelationships, getColumnsByTableName, getTablesForHints, getSourceTableColumns, getTargetTableColumns, saveRelationshipDetails })(Index)
