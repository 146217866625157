import React, { useState } from "react";
import styled from "styled-components";
import PolusLogoHeadLogo from "../../svg/icons-v2/polusLogoHead";
import SearchSvg from "../../svg/search";
import HistorySvg from "../../svg/recent";
import KnowSchemaSvg from "../../svg/schema";
import HideMenuIcon from "../../svg/icons-v2/hideMenuIcon";
import ShowMenuIcon from "../../svg/icons-v2/ShowMenuIcon";
import History from "./history";
import BackArrowIconIcon from "../../svg/icons-v2/backArrowIcon";
import { withRouter } from "react-router-dom";


const Wrapper = styled.div`

  width: ${(props) => (props.showLefter ? props.width || "270px" : "0px")};
  height: ${(props) => (props.showLefter ? props.height || "100vh" : "30px")};
  background-color: #fff;
  box-sizing: border-box;
  padding: ${props => !props.showLefter ? 0 : '1rem'};
  padding-top: ${props => !props.showLefter ? 0 : '2rem'};
  transition: width 0.2s;
  /* margin: ${(props) => (!props.showLefter && '0.5rem')}; */
  overflow: ${props => props.showLefter ? undefined : 'hidden'};
  opacity: ${props => props.showLefter ? 1 : 0};



  .logo {
    font-size: 1rem;
    position: relative;
    border-radius: 8px;
    padding: 0.3rem 1rem 0.3rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* box-shadow: 2px 1px 4px #0000003b; */
    /* background: linear-gradient(to right, #3e9eff, #737dff); */
    /* color: #fff; */
    font-weight: 700;
    font-size: 2rem;
    margin-left: 5px;
    cursor: pointer;
    &:hover{
      opacity: .8;
    }
  }
  .polus_icon_spacing{
    padding-top: 0.2rem;
  }
  .polus_icon_bg {
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }

  .hamberger {
    cursor: pointer;
    /* background: #e9e9e9; */
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 2rem; */
    padding: 0 0.4rem;

  }

  .header_options {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    .option_name {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin: 8px 0px;
      align-items: center;
      justify-content: flex-start;
      font-size: 0.9rem;
      padding: 5px 3px;
      border-radius: 3px;
      font-weight: 300;
      color: #222;
      cursor: pointer;
      &:hover {
        /* background-color: #f6f6f6; */
        background-color: #f0f2f8;
        svg {
          fill: #3e9eff;
        }
      }
      .__icon {
        width: 2rem;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

const LeftMenu = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #fff;
    border-radius: 6px;
    height: 2rem;
    width: 2rem;
    padding: 0.2rem;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
    box-shadow: 0px 3px 7px 2px #0000003d;

    &:hover{
      opacity: .8;
    }
 
`;

const LeftOptionDrawer = (props) => {

  const {
    optionOnClick = () => { },
    setShowLefter,
    showLefter,
    history

  } = props;


  const [showHistory, setShowHistory] = useState(undefined);


  return (
    <>

      {!showLefter && (
        <LeftMenu title="Open Menu" onClick={() => setShowLefter(true)}>
          <ShowMenuIcon height="1.2rem" size="1.2rem" />
        </LeftMenu>
      )
      }

      <Wrapper showLefter={showLefter}>
        <>
          <div
            style={{
              width: "100%",
              marginBottom: "2rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="logo" onClick={() => {
              if (history) {
                history.push('/');
              }
            }}>
              <div
              //  className="polus_icon_bg"
               className="polus_icon_spacing"
               >
                <PolusLogoHeadLogo height="2.2rem" size="2.2rem" />
              </div>
              &nbsp;&nbsp;VADY
            </div>
            <div
              title={showHistory ? "Back to Menu" : "Close Menu"}
              className="hamberger"
              onClick={() =>
                showHistory ? setShowHistory(false) : setShowLefter(false)
              }
            >
              {showHistory ? (
                <BackArrowIconIcon
                  height={"0.8rem"}
                  size={"0.8rem"}
                  color={"#000"}
                />
              ) : (
                <HideMenuIcon
                  height={"0.8rem"}
                  size={"0.8rem"}
                  color={"#000"}
                />
              )}
            </div>
          </div>

          {!showHistory && (
            <div className="header_options">
              <div
                onClick={() => {
                  optionOnClick("SEARCH_CATLOG");
                }}
                className="option_name"
              >
                <div className="__icon">
                  <SearchSvg size={"1.2rem"} width={"1.2rem"} />
                </div>
                <span>Search Catalog</span>
              </div>

              <div
                onClick={() => {
                  optionOnClick("KNOW_SCHEMA");
                }}
                className="option_name"
              >
                <div className="__icon">
                  <KnowSchemaSvg size={"1.2rem"} width={"1.2rem"} />
                </div>
                <span>Know Schemas</span>
              </div>

              <div
                className="option_name"
                onClick={() => {
                  optionOnClick("RECENT_HISTORY");
                  setShowHistory(true)
                }}
              >
                <div className="__icon">
                  <HistorySvg size={"1.2rem"} width={"1.2rem"} />
                </div>
                <span>Recent History</span>
              </div>
            </div>
          )}
          {showHistory && <History />}
        </>
      </Wrapper >
    </>
  );
};


export default withRouter(LeftOptionDrawer)