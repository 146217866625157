import styled from 'styled-components';


export const MainWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
`;

export const LeftTabWrapper = styled.div`
    width: 12rem;
    height: calc(100vh - 184px);
    max-height: calc(100vh - 184px);
    /* background: #eaeaea82; */
    border-right: 1px solid #ccc;
    margin-right: 1rem;
    padding-top: 1rem;
    overflow: auto;

    .title_show{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }

    .parent_node {
        width: 100%;
        padding-left: 0rem;
        box-sizing: border-box;
        height: auto;
        overflow: inherit;
    }


    .title_parent{
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        padding-left: 2px;
        font-size: 1rem;
        font-weight: 400;
        color: #333;
        .icon{
            margin-right: .5rem;
            svg{
                fill: #333333ba;
            }
        }
    }
    .parent_node ul{
        padding: 0px;
        position: relative;
        margin: 0px;
    }

    .overlay_line{
        width: 6px;
        height: 110%;
        position: absolute;
        left: 0rem;
        top: .9rem;
        background: #ffffff;
    }

    .parent_node ul::before {
        content: '';
        position: absolute;
        left: 3px;
        top: -.5rem;
        height: calc(100% - 1rem);
        border: 0.5px solid #989292;
    }

    .parent_node ul li::before {
        content: '';
        position: absolute;
        left: 3px;
        top: 0.7rem;
        border: 0.5px solid #989292;
        width: 0.714rem;
    }

.parent_node ul li {
    padding: .3rem 0px;
    line-height: 1.5rem;
    padding-left: 1.071rem;
    position: relative;
    text-overflow: ellipsis;
    /* overflow: hidden; */
    white-space: nowrap;
    cursor: pointer;
    list-style: none;
    transition: all 0.2s;
    position: relative;

    .parent{
        &::before{
            position:absolute;
            content: '';
            height: 100%;
            border: 1px solid #00000054;
            left: 0px;
            top: 0px;
        }
     }
    }
`;


export const Tab = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-left: .5rem;
    cursor: pointer;
    font-size: .9rem;
    text-overflow: ellipsis;
    text-transform: capitalize;
    /* background-color: ${props => props.active ? "#1976d2d1" : "#eaeaea"}; */
    color: ${props => props.active ? "#fff" : '#222'};
    position: relative;
    padding-right: 0px;;

    
 


    /* .second_parent{
        position: relative;
        &::before{
            position: absolute;
            content: '';
            border: 1px solid #00000054;
            left: -13%;
            top: 16px;
            width: 22%;
            height: 0px;
            z-index: 1;
    } } */

`;



export const Title = styled.div`
    padding: .5rem .4rem;
    width: 100%;
    font-size: .9rem;
    text-transform: uppercase;
    box-sizing: border-box;
    font-weight: 500;
    color: #444;
`;

export const RightSection = styled.div`
    width: 100%;
    height: calc(100vh - 172px);
    border-right: 1px solid #ccc;
    overflow: hidden;
    h1{

    }
`;