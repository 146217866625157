import React, { useState } from 'react'
import styled from 'styled-components';
import CrossSvg from '../svg/close';
import ExclamationSvg from '../svg/exclamation';


const ColumnDroppedBox = styled.div`
    width: 100%;
    border: 2px solid #ccc;
    border-style: dashed;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 0px .1rem;
    min-height: ${props => (props?.maxHeight + "px") || '200px'};
    /* max-height: ${props => (props?.maxHeight + "px") || '200px'}; */


    .remove{
        display: none;
        cursor: pointer;
        svg{
            fill: red;
        };
        margin-left: 3px;
    }

    .setting{
        cursor: pointer;
        width: 1rem;
        margin-left: 3px;
        display: none;

        &:hover{
            .setting_hover {
                display: block;
            }
        }

    }

    h2{
        margin: 4px 3px;
        font-size: 1rem;
        font-weight: 600;
        text-transform: capitalize;
        color: rgba(34, 34, 34, 0.66);
    }
    ul {
        margin: 0px;
        padding-left: 0;
        /* max-height: ${props => (props?.maxHeight - 60 + "px") || '200px'}; */
        min-height: ${props => (props?.maxHeight - 60 + "px") || '200px'};
        /* overflow: auto; */
        /* padding-bottom: 1rem; */
        list-style-type: none;
        padding-bottom: 50px;
        box-sizing: border-box;
        &::-webkit-scrollbar {
            background-color:red;
            width:2px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 2px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 2px;
        }
        li{
            font-size: .9rem;
            font-weight: 400;
            text-transform: capitalize;
            line-height: 1.4rem;
            cursor: pointer;
            padding: 3px;
            border-radius: 12px;
            position: relative;
            padding-left: 5px;;

            .word{
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                display: inline-block;
                font-size: .9rem;
            }

            .setting_hover {
                
                position: absolute;
                background-color: #fff;
                right: 10px;
                top: 100%;
                font-size: .6rem;
                line-height: 17px;
                padding: 3px 10px;
                box-shadow: 0px -1px 7px #ccc;
                z-index: 2;
                text-transform: capitalize;
                display: none;

                &::before{
                    content: '';
                    position: absolute;
                    top: -5px;
                    left: 0;
                    border-bottom: 5px solid #ccc;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                }
            }
            &:hover{
                background-color: #add7eb7a;
                .remove{
                    display: inline-block;
                }
                .setting {
                    display: inline-block;
                }
            }
        }
    }

`;






const ColumnDropperArea = ({

    column_drop_v1,
    height,
    key_for_use,
    title,
    options_for_render,
    change_column_order_v1,
    set_active_row,
    open_value_property_popup,
    delete_column_v1,
    save_properties,
    change_column_value

}) => {

    const [show_input, set_show_input] = useState(undefined);
    const [input_values, set_input_values] = useState({});
    const [active_column, set_active_column] = useState(undefined);


    const remove_nfx = (c) => {
        const pre_str = ((c.pivot_field_alias || c.pivot_field_column_name || c))
        const str = (pre_str?.length > 0 && pre_str?.split('_nfrptr_').join(' '));

        return str && str.replace("_nfx_", " ")
    };

    const input_on_change = (key, value) => {
        const clone_input_values = input_values ? Object.assign({}, input_values) : {};
        clone_input_values[key] = value;
        set_input_values(clone_input_values)
    }

    const apply_local_change = (c_name, index) => {

        const clone_active_column = active_column ? Object.assign({}, active_column) : {};
        clone_active_column['pivot_field_alias'] = input_values?.[c_name];
        // console.log("clone_active_column", clone_active_column)
        save_properties(clone_active_column, index);

        // let's reset all values
        setTimeout(() => {
            set_active_column(undefined)
            set_input_values({})
            set_show_input(undefined)
        }, 100);


    }


    return (
        <ColumnDroppedBox
            maxHeight={height}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
                e.stopPropagation();
                e.preventDefault();
                column_drop_v1(key_for_use, e)
            }}
        >
            <h2>{title}</h2>
            {options_for_render && options_for_render.length > 0 && (
                <ul>
                    {options_for_render.map((c, index) => {
                        return (
                            <li
                                draggable={true}
                                onDragStart={(event) => {
                                    event.dataTransfer.setData("column_info_id", c.id)
                                    event.dataTransfer.setData("column_info_index", c.index)
                                    event.dataTransfer.setData("section_name", key_for_use)
                                }}
                                onDrop={(event) => {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    change_column_order_v1(event)
                                }}
                                column_index={c.index}
                                key_for_use={"c" + index}>

                                <div style={{ display: "flex" }}
                                    onMouseOver={() => set_active_row(index)}
                                    onMouseOut={() => set_active_row(undefined)}>
                                    <div
                                        onClick={(e) => {
                                            if (key_for_use === "pivot_y") open_value_property_popup(e, c, c.index)
                                            if (key_for_use !== "pivot_y") {
                                                // set_show_input(c.pivot_field_column_name)
                                                // set_active_column(c)
                                            }
                                        }}
                                        className="word" style={{ width: '130px', maxWidth: '130px' }}>

                                        {(show_input && show_input === c.pivot_field_column_name) ? (
                                            <input
                                                type="text"
                                                defaultValue={c.pivot_field_alias}
                                                value={input_values?.[c.pivot_field_column_name]}
                                            // onChange={(e) => {
                                            //     input_on_change(c.pivot_field_column_name, e.target.value)
                                            // }}
                                            // onBlur={() => {
                                            //     apply_local_change(c.pivot_field_column_name, index)
                                            // }}
                                            />
                                        ) : remove_nfx(c)}
                                    </div>
                                    {/* {key_for_use === "pivot_x" && (
                                        <>
                                            <input 
                                                checked={c?.is_hide_from_scatter}
                                                title="hide from scatter" 
                                                type="checkbox" onChange={() => {
                                                    const __value = c?.is_hide_from_scatter ? false : true;
                                                   change_column_value('is_hide_from_scatter', __value, c.index)
                                            }}/>
                                        </>
                                    )} */}
                                    {key_for_use === "pivot_y" && (
                                        <div onClick={(e) => {
                                            if (key_for_use === "pivot_y") open_value_property_popup(e, c, c.index)
                                        }} className="setting"  >
                                            <ExclamationSvg size=".8rem" height=".8rem" color="#1171a1" />
                                            <div className="setting_hover">
                                                aggregate : <strong>{c.pivot_aggregation}</strong><br></br>
                                                chart Type : <strong>{c.chart_type && c.chart_type.split("_").join(' ')}</strong><br></br>
                                                axis : <strong>{c.axis}</strong><br></br>
                                            </div>

                                        </div>
                                    )}
                                    <div onClick={() => delete_column_v1(c.index)} title="Remove" className="remove"  >
                                        <CrossSvg size=".6rem" height=".6rem" />
                                    </div>

                                </div>
                            </li>
                        )
                    })}
                </ul>
            )}
        </ColumnDroppedBox>
    )
}

export default ColumnDropperArea
