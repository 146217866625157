import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ArrowIcon from '../../svg/menu/arrowIcon';

const Container = styled.div`
    width: ${props => props.width || 'initial'};
    padding: 0.2rem 0.4rem;
    min-height: ${props => props.minHeight ? props.minHeight : '14rem'};
    max-height: ${props => props.maxHeight ? props.maxHeight : '20rem'};
    background: #ffffff;
    overflow: auto;
    .arrow_{
        position: absolute;
        right: 8px;
        /* background: #fff; */
    }
    &::-webkit-scrollbar {
        background-color: #3F3F3F;
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background-color: #F3F3F3;
        border: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dbdbdb;
        border-radius: 10px;
        height: 4px;
    }
    &::-webkit-scrollbar:horizontal {
        background-color: #3F3F3F;
        height: 4px;
    }
`;

const MainRowWrapper = styled.div`
    /* margin-bottom: 10px; */
    hr {
        background-color: #d9d9d9;
        border: 0;
        height: 1px;
        /* margin: 10px 10px 0; */
    }
`;

const MainItemHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 3px;
    border-radius: 6px;
    cursor: pointer;
    font-size: .9rem;
    position: relative;
    svg {
        text-align: right;
        transform: ${(props) => (!props.isExpand ? 'rotate(270deg)' : 'rotate(90deg)')};
    }
    &:hover {
        background-color: ${props => props.hoverColor || '#dee6ff'};
    }
`;

const InnerItemWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding-left: 15px;
    .sub_item {
        font-size: 0.9rem;
        width: 100%;
        padding: 10px;
        border-radius: 3px;
        box-sizing: border-box;

        &:hover {
            background-color: ${props => props.hoverColor || '#dee6ff'};
        }
    }
`;

const Checkbox = styled.input`
    margin-right: 10px;
    height: 100%;
`

const NestedSelectOption = ({ maxHeight, minHeight, width, selection, display_key, value_key, selected_values, onChange, hoverColor, padding }) => {

    const [localSelectedValues, setLocalSelectedValues] = useState(selected_values || {});
    const [collapsedDepartments, setCollapsedDepartments] = useState({});

    useEffect(() => {
        setLocalSelectedValues(selected_values);
    }, [selected_values]);

    const handleParentCheckboxChange = (departmentId) => {
        setLocalSelectedValues((prevState) => {
            const isSelected = !(prevState[departmentId]?.length === selection.find(dept => dept[value_key] === departmentId).users.length);
            const has_user = selection.find(dept => dept[value_key] === departmentId).users;
            const updatedUsers = isSelected
                ? selection.find(dept => dept[value_key] === departmentId).users.map(user => user[value_key])
                : [];

            if (has_user?.length > 0) {
                const newValues = {
                    ...prevState,
                    [departmentId]: updatedUsers
                };
                if (onChange) {
                    onChange(newValues);
                }
                return newValues;
            }
            else {
                const newValues = {
                    ...prevState,
                };

                return newValues;
            }


        });
    };

    const handleChildCheckboxChange = (departmentId, userId) => {
        setLocalSelectedValues((prevState) => {
            const currentUsers = prevState[departmentId] || [];
            const updatedUsers = currentUsers.includes(userId)
                ? currentUsers.filter(id => id !== userId)
                : [...currentUsers, userId];

            const newValues = {
                ...prevState,
                [departmentId]: updatedUsers
            };

            if (onChange) {
                onChange(newValues);
            }

            return newValues;
        });
    };

    const isParentChecked = (departmentId) => {
        const department = selection.find(dept => dept[value_key] === departmentId);
        return localSelectedValues[departmentId]?.length === department?.users?.length;
    };

    const isChildChecked = (departmentId, userId) => {
        return localSelectedValues[departmentId]?.includes(userId);
    };

    const toggleCollapse = (departmentId) => {
        setCollapsedDepartments((prevState) => ({
            ...prevState,
            [departmentId]: !prevState[departmentId]
        }));
    };

    return (
        <Container maxHeight={maxHeight} minHeight={minHeight} width={width}>

            {selection?.map((department, index) => (

                <MainRowWrapper key={department?.[value_key]}>
                    <MainItemHeader
                        isExpand={collapsedDepartments[department?.[value_key]]}
                        hoverColor={hoverColor}
                        // padding={padding}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            toggleCollapse(department?.[value_key]);
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'start', width: '100%' }}>
                            <div style={{ width: '15%' }}>
                                <Checkbox
                                    type="checkbox"
                                    checked={isParentChecked(department[value_key])}
                                    onChange={() => handleParentCheckboxChange(department[value_key])}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </div>
                            <div style={{ width: '80%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                {department?.[display_key]}
                            </div>
                        </div>

                        {department?.users?.length > 0 && <div className='arrow_'><ArrowIcon size="0.6rem" height="0.6rem" color="#4e4646" /></div>}

                    </MainItemHeader>

                    {!collapsedDepartments[department.id] && (

                        <InnerItemWrapper hoverColor={hoverColor} padding={padding}>
                            {department.users.map(user => (
                                <div
                                    className="sub_item"
                                    key={user.id}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'start', width: '100%' }}>
                                        <div style={{ width: '15%' }}>
                                            <Checkbox
                                                type="checkbox"
                                                checked={isChildChecked(department?.[value_key], user?.[value_key])}
                                                onChange={() => handleChildCheckboxChange(department?.[value_key], user?.[value_key])}
                                            />
                                        </div>
                                        <div style={{ width: '85%' }}>
                                            {user?.[display_key]}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </InnerItemWrapper>
                    )}

                    {index !== (selection?.length - 1) && <hr />}

                </MainRowWrapper>
            ))}
        </Container>
    );
};


export default NestedSelectOption;
