import { simpleDispatch_post, dispatchAction, simpleDispatch_put, simpleDispatch_get, showError } from './helper';
import * as actionTypes from '../action-types/insight';
import { constants } from '../utils/constants';
import { get_client_info } from '../client_info';
import { post, put } from '../data.service';
import * as loaderTypes from '../action-types/loader';
import { reset_data_from_server } from './report'
import { getDefaultInsightForDashboard, get_all_reports } from './report.helper';
import { get_report_menus } from './menu.group';
import { GET_ALL_REPORT_SUCCESS_V1, CLEAR_ALL_REPORTS } from '../action-types/report.helper'



// export const getInsightsData = (id, reportId) => async dispatch => {
//     const url = constants.END_POINTS.API + constants.END_POINTS.INSIGHTS.POINT + constants.END_POINTS.INSIGHTS.GET_INSIGHTS_DATA;
//     const client_info = await get_client_info();

//     const post = {
//         user_id: id,
//         client_info,
//         report_id: reportId
//     };


//     if (process.env.REACT_APP_DEV_LOGIN_KEY) {
//         delete post['client_info'];
//     }

//     await simpleDispatch_post(dispatch, url, post, actionTypes.INSIGHTS_DATA_SUCCESS, undefined);
// };


export const getInsightsListByUser = (userId, showLoader) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.INSIGHTS.POINT + constants.END_POINTS.INSIGHTS.GET_INSIGHTS_LIST_BY_USER;

    const post = {
        user_id: userId
    }

    await simpleDispatch_post(dispatch, url, post, actionTypes.INSIGHTS_LIST_BY_USER_SUCCESS, showLoader ? false : true);
};


export const getInsightsByInsightIdForOpenRoute = (client_id, report_id, token) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.INSIGHTS.POINT + constants.END_POINTS.INSIGHTS.GET_INSIGHTS_LIST_BY_INSIGHT_ID;

    /**
     * no need for post object as we have already dispatched the opr data to session which will be binding automatically
     * with all request anyway
     */
    const post = {
        client_id, report_id, token
    };

    await simpleDispatch_post(dispatch, url, post, GET_ALL_REPORT_SUCCESS_V1, true);


};

export const getInsightsMenu = userId => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.INSIGHTS.POINT + constants.END_POINTS.INSIGHTS.GET_INSIGHTS_LIST_BY_USER;

    const post = {
        user_id: userId,
        report_menu: true
    }


    await simpleDispatch_post(dispatch, url, post, actionTypes.INSIGHTS_MENU_SUCCESS, true);
};


export const saveInsight = (report, refresh, userList, userId, close, option, key, showLoader, history, path, is_run_report) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.SAVE_REPORT;

    // await simpleDispatch_put(dispatch, url, report, actionTypes.SAVE_INSIGHTS_DATA_SUCCESS, showLoader ? false : true);

    // const tempReportItems = report[0].report_items;
    // const tempReportItem = tempReportItems[0];

    // if(!showLoader) {

    // console.log("i am here dude");

    dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);

    try {

        const data = await put(url, report);

        if (data && data.code === 200) {

            dispatchAction(dispatch, actionTypes.SAVE_INSIGHTS_DATA_SUCCESS)

            const actualData = data.data;
            const report_id = actualData.mainReportId;

            // report_id
            if (refresh) {

                // await refreshInsight(tempReportItem)(dispatch);
            }

            getDefaultInsightForDashboard(report_id)(dispatch); // this we get the edited dashboard by id;

            dispatchAction(dispatch, CLEAR_ALL_REPORTS)

            setTimeout(async () => {
                await get_all_reports(undefined, true)(dispatch)
                await get_report_menus()(dispatch)

            }, 1000);

            if (is_run_report && history) {

                reset_data_from_server()(dispatch)

                setTimeout(() => {
                    history.push(`/?insightId=${report_id}`)
                }, 500)
            }

            if (history && !is_run_report) {

                reset_data_from_server()(dispatch)

                setTimeout(() => {
                    history.push(path)
                }, 500)

            }
            dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);

        }
    } catch (error) {
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        showError(dispatch, error);
    }
};


export const createForm = (tblName, tags) => async dispatch => {

    let url = constants.END_POINTS.API + constants.END_POINTS.CREATE_FORM_UI.POINT + constants.END_POINTS.CREATE_FORM_UI.CREATE_TABLE;

    let _data = {
        "tblName": tblName,
        "tags": tags
    }
    await simpleDispatch_post(dispatch, url, _data, actionTypes.FORM_TABLE_CREATE_SUCCESS, true);

}


export const getAnswerListByTableName = (tblName) => async dispatch => {
    let url = constants.END_POINTS.API + constants.END_POINTS.CREATE_FORM_UI.POINT + constants.END_POINTS.CREATE_FORM_UI.GET_ANSWERS_BY_TABLE_NAME;
    let _data = {
        "tblName": tblName,
    }
    await simpleDispatch_post(dispatch, url, _data, actionTypes.GET_ANSWERS_LIST_BY_TABLE_NAME, true);
}


export const edit_table_record = (record) => async dispatch => {
    await dispatchAction(dispatch, actionTypes.EDIT_TABLE_RECORD, record)
}


export const deleteAnswer = (tblName, id) => async dispatch => {
    let url = constants.END_POINTS.API + constants.END_POINTS.CREATE_FORM_UI.POINT + constants.END_POINTS.CREATE_FORM_UI.DELETE_ANSWER_BY_ID;
    let _data = {
        "tblName": tblName,
        "id": id
    }
    await simpleDispatch_post(dispatch, url, _data, actionTypes.DELETE_ANSWER_SUCCESS, true);
}





export const save_form_data = (data) => async dispatch => {
    let url = constants.END_POINTS.API + constants.END_POINTS.CREATE_FORM_UI.POINT + constants.END_POINTS.CREATE_FORM_UI.SAVE_ANSWERS;
    await simpleDispatch_post(dispatch, url, data, actionTypes.SAVE_ANSWER_FORM_UI, true);

    // dispatchAction(dispatch, "SAVE_FORM_SUCCESSFULLY", undefined, undefined, undefined);
}


export const clearInsights = () => async dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_INSIGHTS_DATA_SUCCESS, undefined, undefined, undefined);
};

export const setActiveInsight = data => async dispatch => {
    dispatchAction(dispatch, actionTypes.SET_ACTIVE_INSIGHTS_SUCCESS, data, undefined, undefined);
};

export const setChangeInsightData = data => async dispatch => {
    dispatchAction(dispatch, actionTypes.SET_CHANGE_INSIGHTS_DATA_SUCCESS, data, undefined, undefined);
};

export const refreshInsight = reportItem => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.INSIGHTS.POINT + constants.END_POINTS.INSIGHTS.GET_INSIGHTS_DATA;
    const client_info = await get_client_info();

    const temp = Object.assign({}, reportItem);

    delete temp.modified;

    const post = {
        client_info,
        report_item: temp
    }


    if (process.env.REACT_APP_DEV_LOGIN_KEY) {
        delete post['client_info'];
    }

    await simpleDispatch_post(dispatch, url, post, actionTypes.REFRESH_INSIGHTS_DATA_SUCCESS, true);
};





export const getHintsByClientId = () => async dispatch => {
    let url = constants.END_POINTS.API + constants.END_POINTS.HINTS.POINT + constants.END_POINTS.HINTS.GET_HINTS_BY_CLIENT_ID;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_HINTS_BT_CLIENT_ID_SUCCESS, true);
}


export const getAllInfoOfQuestion = (question, db_info_id, id) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.INSIGHTS.POINT + constants.END_POINTS.INSIGHTS.GET_COLUMN_INFO_BY_QUESTION

    const _body = {
        question,
        db_info_id,
        id: id
    }

    dispatchAction(dispatch, actionTypes.CLEAR_COLUMN_OF_QUESTION, { id: id })

    await simpleDispatch_post(dispatch, url, _body, actionTypes.GET_COLUMN_INFO_BY_QUESTION, true);
}



export const get_question_info_for_dashboard_v2 = (questions, db_info_id='demo_med_db_info_id__nf__db__cached__`', id) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.INSIGHTS.POINT + constants.END_POINTS.INSIGHTS.GET_COLUMN_INFO_BY_QUESTION

    const _body = {
        questions,
        db_info_id,
    }

    await simpleDispatch_post(dispatch, url, _body, actionTypes.GET_QUESTION_COLUMN_FOR_DASHBOARD_V2, true);
}

