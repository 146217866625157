import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { getPredectiveData, getPredectiveDataByMonth, getPredectiveDataByYear } from '../../actions/predictive_analytics';
import ReportWrapper from '../report-wrapper';
import { data_set_processing, getParameterByName } from '../../utils';
import TitleHeader from '../title-header';
import { dispatchHeaderInformation } from '../../actions/common'
import { get_final_process_data, get_pivot_data } from '../../utils/v1.1/index'


const Index = (props) => {

    const { predictiveDataByYear } = props;

    const [wrapperHeight, setWrapperHeight] = useState(undefined);
    const [wrapperWidth, setWrapperWidth] = useState(undefined);
    const [childWrapperHeight, setChildWrapperHeight] = useState(undefined);
    const [childWrapperWidth, setChildWrapperWidth] = useState(undefined);
    const [roomRent, setRoomRent] = useState({});
    const [opd, setOPD] = useState([]);
    const [diagnostic, setDiagnostic] = useState([])
    const [surgery, setSurgery] = useState([])
    const [mainData, setMainData] = useState({});
    const search = props && props.history && props.history.location && props.history.location.search;
    const wrapperRef = useRef(null);
    const childWrapper = useRef(null);

    let submit = '';
    let spec_name = '';

    if (search && search.indexOf('=') > -1) {
        submit = getParameterByName('spec');
        spec_name = getParameterByName('spec_name');
    }

    useEffect(() => {
        props.dispatchHeaderInformation(undefined)
        if (wrapperRef.current) {
            let _height = wrapperRef.current.offsetHeight;
            let _width = wrapperRef.current.offsetWidth;
            setWrapperHeight(_height);
            setWrapperWidth(_width);
        }
        props.getPredectiveDataByYear(submit)
    }, []);

    useEffect(() => {
        if (childWrapper.current) {
            let _height = childWrapper.current.offsetHeight;
            let _width = childWrapper.current.offsetWidth;
            setChildWrapperWidth(_height);
            setChildWrapperHeight(_width);
        }
    }, []);


    const get_local_pivot_data = (input_data, key) => {

        var data = input_data ? JSON.parse(JSON.stringify(input_data)) : undefined;
        const proccess_data = data?.['process_data'] || []


        if (typeof data !== 'undefined') {

            if (key && proccess_data) {
                // then need to filter the data
                const filterd_proccess_data = proccess_data.filter((f) => f["service_id"] === key)
                data["process_data"] = filterd_proccess_data
            }

            if (data && data.config_meta?.xac) {
                // let change the xac
                data.config_meta.xac = ['Month']
            }

            if (!data.config_meta) data.config_meta = {};

            data.config_meta["chart_type"] = "predictive_chart"

            const processed_data = (data && data.process_data) || [];
            const xac = (data && data.config_meta && data.config_meta.xac) || [];
            const xac_new = (data && data.config_meta && data.config_meta.xac?.lenght > 0 && data.config_meta.xac[0]) || [];
            const final_xac_value = [];
            final_xac_value.push(xac_new);

            const yac = (data && data.config_meta && data.config_meta.yac) || [];
            const meta = (data && data.column_meta_data) || [];
            const pre_processing = data_set_processing(processed_data, xac, yac, meta);


            const final_data = get_final_process_data(data);

            if (data) {
                data.process_data = final_data.sorted_data;
                if (data.config_meta) {
                    data.config_meta.xac = pre_processing.xac;
                    data.config_meta.xac = final_data.xac;
                    data.config_meta.yac = final_data.yac;
                }

                if (final_data.pivot_data) {
                    data = { ...data, ...final_data }
                    // data.pivot_data.data = final_data.pivot_data.data;
                    // data.pivot_data.pivot_data_axis = final_data.pivot_data.pivot_data_axis;
                    // data.pivot_data.pivot_data_ultimate_yacs = final_data.pivot_data.pivot_data_ultimate_yacs;
                    // data.pivot_data.pivot_columns_state = final_data.pivot_data.pivot_columns_state
                }

                if (data.column_meta_data) {
                    data.column_meta_data = final_data.column_meta_data;
                }
                data.ultimate_yacs = final_data.ultimate_yacs;
            }

            data.pivot_data.data && Object.values(data.pivot_data.data).forEach((item) => {
                if (item.Projection === 0) {
                    item.Projection = undefined;
                }
                if (item["2020"] === 0) {
                    item["2020"] = undefined;
                }
            })

            const pivot_data = []
            const grand = {};
            data.pivot_data.data.forEach((item) => {
                if (!grand[item.Month]) grand[item.Month] = { 2018: 0, 2019: 0, 2020: 0, Projection: 0 };
                grand[item.Month]["2018"] += item["2018"]
                grand[item.Month]["2019"] += item["2019"]
                grand[item.Month]["2020"] += item["2020"]
                grand[item.Month].Projection = item.Projection
            })
            grand && Object.keys(grand).map((item) => {
                pivot_data.push({
                    Month: item,
                    ...grand[item]
                })
            })
            data["pivot_data"]['data'] = pivot_data
        }
        // console.log("data1.1.1", data)
        return data;
    }



    useEffect(() => {
        const reducer_data = predictiveDataByYear ? JSON.parse(JSON.stringify(predictiveDataByYear)) : undefined;

        if (reducer_data && Object.keys(reducer_data).length > 5) {

            setMainData(get_local_pivot_data(reducer_data))
            setRoomRent(get_local_pivot_data(reducer_data, "Room Rent"))
            setOPD(get_local_pivot_data(reducer_data, "OPD"))
            setDiagnostic(get_local_pivot_data(reducer_data, "Diagnostic"))
            setSurgery(get_local_pivot_data(reducer_data, "Surgery"))
        }

    }, [predictiveDataByYear])



    const serviceList = [
        { title: "Room Rent", service: roomRent },
        { title: "OPD", service: opd },
        { title: "Diagnostic", service: diagnostic },
        { title: "Surgery", service: surgery },
    ]


    console.log("serviceList", serviceList)

    return (<>
        <div
            style={{ width: '100%', height: '190vh', display: "flex", flexDirection: "column" }}
        >
            <div
                style={{ width: '100%' }}
                ref={wrapperRef}
            >
                <div
                    style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', flexDirection: 'row', marginTop: '3rem' }}
                >
                    <div
                        style={{
                            boxShadow: '0px 2px 6px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                            marginBottom: '3%',
                            boxSizing: 'border-box',
                            background: '#fff',
                            width: '100%',
                        }}
                    >
                        <TitleHeader title={spec_name ? spec_name + " Predictions" : "Predictive Analytics"} style={{ paddingLeft: '1rem' }} titleBg="#fff" />
                        <ReportWrapper
                            isDiscovery={true}
                            id={'predictive_analityic01'}
                            width={1 * wrapperWidth}
                            height={1.2 * wrapperHeight}
                            view_mode={"collapsed"}
                            {...props}
                            isPredictive={true}
                            report_type="dashboard"
                            reportWrapperRender={false}
                            dataFromServer={mainData}
                            sort_data={false}
                            hideChartOption={true}
                            staticYAxisLabel={"Revenue"}
                        />
                    </div>
                </div>
            </div>
            <div
                style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', flexDirection: 'row' }}
            >
                {serviceList && serviceList.map((service) => {
                    return (
                        <div
                            style={{
                                boxShadow: '0px 2px 6px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                                marginBottom: '3%',
                                boxSizing: 'border-box',
                                background: '#fff',
                            }}
                        >
                            <TitleHeader center={true} title={service.title} style={{ paddingLeft: '1rem' }} titleBg="#fff" />
                            
                            <ReportWrapper
                                isDiscovery={true}
                                id={'predictive_analityic' + service}
                                width={.48 * wrapperWidth}
                                height={1.50 * wrapperHeight}
                                view_mode={"collapsed"}
                                {...props}
                                isPredictive={true}
                                report_type="dashboard"
                                reportWrapperRender={false}
                                dataFromServer={service.service}
                                sort_data={false}
                                hideChartOption={true}
                                staticYAxisLabel={"Revenue"}
                                restricted_view={false}
                            />

                        </div>
                    )
                })}
            </div>
        </div>
    </>)
}

const mapStateToProps = (state) => ({

    predictiveData: state.predictiveAnalyticsReducer.predictiveData,
    predictiveDataByMonth: state.predictiveAnalyticsReducer.predictiveDataByMonth,
    predictiveDataByYear: state.predictiveAnalyticsReducer.predictiveDataByYear,
})

export default withRouter(connect(mapStateToProps, { dispatchHeaderInformation, getPredectiveData, getPredectiveDataByMonth, getPredectiveDataByYear })(Index))

