import { simpleDispatch_get, simpleDispatch_post, dispatchAction, raiseSuccess } from './helper';
import * as actionTypes from '../action-types/tbl_errors';
import { constants } from '../utils/constants';
import { post } from '../data.service';
import * as loaderTypes from '../action-types/loader';


export const getTblErrors = (job_id) => async dispatch => {

    const _post_data = {
        job_id
    }
    const url = constants.END_POINTS.API + constants.END_POINTS.TBL_ERRORS.POINT + constants.END_POINTS.TBL_ERRORS.GET_TBL_ERRORS;

    await simpleDispatch_post(dispatch, url, _post_data, actionTypes.GET_TBL_ERRORS);
};


export const insertErrorQueries = (jobs, job_id, message) => async dispatch => {

    const _post_data = {
        jobs
    }
    const url = constants.END_POINTS.API + constants.END_POINTS.TBL_ERRORS.POINT + constants.END_POINTS.TBL_ERRORS.INSERT_ERROR_QUERIES;

    try {
        dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);

        const response = await post(url, _post_data);

        if (response && response.code === 200) {

            dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
            
            await dispatchAction(dispatch, actionTypes.INSERT_ERROR_QUERIES)

            await raiseSuccess(dispatch, {
                type: 'success',
                message: message ? message : "Processed Successfully"
            })

            await getTblErrors(job_id)(dispatch)
        }

    } catch (error) {

        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);

        const _message = typeof error === 'object' ? (error.custom_message ? error.custom_message : '') : error;

        raiseSuccess(dispatch, {
            type: 'error',
            title: 'ERROR',
            message: _message,
        })
    }
};



export const pushRowDataToStore = (data) => async (dispatch) => {
    await dispatchAction(dispatch, actionTypes.PUSH_TBL_ERROR_EDITED_DATA_TO_REDUCER, data)
}


export const removeRowDataFromStore = (data) => async (dispatch) => {
    await dispatchAction(dispatch, actionTypes.REMOVE_TBL_ERROR_EDITED_DATA_FRPM_REDUCER, data)

}


export const clearRowDataEditedStore = () => async (dispatch) => {
    await dispatchAction(dispatch, actionTypes.CLEAR_ROW_DATA_EDITED_FROM_STORE,)

}
