import { transform } from 'ol/proj';


export const get_geo_json_form_coordinates = (type, coordinates_array) => {
    const result_to_return = {};
    // const extra_layer_of_array = [];

    // extra_layer_of_array.push(coordinates_array);

    result_to_return["location"] = {};
    result_to_return["location"]["coordinates"] = coordinates_array;
    result_to_return["location"]["type"] = type;

    return result_to_return;
};


export const get_cluster_format = markers => {
    const result = [];

    markers.forEach(data => {
        let temp = {
           ...data,
            lat: data.coordinates.lat,
            lng: data.coordinates.lng
        };

        result.push(temp);
    });

    return result;
};

export const get_lat_lng_from_coordinates = (markers, google) => {
    const result = [];


    markers.forEach(m => {
        let temp = {...m};
        const { coordinates } = temp['location'];

        const lat = coordinates[1];
        const lng = coordinates[0];

        const latLng = new google.maps.LatLng(
            lat,
            lng
        );

        temp = {
            ...temp,
            lat,
            lng
        }

        result.push(latLng);
    });

    return result;

};

export const convertToLonLat = coordinates => {
    const temp = [];

    coordinates.forEach(coordinate => {
        temp.push(transform(coordinate, 'EPSG:3857', 'EPSG:4326'))
    });

    return temp;
};

export const stringToArrayCoor = coor => {
    let pointer = 0

    const result = [];

    let temp = [];

    coor.forEach(_c => {
        if (pointer < 2) {
            temp.push(_c)
            pointer += 1;
        }

        if (pointer === 2) {
            result.push(temp);
            temp = []
            pointer = 0;
        }
    })

    return result;
};