import React from 'react';
import enums from '../../utils/enums';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { popupClicked } from '../../actions/popup';
import { withRouter } from 'react-router-dom';
import { constants } from '../../utils/constants';
import CloseSvg from '../svg/close';
import SweetAlert from '../shared.alert/sweet.alert';
import Loading from '../loading';

const Overlay = styled.div`
    width: 100vw;
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
    /* background: rgba(127, 122, 122, 0.87); */
    background-color: ${props => props.backgroundColor ? props.backgroundColor : ' rgba(0, 0, 0, 0.4)'};
    /*
        if we send top/left, then we will not put the window at the center
    */

    position: fixed;
    display: ${props => props.top ? undefined : 'flex'};
    justify-content:  ${props => props.top ? undefined : 'center'};
    align-items:  ${props => props.top ? undefined : 'center'};
    top: 0px;
    display: flex;
    z-index: ${props => props.totalZIndexForPopUp ? props.totalZIndexForPopUp : 2000};
`;



const PopupBox = styled.div`
    z-index: ${props => props.totalZIndexForPopUp ? props.totalZIndexForPopUp : 2001};
    position: ${props => props.left ? "fixed" : 'relative'};
    top: ${props => {
        return props.top ? props.top : 0
    }};
    left: ${props => {
        return props.left ? props.left : 0
    }};

`;


const NotificationBoxWrapper = styled.div`
    width: 600px;
    height: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #fff;
    position: relative;
    border-radius: 4px;
    transition: all linear 0.2s;

    /* top: ${props => props.show ? '0px' : '-100vh'}; */
    transform: ${props => props.show ? 'scale(1)' : 'scale(0)'};
     /* box-shadow:  0px 2px 5px 0px rgba(0,0,0,0.75); */


`;

const TitleBox = styled.div`
    width: 100%;
    /* background: red; */
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;

    .close{
        cursor: pointer;
        &:hover{
            svg{
                fill: red;
            }
        }
    }
    
    .p_title{
        font-size: 1rem;
        font-weight: 600;
        color: #004d79;
        text-transform: uppercase;

    }
`;

const BottomBox = styled.div`
    width: 100%;
    display: flex;
    padding: 10px;
    margin-top: auto;
    font-size: .9rem;
    box-sizing: border-box;

    button {
        border: none;
        padding: 6px 15px;
        background: #3a9bd0;
        color: white;
        border-radius: 3px;
        cursor: pointer;
        outline: none;
        font-size: .9rem;
    }
`;

const MessageBox = styled.div`
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    font-size: .9rem;
    color: #222;
    line-height: 1.2rem;
    
    &::-webkit-scrollbar {
        background-color:red;
        width:3px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 3px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 3px;
    }
`;






const ElementBox = styled.div`

    width: ${props => props.width || '35rem'};
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    transition: all 0.2s;
    border-radius: 6px;
    transform: ${props => props.show ? 'scale(1), translateX(0), translateY(0)' : 'scale(0)'};

    .popup_content{
        padding: ${props => props.padding || '7px 10px'};
        box-sizing: border-box;
        width: 100%;

    }

    .popup_header{
        background: linear-gradient(to right, #3E9EFF, #737DFF);
        height : ${props => props.title ? undefined : "3rem"};
        padding: 0px 5px;
        box-sizing: border-box;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        border-bottom: 1px solid #ccc;

        h1{
            font-size: 1.2rem;
            margin: 0px auto;
            font-weight: normal;
            text-align: center;
            width: 80%;
            text-overflow: ellipsis;
            overflow: hidden;
            margin: auto;
            color: #fff;
            font-weight: 500;
            text-transform: uppercase;
        }

        .popup_close{
            width: 1.4rem;
            height: 1.4rem;
            right: 1.3rem;
            position: absolute;
            top: 50%;
            margin-top: -.7rem;;
             background-color: #c85149;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            svg{
                fill: #fff;
            }
        }
    }
  
`;


const SharedAnimationWrapper = styled.div`
    box-sizing: border-box;
    transition: all 0.2s;
    transform: ${props => props.show ? 'scale(1), translateX(0), translateY(0)' : 'scale(0)'};
`;


class Popup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            startX: 0,
            stateY: 0,
            show_popups: {}
        }
        this.myRef = React.createRef();
        this.wrapperRef = React.createRef()
    }

    async copyTextToClipboard(text) {
        if ('clipboard' in navigator) await navigator.clipboard.writeText(text);
        else return document.execCommand('copy', true, text);
    }


    componentDidMount() {
        setTimeout(() => {
            let clone_prev_popups = this.state.show_popups || {};
            clone_prev_popups[this.props.popupKey] = true;
            this.setState({
                ...this.state,
                show_popups: clone_prev_popups
            })
        }, 200)
    }

    onCopyText = (copyText) => {
        this.copyTextToClipboard(copyText)
            .then(() => {
                setTimeout(() => {
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });
        alert("copied")
    }

    buttonClickHandler = (optionClicked, key) => {


        let clone_prev_popups = this.state.show_popups || {};
        delete clone_prev_popups[this.props.popupKey]
        this.setState({
            ...this.state,
            show_popups: clone_prev_popups
        })
        setTimeout(() => {

            if (this.props.closeHandler) {
                // use the clickhandler
                this.props.closeHandler(optionClicked, key);
            }
            else {
                this.props.popupClicked(optionClicked, key);
            }
        }, 100)
    }

    onDragEnd = (event) => {
        this.myRef.current.style.top = (event.clientY - this.state.startY) + "px";
        this.myRef.current.style.left = (event.clientX - this.state.startX) + "px"
    }

    onDragStart = (event) => {
        this.setState({
            startX: event.clientX,
            startY: event.clientY
        })
    }



    render() {

        const { title, message, type, popupKey, data, totalZIndexForPopUp, other_properties, disable_overlay_click } = this.props;

        const show = this.state.show_popups?.[this.props.popupKey];

        return (
            <div style={{ position: 'relative' }} >
                <Overlay
                    backgroundColor={this.props.overlay_color}
                    totalZIndexForPopUp={totalZIndexForPopUp} ref={this.wrapperRef}
                    top={this.props.top}
                    left={this.props.top}
                    onClick={() => {
                        if (!disable_overlay_click) {
                            this.buttonClickHandler(undefined, popupKey);
                        }
                    }}
                >

                    <PopupBox

                        top={this.props.top}
                        left={this.props.left}
                        onClick={(e) => e.stopPropagation()}
                        isElement={type === enums.popupType.element || type === enums.popupType.element_with_header}
                    >
                        {(type === enums.popupType.ok || type === enums.popupType.confirm || type === enums.popupType.sqlQuery) &&
                            <NotificationBoxWrapper
                                show={show}
                            >
                                <TitleBox>
                                    <span className='p_title'>{title}</span>
                                    <span className="close" onClick={() => this.buttonClickHandler(enums.popupButton.ok, popupKey)}>
                                        <CloseSvg size=".7rem" height=".7rem" color="#222" />
                                    </span>
                                </TitleBox>
                                <MessageBox id={type === enums.popupType.sqlQuery ? 'sql_query' : 'message'} style={{ overflowY: type === enums.popupType.sqlQuery && 'scroll' }}>
                                    {message}
                                </MessageBox>
                                <BottomBox>
                                    {type === enums.popupType.confirm ?
                                        <span>
                                            <button onClick={() => this.buttonClickHandler(enums.popupButton.yes, popupKey)}>Yes</button>
                                            <button onClick={() => this.buttonClickHandler(enums.popupButton.no, popupKey)}>No</button>
                                        </span>

                                        :

                                        type === enums.popupType.sqlQuery ?
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();

                                                    // const range = document.createRange();
                                                    // range.selectNode(document.getElementById("sql_query"));
                                                    // window.getSelection().removeAllRanges();
                                                    // window.getSelection().addRange(range);
                                                    // document.execCommand("copy");
                                                    // window.getSelection().removeAllRanges();
                                                    // alert('Value copied to your clipboard');
                                                    this.onCopyText(message)

                                                }}

                                            >Copy To Clipboard</button>
                                            :
                                            <button style={{ marginLeft: 'auto' }} onClick={(e) => {
                                                e.stopPropagation();
                                                this.buttonClickHandler(enums.popupButton.ok, popupKey);
                                            }}>Got it</button>
                                    }
                                </BottomBox>
                            </NotificationBoxWrapper>
                        }



                        {(type === enums.popupType.element) &&
                            this.props.element && <this.props.element message={message} close={this.buttonClickHandler} data={data} popupKey={popupKey} option={enums.popupType.element} />
                        }


                        {type === enums.popupType.sweet_alert && (
                            <SharedAnimationWrapper show={show}>
                                <SweetAlert
                                    title={title}
                                    message={message}
                                    close={this.buttonClickHandler}
                                    data={data}
                                    popupKey={popupKey}
                                    option={enums.popupType.element}
                                />
                            </SharedAnimationWrapper>
                        )}


                        {type === enums.popupType.loading && (
                            <Loading v2Loader={true} msg={message} useBg={true} />
                        )}

                        {(type === enums.popupType.element_with_header) &&
                            <ElementBox
                                show={show}
                                scale={type === enums.popupType.element_with_header}
                                width={other_properties?.width}>
                                {!this.props.hide_header && (
                                    <div className='popup_header'>
                                        <h1>{title}</h1>
                                        <div className='popup_close' title="Close" onClick={(e) => {
                                            this.buttonClickHandler(undefined, popupKey);
                                        }}>
                                            <CloseSvg size=".9rem" height=".9rem" color="#222" />
                                        </div>
                                    </div>
                                )}

                                <div className='popup_content'>
                                    {
                                        this.props.element && <this.props.element message={message} close={this.buttonClickHandler} data={data} popupKey={popupKey} option={enums.popupType.element} />
                                    }
                                </div>
                            </ElementBox>
                        }
                    </PopupBox>
                </Overlay>
            </div>
        );
    }
};



export default withRouter(connect(undefined, { popupClicked })(Popup))