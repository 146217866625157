import * as actions from '../action-types/discovery';

const initialState = {
    type: actions.DISCOVERY_INIT,
    discoveryData: undefined
};

const clientInfoReducer = (state, action) => {
	if (!state || typeof action.type === 'undefined') {
		return initialState;
    }

    switch (action.type) {
        case actions.SET_DISCOVERY_DATA:

            return {
                type: action.type,
                discoveryData: action.data
            };

            // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
			return {
                ...state
            };
        }
};

export default clientInfoReducer;