import React from "react";

const PieChartDashedIcon = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      shape-rendering="geometricPrecision"
      text-rendering="geometricPrecision"
      image-rendering="optimizeQuality"
      fill-rule="evenodd"
      clip-rule="evenodd"
      viewBox="0 0 1204 1156"
    >
      <path
        transform="translate(539,18)"
        d="m0 0h65l35 3 40 6 23 5 1 5-7 25-11 42-24 91-22 83-15 56-11 42-19 72-15 56-11 42-13 49-1 3 8 1 69 17 67 17 73 18 256 64 91 23 2 1-1 6-10 32-13 33-9 20-8 16-11 21-15 25-17 25-16 21-9 11-11 13-11 12-9 10-17 17-8 7-12 11-14 11-16 13-19 13-18 12-15 9-23 13-28 14-26 11-27 10-25 8-31 8-29 6-41 6-36 3h-42l-29-2-45-6-37-7-37-10-33-11-27-11-26-12-25-13-22-13-30-20-12-9-13-10-13-11-11-9-8-8-8-7-23-23-7-8-10-11-20-25-10-14-20-30-14-24-14-27-9-19-11-27-12-35-10-37-7-36-4-29-3-33v-62l3-37 4-28 6-31 9-35 12-36 11-28 13-28 8-15 6-11 9-16 14-22 12-17 12-16 13-16 11-13 7-7 7-8 25-25 8-7 10-9 25-20 18-13 26-17 15-9 22-12 16-8 17-8 32-13 33-11 38-10 31-6 27-4z"
        fill="#6D7AFD"
      />
      <path
        transform="translate(539,18)"
        d="m0 0h65l35 3 40 6 23 5 1 5-7 25-11 42-24 91-22 83-15 56-11 42-19 72-15 56-11 42-13 49-1 3 8 1 69 17 67 17 73 18 256 64 91 23 2 1-1 6-10 32-13 33-9 20-8 16-11 21-15 25-17 25-16 21-9 11-11 13-11 12-9 10-17 17-8 7-12 11-14 11-16 13-19 13-18 12-15 9-23 13-28 14-26 11-27 10-25 8-31 8-29 6-41 6-36 3h-42l-29-2-45-6-37-7-37-10-33-11-27-11-26-12-25-13-22-13-30-20-12-9-13-10-13-11-11-9-8-8-8-7-23-23-7-8-10-11-20-25-10-14-20-30-14-24-14-27-9-19-11-27-12-35-10-37-7-36-4-29-3-33v-62l3-37 4-28 6-31 9-35 12-36 11-28 13-28 8-15 6-11 9-16 14-22 12-17 12-16 13-16 11-13 7-7 7-8 25-25 8-7 10-9 25-20 18-13 26-17 15-9 22-12 16-8 17-8 32-13 33-11 38-10 31-6 27-4zm1 17-37 3-36 6-31 7-25 7-32 11-25 10-15 7-16 8-17 9-27 16-21 14-14 10-14 11-11 9-15 13-12 11-6 5-5 5-12 13-3 3v2l-4 2-9 10-8 10-11 13-10 14-6 8-7 11-9 13-15 26-13 25-13 29-11 30-7 21-6 23-3 13-3 12-4 27-3 16-3 41v49l3 40 3 21 4 24 6 28 8 29 8 24 14 34 12 25 13 24 6 10 8 13 7 11 8 12 21 28 12 14 9 11 10 10 1 2h2l2 4h2l2 4h2l2 4 8 7 13 13 11 9 14 12 19 14 20 14 18 11 15 9 18 10 23 11 23 10 29 11 24 8 32 8 23 4 31 3 17 3 52 2h22l26-2 13-2 25-3 31-6 16-4 23-6 21-7 17-6 12-5 29-13 25-13 40-24v-2l5-2 16-12 9-7 14-11 3-2v-2l4-2 14-12 7-7 7-6v-2l4-2v-2l3-1 7-8 10-10 5-6v-2l3-1 11-14 13-16 10-14 6-8 5-9 4-5 12-20 14-24 10-21 9-20 7-16 6-17 2-6v-6l-6-4-25-6-22-6-22-5-48-12-10-3-10-2-23-6-10-2-6-2-10-2-6-2-10-2-6-2-10-2-6-2-10-2-6-2-26-6-17-5-30-7-18-5-15-3-6-2-10-2-6-2-25-6-18-5-16-4-26-6-26-7-22-5-9-3-22-5-20-5-3-2 2-11 7-29 10-40 28-105 20-76 12-45 11-42 19-71 25-95 7-26 4-17v-8l-9-2-52-6-14-1z"
        fill="#2C98FE"
      />
      <path
        transform="translate(1173,370)"
        d="m0 0 3 1 7 23 9 37 2 9v4l-15 4-189 39-73 15-218 45h-9l-35-9-2-1 2-10 12-46 3-2 500-108z"
        fill="#2C98FE"
      />
      <path
        transform="translate(1130,273)"
        d="m0 0 4 1 15 29 14 31 3 8v3l-30 7-457 99h-3l1-8 13-49 7-26 4-4 108-23 208-44z"
        fill="#6D7AFD"
      />
      <path
        transform="translate(1065,181)"
        d="m0 0h5l11 13 15 20 13 18 10 15-1 2-32 7-373 79h-7l10-39 11-41 1-3 36-8 277-58z"
        fill="#2C98FE"
      />
      <path
        transform="translate(1194,471)"
        d="m0 0 5 1 3 30 2 31v15l-57 12-170 35-49 10-10-1-124-31-38-10v-2l131-27 282-58z"
        fill="#2C98FE"
      />
      <path
        transform="translate(973,94)"
        d="m0 0h6l12 10 10 8 14 12 15 14 18 18v2l-60 13-63 13-75 16-109 23h-5l2-10 17-64 3-8 9-3 75-16 127-27z"
        fill="#2C99FE"
      />
      <path
        transform="translate(1158,391)"
        d="m0 0h5l3 7 4 20 1 13-9 3-25 6-101 21-26 5-110 23-31 6-77 16-29 6-34 7-16 3-6 1h-23l-6-7v-8l3-13 5-4 13-4 30-7 35-8 28-6 36-8 20-4 26-6 42-9 50-11 65-14 34-7 31-7 43-9z"
        fill="#6D7AFD"
      />
      <path
        transform="translate(1130,273)"
        d="m0 0 4 1 15 29 14 31 3 8v3l-30 7-457 99h-3l1-8 13-49 7-26 4-4 108-23 208-44zm-10 20-69 15-182 38-32 7-43 9-25 6-42 9-12 5-3 3-7 26v12l3 2 18-1 18-4 107-23 50-11 52-11 59-13 47-10 45-10 26-6 8-3-1-9-10-25-3-5z"
        fill="#2C98FE"
      />
      <path
        transform="translate(1055,201)"
        d="m0 0h6l7 7 11 15 4 7-1 8-8 3-25 6-41 9-48 10-70 15-63 13-47 10-24 5-17 2-4-3 1-14 5-20 4-5 11-4 34-8 43-9 32-7 38-8 29-6 28-6 38-8 26-5z"
        fill="#6D7AFD"
      />
      <path
        transform="translate(1180,492)"
        d="m0 0 3 1 1 3v35l-1 3-16 5-27 6-26 5-91 19-30 6-39 8-17 3-6 1h-14l-24-6-21-6-9-7-2-2 1-4 4-3 21-6 41-9 32-7 31-6 28-6 96-20 30-6z"
        fill="#6D7AFD"
      />
      <path
        transform="translate(1197,576)"
        d="m0 0h7l-4 39-6 39-3 16-3 2-147-37-53-13-2-2 31-7 155-32z"
        fill="#2C99FE"
      />
      <path
        transform="translate(795,2)"
        d="m0 0 11 3 33 11 29 12 29 14 23 13 17 10 10 6v3l-31 6-128 27-20 4h-2l2-11 25-94z"
        fill="#2C98FE"
      />
      <path
        transform="translate(965,114)"
        d="m0 0h8l12 9 10 9 8 7 2 2v5l-5 4-8 3-36 8-25 5-51 11-53 11-39 8-14 2-8-1-1-2v-9l6-24 2-4 5-3 24-6 32-7 60-13 12-2 8-2 42-9z"
        fill="#6D7AFD"
      />
      <path
        transform="translate(537,33)"
        d="m0 0h85l22 4 10 1 9 2 8 1v1h-9l-43-5-14-1h-65l-37 3-36 6-31 7-25 7-32 11-25 10-15 7-16 8-17 9-27 16-21 14-14 10-14 11-11 9-15 13-12 11-4 4h-2l-1 3-6 5-7 8-6 7-12 12-8 10-11 13-10 14-6 8-7 11-9 13-15 26-13 25-13 29-11 30-7 21-6 23-3 13-3 12-4 27-3 16-3 41v49l3 40 3 21 4 24 6 28 8 29 8 24 14 34 12 25 13 24 6 10 8 13 7 11 8 12 21 28 12 14 9 11 10 10v2h2l6 7 8 7 17 17 11 9 14 12 19 14 20 14 18 11 15 9 18 10 23 11 23 10 29 11 24 8 32 8 23 4 31 3 17 3 52 2h22l26-2 13-2 25-3 31-6 10-1-4 2-36 8-23 4-12 2-1 1-9 1h-33l-6 1h-46l-2-1-13 1-10-1-18-5-25-5-9-3-14-3-19-5-20-6-15-5-19-7-20-8-17-9-4-1v-2l-6-1-8-4-3-3-13-6-6-3v-2h-3l-3-3-6-2-4-4-4-1v-2h-3l-2-2-7-4-2-2-7-6-5-2-3-3-4-2-9-7-5-4-2-2-9-6-14-13-2-1v-2l-4-2-5-4v-2l-4-2-5-5-3-2v-2l-4-2v-2h-2v-2h-2l-7-8-6-7v-2h-2l-9-11v-2l-9-9-1-3-8-8v-2h-2l-1-5-10-13-5-8-2-2-7-12-8-13-8-14-5-11v-2h-2l-11-23-13-29-10-27-8-27-4-14-1-3 1-4-2-1-1-5v-7l-2-1-2-15-3-19-2-15-2-37-1-16 1-16v-16l1-16 3-24 2-21 5-28 5-21 2-10 5-17 3-12 6-15 4-11 3-9 10-23 16-32 10-17 7-12 13-20 8-12 20-26 14-17 12-13 9-10 14-14 8-7 21-18 13-10 15-12 9-6 16-11 16-10 15-9 28-15 23-10 13-5 12-5 14-5 17-5 24-7 29-7 19-3 4-1 19-2 10-2z"
        fill="#488EFE"
      />
      <path
        transform="translate(671,42)"
        d="m0 0h10l2 2-1 9-3 15-6 20-6 26-10 36-20 75-9 34-10 38-7 27-5 18-7 27-11 42-12 45-8 31-8 29-9 34-6 22-7 29v6l10 5 27 5 19 5 40 10 21 5 241 60 55 14 21 5 42 11 63 16 13 4 8 3 1 7-6 18-8 20-3 9-3 6-2 6h-2v5l-4 8h-2l-1 6-7 14-13 23-7 13h-2l-2 6-6 9-7 10-10 13-7 10-9 10-8 11-43 43-8 7-16 13-17 13-18 13-11 7-14 9-13 8-16 9-21 11-21 9-25 10-37 12-23 6-4-1 32-9 27-9 12-5 13-5 28-13 24-13 14-8 19-12 4-1v-2l19-13 11-9 14-11 3-1v-2l10-8 12-11 3-3h2l1-3 7-6 6-7h2l2-4 10-10 6-8 5-5 13-16 11-14 12-17 7-11 7-10 16-28 6-10 10-21 9-20 7-16 6-17 2-6v-6l-5-3-25-6-22-6-22-5-48-12-10-3-10-2-23-6-10-2-6-2-10-2-6-2-10-2-6-2-10-2-6-2-10-2-6-2-26-6-17-5-30-7-18-5-15-3-6-2-10-2-6-2-25-6-18-5-16-4-26-6-26-7-22-5-9-3-22-5-20-5-4-3 2-11 7-29 10-40 28-105 20-76 12-45 11-42 19-71 25-95 7-26 4-17 1-8-9-1z"
        fill="#498EFF"
      />
      <path
        transform="translate(810,26)"
        d="m0 0 17 5 20 8 24 11 16 8 2 6-1 3-11 4-21 5-15 3-24 5-9 1h-10l-3-2v-9l5-26 5-18z"
        fill="#6D7AFD"
      />
      <path
        transform="translate(1174,598)"
        d="m0 0h9v13l-4 28-3 9-4 2-12-2-44-11-16-5-6-5-1-6 4-4 11-4 63-14z"
        fill="#6D7AFD"
      />
      <path
        transform="translate(1056,198)"
        d="m0 0h6l6 4 12 16 5 8v2h2l1 3v7l-3 2-15 4h-7l3-2 16-4v-8l-6-10-11-14-4-4-9 1-39 8-28 6-30 6-23 5-38 8-35 7-99 22-13 4-5 5-5 20-1 14 5 2 25-4 23-5 48-10 9-1-3 2-65 14-40 8h-6l-3-5 2-12 3-15 4-12 7-6 26-5 26-6 41-9 15-3 38-8 22-5 54-11 23-5 59-12z"
        fill="#498EFF"
      />
      <path
        transform="translate(1148,392)"
        d="m0 0h7l-4 2-36 8-56 12-47 10-36 8-32 7-60 13-36 8-20 4-36 8-28 6-70 16-10 4-3 5-2 10v8l5 6h23l14-3 17-3 11-1v1l-28 6-22 4h-15l-8-3-2-2-1-13 3-11 3-6 3-3 20-5 15-3 21-5 15-3 58-13 33-7 22-5 15-3 55-12 78-17 15-3 28-6 13-3 16-3 49-10z"
        fill="#498EFF"
      />
      <path
        transform="translate(1167,493)"
        d="m0 0h7l-4 2-29 6-32 6-46 10-30 6-28 6-38 8-26 5-62 14-15 5-2 2 1 4 9 7 28 8 17 4h14l20-3 13-3h6l-3 2-15 3-12 3-5 1h-21l-10-3-13-3-16-4-14-3-4-2-16-5-4-4 2-4 10-4 18-4 22-4 24-5 31-6 33-7 40-8 18-4 85-17z"
        fill="#498EFF"
      />
      <path
        transform="translate(1115,292)"
        d="m0 0h10l4 6 4 11 4 12 3 7-1 6-3 2h-6l3-2 5-1-2-9-10-25-2-4-4-1-69 15-182 38-32 7-43 9-25 6-42 9-12 5-3 5-6 23v12l2 1h18v1l-19 4h-6l-2-2v-9l3-15 6-20 7-6 13-3 41-9 28-6 18-4 56-12 58-12 56-12 58-12 30-6 23-5 17-3z"
        fill="#498EFF"
      />
      <path
        transform="translate(964,111)"
        d="m0 0h10l9 6 7 6 6 4 5 6 8 7 4 4-1 5-18 5-37 8-52 11-57 12-23 5-43 9-11 2h-10l-2-3 2-18 3-9 2-8 3-6 4-4 20-5 28-6 103-22 33-7zm1 3-41 9-16 3-7 2-26 5-31 7-47 10-21 6-4 4-3 9-4 17v9l1 2 8 1 24-4 43-9 105-22 36-8 16-4 6-4 1-1v-5l-8-8-11-9-10-8-3-2z"
        fill="#498EFF"
      />
      <path
        transform="translate(1179,596)"
        d="m0 0h6l2 4-2 5-3 26-2 12-2 9-5 2-17-5-37-9-24-6-14-4-9-4-2-5 4-4 15-4 16-3 24-4 37-7 8-1-3 2-40 9-23 5-12 5-2 2 1 6 5 4 16 5 31 8 22 5 6-1 3-9 4-28 1-13h-8z"
        fill="#498EFF"
      />
      <path
        transform="translate(806,24)"
        d="m0 0h7l17 6 12 5 11 4 18 8 19 10 6 4 1 1v5l-17 5h-6l3-2 11-3-1-8-16-8-19-9-20-8-21-7-5 3-5 18-5 26v9l2 1h10l13-2 10-2h6v1l-27 6-10 2h-9l-2-2 1-14 7-23 5-22z"
        fill="#498EFF"
      />
      <path
        transform="translate(536,601)"
        d="m0 0h1l2 7 7 4-4 1-7-3-1-4z"
        fill="#478EFE"
      />
    </svg>
  );
};

export default PieChartDashedIcon;
