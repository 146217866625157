import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ExpandSvg from '../svg/expand';
import TitleHeader from '../title-header';
import ReportWrapper from '../report-wrapper';
import { FullWidthDiv } from '../full-width-div';
import { Wrapper } from './discovery.styled';
import { showPopup } from '../../utils';
import * as enums from '../../utils/enums';
import DiscoveryPopup from './discovery.popup'
import { hideMenuConfig } from '../../actions/menu.config.righter';
import { withRouter } from 'react-router-dom';


const Discovery = props => {

    const { discoveryData } = props;
    const [wrapperWidth, setWrapperWidth] = useState(undefined);
    const [wrapperHeight, setWrapperHeight] = useState(undefined);

    const wrapperRef = useRef(null)

    useEffect(() => {
        if (wrapperRef && wrapperRef.current) {
            setWrapperWidth(wrapperRef.current.offsetWidth);
            setWrapperHeight(wrapperRef.current.offsetHeight);
        }
    }, [])


    const expand_click = (question, graph, index, title) => {

        showPopup(undefined, undefined, enums.default.popupType.element, DiscoveryPopup, {
            question: question,
            graph: graph,
            index: index,
            title: title,
            hideMenuConfig: () => props.hideMenuConfig()
        },

        )
    }

    let page_title = 'Polus Discovery';

    if (discoveryData && discoveryData.trend_filters && discoveryData.trend_filters.dashboard && discoveryData.trend_filters.dashboard.length > 0) page_title = ('Polus Discovery | ' + discoveryData.trend_filters.dashboard[0].parent + ' data ');

    return (
        <FullWidthDiv
            ref={wrapperRef}
        >
            <TitleHeader
                title={page_title}
                showEdit={false}
                showDuplicate={false}
                showDelete={false}
                showSqlQuery={false}
                showDownload={false}
            />
            <Wrapper>

                {
                    wrapperWidth && wrapperHeight && discoveryData && discoveryData.trend_filters && discoveryData.trend_filters.dashboard.map((d, index) => {
                        return (
                            <div
                                style={{
                                    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
                                    marginBottom: '2%',
                                    borderRadius: '5px',
                                    boxSizing: 'border-box',
                                }}
                                key={"discovery" + index}
                            >
                                <div style={{
                                    textAlign: 'center',
                                    fontSize: '1.143rem',
                                    fontWeight: '500',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '3rem',
                                    maxHeight: '3rem',
                                    textTransform: 'capitalize',
                                    position: 'relative',
                                }}>
                                    {d.title ? d.title : ''}
                                    <div title='Click to Expand' className='expand_icon'>

                                        <ExpandSvg
                                            color='black'
                                            size="25px"
                                            height="22px"
                                            onClick={() => expand_click(d.question, d.graph, index, d.title)}
                                        />
                                    </div>
                                </div>

                                <ReportWrapper
                                    question={d.question}
                                    insightWidth={.30 * wrapperWidth}
                                    insightHeight={.37 * wrapperHeight}
                                    isDiscovery={true}
                                    id={'disc_' + index}
                                    hide_legend={true}
                                    forcedChartType={d.graph}
                                    forcedRenderMode={(d.graph && d.graph === 'table') ? 'table' : 'chart'}
                                    history={props.history}
                                />
                            </div>
                        )
                    })
                }
            </Wrapper>
        </FullWidthDiv>
    )


};

const mapStateToProps = state => {
    const { discoveryData } = state.discoveryReducer;

    return { discoveryData }
};

export default withRouter(connect(mapStateToProps, { hideMenuConfig })(Discovery));