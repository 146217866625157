export const ACCESS_GROUP_INIT = 'modules/access_group/ACCESS_GROUP_INIT';

export const GET_ACCESS_GROUP = 'modules/access_group/GET_ACCESS_GROUP';
export const GET_ACCESS_GROUP_WITH_USERS = 'modules/access_group/GET_ACCESS_GROUP_WITH_USERS';

export const GET_GROUP_ACCESS_GROUP = 'modules/access_group/GET_GROUP_access';
export const CLEAR_GROUP_ACCESS_GROUP = 'modules/access_group/CLEAR_GROUP';
export const SAVE_GROUP_ACCESS = 'modules/access_group/save_group_Access';

export const GET_USER_LIST_SUCESS = 'modules/access_group/get_user_list';



export const GET_GROUP_ACCESS_FOR_MENU = 'modules/access_group/get_group_access_for_menu';


export const GET_ACCESS_GROUP_INFO = 'modules/access_group/GET_ACCESS_GROUP_INFO'
export const SAVE_ACCESS_GROUP_INFO = 'modules/access_group/SAVE_ACCESS_GROUP_INFO';


// DATA POLICY 

export const GET_ALL_DATA_POLICY_SUCCESS  = 'module/access_group/GET_ALL_DATA_POLICY_SUCCESS';
export const GET_DATA_POLICY_BY_ID_SUCCESS  = 'module/access_group/GET_DATA_POLICY_BY_ID_SUCCESS';
export const SAVE_DATA_POLICY  = 'module/access_group/SAVE_DATA_POLICY';
export const CLEAR_EDIT_DATA_POLICY = 'module/CLEAR_EDIT_DATA_POLICY'

