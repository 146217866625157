import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;

    .title{
        font-size: 1.286rem;
         font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        margin: 15px 0px;
        text-transform: capitalize;
        margin-top: 0px;
        padding-left: 5px;


    }
`;


export const Table = styled.table`
    /* border: 1px solid #ccc; */
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    /* cell-padding: 0px; */

    /* border-collapse: collapse; */
    /* tr{
        border-spacing: 0px;
        display: block;
         border-collapse: collapse;
    } */

    td{
        border: 1px solid #ccc;
        border-spacing: 0px;
        margin: 0px;
        position: relative;
        border-collapse: collapse;
        /* margin-bottom: 5px; */
        /* border-radius: 3px; */


        input{
            border: 0px;
            padding:  0px 10px;
            font-size: .9rem;
            line-height: 40px;
            height: 40px;
            position: absolute;
            left: 0px;
            width: 100%;
            z-index: 1;
            box-sizing: border-box;

            &:focus{
                outline: 0;
            }
        }
        span{
            border: 0px;
            padding:  0px 10px;
            height: 40px;
            line-height: 40px;
            font-size: .9rem;
            display: inline-block;
        }
    }
`;

export const Thead = styled.thead`

    td{
        color: #308bc8;
        font-size: 1.2rem;
        /* border-radius: 3px; */
        overflow: hidden;
        /* margin-bottom: 15px; */
        height: 40px;
        /* padding: 5px; */
        box-sizing: border-box;
        border-radius: 0px;
        padding: 0px 10px;
        /* border: 0px; */
        
    }

`;
export const Tbody = styled.tbody`

    td{
        /* box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
        transition: all 0.2s; */
        /* border: 0px; */
    }

`;