import * as actions from '../action-types/multi.display';

const initialState = {
    type: actions.MULTI_DISPLAY_INIT,
    multiDisplayData: undefined
};

const multiDisplayReducer = (state, action) => {
	if (!state || typeof action.type === 'undefined') {
		return initialState;
    }

    switch (action.type) {
        case actions.SET_MULTIDISPLAY_DATA:

            return {
                type: action.type,
                multiDisplayData: action.data
            };

        default:
			return {
                ...state
            };
        }
};

export default multiDisplayReducer;