import React, { useState, useEffect } from 'react';

import { HintsWrapper, AddNewTag, ExistingTags, Filterd, FilterdItemRow } from './hints-edit.style';
import { Button } from '../../hoc/button'
import { Gap } from '../../gap';
import CrossSvg from '../../svg/close';
import EditSvg from '../../svg/edit';
import { insertAtSpecificLocationInArray, removeElementFromArray } from '../../../utils';
import { connect } from 'react-redux';


const HintEditor = props => {

    const { close, option, popupKey, data, closeHintsPopup } = props;
    const { hintData, func } = data;
    const [displayHint, setDisplayHint] = useState(undefined);
    const [filteredHints, setFilteredHints] = useState([]);
    const [editIndex, setEditIndex] = useState(undefined);
    const [hint, setHint] = useState(undefined);
    const [showFilterError, setFilterError] = useState(undefined);
    const [isInputShow, setisInputShow] = useState(false);
    const [inputValue, setinputValue] = useState('');

    useEffect(() => {
        if (hintData && hintData.values && hintData.values.length > 0) {
            setFilteredHints(hintData.values);
        }
        setHint(hintData);
    }, [hintData]);


    const addHints = value => {
        const tempFilteredHints = [...filteredHints];
        const caseInSensitiveValue = typeof value === 'string' ? value.toLowerCase().trim() : value.trim();

        if (tempFilteredHints.indexOf(caseInSensitiveValue) === -1) {
            if (!editIndex) {
                tempFilteredHints.push(value);

                setFilteredHints(tempFilteredHints);

            }
            else {
                const newArray = insertAtSpecificLocationInArray(tempFilteredHints, editIndex, value);
                setEditIndex(undefined);
                setFilteredHints(newArray);
            }
            setDisplayHint(undefined);
            setinputValue("");
        }
        else {
            setFilterError(true);
        }
    };


    useEffect(() => {
        if(closeHintsPopup){
            close(option, popupKey);
        }
    },[closeHintsPopup])


    const removeSelectedHint = (indexOfRemovedHint) => {
        const tempFilteredHints = [...filteredHints];

        tempFilteredHints.splice(indexOfRemovedHint, 1);

        setFilteredHints(tempFilteredHints);
    };

    const _editHints = (value, index) => {
        let tempFilteredHints = removeElementFromArray(index, undefined, filteredHints);

        setinputValue(value);
        setEditIndex(index);
        setisInputShow(true);
        setFilteredHints(tempFilteredHints);
    }


    const _save = () => {
        const temphint = Object.assign({}, hint);
        temphint.modified = true;
        temphint.values = [...filteredHints];
        func.saveHints(temphint);
    }



    return (
        <HintsWrapper>
            {editIndex ?
                <AddNewTag
                    padding={'0'}
                    onClick={() => setisInputShow(true)}
                >
                    <span style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <EditSvg size="1rem" height="1rem" />
                    </span>
                    <span className="add_title">Edit Hint</span>
                </AddNewTag>

                :

                <AddNewTag
                    onClick={() => setisInputShow(true)}
                >
                    <span className="add" style={{ marginTop: '-4px' }}>+</span>
                    <span className="add_title">Add new hint</span>
                </AddNewTag>
            }


            <input
                type="text"
                autoFocus
                value={inputValue}
                placeholder="Enter Hint"
                onChange={(e) => setinputValue(e.target.value)}
                onKeyDown={(event) => {
                    let value = inputValue.trim()
                    if (event.key === 'Enter' && value && value.length > 0) {
                        addHints(value)
                        setisInputShow(false)
                        setinputValue("")
                    }
                }}
            />


            <Gap h="25px" />

            {filteredHints && filteredHints.length > 0 && (
                <ExistingTags>
                    <h2 className="e_tags">Existing hints</h2>
                    <Gap h="14px" />
    
                    <div style={{height: '12rem', border: '1px solid #ddd', borderRadius: '8px'}}>
                    <FilterdItemRow>
                        {filteredHints.map((hint, index) => {
                            return (
                                <Filterd>
                                    {hint}
                                    <div className="filter_hover">
                                        <div onClick={() => removeSelectedHint(index)}><CrossSvg size="12px" height="12px" fill="#737373" /></div>
                                        <div onClick={() => _editHints(hint, index)}><EditSvg size="12px" height="12px" fill="#737373" /></div>
                                    </div>
                                </Filterd>
                            )
                        })}
                    </FilterdItemRow>
                    </div>
                </ExistingTags>
            )}



            <div style={{
                marginTop: 'auto',
                width: '100%',
                textAlign: 'right'
            }}>
                <Button
                    primary={true}
                    bgColor={'linear-gradient(to right, #3E9EFF, #737DFF)'}
                    width='6rem'
                    mR="16px"
                    onClick={_save}
                >
                    Save
                </Button>
                <Button
                    width='6rem'
                    bgColor={'#e6e6e6'}
                    style={{
                        marginBottom: 0
                    }}
                    onClick={() => {
                        close(option, popupKey)
                    }}
                >Cancel</Button>
            </div>
        </HintsWrapper>
    );
};

const mapStateToProps = (state) => ({
    closeHintsPopup: state.hintsReducer.closeHintsPopup
})

export default connect(mapStateToProps, {}) (HintEditor)

