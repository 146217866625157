export const MAP_INIT = 'modules/map/init';

export const HOSPITAL_MARKERS_REQUESTED = 'modules/hospital/markers/request';
export const HOSPITAL_MARKERS_SUCCESS = 'modules/hospital/markers/success';

export const GET_MARKER_INFO_SUCCESS = 'modules/marker/info/success';

export const GET_ZONE_SUCCESS = 'modules/zone/success';
export const GET_MULTI_ZONE_SUCCESS = 'modules/zone/GET_MULTI_ZONE_SUCCESS';

export const CLEAR_ZONE_SUCCESS = 'modules/zone/clearZone';
export const GET_ZONE_NAME_SUCCESS = 'modules/zone/name/success';
export const GET_MARKER_IN_RADIUS_SUCCESS = 'modules/marker/radius/success';

export const GET_CUSTOM_ZONE_MARKERS = 'modules/custom/zone/markers/success';

export const SET_FORCE_ZONE_MARKERS = 'modules/marker/force/success';

export const SET_FORCE_ZONE = 'modules/marker/force/zone_success';



export const MAP_FILTER_CHANGE = "modules/mapFilter/change";

export const PREDEFINED_MARKERS_SUCCESS = 'modules/predefined/marker';

export const GET_AUTOCOMPLETE_NAME_SUCCESS = 'modules/autocomplete/names/success';

export const CLEAR_ENTITY_SUCCESS = 'modules/entity/clear/success';

export const GET_SEARCH_MARKER_SUCCESS = 'modules/marker/success';

export const GET_ALL_ZONE_BY_CLIENT = 'modules/zone/allZones'

export const FLUSH_MAP = 'modules/flush/map';

export const GET_MARKERS_OF_QUESTION_SUCCESS = 'modules/question/marker/success'
export const CLEAR_SEARCH_MARKER = 'modules/search/marker/clearSearch_marker'