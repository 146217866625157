import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TextBox from '../../hoc/textBox';
import { Button } from '../../hoc/button';


const Wrapper = styled.div`
    width: 25rem;
    border-radius: 6px;
    border: 2px solid #ccc;
    background-color: #fff;
    padding: 1rem;

    h1{
        font-size: 1.2rem;
        font-weight: 400;
        margin-top: 0px;
        margin-bottom: 1.5rem;
        text-align: center;
    }

`;


function Index(props) {

    const { close, popupKey, option, data } = props;
    const { textValue, setTextValue } = useState(undefined)


    return (
        <Wrapper>
            <h1>
               Please Provide Primary Key
            </h1>

            <TextBox 
                label="Enter Primary Key"
                value={textValue}
                onChange={(e) => {
                    setTextValue(e.target.value)
                }}
            />

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '2rem' }}>
                <Button
                    width="6rem"
                    mR="1rem"
                    primary={true}
                    onClick={() => {
                        // on_Save_function()

                    }}>Save</Button>
                <Button width="6rem" bgColor={'#e6e6e6'} onClick={() =>
                    close(option, popupKey)
                }>Cancel</Button>
            </div>

        </Wrapper>
    )
}

export default Index
