import React, { useState, useRef, useEffect } from 'react'
import AutoSearch from '../../auto.search';
import TablePopup from '../table.popup'
import ViewSvg from '../../svg/view';
import * as enums from '../../../utils/enums';
import { showPopup } from '../../../utils';
import ExclamationSvg from '../../svg/exclamation';
import TextBox from '../../hoc/textBox';
import { Button } from '../../hoc/button';
import SelectOption from '../../select-option';
import RippleEffect from '../../hoc/ripple-effect'
import CorrectSvg from '../../svg/correct';
import DropDownSvgUp from '../../svg/dropDownUp';
import DropDownSvgDown from '../../svg/dropDown'
import AutoSearchNew from '../../auto.search/auto.search.new'
import MappingTableRow from './mapping.table.row';
import { connect } from 'react-redux';




const MappingTable = (props) => {

    const {
        tableHead,
        tableData,
        sourceColumns,
        ignoreError,
        errorPopupValue,
        sortMappingTable,
        sortOrder,
        is_edit_mode,
        on_change_v1,
        update_all_row_values,
        target_db_id,
        schema_table_info,
        suggest_table

    } = props;

    const [show_table_input, set_show_table_input] = useState(undefined);

    const showTablePopupClicked = (name) => {
        let post_data = {
            database_id: props.target_schema,
            table_name: name
        }
        props.getTargetTableData(post_data)
        showPopup(undefined, undefined, enums.default.popupType.element, TablePopup, {
            tablName: name
        })
    };




    return (
        <div style={{ width: '100%', }} className="source_tbl_main">
            <table style={{background: '#E8E8F9'}}>
                <thead>
                    <tr>
                        {tableHead && tableHead.length > 0 && tableHead.map((row, i) => {
                            let orignal_name = row === 'is_direct_mapping' ? "Direct Mapping ?" : row === "criteria" ? "Your Business logic" : row.split('_').join(' ');
                            const info = {
                                dataKey: row,
                                type: 'string',
                            }
                            return (
                                <th
                                    style={{
                                        textTransform: 'capitalize',
                                        width: (row === 'source_column' || row === 'target_table' || row === 'target_column') ? '250px' : (row === 'is_direct_mapping') ? '150px' : undefined

                                    }}
                                    key={"tbl_column" + i}>{orignal_name}

                                    {row === "target_table" && (
                                        <React.Fragment>
                                            <div
                                                style={{
                                                    width: "10px",
                                                    height: "10px",
                                                    position: "absolute",
                                                    right: "6px",
                                                    top: "10px"
                                                }}
                                                onClick={() => {
                                                    // set_show_table_input(!show_table_input)
                                                    // pick_table_name()
                                                }}>
                                                :
                                            </div>

                                            
                                        </React.Fragment>
                                    )}
                                </th>
                            )
                        })}
                    </tr>
                </thead>

                <tbody>
                    {tableData && tableData.length > 0 && tableData.map((row, i) => {
                        return (
                            <MappingTableRow
                                tableHead={tableHead}
                                rowData={row}
                                row_index={i}
                                sourceColumns={sourceColumns}
                                errorPopupValue={errorPopupValue}
                                ignoreError={ignoreError}
                                target_schema={props.target_schema}
                                showTablePopupClicked={showTablePopupClicked}
                                tableData={tableData}
                                is_edit_mode={is_edit_mode}
                                on_change_v1={on_change_v1}
                                target_db_id={target_db_id}
                                suggest_table={suggest_table}
                            />
                        )
                    })}
                </tbody>
            </table>
        </div >
    )
}



const mapStateToProps = (state) => ({
    schema_table_info: state.reportBuilder.schema_table_info,
})



export default connect(mapStateToProps, {})(MappingTable);
