import styled from 'styled-components';


export const Wrapper = styled.div`
    margin-top: 50px;
    width: 100%;
    overflow-y: scroll;
    height: 80vh;
    border: 1px solid rgb(236, 235, 237);
    margin-top: 20px;
    box-shadow: rgb(221, 220, 221) 2px 2px 5px -1px;
    background: rgb(255, 254, 255);
    border-radius: 14px;
    /* overflow: hidden; */
    padding: 2rem;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        width: 0 !important;
    }

    .title{
        font-size: 1.5rem;
        box-sizing: border-box;
        position: relative;
        z-index: 1;
        font-weight: bold;
        text-transform: uppercase;
        color: rgb(0, 93, 134);
        margin-bottom: 2rem;
    }

    .list_icon{
        margin: 0px 1rem;
        margin-left:0rem;
    }
    .did_you_mean{
        color: #ea4335;
        font-size: 1rem;
        margin-bottom: 1rem;
    }

    .list_item{
        margin-bottom: .7rem;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
       
    }
    .list_name{
        font-size: 1rem;
        text-transform: capitalize;
        cursor: pointer;
        color: #1a0dab;
        &:hover{
            text-decoration: underline;
        }
    }
`;
