import * as actions from '../action-types/rules.alert';

const initialState = {
    type: actions.RULES_ALERT_INIT,
    rules_alerts_data: undefined,
    rule_tickets_cache: undefined,
    ruleJobs: undefined,
    ruleByReportId: undefined,
};

const rulesAlert = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {


        case actions.GET_JOB_BY_REPORT_ID:

            return {
                ...state,
                type: action.type,
                ruleByReportId: action.data

            }

        case actions.CLEAR_JOB_BY_REPORT_ID:
            return {
                ...state,
                type: action.type,
                ruleByReportId: undefined
            }

        case actions.GET_ALL_RULES_JOBS:

            return {
                ...state,
                ruleJobs: action.data,
                type: action.type,

            }

        case actions.GET_ALL_RULES_ALERT_SUCCESS:

            return {
                ...state,
                type: action.type,
                rules_alerts_data: action.data
            };

        case actions.GET_TICKETS_OF_RULE_SUCCESS:

            const clone_rule_tickets_cache = state.rule_tickets_cache ? JSON.parse(JSON.stringify(state.rule_tickets_cache)) : {};
            if (action.data?.rule_id) {
                clone_rule_tickets_cache[action.data?.rule_id] = action.data?.tickets
            }
            return {
                ...state,
                type: action.type,
                rule_tickets_cache: clone_rule_tickets_cache
            };

        default:
            return {
                ...state,
            };
    }
};

export default rulesAlert;