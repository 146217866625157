/*
    * This Component acts parent component for ChartWrapper, ChartOptions, TableWrapper and SingleCellData.
    * This component render according to RenderMode and ChartType which are coming from Props
*/


import React, { useRef, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { saveReport, setAvailableData, getDataFromReportingServer, getReportById, dispatch_pivot_data } from '../../actions/report';
import { Wrapper, LeftSection } from './reports.styled';
import { getReport } from '../../actions/report';
import { REPORT_TO_REFRESH } from '../../action-types/report';
import { reportFormatToSave, return_required_state_for_report_wrapper_v1, returnRequiredState, getParamByName, updateUrlParameter, getParameterByName, get_data_from_server_action, create_report_nf_date_title, console_logger, get_time_format_v1, is_date_month, get_chart_type_and_other_config_data, get_pivot_formatted_axis, get_raw_data_axis, process_column_meta_and_data_type } from '../../utils';
import ErrorLoading from '../error_loading';
import ViewWrapper from '../viewer-wrapper';
import { getRemToPx, createTitleFromDataKeys, showPopup, hideLastPopup, generatePdf, get_valid_id_and_report_type } from '../../utils';
import RighterMenuSection from './right.menu.section';
import Map from '../map';
import KipComponent from '../kpi';
import FormulaCreator from '../formula-creator';
import * as enums from '../../utils/enums';
import { saveInSession, getFromSession, removeItemFromSession } from '../../utils/session.helper'
import { constants } from '../../utils/constants';
import { RENDER_MODE, CHART_TYPES, PIVOT_COLUMN_TYPE } from '../../shared-with-fe/constants';
import { showError, toogleHeader } from '../../actions/helper';
import { raiseMenuConfig, hideMenuConfig } from '../../actions/menu.config.righter'
import { RIGHTER_MENU_MODE } from '../../shared-with-fe/constants';
import { store } from '../../store';
import { set_table_data_mode } from '../../actions/helper';
import { raise_drill_filter_cache, mt_deleted_filter, clear_drill_filter_cache, apply_filters } from '../../actions/filter';
import { save_filters_in_session } from '../../utils/session.helper';
import WarningSvg from '../svg/warning';
import RippleEffect from '../hoc/ripple-effect';
import { raiseExcelDownloadRequest } from '../../actions/table';
import axios from 'axios';
import * as utils from '../../utils/index'

var fe_render_times = {};

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};



const get_valid_filter_for_submition = (filter_to_validate = {}, validation_data = [], type) => {

    let validated_filters = {};

    const filter_to_validate_clone = filter_to_validate ? JSON.parse(JSON.stringify(filter_to_validate)) : {};
    /**
         * 16 Feb 2023
         * for HRPL LOCAL FILTER
    */
    // let's validate the filter if it is persent in validation_data then keep it otherwise remove it blindly


    if (filter_to_validate_clone && Object.keys(filter_to_validate_clone)?.length > 0) {

        Object.keys(filter_to_validate_clone).forEach((filter_key) => {

            if (filter_key === "date_filter") {

                // we are not validating the date filter for now
                if (!validated_filters["date_filter"]) validated_filters["date_filter"] = {};
                validated_filters["date_filter"] = filter_to_validate_clone["date_filter"];


            } else {
                if (validation_data && validation_data?.length > 0 && validation_data.indexOf(filter_key) > -1) {
                    // yeha it is exist move it 
                    if (!validated_filters[filter_key]) validated_filters[filter_key] = {};
                    validated_filters[filter_key] = filter_to_validate_clone[filter_key];

                }
            }
        })
    }
    return validated_filters
}

const render_count_report = {};


var prev_chart_type = {}
const ReportWrapper = (props) => {

    const {
        reportId,
        loading,
        insights,
        insightWidth,
        insightHeight,
        filters,
        question,
        width,
        height,
        scheduleReportId,
        dataFromServer,
        id,
        isDiscovery,
        hide_title,
        is_group,
        insightChartType,
        insightRenderMode,
        getReport,
        isDimension,
        isPolusAIWindow,
        IsDataAvailable,
        setAvailableData,
        forcedChartType,
        availableData,
        childDimension,
        forceRenderOneTime,
        isPolusIqHide,
        isPredictive,
        title,
        user,
        mapFilter,
        reportTitle,
        click_onExpandMap,
        forcedTitle,
        hideChartOption,
        isMobile,
        db_info_id,
        running_total,
        should_refresh,
        filters_cache,
        drill_down_data_cache,
        mtd_ytd_options_cache,
        menu_render_mode,
        report_type,
        view_mode,
        reportItemId,
        is_global_filters,
        set_table_data_mode,
        report_item_filters,
        report_filters,
        validation_keys,
        is_print_report,
        chart_type_command,
        download_command,
        refresh_frequency,
        is_chart_changed,
        file_name,
        print_type,
        isDashboardPrintMode,
        expandCommand,
        downloadCommand,
        why_addition_filter,
        isHighlightedMin,
        
    } = props;

    const { external_report_id, insight_report_type, insight_default_filter_values, tableDataMode } = props;

    const topRef = useRef(null);
    const refLeftSection = useRef();

    const [currentState, setCurrentState] = useState({});

    const [dimensions, setDimensions] = useState(undefined);
    const [LefterWidth, setLefterWidth] = useState(undefined);
    const [forceRefresh, setForceRefresh] = useState(undefined);
    const [isKpiReport, setKpiReport] = useState(undefined);
    const [columnsAligments, setColumnAlignments] = useState(undefined);
    const [ultimate_yacs, set_ultimate_yacs] = useState(undefined);
    const [pivot_data_ultimate_yacs, set_pivot_data_ultimate_yacs] = useState(undefined);
    const [grandTotalColumns, setGrandTotalColumns] = useState(undefined);
    const [pivot_data_grand_total, set_pivot_data_grand_total] = useState(undefined);
    const [mainReportId, setMainReportId] = useState(undefined);
    const [isDetails, setIsDetails] = useState(undefined);
    const [filterFromURL, setFilterFromURL] = useState(undefined);

    const [localForceType, setLocalForceType] = useState(undefined);
    const [reportType, setReportType] = useState(undefined);
    const [localRunningTotal, setLocalRunningTotal] = useState(undefined);
    const [isGroupedData, setIsGroupedData] = useState(undefined);

    const [currentDataFromServer, setCurrentDataFromServer] = useState(undefined);


    if (!prev_chart_type[id] && insights) prev_chart_type[id] = insightChartType;


    useEffect(() => {
        // props.get_job_status('job_one')

        if (refresh_frequency) {

            var job_id = 'nf_ldsaczwj';

            const options = {
                headers: utils.getMandatoryRequestHeaders(undefined),
                id: Math.random()
            }


            function get_frequency(input, delimiter) {
                const parts = input?.split(delimiter)?.filter((a) => a !== '');
                console.log("parts", parts)
                if (parts.length !== 2) {
                    return {
                        value: '',
                        unit: ''
                    }
                }
                return {
                    value: parts && parseInt(parts[0], 10),  // Convert the first part to an integer
                    unit: parts[1].trim()           // Trim any whitespace from the second part
                };
            }

            const frequency_value = get_frequency(refresh_frequency, '____nfx___');

            const interval_time = utils.convertToMilliseconds(frequency_value?.value, frequency_value?.unit);
            console.log("interval_time", interval_time, frequency_value);

            const new_interval_time = interval_time > 600000 ? 600000 : interval_time;
            const interval = setInterval(() => {
                const url = constants.END_POINTS.JOB_API + constants.END_POINTS.JOB_V1.POINT + constants.END_POINTS.JOB_V1.GET_JOB_STATUS + `?job_id=${job_id}`;

                axios.get(url, options).then(res => {
                    const data = res?.data?.data?.job_data;
                    const job_status = ((data?.job_occurences?.length > 0 && data?.job_occurences?.[0]?.job_status) || data?.job_running_status)
                    console.log("job_data", data, job_status)
                    if (job_status === 'completed') {
                        props.set_report_to_refresh(props.id, true);
                    }
                })

            }, new_interval_time); //set your time here. repeat every 5 seconds

            return () => clearInterval(interval);
        }
    }, []);

    useEffect(() => {

        if (dataFromServer) {

            const clone_data = dataFromServer ? JSON.parse(JSON.stringify(dataFromServer)) : {};
            let selectedData = undefined;

            if (clone_data?.pivot_data?.data?.length > 0) {
                selectedData = clone_data?.pivot_data?.data
            } else {
                selectedData = clone_data?.raw_data?.data;
            }

            let showSingleCell = props.insightChartType === 'single_cell' ? true : false;
            let singleCellData = undefined;

            if (selectedData?.length === 1 && (Object.keys(selectedData?.[0])?.length <= 2 ||
                (clone_data?.pivot_data?.pivot_data_axis?.yac?.length < 2 &&
                    clone_data?.pivot_data?.pivot_data_axis?.xac?.length < 2))) {
                // if(clone_data?.disabled_to_call_single_cell) return false;
                showSingleCell = true;
                showSingleCell = (() => {

                    // if chart is single_cell then we will not check anything we will keep it as a single_cell
                    if (props.insightChartType === 'single_cell') {
                        return true;
                    } else {
                        const unique_column_key = [];
                        if (selectedData) {
                            Object.keys(selectedData[0])?.map((v) => {
                                const n_v = v && v.split("_nf")[0];
                                if (unique_column_key.indexOf(n_v) == -1) unique_column_key.push(n_v);
                            });
                        }

                        if (selectedData?.length === 1 && Object.keys(selectedData[0])?.length <= 2) {
                            const firstItem = Object.keys(selectedData[0]);

                            if (selectedData[0][firstItem[0]] === 'No Values Found') {
                                showSingleCell = false;
                            } else {
                                showSingleCell = true;
                                singleCellData = selectedData[0];
                            }
                        }

                        // if (selectedData && selectedData.length === 1) {
                        //     const first_item = Object.keys(selectedData[0]);
                        //     if (selectedData[0][first_item[0]] && selectedData[0][first_item[0]] === 'No Values Found') {
                        //         return false;
                        //     }
                        //     if (clone_data?.raw_data?.data && clone_data?.raw_data?.data.length === 1 && Object.keys(clone_data?.raw_data?.data[0]).length > 2) {
                        //         return false;
                        //     } else if (selectedData && selectedData.length === 1 && unique_column_key.length >= 2) {
                        //         return false;
                        //     } else {
                        //         return true;
                        //     }
                        // }
                        return true;
                    }
                })();

                singleCellData = selectedData[selectedData.length - 1];
            };
            // console.log("singleCellData", showSingleCell, singleCellData, clone_data?.pivot_data?.pivot_data_axis?.yac)
            if (clone_data && clone_data.error) return;

            const chart_type = insightChartType || clone_data?.config_meta?.chart_type;
            const chart_type_to_use = chart_type || __chart_type__ || 'bar_chart'

            const pivot_axis = get_pivot_formatted_axis(clone_data?.['pivot_data']?.pivot_data_axis, clone_data?.['pivot_data']?.['pivot_columns_state']);

            const raw_axis = get_raw_data_axis(clone_data?.['raw_data']?.xac, clone_data?.['raw_data']?.yac, clone_data?.['raw_data']?.['raw_data_column_state'])
            const pivot_column_aligments = process_column_meta_and_data_type(clone_data?.['pivot_data']?.ultimate_yacs, clone_data?.['pivot_data']?.pivot_columns_state, showSingleCell)
            const raw_column_aligments = process_column_meta_and_data_type(clone_data?.['raw_data']?.ultimate_yacs, clone_data?.['raw_data']?.raw_data_column_state, showSingleCell)

            // var yac_chart_types = clone_data?.['pivot_data']?.pivot_data_axis?.yac?.map((c) => (prev_chart_type !== insightChartType ? insightChartType : c.chart_type)) || []
            const yac_chart_types = utils.get_pivot_formatted_axis_chart_types('chart_types', clone_data?.['pivot_data']?.pivot_data_axis, clone_data?.['pivot_data']?.['pivot_columns_state'], prev_chart_type?.[id], insightChartType, chart_type_to_use)
            const yac_axises = utils.get_pivot_formatted_axis_chart_types(undefined, clone_data?.['pivot_data']?.pivot_data_axis, clone_data?.['pivot_data']?.['pivot_columns_state'], prev_chart_type?.[id], insightChartType)

            const pivot_yac_info = clone_data?.['pivot_data']?.pivot_data_axis?.yac

            // console.log("yac_axises", prev_chart_type, yac_chart_types, clone_data?.['pivot_data']?.pivot_data_axis);
            prev_chart_type[id] = insightChartType;

            const temp_current_state = {
                ...currentState,
                singleCellData: singleCellData,
                showSingleCell: showSingleCell,
                yac_chart_types: yac_chart_types,//insights ? yac_chart_types : [],
                yac_axises: yac_axises,
                chartType: chart_type_to_use,
                pivot_axis: pivot_axis,
                raw_axis: raw_axis,
                pivot_column_aligments: pivot_column_aligments,
                raw_column_aligments: raw_column_aligments,
                theme_json_values: clone_data?.config_meta?.theme_json_values,
                pivot_yac_info: pivot_yac_info
            }

            // console.log("temp_current_state", temp_current_state)
            setCurrentState(temp_current_state)
            setCurrentDataFromServer(clone_data)

            if (is_print_report) {

                const type = print_type || 'table';
                const section_id_for_print = (print_type == "chart" ? "chart_section_wrapper" : "table_section_wrapper") || 'normal_view_slide_wrapper'

                setTimeout(() => {

                    generatePdf(type, 'download', 'Report', section_id_for_print, undefined, file_name + '.pdf', undefined, (temp_current_state?.raw_axis?.xac || []), (temp_current_state?.raw_axis?.yac || []), clone_data?.raw_data?.data, undefined, temp_current_state?.raw_column_aligments);

                }, 1000)
            }
        } else {
            setCurrentDataFromServer(undefined)
            setCurrentState(undefined)
        }

        return (() => {
            setCurrentDataFromServer({})
            setCurrentState({})
        })
    }, [dataFromServer, id, insightChartType])



    const [is_pivot_apply_clicked, set_is_pivot_apply_clicked] = useState(undefined);
    const [report_drill_filter_details, set_report_drill_filter_details] = useState(undefined);


    const [locationKeys, setLocationKeys] = useState([])
    const history_data = useHistory();





    useEffect(() => {
        if (downloadCommand) {

            console.log("downloadCommand", downloadCommand)
            // let's download the file automatticly
            if (downloadCommand?.subtype === "pdf") {
                // setTimeout(() => {
                //     // generatePdf(type, 'download', 'Report', section_id_for_print, undefined, file_name + '.pdf', undefined, (currentState?.pivot_axis?.xac || []), (currentState?.pivot_axis?.yac || []), clone_data?.raw_data?.data, undefined, temp_current_state?.raw_column_aligments);
                //     generatePdf(
                //         __download_mode__,
                //         'download',
                //         reportTitle,
                //         section_id_for_print,
                //         undefined,
                //         _file_name,
                //         undefined,
                //         x_keys_for_print,
                //         y_keys_for_print,
                //         data_for_print,
                //         props.header,
                //         column_formatting,
                //         filters,
                //         grand_total_for_print,
                //         undefined,
                //     )
                // }, 1000)
            }
            if (downloadCommand?.subtype === "csv") {
                // setTimeout(() => {
                //     generatePdf(type, 'download', 'Report', section_id_for_print, undefined, file_name + '.pdf', undefined, (temp_current_state?.raw_axis?.xac || []), (temp_current_state?.raw_axis?.yac || []), clone_data?.raw_data?.data, undefined, temp_current_state?.raw_column_aligments);
                // }, 1000)
            }
            if (downloadCommand?.subtype === "excel") {
                console.log("i am working beta")
                props.raiseExcelDownloadRequest((props.reportId || props.id))
                // setTimeout(() => {
                //     generatePdf(type, 'download', 'Report', section_id_for_print, undefined, file_name + '.pdf', undefined, (temp_current_state?.raw_axis?.xac || []), (temp_current_state?.raw_axis?.yac || []), clone_data?.raw_data?.data, undefined, temp_current_state?.raw_column_aligments);
                // }, 1000)
            }
        }
    }, [downloadCommand])



    useEffect(() => {
        return history_data.listen(location => {
            if (history_data.action === 'PUSH') {
                setLocationKeys([location.key])
            }
            if (history_data.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys)
                    // Handle forward event

                } else {
                    setLocationKeys((keys) => [location.key, ...keys])
                    // Handle back event
                    // commenting this code for now
                    // sk
                    // 6 May 2022
                    // props.history.goBack();
                }
            }
        })
    }, [locationKeys,])


    useEffect(() => {

        try {

            let report_type_url = getParameterByName('report_type', search)
            const parameterized_values = getParameterByName('parameterized_values', search);
            const r_d_f = getParameterByName('filter_default_values', search);
            const final_rd_f = r_d_f ? JSON.parse(r_d_f) : undefined;
            const final_parameterized_values = parameterized_values ? JSON.parse(parameterized_values) : {}
            const is_pinned_question = getParameterByName('is_pinned_question', search)


            let really_need_to_refresh = true;

            const { drill_filter_cache, link_filter, deleted_link_filter } = props;

            if (link_filter && link_filter?.[id]) {
                really_need_to_refresh = false;
            }
            if (link_filter && link_filter?.[id]?.index > deleted_link_filter?.index) {
                really_need_to_refresh = true;
            }

            const is_cache_refresh_required_forcefully = should_refresh?.is_cache_refresh_required_forcefully;


            if (should_refresh && really_need_to_refresh) {

                const render_mode = currentState && currentState.renderMode;
                const chart_type = currentState && currentState.chartType;
                const x_data_keys = (currentState && currentState.xDataKeys) || [];
                const comparisons = (currentState && currentState.comparisons) || [];

                const final_report_id_and_type = get_valid_id_and_report_type(reportId, scheduleReportId, reportItemId, (report_type_url || insight_report_type || report_type));
                const ytd_or_mtd = mtd_ytd_options_cache && mtd_ytd_options_cache[final_report_id_and_type.id];
                const drill_down_data = drill_down_data_cache && drill_down_data_cache[final_report_id_and_type.id];

                let report_filters_to_use = report_filters ? JSON.parse(JSON.stringify(report_filters)) : undefined;

                let link_filter_to_apply = undefined;

                const temp_link_filter = link_filter ? JSON.parse(JSON.stringify(link_filter)) : {};
                delete temp_link_filter[id];

                if (temp_link_filter && Object.keys(temp_link_filter).length > 0) {
                    const f_filter = {};
                    Object.keys(temp_link_filter).forEach(f => {
                        if (temp_link_filter[f] && Object.keys(temp_link_filter[f]).length > 0) {
                            Object.keys(temp_link_filter[f].filter).forEach(f1 => {
                                f_filter[f1] = temp_link_filter[f]['filter'][f1]
                            })
                        }
                    })
                    link_filter_to_apply = f_filter;
                }

                const question_pre = (final_report_id_and_type.type === "plain_english") || isPolusAIWindow ? question : undefined;
                const default_filter_values = final_rd_f ? final_rd_f : insight_default_filter_values;

                const idToUSe = isPolusAIWindow ? id : final_report_id_and_type.id;

                getReport(
                    question_pre,
                    undefined,
                    idToUSe,
                    undefined,
                    final_report_id_and_type.type,
                    chart_type,
                    render_mode,
                    report_filters_to_use,
                    link_filter_to_apply,
                    report_item_filters,
                    undefined,
                    insights,
                    (isDimension || isDiscovery),
                    props.history,
                    undefined,
                    final_parameterized_values,
                    undefined,
                    undefined,
                    opr_report_id,
                    opr_token,
                    opr_client_id,
                    {
                        drill_down_data,
                        ytd_or_mtd,
                        groups: [...x_data_keys, ...comparisons],
                    },
                    props.insight_id,
                    is_global_filters,
                    drill_filter_cache,
                    undefined,
                    default_filter_values,
                    is_cache_refresh_required_forcefully,
                    is_pinned_question,
                    props.user,
                    props.date_filter_columns,
                    is_print_report,
                    isDashboardPrintMode,
                    isPolusAIWindow,
                    why_addition_filter
                );

            }
        }
        catch (e) {

            showError(store.dispatch, e);
        }
    }, [should_refresh]);


    const { search } = props.location;

    let opr_client_id = undefined;
    let opr_report_id = undefined;
    let opr_token = undefined;
    let opr_filters = undefined;
    let opr_filter_values = {};

    opr_client_id = getParamByName(search, 'client_id');
    opr_report_id = getParamByName(search, 'report_id');
    opr_token = getParamByName(search, 'token');
    opr_filters = getParamByName(search, 'nf_filters');

    if (opr_filters) {
        const __filters__ = opr_filters.split(',');
        __filters__ && __filters__.forEach(f => {
            const searched = getParamByName(search, f.toLocaleLowerCase());
            if (searched) {
                opr_filter_values[f] = searched.split('_xnfx_');
            }
        });

    }

    const return_valid_filter = (filters, report_id, is_insight) => {
        if (is_insight) {
            return filters
        }
        if (!is_insight && filters && Object.keys(filters).length > 0 && filters['report_id'] === report_id) {
            return filters
        } else return undefined
    }

    useEffect(() => {

        const process_question = getParameterByName('process_question', search)
        let report_type = getParameterByName('report_type', search)
        const insight_id = getParameterByName('insightId', search);
        const r_d_f = getParameterByName('filter_default_values', search);
        const final_rd_f = r_d_f ? JSON.parse(r_d_f) : undefined;
        const is_pinned_question = getParameterByName('is_pinned_question', search)


        if (insight_id) {
            report_type = 'dashboard';
            setReportType(report_type);
        }

        const db_info_id_form_query = getParameterByName('db_info_id', search);
        const m_report_id = getParameterByName('report_id', search)
        const __id__ = getParameterByName('id', search)


        const main_report_id = (typeof m_report_id !== 'undefined' && m_report_id !== "undefined" && m_report_id !== null) ? m_report_id : __id__;

        const report_filters = getParameterByName('report_filters', search)
        const redirect_filters = getParameterByName('redirect_filters', search)

        const is_details_screen = getParameterByName('is_details_screen', search)
        const details_filters_from_url = getParameterByName('details_filters_from_url', search)
        const response_session_id = getParameterByName('response_session_id', search);
        const parameterized_values = getParameterByName('parameterized_values', search);
        const final_parameterized_values = parameterized_values ? JSON.parse(parameterized_values) : {}
        const db_info_ids = db_info_id_form_query ? db_info_id_form_query : db_info_id;

        let tempFilters = return_valid_filter(filters, (insights ? id : scheduleReportId), insights);
        const session_filters_temp = getFromSession(constants.SESSION_KEYS.REPORT_FILTERS);
        const session_filters_prev = session_filters_temp ? JSON.parse(session_filters_temp) : {};

        const __id__to_use_for_filter = is_global_filters && props.insight_id ? props.insight_id : insights ? id : scheduleReportId;
        let session_filter = session_filters_prev && session_filters_prev[__id__to_use_for_filter];



        // here are the redirect filters logic
        const redirect_final_filters = {};
        const __redirect_filters__ = redirect_filters !== 'undefined' ? redirect_filters && redirect_filters.length > 0 && redirect_filters.split(',').map(r => r.split('_').join(' ')) : undefined;
        __redirect_filters__ && __redirect_filters__.length > 0 && __redirect_filters__.map((r) => {
            if (r && r.length > 0) {
                const __key__ = r.split('=')[0].trim();
                const __f__ = r.split('=')[1].split('nfx').map((v) => v.trim())
                redirect_final_filters[__key__] = __f__;
            }
        })

        if (report_filters && scheduleReportId && !insights) {
            setFilterFromURL(report_filters);
        }

        if (__redirect_filters__ && __redirect_filters__.length > 0 && scheduleReportId && !insights) {
            tempFilters = redirect_final_filters
            removeItemFromSession(constants.SESSION_KEYS.REPORT_FILTERS)
        }

        if (session_filter && Object.keys(session_filter).length > 0) {
            tempFilters = session_filter
        }

        if (report_type) {
            setReportType(report_type)
        }

        if (opr_filter_values && scheduleReportId && Object.keys(opr_filter_values).length > 0) {
            tempFilters = opr_filter_values;
        }

        if (details_filters_from_url && is_details_screen) {
            tempFilters = JSON.parse(details_filters_from_url)
        }


        /// new region filter code here
        const session_filters = JSON.parse((getFromSession(constants.SESSION_KEYS.REPORT_FILTERS) || "{}")); // This is a Session Filters 

        // console.log("session_filters", session_filters, props.insight_id)
        const validation_keys_cache = JSON.parse((getFromSession(constants.SESSION_KEYS.VALIDATION_KEYS) || "{}"));
        const report_filter_validation_key = validation_keys_cache?.[props.insight_id]
        const report_item_filter_validation_key = validation_keys_cache?.[id]

        const pre_report_session_filters = session_filters?.[props.insight_id]; //Report Filters
        const pre_report_item_session_filtes = session_filters?.[props.id] || [];

        let report_item_filters_to_use = get_valid_filter_for_submition(pre_report_item_session_filtes, props.report_filter_info)
        let report_filters_to_use = get_valid_filter_for_submition(pre_report_session_filters, props.global_filter_keys)

        // let report_filters_to_use1 = get_valid_filter_for_submition(pre_report_session_filters, props.global_filter_keys, 'report')

        // this block is for report filter
        // if (report_filter_validation_key && props.insight_id && props.report_validation_keys?.['filter_key']) {
        //     const v_key = props.report_validation_keys?.['filter_key'];
        //     if (report_filter_validation_key === v_key) {
        //         //do noting
        //     }
        //     else {
        //         // then assign the mt filter and clear the session for this report
        //         report_filters_to_use = undefined
        //         save_filters_in_session({}, props.insight_id)
        //         props.apply_filters(props.insight_id, {})
        //     }

        // }


        const report_item_filter_key = props.report_item_validation_keys?.['filter_key'];
        /// this block is for report_item filter
        // if (report_item_filter_validation_key && id && report_item_filter_key) {
        //     const v_key = report_item_filter_key;
        //     if (report_item_filter_validation_key === v_key) {
        //         //do noting
        //     }
        //     else {
        //         // then assign the mt filter and clear the session for this report
        //         report_item_filters_to_use = undefined
        //         save_filters_in_session({}, id)
        //         props.apply_filters(id, {})

        //     }
        // }

        setMainReportId(main_report_id);

        /**
         * if we have isMap = true
         * then we will clear all the filter for that report cuz we are not using session filter for map
         * 25 march 2022 
         * as vikash sir said
         */
        if (props.isMap) {
            report_filters_to_use = undefined;
            report_item_filters_to_use = undefined
        }

        if (insightRenderMode === RENDER_MODE.map) {
            return;
        }


        if ((!dataFromServer || process_question) && !IsDataAvailable) {
            const renderMode = currentState?.renderMode;

            const chartType = getParameterByName('chartType', search) || currentState?.chartType

            const final_report_id_and_type = get_valid_id_and_report_type(reportId, scheduleReportId, reportItemId, (insight_report_type || report_type))

            const question_pre = (final_report_id_and_type.type === "plain_english") ? question : undefined;

            const default_filter_values = final_rd_f ? final_rd_f : insight_default_filter_values

            let report_filter_v1 = report_filters_to_use ? JSON.parse(JSON.stringify(report_filters_to_use)) : {};

            // let's filter the session filter and remove if filter has a custom_config_date
            if (report_filters_to_use?.['date_filter']?.['generic_date']?.['type'] === "custom_date_config") {
                delete report_filter_v1['date_filter']["generic_date"];
            }



            // console.log("report_filter_v1", report_item_filters_to_use, report_filter_v1)
            getReport(
                question_pre,
                undefined,
                final_report_id_and_type.id,
                response_session_id,
                final_report_id_and_type.type,
                chartType,
                renderMode,
                report_filter_v1,
                undefined,
                report_item_filters_to_use,
                db_info_ids,
                insights,
                (isDimension || isDiscovery),
                props.history,
                undefined,
                final_parameterized_values,
                props.isMap,
                is_details_screen,
                opr_report_id,
                opr_token,
                opr_client_id,
                undefined,
                insight_id,
                is_global_filters,
                undefined,
                report_item_filter_key,
                default_filter_values,
                undefined,
                is_pinned_question,
                props.user,
                props.date_filter_columns,
                is_print_report,
                isDashboardPrintMode,
                undefined,
                why_addition_filter
            );
        }

    }, [id, question]);

    const prevMapFilter = usePrevious({ mapFilter });

    // this is only fired when we have map and mapFilter will changes 

    useEffect(() => {
        let map_question = undefined;
        let tempFilter = {};
        let report_filters_to_use = report_filters ? JSON.parse(JSON.stringify(report_filters)) : undefined;

        if (insightChartType != 'map' && mapFilter) {
            const k = Object.keys(mapFilter)[0];
            map_question = k + ' is ' + mapFilter[k];

            getReport(map_question, undefined, id, undefined, "dashboard", chartType, renderMode, report_filters_to_use, undefined, report_item_filters, undefined, insights, undefined, props.history,);

            setForceRefresh(true);
        }
        if (!mapFilter && prevMapFilter && insightChartType != 'map') {
            getReport(undefined, undefined, id, undefined, "dashboard", chartType, renderMode, report_filters_to_use, undefined, report_item_filters, undefined, insights, undefined, props.history, undefined, undefined, true);
            setForceRefresh(true);
        }

    }, [mapFilter]);




    // useEffect(() => {
    //     let current = refLeftSection.current;
    //     if (current) {
    //         let offsetWidth = current.offsetWidth;
    //         setLefterWidth(offsetWidth)
    //     }
    // }, [menu_render_mode])



    /****
     * this useEffect
     */

    useEffect(() => {
        if (props.dataFromServer) {
            if (props.dataFromServer && props.dataFromServer.report_type === "entity_questions") {
                if (dataFromServer && dataFromServer.questions && dataFromServer.questions.length > 0) {
                    setKpiReport(dataFromServer.questions)
                }
            }
            else if (props.dataFromServer && props.dataFromServer.is_mathematical_keywords_exist) {
                showPopup('Formula', '', enums.default.popupType.element, FormulaCreator, { data: props.dataFromServer, hideRefresh: false })
            }
            else {
                setKpiReport(undefined)
            }
        }
    }, [props.dataFromServer])



    // useEffect(() => {
    //     if (typeof dataFromServer === 'undefined') {

    //         return;
    //     }
    //     else {

    //     }


    // }, [dataFromServer, id, localRunningTotal]);



    useEffect(() => {

        hideLastPopup()

        if (props.location && !isDimension && !forceRenderOneTime && !isPolusAIWindow) {
            const { search } = props.location;
            const tempChartType = getParamByName(search, 'chartType');
            // const isPrintMode = getParamByName(search, 'print_mode');

            // if (isPrintMode) {
            //     const file_name_from_url = getParamByName(search, 'file');
            //     const is_client_view_report = props.location.pathname.indexOf('client-view-report');
            //     if (is_client_view_report > -1) {
            //         setTimeout(async () => {
            //             console.log("currentState", currentDataFromServer)
            //             generatePdf('chart', 'download', 'testing', 'chart_section_wrapper', undefined, file_name_from_url + '.pdf', undefined, xDataKeys, yDataKeys,);
            //         }, 10000);
            //     }
            // }

            if (tempChartType) {
                console.log("now i am here", tempChartType)
                if (tempChartType !== currentState?.chartType) {
                    setCurrentState({
                        ...currentState,
                        chartType: tempChartType,
                        renderMode: tempChartType === 'table' ? 'table' : (tempChartType === 'map' ? "map" : 'chart')
                    })
                }
            }
            else if (currentState?.reportId === props?.id) {
                let tempChartType = undefined;

                console.log("yaha hu")

                if (dataFromServer && dataFromServer.config_meta) {
                    const config = dataFromServer.config_meta;

                    tempChartType = config.chart_type;
                }
                else {
                    tempChartType = currentState?.chartType;
                }
                setCurrentState({
                    ...currentState,
                    chartType: tempChartType,
                    renderMode: tempChartType === 'table' ? 'table' : (tempChartType === "map" ? "map" : "chart")
                })
            }
        }

        console.log("ye wala block bhi init")

    }, [props.location])


    const dispatch_values_for_pivot = (columns_info, chart_type) => {

        const report_item_id_ = props.report_item_id ? props.report_item_id : id;
        const clonned_columns_info = columns_info ? JSON.parse(JSON.stringify(columns_info)) : [];
        const pivot_x = clonned_columns_info.filter((c) => c.fe_pivot_type === PIVOT_COLUMN_TYPE.PIVOT_X).sort((a, b) => a.pivot_field_order - b.pivot_field_order);
        const pivot_y = clonned_columns_info.filter((c) => c.fe_pivot_type === PIVOT_COLUMN_TYPE.PIVOT_Y).sort((a, b) => a.pivot_field_order - b.pivot_field_order);
        const pivot_comparision = clonned_columns_info.filter((c) => c.fe_pivot_type === PIVOT_COLUMN_TYPE.PIVOT_COMPARISON).sort((a, b) => a.pivot_field_order - b.pivot_field_order);
        const column_local_column_infos = columns_info ? JSON.parse(JSON.stringify(columns_info)) : [];

        // console.log("column_local_column_infos", column_local_column_infos)
        props.dispatch_pivot_data(id, report_item_id_, pivot_x, pivot_y, pivot_comparision, column_local_column_infos, false, props.be_file_path, chart_type);
    };




    const change_column_info_value = (columns_info, property, chart_type) => {

        // Clone the columns_info array
        const clone_clone_info = columns_info ? [...columns_info] : [];

        // Iterate through the cloned array and modify pivot y axis value
        clone_clone_info.map((c) => {
            if (c.fe_pivot_type === PIVOT_COLUMN_TYPE.PIVOT_Y) {
                // Check if property is provided and not empty
                if (property && Object.keys(property)?.length > 0) {
                    // Update the properties of the column
                    Object.keys(property).map((key) => {
                        c[key] = property[key];
                    })
                }
            }
        });

        // Dispatch values for pivot
        dispatch_values_for_pivot(clone_clone_info, chart_type);
    }


    const changeChartType = type => {

        // If type is "ogive_chart", change pivot_aggregation and cumulative_added properties
        if (type === "ogive_chart") {
            change_column_info_value(props.columns_info, {
                pivot_aggregation: 'cumulative',
                cumulative_added: true,
            }, type);
        } else {
            const y_axis = (props.columns_info || [])?.filter((f) => f.fe_pivot_type === PIVOT_COLUMN_TYPE.PIVOT_Y) || [];

            const is_all_y_axis_cumulative = y_axis?.length > 0 && y_axis?.every((c) => c.pivot_aggregation === "cumulative" && c.cumulative_added);

            if (is_all_y_axis_cumulative || type==='gauge_chart') {
                change_column_info_value(props.columns_info, {
                    pivot_aggregation: 'sum',
                    cumulative_added: false,
                }, type);
            }
        }

        const tempHistory = Object.assign({}, props.history);

        const yac_chart_types = currentState?.pivot_axis?.yac?.map((c) => {
            if (c === "Percentage Change") {
                return 'line_chart'
            } else {
                return (type == "ogive_chart" ? 'line_chart' : type)
            }
        })

        if (type !== 'table') {
            setLocalForceType(undefined)
        }


        const { location } = tempHistory
        const { search } = location;

        if (!currentState?.showSingleCell) {
            let string = search;

            if ((search.match(/&chartType/g)) || (search.match(/chartType/g))) {
                string = updateUrlParameter(string, 'chartType', type);
                string = updateUrlParameter(string, 'renderMode', type === 'table' ? 'table' : 'chart');
            }

            else {
                string = string + `${search ? '&' : '?'}chartType=${type}&renderMode=${type === 'table' ? 'table' : 'chart'}`;
            }


            if (!isPolusAIWindow) {
                props.history.push(string);

            }
            setCurrentState({
                ...currentState,
                chartType: type,
                renderMode: type === 'table' ? 'table' : (type === "map" ? "map" : 'chart'),
                yac_chart_types: yac_chart_types
            })

        };
    };


    // this is used for voice action command to chang ethe chart type using voice command

    useEffect(() => {
        if (chart_type_command?.type) {
            changeChartType(chart_type_command?.type)
        }
    }, [chart_type_command]);

    useEffect(() => {
        if (props.other_voice_cmd?.type) {
            const v = props.other_voice_cmd?.type === "show" ? true : false;
            setCurrentState({
                ...currentState,
                show_data_label: v
            })
        }
    }, [props.other_voice_cmd])



    const { sortedData, renderMode, chartType, yDataKeys, xDataKeys, dbType, sqlQuery, showSingleCell, singleCellData, parentReport, csvHeader, trendFilters, error } = (currentState || {});
    const numberOfData = sortedData && sortedData.length;

    const deleteReport = () => {
        if (parentReport && scheduleReportId && parentReport.length > 0 && scheduleReportId.length > 0) {
            const reportId = parentReport;

            const tempObj = {};
            const rptItemObj = {};
            const rptItemArray = [];

            tempObj.id = reportId;
            tempObj.deleted = true;

            rptItemObj.id = scheduleReportId;
            rptItemObj.deleted = true;

            rptItemArray.push(rptItemObj);

            tempObj.report_items = [...rptItemArray];

            props.history.push('/');
            saveReport(reportFormatToSave(tempObj));
        }
    };

    const editReport = (isDuplicate) => {
        const reportId = props.parentReport;

        if (!isDuplicate && props.history && reportId && reportId.length > 0) {
            props.history.push(`/report_editor?report_id=${reportId}`);
        }
        else if (isDuplicate && props.history && reportId && reportId.length > 0) {
            props.history.push(`/report_editor?report_id=${reportId}&duplicate=true`)
        }
    };

    let temp_dimensions = trendFilters && trendFilters.dimensions && trendFilters.dimensions.length > 0 ? trendFilters.dimensions : undefined;

    useEffect(() => {
        setDimensions(temp_dimensions)
    }, [temp_dimensions])



    // we don't need trend filters
    const isTrendFilters = trendFilters && Object.keys(trendFilters).length > 0 && trendFilters.first_filter_values && trendFilters.first_filter_values.length > 0 ? true : false;
    const widthOfChart = insightWidth ? insightWidth : ((isDimension || isDiscovery) ? width : width - 170);
    const tempHeightOfChart = (insightHeight ? insightHeight - 0 : (isDimension || isDiscovery) ? height : (height ? (height - 80) : undefined));
    const heightOfChart = tempHeightOfChart - (filters && !insights && Object.keys(filters).length > 0 ? 30 : 0);
    const restricted_view = report_type === "dashboard" && view_mode === "collapsed";
    // const restricted_view = report_type === "dashboard";


    // this is new regin code 
    const right_menu_width_in_px_pre = getRemToPx(3.7);
    // const filter_height = !restricted_view && filters && Object.keys(filters).length > 0  ? 50 : 0;
    const bread_crumb_height = !restricted_view && props.drill_down_data && props.drill_down_data.length > 0 ? 30 : 0;

    const title_height = reportTitle && insights && !restricted_view ? 45 : 0;

    const other_component_total_height = title_height;

    const right_menu_width_in_px_post = right_menu_width_in_px_pre + 250;
    // const right_menu_total_width_all_state = menu_render_mode ? right_menu_width_in_px_post : right_menu_width_in_px_pre;
    const rpt_inital_width = (insightWidth ? insightWidth : width);

    const is_details_window = dataFromServer?.is_details;

    const report_section_width = restricted_view ? rpt_inital_width : rpt_inital_width - ((!props.toggleHeader) ? 70 : 0)
    const report_section_height = ((insightHeight ? insightHeight - 0 : (props.toggleHeader) ? height : height - 60)) - other_component_total_height;


    /**
     * this is a patch of insight expanded view 
     */


    let __render_mode__ = insightRenderMode || renderMode;
    let __chart_type__ = insightChartType || currentState?.chartType || chartType


    if (!restricted_view && insights) {
        if (__render_mode__ === "table") {
            __render_mode__ = "chart";
            __chart_type__ = "bar_chart";
        }
    }


    /***
     * when user will click on apply pivot data then we will change the 
     * chart_type 
     * render_mode
     * table to pivot_data 
     */
    const change_report_setting_on_apply_pivot = () => {
        setCurrentState({
            ...currentState,
            chartType: "bar_chart",
            renderMode: "chart"
        })
        props.set_table_data_mode("pivot_data", id)
        set_is_pivot_apply_clicked(true)
    }

    useEffect(() => {
        if (!insights) {
            let _page_info = {
                title: undefined,
            }
            if (props.dispatchHeaderInformation) {
                props.dispatchHeaderInformation(_page_info)
            }
        }
    }, [id])


    const theme_json_values = props.theme_json_values || currentState?.theme_json_values

    const show_error_popup = () => {
        showPopup('Error', props?.dashboard_error)
    }




    const is_cache_required = dataFromServer?.is_cache_required;

    // const fe_req_time = dataFromServer?.fe_req_time && get_time_format_v1(dataFromServer?.fe_req_time);
    // const fe_response_time = dataFromServer?.fe_response_time && get_time_format_v1(dataFromServer?.fe_response_time);
    // const java_request_time = dataFromServer?.java_request_time && get_time_format_v1(dataFromServer?.java_request_time);
    // const java_response_time = dataFromServer?.java_response_time && get_time_format_v1(dataFromServer?.java_response_time);
    // const current_report_time = get_time_format_v1(new Date());

    if (!fe_render_times[id] && dataFromServer) {
        fe_render_times[id] = new Date();
    }

    const fe_render_time = fe_render_times[id];

    // let dashboard_total_time_taken = undefined;

    // fe_render_time && Object.keys(fe_render_time).forEach(key => {
    //     dashboard_total_time_taken = 
    // })

    // const pivot_int_time = dataFromServer?.pivot_temp_info?.pivot_int_time && get_time_format_v1(dataFromServer?.pivot_temp_info?.pivot_int_time);
    // const pivot_res_time = dataFromServer?.pivot_temp_info?.pivot_res_time && get_time_format_v1(dataFromServer?.pivot_temp_info?.pivot_res_time);



    const fm_d = (date) => {
        if (date) {
            const d = new Date(date);
            let text = d.toString();
            return text;
        }

    }


    const node_req_time = dataFromServer?.node_req && get_time_format_v1(dataFromServer?.node_req);
    const node_res_time = dataFromServer?.node_res && get_time_format_v1(dataFromServer?.node_res);

    const fe_res_time1 = dataFromServer?.fe_response_time && get_time_format_v1(dataFromServer?.fe_response_time);
    const fe_req_time1 = dataFromServer?.fe_req_time && get_time_format_v1(dataFromServer?.fe_req_time);


    const fe_render_req = dataFromServer?.fe_req_time && get_time_format_v1(dataFromServer?.fe_req_time);
    const fe_render_res = fe_render_time && get_time_format_v1(fe_render_time);



    const fun_1_req = dataFromServer?.fun_1_req && get_time_format_v1(dataFromServer?.fun_1_req);
    const fun_1_res = dataFromServer?.fun_1_res && get_time_format_v1(dataFromServer?.fun_1_res);


    const fun_4_req = dataFromServer?.fun_4_req && get_time_format_v1(dataFromServer?.fun_4_req);
    const fun_4_res = dataFromServer?.fun_4_res && get_time_format_v1(dataFromServer?.fun_4_res);


    const fe_req_time_new = dataFromServer?.fe_req_time && get_time_format_v1(dataFromServer?.fe_req_time);
    const fe_res_time_new = dataFromServer?.fe_response_time && get_time_format_v1(dataFromServer?.fe_response_time);
    const node_req_time_new = dataFromServer?.node_req && get_time_format_v1(dataFromServer?.node_req);
    const node_res_time_new = dataFromServer?.node_res && get_time_format_v1(dataFromServer?.node_res);
    const java_request_time_new = dataFromServer?.java_request_time && get_time_format_v1(dataFromServer?.java_request_time);
    const java_response_time_new = dataFromServer?.java_response_time && get_time_format_v1(dataFromServer?.java_response_time);
    const db_req_time_new = dataFromServer?.fun_4_req && get_time_format_v1(dataFromServer?.fun_4_req);
    const db_res_time_new = dataFromServer?.fun_4_res && get_time_format_v1(dataFromServer?.fun_4_res);





    const StatsElement = () => {
        return (
            <div style={{
                width: '500px',
                backgroundColor: '#fff'
            }}>

                {dataFromServer && (
                    <div>
                        =============
                        id: - {dataFromServer?.config_meta?.id}
                        <br></br>
                        HH-MM-SS-MS
                        {
                            fe_req_time_new && (
                                <p>
                                    Submission time from Front END:<strong>
                                        {dataFromServer?.fe_req_time && <span>{fm_d(dataFromServer?.fe_req_time)}</span>}
                                        ---<br></br>
                                        {fe_req_time_new?.hour} : {fe_req_time_new?.minutes} : {fe_req_time_new?.seconds} : {fe_req_time_new?.milli_seconds}
                                    </strong>

                                </p>
                            )
                        }

                        {node_req_time_new && (
                            <p>
                                NodeJS Req Received Time:<strong>
                                    {dataFromServer?.node_req && <span>{fm_d(dataFromServer?.node_req)}</span>}  ---<br></br>
                                    {node_req_time_new?.hour} : {node_req_time_new?.minutes} : {node_req_time_new?.seconds} : {node_req_time_new?.milli_seconds}
                                </strong>
                            </p>
                        )}



                        {java_response_time_new && (
                            <p>
                                Nodejs Java Request Sent Time<strong>
                                    {dataFromServer?.java_request_time && <span> {fm_d(dataFromServer?.java_request_time)}</span>} ----<br></br>
                                    {java_request_time_new?.hour} : {java_request_time_new?.minutes} : {java_request_time_new?.seconds} : {java_request_time_new?.milli_seconds}
                                </strong>
                            </p>
                        )}


                        {java_response_time_new && (
                            <p>
                                NodeJS Java Response Received Time<strong>
                                    {dataFromServer?.java_response_time && <span> {fm_d(dataFromServer?.java_response_time)}</span>}   ----<br></br>
                                    {java_response_time_new?.hour} :  {java_response_time_new?.minutes} : {java_response_time_new?.seconds} : {java_response_time_new?.milli_seconds}
                                </strong>
                            </p>
                        )}



                        {db_req_time_new && (
                            <p>
                                NodeJS DB Request Submission Time<strong>
                                    {dataFromServer?.fun_4_req && <span> {fm_d(dataFromServer?.fun_4_req)}</span>}  --- <br></br>
                                    {db_req_time_new?.hour} : {db_req_time_new?.minutes} : {db_req_time_new?.seconds} : {db_req_time_new?.milli_seconds}
                                </strong>
                            </p>
                        )}

                        {db_res_time_new && (
                            <p>
                                NodeJS DB Data Received Time<strong>
                                    {dataFromServer?.fun_4_res && <span> {fm_d(dataFromServer?.fun_4_res)}</span>}  --- <br></br>
                                    {db_res_time_new?.hour}  : {db_res_time_new?.minutes} : {db_res_time_new?.seconds} : {db_res_time_new?.milli_seconds}
                                </strong>
                            </p>
                        )}



                        {node_res_time_new && (
                            <p>
                                NodeJS Response Sent Time<strong>
                                    {dataFromServer?.node_res && <span>{fm_d(dataFromServer?.node_res)}</span>} --<br></br>
                                    {node_res_time_new?.hour} :   {node_res_time_new?.minutes} : {node_res_time_new?.seconds} : {node_res_time_new?.milli_seconds}
                                </strong>
                            </p>

                        )}


                        {fe_res_time_new && (
                            <p>
                                {dataFromServer?.fe_response_time && <span> {fm_d(dataFromServer?.fe_response_time)}</span>}   --<br></br>
                                Front End Response Received Time<strong>
                                    {fe_res_time_new?.hour} :  {fe_res_time_new?.minutes} : {fe_res_time_new?.seconds} : {fe_res_time_new?.milli_seconds}
                                </strong>
                            </p>
                        )}

                    </div>
                )
                }


                {/* 

                <p>
                    Step 1 : = Fe Req To Fe Render
                    <strong> {new Date(fe_render_time) - new Date(dataFromServer?.fe_req_time)}</strong>
                </p>

                <p>
                    Step 2 : = Fe Response To Fe Render
                    <strong> {new Date(fe_render_time) - new Date(dataFromServer?.fe_response_time)}</strong>

                    <br></br>
                    <strong>
                        {fe_render_req?.minutes} : {fe_render_req?.seconds} : {fe_render_req?.milli_seconds}
                    </strong> <br></br>
                    <strong>
                        {fe_render_res?.minutes} : {fe_render_res?.seconds} : {fe_render_res?.milli_seconds}
                    </strong>
                </p>

                <p>
                    Step 3 : = Fe Req To Fe Response
                    <strong> {new Date(dataFromServer?.fe_response_time) - new Date(dataFromServer?.fe_req_time)}</strong>
                    <br></br>
                    <strong>
                        {fe_req_time1?.minutes} : {fe_req_time1?.seconds} : {fe_req_time1?.milli_seconds}
                    </strong> <br></br>
                    <strong>
                        {fe_res_time1?.minutes} : {fe_res_time1?.seconds} : {fe_res_time1?.milli_seconds}
                    </strong>
                </p>

                <p>
                    Step 4 : = Node Req To Node Res
                    <strong> {new Date(dataFromServer?.node_res) - new Date(dataFromServer?.node_req)}</strong>
                    <br></br>
                    <strong>
                        {node_req_time?.minutes} : {node_req_time?.seconds} : {node_req_time?.milli_seconds}
                    </strong> <br></br>
                    <strong>
                        {node_res_time?.minutes} : {node_res_time?.seconds} : {node_res_time?.milli_seconds}
                    </strong>
                </p>

                <p>
                    Step 5 : = process_question_and_get_report_data [NODE]
                    <strong> {new Date(dataFromServer?.fun_1_res) - new Date(dataFromServer?.fun_1_req)}</strong>

                    <br></br>
                    <strong>
                        {fun_1_req?.minutes} : {fun_1_req?.seconds} : {fun_1_req?.milli_seconds}
                    </strong> <br></br>
                    <strong>
                        {fun_1_res?.minutes} : {fun_1_res?.seconds} : {fun_1_res?.milli_seconds}
                    </strong>
                </p>

                <p>
                    Step 6 : = process_the_query [NODE]
                    <strong> {new Date(dataFromServer?.fun_2_res) - new Date(dataFromServer?.fun_2_req)}</strong>
                </p>

                <p>
                    Step 7 : = get_report_data [NODE]
                    <strong> {new Date(dataFromServer?.fun_3_res) - new Date(dataFromServer?.fun_3_req)}</strong>
                    
                </p>

                <p>
                    Step 8 : = data from DB by query [NODE]
                    <strong> {new Date(dataFromServer?.fun_4_res) - new Date(dataFromServer?.fun_4_req)}</strong>

                    <br></br>
                    <strong>
                        {fun_4_req?.minutes} : {fun_4_req?.seconds} : {fun_4_req?.milli_seconds}
                    </strong> <br></br>
                    <strong>
                        {fun_4_res?.minutes} : {fun_4_res?.seconds} : {fun_4_res?.milli_seconds}
                    </strong>
                </p>
                <p>
                    Step 9 : = Java Req To Java Res
                    <strong> {new Date(dataFromServer?.java_response_time) - new Date(dataFromServer?.java_request_time)}</strong>
                </p>

                <p>
                    Step 10 : = AUTH TIME TAKEN
                    <strong> {dataFromServer?.auth_time}</strong>
                </p> */}
            </div >
        )
    }


    const show_stats_popup = () => {
        showPopup("Report Statistics data", undefined, enums.default.popupType.element_with_header, StatsElement)
    }

    console_logger("REPORT WRAPPER INIT")

    const filter_not_applied = currentDataFromServer?.filter_not_applied || [];
    const filter_applied = currentDataFromServer?.filter_applied || [];
    const is_drill_down_applicable = true //currentDataFromServer?.is_drill_down_applicable; 


    const config_title = currentDataFromServer?.config_meta?.title;
    const generic_date_filter = filters?.['date_filter']?.['generic_date'];
    const be_title = forcedTitle ? forcedTitle : scheduleReportId ? config_title : 'Polus Report';
    const title_to_use = be_title //create_report_nf_date_title(generic_date_filter, be_title)


    return (
        <React.Fragment>
            {props.dashboard_error && (
                <div style={{ position: 'absolute', zIndex: 10, right: '70px', top: '-3px' }}>
                    <RippleEffect
                        icon={<WarningSvg size=".9rem" height=".9rem" color='red' />}
                        Click={(e) => {
                            show_error_popup()
                        }}
                        title='Error'

                    />
                </div>
            )}

            {user?.is_developer && (
                <button style={{ position: 'absolute', zIndex: 999 }} onClick={() => {
                    show_stats_popup()
                }}>ST</button>
            )}

            {props.dashboard_error && (
                <div style={{ width: '100%', height: '90%', zIndex: insights ? 16 : 3, background: 'rgb(255 255 255 / 66%)', position: 'absolute', bottom: '0px' }}>
                    <div style={{ width: '100%', height: '100%', zIndex: 16, background: 'repeating-linear-gradient(145deg, #EAEAEA, transparent 1%, transparent 1%)', position: 'absolute' }}></div>
                </div>
            )}

            {isKpiReport && (
                <KipComponent currentDataFromServer={currentDataFromServer} data={isKpiReport} />
            )}

            {!props.report_loader && !loading && insightRenderMode === RENDER_MODE.map && insightChartType === CHART_TYPES.map ? (
                <Map
                    widthOfChart={report_section_width}
                    heightOfChart={report_section_height - 70}
                    reportTitle={reportTitle}
                    predefinedLevel={question}
                    click_onExpandMap={click_onExpandMap}
                    expanded={props.expanded}
                    marker_type={props.marker_type}
                    apply_filter_on_all={props.apply_filter_on_all_report_items}
                    change_map_data={props.change_map_data}
                />
            ) : (
                <React.Fragment>

                    <React.Fragment>
                        <Wrapper
                            ref={topRef}
                            style={{
                                height: isMobile ? undefined : (insights || isDimension || isDiscovery) && heightOfChart + "px",
                                background: restricted_view ? undefined : '#fffeff',
                                height: report_section_height + "px",
                                border: (!isDiscovery && !insights && !isPolusAIWindow) ? '1px solid #ecebed' : '0px',
                            }}
                        >
                            {
                                !props.report_loader && !loading && currentDataFromServer ?
                                    <React.Fragment>
                                        <LeftSection id="report_wrapper"
                                            ref={refLeftSection} style={{ width: report_section_width + "px", }} >
                                            <ViewWrapper
                                                is_details_window={is_details_window}
                                                is_cache_required={is_cache_required}
                                                column_meta_data={currentDataFromServer?.column_meta_data}
                                                is_global_filters={is_global_filters}
                                                report_section_width={report_section_width}
                                                report_section_height={report_section_height}
                                                tableDataMode={tableDataMode}
                                                {...props}
                                                report_drill_filter_details={report_drill_filter_details}
                                                insightRenderMode={insightRenderMode}
                                                insightChartType={insightChartType}
                                                chartType={currentDataFromServer?.config_meta?.chart_type_force || __chart_type__ || "bar_chart"}
                                                renderMode={currentDataFromServer?.config_meta?.render_mode_force || __render_mode__ || "chart"}
                                                set_table_data_mode={(v) => {
                                                    set_is_pivot_apply_clicked(false)
                                                    props.set_table_data_mode(v, id)
                                                }}
                                                changeChartType={changeChartType}
                                                ultimate_yacs={ultimate_yacs}
                                                restricted_view={restricted_view}
                                                dimensions={!childDimension ? dimensions : undefined}
                                                childDimension={childDimension}
                                                isPolusIqHide={isPolusIqHide}
                                                title={title_to_use}
                                                deleteAction={deleteReport}
                                                editAction={() => editReport(false)}
                                                duplicateAction={() => editReport(true)}
                                                showEdit={false}
                                                showDuplicate={false}
                                                showDelete={false}
                                                showRanking={props.isRanking}
                                                columnsAligments={columnsAligments}
                                                grandTotalColumns={grandTotalColumns}
                                                isDetails={isDetails}
                                                mainReportId={mainReportId}
                                                filterFromURL={filterFromURL}
                                                reportType={reportType}
                                                LefterWidth={LefterWidth}
                                                is_pivot_apply_clicked={is_pivot_apply_clicked}
                                                theme_json_values={theme_json_values}
                                                isPredictive={props.isPredictive}
                                                menu_render_mode={menu_render_mode}
                                                disable_options={props.disable_options}
                                                filter_not_applied={filter_not_applied}
                                                filter_applied={filter_applied}
                                                is_drill_down_applicable={is_drill_down_applicable}
                                                // new region code for pivot node shifted
                                                is_correlation={currentDataFromServer?.is_correlation}
                                                correlation_data={currentDataFromServer?.correlation_data}

                                                row_data_information={currentDataFromServer?.['raw_data']}
                                                pivot_data_information={currentDataFromServer?.['pivot_data']}
                                                yac_chart_types={currentState?.yac_chart_types}
                                                yac_axises={currentState?.yac_axises}
                                                pivot_data_grand_total={{
                                                    "grand_total_sum_column": currentDataFromServer?.['pivot_data']?.['sum_data']
                                                }}
                                                raw_data_grand_total={{
                                                    "grand_total_sum_column": currentDataFromServer?.['raw_data']?.['sum_data']
                                                }}
                                                pivot_data_ultimate_yacs={currentDataFromServer?.['pivot_data']?.['ultimate_yacs']}
                                                pivot_data={currentDataFromServer?.['pivot_data']?.['data']}
                                                raw_data={currentDataFromServer?.['raw_data']?.['data']}
                                                yac_totals={currentDataFromServer?.['pivot_data']?.['yac_totals']}
                                                columnMeta={currentDataFromServer?.column_meta_data}
                                                report_item={currentDataFromServer?.['config_meta']}
                                                sqlQuery={currentDataFromServer?.['sql_query']}
                                                responseId={currentDataFromServer?.response_session_id}
                                                pivot_axis={currentState?.pivot_axis}
                                                raw_axis={currentState?.raw_axis}
                                                reportTitle={forcedTitle ? forcedTitle : scheduleReportId ? (currentState && currentState.title && currentState.title.trim().length > 0 ? currentState.title : title) : createTitleFromDataKeys(currentDataFromServer?.['config_meta']?.xac, currentDataFromServer?.['config_meta']?.yac) || 'Polus - Next Gen BI'}
                                                show_data_label={currentState.show_data_label || props.show_data_label}
                                                pivot_column_aligments={currentState?.pivot_column_aligments}
                                                raw_column_aligments={currentState?.raw_column_aligments}
                                                showSingleCell={currentState?.showSingleCell}
                                                singleCellData={currentState?.singleCellData}
                                                pivot_yac_info={currentState?.pivot_yac_info}
                                                isHighlightedMin={isHighlightedMin}
                                            />
                                        </LeftSection>

                                        {!restricted_view && !isPredictive && !isDiscovery && !hideChartOption && !props.toggleHeader && (
                                            <RighterMenuSection
                                                isPolusAIWindow={isPolusAIWindow}
                                                isDeepDrive={props?.isDeepDrive}
                                                hints={props?.hints}
                                                is_correlation={currentDataFromServer?.is_correlation}
                                                {...currentState}
                                                is_details_window={is_details_window}
                                                {...props}
                                                is_global_filters={is_global_filters}
                                                id={id}
                                                insights={insights}
                                                restricted_view={restricted_view}
                                                scheduleReportId={scheduleReportId}
                                                report_item_id={currentDataFromServer?.['config_meta']?.id}
                                                changeChartType={changeChartType}
                                                chartType={chartType}
                                                renderMode={renderMode}
                                                report_type={report_type}
                                                ultimate_yacs={ultimate_yacs}
                                                isTrendFilters={isTrendFilters}
                                                showSingleCell={showSingleCell}
                                                csvHeader={csvHeader}
                                                currentDataFromServer={currentDataFromServer}
                                                user={user}
                                                xDataKeys={xDataKeys}
                                                yDataKeys={yDataKeys}
                                                heightOfChart={report_section_height}
                                                numberOfData={currentDataFromServer?.['raw_data']?.data?.length}
                                                history={props.history}
                                                reportTitle={be_title || 'Polus - Next Gen BI'}
                                                mainReportId={mainReportId}
                                                columnsAligments={columnsAligments}
                                                grandTotalColumns={grandTotalColumns}
                                                filterFromURL={filterFromURL}
                                                localRunningTotal={localRunningTotal}
                                                setLocalRunningTotal={(v) => {
                                                    setLocalRunningTotal(v);
                                                    setForceRefresh(true)
                                                }}
                                                pivot_axis={currentState?.pivot_axis}
                                                raw_axis={currentState?.raw_axis}
                                                pivot_data_axis={currentDataFromServer?.['pivot_data']?.['pivot_data_axis']}
                                                yac_totals={currentDataFromServer?.['pivot_data']?.['yac_totals']}
                                                pivot_data={currentDataFromServer?.['pivot_data']?.['data']}
                                                raw_data={currentDataFromServer?.['raw_data']?.['data']}
                                                change_report_setting_on_apply_pivot={change_report_setting_on_apply_pivot}
                                                pivot_column_aligments={currentState?.pivot_column_aligments}
                                                raw_column_aligments={currentState?.raw_column_aligments}
                                                pivot_data_grand_total={{
                                                    "grand_total_sum_column": currentDataFromServer?.['pivot_data']?.['sum_data']
                                                }}
                                                raw_data_grand_total={{
                                                    "grand_total_sum_column": currentDataFromServer?.['raw_data']?.['sum_data']
                                                }}
                                            />
                                        )}
                                    </React.Fragment>

                                    :

                                    <ErrorLoading
                                        height={report_section_height + "px"}
                                        data={sortedData}
                                        insights={insights}
                                        is_group={is_group}
                                        error={props.report_error}
                                        isDimension={isDimension}
                                        question={question}
                                    />
                            }

                        </Wrapper>
                    </React.Fragment>
                </React.Fragment>
            )}
        </React.Fragment>

    );

}


const mapStateToProps = (state, props) => {

    const tempData = state.reportReducer.dataFromServer;

    const id = props.id;
    const insight_id = props.insight_id;

    const chart_type_command = state.voiceHelperReducer.chart_type_command;
    const download_command = state.voiceHelperReducer.download_command;
    const other_voice_cmd = state.voiceHelperReducer.other_voice_cmd;

    const filters_cache = state.filterReducer.filters_cache;
    const mtd_ytd_options_cache = state.filterReducer.mtd_ytd_options_cache;
    const drill_down_data_cache = state.reportReducer.drill_down_data;
    const toggleHeader = state.helperReducer.toggleHeader;

    const report_item_filters = filters_cache && filters_cache[id];
    const report_filters = filters_cache && filters_cache[insight_id];

    const filters = report_item_filters;

    const link_filter = state.filterReducer.link_filter_cache?.[insight_id];
    const deleted_link_filter = state.filterReducer.deleted_link_filter;

    const report_loader = state.reportReducer?.['report_loader_cache']?.[id];

    const tableDataMode = state.helperReducer.tableDataMode?.[id];
    const report_menu_cache = state.menuConfigRighterReducer.report_menu_cache;
    const menu_cache = report_menu_cache && report_menu_cache[id]
    const menu_render_mode = menu_cache?.menu_render_mode;
    const dashboard_error = state.errorReducer?.dashboard_errors?.[id]
    const report_error = state.errorReducer?.report_errors?.[id]

    const { mapFilter } = state.mapReducer;


    if (tempData && tempData[id]) {

        const expanded_section_cache = state.helperReducer.expanded_section_cache;
        const expandedSection = expanded_section_cache && expanded_section_cache[id]
        const destroy_global_filter_data = state?.filterReducer?.destroy_global_filter_data_cache?.[insight_id];
        const drill_filter_cache = state?.filterReducer?.drill_filter_cache?.[insight_id];
        const dataFromServer = tempData[id];

        const report_item_should_refresh = state.reportReducer.clonned_report_status_cache && state.reportReducer.clonned_report_status_cache[id];
        const report_should_refresh = state.reportReducer.clonned_report_status_cache && state.reportReducer.clonned_report_status_cache[insight_id];
        const should_refresh = (report_item_should_refresh || report_should_refresh);
        const be_file_path = dataFromServer?.file_path;
        const columns_info = dataFromServer?.columns_info || [];

        return {
            report_loader,
            deleted_link_filter,
            link_filter,
            report_item_filters,
            report_filters,
            drill_filter_cache,
            destroy_global_filter_data,
            tableDataMode,
            filters,
            toggleHeader,
            should_refresh,
            dataFromServer,
            mapFilter,
            drill_down_data_cache,
            filters_cache,
            mtd_ytd_options_cache,
            dashboard_error,
            chart_type_command,
            download_command,
            other_voice_cmd,
            be_file_path,
            columns_info,
            report_error
        }
    }
    else {
        return { report_loader, toggleHeader, mapFilter, dashboard_error, report_error }
    }
}


export default withRouter(connect(mapStateToProps, { dispatch_pivot_data, raiseExcelDownloadRequest, apply_filters, clear_drill_filter_cache, mt_deleted_filter, raise_drill_filter_cache, set_table_data_mode, raiseMenuConfig, toogleHeader, saveReport, getDataFromReportingServer, getReport, setAvailableData, getReportById })(ReportWrapper));
