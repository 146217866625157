import styled from 'styled-components';


export const Wrapper = styled.div`
    width: 100%;

    table{

        width: 100%;
        border-collapse: collapse
    }
    
    td{
        padding: .4rem .8rem;
        margin: auto;
        font-size: 1rem;
        font-weight: normal;
        
    }
    thead{
        td{
            font-weight: 500;
            position: sticky;
            background-color: rgb(0 138 199);
            color: #fff;
            top: 0px;
            z-index: 3;     
            padding: .2rem .8rem;
            border-right: 1px solid #ccc;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
    tbody{
        tr{
            transition: all 0.3s;
            border-bottom: 1px solid #ccc;
            &:hover{
                background-color: #f3f9fc !important;
            }
        }
        td{
            border-right: 1px solid #ccc;
            margin: auto;
            color: #005d86;
            text-transform: capitalize;
        }
    }
`;

export const ListRow = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    .tbl_name{
        font-size: 1rem;
        margin-right: 10px;
    }
    .column_name{
        font-size: 1rem;
    }
`;


export const ListWrapper = styled.div`
    width: 100%;
    margin-top: 1.5rem;
    max-height: calc(100vh - 260px);
    overflow-y: auto;
    border: 1px solid #ccc;
    &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }
    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }
    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }
`;