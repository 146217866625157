import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getNfColumnInfoAdTech, saveNfColumnInfo } from '../../actions/nf_column_info_ad_tech';
import SharedTable from '../shared-table';
import { showPopup } from '../../utils';
import * as enums from '../../utils/enums.js';
import { PageInnerWrapper } from '../hoc/styled.hoc';
import { Wrapper } from './nf_column_info_ad_tech.styled';
import TitleHeader from '../title-header';
import HintEditor from '../hint-editor';
import { Button } from '../hoc/button';



const Index = (props) => {

    const { columnInfo, saveNfColumnInfo } = props;
    const [localColumnInfo, setLocalColumnInfo] = useState(undefined);
    const [localPlatformInfo, setLocalPlatformInfo] = useState(undefined);


    useEffect(() => {
        let _page_info = {
            title: 'COLUMN INFO',
            extraComponent: <Button width="12rem" onClick={() => showHintPopup()}>Add Column Info</Button>
        }
        props.dispatchHeaderInformation(_page_info)

        props.getNfColumnInfoAdTech()
    }, [])


    useEffect(() => {
        if(columnInfo && columnInfo.results){
            setLocalColumnInfo(columnInfo.results)
        }
        if(columnInfo && columnInfo.platforms && columnInfo.platforms.length > 0){
            let temp_platforms = JSON.parse(JSON.stringify(columnInfo.platforms));
            if(temp_platforms.indexOf('all') == -1){
                temp_platforms.unshift('all')
            }
            setLocalPlatformInfo(temp_platforms)
        }
    },[columnInfo])


    const showHintPopup = (item, hint) => {
        showPopup(undefined, undefined, enums.default.popupType.element, HintEditor, {platforms: localPlatformInfo, columnInfo: item, hintData: hint, func: { saveHints: saveNfColumnInfo } })
    }

    return (
        <Wrapper style={{marginTop : '1rem'}}>
            {/* <TitleHeader
                title={'Column Info'}
                extraOption={
                    <Button width="12rem" onClick={() => showHintPopup()}>Add Column Info</Button>
                }
            /> */}
            <PageInnerWrapper>
                <SharedTable
                    action={true}
                    editAction={(item) => {
                        showHintPopup(item, item.hints)
                    }}
                    deleteAction={(item) => {
                        let temp_item = JSON.parse(JSON.stringify(item));
                        temp_item.deleted = true;
                        saveNfColumnInfo(temp_item, "Column Info Deleted Successfully")
                    }}
                    searchBoxLabel={"Search Column Info"}
                    isSearchBox={true}
                    searchColumns={['column_name']}
                    tableData={(localColumnInfo && localColumnInfo.length > 0) ? localColumnInfo : undefined}
                    tableHead={['Column Name', 'Data Type', 'Platform', 'Possible Column Names', 'Actions']}
                    columnsToShow={['column_name', 'data_type', 'platform', 'hints']}
                    actionsTitles={{
                        "deleteAction": {
                            title: "Delete The Column Info",
                            message: "Are you sure you want to delete this column info ?"
                        },
                        "editAction": {
                            title: "Edit The Column Info",
                        }
                    }}
                />

            </PageInnerWrapper>
        </Wrapper>
    )
}



const mapStateToProps = (state) => ({
    columnInfo: state.columnInfoReducer.columnInfo
})


export default connect(mapStateToProps, { getNfColumnInfoAdTech, saveNfColumnInfo })(Index)
