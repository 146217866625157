import React, { useState } from 'react'
import styled from 'styled-components';
import { Button } from '../hoc/button';
import SelectOption from '../select-option'


const Wrapper = styled.div`
    width: 25rem;
    background-color: #fff;
    padding: 0.2rem 1rem 1rem 1rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h1{

        font-size: 1.286rem;
        font-weight: 500;
        text-align: left;
        color: #1a1a1a;
        margin: 0px;
        display: block;
        width: 100%;
        margin-bottom: 18px;
    }

`;



function SelectSecurityType(props) {

    const { close, popupKey, option, data } = props;
    const [selectd, setSelected] = useState([])

    const { accessGroup, columnValue, setColumnValue, type, id, _save_data } = data;


    const _local_save = () => {
        let _data = [];
        if (type === "all") {
            selectd && selectd.length > 0 && selectd.map((select, i) => {
                columnValue && columnValue.length > 0 && columnValue.map((column, j) => {
                    let _obj = {
                        hint_id: column.id,
                        access_group_id: select,
                        is_active: true,
                    }
                    _data.push(_obj);
                })
            })

        }



        if (type == 'single') {
            selectd && selectd.length > 0 && selectd.map((select, i) => {
                columnValue && columnValue.length > 0 && columnValue.map((column, j) => {
                    if (column.id === id) {
                        let _obj = {
                            hint_id: column.id,
                            access_group_id: select,
                            is_active: true,
                        }
                        _data.push(_obj);
                    }

                })
            })
        }
        _save_data(_data)
    }



    const onSelectCheckBox = (value) => {
        let _clone_selectd = JSON.parse(JSON.stringify(selectd));
        let _index = _clone_selectd && _clone_selectd.indexOf(value)
        if (_clone_selectd && _index > -1) {
            _clone_selectd.splice(_index, 1)
        }
        else {
            _clone_selectd.push(value)
        }
        setSelected(_clone_selectd)
    }





    return (
        <Wrapper>
            {/* <h1>Assign Access of Sensitive Data</h1> */}
            <div style={{ width: '100%' }}>
                <SelectOption
                    label="Select Access Group"
                    onChangeFunc={onSelectCheckBox}
                    selections={(accessGroup && accessGroup.length > 0) ? accessGroup : []}
                    displayKey='name'
                    valueKey='id'
                    activeValue={selectd}
                    width="100%"
                    isMultiSelect={true}
                    isCheckBox={true}

                />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '2rem', marginLeft: 'auto' }}>
                <Button
                    width="6rem"
                    mR="1rem"
                    primary={true}
                    onClick={() => {
                        _local_save();
                        close(option, popupKey)


                    }}>Save</Button>
                <Button width="6rem" bgColor={'#e6e6e6'}
                    onClick={() => {
                        close(option, popupKey)
                    }}
                >Cancel</Button>
            </div>
        </Wrapper>
    )
}

export default SelectSecurityType;
