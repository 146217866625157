import styled from 'styled-components';
import { apply_styles } from '../../../utils';
import { constants } from '../../../utils/constants';



export const Wrapper = styled.div`
    max-width: 17rem;
    min-width: 9rem;
    border-radius: 18px;
    /* box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16); */
    /* box-shadow: 2px 0.5px 8px 0.5px #dadfe8; */
    box-shadow: 1px 1.5px 6px 0.5px rgba(0, 0, 0, 0.16);



    border: solid 1px #eaeaea;
    background-color: #fff;
    outline: none;
    /* padding: 0px 5px; */
    padding: 10px 0px;
    box-sizing: border-box;
    
    ${props => props.theme ? apply_styles(props.theme, constants.STYLED_COMPONENTS.ALL) : null};


    .divider{
        border-bottom: 1px solid #ccc;
        width: 100%;
    }

    .__value{
        color: #000000;
    }

    .bottom_data,
    .tooltip-content{
        padding: 0px 15px;
        
    }

    .__key,
    .label{
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        // line-height: .9rem;
        letter-spacing: normal;
        text-align: left;
        white-space: wrap;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        ${props => props.theme ? apply_styles(props.theme, constants.STYLED_COMPONENTS.FONT) : null};
        color: #122e39;
        /* padding: 5px 0px; */
        font-weight: 500;   
    }

    .bottom_data{
        padding: 5px 15px;
        display: flex;
        align-items: flex-start;

        .dot{
            width: .8rem;
            height: .8rem;
            display: inline-block;
            background: #748891;
            margin-right: 1rem;
            border-radius: 50%;
            margin-top: 3px;
        }
    }

    .__key{
        word-break: normal;
        white-space: nowrap;
        /* font-weight: 400; */
        color: #4d4d4d;
        font-weight: 600;

    }
    .more{
        font-size: .8rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: .9rem;
        letter-spacing: normal;
        text-align: center;
        color: #748891;
        text-decoration: underline;
    }
`;