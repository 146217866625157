import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import CrossSvg from '../svg/close';
import { Button } from '../hoc/button';
import { dispatch_pivot_data, save_pivot_data, clear_pivot_data, switch_rows_into_columns, save_report_items, update_report_user_preferance, update_report_column_state } from '../../actions/report';
import { CHART_TYPES, PIVOT_COLUMN_TYPE } from '../../shared-with-fe/constants';
import { compareArrays, showPopup } from '../../utils';
import enums from '../../utils/enums';
import RippleEffect from '../hoc/ripple-effect'
import * as _ from 'lodash';
import DroppedColumnArea from './column.dropped.area';
import SearchSvg from '../svg/search';
import { constants } from '../../utils/constants';
import AngleDown from '../svg/angleDown';
import AngleUp from '../svg/angleUp';
import RippalEffect from '../hoc/ripple-effect'




import {
    column_drop_v1,
    change_column_order_v1,
    get_elements_for_render

} from '../editor.shared/shared.pivot';
import * as dataTypes from '../../shared-with-fe/data.types';



const { CURRENCY_TYPES } = constants;


const Wrapper = styled.div`
   width: 100%;
   box-sizing: border-box;
   padding: 0px 10px;
   display: flex;
   flex-direction: column;
   overflow-y: auto;
   flex-direction: row;
   justify-content: space-between;;
   border-bottom: 1px solid #ccc;
   position: relative;


   .btn_section{
        position: absolute;
        bottom: 10px;
        background-color: #fff;
        width: 97%;
        box-sizing: border-box;
        box-sizing: border-box;
        padding-top: 10px !important;
        padding: 5px;
        border-top: 1px solid #ccc;
   }


   .column_drop_area_v1{
        box-sizing: border-box;
        border-right: 1px solid #ccc;
        padding-right: 10px;
        overflow-y: auto;
        position: relative;
        padding-bottom: 80px;
        &::-moz-scrollbar,
        &::-webkit-scrollbar {
            background-color:red;
            width:2px
        }
        &::-moz-scrollbar-track,
        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-moz-scrollbar-thumb,
        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 2px;
        }

        &::-moz-scrollbar,
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 2px;
        }
   }

   .column_list_area_v1{
        box-sizing: border-box;
        padding-left: 5px;;
        overflow-y: auto;
        padding-bottom: 80px;

        &::-webkit-scrollbar {
            background-color:red;
            width:2px
        }
        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }
        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 2px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 2px;
        }
   }

   .text_box_wrapper{
        width: 100%;
        position: relative;

        span{
            position: absolute;
            left: .4rem;
            top: .6rem;
        }

        input{
            width: 100%;
            height: 2.3rem;
            border: 1.5px solid #ccc;
            border-radius: 3px;
            padding: 1rem;
            box-sizing: border-box;
            padding-left: 1.4rem;
            font-size: .9rem;
            &:-webkit-placeholder{
                color: #ccc;
            }
            &:focus{

            }
        }
    }
   
    &::-webkit-scrollbar {
        background-color:red;
        width:2px
    }
    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }
    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 2px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 2px;
    }

   .column_list{
       width: 100%;
       margin-bottom: 1rem;
       margin-top: .5rem;
       max-height: ${props => props?.list_height + "px" || '10rem'};
       overflow: auto;

       &::-webkit-scrollbar {
            background-color:red;
            width:2px
        }
        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 2px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 2px;
        }

    ul {
        margin: 0px;
        /* padding-left: 1.2rem; */
        list-style: none;
        padding: 0px;

        li{
            font-size: .9rem;
            font-weight: 400;
            text-transform: capitalize;
            line-height: 1.8rem;
            cursor: pointer;
            color: #3c4043;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding: 0px 10px;
            border-radius: 23px;
            &:hover{
                background: #eaeaea;
            }


        }
    }
   }
`;


const PropertyWrapper = styled.div`
    width: 485px;
    background-color: #fff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    padding: 10px 25px;
    border-radius: 3px;
    position: relative;


    .close{
        position: absolute;
        right: 5px;
        top: 5px;
    }

    h1{
        font-size: 1.1rem;
        margin: 0px;
        margin-bottom: 15px;
        text-align: center;
        color: #202124;
        font-weight: 500;
    }

    h2{
        font-size: .9rem;
        font-weight: 400;
        margin: 0px;
    }
    .row{
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 6px 0px;
        align-items: center;
        justify-content: space-between;
    }

    .label{
        font-size: .9rem;
        font-weight: 400;
        width: 200px;
        color: #3c4043;
        text-transform: capitalize;
    }

    select{
        width: 100%;
        height: 30px;
        font-size: .8rem;
        font-weight: 500;
        color: #222;
        border-color: #ccc;
        border-radius: 3px;
        background-color: #fff;
        text-transform: capitalize;
        /* font-style: italic; */
    }

    input{
        width: 100%;
        height: 30px;
        font-size: .8rem;
        font-weight: 400;
        padding: 5px;
        color: #222;
        border:1px solid  #ccc;
        border-radius: 3px;
        background-color: #fff;
        box-sizing: border-box;
    }

    .select_div{
        width: 200px;

    }


    .save{
        cursor: pointer;
        width: 91px;
        height: 31px;
        font-size: .8rem;
        background: #008AC7;
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
        border: 0px;
        color: #fff;
        border-radius: 3px;
        font-weight: 600;
        text-transform: uppercase;

        &:hover{
            background-color: #005d86;
        }
    }
`;



const ValueFormatterContainer = styled.div`

    width: 100%;

    .main_row{
        font-size: 1rem;
        margin: 0.8rem 0px;
        font-weight: 600;
        color: #222;
        display: flex;
        align-items: center;
        cursor: pointer;
        /* justify-content: space-between; */
    }

    .item_row{

        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        margin-bottom: .5rem;
        justify-content: space-between;

         select{
                width: 50px;
                height: 20px;
                font-size: .8rem;
                font-weight: 500;
                color: #222;
                border-color: #ccc;
                border-radius: 3px;
                background-color: #fff;
                text-transform: capitalize;
         }

        input{
            width: .9rem;
            height: .9rem;
            font-size: .8rem;
            font-weight: 400;
            padding: 5px;
            color: #222;
            border:1px solid  #ccc;
            border-radius: 3px;
            background-color: #fff;
            box-sizing: border-box;
            margin: 0px;;
        }
        .bdr_plain{
            border-bottom: 1px solid #ccc;
            width: 100%;;
            margin: .4rem 0px;
            box-sizing: border-box;
        }
    }
`;



const ___aggreation_types_ = [
    { name: "Sum", value: "sum" },
    // { name: 'Count', value: "count" },
    { name: 'Cumulative', value: "cumulative" },
    // { name: 'Cumulative Count', value: "cumulative_count" },
    // { name: 'Min', value: "min" },
    // { name: 'Max', value: "max" },
    // { name: 'Average', value: "avg" },
    // { name: 'Difference', value: "diff" },
    // { name: 'Difference From Start', value: "diff_start" },
    // { name: 'Column Wise Percentage', value: "percentage_cwise" },
    { name: 'Percentage', value: "percentage" },
    // { name: 'Percentage Diff', value: "perc_diff" },
    // { name: 'Variance', value: "var" },
    // { name: 'Standard Deviation', value: "stdDev" },
]



const ColumnPropertyPiker = (props) => {

    const { data, close, options, popupKey } = props;
    const { column_data, index, save_properties, active_row, pivot_columns_state, save_pivot_column_state, showSingleCell, column_meta_data } = data;

    const [local_setting, set_local_setting] = useState({
        chart_type: undefined,
        axis: undefined,
        pivot_aggregation: undefined,
        is_shade: undefined,
    });


    const [is_open_value_formatter, set_is_open_value_formatter] = useState(false)


    const [number_value_config, set_number_value_config] = useState({});



    useEffect(() => {
        // column_meta_data

        const column_key = column_data?.pivot_field_alias || column_data?.pivot_field_column_name;

        const clone_pivot_columns_state = pivot_columns_state ? JSON.parse(JSON.stringify(pivot_columns_state)) : {};

        const current_column_data = clone_pivot_columns_state?.[column_key];

        if (current_column_data?.["number_config"]) {
            const number_config = current_column_data?.["number_config"] ? { ...current_column_data?.["number_config"] } : {};
            set_number_value_config(number_config)
        } else {

            const data_type = column_meta_data?.[column_key]?.['data_type'];
            console.log("pivot_columns_state1.1", data_type, column_data)

            const default_config_for_number = {
                num_format_type: "comma",
                use_decimal: 2
            }

            const default_config_for_number_with_decimal = {
                num_format_type: "comma",
                use_decimal: 2
            }

            const default_for_percent = {
                use_decimal: 2,
                use_percent: true
            }


            const default_config_for_currency = {
                num_format_type: "comma",
                currency_type: CURRENCY_TYPES.RUPEE,
                use_decimal: 2
            }
            const default_config = data_type === dataTypes.currency ? default_config_for_currency : data_type === dataTypes.number_with_decimal ? default_config_for_number_with_decimal : data_type === dataTypes.percent ? default_for_percent : default_config_for_number;

            if (showSingleCell) {
                default_config.num_format_type = "crores";
            }
            set_number_value_config(default_config)
            // const temp_num_config = {
            //     num_format_type: showSingleCell ? 'crores' : undefined,
            //     use_decimal: showSingleCell ? 2 : undefined,
            //     use_currency: data_type === 2.1 ?  : false,
            // }

        }

    }, [])


    useEffect(() => {
        set_local_setting(column_data)
    }, [column_data])


    const property_on_change = (value, index, key) => {
        set_local_setting({
            ...local_setting,
            [key]: value,
        })
    }



    const mbc_on_change = (type) => {
        const clone_number_value_config = number_value_config ? JSON.parse(JSON.stringify(number_value_config)) : {};
        if (clone_number_value_config["num_format_type"] === type) {
            clone_number_value_config["num_format_type"] = "nothing";
        } else {
            clone_number_value_config["num_format_type"] = type;
        }
        set_number_value_config(clone_number_value_config)
    }


    const pivot_field_alias = ((column_data && column_data.pivot_field_alias) || (column_data && column_data.pivot_field_column_name));


    return (
        <PropertyWrapper>
            <h1>Pivot Table Field</h1>
            <div className="close">
                <RippleEffect
                    width={30}
                    icon={<CrossSvg size=".7rem" height=".7rem" color="red" />}
                    tooltip="Close"
                    onClick={() => close(options, popupKey)}
                />
            </div>

            <div className="row">
                <div className="label">Field Name</div>
                <div className="select_div">
                    <span style={{ fontSize: '.9rem' }}>
                        <input type='text' defaultValue={pivot_field_alias}
                            onChange={(e) => {
                                property_on_change(e.target.value, index, 'pivot_field_alias')
                            }}
                        />
                    </span>
                </div>

            </div>
            <div className="row">
                <div className="label">Aggregation Type</div>
                <div className="select_div">
                    <select value={local_setting.pivot_aggregation}
                        onChange={(e) => {
                            property_on_change(e.target.value, index, 'pivot_aggregation')
                        }}>
                        {___aggreation_types_.map((a) => {
                            return (
                                <option value={a.value}>{a.name}</option>
                            )
                        })}
                    </select>
                </div>

            </div>

            <div className="row">
                <div className="label">How to Display</div>
                <div className="select_div">
                    <select value={local_setting.chart_type}
                        onChange={(e) => {
                            property_on_change(e.target.value, index, 'chart_type')
                        }}>
                        {[CHART_TYPES.bar_chart, CHART_TYPES.line_chart, CHART_TYPES.area_chart].map((a) => {
                            return (
                                <option style={{ textTransform: 'capitalize', }} value={a}>{a && a.split("_").join(" ")}</option>
                            )
                        })}
                    </select>
                </div>
            </div>

            <div className="row">
                <div className="label">Where to Display</div>
                <div className="select_div">
                    <select value={local_setting.axis}
                        onChange={(e) => {
                            property_on_change(e.target.value, index, 'axis')
                        }}>
                        {[{ value: 'primary', name: "Primary (Left Y Axis)" }, { value: 'secondary', name: "Secondary (Right Y Axis)" }].map((a) => {
                            return (
                                <option value={a.value}>{a.name}</option>
                            )
                        })}
                    </select>
                </div>
            </div>

            {/* <div className="row">
                <div className="label">Show Shade</div>
                <div className="select_div">
                    <input style={{ width: '15px', height: '15px' }} type="checkbox" checked={local_setting.is_shade}
                        onChange={(e) => {
                            property_on_change(!local_setting.is_shade, index, 'is_shade')
                        }}
                    />
                </div>
            </div> */}

            {/* <div className="row">
                <div className="label">Show Trend</div>
                <div className="select_div">
                    <input style={{ width: '15px', height: '15px' }} type="checkbox" checked={local_setting.is_trend}
                        onChange={(e) => {
                            property_on_change(!local_setting.is_trend, index, 'is_trend')
                        }}
                    />
                </div>
            </div> */}

            {/* <div className="row">
                <div className="label">Show Gauge Trend</div>
                <div className="select_div">
                    <input style={{ width: '15px', height: '15px' }} type="checkbox" checked={local_setting.is_gauge_trend}
                        onChange={(e) => {
                            property_on_change(!local_setting.is_gauge_trend, index, 'is_gauge_trend')
                        }}
                    />
                </div>
            </div> */}


{/* value formatter is enable here for single cell only */}
           { showSingleCell && 
                <ValueFormatterContainer>

                    <div className='main_row' onClick={() => {
                        set_is_open_value_formatter(!is_open_value_formatter)
                    }}>
                        <div style={{ width: '300px' }}>Value Formatter</div>
                        <span className="select_arrow">
                            <RippalEffect
                                width={30}
                                Click={() => {
                                    set_is_open_value_formatter(!is_open_value_formatter)
                                }}
                                icon={is_open_value_formatter ? <AngleUp size="0.8rem" height="0.8rem" /> : <AngleDown style={{ color: 'red' }} size="0.8rem" height="0.8rem" />}
                            >
                            </RippalEffect>
                        </span>
                    </div>

                    {is_open_value_formatter && (
                        <>
                            <div
                                className="item_row">
                                <span className="label">Decimals Number</span>
                                <div className="select_div">
                                    <select className="custom_select" value={number_value_config["use_decimal"]}
                                        onChange={(e) => {
                                            const clone_number_value_config = number_value_config ? JSON.parse(JSON.stringify(number_value_config)) : {};
                                            clone_number_value_config["use_decimal"] = e.target.value;
                                            set_number_value_config(clone_number_value_config);
                                        }}>
                                        {
                                            [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((v) => {
                                                return <option key={v} value={v}>{v}</option>
                                            })
                                        }
                                    </select>
                                </div>

                            </div>

                            <div className="bdr_plain"></div>

                            <div onClick={() => mbc_on_change("comma")} className="item_row">
                                <span className="label">Use Comma</span>
                                <div className="select_div">

                                    <input checked={number_value_config["num_format_type"] === "comma"} type={"checkbox"}
                                    />
                                </div>
                            </div>
                            <div onClick={() => mbc_on_change("million")} className="item_row">
                                <span className="label">Use Million</span>
                                <div className="select_div">

                                    <input checked={number_value_config["num_format_type"] === "million"} type={"checkbox"}
                                    />
                                </div>
                            </div>
                            <div onClick={() => mbc_on_change("crores")} className="item_row">
                                <span className="label">Use Crores</span>
                                <div className="select_div">

                                    <input checked={number_value_config["num_format_type"] === "crores"} type={"checkbox"}
                                    />
                                </div>
                            </div>


                            <div className="bdr_plain"></div>

                            <div
                                className="item_row">
                                <span className="label">Currency Sign</span>
                                <div className="select_div">

                                    <select className="custom_select" value={number_value_config["currency_type"]}
                                        onChange={(e) => {
                                            const clone_number_value_config = number_value_config ? JSON.parse(JSON.stringify(number_value_config)) : {};
                                            clone_number_value_config["currency_type"] = e.target.value;
                                            set_number_value_config(clone_number_value_config);
                                        }}
                                    >
                                        <option value={""}>---</option>
                                        {Object.values(CURRENCY_TYPES).map((v) => {
                                            return <option key={v} value={v}>{v}</option>
                                        })}
                                    </select>
                                </div>
                            </div>


                            <div className="bdr_plain"></div>

                            <div onClick={() => {

                                const clone_number_value_config = number_value_config ? JSON.parse(JSON.stringify(number_value_config)) : {};
                                if (clone_number_value_config["use_percent"]) clone_number_value_config["use_percent"] = false;
                                else clone_number_value_config["use_percent"] = true;
                                set_number_value_config(clone_number_value_config);

                            }} className="item_row">
                                <span className="label">Use Percent</span>
                                <div className="select_div">

                                    <input checked={number_value_config["use_percent"]} type={"checkbox"}
                                    />
                                </div>
                            </div>
                        </>
                    )}


                </ValueFormatterContainer>
            }

            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px 0px' }}>
                <button onClick={() => {

                    const column_key = local_setting?.pivot_field_alias || column_data?.pivot_field_alias || column_data?.pivot_field_column_name;


                    const clone_pivot_columns_state = pivot_columns_state ? JSON.parse(JSON.stringify(pivot_columns_state)) : {};

                    if (local_setting?.pivot_field_alias !== (pivot_field_alias || column_data.pivot_field_column_name)) {
                        // let's delete previus column keys from the column state
                        delete clone_pivot_columns_state[pivot_field_alias];
                        delete clone_pivot_columns_state[column_data.pivot_field_column_name];

                    }
                    const current_column_data = clone_pivot_columns_state?.[column_key] || { show: true };


                    if (current_column_data && number_value_config && Object.keys(number_value_config)?.length > 0) {
                        current_column_data.number_config = number_value_config
                    } else {
                        current_column_data.number_config = undefined;

                    }
                    if (column_key) {
                        clone_pivot_columns_state[column_key] = current_column_data;

                    }

                    console.log("local_setting", local_setting, clone_pivot_columns_state)
                    save_properties(local_setting, index)
                    save_pivot_column_state(clone_pivot_columns_state);
                    setTimeout(() => {
                        close(options, popupKey)
                    }, 200)
                }} className="save">Save</button>
            </div>
        </PropertyWrapper>
    )

}







const ColumnChooser = (props) => {

    const {

        xDataKeys = [],
        yDataKeys = [],
        report_id,
        pivot_axis,
        dispatch_pivot_data,
        save_pivot_data,
        heightOfChart,
        columns_info,
        column_meta_data,
        need_to_switch_row_column,
        pivot_columns_state

    } = props;



    const { formula_columns } = props;
    const [active_row, set_active_row] = useState(undefined);

    const [dropped_values, set_dropped_values] = useState(undefined);
    const [dropped_row, set_dropped_row] = useState(undefined);
    const [dropped_columns, set_dropped_columns] = useState(undefined);

    const [local_columns_info, set_local_columns_info] = useState(undefined);
    const [need_to_use_initial, set_need_to_use_initial] = useState(true);
    const [initial_dropped_values, set_initial_dropped_values] = useState(undefined);
    const [initial_dropped_row, set_initial_dropped_row] = useState(undefined);
    const [initial_dropped_columns, set_initial_dropped_columns] = useState(undefined);
    const [search_value, set_search_value] = useState('');





    useEffect(() => {
        const clone_columns_info = columns_info ? [...columns_info] : []; //
        clone_columns_info.map((c, i) => c.index = i).sort((a, b) => a.order - b.order);
        set_local_columns_info(clone_columns_info)
    }, [])





    /**
     * 
     * This function will called when pivot_axis will be changed;
     */


    useEffect(() => {

        if (pivot_axis) {

            const xac = pivot_axis.xac ? JSON.parse(JSON.stringify(pivot_axis.xac)) : [];
            const yac = pivot_axis.yac ? JSON.parse(JSON.stringify(pivot_axis.yac)) : [];
            const pivot_data_columns = pivot_axis?.pivot_data_columns ? JSON.parse(JSON.stringify(pivot_axis.pivot_data_columns)) : [];

            const filterd_yac = yac.filter((v) => v.visible_type !== 3) || [];

            yac.forEach((y, index) => {
                if (!y.key) y.key = 'rndm' + index;
            });

            xac.forEach((y, index) => {
                if (!y.key) y.key = 'rndm' + index;
            });

            pivot_data_columns.forEach((y, index) => {
                if (!y.key) y.key = 'rndm' + index;
            });


            set_dropped_row(xac)
            set_dropped_columns(pivot_data_columns)
            set_dropped_values(filterd_yac)


            if (need_to_use_initial) {
                // here we will write the initial values code
                if (!initial_dropped_row || initial_dropped_row.length === 0) set_initial_dropped_row(JSON.parse(JSON.stringify(xac)))
                if (!initial_dropped_columns || initial_dropped_columns.length === 0) set_initial_dropped_columns(JSON.parse(JSON.stringify(pivot_data_columns)))
                if (!initial_dropped_values || initial_dropped_values.length === 0) set_initial_dropped_values(JSON.parse(JSON.stringify(filterd_yac)))
                set_need_to_use_initial(false)
                // console.log("brother1.1", pivot_data_columns)
            }

        } else {

            set_dropped_values(undefined)
            set_dropped_row(undefined)
            set_dropped_columns(undefined)
            set_initial_dropped_row(undefined)
            set_initial_dropped_columns(undefined)
            set_initial_dropped_values(undefined)
            set_need_to_use_initial(true)


        }
    }, [pivot_axis])



    const dispatch_values_for_pivot = (columns_info, need_to_switch_row_column = false) => {

        const report_item_id_ = props.report_item_id ? props.report_item_id : report_id;
        const clonned_columns_info = columns_info ? JSON.parse(JSON.stringify(columns_info)) : [];
        const pivot_x = clonned_columns_info.filter((c) => c.fe_pivot_type === PIVOT_COLUMN_TYPE.PIVOT_X).sort((a, b) => a.pivot_field_order - b.pivot_field_order);
        const pivot_y = clonned_columns_info.filter((c) => c.fe_pivot_type === PIVOT_COLUMN_TYPE.PIVOT_Y).sort((a, b) => a.pivot_field_order - b.pivot_field_order);
        const pivot_comparision = clonned_columns_info.filter((c) => c.fe_pivot_type === PIVOT_COLUMN_TYPE.PIVOT_COMPARISON).sort((a, b) => a.pivot_field_order - b.pivot_field_order);
        const column_local_column_infos = columns_info ? JSON.parse(JSON.stringify(columns_info)) : [];

        const changed_key = compareArrays(clonned_columns_info, props.columns_info);

        console.log("changed_key", changed_key, clonned_columns_info, props.columns_info)
        if (changed_key?.length > 0) {
            dispatch_pivot_data(report_id, report_item_id_, pivot_x, pivot_y, pivot_comparision, column_local_column_infos, need_to_switch_row_column, props.be_file_path);
        }

    };



    const save = () => {

        const report_item_id_ = props.report_item_id ? props.report_item_id : report_id;
        const __id__to_use = props?.is_details_window ? (report_item_id_ + "__details") : report_item_id_;
        save_pivot_data(report_id, __id__to_use, local_columns_info);
        const temp_report = {
            id: __id__to_use,
            modified: true,
            is_switch_row_column: props.need_to_switch_row_column
        }
        props.report_item_id && props.save_report_items([temp_report])

    };


    const reset = () => {
        const rows = initial_dropped_row ? JSON.parse(JSON.stringify(initial_dropped_row)) : [];
        const values = initial_dropped_values ? JSON.parse(JSON.stringify(initial_dropped_values)) : [];
        const columns = initial_dropped_columns ? JSON.parse(JSON.stringify(initial_dropped_columns)) : [];
        dispatch_values_for_pivot(rows, columns, values, true);
    };




    const save_properties = (property, index) => {

        const clone_column_info = local_columns_info ? [...local_columns_info] : [];
        const index_of_item = clone_column_info?.findIndex(c => c.index === index);
        clone_column_info[index_of_item] = property;
        clone_column_info[index_of_item].modified = true;

        dispatch_values_for_pivot(clone_column_info, need_to_switch_row_column);
        set_local_columns_info(clone_column_info)
    }

    const change_column_value = (key, value, index) => {
        const clone_column_info = local_columns_info ? [...local_columns_info] : [];
        const index_of_item = clone_column_info?.findIndex(c => c.index === index);
        clone_column_info[index_of_item][key] = value
        clone_column_info[index_of_item].modified = true;
        dispatch_values_for_pivot(clone_column_info, need_to_switch_row_column);
        set_local_columns_info(clone_column_info)
    }


    const save_pivot_column_state = (config) => {

        const id_to_use = props.report_item_id || props.report_id;
        const preferance_name = 'user_column_preferance'
        console.log("config", config);

        props.update_report_column_state(id_to_use, config, "pivot_data");
        props.update_report_user_preferance(undefined, id_to_use, preferance_name, config, "all")

    }

    const open_value_property_popup = (data, c, index) => {
        showPopup(undefined, undefined, enums.popupType.element,
            ColumnPropertyPiker,
            {
                column_data: c,
                index: index,
                showSingleCell: props.showSingleCell,
                active_row: active_row,
                column_meta_data: column_meta_data,
                save_pivot_column_state: save_pivot_column_state,
                pivot_columns_state: pivot_columns_state,
                save_properties: save_properties

            },
        )
    }




    /**
     * 
     * @param {*} pivot_column_type 
     * @param {*} event 
     */
    const local_column_drop_v1 = (pivot_column_type, event) => {
        const final_data_to_set = column_drop_v1(local_columns_info, column_meta_data, pivot_column_type, event)

        // console.log("final_data_to_set", final_data_to_set)
        set_local_columns_info(final_data_to_set)
        dispatch_values_for_pivot(final_data_to_set, need_to_switch_row_column);
    }

    /**
     * 
     * @param {*} event 
     */

    const local_change_column_order_v1 = (event) => {
        const final_data_to_set = change_column_order_v1(local_columns_info, event)
        set_local_columns_info(final_data_to_set)
        dispatch_values_for_pivot(final_data_to_set, need_to_switch_row_column);

    }


    // this function will update the pivot setting
    const delete_column_v1 = (column_index) => {

        const clone_local_columns_info = local_columns_info ? [...local_columns_info] : [];

        const column_info = clone_local_columns_info.find(c => (c.index == column_index));
        const index_of_column_info = clone_local_columns_info.findIndex(c => (c.index == column_index));

        // here we are changing the column info
        if (index_of_column_info > -1) {
            if (!column_info.is_repeater) {
                clone_local_columns_info[index_of_column_info].fe_pivot_type = undefined;
                clone_local_columns_info[index_of_column_info].pivot_field_order = undefined;
                clone_local_columns_info[index_of_column_info].pivot_field_alias = '';
                clone_local_columns_info[index_of_column_info].axis = '';
                clone_local_columns_info[index_of_column_info].chart_type = '';
                clone_local_columns_info[index_of_column_info].modified = true;

            } else {
                clone_local_columns_info.splice(index_of_column_info, 1);
            }
        }
        set_local_columns_info(clone_local_columns_info)
        dispatch_values_for_pivot(clone_local_columns_info, need_to_switch_row_column);
    }




    const titles = {
        "pivot_y": 'Values',
        "pivot_x": 'Row',
        "pivot_comparison": 'Columns'
    }

    const f_height = (heightOfChart - 60)
    const row_height = 200;
    const column_cell_max_height = 80;
    const field_cell_max_height = 200;

    const total_extra_cell_height = (column_cell_max_height + field_cell_max_height + 70);
    const cell_max_height = (heightOfChart - total_extra_cell_height) / 2;

    const filterd_column_data = local_columns_info?.filter((c) => {
        const display_name = column_meta_data?.[c.alias] ? column_meta_data?.[c.alias].display_value : c.alias;
        if (search_value === "") return true;
        if (search_value && display_name && display_name.toLocaleLowerCase().indexOf(search_value.toLocaleLowerCase()) > -1) return true;
        else return false;
    })



    console.log("polusCommand", props.polusCommand)

    return (
        <Wrapper style={{ height: f_height + "px", width: '400px' }} height={field_cell_max_height}>

            <div style={{ width: '58%', height: (f_height - 10) + "px", maxHeight: (f_height - 10) + "px" }} className="column_drop_area_v1">

                {/* <div style={{ width: '100%', padding: '10px 0px', paddingBottom: '20px', display: 'flex', alignItems: 'center' }}>
                    <input style={{ cursor: 'pointer' }}
                        checked={props.need_to_switch_row_column}
                        type="checkbox"
                        onClick={(e) => {
                            dispatch_values_for_pivot(local_columns_info, e.target.checked)

                        }} /> <span style={{ fontSize: '.9rem', fontWeight: 600 }}>Switch Row And Column</span>
                </div> */}

                {PIVOT_COLUMN_TYPE && Object.keys(PIVOT_COLUMN_TYPE).map((k) => {
                    const key = PIVOT_COLUMN_TYPE[k]
                    return (
                        <DroppedColumnArea
                            key={key}
                            key_for_use={key}
                            title={titles[key]} //
                            column_drop_v1={local_column_drop_v1}
                            height={(key === PIVOT_COLUMN_TYPE.PIVOT_COMPARISON) ? column_cell_max_height : key === PIVOT_COLUMN_TYPE.PIVOT_X ? row_height : field_cell_max_height}
                            options_for_render={local_columns_info?.length > 0 ? get_elements_for_render(local_columns_info, key) : []}
                            change_column_order_v1={local_change_column_order_v1}
                            set_active_row={set_active_row}
                            change_column_value={change_column_value}
                            open_value_property_popup={open_value_property_popup}
                            delete_column_v1={delete_column_v1}
                            save_properties={save_properties}
                        />
                    )
                })}


            </div>

            <div style={{ width: '42%', height: (f_height - 10) + "px", maxHeight: (f_height - 10) + "px" }} className="column_list_area_v1">

                <div style={{ width: '100%', boxSizing: 'border-box', marginBottom: '.6rem' }}>
                    <div className='text_box_wrapper'>
                        <span>
                            <SearchSvg size={'.9rem'} height={'.9rem'} color="#ccc" />
                        </span>
                        <input
                            type="text"
                            placeholder="Search"
                            value={search_value}
                            onChange={(e) => {
                                set_search_value(e.target.value);
                            }}
                        />
                    </div>
                </div>

                <div style={{ color: '#222222a8', fontSize: '1rem', fontWeight: 600 }}>Field Name</div>

                <div className="column_list" style={{ minHeight: '100px' }}>
                    <ul>
                        {filterd_column_data && filterd_column_data.length > 0 && filterd_column_data.map((c, index) => {
                            if (!c.is_repeater) {
                                const display_name = column_meta_data?.[c.alias] ? column_meta_data?.[c.alias].display_value : c.alias;
                                return (
                                    <li
                                        draggable={true}
                                        onDragStart={(event) => {
                                            event.dataTransfer.setData("pivot_field_column_name", display_name)
                                            event.dataTransfer.setData("column_info_index", c.index)
                                            event.dataTransfer.setData("column_info_id", c.id)
                                        }}
                                        title={display_name}
                                        key={"c" + display_name}
                                    >{display_name}</li>
                                )
                            }
                        })}
                    </ul>
                </div>
            </div>



            <div className='btn_section'
                style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 'auto' }}>

                <Button
                    width="120px"
                    primary={true}
                    mR="1rem"
                    onClick={() => {
                        save()
                    }}>Save</Button>

                <Button
                    width="120px"
                    primary={true}
                    disabled={true}
                    onClick={() => {
                        // reset()
                    }}>Reset</Button>
            </div>



            {/* #ed9797 */}
        </Wrapper>
    )
}



const mapStateToProps = (state, props) => {

    const dataFromServer = state.reportReducer.dataFromServer;
    const data = dataFromServer[props.report_id] && dataFromServer[props.report_id];

    const column_meta_data = data?.column_meta_data || {};
    
    // const config_meta = data?.config_meta || {};
    const pivot_columns_state =  data?.pivot_data?.pivot_columns_state
    const columns_info = data?.columns_info || [];

    const tbl_ui_formulas_be = data?.config_meta?.tbl_ui_formulas || [];
    const be_filterd_formula = tbl_ui_formulas_be.filter((f) => f.type === "report_editor") || [];
    const tbl_formulas_from_cache = state.reportReducer.tbl_formulas;

    const tbl_ui_formulas_fe = tbl_formulas_from_cache?.[props.report_id] || []
    const mearged_both_formulas = [...be_filterd_formula, ...tbl_ui_formulas_fe];
    const formula_columns = mearged_both_formulas.map((f) => f.name) || [];
    const pivot_axis = data && data?.pivot_data?.pivot_data_axis;
    const need_to_switch_row_column = data?.need_to_switch_row_column;

    const be_file_path = data?.file_path;

    return {column_meta_data, columns_info, pivot_axis, formula_columns, need_to_switch_row_column, be_file_path, pivot_columns_state }

}


export default connect(mapStateToProps, { update_report_column_state, update_report_user_preferance, save_report_items, clear_pivot_data, dispatch_pivot_data, save_pivot_data, switch_rows_into_columns })(ColumnChooser)
