import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import ProgressBar from '../../hoc/progressBar';

// Keyframes for the bounce animation
const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0.8);
    opacity: 0.6;
  } 
  40% {
    transform: scale(1.2);
    opacity: 1;
  }
`;

// Wrapper for the loader and text in one line
const LoaderWrapper = styled.div`
  /* display: flex;
  align-items: center;
  margin-right: auto;
  justify-content: flex-start;
  flex-direction: column; */
`;

// Styling for the animated dots
const Dot = styled.span`
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #3498db;
  border-radius: 50%;
  display: inline-block;
  animation: ${bounce} 1.5s infinite ease-in-out both;

  &:nth-child(1) {
    animation-delay: -0.3s;
  }
  &:nth-child(2) {
    animation-delay: -0.15s;
  }
`;

// Styling for the text
const Text = styled.p`
  color: #555;
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: 100;
  margin-right: 3px;
`;

const aiModalLodingTexts = [
  "Seeking the Data...",
  "Preparing the Data Model...",
  "Writing the Data Pipeline...",
  "Training the AI Model...",
  "Creating the Executive Summary..."
];

const lodingData = [
  'Analyzing Data...',
  'Processing Information...',
  'Gathering Insights...'
];

const Loader = ({isAiModal=false}) => {

  const [state, setState] = useState(1);

  // const text = state === 1 ? 'Analyzing Data...' : state === 2 ? 'Processing Information...' : 'Gathering Insights...';
  const lodingDataList = isAiModal ? aiModalLodingTexts : lodingData;
  const text = lodingDataList[state-1]

  const incrementalFactor = (99/lodingDataList?.length) // instead of 100%, considering 99%

  useEffect(() => {
    const interval = setInterval(() => {
      if (state === lodingDataList?.length ) return;
      setState((prevState) => (prevState + 1));
    }, 5000);
    return () => clearInterval(interval);
  }, [state]);

  return (
    <LoaderWrapper>
      <Text>{text}</Text>
      <ProgressBar progress={state * incrementalFactor} width={'50rem'} backgroundColor={'#fff'} color={`linear-gradient(to right, #6b82ff, #8685ff, #9c89ff, #b08dff, #c291ff, #be92fe, #bb92fd, #b793fc, #9c90f8, #7f8cf3, #5f88ed, #3384e5)`} />
      {/* <Dot></Dot>
            <Dot></Dot>
            <Dot></Dot> */}
    </LoaderWrapper>
  );
};

export default Loader;
