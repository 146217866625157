import * as actions from '../action-types/hints';

const initialState = {
    type: actions.HINTS_INIT,
    hints: undefined,
    tableNameOfHints: undefined,
    hintsByDbInfo: undefined,
    hintsWithTableColumnsName: undefined,
    recentTableSearch: undefined,
    tableInfo: undefined,
    dublicateHints : undefined,
    closeHintsPopup: undefined,
    rulesHints: undefined
};


const hintsReducer = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }



    // if (action && action.data && typeof action.data === 'object') {
    // action.data.key = new Date();
    // }

    switch (action.type) {
        case actions.HINTS_SUCCESS:
            return {
                ...state,
                type: action.type,
                hints: action.data
            };

        case actions.TABLES_HINTS_SUCCESS:
            return {
                ...state,
                type: action.type,
                tableNameOfHints: action.data
            };

        case actions.HINTS_BY_ENTITY_SUCCESS:
            return {
                ...state,
                type: action.type,
                hintsByDbInfo: action.data
            };

        case actions.CLEAR_HINTS_BY_ENTITY_SUCCESS:
            return {
                ...state,
                type: action.type,
                hintsByDbInfo: undefined
            };

        case actions.HINTS_WITH_TABLE_COLUMN_NAME_SUCCESS:
            return {
                ...state,
                type: action.type,
                hintsWithTableColumnsName: action.data
            };

        case actions.CLEAR_CONFIG_EDITOR_STATE:
            return {
                ...state,
                type: action.type,
                hintsWithTableColumnsName: undefined,
                hints: undefined
            };

        case actions.SAVE_HINTS_SUCCESS:
            return {
                ...state,
                type: action.type,
                hints: undefined
            };

        case actions.CLEAR_HINTS_SUCCESS:
            return {
                ...state,
                type: action.type,
                hints: undefined,
                tableNameOfHints: undefined
            };
        case action.GET_HINTS_FROM_SESSION:
            return {
                ...state,
                type: action.type,
                recentTableSearch: action.data
            }

        case action.SAVE_HINTS_IN_SESSION:
            return {
                ...state,
                type: action.type
            }
        case actions.GET_TBL_INFO_BY_TBL_NAME:
            return {
                ...state,
                type: action.type,
                tableInfo: action.data
            }
        case actions.CLEAR_TBL_INFO:
            return {
                ...state,
                type: action.type,
                tableInfo: undefined
            }
        case actions.RAISE_DUBLICATE_HINTS_FOUND:
            return {
                ...state,
                type: action.type,
                dublicateHints: action.data
            }
        case actions.MT_DUBLICATE_HINTS_FOUND:
            return {
                ...state,
                type: action.type,
                dublicateHints: undefined
            }
        case actions.CLOSE_HINTS_POPUP:
            return {
                ...state,
                type: action.type,
                closeHintsPopup: true
            }

        case actions.GET_HINTS_FOR_RULES:
            return {
                ...state,
                type: action.type,
                rulesHints: action.data
            }
        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
            return {
                type: state.type,
                hints: state.hints,
                tableNameOfHints: state.tableNameOfHints,
                hintsByDbInfo: state.hintsByDbInfo,
                hintsWithTableColumnsName: state.hintsWithTableColumnsName,
                tableInfo: state.tableInfo,
                dublicateHints: state.dublicateHints,
                rulesHints: state.rulesHints
            };
    }
};

export default hintsReducer;