import React, { useState } from 'react';
import styled from 'styled-components';
import Search from '../../svg/search';


const Container = styled.div`
  position: relative;
  width: ${props => props.width || '100%'};
  margin:  ${props => props.margin || '0 auto'};
  border: 1px solid transparent;

  box-sizing: border-box;

  .search{
        position: absolute;
        top: 30%;
        right: 3%;
        z-index: 2;
    }
`;

const Input = styled.input`
  width: ${props => props.width || '100%'};
  padding: ${(props) => props.padding || '0.9rem'};
  border: 1px solid #ccc;
  font-size: 16px;
  box-sizing: border-box;
  background: #f2f2f2;
  border: none;
  border-radius: ${(props) => props.borderRadius || '6px'};
  position: relative;
  z-index: 2;
  &::placeholder{
            font-size: 11px;
            /* padding-left: 1rem; */
        }


  &:hover{
        border: 1px solid #afc4e3;
    }
    &:focus{
        outline: none;
        border: 1px solid #0000003b;
    }
    &:focus-visible{
        outline: none;
        border: 1px solid #007FFF;
    }
`;

const SuggestionsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  width: ${props => props.width || '100%'};
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: #fff;
  z-index: 1;

  &::-webkit-scrollbar {
        background-color: #3F3F3F;
        width:4px;
    }

    &::-webkit-scrollbar-track {
        background-color: #F3F3F3;
        border: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dbdbdb;
        border-radius: 10px;
        height: 4px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color:  #3F3F3F;
        height: 4px;
    }

`;

const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  border-radius: 6px;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const SearchBox = (props) => {

    const { items, suggestionOnClick, clear_after_click, placeholder, width, margin, searchIconStyle, padding, suggestionListStyle, suffix, onChange } = props;

    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);


    const handleChange = (e) => {
        const value = e.target.value;
        onChange && onChange(e)
        setQuery(value);
        if (value.length > 0) {
            const filteredSuggestions = items.filter(item =>
                item?.toLowerCase().includes(value?.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };


    const handleSuggestionClick = (suggestion) => {
        setQuery(suggestion);
        setShowSuggestions(false);
        suggestionOnClick(suggestion)
        if (clear_after_click) {
            setQuery('  ')
        }
    };

    return (
        <Container id='input_search' width={width} margin={margin}>
            <Input
                type="text"
                value={query}
                onChange={handleChange}
                placeholder={placeholder}
                width={width}
                borderRadius={props.borderRadius}
                padding={padding}
            />
            <div className="search" style={{...searchIconStyle}}>
                {suffix || <Search size="1.2rem" height="1.2rem" color='#9caeb5' />}
            </div>
            {showSuggestions && (
                <SuggestionsList style={{...suggestionListStyle}}>
                    {suggestions.map((suggestion, index) => (
                        <SuggestionItem
                            key={index}
                            onClick={() => handleSuggestionClick(suggestion)}
                        >
                            {suggestion}
                        </SuggestionItem>
                    ))}
                </SuggestionsList>
            )}
        </Container>
    );
};

export default SearchBox;
