import * as actions from '../action-types/new-table'



const initialState = {
    type: actions.NEW_TABLE_INIT,
    newTableData: undefined,
    newTableSaveData: undefined,
    dimensionByEntity: undefined,
    hide_popus_iq: false,
    dimensionByHierarchy: undefined,
};


const NewTableReducer = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }


    switch (action.type) {
        case actions.NEW_TABLE_RESULT:
            return {
                ...state,
                type: action.type,
                newTableData: action.data.results
            };
        case actions.NEW_TABLE_SAVE:
            return {
                ...state,
                type: action.type,
                newTableSaveData: action.data
            };
        case actions.GET_DIMENSION_BY_ENTITY:
            return {
                ...state,
                type: action.type,
                dimensionByEntity: action.data ? action.data.results : action.data
            };
        case actions.HIDE_POPUS_IQ:
            return {
                ...state,
                type: action.type,
                hide_popus_iq: action.data
            };
        
        case actions.GET_DATA_HIERARCHY:
            return {
                ...state,
                type: action.type,
                dimensionByHierarchy: action.data
            }

        default:
            return {
                type: actions.NEW_TABLE_INIT,
                newTableData: state.newTableData,
                newTableSaveData: state.newTableSaveData,
                dimensionByEntity: state.dimensionByEntity,
                hide_popus_iq: state.hide_popus_iq,
                dimensionByHierarchy: state.dimensionByHierarchy
            }
    }
}

export default NewTableReducer;