import * as actions from '../action-types/formulas';

const initialState = {
    type: actions.FORMULAS_INIT
};


const formulasReducer = (state, action) => {
	if (!state || typeof action.type === 'undefined') {
		return initialState;
    }


    switch (action.type) {
        case actions.SAVE_FORMULAS:
            return {
                ...state,
                type: action.type
            };
        
        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
			return {
                ...state
            };
        }
};

export default formulasReducer;