import React from 'react';

const Txt = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}

            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 93 100"
            space="preserve">

            <g>
                <path id="svg_1" d="m91.907,29.835l-28.381,-28.385c-0.288,-0.287 -0.676,-0.45 -1.082,-0.45l-32.148,0c-7.482,0 -13.569,6.089 -13.569,13.571l0,26.856l-11.91,0c-2.301,0 -4.173,1.872 -4.173,4.173l0,23.987c0,2.301 1.872,4.173 4.173,4.173l11.909,0l0,11.67c0.001,7.481 6.087,13.57 13.57,13.57l48.491,0c7.482,0 13.569,-6.089 13.569,-13.571l0,-54.51c-0.001,-0.406 -0.162,-0.797 -0.449,-1.084zm-75.446,21.082c-0.846,0 -1.529,-0.685 -1.529,-1.529c0,-0.846 0.683,-1.531 1.529,-1.531l9.869,0c0.846,0 1.531,0.685 1.531,1.531c0,0.844 -0.685,1.529 -1.531,1.529l-3.401,0l0,14.888c0,0.846 -0.685,1.531 -1.529,1.531c-0.846,0 -1.531,-0.685 -1.531,-1.531l0,-14.888l-3.408,0zm72.834,34.512c0,5.796 -4.714,10.51 -10.508,10.51l-48.491,0c-5.794,0 -10.508,-4.714 -10.508,-10.51l0,-11.67l50.415,0c2.301,0 4.173,-1.872 4.173,-4.173l0,-23.986c0,-2.301 -1.872,-4.173 -4.173,-4.173l-50.416,0l0,-26.856c0,-5.796 4.714,-10.51 10.508,-10.51l30.617,0l0,23.792c0,2.534 2.06,4.596 4.59,4.596l23.792,0l0,52.98l0.001,0zm-58.025,-35.255c-0.436,-0.724 -0.201,-1.663 0.523,-2.1c0.725,-0.433 1.665,-0.199 2.1,0.524l3.619,6.025l3.621,-6.025c0.434,-0.724 1.374,-0.957 2.1,-0.524c0.724,0.436 0.959,1.376 0.523,2.1l-4.458,7.423l4.458,7.421c0.436,0.724 0.201,1.663 -0.523,2.1c-0.247,0.147 -0.519,0.218 -0.787,0.218c-0.521,0 -1.026,-0.264 -1.313,-0.743l-3.621,-6.025l-3.619,6.025c-0.287,0.479 -0.794,0.743 -1.313,0.743c-0.268,0 -0.54,-0.071 -0.787,-0.218c-0.723,-0.436 -0.959,-1.376 -0.523,-2.1l4.458,-7.421l-4.458,-7.423zm17.425,0.743c-0.846,0 -1.531,-0.685 -1.531,-1.529c0,-0.846 0.685,-1.531 1.531,-1.531l9.863,0c0.844,0 1.529,0.685 1.529,1.531c0,0.844 -0.685,1.529 -1.529,1.529l-3.401,0l0,14.888c0,0.846 -0.685,1.531 -1.531,1.531c-0.844,0 -1.529,-0.685 -1.529,-1.531l0,-14.888l-3.402,0z" />
                <text id="svg_2" font-family="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif" font-weight="bold" font-size="5px" fill="#000000" y="115" x="0">Created by kliwir art</text>
                <text id="svg_3" font-family="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif" font-weight="bold" font-size="5px" fill="#000000" y="119.66667" x="0">from the Noun Project</text>
            </g>
        </svg>

    )

}


export default Txt;