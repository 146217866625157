import * as actions from '../action-types/directory_upload'


const initialState = {

    type: actions.DIRECTORY_UPLOAD_INIT,
    directory_files: undefined,

};


const DirectoryUpload = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }


    switch (action.type) {

        case actions.SCAN_DIRECTORY_AND_GET_FILES:

            return {
                ...state,
                type: action.type,
                directory_files: action.data
            };

        case actions.CLEAR_DIRECTORY_FILES:

            return {
                ...state,
                type: action.type,
                directory_files: undefined
            }


        default:
            return {
                ...state,
            }
    }
}

export default DirectoryUpload;