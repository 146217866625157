import React from 'react'
import styled, { keyframes } from 'styled-components';
import VoiceSvg from '../svg/speak'
import SearchSvg from '../svg/search'
import { constants } from '../../utils/constants';



const glow = keyframes`
    0% {
      text-shadow: 0 0;
    }
    75% {
      text-shadow: 0 0 16px;
      filter: brightness(300%);
    }
  `;

const Wrapper = styled.div`
    width: 100%;
    height: 80vh;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;


    .logo{
        color: #308bc8;
        background: -webkit-linear-gradient(#308bc838,#308bc8);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 4.5rem;
        margin-bottom: 20px;
        text-align: center;
        font-weight: 600;
        /* text-shadow: 2px 3px 5px #333d4254; */
        /* &:before {
            content: attr(data-text);
            position: absolute;
            mix-blend-mode: difference;
            filter: blur(3px);
        }
        &:after{
            content: "";
            background: linear-gradient(45deg, #7b4397 30%, #dc2430 70%);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            mix-blend-mode: multiply;
        } */
    }


    .bar{
        margin: 0 auto;
        width: 45%;
        border: 1px solid #dfe1e5;
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
    }


    .bar:hover{
         box-shadow: 0 1px 6px rgba(32,33,36,.28);
    }


    .bar:focus-within{
        box-shadow: 0 1px 6px rgba(32,33,36,.28);
        outline:none;
    }


    .searchbar{
        height:50px;
        border:none;
        width:100%;
        font-size:16px;
        padding: 0px 20px;
        outline: none;
        padding-left: 50px;
        padding-right: 50px;
        font-family: "Fira Sans", sans-serif;
        
    }
    .voice{
        position: absolute;
        top: 0px;
        right: 17px;
        bottom: 0px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
     }

     .search{
        position: absolute;
        top: 0px;
        left: 17px;
        bottom: 0px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
       
     }

     .bottom_row{
         margin-top: 20px;
         width: 100%;
         display: flex;
         align-items: center;
         justify-content: center;
         flex-direction: row;
     }

     .polus_search{
        background-image: -webkit-linear-gradient(top,#f8f9fa,#f8f9fa);
        background-color: #f8f9fa;
        border: 1px solid #f8f9fa;
        border-radius: 4px;
        color: #3c4043;
        font-size: 14px;
        margin: 11px 4px;
        padding: 0 16px;
        line-height: 27px;
        height: 36px;
        min-width: 54px;
        text-align: center;
        cursor: pointer;
        user-select: none;
        font-family: "Fira Sans", sans-serif;

        &:hover{
            box-shadow: 0 1px 1px rgba(0,0,0,0.1);
            background-image: -webkit-linear-gradient(top,#f8f9fa,#f8f9fa);
            background-color: #f8f9fa;
            border: 1px solid #dadce0;
            color: #202124;
        }
        &:focus{
            box-shadow: 0 1px 1px rgba(0,0,0,0.1);
            background-image: -webkit-linear-gradient(top,#f8f9fa,#f8f9fa);
            background-color: #f8f9fa;
            border: 1px solid #dadce0;
            color: #202124;
            outline: none;
        }
     }

`;

const Index = (props) => {
    return (
        <Wrapper>

            <div className="logo">
                P<span>O</span><span>L</span><span>U</span>S

            </div>
            <div className="bar">
                <input className="searchbar" type="text" title="Search" />

                <div className="search">
                    <SearchSvg color="#9aa0a6" size="1rem" height="1rem" />
                </div>
                <div className={"voice"} title="search by voice">
                    <VoiceSvg color="#008ac7" size="1.357rem" height="1.357rem" />
                </div>
            </div>

            <div className="bottom_row">
                <button className="polus_search">Polus Search</button>
            </div>

        </Wrapper>
    )
}

export default Index
