import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import TitleHeader from '../title-header'
import { PageInnerWrapper } from '../hoc/styled.hoc';
import { withRouter } from 'react-router-dom';
import { Button } from '../hoc/button';
import SelectDisplayField from '../select-display-field';
import * as enums from '../../utils/enums';
import { showPopup, insertAtSpecificLocationInArray, getParamByName, convertDateInToHtmlDate } from '../../utils';
import { DivRow } from '../styled-hoc';
import { DataSynchUpEditorWrapper } from './data_sync_up.styled';
import * as _ from 'lodash';
import RippleEffect from '../hoc/ripple-effect'
import EditSvg from '../svg/edit';
import TextBox from '../hoc/textBox';
import FrequencySelector from '../frequency-selector';
import SelectOption from '../select-option'
import { getTablesByClientId, getColumnsByTableName } from '../../actions/data-sync-up';
import { getTargetTableNames, saveEtlConfig, getConfigInfo, clearJobInformation } from '../../actions/etl'
import { get_db_info } from '../../actions/connection';
import AutoSearch from '../auto.search';
import LeftArrow from '../svg/leftArrow';



const DataSyncUpEditor = (props) => {


    const { tableLists, columnLists, getTargetTableNames, get_db_info, db_infos, targetTableNames, history, location, getConfigInfo, currentConfigInfo, clearJobInformation } = props;

    const [columnSelected, setColumnSelected] = useState(undefined);
    const [formData, setFormData] = useState(undefined);
    const [frequencys, setFrequencys] = useState([{ type: 'e' }, { type: 't' }, { type: 'l' }]);
    const [configId, setConfigId] = useState(undefined);

    const [localTableDateResult, setTableDateResult] = useState(undefined)


    useEffect(() => {
        props.getTablesByClientId()
        get_db_info()
    }, [])

    useEffect(() => {
        let job_id = getParamByName(location.search, 'config_id');
        if (job_id && job_id !== undefined && job_id !== null) {
            setConfigId(job_id)
            getConfigInfo(job_id)
        }
    }, [location])



    useEffect(() => {
        if (currentConfigInfo && Object.keys(currentConfigInfo).length > 0) {
            let frequency = currentConfigInfo.frequency;
            let final_frequency = [];
            frequency && frequency.length > 0 && frequency.map((f) => {
                let _clone_object = f ? Object.assign({}, f) : {}
                if (_clone_object.frequency_type === 'minutes') {
                    _clone_object.value = f.minutes
                }
                if (_clone_object.frequency_type === 'hours') {
                    _clone_object.value = f.hours
                }
                final_frequency.push(_clone_object)
            })
            setFrequencys(final_frequency)

            if (currentConfigInfo.target_schema) {
                getTargetTableNames(currentConfigInfo.target_schema)
            }
            if (currentConfigInfo.load_config && currentConfigInfo.load_config.length > 0) {
                let _columns = [];
                let s_tbl_name = undefined;
                currentConfigInfo.load_config.map((c) => {
                    _columns.push(c.source_column)
                    s_tbl_name = c.source_table
                })
                setColumnSelected(_columns)
                currentConfigInfo.source_table = s_tbl_name;
                props.getColumnsByTableName(s_tbl_name)

            }



            setFormData(currentConfigInfo)
        } else {
            // setFormData({})
        }

    }, [currentConfigInfo, location])




    const _open_add_edit_popup = (menus, displayItem, selectedFields, title, setSelectedFields, titleOnHover, titleHeading, smallPopup) => {
        showPopup(undefined, undefined, enums.default.popupType.element, SelectDisplayField, {
            menus: menus,
            displayItem: displayItem,
            selectedFields: selectedFields ? selectedFields : [],
            title: title,
            setSelectedFields: setSelectedFields,
            titleOnHover: titleOnHover,
            titleHeading: titleHeading,
            smallPopup: smallPopup ? smallPopup : undefined
        })
    };





    const onDropGroupedColumns = event => {
        const index = event.dataTransfer.getData("columns");
        let currentIndex = undefined;
        let tempFilteredItems = [...columnSelected]
        const value = event.currentTarget.textContent;
        for (let i = 0; i < tempFilteredItems.length; i++) {
            if (tempFilteredItems[i] === value) {
                currentIndex = i;
                break;
            }
        }
        const deletedItem = _.pullAt(tempFilteredItems, index);
        tempFilteredItems = insertAtSpecificLocationInArray(tempFilteredItems, currentIndex, deletedItem[0]);
        setColumnSelected(tempFilteredItems);

    };


    const inputOnChange = (key, value) => {
        let cloneData = formData ? Object.assign({}, formData) : {};
        cloneData[key] = value;
        setFormData(cloneData)
    }



    const onFrequencyChangeFunction = (value, type, value_type, _id, one_time) => {
        let clone_frequency = frequencys && JSON.parse(JSON.stringify(frequencys));
        clone_frequency && clone_frequency.length > 0 && clone_frequency.map((frequency, i) => {
            if (frequency.type === type) {
                frequency[value_type] = value;
                // frequencyValidate(type, value_type, value)
            }
            else {
                if (one_time) {
                    frequency.frequency_type = 'reactive'
                }
            }
        })

        setFrequencys(clone_frequency)
    }



    const localSaveAction = () => {

        let final_load_config_data = [];
        let target_table_name = formData.target_table_name;

        columnSelected && columnSelected.length > 0 && columnSelected.map((column) => {
            let load_config_obj = {
                source_column: column,
                target_table: target_table_name,
                source_table: formData.source_table,
                target_column: column,
                database_id: formData && formData['target_schema']
            }
            final_load_config_data.push(load_config_obj)
        })


        currentConfigInfo && currentConfigInfo.load_config && currentConfigInfo.load_config.length > 0 && currentConfigInfo.load_config.map((config) => {
            for (let index = 0; index < final_load_config_data.length; index++) {
                const element = final_load_config_data[index];
                if (element.source_column === config.source_column) {
                    element.id = config.id;
                    break;
                }
                else {
                    let temp_obj = Object.assign({}, config);
                    temp_obj.deleted = true;
                    final_load_config_data.push(temp_obj)
                    break;
                }
            }
        })




        let final_frequency = [];
        frequencys && frequencys.length > 0 && frequencys.map((f) => {

            let _clone_object = f ? Object.assign({}, f) : {}
            if (_clone_object.frequency_type === 'minutes') {
                _clone_object.minutes = f.value
            }
            if (_clone_object.frequency_type === 'hours') {
                _clone_object.hours = f.value
            }
            final_frequency.push(_clone_object)
        })


        let save_data = {
            name: formData.name,
            config_type: 'bkp',
            // entity: formData.entity,
            // source_columns: selectedColumn,
            criteria: formData.criteria,
            frequency: final_frequency,
            source_table: formData.source_table,
            target_table_name: formData.target_table_name,
            // question: formData.question,
            // next_run_at: formData.next_run_at,
            // job_start_date: formData.job_start_date,
            // job_end_date: formData.job_end_date,
            // job_period: formData.job_period,
            // job_period_type: formData.job_period_type,
            config_id: configId ? configId : undefined,

            database_id: formData && formData['target_schema'],
            load_config: final_load_config_data,
            transformationType: 'Append',
            is_target_table_new: (targetTableNames && targetTableNames.indexOf(target_table_name) > -1) ? false : true,
            // primaryKey: primaryKeyValue,
            // e_type: formData.e_type,
            // main_directory: formData.main_directory,
            // processed_directory: formData.processed_directory,
            // rename_prefix: formData.rename_prefix,
            // extension: formData.extension,
            // landing_date: formData.landing_date,
            // _table_date_result: formData._table_date_result,
            is_backup_and_sync: 1
        };

        let save_message = "Config Saved SuccessFully";
        props.saveEtlConfig(save_data, save_message, 1)
        setTimeout(() => {
            props.clearJobInformation()
            props.history.push('/data_sync_up')
        }, 200)
    }



    return (
        <DataSynchUpEditorWrapper>
            <TitleHeader
                title={'Data Sync Up Editor'}
                extraOption={
                    <RippleEffect
                        title="Back"
                        icon={<LeftArrow size='1.5rem' height='1.4rem' />}
                        Click={() => {
                            if (props.history && props.history.goBack) {
                                props.history.goBack();
                                props.clearJobInformation()
                            }
                        }}
                    />
                }

            />
            <PageInnerWrapper >
                <div style={{ maxWidth: '80%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '2.5rem' }}>
                        <DivRow w="40%">
                            <SelectOption
                                label={"Source DB"}
                                selections={db_infos && db_infos.length > 0 ? db_infos : []}
                                onChangeFunc={(value) => {
                                    inputOnChange('target_schema', value)
                                    getTargetTableNames(value)
                                }}
                                displayKey={"connection_name"}
                                valueKey={"id"}
                                groupKey='db_type'
                                sortKey='desc'
                                activeValue={formData && formData['target_schema'] ? formData['target_schema'] : undefined}
                                isCheckBox={true}
                                isSearch={true}
                                width="100%"
                                height="10.857rem"
                                lineHeight="1.857rem"
                                borderColor='#eaeaea'
                                color='#7c7c7c'
                                addAction={() => {
                                    props.history.push('/connection')
                                }}
                            />
                        </DivRow>



                        <DivRow w="40%">
                            <SelectOption
                                width={"100%"}
                                label="Source Table Name"
                                selections={tableLists && tableLists.length > 0 ? tableLists : []}
                                activeValue={formData && formData['source_table']}
                                onChangeFunc={(value) => {
                                    inputOnChange('source_table', value)
                                    setColumnSelected(undefined)
                                    props.getColumnsByTableName(value)
                                }}
                            />
                        </DivRow>
                    </div>


                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '2.5rem' }}>
                        <DivRow w="40%">
                            <TextBox
                                type="text"
                                label={"Criteria"}
                                value={formData && formData['criteria']}
                                onChange={(e) => inputOnChange('criteria', e.target.value)}
                            />
                            <span style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: '.7rem' }} className="example">Example:- client name is hyundai</span>
                        </DivRow>

                    </div>



                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '2.5rem' }}>
                        <DivRow w="100%">
                            <div
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
                            ><span style={{ marginRight: '10px', fontSize: '1rem', color: '#0000008a', textTransform: 'capitalize' }}>Particular Columns Selection </span>
                                {columnSelected && columnSelected.length > 0 && (
                                    <RippleEffect
                                        icon={<EditSvg size=".9rem" height=".9rem" color="#222" />}
                                        title="edit"
                                        Click={() => {
                                            let _title = "Select Columns"
                                            let _title_on_hover = undefined
                                            if (columnLists && columnLists.length > 0) {
                                                _open_add_edit_popup(props.menus, columnLists, columnSelected, _title, setColumnSelected, _title_on_hover, "Table")
                                            }
                                        }}
                                    />

                                )}
                            </div>

                            {(!columnSelected || (columnSelected && columnSelected.length === 0)) && (
                                <div className="add_btn_new_field" onClick={() => {
                                    let _title = "Select Columns"
                                    let _title_on_hover = undefined;
                                    if (columnLists && columnLists.length > 0) {
                                        _open_add_edit_popup(props.menus, columnLists, columnSelected, _title, setColumnSelected, _title_on_hover, "Table")
                                    }
                                }}>+&nbsp;Pick Columns</div>

                            )}



                            {columnSelected && columnSelected.length > 0 &&
                                <div style={{
                                    width: '100%',
                                    maxHeight: '15.071rem',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    borderBottom: '1px solid #ccc'
                                }}
                                    onDragOver={(event) => event.preventDefault()}
                                >
                                    <ul className="fields_item_ul">
                                        {columnSelected.map((item, index) => {
                                            return (
                                                <li style={{
                                                    fontSize: '1rem',
                                                    lineHeight: '1.714rem',
                                                    marginRight: '3rem',
                                                    minWidth: '10.786rem',
                                                }}
                                                    draggable={true}
                                                    onDragStart={(event) => {
                                                        event.dataTransfer.setData("columns", index);
                                                        event.dataTransfer.effectAllowed = "move"
                                                    }}
                                                    onDrop={(event) => onDropGroupedColumns(event)}
                                                    key={'gpd' + index}><span>{item}</span></li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            }
                        </DivRow>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '2.5rem' }}>
                        <DivRow w="40%" >
                            <SelectOption
                                label={"Target DB"}
                                selections={db_infos && db_infos.length > 0 ? db_infos : []}
                                onChangeFunc={(value) => {
                                    inputOnChange('target_schema', value)
                                    // inputOnChange('target_table_name', '')
                                    getTargetTableNames(value)
                                }}
                                displayKey={"connection_name"}
                                valueKey={"id"}
                                groupKey='db_type'
                                sortKey='desc'
                                activeValue={formData && formData['target_schema'] ? formData['target_schema'] : undefined}
                                isCheckBox={true}
                                isSearch={true}
                                width="100%"
                                height="10.857rem"
                                lineHeight="1.857rem"
                                borderColor='#eaeaea'
                                color='#7c7c7c'
                                addAction={() => {
                                    props.history.push('/connection')
                                }}
                            />
                        </DivRow>
                        <DivRow w="40%">
                            <AutoSearch
                                width={"100%"}
                                label="Select Target Table "
                                selections={targetTableNames && targetTableNames.length > 0 ? targetTableNames : []}
                                activeValue={formData && formData['target_table_name']}
                                setActiveValue={(value) => {
                                    inputOnChange('target_table_name', value)
                                }}
                                onItemClick={(value) => {
                                    inputOnChange('target_table_name', value)
                                }}
                            />
                        </DivRow>

                    </div>



                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '2.5rem' }}>
                        <DivRow w="30%">
                            <DivRow w="100%" style={{ marginBottom: '.1rem' }}>
                                <label style={{ fontSize: '.8rem', color: '#555', fontWeight: 500 }}>Job Frequency</label>
                            </DivRow>
                            <DivRow w="100%">
                                <fieldset className="field_set">
                                    <FrequencySelector
                                        frequency={frequencys && frequencys.length > 0 ? frequencys[0] : undefined}
                                        onChange={(value, type, element_type, _id) => {
                                            onFrequencyChangeFunction(value, type, element_type, _id, true)
                                        }}
                                    />
                                </fieldset>
                            </DivRow>
                        </DivRow>
                        <DivRow w="40%">
                            <TextBox
                                type="text"
                                label={"Job Name"}
                                value={formData && formData['name']}
                                onChange={(e) => inputOnChange('name', e.target.value)}
                            />
                        </DivRow>

                    </div>


                    <React.Fragment>
                        <div style={{ marginBottom: '.5rem' }}>
                            <DivRow w="100%">
                                <label style={{ fontSize: '1.1rem', color: '#555', fontWeight: 500 }}>Please provide information for Incremental Data Movement</label>
                            </DivRow>
                        </div>

                        <div className="flex_row" style={{ marginBottom: '2rem', marginTop: '.6rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <DivRow w="23%">
                                <SelectOption
                                    label={"Select Incremental Date Column"}
                                    selections={localTableDateResult ? localTableDateResult : []}
                                    onChangeFunc={(value) => {
                                        inputOnChange('_table_date_result', value)
                                    }}
                                    activeValue={formData && formData._table_date_result ? formData._table_date_result : undefined}
                                    width="100%"
                                    height="10.857rem"
                                    lineHeight="1.857rem"
                                    borderColor='#eaeaea'
                                    color='#7c7c7c'
                                    hideCase={true}
                                />
                            </DivRow>

                            <DivRow w="23%">
                                <TextBox
                                    type="datetime-local"
                                    label="Incremental Start Date"
                                    value={formData && formData.job_start_date ? convertDateInToHtmlDate(formData.job_start_date, 'start_date') : undefined}
                                    onChange={(e) => {
                                        inputOnChange('job_start_date', e.target.value)
                                    }}

                                />
                            </DivRow>
                            <DivRow w="23%">
                                <TextBox
                                    type="datetime-local"
                                    label="Incremental End Date"
                                    value={formData && formData.job_end_date ? convertDateInToHtmlDate(formData.job_end_date, 'end_date') : undefined}
                                    onChange={(e) => {
                                        inputOnChange('job_end_date', e.target.value)

                                    }}
                                />
                            </DivRow>

                            <DivRow w="23%">
                                <label style={{ fontSize: '.8rem', color: '#555', }}>Incremental Frequency</label>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: '.3rem' }}>
                                    <span style={{ padding: '.4rem 1rem', textTransform: 'uppercase', fontWeight: '500', backgroundColor: '#eaeaeacc', fontSize: '.8rem', borderRadius: '3px', }}>Every</span>
                                    <div style={{ width: '25%' }}>
                                        <input
                                            value={formData && formData.job_period ? formData.job_period : undefined}
                                            style={{ width: '100%', height: '1.9rem', fontSize: '1rem', padding: '.8rem', boxSizing: 'border-box', color: '#000' }}
                                            type="text" placeholder="Value"
                                            onChange={(e) => {
                                                inputOnChange('job_period', e.target.value)

                                            }}
                                        />
                                    </div>

                                    <div style={{ width: '40%', }}>
                                        <select
                                            value={formData && formData.job_period_type ? formData.job_period_type : undefined}
                                            style={{ width: '100%', height: '1.9rem', borderRadius: '2px', backgroundColor: '#fff' }}
                                            onChange={(e) => {
                                                inputOnChange('job_period_type', e.target.value)

                                            }}>
                                            <option value="">--Select Type--</option>
                                            <option value="minutes">Minutes</option>
                                            <option value="hours">Hours</option>
                                            <option value="days">Days</option>
                                            <option value="week">Week</option>
                                            <option value="month">Month</option>

                                        </select>
                                    </div>
                                </div>

                            </DivRow>
                        </div>
                    </React.Fragment>

                </div>

                <div
                    style={{
                        padding: '2.5rem 0px',
                        borderTop: '1px solid #ccc',
                        marginTop: 'auto',
                        display: 'flex',
                        width: '90%'
                    }}>

                    <Button
                        primary={true}
                        mR="1.143rem"
                        onClick={() => {
                            localSaveAction()
                            // preSave(false)
                        }}
                    >Save</Button>

                    <Button
                        bgColor={'#e6e6e6'}
                        onClick={() => {
                            if (props.history && props.history.goBack) {
                                props.history.goBack();
                                props.clearJobInformation()
                            }
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </PageInnerWrapper>
        </DataSynchUpEditorWrapper>
    )
}


const mapStateToProps = (state) => ({
    menu: state.menuGroupReducer.menu,
    tableLists: state.dataSyncUpReducer.tableLists,
    columnLists: state.dataSyncUpReducer.columnLists,
    db_infos: state.connectionReducer.db_infos,
    targetTableNames: state.EtlReducer.targetTableNames,
    currentConfigInfo: state.EtlReducer.currentConfigInfo,

})


export default withRouter(connect(mapStateToProps, { getTablesByClientId, getColumnsByTableName, getTargetTableNames, get_db_info, saveEtlConfig, getConfigInfo, clearJobInformation })(DataSyncUpEditor))
