import React from 'react';

const Search = (props) => {

    return (
        <svg width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 447 447"
            space="preserve" >
            <path d="M440.9,411.2L311.5,281.8c23.3-29.6,37.2-67,37.2-107.5C348.7,78.2,270.5,0,174.3,0S0,78.2,0,174.3
	c0,96.1,78.2,174.3,174.3,174.3c40.5,0,77.9-13.9,107.5-37.2l129.4,129.4c4.1,4.1,9.5,6.1,14.8,6.1s10.7-2,14.8-6.1
	C449,432.7,449,419.4,440.9,411.2z M41.9,174.3c0-73,59.4-132.4,132.4-132.4s132.4,59.4,132.4,132.4c0,35.3-13.9,67.5-36.6,91.3
	c-0.9,0.6-1.7,1.3-2.5,2.1s-1.5,1.6-2.1,2.5c-23.8,22.6-55.9,36.6-91.3,36.6C101.3,306.7,41.9,247.3,41.9,174.3z"/>
        </svg>

    )

}


export default Search;