


export const add_identifier_filters_to_report = (filters) => {
    const return_me = {};
    let found_once = false;
    const clients = ['clients', 'client', 'client name', 'advertiser', 'advertisers', 'advertiser name']
    const campaigns = ['campaign', 'campaigns', 'campaign name']
    

    Object.keys(filters).forEach(key => {
        const filter = filters[key];
        const filter_name = key.toLocaleLowerCase();

        if (clients.indexOf(filter_name) > -1) {
            found_once = true;
            return_me['client'] = filter.map(f => f.toLocaleLowerCase())
        }
        else if (campaigns.indexOf(filter_name) > -1) {
            found_once = true;
            return_me['campaign'] = filter.map(f => f.toLocaleLowerCase())
        }
    });

    return return_me;
};



