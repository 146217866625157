import React from 'react';
import styled from 'styled-components';

const Tooltip = styled.div`
  position: relative;
  display: inline-block;
  cursor: help;

  .tooltip-text {
    visibility: hidden;
    /* width: ${props => props.width || '120px'}; */
    background: ${props => props.bgColor || "#fff"};
    color: #fff;
    font-weight: 400;
    font-size: 0.8rem;
    text-align: center;
    border-radius: 6px;
    padding: 8px;
    position: absolute;
    z-index: 1;
   // bottom: ${props => props.positionBottom || '135%'}; /* Position the tooltip above the target element */
    /* left: 50%; */
    /* left: ${props => props.positionLeft || '-150%'}; */
    //margin-left: -60px; /* Center the tooltip */
    opacity: 1;
    transition: opacity 0.3s;
    white-space: nowrap;
    left: -50%;
    bottom: 107%;

  }

  .tooltip-text::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${props => props.bgColor || "#fff"} transparent transparent transparent;
  }

  &:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
`;

const ToolTip = ({ text, children, width, bgColor, direction, positionLeft, positionBottom }) => (
  <Tooltip width={width} bgColor={bgColor} direction={direction} positionLeft={positionLeft} positionBottom={positionBottom}>
    {children}
    <span className="tooltip-text">{text}</span>
  </Tooltip>
);

export default ToolTip;
