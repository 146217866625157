import React from "react";

const BarChartTrending = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      shape-rendering="geometricPrecision"
      text-rendering="geometricPrecision"
      image-rendering="optimizeQuality"
      fill-rule="evenodd"
      clip-rule="evenodd"
      viewBox="0 0 1064 1384"
    >
      <path
        transform="translate(877,314)"
        d="m0 0h183l1 1v1064l-1 1h-178l-5-1z"
        fill="#687CFE"
      />
      <path
        transform="translate(585,495)"
        d="m0 0h166l1 1v882l-90 1h-74l-3-1z"
        fill="#687CFE"
      />
      <path
        transform="translate(292,607)"
        d="m0 0h180l1 1v771l-4 1h-174l-3-1z"
        fill="#687CFE"
      />
      <path
        transform="translate(0,704)"
        d="m0 0h174l1 1v674l-87 1h-88z"
        fill="#687CFE"
      />
      <path
        transform="translate(877,314)"
        d="m0 0h183l1 1v1064l-1 1h-178l-5-1zm116 24-63 2-19 2-5 4-1 28-1 958 1 11 3 7 5 3 7 2 8 1h81l10-2v-2l3 1v-2l4-1 5-4 4-11 2-13v-892l-1-33-2-28-2-12-6-11-3-3-9-3z"
        fill="#2E96FD"
      />
      <path
        transform="translate(811)"
        d="m0 0 7 1 127 23 66 12 1 5-33 129-12 46-5 19-9-1-24-6-1-1 1-9 26-101 7-25-1-1-21 13-50 32-17 11-19 12-17 11-22 14-17 11-25 16-17 11-19 12-17 11-22 14-17 11-19 12-17 11-22 14-23 15-19 12-5 3-4-2-9-10-11-12-7-8-12-13-9-10-11-12-7-8-12-13-11-12-7-8-11-12-2-2-5 1-24 14-22 12-23 13-25 14-21 12-23 13-25 14-21 12-25 14-23 13-28 16-27 15-21 12-25 14-23 13-25 14-23 13-16 9-4-2-10-18-4-6 1-4 10-6 23-13 21-12 23-13 25-14 138-78 25-14 28-16 18-10 16-9 28-16 18-10 69-39 7-3 15 16 18 20 11 12 18 20 11 12 18 20 11 12 8 9 5-1 15-10 19-12 17-11 22-14 17-11 22-14 17-11 22-14 17-11 47-30 17-11 22-14 17-11 19-12 25-16 20-13 21-13 1-1-12-3-120-22-6-2 2-12 4-21z"
        fill="#2E96FD"
      />
      <path
        transform="translate(292,607)"
        d="m0 0h180l1 1v771l-4 1h-174l-3-1zm111 25-50 1-19 1-11 2-2 2-1 39-1 662 2 9 6 5 9 2 14 1h75l15-3 5-6 3-11 2-15v-42l-1-12-1-88 1-483-2-50-3-6-1-4-4-3-17-1z"
        fill="#2E96FD"
      />
      <path
        transform="translate(585,495)"
        d="m0 0h166l1 1v882l-90 1h-74l-3-1zm86 22-32 1-25 4-2 2-1 57-1 738 1 26 3 7 4 2 11 2 18 1h52l20-2 5-3 6-9 3-11 1-10-1-7-1-2-1-699-1-51-1-17-3-15-6-8-9-5-23-1z"
        fill="#3197FD"
      />
      <path
        transform="translate(0,704)"
        d="m0 0h174l1 1v674l-87 1h-88zm48 25-18 2-2 1-1 23-1 52v486l1 54 2 6 6 2 32 2h50l23-2 5-3 4-6 4-20v-32h-2l1-21-1-76v-433l-2-21-3-10-1-3-3-1z"
        fill="#2E95FD"
      />
    </svg>
  );
};

export default BarChartTrending;
