import React, { useState, useEffect } from 'react';
import { DasboardWrapper, DashboardCardFlex, TableHeaderWrapper } from './dashboard.list.styled';
import { connect } from 'react-redux';
import { getInsightsListByUser, saveInsight, } from '../../actions/insights';
import { clone_report, delete_report, get_all_reports } from '../../actions/report.helper';
import { constants } from '../../utils/constants';
import { deleteReportFormat, reportFormatToSave, makeCopyReport, getParameterByName, sort_report_list, get_report_in_list_formatted_mode, getPermissionById, debounceWrapper } from '../../utils';
import { setActiveEditLayout } from '../../actions/layout';
import CardAction from '../action-card';
import { dispatchHeaderInformation } from '../../actions/common';
import DashboardListHeader from './header';
import { PageInnerWrapper } from '../hoc/styled.hoc';
import SharedTable from '../shared-table';
import styled from 'styled-components';
import NewAddSvg from '../svg/add.circle'
import { getFromSession, saveInSession } from '../../utils/session.helper';
import { COMPONENT_IDS } from '../../utils/elementids';
import { getMenuGroups } from '../../actions/menu.group'
import AddIcon from '../svg/v2-icons/AddCircle';
import SearchBar from '../hoc/SearchBar';
import RippleEffect from '../hoc/ripple-effect';
import ToggleIcon from '../svg/v2-icons/toggle';
import PieChart from '../svg/pieChart';
import Tab from '../tab';
import TrendingChartIcon from '../svg/icons-v2/trendingChart';
import PieChartSliceIcon from '../svg/icons-v2/pieChartSlice';
import PieChartDashedIcon from '../svg/icons-v2/pieChartDashed';
import BarChartTrending from '../svg/icons-v2/barTrendingChart';
import DashboardListSkeleton from '../Skeleton/dashoarad-skeleton-grid';

const getIconFn = (index) => {
    let icon = '';
    if (index % 4 === 0) {
        icon = <PieChartDashedIcon size={"3rem"} height="3rem" color={'#337DFF'} />;
    } else if (index % 3 === 0) {
        icon = <BarChartTrending size={"3rem"} height="3rem" color={'#337DFF'} />;
    } else if (index % 2 === 0) {
        icon = <PieChartSliceIcon size={"3rem"} height="3rem" color={'#337DFF'} />;
    } else {
        icon = <TrendingChartIcon size={"3rem"} height="3rem" color={'#337DFF'} />;
    };
    return icon;
};

const tabList = [
    "All",
    "Other",
    "Files",
    "Production",
];

const AddButton = styled.div`
    width: 3rem;
    height: 3rem;
    font-size: 2rem;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #337DFF;
    cursor: pointer;
    box-shadow: 2px 3px 4px #cfc0c0;
    svg{
        fill: #fff;
    }
`;


const static_types = {
    "maps": {
        title: "YOUR MAPS",
        addTitle: 'Build New Map'
    },

    "insights": {
        title: "YOUR DASHBOARDS",
        addTitle: 'Build New Dashboard'
    }
}

const Index = (props) => {

    const { insightsListData, user, all_reports, menuPermissions } = props;
    const { history } = props;
    const { location } = history;
    const searchColumns = ['title'];

    const [insightLists, setInsightLists] = useState(undefined);
    const [report_list_to_use, set_report_list_to_use] = useState(undefined);

    const [whichInsightType, setWhichInsightType] = useState('insights')
    const [view_report_type, set_view_report_type] = useState('grid')

    const [searchValue, setSearchValue] = useState(undefined);
    const [isLoding, setIsloding] = useState(true);

    let isGridView = view_report_type === 'grid';

    useEffect(() => {
        if (!insightLists) return;
        filterListDataOnSearch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue])

    const filterListDataOnSearch = debounceWrapper(() => {
        if (!insightLists?.length) return;
        let filteredData = insightLists?.filter(i => (!searchValue ? true : i[searchColumns?.[0]]?.toLowerCase().includes(searchValue?.toLowerCase())));
        set_report_list_to_use([...filteredData])
    },300);

    useEffect(() => {
        if (!all_reports) return;
        // let's add menu group name for each report
        const cloned_all_report = all_reports ? JSON.parse(JSON.stringify(all_reports)) : [];
        const get_menu_name_by_id = (menu_id) => {
            const m = props.menu_group?.find(m => m.id === menu_id);
            if (m && m.name) {
                return m.name;
            } else {
                return "other"
            }
        }

        cloned_all_report.forEach((report) => {
            const menu_id = report.menu_group_id ? report.menu_group_id : 'nfx__other';
            const menu_name = get_menu_name_by_id(menu_id)
            report.menu_group_name = menu_name;
        })
        
        const insightType = getParameterByName("type", location.search)
        let formattedList = get_report_in_list_formatted_mode(cloned_all_report?.filter(item => item.type === insightType))
        let sortedList = sort_report_list( formattedList, 'last_updated')
        setInsightLists(sortedList);
        set_report_list_to_use(sortedList);

    }, [all_reports, props.menu_group])

    useEffect(() => {
        if (report_list_to_use?.length > -1) {
            setIsloding(false);
        }
    }, [report_list_to_use])


    useEffect(() => {
        const dashboard_type_from_session = getFromSession('DASHBOARDS_LIST_MODE')
        if (dashboard_type_from_session) {
            set_view_report_type(dashboard_type_from_session)
        }

        const header = {
            title: static_types[whichInsightType]?.title,
            // extraComponent: <DashboardListHeader
            //     view_mode={view_report_type}
            //     on_search={on_search}
            //     // addNewAction={addNewAction}
            //     toggle_view_mode={toggle_view_mode}
            // />
        }
        props.dispatchHeaderInformation(header)

    }, [whichInsightType])


    const toggle_view_mode = async () => {
        const mode_to_use = view_report_type === 'list' ? 'grid' : 'list';
        saveInSession('DASHBOARDS_LIST_MODE', mode_to_use)
        set_view_report_type(mode_to_use)
        // setActiveTab('All');
        setSearchValue('');
    }

    useEffect(() => {
        if (user && (!props.all_reports || props.all_reports.length === 0)) {
            props.get_all_reports(user.id);
        }
        props.getMenuGroups()
    }, [])


    /**
     * this function will get the latest data from backend
     */

    const get_refreshed_insight = (time) => {
        setTimeout(() => {
            props.getInsightsListByUser(user.id);
        }, time)
    }



    /****
     * this function will delete your insight 
     */
    const deleteInsight = (activeInsight) => {
        props.delete_report(activeInsight.id, "report")

    };

    /***
     * this function will copy you insight mean's it will dublicate your insight.
     */
    const copyInsight = (activeInsight) => {
        // const cloneReport = JSON.parse(JSON.stringify(activeInsight));
        // const duplicateInsight = makeCopyReport(cloneReport);
        // props.saveInsight(reportFormatToSave(duplicateInsight), undefined, true, user ? user.id : undefined);
        props.clone_report(activeInsight.id, "report")
    };



    /***
     * when you call this function it will view you insight
     * it will redirect you on home page for view your insight
     */
    const ViewAction = (activeInsight) => {
        const id = activeInsight && activeInsight.id;
        const path = `/?insightId=${id}`;
        props.history.push(path);
    };




    const createNewLayout = (url, type) => {

        if (whichInsightType === "maps") {
            props.history.push("/layout_editor?map_editor=true")
        }
        if (whichInsightType === "insights") {
            props.history.push("/layout_editor")
        }

    }


    /***
     * when u call this function it will redirect to you on layout editor page 
     * where u can easley edit the layout and question whatever you want to edit
     * i am pussing extra key which is #insights true if it is true then after editing when you pressed on save on cancel button it will redirect to the this page
     * 
     */

    const showLayoutEditor = newReport => {

        const cloneState = Object.assign({}, newReport);
        const id = cloneState ? cloneState.id : undefined;

        props.setActiveEditLayout(cloneState);

        let path = whichInsightType == "maps" ? '/layout_editor?map_editor=true' : '/layout_editor';

        if (id) path = path + `?edit=true&reportId=${id}&insightsList=${whichInsightType}`;

        props.history.push(path)
    };


    const currentType = whichInsightType ? static_types[whichInsightType] : undefined;

    const edit_dashboard_id = COMPONENT_IDS.SHARED_ACTIONS.EDIT_DASHBOARD;
    const share_dashboard_id = COMPONENT_IDS.SHARED_ACTIONS.SHARE_DASHBOARD;
    const create_dashboard_id = COMPONENT_IDS.SHARED_ACTIONS.CREATE_DASHBOARD;
    const delete_dashboard_id = COMPONENT_IDS.SHARED_ACTIONS.DELETE_DASHBOARD;
    const clone_dashboard_id = COMPONENT_IDS.SHARED_ACTIONS.CLONE_DASHBOARD;

    return (
        <React.Fragment>

            {!isLoding && <TableHeaderWrapper backgroundColor={isGridView ? 'transparent' : '#fff'} >
                <div style={{ margin: isGridView ? '0.6rem auto 0.5rem' : 0 }}>
                    <SearchBar
                        value={searchValue}
                        type="text"
                        placeHolder={'Search your dashboard'}
                        onChange={(e) => {
                            setSearchValue(e.target.value)
                        }}
                        borderType={isGridView ? 'border-radius' : 'border-bottom'}
                    />

                </div>

                <RippleEffect
                    icon={<ToggleIcon size="1.4rem" height="1.4rem" />}
                    Click={() => toggle_view_mode()}
                    tooltip={view_report_type === 'list' ? "Toggle to grid view" : "Toggle to list view"}
                />

            </TableHeaderWrapper>
            }

            {whichInsightType && isGridView && (
                <DasboardWrapper>
                    {
                        !isLoding && report_list_to_use.length > -1 ?
                            <PageInnerWrapper height={view_report_type === 'grid' ? 'calc(100vh - 9.6rem)' : 'calc(100vh - 8.6rem)'} backgroundColor={isGridView ? 'transparent' : '#fff'} style={{ padding: '5px' }}>
                                <DashboardCardFlex>

                                    {getPermissionById(create_dashboard_id, menuPermissions) && (
                                        <CardAction
                                            parentClick={() => {
                                                createNewLayout()
                                            }}
                                            action={false}
                                            title={currentType && currentType.addTitle}
                                            titleCenter={false}
                                            isOverlay={false}
                                            titleFontSize={'1.1rem'}
                                            addTitleColor='#fff'
                                            // Image={<AddIcon size="5rem" height="5rem" color="#fff" />}
                                            Image={<NewAddSvg size="5rem" height="5rem" color="#fff" />}
                                            cardStyle={{ justifyContent: 'center' }}
                                            iconStyle={{ height: '100%', paddingTop: '1.4rem', justifyContent: 'space-around', flexDirection: 'column', borderRadius: '1rem' }}
                                        />
                                    )}


                                    {/* {report_list_to_use && report_list_to_use.length > 0 && report_list_to_use.map((insight, index) => { */}
                                    {report_list_to_use && report_list_to_use.length > 0 && report_list_to_use.map((insight, index) => {
                                        return (
                                            <CardAction
                                                // isOverlay={false}
                                                data={insight}
                                                key={"insights" + index}
                                                deleteAction={getPermissionById(delete_dashboard_id, menuPermissions) ? deleteInsight : undefined}
                                                copyAction={getPermissionById(clone_dashboard_id, menuPermissions) ? copyInsight : undefined}
                                                editAction={getPermissionById(edit_dashboard_id, menuPermissions) ? showLayoutEditor : undefined}
                                                viewAction={ViewAction}
                                                title={insight.title}
                                                date={insight.created_at}
                                                action={true}
                                                Image={getIconFn(index + 1)}
                                            />
                                        )
                                    })}
                                </DashboardCardFlex>

                                {insightLists && insightLists.length === 0 && !isLoding && <p style={{ textAlign: 'center' }}>No Dashboard Found</p>}
                            </PageInnerWrapper>
                            :
                            <DashboardListSkeleton />
                    }
                </DasboardWrapper>
            )}


            {view_report_type && view_report_type === 'list' && (
                <PageInnerWrapper style={{ padding: '5px' }}>
                    <SharedTable
                        isLoding={isLoding}
                        searchValueonSearch={searchValue}
                        actionsTitles={{
                            "deleteAction": {
                                title: "Delete The Dashboard",
                                message: "Are you sure you want to delete this report ?"
                            },

                        }}
                        pointerRow={true}
                        height={'calc(100vh - 100px)'}
                        rowHeight='2.4rem'
                        action={false}
                        fullTime={true}
                        viewAction={(report) => {
                            ViewAction(report)
                        }}
                        cloneAction={getPermissionById(clone_dashboard_id, menuPermissions) ? (report) => {
                            copyInsight(report)
                        } : undefined}
                        editAction={getPermissionById(edit_dashboard_id, menuPermissions) ? (report) => {
                            showLayoutEditor(report)
                        } : undefined}
                        deleteAction={getPermissionById(delete_dashboard_id, menuPermissions) ? (report, index) => {
                            deleteInsight(report)
                            // removeReportItem(index, report.id)
                        } : undefined}

                        columnAction={{
                            "title": ViewAction
                        }}

                        rowClickAction={(report) => {
                            ViewAction(report)
                        }}
                        tableHead={["Group Name", "Dashboard Name", 'Author', "Number of Widgets", 'Created At', 'Modified At', 'Action']}
                        columnsToShow={['menu_group_name', "title", 'created_user_name', 'number_of_report_item', 'created_at', 'modified_at']}
                        maximumWidthOfColumn={{
                            'Group Name': '10%',
                            "Dashboard Name": '20%',
                            'Author': '14%',
                            'Number of Widgets': '12%',
                            'Created At': '15%',
                            'Modified At': '15%',
                            'Action': '14%'
                        }}
                        colunnTextAlignment={{
                            menu_group_name: 'center',
                            number_of_report_item: 'center',
                            title: 'left'
                        }}
                        searchColumns={['title']}
                        columnTextWithWrapper={['created_user_name', 'Action']}
                        toggle_view_mode={toggle_view_mode}
                        view_mode={view_report_type}
                        sortColumns={{
                            "Group Name": {
                                type: "string",
                                dataKey: 'menu_group_name'
                            },
                            "Dashboard Name": {
                                type: "string",
                                dataKey: 'title'
                            },
                            "DB Mode": {
                                type: "string",
                                dataKey: 'report_type'
                            },
                            "Author": {
                                type: "string",
                                dataKey: 'created_user_name'
                            },
                            "Created At": {
                                type: "date",
                                dataKey: 'created_at'
                            },

                            "Modified At": {
                                type: "date",
                                dataKey: 'modified_at'
                            },
                        }}
                        tableData={report_list_to_use}
                        isDateColumn={"created_at"}
                        date_columns={['created_at', 'modified_at']}
                        column_body_styles={{
                            "title": {
                                fontWeight: 'bold',
                                fontSize: '.8rem',
                                textTransform: 'uppercase',
                            }
                        }}
                    />

                    {getPermissionById(create_dashboard_id, menuPermissions) && (
                        <div style={{ position: 'absolute', zIndex: 4, bottom: '5rem', right: '4rem', borderRadius: "50%", boxShadow: "-5px 9px 20px -7px rgba(0,0,0,0.75)" }}>
                            <AddButton title="Create New Report" onClick={() => {
                                // addNewAction()
                                createNewLayout()
                            }}>
                                <AddIcon size="2.8rem" height="2.8rem" />
                            </AddButton>
                        </div>
                    )}
                </PageInnerWrapper>
            )}
        </React.Fragment>
    )
}


const mapStateToProps = (state) => {

    const insightsListData = state.insightReducer?.insightsListData;
    const refreshInsightData = state.insightReducer?.refreshInsightData;
    const all_reports = state.reportHelperReducer?.all_reports;
    const menuPermissions = state.permissionReducer?.permissions;
    const menu_group = state.menuGroupReducer?.menu_groups_cache?.['report'];

    return {
        insightsListData,
        refreshInsightData,
        all_reports,
        menuPermissions,
        menu_group
    }
}

export default connect(mapStateToProps, { delete_report, clone_report, getMenuGroups, get_all_reports, dispatchHeaderInformation, getInsightsListByUser, saveInsight, setActiveEditLayout })(Index)
