import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as _ from 'lodash';

import { getDoctorRankingData } from '../../actions/ranking';

import RankCard from '../rank-card';
import RankCell from '../rank-cell';
import TitleHeader from '../title-header';
import CloseSvg from '../svg/close';
import RankCompare from '../rank-compare/index';
import { RankingWrapper, CardWrapper, TableWrapper, CompareWrapper, CompareDetails, CompareButton, CompareUserWrapper, UserWrapper, CloseWrapper } from './ranking.styled';
import { showPopup, getNameInitials } from '../../utils'
import * as enums from '../../utils/enums';
import { constants } from '../../utils/constants';


const DoctorRanking = (props) => {

    const [localData, setLocalData] = useState(undefined);
    const [rootCauseFields, setRootCauseFields] = useState(undefined);
    const [selectedCells, setSelectedCells] = useState({});
    const [selectedMainRow, setSelectedMainRow] = useState(undefined);
    const [compareData, setCompareData] = useState(undefined);

    const { tableResults } = props;

    const wrapperRef = useRef(null);


    useEffect(() => {
        props.getDoctorRankingData();
    }, []);



    useEffect(() => {
        if (tableResults && tableResults.data) {
            setLocalData(tableResults);
        }

    }, [tableResults]);


    const cellClicked = index => {
        let tempIndex = undefined;

        if (selectedMainRow === index) {

        }
        else {
            tempIndex = index;
        }

        setSelectedMainRow(tempIndex);
        setCompareData(undefined)
    };




    const _setCompareData = (data) => {
        let temp_compare = compareData ? [...compareData] : [];
        let _index = temp_compare && temp_compare.length > 0 && temp_compare.findIndex((_data, index) => {
            return _data.DoctorId === data.DoctorId;
        })



        if(_index !== -1 && _index !== false){
            temp_compare.splice(_index, 1);
        }
        else{
            temp_compare.push(data)
        }

        setCompareData(temp_compare)
    };


    const showRankCompare = () => {
        showPopup(undefined, undefined, enums.default.popupType.element, RankCompare, {
            detail: selectedCells,
            rootCauseFields,
            nameFields: localData.name_fields,
            compareData : compareData
        });
    };


    const removeCompareData = index => {
        const temp = [...compareData];
        temp.splice(index, 1);
        setCompareData(temp)
    };

    return (
        <div>
            <RankingWrapper
                ref={wrapperRef}
            >
                <TitleHeader
                    title={'Doctor\'s Ranking'}
                />
                <div
                    style={{
                        width: '40%',
                        padding: '0 1.3rem',
                        boxSizing: 'border-box',
                        fontSize: '1.4rem'
                    }}
                >
                    Speciality
                </div>
                <TableWrapper>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            paddingTop: '1rem'
                        }}
                    >
                        {
                            localData && localData.data.length > 0 && localData.data.map((data, index) => {
                                return (
                                    <RankCell
                                        data={data}
                                        columnMeta={localData && localData.column_meta_data}
                                        nameFields={localData && localData.name_fields}
                                        comparisonFields={localData && localData.comparison_fields}
                                        rankIndex={index}
                                        key={'rank_card' + index}
                                        onClick={() => cellClicked(data)}
                                        isSelected={index === selectedMainRow}
                                        selectedMainRow={selectedMainRow}
                                        cellClicked={cellClicked}
                                        extraData={data && data.data ? data['data'] : undefined}
                                        showArrow={true}
                                        primaryName={localData && localData.primary_name}
                                        rankTableFields={localData && localData.rank_table_fields}
                                        rankField={localData && localData.rank_field}
                                        _setCompareData={_setCompareData}
                                        compareData={compareData}
                                    />
                                )
                            })
                        }
                    </div>
                </TableWrapper>
            </RankingWrapper>

            <CompareWrapper
                show={compareData && compareData.length > 0 ? true : false}
            >
                <CompareDetails>
                    <CompareUserWrapper>
                        {
                            compareData && compareData.length > 0 && compareData.map((data, index) => {
                                const name = (data && data['DoctorName']) ? getNameInitials(data['DoctorName']) : undefined;
                                return (
                                    <UserWrapper>
                                        <div
                                            style={{
                                                width: '3rem',
                                                height: '3rem',
                                                borderRadius: '50%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                background: '#e1e1e1',
                                                color: '#3d3d40',
                                                fontSize: '1rem',
                                                fontWeight: 'bolder',
                                                marginRight: 10
                                            }}
                                        >
                                            {name}
                                        </div>
                                        <div>
                                            {_.startCase(_.lowerCase(data['DoctorName']))}
                                        </div>
                                        <CloseWrapper
                                            onClick={() => {
                                                removeCompareData(index);
                                            }}
                                        >
                                            <CloseSvg
                                                size='0.664rem' height='0.664rem' color="#fff"
                                            />
                                        </CloseWrapper>
                                    </UserWrapper>
                                )
                            })
                        }
                    </CompareUserWrapper>
                    <CompareButton
                        onClick={showRankCompare}
                    >
                        Compare
                    </CompareButton>
                </CompareDetails>
            </CompareWrapper>
        </div>
    )
}


const mapStateToProps = state => {
    const tableResults = state.rankingReducer.tableResults;

    return { tableResults }

};

export default withRouter(connect(mapStateToProps, { getDoctorRankingData })(DoctorRanking))
