
/***
 * title: this is a select-option component 
 * author: saroj kumar
 * date: 13 jan 2022
 * 
 * accept props =>
 * 
 *      select_option_style = string
 *              ["type1", "type2", "type3", "type4"]
 *      on_change = function
 *      value = string
 *      options = array
 *      value_key = string
 *      display_key = string
 *      label = string
 *          
 */


import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components'
import * as _ from 'lodash';
import { constants } from '../../utils/constants';
import RightArrowSvg from '../svg/right.arrow'


/***
 * here we have the style of the component 
 * 
 */


const ripple = keyframes`
   to{
        transform: scale(2.5);
        opacity: 0;
      }
  `;



const MainDiv = styled.div`
      width: 100%;
      position: relative;
      /* padding-top: 10px; */
      /* height: 100px; */
      margin-top: ${props => props.hide_margin ? '0px' : '15px'};
      .none_user_input{
          opacity: 0;
          width: 0px;
          height: 0px;
      }
  `;




const OverlayMain = styled.div`
    width: 100vw;
    height: 100vh;
    z-index: 1200;
    position: fixed;
    top: 0px;
    left: 0px;
    background: #ff000000;
`;


const SuggestionList = styled.div`

    width: ${props => (props?.width + "px") || '100%'};
    top : ${props => props?.top + "px" || "0px"};
    left : ${props => props?.left + "px" || "0px"};
    box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
    background-color: red;
    background-color: #fff;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-top: 0px;
    max-height: 180px;
    /* min-height: 180px; */
    overflow: auto;
    position: absolute;

    &::-webkit-scrollbar {
        background-color:red;
        width: 3px
    }
    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }
    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 3px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 3px;
    }
    .suggestion_item{
        /* font-family: Roboto, Helvetica, Arial, sans-serif !important; */
        font-size: .9rem;
        padding: .5rem 10px;
        box-sizing: border-box;
        color: #222;
        cursor: pointer;
        overflow: hidden;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        &:hover{
            background-color: rgba(0, 0, 0, 0.04);
        }
}

.ripple{
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    transform: scale(0);
    animation: ${ripple} 0.6s linear;
}
.active{
    background-color: rgba(25, 118, 210, 0.08);
    &:hover{
        background-color: rgba(25, 118, 210, 0.08);
    }
}

.suggestion_search{
    padding: 0px .2rem;
    margin-top: .5rem;
    margin-bottom: .3rem;
    /* font-family: Roboto, Helvetica, Arial, sans-serif !important; */
    
    input{
        width: 100%;
        box-sizing: border-box;
        height: 1.8rem;
        font-size: .9rem;
        color: #222;
        border: 1px solid;
        border-color: #ccc;
        padding: .5rem;
        /* font-family: Roboto, Helvetica, Arial, sans-serif !important; */
        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            /* font-family: Roboto, Helvetica, Arial, sans-serif !important; */
        }
    }
}
.no_record_found{
    text-align: center;
    font-size: .6rem;
    padding: .5rem;
    box-sizing: border-box;
    color: #222;
    /* font-family: Roboto, Helvetica, Arial, sans-serif !important; */
}
.checkbox{

    width: 13px;
    height: 13px;
    margin-right: 10px;
    cursor: pointer;
}

.group_key{
    text-transform: capitalize;
    box-sizing: border-box;
    line-height: 45px;
    list-style: none;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
    font-size: 0.9rem;
    padding-left: 10px;
    padding-right: 10px;
    position: sticky;
    top: 0px;
    z-index: 1;
    background-color: #fff;
    border-bottom: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea;
    /* font-family: Roboto, Helvetica, Arial, sans-serif !important; */
}
`;



const UserInput = styled.div`
  
      width: 100%;
      height: ${props => props.size ? '1.8rem' : '2.4rem'};
      background: ${props => props.background ? props.background : '#fff'};
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      box-sizing: border-box;
      z-index: ${props => props.open_menu ? 1201 : undefined};
 
      input{
          width: 100%;
          height: 100%;
          border: 0px;
          border-radius: 6px;
          border: ${props => props.hide_border ? '0px' : props?.focused ? '2px solid #007FFF' : '1px solid #0000003b'};
          padding: 0px 10px;
          box-sizing: border-box;
          font-size: .9rem;
          font-weight: normal;
          color: rgb(55, 74, 102);
          background-color: transparent;
          z-index: 2;
 
         &:hover{
             border: 1px solid #000;
         }
         &:focus{
             outline: none;
             border: ${props => props?.focused ? '2px solid #007FFF' : '1px solid #0000003b'};
         }
      }
      
      
      .value_txt{
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: .9rem;
          font-weight: normal;
          color: rgb(55, 74, 102);
      }
      .label_pre{
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: .9rem;
          color: #646464;
          font-weight: 300;   
          text-transform: capitalize;
          color: rgba(0, 0, 0, 0.6);
          /* font-family: Roboto, Helvetica, Arial, sans-serif; */
          font-weight: 400;
          font-size: .9rem;
          line-height: 1.4375em;
          letter-spacing: 0.00938em;
          transition: all 0.2s;
          position: absolute;
          left: 10px;
          background-color: ${props => props.background ? props.background : '#fff'};
          z-index: 1;
          ${props => props.labelStyle ? { ...props.labelStyle } : undefined}
 
       }
       
  
       .label_post{
          color: rgba(0, 0, 0, 0.6);
          font-weight: 400;
          /* font-family: Roboto, Helvetica, Arial, sans-serif; */
          font-size: .9rem;
          transform: translate(-11%, -100%) scale(0.8);
          background-color: ${props => props.background ? props.background : '#fff'};
          padding: 0px 7px;
          z-index: 3;
          ${props => props.labelStyle ? { ...props.labelStyle } : undefined}
 
       }
  
      .select_arrow{
          position: absolute;
          right: 0px;
          top: 0px;
          bottom: 0px;
          height: 100%;
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          transform: ${props => props.is_open ? "rotate(180deg)" : undefined};
      }
  `;



const TextBoxV1 = (props) => {


    /**
     * here we are destructuring the props
     */

    const {

        value,
        type,
        label,
        onFocus,
        onBlur,
        background,
        labelStyle,
        hide_case = true,
        error,
        autoFocus,
        group_key,
        none_value = true,
        none_display_value,
        display_key,
        value_key,
        is_search,
        options_sort_key,
        options,
        on_change,
        multiple,
        group_sort_key,
        icon_key,
        size,
        hide_add_btn=false,
        hide_margin

    } = props;



    /// State is here of the component

    const [suggestion_list, set_suggestion_list] = useState(undefined);
    const [prev_options, set_prev_options] = useState(undefined);
    const [open_menu, set_open_menu] = useState(undefined);
    const [dropdown_position, set_dropdown_position] = useState(undefined);
    const [focused, set_focused] = useState(undefined)

    const [search_value, set_search_value] = useState('')


    const should_we_group = typeof group_key !== 'undefined' && group_key.length > 0;
    let prev_group_item = undefined;


    const select_input_ref = useRef(null)


    // useEffect(() => {
    //     // this is patch code for stop force update 
    //     const need_to_update = (JSON.stringify(options || {})) !== (JSON.stringify(prev_options || []))
    //     if (need_to_update) {
    //         if (should_we_group) {
    //             const sort_data = sort_list_using_group_sort_key(options, group_key, group_sort_key)
    //             set_suggestion_list(sort_data);
    //             set_prev_options(sort_data)

    //         }
    //         else if (options_sort_key) {
    //             const sort_data = sort_list_using_group_sort_key(options, (display_key ? display_key : undefined), options_sort_key)
    //             set_suggestion_list(sort_data)
    //             set_prev_options(sort_data)
    //         }
    //         else {
    //             set_suggestion_list(options)
    //             set_prev_options(options)
    //         }
    //     }
    // }, [options])


    useEffect(() => {
        if (value) {
            const vv = get_display_value_by_original_value(value)
            set_search_value(vv)
        } else {
            set_search_value('')
        }
        set_suggestion_list(options)
    }, [value, open_menu, options])



    const sort_list_using_group_sort_key = (data_to_sort, sort_key, sort_by) => {
        const clonned_data = JSON.parse(JSON.stringify(data_to_sort));
        clonned_data && clonned_data.length > 0 && clonned_data.sort((prev_item, this_item) => {
            const desc_comp = sort_key ? this_item[sort_key].localeCompare(prev_item[sort_key]) : this_item.localeCompare(prev_item);
            const default_comp = sort_key ? prev_item[sort_key].localeCompare(this_item[sort_key]) : prev_item.localeCompare(this_item)
            if (sort_by === 'desc') return desc_comp
            return default_comp
        });
        return clonned_data;

    };

    const el_id = "input__id__uniqqq_auto_gen" + Math.random(100)
    const is_input_have_value = search_value && search_value.length > 0;
    const lable_class = "label_pre" + " " + ((focused || is_input_have_value) ? "label_post" : 'dd');
    const suggestion_list_width = select_input_ref?.current?.clientWidth;



    const get_display_value_by_original_value = (__value__) => {

        // if (multiple) {

        //     if (!display_key && !value_key) return value_join_with ? __value__.join(value_join_with) : __value__.join(", ");

        //     if (display_key && value_key) {

        //         let found_display_value = [];

        //         for (let index = 0; index < __value__.length; index++) {
        //             const v = __value__[index];
        //             const option = options.find((o) => o[value_key] === v);
        //             if (option) found_display_value.push(option[display_key]);
        //         }
        //         return found_display_value ? (value_join_with ? found_display_value.join(value_join_with) : found_display_value.join(',')) : __value__;
        //     }
        // } else {
        if (display_key && value_key && options) {
            let found_display_value = undefined;
            for (let index = 0; index < options.length; index++) {
                const element = options[index];
                if (element[value_key] === __value__) {
                    found_display_value = element[display_key];
                    break;
                }
            }
            return found_display_value ? found_display_value : __value__;
        } else return __value__
        // }
    }



    const on_search_list = (e) => {

        e.preventDefault();
        e.stopPropagation();

        const value = e.target.value?.toLowerCase();

        const filterd_suggestion_list = options && options.length > 0 && options.filter((op) => {
            if (value === '') return true
            if (typeof op === 'string' && op.toLowerCase().indexOf(value) > -1) return true;
            if (typeof op === 'object' && op[display_key].toLowerCase().indexOf(value) > -1) return true;
            else return false;
        })

        set_suggestion_list(filterd_suggestion_list)
    }


    const close_dropdown = () => {
        set_open_menu(!open_menu)

        if (should_we_group) {
            const sort_data = sort_list_using_group_sort_key(options, group_key, group_sort_key)
            set_suggestion_list(sort_data);
            set_prev_options(sort_data)

        }
        else if (options_sort_key) {
            const sort_data = sort_list_using_group_sort_key(options, (display_key ? display_key : undefined), options_sort_key)
            set_suggestion_list(sort_data)
            set_prev_options(sort_data)
        }
    }


    /***
     * when we click on suggestion_list item this function is called
     */
    const click_on_suggestion = (__item__, event) => {
        let __value__ = '';
        if (typeof __item__ === "string") __value__ = __item__;
        if (typeof __item__ === "object") __value__ = __item__[value_key];
        on_change(__value__, __item__, event, false)
    }



    const find_is_this_active_element = (options, __value__) => {
        if (multiple && __value__) {
            if (!display_key && !value_key) return __value__.indexOf(options) > -1;
            if (display_key && value_key) return __value__.indexOf(options[value_key]) > -1;

        } else {
            if (!display_key && !value_key) return options === __value__;
            if (display_key && value_key) return options[value_key] === __value__
        }
    }

    const format_string = (str) => {
        return str
    }


    const open_dropdown_menu = (e) => {
        set_focused(true);
        set_open_menu(true);
        const dropdown_height = 180;
        const element = document.getElementById(el_id);
        const el_bounding = element.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const element_height = el_bounding?.height;
        const element_left = el_bounding?.left;
        const element_top = el_bounding?.top;
        let drop_down_top_to_use = (element_top + element_height);
        if ((windowHeight - drop_down_top_to_use) <= dropdown_height) {
            drop_down_top_to_use = ((element_top + element_height) - dropdown_height) - 10
        }
        set_dropdown_position({
            top: drop_down_top_to_use,
            left: element_left
        })
        close_dropdown(!open_menu)
    }


    // console.log("suggestion_list", suggestion_list)

    // console.log("value1.1", value, search_value)

    return (
        <MainDiv ref={select_input_ref} hide_margin={hide_margin}>

            {/* this is input but not visible for user */}

            {/* this is active value placholder  */}
            <UserInput
                size={size}
                open_menu={open_menu}
                background={background}
                // id={el_id}
                // ref={props.ref_name}
                id={el_id}
                focused={focused}
                onBlur={() => {
                    // set_focused(false)
                }}
                labelStyle={labelStyle}
                hide_border={props.hide_border}


            >
                {/* {<span className={lable_class} style={{ color: focused ? "#007FFF" : undefined }}>{!hide_case ? _.startCase(_.toLower(label)) : label}</span>} */}
                <input
                    placeholder={!hide_case ? _.startCase(_.toLower(label)) : label}
                    autoFocus={autoFocus}
                    ref={props.ref_name}
                    value={search_value}
                    onFocus={(e) => {
                        open_dropdown_menu(e)
                        if (onFocus) onFocus(e)
                        set_focused(true)

                    }}
                    onBlur={(e) => {
                        if (onBlur) onBlur(e)
                        set_focused(false)
                    }}

                    onChange={(e) => {
                        e.stopPropagation()
                        set_search_value(e.target.value)
                        on_search_list(e)
                        on_change(e.target.value)

                    }}
                    onKeyDown={(e) => {
                        e.stopPropagation()

                        if (e && props.onKeyDown) {
                            props.onKeyDown(e)
                        }
                    }}
                    type={type || "text"}
                    style={{
                        borderColor: error ? 'red' : undefined
                    }}
                />

            </UserInput>

            {open_menu && (
                <OverlayMain onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation()
                    close_dropdown(false);
                }}>
                    <SuggestionList onClick={(e) => e.stopPropagation()} top={dropdown_position?.top} left={dropdown_position?.left} width={suggestion_list_width}>

                        {none_value && (
                            <div
                                onClick={(e) => {
                                    e.stopPropagation()
                                    on_change('', undefined, e, false)
                                    close_dropdown(false);
                                }}>
                                <div className={'suggestion_item'}>
                                    <span><i>None</i></span>
                                </div>
                            </div>
                        )}


                        {suggestion_list && suggestion_list.length > 0 && suggestion_list.map((s, index) => {

                            const is_this_item_active = find_is_this_active_element(s, value);
                            const this_group_item = should_we_group && (s[group_key] || '');
                            const __fire__group__div__ = should_we_group && this_group_item !== prev_group_item;
                            const classes = is_this_item_active ? "suggestion_item active" : "suggestion_item";

                            prev_group_item = this_group_item;

                            const value_of_list = typeof s === 'object' ? s[display_key] : s;
                            const value_to_display = !hide_case ? format_string(value_of_list) : value_of_list

                            return (
                                <React.Fragment>
                                    {__fire__group__div__ && <div style={{ textTransform: !hide_case ? "capitalize" : undefined }} className="group_key">{this_group_item}</div>}
                                    <div
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            click_on_suggestion(s, e)
                                            close_dropdown(false);
                                        }}>
                                        <div style={{ paddingLeft: group_key ? "19px" : undefined, textTransform: !hide_case ? "capitalize" : undefined }} className={classes}>
                                            <span>{value_to_display}</span>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })}

                        {((!suggestion_list || (suggestion_list?.length === 0)) && search_value && !hide_add_btn) && <div
                            onClick={(e) => {
                                e.stopPropagation()
                                on_change(search_value, undefined, e, true)
                                close_dropdown(false);

                            }}>
                            <div className={'suggestion_item'}>
                                <span>Add <strong>"{search_value}"</strong></span>
                            </div>
                        </div>}
                    </SuggestionList>
                </OverlayMain>
            )}
        </MainDiv>
    );
}
export default TextBoxV1;