import React, { useEffect } from 'react';
import Editor from '../editor.shared/index.editor';
import SqlEditor from '../sql.editor';
import RippleEffect from '../hoc/ripple-effect';
import ListSvg from '../svg/list'
import { withRouter } from 'react-router-dom';

const Index = (props) => {




    useEffect(() => {
        let _page_info = {
            title: 'REPORT BUILDER',
            // isBack: true,
            extraComponent: <div style={{ marginRight: '0rem' }}>
                <RippleEffect
                    icon={<ListSvg size="1.2rem" height="1.2rem" color="#222" />}
                    tooltip="Go to Report list"
                    Click={() => {
                        const path = `/report_list`;
                        // console.log("props", props)
                        props.history.push(path)
                    }}
                />
            </div>


        }
        props.dispatchHeaderInformation(_page_info)
    }, [])

    return (
        <div>
            <Editor
                {...props}
                type={"config_query_builder"}
                is_filter={true}
                is_formula={true}
                change_report_type={true}
                report_field_title={"Report Display Fields"}
                report_title={"Please provide visualization name"}
            />

            {/* <SqlEditor /> */}
        </div>
    )
}

export default withRouter(Index)
