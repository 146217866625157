import React from "react";

const SqlServeIcon = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      shape-rendering="geometricPrecision"
      text-rendering="geometricPrecision"
      image-rendering="optimizeQuality"
      fill-rule="evenodd"
      clip-rule="evenodd"
      viewBox="0 0 2048 2048"
    >
      <path
        transform="translate(454,2)"
        d="m0 0h1146l34 3 32 5 27 6 25 7 29 10 26 11 30 15 22 13 21 14 19 14 17 14 11 10 8 7 22 22 7 8 12 14 10 13 13 18 11 17 10 17 12 22 12 26 10 26 9 28 8 32 5 29 3 25 1 12v1167l-2 22-5 34-6 28-7 25-10 29-10 24-13 27-10 18-15 24-13 18-10 13-9 11-18 20-25 25-11 9-9 8-13 10-18 13-19 12-19 11-23 12-22 10-26 10-25 8-32 8-35 6-39 4-45 1-1108-1-33-3-28-4-28-6-29-8-29-10-28-12-28-14-22-13-21-14-19-14-17-14-12-11-8-7-20-20-7-8-12-14-11-14-13-18-11-17-9-15-12-22-13-28-11-29-8-25-7-28-5-27-3-22-2-22-1-19v-1115l1-30 3-33 6-35 6-25 7-24 10-28 11-25 8-17 13-23 11-18 14-20 10-13 8-10 12-14 12-13 20-20 8-7 13-11 17-13 18-13 24-15 24-13 27-13 28-11 31-10 29-7 27-5 24-3z"
        fill="#FCEBEB"
      />
      <path
        transform="translate(923,331)"
        d="m0 0 2 1-7 11-3 6-2 1 2 4 18 6 48 10 13 1 2 2 33 6 70 11 30 5 24 3-4 2 20 4 52 8 43 7 42 8 41 9 40 11 7 1 1 2-2 1 42 15 16 6 20 9 18 9 10 4 9 5-1 4 5 2 16 10 10 7 7 3 12 9 11 9 8 7 2 5 8 7 3 3 5 2 20 20 10 13 7 11 6 10-1 4-10 2-45 5-51 8-46 9-11 3v2l-57 13-42 10-38 10-46 13-9 1v2l2 1-36 12-24 7-30 10-17 5-24 9-6 1 1 4-16 5-34 13-15 5-38 16-14 5-8 3 1 3-15 7-16 7-13 5-19 9-16 8-12 5-14 7v3l-8 4-19 12-8 3-14 9h-2l2-4 18-18 5-2 4-4 2-6 8-7 7-8 12-12h2v-2l6-5 4-5 4-1 5-5v-4l5-5 12-13 16-20 7-9v-5l6-2 14-21 11-19 5-12 11-23 5-13h3l9-34 3-18 1-11v-37l-3-22-6-23-4-13v-2h-2l-1 3-5-13-12-22-8-12-3-7-11-14-7-8-6-3-18-18-1-5-3-1v-2l-4-2-9-8-11-8-5-2-17-12-14-10-2-4h2l1-3 17-13z"
        fill="#EC5549"
      />
      <path
        transform="translate(1083,777)"
        d="m0 0h6l1 4-11 7-13 7 1 5 7 12 12 15 8 7 10 10 7 6 6 7 14 11 3 3-1 2-6 1v2l2 1v2l4 1 3 5-2 2-15 6-11 6h-2l-1 3-12 6-15 6-20 9-20 8-37 14-14 4-7 1v2l2 1-11 3 2 5 12 16 3 3 4 1 7 8 10 9 3 2 3 5 16 11 7 4 7 3 6 3 4-1 10-11 8-11 6-9 10-13 4-2 10-15 14-27 10-21 8-26h2v9l4-3-1 7-15 37-11 24-8 15-13 21-12 15-14 18 41-15 29-13 17-8 19-8 8-2h2l-1 4-12 6-13 9-20 9-41 17-17 6h-2l4 4 18 9 1 3 20 8 26 7 50 11 2 2-1 3-13 2-6 1-24-4-29-6-27-8-17-6-15-7h-3l-8 16-10 17-3 5-4 1-3 5-3 7-13 16h-2l-2 4-12 14-5 7-1 4 11-2 1-2 28-6 33-7 38-9 48-13 20-7 12-6 8-2h8l1 4-10 5-12 4-23 9-24 8-22 7-30 9-13 4h-2v2l-18 6-19 4-2 1 38 7 45 7 52 6h16l13-23 10-22 10-27 1 4-9 29-2 7 3 1 1-2h2l-3 10-9 22-5 10-1 1h-13l-66-5-27-3-37-6-36-7-10-3-21-4-9-3-24-7-6-3-3-2-16-5-24-9-9-4-9-3-13-6-12-6v-2l-5-1-9-6-8-2-1-5-11-7-14-11-10-9-15-14-8-16-5-12-3-13v-18l3-15 7-17 10-17 1-3 2 1-1 2h3l1-5 9-11 11-11v3l-6 7-6 10-8 27-2 6-3 3-1 4 16-4 33-8 5-2 26-6 18-5 9-2 16-4h4v-2l-4-1 7-2 11-2 3-2-2-5-8-14-4-11-3-14-2-14-1-31 6-2-1-4 24-11 18-8 16-8 13-6 13-5 18-8 3-2v-3l15-6 12-4 21-9 3 1 4-1z"
        fill="#BBBEC0"
      />
      <path
        transform="translate(923,331)"
        d="m0 0 2 1-7 11-3 6-2 1 2 4 18 6 48 10 13 1 2 2 33 6 70 11 30 5 24 3-4 2 20 4 52 8 43 7 42 8 41 9 40 11 7 1 1 2-2 1 42 15 16 6 20 9 18 9 10 4 9 5-1 4 5 2 16 10 10 7 7 3 12 9 11 9 8 7 2 5 8 7 3 3 5 2 20 20 10 13 7 11 6 10-1 4-10 2-45 5-51 8-46 9-11 3v2l-57 13-42 10-38 10-46 13-9 1v2l2 1-36 12-24 7-30 10-17 5-24 9-6 1 1 4-16 5-34 13-15 5-38 16-14 5-8 3 1 3-15 7-16 7-13 5-19 9-16 8-12 5-14 7v3l-8 4-19 12-8 3-14 9h-2l2-4 18-18 5-2 4-4 2-6 8-7 7-8 12-12h2v-2l6-5 4-5 4-1 5-5v-4l5-5 12-13 2-3 2 1-2 3-1 5h5l1 2 5-2 1-3 8-5 3 1 1 1 1 3 5 1 4-1v2h3v2h2l-2 4h-2l-1 3-3 3 16-8 26-12 9-3 32-13 30-10-1-3 17-6-2-24 2 1 1 6h2v-5l-1-1v-8l1-10 1-2-1-6 2-1 5 24 3 19 5-1 14-18 1-2h2l1 8 6-2 3-2-2 5-4 5 47-14 29-9 7-2-2-2 41-13 60-18 9-2v-3h-2l-7-8-3-3-1-4-5-2-13-13h3l6 5 4 4v2h3l3-6 2 6-1 6h5l1 3 2 1v2l1 2 4 1 1 3h2l2-5-2-1v-3l-4-2-2-4 1-3h-2v-3l-2-1v-3l-2-1 1-2-2-1-4-8-6-6 5 1 12 15 8 13 6 12 2 1 10-17 6-8 1-2 5-1 8-11 13-17 3-5 2-5 11-14 7-9 1-3 5-1 7-10 5-7 3-7 8-11 16-24v-1l-60 17-16 5-2-2 9-3v-3h2v-2l-14 4-6 1-2-1v2l-9 2-7 1h-2l-3 3h-7l-3 2h-5l4-4 26-10 54-18 9-2-2-2 16-6-11-5-58-19-27-7-28-7-17-3-2-1 1-3h-4l-2 4h-2v5h-2l1 3-2 11-2 8-3 3v3l-2 5h-2l-2 8-1 2h-2l3-12 8-30 2-13-7 3-2 4-12 5-16 8-16 9-16 10-7 3-16 11-1 5-13 10-10 9-5-1v3h7l-4 2-3 4v2l-9 1-17-1-3-2h-10l-6-4v-2l-9-1-14-4h-8l-7-1-7 2-25-6-10-3 2 22 1 19v35l-1 9 11-9 4-4 2 1-4 5-2 4-2 2-6 2-1 5-3 2-1-1v-57l-1-28-6-2-3-6-1-3-4 2 2 10v5l-2-3-5-17-2-9h-2l-1 3-5-13-12-22-8-12-3-7-11-14-7-8-6-3-18-18-1-5-3-1v-2l-4-2-9-8-11-8-5-2-17-12-14-10-2-4h2l1-3 17-13z"
        fill="#D74D43"
      />
      <path
        transform="translate(992,817)"
        d="m0 0 1 4-5 4-7 3-6 3-1 2-30 13-8 3-4 17v20l3 17 3 7 3-1 4 9 1 4 4 2 4 4h3l1-3h2l2-5 4-6h2v-2l2 1-7 11-6 8v8l-1 7 3 7-1 10 2 10v2l-4-1-3-5-3-1-2 5-6 11-1 2-4 2-8 16-10 16-14 20-7 9-3 7-5 6-4 1-9 11-9 10-3 4h-2v2l44-10 21-6 23-6 24-8 11-3 4 1-7 3 4 2h5l-1 3-16 4-33 10-48 12-21 4h-3v2l5 2 2 4 10 6 32 16 17 7 15 4 7 3 17 5 4-1 8-8h7 2l6-1v-2l9-7 7-5-2 5-13 15-5 7-1 4 11-2 1-2 28-6 33-7 38-9 48-13 20-7 12-6 8-2h8l1 4-10 5-12 4-23 9-24 8-22 7-30 9-13 4h-2v2l-18 6-19 4-2 1 38 7 45 7 52 6h16l13-23 10-22 10-27 1 4-9 29-2 7 3 1 1-2h2l-3 10-9 22-5 10-1 1h-13l-66-5-27-3-37-6-36-7-10-3-21-4-9-3-24-7-6-3-3-2-16-5-24-9-9-4-9-3-13-6-12-6v-2l-5-1-9-6-8-2-1-5-11-7-14-11-10-9-15-14-8-16-5-12-3-13v-18l3-15 7-17 10-17 1-3 2 1-1 2h3l1-5 9-11 11-11v3l-6 7-6 10-8 27-2 6-3 3-1 4 16-4 33-8 5-2 26-6 18-5 9-2 16-4h4v-2l-4-1 7-2 11-2 3-2-2-5-8-14-4-11-3-14-2-14-1-31 6-2-1-4 24-11 18-8 7-4 3 1 11-5 2 1v-2l9-3z"
        fill="#B3A8AA"
      />
      <path
        transform="translate(452,1355)"
        d="m0 0h25l22 5 13 8 10 9 9 11 5 10v9l5-1 4 13 2 12v43l-4 18-3 7h-2l-1-2-2 10-3 6-8 11-10 10-14 8-7 3-17 3-4 1h-19l-10-2-1-1-11-2-12-6v-2l-5-2-5-5-9-10-7-11-4-14-1 2h-2l-4-15-3-16v-25l3-19 3-12h3l1 2 2-6 2-9 1-2h2l1-5h2l2-5 9-10 11-9 12-6 10-2z"
        fill="#FCEBEB"
      />
      <path
        transform="translate(456,1325)"
        d="m0 0h21l18 2 14 4 18 9v2l4 1 6 5 5 1 2 6 7 6 11 15 5 9 5 13 2 9 4 19 1 10v26l-2 15-4 20-7 21-6 10-9 13-9 9-6 1-1 5-5 4h-3v2l-18 8-4 2-6 1 1 4 13 13 7 6 5 2v5l10 5 18 4h20l12-3v26l-3 3-7 2-9 1h-11l-19-3-13-4-13-7-11-9-9-8-5-2-6-5v-6l-4-2-8-8-6-7v-2h-15l-2-1-17-2-16-6-2-1v-2l-6-2-12-8-1-5-5-1-5-5-7-8-2-5h-2v-3h-2v-3h-2l-7-15v-3h-2l-6-29-2-12v-24l3-24 6-24 8-16 7-11 11-12 2-2h5v-4l-5 2 1-3 7-2 10-7 16-8 14-4zm-4 30-12 3-9 2-13 7-12 11-7 9v2h-2l-1 5h-2l-2 4-1 7-2 6-1-2h-3l-5 22-1 9v25l3 16 4 15h2l1-2 5 16 10 14 6 7 7 5h2v2l12 6 11 2 5 2 6 1h19l13-3 10-2 16-8 10-9 8-9 5-8 2-5 1-8 1 2h2l4-10 3-15v-43l-3-15-3-10-5 1v-9l-7-13-12-13-11-8-10-5-19-4z"
        fill="#181313"
      />
      <path
        transform="translate(1154,540)"
        d="m0 0 1 2-5 17-7 18h-4l-7 20-6 18-12 30-2 9-2 3 4 2 4 8 3 3 15-9 12-6 3-2v-3l18-11 19-12 8-3 26-16 6-4h3l-2-4 7-4 7-3 5-3h3l-2 4-4 5-2 7-2 3-5 1-8 11-14 18-9 12-1 5-7 8-4 6h-4l-4 6-14 18-5 7-1 4-16 21-7 9-5 1-2-7-6-34h-1l1 5-1 4-1 7v8l1 3-1 3h-2l-2-4 2 21-9 4-9 2v2l2 1-10 4-24 8-31 13-14 5-21 10-11 6h-3l1-3 6-5 1-2h-2v-2h-3v-2l-6 2-4-2-1-2-2-1h-3l-6 4-4 4-3 1v-3l-3 1-2-1v-5l4-8 9-10 8-11 1-3v-3l6-2 14-21 11-19 5-12 11-23 5-13h3l9-34 4-22h1l1 13 2 7 3-3 2 1 1-2h3l7-2 6-2 10-10 2 2 1-3-2-1 4-2 4-5 8-7 23-23 5-4 3-4 6-2v-4l8-7z"
        fill="#D84E44"
      />
      <path
        transform="translate(1129,1394)"
        d="m0 0h25l12 3 8 3-1 4 7 2 9 7 5 2 7 8 8 16 3 10 2 15v29l-4 1h-116l1 16 5 16 9 12 5 4 8 5 11 3h29l18-5 10-5 7-3 6-3v28l-6 5-18 6-12 3-11 2h-29l-19-5-14-7-2-2-6-2-6-6-3-9h-2l-10-20-3-14-2-15v-20l3-19 3-12 7-14 9-12 6-6 7-3 10-7 5-2-2-1v-2l15-5z"
        fill="#1A1314"
      />
      <path
        transform="translate(1619,1394)"
        d="m0 0h24l13 3 9 3v2h-2v2l7 2 10 8 7 8v2l4-1 8 16 3 11 2 16v27l-2 1h-122l2 18 3 10 4 8v2l4-2 4 6 8 7 10 5 8 2h29l15-4 14-7 6-4 5-1v29l-9 4-16 7-21 4h-29l-23-6-12-5-12-11-1-6h-2l-6-10-4-9-3-7-3-14-1-10v-15l2-20 5-21 9-16 12-13 11-8 6-2 5-1-2-4 15-5z"
        fill="#1A1314"
      />
      <path
        transform="translate(241,1325)"
        d="m0 0h24l30 4 10 4 1 1v35l-5-2-5-3-9-3-16-4-9-1h-29l-14 4-9 6h-2v2h-2l-1 4h-2l-3 10v12l4 13 5 7 13 9 9 5 6 3 10 4 16 8 10 6 4 2-2 1 2 3 5 3 5 4h5l10 10 6 9 5 11 2 7v21l-3 14-4 10h-2v3h-2l-3 9h-2l-2 4-5 3-6 2-12 6-14 4-11 2-13 1h-15l-14-1-16-3-12-2-12-5-2-2v-36h2v2l5 2 14 7 8 2 14 4 9 2 17 1 12-1 14-4 10-5 6-7 3-6 1-4v-14l-1-8v-2h-2l-5-8-8-7-13-8-9-5-8-2-12-7-14-7-1-4-14-9-8-4-6-5-6-8-6-14-2-14v-16l2-6 4-11 6-8 3-7 7-6 8-3 8-5 17-6 10-2z"
        fill="#181313"
      />
      <path
        transform="translate(944,1326)"
        d="m0 0h42l21 3 10 4v35l-5-2-16-7-11-2-11-1h-26l-14 3-6 3h-3v2h-3l-2 4-5 6-3 7v14l3 11 6 8 16 11 5 1 12 6 30 16 7 2-1 3-4-1 1 2 9 3 14 11 8 10 7 14 2 9v18l-2 13-5 11-6 8-2 6-12 9-13 5-9 3-22 4h-23l-24-3-15-3-13-4-4-3v-36h2v2l30 12 14 4 19 2 16-1 17-5 9-6 4-5 4 2 1-7 2-5v-14l-1-8-2-6-5 1-6-8-13-9-7-4-15-8-10-3-10-6-6-3v-3l-11-7-10-8-7-6-2-5-4 1-7-16-2-11v-19l3-11 6-11 2-1 3 1 2-4 1-5 9-8 14-8 15-5z"
        fill="#171213"
      />
      <path
        transform="translate(1591,567)"
        d="m0 0 4 1 19 19 10 13 7 11 6 10-1 4-10 2-45 5-51 8-46 9-11 3v2l-57 13-42 10-38 10-46 13-9 1v2l2 1-36 12-24 7-30 10-17 5-24 9-6 1 1 4-16 5-34 13-15 5-38 16-14 5-8 3 1 3-15 7-16 7-13 5-19 9-16 8-12 5-14 7v3l-8 4-19 12-8 3-14 9h-2l2-4 18-18 5-2 4-4 2-6 8-7 7-8 12-12h2v-2l6-5 4-5 4-1 5-5v-4l5-5 12-13 2-3 2 1-2 3-1 5h5l1 2 5-2 1-3 8-5 3 1 1 1 1 3 5 1 4-1v2h3v2h2l-2 4h-2l-1 3-3 3 16-8 26-12 9-3 32-13 30-10-1-3 17-6-2-24 2 1 1 6h2v-5l-1-1v-8l1-10 1-2-1-6 2-1 5 24 3 19 5-1 14-18 1-2h2l1 8 6-2 3-2-2 5-4 5 47-14 29-9 7-2-2-2 41-13 60-18 9-2v-3h-2l-7-8-3-3-1-4-5-2-13-13h3l6 5 4 4v2h3l3-6 2 6-1 6h5l1 3 2 1v2l1 2 4 1 1 3h2l2-5-2-1v-3l-4-2-2-4 1-3h-2v-3l-2-1v-3l-2-1 1-2-2-1-4-8-6-6 5 1 12 15 8 13 6 12 2 1 10-17 6-8 1-2 5-1 5-7 5-2v-2h2l1-3 3-1 1-5 8-7 3-3 2-3 4-1 2-5 4-1 2 5-3 7-11 14-11 13-9 11-5 6-3 5-5 2 1 1 26-6-5-1 3-2 63-14 56-10 49-7 29-3h5l-6-9-4-7-7-8-4-4v-2l4 2 8 7 2-4-3-7-4-4-7-9z"
        fill="#EB5549"
      />
      <path
        transform="translate(1494,516)"
        d="m0 0 5 1 18 10 14 9 7 5 7 3 8 6v3l10 8 10 9 8 7 8 8 5 3 10 10 4 8 6 8v2l-25 3-45 6-53 9-34 7-45 10 3 2-6 2-21 5-1-2 6-4 4-7 10-11 9-11 11-14 5-7 3-7 3-4 4 1 2-5 13-18 11-17 6-12 14-21 4-2z"
        fill="#FCEBEB"
      />
      <path
        transform="translate(1481,509)"
        d="m0 0 1 2-10 15-9 13-7 10-3 7-9 12-4 4-3-1-1 4-14 18-5 7-1 5-7 8-9 12-8 11h-5l-2 5-7 10-8 13-5-5-8-16-9-12-7-9-5-2-7-7-6-7-3-2-2-5-9-8-14-10-3-3 16-8 27-12 17-5-1-3 12-5 22-8 21-6 31-10 27-7z"
        fill="#FCEBEB"
      />
      <path
        transform="translate(626,1329)"
        d="m0 0h32l1 1v215h97v29h-130z"
        fill="#181313"
      />
      <path
        transform="translate(1131,887)"
        d="m0 0h4v7l-8 24-12 25-11 21-11 16-5 3-13 18-6 9-11 13-4 4-5-1-7-4-8-3-12-8-10-8-1-3-11-9-8-8v-2l-5-1-8-9-9-13 1-3 9-2v-3l21-6 24-9 26-10 31-14 18-7 5-3 3-3 15-8z"
        fill="#FDECEC"
      />
      <path
        transform="translate(1095,239)"
        d="m0 0 2 1-2 2-2 5-2 3v3l10 2v2l-21 8-31 13-23 10-15 9-4 5-4 1 1 2 21 5 37 6 49 6 9 1 1 2-5 5v3l3 4-5 2-27-3-24-4-36-7-30-8h-4l3 5 4 4 7 8 9 10 6 5 7 8 10 7 12 7 10 6 7 1 31-11 23-11h3l-1 4h2v5l-16 9-20 8-6 2v2l8 2v1l-13-1-62-10-21-4-3-2-18-2-46-10-14-5-3-3 1-3h2l2-6 9-14 13-9 14-8 9-6 9-5 1-4 21-10 4-3 23-11 24-11 18-8 10-6 20-8z"
        fill="#B2A8AA"
      />
      <path
        transform="translate(1367,1398)"
        d="m0 0h36l5 18 3 10 4 10 4 12 2 10 5 15 4 10 5 15 2 9 1 8h2l1-4 3 8 6 19v9h2l2-12 9-29 5-17 3-7 13-39 4-14 2-1v4h2l1-6 8-23 1-5h30l-1 8-5 14-5 11-6 16-6 18-12 30-3 10-2 7h-2v-3h-2l-2 9-5 14-5 11-5 16-6 15h-33l-7-19-8-24-10-26-4-14-12-32v-2h-2l-1 2-8-24-4-9-8-24z"
        fill="#161213"
      />
      <path
        transform="translate(1268,597)"
        d="m0 0 7 3v3l11 6 10 4 17 13 13 11 11 11 5 2 2 5 8 8v2h2v3l-16 5-49 15-20 6-22 7v3l-21 6-32 10-21 6-10 2 2-4 13-16 4-8 8-10 1 2 5-5 8-10 11-13 8-10 1-5 9-10 3-5 5-1 11-14 11-13 4-8z"
        fill="#FBEBEB"
      />
      <path
        transform="translate(925,963)"
        d="m0 0h5l-2 9-12 24-2 3-3 9h-2v3h-2v3h-2v3h-2v3h-2l-1 4-3 4h-2l-1 4h-2l-2 5-3-1v2h-2l-2 4-7 8h-2l1 4-7 7-7 8-8 8-8 7-10-3-10-6-10-9-5-4-5-8-8-19-5-16v-2h-2l-2-7-1-15 1-3 22-5 17-3 61-13 13-2 15-4z"
        fill="#FCEBEB"
      />
      <path
        transform="translate(1053,806)"
        d="m0 0 3 1 10 16 8 11 10 11 6 8 6 4 10 8 15 10 6 3-2 4-8 4-23 10-11 5-4 4-19 8-22 8-31 10-22 5-16 3h-6l1-5 14-23 7-10 3-5 3-3 4-6h2l2-5 7-9 8-12 11-18 11-15 4-4h2l2-5 8-12z"
        fill="#FCECEC"
      />
      <path
        transform="translate(1319,448)"
        d="m0 0 4 2h-3v2l24 4 34 9 22 6 52 17 12 5-3 3-12 4 2 2-24 7-34 12-18 6-29 12-16 6-9 4h-3v2h2v2l-25 12-12 5h-3l2-5 5-10h2v2h2l1-9 7-16 9-27 7-34 3-22z"
        fill="#FCEBEB"
      />
      <path
        transform="translate(1042,1047)"
        d="m0 0h6l-2 10-4 12h-4l-2 6-6 11-8 12-4 8-13 16-14 14-5 3-8 8-10-2-16-5-8-3-16-5-22-10-16-9-10-5-5-4-1-3-4-2v-2l32-7 16-4 31-8 32-10 17-4 29-10h2v-3z"
        fill="#FCEBEB"
      />
      <path
        transform="translate(950,969)"
        d="m0 0 4 1 8 11 7 7 4 5 8 7 1 4 5 4 6 3 14 11 21 14 2 1v2l-28 11h-4v2l2 1-9 4-11 4-17 5-19 6-24 6-13 4-37 8-7 1v-2h2l2-4 7-7 9-11 4-5 4-1 5-7 2-5 12-16 17-26 8-16 3-4h3l2-5z"
        fill="#FCEBEB"
      />
      <path
        transform="translate(1323,1395)"
        d="m0 0h22l5 2v32l-10-3h-22l-8 4-7 6-7 10-4 15-2 6-2-1v-4h-2l-1 112h-29v-176h29l1 32 1 3h2l2-5 6-12 8-9 6-4 2-4z"
        fill="#161213"
      />
      <path
        transform="translate(1212,1101)"
        d="m0 0 4 1-2 10-6 17-11 26-8 15-7 12-1 1h-16l-52-6-51-8-32-6-2-2 26-6 13-4h2v-2l18-6 21-6 31-10 27-9 19-8 12-4 8-2z"
        fill="#FDECEC"
      />
      <path
        transform="translate(1813,1395)"
        d="m0 0h18l5 1 1 2v31l-5-1-4-2h-20l-10 5-7 7h-4l-2 4-4 9-5 20-1 103h-29v-176h29l1 33 7-14 4-5 6-3 5-4 3-3v-2l8-4z"
        fill="#191314"
      />
      <path
        transform="translate(1161,420)"
        d="m0 0 46 3 41 6 31 7 11 2 1 2-10 4-8 4h-3l1 4-23 13-10 5-11 6-16 11-17 12 1 4-8 3-13 10h-2l-7-54-5-38z"
        fill="#FBEBEB"
      />
      <path
        transform="translate(1107,236)"
        d="m0 0 3 4 3 10 10 29 9 22 8 16 9 15 5 12 2 15v4l-5-1-5-5-4-2-4-4-1-4-6 2-4 2 3-5v-3l-3-2 4-1 2 1-2-7-1-10-29-2-53-7-33-6-11-3-1-3 4-2 4-5 17-10 30-13 27-11 14-5 5 1v3h4l-1-11v-8l-1-3z"
        fill="#FDECEC"
      />
      <path
        transform="translate(1307,446)"
        d="m0 0h2v8l-8 32-8 27-3 7-3-2-4 11-8 16-7 16-2 3-5-2-9-7-3-2 1-4-6-2-9-4-21-10-19-7-7-1v-3h5l12-11 11-8 1-5 13-9 6-4 7-3 15-9 23-13 19-8 2-4z"
        fill="#FBEBEB"
      />
      <path
        transform="translate(1023,813)"
        d="m0 0h3l-3 9-13 27-8 16-10 15-4 3-2 4h-2l-1 4-7 10-5 9h-2l-1 4h-2l-2 5-4 6h-2l-1 4-4-1-7-6-5-10v-3h-2l-1 2-4-8-3-17v-20l4-17 13-6 21-9 18-7 12-4z"
        fill="#FCECEC"
      />
      <path
        transform="translate(1277,705)"
        d="m0 0h5l1 1 2 38 1 41v35l-1 25-3 36-4 31-7 35-6 31-2 4-1 7-2 1 1-14 4-27 4-44 2-14 2-31v-37l-9 2-7 3h-9l1-4 5-5 13-8h2l-3-8-9-10-9-8-12-8-9-5-8-6-7-7-1-5 1-2 11 1 20 9 11 7 10 8 6 5 3 4v2h2l-3-39-3-20-9 1-19 3-21 2-14 3-16 1-9 2h-9l1-3 15-5 17-5 27-9 21-6z"
        fill="#FDFCFC"
      />
      <path
        transform="translate(1248,590)"
        d="m0 0h3l-2 4-4 5-2 7-2 3-5 1-8 11-14 18-9 12-1 5-7 8-4 6h-4l-4 6-14 18-5 7-1 4-16 21-7 9-5 1-2-7-6-34-5-15-3-7v-3l15-9 12-6 3-2v-3l18-11 19-12 8-3 26-16 6-4h3l-2-4 7-4 7-3z"
        fill="#FBEBEB"
      />
      <path
        transform="translate(1107,678)"
        d="m0 0 4 2 7 14 3 14 4 30-1 5-14 5h-3v2l2 1-10 4-24 8-31 13-14 5-21 10-11 6h-3l1-3 8-7 7-8 8-8 7-8 10-10v-5l4-1 1 4 2-6 18-18 7-8 14-14 2-6h2l2-4 5-2 7-7z"
        fill="#FBEBEB"
      />
      <path
        transform="translate(1170,545)"
        d="m0 0 11 2 11 4-2 2 10 3 16 8 16 10 2 4-18 13-14 10v3l-9 6-7 3-20 14-23 16-7 3-6 4-1 4-11 7-2-1 5-12 18-39 3 1 6-16 8-18 8-16 3-7 2-7z"
        fill="#FBEBEB"
      />
      <path
        transform="translate(1196,1099)"
        d="m0 0h8l1 4-10 5-12 4-23 9-24 8-22 7-30 9-13 4h-2v2l-18 6-19 4-2 1 38 7 45 7 52 6h16l13-23 10-22 10-27 1 4-9 29-2 7 3 1 1-2h2l-3 10-9 22-5 10-1 1h-13l-66-5-27-3-37-6-36-7-4-2 5-1-5-2-8-3-1-2-6-2-1-4 1-2h2l1-2v2l11-2 1-2 28-6 33-7 38-9 48-13 20-7 12-6z"
        fill="#BBBDBF"
      />
      <path
        transform="translate(1135,890)"
        d="m0 0h2v9l4-3-1 7-15 37-11 24-8 15-13 21-12 15-14 18 41-15 29-13 17-8 19-8 8-2h2l-1 4-12 6-13 9-20 9-41 17-17 6h-2l4 4 18 9 1 3 20 8 26 7 50 11 2 2-1 3-13 2-6 1-24-4-29-6-27-8-17-6-18-8v-1h5l-2-4v-4l-9 7-1-2 1-9v-4l-3-9 1-2-4-1 5-3 9-10 8-11 6-9 10-13 4-2 10-15 14-27 10-21z"
        fill="#D4D4D6"
      />
      <path
        transform="translate(1146,421)"
        d="m0 0h4l7 84v8l-8-1-19-5-12-4-2-2h3v-2l-10-2-21-6-18-6 2-4 6-8 8-9 1-2h6l1-3 9-7-1-3 18-13 13-8 7-4 5-2z"
        fill="#FBEBEB"
      />
      <path
        transform="translate(164,1188)"
        d="m0 0h18l4 11 2 8h3l5 16 6 17 6 22 3 7 3-9 6-19 5-16 4-10 4-13 3-13 5-1h15l1 1v90l-1 6h-11l-1-75-7 11-9 26-5 18-7 19-1 1h-10l-4-10-8-23-2-12h-2v2h-2l-5-17-5-13-1 67-1 6h-11z"
        fill="#1F1515"
      />
      <path
        transform="translate(1083,777)"
        d="m0 0h6l1 4-11 7-13 7 1 5 7 12 12 15 8 7 10 10 7 6 6 7 14 11 3 3-1 2-6 1v2l2 1v2l4 1 3 5-2 2-15 6-11 6h-2l-1 3-12 6-15 6-16 7-5-1-7 2h-9l-3-2 25-9 18-8 3-4 13-5 22-10 6-3 2-3-6-2-12-8-13-10-9-7-7-9-9-10-13-20-3-5-3 1-10 16-7 6-13 19-2-2-5 6h-2l-1 3h-2v-3h2l1-7-2 1 2-6 13-28-32 13-15 5-5 1 3-3 7-4 5-2 3-2v-3l-7 3h-3v2l-5 1-8 4-2-1 8-5 13-6 13-5 18-8 3-2v-3l15-6 12-4 21-9 3 1 4-1z"
        fill="#D4D4D6"
      />
      <path
        transform="translate(1028,395)"
        d="m0 0h10l31 3 6 1v1l-5 1 27 4 32 8 2 1-1 3-18 11-16 11-5 3-5 2-7 5v4l-10 8-15 15-2-1-9-37-2-8-1 2h-2l-6-20-4-14z"
        fill="#FBEAEA"
      />
      <path
        transform="translate(1591,567)"
        d="m0 0 4 1 19 19 10 13 7 11 6 10-1 4-10 2-45 5-51 8-46 9-11 3v2l-57 13-21 5h-8l2-2 5-1 3-2 5-3v-1h-7l-3-1-9 2-1-3h3v-2l13-2 16-4-5-1 3-2 63-14 56-10 49-7 29-3h5l-6-9-4-7-7-8-4-4v-2l4 2 8 7 2-4-3-7-4-4-7-9z"
        fill="#D74D43"
      />
      <path
        transform="translate(965,777)"
        d="m0 0 2 1-2 3-1 5h5l1 2 5-2 1-3 8-5 3 1 1 1 1 3 5 1 4-1v2h3v2h2l-2 4h-2l-1 3-3 3 16-8 22-10 9-1-1 2-7 1v2l-7 3h-2v2l-4 2-2 4-4 2-3 6 5-1 3-1v3l-15 7-13 6-13 5-19 9-16 8-12 5-14 7v3l-8 4-19 12-8 3-14 9h-2l2-4 18-18 5-2 4-4 2-6 8-7 7-8 12-12h2v-2l6-5 4-5 4-1 5-5v-4l5-5 12-13z"
        fill="#C3433D"
      />
      <path
        transform="translate(438,1215)"
        d="m0 0h14l10 3 6 4 6 7 3 9 1 7v13l-3 12-3 6h-2l-2 4-7 4-10 3-16-1-9-4-7-7-3-5-3-13v-12l2-9 3-8 7-8 9-4z"
        fill="#1D1415"
      />
      <path
        transform="translate(580,1215)"
        d="m0 0h16l10 3 9 8 3 5 3 12v18l-4 11-4 6-5 4-10 4h-19l-10-4-9-10-2-5-1-6v-20l2-9 6-9 9-6z"
        fill="#1A1314"
      />
      <path
        transform="translate(1622,1421)"
        d="m0 0h19l10 3 9 7 6 8 4 10 2 11v10h-92l1-6 5-18 3-5 4 1 2-4 7-8 5-4 8-4z"
        fill="#FCEBEB"
      />
      <path
        transform="translate(1095,239)"
        d="m0 0 2 1-2 2-2 5-2 3v3l10 2v2l-21 8-31 13-23 10-15 9-4 5-4 1 1 2 21 5 37 6 49 6 9 1 1 2-5 5v3l3 4-5 2-27-3-24-4-36-7-30-8h-6v-2h5l-1-22 1-2-2-5 15-8 28-13 18-8 26-13z"
        fill="#BBBEC0"
      />
      <path
        transform="translate(1130,1421)"
        d="m0 0h20l10 3 9 6 8 10 3 10 2 10v10h-88v-7l5-16 6-9 4-5 6-5h2v-2l9-4z"
        fill="#FBEBEB"
      />
      <path
        transform="translate(1063,512)"
        d="m0 0 10 2 36 9 28 8-1 4-10 9-6 5-2 5-15 14-8 7-8 4-13 12-7 6h-2v-44l-2-32z"
        fill="#FBEBEB"
      />
      <path
        transform="translate(814,924)"
        d="m0 0v3l-6 7-6 10-8 27-2 6-3 3-1 4 28-7h5l-4 2-13 4-4 4 6-1h8l-4 2-17 4h-5l1 18 1 7h2l9 26 8 16 8 10 1 3 3 1v2l4 2 8 13 6 8-2 3-5-2-17-12-13-11-9-9-8-7-4-6-9-20-4-16v-18l3-15 7-17 10-17 1-3 2 1-1 2h3l1-5 9-11z"
        fill="#D74E44"
      />
      <path
        transform="translate(1154,540)"
        d="m0 0 1 2-5 17-7 18h-4l-7 20-6 18-12 30-2 9-3 1-5-8-8-7-7-3-13-6-10-3 1-4 13-12 10-10 2 2 1-3-2-1 4-2 4-5 8-7 23-23 5-4 3-4 6-2v-4l8-7z"
        fill="#FBEAEB"
      />
      <path
        transform="translate(923,331)"
        d="m0 0 2 1-7 11-3 6-2 1 2 4 18 6 48 10 13 1 2 2 33 6 70 11 30 5 17 2 1 2-3 1 12 3 11 2v1l-13 2h-7l-11-3h-7l-5-2-3 1-9-3-12-1-6-2h-9l-8-3v-2h-12l-4 3h-23l-10-1 3 10v6l-2-1-2-2v-4l-4-1-7-7-1 2 3 8v4h-2l-7-18-2-5-11-3-26-5-9-1 4 4-1 2-4-1-4 2 8 8h-3l-10-9-11-8-5-2-17-12-14-10-2-4h2l1-3 17-13z"
        fill="#C3443D"
      />
      <path
        transform="translate(1128,692)"
        d="m0 0 2 3 6 34 1 6 5-1 14-18 1-2h2l1 8 6-2 3-2-2 5-4 5 47-14 16-5 2 1-1 2-14 4-7 3-15 5-10 4-3 1h-8v3l-5 1v3l2 1v1l9-1-3 2-21 8-6 1 1 4-16 5-34 13-15 5-38 16-14 5-8 3 1 3h-3-3l-5 1-1-2 4-6 4-2 3-5h3v-2l9-3v-2l7-2h-6l2-2 9-3 32-13 30-10-1-3 17-6-2-24 2 1 1 6h2v-5l-1-1v-8l1-10 1-2-1-6z"
        fill="#D74D43"
      />
      <path
        transform="translate(369,1218)"
        d="m0 0 2 1v68l-4-4-2-4h3v-3l-1-1 1-13-4 2-12-3-3 9-5 5-5 2h-13l-5-3-4-5-3-7v-22l4-9 7-6 3-1h8l9 3 5 6 1 3 10-1-2-6-3-5 1-2 4 2 5-1z"
        fill="#FCEAEA"
      />
      <path
        transform="translate(508,1215)"
        d="m0 0h14l10 3 5 3 4 1 3 6v5l-1 1h-13l-4-7-5-2h-10l-5 2-2 2v7l4 3 26 8 7 2v3h-2v-2h-3l1 2 6 2 4 6v10l-5 10-16 6-4 1h-9l-13-4-5-5-4-7v-5l4-2h6l3 7 6 5h15l5-3 1-2v-8l-8-4-22-6v-2l2-1-8-5-3-5v-12l4-6 6-5z"
        fill="#1C1415"
      />
      <path
        transform="translate(1282,444)"
        d="m0 0 5 1 2-1 5 1v2h2v2l6-1-2 5-12 5-16 8-16 9-16 10-7 3-16 11-1 5-13 10-10 9h-7l-8 1-3-1v-6l-3-1 11-8 5-4 7-1-2-4 18-13 21-14 9-4 23-13 6-3 3-3z"
        fill="#EB5549"
      />
      <path
        transform="translate(969,321)"
        d="m0 0 5 2 7 9 6 8 6 4 4 5 9 11 7 6v1l-13 2h-30l-4-4-24-9-1-3 4-10 9-11 10-8z"
        fill="#FBEBEB"
      />
      <path
        transform="translate(583,1224)"
        d="m0 0h11l7 3 6 7 3 10v14l-3 10-5 6-7 3h-14l-5-3-5-6-3-7v-20l3-7 4-6z"
        fill="#F9F3F3"
      />
      <path
        transform="translate(443,1224)"
        d="m0 0h8l8 4 4 5 3 6v24l-5 9-8 5-8 1-10-2-7-8-3-6v-21l4-10 6-5z"
        fill="#FCEBEB"
      />
      <path
        transform="translate(1017,345)"
        d="m0 0 5 4 7 8 10 7 12 7 10 6 7 1 31-11 23-11h3l-1 4h2v5l-16 9-20 8-6 2v2l8 2v1l-13-1-62-10-21-4-3-2-18-2v-1l25-1 12-2-6-5 3-1 1-5v-8h7z"
        fill="#BABDBF"
      />
      <path
        transform="translate(651,1187)"
        d="m0 0h15l2 1v9h-14v5l-2 4-1 10h13v9h-13v59l-1 1h-13l-1-59-9-1v-8l1-1h9v-19l4-1 3-5z"
        fill="#191314"
      />
      <path
        transform="translate(325,1215)"
        d="m0 0h15l10 3 8 7 4 8-1 3-10 1-5-8-5-3-5-1h-8l-6 3-5 6-2 6v22l3 7 5 6 3 1h13l7-3 6-12 10 1-1 8-2 5h-2l-2 4-9 6-6 2h-15l-10-5-7-7-4-9-2-8v-12l2-12 4-8 5-5 8-5z"
        fill="#1B1414"
      />
      <path
        transform="translate(1107,236)"
        d="m0 0 3 4 3 10 10 29 9 22 8 16 9 15 5 12 2 15v4l-5-1-5-5-4-2-4-4-1-4-6 2-4 2 3-5v-3l-3-2 4-1 2 1-2-7-1-10-13-1v-1h9l-4-10-8-19-6-15-4-13v-4h4l-1-11v-8l-1-3z"
        fill="#FDFCFC"
      />
      <path
        transform="translate(728,1198)"
        d="m0 0h7l5 2v2l6 2 5 4 4 7 1 10-4 9-4 5-11 4h-12l-6-3-5-5-4-7v-10l3-8 8-8z"
        fill="#F3E4E4"
      />
      <path
        transform="translate(684,1191)"
        d="m0 0h2v25h13l1 1v7l-1 1h-13l1 50h12l2 3v7l-3 1h-15l-6-5-2-3-1-8v-45h-7v-9h7v-19z"
        fill="#271717"
      />
      <path
        transform="translate(1583,1409)"
        d="m0 0v3l-10 9-8 10-8 16-5 25-1 13v15l2 13 2 11 4 9 3 7 5 8v2h2l3 7 10 9 6 3 13 4 16 4 33 1v1l-5 1 5 1v2l-18 2h-17l-23-6-11-5 2-2 4 1-9-5-9-7-4-1-5-8 1-1-5-5 1-3-1-2-2 1-7-18-3-15v-9h1l1-18 1-9 2-5-2 2-2 8h-1v-7l3-11 1-9 5-12 8-13 1 3 7-8 12-11z"
        fill="#F9F2F2"
      />
      <path
        transform="translate(961,378)"
        d="m0 0h9l26 5 11 4 6 12 6 16 8 24 2 9-1 4-5-9-11-13-7-8-11-12-3-3-5-2-3-4-3-5-11-10-8-6z"
        fill="#FBEBEB"
      />
      <path
        transform="translate(923,331)"
        d="m0 0 2 1-7 11-3 6-2 1 2 4 18 6 48 10 13 1 2 3h-4v2l8 1 7 3 6 5-1 5-5-1v-2l-11-3-26-5-9-1 4 4-1 2-4-1-4 2 8 8h-3l-10-9-11-8-5-2-17-12-14-10-2-4h2l1-3 17-13z"
        fill="#C5443E"
      />
      <path
        transform="translate(1214,1110)"
        d="m0 0 1 4-9 29-2 7 3 1 1-2h2l-3 10-9 22-5 10-1 1h-13l-66-5-27-3-6-1v-1h19l9 1 29 2h9l-6-2-50-8-28-5v-1l13 1 38 6 52 6h16l13-23 10-22z"
        fill="#D5D5D7"
      />
      <path
        transform="translate(1365,1401)"
        d="m0 0 3 2v3h2l7 20 5 12 4 12 2 8 3-1 13 34 6 19 13 35 8 24v7l-3-2-1-5-4-2-2-3-4-13-14-35-5-17-14-35-5-10-9-27-4-11-3-11z"
        fill="#F8EEEE"
      />
      <path
        transform="translate(1591,567)"
        d="m0 0 4 1 19 19 10 13 7 11 6 10-1 4-10 2-10 1h-15l2-2 8-2 2 1v-2l3 1 1-2h2v-2h-6-4l-3 1-4-2v2l-9 1h-11l-9 2-20 2-4 1-18 2-2 1-15 2-3 1h-10l2-2 41-7 45-6 25-2-6-9-4-7-7-8-4-4v-2l4 2 8 7 2-4-3-7-4-4-7-9z"
        fill="#C3433D"
      />
      <path
        transform="translate(1157,775)"
        d="m0 0h5l3 5v10l-6 36-4 18-4 16h-1v-16l-2 1-3 8-2 13-3 3h-6l-11-6-12-11-5-6-8-7-5-5 1-3 6 6v2l4 2 14 14 12 9 5 1 3-9 5-17 6-32 3-22 3-8h2z"
        fill="#FDFCFC"
      />
      <path
        transform="translate(1056,648)"
        d="m0 0 7 2 2 4 9 5 8 7 3 4-1 3-16 10-13 9-12 8h-5l1 2h3v2l-10 3 2-8 4-8h3l7-16 6-21z"
        fill="#FAEBEB"
      />
      <path
        transform="translate(417,1577)"
        d="m0 0 10 2h6l2-2h11l15 1 9 11 10 10-1 4 4 2 3 3 5 2 11 10 13 9 13 6 18 4 22 2v2l7 1-2 2-11 1h-17l-19-4-14-6-15-10 1-2-5-1v-2h-2l-2-5-7-8-6-4-7-7-4-1-2-3h2l-2-2-3-1v-3l-2-1-1 2-3-3v-2h-2l-1 2-23-4-10-3z"
        fill="#FAF5F5"
      />
      <path
        transform="translate(1130,416)"
        d="m0 0h3l1 6 5 1-1 3-15 9-17 12-3 3 1 3-6 4-5 5h-6l-2 4-2 3h-4l-1 3h-2l-1 2-6-2-1-5 3-6 6-8 3-3-2-1 9-7 8-3 11-8 14-9z"
        fill="#EB5549"
      />
      <path
        transform="translate(396,1215)"
        d="m0 0 12 1 2 2-1 7-1 3-12-1-4 1-2 6-5-2-1 10-1 43h-11v-68l1-1h9v8h2l1-4 5 1 2-4z"
        fill="#191314"
      />
      <path
        transform="translate(1042,314)"
        d="m0 0 12 1 57 7 9 1 1 2-5 5v3l3 4-5 2-27-3-24-4-15-3 4-1-2-8v-3l-8-2z"
        fill="#D5D5D7"
      />
      <path
        transform="translate(1130,1421)"
        d="m0 0h20l10 3 9 6 8 10 3 10 2 10v10l-13-1 3-2 1-10-1 2h-2l-2-9-5-8-7-9-6-3-11-1-12 2-6 3 1-3 4-2-5 2-9 7-8 11-1 1v9l2 6-5 3-5-1v-7l4-13 6-9 4-5 6-5h2v-2l9-4z"
        fill="#FAF5F5"
      />
      <path
        transform="translate(992,817)"
        d="m0 0 1 4-5 4-7 3-6 3-1 2-30 13-8 3-4 17v25l-3-2-1-9-3-2h-6l-1-9-3-1-1 18h-1l-1-31 6-2-1-4 24-11 18-8 7-4 3 1 11-5 2 1v-2l9-3z"
        fill="#BCBEC0"
      />
      <path
        transform="translate(1059,1490)"
        d="m0 0h1l4 30 3 8 4 9 4 8v2h2l3 4 2 6 5 5 6 2 6 4 15 6 13 3 33 1v1l-6 1 6 1-1 2h-9l-1 1-8 1h-17l-6-2 1-2-14-5-9-4h-2l8 4 13 4-1 2-11-3-10-5-14-9-6-7-8-14-6-12-5-22v-10l2 3 1-7z"
        fill="#F8F0EF"
      />
      <path
        transform="translate(1095,239)"
        d="m0 0 2 1-2 2-2 5-2 3v3l10 2v2l-21 8-31 13-9-3-3-3 1-5-3-1 20-9 26-13z"
        fill="#D4D4D6"
      />
      <path
        transform="translate(1622,1421)"
        d="m0 0h19l10 3 9 7 6 8 4 10 2 11v10l-11-1v-2h-2l2-5 2-6-1-4-3 2-3-8-8-10-5-4-5-2h-16l-10 4-3 1v-2l6-4-9 5-9 6-4 9-5 2h-2l1-7 2-3 4 1 2-4 7-8 5-4 8-4z"
        fill="#FAF6F6"
      />
      <path
        transform="translate(585,1231)"
        d="m0 0 8 1 5 5 3 7 1 4v8l-1 6 3-4-1 5-5 7-6 2h-9l-5-4-1-6h-2v-19l4-9z"
        fill="#FDECEC"
      />
      <path
        transform="translate(1025,719)"
        d="m0 0 3 1 6 18v5l-8 6v3l-20 14-11 7-13 7-7 5h-2l2-4 13-13 4-1 12-15 2-5 12-17z"
        fill="#FAEBEB"
      />
      <path
        transform="translate(1196,1099)"
        d="m0 0h8l1 4-10 5-12 4-23 9-24 8-22 7-30 9-13 4h-2v2l-8 3-3-1 5-2h3l1-3 21-7 14-4 11-4 1-2 6-1v-2l-6 2-10 1-5 2h-12v-1l25-6 44-12 20-7 12-6z"
        fill="#D5D5D7"
      />
      <path
        transform="translate(443,1224)"
        d="m0 0h8l8 4 4 5 3 6v24l-5 9-8 5-8 1-3-3 10-2 3-3h2l2-7-2-1 1-11-2-11-3-5-5-3h-7l-5 3-3 6-1 9v8l2 4 1 5 5 7v2h-5l-7-8-3-6v-21l4-10 6-5z"
        fill="#F9F4F4"
      />
      <path
        transform="translate(275,1216)"
        d="m0 0h10l1 1v67l-1 1h-10l-1-1v-67z"
        fill="#281717"
      />
      <path
        transform="translate(1431,595)"
        d="m0 0 3 3-2 7-8 11-9 11-11 13-9 11-3 5-5 3h-2v2l-2 1v3h-2v-3h-2l-2-3v-3l2-3h2l1-2 2-2h3l1-3 2-8h2l1-5 4-5 5-2v-2h2l1-3 3-1 1-5 8-7 3-3 2-3 4-1 2-5z"
        fill="#EA5549"
      />
      <path
        transform="translate(493,1220)"
        d="m0 0 2 4-2 4v12l4 6 7 4-3 1v2l10 2 15 4 6 4v8l-4 5-3 1h-15l-7-6-2-3v-4h-5v-2h2l-2-3-2-10-6-5-1-2v-13l4-7z"
        fill="#F9F3F3"
      />
      <path
        transform="translate(473,1357)"
        d="m0 0h14l12 3 13 8 10 9 9 11 5 10v9l5-1 4 13v17l-3-3-3-11h-3l-5-14-1-5 1 2h2l1-2-4-2-2-2-1 2-6-10-11-12-11-9-16-7-5-3-4-1z"
        fill="#FBF6F6"
      />
      <path
        transform="translate(1867,1396)"
        d="m0 0h5l6 4 5 7 1 4v8l-4 6-5 4-9 1-7-4-4-7v-7l4-10 6-5z"
        fill="#F5F0F0"
      />
      <path
        transform="translate(1190,854)"
        d="m0 0h6v3l-10 7-16 8-8 6h-2l-1 7 1 3 4 2 9 7 15 11 10 5 10 6 5 6v3l-8-1-24-12 2-1 6 2v-2l-4-2-12-8-3-3v-2l-4-2-5-5-4-2v-2l-6-2-1-5 20-12h2v-3l-10 3-11 5-5-1 2-10 2 4v2l3 1 20-10z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(310,1219)"
        d="m0 0 2 1-2 5-3 4-2 5-2 12v12l4 13 4 6 4 4 1 4-6-1-5-5-5-8-4-11-2 1-3 15-1 2-4 1 1-54 3 1 2 7 2 9 1 1 4-10 6-9z"
        fill="#FBF8F8"
      />
      <path
        transform="translate(342,1457)"
        d="m0 0h1l2 13 3-2 7 29 1 11h2l4 9 3 6v3h2v3h2v3h2l8 11 6 5v2h5l-1 3-7 2-6-5v-3l-2-1-1 2-2-4-6-5-5-8v-3h-2l-8-17-6-25-2-10-1-16z"
        fill="#F9F2F1"
      />
      <path
        transform="translate(938,1463)"
        d="m0 0 5 1 22 11 12 8 8 7 2 4h5l3 6 1 8v14l-4 10-4-1-1-2 2-3v-9l-2-13-2-3v2l-3-1v-2h-2l-7-8-9-7v-3l-7-4 1 2-5-2-10-5 1-2-6-5z"
        fill="#F9F3F3"
      />
      <path
        transform="translate(757,1549)"
        d="m0 0h1l2 9v8 2l1 3-3 5-7 3v-3l-5 1h-22l20 1h6v2l-67 1h-45l-8-2-3-3 7-1 121-1z"
        fill="#FDFCFC"
      />
      <path
        transform="translate(176,1211)"
        d="m0 0 3 3 8 23v5h2v-2h2l6 21 8 23 1 3-4-1-6-9-4-12-6-15v-2l-4 1-1 4-2 24-2 2-1-1z"
        fill="#F9F2F2"
      />
      <path
        transform="translate(220,1461)"
        d="m0 0 6 1 4 3 6 3 7 2 15 8 12 9 6 7 1 4h2l2 10v14h-2l-1 6-3-4-3-18-1-2 1 9-2-1-3-10-6-9-9-8 1-2-5-4 1 1-3 1-2-1v-3l-8-5-10-5-5-2z"
        fill="#F9F4F4"
      />
      <path
        transform="translate(244,1208)"
        d="m0 0h1v32l-1 45h-1l-1-7-2-3-1-6v-9l-1-10-2-3-3 4-3 11-3 3 1-7h-2l1-11 9-26 7-11z"
        fill="#FBF6F6"
      />
      <path
        transform="translate(1122,356)"
        d="m0 0h3l-1 4h2v5l-16 9-20 8-6 2v2l8 2v1l-13-1-6-1v-2l-2-1-1-4-2-2 31-11z"
        fill="#D4D4D6"
      />
      <path
        transform="translate(625,1217)"
        d="m0 0h1l1 8h9v59l-5-5-1-2v-12l2-23-1-10-4-2-1 1-1 11-1 2-1 10h2l1 14-2 4h-2v-5l-3 3 1-9v-18l-4-14-6-7v-2h11z"
        fill="#FBF8F8"
      />
      <path
        transform="translate(1246,1039)"
        d="m0 0 2 1-2 9-11 31-3 9-1-3 2-7-3 2-2 8-3 8-4 14-5 14-4 7-2-1 3-14 4-11 1-10-9-3v-3l2-1 9-1 2-4-8-1-4-2 14 1 8-16 9-20z"
        fill="#FDFCFC"
      />
      <path
        transform="translate(1139,753)"
        d="m0 0 10 1 2 3-1 4-6 4-11 4-11 5-21 9-3-1 11-6 3-4h-10l-9 2v-3l12-5z"
        fill="#FBF9F9"
      />
      <path
        transform="translate(1153,430)"
        d="m0 0 4 4 2 10-1 4v14l6 18 2 7v10l2 8v7l-2 3-5 1-3-3-3-39-3-17-1-13v-10z"
        fill="#EB5549"
      />
      <path
        transform="translate(163,1539)"
        d="m0 0h1l1 25 6 4 12 4 21 3 3 1 14 1v2h29l5-2h7l1 2-12 3-12 1-5 1 4-3-7 1v3h-16l-37-7-12-6-6-8v-11l2-1z"
        fill="#F9F2F1"
      />
      <path
        transform="translate(226,1203)"
        d="m0 0h2v4l4-4v6l-5 15-4 10-6 19-4 15-3 2-6-18-6-21 1-4 1 2v-4h2l3 9 3 8 1 2 4-1 12-36z"
        fill="#F8F3F3"
      />
      <path
        transform="translate(673,1197)"
        d="m0 0h1v19h-7v9h7v50l-2 3-1-4v-22l-2-17-2-3h-7l-3 2 1-6-4-1v12l3-3-1 23-2 11-2 1v-45l11-1 1-9h-12v-6l2 4h4l-2-4-2-1h3l2 2 7-1 3-3-2-6v-3l3 2v2h2z"
        fill="#F8F2F3"
      />
      <path
        transform="translate(166,1363)"
        d="m0 0h2v11l-2 6v16l2 14 4 10 6 9 5 6 4 3 5 1 11 8 6 4v3l10 5-1 2-4-1v-2l-6-1-1-1-2 2-8-6v-2l-5-1-5-5v-2l-9-7-6-5-2-3v-3l-3 1-1-7-3-8-2-11-2-4 2-17 2-9zm-4 22-1 4 2-1z"
        fill="#F6EFEF"
      />
      <path
        transform="translate(876,1567)"
        d="m0 0 7 1 21 5 18 3 11 1 1 1 19 1 8 1v2h-12v2l-8 1h-14l-31-6-15-5-5-5z"
        fill="#F9F0F0"
      />
      <path
        transform="translate(1056,626)"
        d="m0 0 3 2-2 6v7l-3 17-5 17-8 18-1-2h-2l1-9 4-10v-9l2-6-1-5 4-13h2v-3l3-1 1-4-1-3z"
        fill="#EC5549"
      />
      <path
        transform="translate(1337,621)"
        d="m0 0 5 1 12 15 8 13 6 12 2 1 10-17 6-8 1-2h5v2l-2 1v7l-3 3-3 1-3 3h-2l-2 5 2 1v3h2l-1 3h-6l-1 3-5 2-8-3v-6l1-2-2-1v-3l-4-2-2-4 1-3h-2v-3l-2-1v-3l-2-1 1-2-2-1-4-8-4-4z"
        fill="#D84E44"
      />
      <path
        transform="translate(1118,672)"
        d="m0 0 4 1 6 15v7l1 5-1 4-1 7v8l1 3-1 3h-2l-4-10-3-18-5-12-1-7 3-4z"
        fill="#EB5549"
      />
      <path
        transform="translate(728,1198)"
        d="m0 0h7l5 2v2l6 2 5 4 4 7 1 10-4 9-4 5-11 4h-12l-6-3-5-5-4-7v-10l3-8 8-8zm-2 5-4 2v2l-4 2-1 3h-2l-2 6v7l3 8 7 6 2 1h14l8-5 4-5 1-3v-12l-7-8-9-4z"
        fill="#381C1B"
      />
      <path
        transform="translate(162,1195)"
        d="m0 0h1v90l-3-1-2-7-1-12v-52l1-10h1l1 9v-15z"
        fill="#FDFCFC"
      />
      <path
        transform="translate(1743,1399)"
        d="m0 0h2v128l-2-4-1-13-1-44v-51l2-12-2-1z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(369,1218)"
        d="m0 0 2 1v68l-4-4-2-4h3v-3l-1-1 1-13-4 2-2-1 2-4 1-4v-8l-2-8-7-1 3-2 2-1-2-6-3-5 1-2 4 2 5-1z"
        fill="#FDFCFC"
      />
      <path
        transform="translate(1365,1401)"
        d="m0 0 3 2v3h2l7 20 5 12 4 12v9l-1 5-3-3-5-13-7-21-4-11-3-11z"
        fill="#F9F0F0"
      />
      <path
        transform="translate(726,1208)"
        d="m0 0h7l8 4 3 3-1 4-6 3h-2l4 7 4 1-1 5h-5l-6-9v-2l-3 1-1 10-4-1-1-3v-18l2-4z"
        fill="#281817"
      />
      <path
        transform="translate(511,1224)"
        d="m0 0h10l6 3 6 10-6-2v-2h-2v-2l-11 1 2 4 13 3v2l5 2 3 3v1l-9-1-20-6-5-4v-7l4-4z"
        fill="#F7EFEF"
      />
      <path
        transform="translate(1210,974)"
        d="m0 0h6l-1 4-21 10-10 4-13 7-3 1 2-4 9-5h3l1-4-18 6-22 10-4-1 15-8 31-12 19-6z"
        fill="#FDEFEF"
      />
      <path
        transform="translate(319,1233)"
        d="m0 0 1 3 1 1 1 3-1 10 2 16 5 5 4 1h8l7-4 3-3-1 5-5 5-5 2h-13l-5-3-4-5-3-7v-22l3-5z"
        fill="#F9F3F3"
      />
      <path
        transform="translate(721,1208)"
        d="m0 0 2 1v22l1 3 3 1v-10l4-1 6 10h5l1-4h3l2-5 2-9 2 1v10l-4 7-5 4-4 2h-14l-8-6-3-5v-3l2-1 4-8-2-6z"
        fill="#F8F5F5"
      />
      <path
        transform="translate(555,1248)"
        d="m0 0h1v13h2l2 7 2 5 7 8 10 4 17 2 7 1-2 2-8 2h-14l-16-9-3-2v-2l-4-2-3-1v-2l4 1-5-5 2-12v-2h-2l-1 3-1-2 1-2-4 1 3-3 4-2z"
        fill="#F9F3F3"
      />
      <path
        transform="translate(1219,1171)"
        d="m0 0h28l4 10 2 6 4-9 4-7h3v22h-3v-11l1-4h-2l-5 15-5-1-3-13v-3h-2v17h-3v-13l1-7-14 1v19h-3v-19h-7z"
        fill="#43201E"
      />
      <path
        transform="translate(1e3 748)"
        d="m0 0 3 3 5 2 2 4 2 1-11 8-11 7-13 7-7 5h-2l2-4 13-13 4-1 12-15z"
        fill="#F9F6F6"
      />
      <path
        transform="translate(1486,1431)"
        d="m0 0h1l-1 9-3 7-5 17-3 10-9 24-2 8-3 8-2-1 2-10 3-12 4-14 2-3 6-18v-3l-2-1 3-10 5-2 2-6z"
        fill="#F7EFEF"
      />
      <path
        transform="translate(901,851)"
        d="m0 0h9l3 3-4 4-18 11-8 3-14 9h-2l2-4 18-18 5-1h2l6-5z"
        fill="#D64C43"
      />
      <path
        transform="translate(1808,1425)"
        d="m0 0h20l7 4 1 2-6 2h-16l-6 1v-2l4-1-9 2-8 5-5 7h-2l-2 4-5 3 1-5 3-6 2-3 5-2 7-7z"
        fill="#F8F1F1"
      />
      <path
        transform="translate(258,1193)"
        d="m0 0h3l2 9v50l-2 9h-2l-1-2-1-38v-27z"
        fill="#FDFCFC"
      />
      <path
        transform="translate(1291,593)"
        d="m0 0 6 1 12 8 5 5 8 10 5 6v2l4 2h-3l-13-7-11-10v-2l-4-2-5-5-4-6z"
        fill="#D84E44"
      />
      <path
        transform="translate(951,1356)"
        d="m0 0h34l14 3 13 6-2 4-8-1-18-7h-25l-18 2v-2l22-3h-13z"
        fill="#FAF5F4"
      />
      <path
        transform="translate(422,1219)"
        d="m0 0 2 1-2 4-5 8-2 6-1 7v12l4 15 6 7v3l-6-2-6-8-2-5-1-8v-13l1-14 1-4h3l4-7z"
        fill="#F8F3F3"
      />
      <path
        transform="translate(1614,614)"
        d="m0 0 1 3-3 1-2 2-4 1-4-2v2l-9 1h-11l-9 2-20 2-4 1-18 2-2 1-15 2-3 1h-10l2-2 41-7 45-6 25-2z"
        fill="#C7453E"
      />
      <path
        transform="translate(1318,1425)"
        d="m0 0h22l8 3-1 4-4 1-17-1 2-2 6-1-16 2-6 3-3 1-2 4-6 7-5 2 1-5 8-10 10-7z"
        fill="#F7EFEE"
      />
      <path
        transform="translate(1015,411)"
        d="m0 0 1 2h2l7 19 4 16-1 4-5-9-11-13-3-4 1-4 3-1z"
        fill="#F8F4F4"
      />
      <path
        transform="translate(923,331)"
        d="m0 0 2 1-7 11-3 6-2 1 2 4 14 5v3l-1 4-5-2-2-4-5-1-7-3-7-6v-2h2l1-3 17-13z"
        fill="#D74D43"
      />
      <path
        transform="translate(878,1421)"
        d="m0 0 5 4h3 4l6 8 17 13 8 5-3 6-4-1-9-7-4-6-7-6v-2l-5 1-7-8-4-5z"
        fill="#FAF6F6"
      />
      <path
        transform="translate(1323,634)"
        d="m0 0 4 2 5 4 4 4v2h3l3-6 2 6-1 6h5l1 3 2 1v2l1 2 4 1 1 3 2 1-1 4-9 2-1 3-2 1h-8l-4 3h-7l-7 3h-6l1-2 30-9 9-2v-3h-2l-7-8-3-3-1-4-5-2-13-13z"
        fill="#D84E44"
      />
      <path
        transform="translate(1097,1514)"
        d="m0 0 1 2h2l8 12 8 9 7 5v2l10 2 3 3h-11l-8-4-8-6-8-10-3-5z"
        fill="#F7F1F1"
      />
      <path
        transform="translate(514,1567)"
        d="m0 0h4l-2 5-5 2v7l5 5 7 6v5l-4-1-15-14-6-6 1-4 7-2z"
        fill="#F7EFEF"
      />
      <path
        transform="translate(1e3 368)"
        d="m0 0h13l9 3 2 2-1 5 3 1-9-1-21-4-3-2-18-2v-1z"
        fill="#B2A9AB"
      />
      <path
        transform="translate(1028,395)"
        d="m0 0h10l1 2h5l1 2-5 5-1 7 2 8v6l-3 1v-2h-2l-7-21z"
        fill="#FCFAFA"
      />
      <path
        transform="translate(1583,1409)"
        d="m0 0v3l-10 9-8 10-8 16-4 18-2-3-2 2v-8l4-14 5-12 4-4v3l7-8 12-11z"
        fill="#F9F3F3"
      />
      <path
        transform="translate(1118,423)"
        d="m0 0 5 1-4 4-6 5v3l-4 1v-2h-2v2l-8 5-5 1v2h-3l-1 3-6 4h-2v2l-7 3 2-4 3-3-2-1 9-7 8-3 11-8z"
        fill="#D94E44"
      />
      <path
        transform="translate(654,1197)"
        d="m0 0h13l1 4 2 6-4 4-7 1-3-2 2 3 3 2-7-1-1-2-2 4v-10z"
        fill="#F6E3E2"
      />
      <path
        transform="translate(1867,1396)"
        d="m0 0h5l6 4 5 7 1 4v8l-4 6-5 4-9 1-7-4-4-7v-7l4-10 6-5zm-1 3-7 8-1 2v11l6 7h9l7-6 1-2v-11l-7-8-2-1z"
        fill="#4A2220"
      />
      <path
        transform="translate(659,1530)"
        d="m0 0 2 1 4 9 10 2h30l11-1 38 1v2l-68 1h-27z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(1459,1513)"
        d="m0 0 2 1-9 33h-2l-1-9-5-15v-6l1 2 8 1 2-1 3-5z"
        fill="#F5EAEA"
      />
      <path
        transform="translate(273,1217)"
        d="m0 0h1v19l-1 36-2 3-2-4-1-31 1-16z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(1286,1399)"
        d="m0 0 3 3-1 4 4 7 6-1v-1h-3v-2l9-6h2l-2 6-8 10-7 14h-2l-1-2z"
        fill="#FAF6F6"
      />
      <path
        transform="translate(1020,772)"
        d="m0 0h1v11l-4 4-19 10h-3l1-3 8-7 7-8 6-6z"
        fill="#F7F3F3"
      />
      <path
        transform="translate(1062,520)"
        d="m0 0 2 1 2 32v35l-1 9 11-9 4-4 2 1-4 5-2 4-2 2-6 2-1 5-3 2-1-1v-57z"
        fill="#D84E44"
      />
      <path
        transform="translate(743,1208)"
        d="m0 0 4 1 5 6-3 10-3 5h-7l-4-5v-3l8-4-1-4-2-4 4 1z"
        fill="#F6E3E3"
      />
      <path
        transform="translate(274,1188)"
        d="m0 0h12v16l-10 1-2-1z"
        fill="#331B1A"
      />
      <path
        transform="translate(396,1509)"
        d="m0 0 4 1 3 5 18 18 6 4 1 3 2 1-7-1-4-2v-2l-5-2-5-5-9-10-4-6z"
        fill="#F8F3F3"
      />
      <path
        transform="translate(530,1403)"
        d="m0 0 1 2 10 1 4 13v17l-3-3-3-11h-3l-5-14z"
        fill="#FAF3F3"
      />
      <path
        transform="translate(328,1224)"
        d="m0 0h8l9 3 3 3v4l-9-2-1-1h-10l-8 8-1-3-1-1-2 1 2-5 7-6z"
        fill="#F6EDED"
      />
      <path
        transform="translate(980,375)"
        d="m0 0 6 1 6-1 14 4 7 6-1 5-5-1v-2l-11-3-10-2v-1l4-1-10-1-2-2z"
        fill="#D74D43"
      />
      <path
        transform="translate(1616,627)"
        d="m0 0h18l-3 3-12 1-26 4-12-1v-3z"
        fill="#F8F4F4"
      />
      <path
        transform="translate(388,1555)"
        d="m0 0 5 1 8 6 8 4v2l9 3 11 4 5 2-1 2-8-1-9-3-14-7-9-7-3-1v-2l-4 1-1-3z"
        fill="#F7F1F1"
      />
      <path
        transform="translate(1044,684)"
        d="m0 0 7 3-3 7 3 1-8 5h-5l1 2h3v2l-10 3 2-8 4-8h3z"
        fill="#F5F0F0"
      />
      <path
        transform="translate(1775,1398)"
        d="m0 0h2l3 4-1 2-2-1 4 8h6l-2 5-5 8-4 9h-1z"
        fill="#FBF7F7"
      />
      <path
        transform="translate(1865,1404)"
        d="m0 0 6 1 3 1 1 2v6l-1 4v5h-2l-1-4-4-1-1 5-3-1v-14z"
        fill="#321A1A"
      />
      <path
        transform="translate(1595,1531)"
        d="m0 0 4 1 5 5 7 6 13 3 3 3 25 1v1h-29l-13-4-11-8-4-4z"
        fill="#ECDBDA"
      />
      <path
        transform="translate(673,1197)"
        d="m0 0h1v19l-7 1-2 2-1-2-12-1v-6l2 4h4l-2-4-2-1h3l2 2 7-1 3-3-2-6v-3l3 2v2h2z"
        fill="#FDFCFC"
      />
      <path
        transform="translate(961,378)"
        d="m0 0h9l12 2v2l3 1-3 5-1 2-7-1-7-4-6-5z"
        fill="#F9F6F7"
      />
      <path
        transform="translate(571,1505)"
        d="m0 0h1l-1 11-5 10-6 9-10 9-5 6-3 1 2-4 7-7 8-12 7-12 3-9h2z"
        fill="#F8F1F1"
      />
      <path
        transform="translate(1067,629)"
        d="m0 0 9 1 13 6 1 5 2 2-5 1v-2l-5-1-12-6-3-1-1-4z"
        fill="#EC5549"
      />
      <path
        transform="translate(1041,439)"
        d="m0 0 4 3 4 17v9h-4v-3l-2-2v-3h-2l-1-8 1-3-2-1v-5l2-1z"
        fill="#C4443D"
      />
      <path
        transform="translate(1877,1409)"
        d="m0 0 2 1v2h2v7l-5 6-3 2h-9l-2-2v-3l4-1 1-3 5 1v4h2l-1-8 2-1z"
        fill="#F6F1F1"
      />
      <path
        transform="translate(203,1401)"
        d="m0 0 4 1 7 10v2l5 2 6 5v2l5 2v3l-10-5-12-9-5-7z"
        fill="#F8F2F2"
      />
      <path
        transform="translate(166,1363)"
        d="m0 0h2v11l-2 6-2 15-4 4-1-4 2-17 2-9zm-4 22-1 4 2-1z"
        fill="#FAF1F0"
      />
      <path
        transform="translate(1015,460)"
        d="m0 0 5 1 13 23 3 7v5l-1-2h-2l-4-6-2-6-1-5h-2l-3-6v-2l-4-2-2-6z"
        fill="#F9F4F5"
      />
      <path
        transform="translate(876,1369)"
        d="m0 0h3v8l-2 4h-2l1 2v9l-1 1-1 18-2-4-1-7 1-18 2-11z"
        fill="#FBF5F5"
      />
      <path
        transform="translate(511,1260)"
        d="m0 0 6 1 4 2 2 4-1 4-7 1-6-3-2-3 1-5z"
        fill="#FDECEC"
      />
      <path
        transform="translate(652,1226)"
        d="m0 0h6l-4 1v12l3-3-1 23-2 11-2 1z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(914,1401)"
        d="m0 0 4 1v2h2l9 12 7 4v2h3l-1 3-4-1-14-10-6-8z"
        fill="#F8F3F3"
      />
      <path
        transform="translate(875,1381)"
        d="m0 0h2l1 18 3 14 5 10-1 2-6-5-2-7h-2l-1-11v-9l1-1z"
        fill="#F0E0DF"
      />
      <path
        transform="translate(1095,1495)"
        d="m0 0h54v1l-35 2-13 3-4-1z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(483,1271)"
        d="m0 0 7 6 7 3 9 5 6 2 4 2-2 2h-6l-12-7-11-7h-7v-3z"
        fill="#FBF8F8"
      />
      <path
        transform="translate(1479,498)"
        d="m0 0 9 2-1 6-4 3-2-1v2l-10 1 2-5 2-1 1-4 3-1z"
        fill="#C2433D"
      />
      <path
        transform="translate(1195,979)"
        d="m0 0 3 1-23 8h-2v2l-21 9-9 4-4-1 15-8 31-12z"
        fill="#FDF8F8"
      />
      <path
        transform="translate(1530,1406)"
        d="m0 0h3l-2 10-3 7-5 13-5 5-1-2 7-18 5-12z"
        fill="#F4E3E3"
      />
      <path
        transform="translate(1581,1495)"
        d="m0 0h42v1l-24 2-11 3-7-1z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(1251,1425)"
        d="m0 0h1l2 15 1 21-1 7-2-3v-5l-2-1v-33z"
        fill="#FDFCFC"
      />
      <path
        transform="translate(1028,395)"
        d="m0 0h10l2 4-5 11-4-1-3-11z"
        fill="#F8F3F3"
      />
      <path
        transform="translate(1857,1411)"
        d="m0 0h1l1 9 5 6h9l6-5 3-3 2-3-1 6-6 7-6 2-8-1-5-4-3-6v-7z"
        fill="#642F2C"
      />
      <path
        transform="translate(995,1456)"
        d="m0 0 5 2 10 5 5 5 5 6v5l-4-2-8-10-12-9z"
        fill="#F6F1F1"
      />
      <path
        transform="translate(948,1427)"
        d="m0 0 13 5 8 4 8 6 3 3-2 1-14-8-17-8-1-2z"
        fill="#F2E3E2"
      />
      <path
        transform="translate(470,1223)"
        d="m0 0 5 2 4 4 3 11-1 7-2 3-2-5-2-12-5-8z"
        fill="#F9EEEE"
      />
      <path
        transform="translate(1544,624)"
        d="m0 0h6l-1 3-18 2-2 1-15 2-3 1h-10l2-2z"
        fill="#C8463F"
      />
      <path
        transform="translate(1581,1464)"
        d="m0 0 3 1v2l3 1 80 1v1h-87z"
        fill="#D5D5D7"
      />
      <path
        transform="translate(566,1218)"
        d="m0 0 2 3-6 7-3 7-2 8-3 1-1-7 3-7 6-9z"
        fill="#F9F2F2"
      />
      <path
        transform="translate(1219,1171)"
        d="m0 0h18v2l-8 1v19h-3v-19h-7z"
        fill="#251717"
      />
      <path
        transform="translate(1014,738)"
        d="m0 0 1 2v8l4 1-5 1 2 5h2v2l-4 1-1-5-7-2 1-5 4-6z"
        fill="#FDFBFB"
      />
      <path
        transform="translate(659,1530)"
        d="m0 0 2 1 4 9 10 3 11 1v1h-27z"
        fill="#FDF9F9"
      />
      <path
        transform="translate(1740,1569)"
        d="m0 0 5 1 2 5h28l-3 3h-4v-2l-11 2 1 3-10-2-4-2-2-5z"
        fill="#FDF9F9"
      />
      <path
        transform="translate(948,1355)"
        d="m0 0h26v1l-23 1h15l2 2-14 1-12 1-5-3v-1z"
        fill="#F2DEDE"
      />
      <path
        transform="translate(1499,1404)"
        d="m0 0 1 4-8 24h-2l-2-9 4-4 4-12z"
        fill="#F4EBEB"
      />
      <path
        transform="translate(268,1357)"
        d="m0 0 13 1 4 2v5l-7-1-4-2-16-2 3-2z"
        fill="#F9F4F4"
      />
      <path
        transform="translate(1049,674)"
        d="m0 0 3 1v10l3 2-1 5-2 2h-4l2-7-5-2 1-6z"
        fill="#FBF8F8"
      />
      <path
        transform="translate(1319,448)"
        d="m0 0 4 2h-3v2l5 2-2 5-4 1 1 4h-2l1 3 2-1v10l-2 1-2-6v-14l1-8z"
        fill="#FDFBFB"
      />
      <path
        transform="translate(1585,1453)"
        d="m0 0 1 3h2l4 8v3l-5 2-5-3v-7l1-3h2z"
        fill="#FBF6F6"
      />
      <path
        transform="translate(997,1359)"
        d="m0 0 9 3 6 3-2 4-8-1-8-3v-2l3-1z"
        fill="#F7F1F1"
      />
      <path
        transform="translate(1616,627)"
        d="m0 0h18l-3 3-24 2-2-1v-2l2-1z"
        fill="#F9F5F6"
      />
      <path
        transform="translate(1114,411)"
        d="m0 0h3v2l8-1 6 2-1 3-5 3-6 1z"
        fill="#F8F4F4"
      />
      <path
        transform="translate(1041,390)"
        d="m0 0h6l5 2 1 3-4 1h-11l-10-1-1 3v-4h8v-2z"
        fill="#D84E44"
      />
      <path
        transform="translate(967,403)"
        d="m0 0 4 1 16 16 6 3 3 4-1 2-8-5-5-4-7-8-5-5z"
        fill="#FCF7F7"
      />
      <path
        transform="translate(750,1216)"
        d="m0 0 2 1v10l-4 7-5 4-4 1v-3l3-2 1-4h3l2-5z"
        fill="#F8F1F1"
      />
      <path
        transform="translate(634,1575)"
        d="m0 0h10v2l2 1 17 1-2 2h-23l-8-2-3-3z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(555,1363)"
        d="m0 0 4 1v2h2l4 9 3 7-1 4-5-8-8-12z"
        fill="#F7F5F5"
      />
      <path
        transform="translate(1156,581)"
        d="m0 0 3 1-2 5-6 12-3 1-1 2v-6l6-13z"
        fill="#FAF7F7"
      />
      <path
        transform="translate(1686,1564)"
        d="m0 0 5 1-2 4-16 6-6-1 4-3 12-5z"
        fill="#F2E7E7"
      />
      <path
        transform="translate(788,992)"
        d="m0 0 3 1h-2l1 4 2-2 2 27-2-3v-2h-2l-2-7-1-15z"
        fill="#C8C6C8"
      />
      <path
        transform="translate(1199,1565)"
        d="m0 0v3l-4 2v2l-10 3-13 1 3-2 12-5 10-3z"
        fill="#F7EEED"
      />
      <path
        transform="translate(621,1246)"
        d="m0 0h1v8h3l1 14-2 4h-2v-5l-3 3 1-9z"
        fill="#F7F3F3"
      />
      <path
        transform="translate(1261,1171)"
        d="m0 0h3v22h-3v-11l1-4h-2l-1 3-3 1 2-6z"
        fill="#371C1B"
      />
      <path
        transform="translate(1093,831)"
        d="m0 0 7 6v2l4 2 14 14 6 5 2 4-4-2-11-10-5-6-8-7-5-5z"
        fill="#FDF3F3"
      />
      <path
        transform="translate(1119,435)"
        d="m0 0v3l-16 11 1 4h-2v-2h-3l-1-4 3-3h2v-3l4-2v3h2l1-2z"
        fill="#DA4F44"
      />
      <path
        transform="translate(1146,421)"
        d="m0 0h3v7l-3 2h-12l-1-2 7-4 5-2z"
        fill="#F9F6F6"
      />
      <path
        transform="translate(1210,974)"
        d="m0 0h6l-1 4-14 7-3-1 3-2h3v-3h-6l2-2z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(1052,657)"
        d="m0 0 2 1-5 17-8 18-2-3h2v-6l2-3 2-5 2-7 2-2z"
        fill="#D94E44"
      />
      <path
        transform="translate(1067,578)"
        d="m0 0 3 1 4 6v6l-4 3-3-1z"
        fill="#FDFBFB"
      />
      <path
        transform="translate(1540,524)"
        d="m0 0 5 1 12 9 11 9 7 6-1 4-4-5-8-7-12-9v-2l-6-2v-2l-4-1z"
        fill="#C7453E"
      />
      <path
        transform="translate(728,1224)"
        d="m0 0h3l5 8-2 4-2 2v-2h-2v-2h-2v2l-3-1h2v-10z"
        fill="#F5E0E0"
      />
      <path
        transform="translate(1075,1560)"
        d="m0 0 4 2 14 9 9 5 13 4-1 2-11-3-10-5-14-9z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(1437,661)"
        d="m0 0h6v2l-8 4-10 1-4 2-1-5z"
        fill="#F7EFEF"
      />
      <path
        transform="translate(1408,637)"
        d="m0 0 1 2-3 9-1-2-2 1v4 1l-8 1 1-5z"
        fill="#FBF9F9"
      />
      <path
        transform="translate(256,1575)"
        d="m0 0 6 1v1l-9 2-3 1-29-1v-1l28-2z"
        fill="#E6C6C5"
      />
      <path
        transform="translate(1057,731)"
        d="m0 0h5v2l-5 5-9 8h-2l2-6z"
        fill="#FDFAFA"
      />
      <path
        transform="translate(1448,488)"
        d="m0 0 6 1 5 2v2l2 1-7 3-4-1-4-7z"
        fill="#FAF7F7"
      />
      <path
        transform="translate(717,1211)"
        d="m0 0 4 4-2 6-3 5-3-1v-7l2-6z"
        fill="#F7E7E7"
      />
      <path
        transform="translate(173,1530)"
        d="m0 0 6 1 7 4 1 3-1 1-7-1-7-4z"
        fill="#FBF9F9"
      />
      <path
        transform="translate(728,1212)"
        d="m0 0 7 1 2 4-4 4h-6v-7z"
        fill="#F1E3E3"
      />
      <path
        transform="translate(982,903)"
        d="m0 0 1 2-12 19-7 11-2 1 3-9 6-8 10-15z"
        fill="#B3ABAD"
      />
      <path
        transform="translate(1149,1578)"
        d="m0 0h11v1l-6 1 6 1-1 2h-9l-1 1h-8l2-3-2-2z"
        fill="#FDFCFC"
      />
      <path
        transform="translate(255,1577)"
        d="m0 0h7l1 2-12 3-12 1-5 1 4-3-3-2h15z"
        fill="#FDFCFC"
      />
      <path
        transform="translate(1605,603)"
        d="m0 0 5 5 4 6v2l-10 1 2-1-1-9z"
        fill="#F2E5E5"
      />
      <path
        transform="translate(1063,512)"
        d="m0 0 6 1 1 4-4 6h3l-1 6-3 3z"
        fill="#FCFAFA"
      />
      <path
        transform="translate(1595,1440)"
        d="m0 0 1 3-3 6-5 2h-2l1-7 2-3h5z"
        fill="#F8EFEF"
      />
      <path
        transform="translate(1025,719)"
        d="m0 0 3 1 1 3v7l-2 1-6-2 1-5z"
        fill="#F8F5F5"
      />
      <path
        transform="translate(163,1539)"
        d="m0 0h1l1 26h-2l-1 2-2-3v-11l2-1z"
        fill="#FDF4F4"
      />
      <path
        transform="translate(1323,634)"
        d="m0 0 4 2 5 4 4 4v2h3l3-6 2 6-1 6-2-2-5-2-13-13z"
        fill="#D94E44"
      />
      <path
        transform="translate(1008,751)"
        d="m0 0 5 2 2 4 2 1-11 8h-2l2-4h3l-2-10z"
        fill="#F5E7E7"
      />
      <path
        transform="translate(946,1579)"
        d="m0 0 15 1v2h-12v2l-18-1v-1l15-1z"
        fill="#FDF5F5"
      />
      <path
        transform="translate(1269,693)"
        d="m0 0 3 1-4 3-15 4-7 2-2-2z"
        fill="#D74D43"
      />
      <path
        transform="translate(1089,602)"
        d="m0 0 3 3-2 4-6 2v2l-4 3h-2v2l-5 3 2-4 12-12z"
        fill="#FBF5F6"
      />
      <path
        transform="translate(591,1211)"
        d="m0 0h8l6 4v2h2l-1 2-12-4-8-1 3-2z"
        fill="#F8F1F0"
      />
      <path
        transform="translate(1182,1090)"
        d="m0 0 12 1 6 3-4 4-7 2h-6l3-2 8-3-4-2-8-2z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(1127,649)"
        d="m0 0 3 1-1 4-11 7-2-1 4-10 1 4h2l1-2z"
        fill="#F0E3E4"
      />
      <path
        transform="translate(1169,1545)"
        d="m0 0h8v2l-15 4h-13v-1h11l2-4z"
        fill="#E8D2D1"
      />
      <path
        transform="translate(253,1436)"
        d="m0 0 7 2 11 6 3 4-10-3-11-7z"
        fill="#F6EFEE"
      />
      <path
        transform="translate(1861,1409)"
        d="m0 0 2 1v12l-2 2-3-4v-9z"
        fill="#FCF3F3"
      />
      <path
        transform="translate(909,857)"
        d="m0 0h2l-2 5-9 5-7 2 1-3z"
        fill="#F7F3F3"
      />
      <path
        transform="translate(988,816)"
        d="m0 0h5l-4 4-7 1v2l-5 1-8 4-2-1 8-5z"
        fill="#D1D0D2"
      />
      <path
        transform="translate(1305,447)"
        d="m0 0h3l-1 10-8-1 3-8z"
        fill="#F9F5F5"
      />
      <path
        transform="translate(1161,422)"
        d="m0 0h12l-2 1-2 4h-2v2l-5 2z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(1434,1575)"
        d="m0 0h25l-4 2h-15l1 3-9-2 1-2z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(1512,1454)"
        d="m0 0h2l1 3-4 13-2 2h-3l3-10z"
        fill="#F3E3E2"
      />
      <path
        transform="translate(1308,510)"
        d="m0 0h1l1 8-5 9h-2l1-9 2-6h2z"
        fill="#F9F6F6"
      />
      <path
        transform="translate(961,1576)"
        d="m0 0h6l-2 4h-12l-19-1v-1l22-1z"
        fill="#E9D7D8"
      />
      <path
        transform="translate(1245,1176)"
        d="m0 0h2l3 11v7l-2-2-2 1-1-2z"
        fill="#F1E2E1"
      />
      <path
        transform="translate(347,1423)"
        d="m0 0 1 2 1 8-2 10-3-1v-11l1-4z"
        fill="#FDFCFC"
      />
      <path
        transform="translate(1342,1427)"
        d="m0 0h5l1 3-1 2-4 1-17-1 2-2 11-1z"
        fill="#FCF7F7"
      />
      <path
        transform="translate(1126,644)"
        d="m0 0 5 1 1 2h-2v2l-4 2-3 3h-2l1-9z"
        fill="#FDF9F9"
      />
      <path
        transform="translate(1598,596)"
        d="m0 0 7 4 1 7-1 6 2 1-1 2-3-1-1-9-5-9z"
        fill="#FDFBFB"
      />
      <path
        transform="translate(1079,511)"
        d="m0 0 3 2v3l10-1 4 2-2 1h-10l-13-4 4-2z"
        fill="#EB5549"
      />
      <path
        transform="translate(1031,412)"
        d="m0 0h2l4 12v7l-3-4-4-8z"
        fill="#C7453E"
      />
      <path
        transform="translate(644,1575)"
        d="m0 0h2v2h16l13 1v1h-29l-7-2v-1z"
        fill="#FDECEC"
      />
      <path
        transform="translate(925,1362)"
        d="m0 0 3 1-7 9h-2l-2 4-3 2 1-5 7-9h3z"
        fill="#F8F0F0"
      />
      <path
        transform="translate(1007,442)"
        d="m0 0h2l4 6 1 7h-4l-5-10v-2z"
        fill="#F9F5F5"
      />
      <path
        transform="translate(1271,436)"
        d="m0 0 4 1 4-1 7 3-4 3h-7l-3-2z"
        fill="#FBF9F9"
      />
      <path
        transform="translate(1092,1570)"
        d="m0 0 5 2 20 7 5 2v1l-7 1v-3l-9-2-10-4z"
        fill="#FDEDED"
      />
      <path
        transform="translate(349,1413)"
        d="m0 0h1l1 12-1 7-3-2v-7l-1-3 2-6z"
        fill="#F8F1F1"
      />
      <path
        transform="translate(975,1323)"
        d="m0 0 10 1 9 2v1l-41-1v-1l20-1z"
        fill="#DF948F"
      />
      <path
        transform="translate(438,1225)"
        d="m0 0 4 1-5 2-5 8-2 9h-1v-9l-2 1 2-6 6-5z"
        fill="#F4DDDD"
      />
      <path
        transform="translate(687,1205)"
        d="m0 0 5 5 1 4 6 1v1h-13z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(984,772)"
        d="m0 0 2 3 4 1-8 4-7 5h-2l2-4z"
        fill="#E6DBDC"
      />
      <path
        transform="translate(1408,668)"
        d="m0 0h7l2 2-17 4-4-1 3-1v-2z"
        fill="#F8F4F4"
      />
      <path
        transform="translate(757,1549)"
        d="m0 0h1l2 9v8l-3 9-2-1z"
        fill="#F6F4F5"
      />
      <path
        transform="translate(874,1549)"
        d="m0 0 2 4v12l-2 2-2-4v-11z"
        fill="#FDFCFC"
      />
      <path
        transform="translate(287,1563)"
        d="m0 0 5 1-1 3-7 3-6 1-2-2 10-5z"
        fill="#EFE0E0"
      />
      <path
        transform="translate(273,1189)"
        d="m0 0h1v12l-3 1-3-5 1-5z"
        fill="#FDF9F9"
      />
      <path
        transform="translate(1226,709)"
        d="m0 0 2 1-1 2-14 4-7 3-2-2 9-4z"
        fill="#D84E44"
      />
      <path
        transform="translate(919,357)"
        d="m0 0 7 1 3 2v2l-1 4-5-2-2-4-3-1z"
        fill="#D84E44"
      />
      <path
        transform="translate(1488,1509)"
        d="m0 0h2v3h3l-2 5h-2l-1 4-4 2 3-11z"
        fill="#EFE1E0"
      />
      <path
        transform="translate(720,1241)"
        d="m0 0 15 3-2 3v-2l-6 1 2 2-7-1-4-2v-3z"
        fill="#FBF9FA"
      />
      <path
        transform="translate(654,1227)"
        d="m0 0 5 1-1 7-5 5v-11z"
        fill="#FDECEC"
      />
      <path
        transform="translate(358,1226)"
        d="m0 0 4 2h5v10h-2v-2l-3 1-4-10z"
        fill="#F8E9EA"
      />
      <path
        transform="translate(1064,651)"
        d="m0 0 5 1 7 4 1 3h-5l-7-4-3-2h2z"
        fill="#E95449"
      />
      <path
        transform="translate(1256,1575)"
        d="m0 0h23v1l-6 1h-9l2 1v2l-6-1z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(244,1208)"
        d="m0 0h1v12l-3 1-3-1-2 1 2-5z"
        fill="#E7BEBC"
      />
      <path
        transform="translate(760,1568)"
        d="m0 0 1 3-3 5-7 3v-3l-5 1h-9v-2h20l2-5z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(490,1266)"
        d="m0 0h1l3 9 2 4-6-1-4-7 3-2z"
        fill="#F1DCDB"
      />
      <path
        transform="translate(215,1361)"
        d="m0 0 2 1-7 8-4 4h-3v-2h2l1-4h2v-2z"
        fill="#F0DFDF"
      />
      <path
        transform="translate(543,1350)"
        d="m0 0 5 1 3 5 1 5-4-2-5-4z"
        fill="#FBF5F4"
      />
      <path
        transform="translate(672,1255)"
        d="m0 0h1l1 5v15l-2 3-1-4v-9z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(516,1211)"
        d="m0 0h8l6 3-1 2h-7l-1-1-8-1 3-1z"
        fill="#F9F1F1"
      />
      <path
        transform="translate(1113,441)"
        d="m0 0 2 1-5 6-2 2 1 3-4-1-3-3z"
        fill="#F7F3F3"
      />
      <path
        transform="translate(636,1202)"
        d="m0 0h1v14h-8l1-2h2l2-4v-6h2z"
        fill="#F0EEEE"
      />
      <path
        transform="translate(1080,584)"
        d="m0 0 2 1-4 5-2 4-2 2-6 1 4-6z"
        fill="#D6544A"
      />
      <path
        transform="translate(530,1511)"
        d="m0 0 1 3-6 9-4 4-2-1 9-13z"
        fill="#F6F4F4"
      />
      <path
        transform="translate(1244,1176)"
        d="m0 0h1v17h-3v-13z"
        fill="#2A1818"
      />
      <path
        transform="translate(987,883)"
        d="m0 0 3 1-5 9-9 9 2-6 5-6 1-3 2-1z"
        fill="#B4ACAE"
      />
      <path
        transform="translate(1044,684)"
        d="m0 0 7 3-3 7 3 1-6 3v-6l1-4-3-2z"
        fill="#F5E6E7"
      />
      <path
        transform="translate(1130,431)"
        d="m0 0 2 1-9 7-5 3-2-1 4-5 6-4z"
        fill="#FAF7F7"
      />
      <path
        transform="translate(1519,1439)"
        d="m0 0h1l-1 8-3 5-3 1 3-12z"
        fill="#F6E9E9"
      />
      <path
        transform="translate(1883,1415)"
        d="m0 0 1 4-4 6-5 4-4-1 8-7 3-3z"
        fill="#5D2725"
      />
      <path
        transform="translate(1583,1409)"
        d="m0 0v3l-10 9-4 4-1-3 7-7z"
        fill="#F5F2F2"
      />
      <path
        transform="translate(566,1218)"
        d="m0 0 2 3-6 7-2 2-2-4 6-7z"
        fill="#FAF6F6"
      />
      <path
        transform="translate(1172,501)"
        d="m0 0 5 2v3l-3 3 3 1v4l-4 2z"
        fill="#FDFAFB"
      />
      <path
        transform="translate(1740,1569)"
        d="m0 0 5 1 2 5h5l2 4-3 1-7-3-2-5z"
        fill="#FDFBFB"
      />
      <path
        transform="translate(1551,1461)"
        d="m0 0h1v8l-2-2-3 3-2 8h-1v-7l3-8z"
        fill="#F9F7F7"
      />
      <path
        transform="translate(1074,810)"
        d="m0 0 4 4 7 9 7 6-3 1-5-4-10-13z"
        fill="#FDF5F5"
      />
      <path
        transform="translate(1027,677)"
        d="m0 0h1l-1 7-7 13-2-1 5-11z"
        fill="#F6F2F2"
      />
      <path
        transform="translate(280,1455)"
        d="m0 0 5 1 5 2 1 3-1 2-4-1-6-5z"
        fill="#F5EFEF"
      />
      <path
        transform="translate(1877,1409)"
        d="m0 0 3 3-1 5-4 1v5h-1l-1-8 2-1z"
        fill="#F3DFDE"
      />
      <path
        transform="translate(1179,1101)"
        d="m0 0 2 1-3 5-13 5h-5l2-2 15-6z"
        fill="#FDF5F5"
      />
      <path
        transform="translate(1196,1097)"
        d="m0 0m-3 1h3v2l-13 4-5 2 3-6z"
        fill="#FDECEC"
      />
      <path
        transform="translate(1171,719)"
        d="m0 0 4 1v4l-9 2 1-4z"
        fill="#FDFBFB"
      />
      <path
        transform="translate(1573,569)"
        d="m0 0 4 2 12 11 2 1v2l-4-1-12-12-2-1z"
        fill="#C5443D"
      />
      <path
        transform="translate(534,1556)"
        d="m0 0 4 1h-2v2l-6 5-7 1 2-3h2v-2z"
        fill="#F9F4F4"
      />
      <path
        transform="translate(1555,1447)"
        d="m0 0 1 4-3 14-2-4v-8l2-3h2z"
        fill="#F1DFDE"
      />
      <path
        transform="translate(1829,1427)"
        d="m0 0 5 1 2 3-6 2-9-1 1-2 7-1z"
        fill="#FDFBFB"
      />
      <path
        transform="translate(1884,1409)"
        d="m0 0 2 4 1 10-4 2-1-4 1-2z"
        fill="#FBF7F7"
      />
      <path
        transform="translate(743,1208)"
        d="m0 0 4 1 2 2v3l-4 1-1 3-4-8 4 1z"
        fill="#FAF8F8"
      />
      <path
        transform="translate(246,1184)"
        d="m0 0 7 1v3h-15l3-2z"
        fill="#FDFBFB"
      />
      <path
        transform="translate(1567,633)"
        d="m0 0h12v3l-12 1-1-2z"
        fill="#FBF8F8"
      />
      <path
        transform="translate(1286,557)"
        d="m0 0 2 4-2 3v2h5l-8 4h-3l2-5z"
        fill="#F1E4E5"
      />
      <path
        transform="translate(1147,398)"
        d="m0 0h6l-4 2 18 4v1l-11-1-12-3-2-2z"
        fill="#D84E44"
      />
      <path
        transform="translate(1304,1403)"
        d="m0 0h2l-2 6-4 4v-3l-5 1v-2z"
        fill="#FCF8F8"
      />
      <path
        transform="translate(555,1248)"
        d="m0 0h1v13l-2-3v-2h-2l-1 3-1-2 1-2-4 1 3-3 4-2z"
        fill="#FDFCFC"
      />
      <path
        transform="translate(385,1232)"
        d="m0 0 6 1-2 5-3 1-1 4-1-1v-8z"
        fill="#F2DFDF"
      />
      <path
        transform="translate(1107,678)"
        d="m0 0 4 2v5l-5 1-3-2z"
        fill="#FBF8F9"
      />
      <path
        transform="translate(575,1639)"
        d="m0 0h3v2h-2v3h-8l-2-4z"
        fill="#F2E4E4"
      />
      <path
        transform="translate(1867,1418)"
        d="m0 0 5 1v4l2 1-4 2h-3l-1-5z"
        fill="#F1E3E3"
      />
      <path
        transform="translate(1800,1399)"
        d="m0 0 2 4-9 7-4-1h2v-3l5-3h2z"
        fill="#F5F2F3"
      />
      <path
        transform="translate(632,1205)"
        d="m0 0h3v5l-5 5-3 1 2-5z"
        fill="#FDF4F4"
      />
      <path
        transform="translate(1066,1444)"
        d="m0 0h2l-3 11-2 6h-1l1-15z"
        fill="#F5ECEC"
      />
      <path
        transform="translate(234,1190)"
        d="m0 0h2l-1 6-2 10-2-3v-7z"
        fill="#EED9D8"
      />
      <path
        transform="translate(1262,1179)"
        d="m0 0h2v14h-3v-11z"
        fill="#151213"
      />
      <path
        transform="translate(1544,624)"
        d="m0 0h6l-1 3-15 1-3-2z"
        fill="#CB473F"
      />
      <path
        transform="translate(1584,1516)"
        d="m0 0h2l4 6-1 5-3-1-2-4z"
        fill="#F8F1F1"
      />
      <path
        transform="translate(879,1362)"
        d="m0 0 3 1v5l-2 5-4-4v-3h2z"
        fill="#F5EAE9"
      />
      <path
        transform="translate(728,1224)"
        d="m0 0h3l1 5-2 2-2 5-3-1h2v-10z"
        fill="#ECE6E6"
      />
      <path
        transform="translate(1029,733)"
        d="m0 0 4 2 1 8-4-1-2-7z"
        fill="#FBF8F8"
      />
      <path
        transform="translate(1407,615)"
        d="m0 0 2 1-1 5-3 1-1 2h-2v2l-4 2 2-4z"
        fill="#D94E44"
      />
      <path
        transform="translate(1319,453)"
        d="m0 0h5l1 2-2 4-4 1 1 4h-2z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(163,1385)"
        d="m0 0h1v10l-4 4-1-4 2-6z"
        fill="#FDF8F8"
      />
      <path
        transform="translate(1764,1393)"
        d="m0 0 6 1 4 3h-22v-1h13z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(286,1362)"
        d="m0 0h7l4 2v3l-7-1-4-2z"
        fill="#FBF9F9"
      />
      <path
        transform="translate(680,1287)"
        d="m0 0h13l-4 4-7-1-2-1z"
        fill="#FDFCFC"
      />
      <path
        transform="translate(1244,704)"
        d="m0 0h3v2l-8 3-10 1v-2z"
        fill="#D94E44"
      />
      <path
        transform="translate(1481,635)"
        d="m0 0h4v2l-9 3-6-1 1-2z"
        fill="#C7453E"
      />
      <path
        transform="translate(479,1257)"
        d="m0 0 3 1 3 5v3l-6-3-1 2z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(989,406)"
        d="m0 0 4 2 10 10 2 4-3-1v-2l-3-1v-2h-2l-5-6-3-2z"
        fill="#C8463F"
      />
      <path
        transform="translate(176,1532)"
        d="m0 0 5 2 3 3-1 2-6-2-3-2v-2z"
        fill="#F0D6D5"
      />
      <path
        transform="translate(1463,1499)"
        d="m0 0h1v7l-3 8-2-1 2-10z"
        fill="#F8F5F6"
      />
      <path
        transform="translate(1523,1393)"
        d="m0 0 3 1v2h5v1h-28v-1h20z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(687,1267)"
        d="m0 0 5 4 1 4h-6l-1-5z"
        fill="#F6F1F1"
      />
      <path
        transform="translate(1051,785)"
        d="m0 0h3v2l-10 4h-6l2-2z"
        fill="#C8463F"
      />
      <path
        transform="translate(1614,614)"
        d="m0 0 1 3-3 1-2 2-4 1-6-3v-1l14-1z"
        fill="#CA473F"
      />
      <path
        transform="translate(1261,474)"
        d="m0 0 3 1-5 4-6 2-1-2 1-3z"
        fill="#FCF9F9"
      />
      <path
        transform="translate(1863,1408)"
        d="m0 0h1v8l5 1-2 1-1 5-3-1z"
        fill="#8D3731"
      />
      <path
        transform="translate(1678,1411)"
        d="m0 0 7 3 2 2v5h-2l-7-8z"
        fill="#FCF9F9"
      />
      <path
        transform="translate(421,1365)"
        d="m0 0 3 1-5 7-5 1 3-5z"
        fill="#F4EAEA"
      />
      <path
        transform="translate(791 1e3)"
        d="m0 0h1l2 19-2-3v-2h-2l-1-6h1z"
        fill="#B3ABAD"
      />
      <path
        transform="translate(982,903)"
        d="m0 0 1 2-10 16-2-2 10-15z"
        fill="#B3ADAF"
      />
      <path
        transform="translate(1302,683)"
        d="m0 0v3l-11 3-1 1h-5v-2z"
        fill="#D94E44"
      />
      <path
        transform="translate(1424,664)"
        d="m0 0 9 1-4 2-8 3-1-5z"
        fill="#F9F6F6"
      />
      <path
        transform="translate(1548,636)"
        d="m0 0h7l2 2-4 2-10-1v-1h5z"
        fill="#F9F6F6"
      />
      <path
        transform="translate(161,1552)"
        d="m0 0h1l1 10 2 3h-2l-1 2-2-3v-11z"
        fill="#FCFBFB"
      />
      <path
        transform="translate(1423,1465)"
        d="m0 0 6 6v9l-2-3-4-10z"
        fill="#EFDEDE"
      />
      <path
        transform="translate(1377,1445)"
        d="m0 0 2 1 3 11h2l1 7-3-3-5-13z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(1170,989)"
        d="m0 0 3 1-14 6-2-1v-2l7-3z"
        fill="#FDF1F1"
      />
      <path
        transform="translate(968,912)"
        d="m0 0 2 1-7 11-3 1 2-5 4-6h2zm-10 13 2 1-2 1z"
        fill="#B6B0B2"
      />
      <path
        transform="translate(935,904)"
        d="m0 0 2 3 1 3 3-1 2 8-7-5z"
        fill="#C4C5C7"
      />
      <path
        transform="translate(1553,552)"
        d="m0 0 4 1 11 11h-3l-10-9-2-1z"
        fill="#C8463F"
      />
      <path
        transform="translate(1057,1468)"
        d="m0 0 2 2 1 8-2 3-3-5 1-5z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(1e3 878)"
        d="m0 0 1 2-4 7h-2l-2 4-2 3-2-1 8-11z"
        fill="#B4ACAE"
      />
      <path
        transform="translate(1093,831)"
        d="m0 0 7 6v2l4 2 1 2-4-1-8-8z"
        fill="#FDF7F7"
      />
      <path
        transform="translate(1074,791)"
        d="m0 0 3 1-9 4 1 6h-2l-2-4 1-4 3-2z"
        fill="#FDF6F6"
      />
      <path
        transform="translate(1076,663)"
        d="m0 0 4 1 3 4v4h-2v-2h-2l-3-5z"
        fill="#F6F2F2"
      />
      <path
        transform="translate(1066,578)"
        d="m0 0h1l1 15v3l-3 1v-9z"
        fill="#EDDFE0"
      />
      <path
        transform="translate(987,421)"
        d="m0 0 6 2 3 4-1 2-8-5z"
        fill="#F7F2F2"
      />
      <path
        transform="translate(884,1532)"
        d="m0 0h5v2l6 1 3 3-2 1-7-3-5-2z"
        fill="#EED3D1"
      />
      <path
        transform="translate(1863,1402)"
        d="m0 0 3 2-3 6-5 1 2-6z"
        fill="#EBE3E3"
      />
      <path
        transform="translate(1271,1393)"
        d="m0 0h7l2 1v2l3 1h-14v-1h6v-2z"
        fill="#FDFCFC"
      />
      <path
        transform="translate(792,975)"
        d="m0 0 1 4-1 3 4 1-8 1 1-6z"
        fill="#B2A8AA"
      />
      <path
        transform="translate(1072,398)"
        d="m0 0 9 1 1 2-16 1 4-2z"
        fill="#E25146"
      />
      <path
        transform="translate(1472,1557)"
        d="m0 0h1v7l-5 5 1-7z"
        fill="#F8F1F1"
      />
      <path
        transform="translate(536,1550)"
        d="m0 0h6l-2 5-5 1v-5z"
        fill="#E0C4C3"
      />
      <path
        transform="translate(1670,1454)"
        d="m0 0h1l1 6v10h-3z"
        fill="#DFB7B6"
      />
      <path
        transform="translate(1065,1439)"
        d="m0 0 3 2v3l-4 2-1 4-3-1z"
        fill="#FDF7F7"
      />
      <path
        transform="translate(1561,1426)"
        d="m0 0 1 2-3 7-4 7-1-2 4-10z"
        fill="#FDF2F2"
      />
      <path
        transform="translate(1867,1407)"
        d="m0 0 5 2v5h-5z"
        fill="#EFDFDF"
      />
      <path
        transform="translate(1337,1390)"
        d="m0 0 7 1 1 3 3 1h-9v-2l-2-1z"
        fill="#FDFBFB"
      />
      <path
        transform="translate(890,1351)"
        d="m0 0h4l-1 4-2 3-4-1z"
        fill="#F1E5E4"
      />
      <path
        transform="translate(1034,791)"
        d="m0 0h3v2l-7 3h-6l3-3z"
        fill="#C8463F"
      />
      <path
        transform="translate(1080,775)"
        d="m0 0 2 1v3l-10 3 1-4z"
        fill="#F1E4E4"
      />
      <path
        transform="translate(1089,696)"
        d="m0 0 2 2-2 2-1 4-5-1 1-4z"
        fill="#FBF8F8"
      />
      <path
        transform="translate(1066,536)"
        d="m0 0h3v7l-2 6h-1z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(911,1334)"
        d="m0 0 3 1-12 8-1-3 5-3z"
        fill="#F7F4F4"
      />
      <path
        transform="translate(942,1324)"
        d="m0 0h13l-2 2-13 1z"
        fill="#ECE9E9"
      />
      <path
        transform="translate(1212,1101)"
        d="m0 0 4 1-1 5-2 3-1-5-5-1 3-2z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(1039,658)"
        d="m0 0h3l1 5-4 5-1-5z"
        fill="#ED564A"
      />
      <path
        transform="translate(1012,1556)"
        d="m0 0h2l-2 4-6 5-2-1 4-5z"
        fill="#F9F6F6"
      />
      <path
        transform="translate(1452,1537)"
        d="m0 0h2l-2 10h-2v-9z"
        fill="#EACCCB"
      />
      <path
        transform="translate(1581,1464)"
        d="m0 0 3 1v2l4 2v1h-8z"
        fill="#F2DADA"
      />
      <path
        transform="translate(231,1427)"
        d="m0 0h5l3 1 1 4-6-1-3-2z"
        fill="#F4E7E7"
      />
      <path
        transform="translate(536,1400)"
        d="m0 0h4l1 6-5 1z"
        fill="#81403D"
      />
      <path
        transform="translate(943,1356)"
        d="m0 0h5l1 2-5 2-6-1-1-2z"
        fill="#EFDDDD"
      />
      <path
        transform="translate(979,820)"
        d="m0 0 2 1-5 4-9 3 4-4z"
        fill="#CECCCE"
      />
      <path
        transform="translate(1370,675)"
        d="m0 0 1 3-13 3-1-2z"
        fill="#DA4E44"
      />
      <path
        transform="translate(526,1600)"
        d="m0 0 5 2v4l-6-2-1-3z"
        fill="#F1DFDE"
      />
      <path
        transform="translate(686,1226)"
        d="m0 0h12v1h-8l-1 5-2 2z"
        fill="#FEFDFD"
      />
      <path
        transform="translate(452,1212)"
        d="m0 0 8 2-1 3-7-1z"
        fill="#F3E1E0"
      />
      <path
        transform="translate(1491,633)"
        d="m0 0h9l-3 2-4 1h-7v-2z"
        fill="#C8463F"
      />
      <path
        transform="translate(1319,448)"
        d="m0 0 4 2h-3v2l2 1h-3l-1 11h-1l1-15z"
        fill="#EEE3E4"
      />
      <path
        transform="translate(913,357)"
        d="m0 0 5 2 3 1 5 5-1 2-12-8z"
        fill="#C9463F"
      />
      <path
        transform="translate(542,1487)"
        d="m0 0h2l-3 10-3-3z"
        fill="#ECD4D3"
      />
      <path
        transform="translate(310,1219)"
        d="m0 0 2 1-2 5h-3v-4z"
        fill="#F6F3F3"
      />
      <path
        transform="translate(945,798)"
        d="m0 0 2 4-6 5v-5z"
        fill="#F6E9EA"
      />
      <path
        transform="translate(1068,780)"
        d="m0 0h3l-1 3-8 2-1-2zm-9 4h2v2h-2z"
        fill="#EADFE0"
      />
    </svg>
  );
};

export default SqlServeIcon;
