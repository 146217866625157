import styled from 'styled-components';



export const TabWrapper = styled.div`
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: flex-start;
 margin-bottom: 24px;
 margin-top: 17px;
 position: relative;



 .ripple {
     margin-right: 30px;
     position: relative;
     overflow: hidden;
     z-index: 1;
       &:before {
           content: '';
           position: absolute;
           top: 50%;
           left: 50%;
           width: 0;
           height: 0;
           transform: translate(-50%, -50%);
           border-radius: 50%;
           background-color: currentColor;
           visibility: hidden;
           z-index: 2;
       }
     
     &:not(:active):before {
         animation: ripple 1.2s ;
         transition: visibility 1.2s step-end;
     }
     
     &:active:before {
         visibility: visible;
     }
 }

 .ripple:hover {
     background-color: #356d941c;
 }

`;


export const Tab = styled.div`
   width: auto;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1.286rem;
   letter-spacing: normal;
   text-align: left;
   color: ${props => props.active ? '#122e39' : '#748891'};
   background: ${props => props.active ? '#356d941c' : undefined};
   cursor: pointer;
   user-select: none;
   transition: all 0.2s;
   margin-right: 15px;
   padding: .5rem 1.2rem;
   border-radius: 3px 3px 0px 0px;

   &:hover{
     background: ${props => props.active ? undefined : '#356d941c'};
     opacity: 1;
   }
`;

export const Indicator = styled.div`
    position: absolute;
    bottom: 0px;
    left: ${props => props.left + "px" || '0px'};
    width: ${props => props.width + "px" || '0px'};
    transition: all 0.5s ;
    height: 2px ;
    background: ${props => props.color || 'red'};
    scroll-behavior: smooth;


`;
