import { simpleDispatch_post, dispatchAction, simpleDispatch_get, simpleDispatch_delete, raiseSuccess } from './helper';
import * as actionTypes from '../action-types/actual_plan';
import { constants } from '../utils/constants';





export const getAllPlannedMettings = () => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.ACTUAL_PLAN.POINT + constants.END_POINTS.ACTUAL_PLAN.GET_ALL_PLANNED_MEETINGS;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_ALL_PLANNED_METTINGS);

};


export const getActualByPlanId = (plan_id) => async dispatch => {
    if (!plan_id) {
        dispatchAction(dispatch, actionTypes.CLEAR_ACTUAL_PLAN_DETAILS)
    }
    else {
        dispatchAction(dispatch, actionTypes.CLEAR_ACTUAL_PLAN_DETAILS)
        const url = constants.END_POINTS.API + constants.END_POINTS.ACTUAL_PLAN.POINT + constants.END_POINTS.ACTUAL_PLAN.GET_ACTUAL_BY_PLAN_ID + "?plan_id=" + plan_id;
        await simpleDispatch_get(dispatch, url, actionTypes.GET_ACTUAL_PLAN_DETAILS_BY_PLAN_ID);
    }
};


export const savePlannedMeeting = (planned_data) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.ACTUAL_PLAN.POINT + constants.END_POINTS.ACTUAL_PLAN.SAVE_PLANNED_MEETING
    await simpleDispatch_post(dispatch, url, { planned_data: planned_data }, "test");

    getAllPlannedMettings()(dispatch)
};


export const saveActualPlan = (actual_data, plan_id) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.ACTUAL_PLAN.POINT + constants.END_POINTS.ACTUAL_PLAN.SAVE_ACTUAL_PLAN
    await simpleDispatch_post(dispatch, url, { actual_data: actual_data }, "test");

    getActualByPlanId(plan_id)(dispatch)
};

