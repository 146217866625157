import React from 'react';
import styled from 'styled-components';
import { hideMenuConfig } from '../../actions/menu.config.righter';
import { connect } from 'react-redux'
import MenuRighterElement from './menu.righter.element'
import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils'
import { apply_filters } from '../../actions/filter'


const selected = getSelectedTheme();

const { righterMenuConfig } = themes[selected];

const MainWrapper = styled.div`
    /* width: 250px; */
    opacity: ${props => props.showFull ? 1 : 0};
    background-color: ${righterMenuConfig.chartOptions.color.wrapperBg};
    border-left: ${righterMenuConfig.chartOptions.border.wrapperBorderLeft};
    box-sizing: border-box;
    transition: all 0.3s;
    height: 100%;
    position: relative;
    margin-right: ${props => props.showFull ? "0px" : '-250px'};

    .show_full_wrapper{
        opacity: ${props => props.showFull ? '1' : '0'};
        overflow: hidden;
        transition: all 0.6s;
        display: block;
    }
`;


const Index = (props) => {

    const {

        showFull,
        dateFilter,
        scheduleReportId,
        question,
        changeChartType,
        chartType,
        renderMode,
        trendFilters,
        numberOfData,
        showSingleCell,
        reportTitle,
        data,
        header,
        user,
        heightOfChart,
        filters,
        getDataFromReportingServer,
        mainReportId,
        columnsAligments,
        grandTotalColumns,
        righterMenuStyle,
        report_item

    } = props;



    return (
        <React.Fragment>
            <MainWrapper showFull={showFull} heightOfChart={heightOfChart}>
                <div className="show_full_wrapper">
                    <MenuRighterElement
                        {...props}
                        is_global_filters={props.is_global_filters}
                        report_item={report_item || undefined}
                        changeChartType={changeChartType}
                        chartType={chartType}
                        insights={props.insights}
                        restricted_view={props.restricted_view}
                        renderMode={renderMode}
                        trendFilters={trendFilters}
                        numberOfData={numberOfData}
                        showSingleCell={showSingleCell}
                        reportTitle={reportTitle}
                        data={data}
                        user={user}
                        header={header}
                        heightOfChart={heightOfChart}
                        filters={filters}
                        getDataFromReportingServer={getDataFromReportingServer}
                        question={question}
                        scheduleReportId={scheduleReportId}
                        dateFilter={dateFilter}
                        history={props.history}
                        mainReportId={mainReportId}
                        columnsAligments={columnsAligments}
                        xDataKeys={props.xDataKeys}
                        yDataKeys={props.yDataKeys}
                        grandTotalColumns={grandTotalColumns}
                        filterFromURL={props.filterFromURL}
                        righterMenuStyle={righterMenuStyle}
                        localRunningTotal={props.localRunningTotal}
                        setLocalRunningTotal={props.setLocalRunningTotal}
                        id={props.id}
                        change_report_setting_on_apply_pivot={props.change_report_setting_on_apply_pivot}
                        report_item_id={props.report_item_id}
                    />
                </div>
            </MainWrapper>
        </React.Fragment>
    )
}
















export default connect(undefined, { hideMenuConfig, apply_filters })(Index);

