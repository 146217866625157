export const colors = [
  '#8884d8',
  "#63B6D6",
  "#0C5672",
  "#008AC7",
  "#748891",
  "#122E39",
  "#85C1E9",
  "#E8DAEF",
  "#A9DFBF",
  "#FCF3CF",
  "#D5F5E3",
  "#D4EFDF",
  "#F5B7B1",
  "#85C1E9",
  "#E8DAEF",
  "#A9DFBF",
  "#FCF3CF",
  "#D5F5E3",
  "#D4EFDF",
  "#F5B7B1",
  "#85C1E9",
  "#E8DAEF",
  "#A9DFBF",
  "#FCF3CF",
  "#D5F5E3",
  "#D4EFDF",
  "#F5B7B1",
  "#85C1E9",
  "#E8DAEF",
  "#A9DFBF",
  "#FCF3CF",
  "#D5F5E3",
  "#D4EFDF",
  "#F5B7B1",
  "#85C1E9",
  "#E8DAEF",
  "#A9DFBF",
  "#FCF3CF",
  "#D5F5E3",
  "#D4EFDF",
]

export const randomColors = [
  // '#80CCFF',
  // '#66C2FF',
  // '#4DB8FF',
  // '#4DB8FF',
  // '#1AA3FF',
  // '#1AA3FF',
  // '#008AE6',
  // '#007ACC',
  // '#006BB3',
  // '#005C99'
  // '#f05837',
  // '#f49f05',
  // '#bb1924',
  // '#525b56',
  // '#f3b05a',
  // '#36688d',
  // '#d9ac2a',
  // '#d50b53',
  // '#f07995'

  '#f3cd05',
  '#f18904',
  '#0444bf',
  '#bed905',
  '#f46a4e',
  '#80add7',
  '#0294a5',
  '#f22f08',
  '#0abda0',
  '#d96b0c',
]

export const colorsForLegend = [
  {
    name: 'blue',
    data: [
      '#1AA3FF',
      '#008AE6',
      '#007ACC',
      '#006BB3'
    ]
  },
  {
    name: 'red',
    data: [
      '#ff3333',
      '#ff1a1a',
      '#ff0000',
      '#e60000'
    ]
  },
  {
    name: 'green',
    data: [
      '#33ff33',
      '#1aff1a',
      '#00ff00',
      '#00e600'
    ]
  },
  {
    name: 'yellow',
    data: [
      '#ffff33',
      '#ffff1a',
      '#ffff00',
      '#e6e600'
    ]
  },
  {
    name: 'pink',
    data: [
      '#ff3399',
      '#ff1a8c',
      '#ff0080',
      '#e60073'
    ]
  },
  {
    name: 'grey-white',
    data: [
      '#ffffff',
      '#f6f6f9',
      '#e4e4e7',
      '#252528'
    ]
  }
]