import React, { PureComponent } from 'react';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { formatValueByDataType } from '../../../utils'
import CustomToolTip from '../tooltip/tooltip.custom';
import renderLegend from '../legend/index.new';
import { DataFormaterFixed } from '../yaxis-label';
import RenderLegend from '../legend/index.new';

class SimpleRadarChart extends PureComponent {

    render() {
        const { yDataKeys, xDataKeys, data, widthOfChart, heightOfChart,
            hide_label, hide_title, hide_y_axis, hide_x_axis, hide_y_axis_border, hide_x_axis_border, hide_grid, hide_legend,
            hide_border, hide_tooltip, Alignments, tooltipTheme, chartStyle,chartType
        } = this.props;
        const legendStyle = chartStyle && chartStyle.legend;

        return (
            <div id='chart' style={{ position: 'relative', width: widthOfChart ? (widthOfChart - 30) : '93.1vw', textTransform: 'capitalize' }}>
                <ResponsiveContainer width='100%' height={heightOfChart}>
                    <RadarChart
                        data={data}>
                        {!hide_grid && (<PolarGrid />)}
                        <PolarAngleAxis dataKey={xDataKeys && xDataKeys[xDataKeys.length - 1]} />

                        {!hide_tooltip && (
                            <Tooltip
                                content={function (active) {
                                    // {
                                        let _data = {};
                                        const _payload = active && active.payload;
                                        const _inner_payload = _payload && _payload[0] && _payload[0].payload;
                                        const payload_data = _inner_payload && _inner_payload;
                                        let array_of_keys = [...xDataKeys, ...yDataKeys];
                                        array_of_keys && array_of_keys.length > 0 && array_of_keys.map((key) => {

                                            if (payload_data && Object.keys(payload_data).length > 0 && Object.keys(payload_data).indexOf(key) > -1) {

                                                const column_formating_meta = Alignments?.[key];
                                                const currency_type = column_formating_meta?.['currency_type'];
                                                const use_comma = column_formating_meta?.['use_comma'];
                                                const use_decimal = column_formating_meta?.['use_decimal'];
                                                const d_type = column_formating_meta?.['type'];

                                                _data[key] = formatValueByDataType(payload_data[key], d_type, undefined, undefined, undefined, undefined, currency_type, use_comma, use_decimal, use_decimal)


                                            }
                                        })
                                        return <CustomToolTip data={_data} theme={tooltipTheme} />
                                    // }
                                }}
                            />
                        )}


                        {!hide_legend && <Legend chartType={chartType} content={<RenderLegend payload={data} legendStyle={legendStyle} alignments={Alignments} />} verticalAlign="top" height={30} />}
                        {/* {!hide_legend && <Legend content={renderLegend} verticalAlign="top" height={30} />} */}
                        {/* <PolarRadiusAxis /> */}
                        <PolarRadiusAxis 
                            tick={{ fill: '#747574' }} 
                             tickFormatter={(r) => {
                                var key = yDataKeys?.[0];
                                const column_formating_meta = Alignments?.[key];
                                const value = DataFormaterFixed(r, key, column_formating_meta)
                                return value;
                            }}
                        />
                        {
                            yDataKeys && yDataKeys.length > 0 && yDataKeys.map((data, index) => {
                                const tempColor = this.props.colorForYData[index];
                                return <Radar key={index} name={data} dataKey={data} fill={tempColor} stroke={tempColor} fillOpacity={0.6} />
                            })
                        }
                    </RadarChart>
                </ResponsiveContainer>
            </div>
        );
    }
}

export default SimpleRadarChart;