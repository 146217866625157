import React, { useRef, useState, useEffect } from 'react';
import { Wrapper } from './analytics-map.styled';
import Map from '../map';
import { PageInnerWrapper } from '../hoc/styled.hoc'

const Index = (props) => {

    const [height, setHeight] = useState(undefined);
    const [width, setWidth] = useState(undefined);

    const _wrapperRef = useRef(null)

    useEffect(() => {
        if (_wrapperRef && _wrapperRef.current) {
            setHeight(_wrapperRef.current.offsetHeight);
            setWidth(_wrapperRef.current.offsetWidth);
        }

    }, [_wrapperRef])


    useEffect(() => {
        props.dispatchHeaderInformation(undefined)
    },[])

    
    return (
        <Wrapper style={{ marginTop: '30px' }}>
            <PageInnerWrapper
                padding={'0px'}
                ref={_wrapperRef}
                style={{
                    display: 'flex',
                    alignItems: 'center', 
                    justifyContent: 'center',
                    position: 'relative'
                }}
            >
                {height && width && (
                    <Map
                        headerHide={true}
                        heightOfChart={height - 10}
                        widthOfChart={width - 10}
                    />
                )}
            </PageInnerWrapper>
        </Wrapper>
    )
}

export default Index