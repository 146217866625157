import { simpleDispatch_post, dispatchAction, simpleDispatch_get, simpleDispatch_put, showError, raiseSuccess, postData, set_table_data_mode } from './helper';
import * as actionTypes from '../action-types/report';
import * as helperActionTypes from '../action-types/report.helper';
import * as mapActionTypes from '../action-types/map';
import { getFiltersForDropDowns, clear_all_filters, remove_widget_id_from_stored } from '../actions/filter';
import { setDiscoveryData } from '../actions/discovery';
import { setMultiDisplayData } from '../actions/multi.display';
import { getMenus, get_report_menus } from '../actions/menu.group';
import { constants } from '../utils/constants';
import { CHART_CONFIG } from '../shared-with-fe/keys';
import { get_client_info } from '../client_info';
import { post, put } from '../data.service';
import * as loaderTypes from '../action-types/loader';
import { _getDataFromReportingServer, generate_unique_key, date_add, format_date, console_logger, getParameterByName, convert_normal_date_filter_to_JAVA } from '../utils';
import { apply_filters } from './filter';
import { getInsightsListByUser } from './insights';
import { is_date } from '../utils';
import { get_filters_from_session, save_filters_in_session, save_pivot_column_info_in_session, get_pivot_column_info_from_session, getFromSession } from '../utils/session.helper'
import { dispatch_tbl_format_settings } from './table_format';
import * as errorActionTypes from '../action-types/error';
import { get_edit_report_by_id } from './report_builder';
import { get_all_reports } from './report.helper';
import { FULL_MONTHS, MONTHS } from '../shared-with-fe/constants';
import * as dataTypes from '../shared-with-fe/data.types';
import { s_p } from '../utils/v1.1';



export const getMetaColumnsData = columns => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.SELECT_COLUMN_DATA;

    simpleDispatch_post(dispatch, url, columns, actionTypes.META_COLUMN_DATA_SUCCESS);
};

export const getDataFromReportingServer = (
    question,
    userId = 'default',
    reportId,
    filters = {},
    chartType,
    renderMode,
    scheduleReportId,
    filterFunction,
    hideLoader,
    report_type,
    extra_body_data
) => async dispatch => {

    dispatchAction(dispatch, actionTypes.REPORT_REFRESHED, { base_report_id: reportId }); // updating status to ignore 
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.GET_DATA_FROM_REPORTING_SERVER + `?question=${question}`;
    const client_info = await get_client_info();
    let tempFilters = undefined;

    if (filters) {
        tempFilters = Object.assign({}, filters);
        delete tempFilters.key;
        delete tempFilters.reportId;
    }

    const post_data = {
        ...extra_body_data,
        question,
        userId,
        reportId,
        filters: Object.assign({}, tempFilters),
        [CHART_CONFIG.chart_type]: chartType,
        [CHART_CONFIG.render_mode]: renderMode,
        scheduleReportId: scheduleReportId ? scheduleReportId : undefined,
        client_info,
        report_type,
    };

    if (process.env.REACT_APP_DEV_LOGIN_KEY) {
        delete post['client_info'];
    }

    try {
        const successType = actionTypes.DATA_FROM_RS_SUCCESS;
        const data = await post(url, post_data);
        if (data && data.data) {
            if (report_type === 'dashboard' && data.data.back_end_where_conditions) {
                dispatch_filters(dispatch, data.data.back_end_where_conditions, scheduleReportId, 'anaconda')
            }
            dispatchAction(dispatch, successType, data.data, undefined, undefined, 0);
        }
    }
    catch (e) {
        console.log('error in data getter =====>', e)
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        showError(dispatch, e);

    }
};






export const getDataFromReportingServeras1 = (question, userId = 'default', reportId, filters = {}, chartType, renderMode, scheduleReportId, filterFunction, hideLoader, report_type, extra_body_data) => async dispatch => {

    dispatchAction(dispatch, actionTypes.REPORT_REFRESHED, { base_report_id: reportId }); // updating status to ignore 
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.GET_DATA_FROM_REPORTING_SERVER + `?question=${question}`;
    const client_info = await get_client_info();


    let tempFilters = undefined;

    if (filters) {
        tempFilters = Object.assign({}, filters);

        delete tempFilters.key;
        delete tempFilters.reportId;
    }

    const post_data = {
        ...extra_body_data,
        question,
        userId,
        reportId,
        filters: Object.assign({}, tempFilters),
        [CHART_CONFIG.chart_type]: chartType,
        [CHART_CONFIG.render_mode]: renderMode,
        scheduleReportId: scheduleReportId ? scheduleReportId : undefined,
        client_info,
        report_type,
    };

    if (process.env.REACT_APP_DEV_LOGIN_KEY) {
        delete post['client_info'];
    }

    try {
        const successType = actionTypes.DATA_FROM_RS_SUCCESS;

        const data = await post(url, post_data);


        if (data && data.data) {
            if (report_type === 'dashboard' && data.data.back_end_where_conditions) {
                dispatch_filters(dispatch, data.data.back_end_where_conditions, scheduleReportId, 'anaconda')
            }

            dispatchAction(dispatch, successType, data.data, undefined, undefined, 0);
        }
    }
    catch (e) {
        console.log('error in data getter =====>', e)
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        showError(dispatch, e);

    }
};



export const getReportByReportIdForOpenRoute = (token, report_id, client_id) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.INSIGHTS.POINT + constants.END_POINTS.INSIGHTS.GET_INSIGHTS_LIST_BY_INSIGHT_ID;

    /**
     * no need for post object as we have already dispatched the opr data to session which will be binding automatically
     * with all request anyway
     */
    const post = {
        token,
        report_id,
        client_id
    };

    await simpleDispatch_post(dispatch, url, post, actionTypes.SHARE_REPORT, true);
};


export const convert_filter_into_plain_english = (filters) => {

    const MONTHS1 = {
        1: 'Jan',
        2: 'Feb',
        3: 'Mar',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'Aug',
        9: 'Sep',
        10: 'Oct',
        11: 'Nov',
        12: 'Dec'
    }

    const checked_date = {};
    const final_filter = [];

    const only_years = [];
    const keys = filters ? Object.keys(filters) : {};

    keys && keys.length > 0 && keys.sort().forEach((d) => {

        const y = d.split('-')[0];
        const q = d.split('-')[1];
        const m = d.split('-')[2];

        if (filters[d] === 1) {
            if (!q && !m) {
                final_filter.push(y)
                checked_date[y] = 1;
                only_years.push(y);
            }
            if (!m && q && final_filter.indexOf(y) === -1) {
                const str = "qtr" + (parseInt(q) + 1) + ' ' + y
                final_filter.push(str)
                checked_date[y + '-' + q] = 1
            }
            if (m && q && final_filter.indexOf(y) === -1 && typeof checked_date[y + '-' + q] === 'undefined') {
                const str = MONTHS1[parseInt(m)] + " " + y
                checked_date[y + '-' + q + '-' + m] = 1
                final_filter.push(str)
            }
        }
    })

    const m_filter = [...only_years, ...final_filter];
    const uniq_date_filter = [...new Set(m_filter)];

    return uniq_date_filter;
}

/**
 * 
 * @param {*} already_applied_filters 
 * @param {*} link_filters 
 * @returns 
 */

export const lets_build_link_filter = (already_applied_filters, link_filters) => {

    const filters = {};
    const date_filters = {};
    var new_parameterized_values_to_use = undefined

    if (link_filters && Object.keys(link_filters).length > 0) {

        Object.keys(link_filters).forEach(key => {

            const values = link_filters?.[key];
            const data_type = key?.split('__nfx__db__')?.[1] && parseFloat(key?.split('__nfx__db__')?.[1]);
            const column_name = key?.split("__nfx__db__")?.[0]?.split(',')?.[0];

            // console.log("values", values, data_type)

            if (data_type && (data_type === dataTypes.date || data_type === dataTypes.date_time || data_type === dataTypes.date_month)) {
                const value = values[0];
                const __f___ = convert_normal_date_filter_to_JAVA({ value: value })
                delete date_filters[column_name]
                new_parameterized_values_to_use = __f___ //convert_normal_date_filter_to_JAVA({ value: value })
            }
            else {
                // it is a normal filter 
                filters[column_name] = values;
            }
        })
    }

    filters["date_filter"] = date_filters;

    // we will give the priority to the link filter
    if (already_applied_filters && Object.keys(already_applied_filters).length > 0) {
        Object.keys(already_applied_filters).forEach((key) => {
            if (key !== "date_filter" && !filters[key]) {
                filters[key] = already_applied_filters[key];
            }
        })
    }

    return {
        filters: filters,
        parameterized_values_to_use: new_parameterized_values_to_use

    };
}


export const build_required_filter_and_parameters_for_report_v1 = ({

    default_date_filters,
    parameterized_values_to_use,
    report_filters,
    _report_item_filters

}, insights, reportType, id) => {


    const need_to_add_global = !report_filters?.['date_filter']?.["generic_date"]?.['type'];

    const need_to_use_local_filter = _report_item_filters?.['date_filter']?.["generic_date"]?.['type']; //
    const date_filters = need_to_use_local_filter ? _report_item_filters?.['date_filter'] : report_filters?.['date_filter'];

    const single_date = date_filters?.['generic_date']?.['single_date'];
    const range_start_date = date_filters?.['generic_date']?.['range_start_date'];
    const range_end_date = date_filters?.['generic_date']?.['range_end_date'];
    const month = date_filters?.['generic_date']?.['month'];
    const year = date_filters?.['generic_date']?.['year'];

    const master_date_type = date_filters?.['generic_date']?.['type'];
    const single_date_type = date_filters?.["generic_date"]?.['single_type'];

    const quick_date = date_filters?.["generic_date"]?.['quick_date'];

    const custom_date_config_v1 = date_filters?.['generic_date']?.['custom_date_config'];


    console.log("date_filters bhai", date_filters)

    if (reportType === "plain_english") return;


    else if (master_date_type === "quick_date") {
        // let's handle quick date filter here  
        parameterized_values_to_use['quick_date'] = quick_date;
        parameterized_values_to_use["type"] = "quick_date";
    }

    else if (master_date_type === "custom_date" && month > -1 && year && single_date_type == 'month') {

        parameterized_values_to_use['nf_date'] = format_date(new Date(year, (month - 1), 1, 0, 0, 0, 0));
        parameterized_values_to_use["type"] = "month_year";
        parameterized_values_to_use['month_year'] = FULL_MONTHS[month] + " " + year

    }
    else if (master_date_type === "custom_date" && year && single_date_type === 'year') {

        parameterized_values_to_use['nf_date'] = format_date(new Date(year, (0), 1, 0, 0, 0, 0));
        parameterized_values_to_use["type"] = "year"
        parameterized_values_to_use['year'] = year

    }
    else if (range_start_date && range_end_date && master_date_type === "range") {

        parameterized_values_to_use['range_start_date'] = range_start_date ? range_start_date : new Date();
        parameterized_values_to_use['range_end_date'] = range_end_date ? range_end_date : new Date();
        parameterized_values_to_use["type"] = "range";
    }
    else if (single_date && single_date_type === 'single_date') {

        const single_date_post = single_date ? single_date : new Date();
        // single_date_post.setHours(0, 0, 0, 0);


        parameterized_values_to_use['nf_date'] = single_date_post
        parameterized_values_to_use["type"] = "date";
    }
    else if (master_date_type === "custom_date_config" && custom_date_config_v1) {
        if (!need_to_use_local_filter) {
            parameterized_values_to_use['nf_date'] = custom_date_config_v1;
            parameterized_values_to_use["type"] = "date";

        }
        const parse_date = new Date(custom_date_config_v1)
        // and append this into filter as well
        console.log(`parse_date`, parse_date);
        if (insights) report_filters['date_filter']['generic_date'] = { custom_date_config: parse_date, type: 'custom_date_config' }

    }

    else if (!need_to_use_local_filter) {

        // let's use global filter  default_date_filters
        const custom_date_config = default_date_filters?.custom_date_config;
        let global_single_date = default_date_filters?.['single_date'];

        const g_month = default_date_filters?.['month'];
        const g_year = default_date_filters?.['year'];
        const g_start_date = default_date_filters?.['range_start_date'];
        const g_end_date = default_date_filters?.['range_end_date'];

        const quick_date = default_date_filters?.['quick_date'];


        function getPreviousDay(num) {
            const date = new Date()
            const previous = new Date(date.getTime());
            previous.setDate(date.getDate() - (num || 0));
            return previous;
        }

        if (!report_filters['date_filter']) report_filters['date_filter'] = {};
        if (!report_filters['date_filter']['generic_date']) report_filters['date_filter']['generic_date'] = {};

        if (g_month > -1 && g_year) {
            // if we have month and year then create new nf_date
            parameterized_values_to_use['nf_date'] = format_date(new Date(g_year, (g_month - 1), 1, 0, 0, 0, 0));
            parameterized_values_to_use["type"] = "month_year"
            if (insights) {
                report_filters['date_filter']['generic_date'] = { month: g_month, year: g_year, type: 'custom_date', single_type: "month" }
            }
        }
        else if (g_year > -1 && !g_month) {
            parameterized_values_to_use['nf_date'] = format_date(new Date(g_year, 0, 1, 0, 0, 0, 0));
            parameterized_values_to_use["type"] = "year"
            if (insights) report_filters['date_filter']['generic_date'] = { year: g_year, type: 'custom_date', single_type: 'year', }
        }
        else if (g_start_date && g_end_date) {
            parameterized_values_to_use['range_start_date'] = g_start_date;
            parameterized_values_to_use['range_end_date'] = g_end_date;
            parameterized_values_to_use['type'] = "range";
            report_filters['date_filter']['generic_date'] = { range_start_date: g_start_date, range_end_date: g_end_date, type: 'range' }

        }
        else if (global_single_date) {
            parameterized_values_to_use['nf_date'] = global_single_date;
            parameterized_values_to_use["type"] = "date";
            // and append this into filter as well
            report_filters['date_filter']['generic_date'] = { single_date: global_single_date, single_type: 'single_date', type: 'custom_date' }

        }
        else if (custom_date_config) {
            const CUSTOM_DATE_CONFIG = {
                "yesterday": 1,
                "day_before_yesterday": 2,
                "today": 0
            }

            const prev_date_count = CUSTOM_DATE_CONFIG[custom_date_config]
            const prev_date = getPreviousDay(prev_date_count);

            parameterized_values_to_use['nf_date'] = prev_date;
            parameterized_values_to_use["type"] = "date";
            const parse_date = new Date(prev_date)
            // and append this into filter as well
            if (insights) report_filters['date_filter']['generic_date'] = { custom_date_config: parse_date, type: 'custom_date_config' }
        }
        else if (quick_date) {
            parameterized_values_to_use["quick_date"] = quick_date;
            parameterized_values_to_use["type"] = "quick_date";
            if (insights) report_filters['date_filter']['generic_date'] = {
                "quick_date": quick_date,
                "type": "quick_date"
            }
        }

        else {

            const today_date__ = new Date();
            today_date__.setHours(0, 0, 0, 0);
            parameterized_values_to_use['nf_date'] = new Date();
            parameterized_values_to_use["type"] = "date"
            report_filters['date_filter']['generic_date'] = { single_date: today_date__, single_type: 'single_date', type: "custom_date" }
        }
    }

}





/**
 * 
 * @param {*} param0 
 * @param {*} insights 
 */
export const build_required_filter_and_parameters_for_report = ({

    post_filters, /// BOTH * LOCAL AND GLOBAL
    default_date_filters,
    parameterized_values_to_use,
    master_date_filter,
    report_filters, // report *GLOBAL
    _report_item_filters, /// report item *LOCAL,
    pre_master_date

}, insights, reportType, id) => {


    const need_to_add_global = !report_filters?.['date_filter']?.["generic_date"]?.['type'];
    const need_to_use_local_filter = _report_item_filters?.['date_filter']?.["generic_date"]?.['type'];
    const date_filters = need_to_use_local_filter ? _report_item_filters?.['date_filter'] : report_filters?.['date_filter'];
    const single_date = date_filters?.['generic_date']?.['single_date'];
    const range_start_date = date_filters?.['generic_date']?.['range_start_date'];
    const range_end_date = date_filters?.['generic_date']?.['range_end_date'];
    const month = date_filters?.['generic_date']?.['month'];
    const year = date_filters?.['generic_date']?.['year'];
    const master_date_type = date_filters?.['generic_date']?.['type'];
    const single_date_type = date_filters?.["generic_date"]?.['single_type'];
    const quick_date = date_filters?.["generic_date"]?.['quick_date'];
    const custom_date_config_v1 = date_filters?.['generic_date']?.['custom_date_config'];




    if (reportType === "plain_english") return;



    else if (master_date_type === "quick_date") {
        // let's handle quick date filter here  

        parameterized_values_to_use['quick_date'] = quick_date;
        parameterized_values_to_use["type"] = "quick_date";
    }

    else if (master_date_type === "custom_date" && month > -1 && year && single_date_type == 'month') {

        parameterized_values_to_use['nf_date'] = format_date(new Date(year, (month - 1), 1, 0, 0, 0, 0));
        parameterized_values_to_use["type"] = "month_year";
        parameterized_values_to_use['month_year'] = FULL_MONTHS[month] + " " + year

    }
    else if (master_date_type === "custom_date" && year && single_date_type === 'year') {

        parameterized_values_to_use['nf_date'] = format_date(new Date(year, (0), 1, 0, 0, 0, 0));
        parameterized_values_to_use["type"] = "year"
        parameterized_values_to_use['year'] = year

    }
    else if (range_start_date && range_end_date && master_date_type === "range") {

        parameterized_values_to_use['range_start_date'] = range_start_date ? range_start_date : new Date();
        parameterized_values_to_use['range_end_date'] = range_end_date ? range_end_date : new Date();
        parameterized_values_to_use["type"] = "range";
    }
    else if (single_date && single_date_type === 'single_date') {

        parameterized_values_to_use['nf_date'] = single_date ? single_date : new Date();
        parameterized_values_to_use["type"] = "date";
    }
    else if (master_date_type === "custom_date_config" && custom_date_config_v1) {

        if (!need_to_use_local_filter) {
            parameterized_values_to_use['nf_date'] = custom_date_config_v1;
            parameterized_values_to_use["type"] = "date";
            master_date_filter["single_date"] = custom_date_config_v1;
            pre_master_date["single_date"] = custom_date_config_v1;
            pre_master_date["type"] = "single_date";

        }
        const parse_date = new Date(custom_date_config_v1)
        // and append this into filter as well
        console.log(`parse_date`, parse_date);
        if (insights) report_filters['date_filter']['generic_date'] = { custom_date_config: parse_date, type: 'custom_date_config' }

    }

    if (need_to_add_global) {

        const custom_date_config = default_date_filters?.custom_date_config;
        let single_date1 = default_date_filters?.['single_date'];
        const month1 = default_date_filters?.['month'];
        const year1 = default_date_filters?.['year'];
        const range_start_date1 = default_date_filters?.['range_start_date'];
        const range_end_date1 = default_date_filters?.['range_end_date'];

        function getPreviousDay(num) {
            const date = new Date()
            const previous = new Date(date.getTime());
            previous.setDate(date.getDate() - (num || 0));
            return previous;
        }

        if (!report_filters['date_filter']) report_filters['date_filter'] = {};
        if (!report_filters['date_filter']['generic_date']) report_filters['date_filter']['generic_date'] = {};

        if (month1 > -1 && year1) {

            // if we have month and year then create new nf_date
            if (!need_to_use_local_filter) {
                parameterized_values_to_use['nf_date'] = format_date(new Date(year1, (month1 - 1), 1, 0, 0, 0, 0));
                parameterized_values_to_use["type"] = "month"
            }
            if (insights) report_filters['date_filter']['generic_date'] = { month: month1, year: year1, type: 'month' }
        }
        else if (year1 > -1 && !month1) {

            if (!need_to_use_local_filter) {
                parameterized_values_to_use['nf_date'] = format_date(new Date(year1, 0, 1, 0, 0, 0, 0));
                parameterized_values_to_use["type"] = "year"

            }
            if (insights) report_filters['date_filter']['generic_date'] = { year: year1, type: 'custom_date', single_type: 'year', }

        }
        else if (range_start_date1 && range_end_date1) {

            if (!need_to_use_local_filter) {
                parameterized_values_to_use['range_start_date'] = range_start_date1;
                parameterized_values_to_use['range_end_date'] = range_end_date1;
                parameterized_values_to_use['type'] = "range";

            }
            report_filters['date_filter']['generic_date'] = { range_start_date: range_start_date1, range_end_date: range_end_date1, type: 'range' }

        }
        else if (single_date1) {

            if (!need_to_use_local_filter) {
                parameterized_values_to_use['nf_date'] = single_date1;
                parameterized_values_to_use["type"] = "date";
            }
            // and append this into filter as well
            report_filters['date_filter']['generic_date'] = { single_date: single_date1, single_type: 'single_date', type: 'custom_date' }

        }
        else if (custom_date_config) {

            const CUSTOM_DATE_CONFIG = {
                "yesterday": 1,
                "day_before_yesterday": 2,
                "today": 0
            }
            const prev_date_count = CUSTOM_DATE_CONFIG[custom_date_config]
            const prev_date = getPreviousDay(prev_date_count);

            if (!need_to_use_local_filter) {

                parameterized_values_to_use['nf_date'] = prev_date;
                parameterized_values_to_use["type"] = "date";
            }
            const parse_date = new Date(prev_date)

            // and append this into filter as well
            if (insights) report_filters['date_filter']['generic_date'] = { custom_date_config: parse_date, type: 'custom_date_config' }
        }

        else {
            if (!need_to_use_local_filter) {
                parameterized_values_to_use['nf_date'] = new Date();
                parameterized_values_to_use["type"] = "date"
            }
            report_filters['date_filter']['generic_date'] = { single_date: new Date(), single_type: 'single_date', type: "custom_date" }
        }
    }

    // console.log("parameterized_values_to_use", report_filters, parameterized_values_to_use, need_to_add_global)
}



const get_all_parameters_for_details = (history) => {

    // ==================

    if (!history) return;

    const { location } = history
    const { search } = location;

    const param_data = getParameterByName('details_data', search) || "{}"

    const details_data = param_data && JSON.parse(param_data)

    if (details_data && details_data !== null && typeof details_data !== 'undefined' && Object.keys(details_data)?.length > 0) {
        return details_data;
    }

}


const create_date_filter_for_be_format = (base_data) => {

    const clone_base_data = base_data ? { ...base_data } : {};

    const type = base_data["type"];
    const year = base_data["year"];
    const month = base_data["month"] || 1;
    const single_date = clone_base_data["single_date"];

    const start_date = clone_base_data["start_date"] || clone_base_data["range_start_date"];
    const end_date = clone_base_data["end_date"] || clone_base_data["range_end_date"];

    switch (type) {
        case "month":
        case "month_year":
            return {
                "type": "month_year",
                "month_year": MONTHS[month] + " " + year
            };
        case "year":
            return {
                "type": "year",
                "year": year
            };
        case "range":
            return {
                "type": "range",
                "range_start_date": start_date,
                "range_end_date": end_date,
            };
        default:
            return {
                "type": "single",
                "single_date": single_date || new Date(),
            }
    }


}




var matrix = {
    'rpt_m23f4zcl': [
        "why ebit is continuously decreasing in 2023 for Tamil Nadu",
        "why ebit is continuously decreasing and find the root cause for 2023 for Tamil Nadu",
        "why ebit is very low in 2023 for Tamil Nadu",
        "why ebit is very low in 2023 for 'Tamil Nadu'",
        "why ebit is continuously decreasing in 2023 for 'Tamil Nadu'",
        "why ebit is continuously decreasing and find the root cause for 2023 for 'Tamil Nadu'",
        'why low in tamil nadu',
        'why low in tamil Nadu',
        'why low in Tamil Nadu',
        'why low in Tamil nadu'
    ],
    'rpt_m28okegy': [
        'why content cost per subscriber is rising',
        'why content cost per subscriber is increasing',
        'Why is the cost of content per subscriber going up?',
        'Why is the cost of content per subscriber going up',

    ]
};


function removeSpaces(str) {
    return str.replace(/\s+/g, '');
}

function findMatrixKey(question) {
    var cleanedQuestion = removeSpaces(question).toLowerCase();
    for (var key in matrix) {
        for (var i = 0; i < matrix[key].length; i++) {
            if (removeSpaces(matrix[key][i]).toLowerCase() === cleanedQuestion) {
                return key;
            }
        }
    }
    return null; // Return null if the question is not found
}



/***
 * 1. question
 * 2. userId
 * 3. id
 * 4. response_session_id,
 * 5. reportType,
 * 6. chartType,
 * 7. renderMode
 * 8. filters
 * 9   dbInfoId
 * 10. insights
 * 11. isDimension
 * 12. history,
 * 13. forceLoaderStop
 * 14, parameterized_values
 * 15. isMap,
 * 16. is_detail_query,
 * 17. opr_report_id,
 * 18 opr_token
 * 19. opr_client_id
 * 20: extra data
 * 21. insight_id,
 * 22. is_global_filters
 */


const used_date_filter = {};


var insight_id_for_filter = undefined;

export const getReport = (
    question,
    userId,
    id,
    response_session_id,
    reportType,
    chartType,
    renderMode,
    _report_filters = {},
    link_filter_to_apply,
    _report_item_filters = {},
    dbInfoId,
    insights,
    isDimension,
    history,
    forceLoaderStop,
    parameterized_values,
    isMap,
    is_detail_query,
    opr_report_id,
    opr_token,
    opr_client_id,
    extra_body_data,
    insight_id,
    is_global_filters,
    drill_filter_cache,
    report_item_filter_key,
    default_filter_values,
    is_cache_refresh_required_forcefully,
    is_pinned_question,
    user,
    date_filter_columns,
    is_print_report,
    isDashboardPrintMode,
    isPolusAIWindow = false,
    why_addition_filter,
    gpt_algo_questions

) => async dispatch => {


    if (isDashboardPrintMode && !default_filter_values && insight_id === 'rpt_lzgsh9y3') {
        default_filter_values = { "range_start_date": "2024-04-01T09:52:36.626Z", "range_end_date": "2025-03-31T09:52:39.973Z" };
    }


    if (question?.length > 0) {

        const matrix = findMatrixKey(question);

        if (matrix) {
            dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);
            setTimeout(() => {
                history.push(`/?insightId=${matrix}&newParameter=newValue`)
                dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
            }, 2000);
            return;

        }
    }

    const isDeveloper = user?.is_developer;

    const details_data = get_all_parameters_for_details(history);
    let other_filters = {};

    // this is only for details

    if (details_data && Object.keys(details_data).length > 0) {

        if (details_data.report_filter) _report_filters = details_data.report_filter;
        if (details_data._report_item_filters) _report_filters = details_data._report_item_filters;
        if (details_data.report_filter) _report_filters = details_data.report_filter;
        if (details_data.filter) other_filters = details_data.filter;
        if (details_data.is_detail_query) {
            is_detail_query = true;

        }

        insight_id = details_data.insight_id;
        response_session_id = details_data.response_session_id

    }

    if (insights) {
        dispatchAction(dispatch, actionTypes.RAISE_DASHBOARD_WIDGETS_LOADER, { report_id: id })
    }

    if (!insights) {
        dispatchAction(dispatch, actionTypes.CLEAR_DATA_FROM_SERVER, { id: id })
    }


    const report_filters = _report_filters ? JSON.parse(JSON.stringify(_report_filters)) : {};
    const report_item_filters = _report_item_filters ? JSON.parse(JSON.stringify(_report_item_filters)) : {};

    const filters = Object.assign(s_p((report_filters || {})), s_p((report_item_filters || {})));

    dispatchAction(dispatch, actionTypes.REPORT_REFRESHED, { base_report_id: id }); // updating status to ignore 
    dispatchAction(dispatch, errorActionTypes.DELETE_DASHBOARD_ERROR, { id: id })
    dispatchAction(dispatch, errorActionTypes.DELETE_REPORT_ERROR, { id: id })


    const client_info = await get_client_info();

    let url = constants.END_POINTS.API + constants.END_POINTS.REPORT.GET_DATA_FROM_REPORTING_SERVER;

    if (!insights) {
        url = url + `?question=${question}`;
    }
    if (!insights & !forceLoaderStop) {
        // dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);
    }

    let post_filters = undefined;

    if (filters) {
        post_filters = Object.assign({}, filters);
        delete post_filters.key;
    }


    const final_date_filter = {};
    var parameterized_values_to_use = parameterized_values ? parameterized_values : {};
    const default_date_filters = default_filter_values ? default_filter_values : undefined;

    // let's build some require filters for report 

    build_required_filter_and_parameters_for_report_v1({
        default_date_filters,
        parameterized_values_to_use,
        report_filters,
        _report_item_filters,
    }, insights, reportType, id)


    post_filters && Object.keys(post_filters).length > 0 && Object.keys(post_filters).forEach(key => {
        if (key !== 'date_filter' && id) {
            // here we will insure that the post_filter not mt array if filter is mt array then drop it
            if (post_filters[key] && Array.isArray(post_filters[key]) && post_filters[key].length === 0) delete post_filters[key];
            else if (!id) delete post_filters[key];
        }
    });

    delete final_date_filter['generic_date'] // remove the generic_date we don't need to send it to backend
    const ui_date_filter_v2 = post_filters?.["date_filter"] || {};
    delete ui_date_filter_v2["generic_date"];

    post_filters["date_filter"] = ui_date_filter_v2;


    if (link_filter_to_apply && Object.keys(link_filter_to_apply).length > 0) {
        const linked_filter_data = lets_build_link_filter(post_filters, link_filter_to_apply)
        post_filters = linked_filter_data?.filters;
        if (linked_filter_data?.parameterized_values_to_use) {
            parameterized_values_to_use = linked_filter_data?.parameterized_values_to_use
        }
    }

    if (typeof post_filters === 'undefined') post_filters = {};


    console.log("GetReport PERFORMANCE ===> Step 2, Sending Request", id, '=====>', new Date().toLocaleTimeString());

    let entry = new Date();


    // report_filters
    // let's store process global filter 

    // if (insight_id && !used_date_filter[insight_id] && report_filters) {
    //     used_date_filter[insight_id] = report_filters;
    //     console.log("filters_cache1.1", report_filters)
    apply_filters(insight_id, report_filters, undefined, undefined)(dispatch)

    // }

    const postData = {
        fe_req_time: entry,
        ...extra_body_data,
        question: question && question.trim().replace(/\.$/g, '').replace(/\?$/g, ''),
        userId,
        id: id,
        report_type: reportType === "cache_editor" ? "data_source" : reportType,
        filters: Object.assign({}, post_filters, (other_filters || {})),
        [CHART_CONFIG.chart_type]: chartType,
        [CHART_CONFIG.render_mode]: renderMode,
        db_info_id: dbInfoId !== 'undefined' ? dbInfoId : null,
        client_id: opr_client_id,
        token: opr_token,
        parameterized_values: parameterized_values_to_use,
        response_session_id: response_session_id,
        is_detail_query: is_detail_query,
        dashboard_id: insight_id,
        is_cache_refresh_required_forcefully: why_addition_filter ? true : is_cache_refresh_required_forcefully,
        is_pinned_question: is_pinned_question ? id : undefined,
        is_print_report: is_print_report || false,
        eventType: isPolusAIWindow ? 'POLUS_AI' : undefined,
        why_addition_filter: why_addition_filter,
        gpt_algo_question: gpt_algo_questions,
    }

    try {

        console_logger("GET REPORT ACTION INIT AND POST DATA SUCCESSFULLY");


        let data = await post(url, postData);

        if (isDeveloper) {
            console.log("__NF__REPORT__ START *****************************************************************")
            console.log("__NF__REPORT__ REPORT ID", id)
            console.log("__NF__REPORT__ REQ DATA", id, postData);
            console.log("__NF__REPORT__ RESPONSE DATA", id, data.data)
            console.log("__NF__REPORT__ END *****************************************************************")
        }

        !isDeveloper && console.log("GetReport PERFORMANCE ===> Step 3, Response Received", id, '=====>', new Date().toLocaleTimeString());


        // data.data = { ...data.data, ...vd };

        if (data && data.code === 200) {
            // let's remove this from filter cache 
            remove_widget_id_from_stored(insight_id, id)(dispatch)

            console_logger("RECIVE RESPONSE FROM API ==");

            // data.data = {...data.data, ...t}
            const actualData = data.data;

            if (actualData?.pivot_data?.data?.length > 0) {
                set_table_data_mode("pivot_data", actualData?.reportId)(dispatch)
            } else {
                set_table_data_mode("raw_data", actualData?.reportId)(dispatch)
            }

            if (actualData && actualData?.raw_data?.data?.length === 0) {
                actualData.raw_data['data'][0] = { key: "No Values Found" };
                actualData.config_meta.chart_type_force = 'table';
                actualData.config_meta.render_mode_force = 'table';
            }
            if (actualData && actualData?.raw_data?.data?.length === 1 && Object.values(actualData?.raw_data?.data[0])?.[0] === 'No Values Found') {
                actualData.config_meta.chart_type_force = 'table';
                actualData.config_meta.render_mode_force = 'table';
            }



            if (data.data && data.data.is_multi_display) {
                await setMultiDisplayData(actualData)(dispatch)
                if (history) {
                    history.push(`/multi_display?question=${question}`);
                }
            }
            if (!isDimension || !insights) {
                dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
            }
            if (actualData.trend_filters && actualData.trend_filters.dashboard && actualData.trend_filters.dashboard.length > 0 && !insights) {
                await setDiscoveryData(actualData)(dispatch);
                if (history) {
                    history.push(`/discovery?question=${question}`);
                }
            }
            else {

                dispatchAction(dispatch, actionTypes.DATA_FROM_RS_SUCCESS, actualData, undefined, undefined, 0);

                if (id && ['config_query_builder', 'sql', 'dashboard', 'plain_english', 'dashboard_sql', 'dashboard_config_query_builder'].indexOf(reportType) > -1) {


                    // v2 let's get filter for widgets
                    if (insights || reportType === "config_query_builder") {
                        getFiltersForDropDowns(id, client_info, question, reportType, id, post_filters)(dispatch)
                    }

                    const __config_meta__ = data.data && data.data.config_meta;
                    const column_infos = __config_meta__?.column_infos || [];
                    const formula_columns = column_infos.filter((f) => f.is_formula) || [];
                    const report_viewer_formula = formula_columns && formula_columns.length > 0 && formula_columns.filter((f) => f.formula_type === "report_viewer")
                    const tbl_format_settings = __config_meta__?.tbl_format_settings;

                    if (tbl_format_settings && tbl_format_settings.length > 0) dispatch_tbl_format_settings(tbl_format_settings, id,)(dispatch)
                    if (report_viewer_formula && report_viewer_formula.length > 0) dispatch_report_viewer_formula(report_viewer_formula, id)(dispatch)

                    if (reportType === 'dashboard' && 'dashboard_sql', 'dashboard_config_query_builder' && !isMap) dispatch_filters(dispatch, data.data.back_end_where_conditions, id, undefined, report_item_filter_key)

                    if (!is_detail_query && !data.data.back_end_where_conditions && (reportType === 'dashboard' || reportType === 'dashboard_sql' || reportType === 'dashboard_config_query_builder' || reportType === 'config_query_builder' && !isMap)) {

                        // apply_filters(id, _report_item_filters, undefined, undefined, report_item_filter_key)(dispatch)

                        // if (!is_global_filters && !insights) getFiltersForDropDowns(id, client_info, question, reportType, undefined, post_filters)(dispatch)
                    }

                    /**
                     * this if condition will be dispatch the filter by insight id
                     */
                    if (insight_id && !is_detail_query) {
                        // if(insight_id_for_filter === undefined) insight_id_for_filter = insight_id;
                        // console.log("we are going to apply GF", report_filters)
                        // check_updated_filter_from_backend_for_global_filter(insight_id, report_filters, drill_filter_cache, id, dispatch)
                    }
                }
            }

            //console.log('brilliant 1.2 ', new Date());

            if (insights) {
                dispatchAction(dispatch, actionTypes.HIDE_DASHBOARD_WIDGETS_LOADER, { report_id: id })
            }
        }
    } catch (error) {

        console.log("REPORT ERROR", error)
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);

        if (insights) {

            dispatchAction(dispatch, actionTypes.HIDE_DASHBOARD_WIDGETS_LOADER, { report_id: id })
            remove_widget_id_from_stored(insight_id, id)(dispatch)

        }

        if (['config_query_builder', 'sql', 'plain_english'].indexOf(reportType) > -1) {
            dispatchAction(dispatch, errorActionTypes.RAISE_REPORT_ERROR, {
                id: id,
                error: error
            })

        } else {
            dispatchAction(dispatch, errorActionTypes.RAISE_DASHBOARD_ERROR, {
                id: id,
                error: error
            })
        }

    }
};



const check_updated_filter_from_backend_for_global_filter = (insight_id, filters, drill_filter_cache, id, dispatch) => {
    if (filters) {
        if (typeof drill_filter_cache === 'undefined' || (drill_filter_cache && Object.keys(drill_filter_cache).length == 0)) {
            apply_filters(insight_id, filters, undefined, drill_filter_cache)(dispatch)

        }
    }
}


const check_updated_filter_from_backend = (report_id, report_item, type, dispatch) => {

    const __parameters__ = [];
    const report__parameters__ = report_item && report_item.parameters;

    report__parameters__ && report__parameters__.length > 0 && report__parameters__.forEach((f) => {
        if (type === 'dashboard') {
            const filter_column_name = (f && f.split("__nf__")[0]);
            __parameters__.push(filter_column_name)
        } else {
            __parameters__.push(f)
        }
    })

    const exist_filter_to_apply = {};
    const session_filters = get_filters_from_session(report_id);

    __parameters__ && __parameters__.length > 0 && __parameters__.forEach((k) => {
        session_filters && Object.keys(session_filters).length > 0 && Object.keys(session_filters).map((l) => {
            if (l === "date_filter") {
                const __date_filters__ = session_filters[l];
                __date_filters__ && Object.keys(__date_filters__).length > 0 && Object.keys(__date_filters__).map((d) => {
                    if (k && d && (k + '').toLocaleUpperCase() === (d + '').toLocaleUpperCase()) {
                        if (!exist_filter_to_apply['date_filter']) exist_filter_to_apply["date_filter"] = {};
                        if (!exist_filter_to_apply['date_filter'][d]) exist_filter_to_apply['date_filter'][d] = __date_filters__[d];
                    }
                })

            }
            if (k && l && (k + '').toLocaleLowerCase() === (l + '').toLocaleLowerCase()) {
                exist_filter_to_apply[l] = session_filters[l]
            }
        })
    });

    if (type === 'dashboard') {
        apply_filters(report_id, session_filters)(dispatch)
    }
    else {
        apply_filters(report_id, exist_filter_to_apply)(dispatch)
    }

}

const dispatch_filters = (dispatch, __back_end_where_conditions__, report_id, source = 'NONE', report_item_filter_key) => {

    // const full_months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const fy_date_settings = {
        quarters: {
            0: 3,
            1: 0,
            2: 1,
            3: 2,
        },
        months: {
            1: 10,
            2: 11,
            3: 12,
            4: 1,
            5: 2,
            6: 3,
            7: 4,
            8: 5,
            9: 6,
            10: 7,
            11: 8,
            12: 9,
        }
    }


    const filters_from_backend = {};

    const identify_filters = (filter) => {


        const process_string_filters = (string_filter) => {
            const values = string_filter.values || [];

            if (typeof string_filter.table === 'undefined' || string_filter.column === 'undefined') {
                // dont process, faulty filters received
            }
            else {
                filters_from_backend[`${string_filter.table}.${string_filter.column}`] = values;
            }
        };

        if (filter && filter.values && filter.values.length > 0) {

            // date will never be one
            if (is_date(filter.values[0])) {

                const d_1 = new Date(filter.values[0]);
                const d_2 = new Date(filter.values[1]);

                const filter_column_name = filter.table + "." + filter.column;

                if (!filters_from_backend['date_filter']) filters_from_backend['date_filter'] = {};
                if (!filters_from_backend['date_filter'][filter_column_name]) filters_from_backend['date_filter'][filter_column_name] = {};

                const identify_date_filters = (d_1, d_2) => {
                    const main_filter = filters_from_backend['date_filter'][filter_column_name];

                    let full_quarter_selected = false;
                    let full_year_selected = false;

                    const years = {};
                    const quarters = {};

                    for (let date_counter = d_1; date_counter < d_2;) {
                        const year = date_counter.getFullYear();
                        let month = date_counter.getMonth() + 1;
                        let qtr = (month > 9) ? 3 : (month > 6) ? 2 : (month > 3) ? 1 : 0;

                        qtr = fy_date_settings.quarters[qtr];

                        main_filter[year + '-' + qtr + '-' + month] = 1;

                        if (!years[year]) years[year] = 0;
                        if (!quarters[qtr]) quarters[qtr] = 0;

                        years[year] = years[year] + 1;
                        quarters[qtr] = quarters[qtr] + 1;

                        if (years[year] >= 12) {
                            // we got all the months
                            main_filter[year] = 1;
                        }
                        else {
                            main_filter[year] = 1.1;
                        }

                        if (quarters[qtr] >= 3) {
                            // we got all the months
                            main_filter[year + '-' + qtr] = 1;
                        }
                        else {
                            main_filter[year + '-' + qtr] = 1.1;
                        }

                        date_counter = date_add(date_counter, 'month', 1);
                    }
                };

                identify_date_filters(d_1, d_2);
            }
            else {
                process_string_filters(filter);
            }
        }
        else {
            process_string_filters(filter);
        }
    };


    const back_end_where_conditions = __back_end_where_conditions__ || [];

    back_end_where_conditions.forEach(f => {
        identify_filters(f);
    });

    if (filters_from_backend && Object.keys(filters_from_backend).length > 0) {
        setTimeout(() => {
            console.log('applying ===> ', filters_from_backend)
            apply_filters(report_id, filters_from_backend, true, undefined, report_item_filter_key)(dispatch)
            getFiltersForDropDowns(report_id, undefined, undefined, "dashboard", undefined, filters_from_backend)(dispatch)
            // dispatchAction(dispatch, V2_RAISE_BACKEND_FILTER, { base_report_id : report_id,  filters: filters_from_backend})
        }, 2000)
    }
}



export const getStringHintsFromServer = (db_info_id) => async dispatch => {

    const post_body = {
        // db_info_id
    }

    dispatchAction(dispatch, actionTypes.CLEAR_HINTS_FROM_RS)
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.GET_ENTITY_VALUES;
    simpleDispatch_post(dispatch, url, post_body, actionTypes.HINTS_FROM_RS_SUCCESS, true);
};

export const clearDataInRedux = () => async dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_ALL_DATA, undefined, undefined, undefined);
};



export const getDataFromSecondApi = (question, tempFilters, reportId, response_session_id) => async dispatch => {

    const postData = {
        question: question,
        reportId,
        filters: Object.assign({}, tempFilters),
        response_session_id,
    }

    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.GET_DATA_FROM_REPORTING_SERVER_SECOND_API;
    simpleDispatch_post(dispatch, url, postData, actionTypes.DATA_FROM_RS_SUCCESS);

}



export const changeActiveData = data => async dispatch => {
    dispatchAction(dispatch, actionTypes.CHANGE_ACTIVE_DATA_SUCCESS, data, undefined, undefined);
};


export const getAllReports = (c_id, type) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.GET_ALL_REPORTS;
    const post = {
        c_id,
        type: type ? type : undefined,
    }

    simpleDispatch_post(dispatch, url, post, actionTypes.GET_ALL_REPORTS_SUCCESS, true);
};




export const setAvailableData = data => async dispatch => {
    dispatchAction(dispatch, actionTypes.DATA_FROM_RS_SUCCESS, data, undefined, undefined);
};



export const saveReport = (report, history, filterFunction, run_report, path, success, report_type, parameters_fields, view_report_query, report_filter_default_values, db_formulas) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.SAVE_REPORT;

    const data = await put(url, report);

    if (data && data.code === 200) {
        if (success) {
            raiseSuccess(dispatch, { type: 'success', message: 'config Saved Successfully' })
        }

        // this block is only for save report
        if (data.data && data.data.report && !run_report) {
            const scheduleReportId = data.data.mainReportId;
            if (history && !report?.[0]?.id) {
                history.push(`/config_editor?report_id=${scheduleReportId}`)
            }
            get_edit_report_by_id(scheduleReportId, undefined, view_report_query)(dispatch)
        }

        if (data.data && data.data.report && history && run_report) {
            const scheduleReportId = data.data.mainReportId;
            const db_info_id = undefined;
            if (report_type === 'config_query_builder' || report_type === 'data_source' || report_type === 'sql' || report_type === 'cache_editor') {
                clear_data_for_report(scheduleReportId)(dispatch);
                _getDataFromReportingServer(undefined, scheduleReportId, undefined, undefined, report_type, undefined, history, undefined, db_info_id, parameters_fields, report_filter_default_values)
            }
        }
        dispatchAction(dispatch, actionTypes.SAVE_REPORT_SUCCESS, data)
    }

    getAllReports()(dispatch);
    get_all_reports()(dispatch)
    get_report_menus()(dispatch)

};




// export const get_pivot_data = () => async dispatch => {

//     const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.PIVOT.GET_PIVOT_DATA;

//     const data = await post(url, report);

//     if (data && data.code === 200) {
//         if (success) {
//             raiseSuccess(dispatch, { type: 'success', message: 'config Saved Successfully' })
//         }

//         // this block is only for save report
//         if (data.data && data.data.report && !run_report) {

//         }


//         dispatchAction(dispatch, actionTypes.SAVE_REPORT_SUCCESS, data)
//     }
// };


export const getReportById = (id, type) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.GET_ALL_REPORTS;
    const post = {
        report_id: id,
        type
    }
    simpleDispatch_post(dispatch, url, post, helperActionTypes.SET_ACTIVE_EDIT_REPORT_SUCCESS, true);
};




export const clearFormulas = data => async dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_FORMULAS)
};




export const getFormulasByReportId = (id) => async dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_FORMULAS)

    const url = constants.END_POINTS.API + constants.END_POINTS.FORMULAS.POINT + constants.END_POINTS.FORMULAS.GET_FORMULA_BY_REPORT_ID;
    const post = {
        report_id: id,
    }

    simpleDispatch_post(dispatch, url, post, actionTypes.GET_FORMILAS_BY_REPORT_ID);
};



export const setActiveEditReport = data => async dispatch => {
    dispatchAction(dispatch, helperActionTypes.SET_ACTIVE_EDIT_REPORT_SUCCESS, data, undefined, undefined);
};



/**
 * ----------------------------------------------------------------------------------------
 * v2 implementation
 * ----------------------------------------------------------------------------------------
 */

/**
 * 
 * @param {*} question 
 * @param {*} userId 
 * @param {*} reportId the temp report id which will tie data to the report wrapper. it will be returned back from backend along with the data
 * @param {*} filters 
 * @param {*} chartType 
 * @param {*} renderMode 
 * 
 * @returns nothing, dispatches the data to reducer
 * 
 * this function will only fetch the data for the question
 */
export const getDataForQuestion = (question, userId = 'default', reportId, filters, chartType, renderMode) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.GET_DATA_FROM_REPORTING_SERVER;
    const post = {
        question,
        userId,
        reportId,
        [CHART_CONFIG.chart_type]: chartType,
        [CHART_CONFIG.render_mode]: renderMode,
    };

    if (!process.env.REACT_APP_DEV_LOGIN_KEY) {
        post['client_info'] = await get_client_info();
    }

    simpleDispatch_post(dispatch, url, post, actionTypes.DATA_FROM_RS_SUCCESS);
};


/**
 * 
 * @param {*} reportId the report id, for which we will fetch the data
 * @param {*} userId 
 * @param {*} filters 
 * @param {*} chartType 
 * @param {*} renderMode 
 * @returns 
 */
export const getDataForReport = (reportId, userId = 'default', filters, chartType, renderMode) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.GET_DATA_FROM_REPORTING_SERVER;

    const post = {
        reportId,
        userId,
        [CHART_CONFIG.chart_type]: chartType,
        [CHART_CONFIG.render_mode]: renderMode,
    };

    if (!process.env.REACT_APP_DEV_LOGIN_KEY) {
        post['client_info'] = await get_client_info();
    }

    await simpleDispatch_post(dispatch, url, post, actionTypes.DATA_FROM_RS_SUCCESS);
};


/**
 * 
 * @param {*} db_info_id 
 * @param {*} table_name 
 * @param {*} column_name 
 */
export const getDistinctDataForColumn = (db_info_id, table_name, column_name) => dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.GET_DISTINCT_DATA_FOR_A_COLUMN;

    const post = {
        db_info_id,
        table_name,
        column_name
    };

    simpleDispatch_post(dispatch, url, post, actionTypes.DISTINCT_DATA_FROM_RS_SUCCESS, true);
};





export const save_tbl_formulas = (formulas, report_id, report_item_id, type) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.SAVE_TBL_FORMULAS;
    const post = {
        formulas: formulas,
        report_id,
        report_item_id,
        type
    }

    const have_deleted_formula = formulas && formulas.length > 0 && formulas.filter((f) => f.deleted);

    if (have_deleted_formula && have_deleted_formula.length > 0) {
        raiseSuccess(dispatch, {
            type: 'success',
            message: 'Formula Deleted Successfully'
        })
    }

    await simpleDispatch_post(dispatch, url, post, actionTypes.SAVE_TBL_FORMULAS, true);
    await dispatchAction(dispatch, actionTypes.ADD_TBL_FORMULAS, { formula: formulas, report_id: report_id });
    await get_tbl_formulas(report_id, report_item_id, 'report_viewer')(dispatch)
}

export const get_tbl_formulas = (report_id, report_item_id, type) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.GET_TBL_FORMULAS;

    const post = {
        report_id,
        report_item_id,
        type
    }

    simpleDispatch_post(dispatch, url, post, actionTypes.GET_TBL_FORMULAS, true);

}



export const get_tbl_formulas_for_editor = (report_id, report_item_id, type) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.GET_TBL_FORMULAS;

    const post = {
        report_id,
        report_item_id,
        type
    }
    simpleDispatch_post(dispatch, url, post, actionTypes.GET_TBL_FORMULAS_FOR_EDITOR, true);

}


export const dispatch_report_viewer_formula = (formulas, report_id) => async dispatch => {
    dispatchAction(dispatch, actionTypes.GET_TBL_FORMULAS, { formulas: formulas, report_id: report_id });
}



export const db_functions_config = () => async (dispatch) => {
    const url = constants.END_POINTS.API + constants.END_POINTS.DB_FUNCTIONS_CONFIG.POINT + constants.END_POINTS.DB_FUNCTIONS_CONFIG.GET_FUNCTIONS_CONFIG;

    simpleDispatch_get(dispatch, url, actionTypes.GET_FUNCTIONS_CONFIG)

}


export const db_week_config = () => async (dispatch) => {
    const url = constants.END_POINTS.API + constants.END_POINTS.DB_FUNCTIONS_CONFIG.POINT + constants.END_POINTS.DB_FUNCTIONS_CONFIG.GET_WEEK_CONFIG;

    simpleDispatch_get(dispatch, url, actionTypes.GET_WEEK_CONFIG)

}






export const set_report_to_refresh = (base_report_id, is_cache_refresh_required_forcefully) => async (dispatch) => {
    dispatchAction(dispatch, actionTypes.REPORT_TO_REFRESH, { base_report_id, is_cache_refresh_required_forcefully }); // updating status to ignore 

}


export const clear_data_for_report = (base_report_id) => async (dispatch) => {
    dispatchAction(dispatch, actionTypes.CLEAR_REPORT_DATA, { base_report_id }); // updating status to ignore 

}


export const save_updated_drill_down_data = (base_report_id, drill_down_data) => async (dispatch) => {
    dispatchAction(dispatch, actionTypes.SAVE_DRILL_DOWN_VALUES, { base_report_id, drill_down_data }); // updating status to ignore 
}

export const test_sql_query = (query, db_info_id, report_id, signal) => async dispatch => {
    //  here pass abort
    const _body_ = { query: query, db_info_id: db_info_id, report_id: report_id }

    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.TEST_SQL_QUERY

    dispatchAction(dispatch, actionTypes.CLEAR_TEST_QUERY_DATA, {
        report_id: report_id
    })

    dispatchAction(dispatch, actionTypes.RAISE_SQL_LOADER, { id: report_id })

    try {
        const data = await post(url, _body_, undefined, undefined, signal);
        dispatchAction(dispatch, actionTypes.HIDE_SQL_LOADER, { id: report_id })
        if (data && data.code === 200 && data.data) {

            const final_data_to_dispatch = {
                ...data.data,
                report_id: report_id,
            }
            dispatchAction(dispatch, actionTypes.TEST_SQL_SUCCESS, final_data_to_dispatch)
        } else throw new Error("Somthing went wrong")
    } catch (error) {
        const final_data_to_dispatch = {
            error: error,
            report_id: report_id,
        }
        dispatchAction(dispatch, actionTypes.HIDE_SQL_LOADER, { id: report_id })
        dispatchAction(dispatch, actionTypes.RAISE_TEST_QUERY_ERROR, final_data_to_dispatch)
    }
}



export const reset_data_from_server = () => async (dispatch) => {
    dispatchAction(dispatch, actionTypes.RESET_DATA_FROM_SERVER)
}


export const save_pivot_data = (report_id, report_item_id, columns_info) => async (dispatch) => {

    const clone_columns_info = columns_info ? JSON.parse(JSON.stringify(columns_info)) : [];
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.SAVE_PIVOT;

    clone_columns_info.map(c => {
        if (c.pivot_type === "pivot_y") {
            c.visible_type = c.visible_type === 2 ? 2 : 1;
        }
    })


    await post(url, { columns_info: clone_columns_info, report_item_id, }, true);
    /***
     * @ we commenting this code because we will not assign the the id to pivot settings
     * @ saroj kumar
     * @ 18 feb 2022
     */
    // const data_from_server = await post(url, { pivot_x, pivot_y: pivot_y_for_saving, pivot_comparison: processed_columns, report_item_id, }, true);
    // if (data_from_server) {
    //     dispatchAction(dispatch, actionTypes.SAVE_PIVOT_DATA_SUCCESS, { report_id, pivot_x, pivot_y, pivot_comparison, report_item_id, results: data_from_server.data });
    // }
    // getAllReports()(dispatch);
};


export const dispatch_pivot_data = (report_id, report_item_id, pivot_x = [], pivot_y = [], pivot_comparison = [], column_infos, need_to_switch_row_column, file_path, chart_type) => async (dispatch) => {

    // dispatchAction(dispatch, actionTypes.DISPATCH_PIVOT_DATA_SUCCESS, { report_id, pivot_x, pivot_y, pivot_comparison, report_item_id, results: [], column_infos, need_to_switch_row_column });

    const url = constants.END_POINTS.API + constants.END_POINTS.PIVOT.POINT + constants.END_POINTS.PIVOT.GET_PIVOT_DATA;

    // const data = await post(url, { report_id, pivot_x, pivot_y, pivot_comparison, report_item_id, results: [], column_infos, need_to_switch_row_column, file_path });

    // await post(url, { columns_info: clone_columns_info, report_item_id, }, true);

    if (pivot_x?.length > 0 || pivot_y?.length > 0) {
        simpleDispatch_post(dispatch, url, {
            report_id,
            pivot_x,
            pivot_y,
            pivot_comparison,
            report_item_id,
            results: [],
            column_infos,
            need_to_switch_row_column,
            file_path,
            chart_type
        }, actionTypes.DISPATCH_PIVOT_DATA_SUCCESS,)
    }

    // dispatchAction(dispatch, actionTypes.DISPATCH_PIVOT_DATA_SUCCESS, {  });


};



export const clear_pivot_data = (base_report_id) => async (dispatch) => {
    const data_to_dispatch = {
        base_report_id: base_report_id
    }
    dispatchAction(dispatch, actionTypes.CLEAR_PIVOT_DATA_SUCCESS, data_to_dispatch)

}

export const change_data_mode = (mode) => async (dispatch) => {
    dispatchAction(dispatch, actionTypes.CHANGE_DATA_MODE, mode)
};

export const switch_rows_into_columns = (type) => async (dispatch) => {
    // dispatchAction(dispatch, actionTypes.DISPATCH_PIVOT_DATA_SUCCESS, { report_id, pivot_x, pivot_y, pivot_comparison, report_item_id, results: [], column_infos });
    dispatchAction(dispatch, actionTypes.SWITCH_ROWS_INTO_COLUMNS, type)
}


/***
 * This Function will update the pivot data 
 */
export const dispatch_updated_pivot_data = (pivot_data, sort_orders, base_report_id) => async (dispatch) => {

    dispatchAction(dispatch, actionTypes.UPDATE_PIVOT_DATA, { sorted_pivot_data: pivot_data, sort_orders: sort_orders, base_report_id: base_report_id });
}


/***
 * This Function will update the process data
 */

export const dispatch_updated_process_data = (process_data, sort_orders, base_report_id) => async (dispatch) => {
    dispatchAction(dispatch, actionTypes.UPDATE_PROCESS_DATA, { process_data: process_data, sort_orders: sort_orders, base_report_id: base_report_id });
}


export const save_report_items = (report_items = [], report_item_id, isQuestionModified = false) => async (dispatch) => {

    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.SAVE_REPORT_ITEMS;

    try {

        const data = await put(url, { report_items: report_items });

        if (data?.data?.report_items && isQuestionModified) {
            setTimeout(() => {
                set_report_to_refresh(report_item_id)(dispatch)
            }, 100);
            // const report_items = data?.data?.report_items;
            // const ids = report_items.map((r) => r.id)
            // getReport(undefined, undefined, ids[0], undefined, "dashboard")(dispatch)
        }

    } catch (error) {
        console.log("save report items error", error)
    }
    // SAVE_REPORT_ITEM
}


/**
 * 
 * @param {*} columns_info 
 * @param {*} report_item_id 
 * @returns 
 */
export const save_columns_info = (columns_info, report_item_id, report_id, file_path) => async (dispatch) => {

    const clone_columns_info = columns_info ? JSON.parse(JSON.stringify(columns_info)) : [];
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.SAVE_REPORT_COLUMNS_INFO;
    const response = await post(url, { columns_info: clone_columns_info, report_item_id, });

    if (response && response.data && response.data.columns_info) {
        // update_columns_info_of_report(response.data.columns_info, report_item_id, report_id)(dispatch)
        const url1 = constants.END_POINTS.API + constants.END_POINTS.PIVOT.POINT + constants.END_POINTS.PIVOT.APPLY_FORMULA;
        // console.log("response.data.columns_info", response.data.columns_info)
        simpleDispatch_post(dispatch, url1, {
            column_infos: response.data.columns_info,
            file_path,
            report_id,
        }, actionTypes.DISPATCH_FORMULA_DATA,)
    }
}
// edit
// delete
// add
/**
 * 
 * @param {*} base_report_id 
 */
export const update_columns_info_of_report = (columns_info, base_report_id, report_id) => async (dispatch) => {
    dispatchAction(dispatch, actionTypes.UPDATE_REPORT_COLUMNS_INFO, {
        columns_info: columns_info, base_report_id: base_report_id, report_id: report_id
    })
}

/**
 * 
 * @param {*} base_report_id 
 */
export const get_columns_info = (base_report_id) => {
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.REPORT.POINT + constants.END_POINTS.REPORT.SAVE_REPORT_COLUMNS_INFO;

}

/**
 * 
 * @param {*} base_report_id 
 * @param {*} columns 
 * @returns 
 */
// export const update_report_column_details = (base_report_id, columns) =>  async (dispatch) => {
//     dispatchAction(dispatch, actionTypes.UPDATE_REPORT_COLUMN_DETAILS

// }

export const update_report_column_state = (base_report_id, columns_state, apply_on = "pivot") => async (dispatch) => {
    dispatchAction(dispatch, actionTypes.UPDATE_REPORT_COLUMN_STATE, {
        report_id: base_report_id,
        columns_state: columns_state,
        apply_on: apply_on
    })
}



// UPDATE_REPORT_USER_PREFERANCE

export const update_report_user_preferance = (report_id, report_item_id, preferance_name, preference_data, preferance_type) => async (dispatch) => {
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.UPDATE_REPORT_USER_PREFERANCE;

    const post = {
        report_id,
        report_item_id,
        preferance_name,
        preference_data: preference_data,
        preferance_type: preferance_type
    }

    simpleDispatch_post(dispatch, url, post, "UPDATED", true);

}





// GET_POLUS_GPT_AI_DATA_STORY


export const get_gpt_data_story = (report_id, file_path, is_chat) => async (dispatch) => {
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.GET_POLUS_GPT_AI_DATA_STORY;

    const post = {
        report_id,
        file_path,
        is_chat: is_chat

    }

    simpleDispatch_post(dispatch, url, post, actionTypes.GET_POLUS_AI_DATA_STORY, true);
}
