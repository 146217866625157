import { simpleDispatch_post } from './helper';
import * as actionTypes from '../action-types/formulas';
import { constants } from '../utils/constants';
import { getAllInfoOfQuestion } from './etl'


export const saveFormulas = (formulas, closeFunction, hideRefresh, etlQuestionAction) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.FORMULAS.POINT + constants.END_POINTS.FORMULAS.SAVE_FORMULAS;

    const post = {
        formulas
    };


    const data = await simpleDispatch_post(dispatch, url, post, actionTypes.SAVE_FORMULAS);

    if (closeFunction) {
        closeFunction();
    }

    if (data && data.code === 200 && !hideRefresh) {
        window.location.reload();
    }

    if (etlQuestionAction && etlQuestionAction.length > 0) {
        getAllInfoOfQuestion(etlQuestionAction)(dispatch)
    }
};