import React, { useEffect, useState } from "react";
import { LabelList, Line, ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, Cell, ResponsiveContainer, Legend, Label, Brush } from 'recharts';
import { constants } from '../../../utils/constants';
import { apply_styles, checkYearAndMakeFy, find_cell_format, formatMonthYearForChart, formatValueByDataType, getChartXAxisLayout, getRemToPx, get_formated_cell_data, get_required_attributes_for_chart_click_option, remove_repetitive_keys, showPopup, splitByNf, yDataValueFormatter } from "../../../utils";
import { DataFormater, DataFormaterFixed } from "../yaxis-label";
import enums from "../../../utils/enums";
import ChartFilterPopup from "../../chart-filter-popup";
import ReferenceLineLabel from '../label/referenceline.label';
import RenderLegend from '../legend/index.new';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomToolTip from '../tooltip/tooltip.custom';
import * as DataTypes from '../../../shared-with-fe/data.types';

import { CHART_TYPES } from '../../../shared-with-fe/constants';


const ParetoChart = (props) => {

    const {

        yac_chart_types, showDash, colorForYData, yac_axises,
        yDataKeys, paddingOnXaxis, xDataKeys,
        showReferenceLine, valueOfReferenceLine,
        showStacked, heightOfChart, widthOfChart,
        id, insights, isDimension, isDiscovery,
        hide_y_axis, hide_x_axis, hide_y_axis_border, hide_x_axis_border, hide_grid, hide_legend, hide_tooltip,
        hide_x_axis_label, hide_y_axis_label, showRightYAxis, Alignments, chartStyle, drill_down_type,
        drill_down_report, tooltipTheme, drill_down_values, comparisons, show_data_label,
        isReport, chartType, aggregate_options, yac_totals, equations, od_report_id, od_report,
        pivot_data_columns,
        hide_x_axis_secondry, barChartHoverColor, isHighlightedMin,
    } = props;


    const pivot_columns_state = props?.pivot_data_information?.pivot_columns_state;

    const [chartData, setChartData] = useState(undefined);
    const [axis_settings, set_axis_settings] = useState({
        color_preferences: {},
        uniques: {},
        should_custom_color_be_used: false,
        axis_tick_styles: {},
        x_axises: []
    });
    const [active_bar, set_active_bar] = useState(undefined)
    const [activeReferencecLine, setActiveReferencecLine] = useState(undefined)
    const [local_yac_chart_types, set_local_yac_chart_types] = useState(undefined)
    const [activeIndex, setActiveIndex] = useState(null);

    const handleMouseEnter = (data, index) => {
        setActiveIndex(index);
    };

    const handleMouseLeave = () => {
        setActiveIndex(null);
    };


    useEffect(() => {
        if (yac_chart_types?.length === 0 && yDataKeys?.length > 0) {
            set_local_yac_chart_types(Array(yDataKeys.length).fill(props?.chartType))
        } else {
            set_local_yac_chart_types(yac_chart_types)
        }


    }, [props?.chartType, yac_chart_types])

    var active_points = undefined

    useEffect(() => {
        hoist_the_state();
    }, [props.xDataKeys])

    const calculateCumulativePercentage = (data) => {
        const totalFrequency = data?.reduce((sum, item) => sum + item?.[local_y_data_keys[0]], 0);
        const sortedData = data.sort((a, b) => b?.[local_y_data_keys[0]] - a?.[local_y_data_keys[0]]);
        let cumulativeFrequency = 0;
        return sortedData.map(item => {
            cumulativeFrequency += item?.[local_y_data_keys[0]];
            const cumulativePercentage = (cumulativeFrequency / totalFrequency) * 100;
            return {
                ...item,
                "Cumulative Percentage": cumulativePercentage?.toFixed(2) // Limit to 2 decimal places
            };
        });
    };

    useEffect(() => {
        const prev_data = chartData && JSON.stringify(chartData);
        const upcomping_data = props.data && JSON.stringify(props.data);
        if (prev_data !== upcomping_data) {
            const xdataKey = local_x_data_keys[local_x_data_keys.length - 1]
            // const tickLabelAddedData = props.data?.map(el => ({ ...el, [xdataKey]: el?.[xDataKeys?.[0]] + (el?.[xDataKeys?.[1]] ? ("__nfx__join__" + el?.[xDataKeys?.[1]]) : '') }))
            const tickLabelAddedData = props.data?.map(el => ({
                ...el,
                [xdataKey]: (xDataKeys?.map((key, inde) => el?.[key] + (el?.[xDataKeys?.[inde + 1]] ? "__nfx__join__" : '')))?.join('')
            }))
            setChartData(calculateCumulativePercentage(tickLabelAddedData));
            // setChartData(props.data)
        }
    }, [props.data])


    /**
     * Global variables
     */

    const xAxisInterval = 0;

    const local_x_data_keys = xDataKeys;
    let local_y_data_keys = yDataKeys && yDataKeys.filter(f => f.indexOf('% Share_nfx_') === -1);
    local_y_data_keys = [...local_y_data_keys];

    const chart_xaxis_layout = getChartXAxisLayout(chartData, widthOfChart, heightOfChart, props.xDataKeys, props?.data?.length);


    const xAxisStyle = chartStyle.axis && chartStyle.axis.x;
    const yAxisStyle = chartStyle.axis && chartStyle.axis.y;
    const xAxisTickStyle = apply_styles((xAxisStyle && xAxisStyle['tick']), constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT)
    const yAxisTickStyle = apply_styles((yAxisStyle && yAxisStyle['tick']), constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT)
    const xAxisTitleStyle = apply_styles((xAxisStyle && xAxisStyle['title']), constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT)
    const yAxisTitleStyle = apply_styles((yAxisStyle && yAxisStyle['title']), constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT)
    const legendStyle = chartStyle && chartStyle.legend;


    if (chart_xaxis_layout?.fontSize) {
        xAxisTitleStyle.fontSize = chart_xaxis_layout?.fontSize
    }

    const all_yacs_with_pivot_info = props.pivot_yac_info || [];

    const primaries_header =
        (all_yacs_with_pivot_info
            .filter(f => f.axis === 'primary' && f.visible_type < 3)
            .map(m => m.pivot_field_alias)
            .join(','))

    const is_horizontal = (chartType === 'horizontal_bar_chart' || chartType === 'horizontal_waterfall_chart' || chartType === "horizontal_stack_bar_chart" || chartType === "horizontal_stack_bar_chart_100")

    // const is_multi_x_axis = local_x_data_keys && local_x_data_keys.length > 1;

    // let smallRatio = widthOfChart < 1000 && 20;

    // const x_axis_height_v2 = (() => {
    //     if (hide_x_axis && hide_x_axis_label) {
    //         return 0;
    //     } else if (is_multi_x_axis && hide_x_axis_label) {
    //         return chart_xaxis_layout?.angle > 0 ? xDataKeys?.length * 60 - smallRatio : 25;
    //         // return 25;
    //     } else if (is_multi_x_axis && !hide_x_axis_label && !hide_x_axis) {
    //         return chart_xaxis_layout?.angle > 0 ? xDataKeys?.length * 60 - smallRatio : 40;
    //         // return 40;
    //     } else if (hide_x_axis) {
    //         return 25
    //     }
    //     else if (hide_x_axis_label && chart_xaxis_layout?.angle > 0) {
    //         return chart_xaxis_layout?.height - 15 - smallRatio;
    //     }
    //     else if (hide_x_axis_label) {
    //         return chart_xaxis_layout?.height - 15;
    //     }
    //     else {
    //         return chart_xaxis_layout?.height;
    //     }
    // })();
    const TICK_PADDING = !hide_x_axis_label ? 15 : 5;
    let font_size = (0.014 * widthOfChart);
    font_size = font_size > 13 ? 13 : font_size < 9 ? 9 : font_size;

    const calculateTextHeightorWidth = (fontSizes = 12, fontFamily = 'Arial', isVertical) => {
        const samepleText = local_x_data_keys.length === 1 ? "Neuro Modu.." : local_x_data_keys.length === 2 ? "RoomO.. - Mar'21" : "Room.. - Mar'21 Room.. ";  // Representative sample text 

        let fontSize = fontSizes;
        if(fontSizes?.includes('rem')){
            fontSize = getRemToPx(parseFloat(fontSize))
        }else{
            fontSize = parseFloat(fontSize)
        };
        const span = document.createElement("span");
        span.style.fontSize = `${fontSize}px`;
        span.style.fontFamily = fontFamily;
        span.style.visibility = "hidden";
        span.style.position = "absolute";
        span.style.whiteSpace = "nowrap";
        span.textContent = xAxisStyle?.tick?.font?.text_transform?.toLowerCase() === "uppercase" ? samepleText.toUpperCase() : samepleText;
        // span.textContent = local_x_data_keys.length === 1 ? "Neuro Modu.." : local_x_data_keys.length === 2 ? "Room.. - Mar'21" : "Room.. - Mar'21 Room.. ";  // Representative sample text
        document.body.appendChild(span);
        const width = isVertical ? span.offsetWidth : span.offsetHeight;
        document.body.removeChild(span);
        return width + TICK_PADDING * 2;
    }

    const x_axis_height_v2  = calculateTextHeightorWidth(xAxisTickStyle?.fontSize, 'Arial', chart_xaxis_layout?.angle > 0);

    const width_of_y_axis_v1 = (hide_y_axis && hide_y_axis_label) ? 0 : hide_y_axis_label ? (props?.expanded ? 80 : 60) : !hide_y_axis ? is_horizontal ? 100 : getRemToPx(4.5) : 20;

    const hoist_the_state = () => {
        const x_data_keys = props.xDataKeys;
        const chart_style = props.chartStyle;
        const x_axis_style = chart_style && chart_style.axis && chart_style.axis.x;

        if (x_data_keys && x_data_keys.length > 1) {
            const axis_tick_styles = {};
            const pre_axis_settings = Object.assign({}, axis_settings);
            const color_preferences = {};

            // if we are having two axis, 
            const data_rows = JSON.parse(JSON.stringify(props.data));
            const uniques = {};

            let color_to_use = 'alternate';

            data_rows.forEach((row, index) => {
                if (!uniques[row[x_data_keys[0]]]) {
                    uniques[row[x_data_keys[0]]] = 1;

                    // new item found for row zero

                    if (color_to_use === 'alternate') color_to_use = 'main'
                    else color_to_use = 'alternate';

                    color_preferences[row[x_data_keys[0]]] = color_to_use;
                }

                uniques[row[x_data_keys[0]]]++;


                if (x_axis_style) {
                    const x_axis_tick_style = apply_styles((x_axis_style && x_axis_style['tick']), constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT)

                    const tick_color_main = x_axis_style && x_axis_style.tick && x_axis_style.tick.font && x_axis_style.tick.font.color;
                    const tick_color_alternate = x_axis_style && x_axis_style.tick && x_axis_style.tick.font && x_axis_style.tick.font.alternate;

                    const effective_color = (color_to_use === 'main' ? tick_color_main : tick_color_alternate) || '#000000'
                    const effective_transparency = (color_to_use === 'main' ? 0 : 65);
                    const effective_bold = color_to_use === 'main' ? 400 : 600;

                    if (x_axis_tick_style) {
                        axis_tick_styles[index] = { ...x_axis_tick_style };
                    }

                    if (!axis_tick_styles[index]) {
                        axis_tick_styles[index] = {};
                    }

                    axis_tick_styles[index]['fill'] = effective_color;
                    axis_tick_styles[index]['transparency'] = effective_transparency;
                    axis_tick_styles[index]['fontWeight'] = effective_bold;
                }

            });


            pre_axis_settings.color_preferences = color_preferences;
            pre_axis_settings.uniques = uniques;
            pre_axis_settings.axis_tick_styles = axis_tick_styles;


            return axis_settings;
        } else {
            const blank_setting = {
                color_preferences: {},
                uniques: {},
                should_custom_color_be_used: false,
                axis_tick_styles: {},
                x_axises: []
            }
            return blank_setting
        }

    };


    const hztl_required_data = {
        y_data_keys: [],
        wtr_fall: undefined
    }


    const genrate_req_data_for_hzl_chart = () => {

        const clone_data = props.data;
        let curr_yDataKeys;
        let prev_yDatakeys;

        let prev_x_values;

        clone_data && clone_data.map((item, index) => {
            local_y_data_keys && Object.keys(local_y_data_keys).map((it, inde) => {
                prev_x_values = props.pivot_data?.[index - 1]?.[xDataKeys?.[inde]]
                if (xDataKeys.length > 1) {
                    if (prev_x_values === item[xDataKeys[inde]]) {
                        curr_yDataKeys = item[yDataKeys[0]];
                        prev_yDatakeys = props.data?.[index - 1]?.[yDataKeys[inde]]
                        hztl_required_data['wtr_fall'] = (prev_yDatakeys ? prev_yDatakeys : 0) - curr_yDataKeys;

                        item[`${yDataKeys[inde]}_nfx_1`] = hztl_required_data['wtr_fall']
                        hztl_required_data['y_data_keys'].push(`${yDataKeys[inde]}_nfx_1`)
                    } else {
                        curr_yDataKeys = item[yDataKeys[inde]];
                        prev_yDatakeys = 0
                        hztl_required_data['wtr_fall'] = (prev_yDatakeys ? prev_yDatakeys : 0) - curr_yDataKeys;
                        item[`${yDataKeys[inde]}_nfx_1`] = hztl_required_data['wtr_fall']
                        hztl_required_data['y_data_keys'].push(`${yDataKeys[inde]}_nfx_1`)
                    }

                } else {
                    curr_yDataKeys = item[yDataKeys[inde]];
                    prev_yDatakeys = props.data?.[index - 1]?.[yDataKeys[inde]]
                    hztl_required_data['wtr_fall'] = (prev_yDatakeys ? prev_yDatakeys : 0) - curr_yDataKeys;
                    item[`${yDataKeys[inde]}_nfx_1`] = hztl_required_data['wtr_fall']
                    hztl_required_data['y_data_keys'].push(`${yDataKeys[inde]}_nfx_1`)
                }
            })
        })
    }

    genrate_req_data_for_hzl_chart()


    const is_this_cell_clicked_v1 = (filter_keys, entry, cell_data, pivot_data_columns) => {

        const { link_filter } = props;

        const link_filter_for_comp = link_filter?.filter;

        if (filter_keys && filter_keys.length > 0 && link_filter_for_comp && Object.keys(link_filter_for_comp).length > 0) {

            const values = {};

            filter_keys.reverse().forEach((k) => {

                const db_column = props.columnMeta?.[k]?.db_column;
                const db_data_type = props.columnMeta?.[k]?.db_data_type || 5;
                const use_key = db_column + "__nfx__db__" + db_data_type;
                values[use_key] = entry[k];
            })

            // Rent_nfx_male_nfx_Revenue
            if (pivot_data_columns?.length > 0 && cell_data?.length > 0 && cell_data?.split("_nfx_")?.length > 0) {
                const cell_values = cell_data?.split("_nfx_");
                pivot_data_columns.map((k, index) => {

                    const value = cell_values[index];
                    const db_column = props.columnMeta?.[k]?.db_column;
                    const db_data_type = props.columnMeta?.[k]?.db_data_type || 5;
                    const use_key = db_column + "__nfx__db__" + db_data_type;

                    if (db_column && k) {
                        values[use_key] = value;
                    }
                })

            }

            if (values && Object.keys(values).length > 0) {
                const result = Object.keys(values).every((k) => {
                    if (link_filter_for_comp?.[k]?.indexOf(values[k]?.trim()) > -1) return true;
                    else return false;
                })
                return result;
            } else return false;

        }
    }

    const open_chart_options = (event, data_key, payload, pivot_data_columns) => {

        if (props.is_require_cache) {
            return;
        }
        const options_required_data = get_required_attributes_for_chart_click_option(event, data_key, payload, xDataKeys, props.columnMeta, undefined, undefined, undefined, pivot_data_columns)

        const { left, top, width, popup_bg } = options_required_data;

        showPopup(undefined, undefined, enums.popupType.element, ChartFilterPopup, {
            ...options_required_data,
            question: props.question,
            drill_down_values: props.drill_down_values,
            column_meta_details: props.columnMeta,
            b_history: props.history,
            _id: props.id,
            insight_id: props.insight_id,
            insights: props.insights,
            containerWidth: width,
            od_report_id: od_report_id,
            od_report: od_report,
            reporting_db_info_id: props.reporting_db_info_id,
            hide_drill_down: !props.is_drill_down_applicable
        },
            top + "px",
            left + "px",
            popup_bg
        )

    }

    const render_custom_tick = (props) => {
        const { x, y, payload, fontSize, angle, textAnchor } = props;

        const { value } = payload;
        const words = value?.split("__nfx__join__");;
        const isSingleValued = words?.length < 2;

        const maxLen = chart_xaxis_layout?.maxLen;

        const isAngled = chart_xaxis_layout?.angle > 0 ;
        let _max_value = !isSingleValued && isAngled ? 4 : !isSingleValued && !isAngled ? maxLen-9 :  isSingleValued && isAngled ? 10 : maxLen;
        const h = isSingleValued ? 8 : 4;

        return (
            <g transform={`translate(${x},${y})`}>
                <title>{words?.join(" - ")}</title>
                <text
                    surfaceScale={false}
                    scale={false}
                    {...props}
                    x={0}
                    y={0}
                    visibleTicksCount={undefined}
                    // dy={verticalAlign[verticalAnchor]} 
                    textAnchor={textAnchor}
                    dominantBaseline={'hanging'}
                    // verticalAnchor={verticalAnchor}
                    fill="black"
                    // fontSize={(props?.fontSize || font_size) + "px"}
                    transform={`rotate(${angle})`}
                    style={{...xAxisTickStyle, textTransform:  xAxisStyle?.tick?.font?.text_transform}}
                >
                    {(is_horizontal ? words.reverse() : words)?.map((valueToFormat, ind) => {
                        const word = checkYearAndMakeFy(valueToFormat) || formatMonthYearForChart(valueToFormat);

                        const pattern = /\b(FY|Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)'\d{2}\b/;
                        const isAMatch = pattern.test(word);

                        const truncatedValue = is_horizontal && words?.length ? (word?.length > h ? `${word?.substring(0, h)}` : word) : ((props.angle || maxLen) > 0 && word?.length > _max_value) ? `${word?.substring(0, _max_value)}` : word;
                        // totalWordLength = totalWordLength - truncatedValue?.length; 

                        return <>
                            <tspan fill={ind % 2 === 0 ? xAxisStyle?.tick?.font?.color_primary  : xAxisStyle?.tick?.font?.color_secondary }>
                                {/* {isAMatch ? word : truncatedValue?.trim()} */}
                                {isAMatch ? word : truncatedValue?.trim() + (truncatedValue?.length < word?.length ? ".." : '')}
                            </tspan>
                            {words[ind + 1] && <tspan fill="black">{` - `}</tspan>}
                        </>
                    })}
                </text>
            </g>
        );
    };

    const RenderDataLabel = (__props__) => {

        const { entry, data_key, fontSize, chart_type } = __props__;

        const { x, y, width, value, index, height = 0 } = entry;

        const is_horizontal = (chartType === 'horizontal_bar_chart' || chartType === 'horizontal_waterfall_chart' || chartType === "horizontal_stack_bar_chart" || chartType === "horizontal_stack_bar_chart_100")
        const radius = is_horizontal ? 20 : (props?.yDataKeys?.length > 1 && chartType === CHART_TYPES.stacked_bar_chart) ? (height / 2) : -10;

        const column_formating_meta = props.Alignments && props.Alignments[data_key || props.yDataKeys && props.yDataKeys[0]];

        const currency_type = column_formating_meta?.['currency_type'];
        const num_format_type = "million";
        const use_decimal = 2;
        const d_type = column_formating_meta?.['type'];
        const use_percent = column_formating_meta?.['use_percent'];

        let show_value = formatValueByDataType(value, d_type, undefined, undefined, undefined, "insights", currency_type, num_format_type, use_decimal, use_percent);
        let x_to_use = ((is_horizontal ? (x + width + 50) : x + (width ? width / 2 : 0)) + ((index < 1 && chart_type !== 'bar_chart') ? 30 : 0)) - ((index === (chartData?.length - 1) && chart_type !== 'bar_chart') ? 30 : 0);

        return (
            <g>
                <text
                    x={x_to_use}
                    y={(y + radius)}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontSize={props.expanded ? "16px" : fontSize || (chartStyle?.['data_label']?.['font']?.["size"] || "14px")}
                    fill={chartStyle?.['data_label']?.['font']?.["color"] || "#000"}
                    fontWeight={index === activeIndex ? "bold" : "normal"}
                >
                    {show_value}
                </text>
            </g>
        );
    }


    return (
        <div>

            {chartData && chartData.length > 0 && (

                <ResponsiveContainer width='100%' height={isDimension ? heightOfChart : heightOfChart}>
                    <ComposedChart
                        key={show_data_label ? 'key_chart' : 'key_chart_1'} //for line chart data labels
                        data={chartData}
                        margin={{ top: show_data_label ? 30 : 10, left: show_data_label ? 30 : 10, right: show_data_label ? 30 : 20, bottom: 10 }}
                        layout={is_horizontal ? 'vertical' : undefined}
                    >

                        {!hide_grid && !isDimension && (
                            <CartesianGrid
                                vertical={false}
                                horizontalY={false}
                                stroke="#333"
                                strokeWidth={.2}
                            />
                        )}

                        {!is_horizontal && (
                            <>
                                <XAxis
                                    {...chart_xaxis_layout}
                                    // xAxisId={"bottom"}
                                    // first_axis={true}
                                    // xAxisId={local_x_data_keys && local_x_data_keys.length > 0 && local_x_data_keys[local_x_data_keys.length - 1]}
                                    dataKey={local_x_data_keys && local_x_data_keys.length > 0 && local_x_data_keys[local_x_data_keys.length - 1]}
                                    height={hide_x_axis_secondry ? 1 : x_axis_height_v2}
                                    tick={!hide_x_axis && render_custom_tick}
                                    // tick={hide_x_axis_secondry ? false : !hide_x_axis && render_custom_tick}
                                    // axisLine={!hide_x_axis_border ? true : false}
                                    axisLine={{
                                        stroke: !hide_x_axis_border ? '#222' : 'none', // axis line color
                                        strokeWidth: !hide_x_axis_border ? .3 : 0,      // axis line width (adjust number as needed)
                                    }}
                                    tickLine={{
                                        stroke: '#888',      // Tick line color
                                        strokeWidth: 0,    // Tick line width
                                    }}
                                    // padding={{ left: 30, right: 30 }} 
                                    style={xAxisTickStyle}
                                    interval={chart_xaxis_layout?.interval}
                                    dy={chart_xaxis_layout?.angle > 0 ? -6 : 0}
                                >

                                    {!hide_x_axis_label && local_x_data_keys?.length &&
                                        <Label className="y_axis_label" angle='0' position='insideBottom' style={xAxisTitleStyle}
                                        >
                                            {local_x_data_keys && local_x_data_keys.length > 0 && splitByNf(local_x_data_keys.join(' > '))}
                                        </Label>
                                    }
                                </XAxis>

                            </>
                        )}

                        <>
                            <YAxis
                                yAxisId='left'
                                orientation='left'
                                interval={0}
                                style={{...yAxisTickStyle, fill:yAxisStyle?.tick?.font?.color_primary}}
                                // tick={!hide_y_axis && !isDimension && render_custom_tick}
                                tickFormatter={(r) => {
                                    var key = local_y_data_keys?.[0];
                                    const column_formating_meta = Alignments?.[key];
                                    if (key === "Ratio" && r < .99999) {
                                        return r?.toFixed(2)
                                    } else {
                                        const value = DataFormaterFixed(r, key, column_formating_meta);
                                        return value
                                    }
                                }}
                                tick={!hide_y_axis && !isDimension ? true : false}
                                width={width_of_y_axis_v1 + 10}
                                // axisLine={!hide_y_axis_border ? true : false}
                                axisLine={{
                                    stroke: !hide_y_axis_border ? '#222' : 'none', // axis line color
                                    strokeWidth: !hide_y_axis_border ? .3 : 0,      // axis line width (adjust number as needed)
                                }}
                                tickLine={{
                                    stroke: '#888',      // Tick line color
                                    strokeWidth: 0,    // Tick line width
                                }}

                            >
                                {!hide_y_axis_label &&
                                    <Label
                                        angle='-90'
                                        position='insideLeft'
                                        style={yAxisTitleStyle}
                                    >
                                        {/* {primaries_header} */}
                                        {splitByNf(yDataValueFormatter(primaries_header))}
                                        {/* {chartType === CHART_TYPES.stacked_bar_chart ? secondaries_header : ''} */}
                                    </Label>
                                }
                            </YAxis>

                            <YAxis
                                yAxisId="right"
                                orientation="right"
                                style={{...yAxisTickStyle, fill:yAxisStyle?.tick?.font?.color_secondary}}
                                domain={[0, 100]}
                                ticks={[0, 20, 40, 60, 80, 100]}
                                tickFormatter={(value) => `${value}%`}
                                tick={!hide_y_axis && !isDimension ? true : false}
                                
                            />
                        </>


                        {!hide_tooltip && (
                            <Tooltip
                                cursor={true}
                                // cursor={{
                                //     fill: "#f2f2f2",
                                //     opacity: 0.6
                                //   }}
                                content={function (active) {

                                    // {
                                    let nfx_found = false;
                                    local_y_data_keys && local_y_data_keys.length > 0 && local_y_data_keys.map((key) => {
                                        if (typeof key === 'string' && key.indexOf("_nfx_") > -1) nfx_found = true;
                                    })


                                    let _data = {};
                                    const _pre_payload = active && active.payload;
                                    const filterd_payload = _pre_payload?.filter((t) => {
                                        if (t && t.name.indexOf("nfx_") > -1 && t.name.indexOf("_trends") > -1) return false;
                                        else return true;
                                    })


                                    const __payload__ = filterd_payload
                                    const payload_wrapper = remove_repetitive_keys(__payload__);
                                    const _payload = payload_wrapper.final_payload;
                                    const final_keys = payload_wrapper.final_data_keys;

                                    // const _payload = __payload__;
                                    // MA CODE
                                    if (chartType === CHART_TYPES.area_chart_100 || chartType === CHART_TYPES.stacked_bar_chart_100 || chartType === CHART_TYPES.horizontal_stack_bar_chart_100) {
                                        _payload && _payload?.map((item, index) => {
                                            if (_payload[index]?.["dataKey"].indexOf("_nfstackshare") > 1) {
                                                item["dataKey"] = item?.dataKey.split("_nfstackshare")[0]
                                                item["name"] = item?.name.split("_nfstackshare")[0]
                                            }
                                        })
                                    }

                                    const _inner_payload = _payload && _payload[0] && _payload[0].payload;
                                    const payload_data = _inner_payload && _inner_payload;

                                    let array_of_keys = [...local_x_data_keys, ...local_y_data_keys];

                                    payload_data && local_x_data_keys && local_x_data_keys.forEach(x => {
                                        const concatinatedAxisValue = payload_data[x]?.split('__nfx__join__');
                                        const noofValCon = concatinatedAxisValue.length;
                                        if (noofValCon > 1) {
                                            _data[x] = concatinatedAxisValue[noofValCon - 1];
                                        } else {
                                            _data[x] = payload_data[x];
                                        }
                                    })

                                    if (nfx_found) {
                                        array_of_keys && array_of_keys.length > 0 && array_of_keys.filter(k => active_bar && k === active_bar).map((key) => {
                                            if (payload_data && Object.keys(payload_data).length > 0 && Object.keys(payload_data).indexOf(key) > -1) {

                                                const column_formating_meta = Alignments?.[key];

                                                const d_type = column_formating_meta?.['type'];
                                                const currency_type = column_formating_meta?.['currency_type'];
                                                const num_format_type = column_formating_meta?.['num_format_type'];
                                                const use_decimal = column_formating_meta?.['use_decimal'];
                                                const use_percent = column_formating_meta?.['use_percent'];

                                                const key_to_assign_in_case_of_nfx = (final_keys && final_keys[key]) || key;

                                                _data[key_to_assign_in_case_of_nfx] = formatValueByDataType(payload_data[key], d_type, undefined, undefined, key, undefined, currency_type, num_format_type, use_decimal, use_percent);
                                            }
                                        });

                                        if (typeof active_bar === 'undefined') {

                                            let d_type = undefined;
                                            let currency_type = undefined;
                                            let num_format_type = undefined
                                            let use_decimal = undefined;
                                            let use_percent = undefined;

                                            let amount = 0
                                            let _index = undefined;

                                            // console.log("payload_data", array_of_keys)
                                            array_of_keys && array_of_keys.length > 0 && array_of_keys.map((key, index) => {
                                                if (payload_data && Object.keys(payload_data).length > 0 && Object.keys(payload_data).indexOf(key) > -1) {

                                                    _index = index;
                                                    d_type = Alignments?.[key]?.["type"]
                                                    currency_type = Alignments?.[key]?.['currency_type'];
                                                    num_format_type = Alignments?.[key]?.['num_format_type'];
                                                    use_decimal = Alignments?.[key]?.['use_decimal'];
                                                    use_percent = Alignments?.[key]?.['use_percent'];


                                                    if (typeof payload_data[key] === 'number') {
                                                        amount = amount + parseFloat(payload_data[key]);
                                                    }
                                                }
                                            });
                                            _data["total"] = formatValueByDataType(amount, d_type, undefined, undefined, active.label, undefined, currency_type, num_format_type, use_decimal, use_percent)
                                        }
                                    } else {

                                        __payload__ && __payload__.length > 0 && __payload__.forEach((p) => {

                                            const __key__ = p.dataKey;
                                            const total_label = __key__;

                                            if (__key__.includes("_nfx_1")) return //MA CODE

                                            let d_type = Alignments?.[__key__]?.["type"]
                                            let currency_type = Alignments?.[__key__]?.['currency_type'];
                                            let num_format_type = Alignments?.[__key__]?.['num_format_type'];
                                            let use_decimal = Alignments?.[__key__]?.['use_decimal'];
                                            let use_percent = Alignments?.[__key__]?.['use_percent'];

                                            if (!_data[total_label]) _data[total_label] = 0;
                                            const __value__ = _data[total_label] + parseFloat(p.value);

                                            // MA CODE
                                            if (chartType === CHART_TYPES.area_chart_100 || chartType === CHART_TYPES.stacked_bar_chart_100) {
                                                if (d_type === DataTypes.number || d_type === DataTypes.currency) d_type = DataTypes.percent;
                                            }
                                            const formatted_value = formatValueByDataType(__value__, d_type, undefined, undefined, __key__, undefined, currency_type, num_format_type, use_decimal, use_percent)
                                            _data[total_label] = formatted_value;
                                        })
                                    }


                                    const isLineChart = [CHART_TYPES.line_bar_chart, CHART_TYPES.line_chart, CHART_TYPES.dash_line_chart, CHART_TYPES.ogive_chart, CHART_TYPES.line_bar_chart, CHART_TYPES.predictive_chart,]?.includes(chartType);
                                    const checkKey = Object.keys(_data)?.some(item1 => local_y_data_keys?.map(i => i?.split('_nfx_')?.[0])?.includes(item1))

                                    if (!_data || (_data && Object.keys(_data)?.length === 0) || (isLineChart && !checkKey)) return null
                                    return <CustomToolTip payload={_payload} data={_data} theme={tooltipTheme} xDataKeys={local_x_data_keys} />

                                }}
                            />
                        )}

                        {
                            colorForYData && local_y_data_keys && local_y_data_keys.length > 0 && local_y_data_keys.map((data, index) => {

                                // if(inactiveGraphs?.[data]) return <></>
                                const on_secondary = yac_axises[index] === 'secondary';
                                const all_keys_use_for_cell = [...xDataKeys];

                                all_keys_use_for_cell.push(data)


                                if (true) {

                                    return <Bar
                                        key={data}
                                        yAxisId={(chartType === CHART_TYPES.stacked_bar_chart) ? "left" : (on_secondary ? 'right' : 'left')}
                                        dataKey={data}
                                        stackId={(chartType === CHART_TYPES.stacked_bar_chart) && 'karma'}
                                        maxBarSize={100}
                                        fill={"#5498b1"}
                                        // fill={ colorForYData[index % 11]}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        {
                                            colorForYData && chartData && chartData.length > 0 && chartData.map((entry, _index) => {

                                                let opacity = (axis_settings.axis_tick_styles[_index] && axis_settings.axis_tick_styles[_index].transparency) || 100;
                                                const dimension = local_x_data_keys[local_x_data_keys.length - 1];
                                                let current_key = entry[dimension];
                                                const is_this_cell_clicked = is_this_cell_clicked_v1(local_x_data_keys, entry, data, pivot_data_columns);

                                                if (props.link_filter) {
                                                    if (is_this_cell_clicked) opacity = 100
                                                    else opacity = 10;
                                                }

                                                const conditional_data = find_cell_format(props.tbl_formatter, all_keys_use_for_cell, entry);
                                                const cell_color = conditional_data?.cell_color;

                                                const fillColorSmallBar = (yac_totals?.[data]?.["agg_min"] === entry[data]) && isHighlightedMin ? "#b83535" : undefined;
                                                return (
                                                    <Cell
                                                        style={{ cursor: 'pointer' }}
                                                        key={`cell-${_index}`}
                                                        fill={fillColorSmallBar ? fillColorSmallBar : is_this_cell_clicked ? "red" : _index === activeIndex ? barChartHoverColor?.[index % 5] : cell_color || "#5498b1"}
                                                        // fill={fillColorSmallBar ? fillColorSmallBar : is_this_cell_clicked ? "red" : _index === activeIndex ? barChartHoverColor?.[index % 5] : cell_color || colorForYData[index % 11] || "#BC07FF"}
                                                        opacity={`${opacity}%`}
                                                        onClick={(e) => {
                                                            set_active_bar({ [data]: _index })
                                                            open_chart_options(e, data, entry, pivot_data_columns)
                                                        }}

                                                        onMouseOver={(e) => {
                                                            set_active_bar(data);
                                                        }}
                                                        onMouseOut={(e) => {
                                                            set_active_bar(undefined);

                                                        }}
                                                    />
                                                )
                                            })
                                        }

                                        {(show_data_label || props?.column_meta_data?.[data]?.show_data_lable) && !pivot_columns_state?.[data]?.hide_data_label && <LabelList dataKey={data} position="outside" content={(entry) => {
                                            return <RenderDataLabel entry={entry} data_key={data} chart_type={"bar_chart"} />
                                        }} />}
                                    </Bar>
                                }


                            })
                        }

                        <Line yAxisId="right" dataKey="Cumulative Percentage" stroke={"#005fff"} strokeWidth={2} />

                        {/* <ReferenceLine
                            key={'key_1'}
                            yAxisId="right"
                            y={80}
                            stroke="red"
                            stockWidth="2"
                            // strokeDasharray="3 3" 
                            label=" 80 % "
                        /> */}
                        <ReferenceLine
                            y={80}
                            stroke={'#FF0000'}
                            label={<ReferenceLineLabel index={0} label={" 80 : 20 Rule "} />}
                            yAxisId="right"
                            strokeWidth={3}
                            xHeight={10}

                            style={{ font: '.1rem' }}

                        />

                        {/* ================ MIN, MAX, AVG === LINE============ */}
                        {local_yac_chart_types.indexOf(CHART_TYPES.stacked_bar_chart) == -1 && local_y_data_keys && local_y_data_keys.length > 0 && local_y_data_keys.map((k, index) => {

                            const on_secondary = yac_axises[index] === 'secondary';
                            return (
                                aggregate_options && Object.keys(aggregate_options).length > 0 && Object.keys(aggregate_options).map((a) => {
                                    if (aggregate_options[a]) {
                                        const label = (a === 'is_st_dev_checked') ? 'sd' : a?.split('_')?.[1];
                                        if (is_horizontal) {

                                            const formatted_value = get_formated_cell_data(Alignments, k, yac_totals?.[k]?.[`agg_${label}`])
                                            return (
                                                <ReferenceLine
                                                    x={yac_totals?.[k]?.[`agg_${label}`]}
                                                    stroke={'#FF0000'}
                                                    label={<ReferenceLineLabel index={index} label={k?.split("_nfx_")?.join(" ") + ' ' + label + "(" + formatted_value + ")"} vertical={is_horizontal} />}
                                                    xAxisId={on_secondary ? "top" : "bottom"}
                                                    stockWidth="2"
                                                    style={{ strokeDasharray: '4', font: '.1rem' }}
                                                />
                                            )
                                        }

                                        if (!is_horizontal) {

                                            const formatted_value = get_formated_cell_data(Alignments, k, yac_totals?.[k]?.[`agg_${label}`])
                                            const value = DataFormater(yac_totals?.[k]?.[`agg_${label}`], k)

                                            const __stroke = activeReferencecLine?.[k] ? 10 : 3;
                                            const __color = activeReferencecLine?.[k] ? '#000' : '#6180ec';

                                            return (

                                                <ReferenceLine
                                                    y={yac_totals?.[k]?.[`agg_${label}`]}
                                                    stroke={__color}
                                                    strokeWidth={__stroke}  // Adjust the width of the line
                                                    label={
                                                        <ReferenceLineLabel
                                                            index={index} g
                                                            _value={value}
                                                            label={k?.split("_nfx_")?.join(" ") + ' ' + label?.charAt(0)?.toUpperCase() + label?.slice(1) + "(" + formatted_value + ")"}
                                                        />
                                                    }
                                                    yAxisId={on_secondary ? "right" : "left"}
                                                    style={{ strokeDasharray: '0', font: '.1rem' }}
                                                    onMouseEnter={() => {
                                                        setActiveReferencecLine(k)
                                                        // console.log("ENTERDDDD")
                                                    }}
                                                    onMouseLeave={() => {
                                                        // console.log("Moused leaved")
                                                        // setActiveReferencecLine('')
                                                    }}
                                                />

                                            )
                                        }

                                    } else return null;
                                })
                            )
                        })}



                        {props.show_brush && (
                            <Brush dataKey={xDataKeys[0]} height={25} stroke="#005d86" />
                        )}

                        {/* ================== chart Legend ========= */}

                        {!hide_legend && chartData && <Legend
                            is_water_fall={chartType === CHART_TYPES.horizontal_waterfall_chart || chartType === CHART_TYPES.waterfall_chart}
                            chartType={chartType}
                            content={<RenderLegend payload={chartData} legendStyle={legendStyle} alignments={props.Alignments} />}
                            verticalAlign="top"
                            handleLegendClick={() => { }}
                            height={40}
                        />
                        }

                    </ComposedChart>
                </ResponsiveContainer>

            )}
        </div>
    );
}





const mapStateToProps = (state, props) => {

    const report_cell_clicked_info_cache = state.reportHelperReducer.report_cell_clicked_info_cache?.[props.insight_id];
    const report_cell_click_info = report_cell_clicked_info_cache?.[props.id];
    const link_filter = state.filterReducer.link_filter_cache?.[props.insight_id]?.[props.id];
    const table_format_setting_cache = state.tableFormatReducer.table_format_setting_cache;
    const tbl_formatter = table_format_setting_cache && table_format_setting_cache[props.id];

    return { report_cell_click_info, link_filter, tbl_formatter }
}

export default withRouter(connect(mapStateToProps, {})(ParetoChart));