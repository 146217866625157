import styled, { keyframes } from 'styled-components';
import { apply_styles } from '../../utils';
import { constants } from '../../utils/constants'


const ripple = keyframes`
    0% {
        transform: scale(0, 0);
        opacity: 1;
    }
    20% {
        transform: scale(5, 5);
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: scale(11, 11);
    }
`;



const shakeAnimation = keyframes`
  0% {transform: none; }
  15% { transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);}
  30% {transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);}
  45% { transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);}
  60% {transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);}
  75% {transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  100% {transform: none;}

  `;


export const Wrapper = styled.div`    
    width: 3.7rem;
    padding: 10px 0px;
    padding-top: 15px;
    background-color: #fff;
    right: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    /* border-left: 1px solid #ecebed;   */
    /* box-shadow: rgb(0 0 0 / 10%) 0px 3px 8px; */
    box-shadow: ${(props) => props.isPolusAIWindow ? "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px" : "rgb(0 0 0 / 10%) 0px 3px 8px" };
    z-index: 3;
    height: calc(100%);
    position: relative;

    .shake_animation{
        animation: ${shakeAnimation} .8s 1;
    }
    `;





export const MenuColumn = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        border-radius: ${props => props._style && props._style.border && props._style.border.radius};
        ${(props) => apply_styles(props._style, constants.STYLED_COMPONENTS.ALL, undefined, props.active)}
        background: ${props => props.active ? props._style && props._style.colors && props._style.colors.active_background : undefined};
        svg{
            ${(props) => apply_styles(props._style.icon, constants.STYLED_COMPONENTS.ALL, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG)}
        }
        &:hover {
            background-color: #e8eefb;
            .tooltiptext {
                visibility: visible;
            }
    }

    .extra_massage{
        position: absolute;
        width: ${props => props.message ? "170px" : "0px"};
        overflow: hidden;
        transition: all 0.6s;
        font-size: 13px;
        background: #63b6d661;
        padding: 10px;
        opacity: ${props => props.message ? 1 : 0};
        text-align: center;
        white-space: nowrap;
        right: 3.2rem;
        border-radius: 20px 0px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 1px 2px 5px 0px #00000040;
        
    }

     .tooltiptext {
        visibility: hidden;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 10px;
        position: absolute;
        z-index: 1;
        top: 8px;
        right: 119%;
        font-size: 12px;
        white-space: nowrap;
        
    }

    .tooltiptext::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent transparent black;
    }

    &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 5px;
            height: 5px;
            background: #63b6d652;
            opacity: 0;
            border-radius: 50%;
            transform: scale(1, 1) translate(-50%, -50%);
            transform-origin: 50% 50%;
        }
`;







