import * as actions from '../action-types/sample.demo';

const initialState = {
    type: actions.SAMPLE_DEMO_INIT,
    dbTableInfo: undefined,
    tableData: undefined,
};

const sampleDemoReducer = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        case actions.GET_DB_TABLE_INFO:
            return {
                type: action.type,
                dbTableInfo: action.data
            };
        case actions.CLEAR_DB_TABLE_INFO: 
            return {
                type: action.type,
                dbTableInfo: undefined
            }
        case actions.GET_DATA_BY_TABLE_NAME:
            return {
                type: action.type,
                tableData: action.data
            }
        default:
            return {
                ...state
            }
    }
};

export default sampleDemoReducer;