import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { _getDataFromReportingServer, getParameterByName } from '../../utils';
import ReportWrapper from '../report-wrapper';
import TitleHeader from '../title-header'
import { getReport } from '../../actions/report';



const Index = (props) => {

    const { history, multiDisplayData } = props;
    const { location } = history;

    const [wrapperHeight, setWrapperHeight] = useState(undefined);
    const [wrapperWidth, setWrapperWidth] = useState(undefined);


    const wrapperRef = useRef(null);


    useEffect(() => {
        if (wrapperRef.current) {
            let _height = wrapperRef.current.offsetHeight;
            let _width = wrapperRef.current.offsetWidth;
            setWrapperHeight(_height);
            setWrapperWidth(_width);
        }
    }, []);



    useEffect(() => {
        let question = getParameterByName("question", location.search);
        if(question){
            props.getReport(question)
        }
    }, [])


    return (
        <div
            style={{ width: '95vw',margin: 'auto', boxSizing: 'border-box', display: 'flex', height: 'calc(100vh - 240px)', justifyContent: 'space-between', flexWrap: 'wrap', flexDirection: 'row', marginTop: '2rem' }}
            ref={wrapperRef}>
            {multiDisplayData && multiDisplayData.data && multiDisplayData.data.length > 0 && multiDisplayData.data.map((report, i) => {
                return (
                    <div
                        style={{
                            boxShadow: '0px 2px 6px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                            boxSizing: 'border-box',
                            background: '#fff',
                            marginBottom: '2%'
                        }}>
                        <TitleHeader  title={report.heading ? report.heading : 'MULTI DISPLAY'} style={{ paddingLeft: '1rem', height: '3rem'}} titleBg="#fff" />
                        <div style={{ paddingLeft: '1rem', fontSize: '.9rem', marginBottom: '1rem' }}><strong>{report.sub_heading ? report.sub_heading : null}</strong> {report.sub_heading_data ? report.sub_heading_data : null}</div>
                        <ReportWrapper
                            width={.49 * wrapperWidth}
                            height={.40 * wrapperHeight}
                            isDiscovery={true}
                            // isPredictive={true}
                            // forcedChartType={"line_chart"}
                            // forcedRenderMode={'chart'}
                            id={'multi_display' + i}
                            reportWrapperRender={false}
                            dataFromServer={report}
                            sort_data={false}
                            hideChartOption={true}
                        />
                    </div>
                )
            })}


        </div>
    )
}


const mapStateToProps = (state) => ({
    multiDisplayData: state.multiDisplayReducer.multiDisplayData
})

export default withRouter(connect(mapStateToProps, {getReport})(Index))
