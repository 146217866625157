import styled from 'styled-components';

import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils';
const selected = getSelectedTheme();
const { textbox } = themes[selected];





export const HintsWrapper = styled.div`

        position: fixed;
        top: 0;
        border: 0;
        margin: auto;
        left: 0;
        width: 50.429rem;
        border-radius: 6px;
        background: #fffefe;
        right: 0px;
        bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        padding: 1rem;
        box-sizing: border-box;
        border: 2px solid #ccc;
        max-height: 30rem;
        height: 28rem;

        .table_wrapper{
            overflow: auto;
            max-height: 20rem;
            width: 100%;
            padding-bottom: 8rem;

            &::-webkit-scrollbar {
                background-color:red;
                width:5px
            }

            &::-webkit-scrollbar-track {
                background-color:#b1cad5;
            }

            &::-webkit-scrollbar-thumb {
                background-color:#76919d;
                border-radius:0px;
                height: 5px;
            }
            &::-webkit-scrollbar:horizontal{
                background-color: green;
                height: 5px;
            }
        }


        h1{
            font-size: 1.286rem;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.714rem;
            letter-spacing: normal;
            text-align: left;
            color: #1a1a1a;
            margin: 0px;
            display: block;
            padding-bottom: 18px;
            position: relative;
            text-align: left;
            width: 100%;
            padding-left: 1.5rem;
        }

        table{
            border: 0px;
            width: 100%;
            border-collapse: collapse;

            thead{
                td{
                    color: #444;
                    font-weight: 500;
                    padding: 1rem;
                    border-bottom: 1px solid #222;
                }
            }

            td{
                padding: .5rem 1rem;
                /* border-bottom: 1px solid #ccc; */
                font-size: .9rem;
                color: #555;
            }
        }

        .flex_box{
            display: flex;
            width: 100%;
            justify-content: space-between;

        }

        .file_name_wrapper{
            width: 20%;
            .file_name{
                background-color: #e2e2e2;
                margin-bottom: .1rem;
                width: 100%;;
                cursor: pointer;
                padding: .3rem .5rem;
                box-sizing: border-box;
                border-radius: 4px;
                font-size: 1rem;
                /* text-transform: uppercase; */
                font-weight: bold;
            }
        }
        .active{
            background-color: #76919d !important;
            color: #fff;
        }


   
`;

