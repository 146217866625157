import React from 'react';

const Formula = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 96 96"
            space="preserve">
        
            <g>
            <path id="svg_1" d="m92,96.504l-88,0c-1.56,0 -2.98,-0.912 -3.632,-2.328c-0.652,-1.416 -0.42,-3.084 0.592,-4.271l36.9,-43.192l-36.784,-39.485c-1.084,-1.164 -1.376,-2.864 -0.744,-4.32c0.636,-1.468 2.076,-2.412 3.668,-2.412l76,0c2.208,0 4,1.792 4,4l0,12c0,2.212 -1.792,4 -4,4s-4,-1.788 -4,-4l0,-8l-62.808,0l32.948,35.376c1.388,1.488 1.436,3.772 0.116,5.323l-33.584,39.305l75.328,0l0,-20c0,-2.208 1.792,-4 4,-4s4,1.792 4,4l0,24c0,2.212 -1.792,4.004 -4,4.004l0,0z" />
            </g>
        </svg>

    )

}


export default Formula;