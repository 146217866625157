import React, { useState, useEffect } from 'react';
import TextBox from '../hoc/textBox';
import { Button } from '../hoc/button'
import styled from 'styled-components';
import { save_favourite } from '../../actions/favourite';
import { getMenuGroups } from '../../actions/menu.group'
import SelectOption from '../select-option/select.option.new';
import { connect } from 'react-redux';
import CloseSvg from '../svg/close';


const Wrapper = styled.div`
    position: fixed;
    top: 56px;
    z-index: 4;
    right: 57px;
    width: 25rem;
    background : #fff;
    padding: .5rem 1.2rem;
    box-sizing: border-box;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 1px 2px rgba(0, 0, 0, .23);

        h1{
            font-size: 1.2rem;
            margin: 0px;
            padding: 0px;
            font-weight: 500;
            margin: 1rem 0rem;
            margin-top: .5rem;
            color: #374a66;

        }

        .close{
            position: absolute;
            top: .9rem;
            right: 1rem;
            cursor: pointer;

            svg{
                fill: red;
            }
        }

        .remove_link{
            font-size: 1rem;
            text-decoration: underline;
            color: #1c86cf;
            /* font-weight: 600; */
            cursor: pointer;
        }

`;

const AddModal = (props) => {


    // default props 
    const { question, set_open_pinned_modal, open_pinned_modal, remove_from_pinned, menu_groups, getMenuGroups, pinned_item } = props;

    // component state start from here 
    const [category_name, set_category_name] = useState('')
    const [add_new_category, set_add_new_category] = useState(false)
    const [pinned_data, set_pinned_data] = useState({
        name: '',
        menu_group_id: ''
    })


    // here we are the setting the default 
    useEffect(() => {
        set_pinned_data(pinned_item)
    }, [pinned_item])

    const on_change = (key, value) => {
        set_pinned_data({
            ...pinned_data,
            [key]: value,
        });
    }
    const add_to_pinned_local_function = () => {
        const data_of_pinned = {
            ...pinned_data,
            query: question,
            report_id: props.reportId,
            is_active: 1,
            modified: true
        }
        props.save_favourite(data_of_pinned, category_name)
    }

    useEffect(() => {
        getMenuGroups()
    }, [])

    

    useEffect(() => {
        if (!menu_groups || (menu_groups && menu_groups.length === 0)) {
            set_add_new_category(true)
        } else set_add_new_category(undefined)
    }, [menu_groups])

    // remove_from_pinned


    console.log("menu_groups", menu_groups)
    return (
        <Wrapper className="add_to_favourite_form">
            <h1>Pin this Insight to Menu</h1>
            <div className="close" title="Close" onClick={() => {
                set_open_pinned_modal(false)
            }}>
                <CloseSvg size=".8rem" height=".8rem" />
            </div>

            <div style={{ width: '100%', }}>
                <div className={"global_input_label"}>Your Insight Name</div>
                <div className="global_input">
                    <input
                        type="text"
                        onChange={(e) => on_change("name", e.target.value)}
                        value={pinned_data && pinned_data.name}
                    />
                </div>
                {/* <TextBox label="Your Insight Name" /> */}
            </div>

            {/* {!add_new_category && ( */}
            <div style={{ width: '100%', marginTop: '1rem' }}>
                <div className={"global_input_label"}>Select Menu</div>
                <div className="global_input">

                    <SelectOption
                        width="100%"
                        label="Select Menu"
                        selections={menu_groups ? [{ name: 'None', value: '' }, ...menu_groups] : []}
                        activeValue={pinned_data && pinned_data.menu_group_id && pinned_data.menu_group_id}
                        displayKey={"name"}
                        valueKey={'id'}
                        onChangeFunc={(v) => {
                            on_change("menu_group_id", v)
                        }}
                        addAction={() => {
                            set_add_new_category(!add_new_category);
                        }}
                        addActionName={"Add New Menu"}
                        add_action_value={category_name}
                        show_add_input={add_new_category}
                        add_action_on_change={(v) => {
                            set_category_name(v)
                        }}
                    />
                </div>
            </div>
            {/* )} */}

            {/* {add_new_category && (
                <div style={{ width: '100%', margin: '10px 0px' }}>
                    <TextBox value={category_name} label="Add New Menu" onChange={(e) => set_category_name(e.target.value)} />
                </div>
            )} */}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '2rem' }}>

                {pinned_item && (
                    <div className="remove_link" onClick={() => {
                        remove_from_pinned(pinned_item)
                        set_open_pinned_modal(false)
                    }}>
                        Delete this Pin
                    </div>
                )}

                <Button

                    style={{ marginLeft: 'auto' }}
                    width="6rem"
                    primary={true}
                    onClick={() => {
                        add_to_pinned_local_function()
                        set_open_pinned_modal(false)
                    }}>Save</Button>
                {/* <Button width="6rem" bgColor="#9caeb5" >Cancel</Button> */}
            </div>
        </Wrapper>
    )
}


const mapStateToProps = (state) => ({
    // menu_groups: state.menuGroupReducer.menu_groups
    menu_groups: state.menuGroupReducer?.menu_groups_cache?.['pinned_item']

})

export default connect(mapStateToProps, { save_favourite, getMenuGroups })(AddModal);
