import React from 'react';

const MarkSvg = (props) => {

    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}

            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 500 340"
            space="preserve"
        >
            <defs>
                <symbol overflow="visible" id="s">
                    <path id="svg_1" d="m19.547,-1.1719c-1.0117,0.52344 -2.0625,0.91406 -3.1562,1.1719c-1.0938,0.26953 -2.2344,0.40625 -3.4219,0.40625c-3.543,0 -6.3516,-0.98828 -8.4219,-2.9688c-2.0625,-1.9766 -3.0938,-4.6602 -3.0938,-8.0469c0,-3.4062 1.0312,-6.0977 3.0938,-8.0781c2.0703,-1.9766 4.8789,-2.9688 8.4219,-2.9688c1.1875,0 2.3281,0.13672 3.4219,0.40625c1.0938,0.26172 2.1445,0.65625 3.1562,1.1875l0,4.3906c-1.0234,-0.6875 -2.0273,-1.1914 -3.0156,-1.5156c-0.98047,-0.32031 -2.0156,-0.48438 -3.1094,-0.48438c-1.9609,0 -3.5,0.625 -4.625,1.875c-1.1172,1.25 -1.6719,2.9805 -1.6719,5.1875c0,2.1875 0.55469,3.9141 1.6719,5.1719c1.125,1.25 2.6641,1.875 4.625,1.875c1.0938,0 2.1289,-0.16016 3.1094,-0.48438c0.98828,-0.32031 1.9922,-0.82812 3.0156,-1.5156l0,4.39066z" />
                </symbol>
                <symbol overflow="visible" id="c">
                    <path id="svg_2" d="m14.297,-11.609c-0.44922,-0.20703 -0.89062,-0.35938 -1.3281,-0.45312c-0.4375,-0.10156 -0.88281,-0.15625 -1.3281,-0.15625c-1.3125,0 -2.3242,0.42188 -3.0312,1.2656c-0.71094,0.83594 -1.0625,2.0391 -1.0625,3.6094l0,7.3438l-5.0938,0l0,-15.953l5.0938,0l0,2.625c0.65625,-1.0508 1.4062,-1.8164 2.25,-2.2969c0.85156,-0.47656 1.875,-0.71875 3.0625,-0.71875c0.17578,0 0.36328,0.01172 0.5625,0.03125c0.19531,0.01172 0.48438,0.04297 0.85938,0.09375l0.01552,4.60922z" />
                </symbol>
                <symbol overflow="visible" id="b">
                    <path id="svg_3" d="m18.375,-8.0156l0,1.4531l-11.922,0c0.125,1.1992 0.55469,2.0938 1.2969,2.6875c0.73828,0.59375 1.7734,0.89062 3.1094,0.89062c1.0703,0 2.1719,-0.15625 3.2969,-0.46875c1.125,-0.32031 2.2812,-0.80469 3.4688,-1.4531l0,3.9375c-1.2109,0.46094 -2.418,0.80469 -3.625,1.0312c-1.2109,0.22656 -2.4141,0.34375 -3.6094,0.34375c-2.8984,0 -5.1484,-0.72656 -6.75,-2.1875c-1.5938,-1.4688 -2.3906,-3.5312 -2.3906,-6.1875c0,-2.5938 0.78516,-4.6328 2.3594,-6.125c1.5703,-1.5 3.7344,-2.25 6.4844,-2.25c2.5078,0 4.5156,0.75781 6.0156,2.2656c1.5078,1.5117 2.2656,3.5312 2.2656,6.0625l0,0.00008zm-5.2344,-1.7031c0,-0.96875 -0.28906,-1.75 -0.85938,-2.3438c-0.5625,-0.59375 -1.2969,-0.89062 -2.2031,-0.89062c-0.99219,0 -1.7969,0.28125 -2.4219,0.84375c-0.61719,0.55469 -0.99609,1.3516 -1.1406,2.3906l6.62498,0.00007z" />
                </symbol>
                <symbol overflow="visible" id="e">
                    <path id="svg_4" d="m9.5938,-7.1719c-1.0625,0 -1.8672,0.18359 -2.4062,0.54688c-0.53125,0.35547 -0.79688,0.88281 -0.79688,1.5781c0,0.64844 0.21094,1.1523 0.64062,1.5156c0.4375,0.36719 1.0391,0.54688 1.8125,0.54688c0.95703,0 1.7656,-0.34375 2.4219,-1.0312s0.98438,-1.5469 0.98438,-2.5781l0,-0.57812l-2.65632,-0.00004zm7.7969,-1.9375l0,9.1094l-5.1406,0l0,-2.3594c-0.6875,0.96875 -1.4609,1.6719 -2.3125,2.1094c-0.85547,0.4375 -1.8906,0.65625 -3.1094,0.65625c-1.6562,0 -3,-0.47656 -4.0312,-1.4375c-1.0312,-0.96875 -1.5469,-2.2188 -1.5469,-3.75c0,-1.875 0.64062,-3.25 1.9219,-4.125c1.2891,-0.875 3.3164,-1.3125 6.0781,-1.3125l3,0l0,-0.39062c0,-0.8125 -0.32031,-1.4062 -0.95312,-1.7812c-0.63672,-0.375 -1.6328,-0.5625 -2.9844,-0.5625c-1.0859,0 -2.1016,0.10938 -3.0469,0.32812c-0.9375,0.21875 -1.8086,0.54688 -2.6094,0.98438l0,-3.8906c1.082,-0.25781 2.1758,-0.45703 3.2812,-0.59375c1.1016,-0.14453 2.207,-0.21875 3.3125,-0.21875c2.875,0 4.9453,0.57031 6.2188,1.7031c1.2812,1.1367 1.9219,2.9805 1.9219,5.5312l0.00002,-0.00003z" />
                </symbol>
                <symbol overflow="visible" id="a">
                    <path id="svg_5" d="m8.0156,-20.484l0,4.5312l5.2656,0l0,3.6406l-5.2656,0l0,6.7656c0,0.74219 0.14453,1.2461 0.4375,1.5156c0.30078,0.26172 0.89062,0.39062 1.7656,0.39062l2.6094,0l0,3.6406l-4.3594,0c-2.0234,0 -3.4531,-0.41406 -4.2969,-1.25c-0.83594,-0.84375 -1.25,-2.2734 -1.25,-4.2969l0,-6.7656l-2.5312,0l0,-3.6406l2.5312,0l0,-4.5312l5.0938,0.00008z" />
                </symbol>
                <symbol overflow="visible" id="l">
                    <path id="svg_6" d="m13.297,-13.625l0,-8.5312l5.1406,0l0,22.156l-5.1406,0l0,-2.3125c-0.69922,0.94922 -1.4688,1.6406 -2.3125,2.0781c-0.84375,0.42578 -1.8242,0.64062 -2.9375,0.64062c-1.9688,0 -3.5859,-0.77344 -4.8438,-2.3281c-1.2617,-1.5625 -1.8906,-3.5781 -1.8906,-6.0469c0,-2.457 0.62891,-4.4688 1.8906,-6.0312c1.2578,-1.5625 2.875,-2.3438 4.8438,-2.3438c1.1016,0 2.0781,0.22656 2.9219,0.67188c0.85156,0.4375 1.6289,1.1211 2.3281,2.0469l0,0.0002zm-3.3594,10.328c1.0938,0 1.9258,-0.39453 2.5,-1.1875c0.57031,-0.80078 0.85938,-1.9609 0.85938,-3.4844c0,-1.5078 -0.28906,-2.6641 -0.85938,-3.4688c-0.57422,-0.80078 -1.4062,-1.2031 -2.5,-1.2031c-1.0859,0 -1.9141,0.40234 -2.4844,1.2031c-0.57422,0.80469 -0.85938,1.9609 -0.85938,3.4688c0,1.5234 0.28516,2.6836 0.85938,3.4844c0.57031,0.79297 1.3984,1.1875 2.4844,1.1875z" />
                </symbol>
                <symbol overflow="visible" id="k">
                    <path id="svg_7" d="m10.938,-3.2969c1.0938,0 1.9258,-0.39453 2.5,-1.1875c0.57031,-0.80078 0.85938,-1.9609 0.85938,-3.4844c0,-1.5078 -0.28906,-2.6641 -0.85938,-3.4688c-0.57422,-0.80078 -1.4062,-1.2031 -2.5,-1.2031s-1.9336,0.40234 -2.5156,1.2031c-0.58594,0.80469 -0.875,1.9609 -0.875,3.4688c0,1.5117 0.28906,2.668 0.875,3.4688c0.58203,0.80469 1.4219,1.2031 2.5156,1.2031zm-3.3906,-10.328c0.70703,-0.92578 1.4883,-1.6094 2.3438,-2.0469c0.85156,-0.44531 1.832,-0.67188 2.9375,-0.67188c1.9688,0 3.582,0.78125 4.8438,2.3438c1.2695,1.5625 1.9062,3.5742 1.9062,6.0312c0,2.4688 -0.63672,4.4844 -1.9062,6.0469c-1.2617,1.5547 -2.875,2.3281 -4.8438,2.3281c-1.1055,0 -2.0859,-0.21875 -2.9375,-0.65625c-0.85547,-0.4375 -1.6367,-1.125 -2.3438,-2.0625l0,2.3125l-5.0938,0l0,-22.156l5.0938,0l0,8.53103z" />
                </symbol>
                <symbol overflow="visible" id="f">
                    <path id="svg_8" d="m0.35938,-15.953l5.0938,0l4.2969,10.828l3.6406,-10.828l5.0938,0l-6.7031,17.469c-0.67969,1.7695 -1.4648,3.0078 -2.3594,3.7188c-0.89844,0.70703 -2.0781,1.0625 -3.5469,1.0625l-2.9531,0l0,-3.3438l1.5938,0c0.86328,0 1.4922,-0.14062 1.8906,-0.42188c0.39453,-0.27344 0.70312,-0.76562 0.92188,-1.4844l0.14062,-0.4375l-7.1095,-16.56272z" />
                </symbol>
                <symbol overflow="visible" id="j">
                    <path id="svg_9" d="m7.5469,-2.3125l0,8.375l-5.0938,0l0,-22.016l5.0938,0l0,2.3281c0.70703,-0.92578 1.4883,-1.6094 2.3438,-2.0469c0.85156,-0.44531 1.832,-0.67188 2.9375,-0.67188c1.9688,0 3.582,0.78125 4.8438,2.3438c1.2695,1.5625 1.9062,3.5742 1.9062,6.0312c0,2.4688 -0.63672,4.4844 -1.9062,6.0469c-1.2617,1.5547 -2.875,2.3281 -4.8438,2.3281c-1.1055,0 -2.0859,-0.21875 -2.9375,-0.65625c-0.85547,-0.4375 -1.6367,-1.125 -2.3438,-2.0625l0,0.00043zm3.3906,-10.328c-1.0938,0 -1.9336,0.40234 -2.5156,1.2031c-0.58594,0.80469 -0.875,1.9609 -0.875,3.4688c0,1.5117 0.28906,2.668 0.875,3.4688c0.58203,0.80469 1.4219,1.2031 2.5156,1.2031s1.9258,-0.39453 2.5,-1.1875c0.57031,-0.80078 0.85938,-1.9609 0.85938,-3.4844c0,-1.5078 -0.28906,-2.6641 -0.85938,-3.4688c-0.57422,-0.80078 -1.4062,-1.2031 -2.5,-1.2031z" />
                </symbol>
                <symbol overflow="visible" id="d">
                    <path id="svg_10" d="m10.047,-12.688c-1.1367,0 -2,0.40625 -2.5938,1.2188s-0.89062,1.9805 -0.89062,3.5c0,1.5312 0.29688,2.7031 0.89062,3.5156s1.457,1.2188 2.5938,1.2188c1.1016,0 1.9453,-0.40625 2.5312,-1.2188c0.59375,-0.8125 0.89062,-1.9844 0.89062,-3.5156c0,-1.5195 -0.29688,-2.6875 -0.89062,-3.5c-0.58594,-0.8125 -1.4297,-1.2188 -2.5312,-1.2188zm0,-3.6562c2.7383,0 4.8789,0.74609 6.4219,2.2344c1.5391,1.4805 2.3125,3.5273 2.3125,6.1406c0,2.625 -0.77344,4.6797 -2.3125,6.1562c-1.543,1.4805 -3.6836,2.2188 -6.4219,2.2188c-2.7617,0 -4.918,-0.73828 -6.4688,-2.2188c-1.5547,-1.4766 -2.3281,-3.5312 -2.3281,-6.1562c0,-2.6133 0.77344,-4.6602 2.3281,-6.1406c1.5508,-1.4883 3.707,-2.2344 6.4688,-2.2344z" />
                </symbol>
                <symbol overflow="visible" id="i">
                    <path id="svg_11" d="m2.4531,-15.953l5.0938,0l0,15.672c0,2.1328 -0.51562,3.7656 -1.5469,4.8906c-1.0234,1.125 -2.5078,1.6875 -4.4531,1.6875l-2.5156,0l0,-3.3438l0.89062,0c0.96875,0 1.6289,-0.21875 1.9844,-0.65625c0.36328,-0.4375 0.54688,-1.2969 0.54688,-2.5781l-0.0001,-15.67195zm0,-6.2031l5.0938,0l0,4.1562l-5.0938,0l0,-4.1562z" />
                </symbol>
                <symbol overflow="visible" id="h">
                    <path id="svg_12" d="m15.344,-15.453l0,4.1562c-0.69922,-0.46875 -1.3984,-0.81641 -2.0938,-1.0469c-0.69922,-0.22656 -1.4219,-0.34375 -2.1719,-0.34375c-1.4297,0 -2.5391,0.41797 -3.3281,1.25c-0.79297,0.82422 -1.1875,1.9805 -1.1875,3.4688c0,1.5 0.39453,2.668 1.1875,3.5c0.78906,0.82422 1.8984,1.2344 3.3281,1.2344c0.80078,0 1.5547,-0.11719 2.2656,-0.35938c0.71875,-0.23828 1.3828,-0.58594 2,-1.0469l0,4.1719c-0.80469,0.29297 -1.6172,0.50781 -2.4375,0.65625c-0.8125,0.14453 -1.6367,0.21875 -2.4688,0.21875c-2.875,0 -5.125,-0.73438 -6.75,-2.2031c-1.625,-1.4766 -2.4375,-3.5352 -2.4375,-6.1719c0,-2.625 0.8125,-4.6758 2.4375,-6.1562c1.625,-1.4766 3.875,-2.2188 6.75,-2.2188c0.83203,0 1.6562,0.07813 2.4688,0.23438c0.8125,0.14844 1.625,0.36719 2.4375,0.65625l0.0001,0z" />
                </symbol>
                <symbol overflow="visible" id="g">
                    <path id="svg_13" d="m18.484,-9.7188l0,9.7188l-5.125,0l0,-7.4062c0,-1.3945 -0.03125,-2.3516 -0.09375,-2.875c-0.0625,-0.51953 -0.16797,-0.90625 -0.3125,-1.1562c-0.19922,-0.33203 -0.46875,-0.58594 -0.8125,-0.76562c-0.34375,-0.1875 -0.73438,-0.28125 -1.1719,-0.28125c-1.0625,0 -1.9023,0.41406 -2.5156,1.2344c-0.60547,0.8125 -0.90625,1.9492 -0.90625,3.4062l0,7.8438l-5.0938,0l0,-22.156l5.0938,0l0,8.5312c0.76953,-0.92578 1.5859,-1.6094 2.4531,-2.0469c0.86328,-0.44531 1.8164,-0.67188 2.8594,-0.67188c1.8438,0 3.2383,0.57031 4.1875,1.7031c0.95703,1.125 1.4375,2.7656 1.4375,4.9219l0,-0.00035z" />
                </symbol>
                <symbol overflow="visible" id="r">
                    <path id="svg_14" d="m12.953,-22.156l0,3.3438l-2.8281,0c-0.71875,0 -1.2266,0.13281 -1.5156,0.39062c-0.28125,0.26172 -0.42188,0.71484 -0.42188,1.3594l0,1.1094l4.3594,0l0,3.6406l-4.3594,0l0,12.312l-5.0938,0l0,-12.312l-2.5312,0l0,-3.6406l2.5312,0l0,-1.1094c0,-1.7383 0.48438,-3.0195 1.4531,-3.8438c0.96875,-0.83203 2.4688,-1.25 4.5,-1.25l3.90628,-0.00002z" />
                </symbol>
                <symbol overflow="visible" id="q">
                    <path id="svg_15" d="m17.234,-13.297c0.64453,-0.98828 1.4102,-1.7422 2.2969,-2.2656c0.89453,-0.51953 1.875,-0.78125 2.9375,-0.78125c1.832,0 3.2266,0.57031 4.1875,1.7031c0.95703,1.125 1.4375,2.7656 1.4375,4.9219l0,9.7188l-5.125,0l0,-8.3125l0,-0.39062c0.00781,-0.13281 0.01563,-0.32031 0.01563,-0.5625c0,-1.1328 -0.16797,-1.9531 -0.5,-2.4531c-0.32422,-0.50781 -0.85938,-0.76562 -1.6094,-0.76562c-0.96875,0 -1.7188,0.40234 -2.25,1.2031c-0.52344,0.79297 -0.79297,1.9453 -0.8125,3.4531l0,7.8281l-5.125,0l0,-8.3125c0,-1.7695 -0.15234,-2.9062 -0.45312,-3.4062c-0.30469,-0.50781 -0.84375,-0.76562 -1.625,-0.76562c-0.98047,0 -1.7344,0.40234 -2.2656,1.2031c-0.53125,0.80469 -0.79688,1.9492 -0.79688,3.4375l0,7.8438l-5.125,0l0,-15.953l5.125,0l0,2.3281c0.625,-0.89453 1.3438,-1.5703 2.1562,-2.0312c0.8125,-0.45703 1.707,-0.6875 2.6875,-0.6875c1.1016,0 2.0781,0.26562 2.9219,0.79688s1.4844,1.2812 1.9219,2.25l-0.00003,-0.00027z" />
                </symbol>
                <symbol overflow="visible" id="p">
                    <path id="svg_16" d="m2.6719,-21.266l6.125,0l7.7344,14.578l0,-14.578l5.2031,0l0,21.266l-6.125,0l-7.7344,-14.594l0,14.594l-5.2031,0l0,-21.266z" />
                </symbol>
                <symbol overflow="visible" id="o">
                    <path id="svg_17" d="m2.2812,-6.2031l0,-9.75l5.125,0l0,1.5938c0,0.86719 -0.00781,1.9531 -0.01562,3.2656c-0.01172,1.3047 -0.01563,2.1719 -0.01563,2.6094c0,1.2812 0.03125,2.2031 0.09375,2.7656c0.07031,0.5625 0.1875,0.97656 0.34375,1.2344c0.21875,0.33594 0.49219,0.58984 0.82812,0.76562c0.33203,0.17969 0.71875,0.26562 1.1562,0.26562c1.0625,0 1.8945,-0.40625 2.5,-1.2188c0.61328,-0.8125 0.92188,-1.9453 0.92188,-3.4062l0,-7.875l5.0938,0l0,15.953l-5.0938,0l0,-2.3125c-0.77344,0.9375 -1.5898,1.625 -2.4531,2.0625c-0.85547,0.4375 -1.7969,0.65625 -2.8281,0.65625c-1.8438,0 -3.25,-0.5625 -4.2188,-1.6875c-0.96094,-1.1328 -1.4375,-2.7734 -1.4375,-4.9219l0.00005,0.00011z" />
                </symbol>
                <symbol overflow="visible" id="n">
                    <path id="svg_18" d="m18.484,-9.7188l0,9.7188l-5.125,0l0,-7.4375c0,-1.375 -0.03125,-2.3203 -0.09375,-2.8438c-0.0625,-0.51953 -0.16797,-0.90625 -0.3125,-1.1562c-0.19922,-0.33203 -0.46875,-0.58594 -0.8125,-0.76562c-0.34375,-0.1875 -0.73438,-0.28125 -1.1719,-0.28125c-1.0625,0 -1.9023,0.41406 -2.5156,1.2344c-0.60547,0.8125 -0.90625,1.9492 -0.90625,3.4062l0,7.8438l-5.0938,0l0,-15.953l5.0938,0l0,2.3281c0.76953,-0.92578 1.5859,-1.6094 2.4531,-2.0469c0.86328,-0.44531 1.8164,-0.67188 2.8594,-0.67188c1.8438,0 3.2383,0.57031 4.1875,1.7031c0.95703,1.125 1.4375,2.7656 1.4375,4.9219l0,-0.00015z" />
                </symbol>
                <symbol overflow="visible" id="m">
                    <path id="svg_19" d="m2.6719,-21.266l9.1094,0c2.707,0 4.7852,0.60547 6.2344,1.8125c1.4453,1.1992 2.1719,2.9062 2.1719,5.125c0,2.2305 -0.72656,3.9492 -2.1719,5.1562c-1.4492,1.1992 -3.5273,1.7969 -6.2344,1.7969l-3.625,0l0,7.375l-5.4844,0l0,-21.2656zm5.4844,3.9688l0,5.9375l3.0469,0c1.0625,0 1.8789,-0.25391 2.4531,-0.76562c0.58203,-0.51953 0.875,-1.2539 0.875,-2.2031c0,-0.94531 -0.29297,-1.6758 -0.875,-2.1875c-0.57422,-0.51953 -1.3906,-0.78125 -2.4531,-0.78125l-3.0469,-0.00003z" />
                </symbol>
            </defs>
            <g>
                <g id="svg_20">
                    <path id="svg_21" d="m463.92,1.89l-321.39,259.85l-93.703,-142.5l-43.371,28.516l125.18,190.34l365.92,-295.85l-32.636,-40.356z" />
                    <use id="svg_22"  y="537.5" x="-29" />
                    <use id="svg_23"  y="537.5" x="-7.59375" />
                    <use id="svg_24"  y="537.5" x="6.78906" />
                    <use id="svg_25"  y="537.5" x="26.57031" />
                    <use id="svg_26"  y="537.5" x="46.25391" />
                    <use id="svg_27"  y="537.5" x="60.19531" />
                    <use id="svg_28"  y="537.5" x="79.97656" />
                    <use id="svg_29"  y="537.5" x="111.00781" />
                    <use id="svg_30"  y="537.5" x="131.88672" />
                    <use id="svg_31"  y="537.5" x="161.05469" />
                    <use id="svg_32"  y="537.5" x="181.92969" />
                    <use id="svg_33"  y="537.5" x="196.31641" />
                    <use id="svg_34"  y="537.5" x="216.35156" />
                    <use id="svg_35"  y="537.5" x="226.35156" />
                    <use id="svg_36"  y="537.5" x="246.13281" />
                    <use id="svg_37"  y="537.5" x="263.42188" />
                    <use id="svg_38"  y="537.5" x="277.36328" />
                    <use id="svg_39"  y="537.5" x="298.12891" />
                    <use id="svg_40"  y="537.5" x="317.80859" />
                    <use id="svg_41"  y="537.5" x="336.82422" />
                    <use id="svg_42"  y="537.5" x="356.50391" />
                    <use id="svg_43"  y="566.66797" x="-29" />
                    <use id="svg_44"  y="566.66797" x="-16.3125" />
                    <use id="svg_45"  y="566.66797" x="-1.92578" />
                    <use id="svg_46"  y="566.66797" x="18.10938" />
                    <use id="svg_47"  y="566.66797" x="58.65625" />
                    <use id="svg_48"  y="566.66797" x="72.59766" />
                    <use id="svg_49"  y="566.66797" x="93.36328" />
                    <use id="svg_50"  y="566.66797" x="123.30078" />
                    <use id="svg_51"  y="566.66797" x="147.71094" />
                    <use id="svg_52"  y="566.66797" x="167.74609" />
                    <use id="svg_53"  y="566.66797" x="188.51172" />
                    <use id="svg_54"  y="566.66797" x="219.42969" />
                    <use id="svg_55"  y="566.66797" x="240.80469" />
                    <use id="svg_56"  y="566.66797" x="255.19141" />
                    <use id="svg_57"  y="566.66797" x="275.22656" />
                    <use id="svg_58"  y="566.66797" x="285.22656" />
                    <use id="svg_59"  y="566.66797" x="305.00781" />
                    <use id="svg_60"  y="566.66797" x="322.29688" />
                </g>
            </g>
        </svg>

    )

}

export default MarkSvg;