import * as actions from '../action-types/tiles';

const initialState = {
    type: 'TILE_INIT',
    tilePing: undefined,
};

const tileReducer = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        case actions.CEHCK_DATA_IN_TILE_SERVER:
            return {
                type: action.type,
                tilePing: action.data
            };

        default:
            return { ...state };
    }
};

export default tileReducer;