
/** this will bind the active theme to theme object */


const colorsets_options = [
    ['#A2D9D9', '#686963', '#8aa29e', '#3D5467', '#F1EDEE'],
    ['#A393BF', '#9882AC', '#73648A', '#453750', '#0C0910'],
    ['#EE6352', '#08B2E3', '#EFE9F4', '#57A773', '#484D6D'],
    ['#EFC69B', '#AF1B3F', '#473144', '#CCB69B', '#DF9B6D'],
    ['#B80C09', '#0B4F6C', '#01BAEF', '#FBFBFF', '#040F16'],
    ['#802392', '#995FA3', '#9A98B5', '#A0B9C6', '#A5F8D3'],
    ['#000100', '#A1A6B4', '#94C5CC', '#B4D2E7', '#F8F8F8'],
    ['#6D213C', '#946846', '#BAAB68', '#E3C16F', '#FAFF70'],
    ['#FFA69E', '#FF7E6B', '#8C5E58', '#A9F0D1', '#FFF7F8'],
    ['#131200', '#78BC61', '#C0C781', '#C59B76', '#E9806E'],
    ['#050609', '#F5D0C5', '#D69F7E', '#774936', '#3C0000'],
    ['#474A2C', '#636940', '#59A96A', '#9BDEAC', '#B4E7CE'],
    ['#D3C1C3', '#E2D0BE', '#EEE5BF', '#E8F8C1', '#D1FFC6'],
    ['#EC0B43', '#58355E', '#7AE7C7', '#D6FFB7', '#FFF689'],
    ['#1F271B', '#19647E', '#28AFB0', '#F4D35E', '#EE964B'],
    // ['#06C', '#EF9234', '#5752D1', '#73C5C5', '#002F5D'],
    ["#009596", "#ffcd60", "#7a3e7bb5", "#77e96b", "#22D3E1", "#02A1DB", "#5ee5d1", "#e81e63", "#9c27b0", "#607d8b", "#795548b8", "#ff5722"],
    // ['#F4B678', '#a1c9dd', '#224a5e', '#7cb3d0', '#ecf4f8']
];
const table_colorsets = [['#a21035', '#d11544', '#fff9fa'], ['#223333', '#669999', '#effaf4'], ['#6f80ff', '#51aeae', '#f8faff']];

const active_theme_name = (process.env.active_theme || 'theme.primary.json');
const { value_finder } = require('../utils');

let theme_data = undefined;
const default_theme = require('./default.theme.json'); // default theme data will contain the default setting of dyno set by us, the authors of dyno. Of course, user will override it with their themes

switch (active_theme_name) {
    case 'theme.primary.json':
        theme_data = require('./theme.primary.json');
};


if (theme_data) {
    const d_index = 15;
    const t_index = 2;

    colorsets_options[d_index].forEach((color, i) => {
        theme_data.insight.chart.chart_colors[i] = color;
    });

    colorsets_options[d_index].forEach((color, i) => {
        theme_data.report.chart.chart_colors[i] = color;
    });

    const table_colors = table_colorsets[t_index];

    theme_data.insight.table.header.column.colors.background = table_colors[0];
    theme_data.insight.table.header.column.colors.background_2 = table_colors[1];
    theme_data.insight.table.body.row.even.colors.background = table_colors[2];

    // this is for report table 
    theme_data.report.table.header.column.colors.background = table_colors[0];
    theme_data.report.table.header.column.colors.background_2 = table_colors[1];
    theme_data.report.table.body.row.even.colors.background = table_colors[2];



}

console.log('theme_data: ', theme_data);

/**
 * 
 * @param {*} __theme__ 
 * 
 * we are using a concept of color sets. For example, let's say we have divided our colors into 10 different colors, and then client comes and says, boss I am NOT gonna use these 10 colors, but,
 * I am gonna my own 10 colors. If we are using hard-coded-colors, it will be difficult to change it as we will have to find-replace colors.
 * 
 * Instead of doing that, we are using color sets, and we give reference to colors of these colorsets. When we use colorsets, we will just defined these 10 colors as primary. When client comes
 * and says I wanna use different, we will use replace these 10 colors, and it will change the output quickly
 * 
 * example of usage
 * "color": "colorset.border.light"
 * 
 */
export const apply_color_sets_to_colors = (__theme__, colorset) => {
    __theme__ && Object.keys(__theme__).forEach(theme_property => {
        const theme_value = __theme__[theme_property];

        if (theme_property === 'color') {
            // we only work on color property
            if (typeof theme_value === 'string' && theme_value.indexOf('colorset.') > -1) {
                // the color is built on color sets. The following line will return [background, light] for colorset.background.light
                const colors_values = theme_value.replace('colorset.', '').trim().split('.');
                const final_color_value = value_finder(colorset, colors_values);

                __theme__[theme_property] = final_color_value;
            }
        }
        else {
            // lets run recursive, if required
            if (typeof theme_value === 'object' && !Array.isArray(theme_value)) {
                // object found, lets run the recursion
                apply_color_sets_to_colors(theme_value, colorset);
            }
        }
    });
};


let theme = default_theme;

/**
 * here, we will merge the default theme with the selected theme. This is important so if user doesn't provide something, we will pick from default theme
 */
export const bind_theme_data_to_object = (existing_theme, new_theme_data) => {
    if (typeof new_theme_data === 'undefined' || !new_theme_data) {
        // if new_theme is undefined, it means: either it couldn't find a theme to bind, or we were able to bind the variables and have reached to the end of keys
        return existing_theme;
    }
    else {
        Object.keys(new_theme_data).forEach(theme_key => {
            const new_theme_item = new_theme_data[theme_key];

            if (typeof new_theme_item === 'object' && !Array.isArray(new_theme_item)) {
                const existing_theme_item = existing_theme[theme_key];

                // of course, if existing theme doesn't have this key, it means it is a new key, so assign it plain. 
                // but if it exists, we have to map all the child properties to verify if any of it has the updated values from new theme 

                if (typeof existing_theme_item === 'undefined') {
                    // plain assign
                    existing_theme[theme_key] = new_theme_item;
                }
                else {
                    bind_theme_data_to_object(existing_theme[theme_key], new_theme_item);
                }
            }
            else {
                // it is NOT an object. So it is either a string, array or number etc
                // we can just save this

                existing_theme[theme_key] = new_theme_item;
            }
        });
    }
};

// after we have run this function, we will have the values from client theme loaded into the default theme
bind_theme_data_to_object(theme, theme_data);


if (theme) {
    const d_index = 15;
    const t_index = 2;

    colorsets_options[d_index].forEach((color, i) => {
        theme.insight.chart.chart_colors[i] = color;
        theme.report.chart.chart_colors[i] = color;
    });

    const table_colors = table_colorsets[t_index];

    theme.insight.table.header.column.colors.background = table_colors[0];
    theme.insight.table.header.column.colors.background_2 = table_colors[1];
    theme.insight.table.body.row.even.colors.background = table_colors[2];

    theme.report.table.header.column.colors.background = table_colors[0];
    theme.report.table.header.column.colors.background_2 = table_colors[1];
    theme.report.table.body.row.even.colors.background = table_colors[2];
}

console.log('theme_data: ', theme_data);



apply_color_sets_to_colors(theme, theme.colorset);




// lets get rid of the NULLS

// let's get rid of NULLs

const remove_nulls = base_theme_object => {
    const t_keys = Object.keys(base_theme_object);

    t_keys.forEach(t_key => {
        const new_theme_item = base_theme_object[t_key];
        // console.log('looping ====> ', t_key, new_theme_item);

        if (typeof new_theme_item === 'object' && !Array.isArray(new_theme_item)) {
            remove_nulls(new_theme_item);
        }
        else if (new_theme_item === 'null') {
            delete base_theme_object[t_key];
        }
    });
};


remove_nulls(theme);

// console.log('final theme: ', theme);

// module.exports.theme = theme;

export const themeObject = theme;