import React, { useEffect, useState, useCallback, useMemo } from "react";
import PolusLogoHead from "../../svg/icons-v2/polusLogoHead";
import { MessageContainer, MessageWrapper, Waiting } from "./chat.styled";
import { constants } from "../../../utils/constants";
import { connect } from "react-redux";
import { determineSingleCellDisplay } from "../utils";
import { generate_ai_summary, generate_ai_table, generate_auto_description, get_hardcoded_story, get_pivot_formatted_axis } from "../../../utils";
import TypingEffect from "../typing.effect";
import TypingWrapper from '../typing.effect.dy.js'
import TypingEffectNormal from './../../helper/typingEffect'
import ReportSubWrapper from "./report.sub.wrapper";
import OptionsButtonsGroup from './option.buttons.group';
import enums from "../../../utils/enums";
import SmallChatWindow from './small.chat.window'
import { get_gpt_data_story } from "../../../actions/report";
import ChatLoading from "./chat.loading";
import Map from "../../map";
import TyingEffectDy from '../typing.effect.dy.js'

const ReceiveMessage = React.memo((props) => {

    const {
        response,
        expandChart,
        scrollToBottom,
        dataFromServer,
        report_error,
        gptDataStory
    } = props;



    const { all_reports } = props;


    const [singleCellResponse, setSingleCellResponse] = useState(undefined);
    const [localCurrentData, setLocalCurrentData] = useState(undefined);
    const [showDataStory, setShowDataStory] = useState(true);
    const [dataStoryHtmlContent, setDataStoryHtmlContent] = useState(null);
    const [showDeepDrive, setDeepDrive] = useState(undefined);
    const [is_why_case, set_is_why_case] = useState(false);

    const [detaildStory, setDetaildStory] = useState(undefined)
    const [isDatastoryTyping, setIsDatastoryTyping] = useState(true);
    const [aiSummeryData, setAiSummeryData] = useState(undefined);
    const [eanble_link, set_enable_link] = useState(false);


    // Define styles based on response
    const expandedStyle = useMemo(() => ({
        width: window.innerWidth,
        height: window.innerHeight - 0,
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 100
    }), []);

    useEffect(() => {

        if (response?.link && response?.is_hardcoded_story) {
            setTimeout(() => {
                set_enable_link(true)
            }, 25000)
        }
        else if (response?.link) {
            set_enable_link(true)
        }
    }, [response]);



    const style = useMemo(() => response?.expanded ? expandedStyle : {
        width: window.innerWidth * 0.48,
        height: window.innerHeight * 0.48
    }, [response?.expanded, expandedStyle]);


    useEffect(() => {

        if (dataFromServer && !dataFromServer?.is_ui_command) {

            const columnMeta = dataFromServer?.pivot_data?.ultimate_yacs;
            const middle_ = determineSingleCellDisplay(dataFromServer, columnMeta);
            const pivot_axis = get_pivot_formatted_axis(dataFromServer?.pivot_data?.pivot_data_axis, dataFromServer?.pivot_data?.pivot_columns_state);

            setSingleCellResponse({
                ...middle_,
                pivot_axis: pivot_axis,
                columnMeta: columnMeta
            });

            if (!localCurrentData) {
                setLocalCurrentData(dataFromServer);
                set_is_why_case(dataFromServer?.is_why_case);

                let result = `<div id="${response?.id}" style="padding: 0px;">`;

                result += dataFromServer?.gpt_story + ' ';

                if (dataFromServer?.gpt_data?.data?.length > 0) {

                    const key1 = dataFromServer?.gpt_data?.key1;
                    const key2 = dataFromServer?.gpt_data?.key2;

                    const gpt_data = dataFromServer?.gpt_data?.data;

                    result += `<h3 style="margin-top: 0px">
                    Here's a comparison of Hero Realty's residential projects in ${key1} with some of their competitors, focusing on the size, price range, and customer ratings:

                    </h3>`
                    const ai_table = generate_ai_table(gpt_data, response?.question, response?.id);

                    result += ai_table;

                    if(key2){
                        result += `<p>${key2}​</p>`
                    }
                   

                }

                if (dataFromServer?.raw_data?.data?.length > 1) {
                    const ai_table = generate_ai_table(dataFromServer?.raw_data?.data, response?.question, response?.id);

                    result += ai_table
                }
                result += '</div>';

                setAiSummeryData(result)
            }
        }
        if (dataFromServer) {
            scrollToBottom();
        }
    }, [dataFromServer, scrollToBottom, isDatastoryTyping]);



    // Effect for generating data story content
    useEffect(() => {

        if (localCurrentData && showDataStory) {
            const pivot_data = localCurrentData?.pivot_data?.data;
            const columnMeta = localCurrentData?.pivot_data?.ultimate_yacs;
            const pivot_axis = get_pivot_formatted_axis(localCurrentData?.pivot_data?.pivot_data_axis, localCurrentData?.pivot_data?.pivot_columns_state);
            // setTimeout(() => {
            const desc = generate_auto_description(pivot_data, pivot_axis?.xac?.[0], pivot_axis?.yac?.[0], columnMeta);
            // const desc = data_story_create_v1(pivot_data, pivot_axis?.xac?.[0], pivot_axis?.yac?.[0], columnMeta);
            setDataStoryHtmlContent(desc);
            // }, 1000)
        }

    }, [localCurrentData, showDataStory]);


    const enableCopy = useMemo(() => singleCellResponse?.showSingleCell, [singleCellResponse]);
    const enableChartView = useMemo(() => singleCellResponse?.showSingleCell, [singleCellResponse]);
    const enableDataStory = useMemo(() => !singleCellResponse?.showSingleCell, [singleCellResponse]);


    const handleLinkClick = useCallback(() => {
        if (response.link) {
            setTimeout(() => {
                window.open(response.link, '_blank');
            }, 300);
        }
    }, [response.link]);



    const get_detaild_data_story = () => {
        if (!props?.gptDataStory && dataFromServer) {
            setDetaildStory(true)
            props.get_gpt_data_story(props?.id, dataFromServer?.file_path, true)
        }
    }

    useEffect(() => {

        if (props?.gptDataStory) {
            setDetaildStory(false)
        }
    }, [props?.gptDataStory])



    const isDeepDrive = response?.isDeepDrive;
    const isContainsMap = response?.question && /map/i.test(response?.question);

    const pivot_data = localCurrentData?.pivot_data?.data;

    const predefinedData = {
        data: pivot_data,
        underlying_column: 'State',
        yac: localCurrentData?.pivot_data?.pivot_data_axis?.yac?.map((y) => {
            return y?.pivot_field_column_name;
        })
    }



    function checkQuery(query = '') {
        // Check if the query contains 'marketing' and 'consider' or 'source' and 'consider' or 'why'
        if ((query.includes("marketing") && query.includes("consider")) ||
            (query.includes("source") && query.includes("consider")) ||
            (query.includes("how")) ||
            (query.includes("VADY")) ||
            (query.includes("vady")) ||
            (query.includes("Vady")) ||
            (query.includes("Vady,")) ||
            (query.includes("campaign") && query.includes("consider")) ||
            (query.includes("campaigns") && query.includes("consider")) || query.includes("why")) {
            return true;
        }
    }

    const is_AI_QUESTION = checkQuery(response?.question)
    const hardcoded_story = response?.is_hardcoded_story && get_hardcoded_story(response?.predefined_response, response?.link);



    return (
        <MessageContainer>

            <MessageWrapper iconBg={'#fff'} style={{ justifyContent: 'flex-start', width: '100%' }}>
                <div className="polus_icon_bg1">
                    <span>
                        <PolusLogoHead height="1.5rem" size="1.5rem" />
                    </span>
                </div>

                <div style={{
                    maxWidth: 'calc(100% - 6rem)', width: 'calc(100% - 6rem)'
                    // , marginTop: '0.64rem'
                }}>
                    {report_error && (
                        <div style={{ fontSize: '.9rem', fontWeight: 300, color: '#222' }}>
                            <p style={{ margin: '0px', marginTop: '10px' }}>
                                {/* Sorry, we couldn't follow you. <br /> Can you please rephrase your question? */}
                                Oops! There is no data available.
                            </p>
                        </div>
                    )}

                    {response?.predefined_response && !localCurrentData && response?.subType !== "link" && (
                        <div style={{
                            marginTop: '.6rem',
                            fontWeight: 300
                        }}>
                            <TypingEffect typingSpeed={10} htmlContent={response?.predefined_response} />
                        </div>
                    )}


                    {response?.is_hardcoded_story && hardcoded_story && (
                        <TypingEffect typingSpeed={10} htmlContent={hardcoded_story} />
                    )}


                    {(dataStoryHtmlContent && showDataStory) && !singleCellResponse?.showSingleCell && !is_why_case && (
                        <div style={{ fontWeight: 300, color: '#333' }}>
                            <TypingEffect htmlContent={dataStoryHtmlContent} scrollToBottom={scrollToBottom} setIsDatastoryTyping={setIsDatastoryTyping} />
                        </div>
                    )}
                    {aiSummeryData && is_why_case && (
                        <div style={{ fontWeight: 300, color: '#333' }}>
                            <TypingEffect htmlContent={aiSummeryData} scrollToBottom={scrollToBottom} setIsDatastoryTyping={setIsDatastoryTyping} />
                        </div>
                    )}

                    {response?.predefined_response && !localCurrentData && response?.subType === "link" && eanble_link && (
                        <div style={{ color: '#0000EE' }}>
                            <p
                                style={{ textDecoration: 'underline', marginTop: '0.5rem', color: 'black', fontWeight: '300', fontSize: '.9rem', cursor: 'pointer' }}
                                onClick={handleLinkClick}
                            >
                                {response?.predefined_response}
                            </p>
                        </div>
                    )}



                    {response?.subType === "dashboard_links" && (
                        <div>
                            <p>Here are your dashboards</p>
                            <ul>
                                {
                                    response?.dashboard_data?.map((d) => {
                                        return (
                                            <li onClick={() => {
                                                setTimeout(() => {
                                                    window.open(`/?insightId=${d?.id}`, '_blank');
                                                }, 500)
                                            }}>{d?.title}</li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    )}


                    {response?.subType === "dashboard_links" && (

                        <div>
                            <p>Here are your dashboards</p>
                            <TyingEffectDy links={response?.dashboard_data} />
                        </div>
                    )}



                    {localCurrentData && !report_error && !is_why_case && (
                        <>
                            {singleCellResponse?.showSingleCell && !isContainsMap && (
                                <div style={{ padding: "0.5rem 0" }}>
                                    <TypingEffect typingSpeed={10} htmlContent={singleCellResponse?.singleCellTitle} />
                                </div>
                            )}


                            {/* MAP  */}
                            {localCurrentData?.raw_data?.data?.length > 0 && isContainsMap && (
                                <div style={{
                                    background: '#fff',
                                    padding: response?.expanded ? '0px' : '5px',
                                    boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
                                    borderRadius: '8px',
                                    position: 'relative',
                                    marginTop: !detaildStory ? '0rem' : '1.2rem',
                                    ...style,
                                    width: style.width + "px",
                                    height: style.height + "px",
                                    fontFamily: 'Lato',
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}>
                                    {localCurrentData?.raw_data?.data?.length > 0 && (
                                        <div style={{ position: 'relative', width: style?.width - ((response?.expanded && isDeepDrive) ? 380 : 0), height: style?.height }}>
                                            <Map
                                                id={response?.id}
                                                widthOfChart={style?.width - ((response?.expanded && isDeepDrive) ? 380 : 0)}
                                                heightOfChart={style?.height}
                                                reportTitle={'reportTitle'}
                                                predefinedLevel={'state_data'}
                                                click_onExpandMap={() => { }}
                                                predefinedData={predefinedData}
                                                filterKey={"State"}
                                                expanded={false}
                                                isPolusAIWindow={true}
                                                headerHide={true}
                                                hideClear={true}
                                                // marker_type={""}
                                                apply_filter_on _all={() => {

                                                }}
                                                change_map_data={() => {

                                                }}
                                            />

                                        </div>
                                    )}
                                </div>
                            )}


                            {!isContainsMap && localCurrentData?.raw_data?.data?.length > 0 && !singleCellResponse?.showSingleCell && !isDatastoryTyping && (
                                <div style={{
                                    background: '#fff',
                                    padding: response?.expanded ? '0px' : '5px',
                                    boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
                                    borderRadius: '8px',
                                    position: 'relative',
                                    marginTop: !detaildStory ? '0rem' : '1.2rem',
                                    ...style,
                                    width: singleCellResponse?.showSingleCell ? undefined : style.width + "px",
                                    height: singleCellResponse?.showSingleCell ? undefined : style.height + "px",
                                    fontFamily: 'Lato',
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}>
                                    {!singleCellResponse?.showSingleCell && localCurrentData?.raw_data?.data?.length > 0 && (
                                        <div style={{ position: 'relative', width: style?.width - ((response?.expanded && isDeepDrive) ? 380 : 0), height: style?.height }}>
                                            <ReportSubWrapper
                                                id={response?.id}
                                                response={response}
                                                ReportData={localCurrentData}
                                                style={{ width: style?.width - ((response?.expanded && isDeepDrive) ? 380 : 0), height: style?.height }}
                                                expandChart={expandChart}
                                                isDeepDrive={isDeepDrive}
                                                deepDiveClick={() => {
                                                    expandChart(response?.id, true)
                                                }}
                                            />

                                        </div>
                                    )}
                                    {response?.expanded && isDeepDrive && (
                                        <SmallChatWindow response={response} id={response.id} />
                                    )}
                                </div>
                            )}
                        </>
                    )}

                    {/* {singleCellResponse?.showSingleCell && (
                        <OptionsButtonsGroup
                            setShowDataStory={setShowDataStory}
                            enableDataStory={enableDataStory}
                            showDataStory={showDataStory}
                            enableCopy={enableCopy}
                            singleCellResponse={singleCellResponse}
                        />
                    )} */}

                    {!isContainsMap && !response?.expanded && localCurrentData && !report_error && !singleCellResponse?.showSingleCell && !isDatastoryTyping && (
                        <React.Fragment>
                            <OptionsButtonsGroup
                                setShowDataStory={setShowDataStory}
                                enableDataStory={enableDataStory}
                                showDataStory={showDataStory}
                                enableCopy={enableCopy}
                                setDeepDrive={() => {
                                    expandChart(response?.id, true)
                                }}
                                id={response?.id}
                                get_detaild_data_story={get_detaild_data_story}
                                singleCellResponse={singleCellResponse}
                                is_why_case={is_why_case}
                            />
                        </React.Fragment>
                    )}

                    {detaildStory && <ChatLoading />}

                    {is_AI_QUESTION && !aiSummeryData && !detaildStory && !response?.is_hardcoded_story && <ChatLoading isAiModal={true} />}

                    {gptDataStory &&
                        <div style={{ fontWeight: 300, color: '#333' }}>
                            <TypingEffect htmlContent={gptDataStory} scrollToBottom={scrollToBottom} />
                        </div>
                    }

                    {!localCurrentData && response?.subType === "waiting" && !report_error && !is_why_case && !is_AI_QUESTION && !response?.is_hardcoded_story && (
                        <Waiting>
                            <img src={constants.CONTEXT_PATH + './typing_animation.gif'} style={{ width: '60px' }} alt='waiting img' />
                        </Waiting>
                    )}

                </div>
            </MessageWrapper>
        </MessageContainer>
    );

}, (prevProps, nextProps) => {

    return (

        prevProps.response === nextProps.response &&
        prevProps.expandChart === nextProps.expandChart &&
        prevProps.scrollToBottom === nextProps.scrollToBottom &&
        prevProps.dataFromServer === nextProps.dataFromServer &&
        prevProps.report_error === nextProps.report_error &&
        prevProps.gptDataStory === nextProps.gptDataStory &&
        prevProps.all_reports === nextProps.all_reports


    );
});

const mapStateToProps = (state, props) => {

    const dataFromServerClone = state.reportReducer.dataFromServer;
    const id = props?.id;
    const dataFromServer = dataFromServerClone[id];
    const report_error = state.errorReducer?.report_errors?.[id];
    const gptDataStory = state?.reportReducer?.gptDataStoryCache?.[id];
    const all_reports = state.reportHelperReducer?.all_reports?.filter(item => item.type === "insights");


    return { dataFromServer, report_error, gptDataStory, all_reports };
};

export default connect(mapStateToProps, { get_gpt_data_story })(ReceiveMessage);
