import React from 'react';

const Process = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            space="preserve">
            <path d="M200,24a24,24,0,1,1-24,24A24,24,0,0,1,200,24ZM32,192a24,24,0,1,0,24-24A24,24,0,0,0,32,192Zm53-91a24,24,0,1,0,24-24A24,24,0,0,0,85,101Zm354.039,7.273A237.668,237.668,0,0,0,315.461,24.881a24,24,0,1,0-12.883,46.238,189.368,189.368,0,0,1,98.448,66.463A185.963,185.963,0,0,1,440,252.116C440,355.716,355.629,440,251.922,440a186.451,186.451,0,0,1-114.189-38.577,188.962,188.962,0,0,1-36.191-36.548L88,361.865v59.956a236.16,236.16,0,0,0,330.847-2.9,235.762,235.762,0,0,0,20.192-310.646ZM72,456V341.919l114.794,25.51a24,24,0,1,0,10.412-46.858l-144-32A24,24,0,0,0,24,312V456a24,24,0,0,0,48,0Z"/>
        </svg>

    )

}


export default Process;