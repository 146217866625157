import React from 'react';
import styled from 'styled-components';
import DeleteSvg from '../svg/delete'

const Button = styled.div`
    background: none padding-box rgb(255, 255, 255);
    display: block;
    border: 0px;
    margin: 0px;
    padding: 4px;
    text-transform: none;
    appearance: none;
    position: relative;
    cursor: pointer;
    user-select: none;
    // direction: ltr;
    // overflow: hidden;
    // text-align: left;
    color: rgb(0, 0, 0);
    // font-family: Roboto, Arial, sans-serif;
    font-size: 11px;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    font-weight: 500;
    margin-top: 5px;

    &:hover{
        background-color: #eaeaea;
        svg{
            fill: #222;
        }
    }
`;


const DeleteButton = props => {
    const { polygon, delete_clicked } = props;

    return (
        <Button
            onClick={() => delete_clicked()}
        >
            <DeleteSvg size={'12px'} height={'12px'} color={"#928d8d"} />
        </Button>
    )
};

export default DeleteButton;