import React from 'react';

const StackedChart = (props) => {

    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}

            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1728 1728"
            space="preserve"
        >
            <path transform="translate(140,92)" d="m0 0h100l7 2 1 11v1362h1281l52 1 2 1v51l-1 52-4 2-1280 1-95 1h-57l-5-3-1-3-1-15-1-128v-1267l1-67z" fill="#2C76FE" />
            <path transform="translate(1224,481)" d="m0 0h76l33 1 21 2 6 3 4 5 1 9 1 23v409l-2 101-1 230-1 124-2 4-10 4-10 2-17 1h-137l-8-4-6-7-4-9-1-5-1-18-1-215v-497l2-130 1-19 4-8 5-3 16-2z" fill="#659DFE" />
            <path transform="translate(921,676)" d="m0 0h113l21 1 12 2 15 6 3 5 1 8v330l-1 59-1 90-1 189-1 15-6 12-4 3-8 3-107 1h-46l-11-1-8-4-4-5-4-12-2-21-1-248-1-54v-342l1-20 5-8 8-5 5-2 6-1z" fill="#659DFD" />
            <path transform="translate(611,832)" d="m0 0h177l11 1 1 2v107l-1 445-1 7-5 3-4 1-13 1h-162l-6-4-3-5-2-14-1-14-1-172-1-67-1-110v-133l1-39 2-7 1-1z" fill="#659DFD" />
            <path transform="translate(349,937)" d="m0 0h105l36 2 16 2 4 4 1 9v441l-8 3-27 1h-153l-6-4-2-14-1-16-1-152-1-55v-202l3-10 3-3 15-4z" fill="#659DFD" />
            <path transform="translate(1170,996)" d="m0 0 112 1h82v37l-1 230-1 124-2 4-10 4-10 2-17 1h-137l-8-4-6-7-3-7-1-96v-257l1-31z" fill="#2C76FE" />
            <path transform="translate(882,1082)" d="m0 0 80 1h122l1 4-1 90-1 189-1 15-6 12-4 3-8 3-107 1h-46l-11-1-8-4-4-5-4-12-2-21z" fill="#2C76FE" />
            <path transform="translate(601,1150)" d="m0 0 195 1 1 5 1 41 1 24v166l-1 7-5 3-4 1-13 1h-162l-6-4-3-5-2-14-1-14-1-172z" fill="#2C76FD" />
            <path transform="translate(314,1197)" d="m0 0 190 2 5 2 1 88 1 4v102l-8 3-27 1h-153l-6-4-2-14-1-16z" fill="#2C76FE" />
            <path transform="translate(1169,492)" d="m0 0h1v193l-1 347v347h-1l-1-5-1-18-1-215v-497l2-130 1-19z" fill="#FDFDFE" />
            <path transform="translate(503,941)" d="m0 0 5 1 2 3 1 9v339l-2-4v-202l-1-22v-42l-1-12-1-53-1-12z" fill="#EDF2FD" />
            <path transform="translate(797,833)" d="m0 0h2l1 2v107l-1 279h-1l-1-24v-295l-1-41v-27z" fill="#FDFDFE" />
            <path transform="translate(5,15)" d="m0 0 2 3v48l-1 124-1 31h-1l-1 12-1 61h-1v-119l1-6v-149l2-4z" fill="#B8B8BE" />
            <path transform="translate(611,832)" d="m0 0h109v1l-65 1-33 1h-20l1-2z" fill="#FDFDFE" />
            <path transform="translate(1644,1721)" d="m0 0h61v3h-4-51l-6-2z" fill="#659DFE" />
            <path transform="translate(578,1727)" d="m0 0h163v1h-163z" fill="#7CA4EB" />
            <path transform="translate(1,1154)" d="m0 0h1v143h-1z" fill="#B8B8BE" />
            <path transform="translate(1725,745)" d="m0 0h1v87h-1z" fill="#B8B8BE" />
            <path transform="translate(838,1727)" d="m0 0h71v1h-71z" fill="#7BA4EC" />
            <path transform="translate(461,1726)" d="m0 0h39l1 2h-46v-1z" fill="#86A7E4" />
            <path transform="translate(423,1726)" d="m0 0h31l-1 2h-34z" fill="#85A7E4" />
            <path transform="translate(1720,1549)" d="m0 0h1v41h-1z" fill="#659DFE" />
            <path transform="translate(18,1719)" d="m0 0h39v1h-39z" fill="#FDFDFE" />
            <path transform="translate(502,1726)" d="m0 0h20l11 1v1h-31z" fill="#83A7E6" />
            <path transform="translate(354,1726)" d="m0 0h16v2h-19z" fill="#659DFE" />
            <path transform="translate(295,1726)" d="m0 0h19l3 2h-23z" fill="#659DFE" />
            <path transform="translate(546,1726)" d="m0 0h12l-1 2h-22v-1z" fill="#8CA9DF" />
            <path transform="translate(404,1726)" d="m0 0h14l-1 2h-18v-1z" fill="#7FA5E9" />
            <path transform="translate(68,4)" d="m0 0h22v1h-22z" fill="#B8B8BE" />
            <path transform="translate(960,1727)" d="m0 0h21v1h-21z" fill="#98ADD6" />
            <path transform="translate(1007,1727)" d="m0 0h19v1h-19z" fill="#90ABDC" />
            <path transform="translate(754,1727)" d="m0 0h18v1h-18z" fill="#A0B0CF" />
            <path transform="translate(343,1726)" d="m0 0h7v2h-11z" fill="#659DFE" />
            <path transform="translate(821,1727)" d="m0 0h16v1h-16z" fill="#6A9EFA" />
            <path transform="translate(394,1726)" d="m0 0 4 1-2 1h-10v-1z" fill="#8EAADE" />
            <path transform="translate(331,1726)" d="m0 0h7l-2 2h-9z" fill="#659DFE" />
            <path transform="translate(286,1726)" d="m0 0 7 1v1h-10z" fill="#659DFE" />
            <path transform="translate(361,1724)" d="m0 0h14v1h-14z" fill="#659DFE" />
            <path transform="translate(267,1726)" d="m0 0 3 1-1 1h-10v-1z" fill="#659DFE" />
            <path transform="translate(793,1727)" d="m0 0h12v1h-12z" fill="#AAB3C8" />
            <path transform="translate(275,1726)" d="m0 0 6 1v1h-10z" fill="#659DFE" />
            <path transform="translate(321,1726)" d="m0 0h5l-2 2h-6z" fill="#659DFE" />
            <path transform="translate(996,1727)" d="m0 0h10v1h-10z" fill="#8EAADE" />
            <path transform="translate(983,1727)" d="m0 0h9v1h-9z" fill="#77A3EF" />
            <path transform="translate(807,1727)" d="m0 0h9v1h-9z" fill="#77A3EF" />
            <path transform="translate(568,1727)" d="m0 0h9v1h-9z" fill="#77A3EF" />
            <path transform="translate(558,1727)" d="m0 0h9v1h-9z" fill="#93ACDA" />
            <path transform="translate(381,1726)" d="m0 0h3v2h-6z" fill="#77A3EF" />
            <path transform="translate(921,1727)" d="m0 0h8v1h-8z" fill="#84A7E6" />
            <path transform="translate(744,1727)" d="m0 0h8v1h-8z" fill="#A3B1CE" />
            <path transform="translate(248,1727)" d="m0 0h8v1h-8z" fill="#659DFE" />
            <path transform="translate(236,1727)" d="m0 0h8v1h-8z" fill="#6FA0F6" />
            <path transform="translate(123,1723)" d="m0 0h6v1l-7 1z" fill="#98ADD6" />
            <path transform="translate(110,1723)" d="m0 0h8v1h-8z" fill="#98ADD6" />
            <path transform="translate(100,1723)" d="m0 0h8v1h-8z" fill="#ADB4C6" />
            <path transform="translate(1212,1727)" d="m0 0h7v1h-7z" fill="#88A8E2" />
            <path transform="translate(1181,1727)" d="m0 0h7v1h-7z" fill="#B8B8BE" />
            <path transform="translate(952,1727)" d="m0 0h7v1h-7z" fill="#94ACD9" />
            <path transform="translate(1,1298)" d="m0 0h1v7h-1z" fill="#B8B8BE" />
            <path transform="translate(1257,1727)" d="m0 0h6v1h-6z" fill="#B8B8BE" />
            <path transform="translate(1223,1727)" d="m0 0h6v1h-6z" fill="#B8B8BE" />
            <path transform="translate(1202,1727)" d="m0 0h6v1h-6z" fill="#AAB3C8" />
            <path transform="translate(1065,1727)" d="m0 0h6v1h-6z" fill="#B8B8BE" />
            <path transform="translate(1034,1727)" d="m0 0h6v1h-6z" fill="#AAB3C8" />
            <path transform="translate(942,1727)" d="m0 0h6v1h-6z" fill="#AAB3C8" />
            <path transform="translate(933,1727)" d="m0 0h6v1h-6z" fill="#AAB3C8" />
            <path transform="translate(777,1727)" d="m0 0h6v1h-6z" fill="#8EAADE" />
            <path transform="translate(228,1727)" d="m0 0h6v1h-6z" fill="#659DFE" />
            <path transform="translate(322,1723)" d="m0 0 2 1-5 1z" fill="#659DFE" />
            <path transform="translate(1171,1727)" d="m0 0h5v1h-5z" fill="#B8B8BE" />
            <path transform="translate(912,1727)" d="m0 0h5v1h-5z" fill="#B8B8BE" />
            <path transform="translate(1706,1723)" d="m0 0h5v1h-5z" fill="#659DFE" />
            <path transform="translate(91,1723)" d="m0 0h5v1h-5z" fill="#B8B8BE" />
            <path transform="translate(1637,1721)" d="m0 0h5v1h-5z" fill="#659DFE" />
            <path transform="translate(1721,1537)" d="m0 0h1v5h-1z" fill="#659DFE" />
            <path transform="translate(1,1148)" d="m0 0h1v5h-1z" fill="#B8B8BE" />
            <path transform="translate(1725,833)" d="m0 0h1v5h-1z" fill="#B8B8BE" />
            <path transform="translate(1192,1727)" d="m0 0 4 1z" fill="#B8B8BE" />
            <path transform="translate(1161,1727)" d="m0 0 4 1z" fill="#A3B1CE" />
            <path transform="translate(1129,1727)" d="m0 0 4 1z" fill="#B8B8BE" />
            <path transform="translate(1117,1727)" d="m0 0 4 1z" fill="#B8B8BE" />
            <path transform="translate(1055,1727)" d="m0 0 4 1z" fill="#A3B1CE" />
            <path transform="translate(1045,1727)" d="m0 0 4 1z" fill="#A3B1CE" />
            <path transform="translate(788,1727)" d="m0 0 4 1z" fill="#A3B1CE" />
            <path transform="translate(411,1724)" d="m0 0 4 1z" fill="#659DFE" />
            <path transform="translate(399,1724)" d="m0 0 4 1z" fill="#A3B1CE" />
            <path transform="translate(331,1724)" d="m0 0 4 1z" fill="#659DFE" />
            <path transform="translate(1437,1723)" d="m0 0 4 1z" fill="#659DFE" />
            <path transform="translate(135,1723)" d="m0 0 4 1z" fill="#8EAADE" />
            <path transform="translate(1237,1727)" d="m0 0 3 1z" fill="#B8B8BE" />
            <path transform="translate(1151,1727)" d="m0 0 3 1z" fill="#B8B8BE" />
            <path transform="translate(1141,1727)" d="m0 0 3 1z" fill="#B8B8BE" />
            <path transform="translate(1077,1727)" d="m0 0 3 1z" fill="#9CAFD3" />
            <path transform="translate(219,1727)" d="m0 0 3 1z" fill="#80A6E8" />
            <path transform="translate(448,1724)" d="m0 0 3 1z" fill="#659DFE" />
            <path transform="translate(443,1724)" d="m0 0 3 1z" fill="#80A6E8" />
            <path transform="translate(419,1724)" d="m0 0 3 1z" fill="#80A6E8" />
            <path transform="translate(291,1724)" d="m0 0 3 1z" fill="#659DFE" />
            <path transform="translate(1531,1723)" d="m0 0 3 1z" fill="#80A6E8" />
            <path transform="translate(346,1723)" d="m0 0 3 1z" fill="#659DFE" />
            <path transform="translate(1721,1519)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1721,1515)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1325,1727)" d="m0 0 2 1z" fill="#B8B8BE" />
            <path transform="translate(1293,1727)" d="m0 0 2 1z" fill="#8EAADE" />
            <path transform="translate(1247,1727)" d="m0 0 2 1z" fill="#B8B8BE" />
            <path transform="translate(1109,1727)" d="m0 0 2 1z" fill="#B8B8BE" />
            <path transform="translate(1087,1727)" d="m0 0 2 1z" fill="#B8B8BE" />
            <path transform="translate(1027,1727)" d="m0 0 2 1z" fill="#B8B8BE" />
            <path transform="translate(371,1727)" d="m0 0 2 1z" fill="#659DFE" />
            <path transform="translate(223,1727)" d="m0 0 2 1z" fill="#8EAADE" />
            <path transform="translate(211,1727)" d="m0 0 2 1z" fill="#B8B8BE" />
            <path transform="translate(689,1724)" d="m0 0 2 1z" fill="#B8B8BE" />
            <path transform="translate(593,1724)" d="m0 0 2 1z" fill="#B8B8BE" />
            <path transform="translate(561,1724)" d="m0 0 2 1z" fill="#8EAADE" />
            <path transform="translate(529,1724)" d="m0 0 2 1z" fill="#659DFE" />
            <path transform="translate(461,1724)" d="m0 0 2 1z" fill="#8EAADE" />
            <path transform="translate(453,1724)" d="m0 0 2 1z" fill="#8EAADE" />
            <path transform="translate(391,1724)" d="m0 0 2 1z" fill="#659DFE" />
            <path transform="translate(381,1724)" d="m0 0 2 1z" fill="#659DFE" />
            <path transform="translate(352,1724)" d="m0 0 2 1z" fill="#659DFE" />
            <path transform="translate(339,1724)" d="m0 0 2 1z" fill="#659DFE" />
            <path transform="translate(315,1724)" d="m0 0 2 1z" fill="#659DFE" />
            <path transform="translate(310,1724)" d="m0 0 2 1z" fill="#659DFE" />
            <path transform="translate(242,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(130,1723)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(1500,1723)" d="m0 0 2 1z" fill="#659DFE" />
            <path transform="translate(1481,1723)" d="m0 0 2 1z" fill="#659DFE" />
            <path transform="translate(1426,1723)" d="m0 0 2 1z" fill="#659DFE" />
            <path transform="translate(1415,1723)" d="m0 0 2 1z" fill="#659DFE" />
            <path transform="translate(1394,1723)" d="m0 0 2 1z" fill="#659DFE" />
            <path transform="translate(1383,1723)" d="m0 0 2 1z" fill="#659DFE" />
            <path transform="translate(1374,1723)" d="m0 0 2 1z" fill="#659DFE" />
            <path transform="translate(1362,1723)" d="m0 0 2 1z" fill="#659DFE" />
            <path transform="translate(203,1723)" d="m0 0 2 1z" fill="#659DFE" />
            <path transform="translate(196,1723)" d="m0 0 2 1z" fill="#659DFE" />
            <path transform="translate(146,1723)" d="m0 0 2 1z" fill="#B8B8BE" />
            <path transform="translate(1721,1524)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1721,1504)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1,1143)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(1,1128)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(65,4)" d="m0 0 2 1z" fill="#B8B8BE" />
            <path transform="translate(1314,1727)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(1270,1727)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(1234,1727)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(1166,1727)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(1097,1727)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(1042,1727)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(930,1727)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(910,1727)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(818,1727)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(226,1727)" d="m0 0" fill="#659DFE" />
            <path transform="translate(201,1727)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(192,1727)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(1361,1726)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(533,1726)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(376,1726)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(281,1726)" d="m0 0" fill="#659DFE" />
            <path transform="translate(245,1726)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(945,1724)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(865,1724)" d="m0 0" fill="#659DFE" />
            <path transform="translate(834,1724)" d="m0 0" fill="#659DFE" />
            <path transform="translate(614,1724)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(586,1724)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(518,1724)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(498,1724)" d="m0 0" fill="#659DFE" />
            <path transform="translate(492,1724)" d="m0 0" fill="#659DFE" />
            <path transform="translate(489,1724)" d="m0 0" fill="#659DFE" />
            <path transform="translate(471,1724)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(459,1724)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(438,1724)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(426,1724)" d="m0 0" fill="#659DFE" />
            <path transform="translate(424,1724)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(387,1724)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(379,1724)" d="m0 0" fill="#659DFE" />
            <path transform="translate(358,1724)" d="m0 0" fill="#659DFE" />
            <path transform="translate(344,1724)" d="m0 0" fill="#659DFE" />
            <path transform="translate(328,1724)" d="m0 0" fill="#659DFE" />
            <path transform="translate(325,1724)" d="m0 0" fill="#659DFE" />
            <path transform="translate(298,1724)" d="m0 0" fill="#659DFE" />
            <path transform="translate(284,1724)" d="m0 0" fill="#659DFE" />
            <path transform="translate(278,1724)" d="m0 0" fill="#659DFE" />
            <path transform="translate(120,1724)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(1565,1723)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(1545,1723)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(1513,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1506,1723)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(1474,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1469,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1406,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1403,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1388,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1369,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1366,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1357,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1338,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1282,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1272,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1250,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1244,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1239,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1232,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1226,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1200,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1189,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(940,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(908,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(880,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(869,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(752,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(741,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(694,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(684,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(576,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(565,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(556,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(512,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(385,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(336,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(299,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(288,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(256,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(194,1723)" d="m0 0" fill="#659DFE" />
            <path transform="translate(184,1723)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(165,1723)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(133,1723)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(89,1723)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(1721,1532)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1721,1527)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1721,1512)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1721,1509)" d="m0 0" fill="#659DFE" />
            <path transform="translate(1,1313)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(1,1146)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(1,1141)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(1,1138)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(1,1131)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(1725,842)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(167,4)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(156,4)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(153,4)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(135,4)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(124,4)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(99,4)" d="m0 0" fill="#B8B8BE" />
            <path transform="translate(96,4)" d="m0 0" fill="#B8B8BE" />
        </svg>

    )

}


export default StackedChart;