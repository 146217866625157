import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { getOutliearData, } from '../../actions/new-outlier'
import AddOutlierForm from '../../components/outlier-comp/add-outlier';
import * as enums from '../../utils/enums';
import { showPopup } from '../../utils';
import { PageInnerWrapper } from '../hoc/styled.hoc'
import ScanDataComponent from '../outlier-comp/scanData';
import { Button } from '../hoc/button'
import { Gap } from '../gap';
import SharedTable from '../shared-table'



const Index = (props) => {
	const { getOutliearData, outlierData } = props;
	const [localOutlinerData, setOutlinerData] = useState(undefined);


	useEffect(() => {
		getOutliearData();
	}, []);



	useEffect(() => {
		setOutlinerData(outlierData)
	}, [outlierData])




	return (
		<div style={{ marginTop: '30px' }}>
			<PageInnerWrapper>
				<div>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Button primary={true} onClick={() => {
							showPopup(undefined, undefined, enums.default.popupType.element, ScanDataComponent);
						}}>Scan-Data</Button>
						<Button onClick={() => {
							showPopup(undefined, undefined, enums.default.popupType.element, AddOutlierForm);
						}}>Add New</Button>
					</div>

					<Gap h="25px" />
					<SharedTable
						tableData={(localOutlinerData && localOutlinerData.length > 0) ? localOutlinerData : undefined}
						tableHead={["Patient-Id", "Pat-Temp At", "Pat-Email", "Pat-Country", "Organization"]}
						columnsToShow={["patient_id", "Pat_temp", "Pat_email", "Pat_country", "Organization"]}
					/>
				</div>
			</PageInnerWrapper>
		</div>
	)
}


const mapStateToProps = (state) => ({
	outlierData: state.OutlierReducer.outlierData,
})


export default withRouter(connect(mapStateToProps, { getOutliearData })(Index))
