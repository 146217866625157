import React from "react";

const MenuGroupIcon = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1204 1204"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        transform="translate(565)"
        d="m0 0h75l50 6 35 6 38 9 36 11 29 11 23 10 25 12 21 11 22 13 27 18 14 10 13 10 11 9 13 11 10 9 8 7 30 30 7 8 13 15 13 16 14 19 20 30 12 20 12 22 14 28 14 34 12 34 8 28 8 35 6 36 4 36 1 17v61l-3 33-6 42-7 34-8 30-12 36-8 21-11 25-11 23-13 23-10 17-22 33-16 21-11 14-9 10-1 2h-2l-2 4-4 5h-2l-2 4-11 11-7 8-8 7-16 15-11 9-9 8-13 10-19 14-24 16-25 15-24 13-29 14-27 11-28 10-26 8-32 8-32 6-28 4-35 3-26 1h-19l-28-1-34-3-35-5-34-7-30-8-36-12-21-8-23-10-29-14-23-13-17-10-21-14-18-13-13-10-11-9-14-12-12-11-8-7-23-23-7-8-9-10-9-11-12-15-14-19-18-27-12-20-12-22-15-30-13-31-8-23-8-24-8-30-7-34-5-35-3-34-1-29v-16l1-28 3-35 6-40 8-36 8-29 9-27 8-21 11-26 16-32 15-27 15-23 11-16 12-16 10-13 12-14 9-11 12-12 7-8 11-11h2l1-3 8-7 13-12 11-9 14-11 13-10 23-16 19-12 24-14 27-14 24-11 28-11 35-12 38-10 36-7 37-5zm18 33-33 2-39 5-31 6-28 7-29 9-25 9-26 11-16 7-19 10-18 10-23 14-16 11-14 10-13 10-17 14-11 10-8 7-34 34v2h-2l-9 11-13 15-8 11-12 16-10 15-10 16-10 17-14 26-14 30-10 25-13 40-7 27-7 34-5 35-2 25-1 26v24l2 37 4 32 4 24 8 36 8 27 8 24 11 28 13 28 15 29 14 23 12 18 10 14 9 12 13 16 9 11 14 15 12 13 10 10 8 7 12 11 11 9 16 13 18 13 21 14 21 13 28 15 27 13 32 13 36 12 30 8 34 7 27 4 32 3 15 1h46l39-3 35-5 30-6 31-8 36-12 24-9 24-11 16-8 23-12 23-14 12-8 20-14 13-10 22-18 13-12 8-7 23-23 7-8 11-12 11-14 12-15 13-19 7-10 11-18 12-21 12-23 14-31 11-30 10-31 8-32 6-31 5-38 2-28v-51l-3-37-4-30-7-35-9-35-12-36-12-29-12-26-12-23-15-25-16-24-14-19-13-16-9-11-11-12-7-8-24-24-8-7-10-9-11-9-14-11-18-13-24-16-20-12-18-10-30-15-24-10-24-9-35-11-28-7-38-7-33-4-38-2z"
      />
      <path
        transform="translate(521,529)"
        d="m0 0h142l1 1v141l-2 1h-140l-1-1zm18 18v107h107v-107z"
      />
      <path
        transform="translate(521,344)"
        d="m0 0h143v142l-1 1h-142zm18 18v107h107v-107z"
      />
      <path
        transform="translate(718,529)"
        d="m0 0h143v142l-2 1h-141zm18 18v107h107v-107z"
      />
      <path
        transform="translate(718,344)"
        d="m0 0h142l1 1v141l-1 1h-142zm123 17-105 1-1 98 1 9h107v-108z"
      />
      <path
        transform="translate(521,713)"
        d="m0 0h142l1 1v141l-5 1h-137l-1-1zm18 18v107h106l1-9v-98z"
      />
      <path
        transform="translate(718,713)"
        d="m0 0h143v142l-2 1h-139l-2-1zm18 18v107h107v-107z"
      />
      <path
        transform="translate(324,529)"
        d="m0 0h141l1 2v140l-2 1h-139l-1-1zm17 18v107h107v-107z"
      />
      <path
        transform="translate(324,344)"
        d="m0 0h141l1 1v141l-2 1h-139l-1-1zm121 17-104 1v107h107v-107z"
      />
      <path
        transform="translate(324,713)"
        d="m0 0h141l1 2v140l-2 1h-139l-1-1zm17 18v107h107v-107z"
      />
    </svg>
  );
};

export default MenuGroupIcon;
