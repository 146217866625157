import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { Button } from '../hoc/button';
import SelectOption from '../select-option';
import { Radio } from '../hoc/checkbox';
import { connect } from 'react-redux';
import { getDbTableInfo } from '../../actions/sample.demo'
import { withRouter } from 'react-router-dom';
import { bytesToSize } from '../../utils'



const Wrapper = styled.div`
    width: 35rem;
    background-color: #fff;
    border: 3px solid #ccc;
    border-radius: 4px;
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h1{

        font-size: 1.2rem; 
        font-weight: 400; 
        margin-top: 0px;
         margin-bottom: 1rem; 
         text-align: center;
    }
`;



const TableWrapper = styled.div`
    width: 100%;
    max-height: 30rem;
    overflow-y: auto;
    &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }
    table{
        width: 100%;
    }
    td{
        padding: 10px 5px;
        font-size: .9rem;
    }
    thead{
        td{
            font-weight: 400;
        }
    }
    tbody{
        td{
            &:nth-child(1){
                text-align: left;
            }
        }
    }

`;


const SelectSecurityType = (props) => {

    const { close, popupKey, option, data, getDbTableInfo, dbTableInfo } = props;
    const { info } = data;
    const [tableInfo, setTableInfo] = useState(undefined)
    const [selectedTable, setSelectedTable] = useState(undefined)



    useEffect(() => {
        getDbTableInfo(info.client_id, info.database_name)
    }, [])


    useEffect(() => {
        setTableInfo(dbTableInfo)
    }, [dbTableInfo])


    const clickOnView = () => {
        let _path = `demo_view?db_name=${info.database_name}&table_name=${selectedTable}`
        props.history.push(_path)
    }



    return (
        <Wrapper>
            <h1>Tables list</h1>
            <TableWrapper>
                {tableInfo && tableInfo && tableInfo.length > 0 && (
                    <table>
                        <thead>
                            <tr>
                                <td>#</td>
                                <td>Table Name</td>
                                <td>Number of Records</td>
                                {/* <td>Table Size</td> */}
                            </tr>
                        </thead>
                        <tbody>
                            {tableInfo.map((row, i) => {
                                return (
                                    <tr>
                                        <td>
                                            <div style={{display: 'flex', alignItems: 'center'}} onClick={() => {
                                                if (selectedTable && selectedTable === row.table_name) {
                                                    setSelectedTable(undefined)
                                                }
                                                else {
                                                    setSelectedTable(row.table_name)
                                                }
                                            }}>
                                                <Radio
                                                    checked={selectedTable && selectedTable === row.table_name}
                                                />
                                            </div>
                                        </td>
                                        <td title={row.table_name}>{row.table_name}</td>
                                        <td style={{textAlign: 'center'}}>{row.number_of_record}</td>
                                        {/* <td>{row.size ? bytesToSize(row.size) : 0}</td> */}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                )}


            </TableWrapper>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '2rem', marginLeft: 'auto' }}>
                <Button
                    width="6rem"
                    mR="1rem"
                    primary={true}
                    onClick={() => {
                        clickOnView()
                        close(option, popupKey)


                    }}>View</Button>
                <Button width="6rem" bgColor={'#e6e6e6'}
                    onClick={() => {
                        close(option, popupKey)
                    }}
                >Cancel</Button>
            </div>
        </Wrapper>
    )
}


const mapStateToProps = (state) => ({
    dbTableInfo: state.sampleDemoReducer.dbTableInfo
})


export default withRouter(connect(mapStateToProps, { getDbTableInfo })(SelectSecurityType));
