import React, { useState, useEffect, useRef } from 'react';
import { DisplayFiledWrapper, DisplayColumnData, ATozTabWrapper } from './select.display.field.styled';
import { RadioCheckMark } from '../hoc/radiobox';
import { Button } from '../hoc/button';
import { Gap } from '../gap/index';
import TextBox from '../hoc/textBox';
import SmallPopup from './small.popup';
import { showPopup } from '../../utils';
import * as enums from '../../utils/enums';
import FilterDefaultValue from './filter.default.value';
import View from '../svg/view';



const SelectDisplayField = (props) => {

    const { close, popupKey, option, data } = props;

    const {

        displayItem, title, selectedFields, setSelectedFields,
        isCheckboxHide, hideSearchbox, buttonText, titleOnHover,
        titleHeading, smallPopup, selectFilterValue, setFilterDefaultValues,
        filterDefaultValues, hideSelectAll, hidePrimeryBtn, itemClickAction,
        hide_selected_object,
        width, onViewIconClick

    } = data;

    const [searchValue, setSearchValue] = useState("")
    const [tempSelectedVal, setTempSelectedVal] = useState([]);
    const [isSelectFilterValue, setIsSelectFilterValue] = useState(undefined);
    const [tempFilterDefaultValues, setTempFilterDefaultValues] = useState(undefined);

    let prevLetter = '';
    let aTozArray = [];


    useEffect(() => {
        if (filterDefaultValues) {
            setTempFilterDefaultValues(filterDefaultValues)
        } else setTempFilterDefaultValues(undefined)
    }, [filterDefaultValues])

    useEffect(() => {
        if (selectedFields) {
            setTempSelectedVal([...selectedFields])
        }
    }, [selectedFields]);


    const compare_value_with_selected_item = (value, array) => {
        for (let index = 0; index < array?.length; index++) {
            const item = array?.[index];
            if (typeof item === 'string') {
                if ((item && item?.toLocaleLowerCase()) === (value && value.toLocaleLowerCase())) {
                    return true;
                }
            }
            if (typeof item === 'object') {
                if ((item && item.value && item.value.toLocaleLowerCase()) === (value && value.toLocaleLowerCase())) {
                    return true;
                }
            }
        }
    }


    const sorted_display_items = JSON.parse(JSON.stringify(displayItem)).filter((data) => {
        if (typeof data === 'string') {
            if (compare_value_with_selected_item(data, selectedFields)) {
                return false;
            }
            if (data === '_id') return false;
            if (data === '') return false;
            if (searchValue === '') {
                return true;
            }

            else if (data.toLowerCase().includes(searchValue.toLowerCase())) {
                return true;
            }
        } else return false;
    });


    sorted_display_items.sort(function (a, b) {

        let left = typeof a === 'string' ? a.trim().toLowerCase() : a;
        let right = typeof b === 'string' ? b.trim().toLowerCase() : b;

        return left.localeCompare(right);
    });






    const _onChangeFunction = (name, id, selectOne, selectAll) => {
        let tempArray = [...tempSelectedVal];
        if (name && name.length > 0) {
            if (selectOne) {
                tempArray = [name]
            }
            else {
                if (tempArray.indexOf(name) > -1) {
                    tempArray.splice(tempArray.indexOf(name), 1)
                }
                else {
                    tempArray.push(name)
                }
            }
        }

        if (selectAll) {
            if ((tempArray && tempArray.length) === (displayItem && displayItem.length)) {
                tempArray = []
            } else {
                if (hide_selected_object) {
                    const filterd_item = tempArray && tempArray.length > 0 && tempArray.filter((f) => {
                        if (typeof f === 'object') return true;
                        else return false;
                    }) || [];
                    tempArray = [...filterd_item, ...displayItem]
                }
                else tempArray = displayItem;
            }
        }
        setTempSelectedVal([...tempArray])
    }


    const genCharArray = (charA, charZ) => {
        let a = [];
        let i = charA.charCodeAt(0);
        let j = charZ.charCodeAt(0);

        for (i; i <= j; i++) {
            a.push(String.fromCharCode(i));
        }

        return a
    }

    aTozArray = genCharArray("a", "z");

    const scrollToView = (index) => {
        const element = elRefs.current[index];

        if (element && element.current) {
            element.current.scrollIntoView({ block: 'end', behavior: 'smooth', inline: 'start' });
        }
    };

    const elRefs = useRef([]);

    // add or remove refs
    elRefs.current = Array(26).fill().map((_, i) => elRefs.current[i] || React.createRef());


    const saveLocalType = (name, type, index) => {
        let tempArray = [...tempSelectedVal];
        let temp_values = [];

        tempArray && tempArray.length > 0 && tempArray.map((column) => {
            temp_values.push(column.value)
        })

        if (temp_values && temp_values.length > 0) {
            if (temp_values.indexOf(name) > -1) {
                tempArray.splice(temp_values.indexOf(name), 1)
            } else {
                tempArray.push({
                    type: type,
                    value: name
                })
            }
        }
        if (tempArray && tempArray.length === 0) {
            tempArray.push({
                type: type,
                value: name
            })
        }

        setTempSelectedVal([...tempArray])
    }

    const onColumnClick = (item, index, type) => {
        showPopup(undefined, undefined, enums.default.popupType.element, SmallPopup, {
            saveFunction: (item, type, index) => saveLocalType(item, type, index),
            item: item,
            index: index,
            type: type
        })
    }

    const getActiveValueObject = (item, array) => {
        let _type = undefined
        array && array.length > 0 && array.map((column) => {
            if (column.value === item) {
                _type = column.type
                return column.type
            }
        })
        return _type
    }



    return (
        <DisplayFiledWrapper isCheckboxHide={isCheckboxHide} width={width}>
            <React.Fragment>

                {isSelectFilterValue && selectFilterValue && (
                    <FilterDefaultValue
                        filterValue={tempSelectedVal}
                        filterDefaultValue={tempFilterDefaultValues}
                        setFilterDefaultValue={setTempFilterDefaultValues}
                    />
                )}

                {!isSelectFilterValue && (
                    <React.Fragment>
                        <div style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: `${hideSearchbox ? 'flex-start' : 'space-between'}`,
                            flexDirection: 'row',
                            marginBottom: '27px',
                        }}>

                            <h3 style={{ marginRight: `${hideSearchbox ? '3rem' : undefined}` }}>{title ? title : ""}</h3>

                            {!hideSearchbox && (
                                <div style={{ width: '55%', padding: '0px 15px', boxSizing: 'border-box' }}>
                                    <TextBox
                                        type="text"
                                        label="Type here to quick search"
                                        value={searchValue}
                                        onChange={(e) => setSearchValue(e.target.value)}
                                    />
                                </div>
                            )}

                            {hideSearchbox && (
                                <ATozTabWrapper >
                                    {aTozArray && aTozArray.length > 0 && aTozArray.map((value, index) => {
                                        let IsActive = searchValue && searchValue === value;
                                        return (
                                            <div
                                                className="tab_value"
                                                style={{ backgroundColor: IsActive ? '#eaeaea' : undefined }}
                                                key={index}
                                                onClick={() => scrollToView(index)}
                                            >
                                                {value}
                                            </div>
                                        )
                                    })}
                                </ATozTabWrapper>
                            )}
                        </div>


                        <DisplayColumnData isCheckboxHide={isCheckboxHide} >
                            <ul>
                                {!smallPopup && !hideSelectAll && (
                                    <li
                                        className="item"
                                        onClick={() => {
                                            _onChangeFunction(undefined, undefined, undefined, true);
                                        }}
                                    >
                                        <RadioCheckMark checked={(displayItem && displayItem.length) === (tempSelectedVal && tempSelectedVal.length)} />
                                        <span
                                            className="item_name"
                                            title={"Select All"}
                                        >
                                            Select / Deselect All
                                        </span>
                                    </li>
                                )}

                                {selectedFields && Array.isArray(selectedFields) && selectedFields.length > 0 && (
                                    <React.Fragment>
                                        <li className="sort_title">✓</li>
                                        {selectedFields && selectedFields.map((selected, _index) => {
                                            if (typeof selected === 'string') {
                                                return (
                                                    <React.Fragment key={"selected" + _index}>
                                                        <li
                                                            className="item"
                                                            onClick={() => {
                                                                _onChangeFunction(selected, _index, undefined)
                                                            }}
                                                        >
                                                            <RadioCheckMark checked={compare_value_with_selected_item(selected, tempSelectedVal)} />
                                                            <span
                                                                className="item_name"
                                                                title={titleOnHover && titleHeading ? `${titleHeading + ':- ' + (titleOnHover[selected] && titleOnHover[selected].table_info ? titleOnHover[selected].table_info.join(",") : '')}` : selected}
                                                            >
                                                                {selected}
                                                            </span>
                                                        </li>
                                                    </React.Fragment>
                                                )
                                            }
                                            if (!hide_selected_object && typeof selected === 'object') {
                                                return (
                                                    <React.Fragment key={"selected" + _index}>
                                                        <li
                                                            className="item"
                                                            onClick={() => {
                                                                let tempArray = [...tempSelectedVal];
                                                                let temp_values = [];
                                                                tempArray && tempArray.length > 0 && tempArray.map((column) => {
                                                                    temp_values.push(column.value)
                                                                })
                                                                if (temp_values && temp_values) {
                                                                    if (temp_values.indexOf(selected.value) > -1) {
                                                                        tempArray.splice(temp_values.indexOf(selected.value), 1)
                                                                    } else {
                                                                        onColumnClick(selected.value)
                                                                    }
                                                                }
                                                                setTempSelectedVal([...tempArray])
                                                            }}
                                                        >
                                                            <RadioCheckMark checked={compare_value_with_selected_item(selected.value, tempSelectedVal)} />
                                                            <span
                                                                className="item_name"
                                                                title={titleOnHover && titleHeading ? `${titleHeading + ':- ' + (Array.isArray(titleOnHover[selected]) ? titleOnHover[selected].join(', ') : titleOnHover[selected])}` : selected}
                                                            >
                                                                {selected.value}
                                                            </span>
                                                        </li>
                                                    </React.Fragment>
                                                )
                                            }
                                        })}
                                    </React.Fragment>
                                )}


                                {sorted_display_items && sorted_display_items.length > 0 &&
                                    sorted_display_items.map((item, index) => {
                                        const boo = prevLetter === item.trim().substring(0, 1).toLowerCase() ? true : false;
                                        prevLetter = item.trim().substring(0, 1).toLowerCase();
                                        const firstLetter = item.trim().substring(0, 1).toLowerCase();

                                        return (
                                            <React.Fragment
                                            >
                                                {boo === false &&
                                                    <li
                                                        className="sort_title"
                                                        ref={elRefs.current[aTozArray.indexOf(firstLetter)]}
                                                    >
                                                        {item.substring(0, 1)}
                                                    </li>
                                                }

                                                {!isCheckboxHide ?
                                                    <li
                                                        className="item"
                                                        onClick={() => {
                                                            if (smallPopup) {
                                                                let _value = getActiveValueObject(item, tempSelectedVal)
                                                                if (_value) {
                                                                    let tempArray = [...tempSelectedVal];
                                                                    let temp_values = [];
                                                                    tempArray && tempArray.length > 0 && tempArray.map((column) => {
                                                                        temp_values.push(column.value)
                                                                    })
                                                                    if (temp_values && temp_values.length > 0) {
                                                                        if (temp_values.indexOf(item) > -1) {
                                                                            tempArray.splice(temp_values.indexOf(item), 1)
                                                                        }
                                                                    }
                                                                    setTempSelectedVal([...tempArray])
                                                                } else {
                                                                    onColumnClick(item, index);

                                                                }
                                                            } else {
                                                                _onChangeFunction(item, index, undefined)
                                                            }

                                                        }}
                                                    >

                                                        <RadioCheckMark
                                                            checked={compare_value_with_selected_item(item, tempSelectedVal)} />
                                                        <span
                                                            className="item_name"
                                                            title={item}
                                                        >
                                                            {item}
                                                        </span>
                                                    </li>

                                                    :

                                                    <li className="item" onClick={() => {
                                                        if (itemClickAction) {
                                                            itemClickAction(item)
                                                            close(option, popupKey)
                                                        }
                                                        _onChangeFunction(item, index, true)
                                                    }}>
                                                        <span
                                                            style={{
                                                                marginLeft: isCheckboxHide ? '0px' : undefined,
                                                                background: compare_value_with_selected_item(item, tempSelectedVal) ? '#eaeaea' : undefined,
                                                                display: 'flex',
                                                                justifyContent: 'space-between',

                                                            }}

                                                            className="item_name"
                                                            title={item}
                                                        >
                                                            <span style={{width: onViewIconClick ? '90%' : '100%', whiteSpace:"nowrap", overflow: 'hidden', textOverflow: 'ellipsis'}}>{item?.split("__db__id__")[0]}</span>
                                                           {onViewIconClick && <span className='hover_view' onClick={()=>onViewIconClick(item?.split("__db__id__")[0])}><View height={"1rem"} size={"1rem"} color={"grey"} /></span>}
                                                        </span>
                                                    </li>
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                }


                                {!(sorted_display_items && sorted_display_items.length > 0) &&
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginTop: '30px'

                                        }}
                                    >No related fields found</div>
                                }

                            </ul>

                        </DisplayColumnData>
                    </React.Fragment>

                )}
            </React.Fragment>
            <Gap h="1.643rem" />
            <div>
                {!hidePrimeryBtn && (
                    <Button
                        primary={true}
                        width="6rem"
                        mR="16px"
                        onClick={() => {
                            setSelectedFields(tempSelectedVal);
                            if (setFilterDefaultValues) {
                                setFilterDefaultValues(tempFilterDefaultValues)
                            }

                            close(option, popupKey)
                        }}>{buttonText ? buttonText : 'Save'}</Button>
                )}



                {selectFilterValue && (
                    <Button
                        disabled={tempSelectedVal && tempSelectedVal.length > 0 ? false : true}
                        primary={true}
                        mR="16px"
                        bgColor="#176396"
                        onClick={() => {
                            setIsSelectFilterValue(!isSelectFilterValue);

                        }}>{buttonText ? buttonText : isSelectFilterValue ? "back" : 'Default Filter Value'}</Button>
                )}

                <Button
                    width="6rem"
                    bgColor={'#e6e6e6'}
                    onClick={() => {
                        close(option, popupKey)
                    }}>Cancel</Button>
            </div>

        </DisplayFiledWrapper>
    )
}
export default SelectDisplayField;
