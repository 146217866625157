import React, { useEffect, useState } from 'react'
import { Wrapper, QuestionSearchWrapper, BackButton } from './question.search.styled'
import FileUpload from '../file-upload';
import { connect } from 'react-redux';
import BackSvg from '../svg/back';

import {
    askQuestionToPdf, askQuestionToPdf_SECOND_API, get_uploaded_files_list, get_category_folder_list,
    setSelectedFile, mt__pdf_answer, clear_cashed, getJobsList, deleteConfigById, getSetOfQuestionAnswerByJobId
} from '../../actions/file.uploader';


import { getParameterByName, getPermissionById } from '../../utils'
import { Gap } from '../gap'
import TitleHeader from '../title-header';
import PdfSvg from '../svg/pdf';
import SearchSvg from '../svg/search'
import TextBox from '../hoc/textBox'
import { PageInnerWrapper } from '../hoc/styled.hoc'
import TabSlider from '../tab';
import PDFQuestions from '../pdf-questions';
import SharedTable from '../shared-table';
import FolderExplorer from '../folder-explorer';
import { withRouter } from 'react-router-dom';
import { dispatchHeaderInformation } from '../../actions/common';
import RelationShip from '../relationships';
import ImportRules from '../import-rules';
import ProcessDirectory from '../directory_upload';
import UploadGoogleDrive from './upload.google.drive';



let tabList = [
    "Upload Your File",

    "Ask Any Question",
    // "Define Relationships in Uploaded Files",
    "Set of Questions",
    "Process Directory",
    // "Set of Question Jobs",
]




function Index(props) {

    const {
        filesFromServer, answer, secondAnswer, selectedFile, setSelectedFile,
        jobsList, getJobsList, deleteConfigById, setOfAnswers,
        getSetOfQuestionAnswerByJobId, downloadData,
        history
    } = props;


    const { location } = history;

    const { pathname, search } = location;

    const [question, setQuestion] = useState('')
    const [localFiles, setLocalFiles] = useState(undefined)
    const [isExpand, setIsExpand] = useState(false);
    const [isFileUploadOpen, setFileUploadOpen] = useState(true)
    const [localAnswer, setLocalAnswer] = useState('')
    const [localSecondAnswer, setSecondAnswer] = useState('')
    const [isOpen, setIsOpen] = useState(undefined)
    const [localCompletePath, setCompletePath] = useState(undefined)
    const [searchValue, setSearchValue] = useState('')
    const [localSetOfAnswers, setLocalSetOfAnswers] = useState(undefined);
    const [disableTab, setDisableTab] = useState([])


    const [pageTitle, setPageTitle] = useState(undefined);
    const [localTablList, setLocalTabList] = useState(undefined);
    const [activePageType, setActivePageType] = useState(undefined);

    const [activeFolder, setActiveFolder] = useState(undefined)

    // <-------------------------active tab --------->
    // const [activeTab, setActiveTab] = useState(tabList[0])
    // const [activeTab, setActiveTab] = useState('Manage Your Files')
    const [activeTab, setActiveTab] = useState('Upload Your File')










    useEffect(() => {
        let _page_info = {
            title: 'TEXT ANALYTICS',
        };
        props.dispatchHeaderInformation(_page_info);
        props.get_uploaded_files_list();
        props.get_category_folder_list()
        setLocalTabList(tabList)

    }, [])


    // ?type=importData
    // here we are getting page type 

    useEffect(() => {
        //  window.reload()
        let type = getParameterByName('type', search);
        if (type) {
            setActivePageType(type)
        }
        if (type === 'importData') {

            setPageTitle("Import Data")

            // setLocalTabList(["Upload Your File", "Manage Your Files", "Define Relationships in Uploaded Files", "Import Rules"])

            setLocalTabList(["Upload Your File", "Upload Google Drive","Process Directory", "Manage Your Files"])

            setActiveFolder("importData")
            let _page_info = {
                title: 'Import Data',
            };
            props.dispatchHeaderInformation(_page_info);
        } else {
            setPageTitle(undefined)
            setActiveFolder(undefined)
            let _page_info = {
                title: 'TEXT ANALYTICS',
            };
            props.dispatchHeaderInformation(_page_info);
            setActiveTab(tabList[0])
            setLocalTabList(["Upload Your File", "Ask Any Question", "Set of Questions"])

        }
    }, [search])



    useEffect(() => {
        if (activeTab == tabList[3]) {
            getJobsList()
        }
    }, [activeTab])



    useEffect(() => {

        let short_array_bt_question = setOfAnswers && setOfAnswers.length > 0 && setOfAnswers.sort((a, b) => {
            if (a.question.localeCompare(b.question) > -1) {
                return 1
            }
            else {
                return -1
            }
        })

        let sorted_by_file_name = short_array_bt_question && short_array_bt_question.length > 0 && short_array_bt_question.sort((a, b) => {

            if ((a.file_name.localeCompare(b.file_name) > -1)) {
                return 1
            }
            else {
                return -1
            }

        })

        setLocalSetOfAnswers(sorted_by_file_name)
    }, [setOfAnswers]);


    useEffect(() => {
        _viewFile(selectedFile, "screen")
    }, [selectedFile])


    useEffect(() => {
        setLocalAnswer(answer)
    }, [answer])


    useEffect(() => {
        setSecondAnswer(secondAnswer)
    }, [secondAnswer])


    useEffect(() => {
        setLocalFiles(filesFromServer);
    }, [filesFromServer])



    const toggle_menu = () => {
        setIsExpand(!isExpand)
    }

    const _viewFile = (name, type) => {

        let _parent_path = localFiles && localFiles.parent_path;
        let _api_path = process.env.REACT_APP_MODE === 'development'
            ? process.env.REACT_APP_DEMO_API_ROOT_PATH
            : process.env.REACT_APP_DEMO_API_ROOT_PATH_PROD;

        let _static_path = 'static/uploads/'
        let complete_path = _api_path + _static_path + _parent_path + name;

        setCompletePath(complete_path)

        if (type === "window") {
            window.open(complete_path);
        }
    }



    const _extract_file_name = (path) => {

        if (path) {

            let temp_path = path.split('-');
            let temp_len = temp_path.length - 1;
            let _lastIndex = temp_path[temp_len].split('.');
            let _finalPath = temp_path.slice(0, temp_len).join('-') + "." + _lastIndex[_lastIndex.length - 1];

            return _finalPath
        }
        return null;
    }


    const _ask_questionToPdf = () => {
        let temp_file_name = localFiles.base_path + "/" + selectedFile;
        let data = {
            fileName: temp_file_name,  //"/home/ubuntu/alibaba.pdf",
            question: question
        }
        props.mt__pdf_answer()
        setLocalAnswer("");
        setSecondAnswer('')

        props.askQuestionToPdf(data);
        props.askQuestionToPdf_SECOND_API(data)
    }


    const toggle_view = () => {
        setIsOpen(!isOpen)
    }


    const user_id = props?.user?.id || props?.user?.user_id;
    const uploadGoogleDriveEnable = getPermissionById("upload_google_drive", props.menuPermissions);


    return (
        <Wrapper style={{ marginTop: '1rem' }}>

            {localTablList && <TabSlider hideShadow={true} cutOutSelection={true} overTabSelection={false} tabList={localTablList} activeType={activeTab} setActiveType={setActiveTab} />}

            <PageInnerWrapper height={'calc(100vh - 168px)'} padding={activeTab == 'Manage Your Files' ? "0px" : "20px"}>

                {activeTab === "Process Directory" && (
                    <ProcessDirectory setActiveTab={setActiveTab} />
                )}

                {activeTab === "Upload Google Drive" && (user_id === 'nf_kc2yq6inkc2yqpn2' || uploadGoogleDriveEnable) && (
                    <UploadGoogleDrive setActiveTab={setActiveTab} />
                )}

                {(activeTab == tabList[0]) && (
                    <div>
                        <React.Fragment>
                            <FileUpload
                                activePageType={activePageType}
                                _extract_file_name={_extract_file_name}
                                localFiles={localFiles}
                                setLocalFiles={setLocalFiles}
                                setLocalAnswer={setLocalAnswer}
                                setSecondAnswer={setSecondAnswer}
                                setActiveTab={setActiveTab}
                                tabList={tabList}
                                toggle_view={toggle_view}
                                _viewFile={_viewFile}
                                isOpen={isOpen}
                                clear_cashed={clear_cashed}
                                activeFolder={activeFolder}
                                setActiveFolder={setActiveFolder}
                                closeFileUpload={() => setFileUploadOpen(false)} />
                            <Gap h='2rem' />
                        </React.Fragment>
                    </div>
                )}



                {activeTab && activeTab == 'Manage Your Files' && (
                    <FolderExplorer />
                )}


                {activeTab && activeTab == 'Define Relationships in Uploaded Files' && (
                    <RelationShip />
                )}


                {activeTab && activeTab == 'Import Rules' && (
                    <ImportRules />
                )}



                <div>
                    {activeTab && activeTab === 'Ask Any Question' && (
                        <React.Fragment>
                            <QuestionSearchWrapper>
                                <Gap h="100px" />
                                <React.Fragment>
                                    <div className="big_file_name">
                                        <span style={{ marginRight: '10px', display: 'inline-block' }}>
                                            <PdfSvg size="1.271rem" height="1.514rem" />
                                        </span>
                                        <span
                                            className="select_file"
                                            onClick={() => {
                                                _viewFile(selectedFile, "window")
                                            }}
                                            title={localCompletePath ? localCompletePath : ''}

                                            style={{
                                                display: 'inline-block',
                                                maxWidth: '23rem',
                                                textOverflow: ' ellipsis',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                            }}> {_extract_file_name(selectedFile)}</span>
                                        <div
                                            onClick={() => {
                                                setActiveTab(tabList[0])
                                                setSelectedFile(undefined);
                                                setFileUploadOpen(true)
                                                setLocalAnswer('');
                                                setSecondAnswer('')
                                            }}
                                            className="select_new_file"
                                            title="select other file"
                                        >
                                            Select New File
                                        </div>

                                    </div>
                                    <Gap h="50px" />
                                    <div className="main">
                                        <div className="question_search">


                                            <TextBox
                                                label="Ask anything here to your file here"
                                                type="text"
                                                value={question}
                                                placeholder="Ask anything here to your file here"
                                                onChange={(e) => {
                                                    setQuestion(e.target.value)
                                                }}
                                                paddingRight={"50px"}
                                                onKeyDown={(event) => {
                                                    if (event.keyCode === 13) {
                                                        event.preventDefault();
                                                        // Trigger the button element with a click
                                                        _ask_questionToPdf(question)
                                                        // setQuestion('')
                                                    }
                                                }}
                                            />

                                            <div className="search_icon" title="search" onClick={() => {
                                                _ask_questionToPdf(question)
                                                setQuestion('')
                                            }}>
                                                <SearchSvg color="#1a75bb" size="1.2371rem" height="1.614rem" />
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            </QuestionSearchWrapper>
                            <Gap h="30px" />





                            {/* {(localAnswer || localSecondAnswer) && (
                                <p className='answer_heading'>Answer</p>
                            )} */}
                            <div style={{ display: 'flex', flexDirection: 'row' }}>

                                {(localSecondAnswer && localSecondAnswer.answer && typeof localSecondAnswer.answer === 'string') && (
                                    <div style={{ width: '100%', boxSizing: 'border-box', padding: '0 2rem' }} className="answer_scroll_div">
                                        <p style={{ fontSize: '1.2rem', margin: '0px', marginBottom: '1rem', color: '#333333' }}
                                            dangerouslySetInnerHTML={{ __html: `Answer : &nbsp; ${localSecondAnswer.answer}` }} />
                                    </div>
                                )}
                            </div>

                            <div>

                                {(localSecondAnswer && localSecondAnswer.answer && typeof localSecondAnswer.answer !== 'string') && Object.keys(localSecondAnswer.answer).map(k => {
                                    return <div style={{ width: '100%', boxSizing: 'border-box', padding: '0 2rem' }} className="answer_scroll_div">
                                        <p style={{ fontSize: '1.2rem', margin: '0px', marginBottom: '1rem', color: '#333333' }}
                                            dangerouslySetInnerHTML={{ __html: `${k} : &nbsp; ${localSecondAnswer.answer[k]}` }} />
                                    </div>

                                })
                                }
                            </div>

                        </React.Fragment>
                    )}







                    {activeTab && activeTab === "Set of Questions" && (
                        <React.Fragment>
                            {!localSetOfAnswers && (
                                <PDFQuestions />
                            )}

                            {localSetOfAnswers && (
                                <React.Fragment>
                                    <BackButton>
                                        <div className="back_btn"
                                            style={{ display: 'flex', alignItems: 'center', }}
                                            onClick={() => {
                                                setLocalSetOfAnswers(undefined)
                                            }}>
                                            <BackSvg size="2.5rem" height="1.3rem" color='#ccdde3' />
                                        </div>
                                    </BackButton>
                                    <SharedTable
                                        height={"calc(100vh - 320px)"}
                                        tableData={(localSetOfAnswers && localSetOfAnswers.length > 0) ? localSetOfAnswers : undefined}
                                        columnsToShow={['file_name', "question", "answer"]}
                                        tableHead={['FileName', "Question", "Answer"]}
                                        maximumWidthOfColumn={{
                                            "file_name": '8rem',
                                        }}
                                        removeDublicateField='file_name'
                                    />
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}






                    {/* {activeTab && activeTab === tabList[3] && (
                        <React.Fragment>
                            {!localSetOfAnswers && (
                                <SharedTable
                                    height={"calc(100vh - 320px)"}
                                    tableData={(jobsList && jobsList.length > 0) ? jobsList : undefined}
                                    columnsToShow={["id", "questions", 'files_name', 'created_at']}
                                    tableHead={["Job ID", "Questions", 'Files Name', 'Created At', 'Action']}
                                    isDateColumn={"created_at"}
                                    maximumWidthOfColumn={{
                                        "questions": '10rem',
                                        "files_name": '10rem',

                                    }}
                                    viewAction={(item) => {
                                        getSetOfQuestionAnswerByJobId(item)
                                    }}
                                    blank_dublicated={true}
                                    deleteAction={(item) => {
                                        deleteConfigById(item);
                                        setTimeout(() => {
                                            getJobsList()
                                        }, 100)
                                    }}

                                    downloadAction={(item, type) => {
                                        localDownload(item, type)
                                    }}
                                />
                            )}


                            {localSetOfAnswers && (
                                <React.Fragment>
                                    <BackButton>
                                        <div className="back_btn"
                                            style={{ display: 'flex', alignItems: 'center', }}
                                            onClick={() => {
                                                setLocalSetOfAnswers(undefined)
                                            }}>
                                            <BackSvg size="2.5rem" height="1.3rem" color='#ccdde3' />
                                        </div>
                                    </BackButton>
                                    <SharedTable
                                        height={"calc(100vh - 320px)"}
                                        tableData={(localSetOfAnswers && localSetOfAnswers.length > 0) ? localSetOfAnswers : undefined}
                                        columnsToShow={['file_name', "question", "answer"]}
                                        tableHead={['FileName', "Question", "Answer"]}
                                        maximumWidthOfColumn={{
                                            "file_name": '8rem',
                                        }}
                                        removeDublicateField='file_name'
                                    />
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )} */}
                </div>
            </PageInnerWrapper>
        </Wrapper >

    )
}




const mapStateToProps = (state) => ({

    data: state.fileUploaderReducer.data,
    filesFromServer: state.fileUploaderReducer.filesFromServer,
    type: state.fileUploaderReducer.type,
    isSingleFile: state.fileUploaderReducer.isSingleFile,
    answer: state.fileUploaderReducer.answer,
    secondAnswer: state.fileUploaderReducer.secondAnswer,
    selectedFile: state.fileUploaderReducer.selectedFile,
    jobsList: state.fileUploaderReducer.jobsList,
    setOfAnswers: state.fileUploaderReducer.setOfAnswers,
    downloadData: state.fileUploaderReducer.downloadData,
    folderFromServer: state.fileUploaderReducer.folderFromServer,
    menuPermissions: state.permissionReducer?.permissions


})
export default withRouter(connect(mapStateToProps, { dispatchHeaderInformation, askQuestionToPdf, askQuestionToPdf_SECOND_API, get_uploaded_files_list, get_category_folder_list, setSelectedFile, mt__pdf_answer, clear_cashed, getJobsList, deleteConfigById, getSetOfQuestionAnswerByJobId })(Index));