import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getInsightsMenu } from '../../actions/insights'
import styled from 'styled-components';
import  CheckBox  from '../hoc/checkbox';

const Wrapper = styled.div`
   width: 100%;
   box-sizing: border-box;
`;

const ListItem = styled.div`
    height: 30px;
    padding: 0px 10px;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    justify-content: flex-start;

    span{
        font-size: .9rem;
        cursor: pointer;
        width: calc(100% - 20px);
    }
`;

const DashboardOption = (props) => {

    const { insightsMenu, user } = props;
    const [localInsights, setInsights] = useState(undefined)

    useEffect(() => {
        if (user) props.getInsightsMenu(user.id)
    }, [])

    useEffect(() => {
        if (insightsMenu) {
            setInsights(insightsMenu)
        }
    }, [insightsMenu])


    return (
        <Wrapper>
            {
                localInsights && localInsights.length > 0 && localInsights.map((item, index) => {
                    return (
                        <ListItem key={"insight_item" + index}>
                            <CheckBox /> &nbsp;&nbsp;<span>{item.title}</span>
                        </ListItem>
                    )
                })
            }
        </Wrapper>
    )
}




const mapStateToProps = state => ({
    insightsMenu: state.insightReducer.insightsMenu
})

export default connect(mapStateToProps, { getInsightsMenu })(DashboardOption)
