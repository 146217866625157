// src/SearchBar.js
import React from "react";
import styled from "styled-components";
import SearchIcon from '../svg/search';

// border bottom
// border around N center align

const BORDER_TYPE = {
  'border-bottom':{
    'border-bottom': '1px solid #bcbaba',
  },
  border_radius:{
    'border-radius': '40px',
  },
  normal:{
    border: '1px solid #bcbaba',
  }
};

const SearchContainer = styled.div`
${props => props.borderType === 'border-bottom' ? 'border-bottom: 1px solid #bcbaba;' : 'border: 1px solid #bcbaba;'}
${props => props.borderType === 'border-bottom' ? 'border-radius: 0 ;' : `border-radius: ${ props.borderRadius || '40px' };`}
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  margin-left: 0.8rem;
  padding: 0 0.5rem 0 0.8rem;
  background: #fff;
  /* padding: 0.4rem; */
  width: 100%;
  max-width: 30rem;
  ${props => ({...props.style || {}})}
  &:focus-within {
    border-color: #337DFF; 
  }
  svg{
    margin: 0 0.8rem;
  }
`;


const SearchInput = styled.input`
  border: none;
  outline: none;
  flex: 1;
  padding: ${(props) => props.padding || '10px'};
  font-size: 1.2rem;
  border-radius: 14px;
  &::placeholder {
    color: #999999;
    opacity: 1;
    font-size: ${props => props?.style?.fontSize || '1.2rem'};
  }
`;

const SearchBar = ({
  onChange = () => {},
  onSearch = () => {},
  value,
  type,
  placeHolder,
  label,
  borderRadius, 
  padding,
  style,
  borderType='border-bottom'
}) => {
  return (
    <SearchContainer borderType={borderType} borderRadius={borderRadius} style={style}>
      {label && <label>{label}</label>}
      <SearchIcon
        size={style?.iconSize || '1.4rem'}
        height={style?.iconSize || '1.4rem'}
        color={'#a6a6a6'}
        onClick={onSearch}
      />
      <SearchInput onChange={onChange} value={value} type={type} placeholder={placeHolder || 'Search'} padding={padding} style={style} />
    </SearchContainer>
  );
};

export default SearchBar;