import * as actions from '../action-types/config';

const initialState = {
    type: actions.CONFIG_INIT,
    recordsCount: 0,
    configs: undefined,
    config: undefined,
    dbs: [],
    tables: {},
    columns: {},
    hints: undefined
};


const configReducer = (state, action) => {
	if (!state || typeof action.type === 'undefined') {
		return initialState;
    }

    

    // if (action && action.data && typeof action.data === 'object') {
    //     action.data.key = new Date();
    // }

    switch (action.type) {
        case actions.CONFIG_LIST_SUCCESS:
            return {
                type: action.type,
                configs: action.data,
                config: state.config,
                recordsCount: state.recordsCount,
                dbs: state.dbs,
                tables: state.tables,
                columns: state.columns
            };

        case actions.CONFIG_SAVE_SUCCESS:
            return {
                type: action.type,
                configs: action.data,
                config: state.config,
                recordsCount: state.recordsCount,
                dbs: state.dbs,
                tables: state.tables,
                columns: state.columns
            };

        case actions.CONFIG_SUCCESS:
            return {
                type: state.type,
                configs: state.configs,
                config: action.data,
                recordsCount: state.recordsCount,
                dbs: state.dbs,
                tables: state.tables,
                columns: state.columns
            };

        case actions.DB_SUCCESS:
            return {
                type: action.type,
                configs: state.configs,
                config: state.data,
                recordsCount: state.recordsCount,
                dbs: action.data,
                tables: state.tables,
                columns: state.columns
            };

        case actions.TABLE_SUCCESS:

            const existingTables = JSON.parse(JSON.stringify(state.tables));
            existingTables[action.data.db] = action.data.tables;
            existingTables.key = new Date();

            return {
                type: action.type,
                configs: state.configs,
                config: state.config,
                recordsCount: state.recordsCount,
                dbs: state.dbs,
                tables: existingTables,
                columns: state.columns
            };

        case actions.COLUMN_SUCCESS:
            const existingColumns = JSON.parse(JSON.stringify(state.columns));
            existingColumns[action.data.db + '-' + action.data.table] = action.data.columns;
            

            existingColumns.key = new Date();

            return {
                type: action.type,
                configs: state.configs,
                config: state.config,
                recordsCount: state.recordsCount,
                dbs: state.dbs,
                tables: state.tables,
                columns: existingColumns
            };

        case actions.CONFIG_INIT:
			return {
                type: action.type,
                configs: state.configs,
                config: state.config,
                recordsCount: state.recordsCount,
                dbs: state.dbs,
                tables: state.tables,
                columns: state.columns
            };

        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
			return {
                type: state.type,
                configs: state.configs,
                config: state.config,
                recordsCount: state.recordsCount,
                dbs: state.dbs,
                tables: state.tables,
                columns: state.columns,
                hints: state.hints
            };
        }
};

export default configReducer;