export const CONFIG_INIT = 'modules/config/init';
export const CONFIG_SAVE_REQUESTED = 'modules/config/save/request';
export const CONFIG_SAVE_SUCCESS = 'modules/config/save/success';
export const CONFIG_LIST_REQUESTED = 'modules/config/list/request';
export const CONFIG_LIST_SUCCESS = 'modules/config/list/success';

export const CONFIG_REQUESTED = 'modules/config/single/request';
export const CONFIG_SUCCESS = 'modules/config/single/success';


export const DB_REQUESTED = 'modules/config/DB/request';
export const DB_SUCCESS = 'modules/config/DB/success';


export const TABLE_REQUESTED = 'modules/config/table/request';
export const TABLE_SUCCESS = 'modules/config/table/success';


export const COLUMN_REQUESTED = 'modules/config/column/request';
export const COLUMN_SUCCESS = 'modules/config/column/success';

