
/***
 * title: this is a select-option component 
 * author: saroj kumar
 * date: 13 jan 2022
 * 
 * accept props =>
 * 
 *      select_option_style = string
 *              ["type1", "type2", "type3", "type4"]
 *      on_change = function
 *      value = string
 *      options = array
 *      value_key = string
 *      display_key = string
 *      label = string
 *          
 */


import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components'
import * as _ from 'lodash';


/***
 * here we have the style of the component 
 * 
 */


const ripple = keyframes`
  to{
       transform: scale(2.5);
       opacity: 0;
     }
 `;



const MainDiv = styled.div`
     width: 100%;
     margin-top: ${props => props.hide_margin ? "0px" : "15px"};
     position: relative;
     /* padding-top: 10px; */
     /* height: 100px; */
     .none_user_input{
         opacity: 0;
         width: 0px;
         height: 0px;
     }


     input{
         width: 100%;
         height: 2.6rem;
         border: 0px;
         border-radius: 6px;
         border: ${props => props.hide_border ? '0px' : props?.focused ? '2px solid #007FFF' : '1px solid #0000003b'};
         padding: 0px 10px;
         box-sizing: border-box;
         font-size: .9rem;
         font-weight: normal;
         color: #252526;
         background-color: #ecebeb;
         z-index: 2;
         /* box-shadow: ${props => props.enable_shadow ? "5px 2px 2px 2px #eaeaea" : undefined}; */
         background: #f3f3f3;
         border-color: transparent;
         box-shadow: #cccccc 0px 2px 2px 0px;

        &:hover{
            border: 1px solid #afc4e3;
        }
        &:focus{
            outline: none;
            border: ${props => props?.focused ? '2px solid #007FFF' : '1px solid #0000003b'};
        }
     }
     
     .value_txt{
         white-space: nowrap;
         text-overflow: ellipsis;
         font-size: .9rem;
         font-weight: normal;
         color: rgb(55, 74, 102);
     }
     .label_pre{
         white-space: nowrap;
         text-overflow: ellipsis;
         color: #464444;
         text-transform: capitalize;
         font-weight: 400;
         font-size: .9rem;
         line-height: 1.4375em;
         letter-spacing: 0.00938em;
         transition: all 0.2s;
         display: block;
         z-index: 1;
         ${props => props.labelStyle ? { ...props.labelStyle } : undefined}
         margin-bottom: 8px;
         font-weight: 600;

      }
      
 `;



const TextBoxV1 = (props) => {


    /**
     * here we are destructuring the props
     */

    const {

        value,
        defultValue,
        type,
        label,
        onChange,
        onFocus,
        onBlur,
        background,
        labelStyle,
        hide_case,
        error,
        autoFocus,
        hide_margin,
        hide_border,
        height,
        id,
        designType = "border",
        enable_shadow = "2px 1px 3px 2px #cccccc4d",
        inputStyle,
        labelBgColor

    } = props;


    /// State is here of the component

    const [focused, set_focused] = useState(undefined)

    const el_id = "input__id__uniqqq_auto_gen" + Math.random(100)
    const is_input_have_value = value && value.length > 0 || defultValue?.length > 0;
    const lable_class = "label_pre";

    const is_label_post = (focused || is_input_have_value)
    const label_style_temp = is_label_post ? (props.lablePostStyle || {}) : (props.lablePreStyle || {})
    const label_style = { ...(labelStyle || {}), ...label_style_temp }
    // 

    return (
        <MainDiv height={height} hide_border={hide_border} hide_margin={hide_margin}>

            {<span className={lable_class} >{!hide_case ? _.startCase(_.toLower(label)) : label}</span>}
            <input
                autoFocus={autoFocus}
                ref={props.ref_name}
                value={value}
                defultValue={defultValue}
                onFocus={(e) => {
                    if (onFocus) onFocus(e)
                    set_focused(true)
                }}
                onBlur={(e) => {
                    if (onBlur) onBlur(e)
                    set_focused(false)
                }}
                onChange={(e) => {
                    if(onChange){
                        onChange(e)
                    }
                }}
                onKeyDown={(e) => {
                    if (e && props.onKeyDown) {
                        props.onKeyDown(e)
                    }
                }}
                type={type || "text"}
                style={inputStyle}
            />

        </MainDiv>
    );
}
export default TextBoxV1;