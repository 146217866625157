import React, { useState, useEffect } from 'react';
import { SearchWrapper, SearchSuggestion } from './search-box.styled';
import SearchSvg from '../../svg/search';
import RecentSvg from '../../svg/recent';


const SearchBox = props => {

    const { checkArray, placeholder, FocusOnInput, hideFieldName, pressOnSuggest, sessionValue, setAnimationDefaultState } = props;

    const [suggestArray, setSuggestArray] = useState(undefined);
    const [inputVal, setInputVal] = useState('');
    const [showRecent, setShowRecent] = useState(false)
    const [showSuggestion, setShowShowSuggestion] = useState(false)
    const [recentArray, setRecentArray] = useState(undefined)


    const onChangeText = event => {

        setShowRecent(false)

        const value = event.target.value;

        setInputVal(value)
        if (value.length > 0) {

            const hints = checkArray && checkArray.filter((data, index) => {
                if (value === '') {
                    return true;
                }
                // else if (data.toLowerCase().startsWith(value.toLowerCase())) {
                //     return true;
                // }
                else if (data.toLowerCase()?.includes(value.toLowerCase())) {
                    return true;
                }
                // else if (data.toLowerCase().substring(0, valLen) === value.toLowerCase()) {
                //     return true;
                // }
                else {
                    return false
                }

            });

            setSuggestArray([...hints]);
            setShowShowSuggestion(true)
        }
        else {
            setSuggestArray(undefined)
        }

    }

    useEffect(() => {
        setRecentArray(sessionValue)
    }, [sessionValue])



    return (
        <div>
            <SearchWrapper
                isShow={
                    // (showRecent && recentArray && recentArray.length > 0) || 
                    (showSuggestion && suggestArray && suggestArray.length > 0)}
            >
                <div className="search">
                    <SearchSvg size="1rem" height="1rem" />
                </div>
                <input type="text"
                    placeholder={placeholder}
                    value={inputVal}
                    onChange={(event) => onChangeText(event)}

                    onBlur={(e) => {
                        setTimeout(() => {
                            setShowRecent(false)
                            setShowShowSuggestion(false)
                        }, 200);
                    }}

                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}

                    onFocus={(e) => {
                        FocusOnInput(hideFieldName);
                        setShowRecent(true)
                        e.preventDefault();
                        e.stopPropagation();
                    }}

                // onKeyDown={(e) => {
                //     let keyCode = e.keyCode;
                //     let value = e.target.value;
                //     if (keyCode === 40) {
                //     }
                // }}
                />


                {showSuggestion && suggestArray && suggestArray.length > 0 && (
                    <SearchSuggestion>
                        <ul>
                            {suggestArray.map((item, index) => {

                                // let searchIndex = inputVal && inputVal.length;
                                // let firstValue = item.substring(0, searchIndex)
                                // let lastValue = item.substring(searchIndex);

                                return (
                                    <li
                                        key={index}
                                        className="suggest_item"
                                        onClick={(event) => {
                                            pressOnSuggest(item);
                                            setTimeout(() => {
                                                setAnimationDefaultState('s2')
                                            }, 200);
                                            setInputVal('');
                                            setSuggestArray(undefined)
                                            setShowRecent(false)
                                            setShowShowSuggestion(false)
                                        }}
                                    >
                                        <span style={{ paddingLeft: '3.071rem' }}>
                                            {/* <span style={{ fontWeight: 'bold' }}>{firstValue}</span> */}
                                            {/* <span>{lastValue}</span> */}
                                            {item?.split("__db__id__")?.[0]}
                                        </span>
                                    </li>
                                )
                            })}
                        </ul>
                    </SearchSuggestion>
                )}



                {showRecent && recentArray && recentArray.length > 0 && (
                    <SearchSuggestion>
                        <ul>
                            {recentArray.map((item, index) => {
                                return (
                                    <li
                                        key={index}
                                        className="suggest_item"
                                        onClick={(event) => {
                                            pressOnSuggest(item);
                                            setTimeout(() => {
                                                setAnimationDefaultState('s2')
                                            }, 200);
                                            setInputVal('');
                                            setSuggestArray(undefined)
                                            setShowRecent(false)

                                        }}
                                    >
                                        {/* <span style={{ width: '3.071rem', display: 'inline-block', textAlign: 'center' }}>
                                            <RecentSvg height="1.143rem" size="1.143rem" />
                                        </span> */}
                                        <span style={{ paddingLeft: '1rem' }} >{item?.split("__db__id__")?.[0]}</span>
                                    </li>
                                )
                            })}
                        </ul>
                    </SearchSuggestion>
                )}
            </SearchWrapper>
        </div>
    )
}


export default SearchBox;
