import * as actions from '../action-types/etl';

const initialState = {
    type: actions.ETL_INIT,
    config: undefined,
    questionInfo: undefined,
    parent_jobs: undefined,
    currentConfigInfo: undefined,
    jobLogInfo: undefined,
    currentEditedJob: undefined,
    extractedTableData: undefined,
    targetTableNames: undefined,
    targetColumnNames: undefined,
    // targetTableData: undefined,
    validationData: undefined,
    collectionsOfMongo: undefined,
    mappingTableData: undefined,
    file_logs: undefined,



};


const EtlReducer = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        case actions.SAVE_ETL_CONFIG:
            return {
                ...state,
                type: action.type,
                config: action.data,
            };
        case actions.CLEAR_JOB_INFO:
            return {
                // ...state,
                extractedTableData: undefined,
                currentEditedJob: undefined,
                currentConfigInfo: undefined,
                questionInfo: undefined,
            }
        case actions.GET_ALL_INFO_OF_QUESTION:
            return {
                ...state,
                type: action.type,
                questionInfo: action.data
            }

        case actions.GET_CONFIG_REPORTS:
            return {
                ...state,
                type: action.type,
                parent_jobs: action.data
            }
        case actions.CLEAR_TAGET_AUTO_SUGGESTIONS:
            return {
                ...state,
                type: action.type,
                questionInfo: undefined,
            }

        case actions.GET_CONFIG_INFO_SUCCESS:
            return {
                ...state,
                type: action.type,
                currentConfigInfo: action.data
            }
        case actions.JOB_LOG_INFO_SUCCESS:
            return {
                ...state,
                type: action.type,
                jobLogInfo: action.data
            }
        case actions.UPDATE_MULTIPAL_CONFIG:
            return {
                ...state,
                currentEditedJob: undefined,
            }
        case actions.MT_JOB_LOG_INFO_SUCCESS:
            return {
                ...state,
                type: action.type,
                jobLogInfo: undefined
            }
        case actions.GET_TABLE_DATA_OF_EXTRACTED_COLUMN:
            return {
                ...state,
                type: action.type,
                extractedTableData: action.data,
            }

        case actions.CLEAR_TABLE_EXTRACTED_DATA:
            return {
                ...state,
                type: action.type,
                extractedTableData: undefined
            }
        case actions.PUSH_EDITED_ROW_TO_STORE:
            let payload = action.data;
            let existing_edited_row = state.currentEditedJob ? JSON.parse(JSON.stringify(state.currentEditedJob)) : {};
            existing_edited_row[payload.id] = payload
            return {
                ...state,
                type: action.type,
                currentEditedJob: existing_edited_row,

            }

        case actions.GET_TARGET_COLUMN_NAMES:

            let clone_targetColumnNames = state.targetColumnNames ? JSON.parse(JSON.stringify(state.targetColumnNames)) : {};
            let table_name = action.data.table_name;
            let columns = action.data.columns;
            let payloadObj = {
                [table_name]: columns
            }
            let temp_new_obj = Object.assign(clone_targetColumnNames, payloadObj)
            return {
                ...state,
                type: action.type,
                targetColumnNames: temp_new_obj
            }

        case actions.GET_TARGET_TABLE_NAMES:
            return {
                ...state,
                type: action.type,
                targetTableNames: action.data
            }
        case actions.CLEAR_QUESTION_INFO:
            return {
                ...state,
                type: action.type,
                questionInfo: undefined

            }
        case actions.GET_TARGET_TABLE_DATA:
            return {
                ...state,
                type: action.type,
                extractedTableData: action.data
            }
        case actions.CLEAR_TARGET_TABLE_DATA:
            return {
                ...state,
                type: action.type,
                extractedTableData: undefined
            }
        case actions.VALIDATE_THE_ETL_DATA:
            return {
                ...state,
                type: action.type,
                validationData: action.data
            }
        case actions.GET_COLLECTIONS_FROM_MONGO_BY_CLIENT:
            return {
                ...state,
                type: action.type,
                collectionsOfMongo: action.data
            }
        case actions.GET_COLLECTIONS_FROM_MONGO_BY_SOURCE_PATH:
            return {
                ...state,
                type: action.type,
                collectionsColumns: action.data
            }
        case actions.GET_THE_PATH_IN_FOLDER_SUCCESS:
            let _data = action.data && action.data.files ? action.data.files : []
            return {
                ...state,
                type: action.type,
                filesInfo: _data
            }
        case actions.GET_THE_FILE_DATA_FROM_FILE_SUCCESS:
            let file_data = action.data && action.data.file_data ? action.data.file_data : undefined
            return {
                ...state,
                type: action.type,
                fileData: file_data
            }

        case actions.PUSH_EDITED_SOURCE_MAPPING_ROW_TO_STORE:
            let _payload = action.data;
            let _index = _payload.index;
            let _row_data = _payload.data
            let _existing_edited_row = state.mappingTableData ? JSON.parse(JSON.stringify(state.mappingTableData)) : [];
            _existing_edited_row[_index] = _row_data
            return {
                ...state,
                type: action.type,
                mappingTableData: _existing_edited_row,
            }
        case actions.PUSH_SOURCE_MAPPING_DATA_TO_STORE:
            return {
                ...state,
                type: action.type,
                mappingTableData: action.data,
            }

        case actions.GET_ALL_FILES_LOGS:
            return {
                ...state,
                type: action.type,
                file_logs: action?.data?.file_logs
            }
        default: return {
            ...state
        }
    }
};

export default EtlReducer;