import { dispatchAction } from './helper';
import * as actionTypes from '../action-types/popup';
import { generateUniqueKey } from '../utils/index'

/**
 * 
 * @param {*} message 
 * @param {*} title 
 * @param {*} type enums.popupType
 */
export const raisePopup = (message, title, type, element, data, top, left) => dispatch => {
    dispatchAction(dispatch, actionTypes.POPUP_RAISE, {
        indexKey: generateUniqueKey('pop'),
        message,
        title,
        type,
        element,
        data, top, left
    }, undefined, undefined, 0);
};


/**
 * 
 * @param {*} optionClicked enums.popupButton
 */
export const popupClicked = (optionClicked, key) => dispatch => {
    dispatchAction(dispatch, actionTypes.POPUP_HIDE, { indexKey: key, optionClicked })
};

