import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { generate_auto_description } from '../../utils';
import { get_gpt_data_story } from '../../actions/report';
import { connect } from 'react-redux';
import Loading from '../loading'
import { dataStoryCreateV1 } from '../../utils/data.story.helper';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  margin: 20px auto;
  padding: 20px;
  width: 60vw;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 1s ease-out;
  max-height: 70vh;
  min-height: 30vh;
  overflow-y: auto;
  font-family: 'Lato', sans-serif;
  font-weight: 500 !important;

  h3{
    margin: 0px;
    font-size: 1rem;
    font-weight: 700;
  }
  ul {

    li{
      font-size: 1rem;
      line-height: 1.9rem;
      font-weight: 500;
      color: #333;
    }
  }
`;

const Title = styled.h1`
  font-size: 24px;
  color: #202124;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 5px;;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ListItem = styled.li`
  background-color: #ffffff;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  color: #5f6368;
  opacity: 0;
  animation: ${fadeIn} 0.5s ease-out forwards;
  animation-delay: ${({ index }) => index * 0.5}s;
`;

const DescriptionComponent = (props) => {


  const { data, gptDataStoryCache, } = props;

  const pivot_data = data?.pivot_data;
  const pivot_axis = data?.pivot_axis;
  const columnMeta = data?.columnMeta;

  const { raw_data, x, y, yac_totals} = data;

  const { file_path, id, } = data;
  const [description, setDescription] = useState(null);


  useEffect(() => {

    // const gptDataStory = gptDataStoryCache?.[id]
    // setDescription(gptDataStory);

  }, [gptDataStoryCache])


  console.log("columnMeta", columnMeta)

  useEffect(() => {

    // props.get_gpt_data_story(id, file_path)
    const stroy = dataStoryCreateV1(raw_data,x,y,yac_totals, columnMeta);
    console.log("stroy", stroy)
    setDescription(stroy);

  }, [file_path, id])




  return (
    <Container>

      <Title>Data Story</Title>
      {!description && <Loading v2Loader={true} msg={"Please wait while we prepare the story."} />}
      {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
      {/* {description && description.key_factor.map((factor, index) => (
                    <ListItem key={index} index={index}>
                        {factor}
                    </ListItem>
                ))} */}
      {/* </List> */}
    </Container>
  );
};




const mapStateToProps = (state, props) => {

  const gptDataStoryCache = state.reportReducer.gptDataStoryCache;

  return { gptDataStoryCache }
}


export default connect(mapStateToProps, { get_gpt_data_story })(DescriptionComponent);
