import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TextBoxV1 from '../hoc/text.box.v1';
import { Button } from '../hoc/button';
import { Radio, CheckBox } from '../hoc/checkbox';
import { NormalButton } from '../hoc/normal.button'
import AutoSearch from '../auto.search';
import SelectOptionV1 from '../select-option/select.option.v1.1';
import CloseSvg from '../svg/close'
import { constants } from '../../utils/constants';


const Wrapper = styled.div`

        position: fixed;
        top: 0;
        border: 0;
        margin: auto;
        left: 0;
        width: 65rem;
        border-radius: 6px;
        background: #fff;
        right: 0px;
        bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        /* padding: 1rem; */
        box-sizing: border-box;
        border: 2px solid #ccc;
        max-height: 35rem;
        height: 40rem;

        .flex_box{
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
        }

        .other_info{
            width: 100%;
            margin-left: 1rem;
        }

        .table_wrapper{
            overflow: auto;
            max-height: 25rem;
            width: 100%;

            &::-webkit-scrollbar {
                background-color:red;
                width:5px
            }

            &::-webkit-scrollbar-track {
                background-color:#b1cad5;
            }

            &::-webkit-scrollbar-thumb {
                background-color:#76919d;
                border-radius:0px;
                height: 5px;
            }
            &::-webkit-scrollbar:horizontal{
                background-color: green;
                height: 5px;
            }
        }


        h1{
            font-size: 1.286rem;
            font-weight: bold;
            line-height: 1.714rem;
            color: #1a1a1a;
            margin: 0px;
            display: block;
            position: relative;
            text-align: left;
            width: 100%;
        }

        
        table{
            border: 0px;
            width: 100%;
            border-collapse: collapse;

            thead{
                td{
                    color: #000;
                    font-weight: 600;
                    padding: .8rem 1rem;
                    background-color: #f2f2f2fc;
                    position: sticky;
                    top: 0px;
                    z-index: 2;
                }
            }

            td{
                padding: .8rem 1rem;
                font-size: .9rem;
                color: #000;
                border: 0px;
                font-weight: 600;
                border-bottom: 1px solid #e2e2e2;
            }
        }


   

`;

const ColumnMapping = (props) => {

    const { mappingData, json_file_path, table_name, target_db_info, db_infos } = props;

    const [local_column_data, set_local_column_data] = useState(undefined);
    const [foreign_keys, set_foreign_keys] = useState('');
    const [selected_foreign_key, set_selected_foreign_key] = useState('');
    const [foreign_key_value, set_foreign_key_value] = useState('');




    useEffect(() => {
        if (mappingData?.json_columns?.length > 0) {
            set_local_column_data(mappingData?.json_columns)
        }
        if (mappingData?.foreignKeys?.length > 0) {
            set_foreign_keys(mappingData?.foreignKeys)
        }
    }, [mappingData])



    const on_change = (value) => {

        const clone_data = local_column_data ? [...local_column_data] : [];

        if (clone_data?.indexOf(value) > -1) {
            clone_data.splice(clone_data.indexOf(value), 1);
        }
        else {
            clone_data.push(value);
        }
        set_local_column_data(clone_data);
    }



    const local_save = () => {
        props.saveRequiredColumnsForJson(json_file_path, table_name, local_column_data, selected_foreign_key, foreign_key_value)
    }


    const check_uncheck_all = () => {

        const db_columns = mappingData?.json_columns;

        if (db_columns?.length === local_column_data?.length) {
            set_local_column_data([])
        } else {
            set_local_column_data(db_columns)
        }


    }

    const db_type = (db_infos || []).find((d) => d.id === target_db_info)?.db_type

    return (
        <Wrapper>
            <div style={{ padding: '.5rem 1rem', boxSizing: 'border-box', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <img style={{ width: '70px' }} src={constants.CONTEXT_PATH + './finger.png'}></img>

                <h1>PLEASE CHOOSE REQUIRED ATTRIBUTES</h1>

                <div
                    className="dismiss"
                    title="Close"
                    onClick={() => {
                        props.close()
                    }}
                >
                    <CloseSvg color="#bbd1d9" size="1rem" height="1rem" />
                </div>
            </div>
            <div className='table_wrapper'>
                <table>
                    <thead>
                        <tr>
                            <td style={{ width: '60px', textAlign: 'center' }}>#</td>
                            <td>ATTRIBUTE NAME</td>
                            <td style={{ width: '170px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <CheckBox checked={local_column_data?.length === mappingData?.json_columns?.length}
                                        onClick={(e) => {
                                            check_uncheck_all()
                                        }} />&nbsp;&nbsp;
                                    IS REQUIRED
                                </div></td>
                        </tr>
                    </thead>
                    <tbody>
                        {mappingData?.json_columns?.length > 0 && mappingData?.json_columns.map((c, index) => {
                            return (
                                <tr>
                                    <td style={{ width: '60px', textAlign: 'center' }}>{index + 1}</td>
                                    <td>{c}</td>
                                    <td style={{ textAlign: 'center' }}>
                                        <CheckBox checked={local_column_data && local_column_data.indexOf(c) > -1}
                                            onClick={(e) => {
                                                on_change(c)
                                            }} />
                                        {/* <input type="checkbox" checked={local_column_data && local_column_data.indexOf(c) > -1} /> */}
                                    </td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
            </div>



            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', padding: '1rem 1.5rem', boxSizing: 'border-box' }}>
                <div style={{ width: '50%' }}>
                    {db_type === "mysql" && (
                        <React.Fragment>
                            <label className='__label' style={{ marginBottom: '10px' }}>Please Provide IdentiFier (IF ANY)</label>
                            <div style={{ width: '100%' }}>
                                <div style={{ width: '100%' }}>
                                    <AutoSearch
                                        fullBorder={true}
                                        bg_color={"#fff"}
                                        selections={foreign_keys && foreign_keys.length > 0 ? foreign_keys : []}

                                        onItemClick={(val) => {
                                            set_selected_foreign_key(val)

                                        }}

                                        setActiveValue={(value) => {
                                            set_selected_foreign_key(value)

                                        }}
                                        activeValue={selected_foreign_key}
                                        width="90%"
                                        height="2.857rem"
                                        lineHeight="2.857rem"
                                        border='1px solid #bbd1d9'
                                        color='#7c7c7c'
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>

                <div>
                    <NormalButton
                        height={'2.5rem'}
                        onClick={() => {
                            // close()
                            local_save()
                        }}
                        style={{ fontWeight: 'bold', border: '1px solid #000', borderRadius: '0px' }}
                        hoverBg={"#086f9d"}
                        label="SAVE & UPLOAD"
                        icon={<img src={constants.CONTEXT_PATH + './upload_sm.png'} style={{ width: '35px' }} />}
                    />
                </div>
            </div>




        </Wrapper >
    )
}

export default ColumnMapping