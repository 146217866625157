import React from "react";

const Delete = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      stroke={props.color ? props.color : "#cc0000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
    >
      <g fill="none">
        <path
          d="M112,112l20,320c.95,18.49,14.4,32,32,32H348c17.67,0,30.87-13.51,32-32l20-320"
          style={{
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "32px",
          }}
        />
        <line
          x1="80"
          y1="112"
          x2="432"
          y2="112"
          style={{
            strokeLinecap: "round",
            strokeMiterlimit: 10,
            strokeWidth: "32px",
          }}
        />
        <path
          d="M192,112V72h0a23.93,23.93,0,0,1,24-24h80a23.93,23.93,0,0,1,24,24h0v40"
          style={{
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "32px",
          }}
        />
        <line
          x1="256"
          y1="176"
          x2="256"
          y2="400"
          style={{
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "32px",
          }}
        />
        <line
          x1="184"
          y1="176"
          x2="192"
          y2="400"
          style={{
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "32px",
          }}
        />
        <line
          x1="328"
          y1="176"
          x2="320"
          y2="400"
          style={{
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "32px",
          }}
        />
      </g>
    </svg>
  );
};

export default Delete;
