import React, { useState, useEffect } from 'react'
import { PageInnerWrapper } from '../hoc/styled.hoc';
import { getParamByName, showPopup } from '../../utils';
import enums from '../../utils/enums';
import SharedTable from '../shared-table';
import { connect } from 'react-redux';
import { Button } from '../hoc/button';
import { saveRuleAlertInfo, getRulesAlertInfo, run_rule_job, get_all_rule_jobs, run_rule_job_forcfully, delete_rule_job, save_rule_job } from '../../actions/rules.alert'
import { withRouter } from 'react-router-dom';
import AddEditPopup from './rule.alert.add.edit';
import { getAccessGroup } from '../../actions/access_group';
import { getUsers } from '../../actions/account';
import PlaySvg from '../svg/play';
import TicketInfoPopup from './ticket.info.popup';




const Index = (props) => {

    const { rulesHints, getHintsForRules, tableColumnsHints, _hints_, history, ruleJobs } = props;

    const location = history?.location?.search;

    const [isLoding, setIsloding] = useState(false);

    const raiseAddEditPopup = (item) => {
        showPopup("Business Rule", undefined, enums.popupType.element_with_header, AddEditPopup, {
            popupData: item ? item : undefined,
            _saveFun: local_save,
            hintsData: _hints_,
        }, undefined, undefined, undefined, {
            width: '43rem',
        },
            true,
            true)

    }



    useEffect(() => {
        // props.getRulesAlertInfo()
        setIsloding(true)
        props.getAccessGroup()
        props.getUsers()
        props.get_all_rule_jobs()
    }, [])




    useEffect(() => {
        let _page_info = {
            title: 'Your Business Rules',
            extraComponent: <Button primary={true} onClick={() => {
                raiseAddEditPopup()
            }}>
                Add New
            </Button>
        }
        props.dispatchHeaderInformation(_page_info)

    }, [_hints_])

    useEffect(() => {
        if(ruleJobs?.length > -1) setIsloding(false);
    },[ruleJobs])



    const local_save = (data) => {

        const job_data = data;
        const rule_data = data?.rule_data || {};

        rule_data.frequency = data?.frequency_type;
        rule_data.rule_query = data?.rule_query;
        rule_data.name = data?.name;
        rule_data.alert_type = data?.alert_type;
        rule_data.assigned_to = data?.assigned_to;
        rule_data.assigned_to_name = data?.assigned_to_name;
        rule_data.recipients = data?.recipients;
        rule_data.view_rule_access = data?.view_rule_access;


        props.save_rule_job(job_data, rule_data)

    }

    const view_action = (item) => {

        const latest_ticket = item.latest_ticket;
        showPopup("Business Rule", undefined, enums.popupType.element_with_header, TicketInfoPopup, {
            popupData: latest_ticket ? latest_ticket : undefined,
            _saveFun: local_save,
            hintsData: _hints_,
        }, undefined, undefined, undefined, {
            width: '30rem',
        },
            undefined,
            true)

    }

    useEffect(() => {

        if (location) {
            const should_we_open = getParamByName(location, 'add_new');
            if (should_we_open) {
                raiseAddEditPopup()
            }
        }
    }, [location])

    const rules_alerts = props.rules_alerts_data?.rules;

    console.log("ruleJobs", ruleJobs)

    return (
        <div>
            <PageInnerWrapper padding={'5px'} style={{ marginTop: '1rem' }}>
                <SharedTable
                    isLoding={isLoding}
                    height={"calc(100vh - 180px)"}

                    editAction={(item) => {
                        raiseAddEditPopup(item)
                    }}

                    deleteAction={(item) => {
                        // alert("this is disabled for now.")
                        let tempItem = item ? Object.assign({}, item) : {}
                        tempItem.is_deleted = true;
                        props.delete_rule_job(item)
                    }}
                    processAction={(item) => {
                        // props.run_rule_job(item.id)
                        props.run_rule_job_forcfully(item.id)
                    }}

                    tableData={(ruleJobs && ruleJobs.length > 0) ? ruleJobs : undefined}
                    tableHead={["Rule Name", "Current Status", "Last Violation Date", "Aging", "Ticket#", "Assigned To", ""]}
                    columnsToShow={["name", "job_status", "last_ran_at", "aging", "latest_ticket_id", "assigned_to_name",]}
                    DateColumns={["last_ran_at"]}
                    fullTime={true}
                    column_header_styles={{
                        // backgroundColor: '#cccccc38',
                        textTransform: 'uppercase',
                        fontSize: '.8rem'

                    }}
                    maximumWidthOfColumn={{
                        "Current Status": '7rem'
                    }}


                    columns_width={{
                        "Rule Name": {
                            maxWidth: '15rem',
                            minWidth: '15rem',
                            width: '15rem'
                        },
                        "Current Status": {
                            maxWidth: '10rem',
                            // minWidth: '10rem',
                            width: '10rem'
                        },
                        "Last Violation Date": {
                            maxWidth: '13rem',
                            minWidth: '13rem',
                            width: '13rem'
                        },
                        "Aging": {
                            maxWidth: '12rem',
                            minWidth: '12rem',
                            width: '12rem'
                        },
                        "Ticket#": {
                            maxWidth: '12rem',
                            minWidth: '12rem',
                            width: '12rem'
                        },
                        "Assigned To": {
                            maxWidth: '10rem',
                            minWidth: '10rem',
                            width: '10rem'
                        },

                    }}


                    columnAction={{
                        "latest_ticket_id": view_action
                    }}
                    column_body_styles={{
                        "name": {
                            fontWeight: 'bold',
                            color: '#222',

                        },
                        "current_status": {
                            fontSize: '.8rem',
                            textTransform: 'uppercase',
                            color: '#353232',

                        },
                        "assigned_to_name": {
                            fontSize: '.8rem',
                            textTransform: 'capitalize',
                            color: '#353232',
                        },
                        "aging": {
                            color: '#353232',
                        },
                        "last_violation_date": {
                            color: '#353232',
                        },
                        "latest_ticket_id": {
                            fontSize: '.8rem',
                            textTransform: 'lowerCase',
                            textDecoration: 'underline',
                            color: '#1166a0',
                            // fontWeight: 'bold',
                            cursor: 'pointer',
                            color: '#353232',
                        }
                    }}


                    customActionIconAndStyle={
                        {
                            "processAction": {
                                icon: <PlaySvg size=".8rem" height=".8rem" color="green" />
                            }
                        }
                    }
                    actionsTitles={{
                        "processAction": {
                            title: "Proccess The Rule",
                            message: "Are you sure you want to Process The Rule?",
                        },
                        "editAction": {
                            title: "Edit The Rule",
                        },
                        "DeleteAction": {
                            title: "Delete The Rule",
                        },

                    }}
                    customHoverTitle={{
                        "name": '%name%,=,%rule_query%',
                        "latest_ticket_id": 'Open Ticket for ,%latest_ticket_id%'
                    }}
                    // actionOnHover={true}
                    none_even_odd_color={true}

                />
                {/* {rulesHints == undefined || rulesHints.length == 0 ? <p style={{ textAlign: "center" }}>No Record Found</p> : null} */}
            </PageInnerWrapper>

        </div>
    )

}



const mapStateToProps = (state) => ({
    rules_alerts_data: state.rulesAlert.rules_alerts_data,
    ruleJobs: state.rulesAlert.ruleJobs
})

export default withRouter(connect(mapStateToProps, {
    saveRuleAlertInfo,
    getRulesAlertInfo,
    getAccessGroup,
    getUsers,
    run_rule_job,
    get_all_rule_jobs,
    run_rule_job_forcfully,
    delete_rule_job,
    save_rule_job
})(Index))