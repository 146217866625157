import React, { useState, useEffect } from 'react'
import { MainWrapper } from './user.list.styled';
import { PageInnerWrapper } from '../hoc/styled.hoc';
import SharedTable from '../shared-table';
import { getUsers, save_user } from '../../actions/account';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { showPopup } from '../../utils';
import enums from '../../utils/enums';
import UserEditPopup from './user.edit.popup';
import { Button } from '../hoc/button'
import { getAccessGroup } from '../../actions/access_group';


const Index = (props) => {

    const { usersList, save_user } = props;

    const [localUsers, setLocalUsers] = useState(undefined)
    const [tableHeader, setTableHeader] = useState(undefined)
    const [tableKeys, setTableKeys] = useState(undefined)
    const [isLoding, setIsloding] = useState(true);



    useEffect(() => {
        props.getUsers()
        props.getAccessGroup()
    }, [])


    useEffect(() => {
        let _page_info = {
            title: 'Users',
            extraComponent: <Button primary={true} onClick={() => {
                raiseAddEditPopup()
            }}>
                Add New User
            </Button>
        }
        props.dispatchHeaderInformation(_page_info)


    }, [usersList])


    useEffect(() => {
        
        const temp_headers = ['id', 'name', 'email'];
        const temp_table_keys = ['id', 'name', 'email']

        if (usersList && usersList.client_info) {
            const client_info = usersList.client_info;
            const keys = client_info && Object.keys(client_info)
            const temp_users = (usersList.users ? [...usersList.users] : [])?.filter((u) => u.id !== 'nf_kc2yq6inkc2yqpn2');
            keys && keys.length > 0 && keys.map((key) => {
                let after_sp_key = key.split('_');
                let match_key = after_sp_key[0] + "_" + after_sp_key[1];
                if (client_info[key] !== null) {
                    temp_headers.push(client_info[key])
                    temp_table_keys.push(match_key)
                }
            })

            
            setLocalUsers(temp_users)
            temp_headers.push('Actions')
        }
        setTableKeys(temp_table_keys)
        setTableHeader(temp_headers)

        if (usersList?.users) {
            setIsloding(false);
        }

    }, [usersList])

    const raiseAddEditPopup = (item) => {
        showPopup(undefined, undefined, enums.popupType.element, UserEditPopup, {
            popupData: item ? item : undefined,
            save_user: save_user,
            display_field: usersList?.client_info
        })
    }

    const user_id = props?.user?.id || props?.user?.user_id;


    return (
        <MainWrapper>
            <PageInnerWrapper padding={'10px'} style={{ marginTop: '1rem' }}>
                <SharedTable
                    isLoding={isLoding}
                    height={"calc(100vh - 180px)"}
                    editAction={(user) => {
                        if (user.id === 'nf_kc2yq6inkc2yqpn2') {
                            if (user_id === 'nf_kc2yq6inkc2yqpn2') {
                                raiseAddEditPopup(user);
                            }
                            else {
                                alert("You can't edit the admin user")
                            }
                        }
                        else {
                            raiseAddEditPopup(user);
                        }
                        // if (user.id === 'nf_kc2yq6inkc2yqpn2') {

                        // }

                    }}
                    deleteAction={(user) => {
                        if (user.id === 'nf_kc2yq6inkc2yqpn2') {
                            // alert("")
                            alert("You can't delete the admin user")
                        } else {
                            const clone_user = user ? Object.assign({}, user) : {};
                            clone_user.deleted = true;
                            delete clone_user['email']
                            delete clone_user['name']
                            console.log("clone_user", clone_user);
                            save_user(clone_user)
                        }

                    }}
                    tableData={localUsers}
                    tableHead={tableHeader}
                    columnsToShow={tableKeys}
                    maximumWidthOfColumn={{
                        "idtr_1": '10rem',
                        "idtr_2": '10rem',
                        "idtr_3": '10rem',
                        "idtr_4": '10rem',
                        "idtr_5": '10rem',
                    }}
                />
                {/* {(localUsers == undefined || localUsers.length == 0) && !isLoding ? <p style={{ textAlign: "center" }}>No Record Found</p> : null} */}
            </PageInnerWrapper>

        </MainWrapper>
    )

}



const mapStateToProps = (state) => ({
    usersList: state.accountReducer.usersList
})

export default withRouter(connect(mapStateToProps, { getAccessGroup, getUsers, save_user })(Index))