import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SelectOptionOld from '../select-option'
import Checkbox from '../hoc/checkbox'
import TextBox from '../hoc/textBox'
import CloseSvg from '../svg/close';
import SelectOption from '../select-option/select.option.new';
import { connect } from 'react-redux';
import { CHART_TYPES, PIVOT_COLUMN_TYPE } from '../../shared-with-fe/constants';
import TextBoxV1 from '../hoc/text.box.v1';
import SelectOptionsV1 from '../select-option/select.option.v1.1';
import { ytd_mtd_options } from './builder.shared.functions';
import { constants } from "../../utils/constants";
import ToggleButton from '../hoc/toogle.btn';
import { Row } from './editor.styled';
import { NormalButton } from '../hoc/normal.button';


const ColumnInfoWrapper = styled.div`

        display: flex;
        flex-direction: column;
        min-height: 100%;
        border-top: 0px ;
        background-color: #fff;
        position: relative;
        border-radius: 8px;
        padding-bottom: 2rem;
        box-shadow: 0px 4px 8px -3px rgba(210,221,252,1);

        label{
            font-size: .68rem;
            color: #222;
            bottom: -6px;
            position: relative;
            text-transform: uppercase;
            font-weight: 600;
        }

        .transform{
             &::before{
                content: '';
                height: 1px;
                left: -11px;
                position: absolute;
                top: 2.6rem;
                width: 14px;
                background: #ccc;
            }
             &::after{
                content: '';
                height: 1px;
                right: -13px;
                position: absolute;
                top: 2.6rem;
                width: 15px;
                background: #ccc;
            }
        }

        .border_addon{
            position: relative;;

            &::before{
                content: '';
                height: 48%;
                border: 0.5px dashed #ccc;
                left: -11px;
                position: absolute;
                top: 2.6rem;
            }
             &::after{
                content: '';
                height: 48%;
                border: 0.5px dashed #ccc;
                right: -11px;
                position: absolute;
                top: 2.6rem;
            }
        }

        .t_values{
             &::before{
                 content: '';
                height: 1px;
                left: -11px;
                position: absolute;
                bottom: 1.7rem;
                width: 14px;
                background: #ccc;
            }
             &::after{
                content: '';
                height: 1px;
                right: -11px;
                position: absolute;
                bottom: 1.7rem;
                width: 14px;
                background: #ccc;
            }
        }

        

        .selected_column{
            margin: 1rem 0;
            background: #EFEFEF;
            /* border-bottom: 1px solid #909090; */
            padding: 15px 16px;
            h2{
                margin: 0px;
                font-size: .8rem;
                font-weight: 600;
                line-height: 1.2rem;
                span{
                    color: #222;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    display: block;
                    font-weight: 400;
                }
            }
        }

        .default_msg{   
            display: flex;
            flex-direction: column;
            /* padding: 2rem; */
            box-sizing: border-box;
            align-items: center;
            position: relative;
            top: 19rem;
            left: 0px;
            right: 0px;
            transform: translate(0%, -50%);
            height: 24rem;
            img{
                max-width: 3rem;
            }

            p{
                font-size: .9rem;
                text-transform: uppercase;
                font-weight: 600;
                color: #308bc8;
                margin-top: 0.5rem;
                line-height: 1.2rem;
            }
        }
        
        .label_of_input{
            font-size: .9rem;
            color: #374a66;
            text-transform: capitalize;
            margin-top: 0rem;
            margin-bottom: .5rem;
            font-weight: 600;
        }
        p{
            margin: auto;
            text-align: center;
            font-size: .8rem;
            color: #7a96c3;
            font-weight: 400;
            text-transform: capitalize;
        }
        .right_panel_header{
            display: flex;
            padding: 12px 16px;
            text-transform: capitalize;
            font-size: .9rem;
            position: relative;
            background: linear-gradient(to right, #3E9EFF, #737DFF);
            /* border: 1px dotted #adbdd5; */
            /* border-bottom-style: solid; */
            /* border-bottom-color: #ccc; */
            width: 101%;
            box-sizing: border-box;
            left: -1px;
            border-radius: 8px 8px 0 0;
          

        .header_close{
            position: absolute;
            right: 1rem;
            height: 100%;
            top: 0px;
            display: flex;
            align-items: center;
            cursor: pointer;

            svg{
                fill: #7a96c3;
            }
        }

        p{
            margin: 0px;
            text-overflow: ellipsis;
            overflow: hidden;
            font-weight: 600;
            font-size: .9rem;
            text-align: center;
            width: 90%;
            color: #fff;
        }

    }
    .row_box_right_side{
        width: 100%;
        margin-bottom: .8rem;
        display: flex;
        flex-direction: column;
        input{
            background: #fff;
        }
    }

    /* input:first-child{
        border: none;
        border-radius: 0px;
        background: #efefef;
        border-bottom:1px solid #bab8b8;
    } */
    .form-control_text_box{
        width: 100%;
        height:1.8rem;
        border: 0px;
        font-size: .9rem;
        font-weight: normal;
        color: #374a66;
        line-height: 1.7rem;

        &:focus{
            outline: none;
        }
    }

    .t_values{
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 10px;
    }

    .t_col{
        width: 48%;
        /* overflow: hidden; */
        margin-bottom: 10px;
    }

    fieldset{
        border: 1px solid #ccc;
    }
`;



const ColumnInfo = (props) => {

    const [transformation_types, set_transformation_types] = useState(undefined);
    const [unique_transformation_types, set_unique_transformation_types] = useState(undefined);
    const [current_connection_info, set_current_connection_info] = useState(undefined);


    const {
        column_proprty_on_change,
        setActiveColumn,
        activeColumn,
        index,
        height,
        marginTop,
        functions_config,
        render_mode,
        connectionId,
        db_infos,
        schema_table_info,
        is_etl,
        reporting_db_info_id
    } = props;



    const get_active_transformation_type_inputs = (name) => {
        const inputs = transformation_types.filter(f => f.db_function_name.toLocaleLowerCase() === name.toLocaleLowerCase() && f.param_order > 1).map(t => {
            return {
                param_order: t.param_order,
                param_name: t.param,
                param_type: t.param_type,
                id: t.id
            }

        });
        return inputs;
    };





    useEffect(() => {

        if (functions_config) {

            const current_connection = connectionId && props.db_infos?.length > 0 && props.db_infos.find((d) => d.id === connectionId[0]);

            const db_type = reporting_db_info_id && Array.isArray(reporting_db_info_id) && reporting_db_info_id.length > 1 ? 'hetro' : current_connection?.db_type;

            const mysql_configs = functions_config.filter(f => f.db_type === db_type);

            set_transformation_types(mysql_configs);
            const final_un_to_use = mysql_configs.filter(f => (typeof f.param_order === 'undefined' || f.param_order === 1))
            final_un_to_use.map(f => {
                if (f.function_category && f.function_category.indexOf('func()') == -1) {
                    f.function_category = f.function_category + " " + "func()"
                }
            });
            set_unique_transformation_types(final_un_to_use);
        }

    }, [functions_config, connectionId]);



    const active_t_type_inputs = (activeColumn && activeColumn.t_name) ? get_active_transformation_type_inputs(activeColumn.t_name) : [];

    const p_title = (activeColumn?.table_name ? activeColumn?.table_name + "." : '') + activeColumn?.orignal_name;

    const pivot_type = activeColumn?.pivot_type;

    const should_aggregation_show = (render_mode !== 'table' && pivot_type === 'pivot_y') ? true : (render_mode === 'table' || render_mode === 'single_cell') ? true : false;

    // console.log("render_mode", render_mode)
    const get_date_column_selection = (table_name) => {

        if (table_name && table_name?.length > 0) {
            const final_date_column = [];

            const current_connection = reporting_db_info_id || (connectionId?.length > 0 && props.db_infos?.length > 0 && props.db_infos.find((d) => d.id === connectionId[0]))?.id;

            // console.log("reporting_db_info_id", reporting_db_info_id, schema_table_info)

            const current_schema_tables = current_connection && schema_table_info?.[current_connection];

            const current_table = table_name && current_schema_tables?.[table_name];
            const date_column = current_table && Object.keys(current_table)?.length > 0 && Object.keys(current_table).filter((c) => (current_table[c]['type'] === 'date' || current_table[c]['type'] === "datetime"));
            (date_column || []).forEach((d) => {
                final_date_column.push(table_name + "." + d);
            })
            return final_date_column;
        }

        return [];

    }



    const is_number_data_type = (d_type) => {
        const num_data_type = ['int', 'float', 'double', 'number', 'currency'];
        if (!d_type) return false;
        else if (d_type && num_data_type.indexOf(d_type?.toLocaleLowerCase()) > -1) return true;
        else return false;
    }

    const is_aggregation_valid = (aggregation) => {

        const agg = ['sum', 'count', 'u_count', 'min', 'max', 'avg']
        if (!aggregation) return false;
        else if (aggregation && agg.indexOf(aggregation.toLocaleLowerCase()) > -1) return true;
        else return false;
    }


    const is_cached_db_selected = (reporting_db_info_id?.length > 0 && reporting_db_info_id.indexOf("__nf__db__cached__") > -1);

    const current_connection = connectionId && props?.db_infos?.length > 0 && props.db_infos.find((d) => d.id === connectionId[0]);

    const db_type = is_cached_db_selected ? (props?.cached_db_information?.db_type || "mysql") : current_connection?.db_type;

    const need_to_show_ytd_mtd = false //!activeColumn?.is_formula && db_type === 'mysql' && (is_number_data_type(activeColumn?.data_type) || is_aggregation_valid(activeColumn?.aggregation));

    return (
        <ColumnInfoWrapper style={{
            marginTop: marginTop ? marginTop : '1.5rem', marginBottom: '2rem', width: props.width || "25%", minHeight: '24rem'
        }}>

            <div className="right_panel_header">
                <p>Column Options </p>
            </div>

            {!activeColumn && <div
                className='default_msg'>
                <img src={constants.CONTEXT_PATH + "./select.png"} alt='Select img' />
                <p>
                    Select Column For <br></br>Aggregation AND <br></br>Transformation</p>
            </div>}

            {activeColumn && (
                <React.Fragment>

                    <div className='selected_column'>
                        <h2>Selected Column <br />
                            <span title={p_title} style={{ textTransform: 'capitalize' }}>{p_title}</span></h2>
                    </div>

                    <div style={{
                        width: '100%',
                        height: '100%',
                        padding: '15px 16px',
                        boxSizing: 'border-box'
                    }}>

                        {!is_etl && (
                            <div className="row_box_right_side" style={{ flexDirection: 'column', }}>
                                <label>Display Name</label>
                                <TextBoxV1
                                    type="text"
                                    designType="border"
                                    value={activeColumn.alias ? activeColumn.alias : ''}
                                    onChange={(e) => {
                                        column_proprty_on_change('alias', e.target.value, index)
                                    }}
                                // inputStyle={{
                                //     boxShadow: '2px 1px 3px 2px #cccccc4d'
                                // }}
                                />
                            </div>
                        )}

                        {is_etl && (
                            <div className="row_box_right_side" style={{ flexDirection: 'column', }}>
                                <label>Column Data Type</label>

                                <SelectOptionsV1
                                    options={[
                                        { label: 'String', value: 'string' },
                                        { label: 'Int', value: 'int' },
                                        { label: 'date', value: 'date' },
                                        { label: 'Currency', value: 'currency' },
                                        { label: 'Epoch', value: 'epoch' },
                                        { label: 'YYYY-MM-DD', value: 'yyyymmdd' },
                                        { label: 'Seconds', value: 'seconds' },
                                    ]}
                                    display_key="label"
                                    value_key="value"
                                    on_change={(value) => {
                                        column_proprty_on_change('target_data_type', value, index)
                                    }}
                                    value={activeColumn.target_data_type ? activeColumn.target_data_type : activeColumn.data_type || undefined}
                                    inputStyle={{
                                        boxShadow: '2px 1px 3px 2px #cccccc4d'
                                    }}
                                />
                            </div>
                        )}


                        {activeColumn && (!activeColumn.table_name) && !activeColumn.is_formula && (
                            <div className="row_box_right_side" style={{ flexDirection: 'column', }}>
                                <label>Column Value</label>
                                <TextBoxV1
                                    // label="Column Value"
                                    type="text"
                                    value={activeColumn.value ? activeColumn.value : ''}
                                    onChange={(e) => {
                                        column_proprty_on_change('value', e.target.value, index)
                                    }}
                                    inputStyle={{
                                        boxShadow: '2px 1px 3px 2px #cccccc4d'
                                    }}
                                    labelBgColor={""}
                                />
                            </div>
                        )}

                        {should_aggregation_show && (
                            <div className="row_box_right_side" style={{ flexDirection: 'column', }}>
                                <label>Aggregation</label>
                                <SelectOptionsV1
                                    // label="Aggregation"
                                    options={[
                                        { value: 'sum', label: 'Sum' },
                                        { value: 'count', label: 'Count' },
                                        { value: 'u_count', label: 'Unique Count' },
                                        { value: 'min', label: 'Min' },
                                        { value: 'max', label: 'Max' },
                                        { value: 'avg', label: 'Avg' }
                                    ]}
                                    display_key="label"
                                    value_key="value"
                                    on_change={(value) => {
                                        column_proprty_on_change('aggregation', value, index)
                                    }}
                                    value={activeColumn.aggregation ? activeColumn.aggregation : undefined}
                                    inputStyle={{
                                        boxShadow: '2px 1px 3px 2px #cccccc4d'
                                    }}
                                />
                            </div>


                        )}

                        {activeColumn && (activeColumn.table_name || activeColumn?.is_formula) && (
                            <div className={active_t_type_inputs?.length > 0 ? "row_box_right_side border_addon" : "row_box_right_side"}
                                style={{
                                    flexDirection: 'column',
                                    borderRadius: '3px',
                                    boxSizing: 'border-box'

                                }}
                            >
                                <label>Transform To</label>
                                <div className={active_t_type_inputs?.length > 0 ? "transform" : '--'}>
                                    <SelectOptionsV1
                                        width="100%"
                                        // label={"Transform To"}
                                        options={unique_transformation_types && unique_transformation_types.length > 0 ? unique_transformation_types : []}
                                        on_change={(value) => {
                                            column_proprty_on_change('t_name', value, index, 't_name')
                                        }}
                                        display_key={"identifier"}
                                        value_key={"db_function_name"}
                                        group_key='function_category'
                                        show_group_name={true}
                                        sort_key='desc'
                                        active_group_name={activeColumn.t_name ? activeColumn.t_name : undefined}
                                        value={activeColumn.t_name ? activeColumn.t_name : undefined}
                                        is_search={true}
                                        inputStyle={{
                                            boxShadow: '2px 1px 3px 2px #cccccc4d'
                                        }}
                                    />
                                </div>

                                {activeColumn && activeColumn.t_name && active_t_type_inputs?.length > 0 && (
                                    <div className="t_values">
                                        {active_t_type_inputs && active_t_type_inputs.length > 0 && active_t_type_inputs.map((t, j) => {

                                            const _v_key = `__trns__` + t.id;
                                            const _value = activeColumn && activeColumn?.['user_input_values']?.[_v_key];

                                            const w_d_t = active_t_type_inputs.length === 0 ? 100 : (100 / (active_t_type_inputs.length) - (active_t_type_inputs.length + 1));

                                            return (
                                                <div className="t_col" style={{ width: w_d_t + "%" }}>
                                                    <TextBoxV1
                                                        label={t?.param_name}
                                                        type="text"
                                                        is_required={t.is_required}
                                                        value={_value ? _value : ''}
                                                        onChange={(e) => {
                                                            column_proprty_on_change(_v_key, e.target.value, index, 't_name', true)
                                                        }}
                                                        inputStyle={{
                                                            boxShadow: '2px 1px 3px 2px #cccccc4d'
                                                        }}
                                                        labelBgColor="#f2f5fa"
                                                    />
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>
                        )}

                        {index === 0 && activeColumn?.pivot_type === "pivot_y" && (
                            <React.Fragment>
                                <div className="row_box_right_side" style={{ flexDirection: 'column', }}>
                                    <label>Select Chart Type</label>
                                    <div>
                                        <SelectOptionsV1
                                            // label={""}
                                            options={["bar_chart", "line_chart", "area_chart"]}
                                            on_change={(value) => {
                                                column_proprty_on_change('chart_type', value, index, 'chart_type')
                                            }}
                                            value={activeColumn.chart_type ? activeColumn.chart_type : undefined}
                                            inputStyle={{
                                                boxShadow: '2px 1px 3px 2px #cccccc4d'
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row_box_right_side" style={{ flexDirection: 'column', }}>
                                    <label>Select Chart Axis</label>
                                    <div>
                                        <SelectOptionsV1
                                            options={
                                                [
                                                    { value_key: "primary", display_key: "Primary (Left Y Axis)" },
                                                    { value_key: "secondary", display_key: "Secondary (Right Y Axis)" }
                                                ]
                                            }
                                            value_key="value_key"
                                            display_key="display_key"
                                            on_change={(value) => {
                                                column_proprty_on_change('axis', value, index, 'axis')
                                            }}
                                            value={activeColumn.axis ? activeColumn.axis : undefined}
                                            inputStyle={{
                                                boxShadow: '2px 1px 3px 2px #cccccc4d'
                                            }}
                                        />
                                    </div>
                                </div>

                            </React.Fragment>
                        )}

                        {need_to_show_ytd_mtd && (
                            <React.Fragment>
                                <div style={{ marginBottom: '.8rem' }}>
                                    <label>Choose YTD/MTD</label>
                                    <div>
                                        <SelectOptionsV1
                                            options={ytd_mtd_options}
                                            display_key='label'
                                            value_key='value'
                                            value={activeColumn?.ytd_mtd}
                                            on_change={(value) => {
                                                column_proprty_on_change('ytd_mtd', value, index)
                                            }}
                                            hide_case={true}
                                            is_search={true}
                                            inputStyle={{
                                                boxShadow: '2px 1px 3px 2px #cccccc4d'
                                            }}
                                        />
                                    </div>
                                </div>


                                {activeColumn?.ytd_mtd && get_date_column_selection(activeColumn?.table_name)?.length > 0 && (
                                    <div style={{ marginBottom: '.8rem' }}>
                                        <label>Choose Date Column</label>
                                        <div>
                                            <SelectOptionsV1
                                                // label={"Choose Date Column"}
                                                options={(get_date_column_selection(activeColumn?.table_name) || [])}
                                                on_change={(value) => {
                                                    column_proprty_on_change('ytd_mtd_date_column', value, index)
                                                }}
                                                value={activeColumn?.ytd_mtd_date_column ? activeColumn.ytd_mtd_date_column : undefined}
                                                inputStyle={{
                                                    boxShadow: '2px 1px 3px 2px #cccccc4d'
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </React.Fragment>
                        )}


                        {/* <div className="row_box_right_side" style={{ flexDirection: 'row', alignItems: 'center', marginBottom: '.8rem' }}>
                            <input
                                style={{ width: '16px', height: '16px' }}
                                type="checkbox"
                                checked={activeColumn?.is_hide}
                                onChange={() => {
                                    column_proprty_on_change('is_hide', !activeColumn?.is_hide, index, 'is_hide')
                                }}
                            />&nbsp;&nbsp;
                            <div style={{ color: 'rgba(0,0,0,0.6)', fontSize: '.9rem' }}>Hide Column</div>
                        </div> */}

                        {/* <Row style={{justifyContent: 'left', alignItems: 'center', marginLeft: '0.2rem'}}>
                            <ToggleButton size='medium' bothSide={true}
                                activeColor="linear-gradient(to right, #3E9EFF, #737DFF)"
                                checked={activeColumn?.is_hide}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    column_proprty_on_change('is_hide', !activeColumn?.is_hide, index, 'is_hide')
                                }}
                            />
                            <label  style={{marginLeft: '4px', textTransform: 'capitalize'}}>{activeColumn?.is_hide ? 'Show Column' : 'Hide Column'}</label>
                        </Row> */}

                        {/* {index === 0 && activeColumn?.pivot_type === "pivot_y" && (
                            <Row style={{justifyContent: 'left', alignItems: 'center', marginTop: '1rem', }}>
                                <ToggleButton size='medium' bothSide={true}
                                    activeColor="linear-gradient(to right, #3E9EFF, #737DFF)"
                                    checked={activeColumn?.is_shade}
                                    onChange={() => {
                                        column_proprty_on_change('is_shade', !activeColumn?.is_shade, index, 'is_shade')
                                    }}
                                />
                                <label  style={{marginLeft: '4px', textTransform: 'capitalize'}}>{activeColumn?.is_shade ? 'Hide Shade' : 'Show Shade'}</label>
                            </Row>
                            <React.Fragment>
                                <div className="row_box_right_side" style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <input
                                        style={{ width: '16px', height: '16px' }}
                                        type="checkbox"
                                        checked={activeColumn?.is_shade}
                                        onChange={() => {
                                            column_proprty_on_change('is_shade', !activeColumn?.is_shade, index, 'is_shade')
                                        }}
                                    />&nbsp;&nbsp;
                                    <div style={{ color: 'rgba(0,0,0,0.6)', fontSize: '.9rem' }}>Show Shade</div>
                                </div>
                            </React.Fragment>
                        )} */}

                        {/* column ytd mtd */}

                        {/* <Row style={{flexDirection: 'row', justifyContent: 'center', position: 'absolute', bottom: '22px', width: '100%'}}>
                            <Row style={{width: '50%', justifyContent: 'space-between'}}>
                                <NormalButton
                                    padding='.4rem .8rem'
                                    height='auto'
                                    bgColor='#fff'
                                    // hoverBg={'#FFF'}
                                    mR=".5rem"
                                    onClick={() => {  }}
                                    color="#374a66"
                                    label="CLEAR"
                                />
                                <NormalButton
                                    padding='.4rem .8rem'
                                    // primary={true}
                                    hoverBg={'#5a96ff'}
                                    bgColor={'red'}
                                    color="#fff"
                                    mR=".5rem"

                                    onClick={() => {
                                    }}
                                    iconStyle={{
                                        marginTop: '7px'
                                    }}
                                    label="SAVE"
                                />
                            </Row>
                        </Row> */}

                        <React.Fragment>
                            <div className="row_box_right_side" style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <input
                                    style={{ width: '16px', height: '16px' }}
                                    type="checkbox"
                                    checked={activeColumn?.is_no_aggregation}
                                    onChange={() => {
                                        column_proprty_on_change('is_no_aggregation', !activeColumn?.is_no_aggregation, index, 'is_no_aggregation')
                                    }}
                                />&nbsp;&nbsp;
                                <div style={{ color: 'rgba(0,0,0,0.6)', fontSize: '.9rem' }}>No Aggregation</div>
                            </div>
                        </React.Fragment>
                    </div>
                </React.Fragment>
            )}
        </ColumnInfoWrapper>
    )
}

const mapStateToProps = (state) => ({
    functions_config: state.reportReducer.functions_config,
    schema_table_info: state.reportBuilder.schema_table_info,
    cached_db_information: state.reportBuilder.cached_db_information,


})

export default connect(mapStateToProps, {})(ColumnInfo);