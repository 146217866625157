import React, { useEffect, useState } from "react";
import styled from "styled-components";

const SettingMainDiv = styled.div`
    position: absolute;
    padding:10px;
    height:auto;
    background-color: white;
    opacity: 0.93;
    z-index: 2;
    width:400px;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    border-radius:6px;
    right:1%;
    top: 40px;
    transition: all 0.3s ease-in;
    z-index: 3;
    
    .label{
        font-size:10px;
        padding:5px;
        margin-right:6px;
        margin-bottom:10px;
        text-decoration:none;
    };
    .checkbox {
        color:red;
        cursor: pointer;
        place-content:center; 
        
    }
    .checkbox ::before{
        background-color: yellow;
        transform:scale(1.3);
        box-shadow: inset 1em 1em var(--form-control-color);
    }
    .checkbox:hover {
        background: red;
    }
input[type=checkbox] {
    display: none;
    
}
input[type=checkbox] + label {
    display: inline-block;
    position: relative;
    padding: 8px;
    background-color: #adcfe2;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.7s ease;
    
}
.checked {
    background-color: #0b96ce!important;
    color: #f2f5f7;
}
input[type=checkbox]:checked + label:after {
    position: absolute;
    left: 4px;
    top: 0px;
    font-size: 10px;
} 

`

const Divider = styled.hr`
    width: 100%;    
    height: 1px;
    background-color: rgb(204,204,204);
    opacity: 0.3;
`



const SettingChart = (props) => {

    const [formFields, setFormFields] = React.useState([
        // {
        //     header: "Date Settings",
        //     fields: [
        //         {
        //             id: "MTD",
        //             label: "MTD",
        //             value: "MTD",
        //         },
        //         {
        //             id: "YTD",
        //             label: "YTD",
        //             value: "YTD",
        //         },
        //         {
        //             id: "QTD",
        //             label: "QTD",
        //             value: "QTD",
        //         },
        //     ]

        // },
        // {
        //     header: "Growth Analysis",
        //     fields: [
        //         {
        //             id: "YOY",
        //             label: "YOY",
        //             value: "YOY",
        //         },
        //         {
        //             id: "MOM",
        //             label: "MOM",
        //             value: "MOM",
        //         },
        //         {
        //             id: "QOQ",
        //             label: "QOQ",
        //             value: "QOQ",
        //         },
        //         {
        //             id: "WOW",
        //             label: "WOW",
        //             value: "WOW",
        //         },
        //     ]

        // },
        {
            header: "Data Labels",
            fields: [
                {
                    id: "none",
                    label: "NONE",
                },
                {
                    id: "sum",
                    label: "SUM",
                },
                {
                    id: "count",
                    label: "COUNT",
                },
                {
                    id: "avg",
                    label: "AVG",
                },
                {
                    id: "difference",
                    label: "DIFFERENCE",
                },
                {
                    id: "difference_percentage",
                    label: "PERCENTAGE DIFFERENCE",
                },
                {
                    id: "percentage",
                    label: "PERCENTAGE",
                },
            ]

        },
    ]);

    const [selected, setSelected] = useState({ ['Data Labels']: 'none' });

    useEffect(() => {
        setSelected(props.chart_option_selected_value);
    }, [props.chart_option_selected_value]);

    return (
        <SettingMainDiv>
            {
                formFields.map((h, i) => {
                    return (
                        <div><div
                            style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                marginBottom: "10px",
                            }}
                        >{h.header}</div>
                            {
                                h.fields.map((elem) => {
                                    return (<>
                                        <input className="checkbox" type="checkbox" id={elem.id} />
                                        <label className={`label ${selected && selected[h.header]===elem.id && 'checked'}`} onClick={() => props.close_box({[h.header] : elem.id })} >{elem.label}</label>
                                    </>)
                                })
                            }
                            {i !== formFields.length - 1 && <Divider />}
                        </div>)
                })
            }




            {/* <h3 className="chartSettingHeader">Header</h3>
            <input className="checkBox" type="checkbox" id="YTD" value="MTD" />
            <label className="checkBox" for='YTD'>YTD</label>
            <input className="checkBox" type="checkbox" id="MTD" value="MTD" />
            <label className="checkBox" for='MTD'>MTD</label>
            <input className="checkBox" type="checkbox" id="QTD" value="MTD" />
            <label className="checkBox" for='QTD'>QTD</label>
            <h3 className="chartSettingHeader">Header2</h3>
            <input className="checkBox" type="checkbox" id="YOY" value="MTD" />
            <label className="checkBox" for='YOY'>YOY</label>
            <input className="checkBox" type="checkbox" id="MOM" value="MTD" />
            <label className="checkBox" for='MOM'>MOM</label>
            <input className="checkBox" type="checkbox" id="QOQ" value="MTD" />
            <label className="checkBox" for='QOQ'>QOQ</label>
            <input className="checkBox" type="checkbox" id="WOW" value="MTD" />
            <label className="checkBox" for='WOW'>WOW</label>
            <h3 className="chartSettingHeader">Header3</h3>
            <input className="checkBox" type="checkbox" id="SUM" value="MTD" />
            <label className="checkBox" for='SUM'>SUM</label>
            <input className="checkBox" type="checkbox" id="COUNT" value="MTD" />
            <label className="checkBox" for='COUNT'>COUNT</label>
            <input className="checkBox" type="checkbox" id="QOQ" value="MTD" />
            <label className="checkBox" for='QOQ'>QOQ</label>
            <input className="checkBox" type="checkbox" id="WOW" value="MTD" />
            <label className="checkBox" for='WOW'>WOW</label> */}
        </SettingMainDiv>
    )
}

export default SettingChart;