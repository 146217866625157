import React from 'react';
import { InsightBox } from './insights.styled';
import { DataFormatter, formatValueByDataType, apply_styles, getRemToPx, getPxToRem } from '../../../utils'
import styled from 'styled-components';
import { constants } from '../../../utils/constants';
import TrendDown from '../../svg/dropDown';
import TrendUp from '../../svg/dropDownUp'
import ChartFilterPopup from '../../chart-filter-popup'
import enums from '../../../utils/enums';
import { showPopup } from '../../../utils';
import { check_is_condition_matched } from '../../../utils/v1.1';
import { beautify_cell_data_v1 } from '../../table/shared/helper';


export const SingleDataWrapper = styled.div`
        display: flex;
        justify-content: center;
        height: 100%;
        width: 100%;
        padding: 0px 5px;
        flex-direction: column;
        ${props => apply_styles(props.theme, constants.STYLED_COMPONENTS.COLORS)};
        box-sizing: border-box;

        .trend{
            display: inline-block;
            svg{
                ${props => apply_styles(props.theme.trend, constants.STYLED_COMPONENTS.ALL)};
            }
        }

        .arrow-up {
            width: 0; 
            height: 0; 
            ${props => apply_styles(props.theme.trend, constants.STYLED_COMPONENTS.FONT)};
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid green;
            display: inline-block;
         }

        .arrow-down {
            width: 0; 
            height: 0; 
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #f00;
            display: inline-block;
        }
        

        .arrow-right {
            width: 0; 
            height: 0; 
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 10px solid green;
            display: inline-block;
        }

        .arrow-left {
            width: 0; 
            height: 0; 
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent; 
            border-right:10px solid blue; 
            display: inline-block;
        }
        .value{
            ${props => apply_styles(props.theme, constants.STYLED_COMPONENTS.FONT)};
            /* font-size: ${props => props?.finalFontSize} */
            font-size: ${props => `clamp(0.5rem, ${props.theme?.font?.size}, ${props.finalHeight || '16'}px)`};
            line-height: ${props => `clamp(1rem, ${props.theme?.font?.line_height}, ${parseFloat(props.finalHeight || '16') * 1.2}px)`};
            /* line-height: 2rem; */
        }
        .inside_value{
            display: inline-block;
            ${props => apply_styles(props.theme, constants.STYLED_COMPONENTS.MARGIN)};
        }

`;




const isFloat = (num) => {
    return Number(num) === num && num % 1 !== 0;
}

export const get_trangle_class_by_value = (value) => {
    let temp_value = parseFloat(value);
    if (temp_value > 0) {
        return <TrendUp color="green" />
    }
    if (temp_value < 0) {
        return <TrendDown color="#f00" />
    }
}




const SingleData = (props) => {

    const {

        hide_border,
        theme,
        singleData,
        title,
        yDataKeys = [],
        xDataKeys = [],
        columnsAligments,
        show_trend,
        drill_down_report,
        drill_down_type,
        insight_id,
        id,
        format_setting,
        view_mode,
        od_report,
        finalHeight
    } = props
    const is_expanded = view_mode === 'expanded';
    const expanded_color = is_expanded ? '#000' : undefined;

    // const heightOfCell = singleData && typeof singleData === 'object' && Object.keys(singleData)?.length > 1 ? finalHeight/(Object.keys(singleData)?.length) : finalHeight;

    // const fontSize = props.theme?.font?.size;
    // const font_size = fontSize?.includes("rem") ? getRemToPx(fontSize?.split('rem')?.[0]) : fontSize?.split('px')?.[0] ;
    // const finalFontSize = (font_size < heightOfCell ? font_size : heightOfCell);
    // const fontToUSe = getPxToRem(finalFontSize)+"rem"

    const open_drill_popup = (event, dimension, dimension_value, comparison, comparison_value, info_wrapper) => {

        // const options_required_data = get_required_attributes_for_chart_click_option(event, yDataKeys, payload, xDataKeys, props.columnMeta, undefined, undefined, undefined, pivot_data_columns)

        if (props?.report_item?.reporting_db_info_id && props?.report_item?.reporting_db_info_id.indexOf("__nf__db__cached__") - 1) return false;

        if (process.env.REACT_APP_MODE === "barc") return true;


        const popup_width = ((info_wrapper?.drill_down_type && info_wrapper?.drill_down_report) ? 430 : (360 - 70));
        let popup_top = event?.clientY - 100;
        const popup_left = event?.clientX - (popup_width / 2);
        const popup_left_to_use = popup_left < 0 ? 0 : popup_left;

        showPopup(undefined, undefined, enums.popupType.element, ChartFilterPopup, {
            question: props.question,
            dimension,
            dimension_value,
            drill_down_values: props.drill_down_values,
            comparison,
            comparison_value,
            is_single_cell: true,
            ...info_wrapper,
            _id: id,
            insight_id: insight_id,
            insights: true,
            containerWidth: popup_width,
            od_report: od_report
        },
            popup_top + "px",
            popup_left_to_use + "px",
            "#0000"
        )
    }

    const condition_formating_data = beautify_cell_data_v1(format_setting, yDataKeys, singleData);

    if (singleData && typeof singleData === 'object') {
        const keys = (xDataKeys.concat(yDataKeys) || Object.keys(singleData)).filter(f => {
            return f.toLocaleLowerCase() !== 'value'
        });


        const data = singleData[keys[0]];
        let aligment = columnsAligments && columnsAligments[keys[0]];
        let type = aligment && aligment.type;


        const cell_aligment_type = aligment?.['alignment'] //(aligment_of_column && Object.keys(aligment_of_column).length > 0) ? aligment_of_column['alignment'] : undefined;
        const cell_data_type = aligment?.['type'] // (aligment_of_column && Object.keys(aligment_of_column).length > 0) ? aligment_of_column['type'] : undefined;
        const currency_type = aligment?.['currency_type'];
        const num_format_type = aligment?.['num_format_type'];
        const use_decimal = aligment?.['use_decimal'] || 2;
        const use_percent = aligment?.['use_percent'];


        if (keys.length > 1) {
            return (
                <SingleDataWrapper
                    style={{ cursor: 'pointer' }}
                    theme={theme}
                    finalHeight={finalHeight / keys?.length}
                    // finalFontSize={fontToUSe}  
                    hide_border={hide_border}
                    onClick={(event) => {
                        let dimension = keys[0];
                        let fact = keys[1];
                        let current_key = singleData[dimension];

                        const filter_key = dimension ? props.columnMeta?.[dimension]?.db_column : undefined;
                        let data_type_db = props.columnMeta?.[dimension]?.db_data_type || 5;

                        const final_column_to_use = filter_key + "__nfx__db__" + data_type_db;

                        const filter_data = {
                            [final_column_to_use]: { key: dimension, value: current_key }
                        };
                        // console.log("filter_data", props.columnMeta)
                        open_drill_popup(event, dimension, current_key, undefined, undefined, {
                            dimension,
                            fact,
                            filter_key,
                            current_key,
                            drill_down_report,
                            drill_down_type,
                            filter_data
                        });
                    }}
                >

                    {keys && keys.length > 0 && keys.map((key, index) => {

                        let __key__ = key && typeof key === 'string' && key.length > 0 && key.indexOf('_nfx_') ? key.split('_nfx_')[1] : key;
                        let _aligment = columnsAligments && columnsAligments[key];
                        let _type = _aligment && _aligment.type;
                        const _data = singleData?.[key];
                        const _currency_type = _aligment?.['currency_type'];
                        const _num_format_type = _aligment?.['num_format_type'];
                        const _use_decimal = _aligment?.['use_decimal'] || 2;
                        const _use_percent = _aligment?.['use_percent'];


                        const d = _data !== 'undefined' ? isFloat(_data) ? parseFloat(_data).toFixed(2) : _data : '';
                        return (
                            <div key={index} style={{ width: '100%', textAlign: 'center' }}>
                                <div className="value">
                                    {show_trend ? <div className={"trend"}>
                                        {get_trangle_class_by_value(d)}
                                    </div> : null}
                                    <span style={{ color: expanded_color || condition_formating_data?.[key]?.data?.font_color }}>
                                        {formatValueByDataType(d, _type, 'table', undefined, undefined, undefined, _currency_type, _num_format_type, _use_decimal, _use_percent)}
                                    </span>
                                    {/* {formatValueByDataType(d, _type, undefined, undefined, undefined, 'insights')} */}
                                </div>
                            </div>
                        )
                    })}
                </SingleDataWrapper>
            )
        }
        else {
            const d = isFloat(data) ? parseFloat(data).toFixed(2) : data;
            let dimension = keys[0];
            let fact = keys[1];
            let current_key = singleData[dimension];

            const filter_key = dimension ? props.columnMeta?.[dimension]?.db_column : undefined;
            let data_type_db = props.columnMeta?.[dimension]?.db_data_type || 5;

            const final_column_to_use = filter_key + "__nfx__db__" + data_type_db;

            const filter_data = {
                [final_column_to_use]: { key: dimension, value: current_key }
            };
            return (
                <SingleDataWrapper
                    theme={theme}
                    hide_border={hide_border}
                    // finalFontSize={fontToUSe}
                    finalHeight={finalHeight}
                >
                    <div
                        onClick={(event) => {
                            open_drill_popup(event, dimension, current_key, undefined, undefined, {
                            fact,
                            dimension,
                            filter_key,
                            current_key,
                            drill_down_report,
                            drill_down_type,
                            filter_data
                            })
                        }}
                        className="value"
                        style={{ cursor: 'pointer', color: expanded_color || condition_formating_data?.[keys?.[0]]?.data?.font_color || undefined}}
                        // title={formatValueByDataType(d, type, undefined, undefined, undefined, 'insights')}
                        title={d}
                    >
                        {show_trend ? <div className={"trend"}>
                            {get_trangle_class_by_value(d)}
                        </div> : null}
                        <div className={"inside_value"}>
                            {formatValueByDataType(d, type, 'table', undefined, undefined, undefined, currency_type, num_format_type, use_decimal, use_percent)}
                            {/* {formatValueByDataType(d, type, undefined, undefined, undefined, 'insights')} */}
                        </div>
                    </div>
                </SingleDataWrapper>
            )
        }
    }
    else {
        // const  isConditionSatisfied = check_is_condition_matched( conditionFortating?.condition, singleData, conditionFortating?.value, conditionFortating?.value2)
        return (
            <InsightBox>
                <div className='title' >{title}</div>
                <div className='data'
                style={{ color: expanded_color || condition_formating_data?.font_color || undefined}}
                >{DataFormatter(singleData)}</div>
            </InsightBox>
        )
    }
};

export default SingleData;
