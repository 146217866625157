import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TextBoxV1 from '../hoc/text.box.v1';
import { Button } from '../hoc/button';
import DeleteSvg from '../svg/delete'
import AutoSearch from '../auto.search';
import CloseSvg from '../svg/close'
import { constants } from '../../utils/constants';
import { NormalButton } from '../hoc/normal.button';


const Wrapper = styled.div`

        overflow: auto;
        max-height: ${props => props.maxHeight || '25rem'};
        width: 100%;
        min-height: ${props => props.minHeight || '30rem'};

        &::-webkit-scrollbar {
            background-color:red;
            width:5px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }

        .required{
            font-size: 12px;
            /* color: #444; */
        }
        .action_btn{
            width: 1.4rem;
            height: 1.4rem;
            /* border: 1px solid #ccc; */
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background: #e2e2e2;
            border-radius: 4px;;
            box-sizing: border-box;
        }

        
        table{
            
            border: 0px;
            width: 100%;
            border-collapse: collapse;
            border: 1px solid #e2e2e2;

            thead{
                td{
                    color: #fff;
                    font-weight: 600;
                    padding: .8rem 1rem;
                    background-color: #3D8EF5;
                    position: sticky;
                    top: 0px;
                    z-index: 2;
                }
            }

            td{
                padding: 0rem 1rem;
                font-size: .9rem;
                color: #000;
                border: 0px;
                font-weight: 600;
                border-bottom: 1px solid #e2e2e2;

                input{
                    padding: 1rem 0rem;
                    border: 0px;;
                    width: 100%;
                    box-sizing: border-box;
                    font-size: .9rem;
                     
                }
            }
        }



`;



const TextFileColumnMapping = (props) => {

    const { on_change, delete_row, mapping_data, db_column_hints, minHeight, maxHeight, showExampleColumn = false, showPositionColum = false } = props;


    return (

        <Wrapper minHeight={minHeight} maxHeight={maxHeight}>
            <table>
                <thead>
                    <tr>
                        <td>#</td>
                        <td>ATTRIBUTE <span className='required'>(required)</span></td>
                        {/* <td>COLUMN NAME <span className='required'>(required)</span></td> */}
                        {showPositionColum && <td style={{ width: '50px' }}>POSITION</td>}
                        {showExampleColumn && <td>VALUE</td>}
                        {/* <td style={{ width: '100px' }}>SEPARATOR</td> */}
                        <td>ACTION</td>

                    </tr>
                </thead>
                <tbody>
                    {mapping_data && mapping_data?.length > 0 && mapping_data.map((row, index) => {
                        return (
                            <React.Fragment>
                                {!row?.deleted && (
                                    <tr>
                                        <td>&nbsp;&nbsp;{index + 1}</td>
                                        <td>

                                            <input
                                                type="text"
                                                value={row?.["column"]}
                                                onChange={(e) => {
                                                    on_change(index, "column", e.target.value)
                                                }}
                                            />
                                        </td>
                                        {showPositionColum && <td>{row?.position}</td>}
                                        {showExampleColumn && <td>{row?.sample_value}</td>}
                                        {/* <td >

                                    <AutoSearch
                                        selections={db_column_hints && db_column_hints.length > 0 ? db_column_hints : []}
                                        borderNone={true}
                                        bg_color={"#fff"}
                                        onItemClick={(val) => {
                                            on_change(index, "db_column", val)
                                        }}

                                        setActiveValue={(val) => {
                                            on_change(index, "db_column", val)
                                        }}
                                        activeValue={row?.["db_column"] || " "}
                                        width="100%"
                                        color='#7c7c7c'
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        value={row?.["position"] || ''}
                                        onChange={(e) => {
                                            on_change(index, "position", e.target.value)
                                        }}
                                    />
                                </td>
                                <td>
                                    <input type="text"
                                        value={row?.["demilited_by"] || ''}
                                        onChange={(e) => {
                                            on_change(index, "demilited_by", e.target.value)
                                        }}
                                    />
                                </td> */}
                                        <td>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}>
                                                {mapping_data?.length > 1 && (
                                                    <div className="action_btn"
                                                        title="Delete"
                                                        style={{ backgroundColor: 'red' }}
                                                        onClick={() => {
                                                            delete_row(index)
                                                        }}>
                                                        <DeleteSvg size=".8rem" color="#fff" />
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        )
                    })}

                </tbody>
            </table>
        </Wrapper>

    )
}

export default TextFileColumnMapping