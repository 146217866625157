

export const quick_date_options = [
    { value: 'today', label: 'Today', },
    { value: 'yesterday', label: 'Yesterday', },
    { value: 'dby', label: 'DBY', },
    { value: 'cm', label: 'Current Month', },
    { value: 'cy', label: 'Current Year', },
    { value: 'ytd', label: 'Current Year-to-Date', },
    { value: 'fytd', label: 'Current Financial Year-to-Date', },
    { value: 'qtd', label: 'Current Quarter-to-Date', },
    { value: 'mtd', label: 'Current Month-to-Date', },
    { value: 'lm', label: 'Last Month', },
    { value: 'ly', label: 'Last Year', },
    { value: 'lytd', label: 'Last Year-to-Date', },
    { value: 'lfytd', label: 'Last Financial Year-to-Date', },
    { value: 'lmtd', label: 'Last Month-to-Date', },
    { value: 'lymtd', label: 'Last Year Month-to-Date', },
    { value: 'lylmtd', label: 'LY LM To Date', },
    { value: 'ftd', label: 'By FTD', },
    { value: 'lyqtd', label: 'By LYQTD', },
    { value: 'lqtd', label: 'By LQTD', },
    { value: 'lylqtd', label: 'By LY-LQTD', },
    { value: 'lmftd', label: 'By LMFTD', },
    { value: 'lyftd', label: 'By LYFTD', },

]

