import React, { useState, useRef, useEffect } from 'react';
import { TableCell } from './table.styled';
import { formatValueByDataType } from '../../utils';
import * as DataType from '../../shared-with-fe/data.types';
import TrendDown from '../svg/dropDown';
import TrendUp from '../svg/dropDownUp';
import Tooltip from '../custom-tooltip'


const Cell = props => {


    const {

        isTextBold,
        cellValue,
        wordWidth,
        id,
        hideSpan,
        aligment_of_column,
        beautify_data,
        background,
        cell_shade_info,
        _style,
        on_filter_click,
        showTrend,
        rowSpan,
        value_key

    } = props;



    const [toolTip, setToolTip] = useState(undefined);
    const [isToolTipShow, setToolTipShow] = useState(undefined);
    let spanRef = useRef(null);


    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipContent, setTooltipContent] = useState('');
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });


    const hideTooltipTimeout = useRef(null);

    const handleMouseEnter = (e, content) => {
        clearTimeout(hideTooltipTimeout.current);

        const rect = e.target.getBoundingClientRect();
        setTooltipContent(content);
        setTooltipPosition({ x: rect.left, y: rect.top + rect.height });
        setTooltipVisible(true);
    };

    const handleMouseLeave = () => {
        // Delay hiding tooltip to allow text selection
        hideTooltipTimeout.current = setTimeout(() => setTooltipVisible(false), 100);
    };


    const get_trangle_class_by_value = (value) => {
        let temp_value = parseFloat(value);
        if (temp_value > 0) {
            return <TrendUp color="green" size="10px" height="10px" />
        }
        if (temp_value < 0) {
            return <TrendDown color="#f00" size="10px" height="10px" />
        }
    }


    useEffect(() => {
        if (cellValue && !hideSpan) {
            let current = spanRef.current;
            let { clientWidth, scrollWidth } = current;
            if (scrollWidth > clientWidth) {
                setToolTipShow(true);
            }
        }
    }, [cellValue, hideSpan])


    const cell_aligment_type = aligment_of_column?.['alignment'] || aligment_of_column?.["aligment"]  //(aligment_of_column && Object.keys(aligment_of_column).length > 0) ? aligment_of_column['alignment'] : undefined;
    const cell_data_type = aligment_of_column?.['type'] // (aligment_of_column && Object.keys(aligment_of_column).length > 0) ? aligment_of_column['type'] : undefined;
    const currency_type = aligment_of_column?.['currency_type'];
    const num_format_type = aligment_of_column?.['num_format_type'];
    const use_decimal = aligment_of_column?.['use_decimal'];
    const use_percent = aligment_of_column?.['use_percent'];
    const other_format_config = aligment_of_column?.["other_format_config"];

    const finalDatatoShow = cellValue;

    const afterFormatted = formatValueByDataType(finalDatatoShow, cell_data_type, 'table', undefined, value_key, undefined, currency_type, num_format_type, use_decimal, use_percent, other_format_config);
    const is_num_data_type = num_format_type !== "date_time_hour" && (cell_data_type == DataType.currency || cell_data_type == DataType.number || cell_data_type == DataType.percent || cell_data_type == DataType.number_with_decimal);
    const is_sign_exist = afterFormatted?.length > 0 && afterFormatted?.split("  ")?.length > 1;
    const show_trend = aligment_of_column?.show_trend || showTrend;


    const need_to_show_big_title = afterFormatted && afterFormatted?.length > 100

    return (
        <React.Fragment>

            <TableCell
                hoverDisable={beautify_data}
                id={id}
                {...props}
                // title={finalDatatoShow + "\n" + afterFormatted}
                title={!need_to_show_big_title ? afterFormatted : ''}
                force_bg_color={beautify_data && beautify_data.cell_color ? beautify_data.cell_color : (background ? background : undefined)}
                className='table_cell'
                color='#87AFC6'
                lineHeight="10px"
                vertical={"top"}
                style={{
                    position: 'relative',
                    fontSize: beautify_data && beautify_data.font_size ? beautify_data.font_size + "px" : undefined,
                    color: beautify_data && beautify_data.font_color ? beautify_data.font_color : undefined,
                    fontWeight: isTextBold ? 600 : undefined,
                    overflow: 'hidden',
                    verticalAlign: rowSpan > 0 ? "top" : undefined,

                }}
                // onMouseLeave={(e) => { e.preventDefault(); setToolTipShow(undefined) }}
                onClick={(e) => {

                    if (process?.env?.REACT_APP_MODE === "barc") return;

                    if (on_filter_click) on_filter_click(e)
                }}

                onMouseEnter={(e) => handleMouseEnter(e, afterFormatted)}
                onMouseLeave={handleMouseLeave}

            >


                {/* <ToolTip toolTip={true} cellData={finalDatatoShow} cellValue={cellValue} /> */}

                {cell_shade_info && !(beautify_data && beautify_data.cell_color) && <div
                    style={{
                        width: cell_shade_info.percentage + "%",
                        backgroundImage: `linear-gradient(to right, ${_style?.shade?.color}, ${cell_shade_info.percentage}% , white)`
                    }}
                    className="gradient_div"></div>}


                {!hideSpan && (
                    <span
                        ref={spanRef}
                        className="td_span"
                        style={{
                            minWidth: (wordWidth ? (wordWidth <= 50 ? 105 : wordWidth) : 'inherit'),
                            maxWidth: (wordWidth ? (wordWidth <= 50 ? 105 : wordWidth) : 'inherit'),
                            textAlign: is_sign_exist ? undefined : cell_aligment_type ? cell_aligment_type : undefined,
                            display: (is_sign_exist || show_trend) ? "flex" : 'block',
                            justifyContent: (is_sign_exist || show_trend) ? "space-between" : undefined,
                            overflow: 'hidden',
                        }}
                        onMouseEnter={(e) => {
                            e.preventDefault();
                            if (isToolTipShow) {
                                setToolTipShow(true)
                            }
                        }}
                    >

                        {show_trend ? <span className={"trend"}>
                            {get_trangle_class_by_value(cellValue)} &nbsp;&nbsp;
                        </span> : null}

                        {is_num_data_type ?
                            (value_key === 'Percentage Change' && (cellValue == 0 || !cellValue)) ? " " :
                                afterFormatted && afterFormatted.length > 0 && afterFormatted.split('  ').map((key) => {
                                    return <span>{key}</span>
                                }) : afterFormatted}

                    </span>
                )}
                {need_to_show_big_title && <Tooltip visible={tooltipVisible} position={tooltipPosition} content={tooltipContent} />}

            </TableCell>
        </React.Fragment>
    );
};

export default Cell;