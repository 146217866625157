import React, { useState, useEffect } from 'react'
import { Wrapper, FilesTableWrapper, FileThead, FileTbody } from './file explorer.styled';
import PDFSvg from '../svg/pdf';
import PngSvg from '../svg/png';
import JpgSvg from '../svg/jpg';
import ViewSvg from '../svg/view'
import CSVSvg from '../svg/csv';
import TextBox from '../hoc/textBox';
import DropDownSvgDown from '../svg/dropDown';
import DropDownSvgUp from '../svg/dropDownUp';
import DeleteSvg from '../svg/delete'
import TxtSvg from '../svg/txt.svg';

import { convert_date_to_dd_mm_yyyy_v1, bytesToSize } from '../../utils';



const Index = (props) => {


    const { files, setActiveFile, ViewFile, _extract_file_name, tableHead, columnsToShow, delete_grouped_file, height } = props;
    const [filterdFiles, setFiltedFiles] = useState(undefined,)
    const [sortOrder, setSortOrder] = useState(undefined)


    useEffect(() => {

        let sorted_file_by_date = files && files.length > 0 && files.sort((left, right) => {
            let left_created_at = new Date(left.created_at ? left.created_at : null);
            let right_created_at = new Date(right.created_at ? right.created_at : null)
            return right_created_at - left_created_at
        })
        setFiltedFiles(sorted_file_by_date)
        setSortOrder({ 'created_at': 'za' })
    }, [files]);




    const filterFileOnSearch = (value) => {
        let search_value = value && value.toLowerCase();

        let clone_files = JSON.parse(JSON.stringify(files));
        let after_filterd = clone_files.filter((file) => {
            if (value === '') return true;
            else if (file[columnsToShow[0]].toLowerCase().indexOf(search_value) > -1) return true
            else return false;
        });
        setFiltedFiles(after_filterd)
    }





    const sortFile = (key, _order) => {
        let order = _order ? _order : (sortOrder && sortOrder[key]) ? ((sortOrder[key] == 'az') ? 'za' : 'az') : 'az';
        const sortedFile = filterdFiles && filterdFiles.length > 0 && filterdFiles.sort((left, right) => {

            if (key === 'created_at') {

                if (order == 'az') {
                    let left_created_at = new Date(left.created_at ? left.created_at : null);
                    let right_created_at = new Date(right.created_at ? right.created_at : null)
                    return left_created_at - right_created_at
                }

                if (order == 'za') {
                    let left_created_at = new Date(left.created_at ? left.created_at : null);
                    let right_created_at = new Date(right.created_at ? right.created_at : null)
                    return right_created_at - left_created_at
                }
            }

            if (key === 'name') {

                if (order == 'az') {
                    let leftValue = left[columnsToShow[0]] ? left[columnsToShow[0]].trim().toLowerCase() : '';
                    let rightValue = right[columnsToShow[0]] ? right[columnsToShow[0]].trim().toLowerCase() : '';
                    return leftValue.localeCompare(rightValue);
                }
                if (order == 'za') {
                    let leftValue = left[columnsToShow[0]] ? left[columnsToShow[0]].trim().toLowerCase() : '';
                    let rightValue = right[columnsToShow[0]] ? right[columnsToShow[0]].trim().toLowerCase() : '';

                    return rightValue.localeCompare(leftValue);
                }
            }

            if (key === 'size') {

                if (order == 'az') {
                    let leftValue = left.size ? left.size : '';
                    let rightValue = right.size ? right.size : '';

                    return leftValue - rightValue;
                }

                if (order == 'za') {
                    let leftValue = left.size ? left.size : '';
                    let rightValue = right.size ? right.size : '';

                    return rightValue - leftValue;
                }
            }
        })

        setFiltedFiles(sortedFile)
        setSortOrder({ [key]: order })
    }






    const getIconFromFileType = (name) => {

        let type = name.split('.').pop();
        if (type === 'pfd') {
            return <PDFSvg size='1.2em' height='1.1rem' />
        }
        if (type === 'png') {
            return <PngSvg size='1.2em' height='1.1rem' />
        }
        if (type === 'jpg') {
            return <JpgSvg size='1.2em' height='1.1rem' />
        }
        if (type === 'csv') {
            return <CSVSvg size='1.2em' height='1.1rem' />
        }
        if (type === 'txt') {
            return <TxtSvg size='1.2em' height='1.1rem' />
        }
        if (type === 'json') {
            return <CSVSvg size='1.2em' height='1.1rem' />
        }


    }



    return (
        <Wrapper>

            <div style={{ margin: '1rem 0px', width: '30%' }}>
                <TextBox
                    label="Search File"
                    type="text"
                    placeholder="Search File"
                    onChange={(e) => {
                        filterFileOnSearch(e.target.value)
                    }}
                />
            </div>


            <FilesTableWrapper height={height}>
                <table>
                    <FileThead>
                        <tr>
                            {tableHead && tableHead.length > 0 && tableHead.map((head, i) => {
                                return (
                                    <td>
                                        <div className="thead_row" >
                                            <div className="name">{head}</div>
                                            {(columnsToShow[i] !== 'view_action') && (columnsToShow[i] !== 'delete_action') && (

                                                <div className="dropdown_icon"
                                                    onClick={() => sortFile('name')}
                                                    style={{ marginLeft: '20px', }}
                                                >
                                                    <DropDownSvgUp size={".5rem"} height=".5rem" color={(sortOrder && sortOrder[columnsToShow[i]] === 'za') ? "#222" : '#c1b8b8'} />
                                                    <DropDownSvgDown size={".5rem"} height=".5rem" color={(sortOrder && sortOrder[columnsToShow[i]] === 'az') ? "#222" : '#c1b8b8'} />

                                                </div>

                                            )}
                                        </div>
                                    </td>
                                )
                            })}



                            {/* <td>
                                <div className="thead_row"> <div className="name">View</div></div>
                            </td>
                            <td>
                                <div className="thead_row"> <div className="name">Created At</div>
                                    <div className="dropdown_icon" onClick={() => sortFile('created_at')}
                                        style={{ marginLeft: '20px', }}>
                                        <DropDownSvgUp size={".5rem"} height=".5rem" color={(sortOrder && sortOrder['created_at'] === 'za') ? "#222" : '#c1b8b8'} />
                                        <DropDownSvgDown size={".5rem"} height=".5rem" color={(sortOrder && sortOrder['created_at'] === 'az') ? "#222" : '#c1b8b8'} />
                                    </div>
                                </div>
                            </td>

                            <td>
                                <div className="thead_row"> <div className="name">Size</div>
                                    <div className="dropdown_icon" onClick={() => sortFile('size')}
                                        style={{ marginLeft: '20px', }}>
                                        <DropDownSvgUp size={".5rem"} height=".5rem" color={(sortOrder && sortOrder['size'] === 'za') ? "#222" : '#c1b8b8'} />
                                        <DropDownSvgDown size={".5rem"} height=".5rem" color={(sortOrder && sortOrder['size'] === 'az') ? "#222" : '#c1b8b8'} />
                                    </div>
                                </div>
                            </td> */}

                        </tr>

                    </FileThead>


                    <FileTbody>
                        {filterdFiles && filterdFiles.length > 0 && filterdFiles.map((file, index) => {
                            return (
                                <tr onClick={(e) => {
                                    e.preventDefault();
                                    setActiveFile(file[columnsToShow[0]]);
                                }}>
                                    {/* {columnsToShow && columnsToShow.length > 0 && columnsToShow.map((col, index) => {
                                        return ( */}

                                    <td>
                                        <div style={{ width: '30rem' }}>
                                            {getIconFromFileType(file[columnsToShow[0]])}
                                            <span style={{ cursor: 'pointer' }} className='file_name'>{_extract_file_name(file[columnsToShow[0]])}</span>
                                        </div>
                                    </td>

                                    {/* ) */}
                                    {/* })} */}

                                    {ViewFile && (
                                        <td>
                                            <div onClick={(e) => {
                                                e.stopPropagation();
                                                ViewFile(file.name, 'window', file.id)
                                                // ViewFile(file)

                                            }} className='view_btn'>
                                                <ViewSvg size='1rem' height='1rem' />
                                            </div>
                                        </td>
                                    )}


                                    {delete_grouped_file && (
                                        <td>
                                            <div onClick={(e) => {
                                                e.stopPropagation();
                                                delete_grouped_file(file)

                                            }} className='view_btn'>
                                                <DeleteSvg size='1rem' height='1rem' />
                                            </div>
                                        </td>
                                    )}


                                    <td>{convert_date_to_dd_mm_yyyy_v1(file.created_at, true)}</td>
                                    {columnsToShow && columnsToShow.indexOf('created_by') > -1 ? <td>{file.created_by}</td> : null}
                                    {columnsToShow && columnsToShow.indexOf('date_range') > -1 ? <td>{file.date_range}</td> : null}
                                    {columnsToShow && columnsToShow.indexOf('platform') > -1 ? <td>{file.platform}</td> : null}
                                    {columnsToShow && columnsToShow.indexOf('status') > -1 ? <td>{file.status}</td> : null}
                                    <td>{bytesToSize(file.size)}</td>
                                    {file.number_of_records ? <td>{file.number_of_records}</td> : null}
                                </tr>
                            )
                        })}
                    </FileTbody>
                </table>
            </FilesTableWrapper>


            {(files === undefined || files.length === 0) && (
                <p style={{ textAlign: 'center', marginTop: '5rem', fontSize: '.9rem' }}>No file Found</p>
            )

            }

            {
                files && files.length > 0 && (filterdFiles == undefined || (filterdFiles && filterdFiles.length === 0)) && (
                    <p style={{ textAlign: 'center', marginTop: '5rem', fontSize: '.9rem' }}>No file Found</p>
                )
            }

        </Wrapper >
    )
}



export default Index;
