import React from 'react';
import styled from 'styled-components';
import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils';
import SearchSvg from '../svg/search';

const selected = getSelectedTheme();


export const QueryWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: relative; 
    border-top: 0px;

    .auto_suggestion_box {
        z-index: 12;
        overflow: scroll;
        position: absolute;
        top: ${props => props.top ? props.top : '0%'};
        left: 0;
        width: inherit;
        z-index: 7px;
        border-radius:0px;
        padding: 10px 0px;
        width: 100%;
        left: 0px;
        right: 0px;
        margin: auto;
        box-shadow: 0 4px 6px 0 rgba(32,33,36,0.28);
        padding-top: 5px;
        margin-top: -1px;
        box-sizing: border-box;
        border-top: ${props => props.hints ? '1px solid #eaeaea' : undefined};
        
    &::-webkit-scrollbar {
        width: 0 !important;
    }

    .search_icon{
        margin-right: 14px;
        
        svg{
            width: 1rem;
            height: .9rem;
        }
      }
    }

    .suggest_item{
        padding: 1px 25px;
        transition: all 0.2s;

        &:hover{
            background: #eaeaeaba;
        }
    }
`;



const AutoHints = (props) => {

    const { hints, question, setHints, activeHint, setQuestion, replace_hint_with_word, inputRef, top, id, listCenter, listPadding, fontSize } = props;



    const PosEnd = (end) => {
        let element = document.getElementById(end)
        var len = (element.value.length);

        if (element.setSelectionRange) {
            element.focus();
            element.setSelectionRange(len, len);
        } else if (element.createTextRange) {
            var t = element.createTextRange();
            t.collapse(true);
            t.moveEnd('character', len);
            t.moveStart('character', len);
            t.select();
        }
    }

    return (
        <QueryWrapper top={top}>
            <div
                style={{
                    background: 'white',
                    maxHeight: hints.length > 0 ? '12rem' : 0,
                    overflowX: "hidden",
                    padding: hints.length === 0 && '0',
                }}
                className='auto_suggestion_box'
                id='li'
            >
                {
                    hints && hints.length > 0 && hints.map((hint, index) => {
                        let temp_question = question;
                        temp_question = temp_question && temp_question.length > 0 && temp_question.split(' ');
                        temp_question && temp_question.length > 0 && temp_question.pop();

                        const last_word = temp_question && temp_question[temp_question.length - 1];
                        if (last_word === (hint && hint.length > 0 && hint.split(' ')[0])) {
                            temp_question.pop();
                        }

                        temp_question = temp_question && temp_question.join(' ');
                        let full_question_hint = temp_question + ' ' + hint

                        return <div key={"hint" + index}
                            className="suggest_item"
                            onClick={() => {
                                replace_hint_with_word(index, true)
                                if(setQuestion){
                                    setQuestion(full_question_hint);
                                }
                                setHints([]);

                                if(inputRef && inputRef.current){
                                    setTimeout(() => {
                                        PosEnd(id)
                                    }, 100);
                                }
                            }}
                            id='item'
                            style={{
                                cursor: 'pointer',
                                fontSize:  props.fontSize ? props.fontSize : '1rem',
                                background: activeHint === index && '#eee',
                                color: activeHint === index && themes[selected].header.color.autoSuggestTxtHover,
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: listCenter ? 'center' : undefined,
                                padding: listPadding ? listPadding : undefined,
                            }}>
                            {/* <span className="search_icon">
                                <SearchSvg color={themes[selected].header.color.search} />
                            </span> */}
                            <span className="prev_word">{temp_question}&nbsp;</span>
                            <span className="current_word">{hint}</span>

                        </div>
                    })
                }
            </div>
        </QueryWrapper>
    )
}

export default AutoHints;
