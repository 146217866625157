const aes = require('crypto-js/aes');
const cryptoJS = require('crypto-js');
const __salt__ = '108udhas7h367uehdyh1y36389jsjj^&^%$asdake049SSDJDLasdd2k129k4';


const encryptText = (textToEncrypt, key='') => {
    if (key === '') key = __salt__;
    if (!textToEncrypt || textToEncrypt === '') return '';

    const encrypted =  aes.encrypt(textToEncrypt, key);

    return encrypted.toString();
};


const decryptText = (textToDecrypt, key='') => {
    if (key === '') key = __salt__;
    if (!textToDecrypt || textToDecrypt === '') return '';
    
    return aes.decrypt(textToDecrypt, key).toString(cryptoJS.enc.Utf8);
};


module.exports.encryptText = encryptText;
module.exports.decryptText = decryptText;