import { check_is_condition_matched } from "../../../utils/v1.1";

/**
 * 
 * @param {*} sorted_columns 
 * @param {*} cell_values 
 * @param {*} __index__ 
 * @returns 
 */

export const beautify_cell_data_v1 = (tbl_formatter, sorted_columns, cell_values, __index__) => {


    // console.log("tbl_formatter", tbl_formatter, sorted_columns, cell_values, __index__)
    const beautify_data = {};
    const sorted_tbl_formatter = tbl_formatter?.length > 0 && tbl_formatter.filter((c) => !c.deleted).sort((a, b) => a.field_order - b.field_order) || [];

    /**
     * here we are itrating the sorted table formatter
     *
     */
    if (sorted_tbl_formatter && sorted_tbl_formatter.length > 0) {

        for (let index = 0; index < sorted_tbl_formatter.length; index++) {

            const element = sorted_tbl_formatter?.[index];
            for (let j = 0; j < sorted_columns?.length; j++) {
                const cell_key = sorted_columns[j];
                const cell_value = cell_values[cell_key]
                if (element.column_name === cell_key) {
                    if (check_is_condition_matched(element.condition, cell_value, element.value, element?.value2)) {
                        if (!beautify_data[cell_key]) beautify_data[cell_key] = {};
                        beautify_data[cell_key] = { data: element, row_level: element.apply_to_row };
                        break;
                    }
                }
            }
        }
        return beautify_data
    } else return false;
}