import React from 'react';

const FilterPre = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            {...props}
            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 570 573"
            space="preserve">
            <g>
                <path id="svg_1" d="m25.004,10c-15.488,0 -25.094,16.836 -17.238,30.188l217.23,289.82l0,219.99c0,14.875 15.645,24.535 28.945,17.887l79.992,-40.004c6.7734,-3.3945 11.043,-10.309 11.043,-17.887l0,-180l217.23,-289.82c7.875,-13.336 -1.7344,-30.188 -17.238,-30.188l-519.964,0.014zm35.051,40.004l449.87,0l-202.18,264.36c-1.8203,3.0781 -2.7812,6.5977 -2.7656,10.168l0,173.11l-40.004,20.004l0,-193.11c0,-3.5859 -0.96094,-7.0859 -2.7656,-10.168l-202.1548,-264.364z" />
            </g>
        </svg>

    )

}


export default FilterPre;