import React, { useState, useEffect } from 'react'
import { Wrapper } from './master.data.styled';
import TitleHeader from '../title-header';
import { connect } from 'react-redux';
import { get_table_data, save_table_data, delete_table_record } from '../../actions/master.data';
import SharedTable from '../shared-table';
import { PageInnerWrapper } from '../hoc/styled.hoc';
import AutoSearch from '../auto.search'
import { showPopup } from '../../utils';
import * as enums from '../../utils/enums.js';
import EditAdd from './add.edit.popup'
import { Button } from '../hoc/button';




let master_data_table = [
    'age_group',
    'salary_group'
]


const Index = (props) => {

    const { get_table_data, tableData, save_table_data } = props;

    const [tableDisabledKey, setTableDisplayKey] = useState(undefined);
    const [thead, setThead] = useState(undefined);
    const [tableName, setTableName] = useState('');
    const [HintArray, setHintArray] = useState(undefined);

    useEffect(() => {
        if (tableName) {
            get_table_data(tableName)
        }
    }, [tableName])



    useEffect(() => {

        let tempTableHead = tableData && tableData.length > 0 ? tableData[0] : undefined;

        let filterd_tbody = tempTableHead && Object.keys(tempTableHead) && Object.keys(tempTableHead).length > 0 && Object.keys(tempTableHead).filter(key => {
            if (key === 'created_by') return false;
            if (key === 'updated_by') return false;
            if (key === 'created_at') return false;
            if (key === 'updated_at') return false;
            if (key === 'modified_at') return false;
            if (key === 'modified_by') return false;
            if (key === 'is_active') return false;
            if (key === 'id') return false;
            if (key === 'client_id') return false;
            else return true;
        })

        let temp_thead = filterd_tbody ? JSON.parse(JSON.stringify(filterd_tbody)) : [];
        temp_thead.push('Actions')
        setThead(temp_thead)
        setTableDisplayKey(filterd_tbody)

    }, [tableData])



    const local_save = (data) => {
        save_table_data(tableName, data)
        setTimeout(() => {
            get_table_data(tableName)
        }, 200)

    }


    const editRow = (data) => {
        showPopup(undefined, undefined, enums.default.popupType.element, EditAdd, {
            info: data,
            keys: tableDisabledKey,
            tableName: tableName,
            save__func: (data) => local_save(data)
        })
    }



    return (
        <Wrapper>

            <TitleHeader
                title={'MASTER DATA'}
            />


            <PageInnerWrapper height={"calc(100vh - 200px)"}>
                <div style={{ width: '100%', marginBottom: '10px', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%' }}>
                        <AutoSearch
                            label="Select Table"
                            selections={master_data_table}
                            onItemClick={(val) => {
                                setTableName(val);
                            }}
                            // labelBg="#f7f7f8"
                            activeValue={tableName}
                            width="90%"
                            height="2.857rem"
                            lineHeight="2.857rem"
                            border='1px solid #bbd1d9'
                            color='#7c7c7c'
                        />
                    </div>

                    {tableData && (
                        <div>
                            <Button
                                width="7rem"
                                mR="1rem"
                                primary={true}
                                onClick={() => {
                                    editRow()
                                }}>Add New</Button>
                        </div>
                    )}

                </div>
                <SharedTable
                    action={true}
                    deleteAction={(item) => {
                        props.delete_table_record(tableName, item ? item.id : undefined)
                    }}
                    editAction={editRow}
                    tableData={(tableData && tableData.length > 0) ? tableData : undefined}
                    tableHead={thead ? thead : []}
                    columnsToShow={tableDisabledKey}
                />
            </PageInnerWrapper>
        </Wrapper>
    )
}



const mapStateToProps = (state) => ({
    tableData: state.masterReducer.tableData
})

export default connect(mapStateToProps, { get_table_data, save_table_data, delete_table_record })(Index);
