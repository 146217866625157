import * as actions from '../action-types/sqlHistory';

const initialState = {
    type: actions.SQL_HISTORY_INITIAL,
    favouriteData: undefined,
    message: undefined,
    historyData: undefined,
};


const sqlHistoryReducer = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        // case actions.SAVE_HISTORY:
        //     return {
        //         ...state,
        //         type: action.type,
        //     };
        // case actions.ADDED_MESSAGE:
        //     return {
        //         ...state,
        //         type: action.type,
        //         message: action.data
        //     };

        // case actions.GET_ALL_HISTORY_LIST_BY_ID:
        //     return {
        //         ...state,
        //         type: action.type,
        //         favouriteData: action.data
        //     };

        case actions.GET_HISTORY_SUCCESS:
            
            // let temp_history_data = (action.data && action.data.length > 50) ? action.data.slice(0, 50) : action.data;
            return {
                ...state,
                type: action.type,
                historyData: action.data
            };

        default:
            return {
                ...state
            }
    }
};

export default sqlHistoryReducer;