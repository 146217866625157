import * as actionTypes from '../action-types/report_builder';
import { dispatchAction, simpleDispatch_post, simpleDispatch_get, showError } from './helper';
import { constants } from '../utils/constants';
import { post, put } from '../data.service';
import * as loaderTypes from '../action-types/loader';




export const get_schema_table_info = (db_ids, live_db_id, isRefresh) => async (dispatch) => {
    
    dispatchAction(dispatch, actionTypes.CLEAR_SCHEMA_TABLE_INFO)

    const post_data = { db_ids: db_ids, live_db_id: live_db_id, isRefresh }

    const url = constants.END_POINTS.API + constants.END_POINTS.DB_INFO.POINT + constants.END_POINTS.DB_INFO.GET_TABLE_AND_COLUMNS_OF_DB;
    await simpleDispatch_post(dispatch, url, post_data, actionTypes.GET_SCHEMA_TABLE_COLUMN_INFO, true, undefined);

}



export const add_new_column_into_table = (data) => async (dispatch) => {

    const post_data = { additional_table_column: data }

    const url = constants.END_POINTS.API + constants.END_POINTS.DB_INFO.POINT + constants.END_POINTS.DB_INFO.SAVE_TABLE_ADDITIONAL_COLUMN;

    const response = await post(url, post_data);

    if (response.code === 200) {

        const column_id = response?.data?.additional_table_column

        const column_data = {
            id: column_id,
            ...data,
            is_additional: true,
            type: data?.data_type
        }

        dispatchAction(dispatch, actionTypes.NEW_COLUMN_ADDED_SUCCESS, column_data)

    }

    // await simpleDispatch_post(dispatch, url, post_data, actionTypes.GET_SCHEMA_TABLE_COLUMN_INFO, true, undefined);

}

export const clear_schema_table_info = () => async (dispatch) => {
    dispatchAction(dispatch, actionTypes.CLEAR_SCHEMA_TABLE_INFO)
}



export const set_active_report_for_edit = (report_id, report) => async (dispatch) => {
    dispatchAction(dispatch, actionTypes.SET_ACTIVE_REPORT_FOR_EDIT, { report_id, report: report })
}

export const clear_active_edit_report = () => async (dispatch) => {
    dispatchAction(dispatch, actionTypes.CLEAR_ACTIVE_EDIT_REPORTS)

}


export const get_edit_report_by_id = (id, type, view_report_query) => async dispatch => {

    // console.log("view_report_query", id, type, view_report_query)
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.GET_ALL_REPORTS;
    const post_data = {
        report_id: id,
        type
    }


    if (view_report_query) {
        get_report_sql_query(id)(dispatch)
    }
    dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);

    try {
        const data = await post(url, post_data);
        if (data && data.data && data.data.all_reports && data.data.all_reports.length > 0) {
            set_active_report_for_edit(id, data.data.all_reports[0])(dispatch)
        }
        else throw new Error("Report Not Found")
    } catch (error) {
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        showError(dispatch, error);
    }
};



/**
 * 
 * @param {*} report_id 
 * @returns 
 */




export const get_report_sql_query = (report_id) => async dispatch => {

    dispatchAction(dispatch, actionTypes.CLEAR_REPORT_SQL_QUERY);
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.GET_REPORT_SQL_QUERY;
    await simpleDispatch_post(dispatch, url, { report_id: report_id, }, actionTypes.GET_REPORT_SQL_QUERY, true, undefined);
}

