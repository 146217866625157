import React, { useState, useEffect, useRef } from 'react';
import { InsightReportEditorWrapper, TitleWrapper, CloseWrapper, ChartApperanceSetting } from './insights.styled';
import { DivRow } from '../../styled-hoc';
import { Button } from '../../hoc/button';
import { CHART_TYPES_WITH_ICONS } from '../../../shared-with-fe/constants';
import { Gap } from '../../gap/'
import CheckBox from '../../hoc/checkbox';
import { ELEMENT_UI_TYPES } from '../../../shared-with-fe/constants'
import TextBox from '../../hoc/textBox';
import SelectOption from '../../select-option'
import AngleDown from '../../svg/angleDown';
import AngleUp from '../../svg/angleUp';
import RippleEffect from '../../hoc/ripple-effect';
import { connect } from 'react-redux';
import { clearQuestionInfo } from '../../../actions/etl';
import { getAllInfoOfQuestion } from '../../../actions/insights';
import { getFromSession } from '../../../utils/session.helper';
import { constants } from '../../../utils/constants';
import AutoHints from '../../auto-suggest/auto.hints';
import { find_hint_in_array } from '../../../utils';
import { getStringHintsFromServer } from '../../../actions/report'


const map_level = [
    { name: 'State Data', value: 'state_data' },
    { name: 'Districts', value: 'district_data' },
    { name: 'Divisions', value: 'division_data' },
    // { name: 'Mandalas', value: 'mandala_data' },
    // { name: 'Customer Data', value: 'customer_data' },
    // { name: 'Branch Data', value: 'branch_data' },
    // { name: 'HSL Data', value: 'hsl_data' },
];


const apperance_type = [
    { name: "Hide X Axis Tick", value: 'hide_x_axis', render_mode: 'chart' },
    // {
    //     name: "Hide Secondry X Axis Tick",
    //     value: 'hide_x_axis_secondry',
    //     render_mode: ['chart'],
    //     render_mode: 'chart'
    // },
    { name: "Hide Y Axis Tick", value: 'hide_y_axis', render_mode: 'chart' },
    { name: "Hide Y Axis Label", value: 'hide_y_axis_label', render_mode: 'chart' },
    { name: "Hide X Axis Label ", value: 'hide_x_axis_label', render_mode: 'chart' },
    { name: "Hide Y Axis Border ", value: 'hide_y_axis_border', render_mode: 'chart' },
    { name: "Hide X Axis Border", value: 'hide_x_axis_border', render_mode: 'chart' },
    { name: "Hide Label", value: 'hide_label', render_mode: 'chart' },
    { name: "Hide Title", value: 'hide_title', render_mode: 'chart' },
    { name: "Hide Border ", value: 'hide_border', render_mode: 'chart' },
    { name: "Hide Grid", value: 'hide_grid', render_mode: 'chart' },
    { name: "Hide Legend", value: 'hide_legend', render_mode: 'chart' },
    { name: "Hide Tooltip", value: 'hide_tooltip', render_mode: 'chart' },

    { name: "Hide Grand Total", value: 'hide_grand_total', render_mode: 'table' },
    { name: "Hide Title", value: 'hide_title', render_mode: 'table' },
    { name: "Hide Border ", value: 'hide_border', render_mode: 'table' },

    { name: "Show Label In Legend", value: 'show_label_in_legend', render_mode: 'chart', chart_type: 'pie' },
    { name: "Show Legend as percent", value: 'show_legend_as_percent', render_mode: 'chart', chart_type: 'pie' },

]


const default_data_types = ['number', 'int', 'double', '6', 'currency']



const InsightReportEditor = props => {

    const { close, option, popupKey, data, questionInfo } = props;

    const { popupData, func } = data;
    const { report, editedReportItem, element_type, is_form_editor, render_mode, chart_type, report_item_additional_info, db_infos } = popupData;
    const { _saveInsights } = func;
    const [reportItem, setReportItem] = useState(undefined);
    const [chartSetting, setChartSetting] = useState({});
    const [isAdditionalOpen, setAdditionalOpen] = useState(undefined);
    const [tableMapping, setTableMapping] = useState(undefined);
    const [default_number_columns, setDefaultNumberColumns] = useState([]);
    // console.log('show  ===> ', reportItem);

    const _inputRef = useRef(null)

    // for hints 
    const hints_array = props.hints ? [...props.hints] : [];

    const [question, setQuestion] = useState('');
    const [hints, setHints] = useState([]);
    const [activeHint, setActiveHint] = useState(-1);
    const [tempQuestion, setTempQuestion] = useState(undefined);
    const [scrollValue, setScrollValue] = useState(0);
    const [hintsShow, setHintShow] = useState(false)


    useEffect(() => {
        if (report) {
            setReportItem(report)
        }
        if (report.question) {
            setQuestion(report.question)
        }
        if (report.db_info_id) {
            props.getStringHintsFromServer(report.db_info_id);
        }
    }, []);


    useEffect(() => {
        if (report_item_additional_info) {
            setTableMapping(report_item_additional_info)
        }
    }, [report_item_additional_info])


    const clientIdFromSession = getFromSession(constants.SESSION_KEYS.CLIENT_ID);

    useEffect(() => {
        if (clientIdFromSession && (clientIdFromSession === 'adskom' || clientIdFromSession === 'ads_yahoo')) {
            setDefaultNumberColumns(['cpc', 'cpm', 'ctr', 'cpl'])
        }
    }, [clientIdFromSession])


    const checkColumnNameExistInTable = (array, columnName) => {
        if (array && array.length > 0) {
            for (let index = 0; index < array.length; index++) {
                const element = array[index];
                if (element.column_name === columnName) {
                    return true;
                }
            }
        } else return false
    }




    const first_letter_cap = (letter) => {
        if (letter && letter.trim().length > 0) {
            let temp_letter = letter.split("")
            let first_letter = temp_letter[0].toUpperCase();
            temp_letter[0] = first_letter;
            return temp_letter.join("")
        }
    }



    useEffect(() => {

        let cloneTableMapping = (tableMapping && tableMapping.length > 0) ? [...tableMapping] : [];
        const stateTableMappingColumns = (cloneTableMapping && cloneTableMapping.length > 0) ? cloneTableMapping.map((column) => column.column_name) : [];
        let tempMapping = [];
        let columnsToMap = [];
        let numberDataTypeField = [];


        if (questionInfo && questionInfo.columns_extracted && questionInfo.columns_extracted.length > 0) {
            questionInfo.columns_extracted.map((column) => {
                if (default_data_types.indexOf(column.data_type) > -1) {
                    numberDataTypeField.push({
                        column_name: column.column_name,
                        data_type: column.data_type,
                        column_table: column.column_table
                    })
                }
            })
        }


        if (questionInfo && questionInfo.columns_extracted && questionInfo.columns_extracted.length > 0 && (questionInfo.comparing_column_info.length === 0 || questionInfo.comparing_column_info === 'undefined')) {
            questionInfo.columns_extracted.map((column) => {
                columnsToMap.push({
                    column_name: column.column_name,
                    data_type: column.data_type,
                    column_table: column.column_table
                })
            })
        }


        if (questionInfo && questionInfo.comparing_column_info && questionInfo.comparing_column_info.length > 0) {
            questionInfo.comparing_column_info.forEach((comp) => {
                numberDataTypeField && numberDataTypeField.length > 0 && numberDataTypeField.forEach((column) => {
                    let orignal_name = first_letter_cap(comp.column_name)
                    let last_name = first_letter_cap(column.column_name)
                    let final_name = orignal_name + "-" + last_name;
                    columnsToMap.push({
                        column_name: final_name,
                        data_type: column.data_type,
                        column_table: column.column_table
                    })
                })
            })
        }


        if (questionInfo && questionInfo.columns_extracted) {
            if (columnsToMap && columnsToMap && columnsToMap.length > 0) {
                columnsToMap.map((column) => {
                    let temp_column = JSON.parse(JSON.stringify(column));
                    temp_column.data_type = temp_column.data_type ? temp_column.data_type : "abc"
                    if ((default_data_types.indexOf(temp_column.data_type) > -1) || (default_number_columns.indexOf(temp_column.column_name) > -1)) {

                        if (report_item_additional_info && report_item_additional_info.length > 0 && checkColumnNameExistInTable(report_item_additional_info, temp_column.column_name)) {

                            report_item_additional_info.map((info) => {
                                if (info.column_name === temp_column.column_name) {
                                    tempMapping.push({
                                        column_name: temp_column.column_name,
                                        chart_type: info.chart_type,
                                        axis_position: info.axis_position
                                    })
                                }
                            })
                        }
                        else {
                            tempMapping.push({
                                column_name: temp_column.column_name,
                                axis_position: "Primary"
                            })
                        }
                    }
                })

            } else {

                tempMapping = [];

            }

            if (question && question.length > 0 && (clientIdFromSession === 'adskom' || clientIdFromSession === 'ads_yahoo')) {
                let _split_question = question.split(" ");
                _split_question && _split_question.length > 0 && _split_question.map((column) => {
                    if ((default_number_columns && default_number_columns.indexOf(column) > -1) && !checkColumnNameExistInTable(tempMapping, column)) {
                        tempMapping.push({
                            column_name: column,
                            axis_position: "Primary"
                        })
                    }
                })
            }
            setTableMapping(tempMapping)
        }
    }, [questionInfo])




    const changeAppearance = (key) => {
        let tempReportItem = Object.assign({}, reportItem);

        if (key) {
            tempReportItem[key] = !tempReportItem[key]
        }

        setReportItem(tempReportItem);
    };


    const onChangeType = type => {
        let tempReportItem = Object.assign({}, reportItem);
        if (type === 'group') {
            tempReportItem.chart_type = 'group';
            tempReportItem.render_mode = 'group'
            tempReportItem.is_group = true;
            tempReportItem.question = '';
        }
        else if (type === 'table') {
            tempReportItem.chart_type = 'table';
            tempReportItem.render_mode = 'table'
            tempReportItem.is_group = undefined;

        }
        else {
            tempReportItem.chart_type = type;
            tempReportItem.render_mode = 'chart'
            tempReportItem.is_group = undefined

        }

        setReportItem(tempReportItem);
    };



    const onTextChange = key => event => {
        let tempReportItem = Object.assign({}, reportItem);
        const value = event.target.value;
        tempReportItem[key] = value;

        if (key === 'question') {
            tempReportItem['isQuestionModified'] = true;
        }
        setReportItem(tempReportItem);

        if (key === 'question') {
            setQuestion(value);
            /* 
                * onChange we are setting the default value for activeHint, hints, tempQuestion, scrollValue
            */

            setActiveHint(-1)
            setHints([])
            setTempQuestion(undefined)
            setScrollValue(0)


            if (value.length === 0) {
                setHints([]);
                setTempQuestion(undefined);
            }
            else {
                const full_question = value.split(' ');
                const last_word = full_question[full_question.length - 1];
                const currentWord = last_word;

                let temp_hints = [];
                let concatenate_word = '';
                setHints([])

                if (currentWord && currentWord.length > 0) {
                    /* 
                        * find_hint_in_array()
                            * This is pure magic of Regex.
                            * We are using the regex to get the values from the hints array.
                            * Please see the logic and comments of find_hint_in_array for better understanding
                    */
                    const result = find_hint_in_array(currentWord, hints, hints_array);
                    temp_hints = temp_hints.concat(result);
                }
                if (temp_hints.length > 0) {
                    const unique_hints = Array.from(new Set(temp_hints)).filter(value => !(value === currentWord || value === concatenate_word));
                    setHints(unique_hints)
                    setHintShow(true)
                }
            }
            setQuestion(value);
        }
    };

    const onDrpodownTextChange = (value, key) => {
        let tempReportItem = Object.assign({}, reportItem);
        tempReportItem[key] = value;
        if (key === 'question') {
            tempReportItem['isQuestionModified'] = true;
        }
        if (key === 'db_info_id') {
            props.getStringHintsFromServer(value);
        }


        setReportItem(tempReportItem);
    };

    const replace_hint_with_word = (index, setQues) => {

        if (question && question.length > 0) {
            let temp_question = question;
            const active_index = (index || index === 0) ? index : activeHint;

            /* 
                * Splitting the words into the tokens.
                * Then Removing the last word.
            */

            temp_question = temp_question ? temp_question.split(' ') : '';
            temp_question.pop();

            const last_word = temp_question[temp_question.length - 1];
            const active_hint = hints[active_index]

            /* 
                * Checking again if the last word in the temp_question is equal to splitted hint at index of 0.
                * Then again pop it.
            */

            if (active_hint && last_word === active_hint.split(' ')[0]) {
                temp_question.pop();
            }

            /* 
                * Now joining the temp_question and adding the active hint into it.
            */

            temp_question = temp_question.join(' ');
            const new_question = temp_question + ' ' + hints[active_index];

            if (setQues) {
                setTempQuestion(undefined);
                setQuestion(new_question);
            }
            else {
                setTempQuestion(new_question);
            }
        }

    };

    const scroll = position => {
        if (activeHint < hints.length - 1 && activeHint > 0) {
            const element = document.getElementById('li');
            const value = position === 'down' ? (30) + scrollValue : scrollValue - 30;
            element.scrollTop = value;

            setScrollValue(value);
        }
    };


    const onChnageTableMapping = (value, key, index) => {
        let temptableMapping = tableMapping ? JSON.parse(JSON.stringify(tableMapping)) : [];
        if (report_item_additional_info) {
            temptableMapping[index].modified = true;
        }
        temptableMapping[index][key] = value;
        setTableMapping(temptableMapping)
    }


    const questionKeyDown = (event) => {

        const code = event.keyCode;
        const value = event.target.value;


        if (event.key === 'Enter') {
            if (value.length === 0 || value.trim().length === 0) {
                return
            }
            else {
                // clear_all_filters();
                // props.clearBreadCrumb();

                // if (pathname !== '/analytics_map') {
                //     _getDataFromReportingServer(tempQuestion ? tempQuestion : question, props.history)
                // }
                // else {
                //     props.history.push(`?question=${tempQuestion ? tempQuestion : question}`)
                // }

                replace_hint_with_word(activeHint)
                setQuestion(tempQuestion ? tempQuestion : question)
                setTempQuestion(undefined)
                setActiveHint(-1)
                setHints([])
                setScrollValue(0)
                event.target.blur()
            }
        }

        /* 
            * 38 is key code of up arrow key.
            * It will change the active hint by -1                                
        */

        if (code === 38) {
            if (activeHint > -1) {
                event.preventDefault()
                const index = activeHint - 1;
                if (index === -1) {
                    setTempQuestion(undefined)
                }
                else {
                    replace_hint_with_word(index)
                }
                setActiveHint(index);
                scroll('up')
            }
        }

        /* 
            * 40 is key code of down arrow key.
            * It will change the active hint by +1                                
        */

        if (code === 40) {
            if (activeHint < hints.length - 1) {
                event.preventDefault()
                const index = activeHint + 1;
                replace_hint_with_word(index)
                setActiveHint(index)
                scroll('down')
            }
        }

        /* 
            * 9 is key code of Tab key.
            * 32 is key code of Space key.
            * On Pressing Tab or Space => active hint join the question and setHintsArray to []
        */

        if (code === 9 || code === 32) {
            if (code === 9) {
                event.preventDefault()
            }
            setActiveHint(-1)
            setQuestion(tempQuestion ? tempQuestion : question, undefined, true, undefined)
            setTempQuestion(undefined)
            setHints([])
            setScrollValue(0)
            event.target.focus()
        }

        /* 
            * 8 is key code of BackSpace key.
            * 27 is key code of Esc key.
        */
        if (code === 8 || code === 27) {
            setHints([])
            setActiveHint(-1)
            setScrollValue(0)

            if (code === 27) {
                setTempQuestion(undefined)
            }
        }
    }


    useEffect(() => {
        if (db_infos && db_infos.length === 1) {
            const value = db_infos[0].id
            let tempReportItem = Object.assign({}, report);
            tempReportItem['db_info_id'] = value;
            if (value) {
                props.getStringHintsFromServer(value);
            }
            setReportItem(tempReportItem);
        }
    }, [db_infos, report])



    return (
        <InsightReportEditorWrapper>
            <TitleWrapper>Edit Quick Insight Options </TitleWrapper>
            <Gap h="1.3rem" />
            <div
                style={{
                    padding: '10px'
                }}
            >

                {!is_form_editor && render_mode !== 'map' && chart_type !== 'map' && (reportItem && !reportItem.is_group) && db_infos && db_infos.length > 1 && (
                    <React.Fragment>
                        <DivRow w="100%">
                            <SelectOption
                                label="Select Your Data Source"
                                onChangeFunc={onDrpodownTextChange}
                                selections={db_infos ? db_infos : []}
                                displayKey='connection_name'
                                valueType="db_info_id"
                                valueKey='id'
                                activeValue={reportItem && reportItem.db_info_id ? reportItem.db_info_id : ''}
                                width="100%"
                                padding="0px 0px"
                                paddingRight='25px'
                                lineHeight="2.857rem"
                                menuLineHeight="1.8rem"
                                hideCase='true'
                                groupKey='db_type'
                                sortKey='desc'
                                isSearch={true}

                            />
                        </DivRow>

                        <Gap h="1.2rem" />
                    </React.Fragment>
                )}

                {!is_form_editor && render_mode !== 'map' && chart_type !== 'map' && (
                    <React.Fragment>
                        <DivRow w="100%">
                            <TextBox
                                label="Title"
                                type="text"
                                value={(reportItem && reportItem.title) ? reportItem.title : ''}
                                onChange={onTextChange('title')}
                            />
                        </DivRow>

                        <Gap h="1.2rem" />
                    </React.Fragment>
                )}



                {is_form_editor && (
                    <React.Fragment>
                        <DivRow w="100%">
                            <TextBox
                                label="Label"
                                type="text"
                                value={reportItem ? reportItem.element_label : ''}
                                onChange={onTextChange('element_label')}
                            />
                        </DivRow>
                        <Gap h="1.2rem" />
                    </React.Fragment>
                )}


                {is_form_editor && (
                    <React.Fragment>
                        <DivRow w="100%">
                            <TextBox
                                label="Tag"
                                type="text"
                                value={reportItem ? reportItem.tag : ''}
                                onChange={onTextChange('tag')}
                            />
                        </DivRow>
                        <Gap h="1.2rem" />
                    </React.Fragment>
                )}


                {is_form_editor && (element_type === ELEMENT_UI_TYPES.LIST || element_type === ELEMENT_UI_TYPES.RADIO_BUTTON || element_type == ELEMENT_UI_TYPES.CHECKBOX) && (
                    <React.Fragment>
                        <DivRow w="100%">
                            <TextBox
                                label="Values"
                                type="text"
                                value={reportItem ? reportItem.option_values : ''}
                                onChange={onTextChange('option_values')}
                            />
                        </DivRow>
                        <Gap h="1.2rem" />
                    </React.Fragment>
                )}


                {is_form_editor && (element_type === ELEMENT_UI_TYPES.MULTIPLE_VALUES) && (
                    <React.Fragment>
                        <DivRow w="100%">
                            <TextBox
                                label="Columns Name"
                                type="text"
                                value={reportItem ? reportItem.columns_name : ''}
                                onChange={onTextChange('columns_name')}
                            />
                        </DivRow>
                        <Gap h="1.2rem" />

                    </React.Fragment>
                )}


                {!is_form_editor && (
                    <React.Fragment>
                        <DivRow w="100%">
                            <SelectOption
                                label="Render Type"
                                selections={CHART_TYPES_WITH_ICONS}
                                displayKey='name'
                                valueKey='dataKey'
                                onChangeFunc={onChangeType}
                                activeValue={reportItem && reportItem.chart_type ? reportItem.chart_type : undefined}
                                width="100%"
                                lineHeight='2.857rem'
                                dropDownHeight="8rem"
                                disabled={(chart_type === "map" && render_mode === 'map')}

                            />
                        </DivRow>
                        <Gap h="1.2rem" />
                    </React.Fragment>
                )}

                {reportItem && !reportItem.is_group && reportItem.chart_type !== 'label' && (
                    <React.Fragment>
                        {!is_form_editor && render_mode !== 'map' && chart_type !== 'map' && (
                            <React.Fragment>
                                <DivRow w="100%">
                                    <TextBox
                                        label="Question"
                                        type="text"
                                        id={"input_box_question"}
                                        refName={_inputRef}
                                        value={tempQuestion ? tempQuestion : question}
                                        onChange={onTextChange('question')}
                                        onBlur={(e) => {
                                            let _value = e.target.value;
                                            if (_value && _value.trim().length > 0) {
                                                props.getAllInfoOfQuestion(_value, reportItem && reportItem.db_info_id)
                                            }

                                        }}
                                        onKeyDown={questionKeyDown}
                                    />
                                </DivRow>
                                <Gap h="1.2rem" />
                            </React.Fragment>
                        )}

                        {is_form_editor && (element_type === "list" || element_type === "radio" || element_type == "checkbox") && (
                            <React.Fragment>
                                <DivRow w="100%">
                                    <TextBox
                                        id={"input_box_question"}
                                        label="Question"
                                        type="text"
                                        refName={_inputRef}
                                        value={tempQuestion ? tempQuestion : question}
                                        onChange={onTextChange('question')}
                                        onBlur={(e) => {
                                            let _value = e.target.value;
                                            if (_value && _value.trim().length > 0) {
                                                props.getAllInfoOfQuestion(_value, reportItem && reportItem.db_info_id)
                                            }
                                            // setTimeout(() => {
                                            //     setHintShow(undefined)

                                            // }, 500)
                                        }}
                                        onKeyDown={questionKeyDown}
                                    />
                                </DivRow>
                                <Gap h="1.2rem" />
                            </React.Fragment>
                        )}



                        {hintsShow && hints && hints.length > 0 && (
                            <AutoHints
                                hints={hints}
                                activeHint={activeHint}
                                setHints={setHints}
                                question={question}
                                inputRef={_inputRef}
                                top={'-1rem'}
                                id={"input_box_question"}
                                listPadding={"5px 5px"}
                                replace_hint_with_word={replace_hint_with_word}
                            />
                        )}

                        {
                            render_mode === 'map' && chart_type === 'map' && (
                                <React.Fragment>
                                    <DivRow w="100%">
                                        <SelectOption
                                            label="Map Level"
                                            onChangeFunc={(value) => {
                                                onDrpodownTextChange(value, 'question')
                                            }}
                                            selections={map_level}
                                            displayKey='name'
                                            valueType="question"
                                            valueKey='value'
                                            activeValue={reportItem ? reportItem.question : ''}
                                            width="100%"
                                            padding="0px 0px"
                                            paddingRight='25px'
                                            lineHeight="2.857rem"
                                            menuLineHeight="1.8rem"
                                            hideCase='true'
                                        />
                                    </DivRow>

                                    <Gap h="1.2rem" />

                                    <DivRow w="100%">
                                        <SelectOption
                                            label="Marker Type"
                                            onChangeFunc={(value) => {
                                                onDrpodownTextChange(value, 'marker_type')
                                            }}
                                            selections={['Cluster', 'Bubble']}
                                            activeValue={reportItem ? reportItem.marker_type : ''}
                                            width="100%"
                                            padding="0px 0px"
                                            paddingRight='25px'
                                            lineHeight="2.857rem"
                                            menuLineHeight="1.8rem"
                                            hideCase='true'
                                        />
                                    </DivRow>

                                    <Gap h="1.2rem" />
                                </React.Fragment>
                            )
                        }




                    </React.Fragment>
                )}




                {reportItem && reportItem.chart_type && reportItem.chart_type === 'single_cell' &&
                    <DivRow w="100%">
                        <table className="table_wrapper">
                            <thead>
                                <tr>
                                    <td style={{ width: '110px' }}>Font Size(px)</td>
                                    <td style={{ width: '110px' }}>Font Weight</td>
                                    <td style={{ width: '110px' }}>Color</td>
                                    <td>Background Color</td>
                                    <td>Show Trend</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>

                                    <td>
                                        <input
                                            type="text"
                                            onChange={onTextChange('font_size')}
                                            value={reportItem && reportItem.font_size ? reportItem.font_size : undefined}
                                        />
                                    </td>

                                    <td>
                                        <input
                                            type="text"
                                            onChange={onTextChange('font_weight')}
                                            value={reportItem && reportItem.font_weight ? reportItem.font_weight : undefined}
                                        />
                                    </td>

                                    <td>
                                        <input
                                            type="color"
                                            onChange={onTextChange('font_color')}
                                            value={reportItem && reportItem.font_color ? reportItem.font_color : undefined}
                                        />
                                    </td>

                                    <td>
                                        <input
                                            type="color"
                                            onChange={onTextChange('bg_color')}
                                            value={reportItem && reportItem.bg_color ? reportItem.bg_color : undefined}
                                        />
                                    </td>

                                    <td style={{ textAlign: 'center' }}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '100%'
                                        }}>
                                            <CheckBox
                                                onClick={() => {
                                                    changeAppearance("show_trend")
                                                }}
                                                checked={(reportItem && reportItem.show_trend) ? true : false}
                                            />
                                        </div>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </DivRow>

                }




                {reportItem && !reportItem.is_group && (
                    <div
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}
                        onClick={() => setAdditionalOpen(!isAdditionalOpen)}
                    >
                        Additional Settings
                        <RippleEffect
                            title={isAdditionalOpen ? "Close" : "Open"}
                            icon={isAdditionalOpen ? <AngleUp size="1rem" height="1rem" /> : <AngleDown size="1rem" height="1rem" />}
                            Click={() => {
                                // setAdditionalOpen(!isAdditionalOpen)
                            }}
                        />

                    </div>
                )}

                {isAdditionalOpen && tableMapping && tableMapping.length > 1 && reportItem && reportItem.render_mode !== 'table' &&
                    <React.Fragment>
                        <div style={{ width: '100%' }}>
                            <label style={{ color: '#0000008a', fontSize: '1rem', marginBottom: '7px', display: 'block' }}>Please Select Chart Type/Axis</label>
                            <table className="data_table">
                                <thead>
                                    <tr>
                                        <td>Name</td>
                                        <td>Chart Type</td>
                                        <td>Axis</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableMapping && tableMapping.length > 0 && tableMapping.map((column, index) => {
                                        return (
                                            <tr>
                                                <td>{column.column_name}</td>
                                                <td>
                                                    <SelectOption
                                                        selections={[
                                                            { name: 'Bar', value: 'bar' },
                                                            { name: 'Line', value: 'line' }
                                                        ]}
                                                        displayKey='name'
                                                        valueKey='value'
                                                        onChangeFunc={(value) => {
                                                            onChnageTableMapping(value, 'chart_type', index)
                                                        }}
                                                        activeValue={column.chart_type ? column.chart_type : undefined}
                                                        width="100%"
                                                        lineHeight='2.857rem'
                                                        dropDownHeight="8rem"
                                                        disabled={(chart_type === "map" && render_mode === 'map')}
                                                        borderNone={true}

                                                    />
                                                </td>
                                                <td>
                                                    <SelectOption
                                                        selections={[
                                                            "Primary",
                                                            "Secondary"
                                                        ]}
                                                        onChangeFunc={(value) => {
                                                            onChnageTableMapping(value, 'axis_position', index)
                                                        }}
                                                        activeValue={column.axis_position ? column.axis_position : undefined}
                                                        width="100%"
                                                        lineHeight='2.857rem'
                                                        dropDownHeight="8rem"
                                                        disabled={(chart_type === "map" && render_mode === 'map')}
                                                        borderNone={true}


                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <Gap h="1rem" />
                    </React.Fragment>
                }

                {isAdditionalOpen && !is_form_editor && render_mode !== 'map' && chart_type !== 'map' && (
                    <ChartApperanceSetting>
                        <fieldset>
                            <legend>Appearance Settings:</legend>

                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {apperance_type.map((item, index) => {
                                    if (item.render_mode === (reportItem && reportItem.render_mode)) {
                                        return (
                                            <div key={"Appearance" + index} className="flex_row" onClick={() => { changeAppearance(item.value) }}>
                                                <CheckBox mR="10px" onClick={() => { changeAppearance(item.value) }}
                                                    checked={(reportItem && reportItem[item.value]) ? true : false} /> {item.name}
                                            </div>
                                        )
                                    }
                                })}
                            </div>

                        </fieldset>

                    </ChartApperanceSetting>
                )}

                <Gap h="4rem" />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        width: '100%'
                    }}
                >
                    <Button
                        primary={true}
                        width="6rem"
                        mR="1.143rem"
                        onClick={() => {
                            let temp_report_item = reportItem ? JSON.parse(JSON.stringify(reportItem)) : {};
                            if (render_mode !== 'map') {
                                temp_report_item.question = question
                            }
                            temp_report_item.report_item_additional_info = tableMapping && tableMapping.length > 1 ? tableMapping : undefined;
                            _saveInsights(temp_report_item, editedReportItem);
                            close(option, popupKey);
                            props.clearQuestionInfo()
                        }}
                    >
                        Save
                    </Button>

                    <Button
                        width="6rem"
                        bgColor={'#e6e6e6'}
                        onClick={() => {
                            close(option, popupKey);
                            props.clearQuestionInfo()
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </InsightReportEditorWrapper>
    );
};

const mapStateToProps = (state) => ({
    questionInfo: state.insightReducer.questionInfo,
    hints: state.reportReducer.hints,

})
export default connect(mapStateToProps, { getAllInfoOfQuestion, clearQuestionInfo, getStringHintsFromServer })(InsightReportEditor);