import React from 'react';
import { MainWrapper } from './lefter.styled';
import Overlay from '../helper/overlay';
import { hideLefter } from '../../utils';


const Lefter = (props) => {

    const { showFull } = props;
    
    return (
        <React.Fragment>
            {showFull && <Overlay
                onClick={hideLefter}
                zIndex="10"
                bgColor="rgba(0, 0, 0, 0.4)" />}

            <MainWrapper showFull={showFull}>
                {props.element}
            </MainWrapper>
        </React.Fragment>
    )
}


export default Lefter;