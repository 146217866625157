import { dispatchAction, simpleDispatch_get, simpleDispatch_post } from './helper';
import { constants } from '../utils/constants';
import * as actionTypes from '../action-types/db.formula'



/**
 * 
 * @returns 
 */
export const getAllDbFormula = () => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.DB_FORMULA.POINT + constants.END_POINTS.DB_FORMULA.GET_ALL_DB_FORMULA;

    await simpleDispatch_get(dispatch, url, actionTypes.GET_ALL_DB_FORMULA, true);
};


/**
 * 
 * @param {*} formula_data 
 * @returns 
 */

export const saveDbFormula = (formula_data, db_info_id, history) => async dispatch => {

    const post_body = {
        formula_data: formula_data,
        db_info_id: db_info_id
    };

    const url = constants.END_POINTS.API + constants.END_POINTS.DB_FORMULA.POINT + constants.END_POINTS.DB_FORMULA.SAVE_DB_FORMULA;

    await simpleDispatch_post(dispatch, url, post_body, actionTypes.SAVE_DB_FORMULA, false);

    if (history) {
        history.push("/db_formula");
        getAllDbFormula()(dispatch)
    }

};


/**
 * 
 * @param {*} formula_data 
 * @returns 
 */

export const saveMultipleDbFormula = (formula_data, db_info_id, history) => async dispatch => {

    const post_body = {
        formulas: formula_data,
        db_info_id: db_info_id
    };

    const url = constants.END_POINTS.API + constants.END_POINTS.DB_FORMULA.POINT + constants.END_POINTS.DB_FORMULA.SAVE_MULTIPLE_FORMULAS;

    await simpleDispatch_post(dispatch, url, post_body, actionTypes.SAVE_DB_FORMULA, false);

    if (history) {
        history.push("/db_formula");
        getAllDbFormula()(dispatch)
    }

};




export const deleteDbFormula = (formula_id) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.DB_FORMULA.POINT + constants.END_POINTS.DB_FORMULA.DELETE_FORMULA;

    await simpleDispatch_post(dispatch, url, { formula_id }, actionTypes.SAVE_DB_FORMULA, false);

    getAllDbFormula()(dispatch);


};



/**
 * 
 * @param {*} formula_id 
 * @returns 
 */
export const getDbFormulaById = (formula_id) => async dispatch => {

    try {

        dispatchAction(dispatch, actionTypes.CLEAR_EDITED_DB_FORMULA)
        if (!formula_id) throw new Error("Formula  id not found")

        const url = constants.END_POINTS.API + constants.END_POINTS.DB_FORMULA.POINT + constants.END_POINTS.DB_FORMULA.GET_DB_FORMULA_BY_ID + `?formula_id=${formula_id}`

        await simpleDispatch_get(dispatch, url, actionTypes.GET_DB_FORMULA_BY_ID, true);

    }
    catch (error) {

        console.log("err", error);
    }
};

