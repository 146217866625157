import React, { useState, useEffect } from 'react';
import CheckBox from '../hoc/checkbox';
import styled from 'styled-components';
import { Button } from '../hoc/button';
import TextBox from '../hoc/textBox';
import SelectOption from '../select-option'


const Wrapper = styled.div`
    width: 70vw;
    height: 70vh;
    background: #fff;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.25);
    border: solid 5px rgb(234,234,234);
    padding: 1rem;
    border-radius: 3px;
    display: flex;
    flex-direction: column;

    .list_wrapper{
        max-height: 42rem;
        overflow-y: auto;
        /* background: #308bc81c; */

    &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }
    }


    h1{
        font-size: 1.286rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.714rem;
        text-align: left;
        color: #1a1a1a;
        margin: 0px;
        display: block;
        padding-bottom: 1rem;
    }
`;


const Row = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: ${props => !props.all ? 'whitesmoke' : undefined};
    margin-bottom:  ${props => !props.all ? '2px' : undefined};
    border-radius:  ${props => !props.all ? '3px' : undefined};

    .file_name{
        cursor: pointer;
        width: calc(100% - 50px);
        font-size: 1rem;
        line-height: 1.4rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
`;




const output_types = [
    { name: 'xml', value: 'xml' },
    { name: 'json', value: 'json' },
    { name: 'text', value: 'text' },
    { name: 'csv', value: 'csv' },
]

const SelectFilePopup = (props) => {

    const { close, popupKey, option, data } = props;
    const { questions, filesFromServer, saveSetOfQuestion } = data

    const [selectedFile, setSelectedFile] = useState([]);
    const [FilesList, setFilesList] = useState(undefined);
    const [searchValue, setSearchValue] = useState('');
    const [localQuestions, setLocalQuestions] = useState(undefined);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [questionSearch, setQuestionSearch] = useState('');
    const [selectedOutput, setSelectedOutput] = useState('')


    useEffect(() => {
        if (filesFromServer && filesFromServer.files && filesFromServer.files.length > 0) {
            const files = filesFromServer.files.map(file => file.name) || []
            setFilesList(files)
        } else {
            setFilesList(undefined)
        }

    }, [filesFromServer]);


    useEffect(() => {
        let temp_questions = (questions && questions.questions && questions.questions.length > 0) ? questions.questions : [];
        let selectedQuestion = [];
        temp_questions && temp_questions.length > 0 && temp_questions.map((item, i) => {
            selectedQuestion.push(item.name)
        })
        setSelectedQuestions(selectedQuestion)
        setLocalQuestions(temp_questions)
    }, [questions]);



    const onSearch = (value, type) => {


        let _search_value = value && value.length > 0 && value.toLowerCase();

        if (type === 'files') {
            let _temp_files = (filesFromServer && filesFromServer.files && filesFromServer.files.length > 0) ? filesFromServer.files : []
            let _filter_search_data = _temp_files && _temp_files.length > 0 && _temp_files.filter((item, index) => {
                if (_search_value === '') {
                    return true;
                }
                if (item && item.toLowerCase().indexOf(_search_value) > -1) {
                    return true;
                } else {
                    return false;
                }
            })

            setFilesList(_filter_search_data)
        }

        if (type === 'questions') {

            let temp_questions = (questions && questions.questions && questions.questions.length > 0) ? questions.questions : [];

            let _filter_search_data = temp_questions && temp_questions.length > 0 && temp_questions.filter((item, index) => {
                if (_search_value === '') {
                    return true;
                }
                if (temp_questions && item.name.toLowerCase().indexOf(_search_value) > -1) {
                    return true;
                } else {
                    return false;
                }
            })

            setLocalQuestions(_filter_search_data)
        }

    }



    const _click_on_selecte_file = (name, type, which) => {

        if (which === 'files') {
            let _clone_selected_files = JSON.parse(JSON.stringify(selectedFile));

            if (type === "selectAll") {
                if ((selectedFile && selectedFile.length) === (FilesList && FilesList.length)) {
                    _clone_selected_files = [];
                }
                else {
                    FilesList && FilesList.length > 0 && FilesList.map((file, i) => {
                        if (_clone_selected_files && _clone_selected_files.indexOf(file) == -1) {
                            _clone_selected_files.push(file)
                        }
                    })
                }
            }


            if (type === 'single') {
                if (name && _clone_selected_files && _clone_selected_files.indexOf(name) > -1) {
                    let _index = _clone_selected_files.indexOf(name)
                    _clone_selected_files.splice(_index, 1)
                }
                else {
                    _clone_selected_files.push(name)
                }
            }
            setSelectedFile(_clone_selected_files)
        }


        if (which === 'questions') {

            if (type === "selectAll") {

                let clone_selected = [];
                if ((selectedQuestions && selectedQuestions.length) === (localQuestions && localQuestions.length)) {
                    clone_selected = [];
                }
                else {
                    localQuestions && localQuestions.length > 0 && localQuestions.map((question, i) => {
                        clone_selected.push(question.name)
                    })
                }
                setSelectedQuestions(clone_selected)
            }

            if (type === 'single') {

                let clone_selected = JSON.parse(JSON.stringify(selectedQuestions));
                let _index = clone_selected && clone_selected.indexOf(name)
                if (clone_selected && clone_selected.indexOf(name) > -1) {
                    clone_selected.splice(_index, 1)
                }
                else {
                    clone_selected.push(name)
                }
                setSelectedQuestions(clone_selected)
            }
        }
    }



    const localRun = () => {
        let _base_path = (filesFromServer && filesFromServer.base_path) ? filesFromServer.base_path : undefined;

        if (selectedFile && selectedFile.length > 0 && selectedQuestions && selectedQuestions.length > 0 && selectedOutput) {
            saveSetOfQuestion(selectedQuestions, selectedFile, _base_path, selectedOutput)
            close(option, popupKey)
        }
        else {
            alert("Please select required field")
        }
    }


    return (
        <Wrapper>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <h1>Select your Questions and Files</h1>
            </div>

            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ width: '60%' }}>
                    <div style={{ marginBottom: '20px' }}>
                        <TextBox
                            label="Search Question"
                            value={questionSearch}
                            onChange={(e) => {
                                setQuestionSearch(e.target.value)
                                onSearch(e.target.value, 'questions')
                            }}
                        />
                    </div>


                    {!questionSearch && (
                        <Row key={"file"} all={true}>
                            <CheckBox checked={(selectedQuestions && selectedQuestions.length) === (localQuestions && localQuestions.length)} onClick={() => _click_on_selecte_file(undefined, 'selectAll', 'questions')} />
                            <div className="file_name" onClick={() => _click_on_selecte_file(undefined, 'selectAll', 'questions')}>
                                {(selectedQuestions && selectedQuestions.length) === (localQuestions && localQuestions.length) ? "Deselect All" : "Select All"}
                            </div>
                        </Row>
                    )}

                    <div className="list_wrapper">
                        {localQuestions && localQuestions.length > 0 && localQuestions.map((question, index) => {
                            return (
                                <Row key={"file" + index} title={question.name}>
                                    <CheckBox checked={selectedQuestions && selectedQuestions.indexOf(question.name) > -1} onClick={() => _click_on_selecte_file(question.name, 'single', 'questions')} />
                                    <div className="file_name" onClick={() => _click_on_selecte_file(question.name, 'single', 'questions')}>{question.name}</div>
                                </Row>
                            )
                        })}
                    </div>

                </div>



                <div style={{ width: '30%' }}>
                    <div style={{ marginBottom: '20px' }}>
                        <TextBox
                            label="Search File"
                            value={searchValue}
                            onChange={(e) => {
                                setSearchValue(e.target.value)
                                onSearch(e.target.value, 'files')
                            }}
                        />
                    </div>



                    {!searchValue && (
                        <Row key={"file"} all={true}>
                            <CheckBox checked={(selectedFile && selectedFile.length) === (FilesList && FilesList.length)} onClick={() => _click_on_selecte_file(undefined, 'selectAll', 'files')} />
                            <div className="file_name" onClick={() => _click_on_selecte_file(undefined, 'selectAll', 'files')}>
                                {(selectedFile && selectedFile.length) === (FilesList && FilesList.length) ? "Deselect All" : "Select All"}

                            </div>
                        </Row>
                    )}



                    <div className="list_wrapper">
                        {FilesList && FilesList.length > 0 && FilesList.map((file, index) => {
                            return (
                                <Row key={"file" + index} title={file}>
                                    <div style={{ width: '50px' }}>
                                        <CheckBox checked={selectedFile && selectedFile.indexOf(file) > -1} onClick={() => _click_on_selecte_file(file, 'single', 'files')} />
                                    </div>
                                    <div className="file_name" onClick={() => _click_on_selecte_file(file, 'single', 'files')}>{file}</div>
                                </Row>
                            )
                        })}
                    </div>
                </div>

            </div>

            <div style={{ marginTop: 'auto', width: '300px', marginLeft: 'auto' }}>

                <SelectOption
                    label="Output Type"
                    selections={output_types}
                    displayKey='name'
                    valueKey='value'
                    onChangeFunc={(value) => {
                        setSelectedOutput(value)
                    }}
                    activeValue={selectedOutput}
                    // width={'100%'}
                    width="100%"

                />

            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '20px' }}>
                <Button
                    width="6rem"
                    mR="1rem"
                    primary={true}
                    onClick={() => {
                        localRun();
                    }}>Run</Button>
                <Button width="6rem" bgColor={'#e6e6e6'}
                    onClick={() => {
                        close(option, popupKey)
                    }}
                >Cancel</Button>
            </div>
        </Wrapper>
    )
}

export default SelectFilePopup;
