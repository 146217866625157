import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { clearQuestionInfo, } from '../../../actions/etl';
import { RadioCheckMark } from '../../hoc/radiobox'
import TextBox from '../../hoc/textBox';
import { DivRow } from '../../styled-hoc';
import SelectOption from '../../select-option';
import SelectOptionV1 from '../../select-option/select.option.v1.1'
import { convertDateInToHtmlDate } from '../../../utils';
import { delete_frequency_types, frequencyTypes } from '../constants';

// import EditSvg from '../../svg/edit'
// import * as enums from '../../../utils/enums';
// import { showPopup } from '../../../utils';
// import RippleEffect from '../../hoc/ripple-effect'
// import { getHintsByDbInfo } from '../../../actions/hints';
// import JsonViewer from '../../train-your-polus/json-viewer';
// import { getTargetTableData, getCollectionsOfMongo, viewDirectoryFileAction } from '../../../actions/etl';
// import ViewSvg from '../../svg/view';
// import ViewFileInfo from './view.file.popup';


export const CardBox = styled.div`
    position: relative;
    padding: .5rem;
    box-sizing: border-box;
    margin-bottom: 2rem;

    /* &::before{
        content: '';
        height: 100%;
        width: 5px;
        background: ${props => props.bg ? props.bg : '#000'};
        position: absolute;
        left: -1rem;
        border-radius: 3px;
        top: 0px;
    } */

    h2{ 
        margin: 0px;
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 2rem;
        color: #222;
    }

    .card_row{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }

    .or_style{
        font-style: italic;
        display: inline-block;
        font-weight: bold;
        background: rgb(48 139 200);
        color: #fff;
        width: 2re;
        width: 2.7rem;
        height: 2.7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid #000;
    }
`;


const Index = (props) => {



    const {

        extraction_state,
        set_extraction_state,
        data_source_type,
        db_interaction_type,
        set_db_interaction_type,
        load_data,
        set_load_data,
        date_column_selection
    } = props;


    const on_change_v1 = (value, key) => {
        const clone_extraction_state = extraction_state ? { ...extraction_state } : {};
        clone_extraction_state[key] = value;
        set_extraction_state(clone_extraction_state);
    }


    const on_change_load = (value, key) => {
        const clone_load_data = load_data ? { ...load_data } : {};
        clone_load_data[key] = value;
        set_load_data(clone_load_data);
    }



    return (
        <div style={{ width: '100%' }}>
            {data_source_type === 'directory' && (
                <CardBox style={{ width: '100%', marginTop: '1rem' }} bg={"#03a9f4"}>
                    <h2>Data Source Inputs</h2>
                    <div className="card_row">
                        <div style={{ width: '35%', position: 'relative' }}>
                            <TextBox
                                type="text"
                                label={'Data Source Directory'}
                                value={extraction_state && extraction_state['main_directory'] ? extraction_state['main_directory'] : undefined}
                                onChange={(e) => {
                                    on_change_v1(e.target.value, 'main_directory')
                                }}
                            />


                        </div>
                        <div style={{ width: '20%' }}>
                            <SelectOption
                                width={"100%"}
                                label={"File Type"}
                                selections={["json", 'xml', 'csv']}
                                activeValue={extraction_state && extraction_state['extension'] ? extraction_state['extension'] : undefined}
                                onChangeFunc={(value) => {
                                    on_change_v1(value, 'extension')
                                }}

                            />
                        </div>

                        <div style={{ width: '30%' }}>
                            <SelectOption
                                width={"100%"}
                                label={"Choose Files To Process"}
                                selections={["All", 'Choose Your Files']}
                                value={extraction_state && extraction_state['landing_date'] ? extraction_state['landing_date'] : undefined}
                                onChange={(value) => {
                                    on_change_v1(value, 'landing_date')
                                }}
                            />
                        </div>


                    </div>
                </CardBox>
            )}




            {data_source_type === 'directory' && (
                <CardBox style={{ width: '100%' }} bg={'#851B6A'}>
                    <h2>Post Processing</h2>
                    <div className="card_row">
                        <div style={{ width: '40%', position: 'relative' }}>
                            <TextBox
                                type="text"
                                label="Directory where Processed Files will be Moved"
                                value={extraction_state && extraction_state['processed_directory'] ? extraction_state['processed_directory'] : undefined}
                                onChange={(e) => {
                                    on_change_v1(e.target.value, 'processed_directory')
                                }}
                            />
                        </div>
                        <div className="or_style">OR</div>
                        <div style={{ width: '40%', }}>
                            <TextBox
                                type="text"
                                label="Suffix to Rename Processed Files"
                                value={extraction_state && extraction_state['rename_prefix'] ? extraction_state['rename_prefix'] : undefined}
                                onChange={(e) => {
                                    on_change_v1(e.target.value, 'rename_prefix')
                                }}
                            />
                        </div>
                    </div>
                </CardBox>
            )}


            <div style={{
                width: '100%',
                background: '#fff',
                padding: '1rem 2rem',
                boxSizing: 'border-box',
                borderRadius: '8px',
                marginTop: '1rem',
                boxShadow: 'rgba(204, 204, 204, 0.64) 3px 4px 4px'

            }}>


                <div className="card_row" style={{ justifyContent: 'flex-start' }}>
                    {/* <DivRow w="20%" style={{ marginRight: '4rem', zIndex: 4 }}>
                        <SelectOption
                            option_z_index={30}
                            label={"Select Target DB"}
                            selections={props.db_infos && props.db_infos.length > 0 ? props.db_infos : []}
                            onChangeFunc={(value) => {
                                on_change_v1(value, "target_db_id")
                            }}
                            displayKey={"connection_name"}
                            valueKey={"id"}
                            groupKey='db_type'
                            sortKey='desc'
                            activeValue={extraction_state && extraction_state['target_db_id'] ? extraction_state['target_db_id'] : undefined}
                            isCheckBox={true}
                            isSearch={true}
                            width="100%"
                            height="10.857rem"
                            lineHeight="1.857rem"
                            borderColor='#eaeaea'
                            color='#7c7c7c'
                            addAction={() => {
                                props.history.push('/connection')
                            }}
                        />
                    </DivRow> */}

                    <DivRow w="25%">
                        <label className='job_form_label'>Data Loading Mode</label>
                        <div style={{ boxShadow: '#cccccc 0px 0px 6px 1px', borderRadius: '6px' }}>

                            <SelectOptionV1
                                hide_margin={true}
                                none_value={false}
                                options={['Append', 'Upsert', 'complete_refresh']}
                                hide_border={true}
                                on_change={(value) => {
                                    on_change_v1(value, "data_loading_mode")
                                }}
                                value={extraction_state?.['data_loading_mode']}
                                inputStyle={{ borderRadius: '6px' }}

                            />
                        </div>
                    </DivRow>
                </div>
                <div className="card_row" style={{ justifyContent: 'flex-start' }}>
                    <DivRow w="25%">
                        <p style={{
                            fontSize: '.8rem',
                            color: ' #666',
                            margin: '0px',
                            margin: '6px 0px',
                            marginTop: '1.3rem'

                        }}>Then Choose your primary key/ column having unique values</p>
                        <div style={{ boxShadow: '#cccccc 0px 0px 6px 1px', borderRadius: '6px' }}>
                            <SelectOptionV1
                                hide_margin={true}
                                none_value={false}
                                options={props?.primary_key_selections || []}
                                hide_border={true}
                                on_change={(value) => {
                                    on_change_v1(value, "primary_column")
                                }}
                                value={extraction_state?.["primary_column"]}
                                inputStyle={{ borderRadius: '6px' }}

                            />
                        </div>
                    </DivRow>
                </div>

                    <div style={{
                        width: '100%',
                        height: '1px',
                        background: '#ccc',
                        margin: '2rem auto'
                    }}></div>

                <h2 className='inner_title' style={{ border: '0px' }}>Would you like to delete data before loading?</h2>

                <div>

                    <React.Fragment>
                        <div className="flex_row" style={{marginBottom: '1rem'}}>
                            <DivRow w="25%">
                                <label className='job_form_label'>Specify Delete Column</label>

                                <div style={{ boxShadow: '0px 0px 6px 1px #ccc', borderRadius: '6px' }}>
                                    <SelectOptionV1
                                        hide_margin={true}
                                        none_value={false}
                                        // label={"Specify Delete Column"}
                                        options={date_column_selection || []}
                                        multiple={true}
                                        checkbox={true}
                                        hide_border={true}
                                        inputStyle={{ borderRadius: '6px' }}
                                        value={load_data?.delete_date_column || undefined}
                                        on_change={(v) => {
                                            const clone_load_data = load_data ? { ...load_data } : {};
                                            const clone_incremental_date_column = load_data?.delete_date_column || [];
                                            if (clone_incremental_date_column?.indexOf(v) == -1) {
                                                clone_incremental_date_column.push(v)
                                            } else {
                                                clone_incremental_date_column.splice(clone_incremental_date_column.indexOf(v), 1)
                                            }
                                            clone_load_data.delete_date_column = clone_incremental_date_column;
                                            set_load_data(clone_load_data)
                                        }}
                                    />
                                </div>
                            </DivRow>


                        </div>
                        <div className="flex_row" style={{ width: '52%', marginBottom: '1rem' }}>
                            <DivRow w="48.2%">
                                <label className='job_form_label'>Start Date</label>
                                <input
                                    type="datetime-local"
                                    className='job_form'
                                    style={{background: 'rgb(232, 232, 249)'}}
                                    value={load_data && load_data.delete_start_date ? convertDateInToHtmlDate(load_data.delete_start_date) : undefined}
                                    onChange={(e) => {
                                        on_change_load(e.target.value, 'delete_start_date')

                                    }}
                                />
                            </DivRow>

                            <DivRow w="48.2%" >
                                <label className='job_form_label'>End Date</label>
                                <input
                                    type="datetime-local"
                                    className='job_form'
                                    style={{background: 'rgb(232, 232, 249)'}}

                                    value={load_data?.delete_end_date ? convertDateInToHtmlDate(load_data.delete_end_date, 'delete_end_date') : undefined}
                                    onChange={(e) => {
                                        // onChangeFunction(e.target.value, 'next_run_at')
                                        on_change_load(e.target.value, 'delete_end_date')
                                    }}
                                />
                            </DivRow>

                        </div>
                        <div className="flex_row">
                            <DivRow w="25%">
                                <DivRow w="100%">
                                    <label className='job_form_label'>Frequency</label>
                                </DivRow>
                                <select
                                    className='job_form'
                                    value={load_data?.delete_frequency ? load_data.delete_frequency : undefined}
                                    style={{ width: '100%', height: '2.3rem', backgroundColor: '#fff' }}
                                    onChange={(e) => {
                                        on_change_load(e.target.value, 'delete_frequency')
                                    }}>
                                    <option value="">--Select Type--</option>
                                    {delete_frequency_types?.map((item) => {
                                        return (
                                            <option value={item.value}>{item.name}</option>
                                        )
                                    })}
                                </select>
                            </DivRow>
                        </div>
                    </React.Fragment>
                </div>
            </div>
            {/* <div style={{
                width: '100%',
                background: '#fff',
                padding: '1rem 2rem',
                boxSizing: 'border-box',
                borderRadius: '8px',
                marginTop: '1rem',
                boxShadow: 'rgba(204, 204, 204, 0.64) 3px 4px 4px'

            }}>
               
            </div> */}
        </div>
    )
}



const mapStateToProps = state => ({
    collectionsOfMongo: state.EtlReducer.collectionsOfMongo,
    extraction: state.dataWrangler.extraction,
    db_infos: state.connectionReducer.db_infos,


})




export default connect(mapStateToProps, {

})(Index)
