import React, { useEffect } from 'react';
import Editor from '../editor.shared/index.editor';

const Index = (props) => {

    useEffect(() => {
        let _page_info = {
            title: 'Data Source Editor',
            isBack: true,
        }
        props.dispatchHeaderInformation(_page_info)
    }, [])

    return (
        <div>
            <Editor
                {...props}
                type={"data_source"}
                hide_chart_type={true}
                hide_theme_editor={true}
                hide_menu_group={true}
                is_data_source_editor={true}
            />

        </div>
    )
}

export default Index
