import React from 'react';

import { Wrapper, Word } from './auto.complete.styled';

const AutoComplete = props => {

    const { setWord, words } = props;

    return (
        <Wrapper>
            {
                words && words.length > 0 && words.map(word => {
                    return (
                        <Word
                            onClick={() =>  setWord(word)}
                        >
                            {word}
                        </Word>
                    )
                })
            }
        </Wrapper>
    );
};

export default AutoComplete;