import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { getDataOfTable, saveNewRecord } from '../../actions/sample.demo';
import { withRouter } from 'react-router-dom';
import { getParameterByName } from '../../utils';
import SharedTable from '../shared-table';
import { PageInnerWrapper } from '../hoc/styled.hoc';
import TitleHeader from '../title-header';
import { Button } from '../hoc/button'
import { showPopup } from '../../utils';
import * as enums from '../../utils/enums.js';
import AddPopup from './add.popup'




const Index = (props) => {

    const { getDataOfTable, tableData, history } = props;
    const { location } = history;

    const [tableDisabledKey, setTableDisplayKey] = useState(undefined);
    // const [tableThead, setTableThead] = useState(undefined);
    const [tableName, setTableName] = useState(undefined);
    const [dbName, setDbName] = useState(undefined);


    useEffect(() => {
        let _page_info = {title: tableName ? tableName : ''}
        props.dispatchHeaderInformation(_page_info)
    },[tableName])


    useEffect(() => {

        if (location && location.pathname === '/demo_view') {
            let db_name = getParameterByName("db_name", location.search);
            let table_name = getParameterByName("table_name", location.search);
            setTableName(table_name)
            setDbName(db_name)
            if (db_name && table_name) {
                getDataOfTable(db_name, table_name)
            }
        }

    }, [location])


    useEffect(() => {

        let tempTableHead = tableData && tableData.length > 0 ? tableData[0] : undefined;

        let filterd_tbody = tempTableHead && Object.keys(tempTableHead) && Object.keys(tempTableHead).length > 0 && Object.keys(tempTableHead).filter(key => {
            if (key === 'created_by') return false;
            if (key === 'updated_by') return false;
            if (key === 'created_at') return false;
            if (key === 'updated_at') return false;
            if (key === 'modified_at') return false;
            if (key === 'modified_by') return false;
            // if (key === 'date') return false;
            if (key === 'is_active') return false;
            else return true;
        })

        let fillterd_thead = filterd_tbody && filterd_tbody.length > 0 && filterd_tbody.map((item) => {
            if (item && item.indexOf('_')) {
                return item.split('_').join(' ')
            } else {
                return item
            }
        })

        // setTableThead(fillterd_thead)
        setTableDisplayKey(filterd_tbody)

    }, [tableData])


    const addNewRecord = (records) => {
        let temp_record = [];
        temp_record.push(records)
        props.saveNewRecord(dbName, tableName, temp_record)
        setTimeout(() => {
            getDataOfTable(dbName, tableName)
        }, 100)

    }

    const AddNewRecordClicked = () => {
        showPopup(undefined, undefined, enums.default.popupType.element, AddPopup, {
            keys: tableDisabledKey,
            addNewRecord: addNewRecord
        })
    }

    return (
        <div>
            <TitleHeader
                style={{ paddingLeft: '0', marginBottom: '-10px', textAlign: 'center' }}
                title={tableName ? tableName : ''}
                extraOption={<Button onClick={AddNewRecordClicked}>Add</Button>}
            />
            <PageInnerWrapper padding={'10px'}>
                <SharedTable
                    height={"calc(100vh - 180px)"}
                    tableData={(tableData && tableData.length > 0) ? tableData : undefined}
                    columnsToShow={tableDisabledKey}
                    tableHead={tableDisabledKey}
                />
            </PageInnerWrapper>

        </div>
    )
}



const mapStateToProps = (state) => ({
    tableData: state.sampleDemoReducer.tableData
})



export default withRouter(connect(mapStateToProps, { getDataOfTable, saveNewRecord })(Index))
