export default {
    jobDetails: {
        database: 1.1,
        table: 1.2,
        column: 1.3
    },

    requestType: {
        put: 2.1,
        post: 2.2,
        get: 2.3,
        delete: 2.4
    },

    popupType: {
        ok: 3.1,
        confirm: 3.2,
        element: 3.3,
        sqlQuery: 3.4,
        reportAddition: 3.5,
        element_with_header: 3.6,
        sweet_alert: 5.6,
        loading: 5.7
    },

    popupButton: {
        ok: 4.1,
        yes: 4.2,
        no: 4.3
    }

};