import { s_p } from "../../utils/v1.1";
import { create_display_column_by_column_info } from "../editor.shared/builder.shared.functions";

const DATA_TYPES = require("../../shared-with-fe/data.types");


export const SQL_FORMULA_TEMPLETE = {
    "mysql": {
        "DATEDIFF": [
            {
                "type": "nf_sp",
                "expr": "(",
            },
            {
                "type": "plain_text",
                "expr": "",
                "placholder": "Type or Drag Column",
            },
            {
                "type": "nf_sp",
                "expr": ","
            },
            {
                "type": "plain_text",
                "expr": "",
                "placholder": "Type or Drag Column",

            },
            {
                "type": "nf_sp",
                "expr": ")"
            },
        ],
        "DATE_ADD": [
            {
                "type": "nf_sp",
                "expr": "(",
            },
            {
                "type": "plain_text",
                "expr": "",
                "placholder": "Type or Drag Column",

            },
            {
                "type": "nf_sp",
                "expr": ","
            },
            {
                "type": "plain_text",
                "expr": "INTERVAL 10 DAY",
                "placholder": "INTERVAL 10 DAY",

            },
            {
                "type": "nf_sp",
                "expr": ")",
            },
        ],
        "CONCAT": [
            {
                "type": "nf_sp",
                "expr": "(",
            },
            {
                "type": "plain_text",
                "expr": "",
                "placholder": "Type or Drag Column",

            },
            {
                "type": "nf_sp",
                "expr": ","
            },
            {
                "type": "plain_text",
                "expr": "",
                "placholder": "Type or Drag Column",

            },
            {
                "type": "nf_sp",
                "expr": ")",
            },
        ]
    }

}

const keywords = {
    CASE: {
        column: '<%COLUMN_NAME%>',
        condition: '<%CONDITION%>',
        action: '<%ACTION%>',
        case_content: '<%CASE_CONTENT%>',
        case_name: '<%CASE_NAME%>'
    },

    MATHEMATICAL: {
        sum: 'SUM_WHAT',
        avg: 'AVG_WHAT',
        count: 'COUNT_WHAT',
        max: 'MAX_WHAT',
        min: 'MIN_WHAT',
    }
};


const templates = {
    CASE: {
        WHEN: `WHEN <%COLUMN_NAME%> <%CONDITIONAL%>`,
        THEN: `THEN <%ACTION%>`,
        ELSE: `ELSE <%ACTION%>`,
        FULL: `
			CASE
			<%CASE_CONTENT%>
			END AS <%CASE_NAME%>      
	    `
    },

    SUM: `SUM(<%SUM_WHAT%>)`,
    AVG: `AVG(<%AVG_WHAT%>)`,
    COUNT: `COUNT(<%COUNT_WHAT%>)`,
    MIN: `MIN(<%MIN_WHAT%>)`,
    MAX: `MAX(<%MAX_WHAT%>)`,
    UCOUNT: `COUNT( distinct <%COUNT_WHAT%>)`
};


const enums = {
    CASE_STAGES: {
        NONE: 'none',
        WHEN: 'when',
        AFTER_WHEN: 'after_when',
        OP_PRE_THN: 'op_pre_then', // mathematical, conditional, or extension 
        AFTER_OP_PRE_THN: 'aftr_op_pre_then',
        THEN: 'then',
        AFTER_THEN: 'after_then',
        OP_POST_THN: 'op_post_then', // mathematical, conditional, or extension 
        AFTER_OP_POST_THN: 'aftr_op_post_then',
    },
    IFS: {
        NONE: 'none',
        COLUMN: 'column',
        CONDITIONAL_OPERATOR: 'conditional',
        EXTENSION_OPERATOR: 'and_or_operator',
        MATHEMATICAL_OPERATOR: 'mathematical',
        THEN: 'then',
        TEXT: 'plain_text',
        NF_SP: 'nf_sp',
        SQL_DB_FORMULA: 'db_function'

    },
};




export const get_start_and_end_group_exp = (grouped_items, index) => {

    var start_exp = '';
    var end_exp = '';

    (grouped_items || []).map((el) => {
        if (
            index == el.start &&
            el.start !== -1 &&
            el.end !== -1) {
            if (el.group_type === "date_diff") {
                start_exp += "datediff( "
                end_exp += ","
            } else {
                start_exp += "( "
            }
        }
    });

    (grouped_items || []).map((el) => {
        if (
            index == el.end &&
            el.start !== -1 &&
            el.end !== -1) {
            if (el.group_type === "date_diff") {
                end_exp += ") "
            } else {
                end_exp += ") "
            }
        }
    })

    return {
        start: start_exp,
        end: end_exp
    }
}

/**
 * 
 * @param {*} editedFormula 
 * @returns 
 * this function is used for convert db data into UI DATA FORMATS
 */
export const get_formula_in_formatted_input = (editedFormula) => {

    const cloned_edited_formula = editedFormula ? JSON.parse(JSON.stringify(editedFormula)) : {};

    const formula_type = cloned_edited_formula?.formula_type;

    const nf_formula_columns = (cloned_edited_formula.nf_formula_columns || []).sort((a, b) => a.order - b.order);

    const child_formulas = {};
    const filterd_parent_formula = {};


    if (nf_formula_columns?.length > 0) {

        nf_formula_columns.forEach((f) => {

            if (f.condition_type) {

                // if (!filterd_parent_formula[f.condition_type]) filterd_parent_formula[f.condition_type] = [];

                if (f?.child_conditions?.length > 0) {

                    if (filterd_parent_formula.parent_id) {
                        filterd_parent_formula[f.condition_type].push(f)
                    }

                    if (!child_formulas[f.id]) {
                        const sorted_child_conditions = f.child_conditions.sort((a, b) => a.order - b.order)
                        if (sorted_child_conditions?.length > 0) {
                            child_formulas[f.id] = { "if": sorted_child_conditions || f.child_conditions }
                        }
                    }
                }
                // else {
                //     filterd_parent_formula[f.condition_type].push(f)
                // }
            }

        })
    }

    const post_else_ifs = [];
    const pre_else_ifs = (filterd_parent_formula?.["else_ifs"] || []).sort((a, b) => a.array_index - b.array_index);

    (pre_else_ifs || []).map((else_if) => {
        const __index = else_if.array_index;
        if (!post_else_ifs[__index]) {
            post_else_ifs[__index] = [];
        }
        post_else_ifs[__index].push(else_if)

    })

    filterd_parent_formula["else_ifs"] = post_else_ifs;

    // let's add some default
    if (!filterd_parent_formula["else"]) {
        filterd_parent_formula["else"] = [];
    }
    if (!filterd_parent_formula["else_ifs"] || filterd_parent_formula["else_ifs"].length == 0) {
        filterd_parent_formula["else_ifs"] = [[]];
    }



    // filterd_parent_formula


    function createNestedObjects(__objects) {

        const objects = __objects ? JSON.parse(JSON.stringify(__objects)).filter((a => a.condition_type)) : []

        const nestedObjects = {
            "if": [],
            "else_ifs": [],
            "else": []
        };

        (objects || []).forEach(object => {

            if (!object.parent_column_id || object.parent_column_id === null) {
                if (object?.condition_type === "else_ifs") {
                    const _index = object.array_index || 0;
                    if (!nestedObjects[object.condition_type][_index]) nestedObjects[object.condition_type][_index] = []
                    nestedObjects[object.condition_type][_index].push(object);

                } else {
                    nestedObjects[object.condition_type].push(object);
                }
            }
            else {

                const parent = findParentObject(objects, object.parent_column_id);
                if (parent) {

                    if (!parent.inner_formula) {
                        parent.inner_formula = {
                            "if": [],
                            "else_ifs": [],
                            "else": []
                        };
                    }

                    if (object?.condition_type === "else_ifs") {

                        const _index = object.array_index || 0;
                        if (!parent.inner_formula[object.condition_type][_index]) parent.inner_formula[object.condition_type][_index] = []
                        parent.inner_formula[object.condition_type][_index].push(object);

                    } else {
                        parent.inner_formula[object.condition_type].push(object);
                    }
                }
            }
        });

        // if (nestedObjects['else_ifs']?.length == 0) {
            nestedObjects['else_ifs'].push([])
        // }
        return nestedObjects;
    }

    function findParentObject(objects, parent_column_id) {
        for (let i = 0; i < objects.length; i++) {
            if (objects[i].id === parent_column_id) {
                return objects[i];
            }
            if (objects[i].inner_formula) {
                const parent = findParentObject(objects[i].inner_formula, parent_column_id);
                if (parent) {
                    return parent;
                }
            }
        }
        return null;
    }


    if (formula_type === "nf_mathematical") {
        console.log("nf_formula_columns", nf_formula_columns)

        return {
            child_formulas: child_formulas,
            main_formulas: { "nf_mathematical": nf_formula_columns }
        }
    }
    else {
        const final_data = createNestedObjects(nf_formula_columns)

        console.log("__else", nf_formula_columns)

        return {
            child_formulas: child_formulas,
            main_formulas: final_data
        }
    }
}




var prev_el_b = false;

var is_prev_start_and_end_el = false;



// lets build the formulas
export const convert_ifs_to_case_statement = (ifs = [], type, grouped_items) => {

    let final_statement = '(';

    let statement = {
        CASE: '',
        CONDITION: '',
        THEN: '',
        NESTED: false
    };




    if (ifs.length == 0) return "";

    //child_conditions : []

    const expr_block_to_case = (wrapper, start_prefix, end_prefix, start_concat, end_concat) => {

        const is_formula = wrapper?.data_type === "db_formula";


        console.log("wrapper", wrapper)
        if (is_formula) {
            start_prefix += " __nf__rule__";
            if (end_prefix) {
                end_prefix = " __nf__rule__" + ") "
            } else {
                end_prefix = " __nf__rule__"
            }
        }


        if (prev_el_b) {
            prev_el_b = false;
            end_prefix += " ) "
        }
        

        if (wrapper?.expr === "IN" || wrapper?.expr === "NOT IN") {
            end_prefix += " ( ";
            prev_el_b = true;
        }

     
        if(is_prev_start_and_end_el){
            if(is_prev_start_and_end_el === "START WITH"){
                start_concat+= '%'
            }
            if(is_prev_start_and_end_el === "END WITH"){
                end_concat+= '%'
            }
            is_prev_start_and_end_el = false;
        }

        if(wrapper?.expr == "START WITH" || wrapper?.expr == "END WITH"){
            is_prev_start_and_end_el = wrapper?.expr;
        }




        switch (wrapper.type) {
            case enums.IFS.COLUMN:
            case enums.IFS.MATHEMATICAL_OPERATOR:
            case enums.IFS.EXTENSION_OPERATOR:
            case enums.IFS.TEXT:
            case enums.IFS.NF_SP:
            case enums.IFS.SQL_DB_FORMULA:

                if (statement.CONDITION === '') {
                    // pre condition
                    statement.CASE = statement.CASE + ' ' + (start_prefix + wrapper.expr + end_prefix);
                }
                else {
                    statement.THEN = statement.THEN + ' ' + (start_prefix + wrapper.expr + end_prefix);
                }
                break;
            case enums.IFS.CONDITIONAL_OPERATOR:
                if (wrapper.expr === 'IS NULL') {
                    statement.CASE = statement.CASE + ' ' + start_prefix + ' IS NULL' + end_prefix;
                }
                else if (wrapper.expr === 'IS NOT NULL') {
                    statement.CASE = statement.CASE + ' ' + start_prefix + ' IS NOT NULL' + end_prefix;
                }
                else if(wrapper.expr === 'START WITH'){
                    statement.CASE = statement.CASE + ' ' + start_prefix + ' LIKE ' + end_prefix;

                }
                else if(wrapper.expr === 'END WITH'){
                    statement.CASE = statement.CASE + ' ' + start_prefix + ' LIKE ' + end_prefix;

                }
                else {
                    statement.CASE = statement.CASE + ' ' + start_prefix + (wrapper.expr + end_prefix);
                }
                break;
            case enums.IFS.THEN:
            case "Then":
            case "THEN":
                statement.CONDITION = 'done';
                // if we have received THEN
                break;
        }

    };


    ifs.forEach((__if__, index) => {
        // let's loop the child

        const group_exp = get_start_and_end_group_exp(grouped_items, index);


        if (__if__?.child_conditions?.length > 0) {

            var agg_query = (__if__.aggregation || "sum") + "(";

            agg_query += " CASE " + convert_ifs_to_case_statement(__if__?.child_conditions, group_exp?.start, group_exp?.end);

            agg_query += "`" + __if__.table_name + "`" + "." + __if__.column_name + "";

            const __else__ = __if__.aggregation === "sum" ? 0 : ' NULL ';

            agg_query += " ELSE " + __else__ + " END " + ")"

            statement.CASE += " " + agg_query;

        }

        else if (__if__.inner_formula && __if__.type === "wrapper" && __if__.inner_formula["if"]?.length > 0) {

            // let's create nested CAES
            var __case__ = get_case_statement_v1(__if__.inner_formula, group_exp?.start, group_exp?.end);

            statement.NESTED = true;

            statement.CASE += " THEN " + __case__;
        }

        else {
            console.log("statement", __if__, group_exp)

            expr_block_to_case(__if__, group_exp?.start, group_exp?.end);
        }

    });



    if (type !== "else") {

        return ' WHEN ' + statement.CASE + (statement.NESTED ? statement.THEN : (' THEN ' + statement.THEN))

    } else {

        return ' ' + statement.CASE// + ' THEN ' + statement.THEN
    }


    // return statement;

};




const get_case_statement_v1 = (condition_to_use, formula_type, grouped_items) => {


    var case_statement = '';

    if (formula_type === 'nf_mathematical') {

        // console.log("grouped_items?.['nf_mathematical']", )
        // let's create simple mathematical formula 

        (condition_to_use?.["nf_mathematical"] || [])?.forEach((condition, index) => {

            const group_exp = get_start_and_end_group_exp(grouped_items?.['nf_mathematical'], index)

            if (condition?.child_conditions?.length > 0) {

                var agg_query = group_exp?.start + (condition.aggregation || "sum") + "("
                agg_query += "CASE " + convert_ifs_to_case_statement(condition?.child_conditions, grouped_items?.['nf_mathematical'])
                agg_query += "`" + condition.table_name + "`" + "." + condition.column_name + ""

                var else_value = condition.aggregation == "sum" ? 0 : 'NULL'
                agg_query += " ELSE " + else_value + " END " + ")"
                case_statement += " " + agg_query + group_exp?.end;

            }
            else {
                const is_formula = condition?.data_type === "db_formula";
                
                if (is_formula) {
                    group_exp.start += " __nf__rule__";
                    if (group_exp.end) {
                        group_exp.end = " __nf__rule__" + ") "
                    } else {
                        group_exp.end = " __nf__rule__"
                    }
                }
                // console.log("grouped_items", condition, group_exp)
                case_statement += group_exp?.start + condition?.expr + " " + group_exp?.end + ' '
            }
        })

    } else {

        let case_st = convert_ifs_to_case_statement(condition_to_use?.if, undefined, grouped_items?.["if"])

        if (condition_to_use.else_ifs?.length > 0) {

            condition_to_use.else_ifs.forEach(else_if => {
                if (else_if?.length > 0) {
                    let s1 = convert_ifs_to_case_statement(else_if, undefined, grouped_items?.["else_ifs"]);
                    case_st = case_st + ' ' + s1;
                }
            });
        }

        if (condition_to_use?.else?.length > 0) {

            case_st = case_st + ' ' + ' ELSE ' + convert_ifs_to_case_statement(condition_to_use?.else, 'else', grouped_items?.["else"]);

        } else {

            case_st = case_st + ' ' + ' ELSE ' + '"TEST"' //sample_obj.ELSE.expr;
        }

        const case_st_post = 'CASE ' + case_st + ' END '// + final_templates.name //+ ';'

        case_statement = case_st_post

    }

    return case_statement;

}


/**
 *
 */
export const get_data_into_saved_format = (templates, nf_formula_columns, column_ifs_conditions, grouped_items) => {

    const final_templates = templates ? { ...templates } : {};

    // let's assign_column_info_to_column_ifs_conditions

    const formula_type = templates?.formula_type;


    const condition_to_use = nf_formula_columns ? { ...nf_formula_columns } : {};
    // let's format local formulas into db formula

    if (column_ifs_conditions && Object.keys(column_ifs_conditions)?.length > 0) {
        Object.keys(column_ifs_conditions).forEach((key) => {

            if (formula_type === "nf_mathematical") {
                if (condition_to_use?.["nf_mathematical"]) {
                    condition_to_use?.['nf_mathematical'].map((f) => {
                        if (f.id === key) {
                            f.child_conditions = column_ifs_conditions?.[key]?.["if"]
                        }
                    })
                }
            }

            else {
                if (condition_to_use?.["if"]) {
                    condition_to_use?.['if'].map((f) => {
                        if (f.id === key) {
                            f.child_conditions = column_ifs_conditions?.[key]?.["if"]
                        }
                    })
                }

                if (condition_to_use?.["else"]) {
                    condition_to_use?.['else'].map((f) => {
                        if (f.id === key) {
                            f.child_conditions = column_ifs_conditions?.[key]?.["if"]
                        }
                    })
                }

                if (condition_to_use?.["else_ifs"]) {
                    condition_to_use?.['else_ifs'].map((else_if) => {
                        if (else_if && else_if?.length > 0) {
                            else_if.forEach((f) => {
                                if (f.id === key) f.child_conditions = column_ifs_conditions?.[key]?.["if"]
                            })
                        }
                    })
                }
            }
        })
    }


    var case_statement = get_case_statement_v1(condition_to_use, formula_type, grouped_items)

    final_templates.nf_formula_columns = condition_to_use;
    final_templates.case_statement = case_statement;
    final_templates.modefied = true;
    final_templates.grouped_items = JSON.stringify((grouped_items || []));

    console.log("case_statement", "\n\n" + case_statement)

    return final_templates;

}




export const column_proprty_update = (functions_config, activeColumn, key, value) => {

    const current_column = activeColumn ? { ...activeColumn } : {}

    current_column[key] = value;
    let __value__ = current_column?.expr;

    current_column["orignal_name"] = current_column["column_name"]

    const c_info = create_display_column_by_column_info(functions_config, current_column, "mysql", "expr")

    return c_info

}

