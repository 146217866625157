import { colors } from './colors'

export const configs = {

    label: {
        color: colors.codGray,

    },

    button: {
        color: {
            background: colors.primary,
        }
    },
    svgConfig: {
        color: {
            hover: colors.primary
        }
    },
    textConfig: {
        color: {
            hover: colors.primary
        }
    },


    textbox: {
        border: {
            radius: '5px',
            color: '#ccc',
            thickness: '1.2px',
            hoverColor: "#8395af",
            focusColor: '#8395af',
        },
        color: colors.codGray,
        background: colors.white,

    },

    selectBoxConfig: {
        border: {
            radius: '5px',
            color: '#ccc',
            thickness: '1.2px',
            hoverColor: "#8395af",
            focusColor: '#8395af'
        },
        color: colors.codGray,
        background: colors.white,

    },


    header: {
        color: {
            background: colors.white,
            hamburger: colors.primary,
            home: colors.primary,
            search: colors.primary,
            voice: colors.primary,
            searchBoxBorder: colors.codGray,
            searchBoxPlacholder: colors.nightRiderGray,
            searchBoxText: colors.nightRiderGray,
            autoSuggestTxt: colors.codGray,
        }
    },

    righterMenuConfig: {
        menu: {
            color: {
                icon: colors.primary,
                iconWrapperActive: colors.lightPrimary,
            },
            border: {
                iconWrapper: colors.gray
            }
        },
        chartOptions: {
           color: {
                icon: colors.primary,
                iconActive: colors.lightPrimary,
                iconDisable: '#c3c3c3',
                iconWrapperActive: colors.lightPrimary
           },

        }
    },
    chartOptionsConfig: {
        color: {
            icon: colors.primary,
            iconActive: colors.primary,
            iconDisable: '#c3c3c3',

        }
    },


    lefterMenuConfig: {
        color: {
            backgroundLeft: colors.sixth,
            backgroundRight: colors.primary,
            text: colors.white,
            backgroundActive: colors.primary,
            backIcon: colors.white
        }
    },


    table: {
        color: {
            thBackground: colors.primary,
            thText: colors.white,
            tdText: colors.codGray,
            background: colors.lightGray
        }

    },


    filter: {
        color: {
            background: colors.seventh
        }
    },

    titleHeader: {
        color: {
            titleText: colors.codGray,
            options: '#333333',
            optionsHover: colors.primary
        }
    },

    reportList: {
        color: {
            actionBackground: colors.lightGray,
            hover: colors.white,
        },

    },
    report: {
        color: {

        }
    },
}