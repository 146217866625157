import React, { Component } from 'react';

import ColorBox from '../../color-box';
import OverLay from '../../helper/overlay';
import { ColorCell } from '../../table/table.styled';
import { Wrapper, ContentWrapper, LegendBoxWrapper } from './legend.styled';


class Legend extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showLegendForYac: {},
            colorsInCookie: [],
            showOverlay: false
        }
    }

    componentDidMount() {
        const { yDataKeys } = this.props;

        /* 
            * yDataKeys are coming from props.
            * In componentDidMount we are creating a object for each yDataKey and setting its value FALSE. 
        */

        if ((yDataKeys && yDataKeys.length > 0)) {
            const showLegendForYac = {};

            yDataKeys.forEach(column => {
                showLegendForYac[column] = false
            });

            this.setState({
                showLegendForYac
            });
        }
    }

    /* 
        * showLegend()
            * This function helps to identify like for which yDataKey we have to show the colorBox.
    */

    showLegend = key => {
        const temp = Object.assign({}, this.state.showLegendForYac);
        let showOverlay = false;

        Object.keys(temp).forEach(keyInLoop => {
            if (keyInLoop === key) {
                temp[key] = !temp[key];
                showOverlay = true
            }
            else {
                temp[keyInLoop] = false;
            }
        });

        this.setState({
            showLegendForYac: Object.assign({}, temp),
            showOverlay
        });
    };

    overlayFunc = () => {
        const temp = Object.assign({}, this.state.showLegendForYac);

        Object.keys(temp).forEach(keyInLoop => {
            temp[keyInLoop] = false;
        })

        this.setState({
            showLegendForYac: Object.assign({}, temp),
            showOverlay: false
        })
    }

    render() {
        const { yDataKeys, colors, changeColor, recentColors, heightOfChart } = this.props;

        return (
            <Wrapper>
                {this.state.showOverlay && <OverLay onClick={this.overlayFunc} />}
                {
                    yDataKeys && yDataKeys.length > 0 && yDataKeys.map((yData, index) => {
                        /* 
                            * Showing label for each yDataKey
                        */
                        return (
                            <ContentWrapper key={index} onClick={() => this.showLegend(yData)}>
                                <div style={{ color: colors[index], fontWeight: 'bold', letterSpacing: '1px', fontSize: '1rem' }}>{yData}</div>
                                <LegendBoxWrapper >
                                    <ColorCell color={colors[index]} />
                                    <div
                                        style={{ fontSize: '10px', marginLeft: '2px' }}
                                    >
                                        &#9660;
                                    </div>
                                    {/* 
                                        * Showing colorBox for particular data key whose key is true
                                    */}
                                    {this.state.showLegendForYac[yData] &&
                                        <ColorBox
                                            changeColor={changeColor}
                                            forWhat={index}
                                            showMoreColors={true}
                                            colors={colors}
                                            recentColors={recentColors}
                                            heightOfChart={heightOfChart}
                                        />
                                    }
                                </LegendBoxWrapper>
                            </ContentWrapper>
                        )
                    })
                }
            </Wrapper>
        );
    }
}

export default Legend;