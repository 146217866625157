import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import DropDownSvg from '../svg/dropDown'
import CloseSvg from '../svg/close'
import { Button } from '../hoc/button';
import Overlay from '../helper/overlay';
import CheckBox from '../hoc/checkbox';


const IncColumnPicker = styled.div`
   
    width: 100%;
    position: relative;

    .selected_title{
            margin: 13px 10px 8px;
            font-size: .9rem;
            text-transform: uppercase;
            /* color: rebeccapurple; */
            font-weight: 600;
            color: #555;
    }

    .select_arrow{

        position: absolute;
        right: 10px;
        top: 0rem;
        height: 100%;
        color: #334a53;
        font-size: 1.2rem;
        text-align: center;
        transform: ${props => props.isDropdown ? "rotate(180deg)" : undefined};
        display: flex;
        align-items: center;
        justify-content: center;

    }



    .choose_column{

        width: 100%;
        color: #0000008a;
        font-size: 1rem;
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;;
        position: relative  ;
    }

    .dropdown_menu{ 

        position: absolute;
        background: #fff;
        z-index: 3;
        box-shadow: 1px 4px 7px 1px #ccc;
        padding: 8px;
        box-sizing: border-box;
        width: 100%;
        background-color: #fff;;
        left: 0px;

        .line_item{

            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom: 1px solid #efefef;
            cursor: pointer;
            color: #727c80;
            box-sizing: border-box;
            font-size: .9rem;

         }
    }

    p{
        margin: 0px;
        font-size: 1rem;
        font-weight: 600;
    }

    .selected_item{

        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;

        .name{
             max-width: 90%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-weight: 500;
            font-size: .9rem;

        }

        .delete{
            cursor: pointer;
            svg{
                fill: red;
            }
        }
    }

    
`;



const Index = (props) => {

    const { incremental_date_column, options_date_columns, on_save } = props;

    const [show_dropdown, set_show_dropdown] = useState()

    const [local_incremental_date_column, set_local_incremental_date_column] = useState();

    const [local_selected_items, set_local_selected_items] = useState()


    useEffect(() => {
        const incremental_date_column_temp = incremental_date_column && Array.isArray(incremental_date_column) ? incremental_date_column : incremental_date_column?.split(",");
        set_local_incremental_date_column(incremental_date_column_temp)
    }, [incremental_date_column])



    const click_on_item = (key) => {

        const clone_local_selected_items = local_selected_items ? [...local_selected_items] : [];

        if (clone_local_selected_items?.indexOf(key) > -1) {
            clone_local_selected_items.splice(clone_local_selected_items?.indexOf(key), 1);

        } else {
            clone_local_selected_items.push(key);
        }
        set_local_selected_items(clone_local_selected_items)
    }


    const delete_item = (key, index) => {

        const clone_incremental_state = local_incremental_date_column || [];
        clone_incremental_state.splice(clone_incremental_state.indexOf(key), 1);
        on_save(clone_incremental_state)

    }

    const click_over_select_btn = () => {

        if (local_selected_items?.length === 0) return false;

        const clone_incremental_state = local_incremental_date_column || [];

        let key = local_selected_items?.join("__nfx__");

        if (clone_incremental_state?.indexOf(key) > -1) {
            alert("already added")
            // clone_incremental_state.splice(clone_incremental_state.indexOf(key), 1)
        } else {
            // add 
            clone_incremental_state.push(key)

        }
        set_local_selected_items([])
        on_save(clone_incremental_state)
        set_show_dropdown(false)

    }


    console.log("local_incremental_date_column", local_incremental_date_column)


    return (
        <div>
            {show_dropdown && (
                <Overlay onClick={() => {
                    set_show_dropdown(false)
                }} />
            )}

            <IncColumnPicker isDropdown={show_dropdown}>

                <div className='choose_column' onClick={() => {
                    set_show_dropdown(!show_dropdown);
                }}>
                    Incremental Columns Selections

                    <span className="select_arrow"> <DropDownSvg size={'11px'} /> </span>
                </div>

                {
                    local_incremental_date_column?.length > 0 && (

                        <div style={{ borderBottom: '1px solid #ccc', paddingBottom: '10px' }}>
                            <p className="selected_title">Selected Combinations</p>
                            <div style={{ marginLeft: '10px' }}>

                                {local_incremental_date_column?.map((item, index) => {

                                    const display_name_post = item?.split("__data__type__").join(" ")?.split("__nfx__").join(" + ")

                                    return (
                                        <div className='selected_item'>
                                            <div className='name' title={display_name_post}>{display_name_post}</div>
                                            <div className='delete'
                                                title="Delete"
                                                onClick={() => {
                                                    delete_item(item, index)
                                                }}>
                                                <CloseSvg size=".7rem" height=".7rem" />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                }

                {show_dropdown && options_date_columns?.length > 0 && (
                    <div>
                        <div className='dropdown_menu'>
                            {options_date_columns?.map((item) => {
                                return (
                                    <div className='line_item' onClick={() => {
                                        click_on_item(item)
                                    }}>
                                        <CheckBox
                                            onClick={() => {
                                                click_on_item(item)
                                            }}
                                            checked={local_selected_items?.indexOf(item) > -1} />
                                        {item?.split("__data__type__")[0]}</div>
                                )
                            })}
                            <div style={{ marginTop: '1rem' }}>
                                <Button onClick={() => {
                                    click_over_select_btn()
                                }}>Select</Button>
                            </div>
                        </div>

                    </div>
                )}
            </IncColumnPicker>
        </div>
    )
}

export default Index;