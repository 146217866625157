import React from 'react';

const Menu = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}

            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            space="preserve">
           <g>
	<rect width="512" height="83.5"/>
	<rect y="214.3" width="512" height="83.5"/>
	<rect y="428.5" width="512" height="83.5"/>
</g>
        </svg>

    )

}


export default Menu;