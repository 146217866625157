import * as actions from '../action-types/heatmap.configration';

const initialState = {
    type: actions.HEATMAP_CONFIGRATION_INIT,
    heatmapConfigration: undefined
};



const heatmapConfigrationReducer = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        case actions.GET_HEATMAP_CONFIGRATION_SUCCESS:
            return {
                ...state,
                type: action.type,
                heatmapConfigration: action.data
            };

        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
            return {
                type: state.type,
                heatmapConfigration: state.heatmapConfigration
            };
    }
};

export default heatmapConfigrationReducer;