import React, { useState, useEffect } from 'react';

import { HintsWrapper, AddNewTag, } from './tag-edit.style';
import { Button } from '../../hoc/button'
import { Gap } from '../../gap';



const TagEditor = props => {

    const { close, option, popupKey, data } = props;
    const { tagData, func } = data;
    const [inputValue, setinputValue] = useState('');

    const { saveHints } = func;

    useEffect(() => {
        if (tagData) {
            setinputValue(tagData);
        }
    }, [tagData]);


    // const _local_save = () => {

    // }

    return (
        <HintsWrapper>
            <AddNewTag>
                <span className="add_title">Edit Hint</span>
            </AddNewTag>
            <Gap h="25px" />


            <input
                type="text"
                autoFocus
                value={inputValue}
                placeholder="Enter hint Name"
                onChange={(e) => setinputValue(e.target.value)}

            />
            <Gap h="25px" />


            <div style={{
                paddingTop: '25px',
                borderTop: '1px solid #ccc',
                marginTop: 'auto',
                display: 'block',
                width: '100%'
            }}>
                <Button 
                     primary={true}
                    width='6rem' 
                    mR="16px"
                    onClick={() => {
                        saveHints(inputValue);
                        close(option, popupKey)
                    }}
                >
                    Save
                </Button>
                <Button
                    width='6rem' 
                    bgColor={'#e6e6e6'}
                    style={{
                        marginBottom: 0
                    }}
                    onClick={() => {
                        close(option, popupKey)
                    }}
                >
                    Cancel
                </Button>
            </div>
        </HintsWrapper>
    );
};

export default TagEditor

