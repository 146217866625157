import React, { useEffect, useMemo, useState } from "react";
import { ViewMode, Gantt } from "gantt-task-react";
import { ViewSwitcher } from "./switcher";
import styled from "styled-components";
import CustomToolTip from "./toolTip";
import { get_required_attributes_for_chart_click_option, is_date, showPopup } from "../../../utils";
import enums from "../../../utils/enums";
import ChartFilterPopup from "../../chart-filter-popup";
// import CustomToolTip from '../tooltip/tooltip.custom';



const Line = styled.div`
    position: absolute;
    left: 0px;
    top: 59px;
    background-color: red;
    height: 1px;
    width: 100%;
`;

const GanttWrapper = styled.div`
  width: ${({ width }) => width + "px"};
  border: 1px solid #e2e1e1;
  display: inline-block;
  padding-top: 5px;
  /* padding: 8px; */
  font-family: 'Lato', sans-serif !important;
  ._3eULf {
    /* border: 1px solid #edecec; */
    padding: 4px;
  }
  /* remove horizontal grid color block line */
  ._2dZTy {
    display: none;
  }

  ._3rUKi {
    display: ${({ hide_y_axis }) => (hide_y_axis ? "none" : "block")};
  }
  ._RuwuK {
    display: ${({ hide_x_axis }) => (hide_x_axis ? "none" : "block")};
  }
  ._3rUKi{
    stroke: #fff;
  }
  .gridBody{
    display: none;
  }

  ${({ hide_label }) =>
        hide_label &&
        `
      ._CZjuD >svg {
        display: none;
        height: 0px;
  }
  `}
`;

const GantChartSimple = (props) => {

    const {

        heightOfChart,
        widthOfChart,
        hide_y_axis = false,
        hide_x_axis = false,
        hide_label = false,
        colorForYData,
        od_report_id,
        od_report,
        xDataKeys,
        data,
        yDataKeys

    } = props;



    const [view, setView] = useState(ViewMode.Month);
    const [tasks, setTasks] = useState([]);
    const [isChecked, setIsChecked] = useState(false);

    const [clientX, setClientX] = useState(0);
    const [clientY, setClientY] = useState(0);


    function extractKeys(xDataKeys) {
        let startDateKey, endDateKey, taskNameKey, category, planStartDateKey, planEndDateKey;

        if (xDataKeys.length >= 6) {
            planStartDateKey = xDataKeys[5];
            planEndDateKey = xDataKeys[4];
            startDateKey = xDataKeys[3];
            endDateKey = xDataKeys[2];
            taskNameKey = xDataKeys[1];
            category = xDataKeys[0];

        }
        else if (xDataKeys.length >= 5) {
            planStartDateKey = xDataKeys[4];
            planEndDateKey = xDataKeys[3];
            startDateKey = xDataKeys[2];
            endDateKey = xDataKeys[1];
            taskNameKey = xDataKeys[0];
        }
        else if (xDataKeys.length >= 4) {
            startDateKey = xDataKeys[3];
            endDateKey = xDataKeys[2];
            taskNameKey = xDataKeys[1];
            category = xDataKeys[0];
        } else {
            startDateKey = xDataKeys[2];
            endDateKey = xDataKeys[1];
            taskNameKey = xDataKeys[0];
        }

        return { startDateKey, endDateKey, taskNameKey, category, planStartDateKey, planEndDateKey };
    }



    const extractKeys__ = extractKeys(xDataKeys)


    const startDateKey = extractKeys__?.startDateKey
    const endDateKey = extractKeys__?.endDateKey
    const taskNameKey = extractKeys__?.taskNameKey
    const category = extractKeys__?.category;

    const planStartDateKey = extractKeys__?.planStartDateKey;
    const planEndDateKey = extractKeys__?.planEndDateKey

    const isPlanExist = (planEndDateKey || planStartDateKey);




    useEffect(() => {

        const handleMouseMove = (event) => {
            setClientX(event.clientX);
            setClientY(event.clientY);
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);


    useEffect(() => {

        const clone_data = data ? JSON.parse(JSON.stringify(data)) : [];

        if (clone_data.length > 0) {

            const makeData = [];

            clone_data?.map((i, j) => {

                if (is_date(i?.[startDateKey]) && is_date(i?.[endDateKey])) {

                    const a = new Date(i?.[startDateKey]);
                    const b = new Date(i?.[endDateKey]);
                    const actual = {
                        id: j + "random",
                        start: new Date(a.getFullYear(), a.getMonth(), a.getDate()),
                        end: new Date(b.getFullYear(), b.getMonth(), b.getDate()),
                        name: i?.[taskNameKey] + "- Actual",
                        type: "task",
                        key: "actual",
                        category: i?.[category],
                        styles: {
                            backgroundColor: '#89d8b0',
                            backgroundSelectedColor: "#89d8b0"
                        }
                    }

                    yDataKeys?.length > 0 && yDataKeys?.map((y) => {
                        actual[y] = i?.[y];
                    })
                    xDataKeys?.length > 0 && xDataKeys?.map((x) => {
                        actual[x] = i?.[x];
                    })


                    makeData.push(actual)
                }

                if (isPlanExist && is_date(i?.[planStartDateKey]) && is_date(i?.[planEndDateKey])) {

                    const c = new Date(i?.[planStartDateKey]);
                    const d = new Date(i?.[planEndDateKey]);

                    const plan = {
                        id: j + "random_plan",
                        start: new Date(c.getFullYear(), c.getMonth(), c.getDate()),
                        end: new Date(d.getFullYear(), d.getMonth(), d.getDate()),
                        name: i?.[taskNameKey] + " - Plan ",
                        type: "task",
                        key: "plan",
                        category: i?.[category],
                        styles: {
                            backgroundColor: '#0202aa',
                            backgroundSelectedColor: "#0202aa"

                        }
                    }

                    yDataKeys?.length > 0 && yDataKeys?.map((y) => {
                        plan[y] = i?.[y];
                    })
                    xDataKeys?.length > 0 && xDataKeys?.map((x) => {
                        plan[x] = i?.[x];
                    })

                    makeData.push(plan)
                }

            });
            setTasks([...makeData]);
        } else {
            setTasks([]);
        }
    }, [xDataKeys]);


    // specify coumn width on changing viewmode (day/month/yr basis)
    const columnWidthFn = useMemo(() => {
        let columnWidth = 30;
        if (view === ViewMode.Year) {
            columnWidth = 200;
        } else if (view === ViewMode.Month) {
            columnWidth = 100;
        } else if (view === ViewMode.Week) {
            columnWidth = 60;
        } else {
            columnWidth = 40;
        }
        return columnWidth;
    }, [view]);



    const customTaskList = (data) => {

        const task = data?.tasks;
        const main_style = {
            display: category ? 'flex' : 'block',
            flexDirection: 'row',
            width: category ? '300px' : '150px',
            borderRight: '1px solid  #e0e0e0',
            position: 'relative'
        };

        const cleaned_task_list = task?.filter((t) => t?.key !== "plan");

        return (
            (cleaned_task_list || []).map((d, j) => {

                const cell_style = {
                    padding: '0',
                    borderRight: category ? '1px solid #ccc' : '0px',
                    height: isPlanExist ? '59px' : '30px',
                    width: category ? '49%' : '100%',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    borderBottom: '1px solid #e0e0e0',
                    lineHeight: isPlanExist ? '59px' : '30px',
                    fontSize: '.7rem',
                    fontWeight: '500',
                    color: '#333',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    boxSizing: 'border-box',
                    padding: category ? '0px 8px' : '0px'
                }

                var value = d.category;

                return (
                    <div style={main_style}>
                        {/* <Line style={{ top: (60 * j + "px") }} /> */}
                        {category && <div title={d?.category} style={cell_style}>{value}</div>}
                        <div title={d?.name} style={cell_style}>{d.name?.split("-")?.[0]}</div>
                    </div>
                )
            })
        )
    };



    const customTaskHeader = () => {

        const main_style = {
            backgroundColor: '#f3f3f3',
            display: category ? 'flex' : 'block',
            flexDirection: 'row',
            width: category ? '300px' : '150px'
        };

        const cell_style = {
            padding: '0',
            borderRight: category ? '1px solid #ccc' : '0px',
            width: category ? '49%' : '100%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            borderBottom: '1px solid #e0e0e0',
            fontSize: '.7rem',
            fontWeight: '600',
            color: '#333',
            height: '49px',
            lineHeight: '49px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            padding: category ? '0px 8px' : '0px'

        }


        return (
            <div style={main_style}>
                {category && <div style={cell_style}>{category}</div>}
                <div style={cell_style}>{taskNameKey}</div>
            </div>
        )

    };


    const open_chart_options = (event, data_key, payload, pivot_data_columns) => {

        if (props.is_require_cache) {
            return;
        }
        const options_required_data = get_required_attributes_for_chart_click_option({ clientY: clientY, clientX: clientX }, data_key, payload, xDataKeys, props.columnMeta, undefined, undefined, undefined, pivot_data_columns)

        const { left, top, width, popup_bg } = options_required_data;

        showPopup(undefined, undefined, enums.popupType.element, ChartFilterPopup, {
            ...options_required_data,
            question: props.question,
            drill_down_values: props.drill_down_values,
            column_meta_details: props.columnMeta,
            b_history: props.history,
            _id: props.id,
            insight_id: props.insight_id,
            insights: props.insights,
            containerWidth: width,
            od_report_id: od_report_id,
            od_report: od_report,
            reporting_db_info_id: props.reporting_db_info_id,
            hide_drill_down: !props.is_drill_down_applicable
        },
            top + "px",
            left + "px",
            popup_bg
        )

    }


    return (
        <GanttWrapper
            hide_y_axis={hide_y_axis}
            hide_x_axis={hide_x_axis}
            hide_label={hide_label}
            width={widthOfChart}
        >
            <ViewSwitcher
                onViewModeChange={(viewMode) => setView(viewMode)}
                onViewListChange={setIsChecked}
                isChecked={isChecked}
                view={view}
            />

            {tasks?.length > 0 && startDateKey && endDateKey && taskNameKey && (
                <Gantt
                    listCellWidth={category ? 300 : 150}
                    tasks={tasks}
                    TaskListTable={customTaskList}
                    TaskListHeader={customTaskHeader}
                    viewMode={view}
                    columnWidth={columnWidthFn}
                    onClick={open_chart_options}
                    barCornerRadius={5}
                    barFill={80}
                    rowHeight={30}
                    fontSize={12}
                    ganttHeight={heightOfChart - 150}
                    showLine={true}
                    TooltipContent={({ task }) => <CustomToolTip
                        yDataKeys={yDataKeys}
                        clientX={clientX}
                        clientY={clientY}
                        task={task}
                        xDataKeys={xDataKeys}
                        planStartDateKey={planStartDateKey}
                        planEndDateKey={planEndDateKey}
                        startDateKey={startDateKey}
                        endDateKey={endDateKey}
                    />}
                />
            )}
        </GanttWrapper>
    );
};



export default GantChartSimple;