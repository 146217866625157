import React from 'react';

const Save = (props) => {

    return (
        <svg width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 125 125"
            space="preserve" >
            <g><path d="M5273.1,2400.1v-2c0-2.8-5-4-9.7-4s-9.7,1.3-9.7,4v2c0,1.8,0.7,3.6,2,4.9l5,4.9c0.3,0.3,0.4,0.6,0.4,1v6.4     c0,0.4,0.2,0.7,0.6,0.8l2.9,0.9c0.5,0.1,1-0.2,1-0.8v-7.2c0-0.4,0.2-0.7,0.4-1l5.1-5C5272.4,2403.7,5273.1,2401.9,5273.1,2400.1z      M5263.4,2400c-4.8,0-7.4-1.3-7.5-1.8v0c0.1-0.5,2.7-1.8,7.5-1.8c4.8,0,7.3,1.3,7.5,1.8C5270.7,2398.7,5268.2,2400,5263.4,2400z" /><path d="M5268.4,2410.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H5268.4z" /><path d="M5272.7,2413.7h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2414.1,5273.3,2413.7,5272.7,2413.7z" /><path d="M5272.7,2417h-4.3c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h4.3c0.6,0,1-0.4,1-1C5273.7,2417.5,5273.3,2417,5272.7,2417z" /></g><g><path d="M28.7,42.9c-1.9,1.9-1.9,5.1,0,7l17.8,17.8c1,1,2.2,1.4,3.5,1.4c1.3,0,2.5-0.5,3.5-1.4l17.8-17.8c1.9-1.9,1.9-5.1,0-7     c-1.9-1.9-5.1-1.9-7,0l-9.4,9.4V12.6c0-2.7-2.2-4.9-5-4.9s-4.9,2.2-4.9,4.9v39.6l-9.4-9.4C33.8,41,30.6,41,28.7,42.9z" /><path d="M80.2,16.7h-9.9c-2.7,0-4.9,2.2-4.9,4.9s2.2,4.9,4.9,4.9h9.9c4.1,0,7.4,3.3,7.4,7.4v41c0,4.1-3.3,7.4-7.4,7.4H19.8     c-4.1,0-7.4-3.3-7.4-7.4V34c0-4.1,3.3-7.4,7.4-7.4h9.9c2.7,0,4.9-2.2,4.9-4.9s-2.2-4.9-4.9-4.9h-9.9c-9.6,0-17.3,7.8-17.3,17.3     v41c0,9.6,7.8,17.3,17.3,17.3h60.4c9.6,0,17.3-7.8,17.3-17.3V34C97.5,24.5,89.7,16.7,80.2,16.7z" /></g>        </svg>

    )

}


export default Save;