import React, { Suspense, useEffect, useState } from 'react';
import { TableWrapper } from './shared.table.styled'
import DropDownSvgDown from '../svg/dropDown';
import DropDownSvgUp from '../svg/dropDownUp';
import Checkbox from '../hoc/checkbox';
import TextBox from '../hoc/textBox'
import Table_skeleton from '../Skeleton/table-skeleton';
import TableSkeleton from '../Skeleton/table-skeleton';
import Skeleton from 'react-loading-skeleton';
import SearchBar from '../hoc/SearchBar';





const TableRow = React.lazy(() => import('./row'));


let prev_value = undefined;;

const Index = React.memo((props) => {

    const {

        editAction, deleteAction, tableData, tableHead, DateColumns, processAction, addAction, updateAction, cloneAction,
        columnsToShow, isDateColumn, maximumWidthOfColumn, selected, searchColumns, isSizeColumn, isSearchBox, borderType,borderRadius, sortStyle,
        columnAction, height, customAction, viewAction, actionOnHover, historyAction, extractFileName, searchBoxLabel,searchBarWidth,searchStyle,
        selectAllCheckboxAction, checkBoxAction, editedColumn, columnTextOnChange, updateRecordAction, actionsTitles, notTextWrap,
        downloadAction, removeDublicateField, approvedAction, rejectedAction, pointerRow, columnDblClickAction, editedColumns, autoSearchColumnsKeys,
        declineAction, fullTime, sortAction, sortColumns, rowClickAction, autoSearchColumns, autoSearchColumnsData, autoSearchOnChange, autoSearchColumnsValue, updateRecordActionStoredKey,
        hide_these_columns, fontSize, rowHeight, column_body_styles, customActionIconAndStyle, customHoverTitle, column_header_styles, none_even_odd_color,
        update_job_status, job_status, columnTextWithWrapper, colunnTextAlignment, isLoding, searchValueonSearch, noOfRows
    } = props;



    const { is_expandable, active_expanded_value, active_expanded_value_key, Expanded_row_data_component } = props;

    const __table__head__filtered = (tableHead && tableHead.length > 0 && hide_these_columns) ? tableHead.filter(header => hide_these_columns.indexOf(header.toLowerCase()) === -1) : tableHead

    const [localTableData, setLocalTableData] = useState(undefined);
    const [activeRow, setActiveRow] = useState(undefined);
    const [sortOrder, setSortOrder] = useState(undefined);
    const [searchValue, setSearchValue] = useState(undefined);
    // const [isTableLoding, setIsTableLoding] = useState(true)

    useEffect(() => {
        setLocalTableData(tableData)
        if (searchValue && searchValue.length > 0) {
            filterFileOnSearch(searchValue)
        } else setLocalTableData(tableData)
    }, [tableData, searchValue])

    // useEffect(() => {
    //     if(localTableData?.length > -1){
    //         setIsTableLoding(false)
    //     }

    // },[localTableData])



    const sortFunction = (info, _order) => {

        let key = info && info.dataKey ? info.dataKey : undefined;
        let data_type = info && info.type ? info.type : undefined;

        let order = _order ? _order : (sortOrder && sortOrder[key]) ? ((sortOrder[key] === 'az') ? 'za' : 'az') : 'az';


        const sorted_data = localTableData && localTableData.length > 0 && localTableData.sort((left, right) => {
            if (data_type === 'string') {
                if (order === 'az') {
                    let leftValue = left[key] ? left[key].trim().toLowerCase() : '';
                    let rightValue = right[key] ? right[key].trim().toLowerCase() : '';
                    return leftValue.localeCompare(rightValue);
                }
                if (order === 'za') {
                    let leftValue = left[key] ? left[key].trim().toLowerCase() : '';
                    let rightValue = right[key] ? right[key].trim().toLowerCase() : '';
                    return rightValue.localeCompare(leftValue);
                }
            }

            if (data_type === 'number') {
                if (order === 'az') {
                    let leftValue = left[key] ? left[key].trim().toLowerCase() : '';
                    let rightValue = right[key] ? right[key].trim().toLowerCase() : '';
                    return leftValue - rightValue;
                }
                if (order === 'za') {
                    let leftValue = left[key] ? left[key].trim().toLowerCase() : '';
                    let rightValue = right[key] ? right[key].trim().toLowerCase() : '';
                    return rightValue - leftValue;
                }
            }



            if (data_type === 'date') {
                if (order === 'az') {
                    let leftValue = new Date(left[key] ? left[key] : null);
                    let rightValue = new Date(right[key] ? right[key] : null)
                    return leftValue - rightValue
                }

                if (order === 'za') {
                    let leftValue = new Date(left[key] ? left[key] : null);
                    let rightValue = new Date(right[key] ? right[key] : null)
                    return rightValue - leftValue
                }
            }
        })

        setLocalTableData(sorted_data)
        setSortOrder({ [key]: order })
    }




    const filterFileOnSearch = (value) => {
        let search_value = value && value.toLowerCase();
        let clone_table_data = JSON.parse(JSON.stringify(tableData));
        let after_filterd = clone_table_data.filter((file) => {
            if (value === '') return true;
            else if (file[searchColumns[0]].toLowerCase().indexOf(search_value) > -1) return true
            else return false;
        });
        setLocalTableData(after_filterd)
    }

    return (

        <React.Fragment>
            {localTableData && !isLoding ? (
                <TableWrapper rowHeight={rowHeight} fontSize={fontSize} height={height} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setActiveRow(undefined)
                }}>
                    {isSearchBox && (
                        <div style={{ margin: '1rem 0px 0.4rem 0', width: searchBarWidth || '30%',  }}>
                            {/* <TextBox
                                label={searchBoxLabel ? searchBoxLabel : 'Search'}
                                type="text"
                                value={searchValue}
                                placeholder={searchBoxLabel ? searchBoxLabel : 'Search'}
                                onChange={(e) => {
                                    setSearchValue(e.target.value)
                                    filterFileOnSearch(e.target.value)
                                }}
                            /> */}

                            <SearchBar
                                value={searchValue}
                                type="text"
                                padding={'6px'}
                                marLeft={'0px'}
                                placeHolder={searchBoxLabel ? searchBoxLabel : 'Search'}
                                onChange={(e) => {
                                    setSearchValue(e.target.value)
                                    filterFileOnSearch(e.target.value)
                                }}
                                borderType={borderType ? 'border-radius' : 'border-bottom'}
                                borderRadius={borderRadius}
                                style={searchStyle}
                            />
                    </div>
                    )}

                    <table className="table">
                        <thead className="thead">

                            <tr>
                                {selectAllCheckboxAction && (
                                    <th>
                                        <Checkbox onClick={() => selectAllCheckboxAction()} checked={(selected && selected.length === localTableData.length) ? true : false} />
                                    </th>
                                )}


                                {tableHead && tableHead.length > 0 && tableHead.map((key, i) => {



                                    let sort_column_info = (sortColumns && Object.keys(sortColumns).indexOf(key) > -1) ? sortColumns[key] : undefined;
                                    let color_up_icon = (sortOrder && sort_column_info && sortOrder[sort_column_info['dataKey']] === 'za') ? "#222" : '#ffffff';
                                    let color_up_down = (sortOrder && sort_column_info && sortOrder[sort_column_info['dataKey']] === 'az') ? "#222" : '#ffffff';

                                    const column_width_style = props?.columns_width?.[key] || {};
                                    return (
                                        <th style={{
                                            ...(column_header_styles || {}),
                                            ...column_width_style,
                                            width: maximumWidthOfColumn ? maximumWidthOfColumn[key] : undefined,
                                            maxWidth: maximumWidthOfColumn ? maximumWidthOfColumn[key] : undefined,
                                            // width: '5rem',
                                            textAlign: (key === 'Action' || key === 'Actions') || key?.toLowerCase()?.includes('number') ? "center" :  undefined,
                                            textTransform: 'uppercase', whiteSpace: 'nowrap',

                                        }} key={"th" + i}>

                                            <div className="thead_column" style={(sortColumns && Object.keys(sortColumns).indexOf(key) > -1) ? { display: 'flex', justifyContent: 'space-between' } : {}} >
                                                <div> 
                                                {/* {i === 0 && ( <span>&nbsp;</span> )} */}
                                                { key}</div>
                                                {(key !== 'Action' && key !== 'Actions') && (sortColumns && Object.keys(sortColumns).indexOf(key) > -1) && (
                                                    <div className="dropdown_icon"
                                                        style={{...sortStyle|| {} }}
                                                        onClick={() => sortFunction(sort_column_info)}
                                                    >
                                                        <DropDownSvgUp size={".5rem"} height=".5rem" color={color_up_icon} />
                                                        <DropDownSvgDown size={".5rem"} height=".5rem" color={color_up_down} />
                                                    </div>
                                                )}
                                            </div>

                                        </th>
                                    )
                                })}

                                {(actionOnHover && (editAction || deleteAction || viewAction || approvedAction || rejectedAction || downloadAction || declineAction || historyAction || addAction || processAction || updateAction || cloneAction)) && (
                                    <th></th>
                                )}
                            </tr>
                        </thead>




                        <tbody>
                            {localTableData && localTableData.length > 0 && localTableData.map((item, index) => {

                                let spical_value_to_show = '';

                                if (removeDublicateField) {
                                    if (prev_value && prev_value === item.file_name) {
                                        spical_value_to_show = '';
                                        prev_value = item.file_name;
                                    }
                                    else {
                                        spical_value_to_show = item.file_name;
                                        prev_value = item.file_name

                                    }
                                }
                                return (
                                    <Suspense key={"table_row" + index} fallback={<tr><td><Skeleton /></td></tr>}>
                                        <TableRow
                                            fontSize={fontSize}
                                            index={index}
                                            rowIndex={index}
                                            key={"table_row" + index + item?.id}
                                            item={item}
                                            viewErrorAction={props.viewErrorAction}
                                            customActionIconAndStyle={customActionIconAndStyle}
                                            customHoverTitle={customHoverTitle}
                                            deleteAction={deleteAction}
                                            editAction={editAction}
                                            cloneAction={cloneAction}
                                            viewAction={viewAction}
                                            customAction={customAction}
                                            columnsToShow={columnsToShow}
                                            isDateColumn={isDateColumn}
                                            maximumWidthOfColumn={maximumWidthOfColumn}
                                            columnAction={columnAction}
                                            downloadAction={downloadAction}
                                            spical_value_to_show={spical_value_to_show}
                                            removeDublicateField={removeDublicateField}
                                            approvedAction={approvedAction}
                                            rejectedAction={rejectedAction}
                                            declineAction={declineAction}
                                            fullTime={fullTime}
                                            actionOnHover={actionOnHover}
                                            checkBoxAction={checkBoxAction}
                                            rowClickAction={rowClickAction}
                                            selected={selected}
                                            pointerRow={pointerRow}
                                            columnDblClickAction={columnDblClickAction}
                                            editedColumn={editedColumn}
                                            columnTextOnChange={columnTextOnChange}
                                            editedColumns={editedColumns}
                                            updateRecordAction={updateRecordAction}
                                            DateColumns={DateColumns}
                                            historyAction={historyAction}
                                            processAction={processAction}
                                            addAction={addAction}
                                            updateAction={updateAction}
                                            autoSearchColumns={autoSearchColumns}
                                            autoSearchColumnsData={autoSearchColumnsData}
                                            autoSearchOnChange={autoSearchOnChange}
                                            autoSearchColumnsValue={autoSearchColumnsValue}
                                            autoSearchColumnsKeys={autoSearchColumnsKeys}
                                            isSizeColumn={isSizeColumn}
                                            extractFileName={extractFileName}
                                            activeRow={activeRow}
                                            setActiveRow={setActiveRow}
                                            actionsTitles={actionsTitles}
                                            updateRecordActionStoredKey={updateRecordActionStoredKey}
                                            notTextWrap={notTextWrap}
                                            date_columns={props.date_columns}
                                            column_body_styles={column_body_styles}
                                            none_even_odd_color={none_even_odd_color}
                                            is_expandable={is_expandable}
                                            active_expanded_value_key={active_expanded_value_key}
                                            active_expanded_value={active_expanded_value}
                                            Expanded_row_data_component={Expanded_row_data_component}
                                            update_job_status={update_job_status}
                                            job_status={job_status}
                                            columnTextWithWrapper={columnTextWithWrapper}
                                            colunnTextAlignment={colunnTextAlignment}
                                        />
                                    </Suspense>
                                )
                            })
                            }
                            {/* {Expanded_row_data_component} */}
                        </tbody>
                    </table>
                    {(!localTableData || localTableData.length === 0) && !isLoding ? <p style={{ textAlign: 'center', marginTop: '5rem' }}>{searchValue|| searchValueonSearch ? 'No Results Found' : 'No Record Found'}</p> : null}
                    {/* {!localTableData && <p style={{ textAlign: 'center', marginTop: '5rem' }}>Loading...</p>} */}
                </TableWrapper>
            ) 
            : 
            (<TableSkeleton noOfRows={noOfRows}/>)}
        </React.Fragment>
    )
})

export default Index;
