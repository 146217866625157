import { combineReducers } from "redux";
import commonReducer from './common';
import errorReducer from './error';
import popupReducer from './popup';
import loaderReducer from './loader';
import windowReducer from './window'
import reportReducer from './report';
import accountReducer from './account';
import kpiReducer from './kpi';
import multipleReportReducer from './multiple.reports';
import menuGroupReducer from './menu.group';
import filterReducer from './filter';
import dashboardReducer from './dashboard';
import righterReducer from './righter';
import lefterReducer from './lefter';
import configReducer from './config';
import hintsReducer from './hints';
import entityReducer from './entity';
import insightReducer from './insight';
import layoutReducer from './layout';
import chartHelperReducer from './chart.helper';
import reportHelperReducer from './report.helper';
import tableReducer from './tables';
import overlayReducer from './overlay';
import helperReducer from './helper';
import clientInfoReducer from './client.info';
import relationshipDetail from './relationship.detail';
import rankingReducer from './ranking';
import discoveryReducer from './discovery';
import predictiveAnalyticsReducer from './predictive_analytics';
import fileUploaderReducer from './file.uploader.js';
import favouriteReducer from './favourite'
import OutlierReducer from './new-outlier'
import NewTableReducer from './new-table'
import BreadCrumbReducer from './bread-crumb'
import NfGrammarReducer from './nf_grammar'
import menuConfigRighterReducer from './menu.config.righter'
import InterpretationReducer from './interpretation'
import mapReducer from './map'
import heatmapConfigrationReducer from './heatmap.configration'
import connectionReducer from './connection'
import securityReducer from './security';
import sampleDemoReducer from './sample.demo';
import masterReducer from './master.data';
import demoRequestReducer from './demo-request';
import tileReducer from './tiles';
import EtlReducer from './etl';
import FormulasReducer from './formulas';
import successReducer from './success';
import multiDisplayReducer from './multi.display';
import ImportErrorReducer from './import.error';
import CampaignReducer from './campaign';
import v2_reportReducer from './v2/report';
import v2_filterReducer from './v2/filter';
import markupReducer from './markup';
import domainConfigReducer from './domain_config';
import permissionReducer from './permissions';
import sqoopConfigration from './sqoop_configration';
import columnInfoReducer from './nf_column_info_ad_tech';
import tblErrorReducer from './tbl_errors';
import streamingReducer from './streaming';
import dataSyncUpReducer from './data-sync-up'
import groupAccessReducer from './group_access';
import data_reducer from './v3/data.reducer';
import dataHierarchy from './data-hierarchy';
import tableFormatReducer from './table_format';
import reportBuilder from './report_builder';
import rulesAlert from './rules.alert';
import dataWrangler from "./data_wrangler";
import fileUploadConfig from './file_upload_config.js';
import dbFormula from './db.formula';
import actualPlan from './actual_plan';
import DirectoryUpload from './directory_upload';
import voiceHelperReducer from './voice.helper.js';
import sqlHistoryReducer from './sqlHistory.js';
import InterpretReducer from './interpretCommand.js';
import polusChatReducer from './polus_chat.js';
import gaugeReducer from './gaugeSegmentRange.js'

export default combineReducers({
    errorReducer,
    successReducer,
    popupReducer,
    loaderReducer,
    windowReducer,
    reportReducer,
    accountReducer,
    kpiReducer,
    multipleReportReducer,
    menuGroupReducer,
    filterReducer,
    commonReducer,
    dashboardReducer,
    righterReducer,
    lefterReducer,
    configReducer,
    hintsReducer,
    entityReducer,
    insightReducer,
    layoutReducer,
    chartHelperReducer,
    reportHelperReducer,
    tableReducer,
    overlayReducer,
    helperReducer,
    clientInfoReducer,
    relationshipDetail,
    rankingReducer,
    predictiveAnalyticsReducer,
    discoveryReducer,
    fileUploaderReducer,
    favouriteReducer,
    OutlierReducer,
    NewTableReducer,
    BreadCrumbReducer,
    NfGrammarReducer,
    menuConfigRighterReducer,
    InterpretationReducer,
    mapReducer,
    heatmapConfigrationReducer,
    connectionReducer,
    securityReducer,
    sampleDemoReducer,
    masterReducer,
    demoRequestReducer,
    tileReducer,
    EtlReducer,
    FormulasReducer,
    multiDisplayReducer,
    ImportErrorReducer,
    CampaignReducer,
    markupReducer,
    v2_reportReducer,
    v2_filterReducer,
    domainConfigReducer,
    permissionReducer,
    sqoopConfigration,
    columnInfoReducer,
    tblErrorReducer,
    streamingReducer,
    dataSyncUpReducer,
    groupAccessReducer,
    data_reducer,
    dataHierarchy,
    tableFormatReducer,
    reportBuilder,
    rulesAlert,
    dataWrangler,
    fileUploadConfig,
    dbFormula,
    actualPlan,
    DirectoryUpload,
    voiceHelperReducer,
    sqlHistoryReducer,
    InterpretReducer,
    polusChatReducer,
    gaugeReducer,
});