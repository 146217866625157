import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import RippleEffect from '../hoc/ripple-effect';
import CloseSvg from '../svg/close';
import TextBox from '../hoc/textBox';
import { Button } from '../hoc/button';
import Delete from '../svg/delete'

const MainWrapper = styled.div`
    width: 35rem;
    height: 30rem;
    border-radius: 3px;
    border: 4px solid #ccc;
    padding: 1rem;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 50rem;
    overflow-y: auto;

    .close{
        position: absolute;
        right: .5rem;
        top: .5rem;
    }

    h1{
        text-align: center;
        margin: 0px;
        font-size: 1.4rem;
        font-weight: 600;
        color: rgb(55, 74, 102);
        margin-bottom: 1rem;
    }

    p{
        text-align: center;
    }

    table{
        width: 100%;
        td{
            padding: 10px 0px;
        }
    }
`;

const ParameterizedPopup = (props) => {

    const { close, popupKey, option, data } = props;
    const { parameters_fields, set_parameterized_fields } = data;

    const [localFieldData, setLocalFieldData] = useState(undefined)

    useEffect(() => {
        const temp_p_fields = parameters_fields ? [...parameters_fields] : [];
        if (temp_p_fields.length == 0 || temp_p_fields[temp_p_fields.length - 1] !== '' || temp_p_fields[temp_p_fields.length - 1] === null) {
            temp_p_fields.push('')
        }
        setLocalFieldData(temp_p_fields)
    }, [parameters_fields])


    const on_change = (value, index) => {
        const clone_field_data = localFieldData ? [...localFieldData] : [];
        clone_field_data[index] = value;
        if (clone_field_data.length == 0 || clone_field_data[clone_field_data.length - 1] !== '' || clone_field_data[clone_field_data.length - 1] === null) {
            clone_field_data.push('')
        }
        setLocalFieldData(clone_field_data)
    }

    const _save = () => {
        const clone_field_data = localFieldData ? [...localFieldData] : [];
        const final_data_to_save = clone_field_data && clone_field_data.length > 0 && clone_field_data.filter((f) => {
            if (f && f.length > 1 && typeof f !== null) {
                return true
            } else return false
        }) || [];
        set_parameterized_fields(final_data_to_save)
        close(option, popupKey)
    }

    const delete_field = (index) => {
        const clone_field_data = localFieldData ? [...localFieldData] : [];
        clone_field_data.splice(index, 1)
        setLocalFieldData(clone_field_data)
    }
    


    return (
        <MainWrapper>
            <h1>Add Parameterizede Fields</h1>
            <div>
                <table>
                    {localFieldData && localFieldData.length > 0 && localFieldData.map((f, index) => {
                        const is_last_element = index === (localFieldData.length - 1);
                        return (
                            <tr>
                                <td>
                                    
                                    <TextBox
                                        label={"Type Your Field Name"}
                                        value={f ? f : ''}
                                        onChange={(e) => {
                                            on_change(e.target.value, index)
                                        }}
                                    />
                                </td>
                                <td style={{textAlign: 'center'}}>
                                    {!is_last_element && (
                                        <RippleEffect
                                            // width="25"
                                            icon={<Delete size=".8rem" height=".8rem" color="#222" />}
                                            title={"delete"}
                                            Click={
                                                (e) => {

                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    delete_field(index);
                                                }
                                            }
                                        />
                                    )}
                                </td>
                            </tr>
                        )
                    })}
                </table>
            </div>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: 'auto',
                marginLeft: 'auto'

            }}>
                <Button
                    primary={true}
                    onClick={() => {
                        // _save()
                        _save()
                    }}
                    mR="1rem"
                >
                    Save
                </Button>
                <Button
                    onClick={() => {
                        close(option, popupKey)
                    }}
                    bgColor={'#e6e6e6'}
                    mR="1rem"
                >
                    Cancel
                </Button>
            </div>

        </MainWrapper>
    )
}

export default ParameterizedPopup;
