import React, { useState, useEffect } from 'react'
import { MainWrapper } from './module.access.styled';
import { PageInnerWrapper } from '../hoc/styled.hoc';
import { getParamByName } from '../../utils';
import { get_group_access_for_menu, save_menu_group_access, getUserList, clearGroupAccess } from '../../actions/access_group';
import { connect } from 'react-redux';
import TextBox from '../hoc/textBox';
import Checkbox from '../hoc/checkbox';
import { Button } from '../hoc/button';
import { withRouter } from 'react-router-dom';




const ModuleAddEdit = (props) => {

    const { menu_access_data, get_group_access_for_menu, save_menu_group_access, history } = props;
    const { location } = history;
    const { search } = location;

    const [localData, setLocalData] = useState(undefined)
    const [groupId, setGroupId] = useState(undefined)


    // list 
    const [locaModules, setLocalModules] = useState(undefined)
    const [localUsers, setLocalUser] = useState(undefined)


    // checked  
    const [checkedUsers, setCheckedUsers] = useState(undefined)
    const [checkedModules, setCheckedModules] = useState(undefined)



    useEffect(() => {
        let _page_info = {
            title: groupId ? "Edit Group" : "Add Group",
            isBack: true,
        }
        props.dispatchHeaderInformation(_page_info)
    }, [groupId])


    useEffect(() => {
        let _group_id = getParamByName(search, 'id')
        get_group_access_for_menu(_group_id)
        setGroupId(_group_id)

    }, [search])



    const click_on_checkbox_module = (id, value) => {
        let clone_checked_module = checkedModules ? [...checkedModules] : [];

        if (clone_checked_module.indexOf(id) > -1) {
            let _index = clone_checked_module.indexOf(id)
            clone_checked_module.splice(_index, 1)
        } else {
            clone_checked_module.push(id)
        }
        setCheckedModules(clone_checked_module)
    }

    const click_on_checkbox_user = (id, value) => {
        let clone_checked_users = checkedUsers ? [...checkedUsers] : [];

        if (clone_checked_users.indexOf(id) > -1) {
            let _index = clone_checked_users.indexOf(id)
            clone_checked_users.splice(_index, 1)
        } else {
            clone_checked_users.push(id)
        }
        setCheckedUsers(clone_checked_users)
    }


    const __save__ = () => {

        // saving data 
        const _data = {
            group_access: checkedModules,
            user_role_mapping: checkedUsers,
            access_group: localData
        }


        save_menu_group_access(_data)
        setTimeout(() => {
            props.history.push('/module_access')
        }, 100)
    }


    const onChange = (key, value) => {
        let tempLocalData = localData ? Object.assign({}, localData) : {};
        tempLocalData[key] = value;
        setLocalData(tempLocalData);
    }


    useEffect(() => {
        if (menu_access_data && menu_access_data.nf_tbl_menu) {
            setLocalModules(menu_access_data.nf_tbl_menu);
        }

        if (menu_access_data && menu_access_data.users) {
            setLocalUser(menu_access_data.users)
        }

        if (menu_access_data && menu_access_data.access_group) {
            if (menu_access_data.access_group.group_access_for_menu && menu_access_data.access_group.group_access_for_menu.length > 0) {
                const ids = [];
                menu_access_data.access_group.group_access_for_menu.forEach((g) => ids.push(g.menu_id));
                setCheckedModules(ids)
            }
            if (menu_access_data.access_group.user_roles && menu_access_data.access_group.user_roles.length > 0) {
                const ids = [];
                menu_access_data.access_group.user_roles.forEach((g) => ids.push(g.user_id));
                setCheckedUsers(ids)
            }
            let temp_acc_grp = menu_access_data.access_group;
            delete temp_acc_grp['user_roles']
            delete temp_acc_grp['groups']
            setLocalData(temp_acc_grp)
        } else {
            setLocalData(undefined)
        }


    }, [menu_access_data])



    const click_on_select_all = (key) => {

        if (key === 'group_access') {
            if (checkedModules && checkedModules.length === locaModules.length) {
                setCheckedModules([])
            }
            else {
                let clone_checked_module = [];
                locaModules && locaModules.length > 0 && locaModules.forEach((m) => {
                    clone_checked_module.push(m.id)
                })
                setCheckedModules(clone_checked_module)
            }
        }

        if (key === 'users') {
            if (checkedUsers && checkedUsers.length === localUsers.length) {
                setCheckedUsers([])
            }
            else {
                let clone_checked_user = [];
                localUsers && localUsers.length > 0 && localUsers.forEach((m) => {
                    clone_checked_user.push(m.id)
                })
                setCheckedUsers(clone_checked_user)
            }
        }
    }

    return (
        <MainWrapper>
            <PageInnerWrapper padding={'10px'} style={{ marginTop: '1rem' }}>
                <div className="row" style={{ marginBottom: '2rem' }}>
                    <div className="column_48">
                        <TextBox
                            label="Group Name"
                            value={(localData && localData['name']) ? localData['name'] : ''}
                            onChange={(e) => {
                                onChange('name', e.target.value);
                            }}

                        />
                    </div>

                    <div className="column_48">
                        <TextBox
                            label="Group Description"
                            value={(localData && localData['description']) ? localData['description'] : ''}
                            onChange={(e) => {
                                onChange('description', e.target.value);
                            }}
                        />
                    </div>
                </div>

                <h3 className="title_" style={{ marginTop: '0rem' }}>Menu Mapping</h3>
                <div className="table_wrapper">
                    <table>
                        <thead>
                            <tr>
                                <td>Menu Name</td>
                                <td style={{ width: '100px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
                                        Access
                                        <Checkbox
                                            width='20px'
                                            checked={(checkedModules && checkedModules.length) === (locaModules && locaModules.length)}
                                            onClick={() => {
                                                click_on_select_all('group_access')
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </thead>

                        <tbody>
                            {locaModules && locaModules.length > 0 && locaModules.map((_row, index) => {
                                let is_checked = (checkedModules && checkedModules.indexOf(_row.id) > -1);
                                return (
                                    <tr key={index}>
                                        <td>{_row.id ? _row.id : _row.menu_id}</td>
                                        <td>

                                            <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', alignItems: 'center' }}>
                                                <Checkbox
                                                    checked={is_checked}
                                                    onClick={() => {
                                                        click_on_checkbox_module(_row.id)
                                                    }}

                                                />
                                            </div>

                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

                <h3 className="title_">Users Mapping</h3>
                <div className="table_wrapper" >
                    <table>
                        <thead>
                            <tr>
                                <td>User Id</td>
                                <td>User Name</td>
                                <td style={{ width: '100px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
                                        Access
                                        <Checkbox
                                            width='20px'
                                            checked={(localUsers && localUsers.length) === (checkedUsers && checkedUsers.length)}
                                            onClick={() => {
                                                click_on_select_all('users')
                                            }}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </thead>

                        <tbody>
                            {localUsers && localUsers.length > 0 && localUsers.map((_row, index) => {
                                let is_checked = (checkedUsers && checkedUsers.indexOf(_row.id) > -1);
                                return (
                                    <tr key={index}>
                                        <td>{_row.id}</td>
                                        <td>{_row.name}</td>
                                        <td>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', alignItems: 'center' }}>
                                                <Checkbox
                                                    checked={is_checked}
                                                    onClick={() => {
                                                        click_on_checkbox_user(_row.id)
                                                    }}

                                                />
                                            </div>

                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>


                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: 'auto', marginBottom: '1rem' }}>
                    <Button
                        primary={true}
                        mR={'2rem'}
                        onClick={() => {
                            __save__()
                        }}>
                        Save
                    </Button>
                    <Button bgColor={'#e6e6e6'} onClick={() => {
                        props.history.push("/module_access")
                    }}>
                        Cancel
                    </Button>
                </div>
            </PageInnerWrapper>
        </MainWrapper >
    )

}



const mapStateToProps = (state) => ({
    menu_access_data: state.groupAccessReducer.menu_access_data
})

export default withRouter(connect(mapStateToProps, { clearGroupAccess, get_group_access_for_menu, save_menu_group_access, getUserList })(ModuleAddEdit))