import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { constants } from '../../../utils/constants';
import { connect } from 'react-redux';
import SearchSvg from '../../svg/search';
import * as enums from '../../../utils/enums';
import { showPopup } from '../../../utils';
import DbChooser from './list.schema.popup';
import { withRouter } from 'react-router-dom';
import Tab from '../../tab';
import GDriveLink from './g.drive.link'

const Wrapper = styled.div`

     width: 100%;

    .inner_wrapper{
        max-width: 75%;
        margin: auto;
        text-align: center;
       
    }
    .search_wrapper{
        
        width: 20rem;
        margin: 1rem auto;
        position: relative;
        position: relative;

        input{
            height: 2.7rem;
            width: 100%;
            border: 1px solid #989696ed;
            border-radius: 22px;
            padding: 15px 45px;
            box-sizing: border-box;
            padding-left: 45px;
            font-size: 1rem;
            color: #222;

        &::placeholder{
            text-align: center;
            font-size: .9rem;
        }

        }
        .search_icon{
            position: absolute;
            top: 15px;
            left: 1rem;
        }
    }

`;


const Row = styled.div`

    width: 100%;;
    display: flex;
    flex-direction: row;

`;

const Card = styled.div`
    width: 7rem;
    height: 6rem;
    border-radius: 5px;
    margin-right: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;
    box-sizing: border-box;
    box-shadow: 2px 4px 5px 2px #ccc;
    cursor: pointer;
    transition: all 0.2s;
    background-color: ${props => props.active ? '#0084be' : undefined};

    &:hover{
        background-color: #66bee6;
    }

    svg{
        fill: ${props => props.active ? '#fff' : '#222'};

    }
    .__icon{
        width: 5rem;
        height: 4rem;
        margin: auto;
        text-align: center;
        
    }
    .title{
        text-transform: uppercase;
        font-size: .9rem;
        font-weight: 600;
        color: ${props => props.active ? '#fff' : undefined};

    }

    
`;



const Column = styled.div`
    width: 200px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 5px;
    border-radius: 6px;
    position: relative;
    border: 1px solid #2196f34d;
    box-shadow: ${props => props.active ? '0px 1px 18px 2px #a7a7ce87' : undefined};
    margin: 1rem;
    border-radius: 16px;
    background-color: #fff;

    .connections{
        width: 20rem;
        background: #ebebeb;
        position: absolute;
        top: 97%;
        left: 0px;
        border-radius: 6px;
        padding: 10px;
        box-sizing: border-box;

        h2{
            font-size: 1.3rem;
            font-weight: 500;
        }

        .conn_name{
            padding: 5px;
            background: #fff;
            margin: 5px;
            display: inline-block;
            border-radius: 10px;
            font-size: .9rem;
            font-weight: 500;
            padding: 7px 17px;
            border-radius: 6px;
            font-weight: 500;
            box-shadow: 2px 3px 4px #ccc;
            cursor: pointer;
            &:hover{
                /* background-color: #0084be; */
                    color: #fff;
                    opacity: .8;
            }

        }

        .active{
            background-color: #0084be;
            color: #fff;
        }
    }

    &:hover{
        box-shadow: 0px 1px 18px 2px #a7a7ce87;
    }

    .icon{
       width: 70px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content:center;
        overflow: hidden;
        background: #fff;
        padding: 7px;
        border-radius: 15px;
        box-shadow: 0px 2px 6px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        img{
            max-width: 100%;
        }
    }
    p{
        margin: 0px;
        font-size: .8rem;
        margin-top: 10px;
        text-transform: uppercase;
    }
`;



const dbs_types = [
    {
        label: 'MYSQL',
        value: 'mysql',
        icon_name: 'my_sql.png',
        tag: 'database'
    },
    {
        label: 'sqlServer',
        value: 'sqlServer',
        icon_name: 'sql_server.png',
        tag: 'database'

    },
    {
        label: 'Postgress',
        value: 'postgress',
        icon_name: 'postgres.png',
        tag: 'database'

    },


    {
        label: 'azure synapse',
        value: 'azure_synapse',
        icon_name: 'azure.png',
        tag: 'database'

    },
    {
        label: 'oracle',
        value: 'oracle',
        icon_name: 'oracle.png',
        tag: 'database'

    },
    {
        label: 'mariadb',
        value: 'mariadb',
        icon_name: 'maria.png',
        tag: 'database'

    },
    {
        label: 'sqlite',
        value: 'sqlite',
        icon_name: 'sql_lite.png',
        tag: 'database'

    },
    {
        label: 'MongoDB',
        value: 'mongo',
        icon_name: 'mongodb.png',
        tag: 'database'

    },
    {
        label: 'Redis',
        value: 'redis',
        icon_name: 'redis.png',
        tag: 'database'

    },
    {
        label: 'SalesForce',
        value: 'salesforce',
        icon_name: 'salesforce.png',
        tag: 'database'

    },
    {
        label: 'Hive',
        value: 'hive',
        icon_name: 'hive.png',
        tag: 'database'

    },
    {
        label: 'Elastic',
        value: 'elastic',
        icon_name: 'elastic.png',
        tag: 'database'

    },
    {
        label: 'Google Drive',
        value: 'google_drive_upload',
        icon_name: 'google_drive.png',
        tag: 'files'

    },
    {
        label: 'XLS',
        value: 'xls',
        icon_name: 'xls.png',
        tag: 'files'

    },

    {
        label: 'API',
        value: 'api',
        icon_name: 'api.png',
        tag: 'application'

    },
    {
        label: 'RSYNC',
        value: 'rsync',
        icon_name: 'rsync.png',
        tag: 'database'

    },
    {
        label: 'Big Query',
        value: 'big_query',
        icon_name: 'big_query.png',
        tag: 'database'
    },
    {
        label: 'EXCEL',
        value: 'excel',
        icon_name: 'excel.png',
        tag: 'files'

    },
    {
        label: 'FTP',
        value: 'ftp',
        icon_name: 'ftp.png',
        tag: 'application'

    },
    {
        label: 'SFTP',
        value: 'sftp',
        icon_name: 'sftp.png',
        tag: 'application'

    },
    {
        label: 'Restful API',
        value: 'rest_api',
        icon_name: 'restful.png',
        tag: 'application'

    },
    {
        label: 'Twitter API',
        value: 'twitter_api',
        icon_name: 'twitter.png',
        tag: 'application'

    },
    {
        label: 'Json File',
        value: 'json',
        icon_name: 'json.png',
        link: '/question_search?type=importData',
        tag: 'files'

    },
    {
        label: 'XML',
        value: 'xml',
        icon_name: 'xml.png',
        tag: 'files'

    },
    {
        label: 'CSV',
        value: 'csv',
        icon_name: 'csv.png',
        tag: 'files'

    },
    {
        label: 'Facebook API',
        value: 'fb_api',
        icon_name: 'facebook.png',
        tag: 'application'

    },
    {
        label: 'snowflake',
        value: 'snowflake',
        icon_name: 'snow_flake.png',
        tag: 'application'
    },


]



function Index(props) {

    const {

        title,
        desc,
        set_data_source_type,
        data_source_type,
        innerStep,
        setInnerStep,
        dbType,
        db_infos,
        db_id,
        set_db_id,
        setActiveStep,
        activeStep,
        jobType,
        job_state,
        fileMapping,
        setFileMapping,
        set_job_state,
    } = props;


    console.log("PD", fileMapping)


    const [db_type, set_db_type] = useState();
    const [active_db_id, set_active_db_id] = useState(undefined);
    const [local_dbs_type, set_local_dbs_type] = useState(undefined);

    const [activeTab, setActiveTab] = useState('all')


    const save_fun = (file_mapping, link, db_id) => {

        set_db_id(db_id)

        if (jobType === "google_drive_upload") {


            const convertedDataArray = [];

            for (const sheetName in file_mapping) {
                file_mapping[sheetName].forEach(item => {
                    // Apply the filter condition
                    if (item.isSelected) { // Include only active items
                        convertedDataArray.push({
                            id: item.id,
                            file_identifier: `${sheetName}__nfx__${item.name}`,
                            target_table_name: item.target_table_name,
                        });
                    }
                });
            }

            setFileMapping(convertedDataArray);

            const clone_job_state = job_state ? { ...job_state } : {};
            clone_job_state['drive_path'] = link;
            set_job_state(clone_job_state)
            setActiveStep(4)


        } else {
            setActiveStep(activeStep + 1)

        }
    }

    const open_db_chooser1 = (db_type) => {


    }

    const open_db_chooser = (db_type) => {

        if (db_type === "google_drive_upload") {
            showPopup(undefined, undefined, enums.default.popupType.element, GDriveLink, {
                db_type: db_type,
                save_fun: save_fun,
                selected_db_id: db_id,
                job_state: job_state,
                fileMapping: fileMapping
            }
                ,
                undefined,
                undefined,
                undefined, {
                width: '95vw',
                height: '85vh'
            },
                true
            )
        } else {
            showPopup(undefined, undefined, enums.default.popupType.element, DbChooser, {
                db_type: db_type,
                save_fun: save_fun,
                selected_db_id: db_id
            }
                ,
                undefined,
                undefined,
                undefined, {
                width: '95vw',
                height: '85vh'
            },
                true
            )
        }

    }




    useEffect(() => {
        const filterd = dbs_types?.filter((f) => {
            if (jobType == "google_drive_upload") {
                return f.tag == 'files'
            } else {
                return f;
            }
        })
        set_local_dbs_type(filterd)
    }, [])



    useEffect(() => {

        const active_db_info = db_id && props.db_infos.find((d) => d.id === db_id);
        set_db_type(active_db_info?.db_type);
        set_active_db_id(db_id)
        
    }, [db_id, props.db_infos])



    const dbs_search_result = (value) => {
        const clone_dbs_types = dbs_types ? [...dbs_types] : [];
        const filterd_dbs = clone_dbs_types?.filter((d) => {
            if (value && (d.label.toLowerCase().indexOf(value?.toLowerCase()) > -1 || d.value?.toLowerCase(value?.toLowerCase()) > -1)) {
                return true;
            } else {
                return false;
            }
        });

        if (value) {
            set_local_dbs_type(filterd_dbs)
        } else {
            set_local_dbs_type(dbs_types)
        }

    }


    const filter_result_by_tab = (value) => {
        const clone_dbs_types = dbs_types ? [...dbs_types] : [];
        const filterd_dbs = clone_dbs_types?.filter((d) => {
            if (value === "all") return true;
            else if (value && (d.tag.toLowerCase().indexOf(value?.toLowerCase()) > -1 || d.tag?.toLowerCase(value?.toLowerCase()) > -1)) {
                return true;
            } else {
                return false;
            }
        });

        if (value) {
            set_local_dbs_type(filterd_dbs)
        } else {
            set_local_dbs_type(dbs_types)
        }
        setActiveTab(value)
    }

    return (
        <Wrapper active={activeStep}>

            <div className='inner_wrapper'>

                <h1 className='title_up'>{title}</h1>
                <p style={{ marginBottom: '10px' }} className='sub_title_e12'>{desc}</p>

                <div className='search_wrapper'>
                    <input type='text' onChange={(e) => {
                        dbs_search_result(e.target.value)
                    }}
                        placeholder='Search Source Type'
                    />

                    <div className='search_icon'>
                        <SearchSvg size={'1rem'} height={'1rem'} color="#989696ed" />
                    </div>
                </div>

                {jobType !== "google_drive_upload" && (
                    <div style={{ margin: '1rem auto', width: '100%' }}>
                        <Tab
                            hideShadow={true}
                            // upper_case={true}
                            backgroundColor={"rgb(248, 249, 253)"}
                            tabList={['all', 'database', 'files', 'application']}
                            activeType={activeTab}
                            setActiveType={(value) => {
                                filter_result_by_tab(value)
                            }}
                        />
                    </div>
                )}

                <Row style={{ flexWrap: 'wrap' }}>

                    {local_dbs_type?.length > 0 ? local_dbs_type?.map((db_item, idx) => {
                        const is_active = db_item?.value === db_type || jobType === db_item?.value;
                        const connection_filterd = ((db_infos || []).filter((__db) => __db.db_type === db_item?.value) || [])
                        return (
                            <Column
                                active={is_active}
                                onClick={() => {
                                    if (!db_item?.link) {
                                        set_db_type(db_item?.value)
                                        open_db_chooser(db_item?.value)
                                    }
                                    if (db_item?.link) {
                                        props.history.push(db_item?.link);
                                    }
                                }}
                            >
                                <div className="icon">
                                    <img src={constants.CONTEXT_PATH + `./db_icon_v2/${db_item?.icon_name}`}></img>
                                </div>
                                <p>{db_item?.label}</p>
                            </Column>
                        )
                    }) : <p style={{
                        textAlign: 'center',
                        margin: 'auto',
                        fontSize: '1rem',
                        color: '#444'
                    }}>No Data Source Found...</p>}
                </Row>
            </div>


        </Wrapper>
    )

};








const mapStateToProps = (state) => ({
    db_infos: state.connectionReducer.db_infos,
})


export default withRouter(connect(mapStateToProps, {})(Index))
