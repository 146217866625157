import React, { useEffect, useState, Suspense } from 'react'
import styled from 'styled-components';
import SelectOption from '../select-option/select.option.v1.1';
import TextBox from '../hoc/text.box.v1';

const Wrapper = styled.div`
    width: 100%;
`;

const data_set = [
    { name: 'foo', value: 'a', type: 'saroj' },
    { name: 'bar', value: 'b', type: 'saroj' },
    { name: 'baz1', value: 'c', type: 'vikas' },
    { name: 'baz', value: 'bd', type: 'vikas' },

    { name: 'baz', value: 'e', type: 'vikas' },
    { name: 'baz', value: 'f', type: 'vikas' },
    { name: 'baz', value: 'g', type: 'vikas' },
    { name: 'baz', value: 'df', type: 'vikas' },
    { name: 'baz', value: 'df', type: 'vikas' },
    { name: 'baz', value: 'df', type: 'vikas' },

    { name: 'sssss', value: 'baz2', type: 'saroj' }
]
const data_set2 = [
    "foo", "bar", "baz", "baz2", "abc", "abcd", "abcdefg", "dsf", "gtr", "gty", "feref", "trer"
]



const Index = (props) => {


    const [value, set_value] = useState([])
    const [value2, set_value2] = useState('')
    const [value3, set_value3] = useState('')


    return (
        <Wrapper style={{ background: '#fff', width: '100%', height: '100%', padding: '5rem', boxSizing: 'border-box' }}>
            <SelectOption
                label="label one"
                select_option_style={"type1"}
                options={data_set}
                display_key={"name"}
                value_key="value"
                value={value}
                on_change={(v, value_object) => {
                    const temp_value = value ? [...value] : [];
                    if (value && value.indexOf(v) === -1) temp_value.push(v)
                    else temp_value.splice(value.indexOf(v), 1)
                    set_value(temp_value)
                }}
                group_key={"type"}
                value_join_with={"__nf__"}
                type="text"
            />


            <div>
                <TextBox
                    label="label bhi"
                    value={value2}
                    onChange={(e) => {
                        console.log("vvvv", e.target.value);
                        set_value2(e.target.value)
                    }}
                />
            </div>

            <div style={{ height: '100px', overflow: 'hidden', position: 'fixed', width: '100%', zIndex: 11, left: 100, top: '90%' }}>
                <SelectOption
                    label="label two"
                    options={data_set2}
                    value={value3}
                    on_change={(value) => {
                        set_value3(value)
                    }}
                />
            </div>


        </Wrapper>
    )
}


export default Index;