import * as actionTypes from '../action-types/permission';
import { saveUser, saveInSession, getFromSession, killUser } from '../utils/session.helper';
import { _whatToDoIfTokenNotFound } from '../utils'
import { constants } from '../utils/constants';
import { post } from '../data.service';
import { client_specific_workarounds } from '../client.specific.workarounds';
import { simpleDispatch_get, simpleDispatch_post, dispatchAction, raiseSuccess } from './helper';


export const dispatchPermissionInfo = permissions => dispatch => {
    // dispatchAction(dispatch, actionTypes.SAVE_PERMISSIONS, permissions, null, null, '');
};


export const get_permissions_for_user = () => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.ACCESS_GROUP.POINT + constants.END_POINTS.ACCESS_GROUP.GET_PERMISSIONS_FOR_USER;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_PERMISSIONS_FOR_USER_V1);
};

