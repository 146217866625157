import styled from 'styled-components';
import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils';

let selected = getSelectedTheme();

const { actionCardStyle } = themes[selected]

export const Card = styled.div`
    width: 15rem;
    height: 12rem;
    box-shadow: 0px 0px 6px 0px rgba(144,181,245,1);
    box-sizing: border-box;
    cursor: pointer;
    margin: .7rem;
    padding-top:${props => props.action ? '1.5rem' : '1rem'};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 1rem;
    background: ${props => props.bgColor || '#fff'};

    &:hover{
        border: 2px solid #337DFF;
        .overlay_hover{
            height: 100%;
        } 
    }


    .overlay_hover{
        position: absolute;
        display: flex;
        height: 0%;
        width: 100%;
        z-index: 0;
        transition: all 0.4s;
        overflow: hidden;
        background: ${actionCardStyle.color.overlayBg};
        align-items: center;
        justify-content: center;
        font-size: 1.3rem;
        font-weight: 500;
        padding-bottom: 0rem;
        box-sizing: border-box;
        text-align: center;
        color:${actionCardStyle.color.overlayText};
        word-break: break-word;
        padding: 0px 10px;
        box-sizing: border-box;
        border-radius: 1rem;
  }
    .hover_action{
        width: 100%;
        visibility: hidden;
    }

   
    .icon_wrapper{
        padding: 0.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
    
        :hover{
            /* background: ${actionCardStyle.color.iconWrapperHover}; */
            background: #e3e3e3;
            border-radius: 50px;
            svg{
                fill: ${actionCardStyle.color.iconHover};
            }
            .g_color{
                fill: ${actionCardStyle.color.iconHover};
            }
        }
    }


    &:hover{
        .hover_action{
            visibility: visible;
        }
    }
`;


export const ImageWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 1rem 1rem 0 0;
    color: #fff;
    img{
        max-width: 100%;
    }
`;




export const ConetentSection = styled.div`
    position: relative;
    width: 100%;
    height: 4rem;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    .title{
        font-size: .7rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1rem;
        letter-spacing: normal;
        text-align: left;
        /* color: ${actionCardStyle.color.mainTitle}; */
        color: #4d4d4d;
        margin-bottom: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        text-align: center;
        text-transform: capitalize;
    }

    .subTitle{
        font-size: 0.92rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        position: relative;
        width: 100%;
        text-align: center;
        margin: 0.3rem 0 0.5rem;
        /* margin-bottom: 0.5rem; */
    }
    .date{
        font-size: 0.64rem;
        font-weight: normal;
        line-height: 0.4rem;
    }

    .hover_action{
        position: absolute;
        top: 0%;
        width: 42%;
        right: 0px;
        display: block;
    }

`;