import React, { useEffect, useState } from 'react'
import FormulaEditor from './editor'
import { getParameterByName } from '../../utils';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { connect } from 'react-redux';
import { saveDbFormula } from '../../actions/db.formula';

const Index = (props) => {

    const { get_schema_table_info, schema_table_info, location } = props;

    const { editedFormula } = props;

    const { search } = location;

    const [formulaId, setFormulaId] = useState(undefined)

    useEffect(() => {
        let _page_info = {
            title: formulaId ? "EDIT YOUR FORMULA" : 'CREATE NEW FORMULA',
            isBack: true
        }
        props.dispatchHeaderInformation(_page_info)
    }, [formulaId])



    useEffect(() => {

        const formula_id = getParameterByName('formula_id', search);
        formula_id && setFormulaId(formula_id);
    }, [search]);



    const local_save = (formulas, reporting_db_info_id) => {
        props.saveDbFormula(formulas, reporting_db_info_id, props.history)

    }


    return (
        <FormulaEditor formulaId={formulaId} saveFun={local_save} />
    )
}



const mapStateToProps = (state, props) => ({

    schema_table_info: state.reportBuilder.schema_table_info,
    editedFormula: state.dbFormula.editedFormula,
    functions_config: state.reportReducer.functions_config,

})


export default withRouter(connect(mapStateToProps, { saveDbFormula })(Index));