import { simpleDispatch_post, dispatchAction, simpleDispatch_put } from './helper';
import * as actionTypes from '../action-types/layout';
import { constants } from '../utils/constants';

import { getInsightsListByUser } from '../actions/insights';


export const getLayoutList = userId => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.INSIGHTS.POINT + constants.END_POINTS.INSIGHTS.GET_INSIGHTS_LIST_BY_USER;

    const post = {
        user_id: userId,
        get_layout: true
    }

    await simpleDispatch_post(dispatch, url, post, actionTypes.GET_LAYOUT_LIST_SUCCESS);
};

export const saveLayoutForUser = (report, userId, close, option, popupKey, shouldHit) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.SAVE_REPORT;

    await simpleDispatch_put(dispatch, url, report, actionTypes.SAVE_LAYOUT_SUCCESS);

    await getInsightsListByUser(userId, true)(dispatch);

    close(option, popupKey);
};

export const setActiveEditLayout = data => dispatch => {
    dispatchAction(dispatch, actionTypes.SET_ACTIVE_EDIT_LAYOUT_SUCCESS, data);
};



export const dispatchAutoLayoutData = (data) => dispatch => {
    dispatchAction(dispatch, actionTypes.DISPATCH_AUTO_LAYOUT_DATA, data);
} 
