import styled from 'styled-components';

export const PositionWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: fit-content;
    box-sizing: border-box;

    fieldset {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 5px; 
    cursor: pointer;
    position:relative;
    z-index: 2;
    box-sizing: border-box;
`;

export const LegendBoxWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    border-radius: 2px;
    align-items: center;
    padding: 4px 4px;
    margin-left: 5px;
    position: relative;
    box-sizing: border-box;
    border: ${props => props.borderColor ? `1px solid ${props.borderColor}` : ''};
`;

export const LegendColorWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
`;


export const LegendContentWrapper = styled.div`
    position: absolute;
    z-index: 10;
    padding: 6px;
    box-sizing: border-box;
    background: #f1f1f2;
    border-radius: 5px;
    margin-top: 2px;
    left: -1px;
    border: 1px solid #b5b3b3;
    height: fit-content;

    label {
        font-size: 10px;
    }

    input {
        border: none;
        background: transparent;
        height: 20px;
        width: 20px;
        cursor: pointer;
    }
`;