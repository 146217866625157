/* 
    This component responsible for showing the drop down above the chart
*/

/***
 * new filter component 
 * writen by - saroj kr
 * Feb 2023
 * 
 */

import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import { apply_filters, update_report_filter_alias, clear_all_filters, expand_option, clear_drill_filter_cache, get_filter_distinct_data, clear_link_filter } from '../../actions/filter';
import { set_report_to_refresh } from '../../actions/report';
import {
    ButtonWrapper, ItemRow, ItemWrapper, ButtonNew,
    ReportFilterWrapper
} from './filter.styled';

import { FULL_MONTHS, YEARS } from '../../shared-with-fe/constants';
import { RadioCheckMark } from '../hoc/radiobox';
import Checkbox from '../hoc/checkbox';
import CustomDatePicker from '../hoc/date-time-piker/index'
import SelectOptionV1 from '../select-option/select.option.v1.1'
import { quick_date_options } from './shared.function.js';
import FilterPostSvg from '../svg/filter.post';
import { get_display_value_of_filter_v1, showPopup } from '../../utils';
import AdvanceDateSettingPopup from './advanced.date.setting';
import enums from '../../utils/enums';


let counter = 0;


/**
 * 
 * @param {*} props 
 * @returns 
 */

const DateFilterV1 = props => {

    const [activeTab, setActiveTab] = useState(undefined)
    const [single_type, set_single_type] = useState("month")

    const { active_key, date_inputs, selected_filters, select_unselect_date_filter } = props;

    useEffect(() => {
        if (activeTab !== selected_filters?.type) {
            setActiveTab(selected_filters?.type)
            set_single_type(selected_filters?.single_type)
        }
    }, [selected_filters])


    const on_change_single_type = (type) => {
        const new_value_object = {
            ...selected_filters,
            type: 'custom_date',
            single_type: type
        };
        set_single_type(type)
        select_unselect_date_filter(active_key, "month", new_value_object)
    }

    return (
        <React.Fragment>
            <div>
                <div className='tab_parent'>
                    <div className={activeTab === 'quick_date' ? "active tab_item" : "tab_item"} onClick={(e) => setActiveTab('quick_date')}>Quick Date</div>
                    <div className={activeTab === 'range' ? "active tab_item" : "tab_item"} onClick={(e) => setActiveTab('range')}>Range</div>
                    <div className={activeTab === 'custom_date' ? "active tab_item" : "tab_item"} onClick={(e) => setActiveTab('custom_date')}>Single</div>

                </div>

                <div style={{ marginTop: '0px', background: '#1e66af' }} >
                    {activeTab === "quick_date" && <div className='quick_date_options'>
                        {quick_date_options?.map((option) => {
                            return (
                                <div style={{
                                    background: (selected_filters?.["quick_date"] === option.value) ? "#83add5" : undefined,
                                    color: (selected_filters?.["quick_date"] === option.value) ? "#222" : undefined,
                                }} className='option' onClick={() => {
                                    select_unselect_date_filter(active_key, "month", { quick_date: option.value, type: 'quick_date' })
                                }}>{option.label}</div>
                            )
                        })}

                    </div>}

                    {activeTab === 'custom_date' && <React.Fragment>
                        <div style={{ padding: '10px 5px 10px 5px' }} className="custom_date_options">
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}>
                                <div className='cell' onClick={() => on_change_single_type("month")}>
                                    <RadioCheckMark dColor="#23b2fa" bColor="#fff" outerSize=".9rem" checked={single_type === "month"} />
                                    <span>Month</span>
                                </div>
                                <div className='cell' onClick={() => on_change_single_type("year")}>
                                    <RadioCheckMark dColor="#23b2fa" bColor="#fff" outerSize=".9rem" type="checkbox" checked={single_type === "year"} />
                                    <span>Year</span>
                                </div>
                                <div className='cell' onClick={() => on_change_single_type("single_date")}>
                                    <RadioCheckMark dColor="#23b2fa" bColor="#fff" outerSize="1rem" type="checkbox" checked={single_type === "single_date"} />
                                    <span>Date</span>
                                </div>
                            </div>

                            {single_type === 'month' && (
                                <React.Fragment>
                                    <div style={{ padding: '10px 5px 5px 5px' }}>
                                        <label className='lbl'>Select Year</label>
                                        <SelectOptionV1
                                            labelStyle={{
                                                backgroundColor: 'red'
                                            }}
                                            options={YEARS.map(y => y.toString()) || []}
                                            value={selected_filters?.['year']}
                                            on_change={(value) => {
                                                const new_value_object = {
                                                    year: value,
                                                    type: 'custom_date',
                                                    single_type: 'month'
                                                };
                                                select_unselect_date_filter(active_key, "month", new_value_object)
                                            }}
                                            none_value={false}
                                        />
                                    </div>
                                    <div style={{ padding: '10px 5px 5px 5px' }}>
                                        <label className='lbl'>Select Month</label>
                                        <SelectOptionV1
                                            labelStyle={{
                                                backgroundColor: 'red'
                                            }}
                                            options={months_selections}
                                            value={selected_filters?.['month']}
                                            display_key={"key"}
                                            value_key={"value"}
                                            on_change={(value) => {
                                                const new_value_object = {
                                                    ...selected_filters,
                                                    month: value,
                                                    type: 'custom_date',
                                                    single_type: 'month'
                                                };
                                                select_unselect_date_filter(active_key, "month", new_value_object)
                                            }}
                                            none_value={false}
                                        />
                                    </div>
                                </React.Fragment>
                            )}

                            {single_type === 'year' && (
                                <React.Fragment>
                                    <div style={{ padding: '10px 5px 5px 5px' }}>
                                        <label className='lbl'>Select Year</label>
                                        <SelectOptionV1
                                            options={YEARS.map(y => y.toString()) || []}
                                            value={selected_filters?.['year']}
                                            on_change={(value) => {
                                                const new_value_object = {
                                                    ...selected_filters,
                                                    year: value,
                                                    type: 'custom_date',
                                                    single_type: 'year'
                                                };
                                                select_unselect_date_filter(active_key, "year", new_value_object)
                                            }}
                                            none_value={false}
                                        />
                                    </div>
                                </React.Fragment>
                            )}

                            {single_type == "single_date" && (
                                <div style={{ marginTop: '10px', padding: '5px 8px' }}>
                                    <CustomDatePicker
                                        labelStyle={{
                                            marginBottom: "4px",
                                            fontSize: ".7rem",
                                            color: "#fff",
                                            fontWeight: 'bold'
                                        }}
                                        label={"Select Your Date"}
                                        onChange={(value) => {
                                            const new_value_object = { single_type: "single_date", single_date: value, type: "custom_date" };
                                            select_unselect_date_filter(active_key, "Df", new_value_object)
                                        }}
                                        value={selected_filters?.['single_date']}
                                    />
                                </div>
                            )}
                        </div>

                    </React.Fragment>}

                    {activeTab === 'range' && <React.Fragment>
                        <div style={{ padding: '15px 10px 5px 10px' }}>
                            <CustomDatePicker
                                labelStyle={{
                                    marginBottom: "4px",
                                    fontSize: ".7rem",
                                    color: "#fff",
                                    fontWeight: 'bold'
                                }}
                                label={"Select Start Date"}
                                type='date'
                                onChange={(value) => {
                                    const new_value_object = {
                                        ...selected_filters,
                                        range_start_date: value,
                                        type: "range"
                                    };
                                    select_unselect_date_filter(active_key, "range", new_value_object)

                                }} value={selected_filters?.['range_start_date']} />
                        </div>

                        <div style={{ padding: '10px 10px 10px 10px' }}>
                            <CustomDatePicker type='date'
                                labelStyle={{
                                    marginBottom: "4px",
                                    fontSize: ".7rem",
                                    color: "#fff",
                                    fontWeight: "bold"
                                }}
                                label={'Select End Date'}
                                onChange={(value) => {
                                    const new_value_object = {
                                        ...selected_filters,
                                        range_end_date: value,
                                        type: 'range'
                                    };
                                    select_unselect_date_filter(active_key, "range", new_value_object)

                                }} value={selected_filters?.['range_end_date']} />
                        </div>
                    </React.Fragment>}


                </div>
            </div>
        </React.Fragment>
    )
}




const months_selections = [];
Object.keys(FULL_MONTHS).forEach((m) => {
    months_selections.push({
        key: FULL_MONTHS[m],
        value: m
    })
})



const FilterComp = (props) => {

    const { filters_cache, backend_filter_stored, is_global_filters, dropdowns_cache, which_option_expanded_cache, mtd_ytd_options_cache, id, validation_key, is_hierarchy = false, widegt_ids } = props;

    const [dropdowns_data, set_dropdowns_data] = useState({});
    const [changed_filter_info, set_changed_filter_info] = useState({});
    const [show_filters_input, set_show_filter_input] = useState();

    const [selected_filters, set_selected_filters] = useState({});

    const [activeTab, setActiveTab] = useState(undefined);
    const [enabled_keys, set_enabled_keys] = useState(undefined);
    const dropdown_data_from_cache = dropdowns_cache && dropdowns_cache[id];
    const expanded_option_from_cache = which_option_expanded_cache && which_option_expanded_cache[id];
    const filters_from_cache = filters_cache && filters_cache[id];
    const be_stored_filter = backend_filter_stored && backend_filter_stored[id];

    const [filter_inputs_value, set_filter_input_value] = useState(undefined)



    const open_advance_date_popup = () => {
        showPopup(undefined, undefined, enums.popupType.element, AdvanceDateSettingPopup, {
            id: id,
            save: () => {
                props.set_report_to_refresh(id)
            }
            // question: this.props.question,
            // dimension, dimension_value, comparison, comparison_value,
            // drill_down_values: this.props.drill_down_values,
            // ...info_wrapper,
            // b_history: this.props.history,
            // _id: this.props.id
        })
    }

    const filter_input_on_change = (key, vlaue) => {

        const clone_filter_inputs = filter_inputs_value ? JSON.parse(JSON.stringify(filter_inputs_value)) : {};

        clone_filter_inputs[key] = vlaue;

        set_filter_input_value(clone_filter_inputs)
    }



    useEffect(() => {

        // let's disable other records

        const enabled_keys_new = {};

        be_stored_filter && be_stored_filter.date_filter && Object.keys(be_stored_filter.date_filter).forEach(column_name => {
            enabled_keys_new[column_name] = {};
            Object.keys(be_stored_filter.date_filter[column_name]).forEach(date_key => {
                // fomat is yyyy-q-mm, for enabling a date, we will need to enable the parent also
                const keys = date_key.split('-');
                if (keys[0]) {
                    enabled_keys_new[column_name][keys[0]] = 1;
                }
                if (keys[1]) {
                    enabled_keys_new[column_name][keys[0] + '-' + keys[1]] = 1;
                }

                enabled_keys_new[column_name][date_key] = 1;
            });
        });
        set_enabled_keys(enabled_keys_new);
    }, [be_stored_filter]);



    useEffect(() => {
        setActiveTab(expanded_option_from_cache);
    }, [expanded_option_from_cache]);


    useEffect(() => {

        const filter_to_use = filters_from_cache && !Array.isArray(filters_from_cache) ? filters_from_cache : {};

        const dropdown_data_from_cache_to_use = dropdown_data_from_cache ? JSON.parse(JSON.stringify(dropdown_data_from_cache)) : {};

        const temp_filter_input_value = filter_inputs_value ? JSON.parse(JSON.stringify(filter_inputs_value)) : {};
        const keys_of_filters = dropdown_data_from_cache_to_use ? Object.keys(dropdown_data_from_cache_to_use) : [];

        keys_of_filters.forEach((filter_key) => {

            const selection_filter_data = (dropdown_data_from_cache_to_use[filter_key]?.filter_data) || [];
            const selected_filter_data = (filter_to_use?.[filter_key]) || [];
            const local_selected = selected_filters?.[filter_key] || [];

            const mearg_selection = ([...(Array.isArray(selected_filter_data) ? selected_filter_data : []), ...(Array.isArray(local_selected) ? local_selected : []), ...selection_filter_data]) || [];
            const filter_data = [...new Set(mearg_selection)]

            if (dropdown_data_from_cache_to_use && dropdown_data_from_cache_to_use[filter_key]) {
                dropdown_data_from_cache_to_use[filter_key]['filter_data'] = filter_data;
                // let's  store last search value
                if (dropdown_data_from_cache_to_use[filter_key].search_value && !temp_filter_input_value[filter_key]) {
                    temp_filter_input_value[filter_key] = dropdown_data_from_cache_to_use[filter_key].search_value
                }
            }
        })
        set_filter_input_value(temp_filter_input_value)
        set_dropdowns_data(dropdown_data_from_cache_to_use);

    }, [filters_from_cache, dropdown_data_from_cache]);



    useEffect(() => {

        const filter_to_use = filters_from_cache && !Array.isArray(filters_from_cache) ? filters_from_cache : {};
        const dropdown_data_from_cache_to_use = dropdown_data_from_cache ? JSON.parse(JSON.stringify(dropdown_data_from_cache)) : {};
        const drill_filter_cache = props.drill_filter_cache;

        // this if block will delete the selected filter key and value from filter

        if (props.is_insight && drill_filter_cache) {

            filter_to_use && (Object.keys(filter_to_use) || []).forEach(key => {

                if (drill_filter_cache && drill_filter_cache[key]?.not_save_in_session) {

                    const filter_value = filter_to_use?.[key]?.length > 0 ? filter_to_use[key][0] : undefined;
                    const index_of_value = dropdown_data_from_cache_to_use?.[key]?.["filter_data"]?.indexOf(filter_value)
                    if (index_of_value > -1) {
                        dropdown_data_from_cache_to_use[key]["filter_data"].splice(index_of_value, 1)
                    }
                    delete filter_to_use[key];
                }
            })
        }

        set_selected_filters(filter_to_use);

    }, [filters_from_cache]);


    const select_unselect_item = (key, base_item_text, data_type, clear_all = false) => {

        const existing_filters = selected_filters ? JSON.parse(JSON.stringify(selected_filters)) : {};
        const item_index_in_existing_array = is_this_value_selected(key, base_item_text, data_type)

        if (clear_all) {
            existing_filters[key] = [];
        }
        else {
            if (item_index_in_existing_array > -1) {
                // lets remove
                existing_filters[key].splice(item_index_in_existing_array, 1);
            }
            else {
                if (typeof existing_filters[key] === 'undefined') existing_filters[key] = [];
                existing_filters[key].push(base_item_text);
            }
        }

        set_selected_filters(existing_filters);
    };


    counter++;

    // if (counter % 100) console.log('reached: ', counter);

    const get_data_type = (data_type) => {


        const number_data_types = ['int', 'float', 'double', 'number', 'currency'];

        let dataType = 'string';

        const data_type_to_use = data_type && data_type.toLocaleLowerCase();

        if (number_data_types.indexOf(data_type_to_use) > -1) {
            dataType = 'number'
        }
        if (data_type_to_use === 'string' || data_type_to_use === 'varchar' || data_type_to_use === 'text' || data_type_to_use === 'varchar2') dataType = 'string'
        if (data_type_to_use === 'boolean' || data_type_to_use === 'tinyint') dataType = 'boolean'
        if (data_type_to_use === 'date' || data_type_to_use === 'datetime') dataType = 'date'

        return dataType

    }


    const is_this_value_selected = (key, value, data_type) => {
        const clone_selected = selected_filters?.[key] || [];

        for (let index = 0; index < clone_selected.length; index++) {
            const v = clone_selected[index];;
            // this is for string
            if (data_type && data_type === 'string' && v && value && typeof v === "string") {
                if (v?.toLocaleLowerCase() === value.toLocaleLowerCase()) return index;
            }
            else if (v === value) {
                return index;
            }
            else {
                if (v === value) return index;
            }
        }
    }

    const search_filter_distinct = debounce(function (id, type, key, value) {
        props.get_filter_distinct_data(id, type, key, value)
    }, 500);

    const update_filter_dropdowns_alias = (key, value) => {

        // filter_info
        const clone_changed_filter_info = changed_filter_info ? JSON.parse(JSON.stringify(changed_filter_info)) : {}
        const clone_dropdowns_data = dropdowns_data ? JSON.parse(JSON.stringify(dropdowns_data)) : {};

        if (clone_dropdowns_data[key] && clone_dropdowns_data[key].filter_info) {
            clone_dropdowns_data[key]['filter_info']['alias'] = value;
            clone_dropdowns_data[key]['filter_info']['modified'] = true;
            clone_changed_filter_info[key] = clone_dropdowns_data[key]['filter_info']
        }

        set_changed_filter_info(clone_changed_filter_info)
    }


    const select_unselect_date_filter = (key, type, value) => {

        const existing_filters = selected_filters ? JSON.parse(JSON.stringify(selected_filters)) : {};

        if (!existing_filters["date_filter"]) existing_filters["date_filter"] = {};

        if (!existing_filters["date_filter"][key]) existing_filters["date_filter"][key] = "";

        existing_filters["date_filter"][key] = value

        set_selected_filters(existing_filters);
    }


    const filter_order = dropdowns_data && Object.keys(dropdowns_data);


    // console.log("dropdowns_cache", filter_order, is_hierarchy)
    return (
        <React.Fragment>
            <ReportFilterWrapper minHeight={props.minHeight} width={props.width} maxHeight={props.maxHeight}>
                <div className="inner_height_wrapper">
                    <ItemWrapper filterItemMaxHeight={props.filterItemMaxHeight} show={"generic_date" === activeTab}>
                        <div
                            className="sub_item"
                            onClick={() => {
                                props.expand_option(id, "generic_date");
                            }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <span onDoubleClick={(e) => {
                                    set_show_filter_input('generic_date')
                                }} title={"Master Date"}>Time Presents</span>
                            </div>
                            <i className={"generic_date" === activeTab ? "fas fa-caret-up" : "fas fa-caret-down"}></i>
                        </div>

                        {"generic_date" === activeTab && (
                            <div className="filter_scroller">
                                <DateFilterV1
                                    selected_filters={selected_filters?.["date_filter"]?.["generic_date"]}
                                    active_key={"generic_date"}
                                    select_unselect_date_filter={select_unselect_date_filter}
                                />
                            </div>
                        )}
                    </ItemWrapper>

                    {props.is_insight && process.env.REACT_APP_MODE === "barc" && (
                        //  {props.is_insight && (

                        <ItemWrapper onClick={() => {
                            open_advance_date_popup()
                        }}>
                            <div
                                className="sub_item"
                            >
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <span title={"Date Setting"}>Advance Date Setting</span>
                                </div>
                            </div>
                        </ItemWrapper>
                    )}

                    {
                        dropdowns_data && Object.keys(dropdowns_data).length > 0 && Object.keys(dropdowns_data).map((key, index) => {

                            const selection_filter_data = (dropdowns_data[key] && dropdowns_data[key].filter_data) || [];
                            const dataOfFilter = selection_filter_data;

                            const f_index = filter_order.indexOf(key);

                            const filter_data = () => {

                                console.log("dataOfFilter", dataOfFilter?.[0])
                                // service_name__nfx__v__OPD__nfx__n__speciality_name__nfx__v__Pulmonology
                                const keys = (dataOfFilter && dataOfFilter?.length > 0 && dataOfFilter?.[0] && dataOfFilter?.[0]?.toString()?.split("__nfx__n__") || []);
                                keys.pop();

                                // console.log("keys", keys);
                                if (!is_hierarchy) {
                                    return dataOfFilter
                                }

                                else {

                                    const filterd_v1_data = (dataOfFilter || []).filter((a) => {

                                        var found = false;
                                        keys.map((__k) => {

                                            const __k__ = __k.split("__nfx__v__")[0];
                                            const __k__value = a.split("__nfx__n__")[0];

                                            const selected_filters_values = (selected_filters[__k__] || []) || [];

                                            // console.log("ram", found, selected_filters_values, __k__value)

                                            if (selected_filters_values.length === 0) {
                                                found = true;
                                            }
                                            else if (selected_filters_values.indexOf(__k__value) > -1) {
                                                found = true;
                                            } else {
                                                found = false;
                                            }

                                        })

                                        if (found) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    })

                                    // console.log("final, ", dataOfFilter?.length, filterd_v1_data?.length)
                                    return filterd_v1_data;
                                }
                                // return []
                            }
                            const filterd_hierarchy_data = f_index > 0 ? filter_data() : dataOfFilter;
                            // console.log("filterd_hierarchy_data", filterd_hierarchy_data[0], key, f_index, filter_order)

                            const filter_info = dropdowns_data[key] && dropdowns_data[key].column_info;
                            const display_key = (key && key.length > 0 && key.indexOf(".") > 0) ? key.split(".")[1] : key;
                            const alias = (changed_filter_info && changed_filter_info?.[key]?.alias) ? changed_filter_info[key].alias : dropdowns_data[key]?.filter_info?.alias
                            const data_type = get_data_type(filter_info && filter_info.data_type)


                            if (true) {
                                return (
                                    filterd_hierarchy_data && <ItemWrapper filterItemMaxHeight={props.filterItemMaxHeight} show={key === activeTab}>
                                        <div
                                            className="sub_item"
                                            onClick={() => {
                                                props.expand_option(id, key);
                                            }}
                                        >
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                                                {show_filters_input === key && props.is_insight ? (
                                                    <input
                                                        type="text"
                                                        id="alias"
                                                        placeholder='Type Alias'
                                                        defaultValue={alias}
                                                        autoFocus={true}
                                                        onBlur={() => {
                                                            if (is_global_filters && changed_filter_info && Object.keys(changed_filter_info)?.length > 0) props.update_report_filter_alias(changed_filter_info, id)
                                                            set_show_filter_input()
                                                            set_changed_filter_info({})
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                        }}
                                                        onChange={(e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            update_filter_dropdowns_alias(key, e.target.value)
                                                        }}
                                                    />
                                                ) : <span onDoubleClick={(e) => {
                                                    set_show_filter_input(key)
                                                }} title={key}> {alias ? alias : display_key}</span>}
                                            </div>

                                            {(key === activeTab && data_type === 'date' && selected_filters?.["date_filter"]?.[key]) && <span
                                                onClick={(e) => {
                                                    select_unselect_date_filter(key, {})
                                                }}
                                                className='clear_btn'>clear</span>}

                                            {(key === activeTab) && selected_filters[key]?.length > 0 && data_type !== 'date' && <span
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    select_unselect_item(key, undefined, undefined, true)
                                                }}
                                                title="Clear ALL Selected"
                                                className='clear_btn'>clear all</span>}

                                            {key !== activeTab && selected_filters[key]?.length > 0 && data_type !== 'date' && <span
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    select_unselect_item(key, undefined, undefined, true)
                                                }}
                                                title="Clear ALL Selected"
                                                className='clear_btn'>
                                                <FilterPostSvg size=".7rem" height=".7rem" color="#308bc8" />
                                            </span>}


                                            <i className={key === activeTab ? "fas fa-caret-up" : "fas fa-caret-down"}></i>
                                        </div>



                                        {(data_type === 'date' && key === activeTab) ? (
                                            <div className="filter_scroller">
                                                <DateFilterV1
                                                    selected_filters={selected_filters?.["date_filter"]?.[key]}
                                                    active_key={key}
                                                    select_unselect_date_filter={select_unselect_date_filter}
                                                />
                                            </div>
                                        ) : (
                                            <div className="filter_scroller" style={{ paddingLeft: '5px' }}>
                                                {key === activeTab &&
                                                    <React.Fragment>
                                                        {(data_type === 'string' || data_type === "number") &&
                                                            <input
                                                                placeholder={dropdown_data_from_cache?.[key].value_from_input ? 'Type Minimum 3 Characters' : 'Search Filter'}
                                                                style={{
                                                                    width: '99%',
                                                                    padding: '8px',
                                                                    boxSizing: 'border-box',
                                                                    borderRadius: '2px',
                                                                    marginBottom: '8px'
                                                                }}
                                                                type="text"
                                                                onChange={(e) => {

                                                                    const value = e.target.value;
                                                                    filter_input_on_change(key, value, data_type)

                                                                    // if already we have dropdown data then filter it

                                                                    const clone_dropdown_cache = dropdown_data_from_cache?.[key] ? JSON.parse(JSON.stringify(dropdown_data_from_cache?.[key])) : {};

                                                                    if (value === "") {
                                                                        // if value is mt of the filter then set 
                                                                    }

                                                                    // if (clone_dropdown_cache && clone_dropdown_cache.filter_data.length > 0) {


                                                                    // }

                                                                    // let's fetch the filter data from backend
                                                                    if (dropdown_data_from_cache?.[key].value_from_input && value && value.length > 2 && get_data_type(data_type) !== 'number') {
                                                                        search_filter_distinct(id, "dd", key, value)
                                                                    }


                                                                    if (dropdown_data_from_cache?.[key].value_from_input && value && get_data_type(data_type) === 'number') {
                                                                        search_filter_distinct(id, "dd", key, value)
                                                                    }

                                                                    // lt's search the 
                                                                    if (!dropdown_data_from_cache?.[key].value_from_input) {

                                                                        const clone_dropdowns_data = dropdowns_data ? JSON.parse(JSON.stringify(dropdowns_data)) : {};
                                                                        const current_filter = clone_dropdowns_data?.[key];
                                                                        const data_for_filter = clone_dropdown_cache?.filter_data;

                                                                        const filterd_data = data_type === "string" ? data_for_filter.filter((a) => {
                                                                            if (a && isNaN(a) && a.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1) return true;
                                                                            else return false
                                                                        }) : data_for_filter;

                                                                        current_filter.filter_data = filterd_data;
                                                                        clone_dropdowns_data[key] = current_filter;
                                                                        set_dropdowns_data(clone_dropdowns_data)
                                                                    }
                                                                }}
                                                                value={filter_inputs_value?.[key] || ''}
                                                            />
                                                        }


                                                        {filterd_hierarchy_data && filterd_hierarchy_data.length > 0 ? filterd_hierarchy_data.map((value, index) => {

                                                            let is_active_v = is_this_value_selected(key, value, data_type) > -1;

                                                            const display_value = get_display_value_of_filter_v1(value)

                                                            return <ItemRow
                                                                onClick={() => {
                                                                    select_unselect_item(key, value, data_type);
                                                                }}
                                                            >
                                                                <Checkbox width={20} height={20} size="18px" type="checkbox" onClick={() => {
                                                                }} checked={is_active_v} />

                                                                <div className="item_name" title={display_value}>{display_value}</div>
                                                            </ItemRow>
                                                        }) : <p style={{ margin: '5px', fontSize: '10px' }}>No Value Found {data_type}</p>
                                                        }
                                                    </React.Fragment>
                                                }
                                            </div>
                                        )}
                                    </ItemWrapper>
                                )
                            }
                        })
                    }

                </div>



                <ButtonWrapper style={{ marginTop: 'auto' }}>
                    <ButtonNew title="Apply" onClick={() => {

                        const filters_to_be_applied = selected_filters;
                        const drill_filter_cache = props.drill_filter_cache;

                        props.apply_filters(id, filters_to_be_applied, undefined, drill_filter_cache, validation_key, id, (props?.is_insight ? widegt_ids : undefined));
                        props.clear_link_filter(id, undefined, true)

                        setTimeout(() => {
                            console.log("id", id)

                            props.set_report_to_refresh(id);
                        }, 300);
                        if (props.closeFilter) {
                            props.closeFilter();
                        }
                        if (props.is_insight) {
                            props.clear_drill_filter_cache(id, undefined, undefined, true)
                        }
                        if (props.is_insight && is_global_filters && changed_filter_info && Object.keys(changed_filter_info)?.length > 0) props.update_report_filter_alias(changed_filter_info, id)

                    }}>Apply</ButtonNew>


                    <ButtonNew title="Reset Filters"
                        bgColor="rgb(156, 174, 181);"
                        color="#fff"
                        onClick={() => {
                            props.clear_all_filters(id)
                            if (props.is_insight && is_global_filters && changed_filter_info && Object.keys(changed_filter_info)?.length > 0) props.update_report_filter_alias(changed_filter_info, id)
                            setTimeout(() => {
                                props.set_report_to_refresh(id);
                            }, 300);
                            if (props.closeFilter) {
                                props.closeFilter();
                            }
                            if (props.is_insight) {
                                props.clear_drill_filter_cache(id, undefined, undefined, true)
                            }
                        }}>Reset Filters</ButtonNew>
                </ButtonWrapper>
            </ReportFilterWrapper >
        </React.Fragment >
    );
};





const mapStateToProps = (state, props) => {

    const { filters_cache, backend_filter_stored, dropdowns_cache, mtd_ytd_options_cache, which_option_expanded_cache } = state.filterReducer;
    const drill_filter_cache = state?.filterReducer?.drill_filter_cache?.[props.id];

    const { drill_down_data } = state.reportReducer;
    const { user } = state.accountReducer;

    return { drill_filter_cache, filters_cache, backend_filter_stored, dropdowns_cache, user, mtd_ytd_options_cache, drill_down_data, which_option_expanded_cache }
}

export default withRouter(connect(mapStateToProps, { clear_link_filter, update_report_filter_alias, get_filter_distinct_data, clear_drill_filter_cache, apply_filters, clear_all_filters, expand_option, set_report_to_refresh })(FilterComp));

