import React from 'react';

const Close = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            {...props}
            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 447 447"
            space="preserve">
               <path d="M255.3,223.5L440.4,38.4c8.8-8.8,8.8-23,0-31.8c-8.8-8.8-23-8.8-31.8,0L223.5,191.7L38.4,6.6c-8.8-8.8-23-8.8-31.8,0
	c-8.8,8.8-8.8,23,0,31.8l185.1,185.1L6.6,408.6c-8.8,8.8-8.8,23,0,31.8c4.4,4.4,10.1,6.6,15.9,6.6c5.8,0,11.5-2.2,15.9-6.6
	l185.1-185.1l185.1,185.1c4.4,4.4,10.1,6.6,15.9,6.6c5.8,0,11.5-2.2,15.9-6.6c8.8-8.8,8.8-23,0-31.8L255.3,223.5z"/>
        </svg>

    )

}


export default Close;