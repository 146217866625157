import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '../hoc/button';
import TextBoxV1 from '../hoc/text.box.v1';
import SelectOptionsV1 from '../select-option/select.option.v1.1';


const MainWrapper = styled.div`
    width: 100%;
    height: 13rem;
    box-sizing: border-box;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 20rem;
    overflow-y: auto;

    textarea{
        width: 100%;
        height: 8rem;
        border: 1px solid #ccc;;
        padding: 10px;
        box-sizing: border-box;
        font-size: 1rem;
    }

    .close{
        position: absolute;
        right: .5rem;
        top: .5rem;
    }

    p{
        text-align: center;
    }

    table{
        width: 100%;
        td{
            padding: 10px 0px;
        }
    }
`;

const TableColumnAddEdit = (props) => {

    const { close, popupKey, option, data } = props;

    const { tableName, schema_name, db_type, db_info_id, save_data } = data;

    const [localFieldData, setLocalFieldData] = useState(undefined)



    useEffect(() => {
        setLocalFieldData({
            table_name: tableName,
            db_type: db_type,
            db_info_id: db_info_id,
            schema_name: schema_name
        })
    }, [data])



    const on_change = (key, value) => {

        const clone_prev = localFieldData ? { ...localFieldData } : {};
        clone_prev[key] = value;
        // clone_prev[key] = value;

        setLocalFieldData(clone_prev);

    }


    return (
        <MainWrapper>
            <div style={{ marginBottom: '1rem' }}>

                <TextBoxV1
                    label="Column Name"
                    onChange={(e) => {
                        on_change("column_name", e.target.value)
                    }}
                    value={localFieldData?.['column_name'] || ''}
                />
            </div>


            <div>
                <SelectOptionsV1
                    label="Data Type"
                    options={[
                        { name: 'String', value: 'string' },
                        { name: 'Number', value: 'int' },
                        { name: 'Date', value: 'date' },
                        { name: 'Date+Time', value: 'datetime' },
                        { name: 'Seconds', value: 'seconds' },
                        { name: 'YYYY-MM-DD', value: 'yyyymmdd' },
                        { name: 'Boolean', value: 'boolean' },
                    ]}
                    none_value={false}
                    value={localFieldData?.['data_type'] || ''}
                    value_key={"value"}
                    display_key={"name"}
                    on_change={(value) => {
                        on_change("data_type", value)
                    }}


                />

            </div>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: 'auto',
                marginLeft: 'auto'

            }}>
                <Button
                    primary={true}
                    onClick={() => {
                        // set_optimizer_hints(localFieldData)
                        save_data(localFieldData)
                        close(option, popupKey)

                    }}
                // mR="1rem"
                >
                    Save
                </Button>
                {/* <Button
                    primary={true}
                    onClick={() => {
                        close(option, popupKey)
                    }}
                    bgColor="#9caeb5"
                    mR="1rem"
                >
                    Cancel
                </Button> */}
            </div>

        </MainWrapper>
    )
}

export default TableColumnAddEdit;
