import React, { useState, useEffect, useRef } from 'react';
import { Button } from '../hoc/button';
import { PageInnerWrapper } from '../hoc/styled.hoc';
import { connect } from 'react-redux';
import { getAllRelationships, saveRelationshipDetails, get_table_and_columns } from '../../actions/relationship.detail'
import SelectOptionV1 from '../select-option/select.option.v1.1'
import styled from 'styled-components';
import RippleEffect from '../hoc/ripple-effect';
import PageHeader from './page.header';
import ViewSvg from '../svg/view';
import TableViewPopup from '../editor.shared/table.view';
import { getDbInfoId, isDefaultLiveDb, showPopup } from '../../utils';
import * as enums from '../../utils/enums';
import DropDownSvgDown from '../svg/dropDown';
import DropDownSvgUp from '../svg/dropDownUp';
import DbSwitcher from '../db.switcher';
import Delete from '../svg/v2-icons/deleteIcon';
import TableSkeleton from '../Skeleton/table-skeleton';


const Wrapper = styled.div`

    width: 100%;

      .dropdown_icon {
            cursor: pointer;
            line-height: 2rem;
            vertical-align: middle;
            position: absolute;
            right: 1rem;
            top: 10px;
            svg{
                display: block;
            }
         }

    .column_main_div{
        position: relative;

        &:hover{
            .view_icon{
                opacity: 1;;
            }
        }

        .view_icon{

                opacity: 0;
                position: absolute;
                top: 0.1rem;
                right: 2rem;
                width: 1.5rem;
                height: 1.5rem;
                cursor: pointer;
                
                &:hover{
                    svg{
                        fill: #2285b1;
                    };
                }
        }
    }

    .tableDiv{

        width: 100%;
        max-height: 80vh;
        overflow: auto;

        &::-webkit-scrollbar {
            background-color: #3F3F3F;
            width:4px;
        }

        &::-webkit-scrollbar-track {
            background-color: #F3F3F3;
            border: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #dbdbdb;
            border-radius: 10px;
            height: 4px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color:  #3F3F3F;
            height: 4px;
        }

    }

    .table{
        width: 100%;
        border-collapse: collapse;
    }
    td, th{
        /* border: 0.1px solid #ccc; */
        padding: 10px;
        border: 0px;
        border-right: 1px solid #ccc;
    }
    thead{
        background: linear-gradient(to right, #3E9EFF, #737DFF);
    }
    th{
        font-weight: 600;
        text-align: left;
        font-size: 1rem;
        /* background-color: #2285b1; */
        color: #fff;
        position: sticky;
        z-index: 3;
        top: 0;
    }
`;


const Index = (props) => {


    /**
     * these are the props
     */
    const {

        saveRelationshipDetails,
        relationships,
        table_column_info

    } = props;



    const IS_DEFAULT_LIVE_DB = isDefaultLiveDb()


    const [selectedTablesData, setSelectedTablesData] = useState(undefined);
    const [allTableName, setAllTableName] = useState(undefined);

    const [current_db_info_id, set_current_db_info_id] = useState(undefined);
    const [reporting_db_info_id, set_reporting_db_info_id] = useState(undefined)
    const [isLoding, setIsloding] = useState(true);

    const [sort_meta, set_sort_meta] = useState({
        sort_key: undefined,
        sort_order: undefined
    });




    // let's set db info id first
    useEffect(() => {

        const db_id = getDbInfoId();

        const db_info_id_to_use = IS_DEFAULT_LIVE_DB ? db_id : (db_id && db_id + "__nf__db__cached__");

        set_current_db_info_id(db_id)
        set_reporting_db_info_id(db_info_id_to_use);

    }, [])



    useEffect(() => {
        if (reporting_db_info_id) {
            props.get_table_and_columns(reporting_db_info_id)
            props.getAllRelationships(reporting_db_info_id)
        }

    }, [reporting_db_info_id])




    const rel_struct = {
        target_table: '',
        source_table: '',
        source_column: '',
        target_column: '',
        join_type: 'inner join',
    }


    const push_mt_object_if_needed = (data_array = []) => {


        const data_to_return = data_array ? JSON.parse(JSON.stringify(data_array)) : [];


        if (data_to_return?.length === 0) {
            data_to_return.push(JSON.parse(JSON.stringify(rel_struct)))
        }

        const last_obj = data_to_return[data_to_return.length - 1];

        if (last_obj.source_table !== '' && last_obj.target_table !== '' && last_obj.source_column !== "" && last_obj.target_column !== "") {
            data_to_return.push(JSON.parse(JSON.stringify(rel_struct)))
        }

        return data_to_return;

    }



    const onSearch = (e) => {

        const value = e.target.value;
        const clone_all_be_relationships = relationships ? JSON.parse(JSON.stringify(relationships)) : [];

        if (value && value?.trim()?.length > 0 && clone_all_be_relationships?.length > 0) {
            // let filter the data 

            const filterd_relationships = (clone_all_be_relationships || []).filter((r) => {
                if (
                    r?.target_table?.indexOf(value) > -1 ||
                    r?.source_table?.indexOf(value) > -1 ||
                    r?.source_column?.indexOf(value) > -1 ||
                    r?.target_column?.indexOf(value) > -1
                ) return true;
                else return false;
            })


            console.log("filterd_relationships", filterd_relationships)
            const final_relationship_for_state = push_mt_object_if_needed(filterd_relationships)

            setSelectedTablesData(final_relationship_for_state)

        } else {

            const final_relationship_for_state = push_mt_object_if_needed(clone_all_be_relationships)
            setSelectedTablesData(final_relationship_for_state)
            // we don't need to filter the data 
        }


    }

    useEffect(() => {
        const page_header_info = {
            title: 'define your relationship',
            extraComponent: <PageHeader onSearch={onSearch} />
        }
        props.dispatchHeaderInformation(page_header_info)
    }, [relationships])


    const wrapperRef = useRef(null)



    // useEffect(() => {
    //     props.getAllRelationships()
    //     props.get_table_and_columns()
    // }, [])



    useEffect(() => {

        setIsloding(false);

        if (relationships && relationships.length > 0) {

            const temp_relationships = relationships ? [...relationships] : [];
            temp_relationships.push(JSON.parse(JSON.stringify(rel_struct)))
            setSelectedTablesData(temp_relationships)

        } else {
            setSelectedTablesData([JSON.parse(JSON.stringify(rel_struct))]);
        }


    }, [relationships])


    useEffect(() => {
        if (table_column_info && Object.keys(table_column_info).length > 0) {
            const filter_table_column_info = (Object.keys(table_column_info).filter((k => k !== 'response_session_id'))) || []
            setAllTableName(filter_table_column_info)
        }

    }, [table_column_info])


    const onChangeTableRowColumn = (value, type, rowIndex) => {

        let tempTableData = selectedTablesData ? [...selectedTablesData] : []

        tempTableData && tempTableData.length > 0 && tempTableData.map((table, index) => {
            if (index === rowIndex) {
                table['modified'] = true;

                if (type === 'source_column') {
                    table.source_column = value
                }
                if (type === 'target_column') {
                    table.target_column = value
                }
                if (type === "source") {
                    table.source_column = ''
                    table.source_table = value;
                }
                if (type === "target") {
                    table.target_column = ''
                    table.target_table = value;
                }
                if (type === "join_type") {
                    table.join_type = value;
                }
            }
        })

        // let lastElement = tempTableData[tempTableData.length - 1];
        // if (lastElement.source_table !== '' && lastElement.target_table !== '' && lastElement.source_column !== "" && lastElement.target_column !== "") {
        //     tempTableData.push(obj)
        // }

        const final_relationship_for_state = push_mt_object_if_needed(tempTableData)
        setSelectedTablesData(final_relationship_for_state)
    }



    const onSave = () => {
        const finalData = [];
        const tempSelectedData = selectedTablesData ? [...selectedTablesData] : selectedTablesData;
        tempSelectedData && tempSelectedData.length > 0 && tempSelectedData.map((row, index) => {
            if (row.source_table !== '' && row.target_table !== '' && row.source_column !== "" && row.target_column !== '') {
                finalData.push(row)
            }
        })
        saveRelationshipDetails(finalData, reporting_db_info_id)
    }


    const delete_row = (index) => {
        const clone_selectedTablesData = selectedTablesData ? [...selectedTablesData] : [];
        if (clone_selectedTablesData[index].id) {
            clone_selectedTablesData[index].deleted = true;
        } else {
            clone_selectedTablesData.splice(index, 1)
        }
        setSelectedTablesData(clone_selectedTablesData)
    }


    const openTablePopup = (tableName, schema_name, db_type, table_schema) => {
        showPopup(undefined, undefined, enums.default.popupType.element, TableViewPopup, {
            tableName: tableName,
            connectionId: schema_name,
            db_type: db_type,
            table_schema: table_schema,
        })
    }


    const sort_relation_ship = ({ sort_key, sort_order = 'asc' }) => {

        const clone_state_selectedTablesData = selectedTablesData ? JSON.parse(JSON.stringify(selectedTablesData)) : [];

        const sort_order_to_use = sort_order === "asc" ? "desc" : 'asc';

        if (clone_state_selectedTablesData?.length > 0) {

            const sorted_data = clone_state_selectedTablesData.sort((left, right) => {
                if (sort_order_to_use === "desc") {
                    return ('' + left?.[sort_key]).localeCompare(right?.[sort_key]);
                }
                if (sort_order_to_use === "asc") {
                    return ('' + right?.[sort_key]).localeCompare(left?.[sort_key]);
                }
            })
            // const final_relationship_for_state = push_mt_object_if_needed(sorted_data)
            setSelectedTablesData(sorted_data)

            set_sort_meta({
                sort_key: sort_key,
                sort_order: sort_order_to_use
            })
        }

    }


    return (
        <Wrapper style={{ marginTop: '1rem' }}>

            {!isLoding ? <PageInnerWrapper style={{ padding: '0px' }}>
                <div style={{ margin: '10px' }}>
                    <DbSwitcher
                        db_info_id={[current_db_info_id]}
                        reporting_db_info_id={reporting_db_info_id}
                        set_reporting_db_info_id={(value) => {
                            set_reporting_db_info_id(value)
                        }}
                    />
                </div>
                <div className="tableDiv" ref={wrapperRef}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Source Table

                                    <div className="dropdown_icon"
                                        onClick={() => sort_relation_ship({
                                            sort_key: "source_table",
                                            sort_order: sort_meta?.sort_key === "source_table" && sort_meta?.sort_order ? sort_meta?.sort_order : undefined
                                        })}
                                        style={{ marginLeft: '20px', }}
                                    >
                                        <DropDownSvgUp size={".5rem"} height=".5rem" color={sort_meta?.sort_key === 'source_table' && sort_meta?.sort_order === 'desc' ? "#222" : "#fff"} />
                                        <DropDownSvgDown size={".5rem"} height=".5rem" color={sort_meta?.sort_key === 'source_table' && sort_meta?.sort_order === 'asc' ? "#222" : "#fff"} />
                                    </div>
                                </th>

                                <th>Joining Column of Source Table
                                    <div className="dropdown_icon"
                                        onClick={() => sort_relation_ship({
                                            sort_key: "source_column",
                                            sort_order: sort_meta?.sort_key === "source_column" && sort_meta?.sort_order ? sort_meta?.sort_order : undefined
                                        })}
                                        style={{ marginLeft: '20px', }}
                                    >
                                        <DropDownSvgUp size={".5rem"} height=".5rem" color={sort_meta?.sort_key === 'source_column' && sort_meta?.sort_order === 'desc' ? "#222" : "#fff"} />
                                        <DropDownSvgDown size={".5rem"} height=".5rem" color={sort_meta?.sort_key === 'source_column' && sort_meta?.sort_order === 'asc' ? "#222" : "#fff"} />
                                    </div>
                                </th>

                                <th>Join Type
                                    <div className="dropdown_icon"
                                        onClick={() => sort_relation_ship({
                                            sort_key: "join_type",
                                            sort_order: sort_meta?.sort_key === "join_type" && sort_meta?.sort_order ? sort_meta?.sort_order : undefined
                                        })}
                                        style={{ marginLeft: '20px', }}
                                    >
                                        <DropDownSvgUp size={".5rem"} height=".5rem" color={sort_meta?.sort_key === 'join_type' && sort_meta?.sort_order === 'desc' ? "#222" : "#fff"} />
                                        <DropDownSvgDown size={".5rem"} height=".5rem" color={sort_meta?.sort_key === 'join_type' && sort_meta?.sort_order === 'asc' ? "#222" : "#fff"} />

                                    </div>
                                </th>

                                <th>Target Table
                                    <div className="dropdown_icon"
                                        onClick={() => sort_relation_ship({
                                            sort_key: "target_table",
                                            sort_order: sort_meta?.sort_key === "target_table" && sort_meta?.sort_order ? sort_meta?.sort_order : undefined
                                        })}
                                        style={{ marginLeft: '20px', }}
                                    >
                                        <DropDownSvgUp size={".5rem"} height=".5rem" color={sort_meta?.sort_key === 'target_table' && sort_meta?.sort_order === 'desc' ? "#222" : "#fff"} />
                                        <DropDownSvgDown size={".5rem"} height=".5rem" color={sort_meta?.sort_key === 'target_table' && sort_meta?.sort_order === 'asc' ? "#222" : "#fff"} />

                                    </div>
                                </th>

                                <th>Joining Column of Target Table
                                    <div className="dropdown_icon"
                                        onClick={() => sort_relation_ship({
                                            sort_key: "target_column",
                                            sort_order: sort_meta?.sort_key === "target_column" && sort_meta?.sort_order ? sort_meta?.sort_order : undefined
                                        })}
                                        style={{ marginLeft: '20px', }}
                                    >
                                        <DropDownSvgUp size={".5rem"} height=".5rem" color={sort_meta?.sort_key === 'target_column' && sort_meta?.sort_order === 'desc' ? "#222" : "#fff"} />
                                        <DropDownSvgDown size={".5rem"} height=".5rem" color={sort_meta?.sort_key === 'target_column' && sort_meta?.sort_order === 'asc' ? "#222" : "#fff"} />

                                    </div>
                                </th>
                                <th style={{ width: '50px' }}>Action</th>
                            </tr>

                        </thead>
                        <tbody>
                            {selectedTablesData && selectedTablesData.length > 0 && selectedTablesData.map((table, index) => {

                                const source_column_selection = table_column_info?.[table?.source_table] || []
                                const target_column_selection = table_column_info?.[table?.target_table] || []

                                const source_table_selection = (allTableName || []).filter((c) => c !== table.target_table)
                                const target_table_selection = (allTableName || []).filter((c) => c !== table.source_table);

                                // const active_sort_column = sort_meta?.[]
                                if (!table.deleted) {
                                    return (
                                        <tr key={"row" + index}>
                                            <td>
                                                <div className='column_main_div' style={{ width: '100%' }}>
                                                    <SelectOptionV1
                                                        options={source_table_selection}
                                                        on_change={(value) => {
                                                            onChangeTableRowColumn(value, 'source', index);
                                                        }}
                                                        is_search={true}
                                                        value={table?.source_table}
                                                        hide_case={true}

                                                    />
                                                    {table?.source_table?.length > 0 && (
                                                        <div className='view_icon' onClick={() => {
                                                            openTablePopup(table.source_table, table.db_info_id, "sql",)
                                                        }}>
                                                            <ViewSvg size="1rem" />
                                                        </div>
                                                    )}
                                                </div>
                                            </td>

                                            <td>
                                                <SelectOptionV1
                                                    options={source_column_selection}
                                                    on_change={(value) => {
                                                        onChangeTableRowColumn(value, 'source_column', index)
                                                    }}
                                                    disabled={!table.source_table}
                                                    is_search={true}
                                                    value={table?.source_column}
                                                    hide_case={true}

                                                />
                                            </td>


                                            <td>
                                                <div style={{ width: '100%' }}>
                                                    <SelectOptionV1
                                                        options={['inner join', 'Left outer join', 'Right outer join', 'Full outer join']}
                                                        on_change={(value) => {
                                                            onChangeTableRowColumn(value, 'join_type', index)
                                                        }}
                                                        value={table && table.join_type}
                                                    />
                                                </div>
                                            </td>

                                            <td>
                                                <div className='column_main_div' style={{ width: '100%' }}>
                                                    <SelectOptionV1
                                                        options={target_table_selection}
                                                        on_change={(value) => {
                                                            onChangeTableRowColumn(value, 'target', index)
                                                        }}
                                                        value={table && table.target_table}
                                                        is_search={true}
                                                        hide_case={true}

                                                    />

                                                    {table?.target_table?.length > 0 && (
                                                        <div className='view_icon' onClick={() => {
                                                            openTablePopup(table.target_table, table.db_info_id, "sql")
                                                        }}>
                                                            <ViewSvg size="1rem" />
                                                        </div>
                                                    )}

                                                </div>
                                            </td>

                                            <td>
                                                <SelectOptionV1
                                                    options={target_column_selection}
                                                    on_change={(value) => {
                                                        onChangeTableRowColumn(value, 'target_column', index)
                                                    }}
                                                    value={table?.target_column}
                                                    is_search={true}
                                                    hide_case={true}
                                                    disabled={!table.target_table}


                                                />
                                            </td>

                                            <td>
                                                {index !== (selectedTablesData.length - 1) && (
                                                    <RippleEffect
                                                        icon={<Delete size='1rem' height='1rem' color={'red'} />}
                                                        Click={() => {
                                                            delete_row(index)
                                                        }}
                                                        title={'Delete'}
                                                    />
                                                )}
                                            </td>
                                        </tr>
                                    )
                                }
                            })}
                        </tbody>
                    </table>
                </div>
                <div
                    style={{
                        padding: '25px 10px',
                        borderTop: '1px solid #ccc',
                        marginTop: 'auto',
                        display: 'flex'
                    }}>
                    <Button
                        width="6rem"
                        primary={true}
                        mR="1.143rem"
                        onClick={onSave}
                    >Save</Button>
                    <Button
                        width="6rem"
                        bgColor={'#e6e6e6'}
                        onClick={() => {
                        }}
                    >
                        Cancel
                    </Button>

                </div>
            </PageInnerWrapper> : <TableSkeleton />}
        </Wrapper >
    )
}





const mapStateToProps = (state) => {

    const { tablesForRelationship, targetColumns, sourceColumns, columnsName, relationships, table_column_info } = state.relationshipDetail;
    return { tablesForRelationship, targetColumns, sourceColumns, columnsName, relationships, table_column_info }
}

export default connect(mapStateToProps, { get_table_and_columns, getAllRelationships, saveRelationshipDetails })(Index)
