import React from "react";

const MySqlServeIcon = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      shape-rendering="geometricPrecision"
      text-rendering="geometricPrecision"
      image-rendering="optimizeQuality"
      fill-rule="evenodd"
      clip-rule="evenodd"
      viewBox="0 0 2048 2044"
    >
      <path
        transform="translate(438)"
        d="m0 0h1172l38 5 28 5 32 8 36 12 26 11 25 12 18 10 21 13 20 14 17 13 14 12 20 18 16 16 7 8 13 15 10 13 13 18 10 15 9 15 10 18 12 24 12 29 12 36 9 37 5 32 3 29v1170l-3 30-6 36-6 25-8 26-10 28-12 27-11 22-13 22-16 24-12 16-8 10-12 14-9 10-14 15-8 7-14 13-11 9-14 11-18 13-19 12-21 12-25 13-26 11-25 9-23 7-24 6-33 6-36 4-19 1h-1111l-41-1-36-4-29-5-36-9-33-11-22-9-25-12-23-12-24-15-18-13-16-12-15-13-10-9-12-11-10-10-7-8-10-11-9-11-14-18-18-27-9-15-12-22-12-25-10-26-10-30-8-32-5-28-5-39v-1173l6-45 7-33 7-26 11-32 13-30 12-24 16-27 12-18 14-19 13-16 10-11 7-8 12-13 8-7 13-12 11-9 16-13 19-13 15-10 19-11 23-12 21-10 26-10 24-8 31-8 38-7z"
        fill="#447BA0"
      />
      <path
        transform="translate(438)"
        d="m0 0h1172l38 5 28 5 32 8 36 12 26 11 25 12 18 10 21 13 20 14 17 13 14 12 20 18 16 16 7 8 13 15 10 13 13 18 10 15 9 15 10 18 12 24 12 29 12 36 9 37 5 32 3 29v1170l-3 30-6 36-6 25-8 26-10 28-12 27-11 22-13 22-16 24-12 16-8 10-12 14-9 10-14 15-8 7-14 13-11 9-14 11-18 13-19 12-21 12-25 13-26 11-25 9-23 7-24 6-33 6-36 4-19 1h-1111l-41-1-36-4-29-5-36-9-33-11-22-9-25-12-23-12-24-15-18-13-16-12-15-13-10-9-12-11-10-10-7-8-10-11-9-11-14-18-18-27-9-15-12-22-12-25-10-26-10-30-8-32-5-28-5-39v-1173l6-45 7-33 7-26 11-32 13-30 12-24 16-27 12-18 14-19 13-16 10-11 7-8 12-13 8-7 13-12 11-9 16-13 19-13 15-10 19-11 23-12 21-10 26-10 24-8 31-8 38-7zm592 226-39 2-43 4-32 4-31 6-54 14-24 7-33 11-31 12-15 6-19 10-15 7-16 8-24 13-14 8-9 5-14 10-7 6-10 5-8 6-14 9-12 9-14 11-8 8-17 12-4 5v2l-4 2-9 7-10 10-7 6-7 8-7 7-6 5-7 8-9 9-6 8-9 9-6 9-13 14-21 28-9 14-7 10-5 8-11 15-6 10-5 10-8 13-12 23-5 10-5 12-10 18-8 19-15 40-13 40-12 47-6 24-5 27-6 57-2 26-1 26v43l2 36 6 62 4 24 6 25 11 44 6 21 8 24 5 17 9 21 7 19 8 16 16 33 10 19 10 18 6 10 12 19 14 21 13 18 10 14 10 13 11 12 9 11 9 10 11 14 22 22 8 7 24 24 22 18 13 10 10 8 14 11 14 10 12 8 17 11 32 19 20 12 30 15 29 13 28 11 18 8 24 8 40 12 59 13 23 4 60 6 30 2 22 1h26l40-2 57-5 29-4 28-6 44-10 47-14 17-6 24-10 29-12 26-12 31-15 25-16 14-8 23-14 17-12 13-10 17-13 15-12 11-9 15-13 13-12 7-7 6-5 7-8 8-7 7-8 9-9 9-11 10-11 11-14 12-15 13-17 9-12 14-21 10-17 12-20 10-15 14-28 9-19 12-27 6-16 11-26 5-15 14-47 11-49 5-25 5-45 4-50 1-21v-35l-2-42-6-61-4-26-11-48-5-21-11-36-7-21-11-25-8-21-13-28-11-23-8-15-12-19-12-21-9-14-9-13-6-9-7-11-9-10-10-13-9-11-9-12-11-13-22-22-7-8-27-27-13-10-9-9-9-7-10-9-8-7-40-28-3-3-10-6-10-7-13-8-14-8-8-5-12-7-60-30-32-13-24-9-41-13-66-17-32-6-47-5-38-3-19-1z"
        fill="#FABE8B"
      />
      <path
        transform="translate(1257,999)"
        d="m0 0h19l17 2 19 7 16 9 12 11 7 9 10 18 4 12 6 31 2 20v26l-2 21-6 31-4 13-9 17-9 14-9 8h-2l2 4 24 13 21 10-1 5-6 7-9 17-5 1-20-13-14-8-13-7-11-8-9 1-21 3h-12l-27-4-15-4-8-4-10-8-8-8-12-16-8-15-3-11-5-33-2-24v-13l2-23 6-37 9-19 10-15 6-8 11-11 20-12 16-6 9-2z"
        fill="#E48831"
      />
      <path
        transform="translate(497 1e3)"
        d="m0 0h48l6 2 9 24 6 23 10 27 7 27 5 12 6 15 2 6 3 8 4 18 3 4 4 19v-4l3 1 1 10h2l2-17 3 1 3-11 1-6h2l4-15 4-17h2l4-15h2l4-15 3-1 1-9 6-21 8-22 8-29 5-13 4-2h45l5 1 2 10 5 57 3 40 3 26 5 65 2 46v21l-27 1h-17l-1-1-1-25-2-70-3-51-4-43-1-14-1-4-3 9h-4l-5 26-3 10-4 11-2 6h-2l-2 12-5 22-1 2h-2l-3 15-3 1-4 20-10 31-4 8-6 20-2 12-1 2h-35l-3-4-13-40-6-20-4-13-3-7-8-29-9-27-6-22-7-20-6-17-3-4-2 34-3 68-2 25-2 76-24 1h-16l-2-1v-12l4-80 8-89 4-42 3-38 1-4z"
        fill="#FDFBFA"
      />
      <path
        transform="translate(1178,555)"
        d="m0 0 13 1 18 6 22 12 8 5 8 7 2 1 25 2 20 4 15 6 16 8 17 12 20 15 17 13 18 18 10 13 6 7 8 11 7 9 7 10 13 26 10 25 9 20 9 19 10 17 6 15 8 5 41 19 11 8 11 7 5 4 5 5 8 7 10 6 9 11 10 10-1 4-17 4-26 4-22 5h-2l1 7 5 5 13 9 19 12 13 10 10 11 9 11 7 7 5 7v3l-9-3-5-6-10-7-16-9-4-2v-2l-4-1-20-11-8-4-5-4v-2l-5-2-10-6-6-7-6-12-4-9 1-4 7-3 29-8 23-3h14l-5-4-5-2-2-4-6-4-14-9-1-3-7-2-5-5-2-4-10-3-37-14-12-5-6-7-9-16-9-20-12-26-9-21-8-19-7-11-3-4-8-17-5-6v-2h-2l-7-10-11-13-6-8-8-7-13-13-8-6-18-13-9-6-14-7-8-5-18-8-17-3-26-2-7-6-25-14-15-7-7-2h-12l-4 3-1 8 6 11 10 12 8 12 9 13 10 23 6 19 5 16 6 12 4 6v2l4 2 6 10 6 9v8l-2 9-5 13-4 26v33l3 21 5 15 6 9 6 5h3l3-16 3-18 4-15 4-7h2l2-4 7-8 4 1 8 24 8 16 11 21 11 16 8 13 9 12 9 11 8 7-1 3-9-6-12-12-8-6-6-7-9-6-7-9-6-10-4-5-8-15-4-9-1 1-5 30-7 14-6 4-3 1h-7l-6-3-7-6-6-8-10-18-5-13-4-17-2-17v-10l2-16 4-17 7-25 4-6-4-2-6-4-6-10-6-15-3-4-8-20-7-24-5-12-8-14v-2l-4-2-8-9-8-13-7-12-1-3v-15l5-10 7-7 7-3z"
        fill="#FDF9F5"
      />
      <path
        transform="translate(1250,1032)"
        d="m0 0h22l13 3 11 7 8 8 9 16 6 15 4 20 2 15v44l-3 21-5 16-7 16-7 11-9 7-13 6-11 3h-13l-14-4-16-8-6-5-8-15-7-19-3-12-2-18v-44l2-21 5-19 8-17 7-9 7-7 11-7z"
        fill="#447BA1"
      />
      <path
        transform="translate(1050,999)"
        d="m0 0h13l17 2 18 6 23 11 1 5-7 12-5 6-9-3-14-4-15-2h-17l-14 3-9 6-6 7-5 10-1 3v10l7 16 7 8 9 8 29 16 17 9 19 12 12 10 7 8 5 8 4 13 2 11v12l-4 18-4 11-7 10-12 12-12 9-8 4-18 4-14 2h-21l-24-4-12-3-16-8-12-8-1-6 6-12 3-4 9 1 26 8 17 4h14l15-4 16-8 7-9 5-9 1-5v-7l-3-10-5-8-9-10-13-8-17-10-13-8-22-12-14-10-7-7-9-16-5-16-1-7v-13l2-9 6-15 9-14 7-7 13-8 15-7 12-3z"
        fill="#E48831"
      />
      <path
        transform="translate(790,1071)"
        d="m0 0h11l16 1 4 8 7 24 5 19 10 30 5 21 4 11 3 7 2 7 3 16h3l2 15 4-16h3l2-9 2-10 2-3 7-32 9-38 6-49 1-1 26-1 16 1v7l-11 56-8 29-7 27-8 23-2 1-4 14-2 1-7 22-3 2-3 10-4 1-1 5-4 12-5 2-1 6h-5l-1 7-3 7-6 3-1 4-6 2-1 4-9 6h-4l-1 4-6 2-3 4-16 4h-24l-13-2-5-2v-16l1-5h2v-4l2-1 27-1 11-2 7-4 6-7 5-12v-25l-5-24-3-6-4-13-2-3-5-21-5-16-5-13-10-30-7-24-9-27-1-7z"
        fill="#FDFBFA"
      />
      <path
        transform="translate(1422 1e3)"
        d="m0 0h17l10 1 2 3 1 230h82l1 1v32l-2 1h-124l-1-1-1-88v-175l3-3z"
        fill="#E48831"
      />
      <path
        transform="translate(1587,1236)"
        d="m0 0h10l5 12 2 5h5l1-1 1-14 1-2h11l2 2 1 26-2 5h-6l-1-1v-18l-4 3-2 15-2 1h-6l-2-2-1-8h-3l-3 10-6 1-1-1z"
        fill="#E48831"
      />
      <path
        transform="translate(1576,1235)"
        d="m0 0 4 1-1 6-6 4v22l-1 1h-9l-1-24-5-3-1-1v-5z"
        fill="#E48831"
      />
      <path
        transform="translate(1251,636)"
        d="m0 0h8l7 4 5 6v13l-5 6-4-4-6-10-5-10-3-2v-2z"
        fill="#FDF2E9"
      />
      <path
        transform="translate(801,1306)"
        d="m0 0h7l-3 2h-2v2l-2 1-27 1-1 22-4-1v-16l1-5h2v-4l2-1z"
        fill="#FBBF8C"
      />
      <path
        transform="translate(521,1129)"
        d="m0 0h1v19l-1 19-2 25h-1v-26z"
        fill="#FBBF8C"
      />
      <path
        transform="translate(1345,910)"
        d="m0 0 6 5 4 5 6 5-1 3-9-6-7-7-2-4z"
        fill="#FBBF8C"
      />
      <path
        transform="translate(830,1251)"
        d="m0 0 4 8 1 6v15h-4z"
        fill="#FBBF8C"
      />
      <path
        transform="translate(1586,912)"
        d="m0 0 3 1-1 2-31 2h-8v-1l23-3h14z"
        fill="#FBBF8C"
      />
      <path
        transform="translate(1539,876)"
        d="m0 0 4 2 11 8 3 3v2l-4-2v-2l-7-2-5-5z"
        fill="#FBBF8C"
      />
      <path
        transform="translate(859,1215)"
        d="m0 0h4l-1 11-2 3z"
        fill="#FBC18F"
      />
      <path
        transform="translate(1580,989)"
        d="m0 0 6 2 8 5 1 2-5-2-10-5z"
        fill="#FBBF8C"
      />
    </svg>
  );
};

export default MySqlServeIcon;
