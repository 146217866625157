import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TextBox from '../hoc/textBox';
import TextArea from '../hoc/textArea';
import { Button } from '../hoc/button';
import HintsAdd from '../hints.add';
import HintSearch from '../hints.search';
import SelectOption from '../select-option';
import CheckBox from '../hoc/checkbox';

import { connect } from 'react-redux';


const PopupWrapper = styled.div`

    width: 29rem;
    min-height: 29rem;
    padding: 0 0.8rem 0.8rem 0.8rem;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    max-height: 80rem;


    .row{
        width: 100%;
        margin-bottom: 1.5rem;
    }

    h1{
        font-size: 1.5rem;
        font-weight: 500;
        text-align: center;
        margin-top: 0px;
    }
`;


const _data_types = [
    {
        name: 'String',
        id: 'string',
    },
    {
        name: 'Date',
        id: 'date',
    },
    {
        name: 'Currency',
        id: 'currency',
    },
    {
        name: 'Number',
        id: 'number',
    }
]


const AddEditPopup = (props) => {

    // poops and popup data here
    const { close, popupKey, option, data } = props;
    const { popupData, _saveFun, searchHint } = data;


    // here are state of the component  
    const [formData, setFormData] = useState(undefined)
    const [hints, setHints] = useState(undefined)

    useEffect(() => {
        setFormData(popupData)
        if (popupData && popupData.values) {
            setHints(popupData.values.split(','))
        }
    }, [popupData])


    const _onChange = (key, value) => {
        let cloneFormData = formData ? Object.assign({}, formData) : {};
        cloneFormData[key] = value;
        setFormData(cloneFormData)
    }


    const _save = () => {

        let temp_saved_data = formData ? Object.assign({}, formData) : {};
        temp_saved_data.values = hints.join(',');
        temp_saved_data.ignore_row = temp_saved_data.ignore_row === 'Yes' ? true : false;
        temp_saved_data.modified = true;
        _saveFun(temp_saved_data)
        close(option, popupKey)
    }

    const disabled = false;;

    return (
        <PopupWrapper>


            <div className="row">
                <TextBox
                    label="Table Name"
                    type="text"
                    value={formData && formData.table_name ? formData.table_name : ''}
                    onChange={(e) => _onChange('table_name', e.target.value)}
                />
            </div>
            <div className="row">
                <TextBox
                    label="Column Name"
                    type="text"
                    value={formData && formData.column_name ? formData.column_name : ''}
                    onChange={(e) => _onChange('column_name', e.target.value)}
                />
            </div>

            <div className="row">
                <TextBox
                    label="Questions"
                    type="text"
                    value={formData && formData.question ? formData.question : ''}
                    onChange={(e) => _onChange('question', e.target.value)}
                />
            </div>

            <div className="row" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ width: '70%' }}>
                    <SelectOption
                        width="100%"
                        label="Type"
                        selections={["simple", "picker"]}
                        activeValue={formData && formData.type}
                        onChangeFunc={(value) => {
                            _onChange('type', value)
                        }}

                    />
                </div>
                <div>
                    <CheckBox
                        onClick={() => {
                            if (formData && formData.ignore_row) {
                                _onChange('ignore_row', false)
                            } else {
                                _onChange('ignore_row', true)
                            }
                        }}
                        label="Ignore Row"
                        checked={formData && formData.ignore_row}
                    />
                </div>
            </div>

            <div className="row">
                <HintsAdd
                    hints={hints}
                    setHints={setHints}
                    label="Enter Values"
                    existing_label={"Existing Values"}
                />
            </div>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: 'auto',
                marginLeft: 'auto'

            }}>
                <Button
                    primary={true}
                    disabled={disabled}
                    onClick={() => _save()}
                >
                    Save
                </Button>


            </div>

        </PopupWrapper>
    )
}


const mapStateToProps = (state) => ({
    __hints__: state.reportReducer.hints,
})


export default connect(mapStateToProps, {})(AddEditPopup);
