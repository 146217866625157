import styled from 'styled-components';


export const DasboardWrapper = styled.div`
    width: 100%;
    /* background: #F3F3F3; */
    /* background: #F3F3F3; */
`;


export const DashboardCardFlex = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1.4rem;
    width: 88%;
    margin: 0 auto;
    align-items: start;
`;
export const TableHeaderWrapper = styled.div`
    width: 100%;
    margin-top: 10px;
    padding-top: 0.7rem;
    background: ${props => props.backgroundColor || 'transparent'};
    display: flex;
    justify-content: space-between;
    /* box-shadow: 0px -4px 4px 0px rgba(186,186,186,.6); */
    button {
        margin-right: 12px;
        vertical-align: center;
    }
`;


