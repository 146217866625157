import * as actions from '../action-types/domain_config'


const initialState = {
    type: actions.DOMAIN_CONFIG_INIT,
    domainConfigData: undefined,

};


const DomainConfigReducer = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }


    switch (action.type) {

        case actions.GET_DOMAIN_CONFIG_BY_PAGE_NAME:
            return {
                ...state,
                type: action.type,
                domainConfigData: action.data
            }

        default:
            return {
                ...state,
            };
    }
}

export default DomainConfigReducer;