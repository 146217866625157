import React from 'react';
import { Tooltip } from 'recharts';
import { CHART_TYPES } from '../../../shared-with-fe/constants';
import { formatValueByDataType, remove_repetitive_keys } from '../../../utils';
import * as DataTypes from '../../../shared-with-fe/data.types';

import CustomToolTip from './tooltip.custom';

class ToolTipWrapper extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        const props = this.props;

        const { tooltipTheme, active_bar, local_x_data_keys, local_y_data_keys, Alignments, chartType } = props;

        let nfx_found = false;

        local_y_data_keys && local_y_data_keys.length > 0 && local_y_data_keys.map((key) => {
            if (typeof key === 'string' && key.indexOf("_nfx_") > -1) nfx_found = true;
        })




        return <Tooltip
            {...this.props}
            cursor={false}
            offset={20}
            content={function (active) {
                {
                    let _data = {};
                    const _pre_payload = active && active.payload;
                    const filterd_payload = _pre_payload?.filter((t) => {
                        if (t && t.name.indexOf("nfx_") > -1 && t.name.indexOf("_trends") > -1) return false;
                        else return true;
                    })
                    const __payload__ = filterd_payload
                    const payload_wrapper = remove_repetitive_keys(__payload__);
                    const _payload = payload_wrapper.final_payload;
                    const final_keys = payload_wrapper.final_data_keys;

                    // const _payload = __payload__;

                    // MA CODE
                    if (chartType === CHART_TYPES.area_chart_100 || chartType === CHART_TYPES.stacked_bar_chart_100) {
                        _payload && _payload?.map((item, index) => {
                            if (_payload[index]?.["dataKey"].indexOf("_nfstackshare") > 1) {
                                item["dataKey"] = item?.dataKey.split("_nfstackshare")[0]
                                item["name"] = item?.name.split("_nfstackshare")[0]
                            }
                        })
                    }

                    const _inner_payload = _payload && _payload[0] && _payload[0].payload;
                    const payload_data = _inner_payload && _inner_payload;

                    let array_of_keys = [...local_x_data_keys, ...local_y_data_keys];

                    if (nfx_found) {
                        array_of_keys && array_of_keys.length > 0 && array_of_keys.filter(k => active_bar && k === active_bar).map((key) => {
                            if (payload_data && Object.keys(payload_data).length > 0 && Object.keys(payload_data).indexOf(key) > -1) {
                                let _alignment_type = Alignments && Alignments[key] && Alignments[key].type;
                                const key_to_assign_in_case_of_nfx = (final_keys && final_keys[key]) || key;

                                _data[key_to_assign_in_case_of_nfx] = formatValueByDataType(payload_data[key], _alignment_type, undefined, undefined, key);
                            }
                        });

                        if (typeof active_bar === 'undefined') {
                            let _alignment_type = undefined;
                            let amount = 0;

                            array_of_keys && array_of_keys.length > 0 && array_of_keys.map((key) => {
                                if (payload_data && Object.keys(payload_data).length > 0 && Object.keys(payload_data).indexOf(key) > -1) {
                                    _alignment_type = Alignments && Alignments[key] && Alignments[key].type;
                                    if (typeof payload_data[key] === 'number') {
                                        amount = amount + parseFloat(payload_data[key]);
                                    }
                                }
                            });
                            _data['total'] = formatValueByDataType(amount, _alignment_type, undefined, undefined, active.label)
                        }
                    } else {
                        __payload__ && __payload__.length > 0 && __payload__.forEach((p) => {
                            const __key__ = p.dataKey;
                            const total_label = __key__;
                            if (__key__.includes("_nfx_1")) return //MA CODE
                            let _alignment_type = Alignments && Alignments[__key__] && Alignments[__key__].type;
                            if (!_data[total_label]) _data[total_label] = 0;
                            const __value__ = _data[total_label] + parseFloat(p.value);

                            // MA CODE
                            if (chartType === CHART_TYPES.area_chart_100 || chartType === CHART_TYPES.stacked_bar_chart_100) {
                                if (_alignment_type === DataTypes.number || _alignment_type === DataTypes.currency) _alignment_type = DataTypes.percent;
                            }
                            const formatted_value = formatValueByDataType(__value__, _alignment_type, undefined, undefined,)
                            _data[total_label] = formatted_value
                        })

                    }

                    payload_data && local_x_data_keys && local_x_data_keys.forEach(x => {
                        _data[x] = payload_data[x];
                    })

                    return <CustomToolTip data={_data} theme={tooltipTheme} />
                }
            }}
        />
    }
}

export default ToolTipWrapper;