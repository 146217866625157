import React, { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import * as dataTypes from '../../shared-with-fe/data.types';
import Chevron from '../svg/chevron.down';
import CustomDatePicker from '../hoc/date-time-piker/index'
import { Button } from '../hoc/button';
import CrossSvg from '../svg/close';
import CheckMark from '../svg/checkMark';
import SelectOptionV1 from '../select-option/select.option.v1.1';
// import TextBoxV1 from '../hoc/text.box.v1';
import { constants } from '../../utils/constants';
// import { DATA_TYPES } from '../../shared-with-fe/constants';
import Search from '../svg/search';
import Delete from '../svg/delete';


const { CURRENCY_TYPES, DATE_FORMAT } = constants;
const MainWrapper = styled.div`

    width: 220px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 0px;
    color: #222;
    border-radius: 10px;

    .one_line_item_title{
        font-size: .9rem;
        font-weight: 500;
        text-transform: capitalize;
        display: inline-block;
        width: 140px;  
    }

    .bdr_plain{
        border-bottom: 1px solid #ccc;
        width: 100%;;
        margin: .4rem 0px;
        box-sizing: border-box;
    }
    .title{
        font-size: .9rem;
        font-weight: 500;
        text-transform: capitalize;
    }

    .row{
        width: 100%;
        margin-bottom: .7rem;
    }

    .tab_parent{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;

            .tab_item{
                width: 50%;
                font-size: 1rem;
                border: 1px solid #fff;
                height: 2.2rem;
                background: #f5f5f5;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
            .active{
                background: #008ac7;
                color: #fff;
            }
        }

    .and{
        color: #222;
        font-weight: bold;
        font-size: .6rem;
        background: #dfdfe396;
        width: 52px;
        margin: auto;
        margin: 6px auto;
        text-align: center;
        padding: 4px;
        border-radius: 2px;
        text-transform: uppercase;
    }
  
`;

const Tab = styled.div`
    width: 100%;
    padding: 0px;
    box-sizing: border-box;
    color: #222;
    font-weight: 400;
    border-bottom: 1px solid #ccc;
    position: relative;

    .main_row{
        width: 100%;
        background: #fff;
        height: 40px;
        font-size: .9rem;
        box-sizing: border-box;
        padding: 10px;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;

        &:hover{
            background: #f5f8ff;
        }
    }
    .main_row_radius{
        border-radius: 10px 10px 0px 0px;
    }  
    .select_arrow{
        /* transform: rotate(180deg); */
    }
    
    .add_btn_delete{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .select_arrow{
            /* background-color: red; */
            right: 1.6rem;
            width: 20px;
        }
        .delete_svg_icon{
            background-color: #fff;
            position: absolute;
            right: 0.6rem;
            width: 1rem;
            text-align: center;
            height: 27px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0px 5px 5px 0px;
            cursor: pointer;
        }
    }
    .filter_input{
        padding: 0.2rem 0.2rem 0.2rem 0.3rem;
        input{
            outline: none;
            margin: auto;
            padding: 0.4rem;
            width: 90%;
            border: none;
            background-color: #f2f2f2;
            border-radius: 6px;
            font-size: 1rem;
            /* font-weight: bold; */
        }
    }
    .condition button{
        background-color: #f0f3ff;
        border: none;
        font-size: 0.8rem;
        font-weight: bold;
        border-radius: 5px;
        padding: 3px 8px;
    }
    .row_active{
        border-left: 3px solid #008AC7;
        transition: all 0.2s;

        .title{
            font-weight: bold !important;
            color: #008AC7;
        }
    }

    .item_row{
        padding: 5px 10px;
        transition: all 0.2s;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        justify-content: space-between;
        .child_child_menu{
            position: absolute;
            width : 170px;
            left: 100%;
            margin-left: 1px;
            top: 0%;
            margin-top: -8px;
            background: #fff;
            box-sizing: border-box;
            padding: 5px;
            box-shadow: 3px 5px 7px #ccc;

            input[type="text"]{
                font-size: .9rem;
                height: 2.2rem;
                border: 1px solid #ccc;
                border-radius: 3px;
                box-sizing: border-box;
                padding: 7px;
                width: 100%;
                color: #222;
                background: #fff;
            }
        }
        
        input[type="checkbox"]{
             cursor: pointer;
             width: 12px;
             height: 12px;
             margin: 3px 0px;
        } 
        .custom_select{
            cursor: pointer;
            width: 3.4rem;
            border-radius: 21px;
            background: #ebebeb;
            border: none;
            padding: 1px;
            font-size: 0.8rem;
            &:focus-visible{
                outline: none;
            }
   
        } 
    }
    .no_space{
        justify-content: start !important;
    }
    .title{
        font-size: .9rem;
        margin-left: 7px;
        font-weight: bold;
    }
    .search{
        font-size: .8rem;
        height: 2rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 7px 7px 7px 1.5rem;
        width: 93%;
        color: #222;
        background: #fff;
        margin-left: 0.4rem;
        margin-top: 5px;
        margin-bottom: 5px;
        &:focus{
            outline: none;
        }
    }
`;

const RightSideDropdown = styled.div`
    width: 100%;
    position: absolute;
    right: ${props => props.menu_direction === "left" ? "221px" : "-221px"};
    top: 0%;
    padding: 0px;
    box-shadow: 3px 5px 7px #ccc;
    background-color: #fff;

    .column_scroll{
        width: 100%;
        padding: 10px 0px;
        max-height: 15rem;
        overflow-y: auto;
        &::-webkit-scrollbar {
            background-color:red;
            width:3px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 3px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 3px;
        }
    }   
    .row_active{
        border-left: 3px solid #008AC7;
        transition: all 0.2s;

        .title{
            font-weight: bold !important;
            color: #008AC7;
        }
    }

    .item_row{
        padding: 5px 7px;
        transition: all 0.2s;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;

        .child_child_menu{
            position: absolute;
            width : 170px;
            left: 100%;
            margin-left: 1px;
            top: 0%;
            background: #fff;
            box-sizing: border-box;
            padding: 5px;
            box-shadow: 3px 5px 7px #ccc;

            input[type="text"]{
                font-size: .9rem;
                height: 2.2rem;
                border: 1px solid #ccc;
                border-radius: 3px;
                box-sizing: border-box;
                padding: 7px;
                width: 100%;
                color: #222;
                background: #fff;
            }
        }

            input[type="checkbox"]{
                cursor: pointer;
            }
    }
    .title{
        font-size: .8rem;
        margin-left: 7px;
    }
`;

const default_config_for_number = {
    num_format_type: "crores",
    use_decimal: 2
}

const default_config_for_number_with_decimal = {
    num_format_type: "crores",
    use_decimal: 2
}

const default_for_percent = {
    use_decimal: 2,
    use_percent: true
}

const default_config_for_currency = {
    // num_format_type: "comma",
    num_format_type: 'crores',
    currency_type: CURRENCY_TYPES.RUPEE,
    use_decimal: 2
}

const ColumnMenu = (props) => {
    const { close, popupKey, option, data } = props;
    const { column_data, dataType, column_key, table_meta_information, save, popup_left, tableDataMode } = data;

    const [active_tab, set_active_tab] = useState(undefined);
    const [show_hide_columns, set_show_hide_columns] = useState();
    const [filterd_columns, set_filterd_columns] = useState(undefined);
    const [filter_data, set_filter_data] = useState(undefined);
    const [column_case, set_column_case] = useState(undefined);
    const [is_filter_clear, set_is_filter_clear] = useState(false)
    const [menu_direction, set_menu_direction] = useState("right");
    const [hide_data_label, set_hide_data_label] = useState(false);
    const [grand_total_type, set_grand_total_type] = useState("total");
    const [hide_grand_total, set_hide_grand_total] = useState(false);

    const default_config = dataType === dataTypes.currency ? default_config_for_currency : dataType === dataTypes.number_with_decimal ? default_config_for_number_with_decimal : dataType === dataTypes.percent ? default_for_percent : default_config_for_number;
    const [number_value_config, set_number_value_config] = useState(default_config);

    useEffect(() => {
        const current_column_info = column_data?.[column_key];
        // console.log("current_column_info", current_column_info)
        if (current_column_info) {
            if (current_column_info.filter) set_filter_data(current_column_info.filter)
            if (current_column_info.case) set_column_case(current_column_info.case)
            if (current_column_info.number_config) set_number_value_config(current_column_info.number_config)
            if (current_column_info.hide_data_label) set_hide_data_label(current_column_info.hide_data_label)
            if (current_column_info.grand_total_type) set_grand_total_type(current_column_info.grand_total_type)
            if (current_column_info.hide_grand_total) set_hide_grand_total(current_column_info.hide_grand_total)

        }
        set_show_hide_columns(column_data)
        set_filterd_columns(Object.keys(column_data))

    }, [column_data])

    const click_on_tab = (name) => {
        if (active_tab !== name) set_active_tab(name);
        else set_active_tab(false);
        if (dataType === dataTypes.date || dataType === dataTypes.date_time) {
            const clone_filter_data = filter_data ? JSON.parse(JSON.stringify(filter_data)) : {};
            if (clone_filter_data['filter_type'] && clone_filter_data['filter_type'] !== '') return
            else {
                clone_filter_data['filter_type'] = "single"
            }
            set_filter_data(clone_filter_data)
        }
    }

    const setEditedColumn = (key, index) => {
        const tempColumns = show_hide_columns ? JSON.parse(JSON.stringify(show_hide_columns)) : {};
        if (tempColumns[key]['show']) {
            tempColumns[key]['show'] = false;
        } else {
            tempColumns[key]['show'] = true;
        }
        set_show_hide_columns(tempColumns)
        // set_filterd_columns(Object.keys(tempColumns))
    };

    const hide_show_all = () => {
        const tempColumns = show_hide_columns ? JSON.parse(JSON.stringify(show_hide_columns)) : {};
        const is_hidden_all = Object.keys(tempColumns).every(key => {
            if (show_hide_columns[key]["show"] === false) {
                return true;
            }
        })

        tempColumns && Object.keys(tempColumns).map((key) => {
            if (!is_hidden_all) {
                tempColumns[key]['show'] = false;
            } else {
                tempColumns[key]['show'] = true;
            }
        })
        set_show_hide_columns(tempColumns)
        // set_filterd_columns(Object.keys(tempColumns))
    }

    const column_on_search = (value) => {
        const column_keys = column_data && Object.keys(column_data);
        const filterd_columns = (column_keys || [])?.filter((k) => {
            if (value === "") return true;
            if (k.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1) return true;
            else return false;
        })
        set_filterd_columns(filterd_columns)
    }

    const on_change_filter = (key, value) => {
        const clone_show_hide_columns = show_hide_columns ? JSON.parse(JSON.stringify(show_hide_columns)) : {};
        const clone_filter_data = filter_data ? JSON.parse(JSON.stringify(filter_data)) : {};
        const other_info = clone_show_hide_columns?.['other_info'];

        if (!other_info["applied_filter_keys"]) other_info["applied_filter_keys"] = [];

        if (value && typeof value !== 'undefined' && other_info["applied_filter_keys"].indexOf(column_key) == -1) {
            other_info["applied_filter_keys"].push(column_key)
        }
        if (value == '') {
            other_info["applied_filter_keys"] = (other_info["applied_filter_keys"].filter((k => k !== column_key)) || [])

        }
        clone_show_hide_columns['other_info'] = other_info;

        clone_filter_data[key] = value;

        if (key === 'filter_type') {
            clone_filter_data['formula_value'] = undefined;
        }

        console.log("clone_filter_data", clone_filter_data)
        set_filter_data(clone_filter_data)
        set_show_hide_columns(clone_show_hide_columns)
        set_filterd_columns(Object.keys(clone_show_hide_columns))
    }

    const local_save = () => {
        const clone_show_hide_columns = show_hide_columns ? JSON.parse(JSON.stringify(show_hide_columns)) : {};
        let cloned_filter_data = filter_data ? { ...filter_data } : {};

        if (cloned_filter_data?.['filter_type'] && Object.keys(cloned_filter_data).length === 1) {
            // let's mt the filter 
            cloned_filter_data = {};
        }
        // console.log("filter_data", cloned_filter_data)
        // console.log("clone_show_hide_columns", clone_show_hide_columns, column_key)
        if (clone_show_hide_columns[column_key]) {

            clone_show_hide_columns[column_key]['filter'] = cloned_filter_data;
            clone_show_hide_columns[column_key]['case'] = column_case;
            clone_show_hide_columns[column_key]['number_config'] = number_value_config;
            clone_show_hide_columns[column_key]['data_type'] = dataType;
            clone_show_hide_columns[column_key]['hide_data_label'] = hide_data_label;
            clone_show_hide_columns[column_key]['grand_total_type'] = grand_total_type;
            clone_show_hide_columns[column_key]['hide_grand_total'] = hide_grand_total
        }
        save(clone_show_hide_columns)
        close(option, popupKey)
    }

    const show_hide_columns_keys = (show_hide_columns && Object.keys(show_hide_columns)) || [];
    const show_hide_columns_to_use = show_hide_columns_keys.filter((k => k !== 'other_info')) || [];

    const current_column_info = column_data;
    const other_info = current_column_info?.['other_info'];
    const is_filter_exist = !is_filter_clear && other_info?.["applied_filter_keys"]?.indexOf(column_key) > -1

    const string_conditions = [
        { value_key: 'equal_to', display_key: 'Equals To' },
        { value_key: 'not_equal_to', display_key: "Doesn't Equal To" },
        // { is_seprator: true },
        { value_key: 'start_with', display_key: 'Starts With' },
        { value_key: 'not_start_with', display_key: "Doesn't Start With" },
        { value_key: 'end_with', display_key: 'Ends With' },
        { value_key: 'not_end_with', display_key: "Doesn't End With" },
        // { is_seprator: true },
        { value_key: 'contains', display_key: "Contains" },
        { value_key: 'not_contains', display_key: "Doesn't Contain" },
    ]


    const number_conditions = [
        { value_key: 'less_than', display_key: 'Less Than' },
        { value_key: 'less_than_equal_to', display_key: 'Less Than Equal To' },
        // { is_seprator: true },

        { value_key: 'greater_than', display_key: 'Greater Than' },
        { value_key: 'greater_than_equal_to', display_key: 'Greater Than Equal To' },
        // { is_seprator: true },

        { value_key: 'equal_to', display_key: 'Equals To' },
        { value_key: 'not_equal_to', display_key: "Doesn't Equal To" },
        // { is_seprator: true },
        { value_key: 'between', display_key: "Between" },
    ];


    const conditions_to_use = (dataType === dataTypes.number || dataType === dataTypes.currency || dataType === dataTypes.percent || dataType === dataTypes.number_with_decimal) ? number_conditions : string_conditions

    const find_and_set_direction = () => {
        const element_position = (popup_left || 0) + 400;
        const window_width = window.innerWidth;

        if (element_position > window_width) {
            set_menu_direction("left")
        }
    }

    const mbc_on_change = (type) => {
        const clone_number_value_config = number_value_config ? JSON.parse(JSON.stringify(number_value_config)) : {};
        if (clone_number_value_config["num_format_type"] === type) {
            clone_number_value_config["num_format_type"] = "nothing";
        } else {
            clone_number_value_config["num_format_type"] = type;
        }
        set_number_value_config(clone_number_value_config)
    }

    const is_hide_all = show_hide_columns && Object.keys(show_hide_columns).every((k) => show_hide_columns[k]["show"] === false)

    return (
        <MainWrapper>
            <Tab>
                <div className='main_row main_row_radius' onClick={(event) => {
                    find_and_set_direction()
                    click_on_tab("show_column")
                }}>
                    Show/Hide Columns
                    <span className="select_arrow" style={{ transform: active_tab == 'show_column' ? 'rotate(180deg)' : undefined, }} >
                        <Chevron size={'.8rem'} height={".8rem"} color="rgb(175, 196, 227)" />
                    </span>
                </div>

                {active_tab == 'show_column' && (
                    <div className="column_scroll">
                        <div className='child_child_menu'>
                            <div style={{ position: 'relative' }}>
                                <input type="text"
                                    className='search'
                                    placeholder='Search Column'
                                    onChange={(e) => {
                                        column_on_search(e.target.value)
                                    }}
                                />

                                <span style={{ position: 'absolute', left: '0.9rem', top: '0.7rem' }}>
                                    <Search size={'.8rem'} height={'.8rem'} color="#ccc" />
                                </span>
                            </div>
                        </div>

                        {
                            show_hide_columns_to_use && show_hide_columns_to_use?.length > 0 && show_hide_columns_to_use.map((key, keyIndex) => {
                                const activeCols = show_hide_columns_to_use?.filter(el => show_hide_columns?.[el]?.['show'])?.map(el => el?.toLocaleLowerCase());
                                const canNotCheck = activeCols?.length < 2 && activeCols?.includes(key?.toLocaleLowerCase());
                                if (filterd_columns?.indexOf(key) > -1) {
                                    return <div
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (canNotCheck) return;
                                            setEditedColumn(key);
                                        }}
                                        className="item_row no_space" key={'drp_tab' + key + keyIndex} title={key}>
                                        <input
                                            disabled={canNotCheck}
                                            checked={show_hide_columns[key]['show']}
                                            type={"checkbox"}
                                        />
                                        <span className="title">{key?.split("_nfx_").join(" ")}</span>
                                    </div>
                                }
                            })
                        }
                    </div>
                )}

            </Tab>

            <Tab>
                <div className='main_row' onClick={(e) => {
                    e.stopPropagation()
                    click_on_tab("filter")
                    find_and_set_direction()
                }}>
                    <span style={{ fontWeight: is_filter_exist ? 'bold' : undefined }}>Filter</span>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {is_filter_exist && (
                            <span title="Remove Filter" style={{ display: 'inline-block', marginRight: '15px' }} onClick={(e) => {
                                e.stopPropagation()
                                on_change_filter("filter_type", '')
                                set_is_filter_clear(true)
                            }}>
                                <CrossSvg size={'.7rem'} height={"1rem"} color="red" />
                            </span>
                        )}
                        <span className='condition'>
                            <button>Condition</button>
                        </span>
                    </div>
                </div>


                {active_tab && active_tab === 'filter' && (dataType !== dataTypes.date && dataType !== dataTypes.date_time) && (
                    <div className='active_filter' style={{ boxShadow: !!filter_data?.filter_type ? 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' : undefined, width: !!filter_data?.filter_type ? '96%' : '100%', padding: '3px', paddingLeft: '4px', boxSizing: 'border-box', margin: 'auto', marginBottom: '5px', borderRadius: '6px' }}>
                        <div className='add_btn_delete'>
                            <SelectOptionV1
                                value_key={'value_key'}
                                display_key={'display_key'}
                                label="None"
                                hide_margin={true}
                                hide_case={true}
                                none_value={false}
                                hide_border={!!filter_data?.filter_type}
                                label_as_placeholder={true}
                                background={'#fff'}
                                value={filter_data?.filter_type}
                                options={string_conditions}
                                on_change={(value) => {
                                    on_change_filter("filter_type", value)
                                }}
                            />
                            <div onClick={(e) => {
                                e.stopPropagation();
                                on_change_filter("filter_type", '')
                            }
                            } className='delete_svg_icon' title='Remove filter'>
                                <Delete size={'.8rem'} height={".8rem"} color={"#930404"} />
                            </div>
                        </div>
                        {filter_data?.filter_type &&
                            <div onClick={(e) => {
                                e.stopPropagation()
                            }} className='filter_input' >
                                <input
                                    type="text"
                                    autoFocus={true}
                                    onClick={(e) => {
                                        e.stopPropagation()

                                    }}
                                    placeholder={filter_data.filter_type == "between" ? 'Start Value' : 'Value'}
                                    value={filter_data?.formula_value || ''}
                                    onChange={(e) => {
                                        on_change_filter("formula_value", e.target.value)
                                    }} />
                            </div>

                        }
                    </div>
                )}

                {active_tab && active_tab === 'filter' && (dataType === dataTypes.date || dataType === dataTypes.date_time) && (
                    <RightSideDropdown style={{ width: '100%', textAlign: 'left', padding: '5px', boxSizing: 'border-box' }}>
                        {(dataType === dataTypes.date || dataType === dataTypes.date_time) && (
                            <div>
                                <div className='tab_parent'>
                                    <div className={filter_data?.filter_type === 'single' ? "active tab_item" : "tab_item"} onClick={(e) => on_change_filter("filter_type", "single")}>Single</div>
                                    <div className={filter_data?.filter_type === 'between' ? "active tab_item" : "tab_item"} onClick={(e) => on_change_filter("filter_type", "between")}>Range</div>
                                </div>

                                {filter_data?.filter_type && filter_data.filter_type === 'single' && (
                                    <div className='row'>
                                        <CustomDatePicker
                                            label="Choose Date"
                                            value={filter_data?.single_date}
                                            onChange={(value) => {
                                                on_change_filter("single_date", value)
                                            }}
                                        />
                                    </div>
                                )}

                                {filter_data?.filter_type && filter_data.filter_type === 'between' && (
                                    <React.Fragment>
                                        <div className='row'>
                                            <CustomDatePicker
                                                label={'Start Date'}
                                                value={filter_data?.start_date}
                                                onChange={(value) => {
                                                    on_change_filter("start_date", value)
                                                }}
                                            />
                                        </div>
                                        <div className='row'>
                                            <CustomDatePicker
                                                label={'End Date'}
                                                value={filter_data?.end_date}
                                                onChange={(value) => {
                                                    on_change_filter("end_date", value)
                                                }}
                                            />
                                        </div>
                                    </React.Fragment>
                                )}

                            </div>
                        )}
                    </RightSideDropdown>
                )}
            </Tab>

            {dataType === dataTypes.string && (
                <Tab>
                    <div className='main_row' onClick={() => {
                        find_and_set_direction()
                        click_on_tab("case")
                    }}>
                        Value Formatter
                        <span className="select_arrow">
                            <Chevron size={'.8rem'} height={".8rem"} color="rgb(175, 196, 227)" />
                        </span>
                    </div>

                    {active_tab == 'case' && (
                        // <RightSideDropdown className="tab_content">
                        <div className="column_scroll">
                            {
                                [
                                    { display_key: "Upper", value_key: 'upper_case' },
                                    { display_key: "Lower", value_key: 'lower_case' },
                                    { display_key: "Proper", value_key: 'proper_case' }

                                ].map((item, keyIndex) => {
                                    return <div
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            set_column_case(item["value_key"])
                                        }}
                                        className="item_row no_space" style={{ padding: '8px' }} key={keyIndex}>
                                        <CheckMark size={'.8rem'} height=".8rem" color={item["value_key"] === column_case ? "#3a9bd0" : "#fff"} />

                                        <span style={{
                                            color: item["value_key"] === column_case ? '#3a9bd0' : "#222",
                                            fontWeight: item["value_key"] === column_case ? 'bold' : 500,
                                            textTransform: 'uppercase',
                                        }} className="title">{item['display_key']}</span>
                                    </div>
                                })
                            }
                        </div>
                    )}

                </Tab>
            )}

            {(dataType === dataTypes.number || dataType === dataTypes.currency || dataType === dataTypes.percent || dataType === dataTypes.number_with_decimal) && (
                <Tab>
                    <div className='main_row' onClick={() => {
                        find_and_set_direction()
                        click_on_tab("number_value_config")
                    }}>
                        Value Formatter
                        <span className="select_arrow" style={{ transform: active_tab === 'number_value_config' ? 'rotate(180deg)' : undefined }}>
                            <Chevron size={'.8rem'} height={".8rem"} color="rgb(175, 196, 227)" />
                        </span>
                    </div>


                    {active_tab == 'number_value_config' && (
                        <div className="column_scroll">
                            <div
                                className="item_row">
                                <span className="one_line_item_title">Decimals Number</span>
                                <div style={{ width: '3.6rem' }}>
                                    <SelectOptionV1
                                        value={number_value_config["use_decimal"]}
                                        defaultValue={0}
                                        hide_margin={true}
                                        hide_case={true}
                                        none_value={false}
                                        hide_border={false}
                                        label_as_placeholder={true}
                                        background={'#fff'}
                                        options={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']}
                                        on_change={(value) => {
                                            const clone_number_value_config = number_value_config ? JSON.parse(JSON.stringify(number_value_config)) : {};
                                            clone_number_value_config["use_decimal"] = value;
                                            set_number_value_config(clone_number_value_config);
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="bdr_plain"></div>

                            <div onClick={() => mbc_on_change("comma")} className="item_row">
                                <span className="one_line_item_title">Use Comma</span>
                                <input checked={number_value_config["num_format_type"] === "comma"} type={"checkbox"}
                                />
                            </div>
                            <div onClick={() => mbc_on_change("million")} className="item_row">
                                <span className="one_line_item_title">Use Million</span>
                                <input checked={number_value_config["num_format_type"] === "million"} type={"checkbox"}
                                />
                            </div>
                            <div onClick={() => mbc_on_change("crores")} className="item_row">
                                <span className="one_line_item_title">Use Crores</span>
                                <input checked={number_value_config["num_format_type"] === "crores"} type={"checkbox"}
                                />
                            </div>
                            <div onClick={() => mbc_on_change("date_time_hour")} className="item_row">
                                <span className="one_line_item_title">To DD HH MM</span>
                                <input checked={number_value_config["num_format_type"] === "date_time_hour"} type={"checkbox"}
                                />
                            </div>

                            <div className="bdr_plain"></div>

                            <div
                                className="item_row">
                                <span className="one_line_item_title">Currency Sign</span>
                                <div style={{ width: '3.6rem' }}>
                                    <SelectOptionV1
                                        value={number_value_config["currency_type"]}
                                        defaultValue={0}
                                        hide_margin={true}
                                        hide_case={true}
                                        none_value={false}
                                        hide_border={false}
                                        label_as_placeholder={true}
                                        background={'#fff'}
                                        options={Object.values(CURRENCY_TYPES)}
                                        on_change={(value) => {
                                            const clone_number_value_config = number_value_config ? JSON.parse(JSON.stringify(number_value_config)) : {};
                                            clone_number_value_config["currency_type"] = value;
                                            set_number_value_config(clone_number_value_config);
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="bdr_plain"></div>

                            <div onClick={() => {

                                const clone_number_value_config = number_value_config ? JSON.parse(JSON.stringify(number_value_config)) : {};
                                if (clone_number_value_config["use_percent"]) clone_number_value_config["use_percent"] = false;
                                else clone_number_value_config["use_percent"] = true;
                                // clone_number_value_config["use_percent"] = !clone_number_value_config["use_percent"]
                                // console.log("clone_number_value_config", clone_number_value_config)
                                set_number_value_config(clone_number_value_config);

                            }} className="item_row">
                                <span className="one_line_item_title">Use Percent</span>
                                <input checked={number_value_config["use_percent"]} type={"checkbox"}
                                />
                            </div>
                        </div>
                    )}
                </Tab>
            )}

            {tableDataMode === 'pivot_data' && (dataType === dataTypes.number || dataType === dataTypes.currency || dataType === dataTypes.percent || dataType === dataTypes.number_with_decimal) && (
                <Tab >
                    <div className='main_row' style={{
                        justifyContent: 'flex-start'
                    }} onClick={(event) => {
                        set_hide_data_label(!hide_data_label)
                    }}>
                        <input type='radio' checked={hide_data_label} /> &nbsp;
                        Hide Data Label
                    </div>
                </Tab>

            )}

            {tableDataMode === 'pivot_data' && (dataType === dataTypes.number || dataType === dataTypes.currency || dataType === dataTypes.percent || dataType === dataTypes.number_with_decimal) && (
                <Tab >
                    <div className='main_row' style={{
                        justifyContent: 'flex-start'
                    }} onClick={(event) => {
                        set_hide_grand_total(!hide_grand_total)
                    }}>
                        <input type='radio' checked={hide_grand_total} /> &nbsp;
                        Hide Grand Total
                    </div>
                </Tab>

            )}

            {tableDataMode === 'pivot_data' && (dataType === dataTypes.number || dataType === dataTypes.currency || dataType === dataTypes.percent || dataType === dataTypes.number_with_decimal) && (
                <Tab >
                    <div className='main_row' style={{
                        justifyContent: 'flex-start'
                    }}>

                        Grand Total Type

                        &nbsp;
                        <select value={grand_total_type} onChange={(e) => {
                            set_grand_total_type(e.target.value)
                        }}>
                            <option value={'total'}>Total</option>
                            <option value={'avg'}>Avg</option>
                            <option value={'min'}>Min</option>
                            <option value={'max'}>Max</option>
                        </select>
                    </div>
                </Tab>

            )}

            {/*  */}

            <div style={{ width: '84%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: 'auto', padding: '1.5rem 10px 10px 10px' }}>
                <Button
                    style={{ fontSize: '.8rem', fontWeight: 'bold' }}
                    height={"1.7rem"}
                    mR="10px"
                    width="5rem"
                    primary={true}
                    onClick={() => {
                        local_save()
                    }}>
                    Apply
                </Button>
                <Button
                    style={{ fontSize: '.8rem', fontWeight: 'bold' }}
                    height={"1.7rem"}

                    width="5rem"
                    bgColor={'#e6e6e6'} onClick={() => {
                        close(option, popupKey)
                    }}>Cancel</Button>
            </div>
        </MainWrapper>
    )
}

export default ColumnMenu;