import styled from "styled-components";
import TowerIcon from "../../svg/icons-v2/towerIcon";
import { formatValueByDataType, apply_styles } from "../../../utils";
import { beautify_cell_data_v1 } from "../../table/shared/helper";
import { connect } from "react-redux";
import { themeObject, bind_theme_data_to_object, apply_color_sets_to_colors } from '../../../theme';
import { constants } from '../../../utils/constants';


const ChartContainer = styled.div`
   width: 100%;
   display: flex;
`;

const ChartWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: min-content; */
    margin: auto;
    font-size: 0.8rem;
`;
const OuterWrapper = styled.div`
    ${props => {
        return apply_styles(props.__style, constants.STYLED_COMPONENTS.ALL);
    }};
     /* border: 1px solid  #999;
     background: #D6E6F7;  */
    width: ${props => props.width ? props.width + 'px' : '230px'};
`;
const InnerWrapper = styled.div`

    display: flex;
    flex-direction: column;
    

    border: 1px solid #999;
    background-color: #FAFAFA;
    margin: 0.7rem;
   
    overflow: auto;
    max-height: ${props => props.height + 'px' || '234px'};
    min-height: ${props => props.height + 'px' || '234px'};
    &::-webkit-scrollbar {
        background-color: #3F3F3F;
        width:4px;
    }
    &::-webkit-scrollbar-track {
        background-color: #F3F3F3;
        border: none;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #DBDBDB;
        border-radius: 10px;
        height: 4px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color:  #3F3F3F;
        height: 4px;
    }
`;


const FloorWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 0.7rem;
    gap: 10px;
`;


const FloorUnit = styled.div`
    ${props => {
        return apply_styles(props.__style, constants.STYLED_COMPONENTS.ALL);
    }};

    width: ${props => props.width ? props.width + "px" : '80px'};
    min-width: ${props => props.width ? props.width + "px" : '80px'};
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    position: relative;
    z-index: ${props => props?.isHeader ? 4 : 0};

    span{
        z-index: 2;
        position: relative;
    }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: ${props => props?.colorPerData ? (props?.colorPerData + "%") : 0};
        height: 100%; /* 20% of the div's height */
        background-color: ${props => props?.bgColor ? props?.bgColor : undefined}
    }
    
`;

const TowerFooter = styled.div`
   ${props => {
        return apply_styles(props.__style, constants.STYLED_COMPONENTS.ALL);
    }};
    /* height: 1rem; */
    /* background: #ccc; */
    width: ${props => props.width ? props.width + 'px' : '280px'};
`;


const TowerChart = (props) => {


    const { label, xAxis = [], width, height, data, supTextKey = 'floor', iconSize, columnMeta, insights = true, theme_json_values } = props;



    let theme_data = {};

    const theme_key_to_use = insights ? 'insight' : 'report'

    const apply_theme = () => {
        const __theme__ = JSON.parse(JSON.stringify(themeObject));
        // after we have run this function, we will have the values from client theme loaded into the default theme
        if (__theme__?.[theme_key_to_use]?.["tower_chart"]) {
            theme_data = __theme__?.[theme_key_to_use]?.['tower_chart'];
            bind_theme_data_to_object(theme_data, theme_json_values);
            apply_color_sets_to_colors(theme_data, __theme__.colorset);
        } else {
            theme_data = __theme__
        }
    }
    apply_theme()

    var Xaxis = [...new Set(xAxis)] || [];


    const hideAxis = ['Status', 'Unsold Unit Perc', 'Sold Unit Perc', 'Discount Percent', 'Per', 'sum_testttt'];

    var is_hide_exist = false;


    for (var i = 0; i < Xaxis?.length; i++) {
        if (hideAxis.includes(Xaxis?.[i])) {
            is_hide_exist = true;
            break;
        }
    }

    const gutter_width = 100;
    const gutter_height = 80 + 35;


    const tower_icon_w = width * 0.25
    const tower_icon_h = height * .16

    const __width__ = (width - gutter_width);
    const __height__ = (height - (gutter_height + tower_icon_h));

    const getSupText = (fno) => {
        let text = '';
        if (fno === 1) {
            text = 'st'
        } else if (fno === 2) {
            text = 'nd';
        } else if (fno === 3) {
            text = 'rd';
        } else {
            text = 'th';
        }
        return text;
    };


    const format_value = (cellValue, data_key) => {

        const format = columnMeta?.[data_key];

        const cell_data_type = format?.['type']
        const currency_type = format?.['currency_type'];
        const num_format_type = format?.['num_format_type'];
        const use_decimal = format?.['use_decimal'];
        const use_percent = format?.['use_percent'];
        const other_format_config = format?.["other_format_config"];
        const finalDatatoShow = cellValue;

        const afterFormatted = formatValueByDataType(finalDatatoShow, cell_data_type, 'table', undefined, undefined, undefined, currency_type, num_format_type, use_decimal, use_percent, other_format_config);

        return afterFormatted;

    }

    const cell_width = ((width * 0.82 - gutter_width - 10) / (Xaxis.length - (is_hide_exist ? 1 : 0)));
    const theme_header_column_style = theme_data?.header?.column;
    const theme_body_row_style = theme_data?.body?.row;
    const theme_body_column_style = theme_data?.body?.column;
    const outer_box_style = theme_data?.outer_box;
    const outer_base_style = theme_data?.tower_footer;


    const findMetaForPercentage = () => {
        const entry = columnMeta && Object.entries(columnMeta).find(([key, value]) => value.type === 1.2);
        return entry ? entry[0] : null;
    };

    const anyKeyWithPercentageDataType = findMetaForPercentage();


    return (
        <ChartContainer style={{ marginTop: '35px' }}>
            <ChartWrapper>
                <TowerIcon height={tower_icon_h + "px"} size={tower_icon_w + "px"} />
                <OuterWrapper width={__width__} __style={outer_box_style}>
                    <InnerWrapper

                        height={__height__}>
                        <FloorWrapper
                            style={{
                                position: 'sticky',
                                top: '0px',
                                zIndex: 1,
                                paddingTop: '0.8rem',
                                background: '#fff'

                            }}
                        >
                            {Xaxis.map((i, index) => {

                                if (hideAxis.indexOf(i) > -1) return;;

                                return (
                                    <FloorUnit
                                        __style={theme_header_column_style}
                                        key={"tower_cell" + index}
                                        width={cell_width}
                                        isHeader={true}
                                        title={i}>
                                        {i.charAt(0).toUpperCase() + i.slice(1)}
                                    </FloorUnit>
                                )
                            })}
                        </FloorWrapper>

                        {data.map((rowData, rowIndex) => {

                            const condition_formating_data = beautify_cell_data_v1(props.tbl_formatter, Xaxis, rowData, rowIndex);
                            const beautify_data_beging = (condition_formating_data && Object.keys(condition_formating_data) && Object.keys(condition_formating_data).length > 0) ? Object.keys(condition_formating_data)[0] : undefined;
                            const is_row_level = beautify_data_beging && condition_formating_data && condition_formating_data[beautify_data_beging].row_level;
                            const row_level_style = (beautify_data_beging && condition_formating_data && condition_formating_data[beautify_data_beging].row_level) ? condition_formating_data[beautify_data_beging].data : undefined;

                            const colorPerData = anyKeyWithPercentageDataType && rowData?.[anyKeyWithPercentageDataType]

                            return (
                                <FloorWrapper>
                                    {
                                        Xaxis.map((key, index) => {

                                            const beautify_data = (is_row_level && (key === 'Floor' || key === "Tower" || key == 'FloorNo' || key == 'Service')) ? undefined : row_level_style

                                            const value_to_display = format_value(rowData?.[key], key);

                                            if (hideAxis.indexOf(key) > -1) return;;

                                            return (

                                                supTextKey === key ? <FloorUnit key={(key + index)} width={cell_width} title={value_to_display} ><p>{value_to_display}<sup>{getSupText(rowData.floor)}</sup> Floor</p></FloorUnit>
                                                    : <FloorUnit
                                                        __style={theme_body_column_style}
                                                        key={(key + index)}
                                                        width={cell_width}
                                                        title={value_to_display}
                                                        colorPerData={beautify_data ? colorPerData : undefined}
                                                        bgColor={beautify_data && beautify_data?.cell_color ? beautify_data.cell_color : undefined}
                                                        style={{
                                                            backgroundColor: (beautify_data && anyKeyWithPercentageDataType) ? 'transparent' : beautify_data && beautify_data.cell_color ? beautify_data.cell_color : undefined,
                                                            position: 'relative',
                                                            fontSize: beautify_data && beautify_data.font_size ? beautify_data.font_size + "px" : undefined,
                                                            color: beautify_data && beautify_data.font_color ? beautify_data.font_color : undefined,
                                                            overflow: 'hidden',
                                                        }}
                                                    ><span>{value_to_display}</span></FloorUnit>
                                            )
                                        })
                                    }
                                </FloorWrapper>
                            )
                        })}
                    </InnerWrapper>
                </OuterWrapper>
                <TowerFooter __style={outer_base_style} width={__width__}></TowerFooter>
            </ChartWrapper>
        </ChartContainer>
    )
};



const mapStateToProps = (state, props) => {

    const id = props.id;
    const table_format_setting_cache = state.tableFormatReducer.table_format_setting_cache;
    const tbl_formatter = table_format_setting_cache && table_format_setting_cache[id];

    return { tbl_formatter };
}



export default connect(mapStateToProps, {})(TowerChart)






