import React from 'react';

const HorizontalWaterFallChart = (props) => {

    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}

            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 812 840"
            space="preserve"
        >
            <path transform="translate(58)" d="m0 0 2 2v775h752v60h-812v-835l57-1z" fill="#347CFE" />
            <path transform="translate(503,213)" d="m0 0h213l6 3 4 5 1 4v53l-4 8-5 3-8 1h-206l-6-3-4-4v-63l7-6z" fill="#70F3FD" />
            <path transform="translate(517,406)" d="m0 0h219l7 3 2 6v57l-3 6-7 4h-216l-6-4-3-3v-62l3-5z" fill="#6CA2FE" />
            <path transform="translate(502,598)" d="m0 0h215l6 3 3 5 1 4v53l-4 8-6 3h-215l-5-3-3-4v-62l4-5z" fill="#6CA2FE" />
            <path transform="translate(239,508)" d="m0 0h216l5 3 3 4v61l-3 5-3 2-14 1h-201l-7-2-3-3-1-3v-61l4-5z" fill="#73F3FD" />
            <path transform="translate(129,368)" d="m0 0h206l5 3 3 3 1 7v51l-2 7-4 4-13 1h-195l-6-2-4-6v-60l4-6z" fill="#6DA3FE" />
        </svg>

    )

}


export default HorizontalWaterFallChart;