
const week_sorting = {};
const initials = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I','J','K','L','M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U','W', 'X','Y', 'Z']

let prefix_for_week = 'W';
let counter = 1;

for(let index = 1; index < 53; index ++) {

    if (counter > initials.length - 1) {
        counter = 0;
        prefix_for_week = prefix_for_week + 'Z';
    }

    week_sorting['WEEK ' + index] = prefix_for_week + initials[counter];

    counter = counter + 1;
}

export const SORT_VALUES = {
    JANUARY: 'A',
    FEBRUARY: 'B',
    MARCH: 'C',
    APRIL: 'D',
    MAY: 'E',
    JUNE: 'F',
    JULY: 'G',
    AUGUST: 'H',
    SEPTEMBER: 'I',
    OCTOBER: 'J',
    NOVEMBER: 'K',
    DECEMBER: 'L',
    JAN: 'A',
    FEB: 'B',
    MAR: 'C',
    APR: 'D',
    JUN: 'F',
    JUL: 'G',
    AUG: 'H',
    SEP: 'I',
    OCT: 'J',
    NOV: 'K',
    DEC: 'L',
    ...week_sorting
}





