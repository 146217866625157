import React, { useState, useEffect } from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Label, Cell, Legend, LabelList, Text } from 'recharts';
import { withRouter } from 'react-router-dom';
import { getRemToPx, splitByNf, formatValueByDataType, apply_styles, getChartXAxisLayout, yDataValueFormatter, find_cell_format, formatMonthYearForChart, checkYearAndMakeFy } from '../../../utils';
import { DataFormaterFixed } from '../yaxis-label';
import { yAxisLabelStyleConfig, XAxisLabelStyleConfig } from '../style.config';
import CustomizedAxisTick from '../tick';
import * as dataTypes from '../../../shared-with-fe/data.types';
import CustomToolTip from '../tooltip/tooltip.custom';
import { constants } from '../../../utils/constants';
import RenderLegend from '../legend/index.new';
import { CHART_TYPES } from '../../../shared-with-fe/constants';
import Revenue from '../../svg/scatterChart';
import { connect } from 'react-redux';

const SimpleScatterChart = (props) => {

    const {

        yDataKeys, data, widthOfChart, heightOfChart, xDataKeys,
        hide_y_axis, hide_x_axis, hide_y_axis_border, hide_x_axis_border,
        chartStyle, hide_x_axis_label, hide_y_axis_label, isDimension, isDiscovery,
        showRightYAxis, hide_legend, Alignments, hide_tooltip, tooltipTheme,
        chartType,
        show_data_label,
        colorForYData,
        hide_x_axis_secondry

    } = props;


    const [localData, setLocalData] = useState([]);

    useEffect(() => {
        if (data && xDataKeys && yDataKeys) {

            const xdataKey = local_x_data_keys[local_x_data_keys.length - 1]
            // const tickLabelAddedData = data?.map(el => ({ ...el, [xdataKey]: el?.[xDataKeys?.[0]] + (el?.[xDataKeys?.[1]] ? ("__nfx__join__" + el?.[xDataKeys?.[1]]) : '') }))
            const tickLabelAddedData = props.data?.map(el => ({
                ...el,
                [xdataKey]: (xDataKeys?.map((key, inde) => el?.[key] + (el?.[xDataKeys?.[inde + 1]] ? "__nfx__join__" : '')))?.join('')
            }))
            setLocalData(tickLabelAddedData);
        }
    }, [data, xDataKeys, yDataKeys]);

    const pivot_columns_state = props?.pivot_data_information?.pivot_columns_state;


    const chartMargin = {
        top: !hide_x_axis_label ? (isDimension ? 0 : (20)) : (heightOfChart < 110 ? 0 : 10),
        right: !hide_y_axis_label ? (isDimension ? getRemToPx(1.25) : getRemToPx(2.5)) : 10,
        bottom: isDimension ? 0 : (hide_x_axis_label ? 0 : 20),
        left: !hide_y_axis_label ? (isDimension ? 0 : getRemToPx(2.5)) : 0
    };

    const xAxisTick = !hide_x_axis ? (
        <CustomizedAxisTick totalTick={data.length} _style={XAxisLabelStyleConfig.tickLabel} />
    ) : null;

    const xAxisStyle = chartStyle.axis?.x;
    const yAxisStyle = chartStyle.axis?.y;

    const xAxisTickStyle = apply_styles(xAxisStyle?.tick, constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT);
    const yAxisTickStyle = apply_styles(yAxisStyle?.tick, constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT);
    const xAxisTitleStyle = apply_styles(xAxisStyle?.title, constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT);
    const yAxisTitleStyle = apply_styles(yAxisStyle?.title, constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT);
    const legendStyle = chartStyle?.legend;

    const hidden_Columns = [];

    const local_x_data_keys = xDataKeys ? [...xDataKeys] : [];

    const chart_xaxis_layout = getChartXAxisLayout(data, widthOfChart, heightOfChart, local_x_data_keys, props?.data?.length)

    const TICK_PADDING = !hide_x_axis_label ? 15 : 5;
    let font_size = (0.014 * widthOfChart);
    font_size = font_size > 13 ? 13 : font_size < 10 ? 10 : font_size;

    const calculateTextHeightorWidth = (fontSizes = 12, fontFamily = 'Arial', isVertical) => {
        const samepleText = local_x_data_keys.length === 1 ? "Neuro Modu.." : local_x_data_keys.length === 2 ? "RoomO.. - Mar'21" : "Room.. - Mar'21 Room.. ";  // Representative sample text 
        let fontSize = fontSizes;
        if(fontSizes?.includes('rem')){
            fontSize = getRemToPx(parseFloat(fontSize))
            // fontSizes = fontSize.slice(-)
        }else{
            fontSize = parseFloat(fontSize)
        };
        const span = document.createElement("span");
        span.style.fontSize = `${fontSize}px`;
        span.style.fontFamily = fontFamily;
        span.style.visibility = "hidden";
        span.style.position = "absolute";
        span.style.whiteSpace = "nowrap";
        span.textContent = xAxisStyle?.tick?.font?.text_transform?.toLowerCase() === "uppercase" ? samepleText.toUpperCase() : samepleText;
        // span.textContent = local_x_data_keys.length === 1 ? "Neuro Modu..." : local_x_data_keys.length === 2 ? "RoomOO.. - Mar'21" : "Room.. - Mar'21 Room.. ";  // Representative sample text
        document.body.appendChild(span);
        const width = isVertical ? span.offsetWidth : span.offsetHeight;
        document.body.removeChild(span);
        return width + TICK_PADDING * 2;
    }

    const x_axis_height_v2 = calculateTextHeightorWidth(xAxisTickStyle?.fontSize, 'Arial', chart_xaxis_layout?.angle > 0);


    const render_custom_tick = (props) => {
        const { x, y, payload, fontSize, label_data, angle, textAnchor, verticalAnchor
        } = props;

        const { value } = payload;
        const words = value?.split("__nfx__join__");;
        const isSingleValued = words?.length < 2;

        const maxLen = chart_xaxis_layout?.maxLen || 10;
        const isAngled = chart_xaxis_layout?.angle > 0 ;
        let _max_value = !isSingleValued && isAngled ? 4 : !isSingleValued && !isAngled ? maxLen-9 :  isSingleValued && isAngled ? 10 : maxLen;

        return (
            <g transform={`translate(${x},${y})`}>
                <title>{words?.join(" - ")}</title>
                <text
                    // {...(chart_xaxis_layout?.angle > 0
                    //     ? { textLength: "85", lengthAdjust: 'spacingAndGlyphs' }
                    //     : {})}
                    {...props}
                    surfaceScale={false}
                    scale={false}
                    x={0}
                    y={0}
                    visibleTicksCount={undefined}
                    // dy={verticalAlign[verticalAnchor]} 
                    textAnchor={textAnchor}
                    dominantBaseline={'hanging'}
                    // verticalAnchor={verticalAnchor}
                    style={{...xAxisTickStyle, textTransform:  xAxisStyle?.tick?.font?.text_transform}}
                    transform={`rotate(${angle})`}
                >
                    {words?.map((valueToFormat, ind) => {
                        const word = checkYearAndMakeFy(valueToFormat) || formatMonthYearForChart(valueToFormat);

                        const pattern = /\b(FY|Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)'\d{2}\b/;
                        const isAMatch = pattern.test(word);

                        const truncatedValue = ((props.angle || maxLen) > 0 && word.length > _max_value) ? `${word.substring(0, _max_value)}` : word;

                        const currwordLength = word?.length;
                        return <>
                            <tspan fill={ind % 2 === 0 ? xAxisStyle?.tick?.font?.color_primary  : xAxisStyle?.tick?.font?.color_secondary }>
                                {/* {isAMatch ? word : truncatedValue?.trim()} */}
                                {isAMatch ? word : truncatedValue?.trim() + (truncatedValue?.length < word?.length ? ".." : '')}
                            </tspan>
                            {words[ind + 1] && <tspan fill="black">{` - `}</tspan>}
                        </>
                    })}
                </text>
            </g>
        );
    };

    const RenderDataLabel = (__props__) => {

        const { entry, data_key } = __props__;

        const { x, y, width, value, index, height = 0 } = entry;

        const is_horizontal = (chartType === 'horizontal_bar_chart' || chartType === 'horizontal_waterfall_chart' || chartType === "horizontal_stack_bar_chart" || chartType === "horizontal_stack_bar_chart_100")
        const radius = is_horizontal ? 20 : (props?.yDataKeys?.length > 1 && chartType === CHART_TYPES.stacked_bar_chart) ? (height / 2) : -10;
        const column_formating_meta = props.Alignments && props.Alignments[data_key || props.yDataKeys && props.yDataKeys[0]];
        const currency_type = column_formating_meta?.['currency_type'];
        const num_format_type = column_formating_meta?.['num_format_type'];
        const use_decimal = column_formating_meta?.['use_decimal'];
        const d_type = column_formating_meta?.['type'];
        const use_percent = column_formating_meta?.['use_percent'];

        let show_value = formatValueByDataType(value, d_type, undefined, undefined, undefined, "insights", currency_type, num_format_type, use_decimal, use_percent);
        let x_to_use = is_horizontal ? (x + width + 50) : x + (width ? width / 2 : 0);


        return (
            <g>
                <text
                    x={x_to_use}
                    y={(y + radius)}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontSize={props.expanded ? "12px" : (chartStyle?.['data_label']?.['font']?.["size"] || "10px")}
                    fill={chartStyle?.['data_label']?.['font']?.["color"] || "#000"}
                >
                    {show_value}
                </text>
            </g>
        );
    }


    // const hidden_x_axis = ['']


    const local_y_data_keys = yDataKeys && yDataKeys.filter(f => f.indexOf('Total_nfx_') === -1 && f.indexOf('% Share_nfx_') === -1);
    const multiAxisCondition = local_x_data_keys?.[local_x_data_keys.length - 2];
    const secondary_available = (props.yac_axises || []).filter(y => y === 'secondary').length > 0;
    // const chart_xaxis_layout = getChartXAxisLayout(data, widthOfChart, heightOfChart, props.xDataKeys, props?.data?.length)

    const all_yacs_with_pivot_info = props.pivot_yac_info || [];
    const primaries_header = all_yacs_with_pivot_info.filter(f => f.axis === 'primary' && f.visible_type < 3).map(m => m.pivot_field_alias).join(',');
    const secondaries_header = all_yacs_with_pivot_info.filter(f => f.axis === 'secondary' && f.visible_type < 3).map(m => m.pivot_field_alias).join(',');

    const is_multi_x_axis = local_x_data_keys && local_x_data_keys.length > 1;

    // const x_axis_height_v2 = (hide_x_axis && hide_x_axis_label) ? 0 : (hide_x_axis || hide_x_axis_label) ? 20 : (props.insights ? chart_xaxis_layout?.height - 10 : chart_xaxis_layout?.height);
    // let smallRatio = widthOfChart < 1000 && 20;
    // const x_axis_height_v2 = (() => {
    //     if (hide_x_axis && hide_x_axis_label) {
    //         return 0;
    //     } else if (is_multi_x_axis && hide_x_axis_label) {
    //         return chart_xaxis_layout?.angle > 0 ?  xDataKeys?.length*56 : 25;
    //         // return 25;
    //     } else if (is_multi_x_axis && !hide_x_axis_label && !hide_x_axis) {
    //         return chart_xaxis_layout?.angle > 0 ? xDataKeys?.length*60  : 40;
    //         // return 40;
    //     } else if (hide_x_axis) {
    //         return 25
    //     }
    //     else if (hide_x_axis_label && chart_xaxis_layout?.angle > 0) {
    //         return chart_xaxis_layout?.height - 15;
    //     }
    //     else if (hide_x_axis_label) {
    //         return chart_xaxis_layout?.height - 15;
    //     }
    //     else {
    //         return chart_xaxis_layout?.height;
    //     }
    // })();

    const getUniqueTicks = (data, key) => {
        const ticks = data.map(item => item?.[key]);
        return [...new Set(ticks)];
    };


    const width_of_y_axis_v1 = (hide_y_axis && hide_y_axis_label) ? 0 : hide_y_axis_label ? 60 : !hide_y_axis ? false ? 50 : getRemToPx(4.5) : 20;

    const hidden_columns = props?.columns_info?.filter((f) => f.is_hide_from_scatter) || [];
    const uniqueTicks = localData && local_x_data_keys?.length > 0 && getUniqueTicks(localData, local_x_data_keys[0]);
    const isAllNumbers = local_x_data_keys.length === 1 && uniqueTicks?.every(item => !isNaN(item) && !isNaN(parseFloat(item)));

    const minValue = isAllNumbers ? Math.min(...uniqueTicks) : undefined;
    const maxValue = isAllNumbers ? Math.max(...uniqueTicks) : undefined;


    // console.log("secondary_available", uniqueTicks, isAllNumbers, local_x_data_keys);

    return (
        <div id="chart" style={{ position: 'relative', width: widthOfChart ? (widthOfChart - 30) : '93.1vw', textTransform: 'capitalize' }}>
            {yDataKeys.length >= 1 && localData.length > 0 && (
                <ScatterChart
                    data={localData}
                    width={widthOfChart} height={heightOfChart}
                    margin={{ top: show_data_label ? 30 : 10, left: show_data_label ? 30 : 10, right: show_data_label ? 30 : 15, bottom: 10 }}
                >
                    {!hide_legend && <Legend content={<RenderLegend payload={localData} legendStyle={legendStyle} alignments={Alignments} />} verticalAlign="top" height={30} />}
                    <>
                        <XAxis
                            {...chart_xaxis_layout}
                            first_axis={true}
                            dataKey={
                                local_x_data_keys &&
                                local_x_data_keys.length > 0 &&
                                local_x_data_keys[local_x_data_keys.length - 1]
                            }
                            height={hide_x_axis_secondry ? 1 : x_axis_height_v2}
                            tick={hide_x_axis_secondry ? false : (!hide_x_axis && render_custom_tick)}
                            axisLine={!hide_x_axis_border ? true : false}
                            style={{...yAxisTickStyle, fill:xAxisStyle?.tick?.font?.color_primary}}
                            interval={chart_xaxis_layout?.interval}
                            allowDuplicatedCategory={yDataKeys?.length > 1 ? false : undefined}
                            ticks={local_x_data_keys?.length === 1 ? uniqueTicks : undefined}
                            type={isAllNumbers ? "number" : undefined}
                            domain={(isAllNumbers && local_x_data_keys?.length === 1) ? [minValue, maxValue] : undefined}
                            padding={{ left: 15, right: 15 }}
                            dy={chart_xaxis_layout?.angle > 0 ? -6 : 0}
                            dx={6}
                        >
                            {/* {!hide_x_axis_label && local_x_data_keys?.length < 2 && (
                                <Label
                                    className="y_axis_label"
                                    angle="0"
                                    position="insideBottom"
                                    style={xAxisTitleStyle}
                                >
                                    {local_x_data_keys &&
                                        local_x_data_keys.length > 0 &&
                                        splitByNf(local_x_data_keys.join(" > "))}
                                </Label>
                            )} */}
                            {!hide_x_axis_label &&
                                <Label className="y_axis_label" angle='0' position='insideBottom' style={xAxisTitleStyle}
                                >
                                    {local_x_data_keys && local_x_data_keys.length > 0 && splitByNf(local_x_data_keys.join(' > '))}
                                </Label>
                            }
                        </XAxis>


                        {/* {local_x_data_keys.length > 1 && uniqueTicks &&
                            <XAxis
                                dataKey={local_x_data_keys && local_x_data_keys.length > 0 && (multiAxisCondition || [])}
                                style={xAxisTickStyle}
                                height={x_axis_height_v2}
                                axisLine={false}
                                tick={!hide_x_axis}
                                tickLine={false}
                                xAxisId="quarter"
                                interval='preserveStart'
                                ticks={uniqueTicks}
                            // type="number"
                            >
                                {!hide_x_axis_label &&
                                    <Label className="y_axis_label" angle='0' position='insideBottom' style={xAxisTitleStyle}
                                    >
                                        {local_x_data_keys && local_x_data_keys.length > 0 && splitByNf(local_x_data_keys.join(' > '))}
                                    </Label>
                                }
                            </XAxis>} */}
                    </>

                    <YAxis
                        dataKey={yDataKeys[0]}
                        name={yDataKeys[0]}
                        yAxisId={showRightYAxis ? 'left' : undefined}
                        orientation={showRightYAxis ? 'left' : undefined}
                        stroke="#748891"
                        interval={0}
                        type="number"
                        style={{...yAxisTickStyle, fill:yAxisStyle?.tick?.font?.color_primary}}
                        tickFormatter={(r) => {
                            var key = local_y_data_keys?.[0];
                            const column_formating_meta = Alignments?.[key];
                            const value = DataFormaterFixed(r, key, column_formating_meta)
                            return value;
                        }}
                        width={width_of_y_axis_v1}
                        axisLine={!hide_y_axis_border}
                        tick={!hide_y_axis && !isDimension}

                    // domain={[
                    //     (dataMin) => dataMin < 1 ? (dataMin - .1) : dataMin < 100 ? dataMin - 10 : (dataMin - 100),
                    //     (dataMax) => dataMax > 100 ? dataMax + 100 : dataMax
                    // ]}
                    // domain={[
                    //     (dataMin) => dataMin,
                    //     (dataMax) => dataMax
                    // ]}
                    >
                        {/* {!hide_y_axis_label &&
                            <Label
                                angle='-90'
                                position='insideLeft'
                                style={yAxisTitleStyle}
                            >
                                {(local_y_data_keys?.length > 1 && !secondary_available) ? 'Values' : splitByNf(yDataValueFormatter(local_y_data_keys?.join('-')))}

                            </Label>
                        } */}
                        {!hide_y_axis_label &&
                            <Label
                                angle='-90'
                                position='insideLeft'
                                style={yAxisTitleStyle}
                            >
                                {/* {primaries_header} */}
                                {splitByNf(yDataValueFormatter(primaries_header))}

                            </Label>
                        }
                    </YAxis>

                    {/* {((showRightYAxis || secondary_available) && chartType !== CHART_TYPES.stacked_bar_chart) && (
                        <YAxis
                            dataKey={yDataKeys[1]}
                            name={yDataKeys[1]}
                            yAxisId={'right'}
                            orientation={'right'}
                            stroke="#748891"
                            interval={0}
                            type="number"
                            style={yAxisTickStyle}
                            tickFormatter={(r) => {
                                const len = local_y_data_keys?.length - 1
                                var key = local_y_data_keys?.length > 0 && local_y_data_keys?.[len] || local_y_data_keys[0];
                                const re_key = key?.split("_nfx_")?.length > 1 ? key?.split("_nfx_")[1] : key;
                                const column_formating_meta = Alignments?.[re_key];
                                const value = DataFormaterFixed(r, key, column_formating_meta);
                                return value;
                            }}
                            width={width_of_y_axis_v1}
                            axisLine={!hide_y_axis_border}
                            tick={!hide_y_axis && !isDimension}
                        >
                            {!hide_y_axis_label && (
                                <Label angle="-90" position="insideRight" style={yAxisTitleStyle}>
                                    {splitByNf(yDataValueFormatter(secondaries_header))}
                                </Label>
                            )}
                        </YAxis>
                    )} */}

                    {!props.hide_grid && <CartesianGrid strokeDasharray="2 1" vertical={false} horizontalY={false} />}

                    {/* {yDataKeys && yDataKeys.map((yaxDataKey, ind) => <Scatter name={yaxDataKey} data={localData && localData?.map(i => ({ [yDataKeys[0]]: i?.[yaxDataKey], [xDataKeys[0]]: i?.[xDataKeys[0]] }))} fill={colorForYData?.[ind] || '#008AC7'}> */}
                    {yDataKeys && yDataKeys.map((yaxDataKey, ind) => <Scatter key={"sc" + ind} name={yaxDataKey} dataKey={yaxDataKey} data={localData && localData.map(i => ({ ...i, [yDataKeys[0]]: i?.[yaxDataKey] }))} fill={colorForYData?.[ind] || '#008AC7'}>

                        {props.show_data_label && <LabelList dataKey={yDataKeys[0]} position="top" content={(entry) => {
                            return <RenderDataLabel entry={entry} data={yDataKeys[0]} />
                        }} />}

                        {
                            yDataKeys?.map((data) => {

                                return (
                                    localData.map((entry, index) => {

                                        const all_keys_use_for_cell = [...xDataKeys];
                                        all_keys_use_for_cell.push(data)

                                        const conditional_data = find_cell_format(props.tbl_formatter, all_keys_use_for_cell, entry);
                                        const cell_color = conditional_data?.cell_color;

                                        return (

                                            <Cell key={`cell-${index}`} fill={cell_color || colorForYData?.[ind] || '#008AC7'} />
                                        )
                                    }
                                    )
                                )
                            })
                        }
                    </Scatter>)}


                    {!hide_tooltip && (
                        <Tooltip
                            content={(active) => {

                                const _data = {};
                                const _payload = active?.payload?.[0]?.payload;
                                const array_of_keys = [...xDataKeys, ...yDataKeys];

                                _payload && xDataKeys && xDataKeys.forEach(x => {
                                    // const concatinatedAxisValue = _payload[x]?.split('__join__');
                                    const concatinatedAxisValue = _payload[x]?.split('__nfx__join__');
                                    const noofValCon = concatinatedAxisValue.length;
                                    if (noofValCon > 1) {
                                        _data[x] = concatinatedAxisValue[noofValCon - 1];
                                        _payload[x] = concatinatedAxisValue[noofValCon - 1];
                                    } else {
                                        _data[x] = _payload[x];
                                    }
                                })

                                array_of_keys?.forEach(key => {

                                    if (_payload && _payload[key] !== undefined) {
                                        const columnFormattingMeta = Alignments?.[key];
                                        const currencyType = columnFormattingMeta?.currency_type;
                                        const numFormatType = columnFormattingMeta?.num_format_type;
                                        const useDecimal = columnFormattingMeta?.use_decimal;
                                        const dataType = columnFormattingMeta?.type;
                                        const use_percent = columnFormattingMeta?.use_percent

                                        _data[key] = formatValueByDataType(_payload[key], dataType, undefined, undefined, undefined, undefined, currencyType, numFormatType, useDecimal, use_percent);
                                    }
                                });

                                return <CustomToolTip data={_data} theme={tooltipTheme} />;
                            }}
                        />
                    )}
                </ScatterChart>
            )}
        </div>
    );
};





const mapStateToProps = (state, props) => {

    const report_cell_clicked_info_cache = state.reportHelperReducer.report_cell_clicked_info_cache?.[props.insight_id];
    const report_cell_click_info = report_cell_clicked_info_cache?.[props.id];
    const link_filter = state.filterReducer.link_filter_cache?.[props.insight_id]?.[props.id];
    const table_format_setting_cache = state.tableFormatReducer.table_format_setting_cache;
    const tbl_formatter = table_format_setting_cache && table_format_setting_cache[props.id];

    return { report_cell_click_info, link_filter, tbl_formatter }
};


export default withRouter(connect(mapStateToProps, {})(SimpleScatterChart));
