import React from 'react'
import { RadioCheckMark } from '../hoc/radiobox';
import styled from 'styled-components';
import { getFromSession } from '../../utils/session.helper';
import { constants } from '../../utils/constants';
import { isDefaultLiveDb } from '../../utils';


const Wrapper = styled.div`

    width: 100%;;

     .db_switch_box{

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .l_bl{
            margin-left: 10px;
            display: inline-block;
            font-size: .9rem;
            color: #374a66;
        }
    }
`;


const get_db_id = (value) => {

    if (value && value?.length > 0 && Array.isArray(value)) {
        return value[0];
    } else {
        return value;
    }
}


// db_info_id__nf__db__cached__;

const Index = (props) => {

    const {

        db_info_id = [],
        set_reporting_db_info_id,
        reporting_db_info_id,
        margin,
        disabled = false

    } = props;


    const IS_DEFAULT_LIVE_DB = isDefaultLiveDb()

    const __used__db_info__ = db_info_id?.length > 0 && get_db_id(db_info_id[0]);

    const reporting_db_info_id_v1 = get_db_id(reporting_db_info_id);

    const is_live_db_selected = ((__used__db_info__ && __used__db_info__?.indexOf("__nf__db__cached__") == -1) || (reporting_db_info_id_v1 === __used__db_info__)) && (!reporting_db_info_id_v1 || (reporting_db_info_id_v1)?.length > 0 && reporting_db_info_id_v1.indexOf("__nf__db__cached__") == -1);

    const is_cached_db_selected = (reporting_db_info_id_v1?.length > 0 && reporting_db_info_id_v1.indexOf("__nf__db__cached__") > -1);

    const clientIdFromSession = getFromSession(constants.SESSION_KEYS.CLIENT_ID);

    const is_cached_db_pirority = IS_DEFAULT_LIVE_DB //is_barc();
    const CLIENT_WISE_DISABLED = false//!is_barc();

    // console.log("clientIdFromSession", clientIdFromSession);
    

    if (clientIdFromSession !== 'hrpl_polus_etl') {
        return <></>;
    } else {
        return (
            <Wrapper>
                {db_info_id && db_info_id.length > 0 && db_info_id?.length === 1 && (
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%',
                        marginBottom: margin || '1rem'
                    }}>

                        <div title={is_cached_db_pirority ? "Switch To Live Connection" : "Switch To Cached Data"} className='db_switch_box' style={{ marginRight: '1rem' }}
                            onClick={() => {
                                if (!disabled) {
                                    set_reporting_db_info_id(db_info_id[0])
                                }
                            }}>
                            <RadioCheckMark dColor='linear-gradient(to right, #3E9EFF, #737DFF)' disabled={CLIENT_WISE_DISABLED} checked={is_live_db_selected} outerSize="1rem" />
                            <span className='l_bl'>{is_cached_db_pirority ? "Live Connection" : "Cached Data"}</span>
                        </div>


                        <div title={is_cached_db_pirority ? "Switch To Cached Data" : "Switch To Live Connection"} className='db_switch_box' onClick={() => {

                            if (!disabled) {
                                if (db_info_id?.length > 0) {
                                    const cached_db = db_info_id?.length > 0 && db_info_id[0] + "__nf__db__cached__";
                                    set_reporting_db_info_id(cached_db)

                                } else {
                                    alert("somthing went wrong...")
                                }
                            }
                            // props.get_schema_table_info(['__nf__db__cached__'], db_info_id[0])
                        }}>
                            <RadioCheckMark dColor='#337DFF' disabled={disabled} checked={is_cached_db_selected} outerSize="1rem" />
                            <span className='l_bl'>{is_cached_db_pirority ? "Cached Data" : "Live Connection"}</span>
                        </div>

                    </div>
                )}
            </Wrapper>
        )
    }
}


export default Index;