const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

export const startRecord = (func_parent) => {

	if (SpeechRecognition) {
		const recognition = new SpeechRecognition()

		recognition.continous = true
		recognition.start();

		recognition.onstart = () => {
			console.log("voice is activated");
		}

		recognition.onresult = (e) => {
			let command_voice = e.resultIndex;
			let transcript = e.results[command_voice][0].transcript;

			func_parent(transcript);
		}
	}
	else {
		alert('Your browser doesn\'t support speech')
	}

};