import React from 'react';
import { SingleDataMainCard } from '../open-route/insights/insights.styled';
import SingleData from '../open-route/insights/single.row';
import { getRemToPx } from '../../utils';
import { themeObject, bind_theme_data_to_object, apply_color_sets_to_colors } from '../../theme';


const InsightSingleData = props => {

    const {

        singleData,
        title, hideTitle,
        heightOfChart,
        comparisonKeys,
        columnsAligments,
        windowWidth,
        isMobile,
        theme_json_values,
        hide_border,
        drill_down_report,
        drill_down_type,
        view_mode,
        drill_down_values,
        od_report
    } = props;

    const data = (props.sortedData && props.sortedData.length > 0) ? props.sortedData[0] : singleData;
    const finalHeight = isMobile ? 100 : heightOfChart;

    let theme = {};
    const apply_theme = () => {
        const __theme__ = JSON.parse(JSON.stringify(themeObject));
        // after we have run this function, we will have the values from client theme loaded into the default theme
        if (__theme__.insight['single_cell']) {
            theme = __theme__.insight['single_cell'];
            bind_theme_data_to_object(theme, theme_json_values);
            apply_color_sets_to_colors(theme, __theme__.colorset);
        }
    }
    apply_theme()



    return (
        <SingleDataMainCard style={{ height: finalHeight ? finalHeight - 0 + "px" : undefined }}>
            <SingleData
                finalHeight={finalHeight}
                format_setting={props.report_item?.tbl_format_settings}
                drill_down_report={drill_down_report}
                drill_down_type={drill_down_type}
                hide_title={hideTitle}
                singleData={data}
                yDataKeys={props.yDataKeys}
                xDataKeys={props.xDataKeys}
                title={title}
                columnsAligments={columnsAligments}
                show_trend={props.show_trend}
                font_size={props.font_size}
                font_weight={props.font_weight}
                font_color={props.font_color}
                theme={theme}
                hide_border={hide_border}
                comparisonKeys={comparisonKeys}
                columnMeta={props.columnMeta}
                insight_id={props.insight_id}
                id={props.id}
                view_mode={view_mode}
                drill_down_values={drill_down_values}
                od_report={od_report}
            />
        </SingleDataMainCard>
    )
};

export default InsightSingleData;