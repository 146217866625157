import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import Dimensions from '../dimensions'
import IqSvg from '../svg/iq'
import ToolTip from '../tooltip-hover';
import * as enums from '../../utils/enums.js';
import { constants } from '../../utils/constants';
import EyeSvg from '../svg/view'
import { getPermissionById, showPopup } from '../../utils';
import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils';
import { Button } from '../hoc/button';
import { _getDataFromReportingServer, url_for_share } from '../../utils';
import RippleEffect from '../hoc/ripple-effect';
import ShareSvg from '../svg/share'
import ShareLinkPopup from '../share-link';
import { getFromSession } from '../../utils/session.helper'
import DesignModeSvg from '../svg/design.mode'
import { themeObject } from '../../theme'
import PinnedPreSvg from '../svg/pre.pinned';
import PinnedPostSvg from '../svg/post.pinned';
import { connect } from 'react-redux';
import { get_favourite_list_by_user_id } from '../../actions/favourite'
import ListSvg from '../svg/list'
import { COMPONENT_IDS } from '../../utils/elementids';

const theme = themeObject;
let selected = getSelectedTheme();


const { titleHeader } = themes[selected];


const CircleWrapper = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* background-color: ${titleHeader.color.iconWrapper}; */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    margin-left: .5rem;
    border: 1px solid #CCC;
    box-shadow: -1px 1px 8px #0000002b;
    &:hover{
        .tooltiptext{
            visibility: visible;
        }
    }
    &:hover{
        background-color: ${titleHeader.color.iconWrapperHover};
        svg{
            fill: #fff;
        }
    }
`;



const ActionButtons = (props) => {

    const {

        edit_report,
        mainReportId, title,
        isPredictive, yDataKeys, xDataKeys,
        dimensions, question, isTrendFilters,
        isPolusIqShow, sqlQuery, dataFromServer,
        history, reportType, scheduleReportId,
        set_open_pinned_modal, open_pinned_modal, remove_from_pinned, pinned_item, is_details_window
    } = props;


    const { location } = history;
    const [showPolusIq, setShowPolusIq] = useState(undefined);
    const [isUserClicked, setUserClicked] = useState(undefined);
    const [isCorrelation, setIsCorrelation] = useState(undefined);



    useEffect(() => {
        if (question) {
            const regex = /\bcorrelation\b.*/;
            const str = question.toLowerCase();
            let m = regex.exec(str)
            if (m) {
                let new_question = str.replace('correlation', 'k cluster')
                setIsCorrelation(new_question)
            } else {
                setIsCorrelation(undefined)
            }

        }
    }, [question]);




    const openShareLinkPopup = (_link) => {
        showPopup(undefined, undefined, enums.default.popupType.element, ShareLinkPopup, {
            link: _link,
            title: title ? title : undefined,
            report_id: props?.parentReport || props?.id,
            report_type: "nf_sh_report"
        })
    }



    const print_page = async (report_id) => {
        if (!report_id) {
            return alert('Sharing not available for adhoc questions');
        }
        const client_id = getFromSession(constants.SESSION_KEYS.CLIENT_ID);
        const shareable_url = url_for_share('client-view-report', client_id, report_id, props.filters);
        if (shareable_url) {
            openShareLinkPopup(shareable_url)
        }
    };


    const user_id = props?.user?.id || props?.user?.user_id;

    // 'nf_kc2yq6inkc2yqpn2'

    const show_eye_db = getPermissionById("view_sql_button", props.menuPermissions);
    const show_eye = props?.user?.email && props?.user?.email?.indexOf("newfangled.io") > -1 || show_eye_db;

    console.log("love000")


    return (
        <div>

            {(!isPredictive) && (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row' }}>

                    {scheduleReportId && !props.is_details_window && (
                        <div style={{ marginRight: '0rem' }}>
                            <RippleEffect
                                icon={<ListSvg size="1.2rem" height="1.2rem" color="#222" />}
                                // title="Report List"
                                tooltip="Report List"
                                Click={async () => {
                                    var path = `/report_list`

                                    // let __route__ = "config_editor";
                                    if (reportType === 'config_query_builder' || reportType === 'sql') {
                                        path = 'report_list'
                                    }
                                    if (reportType === 'data_source') {
                                        path = 'report_list'
                                    }
                                    if (reportType === 'cache_editor') {
                                        path = 'report_list?type=cache_editor'
                                    }
                                    props.history.push(path)
                                }}
                            />
                        </div>
                    )}

                    <div id={"header_right"} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row' }}>
                        <Dimensions
                            isShow={isPolusIqShow && showPolusIq}
                            yDataKeys={yDataKeys}
                            xDataKeys={xDataKeys}
                            dimensions={dimensions}
                            question={question}
                            isTrendFilters={isTrendFilters}
                        />

                        {isPolusIqShow && (
                            <CircleWrapper showPolusIq={showPolusIq} className="circle" onClick={() => {
                                setShowPolusIq(!showPolusIq);
                                setUserClicked(true)
                            }}>
                                <ToolTip title="PolusIq" className="tooltiptext" />
                                <IqSvg
                                    size="40px"
                                    height="40px"
                                    color={titleHeader.color.iqIcon}
                                />
                            </CircleWrapper>
                        )}


                        {set_open_pinned_modal && dataFromServer && !scheduleReportId && !props.is_details_window &&
                            <CircleWrapper
                                style={{
                                    background: pinned_item ? '#287eb7' : undefined,
                                }}
                                onClick={() => {
                                    set_open_pinned_modal(!open_pinned_modal);
                                }}
                            >
                                {pinned_item ? (
                                    <PinnedPostSvg
                                        size="22px"
                                        height="22px"
                                        style={{ transform: 'rotate(33deg' }}
                                        color={"#fff"}
                                    />
                                ) : (
                                    <PinnedPreSvg
                                        size="22px"
                                        height="22px"
                                        color={titleHeader.color.icon}
                                    />
                                )}
                                <ToolTip title={pinned_item ? "Edit Pinned" : "Add Pinned"} className="tooltiptext" />
                            </CircleWrapper>
                        }

                        {show_eye &&
                            <CircleWrapper
                                onClick={() => {
                                    showPopup('SQL Query', sqlQuery, enums.default.popupType.sqlQuery, undefined, undefined)
                                }}
                            >
                                <EyeSvg
                                    size="18px"
                                    height="18px"
                                    color={titleHeader.color.icon}
                                />
                                <ToolTip title="View SQL" className="tooltiptext" />
                            </CircleWrapper>
                        }



                        {
                            !props.is_details_window && edit_report && getPermissionById(COMPONENT_IDS.SHARED_ACTIONS.EDIT_REPORT, props.menuPermissions) &&
                            <CircleWrapper>
                                <RippleEffect
                                    icon={<DesignModeSvg size="1.3rem" height="1.3rem" color="#222" />}
                                    Click={async () => {
                                        let __route__ = "config_editor";
                                        if (reportType === 'config_query_builder' || reportType === 'sql') {
                                            __route__ = 'config_editor'
                                        }
                                        if (reportType === 'data_source') {
                                            __route__ = 'data_source_editor'
                                        }
                                        if (reportType === 'cache_editor') {
                                            __route__ = 'cache_editor'
                                        }
                                        // __route__ = __route__ + `?report_id=${mainReportId}`
                                        let path = __route__ + `?report_id=${scheduleReportId}`
                                        props.history.push(path)
                                    }}
                                />
                                <ToolTip title="Edit Report" className="tooltiptext" />
                            </CircleWrapper>
                        }


                        {
                            !props.is_details_window && !props.share_mode && dataFromServer &&
                            <CircleWrapper >
                                <RippleEffect
                                    icon={<ShareSvg size="1rem" height="1rem" color="#222" />}
                                    tooltip="Share"
                                    Click={async () => {
                                        print_page(props.dataFromServer && props.dataFromServer.config_meta ? props.dataFromServer.config_meta.report_id : undefined);
                                    }}
                                />
                            </CircleWrapper>
                        }
                    </div>
                </div>
            )}
        </div>
    )
}







const mapStateToProps = (state) => ({

    menuPermissions: state?.permissionReducer?.permissions
})

export default connect(mapStateToProps, { get_favourite_list_by_user_id })(ActionButtons);
