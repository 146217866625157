import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TitleHeader from '../title-header'
import { Wrapper, ListWrapper } from './security.styled';
import { PageInnerWrapper } from '../hoc/styled.hoc'
import Security from '../svg/security';
import { getTablesForHints, getHintsWithTableAndColumnName } from '../../actions/hints';
import { get_access_group, save_sensitive_data } from '../../actions/security';

import TextBox from '../hoc/textBox';
import Checkbox from '../hoc/checkbox';
import { getDbInfoId, isDefaultLiveDb, showPopup } from '../../utils';
import * as enums from '../../utils/enums.js';
import SelectSecurityType from './select.security.type';
import { dispatchHeaderInformation } from '../../actions/common';
import TableSkeleton from '../Skeleton/table-skeleton.js';

const Index = (props) => {

    const { hintsWithTableColumnsName, accessGroup } = props;
    const [searchValue, setSearchValue] = useState('')
    const [columnValue, setColumnValue] = useState(undefined);
    const [isLoding, setIsloding] = useState(true);

    const IS_DEFAULT_LIVE_DB = isDefaultLiveDb()


    useEffect(() => {
        let _page_info = { title: 'Mask Your Sensitive Information' };
        props.dispatchHeaderInformation(_page_info);

        props.getTablesForHints()

        const db_info_id = getDbInfoId();
        
        if (db_info_id) {

            const db_id_to_use = IS_DEFAULT_LIVE_DB ? db_info_id : (db_info_id + "__nf__db__cached__");
            props.getHintsWithTableAndColumnName(undefined, db_id_to_use)

        }
        props.get_access_group()
    }, [])


    useEffect(() => {
        const _value = Array.isArray(searchValue) ? searchValue.join(' ').trim() : searchValue;
        const valueToCheck = typeof _value === 'string' ? _value.trim().toLowerCase() : _value.trim();

        let filterd_value = hintsWithTableColumnsName && hintsWithTableColumnsName.length > 0 && hintsWithTableColumnsName.filter((item, index) => {
            let _values = (item.values && item.values.length > 0) ? item.values.join(' ').trim().toLowerCase() : item.values;
            if (searchValue == '') {
                return true;
            }
            if ((_values && _values.indexOf(valueToCheck) > -1) || (item.column_name && item.column_name.toLowerCase().indexOf(valueToCheck) > -1)) {
                return true
            }

            else {
                return false
            }
        })
        setColumnValue(filterd_value)

        if(hintsWithTableColumnsName?.length > -1){
            setIsloding(false);
        }
        
    }, [hintsWithTableColumnsName, searchValue])



    const _save_data = (data) => {
        props.save_sensitive_data(data)
        setTimeout(() => {
            const db_info_id = getDbInfoId()
            if (db_info_id) {
                props.getHintsWithTableAndColumnName(undefined, db_info_id + "__nf__db__cached__")
            }
        }, 500)
    }


    const on_select_value = (item, type, is_sensitive) => {

        if (is_sensitive) {

            let _data = [];

            if (type === "all") {
                columnValue && columnValue.length > 0 && columnValue.map((column, j) => {
                    let _obj = {
                        hint_id: column.id,
                        is_deleted: true,
                    }
                    _data.push(_obj);
                })
            }

            if (type === "single") {
                let _obj = {
                    hint_id: item.id,
                    is_deleted: true,
                }
                _data.push(_obj);
            }
            _save_data(_data)
        }

        else {
            showPopup("Assign Access of Sensitive Data", undefined, enums.default.popupType.element_with_header, SelectSecurityType, {
                accessGroup: accessGroup,
                columnValue: columnValue,
                setColumnValue: setColumnValue,
                _save_data: _save_data,
                type: type,
                id: item ? item.id : undefined
            }, undefined, undefined, undefined, {
                width: '27rem',
            });
        }
    }


    return (
        <Wrapper style={{ marginTop: '1rem' }}>
            { !isLoding ? <PageInnerWrapper>
                <div style={{ width: '50%' }}>
                    <TextBox
                        label="Search"
                        value={searchValue}
                        onChange={(e) => {
                            setSearchValue(e.target.value)
                        }}
                    />
                </div>



                <div style={{ width: '100%' }}>
                    <ListWrapper>
                        {columnValue && columnValue.length > 0 ? (
                            <table>
                                <thead>
                                    <tr style={{ background: 'rgb(173, 215, 235)' }}>
                                        <td>Table Name</td>
                                        <td>Field Name</td>
                                        <td>Hints</td>
                                        <td>
                                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                                <Checkbox
                                                    checked={columnValue && columnValue.every(item => item.is_sensitive == true)}
                                                    onClick={() => {
                                                        let is_sensitive = columnValue && columnValue.every(item => item.is_sensitive == true)
                                                        on_select_value(null, "all", is_sensitive)
                                                    }} />
                                                Mark Sensitive

                                            </div>
                                        </td>
                                    </tr>
                                </thead>

                                <tbody>
                                    {columnValue.map((item, index) => {
                                        let _bg_color = index % 2 == 0 ? "#fff" : 'rgb(238, 247, 251)';
                                        return (
                                            <tr
                                            // style={{ background: _bg_color }}
                                            >
                                                <td title={item.table_name}><span>{item.table_name}</span></td>
                                                <td title={item.column_name}>
                                                    <span>{item.column_name}</span></td>
                                                <td title={item.values}><span style={{
                                                    display: 'inline-block',
                                                    maxWidth: '20rem',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap'
                                                }}>
                                                    {
                                                        item.values && item.values.length > 0 ? item.values.toString() : item.values
                                                    }
                                                </span></td>
                                                <td >
                                                    <Checkbox
                                                        checked={item.is_sensitive}
                                                        onClick={() => {
                                                            on_select_value(item, "single", item.is_sensitive ? true : false)
                                                        }} />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        ) : <p style={{ textAlign: 'center', marginTop: '5rem' }}>No results Found</p>}

                    </ListWrapper>
                </div>
            </PageInnerWrapper> : <TableSkeleton />}
        </Wrapper>
    )
}




const mapStateToProps = state => {
    const { hints, tableNameOfHints, hintsWithTableColumnsName } = state.hintsReducer;
    const { accessGroup } = state.securityReducer;
    return { hints, tableNameOfHints, hintsWithTableColumnsName, accessGroup }
}

export default connect(mapStateToProps, { dispatchHeaderInformation, getTablesForHints, getHintsWithTableAndColumnName, get_access_group, save_sensitive_data })(Index)
