import React, { useState, useEffect } from 'react'
import AutoSearch from '../../auto.search';
import TablePopup from '../table.popup'
import ViewSvg from '../../svg/view';
import * as enums from '../../../utils/enums';
import { showPopup } from '../../../utils';
import ExclamationSvg from '../../svg/exclamation';
import TextBox from '../../hoc/textBox';
import { Button } from '../../hoc/button';
import SelectOption from '../../select-option';
import RippleEffect from '../../hoc/ripple-effect'
import CorrectSvg from '../../svg/correct';
import AutoSearchNew from '../../auto.search/auto.search.new'
import { getTargetColumnNames, pushSourceMappingTableRowToStore, pushSourceMappingDataToStore } from '../../../actions/etl';
import { connect } from 'react-redux';


const MappingTableRow = (props) => {

    const {

        tableHead, rowData, row_index, targetColumnNames,
        targetTableNames, sourceColumns, errorPopupValue,
        ignoreError, target_schema, showTablePopupClicked,
        pushSourceMappingTableRowToStore, mappingTableData, is_edit_mode

    } = props;

    const [localData, setLocalData] = useState(undefined);

    useEffect(() => {
        setLocalData(rowData)
    }, [rowData])

    const get_plain_row_object = (__table__head__) => {
        const table_head = __table__head__ ? __table__head__ : tableHead;

        const object_to_return = {
            id: undefined,
            source_column: undefined,
            target_column: undefined,
            target_table: undefined,
            direct_mapping: undefined,
            criteria: undefined,
        };

        const existing_keys = Object.keys(object_to_return);

        table_head && table_head.forEach(head => {
            if (existing_keys.indexOf(head) === -1) {
                object_to_return[head] = [];
            }
        });

        return object_to_return;
    };

    const autoSearchOnChange = (key, value, type) => {
        let clone_table_data = mappingTableData ? JSON.parse(JSON.stringify(mappingTableData)) : []
        let temp_local_data = localData ? JSON.parse(JSON.stringify(localData)) : {};
        temp_local_data[key] = value;

        if (key === 'target_table' && type == 'clicked') {
            let post_data_for_target_column = {
                database_id: target_schema,
                table_name: value
            }
            props.getTargetColumnNames(post_data_for_target_column)
        }

        if (key === 'target_table' && type === 'typing') {
            temp_local_data.target_column = ' ';


        }

        if (type === 'typing') {
            if(key === 'criteria'){
                temp_local_data[key] = value;
            }else {
                temp_local_data[key] = {
                    is_new: true,
                    value: value
                };
            }
        }


        if (type === 'clicked') {
            temp_local_data[key] = value;

        }

        if (row_index === (clone_table_data.length - 1)) {
            clone_table_data.push(get_plain_row_object())
            clone_table_data[row_index] = temp_local_data
            props.pushSourceMappingDataToStore(clone_table_data)
        };

        // if (!clone_table_data[row_index].source_column && !clone_table_data[row_index].target_table && !clone_table_data[row_index].target_column) {
        //     clone_table_data.pop();
        // }

        setLocalData(temp_local_data)
    }



    return (
        <React.Fragment>
            {localData && (
                <tr key={"tbl" + row_index} tabIndex={row_index + 1}
                    onBlur={() => {
                        pushSourceMappingTableRowToStore(row_index, localData)

                    }}
                >
                    {tableHead && tableHead.length > 0 && tableHead.map((cell, j) => {
                        const active_value = (typeof localData[cell] === 'object') ? (localData[cell] && localData[cell].value) : localData[cell];
                        let selectionsOfTargetColumnNames = targetColumnNames && targetColumnNames[localData['target_table']];

                        let selection = (cell == 'target_table') ?
                            targetTableNames : (cell == 'target_column') ?
                                selectionsOfTargetColumnNames : (cell == 'source_column') ?
                                    sourceColumns ?
                                        sourceColumns : undefined : undefined;

                        let is_new_value = undefined;
                        const which_element_should_render = (cell === 'direct_mapping') ? "selectOption" : (cell === 'criteria') ? "input" : 'auto_search_option';
                        let color_of_exc_btn = (errorPopupValue && Object.keys(errorPopupValue).length > 0 && Object.keys(errorPopupValue).indexOf(row_index.toString()) > -1) ? 'green' : (ignoreError && ignoreError.indexOf(row_index) > -1) ? '#f1db42' : "#E2574C"
                        if (!selection && active_value && typeof active_value === 'string' && active_value.trim().length > 0) is_new_value = true;
                        if (selection && active_value && typeof active_value === 'string' && selection.indexOf(active_value.trim()) === -1 && active_value && active_value.trim().length > 0) is_new_value = true;


                        return (
                            <td
                                onBlur={() => {
                                    pushSourceMappingTableRowToStore(row_index, localData)

                                }}
                                key={"cell" + j} style={{ position: 'relative' }}>
                                {which_element_should_render === 'auto_search_option' &&
                                    <AutoSearch
                                        selections={cell == 'target_column' ? (localData.target_table && targetTableNames && targetTableNames.indexOf(localData.target_table) > -1 ? selection : []) : selection}
                                        onItemClick={(val) => {
                                            autoSearchOnChange(cell, val, 'clicked');
                                        }}
                                        setActiveValue={(val) => {
                                            autoSearchOnChange(cell, val, 'typing');
                                        }}
                                        activeValue={active_value ? active_value : ' '}
                                        width="100%"
                                        borderNone={true}
                                        color='#7c7c7c'
                                        borderColor={"transparent"}
                                        dropdownShadow={"2px 3px 6px 2px #ccc"}
                                        onBlur={() => {
                                            pushSourceMappingTableRowToStore(row_index, localData)
                                        }}
                                    />
                                }

                                {which_element_should_render === 'selectOption' &&
                                    <SelectOption
                                        selections={[
                                            { name: "Yes", value: true },
                                            { name: "No", value: false }
                                        ]}
                                        onChangeFunc={(value) => {
                                            autoSearchOnChange(cell, value, 'clicked');
                                        }}
                                        displayKey={"name"}
                                        valueKey={'value'}
                                        activeValue={active_value}
                                        width="100%"
                                        height="15.857rem"
                                        borderNone={true}
                                        removeInputHeight={true}

                                    />
                                }

                                {which_element_should_render === 'input' &&
                                    <TextBox
                                        borderColor='#eaeaea'
                                        value={(active_value && active_value) ? active_value : ''}
                                        borderNone={true}
                                        onChange={(e) => {
                                            let _value = e.target.value
                                            autoSearchOnChange(cell, _value, 'typing');
                                        }}
                                        onBlur={() => {
                                            pushSourceMappingTableRowToStore(row_index, localData)

                                        }}
                                    />

                                }


                                {/* {cell == 'source_column' && is_error && (
                                <React.Fragment>
                                    {is_error && is_error.error_type && is_error.error_type == "length_error" && (isErrorOpen == row_index) && (
                                        <div className="small_data_popup">
                                            <h2>Difference in column length found</h2>
                                            <p >
                                                {localData.source_column} Length = {is_error.current_length} <br></br>
                                                {localData.target_column} Length = {is_error.length_required} <br></br>
                                            </p>
    
                                            <div style={{ textAlign: 'left' }}>
                                                <TextBox
                                                    label="Please Provide The Range"
                                                    value={(errorValue && errorValue[row_index]) ? errorValue[row_index] : undefined}
                                                    onChange={(e) => {
                                                        let temp_error_value = errorValue ? JSON.parse(JSON.stringify(errorValue)) : {};
                                                        let _value = e.target.value;
                                                        temp_error_value[row_index] = _value;
                                                        setErrorValues(temp_error_value)
                                                    }}
                                                />
                                            </div>
                                            <p style={{ marginTop: '.8rem' }}>Example- <span className="tag">0-10</span> or <span className="tag">0-20</span></p>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '1rem' }}>
                                                <Button
                                                    mR="1rem"
                                                    primary={true}
                                                    onClick={() => {
                                                        let _value = errorValue && errorValue[row_index];
                                                        clickOnApplyButton(row_index, _value)
                                                        setIsErrorOpen(undefined);
                                                    }}>Apply</Button>
                                                <Button bgColor="#9caeb5" onClick={() => {
                                                    clickOnIgnoreButton(row_index)
                                                    setIsErrorOpen(undefined);
    
                                                }}>Ignore Error</Button>
                                            </div>
                                        </div>
                                    )}
    
                                    <div
                                        style={{
                                            position: 'absolute',
                                            marginTop: '0rem',
                                            top: '31%',
                                            left: '-14%',
                                        }} className={"view_error_btn"}>
                                        <RippleEffect
                                            zIndex={1}
                                            width={'1rem'}
                                            height={'1rem'}
                                            title="View Error"
                                            icon={
                                                color_of_exc_btn === 'green' ? <CorrectSvg size={"1rem"} height={'1rem'} color={color_of_exc_btn} /> :
                                                    <ExclamationSvg size={"1rem"} height={'1rem'} color={color_of_exc_btn} />}
                                            Click={(e) => {
                                                setIsErrorOpen(row_index);
                                            }}
                                        />
                                    </div>
                                </React.Fragment>
                            )} */}


                                {is_new_value && (cell !== 'direct_mapping') && cell !== 'criteria' && (
                                    <div className={"new_value"} title={`New ${cell && cell.split('_').join(' ')}`}>{`New`}</div>
                                )}

                                {active_value && typeof active_value === 'string' && active_value.trim().length > 0 && cell === 'target_table' && selectionsOfTargetColumnNames && !is_new_value && (
                                    <div style={{ width: '2.1rem', position: 'absolute', right: '2.8rem', top: '.9rem', background: '#fff', zIndex: '1' }}>
                                        <RippleEffect
                                            zIndex={1}
                                            width={'1rem'}
                                            height={'1rem'}
                                            title="View Table Data"
                                            icon={<ViewSvg size='1rem' height='1rem' />}
                                            Click={() => {
                                                showTablePopupClicked(active_value)
                                            }}
                                        />
                                    </div>
                                )}
                            </td>
                        )
                    })}
                </tr>
            )}
        </React.Fragment>
    )
}



const mapStateToProps = (state) => ({
    targetColumnNames: state.EtlReducer.targetColumnNames,
    targetTableNames: state.EtlReducer.targetTableNames,
    mappingTableData: state.EtlReducer.mappingTableData,

})
export default connect(mapStateToProps, { getTargetColumnNames, pushSourceMappingTableRowToStore, pushSourceMappingDataToStore })(MappingTableRow)
