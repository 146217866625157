
export const POLUS_INSTRUCTION_MODULE_TYPE = {
    DOWNLOAD_FILES: 'DOWNLOAD_FILES',
    PIVOT: 'pivot',
    COLOR_FORMATING_FORMULA: "conditional_formatting",
    DATA_SORTING: 'sorting',
    CHART_TYPE: 'CHART_TYPE',
    DATA_FILTER: 'DATA_FILTER',
    OPEN_DASHBOARD: 'OPEN_DASHBOARD',
    OPEN_REPORT: 'OPEN_REPORT',
    OPEN_DASHBOARD_LIST: "OPEN_DASHBOARD_LIST",
    FORMULA: 'formula',
    FILTER: 'filter'
}



export const POLUS_INSTRUCTION_SUB_TYPE = {
    TRANSPONSE_COLUMN: 'TRANSPONSE_COLUMN',
    CSV_DOWNLOAD: 'CSV_DOWNLOAD',
    PDF_DOWNLOAD: 'PDF_DOWNLOAD',
    EXCEL_DOWNLOAD: 'EXCEL_DOWNLOAD',
    Y_AXIS_CHANGE: 'Y_AXIS_CHANGE',
    X_AXIS_CHANGE: 'X_AXIS_CHANGE',
    
}

