import { CHART_TYPES, PIVOT_COLUMN_TYPE } from '../../shared-with-fe/constants'


/**
 * 
 * @param {*} __state__ 
 * @param {*} column_info_index 
 * @param {*} pivot_column_type 
 * @returns 
 */


export const get_highest_field_order_and_index_v2 = (display_columns) => {

    var order = display_columns?.length || 1;
    var index = display_columns?.length || 1;
    var pivot_field_order = display_columns?.length || 1;

    display_columns?.forEach((column) => {
        if (order < column.order) {
            order = column.order
        }
        if (column.pivot_type === "pivot_x" && pivot_field_order < column.pivot_field_order) {
            pivot_field_order = column.pivot_field_order;
        }
    })

    return {
        order: order + 1,
        index: index,
        pivot_field_order: (pivot_field_order) + 1

    }


}

export const get_existence_state_and_highest_field_order_v1 = (__state__, column_info_index, pivot_column_type, table_name, column_name) => {

    const clone_local_columns_info = __state__ ? JSON.parse(JSON.stringify(__state__)) : [];
    const column_info = clone_local_columns_info[column_info_index];
    let highest_field_order = -1;

    const how_may_element_already_exist = ((__state__ || []).filter((c) => !c?.deleted) || []).filter(c => (c.table_name === table_name && c.orignal_name === column_name));

    const already_exists = clone_local_columns_info && clone_local_columns_info.filter(c => {
        if (c.fe_pivot_type === pivot_column_type) {
            if (c.pivot_field_order > highest_field_order && c.fe_pivot_type === pivot_column_type) highest_field_order = c?.pivot_field_order
            return !c.deleted && (column_info?.pivot_field_column_name === c?.pivot_field_column_name);
        }
    }).length > 0;

    return {
        highest_field_order,
        already_exists,
        count: how_may_element_already_exist?.length
    }
};




/**
 * 
 * @param {*} __state__ 
 * @param {*} column_info_index 
 * @returns 
 */
export const find_next_available_duplicate_column_v1 = (__state__, column_info_index) => {

    const items = __state__ ? JSON.parse(JSON.stringify(__state__)) : [];
    const column_info = items[column_info_index];

    let available_name = undefined;
    const all_names = {};

    items.forEach(i => all_names[i.pivot_field_alias || i.pivot_field_column_name] = 1);
    Array(10).fill(1).map((_, index) => {
        if (!available_name) {
            if (!all_names[column_info.pivot_field_column_name + ' ' + parseInt(index + 1)]) {
                available_name = column_info.pivot_field_column_name + ' ' + parseInt(index + 1);
            }
        }
    });
    return available_name;
};




/**
 * 
 * @param {*} __state__ 
 * @param {*} pivot_column_type 
 * @param {*} event 
 * @returns 
 */
export const column_drop_v1 = (__state__, column_meta_data, pivot_column_type, event) => {


    const get_data_type1 = (d_type) => {
        if (!d_type) return "number";
        if (d_type === "percent") return "percent";
        if (d_type === "currency") return "currency";
        else return "number";
    }

    const column_info_index = event.dataTransfer.getData("column_info_index");
    const section_name = event.dataTransfer.getData("section_name");
    const clone_local_columns_info = __state__ ? JSON.parse(JSON.stringify(__state__)) : [];

    if (pivot_column_type === section_name) return clone_local_columns_info

    const chart_types = Array(10).fill(CHART_TYPES.bar_chart)
    const axises = Array(10).fill('primary')

    chart_types[1] = CHART_TYPES.line_chart;
    chart_types[2] = CHART_TYPES.area_chart;

    axises[1] = 'secondary';
    axises[2] = 'secondary';

    const column_info = clone_local_columns_info.find(c => (c.index == column_info_index));
    const index_of_column_info = clone_local_columns_info.findIndex(c => (c.index == column_info_index));

    // here we are changing the column info
    if (index_of_column_info > -1) {

        const existence_details = get_existence_state_and_highest_field_order_v1(__state__, index_of_column_info, pivot_column_type);
        const is_already = clone_local_columns_info.filter((v) => v.fe_pivot_type === pivot_column_type);
        const filterd_y_axis = clone_local_columns_info.filter((f) => f.fe_pivot_type === PIVOT_COLUMN_TYPE.PIVOT_Y)

        if (pivot_column_type === PIVOT_COLUMN_TYPE.PIVOT_Y && existence_details.already_exists && is_already.length > 0) {

            const ob = {
                pivot_field_alias: existence_details.already_exists ? find_next_available_duplicate_column_v1(__state__, index_of_column_info) : column_info.pivot_field_column_name,
                is_repeater: existence_details.already_exists,
                pivot_field_column_name: column_info.pivot_field_column_name,
                visible_type: 1,
                index: clone_local_columns_info.length,
                fe_pivot_type: pivot_column_type,
                chart_type: chart_types[filterd_y_axis.length],
                axis: axises[filterd_y_axis.length],
                pivot_aggregation: 'sum',
                pivot_field_order: existence_details.highest_field_order + 1
            }

            if(existence_details?.already_exists){
                ob.alias = column_info.pivot_field_column_name
            }

            clone_local_columns_info.push(ob)

        }

        else {

            const pivot_column_name_to_use = column_meta_data?.[column_info.alias] ? column_meta_data?.[column_info.alias].display_value : column_info.alias;
            column_info.fe_pivot_type = pivot_column_type
            column_info.pivot_field_column_name = pivot_column_name_to_use;
            column_info.pivot_field_alias = pivot_column_name_to_use;
            column_info.modified = true;
            column_info.pivot_field_order = existence_details.highest_field_order + 1;
            column_info.visible_type = 1;

            if (pivot_column_type === PIVOT_COLUMN_TYPE.PIVOT_Y) {
                column_info.fe_pivot_type = PIVOT_COLUMN_TYPE.PIVOT_Y
                column_info.chart_type = chart_types[filterd_y_axis.length]
                column_info.axis = axises[filterd_y_axis.length]
                column_info.data_type = get_data_type1(column_info.data_type)
                column_info.pivot_aggregation = 'sum'

            }
            clone_local_columns_info[index_of_column_info] = column_info;
        }
        return clone_local_columns_info;
    }
}



/**
 * 
 * @param {*} __state__ 
 * @param {*} event 
 */
export const change_column_order_v1 = (__state__, event, order_key = "pivot_field_order") => {

    const clone_local_columns_info = __state__ ? JSON.parse(JSON.stringify(__state__)) : [];
    const prev_local_columns_info = __state__ ? JSON.parse(JSON.stringify(__state__)) : [];



    // console.log("column_state_in_sorted_", column_state_in_sorted_)
    // draged element
    const drag_element_index = event.dataTransfer.getData("column_info_index");

    const drag_el_index = prev_local_columns_info.findIndex(c => (c.index == drag_element_index));
    const drag_el = prev_local_columns_info[drag_el_index]


    // this is for where element will be dropped
    const where_to_dropped_index = event.currentTarget.getAttribute("column_index")
    const where_to_drop_el_index = prev_local_columns_info.findIndex(c => (c.index == where_to_dropped_index));
    const where_to_drop_el = prev_local_columns_info[where_to_drop_el_index];

    // a_el is drag_element_index
    // b_el is_ where element will be droped

    const a_el = drag_el;
    const b_el = where_to_drop_el;

    // jo elements drag ho raha hai
    const a_order = drag_el[order_key] || 0;

    // jaha par emlement drop hoga
    const b_order = b_el[order_key] || 0;

    // 0                 1             2      3           4     
    // [admission_id, payment_mode, discount, net_amount, month]
    // console.log("hello", drag_el, b_el)

    // sinairo one
    if (a_order > b_order) {
        clone_local_columns_info.forEach((column) => {
            const c_order = column[order_key];
            if (c_order === a_order && (drag_el_index === column.index || drag_element_index === column.index)) {
                column[order_key] = b_order
                column.modified = true
            }
            if (c_order >= b_order && a_order > c_order) {
                column[order_key] = column[order_key] + 1;
                column.modified = true
            }
        })
    }

    // sinairo two
    else if (b_order > a_order) {
        clone_local_columns_info.forEach((column) => {
            const c_order = column[order_key];
            if (c_order === a_order && (drag_el_index === column.index || drag_element_index === column.index)) {
                column[order_key] = b_order
                column.modified = true
            }
            if (c_order <= b_order && a_order < c_order) {
                column[order_key] = column[order_key] - 1;
                column.modified = true
            }
        })
    }


    // sinairo two if both column has same order 
    else if (b_order === a_order) {
        clone_local_columns_info.forEach((column) => {
            const c_order = column[order_key];
            if (column.index === drag_el_index && c_order === a_order) {
                // keep it same 
                column[order_key] = a_order;
                column.modified = true

            }
            if (column.index !== drag_el_index && c_order >= a_order) {
                // let's add one
                column[order_key] = a_order + 1
                column.modified = true


            }
        })
    }

    //  [0, 1, 2, 3, 3, 4]
    // [a, b, c, d, e, f]
    // 3, 4, 5
    // [a, b, c, e, d, f]



    return clone_local_columns_info || [];

}





/**
 * 
 * @param {*} elements 
 * @returns 
 */
export const get_elements_by_sorted = (elements) => {
    const clone_elements = elements ? [...elements] : [];
    return clone_elements.sort((a, b) => a.pivot_field_order - b.pivot_field_order) || [];
}

/**
 * 
 * @param {*} elements 
 * @param {*} pivot_type 
 * @returns 
 */
export const get_elements_by_pivot_types = (elements, pivot_type, pivot_key = "fe_pivot_type") => {
    const clone_elements = elements ? [...elements] : [];
    return clone_elements.filter((c) => (c[pivot_key] === pivot_type)) || [];

}

/**
 * 
 * @param {*} elements 
 * @param {*} pivot_type 
 * @returns 
 */
export const get_elements_for_render = (elements, pivot_type, pivot_key) => {
    const clone_elements = elements ? [...elements] : [];
    const filterd_elements = get_elements_by_pivot_types(clone_elements, pivot_type, pivot_key)
    const sorted = get_elements_by_sorted(filterd_elements)
    return (sorted || [])
}




