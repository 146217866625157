import React from 'react';

const DesignMode = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            space="preserve">
            <g>
                <path d="M99.279,18.508L83.756,2.982c-0.923-0.924-2.558-0.924-3.48,0l-9.656,9.659c-0.756-2.6-3.13-4.516-5.968-4.516H6.243   C2.8,8.126,0,10.928,0,14.37v46.522c0,3.443,2.8,6.243,6.243,6.243h10.016l-3.005,3.006c-0.01,0.009-0.019,0.021-0.029,0.029   l-0.055,0.055c-0.078,0.077-0.138,0.164-0.202,0.248c-0.079,0.102-0.147,0.207-0.209,0.32c-0.054,0.1-0.107,0.199-0.146,0.304   c-0.012,0.031-0.033,0.058-0.043,0.09L4.807,94.471c-0.294,0.885-0.064,1.86,0.595,2.519c0.469,0.47,1.098,0.722,1.741,0.722   c0.26,0,0.522-0.042,0.777-0.126l23.287-7.762c0.021-0.007,0.039-0.021,0.061-0.029c0.167-0.061,0.329-0.137,0.48-0.232   c0.01-0.006,0.02-0.009,0.029-0.015c0.142-0.093,0.274-0.198,0.395-0.318L54.26,67.136h10.392c3.442,0,6.241-2.8,6.241-6.243   V50.498l15.009-15.011c0.147-0.148,0.267-0.317,0.37-0.494l13.008-13.006C100.24,21.026,100.24,19.468,99.279,18.508z    M65.971,60.893c0,0.729-0.592,1.321-1.319,1.321h-5.47l6.789-6.792V60.893z M68.494,21.848l4.443,4.442L22.833,76.408   l-4.442-4.441L68.494,21.848z M4.921,60.893V14.37c0-0.729,0.592-1.321,1.321-1.321h58.409c0.728,0,1.319,0.592,1.319,1.321v2.948   L21.174,62.214H6.243C5.514,62.214,4.921,61.621,4.921,60.893z M11.033,91.359l4.946-14.839l9.893,9.893L11.033,91.359z    M66.693,47.738c0,0-0.002,0-0.002,0.002l-2.67,2.671l-33.59,33.597l-4.119-4.12L76.418,29.77l4.121,4.12L66.693,47.738z    M84.1,30.205L72.055,18.166l9.961-9.962l12.043,12.043L84.1,30.205z" />
                <path d="M22.885,27.229c0-0.652-0.259-1.277-0.721-1.74l-5.917-5.916c-0.961-0.959-2.519-0.96-3.48,0.002   c-0.961,0.96-0.961,2.519,0,3.48l4.176,4.174l-4.176,4.176c-0.962,0.962-0.962,2.52,0,3.48c0.48,0.48,1.11,0.722,1.74,0.722   c0.629,0,1.259-0.241,1.74-0.721l5.917-5.916C22.625,28.508,22.885,27.883,22.885,27.229z" />
                <path d="M26.482,35.606h7.048c1.359,0,2.461-1.102,2.461-2.461c0-1.358-1.102-2.462-2.461-2.462h-7.048   c-1.359,0-2.46,1.104-2.46,2.462C24.021,34.505,25.123,35.606,26.482,35.606z" />
            </g>
        </svg>

    )

}


export default DesignMode;