import React from "react";

const GoogleSheetIcon = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 2048 2048"
      preserveAspectRatio="xMidYMid meet"
    >
     <path transform="translate(400)" d="m0 0h859l7 8 502 502 1 2v1385l-1 25-3 19-7 21-8 16-7 11-8 10-9 10-9 8-14 10-15 8-19 7-19 5-4 1h-1243l-28-7-15-6-15-8-11-8-13-11-12-13-10-15-9-17-7-21-3-15-1-11v-1784l2-18 5-19 8-19 9-15 11-14 15-15 14-10 14-8 14-6 16-5z" fill="#00A942"/>
<path transform="translate(568,803)" d="m0 0h85l835 1 1 1v649h-930l-1-42v-605l2-3z" fill="#FEFEFE"/>
<path transform="translate(1258,2)" d="m0 0 4 2 505 505v2l-4 1h-455l-50-1-1-2v-506z" fill="#007D24"/>
<path transform="translate(1126,1186)" d="m0 0h218l29 1v150l-1 2h-273l-16-1-1-1v-150z" fill="#00A942"/>
<path transform="translate(1082,919)" d="m0 0h290l1 1v148l-1 3h-287l-3-1z" fill="#00A942"/>
<path transform="translate(715,1186)" d="m0 0 251 1v138l-1 14h-273l-17-1v-151z" fill="#00A942"/>
<path transform="translate(675,919)" d="m0 0h290l1 1v132l-1 19h-289l-1-1z" fill="#00A942"/>
    </svg>

  );
};

export default GoogleSheetIcon;
