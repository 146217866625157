import React from 'react';

const ChevronDown = (props) => {
  return (
    <svg
      width={props.size ? props.size : '2.2rem'}
      height={props.height ? props.height : '2.2rem'}
      {...props}
      fill={props.color ? props.color : 'rgb(175, 196, 227)'}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 21 12"
      space="preserve">
      <path stroke="null" id="svg_1" d="m10.60776,7.90802l7.91827,-7.60053c0.24361,-0.24361 0.48509,-0.24361 0.7287,0l1.4108,1.36632c0.24361,0.24361 0.24361,0.48509 0,0.7287l-9.69342,9.28459c-0.09109,0.09109 -0.21183,0.13557 -0.36435,0.13557c-0.15252,0 -0.27326,-0.04448 -0.36435,-0.13557l-9.69342,-9.28459c-0.24361,-0.24361 -0.24361,-0.48509 0,-0.7287l1.4108,-1.36632c0.24361,-0.24361 0.48509,-0.24361 0.7287,0l7.91827,7.60053z"/>
    </svg>

  )

}


export default ChevronDown;