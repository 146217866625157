import React, { useState, useEffect } from 'react'
import FileExplorer from '../file explorer';
import { MainWrapper, LeftTabWrapper, Tab, Title, RightSection } from './folder.explorer.styled';
import { getAllFiles, getFilesByPlatform, getFilesByDimension, deleteFile, getViewFileTableData, getMetaInfoOfFile, downloadTheFile, processTheFile, clearProcessFileData } from '../../actions/file.uploader';
import { connect } from 'react-redux';
import { showPopup, } from '../../utils';
import ConfirmBox from '../confirm-box'
import * as enums from '../../utils/enums';
import ViewPopup from './view.popup';
import SharedTable from '../shared-table';
import FilePropertyPopup from './file.property.popup';
import AddColumnsPopup from '../tbl_edits/add.columns.popup';
import { ProgressOverlay } from '../file-upload/styled.file.upload'
import { saveInSession, getFromSession } from '../../utils/session.helper';
import { constants } from '../../utils/constants';
import FolderSvg from '../svg/folder';
import OpenFolder from '../svg/open.folder';

const Index = (props) => {

    const { getAllFiles, getFilesByPlatform, getFilesByDimension, allFiles, platformFiles, dimensionsFiles, deleteFile, getViewFileTableData, getMetaInfoOfFile, downloadTheFile, processTheFile, processFileData, clientsInfo } = props;

    const [activeTab, setActiveTab] = useState(undefined);
    const [activeTabKey, setActiveTabKey] = useState("all files");
    const [tabList, setTabList] = useState(undefined);
    const [localFiles, setLocalFiles] = useState(undefined);
    const [permissionPopup, setPermissionPopup] = useState(undefined);
    const [menuOpen, setMenuOpen] = useState(undefined);
    const [mainMenuOpen, setMainMenuOpen] = useState(false);


    const [selected_files, set_selected_files] = useState(undefined);


    useEffect(() => {
        if (!allFiles || allFiles.length == 0) {
            getAllFiles()
        }
        if (!platformFiles || platformFiles.length == 0) {
            getFilesByPlatform()
        }
        if (!dimensionsFiles || dimensionsFiles.length == 0) {
            getFilesByDimension()
        }
    }, [])


    const clientIdFromSession = getFromSession(constants.SESSION_KEYS.CLIENT_ID);

    const clientId = process.env.REACT_APP_DEV_LOGIN_KEY ? (clientIdFromSession ? clientIdFromSession : constants.CLIENT_ID) : constants.CLIENT_ID;

    const business_domain_name = () => {
        for (let index = 0; index < clientsInfo && clientsInfo.length; index++) {
            const element = clientsInfo[index];
            if (element.id === clientId) {
                return element.business_domain
            }

        }
    }

    const toggleMenu = id => {
        const tempOpenMenu = {}

        if (typeof tempOpenMenu[id] === 'undefined') {
            tempOpenMenu[id] = {};
        }
        else if (tempOpenMenu[id]) {
            delete tempOpenMenu[id];
        }

        setMenuOpen(tempOpenMenu);
    };

    let bussiness_name = business_domain_name()


    useEffect(() => {
        if (processFileData) {
            setPermissionPopup(processFileData);
        }

        if (permissionPopup && !processFileData) {
            setPermissionPopup(undefined);
        }
    }, [processFileData]);

    const _extract_file_name = (path) => {

        if (path) {
            return path.split("-")[0]?.split(".")[0]
        }
        return null;
    }


    const getFilterFiles = (array, keyword, value) => {
        let filterd_result = array && array.length > 0 && array.filter((item) => {
            if (item[keyword] && item[keyword].toLowerCase() === value && value.toLowerCase()) {
                return true;
            } else return false;
        })
        return filterd_result
    }

    useEffect(() => {

        let keys_of_dimensions = dimensionsFiles && Object.keys(dimensionsFiles);
        let keys_of_platforms = platformFiles && Object.keys(platformFiles);

        let temp_tabs = {
            "all files": {},

        };

        if (allFiles) {
            temp_tabs['all files']['data'] = allFiles;
        }

        let data_by_dimensions = {};

        keys_of_platforms && keys_of_platforms.length > 0 && keys_of_platforms.forEach(key => data_by_dimensions[key] = [])


        // dimensionsFiles && dimensionsFiles.length > 0 && dimensionsFiles.forEach((dim) => {
        //     keys_of_platforms && keys_of_platforms.length > 0 && keys_of_platforms.forEach((key) => {
        //         if(dim.platform === key){

        //         }

        //     })
        // })

        keys_of_platforms && keys_of_platforms.length > 0 && keys_of_platforms.forEach((key) => {
            if (key !== 'response_session_id') {
                temp_tabs["all files"][key] = {};
                temp_tabs["all files"][key] = {};

                keys_of_dimensions && keys_of_dimensions.length > 0 && keys_of_dimensions.forEach((dim_key) => {
                    if (dim_key !== 'response_session_id') {
                        temp_tabs["all files"][key]['data'] = {};
                        temp_tabs["all files"][key][dim_key] = {};
                        temp_tabs["all files"][key]['data'] = platformFiles[key];
                        temp_tabs["all files"][key][dim_key]['data'] = getFilterFiles(dimensionsFiles, key, dim_key)
                    }
                })
            }

        })

        setTabList(temp_tabs)
        setLocalFiles(allFiles)
        setActiveTab(activeTabKey);
    }, [platformFiles, dimensionsFiles, allFiles])


    // const filterDa
    const openPropertyPopup = (file) => {
        let _data = {
            file_id: file.id
        }
        // getMetaInfoOfFile(_data)
        // showPopup(undefined, undefined, enums.default.popupType.element, FilePropertyPopup);
    }


    const RenderMenu = ({ tab_object, is_main, child }) => {
        return (
            <React.Fragment>
                <ul>
                    {tab_object && Object.keys(tab_object).map((key, index) => {
                        let _main_object = tab_object[key] ? tab_object[key] : {};
                        if (key !== 'data') {
                            return (
                                <li onClick={(e) => {
                                    e.stopPropagation()
                                    toggleMenu(key)
                                    setLocalFiles(tab_object[key]['data'])
                                }} className={"inner_title " + (menuOpen && menuOpen[key] ? 'active' : 'a')}>

                                    <div className="title_parent" title={key}>
                                        <div className="icon">
                                            {menuOpen && menuOpen[key] ? <OpenFolder size=".9rem" height=".9rem" /> : <FolderSvg size=".9rem" height=".9rem" />}
                                        </div><div className="title_show">{key}</div>
                                    </div>
                                    <div className={index == (Object.keys(tab_object).length - 1) ? 'overlay_line' : 'a'}></div>

                                    {menuOpen && menuOpen[key] && _main_object && Object.keys(_main_object).length > 0 &&
                                        <ul>
                                            {
                                                Object.keys(_main_object).map((m, j) => {
                                                    if (m !== 'data') {
                                                        if (Object.keys(_main_object[m]).length > 1) {
                                                            return <RenderMenu key={"chlid" + j} is_main={false} tab_object={_main_object[m]} child={true}></RenderMenu>
                                                        } else {
                                                            return (
                                                                <li
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        const tempOpenMenu = { [key]: {} };
                                                                        if (typeof tempOpenMenu[key][m] === 'undefined') {
                                                                            tempOpenMenu[key][m] = true;
                                                                        }
                                                                        else if (tempOpenMenu[key][m]) {
                                                                            delete tempOpenMenu[key][m];
                                                                        }

                                                                        setMenuOpen(tempOpenMenu);
                                                                        setLocalFiles(_main_object[m]['data'])
                                                                    }}
                                                                    className="second_parent" active={activeTab == m}>
                                                                    <div className="title_parent" title={m}>
                                                                        <div className="icon">
                                                                            {menuOpen && menuOpen[key][m] ? <OpenFolder size=".9rem" height=".9rem" /> : <FolderSvg size=".9rem" height=".9rem" />}
                                                                        </div><div className="title_show">{m}</div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        }
                                                    }
                                                })
                                            }
                                        </ul>
                                    }
                                </li>
                            )
                        }
                    })}
                </ul>
            </React.Fragment>
        )
    }



    const select_unselect_file = (id, is_all = false) => {

        let clone_selected_files = selected_files ? [...selected_files] : [];

        if (is_all) {

            if (clone_selected_files.length === localFiles.length) {
                // let un select all files
                clone_selected_files = [];
            }
            else {
                const all_file_ids = localFiles?.map((f) => f.id) || [];
                clone_selected_files = all_file_ids
            }
        }
        else {
            if (clone_selected_files?.indexOf(id) > -1) {
                // let remove this 
                clone_selected_files.splice(clone_selected_files?.indexOf(id), 1);
            } else {
                // let's push it
                clone_selected_files.push(id);
            }
        }

        set_selected_files(clone_selected_files);

    }



    return (
        <MainWrapper>
            {/* <LeftTabWrapper>
                <Tab
                    onClick={() => {
                        setMainMenuOpen(!mainMenuOpen)
                        setMenuOpen(undefined)
                        setLocalFiles(tabList['all files'].data)
                    }}
                    className="parent">
                    <div style={{ padding: '.4rem 0px' }} className="title_parent">
                        <div className="icon">
                            {mainMenuOpen ? <OpenFolder size=".9rem" height=".9rem" /> : <FolderSvg size=".9rem" height=".9rem" />}
                        </div><div className="title_show">All Files</div>
                    </div>
                    <div className={"parent_node"}>
                        {mainMenuOpen && tabList && Object.keys(tabList).length > 0 && Object.keys(tabList).map((key, index) => {
                            return <RenderMenu key={"parent" + index} tab_object={tabList[key]} is_main={true}></RenderMenu>

                        })}
                    </div>
                </Tab>

            </LeftTabWrapper> */}

            <RightSection>
                {permissionPopup &&
                    <ProgressOverlay bg={"#1c1b1b85"}>
                        <AddColumnsPopup
                            columns_from_db={permissionPopup.columns_from_db ? permissionPopup.columns_from_db : undefined}
                            extra_columns={permissionPopup.new_columns ? permissionPopup.new_columns : undefined}
                            main_id={permissionPopup.main_id ? permissionPopup.main_main : undefined}
                            file_id={permissionPopup.file_id ? permissionPopup.file_id : undefined}
                            file_name={permissionPopup.file_name ? permissionPopup.file_name : undefined}
                            platform_details={permissionPopup.platform_details ? permissionPopup.platform_details : undefined}
                            tbl_config_result={permissionPopup.tbl_config_result ? permissionPopup.tbl_config_result : undefined}
                            closeFunction={() => {
                                props.clearProcessFileData();
                            }}
                        />
                    </ProgressOverlay>

                }

                <SharedTable
                    actionsTitles={{
                        "processAction": {
                            title: "Process The File",
                            message: "Are you sure you want to re-process this file ?"
                        },
                        "deleteAction": {
                            title: "Delete The File",
                            message: "Are you sure you want to delete this file ?"
                        },
                        "downloadAction": {
                            title: "Download The File",
                        }
                    }}

                    maximumWidthOfColumn={{
                        // "platform": '15rem',
                        // "number_of_records": '120px',
                        // "process_records": '160px',
                        // "error_records": '100px',

                        "file_name": '12rem', 
                        'platform': '10rem', 
                        'created_at': '10rem', 
                        'status': '10rem', 
                        'size': '5rem', 
                        'number_of_records': '10rem',  
                        'process_records': '10rem', 
                        'error_records': '10rem', 
                        'created_by': '10rem',

                        "Name": '12rem',
                        'Dataset Name': '10rem',
                        'Upload Time': '10rem',
                        'Status': '10rem',
                        'Size': '5rem',
                        'Number Of Records': '10rem',
                        'Process Records': '10rem',
                        'Failed Records': '10rem',
                        'Uploaded By': '10rem',
                    }}
                    searchBarWidth={'22%'}
                    searchStyle={{iconSize: '1rem', paddingLeft: '4px', fontSize: '1rem'}}
                    sortStyle={{paddingLeft: '4px'}}
                    selected={selected_files}
                    pointerRow={true}
                    extractFileName={_extract_file_name}
                    isSearchBox={true}
                    borderType='border-radius'
                    borderRadius='6px'
                    searchBoxLabel={"Search The File"}
                    isSizeColumn={"size"}
                    searchColumns={['file_name', 'platform']}
                    height={'calc(100vh - 180px)'}
                    action={false}
                    fullTime={true}
                    actionOnHover={true}
                    viewAction={(file) => {
                        let _post_data = {
                            file_id: file.id,
                        }

                        getViewFileTableData(_post_data)
                        showPopup(undefined, undefined, enums.default.popupType.element, ViewPopup);
                    }}
                    viewErrorAction={(file) => {
                        let _post_data = {
                            file_id: file.id,
                            error_table: true
                        }

                        getViewFileTableData(_post_data)
                        showPopup(undefined, undefined, enums.default.popupType.element, ViewPopup);
                    }}
                    deleteAction={(file) => {
                        const _data = {
                            file_id: file.id,
                            file_name: file.file_name,
                            platform_id: file.platform_id
                        }
                        deleteFile(_data)
                        setMenuOpen(true)
                    }}
                    // processAction={(file) => {
                    //     let _data = {
                    //         file_id: file.id,
                    //         file_name: file.file_name,
                    //         platform_id: file.platform_id,
                    //         status: file.status
                    //     }
                    //     processTheFile(_data)
                    // }}
                    // downloadAction={(file) => {
                    //     downloadTheFile(file.file_name)
                    // }}
                    rowClickAction={(file) => {
                        openPropertyPopup(file)
                    }}
                    tableHead={["Name", 'Dataset Name', 'Upload Time', 'Status', 'Size', 'Number Of Records', 'Process Records', 'Failed Records', 'Uploaded By']}
                    columnsToShow={["file_name", 'platform', 'created_at', 'status', 'size', 'number_of_records',  'process_records', 'error_records', 'created_by']}
                    sortColumns={{
                        "Name": {
                            type: "string",
                            dataKey: 'file_name'
                        },
                        "Status": {
                            type: "string",
                            dataKey: 'status'
                        },
                        'Upload Time': {
                            type: "date",
                            dataKey: 'created_at'
                        },
                        'Number Of Records': {
                            type: 'number',
                            dataKey: 'number_of_records'
                        },

                        'Process Records': {
                            type: 'number',
                            dataKey: 'process_records'
                        },
                        'Failed Records': {
                            type: 'number',
                            dataKey: 'error_records'
                        },
                        "Size": {
                            type: 'number',
                            dataKey: 'size'
                        },
                        "Dataset Name": {
                            type: "string",
                            dataKey: 'platform'
                        }
                    }}
                    columnTextWithWrapper={['status']}
                    tableData={(localFiles && localFiles.length > 0 ? localFiles.slice(0, 100) : [])}
                    date_columns={["created_at"]}
                />
            </RightSection>

        </MainWrapper>
    )
}




const mapStateToProps = (state) => ({
    allFiles: state.fileUploaderReducer.allFiles,
    platformFiles: state.fileUploaderReducer.platformFiles,
    dimensionsFiles: state.fileUploaderReducer.dimensionsFiles,
    processFileData: state.fileUploaderReducer.processFileData,
    clientsInfo: state.clientInfoReducer.clientsInfo,
})




export default connect(mapStateToProps, { getAllFiles, getFilesByPlatform, getFilesByDimension, deleteFile, getViewFileTableData, getMetaInfoOfFile, downloadTheFile, processTheFile, clearProcessFileData })(Index)

