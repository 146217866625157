import React, { useEffect, useState } from 'react'
import { PageInnerWrapper } from '../hoc/styled.hoc';
import { getDbInfoId, isDefaultLiveDb, showPopup } from '../../utils';
import enums from '../../utils/enums';
import SharedTable from '../shared-table';
import { get_data_hierarchy, save_data_hierarchy, get_table_column_hints_v1 } from '../../actions/data-hierarchy';
import { getStringHintsFromServer } from '../../actions/report'
import { connect } from 'react-redux';
import { Button } from '../hoc/button';
import { withRouter } from 'react-router-dom';
import AddEditPopup from './add.edit.popup';
import { getAllRelationships } from '../../actions/relationship.detail';


const Index = (props) => {

    const { tableColumnsHints } = props;

    const { get_data_hierarchy, data_hierarchy_data, save_data_hierarchy, get_table_column_hints_v1 } = props;
    const [isLoding, setIsloding] = useState(true);

    const raiseAddEditPopup = (item) => {

        showPopup((item ? 'Edit Fact & Dimensions' : 'Add Your Facts & Dimensions'), undefined, enums.popupType.element_with_header, AddEditPopup, {
            popupData: item ? item : undefined,
            _saveFun: save_data_hierarchy,
        }, undefined, undefined, undefined, {
            width: '32rem',
        });
    }


    useEffect(() => {
        get_data_hierarchy()
    }, [])

    useEffect(() => {
        if(data_hierarchy_data?.length > -1){
            setIsloding(false);
        }
    },[data_hierarchy_data])

    console.log('data_hierarchy_data',data_hierarchy_data,'isLoding',isLoding )
    useEffect(() => {
        let _page_info = {
            title: 'Facts & Dimensions',
            extraComponent: <Button primary={true} onClick={() => {
                raiseAddEditPopup()
            }}>
                Add New
            </Button>
        }
        props.dispatchHeaderInformation(_page_info)

    }, [tableColumnsHints])


    return (
        <div>
            <PageInnerWrapper padding={'10px'} style={{ marginTop: '1rem' }}>
                <SharedTable
                    isLoding={isLoding}
                    height={"calc(100vh - 180px)"}
                    editAction={(item) => {
                        raiseAddEditPopup(item)
                    }}
                    deleteAction={(item) => {
                        let tempItem = item ? Object.assign({}, item) : {}
                        tempItem.deleted = true;
                        save_data_hierarchy(tempItem)
                    }}
                    duplicateAction={() => { }}
                    tableData={(data_hierarchy_data && data_hierarchy_data.length > 0) ? data_hierarchy_data : undefined}
                    tableHead={["Fact", "Dimensions", 'Action']}
                    columnsToShow={["fact", "go_to",]}
                />
                {data_hierarchy_data == undefined || data_hierarchy_data.length == 0 ? <p style={{ textAlign: "center" }}>No Record Found</p> : null}
            </PageInnerWrapper>

        </div>
    )

}




const mapStateToProps = (state) => ({

    rulesHints: state.hintsReducer.rulesHints,
    tableColumnsHints: state.hintsReducer.tableColumnsHints,
    data_hierarchy_data: state.dataHierarchy.data_hierarchy_data,
    relationships: state.relationshipDetail.relationships

})



export default withRouter(connect(mapStateToProps, { getAllRelationships, get_table_column_hints_v1, getStringHintsFromServer, get_data_hierarchy, save_data_hierarchy })(Index))