import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import CopyIcon from '../svg/icons-v2/copyIcon';

const Container = styled.div`
`;


const CopyButton = styled.button`
  position: relative;
  box-sizing: border-box;
  border: 0;
  background: none;
  outline: none;
  cursor: pointer;
  user-select: none;

  /* &:before {
    content: "";
    width: 16px;
    height: 16px;
    bottom: -20px;
    left: 82px;
    clip-path: polygon(50% 40%, 0% 100%, 100% 100%);
  } */

  &:after {
    content: ${(props) => `"${props.tooltip}"`};
    width: 7rem;
    padding: 5px;
    border-radius: 4px;
    font-size: 0.8rem;
    z-index: 100;
  }

  &:before,
  &:after {
    opacity: 0;
    top: 1.2rem;
    left: -4rem;
    pointer-events: none;
    position: absolute;
    box-sizing: border-box;
    background-color: #000000;
    color: #ffffff;
    transform: translateY(-10px);
    transition: all 300ms ease;
  }

  &:hover:before,
  &:hover:after {
    opacity: 1;
    transform: translateY(0);
  }
`;

const CopyToClipboardBtn = ({ text }) => {
  const [tooltip, setTooltip] = useState("Copy to clipboard");

  const copyTextPrev = async (e) => {
    e.stopPropagation()
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
      setTooltip("Copied! ✅");
      setTimeout(() => setTooltip("Copy to clipboard"), 2000);
    };
  };

  const copyText = async (e) => {
    e.stopPropagation();
    try {
      if ('clipboard' in navigator) {
        await navigator.clipboard.writeText(text);
        setTooltip("Copied! ✅");
      } else {
        // Fallback for environments where clipboard API is not available
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        setTooltip("Copied! ✅");
      }
      setTimeout(() => setTooltip("Copy to clipboard"), 2000);
    } catch (error) {
      console.error('Failed to copy text:', error);
      setTooltip("Failed to copy");
      setTimeout(() => setTooltip("Copy to clipboard"), 2000);
    }
  };
  return (
    <Container>
      <CopyButton onClick={copyText} tooltip={tooltip}>
        <CopyIcon size='.9rem' height=".9rem" color={'#9D9D9D'} />
      </CopyButton>
    </Container>
  );
};

export default CopyToClipboardBtn;
