import React from 'react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ZAxis
} from 'recharts';

const data = [
  { service: 'opd', revenue: 100 },
  { service: 'ipd', revenue: 200 },
  { service: 'pharmacy', revenue: 120 },
];

// Map services to x-axis categories
const serviceMapping = {
  opd: 1,
  ipd: 2,
  pharmacy: 3
};

// Prepare data for the ScatterChart
const preparedData = data.map(item => ({
  x: serviceMapping[item.service],  // Map service to x-axis categories
  y: item.revenue,                  // Revenue on y-axis
  z: item.revenue,                  // Bubble size based on revenue
  service: item.service             // Include service for tooltip
}));

const BubbleChart = () => (
  <ResponsiveContainer width="100%" height={400}>
    <ScatterChart
      width={500}
      height={400}
      margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
    >
      <CartesianGrid />
      <XAxis 
        type="number" 
        dataKey="x" 
        name="Service" 
        tickFormatter={(value) => Object.keys(serviceMapping).find(key => serviceMapping[key] === value)} // Show service names
        allowDecimals={false}
      />
      <YAxis type="number" dataKey="y" name="Revenue" />
      <ZAxis type="number" dataKey="z" range={[100, 500]} name="Bubble Size" />
      <Tooltip cursor={{ strokeDasharray: '3 3' }} />
      <Legend />
      <Scatter name="Revenue" data={preparedData} fill="#8884d8" shape="circle" />
    </ScatterChart>
  </ResponsiveContainer>
);

export default BubbleChart;
