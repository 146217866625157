import React, { useState } from 'react'
import { startRecord } from '../../utils/voice';
import { find_command_type } from '../../utils';
import styled, { keyframes } from 'styled-components';
import { constants } from '../../utils/constants';



const Wrapper = styled.div`
    width: 400px;
    height: 500px;
    /* box-shadow: -1px 0px 14px 0px rgba(0, 0, 0, 0.75); */
    border-radius: 30px;
    text-align: center;
    overflow: hidden;
    background: white;
    overflow: hidden;
    position: relative;

`;

const TopContent = styled.div`
    height: 320px;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TopBlueCover = styled.div`
    height: 450px;
    width: 500px;
    background: #337DFF;
    border-radius: 100%;
    position: absolute;
    bottom: 180px;
    left: -50px;
`;

const BottomContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 2rem;
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const Icon = styled.img`
    width: 90px;
    position: absolute;
`;


const IconWrapper = styled.span`
    width: 92px;
    height: 92px;
    background: #689AF0;
    border-radius: 90px;
    animation: ${pulse} 1.5s infinite;
`;


const VoiceSearchWrapper = (props) => {



    const { popupKey, close, option, data } = props;

    const { save_fun } = props?.data;


    const [text, setText] = useState('')


    startRecord((text) => {
        setText(text)
        if (save_fun) {
            save_fun(text)
            setTimeout(() => {
                close(option, popupKey)
            }, 1000);
        }
    });


    return (
        <Wrapper>

            <TopBlueCover />
            <TopContent>
                <IconWrapper></IconWrapper>
                <Icon src={`${constants.CONTEXT_PATH}/editor/mike_new.png`} />
            </TopContent>
            <BottomContent>
                {text}
            </BottomContent>
        </Wrapper>
    )
}

export default VoiceSearchWrapper;

