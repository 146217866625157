
/***
 * title: this is a select-option component 
 * author: saroj kumar
 * date: 13 jan 2022
 * 
 * accept props =>
 * 
 *      select_option_style = string
 *              ["type1", "type2", "type3", "type4"]
 *      on_change = function
 *      value = string
 *      options = array
 *      value_key = string
 *      display_key = string
 *      label = string
 *          
 */


import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components'
import * as _ from 'lodash';


/***
 * here we have the style of the component 
 * 
 */


const MainDiv = styled.div`
      width: 100%;
      position: relative;
      padding-top: 10px;
      .none_user_input{
          opacity: 0;
          width: 0px;
          height: 0px;
      }
  `;



const OverlayMain = styled.div`
      width: 100vw;
      height: 100vh;
      z-index: 1200;
      position: fixed;
      top: 0px;
      left: 0px;
      background: #ff000000;
  `;


const UserInput = styled.div`
      width: 100%;
      height: ${props => props.height || '200px'};
      /* background: ${props => props.background ? props.background : '#fff'}; */
      position: relative;
      /* display: flex; */
      /* align-items: center; */
      cursor: pointer;
      box-sizing: border-box;
 
      textarea{
          width: 100%;
          height: 100%;
          border: 0px;
          border-radius: 2px;
          border: ${props => props?.focused ? '2px solid #007FFF' : '1px solid #0000003b'};
          padding: 0px 10px;
          box-sizing: border-box;
          font-size: .9rem;
          font-weight: normal;
          color: rgb(55, 74, 102);
          background-color: transparent;
          z-index: 2;
          padding-top: 15px;
          resize: none;
 
         &:hover{
             border: 1px solid #000;
         }
         &:focus{
             outline: none;
             border: ${props => props?.focused ? '2px solid #007FFF' : '1px solid #0000003b'};
         }
      }
      
      
      .value_txt{
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: .9rem;
          font-weight: normal;
          color: rgb(55, 74, 102);
      }
      .label_pre{
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: .9rem;
          color: #646464;
          font-weight: 300;   
          text-transform: capitalize;
          color: rgba(0, 0, 0, 0.6);
          /* font-family: Roboto, Helvetica, Arial, sans-serif; */
          font-weight: 400;
          font-size: .9rem;
          line-height: 1.4375em;
          letter-spacing: 0.00938em;
          transition: all 0.2s;
          position: absolute;
          left: 10px;
          background-color: ${props => props.background ? props.background : '#fff'};
          z-index: 1;
          ${props => props.labelStyle ? { ...props.labelStyle } : undefined}
 
       }
       
  
       .label_post{
          color: rgba(0, 0, 0, 0.6);
          font-weight: 400;
          /* font-family: Roboto, Helvetica, Arial, sans-serif; */
          font-size: .9rem;
          transform: translate(-11%, -100%) scale(0.8);
          background-color: ${props => props.background ? props.background : '#fff'};
          padding: 0px 7px;
          z-index: 3;
          ${props => props.labelStyle ? { ...props.labelStyle } : undefined}
 
       }
  
  `;



const TextBoxV1 = (props) => {


    /**
     * here we are destructuring the props
     */

    const {

        value,
        type,
        label,
        onChange,
        onFocus,
        onBlur,
        background,
        labelStyle,
        hide_case,
        height,
        valueStyle

    } = props;



    /// State is here of the component

    const [focused, set_focused] = useState(undefined)


    const el_id = "input__id__uniqqq_auto_gen" + Math.random(100)
    const is_input_have_value = value && value.length > 0;
    const lable_class = "label_pre" + " " + ((focused || is_input_have_value) ? "label_post" : 'dd');

    return (
        <MainDiv>

            {/* this is input but not visible for user */}

            {/* this is active value placholder  */}
            <UserInput
                background={background}
                id={el_id}
                height={height}
                focused={focused}
                onBlur={() => {
                    set_focused(false)
                }}
                labelStyle={labelStyle}
            >
                {<span className={lable_class} style={{ color: focused ? "#007FFF" : undefined , top: '12px'}}>{!hide_case ? _.startCase(_.toLower(label)) : label}</span>}
                <textarea
                    value={value}
                    onFocus={(e) => {
                        if (onFocus) onFocus(e)
                        set_focused(true)
                    }}
                    onBlur={(e) => {
                        if (onBlur) onBlur(e)
                        set_focused(false)
                    }}
                    onChange={(e) => {
                        onChange(e)
                    }}
                    style={valueStyle}
                    type={type || "text"} />

            </UserInput>
        </MainDiv>
    );
}
export default TextBoxV1;