import { simpleDispatch_get, simpleDispatch_post } from './helper';
import * as actionTypes from '../action-types/campaign';
import { constants } from '../utils/constants';





export const getAdTechClients = () => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.AD_TECH_CLIENT.POINT + constants.END_POINTS.AD_TECH_CLIENT.GET_AD_TECH_CLIENTS;

    await simpleDispatch_get(dispatch, url, actionTypes.GET_AD_TECH_CLIENTS);
};





export const  getAdTechCampaignMappings = () => async dispatch => {
    
    const url = constants.END_POINTS.API + constants.END_POINTS.AD_TECH_CAMPAIGN_MAPPING.POINT + constants.END_POINTS.AD_TECH_CAMPAIGN_MAPPING.GET_AD_TECH_CAMPAIGN_MAPPING;

    await simpleDispatch_get(dispatch, url, actionTypes.GET_AD_TECH_CAMPAIGN_MAPPINGS);
};





export const  getAdTechCampaignName = () => async dispatch => {
    
    const url = constants.END_POINTS.API + constants.END_POINTS.AD_TECH_CAMPAIGN.POINT + constants.END_POINTS.AD_TECH_CAMPAIGN.GET_AD_TECH_CAMPAIGN;

    await simpleDispatch_get(dispatch, url, actionTypes.GET_AD_TECH_CAMPAIGN);
};



export const  saveTheMapping = (data) => async dispatch => {
    
    const url = constants.END_POINTS.API + constants.END_POINTS.AD_TECH_CAMPAIGN_MAPPING.POINT + constants.END_POINTS.AD_TECH_CAMPAIGN_MAPPING.SAVE_THE_MAPPING;

    await simpleDispatch_post(dispatch, url, data, actionTypes.SAVE_THE_MAPPING, undefined, undefined, { message: "Campaign Save successFully"});
};





export const  getTheMappingByAdTechClientAndPlatform = (data) => async dispatch => {
    
    const url = constants.END_POINTS.API + constants.END_POINTS.AD_TECH_CAMPAIGN_MAPPING.POINT + constants.END_POINTS.AD_TECH_CAMPAIGN_MAPPING.GET_THE_MAPPING_BY_AD_TECH_CLIENT_AND_PLATFORM;

    await simpleDispatch_post(dispatch, url, data, actionTypes.GET_THE_MAPPING_AD_TECH_CLIENT);
};









