import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '../hoc/button';
import RippleEffect from '../hoc/ripple-effect';
import Delete from '../svg/delete'
import ExpandSvg from '../svg/expand'
import ShrinkSvg from '../svg/shrink'
import { isDefaultLiveDb } from '../../utils';
import SelectOptionV1 from '../select-option/select.option.v1.1';


const MainWrapper = styled.div`

    width: ${props => props.expanded ? '100vw' : '65rem'};
    height:  ${props => props.expanded ? '90vh' : '28rem'};;
    background-color: #fff;
    border-radius: 4px;
    padding: .5rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;

    h1{
        margin: 0px;
        font-size: 1.3rem;
        margin-bottom: 1rem;
        color: #222;
    }

    .scroll_div{
        width: 100%;
        height: ${props => props.expanded ? '85vh' : '30rem'};
        overflow-x: auto;
        &::-webkit-scrollbar {
            background-color: #3F3F3F;
            width:2px;
        }

        &::-webkit-scrollbar-track {
            background-color: #F3F3F3;
            border: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #dbdbdb;
            border-radius: 10px;
            height: 2px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color:  #3F3F3F;
            height: 2px;
        }
        
    }
    table{
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0 7px;
        
    }
   
 
    thead{
        width: 100%;
        th{
            font-size: 1rem;
            overflow: hidden;
            color: #374a66;
            padding: 1rem;
            text-align: left;
            /* height: 3rem; */
            background-color: #e3eeff;
            box-shadow: border-box;
            width: 16.7%;
        }
        th:first-child{
            border-radius: 6px 0 0 6px;
        }
        th:last-child{
            border-radius: 0 6px 6px 0;
        }
    }


    td{
        font-size: 1.2rem;
        width: 16.7%;
        overflow: hidden;
        color: #374a66;
        text-align: left;
        border-bottom: 1px solid #eaeaea;
        padding: 0.9rem;
        box-shadow: border-box;
        &div, .action_button{
            border: 1px solid grey;
            border-radius: 6px;
            width: 100%;

        }
        div:first-child{
            text-align: left;
        }
        .action_button{
            background-color: #f2f2f2;
            justify-content: center;
            text-align: center;
        }

        
        select{
            width: 100%;
            border: 0px;
            background: transparent;
            color: #374a66;
            font-size: 1.3rem;
        }
            option{
                font-size:1.1rem;
            }
            select:focus {
             outline:none 
             }
    }
`;

const mt_data_obj = {
    target_table: '',
    source_table: '',
    source_column: '',
    target_column: '',
    join_type: '',
}

const RelationshipForm = (props) => {

    // const { tableNames, tableData, tableRelationship } = props;
    const { close, popupKey, option, data } = props;
    const { tableInfo, tableData, tableRelationship, setTableRelationship, index } = data;

    const tableNames = tableInfo.map(t => t.name) || [];

    const [formData, setFormData] = useState(undefined)
    const [expanded, setExpanded] = useState(undefined)

    const IS_DEFAULT_LIVE_DB = isDefaultLiveDb()


    useEffect(() => {
        if (tableRelationship && tableRelationship.length > 0) {

            const clone_data = formData ? [...formData] : [];
            const final_array = [...clone_data, ...tableRelationship];

            final_array.push({
                target_table: '',
                source_table: '',
                source_column: '',
                target_column: '',
                join_type: '',
            })
            setFormData(final_array)
        }
        else {
            const mt_a = [];
            mt_a.push(JSON.parse(JSON.stringify(mt_data_obj)))
            setFormData(mt_a)
        }
    }, [tableRelationship])


    const value_on_change = (key, value, index) => {

        const cloned_form_data = formData ? [...formData] : [];

        let target_row = cloned_form_data[index];
        target_row[key] = value;
        target_row['modified'] = true;

        cloned_form_data[index] = target_row;

        const l_element = cloned_form_data[cloned_form_data.length - 1];

        if (l_element.source_column !== '' && l_element.source_table !== '' && l_element.target_table !== '' && l_element.target_column !== '' && l_element.join_type !== '') {
            cloned_form_data.push({
                target_table: '',
                source_table: '',
                source_column: '',
                target_column: '',
                join_type: '',
            })
        }
        setFormData(cloned_form_data)
    }


    const _save_relationShip = () => {
        const clone_data = formData ? [...formData] : [];
        clone_data.pop()
        setTableRelationship(clone_data, index)
        close(option, popupKey)
    }


    const delete_row = (_index) => {
        const cloned_form_data = formData ? [...formData] : [];
        cloned_form_data.splice(_index, 1)
        setFormData(cloned_form_data)
    }


    const get_table_info_by_table_name = (table_name) => {
        for (let i = 0; i < tableInfo.length; i++) {
            if (tableInfo[i].name === table_name) return tableInfo[i];
        }
    }

    const get_columns_for_table = (info) => {

        const table_name = info?.name;
        const schema_id = info?.schema_id;

        if (schema_id) {

            const current_schema_tables = tableData && tableData[schema_id] || tableData[schema_id + "__nf__db__cached__"];
            const table = current_schema_tables && current_schema_tables[table_name];
            if (table) return Object.keys(table)

        } else {
            const table = tableData && tableData[table_name];
            if (table) return Object.keys(table)
        }


    }


    return (
        <MainWrapper expanded={expanded}>
            <React.Fragment>
                {expanded ?
                    <div style={{ position: 'absolute', top: '.5rem', right: '4rem' }}><RippleEffect
                        width={35}
                        title={"Shrink"}
                        icon={<ShrinkSvg size={'1rem'} height={"1rem"} />}
                        Click={() => {
                            setExpanded(undefined)
                        }}
                    />  </div> :
                    <div className="report_expand_icon" style={{ position: 'absolute', top: '.5rem', right: '4rem' }}><RippleEffect
                        width={35}
                        title={"Expand"}
                        icon={<ExpandSvg size={'1rem'} height={"1rem"} />}
                        Click={() => {
                            setExpanded(true)
                        }}
                    /></div>}
            </React.Fragment>
            <div className="scroll_div">
                <table>
                    <thead>
                        <tr style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} className='table_header'>
                            <th style={{ width: '18%' }}>Source Table</th>
                            <th style={{ width: '18%' }}>Source Column</th>
                            <th style={{ width: '18%' }}>Join Type</th>
                            <th style={{ width: '18%' }}>Target Table</th>
                            <th style={{ width: '18%' }}>Target Column</th>
                            <th >Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        {formData && formData.length > 0 && formData.map((row, index) => {

                            const source_table_info = row?.source_table ? get_table_info_by_table_name(row.source_table) : undefined;
                            const target_table_info = row?.target_table ? get_table_info_by_table_name(row.target_table) : undefined;

                            const source_column_dropdown = source_table_info && get_columns_for_table(source_table_info) || []
                            const target_column_dropdown = target_table_info && get_columns_for_table(target_table_info) || []

                            const is_last_element = index === (formData.length - 1)

                            if (!row.deleted) {
                                return (
                                    <tr style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} key={"tb" + index}>
                                        <td style={{ width: '18%' }} className='on_hover'>

                                            <SelectOptionV1
                                                options={tableNames}
                                                value={row.source_table}
                                                background={'transparent'}
                                                hide_margin={true}
                                                none_value={false}
                                                on_change={(val) => {
                                                    value_on_change("source_table", val, index)
                                                }}
                                            />
                                        </td>
                                        <td style={{ width: '18%' }}>

                                            <SelectOptionV1
                                                options={source_column_dropdown}
                                                value={row.source_column}
                                                background={'transparent'}
                                                none_value={false}
                                                hide_margin={true}
                                                on_change={(val) => {
                                                    value_on_change("source_column", val, index)
                                                }}
                                            />
                                        </td>
                                        <td style={{ width: '18%' }}>

                                            <SelectOptionV1
                                                options={['inner join', 'Left outer join', 'Right outer join', 'Full outer join']}
                                                value={row.join_type}
                                                background={'transparent'}
                                                none_value={false}
                                                hide_margin={true}
                                                on_change={(val) => {
                                                    value_on_change("join_type", val, index)
                                                }}
                                            />
                                        </td>
                                        <td style={{ width: '18%' }}>

                                            <SelectOptionV1
                                                options={tableNames}
                                                value={row.target_table}
                                                background={'transparent'}
                                                none_value={false}
                                                hide_margin={true}
                                                on_change={(val) => {
                                                    value_on_change("target_table", val, index)
                                                }}
                                            />

                                        </td>
                                        <td style={{ width: '18%' }}>
                                            <SelectOptionV1
                                                options={target_column_dropdown}
                                                value={row.target_column}
                                                background={'transparent'}
                                                none_value={false}
                                                hide_margin={true}
                                                on_change={(val) => {
                                                    value_on_change("target_column", val, index)
                                                }}
                                            />
                                        </td>


                                        <td >
                                            {!is_last_element && (
                                            <div className='action_button'> 
                                                <RippleEffect
                                                    icon={<Delete size="0.9rem" height="0.9rem" color="#d98b8f" />}
                                                    title={"delete"}
                                                    Click={
                                                        (e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            delete_row(index);
                                                        }
                                                    }
                                                />
                                            </div>
                                            )}
                                        </td>
                                    </tr>
                                )
                            }
                        })}

                    </tbody>
                </table>
            </div>

            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center', marginTop: 'auto' }}>

                <Button
                    width={'5rem'}
                    bgColor="#eaeaea"
                    color="#000000"
                    mR="1rem"
                    onClick={() => {
                        close(option, popupKey)
                    }}>
                    Cancel
                </Button>
                <Button
                    primary={true}
                    width={'5rem'}
                    onClick={() => {
                        _save_relationShip()
                    }}
                >
                    Save
                </Button>
            </div>
        </MainWrapper>
    );
}

export default RelationshipForm;
