import React from "react";

const AddIcon = (props) => {
  return (
    <svg
    width={props.size ? props.size : "2.2rem"}
    height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 187 187"
      space="preserve"
    >
      <path
        d="M0 0 C3.46492311 2.8721044 6.74646416 5.89231232 10 9 C10.60328125 9.52851563 11.2065625 10.05703125 11.828125 10.6015625 C26.59781346 24.05992348 35.79115829 45.42641698 37.22753906 65.11962891 C38.56515088 94.13907512 31.9340511 117.51667552 13.0625 139.8125 C0.14049481 153.72265852 -15.92342841 162.00539618 -34 167 C-34.928125 167.26683594 -35.85625 167.53367188 -36.8125 167.80859375 C-59.41660063 173.06114919 -84.90826234 167.92774426 -104.6875 156.3125 C-126.22611913 142.22853273 -141.64926976 122.51244011 -147.5390625 97.20898438 C-153.04978083 70.79956731 -148.24749865 45.88264143 -134.1640625 23.01953125 C-120.19729999 2.59977088 -99.30595165 -11.37134673 -75.125 -16.4375 C-48.80486473 -21.36782086 -21.65543625 -16.15777132 0 0 Z "
        fill="#4E8FFE"
        transform="translate(150,18)"
      />
      <path
        d="M0 0 C3.46492311 2.8721044 6.74646416 5.89231232 10 9 C10.60328125 9.52851563 11.2065625 10.05703125 11.828125 10.6015625 C26.59781346 24.05992348 35.79115829 45.42641698 37.22753906 65.11962891 C38.56515088 94.13907512 31.9340511 117.51667552 13.0625 139.8125 C0.14049481 153.72265852 -15.92342841 162.00539618 -34 167 C-34.928125 167.26683594 -35.85625 167.53367188 -36.8125 167.80859375 C-59.41660063 173.06114919 -84.90826234 167.92774426 -104.6875 156.3125 C-126.22611913 142.22853273 -141.64926976 122.51244011 -147.5390625 97.20898438 C-153.04978083 70.79956731 -148.24749865 45.88264143 -134.1640625 23.01953125 C-120.19729999 2.59977088 -99.30595165 -11.37134673 -75.125 -16.4375 C-48.80486473 -21.36782086 -21.65543625 -16.15777132 0 0 Z M-108 19 C-108.59683594 19.5053125 -109.19367188 20.010625 -109.80859375 20.53125 C-119.45326295 29.26268262 -126.27557388 41.69387535 -130 54 C-130.36480469 55.17046875 -130.72960937 56.3409375 -131.10546875 57.546875 C-132.52058644 63.38996398 -132.32894925 69.33484298 -132.3125 75.3125 C-132.32861328 76.47201172 -132.34472656 77.63152344 -132.36132812 78.82617188 C-132.37293196 99.46938703 -123.12521873 116.57893647 -109.125 131.3125 C-93.65218819 146.2131277 -73.84231545 152.60269511 -52.71655273 152.41748047 C-35.25070744 151.82045383 -17.94397089 144.78556104 -5 133 C-3.88238281 132.00226563 -3.88238281 132.00226563 -2.7421875 130.984375 C10.60921016 118.40237992 19.6738892 101.16028931 20.43359375 82.66796875 C20.4572815 80.50794199 20.45811339 78.34755829 20.4375 76.1875 C20.42847656 75.04821045 20.41945312 73.9089209 20.41015625 72.73510742 C19.98902928 52.18908274 11.75884964 35.03146594 -2.52734375 20.4140625 C-18.68153831 4.97834718 -38.72216873 -1.61686582 -60.72265625 -1.26953125 C-78.38663866 -0.62286066 -95.02872932 7.16826151 -108 19 Z "
        fill="#347DFE"
        transform="translate(150,18)"
      />
      <path
        d="M0 0 C15.08060428 13.08464195 26.55792253 30.58284557 28.49047852 50.86767578 C28.62318333 54.10567328 28.65557542 57.3219872 28.625 60.5625 C28.61670166 61.70364258 28.60840332 62.84478516 28.59985352 64.02050781 C28.23585835 82.19176841 21.42248645 98.19471121 9.1875 111.6875 C8.13755859 112.85925781 8.13755859 112.85925781 7.06640625 114.0546875 C-6.16753093 128.06709157 -25.27419626 136.49027784 -44.5390625 137.11206055 C-64.87413314 137.22779924 -83.66999657 131.80430801 -98.8125 117.6875 C-99.56660156 117.02363281 -100.32070312 116.35976562 -101.09765625 115.67578125 C-113.10883776 104.52989927 -122.85761596 87.51156167 -124.00537109 70.89526367 C-124.38364728 56.62278858 -124.59307797 43.92998748 -118.8125 30.6875 C-118.43996094 29.77613281 -118.06742188 28.86476562 -117.68359375 27.92578125 C-109.01925423 8.69517403 -92.55149387 -5.12156982 -73.12744141 -12.48339844 C-48.44000249 -21.36395653 -20.57323512 -16.15654337 0 0 Z M-105.5 12.5 C-119.48558107 29.24979004 -123.13543657 49.46215006 -121.8125 70.6875 C-119.84718001 88.3868062 -110.40259915 103.55109811 -97.8125 115.6875 C-97.18085937 116.35007813 -96.54921875 117.01265625 -95.8984375 117.6953125 C-83.35874262 130.01404359 -64.52156324 135.0071606 -47.4375 135.0625 C-26.63431448 134.74125287 -9.06729547 126.82162833 6.1875 112.6875 C21.16081387 96.70562095 27.11534146 77.1841382 27.19921875 55.68359375 C26.26334156 36.03017286 16.91811278 19.2384972 3.1875 5.6875 C2.506875 5.00816406 1.82625 4.32882813 1.125 3.62890625 C-13.85909544 -10.38746636 -33.48788884 -15.09351208 -53.45996094 -14.453125 C-74.67399671 -13.18503818 -91.29575824 -2.74820607 -105.5 12.5 Z "
        fill="#DCE6F6"
        transform="translate(141.8125,33.3125)"
      />
      <path
        d="M0 0 C1.98 0 3.96 0 6 0 C6 7.92 6 15.84 6 24 C13.59 24 21.18 24 29 24 C29 25.98 29 27.96 29 30 C21.41 30 13.82 30 6 30 C6 37.92 6 45.84 6 54 C4.02 54 2.04 54 0 54 C0 46.08 0 38.16 0 30 C-7.59 30 -15.18 30 -23 30 C-23 28.02 -23 26.04 -23 24 C-15.41 24 -7.82 24 0 24 C0 16.08 0 8.16 0 0 Z "
        fill="#FEFEFF"
        transform="translate(92,67)"
      />
      <path
        d="M0 0 C0.33 0 0.66 0 1 0 C1.09796875 1.30453125 1.1959375 2.6090625 1.296875 3.953125 C2.22605477 15.20018397 3.46260971 25.39687145 8.875 35.5 C9.24625 36.325 9.6175 37.15 10 38 C9.0703125 40.15625 9.0703125 40.15625 8 42 C1.08060451 26.92163817 -1.40934561 16.58837872 0 0 Z "
        fill="#CADCF9"
        transform="translate(19,90)"
      />
      <path
        d="M0 0 C0.33 0 0.66 0 1 0 C3.09246536 12.41832705 0.09147151 23.12237231 -6 34 C-7.27522109 30.45049926 -6.72829518 28.69353818 -5.1875 25.3125 C-1.66948703 17.01484181 -0.63583506 8.95188829 0 0 Z "
        fill="#CDDCF6"
        transform="translate(168,95)"
      />
      <path
        d="M0 0 C0.33 0 0.66 0 1 0 C1.39328061 3.76916217 1.76147442 7.54036107 2.125 11.3125 C2.23714844 12.38435547 2.34929688 13.45621094 2.46484375 14.56054688 C2.56152344 15.58857422 2.65820312 16.61660156 2.7578125 17.67578125 C2.8520752 18.62364502 2.94633789 19.57150879 3.04345703 20.5480957 C3 23 3 23 1 26 C-1.69983817 17.26522944 -0.76250709 8.97491454 0 0 Z "
        fill="#C7DAF9"
        transform="translate(19,90)"
      />
      <path
        d="M0 0 C1.1171875 1.578125 1.1171875 1.578125 2 4 C1.3203125 7.234375 1.3203125 7.234375 0.125 10.75 C-0.26429687 11.92046875 -0.65359375 13.0909375 -1.0546875 14.296875 C-1.36664062 15.18890625 -1.67859375 16.0809375 -2 17 C-3.5711696 14.55532816 -4.07595875 13.44111823 -3.57421875 10.52734375 C-3.28160156 9.67269531 -2.98898438 8.81804687 -2.6875 7.9375 C-2.40261719 7.08027344 -2.11773438 6.22304687 -1.82421875 5.33984375 C-1.55222656 4.56769531 -1.28023437 3.79554687 -1 3 C-0.67 2.01 -0.34 1.02 0 0 Z "
        fill="#D0E0F9"
        transform="translate(24,61)"
      />
      <path
        d="M0 0 C2.20004472 0.41780398 4.38631928 0.9093008 6.5625 1.4375 C7.76003906 1.72496094 8.95757812 2.01242188 10.19140625 2.30859375 C11.11824219 2.53675781 12.04507813 2.76492187 13 3 C10 5 10 5 7.80859375 4.828125 C7.02613281 4.63734375 6.24367188 4.4465625 5.4375 4.25 C4.65246094 4.06953125 3.86742188 3.8890625 3.05859375 3.703125 C1 3 1 3 -1 1 C-0.67 0.67 -0.34 0.34 0 0 Z "
        fill="#C9DAF8"
        transform="translate(66,163)"
      />
      <path
        d="M0 0 C2 1 2 1 3.1875 3.8125 C3.96421451 6.85961076 4.10096399 8.13749363 3 11 C0.84694043 7.14243493 -0.39253254 4.44870215 0 0 Z "
        fill="#DFE9F8"
        transform="translate(21,113)"
      />
      <path
        d="M0 0 C0 0.33 0 0.66 0 1 C-1.43534841 1.2226746 -2.87345525 1.42761906 -4.3125 1.625 C-5.11300781 1.74101562 -5.91351563 1.85703125 -6.73828125 1.9765625 C-9 2 -9 2 -12 0 C-7.74433453 -1.16063604 -4.3164922 -0.76173392 0 0 Z "
        fill="#C7D9F8"
        transform="translate(86,19)"
      />
    </svg>
  );
};

export default AddIcon;



// const AddIcon = (props) => {
//   return (
//     <svg
//     width={props.size ? props.size : "2.2rem"}
//     height={props.height ? props.height : "2.2rem"}
//       fill={props.color ? props.color : "#000"}
//       version="1.1"
//       xmlns="http://www.w3.org/2000/svg"
//       x="0px"
//       y="0px"
//        viewBox="0 0 1024 1063.18" >
//         <path d="M512 21.333c282.77 0 512 229.23 512 512s-229.23 512-512 512-512-229.23-512-512 229.23-512 512-512zm0 934.957c233.592 0 422.957-189.364 422.957-422.957S745.593 110.376 512 110.376c-233.592 0-422.957 189.364-422.957 422.957.278 233.48 189.476 422.678 422.93 422.956h.026zm255.11-378.435H256.89v-89.043h510.22zM556.522 788.443H467.48v-510.22h89.042z"/></svg>
//   );
// };

// export default AddIcon;

