import * as actions from '../action-types/file_upload_config'


const initialState = {
    type: actions.FILE_UPLOAD_CONFIG_INIT,
    config_data: undefined,
};


const fileUploadConfig = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }


    switch (action.type) {

        case actions.GET_FILE_UPLOAD_CONFIG:
            return {
                ...state,
                type: action.type,
                config_data: action.data
            };


        default:
            return {
                ...state,
            }
    }
}

export default fileUploadConfig;