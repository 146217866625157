import React, { useState, useEffect, Suspense } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { get_Nf_Grammar, delete_Nf_Grammar } from '../../actions/nf_grammar'
import { NewWrapper, TableWrapper, Wrapper, EditorInnerWrapper } from '../../components/nf_grammar/nf-grammar.styled'
import { Button } from '../hoc/button'
import { showPopup } from '../../utils';
import * as enums from '../../utils/enums';
import AddData from './add'
import TabSlider from './tabs-slider'


const TableRow = React.lazy(() => import('./row'));


const Index = (props) => {

    const { StoreNfGrammar} = props;
    const [localgrammardata, setLocalGrammarData] = useState(undefined);

    const [selectTab, setSelectTab] = useState('noun');
    const [inputValue, setInputValue] = useState('')
    const [filterdData, setFilterData] = useState(undefined);
    const [labelType, setLableType] = useState(undefined);


    useEffect(() => {
        props.get_Nf_Grammar();
    }, []);


    useEffect(() => {
        if (StoreNfGrammar) {
            setLocalGrammarData(StoreNfGrammar.filter(x => x.type === selectTab));
        }
    }, [StoreNfGrammar]);




    const acceptValue = (value) => {
        if (selectTab !== value)
            setSelectTab(value);
    }

    useEffect(() => {
        if (localgrammardata)
            setLocalGrammarData(StoreNfGrammar.filter(x => x.type === selectTab));
    }, [selectTab]);


    const _delete_row = (id) => {

        {
            props.delete_Nf_Grammar(id)
            setTimeout(() => {
                props.get_Nf_Grammar()
            }, 1000)
        }
    }


    const _searchWord = (word) => {
        if (word) {
            let clone_local_array = StoreNfGrammar ? [...StoreNfGrammar] : StoreNfGrammar
            let filterdData = clone_local_array && clone_local_array.length > 0 && clone_local_array.filter((item, index) => {
                return item.word == word
            })
            setFilterData(filterdData)
            setLableType(filterdData)
        }
        else {
            setFilterData(StoreNfGrammar)
            setLableType(undefined)
        }
    }

    useEffect(() => {
        if (filterdData) {
            setLocalGrammarData(filterdData.filter(x => x.type === selectTab));
        }

    }, [filterdData, selectTab])




    return (
        <div style={{ marginTop: '4rem' }}>

            <NewWrapper>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem', padding: '0px 10px' }}>
                    <div>
                        <input type="text" placeholder="Search Word" value={inputValue} style={{ padding: '0 10px', height: '30px' }} onChange={(e) => {
                            setInputValue(e.target.value)
                            _searchWord(e.target.value)
                        }} />

                        {labelType && labelType.length > 0 && labelType.map((item, index) => {
                            return <span>&nbsp; &nbsp; {item.type} {(index == labelType.length - 1) ? '' : ','}</span>
                        })}
                    </div>
                    <Button primary={true} onClick={() => {
                        showPopup(undefined, undefined, enums.default.popupType.element, AddData);
                    }}>Add New</Button>
                </div>
                <Wrapper>
                    <EditorInnerWrapper>
                        <TabSlider acceptValue={acceptValue} />
                        <TableWrapper>
                            {localgrammardata && localgrammardata !== undefined &&

                                <table className="table">
                                    <thead className="thead">
                                        <tr>
                                            <th>Word</th>
                                            <th>Type</th>
                                            <th>Hints</th>
                                            <th>Context</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody style={{ alignItems: 'center' }}>
                                        {localgrammardata.map((item, index) => {
                                            return (
                                                <Suspense fallback={<p>Loading</p>}>
                                                    <TableRow key={"word_row" + index} item={item} _delete_row={_delete_row} />
                                                </Suspense>
                                            )
                                        })
                                        }

                                    </tbody>
                                </table>
                            }
                            {!localgrammardata && <p style={{ textAlign: 'center', marginTop: '5rem' }}>Loading...</p>}
                        </TableWrapper>
                    </EditorInnerWrapper>
                </Wrapper>
            </NewWrapper>

        </div>
    )
}



const mapStateToProps = (state) => ({
    StoreNfGrammar: state.NfGrammarReducer.StoreNfGrammar,
    DeleteNfGrammar: state.NfGrammarReducer.DeleteNfGrammar
})


export default withRouter(connect(mapStateToProps, { get_Nf_Grammar, delete_Nf_Grammar })(Index))
