import styled from 'styled-components';

export const Wrapper = styled.div`
    width: '100%';

    .while_loading{
        text-align: center;
        margin-top: 5rem;
        font-weight: 500;
    }
    
    .schema_block{

        margin: 1rem 0px;

        label{
            display: block;
            margin-bottom: .3rem;
            font-size: 1.2rem;
            font-weight: 400;
            color: #5a5757;
        }

        select{
            height: 2.1rem;
            padding: .5rem;
            border-radius: 3px;
            background-color: #fff;
            font-size: 1rem;
            width: 10rem;
        }
    }

    .normal_icon{
        transform: rotate(270deg)
    }
    /* .rotate{
        transform: rotate()
    } */
`;


export const LeftSection = styled.div`

    width: 20rem;
    max-height: calc(100vh - 175px);
    height: calc(100vh - 175px);
    overflow: auto;
    background-color: #f0f4f7;

    .tbl_title{
        font-size: .9rem;
        font-weight: 600;
        text-transform: uppercase;
        margin: .5rem 1rem;
    }
    &::-webkit-scrollbar {
        background-color:red;
        width:2px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 2px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 2px;
    }


    .tbl_name{
        font-size: 1.1rem;
        padding: .3rem 0px;
        padding-left: 1rem;
        font-weight: 500;
        box-sizing: border-box;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
        /* margin-bottom: .4rem; */
        color: #222;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .tbl_name_no_wrap{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .column_list{
        margin: .5rem 0px;
        list-style: none;
        padding-left: 1rem;
        max-height: 25rem;
        overflow-y: auto;

        &::-webkit-scrollbar {
            background-color:red;
            width:2px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 2px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 2px;
        }

        li{
            font-size: .8rem;
            line-height: 1.5rem;
            cursor: pointer;
        }
    }

    .column_name_title{
       margin: 0px;
        font-size: .9rem;
        font-weight: 600;
        color: #444;
    }


    .column_sorting{
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        .rotate_180deg{
            transform: rotate(90deg);
        }
    }
`;


export const RightSection = styled.div`

    width: calc(100% - 20rem);
    padding-left: .1rem;
    box-sizing: border-box;





`;