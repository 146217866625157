import React, { useEffect } from 'react';
import ReactJson from 'react-json-view';
import { connect } from 'react-redux';
import styled from 'styled-components';
import CloseSvg from '../../svg/close';
import { getDirectoryFileData } from '../../../actions/etl';



const MainWrapper = styled.div`
    width: 56.429rem;
    height: 31.214rem;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.25);
    border: solid 5px rgb(234,234,234);
    background-color: rgb(255,255,255);
    padding: 1.643rem 1.429rem;
    overflow-y: auto;
    overflow-x: hidden;

    h1{
        margin: 0px;
        text-transform: capitalize;
        font-size: 1.5rem;
        text-align: center;
        margin: auto;
    }
`;


const FileDataViewer = (props) => {

    const { option, popupKey, close, data, fileData, getDirectoryFileData } = props;
    
    const { file_name, file_path, is_main_dir } = data;


    useEffect(() => {
        if (file_name, file_path) {
            getDirectoryFileData(file_path, file_name, is_main_dir)
        }
    }, [file_name, file_path])


    return (
        <MainWrapper>
            <div style={{
                position: "sticky",
                top: '-30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                backgroundColor: 'white',
                height: '50px',
                marginTop: '-30px',
                zIndex: 3
            }}>
                <h1>{file_name ? file_name : null}</h1>
                <div
                    title="close"
                    onClick={() => {
                        close(option, popupKey)
                    }}

                    style={{
                        cursor: 'pointer'
                    }}
                >
                    <CloseSvg size="1rem" height="1rem" />
                </div>
            </div>
            {
                fileData && fileData && fileData.length > 0 ?
                    <ReactJson src={JSON.parse(fileData)} />
                    :
                    undefined
            }
        </MainWrapper>
    )
}



const mapStateToProps = state => {

    const { fileData } = state.EtlReducer;

    return { fileData };
};

export default connect(mapStateToProps, { getDirectoryFileData })(FileDataViewer);
