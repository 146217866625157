import * as actionTypes from '../action-types/connection';
import { dispatchAction, simpleDispatch_post, simpleDispatch_get, showError } from './helper';
import { constants } from '../utils/constants';
import * as loaderTypes from '../action-types/loader';




export const save_connection = (info) => async dispatch => {

    let temp_info = info;
    temp_info.client_id = constants.CLIENT_ID
    let config = {
        "info": temp_info
    }
    const url = constants.END_POINTS.API + constants.END_POINTS.CLIENT_INFO.POINT + constants.END_POINTS.CLIENT_INFO.SAVE_CLIENT_INFO;

    await simpleDispatch_post(dispatch, url, config, actionTypes.SAVE_CONNECTION, undefined, undefined, { message: 'Connection Saved Successfully' });
}



export const delete_db_info = (id) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.CLIENT_INFO.POINT + constants.END_POINTS.CLIENT_INFO.DELETE_DB_INFO;
    await simpleDispatch_post(dispatch, url, {db_id: id}, 'DELETED_DB_ID', undefined, undefined, { message: 'DB info deleted Successfully' });
}





export const test_connection = (info) => async dispatch => {
    try {
        dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);

        let temp_info = info;
        temp_info.client_id = constants.CLIENT_ID
        let config = {
            "info": temp_info
        }

        const url = constants.END_POINTS.API + constants.END_POINTS.CLIENT_INFO.POINT + constants.END_POINTS.CLIENT_INFO.TEST_CONNECTION;
        await simpleDispatch_post(dispatch, url, config, actionTypes.TEST_CONNECTION_SUCCESS, undefined, undefined, { message: 'Connected successfully' });

        // const data = await post(url, config)
        // if (data && data.code === 200) {
        //     dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);

        //     dispatchAction(dispatch, actionTypes.TEST_CONNECTION_SUCCESS, data, undefined, undefined)
        // } else {
        //     const error = {}
        //     error.message = data.message;
        //     throw new Error(error);
        // }
    } catch (error) {
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        dispatchAction(dispatch, actionTypes.TEST_CONNECTION_FAILED, undefined, undefined, undefined)
        showError(dispatch, error);
    }


}


export const get_db_info = (db_type) => async dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_DB_INFO_SUCCESS)
    const url = constants.END_POINTS.API + constants.END_POINTS.CLIENT_INFO.POINT + constants.END_POINTS.CLIENT_INFO.GET_DB_INFO + `?db_type=${db_type}`;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_DB_INFO_SUCCESS)
}



export const get_table_of_db = (config_id) => async (dispatch) => {
    let post_data = {
        database_id: config_id
    }
    dispatchAction(dispatch, actionTypes.CLEAR_DB_OF_TABLE_SUCCESS)
    const url = constants.END_POINTS.API + constants.END_POINTS.DB_INFO.POINT + constants.END_POINTS.DB_INFO.GET_TABLE_OF_DB;
    await simpleDispatch_post(dispatch, url, post_data, actionTypes.GET_DB_OF_TABLE_SUCCESS, true, undefined);
}



export const get_table_and_columns_of_db = (config_id) => async (dispatch) => {

    let post_data = { db_ids: config_id }

    dispatchAction(dispatch, actionTypes.CLEAR_DB_OF_TABLE_SUCCESS)
    const url = constants.END_POINTS.API + constants.END_POINTS.DB_INFO.POINT + constants.END_POINTS.DB_INFO.GET_TABLE_AND_COLUMNS_OF_DB;
    await simpleDispatch_post(dispatch, url, post_data, actionTypes.GET_TABLE_AND_COLUMNS_OF_DB, true, undefined);
}


export const get_table_data = (config) => async (dispatch) => {
    dispatchAction(dispatch, actionTypes.CLEAR_DB_OF_TABLE_DATA)
    const url = constants.END_POINTS.API + constants.END_POINTS.DB_INFO.POINT + constants.END_POINTS.DB_INFO.GET_TABLE_DATA;
    await simpleDispatch_post(dispatch, url, config, actionTypes.GET_DB_OF_TABLE_DATA_SUCCESS, true, undefined);
}



export const clearTableNameAndTableData = () => async (dispatch) => {
    dispatchAction(dispatch, actionTypes.CLEAR_DB_OF_TABLE_DATA)
    dispatchAction(dispatch, actionTypes.CLEAR_DB_OF_TABLE_SUCCESS)

}


export const getSqlQuery = (reportId) => async (dispatch) => {

    const url = constants.END_POINTS.API + constants.END_POINTS.DB_INFO.POINT + constants.END_POINTS.DB_INFO.GET_SQL_QUERY + `?reportId=${reportId}`;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_SQL_QUERY_SUCCESS)


}

// GET_SQL_QUERY_SUCCESS