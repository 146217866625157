export const formatSQLQuery = (query) => {
    // Regular expressions for basic SQL keywords
    const keywords = [
        "SELECT", "FROM", "WHERE", "GROUP BY", "ORDER BY", "INSERT INTO",
        "VALUES", "UPDATE", "SET", "DELETE FROM", "JOIN", "INNER JOIN",
        "LEFT JOIN", "RIGHT JOIN", "FULL JOIN", "ON", "AS", "AND", "OR",
        "LIMIT", "OFFSET", "CREATE TABLE", "DROP TABLE", "ALTER TABLE",
        "ADD", "CREATE INDEX", "DROP INDEX"
    ];

    // Escape keywords for regex and join them into a single pattern with word boundaries
    const keywordPattern = new RegExp(`\\b(${keywords.join('|')})\\b`, 'gi');

    // Split the query by newlines to process each line
    let formattedQuery = query?.split('\n')?.map(line => {
        if (line?.trim()?.startsWith('--')) {
            return line;
        }else{
            return line
                .replace(keywordPattern, '\n$1') // Place each keyword on a new line
                .replace(/\s+/g, ' ') // Reduce multiple spaces to a single space
                .replace(/\n\s+/g, '\n') // Remove leading spaces after a newline
                .trim() // Remove leading and trailing whitespace
                .replace(/\b(SELECT|FROM|WHERE|GROUP BY|ORDER BY|JOIN|ON|VALUES|SET|INSERT INTO|DELETE FROM)\b\s*/gi, '\n$1\n\t')
                .replace(/,\s*/g, ',\n\t') // Move commas to a new line with indentation
                .replace(/\s+\(/g, ' (') // Fix spacing before parentheses
                .replace(/\)\s+/g, ') ') // Fix spacing after parentheses
                .replace(/;\s*/g, ';\n')
        }})?.join('\n');

    return formattedQuery;
};

// export const formatSQLQuery = (query) => {
//     const filteredOutCommentedLines = query?.split('\n').filter(line => line.trim().startsWith('--'));
//     const filteredLines = query?.split('\n').filter(line => !line.trim().startsWith('--'));
//     const processedInput = filteredLines?.join('\n');
//     // Regular expressions for basic SQL keywords
//     const keywords = [
//         "SELECT", "FROM", "WHERE", "GROUP BY", "ORDER BY", "INSERT INTO",
//         "VALUES", "UPDATE", "SET", "DELETE FROM", "JOIN", "INNER JOIN",
//         "LEFT JOIN", "RIGHT JOIN", "FULL JOIN", "ON", "AS", "AND", "OR",
//         "LIMIT", "OFFSET", "CREATE TABLE", "DROP TABLE", "ALTER TABLE",
//         "ADD", "CREATE INDEX", "DROP INDEX"
//     ];

//     // Escape keywords for regex and join them into a single pattern with word boundaries
//     const keywordPattern = new RegExp(`\\b(${keywords.join('|')})\\b`, 'gi');

//     // Split the query into lines for better formatting
//     let formattedQuery = processedInput
//         .replace(keywordPattern, '\n$1') // Place each keyword on a new line
//         .replace(/\s+/g, ' ') // Reduce multiple spaces to a single space
//         .replace(/\n\s+/g, '\n') // Remove leading spaces after a newline
//         .trim(); // Remove leading and trailing whitespace

//     // Additional formatting for SELECT statements and others
//     formattedQuery = formattedQuery
//         .replace(/\b(SELECT|FROM|WHERE|GROUP BY|ORDER BY|JOIN|ON|VALUES|SET|INSERT INTO|DELETE FROM)\b\s*/gi, '\n$1\n\t')
//         .replace(/,\s*/g, ',\n\t') // Move commas to a new line with indentation
//         .replace(/\s+\(/g, ' (') // Fix spacing before parentheses
//         .replace(/\)\s+/g, ') ') // Fix spacing after parentheses
//         .replace(/;\s*/g, ';\n'); // Place each query on a new line

//         return formattedQuery;
//         return filteredOutCommentedLines+ '\n' + formattedQuery
// };

export const syncScrollAfterFormat = ({ textAreaRef, highlightedRef, syncScroll }) => {
    const textarea = textAreaRef.current;
    const { scrollHeight, clientHeight, selectionStart } = textarea;

    // Get the line number where the cursor is located
    const caretLine = textarea.value.substr(0, selectionStart).split("\n").length;
    const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight, 10);

    // Calculate the desired scroll position based on the cursor's line number
    const scrollToPosition = caretLine * lineHeight - clientHeight;

    // Ensure the textarea scrolls to the cursor position if the new content exceeds the viewport
    if (scrollHeight > clientHeight) {
        textarea.scrollTop = Math.max(scrollToPosition, 0);
        highlightedRef.current.scrollTop = textarea.scrollTop;
    }

    // Sync the scroll positions and heights of both the textarea and the custom div
    syncScroll({ target: textarea });
};

export const findAliasesBCP = (input) => {
    // Split input into queries, handling multiple queries separated by semicolons
    const queries = input?.replace(/[\n\t]+/g, ' ')?.split(';')?.map(query => query.trim()).filter(Boolean);

    // Regular expression to match table names with their aliases, including join conditions
    const regex = /\b([a-zA-Z_][\w.]*)\s+([a-zA-Z_][\w]*)\b(?![\s\w])|(?:from|join|inner\s+join|left\s+join|right\s+join|full\s+join)\s+([a-zA-Z_][\w.]*)\s+([a-zA-Z_][\w]*)/gi;

    return queries.map(query => {
        const aliases = [];
        let match;

        // Use regex to find matches in the query
        while ((match = regex.exec(query)) !== null) {
            const table = match[1] || match[3]; // Extract table name
            const alias = match[2] || match[4]; // Extract alias

            // Check if both table and alias are valid and not SQL keywords
            if (table && alias) {
                if (!["select", "from", "inner", "join", "on", "left", "right", "outer", "cross", "natural"].includes(table.toLowerCase()) &&
                    !["select", "from", "inner", "join", "on", "left", "right", "outer", "cross", "natural"].includes(alias.toLowerCase())) {
                    aliases.push({
                        table,
                        alias
                    });
                }
            }
        }

        return {
            query,
            aliases
        };
    });
};

// work for query without schema
export const findAliases2 = (input) => {
    // Split input into queries, handling multiple queries separated by semicolons
    const queries = input?.replace(/[\n\t]+/g, ' ')?.split(';')?.map(query => query.trim()).filter(Boolean);

    // Updated regular expression to match table names with their aliases, including join conditions and AS keyword
    const regex = /\b([a-zA-Z_][\w.]*)\s+(?:AS\s+)?([a-zA-Z_][\w]*)\b(?![\s\w])|(?:from|join|inner\s+join|left\s+join|right\s+join|full\s+join)\s+([a-zA-Z_][\w.]*)\s+(?:AS\s+)?([a-zA-Z_][\w]*)/gi;

    return queries.map(query => {
        const aliases = [];
        let match;

        // Use regex to find matches in the query
        while ((match = regex.exec(query)) !== null) {
            const table = match[1] || match[3]; // Extract table name
            const alias = match[2] || match[4]; // Extract alias

            // Check if both table and alias are valid and not SQL keywords
            if (table && alias) {
                if (!["select", "from", "inner", "join", "on", "left", "right", "outer", "cross", "natural", "full"].includes(table.toLowerCase()) &&
                    !["select", "from", "inner", "join", "on", "left", "right", "outer", "cross", "natural", "full"].includes(alias.toLowerCase())) {
                    aliases.push({
                        table,
                        alias
                    });
                }
            }
        }

        return {
            query,
            aliases
        };
    });
};

const sqlFunctions = [
    'COUNT',
    'SUM',
    'AVG',
    'MIN',
    'MAX',
    'DATE',
    'YEAR',
    'MONTH',
];

export const findAliases = (input) => {
    // Split input into queries, handling multiple queries separated by semicolons
    const queries = input?.replace(/[\n\t]+/g, ' ')?.split(';')?.map(query => query.trim()).filter(Boolean);

    // Updated regular expression to handle both schema-qualified and non-schema-qualified table names
    const regex = /\b([a-zA-Z_][\w.]*\.[a-zA-Z_][\w.]*)\s+(?:AS\s+)?([a-zA-Z_][\w]*)\b|\b(?!from\b|select\b|where\b|join\b|on\b|distinct\b)([a-zA-Z_][\w]*)\s+(?:AS\s+)?([a-zA-Z_][\w]*)\b/gi;

    return queries.map(query => {
        const aliases = [];
        let match;

        // Use regex to find matches in the query
        while ((match = regex.exec(query)) !== null) {
            const table = match[1] || match[3]; // Extract table name (schema-qualified or not)
            const alias = match[2] || match[4]; // Extract alias

            // Check if both table and alias are valid
            if (table && alias && !sqlFunctions?.includes(table?.toLocaleUpperCase())) {
                aliases.push({
                    table,
                    alias
                });
            }
        }

        return {
            query,
            aliases
        };
    });
};

const removeNewLinesAndTabs = (str) => {
    return str.replace(/[\n\t]+/g, ''); // Remove all new lines and tabs
};



