import React from 'react'
import styled from 'styled-components'
import { themes } from '../../theme/theme.config'
import { formatValueByDataType, getSelectedTheme } from '../../utils';
import PdfSvg from '../svg/pdf';
import CsvSvg from '../svg/csv';
import Xlsx from '../svg/xlsx';
import { CSVLink } from "react-csv";
import { generatePdf } from '../../utils';
import { connect } from 'react-redux';
import { raiseExcelDownloadRequest } from '../../actions/table';

const selected = getSelectedTheme();


const { righterMenuConfig } = themes[selected]
const { menu } = righterMenuConfig


const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 250px;
`;

const MenuWrapper = styled.div`
    width: 70px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 5px;
    flex-direction: column;
    background: ${props => props.active ? "#6189de2e" : '#fff'};
    box-shadow: ${props => props.active ? '1px 1px 6px 2px #cccccc75' : '0 0 0'};

    &:hover{
        box-shadow: 1px 1px 6px 2px #cccccc75;
        div{
            font-weight: 500;
        }
    }
`;

const Label = styled.div`
    font-size: 15px;
    margin-top: 6px;
    color: #222;
    font-weight: 300;
    text-align: center;
`;


const DownloadOption = (props) => {

    const { reportTitle, filters, expandedSection, tableDataMode, raw_data, raw_axis } = props;

    const _file_name = reportTitle && reportTitle.length > 0 ? `${reportTitle}` : 'nf-report';

    const { raw_data_grand_total = {}, pivot_data_grand_total = {} } = props;

    const is_chart_expanded = (expandedSection && expandedSection.section_name === 'chart');
    const is_table_expanded = (expandedSection && expandedSection.section_name === 'table');

    const __download_mode__ = is_table_expanded ? "table" : "chart";
    const section_id_for_print = is_chart_expanded ? "chart_section_wrapper" : is_table_expanded ? "table_section_wrapper" : 'normal_view_slide_wrapper'
    const data_for_print = (tableDataMode === "pivot_data") ? props?.pivot_data : raw_data;
    const x_keys_for_print = (tableDataMode === "pivot_data") ? props?.pivot_xac : raw_axis.xac;
    const y_keys_for_print = (tableDataMode === "pivot_data") ? props?.pivot_yac : raw_axis.yac;
    const grand_total_for_print = (tableDataMode === "pivot_data") ? pivot_data_grand_total["grand_total_sum_column"] : raw_data_grand_total["grand_total_sum_column"];
    const column_formatting = (tableDataMode === "pivot_data") ? props.pivot_column_aligments : props.raw_column_aligments;


    const create_header_for_print_csv = () => {

        const header = []
        const temp_header = [...(x_keys_for_print || []), ...(y_keys_for_print || [])].forEach((h) => {
            header.push({
                key: h,
                label: h?.split("_nfx_")?.join(" "),
                displayValue: h?.split("_nfx_")?.join(" ")
            })
        });

        return (header || [])
    }


    const get_formated_cell = (cell_key, value) => {

        const column_style = column_formatting?.[cell_key];
        // const cell_aligment_type = column_style?.['alignment'] || column_style?.["aligment"];
        const cell_data_type = column_style?.['type']
        const currency_type = column_style?.['currency_type'];
        const num_format_type = column_style?.['num_format_type'];
        const use_decimal = column_style?.['use_decimal'];
        const use_percent = column_style?.['use_percent'];
        const other_format_config = column_style?.["other_format_config"];
        const finalDatatoShow = value // (cellValue + '') === '0' ? '-' : cellValue;
        const afterFormatted = formatValueByDataType(finalDatatoShow, cell_data_type, 'table', undefined, undefined, undefined, currency_type, num_format_type, use_decimal, use_percent, other_format_config);

        return afterFormatted;
    }



    /**
     * 
     * @returns this code is used for formate the plain data
     */
    const get_csv_data_with_formatted = () => {

        const csv_data = [];

        data_for_print && data_for_print?.length > 0 && data_for_print.map((row) => {
            const row_data = {};
            if (row && Object.keys(row)?.length > 0) {
                // let's formate the data like, number, currency, etc....
                Object.keys(row).map((k) => {
                    const new_data = get_formated_cell(k, row[k])
                    row_data[k] = new_data;
                })
            }
            csv_data.push(row_data)
        })
        return csv_data;
    };




    return (
        <Wrapper>
            <MenuWrapper title="CSV">
                <CSVLink data={get_csv_data_with_formatted()} headers={create_header_for_print_csv()} filename={`${_file_name}.csv`}>
                    <CsvSvg
                        size="24px"
                        height="24px"
                        color={menu.color.icon}
                    />
                    <Label>CSV</Label>
                </CSVLink>
            </MenuWrapper>

            <MenuWrapper title="PDF"
                onClick={() => {
                    console.log('ok goo=====>', __download_mode__, section_id_for_print, data_for_print?.length, x_keys_for_print, y_keys_for_print)
                    generatePdf(
                        __download_mode__,
                        'download',
                        reportTitle,
                        section_id_for_print,
                        undefined,
                        _file_name,
                        undefined,
                        x_keys_for_print,
                        y_keys_for_print,
                        data_for_print,
                        props.header,
                        column_formatting,
                        filters,
                        grand_total_for_print,
                        undefined,
                        // [{ element_id_to_print: section_id_for_print, type: __download_mode__ }, { element_id_to_print: section_id_for_print, type: __download_mode__ }]
                    )
                }}
            >
                <PdfSvg
                    size="24px"
                    height="24px"
                    color={menu.color.icon}
                />
                <Label>Pdf</Label>
            </MenuWrapper>

            <MenuWrapper title="XLS"
                onClick={() => {
                    props.raiseExcelDownloadRequest((props.reportId || props.id))
                }}
            >
                <Xlsx
                    size="24px"
                    height="24px"
                    color={menu.color.icon}
                />
                <Label>Xls</Label>
            </MenuWrapper>

        </Wrapper>
    )
}


const mapStateToProps = (state, props) => {
    const expanded_section_cache = state.helperReducer.expanded_section_cache;
    const table_data_mode_cache = state.helperReducer.tableDataMode;

    const tableDataMode = table_data_mode_cache && table_data_mode_cache[props.id]
    const expandedSection = expanded_section_cache && expanded_section_cache[props.id]
    return { expandedSection, tableDataMode }
}


export default connect(mapStateToProps, { raiseExcelDownloadRequest })(DownloadOption)
