import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { store_opr_credentials } from '../../../actions/account';
import { getReportByReportIdForOpenRoute } from '../../../actions/report';
import { generateUniqueKey, getParamByName } from '../../../utils/'
import Report from "../../report-wrapper";

const ShareableReport = props => {

    const { shareReport } = props;

    const { location } = props.history;
    const { search } = location;


    const [reportState, setReportState] = useState({
        question: undefined,
        scheduleReportId: undefined,
        id: undefined,
        db_info_id: undefined
    });

    useEffect(() => {
        const { location } = props.history;
        const { search } = location;

        const client_id = getParamByName(search, 'client_id');
        const insight_id = getParamByName(search, 'schedule_report_id');
        const question = getParamByName(search, 'question');
        const token = getParamByName(search, 'token');

        // setTimeout(() => {
        //     props.getReportByReportIdForOpenRoute(token, insight_id, client_id);
        // }, 500);
    }, [])


    // ====


    useEffect(() => {

        const client_id = getParamByName(search, 'client_id');
        const insight_id = getParamByName(search, 'schedule_report_id');
        const question = getParamByName(search, 'question');
        const token = getParamByName(search, 'token');
        const report_type = getParamByName(search, 'report_type');
        const print_type = getParamByName(search, 'print_type');
        const file = getParamByName(search, 'file');

        setReportState({
            client_id: client_id,
            insight_id: insight_id,
            id: generateUniqueKey("open-rout_id"),
            question: question,
            token: token,
            report_type: report_type,
            scheduleReportId: insight_id,
            print_type: print_type,
            file: file

        })

    }, [search])



    useEffect(() => {
        if (shareReport && shareReport.length > 0) {
            const sr = shareReport[0]
            if (sr && sr.report_items && sr.report_items.length > 0) {
                const reportItem = sr.report_items[0];

                const { display_columns, id, db_info_id } = reportItem;

                // setReportState({
                //     question: display_columns.join(','),
                //     scheduleReportId: id,
                //     id: id || "test_id",
                //     db_info_id
                // });
            }
        }
    }, [shareReport])


    return (
        <>
            <div style={{ minHeight: 'calc(100vh - 10px)', maxHeight: 'calc(100vh - 10px)', }}>
                {
                    (reportState?.question || reportState?.scheduleReportId) ? <Report
                        share_mode={true}
                        db_info_id={reportState.db_info_id}
                        question={reportState.question}
                        scheduleReportId={reportState.scheduleReportId}
                        id={reportState.scheduleReportId || "generateeddd"}
                        reportId={reportState.scheduleReportId || "generateeddd"}
                        report_type={reportState.report_type}
                        width={props.width}
                        height={props.height}
                        is_print_report={true}
                        file_name={reportState?.file}
                        print_type={reportState?.print_type}
                    /> : undefined
                }
            </div>
        </>

    )
};


const mapStateToProps = state => {

    const shareReport = state.reportReducer.shareReportData;

    return { shareReport };

};


export default withRouter(connect(mapStateToProps, { store_opr_credentials, getReportByReportIdForOpenRoute })(ShareableReport));

