import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '../hoc/button';
import { connect } from 'react-redux';
import { save_connection, test_connection, get_db_info } from '../../actions/connection';
import * as actionTypes from '../../action-types/connection'
import TextBox from '../hoc/textBox';
import { themes } from '../../theme/theme.config';
import { getSelectedTheme } from '../../utils';
import { color } from 'd3';
const selected = getSelectedTheme();
const { connectStyle } = themes[selected];




const Wrapper = styled.div`
    background: ${connectStyle.color.popupBg};
    width: 510px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    overflow: hidden;
`;


const ConnectionStatus = styled.div`
    font-size: 1rem;
    color: green;
    margin-left: 20px;
    margin-top: 10px;
    text-transform: capitalize;
`;


const Header = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 1rem;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.6;
    box-sizing: border-box;
    padding: 0px 15px;
    letter-spacing: 0.0075em;
    background-color: #6785ff;
    justify-content: center;
    font-weight: 600;
    color: #fff;

`;


const InnerContent = styled.div`
    width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    // border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .input_row{
        display: flex;
        width: 100%;
        margin-bottom: 15px;
        flex-direction: column;
        position: relative;
        padding-top: 10px;
    }
`;




const fields_json = {

    "mysql": [
        {
            label: "Connection Name",
            value_key: 'connection_name',
            type: "text"
        },
        {
            label: "Server",
            value_key: 'host',
            type: "text",
            is_required: true,

        },
        {
            label: "Port",
            value_key: 'port',
            type: "text",
            is_required: false,

        },
        {
            label: "Database Name",
            value_key: 'database_name',
            type: "text",
            is_required: true,
        },
        {
            label: "User Id",
            value_key: 'user_name',
            type: "text"
        },
        {
            label: "Password",
            value_key: 'pwd',
            type: "password"
        },
    ],

    "snowFlake": [
        {
            label: "Connection Name",
            value_key: 'connection_name',
            type: "text"
        },
        {
            label: "Account",
            value_key: 'host',
            type: "text",
            is_required: true,

        },
        {
            label: "Warehouse",
            value_key: 'warehouse',
            type: "text",
            is_required: true,
            is_other_info: true,

        },
        {
            label: "Schema",
            value_key: 'schema',
            type: "text",
            is_required: true,
            is_other_info: true,

        },
        {
            label: "Database Name",
            value_key: 'database_name',
            type: "text",
            is_required: true,
        },
        {
            label: "Region",
            value_key: 'region',
            type: "text",
            is_required: true,
            is_other_info: true,

        },


        {
            label: "User Id",
            value_key: 'user_name',
            type: "text"
        },
        {
            label: "Password",
            value_key: 'pwd',
            type: "password"
        },
    ],

    "elasticsearch": [
        {
            label: "Connection Name",
            value_key: 'connection_name',
            type: "text"
        },
        {
            label: "Server",
            value_key: 'host',
            type: "text",
            is_required: true,

        },
        {
            label: "Port",
            value_key: 'port',
            type: "text",
            is_required: false,

        },
        {
            label: "Database Name",
            value_key: 'database_name',
            type: "text",
        },
        {
            label: "User Id",
            value_key: 'user_name',
            type: "text"
        },
        {
            label: "Password",
            value_key: 'pwd',
            type: "password"
        },
    ],

    "json": [
        {
            label: "Connection Name",
            value_key: 'connection_name',
            type: "text"

        },
        {
            label: "Host",
            value_key: 'host',
            type: "text"

        },
    ],
    "hive": [
        {
            label: "Connection Name",
            value_key: 'connection_name',
            type: "text"
        },
        {
            label: "Server",
            value_key: 'host',
            type: "text",
            is_required: true
        },
        {
            label: "Port",
            value_key: 'port',
            type: "text"
        },
        {
            label: "Database Name",
            value_key: 'database_name',
            type: "text",
            is_required: true
        },
        {
            label: "User Id",
            value_key: 'user_name',
            type: "text"
        },
        {
            label: "Password",
            value_key: 'pwd',
            type: "password"
        },
    ],
    "big_query": [
        {
            label: "Connection Name",
            value_key: 'connection_name',
            type: "text"
        },
        {
            label: "Connection File Path",
            value_key: 'host',
            type: "text",
            is_required: true
        },
        {
            label: "Port",
            value_key: 'port',
            type: "text"
        },
        {
            label: "Project Id",
            value_key: 'project_id',
            type: "text",
            is_required: true
        },
        {
            label: "DataSet Id",
            value_key: 'database_name',
            type: "text",
            is_required: true
        },
        {
            label: "User Id",
            value_key: 'user_name',
            type: "text"
        },
        {
            label: "Password",
            value_key: 'pwd',
            type: "password"
        },
    ],

}



const step_2_hive_meta = [
    {
        label: "Meta Server",
        value_key: 'meta_host',
        type: "text",
        is_required: true,
    },
    {
        label: "Port",
        value_key: 'meta_port',
        type: "text",
        is_required: false,

    },
    {
        label: "Meta Database Name",
        value_key: 'meta_database_name',
        type: "text",
        is_required: true,

    },
    {
        label: "User Id",
        value_key: 'meta_user_name',
        type: "text"
    },
    {
        label: "Password",
        value_key: 'meta_pwd',
        type: "password"
    },
]


const ConnectionPopup = (props) => {

    const [dbObject, setDbObject] = useState({
        connection_name: '',
        host: '',
        user_name: '',
        pwd: '',
        database_name: '',
        meta_host: '',
        meta_database_name: '',
        meta_user_name: '',
        meta_pwd: ''
    })



    const [status, setStatus] = useState(undefined)

    const { close, option, popupKey, data } = props;
    const { db_type, item } = data;

    const [step, set_step] = useState(1)



    useEffect(() => {
        setDbObject(item)
    }, [item])


    const _onChangeFunction = (value, key, is_other_info = false) => {

        let temp_db_obj = dbObject ? { ...dbObject } : {};

        if (is_other_info) {
            if (!temp_db_obj['other_information']) {
                temp_db_obj['other_information'] = {};
            }
            temp_db_obj['other_information'][key] = value;
        } else {
            temp_db_obj[key] = value;
        }

        if (!temp_db_obj['modefied_keys']) temp_db_obj['modefied_keys'] = {};
        temp_db_obj['modefied_keys'][key] = true;

        setDbObject(temp_db_obj)
    }


    const save_conn = (type) => {

        const info = dbObject ? { ...dbObject } : {};

        if (!dbObject?.id) info.db_type = db_type;

        info.is_created = true;

        if (db_type === "hive") info.meta_db_type = "mysql"

        if (type === 'save') {

            console.log("info", info)
            props.save_connection(info)
            setTimeout(() => {
                props.get_db_info()
                close(option, popupKey)
            }, 1000)

        } if (type === 'test') {


            const test_info = { ...info }

            if (step == 2 && db_type === 'hive') {

                test_info.host = info?.meta_host;
                test_info.database_name = info?.meta_database_name;
                test_info.user_name = info?.meta_user_name;
                test_info.pwd = info?.meta_pwd;
                test_info.port = info?.meta_port;
                test_info.db_type = "mysql"; /// for now we are keeping it default mysql
            }

            if (test_info.host) {
                props.test_connection(test_info)

            } else {
                alert("Please Fill The Required Fields")
            }
        }
    }


    useEffect(() => {
        if (props.type && props.type === actionTypes.TEST_CONNECTION_SUCCESS) {
            setStatus("Connection SuccessFul")
            setTimeout(() => {
                setStatus(undefined)
            }, 5000)
        } else {
            setStatus(undefined)
        }
    }, [props.type])


    const field_data = fields_json?.[db_type] || fields_json['mysql'];

    const is_next_btn_enable = dbObject?.['host']// && dbObject?.['database_name'];




    return (
        <Wrapper>
            <Header>
                {(item && item.id) ? "Edit Connection" : 'Setup Connection'}
            </Header>
            <InnerContent>


                {step == 1 && field_data && field_data.length > 0 && field_data.map((field) => {
                    const is_other = field?.is_other_info;
                    return (
                        <div className="input_row">
                            <TextBox
                                label={field?.label}
                                type={field?.type || 'text'}
                                // isRequired={field.is_required}
                                value={is_other ? dbObject?.['other_information']?.[field?.value_key] : dbObject && dbObject[field?.value_key]}
                                onChange={(e) => _onChangeFunction(e.target.value, field?.value_key, is_other)}
                            />
                        </div>
                    )
                })}

                {step == 2 && step_2_hive_meta && step_2_hive_meta.length > 0 && step_2_hive_meta.map((field) => {
                    return (
                        <div className="input_row">
                            <TextBox
                                label={field?.label}
                                // isRequired={field.is_required}
                                type={field?.type || 'text'}
                                value={dbObject && dbObject[field?.value_key]}
                                onChange={(e) => _onChangeFunction(e.target.value, field?.value_key)}
                            />
                        </div>
                    )
                })}
            </InnerContent>


            <div style={{ width: "93%", margin: 'auto', marginBottom: '30px', marginTop: '20px', display: 'flex', flexDirection: 'row' }}>

                <Button style={{ background: "#51cc62", color: '#fff' }} mR="10px" disabled={!is_next_btn_enable} onClick={(e) => save_conn('test')} bgColor={'#008000d1'}> Test Connection</Button>

                {/* {status && status.length > 0 && <ConnectionStatus>{status}</ConnectionStatus>} */}

                <div style={{ marginLeft: 'auto' }}>

                    {step == 2 && db_type === "hive" && <Button width="110px" mR="10px" bgColor="#e6e6e6" onClick={() => set_step(1)}>Back</Button>}

                    {step == 1 && db_type === "hive" && <Button disabled={!is_next_btn_enable} onClick={(e) => {

                        set_step(2)

                    }} width="110px" mR="10px" primary={true}>Next</Button>}

                    {step == 2 && db_type === "hive" && <Button onClick={(e) => save_conn('save')} width="110px" mR="10px" primary={true}>Save</Button>}

                    {db_type !== "hive" && <Button style={{ background: "linear-gradient(to right, #3E9EFF, #737DFF)", color: '#fff' }} onClick={(e) => save_conn('save')} width="110px" mR="10px" primary={true}>Save</Button>}

                    <Button style={{ background: "#c8c8ca", color: '#fff' }} width="110px" bgColor="#e6e6e6" onClick={() => close(option, popupKey)}> Cancel</Button>
                </div>
            </div>
        </Wrapper>
    )
}


const mapStateToProps = (state) => ({
    type: state.connectionReducer.type
})


export default connect(mapStateToProps, { save_connection, test_connection, get_db_info })(ConnectionPopup);
