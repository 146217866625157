import React from 'react';

const GraterThen = (props) => {

    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            {...props}
            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 340 570"
            space="preserve"
        >
            <defs>
                <symbol overflow="visible" id="i">
                    <path id="svg_1" d="m37.203,-2.2188c-1.918,0.99219 -3.918,1.7344 -6,2.2344c-2.0742,0.50781 -4.2422,0.76562 -6.5,0.76562c-6.7422,0 -12.086,-1.8789 -16.031,-5.6406c-3.9375,-3.7695 -5.9062,-8.8828 -5.9062,-15.344c0,-6.4688 1.9688,-11.586 5.9062,-15.359c3.9453,-3.7695 9.2891,-5.6562 16.031,-5.6562c2.2578,0 4.4258,0.25781 6.5,0.76562c2.082,0.5 4.082,1.25 6,2.25l0,8.375c-1.9375,-1.3203 -3.8516,-2.2891 -5.7344,-2.9062c-1.875,-0.61328 -3.8516,-0.92188 -5.9219,-0.92188c-3.7305,0 -6.6641,1.1992 -8.7969,3.5938c-2.125,2.3867 -3.1875,5.6719 -3.1875,9.8594c0,4.1797 1.0625,7.4609 3.1875,9.8438c2.1328,2.3867 5.0664,3.5781 8.7969,3.5781c2.0703,0 4.0469,-0.30469 5.9219,-0.92188c1.8828,-0.61328 3.7969,-1.582 5.7344,-2.9062l0,8.39022z" />
                </symbol>
                <symbol overflow="visible" id="d">
                    <path id="svg_2" d="m27.219,-22.094c-0.84375,-0.39453 -1.6875,-0.6875 -2.5312,-0.875c-0.84375,-0.19531 -1.6875,-0.29688 -2.5312,-0.29688c-2.5,0 -4.4219,0.80469 -5.7656,2.4062c-1.3438,1.5938 -2.0156,3.8867 -2.0156,6.875l0,13.984l-9.7188,0l0,-30.375l9.7188,0l0,5c1.2383,-1.9883 2.6641,-3.4375 4.2812,-4.3438c1.625,-0.91406 3.5664,-1.375 5.8281,-1.375c0.32031,0 0.67188,0.01562 1.0469,0.04687c0.38281,0.02344 0.94141,0.07422 1.6719,0.15625l0.0155,8.79736z" />
                </symbol>
                <symbol overflow="visible" id="c">
                    <path id="svg_3" d="m34.984,-15.266l0,2.7656l-22.703,0c0.23828,2.2812 1.0625,3.9922 2.4688,5.125c1.4062,1.1367 3.375,1.7031 5.9062,1.7031c2.0391,0 4.1328,-0.30078 6.2812,-0.90625c2.1445,-0.60156 4.3477,-1.5195 6.6094,-2.75l0,7.4844c-2.3047,0.86719 -4.6055,1.5156 -6.9062,1.9531c-2.293,0.44531 -4.5859,0.67188 -6.875,0.67188c-5.4922,0 -9.7617,-1.3945 -12.812,-4.1875c-3.043,-2.7891 -4.5625,-6.707 -4.5625,-11.75c0,-4.957 1.4922,-8.8516 4.4844,-11.688c2.9883,-2.832 7.1016,-4.25 12.344,-4.25c4.7695,0 8.5859,1.4375 11.453,4.3125c2.875,2.8672 4.3125,6.7031 4.3125,11.516l-0.0008,0.00017zm-9.9844,-3.2344c0,-1.8438 -0.54297,-3.3281 -1.625,-4.4531c-1.0742,-1.125 -2.4766,-1.6875 -4.2031,-1.6875c-1.8867,0 -3.418,0.53125 -4.5938,1.5938c-1.168,1.0547 -1.8984,2.5703 -2.1875,4.5469l12.6094,-0.0001z" />
                </symbol>
                <symbol overflow="visible" id="b">
                    <path id="svg_4" d="m18.281,-13.672c-2.0312,0 -3.5586,0.34375 -4.5781,1.0312c-1.0234,0.6875 -1.5312,1.7031 -1.5312,3.0469c0,1.2305 0.41016,2.1953 1.2344,2.8906c0.82031,0.6875 1.9688,1.0312 3.4375,1.0312c1.8203,0 3.3516,-0.64844 4.5938,-1.9531c1.25,-1.3125 1.875,-2.9531 1.875,-4.9219l0,-1.125l-5.0314,0.0001zm14.828,-3.6562l0,17.328l-9.7969,0l0,-4.5c-1.3047,1.8438 -2.7656,3.1875 -4.3906,4.0312c-1.625,0.83203 -3.6055,1.25 -5.9375,1.25c-3.1484,0 -5.6992,-0.91406 -7.6562,-2.75c-1.9609,-1.832 -2.9375,-4.2109 -2.9375,-7.1406c0,-3.5625 1.2227,-6.1719 3.6719,-7.8281c2.4453,-1.6641 6.2891,-2.5 11.531,-2.5l5.7188,0l0,-0.76562c0,-1.5312 -0.60547,-2.6484 -1.8125,-3.3594c-1.2109,-0.71875 -3.1016,-1.0781 -5.6719,-1.0781c-2.0742,0 -4.0078,0.21094 -5.7969,0.625c-1.793,0.40625 -3.4531,1.0273 -4.9844,1.8594l0,-7.4062c2.0703,-0.5 4.1562,-0.87891 6.25,-1.1406c2.1016,-0.25781 4.2031,-0.39062 6.2969,-0.39062c5.4766,0 9.4297,1.0781 11.859,3.2344c2.4375,2.1562 3.6562,5.668 3.6562,10.531l0.0006,0.00024z" />
                </symbol>
                <symbol overflow="visible" id="a">
                    <path id="svg_5" d="m15.266,-38.984l0,8.6094l10,0l0,6.9531l-10,0l0,12.875c0,1.4062 0.28125,2.3594 0.84375,2.8594s1.6719,0.75 3.3281,0.75l5,0l0,6.9375l-8.3281,0c-3.8359,0 -6.5547,-0.79688 -8.1562,-2.3906c-1.5938,-1.6016 -2.3906,-4.3203 -2.3906,-8.1562l0,-12.875l-4.8281,0l0,-6.9531l4.8281,0l0,-8.6094l9.70305,-0.0001z" />
                </symbol>
                <symbol overflow="visible" id="h">
                    <path id="svg_6" d="m25.328,-25.922l0,-16.266l9.7656,0l0,42.188l-9.7656,0l0,-4.3906c-1.3438,1.793 -2.8203,3.1055 -4.4219,3.9375c-1.6055,0.82031 -3.4688,1.2344 -5.5938,1.2344c-3.7422,0 -6.8125,-1.4844 -9.2188,-4.4531c-2.3984,-2.9766 -3.5938,-6.8047 -3.5938,-11.484c0,-4.6875 1.1953,-8.5156 3.5938,-11.484c2.4062,-2.9688 5.4766,-4.4531 9.2188,-4.4531c2.1016,0 3.9609,0.42188 5.5781,1.2656c1.6133,0.83594 3.0938,2.1367 4.4375,3.9062l0.0001,-0.0009zm-6.4062,19.656c2.082,0 3.6719,-0.75391 4.7656,-2.2656c1.0938,-1.5195 1.6406,-3.7266 1.6406,-6.625c0,-2.8945 -0.54688,-5.0977 -1.6406,-6.6094c-1.0938,-1.5195 -2.6836,-2.2812 -4.7656,-2.2812c-2.0625,0 -3.6406,0.76172 -4.7344,2.2812c-1.0938,1.5117 -1.6406,3.7148 -1.6406,6.6094c0,2.8984 0.54688,5.1055 1.6406,6.625c1.0938,1.5117 2.6719,2.2656 4.7344,2.2656z" />
                </symbol>
                <symbol overflow="visible" id="g">
                    <path id="svg_7" d="m20.828,-6.2656c2.0703,0 3.6562,-0.75391 4.75,-2.2656c1.0938,-1.5195 1.6406,-3.7266 1.6406,-6.625c0,-2.8945 -0.54688,-5.0977 -1.6406,-6.6094c-1.0938,-1.5195 -2.6797,-2.2812 -4.75,-2.2812c-2.0859,0 -3.6836,0.76562 -4.7969,2.2969c-1.1055,1.5234 -1.6562,3.7188 -1.6562,6.5938s0.55078,5.0781 1.6562,6.6094c1.1133,1.5234 2.7109,2.2812 4.7969,2.2812l0,-0.0001zm-6.4531,-19.656c1.332,-1.7695 2.8125,-3.0703 4.4375,-3.9062c1.6328,-0.84375 3.5078,-1.2656 5.625,-1.2656c3.7383,0 6.8047,1.4844 9.2031,4.4531c2.4062,2.9688 3.6094,6.7969 3.6094,11.484c0,4.6797 -1.2031,8.5078 -3.6094,11.484c-2.3984,2.9688 -5.4648,4.4531 -9.2031,4.4531c-2.1172,0 -3.9922,-0.41797 -5.625,-1.25c-1.625,-0.84375 -3.1055,-2.1484 -4.4375,-3.9219l0,4.3906l-9.7188,0l0,-42.188l9.7188,0l0,16.2669z" />
                </symbol>
                <symbol overflow="visible" id="f">
                    <path id="svg_8" d="m0.67188,-30.375l9.7188,0l8.1562,20.609l6.9375,-20.609l9.7031,0l-12.766,33.25c-1.2812,3.375 -2.7773,5.7344 -4.4844,7.0781c-1.7109,1.3516 -3.9648,2.0312 -6.7656,2.0312l-5.6094,0l0,-6.375l3.0312,0c1.6445,0 2.8438,-0.26172 3.5938,-0.78125c0.75,-0.52344 1.332,-1.4648 1.75,-2.8281l0.26562,-0.82812l-13.53082,-31.54683z" />
                </symbol>
                <symbol overflow="visible" id="e">
                    <path id="svg_9" d="m33.266,-39.203l0,8.5625c-2.2188,-0.98828 -4.3867,-1.7383 -6.5,-2.25c-2.1172,-0.50781 -4.1172,-0.76562 -6,-0.76562c-2.4922,0 -4.3359,0.34375 -5.5312,1.0312c-1.1875,0.6875 -1.7812,1.7578 -1.7812,3.2031c0,1.0859 0.39844,1.9336 1.2031,2.5469c0.80078,0.60547 2.2578,1.1211 4.375,1.5469l4.4531,0.89062c4.5,0.90625 7.6953,2.2812 9.5938,4.125c1.8945,1.8438 2.8438,4.4648 2.8438,7.8594c0,4.4688 -1.3242,7.793 -3.9688,9.9688c-2.6484,2.1797 -6.6875,3.2656 -12.125,3.2656c-2.5742,0 -5.1523,-0.24609 -7.7344,-0.73438c-2.5859,-0.47656 -5.168,-1.1953 -7.75,-2.1562l0,-8.8125c2.582,1.375 5.0781,2.4141 7.4844,3.1094c2.4141,0.6875 4.7422,1.0312 6.9844,1.0312c2.2812,0 4.0234,-0.37891 5.2344,-1.1406c1.2188,-0.75781 1.8281,-1.8438 1.8281,-3.25c0,-1.2578 -0.41406,-2.2344 -1.2344,-2.9219c-0.82422,-0.6875 -2.4648,-1.3008 -4.9219,-1.8438l-4.0469,-0.90625c-4.0547,-0.86328 -7.0156,-2.2422 -8.8906,-4.1406c-1.8672,-1.8945 -2.7969,-4.4531 -2.7969,-7.6719c0,-4.0312 1.3008,-7.1289 3.9062,-9.2969c2.6016,-2.1758 6.3438,-3.2656 11.219,-3.2656c2.2266,0 4.5156,0.16797 6.8594,0.5c2.3516,0.33594 4.7852,0.83984 7.2969,1.5156l-0.0003,0.00003z" />
                </symbol>
            </defs>
            <g>
                <g id="svg_10">
                    <path id="svg_11" d="m22.82,548.97c-17.355,-17.348 -17.355,-45.488 0,-62.82l204.16,-204.18l-204.16,-204.14l0,0.02344c-17.348,-17.348 -17.355,-45.488 0,-62.82c17.312,-17.371 45.453,-17.371 62.832,-0.03516l235.54,235.59c8.2852,8.25 13.016,19.711 13.016,31.406c0,11.703 -4.7305,23.133 -13.016,31.418l-235.54,235.58c-8.6836,8.6719 -20.055,13.004 -31.418,13.004c-11.371,0 -22.742,-4.332 -31.414,-13.027l0,0.00072z" />
                    <use id="svg_12" y="728.57813" x="9.79297" />
                    <use id="svg_13" y="728.57813" x="50.54297" />
                    <use id="svg_14" y="728.57813" x="77.92578" />
                    <use id="svg_15" y="728.57813" x="115.58594" />
                    <use id="svg_16" y="728.57813" x="153.05469" />
                    <use id="svg_17" y="728.57813" x="179.59766" />
                    <use id="svg_18" y="728.57813" x="217.25391" />
                    <use id="svg_19" y="728.57813" x="276.33203" />
                    <use id="svg_20" y="728.57813" x="316.07813" />
                    <use id="svg_21" y="728.57813" x="371.60547" />
                    <use id="svg_22" y="728.57813" x="411.59375" />
                    <use id="svg_23" y="728.57813" x="438.13672" />
                    <use id="svg_24" y="728.57813" x="475.60547" />
                </g>
            </g>
        </svg>

    )

}

export default GraterThen;