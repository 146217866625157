import styled from 'styled-components';


export const Wrapper = styled.div`

    width: 100%;
    display: flex;
    position: relative;
    min-height: calc(100vh - 220px);
    flex-direction: column;

    .path_wrapper{
        position: absolute;
        width: 40%;
        margin: auto;
        left: 0;
        top: 0;
        bottom: 0px;
        right: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #444;
        padding: 0px 1rem;
        height: 3rem;
        box-sizing: border-box;
        border-radius: 30px;
        padding-right: 5px;
        display: flex;
        justify-content: space-between;

        input{
            width: calc(100% - 8.3rem);
            border: 0px;
            font-size: 1rem;

            &:focus{
                outline: none;
            }


            &::placeholder{
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: left;
                color: rgb(77, 77, 77);
            }
        }

        .view_files{
            width: 8rem;
            height: 2.3rem;
            background: #3D8EF5;
            border: 0px;
            border-radius: 30px;
            color: #fff;
            font-size: 1rem;
            cursor: pointer;

            &:hover{
                opacity: .9;
            }
        }
    }


    .file_container_flex{
        width: 100%;
        margin: 0px auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }



    .choose_file_for_mapping{
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        top: 0px;
        position: absolute;
        margin: auto;
        right: 0px;
        left: 0px;
        top: 12rem;

    }
    .file_list{
        width:45%;
        background: #f2f2f285;
        padding: 1rem;
        box-sizing: border-box;

         table{
            border: 0px;
            width: 100%;
            border-collapse: collapse;
            border: 1px solid #e2e2e2;
            background-color: #fff;

            thead{
                td{
                    color: #fff;
                    font-weight: 600;
                    background-color: #3D8EF5;
                    position: sticky;
                    top: 0px;
                    z-index: 2;
                    padding: .8rem 1rem;

                }
            }

            td{
                
                padding: .5rem 1rem;
                font-size: .9rem;
                color: #000;
                border: 1px solid #ccc;
                font-weight: 400;
                border-bottom: 1px solid #e2e2e2;
                input{
                    border: 0px;;
                    width: 100%;
                    box-sizing: border-box;
                     
                }
            }

            tbody{

                select{
                    width: 120px;
                    height: 30px;
                    margin: 0px;
                    margin-left: 10px;
                    border-radius: 0px;
                    font-size: .9rem;
                }

                .active{
                    background-color: #b9cee9;
                    td{
                        /* color: #fff; */
                    }
                }
                .found{
                    /* background-color: #9e9e9e14; */
                    &:hover{
                        background-color: #9e9e9e14;
                        td{
                            color: #222;
                        }
                    }
                    td{
                        color: #222;
                    }

                }
                tr{

                cursor: pointer;
                transition: all .3s;
                
                &:hover{
                    background-color: #b9cee9;
                    transition: all .3s;
                     td{
                        /* color: #fff; */
                    }
                }
            }
            }
        }
    }

    .mapping_wrapper{
        width: 50%;
        position: relative;
        background: #f2f2f285;
        padding: 1rem;
        box-sizing: border-box;
    }


     h2{
            font-size: 1.286rem;
            font-weight: bold;
            line-height: 1.714rem;
            color: #4d4d4d;
            margin: 0px;
            display: block;
            position: relative;
            text-align: left;
            width: 100%;
            margin: 1rem 0px;
            margin-top: 0px;
            
        }
`;




export const ProgressOverlay = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 6;
    background-color: ${props => props.bg ? props.bg : '#0000007d'};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* opacity: .9; */

    .content{
        position: fixed;
        top: 0;
        border: 0;
        margin: auto;
        left: 0;
        width: 40rem;
        border-radius: 6px;
        background: #fffefe;
        right: 0px;
        bottom: 0px;
        height: 20rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h1{
            font-size: 30px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 39px;
            letter-spacing: normal;
            text-align: center;
            color: #1a1a1a;
            margin: 0
        }

        h2{
            font-size: 48px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 63px;
            letter-spacing: normal;
            text-align: center;
            color: #1a1a1a;
            margin: 15px 0px;
        }
    }
`;
