import styled from 'styled-components';



export const Wrapper = styled.div`
    width: ${props => props.width ? props.width : props.open ? '24%' : '0px'};
    height: ${props => props.height ? props.height : '100vh'};
    margin-top: 0rem;
    box-shadow: ${props => !props.hideBoxShadow ? '0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%)' : undefined};
    background-color: #fff;
    text-align: left;
    position: relative;
    z-index: ${props => props.zIndex ? props.zIndex : '5'};
    position: absolute;
    right: 0px;


              
                         
    .lable{
        font-size: 1rem;
        color: #000 ;
        font-weight: 500;
        display: inline-block;
        /* width: 140px; */
    }
    .__input__{
        height: 2rem;
        box-sizing: border-box;
        font-size: .9rem;
        width: 100%;
    }                       
                    

    .____label{
        display: block;
        margin-left: 12px;
        color: #333333;
        display: inline-block;
        word-wrap: break-word;
        width: calc(100% - 50px);
    }

    .link{
        margin: 0px;
        color: #1499f1;
        font-weight: 600;
        font-size: 1rem;
        cursor: pointer;
        display: inline-block;
        &:hover{
            text-decoration: underline;
        }
    }

    .link2{
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 1rem;
        font-weight: normal;
        color: rgb(55,74,102);
        text-decoration: underline;
        margin: 0px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;
        margin: 0px;
        font-weight: 600;
        font-size: 1rem;
        cursor: pointer;
        display: inline-block;
        &:hover{
            text-decoration: underline;
        }
    }

    .formula_list{
        padding-left: 20px;
        margin-top: 0px;
        margin-bottom: 10px;
        li{
            font-size: .9rem;
            color: #222;
            padding: 4px 0px;
            text-transform: capitalize;
            cursor: pointer;
            font-style: italic;
            font-weight: 600;
            .remove{
                opacity: 0;
            }
            &:hover{
                .remove{
                    opacity: 1;
                }
            }
        }
    }
    .scroll_div{
        width: 100%;
        height: calc(100vh - 110px);
        border-bottom: 1px solid #e3e3e3;
        overflow-y: auto;
        &::-webkit-scrollbar {
            background-color:red;
            width:5px
         }
        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }
        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }
    }
    .setting_header{
        background: #f3f3f3;
        padding: 15px 10px;
        box-sizing: border-box;
        font-size: 1rem;
        border-radius: 3px;
        margin-bottom: 10px;
        color: #222;
    }
   
    .table_wrapper{
        width: 100%;
        border-collapse: collapse;
        thead{
            td{
                padding: 0rem 0px;
                font-weight: 600;
            }
        }
        td{
            font-size: .8rem;
            color: #000;
            padding: .4rem 0px;
            padding-right: 10px;
            box-sizing: border-box;
        }
    }
      
        .setion_of_setting{
            label{
                font-size: .9rem;
                font-weight: 600;
                display: block;
                margin-bottom: 10px;
            }
           
        }
    
    .header_top{
        font-size: 1rem;
        border-bottom: 1px solid #bdbdbd;
        color: rgba(0,0,0,.87);
        padding: 10px 8px;
        text-align: left;
        font-weight: bold;
        display: flex;
        flex-direction: row;
        align-items: center;
        
    }
    .flex_row{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: .8rem;
        width: 50%;
    }
    .data_table{
        width: 100%;
        border: 1px solid #ccc;
        border-collapse: collapse;
        thead{
            td{
                font-weight: 500;
                background-color: #025072;
                padding: .3rem .3rem;
                color: #fff;
                /* width: 30%; */
                font-size: .8rem;
            }
        }
        td{
            border: 1px solid #ccc;
            padding: 0rem .3rem;
            font-size: .8rem !important;
            
        }
    }

    .filter_tbl_main{
        width: 100%;
        max-height: 10rem;
        overflow: auto;
        &::-webkit-scrollbar {
            background-color:red;
            width:5px
         }
        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }
        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }
        table{
            width: 100%;
            border: 1px solid #ccc;
            border-collapse: collapse;

            thead{
                td{
                    font-weight: 600;
                    background-color: #025072;
                    padding: .3rem .3rem;
                    color: #fff;
                    font-size: .9rem;
                }
            }

            tbody{
                td{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            td{
                border: 1px solid #ccc;
                padding: 0rem .3rem;
                font-size: .8rem !important;
                
                /* max-width: 25%; */

                input{
                    height: 25px;
                    /* width: 50px; */
                }

                .delete{
                    cursor: pointer;
                }
            }
        }
    }



    .drill_down_section{
        ul{
            margin: 0;
            padding: 0;
            padding-left: 1.5rem;
            li{
                font-size: .9rem;
                text-transform: capitalize;
                line-height: 1.3rem;
                cursor: pointer;
                position: relative;
                width: 100%;
                color: #222;
                font-style: italic;
                font-weight: 500;

                &:hover{
                    .delete{
                        opacity:1;
                    }
                }
            }
        }

        .delete{
            opacity: 0;
            margin-left: 10px;;
        }
    }
    .filter_suggestion_list{
        width: 92%;
        background-color: #fff;
        position: absolute;
        left: 0;
        top: 2.3rem;
        border: 1px solid #eaeaea;
        box-shadow: 0px 3px 4px #ccc;
        max-height: 10rem;
        overflow: auto;
        z-index: 2;

        &::-webkit-scrollbar {
            background-color:red;
            width:5px
        }
        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }
        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }
        div{
            cursor: pointer;
            padding: 7px 4px;
            font-size: .9rem;
            color: #222;
            font-weight: 500;
            width: 100%;
            box-sizing: border-box;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            &:hover{
                background-color: #eaeaea
            }
        }
    }

    .form-control_search{
        width: 100%;
        height: 2.3rem;
        border: 1.5px solid #ccc;
        border-radius: 3px;
        padding: .7rem;
        box-sizing: border-box;
        font-size: .9rem;
        margin-bottom: 10px;
        color: #222;
        &::placeholder{
            color: #3a3a3a;
        }
       
        &:focus{

        }
    }
    
    .form-control_btn{
        height: 2rem;
    }
`;



export const LeftSideSetting = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    
`;

export const RightSideSetting = styled.div`
    width: 100%;
    height: 100%;
    padding-top: .5rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /* background-color: red; */
    display: flex;
    padding: 1rem 5px;
    box-sizing: border-box;
    /* align-items: center; */
    /* border-left: 1px solid #ccc; */

    .item__{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-bottom: 1px solid #ccc;
        flex-direction: column;
        font-size: .7rem;
        font-weight: 400;
        padding-bottom: 1rem;
        padding-top: 1rem;

        &:hover{
            opacity: .8;
            cursor: pointer;
        }

        .icon{
            margin-bottom: 10px;

            img{
                width: 40px;
            }
        }
        
    }
`;



export const BottomDropDownMenu = styled.div`
    background: #fff;
    position: absolute;
    top: 38px;
    width: 150px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 20%);
    right: 9px;
    padding: 8px 0px;
    box-sizing: border-box;
    z-index: 3;

    .item{
        padding: 7px 10px;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        svg{
            fill: #030303;
        }

        span{
            display: inline-block;
            margin-left: 10px;
            color: #030303;
            font-size: .9rem;

        }

        &:hover{
            background-color: rgba(0, 0, 0, 0.1);
            cursor: pointer;
        }
    }
`;
