import React, { useState } from 'react'
import styled from 'styled-components';
import SearchSvg from '../svg/search';

const Wrapper = styled.div`

    width: 100%;
    display: flex;
    align-items: center;

  
    .text_box_wrapper{
        width: 100%;
        position: relative;

        span{
            position: absolute;
            left: .5rem;
            top: .6rem;
        }

        input{
            width: 100%;
            height: 2.3rem;
            border: 1.5px solid #ccc;
            border-radius: 3px;
            padding: 1rem;
            box-sizing: border-box;
            padding-left: 1.8rem;
            font-size: .9rem;
            &:-webkit-placeholder{
                color: #ccc;
            }
            &:focus{

            }
        }
    }
    

`;


// const AddButton = styled.div`
//     width: 2.2rem;
//     height: 2.2rem;
//     font-size: 2rem;
//     color: #fff;
//     border-radius: 50%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     background-color: #008AC7;
//     cursor: pointer;
//     box-shadow: 2px 3px 4px #cfc0c0;
//     svg{
//         fill: #fff;
//     }
// `;

const PageHeader = (props) => {

    const { onSearch, searchValue } = props;


    return (
        <Wrapper>
            <div style={{ marginRight: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ width: '100%', boxSizing: 'border-box', }}>
                    <div className='text_box_wrapper'>
                        <span>
                            <SearchSvg size={'1rem'} height={'1rem'} color="#ccc" />
                        </span>
                        <input
                            type="text"
                            placeholder="Search Relationship"
                            onChange={(e) => {
                                onSearch(e)
                            }}
                        />
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default PageHeader;