import React, { useState, useEffect } from 'react'
import { Wrapper, ProgressOverlay, ProgressBar, SelectCategoryWrapper, } from './styled.file.upload'
import { constants } from '../../utils/constants';
import { send_File, getAllFiles, setSelectedFile, get_category_folder_list, get_uploaded_table_list, approveTableRequest, getTableFromDb, saveRequiredColumnsForJson } from '../../actions/file.uploader';
import { connect } from 'react-redux'
import axios from 'axios';
import * as util from '../../utils'
import * as actionTypes from '../../action-types/file.uploader';
import PDFSvg from '../svg/pdf';
import PngSvg from '../svg/png';
import JpgSvg from '../svg/jpg';
import AutoSearch from '../auto.search';
import { Gap } from '../gap';
import { withRouter } from 'react-router-dom';
import AddColumnsPopup from '../tbl_edits/add.columns.popup';
import TextBox from '../hoc/textBox';
import { get_db_info } from '../../actions/connection'
import SelectOptionV1 from '../select-option/select.option.v1.1';
import ColumnMapping from './column.mapping';
import TextFileColumnMapping from './text.file.column.mapping';
import CloseSvg from '../svg/close'
import { NormalButton } from '../hoc/normal.button'
import * as XLSX from 'xlsx';
import { getHintsWithTableAndColumnName, getTablesForHints } from '../../actions/hints';
import UploadIcon from '../svg/icons-v2/uploadIcon';
import TextBoxV1 from '../hoc/text.box.v1';



/**
 * 
 * @param {*} props 
 * @returns 
 * This is A file Upload Popup for GLOBAL
 * 
 */
const Index = (props) => {

    const {

        type,
        setSelectedFile,
        close,
        popupKey,
        option,
        data,
        tableOfDb

    } = props;



    const {

        setActiveTab = () => { },
        tabList,
        uploadedFileList,
        setUploadedFileList,
        redirectToTableViewPage,
        onCompleted = () => { },
        onError = () => { },
        onclose = () => { }

    } = data;


    const [localUploadedFileList, setLocalUploadedFileList] = useState([]);

    const [progress, setProgress] = useState(undefined)
    const [complete, setComplete] = useState(undefined);
    const [uploadFailed, setUploadFailed] = useState(undefined)
    const [searchValue, setSearchValue] = useState('');
    const [whichKindOfDataImporting, setWhichKindOfDataImporting] = useState('');
    const [processingLoader, setProcessingLoader] = useState(undefined);
    const [uploadingData, setUploadingData] = useState(undefined);
    const [permissionPopup, setPermissionPopup] = useState(undefined);
    const [refreshData, setRefreshData] = useState(false);
    const [syncDataTo, setSyncDataTo] = useState(undefined)
    const [selectedSchema, setSelectedSchema] = useState(undefined)
    const [selectedTable, setSelectedTable] = useState(undefined)
    const [directoryName, setDirectoryName] = useState(undefined);
    const [column_mapping, setColumnMapping] = useState(undefined);
    const [mappingData, setMappingData] = useState(undefined);
    const [mappingType, setMappingType] = useState(undefined);
    const [mapping_file_path, set_mapping_file_path] = useState(undefined);

    const [errorMessage, setErrorMessage] = useState(undefined);


    // v2

    const [file_seperator, set_file_seperator] = useState("");

    const [file_info_form_java, set_file_info_form_java] = useState(undefined);

    const [target_db_info, set_target_db_info] = useState(undefined);
    const [target_table, set_target_table] = useState(undefined);

    const [selected_work_sheet, set_selected_work_sheet] = useState(undefined);


    const file_upload = (e) => {

        let temp_files = localUploadedFileList ? [...localUploadedFileList] : [];
        let files = e.target.files;

        if (files.length < 21) {
            for (var i = 0; i < files.length; i++) {
                temp_files.push(files[i]);
            }
            setLocalUploadedFileList(temp_files)
        }
        else {
            alert("you can only select 20 files at a time")
        }
    };




    useEffect(() => {
        if (uploadedFileList?.length > 0) {
            const type = uploadedFileList[0]?.type;

            console.log("type", type)
            if (type == "application/json") {
                setSearchValue("json_data")
            }
            else if (type == "text/plain") {
                setSearchValue("text_data")

            } else if (type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                setSearchValue("xls_data")

            }
            else if (type === 'application/pdf' || type === 'application/rtf' || type === 'application/msword') {
                setSearchValue("pdf_data")
            }
            else {
                setSearchValue("importData")

            }

            const file = uploadedFileList?.[0];

            if (file && type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {

                const reader = new FileReader();
                const file_size = (file?.size / (1024 * 1024))

                if (file_size > 10) {
                    set_selected_work_sheet([
                        {
                            name: '',
                            selected: false,
                            table_name: ''

                        }
                    ])
                }
                else {
                    reader.onload = e => {
                        const data = e.target.result;
                        const workbook = XLSX.read(data, { type: 'binary' });
                        if (workbook.SheetNames?.length > 0) {
                            const temp_work_sheet = workbook.SheetNames.map((s) => {
                                return {
                                    name: s,
                                    selected: false,
                                    table_name: ''
                                }
                            })
                            set_selected_work_sheet(temp_work_sheet)
                        }
                    };
                    reader.readAsBinaryString(file);
                }

            }
        }
        setLocalUploadedFileList(uploadedFileList);
    }, [uploadedFileList])



    useEffect(() => {
        if (type === actionTypes.SINGLE_FILE_SUCCESS || type === actionTypes.MULTIPAL_FILE_SUCCESS) {
            setTimeout(() => {
                props.get_category_folder_list();
            }, 100)
        }
    }, [type]);



    useEffect(() => {
        if (searchValue === 'importData') {
            props.get_uploaded_table_list()
        }
    }, [searchValue])


    const file_upload_to_server = () => {

        let _folder_name = (syncDataTo && syncDataTo === 'directory') ? 'etljson' : searchValue ? searchValue : 'default';

        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percentage = Math.round((loaded * 100) / total);
                // if (percentage < 100) {
                setProgress(percentage)
                // } 
                if (percentage == 100) {
                    setProcessingLoader(true)
                }
            },
            headers: util.getMandatoryRequestHeaders(undefined, _folder_name, target_table, directoryName, target_db_info, whichKindOfDataImporting),
            id: Math.random()
        }
        const data = new FormData();


        for (var x = 0; x < localUploadedFileList.length; x++) {
            data.append('nf_uploader', localUploadedFileList[x]);
        }

        const temp_selected_work_sheet = selected_work_sheet?.filter((f) => f?.table_name?.split(" ").join("_"));


        data.append('refresh_data', refreshData);
        data.append("file_seperator", (file_seperator || ''));
        data.append('work_sheets', (temp_selected_work_sheet ? JSON.stringify(temp_selected_work_sheet) : '[]'));

        const url = constants.END_POINTS.API + constants.END_POINTS.PDF_TO_QUESTION_ANSWER.SEND_FILE_TO_UPLOAD;
        // console.log("target_db_info", target_db_info)

        axios.post(url, data, options).then(res => {

            setProgress(100)

            setTimeout(() => {

                if (res.status == 200 && res.data && res.data.code == 200) {

                    setProgress(undefined)
                    setComplete(undefined)
                    setComplete(true)

                    if (res.data.data?.user_inputs_required_to_go_ahead && res.data?.data?.mapping_type === "JSON_MAPPING") {

                        setMappingType("JSON_MAPPING");
                        set_mapping_file_path(res?.data?.data?.filePath)
                        setMappingData({
                            foreignKeys: res.data?.data?.foreignKeys,
                            json_columns: res.data?.data?.json_columns
                        })

                    }

                    if (res.data.data?.user_inputs_required_to_go_ahead && res.data?.data?.mapping_type === "TEXT_MAPPING") {
                        setMappingType("TEXT_MAPPING");
                        set_mapping_file_path(res?.data?.data?.filePath)
                        setMappingData({
                            extracted_db_columns: res.data?.data?.extracted_db_columns,
                            file_info_form_java: res.data?.data?.file_info_from_JAVA
                        })
                    }


                    if (res.data.user_inputs_required_to_go_ahead || res?.data?.data.user_inputs_required_to_go_ahead) {
                        // console.log("es.data", res.data?.data)
                        // set_mapping_file_path(res.data?.data[0])
                        setPermissionPopup(res.data.data)
                    }


                    setUploadedFileList(undefined)
                    setProcessingLoader(undefined)
                    props.send_File(res)

                    let file_name = res?.data?.data?.fileName;

                    if (file_name) {
                        setSelectedFile(file_name)
                        setActiveTab(tabList[0])
                    }
                    // if (searchValue === 'importData') {
                    setActiveTab("Manage Your Files")
                    // }
                    if ((searchValue == 'certificate') || (searchValue == 'food_menu')) {
                        redirectToTableViewPage(searchValue)
                    }
                    setSearchValue('');

                    if (!res?.data?.user_inputs_required_to_go_ahead) {
                        setTimeout(() => {
                            close(option, popupKey)
                        }, 2000)
                    }

                    if (onCompleted) {
                        onCompleted(res?.data)
                    }

                } else {
                    const error_msg = res?.data?.message;
                    onError(error_msg)
                    setUploadFailed(true)
                    error_msg && setErrorMessage(error_msg);

                    if (searchValue == 'importData') {
                    }
                    setTimeout(() => {
                        close(option, popupKey)
                    }, 7000)
                }

                // let's fetch all hints and relationship
                // props.getHintsWithTableAndColumnName()
                // props.getTablesForHints();

                if (target_db_info) {
                    const db_id_to_use = target_db_info + '__nf__db__cached__'
                    props.getAllFiles(db_id_to_use)
                    props.get_category_folder_list(db_id_to_use)
                }
            }, 1000)
        })

    }



    const upload_file_data_v2 = (json_file_path, table_name, columns, foreignKey, foreignValue) => {

        const options = {
            headers: util.getMandatoryRequestHeaders(),
        };

        const body = {
            json_file_path: json_file_path,
            columns: columns,
            table_name: target_table?.split(" ").join("_"),
            db_info_id: target_db_info,
            foreignKey: foreignKey,
            foreignValue: foreignValue,
        }

        setProgress(0)
        setComplete(undefined)
        setUploadFailed(undefined)
        setMappingType(undefined)
        setMappingData(undefined)
        setUploadingData(true)

        const url = constants.END_POINTS.API + constants.END_POINTS.FILE_UPLOAD_INFO.POINT + constants.END_POINTS.FILE_UPLOAD_INFO.SAVE_REQUIRED_JSON_COLUMNS;
        axios.post(url, body, options).then(res => {

            setUploadingData(false);
            if (res.status == 200 && res.data && res.data.code == 200) {
                setComplete(true)
                setTimeout(() => {
                    close(option, popupKey)
                }, 2000)

            } else {
                setUploadFailed(true)
                setTimeout(() => {
                    close(option, popupKey)
                }, 2000)
            }
            props.getAllFiles()
            setActiveTab("Manage Your Files")

        })


    }



    const upload_file_data_v3 = (json_file_path, table_name, mapping_columns) => {

        const options = {
            headers: util.getMandatoryRequestHeaders(),
        }

        const body = {
            file_path: mapping_file_path,
            mapping_columns: mapping_columns,
            table_name, target_table,
            db_info_id: target_db_info,
        }

        setColumnMapping(undefined)
        setProgress(0)
        setComplete(undefined)
        setUploadFailed(undefined)
        setUploadingData(true)
        setMappingType(undefined)


        const url = constants.END_POINTS.API + constants.END_POINTS.FILE_UPLOAD_INFO.POINT + constants.END_POINTS.FILE_UPLOAD_INFO.SAVE_TEXT_FILE_REQUIRED_DATA;
        axios.post(url, body, options).then(res => {

            setUploadingData(false)

            // console.log("resresresresres", res)
            if (res.status == 200 && res.data && res.data.code == 200) {
                setComplete(true)
                setTimeout(() => {
                    // close(option, popupKey)
                }, 2000)

            } else {
                setUploadFailed(true)
                setTimeout(() => {
                    // close(option, popupKey)
                }, 2000)
            }

            props.getAllFiles()
            setActiveTab("Manage Your Files")

        })
    }



    const getIconFromFileType = (name) => {
        let type = name.split('.').pop();
        if (type === 'pfd') {
            return <PDFSvg size='1.2em' height='1.1rem' />
        }
        if (type === 'png') {
            return <PngSvg size='1.2em' height='1.1rem' />
        }
        if (type === 'jpg') {
            return <JpgSvg size='1.2em' height='1.1rem' />
        }
    }


    const remove_file = (index) => {
        const temp_localUploadedFileList = localUploadedFileList ? [...localUploadedFileList] : [];
        temp_localUploadedFileList.splice(index, 1);
        setLocalUploadedFileList(temp_localUploadedFileList);
    }



    const upload_btn_text = searchValue === "pdf_data"
        ? "Upload PDF"
        : searchValue === "json_data"
            ? "Read Your JSON"
            : searchValue === "xls_data"
                ? "Upload Excel"
                : "Upload CSV";


    const file_proccessing_type = (searchValue === "json_data" ? "JSON" : searchValue === "text_data" ? "TEXT" : "FILE")


    return (
        <Wrapper>
            {localUploadedFileList?.length > 0 && (!progress && !complete && !processingLoader && !uploadFailed && !uploadingData) && !permissionPopup && (
                <React.Fragment>
                    <div
                        className="dismiss"
                        title="Close"
                        onClick={() => {
                            close(option, popupKey)
                            setUploadedFileList(undefined)
                            if (onclose) {
                                onclose()
                            }

                        }}
                    >
                        <CloseSvg color="#999999" size="1rem" height="1rem" />
                    </div>


                    <div className="flex_box">
                        <div className='thumbnail'>
                            {/* <img height='120px' src={constants.CONTEXT_PATH + './file_upload.png'} alt='upload img'></img> */}
                            <UploadIcon size={'10rem'} height={'10rem'} />
                        </div>
                        <div className='file_other_properties'>
                            <h2 style={{
                                margin: '0px',
                                fontSize: '1.4rem',
                                fontWeight: 500,
                                marginBottom: '4px',
                                // textTransform: 'uppercase',
                            }}>Your Selected File(s)</h2>

                            <div className='table_wrapper'>
                                {/* <div className='table_scroll'>
                            <table>
                                <thead>
                                    <tr>
                                        <td>#</td>
                                        <td>FILE NAME</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>

                                    {localUploadedFileList?.length > 0 && localUploadedFileList?.map((file, index) => {
                                        return (
                                            <tr>
                                                <td style={{ width: '40px' }}>{index + 1}</td>
                                                <td>{file.name}</td>
                                                <td style={{ width: '80px' }}>

                                                    <div className='delete_file' title="Remove" onClick={() => remove_file(index)}>
                                                        <CloseSvg size=".8rem" height=".8rem" />
                                                    </div>
                                                </td>
                                            </tr>
                                        )

                                    })}
                                    <tr>
                                        <td></td>
                                        <td>
                                            <div className='select_more_file'>
                                            <input
                                                type="file" id="imgupload"
                                                multiple={false}
                                                style={{
                                                    width: '100%',
                                                    position: 'absolute',
                                                    opacity: 0,
                                                    cursor: 'pointer',
                                                }}
                                                // onClick={(e) => {

                                                // }}
                                                // value={}
                                                onChange={(e) => {
                                                    file_upload(e)
                                                }} />
                                                 + Select More Files
                                                 </div></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                            </div> */}
                            <div className='item_row' style={{color: '#fff', background:'linear-gradient(to right, #3E9EFF, #737DFF)'}}>
                                <div style={{width: '10%'}}>#</div>
                                <div style={{width: '80%'}}>File Name</div>
                                <div style={{width: '10%', paddingLeft: '4%' }}></div>
                            </div>
                            <div className='scroll_items_wrapper'>
                                {localUploadedFileList?.length > 0 && localUploadedFileList?.map((file, index) => {
                                    return <div className='item_row' style={{display: 'flex',  backgroundColor: "#F0F3FF" }}>
                                        <div style={{width: '10%'}}>{index + 1}</div>
                                        <div style={{width: '82%'}}>{file.name}</div>
                                        <div style={{ width: '8%', paddingLeft: '50%' }} className='delete_file' title="Remove" onClick={() => remove_file(index)}>
                                            <CloseSvg size=".8rem" height=".8rem" />
                                        </div>
                                    </div>
                                })}
                            </div>

                            <div className='select_more_file'>
                                <input
                                    type="file" id="imgupload"
                                    multiple={false}
                                    style={{
                                        width: '28%',
                                        position: 'absolute',
                                        left: '33rem',
                                        opacity: 0,
                                        cursor: 'pointer',
                                        height: '2.4rem'
                                    }}
                                    onChange={(e) => {
                                        file_upload(e)
                                    }} 
                                />
                                <div className='add_icon_outline'>
                                +
                                </div>
                                     Select More Files
                            </div>
                        </div>




                            {searchValue !== "pdf_data" && (
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: '1rem' }}>
                                    <div style={{ width: '49%' }}>
                                        <label className='__label'>Target DB</label>
                                        <div>
                                            <SelectOptionV1
                                                options={props?.db_infos || []}
                                                on_change={(value) => {
                                                    set_target_db_info(value)
                                                    props.getTableFromDb(value)
                                                }}
                                                value_key="id"
                                                display_key="connection_name"
                                                value={target_db_info || ''}
                                                group_key="db_type"
                                                none_value={false}
                                                hide_margin={true}
                                            // label="Select Target DB"
                                            />
                                        </div>
                                    </div>

                                    {searchValue !== "xls_data" && (
                                        <div style={{ width: '49%' }}>
                                            <label className='__label' >Target Table</label>
                                            <div style={{ width: '100%' }}>
                                                <AutoSearch
                                                    // borderNone={true}
                                                    fullBorder={true}
                                                    bg_color={"#fff"}
                                                    selections={tableOfDb || []}
                                                    onItemClick={(value) => {
                                                        set_target_table(value)
                                                    }}

                                                    setActiveValue={(value) => {
                                                        set_target_table(value)

                                                    }}

                                                    activeValue={target_table || ''}
                                                    width="100%"
                                                    height="2.857rem"
                                                    lineHeight="2.857rem"
                                                    color='#7c7c7c'
                                                    hide_margin={true}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}



                            {selected_work_sheet?.length > 0 && (
                                <div style={{ width: '100%', marginTop: '1rem' }}>
                                    <h2 style={{
                                        marginBottom: '4px',
                                        fontSize: '1.1rem',
                                        fontWeight: 500,
                                    }}>Please Provide Mapping For Sheet</h2>
                                    
                                <div className='item_row' style={{color: '#fff', background:'linear-gradient(to right, #3E9EFF, #737DFF)', display: 'flex', justifyContent: 'space-between'}}>
                                <div style={{width: '5%'}}>#</div>
                                <div style={{width: '45%'}}>Sheet Name</div>
                                <div style={{width: '45%'}}>Table Name</div>
                            </div>
                            <div >

                            <div className='scroll_items_wrapper_sec'>
                            {selected_work_sheet?.length > 0 && selected_work_sheet?.map((sheet, index) => {
                                return <div className='item_row' style={{display: 'flex',  backgroundColor: "#F0F3FF",  justifyContent: 'space-between' }}>
                                    <div style={{width: '5%'}}>{index + 1}</div>
                                    <div  style={{ width: '45%'}} >
                                        <TextBoxV1
                                            hide_margin={true}
                                            hide_border={true}
                                            type="text"
                                            onChange={((e) => {
                                                const temp_data = selected_work_sheet ? [...selected_work_sheet] : [];
                                                temp_data[index]['name'] = e.target.value;
                                                set_selected_work_sheet(temp_data);

                                                        })}
                                                        value={sheet.name || ''}
                                                        style={{
                                                            width: '100%',
                                                            boxSizing: 'border-box',
                                                            outline: 'none'
                                                        }}
                                                        height={"2.4rem"}
                                                        labelStyle={{
                                                            color: 'rgb(0 0 0 / 39%)'
                                                        }}
                                                    />
                                                </div>

                                    <div style={{width: '45%'}}>
                                    <AutoSearch
                                        borderNone={true}
                                        bg_color={"#fff"}
                                        selections={tableOfDb || []}
                                        onItemClick={(value) => {
                                            const temp_data = selected_work_sheet ? [...selected_work_sheet] : [];
                                            temp_data[index]['table_name'] = value;
                                            set_selected_work_sheet(temp_data);
                                        }}
                                        setActiveValue={(value) => {
                                            const temp_data = selected_work_sheet ? [...selected_work_sheet] : [];
                                            temp_data[index]['table_name'] = value;
                                            set_selected_work_sheet(temp_data);
                                        }}
                                        activeValue={sheet?.table_name || ''}
                                        width="100%"
                                        height={"2.4rem"}
                                        // lineHeight="2rem"
                                        color='#7c7c7c'
                                    />
                                    </div>
                                   
                                    </div>
                                })}
                            </div>
                            </div>
                            </div>
                            )}


                            {searchValue && searchValue === 'importData' && (

                                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: '1rem', mrginBottom: '1rem' }}>
                                    <div style={{ width: '50%' }}>
                                        <label className='__label' >Delimeter</label>
                                        <div style={{ width: '100%' }}>
                                            <div style={{ width: '100%', marginBottom: '1.2rem', }}>
                                                <AutoSearch
                                                    fullBorder={true}
                                                    // label="Please Provide Delimeter"
                                                    selections={[",", ";", "^", "#", "$", "|", "||", "|", "__"]}
                                                    bg_color={"#fff"}
                                                    onItemClick={(val) => {
                                                        set_file_seperator(val)
                                                    }}
                                                    setActiveValue={(value) => {
                                                        set_file_seperator(value)
                                                    }}
                                                    // activeValue={file_seperator}
                                                    width="100%"
                                                    height="2.857rem"
                                                    lineHeight="2.857rem"
                                                    color='#7c7c7c'
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )}
                            <div style={{ position: 'absolute', bottom: '1.4rem', right: '3rem', display:'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                {uploadedFileList && uploadedFileList.length > 0 ? (
                                    <NormalButton
                                        width={'12rem'}
                                        primary={true}
                                        height={'2.8rem'}
                                        label={upload_btn_text}
                                        onClick={() => {
                                            // close()
                                            file_upload_to_server();
                                        }}
                                        style={{ fontWeight: 500 }}
                                    // hoverBg={"#086f9d"}
                                    // icon={<img src={constants.CONTEXT_PATH + './read_json.png'} style={{ width: '30px' }} />}

                                    />
                                ) : null}


                            </div>

                        </div>
                    </div>

                </React.Fragment>
            )}

            <div>


                {syncDataTo && syncDataTo === 'directory' && (
                    <div style={{ width: '100%', marginBottom: '1.2rem', }}>
                        <TextBox
                            label="Directory Name"
                            onChange={(e) => {
                                setDirectoryName(e.target.value)
                            }}
                            value={directoryName}
                        />
                    </div>
                )}







                {(progress || complete || processingLoader || uploadFailed || uploadingData) && !permissionPopup && (

                    <ProgressOverlay>
                        <div className="content">

                            {(progress > 0) && !processingLoader && (
                                <React.Fragment>
                                    <h1>Uploading</h1>
                                    <h2>{progress}%</h2>
                                    <ProgressBar progress={progress} />
                                </React.Fragment>
                            )}

                            {processingLoader && !complete && !uploadFailed && (
                                <React.Fragment>
                                    <img src={constants.CONTEXT_PATH + './loader.gif'} style={{ width: '110px', }} />
                                    <h1 style={{ marginTop: '1rem' }}>File Processing...</h1>
                                </React.Fragment>
                            )}

                            {uploadingData && (
                                <React.Fragment>
                                    <img src={constants.CONTEXT_PATH + './loader.gif'} style={{ width: '110px', }} />
                                    <h1 style={{ marginTop: '1rem' }}>Data Uploading...</h1>
                                </React.Fragment>
                            )}



                            {uploadFailed && (
                                <React.Fragment>
                                    <img src={constants.CONTEXT_PATH + './error.png'} style={{ width: '150px', }} />
                                    <h1 style={{ marginTop: '1rem' }}>{(syncDataTo && syncDataTo === 'directory') ? 'Uploaded Failed' : 'Process Failed'}</h1>
                                    <div style={{ maxHeight: '400px', overflow: 'hidden', width: '100%', textAlign: 'center' }}><p>{errorMessage}</p></div>
                                </React.Fragment>
                            )}
                            {complete && (
                                <React.Fragment>
                                    <img src={constants.CONTEXT_PATH + './upload.loader.gif'} style={{ width: '150px', }} />
                                    <h1 style={{ marginTop: '1rem' }}>{(syncDataTo && syncDataTo === 'directory') ? 'Uploaded SuccessFully' : 'Process SuccessFully'}</h1>
                                </React.Fragment>
                            )}
                        </div>
                    </ProgressOverlay>
                )}



                {mappingType && mappingType == "JSON_MAPPING" && (
                    <ProgressOverlay bg={"#1c1b1b85"}>
                        <ColumnMapping
                            mappingData={mappingData}
                            saveRequiredColumnsForJson={upload_file_data_v2}
                            close={() => {
                                close(option, popupKey)
                                if (onclose) onclose()
                            }}
                            json_file_path={mapping_file_path}
                            table_name={selectedTable}
                            target_db_info={target_db_info}
                            db_infos={props.db_infos}
                            file_info_form_java={file_info_form_java}
                        />
                    </ProgressOverlay>
                )}


                {mappingType && mappingType === "TEXT_MAPPING" && (
                    <ProgressOverlay bg={"#1c1b1b85"}>
                        <TextFileColumnMapping
                            mappingData={mappingData}
                            save={upload_file_data_v3}
                            close={() => {
                                close(option, popupKey)
                                // setColumnMapping(undefined)
                            }}
                            json_file_path={mapping_file_path}
                            table_name={selectedTable}
                            file_info_form_java={file_info_form_java}
                        />
                    </ProgressOverlay>
                )}




                {permissionPopup && (
                    <ProgressOverlay bg={"#1c1b1b85"}>
                        <AddColumnsPopup
                            file_information={permissionPopup}
                            columns_from_db={permissionPopup.old_columns ? permissionPopup.old_columns : undefined}
                            extra_columns={permissionPopup.new_columns ? permissionPopup.new_columns : undefined}
                            dimension_info_id={permissionPopup.dimension_info_id ? permissionPopup.dimension_info_id : undefined}
                            table_name={permissionPopup.table_name ? permissionPopup.table_name : undefined}
                            platform_name={permissionPopup.platform_name ? permissionPopup.platform_name : undefined}
                            file_id={permissionPopup.file_id ? permissionPopup.file_id : undefined}
                            file_name={permissionPopup.file_name ? permissionPopup.file_name : undefined}
                            tbl_config_result={permissionPopup.tbl_config_result ? permissionPopup.tbl_config_result : undefined}
                            closeFunction={() => {
                                close(option, popupKey)
                                if (onclose) onclose()
                            }}
                        />
                    </ProgressOverlay>
                )}
            </div>
        </Wrapper>
    )
}



const mapStateToProps = (state) => ({
    type: state.fileUploaderReducer.type,
    folderFromServer: state.fileUploaderReducer.folderFromServer,
    uploaded_table: state.fileUploaderReducer.uploaded_table,
    db_infos: state.connectionReducer.db_infos,
    tableOfDb: state.fileUploaderReducer.tableOfDb,

})

export default withRouter(connect(mapStateToProps, { getHintsWithTableAndColumnName, getTablesForHints, saveRequiredColumnsForJson, get_db_info, send_File, getAllFiles, setSelectedFile, get_category_folder_list, get_uploaded_table_list, approveTableRequest, getTableFromDb })(Index))

