import * as actions from '../action-types/layout';

const initialState = {
    type: actions.LAYOUT_INIT,
    layoutList: undefined,
    activeEditLayout: undefined,
    autoLayoutData: undefined,
};


const layoutReducer = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    // if (action && action.data && typeof action.data === 'object') {
    //     action.data.key = new Date();
    // }

    switch (action.type) {
        case actions.GET_LAYOUT_LIST_SUCCESS:
            return {
                ...state,
                type: action.type,
                layoutList: action.data
            };

        case actions.SAVE_LAYOUT_SUCCESS:
            return {
                ...state,
                type: action.type
            };

        case actions.SET_ACTIVE_EDIT_LAYOUT_SUCCESS:
            return {
                ...state,
                type: action.type,
                activeEditLayout: action.data
            };

        case actions.DISPATCH_AUTO_LAYOUT_DATA:
            const clone_data = {};
            const __id__ = action?.data?.id;
            clone_data[__id__] = action.data;
            return {
                ...state,
                type: action.type,
                autoLayoutData: clone_data
            }

        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
            return {
                type: state.type,
                layoutList: state.layoutList,
                activeEditLayout: state.activeEditLayout,
                autoLayoutData: state.autoLayoutData,
            };
    }
};

export default layoutReducer;