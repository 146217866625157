import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import RippleEffect from '../../hoc/ripple-effect'
import { connect } from 'react-redux';
import { getTargetTableData, getCollectionsOfMongo, pushSourceMappingDataToStore } from '../../../actions/etl'
import { DivRow } from '../../styled-hoc';
import SelectOption from '../../select-option';
import OverLay from '../../helper/overlay';
import ExpandSvg from '../../svg/expand.ract';
import MappingTable from './mapping.table';
import CloseSvg from '../../svg/close'
import { get_schema_table_info } from '../../../actions/report_builder';
import { CheckBox } from '../../hoc/checkbox';
import TableChooser from './table.chooser';
import * as enums from '../../../utils/enums';
import { showPopup } from '../../../utils';


const Wrapper = styled.div`

    width: 100%;

    .table_mapping_scroller{
        max-height: 15rem;
        /* height: 12rem; */
        overflow-y: auto;
        &::-webkit-scrollbar {
            background-color: transparent;
            width:16px;
        }
        
        &::-webkit-scrollbar-track {
            background-color:#fff;
        }

        &::-webkit-scrollbar-track:hover {
            background-color:#f4f4f4;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color:#babac0;
            border-radius:16px;
            border:5px solid #fff;
        }
        
        &::-webkit-scrollbar-thumb:hover {
            background-color:#a0a0a5;
            border:4px solid #f4f4f4;
        }
        
        &::-webkit-scrollbar-button {
            display:none;
        }

    }

    .source_tbl_main{
        width: 100%;
        margin-bottom: 1rem;

        .dropdown_icon {
            cursor: pointer;
            line-height: 2rem;
            vertical-align: middle;
            position: absolute;
            top: .5rem;
            right: .5rem;
            svg{
                display: block;
            }
         }


        table{

            width: 100%;
            border: 0px;
            border-collapse: collapse;
            /* table-layout: fixed ; */
            border: 0px;
            border-right: 1px solid #308bc878;
            border-left: 1px solid #308bc878;
            /* border-radius: 10px; */
            overflow: hidden;

            thead{
                th{
                    background: #2173F7;
                    color: #fff;
                    padding: .8rem 1rem;
                    font-size: .9rem;
                    border: 1px solid #fff;
                    border-top: 0px;
                    position: sticky;
                    z-index: 3;
                    text-align: left;
                    top: -1px; /* Don't forget this, required for the stickiness */
                }
            }
            td{
                padding: 10px .9rem;
                font-size: .9rem;
                font-weight: 500;
                position: relative;
                border: 1px solid #308bc878;
                border-left: 0px;
                border-right: 0px;
            }
        }
    }

    .small_data_popup{
        width: 30rem;
        background: #fff;
        position: absolute;
        border-radius: 3px;
        box-shadow: 2px 2px 8px rgb(0 0 0 / 50%);
        bottom: 50%;
        left: -7%;
        margin-bottom: 1rem;
        padding: 1rem;
        box-sizing: border-box;
        z-index: 5;

        h2{
            font-weight: 600;
            text-align: center;
            margin-top: 0px;
            text-align: center;
            font-size: 1.4rem;
            text-transform: capitalize;

        }

        p{
            text-transform: capitalize;
            margin-top: 0px;
            text-align: left;
            font-size: 1rem;
            color: #333;
            font-weight: 500;
            font-weight: 400;
        }
        .tag{
            padding: .1rem .3rem;
            background: #eaeaea;
            border-radius: 3px;
            font-size: .9rem;
        }
    }

    .new_value{
        position: absolute;
        right: 22px;
        top: 22px;
        border: 1px solid #cccccc69;
        font-weight: 400;
        background: #eaeaea;
        color: #000;
        padding: 0.1rem 0.5rem;
        border-radius: 3px;
        text-transform: capitalize;
        font-size: .6rem;
        cursor: default;
        border-radius: 30px;
        font-weight: 900;
    }

    .expand_icon{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: .3rem;
    }

    .add_edit{
        background: #2173F7;
        color: #fff;
        border: 0px;
        padding: 1px 14px;
        font-size: .9rem;
        border-radius: 33px;
        line-height: 23px;
        font-weight: 500;
        position: absolute;
        right: 10px;
        cursor: pointer;
        bottom: 10px;
        &:hover{
            opacity: .8;
        }
    }

    .btn_small{
        margin-right: 17px;
        display: flex;
        align-items: center;
        position: relative;
        background: #2173F7;
        padding: 6px 11px;
        font-size: .7rem;
        border-radius: 26px;
        color: #fff;
        font-weight: 400;
        cursor: pointer;
        &:hover{
            opacity: .8;
        }
    }
`;



const PopupWrapper = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 9;
    padding: 2rem;
    padding-top: .5rem;
    box-sizing: border-box;

    .close_expander{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: .5rem;

        h1{
            margin: .5rem 0px;
            font-weight: bold;
            letter-spacing: normal;
            color: #222;
            text-transform: capitalize;
            font-size: 1.5rem;
            text-align: center;
        }
    }
    .scroll_wrapper{
        width: 100%;
        max-height: calc(100vh - 200px);
        height: calc(100vh - 200px);
        overflow: auto;
        &::-webkit-scrollbar {
            background-color: transparent;
            width:16px;
        }
        
        &::-webkit-scrollbar-track {
            background-color:#fff;
        }

        &::-webkit-scrollbar-track:hover {
            background-color:#f4f4f4;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color:#babac0;
            border-radius:16px;
            border:5px solid #fff;
        }
        
        &::-webkit-scrollbar-thumb:hover {
            background-color:#a0a0a5;
            border:4px solid #f4f4f4;
        }
        
        &::-webkit-scrollbar-button {
            display:none;
        }
    }

`;


const Index = (props) => {

    const {

        load_mapping_state,
        set_load_mapping_state,
        data_source_type,
        db_interaction_type,
        extraction_state,
        schema_table_info,
        activeStep

    } = props;



    const [isErrorOpen, setIsErrorOpen] = useState(undefined);
    const [isPopupOpen, setIsPopupOpen] = useState(undefined);
    const [source_columns, set_source_columns] = useState(undefined);

    const [table_list, set_table_list] = useState(undefined)
    const [suggest_table, set_suggest_table] = useState(undefined);
    const [show_table_input, set_show_table_input] = useState(undefined)


    const target_db_id = props?.extraction_state?.target_db_id;

    const click_on_expander = () => {
        setIsPopupOpen(true);
    }


    const wrapperRef = useRef(null);


    const on_change_v1 = (key, value, row_index) => {

        const clone_load_mapping_state = load_mapping_state ? [...load_mapping_state] : [];

        if (clone_load_mapping_state[row_index]) {
            clone_load_mapping_state[row_index]["modified"] = true;
            clone_load_mapping_state[row_index][key] = value;
        }

        set_load_mapping_state(clone_load_mapping_state)


    }


    const update_all_row_values = (key, value, target__column_too = false) => {
        const clone_load_mapping_state = load_mapping_state ? [...load_mapping_state] : [];

        clone_load_mapping_state.map((row) => {
            row[key] = value;
            if (target__column_too) {
                row['target_column'] = row['source_column'];
            }
        })
        set_load_mapping_state(clone_load_mapping_state)
    }


    useEffect(() => {

        if (extraction_state?.target_db_id) {
            if (!props.schema_table_info?.[extraction_state?.target_db_id]) {
                // let's fetch schema info 
                props.get_schema_table_info([extraction_state?.target_db_id])

            }
        }
    }, [extraction_state?.target_db_id])




    useEffect(() => {
        if (props?.schema_table_info && Object.keys(schema_table_info)?.length > 0 && target_db_id && schema_table_info?.[target_db_id]) {
            const all_tables = Object.keys(schema_table_info?.[target_db_id]);
            set_table_list(all_tables);
        }
    }, [props.schema_table_info]);



    const save_fun = (selected_table) => {
        if (selected_table?.length === 1) {
            update_all_row_values("target_table", selected_table[0]);
            update_all_row_values("target_column", '');

        } else {
            update_all_row_values("target_table", '');
            update_all_row_values("target_column", '');
            set_suggest_table(selected_table)
        }

    };


    const pick_table_name = () => {
        showPopup('Pick Your Target Table', undefined, enums.default.popupType.element, TableChooser, {
            // tablName: name
            table_list: table_list,
            save_fun: save_fun
        },
            undefined,
            undefined,
            undefined,
        )
    }



    return (
        <React.Fragment>
            {isPopupOpen && (
                <PopupWrapper>

                    <div className="close_expander">
                        <h1>Data Movement Mapping</h1>

                        <div style={{ position: 'absolute', right: '2rem' }}>
                            <RippleEffect
                                zIndex={1}
                                title="close"
                                icon={<CloseSvg size="1rem" height="1rem" />}
                                Click={(e) => {
                                    setIsPopupOpen(false)
                                }}
                            />
                        </div>
                    </div>

                    <Wrapper>
                        <div className="scroll_wrapper">
                            <MappingTable
                                {...props}
                                tableHead={[
                                    "source_column",
                                    "target_table",
                                    "target_column",
                                    "is_direct_mapping",
                                    "criteria",
                                ]}
                                target_db_id={props?.extraction_state?.target_db_id}
                                tableData={load_mapping_state}
                                on_change_v1={on_change_v1}
                                update_all_row_values={update_all_row_values}
                                suggest_table={suggest_table}
                            />
                        </div>
                    </Wrapper>
                </PopupWrapper>
            )}

            <Wrapper style={{
                width: '100%',
                background: '#fff',
                padding: '1rem 2rem',
                boxSizing: 'border-box',
                boxShadow: '3px 4px 4px #cccccca3',
                position: 'relative',
                // right: activeStep === 3   ? '0px' : '-110%',
                // transition: 'all 3s'
            }}>
                {
                    isErrorOpen !== undefined &&
                    <OverLay
                        zIndex={"1"}
                        onClick={() => {
                            setIsErrorOpen(undefined);
                        }} />
                }

                <div className="expand_icon">

                    {!isPopupOpen && (
                        <h3 style={{ border: '0px', margin: '1rem 0px' }} className='title_up'>Data Movement Mapping</h3>
                    )}

                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '10px'
                    }}>
                        <div
                            style={{
                                background: '#fff',
                                color: '#222',
                                border: '1px solid #2173F7'
                            }}
                            className='btn_small'
                            onClick={() => {
                                set_show_table_input(true)
                            }}
                        >
                            Auto fill Mapping
                        </div>
                        <div

                            onClick={() => {
                                pick_table_name()
                                set_show_table_input(false)
                            }}
                            className='btn_small'

                        >
                            Manual Mapping
                        </div>
                        {show_table_input && (
                            <div style={{
                                width: "200px",
                                position: "absolute",
                                background: " #fff",
                                padding: "6px",
                                boxSizing: "border-box",
                                top: "77px",
                                right: "166px",
                                boxShadow: "2px 3px 4px #00000059",
                                borderRadius: "6px",
                                zIndex: '5'
                            }}>
                                <input style={{ width: '100%', padding: '7px', boxSizing: 'border-box' }} type="text" placeholder='Enter Your Table Name'
                                    onKeyDown={(e) => {

                                        const key_code = e.keyCode;
                                        const value = e.target.value;
                                        if (key_code === 13) {
                                            // enter prassed
                                            update_all_row_values("target_table", value, true);
                                            set_show_table_input(false)

                                        }
                                    }} />

                            </div>)}
                    </div>

                    <div style={{
                        position: 'absolute',
                        bottom: '10px',
                        right: '20px'
                    }}>
                        <RippleEffect
                            title={"Expand Source & Target Mapping"}
                            icon={<ExpandSvg size="2rem" height="2rem" color="#222" />}
                            Click={() => click_on_expander()}
                        />
                    </div>

                </div>


                <div ref={wrapperRef} style={{ width: '100%', marginBottom: '1rem' }} className="table_mapping_scroller">
                    <MappingTable
                        {...props}
                        tableHead={[
                            "source_column",
                            "target_table",
                            "target_column",
                            // "target_data_type",
                            "is_direct_mapping",
                            "criteria",
                        ]}
                        tableData={load_mapping_state}
                        on_change_v1={on_change_v1}
                        update_all_row_values={update_all_row_values}
                        target_db_id={props?.extraction_state?.target_db_id}
                        suggest_table={suggest_table}

                    />

                    {data_source_type === 'dbs' &&
                        <React.Fragment>
                            {source_columns && source_columns.length === 0 ? <p style={{ textAlign: 'center', marginTop: '2rem' }}>No Data Found</p> : null}
                        </React.Fragment>
                    }

                </div>
            </Wrapper >
        </React.Fragment >
    )
}






const mapStateToProps = (state) => ({
    collectionsOfMongo: state.EtlReducer.collectionsOfMongo,
    schema_table_info: state.reportBuilder.schema_table_info,

})

export default connect(mapStateToProps, { get_schema_table_info, getTargetTableData, getCollectionsOfMongo, pushSourceMappingDataToStore })(Index);
