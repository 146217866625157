import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import SharedTable from '../shared-table'
import CloseSvg from '../svg/close';

const MainWrapper = styled.div`
    width: 60rem;
    height: 35rem;
    background-color: #fff;
    padding: 2rem .5rem;
    padding-top: 2.5rem;
    border: 3px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
    position: relative;

    h1{
        margin: 0px;
        text-transform: capitalize;
        font-size: 1.5rem;
        text-align: center;
        margin: auto;
    }
    .close_btn{
        background: #cccccc4f;
        width: 1.8rem;
        height: 1.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover{
            background-color: #eaeaea;
            svg{
                fill: rgb(0,138,199);
            }
        }

    }


    .flex_row{
        display: flex;
        align-items: center;
        margin-top: -1.5rem;
        margin-bottom: .8rem;
        width: 100%;
        justify-content: space-between;
    }
`;



const TablePopup = (props) => {

    const { close, popupKey, option, extractedTableData, data } = props;
    const { tablName, headerTitle } = data;
    const [tableHead, setTableHead] = useState(undefined);
    const [tableBody, setTableBody] = useState(undefined);

    useEffect(() => {
        if (extractedTableData && extractedTableData.length > 0) {
            let keys = Object.keys(extractedTableData[0]);
            let temp_key_for_header = [];
            keys && keys.length > 0 && keys.forEach((k) => {
                let final_key = k.split("_").join(" ")
                temp_key_for_header.push(final_key)
            })
            setTableBody(keys)
            setTableHead(temp_key_for_header)

        }
    }, [extractedTableData])


    return (

        <MainWrapper>
            <div style={{ width: '100%', padding: '0px .5rem', boxSizing: 'border-box', borderBottom: '2px solid #ccc' }}>
                <div className="flex_row" style={{ display: 'flex', alignItems: 'center' }}>
                    {headerTitle ? <h1>{headerTitle}</h1> : tablName ? <h1>Table Name: {tablName}</h1> : null}
                    <div className="close_btn" onClick={() => close(option, popupKey)}>
                        <CloseSvg size="1rem" height="1rem" />
                    </div>
                </div>
                {extractedTableData && extractedTableData.length > 0 ? <p style={{ fontSize: '1rem', marginTop: '0rem' }}>Rows Shown Here: {extractedTableData.length}</p> : null}
            </div>
            {extractedTableData && extractedTableData.length > 0 ? (
                <SharedTable
                    height={'28rem'}
                    action={false}
                    tableData={(extractedTableData && extractedTableData.length > 0) ? extractedTableData : undefined}
                    columnsToShow={tableBody}
                    tableHead={tableHead}
                />
            ) : <p style={{ textAlign: 'center', marginTop: '2rem' }}>No Records Found..</p>}
        </MainWrapper>
    )
}



const mapStateToProps = (state) => ({
    extractedTableData: state.EtlReducer.extractedTableData,
})

export default connect(mapStateToProps, {})(TablePopup)
