import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TextBoxV1 from '../hoc/text.box.v1';
import { Button } from '../hoc/button';
import HintsAdd from '../hints.add';
import SelectOptionV1 from '../select-option/select.option.v1.1';
import { connect } from 'react-redux';

const PopupWrapper = styled.div`

    width: 35rem;
    min-height: 30rem;
    /* padding: .8rem; */
    /* border: 5px solid #CCC; */
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    max-height: 80rem;
    /* overflow: hidden; */
    /* overflow-y: auto; */


    .row{
        width: 100%;
        margin-bottom: 1rem;
    }
    .header_wrapper{
        background: linear-gradient(to right, #3E9EFF, #737DFF);
        border-radius: 20px 20px 0 0;
        height: 4rem;
        vertical-align: middle;
    }
    h1{
        font-size: 1.5rem;
        font-weight: 500;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
    }
    .body_wrapper{
        padding: .8rem;
        min-height: 26rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    }

    .change_pwd{
        text-align: right;
        span{
            font-weight: 700;
            font-size: .9rem;
            color: #542d7b;
            text-decoration: underline;
            cursor: pointer;
            /* &:hover{
            } */
        }
    }
`;




const AddEditPopup = (props) => {

    // poops and popup data here
    const { close, popupKey, option, data, accessGroupList } = props;
    const { popupData, save_user, display_field } = data;

    const [show_changed_pwd, set_show_change_pwd] = useState(false)

    // here are starte 
    const [formData, setFormData] = useState(undefined);


    const [access_group, set_access_group] = useState(undefined);
    const [report_access_group, set_report_access_group] = useState(undefined);


    const [idtr1, setIdtr1] = useState([])
    const [idtr2, setIdtr2] = useState([])
    const [idtr3, setIdtr3] = useState([])
    const [idtr4, setIdtr4] = useState([])
    const [idtr5, setIdtr5] = useState([])


    useEffect(() => {

        const access_group = (accessGroupList?.filter((e) => !e.is_report_access_group) || []);
        const report_access_group = (accessGroupList?.filter((e) => e.is_report_access_group) || []);
        set_access_group(access_group);
        set_report_access_group(report_access_group);

    }, [accessGroupList])



    useEffect(() => {

        if (popupData) {

            const clone_popupData = popupData ? { ...popupData } : {};
            clone_popupData.prev_access_group_id = popupData?.access_group_id;
            clone_popupData.prev_report_access_group_id = popupData?.report_access_group_id;
            setFormData(clone_popupData)

            if (popupData && popupData.values) {

            }
            if (popupData.idtr_1) {
                setIdtr1(popupData.idtr_1)
            }
            if (popupData.idtr_2) {
                setIdtr2(popupData.idtr_2)
            }
            if (popupData.idtr_3) {
                setIdtr3(popupData.idtr_3)
            }
            if (popupData.idtr_4) {
                setIdtr4(popupData.idtr_4)
            }
            if (popupData.idtr_5) {
                setIdtr5(popupData.idtr_5)
            }
        } else {
            setFormData({})
        }

    }, [popupData])


    const _onChange = (key, value) => {
        console.log("report_access_group_id", key, value)
        let cloneFormData = formData ? Object.assign({}, formData) : {};
        cloneFormData[key] = value;
        setFormData(cloneFormData)
    }


    const _save = () => {

        let data = formData ? Object.assign({}, formData) : {};
        data.idtr_1 = idtr1
        data.idtr_2 = idtr2
        data.idtr_3 = idtr3
        data.idtr_4 = idtr4
        data.idtr_5 = idtr5
        data.modified = true;
        delete data['client_infos'];

        if (data?.prev_access_group_id !== data?.access_group_id || data?.prev_report_access_group_id !== data?.report_access_group_id) {
            data.access_modified = true;
        }

        if (!data.access_group_id || !data.report_access_group_id) {
            alert("Please Choose Access Group")
        } else {
            save_user(data)
            close(option, popupKey)
        }


    }


    const disabled = !formData || formData && !formData.name || !formData.email


    const getHintsListByKey = (key) => {
        let after_sp_key = key.split('_');
        let match_key = after_sp_key[0] + "_" + after_sp_key[1];
        if (match_key === 'idtr_1') {
            return idtr1
        }
        if (match_key === 'idtr_2') {
            return idtr2
        }
        if (match_key === 'idtr_3') {
            return idtr3
        }
        if (match_key === 'idtr_4') {
            return idtr4
        }
        if (match_key === 'idtr_5') {
            return idtr5
        }
    }



    const setHints = (key, values) => {
        let after_sp_key = key.split('_');
        let match_key = after_sp_key[0] + "_" + after_sp_key[1];
        if (match_key === 'idtr_1') {
            setIdtr1(values)
        }
        if (match_key === 'idtr_2') {
            setIdtr2(values)
        }
        if (match_key === 'idtr_3') {
            setIdtr3(values)
        }
        if (match_key === 'idtr_4') {
            setIdtr4(values)
        }
        if (match_key === 'idtr_5') {
            setIdtr5(values)
        }
    }

    console.log("formData", formData)


    return (
        <PopupWrapper>
            <div className='header_wrapper'>
                <h1>
                    {popupData ? 'Edit User' : 'Create User'}
                </h1>
            </div>
        <div className='body_wrapper'>
            <div>
            <div className="row">
                <TextBoxV1
                    label="Name"
                    type="text"
                    value={formData && formData.name ? formData.name : ''}
                    onChange={(e) => _onChange('name', e.target.value)}
                />
            </div>
            <div className="row">
                <TextBoxV1
                    label="Email"
                    type="text"
                    value={formData && formData.email ? formData.email : ''}
                    onChange={(e) => _onChange('email', e.target.value)}
                />
            </div>

            {!popupData && (
                <div className="row">
                    <TextBoxV1
                        label="Password"
                        type="password"
                        defaultValue={'xxxxxx'}
                        value={formData && formData.new_password ? formData.new_password : ''}
                        onChange={(e) => _onChange('new_password', e.target.value)}
                    />
                </div>
            )}


            {popupData && (
                <div className='change_pwd'>
                    <span onClick={() => {
                        set_show_change_pwd(true)
                    }}> Change Password</span>
                </div>
            )}


            {show_changed_pwd && popupData && (
                <div className="row">
                    <TextBoxV1
                        label="Enter New Password"
                        type="password"
                        defaultValue={'xxxxxx'}
                        value={formData && formData.new_password ? formData.new_password : ''}
                        onChange={(e) => _onChange('new_password', e.target.value)}
                    />
                </div>
            )}

            <div className="row">
                <SelectOptionV1
                    label="Select Access Group"
                    options={access_group}
                    value={formData && formData.access_group_id ? formData.access_group_id : ''}
                    display_key={"name"}
                    value_key={"id"}
                    none_value={false}
                    on_change={(val) => {
                        _onChange('access_group_id', val)
                    }}
                />
            </div>


            <div className="row">
                <SelectOptionV1
                    label="Select Report / Dashboard Access Group"
                    options={report_access_group}
                    value={formData && formData.report_access_group_id ? formData.report_access_group_id : ''}
                    display_key={"name"}
                    value_key={"id"}
                    none_value={false}
                    on_change={(val) => {
                        _onChange('report_access_group_id', val)
                    }}
                />
            </div>
            </div>

{/* 
            {display_field && Object.keys(display_field).length > 0 && Object.keys(display_field).map((d) => {

                if (display_field[d] !== null) {
                    const existing_hints = getHintsListByKey(d)
                    return (
                        <div className="row" style={{ marginBottom: '1rem' }}>
                            <HintsAdd
                                hints={existing_hints}
                                setHints={(value) => {
                                    setHints(d, value)
                                }}
                                height={"2rem"}
                                label={display_field[d]}
                                existing_label={`Existing ${display_field[d]}`}
                            />
                        </div>
                    )
                }
            })} */}


            <div style={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'right',
                flexDirection: 'row',
                

            }}>

                <Button
                    primary={true}
                    disabled={disabled}
                    onClick={() => _save()}
                    mR="1rem"
                >
                    Save
                </Button>

                <Button
                    onClick={() => {
                        setFormData(undefined);
                        close(option, popupKey)
                    }}
                    bgColor={'#e6e6e6'}
                    color='#000000'
                    hoverBg='#d6d6d8'
                >
                    Cancel
                </Button>
            </div>
            </div>
        
        </PopupWrapper>
    )
}




const mapStateToProps = (state) => ({
    accessGroupList: state.groupAccessReducer.accessGroupList
})

export default connect(mapStateToProps, {})(AddEditPopup);
