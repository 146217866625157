import styled from 'styled-components';

export const Wrapper = styled.div`
    background: white;
    border-radius: 5px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);

    
    padding: 10px;
    border: solid 5px rgb(234, 234, 234);
    position: relative;

    .dismiss{
        position: absolute;
        right: 1rem;
        top: 1rem;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 4px;
        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        cursor: pointer;

        &:hover{
            background: #356d941c;
            svg{
                fill: rgb(0,138,199);
            }
        }
    }
`;



export const HeadingWrapper = styled.div`
    font-size: 1.4rem;
    padding: 5px 10px;
    font-weight: bolder;
    box-sizing: border-box;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    h2{
        font-size: 1.2rem;
        margin: 0px;
        text-transform: uppercase;
        font-weight: 500;
    }

`;



export const InformationWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 10px;


    table {
        max-width: 960px;
        margin: 10px auto;
    }
    caption {
        font-size: 1.6em;
        font-weight: 400;
        padding: 10px 0;
    }

    thead th {
        font-weight: 400;
        background: #8a97a0;
        color: #fff;
    }

    tr {
        background: #f4f7f8;
        border-bottom: 1px solid #fff;
        margin-bottom: 5px;
    }

    tr:nth-child(even) {
          background: #e8eeef;
    }

    th,
    td {
        text-align: left;
        padding: 20px;
         font-weight: 300;
    }

    tfoot tr {
      background: none;
    }

    tfoot td {
        padding: 10px 2px;
        font-size: 0.8em;
        font-style: italic;
        color: #8a97a0;
    }


`;


export const Detail = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 3rem;
    box-sizing: border-box;
    padding: 5px 10px;
    
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    
    border-radius: 5px;
    background: white;
    margin-bottom: 8px;
`;



export const List = styled.div`
    /* width: 200px; */
    /* padding: 10px; */
    border: 1px solid #eaeaea;
    margin: 0px;

    h1, h2,p{
        margin: 0px;
    }

    h2{
        font-weight: 400;
        background: #8a97a0;
        color: #fff;
        padding: 7px;
        font-size: 1.2rem;
        text-overflow: ellipsis;
        overflow: hidden;
        text-transform: uppercase;
        white-space: nowrap;
    }
    p{

        font-weight: 400;
        background: #f3f7f8;
        color: #000;
        padding: 7px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

    }

    p:nth-child(even) {
          background: #e8eeef;
    }
`;

export const ChartWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;

    min-width: 50vw;
    max-width: 90vw;
    min-height: 500px;
    max-height: 70vh;
    overflow-y: scroll;
`;


export const Close = styled.div`
    background: #cccccc4f;
    border-radius: relative;
    top: 10px;
    right: 10px;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    cursor: pointer;

    &:hover{
        background-color: #eaeaea;
        svg{
            fill: rgb(0,138,199);
        }
    }

`;