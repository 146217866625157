import React, { useState } from "react";
import { SendMessageContainer, SentMessageWrapper } from "./chat.styled";
import ToolTip from "../../hoc/toolTip";
import Edit from "../../svg/v2-icons/editIcon";
import SearchTextAreaBar from "../../hoc/searchTextArea";
import RippleEffect from "../../hoc/ripple-effect";

const SentMessage = ({ question, onSendAfterEdit, onChangeEditQuestion }) => {

  const [isEditing, setIsEditing] = useState(false);
  const [localQuestion, setLocalQuestion] = useState(question)

  const onSaveEdit = () => {
    console.log('editsaved');
    // let's update the current question 
  }

  

  
  return (
    <SendMessageContainer>
      {!isEditing && (
        <>
        {/* display: none; to hide for now */}
          <div className="edit_question">
            <RippleEffect
              icon={<Edit size="1.2rem" height="1.2rem" color="#222" />}
              tooltip="Edit"
              Click={() => {
                setIsEditing(true)
              }}
            />
          </div>

          <SentMessageWrapper><span>{question}</span></SentMessageWrapper>
        </>
      )}

      {!!isEditing && (
        <SearchTextAreaBar
          onSave={onSaveEdit}
          onCancelEdit={() => setIsEditing(false)}
          value={localQuestion}
          showBtnGroup={true}
          rows={3}
          type="text"
          width="100%"
          padding="16px"
          placeHolder="Ask to VADY"
          showSearchIcon={false}
          showSendBtn={false}
          showSpeakBtn={false}
          onSearch={onSendAfterEdit}
          // onKeyUp={handleEnterBtn}
          onChange={(e) => {
            setLocalQuestion(e.target.value)
          }}
          borderType={"border-radius"}
        />
      )}
    </SendMessageContainer>
  );
};

export default SentMessage;
