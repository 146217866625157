import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { getPredictivePatientsByYearly, getPredictivePatientsByMonthly, getPredictivePatientsByQuarterly } from '../../actions/predictive_analytics';
import ReportWrapper from '../report-wrapper';
import { Gap } from '../gap';
import { PredictiveWrapper } from './predictive.styled';

const PredictiveNewComponent = (props) => {

    const { predictivePatientsYearly, predictivePatientsMonthly, predictivePatientsQuaterly } = props;
    const [localPredictiveData, setPredectiveData] = useState(undefined);
    const [localPredictiveMonthly, setPredectiveDataMonthly] = useState(undefined);
    const [localPredictiveQuaterly, setPredectiveDataQuaterly] = useState(undefined);


    const [wrapperHeight, setWrapperHeight] = useState(undefined);
    const [wrapperWidth, setWrapperWidth] = useState(undefined);



    const [patient, setPatient] = useState('');
    const [patient1, setPatient1] = useState('');
    const [patient2, setPatient2] = useState('');
    const [month, setMonth] = useState('');
    const [quarter, setQuarter] = useState('');

    const wrapperRef = useRef(null);


    useEffect(() => {
        if (wrapperRef.current) {
            let _height = wrapperRef.current.offsetHeight;
            let _width = wrapperRef.current.offsetWidth;

            setWrapperHeight(_height);
            setWrapperWidth(_width);
        }
    }, []);

    useEffect(() => {
        props.getPredictivePatientsByYearly()
        props.getPredictivePatientsByMonthly()
        props.getPredictivePatientsByQuarterly()
    }, [])


    useEffect(() => {
        if (predictivePatientsYearly) {
            setPredectiveData(predictivePatientsYearly)
        }
    }, [predictivePatientsYearly])

    useEffect(() => {
        if (predictivePatientsQuaterly) {
            setPredectiveDataQuaterly(predictivePatientsQuaterly)
        }
    }, [predictivePatientsQuaterly])


    useEffect(() => {
        if (predictivePatientsMonthly) {
            setPredectiveDataMonthly(predictivePatientsMonthly)
        }
    }, [predictivePatientsMonthly])


    const get_predictive_by_patient = (type) => {
        if (type === 'year') {
            props.getPredictivePatientsByYearly(patient)
        }
        if (type === 'month') {
            let data = {
                patients: parseInt(patient1),
                month: month
            }
            props.getPredictivePatientsByMonthly(data)

        }
        if (type === 'quater') {
            let data = {
                quarter: parseInt(quarter),
                patients: parseInt(patient2)
            }
            props.getPredictivePatientsByQuarterly(data)
        }
    }



    return (
        <PredictiveWrapper  ref={wrapperRef}>
            <Gap h="100px" />
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'row',
                flexWrap: 'wrap'
            }}>
                {localPredictiveData && (
                    <div
                        style={{
                            boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
                            marginBottom: '3%',
                            borderRadius: '5px',
                            boxSizing: 'border-box',
                            padding: '10px'
                        }}
                    >
                        <h1 style={{ fontSize: '1.2rem', textAlign: 'center', fontWeight: '400' }}>Yearly Patients Revenue Prediction</h1>
                        <div className="row">
                            <input type="text" value={patient} className="inputFiled" placeholder="Patients Number" onChange={(e) => setPatient(e.target.value)} />
                            <button
                                className='btn'
                                onClick={() => {
                                    get_predictive_by_patient("year")
                                }}>PREDICT</button>
                        </div>

                        <ReportWrapper
                            isDiscovery={true}
                            id={'predictive_anality'}
                            width={.48 * wrapperWidth}
                            height={500}
                            {...props}
                            isPredictive={true}
                            reportWrapperRender={false}
                            dataFromServer={localPredictiveData}
                            sort_data={false}
                            showRightYAxis={true}
                            forcedChartType={"line_bar_chart"}
                            forcedRenderMode={'chart'}
                            hideChartOption={true}
                        />

                    </div>
                )}


                {localPredictiveMonthly && (
                    <div
                        style={{
                            boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
                            marginBottom: '3%',
                            borderRadius: '5px',
                            boxSizing: 'border-box',
                            padding: '10px'
                        }}
                    >
                        <h1 style={{ fontSize: '1.2rem', textAlign: 'center', fontWeight: '400' }}>Monthly Patients Revenue Prediction</h1>
                        <div className="row">
                            <input type="text" value={patient1} className="inputFiled" placeholder="Patients Number" onChange={(e) => setPatient1(e.target.value)} />
                            <input type="text" value={month} style={{ marginLeft: '15px' }} className="inputFiled" placeholder="Month" onChange={(e) => setMonth(e.target.value)} />
                            <button
                                className='btn'
                                onClick={() => {
                                    get_predictive_by_patient("month")
                                }}>PREDICT</button>
                        </div>
                        <ReportWrapper
                            isDiscovery={true}
                            id={'predictive_anality1'}
                            width={.48 * wrapperWidth}
                            height={500}
                            {...props}
                            isPredictive={true}
                            reportWrapperRender={false}
                            dataFromServer={localPredictiveMonthly}
                            sort_data={false}
                            showRightYAxis={true}
                            forcedChartType={"line_bar_chart"}
                            forcedRenderMode={'chart'}
                            hideChartOption={true}
                        />
                    </div>
                )}



                {localPredictiveQuaterly && (
                    <div
                        style={{
                            boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
                            marginBottom: '3%',
                            borderRadius: '5px',
                            boxSizing: 'border-box',
                            padding: '10px'
                        }}
                    >
                        <h1 style={{ fontSize: '1.2rem', textAlign: 'center', fontWeight: '400' }}>Quarter Patients Revenue Prediction</h1>
                        <div className="row">
                            <input type="text" value={patient2} className="inputFiled" placeholder="Patients Number" onChange={(e) => setPatient2(e.target.value)} />
                            <input type="text" value={quarter} style={{ marginLeft: '15px' }} className="inputFiled" placeholder="Quarter" onChange={(e) => setQuarter(e.target.value)} />
                            <button
                                className='btn'
                                onClick={() => {
                                    get_predictive_by_patient("quater")
                                }}>PREDICT</button>
                        </div>
                        <ReportWrapper
                            isDiscovery={true}
                            id={'predictive_anality2'}
                            width={.48 * wrapperWidth}
                            height={500}
                            {...props}
                            isPredictive={true}
                            reportWrapperRender={false}
                            dataFromServer={localPredictiveQuaterly}
                            sort_data={false}
                            showRightYAxis={true}
                            forcedChartType={"line_bar_chart"}
                            forcedRenderMode={'chart'}
                            hideChartOption={true}
                        />
                    </div>
                )}
            </div>

        </PredictiveWrapper>
    )
}


const mapStateToProps = (state) => ({
    predictivePatientsYearly: state.predictiveAnalyticsReducer.predictivePatientsYearly,
    predictivePatientsMonthly: state.predictiveAnalyticsReducer.predictivePatientsMonthly,
    predictivePatientsQuaterly: state.predictiveAnalyticsReducer.predictivePatientsQuaterly,
})

export default withRouter(connect(mapStateToProps, { getPredictivePatientsByYearly, getPredictivePatientsByMonthly, getPredictivePatientsByQuarterly })(PredictiveNewComponent))
