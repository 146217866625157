import { dispatchAction } from './helper';
import * as actionTypes from '../action-types/table';
import { constants } from '../utils/constants';
import { saveInSession } from '../utils/session.helper'

export const changeIndexPageType = changePageType => dispatch => {
    dispatchAction(dispatch, actionTypes.CHANGE_PAGE_TYPE_SUCCESS, changePageType, undefined, undefined, 0);
};


export const raiseExcelDownloadRequest = (id) => dispatch => {
    // console.log("raised", id)
    dispatchAction(dispatch, actionTypes.RAISE_EXCEL_DOWNLOAD_REQUEST, { id: id })
}



export const setData = data => dispatch => {
    dispatchAction(dispatch, actionTypes.SET_DATA_SUCCESS, data, undefined, undefined, 0);
};

export const setFullData = data => dispatch => {
    dispatchAction(dispatch, actionTypes.SET_FULL_DATA_SUCCESS, data, undefined, undefined, 0);
};

export const setColumns = columns => dispatch => {
    dispatchAction(dispatch, actionTypes.SET_COLUMNS_SUCCESS, columns, undefined, undefined, 0);
};

export const clearTable = () => dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_TABLE_SUCCESS, undefined, undefined, undefined, 0);
};

export const setEditedColumns = editedColumns => dispatch => {
    dispatchAction(dispatch, actionTypes.SET_EDITED_COLUMNS_SUCCESS, editedColumns, undefined, undefined, 0);
};


export const setBothColumns = (editedColumns, id) => dispatch => {
    const _hidden_column = editedColumns && Object.keys(editedColumns).length > 0 && Object.keys(editedColumns).filter(key => {
        if (!editedColumns[key].show) {
            return true;
        }
    })
    saveInSession(constants.SESSION_KEYS.TABLE_HIDDEN_COLUMNS, (_hidden_column ? JSON.stringify(_hidden_column) : []))
    dispatchAction(dispatch, actionTypes.SET_BOTH_COLUMNS_SUCCESS, { editedColumns, id }, undefined, undefined, 0);
}

export const setTableState = (data, id, columns, editedColumns, additionalHeaders, updated_at) => dispatch => {
    dispatchAction(dispatch, actionTypes.SET_TABLE_STATE_SUCCESS, { data, id, columns, editedColumns, additionalHeaders, updated_at }, undefined, undefined, 0);
};

export const updateTableColumn = (columns, id) => dispatch => {
    dispatchAction(dispatch, actionTypes.UPDATE_ACTIVE_TABLE_COLUMN, {
        id: id,
        editedColumns: columns
    })
}

export const nextData = (id) => dispatch => {
    dispatchAction(dispatch, actionTypes.NEXT_DATA_SUCCESS, { id: id }, undefined, undefined, undefined, 0);
};

export const previousData = (id) => dispatch => {
    dispatchAction(dispatch, actionTypes.PREV_DATA_SUCCESS, { id: id }, undefined, undefined, undefined, 0);
};

export const loadMore = id => dispatch => {
    dispatchAction(dispatch, actionTypes.LOAD_MORE_DATA_SUCCESS, id, undefined, undefined, 0);
};

export const setSortedDataInReducer = data => dispatch => {
    dispatchAction(dispatch, actionTypes.SET_SORTED_DATA_SUCCESS, data, undefined, undefined, 0);
};

export const scrollParentWindow = howMuchToScroll => dispatch => {
    dispatchAction(dispatch, actionTypes.SCROLL_PARENT_WINDOW_TO, howMuchToScroll, undefined, undefined, 0);
};

export const pushSortFunctionDataToReducer = (data, id) => dispatch => {
    let _data = {
        data: data,
        id: id
    }
    dispatchAction(dispatch, actionTypes.PUSH_SORT_DATA_TO_REDUCERS, _data, undefined, undefined, 0);
}

export const update_sort_order = (id, orders) => dispatch => {
    dispatchAction(dispatch, actionTypes.UPDATE_SORT_ORDERS, {
        id: id,
        sort_orders: orders
    })
}


export const update_width_additions = (id, width_additions) => dispatch => {
    dispatchAction(dispatch, actionTypes.UPDATE_WIDTH_ADDITIONS, {
        id: id,
        width_additions: width_additions
    })
}