import { simpleDispatch_post, dispatchAction, simpleDispatch_put, simpleDispatch_get, showError } from './helper';
import * as actionTypes from '../action-types/gaugeChart';
import { constants } from '../utils/constants';


export const getGaugeSegmentRangeData = (chart_type, report_item_id, report_id) => async dispatch => {

    const data = {
        chart_type: chart_type,
        report_item_id: report_item_id,
        report_id: report_id
    }
    const url = constants.END_POINTS.API + constants.END_POINTS.INSIGHTS.POINT + constants.END_POINTS.INSIGHTS.GET_CHART_CONFIG;
    await simpleDispatch_post(dispatch, url, data, actionTypes.GET_GAUGE_RANGE_DATA_LIST, true);
}



export const saveGaugeRange = (config, report_item_id, report_id) => async dispatch => {

    const body = {
        config: config,
        chart_type: "gauge_chart",
        report_item_id,
        report_id
    }

    const url = constants.END_POINTS.API + constants.END_POINTS.INSIGHTS.POINT + constants.END_POINTS.INSIGHTS.SAVE_CHART_CONFIG;
    await simpleDispatch_post(dispatch, url, body, actionTypes.SAVE_GAUGE_DATA_REQUESTED, true);

    getGaugeSegmentRangeData('gauge_chart', report_item_id,report_id)(dispatch)


};