import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { constants } from '../../utils/constants';

const Wrapper = styled.div`
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
`;

const Error = props => {
    const { history } = props;

    return (
        <Wrapper>
            <img src={constants.CONTEXT_PATH + './face_logo.png'} alt='polus' style={{ maxWidth: '100%' }} />
            <ul>
                <li
                    style={{
                        fontSize: '1.4rem'
                    }}
                >
                    Polus unable to find the result. Please train your Polus
                    <ul>
                        <li
                            onClick={() => history.push('/train_polus')}
                            style={{
                                fontWeight: 'bolder',
                                color: 'black',
                                cursor: 'pointer',
                                fontSize: '1.2rem'
                            }}
                        >
                            Train Your Polus
                        </li>
                    </ul>
                </li>
            </ul>
        </Wrapper>
    )
};


export default withRouter(Error);