import * as actions from '../action-types/nf_column_info_ad_tech';

const initialState = {
    type: actions.NF_COLUMN_INFO_INIT,
    columnInfo: undefined
};

const columnInfoReducer = (state, action) => {
	if (!state || typeof action.type === 'undefined') {
		return initialState;
    }

    switch (action.type) {
        case actions.GET_NF_COLUMN_INFO_SUCCESS:
            return {
                type: action.type,
                columnInfo: action.data
            };

        default:
			return {
                ...state
            };
        }
};

export default columnInfoReducer;