import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import CloseSvg from '../svg/close';
import { get_table_data, clearTableNameAndTableData, get_db_info, getSqlQuery } from '../../actions/connection';
import { Wrapper, LeftSection, RightSection, Row } from './editor.styled';
import { withRouter } from 'react-router-dom';
import { find_sql_query_table_column_information, generate_unique_key, getClientId, getParameterByName, get_matched_parm_from_query, replace_query_with_match_parm_value } from '../../utils'
import * as _ from 'lodash';
import { saveReport, getReportById, getAllReports, get_tbl_formulas_for_editor, db_functions_config, test_sql_query, clear_data_for_report } from '../../actions/report';
import { getAllRelationships } from '../../actions/relationship.detail';
import * as enums from '../../utils/enums';
import { showPopup, } from '../../utils';
import Criteria from '../criteria/new.criteria';
import TextBoxV1 from '../hoc/text.box.v1'
import TextBox from '../hoc/textBox'
import ConfirmBox from '../confirm-box';
import ElementBox from './element.box';
import RelationshipForm from './relationship.form';
import LeftSidePanel from './left.side.panel';
import EditorHeader from './editor.header';
import ColumnInfoEditor from './column.info';
import RippleEffect from '../hoc/ripple-effect';
import DeleteSvg from '../svg/delete';
import FormulaEditorPopup from '../formatting/formula.editor.popup';
import ParameterizedPopup from './parameterize.fields.popup';
import ParameterizedDataPopup from '../report-editor/p.field';
import AddSvg from '../svg/add'
import SqlEditor from '../sql.editor';
import SharedTable from '../shared-table';
import WarningSvg from '../svg/warning'
import PlaySvg from '../svg/play';
import SelectOption from '../select-option';
import { CHART_TYPES, CHART_TYPES_V1 } from '../../shared-with-fe/constants'
import ThemePanel from './theme.panel';
import { showRighter } from '../../utils';
import SelectOptionV1 from '../select-option/select.option.v1.1';
import OptimizerHintsPopup from './optimizer.popup';
import SqlBuilderWeight from './sql.editor.section';
import DefaultFilterChooser from './default.filter.popup';
import FreeSelectBox from '../select-option/free.select.box.v1.1';
import { NormalButton } from '../hoc/normal.button';


/***
 * new regin code
 */
import { getReportByReportId } from '../../actions/report.helper';


import { get_schema_table_info, clear_schema_table_info } from '../../actions/report_builder';
import { get_elements_for_render, change_column_order_v1, get_existence_state_and_highest_field_order_v1, get_highest_field_order_and_index_v2 } from './shared.pivot';

import {

    drop_column_for_editor_v1,
    get_final_save_report_item,
    find_criteria_suggesition_by_report_table,
    find_relationship,
    column_proprty_on_change,
    sort_table_and_column,
    search_table_and_column,
    change_render_mode_v1,
    get_pivot_field_in_proper_case,
    get_pivot_charts_json,
    remove_table_v1,
    find_table_which_not_have_relationship_v1,
    convert_display_fields_into_table_column_for_ds_v1

} from './builder.shared.functions';
import { getAccessGroup } from '../../actions/access_group';
import { getMenuGroups } from '../../actions/menu.group';
import { Button } from '../hoc/button';
import { getAllDataSource } from '../../actions/report.helper';





var id_to_use_in_sql = 'randorm232'
var all_table_uniq_keys = []


const Editor = (props) => {




    const {

        type,
        location,
        get_db_info,
        getAllRelationships,
        relationships,
        is_filter,

        report_title,
        change_report_type,
        edit_report_cache

    } = props;



    // required props 



    const containerRef = useRef();

    // popup props 

    const { close, popupKey, option } = props;

    const {

        hide_header = false,
        hide_user_data_source = false,
        hide_report_inputs = false,
        hide_compiler_hints = false,
        hide_filter_fields = false,
        report_field_title,
        is_etl = false,
        

    } = (props?.data || props);



    const {

        db_info_id,
        default_render_mode,
        default_report_mode,
        save_function,
        current_report,
        report_type,
        edit_report_id,
        update_sync,
        sync

    } = (props?.data || props)


    const is_popup_window = props.data; /// 


    const { get_schema_table_info, getReportByReportId } = props;

    const { schema_table_info } = props;
    const { search } = location;
    const [activeTable, setActiveTable] = useState(undefined);

    // const [connectionId, setConnectionId] = useState(undefined);
    const [connectionId, setConnectionId] = useState([]);
    const [render_mode, set_render_mode] = useState("table");
    const [showColumns, setShowColumns] = useState(undefined);
    const [localTableColumns, setLocalTableColumns] = useState({});
    const [criteriaSuggestions, setCriteriaSuggestions] = useState(undefined);
    const [activeReportIndex, setActiveReportIndex] = useState(undefined)
    const [activeColumn, setActiveColumn] = useState(undefined);
    const [localReport, setLocalReport] = useState(undefined);
    const [localReportItems, setLocalReportItems] = useState([{}]);
    const [activeReport, setActiveReport] = useState(0);
    const [activeReportId, setActiveReportId] = useState(undefined);
    const [filterColumns, setFilterColumns] = useState(undefined);
    const [showAddColumn, setShowAddColumn] = useState(undefined);
    const [added_column_name, set_added_column_name] = useState(undefined);
    const [parameters_fields, set_parameterized_fields] = useState(undefined);
    const [optimizer_hints, set_optimizer_hints] = useState(undefined);
    const [isColumnOpen, setIsColumnOpen] = useState(undefined);
    const [active_editor, set_active_editor] = useState(report_type ? report_type : "config_query_builder");
    const [sql_query, set_sql_query] = useState(undefined);
    const [inital_query, set_inital_query] = useState();
    const [enable_editing, set_enable_editing] = useState(undefined);
    const [theme_json_values, set_theme_json_values] = useState(undefined);
    const [pivot_box_jsons, set_pivot_box_jsons] = useState(undefined)
    const [show_sql_editor, set_show_sql_editor] = useState(undefined)
    const [report_sql, set_report_sql] = useState(undefined);
    const [sql_table_column_info, set_sql_table_column_info] = useState(undefined)

    const [access_group_report_mapping, set_access_group_report_mapping] = useState(['any'])
    const [menu_group_id, set_menu_group_id] = useState('')
    const [is_menu_group_new, set_is_menu_group_new] = useState(undefined)

    const [open_table_column, set_open_table_column] = useState(undefined)



    /***
     * 
     */

    const [filter_default_value, set_filter_default_value] = useState(undefined);


    const [dataSourceColumnInfo, setDataSourceColumnInfo] = useState(undefined);



    /**
     * when component is mounted this useEffect(() => {}) will be called
     */

    useEffect(() => {

        if (!props.dataSources || props.dataSources?.length === 0) {
            props.getAllDataSource()
        }

        props.db_functions_config()

        if (!props.db_infos || props.db_infos?.length === 0) get_db_info()

        getAllRelationships()
        set_pivot_box_jsons(get_pivot_charts_json())
        props.getAccessGroup()
        set_access_group_report_mapping(['any'])
        props.getMenuGroups()
        // id_to_use_in_sql = generate_unique_key('sql_mode')
    }, [])



    useEffect(() => {

        const clone_all_reports = props.dataSources ? [...props.dataSources] : [];

        if (clone_all_reports?.length > 0) {

            const local_table_column_info = {};

            clone_all_reports?.forEach(report => {

                if (report.name) {

                    const report_items = report.report_items;
                    const table_columns = convert_display_fields_into_table_column_for_ds_v1(report_items, report.name);

                    local_table_column_info[report.name] = {};
                    table_columns.forEach(c => {
                        const name = c.alias || c.column_name;
                        local_table_column_info[report.name][name] = {
                            type: c.data_type
                        }
                    })
                }
            })

            setDataSourceColumnInfo(local_table_column_info)

            // console.log("local_table_column_info", local_table_column_info)
        }

    }, [props.dataSources])



    // this is only for sql 
    useEffect(() => {
        id_to_use_in_sql = generate_unique_key('sql_mode')
        if (!editReport) {
            set_sql_query(undefined)
            set_inital_query(undefined)
        }
    }, [search])


    const open_default_filter_popup = () => {
        showPopup('Choose Default Period', undefined, enums.default.popupType.element_with_header, DefaultFilterChooser, {
            filter_default_value: filter_default_value,
            set_filter_default_value: set_filter_default_value
        },
            undefined,
            undefined,
            undefined,
            {
                width: '27rem'
            })
    }



    const get_db_type = () => {
        const current_connection = connectionId && props.db_infos?.length > 0 && props.db_infos.find((d) => d.id === connectionId[0]);
        const db_type = connectionId && connectionId.length > 1 ? 'mysql' : current_connection?.db_type;
        return db_type
    }

    useEffect(() => {
        if (props.sqlQueryResult && props.sqlQueryResult.sql) {
            showPopup('SQL Query', props.sqlQueryResult.sql, enums.default.popupType.sqlQuery, undefined, undefined)
        }
    }, [props.sqlQueryResult])



    /***
     * this will update the connectionId
     * 
     */
    const if_we_have_only_one_db_id_then_set_db_id = (p) => {
        try {

            const db_infos = props.db_infos;

            if (db_infos && db_infos.length > 0) {
                if (db_info_id) {
                    setConnectionId([db_info_id])
                    get_schema_table_info([db_info_id])
                }
                else {
                    // console.log("called", props.db_infos)
                    setConnectionId([db_infos[0].id])
                    get_schema_table_info([db_infos[0].id])
                }

            }
        } catch (error) {

        }
    }



    useEffect(() => {
        const rpt_id = edit_report_id || getParameterByName('report_id', search);
        if (rpt_id) {
            getReportByReportId(rpt_id);
            // props.get_tbl_formulas_for_editor(rpt_id, undefined, "report_editor")
            setActiveReportId(rpt_id);
        }
    }, [search, edit_report_id]);



    useEffect(() => {
        if_we_have_only_one_db_id_then_set_db_id("useeffect")
    }, [props.db_infos, search])


    const rpt_id = edit_report_id || getParameterByName('report_id', search);
    const editReport = current_report || (rpt_id && (props.editCurrentReport || edit_report_cache && edit_report_cache[rpt_id]));


    useEffect(() => {

        if (editReport && Object.keys(editReport).length > 0) {

            const clone_report = editReport ? Object.assign({}, editReport) : {};
            const __report__ = clone_report ? JSON.parse(JSON.stringify(clone_report)) : {};


            if (!is_popup_window) {

                set_filter_default_value(JSON.parse(__report__.filter_default_value_json || '{}'))

                const access_group_report_mapping = clone_report?.access_group_report_mapping;
                const access_group_report_mapping_ids = access_group_report_mapping?.length > 0 && access_group_report_mapping?.map(a => a.access_group_id) || [];

                if (access_group_report_mapping_ids && access_group_report_mapping_ids?.length > 0) {
                    set_access_group_report_mapping([...new Set(access_group_report_mapping_ids)])
                } else set_access_group_report_mapping(['any'])
            }

            if (clone_report.menu_group_id) set_menu_group_id(clone_report.menu_group_id)
            else set_menu_group_id('')

            delete __report__.report_items;
            setActiveReportId(editReport.id)
            set_active_editor(clone_report.type)
            id_to_use_in_sql = editReport.id

            const report_items = clone_report && clone_report.report_items ? [...clone_report.report_items] : [];
            const sorted_report_items = report_items && report_items.length > 0 && report_items.sort((a, b) => {
                return a.order - b.order;
            });

            if (report_items && report_items[0] && report_items[0].sql_query) {
                set_sql_query(report_items[0].sql_query)
                set_inital_query(report_items[0].sql_query)
            }

            const criteriaSuggestions = [];

            sorted_report_items && sorted_report_items.length > 0 && sorted_report_items.forEach((report_item, report_index) => {
                const __json_data = report_item.json_data ? JSON.parse(report_item.json_data) : {};
                const column_infos = report_item.column_infos;
                const table_names_from_json_data = __json_data.table_names;
                const _display_fields = column_infos?.length > 0 && column_infos.sort((a, b) => {
                    return a.order - b.order;
                }) || [];

                // here we are assigning the index key for using index
                _display_fields.forEach((row, index) => row.index = index)
                const suggestion = find_criteria_suggesition_by_report_table(schema_table_info, table_names_from_json_data)
                criteriaSuggestions.push(suggestion)

                /***
                 * this is patch code of user inputs value 
                 */
                _display_fields.map(c => {
                    const user_input_val = c.user_input_values ? c.user_input_values : {};
                    user_input_val && Object.keys(user_input_val).forEach((k) => {
                        c[k] = user_input_val[k];
                    })
                })

                if (report_index === 0) {
                    const filter_columns = [];
                    report_item.parameters && report_item.parameters.length > 0 && report_item.parameters.forEach((filter) => {
                        filter_columns.push({ column_name: filter })
                    })
                    setFilterColumns(filter_columns)
                }

                const final_d_fields = [..._display_fields];
                const relationship_details = __json_data && __json_data.relationship_details;
                report_item.display_columns = final_d_fields
                report_item.relationship_details = relationship_details;
                report_item.table_names = table_names_from_json_data;
                set_optimizer_hints(report_item.optimizer_hints);
            })

            const report_item_chart_type = sorted_report_items[0].chart_type;
            const chart_type = (report_item_chart_type && report_item_chart_type === 'table') ? 'table' : report_item_chart_type;
            set_render_mode((chart_type ? chart_type : 'table'))
            set_theme_json_values(sorted_report_items?.[0]?.theme_json_values)

            setCriteriaSuggestions(criteriaSuggestions)
            set_parameterized_fields(clone_report.parameterized_fields)
            const db_ids_in_array = __report__.db_info_id.split(',')
            setConnectionId(db_ids_in_array)
            get_schema_table_info(db_ids_in_array)
            setLocalReport(__report__)

            console.log("sorted_report_items", __report__)
            setLocalReportItems(sorted_report_items)

        }
        else {
            setLocalReportItems([{}])
            setLocalReport(undefined)
            setLocalTableColumns(undefined)
            setCriteriaSuggestions(undefined)
            set_parameterized_fields(undefined)
            setActiveReportId(undefined)
            // setConnectionId(undefined)
            setFilterColumns(undefined)
            props.clear_schema_table_info()
            set_sql_query(undefined)
            set_inital_query(undefined)
        }
    }, [editReport])





    // schema_table_info
    // if schema_table_info will change then we will update our criteriaSuggestions
    useEffect(() => {

        if (editReport && Object.keys(editReport).length > 0) {

            const clone_report = editReport ? Object.assign({}, editReport) : {};
            const report_items = clone_report && clone_report.report_items ? [...clone_report.report_items] : [];
            const criteriaSuggestions = [];

            report_items && report_items.length > 0 && report_items.forEach((report_item) => {
                const __json_data = report_item.json_data ? JSON.parse(report_item.json_data) : {};
                const table_names_from_json_data = __json_data.table_names;
                const suggestion = find_criteria_suggesition_by_report_table(schema_table_info, table_names_from_json_data)
                criteriaSuggestions.push(suggestion)
            })
            setCriteriaSuggestions(criteriaSuggestions)
        }

        if (connectionId && connectionId.length > 0) {
            const client_id = getClientId()
            const sorted_schema_table = sort_table_and_column(schema_table_info, client_id?.['client-id'], props.hints_table);
            setLocalTableColumns(sorted_schema_table)
            set_enable_editing(true)
        }
        else {
            setLocalTableColumns(undefined)
            set_enable_editing(false)
        }
        if (schema_table_info && connectionId) {
            set_enable_editing(true)
        } else set_enable_editing(false)

    }, [schema_table_info, editReport])


    /**
     * this function is used for finding relationships between tables
     */

    const local_find_relationship = (tabels, index) => {
        const db_type = get_db_type()
        setLocalReportItems(find_relationship(localReportItems, relationships, tabels, index, db_type))
    }

    useEffect(() => {
        const criteriaSuggestions = [];
        localReportItems && localReportItems.length > 0 && localReportItems.map((reportItem) => {
            const suggestion = find_criteria_suggesition_by_report_table(schema_table_info, reportItem.table_names)
            criteriaSuggestions.push(suggestion)
        })
        setCriteriaSuggestions(criteriaSuggestions)
    }, [localReportItems])



    const onDropTable = (event, index) => {

        const reportItemsClone = localReportItems ? [...localReportItems] : [];
        const targetReportItem = reportItemsClone[index];
        const tables_clone = (targetReportItem?.table_names) ? [...targetReportItem.table_names] : [];

        const column_info = JSON.parse(event.dataTransfer.getData("column_info") || "{}")
        const { column_name, table_schema, table_name, schema_id, plugin, schema_name, db_type, connection_name, data_transfer_mode, data_transfer_from } = column_info;

        const is_table_exist = tables_clone.some(el => el.name === table_name);
        if (!is_table_exist && table_name) tables_clone.push({ name: table_name, table_schema: table_schema, schema_id: schema_id, db_type: db_type })
        targetReportItem.table_names = tables_clone;

        targetReportItem['delete_from_storage'] = true;
        targetReportItem['call_java_forcefully'] = true;


        reportItemsClone[index] = targetReportItem;

        setLocalReportItems(reportItemsClone)
        local_find_relationship([table_name], index)
    }


    const change_render_mode_local = (render_mode) => {
        const result = change_render_mode_v1(localReportItems, render_mode)
        setLocalReportItems(result?.report_items)
        set_render_mode(result?.render_mode)

    }


    const drop_column = (event, key, pivot_type, index) => {
        const db_type = get_db_type()
        const dropped_results = drop_column_for_editor_v1(localReportItems, schema_table_info, filterColumns, relationships, event, key, pivot_type, index, db_type)
        setFilterColumns(dropped_results.filter_fields)
        setLocalReportItems(dropped_results.reportItems)
    }


    const onSelectedColumnClick = (key = 'selected_columns', data, index, report_index) => {
        if (key === 'selected_columns') {
            setActiveColumn(data);
            setActiveReportIndex(report_index)
        }
        if (key === 'parameterized') {
            setActiveColumn(data);
            setActiveReportIndex(report_index)
        }
    };

    const column_proprty_on_change_local = (key, value, index, param_key, is_user_input_key) => {
        const db_type = get_db_type()
        const result = column_proprty_on_change(db_type, props.functions_config, activeColumn, localReportItems, key, value, index, param_key, is_user_input_key)
        setLocalReportItems(result?.report_items)
        setActiveColumn(result?.active_columns)
    }


    const onDelete = (key, index, table_name, report_index) => {


        const reportItemsClone = localReportItems ? [...localReportItems] : [];
        const targetReportItem = reportItemsClone[report_index];
        const display_columns = (targetReportItem?.display_columns) ? [...targetReportItem.display_columns] : [];
        const filter_fields = filterColumns ? [...filterColumns] : [];

        // deleted
        if (key === 'selected_columns') {
            const element_index_to_use = display_columns?.findIndex(c => c.index === index);
            display_columns[element_index_to_use]["deleted"] = true;

            const how_many_items = display_columns?.length > 0 && display_columns.filter((c) => {
                if (c.table_name === table_name && !c.deleted) return true;
                else return false;
            })
            if ((how_many_items?.length == 0) || !how_many_items) {
                // _remove_table(table_name, report_index)
            }
        }


        if (key === 'filter_fields') {
            filter_fields.splice(index, 1)
            const how_many_items = filter_fields?.length > 0 && filter_fields.filter((c) => {
                if (c.table_name === table_name) return true;
                else return false;
            })
        }

        targetReportItem.display_columns = display_columns;
        targetReportItem.display_columns = display_columns;

        targetReportItem['delete_from_storage'] = true;
        targetReportItem['call_java_forcefully'] = true;

        reportItemsClone[report_index] = targetReportItem;
        setLocalReportItems(reportItemsClone)
        // console.log("filter_fields", filter_fields)
        setFilterColumns(filter_fields)
        setActiveColumn(undefined)
        setActiveReportIndex(undefined)
    }


    const remove_pirtual_element_from_array = (key, index, table_name, report_index) => {
        showPopup(undefined, 'Are you sure you want to delete this ? ', enums.default.popupType.element, ConfirmBox, { func: { setYes: () => onDelete(key, index, table_name, report_index) } })
    }


    const local_save = (report, is_run_report, type, parameters_fields) => {
        props.saveReport([report], props.history, undefined, is_run_report, undefined, true, type, parameters_fields, undefined, filter_default_value);

    }


    const get_filter_column = () => {
        const columns = [];
        filterColumns && filterColumns.length > 0 && filterColumns.forEach((f) => {
            if (!f.deleted) {
                columns.push(f.column_name)
            }
        })
        return columns;
    }



    const get_all_table_column_of_table = (tables = []) => {

        const tables_to_use = [...new Set(tables)];

        // localTableColumns
        const date_columns = [];

        const all_table_and_columns = connectionId && localTableColumns?.[connectionId?.[0]];

        tables_to_use?.forEach((__table) => {
            const table = __table?.split('.')[__table?.split('.').length - 1];


            if (all_table_and_columns?.[table] && Object.keys(all_table_and_columns?.[table]).length > 0) {
                Object.keys(all_table_and_columns?.[table]).forEach((c) => {
                    if (
                        all_table_and_columns?.[table]?.[c]?.type === "date" ||
                        all_table_and_columns?.[table]?.[c]?.type === "datetime" ||
                        all_table_and_columns?.[table]?.[c]?.type === "timestamp" ||
                        all_table_and_columns?.[table]?.[c]?.type === "datetime2" ||
                        all_table_and_columns?.[table]?.[c]?.type === "complex_date" ||
                        all_table_and_columns?.[table]?.[c]?.type === "yyyymmdd" ||
                        all_table_and_columns?.[table]?.[c]?.type === "seconds"


                    ) {
                        const n = table + "." + c + "__data__type__" + all_table_and_columns?.[table]?.[c]?.type;
                        date_columns.push(n)
                    }
                })
            }
        })

        return date_columns;
    }




    // save_function


    const on_save_v1 = (is_run_report, view_sql) => {

        const report = localReport ? { ...localReport } : {};

        report && report.id && props.clear_data_for_report(report.id);

        setTimeout(() => {

            let _is_processed = true;

            const _t_name = find_table_which_not_have_relationship_v1(localReportItems);

            if (_t_name && _t_name.length > 0) {
                _is_processed = false;
            }
            if (!connectionId) {
                _is_processed = false;
                alert("please Select Schema Name")
            }

            if (_is_processed) {

                const client_id = getClientId()['client-id'];
                report.type = active_editor;
                report.is_active = true;
                report.db_info_id = connectionId.join(',');
                report.modified = true;

                let tables_for_date_columns = [];

                const report_items = localReportItems ? JSON.parse(JSON.stringify(localReportItems)) : [];

                report_items?.length > 0 && report_items.map((reportItem, index) => {

                    const relationshipfinalOutput = [];

                    const db_type = get_db_type();

                    const final_save_output = get_final_save_report_item(reportItem, db_type);

                    const final_column_info = reportItem.display_columns && reportItem.display_columns.length > 0 && reportItem.display_columns.filter((col) => {
                        if (!col.id && col.deleted) return false;
                        else return true;
                    }) || [];

                    const report_item_auto_criteria = [];
                    // here we are changing the pivot display field in proper case
                    final_column_info?.forEach((__row__) => {

                        if (__row__.auto_criteria && !__row__.deleted) {
                            report_item_auto_criteria.push(__row__.auto_criteria);
                        }
                        // pivot_aggregation
                        const proper_case = !__row__.is_formula ? get_pivot_field_in_proper_case(__row__.pivot_field_column_name) : __row__.pivot_field_column_name
                        __row__.pivot_field_column_name = proper_case;
                        if (!__row__.is_repeater) {
                            __row__.pivot_field_alias = proper_case
                        }
                    })



                    reportItem.modified = true;
                    reportItem.column_infos = final_column_info;
                    reportItem.auto_criteria = report_item_auto_criteria ? report_item_auto_criteria.join(" or ") : undefined;


                    const hsl_auto_criteria_config = {
                        "tbl_targets": { "month": "target_month", "year": "target_year" },
                        "tbl_nsr_rate": { "month": "month", "year": "year" },
                        "tbl_nsr_rates": { "month": "month", "year": "year" },
                        "tbl_extra_rmcost": { "month": "month", "year": "year" },
                        "tbl_rm_newrates": { "month": "month", "year": "year" },
                        "tbl_freight": { "month": "month", "year": "year" },
                        "tbl_variable_cost": { "month": "month", "year": "year" },
                        "tbl_adjusted_price": { "month": "month", "year": "year" },
                    }

                    // this code is only for HSL
                    client_id && client_id === 'hsl' && final_save_output?.table_names?.length > 0 && final_save_output?.table_names?.forEach(a => {
                        if (hsl_auto_criteria_config[a]) {
                            if (reportItem.auto_criteria && reportItem.auto_criteria.length > 5) {
                                reportItem.auto_criteria = "(" + reportItem.auto_criteria + ") And " + ` ((${a}.${hsl_auto_criteria_config[a]["month"]}=` + "':nf_month'" + ` or ${a}.${hsl_auto_criteria_config[a]["month"]} is null) And (${a}.${hsl_auto_criteria_config[a]["year"]}= ` + "':nf_year' or " + `${a}.${hsl_auto_criteria_config[a]["year"]} is null)) `;
                                // reportItem.auto_criteria = "(" + reportItem.auto_criteria + ") And " + " ((tbl_targets.target_month= ':nf_month' or tbl_targets.target_month is null) And (tbl_targets.target_year= ':nf_year' or tbl_targets.target_year is null)) ";
                            } else {
                                reportItem.auto_criteria = " " + `((${a}.${hsl_auto_criteria_config[a]["month"]}=` + " ':nf_month'" + `or ${a}.${hsl_auto_criteria_config[a]["month"]} is null) And (${a}.${hsl_auto_criteria_config[a]["year"]}=` + " ':nf_year' " + `or ${a}.${hsl_auto_criteria_config[a]["year"]} is null))`
                            }
                        }
                    })


                    const __json__data = {
                        "display_columns": reportItem.display_columns,
                        "relationship_details": reportItem.relationship_details,
                        "filter_fields": filterColumns,
                        "table_names": reportItem.table_names
                    }

                    reportItem.json_data = JSON.stringify(__json__data);
                    reportItem.relationship_details?.length > 0 && reportItem.relationship_details.forEach((r) => {
                        let _str = r.source_table + '.' + r.source_column + "=" + r.target_table + '.' + r.target_column + "@" + r.join_type;
                        relationshipfinalOutput.push(_str)
                    })
                    reportItem.order = index;
                    reportItem.relationship_details = relationshipfinalOutput;
                    reportItem.display_columns = final_save_output?.display_columns;
                    reportItem.tbl_ui_formulas = final_save_output?.formulas;
                    reportItem.table_names = final_save_output?.table_names;
                    reportItem.optimizer_hints = optimizer_hints;
                    reportItem.reporting_db_info_id = connectionId.join(',');


                    tables_for_date_columns = [...tables_for_date_columns, ...(final_save_output?.table_names || [])]
                })


                if (report_items && report_items.length > 0) {
                    report_items[0].parameters = get_filter_column();
                    report_items[0].modified = true;
                    report_items[0].chart_type = render_mode;
                    report_items[0].render_mode = (render_mode === 'table') ? render_mode : "chart";
                    report_items[0].theme_json_values = theme_json_values
                }

                report.report_items = report_items;

                const criteria = report_items[0]?.criteria;
                report.filter_default_value_json = JSON.stringify(filter_default_value || {});
                const report_parameters_fields = get_matched_parm_from_query(criteria);

                report.parameterized_fields = report_parameters_fields ? Object.keys(report_parameters_fields) : undefined;


                if (access_group_report_mapping && access_group_report_mapping.length > 0) {

                    report.access_group_report_mapping = access_group_report_mapping;

                } else {

                    report.access_group_report_mapping = ["any"]
                }

                report.menu_group_id = menu_group_id;
                report.is_menu_group_new = is_menu_group_new;

                const date_columns = get_all_table_column_of_table(tables_for_date_columns);

                save_function(report, is_run_report, {
                    date_columns: date_columns,

                });

                if (is_popup_window) {
                    close(option, popupKey)
                }
            }
        }, 200);
    }




    const onSave = (is_run_report, view_sql) => {

        const report = localReport ? { ...localReport } : {};

        report && report.id && props.clear_data_for_report(report.id);

        setTimeout(() => {

            let _is_processed = true;

            if (!report.name) {
                _is_processed = false;
                alert("Please Provide Report Name")
            }

            if (localReportItems && localReportItems.length > 0 && localReportItems[0].column_info && localReportItems[0].column_info.length > 0) {
                _is_processed = false;
                alert('Please Provide Display Fields')
            }

            const _t_name = find_table_which_not_have_relationship_v1(localReportItems);

            if (_t_name && _t_name.length > 0) {
                _is_processed = false;
            }
            if (!connectionId) {
                _is_processed = false;
                alert("please Select Schema Name")
            }

            if (_is_processed) {
                const client_id = getClientId()['client-id'];

                report.type = active_editor
                report.is_active = true;
                report.db_info_id = connectionId.join(',');
                report.modified = true;
                const report_items = localReportItems ? JSON.parse(JSON.stringify(localReportItems)) : [];

                const is_pivot = Object.values(CHART_TYPES).indexOf(render_mode) > -1;

                report_items?.length > 0 && report_items.map((reportItem, index) => {

                    const relationshipfinalOutput = [];

                    const db_type = get_db_type();

                    const final_save_output = get_final_save_report_item(reportItem, db_type, is_pivot);

                    const final_column_info = reportItem.display_columns && reportItem.display_columns.length > 0 && reportItem.display_columns.filter((col) => {
                        if (!col.id && col.deleted) return false;
                        else return true;
                    }) || [];

                    const report_item_auto_criteria = [];
                    // here we are changing the pivot display field in proper case
                    final_column_info?.forEach((__row__) => {

                        if (__row__.auto_criteria && !__row__.deleted) {
                            report_item_auto_criteria.push(__row__.auto_criteria);
                        }
                        // pivot_aggregation
                        const proper_case = !__row__.is_formula ? get_pivot_field_in_proper_case(__row__.pivot_field_column_name) : __row__.pivot_field_column_name
                        __row__.pivot_field_column_name = proper_case;
                        if (!__row__.is_repeater) {
                            __row__.pivot_field_alias = proper_case
                        }
                    })



                    reportItem.modified = true;
                    reportItem.column_infos = final_column_info;
                    reportItem.auto_criteria = report_item_auto_criteria ? report_item_auto_criteria.join(" or ") : undefined;

                    // this code is only for HSL
                    client_id && client_id === 'hsl' && final_save_output?.table_names?.length > 0 && final_save_output?.table_names?.forEach(a => {
                        if (a === 'tbl_targets') {
                            if (reportItem.auto_criteria && reportItem.auto_criteria.length > 1) {
                                reportItem.auto_criteria = "(" + reportItem.auto_criteria + ") And " + " tbl_targets.target_month= ':nf_month'";
                            } else {
                                reportItem.auto_criteria = "tbl_targets.target_month= ':nf_month'"
                            }
                        }
                    })


                    const __json__data = {
                        "display_columns": reportItem.display_columns,
                        "relationship_details": reportItem.relationship_details,
                        "filter_fields": filterColumns,
                        "table_names": reportItem.table_names
                    }

                    reportItem.json_data = JSON.stringify(__json__data);
                    reportItem.relationship_details?.length > 0 && reportItem.relationship_details.forEach((r) => {
                        let _str = r.source_table + '.' + r.source_column + "=" + r.target_table + '.' + r.target_column + "@" + r.join_type;
                        relationshipfinalOutput.push(_str)
                    })
                    reportItem.order = index;
                    reportItem.relationship_details = relationshipfinalOutput;
                    reportItem.display_columns = final_save_output?.display_columns;
                    reportItem.tbl_ui_formulas = final_save_output?.formulas;
                    reportItem.table_names = final_save_output?.table_names;
                    reportItem.optimizer_hints = optimizer_hints
                })


                if (report_items && report_items.length > 0) {
                    report_items[0].parameters = get_filter_column();
                    report_items[0].modified = true;
                    report_items[0].chart_type = render_mode;
                    report_items[0].render_mode = (render_mode === 'table') ? render_mode : "chart";
                    report_items[0].theme_json_values = theme_json_values
                }

                report.report_items = report_items;

                const criteria = report_items[0]?.criteria;
                report.filter_default_value_json = JSON.stringify(filter_default_value || {});
                const report_parameters_fields = get_matched_parm_from_query(criteria);

                report.parameterized_fields = report_parameters_fields ? Object.keys(report_parameters_fields) : undefined;


                if (access_group_report_mapping && access_group_report_mapping.length > 0) {
                    report.access_group_report_mapping = access_group_report_mapping;
                } else {
                    report.access_group_report_mapping = ["any"]
                }

                report.menu_group_id = menu_group_id;
                report.is_menu_group_new = is_menu_group_new;




                if (report_parameters_fields && Object.keys(report_parameters_fields).length > 0 && is_run_report) {
                    showPopup('Please fill out the below form', undefined, enums.default.popupType.element_with_header, ParameterizedDataPopup, {
                        parameters_fields: Object.keys(report_parameters_fields),
                        report_name: report.name,
                        view_function: (_field_data_) => {
                            local_save(report, is_run_report, type, _field_data_)
                        },
                    },
                        undefined,
                        undefined,
                        undefined,
                        {
                            width: '27rem'
                        })
                } else {
                    if (!is_run_report) {
                        props.saveReport([report], props.history, undefined, is_run_report, undefined, true, type, undefined, view_sql, filter_default_value);
                        // props.history.push('/report_list')
                    }
                    else {
                        props.saveReport([report], props.history, undefined, is_run_report, undefined, true, type, undefined, view_sql, filter_default_value);
                    }
                }
            }
        }, 200);
    }


    const searchTable = (value) => {
        setLocalTableColumns(search_table_and_column(value, schema_table_info))
    }

    /**
     * 
     * @param {*} event 
     * @param {*} key 
     * @param {*} report_index 
     */
    const change_column_order = (event, key, report_index) => {
        // change_column_order_v1
        const reportItemsClone = localReportItems ? [...localReportItems] : [];
        const targetReportItem = reportItemsClone[report_index];
        const display_columns = (targetReportItem?.display_columns) ? [...targetReportItem.display_columns] : [];
        const filter = filterColumns ? filterColumns : []

        if (key === "selected_columns") {
            const sort_field_order_column = (render_mode === "table" || !render_mode) ? "order" : "pivot_field_order";
            const final_display_column = change_column_order_v1(JSON.parse(JSON.stringify(display_columns)), event, sort_field_order_column)
            targetReportItem.display_columns = final_display_column;
            targetReportItem['modified'] = true;
            reportItemsClone[report_index] = targetReportItem;

            setLocalReportItems(reportItemsClone)

        }

        if (key === "filter_fields") {

            // const sort_field_order_column = 'order'
            // const final_filter = change_column_order_v1(JSON.parse(JSON.stringify(filter)), event, sort_field_order_column)
            // targetReportItem.display_columns = final_display_column;
            // reportItemsClone[report_index] = targetReportItem;
            // setFilterColumns(final_filter)

        }
    }


    const save_relationship_details = (relationships, report_index) => {
        const clone_report_items = localReportItems ? [...localReportItems] : [];
        const target_report = clone_report_items[report_index];

        target_report['delete_from_storage'] = true;
        target_report['call_java_forcefully'] = true;

        target_report.relationship_details = relationships;
        clone_report_items[report_index] = target_report;
        setLocalReportItems(clone_report_items)
    }




    const openRelationshipPopup = (report_index) => {

        const clone_report_items = localReportItems ? [...localReportItems] : [];
        const target_report = clone_report_items[report_index];
        const relationships = target_report?.relationship_details ? [...target_report.relationship_details] : [];
        const table_names = target_report?.table_names ? [...target_report.table_names] : [];

        let final_table_data = { ...schema_table_info };

        // let's append data source column table into relationships table
        if (dataSourceColumnInfo && Object.keys(dataSourceColumnInfo)?.length > 0 && connectionId?.length > 0) {
            final_table_data = { ...schema_table_info, ...dataSourceColumnInfo }
        }

        showPopup(("Stitch Tables"), undefined, enums.default.popupType.element_with_header, RelationshipForm, {
            tableRelationship: relationships,
            tableInfo: table_names,
            index: report_index,
            tableData: final_table_data,
            setTableRelationship: save_relationship_details,
        }, undefined, undefined, undefined, {
            width: '100%',
            maxWidth: '35rem'
        })

    }


    /**
     * 
     * @param {*} table_name 
     * @param {*} report_index 
     */

    const _remove_table = (table_name, report_index) => {
        const db_type = get_db_type()

        const after_removed_result = remove_table_v1(localReportItems, table_name, report_index, db_type)
        setLocalReportItems(after_removed_result)
    }


    const _on_change_report = (value, key) => {
        const clone_report = localReport ? { ...localReport } : {};
        clone_report[key] = value;
        if (key === 'title') {
            clone_report["name"] = value;
        }
        clone_report['modified'] = true;
        setLocalReport(clone_report)

    }

    /**** */
    const _add_new_report_item = () => {
        const clone_report_items = localReportItems ? [...localReportItems] : [];
        clone_report_items.push({
            display_columns: [],
            table_names: [],
            relationship_details: [],
            union: 'union'
        })
        setLocalReportItems(clone_report_items)
        setActiveReport(clone_report_items.length - 1)
    }


    /***
     * this function will delete the report item by report index from local state 
     */
    const delete_report_item = (index) => {
        const clone_report_items = localReportItems ? [...localReportItems] : [];
        clone_report_items.splice(index, 1)
        setLocalReportItems(clone_report_items)
    }

    const delete_union = (index) => {
        showPopup(undefined, 'Are you sure you want to delete this ? ', enums.default.popupType.element, ConfirmBox, { func: { setYes: () => delete_report_item(index) } })
    }


    /****
     * this function will set the criteria of the report item by index
     */
    const setCriteria = (criteria, report_index) => {
        const reportItemsClone = localReportItems ? [...localReportItems] : [];
        const targetReportItem = reportItemsClone[report_index];

        targetReportItem['delete_from_storage'] = true;
        targetReportItem['call_java_forcefully'] = true;


        targetReportItem.criteria = criteria;
        targetReportItem['modified'] = true;
        reportItemsClone[report_index] = targetReportItem;
        setLocalReportItems(reportItemsClone)
    }


    /***
     * this function will the the value of report 
     */

    const on_change_report_item_value = (value, key, report_index) => {
        const reportItemsClone = localReportItems ? [...localReportItems] : [];
        const targetReportItem = reportItemsClone[report_index];
        targetReportItem[key] = value;
        targetReportItem["modified"] = true;
        reportItemsClone[report_index] = targetReportItem;
        setLocalReportItems(reportItemsClone)

    }

    /***
     * this function will add the new field into the display field 
     */
    const add_new_column = (name, report_index) => {

        const reportItemsClone = localReportItems ? [...localReportItems] : [];
        const targetReportItem = reportItemsClone[report_index];
        let display_columns = (targetReportItem?.display_columns) ? [...targetReportItem.display_columns] : [];

        let sorted_columns = display_columns.sort((a, b) => a.order - b.order);
        const find_biggest_order_element = sorted_columns[sorted_columns.length - 1];

        const is_pivot = Object.values(CHART_TYPES).indexOf(render_mode) > -1;
        const pivot_type = "pivot_x" // we will add free column into pivot x

        const { index, order, pivot_field_order } = get_highest_field_order_and_index_v2(display_columns);

        const data_type = (name && parseInt(name)) ? 'number' : 'string'

        const __obj__ = {
            aggregation: "none",
            order: (order || display_columns.length + 1),
            index: (index || display_columns.length),
            pivot_field_order: (pivot_field_order || (display_columns.length + 1)),
            alias: name,
            pivot_type: is_pivot ? pivot_type : undefined,
            pivot_field_alias: is_pivot ? name : undefined,
            pivot_field_column_name: is_pivot ? name : undefined,
            column_name: name,
            data_type: data_type,
            orignal_name: name,
            table_name: undefined,
            value: '',
        }

        display_columns.push(__obj__)

        targetReportItem.display_columns = display_columns;

        targetReportItem['delete_from_storage'] = true /// this is for delete from storage
        targetReportItem['call_java_forcefully'] = true;

        reportItemsClone[report_index] = targetReportItem;

        setShowAddColumn(undefined);
        setLocalReportItems(reportItemsClone)

    }



    /****
     * this function will genrate the hints for the formula window 
     */
    const generate_hint_for_formula = (report_index) => {
        const result = [];
        const reportItemsClone = localReportItems ? [...localReportItems] : [];
        const targetReportItem = reportItemsClone[report_index];
        const table_names = (targetReportItem?.table_names) ? [...targetReportItem.table_names] : [];
        const display_columns = (targetReportItem?.display_columns) ? [...targetReportItem.display_columns] : [];

        display_columns && display_columns.length > 0 && display_columns.forEach((f) => {
            if (!f.is_formula && f.table_name) {
                const str = f.table_name + "." + f.orignal_name;
                result.push(str)
            }
        })
        return result
    }



    /***
     * this function will save the formula into the
     * local report item display field
     */
    const save_formula = (formula, index, report_index) => {

        const reportItemsClone = localReportItems ? [...localReportItems] : [];
        const targetReportItem = reportItemsClone[report_index];
        const display_columns = (targetReportItem?.display_columns) ? [...targetReportItem.display_columns] : [];
        const formula_index = display_columns.findIndex(el => el.index === index);
        const number_d_type = ["number", "currency", "percent"];
        const data_type = formula?.data_type;
        const pivot_type = number_d_type.indexOf(data_type) > -1 ? 'pivot_y' : 'pivot_x'
        const existence_details = pivot_type && get_existence_state_and_highest_field_order_v1(display_columns, 1, pivot_type);

        const pivot_info = {
            pivot_field_alias: formula.alias,
            pivot_field_column_name: formula.alias,
            pivot_type: render_mode !== 'table' ? pivot_type : undefined,
            axis: 'secondary',
            chart_type: render_mode,
            pivot_field_order: existence_details?.highest_field_order + 1
        }


        const prev_information = formula_index > -1 && display_columns[formula_index];

        const __obj__ = {
            ...prev_information,
            ...formula,
            aggregation: "sum",
            orignal_name: formula.alias,
            table_name: undefined,
            is_formula: true,
            visible_type: 1,
            modified: true,
            formula_type: "report_editor",
            ...pivot_info,
        }
        if (formula_index > -1) {
            display_columns[formula_index] = __obj__
        }
        else {
            __obj__.index = display_columns.length
            __obj__.order = display_columns.length
            display_columns.push(__obj__)
        }

        targetReportItem.display_columns = display_columns;
        reportItemsClone[report_index] = targetReportItem;
        setLocalReportItems(reportItemsClone)
    }


    /****
     * 
     * this function will open formula popup
     * where we can create or edit the formula of report
     */
    // const open_formula_creator = (item, index, report_index) => {
    //     const clone_item = item ? Object.assign({}, item) : undefined;
    //     const __hints__ = generate_hint_for_formula(report_index);
    //     showPopup(undefined, undefined, enums.default.popupType.element, FormulaEditorPopup, {
    //         items: clone_item ? clone_item : undefined,
    //         index: index,
    //         report_index: report_index,
    //         save_formula: save_formula,
    //         columns: __hints__
    //     }, undefined, undefined, undefined, {
    //         width: '35rem',
    //         maxWidth: '35rem'
    //     })
    // }
    const open_formula_creator = (item, index, report_index) => {

        const clone_item = item ? Object.assign({}, item) : undefined;
        const __hints__ = generate_hint_for_formula(report_index);
        showPopup((clone_item ? 'Edit Your Formula' : 'Add Your Formula'), undefined, enums.default.popupType.element_with_header, FormulaEditorPopup, {
            items: clone_item ? clone_item : undefined,
            index: index,
            report_index: report_index,
            save_formula: save_formula,
            columns: __hints__
        }, undefined, undefined, undefined, {
            width: '39rem',
            maxWidth: '39rem'
        })
    }


    /***
     * this function will reset all the state variables
     * saroj kr
     */
    const reset_all_data = () => {

        setLocalReportItems([{}])
        setLocalReport(undefined)
        set_sql_query(undefined)
    }

    const elRefs = useRef([]);

    useEffect(() => {

        if (localTableColumns && Object.keys(localTableColumns).length > 0) {

            Object.keys(localTableColumns)?.forEach((schema_key) => {

                const _schema_table_ = localTableColumns[schema_key] && Object.keys(localTableColumns[schema_key]);

                if (_schema_table_?.length > 0) {
                    _schema_table_?.forEach((t) => {
                        const un_combind_key = schema_key + "__nf__" + t;
                        if (all_table_uniq_keys?.indexOf(un_combind_key) == -1) {
                            all_table_uniq_keys.push(un_combind_key)
                        }
                    })
                }
            })

            let clone_all_table_uniq_keys = [...(all_table_uniq_keys || [])];
            // const schema_table_keys = _schema_table_ && Object.keys(_schema_table_) || [];
            elRefs.current = (clone_all_table_uniq_keys || []).fill().map((_, i) => elRefs.current[i] || React.createRef());
        }
    }, [localTableColumns])


    const scrollToView = (item) => {
        const table_name = item?.name;
        const schema_id = item?.schema_id;
        const un_combind_key = schema_id + "__nf__" + table_name;

        const el_ui_index = all_table_uniq_keys?.indexOf(un_combind_key);
        const element = el_ui_index > -1 && elRefs.current[el_ui_index];


        if (element && element.current) {

            element.current.scrollIntoView({ block: 'start', behavior: 'smooth', inline: 'start' });
            setActiveTable(un_combind_key)
            setIsColumnOpen(true)
            // setActiveColumn()
            set_open_table_column({
                schema_id: schema_id,
                table_name: table_name
            })
        }
    };



    const save_sql_mode_report = (is_run_report, query, is_delete_column_info) => {

        const report = {
            ...localReport,
            type: 'sql',
            modified: true,
        };


        if (access_group_report_mapping && access_group_report_mapping.length > 0) {
            report.access_group_report_mapping = access_group_report_mapping;
        } else {
            report.access_group_report_mapping = ["any"]
        }
        report.menu_group_id = menu_group_id;

        report.db_info_id = connectionId;
        const report_items = localReportItems ? [...localReportItems] : [{}];
        report_items[0].sql_query = query
        report_items[0].parameters = get_filter_column();
        report_items[0].chart_type = render_mode;
        report_items[0].render_mode = (render_mode === 'table') ? render_mode : "chart";
        report_items[0].modified = true;
        report.filter_default_value_json = JSON.stringify(filter_default_value || {});
        const db_type = get_db_type()
        const sql_table_column_info = find_sql_query_table_column_information(connectionId[0], db_type, schema_table_info, query)

        const json_data = {
            "sql_table_columns_info": sql_table_column_info

        }
        report_items[0].json_data = JSON.stringify(json_data);

        // let's delete column info and display filed and criteria for report
        if (is_delete_column_info) {
            report_items[0].is_delete_column_info = true;
            report_items[0].column_infos = [];
            report_items[0].display_columns = [];
            report_items[0].criteria = null;
            report_items[0].relationship_details = [];
            report_items[0].table_names = [];

        }

        report.report_items = report_items;

        if (sql_table_column_info && Object.keys(sql_table_column_info).length > 0 && is_run_report) {
            // let open popup 

            const parameters = sql_table_column_info ? JSON.parse(JSON.stringify(sql_table_column_info)) : {}
            const final_data_obj = parameters ? JSON.parse(JSON.stringify(parameters)) : {};
            const parameters_keys = Object.keys(parameters);
            showPopup('Please fill out the below form', undefined, enums.default.popupType.element_with_header, ParameterizedDataPopup, {
                parameters_fields: parameters_keys,
                btn_text: 'View Result',
                report_name: report?.name,
                view_function: (_field_data_) => {
                    Object.keys(_field_data_).forEach(k => final_data_obj[k].value = _field_data_[k]);
                    props.saveReport([report], props.history, undefined, is_run_report, undefined, true, 'sql', final_data_obj, undefined, filter_default_value);
                },
            },
                undefined,
                undefined,
                undefined,
                {
                    width: '27rem'
                })

        } else {
            props.saveReport([report], props.history, undefined, is_run_report, undefined, true, 'sql', undefined, undefined, filter_default_value);
        }


    }


    const open_sql_report_parameter_popup = (query, parameters) => {
        const final_data_obj = parameters ? JSON.parse(JSON.stringify(parameters)) : {};
        const parameters_keys = Object.keys(parameters);
        showPopup('Please fill out the below form', undefined, enums.default.popupType.element_with_header, ParameterizedDataPopup, {
            parameters_fields: parameters_keys,
            btn_text: 'View Result',
            report_name: 'test',
            view_function: (_field_data_) => {
                Object.keys(_field_data_).forEach(k => final_data_obj[k].value = _field_data_[k]);
                const final_q = replace_query_with_match_parm_value(query, final_data_obj)
                props.test_sql_query(final_q, connectionId, id_to_use_in_sql);
            },
        },
            undefined,
            undefined,
            undefined,
            {
                width: '27rem'
            })
    }



    const test_sql = (query) => {

        if (query && query.length > 5) {

            const db_type = get_db_type()
            const query_info = find_sql_query_table_column_information(connectionId[0], db_type, schema_table_info, query)

            if (query_info && Object.keys(query_info).length > 0) open_sql_report_parameter_popup(query, query_info)
            else props.test_sql_query(query, connectionId, id_to_use_in_sql);
        }

        else alert("please add your query")
    }


    const report_item_id = localReportItems?.length > 0 && localReportItems[0].id;
    const update_theme_value = (__values__) => {
        set_theme_json_values(__values__)
    }


    /**
     * this will open the theme editor 
     */
    const open_theme_editor = () => {
        showRighter(<ThemePanel
            theme_json_values={theme_json_values}
            report_item_id={report_item_id}
            update_theme_value={update_theme_value}
            render_mode={render_mode !== 'table' ? 'chart' : render_mode}
            chart_type={render_mode !== 'table' ? render_mode : 'table'}
        />)
    }


    const pivot_boxes = pivot_box_jsons && pivot_box_jsons?.[render_mode]
    const sql_result = props.sql_result_cache?.[id_to_use_in_sql];
    const sql_error = props.sql_error_cache?.[id_to_use_in_sql]


    // =========== optimize hinst code here ====
    const open_compiler_hints_popup = () => {
        showPopup((optimizer_hints ? "Edit Compiler hints" : "Add Compiler Hints"), undefined, enums.default.popupType.element_with_header, OptimizerHintsPopup, {
            optimizer_hints: optimizer_hints ? optimizer_hints : undefined,
            set_optimizer_hints: set_optimizer_hints,
        }, undefined, undefined, undefined, {
            width: '35rem',
            maxWidth: '35rem'
        })
    }



    const change_data_mode = (mode) => {
        set_active_editor(mode)
        const report_items = localReportItems ? [...localReportItems] : [{}];
        report_items[0].mode_switch = true;
        setLocalReport(report_items)
    }


    const open_permission_popup_for_sql_save = (is_run_report, query,) => {
        // is_delete_column_info
        showPopup(undefined, 'Query modified save report as CODE Mode', enums.default.popupType.element, ConfirmBox, { func: { setYes: () => save_sql_mode_report(is_run_report, query, true) } })
    }


    // const local_find_sql_query_table_column_info = (query) => {
    //     const query_info = 
    //     console.log("query_info", query_info)
    //     set_sql_table_column_info(query_info)
    // }


    const containerHeight = containerRef?.current?.clientHeight;
    const containerWidth = containerRef?.current?.clientWidth;

    const left_panel_height = containerHeight - 50;
    const right_panel_height = containerHeight;

    const sql_loader = props?.sql_loader_cache?.[id_to_use_in_sql];
    const report_sql_response = props.report_sql_cache?.[activeReportId];






    return (
        <Wrapper
            ref={containerRef}
            is_popup_window={is_popup_window}
            onClick={(e) => {
                setShowAddColumn(false);
            }}>


            {!hide_header && (
                <EditorHeader
                    setConnectionId={setConnectionId}
                    setShowColumns={setShowColumns}
                    setLocalTableColumns={setLocalTableColumns}
                    connectionId={connectionId}
                    set_access_group_report_mapping={set_access_group_report_mapping}
                    menu_group_id={menu_group_id}
                    set_menu_group_id={set_menu_group_id}
                    access_group_report_mapping={access_group_report_mapping}
                    onSave={(is_run_report, view_sql) => {
                        // save_function()
                        setActiveColumn(undefined)
                        if (active_editor === 'sql') {
                            const prv_sql = localReportItems?.length > 0 && localReportItems[0].sql_query;
                            const is_sql_changed = prv_sql !== sql_query;
                            // local_find_sql_query_table_column_info(sql_query)
                            save_sql_mode_report(is_run_report, sql_query, is_sql_changed)

                        } else {
                            if (show_sql_editor) {
                                // let's compare is query has change or not 
                                if (!report_sql || report_sql.length === 0) {
                                    // go no worry save it
                                    on_save_v1(is_run_report)
                                }
                                if ((report_sql && report_sql.length > 0) && report_sql !== report_sql_response.sql) {
                                    // lets open popup . and ask question to user 
                                    // alert("sql has changed")
                                    open_permission_popup_for_sql_save(is_run_report, (report_sql || report_sql_response.sql))
                                }

                            }
                            else {
                                on_save_v1(is_run_report, view_sql)
                            }
                            if (view_sql) set_show_sql_editor(true)
                        }
                    }}

                    activeReportId={activeReportId}
                    reset_all_data={reset_all_data}
                    set_active_editor={set_active_editor}
                    change_data_mode={change_data_mode}
                    active_editor={active_editor}
                    change_report_type={change_report_type}
                    open_theme_editor={open_theme_editor}
                    history={props.history}
                    set_show_sql_editor={set_show_sql_editor}
                    show_sql_editor={show_sql_editor}
                />
            )}



            <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', width: '100%', }}>
                <LeftSection
                    height={is_popup_window ? left_panel_height : undefined}
                >
                    <LeftSidePanel
                        connectionId={connectionId}
                        localTableColumns={localTableColumns}
                        isColumnOpen={isColumnOpen}
                        activeTable={activeTable}
                        dataSources={props.dataSources ? props.dataSources : []}
                        searchTable={searchTable}
                        setActiveTable={setActiveTable}
                        activeReportId={activeReportId}
                        elRefs={elRefs}
                        setIsColumnOpen={setIsColumnOpen}
                        type={type}
                        hide_user_data_source={hide_user_data_source}
                        hide_db_changer={true}
                        is_etl={is_etl}
                        sync={sync}
                        update_sync={update_sync}


                    />
                </LeftSection>

                <RightSection
                    height={is_popup_window ? right_panel_height : undefined}
                >
                    <div className="right_section_scroll" style={{ opacity: enable_editing ? 1 : 0 }}>

                        {!hide_report_inputs && (
                            <Row style={{ justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                <Row style={{ justifyContent: 'space-between', width: '70%' }}>
                                    <div style={{ width: '45%' }}>
                                        <TextBoxV1
                                            value={localReport && localReport.title ? localReport.title : ''}
                                            label={report_title ? report_title : "Please Provide Data Source Name"}
                                            onChange={(e) => {
                                                _on_change_report(e.target.value, 'title')
                                            }}
                                            disabled={
                                                (activeReportId && type === 'data_source') ? true : false
                                            }
                                            background={'#f0f5fc'}
                                        />
                                    </div>


                                    {!props.hide_chart_type && (
                                        <div style={{ width: '30%', marginLeft: '10px' }}>
                                            <SelectOptionV1
                                                label="Visualization Type"
                                                options={["table", ...Object.values(CHART_TYPES).filter((t => t !== 'map' && t !== 'composed_bar_chart'))]}
                                                value={render_mode}
                                                background={'#f0f5fc'}
                                                none_value={false}
                                                on_change={(val) => {
                                                    change_render_mode_local(val)
                                                }}
                                            />

                                        </div>


                                    )}
                                    <div style={{ width: '25%', marginLeft: '10px' }}>
                                        <FreeSelectBox
                                            label="Choose Menu Group"
                                            value={menu_group_id}
                                            display_key='name'
                                            value_key="id"
                                            background={'#f0f5fc'}
                                            options={props.menu_group}
                                            on_change={(value, item, event, is_new) => {
                                                set_menu_group_id(value);
                                                set_is_menu_group_new(is_new)
                                            }}
                                        />
                                    </div>
                                </Row>



                                <NormalButton
                                    style={{ marginTop: '14px' }}
                                    primary={true}
                                    bgColor='#308bc8b5'
                                    mR=".5rem"
                                    color="#fff"
                                    onClick={open_default_filter_popup}
                                    icon={<AddSvg size='.8rem' height='.8rem' color="#fff" />}
                                    label="Default Period"
                                />
                            </Row>

                        )}

                        {(!show_sql_editor && active_editor === 'config_query_builder' || report_type === 'data_source' || report_type === "etl_config_query_builder") && localReportItems && localReportItems.length > 0 && localReportItems.map((reportItem, index) => {

                            const display_columns = (reportItem.display_columns || []).filter((c) => c.formula_type !== 'report_viewer');
                            const tableRelationship = reportItem.relationship_details;
                            const tableNames = reportItem.table_names;
                            const filter_fields = reportItem.filter_fields;
                            const is_last_union = index === (localReportItems.length - 1);

                            return (
                                <Row
                                    onClick={() => {
                                        setActiveReport(index);
                                    }}
                                    style={{ marginTop: index > 0 ? '3rem' : '1rem', marginBottom: '1rem', opacity: activeReport == index ? '1' : '0.3' }}>
                                    <div className="left_side_col" style={{ width: '73%' }} onClick={(e) => {
                                        e.preventDefault()
                                        setActiveColumn(undefined)
                                    }}>
                                        {index === 0 && !hide_compiler_hints && (
                                            <Row style={{ marginBottom: '10px', justifyContent: 'flex-start' }}>
                                                <button className='rpt_primary_btn'
                                                    onClick={() => {
                                                        open_compiler_hints_popup();
                                                        // showPopup('Disabled', 'This functionality is currently disabled')

                                                    }}>
                                                    {optimizer_hints ? 'Edit Compiler Hints' : 'Add Compiler Hints'}

                                                </button>
                                            </Row>
                                        )}

                                        {index > 0 && (
                                            <div className="row_header">
                                                <select value={reportItem.union && reportItem.union} onChange={(e) => {
                                                    on_change_report_item_value(e.target.value, "union", index)
                                                }}>
                                                    <option value="union">Union</option>
                                                    <option value="union all">Union All</option>

                                                </select>
                                                <div className="delete_btn">
                                                    <RippleEffect
                                                        icon={<DeleteSvg size="1rem" height="1rem" color={"#1e5d86"} />}
                                                        title={"delete union"}
                                                        Click={() => delete_union(index)}
                                                    />
                                                </div>
                                            </div>

                                        )}

                                        <Row style={{ flexDirection: 'column', }}>
                                            {Object.values(CHART_TYPES).indexOf(render_mode) == -1 && (
                                                <Row style={{flexDirection: 'column', justifyContent: 'right', alignItems: 'end',height: 'max-content'}}>
                                                    <div className="add_btn" title={"add column"}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            setShowAddColumn(!showAddColumn);
                                                        }}
                                                    >+</div>
                                                    {showAddColumn && (
                                                        <div onClick={(e) => {
                                                            e.stopPropagation()
                                                        }} style={{ position: 'relative', width: '100%' }}>
                                                            <div className="add_column_section">
                                                                <input
                                                                    type={"text"}
                                                                    value={added_column_name}
                                                                    placeholder="Enter column Name"
                                                                    onChange={(e) => {
                                                                        set_added_column_name(e.target.value);
                                                                    }}
                                                                />
                                                                <button onClick={() => {
                                                                    if (added_column_name && added_column_name.length > 0) {
                                                                        add_new_column(added_column_name, index);
                                                                        set_added_column_name('');
                                                                    }

                                                                }}>Add</button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Row>
                                            )}

                                            {(render_mode === 'table' || !render_mode) && (
                                                <ElementBox
                                                    name={report_field_title ? report_field_title : "Data Source Fields"}
                                                    area_key={'selected_columns'}
                                                    width={'100%'}
                                                    wrapperHeight={'10rem'}
                                                    elementData={(display_columns || []).sort((a, b) => a.order - b.order)}
                                                    onDragOver={(event) => event.preventDefault()}
                                                    onColumnClick={onSelectedColumnClick}
                                                    drop_column={drop_column}
                                                    onDelete={remove_pirtual_element_from_array}
                                                    margin={'0rem 0rem 2rem 0rem'}
                                                    activeColumn={activeColumn}
                                                    change_column_order={change_column_order}
                                                    open_formula_creator={open_formula_creator}
                                                    dropColumnMargin={"2.1rem"}
                                                    index={index}
                                                    setActiveColumn={setActiveColumn}
                                                />
                                            )}



                                            {/* here we will show the column boxes */}
                                            {Object.values(CHART_TYPES).indexOf(render_mode) > -1 && (
                                                <Row>
                                                    <React.Fragment>
                                                        {pivot_boxes && Object.keys(pivot_boxes).map((k) => {

                                                            const key = k;
                                                            const width = (100 / (pivot_boxes && Object.keys(pivot_boxes).length)) - 1;
                                                            const element_data = (display_columns ? get_elements_for_render(display_columns, key, 'pivot_type') : []).filter((c => (!c.is_repeater && c.formula_type !== 'report_viewer')))

                                                            return (
                                                                <ElementBox
                                                                    pivot_type={key}
                                                                    name={pivot_boxes[k].label}
                                                                    area_key={'selected_columns'}
                                                                    width={width + "%"}
                                                                    wrapperHeight={'10rem'}
                                                                    elementData={element_data}
                                                                    onDragOver={(event) => event.preventDefault()}
                                                                    onColumnClick={onSelectedColumnClick}
                                                                    drop_column={drop_column}
                                                                    onDelete={remove_pirtual_element_from_array}
                                                                    margin={'0rem 0rem 2rem 0rem'}
                                                                    activeColumn={activeColumn}
                                                                    change_column_order={change_column_order}
                                                                    open_formula_creator={open_formula_creator}
                                                                    dropColumnMargin={"2.1rem"}
                                                                    index={index}
                                                                    setActiveColumn={setActiveColumn}
                                                                />
                                                            )
                                                        })}
                                                    </React.Fragment>
                                                </Row>

                                            )}


                                        </Row>

                                        {index === 0 && (
                                            <Row style={{ marginTop: '10px' }}>
                                                <button
                                                    className='rpt_primary_btn'
                                                    onClick={() => {
                                                        open_formula_creator(undefined, undefined, index);
                                                    }}>
                                                    + Add Formula
                                                </button>
                                            </Row>
                                        )}

                                        <Row style={{ justifyContent: 'flex-start', marginTop: index > 0 ? '1.5rem' : '1rem' }}>
                                            <div class="cell_box" style={{ width: '30%', marginRight: '2rem' }}>
                                                <h2 class="label">Table Names</h2>
                                                <div class="cell_box_border"
                                                    onDrop={(e) => {
                                                        e.stopPropagation();
                                                        onDropTable(e, index);
                                                    }}
                                                    onDragOver={(event) => event.preventDefault()}
                                                >
                                                    {tableNames && tableNames.length > 0 && (
                                                        <ul>
                                                            {tableNames.map((item, i) => {
                                                                return (
                                                                    <li key={'t' + i}>
                                                                        <div class="cell_flex">
                                                                            <div className="column_name"
                                                                                title={item.schema_id}
                                                                                onClick={() => {
                                                                                    const tbl_index = localTableColumns && Object.keys(localTableColumns).indexOf(item);
                                                                                    scrollToView(item, tbl_index)
                                                                                    // setActiveColumn
                                                                                }}
                                                                            >{item.name}</div>
                                                                            <div title="remove" className="close" onClick={(e) => {
                                                                                _remove_table(item.name, index);
                                                                            }}>
                                                                                <CloseSvg size=".5rem" height=".5rem" />
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>

                                            <div class="cell_box" style={{ width: '68%', }}>
                                                <div style={{ position: 'relative' }}>
                                                    <h2 class="label">Table Relationship</h2>
                                                    <button
                                                        className='rpt_primary_btn'

                                                        onClick={() => {
                                                            openRelationshipPopup(index)
                                                        }}
                                                        style={{
                                                            fontSize: '0.8rem',
                                                            position: 'absolute',
                                                            right: '0px',
                                                            top: '-1rem',
                                                        }}
                                                    >Edit Relationship</button>
                                                </div>
                                                <div class="cell_box_border">
                                                    <ul>
                                                        {tableRelationship && tableRelationship.length > 0 && tableRelationship.map((item, i) => {
                                                            if (!item.deleted) {
                                                                let _str = item.source_table + '.' + item.source_column + "=" + item.target_table + '.' + item.target_column + "@" + item.join_type;
                                                                return (
                                                                    <li style={{ maxWidth: '100%' }}>
                                                                        <div className="column_name">{_str}</div>
                                                                    </li>
                                                                )
                                                            }
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Row>

                                        <Row style={{ justifyContent: 'flex-start', marginTop: '1rem' }}>
                                            <div style={{ width: '100%', }}>
                                                <Criteria
                                                    example={"tbl_bill.name starts with surgery"}
                                                    hints={criteriaSuggestions && criteriaSuggestions[index]}
                                                    criteria={reportItem && reportItem.criteria}
                                                    parameters_fields={parameters_fields}
                                                    schema_table_info={schema_table_info}
                                                    db_info_id={connectionId}
                                                    setCriteria={(c) => {
                                                        setCriteria(c, index)
                                                    }} />
                                            </div>
                                        </Row>


                                        {is_filter && is_last_union && (
                                            <Row style={{ justifyContent: 'flex-start', marginTop: '1rem' }}>
                                                <ElementBox
                                                    name={"Filter Fields"}
                                                    area_key={'filter_fields'}
                                                    width={'100%'}
                                                    wrapperHeight={'6rem'}
                                                    elementData={filterColumns}
                                                    onDragOver={(event) => event.preventDefault()}
                                                    // onColumnClick={onSelectedColumnClick}
                                                    drop_column={drop_column}
                                                    onDelete={remove_pirtual_element_from_array}
                                                    margin={'0rem 0rem 2rem 0rem'}
                                                    // activeColumn={activeColumn}
                                                    change_column_order={change_column_order}
                                                    // deleteFormula={deleteFormula}
                                                    dropColumnMargin={"1rem"}
                                                    index={index}
                                                />
                                            </Row>
                                        )}
                                    </div>
                                    <ColumnInfoEditor
                                        column_proprty_on_change={column_proprty_on_change_local}
                                        setActiveColumn={setActiveColumn}
                                        activeColumn={(activeReportIndex === index) ? activeColumn : undefined}
                                        index={index}
                                        height={"22.7rem"}
                                        marginTop={index > 0 ? "5rem" : '0rem'}
                                        render_mode={render_mode}
                                        db_infos={props.db_infos}
                                        connectionId={connectionId}
                                        is_etl={is_etl}

                                    />
                                </Row>
                            )
                        })}


                        {/* this is for sql builder */}
                        {show_sql_editor && active_editor === 'config_query_builder' && localReportItems && localReportItems.length > 0 && (
                            <div>
                                <SqlBuilderWeight
                                    initalValue={report_sql || report_sql_response?.sql}
                                    set_sql_query={(value) => {
                                        set_report_sql(value)
                                    }}
                                    sql_table_column_info={sql_table_column_info}
                                    db_info_id={connectionId[0]}
                                    test_sql={test_sql}
                                    sql_loader={sql_loader}
                                    sql_result={sql_result}
                                    sql_error={sql_error}
                                    set_show_sql_editor={set_show_sql_editor}
                                    show_sql_editor={show_sql_editor}

                                />
                            </div>
                        )}

                        {active_editor === 'sql' && (
                            <SqlBuilderWeight
                                initalValue={sql_query}
                                set_sql_query={(value) => {
                                    set_sql_query(value)
                                    if (localReportItems && !localReportItems[0]?.delete_from_storage) {
                                        const clone_report_items = localReportItems ? JSON.parse(JSON.stringify(localReportItems)) : [];
                                        clone_report_items[0].delete_from_storage = true;
                                        clone_report_items[0]['call_java_forcefully'] = true;

                                        setLocalReportItems(clone_report_items)
                                    }
                                }}
                                db_info_id={connectionId[0]}
                                test_sql={test_sql}
                                sql_loader={sql_loader}
                                sql_result={sql_result}
                                sql_error={sql_error}

                            />
                        )}


                        {!is_etl && !show_sql_editor && (active_editor === 'config_query_builder' || report_type === 'data_source' || report_type === "etl_config_query_builder") && (
                            <Row style={{ justifyContent: 'flex-start', marginTop: '1rem' }}>
                                <NormalButton
                                    bgColor='transparent'
                                    style={{
                                        border: '1px solid #374a66'
                                    }}
                                    // hoverBg="#374a66"
                                    mR=".5rem"
                                    onClick={() => {
                                        _add_new_report_item()
                                    }}
                                    color="#374a66"
                                    icon={<AddSvg size='.7rem' height='.7rem' color="#374a66" />}
                                    label="Add Union"
                                />
                            </Row>
                        )}

                       

                        {is_popup_window && (
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginTop: 'auto' }}>
                                <Button width="5rem" mR="1rem" onClick={() => {
                                    on_save_v1();
                                }}>Next</Button>

                            </div>
                        )}
                    </div>



                </RightSection>
            </div>
        </Wrapper>
    )
}


const mapStateToProps = (state) => ({

    db_infos: state.connectionReducer.db_infos,
    relationships: state.relationshipDetail.relationships,
    sqlQueryResult: state.connectionReducer.sqlQueryResult,
    allReports: state.reportReducer.reports && state.reportReducer.reports.all_reports,
    functions_config: state.reportReducer.functions_config,
    testSqlError: state.reportReducer.testSqlError,
    sql_result_cache: state.reportReducer.sql_result_cache,
    sql_error_cache: state.reportReducer.sql_error_cache,
    sql_loader_cache: state.reportReducer.sql_loader_cache,

    /**new reducer  */
    schema_table_info: state.reportBuilder.schema_table_info,
    hints_table: state.reportBuilder.hints_table,
    edit_report_cache: state.reportBuilder.edit_report_cache,
    report_sql_cache: state.reportBuilder.report_sql_cache,
    accessGroupList: state.groupAccessReducer.accessGroupList,
    menu_group: state.menuGroupReducer?.menu_groups_cache?.['report'],

    dataSources: state.reportHelperReducer?.dataSources,
    editCurrentReport: state.reportHelperReducer?.editCurrentReport,




})

export default withRouter(connect(mapStateToProps, { getAllDataSource, getMenuGroups, getAccessGroup, clear_schema_table_info, getReportByReportId, test_sql_query, db_functions_config, get_tbl_formulas_for_editor, getAllReports, getReportById, getAllRelationships, get_db_info, get_table_data, get_schema_table_info, clearTableNameAndTableData, saveReport, getSqlQuery, clear_data_for_report })(Editor))
