import styled from 'styled-components';

export const Wrapper = styled.div`
    position: absolute;
    width: inherit;
    z-index: 10;
    top: 95%;
    height: 50px;
    overflow: hidden;
    overflow-x: auto;
    background: white;
    border-radius: 5px;
    border: 1px solid #8395af;;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    white-space: nowrap;
    padding: 5px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        background-color: transparent;
        width:16px;
    }
    
    &::-webkit-scrollbar-track {
        background-color:#fff;
    }

    &::-webkit-scrollbar-track:hover {
        background-color:#f4f4f4;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color:#babac0;
        border-radius:16px;
        border:5px solid #fff;
    }
    
    &::-webkit-scrollbar-thumb:hover {
        background-color:#a0a0a5;
        border:4px solid #f4f4f4;
    }
    
    &::-webkit-scrollbar-button {
        display:none;
    }
`;

export const Word = styled.span`
    border: 1px solid #c1c1c2;
    border-radius: 2px;
    padding: 3px 5px;
    font-size: .8rem;
    box-sizing: border-box;
    margin-right: 5px;
    cursor: pointer;
    font-weight: ${props => props.active ? 'bold' : 'normal'};

    &:last-child {
        margin-right: 0;
    }
`;