import * as actions from '../action-types/voice.helper';

const initialState = {
    type: actions.VOICE_HELPER_INIT,
    chart_type_command: undefined,
    download_command: undefined,
    text_voice_command: undefined,
    dashboard_data: undefined,
    other_voice_cmd: undefined,
};


const VoiceHelper = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    };


    switch (action.type) {
        case actions.DISPATCH_CHART_TYPE_COMMAND_SUCCESS:
            return {
                type: action.type,
                chart_type_command: action.data
            };

        case actions.DISPATCH_DOWNLOAD_COMMAND_SUCCESS:
            return {
                type: action.type,
                download_command: action.data
            };

        case actions.RAISE_TEXT_TO_VOICE:
            return {
                type: action.type,
                text_voice_command: action.data
            };
        case actions.CREATE_DASHBOARD_USING_VOICE_COMMAND:

            console.log("ABBBB", action?.data)
            return {
                type: action.type,
                dashboard_data: action.data
            };
        case actions.DISPATCH_OTHER_VOICE_COMMAND:
            return {
                type: action.type,
                other_voice_cmd: action.data
            };

        case actions.CLEAR_VOICE_COMMAND_DASHBOARD:
            return {
                type: action.type,
                dashboard_data: undefined
            };


        default:
            return { ...state };
    }
};

export default VoiceHelper;