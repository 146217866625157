import React from "react";

const SystemJob = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1536 2048"
    >
      <path transform="translate(1533,372)" d="m0 0 3 1v8l-2 6h-1l-1 382 1-4h2v6l-2 4-2 1-4 9-6 9-9 11-14 14-11 9-15 11-15 10-23 13-27 14-34 15-28 11-34 12-35 11-40 11-32 8-57 12-51 9-65 9-65 7-51 4-37 2-33 1h-167l-43-2-44-3-61-6-35-4-71-11-58-11-52-12-41-11-48-15-36-13-29-12-28-13-26-13-21-12-18-12-12-9-13-11-8-7-9-9-13-18-3-9-1-11-1-43v-142l1-142v-53l1-10 6 2 24 13 40 20 36 15 34 13 28 10 39 12 36 10 44 11 47 10 54 10 58 9 66 8 40 4 37 3 93 5 39 1h87l32-1 73-4 44-3 51-5 53-6 67-10 50-9 52-11 60-15 28-8 29-9 40-14 28-11 22-9 29-13 34-17 16-9z" fill="#07335A" />
      <path transform="translate(8,1427)" d="m0 0 6 2 21 12 30 15 31 14 32 13 30 11 36 12 33 10 37 10 32 8 47 10 49 9 64 10 77 9 53 5 61 4 45 2 33 1h93l72-3 62-4 94-9 72-10 68-12 43-9 42-10 45-12 33-10 41-14 29-11 22-9 19-8 14-7 23-11 19-10 13-7h6l1 2v19l-2 2-2-10v183l1 200 2-1-1 4-8 15-11 13-12 13-11 9-8 7-17 12-21 13-24 13-24 12-35 15-41 15-37 12-47 13-49 12-48 10-60 10-53 7-72 8-46 4-35 2-31 1h-119l-47-1-42-2-47-4-62-6-70-9-65-11-56-12-44-11-44-13-36-12-30-11-29-12-28-13-19-10-21-12-19-13-14-11-13-11-8-7v-2h-2l-9-11-9-14-2-5-1-6-1-22v-164z" fill="#07335A" />
      <path transform="translate(9,906)" d="m0 0 7 3 14 8 17 9 42 20 34 14 35 13 35 12 44 13 64 16 43 9 47 9 65 10 67 8 52 5 59 4 74 3 14 1h94l12-1 76-3 47-3 56-5 55-6 75-11 64-12 41-9 56-14 41-12 51-17 29-11 24-10 25-11 29-14 26-14 8-4 4 1 1 6-2 4h-2v245l1 77 1 5v20l2 1v3 17h-2l2 5h-2l1 4h-2l-1-14v22l1-7 2 4 1 5-5 5-8 16-13 15-11 11-11 9-15 11-19 12-21 12-15 8-23 11-30 13-29 11-36 12-29 9-37 10-41 10-53 11-52 9-68 9-57 6-52 4-63 3-48 1h-81l-38-1-61-3-48-4-66-7-69-10-65-12-56-13-45-12-38-12-35-12-33-13-29-13-25-12-27-16-11-7-11-8-11-9-10-9-19-19-10-13-3-9-1-9-1-46v-135l1-133v-72zm1525 362 1 2z" fill="#07335A" />
      <path transform="translate(701,7)" d="m0 0h138l35 1 64 4 43 4 62 7 44 6 37 6 42 8 49 11 46 12 39 12 33 11 41 16 36 16 27 14 28 17 18 13 14 12 19 19 8 10 8 14 3 15-1 12-8 15-7 9-9 10-13 13-11 9-16 12-20 12-21 12-16 8-23 11-36 15-36 13-41 13-45 12-46 11-49 10-53 9-60 8-59 6-48 4-31 2-25 1h-197l-49-3-57-5-39-4-59-8-37-6-53-10-49-11-41-11-39-12-47-16-34-14-29-13-20-10-20-11-22-14-13-10-13-11-8-7-11-11-10-15-5-11-2-9v-9l4-14 7-13 8-11 12-13 15-12 16-12 15-10 17-10 35-18 29-13 36-14 50-17 42-12 48-12 63-13 63-10 62-8 51-5 71-5z" fill="#07335A" />
      <path transform="translate(1531,1162)" d="m0 0h1l1 77 1 5v20l2 1v3 17h-2l2 5h-2l1 4h-2l-1-14-1-37zm3 106 1 2z" fill="#FDFDFD" />
      <path transform="translate(1535,1435)" d="m0 0 1 3v11l-2 2-2-13z" fill="#FDFDFD" />
      <path transform="translate(1533,1295)" d="m0 0 2 4 1 5-5 4v-5l1-1z" fill="#FDFDFD" />
      <path transform="translate(1533,1818)" d="m0 0" fill="#FDFDFD" />
      <path transform="translate(1535,1261)" d="m0 0" fill="#FDFDFD" />
      <path transform="translate(1534,1820)" d="m0 0" fill="#FDFDFD" />
    </svg>

  );
};

export default SystemJob;
