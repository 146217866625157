import { simpleDispatch_get, simpleDispatch_post, dispatchAction, showError, raiseSuccess } from './helper';
import * as actionTypes from '../action-types/import.error';
import { constants } from '../utils/constants';
import { getAllFiles, getFilesByDimension, getFilesByPlatform } from '../actions/file.uploader';
import * as loaderTypes from '../action-types/loader';
import { post, axiosPostDownloadReq } from '../data.service';


export const getImportErrorInfo = () => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.IMPORT_ERROR.POINT + constants.END_POINTS.IMPORT_ERROR.GET_IMPORT_INFO;

    await simpleDispatch_get(dispatch, url, actionTypes.GET_IMPORT_INFO);
};


export const getColumnsInfo = (tableName) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.IMPORT_ERROR.POINT + constants.END_POINTS.IMPORT_ERROR.GET_COLUMNS_INFO + `?tableName=${tableName}`;

    await simpleDispatch_get(dispatch, url, actionTypes.GET_COLUMNS_INFO);
};


export const saveColumnInfo = (file_information) => async dispatch => {

    // const _post = {
    //     file_id,
    //     column_meta_data,
    //     database_id,
    //     etl_config_id
    // }

    const _post = {
        file_information: file_information
    }

    const url = constants.END_POINTS.API + constants.END_POINTS.IMPORT_ERROR.SAVE_COLUMNS_INFO

    try {

        dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);

        const response = await post(url, _post);

        if (response && response.code === 200) {
            await dispatchAction(dispatch, actionTypes.SAVE_COLUMN_INFO)
            await raiseSuccess(dispatch, {
                type: 'success',
                message: "File Processed Successfully"
            })
            await getAllFiles()(dispatch)
            await getFilesByPlatform()(dispatch)
            await getFilesByDimension()(dispatch)
        }

    } catch (error) {
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        const _message = typeof error === 'object' ? (error.custom_message ? error.custom_message : '') : error;

        raiseSuccess(dispatch, {
            type: 'error',
            title: 'ERROR',
            message: _message,
        })
    }
};

















// export const saveColumnInfo = (new_columns, old_columns_with_hints, table_name, file_id, file_name,platform_name,platform_id, dimension, dimension_info_id, error_type, database_id, etl_config_id) => async dispatch => {
//     const _post = {
//         new_columns,
//         old_columns_with_hints,
//         table_name,
//         file_id, 
//         file_name,
//         platform_name,
//         dimension,
//         dimension_info_id,
//         error_type,
//         database_id,
//         etl_config_id
//     }

//     const url = constants.END_POINTS.API + constants.END_POINTS.IMPORT_ERROR.SAVE_COLUMNS_INFO

//     try {
//         dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);

//         const response = await post(url, _post);

//         if (response && response.code === 200) {
//             await dispatchAction(dispatch, actionTypes.SAVE_COLUMN_INFO)
//             await raiseSuccess(dispatch, {
//                 type: 'success',
//                 message: "File Processed Successfully"
//             })
//             await getAllFiles()(dispatch)
//             await getFilesByPlatform()(dispatch)
//             await getFilesByDimension()(dispatch)
//         }

//     } catch (error) {
//         dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
//         const _message = typeof error === 'object' ? (error.custom_message ? error.custom_message : '') : error;

//         raiseSuccess(dispatch, {
//             type: 'error',
//             title: 'ERROR',
//             message: _message,
//         })
//     }
// };





