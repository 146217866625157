import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '../hoc/button';
import TextBoxV1 from '../hoc/text.box.v1';
import DeleteSvg from '../svg/delete'


const Wrapper = styled.div`

    width: 100%;
    display: flex;
    flex-direction: column;


    box-sizing: border-box;
    table{
        width: 100%;
        border: 1px solid #ccc;
        border-collapse: collapse;
    }


     .table_wrapper{
        width: 100%;
        max-height: calc(100vh - 280px);
        overflow: auto;
        min-height:  calc(100vh - 280px);
        &::-webkit-scrollbar {
            background-color:red;
            width:5px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }
    }
    td{
        border: 1px solid #ccc;
        padding: 10px;
        box-sizing: border-box;
        font-size: 1rem;
    }

    thead{
        td{
            font-weight: 600;
        }
    }
    tbody{
        td{
            padding: 5px;
            border: 0px;;
        }


    }


    .delete_btn{
        cursor: pointer;
        svg{
            fill: red;
        }
    }

`;




const Index = (props) => {


    const [local_data, set_local_data] = useState([{}])

    const on_change_v1 = (field, value, row_index) => {

        const clone_local_data = local_data ? [...local_data] : [];

        if (clone_local_data?.[row_index]) {

            clone_local_data[row_index][field] = value;
            clone_local_data[row_index]["modified"] = value;
        }

        if (clone_local_data[clone_local_data.length - 1].what_to_replace && clone_local_data[clone_local_data.length - 1].what_to_replace_with) {
            // let add mt row
            clone_local_data.push({})
        }

        set_local_data(clone_local_data);
    }


    const delete_row = (row_index, id) => {

        const clone_local_data = local_data ? [...local_data] : [];

        if (clone_local_data[row_index].id) {
            clone_local_data[row_index].deleted = true;
        }
        else {
            clone_local_data.splice(row_index, 1)

        }

        if (clone_local_data[clone_local_data.length - 1].what_to_replace && clone_local_data[clone_local_data.length - 1].what_to_replace_with) {
            // let add mt row
            clone_local_data.push({})
        }

        set_local_data(clone_local_data)
    }


    const save_data = () => {

        const cloned_data = local_data ? [...local_data] : [];

        const filterd_data = cloned_data?.filter((r) => {
            if (r.what_to_replace && r.what_to_replace_with) return true;
            else return false;
        })


    }


    return (
        <Wrapper>

            <div className='table_wrapper'>
                <table>
                    <thead>
                        <tr>
                            <td>What To Replace</td>
                            <td>What To Replace With</td>
                            <td style={{ width: '100px' }}>Actions</td>
                        </tr>
                    </thead>

                    <tbody>
                        {local_data?.map((row, index) => {

                            const is_last = (local_data.length - 1) === index;

                            if (!row.deleted) {
                                return (
                                    <tr key={"impt_r" + index}>
                                        <td>
                                            <TextBoxV1
                                                hide_margin={true}
                                                type="text"
                                                onChange={(e) => {
                                                    on_change_v1("what_to_replace", e.target.value, index);
                                                }}
                                                value={row?.["what_to_replace"] || ''}
                                            />
                                        </td>
                                        <td>
                                            <TextBoxV1
                                                hide_margin={true}
                                                type={"text"}
                                                onChange={(e) => {
                                                    on_change_v1("what_to_replace_with", e.target.value, index);
                                                }}
                                                value={row?.["what_to_replace_with"] || ''} />
                                        </td>
                                        {!is_last && (
                                            <td style={{ width: '100px', textAlign: 'center' }}>
                                                <div title="Delete" className='delete_btn' onClick={() => delete_row(index)}>
                                                    <DeleteSvg size="1rem" height="1rem" />
                                                </div>
                                            </td>
                                        )}
                                    </tr>
                                )
                            }
                        })}
                    </tbody>
                </table>
            </div>
            <div
                style={{
                    padding: '10px 0px',
                    borderTop: '1px solid #ccc',
                    marginTop: 'auto',
                    display: 'flex',
                    boxSizing: 'border-box',
                    justifyContent: 'flex-end'
                }}>
                <Button
                    width="6rem"
                    primary={true}
                    onClick={() => {
                        save_data()
                    }}
                >Save</Button>
                {/* <Button
                    width="6rem"
                    bgColor="#9caeb5"
                    // mR="1.143rem"
                    onClick={() => {

                    }}
                >
                    Cancel
                </Button> */}

            </div>
        </Wrapper>
    )
}

export default Index