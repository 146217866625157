import styled from "styled-components";

export const ChatBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: baseline;    
    width: 70%;
    margin: auto;
    height: 100%;
    z-index:1; 

   
`;
export const SentMessageWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    max-width: ${props => props.width || '45%'};
    /* background: #f2f2f2; */
    background: linear-gradient(to right, #3E9EFF, #737DFF);
    border-radius: 8px;
    /* padding: 14px 20px; */
    padding: ${props => props.padding || '14px 20px'};
    color: #fff;
    font-size: .9rem;
    font-weight: 300;
    position: relative;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.2rem;
    box-sizing: border-box;
    span{
        overflow: hidden;
    }

    &::after{
        transform: rotate(179deg);
        content: '';
        position: absolute;
        bottom: 0px;
        right: ${props => props?.tailDimensions?.right || '-8px'};
        width: 0;
        height: 0;
        border: ${props => props?.tailDimensions?.border ? `${props?.tailDimensions?.border} solid transparent` : "10px solid transparent"};
        /* border: 20px solid transparent; */
        border-top-color: #737DFF;
        border-bottom: 0;
        z-index: 1;

    }
    &:hover{
        .tooltip_edit{
            opacity: 1;
        }
    }

`;
export const SendMessageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  max-width: 100%;
  min-width: 100%;
  align-items: center;

  &:hover{
    .edit_question{
        /* display: inline-block; */ 
        display: none;

    }
  }

  .edit_question{
    margin-right: 10px;
    display: none;
  }
/* 
  .tooltip_edit{
    height: 1.6rem;
    width: 1.6rem;
    border-radius: 50px;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    opacity: 0;
  } */

`;

export const MessageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  max-width: 100%;
  min-width: 100%;
  .msg_bg{
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 8px;
    margin-bottom: 10px;
  }
`;



export const MessageWrapper = styled.div`

  display: flex;
  justify-content: flex-end;
  max-width: 90%;
  /* padding: 0.4rem 1rem; */
  padding: ${props => props.padding || '0.4rem 1rem'};

    .polus_icon_bg1 {
        height: ${props => props.iconbgSize || '2.5rem'}; 
        width: ${props => props.iconbgSize || '2.5rem'}; 
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: ${props => props.marginRightIcon || '1.3rem'};
        span{
            border-radius: 50%;
            height: 100%;
            /* background-color: #fff; */
            /* background-color: #e1ebff; */
            background-color: ${props => props.iconBg || '#e1ebff'};
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            /* border: 1px solid #3E9EFF; */
            padding: 0.1rem 0 0 0.1rem;
        }
    }
    .receive_msg_text p {
        margin:0;
        font-size: 1.2rem;
        line-height: 1.8rem;
        padding-bottom: 1rem;
    }
    .receive_msg_text ul{
        margin:0;
        padding-left: 1rem;
        padding-bottom: 1rem;
    }
    .receive_msg_text ul li{
        line-height: 2.2rem;
        font-size: 1.2rem;
        padding-left: 10px;
    }
    .summery_Text{
        display: flex;
    }
    .summery_Text_bg{
        background: #fff;
        border-radius: 6px;
        padding: 0.4rem 0.8rem;
        font-size: 1.2rem;
    }
    .button_group{
        display: flex;
        flex-direction: row;
        margin: 1rem 0;
        svg{
            margin: 0.5rem;
        }
    }
    .btn{
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.6rem;
        width: 2.6rem;
        margin-right: 0.8rem;
        /* box-shadow: 1px 1px 5px 3px #cccccca8; */
        cursor: pointer;
        &:hover{
            /* background: linear-gradient(to right, #3E9EFF, #737DFF); */
            box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 8px;
            svg{
                fill: #fff;
            }
        }
    }
    .disabled{
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.6rem;
        width: 2.6rem;
        margin-right: 0.8rem;
        /* box-shadow: 2px 2px 2px #ccc; */
        background: #e9e9e9;
        cursor: not-allowed !important;
        svg{
            fill: #767676
        }
        &:hover {
            cursor: not-allowed !important;
            background: #e9e9e9;
            svg{
                fill: #767676;
            }
        }
    }
`;


export const Waiting = styled.div`
    /* width: 125px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* background: #fff; */
    /* height: 35px; */
    /* box-shadow: -2px 2px 5px #c5cddd; */
    border-radius: 5px;
    overflow: hidden;

`;