import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { apply_filters, clear_single_filter, remove_global_filter, clear_drill_filter_cache, clear_link_filter, clear_all_filters } from '../../actions/filter';
import { set_report_to_refresh } from '../../actions/report';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { withRouter } from 'react-router';
import { DATE_FILTER, MONTHS, FULL_MONTHS, YEAR, MONTHS_INDEXED } from '../../shared-with-fe/constants';
import CloseSvg from '../svg/close';
import { mark_report_for_no_need_to_refresh } from '../../actions/report.helper'
import { debouncev1, convert_date_to_dd_mm_yyyy_v1, DataFormatter, formatValueByDataType, format_date, get_date_filter_value, get_display_value_of_filter_v1 } from '../../utils';
import { quick_date_options } from './shared.function.js';
import FilterSvg from '../svg/filter.js'

export const Filterd = styled.div`
     max-width: 40rem;
     display: flex;
     align-items: center;
     justify-content: flex-start;
     box-sizing: border-box;
     margin: 2px 1px;
     font-weight: 500;
     position: relative;
     border-bottom: 1px solid #3a383847;
     padding: 2px 4px;
     background: #fff;
    
     .filter_name{
        font-size: .7rem;
        font-weight: 400;
        letter-spacing: normal;
        text-align: left;
        color: #005d86;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-bottom: 5px;
        min-width: 270px;
        strong{
            font-size: .7rem;
        }
     }
`;




export const FilterMain = styled.div`
    display: flex;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    flex-direction: row;
    position: sticky;
    top: 0px;
    z-index: 348;
    background-color: #f7f7f8;

    .filter_wrapper{
        padding: 10px 0px;
        display: flex;
        background-color: #f7f7f8;
    /* background-color: transparent; */
     }

    &::-webkit-scrollbar {
        background-color:red;
        width:4px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 4px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 4px;
    }

`;




export const FilterdTag = styled.div`
    background: ${props => props.bgColor || '#E8E9EA'};
    padding: 7px;
    /* padding-right: 5px; */
    position: relative;
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid transparent;
    transition: 0.2s ease-in-out;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-right: 0.5rem;


    /* &:nth-child(2){
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border:0px;
    } */
    /* &:nth-last-child(1){
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border:0px;
    } */

    &:hover{
        /* background-color: ${props => props.bgColor || '#fff'}; */
        border: 1px solid #1459d5;
        transition: 0.2s ease-in-out;
        opacity: .9;
    }
    .name{
        font-size: .8rem;
        font-weight: 500;
        letter-spacing: normal;
        text-align: left;
        color: ${props => props.color || '#1459d5'};
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: bold;
    }

    .remove{
        height: 100%;
        cursor: pointer;
        /* width: 20px; */
        margin-left: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

`;


const FilterDisplay = (props) => {

    const {
        id,
        width,
        drill_filter_cache,
        link_filter,
        validation_key,
        widegt_ids
        // report_cell_clicked_info_cache
    } = props;


    const filters = props.filters_cache && props.filters_cache[id];
    const [localFilter, setLocalFilter] = useState(undefined);


    const get_date_filter = (filters) => {
        const data = get_date_filter_value(filters);
        return data?.value;
    }

    const return_date_string_from_generic_date = (g_object) => {

        const custom_date_config = g_object?.['custom_date_config'];
        const single_date = g_object?.['single_date'];
        const range_start_date = g_object?.['range_start_date'];
        const range_end_date = g_object?.['range_end_date'];
        const month = g_object?.['month'];
        const year = g_object?.['year'];
        const type = g_object?.['type'];
        const single_type = g_object?.['single_type'];

        if (type === "custom_date" && single_type) {

            if (month > -1 && year && single_type === 'month') {
                return [MONTHS[month] + " " + year]
            }
            if (year && single_type === 'year') {
                return [year]
            }
            if (single_date && single_type === 'single_date') {
                var d = convert_date_to_dd_mm_yyyy_v1(single_date)
                return [d]
            }
        }

        if (type === "quick_date") {

            const value_key = g_object["quick_date"];
            const display_key = quick_date_options?.find((o) => o.value === value_key)?.label;

            return [display_key]
        }

        else if (range_start_date && range_end_date && type === "range") {
            const string = convert_date_to_dd_mm_yyyy_v1(range_start_date) + " To " + convert_date_to_dd_mm_yyyy_v1(range_end_date)
            return [string]
        }
        else if (custom_date_config) {
            var d = convert_date_to_dd_mm_yyyy_v1(custom_date_config)
            return [d]
        }
        return undefined;
    }


    useEffect(() => {

        const post_filter_data = {};

        if (filters && Object.keys(filters).length > 0) {

            Object.keys(filters).forEach(key => {

                if (key === 'date_filter') {

                    const preDateFilter = filters["date_filter"];

                    Object.keys(preDateFilter) && Object.keys(preDateFilter).length > 0 && Object.keys(preDateFilter).forEach(date_f_key => {

                        if (!post_filter_data[date_f_key]) post_filter_data[date_f_key] = {};

                        if (date_f_key === 'generic_date') {
                            if (preDateFilter[date_f_key] && Object.keys(preDateFilter[date_f_key]).length > 0) {
                                post_filter_data[date_f_key] = return_date_string_from_generic_date(preDateFilter[date_f_key])

                            } else delete post_filter_data[date_f_key];
                        } else {
                            // const _d_values = get_date_filter(preDateFilter[date_f_key]);
                            post_filter_data[date_f_key] = return_date_string_from_generic_date(preDateFilter[date_f_key]);
                        }

                    })
                } else {
                    post_filter_data[key] = filters[key];
                }
            })
        }

        setLocalFilter(post_filter_data)

    }, [filters, props.filters_cache])



    const find_current_filter_id = (key, value) => {
        const keys = (drill_filter_cache && Object.keys(drill_filter_cache)) || [{}];
        for (let i = 0; i < keys.length; i++) {
            const id = keys[i];
            if (drill_filter_cache?.[id]?.filter_key?.length > 0 && drill_filter_cache?.[id]?.filter_key?.indexOf(key) > -1) {
                return id;
            }
        }
    }



    /**
     * 
     * @param {*} key 
     * @param {*} value 
     */
    const clear_filter = (key, value) => {

        const report_item_id = find_current_filter_id(key, value);
        const previous_filters = JSON.parse(JSON.stringify(filters));
        const filter_keys = Object.keys(previous_filters)
        const delete_filter_index = filter_keys?.indexOf(key);

        // let's find that value from filter and delete it
        if (Array.isArray(previous_filters[key]) && previous_filters[key].length > 1 && previous_filters[key].indexOf(value) > -1) {
            previous_filters[key].splice(previous_filters[key].indexOf(value), 1)
        }
        else {

            delete previous_filters[key];
            delete previous_filters["date_filter"][key];

            if (report_item_id) {
                props.clear_drill_filter_cache(id, report_item_id, key);
            }
        }

        props.remove_global_filter(id, delete_filter_index, key, value);
        props.apply_filters(id, previous_filters, undefined, undefined, undefined, id, widegt_ids);
        props.set_report_to_refresh(id)

    }

    const clear_all_filter = () => {

        props.clear_all_filters(id, {})
        props.clear_drill_filter_cache(id, undefined, undefined, true);
        props.clear_link_filter(id, undefined, true);

        setTimeout(() => {
            props.set_report_to_refresh(id)
        }, 300)

    }

    const get_param_value = (f) => {
        return f?.display_value + "=" + f.value + " to " + f.value2;
    };


    return (
        <React.Fragment>
            <FilterMain id="ddd" multi_line={props.multi_line} width={width}>
                <div className='filter_wrapper'>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '10px'}}>
                        <FilterSvg size='1.2rem' height='1.2rem' color='#337DFF'/>
                    </div>
                    <FilterdTag bgColor='#E8E9EA' color='#222' title="Clear All" style={{ cursor: 'pointer',marginRight:'0.5rem' }} onClick={() => {
                        clear_all_filter()
                    }}>
                        <span className="name">Clear All</span>
                    </FilterdTag>

                    {props?.advance_date_setting?.length > 0 && props?.advance_date_setting?.map((f, idx) => {
                        return (
                            <FilterdTag key={f?.display_value} title={f?.display_value} style={{ cursor: 'pointer' }}>
                                <span className="name">{get_param_value(f)}</span>
                            </FilterdTag>
                        )
                    })}


                    {((localFilter && Object.keys(localFilter).length > 0) || (link_filter && Object.keys(link_filter).length > 0)) && localFilter && Object.keys(localFilter).map((key, index) => {

                        let __inner_f__ = localFilter[key] && Object.keys(localFilter[key]).length > 0 ? Object.keys(localFilter[key]) : [];

                        if (localFilter[key] && Object.keys(localFilter[key]).length > 0 && Object.keys(localFilter[key]) && !Array.isArray(localFilter[key])) {
                            return (
                                <React.Fragment>
                                    {__inner_f__ && __inner_f__.length > 0 && __inner_f__.map((j) => {

                                        let __v__ = localFilter[key][j] && Array.isArray(localFilter[key][j]) ? localFilter[key][j].join(",  ") : localFilter[key][j];
                                        if (j === "range" && localFilter[key][j]) {
                                            __v__ = localFilter[key][j]["range_start_date"] + " To " + localFilter[key][j]["range_end_date"];
                                        }
                                        if (__v__ && __v__.length > 0) {
                                            const __key__for_display = (key && key.split(".").length > 1) ? key.split(".")[1].toLocaleUpperCase() : key.toLocaleUpperCase()
                                            return (
                                                <FilterdTag title={__key__for_display + "=" + __v__} >
                                                    <span className="name">{__v__}</span>
                                                </FilterdTag>
                                            )
                                        }
                                    })

                                    }
                                </React.Fragment>
                            )
                        }

                        // this is for any other filter

                        if (localFilter[key] && Array.isArray(localFilter[key]) && localFilter[key].length > 0) {

                            const values = localFilter[key] && localFilter[key].length > 0 && localFilter[key] || [];
                            const __key__for_display = (key && key.split(".").length > 1) ? key.split(".")[1].toLocaleUpperCase() : key.toLocaleUpperCase()

                            return (
                                <>
                                    {
                                        Array.isArray(values) && values.length > 0 && values?.map((__v) => {

                                            const v = typeof __v === "string" ? __v : typeof __v?.getMonth === 'function' ? convert_date_to_dd_mm_yyyy_v1(__v) : __v;
                                            if (props?.advance_date_setting?.length > 0 && key === "generic_date") {
                                                return <></>
                                            } else {
                                                return (
                                                    <FilterdTag title={__key__for_display + "=" + v}>
                                                        <span className="name">{get_display_value_of_filter_v1(v)}</span>
                                                        {key !== 'generic_date' && (
                                                            <span title="Remove" className="remove" onClick={() => {
                                                                clear_filter(key, v)
                                                            }}>
                                                                <CloseSvg size=".5rem" height='1rem' color='#1459d5' />
                                                            </span>
                                                        )}
                                                    </FilterdTag>
                                                )
                                            }
                                        })
                                    }
                                </>
                            )
                        }
                    })}


                    {link_filter && Object.keys(link_filter)?.length > 0 && Object.keys(link_filter).map((key, index) => {

                        const report_filters = link_filter[key].filter;
                        const title_to_display = Object.values(report_filters).join(" And ");
                        let title = '';
                        Object.keys(report_filters).forEach((p, index) => {
                            title += p + '=' + report_filters[p] + " "
                        })

                        return (
                            <React.Fragment>
                                <FilterdTag title={title?.split('__nfx__db__')?.[0]}>
                                    <span className="name">{title_to_display}</span>
                                    <span title="Remove" className="remove" onClick={() => {
                                        // clear_filter(key, v)
                                        props.clear_link_filter(props.id, key)
                                        props.set_report_to_refresh(id)

                                    }}>
                                        <CloseSvg size=".5rem" height='1rem' />
                                    </span>
                                </FilterdTag>
                            </React.Fragment>
                        )
                    })}

                </div>
            </FilterMain>

        </React.Fragment>
    )
}



const mapStateToProps = (state, props) => ({

    filters_cache: state.filterReducer.filters_cache,
    report_cell_clicked_info_cache: state.reportHelperReducer.report_cell_clicked_info_cache?.[props.id],
    drill_filter_cache: state?.filterReducer?.drill_filter_cache?.[props.id],
    link_filter: state.filterReducer.link_filter_cache?.[props.id],
    advance_date_setting: state?.filterReducer?.advance_date_setting_cache?.[props?.id],
    global_filter_widgets_ids_cache: state?.filterReducer?.global_filter_widgets_ids_cache

})

export default withRouter(connect(mapStateToProps, { clear_all_filters, clear_link_filter, clear_drill_filter_cache, remove_global_filter, mark_report_for_no_need_to_refresh, apply_filters, set_report_to_refresh, clear_single_filter })(FilterDisplay))
