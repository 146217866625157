// import React from 'react';
// import * as _ from 'lodash';
// import { formatValueByDataType } from '../../../utils';
// import * as DataTypes from '../../../shared-with-fe/data.types'

// const CustomizedAxisTick = (props) => {

//   const { x, y, payload, totalTick, index, _style, Alignments, xDataKeys, set_ref_width } = props;

//   const sliceWord = (word, start, end) => {
//     if (word) {
//       let res = word.substring(start, end);
//       return res;
//     }
//   }


//   let aligment = Alignments && xDataKeys && xDataKeys.length > 0 && xDataKeys && xDataKeys.length > 0 && Alignments[xDataKeys[xDataKeys.length - 1]];
//   let _type = aligment && aligment.type;
//   let _value = (_type && (_type === DataTypes.date || _type === DataTypes.date_time)) ? formatValueByDataType(payload.value, _type) : payload.value;
//   // let _count = 30;
//   // let tick_width = 41;
//   // let wrapper_width = props.width;
//   // let how_much_word_visable = wrapper_width / tick_width;
//   // let which_word_show = Math.floor(how_much_word_visable > totalTick ? 1 : totalTick / how_much_word_visable);
//   // let _word = (_value && _value.length > 0 && _value.trim().length) > 8 ? sliceWord(_value, 0, 8) + ".." : _value;
//   // const minus_x = (typeof _word === 'string' ? 5 : (_word && _word.toString().length * 5))


//   return (
//     <g transform={`translate(${x - minus_x},${y})`}>
//       {/* <filter x="0" y="0" width="1" height="1" id="solid">
//       <feFlood flood-color="white" result="bg" />
//       <feMerge>
//         <feMergeNode in="bg"/>
//         <feMergeNode in="SourceGraphic"/>
//       </feMerge>
//     </filter> */}
//       <text
//         id={'well' + index}
//         // filter="url(#solid)"
//         style={_style}
//         x={0}
//         y={10}
//         dy={0}
//         ref={(_ref_) => {
//           _ref_ && set_ref_width && set_ref_width(_ref_.scrollWidth, index);
//           // _ref_ && console.log('reffff: ', _ref_.scrollWidth, _ref_.scrollHeight);
//         }}
//         textAnchor={typeof _word === 'string' ? "end" : 'center'}
//         transform={typeof _word === 'string' ? "rotate(-45)" : undefined}
//       >
//         {
//           (index % which_word_show) === 0 ? _word : null
//         }
//       </text>
//     </g>
//   );
// }

// export default CustomizedAxisTick;




import React from 'react';
import * as _ from 'lodash';
import { formatValueByDataType } from '../../../utils';
import * as DataTypes from '../../../shared-with-fe/data.types'

const CustomizedAxisTick = (props) => {

  const { x, y, height, width, payload, dataKey, index, Alignments, style } = props;


  const { offset } = payload
  const aligment = dataKey && Alignments?.[dataKey];
  const d_type = aligment && aligment.type;
  const value = (d_type && (d_type === DataTypes.date || d_type === DataTypes.date_time)) ? formatValueByDataType(payload.value, d_type) : payload.value;

  // console.log("Alignments1.1", props)

  return (
    <text
      style={style}
      id={'well' + index}
      x={x}
      y={y + 10}
      dy={0}
    >
      {value}
    </text>
  );
}

export default CustomizedAxisTick;




