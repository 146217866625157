import * as actions from '../action-types/tbl_errors';

const initialState = {
    type: actions.TBL_ERRORS_INIT,
    tblErrorsData: undefined,
    currentEditedError: undefined

};

const tblErrorReducer = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {

        case actions.GET_TBL_ERRORS:
            return {
                ...state,
                type: action.type,
                tblErrorsData: action.data
            }

        case actions.PUSH_TBL_ERROR_EDITED_DATA_TO_REDUCER:
            let payload = action.data;
            let existing_edited_row = state.currentEditedError ? JSON.parse(JSON.stringify(state.currentEditedError)) : {};
            existing_edited_row[payload.id] = payload
            return {
                ...state,
                type: action.type,
                currentEditedError: existing_edited_row,
            }
        
        case actions.REMOVE_TBL_ERROR_EDITED_DATA_FRPM_REDUCER:
            let id = action.data;
            let _existing_edited_row = state.currentEditedError ? JSON.parse(JSON.stringify(state.currentEditedError)) : {};
            // existing_edited_row[id] = payload
            delete _existing_edited_row[id];
            return {
                ...state,
                type: action.type,
                currentEditedError: _existing_edited_row,
            }
        
            case actions.CLEAR_ROW_DATA_EDITED_FROM_STORE:
                return {
                    ...state,
                    type: action.type,
                    currentEditedError: undefined,
                }
           
        default:
            return {
                ...state,
            };
    }
};

export default tblErrorReducer;