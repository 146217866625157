import React, { useState } from 'react';
import styled from 'styled-components';
import PolusLogoHead from '../../svg/icons-v2/polusLogoHead';
import ThreeDot from '../../svg/threeDot';
import Popover from '../../hoc/popover';
import { NormalButton } from '../../hoc/normal.button';
import Delete from '../../svg/delete';
import Share from '../../svg/share';
import Edit from '../../svg/v2-icons/editIcon';
import SelectOptionV1 from '../../select-option/select.option.v1.1';

const MainContainer = styled.div`
    width: 100%;
    font-weight: 300;
    .flex{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .filter{
        min-width: 40%;
        max-width: 50%;
        height: 3.1rem;
        span{
         font-size: 0.7rem;
        }
    }
    .history_content{
        display: flex;
        justify-content: start;
    }
    .polus_icon{
        margin-right: 0.3rem;
        /* background-color: #fff; */
        /* display: flex;
        border-radius: 50px;
        height: 1rem;
        width: 1rem; */
    }
    .date{
        font-size: 0.7rem;
        white-space: nowrap;
        margin-right: 0.8rem;
    }
    .history_item{
        display: flex;
        flex-direction: column;
        margin-bottom: 0.4rem;
        font-weight: 300;
    }
    .content_title{
        width: 210px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden;
    }

`;

const Content = styled.div`
    width: 100%;
    font-size: 0.7rem;
    font-weight: 400;
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    padding: 0.2rem 0.3rem;
    border-bottom: 1px solid #f2f2f2;
    &:hover{
        background-color: #f0f2f8;
    }
    .time{
        font-size: 0.6rem;
        color: grey;
        margin-top: 0.3rem;
    }

`;


const historyData = [
    { id: 11, title: 'Give me total revenue for each services', time: '11:09' }, { id: 12, title: 'total revenue', time: '11:00' },
    { id: 11, title: 'Give me total revenue for each services Give me total revenue for each services Give me total revenue for each services', time: '09:00' }, { id: 12, title: 'total revenue', time: '09:30' },
    { id: 11, title: 'Give me total revenue for each services', time: '09:00' }, { id: 12, title: 'total revenue', time: '09:30' }
]


const History = () => {
    const [localFilter, setLocalFilter] = useState('');

    return (
        <MainContainer>
            <div className='flex'>
                <div>Recent History</div>
                <div className='filter'>
                    <SelectOptionV1
                        height='1.6rem'
                        label="Filter"
                        options={['Today','Last Week','Last 30 days']}
                        on_change={(value, item) => {
                            setLocalFilter(value)
                        }}
                        value={localFilter}
                        none_value={false}
                        is_search={false}
                        is_chart_selection={false}
                        label_as_placeholder={true}
                        style={{fontSize: '0.8rem'}}
                    />
                </div>
            </div>
            <hr />

            {historyData?.map(item => <Content>
                {/* <div className='date'>  {item?.date}</div> */}
                <div>
                    {/* {item?.data?.map(el =>  */}
                    <div className='history_content'>
                        <div className='polus_icon'><PolusLogoHead height={'0.5rem'} size={'0.5rem'} /></div>
                        <div className='history_item'>
                            <div title={item?.title} className='content_title'>{item?.title}</div>
                            <div className='time'>{item?.time}</div>
                        </div>
                    </div>
                    {/* )} */}
                </div>
                {/* <div><ThreeDot height={'0.5rem'} size={'0.5rem'} /></div> */}
                <div >
                    <Popover width={'auto'} xPosition={'0%'} content={
                            <div>
                                <NormalButton label={'Share'} onClick={() => { }} justify="left" color={'#000000'} bgColor={'#fff'} hoverBg={"#f0f2f8"} width={'100%'} isIconMarRt={true} icon={<Share size={'1rem'} height={'0.7rem'} color={'#000'} />} />
                                <NormalButton label={'Rename'} onClick={() => { }} justify="left" color={'#000000'} bgColor={'#fff'} hoverBg={"#f0f2f8"} width={'100%'} isIconMarRt={true} icon={<Edit size={'1rem'} height={'0.7rem'} color={'#000'} />} />
                                <NormalButton label={'Delete'} onClick={() => { }} justify="left" color={'#000000'} bgColor={'#fff'} hoverBg={"#f0f2f8"} width={'100%'} isIconMarRt={true} icon={<Delete size={'1rem'} height={'0.7rem'} color={'#000'} />} />
                            </div>
                        }>
                        <ThreeDot height={'0.5rem'} size={'0.5rem'} />
                    </Popover>
                </div>
            </Content>)}
        </MainContainer>
    )
}

export default History