import styled from 'styled-components';




export const BackButton = styled.div`
    margin-top: -25px;
    position: sticky;
    top: -25px;
    /* background: #fff; */
    z-index: 2;
    min-height: 3.571rem;
    display: flex;
    align-items: center;

    .back_btn{
        cursor: pointer;
        svg{
            transform: rotate(180deg);
        }

        &:hover{
            svg{
                fill: #24a8d6;
            }
        }
    }

`;





export const Wrapper = styled.div`
    width: 98%;
    margin: auto;
    padding: 10px;
    overflow: hidden;
    
    .main_title{
            text-align: center;
            font-size: 1.8rem;
            font-weight: 300;
            margin-top: 0px;
        }
    .main {
        display: flex;
        justify-content: center;
        /* margin-top: 2.5rem; */
    }
    .div-inp {
        position: relative;
        display: flex;
    }
    .icons {
        position: absolute;
        font-size: 1.125rem;
        padding: 0.75rem;
        color: #b3b3b3;
    }
 
 
    .question_search{
    /* border-radius: 30px; */
    /* height: 3rem; */
    /* border: solid 1px #778a918a; */
    /* overflow: hidden; */
    position: relative;
    width: 100%;
     transition: all 0.2s;

    /* &:hover{
        box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28);
        border-color: rgba(223,225,229,0);
    }
    &:focus{
        box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28);
        border-color: rgba(223,225,229,0);
    } */

    /* input{
            height: 100%;
            width: 100%;
            border: 0px;
            box-sizing: border-box;
            padding: 10px 45px;
            font-size: 1rem;
            text-align: center;
            font-family: "DM Sans", sans-serif;
            &:focus{
                border: 0px;
                outline-offset: 0px;
                outline: 0;
            }
        } */
        .search_icon{
            position: absolute;
            z-index: 1;
            right: 0px;
            top: 0px;
            bottom: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            cursor: pointer;
            svg{
                   transition: all 0.2s;
                }
            &:hover{
                opacity: 0.9;
                svg{
                    transform: scale(1.1);
                }
            }
        }
        
        /* .voice_icon{
            position: absolute;
            z-index: 1;
            left: 0px;
            top: 0px;
            bottom: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            cursor: pointer;

            &:hover{
                opacity: 0.9;
            }
        }  */

}

`;


export const Transparent = styled.div`
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background-color: rgba(127,122,122,0.87);
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
`;




export const QuestionSearchWrapper = styled.div`
    width: 46.714rem;
    margin: auto;

    .big_file_name{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.286rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.714rem;
        letter-spacing: normal;
        color: #1a1a1a;
        position: relative;
        width: 100%;
    }
       
    .select_new_file{
        position: absolute;
        right: 15px;
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 18px;
        letter-spacing: normal;
        text-align: right;
        color: #7c7c7c;
        cursor: pointer;
        padding: 10px;


        &:hover{
            color: #2196F3;
            border: 1px solid #2196F3;
            border-radius: 3px;
        }
    }

    .answer_heading{
        font-size: 1.714rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.214rem;
        letter-spacing: normal;
        text-align: center;
        color: #63b6d6;
    }

    .currentFile{
        border-radius: 0px;
        height: 3rem;
        border: 0px;
        border-bottom: solid 1px #778a918a ;
        overflow: hidden;
        position: relative;
  
        input{
            height: 100%;
            width: 100%;
            border: 0px;
            box-sizing: border-box;
            padding: 10px 0px;
            font-size: 1rem;
            &:focus{
                border: 0px;
            }
        }
        .drop_down_icon{
            position: absolute;
            z-index: 1;
            right: 0px;
            top: 0px;
            bottom: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            /* background: #eaeaea; */
            cursor: pointer;

            &:hover{
                opacity: 0.9;
            }
        }
    }
`;



export const ListDropDown = styled.div`
    width: 100%;
    background: white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    position: absolute;
    top: 3rem;
    left: 0px;
    margin-top: 1px;
    z-index: 5;


    .upload_more{
        height: 2.5rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: #eaeaea;
        color: #333;
        cursor: pointer;
        width: 100%;
        padding: 0px 10px;
       box-sizing: border-box;

    }

    .file_item_wrapper{
        max-height: 200px;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;


     &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }

    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }

}

   

    .row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
        border-bottom: 1px solid #eaeaea;
        cursor: pointer;
        &:hover{
            background-color: #eaeaea;
        }
    }

    .name{
        font-size: .9rem;
        font-weight: 300;
        color: #333;
        width: calc(100% - 120px);
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .btn{
        border: 0px;
        padding: 4px 9px;
        border-radius: 4px;
        font-size: 12px;
        cursor: pointer;
        color: #222;
    }
    /* .delete{
        background: red;
    }

    .view{
        background: #008AC7; */
    /* } */

`;




