import React, { useState, useEffect } from 'react';
import ReportSettingPanel from '../layout-editor/right.side.panel';
import { Button } from '../hoc/button';
import { connect } from 'react-redux';
import { save_report_items } from '../../actions/report';


const ReportSetting = (props) => {


    const {
        report_item,
        heightOfChart,
        save_report_items,
        id

    } = props;



    const [localReportItems, setLocalReportItems] = useState(undefined)
    const [selected_ids, set_selected_ids] = useState([])


    useEffect(() => {
        const clone_report_items = [];
        clone_report_items.push(report_item)
        if (report_item && report_item.id) {
            set_selected_ids([report_item.id])
        }
        setLocalReportItems(clone_report_items)
    }, [report_item, id])


    const saveLocalReport = report_modified_data => {
        let temp_report_items = localReportItems ? localReportItems : [];
        selected_ids && selected_ids.forEach(selected_id => {
            const index = temp_report_items.findIndex(item => item.id === selected_id);
            if (index > -1) {
                const temp_report_item = temp_report_items[index];
                temp_report_items[index] = {
                    ...temp_report_items[index],
                    ...report_modified_data
                };

                temp_report_items[index].modified = true;
            }
        });
        setLocalReportItems(temp_report_items);
    };


    const save_report_item_config = () => {
        // const report_item = (localReportItems && localReportItems.length > 0) ? localReportItems[0] : undefined;
        save_report_items(localReportItems)
    }



    return (
        <div id={"brother"}>

            {selected_ids && (
                <ReportSettingPanel
                    height={heightOfChart ? (heightOfChart - 200) + "px" : undefined}
                    hideHeader={true}
                    rightPanelOpen={true}
                    hideBoxShadow={true}
                    report_items={localReportItems}
                    width={"100%"}
                    zIndex={'none'}
                    saveLocalReport={(modified_data) => {
                        saveLocalReport(modified_data);
                    }}
                    selected_ids={selected_ids}
                />
            )}


            <Button primary={true} onClick={save_report_item_config}>
                Save
            </Button>

        </div>
    );
}



export default connect(undefined, { save_report_items })(ReportSetting);
