import { dispatchAction, raiseSuccess, showError, simpleDispatch_get, simpleDispatch_post, simpleDispatch_put } from './helper';
import * as actionTypes from '../action-types/rules.alert';
import { LOADER_SHOW_V1, LOADER_HIDE } from '../action-types/loader'
import { constants } from '../utils/constants';
import { post, get } from '../data.service';



export const saveRuleAlertInfo = (data) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.RULES_ALERT.POINT + constants.END_POINTS.RULES_ALERT.SAVE_RULE_INFO;;
    await simpleDispatch_post(dispatch, url, { rules_data: data }, actionTypes.SAVE_RULE_ALERT_SUCCESS);

}


export const getRulesAlertInfo = () => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.RULES_ALERT.POINT + constants.END_POINTS.RULES_ALERT.GET_ALL_RULES_INFO;;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_ALL_RULES_ALERT_SUCCESS);

};

export const getRuleTicketInfo = (rule_id) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.RULES_ALERT.POINT + constants.END_POINTS.RULES_ALERT.GET_TICKET_BY_RULE + `?rule_id=${rule_id}`;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_TICKETS_OF_RULE_SUCCESS);
}



export const saveTicketAction = (data) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.RULES_ALERT.POINT + constants.END_POINTS.RULES_ALERT.SAVE_TICKET_ACTION;;
    await simpleDispatch_post(dispatch, url, { rules_data: data }, actionTypes.SAVE_TICKET_ACTION_SUCCESS);

}



export const run_rule_job = (id) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.RULES_ALERT.POINT + constants.END_POINTS.RULES_ALERT.RUN_RULE_JOB;;
    // await simpleDispatch_post(dispatch, url, { rule_id: id }, actionTypes.RUN_RULE_ALERT_SUCCESS);
    dispatchAction(dispatch, LOADER_SHOW_V1, id);

    try {

        setTimeout(() => {
            dispatchAction(dispatch, LOADER_HIDE, id);
        }, 3000)

        const data = await post(url, { rule_id: id });

        if (data && data.code === 200) {
            // success 
            getRulesAlertInfo()(dispatch)

        } else {

            throw new Error(data.message);
            // shomthing went wrong/
        }

    } catch (error) {

        showError(dispatch, error);
    }
}



// V2 



// get_job_by_id

export const get_rule_and_job_by_report_id = (report_id) => async (dispatch) => {

    const url = constants.END_POINTS.API + constants.END_POINTS.RULES_ALERT.POINT + constants.END_POINTS.RULES_ALERT.GET_RULE_BY_REPORT_ID;;
    const rule_response = await post(url, { report_id: report_id });

    dispatchAction(dispatch, actionTypes.CLEAR_JOB_BY_REPORT_ID)

    if (rule_response.code === 200) {

        // go ahead

        const rules_data = rule_response?.data?.rule_data;
        const job_id = rules_data?.job_id;

        if (job_id) {

            // let's fetch the job data 

            const job_url = constants.END_POINTS.JOB_API + constants.END_POINTS.JOB_V1.POINT + constants.END_POINTS.JOB_V1.GET_JOB_BY_ID + "?job_id=" + job_id;
            const job_response = await get(job_url);

            if (job_response?.code === 200) {

                const data = {
                    ...rules_data,
                    rule_data: rules_data,
                    job_data: job_response?.data?.job_data

                }

                dispatchAction(dispatch, actionTypes.GET_JOB_BY_REPORT_ID, data)

            }
        }

    }
    // get_rule_by_id
}


export const get_all_rule_jobs = () => async (dispatch) => {

    const url = constants.END_POINTS.JOB_API + constants.END_POINTS.JOB_V1.POINT + constants.END_POINTS.JOB_V1.GET_ALL_JOBS;

    const job_response = await post(url, { job_type: "rule_alert" });

    if (job_response?.code === 200) {

        const job_data = job_response?.data;
        const job_ids = job_data?.map((j) => j.id) || [];


        if (job_ids?.length > 0) {
            // let's fetch job rules data

            const url = constants.END_POINTS.API + constants.END_POINTS.RULES_ALERT.POINT + constants.END_POINTS.RULES_ALERT.GET_ALL_RULES_INFO;;

            const rules_response = await post(url, { job_ids: job_ids });

            if (rules_response?.code === 200) {

                const rules_data = rules_response?.data?.rules;


                // let's mearg both array with 
                const get_rule = (job_id) => {
                    return rules_data.find((r) => r.job_id === job_id);
                }

                const new_job_data = [];

                job_data.map((job) => {

                    const rule_d = get_rule(job.id)

                    if (rule_d) {

                        const new_data = {
                            ...job
                        }

                        new_data.rule_data = rule_d;
                        new_data.job_data = job;
                        new_data.rule_query = rule_d.rule_query;
                        new_data.nf_rule_alert_result = rule_d.rule_alert_result;
                        new_data.recipients = rule_d.recipients;
                        new_data.view_rule_access = rule_d.view_rule_access;
                        new_data.alert_type = rule_d.alert_type;
                        new_data.assigned_to = rule_d.assigned_to;
                        new_data.assigned_to_name = rule_d.assigned_to_name;
                        new_job_data.push(new_data)
                    }

                })

                dispatchAction(dispatch, actionTypes.GET_ALL_RULES_JOBS, new_job_data)

            }
        }
    }
}


export const get_all_rule_jobs_v1 = () => async (dispatch) => {

    const url = constants.END_POINTS.JOB_API + constants.END_POINTS.JOB_V1.POINT + constants.END_POINTS.JOB_V1.GET_ALL_JOBS;

    await simpleDispatch_post(dispatch, url, { job_type: "rule_alert" }, actionTypes.GET_ALL_RULES_JOBS,);

}



export const send_gpt_mail = (to, subject, file_path) => async (dispatch) => {

    const url = constants.END_POINTS.API + constants.END_POINTS.RULES_ALERT.POINT + constants.END_POINTS.RULES_ALERT.SEND_GPT_MAIL;;

    await simpleDispatch_post(dispatch, url, { 
        to: to,
        subject: subject,
        file_path: file_path
     }, "SENT_MAIL", true);

}


export const run_rule_job_forcfully = (job_id) => async (dispatch) => {

    const url = constants.END_POINTS.JOB_API + constants.END_POINTS.JOB_V1.POINT + constants.END_POINTS.JOB_V1.RUN_JOB;

    await simpleDispatch_post(dispatch, url, { job_id: job_id }, "RUN_JOB",);

    setTimeout(() => {
        get_all_rule_jobs()(dispatch)
    }, 1000)

}




export const delete_rule_job = (job) => async (dispatch) => {

    try {

        const job_clone = job ? { ...job } : {};
        job_clone.deleted = true;

        const url = constants.END_POINTS.JOB_API + constants.END_POINTS.JOB_V1.POINT + constants.END_POINTS.JOB_V1.SAVE_JOB;
        const response = await post(url, job_clone);

        if (response && response.code === 200) {
            get_all_rule_jobs()(dispatch)
            raiseSuccess(dispatch, {
                type: 'success',
                title: 'Success',
                message: "Job Delete Successfully"
            })
        }

    } catch (error) {
        raiseSuccess(dispatch, {
            type: 'error',
            title: 'ERROR',
            message: error && error.message ? error.message : 'Somthing Went wrong..'
        })
    }

}


export const save_rule_job = (job_data, rule_data) => async dispatch => {

    const url = constants.END_POINTS.JOB_API + constants.END_POINTS.JOB_V1.POINT + constants.END_POINTS.JOB_V1.SAVE_JOB;

    try {

        const job_start_time = new Date();

        const clone_job_data = { ...job_data };
        clone_job_data.job_type = "rule_alert";
        clone_job_data.grouping_tag = "rule_alert";
        clone_job_data.frequency_value = 1;
        clone_job_data.script_type = "api"
        clone_job_data.job_status = "ready"



        if (!clone_job_data.start_time) {
            clone_job_data.start_time = job_start_time
        }

        const response = await post(url, clone_job_data);

        if (response && response.code === 200 && response.data.job_id) {

            const job_id = response.data.job_id;

            rule_data.job_id = job_id;

            saveRuleAlertInfo(rule_data)(dispatch)

            raiseSuccess(dispatch, {
                type: 'success',
                title: 'Success',
                message: "Save Successfully"
            })

            get_all_rule_jobs()(dispatch)
        }

    } catch (error) {
        raiseSuccess(dispatch, {
            type: 'error',
            title: 'ERROR',
            message: error && error.message ? error.message : 'Somthing Went wrong..'
        })
    }


};