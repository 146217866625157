import React, { useState, useEffect } from 'react';
import ShareSvg from '../../svg/share';
import Pdf from '../../svg/pdf';
import RippleEffect from '../../hoc/ripple-effect';
import DesignModeSvg from '../../svg/design.mode'
import { connect } from 'react-redux';
import { getParamByName, url_for_share, showFileDownloadingStatus, getPermissionById } from '../../../utils';
import { constants } from '../../../utils/constants';
import { getFromSession } from '../../../utils/session.helper'
import { generatePdf, showPopup } from '../../../utils';
import * as enums from '../../../utils/enums.js';
import ShareLinkPopup from '../../share-link';
import ListSvg from '../../svg/list';
import FilterPreSvg from '../../svg/filter.pre';
import FilterPostSvg from '../../svg/filter.post';
import FilterElement from '../../filter';
import FilterTooltip from '../../filter/filter.tooltip';
import { get_advance_date_config, get_filters_dropdown_for_global_filter } from '../../../actions/filter';
import { check_filter_exist } from '../../../utils/v1.1/index'
import { clear_report_cell_clicked_info } from '../../../actions/report.helper'
import LoaderDiv from '../../extra/loading'
import { COMPONENT_IDS, generate_element_id } from '../../../utils/elementids'
import DataStory from './data_story.js';
import PolusIq from '../../svg/iq.js'


const HeaderAction = (props) => {


    // props from parent components
    const {
        activeInsight,
        insightsListData,
        setIncludeGap,
        isIncludeGap,
        clear_report_cell_clicked_info,
        menuPermissions,
        page_height,
        print_start,
        set_print_start,
        is_hierarchy,
        IsLoading,
        widegt_ids
    } = props;


    // url disctructors
    const { search } = props?.history?.location;

    // dispatch props from reducer
    const { filters_cache } = props;

    // geting report id
    const report_id = activeInsight && activeInsight.id;
    const reporting_db_info_id = activeInsight && activeInsight.reporting_db_info_id;


    // component state
    const [filter_mouse_enter, set_filter_mouse_enter] = useState(undefined,)

    const filters = filters_cache && filters_cache[report_id];

    const openShareLinkPopup = (_link) => {
        showPopup(undefined, undefined, enums.default.popupType.element, ShareLinkPopup, {
            link: _link,
            title: activeInsight && activeInsight.title ? activeInsight.title : undefined,
            report_id: activeInsight && activeInsight.id,
            report_type: "nf_dashboard"
        })
    }


    const openDashboardVi = () => {

    }



    const showLayoutEditor = _id => {

        const is_form_editor = activeInsight ? activeInsight.type === 'dynamic_forms' : false;
        const is_map_editor = activeInsight ? activeInsight.type === 'maps' : false;

        let cloneState = undefined;

        for (let index = 0; index < (props.all_reports && props.all_reports.length); index++) {
            const element = props.all_reports[index];
            if (element.id === _id) {
                cloneState = element;
                break;
            }
        }

        if (cloneState) {

            const id = cloneState ? cloneState.id : undefined;
            let path = '/layout_editor';
            if (id) {
                if (is_form_editor) {
                    path = path + `?edit=true&reportId=${id}&form_editor=true`;
                }
                if (is_map_editor) {
                    path = path + `?edit=true&reportId=${id}&map_editor=true`;
                }
                else {
                    path = path + `?edit=true&reportId=${id}`;
                }
            }
            props.history.push(path)
        }
    };



    const print_page = async (insight_id) => {
        
        if (!insight_id) {
            return alert('Sharing not available for adhoc questions');
        }
        let param = undefined;
        const url = constants.END_POINTS.API + constants.END_POINTS.DATA_SHARE.GET_SHAREABLE_TOKEN;
        const client_id = getFromSession(constants.SESSION_KEYS.CLIENT_ID);

        const shareable_url = url_for_share('client-view', client_id, insight_id, props.filters);

        if (shareable_url) {
            openShareLinkPopup(shareable_url)
        }
    };



    const FilterBox = props => {

        const { report_section_height, id, clear_report_cell_clicked_info, validation_key, is_global_filters } = (props && props.data || 0);

        const { close, popupKey, option } = props;

        return (
            <div style={{ width: '400px', right: '10px', padding: '10px 0px 0px 0px ', zIndex: 10, boxShadow: ' 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)', borderRadius: '10px', background: '#1e66af' }}>
                <h1 style={{ fontSize: '1.2rem', margin: '0px', textAlign: 'center', fontWeight: 'bold', color: '#fff', marginBottom: '.7rem' }}>Global Filter</h1>
                <FilterElement
                    width={400}
                    minHeight={500}
                    maxHeight={500}
                    filterItemMaxHeight={"7rem"}
                    id={id}
                    is_global_filters={is_global_filters}
                    validation_key={validation_key}
                    is_insight={true}
                    closeFilter={() => {
                        close(option, popupKey)
                    }}
                    is_hierarchy={is_hierarchy}
                    widegt_ids={widegt_ids}
                />
            </div>
        )
    };




    const open_report_filter = (e, data) => {

        const popup_width = 250;
        const win_height = window.innerHeight;
        const el_pos = data?.clientY + 300;
        const popup_top_margin = win_height - el_pos < 100 ? 100 : 10;
        const popup_top = 60
        const popup_left = data?.clientX - popup_width

        showPopup(undefined, undefined, enums.default.popupType.element,
            FilterBox,
            {
                id: activeInsight?.id,
                clear_report_cell_clicked_info: props.clear_report_cell_clicked_info,
                validation_key: activeInsight?.validation_keys?.['filter_key'],
                is_global_filters: true
            },
            popup_top + "px",
            popup_left + "px",
            "#00000000"
        )
    }


    useEffect(() => {
        report_id && props.get_filters_dropdown_for_global_filter(report_id, undefined, reporting_db_info_id)
        report_id && props.get_advance_date_config(report_id)
    }, [report_id])


    const show_data_stroy = (id) => {
        showPopup(undefined, undefined, enums.default.popupType.element, DataStory, {
            id: id
        })
    }

    const is_filter_exist = check_filter_exist(filters);

    const edit_dashboard_id = COMPONENT_IDS.SHARED_ACTIONS.EDIT_DASHBOARD;
    const share_dashboard_id = COMPONENT_IDS.SHARED_ACTIONS.SHARE_DASHBOARD;


    return (
        <div>
            <div id="printHide" style={{ flexDirection: 'row', display: 'flex', marginLeft: 'auto' }}>

                {(activeInsight?.id === "rpt_m23f4zcl" || activeInsight?.id === 'rpt_m28okegy') && (
                    <div style={{ marginRight: '0rem' }}>
                        <RippleEffect
                            icon={<PolusIq size="1.3rem" height="1.3rem" color="#337DFF" />}
                            tooltip="Polus IQ"
                            Click={async () => {
                                show_data_stroy(activeInsight?.id)
                            }}
                        />
                    </div>
                )}

                <div style={{ marginRight: '0rem' }}>
                    <RippleEffect
                        icon={<ListSvg size="1.2rem" height="1.2rem" color="#337DFF" />}
                        tooltip="Dashboard List"
                        Click={async () => {
                            const path = `insightsList?type=${(activeInsight && activeInsight.type) ? activeInsight.type : 'insights'}`
                            props.history.push(path)
                        }}
                    />
                </div>

                {true && (
                    <div
                        onMouseEnter={() => set_filter_mouse_enter(true)}
                        onMouseLeave={() => set_filter_mouse_enter(undefined)}
                        style={{ marginRight: '0rem', position: 'relative' }}>

                        <RippleEffect
                            icon={is_filter_exist ? <FilterPostSvg size="1.1rem" height="1.1rem" color="#337DFF" /> : <FilterPreSvg size="1.1rem" height="1.1rem" color="#222" />}
                            tooltip="Open Global Filters"
                            Click={async (e, data) => {
                                if (IsLoading) {
                                    alert("please wait...")
                                }
                                else {
                                    open_report_filter(e, data)
                                }
                            }}
                        />

                        {filter_mouse_enter && is_filter_exist && !IsLoading && (
                            <div className="filter_area" style={{ background: '#fff', position: 'absolute', right: '10px', top: '100%', border: '1px solid #ccc', zIndex: 2, borderRadius: '3px', padding: '5px' }}>
                                <FilterTooltip id={report_id} report_id={report_id} width={'300px'} multi_line={true} />
                            </div>
                        )}
                    </div>
                )}
                {/* )} */}

                {getPermissionById(edit_dashboard_id, menuPermissions) &&
                    <div style={{ marginRight: '0rem' }}>
                        <RippleEffect
                            icon={<DesignModeSvg size="1.3rem" height="1.3rem" color="#337DFF" />}
                            tooltip="Edit Dashboard"
                            Click={async () => {
                                showLayoutEditor(activeInsight?.id)
                            }}
                        />
                    </div>
                }


                {!props.share_mode && getPermissionById(share_dashboard_id, menuPermissions) && (
                    <React.Fragment>
                        <RippleEffect
                            icon={<ShareSvg size="1rem" height="1rem" color="#337DFF" />}
                            tooltip="Share"
                            Click={async () => {
                                const insight_id = getParamByName(search, 'insightId');
                                print_page(insight_id);
                            }}
                        />
                        <RippleEffect
                            icon={<Pdf size="1rem" height="1rem" color="#337DFF" />}
                            tooltip="Download Dashboard"
                            Click={async () => {
                                showFileDownloadingStatus({
                                    title: "Please wait while we are processing...",
                                    image: './loader.gif'
                                })
                                set_print_start(true);
                                setTimeout(() => {
                                    generatePdf('chart', 'download', (activeInsight && activeInsight.title ? activeInsight.title : "Dashboard"), 'inner_wrapper', undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, filters, undefined, page_height);
                                    setTimeout(() => {
                                        set_print_start(false);
                                    }, 1000)
                                }, 4000)
                            }}
                        />
                    </React.Fragment>
                )}
            </div>
        </div>
    );
}



const mapStateToProps = state => {

    const { insightsListData } = state.insightReducer;
    const filters_cache = state.filterReducer.filters_cache;
    const menuPermissions = state.permissionReducer?.permissions
    const all_reports = state.reportHelperReducer.all_reports;

    return { all_reports, insightsListData, filters_cache, menuPermissions };

};

export default connect(mapStateToProps, { get_advance_date_config, clear_report_cell_clicked_info, get_filters_dropdown_for_global_filter })(HeaderAction);
