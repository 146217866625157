import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import CloseSvg from '../svg/close';
import { getSqlQuery } from '../../actions/connection';
import { Wrapper, LeftSection, RightSection, Row } from '../editor.shared/editor.styled';
import { withRouter } from 'react-router-dom';
import * as _ from 'lodash';
import { db_functions_config, test_sql_query, } from '../../actions/report';
import { getAllRelationships } from '../../actions/relationship.detail';
import * as enums from '../../utils/enums';
import { generate_unique_key, getClientId, showPopup } from '../../utils';
import Criteria from '../criteria/new.criteria';
import ConfirmBox from '../confirm-box';
import ElementBox from '../editor.shared/element.box';
import RelationshipForm from '../editor.shared/relationship.form';
import LeftSidePanel from '../editor.shared/left.side.panel';
import ColumnInfoEditor from '../editor.shared/column.info';
import RippleEffect from '../hoc/ripple-effect';
import DeleteSvg from '../svg/delete';
import FormulaEditorPopup from '../formatting/formula.editor.popup';
import { NormalButton } from '../hoc/normal.button';
import SqlEditor from '../sql.editor';
import SharedTable from '../shared-table';
import WarningSvg from '../svg/warning'
import PlaySvg from '../svg/play';
import styled from 'styled-components'
import { Button } from '../hoc/button';
import AddSvg from '../svg/add'

import { CHART_TYPES, PIVOT_COLUMN_TYPE } from '../../shared-with-fe/constants';

/***
 * new regin code
 */

import { get_schema_table_info, clear_schema_table_info } from '../../actions/report_builder';
import { get_elements_for_render, change_column_order_v1, get_existence_state_and_highest_field_order_v1 } from '../editor.shared/shared.pivot';

import {
    drop_column_for_editor_v1,
    get_final_save_report_item,
    find_criteria_suggesition_by_report_table,
    find_relationship,
    column_proprty_on_change,
    sort_table_and_column,
    search_table_and_column,
    change_render_mode_v1,
    get_pivot_field_in_proper_case,
    get_pivot_charts_json,
    remove_table_v1,
    find_table_which_not_have_relationship_v1
} from '../editor.shared/builder.shared.functions';





const EditorWrapper = styled.div`
    width: 100%;
    height: 85vh;
    box-sizing: border-box;
    overflow: hidden;
    background-color: #fff;
`;



const Editor = (props) => {

    const {
        getAllRelationships,
        relationships,
        report_field_title,
        is_ETL
    } = props;




    // popup props data 
    const { data, close, popupKey, option } = props;

    const {
        chart_type,
        db_info_id,
        current_report,
        save_current_report,
        report_type
    } = data;


    const { get_schema_table_info } = props;
    const { schema_table_info } = props;



    const [activeTable, setActiveTable] = useState(undefined);
    const [localTableColumns, setLocalTableColumns] = useState({});
    const [criteriaSuggestions, setCriteriaSuggestions] = useState(undefined);
    const [activeReportIndex, setActiveReportIndex] = useState(undefined)
    const [activeColumn, setActiveColumn] = useState(undefined);
    const [localReportItems, setLocalReportItems] = useState([{}]);
    const [localReport, setLocalReport] = useState(undefined);

    const [activeReport, setActiveReport] = useState(0);
    const [activeReportId, setActiveReportId] = useState(undefined);
    const [filterColumns, setFilterColumns] = useState(undefined);
    const [parameters_fields, set_parameterized_fields] = useState(undefined);
    const [isColumnOpen, setIsColumnOpen] = useState(undefined);
    const [active_editor, set_active_editor] = useState();
    const [sql_query, set_sql_query] = useState(undefined);
    const [inital_query, set_inital_query] = useState();
    const [enable_editing, set_enable_editing] = useState(undefined);
    const [pivot_box_jsons, set_pivot_box_jsons] = useState(undefined)
    const [showAddColumn, setShowAddColumn] = useState(undefined);
    const [added_column_name, set_added_column_name] = useState(undefined);

    const [render_mode, set_render_mode] = useState(undefined);


    const [old_column_info, set_old_column_info] = useState(undefined)

    const containerRef = useRef();

    const get_db_type = () => {
        const current_connection = db_info_id && props.db_infos?.length > 0 && props.db_infos.find((d) => d.id === db_info_id);
        const db_type = current_connection?.db_type || 'mysql';
        return db_type;
    }




    useEffect(() => {
        get_schema_table_info([db_info_id])
        props.db_functions_config()
        getAllRelationships()
        set_pivot_box_jsons(get_pivot_charts_json())
    }, [])


    useEffect(() => {
        set_active_editor(report_type)
    }, [report_type])



    useEffect(() => {
        if (props.sqlQueryResult && props.sqlQueryResult.sql) {
            showPopup('SQL Query', props.sqlQueryResult.sql, enums.default.popupType.sqlQuery, undefined, undefined)
        }
    }, [props.sqlQueryResult])


    useEffect(() => {

        if (current_report) {
            const clone_report = current_report ? Object.assign({}, current_report) : {};
            const __report__ = clone_report ? JSON.parse(JSON.stringify(clone_report)) : {};

            delete __report__.report_items;
            setActiveReportId(current_report.id)
            //set_active_editor(clone_report.type)
            id_to_use_in_sql = current_report.id

            const report_items = clone_report && clone_report.report_items ? [...clone_report.report_items] : [];
            const sorted_report_items = report_items && report_items.length > 0 && report_items.sort((a, b) => {
                return a.order - b.order;
            });

            if (report_items && report_items[0] && report_items[0].sql_query) {
                set_sql_query(report_items[0].sql_query)
                set_inital_query(report_items[0].sql_query)
            }

            const criteriaSuggestions = [];

            sorted_report_items && sorted_report_items.length > 0 && sorted_report_items.forEach((report_item, report_index) => {
                const __json_data = report_item.json_data ? JSON.parse(report_item.json_data) : {};
                const column_infos = report_item.column_infos;
                const table_names_from_json_data = __json_data.table_names;
                const _display_fields = column_infos?.length > 0 && column_infos.sort((a, b) => {
                    return a.order - b.order;
                }) || [];

                // here we are assigning the index key for using index
                _display_fields.forEach((row, index) => row.index = index)
                const suggestion = find_criteria_suggesition_by_report_table(schema_table_info, table_names_from_json_data)
                criteriaSuggestions.push(suggestion)

                /***
                 * this is patch code of user inputs value 
                 */
                _display_fields.map(c => {
                    const user_input_val = c.user_input_values ? c.user_input_values : {};
                    user_input_val && Object.keys(user_input_val).forEach((k) => {
                        c[k] = user_input_val[k];
                    })
                })

                if (report_index === 0) {
                    const filter_columns = [];
                    report_item.parameters && report_item.parameters.length > 0 && report_item.parameters.forEach((filter) => {
                        filter_columns.push({ column_name: filter })
                    })
                    setFilterColumns(filter_columns)
                }

                const final_d_fields = [..._display_fields];
                const relationship_details = __json_data && __json_data.relationship_details;
                report_item.display_columns = final_d_fields
                report_item.relationship_details = relationship_details;
                report_item.table_names = table_names_from_json_data;
                // set_optimizer_hints(report_item.optimizer_hints);
            })

            const report_item_chart_type = sorted_report_items?.length > 0 && sorted_report_items?.[0].chart_type;
            // const chart_type = "table"
            // set_render_mode(chart_type)
            setCriteriaSuggestions(criteriaSuggestions)
            set_parameterized_fields(clone_report.parameterized_fields)
            setLocalReport(__report__)
            setLocalReportItems(sorted_report_items)
        }

        else {
            // setLocalReportItems([{}])
            // setLocalTableColumns(undefined)
            // setCriteriaSuggestions(undefined)
            // set_parameterized_fields(undefined)
            // setActiveReportId(undefined)
            // setFilterColumns(undefined)
            // props.clear_schema_table_info()
        }
    }, [current_report, schema_table_info])


    useEffect(() => {
        if (db_info_id) {
            const client_id = getClientId()
            const sorted_schema_table = sort_table_and_column(schema_table_info, client_id?.['client-id'], props.hints_table);
            setLocalTableColumns(sorted_schema_table)
        } else setLocalTableColumns(undefined)

        if (schema_table_info && db_info_id) {
            set_enable_editing(true)
        } else set_enable_editing(false)
    }, [schema_table_info, current_report])



    const local_find_relationship = (tabels, index) => {
        const db_type = get_db_type()
        setLocalReportItems(find_relationship(localReportItems, relationships, tabels, index, db_type))
    }

    useEffect(() => {
        const criteriaSuggestions = [];
        localReportItems && localReportItems.length > 0 && localReportItems.map((reportItem) => {
            const suggestion = find_criteria_suggesition_by_report_table(schema_table_info, reportItem.table_names)
            criteriaSuggestions.push(suggestion)
        })
        setCriteriaSuggestions(criteriaSuggestions)
    }, [localReportItems])


    const onDropTable = (event, index) => {

        const reportItemsClone = localReportItems ? [...localReportItems] : [];
        const targetReportItem = reportItemsClone[index];
        const tables_clone = (targetReportItem?.table_names) ? [...targetReportItem.table_names] : [];

        const column_info = JSON.parse(event.dataTransfer.getData("column_info") || "{}")
        const { column_name, table_name, schema_id, plugin, schema_name, db_type, connection_name, data_transfer_mode, data_transfer_from } = column_info;
        const is_table_exist = tables_clone.some(el => el.name === table_name);
        if (!is_table_exist && table_name) tables_clone.push({ name: table_name, schema_id: schema_id, db_type: db_type })
        targetReportItem.table_names = tables_clone;
        reportItemsClone[index] = targetReportItem;
        setLocalReportItems(reportItemsClone)
        local_find_relationship([table_name], index)
    }


    const drop_column = (event, key, pivot_type, index) => {

        const db_type = get_db_type()
        const dropped_results = drop_column_for_editor_v1(localReportItems, schema_table_info, filterColumns, relationships, event, key, pivot_type, index, db_type)
        setFilterColumns(dropped_results.filter_fields)
        setLocalReportItems(dropped_results.reportItems)
    }

    const onSelectedColumnClick = (key = 'selected_columns', data, index, report_index) => {
        if (key === 'selected_columns') {
            setActiveColumn(data);
            setActiveReportIndex(report_index)
        }
        if (key === 'parameterized') {
            setActiveColumn(data);
            setActiveReportIndex(report_index)
        }
    };

    const column_proprty_on_change_local = (key, value, index, param_key, is_user_input_key) => {

        const db_type = get_db_type()

        const result = column_proprty_on_change(db_type, props.functions_config, activeColumn, localReportItems, key, value, index, param_key, is_user_input_key)
        setLocalReportItems(result?.report_items)
        setActiveColumn(result?.active_columns)
    }


    const onDelete = (key, index, table_name, report_index) => {

        const reportItemsClone = localReportItems ? [...localReportItems] : [];
        const targetReportItem = reportItemsClone[report_index];
        const display_columns = (targetReportItem?.display_columns) ? [...targetReportItem.display_columns] : [];
        const filter_fields = filterColumns ? [...filterColumns] : [];

        // deleted
        if (key === 'selected_columns') {
            const element_index_to_use = display_columns?.findIndex(c => c.index === index);
            display_columns[element_index_to_use]["deleted"] = true;

            const how_many_items = display_columns?.length > 0 && display_columns.filter((c) => {
                if (c.table_name === table_name && !c.deleted) return true;
                else return false;
            })
            if ((how_many_items?.length == 0) || !how_many_items) {
                _remove_table(table_name, report_index)
            }
        }

        if (key === 'filter_fields') {
            filter_fields.splice(index, 1)
            const how_many_items = filter_fields?.length > 0 && filter_fields.filter((c) => {
                if (c.table_name === table_name) return true;
                else return false;
            })
            if ((how_many_items?.length == 0) || !how_many_items) {
                _remove_table(table_name, report_index)
            }
        }

        targetReportItem.display_columns = display_columns;
        targetReportItem['isQuestionModified'] = true;
        targetReportItem['delete_from_storage'] = true /// this is for delete from storage
        targetReportItem['call_java_forcefully'] = true;

        reportItemsClone[report_index] = targetReportItem;
        setLocalReportItems(reportItemsClone)
        setFilterColumns(filter_fields)
        setActiveColumn(undefined)
        setActiveReportIndex(undefined)
    }

    const remove_pirtual_element_from_array = (key, index, table_name, report_index) => {
        showPopup(undefined, 'Are you sure you want to delete this ? ', enums.default.popupType.element, ConfirmBox, { func: { setYes: () => onDelete(key, index, table_name, report_index) } })
    }

    const get_filter_column = () => {
        const columns = [];
        filterColumns && filterColumns.length > 0 && filterColumns.forEach((f) => {
            if (!f.deleted) columns.push(f.column_name)
        })
        return columns;
    }


    const searchTable = (value) => {
        setLocalTableColumns(search_table_and_column(value, schema_table_info))
    }


    const change_column_order = (event, key, report_index) => {
        const reportItemsClone = localReportItems ? [...localReportItems] : [];
        const targetReportItem = reportItemsClone[report_index];
        const display_columns = (targetReportItem?.display_columns) ? [...targetReportItem.display_columns] : [];

        if (key === "selected_columns") {
            const sort_field_order_column = (render_mode === "table" || !render_mode) ? "order" : "pivot_field_order";
            const final_display_column = change_column_order_v1(JSON.parse(JSON.stringify(display_columns)), event, sort_field_order_column)
            targetReportItem.display_columns = final_display_column;
            reportItemsClone[report_index] = targetReportItem;
            targetReportItem['delete_from_storage'] = true /// this is for delete from storage
            targetReportItem['call_java_forcefully'] = true;

            setLocalReportItems(reportItemsClone)

        }
    }

    const save_relation_ship_details = (relationships, report_index) => {
        const clone_report_items = localReportItems ? [...localReportItems] : [];
        const target_report = clone_report_items[report_index];
        target_report.relationship_details = relationships;

        target_report['delete_from_storage'] = true /// this is for delete from storage
        target_report['call_java_forcefully'] = true;


        clone_report_items[report_index] = target_report;
        setLocalReportItems(clone_report_items)
    }





    const openRelationshipPopup = (report_index) => {
        const clone_report_items = localReportItems ? [...localReportItems] : [];
        const target_report = clone_report_items[report_index];
        const relationships = target_report?.relationship_details ? [...target_report.relationship_details] : [];
        const table_names = target_report?.table_names ? [...target_report.table_names] : [];
        const final_table_data = { ...schema_table_info }
        showPopup("Stitch Tables", undefined, enums.default.popupType.element_with_header, RelationshipForm, {
            tableRelationship: relationships,
            tableInfo: table_names,
            index: report_index,
            tableData: final_table_data,
            setTableRelationship: save_relation_ship_details,
        }, undefined, undefined, undefined, {
            width: '100%',
            maxWidth: '35rem'
        });
    }




    const _remove_table = (table_name, report_index) => {
        const after_removed_result = remove_table_v1(localReportItems, table_name, report_index)
        setLocalReportItems(after_removed_result)
    }

    /***
     * this function will delete the report item by report index from local state 
     */
    const delete_report_item = (index) => {
        const clone_report_items = localReportItems ? [...localReportItems] : [];
        const report_item = clone_report_items[index];
        // if this report item has id then we will set it deleted=true
        if (report_item.id) {
            clone_report_items[index].deleted = true;
        } else {
            // we report item don't have id then we will delete it permanently
            clone_report_items.splice(index, 1)
        }

        setLocalReportItems(clone_report_items)

    }

    const delete_union = (index) => {
        showPopup(undefined, 'Are you sure you want to delete this ? ', enums.default.popupType.element, ConfirmBox, { func: { setYes: () => delete_report_item(index) } })
    }


    /****
     * this function will set the criteria of the report item by index
     */
    const setCriteria = (criteria, report_index) => {

        const reportItemsClone = localReportItems ? [...localReportItems] : [];
        const targetReportItem = reportItemsClone[report_index];

        targetReportItem.criteria = criteria;
        targetReportItem['modified'] = true;
        targetReportItem['delete_from_storage'] = true;
        targetReportItem['call_java_forcefully'] = true;


        reportItemsClone[report_index] = targetReportItem;
        setLocalReportItems(reportItemsClone)
    }

    /***
     * this function will the the value of report 
     */

    const on_change_report_item_value = (value, key, report_index) => {

        const reportItemsClone = localReportItems ? [...localReportItems] : [];
        const targetReportItem = reportItemsClone[report_index];
        targetReportItem[key] = value;
        targetReportItem["modified"] = true;
        targetReportItem["isQuestionModified"] = true;
        reportItemsClone[report_index] = targetReportItem;
        setLocalReportItems(reportItemsClone)

    }



    /****
     * this function will genrate the hints for the formula window 
     */
    const generate_hint_for_formula = (report_index) => {

        const result = [];
        const reportItemsClone = localReportItems ? [...localReportItems] : [];
        const targetReportItem = reportItemsClone[report_index];
        const table_names = (targetReportItem?.table_names) ? [...targetReportItem.table_names] : [];
        const display_columns = (targetReportItem?.display_columns) ? [...targetReportItem.display_columns] : [];


        display_columns && display_columns.length > 0 && display_columns.forEach((f) => {
            if (!f.is_formula && f.table_name) {
                const str = f.table_name + "." + f.orignal_name;
                result.push(str)
            }
        })
        return result
    }



    /***
     * this function will save the formula into the
     * local report item display field
     */
    const save_formula = (formula, index, report_index) => {

        const reportItemsClone = localReportItems ? [...localReportItems] : [];
        const targetReportItem = reportItemsClone[report_index];
        const display_columns = (targetReportItem?.display_columns) ? [...targetReportItem.display_columns] : [];
        const formula_index = display_columns.findIndex(el => el.index === index);
        const number_d_type = ["number", "currency", "percent"];
        const data_type = formula?.data_type;
        const pivot_type = number_d_type.indexOf(data_type) > -1 ? 'pivot_y' : 'pivot_x'
        const existence_details = pivot_type && get_existence_state_and_highest_field_order_v1(display_columns, 1, pivot_type);

        const pivot_info = {
            pivot_field_alias: formula.alias,
            pivot_field_column_name: formula.alias,
            pivot_type: render_mode !== 'table' ? pivot_type : undefined,
            axis: 'secondary',
            chart_type: render_mode,
            pivot_field_order: existence_details?.highest_field_order + 1
        }


        const prev_information = formula_index > -1 && display_columns[formula_index];

        const __obj__ = {
            ...prev_information,
            ...formula,
            aggregation: "sum",
            orignal_name: formula.alias,
            table_name: undefined,
            is_formula: true,
            visible_type: 1,
            modified: true,
            formula_type: "report_editor",
            ...pivot_info,
        }
        if (formula_index > -1) {
            display_columns[formula_index] = __obj__
        }
        else {
            __obj__.index = display_columns.length
            __obj__.order = display_columns.length
            display_columns.push(__obj__)
        }

        targetReportItem.display_columns = display_columns;
        reportItemsClone[report_index] = targetReportItem;
        setLocalReportItems(reportItemsClone)
    }


    /****
     * 
     * this function will open formula popup
     * where we can create or edit the formula of report
     */

    const open_formula_creator = (item, index, report_index) => {
        const clone_item = item ? Object.assign({}, item) : undefined;
        const __hints__ = generate_hint_for_formula(report_index);
        showPopup((clone_item ? 'Edit Your Formula' : 'Add Your Formula'), undefined, enums.default.popupType.element_with_header, FormulaEditorPopup, {
            items: clone_item ? clone_item : undefined,
            index: index,
            report_index: report_index,
            save_formula: save_formula,
            columns: __hints__
        }, undefined, undefined, undefined, {
            width: '39rem',
            maxWidth: '39rem'
        });
    }

    const elRefs = useRef([]);

    useEffect(() => {
        if (localTableColumns && Object.keys(localTableColumns).length > 0) {
            elRefs.current = localTableColumns && Object.keys(localTableColumns).length > 0 && Object.keys(localTableColumns).fill().map((_, i) => elRefs.current[i] || React.createRef());
        }
    }, [localTableColumns])


    const scrollToView = (word, index) => {
        const element = elRefs.current[index];
        if (element && element.current) {
            element.current.scrollIntoView({ block: 'start', behavior: 'smooth', inline: 'start' });
            setActiveTable(word)
            setIsColumnOpen(true)
        }
    };



    /***
     * this function will add the new field into the display field 
     */
    const add_new_column = (name, report_index) => {

        const reportItemsClone = localReportItems ? [...localReportItems] : [];
        const targetReportItem = reportItemsClone[report_index];
        let display_columns = (targetReportItem?.display_columns) ? [...targetReportItem.display_columns] : [];

        const data_type = (name && parseInt(name)) ? 'number' : 'string'

        const __obj__ = {
            aggregation: "none",
            order: display_columns.length,
            index: display_columns.length,
            alias: name,
            column_name: name,
            data_type: data_type,
            orignal_name: name,
            table_name: undefined,
            value: '',
            index: display_columns.length
        }

        display_columns.push(__obj__)

        targetReportItem.display_columns = display_columns;

        targetReportItem['delete_from_storage'] = true /// this is for delete from storage
        reportItemsClone[report_index] = targetReportItem;

        setShowAddColumn(undefined);
        setLocalReportItems(reportItemsClone)

    }



    const onSave = () => {

        let _is_processed = true;
        const _t_name = find_table_which_not_have_relationship_v1(localReportItems)
        if (_t_name && _t_name.length > 0) {
            _is_processed = false;
        }
        if (!db_info_id) {
            _is_processed = false;
        }

        const parameterized_fields = [];

        if (_is_processed) {

            const report = localReport ? localReport : {};
            const client_id = getClientId()['client-id'];
            const report_items = localReportItems ? [...localReportItems] : [];

            const is_pivot = Object.values(CHART_TYPES).indexOf(render_mode) > -1;

            report_items?.length > 0 && report_items.map((reportItem, index) => {

                const relationshipfinalOutput = [];
                const db_type = get_db_type();

                const final_save_output = get_final_save_report_item(reportItem, db_type, is_pivot);

                const report_item_auto_criteria = [];

                const final_column_info = reportItem.display_columns && reportItem.display_columns.length > 0 && reportItem.display_columns.filter((col) => {
                    if (!col.id && col.deleted) return false;
                    else return true;
                }) || [];

                final_column_info?.forEach((__row__) => {

                    if (__row__.auto_criteria && !__row__.deleted) {
                        report_item_auto_criteria.push(__row__.auto_criteria);
                    }
                    const proper_case = !__row__.is_formula ? get_pivot_field_in_proper_case(__row__.pivot_field_column_name) : __row__.pivot_field_column_name
                    __row__.pivot_field_column_name = proper_case
                    if (!__row__.is_repeater && !__row__.pivot_field_alias) {
                        __row__.pivot_field_alias = proper_case
                    }
                })






                reportItem.modified = true;
                reportItem.column_infos = final_column_info;
                reportItem.auto_criteria = report_item_auto_criteria ? report_item_auto_criteria.join(" or ") : undefined;


                // this code is only for HSL
                client_id && client_id === 'hsl' && final_save_output?.table_names?.length > 0 && final_save_output?.table_names?.forEach(a => {
                    if (a === 'tbl_targets') {
                        if (reportItem.auto_criteria && reportItem.auto_criteria.length > 5) {
                            reportItem.auto_criteria = "(" + reportItem.auto_criteria + ") And " + " tbl_targets.target_month= ':nf_month'";
                        } else {
                            reportItem.auto_criteria = "tbl_targets.target_month= ':nf_month'"
                        }

                    }
                })

                const __json__data = {
                    "display_columns": reportItem.display_columns,
                    "relationship_details": reportItem.relationship_details,
                    "filter_fields": filterColumns,
                    "table_names": reportItem.table_names
                }

                reportItem.json_data = JSON.stringify(__json__data);
                reportItem.relationship_details?.length > 0 && reportItem.relationship_details.forEach((r) => {
                    let _str = r.source_table + '.' + r.source_column + "=" + r.target_table + '.' + r.target_column + "@" + r.join_type;;
                    relationshipfinalOutput.push(_str)
                })

                reportItem.order = index;
                reportItem.relationship_details = relationshipfinalOutput;
                reportItem.display_columns = final_save_output?.display_columns;
                reportItem.table_names = final_save_output?.table_names;

            })

            report.report_items = report_items;
            report.modified = true;
            report.db_info_id = db_info_id;

            save_current_report(report)
            close(option, popupKey)

        }
    }



    var id_to_use_in_sql = current_report?.id || "insight02931234r3234"


    const save_sql_mode_report = () => {
        const report_items = localReportItems ? [...localReportItems] : [{}];
        report_items[0].sql_query = sql_query;
        report_items[0].report_item_type = report_type;
        // let's mt every thing when data mode 
        report_items[0].display_columns = [];
        report_items[0].column_infos = []
        report_items[0].parameters = [];
        report_items[0].modified = true;
        report_items[0].json_data = '{}';
        report_items[0].isQuestionModified = true;
        save_current_report(report_items[0])
        close(option, popupKey)
    }



    const test_sql = () => {
        const query = sql_query;
        if (query && query.length > 5) {
            props.test_sql_query(query, [db_info_id], id_to_use_in_sql);
        } else alert("please add your query")
    }




    /**** */
    const _add_new_report_item = () => {
        const clone_report_items = localReportItems ? [...localReportItems] : [];
        clone_report_items.push({
            display_columns: [],
            table_names: [],
            relationship_details: [],
            union: 'union'
        })
        setLocalReportItems(clone_report_items)
        setActiveReport(clone_report_items.length - 1)
    }



    const pivot_boxes = pivot_box_jsons && pivot_box_jsons?.[render_mode]
    const containerHeight = containerRef?.current?.clientHeight;
    const containerWidth = containerRef?.current?.clientWidth;
    const sql_result = props.sql_result_cache?.[id_to_use_in_sql];
    const sql_error = props.sql_error_cache?.[id_to_use_in_sql];
    const sql_loader = props?.sql_loader_cache?.[id_to_use_in_sql];


    console.log("render_mode", render_mode)

    return (
        <EditorWrapper ref={containerRef}>

            <Wrapper onClick={(e) => {
                setShowAddColumn(false);
            }}>
                {containerHeight && containerWidth && (
                    <div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', width: '100%', }}>
                        <LeftSection height={containerHeight - 50}>
                            <LeftSidePanel
                                connectionId={[db_info_id]}
                                localTableColumns={localTableColumns}
                                isColumnOpen={isColumnOpen}
                                activeTable={activeTable}
                                report_list={props.allReports ? props.allReports : []}
                                searchTable={searchTable}
                                setActiveTable={setActiveTable}
                                activeReportId={activeReportId}
                                elRefs={elRefs}
                                setIsColumnOpen={setIsColumnOpen}
                                is_etl={props.is_etl}

                            />
                        </LeftSection>

                        <RightSection
                            height={containerHeight - 5}
                            paddingTop={10}>

                            <div className="right_section_scroll" style={{ opacity: enable_editing ? 1 : 0, paddingTop: '0px', marginTop: '0px' }}>

                                {(active_editor === 'dashboard_config_query_builder') && localReportItems && localReportItems.length > 0 && localReportItems.map((reportItem, index) => {


                                    const display_columns = (reportItem.display_columns || []).filter((c) => c.formula_type !== 'report_viewer');
                                    const tableRelationship = reportItem.relationship_details;
                                    const tableNames = reportItem.table_names;

                                    if (!reportItem.deleted) {
                                        return (
                                            <Row
                                                onClick={() => {
                                                    setActiveReport(index);
                                                }}
                                                style={{ marginBottom: '1rem', opacity: activeReport == index ? '1' : '0.3' }}>
                                                <div className="left_side_col" style={{ width: '73%' }} onClick={(e) => {
                                                    e.preventDefault()
                                                    setActiveColumn(undefined)
                                                }}>
                                                    {index > 0 && (
                                                        <div className="row_header">
                                                            <select value={reportItem.union && reportItem.union} onChange={(e) => {
                                                                on_change_report_item_value(e.target.value, "union", index)
                                                            }}>
                                                                <option value="union">Union</option>
                                                                <option value="union all">Union All</option>

                                                            </select>
                                                            <div className="delete_btn">
                                                                <RippleEffect
                                                                    icon={<DeleteSvg size="1rem" height="1rem" color={"#1e5d86"} />}
                                                                    title={"delete union"}
                                                                    Click={() => delete_union(index)}
                                                                />
                                                            </div>
                                                        </div>

                                                    )}

                                                    <Row style={{ flexDirection: 'column', }}>
                                                        {Object.values(CHART_TYPES).indexOf(render_mode) == -1 && (
                                                            <div className="add_extra_column">
                                                                <div className="add_btn" title={"add column"}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        setShowAddColumn(!showAddColumn);
                                                                    }}
                                                                >+</div>
                                                                {showAddColumn && (
                                                                    <div onClick={(e) => {
                                                                        e.stopPropagation()
                                                                    }} style={{ position: 'relative', width: '100%' }}>
                                                                        <div className="add_column_section">
                                                                            <input
                                                                                type={"text"}
                                                                                value={added_column_name}
                                                                                placeholder="Enter column Name"
                                                                                onChange={(e) => {
                                                                                    set_added_column_name(e.target.value);
                                                                                }}
                                                                            />
                                                                            <button onClick={() => {
                                                                                if (added_column_name && added_column_name.length > 0) {
                                                                                    add_new_column(added_column_name, index);
                                                                                    set_added_column_name('');
                                                                                }

                                                                            }}>Add</button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                        {(render_mode === 'table' || render_mode === 'single_cell' || !render_mode) && (
                                                            <ElementBox
                                                                name={report_field_title ? report_field_title : "Report Fields"}
                                                                area_key={'selected_columns'}
                                                                width={'100%'}
                                                                wrapperHeight={'10rem'}
                                                                elementData={(display_columns || []).sort((a, b) => a.order - b.order)}
                                                                onDragOver={(event) => event.preventDefault()}
                                                                onColumnClick={onSelectedColumnClick}
                                                                drop_column={drop_column}
                                                                onDelete={remove_pirtual_element_from_array}
                                                                margin={'0rem 0rem 2rem 0rem'}
                                                                activeColumn={activeColumn}
                                                                change_column_order={change_column_order}
                                                                open_formula_creator={open_formula_creator}
                                                                dropColumnMargin={"2.1rem"}
                                                                index={index}
                                                                setActiveColumn={setActiveColumn}
                                                            />
                                                        )}


                                                        {/* here we will show the column boxes */}
                                                        {Object.values(CHART_TYPES).indexOf(render_mode) > -1 && (
                                                            <Row>
                                                                <React.Fragment>
                                                                    {pivot_boxes && Object.keys(pivot_boxes).map((k) => {

                                                                        const key = k;
                                                                        const width = (100 / (pivot_boxes && Object.keys(pivot_boxes).length)) - 1;
                                                                        const element_data = (display_columns ? get_elements_for_render(display_columns, key) : []).filter((c => (!c.is_repeater && c.formula_type !== 'report_viewer')))

                                                                        return (
                                                                            <ElementBox
                                                                                pivot_type={key}
                                                                                name={pivot_boxes[k].label}
                                                                                area_key={'selected_columns'}
                                                                                width={width + "%"}
                                                                                wrapperHeight={'10rem'}
                                                                                elementData={element_data}
                                                                                onDragOver={(event) => event.preventDefault()}
                                                                                onColumnClick={onSelectedColumnClick}
                                                                                drop_column={drop_column}
                                                                                onDelete={remove_pirtual_element_from_array}
                                                                                margin={'0rem 0rem 2rem 0rem'}
                                                                                activeColumn={activeColumn}
                                                                                change_column_order={change_column_order}
                                                                                open_formula_creator={open_formula_creator}
                                                                                dropColumnMargin={"2.1rem"}
                                                                                index={index}
                                                                                setActiveColumn={setActiveColumn}
                                                                            />
                                                                        )
                                                                    })}
                                                                </React.Fragment>
                                                            </Row>

                                                        )}


                                                    </Row>

                                                    {index === 0 && (
                                                        <Row style={{ marginTop: '10px' }}>
                                                            <button
                                                                style={{ fontSize: '0.8rem' }}
                                                                onClick={() => {
                                                                    open_formula_creator(undefined, undefined, index);
                                                                }}>
                                                                + Add Formula
                                                            </button>
                                                        </Row>
                                                    )}

                                                    <Row style={{ justifyContent: 'flex-start', marginTop: '1rem' }}>
                                                        <div class="cell_box" style={{ width: '30%', marginRight: '2rem' }}>
                                                            <h2 class="label">Table Names</h2>
                                                            <div class="cell_box_border"
                                                                onDrop={(e) => {
                                                                    e.stopPropagation();
                                                                    onDropTable(e, index);
                                                                }}
                                                                onDragOver={(event) => event.preventDefault()}
                                                            >
                                                                {tableNames && tableNames.length > 0 && (
                                                                    <ul>
                                                                        {tableNames.map((item, i) => {
                                                                            return (
                                                                                <li key={'t' + i}>
                                                                                    <div class="cell_flex">
                                                                                        <div className="column_name"
                                                                                            title={item.schema_id}
                                                                                            onClick={() => {
                                                                                                const tbl_index = localTableColumns && Object.keys(localTableColumns).indexOf(item);
                                                                                                scrollToView(item, tbl_index)
                                                                                                // setActiveColumn
                                                                                            }}
                                                                                        >{item.name}</div>
                                                                                        <div title="remove" className="close" onClick={(e) => {
                                                                                            _remove_table(item.name, index);
                                                                                        }}>
                                                                                            <CloseSvg size=".5rem" height=".5rem" />
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div class="cell_box" style={{ width: '68%', }}>
                                                            <div style={{ position: 'relative' }}>
                                                                <h2 class="label">Your Table Relationship</h2>
                                                                <button
                                                                    onClick={() => {
                                                                        openRelationshipPopup(index)
                                                                    }}
                                                                    style={{
                                                                        fontSize: '0.8rem',
                                                                        position: 'absolute',
                                                                        right: '0px',
                                                                        top: '0px',
                                                                    }}
                                                                >Edit Relationship</button>
                                                            </div>
                                                            <div class="cell_box_border">
                                                                <ul>
                                                                    {tableRelationship && tableRelationship.length > 0 && tableRelationship.map((item, i) => {
                                                                        if (!item.deleted) {
                                                                            let _str = item.source_table + '.' + item.source_column + "=" + item.target_table + '.' + item.target_column + "@" + item.join_type;
                                                                            return (
                                                                                <li style={{ maxWidth: '100%' }}>
                                                                                    <div className="column_name">{_str}</div>
                                                                                </li>
                                                                            )
                                                                        }
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </Row>



                                                    <Row style={{ justifyContent: 'flex-start', marginTop: '1rem' }}>
                                                        <div style={{ width: '100%', }}>
                                                            <Criteria
                                                                example={"tbl_bill.name starts with surgery"}
                                                                hints={criteriaSuggestions && criteriaSuggestions[index]}
                                                                criteria={reportItem && reportItem.criteria}
                                                                parameters_fields={parameters_fields}
                                                                schema_table_info={schema_table_info}
                                                                db_info_id={[db_info_id]}
                                                                setCriteria={(c) => {
                                                                    setCriteria(c, index)
                                                                }} />
                                                        </div>
                                                    </Row>


                                                    {/* <Row style={{ justifyContent: 'flex-start', marginTop: '1rem' }}>
                                                        <ElementBox
                                                            name={"Filter Fields"}
                                                            area_key={'filter_fields'}
                                                            width={'100%'}
                                                            wrapperHeight={'6rem'}
                                                            elementData={filterColumns}
                                                            onDragOver={(event) => event.preventDefault()}
                                                            drop_column={drop_column}
                                                            onDelete={remove_pirtual_element_from_array}
                                                            margin={'0rem 0rem 2rem 0rem'}
                                                            activeColumn={activeColumn}
                                                            change_column_order={change_column_order}
                                                            dropColumnMargin={"1rem"}
                                                            index={index}
                                                        />
                                                    </Row> */}
                                                </div>
                                                <ColumnInfoEditor
                                                    column_proprty_on_change={column_proprty_on_change_local}
                                                    setActiveColumn={setActiveColumn}
                                                    activeColumn={(activeReportIndex === index) ? activeColumn : undefined}
                                                    index={index}
                                                    height={"22.7rem"}
                                                    marginTop={index > 0 ? "5rem" : '1.5rem'}
                                                    render_mode={"table"}
                                                    connectionId={db_info_id ? [db_info_id] : undefined}
                                                    db_infos={props.db_infos}
                                                />
                                            </Row>
                                        )
                                    }


                                })}

                                {active_editor === 'dashboard_sql' && (
                                    <React.Fragment>
                                        <Row style={{ marginTop: '1rem', flexDirection: 'column' }}>
                                            {/* <h2 class="label">Write Your SQL Query</h2> */}
                                            <SqlEditor
                                                set_sql_query={(value) => {
                                                    set_sql_query(value)
                                                    if (localReportItems && !localReportItems[0]?.delete_from_storage) {
                                                        const clone_report_items = localReportItems ? JSON.parse(JSON.stringify(localReportItems)) : [];
                                                        clone_report_items[0].delete_from_storage = true;
                                                        clone_report_items[0]['call_java_forcefully'] = true;

                                                        setLocalReportItems(clone_report_items)
                                                    }
                                                }}
                                                sql_query={sql_query}
                                                initalValue={inital_query}
                                            />
                                        </Row>

                                        <Row style={{ marginTop: '.5rem' }}>
                                            <NormalButton
                                                width='7rem'
                                                primary={true}
                                                mR=".5rem"
                                                onClick={() => {
                                                    test_sql()
                                                }}
                                                icon={<PlaySvg size='.8rem' height='.8rem' color="#fff" />}
                                                label="Test Query"
                                            />
                                        </Row>

                                        <Row style={{ marginTop: '.5rem' }}>
                                            {!sql_loader && (
                                                <React.Fragment>
                                                    {sql_result && sql_result.length > 0 && (
                                                        <div style={{ border: '1px solid #ccc', width: '100%' }}>
                                                            <SharedTable
                                                                height={'17rem'}
                                                                tableData={(sql_result)}
                                                                columnsToShow={sql_result ? Object.keys(sql_result[0]) : undefined}
                                                                tableHead={sql_result ? Object.keys(sql_result[0]) : undefined}
                                                                fontSize=".7rem"
                                                                rowHeight="1.8rem"
                                                            />
                                                            <p style={{ margin: '0px', padding: '10px', fontSize: '15px' }}>
                                                                Total Number Of Records :  <strong>{sql_result.length}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;
                                                                Max Records Limit:  <strong>{200}</strong>
                                                            </p>
                                                        </div>
                                                    )}

                                                    {sql_result && sql_result.length == 0 && <p style={{ textAlign: 'center' }}>No Record Found</p>}
                                                    {sql_error && (
                                                        <div style={{
                                                            display: 'flex',
                                                            width: '100%',
                                                            alignItems: 'center',
                                                            flexDirection: 'column',
                                                            height: '16rem',
                                                            justifyContent: 'center'
                                                        }}>
                                                            <WarningSvg size="2rem" height="2rem" />
                                                            <p style={{ fontSize: '.9rem' }}>{sql_error.message}</p>
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}

                                            {sql_loader && <p style={{ textAlign: 'center', margin: 'auto' }}>Please wait while we are fetching the results....</p>}

                                        </Row>
                                    </React.Fragment>
                                )}



                                <Row style={{ justifyContent: 'flex-start', marginTop: '1rem' }}>
                                    <NormalButton
                                        bgColor='transparent'
                                        style={{
                                            border: '1px solid #374a66'
                                        }}
                                        // hoverBg="#374a66"
                                        mR=".5rem"
                                        onClick={() => {
                                            _add_new_report_item()
                                        }}
                                        color="#374a66"
                                        icon={<AddSvg size='.7rem' height='.7rem' color="#374a66" />}
                                        label="Add Union"
                                    />
                                </Row>


                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginTop: 'auto' }}>
                                    <Button width="5rem" mR="1rem" onClick={() => {
                                        setActiveColumn(undefined)
                                        // if (report_type === 'dashboard_sql') {
                                        //     save_sql_mode_report()
                                        // } else

                                        onSave()
                                    }}>Next</Button>
                                    {/* <Button
                                        width="5rem"
                                        bgColor="#9caeb5"
                                        onClick={() => {
                                            close(option, popupKey)
                                        }}>Cancel</Button> */}
                                </div>
                            </div>
                        </RightSection>
                    </div>
                )}
            </Wrapper>
        </EditorWrapper>
    )
}


const mapStateToProps = (state) => ({

    relationships: state.relationshipDetail.relationships,
    sqlQueryResult: state.connectionReducer.sqlQueryResult,
    functions_config: state.reportReducer.functions_config,
    testSqlResult: state.reportReducer.testSqlResult,
    testSqlError: state.reportReducer.testSqlError,
    schema_table_info: state.reportBuilder.schema_table_info,
    hints_table: state.reportBuilder.hints_table,
    edit_report_cache: state.reportBuilder.edit_report_cache,
    db_infos: state.connectionReducer.db_infos,

    sql_result_cache: state.reportReducer.sql_result_cache,
    sql_error_cache: state.reportReducer.sql_error_cache,
    sql_loader_cache: state.reportReducer.sql_loader_cache,


})

export default withRouter(connect(mapStateToProps, {
    clear_schema_table_info,
    test_sql_query,
    db_functions_config,
    getAllRelationships,
    get_schema_table_info,
    getSqlQuery,
})(Editor))
