import React from 'react';

const DonutChart = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}

            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1728 1728"
            space="preserve">
            <path transform="translate(1058,130)" d="m0 0 74 26 27 11 26 12 26 13 23 13 23 14 22 14 14 10 19 14 17 14 11 9 14 12 13 12 14 14 6 5v2l4 2 7 8 14 14 9 11 10 11 11 14 12 15 13 18 22 33 11 17 13 23 10 19 13 27 12 28 12 31 9 27 10 34 10 44 6 36 5 46 3 29v80l-8 70-5 29-12 53-9 31-12 35-15 36-17 36-12 24-8 14-17 28-14 21-14 20-9 12-10 13-9 11-13 15-9 10-7 8-7 7-7 8-16 16-8 7-12 11-12 9-9 8-14 11-13 10-20 14-18 12-19 12-26 15-41 21-31 14-36 14-40 13-37 10-40 9-22 4h-12l-4-6-15-53-16-64-9-34-21-80-14-54-3-15v-11l3-1 9 1h23l31-6 39-11 28-10 21-9 21-10 25-15 17-11 20-14 22-18 12-11 8-7 12-13 8-10 9-11 8-10 8-11 11-16 9-15 8-14 11-23 11-28 9-28 7-29 6-30 4-42 1-5v-16l-5-48-5-30-7-28-8-26-14-35-17-34-15-25-14-20-14-17-11-15-7-14-6-20-11-42-15-56-13-50-11-43-17-64-14-55-10-38-8-34-3-13v-5z" fill="#357CFD" />
            <path transform="translate(823,107)" d="m0 0h76l52 3 23 2 5 2 6 21 11 42 19 74 9 34 9 35 8 30 8 31 14 52 9 36 2 7-1 5-8-1-16-8-17-8-41-16-24-7-42-9-21-3-11-1-36-1h-18l-26 2-26 4-23 5-31 9-18 6-24 10-25 12-23 13-24 16-13 10-17 14-11 9-15 14-13 13-9 11-10 12-12 17-11 16-8 13-10 18-12 23-12 29-8 24-8 28-6 29-3 22-1 12v71l6 23v8l-8 10-7 7-7 8-27 27-8 7-15 14-8 7-93 93h-2l-2 4-24 24-8 7-19 19-7 8-12 13-4 4-4-1-8-14-8-19-12-30-12-36-8-26-12-49-5-26-6-40-2-25-2-26-1-11v-41l3-28 2-29 4-32 8-44 7-31 13-46 15-41 11-26 12-25 15-30 9-16 16-27 10-15 9-13 7-10 12-17 8-10 7-9 13-16 9-10 1-2h2l2-4 14-15 32-32 8-7 13-11 14-11 13-11 19-14 14-10 29-19 15-9 26-15 20-11 28-13 24-10 29-11 36-12 37-10 32-7 48-7 36-4z" fill="#1960C3" />
            <path transform="translate(465 1e3)" d="m0 0 4 2 12 28 14 26 16 26 10 14 9 12 12 14 15 16 21 21 11 9 10 9 13 10 21 14 13 8 21 12 25 12 27 11 29 9 28 7 26 5 5 5 5 14 15 60 21 80 12 46 15 56 10 38 6 18v7l-3 1h-78l-36-4-42-6-39-7-34-9-29-9-43-15-22-9-24-11-30-15-22-12-25-15-27-18-20-14-14-11-13-11-10-8-13-12-11-9-13-12-3-2v-2l-4-2-8-8-7-8-13-15-11-12-9-11-11-13-12-16-10-13-13-18-14-22-2-3 1-6 12-11 7-8 31-31 7-8 63-63 8-7 10-9 8-7 16-15 27-27h2l2-4 4-4h2v-2l8-7 10-10 8-7 8-9z" fill="#6EA2FC" />
            <path transform="translate(1702)" d="m0 0h4v1728h-5l-1-2-13-1-31-1h-44l-30 1 5 1v1h-11-1-24l-25 1h-179l-39-1v-1l44-1h-143l2-2 21-1 7 1v-2h114l342 1 5 1v-915l-1-519v-57l1-159 1-65z" fill="#C9CBD1" />
            <path transform="translate(5,15)" d="m0 0h1v396l1 43 1 107 1 437v237l-1 177-1 78-1 109-2-1-2-36-1-5v-183l1-817v-72l1-236h-1l-1-19v-86h1v-107l1-7h2zm-3 132m1 52 1 2zm0 4m0 2 1 4z" fill="#B2BCCD" />
            <path transform="translate(1701,1191)" d="m0 0h1l2 93 1 258 1 152v34h-5l-1-2-13-1-31-1h-44l-30 1 5 1v1h-11-1-24l-25 1h-179l-39-1v-1l44-1h-143l2-2 21-1 7 1v-2h114l342 1 5 1v-453z" fill="#5F98FD" />
            <path transform="translate(71,1721)" d="m0 0h36l69 1h319l81 1v1l-15 1-43 1h54l7 1v1h-446l-36-1v-1l23-1h-88l-6-1v-1l45-1zm13 1 4 1z" fill="#B1BBCE" />
            <path transform="translate(5,15)" d="m0 0h1v396l1 43 1 107v144h-1l-1-68v-3l-1 5-1 25-1 49h-1v-228l1-236h-1l-1-19v-86h1v-107l1-7h2zm-3 132m1 52 1 2zm0 4m0 2 1 4z" fill="#B2BCCD" />
            <path transform="translate(1239,1721)" d="m0 0h114l293 1 10 1v1h-44l-30 1 5 1v1h-11-1-24l-25 1h-179l-39-1v-1l44-1h-143l2-2 21-1 7 1z" fill="#B1BBCE" />
            <path transform="translate(1050,388)" d="m0 0 2 4 14 52 6 25 2 7-1 5-8-1-22-11v-8l1-5v-10l2-5 2-14 1-8-2-14v-11l1-2h2z" fill="#367DFD" />
            <path transform="translate(5,411)" d="m0 0h1l1 43 1 107v144h-1l-1-68v-3l-1 5-1 25-1 49h-1v-228l1-46 1-2z" fill="#C4C6CD" />
            <path transform="translate(1702,41)" d="m0 0h1l1 95v108l-2 178h-1l-2-133v-57l1-159z" fill="#2E6FCE" />
            <path transform="translate(1702)" d="m0 0h4v110l-1 153-2 212-1 53h-1l-1-71v-105h1l1-26 1-82v-108l-1-78h-1v-50z" fill="#6CA0FD" />
            <path transform="translate(1701,1022)" d="m0 0h1l1 45 1 94 1 38v343h-1l-1-258-1-40-1 26h-1v-121z" fill="#B2BCCD" />
            <path transform="translate(1701,1321)" d="m0 0h1l1 133v221l-1 37h-1l-1-32v-300z" fill="#1960C3" />
            <path transform="translate(71,1721)" d="m0 0h36l69 1h275v1l-15 1-46 1h-358l-6-1v-1l45-1zm13 1 4 1z" fill="#70A3FB" />
            <path transform="translate(1704,263)" d="m0 0h1v153l-2 249-1 65h-1l-1-114v-159h1l1 18z" fill="#B2BCCE" />
            <path transform="translate(1239,1721)" d="m0 0h114l203 2v1l-59 1h-288l2-2 21-1 7 1z" fill="#70A2FB" />
            <path transform="translate(1704,244)" d="m0 0h1l-1 123-1 108-1 53h-1l-1-71v-105h1l1-26 1-81z" fill="#629AFD" />
            <path transform="translate(1395,4)" d="m0 0h289v2h-144l-145-1z" fill="#B2BCCE" />
            <path transform="translate(688,1726)" d="m0 0h136l52 1v1h-240v-1z" fill="#B2BCCE" />
            <path transform="translate(1700,1680)" d="m0 0h1v32h3l1-18h1v34h-5l-1-2-13-1-30-1-11-1v-1h49l5 1z" fill="#78A4F2" />
            <path transform="translate(136,4)" d="m0 0h231v1h-231z" fill="#81A9EF" />
            <path transform="translate(5,15)" d="m0 0h1v48l-1 52-1 9h-1l-1-87 1-7h2z" fill="#C3C6CD" />
            <path transform="translate(225,1725)" d="m0 0h70v2l-2 1h-69l-1-2z" fill="#AFBBCF" />
            <path transform="translate(1704,1628)" d="m0 0h1v85l-3-1v-39l1-44z" fill="#6A9FFD" />
            <path transform="translate(1239,1721)" d="m0 0h28l26 1v1l-16 1v1h-68l2-2 21-1 7 1z" fill="#B2BCCE" />
            <path transform="translate(71,1721)" d="m0 0h36l9 1-2 2-60 1h-22l-6-1v-1l45-1zm13 1 4 1z" fill="#A6B7D5" />
            <path transform="translate(1702)" d="m0 0h4v110h-1l-1-76-1-21z" fill="#B2BCCE" />
            <path transform="translate(1476,2)" d="m0 0h159v1h-159z" fill="#B2BCCE" />
            <path transform="translate(1044,366)" d="m0 0 2 4 4 14-1 4-6-4-2-3-1-9z" fill="#3A7FFD" />
            <path transform="translate(1702,6)" d="m0 0h1l1 7v21h-1l-1 24h-1v-50z" fill="#70A1F8" />
            <path transform="translate(877,1727)" d="m0 0h91v1h-91z" fill="#B2BCCE" />
            <path transform="translate(1705,1694)" d="m0 0h1v34h-3l-1-8 1-8h1z" fill="#B4BECF" />
            <path transform="translate(225,1725)" d="m0 0h14v2l-3 1h-12l-1-2z" fill="#A8B8D4" />
            <path transform="translate(14,1720)" d="m0 0h39v1h-39z" fill="#B2BCCE" />
            <path transform="translate(307,1725)" d="m0 0h9l-1 3h-9l-2-2z" fill="#A6B7D5" />
            <path transform="translate(969,1727)" d="m0 0h23v1h-23z" fill="#B2BCCE" />
            <path transform="translate(623,1727)" d="m0 0h11v1h-11z" fill="#B2BCCE" />
            <path transform="translate(580,1727)" d="m0 0h9v1h-9z" fill="#B2BCCE" />
            <path transform="translate(996,1727)" d="m0 0h8v1h-8z" fill="#B2BCCE" />
            <path transform="translate(593,1727)" d="m0 0h8v1h-8z" fill="#B2BCCE" />
            <path transform="translate(376,4)" d="m0 0h8v1h-8z" fill="#B2BCCE" />
            <path transform="translate(604,1727)" d="m0 0h7v1h-7z" fill="#B2BCCE" />
            <path transform="translate(368,4)" d="m0 0h7v1h-7z" fill="#B2BCCE" />
            <path transform="translate(1639,2)" d="m0 0h7v1h-7z" fill="#B2BCCE" />
            <path transform="translate(1301,1726)" d="m0 0h6v1h-6z" fill="#B2BCCE" />
            <path transform="translate(386,4)" d="m0 0h6v1h-6z" fill="#B2BCCE" />
            <path transform="translate(1008,1727)" d="m0 0h5v1h-5z" fill="#B2BCCE" />
            <path transform="translate(615,1727)" d="m0 0h5v1h-5z" fill="#B2BCCE" />
            <path transform="translate(1388,4)" d="m0 0h5v1h-5z" fill="#B2BCCE" />
            <path transform="translate(1695,3)" d="m0 0h3v2l-3-1z" fill="#B2BCCE" />
            <path transform="translate(1589,1726)" d="m0 0 4 1z" fill="#B2BCCE" />
            <path transform="translate(580,1723)" d="m0 0 4 1z" fill="#B2BCCE" />
            <path transform="translate(125,4)" d="m0 0 4 1z" fill="#B2BCCE" />
            <path transform="translate(54,1720)" d="m0 0 3 1z" fill="#B2BCCE" />
            <path transform="translate(1692,3)" d="m0 0h2l-1 2z" fill="#B2BCCE" />
            <path transform="translate(1040,1727)" d="m0 0 2 1z" fill="#B2BCCE" />
            <path transform="translate(1023,1727)" d="m0 0 2 1z" fill="#B2BCCE" />
            <path transform="translate(1298,1726)" d="m0 0 2 1z" fill="#B2BCCE" />
            <path transform="translate(91,1726)" d="m0 0 2 1z" fill="#B2BCCE" />
            <path transform="translate(585,1724)" d="m0 0 2 1z" fill="#B2BCCE" />
            <path transform="translate(1206,1723)" d="m0 0 2 1z" fill="#B2BCCE" />
            <path transform="translate(2,27)" d="m0 0" fill="#C5C7CD" />
            <path transform="translate(3,25)" d="m0 0" fill="#C5C7CD" />
            <path transform="translate(133,4)" d="m0 0 2 1z" fill="#B2BCCE" />
            <path transform="translate(1470,2)" d="m0 0 2 1z" fill="#B2BCCE" />
            <path transform="translate(1044,1727)" d="m0 0" fill="#B2BCCE" />
            <path transform="translate(1019,1727)" d="m0 0" fill="#B2BCCE" />
            <path transform="translate(1603,1726)" d="m0 0" fill="#B2BCCE" />
            <path transform="translate(94,1726)" d="m0 0" fill="#B2BCCE" />
            <path transform="translate(597,1724)" d="m0 0" fill="#B2BCCE" />
            <path transform="translate(1203,1723)" d="m0 0" fill="#B2BCCE" />
            <path transform="translate(592,1723)" d="m0 0" fill="#B2BCCE" />
            <path transform="translate(577,1723)" d="m0 0" fill="#B2BCCE" />
            <path transform="translate(22,1722)" d="m0 0" fill="#B2BCCE" />
            <path transform="translate(58,1720)" d="m0 0" fill="#B2BCCE" />
            <path transform="translate(2,19)" d="m0 0" fill="#C5C7CD" />
            <path transform="translate(5,13)" d="m0 0" fill="#C5C7CD" />
            <path transform="translate(1688,5)" d="m0 0" fill="#B2BCCE" />
            <path transform="translate(1690,3)" d="m0 0" fill="#B2BCCE" />
            <path transform="translate(1647,2)" d="m0 0" fill="#B2BCCE" />
            <path transform="translate(1636,2)" d="m0 0" fill="#B2BCCE" />
            <path transform="translate(1468,2)" d="m0 0" fill="#B2BCCE" />
        </svg>

    )

}


export default DonutChart;

