import React from "react";

const InfoIcon = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      shape-rendering="geometricPrecision"
      text-rendering="geometricPrecision"
      image-rendering="optimizeQuality"
      fill-rule="evenodd"
      clip-rule="evenodd"
      viewBox="0 0 512 512"
    >
      <path d="M256 0c70.689 0 134.692 28.656 181.02 74.98C483.344 121.308 512 185.311 512 256c0 70.689-28.656 134.692-74.98 181.016C390.692 483.344 326.689 512 256 512c-70.689 0-134.692-28.656-181.016-74.984C28.656 390.692 0 326.689 0 256S28.656 121.308 74.984 74.98C121.308 28.656 185.311 0 256 0zm-8.393 139.828c5.039-12.2 17.404-20.536 30.609-20.536 18.611 0 32.717 15.259 32.717 33.478 0 4.53-.796 8.776-2.407 12.704-6.902 16.91-26.09 25.405-43.082 18.302-16.871-7.122-24.821-27.096-17.837-43.948zm12.103 206.605c-.833 2.984-2.256 7.946-.674 10.725 4.22 7.45 16.459-6.058 19.036-8.97 8.307-9.414 15.461-20.475 21.905-31.229a1.506 1.506 0 012.061-.523l13.44 9.972c.641.473.789 1.363.367 2.03-6.18 10.743-12.426 20.124-18.744 28.129-10.452 13.234-23.595 25.852-39.583 32.065-9.918 3.842-22.817 5.363-34.144 2.829-10.506-2.353-19.66-8.206-23.822-18.946-5.464-14.092-.97-30.105 3.33-43.887l21.689-65.697c2.962-10.647 10.044-29.661-8.25-29.661H197.36c-1.56 0-1.596-1.402-1.297-2.484l4.858-17.685a1.5 1.5 0 011.463-1.103l96.89-3.038c1.409-.05 1.701 1.19 1.374 2.286L259.71 346.433z" />
    </svg>
  );
};

export default InfoIcon;
