import React, { useEffect, useState } from 'react'
import { useFormula } from './useFormula';
import SchemaTableWindow from '../editor.shared/left.side.panel';
import { connect } from 'react-redux';
import BlockElements from './block.elements';
import styled from 'styled-components';
import { Button } from '../hoc/button';
import ColumnEditor from './column.editor';
import { column_proprty_update } from './shared.fun';
import { withRouter } from 'react-router-dom';
import { getDbInfoId } from '../../utils';



const PopupWrapper = styled.div`
    background-color: #fff;
    height: 80vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background-color: red; */
`;


const AggregationIF = (props) => {


    const { close, option, popupKey, data } = props
    const { sorted_schema_table, column_info, __save, prev_formulas } = data;


    const [db_info_ids, set_db_info_ids] = useState(['demo_med_db_info_id']);

    const [localTableColumns, setLocalTableColumns] = useState();

    const [activeColumn, setActiveColumn] = useState(undefined)
    const [activeEditorType, setActiveEditorType] = useState(undefined)


    useEffect(() => {

        const db_id = getDbInfoId()
        set_db_info_ids([db_id])

    }, [])
    useEffect(() => {
        setLocalTableColumns(sorted_schema_table)
    }, [sorted_schema_table])


    //
    const {

        formulas = {},
        addFormula,
        updateFormula,
        deleteFormula,
        deleteFormulaRow,
        deleteFormulaBlock,
        setFormulas

    } = useFormula();


    useEffect(() => {

        if (prev_formulas) setFormulas(JSON.parse(JSON.stringify((prev_formulas || {}))))
        else setFormulas({ if: [] })
        // return () => {
        // }

    }, [prev_formulas])


    const column_proprty_on_change = (key, value, __id) => {

        const current_column = activeColumn ? { ...activeColumn } : {} //formulas?.find((f) => f.id === __id);

        if (current_column) {

            const new_info = column_proprty_update(props.functions_config, activeColumn, key, value)

            console.log("new_info", new_info)
            setActiveColumn(new_info)

            updateFormula(undefined, activeEditorType, new_info, __id)
        }
    }




    return (
        <PopupWrapper>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '0',

                }}>
                <div style={{
                    width: '20rem',
                    background: '#fff',
                    boxSizing: 'border-box',
                    padding: '0px .4rem'
                }}>
                    <SchemaTableWindow
                        connectionId={db_info_ids}
                        localTableColumns={localTableColumns}
                        open_table_column={{
                            schema_id: column_info?.schema_id || db_info_ids?.[0],
                            table_name: column_info?.table_name
                        }}
                        hide_db_changer={true}
                        hide_search_table={true}
                        height={"70vh"}
                    />
                </div>


                <div
                    style={{
                        width: 'calc(100% - 40rem)',  
                        background: '#eff4fc',
                        padding: '10px',
                        boxSizing: 'border-box',
                        marginRight: '20px'
                    }}>
                    <BlockElements
                        addFormula={addFormula}
                        updateFormula={updateFormula}
                        deleteFormula={deleteFormula}
                        deleteFormulaRow={deleteFormulaRow}
                        onDragOver={(event) => event.preventDefault()}
                        formulas={formulas}
                        setActiveColumn={setActiveColumn}
                        setActiveEditorType={setActiveEditorType}
                        deleteFormulaBlock={deleteFormulaBlock}
                        hide_lable={true}
                        hide_then={true}
                        minHeight={"300px"}
                    />
                </div>
                <div style={{
                    marginTop: '1rem',
                    minHeight: '200px',
                    maxHeight: '300px',
                    width: '20rem',
                    background: '#fff',
                    marginRight: '1rem',
                    position: 'relative',
                }}>
                    <ColumnEditor
                        activeColumn={activeColumn}
                        hide_aggregation={true}
                        column_proprty_on_change={column_proprty_on_change}
                        setActiveColumn={setActiveColumn}
                        height={"300px"}

                    // open_aggregation_if_popup={open_aggregation_if_popup}
                    // column_ifs_conditions={activeColumn && column_ifs_conditions?.[activeColumn?.id]}
                    />

                </div>
            </div>

            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginTop: 'auto', paddingBottom: '1rem' }}>
                <Button width="5rem" mR="1rem"
                    primary={true}
                    onClick={() => {
                        __save(formulas)
                        close(option, popupKey)

                    }}>Save</Button>

                <Button
                    width="5rem"
                    bgColor={'#e6e6e6'}
                    onClick={() => {
                        close(option, popupKey)
                    }}>Cancel</Button>
            </div>

        </PopupWrapper>
    )
}




const mapStateToProps = (state, props) => ({
    functions_config: state.reportReducer.functions_config,
})


export default withRouter(connect(mapStateToProps, {})(AggregationIF));