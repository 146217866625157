export const LAYOUT_INIT = 'modules/layout/init';

export const GET_LAYOUT_LIST_REQUESTED = 'modules/layout/list/requested';
export const GET_LAYOUT_LIST_SUCCESS = 'modules/layout/list/success';

export const SET_ACTIVE_EDIT_LAYOUT_SUCCESS = 'modules/activeEditLayout/success';

export const SAVE_LAYOUT_REQUESTED = 'modules/layout/save/requested';
export const SAVE_LAYOUT_SUCCESS = 'modules/layout/save/success';


// v1

export const DISPATCH_AUTO_LAYOUT_DATA = 'modules/layout/data/autoLayoutData';
