import React, { useEffect, useState } from 'react'
import { PageInnerWrapper } from '../hoc/styled.hoc';
import SharedTable from '../shared-table';
import { connect } from 'react-redux';
import { Button } from '../hoc/button';
import { withRouter } from 'react-router-dom';
import { getAllDataPolicy, saveDataPolicy } from '../../actions/access_group';


const Index = (props) => {

    const { getAllDataPolicy, dataPolicies, } = props;
    const [isLoding, setIsloding] = useState(true);


    useEffect(() => {
        if(dataPolicies?.length > -1){
            setIsloding(false);
        }
    }, [dataPolicies])



    useEffect(() => {
        getAllDataPolicy()

        let _page_info = {
            title: 'Data Policy',
            extraComponent: <Button primary={true} onClick={() => {
                props.history.push('/data_policy_editor');

            }}>
                Add New
            </Button>
        }
        props.dispatchHeaderInformation(_page_info)

    }, [])


    return (
        <div>
            <PageInnerWrapper padding={'10px'} style={{ marginTop: '1rem' }}>
                <SharedTable
                    isLoding={isLoding}
                    height={"calc(100vh - 180px)"}
                    editAction={(item) => {
                        const path = `/data_policy_editor?policyId=${item?.id}`;
                        props.history.push(path);
                    }}
                    deleteAction={(item) => {
                        let tempItem = item ? Object.assign({}, item) : {}
                        tempItem.deleted = true;
                        props.saveDataPolicy(tempItem)
                        // save_data_hierarchy(tempItem)
                    }}
                    duplicateAction={() => { }}
                    tableData={(dataPolicies && dataPolicies.length > 0) ? dataPolicies : []}
                    tableHead={["Name", "Table Columns", 'Access', 'Action']}
                    columnsToShow={["name", 'table_columns', "go_to",]}
                />
                {/* {dataPolicies == undefined || dataPolicies.length == 0 ? <p style={{ textAlign: "center" }}>No Record Found</p> : null} */}
            </PageInnerWrapper>

        </div>
    )

}




const mapStateToProps = (state) => ({

    dataPolicies: state.groupAccessReducer.dataPolicies

})



export default withRouter(connect(mapStateToProps, { saveDataPolicy, getAllDataPolicy })(Index))