export const none = 0; // for the undefined values
export const number = 1;
export const integer = 1;
export const int = 1;
export const number_with_decimal = 1.1;
export const mobile = 2;
export const currency = 2.1;
export const epoch = 3;
export const date = 4;
export const date_time = 4.1;
export const date_month = 4.2;
export const time = 4.5;
export const string = 5;
export const email = 6;
export const object = 7;
export const geojson = 8;
export const percent = 1.2;

export function convert_to_string(data_type) {
    switch (data_type) {
        case 0:
            return 'none';
        case 1:
            return "number";
        case 5:
            return "string";
        case 1.2:
            return "percent";
        case 1.1:
        case 2:
        case 2.1:
            return 'currency';
        case 3:
        case 9:
            return 'number';
        case 4:
        case 4.1:
            return 'date';
        default:
            return 'string';
    }
}

export function convert_to_db_specific_string(data_type) {
    switch (data_type) {
        case none:
        case string:
        case email:
        case mobile:
            return 'string';

        case number:
        case integer:
        case int:
            return 'number';

        case number_with_decimal:
            return 'number';

        case date:
        case date_time:
            return 'date';

        default:
            return 'string';
    }
}
