import React, { useEffect } from 'react';
import { connect } from 'react-redux';

const VoiceSynthesis = (props) => {
  useEffect(() => {
    setTimeout(() => {
      speak(props?.text_voice_command?.text);
    }, 1000)
    console.log("CALLED")
  }, [props.text_voice_command]);

  const speak = (text) => {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(text);
    synth.speak(utterance);
  };

  return <>
    {/* <button onClick={() => {
      // speak(props?.text_voice_command?.text)
    }}>Speak</button> */}

  </>; // Assuming this component doesn't render anything
};

const mapStateToProps = (state) => ({
  text_voice_command: state?.voiceHelperReducer?.text_voice_command,
});

export default connect(mapStateToProps, {})(VoiceSynthesis);
