import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetStore } from '../../actions/common';
import { clear_single_filter, clear_all_filters } from '../../actions/filter';
import { getDataFromReportingServer, getStringHintsFromServer, changeActiveData } from '../../actions/report';
import { clearTable } from '../../actions/table';
import Reports from '../report-wrapper';
import { getParameterByName } from '../../utils';
import { _getDataFromReportingServer, todayDateTemplate, get_valid_id_and_report_type } from '../../utils';
import Footer from '../footer';
import { dispatchHeaderInformation } from '../../actions/common';
import { withRouter } from 'react-router';

import { DashboardThemeStyle } from './dashboard.theme.style'

let dashId = undefined;

class Dashboard extends Component {


    constructor(props) {
        super(props);
        this.themeStyle = new DashboardThemeStyle("report");
        this.state = {
            question: '',
            reportId: undefined,
            sortData: undefined,
            hints: [],
            renderMode: undefined,
            chartType: undefined,
            xDataKeys: undefined,
            yDataKeys: undefined,
            loading: true,
            title: undefined,
            singleCellData: undefined,
            showSingleCell: false,
            dropDownData: {},
            reportType: undefined,
            kpiData: undefined,
            sqlQuery: undefined,
            filters: undefined,
            isSchedule: false,
            columnMeta: undefined,
            chartXDataKeys: undefined,
            chartYDataKeys: undefined,
            scheduleReportId: undefined,
            parentReport: undefined,
            sqlQuery: undefined,
            dbType: undefined,
            showSpecialCell: false,
            specialCellData: undefined,
            trendFilters: undefined,
            localDataFromServer: undefined

        }
    };


    onLeave(nextState) {
        if (nextState.action === 'POP') {
            alert("leave")
            //...
        }
    }

    componentDidMount() {
        const { history, apply_filters } = this.props;
        // console.log("this.props.router", this.props)
        // this.props.router.setRouteLeaveHook(this.props.route, this.onLeave);

        // if (history && history.action && history.action === 'POP') {
        //     const id = getParameterByName('id');
        //     const scheduleReportId = getParameterByName('srId');
        //     if (scheduleReportId) {
        //         const filter_object = {}
        //     }
        // }
    };



    static getDerivedStateFromProps(nextProps, currentState) {
        const questionFromUrl = getParameterByName('question');
        const id = getParameterByName('id');
        const srId = getParameterByName('srId');

        dashId = id;

        return {
            question: questionFromUrl,
            id,
            srId
        }
    };





    /**
     * We DONT write anything in component did update. No Matter WHAT. getting rid of it.
     */

    changeChartType = type => {
        const tempHistory = Object.assign({}, this.props.history);
        const { location } = tempHistory
        const { search } = location;

        if (search && search.length > 0 && !this.state.showSingleCell) {
            let string = search;

            if (search.match(/&chartType/g)) {
                const chartTypeRegexString = `(&chartType=)(\\w+)(?=&)`;
                const renderModeRegexString = `(&renderMode=)(\\w+)`;

                const chartTypeRegex = new RegExp(chartTypeRegexString);
                const renderModeRegex = new RegExp(renderModeRegexString);

                string = string.replace(chartTypeRegex, `&chartType=${type}`);
                string = string.replace(renderModeRegex, `&renderMode=${type === 'table' ? 'table' : 'chart'}`);
            }

            else {
                string = string + `&chartType=${type}&renderMode=${type === 'table' ? 'table' : 'chart'}`;
            }

            this.props.history.push(string);
        };
    };

    apply_filter_on_all_report_items(filter) {

    };

    /* 
        * This function is responsible for adding the recentColor in the State
    */

    render() {

        const {
            parentReport,
            question,
            id,
            srId
        } = this.state;

        const { width, height, filters, toggleHeader } = this.props;

        //console.log('brilliant amigo 1.1: ', new Date(), );
        /***
         *new regin code for id
         */

        const reportId = getParameterByName('report_id', this.props.history.location.search, "id form dashboard");
        const scheduleReportId = getParameterByName('schedule_report_id', this.props.history.location.search, "from dashboard sh id")
        const final_id_and_type = get_valid_id_and_report_type(reportId, scheduleReportId)


        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                <div style={{ minHeight: 'calc(100vh - 110px)', maxHeight: 'calc(100vh - 110px)' }}>
                    <Reports
                        user={this.props.user}
                        loading={false}
                        history={this.props.history}
                        hints={this.props.hints}
                        location={this.props.location}
                        changeChartType={this.changeChartType}
                        getDataFromReportingServer={_getDataFromReportingServer}
                        // filters={this.props.filters}
                        width={width - (!this.props.toggleHeader ? 30 : 0)}
                        height={height - 60}
                        parentReport={parentReport}
                        question={question}
                        header={this.state.csvHeader}
                        hide_legend={true}
                        dispatchHeaderInformation={this.props.dispatchHeaderInformation}
                        report_type={final_id_and_type.type}
                        reportId={reportId}
                        scheduleReportId={scheduleReportId}
                        id={final_id_and_type.id}
                        show_percentage_total={false}
                        hide_y_axis_label={true}
                        hide_x_axis_label={true}
                        isHighlightedMin={false}
                    />

                </div>

                <Footer />
            </div>
        );
    }
}



const mapStateToProps = state => {
    const tempData = state.reportReducer.dataFromServer;
    let dimensions = undefined;
    const toggleHeader = state.helperReducer.toggleHeader
    if (tempData && tempData[dashId] && tempData[dashId].data) {
        const data = tempData[dashId].data;
        if (data.trend_filters && data.trend_filters.dimensions) {
            dimensions = data.trend_filters.dimensions
        }

    }
    // const { filters_cache } = state.filterReducer;
    return { dimensions, cachedServerData: tempData, toggleHeader }
}


export default withRouter(connect(mapStateToProps, { dispatchHeaderInformation, getDataFromReportingServer, clear_single_filter, clear_all_filters, resetStore, getStringHintsFromServer, changeActiveData, clearTable })(Dashboard));