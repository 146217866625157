import React from 'react';
import styled from 'styled-components';
import RighterMenu from '../righter-menu';
import MenuConfigRighter from '../menu-config-righter';
import { connect } from 'react-redux';
import { themeObject } from '../../theme'
import { get_pivot_formatted_axis } from '../../utils';

const theme = themeObject;

export const RightSection = styled.div`
     display: flex;
     align-items: flex-start;
     /* height: ${props => props.heightOfChart ? props.heightOfChart + "px" : '90vh'}; */
     height: ${props => props?.isDashboard ? `calc(100%)` : `calc(100% - 40px)`};
     flex-direction: row;
     position: absolute;
     right: ${props => props.isDeepDrive ? '0px' : '15px'};
     z-index: 3;
`;


const RightMenuSection = (props) => {

    const {

        changeChartType,
        chartType,
        renderMode,
        isTrendFilters,
        numberOfData,
        showSingleCell,
        user,
        id,
        sortedData,
        heightOfChart,
        filters,
        getDataFromReportingServer,
        question,
        scheduleReportId,
        dateFilter,
        csvHeader,
        reportTitle,
        mainReportId,
        columnsAligments, grandTotalColumns,
        report_item_id,
        report_menu_cache,
        pivot_data_axis,
        pivot_axis,
        raw_axis,
        isDeepDrive,
        isPolusAIWindow,
        report_type
    } = props;


    const righterMenuStyle = theme.report.right_menu;
    const menu_cache = report_menu_cache && report_menu_cache[id]
    const menu_render_mode = menu_cache?.menu_render_mode;


    const config = {
        pivot_option: true,
        chart_option: true,
        filter_option: true,
        download_option: true,
        formula: true,
        formation: true
    }

    if (props?.is_correlation) {
        config.pivot_option = false;
        config.chart_option = false
    }

    return (
        <RightSection isDashboard={report_type === 'dashboard'} heightOfChart={heightOfChart} isDeepDrive={isDeepDrive}>
            {menu_render_mode && (
                <MenuConfigRighter
                    {...props}
                    is_global_filters={props.is_global_filters}
                    report_item={props.report_item || undefined}
                    showFull={menu_render_mode}
                    changeChartType={changeChartType}
                    chartType={chartType}
                    renderMode={renderMode}
                    trendFilters={isTrendFilters}
                    numberOfData={numberOfData}
                    showSingleCell={showSingleCell}
                    header={csvHeader}
                    // commenting so I can send calculated data in csv to print
                    // data={dataFromServer ? dataFromServer.process_data : []}
                    data={sortedData ? sortedData : []}
                    reportTitle={reportTitle}
                    user={user}
                    id={id}
                    heightOfChart={heightOfChart}
                    filters={filters}
                    getDataFromReportingServer={getDataFromReportingServer}
                    question={question}
                    scheduleReportId={scheduleReportId}
                    dateFilter={dateFilter}
                    history={props.history}
                    mainReportId={mainReportId}
                    columnsAligments={columnsAligments}
                    xDataKeys={props.xDataKeys}
                    grandTotalColumns={grandTotalColumns}
                    yDataKeys={props.yDataKeys}
                    filterFromURL={props.filterFromURL}
                    righterMenuStyle={righterMenuStyle}
                    localRunningTotal={props.localRunningTotal}
                    setLocalRunningTotal={props.setLocalRunningTotal}
                    change_report_setting_on_apply_pivot={props.change_report_setting_on_apply_pivot}
                    report_item_id={report_item_id}
                    insights={props.insights}
                    restricted_view={props.restricted_view}
                    pivot_data_axis={pivot_data_axis}
                    pivot_xac={pivot_axis?.xac}
                    pivot_yac={pivot_axis?.yac}
                    raw_axis={raw_axis}

                />
            )}

            <RighterMenu
                {...props}
                is_global_filters={props.is_global_filters}
                scheduleReportId={scheduleReportId}
                changeChartType={changeChartType}
                chartType={chartType}
                renderMode={renderMode}
                trendFilters={isTrendFilters}
                numberOfData={numberOfData}
                showSingleCell={showSingleCell}
                user={user}
                id={id}
                heightOfChart={heightOfChart}
                filters={filters}
                getDataFromReportingServer={getDataFromReportingServer}
                question={question}
                mainReportId={mainReportId}
                columnsAligments={columnsAligments}
                xDataKeys={props.xDataKeys}
                yDataKeys={props.yDataKeys}
                grandTotalColumns={grandTotalColumns}
                filterFromURL={props.filterFromURL}
                righterMenuStyle={righterMenuStyle}
                report_item_id={report_item_id}
                change_report_setting_on_apply_pivot={props.change_report_setting_on_apply_pivot}
                pivot_data_axis={pivot_data_axis}
                pivot_xac={pivot_axis?.xac}
                pivot_yac={pivot_axis?.yac}
                pivot_data={props.pivot_data}
                raw_data={props.raw_data}
                raw_axis={raw_axis}
                config={config}

            />
        </RightSection>
    )
}








































const mapStateToProps = (state, props) => {
    const report_menu_cache = state.menuConfigRighterReducer.report_menu_cache;
    return { report_menu_cache }
}

export default connect(mapStateToProps, {})(RightMenuSection);