import React from "react";

const DrillDown = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1665 2048"
    >
      <path transform="translate(856,3)" d="m0 0h11l1 3v756l10-10 8-10 16-17 11-12 16-17 9-9 7-8 16-17 9-10 11-12 7-8 16-16 4 2 5 4 7 8 10 9 11 9 2 5-18 20-7 8-8 8-9 11-12 12-7 8-14 15-7 8-10 10-7 8-9 9-3 4 21 6 29 9 22 8 26 11 28 14 22 13 24 16 14 10 16 13 14 12 9 9 8 7 8 8 7 8 7 7 9 11 11 13 13 18 15 23 12 21 8 15 12 26 12 31 10 33 8 36 5 35 2 18 1 43-1 26-6 45-5 26-8 30-10 30-10 24-14 30-13 23-15 23-12 17-11 14-11 13-9 10-7 8-16 16-8 7-14 12-16 13-23 16-14 9-26 15-26 13-32 13-34 11-28 7-32 6-36 4-23 2-9 2-10-1-7-1-6-1-24-1-40-5-40-9-35-11-29-11-26-12-19-10-21-13-21-14-13-10-10-8-11-9-13-12-31-31-7-8-12-14-12-16-12-17-12-19-12-22-12-24-10-24-9-25-9-32-8-39-4-30-1-10v-74l5-37 5-26 9-36 11-32 13-31 11-22 14-24 11-17 12-17 12-16 11-13 11-12 12-13 13-13 8-7 10-9 14-11 19-14 21-14 24-14 34-17 27-11 28-9 21-6 16-4-7-8-12-13-7-8-15-16-13-14-7-8-15-16-7-8-12-12-7-8-9-9 1-5 12-12 8-7 5-4 7-8 3-2 5 1 16 17 9 9 7 8 11 11 7 8 15 16 9 9 7 8 11 12 18 18 9 11 11 12 2 2-1-96 1-654 1-4 3-1z" fill="#06335C" />
      <path transform="translate(1347,558)" d="m0 0 6 3 10 9 10 8 10 9 8 7 10 9 12 11 25 25 7 8 11 12 11 13 12 14 13 17 15 20 14 20 15 23 15 25 14 25 15 29 16 35 14 35 12 34 10 34 8 32 9 42 7 41 4 36 2 24 1 30v50l-1 29-3 33-6 45-11 57-10 40-11 36-12 34-16 39-12 26-8 16-14 27-12 20-14 23-16 24-12 17-13 17-10 13-9 11-9 10-9 11-12 13-51 51-11 9-11 10-10 8-11 9-20 15-18 13-27 18-18 11-17 10-24 13-19 10-29 14-36 15-27 10-36 12-36 10-51 11-43 7-39 5-22 2-19 1h-70l-39-3-58-7-45-8-42-10-35-10-47-16-27-11-40-18-24-12-23-13-21-12-25-16-19-13-18-13-12-9-14-11-13-11-14-12-16-15-53-53-7-8-9-10-9-11-12-15-15-20-11-15-20-30-17-28-14-25-17-33-14-31-12-30-12-34-10-31-10-38-7-33-8-46-5-39-3-33-1-23v-55l1-24 5-43 6-42 6-33 10-42 7-26 14-43 14-37 12-27 17-36 12-22 12-21 15-24 9-14 16-23 11-15 14-18 11-14 10-11 7-8 12-14 14-15 7-7 5 1 81 81 3 4-1 5-5 5-15 16-11 12-9 11-11 13-12 16-11 15-16 24-13 21-14 24-17 33-13 29-13 33-12 35-10 36-7 30-8 45-4 37-2 28v78l4 42 5 35 8 40 10 38 9 29 12 33 13 31 12 25 12 24 14 24 11 18 10 15 14 20 9 12 11 14 14 17 9 10 7 8 15 16 8 9 8 7 16 15 8 7 13 11 14 11 15 12 18 13 26 17 18 11 25 14 22 12 31 14 32 13 34 12 35 10 38 9 47 8 34 4 43 3h47l49-4 42-6 36-7 29-7 28-8 41-14 39-16 36-17 22-12 25-15 19-12 19-13 19-14 20-16 11-9 12-11 13-12 16-15 32-34 9-11 7-8 11-14 14-19 12-17 8-13 15-24 16-29 16-33 13-31 9-24 11-33 10-37 8-37 5-28 4-33 3-41v-68l-3-38-6-42-5-28-7-31-12-42-10-30-12-30-16-35-12-24-13-23-14-23-18-27-14-19-10-13-9-11-12-14-12-13-14-15-27-27-8-7-10-9-22-18 1-5 8-9 10-13 12-15 10-13 12-15 15-20z" fill="#06335C" />
      <path transform="translate(856,3)" d="m0 0h11l1 3v756l10-10 8-10 16-17 11-12 16-17 9-9 7-8 16-17 9-10 11-12 7-8 16-16 4 2 5 4 7 8 10 9 11 9 2 5-18 20-7 8-8 8-9 11-12 12-7 8-14 15-7 8-10 10-7 8-9 9-3 4 21 6 29 9 22 8 26 11 28 14 22 13 24 16 14 10 16 13 14 12 9 9 8 7 8 8 7 8 7 7 9 11 11 13 13 18 15 23 12 21 8 15 12 26 12 31 10 33 8 36 5 35 2 18 1 43-1 26-6 45-5 26-8 30-10 30-10 24-14 30-13 23-15 23-12 17-11 14-11 13-9 10-7 8-16 16-8 7-14 12-16 13-23 16-14 9-26 15-26 13-32 13-34 11-28 7-32 6-36 4-23 2-9 2-10-1-7-1-6-1-24-1-40-5-40-9-35-11-29-11-26-12-19-10-21-13-21-14-13-10-10-8-11-9-13-12-31-31-7-8-12-14-12-16-12-17-12-19-12-22-12-24-10-24-9-25-9-32-8-39-4-30-1-10v-74l5-37 5-26 9-36 11-32 13-31 11-22 14-24 11-17 12-17 12-16 11-13 11-12 12-13 13-13 8-7 10-9 14-11 19-14 21-14 24-14 34-17 27-11 28-9 21-6 16-4-7-8-12-13-7-8-15-16-13-14-7-8-15-16-7-8-12-12-7-8-9-9 1-5 12-12 8-7 5-4 7-8 3-2 5 1 16 17 9 9 7 8 11 11 7 8 15 16 9 9 7 8 11 12 18 18 9 11 11 12 2 2-1-96 1-654 1-4 3-1zm263 939-8 7-6 5-7 8-9 9-7 8-121 121-7 8-10 10-7 8-92 92-6 5-4 5v2h-5l-12-11-37-37-6-5-5-5-12-13-10-10-7-8-22-24-98-98h-2l-2-4-8-7-5-6-8-7-14-14-5-6-5-5-12-11-16-16h-5l-19 19-9 11-12 16-13 19-12 19-15 29-11 24-7 18-9 31-6 26-5 30-4 46 1 22 3 36 6 33 7 28 10 30 11 26 9 19 14 24 8 13 11 16 10 13 24 28 22 22 8 7 11 9 14 11 17 12 14 9 17 10 25 13 21 9 24 9 29 8 36 7 21 3 29 2 16 1 44-4 25-4 31-7 28-8 27-10 24-11 25-13 22-14 18-13 13-10 14-12 13-12 17-17 7-8 9-10 10-13 11-16 9-13 13-22 14-27 10-25 11-33 7-28 6-35 2-18v-67l-6-42-5-23-9-32-10-27-9-20-13-26-7-11-14-21-13-18-13-16-12-13-9-9z" fill="#D6DADE" />
      <path transform="translate(856,3)" d="m0 0h11l1 3v756l10-10 8-10 16-17 11-12 16-17 9-9 7-8 16-17 9-10 11-12 7-8 16-16 4 2 5 4 7 8 10 9 11 9 2 5-18 20-7 8-8 8-9 11-12 12-7 8-14 15-7 8-10 10-7 8-9 9-3 5-4 5-5 4-7 8-11 12-13 13-7 8-12 13-10 10-7 8-9 10-8 8-5 6-3-1-4-5-9-7-7-7-7-8-12-13-14-14-7-8-9-10-7-8-10-10-7-8-6-4-2-5-8-8-7-8-11-12-12-13-14-15-7-8-15-16-7-8-12-12-7-8-9-9 1-5 12-12 8-7 5-4 7-8 3-2 5 1 16 17 9 9 7 8 11 11 7 8 15 16 9 9 7 8 11 12 18 18 9 11 11 12 2 2-1-96 1-654 1-4 3-1z" fill="#06335C" />
      <path transform="translate(834,1224)" d="m0 0h3l-1 4-3 3-4-1-3-5z" fill="#F4F5F6" />
    </svg>
  );
};

export default DrillDown;
