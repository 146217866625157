import React, { useState } from 'react';
import styled from 'styled-components';
import SelectOptionV1 from '../select-option/select.option.v1.1';


const Wrapper = styled.div`
    
    display: flex;
    align-items: 'center';
    border: 0px;;
    select,
    input {
        border: 0px;
        height: 2rem;
        background-color: #fff;
        border: 1px solid #a5a0fffa;
        border-radius: 4px;
        padding: '0 0.5rem';
        &:focus-visible{
            outline: 1px solid #7972fffa;
        }

    }
    select{
        width: 100px;
        font-size: .9rem;
        color: #3a3a3a;
    }
    input{
        min-width: 250px;
        padding: 0px 0.5rem;
        box-sizing: border-box;
        font-size: .8rem;
        &::placeholder{
            color: #222;
        }
    }
    li{
        padding: 0px !important;
        overflow: none !important;
        position: relative;
    
    }
    .dropdown{
        position: absolute;
        background: #fff;
        top: 103%;
        z-index: 111;
        width: 100%;
        box-sizing: border-box;

        div{
            padding: 5px;
            cursor: pointer;

            :hover{
                background-color: #e2e2e2;;
            }
        }
    }
    
`;

const ElementCreator = (props) => {


    const {

        enable_text_editor,
        onDragOver,
        onDropColumn,
        action_on_change,
        enable_selection,
        __get_ifs_type_by_value_expr_,
        selection__,
        area_key,
        type,
        else_if_index,
        enums,
        position_in_array,
        parent_id,
        enable_nested_if,
        placeholder = "Enter Value or Column Name",
        width = '230px'


    } = props;


    const [show_hint, set_show_hint] = useState(undefined)



    return (
        <Wrapper
            style={{
                display: 'flex',
                alignItems: 'center'
            }}
            onClick={(e) => {
                e.stopPropagation();
                // set_show_hint(false)
            }}
        >
            <li style={{ listStyle: 'none', border: '0px', minWidth: '220px' }}>

                {enable_text_editor && (
                    <input
                        style={{
                            width: width ? width : undefined
                        }}
                        onDragOver={onDragOver}
                        onDrop={(e) => {
                            if (onDropColumn) {
                                onDropColumn(e, area_key, position_in_array);
                            }
                        }}
                        autoFocus={true}
                        onClick={(e) => {
                            // e.stopPropagation()
                            set_show_hint(true);
                        }}
                        type="text"
                        placeholder={placeholder}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                set_show_hint(true)
                            } else {
                                set_show_hint(false)
                            }
                        }}
                        onKeyPress={(event) => {
                            const value = event.target.value
                            const eventKey = event.key;

                            if (eventKey === "Enter") {
                                // let's call on change functions
                                action_on_change(parent_id, type, enums.IFS.TEXT, value, else_if_index, position_in_array)
                            }
                        }}
                    />
                )}

                {show_hint && enable_nested_if && (
                    <div className='dropdown'>
                        <div onClick={() => {

                            const value = "Nested If"
                            const event_type = "wrapper";
                            action_on_change(parent_id, type, event_type, value, else_if_index, position_in_array)

                        }}>Nested If</div>
                    </div>
                )}

                {/* {enable_nested_if && (
                    <select onChange={(event) => {

                        const value = event.target.value;
                        const event_type = "wrapper";

                        action_on_change(parent_id, type, event_type, value, else_if_index, position_in_array)

                    }}>
                        <option value={""}>Select</option>
                        <option value={"Nested If"}>Nested If</option>
                    </select>
                )} */}

                {enable_selection && (
                    <SelectOptionV1
                        background={'#fff'}
                        label={"CHOOSE OPERATOR"}
                        options={selection__}
                        width="100%"
                        hide_margin={true}
                        height="2rem"
                        on_change={(value) => {
                            const event_type = __get_ifs_type_by_value_expr_(value);
                            action_on_change(parent_id, type, event_type, value, else_if_index, position_in_array)
                        }}
                        // background={'#f0f5fc'}
                        none_value={false}
                        hide_post_label={true}
                        label_as_placeholder={true}
                        lablePreStyle={{
                            fontSize: '0.8rem',
                            color: '#222',
                        }}
                        inputStyle={{border: '1px solid #9b95fbfa'}}
                    />
                    // <select onChange={(event) => {
                    //     const value = event.target.value;
                    //     const event_type = __get_ifs_type_by_value_expr_(value);
                    //     // console.log("else_if_index", else_if_index, type, value,)
                    //     action_on_change(parent_id, type, event_type, value, else_if_index, position_in_array)

                    // }}>
                    //     <option value={''}>Choose Operator</option>
                    //     {selection__.map((opr) => {
                    //         return <option value={opr}>{opr}</option>
                    //     })}

                    // </select>
                )}

            </li>
        </Wrapper>

    )
}


export default ElementCreator;
