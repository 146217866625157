import React from 'react';

const AngleDown = (props) => {
    return (
        <svg
            style={{ transform: 'rotate(270deg)' }}
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 447 248.2"
            space="preserve">
            <path d="M439.9,7.2c-9.6-9.6-25.2-9.6-34.8,0L223.5,188.9L41.8,7.2C32.2-2.4,16.6-2.4,7,7.2S-2.6,32.4,7,42l199,199
            	c4.8,4.8,11.1,7.2,17.4,7.2l0,0l0,0c6.3,0,12.6-2.4,17.4-7.2l199-199C449.5,32.4,449.5,16.8,439.9,7.2z"/>
        </svg>
    )

}


export default AngleDown;