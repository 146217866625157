import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import CloseSvg from "../../svg/close";
import SearchSuggestion from "./../../data-policy-v1/components/search.suggestion";
import CombinationTable from "./CombinationTable";
import Search from "../../svg/search";

const MainWrapper = styled.div`
  width: 36rem;
  height: 31rem;
  background-color: #fff;
  border-radius: 20px;
  box-sizing: border-box;
  position: relative;
  .header{
    width: 100%;
    padding: 1rem 1rem .5rem 1rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
    border-radius: 20px 20px 0 0;
    background: linear-gradient(to right, #3E9EFF, #737DFF);
    color: #fff;
  }
  .body_container {
    padding: 0rem 2rem 2rem;
  }
  h1 {
    margin: 0px;
    text-transform: capitalize;
    font-size: 1.4rem;
    text-align: center;
    margin: auto;
  }
  .search_container {
    margin-bottom: 1rem;
    input {
      background: #f9f9f9;
      border: 1px solid #ccc;
      &:hover {
        border: 1px solid #afc4e3;
      }
    }
  }
  .label{
    padding-bottom: 0.3rem;
    font-size: 1rem;
    margin-left: 0.2rem;
    color: #333333;
  }

  .close_btn {
    width: 1.8rem;
    height: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
        fill: #fff;
      }

    &:hover {
      svg {
        fill: #fff;
      }
    }
  }

  .flex_row {
    display: flex;
    margin-bottom: 0.8rem;
    width: 100%;
    justify-content: space-between;
  }
`;

const CatelogPopup = (props) => {
    const {
        close,
        popupKey,
        option,
        hintsWithTableColumnsName,
    } = props;
    const [hint_values, set_hint_values] = useState(undefined);
    const [hint_value_detail, set_hint_value_details] = useState(undefined);
    const [table_column_data, set_table_column_data] = useState(undefined);

    useEffect(() => {
        const tempObject = {};
        const tempTagSet = new Set();
        hintsWithTableColumnsName?.forEach((d) => {
            const { table_name: tableName, column_name: columnName, id, values, context } = d;
            if (values && values.length > 0) {
                values.forEach((v) => {
                    const trimmedValue =
                        typeof v === "string" ? v.trim().toLowerCase() : v.trim();
                    tempTagSet.add(v);

                    if (!tempObject[trimmedValue]) {
                        tempObject[trimmedValue] = [];
                    }
                    tempObject[trimmedValue].push({ columnName, tableName, id, values, context });
                });
            }
        });
        set_hint_value_details(tempObject);
        set_hint_values(Array.from(tempTagSet));
    }, [hintsWithTableColumnsName]);

    const hintSuggestionClick = (value) => {
        let tempArray = [];
        const _value = Array.isArray(value) ? value.join(" ").trim() : value;
        const valueToCheck =
            typeof _value === "string" ? _value.trim().toLowerCase() : _value.trim();
        if (valueToCheck) {
            if (hint_value_detail?.[valueToCheck]) {
                tempArray = [...hint_value_detail[valueToCheck]];
            }
            set_table_column_data(tempArray);

            const t = tempArray?.map((d) => {
                return {
                    ...d,
                    table_name: d?.tableName,
                    column_name: d?.columnName,
                    values: [],
                    id: undefined,
                };
            });
        }
    };

    const distinct_of_table_name = useMemo(
        () =>
            table_column_data?.filter(
                (obj1, i, arr) =>
                    arr?.findIndex((obj2) => obj2?.tableName === obj1?.tableName) === i
            ),
        [table_column_data]
    );

    return (
        <MainWrapper>
            <div className="header">
                <div
                    className="flex_row"
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <h1>Search Your Catalog</h1>
                    <div className="close_btn" onClick={() => close(option, popupKey)}>
                        <CloseSvg size="1rem" height="1rem" />
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                    }}
                >
                </div>
            </div>
            <div className="body_container">
                <div className="search_container">
                    <div className="label">Search Your Attribute</div>
                    <SearchSuggestion
                        onChange={()=> table_column_data?.length && set_table_column_data([])}
                        items={hint_values || []}
                        suggestionOnClick={hintSuggestionClick}
                        placeholder="Enter Attribute"
                        borderRadius={'6px'}
                        padding={'11px 10px 14px 2.5rem' }
                        suffix={<Search size="0.9rem" height="0.9rem" color='#ccc' />}
                        suggestionListStyle={{ marginTop: '-1rem', padding: '1rem 0.5rem 0.5rem 0.5rem', border: 'none', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderRadius: '0 0 10px 10px' , boxSizing: 'border-box' }}
                        searchIconStyle={{position: 'absolute', background: 'transparent', borderRadius: '0 50px 50px 0', height: '100%', width: '3rem', display: 'flex', alignItems: 'center', justifyContent: 'center', top: '0', left: 0}}
                    />
                </div>
                <CombinationTable
                    table_column_data={distinct_of_table_name}
                />
            </div>
        </MainWrapper>
    );
};

const mapStateToProps = (state) => {
    const { hintsWithTableColumnsName } = state.hintsReducer;

    return {
        hintsWithTableColumnsName
    };
};

export default connect(mapStateToProps, {})(CatelogPopup);
