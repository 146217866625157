import React, { useState, useEffect } from 'react';
import { AddNewTag } from './hints-edit.style';
import { Button } from '../../hoc/button'
import { Gap } from '../../gap';
import EditSvg from '../../svg/edit';
import styled from 'styled-components';
import TextArea from '../../hoc/textArea';


const ContextEditorWrapper = styled.div`

        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        box-sizing: border-box;
        padding: 15px 27px;
        background: white;
        min-width: 30.857rem;
        max-width: 30.857rem;
        min-height: 16.143rem;
        max-height: 18.143rem;
        border-radius: 5px;
        box-shadow: 0 3px 6px 0 rgba(0,0,0,0.25);
        /* border: solid 5px rgb(234,234,234); */
        background-color: rgb(255,255,255);
        overflow-x: auto;
        .form {
            border: none;
            background: #F3F3F3;
            border-radius: 6px;
            margin: 0;
            padding: 0.3rem;
        }
        
        &::-webkit-scrollbar {
            background-color:red;
            width:5px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }

`;



const AddContext = props => {

    const { close, option, popupKey, data } = props;
    const { hintData, func, tableName, label } = data;
    const { _saveFunction } = func
    const [hint, setHint] = useState(undefined);
    const [editIndex, setEditIndex] = useState(undefined);
    const [context, setContext] = useState(undefined);

    useEffect(() => {
        if (hintData && hintData.context) {
            setContext(hintData.context)
            setEditIndex(true)
        }
    }, [hintData])


    const save = () => {
        let temp_obj = hintData ? Object.assign(hintData, {}) : {};
        temp_obj.context = context;
        temp_obj.modified = true;
        if(tableName){
            temp_obj.table_name = tableName
        }
        _saveFunction(temp_obj)
        close(option, popupKey)
    }

    return (
        <ContextEditorWrapper>
            {editIndex ?
                <AddNewTag
                >
                    <span style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <EditSvg size="1rem" height="1rem" />
                    </span>
                    <span className="add_title">Edit Context</span>
                </AddNewTag>

                :

                <AddNewTag
                size={'30px'}
                >
                    <span className="add" style={{ marginTop: '-4px' }}>+</span>
                    <span className="add_title">Add Context</span>
                </AddNewTag>
            }

            <TextArea
                type="text"
                label={label ? label : "Type Your Context"}
                label_as_placeholder={true}
                autoFocus
                rows={"5"}
                value={context}
                onChange={(e) => {
                    setContext(e.target.value)
                }}
            />
            <Gap h="25px" />
            <div style={{
                // paddingTop: '25px',
                display: 'block',
                width: '100%',
                textAlign: 'right'
            }}>
                <Button
                    primary={true}
                    bgColor={'linear-gradient(to right, #3E9EFF, #737DFF)'}
                    hoverBg={'linear-gradient(to right, #3e9effcf, #737dffe0)'}
                    width='6rem'
                    mR="16px"
                    onClick={save}
                >
                    Save
                </Button>
                <Button
                    width='6rem'
                    bgColor={'#e6e6e6'}
                    style={{
                        marginBottom: 0
                    }}
                    onClick={() => {
                        close(option, popupKey)
                    }}
                >Cancel</Button>
            </div>
        </ContextEditorWrapper>
    );
};

export default AddContext

