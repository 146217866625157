import { colors } from './colors';
import { TABLE_CONFIG } from '../shared-with-fe/keys';
import { SORT_VALUES } from './config';
import * as dataTypes from '../shared-with-fe/data.types';
import { getDataType } from './index';
import { chartDisableOptions } from './chart.config'
import { checkDateAndSetDateTimeTypeToData, checkDataTypesForNumberAndDecimal } from './index';
import { CHART_TYPES } from '../shared-with-fe/constants';
/** for sorting collection when it comes the first time 
 *  We need to sort the data, so it should sort all the columns in a straight line
 * 
 * We will create a key which will concatenate all the xacs, and then sort the data on that xacs;
 * 
*/


let temp_aligment = {
    [dataTypes.none]: {
        alignment: 'center',
        type: dataTypes.none,
    },
    [dataTypes.number]: {
        alignment: "right",
        type: dataTypes.number
    },
    [dataTypes.number_with_decimal]: {
        alignment: "right",
        type: dataTypes.number_with_decimal
    },
    [dataTypes.percent]: {
        alignment: "right",
        type: dataTypes.percent
    },
    [dataTypes.mobile]: {
        alignment: "left",
        type: dataTypes.mobile
    },
    [dataTypes.currency]: {
        alignment: "right",
        type: dataTypes.currency
    },
    [dataTypes.epoch]: {
        alignment: "left",
        type: dataTypes.epoch
    },
    [dataTypes.date]: {
        alignment: "left",
        type: dataTypes.date
    },
    [dataTypes.date_time]: {
        alignment: "left",
        type: dataTypes.date_time
    },
    [dataTypes.string]: {
        alignment: "left",
        type: dataTypes.string
    },
    [dataTypes.email]: {
        alignment: "center",
        // type: "/email"
    }
}
export const sortInitialData = (dataToSort, xac) => {
    if (!(dataToSort && Array.isArray(dataToSort) && dataToSort.length > 0)) {
        return [];
    }

    // let's inject keys to data

    const list_clonned = dataToSort.map(row => {
        let key = undefined;

        xac && xac.length > 0 && xac.forEach(x => {

            const dataType = x.toLowerCase() === 'week' ? dataTypes.string : getDataType(row[x]);

            if (dataType === dataTypes.string) {
                const new_x = (SORT_VALUES && row[x] && SORT_VALUES[row[x].toUpperCase()]) ? SORT_VALUES[row[x].toUpperCase()] : row[x];

                key = key ? key + '_nfx_' + new_x : new_x;
            }


            else if (dataType === dataTypes.number || dataType === dataTypes.currency) {
                const new_x = parseFloat(row[x]);
                key = key ? key + '_nfx_' + new_x : new_x;
            }

            else {
                key = key ? key + '_nfx_' + row[x] : row[x];
            }

        });


        return {
            ...row,
            sortKey: key
        };
    });



    list_clonned.sort((a, b) => a.sortKey > b.sortKey ? 1 : -1);

    return list_clonned;
};


export const getChartDataWithType = (data, xDataKeys = [], yDataKeys = [], columnMeta, comparisonKeys, comparisons, mainFormulas) => {

    const tempColumns = [...xDataKeys, ...yDataKeys];
    let columnsAligments = {};

    tempColumns && tempColumns.length > 0 && tempColumns.forEach((_data_key) => {
        let data_key = _data_key;

        // change in the xac formation for saving it in database
        if (typeof data_key === 'object') data_key = data_key.column_name;

        if (columnMeta && Object.keys(columnMeta).length > 0) {
            Object.keys(columnMeta).forEach(metaKey => {
                let display_value = (columnMeta[metaKey] && columnMeta[metaKey].display_value) ? columnMeta[metaKey].display_value : undefined;
                let typeOfData = (columnMeta[metaKey] && columnMeta[metaKey].data_type) ? columnMeta[metaKey].data_type : undefined;

                if ((comparisonKeys && comparisonKeys.length > 0)) {
                    let local_temp_algment = {};
                    comparisonKeys.map((compare_key, i) => {
                        local_temp_algment = temp_aligment[dataTypes.number];
                        if (!columnsAligments[compare_key]) {
                            columnsAligments[compare_key] = local_temp_algment;
                        }
                    })
                }

                // console.log('display ====> ', display_value);

                if ((data_key && data_key.toLowerCase()) == (display_value && display_value.toLowerCase())) {
                    let local_temp_algment = {};
                    local_temp_algment = typeOfData === 4 ? temp_aligment[checkDateAndSetDateTimeTypeToData(data, display_value)] : temp_aligment[typeOfData];
                    if (!columnsAligments[display_value]) {
                        columnsAligments[display_value] = local_temp_algment;
                    } else {
                        columnsAligments[display_value] = local_temp_algment;
                    }

                }
            })
        }
    })







    mainFormulas && Object.keys(mainFormulas).length > 0 && Object.keys(mainFormulas).map((key) => {
        let data_type = mainFormulas[key].data_type;
        columnsAligments[key] = temp_aligment[data_type]
    })


    if (columnsAligments && Object.keys(columnsAligments).length > 0) {
        Object.keys(columnsAligments).forEach(key => {
            if (columnsAligments[key] && columnsAligments[key].type === dataTypes.number) {
                let _d_type = checkDataTypesForNumberAndDecimal(data, key)
                columnsAligments[key] = temp_aligment[_d_type]
            }
        })
    }

    return columnsAligments;

}




/* 
    * getGroupByData()
        * Not Using
*/

export const getGroupByData = (data, groupBy, groupWhat) => {
    const flatData = data;
    const barChartBlock = {};
    const separator = '$%$';

    flatData && flatData.length > 0 && flatData.forEach(data => {
        let checkDataGroup = undefined;

        groupBy.forEach(key => {
            if (checkDataGroup) {
                checkDataGroup = checkDataGroup + separator + data[key];
            }
            else {
                checkDataGroup = data[key];
            }
        });

        if (typeof barChartBlock[checkDataGroup] === 'undefined') {
            barChartBlock[checkDataGroup] = {};
        }

        groupWhat.forEach(yColumn => {
            if (typeof barChartBlock[checkDataGroup][yColumn] === 'undefined') {
                barChartBlock[checkDataGroup][yColumn] = data[yColumn] ? parseInt(data[yColumn]) : undefined;
            }
            else {
                barChartBlock[checkDataGroup][yColumn] = barChartBlock[checkDataGroup][yColumn] + parseInt(data[yColumn]);
            }
        });
    });


    const dataInFormForBarChart = Object.entries(barChartBlock).map(([key, value]) => {
        const chartDataItem = {};
        if (key.indexOf(separator) > -1) {
            const separated_column = key.split(separator);

            groupBy.forEach((columnName, index) => {
                chartDataItem[columnName] = separated_column[index]
            });

            chartDataItem[groupBy.join('-')] = key;
        }
        else {
            chartDataItem[groupBy] = key;
        }

        Object.assign(chartDataItem, value);
        return chartDataItem;
    });


    return dataInFormForBarChart;
};

export const getRandomColor = howManyColors => {
    // const colorArray = [];

    // for(let i = 0; i < howManyColors; i++) {
    //     const value = Math.floor(Math.random() * (blueColors.length - 1));
    //     colorArray.push(blueColors[value]); 
    // }

    // return colorArray;


    return Array.from(colors);
};

export const getPaddingOnXAxis = howManyBars => {
    const widthOfWindow = window && window.innerWidth;
    if (widthOfWindow) {
        if ((widthOfWindow > 1200 && widthOfWindow < 1500) && howManyBars <= 4) {
            return ((parseInt(howManyBars) * 25));
        }
        else if (widthOfWindow > 1500 && widthOfWindow < 1800 && howManyBars <= 4) {
            return ((parseInt(howManyBars) * 50));
        }
        else if (widthOfWindow > 1800 && howManyBars <= 5) {
            return ((parseInt(howManyBars) * 100));
        }
        else {
            return 0;
        }
    }

};

/* 
    * groupTabularData()
        * This function returns the Objects
        * Input data => [{Patient Discharged: 214, Speciality: "Cardiac Surgery", sortKey: "Cardiac Surgery"}]
        * Output Data => {Cardiac Surgery:{ Speciality: "Cardiac Surgery", Patient Discharged: 214, nf-reserved-row-group: false}}
        * In output Data => It creates the keys which are xDataKeys. Like Above xDataKey is Speciality.
        * In Table their is procedure to show the subTotal, This function also adding a special key nf-reserved-row-group which tells the UI It is subtotal row.
*/

export const groupTabularData = (data, xDataKeys, yDataKeys) => {
    const main_object = {};

    // means have found that we need to inject total table
    let inject_total_columns = false;

    yDataKeys.sort();

    data && data.length > 0 && data.forEach(row => {
        let key = undefined;
        const parent_object = {};

        xDataKeys && xDataKeys.length > 0 && xDataKeys.forEach((xColumn, xIndex) => {
            key = key ? (key + '_nfx_' + row[xColumn]) : row[xColumn];
            parent_object[xColumn] = row[xColumn];

            let parent_object_clonned = Object.assign({}, parent_object);

            if (typeof main_object[key] !== 'undefined') {
                parent_object_clonned = main_object[key];
            }

            yDataKeys && yDataKeys.length > 0 && yDataKeys.forEach(yColumn => {
                // let's inject the total column data here
                const splitter = yColumn.split('_nfx_');
                const actual_key = 'total_nfx_' + splitter[1];
                if (typeof parent_object_clonned[actual_key] === 'undefined') {
                    parent_object_clonned[actual_key] = 0;
                }

                // total column injection ends here

                if (typeof parent_object_clonned[yColumn] === 'undefined') {
                    parent_object_clonned[yColumn] = 0;
                }

                if (row[yColumn] === 'XXXX') {
                    parent_object_clonned[actual_key] = 'XXXX';
                    parent_object_clonned[yColumn] = 'XXXX';
                }
                else {
                    parent_object_clonned[actual_key] = parseFloat(parent_object_clonned[actual_key]) + parseFloat(row[yColumn] || 0);
                    parent_object_clonned[yColumn] = parseFloat(parent_object_clonned[yColumn]) + parseFloat(row[yColumn] || 0);
                }
            });

            /* 
                * Assigning SubTotal Row Here
            */
            parent_object_clonned[TABLE_CONFIG.group_row_identifier] = xIndex < (xDataKeys.length - 1);
            main_object[key] = parent_object_clonned;
        });
    });

    return main_object;
};

/* 
    * getCsvHeader()
        * This function returns the data in format which is required by react-csv
*/

export const getCsvHeader = (xDataKeys, yDataKeys, formulaKeys) => {
    let array = [];
    const return_array = [];

    if (xDataKeys && xDataKeys.length > 0) {
        array = array.concat(xDataKeys);
    }
    if (yDataKeys && yDataKeys.length > 0) {
        array = array.concat(yDataKeys);
    }
    if (formulaKeys && formulaKeys.length > 0) {
        array = array.concat(formulaKeys);
    }

    if (array && array.length > 0) {
        array.forEach(data => {
            const _label = data && data.length > 0 && data.split('_nfx_').join(' ');
            const _displayValue = data && data.length > 0 && data.split('_nfx_')[0]
            return_array.push({ key: data, label: _label, displayValue: _displayValue })
        })
    }

    return return_array
};

export const groupSelectedColumnsChartData = (data, xDataKeys, yDataKeys) => {
    const temp = {};

    data && data.length > 0 && data.forEach(row => {
        xDataKeys && xDataKeys.length > 0 && xDataKeys.forEach(xac => {
            const tempLabel = row[xac];
            const label = (tempLabel && tempLabel.length > 0 && typeof tempLabel === 'string') ? tempLabel.toLowerCase() : tempLabel;

            if (typeof temp[label] == 'undefined') {
                temp[label] = {};
            }

            yDataKeys && yDataKeys.length > 0 && yDataKeys.forEach(yac => {
                const value = row[yac];
                if (typeof temp[label][yac] == 'undefined') {
                    temp[label][yac] = {};
                }

                temp[label][yac]['sum'] = value + (temp[label][yac]['sum'] || 0);
                temp[label][yac]['count'] = 1 + (temp[label][yac]['count'] || 0);
            });
        })
    });

    return temp;
};


export const disableChart = (type, numberOfData = 0, singleCell, trendFilter, raw_axis) => {
    const optionsForChart = chartDisableOptions[type];
    const minimumData = optionsForChart.minNumberOfData;
    const maximumData = optionsForChart.maxNumberOfData;
    const yac = optionsForChart?.yac;
    const xac = optionsForChart?.xac;
    const isTrendFilter = optionsForChart.isTrendFilter;

    if (singleCell && type !== 'gauge_chart') {
        return true
    }
    else if ((trendFilter || (trendFilter && trendFilter.length > 0)) && !isTrendFilter) {
        return true;
    }
    else if (maximumData && minimumData && (numberOfData > minimumData) && (numberOfData > maximumData)) {
        return true;
    }
    else if (!maximumData && minimumData && numberOfData < minimumData) {
        return true;
    }else if(type === CHART_TYPES.pareto_chart && raw_axis &&  yac !== raw_axis?.yac?.length){
        return true;
    }else if(type === CHART_TYPES.funnel_chart && raw_axis && ( yac !== raw_axis?.yac?.length  || xac !== raw_axis?.xac?.length) ){
        return true;
    }
};
