import React, { useState } from 'react';
import { DivRow } from '../styled-hoc';
import { Button } from '../hoc/button';
import { Gap } from '../gap/'
import styled from 'styled-components';
import { connect } from 'react-redux';
import { save_favourite, get_favourite_list_by_user_id } from '../../actions/favourite'
import TextBox from '../hoc/textBox';

import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils';


const selected = getSelectedTheme()
const { righterMenuConfig } = themes[selected]
// const { favouritStyle } = righterMenuConfig





export const Wrapper = styled.div`
    position: relative;
    width: 30rem;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 6px 0px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(234, 234, 234);
    border-image: initial;
    padding: 1.643rem 1.429rem;
    overflow-y: auto;
    max-height: 80vh;

    &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }
    .title{
        font-size: 1rem;
        margin-bottom: 5px;
    }
`;






export const TitleWrapper = styled.div`
    font-size: 1.286rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.714rem;
    letter-spacing: normal;
    text-align: center;
    color: rgb(26, 26, 26);
    display: block;
    margin: 0px;
`;


export const CloseWrapper = styled.div`
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 7px;
`;


const AddToFavouriteForm = (props) => {


    const { close, popupKey, option, data} = props;

    const [inputData, setInputData] = useState({
        name: data.name,
        query: data.query
    })

    const onTextChange = (e, key) => {
        let value = e.target.value;
        setInputData({ ...inputData, [key]: value });
    }


    const _save_favorite = () => {

        let _data = {
            id: data.id,
            name: inputData.name,
            query: inputData.query,
            is_active: 1,
            is_modified: true
        }

        props.save_favourite(_data)
        setTimeout(() => {
            props.get_favourite_list_by_user_id()
        }, 100)
    }


    return (
        <Wrapper>
            <TitleWrapper>Edit Favorites</TitleWrapper>
            <Gap h="1.3rem" />
            <div style={{ padding: '10px' }}>
                <DivRow w="100%">
                    <TextBox
                        label="Title"
                        type="text"
                        value={inputData.name}
                        onChange={(e) => onTextChange(e, 'name')}
                    />
                </DivRow>
                <Gap h="20px" />
                <DivRow w="100%">
                    <TextBox
                        label="Question"
                        type="text"
                        value={inputData.query}
                        onChange={(e) => onTextChange(e, 'query')}
                    />
                </DivRow>

                <Gap h="1.5rem" />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        width: '100%'
                    }}
                >
                    <Button
                        primary={true}
                        width="6rem"
                        mR="1.143rem"
                        onClick={() => {
                            _save_favorite()
                            close(option, popupKey);
                        }}
                    >
                        Save
                    </Button>

                    <Button
                        width="6rem"
                        bgColor={'#e6e6e6'}
                        onClick={() => {
                            close(option, popupKey);
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </Wrapper>
    );
};



const mapStateToProps = (state) => ({

})
export default connect(mapStateToProps, { save_favourite, get_favourite_list_by_user_id })(AddToFavouriteForm);