import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TextBox from '../hoc/textBox';
import SelectOption from '../select-option';
import { frequencyTypes } from '../../utils/frequency.types';
import AutoSearch from '../auto.search';
import { dragDisable } from 'd3';

const week_days = [
    { name: 'Monday', value: 'monday' },
    { name: 'Tuesday', value: 'tuesday' },
    { name: 'Wednesday', value: 'wednesday' },
    { name: 'Thursday', value: 'thursday' },
    { name: 'Friday', value: 'friday' },
    { name: 'Saturday', value: 'saturday' },
    { name: 'Sunday', value: 'sunday' }

]



const monthAndDayArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
]




const range = (start, end) => {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
}



const Wrapper = styled.div`
  
    width: 100%;

    .label{
        margin-right: 10px;
        font-size: 1rem;
        color: rgba(0, 0, 0, 0.54);
        text-transform: capitalize;
        margin-bottom: 10px;
        display: block;
    }
    .check_box_main{
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
    }
    .date_time_piker{
        margin-top: 1rem;
    }

    .time_slot{
        margin-top: 1rem;
    }
`;





export const DropDownMenu = styled.div`
        width: 100%;
        max-height: 10rem;
        background: #fff;
        position: absolute;
        top: 100%;
        left: 0px;
        right: 0px;
        box-shadow: 1px 3px 4px #ccc;
        overflow-y: auto;
        z-index: 10;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            background-color:red;
            width:5px
        }
        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }
        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 5px;
        }

        .select_result{
            padding: 3px 11px 5px 10px;
            text-transform: uppercase;
            letter-spacing: .06em;
            font-size: .8rem;
            color: #071c26;
            font-weight: 600;
        }
        .select_result_sub{
            font-size: .9rem;
            font-weight: 400;
            color: #63636b;
            /* width: 100%; */
            text-overflow: ellipsis;
            overflow: hidden;
            padding: ${props => props.isCheckBox ? "0px" : '0px 1rem'};
            white-space: nowrap;
            line-height: ${props => props.menuLineHeight || '2.2rem'};
            cursor: pointer;
            margin-right: 0px;

            &:hover{
                color: #000;
            }
        }

`;


const Index = (props) => {

    const {
        frequency,
        label,
        onChange,
        // type,
        // frequencyObject,
        disabled
    } = props;

    const [frequency_types, setFrequencyTypes] = useState(undefined);
    const [value, setValue] = useState(undefined);
    const [HintArray, setHintArray] = useState(undefined);
    const [showDropdown, setShowDropdown] = useState(undefined);


    const type = frequency.type
    const frequency_value = frequency && frequency['value'];
    const frequency_day = frequency && frequency['day'];
    const frequency_type = frequency && frequency['frequency_type'];
    const frequency_minute = frequency && frequency['minutes'];
    const frequency_hours = frequency && frequency['hours'];
    const frequency_time = frequency && frequency['time'];
    const frequency_month = frequency && frequency['frequency_month'];
    const frequency_month_date = frequency && frequency['month_date'];


    useEffect(() => {
        let clone_fre_types = JSON.parse(JSON.stringify(frequencyTypes));
        if (type === 't' || type === 'l') {
            clone_fre_types.push(
                { name: 'Reactive', value: 'reactive' }
            )
        }
        setFrequencyTypes(clone_fre_types)
    }, [type]);


    useEffect(() => {
        setValue(frequency_value)
    }, [frequency_value])


    // useEffect(() => {
    //     if (frequency_type === 'minutes') {
    //         onChange(value, type, 'minutes', frequency.id)
    //     }
    //     if (frequency_type === 'hours') {
    //         onChange(value, type, 'hours', frequency.id)
    //     }

    // }, [frequency_type, value])


    const OnSearchTextChange = (e) => {
        let value = e.target.value;
        let search_Value = value && value.toLowerCase();
        let clone_months = monthAndDayArray ? JSON.parse(JSON.stringify(monthAndDayArray)) : {};
        let filterd_data = clone_months && clone_months.length > 0 && clone_months.filter((item) => {
            if (value == '') {
                return true
            }
            if (item.toLowerCase().indexOf(search_Value) > -1) {
                return true
            }
            else return false
        })

        if (filterd_data && filterd_data.length > 0 && value && value.length > 0) {
            setShowDropdown(true)
        }
        setHintArray(filterd_data)
    }

    return (
        <Wrapper>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row', marginTop: '.3rem' }}>
                
                {frequency_type !== 'reactive' && (
                    <span style={{ padding: '.4rem 1rem', textTransform: 'uppercase', fontWeight: '500', backgroundColor: '#eaeaeacc', fontSize: '.8rem', borderRadius: '3px', display: 'inline-block', marginRight: '10px', color: disabled ? "#ccc" : undefined }}>Every</span>
                )}

                {frequency_type !== 'reactive' && (
                    <div style={{ width: '30%', position: 'relative', marginRight: '10px' }}>
                        <input
                            disabled={disabled}
                            value={value ? value : ''}
                            style={{ width: '100%', height: '1.9rem', fontSize: '1rem', padding: '.8rem', boxSizing: 'border-box', color: disabled ? '#ccc' : '#000' }}
                            type="text" placeholder="Value"
                            onChange={(e) => {
                                OnSearchTextChange(e)
                                setValue(e.target.value)
                                onChange(e.target.value, type, 'value', frequency.id)

                            }}
                        />
                        {
                            showDropdown &&
                            <DropDownMenu
                                disabled={disabled}
                                isDropdown={showDropdown}
                            >
                                {
                                    HintArray && HintArray.length > 0 && HintArray.map(data => {
                                        return (
                                            <div className='select_result_sub'
                                                onClick={(e) => {
                                                    setValue(data)
                                                    onChange(data, type, 'value', frequency.id)
                                                    setShowDropdown(false)
                                                }}
                                            >
                                                {data}
                                            </div>
                                        )
                                    })
                                }
                            </DropDownMenu>
                        }

                    </div>
                )}


                {/* <AutoSearch
                        inputBorder="1px solid #000"
                        autoOpenListHide={true}
                        dropdownIconHide={true}
                        selections={monthAndDayArray}
                        onItemClick={(val) => {
                            setValue(val)
                        }}
                        setActiveValue={(val) => {
                            setValue(val)
                        }}
                        activeValue={value ? value : ''}
                        width="100%"
                        height="1.857rem"
                        lineHeight="1.857rem"
                        borderColor='#eaeaea'
                        color='#7c7c7c'
                    /> */}
                <div style={{ width: frequency_type !== 'reactive' ? '45%' : '100%' }}>

                    {monthAndDayArray.indexOf(value) > -1 ?

                        <input
                            disabled={disabled}
                            // value={value ? value : ''}
                            style={{ width: '100%', height: '1.9rem', fontSize: '1rem', padding: '.8rem', boxSizing: 'border-box', color: disabled ? '#ccc' : '#000'}}
                            type="datetime-local" placeholder="Value"
                            onChange={(e) => {
                                // OnSearchTextChange(e)
                                // setValue(e.target.value)
                            }}
                        />
                        :
                        <select
                            disabled={disabled}
                            value={frequency_type ? frequency_type : ''}
                            style={{ width: '100%', height: '1.9rem', borderRadius: '2px', backgroundColor: '#fff' }}
                            onChange={(e) => {
                                onChange(e.target.value, type, 'frequency_type', frequency.id)
                            }}>

                            <option value="">--Select Type--</option>
                            {frequency_types && frequency_types.length > 0 && frequency_types.map((f, i) => {
                                return (
                                    <option key={i} value={f.value}>{f.name}</option>
                                )
                            })}

                        </select>}



                </div>
            </div>


            <div className="time_slot">
                {/* <div style={{ width: '100%', marginBottom: '1.5rem' }}>
                    <SelectOption
                        disabled={disabled}
                        label={`Select Frequency Type For ${type}`}
                        selections={frequency_types ? frequency_types : []}
                        displayKey='name'
                        valueKey='value'
                        onChangeFunc={(value) => {
                            onChange(value, type, 'frequency_type', frequency.id)
                        }}
                        activeValue={frequency_type}
                        width="100%"
                        border='1px solid #bbd1d9'
                        color='#7c7c7c'
                    />
                </div> */}


                {/* {frequency_type && frequency_type === 'weekly' && (
                    <div style={{ width: '100%', marginBottom: '1.5rem' }}>
                        <SelectOption
                            disabled={disabled}
                            label='Frequency Day'
                            selections={week_days}
                            displayKey='name'
                            valueKey='value'
                            onChangeFunc={(value) => {
                                onChange(value, type, 'day', frequency.id)
                            }}
                            activeValue={frequency_day ? frequency_day : undefined}
                            width="100%"
                            border='1px solid #bbd1d9'
                            color='#7c7c7c'
                        />
                    </div>
                )} */}


                {/* {frequency_type && frequency_type === 'monthly' && (
                    <div style={{ width: '100%', marginBottom: '1.5rem' }}>
                        <SelectOption
                             disabled={disabled}
                            label='Frequency Date'
                            selections={range(1, 30)}
                            onChangeFunc={(value) => {
                                onChange(value, type, 'month_date', frequency.id)
                            }}
                            activeValue={frequency_month_date ? frequency_month_date : undefined}
                            width="100%"
                            border='1px solid #bbd1d9'
                            color='#7c7c7c'
                        />
                    </div>
                )} */}



                {/* 
                {frequency_type && (frequency_type === 'weekly' || frequency_type === 'daily' || frequency_type === 'monthly') &&
                    <div style={{ width: '100%', marginBottom: '1.5rem' }}>
                        <TextBox type="time"
                            label="Time"
                            value={frequency_time ? frequency_time : undefined}
                            onChange={(e) => onChange(e.target.value, type, 'time', frequency.id)}
                        />
                    </div>
                } */}



                {/* {(frequency_type && frequency_type === 'minutes') &&
                    <div style={{ width: '100%', marginBottom: '1.5rem' }}>
                        <TextBox type="text"
                            label="Enter Minutes"
                            value={frequency_minute ? frequency_minute : undefined}
                            onChange={(e) => onChange(e.target.value, type, 'minutes', frequency.id)}
                        />
                    </div>
                } */}

                {/* {(frequency_type && frequency_type === 'hours') &&
                    <div style={{ width: '100%', marginBottom: '1.5rem' }}>
                        <TextBox type="text"
                            label="Enter Hours"
                            value={frequency_hours ? frequency_hours : undefined}
                            onChange={(e) => onChange(e.target.value, type, 'hours', frequency.id)}
                        />
                    </div>
                } */}


                {/* {frequency_type && frequency_type === 'one_time' && (
                    <div className="date_time_piker">
                        <TextBox type="datetime-local"
                            label="Date"
                            value={undefined}
                            onChange={(e) => onChange(e.target.value, type, 'date_time', frequency.id)}

                        />
                    </div>
                )} */}
            </div>
        </Wrapper>
    );
}

export default Index;
