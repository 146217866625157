import React, { useState, useEffect } from 'react';

const MultiReportTable = (props) => {

    const { values, formula, formulaMeta, chartId } = props;

    const [formulaValue, setFormulaValue] = useState()

    useEffect(() => {
        if (chartId) {
            let startIndex = 97;
            let formulaObject = formula;

            let formula_key = Object.keys(formulaObject)[0];
            let new_formula = formulaObject[formula_key].formula;

            let temp_formula = new_formula;

            values.forEach((data, index) => {
                const key = Object.keys(data)[0];
                const value = data[key];
                const variable = String.fromCharCode(startIndex);
                startIndex++;

                temp_formula = temp_formula.replace(new RegExp(variable, 'g'), parseFloat(value));

            });

            // eslint-disable-next-line
            setFormulaValue(parseFloat((eval(temp_formula) * 100)).toFixed(2));
        }

    }, [values, formula, chartId]);

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', 'flexDirection': 'column', height: props.heightOfChart, width: props.widthOfChart }}>
            {
                values && values.length > 0 && values.map((data, index) => {
                    const key = Object.keys(data)[0];
                    const value = data[key];
                    return (
                        <div style={{ fontWeight: 'bold', marginBottom: '10px' }} key={'label' + index}>
                            <span style={{ fontSize: '1.5rem' }}>{typeof key === 'string' ? key.toLocaleUpperCase() : key}</span> : <span style={{ fontSize: '1.3rem' }}>{value}</span>
                        </div>
                    )
                })
            }

            {
                formulaMeta &&
                <div> <span style={{ fontSize: '1.5rem', fontWeight: 'bold'}}>{formulaMeta.title.toLocaleUpperCase()}</span>: <span style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>{formulaValue} {formulaMeta.symbol}</span></div>
            }
        </div>
    );
};

export default MultiReportTable;