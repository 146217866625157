import styled from 'styled-components';
import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils'
const selected = getSelectedTheme();



export const Wrapper = styled.div`
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;

    .example{
        margin: 1.2rem 0px;
        font-size: .8rem;
        margin-top: .5rem;
        color: rgba(0, 0, 0, 0.54);
       
    }
`;



export const InputLabel = styled.div`
    color: ${props => props.focused ? "#008AC7" : '#0000008a'};
    position: absolute;
    font-weight: 400;
    font-size: ${props => props.top ? '.8rem' : '1rem'};
    cursor: text;
    top: ${props => props.top ? "-.4rem" : '1.2rem'};
    z-index: 1;
    -webkit-transition: all .2s;
    transition: all .2s;
    z-index: 1;
`;


export const Content = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .q_word {
        text-transform: capitalize;
        color: black;
        color: #555;
        font-size: 1rem;
        margin-bottom: 1rem;
    }

    .q_error {
        color: #f44336;
        margin-top: 5px;
        font-size: .9rem;
        margin-top: 10px;
        text-transform: capitalize;
    }

   
`;



export const InputWrapper = styled.div`
    width: 100%;
    min-height: 2.7rem;
    border: 0px;
    border-bottom: ${props => props.focused ? '2px solid #008AC7' : '1px solid #ccc'};
    border-radius: 0px;
    transition: all .2s;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: flex-start;
    

    .tag{
        font-size: 1rem;
        box-sizing: border-box;
        margin-right: .3rem;
        color: #222;
        margin-top: .7rem;
        cursor: pointer;
    }

    

    input {
        width: 100%;
        height: 2.7rem;
        padding: 0px 0px;
        border:0px;
        font-size: 1rem;
        color: #000;
        box-sizing: border-box;
        z-index: 2;
        position: relative;
        background-color: transparent;

        &:focus {
            border: 0px;
            outline: none;
        }
    }
`;




export const ContentTitle = styled.div`
    font-size: 1.5rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.714rem;
    letter-spacing: normal;
    text-align: center;
    color: #1a1a1a;
    margin: 0px;
`;


export const ButtonWrapper = styled.div`
    border: 1px solid #c1c1c1;
    padding: 5px 10px;
    width: fit-content;
    box-sizing: border-box;
    margin-top: 20px;
    cursor: pointer;
`;


export const AutoComplete = styled.div`
    position: absolute;
    top: 100%;
    left: -2px;
    max-height: 8rem;
    overflow-y: auto;
    box-sizing: border-box;
    box-shadow: 0px 0px 4px #ccc;
    margin-top: 1px;
    background-color: #fff;


    &::-webkit-scrollbar {
        background-color:red;
        width:2px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 2px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 2px;
    }

    .word{
        box-sizing: border-box;
        font-size: .9rem;
        color: #555;
        padding: ${props => props.gap ? "7px 1.5rem" : '7px 8px'};
        &:hover{
            background-color: #eeeeee;
            color: #222;
            cursor: pointer;
        }
    }
    .selected{
        background-color: #eeeeee;
        color: #222;
    }

`;