import React from 'react'
import styled from 'styled-components';
import AddSvg from '../svg/add'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';


const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;

.outer_border {
        border: 1px solid #3E9EFF;
        padding: 1rem;
        border-radius: 26px;
        width: 100%;
        height: 100%;
    }
    .card{
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         width: 100%;
         height: 100%;
         border: 1px solid #3E9EFF;
         border-radius: 26px;
         background: linear-gradient(to right, #3E9EFF, #737DFF);
         cursor: pointer;
         &:hover{
          opacity: .8;
         }
    }
    .icon_outer {
        background: #fff;
        border-radius: 50%;
        padding: 16px;
        border: 4px solid #3E9EFF;
    }

`;
const Index = (props) => {
  return (
    <Wrapper>
      <div style={{ width: '18rem', height: '12rem' }}>
        <div className='outer_border'>
          <div className='card' onClick={() => {
            props.history.push("/layout_editor")
          }} >
            {/* <NewAddSvg size="5rem" height="5rem" color="#fff" /> */}
            <div style={{ border: '2px solid #fff', borderRadius: '50%' }}>
              <div className='icon_outer'>
                <AddSvg size="1.8rem" height="1.6rem" color="#3E9EFF" />
              </div>
            </div>
            <h3 style={{ color: '#fff' }}>Add Your Dashboard</h3>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default withRouter(Index)