import React from 'react';
import { withRouter } from 'react-router-dom';
import { _getDataFromReportingServer } from '../../utils'
import { Wrapper } from './kpi.styled';

const KPI = (props) => {

    const { data } = props;

    return (
        <Wrapper>
            <div className="title">Sorry we couldn't understand</div>
            <div className="did_you_mean">Did you mean these :</div>
            {
                data && data.length > 0 && data.map(question => {
                    return <div
                            className="list_item"
                            onClick={() => _getDataFromReportingServer(question, props.history)}
                            key={question}
                        >
                        <span className="list_icon">*</span>
                        <span className="list_name">{question}</span>
                    </div>
                })
            }
        </Wrapper>
    );
};

export default withRouter(KPI);