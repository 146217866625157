import React, { useEffect } from 'react'
import { PageInnerWrapper } from '../hoc/styled.hoc';
import { showPopup } from '../../utils';
import enums from '../../utils/enums';
import SharedTable from '../shared-table';
import { connect } from 'react-redux';
import { Button } from '../hoc/button';
import { withRouter } from 'react-router-dom';
import AddEditPopup from './add.edit.popup';
import { getUploadConfig, saveFileUploadConfig } from '../../actions/file_upload_config';


const Index = (props) => {


    const { saveFileUploadConfig, config_data } = props;


    useEffect(() => {
        props.getUploadConfig()
    }, [])


    useEffect(() => {
        let _page_info = {
            title: 'File Upload Config',
            extraComponent: <Button primary={true} onClick={() => {
                raiseAddEditPopup()
            }}>
                Add New
            </Button>
        }
        props.dispatchHeaderInformation(_page_info)

    }, [])


    const raiseAddEditPopup = (item) => {
        showPopup((item ? 'Edit Config' : 'Add Config'), undefined, enums.popupType.element_with_header, AddEditPopup, {
            popupData: item ? item : undefined,
            _saveFun: saveFileUploadConfig,
        }, undefined, undefined, undefined, {
            width: '32rem',
        });
    }




    return (
        <div>
            <PageInnerWrapper padding={'10px'} style={{ marginTop: '1rem' }}>
                <SharedTable
                    height={"calc(100vh - 180px)"}
                    editAction={(item) => {
                        raiseAddEditPopup(item)
                    }}
                    deleteAction={(item) => {

                        let clone_item = item ? Object.assign({}, item) : {}
                        clone_item.is_active = 0;
                        saveFileUploadConfig(clone_item)

                    }}

                    tableData={(config_data && config_data.length > 0) ? config_data : undefined}
                    tableHead={["Table Name", "Column Name", 'Question', "Values", "Ignore Row", "Type", "Action"]}
                    columnsToShow={["table_name", "column_name", "question", "values", "ignore_row", "type",]}
                    sortColumns={{
                        "Table Name": {
                            type: "string",
                            dataKey: 'table_name'
                        },

                        "Column Name": {
                            type: "string",
                            dataKey: 'column_name'
                        },
                        'Type': {
                            type: 'string',
                            dataKey: 'type'
                        }
                    }}

                />
                {config_data == undefined || config_data.length == 0 ? <p style={{ textAlign: "center" }}>No Record Found</p> : null}
            </PageInnerWrapper>

        </div>
    )

}



const mapStateToProps = (state) => ({
    config_data: state.fileUploadConfig.config_data?.configs,

})

export default withRouter(connect(mapStateToProps, { saveFileUploadConfig, getUploadConfig })(Index))