import React from 'react';
import styled, { keyframes } from 'styled-components';

import Error from './error';
import { constants } from '../../utils/constants';

const Wrapper = styled.div`
    text-align: center;
    height: ${props => props.height ? props.height : props.error ? '80vh' : ''};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
/* background-color: red; */
`;


const Title = styled.h1`
  font-size: 1.75rem;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  margin-block: 2rem 8rem;
`;

const Loader = styled.div`
  --size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: calc(var(--size) / 2);
  height: calc(var(--size) * 5);
`;

const wave = keyframes`
  25% {
    height: calc(var(--size) * 5);
    background-color: #fc00ff;
  }
  50% {
    height: var(--size);
    background-color: #9c73f8;
  }
`;

const Wave = styled.div`
  width: var(--size);
  height: var(--size);
  border-radius: var(--size);
  background-color: #ffd700;
  animation: ${wave} 2s infinite ease-in-out;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }

  &:nth-child(4) {
    animation-delay: 0.6s;
  }

  &:nth-child(5) {
    animation-delay: 0.8s;
  }
`;

const ErrorLoading = props => {

  
  const { is_group, insights, data, error, isDimension, question, height } = props;

  return (
    <Wrapper error={error} height={height} id={"lo"}>

      {error && <h2>{error}</h2>}
      {/* <h1>No Report..</h1> */}
      {insights && <img src={constants.CONTEXT_PATH + './loader.gif'} alt='Chart Loader' width='50' />}
      {!error && !insights && <>
        <Loader>
          <Wave />
          <Wave />
          <Wave />
          <Wave />
          <Wave />
        </Loader>
        <p>Seeking the Data...</p></>}
    </Wrapper>
  )
};

export default ErrorLoading;