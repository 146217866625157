import styled from 'styled-components'


export const NewWrapper = styled.div`

 table{
        width: 100%;
        border: 0px;
        border-collapse: collapse;
        background-color: #fff;
    }

   td,th{
      padding: 5px;
      height: 2.1rem;
      font-size: 1rem;
   }
   
   td{
      border-bottom: 1px solid #eaeaea;
      span{
        max-width: 40rem;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
   }

    thead {
       /* background: rgb(36, 168, 214); */
       color: #fff;
       text-align: left;
       /* font-family: inherit; */
       /* background-color: cadetblue; */
   }
   th{
        position: sticky;
        top: 0px;
        z-index: 1;
        background: rgb(36,168,214);

        &:nth-last-child(1){
            text-align: center;
        }
   }

   tbody tr:hover td{
      background-color: rgb(204, 221, 227);
   }
   

`;

export const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    margin: auto;
    position: relative;
`;


export const EditorInnerWrapper = styled.div`
    width: 100%;
    padding: 25px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    border: 10px solid #eaeaea;
    overflow: auto;
    /* height:${props => props.scroll ? " calc(100vh - 280px)" : '100%'}; */
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    /* height: calc(100vh - 165px); */

    `;



export const TableWrapper = styled.div`

      width: 100%;
      height:${props => 'calc(100vh - 240px)'};
      overflow: auto;

    &::-webkit-scrollbar {
            background-color:red;
            width:5px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }


    .button_td{
        display: flex;
        align-items: center;
        justify-content: center;

        button{
            display: flex;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 1.6rem;
            width: 2rem;
        }
    }

    
    
  .btn_one{
    border: none;
    /* margin-left: 1.25rem; */
    padding-top: 4px;
    background-color: #eaeaea;
    text-align: center;
    font-size: 1rem;
    cursor: pointer;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    &:hover{
        background: #46a9d6;
        svg{
            fill: #fff;
        }
    }
  }
  .btn_two {
    border: none;
    margin-left: 1.25rem;
    padding-top: 4px;
    background-color: #eaeaea;
    cursor: pointer;
    text-align: center;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    &:hover{
        background: #46a9d6;
        svg{
            fill: #fff;
        }
    }
  }


`;






