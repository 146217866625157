import React, { useCallback, useEffect, useRef, useState } from 'react'
import Footer from '../footer'
import PolusLogoHeadLogo from '../svg/icons-v2/polusLogoHead';
import SearchTextAreaBar from '../hoc/searchTextArea';
import ChatBox from './chat/chatBox';
import { BackgroundIconContainer, BodyContainer, Container, HeaderContainer, HeaderOptions, SearchWrapper } from './index.styled';
import { connect } from 'react-redux';
import { debounceWrapper, find_command_type, generate_unique_key, generatePDFFromElement, getDbInfoId, getMandatoryRequestHeaders, isDefaultLiveDb, showPopup } from '../../utils';
import { determineSingleCellDisplay, extract_the_filter_from_question, generateFileMetaHTML, getFilterdDashoardList, static_response_parser } from './utils';
import { getReport } from '../../actions/report';
import TypingEffect from './typing.effect';
import SuggestionListHoc from '../hoc/suggestionListHoc';
import { calculatePositionForSuggestionBox, calculatePositionForSuggestionBoxTextBox } from '../../utils/calculatePositionAtCursor';
import CatelogPopup from './landingPage/catelogPopup';
import SelectDisplayField from './../select-display-field';
import { getHintsWithTableAndColumnName, getTablesForHints } from '../../actions/hints';
import tableDataPopup from "../../components/table.data.popup";
import VoiceSearchWrapper from '../voice-search-wrapper'

import DashboardAutoSave from '../dashboard-auto-save';
import autoLayoutPopup from '../layout-editor/auto.layout/form.popup';
import enums from '../../utils/enums';
import LeftOptionDrawer from './landingPage/LeftOptionDrawer';
import { interpretCommandAction } from '../../actions/helper.v2';
import SelectFilePopup from '../file-upload/choose.upload.popup'
import { withRouter } from 'react-router-dom';
import { clone_report, delete_report, get_all_reports } from '../../actions/report.helper';
import { send_gpt_mail } from '../../actions/rules.alert';
import { constants } from '../../utils/constants';
import axios from 'axios';
import { dispatchAutoLayoutData } from '../../actions/layout';
import questionPopup from '../layout-editor/question.popup';

let userScrolledUp = false;


const Index = (props) => {



    const {
        hints,
        tableNameOfHints,
        getHintsWithTableAndColumnName,
        hintsWithTableColumnsName,
        getTablesForHints,
        db_infos,
        all_reports,
        outerChartAction,
        dataFromServerClone

    } = props

    const [searchValue, setSearchValue] = useState('');

    const [message, setMessage] = useState([]);
    const chatboxRef = useRef(null);

    const [mainResponseHolder, setMainResponseHolder] = useState([])
    const [isClicked, setIsClicked] = useState(false);
    const [isInitialPageInput, setIsInitialPageInput] = useState(true)


    const [localQuestion, setLocalQuestion] = useState('')
    const [hintList, setHintsList] = useState([]);

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [suggestionBoxPosition, setSuggestionBoxPosition] = useState({ top: 40, left: 0 });
    const [currCursorPosition, setCurrCursorPosition] = useState(undefined)
    const [showSuggestions, setShowSuggestions] = useState(undefined)
    const [isFocused, setIsFocused] = useState(false)
    const [cached_db_info_id, set_cached_db_info_id] = useState(undefined);

    const [showLefter, setShowLefter] = useState(false)

    // THis state will be used for the ACTIONS PART

    const [action, setAction] = useState(undefined);
    const [otherActionData, setOtherActionData] = useState(undefined)
    const [showHistory, setShowHistory] = useState(false);

    const [selectedFile, setSelectedFile] = useState(undefined)

    const [virtualization, setVirtualization] = useState({
        upper: 0,
        lower: 4
    })


    const [isShiftPressed, setIsShiftPressed] = useState(false);
    // const [userScrolledUp, setUserScrolledUp] = useState(false);

    // const { containerRef, visibleItems, itemHeights, setItemHeights } = useVirtualization(mainResponseHolder, 300);

    const handleScroll = () => {
        if (!chatboxRef.current) return;

        const { scrollHeight, clientHeight } = chatboxRef.current;
        const container = chatboxRef.current;
        const scrollTop = container.scrollTop;
        const containerHeight = container.clientHeight;
        const itemHeight = 500; // Fixed height of each item

        // Calculate which items are visible
        const startIndex = Math.floor(scrollTop / itemHeight);
        const endIndex = Math.min(
            mainResponseHolder.length - 1,
            Math.floor((scrollTop + containerHeight) / itemHeight)
        );

        const newVisibleItems = mainResponseHolder.map((_, index) => index >= startIndex && index <= endIndex);
        // console.log("Scroll Top:", scrollTop, "Container Height:", containerHeight);
        // console.log("Visible Items Indices:", { startIndex, endIndex });
        // console.log("Updated Visibility States:", newVisibleItems);
        // setVisibleItems(newVisibleItems);
        userScrolledUp = scrollTop + clientHeight + 20 < scrollHeight// scroll
    }

    // useEffect(() => {
    //   handleScroll(); // Initial calculation
    //   window.addEventListener('resize', handleScroll);
    //   return () => window.removeEventListener('resize', handleScroll);
    // }, [handleScroll]);



    // useEffect(() => {
    //   const handleScroll = () => {
    //     const scrollTop = chatboxRef.current.scrollTop; // Current scroll position
    //     const visibleStartIndex = Math.floor(scrollTop / itemHeight); // First visible item index
    //     const visibleEndIndex = visibleStartIndex + Math.ceil(chatboxRef.current.clientHeight / itemHeight); // Last visible item index

    //     // Update the state with indices of visible items
    //     setVisibleItems([visibleStartIndex, visibleEndIndex]);
    //   };

    //   const container = chatboxRef.current;
    //   container.addEventListener('scroll', handleScroll);

    //   // Trigger the initial scroll event to show the first set of items
    //   handleScroll();

    //   return () => container.removeEventListener('scroll', handleScroll); // Cleanup
    // }, []);


    useEffect(() => {
        const page_info = {
            title: '',
        };
        props.dispatchHeaderInformation(page_info);

        if (!props.all_reports || props.all_reports.length === 0) {
            props.get_all_reports()
        }
    }, [])



    const InputRef = useRef(null);
    const wrapperRef = useRef(null)

    const fileInputRef = useRef(null);



    const updateOrAddResponse = (prev, id, predefined_response, combind_id = 'test') => {
        const exists = prev.some(item => item.id === id);  // Check if the item with the given id exists

        if (exists) {
            // If condition is satisfied, update the existing item
            return prev.map(item =>
                item.id === id
                    ? { ...item, type: 'polus', subType: undefined, predefined_response, combind_id }
                    : item
            );
        } else {
            // If condition is not satisfied, add a new entry
            return [
                ...prev,
                {
                    id: id || 'defaultId',  // Replace 'defaultId' with your desired default value if needed
                    type: 'polus',
                    subType: undefined,
                    predefined_response,
                    combind_id: combind_id
                }
            ];
        }
    };

    const onFileChange = (e) => {

        const files = e.target.files;

        const { id, combind_id } = otherActionData || {};

        const localFiles = []

        if (files.length < 21) {
            for (var i = 0; i < files.length; i++) {
                localFiles.push(files[i]);
            }
        }

        showPopup(undefined, undefined, enums.popupType.element, SelectFilePopup, {
            setActiveTab: () => {

            },
            tabList: ['test'],
            activeFolder: 'test',
            uploadedFileList: localFiles,
            setUploadedFileList: () => {

            },
            onCompleted: (data) => {
                if (data?.data) {
                    const formatted_data = generateFileMetaHTML(data?.data)
                    setMainResponseHolder(prev =>
                        updateOrAddResponse(prev, id, formatted_data, combind_id)
                    );

                } else {
                    setMainResponseHolder(prev =>
                        updateOrAddResponse(prev, id, 'something went wrong', combind_id)
                    );
                }

                setOtherActionData();
                setAction(undefined)
                setIsInitialPageInput(false)

            },

            onError: (error) => {

                console.log("file upload error...", error)
                setMainResponseHolder(prev =>
                    updateOrAddResponse(prev, id, error, combind_id)
                );

                setOtherActionData();
                setAction(undefined)
                setIsInitialPageInput(false)


            },
            onclose: () => {
                setMainResponseHolder(prev =>
                    updateOrAddResponse(prev, id, 'The process has been terminated.', combind_id)
                );
                setIsInitialPageInput(false)

            }
        }, undefined, undefined, "#ccc", undefined, true);

        // Reset the file input to allow the same file to be selected again
        e.target.value = null;
        // Handle the uploaded file here
    };



    useEffect(() => {

        if (db_infos?.length > 0) {

            const db_id = getDbInfoId()
            const IS_DEFAULT_LIVE_DB = isDefaultLiveDb()
            const db_info_id_to_use = IS_DEFAULT_LIVE_DB ? db_id : (db_id && db_id + "__nf__db__cached__");
            set_cached_db_info_id(db_info_id_to_use)
            !tableNameOfHints?.length && getTablesForHints(db_info_id_to_use);
            !hintsWithTableColumnsName?.length && getHintsWithTableAndColumnName(undefined, db_info_id_to_use)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const animateInputOnClick = () => {
        setIsClicked(true);
    };


    const scrollToBottom = useCallback(() => {
        console.log("USER SCROLLING1.1", userScrolledUp)
        if (chatboxRef.current && !userScrolledUp) {
            chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
            // Optional smooth scroll
            // chatboxRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
    }, [chatboxRef, userScrolledUp]); // chatboxRef is stable but included for clarity


    useEffect(() => {

        const handleScroll = () => {
            // setUserScrolledUp(true);
            console.log("USER SCROLLING",)
            // const { scrollTop, scrollHeight, clientHeight } = chatboxRef.current;
            // setUserScrolledUp(scrollTop + clientHeight < scrollHeight);
        };
        chatboxRef.current?.addEventListener('scroll', handleScroll);
        return () => chatboxRef.current?.removeEventListener('scroll', handleScroll);
    }, [chatboxRef]);

    const actionFireOnEvent = (actionName, __id, combind_id, kpiName, dashboardQuestions, dashboard_name) => {

        switch (actionName) {

            case 'OPEN_DASHBOARD_FORM':

                if (dashboardQuestions?.length > 0) {

                    console.log("CREATING THE DASHBOARD", dashboardQuestions);


                    setAction("AUTO_DASHBOARD_GENERATE")

                    setOtherActionData({
                        layoutId: __id,
                        id: __id,
                        combind_id: combind_id,
                        dashboard_name: dashboard_name,
                        dashboardQuestions: dashboardQuestions
                    })

                    const data = {
                        "id": __id,
                        "title": dashboard_name,
                        "widgets": dashboardQuestions?.map((q) => {
                            return {
                                question: q,

                            }
                        })
                    }

                    props.dispatchAutoLayoutData(data)

                    // setMainResponseHolder(prev => ([...prev, { id: __id, type: 'polus', subType: 'waiting', combind_id: combind_id }]))

                }
                else {
                    showPopup('Dashboard Input Form', undefined, enums.popupType.element_with_header, autoLayoutPopup,
                        {
                            onSave: (id) => {
                                setAction("AUTO_DASHBOARD_GENERATE")
                                setOtherActionData({
                                    layoutId: id,
                                    id: __id,
                                    combind_id: combind_id,
                                    dashboard_name: dashboard_name
                                })
                                setMainResponseHolder(prev => ([...prev, { id: __id, type: 'polus', subType: 'waiting', combind_id: combind_id }]))
                            },
                            dashboardQuestions: dashboardQuestions,
                            dashboard_name: dashboard_name
                        }, undefined, undefined, '#515355d6', {
                        width: '75rem',

                    },
                        true,
                        true
                    )
                }

                break;
            case "OPEN_FILE_UPLOAD":

                setOtherActionData({
                    id: __id,
                    combind_id: combind_id
                })
                setMainResponseHolder(prev => ([...prev, { id: __id, type: 'polus', subType: 'waiting', combind_id: combind_id }]))

                // setTimeout(() => {
                //   fileInputRef.current.click();
                // }, 500);
                if (fileInputRef.current) {
                    fileInputRef.current.click(); // Trigger the file input click automatically
                }

                // let's open file upload popup 
                break;

            case "UPDATE_A_DASHBOARD":
                setAction("UPDATE_A_DASHBOARD");

                setOtherActionData({
                    id: __id,
                    combind_id: combind_id,
                    kpiName: kpiName,
                    dashboardQuestions: dashboardQuestions
                })
                setMainResponseHolder(prev => ([...prev, { id: __id, type: 'polus', subType: 'waiting', combind_id: combind_id, }]))
                break;

            default:
                break;
        }
    }




    var matrix = {
        'rpt_m23f4zcl': [
            "why ebit is continuously decreasing in 2023 for Tamil Nadu",
            "why ebit is continuously decreasing and find the root cause for 2023 for Tamil Nadu",
            "why ebit is very low in 2023 for Tamil Nadu",
            "why ebit is very low in 2023 for 'Tamil Nadu'",
            "why ebit is continuously decreasing in 2023 for 'Tamil Nadu'",
            "why ebit is continuously decreasing and find the root cause for 2023 for 'Tamil Nadu'",
            'why low in tamil nadu',
            'why low in tamil Nadu',
            'why low in Tamil Nadu',
            'why low in Tamil nadu'
        ],
        'rpt_m28okegy': [
            'why content cost per subscriber is rising',
            'why content cost per subscriber is increasing',
            'Why is the cost of content per subscriber going up?',
            'Why is the cost of content per subscriber going up',

        ]
    };



    function removeSpaces(str) {
        return str.replace(/\s+/g, '');
    }

    function findMatrixKey(question) {
        var cleanedQuestion = removeSpaces(question).toLowerCase();


        for (var key in matrix) {
            for (var i = 0; i < matrix[key].length; i++) {
                if (removeSpaces(matrix[key][i]).toLowerCase() === cleanedQuestion) {
                    return key;
                }
            }
        }

        if (cleanedQuestion.startsWith('why')) {
            return 'rpt_m23f4zcl';
        }

        return null; // Return null if the question is not found
    };

    const filterDashboards = (all_reports, filter) => {
        return all_reports?.filter(dashboard => {
            // 1. Filter by `createdBy`
            const createdByMatch = filter?.createdBy?.length
                ? dashboard?.created_user?.name?.toLowerCase() === filter?.createdBy?.[0]?.toLowerCase()
                : true;

            // 2. Filter by `dates`
            const dateMatch = filter?.dates?.length > 0
                ? new Date(dashboard?.created_at).getTime() === new Date(filter?.dates[0]).getTime()
                : true;

            // 3. Combine conditions based on what's provided
            if (filter?.createdBy && filter?.dates?.length) {
                return createdByMatch && dateMatch; // Both must match
            }
            if (filter?.createdBy) {
                return createdByMatch; // Only filter by `createdBy`
            }
            if (filter?.dates?.length) {
                return dateMatch; // Only filter by `dates`
            }

            // If no filters, return all dashboards
            return true;
        }).sort((a, b) => {
            // Trim, convert to string, and safely compare
            const aStr = a?.title ? String(a?.title)?.trim().toLocaleLowerCase() : '';
            const bStr = b?.title ? String(b?.title)?.trim().toLocaleLowerCase() : '';
            return aStr?.localeCompare(bStr);
        });
    };


    const onSend = async (text) => {

        const question = (text || localQuestion).trim();

        if (question?.trim()?.length > 1) {

            if (isInitialPageInput) {
                setIsInitialPageInput(false)
            }


            const unique_id = generate_unique_key("auto_ask")
            const combind_id = generate_unique_key("combind_id")

            setMainResponseHolder(prev => ([...prev, { type: 'user', question: question?.trim(), combind_id: combind_id }]));
            setLocalQuestion('');
            setShowSuggestions(false)
            setSuggestionBoxPosition({ left: 0 })
            setIsFocused(false)


            /**
             * here we will write a text parser logic will will prevent the unnessery API calls,
             * we will fixed some static response or some static task for fronted
             * like if i will ask => How are You => I am good what about you
             * please create a dashboard for me => {dashboard create logic will go here}
             * what is my name {Your name is user.name}
             * 
             */

            // const __information_ = find_command_type(question)
            const predefined_response = static_response_parser(question);




            //   // chart type change logic will goes here
            //   setMainResponseHolder(prev => {
            //     const updated = [...prev];
            //     const latestIndex = updated.map((item, index) => item.type === "polus" && item.subType === "waiting" ? index : -1).filter(i => i !== -1).pop();
            //     if (latestIndex !== undefined) {
            //       updated[latestIndex].chartType = __information_?.subtype;
            //       updated[latestIndex].renderMode = 'chart';
            //     }
            //     return [...updated];
            //   });
            // }

            const matrix = findMatrixKey(question);

            if (predefined_response?.text && !predefined_response?.action && predefined_response?.text !== 'DASHBOARD_LIST') {// in case of dashboard list show all list

                setMainResponseHolder(prev => ([...prev, { id: unique_id, type: 'polus', subType: undefined, predefined_response: predefined_response?.text, combind_id: combind_id, question: question?.trim() }]))

                // for dashboard list
            } else if (predefined_response?.text && !predefined_response?.action && predefined_response?.text === 'DASHBOARD_LIST') {
                const filter = predefined_response.applyFilter;
                const filteredDashboardList = filterDashboards(all_reports, filter)

                setMainResponseHolder(prev => ([...prev, { id: unique_id, type: 'polus', subType: 'waiting', combind_id: combind_id }]))
                // for loading effect
                setTimeout(() => {
                    setMainResponseHolder(prev => {
                        const updateData = [...prev];
                        const findIdDataIndex = prev.findIndex(el => el.id === unique_id);
                        updateData?.splice(findIdDataIndex, 1, { id: unique_id, type: 'polus', subType: 'link', predefined_response: filteredDashboardList, combind_id: combind_id, question: question?.trim() })
                        return updateData;
                    });
                }, 2000);
                // setMainResponseHolder(prev => ([...prev, { id: unique_id, type: 'polus', subType: 'link', predefined_response: all_reports.slice(0, 10), combind_id: combind_id, question: question?.trim() }]))
            }
            else if (matrix) {

                // let's process the WHY journey code here 
                // setMainResponseHolder(prev => ([...prev, { id: unique_id, type: 'polus', subType: 'waiting', combind_id: combind_id, question: question?.trim() }]))
                const filter_object = extract_the_filter_from_question(question)
                const encodedParams = filter_object ? btoa(JSON.stringify(filter_object)) : '';

                setMainResponseHolder(prev => ([...prev, {
                    is_hardcoded_story: true,
                    predefined_response: 'CLICK HERE TO SEE THE VISUALIZATION',
                    link: `/?insightId=${matrix}&__nf__additional__=${encodedParams}`,
                    unique_id, type: 'polus', subType: 'link', combind_id: combind_id, question: question?.trim()
                }]))
                setTimeout(() => {
                    // setMainResponseHolder(prev => ([...prev, { id: unique_id, type: 'polus', subType: undefined, predefined_response: "Please wait while we are generating...", combind_id: combind_id, question: question?.trim() }]))
                    // window.open(`/?insightId=${matrix}&__nf__additional__=${encodedParams}`, '_blank');
                }, 2000);


            }

            else if (predefined_response?.action) {
                // if this block we will handle all type of action like create dashboard / report etc ....
                actionFireOnEvent(predefined_response?.action, unique_id, combind_id, predefined_response?.kpiName, predefined_response?.dashboardQuestions)
                //setMainResponseHolder(prev => ([...prev, { id: unique_id, type: 'polus', subType: undefined, predefined: predefined_response, combind_id: combind_id, question: question?.trim() }]))
            }
            else {
                setMainResponseHolder(prev => ([...prev, { id: unique_id, type: 'polus', subType: 'waiting', combind_id: combind_id, question: question?.trim() }]));

                const lastReport = getLastReport(dataFromServerClone);
                const gpt_algo_questions = lastReport?.gpt_algo?.map((q) => q?.question) || undefined
                await ___getQuestionResponse(question, unique_id, combind_id, gpt_algo_questions)
                scrollToBottom();
            }

        } else {
            alert("please enter the question")
        }
    };



    const handleEnterBtn = (e) => {

        if (e.key === 'Enter' && !isShiftPressed) {
            e.preventDefault();
            e.stopPropagation();
            onSend();
        }
        if (e.key === "Shift" && isShiftPressed) {
            e.preventDefault();
            e.stopPropagation();
            setIsShiftPressed(false);
        }
    };



    const ___getQuestionResponse = (question, id = "random_ids", combind_id, gpt_algo_questions) => {
        props.getReport(
            question,
            undefined,
            id,
            undefined,
            'plain_english',
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            props.history,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            props.user,
            undefined,
            undefined,
            undefined,
            true,
            combind_id,
            gpt_algo_questions
        );
    }



    const expandChart = useCallback((id, isDeepDrive = false) => {
        const clone_data = mainResponseHolder ? [...mainResponseHolder] : [];
        clone_data.map((d) => {
            if (d.id === id) {
                if (d.expanded) {
                    d.width = 700;
                    d.height = 400

                    d.expanded = false
                } else {
                    d.expanded = true;
                }
                d.isDeepDrive = isDeepDrive
            }
        })
        setMainResponseHolder(clone_data)
    }, [mainResponseHolder]);




    const handleSuggestionClick = useCallback((word, isClicked) => {

        var startPos = InputRef?.current?.selectionStart;
        const start_v = localQuestion?.substring(0, currCursorPosition || startPos)
        let splitted_value = start_v && start_v?.split(' ');
        splitted_value && splitted_value?.pop();

        !currCursorPosition && setCurrCursorPosition(splitted_value ? (splitted_value?.join(" ")?.length + 1) : 1);
        let last_value = splitted_value && splitted_value?.[splitted_value.length - 1];
        if (word && (last_value === word?.split(' ')?.[0] || word?.includes(last_value))) {
            splitted_value && splitted_value?.pop();
        }


        const newValue = [...splitted_value, word]?.join(' ') + InputRef?.current?.value?.slice(startPos);
        const newCursorPosition = (splitted_value ? splitted_value?.join(' ')?.length : 0) + word?.length + 1;
        setLocalQuestion(newValue);
        if (isClicked) {
            setShowSuggestions(false);
            setHintsList([]);
        }
        if (InputRef?.current) {
            setTimeout(() => {
                if (!InputRef?.current?.value?.slice(startPos)?.trim()) {
                    InputRef.current.scrollLeft = Math.max(0, InputRef.current.scrollWidth - InputRef.current.clientWidth);
                } else {
                    InputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
                }
            }, 0)
        }
    }, [localQuestion]);

    const view_table_data = (table_name) => {
        showPopup(
            undefined,
            undefined,
            enums.popupType.element,
            tableDataPopup,
            {
                tableName: table_name,
                db_info_id: cached_db_info_id,
            }
        );
    };


    const onClickCatelogCategory = () => {
        showPopup(undefined, undefined, enums.popupType.element, CatelogPopup, { cached_db_info_id })
    };

    const onClickKnowSchema = () => {

        const table_selections = [];
        (tableNameOfHints || []).forEach((item) => {
            const tbl_name = item.table_name //+ "__db__id__" + item.db_info_id
            table_selections.push(tbl_name)
        })

        showPopup(undefined, undefined, enums.popupType.element_with_header, SelectDisplayField, {
            menus: props.menus,
            displayItem: table_selections,
            title: "Table list",
            onViewIconClick: view_table_data,
            isCheckboxHide: true,
            hideSearchbox: true,
            hideSelectAll: true,
            hidePrimeryBtn: true,
            selectedFields: undefined,
            buttonText: 'View',
        }, undefined, undefined, undefined, {
            width: '62rem',
            maxWidth: '35rem'
        })
    };


    const voiceAction = () => {

        showPopup('', undefined, enums.popupType.element, VoiceSearchWrapper, {
            save_fun: (text) => {
                // call the get data function here to fetch the data from the server 
                onSend(text);
            }
        },
            undefined,
            undefined,
            undefined, {
        },
        )
    };




    const onDashboardComplete = (dashboard_id = 111, name) => {

        const { id, combind_id } = otherActionData;

        if (dashboard_id) {

            const URL = `/?insightId=${dashboard_id}`;
            // window.open(URL, '_blank')
            const msg = `Your ${name} dashboard is Ready please click here to see`;
            setMainResponseHolder(prev => (
                prev.map(item =>
                    item.id === id
                        ? { ...item, type: 'polus', subType: 'link', link: URL, predefined_response: msg, combind_id: combind_id }
                        : item
                )
            ));
        } else {

            setMainResponseHolder(prev => (
                prev.some(item => item.id === id)
                    ? prev.map(item =>
                        item.id === id
                            ? { ...item, type: 'polus', subType: undefined, predefined_response: 'Ops something went wrong....', combind_id: combind_id }
                            : item
                    )
                    : [...prev, { id: id, type: 'polus', subType: undefined, predefined_response: 'Ops something went wrong....', combind_id: combind_id }]
            ));

        }

        setOtherActionData();
        setAction(undefined)
    };




    const optionOnClick = (key) => {

        switch (key) {

            case 'SEARCH_CATLOG':
                onClickCatelogCategory()
                break;
            case 'KNOW_SCHEMA':
                onClickKnowSchema()
                break;
            case 'RECENT_HISTORY':
                setShowHistory(true)
                break;

            default:
                break;
        }
    }


    const handleAddAttachmentClick = (key) => {
        switch (key) {
            case 'Recent File':

                break;
            case 'Google Drive':

                break;
            case 'Upload from Computer':
                // fileInputRef.current.click()
                if (fileInputRef.current) {
                    fileInputRef.current.click(); // Trigger the file input click automatically
                }
                break;

            default:
                break;
        }
    }


    const updateResponseState = (id, type, subType, msg) => {
        setMainResponseHolder(prev => (
            prev.map(item =>
                item.id === id
                    ? { ...item, type: type || 'polus', subType: subType || "polus", predefined_response: msg || 'No Found Any Related Dashboard.', combind_id: id }
                    : item
            )
        ));
    }




    async function sendPDFToBackend(pdfBlob, pdfTitle, extraData) {

        const formData = new FormData();
        formData.append("nf_uploader", pdfBlob, `${pdfTitle}.pdf`);

        // Append additional data to FormData
        // Object.keys(extraData).forEach((key) => {
        //     formData.append(key, extraData[key]);
        // });

        console.log("formData", extraData)

        try {

            // Define the options for the axios request, including mandatory headers
            const url = `${constants.END_POINTS.API}${constants.END_POINTS.PDF_TO_QUESTION_ANSWER.SEND_FILE_TO_UPLOAD}`;

            const options = {
                headers: getMandatoryRequestHeaders(
                    undefined, 'upload_and_mail',
                    undefined, undefined, undefined,
                    undefined,
                    extraData?.emails?.join(","),
                    extraData?.subject,
                    extraData?.message?.replace(/\s+/g, ' ').trim()
                ),
            }

            const response = await axios.post(url, formData, options)

            if (response.status === 200) {
                console.log("PDF and extra data sent to backend successfully!");
            } else {
                console.error("Failed to store PDF and extra data on backend.");
            }
        } catch (error) {
            console.error("Error sending PDF and extra data to backend:", error);
        }
    }

    const getLastReport = (dataFromServerClone) => {

        const keys = dataFromServerClone && Object.keys(dataFromServerClone);
        const id = keys?.filter((k => dataFromServerClone[k]?.file_path || dataFromServerClone[k]?.is_why_case))

        return dataFromServerClone?.[id?.[id.length - 1]];
    }

    useEffect(() => {

        const handleAsyncOperations = async () => {
            if (dataFromServerClone) {
                const lastQuestionAskedId =
                    mainResponseHolder?.length > 0 && mainResponseHolder[mainResponseHolder?.length - 1]?.id;
                const dataFromServer = dataFromServerClone?.[lastQuestionAskedId];

                const lastReport = getLastReport(dataFromServerClone);

                console.log("lastReport", lastReport, dataFromServerClone);

                if (dataFromServer?.entity) {

                    const entity = dataFromServer?.entity;
                    const action = dataFromServer?.action;
                    const criteria = dataFromServer?.criteria;

                    switch (entity) {
                        case "dashboard":
                            const dashboard_data = action !== 'create' && getFilterdDashoardList(all_reports, criteria);

                            if (action === 'create') {
                                console.log("dataFromServer1.1", dataFromServer)

                                const questions = dataFromServer?.criteria?.question;
                                actionFireOnEvent(
                                    'OPEN_DASHBOARD_FORM',
                                    lastQuestionAskedId,
                                    lastQuestionAskedId,
                                    'test',
                                    questions?.length > 0 ? questions : undefined,
                                    criteria?.title
                                );
                                return;
                            }

                            if (dashboard_data?.length > 1) {
                                updateResponseState(lastQuestionAskedId, undefined, undefined, 'Too many dashboards found with the same name');
                            } else if (dashboard_data?.length === 1) {
                                const dashboard_id = dashboard_data?.[0]?.id;

                                if (action === 'open') {
                                    updateResponseState(lastQuestionAskedId, undefined, undefined, 'Opening the dashboard.');
                                    setTimeout(() => {
                                        window.open(`/?insightId=${dashboard_id}`, '_blank');
                                    }, 2000);
                                }

                                if (action === 'delete') {
                                    props.delete_report(dashboard_id, "report");
                                    setTimeout(() => {
                                        updateResponseState(lastQuestionAskedId, undefined, undefined, 'Dashboard Deleted.');
                                    }, 2000);
                                }

                                if (action === 'duplicate') {
                                    props.clone_report(dashboard_id, "report");
                                    setTimeout(() => {
                                        updateResponseState(lastQuestionAskedId, undefined, undefined, 'Created Copy of Dashboard');
                                    }, 2000);
                                }
                            } else {
                                updateResponseState(lastQuestionAskedId, undefined, undefined, "Couldn't understand this.");
                            }

                            break;

                        case "email":
                            if (action === 'create') {

                                // const to = criteria?.receiver?.email_id || 'saroj.k@newfangled.io';
                                const to = 'saroj.k@newfangled.io';

                                const subject = criteria?.subject;
                                const file_path = lastReport?.file_path;

                                if (lastReport?.is_why_case) {

                                    const blob_data = await generatePDFFromElement(lastReport?.reportId, "VADY AI Summary", false);

                                    await sendPDFToBackend(blob_data, "VADY AI Summary", {
                                        emails: to?.split(","),
                                        subject: "VADY AI Summary",
                                        message: "This is an automated notification to inform you that your Vady AI summary has been successfully generated. Please find the attached PDF document containing the requested insights and analysis. Best regards,Vady AI Support Team."
                                    });

                                } else {
                                    props.send_gpt_mail(to, subject, file_path);
                                    setTimeout(() => {
                                        updateResponseState(lastQuestionAskedId, undefined, undefined, 'Email Sent successfully.');
                                    }, 5000);
                                }
                            }
                            break;

                        default:
                            break;
                    }
                }
            }
        };
        handleAsyncOperations();
    }, [dataFromServerClone]);




    const isAnyExpanded = mainResponseHolder?.some((e) => e.expanded);
    const suggestionBoxPositionTop = isInitialPageInput ? 155 : 45;


    console.log("(here)", action, otherActionData)

    return (<>
        <Container style={{
            zIndex: isAnyExpanded ? 4 : 1,
        }}>




            <input
                ref={fileInputRef}
                type="file"
                style={{ display: 'none' }}
                onChange={onFileChange}
            />

            {(action === "AUTO_DASHBOARD_GENERATE" || action === "UPDATE_A_DASHBOARD") && (
                <DashboardAutoSave
                    layoutId={otherActionData?.layoutId}
                    onCompleted={onDashboardComplete}
                    showLoader={true}
                    dashboard_name={otherActionData?.dashboard_name}
                    kpiToUpdate={otherActionData?.kpiName}
                    dashboardQuestions={otherActionData?.dashboardQuestions}
                    all_reports={all_reports}
                />
            )}


            <LeftOptionDrawer
                showLefter={showLefter}
                setShowLefter={setShowLefter}
                optionOnClick={optionOnClick}
                history={props.history}
            />

            <div style={{
                width: `calc(100% - ${showLefter ? '270px' : '0px'})`,
                height: '100vh',
                position: 'relative',
                padding: '2rem 0px',
                boxSizing: 'border-box'

            }}>

                {/* <div style={{
        position: 'absolute',
        top: '0px',
        fontWeight: 300,
        fontSize: '.8rem'
    }}

    >
        Top Index  -: {visibleItems[0]} <br></br>
        Bottom index -: {visibleItems[1]}
    </div> */}

                <BodyContainer
                    ref={chatboxRef}
                    onScroll={(e) => {
                        // handleScroll
                        handleScroll(e)
                        console.log("SCROLLING")
                    }}
                >
                    <ChatBox
                        chatboxRef={chatboxRef}
                        expandChart={expandChart}
                        mainResponseHolder={mainResponseHolder}
                        scrollToBottom={scrollToBottom}
                    />
                </BodyContainer>


                <SearchWrapper
                    // ref={wrapperRef}
                    isClicked={isClicked}
                    isInitialPageInput={isInitialPageInput}
                    isFocused={isFocused}
                    style={{
                        zIndex: isAnyExpanded ? 1 : 2
                    }}
                >
                    {isInitialPageInput && (
                        <div className='logo_page'>
                            <div style={{
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <PolusLogoHeadLogo height='6rem' size='6rem' />
                            </div>
                            <div style={{
                                margin: '1.3rem 0px',
                            }}>

                                <TypingEffect
                                    typingSpeed={50}
                                    htmlContent={`<span style="font-size: 2.2rem; font-weight: 300;">Hi, How Can I Help you with Data Insights?</span>`} />

                            </div>
                        </div>
                    )}

                    <SearchTextAreaBar
                        InputRef={InputRef}
                        wrapperRef={wrapperRef}
                        value={localQuestion}
                        type="text"
                        width='71rem'
                        // padding='16px'
                        placeHolder='Ask any Data Question to VADY'
                        showSearchIcon={false}
                        showSendBtn={true}
                        showSpeakBtn={false}
                        handleMicClick={voiceAction}
                        handleAddAttachmentClick={handleAddAttachmentClick}
                        showAddAttachment={true}
                        onSearch={onSend}
                        onKeyUp={handleEnterBtn}
                        // onClick={animateInputOnClick}
                        onFocus={(e) => {
                            setShowSuggestions(true);
                            setIsFocused(true)
                        }}
                        onClick={(e) => {
                            e.stopPropagation()
                            animateInputOnClick()
                            setIsFocused(true)
                            setHintsList([])
                        }}

                        onBlur={() => {
                            setIsFocused(false)
                            setShowSuggestions(false);
                            setHintsList([])
                            setSuggestionBoxPosition({ left: 0 })
                        }}

                        onKeyDown={(e) => {

                            setIsFocused(true)
                            e.stopPropagation();
                            if (e.key === "Shift") {
                                setIsShiftPressed(true);
                            }
                            if (e.key === "Enter" && isShiftPressed) {
                                e.preventDefault();
                                setLocalQuestion((prevText) => prevText + "\n")
                            }
                            if (e.key === 'Enter') {
                                e.preventDefault();
                            }
                            if (hintList?.length === 0) return;
                            if (e.key === 'ArrowDown') {
                                e.preventDefault();
                                setSelectedIndex((prevIndex) =>
                                    prevIndex < hintList.length - 1 ? prevIndex + 1 : 0
                                );
                                handleSuggestionClick(hintList[selectedIndex < hintList.length - 1 ? selectedIndex + 1 : 0]);
                            } else if (e.key === 'ArrowUp') {
                                e.preventDefault();
                                setSelectedIndex((prevIndex) =>
                                    prevIndex > 0 ? prevIndex - 1 : hintList.length - 1
                                );
                                handleSuggestionClick(hintList[selectedIndex > 1 ? selectedIndex - 1 : hintList.length - 1]);
                            }
                        }}


                        onChange={(e) => {

                            // setSearchValue(e.target.value)
                            const value = e.target.value;
                            const startPos = e.target.selectionStart;
                            currCursorPosition && setCurrCursorPosition(undefined)
                            selectedIndex !== -1 && setSelectedIndex(-1)

                            setLocalQuestion(e.target.value)

                            const full_question = value.slice(0, startPos)?.split(' ');
                            const last_word = full_question[full_question.length - 1];
                            const currentWord = last_word;

                            if (hints?.length > 0) {

                                // const result = find_hint_in_array(currentWord, hints, hints);
                                const result = hints?.filter(el => el?.toLocaleLowerCase()?.startsWith(currentWord?.toLocaleLowerCase()))?.sort((a, b) => a?.localeCompare(b, 'en', { numeric: true, sensitivity: 'accent' }))
                                setHintsList([...result]);
                                const noOfSuggestionVisible = result?.length > 5 ? 5 : result?.length;
                                const heightOfSuggestion = noOfSuggestionVisible * 30;
                                const coordinates = calculatePositionForSuggestionBoxTextBox(startPos, InputRef, wrapperRef, undefined, heightOfSuggestion);
                                setSuggestionBoxPosition({ left: coordinates?.left + 80, top: isInitialPageInput ? 150 : -heightOfSuggestion - 18 })
                            }
                            if (!showSuggestions) setShowSuggestions(true);
                        }}
                        borderType={'border-radius'}

                    />

                    {showSuggestions && hintList && hintList?.length > 0 &&
                        <SuggestionListHoc
                            height={'160px'}
                            style={{
                                fontWeight: 300,
                                color: '#333',
                                width: '170px',
                                boxSizing: 'border-box'

                            }}
                            suggestionList={hintList}
                            selectedIndex={selectedIndex}
                            position={{
                                ...suggestionBoxPosition,
                                // top: suggestionBoxPositionTop
                            }}
                            handleSuggestionClick={handleSuggestionClick}
                        />
                    }
                </SearchWrapper>
            </div>
            {/* <Footer height={'20px'} /> */}
        </Container>
    </>
    )
};



const mapStateToProps = state => {

    const { tableNameOfHints, hintsWithTableColumnsName } = state.hintsReducer;

    const hints = state.reportReducer.hints;
    const outerChartAction = state.polusChatReducer.outerChartAction;

    const db_infos = state.connectionReducer.db_infos;
    const dataFromServerClone = state.reportReducer.dataFromServer;

    const { autoLayoutData } = state.layoutReducer;

    const all_reports = state.reportHelperReducer?.all_reports?.filter(item => item.type === "insights");


    return { autoLayoutData, hints, tableNameOfHints, hintsWithTableColumnsName, db_infos, outerChartAction, all_reports, dataFromServerClone }

}

export default withRouter(connect(mapStateToProps, { dispatchAutoLayoutData, send_gpt_mail, delete_report, clone_report, interpretCommandAction, getReport, getHintsWithTableAndColumnName, getTablesForHints, get_all_reports })(Index))