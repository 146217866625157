import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getAutocompleteNames, getZone, getSearchMarker, clearSearchMarker, getPredefinedMarker, clearZone } from '../../actions/map';
import { debounceWrapper } from '../../utils/index';
// import ExpandSvg from '../svg/expand'
import TextBox from '../hoc/textBox';
import RippleEffect from '../hoc/ripple-effect';
import ClearSvg from '../svg/close'


const HeaderWrapper = styled.div`
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      box-sizing: border-box;    
      margin-bottom: 5px;  
`;


const InputWrapper = styled.div`
      width: calc(100% - 40px);
      margin-right: 50px;
      position: relative;
      box-sizing: border-box;
      padding-left: 10px;
      /* input{
        font-size: .9rem;
        width: 100%;
        height: 2rem;
        box-sizing: border-box;
        border: 0px;
        border-bottom: 1.8px solid #000;
        background: whitesmoke;
        padding: 10px;
        &::placholder{
            font-size: 1rem;
            font-weight: normal;
            text-align: center;
            font-family: "DM Sans", sans-serif;
            transition: all 0.2s;
        }

        &:focus{
            outline: none;
            border-bottom: 1.8px solid #008AC7;
            &::placeholder {
                font-size: 0rem;
                transition: all 0.2s;
              }
        }
      } */

      .list_view_wrapper{
          position: absolute;
          width: 100%;
          max-height: 200px;
          overflow-y: auto;
          background :#fff;
          z-index: 2;
          top: 100%;
          box-shadow: 0 4px 8px 0 rgba(0,0,0,.15);    
          border: 1px solid #eff3f6;
            &::-webkit-scrollbar {
                background-color:red;
                width:5px
            }

            &::-webkit-scrollbar-track {
                background-color:#b1cad5;
            }

            &::-webkit-scrollbar-thumb {
                background-color:#76919d;
                border-radius:0px;
                height: 5px;
            }
            &::-webkit-scrollbar:horizontal{
                background-color: green;
                height: 5px;
            }
      }

      .item{
          font-size: .9rem;
          padding: 7px 5px;
          box-sizing: border-box;
          cursor: pointer;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: #636363;

          &:hover{
              background-color: whitesmoke;
              color: #000;
          }
      }

`;



const MapHeader = (props) => {
    const { getZone, getAutocompleteNames, autocompleteNames, getSearchMarker, predefinedLevel } = props;

    const [searchValue, setSearchValue] = useState("");
    const [searchHintArray, setSearchHintArray] = useState([]);
    const [showDrop, setShowDrop] = useState(undefined);
    const [activeZones, setActiveZones] = useState('');
    const [closeTime, setCloseTime] = useState(undefined);

    const _getNames = value => {
        getAutocompleteNames(value, predefinedLevel);
    };

    const debounceGetNames = debounceWrapper(_getNames, 200);

    useEffect(() => {
        if ((searchValue && searchValue.length > 0) && !closeTime) {
            debounceGetNames(searchValue);
        }
    }, [searchValue]);



    useEffect(() => {
        if (autocompleteNames) {
            let cloneautocompleteNames = autocompleteNames ? JSON.parse(JSON.stringify(autocompleteNames)) : [];
            let filterdCompleteValue = cloneautocompleteNames && cloneautocompleteNames.length > 0 && cloneautocompleteNames.filter((item, index) => {
                if (searchValue === '') {
                    setShowDrop(undefined)
                    return false;
                }
                if ((typeof item.name === 'string' && item.name.toLowerCase().includes(searchValue.toLowerCase())) || (typeof item.name === 'number')) {
                    setShowDrop(true)
                    return true

                } else {
                    return false
                }
            })

            const sorted_hints = (filterdCompleteValue || []).sort((a, b) => {
                return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            })
            setSearchHintArray(sorted_hints);
        }
    }, [autocompleteNames]);


    const _on_change_value = (data) => {
        if (data && activeZones && activeZones === data) {
            setActiveZones('');


        } else {
            setActiveZones(data)
        }
    };


    return (
        <HeaderWrapper>
            <InputWrapper>
                <TextBox
                    label="Search"
                    labelCenter={true}
                    // placeholder={""}
                    value={searchValue}
                    onChange={(event) => {
                        setCloseTime(undefined);
                        event.preventDefault();
                        event.stopPropagation()
                        const value = event.target.value;
                        setSearchValue(value);
                        setShowDrop(true)
                        if (value.trim.length === 0) {
                            setShowDrop(undefined);
                        }
                    }}
                />

                <div className="list_view_wrapper">
                    {showDrop && searchHintArray && searchHintArray.length > 0 && searchHintArray.map((item, index) => {
                        const type = item.name_type;

                        return (
                            <div
                                key={"drop_down_item" + index}
                                onClick={() => {
                                    if (type === 'zone') {
                                        getZone(item._id, predefinedLevel);
                                        _on_change_value(item.name);
                                        // setMode('zone')
                                    }
                                    else if (type === 'secondary_search_column') {
                                        getSearchMarker(item.id, predefinedLevel, item.column_to_search);
                                        // setMode('single marker')
                                    }
                                    else if (type === 'ternary_search_column') {

                                        getSearchMarker(item.name, predefinedLevel, item.column_to_search);
                                        // setMode('single marker')
                                    }

                                    setSearchValue(item.name)
                                    setShowDrop(undefined);
                                    setSearchHintArray(undefined)
                                    setCloseTime(true)
                                }}
                                className="item"
                            >
                                {item.name}
                            </div>
                        )
                    })}
                </div>
            </InputWrapper>

            {searchValue && (
                <div style={{ position: 'absolute', right: '10px' }}>
                    <RippleEffect
                        tooltip={'Clear'}
                        Click={() => {
                            props.clearSearchMarker()
                            setSearchValue('')
                            props.getPredefinedMarker(predefinedLevel)
                            props.clearZone()

                        }}
                        icon={<ClearSvg size=".8rem" height=".8rem" />}>
                    </RippleEffect>
                </div>
            )}
            {/* </div> */}
        </HeaderWrapper>
    )
};












const mapStateToProps = state => {
    const { autocompleteNames } = state.mapReducer;
    return { autocompleteNames };
};

export default connect(mapStateToProps, { getPredefinedMarker, clearSearchMarker, getAutocompleteNames, getZone, getSearchMarker, clearZone })(MapHeader);
