import styled from 'styled-components';

export const ColorOptionWrapper = styled.div`
    display: flex;
    position: relative;
`;

export const ColorCell = styled.div`
    margin-bottom: 4px;
    width: 15px;
    height: 15px;
    background-color: ${props => props.color ? props.color : '#fff'};
    border-radius: 2px;
    cursor: pointer;

    &:nth-last-child {
        margin-bottom: 0;
    }

    &:hover {
        transform: scale(1.2);
    }
`;

export const RecentColorCell = styled.div`
    border-radius: 2px; 
    width: 15px; 
    height: 12px; 
    background-color: ${props => props.color}; 
    margin-right: 2px; 
    margin-bottom: 2px;
    cursor: pointer;

    &:hover {
        transform: scale(1.2);
    }
`;