export const ENTITY_INIT = 'modules/entity/init';

export const GET_ENTITY_REQUESTED = 'modules/entity/request';
export const GET_ENTITY_SUCCESS = 'modules/entity/success';

export const CLEAR_ENTITY_SUCCESS = 'modules/entity/clear/success';


export const SAVE_ENTITY_SUCCESS = 'modules/entity/saveEntity/success';


