import React from 'react';
import styled from 'styled-components';
import { Button } from '../../hoc/button';


const Wrapper = styled.div`

    width: 650px;
    margin: auto;
    position: relative;

    .inner_wrapper{
        margin: auto;
        width: 100%;
        padding: 1.8rem;
        box-sizing: border-box;
        box-shadow: 4px 5px 13px 5px #CBCBD8;
        border-radius: 15px;
        position: relative;
        z-index: 1;
        display: flex;
        background-color: #fff;
        flex-direction: column;

    }

    .outer_shadow{
        margin: auto;
        width: 100%;
        box-sizing: border-box;
        border-radius: 25px;
        position: absolute;
        right: 3rem;
        background: #E8E8FC;
        top: -3rem;
        height: 26rem;
    }


    .row{
      width: 100%;
      margin-bottom: 1.7rem;

      label{
        display: block;
        font-size: .9rem;
        color: #333;
        font-weight: 600;

        sup{
          color: red;
        }
        
      }
      input,
      textarea{
        display: block;
          width: 100%;
          border-radius: 7px;
          border: 1px solid #ccc;
          box-shadow: 2px 3px 4px #ccc;
          height: 2.4rem;
          padding: 10px;
          box-sizing: border-box;
          margin-top: 7px;
          font-size: .9rem;
          font-weight: 500;
          color: #222;
        }
    }

`;


const JobInputs = ({
  job_state,
  set_job_state,
  pre_step,
  set_pre_step

}) => {



  const on_change = (value, key) => {

    const clone_state = job_state ? { ...job_state } : {};
    clone_state[key] = value;
    set_job_state(clone_state);

  }


  return (
    <Wrapper>
      <div className='outer_shadow'></div>
      <div className='inner_wrapper'>

        <div className='row'>
          <label>Give a Name to your Data Wrangler Job <sup>*</sup></label>
          <input type="text" value={job_state?.name} onChange={(e) => on_change(e.target.value, 'name')} />
        </div>

        <div className='row'>
          <label>Wanna Add Any Description to this Job ?</label>
          <textarea style={{ height: '9rem' }} value={job_state?.job_description} onChange={(e) => on_change(e.target.value, 'job_description')} />
        </div>

        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '2rem'
        }}>
          <Button mR="1rem"
            primary={true}
            width={'8rem'}
            onClick={() => {
              set_pre_step(pre_step + 1)
            }}>Next</Button>

          <Button
            width={'8rem'}
            bgColor={"#e2e2e2"}
            onClick={() => {
              // set_pre_step(pre_step + 1)
            }}

            style={{
              color: '#222'
            }}>Back</Button>
        </div>

      </div>
    </Wrapper>
  )
}


export default JobInputs;
