import { dispatchAction, simpleDispatch_put, simpleDispatch_get } from './helper';
import * as actionTypes from '../action-types/favourite';
import { constants } from '../utils/constants';


export const save_favourite = (data, category_name) => async dispatch => {
    const post = {
        favourite: data,
        category_name: category_name
    }
    const url = constants.END_POINTS.API + constants.END_POINTS.FAVOURITE.POINT + constants.END_POINTS.FAVOURITE.SAVE_FAVOURITE;
    await simpleDispatch_put(dispatch, url, post, actionTypes.SAVE_FAVOURITE, true);

    get_favourite_list_by_user_id()(dispatch)
};



export const toggleMessage =  (message) => async dispatch => {

    let _message = message;
    await dispatchAction(dispatch, actionTypes.ADDED_MESSAGE, _message)
};




export const get_favourite_list_by_user_id = (id) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.FAVOURITE.POINT + constants.END_POINTS.FAVOURITE.GET_FAVOURITE_BY_USER_ID;

    await simpleDispatch_get(dispatch, url, actionTypes.GET_ALL_FAVOURITE_LIST_BY_ID, true);
};




export const get_history = (id) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.HISTORY.POINT + constants.END_POINTS.HISTORY.GET_HISTORY;

    await simpleDispatch_get(dispatch, url, actionTypes.GET_HISTORY_SUCCESS, true);
};


export const delete_history = (history) => async dispatch => {

    const post = {
        "history" : history
    }

    const url = constants.END_POINTS.API + constants.END_POINTS.HISTORY.POINT + constants.END_POINTS.HISTORY.DELETE_HISTORY;

    await simpleDispatch_put(dispatch, url, post, actionTypes.GET_HISTORY_SUCCESS, true);
};

