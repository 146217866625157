import { simpleDispatch_get, simpleDispatch_post } from './helper';
import { constants } from '../utils/constants';
import * as actionTypes from '../action-types/data-hierarchy'


export const get_data_hierarchy = () => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.DATA_HIERARCHY.POINT + constants.END_POINTS.DATA_HIERARCHY.GET_DATA_HIERARCHY
    await simpleDispatch_get(dispatch, url, actionTypes.GET_DATA_HIERARCHY, true);
};



export const save_data_hierarchy = (data) => async dispatch => {
    const post_body = {
        data_hierarchy: data
    };
    const url = constants.END_POINTS.API + constants.END_POINTS.DATA_HIERARCHY.POINT + constants.END_POINTS.DATA_HIERARCHY.SAVE_DATA_HIERARCHY;
    await simpleDispatch_post(dispatch, url, post_body, actionTypes.SAVE_DATA_HIERARCHY, false);
    get_data_hierarchy()(dispatch)
};




export const get_dimension_by_hierarchy = (fact, dimension, reporting_db_info_id) => async dispatch => {

    const post_body = { fact, main_dimension: dimension,  };

    if(reporting_db_info_id){
        
        post_body.db_info_id = reporting_db_info_id
    }

    const url = constants.END_POINTS.API + constants.END_POINTS.DATA_HIERARCHY.POINT + constants.END_POINTS.DATA_HIERARCHY.GET_DIMENSION_BY_HIERARCHY

    await simpleDispatch_post(dispatch, url, post_body, actionTypes.GET_DIMENSION_BY_HIERARCHY, true);
};



export const get_table_column_hints_v1 = (db_info_id) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.HINTS.POINT + constants.END_POINTS.HINTS.GET_HINTS_WTIH_TABLE_COLUMN_NAME

    await simpleDispatch_post(dispatch, url, { db_info_id: db_info_id }, actionTypes.GET_HINTS_FOR_DATA_HIEARCY, true);

}