import styled from 'styled-components';


export const MainWrapper = styled.div`
    width: 100%;
    

    .row{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content:space-between;

        .column_48{
            width: 48%;
        }
    }


    .table_wrapper{
        max-height: 35%;
        overflow: auto;    
        width: 80%;
        table{
            width: 100%;
            border: 0px;
            border-collapse: collapse;
            table-layout: fixed ;
            border: 1px solid #ccc;
            border-radius: 3px;
            

            thead{

                td{
                    background: #308BC8;
                    color: #fff;
                    padding: .6rem .9rem;
                    font-size: .9rem;
                    border: 1px solid #ccc;
                    position: sticky;
                    z-index: 3;
                    top: -1px; /* Don't forget this, required for the stickiness */
                }
            }

            td{
                padding: .2rem .9rem;
                font-size: .9rem;
                font-weight: 500;
                position: relative;
                border: 1px solid #ccc;
            }
        }
    }
`;