import React from 'react';
import { showPopup } from '../../utils';
import * as enums from '../../utils/enums';
import ConfirmBox from '../../components/confirm-box';
import EnableSvg from '../svg/enable'
import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils';
import DeleteIcon from '../svg/v2-icons/deleteIcon'
import EditIcon from '../svg/v2-icons/editIcon'
import DuplicateIcon from '../svg/v2-icons/duplicateIcon'

let selected = getSelectedTheme();

const { actionCardStyle } = themes[selected]


const CardAction = (props) => {

    const { EditAction, DeleteAction, ViewAction, DuplicateAction, enableAction, data, numberOfAction } = props;

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around',margin: '0 auto 1.2rem', width: '80%' }}>

            {/* {ViewAction && (
                <div className="icon_wrapper" title="View" onClick={() => ViewAction(data)} style={{ width: numberOfAction ? (100 / numberOfAction) + "%" : undefined }}>
                    <ViewSvg size={"1.1rem"} height={'1.1rem'} color={actionCardStyle.color.icon} />
                </div>
            )} */}

            {EditAction && (
                <div className="icon_wrapper" title="Edit" onClick={(e) =>{
                    e.stopPropagation();
                    EditAction(data)} 
                } 
                style={{ width: numberOfAction ? (100 / numberOfAction) + "%" : undefined }}>
                    <EditIcon size=".9rem" height=".9rem" color="#006600" />
                </div>
            )}

            {DeleteAction && (
                <div className="icon_wrapper" title="Delete" style={{ width: numberOfAction ? (100 / numberOfAction) + "%" : undefined }} onClick={(e) => {
                    e.stopPropagation();
                    showPopup(undefined, 'Are you sure you want to delete this ? ', enums.default.popupType.element, ConfirmBox,
                        { func: { setYes: () => DeleteAction(data) } })

                }}>
                    <DeleteIcon size=".9rem" height=".9rem" color="#cc0000" />
                </div>
            )}

            {DuplicateAction && (
                <div className="icon_wrapper" title="Duplicate" style={{ width: numberOfAction ? (100 / numberOfAction) + "%" : undefined }} onClick={(e) => {
                    e.stopPropagation();
                    showPopup(undefined, 'Are you sure you want to duplicate this ? ', enums.default.popupType.element, ConfirmBox,
                        { func: { setYes: () => DuplicateAction(data) } })

                }}>
                    <DuplicateIcon size=".9rem" height=".9rem" color="#222" />
                    {/* <DublicateSvg size={".9rem"} height={'.9rem'} color={actionCardStyle.color.icon} /> */}
                </div>
            )}

            {enableAction && (
                <div className="icon_wrapper" title="enable" style={{ width: numberOfAction ? (100 / numberOfAction) + "%" : undefined }} onClick={(e) => {
                    e.stopPropagation();
                    enableAction(data)

                }}>
                    <EnableSvg size={"1.2rem"} height={'1.2rem'} color={'#008AC7'} />
                </div>
            )}


        </div>
    )
}

export default CardAction;
