import React, { useState, useEffect } from 'react';
import { DivRow } from '../../styled-hoc';
import TextBox from '../../hoc/textBox';
import { convertDateInToHtmlDate } from '../../../utils';
import SelectOption from '../../select-option'
import FrequencySelector from '../../frequency-selector';
import DateTimePiker from '../../hoc/date-time-piker';
import { frequencyTypes } from '../constants'
import { RadioCheckMark } from '../../hoc/radiobox';
import IncrementalColumnSelector from '../../incremental_column_selections';
import SelectOptionV1 from '../../select-option/select.option.v1.1';
import { Radio } from '../../hoc/checkbox';


const Index = (props) => {

    const {

        job_state,
        set_job_state,
        load_data,
        set_load_data,
        load_mapping_state,
        jobType

    } = props;


    const on_change_v1 = (value, key) => {

        const clone_job_state = job_state ? { ...job_state } : {};
        clone_job_state[key] = value;
        clone_job_state["modified"] = true;
        set_job_state(clone_job_state);
    }


    const on_change_load_v1 = (value, key) => {
        const clone_load_state = load_data ? { ...load_data } : {};
        clone_load_state[key] = value;
        clone_load_state["modified"] = true;
        set_load_data(clone_load_state);
    }


    const date_columns = (load_mapping_state || [])?.filter((a) => {
        if (a.source_data_type === "datetime") {
            return a
        }
    }).map((a) => {
        return a.source_column;
    });




    return (
        <div>
            <h3 className='title_up' style={{ border: '0px', textAlign: 'center', marginBottom: '1rem' }}>Schedule Your Job</h3>

            <div style={{
                width: '70%',
                background: '#fff',
                // display: 'flex',
                padding: '1rem 2rem',
                boxSizing: 'border-box',
                // flexDirection: 'row',
                borderRadius: '8px',
                margin: 'auto',
                marginTop: '1rem',
                marginBottom: '1rem',
                boxShadow: 'rgba(204, 204, 204, 0.64) 3px 4px 4px'



            }}>

                <div className="flex_row" style={{ margin: "0px 0rem 1rem 0px" }}>

                    <DivRow w="45%">
                        <label className='job_form_label'>Job Start Time</label>
                        <input
                            type="datetime-local"
                            className='job_form'
                            style={{ background: '#E8E8F9' }}
                            value={job_state?.start_time ? convertDateInToHtmlDate(job_state.start_time, 'start_time') : undefined}
                            onChange={(e) => {
                                // onChangeFunction(e.target.value, 'next_run_at')
                                on_change_v1(e.target.value, 'start_time')
                            }}
                        />
                    </DivRow>
                </div>

                <div className="flex_row" style={{ margin: "0px 0rem 1rem 0px" }}>
                    <DivRow w="45%">
                        <DivRow w="100%" style={{ marginBottom: '.1rem' }}>
                            <label className='job_form_label'>Job Frequency</label>
                        </DivRow>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', }}>
                            <span style={{ padding: '.7rem 1rem', fontWeight: '500', backgroundColor: '#fff', fontSize: '.8rem', borderRadius: '7px', color: '#666666', boxShadow: '#cccccc 0px 0px 6px 1px', width: '100px' }}>Every</span>
                            <div style={{ width: '25%' }}>
                                <input
                                    className='job_form'
                                    value={job_state?.frequency_value ? job_state.frequency_value : undefined}
                                    // style={{ width: '100%', height: '1.9rem', fontSize: '1rem', padding: '.8rem', boxSizing: 'border-box', color: '#000' }}
                                    type="text" placeholder="Value"
                                    onChange={(e) => {
                                        on_change_v1(e.target.value, 'frequency_value')

                                    }}
                                />
                            </div>

                            <div style={{ width: '40%', }}>
                                <select
                                    className='job_form'
                                    value={job_state?.frequency_type ? job_state.frequency_type : undefined}
                                    style={{ width: '100%', height: '2.3rem', backgroundColor: '#fff' }}
                                    onChange={(e) => {
                                        on_change_v1(e.target.value, 'frequency_type')
                                    }}>
                                    <option value="">--Select Type--</option>
                                    {frequencyTypes?.map((item) => {
                                        return (
                                            <option value={item.value}>{item.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                    </DivRow>
                </div>

                {jobType !== "google_drive_upload" && (
                    <React.Fragment>
                        <div style={{ width: '100%', borderBottom: '1px solid #e2e2e2' }}></div>
                        <p className="job_form_label" >Choose Data Loading Mode</p>
                        <div className="flex_row"
                            style={{
                                justifyContent: 'flex-start',
                                // border: '1px solid #308bc885',

                                // background: '#fff',
                                // borderRadius: '6px',
                                // boxShadow: '5px 3px 6px 2px #e2e2e2',
                                padding: '10px 0px',
                                boxSizing: 'border-box',
                                width: '290px',
                                marginBottom: '.7rem'
                            }}>
                            <div style={{ marginRight: '2rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                onClick={() => {
                                    on_change_load_v1(true, 'is_reactive')
                                }}
                            >
                                {/* <RadioCheckMark
                            checked={load_data?.is_reactive}
                            outerSize="1rem" />  */}
                                <Radio
                                    w="25px"
                                    h="25px"
                                    mR="5px"
                                    top="5px"
                                    right="8px"
                                    checked={load_data?.is_reactive}
                                    bColor='#6b6b6b'
                                // radioBg={"green"}
                                />
                                <span style={{ display: 'inline-block', marginLeft: '5px' }}>Reactive</span>

                            </div>

                            <div
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                onClick={() => {
                                    on_change_load_v1(false, 'is_reactive')
                                }}
                            >
                                {/* <RadioCheckMark
                            checked={!load_data?.is_reactive}
                            outerSize="1rem" /> */}
                                <Radio
                                    w="25px"
                                    h="25px"
                                    mR="5px"
                                    top="5px"
                                    right="8px"
                                    checked={!load_data?.is_reactive}
                                    bColor='#6b6b6b'
                                // radioBg={"green"}
                                />

                                <span style={{ display: 'inline-block', marginLeft: '5px' }}>Incremental</span>
                            </div>
                        </div>
                    </React.Fragment>
                )}


                {!load_data?.is_reactive && (
                    <React.Fragment>
                        <div className="flex_row">
                            <div style={{ width: '45%' }}>
                                <div style={{ boxShadow: '#cccccc 0px 0px 6px 1px', borderRadius: '6px' }}>
                                    <SelectOptionV1
                                        label={"Specify Incremental Column"}
                                        options={(date_columns || [])}
                                        multiple={true}
                                        checkbox={true}
                                        hide_border={true}
                                        none_value={false}
                                        inputStyle={{ borderRadius: '6px' }}
                                        value={load_data?.incremental_date_column && load_data?.incremental_date_column}
                                        on_change={(v) => {

                                            const clone_load_data = load_data ? { ...load_data } : {};
                                            const clone_incremental_date_column = load_data?.incremental_date_column || [];

                                            if (clone_incremental_date_column?.indexOf(v) == -1) {
                                                clone_incremental_date_column.push(v)
                                            } else {
                                                clone_incremental_date_column.splice(clone_incremental_date_column.indexOf(v), 1)
                                            }
                                            clone_load_data.incremental_date_column = clone_incremental_date_column;
                                            set_load_data(clone_load_data)
                                        }}
                                    />
                                </div>
                            </div>


                        </div>
                        <div className="flex_row">
                            <DivRow w="45%">
                                <DivRow w="100%" style={{ marginBottom: '.1rem' }}>
                                    <label className='job_form_label'>Load Frequency</label>
                                </DivRow>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', }}>
                                    <span style={{ padding: '.7rem 1rem', fontWeight: '500', backgroundColor: '#fff', fontSize: '.8rem', borderRadius: '7px', color: '#666666', boxShadow: '#cccccc 0px 0px 6px 1px', width: '100px' }}>Every</span>
                                    <div style={{ width: '25%' }}>
                                        <input
                                            className='job_form'
                                            value={load_data?.incremental_frequency_value ? load_data.incremental_frequency_value : undefined}
                                            // style={{ width: '100%', height: '1.9rem', fontSize: '1rem', padding: '.8rem', boxSizing: 'border-box', color: '#000' }}
                                            type="text" placeholder="Value"
                                            onChange={(e) => {
                                                on_change_load_v1(e.target.value, 'incremental_frequency_value')

                                            }}
                                        />
                                    </div>

                                    <div style={{ width: '40%', }}>
                                        <select
                                            className='job_form'
                                            value={load_data?.incremental_frequency_type ? load_data.incremental_frequency_type : undefined}
                                            style={{ width: '100%', height: '2.3rem', backgroundColor: '#fff' }}
                                            onChange={(e) => {
                                                on_change_load_v1(e.target.value, 'incremental_frequency_type')
                                            }}>
                                            <option value="">--Select Type--</option>
                                            {frequencyTypes?.map((item) => {
                                                return (
                                                    <option value={item.value}>{item.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </DivRow>
                        </div>
                    </React.Fragment>

                )}
            </div>

        </div>
    )
};


export default Index;

