import * as actions from '../action-types/map';

import { getCoordinteColumnFromColumnMeta, getDataInCoordinateFrom } from '../utils'



const initialState = {
    type: actions.MAP_INIT,
    markers: undefined,
    markerInfo: undefined,
    zone: {},
    multi_zone: undefined,
    zoneMarkers: undefined,
    zoneNames: undefined,
    customZone: undefined,
    customMarkers: undefined,
    radiusMarkers: undefined,
    mapFilter: undefined,
    autocompleteNames: undefined,
    searchMarker: undefined,
    allZones: undefined,
    questionMarker: undefined,
    questionZone: undefined
};


const mapReducer = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    // if (action && action.data && typeof action.data === 'object') {
    //     action.data.key = new Date();
    // }

    switch (action.type) {


        case actions.CLEAR_ZONE_SUCCESS:

            return {
                ...state,
                zone: {}
            }

        case actions.GET_ZONE_SUCCESS:
            const zone_id = action.data.zone_data['id'];

            let temp_zone = { ...state.zone };

            if (typeof temp_zone[zone_id] !== 'undefined') {
                delete temp_zone[zone_id];
            }
            else {
                // temp_zone = {...temp_zone, [zone_id]: action.data}
                temp_zone = { [zone_id]: action.data }
            }

            return {
                ...state,
                type: action.type,
                zone: temp_zone
            };


        case actions.GET_MULTI_ZONE_SUCCESS:

            const cached_multi_zone_data = state?.multi_zone ? { ...state?.multi_zone } : {};
            const payload = action?.data;
            const zone_data = payload?.zone_data;
            const __id__ = payload?.id;

            if (!cached_multi_zone_data[__id__]) {
                cached_multi_zone_data[__id__] = zone_data
            } else {
                cached_multi_zone_data[__id__] = zone_data;
            }

            return {
                ...state,
                type: action.type,
                multi_zone_cache: cached_multi_zone_data
            };

        case actions.GET_CUSTOM_ZONE_MARKERS:
            return {
                ...state,
                type: action.type,
                customZone: action.data.zone_data,
                customMarkers: action.data.markers_in_zone
            };

        case actions.GET_ZONE_NAME_SUCCESS:
            return {
                ...state,
                type: action.type,
                zoneNames: action.data
            };

        case actions.GET_MARKER_IN_RADIUS_SUCCESS:
            return {
                ...state,
                type: action.type,
                radiusMarkers: action.data
            };

        case actions.MAP_FILTER_CHANGE:
            return {
                ...state,
                type: action.type,
                mapFilter: action.data
            };

        case actions.PREDEFINED_MARKERS_SUCCESS:
            return {
                ...state,
                type: action.type,
                predefinedMarkers: action.data
            };

        case actions.GET_AUTOCOMPLETE_NAME_SUCCESS:
            return {
                ...state,
                type: action.type,
                autocompleteNames: action.data
            };

        case actions.SET_FORCE_ZONE_MARKERS:
            return {
                ...state,
                type: action.type,
                zoneMarkers: action.data
            };

        case actions.SET_FORCE_ZONE:
            return {
                ...state,
                type: action.type,
                zone: action.data
            };

        case actions.GET_SEARCH_MARKER_SUCCESS:
            return {
                ...state,
                type: action.type,
                searchMarker: action.data
            };
        case actions.CLEAR_SEARCH_MARKER:
            return {
                ...state,
                type: action.type,
                searchMarker: undefined
            }
        case actions.GET_ALL_ZONE_BY_CLIENT:
            return {
                ...state,
                type: action.type,
                allZones: action.data
            };

        case actions.FLUSH_MAP:
            return {
                ...state,
                type: action.type,
                customMarkers: undefined,
                zoneMarkers: undefined,
                radiusMarkers: undefined
            };


        case actions.GET_MARKERS_OF_QUESTION_SUCCESS:
            const data = action.data;


            const coordinateColumn = getCoordinteColumnFromColumnMeta(data.column_meta_data);
            const dataInCoordinateForm = getDataInCoordinateFrom(data.process_data, coordinateColumn)

            return {
                ...state,
                type: action.type,
                questionMarker: dataInCoordinateForm,
                questionZone: data.zones
            };

        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
            return {
                ...state
            };
    }
};

export default mapReducer;
