import * as actions from '../action-types/interpretation'


const initialState = {
    type: actions.INTERPREATION_INIT,
    data: undefined

};

const Interpretation = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        case actions.GET_NTERPREATION_LIST_SUCCESS:
            return {
                type: action.type,
                data: action.data 
            };

        default:
            return { ...state }
    }
}

export default Interpretation;