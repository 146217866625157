import React from 'react';

import AngleDown from '../svg/angleDown';
import AngleUp from '../svg/angleUp';


const RotatingArrows = props => {

    const { isExpand, onClick, size, color, height, style } = props;

    return (
        <div
         style={style}
            onClick={onClick}
        >
            {isExpand ?
                <AngleUp size={size ? size : '.9rem'} height={height ? height : '.9rem'}  color={color ? color : '#008AC7'} />
                :
                <AngleDown size={size ? size : '.9rem'} height={height ? height : '.9rem'} color={color ? color : '#008AC7'} />
            }
        </div>
    );
}


export default RotatingArrows;