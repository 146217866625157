import * as actions from '../action-types/security';

const initialState = {
    type: actions.SENSITIVE_INT,
    accessGroup: undefined,
};

const securityReducer = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        case actions.GET_ACCESS_GROUP:
            return {
                type: action.type,
                accessGroup: action.data
            };
        
       


        default:
            return { ...state };
    }
};

export default securityReducer;