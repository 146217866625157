import styled from 'styled-components';

export const Wrapper = styled.div`
    height: 142px;
    width: 395px;
    border-radius: 5px;
    position: relative;
    margin: 56px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
    border: solid 5px #eaeaea;
    background-color: #ffffff;

    display: flex;
    justify-content: space-around;
    flex-direction: column;
`;

export const MessageWrapper = styled.div`
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #122e39;
    margin-left: 34px;
    margin-top: 28.4px;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    /* justify-content: center; */
    align-items: end;
    /* bottom: 1.2rem; */
    /* right: 10px; */
    margin-left: 33px;
    /* margin-top: 28px; */
`;


