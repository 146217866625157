import React from 'react';
import FormulaEditorElement from './formula.editor.element';

const BlockElements = (props) => {

    const {

        setActiveColumn,
        setActiveEditorType,
        formulas,
        hide_lable = false,
        hide_then = false,
        minHeight,
        formulaType = "nf_conditional",
        activeColumnId,
        parent_id,
        inner_formula,
        updated_inner_formula,
        set_grouped_items,
        grouped_items

    } = props;


    const {

        addFormula,
        updateFormula,
        deleteFormula,
        deleteFormulaRow,
        deleteFormulaBlock,
        borderColor = "green"

    } = props;




    const local_add_formula = (parent_id, type, value_type, expr, else_if_index, prev_element_id, other_values) => {
        const formula = { expr: expr, type: value_type, ...other_values };
        addFormula(parent_id, type, formula, else_if_index, prev_element_id);
    }


    return (

        <div style={{
            // border: '1px solid #adbdd5',
            borderSpacing: '3px',
            marginTop: '1rem',
            marginLeft: '8px',
            // boxShadow: 'rgb(204, 204, 204) 2px 4px 5px 1px',
            // backgroundColor: '#fff',
            borderRadius: '9px'

        }}>

            {formulaType === "nf_mathematical" && (
                <FormulaEditorElement
                    onDragOver={(event) => event.preventDefault()}
                    formulas={formulas?.["nf_mathematical"] ? JSON.parse(JSON.stringify(formulas?.["nf_mathematical"])) : []}
                    action_on_change={local_add_formula}
                    label={"Mathematical"}
                    type="nf_mathematical"
                    setActiveColumn={setActiveColumn}
                    enable_editor={true}
                    setActiveEditorType={setActiveEditorType}
                    delete_formula_row={deleteFormulaRow}
                    updateFormula={updateFormula}
                    hide_then={hide_then}
                    minHeight={"300px"}
                    formulaType={formulaType}
                    activeColumnId={activeColumnId}
                    addFormula={addFormula}
                    parent_id={parent_id}
                    inner_formula={inner_formula}
                    updated_inner_formula={updated_inner_formula}
                    deleteFormulaBlock={deleteFormulaBlock}
                    set_grouped_items={set_grouped_items}
                    grouped_items={grouped_items}

                />
            )}


            {formulaType !== "nf_mathematical" && (
                <FormulaEditorElement
                    onDragOver={(event) => event.preventDefault()}
                    formulas={formulas?.["if"] && JSON.parse(JSON.stringify(formulas?.["if"])) || []}
                    action_on_change={local_add_formula}
                    label={hide_lable ? undefined : formulaType === "nf_mathematical" ? "MATH" : "IF"}
                    type="if"
                    setActiveColumn={setActiveColumn}
                    enable_editor={true}
                    setActiveEditorType={setActiveEditorType}
                    delete_formula_row={deleteFormulaRow}
                    updateFormula={updateFormula}
                    hide_then={hide_then}
                    minHeight={minHeight}
                    formulaType={formulaType}
                    activeColumnId={activeColumnId}
                    addFormula={addFormula}
                    parent_id={parent_id}
                    inner_formula={inner_formula}
                    updated_inner_formula={updated_inner_formula}
                    deleteFormulaBlock={deleteFormulaBlock}
                    set_grouped_items={set_grouped_items}
                    grouped_items={grouped_items}



                />
            )}


            {formulaType !== "nf_mathematical" && formulas?.["else_ifs"] && formulas?.["else_ifs"]?.length > 0 && JSON.parse(JSON.stringify(formulas?.["else_ifs"])).map((else_if, else_if_index) => {

                return (
                    <FormulaEditorElement
                        else_if_index={else_if_index}
                        onDragOver={(event) => event.preventDefault()}
                        formulas={else_if ? JSON.parse(JSON.stringify(else_if)) : []}
                        action_on_change={local_add_formula}
                        label={hide_lable ? undefined : "ELSE IF"}
                        type="else_ifs"
                        setActiveColumn={setActiveColumn}
                        enable_editor={true}
                        setActiveEditorType={setActiveEditorType}
                        delete_formula_row={deleteFormulaRow}
                        updateFormula={updateFormula}
                        deleteFormulaBlock={deleteFormulaBlock}
                        hide_then={hide_then}
                        minHeight={minHeight}
                        formulaType={formulaType}
                        activeColumnId={activeColumnId}
                        addFormula={addFormula}
                        parent_id={parent_id}
                        inner_formula={inner_formula}
                        updated_inner_formula={updated_inner_formula}
                        set_grouped_items={set_grouped_items}
                        grouped_items={grouped_items}


                    />
                )

            })}

            {formulaType !== "nf_mathematical" && formulas?.["else"] && (
                <FormulaEditorElement
                    onDragOver={(event) => event.preventDefault()}
                    formulas={formulas?.["else"] ? JSON.parse(JSON.stringify(formulas?.["else"])) : []}
                    action_on_change={local_add_formula}
                    label={hide_lable ? undefined : "ELSE"}
                    type="else"
                    setActiveColumn={setActiveColumn}
                    enable_editor={true}
                    setActiveEditorType={setActiveEditorType}
                    delete_formula_row={deleteFormulaRow}
                    updateFormula={updateFormula}
                    minHeight={minHeight}
                    formulaType={formulaType}
                    activeColumnId={activeColumnId}
                    addFormula={addFormula}
                    parent_id={parent_id}
                    inner_formula={inner_formula}
                    updated_inner_formula={updated_inner_formula}
                    deleteFormulaBlock={deleteFormulaBlock}
                    set_grouped_items={set_grouped_items}
                    grouped_items={grouped_items}



                />
            )}
        </div>
    )
}


export default BlockElements;