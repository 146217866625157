import * as actions from '../action-types/helper';

const initialState = {
    type: actions.HELPER_INIT,
    recentMenu: undefined,
    whichTableDropDown: undefined,
    recentMainMenu: undefined,
    mongoDbTableData: undefined,
    taggingInfo : undefined,
    toggleHeader: false,
    expanded_section_cache: {},
    tableDataMode: {}
};

const helperReducer = (state, action) => {
	if (!state || typeof action.type === 'undefined') {
		return initialState;
    }

    switch (action.type) {
        case actions.SAVE_RECENT_OPEN_MENU:

            return {
                ...state,
                type: action.type,
                recentMenu: action.data
            };
        case actions.TOGGLE_HEADER:
            return {
                ...state,
                type: action.type,
                toggleHeader: !state.toggleHeader
            }

        case actions.SAVE_RECENT_OPEN_MAIN_MENU:

            return {
                ...state,
                type: action.type,
                recentMainMenu: action.data
            };

        case actions.SAVE_WHICH_TABLE_DROP_DOWN:

            return {
                ...state,
                type: action.type,
                whichTableDropDown: action.data
            };
        case actions.GET_TAGGING_INFO_SUCCESS:

            return {
                ...state,
                type: action.type,
                taggingInfo: action.data
            };

        case actions.GET_MONGO_TABLE_DATA_SUCCESS:

            return {
                ...state,
                type: action.type,
                mongoDbTableData: action.data
            };
        case actions.CLEAR_MONGO_TABLE_DATA_SUCCESS:
            return {
                ...state,
                type: action.type,
                mongoDbTableData : undefined
            }
        case actions.EXPAND_SECTION_SUCCESS:

            const temp_expanded_sections = state.expanded_section_cache ? JSON.parse(JSON.stringify(state.expanded_section_cache)) : {};
            const report_id = action?.data?.id;
            const __data__ = action?.data?.data;
            temp_expanded_sections[report_id] = __data__;
            return {
                ...state,
                type: action.type,
                expanded_section_cache: temp_expanded_sections
            }

        case actions.SET_TABLE_DATA_MODE_SUCCESS:
            const __id__ = action.data.id;
            const clone_table_data_mode = state.tableDataMode ? JSON.parse(JSON.stringify(state.tableDataMode)) : {};
            clone_table_data_mode[__id__] = action.data.mode;

            return {
                ...state,
                type: action.type,
                tableDataMode: clone_table_data_mode
                
            }
        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
			return { ...state};
        }
};

export default helperReducer;