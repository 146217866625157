import React from 'react';

const Shrink = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            fill={props.color ? props.color : '#EAEAEA'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 24 24"
            space="preserve">
            <g>
                <path d="m21.5 24h-19c-1.379 0-2.5-1.122-2.5-2.5v-19c0-1.378 1.121-2.5 2.5-2.5h19c1.379 0 2.5 1.122 2.5 2.5v19c0 1.378-1.121 2.5-2.5 2.5zm-19-23c-.827 0-1.5.673-1.5 1.5v19c0 .827.673 1.5 1.5 1.5h19c.827 0 1.5-.673 1.5-1.5v-19c0-.827-.673-1.5-1.5-1.5z" /></g><g>
                <path d="m17.5 14h-7c-.276 0-.5-.224-.5-.5v-7c0-.276.224-.5.5-.5s.5.224.5.5v6.5h6.5c.276 0 .5.224.5.5s-.224.5-.5.5z" /></g><g>
                <path d="m10.5 14c-.128 0-.256-.049-.354-.146-.195-.195-.195-.512 0-.707l9-9c.195-.195.512-.195.707 0s.195.512 0 .707l-9 9c-.097.097-.225.146-.353.146z" /></g><g>
                <path d="m8.5 24c-.276 0-.5-.224-.5-.5v-7.5h-7.5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h8c.276 0 .5.224.5.5v8c0 .276-.224.5-.5.5z" />
            </g>
        </svg>

    )

}


export default Shrink;