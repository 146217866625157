

import { get_existence_state_and_highest_field_order_v1 } from './shared.pivot';
import { CHART_TYPES, PIVOT_COLUMN_TYPE } from '../../shared-with-fe/constants';
import FormulaSvg from '../svg/formula';
import { proper_case } from '../../utils';


/**
 * 
 * @param {*} _v_ 
 * @returns 
 */
export const get_pivot_field_in_proper_case = (_v_) => {
    // if (_v_) return _v_.split('nf_').join(' ').split('_').join(' ').split(' ').map(v => v.substring(0, 1).toUpperCase() + v.substring(1, v.length)).join(' ').trim()
    if (_v_) return _v_.split('nf_').join(' ').split('_').join(' ').split(' ').map(v => proper_case(v)).join(' ').trim()
    else return undefined
};

export const get_sql_server_column = (orignal_name, column_name) => {
    const find_key = orignal_name?.trim();

    const f_with_replacer = "[" + find_key + "]";

    const find_key_regix = new RegExp(`__COLUMN__${find_key}`, 'gi');

    // we will need to change this REGIX in feture for sqlServer report

    // const pre = column_name.replace(/\./g, "__COLUMN__")
    const pre = column_name.replace(/\./g, "__DOT____COLUMN__").replace(/__DOT__/gi, '.')

    const c_to_use = pre.replace(find_key_regix, f_with_replacer).replace(/`/g, '')

    return c_to_use;
}


export const get_final_save_report_item = (reportItem, db_type, is_pivot) => {

    const clone_report_item = reportItem ? { ...reportItem } : {};
    const display_columns = clone_report_item?.display_columns ? [...clone_report_item.display_columns] : [];
    const report_filter_fields = clone_report_item?.filter_fields ? [...clone_report_item.filter_fields] : [];
    const clone_table_names = clone_report_item?.table_names ? [...clone_report_item.table_names] : [];


    const display_column = [];
    const transpose = [];
    const table_names = [];
    const filter_fields = report_filter_fields && report_filter_fields.length > 0 && report_filter_fields.map((f) => f.column_name) || [];

    if (is_pivot) {

        const pivot_x_columns = ((display_columns?.length > 0 && display_columns?.filter((a) => a.pivot_type === "pivot_x") || []).sort((a, b) => a.pivot_field_order - b.pivot_field_order)) || []
        const pivot_y_columns = ((display_columns?.length > 0 && display_columns?.filter((a) => a.pivot_type === "pivot_y") || []).sort((a, b) => a.pivot_field_order - b.pivot_field_order)) || []
        const pivot_z_columns = ((display_columns?.length > 0 && display_columns?.filter((a) => a.pivot_type === "pivot_comparison") || []).sort((a, b) => a.pivot_field_order - b.pivot_field_order)) || []

        if (pivot_x_columns?.length > 0) {
            pivot_x_columns.map((c) => {
                const d_column = get_display_column(c, db_type)
                if (d_column?.length > 0) display_column.push(d_column)
            })
        }

        if (pivot_y_columns?.length > 0) {
            pivot_y_columns.map((c) => {
                const d_column = get_display_column(c, db_type)
                if (d_column?.length > 0) display_column.push(d_column)
            })
        }

        if (pivot_z_columns?.length > 0) {
            pivot_z_columns.map((c) => {
                const d_column = get_display_column(c, db_type)
                if (d_column?.length > 0) display_column.push(d_column)
            })
        }

    }

    else {
        display_columns && display_columns.length > 0 && display_columns.sort((a, b) => a.order - b.order).map((c) => {
            const d_column = get_display_column(c, db_type)
            if (d_column?.length > 0) {
                display_column.push(d_column)
            }
        })
    }

    clone_table_names?.length > 0 && clone_table_names.forEach(t => {
        const t_name = t?.table_schema ? (t.table_schema + "." + t.name) : t.name
        table_names.push(t_name)
    })

    // console.log("display_column", display_column, display_columns)
    return {
        "display_columns": display_column,
        "filter_fields": filter_fields,
        "table_names": table_names
    };
}


const get_display_column = (c, db_type) => {


    const add_prefix_in_alias = (str) => {
        const s = str && str.trim("")
        return `${s}`
    }

    let display_column = '';

    if (!c.deleted && !c.is_repeater) {

        const is_formula = c?.is_formula || c?.db_data_type == "db_formula";
        const is_no_aggregation = c?.is_no_aggregation;

        if (c.table_name || c.is_formula || c.db_data_type == "db_formula") {

            if (c.ytd_mtd && c.ytd_mtd_date_column && c.ytd_mtd_sql) {

                const agg = c.aggregation;
                let str1 = "";
                if (agg !== "none") {
                    if (agg === "u_count") {
                        str1 = `count(distinct ${c.ytd_mtd_sql})`
                    } else {
                        str1 = `${c.aggregation}(${c.ytd_mtd_sql})`
                    }
                }

                let _str = str1 + ' as ' + add_prefix_in_alias(c.alias)
                let r1 = is_formula ? ("__nf__rule__" + _str + "__nf__rule__") : _str;
                display_column = is_no_aggregation ? (r1 + "!!grouping!!") : r1;


            }
            else {
                if (db_type === 'sqlServer' || db_type === 'azure_synapse') {
                    const sql_server_column = get_sql_server_column(c.orignal_name, c.column_name)
                    let _str = (sql_server_column || c.column_name) + ' as ' + add_prefix_in_alias(c.alias)
                    display_column = _str
                }
                else {
                    let _str = c.column_name + ' as ' + add_prefix_in_alias(c.alias)
                    let r1 = is_formula ? ("__nf__rule__" + _str + "__nf__rule__") : _str
                    display_column = is_no_aggregation ? (r1 + "!!grouping!!") : r1;

                }
            }
        } else {

            let _str = (c.value ? `"${c.value}"` + " as " : "") + `'${c.alias}'`;
            let r1 = is_formula ? ("__nf__rule__" + _str + "__nf__rule__") : _str;
            display_column = is_no_aggregation ? (r1 + "!!grouping!!") : r1;


        }
    }
    return display_column
}



const compare_two_string = (str1, str2) => {
    // console.log("here we are comparing", str1, str2)
    if (str1 !== null && str2 !== null && typeof str1 !== 'undefined' && typeof str2 !== 'undefined') {
        return str1?.toLocaleLowerCase() === str2.toLocaleLowerCase()
    } else return false

}

const find_index_of_str_inside_array = (array, str) => {
    const str_to_use = str && str.toLocaleLowerCase()
    const array_to_use = (array ? JSON.parse(JSON.stringify(array)) : []).map((a) => a.toLocaleLowerCase());
    if (array?.length > 0 && array_to_use.indexOf(str_to_use) > -1) return array_to_use.indexOf(str_to_use);
    else return -1
}




/**
 * 
 * @param {*} localReportItems 
 * @param {*} tabels 
 * @param {*} index 
 */

const covert_r_into_upper_case_for_oracle = (relationship) => {

    const cloned_relationship = relationship ? JSON.parse(JSON.stringify(relationship)) : [];
    cloned_relationship.forEach((r) => {
        r.source_table = r?.source_table?.toLocaleUpperCase();
        r.target_table = r?.target_table?.toLocaleUpperCase();
        r.target_column = r?.target_column?.toLocaleUpperCase();
        r.source_column = r?.source_column?.toLocaleUpperCase();
    })


    return cloned_relationship
}


const is_this_relationship_exists = (relationship, r) => {

    const r_clone = relationship ? JSON.parse(JSON.stringify(relationship)) : [];

    if (r_clone && r_clone.length > 0) {

        // let's check the relationship
        // there is a simple logic of finding the relationship 

        return r_clone.find((rel => {

            if ((rel.target_table === r.target_table && rel.target_column === r.target_column && rel.source_table === r.source_table && rel.source_column === r.source_column)) return true;
            else if ((rel.source_table === r.target_table && rel.target_column === r.target_column && rel.target_table === r.source_table && rel.source_column === r.source_column)) return true;
            else return false;

        }))
    } else return undefined
}

export const find_relationship = (localReportItems, relationships, tabels, index, db_type) => {


    const reportItemsClone = localReportItems ? [...localReportItems] : [];


    const targetReportItem = reportItemsClone[index];
    const tables_clone = (targetReportItem && targetReportItem.table_names) ? [...targetReportItem.table_names] : [];
    const clone_processed_table = (targetReportItem && targetReportItem.processed_table) ? [...targetReportItem.processed_table] : [];
    const clone_relationship_details = (targetReportItem && targetReportItem.relationship_details) ? [...targetReportItem.relationship_details] : [];

    const __tables__ = tabels ? [...tabels] : [];
    const __prev__table__ = tables_clone.map(el => el.name) || [];
    const processedTable = clone_processed_table ? [...clone_processed_table] : [];
    const final_relationship = clone_relationship_details ? [...clone_relationship_details] : [];



    const relationship_to_use = db_type && db_type === "oracle" ? covert_r_into_upper_case_for_oracle(relationships) : relationships;

    // console.log("relationship_to_use", relationship_to_use)

    const push_to_array = (r, source_table, target_table) => {
        // console.log("came here fro 2 times", source_table, target_table)

        const index_of_source = find_index_of_str_inside_array(processedTable, source_table)
        const index_of_target = find_index_of_str_inside_array(processedTable, target_table)

        if (index_of_source === -1 || index_of_target === -1) {

            // lets check if this relationship is not exist then push it other wise we will not add this

            if (!is_this_relationship_exists(final_relationship, r)) {
                final_relationship.push(r);
            }

            // if (find_index_of_str_inside_array(processedTable, source_table) === -1) {
            // commenting this code for now 
            // processedTable.push(source_table);
            // }
            // if (find_index_of_str_inside_array(processedTable, target_table) === -1) {
            // commenting this code for now 
            // processedTable.push(target_table);

            // }

        }
    };

    __tables__ && __tables__.length > 0 && __tables__.forEach((t, i) => {
        relationship_to_use && relationship_to_use.length > 0 && relationship_to_use.forEach((r) => {
            if (compare_two_string(r.source_table, t) && find_index_of_str_inside_array(__prev__table__, r.target_table) > -1) {
                push_to_array(r, r.source_table, r.target_table);
            }
            else if ((r.target_table === t) && find_index_of_str_inside_array(__prev__table__, r.source_table) > -1) {
                push_to_array(r, r.source_table, r.target_table);
            }
        })
    })


    targetReportItem.relationship_details = final_relationship;
    targetReportItem.processed_table = processedTable;
    reportItemsClone[index] = targetReportItem;


    return reportItemsClone;
}






const createHintString = (table_name, column_name, data_type) => {
    let output = [];
    // if(number_data_types.indexOf(data_type) > -1) {
    //     output.push(`${table_name}.${column_name}`)
    //     output.push(`count(${table_name}.${column_name})`)
    //     output.push(`sum(${table_name}.${column_name})`)
    //     output.push(`min(${table_name}.${column_name})`)
    //     output.push(`max(${table_name}.${column_name})`)
    //     output.push(`avg(${table_name}.${column_name})`)
    // }
    // else if(data_type === 'string' || data_type === 'varchar') {
    //     output.push(`${table_name}.${column_name}`)
    //     output.push(`count(${table_name}.${column_name})`)
    // }
    // else{
    output.push(`${table_name}.${column_name}`)
    // }

    return output
}


/**
 * 
 * @param {*} schema_table_info 
 * @param {*} table_names 
 * @returns 
 */
export const find_criteria_suggesition_by_report_table = (schema_table_info, table_names) => {

    const final_hints = [];

    if (table_names && table_names.length > 0 && schema_table_info && Object.keys(schema_table_info).length > 0) {
        table_names.map((t) => {
            const table_name = t.name;
            const schema_id = t.schema_id;
            const sh_tables = schema_table_info[schema_id] || schema_table_info[schema_id + "__nf__db__cached__"];
            if (sh_tables && sh_tables[table_name] && Object.keys(sh_tables[table_name]).length > 0) {
                Object.keys(sh_tables[table_name]).map((c) => {
                    let hints = createHintString(table_name, c, sh_tables[table_name][c].type)
                    hints && hints.length > 0 && hints.map((v) => {
                        if (final_hints.indexOf(v) === -1) {
                            final_hints.push(v)
                        }
                    })
                })
            }
        })
    }
    return final_hints;
}




/**
 * 
 * @param {*} __data_type__ 
 * @returns 
 */
const return_data_type_of_column = (__data_type__) => {
    let data_type = 'string';
    switch (__data_type__ && __data_type__.toLowerCase()) {
        case 'int':
        case 'double':
        case 'currency':
        case 'float':
        case 'number':
            data_type = 'number';
            break;
        case 'datetime':
        case 'date':
        case 'timestamp':
        case 'datetime2':
        case 'complex_date':
        case 'yyyymmdd':
        case 'seconds':

            data_type = 'date';
            break;
        case 'db_formula':

            return 'db_formula'

    }
    return data_type
}


/**
 * 
 * @param {*} connection_name 
 * @param {*} schema_id 
 * @param {*} table_name 
 * @param {*} column_name 
 * @param {*} __data_type__ 
 * @param {*} pivot_type 
 * @param {*} index_to_use 
 * @param {*} existence_details 
 * @param {*} render_mode 
 * @returns 
 */
const return_required_data_for_column = (connection_name, schema_id, table_name, column_name, __data_type__, pivot_type, index_to_use, existence_details, render_mode, character_length) => {

    const data_type = return_data_type_of_column(__data_type__)
    const prefix = data_type === 'number' ? `sum(` : '';
    const suffix = data_type === 'number' ? `)` : '';
    const alias_to_use = column_name + (existence_details?.count ? (existence_details?.count + 1) : '')
    const aggregation_to_use = ''
    const display_column = aggregation_to_use ? 'sum(`' + table_name + '`.' + column_name + ")" : '`' + table_name + '`.' + column_name;


    return {
        schema_id: schema_id,
        column_name: display_column,
        alias: alias_to_use,
        table_name: table_name,
        data_type: data_type,
        db_data_type: __data_type__,
        source_data_type: __data_type__,
        character_length: character_length,
        orignal_name: column_name,
        aggregation: '',
        pivot_aggregation: 'sum',
        index: index_to_use,
        order: index_to_use,
        pivot_type: pivot_type ? pivot_type : undefined,
        fe_pivot_type: pivot_type ? pivot_type : undefined, /// this is used for only in ui side which user drag drop the column

        pivot_field_alias: pivot_type ? alias_to_use : undefined,
        pivot_field_column_name: pivot_type ? alias_to_use : undefined,
        pivot_field_order: pivot_type ? existence_details?.highest_field_order + 1 : undefined,
        visible_type: 1,
        axis: 'primary',
        chart_type: render_mode,
    }
}


/**
 * 
 * @param {*} localReportItems 
 * @param {*} schema_table_info 
 * @param {*} filterColumns 
 * @param {*} relationships 
 * @param {*} event 
 * @param {*} key 
 * @param {*} pivot_type 
 * @param {*} index 
 * @returns 
 */

export const drop_column_for_editor_v1 = (localReportItems, schema_table_info, filterColumns, relationships, event, key, pivot_type, index, __db_type__) => {

    const reportItemsClone = localReportItems ? [...localReportItems] : [];
    const targetReportItem = reportItemsClone[index];
    const display_columns = (targetReportItem?.display_columns) ? [...targetReportItem.display_columns] : [];
    const filter_fields = filterColumns ? [...filterColumns] : [];
    const tables_clone = (targetReportItem?.table_names) ? [...targetReportItem.table_names] : [];

    const all_valid_table_for_filters = [];

    (reportItemsClone || []).forEach((r) => {
        (r.table_names || []).forEach((t) => {
            if (t.name) {
                all_valid_table_for_filters.push(t?.name)
            }
        })
    })
    // this code added for v2


    const column_info = JSON.parse(event.dataTransfer.getData("column_info") || "{}");

    console.log("column_info", column_info);

    const { column_name, table_schema, table_name, schema_id, plugin, schema_name, db_type, connection_name, data_transfer_mode, data_transfer_from, data_type } = column_info;

    // here we will change the column pivot_type
    if (pivot_type && data_transfer_from !== 'left_panel') {
        const target_column_index = display_columns?.findIndex(c => c.index === column_info.index);
        const existence_details = get_existence_state_and_highest_field_order_v1(display_columns, 1, pivot_type);

        if (target_column_index > -1) {
            display_columns[target_column_index] = column_info;
            display_columns[target_column_index].pivot_type = pivot_type;
            display_columns[target_column_index].fe_pivot_type = pivot_type;
            display_columns[target_column_index].pivot_aggregation = 'sum'
            display_columns[target_column_index].pivot_field_order = existence_details?.highest_field_order + 1;
        }
        targetReportItem.display_columns = display_columns;
        targetReportItem["isQuestionModified"] = true;

        targetReportItem['delete_from_storage'] = true /// this is for delete from storage
        reportItemsClone[index] = targetReportItem;
        // [__nf_rule__][COL NAME][__nf_rule__]
    }


    const is_this_column_already_exist = ((display_columns || []).filter((c) => !c?.deleted) || []).find(c => (c.table_name === table_name && c.orignal_name === column_name));
    // const we_should_not_use_duplcate_column = pivot_type ? is_this_column_already_exist : false;

    // this code is use for if you drag column from left section then this w

    if (data_transfer_from === 'left_panel') {

        if (key === 'selected_columns') {
            // this is for if user will drop whole table then we will push all that column in display field
            if (data_transfer_mode === 'parent') {
                schema_table_info && Object.keys(schema_table_info[schema_id][table_name]).length > 0 && Object.keys(schema_table_info[schema_id][table_name]).map((c) => {

                    const item_index = display_columns?.length;
                    const existence_details = get_existence_state_and_highest_field_order_v1(display_columns, 1, pivot_type, table_name, column_name)

                    const __data_type__ = schema_table_info && schema_table_info[schema_id][table_name] && schema_table_info[schema_id][table_name][c].type;
                    const character_length = schema_table_info && schema_table_info[schema_id][table_name] && schema_table_info[schema_id][table_name][c].character_length;

                    display_columns.push(return_required_data_for_column(connection_name, schema_id, table_name, c, __data_type__, pivot_type, item_index, existence_details, undefined, character_length));

                });
            }
            if (data_transfer_mode === 'child') {

                const __data_type__ = schema_table_info && schema_table_info[schema_id] && schema_table_info[schema_id][table_name] && schema_table_info[schema_id][table_name][column_name].type;
                const character_length = schema_table_info && schema_table_info[schema_id] && schema_table_info[schema_id][table_name] && schema_table_info[schema_id][table_name][column_name].character_length;


                const item_index = display_columns?.length;
                const existence_details = get_existence_state_and_highest_field_order_v1(display_columns, 1, pivot_type, table_name, column_name)
                display_columns.push(return_required_data_for_column(connection_name, schema_id, table_name, column_name, __data_type__, pivot_type, item_index, existence_details, undefined, character_length));
            }

            // here we are finding the relationship for the table name 

            const table_name_to_use = (table_schema && table_name) ? (table_schema + "." + table_name) : table_name;

            const is_table_exist = tables_clone.some(el => el.name === table_name);
            if (tables_clone && !is_table_exist) {
                tables_clone.push({ name: table_name, schema_id: schema_id, plugin: plugin, table_schema: table_schema, schema_name: (table_schema || schema_name), db_type: db_type })
                find_relationship(reportItemsClone, relationships, [table_name], index, __db_type__)
            }

            // here we are setting the state
            targetReportItem.display_columns = display_columns;
            targetReportItem["isQuestionModified"] = true;

            targetReportItem['delete_from_storage'] = true /// this is for delete from storage
            targetReportItem['call_java_forcefully'] = true;

            targetReportItem.table_names = tables_clone;
            reportItemsClone[index] = targetReportItem;
            // setLocalReportItems(reportItemsClone)
            if (is_this_column_already_exist) alert("column already exists")
        }



        if (key === 'filter_fields') {
            if (data_transfer_mode === 'child') {

                const __data_type__ = schema_table_info && schema_table_info[schema_id] && schema_table_info[schema_id][table_name] && schema_table_info[schema_id][table_name][column_name].type;
                const data_type = return_data_type_of_column(__data_type__)
                const is_filter_exist = filter_fields.find(f => (f.column_name === table_name + '.' + column_name))

                if (all_valid_table_for_filters?.indexOf(table_name) == -1) {

                    alert("Please use selected table columns only...")

                } else {

                    if (!is_filter_exist) {
                        filter_fields.push({
                            order: filter_fields.length,
                            index: filter_fields.length,
                            column_name: table_name + '.' + column_name,
                            table_name: table_name,
                            data_type: data_type,
                            schema_id: schema_id
                        })
                    }
                }


            }
            // setFilterColumns(filter_fields)
        }
    }

    return {
        reportItems: reportItemsClone,
        filter_fields: filter_fields
    }

}





const convert_to_db_function_updated = (functions_config, column_info, function_from_transformation = '', dbType) => {

    let function_def = undefined;
    let final_formula = undefined;
    const function_entries = functions_config;


    const db_type = dbType ? dbType : 'mysql';
    // get this from db_info
    let params_counter = 0;

    /**
     * function_entries will always come sorted, so we can just assume the same and
     * process the items in order
     */


    (function_entries || []).forEach(func => {
        if (func.db_type === db_type && func.db_function_name === (function_from_transformation && function_from_transformation.length > 0 && function_from_transformation.toLocaleLowerCase())) {
            if (typeof function_def === 'undefined' && func.formula_regex !== null) {

                function_def = func.formula_regex;
            }

            if (function_def) {
                const key = '<%' + func.param + '%>';
                let property_name = '__trns__' + func.id;

                if (func.param === 'column') property_name = '__trns__column';

                const final_value = column_info[property_name];

                if (!final_formula) final_formula = function_def;

                // console.log("property_name", function_def, final_formula, "--", key, "---", final_value)

                // if (final_value) {
                final_formula = final_formula.split(key).join(final_value);
                // } else {
                // final_formula = final_formula.split(key).join('');
                // }
            }
        }
    });

    console.log("final_formula", final_formula)

    return final_formula;
};




const apply_transform_and_aggregate_to_columns = (final_column_name_to_return, functions_config, column_info, t_name, db_type) => {

    // let temp_final_column_name_to_return = '';
    // let final_function = convert_to_db_function_updated(functions_config, column_info, t_name, db_type);
    // final_function = final_function ? final_function.split(',').join('__nf_comma__') : undefined;

    // if (final_function) {
    //     final_column_name_to_return = final_function;
    // }

    // if (aggregation && aggregation !== 'none') {
    //     if (aggregation === 'u_count') {
    //         final_column_name_to_return = `count(distinct ${final_column_name_to_return})`
    //     }
    //     else {
    //         final_column_name_to_return = `${aggregation}(${final_column_name_to_return})`;
    //     }
    // }
}

/**
 * 
 * @param {*} __column_info__ 
 */
export const create_display_column_by_column_info = (functions_config, __column_info__, db_type, display__key = "column_name", active_key) => {
    const column_info = __column_info__ ? { ...__column_info__ } : {};

    if (Object.keys(column_info) && Object.keys(column_info).length > 0) {
        column_info['modified'] = true;

        const aggregation = column_info["aggregation"];
        const value = column_info["value"];
        const alias = column_info["alias"];
        const table = column_info["table_name"];
        // const schema_id = column_info["schema_id"];
        const connection_name = column_info["connection_name"]
        const column = column_info["orignal_name"];
        const t_name = column_info["t_name"];


        if (table && column) {
            column_info['__trns__column'] = `\`${table}\`.${column}`;

        }
        if (column_info.is_formula) {
            column_info['__trns__column'] = `\`${column}\``;
        }

        let final_function = undefined
        const is_t_key = active_key && (active_key === "t_name" || active_key.indexOf("__trns__") > -1);

        let final_column_name_to_return = `${column}`;
        if (column_info.is_formula) {
            final_column_name_to_return = `\`${column}\``;
        }
        if (table && column) {
            final_column_name_to_return = `\`${table}\`.${column}`;
        }

        if (value) {
            // this is a value case, 
            final_column_name_to_return = `"${value}"`;
        }


        if ((active_key === "aggregation" || active_key === "t_name")) {
            const priroty = active_key === "t_name" ? "transform" : 'agg';
            if (!column_info['user_input_values']) column_info['user_input_values'] = {};
            column_info['user_input_values']['priroty'] = priroty;
        }


        if (column_info?.['user_input_values']?.['priroty'] === "transform") {

            if (aggregation && aggregation !== 'none') {

                if (aggregation === 'u_count') {
                    final_column_name_to_return = `count(distinct ${final_column_name_to_return})`
                }
                else {
                    final_column_name_to_return = `${aggregation}(${final_column_name_to_return})`;
                }
            }

            if (final_column_name_to_return) {
                column_info['__trns__column'] = final_column_name_to_return;

            }

            final_function = convert_to_db_function_updated(functions_config, column_info, t_name, db_type);
            final_function = final_function ? final_function.split(',').join('__nf_comma__') : undefined;


            if (final_function) {
                final_column_name_to_return = final_function;
            }


        } else {

            final_function = convert_to_db_function_updated(functions_config, column_info, t_name, db_type);
            final_function = final_function ? final_function.split(',').join('__nf_comma__') : undefined;


            if (final_function) {
                final_column_name_to_return = final_function;
            }

            if (aggregation && aggregation !== 'none') {
                if (aggregation === 'u_count') {
                    final_column_name_to_return = `count(distinct ${final_column_name_to_return})`
                }
                else {
                    final_column_name_to_return = `${aggregation}(${final_column_name_to_return})`;
                }
            }
        }

        if ((value) && alias) {
            final_column_name_to_return = `${final_column_name_to_return} as ${alias}`;
        }


        column_info[display__key] = final_column_name_to_return;
        return column_info
    }
}


/**
 * 
 * @param {*} functions_config 
 * @param {*} activeColumn 
 * @param {*} localReportItems 
 * @param {*} key 
 * @param {*} value 
 * @param {*} index 
 * @param {*} param_key 
 * @param {*} is_user_input_key 
 * @returns 
 */
export const column_proprty_on_change = (db_type, functions_config, activeColumn, localReportItems, key, value, index, param_key, is_user_input_key) => {

    const reportItemsClone = localReportItems ? [...localReportItems] : [];
    const targetReportItem = reportItemsClone[index];
    const display_columns = (targetReportItem?.display_columns) ? [...targetReportItem.display_columns] : [];


    // console.log("functions_config", functions_config)
    let temp_column_obj = activeColumn ? activeColumn : {};
    const _index = activeColumn.index;
    const table = temp_column_obj["table_name"];

    if (!temp_column_obj["user_input_values"]) temp_column_obj["user_input_values"] = {};
    if (is_user_input_key) {
        temp_column_obj["user_input_values"][key] = value;
    }

    if (key === 'aggregation') {
        if (!temp_column_obj['pivot_aggregation']) {
            temp_column_obj['pivot_aggregation'] = 'sum'
        }
    }
    temp_column_obj[key] = value;

    // if user will change the alias of the column then we will update the pivot column info 
    if (key == "alias") {

        temp_column_obj["pivot_field_column_name"] = value;
        temp_column_obj["pivot_field_alias"] = value;
        if (!table && !temp_column_obj.is_formula) {
            temp_column_obj["orignal_name"] = value;
        }
    }

    if (key === 'ytd_mtd') {

        // temp_column_obj['ytd_mtd_date_column'] = undefined;
        temp_column_obj['ytd_mtd_sql'] = undefined;
        temp_column_obj['auto_criteria'] = undefined;
    }

    if ((key === 'ytd_mtd_date_column' || key === 'ytd_mtd') && (temp_column_obj['ytd_mtd_date_column'] && temp_column_obj['ytd_mtd'])) {

        let c = temp_column_obj['table_name'] + "." + temp_column_obj["orignal_name"];
        const ytd_mtd_result = change_column_to_ytd_mtd(db_type, temp_column_obj['ytd_mtd_date_column'], c, temp_column_obj['ytd_mtd'])

        temp_column_obj['ytd_mtd_sql'] = ytd_mtd_result?.['case_statement'];
        temp_column_obj['auto_criteria'] = ytd_mtd_result?.['auto_criteria'];

    }

    // const c_info = temp_column_obj?.is_formula ? temp_column_obj : create_display_column_by_column_info(functions_config, temp_column_obj)

    const c_info = create_display_column_by_column_info(functions_config, temp_column_obj, db_type, undefined, key)

    // console.log("c_info", c_info)
    temp_column_obj = c_info;
    display_columns[_index] = temp_column_obj;
    targetReportItem.display_columns = display_columns;
    targetReportItem['delete_from_storage'] = true /// this is for delete from storage
    targetReportItem['call_java_forcefully'] = true;
    reportItemsClone[index] = targetReportItem;

    return {
        report_items: localReportItems,
        active_columns: temp_column_obj
    };
}









/**
 * 
 * @param {*} __schema_table_info__ 
 * @returns 
 */
export const sort_table_and_column = (__schema_table_info__, client_id = 'abcd__', hints_table = [], need_to_validate_table) => {

    let need_to_validate_table_by_hint = false;

    const hints_table_to_use = hints_table.map((h => (h && h.toLocaleUpperCase()))) || [];

    // this is a patch code for mlc and mlc_labs 
    // we wrote this code as vikas sir said
    // on 19 july 2022 

    if (client_id === 'mlc' || client_id === 'mlc_labs') {
        need_to_validate_table_by_hint = true;
    }

    if (need_to_validate_table) {
        need_to_validate_table_by_hint = need_to_validate_table
    }

    // console.log("need_to_validate_table_by_hint", need_to_validate_table_by_hint, client_id)

    const final_schema_table_info = {};
    __schema_table_info__ && Object.keys(__schema_table_info__).length > 0 && Object.keys(__schema_table_info__).map((s_key) => {
        const sh_tables = __schema_table_info__[s_key];
        const __sorted__tables__ = {};
        sh_tables && Object.keys(sh_tables).length > 0 && Object.keys(sh_tables).sort((a, b) => a.localeCompare(b)).map((c) => {
            const columns = sh_tables[c];
            const keys = columns && Object.keys(columns);
            const sorted_columns = {};
            keys && keys.length > 0 && keys.sort((a, b) => a.localeCompare(b)).map((t) => sorted_columns[t] = columns[t]);
            if (need_to_validate_table_by_hint) {
                if (hints_table_to_use.indexOf(c.toLocaleUpperCase()) > -1) __sorted__tables__[c] = sorted_columns;
            } else __sorted__tables__[c] = sorted_columns;
        })

        final_schema_table_info[s_key] = __sorted__tables__
    })

    return final_schema_table_info;
}





export const search_table_and_column = (value, schema_table_info, filterd_table_name) => {

    const _filterd_schema_info_ = [];
    // schema_table_info

    schema_table_info && Object.keys(schema_table_info).length > 0 && Object.keys(schema_table_info).map((s_key) => {

        const filterd_table = {};
        const sh_tables = schema_table_info[s_key];

        sh_tables && Object.keys(sh_tables).length > 0 && Object.keys(sh_tables).map((t) => {
            if (!value && filterd_table_name && filterd_table_name?.toLocaleLowerCase() === t && t.toLocaleLowerCase()) {
                filterd_table[t] = sh_tables[t];
                return;
            }
            if (!filterd_table_name) {

                if (value === undefined || value === null || value === '') {
                    filterd_table[t] = sh_tables[t];
                }
                if (value && t && t.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1) {
                    filterd_table[t] = sh_tables[t];
                }

                /***
                 * here we are finding table column by column and
                 * we will only show that column which will match the search hint 
                 */

                if (Object.keys(sh_tables[t]).length > 0) {
                    let match = false;
                    let match_column = {};
                    Object.keys(sh_tables[t]).forEach((c) => {
                        if (c && c.length > 0 && c.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1) {
                            match = true;
                            match_column[c] = sh_tables[t][c];
                        }
                    })
                    if (match) {
                        if (filterd_table[t]) {
                            filterd_table[t] = sh_tables[t]
                            match = false
                        }
                        else {
                            filterd_table[t] = match_column
                            match = false
                        }
                    }
                }

                /***
                 * here we are finding table column by hints
                 */
                // if (Object.keys(sh_tables[t]).length > 0) {
                //     let match = false;
                //     Object.keys(sh_tables[t]).forEach((c) => {
                //         if (sh_tables[t][c].hint) {
                //             sh_tables[t][c].hint.values && sh_tables[t][c].hint.values.length > 0 && sh_tables[t][c].hint.values.forEach((v) => {
                //                 if (v && v.length > 0 && v.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1) {
                //                     match = true;
                //                 }
                //             })
                //         }
                //     })
                //     if (match) {
                //         filterd_table[t] = sh_tables[t]
                //         match = false
                //     }
                // }
            }
        })
        _filterd_schema_info_[s_key] = filterd_table;
    })
    return sort_table_and_column(_filterd_schema_info_)
}



/**
 * 
 * @param {*} localReportItems 
 * @param {*} render_mode 
 * @returns 
 */
export const change_render_mode_v1 = (localReportItems, render_mode, disply_column_key = "display_columns", db_type) => {
    // column_infos
    const reportItemsClone = localReportItems ? [...localReportItems] : [];


    reportItemsClone.forEach((report_item) => {

        const display_columns = ((report_item?.[disply_column_key]) ? [...report_item[disply_column_key]] : []).sort((a, b) => a.order - b.order);
        const y_data_type = ['int', 'float', 'double', 'number', 'currency']

        // if render mode is not a table then we need to create pivot

        // console.log("display_columns1.1", display_columns, report_item)

        if (render_mode !== 'table') {
            display_columns.forEach((f, i) => {
                if (((y_data_type.indexOf(f?.data_type && f?.data_type.toLocaleLowerCase()) > -1) || f?.aggregation) && !f?.pivot_type) {
                    f.pivot_type = 'pivot_y';
                    f.fe_pivot_type = 'pivot_y';
                    f.pivot_field_alias = f.alias;
                    f.pivot_field_column_name = f.alias;
                    f.pivot_field_order = i + 1;
                    f.modified = true;
                }
                else if (!f?.pivot_type) {
                    // this will go to pivot x
                    f.pivot_type = 'pivot_x';
                    f.fe_pivot_type = 'pivot_x';
                    f.pivot_field_alias = f?.alias;
                    f.pivot_field_column_name = f?.alias;
                    f.pivot_field_order = i + 1;
                    f.modified = true;
                }
                // get_pivot_field_in_proper_case

            })
        }

        if (render_mode === "table" || render_mode === "single_cell") {
            display_columns.forEach((f, i) => {
                f.pivot_type = null;;
                f.fe_pivot_type = null;
                f.modified = true;
            })
        }

        display_columns.forEach((f) => {
            if (!f.is_formula && !f.is_repeater) {
                const proper_case = get_pivot_field_in_proper_case(f.pivot_field_column_name) || f.pivot_field_column_name
                f.pivot_field_alias = proper_case;
                f.pivot_field_column_name = proper_case;
                f.modified = true;
            }

        })

        // const final_save_output = get_final_save_report_item(local_report_object, db_type, is_pivot);
        report_item.modified = true;
        report_item[disply_column_key] = display_columns;
    })


    return {
        render_mode: render_mode,
        report_items: reportItemsClone
    }
}



/**
 * 
 * @returns 
 */
export const get_pivot_charts_json = () => {
    const temp_pivot_box_jsons = {};
    CHART_TYPES && Object.keys(CHART_TYPES).length > 0 && Object.values(CHART_TYPES).forEach((key) => {
        if (!temp_pivot_box_jsons[key]) {
            if (key === CHART_TYPES.pie_chart) {
                temp_pivot_box_jsons[key] = {
                    [PIVOT_COLUMN_TYPE.PIVOT_X]: { "label": 'Groups' },
                    [PIVOT_COLUMN_TYPE.PIVOT_Y]: { "label": 'Values' },
                }
            }
            if (key === CHART_TYPES.gantt_chart) {
                temp_pivot_box_jsons[key] = {
                    [PIVOT_COLUMN_TYPE.PIVOT_X]: { "label": 'Select Fields (Task, Start Date, End Date)' },
                    [PIVOT_COLUMN_TYPE.PIVOT_Y]: { "label": 'Select Your Tooltip Columns' },
                }
            }
            else {
                temp_pivot_box_jsons[key] = {
                    [PIVOT_COLUMN_TYPE.PIVOT_X]: { "label": 'x Axis' },
                    [PIVOT_COLUMN_TYPE.PIVOT_Y]: { "label": 'Y Axis' },
                    [PIVOT_COLUMN_TYPE.PIVOT_COMPARISON]: { "label": 'Z Axis' },
                }
            }
        }
    })

    return temp_pivot_box_jsons;
}



/**
 * 
 * @param {*} report_items 
 * @param {*} table_name 
 * @param {*} report_index 
 * @returns 
 */
export const remove_table_v1 = (report_items, table_name, report_index, db_type) => {

    const reportItemsClone = report_items ? [...report_items] : [];
    const targetReportItem = reportItemsClone[report_index];
    const clone_display_fields = (targetReportItem?.display_columns) ? [...targetReportItem.display_columns] : [];
    const clone_tbl_names = (targetReportItem?.table_names) ? [...targetReportItem.table_names] : [];
    const clone_relationship = (targetReportItem?.relationship_details) ? [...targetReportItem.relationship_details] : [];
    const processedTable = (targetReportItem?.processed_table) ? [...targetReportItem.processed_table] : [];


    // here we are removing relationship of target table 
    const p_relationship = clone_relationship?.length > 0 && clone_relationship.filter((r, index) => {
        if (compare_two_string(r.source_table, table_name) || compare_two_string(r.target_table, table_name)) return false
        else return true;
    })


    clone_display_fields?.forEach((d) => {
        if (d.table_name === table_name && d.id) d.deleted = true;
    })


    // here we are removing display fields 
    const f_m_field = clone_display_fields?.length > 0 && clone_display_fields.filter((c) => {
        if (c.id) return true;
        else {
            if (c && c.table_name !== table_name) return true;
            else return false;
        }
    })



    // here we are removing table name form table list
    if (clone_tbl_names?.length > 0) {
        const __index__ = clone_tbl_names.findIndex(t => compare_two_string(t.name, table_name));
        if (__index__ > -1) clone_tbl_names.splice(__index__, 1)
    }
    if (processedTable?.length > 0) {
        const __index__ = processedTable.findIndex(t => compare_two_string(t, table_name));
        if (__index__ > -1) processedTable.splice(__index__, 1)
    }




    targetReportItem['modified'] = true;
    targetReportItem.display_columns = f_m_field;
    targetReportItem.table_names = clone_tbl_names;
    targetReportItem.relationship_details = p_relationship;
    targetReportItem.processed_table = processedTable;
    reportItemsClone[report_index] = targetReportItem;


    return reportItemsClone;
}



/**
 * 
 * @param {*} table_name 
 * @param {*} relationship 
 * @returns 
 */
const is_this_table_have_relationship = (table_name, relationship) => {
    if (table_name && relationship && relationship.length > 0) {
        for (let index = 0; index < relationship.length; index++) {
            const r = relationship[index];
            if (compare_two_string(r.source_table, table_name) || compare_two_string(r.target_table, table_name)) {
                return true
            }
        }
    } else return false;
}

/**
 * 
 * @param {*} report_items 
 * @returns 
 */
export const find_table_which_not_have_relationship_v1 = (report_items) => {

    const table_name_with_not_relationship = [];
    const cloned_report_items = report_items ? [...report_items] : [];

    cloned_report_items && cloned_report_items.length > 0 && cloned_report_items.forEach(report_item => {
        const tables_clone = (report_item && report_item.table_names) ? [...report_item.table_names] : [];
        // console.log("tables_clone", tables_clone)
        const clone_relationship_details = (report_item && report_item.relationship_details) ? [...report_item.relationship_details] : [];
        tables_clone && tables_clone.length > 1 && tables_clone.forEach((t) => {
            if (!is_this_table_have_relationship(t.name, clone_relationship_details)) {
                table_name_with_not_relationship.push(t.name)
            }
        })
    })

    // console.log("table_name_with_not_relationship", table_name_with_not_relationship)
    if (table_name_with_not_relationship && table_name_with_not_relationship.length > 0) {
        const __str__ = `No Relationship Found for Table ${table_name_with_not_relationship.join(",")}`;
        alert(__str__)
    }
    return table_name_with_not_relationship;
}




/**
 * 
 * @param {*} data_type 
 * @returns 
 */



const icon_types = {
    'number': <span>#</span>,
    'date': <span>D</span>,
    'string': <span>T</span>,
    'boolean': <span>B</span>,
    'formula': <FormulaSvg color='#afc4e3' size='.6rem' height='.6rem' />,
    'db_formula': <span>F</span>,
}


export const renderIconByDataType = (data_type) => {

    const number_data_types = ['int', 'float', 'double', 'number', 'currency'];

    let dataType = undefined;
    const data_type_to_use = data_type && data_type.toLocaleLowerCase();

    if (number_data_types.indexOf(data_type_to_use) > -1) {
        dataType = 'number'
    }
    if (data_type_to_use === 'string' || data_type_to_use === 'varchar' || data_type_to_use === 'text' || data_type_to_use === 'varchar2' || data_type_to_use === "nvarchar") dataType = 'string'
    if (data_type_to_use === 'boolean' || data_type_to_use === 'tinyint') dataType = 'boolean'
    if (data_type_to_use === 'db_formula') dataType = 'db_formula'
    if (
        data_type_to_use === 'date' ||
        data_type_to_use === 'datetime' ||
        data_type_to_use === "datetime2" ||
        data_type_to_use === "timestamp" ||
        data_type_to_use === "seconds" ||
        data_type_to_use === "yyyymmdd" ||
        data_type_to_use === 'complex_date'

    ) dataType = 'date'

    if (data_type_to_use === 'formula' || data_type_to_use === "db_formula") dataType = 'formula'

    // console.log("data_type_to_use", data_type_to_use, data_type)

    return (
        <div className="d_type_icon">
            {icon_types[dataType]}
        </div>
    )
}




/**
 * 
 * @param {*} db_type 
 * @param {*} date_column 
 * @param {*} calculation_column 
 * @param {*} to_date_category 
 * @param {*} alias 
 * @returns 
 */
export const change_column_to_ytd_mtd = (db_type, date_column, calculation_column, to_date_category, alias) => {

    const get_previous_case_text = (current_date_field) => {
        switch (db_type) {
            case 'mysql': {
                switch (current_date_field) {
                    case 'month':

                }
                break;
            }
        }

        return '';
    };


    const formulas_prefixes = {
        mysql: {
            calendar_year: {
                ytd: { title: '', case_statement: `case when (Year(${date_column}) =  YEAR(':nf_date') AND Date(${date_column}) <=  ':nf_date') ` },
                lytd: { title: '', case_statement: `case when (Year(${date_column}) =  (YEAR(':nf_date') - 1) AND Date(${date_column}) <=  ':nf_date') ` },
                mtd: { title: '', case_statement: `case when (Year(${date_column}) =  YEAR(':nf_date') AND MONTH(${date_column}) = MONTH(':nf_date')  AND Date(${date_column}) <=  ':nf_date') ` },
                ftd: { title: '', case_statement: `case when (Date(${date_column}) =  ':nf_date') ` },
                /** following ones have to be fixed for calculation of last month */
                lymtd: { title: '', case_statement: `case when (Year(${date_column}) =  YEAR(':nf_date') AND Date(${date_column}) <=  ':nf_date') ` },
                lmtd: { title: '', case_statement: `case when (Year(${date_column}) =  YEAR(':nf_date') AND Date(${date_column}) <=  ':nf_date') ` },
            },
            financial_year: {
                ytd: { title: 'YTD', case_statement: ` case when  ${date_column} between ':nf_y_start' and ':nf_now' `, auto_criteria: ` (${date_column} between ':nf_y_start' and ':nf_now') ` },
                fytd: { title: 'FYTD', case_statement: ` case when  ${date_column} between ':nf_finyr_start' and ':nf_now' `, auto_criteria: ` (${date_column} between ':nf_finyr_start' and ':nf_now') ` },
                qtd: { title: 'QTD', case_statement: ` case when  ${date_column} between ':nf_q_start' and ':nf_now' `, auto_criteria: ` (${date_column} between ':nf_q_start' and ':nf_now') ` },
                mtd: { title: 'MTD', case_statement: ` case when  ${date_column} between ':nf_m_start' and ':nf_now' `, auto_criteria: ` (${date_column} between ':nf_m_start' and ':nf_now') ` },
                ftd: { title: 'FTD', case_statement: ` case when  ${date_column} between ':nf_d_start' and ':nf_now' `, auto_criteria: ` (${date_column} between ':nf_d_start' and ':nf_now') ` },
                lytd: { title: 'LYTD', case_statement: ` case when  ${date_column} between ':nf_ly_start' and ':nf_ly_end' `, auto_criteria: ` (${date_column} between ':nf_ly_start' and ':nf_ly_end') ` },
                lfytd: { title: 'LFYTD', case_statement: ` case when  ${date_column} between ':nf_lfy_start' and ':nf_lfy_end' `, auto_criteria: ` (${date_column} between ':nf_lfy_start' and ':nf_lfy_end') ` },
                lqtd: { title: 'LQTD', case_statement: ` case when  ${date_column} between ':nf_lq_start' and ':nf_lq_end' `, auto_criteria: ` (${date_column} between ':nf_lq_start' and ':nf_lq_end') ` },
                lmtd: { title: 'LMTD', case_statement: ` case when  ${date_column} between ':nf_lm_start' and ':nf_lm_end' `, auto_criteria: ` (${date_column} between ':nf_lm_start' and ':nf_lm_end') ` },
                lftd: { title: 'LFTD', case_statement: ` case when  ${date_column} between ':nf_ld_start' and ':nf_ld_end' `, auto_criteria: ` (${date_column} between ':nf_ld_start' and ':nf_ld_end') ` },
                lyqtd: { title: 'LYQTD', case_statement: ` case when  ${date_column} between ':nf_lyq_start' and ':nf_lyq_end' `, auto_criteria: ` (${date_column} between ':nf_lyq_start' and ':nf_lyq_end') ` },
                lymtd: { title: 'LYMTD', case_statement: ` case when  ${date_column} between ':nf_lym_start' and ':nf_lym_end' `, auto_criteria: ` (${date_column} between ':nf_lym_start' and ':nf_lym_end') ` },
                lyftd: { title: 'LYFTD', case_statement: ` case when  ${date_column} between ':nf_lyd_start' and ':nf_lyd_end' `, auto_criteria: ` (${date_column} between ':nf_lyd_start' and ':nf_lyd_end') ` },
                lylqtd: { title: 'LYLQTD', case_statement: ` case when  ${date_column} between ':nf_lylq_start' and ':nf_lylq_end' `, auto_criteria: ` (${date_column} between ':nf_lylq_start' and ':nf_lylq_end') ` },
                lylmtd: { title: 'LYLMTD', case_statement: ` case when  ${date_column} between ':nf_lylm_start' and ':nf_lylm_end' `, auto_criteria: ` (${date_column} between ':nf_lylm_start' and ':nf_lylm_end') ` },
                lylftd: { title: 'LYLFTD', case_statement: ` case when  ${date_column} between ':nf_lyld_start' and ':nf_lyld_end' `, auto_criteria: ` (${date_column} between ':nf_lyld_start' and ':nf_lyld_end') ` },



                /** following ones have to be fixed for calculation of last month */
                'cm': {
                    title: 'Current Month', case_statement: `case when Year(${date_column}) =  YEAR(':nf_date') AND MONTH(${date_column}) = MONTH(':nf_date') `,
                    auto_criteria: ` (
                        MONTH(${date_column}) = :nf_current_month AND YEAR(${date_column}) = :nf_year 
                        )`

                },

                'lm': {
                    title: 'Last Month', case_statement: `CASE WHEN MONTH(':nf_date') > 1 THEN ( CASE WHEN (YEAR(${date_column}) = YEAR(':nf_date') AND MONTH(${date_column}) = (MONTH(':nf_date') - 1)) THEN ${calculation_column} ELSE 0 END ) WHEN MONTH(':nf_date') = 1 THEN ( CASE WHEN (YEAR(${date_column}) = (YEAR(':nf_date') - 1) AND MONTH(${date_column}) = 12) THEN ${calculation_column} ELSE 0 END ) WHEN 1 > 2 `,
                    auto_criteria: ` (
                        (MONTH(${date_column}) = (:nf_current_month - 1) AND :nf_current_month > 1 AND YEAR(${date_column}) = :nf_year)
                        OR
                        (MONTH(${date_column}) = 12  AND :nf_current_month = 1 AND YEAR(${date_column}) = (:nf_year - 1) )
                        )`
                },

                'cy': {
                    title: 'Current Year', case_statement: ` CASE WHEN ((YEAR(${date_column}) = (Year(':nf_date')) AND MONTH(${date_column}) > 3) OR ((YEAR(${date_column}) = (Year(':nf_date') + 1) AND MONTH(${date_column}) < 4)))  `,
                    auto_criteria: ` (
                        (MONTH(${date_column}) > 3 AND YEAR(${date_column}) = :nf_fy)
                        OR
                        (MONTH(${date_column}) < 4 AND YEAR(${date_column}) = (:nf_fy + 1))
                        )`

                },
                'ly': {
                    title: 'Last Year', case_statement: ` CASE WHEN ((YEAR(${date_column}) = (Year(':nf_date') - 1) AND MONTH(${date_column}) > 3) OR ((YEAR(${date_column}) = (Year(':nf_date')) AND MONTH(${date_column}) < 4)))  `,
                    auto_criteria: ` (
                        (MONTH(${date_column}) > 3 AND YEAR(${date_column}) = (:nf_fy - 1))
                        OR
                        (MONTH(${date_column}) < 4 AND YEAR(${date_column}) = (:nf_fy))
                        )`
                },
                'lmftd': { title: 'LMFTD', title: '', case_statement: `case when (Date(${date_column}) =  ':nf_lmftd') ` },
                'lyftd': { title: 'LYFTD', title: '', case_statement: `case when (Date(${date_column}) =  ':nf_lyftd') ` },
            },
        },
    };


    const formula_suffix = `then ${calculation_column} else 0 end `;
    const year_type = process.env.YEAR_FORMAT === 'calendar' ? 'calendar_year' : 'financial_year';

    // const final_formula = formulas_prefixes[db_type][to_date_category] + ' ' + formula_suffix + ' as ' + (alias || to_date_category);
    const returning_object = formulas_prefixes[db_type][year_type][to_date_category];

    // console.log('to+date category: ', to_date_category, formulas_prefixes[db_type][year_type][to_date_category]['case_statement'], formula_suffix);

    return {
        ...returning_object,
        case_statement: formulas_prefixes[db_type][year_type][to_date_category]['case_statement'] + ' ' + formula_suffix
    };
};


export const ytd_mtd_options = [
    { value: 'ytd', label: 'YTD', },
    { value: 'fytd', label: 'FYTD', },
    { value: 'lytd', label: 'LYTD', },
    { value: 'lfytd', label: 'LFYTD', },
    { value: 'mtd', label: 'MTD', },
    { value: 'lmtd', label: 'LMTD', },
    { value: 'lymtd', label: 'LYMTD', },
    { value: 'lylmtd', label: 'Last Year Last Month To Date', },
    { value: 'ftd', label: 'FTD', },
    { value: 'qtd', label: 'QTD', },
    { value: 'lyqtd', label: 'LYQTD', },
    { value: 'lqtd', label: 'LQTD', },
    { value: 'lylqtd', label: 'LY-LQTD', },
    { value: 'cm', label: 'Current Month', },
    { value: 'lm', label: 'Last Month', },
    { value: 'ly', label: 'Last Year', },
    { value: 'cy', label: 'Current Year', },
    { value: 'lmftd', label: 'LMFTD', },
    { value: 'lyftd', label: 'LYFTD', },

]



export const convert_display_fields_into_table_column_for_ds_v1 = (reports, report_name) => {

    const _tbl_columns = [];
    let column_info = [];
    reports && reports.length > 0 && reports.forEach((report_item) => {
        const column_i = report_item && report_item.column_infos ? report_item.column_infos : [];
        column_info = [...column_info, ...column_i]
    })


    const find_hint_in_hints_array = (hint) => {
        for (let index = 0; index < _tbl_columns.length; index++) {
            const element = _tbl_columns[index];
            if (element.column_name === hint.orignal_name) {
                return true;
            }
        }
    }

    column_info && column_info.length > 0 && column_info.map((f) => {
        if (!find_hint_in_hints_array(f)) {
            _tbl_columns.push({
                alias: f.alias,
                column_name: f.orignal_name,
                table_name: report_name,
                data_type: f.data_type
            })
        }
    })

    return _tbl_columns

}