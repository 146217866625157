import { simpleDispatch_get, simpleDispatch_post, dispatchAction, raiseSuccess } from './helper';
import * as actionTypes from '../action-types/group_access';
import { constants } from '../utils/constants';
import * as loaderTypes from '../action-types/loader';
import { post } from '../data.service';



export const getAccessGroup = () => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.ACCESS_GROUP.POINT + constants.END_POINTS.ACCESS_GROUP.GET_ACCESS_GROUP;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_ACCESS_GROUP);

};


export const getAccessGroupWithUsers = () => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.ACCESS_GROUP.POINT + constants.END_POINTS.ACCESS_GROUP.GET_ACCESS_GROUP_WITH_USERS;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_ACCESS_GROUP_WITH_USERS);

};

export const getGroupAccess = (id) => async dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_GROUP_ACCESS_GROUP)
    const _post = {
        access_group_id: id,
    }
    const url = constants.END_POINTS.API + constants.END_POINTS.ACCESS_GROUP.POINT + constants.END_POINTS.ACCESS_GROUP.GET_GROUP_ACCESS;
    await simpleDispatch_post(dispatch, url, _post, actionTypes.GET_GROUP_ACCESS_GROUP);
};






export const clearGroupAccess = (id) => async dispatch => {
    await dispatchAction(dispatch, actionTypes.CLEAR_GROUP_ACCESS_GROUP)
};



export const saveGroupAccess = (data) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.ACCESS_GROUP.POINT + constants.END_POINTS.ACCESS_GROUP.SAVE_GROUP_ACCESS;

    try {
        const response = await post(url, data);
        if (response && response.code === 200) {
            raiseSuccess(dispatch, {
                type: 'success',
                message: 'Saved Successfully'
            })
            dispatchAction(dispatch, actionTypes.SAVE_GROUP_ACCESS, undefined, undefined, undefined, 0);
            dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        }

    } catch (error) {
        raiseSuccess(dispatch, {
            type: 'error',
            title: 'ERROR',
            message: error && error.message ? error.message : 'Somthing Went wrong..'
        })
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
    }
};



export const getUserList = () => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.ACCESS_GROUP.POINT + constants.END_POINTS.ACCESS_GROUP.GET_USER_LIST;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_USER_LIST_SUCESS);
};



/****
 * 
 * 
 * 
 */





export const get_group_access_for_menu = () => {

}

export const save_menu_group_access = () => {

}


export const get_access_group_info = (id) => async dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_GROUP_ACCESS_GROUP)
    const _post = {
        access_group_id: id,
    }
    const url = constants.END_POINTS.API + constants.END_POINTS.ACCESS_GROUP.POINT + constants.END_POINTS.ACCESS_GROUP.GET_ACCESS_GROUP_INFO;
    await simpleDispatch_post(dispatch, url, _post, actionTypes.GET_ACCESS_GROUP_INFO);
};





export const save_access_group_info = (data) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.ACCESS_GROUP.POINT + constants.END_POINTS.ACCESS_GROUP.SAVE_ACCESS_GROUP_INFO;

    try {
        const response = await post(url, data);
        if (response && response.code === 200) {
            raiseSuccess(dispatch, {
                type: 'success',
                message: 'Saved Successfully'
            })
            dispatchAction(dispatch, actionTypes.SAVE_ACCESS_GROUP_INFO, undefined, undefined, undefined, 0);
            dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        }

    } catch (error) {
        raiseSuccess(dispatch, {
            type: 'error',
            title: 'ERROR',
            message: error && error.message ? error.message : 'Somthing Went wrong..'
        })
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
    }
};












/// DATA POLICY 


export const getAllDataPolicy = () => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.ACCESS_GROUP.POINT + constants.END_POINTS.ACCESS_GROUP.GET_ALL_DATA_POLICY;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_ALL_DATA_POLICY_SUCCESS);

};

export const getDataPolicyById = (id) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.ACCESS_GROUP.POINT + constants.END_POINTS.ACCESS_GROUP.GET_POLICY_BY_ID + "?id=" + id;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_DATA_POLICY_BY_ID_SUCCESS);
};


export const saveDataPolicy = (data) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.ACCESS_GROUP.POINT + constants.END_POINTS.ACCESS_GROUP.SAVE_DATA_POLICY;

    try {
        const response = await post(url, data);
        if (response && response.code === 200) {
            raiseSuccess(dispatch, {
                type: 'success',
                message: 'Saved Successfully'
            })
            dispatchAction(dispatch, actionTypes.SAVE_DATA_POLICY, undefined, undefined, undefined, 0);
            dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
            getAllDataPolicy()(dispatch)
        }

    } catch (error) {
        raiseSuccess(dispatch, {
            type: 'error',
            title: 'ERROR',
            message: error && error.message ? error.message : 'Somthing Went wrong..'
        })
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
    }
};


