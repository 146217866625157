export const CONNECTION_INIT = 'modules/connection/init';
export const SAVE_CONNECTION = 'modules/connection/save_connection';
export const TEST_CONNECTION_SUCCESS = 'modules/connection/test_connection_success';
export const TEST_CONNECTION_FAILED = 'modules/connection/test_connection_failed';
export const GET_DB_INFO_SUCCESS = 'modules/connection/get_db_info_succcess'
export const CLEAR_DB_INFO_SUCCESS = 'modules/connection/clear_db_info_'
export const GET_DB_OF_TABLE_SUCCESS = 'modules/connection/get_db_of_table_success';
export const CLEAR_DB_OF_TABLE_SUCCESS = 'modules/connection/clear_db_of_table'
export const GET_DB_OF_TABLE_DATA_SUCCESS = 'modules/connection/get_db_of_data_success';
export const CLEAR_DB_OF_TABLE_DATA = 'modules/connection/clear_of_db_table_data';
export const GET_TABLE_AND_COLUMNS_OF_DB = 'modules/connection/get_table_and_columns_of_db';
export const CLEAR_TABLE_AND_COLUMNS_OF_DB = 'modules/connection/clear_table_and_columns_';
export const GET_SQL_QUERY_SUCCESS = 'modules/connection/get_sql_query_success';