import styled from 'styled-components'

export const TabWrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    box-shadow: ${props => props.hideShadow ? undefined : '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'};
    width: auto;
    
    ${props => props.overTabSelection ? 
        ({ 
            width: '100%',
            'justify-content': 'center',
            'margin-bottom': '24px',
            'margin-top': '17px',
            background: props.bgColor ? props.bgColor : '#e1e1e173',
            'box-shadow': 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'
        })
        : props.cutOutSelection ?  ({ 
            width: '100%',
            'margin-bottom': '0px',
            'margin-top': '17px',
            height:'4rem',
            'padding-top': '1rem',
            'padding-left': '1rem',
           "box-shadow":  "0px 11px -1px 2px rgba(240, 240, 240, 0.9)",
            background: props.bgColor ? props.bgColor : '#e1e1e173',
        }) 
        : 
        'border-bottom: 1px solid #eaeaea;'
    }

/* "box-shadow": "0px 2px 4px 4px rgba(240,240,240,0.7);", */
    /* width: 100%;
    justify-content: center;
    margin-bottom: 24px;
    margin-top: 17px;
    background: ${props => props.bgColor ? props.bgColor : '#e1e1e173'};
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */

 .ripple {
     margin-right: 30px;
     position: relative;
     overflow: hidden;
     z-index: 1;
     
       &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0;
            height: 0;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            visibility: hidden;
            z-index: 2;

            /* background-color: currentColor; */
       }
     
     &:not(:active):before {
         animation: ripple 1.2s ;
         transition: visibility 1.2s step-end;
         
     }
     
     &:active:before {
         visibility: visible;
     }
 }

`;



export const Tab = styled.div`
        width: auto;
        font-size: 1.1rem;
        font-weight: bold;
        line-height: 1.286rem;
        letter-spacing: normal;
        text-align: left;
        color: ${props => props.active && !props.cutOutSelection ? '#1976d2' : '#6f7374'};
        cursor: ${props => props.disable ? "not-allowed" : 'pointer'};
        user-select: none;
        transition: all 0.2s;
        margin-right: 15px;
        padding: .8rem 2rem;
        border-radius:${props => props.cutOutSelection ? '18px 18px 0 0' : '3px 3px 0px 0px' };
        position: relative;
        text-transform: ${props => props.upper_case ? 'uppercase' : 'capitalize'};

        .ripple{
            border-radius: 50%;
            background: #1082f336;
            position: absolute;
            top: 0px;
            left: -12px;
            bottom: 0px;
            right: 0px;
            margin: auto;
            width: 0px;
            height: 0px;
            transition: ${props => props.mode === "none" ? 'all 1s' : undefined};
            opacity: ${props => props.mode === "none" ? 1 : 0};
            
        }


`;

export const Indicator = styled.div`
        position: absolute;
        bottom: 0px;
        left: ${props => props.left + "px" || '0px'};
        width: ${props => props.width + "px" || '0px'};
        transition: all 0.5s ;
        height: 100% ;
        scroll-behavior: smooth;
        
        ${props => props.overTabSelection ? 
            ({
                'background': '#fff',
                'box-shadow': 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                'border-radius': '6px' 
            })
            : props.cutOutSelection ? ({
                'background': '#fff',
                'border-radius': '10px 10px 0px -0px' ,
                top: '1.2rem',
                height: '80%',
                "box-shadow": "0px 2px 2px 0px #dedede inset",
            }) 
            : 'border-bottom: 2px solid #1976d2'
        }
/* 
        background: ${props => props.color || 'red'};
        background:#fff;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 6px; */
        


`;
