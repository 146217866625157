import * as actions from '../action-types/db.formula'


const initialState = {

    type: actions.DB_FORMULA_INIT,
    editedFormula: undefined,
    dbFormulas: undefined,

};


const dbFormula = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {

        case actions.GET_ALL_DB_FORMULA:
            return {
                ...state,
                type: action.type,
                dbFormulas: action?.data?.nf_main_db_formula
            }

        case actions.SAVE_DB_FORMULA:
            return {
                ...state,
                type: action.type,
            }


        case actions.GET_DB_FORMULA_BY_ID:
            return {
                ...state,
                type: action.type,
                editedFormula: action?.data?.nf_main_db_formula

            }
        case actions.CLEAR_EDITED_DB_FORMULA:

            return {
                ...state,
                type: action.type,
                editedFormula: undefined,
            }
        default:
            return {
                ...state,
            }
    }
}

export default dbFormula;