import React, { useEffect, useRef, useState } from 'react';
import TypingEffect from '../helper/typingEffect';

const LinkComponent = ({ response, links }) => {

    const [currentIndex, setCurrentIndex] = useState(0);

    const onTypingComplete = () => {
        setCurrentIndex(prev => prev + 1);
    };
    
    const handleClick = (e, d) => {
        e.stopPropagation();
        setTimeout(() => {
            window.open(`/?insightId=${d?.id}`, '_blank');
        }, 500)
    };


    return (
        <div>
            {/* <p>{response?.title || 'Here are your dashboards'}</p> */}
            <ul>
                {
                    links?.map((d, index) => (
                        <>
                            {index <= currentIndex &&
                                // <li onClick={(e) => {
                                //     e.stopPropagation();
                                //     d?.id &&
                                //     response?.isLink &&
                                //     setTimeout(() => {
                                //         window.open(`/?insightId=${d?.id}`, '_blank');
                                //     }, 500)
                                // }}
                                <li onClick={(e) => handleClick(e, d)}
                                >
                                    <div style={{
                                        textDecoration: response?.isLink ? 'underline' : 'none',
                                        marginTop: '0.5rem',
                                        color: 'black',
                                        fontWeight: '300',
                                        display: 'flex',
                                        fontSize: '.9rem',
                                        cursor: response?.isLink && 'pointer'
                                    }}>
                                        <TypingEffect key={"_nfx_key_" + index} text={d?.title} onComplete={index === currentIndex && onTypingComplete} />
                                    </div>
                                </li>
                            }
                        </>
                    ))
                }
            </ul>
        </div>
    )
}
export default LinkComponent





