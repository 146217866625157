import React from "react";

const CreateBusinessRule = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1680 2048"
    >
      <path transform="translate(31)" d="m0 0h1171l7 6 12 9 16 15 11 11 8 7 12 12 8 7 17 16 42 42 8 7 13 12 38 38 8 7 33 31 33 33 8 7 17 16 38 38 8 7 9 9 8 7 19 19 8 7 27 27 8 7 15 14 18 18 7 8 9 12 4 13 1 5v1516l-1 18-3 14-9 15-7 8-12 4-3 2-34 1-107 1-175 1h-801l-431-1-40-1-15-1-10-6-13-12-9-14-1-4-1-31v-1896l1-36 1-11 6-12 10-10 11-9zm301 95-232 1-1 8v1845h1472l1-1v-1429l-96-1h-292l-14-1-8-3-8-6-9-9-4-6-4-12-1-9-1-39v-336l-1-1-349-1zm900 68v259l267 1-6-8-60-60-8-7-10-10-8-7-33-33-8-7-13-12-10-9-32-32-8-7-10-10-8-7-10-10-8-7-34-34z" fill="#07325B" />
      <path transform="translate(407,397)" d="m0 0h179l160 1 2 3v193l-3 2h-400l-5-1-1-4v-174l1-19z" fill="#05315A" />
      <path transform="translate(362,1071)" d="m0 0h961l16 2 7 4 7 7 5 9 2 12-1 12-5 10-8 8-8 4-13 1h-973l-6-3-8-9-7-14-1-3v-11l3-9 7-9 8-7 6-3z" fill="#05315A" />
      <path transform="translate(368,1464)" d="m0 0h949l15 1 11 4 10 9 5 8 2 7v11l-3 10-9 13-6 4-4 1-46 1h-930l-12-1-6-3-7-9-7-15-1-8 3-10 5-8 5-6 10-6 5-2z" fill="#05315A" />
      <path transform="translate(357,678)" d="m0 0h74l298 1h368l197-1 41 1 8 3 8 7 6 8 3 11v9l-3 10-9 13-5 4-7 2-11 1h-970l-7-3-8-7-7-11-3-7v-13l6-12 9-10 8-5z" fill="#06325A" />
      <path transform="translate(354,1210)" d="m0 0h515l16 2 9 6 7 9 3 9v16l-3 8-8 10-8 6-6 2-10 1h-514l-10-5-9-10-5-12-1-4v-7l3-10 6-10 7-8z" fill="#05315A" />
      <path transform="translate(369,815)" d="m0 0h489l17 1 10 3 10 7 7 10 2 8v13l-4 11-7 9-5 5-3 1-37 1h-490l-11-2-8-7-8-16-1-3v-13l5-10 9-10 9-6 3-1z" fill="#07325B" />
      <path transform="translate(355,1603)" d="m0 0h509l16 1 8 3 10 9 5 10 1 5v13l-4 11-8 10-5 4-9 2-23 1h-492l-12-2-6-4-7-7-8-16v-11l5-12 11-12 6-4z" fill="#06315A" />
      <path transform="translate(34,2045)" d="m0 0h2v2h-2z" fill="#FDFDFD" />
      <path transform="translate(1634,2045)" d="m0 0" fill="#FDFDFD" />
    </svg>
  );
};

export default CreateBusinessRule;
