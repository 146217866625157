
export const ETL_INIT = 'modules/etl/init';
export const SAVE_ETL_CONFIG = 'modules/etl/save-config';
export const GET_ALL_INFO_OF_QUESTION = 'modules/etl/GET_ALL_INFO_OF_QUESTION'
export const CLEAR_TAGET_AUTO_SUGGESTIONS = 'modules/etl/clear/target_auto_suggestions'
export const GET_CONFIG_REPORTS = 'modules/etl/get_config_reports'
export const DELETE_CONFIG_SUCCESS = 'modules/etl/delete-config-success'
export const GET_CONFIG_INFO_SUCCESS = 'modules/etl/get_config_info_suceess'
export const JOB_LOG_INFO_SUCCESS = 'modules/etl/job-log-info-sucees'
export const MT_JOB_LOG_INFO_SUCCESS = 'modules/etl/job-mt_job_log'
export const PUSH_EDITED_ROW_TO_STORE = 'modules/etl/push_edited_row_to_store_row';
export const UPDATE_MULTIPAL_CONFIG = 'modules/etl/update_multipal'
export const GET_TABLE_DATA_OF_EXTRACTED_COLUMN = 'modules/etl/GET_TABLE_DATA_OF_EXTRACTED_COLUMN'
export const CLEAR_TABLE_EXTRACTED_DATA = 'modules/etl/clear_table_date'
export const CLEAR_JOB_INFO = 'modules/etl/CLEAR_JOB_INFO';
export const CLEAR_QUESTION_INFO = 'modules/etl/CLEAR_QUESTION_INFO';
export const GET_TARGET_TABLE_NAMES = 'modules/etl/GET_TARGET_TABLE_NAMES';
export const GET_TARGET_COLUMN_NAMES = 'modules/etl/GET_TARGET_COLUMN_NAMES';
export const GET_TARGET_TABLE_DATA = 'modules/etl/GET_TARGET_TABLE_DATA';
export const CLEAR_TARGET_TABLE_DATA = 'modules/etl/CLEAR_TARGET_TABLE_DATA';
export const OPEN_TABLE_VIEW_DATA_POPUP = 'modules/etl/OPEN_TABLE_VIEW_DATA_POPUP';
export const VALIDATE_THE_ETL_DATA = 'modules/etl/VALIDATE_THE_ETL_DATA';
export const GET_COLLECTIONS_FROM_MONGO_BY_CLIENT = 'modules/etl/GET_COLLECTIONS_FROM_MONGO_BY_CLIENT';
export const GET_COLLECTIONS_FROM_MONGO_BY_SOURCE_PATH = 'modules/etl/GET_COLLECTIONS/source_path';
export const GET_THE_PATH_IN_FOLDER_SUCCESS = 'modules/etl/GET_THE_PATH_IN_FOLDER_SUCCESS';
export const GET_THE_FILE_DATA_FROM_FILE_SUCCESS = 'modules/etl/GET_THE_FILE_DATA_FROM_FILE_SUCCESS';
export const PUSH_EDITED_SOURCE_MAPPING_ROW_TO_STORE = 'modules/etl/PUSH_EDITED_SOURCE_MAPPING_ROW_TO_STORE';
export const PUSH_SOURCE_MAPPING_DATA_TO_STORE = 'modules/etl/PUSH_SOURCE_MAPP_DATA_TO_STORE';
export const GET_ALL_FILES_LOGS = 'modules/etl/GET_ALL_FILES_LOGS';
export const DELETE_FILE_LOG = 'modules/etl/DELETE_FILE_LOG'