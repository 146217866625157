import React, { useState, useEffect } from 'react'
import SelectOption from '../../select-option';
import FrequencySelector from '../../frequency-selector';
import TextBox from '../../hoc/textBox';
import { DivRow } from '../../styled-hoc';
import { convertDateInToHtmlDate, getParamByName } from '../../../utils';
import styled from 'styled-components';
import { Button } from '../../hoc/button';
import { connect } from 'react-redux';
import { getTheStreamingService, saveTheStreamingData, getCurrentStreamingJob, clearCurrentStreamingJob } from '../../../actions/streaming';
import RippleEffect from '../../hoc/ripple-effect'
import ViewSvg from '../../svg/view'
import TablePopup from '../../etl/table.popup'
import { showPopup } from '../../../utils';
import * as enums from '../../../utils/enums';


const PopupWrapper = styled.div`
    width: 35rem;
    background: #fff;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 3px;
    border: 5px solid #ccc;
    display: flex;
    flex-direction: column;

    h1{
        text-align: center;
        font-weight: 500;
        color: #222;
        margin-top: 0px;
        margin-bottom: 1rem;
        font-size: 1.7rem;
    }

    .field_set{
        width: 100%;
        padding: 0px;
        border: 0px;
    }



`;


function StreamingEditorPopup(props) {

    const { data, close, popupKey, option, getCurrentStreamingJob, currentStreamingJob, streamingServiceData, getTheStreamingService } = props;
    const { config_id, save_func, serviceId } = data;


    const [formData, setFormData] = useState(undefined)
    const [frequency, setFrequency] = useState({ type: 'stream' });


    useEffect(() => {
        if (config_id) {
            getTheStreamingService()
            getCurrentStreamingJob(config_id)
        }
    }, [config_id])



    const getFrequencyValue = (frequency) => {

        if (frequency.frequency_type === 'minutes') {
            return frequency.minutes
        }
        if (frequency.frequency_type === 'hours') {
            return frequency.hours
        }
        if (frequency.frequency_type === 'days') {
            return frequency.days
        }
        if (frequency.frequency_type === 'week') {
            return frequency.week
        }
        if (frequency.frequency_type === 'month') {
            return frequency.month
        }

    }


    const getFrequencyValueType = (frequency) => {

        if (frequency.frequency_type === 'minutes') {
            return {
                ...frequency,
                minutes: frequency.value
            }
        }
        if (frequency.frequency_type === 'hours') {
            return {
                ...frequency,
                hours: frequency.value
            }
        }
        if (frequency.frequency_type === 'days') {
            return {
                ...frequency,
                days: frequency.value
            }
        }
        if (frequency.frequency_type === 'week') {
            return {
                ...frequency,
                week: frequency.value
            }
        }
        if (frequency.frequency_type === 'month') {
            return {
                ...frequency,
                month: frequency.value
            }
        }

    }

    useEffect(() => {
        if (currentStreamingJob && Object.keys(currentStreamingJob).length > 0) {
            let temp_form_data = {};
            if (currentStreamingJob && currentStreamingJob.frequency && currentStreamingJob.frequency.length > 0) {
                let temp_frequency = currentStreamingJob.frequency[0];
                let temp_frequency_value = getFrequencyValue(temp_frequency)
                temp_frequency.value = temp_frequency_value;
                setFrequency(temp_frequency)
            }
            Object.keys(currentStreamingJob).map((key) => {
                if (key !== 'frequency') {
                    temp_form_data[key] = currentStreamingJob[key];
                }
            })
            setFormData(temp_form_data)
        }
    }, [currentStreamingJob])



    const onFrequencyChangeFunction = (type, value) => {

        let clone_frequency = frequency && JSON.parse(JSON.stringify(frequency));
        if (type === 'value') {
            clone_frequency[type] = value ? value : undefined;
        }
        else {
            clone_frequency.frequency_type = type;
        }
        setFrequency(clone_frequency)

    }



    const onChangeFunction = (value, key) => {
        let clone_formData = formData ? JSON.parse(JSON.stringify(formData)) : {};
        clone_formData[key] = value;
        setFormData(clone_formData)
    }




    return (
        <PopupWrapper>
            <h1>Streaming Job details</h1>
            <div className="flex_row" style={{ marginBottom: '1.5rem' }}>
                <TextBox
                    type="text"
                    label="Service Name"
                    value={formData && formData.name ? formData.name : undefined}
                    onChange={(e) => {
                        onChangeFunction(e.target.value, 'name')
                    }}
                />
            </div>

            {config_id && (
                <div style={{ marginBottom: '1.5rem', width: '100%' }}>
                    <DivRow w="100%">
                        <SelectOption
                            label="Service Type"
                            selections={streamingServiceData ? streamingServiceData : []}
                            onChangeFunc={(value) => {
                                onChangeFunction(value, 'streaming_service_id')
                            }}

                            activeValue={formData && formData['streaming_service_id'] ? ((typeof formData['streaming_service_id'] == 'string') ? parseInt(formData['streaming_service_id']) : formData['streaming_service_id']) : undefined}
                            width="100%"
                            displayKey={"service_name"}
                            valueKey={"id"}
                            lineHeight="2.857rem"
                            border='1px solid #bbd1d9'
                            color='#7c7c7c'
                        />
                    </DivRow>
                </div>
            )}

            <div style={{ marginBottom: '1.5rem', width: '100%', position: 'relative' }}>
                <DivRow w="100%">
                    <TextBox
                        type="text"
                        label="Table Name"
                        value={formData && formData.table_name ? formData.table_name : undefined}
                        onChange={(e) => {
                            onChangeFunction(e.target.value, 'table_name')
                        }}
                    />
                </DivRow>

                <RippleEffect
                    style={{
                        position: 'absolute',
                        right: '.5rem',
                        top: '40%',
                        cursor: 'pointer',
                        zIndex: 2,
                    }}
                    zIndex={1}
                    width={'1rem'}
                    height={'1rem'}
                    title="View Table Data"
                    icon={<ViewSvg size='1rem' height='1rem' />}
                    Click={() => {
                        showPopup(undefined, undefined, enums.default.popupType.element, TablePopup, {
                            tablName: formData && formData.table_name ? formData.table_name : undefined,
                        })
                    }}
                />
            </div>

            {/* <div className="flex_row" style={{ alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: '.5rem' }}>
                <div
                    style={{
                        opacity: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}>
                    <fieldset className="field_set" style={{ width: '100%' }}>
                        <legend className="title_up" style={{ padding: '0px', color: '#0000008a' }}>Please Provide Frequency</legend>
                        <FrequencySelector
                            frequency={frequency}
                            onChange={(value, type, element_type, _id) => {
                                onFrequencyChangeFunction(value, type, element_type, _id)
                            }}
                        />
                    </fieldset>
                </div>
            </div> */}


            <div className="flex_row" style={{ marginBottom: '1.5rem' }}>
                <DivRow w="100%">
                    <label style={{ fontSize: '.8rem', color: '#555', }}>Please Provide Frequency</label>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row', marginTop: '.3rem' }}>
                        <span style={{ padding: '.4rem 1rem', textTransform: 'uppercase', fontWeight: '500', backgroundColor: '#eaeaeacc', fontSize: '.8rem', borderRadius: '3px', }}>Every</span>
                        <div style={{ width: '25%' }}>
                            <input
                                value={frequency && frequency.value ? frequency.value : undefined}
                                style={{ width: '100%', height: '1.9rem', fontSize: '1rem', padding: '.8rem', boxSizing: 'border-box', color: '#000' }}
                                type="text" placeholder="Enter Value"
                                onChange={(e) => {
                                    onFrequencyChangeFunction('value', e.target.value)
                                }}
                            />
                        </div>

                        <div style={{ width: '50%', }}>

                            <select
                                value={frequency && frequency.frequency_type ? frequency.frequency_type : undefined}
                                style={{ width: '100%', height: '1.9rem', borderRadius: '2px', backgroundColor: '#fff' }}
                                onChange={(e) => {
                                    onFrequencyChangeFunction(e.target.value)
                                }}>
                                <option value="">--Select Type--</option>
                                <option value="minutes">Minutes</option>
                                <option value="hours">Hours</option>
                                <option value="days">Days</option>
                                <option value="week">Week</option>
                                <option value="month">Month</option>

                            </select>
                        </div>
                    </div>

                </DivRow>
            </div>




            <div className="flex_row" style={{ marginBottom: '1.5rem' }}>
                <TextBox
                    type="datetime-local"
                    label="Start Date"
                    value={formData && formData.next_run_at ? convertDateInToHtmlDate(formData.next_run_at, 'next_run_at') : undefined}
                    onChange={(e) => {
                        onChangeFunction(e.target.value, 'next_run_at')

                    }}

                />

            </div>

            <div style={{ marginTop: 'auto', marginBottom: '1rem' }}>
                <Button
                    primary={true}
                    width="8rem"
                    mR="16px"
                    onClick={() => {
                        let temp_frequency = getFrequencyValueType(frequency)
                        let data = {
                            streaming_service_id: serviceId,
                            ...formData,
                            frequency: temp_frequency
                        }
                        if(data.name && data.table_name){
                            save_func(data)
                            close(option, popupKey)
                        }else alert("please Provide Job details")
                    }}>Save</Button>
                <Button
                    width="8rem"
                    bgColor={'#e6e6e6'}
                    onClick={() => {
                        close(option, popupKey)
                    }}>Cancel</Button>
            </div>
        </PopupWrapper>
    )
}


const mapStateToProps = (state) => ({
    currentStreamingJob: state.streamingReducer.currentStreamingJob,
    streamingServiceData: state.streamingReducer.streamingServiceData,

})

export default connect(mapStateToProps, { getCurrentStreamingJob, getTheStreamingService })(StreamingEditorPopup)
