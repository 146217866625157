import React, { useEffect, useState } from "react";
import { Treemap, LabelList, Text, Line, Area, ComposedChart, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, Cell, ResponsiveContainer, Legend, Label, LineChart } from 'recharts';
import { constants } from '../../../utils/constants';
import { apply_styles, find_cell_format, formatValueByDataType, getRemToPx, get_required_attributes_for_chart_click_option, remove_repetitive_keys, showPopup, splitByNf, yDataValueFormatter } from "../../../utils";
import { DataFormaterFixed } from "../yaxis-label";
import { CHART_TYPES } from "../../../shared-with-fe/constants";
import enums from "../../../utils/enums";
import ChartFilterPopup from "../../chart-filter-popup";
import ToolTipWrapper from "../tooltip/tool.tip.wrapper";
import ReferenceLineLabel from '../label/referenceline.label';
import RenderLegend from '../legend/index.new';
import RenderLabelForTrendLine from '../label/trendline.label';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomToolTip from '../tooltip/tooltip.custom';
import * as DataTypes from '../../../shared-with-fe/data.types';
import { colors } from "../../../utils/colors";




/***
 * New composed chart
 * writen by - saroj kumar
 * date - 30 July 2022
*/




const BarChartComp = (props) => {

    const {

        yac_chart_types, showDash, colorForYData, yac_axises,
        yDataKeys, paddingOnXaxis, xDataKeys,
        showReferenceLine, valueOfReferenceLine,
        showStacked, heightOfChart, widthOfChart,
        id, insights, isDimension, isDiscovery,
        hide_y_axis, hide_x_axis, hide_y_axis_border, hide_x_axis_border, hide_grid, hide_legend, hide_tooltip,
        hide_x_axis_label, hide_y_axis_label, showRightYAxis, Alignments, chartStyle, drill_down_type,
        drill_down_report, tooltipTheme, drill_down_values, comparisons, show_data_label,
        isReport, chartType, aggregate_options, yac_totals, equations,
        theme_json_values
    } = props;




    const data_label_style = apply_styles(theme_json_values?.data_label, constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT);

    // const chart_colors = theme_json_values?.chart_colors && Object.values(theme_json_values?.chart_colors) ||
    /***
     * State of the component will be here
     */
    const [chartData, setChartData] = useState(undefined);
    const [axis_settings, set_axis_settings] = useState({
        color_preferences: {},
        uniques: {},
        should_custom_color_be_used: false,
        axis_tick_styles: {},
        x_axises: []
    });
    const [active_bar, set_active_bar] = useState(undefined)




    /***
     * All Lifecycle method of the component 
     */


    useEffect(() => {
        // hoist_the_state();
    }, [props.xDataKeys])



    useEffect(() => {
        const prev_data = chartData && JSON.stringify(chartData);
        const upcomping_data = props.data && JSON.stringify(props.data);
        if (prev_data !== upcomping_data) {
            setChartData(props.data)

        }
    }, [props.data])

    const y_key = yDataKeys?.[0];


    const open_chart_options = (event, data_key, payload) => {
        if (process.env.REACT_APP_MODE === "barc") {
            return;
        }
        const options_required_data = get_required_attributes_for_chart_click_option(event, data_key, payload, xDataKeys, props.columnMeta,)

        const { left, top, width, popup_bg } = options_required_data;

        showPopup(undefined, undefined, enums.popupType.element, ChartFilterPopup, {
            ...options_required_data,
            question: props.question,
            drill_down_values: props.drill_down_values,
            column_meta_details: props.columnMeta,
            b_history: props.history,
            _id: props.id,
            insight_id: props.insight_id,
            insights: props.insights,
            containerWidth: width,
            reporting_db_info_id: props.reporting_db_info_id,
            hide_drill_down: !props.is_drill_down_applicable


        },
            top + "px",
            left + "px",
            popup_bg
        )
    }


    const CustomizedContent = (_props) => {

        const { root, depth, x, y, width, height, index, colors, name, value } = _props;

        const k_key = xDataKeys?.[0]
        const label = _props?.[k_key];

        let d_type = Alignments?.[y_key]?.["type"]
        let currency_type = Alignments?.[y_key]?.['currency_type'];
        let num_format_type = Alignments?.[y_key]?.['num_format_type'];
        let use_decimal = Alignments?.[y_key]?.['use_decimal'];
        let use_percent = Alignments?.[y_key]?.['use_percent'];

        const formatted_value = formatValueByDataType(value, d_type, undefined, undefined, undefined, undefined, currency_type, num_format_type, use_decimal, use_percent)


        return (
            <g>
                <rect
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    onClick={(e) => {
                        open_chart_options(e, y_key, _props)

                    }}
                    style={{
                        fill: colors[index] || (depth < 2
                            ? colors[Math.floor((index / root.children.length) * 6)]
                            : "none"),
                        stroke: "#fff",
                        strokeWidth: 2 / (depth + 1e-10),
                        strokeOpacity: 1 / (depth + 1e-10),
                        cursor: 'pointer',
                        fillOpacity: .8
                    }}
                />
                {depth === 1 && !props.hide_x_axis_tick ? (
                    <text
                        x={x + width / 2}
                        y={(y + height / 2 + 7) - 20}
                        textAnchor="middle"
                        fill="#5c0202"
                        fontSize={22}
                        {...data_label_style}

                    >
                        {label?.length > 20 ? label.substring(label, 20) : label}
                    </text>
                ) : null}

                {depth === 1 && !props.hide_y_axis_tick ? (
                    <text
                        x={x + width / 2}
                        y={(y + height / 2 + 7)}
                        textAnchor="middle"
                        fill="#5c0202"
                        fontSize={22}
                        {...data_label_style}
                    >
                        {formatted_value}
                    </text>
                ) : null}

                {depth === 1 ? (
                    <g>
                        <circle cx={x + 14} cy={y + 18} r="12" fill="#fff" />
                        <text x={x + 6} y={y + 22} fill="#222"
                            fontSize={12} fillOpacity={0.9}
                        // {...data_label_style}
                        >
                            {index + 1}
                        </text>
                    </g>

                ) : null}
            </g>
        );
    };




    // const COLORS = [
    //     "#8889DD",
    //     "#9597E4",
    //     "#8DC77B",
    //     "#A5D297",
    //     "#E2CF45",
    //     "#F8C12D"
    // ];

    const COLORS = props.colorForYData || [
        "#fd7f6f",
        "#7eb0d5",
        "#b2e061",
        "#bd7ebe",
        "#ffb55a",
        "#ffee65",
        "#beb9db",
        "#fdcce5",
        "#8bd3c7",
    ];



    return (
        <div>
            {chartData && chartData.length > 0 && (
                <ResponsiveContainer width='100%' height={heightOfChart}>
                    <Treemap
                        dataKey={y_key}
                        data={chartData}
                        content={<CustomizedContent colors={COLORS} />}
                    >
                    </Treemap>
                </ResponsiveContainer>

            )}
        </div>
    );
}





const mapStateToProps = (state, props) => {

    const report_cell_clicked_info_cache = state.reportHelperReducer.report_cell_clicked_info_cache?.[props.insight_id];
    const report_cell_click_info = report_cell_clicked_info_cache?.[props.id];
    const link_filter = state.filterReducer.link_filter_cache?.[props.insight_id]?.[props.id];
    const table_format_setting_cache = state.tableFormatReducer.table_format_setting_cache;
    const tbl_formatter = table_format_setting_cache && table_format_setting_cache[props.id];

    return { report_cell_click_info, link_filter, tbl_formatter }
}

export default withRouter(connect(mapStateToProps, {})(BarChartComp));