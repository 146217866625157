import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { login } from "../../actions/account";
// import { Wrapper, LoginWrapper, FieldWrapper, ButtonWrapper, FieldContent, ImageWrapper, DataWrapper } from './login.styled';
import { MainWrapper, ButtonWrapper, FieldContent } from "./login.new.styled";
import { constants } from "../../utils/constants";
import TextBox from "../hoc/textBox";
import { getParameterByName } from "../../utils";
import { useEffect } from "react";
import { getFromSession, saveInSession } from "../../utils/session.helper";
import PolusLogoHead from "../svg/icons-v2/polusLogoHead";
import Loginpagebg from "../svg/icons-v2/login_page_bg";
import PolusLogoHeadLogo from "../svg/icons-v2/polusLogoHeadLogo";



const clients_config = {
    "bi.sbi.com": {
        logo: "sbi.png",
    },
    "dev.bi.mylabconnect.com/": {
        logo: "mylabconnect.png",
    },
    "dev.bi.mylabconnect.com": {
        logo: "mylabconnect.png",
    },
    "bi.mylabconnect.com": {
        logo: "mylabconnect.png",
    },
    "bi.mylabconnect.com/": {
        logo: "mylabconnect.png",
    },
};

const Login = (props) => {
    const [userName, setUserName] = useState(undefined);
    const [password, setPassword] = useState(undefined);
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };



    const { login, token, reload, history } = props;

    const host = window.location.hostname;

    const client_config = clients_config[host] || undefined;



    useEffect(() => {

        // const login_details = getFromSession("nf_usr_login_detail")
        // console.log("login_details", login_details)

    }, [])


    const save_login_details_into_session = async () => {
        // let _u_name = userName && userName.trim();
        // let p = password && password.trim();

        // const detail = {
        //     user_name: _u_name,
        //     password: p
        // }
        // console.log("detail", detail)
        // saveInSession("nf_usr_login_detail", JSON.parse(JSON.stringify(detail)))
    }


    console.log("window.location.hostname", window.location.hostname);

    const local_login = () => {
        let _u_name = userName && userName.trim();
        let p = password && password.trim();
        login(_u_name, p, reload);
    };

    return (
        <MainWrapper>
            <div className="login_main">
                <div className="login_container">
                    <div className="newfangled_bg_icon">
                        <Loginpagebg height='18rem' size='18rem' />
                    </div>

                    <div className="col_right">

                        {/* <div className="bg_bottom_img">
                            <img src={constants.CONTEXT_PATH + "./login_bg.png"} alt='login bg' />
                        </div> */}
                        <div className="login_form">
                            <div className="newfangled_logos">
                                <PolusLogoHeadLogo height='4rem' size='4rem' />
                                <h1>Sign In With PolusAI</h1>
                                <h6>Unleash the Power with GenAI Platform</h6>
                            </div>


                            <FieldContent autoSave="off">
                                <label>Username</label>
                                <input
                                //  autoComplete="off"
                                    label="Username"
                                    // autoFill={false}
                                    value={userName}
                                    // placeholder="Username"
                                    onChange={(event) => setUserName(event.target.value)}
                                />
                                <div className="users">
                                    <i class="fa fa-user" aria-hidden="true"></i>
                                </div>
                            </FieldContent>

                            <FieldContent>
                                <label>Password</label>
                                <input
                                    label="Password"
                                     autoComplete="off"
                                    // placeholder="Password"
                                    type={showPassword ? 'text' : "password"}
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                    onKeyPress={(event) => {
                                        if (event.key === "Enter") {
                                            login(userName, password, reload);
                                            event.target.blur();
                                        }
                                    }}
                                />
                                <div className="users users_cur" onClick={togglePasswordVisibility}>
                                    <i className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"} aria-hidden="true"></i>
                                </div>
                            </FieldContent>

                            <div className="Remember_Me_main">
                                <div className="checkbox_main">
                                    <span>
                                        <a href="">
                                            <input type="checkbox" onChange={() => {
                                                save_login_details_into_session()

                                            }} />
                                        </a>
                                        <a href="">Keep Remember Me</a>
                                    </span>
                                </div>

                                {/* <div className="Forget_Password">
                                <span>
                                    <a href="">Forget Password?</a>
                                </span>
                            </div> */}

                            </div>
                            <ButtonWrapper
                                onClick={() => {
                                    local_login();
                                }}
                            >
                                Login
                            </ButtonWrapper>
                        </div>
                    </div>
                </div>
            </div>
        </MainWrapper>
    );
};

const mapStateToProps = (state) => {
    const { type, user, token } = state.accountReducer;

    return { type, user, token };
};
export default withRouter(connect(mapStateToProps, { login })(Login));