import styled from 'styled-components';


export const PageInnerWrapper = styled.div`
    width: ${props => props.width ? props.width : '100%'};
    padding: ${props => props.padding ? props.padding : '25px'};
    box-sizing: border-box;
    background: ${props => props.backgroundColor || '#fff' }; 
    box-sizing: border-box;
    /* box-shadow: 0px 4px 4px 0px rgba(186,186,186,.6); */
    overflow: ${props => props.overflow ? props.overflow : 'auto'};
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    height: ${props => props.height ? props.height : 'calc(100vh - 9rem)'};

    &::-webkit-scrollbar {
        background-color:white;
        width:5px;
    }

    &::-webkit-scrollbar-track {
        background-color:#ffffff
    }

    &::-webkit-scrollbar-thumb {
        background-color:#d9d9d9;
        border-radius:10px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: white;
        height: 5px;
    }

`;

