import styled from 'styled-components';

export const Wrapper = styled.div`
    /* width: 85%; */
    /* margin: auto; */

`;


export const SelectionItemRow = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
`;


export const SelectionColumn = styled.div`
    width: 11rem;
    height: 10rem;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    padding: 1rem;
    box-sizing: border-box;
    margin: .5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: 'center';
    flex-direction: column;
    
    &:hover{
        svg{
            fill: #308bc8;
        }
        .name{ 
            color: #308bc8;
        }
    }
    
    .icon_center{
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 1rem;
        justify-content: center;
    }

    .name{
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4rem;
        letter-spacing: normal;
        text-align: center;
        color: #1a1a1a;
        margin: 0px;
        text-overflow: ellipsis;
        text-transform: capitalize;
        overflow: hidden;
        margin-top: auto;
    }

`;