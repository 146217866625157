import { CHART_TYPES } from "../../shared-with-fe/constants"
import { generateUniqueKey } from "../../utils"


const layouts = {
    "design1": {
        "margin": 20,
        "widgets": [
            {
                title: 'Last year revenue',
                question: 'total revenue in last year',
                chart_type: 'single_cell'
            },
            {
                title: 'Last year revenue',
                question: 'total revenue in last year',
                chart_type: 'single_cell'
            }, {
                title: 'Last year revenue',
                question: 'total revenue in last year',
                chart_type: 'single_cell'
            }, {
                title: 'Last year revenue',
                question: 'total revenue in last year',
                chart_type: 'single_cell'
            }, {
                title: 'Last year revenue',
                question: 'total revenue in last year',
                chart_type: 'single_cell'
            }, {
                title: 'Last year revenue',
                question: 'total revenue in last year',
                chart_type: 'single_cell'
            },
            {
                title: 'Revenue 2021',
                question: 'total revenue in 2022',
                chart_type: 'single_cell'
            },
            {
                title: 'Revenue 2020',
                question: 'total revenue in 2021',
                chart_type: 'single_cell'
            },
            {
                title: 'Revenue 2019',
                question: 'total revenue in 2019',
                chart_type: 'single_cell'
            },


            {
                title: 'department wise admission',
                question: 'give me total tbl_admission.admission_id for each department',
                chart_type: 'bar_chart'
            },
            {
                title: 'service wise',
                question: 'give me total revenue and discount for each service',
                chart_type: 'table',
            },
            {
                title: 'top 3 department',
                question: 'top 3 department with hightest revenue',
                chart_type: 'table',
            },
            {
                title: 'top 2 service',
                question: 'top 3 service with hightest revenue',
                chart_type: 'bar_chart'
            },


        ]
    }
}

const get_render_mode_by_widget__type = (type) => {
    if (type === 'table') return "table"
    if (Object.values(CHART_TYPES).indexOf(type) > -1) return "chart"
    if (type === "single_cell") return "chart";
    if (type === "group") return "group";

}

const colorArray = ['#ffff', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
    '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
    '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
    '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
    '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

const assign_every_single_cell_style = (index, type) => {

    let __theme__ = {};

    if (type === "single_cell") {

        __theme__ = {
            "chart_title": {
                "font": {
                    "size": "1rem",
                    "color": "#fff",
                    "weight": "bold",
                    "text_align": "center",
                    "text_transform": "uppercase"
                },
                "size": {
                    "height": "45px"
                }
            },
            "font": {

                "size": "1.7rem",
                "color": "#fff",
                "weight": "bold",
                "line_height": "2rem",
                "text_align": "center"
            },
            "border": {
                "radius": "30px",
                "bottom": {
                    // "color": colorArray[index],
                    "color": colorArray[0],
                    "width": "5px",
                    "style": "solid"
                },
                "top": {
                    "color": colorArray[0],
                    // "color": colorArray[index],
                    "width": "0px",
                    "style": "solid"
                },
                "left": {
                    "color": colorArray[0],
                    // "color": colorArray[index],
                    "width": "0px",
                    "style": "solid"
                },
                "right": {
                    "color": colorArray[0],
                    // "color": colorArray[index],
                    "width": "0px",
                    "style": "solid"
                }
            },
            "colors": {
                "background": "transparent"
            },
            "label": {
                "font": {
                    "color": "#fff",
                    "size": "1rem",
                },
            }
        }
    } else if (type === 'group') {
        __theme__ = {
            "colors": {
                "background": "#673AB7"
            },
            "border": {
                "radius": "10px",
                "bottom": {
                    "style": "solid",
                    "width": "0.25px",
                    "color": "#e2e2e2"
                },
                "top": {
                    "style": "solid",
                    "width": "0.25px",
                    "color": "#e2e2e2"
                },
                "right": {
                    "style": "solid",
                    "width": "0.25px",
                    "color": "#e2e2e2"
                },
                "left": {
                    "style": "solid",
                    "width": "0.25px",
                    "color": "#e2e2e2"
                }
            },
            "z_index": 1
        }
    };
    return __theme__;
};

const assign_dim_info_to_widget = ({ widget, wrapper_width, wrapper_height }) => {

    const top_conv = widget.top ? parseFloat(widget.top / wrapper_height) : 0;
    const left_conv = widget.left ? parseFloat(widget.left / wrapper_width) : 0;
    const width_conv = widget.width ? parseFloat(widget.width / wrapper_width) : 0;
    const height_conv = widget.height ? parseFloat(widget.height / wrapper_height) : 0;

    widget.top_px = widget.top;
    widget.left_px = widget.left;
    widget.width_px = widget.width;
    widget.height_px = widget.height;

    widget.top = top_conv;
    widget.left = left_conv;
    widget.width = width_conv;
    widget.height = height_conv;


}
const assign_required_information_to_widget = (widget, width, height, db_info_id, cache_db_info_id,isEditingAI) => {
    assign_dim_info_to_widget({ widget, wrapper_width: width, wrapper_height: height })
    widget.render_mode = get_render_mode_by_widget__type(widget.chart_type)
    widget.id = widget?.id || generateUniqueKey("el_id")
    widget.is_created = widget?.id ? false : true;
    widget.modified = true;
    widget.is_group = widget.chart_type === 'group' ? true : false;
    widget.report_item_type = "dashboard";
    widget.db_info_id = db_info_id;
    widget.reporting_db_info_id = cache_db_info_id;
    widget.hide_x_axis_label = true
    widget.hide_y_axis_label = true

}

const CONFIG = {
    "cell_margin": 10,
    "single_cell": {
        width: 300,
        height: 130,
        left: 10
    },
    "chart": {
        width: 650,
        height: 450
    },
    "chart_second": {
        width: 1000,
        height: 450
    }
}


// const find_top_left_for_widget = (prev_element, current_element) => {

//     const struct = {
//         top: 10,
//         left: 10
//     }

//     if (prev_element) {
//         if (prev_element.row_index === current_element.row_index) {
//             struct.top = prev_element.top;
//             struct.left = prev_element.width + prev_element.left + CONFIG?.['cell_margin'];
//         }
//         else {
//             struct.top = prev_element.top + prev_element.height + CONFIG?.['cell_margin'];
//             struct.left = 10;
//         }
//     }

//     return struct;
// }

//set top and left position of charts
const find_top_left_for_widget = (prev_element, current_element, singleCellPresent, single_cell_total_height) => {
    const struct = {
        top: 10,
        left: 10
    }
    // debugger
    if (current_element.chart_type === "single_cell") {
        struct.top = !(prev_element?.top + prev_element?.height) ? struct.top : prev_element?.top + prev_element?.height + 2 * CONFIG?.['cell_margin'];
        struct.left = 10;
    } else if (current_element.chart_type !== "single_cell" && current_element.chart_type !== "group") {
        if (!!prev_element && (prev_element?.row_index === current_element?.row_index)) {
            struct.top = prev_element?.top || struct?.top;
            struct.left = prev_element?.width + prev_element?.left + CONFIG?.['cell_margin'];
        } else {
            struct.top = (prev_element?.top + prev_element?.height) ? (prev_element?.top + prev_element?.height) + CONFIG?.['cell_margin'] : struct.top;
            struct.left = (!singleCellPresent || (single_cell_total_height < prev_element?.top + prev_element?.height)) ? struct?.left : CONFIG['single_cell'].width + 3 * CONFIG?.['cell_margin'];
        }
    } else {
        struct.top = (prev_element?.top + prev_element?.height) ? (prev_element?.top + prev_element?.height) + CONFIG?.['cell_margin'] : CONFIG?.['cell_margin'];
        struct.left = !singleCellPresent ? struct?.left : CONFIG['single_cell'].width + CONFIG?.['cell_margin'];

    }

    return struct;
}

const smallWidthIndices = [1, 2, 5, 6, 9, 10, 13, 14, 17, 18, 21];

const assign_width_height_top_left = (widgets, wrapper_width, wrapper_height) => {
    const box_widgets = widgets?.filter((w) => w.chart_type === "group") || [];
    const single_cell_widgets = widgets?.filter((w) => w.chart_type === "single_cell") || [];
    const others_widgets = widgets?.filter((w) => w.chart_type !== "single_cell" && w.chart_type !== "group") || [];

    let single_cell_total_height = 0; // init
    const singleCellCount = single_cell_widgets?.length;
    const otherwigetCount = others_widgets?.length;

    const singleCellPresent = singleCellCount > 0;
    const otherWigetPresent = otherwigetCount > 0;

    let chartHeightClaculated = 0;
    if(otherwigetCount === 1){
        chartHeightClaculated = wrapper_height - 4*CONFIG['cell_margin'];
    }else if ([2, 4, 8].includes(singleCellCount)) {
        chartHeightClaculated = 2 * CONFIG['single_cell'].height + 3 * CONFIG['cell_margin'];
    } else if ([5, 7, 10, 11, 13].includes(singleCellCount)) {
        chartHeightClaculated = 2.5 * CONFIG['single_cell'].height + 3 * CONFIG['cell_margin'];
    } else if ([3, 6, 9, 12, 15, 18, 21, 24, 27, 30].includes(singleCellCount)) {
        chartHeightClaculated = 3 * CONFIG['single_cell'].height + 3 * CONFIG['cell_margin'];
    }else {
        chartHeightClaculated = 3 * CONFIG['single_cell'].height;
    };

    single_cell_widgets.map((w, index) => {
        single_cell_total_height += CONFIG['single_cell'].height;
        let new_index = index + 1;
        w.row_index = new_index;
        w.width = CONFIG['single_cell'].width;
        w.height = CONFIG['single_cell'].height - CONFIG['cell_margin'];
        w.left = CONFIG['single_cell'].left;
        w.top = (CONFIG['single_cell'].height * (index)) + (CONFIG?.['cell_margin'] * (index + 2));
        w.theme_json_values = assign_every_single_cell_style(index, 'single_cell')
    });

    const highest_row_index = single_cell_widgets && JSON.parse(JSON.stringify(single_cell_widgets)).sort((a, b) => {
        return b.row_index - a.row_index
    })?.[0]?.row_index || 0;

    let noOfRowsWIthSingleCell = Math.ceil(singleCellCount / 3);

    if (noOfRowsWIthSingleCell === otherwigetCount) { 
        others_widgets.forEach((w, index) => {
            let new_index = index + 1;
            let row_index = (new_index <= 2) ? 1 : Math.ceil(new_index / 2);
            w.row_index = highest_row_index + row_index;
            w.width = CONFIG['chart'].width + CONFIG['chart_second'].width + 1*CONFIG['cell_margin'];
            w.height = chartHeightClaculated - CONFIG['cell_margin'];
        })
    } else if (noOfRowsWIthSingleCell < otherwigetCount && noOfRowsWIthSingleCell >= (otherwigetCount / 2)) {
        let remainingRow = noOfRowsWIthSingleCell;
        let remainingChart = otherwigetCount;
        others_widgets.forEach((w, index) => {
            let new_index = index + 1;
            let row_index = remainingRow <= remainingChart ? Math.ceil(new_index / 2) : new_index;
            w.row_index = highest_row_index + row_index;
            w.width = remainingRow === remainingChart ? CONFIG['chart'].width + CONFIG['chart_second'].width + CONFIG['cell_margin']  : smallWidthIndices?.includes(index) ? CONFIG['chart'].width : CONFIG['chart_second'].width;
            w.height = chartHeightClaculated;
            remainingRow = noOfRowsWIthSingleCell - row_index;
            remainingChart = remainingChart - 1;
        })
    }else if (noOfRowsWIthSingleCell && noOfRowsWIthSingleCell < otherwigetCount / 2) {
        others_widgets.forEach((w, index) => {
            let new_index = index + 1;
            let row_index = (new_index <= 2) ? 1 : Math.ceil(new_index / 2);
            const additionalWidth = noOfRowsWIthSingleCell < row_index ? CONFIG['single_cell'].width / 2 + CONFIG['cell_margin'] : 0;
            w.row_index = highest_row_index + row_index;
            w.width = smallWidthIndices?.includes(index) ? CONFIG['chart'].width + additionalWidth : CONFIG['chart_second'].width + additionalWidth;
            w.height = chartHeightClaculated;
        })
    } else if (!noOfRowsWIthSingleCell) {
        const additionalWidth = (CONFIG['single_cell'].width + CONFIG['cell_margin'] )/ 2;
        others_widgets.forEach((w, index) => {
            let new_index = index + 1;
            let row_index = (new_index <= 2) ? 1 : Math.ceil(new_index / 2);
            w.row_index = highest_row_index + row_index;
            w.width = smallWidthIndices?.includes(index) ? CONFIG['chart'].width + additionalWidth + CONFIG['cell_margin'] : CONFIG['chart_second'].width + additionalWidth;
            w.height = chartHeightClaculated;
        })
    };
    
    others_widgets.forEach((widget, index) => {
        const dim = find_top_left_for_widget(others_widgets?.[index - 1], widget, singleCellPresent, single_cell_total_height)
        widget.top = dim.top
        widget.left = dim.left;
        widget.width = dim.left < CONFIG['single_cell'].width && !others_widgets?.[index + 1] ? CONFIG['chart'].width + CONFIG['chart_second'].width + CONFIG['single_cell'].width + 3 * CONFIG['cell_margin'] : widget.width; // reassign width if there is no single cell in that line
    })

    //calculate box/group height and width
    let widthOfBox = 0;
    let heightOfBox = 0;
    if (others_widgets?.length > 1) {
        const noOfRows = Math.ceil(others_widgets?.length / 2);
        const calculatedTotalHeight = noOfRows * chartHeightClaculated + (noOfRows + 1) * CONFIG?.['cell_margin']
        heightOfBox =   calculatedTotalHeight > wrapper_height ? calculatedTotalHeight : wrapper_height;
        widthOfBox =  wrapper_width - 1*CONFIG['cell_margin'];
    } else if (others_widgets?.length === 1) {
        widthOfBox = wrapper_width - CONFIG['cell_margin'];
        heightOfBox = wrapper_height;
    }
    if (single_cell_widgets?.length > 0) {
        const noOfSingleCell = single_cell_widgets?.length
        const heightSingleCell = noOfSingleCell * CONFIG["single_cell"].height + (noOfSingleCell + 1) * CONFIG?.['cell_margin'];
        heightOfBox = heightSingleCell > heightOfBox ? heightSingleCell + CONFIG?.['cell_margin'] : heightOfBox;
        widthOfBox = widthOfBox ? widthOfBox : widthOfBox + CONFIG["single_cell"].width + 3 * CONFIG?.['cell_margin'];
    }

    box_widgets.forEach((w, index) => {
        let new_index = index + 1;
        w.row_index = new_index;
        w.width = (singleCellPresent && otherWigetPresent) ? widthOfBox  : widthOfBox;
        w.height = heightOfBox;
        w.theme_json_values = assign_every_single_cell_style(index, 'group');
        w.render_mode = "group";

    })

    const all_widgets = [...box_widgets, ...single_cell_widgets, ...others_widgets];
    return all_widgets;
}

export const get_layout_json_data_v1 = (json_data, wrapper_width_v1, wrapper_height_v1, db_info_id, cache_db_info_id, isEditingAI) => {

    const wrapper_initial_height = (wrapper_height_v1 || 910);

    const wrapper_width = (wrapper_width_v1 || 1806) - 40;
    const final_report_items = json_data ? [...json_data] : [];

    const single_cell_width = (wrapper_width / 6) - 20 //
    const single_cell_height = (wrapper_initial_height / 5) - 10 //

    const wrapper_width_after_single_cell = (wrapper_width || 1806) - single_cell_width - 20;

    const chart_width = (wrapper_width_after_single_cell * 0.4) - 20 //
    const chart_height = (wrapper_initial_height / 1.8) - 20 //

    const second_chart_width = (wrapper_width_after_single_cell * 0.6) - 20

    CONFIG['single_cell']['width'] = single_cell_width;
    CONFIG['single_cell']['height'] = single_cell_height;

    CONFIG['chart']['width'] = chart_width;
    CONFIG['chart_second']['width'] = second_chart_width;

    CONFIG['chart_second']['height'] = chart_height;
    CONFIG['chart_second']['height'] = chart_height;


    const post_report_items = assign_width_height_top_left(final_report_items, wrapper_width, wrapper_initial_height);
    post_report_items.map((reportItem) => {
        assign_required_information_to_widget(reportItem, wrapper_width, wrapper_initial_height, db_info_id, cache_db_info_id, isEditingAI)

    })
    return final_report_items;
}
