import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';
import CloseSvg from '../svg/close';
import { get_errors_records } from '../../actions/data_wrangler';
import { convert_date_to_dd_mm_yyyy_v1 } from '../../utils';





const TableMain = styled.table`
    box-sizing: border-box;
    width: 100% !important;
    margin: auto;
    position: relative;
    border-collapse: collapse;
    td, th{
        font-size: .9rem !important;
        padding: .4rem;
        height: 1.6rem;
        border-spacing: 0px;
        border: 1px solid #ccc;
    }
 
    thead{
        th{
            /* background: #fff; */
            z-index: 1 !important;
            background-color: #2173F7;
            color: #fff;
            text-align: left;
            position: sticky;
            top: 0px;

        }
    }

    tr:hover{
        box-shadow: none !important;
        border: 0px !important;
    }

`;


const Wrapper = styled.div`
    background: #fff;
    width: 70rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 12px;
    overflow: hidden;
    position: relative;

    .table_wrapper{
        width: 100%;
        max-height: 30rem;
        min-height: 25rem;
        overflow: auto;
        &::-webkit-scrollbar {
            width:10px;
            border-radius: 10px;
    }
        &::-webkit-scrollbar-track {
            background-color:#EFEDF9;
            border-radius: 20px;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#C1C1EA;
            border-radius:20px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }
    }

    .header{
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        margin-bottom: 1rem;
        justify-content: center;
        position: relative;

        h1{
            font-size: 1.5rem;
            font-weight: 500;
            margin: 0px;
            font-weight: bold;
            color: #2173F7;

        }

        .close{
            cursor: pointer;
            width: 1rem;
            height: 1rem;
            svg{
                fill: #333;
                
            }
        }
    }
    .close_btn{
        /* margin-top: -32px; */
        cursor: pointer;
        position: absolute;
        right: 1rem;
        top: 5px;
    }

`;




const JobErrorRecordsPopup = (props) => {

    const { db_infos, popupKey, option, data, close } = props;

    const job_occurence_id = data?.job_occurence_id;
    const job_id = data?.job_id;



    const { job_error_records_cache } = props;

    const [table_data, set_table_data] = useState(undefined);

    const error_records = job_error_records_cache?.[job_occurence_id];


    useEffect(() => {

        (job_id && job_occurence_id) && props.get_errors_records(job_id, job_occurence_id);

    }, [job_id, job_occurence_id])


    useEffect(() => {
        set_table_data(error_records);
    }, [error_records]);

    console.log("job_error_records_cache", job_error_records_cache)

    return (
        <Wrapper>
            <div className='header'>
                <h1>Error Records</h1>
                <div title="Close" className='close_btn' onClick={() => {
                    close(option, popupKey)
                }}>
                    <CloseSvg size="1.1rem" />
                </div>
            </div>


            <div className='table_wrapper'>
                {error_records && table_data?.length > 0 ? (
                    <TableMain>
                        <thead>
                            <tr>
                                <th>#Id</th>
                                <th>exception</th>
                                <th>exception stack</th>
                                <th>json record</th>
                            </tr>
                        </thead>
                        <tbody>
                            {table_data?.map((row, index) => {
                                return (
                                    <tr >
                                        <td>{row.id}</td>
                                        <td>{row.exception}</td>
                                        <td>{row.exception_stack}</td>
                                        <td>{row.json_record}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </TableMain >
                ) : <p style={{ textAlign: 'center' }}>No Error Records....</p>}
            </div>
        </Wrapper>
    )
}

const mapStateToProps = (state) => ({
    job_error_records_cache: state.dataWrangler.job_error_records_cache
})


export default connect(mapStateToProps, { get_errors_records })(JobErrorRecordsPopup);
