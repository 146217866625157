import React from "react";

const ShowMenuIcon = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      preserveAspectRatio="xMidYMid meet"
    >
      <path d="M4 18H10" stroke="#000000" stroke-width="2" stroke-linecap="round" />
      <path d="M4 12L16 12" stroke="#000000" stroke-width="2" stroke-linecap="round" />
      <path d="M4 6L20 6" stroke="#000000" stroke-width="2" stroke-linecap="round" />
    </svg>);
};

export default ShowMenuIcon;
