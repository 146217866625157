import * as actions from '../action-types/gaugeChart';

const initialState = {
    gaugeDataRangesCache: undefined,
};


const gaugeReducer = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        case actions.GET_GAUGE_RANGE_DATA_LIST:

            const cache_gaugeDataRangesCache = state?.gaugeDataRangesCache ? { ...state?.gaugeDataRangesCache } : {};
            const data = action?.data?.data;
            const widget_id = action?.data?.id;
            if (!cache_gaugeDataRangesCache[widget_id]) {
                cache_gaugeDataRangesCache[widget_id] = [];
            }

            cache_gaugeDataRangesCache[widget_id] = data;

            return {
                ...state,
                type: action.type,
                gaugeDataRangesCache: cache_gaugeDataRangesCache
            }
        // case actions.SAVE_GAUGE_DATA_REQUESTED  :
        //     return {
        //         ...state,
        //         type: action.type,
        //         gaugeDataRangesCache: action?.data?.data
        //     }
        default:
            return {
                ...state,
                type: state.type,
            };
    }
};

export default gaugeReducer;