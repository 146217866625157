import React from 'react'
import styled from 'styled-components';
import { constants } from '../../utils/constants';

import { themes } from '../../theme/theme.config';
import { getSelectedTheme } from '../../utils';

const selected = getSelectedTheme();

const { connectStyle } = themes[selected];



const Row = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
`;


const Column = styled.div`
    width: 150px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 3rem;
    border-radius: 10px;
    border:1px solid #ccc;
    // box-shadow: 0px 2px 6px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12); 
    background:${props => props.active ? connectStyle.color.iconBoxActive : undefined};
    background:#fff;
    

    &:hover{
        background:${connectStyle.color.iconBoxHover};
        background:#fff;
        box-shadow: 0px 2px 6px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12); 
        

    }
    .icon{
       width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content:center;
        background: ${connectStyle.color.iconBg};
        overflow: hidden;
        background: #fff;
        padding: 7px;
        border-radius: 15px;
        // box-shadow: 0px 2px 6px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */

        img{
            max-width: 100%;
        }
    }
    p{
        margin: 0px;
        font-size: .8rem;
        margin-top: 10px;
        text-transform: uppercase;
        font-weight:600;
    }
`;



const TabContent = (props) => {

    const { dbType, onClickDbType, db_infos, addNewConnection, activeType, history } = props;


    const DbClicked = (name) => {
        let filterd = db_infos && db_infos.length > 0 && db_infos.filter((data, i) => {
            if (data.db_type === name) {
                return data
            }
        })
        if ((filterd && filterd.length === 0) || (!filterd)) {
            addNewConnection(name)
        }
    }

    return (
        <div>
            {activeType === "RDBMS" && (
                <Row>
                    <Column
                        active={dbType === "postgress"}
                        onClick={() => {
                            DbClicked("postgress")
                            onClickDbType("postgress")
                        }}>
                        <div className="icon">
                            <img style={{ width: '90%', backgroundColor: '#cdedfc', padding: '12px' }} src={constants.CONTEXT_PATH + './db_icon_v2/postgres.png'}></img>
                        </div>
                        <p>Postgress</p>
                    </Column>

                    <Column
                        active={dbType === "mysql"}
                        onClick={() => {
                            DbClicked("mysql")
                            onClickDbType("mysql")
                        }}>

                        <div className="icon">
                            <img style={{ width: '100%', backgroundColor: '#f9bf8c', padding: '12px' }} src={constants.CONTEXT_PATH + './db_icon_v2/my_sql.png'}></img>
                        </div>
                        <p>mysql</p>
                    </Column>

                    <Column
                        active={dbType === "sqlServer"}
                        onClick={() => {
                            DbClicked("sqlServer")
                            onClickDbType("sqlServer")
                        }}>

                        <div className="icon">
                            <img style={{ width: '100%', backgroundColor: '#fcebee', padding: '12px' }} src={constants.CONTEXT_PATH + './db_icon_v2/sql_server.png'}></img>
                        </div>
                        <p>sql server</p>
                    </Column>


                    <Column
                        active={dbType === "db2"}
                        onClick={() => {
                            DbClicked("db2")
                            onClickDbType("db2")
                        }}>

                        <div className="icon">
                            <img style={{ width: '100%', backgroundColor: '#c9fcd3', padding: '12px' }} src={constants.CONTEXT_PATH + './db_icon_v2/db__2.png'}></img>
                        </div>
                        <p>DB2</p>
                    </Column>

                    <Column
                        active={dbType === "azure_synapse"}
                        onClick={() => {
                            DbClicked("azure_synapse")
                            onClickDbType("azure_synapse")
                        }}>

                        <div className="icon">
                            <img style={{ width: '100%', backgroundColor: '#b4f5fc', padding: '12px' }} src={constants.CONTEXT_PATH + './db_icon_v2/azure.png'}></img>
                        </div>
                        <p>azure synapse</p>
                    </Column>


                    <Column
                        active={dbType === "snowFlake"}
                        onClick={() => {
                            DbClicked("snowFlake")
                            onClickDbType("snowFlake")
                        }}>

                        <div className="icon">
                            <img style={{ width: '100%', backgroundColor: '#b4f5fc', padding: '12px' }} src={constants.CONTEXT_PATH + './db_icon_v2/snow_flake.png'}></img>
                        </div>
                        <p>SnowFlake</p>
                    </Column>


                    <Column
                        active={dbType === "big_query"}
                        onClick={() => {
                            DbClicked("big_query")
                            onClickDbType("big_query")
                        }}>

                        <div className="icon">
                            <img style={{ width: '80%', backgroundColor: '#ddf4fc', padding: '12px' }} src={constants.CONTEXT_PATH + './db_icon_v2/big_query.png'}></img>
                        </div>
                        <p>Big Query</p>
                    </Column>





                    <Column
                        active={dbType === "oracle"}
                        onClick={() => {
                            DbClicked("oracle")
                            onClickDbType("oracle")
                        }}
                    >
                        <div className="icon">
                            <img style={{ width: '100%', backgroundColor: '#ffe1e1', padding: '12px' }} src={constants.CONTEXT_PATH + './db_icon_v2/oracle.png'}></img>
                        </div>
                        <p>oracle</p>
                    </Column>

                    <Column
                        active={dbType === "mariadb"}
                        onClick={() => {
                            DbClicked("mariadb")
                            onClickDbType("mariadb")
                        }}
                    >
                        <div className="icon">
                            <img style={{ width: '100%', backgroundColor: '#f9edea', padding: '12px' }} src={constants.CONTEXT_PATH + './db_icon_v2/maria.png'}></img>
                        </div>
                        <p>mariadb</p>
                    </Column>

                    <Column
                        active={dbType === "sqlite"}
                        onClick={() => {
                            DbClicked("sqlite")
                            onClickDbType("sqlite")
                        }}
                    >
                        <div className="icon">
                            <img style={{ width: '100%', backgroundColor: '#def4fc', padding: '12px' }} src={constants.CONTEXT_PATH + './db_icon_v2/sql_lite.png'}></img>
                        </div>
                        <p>sqlite</p>
                    </Column>

                </Row>
            )}


            {activeType === "HDFS" && (
                <Column
                    active={dbType === "hive"}
                    onClick={() => {
                        DbClicked("hive")
                        onClickDbType("hive")
                    }}>

                    <div className="icon">
                        <img style={{ width: '100%', backgroundColor: '#fcf5c3', padding: '12px' }} src={constants.CONTEXT_PATH + './db_icon_v2/hive.png'}></img>
                    </div>
                    <p>HIVE</p>
                </Column>
            )}


            {activeType === "No-SQL" && (
                <Row>
                    <Column
                        active={dbType === "mongo"}
                        onClick={() => {
                            DbClicked("mongo")
                            onClickDbType("mongo")
                        }}>
                        <div className="icon">
                            <img style={{ width: '100%', backgroundColor: '#cdf9d5', padding: '12px' }} src={constants.CONTEXT_PATH + './db_icon_v2/mongodb.png'}></img>
                        </div>
                        <p>Mongo</p>
                    </Column>


                </Row>
            )}


            {activeType === "Text File" && (
                <Row>
                    <Column
                        active={dbType === "json"}
                        onClick={() => {
                            DbClicked("json")
                            onClickDbType("json")
                        }}>
                        <div className="icon">
                            <img style={{ width: '100%', backgroundColor: '#d2e1fc', padding: '12px' }} src={constants.CONTEXT_PATH + './db_icon_v2/json.png'}></img>
                        </div>
                        <p>Json</p>
                    </Column>
                    <Column
                        active={dbType === "elasticsearch"}
                        onClick={() => {
                            DbClicked("elasticsearch")
                            onClickDbType("elasticsearch")
                        }}>
                        <div className="icon">
                            <img style={{ width: '100%', backgroundColor: '#b6fcf5', padding: '12px' }} src={constants.CONTEXT_PATH + './db_icon_v2/elastic.png'}></img>
                        </div>
                        <p>ElasticSearch</p>
                    </Column>

                </Row>
            )}



        </div>
    )
}

export default TabContent
