import React from 'react';
import * as _ from 'lodash';

import { Wrapper } from './rank.table.styled';

import { showPopup, isFloat } from '../../utils';
import * as enums from '../../utils/enums';
import DetailWrapper from '../detail-wrapper';


const RankTable = props => {
    const { data, rankTableFields, _setCompareData, compareData } = props;

    const headers = rankTableFields && Object.keys(rankTableFields);



    const showDetailWrapper = index => {
        showPopup(undefined, undefined, enums.default.popupType.element, DetailWrapper, {
            detail: data[index]
        });
    };

    return (
        <Wrapper>
            <table>
                <tr>
                    {
                        headers && headers.length > 0 && headers.map(header => {
                            const tempRankField = rankTableFields[header];
                            const currencySymbol = tempRankField ? tempRankField['currency'] : undefined;

                            let heading = tempRankField ? tempRankField['display_value'] : header;

                            if (currencySymbol) {
                                heading = heading + ' (in ' + currencySymbol + ' )';
                            }

                            return (
                                <th>
                                    {heading}
                                </th>
                            )
                        })
                    }
                    <th>
                        Compare
                    </th>
                </tr>

                {
                    data && data.length > 0 && data.map((d, index) => {
                        const lastRow = index === (data.length - 1);
                        
                        let is_checked = (compareData && compareData.filter((_data, idx) => {
                            if(_data.DoctorId === d.DoctorId){
                                return true
                            }
                            else{
                                return false
                            }
                        }))


                        return (
                            <tr
                                style={{
                                    borderBottom: lastRow ? 'none' : ''
                                }}
                                onDoubleClick={() => showDetailWrapper(index)}
                            >
                                {
                                    headers && headers.length > 0 && headers.map(header => {

                                        let dataToShow = d[header];
                                        const isDataFloat = isFloat(dataToShow);

                                        if (typeof dataToShow === 'string') {
                                            dataToShow = _.startCase(_.lowerCase(dataToShow));
                                        }

                                        if (isDataFloat) {
                                            dataToShow = dataToShow.toFixed(2);
                                        }

                                        const warning = rankTableFields[header]['warning'];
                                        let bgColor = undefined;

                                        if (warning) {
                                            const baseValue = warning['value'];
                                            const direction = warning['direction'];
                                            const color = warning['color'];

                                            if (direction === '-') {
                                                if (dataToShow > baseValue) {
                                                    bgColor = color;
                                                }
                                            }
                                            else if (direction === '+') {
                                                if (dataToShow < baseValue) {
                                                    bgColor = color;
                                                }
                                            }

                                        }


                                        return (
                                            <td>
                                                {
                                                    bgColor ?
                                                        <span
                                                            style={{
                                                                background: bgColor,
                                                                color: 'white',
                                                                padding: '3px 8px',
                                                                boxSizing: 'border-box',
                                                                // borderRadius: '5px',
                                                                fontSize: '.9rem',
                                                                borderRadius: '4px'
                                                            }}
                                                        >
                                                            {dataToShow}
                                                        </span>
                                                        :
                                                        dataToShow
                                                }
                                            </td>
                                        )
                                    })
                                }
                                <td>
                                    <input
                                        onChange={() => _setCompareData(d)}
                                        type='checkbox'
                                        checked={(is_checked && is_checked.length > 0)}
                                    />
                                </td>
                            </tr>
                        )
                    })
                }
            </table>
        </Wrapper>
    )
};

export default RankTable;