import styled from 'styled-components';

export const Wrapper = styled.div`

    width: 100%;
    position: relative;
    /* z-index: ${props => props.zIndex ? props.zIndex : '0'}; */

    label{
        font-size: .8rem;
        color: #0000008a;
        display: block;
        margin-bottom: .4rem;
    }

.MyDatePicker {
    float: left;
    position: relative;
}
.MyDatePicker * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;           /* Non-prefixed version, currently */  
}


.mdp-flex_row{
    display: flex;
    flex-direction: row;
}
.cc-hours{
    overflow: scroll;
    width: 100px;
    padding: 1px .3rem;
    box-sizing: border-box;
    max-height: 20rem;
    &::-webkit-scrollbar {
        width: 0 !important;
    }
}

.cch-title{
    width: 100%;
    height: 2rem;
    font-size: 12px;
    font-weight: 500;
    color: #444;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cch-hour{
    width: 100%;
    height: 2rem;
    font-size: 12px;
    font-weight: 300;
    color: #444;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover{
         cursor: pointer;
        background: #eee;
    }
}
.mdp-input{
    /* float: left; */
    width: 100%;
    height: 2.3rem;
    overflow: hidden;
    cursor: pointer;
    border-radius: 3px;
    display: flex;
    padding: .5rem .8rem;
    color: #555;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    background: ${props => props.bgColor ? props.bgColor : '#f5f5f5'};

}

.unstyled::-webkit-inner-spin-button,
.unstyled::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.mdp-input input:focus{
    outline: none;
}

.md-time{
    white-space: nowrap;
    display: inline-block;
    font-size: .9rem;
}
.mdp-input input{
    border: none;
    height: 100%;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: .9rem;
    cursor: pointer;
    background: #f5f5f5;

}

.mdp-container {
    float: left;
    position: absolute;
    left: 0px;
    width: 15rem;
    background: #fff;
    box-shadow: 6px 4px 14px rgb(0 0 0 / 20%);
    border-radius: 4px;
    overflow: hidden;
    padding: .3rem;
    /* margin-top: .5rem; */
    max-height: 22rem;
    /* z-index: 33; */
}
.mdpc-head{
    float: left;
    width: 100%;
    height: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.mdpc-body{
    float: left;
    width: 100%;
    /* margin-top: 20px; */
}

/**
 * Controls
 */

.mdpch-button{
    /* float: left; */
    /* width: 45px; */
    height: 100%;
    box-sizing: border-box;
    /* position: relative; */
}
.mdpchb-inner:hover > span{
    border-color: #555!important;
} 
.mdpchb-inner:hover{ 
    cursor: pointer;
    background: #eee;
}
.mdpchb-inner {
    height: 2rem;
    width: 2rem;
    background: #f4f4f4;
    border-radius: 100%;
    line-height: 2rem;
    text-align: center;
    position: 'relative';
    display: flex;
    align-items: center;
    justify-content: center;
}

.mdpchbi-right-arrows:after,
.mdpchbi-left-arrows:after,
.mdpchbi-right-arrow,
.mdpchbi-right-arrows,
.mdpchbi-left-arrow,
.mdpchbi-left-arrows{
    display: block;
    float: left;
    width: 6px;
    height: 6px;
    border-left: 2px solid #888;
    border-bottom: 2px solid #888;
    position: absolute;
}
.mdpchbi-right-arrow,
.mdpchbi-right-arrows,
.mdpchbi-left-arrow,
.mdpchbi-left-arrows{
    transform: rotate(45deg);
    /* left: 50%; */
    /* top:50%; */
    /* margin-left: -2px; */
    /* margin-top: -4px; */
}
.mdpchbi-right-arrows,
.mdpchbi-right-arrow {
    transform: rotate(225deg);
    margin-left: -4px;
}
.mdpchbi-right-arrows:after,
.mdpchbi-left-arrows:after{
    content: '';
}

.mdpchbi-left-arrows{
    margin-left: -5px;
}
.mdpchbi-right-arrows{
    margin-left: -2px;
}
.mdpchbi-right-arrows:after,
.mdpchbi-left-arrows:after{
    left: 3px;
    top: -5px
}
 

.mdpch-container{
    /* float: left; */
    /* width: 120px; */
    height: 100%;
    position: relative;
}

.mdpch-dropdown{
    position: absolute;
    width: 14rem;
    max-height: 12rem;
    overflow: hidden;
    background: white;
    box-shadow: 2px 3px 4px 3px #ccc;
    top: 100%;
    z-index: 2;
    display: flex;
    flex-direction: row;
    top: 3.5rem;
    left: 0rem;
    right: 0rem;
    margin: auto;
}
   
   .mdpch-year{
        width: 30%;
        max-height: 12rem;
        overflow: auto;
        border-right: 1px solid #ccc;
        &::-webkit-scrollbar {
           width: 0 !important;
      }
   }

   .mdpch-month{
       width: 70%;
       display: flex;
       flex-direction: row;
       flex-wrap: wrap;

       .mdpch-month-name{
            font-size: .9rem;
            text-align: center;
            /* padding: .3rem; */
            width: 33%;
            border-bottom: 1px solid #cccccc70;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #555;
            box-sizing: border-box;
            &:hover{
                background: #eaeaea;
                cursor: pointer;
            }
       }
   }

}
.mdpch-name{
    display: block;
    font-size: .9rem;
    text-align: center;
    padding: .3rem;
    border-bottom: 1px solid #cccccc70;
    color: #555;
    &:hover{
        background: #eaeaea;
        cursor: pointer;
    }
}

.mdpchc-year{
    float: left;
    width: 100%;
    height: 30px;
    font-size: 27px;
    color: #666;
    font-weight: 200px;
    text-align: center;
}
.mdpchc-month{
    float: left;
    width: 100%;
    height: 15px;
    font-size: 13px;
    color: #666;
    font-weight: 200px;
    text-align: center;
}

/**
 *  Calendar
 */
.cc-month,
.cc-head,
.cch-name, 
.cc-body,
.cdc-day span,
.cdc-day,
.c-day-container,
.c-container{
    position: relative;
    display: block;
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.c-container{ 
    width: 100%;
    height: 100%;
}

.cc-month{ 
    height: 30px;
    width: 100%;
    font-family: Roboto;
    font-size: 16px;
    line-height: 30px;
    color: #666;
}

.cc-head{ 
    height: 2.2rem;
    width: 100%;
    /* margin-top: 10px;// */
}
.cch-name{ 
    width: 14.285%;
    height: 2.2rem;
    line-height: 2.2rem;
    font-weight: 700;
    color: #666;
    font-size: 9px;
    text-align: center;
}
.cc-body{ 
    height: 270px;
    width: 100%;
}
.c-day-container{ 
    width: 14.285%;
    height: 14.285%;
}
.cdc-day{
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-weight: 300;
    color: #444;
    text-align: center;
}
.cdc-day span{ 
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-weight: 300;
    color: #444;
}

.cdc-day span:hover{
    cursor: pointer;
    background: #eee;
} 
.cdc-day span{
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    left: 50%;
    top: 50%;
    font-weight: 400;
    border-radius: 100%;
    line-height: 30px;
}
.c-day-container.disabled{
    pointer-events: none;
}

.c-day-container.disabled .cdc-day span{
    color: #ddd;
}
.c-day-container.disabled .cdc-day span{
    background: #fff!important;
}
.c-day-container.highlight .cdc-day span{
    background: #efdbca;
}
.c-day-container.highlight-green .cdc-day span{
    background: #308bc8;
    color: #fff !important;
}

.highlight-green_active{
    background: #308bc8;
    color: #fff !important;
}

`;