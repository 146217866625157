import React, { useState, useEffect } from 'react'
import { MainWrapper } from './module.access.styled';
import { PageInnerWrapper } from '../hoc/styled.hoc';
import { getParamByName } from '../../utils';
import { get_access_group_info, save_access_group_info, getUserList, clearGroupAccess } from '../../actions/access_group';
import { connect } from 'react-redux';
import Checkbox from '../hoc/checkbox';
import { Button } from '../hoc/button';
import { withRouter } from 'react-router-dom';
import Tab from '../tab';
import TextBoxV1 from '../hoc/text.box.v1';




const tabList = [
    "Module Mapping",
    "Menu Mapping",
    "User Mapping",
]

const ModuleAddEdit = (props) => {

    const { access_group_info, get_access_group_info, save_access_group_info, history } = props;
    const { location } = history;
    const { search } = location;

    const [activeTab, setActiveTab] = useState('Module Mapping')

    const [localData, setLocalData] = useState(undefined)
    const [groupId, setGroupId] = useState(undefined)



    useEffect(() => {
        let _page_info = {
            title: groupId ? "Edit Group" : "Add Group",
            isBack: true,
        }
        props.dispatchHeaderInformation(_page_info)
    }, [groupId])


    // list 
    const [locaModules, setLocalModules] = useState(undefined)
    const [localUsers, setLocalUser] = useState(undefined)
    const [localMenuAccess, setLocalMenuAccess] = useState(undefined)


    // checked

    const [master_checked_access, set_master_checked_access] = useState({
        "users": [],
        "models": [],
        "menus": [],
    })
    const [checkedUsers, setCheckedUsers] = useState(undefined)
    const [checkedModules, setCheckedModules] = useState(undefined)
    const [checkedMenus, setCheckedMenus] = useState(undefined)


    const [search_values, set_seach_values] = useState({

    })



    useEffect(() => {

        let _group_id = getParamByName(search, 'id')
        get_access_group_info(_group_id)
        setGroupId(_group_id)
        setCheckedModules(undefined)
        setCheckedUsers(undefined)
        setCheckedMenus(undefined)

    }, [location])


    const click_on_checkbox_module = (id, value) => {
        let clone_checked_module = checkedModules ? [...checkedModules] : [];
        if (clone_checked_module.indexOf(id) > -1) {
            let _index = clone_checked_module.indexOf(id)
            clone_checked_module.splice(_index, 1)
        } else {
            clone_checked_module.push(id)
        }
        setCheckedModules(clone_checked_module)
    }


    const click_on_checkbox_user = (id, value) => {
        let clone_checked_users = checkedUsers ? [...checkedUsers] : [];
        if (clone_checked_users.indexOf(id) > -1) {
            let _index = clone_checked_users.indexOf(id)
            clone_checked_users.splice(_index, 1)
        } else {
            clone_checked_users.push(id)
        }
        setCheckedUsers(clone_checked_users)
    }

    const click_on_checkbox_menu = (id, value) => {
        let clone_checked_menu = checkedMenus ? [...checkedMenus] : [];
        if (clone_checked_menu.indexOf(id) > -1) {
            let _index = clone_checked_menu.indexOf(id)
            clone_checked_menu.splice(_index, 1)
        } else {
            clone_checked_menu.push(id)
        }
        setCheckedMenus(clone_checked_menu)
    }


    const __save__ = () => {

        // saving data 
        const _data = {
            access_group: localData,
            group_access: checkedModules,
            user_role_mapping: checkedUsers,
            access_group_for_menu: checkedMenus,
        }

        save_access_group_info(_data)
        setTimeout(() => {
            props.history.push('/module_access')
        }, 100)
    }


    const onChange = (key, value) => {
        let tempLocalData = localData ? Object.assign({}, localData) : {};
        tempLocalData[key] = value;
        setLocalData(tempLocalData);
    }


    useEffect(() => {

        if (access_group_info && access_group_info.tbl_modules_list) {
            setLocalModules(access_group_info.tbl_modules_list);
        }

        if (access_group_info && access_group_info.users) {
            setLocalUser(access_group_info.users)
        }
        if (access_group_info?.nf_tbl_menu_list) {
            setLocalMenuAccess(access_group_info.nf_tbl_menu_list)
        }

        if (access_group_info && access_group_info.access_group) {
            if (access_group_info.access_group.groups && access_group_info.access_group.groups.length > 0) {
                const ids = [];
                access_group_info.access_group.groups.forEach((g) => ids.push(g.module_id));
                setCheckedModules(ids)
            }
            if (access_group_info.access_group.group_access_for_menus && access_group_info.access_group.group_access_for_menus.length > 0) {
                const ids = [];
                access_group_info.access_group.group_access_for_menus.forEach((g) => ids.push(g.menu_id));
                setCheckedMenus(ids)
            }
            if (access_group_info.access_group.user_roles && access_group_info.access_group.user_roles.length > 0) {
                const ids = [];
                access_group_info.access_group.user_roles.forEach((g) => ids.push(g.user_id));
                setCheckedUsers(ids)
            }
            let temp_access_group = access_group_info.access_group ? JSON.parse(JSON.stringify(access_group_info.access_group)) : {};
            delete temp_access_group['user_roles'];
            delete temp_access_group['groups'];
            delete temp_access_group['group_access_for_menus'];
            setLocalData(temp_access_group)

        }

        if (!access_group_info?.access_group) {
            setLocalData(undefined)
            setCheckedUsers(undefined)
            setCheckedModules(undefined)
            setCheckedMenus(undefined)
        }

    }, [access_group_info])



    const check_uncheck_item = (checked_items = [], items = []) => {

        const clone_checked_items = checked_items ? [...checked_items] : [];

        if (it_is_checked(checked_items, items)) {
            const menus_ids = items.map((a) => a.id) || [];
            const filterd_checked_items = clone_checked_items.filter((a => menus_ids.indexOf(a) === -1)) || [];
            return filterd_checked_items
        }
        else {
            items?.forEach((m) => {
                if (clone_checked_items.indexOf(m.id) === -1) {
                    clone_checked_items.push(m.id)
                }
            })
            return (clone_checked_items || []);
        }
    }


    const click_on_select_all = (key) => {
        if (key === 'group_access') {
            setCheckedModules(check_uncheck_item(checkedModules, locaModules))
        }
        if (key === 'menu_access') {
            setCheckedMenus(check_uncheck_item(checkedMenus, localMenuAccess))
        }
        if (key === 'users') {
            setCheckedUsers(check_uncheck_item(checkedUsers, localUsers))
        }
    }




    // this function will filter all those list
    const filter_list = (key, value) => {

        const clone_search_values = search_values ? { ...search_values } : {};
        clone_search_values[key] = value;

        if (key === 'module') {

            if (value && value.trim().length > 0) {
                const clone_local_module = access_group_info.tbl_modules_list ? [...access_group_info.tbl_modules_list] : [];
                const filterd_local_module = clone_local_module?.length > 0 && clone_local_module.filter((a) => {
                    if (a.name.indexOf(value) > -1) return true;
                    else return false;
                })
                setLocalModules(filterd_local_module)
            }
            else setLocalModules(access_group_info.tbl_modules_list)
        }

        if (key === 'menu') {

            if (value && value.trim().length > 0) {
                const clone_local_module = access_group_info.nf_tbl_menu_list ? [...access_group_info.nf_tbl_menu_list] : [];
                const filterd_local_module = clone_local_module?.length > 0 && clone_local_module.filter((a) => {
                    if (a.id.indexOf(value) > -1 || a.name.indexOf(value) > -1) return true;
                    else return false;
                })
                setLocalMenuAccess(filterd_local_module)
            }
            else setLocalMenuAccess(access_group_info.nf_tbl_menu_list)
        }

        set_seach_values(clone_search_values)
    }



    const it_is_checked = (checked_items = [], items = []) => {
        return items.every((item) => checked_items.indexOf(item.id) > -1)
    }



    return (
        <MainWrapper>
            <PageInnerWrapper padding={'10px'} style={{ marginTop: '1rem' }}>
                <div className="row" style={{ marginBottom: '1rem', justifyContent: 'flex-start' }}>
                    <div className="column_48" style={{ width: '30%', marginRight: '1.5rem' }}>
                        <TextBoxV1
                            label="Group Name"
                            value={(localData && localData['name']) ? localData['name'] : ''}
                            onChange={(e) => {
                                onChange('name', e.target.value);
                            }}

                        />
                    </div>

                    <div className="column_48" style={{ width: '30%' }}>
                        <TextBoxV1
                            label="Group Description"
                            value={(localData && localData['description']) ? localData['description'] : ''}
                            onChange={(e) => {
                                onChange('description', e.target.value);
                            }}
                        />
                    </div>
                </div>


                <Tab
                    hideShadow={true}
                    tabList={tabList}
                    activeType={activeTab}
                    setActiveType={(value) => {
                        setActiveTab(value)
                    }}
                />



                {activeTab && activeTab === 'Module Mapping' && (
                    <div>
                        <div style={{ margin: '10px 0px' }}>
                            <div style={{ width: '30%' }}>
                                <TextBoxV1
                                    label="Search Module"
                                    value={(search_values && search_values['module']) ? search_values['module'] : ''}
                                    onChange={(e) => {
                                        filter_list('module', e.target.value)
                                    }}

                                />
                            </div>
                        </div>
                        <div className="table_wrapper">
                            <table>
                                <thead>
                                    <tr>
                                        <td>Module Name</td>
                                        <td>Module Group</td>
                                        <td>Module Description</td>
                                        <td style={{ width: '100px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
                                                Access
                                                <Checkbox
                                                    width='20px'
                                                    checked={it_is_checked(checkedModules, locaModules)}
                                                    onClick={() => {
                                                        click_on_select_all('group_access')
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </thead>

                                <tbody>
                                    {locaModules && locaModules.length > 0 && locaModules.map((_row, index) => {
                                        let is_checked = (checkedModules && checkedModules.indexOf(_row.id) > -1);
                                        return (
                                            <tr key={index}>
                                                <td>{_row.name ? _row.name : _row.module_id}</td>
                                                <td>{_row.group}</td>
                                                <td>{_row.description}</td>
                                                <td>

                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', alignItems: 'center' }}>
                                                        <Checkbox
                                                            checked={is_checked}
                                                            onClick={() => {
                                                                click_on_checkbox_module(_row.id)
                                                            }}

                                                        />
                                                    </div>

                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}



                {activeTab && activeTab === 'Menu Mapping' && (
                    <div>
                        <div style={{ margin: '10px 0px' }}>
                            <div style={{ width: '30%' }}>
                                <TextBoxV1
                                    label="Search Menu"
                                    value={(search_values && search_values['menu']) ? search_values['menu'] : ''}
                                    onChange={(e) => {
                                        filter_list("menu", e.target.value);
                                        // onChange('name', e.target.value);
                                    }}

                                />
                            </div>
                        </div>
                        <div className="table_wrapper">
                            <table>
                                <thead>
                                    <tr>
                                        <td>Menu Name</td>
                                        <td>Menu Group</td>
                                        <td>Menu Description</td>
                                        <td style={{ width: '100px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
                                                Access
                                                <Checkbox
                                                    width='20px'
                                                    checked={it_is_checked(checkedMenus, localMenuAccess)}
                                                    onClick={() => {
                                                        click_on_select_all('menu_access')
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </thead>

                                <tbody>
                                    {localMenuAccess && localMenuAccess.length > 0 && localMenuAccess.map((_row, index) => {
                                        let is_checked = (checkedMenus && checkedMenus.indexOf(_row.id) > -1);
                                        return (
                                            <tr key={index}>
                                                <td>{_row.name ? _row.name : _row.id}</td>
                                                <td>{_row.group_name}</td>
                                                <td>{_row.description}</td>
                                                <td>

                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', alignItems: 'center' }}>
                                                        <Checkbox
                                                            checked={is_checked}
                                                            onClick={() => {
                                                                click_on_checkbox_menu(_row.id)
                                                            }}

                                                        />
                                                    </div>

                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}



                {activeTab && activeTab == 'User Mapping' && (
                    <div style={{ marginTop: '1rem' }}>
                        <div className="table_wrapper" >
                            <table>
                                <thead>
                                    <tr>
                                        <td>User Id</td>
                                        <td>User Name</td>
                                        <td style={{ width: '100px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
                                                Access
                                                <Checkbox
                                                    width='20px'
                                                    checked={it_is_checked(checkedUsers, localUsers)}
                                                    onClick={() => {
                                                        click_on_select_all('users')
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </thead>

                                <tbody>
                                    {localUsers && localUsers.length > 0 && localUsers.map((_row, index) => {
                                        let is_checked = (checkedUsers && checkedUsers.indexOf(_row.id) > -1);
                                        return (
                                            <tr key={index}>
                                                <td>{_row.id}</td>
                                                <td>{_row.name}</td>
                                                <td>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'row', alignItems: 'center' }}>
                                                        <Checkbox
                                                            checked={is_checked}
                                                            onClick={() => {
                                                                click_on_checkbox_user(_row.id)
                                                            }}

                                                        />
                                                    </div>

                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )

                }


                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: 'auto', marginBottom: '1rem' }}>
                    <Button
                        mR={'2rem'}
                        onClick={() => {
                            __save__()
                        }}>
                        Save
                    </Button>
                    <Button
                    bgColor={'#e6e6e6'}
                    onClick={() => {
                        props.history.push("/module_access")

                    }}>
                        Cancel
                    </Button>
                </div>
            </PageInnerWrapper>
        </MainWrapper >
    )

}



const mapStateToProps = (state) => ({
    access_group_info: state.groupAccessReducer.access_group_info
})

export default withRouter(connect(mapStateToProps, { clearGroupAccess, get_access_group_info, save_access_group_info, getUserList })(ModuleAddEdit))