import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { getPdfQuestions, savePdfQuestions, saveSetOfQuestion, get_uploaded_files_list } from '../../actions/file.uploader';
import Spreadsheet from '../spreadsheet';
import SharedTable from '../shared-table'
import { Button } from '../hoc/button';
import CardAction from '../action-card';
import { constants } from '../../utils/constants';
import NewAddSvg from '../svg/add.circle'
import { showPopup } from '../../utils';
import * as enums from '../../utils/enums';
import SelectFilePopup from './select.file.popup';
import styled from 'styled-components';




const Index = (props) => {

    const { pdfQuestionsData, savePdfQuestions, getPdfQuestions, filesFromServer, get_uploaded_files_list, saveSetOfQuestion, setOfAnswers } = props;


    const [results, setResults] = useState(undefined);
    const [showSpreadsheet, setSpreadsheetData] = useState(undefined);




    /*****
     * here we are calling the action
     * which will be fetch the data from server
     */
    useEffect(() => {
        getPdfQuestions()
        get_uploaded_files_list()
    }, [])









    /****
     * here we are setting the result 
     * when props change it will be update the local results state
     * 
     */
    useEffect(() => {
        if (pdfQuestionsData && pdfQuestionsData.results && pdfQuestionsData.results.length > 0) {
            setResults(pdfQuestionsData.results)
        }
        else {
            setResults(undefined)
        }
    }, [pdfQuestionsData])



    const questionClicked = (_data) => {
        setSpreadsheetData(_data)
    }




    /****
     * this function will save the new Data and modified data
     * after saving data it will fet new data after 1second;
     * { questions } => array
     * { name }  => string
     */
    const save_pdf_questions = (questions, title) => {
        if (title, questions) {
            let _clone_spread_data = showSpreadsheet && JSON.parse(JSON.stringify(showSpreadsheet));
            _clone_spread_data.questions = questions;
            _clone_spread_data.name = title;
            _clone_spread_data.is_modified = true;
            savePdfQuestions(_clone_spread_data)
            setTimeout(() => {
                setSpreadsheetData(undefined)
                getPdfQuestions()
            }, 100)
        }
        else (
            alert("Please enter name and questions")
        )
    }




    /***
     * this function will be delete the table row
     * after deleted row it will fet new data after 1second
     */

    const local_delete_record = (data) => {
        let _temp_data = data && JSON.parse(JSON.stringify(data))
        _temp_data.is_active = 0;
        _temp_data.is_modified = true;
        savePdfQuestions(_temp_data)
        setTimeout(() => {
            getPdfQuestions()
        }, 100)

    }




    const runRecord = (data) => {
        showPopup(undefined, undefined, enums.default.popupType.element, SelectFilePopup, {
            questions: data,
            filesFromServer: filesFromServer,
            saveSetOfQuestion: saveSetOfQuestion
        })

    }

    return (
        <div>

            {!showSpreadsheet && (
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
                    <CardAction
                        parentClick={() => {
                            setSpreadsheetData({
                                name: '',
                                is_active: 1,
                                id: undefined,
                            })
                        }}
                        action={false}
                        title={"Add New"}
                        titleCenter={true}
                        isOverlay={false}
                        Image={<NewAddSvg size="4rem" height="4rem" color="#5f5f5f" />}
                    />

                    {results && results.length > 0 && results.map((result, index) => {
                        return (
                            <CardAction
                                isOverlay={true}
                                data={result}
                                key={"res" + index}
                                numberOfAction={3}
                                deleteAction={local_delete_record}
                                editAction={questionClicked}
                                viewAction={runRecord}
                                title={result.name ? result.name : ''}
                                date={result.created_at}
                                action={true}
                                Image={
                                    <img src={constants.CONTEXT_PATH + '/icon_of_question.png'} />
                                }
                            />
                        )
                    })}
                </div>
            )}



            {showSpreadsheet && (
                <Spreadsheet
                    spreadsheet={(showSpreadsheet && showSpreadsheet['questions'] && showSpreadsheet['questions'].length > 0) ? showSpreadsheet['questions'] : []}
                    backSpreadsheet={setSpreadsheetData}
                    keys={['name', 'aliases']}
                    save_function={(data, title) => {
                        save_pdf_questions(data, title)
                    }}
                    titleValue={showSpreadsheet && showSpreadsheet.name ? showSpreadsheet.name : undefined}
                    topTextBoxHide={false}
                    requiredKey={['name']}
                    close_function={(data) => {
                        setSpreadsheetData(undefined)
                    }}
                />
            )}

        </div>
    )
}







const mapStateToProps = (state) => ({

    pdfQuestionsData: state.fileUploaderReducer.pdfQuestionsData,
    filesFromServer: state.fileUploaderReducer.filesFromServer,
    setOfAnswers: state.fileUploaderReducer.setOfAnswers

})

export default connect(mapStateToProps, { getPdfQuestions, savePdfQuestions, saveSetOfQuestion, get_uploaded_files_list })(Index); 