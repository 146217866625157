import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import TextBox from '../hoc/textBox';
import Overlay from '../helper/overlay';
import CloseSvg from '../svg/close'
import RippalEffect from '../hoc/ripple-effect'
import { Button } from '../hoc/button';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { get_history } from '../../actions/favourite';
import RecentSvg from '../svg/recent'
import { debounceWrapper, showPopup } from '../../utils';
import DbSwitcher from '../db.switcher';
import CacheSettingPopup from '../editor.shared/cache.setting.popup';
import enums from '../../utils/enums';
import { getFromSession } from '../../utils/session.helper';
import { constants } from '../../utils/constants';
import SuggestionListHoc from '../hoc/suggestionListHoc';


const Wrapper = styled.div`
    width: 60rem;
    background-color: #fff;
    border: 3px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 1rem;
    height: 22rem;
    position: fixed;
    z-index: 5;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    display: flex;
    flex-direction: column;

    .close{
        position: absolute;
        right: 9px;
        top: 5px;
    }
    h1{
        font-size: 1.286rem;
        font-weight: 500;
        text-align: center;
        color: #1a1a1a;
        margin: 0px;
        display: block;
        width: 100%;
        margin-bottom: 18px;
    }
       .label{
        font-size: .9rem;
        color: #374a66;
        text-transform: capitalize;
        margin-top: 0rem;
        margin-bottom: .5rem;
    }

      .cell_box{
        width: 30%;
    }
    .cell_box_border{
        height: 6rem;  
        border: 1px dashed #7A91B6;
        border-radius: 5px;
        border-style: dashed;
        width: 100%;

        .cell_flex{
            display: flex;
            align-items: center;

        }

        ul{
            padding: 0;
            margin: 0;
            width: 100%;
            overflow: auto;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            max-height:6rem;
            height:6rem;
            flex-wrap: wrap;
            align-content: flex-start;
            box-sizing: border-box;
            padding-left: 1.5rem;

            &::-webkit-scrollbar {
                background-color:red;
                width:3px
            }

            &::-webkit-scrollbar-track {
                background-color:#b1cad5;
            }

            &::-webkit-scrollbar-thumb {
                background-color:#76919d;
                border-radius:0px;
                height: 3px;
            }
            &::-webkit-scrollbar:horizontal{
                background-color: green;
                height: 3px;
            }

        li{
            max-width: 11rem;
            min-width: 11rem;
            margin-right: 3rem;
            cursor: pointer;

            &:hover{
                .close{
                    opacity: 1;
                    cursor: pointer;
                }
            }

            .column_name{
                text-align: left;
                font-size: .8rem;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                color: #374a66;
                text-transform: capitalize;
            }

            .close{
                margin-left: 10px;
                opacity: 0;
            }
            
        }
    }
}
`;


const MiniHintWrapper = styled.div`
    left: 0px;
    right: 0px;
    margin: auto;
    z-index: 222;
    top: ${props => props.top ? props.top : '4rem'};
    display: block;
    transition: all 0.3s;
    max-height: 15rem;
    position: absolute;
    overflow-x: auto;
    &::-webkit-scrollbar {
        background-color:red;
        width:0px
    }
    

    .hints_shadow{
        box-shadow: 0 4px 6px 0 rgb(32 33 36 / 28%);
        width: 100%;
        padding-top: .4rem;
        background-color: #fff;
     }
    .hint_row{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
    }

    span{
        font-size: 1rem;
        padding: .2rem 6px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .prev_hint{
        opacity: 0;
        font-size: 1.1rem;
        max-width: calc(100% - 300px);
    }
    .next_hint{

        background: #fff;
        font-size: .9rem;
        height: 1.5rem;
        line-height: 1.5rem;
        border-bottom: 1px solid #cccccc85;
        color: #333;
        font-weight: 500;
        box-shadow: 0 4px 6px 0 rgb(32 33 36 / 28%);
        &:hover{
            background: #eaeaea;
        }
    }

    .one_line_hint{
        background: #fff;
        font-size: .9rem;
        height: 1.5rem;
        width: 100%;
        margin: 0px 15px;
        line-height: 1.5rem;
        border-bottom: 1px solid #cccccc85;
        color: #333;
        font-weight: 500;
        box-shadow: 0 4px 6px 0 rgb(32 33 36 / 28%);
        display: flex;
        align-items: center;
        
        svg{
            margin-top: 5px;
            margin-right: 10px;
            fill: #008AC7;
        }
        &:hover{
            background: #eaeaea;
        }
    }

      h3{
        padding: 6px 16px;
        font-size: 1.1rem;
        font-weight: 500;
        font-style: italic;
        position: sticky;
        background: #fff;
        top: 0px;
        border-bottom: 1px solid #ccc;
        margin: 0px 15px;

    }


`;




const QuestionPopup = (props) => {

    const {

        question,
        activeHint,
        setQuestion,
        setQuestionFocus,
        replace_hint_with_word,
        on_change_question,
        hints,
        onKeyDown,
        db_info_id,
        __selected_date_filter_columns,
        __set_selected_date_filter_columns,
        __set_global_filter_wtr,
        __global_filter_wtr,
        selected_id,
        cache_settings,
        set_cache_settings,
        suggestionBoxPosition,
        InputRef

    } = props;



    const questionInfo = props?.questionDetailsCache?.[selected_id]

    const [showSuggestions, setShowSuggestions] = useState(undefined);
    const [historyHints, setHistoryHints] = useState(undefined);
    const [selected_date_filter_columns, set_selected_date_filter_columns] = useState(__selected_date_filter_columns);
    const [blur__, set_blur] = useState(false);
    const [local_cache_setting, set_local_cache_setting] = useState(undefined);
    const [local_global_filter_wtr, set_global_filter_wtr] = useState(__global_filter_wtr);



    const {

        reporting_db_info_id,
        set_reporting_db_info_id

    } = props;



    const need_to_use_history_in_suggestion = (!question || question === "")

    // const InputRef = useRef(null);
    const wrapperRef = useRef();

    useEffect(() => {
        if (!props.historyData || props.historyData.length === 0) {
            props.get_history()
        }
    }, [])




    const select_unselect_date_columns = (item) => {

        const clone_s_d_fc = selected_date_filter_columns ? [...selected_date_filter_columns] : [];

        if (clone_s_d_fc.indexOf(item) > -1) {
            clone_s_d_fc.splice(clone_s_d_fc.indexOf(item), 1)

        } else {

            clone_s_d_fc.push(item)
        }
        set_selected_date_filter_columns(clone_s_d_fc)
    }



    useEffect(() => {
        if (need_to_use_history_in_suggestion) {
            reset_hints()
        }
    }, [question, props?.historyData?.results])



    const reset_hints = () => {

        if (props?.historyData?.results?.length > 0) {

            const hints = props?.historyData?.results.map((hint) => hint.question) || [];
            const unique_hints = hints ? [...new Set(hints)] : [];
            setHistoryHints(unique_hints)
        }
    }


    useEffect(() => {

        if (props.questionInfo && props.questionInfo.columns_extracted) {
            set_blur(false)
        }

    }, [props.questionInfo])



    const find_biggest_word_in_hints = (hints) => {
        let len = 0;
        hints && hints.length > 0 && hints.forEach((h) => {
            if (h && h.length > len) {
                len = h.length;
            }
        })
        return len;
    }


    const w_l = find_biggest_word_in_hints(hints)
    const array_of_suggestion = need_to_use_history_in_suggestion ? historyHints : hints;
    const date_columns = questionInfo?.date_columns || [];
    const btn_enabled = questionInfo && questionInfo?.columns_extracted?.length > 0;


    // 24 dec 2022
    const open_cache_setting_popup = () => {

        // const table_names = localReportItems?.[0]?.table_names?.map((t) => t.name)
        // we will move this code into builder shared function
        // const date_columns = get_only_selected_date_columns(table_names)

        const date_columns = questionInfo?.date_columns || [];

        showPopup("configure cache Setting", undefined, enums.popupType.element_with_header, CacheSettingPopup,
            {
                cache_settings: cache_settings,
                save_cache_setting: (__setting) => {
                    set_cache_settings(__setting)
                },
                date_columns: date_columns || []

            },
            undefined,
            undefined,
            undefined,
            {
                width: '100%',
                maxWidth: '35rem'
            });
    }


    const clientIdFromSession = getFromSession(constants.SESSION_KEYS.CLIENT_ID);


    const is_it_cached_db = db_info_id && db_info_id.indexOf('__nf__db__cached__') > -1;

    const global_filter_wrt__constant = [
        {
            label: 'Global Filter WRT LastYear',
            value: 'global_filter_wrt_lastyear',
        },
        {
            label: 'Global Filter WRT Last To LastYear',
            value: 'global_filter_wrt_last_to_lastyear',
        }

    ]

    return (
        <React.Fragment>
            <Overlay
                bgColor="#00000059"
                zIndex="4"
                onClick={() => {
                    setQuestionFocus(undefined);
                    if (question && question.length > 0) {
                        // props.getAllInfoOfQuestion(question, db_info_id, selected_id)
                    }
                }}
            />
            <Wrapper
                ref={wrapperRef}
                onClick={(e) => {
                    e.stopPropagation()
                    setShowSuggestions(undefined);
                }}
            >
                <div className="close">
                    <RippalEffect
                        title={"Close"}
                        icon={<CloseSvg size=".8rem" height=".8rem" />}
                        Click={() => {
                            // setQuestion('')
                            setQuestionFocus(undefined);
                        }}
                    />
                </div>

                {/* <DbSwitcher
                    db_info_id={db_info_id ? [db_info_id] : undefined}
                    reporting_db_info_id={reporting_db_info_id}
                    set_reporting_db_info_id={set_reporting_db_info_id}

                /> */}

                <TextBox
                    autoFocus="true"
                    refName={InputRef}
                    label="Please Type Your Data Question Here"
                    value={question}
                    inputStyle={{
                        paddingRight: '1rem',
                        fontSize: '1.1rem'
                    }}
                    onChange={(e) => {

                        const value = e.target.value
                        on_change_question(e,undefined, wrapperRef)
                        // if (e.target.value?.length > 0) {

                        const get_question_info = () => {
                            props.getAllInfoOfQuestion((value || "---"), db_info_id, selected_id)
                        }

                        const debounceWrapperFun = debounceWrapper(get_question_info, 700)
                        debounceWrapperFun()
                        // }

                        if (!showSuggestions) setShowSuggestions(true);
                    }}
                    onFocus={() => {
                        setShowSuggestions(true);
                    }}

                    onClick={(e) => {
                        e.stopPropagation()
                    }}

                    onBlur={() => {
                        if (question?.length > 0) {
                            // props.getAllInfoOfQuestion(question, db_info_id)
                        }
                        // set_blur(true)
                    }}

                    onKeyDown={(e) => {

                        e.stopPropagation()
                        onKeyDown(e)

                        if (e.key === 'Enter') {
                            const temp_selected = (selected_date_filter_columns || []).filter((f) => date_columns.indexOf(f) > -1) || []
                            __set_selected_date_filter_columns(temp_selected)
                        }
                    }}
                />

                {showSuggestions && array_of_suggestion && array_of_suggestion?.length > 0 &&
                    <SuggestionListHoc suggestionList={array_of_suggestion} selectedIndex={activeHint} position={suggestionBoxPosition} boxShadow={'rgba(0, 0, 0, 0.16) 0px 1px 4px;'} handleSuggestionClick={(item, _, index)=> replace_hint_with_word(index, true, undefined, item )} /> 
                }

                {/* {
                    showSuggestions && typeof array_of_suggestion !== 'undefined' && array_of_suggestion && array_of_suggestion.length > 0 && (
                        <MiniHintWrapper>
                            {need_to_use_history_in_suggestion && (
                                <h3>Recent History</h3>

                            )}
                            {array_of_suggestion.map((hint, index) => {

                                // let value_arr = question && question.split(" ");
                                // let searchIndex = value_arr && value_arr[value_arr.length - 1].length;
                                // let firstValue = hint.substring(0, searchIndex)
                                // let lastValue = hint.substring(searchIndex);

                                return (
                                    <div className="hint_row" index={"mini" + index}>
                                        {need_to_use_history_in_suggestion && (
                                            <span className="one_line_hint" onClick={() => {
                                                on_change_question(undefined, hint)
                                                if (InputRef && InputRef.current) {
                                                    InputRef.current.focus();
                                                }
                                            }}>
                                                <RecentSvg size="1rem" height="1rem" color="008AC7" /> {hint}
                                            </span>
                                        )}
                                        {!need_to_use_history_in_suggestion && (
                                            <React.Fragment>
                                                <span className="prev_hint">{question}</span>
                                                <span className="next_hint"
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        replace_hint_with_word(index, true);
                                                        if (InputRef && InputRef.current) {
                                                            InputRef.current.focus();
                                                        }
                                                    }}
                                                    style={{
                                                        width: w_l ? (w_l > 3) ? (w_l * 12) + "px" : undefined : undefined,
                                                        backgroundColor: activeHint === index ? "#eaeaea" : undefined
                                                    }}>
                                                    {hint}
                                                </span>
                                            </React.Fragment>
                                        )}

                                    </div>
                                )
                            })}
                        </MiniHintWrapper>
                    )
                } */}

                {blur__ && (
                    <div>
                        <p style={{
                            textAlign: "center",
                            fontSize: '.9rem',
                            margin: '3rem',
                        }}>Please wait while we are loading information...</p>
                    </div>
                )}


                {!blur__ && date_columns && date_columns.length > 0 && (
                    <div class="cell_box" style={{ width: '100%', marginTop: '1rem' }}>
                        <div style={{ position: 'relative' }}>
                            <h2 class="label">Map Dates with Master Date Filter</h2>
                        </div>
                        <div class="cell_box_border" >
                            <ul style={{ listStyle: 'none', paddingLeft: '0px' }}>
                                {date_columns.map((item, i) => {
                                    return (
                                        <li style={{ maxWidth: '100%', padding: '2px 0px' }}>
                                            <div className="column_name" onClick={(e) => {
                                                e.stopPropagation()
                                                select_unselect_date_columns(item)

                                            }}>
                                                <input type="checkbox"
                                                    checked={(selected_date_filter_columns || []).indexOf(item) > -1}
                                                    onChange={(e) => {
                                                        select_unselect_date_columns(item)
                                                    }}
                                                /> &nbsp;&nbsp;
                                                {item}
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                )}


                {/* 
                    no need of this code
                */}
                {/* {false && !blur__ && question?.length > 0 && global_filter_wrt__constant?.length > 0 && (
                    <div class="cell_box" style={{ width: '100%', marginTop: '1rem', }}>
                        <div style={{ position: 'relative' }}>
                            <h2 class="label">Choose DATE</h2>
                        </div>
                        <div class="cell_box_border" style={{ height: '1.4rem', border: '0px' }}>
                            <ul style={{ listStyle: 'none', paddingLeft: '0px', display: 'flex', flexDirection: 'row' }}>
                                {global_filter_wrt__constant.map((item, i) => {
                                    return (
                                        <li style={{ maxWidth: '30%', padding: '2px 0px' }}>
                                            <div className="column_name" onClick={(e) => {
                                                e.stopPropagation()
                                                if (local_global_filter_wtr === item?.value) {
                                                    set_global_filter_wtr('')
                                                }
                                                else{
                                                    set_global_filter_wtr(item?.value)
                                                }
                                            }}>
                                                <input type="checkbox"
                                                    checked={local_global_filter_wtr === item?.value}
                                                    onChange={(e) => {
                                                        if (local_global_filter_wtr === item?.value) {
                                                            set_global_filter_wtr('')
                                                        }
                                                        else{
                                                            set_global_filter_wtr(item?.value)
                                                        }
                                                    }}
                                                /> &nbsp;&nbsp;
                                                {item?.label}
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                )} */}

                <div style={{ marginTop: 'auto' }}>
                    {/* {!is_it_cached_db && (
                        <Button disabled={question?.length === 0 || !question} bgColor="#06577a" width="8rem" mR="1rem" onClick={() => {
                            open_cache_setting_popup()
                        }}>Wanna Cache ?</Button>
                    )} */}

                    <Button bgColor='#337dff' mR="1rem" primary={true} onClick={() => {
                        __set_selected_date_filter_columns(selected_date_filter_columns)
                        __set_global_filter_wtr(local_global_filter_wtr)
                        props.getAllInfoOfQuestion(question, db_info_id)

                        setQuestionFocus(undefined);
                    }}>
                        Save
                    </Button>
                    <Button
                        width="5rem"
                        bgColor={'#e6e6e6'}
                        onClick={() => {
                            setQuestionFocus(undefined);
                        }}>Cancel</Button>
                </div >
            </Wrapper >
        </React.Fragment >

    )
}


const mapStateToProps = state => ({

    historyData: state.favouriteReducer.historyData,
    questionDetailsCache: state.insightReducer.questionDetailsCache

})

export default withRouter(connect(mapStateToProps, { get_history, })(QuestionPopup))

