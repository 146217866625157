export const RELATIONSHIP_DETAIL_INIT = 'modules/relationshipDetail/init';

export const GET_TABLE_FOR_RELATIONSHIP_DETAIL_SUCCESS = 'modules/getTableForRelationshipDetail/success';

export const GET_SOURCE_TABLE_COLUMNS_SUCCESS = 'modules/get_source_table_column_/success';

export const GET_TARGET_TABLE_COLUMNS_SUCCESS = 'modules/get_target_table_column_/success';

export const GET_ALL_RELATIONSHIPS_SUCCESS = 'modules/getAllRelationships/success';


export const SAVE_RELATIONSHIP_DETAILS_SUCCESS = 'modules/save_relatioship_details_/success';

export const GET_COLUMNS_BY_TABLE_NAME = 'modules/getColumnNamesByTableName'
export const GET_TABLE_AND_COLUMNS = 'modules/GET_TABLE_AND_COLUMNS';