import {  simpleDispatch_post } from './helper';
import * as actionTypes from '../action-types/sample.demo';
import { constants } from '../utils/constants';


export const getDbTableInfo = (client_id, db_name) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.DEMO_SAMPLE.POINT + constants.END_POINTS.DEMO_SAMPLE.GET_DB_TABLE_INFO;

    let post_body = {
        client_id: client_id,
        db_name: db_name
    }
    dispatch({type: actionTypes.CLEAR_DB_TABLE_INFO})
    await simpleDispatch_post(dispatch, url, post_body, actionTypes.GET_DB_TABLE_INFO, true);
};

export const getDataOfTable = (db_name, table_name) => async (dispatch) => {

    const url = constants.END_POINTS.API + constants.END_POINTS.DEMO_SAMPLE.POINT + constants.END_POINTS.DEMO_SAMPLE.GET_TABLE_DATA;

    let post_body = {
        db_name: db_name,
        table_name: table_name
    }

    await simpleDispatch_post(dispatch, url, post_body, actionTypes.GET_DATA_BY_TABLE_NAME, true);
}



export const saveNewRecord = (db_name, table_name, records) => async (dispatch) => {

    const url = constants.END_POINTS.API + constants.END_POINTS.DEMO_SAMPLE.POINT + constants.END_POINTS.DEMO_SAMPLE.SAVE_NEW_RECORD;

    let post_body = {

        db_name: db_name,
        table_name: table_name,
        records: records

    }

    await simpleDispatch_post(dispatch, url, post_body, actionTypes.ADD_NEW_RECORD);
}

