import {  simpleDispatch_get, simpleDispatch_post } from './helper';
import { constants } from '../utils/constants';
import * as actionTypes from '../action-types/new-table'



export const getNewTable = (id) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.NEWTABLE.POINT + constants.END_POINTS.NEWTABLE.GET_DIMENSION_RELATION
    await simpleDispatch_get(dispatch, url, actionTypes.NEW_TABLE_RESULT);
};


export const getNewTableEdit = (data) => async dispatch => {

    const post = {
        dimensions: data
    };

    const url = constants.END_POINTS.API + constants.END_POINTS.NEWTABLE.POINT + constants.END_POINTS.NEWTABLE.SAVE_DIMENSION_RELATION
    await simpleDispatch_post(dispatch, url, post, actionTypes.NEW_TABLE_SAVE, true);
};


export const getDimensionByEntity = (fact, dimension) => async dispatch => {
    const post = {
        fact,
        dimension
    };

    const url = constants.END_POINTS.API + constants.END_POINTS.NEWTABLE.POINT + constants.END_POINTS.NEWTABLE.GET_DIMENSION_BY_ENTITY

    await simpleDispatch_post(dispatch, url, post, actionTypes.GET_DIMENSION_BY_ENTITY, true);
};

export const getDimensionByHierarchy = (fact, dimension) => async dispatch => {
    const post = {
        fact,
        main_dimension: dimension
    };
    const url = constants.END_POINTS.API + constants.END_POINTS.NEWTABLE.GET_DATA_HIERARCHY
    await simpleDispatch_post(dispatch, url, post, actionTypes.GET_DATA_HIERARCHY, true);
};



export const delete_dimension = (data) => async dispatch => {

    const post = {
        dimensions: data
    };

    const url = constants.END_POINTS.API + constants.END_POINTS.NEWTABLE.POINT + constants.END_POINTS.NEWTABLE.DELETE_DIMENSION_RELATION
    await simpleDispatch_post(dispatch, url, post, actionTypes.DELETE_DIMENSION_RELSTION, true);
};


export const hide_popus_iq = (data) => async dispatch => {
    await dispatch({
        type: actionTypes.HIDE_POPUS_IQ,
        data: data
    })
};


