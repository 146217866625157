import * as actions from '../action-types/polus_chat';

const initialState = {
    type: actions.POLUS_CHAT_INIT,
    innerMessageCache: {},
    outerChartActionCache: undefined,
    isWaiting: false
};

const PolusChatReducer = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {

        case actions.SAVE_SINGLE_CHAT_RESPONSE:
            const cloneMessageCache = state.innerMessageCache ? { ...state.innerMessageCache } : {};

            const payload = action?.data;

            if (!cloneMessageCache[payload?.id] && payload?.id) {
                cloneMessageCache[payload.id] = [];
                cloneMessageCache[payload.id].push(payload?.data)
            } else {
                cloneMessageCache[payload.id].push(payload?.data)
            }

            console.log("payload", payload)
            return {
                ...state,
                innerMessageCache: cloneMessageCache,
                isWaiting: payload?.hideIsWaiting ? false : state.isWaiting

            };


        case actions.OTHER_ACTION_COMAND:

            const cloneOuterChartActionCache = state?.outerChartActionCache ? { ...state.outerChartActionCache } : {};

            if (!cloneOuterChartActionCache[action?.data?.id]) {
                cloneOuterChartActionCache[action.data.id] = action.data;
            }
            else {
                cloneOuterChartActionCache[action.data.id] = action.data;
            }
            return {
                ...state,
                outerChartActionCache: cloneOuterChartActionCache
            }

        case actions.SAVE_IS_WAITTING:
            return {
                ...state,
                isWaiting: action?.data
            }
        default:
            return {
                ...state
            };
    }
};

export default PolusChatReducer;