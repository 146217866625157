import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '../hoc/button';
import HintsAdd from '../hints.add';

import { connect } from 'react-redux';
import SelectOptionV1 from '../select-option/select.option.v1.1';
import { getDbInfoId, isDefaultLiveDb } from '../../utils';
import { getAllRelationships } from '../../actions/relationship.detail';
import { get_table_column_hints_v1 } from '../../actions/data-hierarchy';
import { getStringHintsFromServer } from '../../actions/report'
import DbSwitcher from '../db.switcher';


const PopupWrapper = styled.div`

    width: 29rem;
    min-height: 29rem;
    padding: 0 0.8rem 0.8rem 0.8rem;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    max-height: 80rem;


    .row{
        width: 100%;
        margin-bottom: 1.5rem;
        .label{
            margin-bottom: -7px;
        }
    }

    h1{
        font-size: 1.5rem;
        font-weight: 500;
        text-align: center;
        margin-top: 0px;
    }
`;


const _data_types = [
    {
        name: 'String',
        id: 'string',
    },
    {
        name: 'Date',
        id: 'date',
    },
    {
        name: 'Currency',
        id: 'currency',
    },
    {
        name: 'Number',
        id: 'number',
    }
]


const AddEditPopup = (props) => {

    // poops and popup data here

    const { close, popupKey, option, data, relationships, hints_other_information, get_table_column_hints_v1 } = props;
    const { popupData, _saveFun, } = data;


    // here are state of the component  
    const [formData, setFormData] = useState(undefined)
    const [hints, setHints] = useState(undefined)
    const [dimesnsions_hints, set_dimesnsions_hints] = useState(undefined);
    const [active_db_info, set_active_db_info] = useState(undefined);
    const [cached_db_info_id, set_cached_db_info_id] = useState(undefined);


    const IS_DEFAULT_LIVE_DB = isDefaultLiveDb();


    useEffect(() => {

        // props.getStringHintsFromServer()
        const db_info = getDbInfoId()
        const db_id_to_use = IS_DEFAULT_LIVE_DB ? db_info : (db_info + "__nf__db__cached__");
        set_active_db_info(db_info)
        set_cached_db_info_id(db_id_to_use)
        props.getAllRelationships(db_id_to_use)

    }, [])



    useEffect(() => {

        const db_info = getDbInfoId();

        if (!props.table_column_hints || props.table_column_hints.length === 0 && db_info) {
            const db_id_to_use = IS_DEFAULT_LIVE_DB ? db_info : (db_info + "__nf__db__cached__");
            get_table_column_hints_v1(db_id_to_use)
        }

    }, [])


    const _switch_db_info_id = (id) => {
        props.getAllRelationships(id)
        get_table_column_hints_v1(id)
        set_cached_db_info_id(id)
    }



    useEffect(() => {
        setFormData(popupData)
        if (popupData && popupData.go_to) {
            setHints(popupData.go_to.split(','))
        }

        if (popupData?.db_info_id) {
            _switch_db_info_id(popupData?.db_info_id)
        }

        // if (popupData?.fact) {
        //     setTimeout(() => {
        //         lets_create_dimension_hint(popupData.fact)
        //     }, 4000);
        // }
    }, [popupData])


    useEffect(() => {
        if (popupData?.fact) {
            lets_create_dimension_hint(popupData.fact)
        }
    }, [popupData, relationships, hints_other_information])


    const _onChange = (key, value) => {
        let cloneFormData = formData ? Object.assign({}, formData) : {};
        cloneFormData[key] = value;
        setFormData(cloneFormData)

    }


    const _save = () => {
        let temp_saved_data = formData ? Object.assign({}, formData) : {};
        temp_saved_data.go_to = hints.join(',');
        temp_saved_data.modified = true;
        temp_saved_data.db_info_id = cached_db_info_id || active_db_info;
        _saveFun(temp_saved_data)
        close(option, popupKey)
    }


    const lets_create_dimension_hint = (__hint_) => {

        const clone_relations__ship = relationships ? [...(relationships || [])] : [];

        const hint_info = hints_other_information?.[__hint_];

        const is_rule = hint_info?.table_name === "nf_rule";

        const rule_tbl = hint_info?.rule_tbl?.trim()?.toLocaleLowerCase();

        const valid_relation_table = [];

        const table_name_to_filter = is_rule ? rule_tbl : __hint_?.split('.')?.[0]?.toLocaleLowerCase();

        const column_name_to_filter = __hint_?.split('.')?.[1]?.toLocaleLowerCase();

        (clone_relations__ship || [])?.map((r) => {
            if (r?.target_table?.toLocaleLowerCase() === table_name_to_filter?.toLocaleLowerCase() || r?.source_table?.toLocaleLowerCase() === table_name_to_filter?.toLocaleLowerCase()) {
                valid_relation_table.push(r?.target_table?.toLocaleLowerCase())
                valid_relation_table.push(r?.source_table?.toLocaleLowerCase())


            }
        })


        valid_relation_table.push(table_name_to_filter)

        const clone_table_column_hints = props.table_column_hints ? [...props.table_column_hints] : []
        // clone_table_column_hints.

        const filterd_clone_table_column_hints = clone_table_column_hints.filter((c) => {

            const table_name = c.split(".")[0].toLocaleLowerCase();
            const column_name = c.split(".")[1].toLocaleLowerCase();

            // FOR EURO NET 
            if (valid_relation_table.indexOf(table_name) > -1) {
                return true;
            }
            // if (valid_relation_table.indexOf(table_name) > -1 && column_name !== column_name_to_filter) {
            //     return true;
            // }
            else {
                return false;
            }
        })

        set_dimesnsions_hints(filterd_clone_table_column_hints)
    }

    const disabled = !formData || formData && !formData.fact || (!hints || hints?.length == 0);



    return (
        <PopupWrapper>

            <h2 style={{ margin: '.5rem 0px' }}></h2>
            {active_db_info && (
                <DbSwitcher
                    db_info_id={[active_db_info]}
                    reporting_db_info_id={cached_db_info_id}
                    set_reporting_db_info_id={(value) => {
                        _switch_db_info_id(value)
                        setFormData({})
                    }}
                    disabled={formData?.id}
                />
            )}




            <div className="row">
                <p className='label'>Fact</p>
                <SelectOptionV1
                    label="Fact"
                    is_search={true}
                    hide_case={true}
                    none_value={false}
                    hide_border={true}
                    label_as_placeholder={true}
                    background={'#eaeaea'}
                    value={formData && formData.fact ? formData.fact : ''}
                    options={props.table_column_hints}
                    on_change={(value) => {
                        _onChange('fact', value)
                        lets_create_dimension_hint(value)
                    }}
                    search_placholder_txt={"Search Fact"}
                />
            </div>


            <div className="row">
                <p className='label'>Enter Dimension</p>
                <HintsAdd
                    hints={hints}
                    hide_border={true}
                    setHints={setHints}
                    is_new_text_box={true}
                    prevent_enter_hint={true}
                    show_as_placholder={true}
                    background={'#eaeaea'}
                    label="Enter Dimension"
                    existing_label={"Existing Dimensions"}
                    searchHint={dimesnsions_hints || []}
                />
            </div>

            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: 'auto',
                marginLeft: 'auto'

            }}>
                <Button
                    primary={true}
                    disabled={disabled}
                    onClick={() => _save()}
                >
                    Save
                </Button>


            </div>

        </PopupWrapper>
    )
}


const mapStateToProps = (state) => ({
    // __hints__: state.reportReducer.hints,
    table_column_hints: state.dataHierarchy.table_column_hints,
    hints_other_information: state.dataHierarchy.hints_other_information,
    relationships: state.relationshipDetail.relationships
})



export default connect(mapStateToProps, { getStringHintsFromServer, getAllRelationships, get_table_column_hints_v1 })(AddEditPopup);
