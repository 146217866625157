import * as actions from '../action-types/kpi';

const initialState = {
    type: actions.KPI_INIT,
    recordsCount: 0,
    newKpiData: undefined,
};


const kpiReducer = (state, action) => {
	if (!state || typeof action.type === 'undefined') {
		return initialState;
    }

    // if (action && action.data && typeof action.data === 'object') {
    //     action.data.key = new Date();
    // }

    switch (action.type) {
        case actions.REFRESH_KPI_DATA_SUCCESS:
            return {
                ...state,
                type: action.type,
                newKpiData: action.data
            };

        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
			return {
                type: state.type,
                newKpiData: state.newKpiData,
                recordsCount: state.recordsCount,
            };
        }
};

export default kpiReducer;