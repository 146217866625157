import React, { useCallback } from 'react';
import ReceiveMessage from './receiveMessage';
import SentMessage from './sentMessage';
import { ChatBoxContainer } from './chat.styled';

const ChatBox = React.memo(({ mainResponseHolder, scrollToBottom, expandChart, chatboxRef }) => {

    console.log("SAROJ RENDER 1",);

    const renderMessage = useCallback((message) => {
        if (message.type === 'user') {
            return <SentMessage key={message.id} {...message} />;
        }
        return (
            <ReceiveMessage
                key={message.id}
                id={message.id}
                response={message}
                expandChart={expandChart}
                scrollToBottom={scrollToBottom}
            />
        );
    }, [expandChart, scrollToBottom]);

    return (
        <ChatBoxContainer>
            {mainResponseHolder?.map(renderMessage)}
        </ChatBoxContainer>
    );
});

export default ChatBox;
