import React, { useEffect } from 'react';
import * as _ from 'lodash';
import { connect } from 'react-redux';

import { getComparisonData } from '../../actions/ranking';
import ReportWrapper from '../report-wrapper';
import CloseSvg from '../svg/close';

import { Wrapper, HeadingWrapper, ChartWrapper, Close } from './rank.compare.styled';

const RankCompare = props => {

    const { data, close, popupKey, option, getComparisonData, comparisonData } = props;

    const {compareData } = data;

    useEffect(() => {
        if (compareData && compareData.length > 0) {
            const ids = [];
            const primaryIdField = 'DoctorId';
            compareData.forEach(dataInObject => {
                const id = dataInObject[primaryIdField];

                if (id) {
                    ids.push(id);
                }
            });

            getComparisonData(ids)
        };

        return () => {
            getComparisonData(undefined)
        }
    }, []);


    return (
        <Wrapper>
            <Close onClick={() => {
                close(option, popupKey)
            }}>
                <CloseSvg size="1rem" width="1rem" />
            </Close>
            <HeadingWrapper>
                Comparison
            </HeadingWrapper>

            <ChartWrapper>
                {
                    comparisonData && comparisonData.length > 0 && comparisonData.map((data, index) => {
                        const id = data.reportId;

                        return (
                            <div
                                style={{
                                    marginRight: '10px',
                                    marginBottom: '10px'
                                }}
                            >
                                <ReportWrapper
                                    // isDimension={true}
                                    height={400}
                                    width={400}
                                    IsDataAvailable={true}
                                    availableData={data}
                                    id={id}
                                    hide_y_axis_label={false}
                                    isDiscovery={true}
                                />
                            </div>
                        )
                    })
                }
            </ChartWrapper>
        </Wrapper>
    )
};

const mapStateToProps = state => {
    const { comparisonData } = state.rankingReducer;

    return { comparisonData }
};


export default connect(mapStateToProps, { getComparisonData })(RankCompare);