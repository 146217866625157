import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import FormulaCreator from '../formula-creator/mini.formula';
import { CheckBox } from '../hoc/checkbox';
import RippleEffect from '../hoc/ripple-effect';
import Delete from '../svg/delete';
import { Button } from '../hoc/button'
import { constants } from '../../utils/constants'
import * as DataTypes from '../../shared-with-fe/data.types';
import Tab from '../tab'
import CustomDatePicker from '../hoc/date-time-piker/index'
import CloseSvg from '../svg/close';
import { connect } from 'react-redux';
import { save_advance_date_config } from '../../actions/filter';

const { CONDITIONS } = constants


// const CONDITIONS = {
//     BETWEEN: { DISPLAY_KEY: 'Between', VALUE_KEY: 'between', DATA_TYPE: 'number' },
// }

export const AutoComplete = styled.div`
    position: absolute;
    top: 100%;
    left: -2px;
    max-height: 8rem;
    overflow-y: auto;
    box-sizing: border-box;
    /* box-shadow: 0px 0px 4px #ccc; */
    margin-top: 1px;
    /* background-color: #fff; */
    z-index: 2;


    &::-webkit-scrollbar {
        background-color:red;
        width:2px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 2px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 2px;
    }

    .word{
        box-sizing: border-box;
        font-size: .8rem;
        color: #555;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        /* &:hover{
            background-color: #eeeeee;
            color: #222;
            cursor: pointer;
        } */

        span{
            display: inline-block;
            padding: ${props => props.gap ? "7px 1.5rem" : '7px 8px'};
        }
    }

    .prev_word{
        opacity: 0;
        padding: 0px !important;
    }
    .current_word{
        background-color: #ffffff;
        cursor: pointer;
        &:hover{
            background-color: #eeeeee;
            color: #222;
            cursor: pointer;
        }
        
    }
    .selected{
        background-color: #eeeeee;
        color: #222;
    }

`;


export const Wrapper = styled.div`
    min-height: 35rem;
    max-height: 42rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 55rem;
    border-radius: 20px;
    padding: 1.5rem;
    box-sizing: border-box;

    .header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        margin-bottom: 1rem;
        position: relative;

        h1{
            font-size: 1.5rem;
            font-weight: 500;
            margin: 0px;
            font-weight: bold;
            color: #2173F7;
            width: 80%;
            /* text-align: center; */

        }

        .close_btn{
            cursor: pointer;
            width: 1rem;
            height: 1rem;
            position: absolute;
            right: 10px;
            top: -.5rem;
            svg{
                fill: #333;
                
            }
        }
    }

    

    ._label_{
        width: 30%; 
        font-size: 0.9rem;
        font-weight: 600;
        color: #333;
        sup{
            color: red;
            font-size: 1rem;
        }
    }

    ._row{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 1rem;

        .input{
            font-size: 1rem;
            width: 100%;
            height: 2.3rem;
            box-sizing: border-box;
            background-color: #F0F3FF;
            border: 0px;;
            padding: 10px 10px;
            border-radius: 6px;
            border: 1px solid #e2e2e2;

            &:focus{
                outline: none;
            }
        }

        .option{
            font-size: 1rem;
            width: 100%;
            height: 2.3rem;
            box-sizing: border-box;
            background-color: #F0F3FF;
            border: 0px;;
            padding: 0px 10px;
            border-radius: 6px;
            border: 1px solid #e2e2e2;
        }
    }

    .formula_checkbox{
        display: flex;
        width: 70%;
        font-size: .8rem;
        color: #222;
        align-items: center;
        justify-content: flex-start;
        padding: 5px;
        /* margin-left: 19%; */
        text-transform: uppercase;
        font-weight: 500;
    }

    .drop_down_suggestion{
        position: absolute;
        width: 90%;
        background-color: #fff;
        z-index: 1;
        border: 1px solid #eaeaea;
        
        .item{
            width: 100%;
            padding: 5px;
            font-size: .9rem;
            box-sizing: border-box;
            cursor: pointer;

            &:hover {
                background-color: #eaeaea;
            }
        }
    }

    .table_wrapper{
        width: 100%;
        max-height: 22rem;
        margin-top: 1rem;
        overflow: auto;
        &::-webkit-scrollbar {
            background-color:red;
            width:5px
        }
        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }
        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }
        table{
            width: 100%;
            border-collapse: collapse;
            thead{
                td{
                    padding: .7rem 7px;
                    font-weight: 600;
                    background-color: #4D9DF4;
                    color: #fff
                }
            }
            td{
                border-bottom: 1px solid #222;
                font-size: .9rem;
                color: #000;
                padding: .4rem 7px;
                box-sizing: border-box;
            }

            tbody{
                background-color: #F0F3FF;
            }
        }
        .input_wrapper{
            background-color: #fff;
            height: 2.1rem;
            border: 1px solid #e2e2e2;
            border-radius: 6px;
            box-shadow: 1px 2px 2px -1px #ccc;

            select,
            input{
                border: 0px;
                background-color: #fff;
                height: 100%;
                width: 100%;
                padding: 0px 10px;
                box-sizing: border-box;
                font-size: 1rem;

                &:focus{
                    outline: none;
                }

            }
    }
        /* select{
            width: 100%;
            height: 30px;
            font-size: .8rem;
            padding: 5px;
            box-sizing: border-box;
            border: 1px solid #000;
            background: #fff;;
        }
        input{
            width: 100%;
            height: 30px;
            font-size: .8rem;
            padding: 5px;
            box-sizing: border-box;
            border: 1px solid #000;
        } */
    
    }

`;


const TabContent = styled.div`
    width: 100%;
    padding: 5px;
    padding-top: 1rem;
    box-sizing: border-box;
`;


const AdvanceDateSetting = (props) => {

    const { close, popupKey, option, data, advance_date_setting } = props;

    const { id, save } = data;

    const [localData, setLocalData] = useState(undefined);



    const __get_plan__object = () => {
        return {
            condition: '',
            value: '',
            display_value: ''
        }
    }


    useEffect(() => {

        if (advance_date_setting?.length > 0 && id) {

            const clone_advance_date_config = advance_date_setting ? [...advance_date_setting] : [];
            clone_advance_date_config.push(__get_plan__object());

            setLocalData(clone_advance_date_config);

        } else {
            setLocalData([__get_plan__object()])
        }

    }, [advance_date_setting, id])



    const _onChange = (key, value, index) => {

        const clone_local_data = localData ? [...localData] : [];
        if (key === "value" || key === "value2") {
            var dateObj = new Date(value);
            // Extract year, month, and date from the Date object
            var year = dateObj.getFullYear();
            var month = ('0' + (dateObj.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based month index
            var date = ('0' + dateObj.getDate()).slice(-2);
            // Concatenate year, month, and date with hyphens to form year-month-date format
            var yearMonthDate = year + '-' + month + '-' + date;
            clone_local_data[index][key] = yearMonthDate;

        } else {
            clone_local_data[index][key] = value;

        }

        const last_element = clone_local_data[clone_local_data.length - 1];
        if (last_element.condition && last_element.value && last_element.display_value) {
            clone_local_data.push(__get_plan__object())
        }


        console.log("clone_local_data", clone_local_data)
        setLocalData(clone_local_data);

    }


    const _local_save = () => {

        const clone_advance_date_config = localData ? [...localData] : [];
        const filterd = clone_advance_date_config?.filter((f) => {
            if (f.condition && f.value && f.display_value) {
                return true;
            } else return false
        }).filter((f) => !f.deleted);

        (filterd || []).map((f) => {
            f.display_value = f?.display_value?.trim()?.split(" ").join("_");
        })

        props.save_advance_date_config(id, undefined, filterd);
        if (save) {
            save()
        }
        close(option, popupKey);
    }


    const delete_row = (index) => {
        const clone_local_data = localData ? [...localData] : [];
        clone_local_data[index]['deleted'] = true;
        setLocalData(clone_local_data)
    }


    return (
        <Wrapper>
            <div className='header'>
                <h1>{advance_date_setting ? 'Edit Your Advance Date Config' : 'Add Your Advance Date Config'}</h1>
                <div title="Close" className='close_btn' onClick={() => {
                    close(option, popupKey)
                }}>
                    <CloseSvg size="1.1rem" />
                </div>
            </div>
            <TabContent>
                <div className="table_wrapper">
                    <table>
                        <thead>
                            <tr>
                                <td>#</td>
                                <td>Condition</td>
                                <td>Value</td>
                                <td>Display Value</td>
                                <td>Action</td>
                            </tr>
                        </thead>

                        <tbody>
                            {(localData || []).length > 0 && (localData.filter((f) => !f.deleted) || []).map((item, index) => {
                                return (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                            <div className='input_wrapper'>
                                                <select
                                                    value={item.condition ? item.condition : '--select--'}
                                                    onChange={(e) => {
                                                        _onChange("condition", e.target.value, index,);
                                                    }}
                                                >
                                                    <option value="">{`--select--`}</option>
                                                    {CONDITIONS && Object.keys(CONDITIONS).map((key) => {
                                                        return (
                                                            <option value={CONDITIONS[key].VALUE_KEY}>{CONDITIONS[key].DISPLAY_KEY}</option>
                                                        )
                                                    })}

                                                </select>
                                            </div>
                                        </td>

                                        <td style={{
                                            display: item.condition === CONDITIONS.BETWEEN.VALUE_KEY ? 'flex' : undefined,
                                            justifyContent: 'space-between'
                                        }}>
                                            {item.condition !== CONDITIONS.ELSE.VALUE_KEY && (
                                                <React.Fragment>
                                                    <>
                                                        <div className='input_wrapper'>
                                                            <CustomDatePicker
                                                                labelStyle={{
                                                                    marginBottom: "4px",
                                                                    fontSize: ".7rem",
                                                                    color: "#fff",
                                                                    fontWeight: 'bold'
                                                                }}
                                                                bgColor={"#fff"}

                                                                type='date'
                                                                onChange={(value) => {
                                                                    console.log("CALLING", value)
                                                                    _onChange("value", value, index,);

                                                                }} value={item?.['value']} />
                                                        </div>

                                                        {item.condition === CONDITIONS.BETWEEN.VALUE_KEY && (
                                                            <div className='input_wrapper'
                                                                style={{
                                                                    width: item.condition === CONDITIONS.BETWEEN.VALUE_KEY ? '47%' : undefined
                                                                }}
                                                            >
                                                                <CustomDatePicker
                                                                    labelStyle={{
                                                                        marginBottom: "4px",
                                                                        fontSize: ".7rem",
                                                                        color: "#fff",
                                                                        fontWeight: 'bold'
                                                                    }}
                                                                    bgColor={"#fff"}

                                                                    type='date'
                                                                    onChange={(value) => {

                                                                        _onChange("value2", value, index,);

                                                                    }} value={item?.['value2']} />
                                                            </div>
                                                        )}</>
                                                </React.Fragment>
                                            )}
                                        </td>

                                        <td style={{ position: "relative" }}>
                                            <div className='input_wrapper'>
                                                <input
                                                    value={item.display_value ? item.display_value : ''}
                                                    onChange={(e) => {
                                                        _onChange("display_value", e.target.value, index,);

                                                    }}
                                                    onFocus={(e) => {

                                                    }}
                                                    onClick={(e) => {

                                                    }}
                                                    onBlur={() => {
                                                        // setTimeout(() => {
                                                        //     toggle_dropdown(j, false);
                                                        // }, 1000)
                                                    }}
                                                    type="text"
                                                />
                                            </div>
                                        </td>

                                        <td>
                                            <RippleEffect
                                                width="25"
                                                icon={<Delete size=".8rem" height=".8rem" color="#222" />}
                                                title={"delete"}
                                                Click={
                                                    (e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        delete_row(index);
                                                    }
                                                }
                                            />
                                        </td>
                                    </tr>
                                )

                            })}
                        </tbody>
                    </table>
                </div>
            </TabContent>

            <div
                style={{
                    padding: '.5rem',
                    paddingBottom: '0px',
                    // borderTop: '1px solid #ccc',
                    marginTop: 'auto',
                    display: 'flex',
                    // width: '100%',
                    marginLeft: 'auto',
                    paddingBottom: '.7rem'
                }}>

                <Button
                    primary={true}
                    // mR="1.143rem"
                    bgColor='#4D9DF4'
                    width="7rem"
                    onClick={() => {
                        _local_save()

                    }}
                >Save</Button>

            </div>
        </Wrapper>
    )
}

const mapStateToProps = (state, props) => {

    const advance_date_setting = state?.filterReducer?.advance_date_setting_cache?.[props?.data?.id];

    return { advance_date_setting }

};


export default connect(mapStateToProps, { save_advance_date_config })(AdvanceDateSetting);
