import { simpleDispatch_post, dispatchAction, simpleDispatch_get, simpleDispatch_delete, raiseSuccess } from './helper';
import * as actionTypes from '../action-types/data_wrangler';
import { constants } from '../utils/constants';
import { get, post } from '../data.service';
import * as loaderTypes from '../action-types/loader';


export const save_data_wrangler_job = (job, extraction_data, file_mapping, report_data, load_data, load_mapping_data, history) => async dispatch => {

    const url = constants.END_POINTS.JOB_API + constants.END_POINTS.JOB_V1.POINT + constants.END_POINTS.JOB_V1.SAVE_JOB;

    try {

        const response = await post(url, job);

        if (response && response.code === 200 && response.data.job_id) {

            const job_id = response.data.job_id;

            if (report_data) {

                const report_id = await save_report_v1(report_data) /// saving the report 

                if (report_id && typeof report_id === 'string') {

                    const new_extraction_data = extraction_data ? { ...extraction_data } : {};
                    new_extraction_data.job_id = job_id;
                    new_extraction_data.report_id = report_id;
                    await save_extraction_v1(new_extraction_data, job_id)

                }
            } else {

                const new_extraction_data = extraction_data ? { ...extraction_data } : {};
                new_extraction_data.job_id = job_id;
                // new_extraction_data.report_id = report_id;
                await save_extraction_v1(new_extraction_data, job_id)
            }

            const local_load_mapping_data = load_mapping_data ? [...load_mapping_data] : [];

            // let's save load mapping report to db first 
            // for (let index = 0; index < local_load_mapping_data.length; index++) {
            //     const load_mapping = local_load_mapping_data[index];
            //     if(load_mapping?.local_report){
            //         const load_mapping_rpt_id = await save_report_v1(load_mapping?.local_report) /// saving the report 
            //         local_load_mapping_data[index].report_id = load_mapping_rpt_id
            //     }
            // }

            await save_load_v1(load_data, load_mapping_data, job_id)
            await get_data_wrangler_jobs(job?.job_type)(dispatch)


            if (job?.job_type === "google_drive_upload") {

                const file_mapping_url = constants.END_POINTS.DATA_WRANGLER_API + constants.END_POINTS.DATA_WRANGLER_FILE_MAPPING.POINT + constants.END_POINTS.DATA_WRANGLER_FILE_MAPPING.SAVE_FILE_MAPPING;
                const response = await post(file_mapping_url, { file_mapping, job_id: job_id });
                try {

                    if (response && response.code === 200) {
                        console.log("done")
                    }

                    else throw new Error(response.message);

                } catch (error) {

                    throw error;
                }
            }

            raiseSuccess(dispatch, {
                type: 'success',
                title: 'Success',
                message: "Save Successfully"
            })

            var path = '/etl_jobs';

            if (job?.job_type === "google_drive_upload") {

                path += '?job_type=google_drive_upload'
            }
            if (history) history.push(path)

        }

    } catch (error) {
        raiseSuccess(dispatch, {
            type: 'error',
            title: 'ERROR',
            message: error && error.message ? error.message : 'Somthing Went wrong..'
        })
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
    }

};



export const delete_job = (job, jobType) => async (dispatch) => {

    try {

        const job_clone = job ? { ...job } : {};
        job_clone.deleted = true;

        const url = constants.END_POINTS.JOB_API + constants.END_POINTS.JOB_V1.POINT + constants.END_POINTS.JOB_V1.SAVE_JOB;
        const response = await post(url, job_clone);

        if (response && response.code === 200) {
            get_data_wrangler_jobs(jobType)(dispatch)
            raiseSuccess(dispatch, {
                type: 'success',
                title: 'Success',
                message: "Job Delete Successfully"
            })
        }

    } catch (error) {
        raiseSuccess(dispatch, {
            type: 'error',
            title: 'ERROR',
            message: error && error.message ? error.message : 'Somthing Went wrong..'
        })
    }

}


export const get_data_wrangler_config_by_job_id = (job_id, history) => async dispatch => {

    try {

        const job_url = constants.END_POINTS.JOB_API + constants.END_POINTS.JOB_V1.POINT + constants.END_POINTS.JOB_V1.GET_JOB_BY_ID + `?job_id=${job_id}`;
        const job_response = await get(job_url);

        if (
            job_response && job_response.code === 200 &&
            job_response?.data?.job_data &&
            job_response?.data?.job_data !== 'null'

        ) {

            // console.log("job_re/sponse?.data?.job_data", job_response?.data?.job_data)

            // if(job_response?.data?.job_data.grouping_tag === "etl"){

            const url = constants.END_POINTS.DATA_WRANGLER_API + constants.END_POINTS.DATA_WRANGLER_CONFIG_V1.POINT + constants.END_POINTS.DATA_WRANGLER_CONFIG_V1.GET_CONFIG_BY_JOB_ID + `?job_id=${job_id}`;
            const response = await get(url);

            if (response && response.code === 200 && response.data.etl_config) {

                const etl_config = response.data.etl_config;
                const extraction = etl_config?.extraction;
                const load = etl_config?.load;
                const file_mapping = etl_config?.file_mapping;


                let report = undefined;

                if (extraction && extraction.report_id && extraction.db_interaction_type === 'etl_config_query_builder') {
                    report = await get_report_v1(extraction.report_id)(dispatch)
                }

                const etl_config_to_dispatch = {
                    job_id: job_id,
                    extraction: extraction,
                    load: load,
                    report: report,
                    job_data: job_response?.data?.job_data,
                    file_mapping: file_mapping,
                }
                dispatchAction(dispatch, actionTypes.GET_DATA_WRANGLER_CONFIG_BY_JOB_ID, {
                    etl_config: etl_config_to_dispatch
                })
            }
            // }

        }
        else throw new Error("Job Not Found");

    } catch (error) {

        raiseSuccess(dispatch, {
            type: 'error',
            title: 'ERROR',
            message: error && error.message ? error.message : 'Somthing Went wrong..'
        })
        // if(history) history.push(j
    }

}



export const run_job_forcfully = (job_id, jobType) => async (dispatch) => {

    const url = constants.END_POINTS.JOB_API + constants.END_POINTS.JOB_V1.POINT + constants.END_POINTS.JOB_V1.RUN_JOB;

    await simpleDispatch_post(dispatch, url, { job_id: job_id }, "RUN_JOB",);

    setTimeout(() => {
        get_data_wrangler_jobs(jobType)(dispatch)
    }, 1000)

}



export const update_job_status = (job_ids, status,job_Type) => async (dispatch) => {

    const url = constants.END_POINTS.JOB_API + constants.END_POINTS.JOB_V1.POINT + constants.END_POINTS.JOB_V1.UPDATE_JOB_STATUS;

    await simpleDispatch_post(dispatch, url, { job_ids: job_ids, status: status }, "UPDATE_JOB_STATUS",);

    setTimeout(() => {
        get_data_wrangler_jobs(job_Type)(dispatch)
    }, 1000)

}




export const get_data_wrangler_jobs = (job_type) => async (dispatch) => {

    const url = constants.END_POINTS.JOB_API + constants.END_POINTS.JOB_V1.POINT + constants.END_POINTS.JOB_V1.GET_ALL_JOBS;

    await simpleDispatch_post(dispatch, url, { job_type1: "etl", job_type: job_type }, actionTypes.GET_DATA_WRAGLING_JOBS,);

}

export const get_job_by_id = (job_id) => async (dispatch) => {

    const url = constants.END_POINTS.JOB_API + constants.END_POINTS.JOB_V1.POINT + constants.END_POINTS.JOB_V1.GET_JOB_BY_ID + `?=job_id${job_id}`;

    await simpleDispatch_get(dispatch, url, actionTypes.GET_LOAD_MAPPING_BY_ID,);

}








export const save_report_v1 = async (report, save_type = "ETL", get_query = false) => {

    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.SAVE_REPORT_V1;

    const response = await post(url, { report: report, save_type: save_type });

    if (response && response.code === 200 && response.data.report_id) {
        const report_id = response.data.report_id;
        return report_id;
    }
};




export const save_extraction_v1 = async (extraction_data, job_id) => {

    const url = constants.END_POINTS.DATA_WRANGLER_API + constants.END_POINTS.DATA_WRANGLER_EXTRACTION.POINT + constants.END_POINTS.DATA_WRANGLER_EXTRACTION.SAVE_EXTRACTION;

    const response = await post(url, { extraction_data: extraction_data, job_id: job_id });

    try {
        if (response && response.code === 200 && response.data.extraction_id) {
            return await response.data.extraction_id
        }
        else throw new Error(response.message);
    } catch (error) {
        throw error;
    }
};



export const save_load_v1 = async (load_data, load_mapping, job_id) => {

    const url = constants.END_POINTS.DATA_WRANGLER_API + constants.END_POINTS.DATA_WRANGLER_LOAD.POINT + constants.END_POINTS.DATA_WRANGLER_LOAD.SAVE_LOAD;

    const response = await post(url, { load_data: load_data, load_mapping: load_mapping, job_id: job_id });

    try {
        if (response && response.code === 200 && response.data.load_id) return await response.data.load_id

        else throw new Error(response.message);

    } catch (error) {
        throw error;
    }
};







export const get_report_v1 = (report_id) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.GET_REPORT_BY_ID_V1 + `?report_id=${report_id}`;

    const response = await get(url);

    if (response && response.code === 200 && response?.data?.report) {
        return await response.data?.report
    } else {
        return await undefined;
    }
    // await simpleDispatch_get(dispatch, url, actionTypes.GET_REPORT_BY_ID,);
}




export const get_extraction_v1 = (extraction_id) => async dispatch => {

    const url = constants.END_POINTS.DATA_WRANGLER_API + constants.END_POINTS.DATA_WRANGLER_EXTRACTION.POINT + constants.END_POINTS.DATA_WRANGLER_EXTRACTION.GET_EXTRACTION_BY_ID + `?extraction_id=${extraction_id}`;
    const response = await get(url);

    if (response && response.code === 200 &&
        response?.data?.extraction_data &&
        response?.data?.extraction_data
    ) {
        get_report_v1(response?.data?.extraction_data?.report_id)(dispatch)
    }

    await simpleDispatch_get(dispatch, url, actionTypes.GET_EXTRACTION_BY_ID,);
}




export const get_load_mapping_v1 = (load_mapping_id) => async dispatch => {

    const url = constants.END_POINTS.DATA_WRANGLER_API + constants.END_POINTS.DATA_WRANGLER_LOAD_MAPPING.POINT + constants.END_POINTS.DATA_WRANGLER_LOAD_MAPPING.GET_LOAD_MAPPING_BY_ID + `?load_mapping_id=${load_mapping_id}`;

    await simpleDispatch_get(dispatch, url, actionTypes.GET_LOAD_MAPPING_BY_ID,);

}




/**
 * 
 * @param {*} job_id 
 * @returns 
 */
export const get_job_occurences = (job_id) => async dispatch => {

    const url = constants.END_POINTS.JOB_API + constants.END_POINTS.JOB_V1.POINT + constants.END_POINTS.JOB_V1.GET_JOB_OCCURENCES_BY_JOB_ID + `?job_id=${job_id}`;

    await simpleDispatch_get(dispatch, url, actionTypes.GET_JOB_OCCURENCES_BY_JOB_ID_SUCCESS, true);

}

/**
 * 
 * @param {*} job_id 
 * @returns 
 */
export const get_job_logs = (job_occurence_id) => async dispatch => {

    const url = constants.END_POINTS.JOB_API + constants.END_POINTS.JOB_V1.POINT + constants.END_POINTS.JOB_V1.GET_JOBS_LOGS_BY_OCCUURANCE_ID + `?job_occurence_id=${job_occurence_id}`;

    await simpleDispatch_get(dispatch, url, actionTypes.GET_JOB_LOGS_BY_OCCURANCE_ID_SUCCESS, true);

}



export const get_errors_records = (job_id, job_occurence_id) => async dispatch => {

    const url = constants.END_POINTS.DATA_WRANGLER_API + constants.END_POINTS.DATA_WRANGLER_EXCEPTIONS_LOGS.POINT + constants.END_POINTS.DATA_WRANGLER_EXCEPTIONS_LOGS.GET_ERRORS_RECORDS + `?job_id=${job_id}&unique_id=${job_occurence_id}`;

    await simpleDispatch_get(dispatch, url, actionTypes.GET_JOB_ERRORS_RECORDS, true);

}






