import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  &:focus-visible {
    outline: none;
  }
`;
const Menu = styled.ul`
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  z-index: 1000;
`;

const MenuItem = styled.li`
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 6px;
  background: ${({ selected }) => (selected ? "linear-gradient(to right, #3E9EFF, #737DFF)" : "#fff")};
  color: ${({ selected }) => (selected ? "#fff" : "#000")};

  &:hover {
    background: linear-gradient(to right, #3E9EFF, #737DFF);
    color: #fff;
  }
`;

const RightClickMenu = ({
    menuItems,
    handleItemClick,
    children,
    menuVisible,
    setMenuVisible,
    menuPosition,
}) => {

    const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
    const wrapperRef = useRef(null);

    const handleKeyDown = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (!menuVisible) return;

        if (event.key === "ArrowDown") {
            setSelectedItemIndex((prevIndex) => (prevIndex + 1) % menuItems.length);
        } else if (event.key === "ArrowUp") {
            setSelectedItemIndex(
                (prevIndex) => (prevIndex - 1 + menuItems.length) % menuItems.length
            );
        } else if (event.key === "Enter" && selectedItemIndex !== -1) {
            handleItemClick(menuItems[selectedItemIndex]);
        }
    };

    const handleChildClick = (event) => {
        event.stopPropagation();
        setMenuVisible(false);
        setSelectedItemIndex(-1);
    };

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setMenuVisible(false);
            setSelectedItemIndex(-1);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <Container
            ref={wrapperRef}
            onKeyDown={handleKeyDown}
            tabIndex={0} // To make the div focusable for keyboard events
            onClick={handleClickOutside}
        >
            <div className="childWrapper" onClick={handleChildClick}>
                {children}
            </div>

            {menuVisible && (
                <Menu style={{ top: menuPosition.y, left: menuPosition.x }}>
                    {menuItems.map((item, index) => (
                        <MenuItem
                            key={item}
                            selected={index === selectedItemIndex}
                            onClick={() => handleItemClick(item)}
                        >
                            {item}
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </Container>
    );
};

export default RightClickMenu;
