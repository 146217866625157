import React, { useState, useEffect } from 'react'
import { PageInnerWrapper } from '../hoc/styled.hoc';
import { getParamByName, getParameterByName, showPopup } from '../../utils';
import enums from '../../utils/enums';
import SharedTable from '../shared-table';
import { connect } from 'react-redux';
import { Button } from '../hoc/button';
import { getRuleTicketInfo, saveTicketAction } from '../../actions/rules.alert'
import { withRouter } from 'react-router-dom';
import TicketDetails from './ticket.details';
import { getAccessGroup } from '../../actions/access_group';
import { getUsers } from '../../actions/account';



const Index = (props) => {



    const [rule_id, set_rule_id] = useState(undefined);
    const [local_rule_tickets, set_local_rule_tickets] = useState(undefined);


    const { getRuleTicketInfo, _hints_ } = props;
    const search = props.history?.location?.search;
    const location = props.history?.location;
    const rule_tickets = props.rule_tickets_cache?.[rule_id];


    useEffect(() => {
        if (location && search) {

            const rule_id = getParameterByName("rule_id", search);
            if (rule_id) {
                getRuleTicketInfo(rule_id)
                set_rule_id(rule_id)
            } else {
                set_rule_id(undefined)
            }
            set_local_rule_tickets(undefined)
        }
    }, [location])



    useEffect(() => {
        set_local_rule_tickets(rule_tickets)
    }, [rule_tickets])


    const raiseDetailsPopup = (item) => {
        showPopup(("Ticket Details"), undefined, enums.popupType.element_with_header, TicketDetails, {
            popupData: item ? item : undefined,
            _saveFun: props.saveRuleAlertInfo,
            hintsData: _hints_,
        }, undefined, undefined, undefined, {
            width: '43rem',
        });
    }




    useEffect(() => {
        let _page_info = {
            title: 'TICKETS',
            isBack: true
        }
        props.dispatchHeaderInformation(_page_info)
    }, [])



    return (
        <div>
            <PageInnerWrapper padding={'10px'} style={{ marginTop: '1rem' }}>
                <SharedTable
                    height={"calc(100vh - 180px)"}
                    // editAction={(item) => {
                    //     raiseDetailsPopup(item)
                    // }}
                    // addAction={(item) => {
                    //     let tempItem = item ? Object.assign({}, item) : {}
                    //     tempItem.is_deleted = true;
                    // }}
                    // viewAction={(item) => {

                    // }}
                    // actionsTitles={{
                    //     "addAction": {
                    //         title: "Add Action",
                    //     },
                    //     "viewAction": {
                    //         title: "View Action",
                    //     },

                    // }}
                    // duplicateAction={() => { }}
                    tableData={(local_rule_tickets && local_rule_tickets.length > 0) ? local_rule_tickets : undefined}
                    tableHead={["Ticket ID", "Ticket Name", "Ticket Description", "Current Status", "Created Date", "Due Date"]}
                    columnsToShow={["id", "ticket_name", "ticket_description", "ticket_status", "ticket_created_at", "ticekt_due_date",]}
                // editedColumns={[
                //     { type: "text", name: "ticket_status" },
                // ]}
                // date_columns={['ticket_created_at', 'ticekt_due_date']}

                />
                {/* {rulesHints == undefined || rulesHints.length == 0 ? <p style={{ textAlign: "center" }}>No Record Found</p> : null} */}
            </PageInnerWrapper>

        </div>
    )

}



const mapStateToProps = (state) => ({
    rule_tickets_cache: state.rulesAlert.rule_tickets_cache
})

export default withRouter(connect(mapStateToProps, {
    getRuleTicketInfo,
    saveTicketAction
})(Index))