import React from 'react';

const TrendingChartSVG = (props) => {

    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}

            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1728 1728"
            space="preserve"
        >
            <path transform="translate(155,116)" d="m0 0h107l8 1 2 2v111l-1 1254 122-1 1182 1 7 1 1 1v113l-5 3-1237 1h-185l-4-2-1-2-1-20-1-1336 1-119 2-7z" fill="#2B77FE" />
            <path transform="translate(773,591)" d="m0 0h24l22 4 16 7 13 9 11 11 7 10 8 16 4 13 1 5 1 21-2 16-1 9 3 3v2l4 2 12 9 17 13 16 12 11 9 26 20 20 15 17 13 14 11 19 14 9 7 5-1 13-8 10-3 18-3h13l25 6 12 5 15 7 6-1 6-4 10-8 18-11 21-14 37-24 28-18 11-7 19-12 20-12 8-5h2l1-3v-41l3-13 8-16 8-12 8-9 11-9 15-8 15-5 11-2h26l16 3 13 5 13 7 11 9 8 8 9 13 7 14 5 16 2 16v9l-3 16-6 16-10 18-8 10-10 10-19 10-16 5-12 2h-26l-16-3-17-7-5-4-5-2-7 1-34 22-18 11-17 11-19 12-17 11-18 12-23 14-17 11-15 8-6 5 1 5 4 15v12l-8 24-5 11-6 10-8 10-9 9-11 8-16 7-14 4-15 2-19-1-17-4-14-6-14-10-10-9-11-14-9-17-5-21v-11l2-11 3-10v-7l-2-4-3-1-5-5-7-6-13-10-18-14-20-15-16-13-16-12-13-10-19-14-16-13-10-7-9-5-3-1h-9l-15 8-19 7h-5l-11-6h-5l-4 2v2h-2l-2 5-10 18-6 10-14 25-13 22-11 19-8 13-12 21-8 13-14 25-13 21-8 15-10 16-13 23-9 15-8 13-6 12-12 19-9 17-9 14-6 11-9 16-14 22-10 19-5 9v7l14 28 5 18 1 6v13l-4 18-6 15-9 15-8 10-5 6-10 8-14 7-15 5-13 3-10 1h-11l-18-3-14-5-15-9-12-11-8-9-8-13-6-13-3-9-2-10v-25l3-14 6-15 5-9 9-11 13-13 15-10 14-6 18-4h27l6-5 9-15 14-24 11-19 12-20 14-24 13-22 14-24 11-19 6-10 13-22 14-24 15-26 13-22 11-19 17-29 20-35 9-15 14-24 6-12 2-5-1-7-12-23-6-16-2-9v-15l4-18 6-16 6-11 7-9 9-10 8-7 13-8 14-5z" fill="#6CA2FD" />
            <path transform="translate(179,1722)" d="m0 0 519 1h883v1l-51 1h-18l-29 1-1 2h-1316l-2-2 43-1h-22l-10-1zm83 3v1h18v-1zm20 0v1h10v-1zm11 0v1h7v-1zm12 0v1h5v-1zm13 0m4 0m5 0m1105 0m4 0m6 0m2 0 4 1zm7 0v1h9v-1z" fill="#95969B" />
            <path transform="translate(268,117)" d="m0 0 4 2v111l-1 1243h-1l-1-414-1-616z" fill="#FDFDFD" />
            <path transform="translate(1721,844)" d="m0 0h1l1 12v285l-1 79h-1l-1-206v-34z" fill="#95969B" />
            <path transform="translate(6,21)" d="m0 0h1v82l-1 106-1 28h-1l-2-127v-80l1-8h2v11z" fill="#BFC0C8" />
            <path transform="translate(13,1718)" d="m0 0h20l61 2v1l-8 1 87 1v1l-32 1h-34l-57-2-28-2-9-2z" fill="#95969B" />
            <path transform="translate(6,76)" d="m0 0h1v27l-1 106-1 28h-1l-1-73v-44h1l1-31-1-4v-3h2z" fill="#B1B1B6" />
            <path transform="translate(13,1718)" d="m0 0h20l61 2v1h-40v2l-32-2-9-2z" fill="#D1D2D8" />
            <path transform="translate(1,1185)" d="m0 0h1l1 34-1 97h-1z" fill="#C0C1C9" />
            <path transform="translate(1721,462)" d="m0 0h1v92h-1z" fill="#95969B" />
            <path transform="translate(13,1718)" d="m0 0h20l5 2 16 1v2l-32-2-9-2z" fill="#C5C5C8" />
            <path transform="translate(6,76)" d="m0 0h1v27l-1 20h-1v-34l-1-4v-3h2zm-3 44 2 3v2h-2z" fill="#B3B3B9" />
            <path transform="translate(1484,1726)" d="m0 0h35l-4 2h-32z" fill="#95969B" />
            <path transform="translate(42,5)" d="m0 0h46v1h-46z" fill="#9A9BA0" />
            <path transform="translate(6,76)" d="m0 0h1v19l-2 1v-7l-1-4v-3h2z" fill="#B1B1B6" />
            <path transform="translate(115,1721)" d="m0 0h26v1h-26z" fill="#95969B" />
            <path transform="translate(1678,1722)" d="m0 0h18v1h-18z" fill="#95969B" />
            <path transform="translate(1,295)" d="m0 0h1v18h-1z" fill="#C0C1C9" />
            <path transform="translate(1520,1726)" d="m0 0h11v1l-11 1z" fill="#95969B" />
            <path transform="translate(1535,1726)" d="m0 0h7v1h-7z" fill="#95969B" />
            <path transform="translate(1,316)" d="m0 0h1v7h-1z" fill="#C0C1C9" />
            <path transform="translate(1,287)" d="m0 0h1v7h-1z" fill="#C0C1C9" />
            <path transform="translate(1667,1722)" d="m0 0h6v1h-6z" fill="#95969B" />
            <path transform="translate(1720,1605)" d="m0 0h1v6h-1z" fill="#95969B" />
            <path transform="translate(1,1318)" d="m0 0h1v6h-1z" fill="#C0C1C9" />
            <path transform="translate(1,329)" d="m0 0h1v5h-1z" fill="#C0C1C9" />
            <path transform="translate(3,18)" d="m0 0h2l-1 3z" fill="#C0C1C9" />
            <path transform="translate(1547,1726)" d="m0 0 4 1z" fill="#95969B" />
            <path transform="translate(157,1726)" d="m0 0 4 1z" fill="#95969B" />
            <path transform="translate(1582,1723)" d="m0 0 4 1z" fill="#95969B" />
            <path transform="translate(1,1332)" d="m0 0 1 4z" fill="#C0C1C9" />
            <path transform="translate(1721,1221)" d="m0 0 1 4z" fill="#95969B" />
            <path transform="translate(1558,1726)" d="m0 0 2 1z" fill="#95969B" />
            <path transform="translate(154,1726)" d="m0 0 2 1z" fill="#95969B" />
            <path transform="translate(1588,1723)" d="m0 0 2 1z" fill="#95969B" />
            <path transform="translate(1674,1722)" d="m0 0 2 1z" fill="#95969B" />
            <path transform="translate(112,1721)" d="m0 0 2 1z" fill="#95969B" />
            <path transform="translate(1720,1613)" d="m0 0" fill="#95969B" />
            <path transform="translate(1,1338)" d="m0 0" fill="#C0C1C9" />
            <path transform="translate(1,1326)" d="m0 0" fill="#C0C1C9" />
            <path transform="translate(1,1182)" d="m0 0" fill="#C0C1C9" />
            <path transform="translate(1,1179)" d="m0 0" fill="#C0C1C9" />
            <path transform="translate(1,1176)" d="m0 0" fill="#C0C1C9" />
            <path transform="translate(1,1170)" d="m0 0" fill="#C0C1C9" />
            <path transform="translate(1721,841)" d="m0 0" fill="#95969B" />
            <path transform="translate(1721,838)" d="m0 0" fill="#95969B" />
            <path transform="translate(1721,826)" d="m0 0" fill="#95969B" />
            <path transform="translate(1721,459)" d="m0 0" fill="#95969B" />
            <path transform="translate(1,239)" d="m0 0" fill="#C0C1C9" />
            <path transform="translate(4,238)" d="m0 0" fill="#B1B1B6" />
            <path transform="translate(92,5)" d="m0 0 2 1z" fill="#B1B1B6" />
            <path transform="translate(89,5)" d="m0 0 2 1z" fill="#B1B1B6" />
            <path transform="translate(39,5)" d="m0 0 2 1z" fill="#B1B1B6" />
            <path transform="translate(35,5)" d="m0 0 2 1z" fill="#B1B1B6" />
            <path transform="translate(1582,1726)" d="m0 0" fill="#95969B" />
            <path transform="translate(1570,1726)" d="m0 0" fill="#95969B" />
            <path transform="translate(1594,1723)" d="m0 0" fill="#95969B" />
            <path transform="translate(1591,1723)" d="m0 0" fill="#95969B" />
            <path transform="translate(1720,1597)" d="m0 0" fill="#95969B" />
            <path transform="translate(1720,1594)" d="m0 0" fill="#95969B" />
            <path transform="translate(1720,1590)" d="m0 0" fill="#95969B" />
            <path transform="translate(1,1346)" d="m0 0" fill="#C0C1C9" />
            <path transform="translate(1,1341)" d="m0 0" fill="#C0C1C9" />
            <path transform="translate(1,1173)" d="m0 0" fill="#C0C1C9" />
            <path transform="translate(1,1168)" d="m0 0" fill="#C0C1C9" />
            <path transform="translate(1,1157)" d="m0 0" fill="#C0C1C9" />
            <path transform="translate(1,901)" d="m0 0" fill="#C0C1C9" />
            <path transform="translate(1721,823)" d="m0 0" fill="#95969B" />
            <path transform="translate(1,335)" d="m0 0" fill="#C0C1C9" />
            <path transform="translate(1,283)" d="m0 0" fill="#C0C1C9" />
            <path transform="translate(1,247)" d="m0 0" fill="#C0C1C9" />
            <path transform="translate(101,5)" d="m0 0" fill="#B1B1B6" />
            <path transform="translate(95,5)" d="m0 0" fill="#B1B1B6" />
            <path transform="translate(31,5)" d="m0 0" fill="#B1B1B6" />
        </svg>

    )

}


export default TrendingChartSVG;