import { simpleDispatch_post, simpleDispatch_get, dispatchAction, showError, raiseSuccess } from './helper';
import * as actionTypes from '../action-types/file.uploader';
import * as loaderTypes from '../action-types/loader';
import { constants } from '../utils/constants';
import { post, axiosPostDownloadReq } from '../data.service';


export const send_File = (response) => async dispatch => {

    // const data = new FormData();

    // for (var x = 0; x < _files.length; x++) {
    //     data.append('nf_uploader', _files[x])
    // }

    // dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);

    try {
        // const url = constants.END_POINTS.API + constants.END_POINTS.PDF_TO_QUESTION_ANSWER.SEND_FILE_TO_UPLOAD;
        // let response = await postMultipartData(dispatch, url, data);

        // if (response && response.code === 200) {
        //     setTimeout(() => {
        //         if (_files && _files.length === 1) {
        //             dispatchAction(dispatch, actionTypes.SINGLE_FILE_SUCCESS, response, undefined, undefined)
        //         }
        //         else {
        //             dispatchAction(dispatch, actionTypes.MULTIPAL_FILE_SUCCESS, response, undefined, undefined)
        //         }
        //         dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        //     }, 100)

        // }

        dispatchAction(dispatch, actionTypes.SINGLE_FILE_SUCCESS, response, undefined, undefined)
    }
    catch (error) {
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        showError(dispatch, error);
    }
};


export const askQuestionToPdf = (data) => async dispatch => {

    dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);

    try {
        const url = constants.END_POINTS.API + constants.END_POINTS.PDF_TO_QUESTION_ANSWER.ASK_QUESTION_TO_PDF;
        const response = await post(url, data);

        if (response && response.code === 200) {
            let _data = response.data ? response.data : undefined;
            dispatchAction(dispatch, actionTypes.ASK_PDF_QUESTION, _data, undefined, undefined, 0)
            dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);

        }


    } catch (error) {
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        showError(dispatch, error);

    }

};


export const askQuestionToPdf_SECOND_API = (data) => async dispatch => {

    dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);

    try {
        const url = constants.END_POINTS.API + constants.END_POINTS.PDF_TO_QUESTION_ANSWER.ASK_QUESTION_TO_PDF_SECOND_API;
        const response = await post(url, data);

        if (response && response.code === 200) {
            let _data = response.data ? response.data : undefined;
            dispatchAction(dispatch, actionTypes.ASK_PDF_QUESTION_PDF_SECOND, _data, undefined, undefined, 0)
            dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);

        }


    } catch (error) {
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        showError(dispatch, error);

    }

};



export const get_uploaded_files_list = (folderName = "default") => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.PDF_TO_QUESTION_ANSWER.LIST_OF_UPLOADER_FILES;
    // const url = 'http://192.168.1.6:29302/api/v1/ask-from-text/list-of-uploaded-files'
    if (folderName) {
        let _data = {
            name: folderName
        };
        await simpleDispatch_post(dispatch, url, _data, actionTypes.GET_FILE_LIST, true);

    }
};



export const get_category_folder_list = () => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.PDF_TO_QUESTION_ANSWER.LIST_OF_CATEGORY_FOLDER;

    await simpleDispatch_get(dispatch, url, actionTypes.LIST_OF_CATEGORY_FOLDER, true);
};



export const get_uploaded_table_list = () => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.PDF_TO_QUESTION_ANSWER.UPLOADED_TABLE_LIST;

    await simpleDispatch_get(dispatch, url, actionTypes.LIST_OF_UPLOADED_TABLE_LIST, true);
};


export const get_uploaded_file_info = (id) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.PDF_TO_QUESTION_ANSWER.LIST_OF_FILE_INFO + `?group_id=${id}`;

    await simpleDispatch_get(dispatch, url, actionTypes.LIST_OF_UPLOADED_FILE_INFO, true);
};


export const delete_grouped_file = (table_name, group_id) => async dispatch => {
    const _post = {
        table_name,
        group_id
    }

    const url = constants.END_POINTS.API + constants.END_POINTS.PDF_TO_QUESTION_ANSWER.DELETE_GROUPED_FILE;

    await simpleDispatch_post(dispatch, url, _post, actionTypes.DELETED_GROUPED_FILE_SUCCESS);
};








export const getAllFiles = () => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.FILE_UPLOAD_INFO.POINT + constants.END_POINTS.FILE_UPLOAD_INFO.GET_ALL_FILES;

    await simpleDispatch_get(dispatch, url, actionTypes.GET_ALL_FILES, true);
};

export const getViewFileTableData = (data) => async dispatch => {

    await dispatchAction(dispatch, actionTypes.CLEAR_THE_SIMPALE_FILE_DETAILS)
    const url = constants.END_POINTS.API + constants.END_POINTS.FILE_UPLOAD_INFO.POINT + constants.END_POINTS.FILE_UPLOAD_INFO.GET_THE_SAMPLE_FILE_DETAILS;

    await simpleDispatch_post(dispatch, url, data, actionTypes.GET_THE_SIMPALE_FILE_DETAILS_SUCCESS)
};


export const getMetaInfoOfFile = (data) => async dispatch => {


    await dispatchAction(dispatch, actionTypes.CLEAR_THE_META_DETAILS_OF_THE_FILE);

    const url = constants.END_POINTS.API + constants.END_POINTS.FILE_UPLOAD_INFO.POINT + constants.END_POINTS.FILE_UPLOAD_INFO.GET_THE_META_DETAILS;

    await simpleDispatch_post(dispatch, url, data, actionTypes.GET_THE_META_DETAILS_OF_FILE)
};



export const getFilesByPlatform = () => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.FILE_UPLOAD_INFO.POINT + constants.END_POINTS.FILE_UPLOAD_INFO.GET_FILES_BY_PLATFORMS;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_FILE_BY_PLATFORM, true);
};






export const getFilesByDimension = () => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.FILE_UPLOAD_INFO.POINT + constants.END_POINTS.FILE_UPLOAD_INFO.GET_FILE_BY_DIMENSION;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_FILES_BY_DIMENSION);
    // await simpleDispatch_post(dispatch, url, undefined, actionTypes.GET_FILE_BY_PLATFORM)
};





export const approveTableRequest = (data) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.PDF_TO_QUESTION_ANSWER.APPROVE_TABLE_REQUEST;

    await simpleDispatch_post(dispatch, url, data, actionTypes.APPROVED_TABLE_REQUEST)

}



export const deleteFile = (data) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.NF_FILE_UPLOAD_INFO.POINT + constants.END_POINTS.NF_FILE_UPLOAD_INFO.DELETE_THE_FILE;

    try {
        dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);

        const response = await post(url, data);

        if (response && response.code === 200) {

            await dispatchAction(dispatch, actionTypes.DELETE_THE_FILE)
            await raiseSuccess(dispatch, {
                type: 'success',
                message: "File deleted Successfully"
            })
            await getAllFiles()(dispatch)
            await getFilesByPlatform()(dispatch)
            await getFilesByDimension()(dispatch)
        }

    } catch (error) {
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        const _message = typeof error === 'object' ? (error.custom_message ? error.custom_message : '') : error;

        raiseSuccess(dispatch, {
            type: 'error',
            title: 'ERROR',
            message: _message,
        })
    }


}

export const downloadTheFile = (data) => async dispatch => {

    try {
        const url = constants.END_POINTS.API + constants.END_POINTS.FILE_UPLOAD_INFO.POINT + constants.END_POINTS.FILE_UPLOAD_INFO.DOWNLOAD_THE_CSV;

        await axiosPostDownloadReq(url, { file_name: data })
        raiseSuccess(dispatch, {
            type: 'success',
            message: "Download Successfully"
        })

    } catch (error) {
        raiseSuccess(dispatch, {
            type: 'error',
            title: 'ERROR',
            message: "Download Failed",
        })
    }
}


export const processTheFile = (data) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.FILE_UPLOAD_INFO.POINT + constants.END_POINTS.FILE_UPLOAD_INFO.PROCESS_THE_FILE;
    try {

        dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);

        const response = await post(url, data);


        if (response && response.code === 200) {
            if (response.data && response.data.data && response.data.user_inputs_required_to_go_ahead) {
                await dispatchAction(dispatch, actionTypes.PROCESS_THE_FILE, response.data.data);
            }
            else {
                await dispatchAction(dispatch, actionTypes.PROCESS_THE_FILE)
                await raiseSuccess(dispatch, {
                    type: 'success',
                    message: "File Process Successfully"
                })
                await getAllFiles()(dispatch)
                await getFilesByPlatform()(dispatch)
                await getFilesByDimension()(dispatch)
            }
        }
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);

    } catch (error) {
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        const _message = typeof error === 'object' ? (error.custom_message ? error.custom_message : '') : error;

        raiseSuccess(dispatch, {
            type: 'error',
            title: 'ERROR',
            message: _message,
        })
    }
}

export const clearProcessFileData = () => async dispatch => {
    await dispatchAction(dispatch, actionTypes.CLEAR_PROCESS_THE_FILE);
}

export const setSelectedFile = name => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.PDF_TO_QUESTION_ANSWER.SELECT_FILE;
    if (name && name.length > 0) await simpleDispatch_post(dispatch, url, { fileName: name }, actionTypes.GET_META_DATA, true);

    dispatchAction(dispatch, actionTypes.SET_CURRENT_FILE_NAME, name)
}


export const mt__pdf_answer = () => async dispatch => {
    await dispatchAction(dispatch, actionTypes.MT_ANSWER_SUCCESS)

}


export const clear_cashed = (file) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.PDF_TO_QUESTION_ANSWER.CLEAR_CASHED_INFO;
    await simpleDispatch_post(dispatch, url, file, actionTypes.CLEAR_CASHED_INFO)

}



export const getPdfQuestions = () => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.PDF_QUESTIONS.POINT + constants.END_POINTS.PDF_QUESTIONS.GET_PDF_QUESTIONS;

    await simpleDispatch_post(dispatch, url, {}, actionTypes.GET_PDF_QUESTIONS)

}



export const savePdfQuestions = (data) => async dispatch => {

    let _data = {
        data: data,
    }
    const url = constants.END_POINTS.API + constants.END_POINTS.PDF_QUESTIONS.POINT + constants.END_POINTS.PDF_QUESTIONS.SAVE_PDF_QUESTIONS;

    await simpleDispatch_post(dispatch, url, _data, actionTypes.SAVE_PDF_QUESTIONS)

}



export const saveSetOfQuestion = (data, selectedFile, _base_path, output_type) => async dispatch => {

    let post_data = {
        setOfQuestions: data,
        files_name: selectedFile,
        base_path: _base_path,
        output_type: output_type
    }

    const url = constants.END_POINTS.API + constants.END_POINTS.PDF_QUESTIONS.POINT + constants.END_POINTS.PDF_QUESTIONS.SAVE_SET_OF_QUESTIONS;

    await simpleDispatch_post(dispatch, url, post_data, actionTypes.SAVE_SET_OF_QUESTIONS)
}




export const getSetOfQuestionAnswerByJobId = (job) => async dispatch => {

    let post_data = {
        id: job.id
    }

    const url = constants.END_POINTS.API + constants.END_POINTS.PDF_QUESTIONS.POINT + constants.END_POINTS.PDF_QUESTIONS.GET_ANSWERS_BY_JOB_ID;
    await simpleDispatch_post(dispatch, url, post_data, actionTypes.SAVE_SET_OF_QUESTIONS)
}







export const getJobsList = () => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.PDF_QUESTIONS.POINT + constants.END_POINTS.PDF_QUESTIONS.GET_JOBS_LIST;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_JOBS_LIST_SUCCESS, true);
};

export const deleteConfigById = (job) => async dispatch => {

    const post_data = {
        id: job.id
    }

    const url = constants.END_POINTS.API + constants.END_POINTS.PDF_QUESTIONS.POINT + constants.END_POINTS.PDF_QUESTIONS.DELETE_JOB_BY_ID;
    await simpleDispatch_post(dispatch, url, post_data, actionTypes.DELETE_JOB_BY_ID)
};




export const getTableFromDb = (config_id) => async (dispatch) => {
    let post_data = {
        database_id: config_id
    }
    dispatchAction(dispatch, actionTypes.CLEAR_TABLE_FROM_DB)
    const url = constants.END_POINTS.API + constants.END_POINTS.DB_INFO.POINT + constants.END_POINTS.DB_INFO.GET_TABLE_OF_DB;
    await simpleDispatch_post(dispatch, url, post_data, actionTypes.GET_TABLE_FROM_DB_SUCCESS, true, undefined);
}



export const get_import_rules = () => async (dispatch) => {

    const url = constants.END_POINTS.API + constants.END_POINTS.FILE_UPLOAD_INFO.POINT + constants.END_POINTS.FILE_UPLOAD_INFO.GET_NF_IMPORT_RULES;

    await simpleDispatch_get(dispatch, url, actionTypes.GET_IMPORT_RULES_SUCCESS, true, undefined);
}






export const saveRequiredColumnsForJson = (json_file_path, table_name, columns) => async (dispatch) => {

    const post_data = {
        json_file_path: json_file_path,
        columns: columns,
        table_name, table_name
    }
    // dispatchAction(dispatch, actionTypes.CLEAR_TABLE_FROM_DB)
    const url = constants.END_POINTS.API + constants.END_POINTS.FILE_UPLOAD_INFO.POINT + constants.END_POINTS.FILE_UPLOAD_INFO.SAVE_REQUIRED_JSON_COLUMNS;
    await simpleDispatch_post(dispatch, url, post_data, "SAVE ", true, undefined);
}


