export const FILE_UPLOADER_INIT = 'modules/FILE_UPLOADER/init';

export const SEND_FILE_SUCCESS = 'modules/SEND_FILE_SUCCESS/success';

export const ASK_PDF_QUESTION = 'modules/ASK_PDF_QUESTION/question';

export const ASK_PDF_QUESTION_PDF_SECOND = 'modules/Ask_question/question_second_api';

export const GET_FILE_LIST = 'modules/GET_FILE_LIST/success';

export const SET_CURRENT_FILE_NAME = 'modules/SET_CURRENT_FILE_NAME/success';
export const GET_META_DATA = 'modules/ASK_PDF_QUESTION/get_meta_data';

export const SINGLE_FILE_SUCCESS = 'modules/SINGLE_FILE_SUCCESS/success';

export const MULTIPAL_FILE_SUCCESS = 'modules/MULTIPAL_FILE_SUCCESS/success';

export const MT_ANSWER_SUCCESS = 'modules/mt_selected_answer';

export const CLEAR_CASHED_INFO = 'modules/clear_cashed_info';


export const GET_PDF_QUESTIONS = 'modules/get_pdf_questions';
export const SAVE_PDF_QUESTIONS = 'modules/save_pdf_questions';
export const SAVE_SET_OF_QUESTIONS = 'modules/save_set_of_questions';
export const GET_JOBS_LIST_SUCCESS = 'modules/get_jobs_list_success';
export const DELETE_JOB_BY_ID = 'modules/delete_job_by_id';
export const LIST_OF_CATEGORY_FOLDER = 'modules/LIST_OF_CATEGORY_FOLDER'

export const LIST_OF_UPLOADED_TABLE_LIST = 'modules/LIST_OF_UPLOADED_TABLE_list'
export const LIST_OF_UPLOADED_FILE_INFO = 'modules/LIST_OF_UPLOADED_FILE_info';
export const LIST_OF_FILE_GROUPED = 'modules/LIST_OF_FILE_GROUPED';

export const DELETED_GROUPED_FILE_SUCCESS = 'modules/DELETED_GROUPED_FILE_SUCCESS';
export const APPROVED_TABLE_REQUEST = 'modules/APPROVED_TABLEREQUEST'

export const GET_TABLE_EDITS_INFORMATION = 'modules/GET_TABLE_EDITS_INFORMATION'



// const

export const GET_ALL_FILES = 'modules/file.uploader/get_all_files';
export const GET_FILES_BY_DIMENSION = 'modules/file.uploader/get_file_by_dimension';
export const GET_FILE_BY_PLATFORM = 'modules/file.uploader/get_file_by_platform'
export const DELETE_THE_FILE = 'modules/DELETE_THE_FILE'
export const GET_THE_SIMPALE_FILE_DETAILS_SUCCESS = 'modules/file.uploader/get_the_simple_file_details';
export const CLEAR_THE_SIMPALE_FILE_DETAILS = 'modules/file.uploader/clear_the_simple_file_details';
export const GET_THE_META_DETAILS_OF_FILE = 'modules/file.uploader/get_the_meta_details';
export const CLEAR_THE_META_DETAILS_OF_THE_FILE = 'modules/file.uploader/clear_the_meta_details_of_the_File';
export const DOWNLOAD_THE_CSV_FILE = 'modules/file.uploader/download_the_csv_file';
export const PROCESS_THE_FILE = 'modules/file.uploader/process_the_file';
export const CLEAR_PROCESS_THE_FILE = 'modules/file.uploader/clear_process_the_file';

export const CLEAR_TABLE_FROM_DB = 'modules/file.uploader/clear_table_from_db'
export const GET_TABLE_FROM_DB_SUCCESS = 'modules/file.uploader/get_table_from_db'


export const GET_IMPORT_RULES_SUCCESS = 'modules/file.uploader/get_import_rules_success';

export const GET_TABLE_OF_DB_SUCCESS = 'modules/file.uploader/get_tables_of_db';