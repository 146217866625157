import styled from 'styled-components';

export const PredictiveWrapper = styled.div`
    width: 100%;

    .inputFiled{
        border-radius: 3px;
        height: 35px;
        padding: 10px;
        box-sizing: border-box;
        border: 1px solid #ccc;
        width: 150px;
    }


    .btn{
        background: rgb(99, 182, 214);
        border: 0px;
        height: 35px;
        margin-left: 10px;
        border-radius: 3px;
        width: 100px;
        color: #fff;
        cursor: pointer;
        text-transform: uppercase;

        &:hover{
            opacity: .8;
        }
    }

    .row{
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;