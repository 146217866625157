import * as actions from '../action-types/account';
import { saveInSession } from '../utils/session.helper';
import { constants } from '../utils/constants';

const initialState = {
    type: actions.LOGIN_INIT,
    user: undefined,
    recordsCount: undefined,
    token: undefined,
    usersList: undefined,
    userPreference: undefined,
    user_sessions: undefined
};


const accountReducer = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    // if (action && action.data && typeof action.data === 'object') {
    //     action.data.key = new Date();
    // }

    switch (action.type) {
        case actions.LOGIN_SUCCESS:
            return {
                ...state,
                type: action.type,
                user: action.data,
            };

        case actions.AUTHENTICATE_SUCCESS:
            const token = action.data && action.data.token ? action.data.token : undefined;
            saveInSession(constants.SESSION_KEYS.TOKEN, token);

            return {
                ...state,
                type: action.type,
                token
            };

        case actions.DISPATCH_TOKEN:
            return {
                ...state,
                type: action.type,
                token: action.data
            };
        case actions.GET_USER_LIST:
            return {
                ...state,
                type: action.type,
                usersList: action.data
            }

        case actions.GET_USER_PREFERENCES:
            return {
                ...state,
                type: action.type,
                userPreference: action.data
            }
        case actions.GET_USER_SESSION_SUCCESS:
            return {
                ...state,
                type: action.type,
                user_sessions: action.data
            }
        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
            return {
                type: state.type,
                user: state.user,
                token: state.token,
                recordsCount: state.recordsCount,
                usersList: state.usersList,
                userPreference: state.userPreference,
                user_sessions: state.user_sessions
            };
    }
};

export default accountReducer;