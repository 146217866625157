import * as actions from '../action-types/import.error';

const initialState = {
    type: actions.IMPORT_ERROR_INIT,
    errorData: undefined,
    columnsInfo: undefined

};


const ImportErrorReducer = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        case actions.GET_IMPORT_INFO:
            return {
                ...state,
                type: action.type,
                errorData: action.data
            }
        case actions.GET_COLUMNS_INFO:
            return {
                ...state,
                type: action.type,
                columnsInfo: action.data
            }
        default:
            return { ...state };
    }
};

export default ImportErrorReducer;