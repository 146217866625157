import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TextBox from '../hoc/textBox';
import { Button } from '../hoc/button';
import CustomCalendar from '../custom-calendar';
import { getTimeSlotList, getBookedInfyCurrentDate } from '../../actions/demo-request';

import { connect } from 'react-redux';


const Wrapper = styled.div`
    width: 30rem;
    border-radius: 6px;
    border: 2px solid #ccc;
    background-color: #fff;
    padding: 1rem;

    h1{
        font-size: 1.2rem;
        font-weight: 400;
        margin-top: 0px;
        margin-bottom: 1rem;
        text-align: center;
    }

`;


function AddEditPopup(props) {

    const { close, popupKey, option, data, timeSlotList, bookedList, getTimeSlotList, getBookedInfyCurrentDate } = props;
    const { keys, info, save__func, } = data;
    const [formData, setFormData] = useState({});
    const [selectedDateTime, setSelectedDateTime] = useState(undefined)


    useEffect(() => {
        getTimeSlotList()
        getBookedInfyCurrentDate()
    }, [])


    useEffect(() => {
        if (info) {
            setFormData(info)
            setSelectedDateTime({
                time: info['time_slot_id'],
                date: info['demo_date']
            })
        }

    }, [info])



    const on_Save_function = () => {
        let temp_data = JSON.parse(JSON.stringify(formData))
        if (info) {
            temp_data.time_slot_id = selectedDateTime['time']
            temp_data.demo_date = selectedDateTime['date']

            save__func(temp_data)
        }
        else {
            temp_data.id = undefined;
            save__func(temp_data)
        }

    }




    const onChange = (value, key) => {
        let cloneFormData = JSON.parse(JSON.stringify(formData));
        cloneFormData[key] = value;
        setFormData(cloneFormData)
    }


    return (
        <Wrapper>
            <h1>
                {info ? "Edit Request" : "Add New Request"}
            </h1>

            {keys && keys.length > 0 && keys.map((key, i) => {
                return (
                    <div key={"ed"+ i} style={{ width: '100%', marginBottom: '10px' }}>
                        <TextBox
                            label={key}
                            labelStyle={{
                                textTransform: 'capitalize'
                            }}
                            onChange={(e) => {
                                onChange(e.target.value, key)
                            }}
                            value={(formData && formData[key] !== undefined) ? formData[key] : undefined}
                        />
                    </div>
                )
            })}

            <CustomCalendar

                bookedList={bookedList}
                timeSlotList={timeSlotList}
                selectedDateTime={selectedDateTime}
                setSelectedDateTime={setSelectedDateTime}
            />



            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '2rem' }}>
                <Button
                    width="6rem"
                    mR="1rem"
                    primary={true}
                    onClick={() => {
                        on_Save_function()
                        close(option, popupKey)

                    }}>Approve</Button>
                <Button width="6rem" bgColor={'#e6e6e6'} onClick={() =>
                    close(option, popupKey)
                }>Cancel</Button>
            </div>

        </Wrapper>
    )
}

const mapStateToProps = (state) => ({
    timeSlotList: state.demoRequestReducer.timeSlotList,
    bookedList: state.demoRequestReducer.bookedList
})

export default connect(mapStateToProps, { getTimeSlotList, getBookedInfyCurrentDate })(AddEditPopup)
