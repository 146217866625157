import { showError, killError } from './helper';


export const raiseError = error => dispatch => {
    showError(dispatch, error)
};


export const hideError = key => dispatch => {
    killError(dispatch, key);
};

