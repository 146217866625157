import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getActualByPlanId, getAllPlannedMettings, saveActualPlan, savePlannedMeeting } from '../../actions/actual_plan';
import { Button } from '../hoc/button';
import { PageInnerWrapper } from '../hoc/styled.hoc';
import TextBoxV1 from '../hoc/text.box.v1';
import { Wrapper, PlannedMettingWrapper, ActualMeeting } from './actual_plan.style';
import SharedTable from '../shared-table';
import AddSvg from '../svg/add';
import BackSvg from '../svg/back'
import RippleEffect from '../hoc/ripple-effect';
import EditSvg from '../svg/edit';
import DeleteSvg from '../svg/delete';
import SaveSvg from '../svg/save'
import { convertDateInToHtmlDate, convert_date_to_dd_mm_yyyy_v1, generateUniqueKey } from '../../utils';
import CheveronDown from '../svg/chevron.down';



const Index = (props) => {


    const { plannedMeetings, actualMeetings } = props;

    const [selectedMeetingID, setSelectedMeetingID] = useState(undefined);

    const [actualFormData, setActualFormData] = useState(undefined);
    const [isAddEdit, setIsAddEdit] = useState(undefined);

    const [planned_form_data, set_planned_form_data] = useState(undefined);

    const [planned_edit_id, set_planned_edit_id] = useState(undefined);


    const [local_plannedMeetings, set_local_plannedMeetings] = useState(undefined)



    useEffect(() => {
        set_local_plannedMeetings(plannedMeetings)
    }, [plannedMeetings])

    useEffect(() => {
        const _page_info = {
            title: 'customer visit report',
        }
        props.dispatchHeaderInformation(_page_info)


    }, [])


    useEffect(() => {
        props.getAllPlannedMettings()
    }, [])


    const actualOnChange = (key, value) => {
        const clone_actual_formData = actualFormData ? { ...actualFormData } : {};
        clone_actual_formData[key] = value;
        setActualFormData(clone_actual_formData)
    }


    const planned_on_change = (key, value) => {
        const clone_planned_form_data = planned_form_data ? { ...planned_form_data } : {};
        clone_planned_form_data[key] = value;
        set_planned_form_data(clone_planned_form_data)
    }


    const localSaveActualPlan = () => {
        const actual_data = actualFormData ? { ...actualFormData } : {};
        actual_data.plan_id = selectedMeetingID;
        // let's save 
        props.saveActualPlan(actual_data, selectedMeetingID)
        setIsAddEdit(false)
        setActualFormData(undefined)
    }

    const localSavePlannedMetting = () => {
        const temp_planned_form_data = planned_form_data ? { ...planned_form_data } : {};
        // let's save 
        delete temp_planned_form_data["id"];
        props.savePlannedMeeting(temp_planned_form_data)

        set_planned_edit_id(undefined)
        set_planned_form_data(undefined)
    }



    const create_new_meeting = () => {
        const temp_local_plannedMeetings = local_plannedMeetings ? [...local_plannedMeetings] : [];
        const id = generateUniqueKey("temp_id")

        temp_local_plannedMeetings.push({
            id: id
        })

        set_local_plannedMeetings(temp_local_plannedMeetings)
    }


    const delete_metting = (metting) => {

        if (!metting) return;

        if (metting.__id__) {

            // let's delete from db
            const temp_metting = metting ? { ...metting } : {};
            // let's save 
            temp_metting.deleted = true;
            delete temp_metting["id"];
            props.savePlannedMeeting(temp_metting)


        } else {

            // delete from local

            const clone_local_plannedMeetings = local_plannedMeetings ? [...local_plannedMeetings] : [];
            const index = clone_local_plannedMeetings.findIndex((item) => item.id === metting.id);
            clone_local_plannedMeetings.splice(index, 1);
            set_local_plannedMeetings(clone_local_plannedMeetings)

        }

    }


    // console.log("local_plannedMeetings", local_plannedMeetings)


    return (
        <PageInnerWrapper padding={'10px'} style={{ marginTop: '1rem' }} onClick={() => {
            // setSelectedMeetingID(undefined)
        }}>
            <Wrapper>
                <PlannedMettingWrapper onClick={(e) => {
                    e.stopPropagation()
                    setSelectedMeetingID(undefined)
                }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h3>Planned Visit</h3>
                        <div className='add_meeting' onClick={() => {
                            create_new_meeting()
                            setIsAddEdit(false)
                        }}>
                            <AddSvg size=".9rem" color="#fff" />&nbsp; &nbsp; Create Meeting
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <td>Plan Id</td>
                                <td>Date</td>
                                <td>Time</td>
                                <td>Asm Name</td>
                                <td>Asm Code</td>
                                <td>Duration</td>
                                <td>Customer Code</td>
                                <td>Customer Name</td>
                                <td>Location</td>
                                <td>Customer Representative</td>
                                <td>Agenda Points</td>
                                <td style={{ width: '70px' }}>Action</td>


                            </tr>
                        </thead>
                        <tbody>
                            {local_plannedMeetings?.length > 0 && local_plannedMeetings.map((plan_row) => {

                                const is_edited_row = (planned_form_data?.id && planned_form_data?.id === plan_row?.id) || (planned_form_data?.__id__ === plan_row?.__id__);

                                if (is_edited_row) {
                                    return (
                                        <tr style={{ cursor: 'pointer', background: selectedMeetingID === plan_row?.__id__ ? "#e2e2e29e" : undefined }}
                                            onClick={() => {
                                                // setIsAddEdit(undefined)
                                            }}>
                                            <React.Fragment>
                                                <td style={{ padding: '2px' }}></td>
                                                <td style={{ padding: '2px' }}>
                                                    <input type="date" value={planned_form_data?.date ? convertDateInToHtmlDate(planned_form_data?.date, "", true) : ""} onChange={(e) => {
                                                        planned_on_change("date", e.target.value)
                                                    }} />
                                                </td>
                                                <td style={{ padding: '2px' }}> <input type="time" value={planned_form_data?.time} onChange={(e) => {
                                                    planned_on_change("time", e.target.value)
                                                }} /></td>
                                                <td> <input type="text" value={planned_form_data?.asm_name} onChange={(e) => {
                                                    planned_on_change("asm_name", e.target.value)
                                                }} /></td>
                                                <td style={{ padding: '2px' }}>
                                                    <input type="text" value={planned_form_data?.asm_code} onChange={(e) => {
                                                        planned_on_change("asm_code", e.target.value)
                                                    }} /></td>
                                                <td style={{ padding: '2px' }}>
                                                    <input type="text" value={planned_form_data?.duration} onChange={(e) => {
                                                        planned_on_change("duration", e.target.value)
                                                    }} /></td>
                                                <td style={{ padding: '2px' }}>
                                                    <input type="text" value={planned_form_data?.customer_code} onChange={(e) => {
                                                        planned_on_change("customer_code", e.target.value)
                                                    }} /></td>
                                                <td style={{ padding: '2px' }}>
                                                    <input type="text" value={planned_form_data?.customer_name} onChange={(e) => {
                                                        planned_on_change("customer_name", e.target.value)
                                                    }} /></td>
                                                <td style={{ padding: '2px' }}>
                                                    <input type="text" value={planned_form_data?.location} onChange={(e) => {
                                                        planned_on_change("location", e.target.value)
                                                    }} /></td>
                                                <td style={{ padding: '2px' }}>
                                                    <input type="text" value={planned_form_data?.name_of_customers_representative} onChange={(e) => {
                                                        planned_on_change("name_of_customers_representative", e.target.value)
                                                    }} /></td>
                                                <td style={{ padding: '2px' }}>
                                                    <input type="text" value={planned_form_data?.agenda_points} onChange={(e) => {
                                                        planned_on_change("agenda_points", e.target.value)
                                                    }} /></td>
                                                <td>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <div className="action_btn"
                                                            title="Edit"
                                                            style={{ backgroundColor: '#2196f3b3' }}
                                                            onClick={() => {
                                                                // setActualFormData(plan_row);
                                                                localSavePlannedMetting()

                                                            }}>
                                                            <SaveSvg size=".8rem" color="#fff" />
                                                        </div>
                                                        <div className="action_btn"
                                                            title="Delete"
                                                            style={{ backgroundColor: 'red' }}
                                                            onClick={() => {
                                                                delete_metting(plan_row)

                                                            }}>
                                                            <DeleteSvg size=".8rem" color="#fff" />
                                                        </div>

                                                    </div>
                                                </td>
                                            </React.Fragment>
                                        </tr>
                                    )
                                }

                                else {
                                    return (
                                        <tr style={{ cursor: 'pointer', background: selectedMeetingID === plan_row?.__id__ ? "#e2e2e29e" : undefined }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation()
                                                setIsAddEdit(false);
                                                if (selectedMeetingID == plan_row?.__id__) {
                                                    setSelectedMeetingID(undefined)
                                                    props.getActualByPlanId()


                                                } else {
                                                    setSelectedMeetingID(plan_row.__id__)
                                                    props.getActualByPlanId(plan_row?.__id__)
                                                }
                                            }}>

                                            <React.Fragment>

                                                <td>{plan_row.__id__}</td>
                                                <td>{convert_date_to_dd_mm_yyyy_v1(plan_row.date)}</td>
                                                <td>{plan_row.time}</td>
                                                <td>{plan_row.asm_name}</td>
                                                <td>{plan_row.asm_code}</td>
                                                <td>{plan_row.duration}</td>
                                                <td>{plan_row.customer_code}</td>
                                                <td>{plan_row.customer_name}</td>
                                                <td>{plan_row.location}</td>
                                                <td>{plan_row.name_of_customers_representative}</td>
                                                <td>{plan_row.agenda_points}</td>
                                                <td>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <div className="action_btn"
                                                            title="Edit"
                                                            style={{ backgroundColor: '#2196f3b3' }}
                                                            onClick={() => {
                                                                // setIsAddEdit(true)
                                                                set_planned_form_data(plan_row)
                                                                set_planned_edit_id(plan_row?.__id__)

                                                            }}>
                                                            <EditSvg size=".8rem" color="#fff" />
                                                        </div>
                                                        <div className="action_btn"
                                                            title="Delete"
                                                            style={{ backgroundColor: 'red' }}
                                                            onClick={() => {
                                                                delete_metting(plan_row)
                                                            }}>
                                                            <DeleteSvg size=".8rem" color="#fff" />
                                                        </div>

                                                    </div>
                                                </td>
                                            </React.Fragment>

                                        </tr>
                                    )
                                }

                            })}
                        </tbody>
                    </table>
                </PlannedMettingWrapper>

                <ActualMeeting>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h3>Actual Visit</h3>
                        {selectedMeetingID && !isAddEdit && (
                            // <div style={{ width: "60%" }}>
                            //     <div className='add_meeting' onClick={() => {
                            //         setIsAddEdit(true)
                            //     }}>
                            //         <AddSvg size=".9rem" color="#fff" />&nbsp; &nbsp; &nbsp; Add  New Meeting
                            //     </div>
                            // </div>
                            <div className='add_meeting' onClick={() => {
                                setIsAddEdit(true)
                            }}>
                                <AddSvg size=".9rem" color="#fff" />&nbsp; &nbsp; Add Remark
                            </div>
                        )}
                    </div>

                    {!selectedMeetingID && (
                        <div className='choose_meeting'>
                            Please Choose a Meeting...
                        </div>
                    )}


                    {isAddEdit && (
                        <React.Fragment>
                            <div className='add_meeting_form'>

                                <div title="Back" className='back_btn' onClick={() => {
                                    setIsAddEdit(undefined)
                                    setActualFormData(undefined)
                                }}>
                                    <span className='back_icon'>
                                        <CheveronDown size=".9rem" />
                                    </span>
                                    Back
                                </div>

                                <div>

                                    <div className='__row'>
                                        <div style={{ width: "49%" }}>
                                            <TextBoxV1
                                                label="Date"
                                                type="date"
                                                value={actualFormData?.date ? convertDateInToHtmlDate(actualFormData?.date, undefined, true) : '-'}
                                                onChange={(e) => {
                                                    actualOnChange("date", e.target.value)
                                                }}
                                            />
                                        </div>
                                        <div style={{ width: "49%" }}>
                                            <TextBoxV1
                                                label="Time"
                                                value={actualFormData?.time ? actualFormData?.time : '-'}
                                                type="time"
                                                onChange={(e) => {
                                                    actualOnChange("time", e.target.value)
                                                }}
                                            />
                                        </div>

                                    </div>


                                    <div className='__row'>
                                        <div style={{ width: "49%" }}>
                                            <TextBoxV1
                                                label="Duration"
                                                value={actualFormData?.duration}
                                                onChange={(e) => {
                                                    actualOnChange("duration", e.target.value)
                                                }}
                                            />
                                        </div>
                                        <div style={{ width: "49%" }}>
                                            <TextBoxV1
                                                label="TimeLine To Close"
                                                type="date"
                                                value={actualFormData?.time_line_to_close ? convertDateInToHtmlDate(actualFormData?.time_line_to_close, "dd", true) : '-'}
                                                onChange={(e) => {
                                                    actualOnChange("time_line_to_close", e.target.value)
                                                }}
                                            />
                                        </div>

                                    </div>

                                    {/* =============  */}

                                    <div className='__row'>
                                        <div style={{ width: "100%" }}>
                                            <TextBoxV1
                                                label="Discussion Points"
                                                value={actualFormData?.discussion_points}
                                                onChange={(e) => {
                                                    actualOnChange("discussion_points", e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className='__row'>
                                        <div style={{ width: "100%" }}>
                                            <TextBoxV1
                                                label="Responsibility"
                                                value={actualFormData?.responsibility}
                                                onChange={(e) => {
                                                    actualOnChange("responsibility", e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className='__row'>
                                        <div style={{ width: "100%" }}>
                                            <TextBoxV1
                                                label="Action Points"
                                                value={actualFormData?.action_point_to_close_if_any_open}
                                                onChange={(e) => {
                                                    actualOnChange("action_point_to_close_if_any_open", e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {/* ============ */}

                                </div>
                            </div>

                            <div style={{ marginTop: 'auto', width: '100%', display: 'flex', justifyContent: 'flex-end', padding: '10px', boxSizing: 'border-box' }}>
                                <Button onClick={() => {
                                    localSaveActualPlan()
                                }}>
                                    SAVE
                                </Button>
                            </div>
                        </React.Fragment>
                    )}


                    {!isAddEdit && actualMeetings?.length > 0 && selectedMeetingID && (
                        <table>
                            <thead>
                                <tr>
                                    {/* <td>Id</td> */}
                                    <td>Date</td>
                                    <td>Time</td>
                                    <td>Duration</td>
                                    <td>Discussion Points</td>
                                    <td>Responsibility</td>
                                    <td>Action Point To Close</td>
                                    <td>TimeLine To Close</td>
                                    <td style={{ width: '70px' }}>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {actualMeetings?.length > 0 && actualMeetings.map((plan_row) => {
                                    return (
                                        <tr>
                                            {/* <td>{plan_row.__id__}</td> */}
                                            <td>{convert_date_to_dd_mm_yyyy_v1(plan_row.date)}</td>
                                            <td>{plan_row.time}</td>
                                            <td>{plan_row.duration}</td>
                                            <td>{plan_row.discussion_points}</td>
                                            <td>{plan_row.responsibility}</td>
                                            <td>{plan_row.action_point_to_close_if_any_open}</td>
                                            <td>{convert_date_to_dd_mm_yyyy_v1(plan_row.time_line_to_close)}</td>

                                            <td>

                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <div className="action_btn"
                                                        title="Edit"
                                                        style={{ backgroundColor: '#2196f3b3' }}
                                                        onClick={() => {
                                                            setActualFormData(plan_row);
                                                            setIsAddEdit(true)
                                                        }}>
                                                        <EditSvg size=".8rem" color="#fff" />
                                                    </div>
                                                    <div className="action_btn"
                                                        title="Delete"
                                                        style={{ backgroundColor: 'red' }}
                                                        onClick={() => {
                                                            setActualFormData(plan_row);
                                                            setIsAddEdit(true)
                                                        }}>
                                                        <DeleteSvg size=".8rem" color="#fff" />
                                                    </div>

                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    )}



                </ActualMeeting>
            </Wrapper >
        </PageInnerWrapper >
    )
}



const mapStateToProps = (state) => ({
    plannedMeetings: state.actualPlan.plannedMeetings,
    actualMeetings: state?.actualPlan?.actualMeetings
})

export default withRouter(connect(mapStateToProps, { savePlannedMeeting, getActualByPlanId, saveActualPlan, getAllPlannedMettings })(Index));