import styled from "styled-components";

export const MainWrapper = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
  }
  .login_main {
    width: 100%;
    height: 100vh;
    background: #fff;
    display: flex;
  }
  .newfangled_bg_icon{
    width: 33%;
    position: absolute;
    left: 21%;
    top: -7%;
    svg{
      width: 25rem;
      height: 25rem;
    }
    }
  .login_wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .login_container {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: auto;
    display: flex;
    background: linear-gradient(#f4f4fe 50%, #6c2fd4 50%);
  }
  .newfangled_logos{
    text-align: center;
  }
  .btn {
    display: inline-block;
    padding: 7px 20px;
    font-size: 16px;
    letter-spacing: 1px;
    text-decoration: none;
    border-radius: 5px;
    color: #ffffff;
    outline: none;
    border: 1px solid #ffffff;
    transition: 0.3s;
    -webkit-transition: 0.3s;
  }
  .btn:hover {
    color: #4caf50;
    background: #ffffff;
  }
  .col_right {
    width: 35%;
    position:relative;
    display: flex;
    background: #fff;
    margin: auto;
    border-radius: 20px;
    box-shadow: inset 0px 144px 87px 0px #eeedfd, 
    rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, 
    rgba(50,50,93,0.25) 0px 13px 27px -5px,rgba(0,0,0,0.3) 0px 9px 16px -4px;

  }
  .newfangled_logos{
    h1{
    font-size: 1.4rem;
    margin: 0px;
    font-weight: bold;
    color: #2d347a;
    padding-bottom: 0.4rem;
    padding-top: 0.6rem;
    }
  }
  
  
  .newfangled_logos h6 {
    font-size: 1rem;
    font-weight: 400;
    padding-bottom: 1rem;
  }
  .login_logo {
    width: 100%;
  }

  .login_form p:last-child {
    margin: 0;
  }

  .login_form {
    position: relative;
    width: 100%;
    padding: 35px;
    margin: auto;
    border-radius: 20px;
      p{
        a{
          color: #132778;
          font-size: 20px;
          text-decoration: none;
          font-weight: bold;
          float: left;
        }
      }
        label{
          display: block;
          width: 100%;
          margin-bottom: 5px;
          letter-spacing: 0.5px;
          font-size: 0.8rem;
          font-weight: bold;
        }
        span {
            color: #ff574e;
            padding-left: 2px;
           }
           input {
    display: block;
    height: 2.8rem;
    font-size: 1rem;
    letter-spacing: 1px;
    outline: none;
    border: 1px solid transparent;
    box-shadow: rgba(50,50,93,0.25) 0px 2px 9px 0px,rgba(0,0,0,0.3) 0px 1px 3px -1px;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    border-radius: 6px;
    padding: 0px 10px;
    width: 85%;
    padding-right: 3.4rem;
    margin-bottom: 9px;
    background: #fff;
    &:focus{
      border: 1px solid #409dff;
    }
   
  }
  input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #fff inset;
}


  .btn {
    color: #ffffff;
    background: #2d347a;
    border: 1px solid #2d347a;
    outline: none;
    cursor: pointer;
    width: 100%;
    margin: 20px 0px;
    font-weight: bold;
    padding: 0;
    height: 52px;
  }
  form {
    width: 100%;
    margin: auto;
  }
  .btn:hover {
    color: #003f5c;
    background: #ffffff;
  }
  }
  .login_form p:last-child a:last-child {
    float: right;
  }
  .login_form p:last-child label {
    width: 60%;
    float: left;
  }
 
   
  .checkbox_main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
    border-bottom: 1px solid #cccccc78;
  }
    .Forget_Password{
  width:25%
    }
  .Forget_Password span a{
    text-decoration: none;
    line-height: 54px;
    color: #2d347a;
    font-size:14px;
  }
  .checkbox_main span {
    width: 100%;
      a{
      float: left;
      font-size: 12px;
      text-decoration: none;
      line-height: 47px;
      color: #222;
      font-weight: bold;
        input{
          width: 14px;
          margin-right: 4px;
          box-shadow: none;
        }
      }
  }
  .users {
    position: absolute;
    top: 19px;
    right: 11px;
    color: #ccc;
    padding: 10.5px;
    font-size: 1rem;
    border-radius: 0px 9px 9px 0px;
    :hover{
    color: #707fff;
  }
  }
  
  .users_cur{
    cursor: pointer;
  }
  .Remember_Me_main{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media screen and (min-width: 720px) and (max-width: 1100px) {
    .users {
      top: 17px;
      right: 4px;
    }
    .checkbox_main span a {
      line-height: 41px;
    }
   
  }
  
  @media screen and (min-width: 1280px) and (max-width: 1360px) {
    .users {
      top: 17px;
      right: 14px;
    }
    .checkbox_main span a {
      line-height: 41px;
    }
  } 
  
  @media screen and (min-width: 1399px) and (max-width: 1479px) {
    .users {
      top: 22px;
      right: 21px;

    }
    .checkbox_main span a {
      line-height: 47px;
    }
  }
  @media screen and (min-width: 1480px) and (max-width: 1515px) {
    .users {
      top: 22px;
      right: 21px;

    }
    .checkbox_main span a {
      line-height: 41px;
    }
  } 

  @media screen and (min-width: 1516px) and (max-width: 1699px) {
    .users {
      top: 25px;
      right: 24px;


    }
    .checkbox_main span a {
      line-height: 55px;
    }
  }
  @media screen and (min-width: 1700px) and (max-width: 1869px) {
    .users {
      top: 27px;
      right: 32px;


    }
    .checkbox_main span a {
      line-height: 57px;
    }
  }

  @media screen and (min-width: 1870px) and (max-width: 2099px) {
    .users {
      top: 27px;
      right: 15px;
    }
    .login_form{
      input{
        width: 87%;
      }
    }
    .col_right {
      width: 33%;
    }
    .checkbox_main span a {
      line-height: 57px;
    }
    .newfangled_bg_icon{
    width: 32%;
    position: absolute;
    left: 17%;
    top: -5%;
    svg{
      width: 38rem;
      height: 38rem;
    }
    }
  }
  @media screen and (min-width: 2100px) and (max-width: 2299px) {
    .users {
      top: 26px;
      right: 62px;
    }

    .checkbox_main span a {
      line-height: 60px;
    }
  }

  @media screen and (min-width: 2300px) and (max-width: 2600px) {
    .users {
      top: 26px;
      right: 73px;


    }
    .checkbox_main span a {
      line-height: 60px;
    }
  }
  

`;

export const ButtonWrapper = styled.div`
  border-radius: 5px;
  border: 1px solid #c1c1c2;
  box-sizing: border-box;
  text-align: center;
  padding: 10px 0;
  font-size: 18px;
  letter-spacing: 1px;
  transition: all 0.3s ease-out;
  &:hover {
    box-shadow: inset 23em 0 #707fff;
    cursor: pointer;
    color: #fff;
  }
  color: #ffffff;
  background-image: linear-gradient(to top, #707ffd, #409cff);
  outline: none;
  cursor: pointer;
  width: 100%;
  font-weight: bold;
  height: 48px;
`;

export const FieldContent = styled.div`
  display: block;
  height: 3.6rem;
  font-size: 26px;
  letter-spacing: 1px;
  outline: none;
  border-radius: 7px;
  width: 100%;
  margin-top: 25px;
  position: relative;
`;
