import React, { useEffect, useState } from 'react'
import { get_question_info_for_dashboard_v2, saveInsight } from '../../actions/insights';
import { connect } from 'react-redux';
import { getFromSession } from '../../utils/session.helper';
import { constants } from '../../utils/constants';
import { generate_unique_key, get_chart_type_based_on_column_response, getDbInfoId, getMandatoryRequestHeaders, getParamByName, getParameterByName, showPopup } from '../../utils';
import { get_layout_json_data_v1 } from '../layout-editor/v1.2.1.1';
import enums from '../../utils/enums';
import axios from 'axios';
import { getDefaultInsightForDashboard } from '../../actions/report.helper';
// import { dispatchAutoLayoutData, setActiveEditLayout } from '../../actions/layout';

/**
 * 
 * @param {*} props 
 * @returns 
 */
const DashboardAutoSave = (props) => {


    // get_question_info_for_dashboard_v2()

    const [widgetsData, setWidgetsData] = useState(undefined);

    const [Loading, setLoading] = useState(true);

    const [editingReportId, setEditingReportId] = useState('');
    const [editingDeshboard, setEditingDeshboard] = useState()



    const db_info_id = getDbInfoId();


    // useEffect(() => {

    //     if (Loading) {
    //         showPopup("Awesome !", "Your Dashboard layout is ready.", enums.popupType.sweet_alert)
    //     } else {

    //     }

    // }, [Loading])


    const db_info_id_to_use = (db_info_id + "__nf__db__cached__")


    const { wrapperWidth, wrapperHeight, reporting_db_info_id, layoutId, user, showLoader, onCompleted, kpiToUpdate, all_reports, getDefaultInsightForDashboard, defaultInsight, dashboardQuestions, dashboard_name } = props;
    
    // defaultInsight
    useEffect(() => {

        if (kpiToUpdate) {
            
            const dashboardData = all_reports?.find(el => el?.title?.toLowerCase() === kpiToUpdate?.toLowerCase())
            const client_id = dashboardData?.client_id;
            const insight_id = dashboardData?.id;
            setEditingReportId(insight_id)
            getDefaultInsightForDashboard(insight_id, client_id)
        }
    }, [kpiToUpdate]);


    // this to start again for save dashboard
    const defaultreportItemToAdd = {
        // title: 'dummy title',
        // question: 'total revenue for each service for each year 2021',
        chart_type: 'bar_chart',
        is_created: true,
        is_active: 1,
        is_group: false,
        render_mode: 'chart',
        report_item_type: 'dashboard',
        xac: [],
        yac: [],
        comparisons: [],
        isQuestionModified: true,
        // call_java_forcefully: true
    }



    useEffect(() => {

        if (defaultInsight && kpiToUpdate && dashboardQuestions) {

            const report_items = defaultInsight?.report_items?.map(el => ({ question: el?.question, title: el?.title, chart_type: el?.chart_type, modified: true, report_id: el.report_id, id: el.id }));
            const id = defaultInsight?.id;
            const title = defaultInsight?.title;

            let reportItemToAdd = [];
            if (dashboardQuestions?.length) {
                reportItemToAdd = dashboardQuestions?.map(question => ({ ...defaultreportItemToAdd, question: question }));
            }

            setEditingDeshboard({
                ...defaultInsight,
                reporting_db_info_id: defaultInsight?.reporting_db_info_id,
                db_info_id: defaultInsight?.db_info_id,
                widgets: [...report_items, ...reportItemToAdd],
                // report_items:  [...report_items, reportItemToAdd],
                id,
                title,
                modified: true
            })
        }
    }, [defaultInsight, kpiToUpdate, dashboardQuestions])


    // Helper function to get default filter values
    const getDefaultFilterValues = () => {
        const own_env = ['demo_med', 'sbi', 'hsl', 'tata_play', 'hrpl_new_olap'];
        const year_by_c_id = {
            'demo_med': '2021',
            'hsl': '2022',
            'hrpl_new_olap': '2024',
            'sbi': '2021',
            'tata_play': '2023'
        };
        const clientIdFromSession = getFromSession(constants.SESSION_KEYS.CLIENT_ID);
        const year__ = year_by_c_id[clientIdFromSession] || '2021';
        return (own_env.includes(clientIdFromSession) ? { "year": year__ } : {});
    };


    const saveDashboardV2 = async (localReport, isEditing) => {

        // Clone localReport and initialize variables
        const tempReport = { ...localReport };
        const results = [];

        // Process report items
        const tempReportItems = tempReport.report_items || [];
        tempReportItems.forEach(reportItem => {

            reportItem.reporting_db_info_id = reporting_db_info_id || db_info_id_to_use;

            if (reportItem.deleted && reportItem.is_created) return;

            if (reportItem.modified) {
                // Combine frequency value and type
                // if (reportItem?.frequency_value && reportItem?.frequency_type) {
                //     reportItem.frequency = `${reportItem.frequency_value}____nfx___${reportItem.frequency_type}`;
                // }
                // Handle report item based on type
                if (reportItem.report_item_type === 'dashboard') {
                    reportItem.criteria = '';
                }

                // Handle chart-specific processing
                if (reportItem.render_mode !== 'chart') {
                    reportItem.report_item_additional_info = [];
                    reportItem.id = reportItem.id?.includes('nf_sr') ? undefined : reportItem.id;
                    reportItem.xac = [];
                    reportItem.yac = [];
                    reportItem.comparisons = [];
                }
                results.push(reportItem);
            }
        });

        // Map access group and users
        // const { unmapped_users, access_group_report_mapping_data } = get_map_and_unmapped_user_or_role(access_group_report_mapping, reportAccessGroupData);
        // Prepare data to save

        const dataToSave = isEditing
            ? [{ ...tempReport, report_items: results }]
            : [{
                ...tempReport,
                report_items: results,
                access_group_report_mapping: ["any"],
                access_report_exclude_user_mapping: undefined,
                menu_group_id: undefined,
                is_menu_group_new: undefined,
                modified: true,
                reporting_db_info_id: reporting_db_info_id || db_info_id_to_use,
                is_hierarchy_filter: undefined,
                filter_default_value_json: JSON.stringify(getDefaultFilterValues()),
                report_filter: undefined
            }];



        const url = constants.END_POINTS.API +
            constants.END_POINTS.REPORT.POINT +
            constants.END_POINTS.REPORT.SAVE_REPORT;

        const options = {
            headers: getMandatoryRequestHeaders(undefined),
        };
        try {

            const response = await axios.put(url, dataToSave, options);
            const responseData = response.data;
            const dashboardId = responseData?.data?.mainReportId;

            if (onCompleted) {
                onCompleted(dashboardId, tempReport.name)
            }

            console.log("responseData", responseData)
        } catch (error) {

            console.error("An error occurred while saving the report:", error);

            if (onCompleted) {

                onCompleted(undefined)
            }
        }
    };




    useEffect(() => {

        const autoLayoutData = props?.autoLayoutData?.[layoutId] || editingDeshboard;
        // const autoLayoutData = props?.autoLayoutData?.[layoutId] || props?.autoLayoutData?.[editingReportId];

        // console.log("INNNNER", autoLayoutData, layoutId);

        if (autoLayoutData && autoLayoutData["widgets"]?.length > 0) {


            const widgets = autoLayoutData["widgets"]?.map((a) => {
                return {
                    ...a,
                    id: a?.id || generate_unique_key("auto_design")
                }
            });

            // console.log("DATA TTWAAMFNASDKF", widgets)
            props.get_question_info_for_dashboard_v2(widgets, (reporting_db_info_id || db_info_id_to_use));
            setWidgetsData(widgets)

        }
    }, [props.autoLayoutData, layoutId, editingDeshboard, editingReportId])



    useEffect(() => {

        if (props.questionInfoDataV2 && widgetsData && !editingDeshboard) {
            const autoLayoutData = props?.autoLayoutData?.[layoutId];

            const report_template = {
                name: 'Quick Insight',
                type: 'insights',
                user_id: user ? user.id : '',
                report_items: []
            }

            const title = autoLayoutData["title"];
            // showPopup("Awesome !", "Your Dashboard layout is ready.", enums.default.popupType.sweet_alert)

            const clone_widgetsData = widgetsData ? [...widgetsData] : {};

            const question_based_meta = get_chart_type_based_on_column_response(props?.questionInfoDataV2)


            clone_widgetsData.map((w) => {
                if (w.question) {
                    const info = question_based_meta[w?.id];
                    w.chart_type = info?.chartType;
                    w.render_mode = 'chart'
                    w.title = info?.title || ''
                }
            })

            // let's create it automaticly
            // let's map the question and chart type with proper id


            const report_items = get_layout_json_data_v1(clone_widgetsData, wrapperWidth, wrapperHeight)
            report_template["report_items"] = report_items;
            report_template["title"] = title || "AUTO Gen DASHBOARD";
            report_template["name"] = title || "AUTO Gen DASHBOARD";
            // setReport(report_template);
            // everryting is done let's save it and

            saveDashboardV2(report_template)

        }
        else if (props.questionInfoDataV2 && editingDeshboard) {
            const autoLayoutData = props?.autoLayoutData?.[layoutId] || editingDeshboard;
            const title = autoLayoutData["title"];
            // showPopup("Awesome !", "Your Dashboard layout is ready.", enums.default.popupType.sweet_alert)

            const clone_widgetsData = widgetsData ? [...widgetsData] : {};

            const question_based_meta = get_chart_type_based_on_column_response(props?.questionInfoDataV2)
            const report_items = get_layout_json_data_v1(clone_widgetsData, wrapperWidth, wrapperHeight, undefined, undefined, true)
            clone_widgetsData.map((w) => {
                if (w.question) {
                    const info = question_based_meta[w?.id];
                    const layoutData = report_items?.find(el => el.id === w.id);
                    // w = {layoutData},
                    // w.chart_type = info?.chartType;
                    // w.render_mode = 'chart';
                    // w.title = info?.title || '';
                    return {
                        ...layoutData,
                        chart_type: info?.chartType,
                        render_mode: 'chart',
                        // title: layoutData?.title || info?.title,
                        title: info?.title,
                    }
                }
            })

            saveDashboardV2({ ...editingDeshboard, report_items: clone_widgetsData }, true);

        }

    }, [props.questionInfoDataV2])

    console.log("now i am working -))", props.questionInfoDataV2, layoutId, props.autoLayoutData)
    return (
        <span></span>
    )
}



const mapStateToProps = state => {

    const questionInfoDataV2 = state?.insightReducer?.questionInfoDataV2
    const { autoLayoutData } = state.layoutReducer;
    const defaultInsight = state.reportHelperReducer.defaultInsight

    return { questionInfoDataV2, autoLayoutData, defaultInsight }
};


export default connect(mapStateToProps, { get_question_info_for_dashboard_v2, getDefaultInsightForDashboard })(DashboardAutoSave);