import React from 'react';
import styled from 'styled-components';


const OtherSettingWrapper = styled.div`

    display: flex;
    border-right: 0px;
    margin: 5px;
    background-color: #fff;

    div{
        border: 0px;
        border: 0px;
        padding: 3px 5px;
        font-size: .7rem;
        font-weight: 600;
        cursor: pointer;
        background-color: #EFEFEF;
        border-radius: 5px;
        margin: 0px 2px;
        //  border-right: 1px solid #005D86;
        display: inline-block;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
    }
    input[type="checkbox"]{
            cursor: pointer;
        }
        .active{
            background-color:#0075ff;
            color:#fff;
        }
        .accented {
            accent-color: #fff;
        }
        [color-scheme="dark"] .accented {
            accent-color:#fff;
        }
`;



const ChartOtherSetting = ({

    show_data_label,
    set_show_data_label,
    open_auto_description

}) => {

    return (
        <OtherSettingWrapper>
            <div className={show_data_label ? `active` : undefined} onClick={() => set_show_data_label(!show_data_label)}>
                <input className="accented" id="accented-dark" type="checkbox" checked={show_data_label} />&nbsp; Data Label
            </div>

            <div onClick={() => open_auto_description()}>
                {/* <input className="accented" id="accented-dark" type="checkbox" checked={show_data_label} />&nbsp;  */}
                Data Story
            </div>
        </OtherSettingWrapper>
    );
}

export default ChartOtherSetting;
