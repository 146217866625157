import React, { useState, useEffect, useRef } from 'react';
import * as _ from 'lodash';
import { SelectBox, DropDownMenu, DefaultLabel, OverlayMain } from './auto.search';
import OverLay from '../helper/overlay';
import DropDownSvg from '../svg/dropDown';
import AddSvg from '../svg/add';
import RightArrowSvg from '../svg/right.arrow'
import Chevron from '../svg/chevron.down';



const AutoSearch = props => {

    const {
        onItemClick,
        fullBorder,
        bg_color,
        label,
        selections,
        displayKey,
        valueKey,
        activeValue,
        height,
        width,
        dropDownItemAlign,
        disabled,
        labelBg,
        setActiveValue,
        borderColor,
        onBlur,
        autoOpenListHide,
        dropdownIconHide,
        inputBorder,
        borderNone,
        dropdownShadow,
        wrapperRef

    } = props;


    const [showMenu, setShowMenu] = useState(undefined);
    const [HintArray, setHintArray] = useState(undefined);
    const [searchValue, setSearchValue] = useState(undefined);
    const [legendPosition, setLegendPosition] = useState('down');
    const [extraHeight, setExtraHeight] = useState(0);
    const [dropdown_position, set_dropdown_position] = useState(undefined);



    let nameValue = undefined;

    const text_box_ref = useRef(null);
    const dropdwon_ref = useRef(null);

    useEffect(() => {
        /* 
            * Here we are deciding whether the colorBox should open on bottom or top 
        */


        if (dropdwon_ref && showMenu && wrapperRef) {
            const table_height = wrapperRef && wrapperRef.current && wrapperRef.current.scrollHeight;
            const windowHeight = table_height ? table_height : window.innerHeight;
            const elementData = dropdwon_ref.current.getBoundingClientRect();
            const { height, y } = elementData;
            // if (windowHeight < (y - height)) {
            //     setLegendPosition('up');
            // }
        }

        // eslint-disable-next-line
    }, [showMenu]);

    useEffect(() => {
        if (activeValue) {
            setSearchValue(activeValue)
        }
        if (valueKey && displayKey) {
            const activeValueObject = selections && selections.length > 0 && selections.filter(data => data[valueKey] === activeValue);
            nameValue = activeValueObject && activeValueObject.length > 0 ? activeValueObject[0][displayKey] : undefined;
        }
    }, [activeValue])



    useEffect(() => {
        if (selections) {
            setHintArray(selections)
        }
    }, [selections]);



    const setFocusedOnTextBox = (opposite) => {
        let _current = text_box_ref.current;
        if (opposite) {
            _current.blur();
        } else _current.focus();

    }

    const el_id = "input__id__uniqqq_auto_gen" + Math.random(100);

    const open_dropdown_menu = (e) => {
        setShowMenu(true);
        const dropdown_height = 180;
        const element = document.getElementById(el_id);
        const el_bounding = element.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const element_height = el_bounding?.height;
        const element_left = el_bounding?.left;
        const element_top = el_bounding?.top;
        let drop_down_top_to_use = (element_top + element_height);
        if ((windowHeight - drop_down_top_to_use) <= dropdown_height) {
            drop_down_top_to_use = ((element_top + element_height) - dropdown_height) - 10
        }
        set_dropdown_position({
            top: drop_down_top_to_use,
            left: element_left
        })
        // close_dropdown(!open_menu)
        // updated_suggestion_options()
    }
    const suggestion_list_width = text_box_ref?.current?.clientWidth;
    const OnSearchTextChange = (e) => {

        let value = e.target.value;
        setSearchValue(value)

        if (setActiveValue) {
            setActiveValue(value)
        }

        let search_value = value && value.length > 0 && value.trim().toLowerCase();
        let cloneMasterData = (selections && selections.length > 0) ? JSON.parse(JSON.stringify(selections)) : [];
        let filterd_data = cloneMasterData && cloneMasterData?.length > 0 && cloneMasterData.filter((item) => {
            if (value == '') {
                return true
            }
            if (typeof item === 'object' && typeof item?.[displayKey] !== 'undefined' && item?.[displayKey] !== null) {
                if (item[displayKey].toLowerCase().indexOf(search_value) > -1) {
                    return true
                }
            }
            if (typeof item == 'string') {
                if (item.toLowerCase().indexOf(search_value) > -1) {
                    return true
                }
            }
            else return false
        })
        if (filterd_data && filterd_data.length > 0 && autoOpenListHide && value && value.length > 0) {
            setShowMenu(true);
        }
        setHintArray(filterd_data)
    }


    return (
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', }}>
          

            <SelectBox
                borderNone={borderNone}
                fullBorder={fullBorder}
                focused={showMenu}
                isDropdown={(showMenu && HintArray && HintArray.length > 0)}
                style={{
                    zIndex: showMenu ? 2 : 0,
                    cursor: disabled ? "not-allowed" : undefined,
                    height: '2.4rem'
                }}

                height={height}
                width={width}
            >

                <input
                    id={el_id}
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    ref={text_box_ref}
                    // autoFocus={showMenu ? true : undefined}
                    type="text"
                    style={{
                        width: '100%',
                        height: '100%',
                        margin: '0px',
                        // border: '0px',
                        borderBottom: `1px solid #eaeaea`,
                        // border: inputBorder ? inputBorder : '0px',
                        borderBottomColor: borderNone ? 'transparent' : borderColor ? borderColor : "#ccc",                        
                        border: borderNone ? '0px' : fullBorder ? '1px solid #0000003b' : undefined,
                        // border: borderNone ? `1px solid ${bg_color || 'transparent'}` : fullBorder ? '1px solid #0000003b' : undefined,
                        background: bg_color || 'transparent',
                        boxSizing: 'border-box',
                        borderRadius: '6px',
                        cursor: disabled ? 'not-allowed' : undefined,
                    }}
                    label={label}
                    onChange={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (!disabled) {
                            OnSearchTextChange(e)
                        }
                    }}
                    onFocus={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (!disabled && !autoOpenListHide) {
                            setShowMenu(true)
                            setFocusedOnTextBox()
                            open_dropdown_menu(e)
                        }
                    }}
                    onBlur={(e) => {
                        if (!disabled && showMenu) {
                            if (onBlur) {
                                onBlur(e.target.value)
                            }
                            e.preventDefault()
                            e.stopPropagation()
                            setTimeout((e) => {
                                setShowMenu(false)
                            }, 500)
                        }
                    }}
                    title={searchValue ? displayKey ? searchValue[displayKey] : searchValue : ''}
                    value={searchValue ? displayKey ? searchValue[displayKey] : searchValue : ''}
                />

                {!dropdownIconHide && (
                    <span
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (!disabled && !showMenu) {
                                setShowMenu(true)
                                setFocusedOnTextBox()
                                setHintArray(selections)
                            }

                        }}
                        className="select_arrow"
                    > <Chevron size={'.8rem'} height={".8rem"} color="rgb(175, 196, 227)" />

                    </span>

                )}
  {
                (showMenu && HintArray && HintArray.length > 0) &&
                <>
                <OverlayMain
                    onClick={(e) => {
                        e?.preventDefault();
                        e?.stopPropagation()
                        if (!disabled) {
                            setFocusedOnTextBox(showMenu)
                            setShowMenu(!showMenu)
                        }
                    }} >
   {/* added top and left to visiblein a component with scroll */}
                    <DropDownMenu
                        ref={dropdwon_ref}
                        dropdownShadow={dropdownShadow}
                        isDropdown={showMenu}
                        dropDownItemAlign={dropDownItemAlign}
                        postionType={legendPosition}
                        top={dropdown_position?.top} left={dropdown_position?.left}
                        width={suggestion_list_width}
                        // style={{ bottom: legendPosition && legendPosition === 'up' ? '101%' : undefined, top: legendPosition && legendPosition === 'down' ? '100%' : undefined }}
                    >
                        {
                            HintArray && HintArray.length > 0 && HintArray.map(data => {
                                let _title = typeof data === 'string' ? data : (displayKey && typeof data === 'object') ? data[displayKey] : undefined;

                                return (
                                    <div title={_title} className='select_result_sub'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            onItemClick(data);
                                            setSearchValue(data)
                                            setShowMenu(false)
                                            setFocusedOnTextBox('hide')
                                        }}

                                    >
                                        {
                                            typeof data === 'string' &&
                                            data
                                            // _.startCase(_.toLower(data))
                                        }
                                        {
                                            displayKey && typeof data === 'object' &&
                                            data[displayKey]
                                            // _.startCase(_.toLower(data))
                                        }

                                    </div>
                                )
                            })
                        }
                    </DropDownMenu>
                    </OverlayMain>
                    </>
                }
            </SelectBox>


            <DefaultLabel
                onClick={() => {
                    if (!disabled) {
                        setFocusedOnTextBox(false)
                        setShowMenu(true)
                    }
                }}
                labelBg={labelBg}
                isOpen={showMenu}
                LabelTop={showMenu || (searchValue && searchValue.length > 0)} >
                {label ? label : ""}
            </DefaultLabel>

        </div>
    );
};

export default AutoSearch;