import * as actions from '../action-types/predictive_analytics';

const initialState = {
    type: actions.PREDICTIVE_INIT,
    predictiveData: undefined,
    predictiveDataByMonth : undefined,
    predictiveDataByYear : undefined,
    predictivePatientsYearly: undefined,
    predictivePatientsMonthly: undefined,
    predictivePatientsQuaterly: undefined,

};

const predictiveAnalyticsReducer = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        case actions.GET_PREDICTIVE_RESULT:
            return {
                type: action.type,
                predictiveData: action.data
            };

        case actions.GET_PREDICTIVE_SPECALITY_BY_MONTH:
            return {
                type: action.type,
                predictiveDataByMonth: action.data
            };
        case actions.GET_PREDICTIVE_SPECALITY_BY_YEAR:
            return {
                type: action.type,
                predictiveDataByYear: action.data
            };
        case actions.GET_PREDICTIVE_PATIENT_BY_YEARLY_SUCCESS:
            return {
                type: action.type,
                predictivePatientsYearly: action.data
            };
        case actions.GET_PREDICTIVE_PATIENT_BY_MONTHLY_SUCCESS:
            return {
                type: action.type,
                predictivePatientsMonthly: action.data
            };
        case actions.GET_PREDICTIVE_PATIENT_BY_QUATERLY_SUCCESS:
            return {
                type: action.type,
                predictivePatientsQuaterly: action.data
            };
        default:
            return { ...state };
    }
};

export default predictiveAnalyticsReducer;