import styled from 'styled-components';

export const Wrapper = styled.div`
    position: fixed;
    height: 100%;
    /* width: 20vw; */
    top: 0;
    right: ${props => props.showFull ? '0' : '-20vw'};
    background: white;
    z-index: 30;
    overflow: hidden;
    transition: all 0.2s linear;
    overflow-y: auto;
    box-shadow:-1px 0px 5px 0px rgba(0, 0, 0, 0.18);



 

.right_menu_ul{
    list-style: none;
    padding: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 20px;
    border-left: 1px solid #ccc;

  li{
    position: relative;
    &::before{
      width: 10px;
      content: "";
      background: #ccc;
      height: 1px;
      z-index: 111;
      position: absolute;
      z-index: 1;
      top: 50%;
    }
  }
}
`;

export const IconWrapper = styled.div`
    box-sizing: border-box;
    padding: 5px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: fit-content;
    width: 30px;
`;


export const SettingsWrapper = styled.div`
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    box-sizing: border-box;

    div {
        width: 100%;
        /* border-bottom: 1px solid #c1c1c2; */
        padding: 10px;
        font-size: 1rem;
        color: #fff;
        text-align: left;
        margin-bottom: 10px;
        cursor: pointer;
        background: #0285fc;
        font-weight: 600;

        &:hover{
            opacity: 0.8;
        }
    }
`;

export const HamburgerWrapper = styled.div`
    /* position: absolute;
    top: 19px;
    right: 10px;
    z-index: 10; */
    cursor: pointer;
    background: #f1f3f5;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover{
        background: #d8d8d8;
    }

    i{
        font-size: 20px;
    }

`;

export const TabWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    border-bottom: .25px solid #c1c1c2;
    padding: 5px;
    padding-bottom: 0;
    font-size: 1rem;
`;

export const Tab = styled.div`
    font-weight: ${props => props.active ? 'bold' : 'normal'};
    border-bottom: ${props => props.active && '2px solid #1595e7'};
    color: ${props => props.active && "#1595e7"};
    padding: 12px 20px;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
`;

export const DataWrapper = styled.div`
    // padding: 5px;
    // padding-left: 10px;
    overflow-y: ${props => props.scroll ? 'scroll' : ''};
    height:calc(100vh - 170px);
    
    &::-webkit-scrollbar {
        background-color:#fff;
        width:16px
    }
    
    /* background of the scrollbar except button or resizer */
    &::-webkit-scrollbar-track {
        background-color:#fff
    }
    &::-webkit-scrollbar-track:hover {
        background-color:#f4f4f4
    }
    
    /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
        background-color:#babac0;
        border-radius:16px;
        border:5px solid #fff
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color:#a0a0a5;
        border:4px solid #f4f4f4
    }
    
    /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {display:none}
    
`;

export const ProfileWrapper = styled.div`
    padding: 10px 5px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
`;

export const GeneralMenu = styled.div`
    width: 100%;
    text-align: center;
    .user_name {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 7px;
    }

    .user_role {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
    }
`;

export const LogoutButton = styled.div`
    border-radius: 2px;
    background: #256394;
    padding: 5px 8px;
    color: white;
    font-size: .9rem;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 15px;
    box-shadow: 1px 4px 4px #ccc;
    &:hover{
        opacity: 0.8;
    }
`;
