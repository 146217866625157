import * as actions from '../action-types/insight';

const initialState = {
    type: actions.INSIGHTS_INIT,
    recordsCount: 0,
    insightsData: undefined,
    refreshInsightData: undefined,
    insightsList: undefined,
    insightsMenu: undefined,
    activeInsight: undefined,
    answersList: undefined,
    editRecordAnswers: undefined,
    hintsForFilter: undefined,
    questionInfo: undefined,
    questionDetailsCache: undefined,
    questionInfoDataV2: undefined
};


const insightReducer = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }


    // if (action && action.data && typeof action.data === 'object') {
    //     action.data.key = new Date();
    // }

    switch (action.type) {
        case actions.INSIGHTS_DATA_SUCCESS:
            return {
                ...state,
                type: action.type,
                insightsData: action.data
            };

        case actions.CLEAR_COLUMN_OF_QUESTION:

            const clone_questionDetailsCache_1 = state?.questionDetailsCache ? { ...state.questionDetailsCache } : {};
            delete clone_questionDetailsCache_1[action?.data?.id];

            // console.log("clearing", action?.data.id)
            return {
                ...state,
                type: action.type,
                questionInfo: undefined,
                questionDetailsCache: clone_questionDetailsCache_1
            }
            
        case actions.GET_QUESTION_COLUMN_FOR_DASHBOARD_V2:

            return {
                ...state,
                type: action.type,
                questionInfoDataV2: action.data
            };

        case actions.INSIGHTS_LIST_BY_USER_SUCCESS:
            return {
                ...state,
                type: action.type,
                insightsListData: action.data,
                insightsMenu: action.data
            };

        case actions.INSIGHTS_MENU_SUCCESS:
            return {
                ...state,
                type: action.type,
                insightsMenu: action.data
            };

        case actions.SET_CHANGE_INSIGHTS_DATA_SUCCESS:
            return {
                ...state,
                type: action.type,
                insightsMenu: action.data
            };

        case actions.REFRESH_INSIGHTS_DATA_SUCCESS:
            return {
                ...state,
                type: action.type,
                refreshInsightData: action.data
            };

        case actions.SET_ACTIVE_INSIGHTS_SUCCESS:
            return {
                ...state,
                type: action.type,
                activeInsight: action.data
            };

        case actions.SAVE_INSIGHTS_DATA_SUCCESS:
            return {
                ...state,
                type: action.type
            };

        case actions.CLEAR_INSIGHTS_DATA_SUCCESS:
            return {
                ...state,
                type: action.type,
                insightsData: undefined,
                refreshInsightData: undefined,
                insightsListData: undefined
            };

        case actions.GET_ANSWERS_LIST_BY_TABLE_NAME:
            return {
                ...state,
                type: action.type,
                answersList: action.data
            };
        case actions.EDIT_TABLE_RECORD:
            return {
                ...state,
                type: action.type,
                editRecordAnswers: action.data
            };

        case actions.GET_HINTS_BT_CLIENT_ID_SUCCESS:
            return {
                ...state,
                type: action.type,
                hintsForFilter: action.data
            }
        case actions.GET_COLUMN_INFO_BY_QUESTION:

            const clone_questionDetailsCache = state.questionDetailsCache ? { ...state.questionDetailsCache } : {};
            const payload_data = action?.data;

            if (!clone_questionDetailsCache[payload_data?.id]) {
                clone_questionDetailsCache[payload_data?.id] = {};
            }

            clone_questionDetailsCache[payload_data?.id] = payload_data

            return {
                ...state,
                type: action.type,
                questionInfo: action.data,
                questionDetailsCache: clone_questionDetailsCache
            }
        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
            return {
                ...state,
                type: state.type,
            };
    }
};

export default insightReducer;