import { POLUS_INSTRUCTION_MODULE_TYPE } from '../utils/polus.action.constant';
import { save_tbl_format_setting } from './table_format';
import { constants } from '../utils/constants';
import { post } from '../data.service';
import { PIVOT_COLUMN_TYPE } from '../shared-with-fe/constants';
import { dispatch_pivot_data, dispatch_updated_pivot_data, save_columns_info, update_report_column_state } from './report';
import { s_p, step_1_loop_through_rows_and_sort } from '../utils/v1.1';
import { update_sort_order } from './table';
import { saveInnerChat, toggleIsWaiting } from './polus_chat';

const { CONDITIONS } = constants;

/**
 * Converts Java condition syntax to front-end format.
 *
 * @param {string} condition - The Java condition to convert.
 * @returns {string} - The converted condition.
 */


const convert_java_condition_into_fe = (condition) => {
    const conditionMap = {
        "=": CONDITIONS.EQUAL_TO.VALUE_KEY,
        ">": CONDITIONS.GREATER_THAN.VALUE_KEY,
        "<": CONDITIONS.LESS_THEN.VALUE_KEY,
        ">=": CONDITIONS.GREATER_THAN_OR_EQUAL_TO.VALUE_KEY,
        "<=": CONDITIONS.LESS_THEN_OR_EQUAL_TO.VALUE_KEY,
        "!=": CONDITIONS.NOT_EQUAL.VALUE_KEY,
        "start_with": CONDITIONS.START_WITH.VALUE_KEY,
        "end_with": CONDITIONS.END_WITH.VALUE_KEY,
        "does_not_contain": CONDITIONS.DOES_NOT_CONTAIN.VALUE_KEY,
        "contains": CONDITIONS.CONTAINS.VALUE_KEY,
        "between": CONDITIONS.CONTAINS.VALUE_KEY
    };


    return conditionMap[condition] || condition;

};



const valid_java_response = (data = {}) => {

    var error = false;
    var error_msg = ''

    if (!data?.action_type) {
        error = true;
        error_msg = 'condition not found.'
    }
    else {

        switch (data?.action_type) {
            case POLUS_INSTRUCTION_MODULE_TYPE.PIVOT:
                if (data?.condition && data?.display_column) {

                }
                break;

            default:
                break;
        }
    }

    return {

    }

}

/**
 * Parses the response from Java into a front-end format.
 *
 * @param {string} type - The type of action.
 * @param {Object} data - The response data from Java.
 * @returns {Object} - The parsed data.
 */

const parse_java_response = (type, data) => {

    if (type === POLUS_INSTRUCTION_MODULE_TYPE.COLOR_FORMATING_FORMULA) {
        return {
            column: data?.display_column,
            column_name: data?.display_column,
            condition: convert_java_condition_into_fe(data?.condition),
            value: data?.condition_value?.[0],
            cell_color: data?.color,
            apply_to_chart: true,
        };
    }
    else if (type === POLUS_INSTRUCTION_MODULE_TYPE.PIVOT) {
        return {
            column: data?.display_column,
            fe_pivot_type: data?.condition
        };
    }
    else if (type === POLUS_INSTRUCTION_MODULE_TYPE.FORMULA) {
        return {
            "formula_str": data?.condition,
            "name": "",
            "order": 1,
            "nf_formula_conditions": [],
            "alias": data?.display_column,
            "modified": true,
            "data_type": "number",
            "db_data_type": "string",
            "grand_total_value_type": "sum",
            "formula_applicable_to": "pivot_data",
            "formula_type": "report_viewer",
            "is_formula": true,
            "visible_type": 1

        };
    }
    else if (type === POLUS_INSTRUCTION_MODULE_TYPE.DATA_SORTING) {
        return {
            column: data?.display_column,
            fe_pivot_type: data?.pivot_type
        };
    }
    else if (type === POLUS_INSTRUCTION_MODULE_TYPE.FILTER) {
        return {
            column: data?.display_column,
            filter_data: {
                "filter_type": convert_java_condition_into_fe(data?.condition),
                "formula_value": data?.condition_value?.[0],
            }
        };
    }
    return null;
};


/**
 * Handles opening the dashboard action.
 */
const handleOpenDashboard = () => {
    setTimeout(() => {
        window.open('/insightsList?type=insights', '_blank');
    }, 100);
};



/**
 * Handles color formatting formula action.
 *
 * @param {Object} finalData - The parsed data from the response.
 * @param {string} id - The identifier for the data.
 * @param {Function} dispatch - The dispatch function.
 * @param {Object} state - The current state.
 */


const handleColorFormattingFormula = async (finalData, id, dispatch, state) => {
    const reducerData = state.tableFormatReducer?.table_format_setting_cache?.[id] || [];
    const clonedData = [...reducerData];
    const parsedData = parse_java_response(POLUS_INSTRUCTION_MODULE_TYPE.COLOR_FORMATING_FORMULA, finalData);

    if (parsedData) {
        clonedData.push(parsedData);
        await save_tbl_format_setting(clonedData, id, id)(dispatch);
    }
};


const handlePivot = async (finalData, id, dispatch, state) => {

    const parsedData = parse_java_response(POLUS_INSTRUCTION_MODULE_TYPE.PIVOT, finalData);

    const dataFromServer = state.reportReducer.dataFromServer;
    const serverData = dataFromServer[id] && dataFromServer[id];

    const columns_info = serverData?.columns_info || [];
    const be_file_path = serverData?.file_path;

    const cloned_columns_info = columns_info ? JSON.parse(JSON.stringify(columns_info)) : [];

    // Update pivot type for the specified column
    cloned_columns_info.map((c) => {
        if (c.alias === parsedData?.column) {
            c.fe_pivot_type = parsedData?.fe_pivot_type; // Update pivot type
            c.modified = true;
        }
    });


    // Filter and sort pivot columns
    const pivot_x = cloned_columns_info
        .filter((c) => c.fe_pivot_type === PIVOT_COLUMN_TYPE.PIVOT_X)
        .sort((a, b) => a.pivot_field_order - b.pivot_field_order);

    const pivot_y = cloned_columns_info
        .filter((c) => c.fe_pivot_type === PIVOT_COLUMN_TYPE.PIVOT_Y)
        .sort((a, b) => a.pivot_field_order - b.pivot_field_order);

    const pivot_comparision = cloned_columns_info
        .filter((c) => c.fe_pivot_type === PIVOT_COLUMN_TYPE.PIVOT_COMPARISON)
        .sort((a, b) => a.pivot_field_order - b.pivot_field_order);

    dispatch_pivot_data(id, id, pivot_x, pivot_y, pivot_comparision, cloned_columns_info, false, be_file_path)(dispatch);
};


/**
 * Interprets the command text and dispatches the appropriate action.
 *
 * @param {string} text - The command text to interpret.
 * @param {string} id - The identifier for the data.
 */


export const interpretCommandAction = (text = 'PIVOT', id, file_path) => async (dispatch, getState) => {

    try {

        const url = `${constants.END_POINTS.API}${constants.END_POINTS.REPORT.JAVA_STRING_PARSER}`;


        const state = getState();
        const dataFromServer = state.reportReducer.dataFromServer;
        const serverData = dataFromServer[id] && dataFromServer[id];
        const column_meta_data = serverData?.column_meta_data || [];
        const be_file_path = serverData?.file_path;


        const data = await post(url, {
            text,
            file_path: be_file_path,
        });

        if (data && data.data && !data?.data?.error) {


            if (data?.data?.length === 0) {
                throw new Error("Data not Found")
            }

            const finalData = data?.data[0];
            const action = finalData?.action_type;

            const parsed_data = parse_java_response(finalData?.action_type, finalData)

            if (action === POLUS_INSTRUCTION_MODULE_TYPE.OPEN_DASHBOARD) {

                handleOpenDashboard();

            } else if (action === POLUS_INSTRUCTION_MODULE_TYPE.COLOR_FORMATING_FORMULA) {

                await handleColorFormattingFormula(finalData, id, dispatch, state);

                saveInnerChat(id, { text: "Success! Conditional formatting applied." }, true)(dispatch)


            } else if (action === POLUS_INSTRUCTION_MODULE_TYPE.PIVOT) {

                await handlePivot(finalData, id, dispatch, state);
                saveInnerChat(id, { text: "Success! Pivot applied." }, true)(dispatch)


            }
            else if (action === POLUS_INSTRUCTION_MODULE_TYPE.DATA_SORTING) {


                const table_id_to_use = (id + "__nfx__" + "1");
                const sort_order_cache = state.tableReducer.sort_orders;

                const sort_orders = sort_order_cache?.[table_id_to_use] || [];

                // then update it 
                const isExist = sort_orders?.find((a) => a.name === finalData?.display_column);
                if (isExist) {
                    sort_orders.map((k) => {
                        if (k.name === finalData?.display_column) {
                            k.order_by = finalData?.condition
                        }
                    })
                } else {
                    sort_orders.push({ name: finalData?.display_column, order_by: finalData?.condition })

                }


                const pivot_data = serverData?.pivot_data?.data ? JSON.parse(JSON.stringify(serverData?.pivot_data?.data)) : {};
                const sorted_data = step_1_loop_through_rows_and_sort(pivot_data, column_meta_data, sort_orders);
                dispatch_updated_pivot_data(sorted_data, sort_orders, id)(dispatch)

                update_sort_order(table_id_to_use, sort_orders)(dispatch)
                saveInnerChat(id, { text: "Success! Sorting completed." }, true)(dispatch)


            }
            else if (action === POLUS_INSTRUCTION_MODULE_TYPE.FORMULA) {

                const _data = [];

                _data.push({
                    ...parsed_data
                })

                save_columns_info(_data, id, id, be_file_path)(dispatch)
                saveInnerChat(id, { text: `Success! ${parsed_data?.alias} formula applied.` }, true)(dispatch)

            } else if (action === POLUS_INSTRUCTION_MODULE_TYPE.FILTER) {

                const pivot_columns_state_clone = serverData?.pivot_data?.pivot_columns_state ? s_p(serverData?.pivot_data?.pivot_columns_state) : {}

                if (pivot_columns_state_clone && Object.keys(pivot_columns_state_clone)?.length > 0) {
                    pivot_columns_state_clone[parsed_data?.column] = {
                        ...pivot_columns_state_clone[parsed_data?.column],
                        "filter": parsed_data?.filter
                    }
                }
                update_report_column_state(id, pivot_columns_state_clone, 'pivot_data')(dispatch);
                saveInnerChat(id, { text: "Success! Filter applied to data." }, true)(dispatch)
            }

        } else {
            saveInnerChat(id, { text: "Sorry, we couldn't follow you." }, true)(dispatch)
        }
    } catch (error) {
        saveInnerChat(id, { text: "Sorry, we couldn't follow you." }, true)(dispatch)
    }
};


