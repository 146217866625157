import React, { useState, useEffect, useRef } from 'react';
import { Wrapper, Content, ContentTitle, ButtonWrapper, InputWrapper, AutoComplete, InputLabel } from './styled';
import { get_hints_in_object, find_hint_in_array, check_brackets } from '../../utils';
import Overlay from '../helper/overlay';

const arrayOfOperators = ['+', '-', '*', '/', '%', '='];
let current_index = undefined;


function MiniFormula(props) {

    const { formulaValue, setFormulaValue, hints = [] } = props;

    const [formData, setFormData] = useState(undefined)
    const [currentInputValue, setCurrentInputValue] = useState('');
    const [autoCompleteWords, setAutoCompleteWords] = useState(undefined);
    const [inputFocus, setInputFocus] = useState(undefined);
    const [index_being_edited, set_index_being_edited] = useState(-1);
    const [editing_text, set_editing_text] = useState(undefined);

    const [showSuggestions, setSuggestions] = useState(undefined);


    const inputRef = useRef(null)
    useEffect(() => {
        setFormData(formulaValue)
    }, [formulaValue])


    const _onChange = (value) => {
        current_index = undefined;
        const hints_from_array = find_hint_in_array(value, undefined, hints);
        setAutoCompleteWords(hints_from_array)
        setCurrentInputValue(value)

    };

    const mark_text_for_editing = (index) => {
        set_index_being_edited(index);
    };

    const submit_text_for_editing = (index) => {
        set_index_being_edited(index);

        const clone_form_data = formData ? [...formData] : [];

        clone_form_data[index] = editing_text;
        set_editing_text(undefined);
        setFormData(clone_form_data);
        setFormulaValue(clone_form_data)
        set_index_being_edited(-1);
    };


    const find_user_value_inside_hints = (value) => {
        let found = value;
        if (hints && hints.length > 0) {
            for (let index = 0; index < hints.length; index++) {
                const element = hints[index] && hints[index].toLowerCase();
                if (element === (value && value.length > 0 && value.toLocaleLowerCase())) {
                    found = hints[index];
                    break;
                }
            }
        }
        // console.log("formula t.2", found, hints);

        return found;
    }

    let clicked_or_not = false;



    return (
        <div style={{ position: 'relative', zIndex: '2' }}>
            {showSuggestions && (
                <Overlay
                    zIndex={"2"}
                    onClick={() => {
                        setSuggestions(undefined);
                    }}
                />
            )}

            

            <InputWrapper style={{ border: '1px solid #ccc' }}>
                {typeof formData !== 'undefined' && formData && formData.length > 0 && formData.map((t, j) => {
                    let _is_oprator = arrayOfOperators.indexOf(t) > -1;
                    return (
                        index_being_edited === j ? <input type='text' defaultValue={t}
                            onChange={(event) => {
                                set_editing_text(event.target.value)

                            }}

                            onKeyDown={(e) => {
                                const keyCode = e.keyCode;
                                if (keyCode === 13) {
                                    submit_text_for_editing(j);
                                }
                            }}

                            onBlur={() => submit_text_for_editing(j)} /> :
                            <div onClick={() => mark_text_for_editing(j)} style={{ borderBottomColor: _is_oprator ? 'transparent' : '#000' }} className='tag'>
                                {t && t.length > 0 ? t.split("_nfx_")[0] : t}
                            </div>
                    )
                })}
                <div style={{ position: 'relative', flex: 1, minWidth: '100px' }}>


                    <input
                        style={{ height: '1.8rem' }}
                        // autoFocus={true}
                        onChange={(event) => {
                            _onChange(event.target.value)
                        }}
                        ref={inputRef}
                        onFocus={() => {
                            setInputFocus(true)
                            setSuggestions(true)
                        }}
                        onBlur={(event) => {
                            setTimeout(() => {
                                if (clicked_or_not || currentInputValue.length === 0) {
                                    clicked_or_not = false;
                                    // console.log("formData", )
                                    // setFormulaValue(formData)
                                }
                                else {
                                    let temp_form_data = formData ? [...formData] : [];
                                    const value = currentInputValue;
                                    const value_arr = value && value.split(' ') || [];
                                    if (value_arr && value_arr.length > 0) {
                                        value_arr.forEach((v) => {
                                            temp_form_data.push(find_user_value_inside_hints(v))
                                        })
                                    }

                                    setFormulaValue(temp_form_data)
                                    setCurrentInputValue('')
                                }
                            }, 300);

                        }}

                        value={currentInputValue}
                        onKeyDown={(event) => {

                            const keyCode = event.keyCode;
                            const _value = event.target.value

                            const clone_form_data = formData ? [...formData] : [];

                            // when user press Enter add current value
                            if (keyCode === 13) {
                                clone_form_data.push(find_user_value_inside_hints(_value));
                                setFormData(clone_form_data);
                                // console.log("clone_form_data", clone_form_data)
                                setFormulaValue(clone_form_data)
                                setCurrentInputValue('');
                                current_index = undefined;
                                if (arrayOfOperators.indexOf(_value) === -1) {
                                    setAutoCompleteWords(arrayOfOperators);
                                } else {
                                    setAutoCompleteWords(undefined);
                                    setCurrentInputValue('');
                                    current_index = undefined;
                                }
                            }


                            // when user Click on BackSpace remove last word from string
                            if (keyCode === 8) {
                                if (!clone_form_data) {
                                    setAutoCompleteWords(undefined);
                                }
                                if (!_value && clone_form_data && clone_form_data.length > 0) {
                                    clone_form_data.pop();
                                    setFormData(clone_form_data);
                                    setFormulaValue(clone_form_data)
                                    setCurrentInputValue('');
                                }
                               
                            }


                            let total_suggested_items_length = autoCompleteWords && autoCompleteWords.length;

                            // page down
                            if (keyCode === 40) {

                                let temp_index = (current_index == 0 || current_index) ? current_index + 1 : 0;
                                current_index = temp_index;
                                if (total_suggested_items_length === temp_index) {
                                    current_index = 0;
                                    temp_index = 0;
                                }
                                let current_item = autoCompleteWords && autoCompleteWords[temp_index]
                                setCurrentInputValue(current_item)
                            }
                            // page up 
                            if (keyCode === 38) {

                                // current_index = current_index - 1;
                                let temp_index = current_index - 1;
                                if (current_index <= 0) {
                                    temp_index = total_suggested_items_length
                                }
                                current_index = temp_index;

                                let current_item = autoCompleteWords && autoCompleteWords[temp_index]
                                if (current_item) {
                                    setCurrentInputValue(current_item)
                                }

                            }

                        }}
                    />
                    {
                        showSuggestions && autoCompleteWords && autoCompleteWords.length > 0 &&

                        <AutoComplete
                            gap={autoCompleteWords[0] === '+'}
                        >
                            {autoCompleteWords && autoCompleteWords.map((word, i) => {
                                return (
                                    <div
                                        onClick={() => {
                                            clicked_or_not = true;
                                            const clone_form_value = formData ? [...formData] : [];
                                            clone_form_value.push(word)
                                            setFormData(clone_form_value)
                                            setFormulaValue(clone_form_value)

                                            if (arrayOfOperators.indexOf(word) === -1) {
                                                setAutoCompleteWords(arrayOfOperators);
                                            } else {
                                                setAutoCompleteWords(undefined);
                                                setCurrentInputValue('');
                                                current_index = undefined;
                                            }
                                            setCurrentInputValue('')

                                        }}
                                        className={`word ${(currentInputValue && currentInputValue === word) ? ' selected' : 'none_selected'}`}
                                    >{
                                            word && word.length > 0 ? word.split("_nfx_")[0] : word
                                        }</div>
                                )
                            })}
                        </AutoComplete>
                    }
                </div>
            </InputWrapper>
        </div>
    )
}

export default MiniFormula;
