import React from 'react';

const Theme = (props) => {

    return (
        <svg width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 437 439"
            space="preserve" >
            <defs>
                <symbol overflow="visible" id="l">
                    <path id="svg_1" d="m29.312,-1.75c-1.5117,0.78125 -3.0898,1.3711 -4.7344,1.7656c-1.6367,0.40625 -3.3398,0.60938 -5.1094,0.60938c-5.3125,0 -9.5273,-1.4844 -12.641,-4.4531c-3.1055,-2.9688 -4.6562,-7 -4.6562,-12.094s1.5508,-9.125 4.6562,-12.094c3.1133,-2.9688 7.3281,-4.4531 12.641,-4.4531c1.7695,0 3.4727,0.19922 5.1094,0.59375c1.6445,0.39844 3.2227,0.99219 4.7344,1.7812l0,6.5938c-1.5312,-1.0391 -3.0391,-1.8008 -4.5156,-2.2812c-1.4805,-0.48828 -3.0391,-0.73438 -4.6719,-0.73438c-2.9375,0 -5.2461,0.94531 -6.9219,2.8281c-1.6797,1.875 -2.5156,4.4648 -2.5156,7.7656c0,3.293 0.83594,5.8828 2.5156,7.7656c1.6758,1.875 3.9844,2.8125 6.9219,2.8125c1.6328,0 3.1914,-0.23828 4.6719,-0.71875c1.4766,-0.48828 2.9844,-1.2539 4.5156,-2.2969l0,6.6099z" />
                </symbol>
                <symbol overflow="visible" id="f">
                    <path id="svg_2" d="m21.453,-17.406c-0.67969,-0.3125 -1.3516,-0.53906 -2.0156,-0.6875c-0.65625,-0.15625 -1.3203,-0.23438 -1.9844,-0.23438c-1.9688,0 -3.4844,0.63281 -4.5469,1.8906c-1.0547,1.2617 -1.5781,3.0703 -1.5781,5.4219l0,11.016l-7.6562,0l0,-23.922l7.6562,0l0,3.9219c0.97656,-1.5625 2.1016,-2.7031 3.375,-3.4219c1.2812,-0.71875 2.8125,-1.0781 4.5938,-1.0781c0.25,0 0.52344,0.01172 0.82812,0.03125c0.30078,0.02344 0.73438,0.07031 1.2969,0.14062l0.03118,6.92161z" />
                </symbol>
                <symbol overflow="visible" id="e">
                    <path id="svg_3" d="m27.562,-12.031l0,2.1875l-17.891,0c0.1875,1.793 0.83203,3.1367 1.9375,4.0312c1.1133,0.89844 2.6719,1.3438 4.6719,1.3438c1.6016,0 3.25,-0.23438 4.9375,-0.70312c1.6875,-0.47656 3.4219,-1.2031 5.2031,-2.1719l0,5.8906c-1.8047,0.6875 -3.6094,1.2031 -5.4219,1.5469c-1.8125,0.35156 -3.6211,0.53125 -5.4219,0.53125c-4.3359,0 -7.7031,-1.0977 -10.109,-3.2969c-2.3984,-2.207 -3.5938,-5.2969 -3.5938,-9.2656c0,-3.9062 1.1758,-6.9727 3.5312,-9.2031c2.3633,-2.2383 5.6094,-3.3594 9.7344,-3.3594c3.7578,0 6.7695,1.1367 9.0312,3.4062c2.2578,2.2617 3.3906,5.2812 3.3906,9.0625l0.0002,0.00007zm-7.8594,-2.5312c0,-1.457 -0.42969,-2.6289 -1.2812,-3.5156c-0.84375,-0.89453 -1.9492,-1.3438 -3.3125,-1.3438c-1.4922,0 -2.6992,0.41797 -3.625,1.25c-0.91797,0.83594 -1.4922,2.0391 -1.7188,3.6094l9.9375,0z" />
                </symbol>
                <symbol overflow="visible" id="b">
                    <path id="svg_4" d="m14.391,-10.766c-1.5938,0 -2.793,0.27344 -3.5938,0.8125c-0.80469,0.54297 -1.2031,1.3398 -1.2031,2.3906c0,0.96875 0.32031,1.7305 0.96875,2.2812c0.64453,0.54297 1.5469,0.8125 2.7031,0.8125c1.4375,0 2.6445,-0.51562 3.625,-1.5469c0.98828,-1.0312 1.4844,-2.3203 1.4844,-3.875l0,-0.875l-3.98435,0.0001zm11.688,-2.8906l0,13.656l-7.7031,0l0,-3.5469c-1.0312,1.4492 -2.1875,2.5078 -3.4688,3.1719s-2.8398,1 -4.6719,1c-2.4805,0 -4.4961,-0.72266 -6.0469,-2.1719c-1.543,-1.4453 -2.3125,-3.3203 -2.3125,-5.625c0,-2.8125 0.96094,-4.8672 2.8906,-6.1719c1.9375,-1.3125 4.9688,-1.9688 9.0938,-1.9688l4.5156,0l0,-0.60938c0,-1.207 -0.48047,-2.0938 -1.4375,-2.6562c-0.94922,-0.5625 -2.4375,-0.84375 -4.4688,-0.84375c-1.6367,0 -3.1562,0.16797 -4.5625,0.5c-1.4062,0.32422 -2.7188,0.8125 -3.9375,1.4688l0,-5.8281c1.6445,-0.40625 3.2891,-0.70703 4.9375,-0.90625c1.6562,-0.20703 3.3047,-0.3125 4.9531,-0.3125c4.3203,0 7.4375,0.85547 9.3438,2.5625c1.9141,1.6992 2.875,4.4609 2.875,8.2812l0.0001,0.00028z" />
                </symbol>
                <symbol overflow="visible" id="d">
                    <path id="svg_5" d="m12.031,-30.719l0,6.7969l7.875,0l0,5.4688l-7.875,0l0,10.141c0,1.1172 0.21875,1.8711 0.65625,2.2656c0.4375,0.38672 1.3125,0.57812 2.625,0.57812l3.9375,0l0,5.4688l-6.5625,0c-3.0234,0 -5.1641,-0.62891 -6.4219,-1.8906c-1.2617,-1.2578 -1.8906,-3.3984 -1.8906,-6.4219l0,-10.141l-3.7969,0l0,-5.4688l3.7969,0l0,-6.7969l7.65625,-0.00002z" />
                </symbol>
                <symbol overflow="visible" id="j">
                    <path id="svg_6" d="m19.953,-20.422l0,-12.812l7.6875,0l0,33.234l-7.6875,0l0,-3.4531c-1.0547,1.4062 -2.2148,2.4375 -3.4844,3.0938c-1.2734,0.65625 -2.7422,0.98438 -4.4062,0.98438c-2.9492,0 -5.3711,-1.1719 -7.2656,-3.5156c-1.8867,-2.3438 -2.8281,-5.3594 -2.8281,-9.0469s0.94141,-6.7031 2.8281,-9.0469c1.8945,-2.3438 4.3164,-3.5156 7.2656,-3.5156c1.6562,0 3.1172,0.33594 4.3906,1c1.2812,0.65625 2.4453,1.6836 3.5,3.0781l0,-0.00018zm-5.0469,15.484c1.6445,0 2.8945,-0.59766 3.75,-1.7969c0.86328,-1.1953 1.2969,-2.9297 1.2969,-5.2031c0,-2.2812 -0.43359,-4.0195 -1.2969,-5.2188c-0.85547,-1.1953 -2.1055,-1.7969 -3.75,-1.7969c-1.625,0 -2.8711,0.60156 -3.7344,1.7969c-0.85547,1.1992 -1.2812,2.9375 -1.2812,5.2188c0,2.2734 0.42578,4.0078 1.2812,5.2031c0.86328,1.1992 2.1094,1.7969 3.7344,1.7969z" />
                </symbol>
                <symbol overflow="visible" id="a">
                    <path id="svg_7" d="m16.406,-4.9375c1.6328,0 2.8828,-0.59766 3.75,-1.7969c0.86328,-1.1953 1.2969,-2.9297 1.2969,-5.2031c0,-2.2812 -0.43359,-4.0195 -1.2969,-5.2188c-0.86719,-1.1953 -2.1172,-1.7969 -3.75,-1.7969c-1.6367,0 -2.8906,0.60547 -3.7656,1.8125c-0.875,1.1992 -1.3125,2.9336 -1.3125,5.2031c0,2.2617 0.4375,3.9961 1.3125,5.2031c0.875,1.1992 2.1289,1.7969 3.7656,1.7969l0,0.0001zm-5.0781,-15.484c1.0508,-1.3945 2.2188,-2.4219 3.5,-3.0781c1.2812,-0.66406 2.7539,-1 4.4219,-1c2.9453,0 5.3672,1.1719 7.2656,3.5156c1.8945,2.3438 2.8438,5.3594 2.8438,9.0469s-0.94922,6.7031 -2.8438,9.0469c-1.8984,2.3438 -4.3203,3.5156 -7.2656,3.5156c-1.668,0 -3.1406,-0.33594 -4.4219,-1s-2.4492,-1.6914 -3.5,-3.0781l0,3.4531l-7.6562,0l0,-33.234l7.6562,0l0,12.8121z" />
                </symbol>
                <symbol overflow="visible" id="i">
                    <path id="svg_8" d="m0.53125,-23.922l7.6562,0l6.4219,16.234l5.4688,-16.234l7.6562,0l-10.062,26.188c-1.0117,2.6641 -2.1953,4.5234 -3.5469,5.5781c-1.3438,1.0625 -3.1211,1.5938 -5.3281,1.5938l-4.4219,0l0,-5.0156l2.3906,0c1.3008,0 2.2422,-0.21094 2.8281,-0.625c0.59375,-0.40625 1.0547,-1.1484 1.3906,-2.2188l0.20312,-0.65625l-10.65662,-24.84425z" />
                </symbol>
                <symbol overflow="visible" id="h">
                    <path id="svg_9" d="m22.359,-23.172l0,5.7969c-1.6367,-0.67578 -3.2148,-1.1875 -4.7344,-1.5312c-1.5234,-0.34375 -2.9609,-0.51562 -4.3125,-0.51562c-1.4609,0 -2.543,0.18359 -3.25,0.54688c-0.69922,0.36719 -1.0469,0.92188 -1.0469,1.6719c0,0.61719 0.26562,1.0898 0.79688,1.4219c0.53125,0.32422 1.4883,0.5625 2.875,0.71875l1.3438,0.1875c3.9141,0.5 6.5508,1.3242 7.9062,2.4688c1.3516,1.1367 2.0312,2.9219 2.0312,5.3594c0,2.5547 -0.94531,4.4688 -2.8281,5.75c-1.875,1.2812 -4.6797,1.9219 -8.4062,1.9219c-1.5859,0 -3.2188,-0.125 -4.9062,-0.375s-3.4219,-0.625 -5.2031,-1.125l0,-5.8125c1.5195,0.74219 3.082,1.2969 4.6875,1.6719c1.6016,0.36719 3.2344,0.54688 4.8906,0.54688c1.4883,0 2.6094,-0.20312 3.3594,-0.60938c0.75781,-0.41406 1.1406,-1.0312 1.1406,-1.8438c0,-0.6875 -0.26172,-1.1953 -0.78125,-1.5312c-0.52344,-0.33203 -1.5586,-0.59375 -3.1094,-0.78125l-1.3438,-0.17188c-3.4062,-0.42578 -5.793,-1.2109 -7.1562,-2.3594c-1.3672,-1.1562 -2.0469,-2.9102 -2.0469,-5.2656c0,-2.5312 0.86719,-4.4062 2.6094,-5.625c1.7383,-1.2266 4.3984,-1.8438 7.9844,-1.8438c1.4062,0 2.8828,0.10938 4.4375,0.32812c1.5508,0.21094 3.2383,0.54297 5.0625,1l-0.00003,-0.0002z" />
                </symbol>
                <symbol overflow="visible" id="c">
                    <path id="svg_10" d="m3.6719,-23.922l7.6562,0l0,23.922l-7.6562,0l0,-23.922zm0,-9.3125l7.6562,0l0,6.2344l-7.6562,0l0,-6.2344z" />
                </symbol>
                <symbol overflow="visible" id="g">
                    <path id="svg_11" d="m27.734,-14.562l0,14.562l-7.7031,0l0,-11.109c0,-2.0938 -0.04687,-3.5312 -0.14062,-4.3125c-0.08594,-0.78125 -0.24219,-1.3594 -0.46875,-1.7344c-0.30469,-0.5 -0.71094,-0.88281 -1.2188,-1.1562c-0.51172,-0.28125 -1.0938,-0.42188 -1.75,-0.42188c-1.5938,0 -2.8516,0.61719 -3.7656,1.8438c-0.90625,1.2305 -1.3594,2.9375 -1.3594,5.125l0,11.766l-7.6562,0l0,-33.234l7.6562,0l0,12.812c1.1445,-1.3945 2.3633,-2.4219 3.6562,-3.0781c1.3008,-0.66406 2.7383,-1 4.3125,-1c2.7578,0 4.8516,0.85156 6.2812,2.5469c1.4375,1.6875 2.1562,4.1523 2.1562,7.3906l0.00017,-0.00022z" />
                </symbol>
                <symbol overflow="visible" id="k">
                    <path id="svg_12" d="m25.844,-19.953c0.96875,-1.4766 2.1172,-2.6016 3.4531,-3.375c1.332,-0.78125 2.7969,-1.1719 4.3906,-1.1719c2.75,0 4.8438,0.85156 6.2812,2.5469c1.4375,1.6875 2.1562,4.1523 2.1562,7.3906l0,14.562l-7.6875,0l0,-12.469c0.00781,-0.1875 0.01953,-0.37891 0.03125,-0.57812c0.00781,-0.19531 0.01562,-0.48438 0.01562,-0.85938c0,-1.6953 -0.25,-2.9258 -0.75,-3.6875c-0.5,-0.75781 -1.3086,-1.1406 -2.4219,-1.1406c-1.4492,0 -2.5703,0.60156 -3.3594,1.7969c-0.79297,1.1992 -1.2031,2.9297 -1.2344,5.1875l0,11.75l-7.6875,0l0,-12.469c0,-2.6562 -0.23047,-4.3633 -0.6875,-5.125c-0.44922,-0.75781 -1.2578,-1.1406 -2.4219,-1.1406c-1.4688,0 -2.6055,0.60547 -3.4062,1.8125c-0.79297,1.1992 -1.1875,2.918 -1.1875,5.1562l0,11.766l-7.7031,0l0,-23.922l7.7031,0l0,3.5c0.9375,-1.3516 2.0156,-2.3672 3.2344,-3.0469c1.2188,-0.6875 2.5547,-1.0312 4.0156,-1.0312c1.6562,0 3.1133,0.40234 4.375,1.2031c1.2695,0.79297 2.2344,1.9062 2.8906,3.3438l0.00023,0.0007z" />
                </symbol>
            </defs>
            <g>
                <title>Layer 1</title>
                <g id="svg_13">
                    <path id="svg_14" d="m284.71,403.09c-2.1914,0 -4.2812,-0.03906 -6.25,-0.12109c-57.734,-2.375 -83.434,-40.297 -102.2,-67.992c-8.1797,-12.074 -15.898,-23.465 -22.281,-24.82c-16.469,-3.2539 -27.684,6.8008 -44.172,23.801c-13.469,13.859 -27.062,28.184 -47.355,27.207c-39.309,-5.3633 -58.215,-33.609 -57.094,-84.055c0.55078,-24.309 6.5352,-48.984 17.785,-73.348c35.375,-76.652 87.195,-121.62 158.43,-137.48c123.26,-16.109 201.02,29.672 238.77,139.7c0.11328,0.32813 0.20312,0.67188 0.28516,1.0078c22.688,97.258 -3.9766,160.5 -79.25,187.97c-15.062,5.5 -38.996,8.1328 -56.676,8.1328l0.00784,-0.00251zm-136.65,-114.41c3.2344,0 6.5977,0.31641 10.117,1.0078c14.754,3.1289 24.309,17.242 35.383,33.57c17.172,25.352 38.543,56.91 85.758,58.848c15.754,0.67188 41.207,-1.7656 54.91,-6.7695c64.508,-23.543 86.164,-76.895 66.199,-163.09c-34.547,-100.18 -102.98,-139.97 -215.23,-125.43c-63.527,14.195 -110.63,55.371 -143.08,125.7c-10.043,21.75 -15.387,43.645 -15.867,65.066c-1.1406,50.871 19.793,60.285 38.156,62.812c9.625,-0.03906 19.25,-9.4844 30.418,-20.984c13.469,-13.871 29.824,-30.727 53.234,-30.727l0.002,-0.0033zm-115.43,-80.547l0.10156,0l-0.10156,0z" />
                    <path id="svg_15" d="m132.86,176.21c-16.051,0 -29.113,-13.133 -29.113,-29.277c0,-16.141 13.062,-29.277 29.113,-29.277c16.059,0 29.121,13.133 29.121,29.277c0,16.145 -13.062,29.277 -29.121,29.277zm0,-37.668c-4.5391,0 -8.2305,3.7617 -8.2305,8.3906c0,4.6289 3.6914,8.3906 8.2305,8.3906c4.5469,0 8.2383,-3.7617 8.2383,-8.3906c0,-4.6289 -3.6914,-8.3906 -8.2383,-8.3906z" />
                    <path id="svg_16" d="m319.8,216.63c-24.441,0 -44.328,-20.016 -44.328,-44.621c0,-24.605 19.883,-44.621 44.328,-44.621c24.441,0 44.328,20.016 44.328,44.621c0,24.602 -19.887,44.621 -44.328,44.621zm0,-68.363c-12.93,0 -23.441,10.645 -23.441,23.738s10.512,23.738 23.441,23.738c12.93,0 23.441,-10.645 23.441,-23.738c0,-13.09 -10.512,-23.738 -23.441,-23.738z" />
                    <path id="svg_17" d="m221.08,165.43c-20.148,0 -36.547,-16.398 -36.547,-36.547c0,-20.148 16.398,-36.547 36.547,-36.547c20.148,0 36.547,16.398 36.547,36.547c0,20.148 -16.395,36.547 -36.547,36.547zm0,-52.211c-8.6367,0 -15.664,7.0273 -15.664,15.664c0,8.6367 7.0273,15.664 15.664,15.664s15.664,-7.0273 15.664,-15.664c0,-8.6367 -7.0234,-15.664 -15.664,-15.664z" />
                    <path id="svg_18" d="m306.18,363.28c-33.875,0 -61.426,-27.758 -61.426,-61.863c0,-34.109 27.551,-61.863 61.426,-61.863s61.438,27.758 61.438,61.863s-27.562,61.863 -61.438,61.863zm0,-102.85c-22.352,0 -40.543,18.387 -40.543,40.98c0,22.598 18.191,40.98 40.543,40.98c22.363,0 40.555,-18.387 40.555,-40.98s-18.191,-40.98 -40.555,-40.98z" />
                    <path id="svg_19" d="m347.86,82.53l5.4922,-8.75l24.73,18.75l-5.8359,8.3281l-24.3863,-18.3281z" />
                    <path id="svg_20" d="m372.24,106.08c-1.1211,0 -2.2227,-0.36719 -3.1406,-1.0508l-24.391,-18.336c-2.1602,-1.6328 -2.7227,-4.6484 -1.2852,-6.9453l5.4961,-8.75c0.78516,-1.2422 2.0508,-2.1016 3.4883,-2.3672c1.4375,-0.22266 2.9375,0.10156 4.0898,0.98047l24.727,18.754c2.2422,1.6914 2.7344,4.8555 1.1211,7.1602l-5.832,8.332c-0.80469,1.1523 -2.0586,1.9375 -3.457,2.1602c-0.26562,0.04297 -0.53906,0.0625 -0.81641,0.0625l-0.00009,-0.00007z" />
                    <path id="svg_21" d="m382.69,95.53c-1.1211,0 -2.2227,-0.35547 -3.1406,-1.0508l-27.492,-20.699c-0.9375,-0.70312 -1.6094,-1.7148 -1.9062,-2.8438c-3.8125,-14.531 1.4883,-32.621 13.492,-46.082c17.02,-19.059 50.73,-31.602 68.738,-19.223c2.2227,1.5391 2.9062,4.5273 1.5703,6.8828c-4.5898,8.0547 -4.5156,17.152 -4.4375,26.777c0.08984,12.359 0.19531,25.145 -9.6562,36.199c-12.543,14.051 -34.977,19.66 -35.934,19.883c-0.41016,0.10547 -0.81641,0.15625 -1.2344,0.15625l0.0006,0.00055zm-22.824,-28.938l23.891,17.988c5.6094,-1.7031 20.047,-6.7812 28.309,-16.039c7.168,-8.0469 7.0977,-17.824 7.0039,-29.164c-0.07031,-8.9336 -0.14453,-18.129 3.4258,-27.094c-13.348,-4.5156 -37.352,4.1484 -51.066,19.527c-10.418,11.684 -13.266,25.523 -11.562,34.781l-0.0017,0.001zm56.094,5.4258l0.10156,0l-0.10156,0z" />
                    <path id="svg_22" d="m165.67,342.01l-76.199,85.297c-5.2734,5.9062 -14.191,6.4102 -19.973,1.1406c-5.7812,-5.1445 -6.2891,-14.059 -1.1406,-19.965l79.34,-88.691l17.9726,22.2184z" />
                    <path id="svg_23" d="m78.86,437.31c-4.7734,0 -9.3203,-1.7539 -12.879,-5.0078c-7.8398,-6.9766 -8.5664,-19.223 -1.5586,-27.258l79.383,-88.723c1.0312,-1.1406 2.1016,-1.8164 4.0273,-1.7422c1.5312,0.03906 2.9688,0.74609 3.9258,1.9375l17.977,22.211c1.6094,1.9883 1.5391,4.8555 -0.16406,6.7617l-76.203,85.297c-3.5078,3.9258 -8.3008,6.2422 -13.5,6.5039c-0.33203,0.01172 -0.67188,0.01953 -1.0078,0.01953l-0.00064,0.00037zm68.637,-109.45l-75.242,84.105c-3.2227,3.6914 -2.918,9.3594 0.71484,12.582c1.793,1.6328 4.1094,2.4688 6.3633,2.3164c2.3945,-0.12109 4.6211,-1.1914 6.25,-3.0273l73.246,-81.984l-11.33214,-13.9921z" />
                    <use id="svg_24" y="630.25" x="-49" />
                    <use id="svg_25" y="630.25" x="-16.89063" />
                    <use id="svg_26" y="630.25" x="4.68359" />
                    <use id="svg_27" y="630.25" x="34.35547" />
                    <use id="svg_28" y="630.25" x="63.87891" />
                    <use id="svg_29" y="630.25" x="84.79297" />
                    <use id="svg_30" y="630.25" x="114.46484" />
                    <use id="svg_31" y="630.25" x="161.01172" />
                    <use id="svg_32" y="630.25" x="192.32813" />
                    <use id="svg_33" y="630.25" x="236.07813" />
                    <use id="svg_34" y="630.25" x="267.39844" />
                    <use id="svg_35" y="630.25" x="296.91797" />
                    <use id="svg_36" y="630.25" x="322.96094" />
                    <use id="svg_37" y="630.25" x="337.95703" />
                    <use id="svg_38" y="630.25" x="358.87109" />
                    <use id="svg_39" y="630.25" x="405.25" />
                    <use id="svg_40" y="630.25" x="420.24609" />
                    <use id="svg_41" y="630.25" x="451.5625" />
                    <use id="svg_42" y="630.25" x="473.13672" />
                    <use id="svg_43" y="630.25" x="502.66016" />
                    <use id="svg_44" y="630.25" x="533.80469" />
                    <use id="svg_45" y="630.25" x="548.80469" />
                </g>
            </g>
        </svg>

    )

}


export default Theme;