import styled from 'styled-components';



export const Wrapper = styled.div`

    position: relative;
    width: 80vw;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 6px 0px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(234, 234, 234);
    border-image: initial;
    padding: 1.643rem 1.429rem;
    overflow-y: auto;
    height: 80vh;
    position: relative;
`;



export const Close = styled.div`
    background: #cccccc4f;
    /* border-radius: relative; */
    top: -10px;
    right: -8px;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    cursor: pointer;

    &:hover{
        background-color: #eaeaea;
        svg{
            fill: rgb(0,138,199);
        }
    }

`;