import React, { useState } from 'react';
import styled from 'styled-components';
import TickSvg from '../svg/tick'

const SteperWrapper = styled.div`
    width: ${props => props.width ? props.width : '100%'};
    margin: 0rem auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .extra_label {
        text-transform: capitalize;
        text-decoration: underline;
    }
`;



const StepWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    background: #fff;
    cursor: pointer;
    background-color: ${props => (props.active || props.completed) ? '#E8E8F9 !important' : undefined};
    justify-content: center;
    &:hover{
        background: #eaeaea80;
        border-radius: 3px;
    }
    .step_circle{
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 50%;
        background-color: ${props => props.active ? '#2173F7' : props.completed ? "#0bad0b9c" : '#B3B3B3'};
        text-align: center;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        margin-right: .8rem;
        color: #fff;
    }

    &::before{
        content: ${props => (props.completed || props.active) ? "''" : undefined};
        width: ${props => props.completed ? '101%' : '70%'};
        position: absolute;
        height: 3px;
        background: #2173F7;
        bottom: -2px;
        left: 0px;
        border-radius: 27px;
        overflow: hidden;
        transition: all .2s;
    }
`;






const SteperLine = styled.div`
    flex: 1;
    border: 2px solid #ccc;
    position: relative;
    border-radius: 3px;

     &::before{
        position: absolute;
        content: '';
        width: ${props => props.completed ? '100%' : '0%'};
        opacity: ${props => props.completed ? '1' : '-1'};
        border:  ${props => props.completed ? '2px solid #00800040' : '2px solid #308bc8'};
        z-index: 1;
        top: -2px;
        left: -2px;
        transition: all 0.5s;
        border-radius: 3px;
     }
`;







const Index = (props) => {

    const { steps, disableSteps, activeStep, setActiveStep, wrapperWidth, extraLabel, stepper_style, step_style, hide_stepper_line, enable_next } = props;

    return (
        <SteperWrapper width={wrapperWidth} style={stepper_style}>
            {steps && steps.length > 0 && steps.map((step, i) => {

                let _active = activeStep == i;
                let _completedStep = activeStep > i;

                return (
                    <React.Fragment>
                        <StepWrapper
                            key={"step" + i}
                            completed={_completedStep}
                            onClick={() => {
                                if (activeStep > i || enable_next) {
                                    setActiveStep(i)
                                }
                            }}
                            active={_active}
                            style={step_style}
                            numberOfSteps={steps && steps.length}>
                            <div className="step_circle" >
                                {_completedStep ? <TickSvg size="1rem" height="1rem" color='#fff' /> : i + 1}
                            </div>
                            <div className="step_name">{step}&nbsp; {extraLabel ? <span className="extra_label"> {i === 0 ? `(${extraLabel})` : null}</span>  : null}</div>
                        </StepWrapper>

                        {!hide_stepper_line && steps.length !== (i + 1) ? <SteperLine completed={_completedStep} /> : null}
                        
                    </React.Fragment>
                )
            })}
        </SteperWrapper>
    )
}

export default Index;
