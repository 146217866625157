import React from 'react';

const ExpandRact = (props) => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg"
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            viewBox="0 0 100 125"
            {...props}
            space="preserve">
                

            <polygon points="14.5 33.86 19.5 33.86 19.5 19.5 33.86 19.5 33.86 14.5 14.5 14.5 14.5 33.86" />
            <polygon points="66.14 85.5 85.5 85.5 85.5 66.14 80.5 66.14 80.5 80.5 66.14 80.5 66.14 85.5" />
            <polygon points="14.5 85.5 33.86 85.5 33.86 80.5 19.5 80.5 19.5 66.14 14.5 66.14 14.5 85.5" />
            <polygon points="85.5 14.5 66.14 14.5 66.14 19.5 80.5 19.5 80.5 33.86 85.5 33.86 85.5 14.5" />
            <path d="M65.6167,71.61523h3.5a2.49981,2.49981,0,0,0,2.5-2.5v-3.5h-5v1h-1Z" />
            <rect x="40.62988" y="66.61523" width="6.24707" height="5" />
            <rect x="53.12354" y="66.61523" width="6.24658" height="5" />
            <path d="M28.3833,69.11523a2.49981,2.49981,0,0,0,2.5,2.5h3.5v-5h-1v-1h-5Z" />
            <rect x="28.3833" y="40.62793" width="5" height="6.24707" />
            <rect x="28.3833" y="53.12109" width="5" height="6.24707" />
            <path d="M34.3833,28.38184h-3.5a2.49981,2.49981,0,0,0-2.5,2.5v3.5h5v-1h1Z" />
            <rect x="40.62988" y="28.38184" width="6.24658" height="5" />
            <rect x="53.12305" y="28.38184" width="6.24707" height="5" />
            <path d="M65.6167,33.38184h1v1h5v-3.5a2.49981,2.49981,0,0,0-2.5-2.5h-3.5Z" />
            <rect x="66.6167" y="40.62793" width="5" height="6.24707" />
            <rect x="66.6167" y="53.12109" width="5" height="6.24707" />
        </svg>


    )

}


export default ExpandRact;