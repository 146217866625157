import * as actions from '../action-types/table_format';

const initialState = {
    type: actions.TABLE_FORMAT_INIT,
    table_format_setting_cache: {},
};

const tableFormatReducer = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        
        case actions.GET_TBL_FORMAT_SETTING:
        case actions.SAVE_TBL_FORMAT_SETTING:
        case actions.ADD_SAVE_TBL_FORMAT_SETTING:

            const payload = action.data;
            const report_id = payload.report_id;
            const settings = payload.settings;
            const prev_format_settings = state.table_format_setting_cache ? JSON.parse(JSON.stringify(state.table_format_setting_cache)) : {};
            prev_format_settings[report_id] = settings;
            return {
                ...state,
                type: action.type,
                table_format_setting_cache: prev_format_settings
            };


        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
            return {
                ...state,
            };
    }
};

export default tableFormatReducer;