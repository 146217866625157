import React, { useState, useEffect } from 'react';
import { Wrapper, AnswerWrapper } from './single.answer.styled';
import { formatValueByDataType } from '../../utils';
import * as DataType from '../../../src/shared-with-fe/data.types';


const SingleAnswer = (props) => {

    const { isReport, columnMeta, isDiscovery, insights, singleCellData, pivot_data_ultimate_yacs } = props;
    const [headers, setHeaders] = useState(undefined);
    const [columnMetaHeaders, setColumnMetaHeaders] = useState(undefined);

    let tempData = Object.assign({}, singleCellData)
    delete tempData['sortKey'];
    let data_keys = (Object.keys(tempData) || [])

    data_keys = data_keys.filter(f => f.toLocaleLowerCase() !== 'value' && !f?.includes('_nf'));

    useEffect(() => {
        if (singleCellData && data_keys.length > 0) {
            setHeaders(data_keys)
        }
    }, [singleCellData])

    useEffect(() => {
        if (columnMeta && Object.keys(columnMeta).length > 0) {
            setColumnMetaHeaders(columnMeta);
        }
    }, [columnMeta]);


    let _width = singleCellData && data_keys.length > 0 && ((data_keys.length <= 2) && !isDiscovery) ? '80vw' : '100%';
    let _margin_left = singleCellData && data_keys.length > 0 && ((data_keys.length <= 2) && !isDiscovery) ? '10vw' : '';
    let _height = singleCellData && data_keys.length > 0 && ((data_keys.length <= 2) && !isDiscovery) ? '70vh' : '';


    console.log("data_keys", data_keys)
    return (
        <React.Fragment>
            {
                !isReport ?
                    <Wrapper
                        isReport={isReport}
                        style={{
                            display: singleCellData && data_keys.length > 0 && data_keys.length <= 2 ? 'flex' : '',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            width: _width,
                            marginLeft: _margin_left,
                            height: _height
                        }}
                    >
                        {
                            typeof singleCellData === 'object' && data_keys.length >= 1 && data_keys.length <= 2 ? data_keys.map((key, index) => {
                                const displayValue = (columnMeta && columnMeta[key]) ? columnMeta[key].display_value : key;
                                const value = singleCellData[key];
                                const aligment = pivot_data_ultimate_yacs && pivot_data_ultimate_yacs[key];
                                const data_type = aligment && aligment.type;
                                const currency_type = aligment?.['currency_type'];
                                const use_decimal = aligment?.['use_decimal'] || 2;
                                const num_format_type = data_type === DataType?.currency;
                                const use_percent = data_type === DataType?.percent;
                                
                                // const afterFormatted = formatValueByDataType(value, data_type, 'table');
                                const afterFormatted = formatValueByDataType(value, data_type, 'table', undefined, undefined, undefined, currency_type, num_format_type, use_decimal, use_percent);


                                return (
                                    <fieldset key={key}>
                                        <legend>
                                            <div>{displayValue}</div>
                                        </legend>
                                        <AnswerWrapper isDiscovery={isDiscovery}>
                                            {afterFormatted}
                                        </AnswerWrapper>
                                    </fieldset>
                                )
                            })
                                :
                                typeof singleCellData === 'object' && data_keys.length > 2 ?
                                    <table>
                                        <tr>
                                            {
                                                headers && headers.length > 0 && headers.map(data => {
                                                    return <td>{data}</td>
                                                })
                                            }
                                        </tr>
                                        <tr>
                                            {
                                                Object.entries(singleCellData).map(([key, value]) => {
                                                    return <td>{value}</td>
                                                })
                                            }
                                        </tr>
                                    </table>
                                    :
                                    columnMetaHeaders && Object.keys(columnMetaHeaders).length > 0 ?
                                        <table>
                                            <tr>
                                                {
                                                    columnMetaHeaders && Object.keys(columnMetaHeaders).length > 0 && Object.keys(columnMetaHeaders).map(data => {
                                                        const displayValue = columnMeta[data] ? columnMeta[data].display_value : ''
                                                        return <th>{displayValue}</th>
                                                    })
                                                }
                                            </tr>
                                            <tr>
                                                {
                                                    columnMetaHeaders && Object.keys(columnMetaHeaders).length > 0 && Object.keys(columnMetaHeaders).map((data, index) => {
                                                        return (
                                                            <td
                                                                colSpan={index === 0 ? Object.keys(columnMetaHeaders).length : ''}
                                                                style={{
                                                                    display: index === 0 ? '' : 'none', alignItems: 'center', textAlign: 'center'
                                                                }}
                                                            >
                                                                {index === 0 ? 'No Records Found' : ''}
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </table>
                                        :
                                        <div>Found nothing related to your query</div>
                        }
                    </Wrapper>
                    :
                    <div
                        style={{
                            width: props.width,
                            height: props.height,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            border: '1px solid #c1c1c2',
                            borderRadius: '2px'
                        }}>
                        {
                            typeof singleCellData === 'object' && data_keys.length >= 1 && Object.entries(singleCellData).map(([key, value]) => {
                                return (
                                    <div key={key}>
                                        <div>
                                            {key && key.toLowerCase() !== 'value' && <span style={{ fontSize: '1.3rem', fontWeight: 'bold' }}>{key}:</span>}&nbsp;&nbsp;
                                            <span style={{ fontSize: '1rem', fontWeight: 'bold' }}>{value}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
            }
        </React.Fragment>

    );
};

export default SingleAnswer;