import React, { useState, useEffect, useRef } from 'react';
import { showPopup } from '../../utils';
import { TabWrapper, Tab, Indicator } from './tab.styled'


let _ripple_width = 200;

const TabSlider = (props) => {

    const { tabList, activeType, setActiveType, disableTab, numberOfTab, backgroundColor, hideShadow, upper_case, rippleEffect, overTabSelection, cutOutSelection } = props;
    const tabWrapperRef = useRef(null);
    const [elRefs, setElRefs] = useState([]);
    const arrLength = 10;
    // const [isHidden, setIsHidden] = useState("")
    const [listArray, setListArray] = useState(undefined)
    const [indicatorStyle, setIndicatorStyle] = useState({ left: 0, width: 0 });

    const [effect, seteffect] = useState({
        _width: 0,
        mode: "none"
    });



    useEffect(() => {
        if (tabList) {
            setListArray(tabList)
        }
    }, [tabList])



    useEffect(() => {
        setElRefs(elRefs => (
            Array(arrLength).fill().map((_, i) => elRefs[i] || React.createRef())
        ));

    }, [arrLength]);




    useEffect(() => {
        let current_index = (tabList && tabList.length > 0 && activeType && tabList.indexOf(activeType) > -1) ? tabList.indexOf(activeType) : undefined;
        if (elRefs[current_index]) {
            setTimeout(() => {
                setIndicatorProperty(elRefs[current_index])
            }, 500)
        }
    }, [elRefs[0], activeType])




    const onTabClick = (refName, tabName) => {

        if (tabName) {
            // setIsHidden(tabName)
            setIndicatorProperty(refName)
            setTimeout(() => {
                setActiveType(tabName)
            }, 200);
        }
    }



    const setIndicatorProperty = (refName) => {

        if (refName) {

            let wrapper = tabWrapperRef?.current?.getBoundingClientRect();
            let wrapperWidth = wrapper?.width;
            let currentEl = refName?.current?.getBoundingClientRect();
            let width = currentEl?.width;

            let left = currentEl?.x - wrapper?.x;
            if (width < 150) {
                _ripple_width = width * 2
            }
            setIndicatorStyle({
                width,
                left
            })
        }
    }



    return (
        <TabWrapper hideShadow={hideShadow} ref={tabWrapperRef} activeType={activeType} overTabSelection={overTabSelection} cutOutSelection={cutOutSelection} style={{ width: '100%' }} bgColor={backgroundColor}>
            {listArray && listArray.length > 0 && listArray.map((item, i) => {
                return (
                    <Tab
                        cutOutSelection={cutOutSelection}
                        key={"tabl_item" + i}
                        style={{
                            width: numberOfTab ? 100 / numberOfTab + "%" : undefined,
                            marginRight: numberOfTab ? '0px' : undefined,
                            textAlign: numberOfTab ? 'center' : undefined
                        }}
                        upper_case={upper_case}
                        mode={effect.mode}
                        _width={effect._width}
                        className="ripple"
                        ref={elRefs[i]}
                        active={activeType === item}
                        disable={disableTab && disableTab.indexOf(item) > -1}
                        onClick={() => {
                            if (disableTab && disableTab.indexOf(item) > -1) {
                                if (props.disable_click_message) {
                                    showPopup('', props.disable_click_message);
                                }
                            }
                            else {
                                rippleEffect && seteffect({
                                    _id: i,
                                    _width: _ripple_width,
                                    mode: "none"
                                })
                                onTabClick(elRefs[i], `${item}`)
                                rippleEffect && setTimeout(() => {
                                    seteffect({
                                        _id: i,
                                        _width: 0,
                                        mode: "active"
                                    })
                                }, 600)
                            }
                        }}
                    >
                        {item}
                        <span style={{ width: (effect._id === i) ? effect._width + 'px' : undefined, height: (effect._id === i) ? effect._width + 'px' : undefined }} className="ripple"></span>
                    </Tab>
                )
            })}
            <Indicator {...indicatorStyle} activeType={activeType} overTabSelection={overTabSelection} cutOutSelection={cutOutSelection} color="#1976d2" />
        </TabWrapper>
        // </div>
    )
}



export default TabSlider;