import React, { useState, useEffect } from 'react'
import { Wrapper, EditorInnerWrapper } from './db.connection.styled';
import TabContent from './tab.content';
import DisplayField from '../display-field'
import { connect } from 'react-redux';
import { get_db_info, clearTableNameAndTableData, delete_db_info } from '../../actions/connection'
import { showPopup } from '../../utils';
import * as enums from '../../utils/enums';
import { constants } from '../../utils/constants';
import ConnectionPopup from './connection.popup';
import Tab from '../tab';
import { dispatchHeaderInformation } from '../../actions/common';
import { withRouter } from 'react-router-dom';
import { getParamByName } from '../../utils'
import { saveInSession } from '../../utils/session.helper';
import ConfirmBox from '../confirm-box';


const TabType = [
    "RDBMS",
    "No-SQL",
    "HDFS",
    "Queue",
    "File System",
    "Text File",
    "Social Media"
];

const Index = (props) => {


    const { location, history } = props;
    const { search } = location;

    const [activeType, setActiveType] = useState('RDBMS')
    const [localDbInfo, setDbInfo] = useState(undefined);
    const [dbType, setDbType] = useState(undefined);
    const [displayFieldsShow, setDisplayFieldsShow] = useState(undefined);
    const [clickActionSaveInSession, setClickActionSaveInSession] = useState(undefined);

    useEffect(() => {
        let _page_info = {
            title: 'Select Your Data Source',

        }
        props.dispatchHeaderInformation(_page_info)
        props.get_db_info()
    }, [])


    useEffect(() => {
        const db_type = getParamByName(search, 'db_type');
        const from = getParamByName(search, 'from');
        if (from === 'homePage') {
            setClickActionSaveInSession(true)
        }
        setDbType(db_type)

        if (db_type === 'hive') {
            setActiveType("HDFS")
        }
        if (db_type === 'elasticsearch') {
            setActiveType("Text File")
        }
    }, [search])


    useEffect(() => {
        let filterd = props.db_infos && props.db_infos.length > 0 && props.db_infos.filter((data, i) => {
            if (data.db_type === dbType) {
                return data
            }
        })
        setDbInfo(filterd)
    }, [dbType, props.db_infos])



    const addNewConnection = (name) => {
        showPopup(undefined, undefined, enums.default.popupType.element, ConnectionPopup, {
            db_type: name,
        }, undefined, undefined, undefined, undefined, true);

    }
    

    const viewConnectionTables = (connectionInfo) => {
        if (clickActionSaveInSession) {
            saveInSession(constants.SESSION_KEYS.DATABASE_ID, connectionInfo.id)
            props.history.push('/')
            window.location.reload(false);
        }
    }

    const viewAction = (item) => {
        let path_to_redirect = `db_explorer?connection_id=${item.id}&sh_name=${item.connection_name}`;
        props.history.push(path_to_redirect)
        props.clearTableNameAndTableData()
    }

    const editAction = (item) => {
        showPopup(undefined, undefined, enums.default.popupType.element, ConnectionPopup, {
            item: item,
            db_type: item.db_type,
        }, undefined, undefined, undefined, undefined, true);
    }

    const deleteAction = (item) => {
        const db_id = item?.id;
        showPopup(undefined, 'Are you sure you want to Delete this ? ', enums.default.popupType.element, ConfirmBox, {
            func: {
                setYes: () => {
                    props.delete_db_info(db_id)
                    setTimeout(() => {
                        props.get_db_info()
                    }, 1000)
                }
            }
        })

    };
    
    const onClickDbType = (db_type) => {
        let path = `/connection?db_type=${db_type}`;
        props.history.push(path)
    }


    return (
        <Wrapper style={{ marginTop: '1rem' }}>

            {/* <TitleHeader title="Create Connection" /> */}

            <Tab tabList={TabType} activeType={activeType} overTabSelection={true} setActiveType={(value) => {
                setActiveType(value)
                setDbInfo(undefined)
                // setDbType(undefined)
            }} />

            <EditorInnerWrapper>
                <TabContent
                    activeType={activeType}
                    onClickDbType={onClickDbType}
                    setDbType={setDbType}
                    addNewConnection={addNewConnection}
                    db_infos={props.db_infos}
                    history={props.history}
                    dbType={dbType}
                />

                {localDbInfo && localDbInfo.length > 0 && (
                    <DisplayField
                        displayItem={localDbInfo}
                        addNewConnection={addNewConnection}
                        dbType={dbType}
                        activeType={activeType}
                        onItemClick={viewConnectionTables}
                        clickActionSaveInSession={clickActionSaveInSession}
                        viewAction={viewAction}
                        editAction={editAction}
                        deleteAction={deleteAction}
                    />
                )}

            </EditorInnerWrapper>
        </Wrapper>
    )
}



const mapStateToProps = (state) => ({
    db_infos: state.connectionReducer.db_infos
})

export default withRouter(connect(mapStateToProps, { delete_db_info, dispatchHeaderInformation, get_db_info, clearTableNameAndTableData })(Index))

