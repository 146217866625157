import styled from 'styled-components';

export const Wrapper = styled.div`

    width: 100%;
  

`;

export const FilesTableWrapper = styled.div`


    max-height: ${props => props.height ? props.height : 'calc(100vh - 450px)'} ;
    overflow-x: auto;
     &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }
   
   table{
        width: 100%;
        border: 0px;
        border-collapse: collapse;
   }

    td{
        border-top: 1px solid #eaeaea;
        padding: .7rem 5px;
        font-size: .9rem;
        text-align: left;
        
    }

    .file_name{
        margin-left: 10px;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;


export const FileThead = styled.thead`

    td{
        border: 0px;
        position: sticky;
        top: 0px;
        background-color: #fff;
        z-index: 1;
        .dropdown_icon{
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        .thead_row{
            display: flex;
            flex-direction: row;
        }
    }
    

`;


export const FileTbody = styled.tbody`

    .view_btn{
        cursor: pointer;
        padding-left: .5rem;
            &:hover{
                svg{
                fill: #008AC7;
            }
        }
    }

    tr{
        transition: all 0.2s;
        &:hover{
            svg{
                fill: #008AC7;
            }
            background: #eaeaea6e;
            /* box-shadow: -2px 0px 4px 0px #ccc; */
        }
    }
`;