import React, { useState, useEffect, useRef } from 'react'
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer, Legend, Label, LabelList, Text, LineChart, Line, XAxis, YAxis } from 'recharts';
import styled from 'styled-components';
import * as dataTypes from '../../../shared-with-fe/data.types';
import CustomToolTip from '../tooltip/tooltip.custom';
import { apply_styles, find_cell_format, formatValueByDataType } from '../../../utils';
import { constants } from '../../../utils/constants';
import RenderLegend from '../legend/index.new';
import { connect } from 'react-redux';

const Wrapper = styled.div`
   width: 100%;
.recharts-wrapper .recharts-legend-wrapper{
    right: 0px;
    left: auto !important;
    width: ${props => props.insights ? 'auto' : '10rem !important'};
}
`;

const LegendText = styled.span`
    ${props => props._style && apply_styles(props._style, constants.STYLED_COMPONENTS.ALL)}
`;


const GuageChart = (props) => {

    const {

        xDataKeys,
        data,
        yDataKeys,
        widthOfChart,
        colorForYData,
        heightOfChart,
        question,
        getDataFromReportingServer,
        isDonut,
        hide_label,
        hide_legend,
        hide_tooltip,
        insights,
        Alignments,
        chartStyle,
        show_data_label = true,
        show_percentage_in_data_labels = true,
        show_prcentage_in_legends,
        tooltipTheme,
        pivot_target_yac,
        yac_totals,
        isGauge

    } = props;


    const show_label_in_legend = 1;
    const heightOfWindow = window.innerHeight;
    const yDataKeyLength = yDataKeys.length;
    const legendStyle = chartStyle && chartStyle.legend;



    const dataLabelStyle = apply_styles(chartStyle?.data_label, constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT)

    const [localData, setLocalData] = useState(undefined)
    const [value_angle, set_value_angle] = useState(undefined)
    const [value_to_show, set_value_to_show] = useState(undefined)


    const convertValueToPercent = (data) => {
        let total = {};
        data && data.length > 0 && data.forEach((data_row) => {
            yDataKeys && yDataKeys.length > 0 && yDataKeys.forEach((key) => {
                if (typeof total[key] === 'undefined') {
                    total[key] = 0;
                }
                total[key] += data_row[key] || 0
            })
        })


        data && data.length > 0 && data.forEach((data_row) => {
            yDataKeys && yDataKeys.length > 0 && yDataKeys.forEach((key, i) => {
                if (data_row[key] > 0) {
                    if (show_percentage_in_data_labels) {
                        let _v = (data_row[key] / total[key]) * 100;
                        // data_row[key] = _v
                        data_row['p_value'] = _v.toFixed(1) + '%';
                    }
                }
            })
        })
    }



    useEffect(() => {

        // let clonData = props.data ? JSON.parse(JSON.stringify(props.data)) : [];
        // convertValueToPercent(clonData)
        // setLocalData(clonData)

        // case 1: sales is less than target L2

        // 1 x || 0;
        // 2 y

        // hide tooltip

        const sum_of_sales = yDataKeys && yac_totals?.[yDataKeys?.[0]]?.['sum']; // y1
        const sum_of_target = yDataKeys?.length > 1 && yac_totals?.[yDataKeys?.[1]]?.['sum']; //y2

        if (sum_of_target > sum_of_sales) {

            const temp_data = [];

            temp_data.push(

                { Service: 'g1', Discount: 0, p_value: '0' },
                { Service: 'g2', Discount: 25, p_value: '25%' },
                { Service: 'g3', Discount: 25, p_value: '50%' },
                { Service: 'g4', Discount: 25, p_value: '75%' },
                { Service: 'g5', Discount: 25, p_value: '100%' },
            )

            setLocalData(temp_data)
            set_value_angle(((sum_of_sales / sum_of_target) * 100));
            set_value_to_show(((sum_of_sales / sum_of_target) * 100))

        }
        else if (sum_of_sales > sum_of_target) {

            const trend_line_y_data = yDataKeys?.length > 1 ? yDataKeys?.[1] : undefined;
            const y_axis_avg = yDataKeys && yac_totals?.[yDataKeys?.[0]]?.['avg'];
            const avg_of_target_column = trend_line_y_data && yac_totals?.[trend_line_y_data]?.['avg'];


            let value_angle_in_100 = avg_of_target_column ? ((avg_of_target_column / y_axis_avg) * 100) : 0;

            set_value_to_show(value_angle_in_100 > 100 ? 100 : value_angle_in_100 < 0 ? 0 : value_angle_in_100);

            // value_angle = avg_of_target_column ? ((avg_of_target_column / y_axis_avg) * 180) : 0;

            // if (value_angle > 180) {
            //     value_angle = 180;
            // }
            // if (value_angle < 0) {
            //     value_angle = 0;
            // }
        }

    }, [props?.yDataKeys, props?.data, props?.xDataKeys])





    const RADIAN = Math.PI / 180;

    const d_data = [{ value: 1 }]


    const pie_cell_data = [
        {
            label: '0%',
            value: 1,
            color: '#ff5b63f7'
        },
        {
            label: '25%',
            value: 25,
            color: '#ff5b63f7'
        },
        {
            label: '50%',
            value: 25,
            color: '#ff5b63f7'
        },
        {
            label: '75%',
            value: 25,
            color: '#ffce07'
        },
        {
            label: '100%',
            value: 25,
            color: '#24c092'
        }
    ]



    const renderLabel = (entry) => {

        const { x, y, width, index, payload } = entry;

        const value = payload?.p_value
        const radius = 10;

        const entry_label = entry?.name;
        const aligment = Alignments && Alignments[yDataKeys && yDataKeys[0]];
        const d_type = show_percentage_in_data_labels ? dataTypes.percent : (aligment && aligment.type);

        const formatted_value = formatValueByDataType(value, d_type, undefined, undefined, undefined, "insights");

        const __v__ = (entry_label?.length > 20 ? (entry_label?.substring(0, 18) + "..") : entry_label) + " , " + formatted_value;

        let x_to_use = x;


        return (
            <g>
                <text
                    x={x_to_use}
                    y={y - radius}
                    textAnchor="end"
                    // dominantBaseline="middle"
                    fontSize="12px"
                >
                    {formatted_value}
                </text>
            </g>
        );
    }


    const renderColorfulLegendText = (value, entry) => {

        const { color } = entry;

        const { label, rect } = legendStyle;

        const p_v = show_prcentage_in_legends ? (entry && entry.payload && entry.payload.percent && entry.payload.percent * 100) : null;

        return <LegendText _style={label} title={value}>{value} {show_prcentage_in_legends ? formatValueByDataType(p_v, dataTypes.percent) : null}</LegendText>;
    };

    const yData = yDataKeys && yDataKeys?.[0];


    return (<>
        <Wrapper insights={insights} id='chart' style={{ position: 'relative', width: widthOfChart ? (widthOfChart - 30) : '93.1vw', textTransform: 'capitalize', margin: 'auto' }}>
            <ResponsiveContainer width='100%' height={heightOfChart}>
                {localData && localData.length > 0 && (
                    <PieChart>

                        {!hide_tooltip && (
                            <Tooltip
                                content={function (active) {
                                    {
                                        let _data = {};
                                        const _payload = active && active.payload;
                                        const _inner_payload = _payload && _payload[0] && _payload[0].payload;
                                        const payload_data = _inner_payload && _inner_payload.payload;
                                        let array_of_keys = [...xDataKeys];
                                        array_of_keys.push(yData)
                                        array_of_keys && array_of_keys.length > 0 && array_of_keys.map((key) => {
                                            if (payload_data && Object.keys(payload_data).length > 0 && Object.keys(payload_data).indexOf(key) > -1) {
                                                let _alignment_type = Alignments && Alignments[key] && Alignments[key].type;
                                                _data[key] = formatValueByDataType(payload_data[key], _alignment_type)
                                            }
                                        })

                                        if (!_data || (_data && Object.keys(_data)?.length === 0)) return null
                                        return <CustomToolTip data={_data} theme={tooltipTheme} />
                                    }
                                }}
                            />
                        )}

                        {!hide_legend && (
                            <Legend
                                content={<RenderLegend is_guage_chart={true} legendStyle={legendStyle} />}
                                layout={(legendStyle && legendStyle.position) ? legendStyle.position : "vertical"}
                                verticalAlign={(legendStyle && legendStyle.verticalAlign) ? legendStyle.verticalAlign : "middle"}
                                align={(legendStyle && legendStyle.horizontalAlign) ? legendStyle.horizontalAlign : "right"}
                                formatter={renderColorfulLegendText}
                            />
                        )}


                        {
                            colorForYData && yDataKeys && yDataKeys.length > 0 && localData && localData.length > 0 && yData && (
                                <>
                                    <Pie
                                        key={yData}
                                        animationBegin={false}
                                        startAngle={180}
                                        endAngle={0}
                                        innerRadius="50%"
                                        cy={"60%"}

                                        // labelLine={false}
                                        data={localData}
                                        // nameKey={xDataKeys && xDataKeys[xDataKeys.length - 1]}
                                        dataKey={yData}
                                        label={false}

                                    >

                                        {
                                            localData.map((entry, index) => {

                                                const all_keys = [...xDataKeys];
                                                all_keys.push(yData);
                                                const conditional_data = find_cell_format(props.tbl_formatter, all_keys, entry);
                                                const cell_color = conditional_data?.cell_color;
                                                let colorsLength = (colorForYData && colorForYData.length > 0) ? colorForYData.length : undefined;
                                                let colorForCell = colorForYData[index % colorsLength];
                                                let data_length = (localData && localData.length > 0) ? localData.length : undefined;

                                                return <Cell key={index} fill={cell_color || colorForCell} />
                                            })
                                        }

                                        {<LabelList
                                            dataKey={'p_value'}
                                            position="outsideEnd"
                                            offset={10}
                                            textBreakAll={true}
                                            // clockWise={true}
                                            style={{ fontSize: '.8rem', fontWeight: 'bold', fill: '#000' }}
                                        />}
                                    </Pie>


                                    <Pie
                                        startAngle={180}
                                        endAngle={0}
                                        data={localData}
                                        dataKey={yData}
                                        cy={"60%"}
                                        innerRadius='70%'
                                        animationBegin={false}
                                        label={false}
                                        legend={false}


                                    >
                                        {localData?.map((cell, index) => {
                                            return <Cell fill='#fff' />
                                        })}

                                        {<LabelList
                                            dataKey={'p_value'}
                                            position="end"
                                            offset={-6}
                                            style={{ fontSize: '.8rem', fontWeight: 'bold', fill: '#000' }}
                                        />}
                                    </Pie>


                                    <Pie
                                        animationBegin={1100}
                                        startAngle={180 - value_angle}
                                        endAngle={(180 - value_angle) + 0.1}
                                        blendStroke
                                        outerRadius="60%"
                                        innerRadius={10}
                                        cy={"59%"}
                                        data={d_data}
                                        isAnimationActive={show_data_label ? false : true}
                                    >
                                        <Cell fill='red' />
                                    </Pie>


                                    <Pie
                                        animationBegin={1100}
                                        startAngle={180 - value_angle}
                                        endAngle={538 - value_angle}
                                        outerRadius={7}
                                        innerRadius={1.5}
                                        cy={"59%"}
                                        data={d_data}
                                        isAnimationActive={show_data_label ? false : true}
                                        label={false}
                                    >
                                        <Cell fill='black' />

                                        <Label
                                            style={{ fontSize: '1rem', textAlign: 'center', fontWeight: 'bold', fill: '#000' }}
                                            width={120}
                                            position="outside" offset={10}>
                                            {`${parseFloat(value_to_show).toFixed(1)} %`}
                                        </Label>
                                    </Pie>

                                </>)

                        }

                    </PieChart>
                )}
            </ResponsiveContainer>

        </Wrapper >
    </>)
}



const mapStateToProps = (state, props) => {

    const table_format_setting_cache = state.tableFormatReducer.table_format_setting_cache;

    const tbl_formatter = table_format_setting_cache && table_format_setting_cache[props.id];

    return { tbl_formatter }
}

export default connect(mapStateToProps, {})(GuageChart);
