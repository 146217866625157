import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import RippleEffect from '../../hoc/ripple-effect'
import { connect } from 'react-redux';
import { getTargetTableData, getCollectionsOfMongo, pushSourceMappingDataToStore } from '../../../actions/etl'
import { DivRow } from '../../styled-hoc';
import SelectOption from '../../select-option';
import OverLay from '../../helper/overlay';
import ExpandSvg from '../../svg/expand.ract';
import MappingTable from './mapping.table';
import CloseSvg from '../../svg/close'


const Wrapper = styled.div`

    width: 100%;

    .table_mapping_scroller{
        max-height: 27rem;
        height: 27rem;
        overflow-y: auto;
        &::-webkit-scrollbar {
            background-color: transparent;
            width:16px;
        }
        
        &::-webkit-scrollbar-track {
            background-color:#fff;
        }

        &::-webkit-scrollbar-track:hover {
            background-color:#f4f4f4;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color:#babac0;
            border-radius:16px;
            border:5px solid #fff;
        }
        
        &::-webkit-scrollbar-thumb:hover {
            background-color:#a0a0a5;
            border:4px solid #f4f4f4;
        }
        
        &::-webkit-scrollbar-button {
            display:none;
        }

    }

    .source_tbl_main{
        width: 100%;
        margin-bottom: 1rem;

        .dropdown_icon {
            cursor: pointer;
            line-height: 2rem;
            vertical-align: middle;
            position: absolute;
            top: .5rem;
            right: .5rem;
            svg{
                display: block;
            }
         }


        table{
            width: 100%;
            border: 0px;
            border-collapse: collapse;
            table-layout: fixed ;
            border: 1px solid #ccc;
            border-radius: 3px;
            thead{
                th{
                    background: #308BC8;
                    color: #fff;
                    padding: .6rem .9rem;
                    font-size: .9rem;
                    border: 1px solid #ccc;
                    position: sticky;
                    z-index: 3;
                    top: -1px; /* Don't forget this, required for the stickiness */
                }
            }
            td{
                padding: 0rem .9rem;
                font-size: .9rem;
                font-weight: 500;
                position: relative;
                border: 1px solid #ccc;
            }
        }
    }

    .small_data_popup{
        width: 30rem;
        background: #fff;
        position: absolute;
        border-radius: 3px;
        box-shadow: 2px 2px 8px rgb(0 0 0 / 50%);
        bottom: 50%;
        left: -7%;
        margin-bottom: 1rem;
        padding: 1rem;
        box-sizing: border-box;
        z-index: 5;

        h2{
            font-weight: 600;
            text-align: center;
            margin-top: 0px;
            text-align: center;
            font-size: 1.4rem;
            text-transform: capitalize;

        }

        p{
            text-transform: capitalize;
            margin-top: 0px;
            text-align: left;
            font-size: 1rem;
            color: #333;
            font-weight: 500;
            font-weight: 400;
        }
        .tag{
            padding: .1rem .3rem;
            background: #eaeaea;
            border-radius: 3px;
            font-size: .9rem;
        }
    }

    .new_value{
        position: absolute;
        right: 2.8rem;
        top: .7rem;
        border: 1px solid #cccccc69;
        font-weight: 400;
        background: #eaeaea;
        color: #e91e638f;
        padding: .1rem .1rem;
        border-radius: 3px;
        text-transform: capitalize;
        font-size: .7rem;
        cursor: default;
    }

    .expand_icon{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        margin-bottom: .3rem;
    }
`;



const PopupWrapper = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 9;
    padding: 2rem;
    padding-top: .5rem;
    box-sizing: border-box;

    .close_expander{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: .5rem;

        h1{
            margin: .5rem 0px;
            font-weight: bold;
            letter-spacing: normal;
            color: #222;
            text-transform: capitalize;
            font-size: 1.5rem;
            text-align: center;
        }
    }
    .scroll_wrapper{
        width: 100%;
        max-height: calc(100vh - 200px);
        height: calc(100vh - 200px);
        overflow: auto;
        &::-webkit-scrollbar {
            background-color: transparent;
            width:16px;
        }
        
        &::-webkit-scrollbar-track {
            background-color:#fff;
        }

        &::-webkit-scrollbar-track:hover {
            background-color:#f4f4f4;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color:#babac0;
            border-radius:16px;
            border:5px solid #fff;
        }
        
        &::-webkit-scrollbar-thumb:hover {
            background-color:#a0a0a5;
            border:4px solid #f4f4f4;
        }
        
        &::-webkit-scrollbar-button {
            display:none;
        }
    }

`;


const Index = (props) => {

    const {
        tableHead,
        tableData,
        targetColumnNames,
        targetTableNames,
        sourceColumns,
        autoSearchOnChange,
        formData,
        db_infos,
        getTargetTableNames,
        setFormData,
        setTransformationType,
        transformationType,
        setPrimaryKeyValue,
        primaryKeyValue,
        localError,
        ignoreError,
        clickOnApplyButton,
        clickOnIgnoreButton,
        errorPopupValue,
        setTableData,
        setSourceColumns,
        config_type,
        collectionsOfMongo,
        collectionsColumns,
        is_edit_mode,
        sortMappingTable,
        sortOrder,
        target_schema,
        properties
    } = props;


    const [isErrorOpen, setIsErrorOpen] = useState(undefined);
    const [isPopupOpen, setIsPopupOpen] = useState(undefined);


    const click_on_expander = () => {
        setIsPopupOpen(true);
    }



    let _properties = undefined;

    if (collectionsColumns && collectionsColumns.properties) {
        _properties = collectionsColumns.properties
    }

    if (is_edit_mode && properties) {
        _properties = properties;
    }


    useEffect(() => {
        if ((formData && formData['e_type'] === 'directory')) {
            if (_properties) {
                let temp_table_data = [];
                let temp_column = [];
                _properties && _properties.length > 0 && _properties.forEach((column) => {
                    temp_column.push(column)
                    if (tableData && tableData.length > 0) {
                        tableData.map((tbl_item) => {
                            if (tbl_item.source_column === column) {
                                let temp_obj = Object.assign({}, tbl_item)
                                temp_table_data.push(temp_obj)
                            }
                        })
                    } else {
                        temp_table_data.push({
                            source_column: column,
                            target_table: undefined,
                            target_column: undefined
                        })
                    }
                })

                setSourceColumns(temp_column)
                temp_table_data.push({
                    id: undefined,
                    source_column: undefined,
                })
                props.pushSourceMappingDataToStore(temp_table_data)
            }
        }
    }, [collectionsColumns])


    const wrapperRef = useRef(null);


    return (
        <React.Fragment>

            {isPopupOpen && (
                <PopupWrapper>
                    <div className="close_expander">
                        <h1>Source & Target Mapping</h1>

                        <div style={{ position: 'absolute', right: '2rem' }}>
                            <RippleEffect
                                zIndex={1}
                                title="close"
                                icon={<CloseSvg size="1rem" height="1rem" />}
                                Click={(e) => {
                                    setIsPopupOpen(false)
                                }}
                            />
                        </div>
                    </div>

                    <Wrapper>
                        <div className="scroll_wrapper">
                            <MappingTable
                                {...props}
                                isErrorOpen={isErrorOpen}
                                setIsErrorOpen={setIsErrorOpen}
                                sortMappingTable={sortMappingTable}
                                sortOrder={sortOrder}

                            />
                        </div>
                    </Wrapper>
                </PopupWrapper>
            )}

            <Wrapper>

                {
                    isErrorOpen !== undefined &&
                    <OverLay
                        zIndex={"1"}
                        onClick={() => {
                            setIsErrorOpen(undefined);
                        }} />
                }



                <div className="expand_icon">
                    <RippleEffect
                        title={"Expand Source & Target Mapping"}
                        icon={<ExpandSvg size="2rem" height="2rem" color="#222" />}
                        Click={() => click_on_expander()}
                    />
                </div>


                <div ref={wrapperRef} style={{ width: '100%', marginBottom: '5rem' }} className="table_mapping_scroller">
                    <MappingTable
                        {...props}
                        isErrorOpen={isErrorOpen}
                        setIsErrorOpen={setIsErrorOpen}
                        is_edit_mode={is_edit_mode}
                    />
                    {formData && formData['e_type'] === 'directory' &&
                        (!_properties) ? <p style={{ textAlign: 'center', marginTop: '2rem' }}>Please wait while we are fetching the data...</p> : null
                    }

                    {formData && formData['e_type'] === 'dbs' &&
                        <React.Fragment>
                            {!sourceColumns ? <p style={{ textAlign: 'center', marginTop: '2rem' }}>Please wait while we are fetching the data...</p> : null}
                            {sourceColumns && sourceColumns.length === 0 ? <p style={{ textAlign: 'center', marginTop: '2rem' }}>No Data Found</p> : null}
                        </React.Fragment>
                    }

                </div>






                {transformationType && transformationType === 'Upsert' && tableData && tableData.length > 0 && tableData[0].target_table &&
                    <div className="flex_row" style={{ marginTop: '1.5rem' }}>
                        <DivRow w="45%">
                            <SelectOption
                                selections={
                                    tableData[0].target_table && targetColumnNames && targetColumnNames[tableData[0].target_table] ? targetColumnNames[tableData[0].target_table] : []
                                }
                                label="Choose Primary Key"
                                onChangeFunc={(value) => {
                                    setPrimaryKeyValue(value);
                                }}
                                activeValue={primaryKeyValue}
                                width="90%"
                                lineHeight="2.857rem"
                                border='1px solid #bbd1d9'
                                color='#7c7c7c'
                            />
                        </DivRow>

                    </div>
                }
            </Wrapper>
        </React.Fragment>
    )
}






const mapStateToProps = (state) => ({
    collectionsOfMongo: state.EtlReducer.collectionsOfMongo,

})

export default connect(mapStateToProps, { getTargetTableData, getCollectionsOfMongo, pushSourceMappingDataToStore })(Index);
