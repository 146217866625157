import { dispatchAction } from './helper';
import * as actionTypes from '../action-types/polus_chat'



export const saveInnerChat = (id, data, hideIsWaiting = false) => async dispatch => {
    dispatchAction(dispatch, actionTypes.SAVE_SINGLE_CHAT_RESPONSE, {
        data: data,
        id: id,
        hideIsWaiting: hideIsWaiting
    }, undefined, 0);
};


export const dispatchActionCommand = (id, data) => async dispatch => {

    dispatchAction(dispatch, actionTypes.OTHER_ACTION_COMAND, {
        ...data,
        id: id
    }, undefined, 0);
}

export const toggleIsWaiting = (value) => async dispatch => {
    dispatchAction(dispatch, actionTypes.SAVE_IS_WAITTING, value, undefined, 0);
}