import React from 'react';
import * as _ from 'lodash';

import { Wrapper, HeadingWrapper, InformationWrapper, Detail } from './detail.wrapper.styled';
import CloseSvg from '../svg/close'


const DetailWrapper = props => {

    const { data, close, popupKey, option } = props;
    const { detail } = data;


    return (
        <Wrapper>
            <HeadingWrapper>
                Details
            </HeadingWrapper>

            <div
                className="dismiss"
                title="close"
                onClick={() => {
                    close(option, popupKey)
                }}
            >
                <CloseSvg color="#bbd1d9" size="1rem" height="1rem" />
            </div>
            <InformationWrapper>
                {
                    detail && Object.keys(detail).length > 0 && Object.keys(detail).map(key => {
                        const heading = typeof key === 'string' ? _.startCase(_.lowerCase(key)).trim() : key;
                        const data = typeof detail[key] === 'string' ? _.startCase(_.lowerCase(detail[key])).trim() : detail[key];

                        return (
                            <Detail>
                                <div
                                    style={{
                                        
                                    }}
                                >
                                    {heading}
                                </div>
                                <div>{data}</div>
                            </Detail>
                        )
                    })
                }
            </InformationWrapper>
        </Wrapper>
    )
};

export default DetailWrapper;