import React from "react";

const CdataProfile = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 180 180"
    >
      <path
        d="M0 0 C23.17449696 0.35161306 43.94119145 7.28333487 62 22 C62.83917969 22.67546875 63.67835938 23.3509375 64.54296875 24.046875 C84.77468618 41.23599307 95.81074785 65.80906877 98 92 C98 93.98 98 95.96 98 98 C94.04 98 90.08 98 86 98 C86.04640625 99.71703125 86.04640625 99.71703125 86.09375 101.46875 C86.06929485 115.38373295 80.93641161 128.17270347 74 140 C73.46632812 140.91136719 72.93265625 141.82273438 72.3828125 142.76171875 C59.91782577 162.01733259 40.23876309 174.18105866 18 179 C-4.68742802 182.87543254 -28.01655356 178.23616449 -47 165 C-51.26081048 161.60202032 -55.14682197 157.84770476 -59 154 C-60.11375 152.90365234 -60.11375 152.90365234 -61.25 151.78515625 C-76.25512427 136.0473599 -82.4406691 114.75768197 -82.36645508 93.41796875 C-81.80655052 70.96867296 -72.03766971 49.72009209 -56 34 C-41.74285267 21.25196576 -22.91232596 11.87190814 -3.4375 11.75 C-2.303125 11.8325 -1.16875 11.915 0 12 C0 8.04 0 4.08 0 0 Z M-54.1875 41.6875 C-68.57756586 56.22674517 -75.74584921 75.57173366 -76.3125 95.8125 C-75.90532299 117.60844588 -67.58642689 136.20925487 -52.625 151.875 C-47.52032965 156.7732053 -42.16868838 160.56393235 -36 164 C-35.20078125 164.46019531 -34.4015625 164.92039063 -33.578125 165.39453125 C-15.11590765 175.37981547 6.09411666 176.33177668 25.96777344 170.4140625 C45.90579144 163.87899135 62.40297262 149.64019647 72 131 C76.9296146 120.40931283 80 109.729569 80 98 C53.6 98 27.2 98 0 98 C0 71.6 0 45.2 0 18 C-21.14273162 18 -39.19301497 27.19689341 -54.1875 41.6875 Z "
        fill="#D6D9DE"
        transform="translate(82,0)"
      />
      <path
        d="M0 0 C23.17449696 0.35161306 43.94119145 7.28333487 62 22 C62.83917969 22.67546875 63.67835938 23.3509375 64.54296875 24.046875 C84.77468618 41.23599307 95.81074785 65.80906877 98 92 C98 93.98 98 95.96 98 98 C94.04 98 90.08 98 86 98 C86.04640625 99.71703125 86.04640625 99.71703125 86.09375 101.46875 C86.06929485 115.38373295 80.93641161 128.17270347 74 140 C73.46632812 140.91136719 72.93265625 141.82273438 72.3828125 142.76171875 C59.91782577 162.01733259 40.23876309 174.18105866 18 179 C-4.68742802 182.87543254 -28.01655356 178.23616449 -47 165 C-51.26081048 161.60202032 -55.14682197 157.84770476 -59 154 C-60.11375 152.90365234 -60.11375 152.90365234 -61.25 151.78515625 C-76.25512427 136.0473599 -82.4406691 114.75768197 -82.36645508 93.41796875 C-81.80655052 70.96867296 -72.03766971 49.72009209 -56 34 C-41.74285267 21.25196576 -22.91232596 11.87190814 -3.4375 11.75 C-2.303125 11.8325 -1.16875 11.915 0 12 C0 8.04 0 4.08 0 0 Z M4 5 C4 34.37 4 63.74 4 94 C33.37 94 62.74 94 93 94 C93 72.62997093 85.64904395 54.31109153 72 38 C71.37609375 37.21367188 70.7521875 36.42734375 70.109375 35.6171875 C58.49133827 21.60720203 42.49492582 12.60121563 25.25 7.5 C24.39921875 7.24444336 23.5484375 6.98888672 22.671875 6.72558594 C15.97650021 4.88900744 11.3429888 5 4 5 Z M-54.1875 41.6875 C-68.57756586 56.22674517 -75.74584921 75.57173366 -76.3125 95.8125 C-75.90532299 117.60844588 -67.58642689 136.20925487 -52.625 151.875 C-47.52032965 156.7732053 -42.16868838 160.56393235 -36 164 C-35.20078125 164.46019531 -34.4015625 164.92039063 -33.578125 165.39453125 C-15.11590765 175.37981547 6.09411666 176.33177668 25.96777344 170.4140625 C45.90579144 163.87899135 62.40297262 149.64019647 72 131 C76.9296146 120.40931283 80 109.729569 80 98 C53.6 98 27.2 98 0 98 C0 71.6 0 45.2 0 18 C-21.14273162 18 -39.19301497 27.19689341 -54.1875 41.6875 Z "
        fill="#07315B"
        transform="translate(82,0)"
      />
    </svg>
  );
};

export default CdataProfile;
