import * as actions from '../../action-types/report';
import * as commonActions from '../../action-types/common';
import { constants } from '../../utils/constants';

const initialState = {
    type: actions.REPORT_INIT,
    recordsCount: 0,
    metaColumnsData: undefined,
    dataFromServer: {},
    hints: [],
    reports: undefined
};


const v2_reportReducer = (state, action) => {
	if (!state || typeof action.type === 'undefined') {
		return initialState;
    }

    switch (action.type) {
        case actions.DATA_FROM_RS_SUCCESS:
            const data = action.data;

            const tempDataFromServerCache = state.dataFromServer;
            const id = data.reportId;
            const limit = constants.LIMIT_OF_DATA_IN_REDUX_STATE;

            tempDataFromServerCache[id] = {
                last_updated_at: new Date(),
                data: action.data,
                index: Object.keys(tempDataFromServerCache).length
            };

            return {
                ...state,
                type: action.type,
                dataFromServer: tempDataFromServerCache
            };

        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
			return {
                type: state.type,
                metaColumnsData: state.metaColumnsData,
                recordsCount: state.recordsCount,
                dataFromServer: state.dataFromServer,
                hints: state.hints,
                reports: state.reports
            };
        }
};

export default v2_reportReducer;