import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils';

let selected = getSelectedTheme();

const { buttonStyle } = themes[selected]


const ripple = keyframes`
    0% {
        transform: scale(0, 0);
        opacity: 1;
    }
    20% {
        transform: scale(25, 25);
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: scale(40, 40);
    }
`;



export const CheckBox = styled.div`
    width: 1.3rem;
    height: 1.3rem;
    border: 2px solid #fff;
    border-radius: 50%;
    position: relative;
    display: inline-block;
    margin-right: 1rem;
    transition: all .2s;
    &:before {
        transition: all .2s;
        content: '';
        width: .4rem;
        height: .8rem;
        border: 1px solid;
        border-width: 0 2px 3px 0;
        transform: rotate(45deg);
        position: absolute;
        right: .3rem;
        top: 0px;
     }

`;

export const ButtonStyled = styled.button`
        display: ${props => props.display || 'inline-block'};
        padding: ${props => props.padding || "2px 1.286rem"};
        box-sizing: border-box;
        height: ${props => props.height || '2.125rem'};
        width: ${props => props.width || undefined};
        background: ${props => props.disabled ? '#d9d9d9' : props.bgColor || 'linear-gradient(to right, #3E9EFF, #737DFF);'};
        /* opacity: ${props => props.disabled ? '0.3' : undefined}; */
        opacity: ${props => props.disabled ? '0.9' : '0.8'};
        box-shadow:  ${buttonStyle.shadow.btnShadow};
        border-radius: ${props => props.borderRadius || '4px'};
        border: 0px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: center;
        font-size: 0.875rem;
        color: ${props => props.disabled ? 'gray' : props.primary ? buttonStyle.color.textColor :   props.color ? props.color : '#000000'};
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        cursor: ${props => props.disabled ? undefined : 'pointer'};
        white-space: nowrap;
        margin-right: ${props => props.mR ? props.mR : "0px"};
        font-family: "DM Sans", sans-serif;
        position: relative;
        overflow: hidden;
        transition: all 0.2s;
        text-transform: uppercase;
        font-weight: 500;
        


        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 5px;
            height: 5px;
            background: rgb(255 255 255 / 32%);
            opacity: 0;
            border-radius: 50%;
            transform: scale(1, 1) translate(-50%);
            transform-origin: 50% 50%;
        }

        &:active{
            box-shadow: ${buttonStyle.shadow.activeShadow};
        }

        &:hover{
            background: ${props => props.disabled ? '#d9d9d9' : props.hoverBg ? props.hoverBg : props.primary ? 'linear-gradient(to right, #2A8BEF, #5966FF)' : '#d6d6d8'};    
            box-shadow: ${buttonStyle.shadow.hoverShadow};
            cursor: ${props => props.disabled ? "not-allowed" : undefined};
        }
        &:focus{
            background-color: ${props => props.primary ? buttonStyle.color.focusBg : undefined};
            box-shadow: ${buttonStyle.shadow.focusShadow};
            &:after{
                animation: ${ripple} 3s ease-out;
            }
            border: 0px;
            outline: none;
        }

`;


const style_w = {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}
const style_n = {
    display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center'
}
export const Button = (props) => {
    // const [heightIncrementor, setHeightIncrementor] = useState(0);

    const { wrap } = props;
    return (
        <ButtonStyled
            title={props.title}
            {...props}
            disabled={props.disabled}
            onClick={(event) => {
                // setHeightIncrementor(1);
                if (props.onClick) {
                    setTimeout(() => {
                        props.onClick(event);
                        // setHeightIncrementor(0);
                    }, 400);
                }
            }}
        >
            <div style={wrap ? style_w : style_n}>
                {props.complited ? <CheckBox complited={props.complited} /> : null}
                {props.children}
            </div>
        </ButtonStyled>
    )
}