import React from 'react';

const Home = (props) => {

    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}

            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            space="preserve"
        >
            <g>
                <g>
                    <path d="M503.4,216.2L273.7,6.9c-10.1-9.2-25.3-9.2-35.4,0L8.6,216.2c-8.1,7.4-10.7,18.7-6.8,28.9c3.9,10.2,13.6,16.8,24.5,16.8
			H63v209.7c0,8.3,6.7,15.1,15.1,15.1H204c8.3,0,15.1-6.7,15.1-15.1V344.2h74v127.3c0,8.3,6.7,15.1,15.1,15.1H434
			c8.3,0,15.1-6.7,15.1-15.1V261.8h36.7c10.9,0,20.5-6.6,24.5-16.8C514.1,234.9,511.5,223.5,503.4,216.2z"/>
                </g>
            </g>
        </svg>

    )

}

export default Home;