// App.js
import React, { useState } from 'react';
import styled from 'styled-components';
import Close from '../../svg/close';
import TypingEffect from '../../chatPolusAi/typing.effect';


const Container = styled.div`
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;

  max-width: 70vw;
  min-width: 70vw;
  min-height: ${props => props?.height ? props.height : '70vh'};
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }
`;

const Title = styled.h1`
  text-align: center;
  color: #333;
`;

const AnalysisItem = styled.div`
  margin-bottom: 20px;
  background: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const AnalysisText = styled.p`
  margin: 0;
  color: #555;
  font-size: 16px;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: #555;
`;


const Heading = styled.h2`
  color: #202124;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 16px;
`;

const List = styled.ul`
  margin-left: 0px;
  margin-bottom: 24px;
  list-style-type: ${props => props.is_child ? "circle" : 'disc'};
  margin-top: ${props => props.is_child ? "10px" : undefined};



`;

const ListItem = styled.li`
  color: #222;
  line-height: ${props => props.is_child ? "1.3rem" : '1.7rem'};
  font-size: ${props => props.is_child ? ".9567rem" : '1rem'};
  margin-bottom: 8px;
  /* text-transform: capitalize; */
`;


// <h2 style="font-size: 20px; color: #2C3E50;">
//     📉 4. Decline in Active Subscribers
// </h2>

//  <ul style="font-size: 1rem; color: #333; list-style-type: disc; padding-left: 50px;">
//     <li style="margin-bottom: 10px; font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
//       The percentage of active subscribers in August 2023 decreased by 10.35% compared to August 2022.
//     </li>

//     <li style="margin-bottom: 10px; font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
//       This decline may impact revenue streams for service providers in the region.
//     </li>
// </ul>

const DataStory = (props) => {

  const { close, popupKey, option, data } = props;

  const id = data?.id;

  const HTML1 = `
  <div>
        <h2 style="color: #2c3e50">📊 Data Story: EBIT declination in Hiking Backpack </h2>
        <div style="marginBottom: '20px'">
          <p style="font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
            In August 2023, Hiking Backpack encountered significant drop in Earnings Before Interest and Taxes (EBIT).
          </p>
          <p>
            The most alarming trends include a surge in overall costs, increased depreciation, a drop in active subscribers, and substantial declines in key financial indicators such as Return on Capital Employed (ROCE) and operating margins.
          </p>
        </div>
        
           <h1 style="font-size: 24px; color: #000; border-bottom: 2px solid #000; padding-bottom: 10px;">
        📈 Detailed Analysis of Key Metrics
    </h1>

    <h2 style="font-size: 20px; color: #2C3E50;">
        📊 1. Earnings Before Interest and Taxes (EBIT)
    </h2>
    
     <ul style="font-size: 1rem; color: #333; list-style-type: disc; padding-left: 50px;">
        <li style="margin-bottom: 10px; font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
          The continuous decline in EBIT underscores the worsening profitability of businesses in Hiking Backpack. In August 2023, the decrease was notably sharp.
        </li>
    </ul>

    <h2 style="font-size: 20px; color: #2C3E50;">
        📈 2. Rising Overall Costs
    </h2>
    
     <ul style="font-size: 1rem; color: #333; list-style-type: disc; padding-left: 50px;">
        <li style="margin-bottom: 10px; font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
          In August 2023, the overall costs in Hiking Backpack increased by 30.10% compared to August 2022
        </li>

        <li style="margin-bottom: 10px; font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
          When comparing with the previous month, July 2023, costs rose by 0.61%.
        </li>
    </ul>

    <h2 style="font-size: 20px; color: #2C3E50;">
        💰 3. Increased Depreciation
    </h2>
    
     <ul style="font-size: 1rem; color: #333; list-style-type: disc; padding-left: 50px;">
        <li style="margin-bottom: 10px; font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
          The depreciation in Hiking Backpack rose by 15.86% when comparing August 2023 with August 2022.
        </li>

        <li style="margin-bottom: 10px; font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
          This increase in depreciation indicates potential challenges for asset management and operational costs.
        </li>
    </ul>

   


     <h2 style="font-size: 20px; color: #2C3E50;">
        📉 4. ROCE Growth Decline
    </h2>
    
     <ul style="font-size: 1rem; color: #333; list-style-type: disc; padding-left: 50px;">
        <li style="margin-bottom: 10px; font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
          The Return on Capital Employed (ROCE) witnessed a dramatic decrease of 316.66% in August 2023 compared to August 2022.
        </li>

        <li style="margin-bottom: 10px; font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
          This decline signals severe financial strain on capital efficiency.
        </li>
    </ul>


    <h2 style="font-size: 20px; color: #2C3E50;">
       📉  5. Operating Margin Decrease
    </h2>

    <ul style="font-size: 1rem; color: #333; list-style-type: disc; padding-left: 50px;">
        <li style="margin-bottom: 10px; font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
          The operating margin also saw a significant drop of 75.38% in August 2023 relative to August 2022.
        </li>

        <li style="margin-bottom: 10px; font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
          This decline suggests critical challenges in maintaining profitability amidst rising costs.
        </li>
    </ul>


      </div>
  `;



  const HTML2 = `
  <div>
        <h2 style="color: #2c3e50">📊 Data Story: Significant Rise in content cost per subscriber </h2>
        <div style="marginBottom: '20px'">
          <p style="font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
            In FY'23, content cost per subscriber is increased as compared to last year and The most alarming trends include a decline in subscriber and  significant increased in content cost.
          </p>
        </div>
        
           <h1 style="font-size: 24px; color: #000; border-bottom: 2px solid #000; padding-bottom: 10px;">
        📈 Detailed Analysis of Key Metrics
    </h1>

   

    <h2 style="font-size: 20px; color: #2C3E50;">
        📈 1. Total Active Subscriber
    </h2>
    
     <ul style="font-size: 1rem; color: #333; list-style-type: disc; padding-left: 50px;">
        <li style="margin-bottom: 10px; font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
         In FY'23, the total active subscriber decreased by 5.79% as compared to FY'22
        </li>
    </ul>


    <h2 style="font-size: 20px; color: #2C3E50;">
        💰 2. Total Content Cost
    </h2>
    
     <ul style="font-size: 1rem; color: #333; list-style-type: disc; padding-left: 50px;">
        <li style="margin-bottom: 10px; font-size: 1.1rem;color:#333;line-height:1.7rem;font-weight:500">
          In FY'23, the Total content cost increased by 1% as compared to FY'22
        </li>

    </ul>
      
    <strong>
    <p>Hence, a significant rise in overall content cost and drop in net active subscriber leads to the increase in content cost per subscriber</p>
    </strong>


      </div>
  `;


  const HTML = id === 'rpt_m28okegy' ? HTML2 : HTML1;
  return (
    <Container height={id === "rpt_m28okegy" ? '50vh' : undefined}>
      <CloseButton onClick={() => {
        close(option, popupKey)

      }}>
        <Close size="1rem" height='1rem' />

      </CloseButton>
      <TypingEffect htmlContent={HTML} typingSpeed={3} />


    </Container >
  );
};

export default DataStory;