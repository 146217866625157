import { CHART_TYPES } from '../shared-with-fe/constants';

export const chartDisableOptions = {
    [CHART_TYPES.bar_chart]: {
        minNumberOfData: 1,
        isTrendFilter: 0
    },
    [CHART_TYPES.horizontal_bar_chart]: {
        minNumberOfData: 1,
        isTrendFilter: 0
    },
    [CHART_TYPES.horizontal_waterfall_chart]: {
        minNumberOfData: 1,
        isTrendFilter: 0
    },
    [CHART_TYPES.waterfall_chart]: {
        minNumberOfData: 1,
        isTrendFilter: 0
    },
    [CHART_TYPES.stacked_bar_chart]: {
        minNumberOfData: 1,
        isTrendFilter: 0
    },
    [CHART_TYPES.horizontal_stack_bar_chart]: {
        minNumberOfData: 1,
        isTrendFilter: 0
    },
    [CHART_TYPES.horizontal_stack_bar_chart_100]: {
        minNumberOfData: 1,
        isTrendFilter: 0
    },
    [CHART_TYPES.line_chart]: {
        minNumberOfData: 1,
        isTrendFilter: 0
    },
    [CHART_TYPES.ogive_chart]: {
        minNumberOfData: 1,
        isTrendFilter: 0
    },
    [CHART_TYPES.line_bar_chart]: {
        minNumberOfData: 1,
        isTrendFilter: 0
    },
    [CHART_TYPES.dash_line_chart]: {
        minNumberOfData: 1,
        isTrendFilter: 0
    },
    [CHART_TYPES.area_chart]: {
        minNumberOfData: 1,
        isTrendFilter: 0
    },
    [CHART_TYPES.pie_chart]: {
        minNumberOfData: 1,
        isTrendFilter: 0
    },
    [CHART_TYPES.donut_chart]: {
        minNumberOfData: 1,
        isTrendFilter: 0
    },
    [CHART_TYPES.tree_map]: {
        minNumberOfData: 1,
        isTrendFilter: 0
    },
    "tree_map": {
        minNumberOfData: 1,
        isTrendFilter: 0
    },
    [CHART_TYPES.gauge_chart]: {
        minNumberOfData: 0,
        isTrendFilter: 0
    },
    [CHART_TYPES.radial_bar_chart]: {
        minNumberOfData: 1,
        isTrendFilter: 0
    },
    [CHART_TYPES.scatter_chart]: {
        minNumberOfData: 1,
        isTrendFilter: 0
    },
    [CHART_TYPES.bubble_chart]: {
        minNumberOfData: 1,
        isTrendFilter: 0
    },
    [CHART_TYPES.pareto_chart]: {
        minNumberOfData: 1, 
        isTrendFilter: 0,
        yac: 1,
    },
    [CHART_TYPES.radar_chart]: {
        minNumberOfData: 1,
        maxNumberOfData: 14,
        isTrendFilter: 0
    },
    // [CHART_TYPES.scatter_chart]: {
    //     minNumberOfData: 2,
    //     maxNumberOfData: 50,
    //     isTrendFilter: 0
    // },
    [CHART_TYPES.stacked_bar_chart_100]: {
        minNumberOfData: 1,
        isTrendFilter: 0
    },
    [CHART_TYPES.area_chart_100]: {
        minNumberOfData: 1,
        isTrendFilter: 0
    },
    [CHART_TYPES.gantt_chart]: {
        minNumberOfData: 1,
        isTrendFilter: 0
    },
    'table': {
        isTrendFilter: 0
    },
    [CHART_TYPES.funnel_chart]: {
        minNumberOfData: 1,
        isTrendFilter: 0,
        yac: 1,
        xac: 1,
    },
}


export const CHART_CONSTANTS = {
    MIN_Y_AXIS: 1,
    MAX_Y_AXIS: 25,
    MIN_X_AXIS: 1,
    MAX_X_AXIS: 10,
    MAX_CHART_DATA_LIMIT: 500,
    MAX_SCATTER_DATA_LIMIT: 7000,
    MIN_CHART_DATA_LIMIT: 1,
}