import React, { useState } from "react";
import { TableMainDiv, RowDiv, TdDiv, SelectionWrapper, OverlayMain, ButtonWrapper, Container } from "./combination-table.styled";
import { showPopup } from "../../../utils";
import * as enums from "../../../utils/enums";
import { NormalButton } from "../../hoc/normal.button";
import Add from "../../svg/add";
import SelectOptionV1 from "../../select-option/select.option.v1.1";
import DeleteIcon from "../../svg/v2-icons/deleteIcon";
import NestedSelectOption from '../../hoc/v2/nestedSelectOption';
import AddEditValue from '../components/value.add.edit';
import tableDataPopup from "../../table.data.popup";
import ArrowIcon from "../../svg/menu/arrowIcon";


const CombinationTable = (props) => {


    const { data, handleDelete, handleAddNewRule, handleCancel, handleSave, handleAddNewValue, onChangeRowValue, db_info_id, removeValueItem } = props;


    const rowWidth = {
        tableName: "20%",
        columnName: "15%",
        values: "33%",
        accessGroup: "20%",
        action: "12%"
    }


    const [nested_open, set_nested_open] = useState(false);
    const [dropdown_position, set_dropdown_position] = useState(undefined)

    /**
     * 
     * @param {*} row_data 
     * @param {*} row_index 
     */
    const add_value = (row_data, row_index) => {

        showPopup(undefined, undefined, enums.default.popupType.element, AddEditValue, {
            hintData: row_data,
            db_info_id: db_info_id,
            saveFun: (values) => {
                onChangeRowValue('table_column_values', values, row_index)
            }
        })
    }


    /**
     * 
     * @param {*} table_name 
     */
    const view_table_date = (table_name) => {
        showPopup(undefined, undefined, enums.default.popupType.element, tableDataPopup, {
            tableName: table_name,
            db_info_id: db_info_id
        })
    }


    const get_value_str = (data) => {
        console.log("data", data)
        var str = ''
        if (props.access_group_selections?.length > 0 && data && Object.keys(data)?.length > 0) {
            Object.keys(data)?.map((k) => {
                if (k && typeof data?.[k] === "object" && Array.isArray(data[k])) {
                    data[k].map((id) => {
                        props.access_group_selections.map((acc) => {
                            if (acc.id === id) {
                                str += acc.name
                            } else {
                                acc?.users?.map((u) => {
                                    if (u.id === id) {
                                        str += u.name + ","
                                    }
                                })
                            }
                        })
                    })
                }
            })
        }

        return str;

    }

    return (
        <Container>
            <TableMainDiv>
                <RowDiv
                    style={{
                        background: "linear-gradient(to right, #3E9EFF, #737DFF)",
                        borderRadius: "6px",
                    }}
                >
                    <TdDiv
                        width={rowWidth?.tableName}
                        style={{ color: "#fff" }}>
                        Table Name
                    </TdDiv>
                    <TdDiv
                        width={rowWidth?.columnName}
                        style={{ color: "#fff" }}>
                        Column Name
                    </TdDiv>
                    <TdDiv
                        width={rowWidth?.values}
                        style={{ color: "#fff" }}>
                        Values
                    </TdDiv>
                    <TdDiv
                        width={rowWidth?.accessGroup}
                        style={{ color: "#fff" }}>
                        Access Group
                    </TdDiv>
                    <TdDiv
                        width={rowWidth?.action}
                        style={{ color: "#fff" }}>
                        Action
                    </TdDiv>
                </RowDiv>

                {data?.length > 0 && data?.map((table, row_index) => {

                    const el_id = "element_id" + row_index;

                    return (
                        <RowDiv key={row_index}>

                            <TdDiv
                                width={rowWidth?.tableName}

                                style={{ justifyContent: 'center', alignItems: 'center' }}
                            >

                                {
                                    <SelectOptionV1
                                        options={props.distinct_of_table_name}
                                        value={table?.table_name || ''}
                                        icon_key="icon"
                                        background={"#f6f8fe"}
                                        none_value={false}
                                        hide_case={true}
                                        handleViewDetail={view_table_date}
                                        hide_margin={true}
                                        on_change={(val) => {
                                            onChangeRowValue("table_name", val, row_index)
                                        }}
                                    />
                                }
                            </TdDiv>

                            <TdDiv
                                width={rowWidth?.columnName}

                            >{table.column_name}
                            </TdDiv>

                            <TdDiv
                                width={rowWidth?.values}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}

                            >
                                <div className="value_selected" >
                                    <div>
                                        <input checked={table?.value_type === "all_value"} type="checkbox" onChange={() => {
                                            onChangeRowValue("value_type", "all_value", row_index)
                                        }} /> &nbsp;All Values &nbsp; &nbsp;
                                        <input type="checkbox"
                                            checked={table?.value_type === "selected_value"}
                                            onChange={() => {
                                                onChangeRowValue("value_type", 'selected_value', row_index)
                                            }}
                                        /> &nbsp; Selected Values
                                    </div>
                                    {table?.table_column_values?.map(el => {
                                        return <div className="value_item">
                                            {el}
                                            {/* <span onClick={() => removeValueItem()} className="remove_item_icon" >x</span> */}
                                        </div>
                                    })}
                                </div>

                                {table?.value_type === "selected_value" && (
                                    <NormalButton
                                        label="Add value"
                                        onClick={() => {
                                            add_value(table, row_index)
                                        }}
                                        color="#fff"
                                        mL='0.5rem'
                                        borderRadius="10px"
                                        icon={<Add size=".8rem" height=".8rem" color="#fff" />}
                                        boxShadow={'2px 2px 4px 0px rgba(163,163,163,1);'}
                                    />
                                )}

                            </TdDiv>


                            <TdDiv
                                width={rowWidth?.accessGroup}
                                isOpenaccess={nested_open }
                            >
                                <div
                                    id={el_id}
                                    className="__placholder"
                                    onClick={(e) => {

                                        const dropdown_height = 180;
                                        const element = document.getElementById(el_id);
                                        const el_bounding = element.getBoundingClientRect();
                                        const windowHeight = window.innerHeight;
                                        const element_width = el_bounding?.width;
                                        const element_height = el_bounding?.height;
                                        const element_left = el_bounding?.left;
                                        const element_top = el_bounding?.top;
                                        let drop_down_top_to_use = (element_top + element_height);
                                        if ((windowHeight - drop_down_top_to_use) <= dropdown_height) {
                                            drop_down_top_to_use = ((element_top + element_height) - dropdown_height) - 10
                                        }
                                        set_dropdown_position({
                                            top: drop_down_top_to_use,
                                            left: element_left,
                                            width: element_width - 14,
                                        })
                                        set_nested_open((row_index + "__key"))
                                    }}>
                                    <div className="selectedItems">{get_value_str(table?.access_group_data)}</div>
                                    <div className="accessGroupopen">
                                        <ArrowIcon height='0.8rem' size='0.8rem' color={'#7f7c7c'} style={{transform: 'rotate(180deg)'}} />
                                    </div>
                                </div>
                                {nested_open && (nested_open === row_index + "__key") && (
                                    <OverlayMain onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation()
                                        set_nested_open(false)
                                    }}>
                                        <SelectionWrapper onClick={(e) => e.stopPropagation()} top={dropdown_position?.top} left={dropdown_position?.left}>
                                            <NestedSelectOption
                                                // selection={[{id: '1', name: 'test 1', users: [{id: '2', name: 'test 2'}]}, {id: '12', name: 'test 12', users: [{id: '22', name: 'test 22'}]}]}
                                                selection={props.access_group_selections}
                                                display_key={"name"}
                                                value_key={"id"}
                                                maxHeight={"180px"}
                                                minHeight={"180px"}
                                                width={dropdown_position.width + 'px'}
                                                hoverColor='#E3EEFF'
                                                padding="0 0.4rem"
                                                onChange={(access_group_data) => {
                                                    onChangeRowValue('access_group_data', access_group_data, row_index)
                                                }}
                                                selected_values={table?.access_group_data || {}}
                                            />
                                        </SelectionWrapper>
                                    </OverlayMain>
                                )}


                            </TdDiv>

                            <TdDiv
                                width={rowWidth?.action}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f6f8fe', borderRadius: '20px', height: '100%', padding: '.5rem' }}>
                                   <span onClick={(e) => {
                                        handleDelete(row_index)
                                    }}> 
                                        <DeleteIcon  height={'1rem'} size={'1rem'} color="#e72f2f" />
                                    </span>
                                </div>
                            </TdDiv>

                        </RowDiv>
                    );
                })}

                <div className="addBtn">
                    <NormalButton
                        primary={true}
                        label="Add New Rule"
                        onClick={handleAddNewRule}
                        color="#fff"
                        icon={<Add size=".8rem" height=".8rem" color="#fff" />}
                        height="2.4rem"
                    />
                </div>

            </TableMainDiv>
        </Container>
    );
};

export default CombinationTable;
