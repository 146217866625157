import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils';
const selected = getSelectedTheme();

const { table } = themes[selected];



export const designs = {
    design1: {
        // vikash sir wala design 
        menu: true,
        th: {
            bgColor: table.color.thBackground,
            width: 'auto',
            maxHeight: '2rem',
            padding: '7px 0px',
            paddingRight: '10px',
            paddingLeft: '10px',
            textAlign: 'left',
            color: table.color.thText,
            fontWeight: 'bold',
            lHeight: '1.29',
            borderLeft: '0px',
            borderRight: '0px',
            position: 'sticky!important',
            zIndex: '1',
            top: '-1px',
        },
        td: {
            width: 'auto',
            height: '1.9rem',
            padding: '5px',
            paddingRight: '10px',
            paddingLeft: '10px',
            textAlign: 'left',
            color: table.color.tdText,
            borderLeft: '0px',
            fontSize: '0.8rem',
            whiteSpace: 'nowrap'
        },
    },

    design2: {
        menu: true,
        th: {
            bgColor: table.color.thBackground,
            width: 'auto',
            height: '3rem',
            maxHeight: '4rem',
            padding: '5px 0px',
            paddingRight: '10px',
            paddingLeft: '10px',
            textAlign: 'left',
            whiteSpace: 'normal',
            color: table.color.thText,
            fontWeight: 'bold',
            lHeight: '1.29',
            borderLeft: '0px',
            position: 'sticky!important',
            zIndex: '1',
            top: '-1px',
            fontSize: '.9rem',
            divHeight: '2.5rem',
            cursor: 'pointer'

        },
        td: {
            width: 'auto',
            height: '1rem',
            maxHeight: '1rem',
            padding: '5px',
            paddingRight: '10px',
            paddingLeft: '10px',
            textAlign: 'left',
            whiteSpace: 'normal',
            color: table.color.tdText,
            lHeight: '1.3rem',
            borderLeft: '0px',
            fontSize: '.8rem',
            spanHeight: '1rem'
        },
    },


    design3: {
        menu: false,

        th: {
            bgColor: table.color.thBackground,
            maxWidth: '9.714rem',
            width: '8.429rem',
            minWidth: '9.714rem',
            height: '3.786rem',
            maxHeight: '3.786rem',
            padding: '0.5rem 0px',
            paddingRight: '0.643rem',
            paddingLeft: '0.643rem',
            textAlign: 'left',
            whiteSpace: 'normal',
            color: table.color.thText,
            fontWeight: 'bold',
            lHeight: '1.29',
            borderLeft: '0px',
            borderRight: '0px',
            position: 'sticky!important',
            zIndex: '1',
            top: '-1px',
            fontSize: '1rem',
            divHeight: '2.929rem',

        },
        td: {
            maxWidth: '9.714rem', 
            width: '8.429rem',
            minWidth: '9.714rem',
            height: '3.786rem',
            maxHeight: '3.786rem',
            padding: '0.5rem',
            paddingRight: '0.643rem',
            paddingLeft: '0.643rem',
            textAlign: 'left',
            whiteSpace: 'normal',
            color: table.color.tdText,
            lHeight: '1.29',
            borderLeft: '0px',
            borderRight: '0px',
            fontSize: '14px',
            spanHeight: '37px'
        },
    }
}