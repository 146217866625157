import React, { useEffect, useState } from 'react';
import * as ol from 'ol';
import { withRouter } from 'react-router-dom';
import View from 'ol/View';
import Tile from 'ol/layer/Tile';
import VectorTile from 'ol/layer/VectorTile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import VectorTileSource from "ol/source/VectorTile";

import OSM from "ol/source/OSM";
import MVT from "ol/format/MVT";
import { connect } from 'react-redux'
import { defaults as defaultControls } from 'ol/control';
import Overlay from 'ol/Overlay';
import { transform } from 'ol/proj';
import Polygon from 'ol/geom/Polygon';
import Feature from 'ol/Feature';
import MapHeader from './map.header'
import { getPredefinedMarker, getCustomZoneMarkers, changeMapFilter, getZone, getMultiZone } from '../../actions/map';
import { checkDataInTileServer } from '../../actions/tiles';
import { render_cluster } from './open_layers/Clusters';
import { render_polygon } from './open_layers/Polgon';
import { drawPolygonButton, clearDrawnPolygon } from './open_layers/Controls/polygon_draw';
import { getClientId, getParamByName } from '../../utils';
import { stringToArrayCoor } from '../../utils/map';
import ClearSvg from '../svg/close';
import DeleteButton from './delete.button'


const CLIENT_ID = getClientId()['client-id'];

var tilePolygon;


var localZoneData = undefined

const MapWrapper = props => {

    const { search } = props.location;


    let opr_client_id = undefined;
    let opr_report_id = undefined;
    let opr_token = undefined;

    if (search) {
        opr_client_id = getParamByName(search, 'client_id');
        opr_report_id = getParamByName(search, 'report_id');
        opr_token = getParamByName(search, 'token');
    }

    const {

        widthOfChart,
        heightOfChart,
        predefinedLevel,
        getPredefinedMarker,
        predefinedMarkers,
        getCustomZoneMarkers,
        customMarkers,
        changeMapFilter,
        getZone, zone,
        searchMarker,
        checkDataInTileServer,
        tilePing,
        headerHide,
        questionMarker,
        questionZone,
        marker_type,
        predefinedData,
        isPolusAIWindow,
        id = "map",
        multi_zone

    } = props;

    const [localMap, setLocalMap] = useState(undefined);

    useEffect(() => {
        checkDataInTileServer();

        if (predefinedLevel) {
            getPredefinedMarker(predefinedLevel, opr_token, opr_client_id);
        }
    }, [])




    useEffect(() => {
        if (predefinedMarkers && predefinedMarkers.length > 0 && localMap) {

            const __data = predefinedData?.data;
            const underlying_column = predefinedData?.underlying_column;

            console.log("predefinedMarkers", predefinedMarkers)

            const yac = predefinedData?.yac || ['Revenue', 'Discount'];

            var stateDataMap = __data?.reduce((acc, item) => {
                acc[item[underlying_column]] = {};

                yac.forEach((key) => {
                    acc[item[underlying_column]][key] = item[key];
                });

                return acc;
            }, {});

            var new_predefined_markers = predefinedMarkers?.filter(item => stateDataMap?.[item?.underlying_column_data])
                .map(item => ({
                    ...item,
                    "tooltip_html": {
                        ...stateDataMap[item.underlying_column_data]
                    }
                }));


            const ids = predefinedMarkers?.filter(item => stateDataMap?.[item?.underlying_column_data])?.map((d) => d.underlying_column_data)
            console.log("stateDataMap", ids)

            if (isPolusAIWindow && predefinedMarkers && !multi_zone) {
                props?.getMultiZone(ids, "state_data", id)
            }
            if (isPolusAIWindow) {
                render_cluster(new_predefined_markers, localMap, undefined, marker_type);

            } else {
                render_cluster(predefinedMarkers, localMap, undefined, marker_type);

            }
        }
    }, [predefinedMarkers, predefinedData]);


    useEffect(() => {
        if (customMarkers && customMarkers.length > 0 && localMap) {
            render_cluster(customMarkers, localMap, undefined, marker_type);
            const underlyingColumn = customMarkers[0]['underlying_column'];
            const underlyingColumnData = customMarkers.map(d => d.underlying_column_data).join(',');

            // changeMapFilter(underlyingColumn + " is " + underlyingColumnData);
            let _filter = {
                [underlyingColumn]: underlyingColumnData.split(',')
            }
            if (!isPolusAIWindow) changeMapFilter(_filter);
        }
    }, [customMarkers]);


    useEffect(() => {
        if (localMap) {
            if (searchMarker && searchMarker.length > 0) {
                render_cluster(searchMarker, localMap, undefined, marker_type);
                render_polygon(undefined, localMap, true);
            } else {
                render_cluster(searchMarker, localMap, true, marker_type);
                render_polygon(undefined, localMap, true);
            }
        }
    }, [searchMarker]);


    useEffect(() => {
        if (questionMarker && questionMarker.length > 0 && localMap) {
            render_cluster(questionMarker, localMap, undefined, marker_type);
            render_polygon(undefined, localMap, true);
        }
    }, [questionMarker]);


    useEffect(() => {
        if (tilePing && tilePing.code === 200 && localMap) {
            const tileLayer = new VectorTile({
                declutter: true,
                source: new VectorTileSource({
                    format: new MVT({ idProperty: 'iso_a3' }),
                    url: `${process.env.REACT_APP_TILE_SERVER_URL}{z}/{x}/{y}.pbf`,
                }),
                style: function (feature) {
                    const property = feature.getProperties();

                    let col = 'rgba(41, 182, 246, 0.1)'

                    if (property && property['COLOR']) {
                        const splitted = property['COLOR'].split(' ').join(',');

                        col = `rgb(${splitted})`;
                    }

                    const s = new Style({
                        stroke: new Stroke({
                            color: '#3399CC',
                        }),
                        fill: new Fill({
                            color: col,
                        })
                    })

                    return s
                }
            });

            localMap.addLayer(tileLayer)
        }
    }, [tilePing]);


    const render_polygon_by_zone = (local_polygon = false) => {


        localZoneData = zone;

        console.log("local_polygon", local_polygon, zone)

        if ((!zone || Object.keys(zone)?.length === 0) && local_polygon) {
            props.getPredefinedMarker(predefinedLevel, opr_token, opr_client_id)
        }

        if (zone && Object.keys(zone).length > 0 && localMap) {

            const zone_data = zone[Object.keys(zone)[0]];

            console.log("zone_data", zone);

            const markers_in_zone = zone_data?.markers_in_zone;

            if (zone_data && zone_data.markers_in_zone && zone_data.markers_in_zone.length > 0) {
                render_cluster(zone_data.markers_in_zone, localMap, undefined, marker_type);
            }
            else {
                render_cluster(undefined, localMap, true, marker_type);
            }

            if (zone_data && zone_data.zone_data && zone_data.zone_data.converted_coordinates && zone_data.zone_data.converted_coordinates.length > 0) {

                const d = zone_data.zone_data.location;
                // d.type = 'MultiPolygon';
                render_polygon(d, localMap);
            }
            if (markers_in_zone?.length > 0) {
                const underlyingColumn = markers_in_zone[0]['underlying_column'];
                const underlyingColumnData = markers_in_zone.map(d => d.underlying_column_data).join(',');
                let _filter = {
                    [underlyingColumn]: underlyingColumnData.split(',')
                }
                if (!isPolusAIWindow) changeMapFilter(_filter);
            } else {
                let _filter = {
                    'customer_code': ['00000100000']
                }
                if (!isPolusAIWindow) changeMapFilter(_filter);

            }
        }
        else {
            // discard all filter 
            if (!isPolusAIWindow) changeMapFilter(undefined)
            render_polygon(undefined, localMap, true);

        }
        console.log("calling", zone)
    }



    useEffect(() => {

        if (localMap && multi_zone) {

            // render_polygon_by_zone(false, true)
            const data = {
                type: 'MultiPolygon',
                coordinates: undefined
            }
            const coordinates = [];

            if (multi_zone && Object.keys(multi_zone)?.length > 0) {
                Object.keys(multi_zone)?.map((k) => {
                    coordinates.push(multi_zone[k])
                })
            }
            data.coordinates = coordinates

            // console.log("coordinates", coordinates)
            render_polygon(data, localMap);

        }
    }, [multi_zone]);

    useEffect(() => {
        render_polygon_by_zone()
    }, [zone]);


    useEffect(() => {
        if (questionZone && questionZone.length > 0) {
            render_polygon(undefined, localMap, true);

            questionZone.forEach(z => {
                render_polygon(z.location, localMap, undefined, 'grouping');
            })
        }
    }, [questionZone])



    const remove_filter = (tooltip = false) => {

        console.log("removing filter", localZoneData, tooltip)

        if (localZoneData && Object.keys(localZoneData).length > 0) {
            const zone_data = localZoneData[Object.keys(localZoneData)[0]];
            const markers_in_zone = zone_data?.markers_in_zone;

            console.log("removing filter1.1", markers_in_zone);

            if (markers_in_zone?.length > 0) {
                const underlyingColumn = markers_in_zone[0]['underlying_column'];
                const underlyingColumnData = markers_in_zone.map(d => d.underlying_column_data).join(',');
                let _filter = {
                    [underlyingColumn]: underlyingColumnData.split(',')
                }
                if (!isPolusAIWindow) changeMapFilter(_filter);
            } else {
                // discard all filter

                if (!isPolusAIWindow) changeMapFilter(undefined)
                !tooltip && render_polygon(undefined, localMap, true);

            }

        } else {
            if (!isPolusAIWindow) changeMapFilter(undefined)
            !tooltip && render_polygon(undefined, localMap, true);

        }
    }


    useEffect(() => {
        const layers = [
            new Tile({
                source: new OSM()
            })
        ]

        const map = new ol.Map({
            controls: defaultControls(),
            view: new View({
                center: transform([79.0882, 21.1458], 'EPSG:4326', 'EPSG:3857'),
                zoom: 4.8
            }),
            layers: layers,
            target: id
        });

        map.getViewport().addEventListener("click", function (e) {

            map.forEachFeatureAtPixel(map.getEventPixel(e), function (feature, layer) {
                const extractedFeatures = feature.get('features');
                const layerName = feature.getGeometry().get('layer');

                const type = feature.getGeometry().getType()


                if (feature.get('DNAME')) {
                    const dname = feature.get('DNAME')
                    const dmname = feature.get('DMNAME')
                    const dvname = feature.get('DVNAME')


                    if (!isPolusAIWindow) changeMapFilter("dname" + " is " + dname + " and " + "dmname" + " is " + dmname + " and " + "dvname" + " is " + dvname);
                }

                else if (extractedFeatures && extractedFeatures.length === 1 && type === 'Point') {

                    const name = extractedFeatures[0].get('name');

                    if (name === 'cluster') {

                        const meta = extractedFeatures[0].get('meta');
                        const coor = extractedFeatures[0].getGeometry().getCoordinates();

                        const underlyingColumn = meta['underlying_column'];
                        const underlyingColumnData = meta['underlying_column_data'] + ",";

                        // changeMapFilter(underlyingColumn + " is " + underlyingColumnData);
                        let _filter = {
                            [underlyingColumn]: underlyingColumnData.split(',')
                        }
                        // console.log("___filter__", JSON.parse(JSON.stringify(_filter)))

                        if (!isPolusAIWindow) changeMapFilter(_filter);


                        const container = document.getElementById('popup');
                        const content = document.getElementById('popup-content');
                        const closer = document.getElementById('popup-closer');

                        container.style.display = "block"

                        const overlay = new Overlay({
                            element: container,
                            autoPan: true,
                            autoPanAnimation: {
                                duration: 250,
                            },
                        });


                        map.addOverlay(overlay);

                        closer.onclick = function () {
                            overlay.setPosition(undefined);
                            closer.blur();
                            remove_filter(true)
                            return false;
                        };

                        const name = meta['name'] || meta['Branch Name'];
                        const address = meta['address'];
                        const hm_c_data = meta['hm_c_data'];
                        const tooltip_html = meta['tooltip_html'];

                        // console.log("tooltip_html", tooltip_html)

                        let innerHtml = ``;

                        if (name) {
                            innerHtml += `<h1 
							style="margin: 0px;
							font-size: 16px;
							font-weight: bold;
							text-transform: uppercase;
							color: rgb(0, 107, 211);">${name}</h1>`
                        }

                        if (address) {
                            innerHtml += `<p style="margin: 10px 0px;
							font-size: 13px;
							color: rgb(34, 34, 34);
							width: 80%;">${address}</p>`
                        }

                        if (tooltip_html) {
                            // it will be an object, until we decide to pass it as html
                            Object.keys(tooltip_html).forEach(tooltip => {
                                innerHtml += `											<div>
								<span style="color: rgb(0, 0, 0);
								font-size: 16px;
								text-transform: capitalize;">${tooltip}:</span>
								<span style="color: rgb(0, 0, 0);
								font-size: 16px;
								font-weight: bold;">${tooltip_html[tooltip]}</span>`
                            });

                            innerHtml += `</div>`;

                        }

                        else if (hm_c_data) {
                            innerHtml += `											<div>
							<span style="color: rgb(0, 0, 0);
							font-size: 16px;
							text-transform: capitalize;">No of Customers:</span> 
							<span style="color: rgb(0, 0, 0);
							font-size: 16px;
							font-weight: bold;">${hm_c_data}</span>
						</div>`
                        }

                        content.innerHTML = innerHtml;

                        overlay.setPosition(coor);
                    }
                }
            });
        });


        map.on('pointermove', function (e) {
            // console.log("calling1")
            map.forEachFeatureAtPixel(e.pixel, function (f) {

                if (f && f.getOrientedFlatCoordinates) {
                    const prop = f.getProperties();

                    if (typeof prop['LEGEND'] === 'undefined') {
                        let flatCoordinates = f.getOrientedFlatCoordinates();

                        flatCoordinates = stringToArrayCoor(flatCoordinates);

                        if (tilePolygon) {
                            tilePolygon.getSource().clear()
                            map.removeLayer(tilePolygon)

                            tilePolygon = undefined;
                        }

                        const polygon = new Polygon([flatCoordinates]);

                        var feature = new Feature(polygon);

                        var vectorSource = new VectorSource();
                        vectorSource.addFeature(feature);

                        const style = new Style({
                            stroke: new Stroke({
                                color: '#3399CC',
                                width: 1,
                            }),
                            fill: new Fill({
                                color: 'rgba(0, 0, 255, 0.2	)',
                            })
                        })

                        // Create vector layer attached to the vector source.
                        tilePolygon = new VectorLayer({
                            source: vectorSource,
                            style: style
                        });


                        // Add the vector layer to the map.
                        map.addLayer(tilePolygon);
                    }
                }
            });
        });


        drawPolygonButton(map, getCustomZoneMarkers, render_polygon, predefinedLevel);
        setLocalMap(map);

    }, []);


    // console.log("multi_zone", props?.multi_zone)

    return (
        <div>

            {!headerHide && (
                <MapHeader
                    getZone={getZone}
                    predefinedLevel={predefinedLevel}
                />
            )}


            <div
                id={id}
                style={{
                    height: heightOfChart,
                    width: widthOfChart
                }}
            >

                {!props?.hideClear && <button className="map_clear_btn" title="clear Polygon" style={{
                    position: 'absolute',
                    top: '75px',
                    zIndex: '1',
                    width: '1.5rem',
                    height: '1.5rem',
                    cursor: 'pointer',
                    right: '1rem',
                    backgroundColor: 'rgb(0 60 136 / 61%)',
                    border: '0px',
                    borderRadius: '3px'
                }}
                    onClick={() => {
                        clearDrawnPolygon(localMap)
                        // changeMapFilter();
                        render_polygon_by_zone(true)

                    }}
                >
                    <ClearSvg size=".8rem" height=".7rem" color="#fff" />
                </button>}


                {/* <DeleteButton /> */}
                <span id="status"></span>

                <div id="popup" class="ol-popup">
                    <a href="#" id="popup-closer" class="ol-popup-closer"></a>
                    <div id="popup-content"></div>
                </div>
            </div>
        </div>

    )
};





const mapStateToProps = (state, props) => {
    
    const { zone, zoneMarkers, zoneNames, customMarkers, radiusMarkers, predefinedMarkers, searchMarker, questionMarker, questionZone } = state.mapReducer;
    const { heatmapConfigration } = state.heatmapConfigrationReducer;
    const { tilePing } = state.tileReducer;

    const multi_zone = state?.mapReducer?.multi_zone_cache?.[props?.id];

    return { multi_zone, zone, zoneMarkers, zoneNames, customMarkers, radiusMarkers, predefinedMarkers, heatmapConfigration, searchMarker, tilePing, questionMarker, questionZone }
};


export default withRouter(connect(mapStateToProps, { getMultiZone, getPredefinedMarker, getCustomZoneMarkers, changeMapFilter, getZone, checkDataInTileServer })(MapWrapper));
