import styled from 'styled-components';

import { themes } from '../../../theme/theme.config'
import { getSelectedTheme } from '../../../utils';
const selected = getSelectedTheme();

const { label, textbox, reportList } = themes[selected];

export const HintsWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    padding: 15px 27px;
    background: white;
    min-width: 22.857rem;
    max-width: 22.857rem;
    max-height: 27.143rem;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.25);
    border: solid 5px rgb(234,234,234);
    background-color: rgb(255,255,255);
    overflow: auto;


    input{
        height: 2.857rem;
        border-radius: 0px;
        border: 0px;
        background-color: #eaeaea;
        width: 100%;
        font-family: "DM Sans", sans-serif;
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.286rem;
        letter-spacing: normal;
        text-align: left;
        color: ${textbox.color};
        padding: 0px 16px;
        padding-right: 25px;
        box-sizing: border-box;

        &:hover{
            border-color:${textbox.border.hoverColor};
            outline: none;
            box-shadow: 0 0 0;
        }
        &:focus{
                box-shadow: 0 0 0;
                outline: none;
        }

    }




`;


export const AddNewTag = styled.div`
    width: 100%;
    padding: 12px 0rem;
    border-bottom: 2px solid #ccc;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .add_title{
        font-size: 1rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.286rem;
        letter-spacing: normal;
        text-align: left;
        color: #1a1a1a;
        margin: 0px;
        padding-left: 10px;
    }
    
    .add{
        font-size: 35px;
        line-height: 17px;
    }

`;


