import React, { useEffect, useState, Suspense } from 'react'
import { Wrapper, EditorInnerWrapper, NewWrapper, TableWrapper, } from './context.styled'
import { connect } from 'react-redux'
import { getTaggedInfo , saveTaggedInfo} from '../../actions/helper';
import { Button } from '../hoc/button'
import { showPopup } from '../../utils';
import * as enums from '../../utils/enums';
import AddEdit from './add_edit';
import {Gap} from '../gap'


const Index = (props) => {

    const TableRow = React.lazy(() => import('./row'));

    const { taggingInfo } = props;
    const [localTaggedData, setTaggedData] = useState(undefined)

    useEffect(() => {
        props.getTaggedInfo();
    }, [])

    useEffect(() => {
        setTaggedData(taggingInfo)
    }, [taggingInfo])

    const _delete_row = (data) => {

        {
            props.saveTaggedInfo(data)
            setTimeout(() => {
                props.getTaggedInfo()
            }, 1000)
        }
    }

    return (
        <div style={{ marginTop: '4rem' }}>
            <NewWrapper>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '0.5rem' }}>
                    <Button 
                        primary={true}
                        onClick={() => {
                        showPopup(undefined, undefined, enums.default.popupType.element, AddEdit,{
                            item: undefined
                        });
                    }}>Add New</Button>
                </div>
                <Wrapper>
                    <EditorInnerWrapper>
                    <Gap h="1rem" />

                        <TableWrapper>
                            {localTaggedData && localTaggedData !== undefined &&

                                <table className="table">
                                    <thead className="thead">
                                        <tr>
                                            <th>Document Type</th>
                                            <th>Context</th>
                                            <th>Tagged To</th>
                                            <th>Action</th>

                                        </tr>
                                    </thead>

                                    <tbody style={{ alignItems: 'center' }}>
                                        {localTaggedData.map((item, index) => {
                                            return (
                                                <Suspense fallback={<p>Loading</p>}>
                                                    <TableRow key={"word_row" + index} item={item} _delete_row={_delete_row} />
                                                </Suspense>
                                            )
                                        })
                                        }

                                    </tbody>
                                </table>
                            }
                            {!localTaggedData && <p style={{ textAlign: 'center', marginTop: '5rem' }}>Loading...</p>}
                        </TableWrapper>
                    </EditorInnerWrapper>
                </Wrapper>
            </NewWrapper>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { taggingInfo } = state.helperReducer;
    return { taggingInfo }
}
export default connect(mapStateToProps, { getTaggedInfo , saveTaggedInfo})(Index)
