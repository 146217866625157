export const VOICE_HELPER_INIT = 'modules/VOICE_HELPER/init';
export const DISPATCH_CHART_TYPE_COMMAND_SUCCESS= 'modules/VOICE_HELPER/DISPATCH_CHART_TYPE_COMMAND_SUCCESS';
export const DISPATCH_DOWNLOAD_COMMAND_SUCCESS = 'modules/VOICE_HELPER/DISPATCH_DOWNLOAD_COMMAND_SUCCESS';
export const RAISE_TEXT_TO_VOICE = 'modules/VOICE_HELPER/RAISE_TEXT_TO_VOICE';
export const CREATE_DASHBOARD_USING_VOICE_COMMAND = '/modules/CREATE_DASHBOARD_USING_VOICE_COMMAND';
export const CLEAR_VOICE_COMMAND_DASHBOARD = '/modules/CLEAR_VOICE_COMMAND_DASHBOARD';
export const DISPATCH_OTHER_VOICE_COMMAND = 'DISPATCH_OTHER_VOICE_COMMAND'





export const RAISE_INTERPRET_COMMAND  = 'polus_v2/module/RAISE_INTERPRET_COMMAND';
export const CLEAR_INTERPRET_COMMAND  = 'polus_v2/module/CLEAR_INTERPRET_COMMAND';