import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { CHART_TYPES } from '../../../shared-with-fe/constants';
import { apply_styles, formatValueByDataType, is_date, remove_repetitive_keys } from '../../../utils';
import { constants } from '../../../utils/constants';


const LegendWrapper = styled.ul`
    display: flex;
    flex-direction: row;
    margin: 0px;
    justify-content: ${props => props?.moreLen ? 'flex-start' : 'center'};
    list-style: none;
    align-items: ${props => props.guage_chart ? "flex-start" : 'center'};
    flex-direction: ${props => props.guage_chart ? "column" : undefined};
    overflow-x: auto;

    &::-webkit-scrollbar {
        background-color:red;
        width:0px
     }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 0px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 0px;
    }
`;




const Legend = styled.li`

    ${props => apply_styles(props._style, constants.STYLED_COMPONENTS.ALL)}
    padding-left: 1.5rem;
    position: relative;
    /* margin-right: 1.4rem; */
    min-width: 1.2rem;
    /* max-width: 1.8rem; */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 1rem;
    flex-shrink: 0;
    

    ::before{
        background-color: ${props => props?.inActive ? '#ccc' : props.rectColor ? props.rectColor : undefined};
        ${props => apply_styles(props.rectStyle, constants.STYLED_COMPONENTS.ALL)}
        content: '';
        position: absolute;
        left: 0px;
        border-radius: 30%;
        display: inline-block;
        margin-right: 5px;
    }

    /* .__value__{
        display: inline-block;
        min-width: calc(100%  - 1rem);
        max-width: calc(100%  - 1rem);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    } */
`;


const RenderLegend = (props) => {

    const { inactiveGraphs, payload, chartType, legendStyle, isPieChart, _style, show_prcentage_in_legends, is_guage_chart, alignments, handleLegendClick } = props;

    const [localPayload, setLocalPayload] = useState(undefined)

    // console.log("payload11",props )


    useEffect(() => {

        let keys = [];
        let _temp_data = [];
        let temp_payload = payload ? [...payload] : [];

        if (props.is_water_fall) {
            const f_data = temp_payload.filter(c => {
                if (c.dataKey?.indexOf("_nfx_1") > -1) return true
                else return false
            })

            temp_payload = f_data
        }

        // MA CODE        
        if (chartType === CHART_TYPES.stacked_bar_chart_100 || chartType === CHART_TYPES.area_chart_100) {
            temp_payload.map((item, index) => {
                if (temp_payload[index]?.["dataKey"].indexOf("_nfstackshare") > 1) {
                    item["dataKey"] = item?.dataKey.split("_nfstackshare")[0]
                    item["value"] = item?.value.split("_nfstackshare")[0]
                }
            })
        }


        if (payload && payload.length > 0) {
            const processd_payload = remove_repetitive_keys(temp_payload).final_payload;

            Array.isArray(processd_payload) && processd_payload.map((key) => {
                if (keys.indexOf(key.dataKey) === -1) {
                    keys.push(key.dataKey);
                    _temp_data.push(key);
                }
            })
        }
        setLocalPayload(_temp_data)
        if (isPieChart) {
            setLocalPayload(payload)
        }
        // console.log("payload", payload)

    }, [payload])



    useEffect(() => {
        let keys = [];
        let _temp_data = [];
        let gauge_legend__val = [];
        let temp_payload = payload ? [...payload] : [];
        if (props.is_water_fall) {
            const f_data = temp_payload.filter(c => {
                if (c.dataKey?.indexOf("_nfx_1") > -1) return true
                else return false
            })
            temp_payload = f_data
        }
        if (payload && payload.length > 0) {
            const processd_payload = remove_repetitive_keys(temp_payload).final_payload;
            Array.isArray(processd_payload) && processd_payload.map((key, index) => {
                if (keys.indexOf(key.dataKey) === -1) {
                    keys.push(key.dataKey);
                    _temp_data.push(key);
                }
                //MA
                if (key?.value !== 0 && key?.value !== 1 && key?.value !== 2 && key?.value !== 3 && key?.value !== 4) gauge_legend__val.push(key);
                //MA
            })
        }
        setLocalPayload(_temp_data)
        if (isPieChart) {
            setLocalPayload(payload)
        }
        if (is_guage_chart) {
            setLocalPayload(gauge_legend__val)
        }
    }, [payload])


    const labelStyle = legendStyle && legendStyle['label'];
    const rectStyle = legendStyle && legendStyle['rectangle'];


    return (
        <LegendWrapper style={_style} guage_chart={is_guage_chart ? "flex" : undefined} moreLen={localPayload?.length > 15}>
            {
                localPayload && localPayload.length > 0 && localPayload.map((entry, index) => {
                    const p_v = show_prcentage_in_legends ? (entry && entry.payload && entry.payload.percent && entry.payload.percent * 100) : null;


                    const column_formating_meta = alignments?.[entry?.['dataKey']]

                    const currency_type = column_formating_meta?.['currency_type'];
                    const use_comma = column_formating_meta?.['use_comma'];
                    const use_decimal = column_formating_meta?.['use_decimal'];
                    const d_type = column_formating_meta?.['type'];

                    // let display_value = (entry.value && entry.value.length > 0) ? entry.value.split("_nfx_").join(' ') : entry.value;

                    // MA CODE
                    let display_value = '';
                    if (entry?.value && entry?.value?.length > 0 && entry?.value?.split("_nfx_")?.length > 0 && entry?.value?.split("_nfx_")[1] == 1) {
                        display_value = (entry.value && entry.value.length > 0) ? entry?.value?.split("_nfx_")[0] : entry?.value;
                    } else display_value = (entry.value && entry.value.length > 0) ? entry?.value?.split("_nfx_").join(' ') : entry?.value;


                    // let display_value = (entry.value && entry.value.length > 0) ? entry.value.split("_nfx_")[0] : entry.value;

                    // const d_value = entry.value && entry.value.length > 0 && entry.value.split("_nfx_")[0];
                    // if (entry.value && entry.value.length > 0 && d_value && is_date(d_value)) {
                    //     display_value = formatValueByDataType(d_value, 4)
                    // }

                    if (entry?.value && entry?.value?.length > 0 && entry?.value?.indexOf("_trend") > -1 && entry?.value?.indexOf("nfx_") > -1) return null;

                    else {
                        return (
                            <Legend
                                inActive={inactiveGraphs?.[entry.dataKey]}
                                onClick={() => {
                                    if (handleLegendClick) {
                                        handleLegendClick(entry.dataKey)
                                        console.log("inactiveGraphs", inactiveGraphs)
                                    }
                                }}
                                title={display_value}
                                _style={labelStyle}
                                rectStyle={rectStyle}
                                key={"legend" + index}
                                rectColor={entry.color || "#000"}
                            >
                                {/* <span className='__circle__'></span> */}
                                {/* <span className='__value__'> */}
                                {display_value?.split("nf").join(" ")}
                                {show_prcentage_in_legends ? (", " + formatValueByDataType(p_v, d_type, undefined, undefined, undefined, undefined, currency_type, use_comma, use_decimal)) : null}

                                {/* </span> */}
                            </Legend>

                        )
                    }
                })
            }
        </LegendWrapper>
    );
}


export default RenderLegend;