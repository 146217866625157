import * as actions from '../action-types/actual_plan'


const initialState = {
    type: actions.ACTUAL_PLAN_INIT,
    plannedMeetings: undefined,
    actualMeetings: undefined,
};

const ActualPlan = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }


    switch (action.type) {

        case actions.GET_ALL_PLANNED_METTINGS:
            return {
                ...state,
                type: action.type,
                plannedMeetings: action?.data?.planned_meetings
            }

        case actions.CLEAR_ACTUAL_PLAN_DETAILS:
            return {
                ...state,
                type: action.type,
                actualMeetings: undefined,
            }
        case actions.GET_ACTUAL_PLAN_DETAILS_BY_PLAN_ID:
            return {
                ...state,
                type: action.type,
                actualMeetings: action?.data?.actual_meetings
            }
        default:
            return {
                ...state,
            }
    }
}

export default ActualPlan;