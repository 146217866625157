import styled from 'styled-components';


export const MainWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    background: #CCC;
    border: 1px solid #CCC;
    margin: auto;
    background: white;
    text-align: center;
    position: relative;
    box-sizing: border-box;

    .loading_def{
           position: fixed;
            z-index: 1111;
            top: 0px;
            bottom: 0px;
            margin: auto;
            left: 0px;
            right: 0px;
            /* height: 100vh; */
            height: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 1.8rem;
            color: #222;;
    }


    .anchor_menu {
        font-size: 14px;
        background-color: #fff;
        border-radius: 2px;
        padding: 5px 0 5px 0;
        width: 150px;
        height: auto;
        margin: 0;
        position: absolute;
        list-style: none;
        box-shadow: 0 0 20px 0 #ccc;
        z-index: 8;
        transition: opacity 0.5s linear;
        li{
            text-align: left;
            padding: 4px 10px;
            font-size: 1rem;
            font-weight: 500;
            color: #222;
            cursor: pointer;
            &:hover{
                background-color:#dcdcdc;
            }
        }
}
    .delete_item{
        position: absolute;
        right: -25px;
        top: -25px;
    }
    #dragA {
        width: 150px;
        position: absolute;
        left: 100px;
        top: 100px;
    }
    img {
        max-width: 100%;
    }
    .resizable {
        background: cyan;
    }
    .resizer {
        width: 20px;
        height: 20px;
        background: #3d7cae;
        position: absolute;
        right: 0;
        bottom: 0;
        cursor: se-resize;
        border-top-left-radius: 23px;
    }
    .dynamic_div{
        width: 200px;
        height: 200px;
        background: red;
        margin: auto;
    }
    .grid_custom{
        overflow: -moz-scrollbars-none; /* Firefox */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */

        &:-webkit-scrollbar {
             display: none;
      }
        /* &::-webkit-scrollbar {
            background-color:red;
            width: 5px
        }
        &::-webkit-scrollbar-track {
            background-color:#ccc;
        }
        &::-webkit-scrollbar-thumb {
            background-color:#ccc;
            border-radius:0px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        } */
    }
`;


export const Header = styled.div`
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 50px;
    box-sizing: border-box;
    border-bottom: 1px solid #ccc;
    background: #fff;
    position: sticky;
    top: 0px;
    z-index: 6;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.2),0 1px 5px 0 rgba(0,0,0,0.12)!important;
`;



export const LogoWrapper = styled.div`
    cursor: pointer;
`;


export const ChartOptions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.5rem;
`;

export const ChartIcon = styled.div`
    height: 2.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    position: relative;
    padding: 0rem 1rem;
    margin-right: .3rem;
    border-radius: 3px;
    background: #308bc8;
    svg{
        fill: #fff;
    }
    span{
        font-size: .8rem;
        font-weight: 500;
        text-transform: uppercase;
    }
    .tooltiptext {
        visibility: hidden;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 10px;
        position: absolute;
        z-index: 1;
        top: 8px;
        right: 119%;
        font-size: 12px;
        white-space: nowrap;
        
    }
    .tooltiptext::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent transparent black;
    }
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 5px;
        height: 5px;
        background: #63b6d652;
        opacity: 0;
        border-radius: 50%;
        transform: scale(1, 1) translate(-50%, -50%);
        transform-origin: 50% 50%;
    }
    &:hover{
        .tooltiptext {
             visibility: visible;
        }
        svg{
            fill: #008AC7;
        }
       background: #63b6d652;
    }
    &:last-child{
        border-right: 1px solid #eaeaea;
    }
`;

export const IconSmall = styled.div`
        /* width: 1.5rem;
        height: 1.5rem; */
        border-radius: 50%;
        /* background: ${props => props.isShow ? "#c7c9cc" : 'transparent'}; */
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        cursor: pointer;
        color: #000;
        z-index: 1;
        margin-right: 10px;
        &:hover{
            svg{
                fill: #008ac7;
            }
        }
`;

export const ActionWrapper = styled.div`
    position: absolute;
    top: 50%;
    right: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform: translate(0%, -50%);
`;

export const HeaderWrapper = styled.div`
    width: 100%;
    position: relative;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.143rem;
    font-weight: 500;
    display: block;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    color: rgb(26, 26, 26);
    text-transform: capitalize;
    min-height: 46px;
    padding: 14px;
`;




export const PopupWrapper = styled.div`
    position: relative;
    width: 47.2rem;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 6px 0px;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(234, 234, 234);
    border-image: initial;
    padding: 1.643rem 1.429rem;
    overflow-y: auto;
    max-height: 80vh;
    &::-webkit-scrollbar {
        background-color:red;
        width:5px
    }
    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }
    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 5px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 5px;
    }
   
    .title{
        font-size: 1rem;
        margin-bottom: 5px;
    }
`;



export const TitleWrapper = styled.div`
    font-size: 1.286rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.714rem;
    letter-spacing: normal;
    text-align: center;
    color: rgb(26, 26, 26);
    display: block;
    margin: 0px;
    margin-bottom: 1.5rem;
`;


export const CloseWrapper = styled.div`
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 7px;
`;



export const LayoutFlexWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const LayoutBox = styled.div`
    width: 10rem;
    height: 10rem;
    border: 1px solid ${props => props.active ? "red" : '#ccc'};
    margin: 1rem;
    margin-top: 0px;
    margin-left: 0px;
    cursor: pointer;
    &:hover{
        border-color: red;
    }
    &:nth-child(4) {
        margin-right: 0px;
    }
`;


export const FromEditor = styled.div`
    width: 100%;
    padding: 0px 10px;
    box-sizing: border-box;
    input{
        cursor: pointer;
    }
`;