import React from "react";

const ToggleIcon = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 76.000000 76.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,76.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M14 747 c-2 -7 -3 -94 -2 -192 l3 -180 87 -3 88 -3 0 -94 0 -95 85 0
            85 0 0 -77 c0 -43 4 -84 8 -91 7 -9 53 -12 198 -10 l189 3 0 195 0 195 -87 3
            -87 3 -3 82 -3 82 -92 3 -93 3 0 88 c0 72 -3 90 -16 95 -9 3 -92 6 -185 6
            -129 0 -171 -3 -175 -13z m346 -97 l0 -79 -82 -3 -83 -3 -3 -83 -3 -83 -77 3
            -77 3 -3 150 c-1 82 0 155 2 162 4 10 44 13 166 13 l160 0 0 -80z m190 -280
            l0 -170 -165 0 -165 0 0 170 0 170 165 0 165 0 0 -170z m180 -170 l0 -170
            -170 0 -170 0 0 75 0 75 95 0 95 0 0 95 0 95 75 0 75 0 0 -170z"
        />
      </g>
    </svg>
  );
};

export default ToggleIcon;
