import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const weekArray = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
];


const Wrapper = styled.div`
    width: 100%;
`;



const CalendarTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    overflow: auto;
    td{
        border: 1px solid #d1cacaad;
    }
`;




const CalendarThead = styled.thead`
    width: 100%;
`;



const CalendarDate = styled.td`
    text-align: center;
    padding: .4rem 0px;

    p{
        margin: 0px;
        text-transform: uppercase;
        color: #70757a;
        font-size: .8rem;
    }

    h1{
        margin: 0px;
        text-transform: uppercase;
        color: #70757a;
        margin-top: .5rem;
        font-size: 1.2rem;
        margin-bottom: 0px !important;
    }

`;




const CalendarSlot = styled.td`
    height: 2rem;
    text-align: center;
    width: 2.5rem;
    border-bottom-color: ${props => props.active ? "#308bc8 !important" : undefined};
    background-color: ${props => props.active ? '#0795f52e' : undefined};
    background-color: ${props => props.bg ? props.bg : undefined};
    cursor: pointer;

    &:hover{
        background-color: #308bc821;
    }
    .booked_text{
        font-size: .7rem;
    }
`;


const CalandarLeft = styled.td`
    height: 2rem;
    width: 3rem;
    margin: 0px;
    text-transform: uppercase;
    color: #70757a;
    text-align: center;
    font-size: .8rem;
`;




const Index = (props) => {

    const { timeSlotList, bookedList, selectedDateTime, setSelectedDateTime } = props;
    const [dates, setDates] = useState(undefined);
    // const [seletValue, setSelectValue] = useState(undefined);


    const getDayName = (date) => {
        var d = new Date(date);
        var day = d?.getDay();
        return weekArray[day];

    }

    const getDate = (date) => {
        var d = new Date(date);
        var _date = d?.getDate();
        return _date;
    }


    const getUpcomingDates = () => {
        var days = new Array(10);
        var d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        let full_date = new Date(year, month, day)

        for (var i = 0; i < days.length; i++) {
            var nextDate = new Date(full_date);
            nextDate.setDate(nextDate.getDate() + i);
            days[i] = nextDate;
        }
        return days;
    }



    useEffect(() => {
        let days = getUpcomingDates();
        setDates(days);

    }, []);




    return (
        <Wrapper>
            <CalendarTable>
                <CalendarThead>
                    <tr>
                        <td></td>
                        {dates && dates.length > 0 && dates.map((d, i) => {
                            return (
                                <CalendarDate key={"d" + i}>
                                    <p>{getDayName(d)}</p>
                                    <h1>{getDate(d)}</h1>
                                </CalendarDate>
                            )
                        })}
                    </tr>
                </CalendarThead>

                <tbody>
                    {timeSlotList && timeSlotList.length > 0 && timeSlotList.map((slot, i) => {
                        return (
                            <tr key={"sl" + i}>
                                <CalandarLeft>
                                    {slot.name}
                                </CalandarLeft>
                                {dates && dates.length > 0 && dates.map((date, j) => {
                                    let fronted_date = new Date(date);
                                    let fronted_date_year = fronted_date.getFullYear();
                                    let fronted_date_month = fronted_date.getMonth();
                                    let fronted_day = fronted_date.getDate();
                                    let is_booked_top = false;


                                    let selected_date = selectedDateTime ? new Date(selectedDateTime.date) : undefined;
                                    let selected_day = selected_date && selected_date.getDate();
                                    const is_selected = selectedDateTime && (selectedDateTime.time === slot.id) && (selected_day === fronted_day);

                                    return (
                                        <CalendarSlot key={"s" + j}
                                            title={fronted_date + slot.name}
                                            active={is_selected}
                                            // bg={is_booked_top ? "red" : undefined}

                                            onClick={() => {
                                                if (!is_booked_top) {
                                                    setSelectedDateTime({
                                                        time: slot.id,
                                                        date: date
                                                    })
                                                }
                                            }}
                                        >
                                            {bookedList && bookedList.length > 0 && bookedList.map((re, k) => {
                                                let backend_date = new Date(re.demo_date)
                                                let backend_date_year = backend_date.getFullYear();
                                                let backend_date_month = backend_date.getMonth();
                                                let backend_day = backend_date.getDate();
                                                let is_booked = backend_date_year === fronted_date_year && backend_date_month === fronted_date_month && fronted_day === backend_day && slot.id === re.time_slot_id;
                                                if (is_booked) {
                                                    is_booked_top = true;
                                                    return <p className="booked_text">Booked</p>
                                                }

                                            })}

                                        </CalendarSlot>
                                    )
                                })}
                            </tr>
                        )
                    })}

                </tbody>
            </CalendarTable>
        </Wrapper>
    )
}



export default Index;
