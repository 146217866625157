import * as actions from '../action-types/overlay';

const initialState = {
    type: actions.OVERLAY_INIT,
    onClickFunc: undefined
};

const overlayReducer = (state, action) => {
	if (!state || typeof action.type === 'undefined') {
		return initialState;
    }

    switch (action.type) {
        case actions.OVERLAY_RAISE:

            return {
                type: action.type,
                onClickFunc: action.data
            };

        case actions.OVERLAY_HIDE:
            return {
                type: action.type,
                onClickFunc: action.data
            };

            // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
			return {
                type: state.type
            };
        }
};

export default overlayReducer;