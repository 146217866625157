import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getInsightsListByUser, getInsightsByInsightIdForOpenRoute, refreshInsight, saveInsight, clearInsights, setChangeInsightData, save_form_data } from '../../../actions/insights';
import { store_opr_credentials } from '../../../actions/account';
import Reports from '../../report-wrapper';
import {
    Wrapper,
    Title,
    NoInsightWrapper,
    CenterWrapper,
    AbsoluteWrapper,
    MainWrapper,
    HoverWrapper,
} from './insights.styled';
import * as _ from 'lodash';
import InsightReportEditor from './editor';
import * as enums from '../../../utils/enums.js';
import { compare_different_states_of_an_object, generatePdf, showPopup, generateUniqueKey, sort_report_list, get_dashboard_for_home_page, format_date, formatValueByDataType, create_report_nf_date_title, console_logger, find_command_type } from '../../../utils';
import Footer from '../../footer';
import { setActiveEditLayout } from '../../../actions/layout';
import { getParamByName, getParameterByName, showRighter, giveErrorTypeForInsights, deleteReportFormat, reportFormatToSave, makeCopyReport, url_for_share } from '../../../utils';
import { getDataFromReportingServer, reset_data_from_server } from '../../../actions/report';
import { apply_filters, } from '../../../actions/filter';
import { themeObject, bind_theme_data_to_object } from '../../../theme'
import ActionsButton from './header.action';
import FilterDisplay from '../../filter/filter.display';
import { FULL_MONTHS, MONTHS } from '../../../shared-with-fe/constants';
import { getDefaultInsightForDashboard, get_all_reports } from '../../../actions/report.helper';
import DashboardEditor from '../../dashboard.editor';
import DashboardVoiceEdit from '../../dashboard.editor/dashboard.edit.voice.js';
import Loading from '../../loading/index.js';

let wrapper_initial_height = 0;
let wrapper_initial_width = 0;
let print_height = 0
let input_edit_index = undefined
let map_expander_prev = {

};


let is_already_render = false

let index = 0;
const dates = {};

let k = generateUniqueKey('__');

const Insights = props => {

    const { getInsightsListByUser, setChangeInsightData, all_reports, insightsListData, user, hints, saveInsight, clearInsights, location, editRecordAnswers, filters } = props;

    const { search } = location;

    const idFromUrl = getParamByName(search, 'insightId');
    // const __nf__additional__from_URI = getParamByName(search, '__nf__additional__');

    const encodedStringFromUrl = search?.split("__nf__additional__=")[1]; // Extract encoded part
    const __nf__additional__from_URI = encodedStringFromUrl && JSON.parse(atob(encodedStringFromUrl)); // Base64 decode and parse JSON


    compare_different_states_of_an_object(props, k, true);

    const isNew = getParamByName(search, 'new');
    const isPrintMode = getParamByName(search, 'print_mode');
    const redirect_filters = getParameterByName('redirect_filters', search)

    const [insights, setInsights] = useState(undefined);

    const [activeInsight, setActiveInsight] = useState(undefined);
    const [wrapperHeight, setWrapperHeight] = useState(undefined);
    const [wrapperWidth, setWrapperWidth] = useState(undefined);
    const [proceed, setProceed] = useState(false);
    const containerRef = useRef(null);
    const mainContainerRef = useRef(null);
    const [isIncludeGap, setIncludeGap] = useState(true);
    const [print_start, set_print_start] = useState(false);
    const isMobile = window.screen.width < 700;

    const [IsLoading, setIsLoading] = useState(false)



    const [why_addition_filter, set_why_addition_filter] = useState(undefined)





    const all_report_item_ids = ((activeInsight?.report_items?.filter((r) => (r?.render_mode !== 'group' && r?.chart_type !== 'label' && r?.chart_type !== 'v' && r?.render_mode !== 'label' && !r?.union && r?.type !== 'maps')))?.map((r) => r.id) || [])

    useEffect(() => {


        const ___title___ = (why_addition_filter && create_report_nf_date_title(undefined, undefined, undefined, why_addition_filter, (activeInsight?.title || ''))) || activeInsight?.title;

        const page_info = {
            title: ___title___,//activeInsight && activeInsight.title,
            extraComponent: <ActionsButton
                {...props}
                activeInsight={activeInsight}
                isIncludeGap={isIncludeGap}
                setIncludeGap={setIncludeGap}
                page_height={print_height}
                is_hierarchy={activeInsight?.is_hierarchy_filter}
                print_start={print_start}
                set_print_start={set_print_start}
                IsLoading={IsLoading}
                widegt_ids={all_report_item_ids}

            />
        }
        props.dispatchHeaderInformation && props.dispatchHeaderInformation(page_info)
    }, [activeInsight, print_start, print_height, IsLoading, why_addition_filter])



    useEffect(() => {

        console_logger("use effect 2")

        if (activeInsight && activeInsight['report_items']) {

            change_report_item_height_if_required()
        }
    }, [print_start])



    useEffect(() => {

        setActiveInsight()

        const __nf__additional__from_URI_parsed = __nf__additional__from_URI

        if (__nf__additional__from_URI_parsed && Object.keys(__nf__additional__from_URI_parsed)?.length > 0) {
            set_why_addition_filter(__nf__additional__from_URI_parsed)
        }

        if (containerRef.current) {

            let height = containerRef.current.offsetHeight;

            if (wrapper_initial_height === 0) {
                wrapper_initial_height = height;
            }

            print_height = 2480 //
            print_height = (containerRef.current.offsetHeight || 0) * 1.414516129032258;

            wrapper_initial_width = containerRef.current.offsetWidth;
        }

        if (!isNew && user && user.id) {

            console.log("i am calling/.......", idFromUrl, all_reports?.length)

            props.getDefaultInsightForDashboard(idFromUrl)

            if (!all_reports || all_reports.length === 0) {
                setTimeout(() => {
                    props.get_all_reports(user.id, true)
                }, 1000);
            }
        }

        else if (props.share_mode === true) {
            // sample pathname: /client-view/med.solution/rpt_item_kjzfvq9lkjzfxb7m/sometokenishere

            const client_id = getParamByName(search, 'client_id');
            const insight_id = getParamByName(search, 'insightId');
            const token = getParamByName(search, 'token');

            setTimeout(() => {
                props.getDefaultInsightForDashboard(insight_id, client_id, token)
                // props.getInsightsByInsightIdForOpenRoute(client_id, insight_id, token);
            }, 500);
        }
        return () => {
            clearInsights();
        }

    }, [props?.history?.location?.search]);





    // this is added for only PRINT 
    useEffect(() => {
        if (isPrintMode && activeInsight) {
            const pathname = location && location.pathname;
            const file_name_from_url = getParamByName(search, 'file');
            setTimeout(async () => {
                // generatePdf('chart', 'download', (activeInsight && activeInsight.title ? activeInsight.title : "Dashboard"), 'inner_wrapper', undefined, file_name_from_url + '.pdf');
                generatePdf('chart', 'download', (activeInsight && activeInsight.title ? activeInsight.title : "Dashboard"), 'inner_wrapper', undefined, (file_name_from_url + ".pdf"), undefined, undefined, undefined, undefined, undefined, undefined, filters, undefined, print_height, true);

            }, 100000);
            // 55000
        }
    }, [props?.history?.location?.search, activeInsight]);



    useEffect(() => {


        if (containerRef.current) {

            let tempHeight = containerRef.current.offsetHeight;

            let width = containerRef.current.offsetWidth;
            let teLayoutEditormpHeight = ''

            const element = containerRef.current.getBoundingClientRect();

            const { y } = element;

            teLayoutEditormpHeight = tempHeight - y;

            setWrapperHeight(tempHeight);
            setWrapperWidth(width);
        }

    }, [props.defaultInsight]);



    useEffect(() => {

        if (props.defaultInsight && props.defaultInsight.id) {

            if (!idFromUrl) {
                props.history.push(`?insightId=${props.defaultInsight.id}`);
            }
            else if (idFromUrl == props.defaultInsight?.id) {
                convert_report_percentage_to_px(props.defaultInsight);

            } else {
                setActiveInsight(undefined)
            }

        } else {

            setActiveInsight(undefined)
        }

    }, [props.defaultInsight])






    const convert_report_percentage_to_px = (report) => {

        let finalReport = JSON.parse(JSON.stringify(report));

        if (report) {

            let wrapper_height = wrapper_initial_height;
            let wrapper_width = wrapper_initial_width;

            let tempReport = JSON.parse(JSON.stringify(report));

            const firstReportItem = (tempReport && tempReport.report_items && tempReport.report_items.length > 0) ? tempReport.report_items[0] : undefined;
            const shouldProceed = true //firstReportItem ? firstReportItem.top < 3 : false;

            if (shouldProceed) {

                tempReport['report_items'] && tempReport['report_items'].map((reportItem, index) => {

                    const top = reportItem.top ? parseFloat(reportItem.top * wrapper_height) : 0;
                    const left = reportItem.left ? parseFloat(reportItem.left * wrapper_width) : 0;
                    const width = reportItem.width ? parseFloat(reportItem.width * wrapper_width) : 0;
                    const height = reportItem.height ? parseFloat(reportItem.height * wrapper_height) : 0;

                    reportItem._top = reportItem.top;
                    reportItem._left = reportItem.left;
                    reportItem._width = reportItem.width;
                    reportItem._height = reportItem.height;

                    reportItem.top = top;
                    reportItem.left = left;
                    reportItem.width = width;
                    reportItem.height = height;

                })

                finalReport = tempReport;
                finalReport.which_format = 'px';

            }
            setActiveInsight(finalReport);
        };
    };


    const change_wrapper_height_if_required = () => {

        const wrapper_height_to_use = print_start ? print_height : wrapper_initial_height;

        if (activeInsight) {

            let biggest_element = 0;
            let b_el = undefined
            let total_number_of_page = 0;

            let reportItems = activeInsight["report_items"] ? activeInsight["report_items"] : [];
            reportItems && reportItems.length > 0 && reportItems.map((report, index) => {

                let _height = parseInt(report.height);
                let _top = parseInt(report.top);
                let element_height = parseInt(_height + _top);

                if (element_height > biggest_element) {
                    biggest_element = element_height;
                }
                if (total_number_of_page < report.pageIndex) {
                    total_number_of_page = report.pageIndex;
                }
            });

            let factor = parseInt(biggest_element / wrapper_initial_height) + 1;
            let extra_space_to_add = (parseInt(total_number_of_page) * 130) + 160;

            // console.log("biggest_element", wrapperHeight, wrapper_initial_height, biggest_element, activeInsight?.id)

            setWrapperHeight(biggest_element + (!isIncludeGap ? extra_space_to_add : 160));
            // setWrapperHeight(biggest_element - 260);
            setProceed(true);
        };
    };



    const _saveInsights = (reportItem, editId) => {

        const tempActiveInsight = Object.assign({}, activeInsight);

        const tempReportItem = Object.assign({}, reportItem);

        const tempReportItems = tempActiveInsight && tempActiveInsight.report_items && tempActiveInsight.report_items.length > 0 ? [...tempActiveInsight.report_items] : undefined;;

        let refresh = false;

        if (tempReportItem.isQuestionModified) {
            refresh = true;
        }

        tempReportItems && tempReportItems.length > 0 && tempReportItems.forEach((data, index) => {
            if (data.id === editId) {
                tempReportItems[index] = Object.assign({}, tempReportItem)
            }
        });

        const tempInsightMenu = all_reports && all_reports.length > 0 ? [...all_reports] : undefined;

        let changeMenu = false;

        if (tempInsightMenu && tempInsightMenu.length > 0) {
            tempInsightMenu && tempInsightMenu.length > 0 && tempInsightMenu.forEach(reportInMenu => {
                if (reportInMenu.id === tempActiveInsight.id) {
                    reportInMenu.report_items = [...tempReportItems];
                    changeMenu = true;
                }
            })
        }

        tempActiveInsight.report_items = [...tempReportItems];
        if (tempActiveInsight && tempActiveInsight.filter_default_value_json) {
            let filter_default_values = JSON.parse(tempActiveInsight.filter_default_value_json);
            props.apply_filters(filter_default_values)
        }

        setActiveInsight(tempActiveInsight);

        if (changeMenu) {
            setChangeInsightData(tempInsightMenu);
        }

        tempReportItem.modified = true;

        delete tempReportItem.column_meta_data;

        let tempFormatToSave = { ...tempActiveInsight };

        if (refresh) {
            tempFormatToSave.report_items = [];

            tempReportItem.xac = [];
            tempReportItem.yac = [];
            tempReportItem.comparisons = [];

            delete tempReportItem.isQuestionModified;
            delete tempReportItem.process_data;
            delete tempReportItem.error;
        }

        tempFormatToSave.report_items.push(tempReportItem);
        saveInsight(reportFormatToSave(tempFormatToSave), refresh);
    };



    useEffect(() => {

        console_logger("use effect 8")
        change_wrapper_height_if_required()

    }, [activeInsight, print_start])



    const click_onExpandMap = () => {

        let clone_report = activeInsight ? { ...activeInsight } : {};
        let clone_reports = (clone_report && clone_report.report_items && clone_report.report_items.length > 0) ? [...clone_report.report_items] : [];

        clone_reports && clone_reports.length > 0 && clone_reports.map((report, i) => {

            if ((report.chart_type == 'map') && (report.render_mode == 'map')) {

                if (map_expander_prev && map_expander_prev.mode !== 'expanded') {

                    map_expander_prev.width = report.width;
                    map_expander_prev.height = report.height;
                    map_expander_prev.top = report.top;
                    map_expander_prev.position = "absolute";
                    map_expander_prev.left = report.left;
                    map_expander_prev.zIndex = undefined;
                    map_expander_prev.expanded = true;

                    report.width = window.innerWidth;
                    report.height = window.innerHeight - 10;
                    report.position = "fixed";
                    report.top = 0;
                    report.left = 0;
                    map_expander_prev.zIndex = 10;
                    report.expanded = true;
                    map_expander_prev.mode = 'expanded';
                }

                else {

                    report.width = map_expander_prev.width;
                    report.height = map_expander_prev.height;
                    report.top = map_expander_prev.top;
                    report.left = map_expander_prev.left;
                    report.position = map_expander_prev.position;
                    report.expanded = false;
                    map_expander_prev = {}
                }

            }
        })

        clone_report.report_items = clone_reports;

        setActiveInsight(clone_report)
    }



    const change_report_item_height_if_required = () => {

        const clone_report = activeInsight ? { ...activeInsight } : {};
        const clone_reports = (clone_report && clone_report.report_items && clone_report.report_items.length > 0) ? [...clone_report.report_items] : [];
        const wrapper_height = wrapper_initial_height;
        const wrapper_width = print_start ? 1754 : wrapper_initial_width;

        if (clone_reports && clone_reports?.length > 0) {

            clone_reports.map((reportItem) => {

                const top = reportItem._top ? parseFloat(reportItem._top * wrapper_height) : 0;
                const left = reportItem._left ? parseFloat(reportItem._left * wrapper_width) : 0;
                const width = reportItem._width ? parseFloat(reportItem._width * wrapper_width) : 0;
                const height = reportItem._height ? parseFloat(reportItem._height * wrapper_height) : 0;

                reportItem.top = top;
                reportItem.left = left;
                reportItem.width = width;
                reportItem.height = height;
            })
            clone_report["report_items"] = clone_reports;
            setActiveInsight(clone_report)
        }
    }


    const expandChart = (id) => {

        let clone_report = activeInsight ? { ...activeInsight } : {};
        let clone_reports = (clone_report && clone_report.report_items && clone_report.report_items.length > 0) ? [...clone_report.report_items] : [];

        clone_reports && clone_reports.length > 0 && clone_reports.map((report, i) => {

            if (report.id == id) {

                if (map_expander_prev && map_expander_prev.mode !== 'expanded') {
                    map_expander_prev.width = report.width;
                    map_expander_prev.height = report.height;
                    map_expander_prev.top = report.top;
                    map_expander_prev.position = "absolute";
                    map_expander_prev.left = report.left;
                    map_expander_prev.zIndex = undefined;
                    map_expander_prev.expanded = true;
                    report.width = window.innerWidth;
                    report.height = window.innerHeight - 0;
                    report.position = "fixed";
                    report.top = 0;
                    report.left = 0;
                    map_expander_prev.zIndex = 999;
                    report.expanded = true;
                    map_expander_prev.mode = 'expanded';
                }

                else {

                    report.width = map_expander_prev.width;
                    report.height = map_expander_prev.height;
                    report.top = map_expander_prev.top;
                    report.left = map_expander_prev.left;
                    report.position = map_expander_prev.position;
                    report.expanded = false;
                    map_expander_prev = {}
                }
            }
        })

        clone_report.report_items = clone_reports;
        setActiveInsight(clone_report)
    }


    const insight_generic_date = props?.filters_cache?.[activeInsight?.id]?.['date_filter']?.['generic_date'];
    const filter_data = props?.filters_cache?.[activeInsight?.id]
    const isDeveloper = props?.user?.is_developer;


    const is_link_filter_enabled = activeInsight?.id && proceed && props.link_filter_cache?.[activeInsight?.id] && Object.keys(props.link_filter_cache?.[activeInsight?.id])?.length > 0;


    useEffect(() => {

        // console.log("props.global_filter_widgets_ids_cache", props.global_filter_widgets_ids_cache)
        const global_filter_widgets_ids = props.global_filter_widgets_ids_cache[activeInsight?.id];
        if (global_filter_widgets_ids?.length > 0) {
            setIsLoading(true)
        } else {
            setIsLoading(false)
        }

    }, [props.global_filter_widgets_ids_cache])






    const update_report_item = (item_id, properties) => {

        const clone_activeInsight = activeInsight ? { ...activeInsight } : {};
        const clone_report_items = clone_activeInsight?.report_items ? clone_activeInsight.report_items.map((item) => {
            if (item.id === item_id) {
                // let's update it
                // const is_chart_type_changed = item?.chart_type !== properties?.chart_type;
                // console.log("is_chart_type_changed", is_chart_type_changed, item?.chart_type, properties?.chart_type)
                return { ...item, ...properties };
            } else {
                return item; // return the original item if no update is needed
            }
        }) : [];

        clone_activeInsight.report_items = clone_report_items;
        setActiveInsight(clone_activeInsight)

    }


    const open_layout_editor = (reportItem, report_item_id) => {
        showRighter(<DashboardEditor
            hints={hints}
            reportItem={reportItem}
            report_item_id={report_item_id}
            save_fun={(id, properties) => {
                update_report_item(id, properties)
            }} />)
    }



    const change_layout_using_voice = (reportItem, report_item_id) => {

        showPopup('', undefined, enums.default.popupType.element, DashboardVoiceEdit, {
            reportItem: reportItem,
            report_item_id: report_item_id,
            save_fun: update_report_item
        },
            undefined,
            undefined,
            undefined, {

        },
        )
    };



    const advance_date_setting = props?.advance_date_setting_cache?.[activeInsight?.id]


    return (
        <MainWrapper
            id="main_wrapper"
            isMobile={isMobile}
            ref={mainContainerRef}
            style={{ zIndex: (map_expander_prev && map_expander_prev.expanded) ? '10' : undefined, }}
        >
            <div id="inner_wrapper" style={{
                width: print_start ? (1754 + "px") : '100%',
                height: isMobile ? undefined : (wrapperHeight && wrapperHeight > wrapper_initial_height) ? (wrapperHeight) : 'calc(100vh - 110px)',
            }}>

                {IsLoading && <Loading height="100vh" hideLoader={true} overLayColor={'#ffffff0d'} clickMsg={'Please wait'} />}

                {!IsLoading && ((!props.defaultInsight || props?.defaultInsight?.id !== idFromUrl) && idFromUrl) && <Loading height="100vh" msg="Seeking the Dashboard..." v2Loader={true} />}

                {activeInsight && (
                    <FilterDisplay
                        id={activeInsight?.id}
                        width="100%"
                        validation_key={activeInsight?.validation_keys?.['filter_key']}
                        insight={true}
                        widegt_ids={all_report_item_ids}
                    />
                )}

                <Wrapper
                    ref={containerRef}
                    id='Wrapper'
                    style={{
                        height: (wrapperHeight && wrapperHeight > wrapper_initial_height) ? (wrapperHeight - 110) : 'calc(100vh - 110px)',
                        marginTop: '1rem'
                    }}
                >
                    {
                        activeInsight && proceed && wrapperHeight && wrapperWidth && activeInsight.report_items && activeInsight.report_items.length > 0 ?
                            <React.Fragment>
                                {!is_already_render && activeInsight.report_items.map((reportItem, index) => {

                                    if (reportItem?.union) return;

                                    if (!dates[reportItem.id]) {
                                        dates[reportItem.id] = { index: 0, items: [] };
                                    }

                                    dates[reportItem.id].index++;
                                    const render_count_and_time = new Date().toLocaleTimeString() + '-' + (dates[reportItem.id].index);

                                    dates[reportItem.id].items.push(render_count_and_time);

                                    const processData = reportItem.process_data;
                                    const configMeta = reportItem;
                                    const title = configMeta && configMeta.title ? configMeta.title : '';
                                    const question = configMeta && configMeta.question;
                                    let renderMode = configMeta && configMeta.render_mode;
                                    let chartType = configMeta && configMeta.chart_type;

                                    const columnMeta = reportItem.column_meta_data;
                                    const show_ytd = reportItem.show_ytd;
                                    const show_mtd = reportItem.show_mtd;
                                    const pageIndex = reportItem && reportItem.pageIndex;
                                    const temp_top = configMeta && configMeta.top ? configMeta.top : 0;
                                    const margin_minus_top = pageIndex ? (parseInt(pageIndex) * 120) : 0;
                                    const top = isMobile ? 0 : (parseInt(temp_top)) + (!isIncludeGap ? margin_minus_top : 0) + "px";
                                    const top1 = isMobile ? 0 : (parseInt(temp_top)) + (!isIncludeGap ? margin_minus_top : 0) + "px";
                                    const left1 = isMobile ? 0 : configMeta && configMeta.left ? configMeta.left : 0;
                                    const left = isMobile ? 0 : configMeta && configMeta.left ? configMeta.left + 'px' : 0;
                                    const width = isMobile ? '100%' : configMeta && configMeta.width ? configMeta.width + "px" : 0;
                                    const height = isMobile ? (250 + "px") : configMeta && configMeta.height ? configMeta.height + "px" : 0;
                                    const insightWidth = isMobile ? (props.width - 20) : configMeta && configMeta.width ? configMeta.width : 0;
                                    const insightHeight = isMobile ? 250 : configMeta && configMeta.height ? configMeta.height : 0;
                                    const insightError = reportItem.error;
                                    const insightErrorType = giveErrorTypeForInsights(processData, columnMeta, insightError, configMeta && configMeta.question,);
                                    const element_type = reportItem.element_type ? reportItem.element_type : undefined;
                                    const expanded = reportItem && reportItem.expanded;
                                    const position = (reportItem && reportItem.position) ? reportItem.position : undefined;
                                    const report_item_additional_info = (reportItem && reportItem.report_item_additional_info) ? reportItem.report_item_additional_info : undefined;
                                    const db_info_id = reportItem && reportItem.db_info_id ? reportItem.db_info_id : undefined;
                                    const marker_type = reportItem && reportItem.marker_type ? reportItem.marker_type : undefined;
                                    const theme_json_values = (reportItem && reportItem.theme_json_values) ? reportItem.theme_json_values : {};
                                    const drill_down_type = (reportItem && reportItem.drill_down_type) ? reportItem.drill_down_type : undefined;
                                    const od_report_id = (reportItem && reportItem.od_report_id) ? reportItem.od_report_id : undefined;


                                    const is_group = chartType === 'group';
                                    const show_title = title && title.length > 0;
                                    const hide_table_header = (reportItem && reportItem.hide_headers) ? reportItem.hide_headers : undefined;


                                    const drill_down_report = drill_down_type && props.all_reports && props.all_reports.length > 0 && props.all_reports.filter((r) => {
                                        if (r.id === drill_down_type) {
                                            return true;
                                        } else return false;
                                    })


                                    const od_report = od_report_id && props.all_reports && props.all_reports.length > 0 && props.all_reports.find((r) => {
                                        if (r.id === od_report_id) {
                                            return true;
                                        } else return false;
                                    })



                                    const __theme__ = JSON.parse(JSON.stringify(themeObject));
                                    let theme = {};

                                    if (reportItem.render_mode) {
                                        if (reportItem.render_mode === 'chart' && chartType === 'single_cell') {
                                            theme = __theme__.insight["single_cell"];
                                        }
                                        else if (reportItem.render_mode === 'report') {
                                            theme = __theme__.insight['chart'];
                                        }
                                        else {
                                            theme = __theme__.insight[reportItem.render_mode];
                                        }
                                        bind_theme_data_to_object(theme, theme_json_values);
                                        // apply_color_sets_to_colors(theme, __theme__.colorset);
                                    }

                                    if (report_item_additional_info && report_item_additional_info.filter((f) => (f.axis_position === "Y Axis Primary" || f.axis_position === "Y Axis Secondary")).length > 1 && chartType === 'bar_chart') {
                                        // chartType = 'composed_bar_chart';
                                    }
                                    if (insightError) {
                                        console.warn('<==>', insightError);
                                    }

                                    if (reportItem && reportItem.xac && reportItem.xac.length > 2) {
                                        chartType = "table"
                                        renderMode = "table";
                                    }

                                    const id = configMeta ? configMeta.id : '';
                                    const objectCreated = _.clone(reportItem)
                                    objectCreated.config_meta = reportItem;

                                    const report_filter_info = reportItem.parameters;
                                    const drill_down_values = reportItem.drill_down_values;

                                    const dashboard_link_filter = props.link_filter_cache?.[activeInsight?.id];

                                    const dim_color = typeof dashboard_link_filter?.[id] !== 'undefined';

                                    const disable_options = dim_color ? {
                                        "refresh": true,
                                        "filter": true,
                                        "show_filter_info": true
                                    } : {}

                                    const validation_keys = reportItem?.validation_keys;
                                    const report_validation_keys = activeInsight?.validation_keys;
                                    const table_title_style = ((renderMode === 'table' && theme) && theme.table_title);
                                    const title_height = table_title_style?.size?.height || theme?.chart_title?.size?.height || '45px';
                                    const table_title_alignment = table_title_style?.font?.text_align || 'center';
                                    const z_index = (expanded ? 999 : (theme?.z_index && (parseInt(theme.z_index) > 100 ? 100 : theme.z_index) || 100));

                                    return (
                                        <HoverWrapper
                                            windowWidth={props.width}
                                            top={top}
                                            position={position ? position : undefined}
                                            left={left}
                                            key={'insights' + index}
                                            theme={theme}
                                            zIndex={z_index}
                                            isGroup={chartType === "group"}
                                        >
                                            <AbsoluteWrapper
                                                className={"insight_box"}
                                                windowWidth={props.width}
                                                top={top}
                                                position={position ? position : undefined}
                                                left={left}
                                                height={isMobile ? 'auto' : height}
                                                width={isMobile ? 'auto' : width}
                                                key={'insights' + index}
                                                theme={theme}
                                                hide_border={reportItem.hide_border}
                                                style={{
                                                    transition: 'all 0.5s',
                                                    border: !reportItem.hide_border ? (renderMode === 'label' || chartType === 'single_cell' || is_group) ? undefined : '1px solid #fff' : undefined,
                                                    position: isMobile ? "relative" : position,
                                                    marginBottom: isMobile ? '1rem' : undefined,
                                                    backgroundColor: dim_color ? '#dfebf3' : undefined,
                                                }}
                                            >

                                                {/* {z_index} */}
                                                {chartType !== 'group' ?
                                                    <React.Fragment>

                                                        {
                                                            chartType === 'label' ?
                                                                <Title
                                                                    hide_border={reportItem.hide_border}
                                                                    height={renderMode === 'label' ? insightHeight : '45px'}
                                                                    theme={theme}
                                                                    need_to_apply_height={false}
                                                                    font={true}
                                                                    style={{ height: '100%' }}
                                                                >  {create_report_nf_date_title(filter_data, advance_date_setting, insight_generic_date, why_addition_filter, title) || ''}  </Title>


                                                                :

                                                                <div>
                                                                    {show_title && !expanded && (
                                                                        <Title
                                                                            font={theme && (theme?.table_title || theme?.chart_title) ? true : undefined}
                                                                            hide_border={reportItem.hide_border}
                                                                            theme={(renderMode === 'table' && theme) ? theme?.table_title : theme?.chart_title}
                                                                            margin={chartType === 'single_cell' ? { margin: theme?.margin } : undefined}
                                                                            need_to_apply_height={true}
                                                                        // height={true}
                                                                        >
                                                                            {create_report_nf_date_title(filter_data, advance_date_setting, insight_generic_date, why_addition_filter, title) || ''}

                                                                        </Title>
                                                                    )}

                                                                    {isDeveloper ? <span
                                                                        style={{
                                                                            // textTransform: 'lowercase',
                                                                            color: '#fff',
                                                                            fontSize: '.9rem',
                                                                            backgroundColor: '#222',
                                                                            position: 'absolute',
                                                                            top: '0px',
                                                                            zIndex: 999,
                                                                            padding: '5px',
                                                                            display: 'inline-block',
                                                                            fontWeight: 'bold',
                                                                        }}>{id}</span> : null}
                                                                    {/* 
                                                                <div onClick={() => {
                                                                    open_layout_editor(reportItem, id)
                                                                }}>OPEN</div> */}

                                                                    <Reports
                                                                        open_layout_editor={() => {
                                                                            open_layout_editor(reportItem, id)
                                                                        }}
                                                                        change_layout_using_voice={() => {
                                                                            change_layout_using_voice(reportItem, id)
                                                                        }}
                                                                        id={id}
                                                                        disable_options={disable_options}
                                                                        insight_default_filter_values={activeInsight.filter_default_value_json ? JSON.parse(activeInsight.filter_default_value_json || "{}") : undefined}
                                                                        reportItemId={id}
                                                                        report_type={"dashboard"}
                                                                        insight_report_type={reportItem.report_item_type}
                                                                        insightChartType={chartType !== 'report' ? chartType : undefined}
                                                                        insightRenderMode={chartType !== 'report' ? renderMode : undefined}
                                                                        view_mode={expanded ? "expanded" : "collapsed"}
                                                                        insight_id={activeInsight?.id}
                                                                        report_item_report_id={reportItem?.report_id}
                                                                        is_global_filters={activeInsight.report_filter && activeInsight.report_filter.length > 0 ? activeInsight.report_filter : undefined}
                                                                        global_filter_keys={activeInsight?.report_filter?.length > 0 && (activeInsight.report_filter?.map((k) => k.column_name) || [])}
                                                                        click_onExpandMap={click_onExpandMap}
                                                                        expandChart={expandChart}
                                                                        expanded={expanded}
                                                                        question={question}
                                                                        user={user}
                                                                        hints={hints}
                                                                        reportTitle={create_report_nf_date_title(filter_data, advance_date_setting, insight_generic_date, title) || "Polus Report"}
                                                                        filters={props.filters}
                                                                        share_mode={props.share_mode}
                                                                        insights={true}
                                                                        insightWidth={insightWidth}
                                                                        insightHeight={insightHeight - (!expanded && show_title ? ((title_height && parseInt(title_height)) || 45) : 0)}
                                                                        wrapperHeight={wrapperHeight}
                                                                        wrapperWidth={wrapperWidth}
                                                                        is_group={reportItem.is_group}
                                                                        hide_label={reportItem.hide_label}
                                                                        hide_y_axis={reportItem.hide_y_axis}
                                                                        hide_x_axis={reportItem.hide_x_axis}
                                                                        hide_x_axis_secondry={reportItem?.hide_x_axis_secondry}
                                                                        hide_title={reportItem.hide_title}
                                                                        hide_x_axis_border={reportItem.hide_x_axis_border}
                                                                        hide_y_axis_border={reportItem.hide_y_axis_border}
                                                                        hide_grid={reportItem.hide_grid}
                                                                        hide_legend={expanded ? false : reportItem.hide_legend}
                                                                        hide_border={reportItem.hide_border}
                                                                        hide_tooltip={reportItem.hide_tooltip}
                                                                        hide_y_axis_label={reportItem.hide_y_axis_label}
                                                                        hide_x_axis_label={reportItem.hide_x_axis_label}
                                                                        hide_grand_total={reportItem.hide_grand_total}
                                                                        show_brush={reportItem.show_brush}
                                                                        show_percentage_total={reportItem.show_percentage_total}
                                                                        show_legend_as_percent={reportItem.show_legend_as_percent}
                                                                        show_label_in_legend={reportItem.show_label_in_legend}
                                                                        show_trend={reportItem.show_trend}
                                                                        show_data_label={reportItem.show_data_label}
                                                                        show_percentage_in_data_labels={reportItem.show_percentage_in_data_labels}
                                                                        show_prcentage_in_legends={reportItem.show_prcentage_in_legends}
                                                                        font_size={reportItem.font_size}
                                                                        font_weight={reportItem.font_weight}
                                                                        font_color={reportItem.font_color}
                                                                        bg_color={reportItem.bg_color}
                                                                        reportItem={reportItem}
                                                                        // showEditor={showEditor}
                                                                        chartAdditionalInfo={report_item_additional_info}
                                                                        windowWidth={props.width}
                                                                        isMobile={isMobile}
                                                                        db_info_id={db_info_id}
                                                                        marker_type={marker_type}
                                                                        theme_json_values={theme_json_values}
                                                                        drill_down_type={drill_down_type}
                                                                        drill_down_report={(drill_down_report && drill_down_report.length > 0) ? drill_down_report[0] : undefined}
                                                                        od_report={od_report ? od_report : undefined}
                                                                        od_report_id={od_report_id}
                                                                        hide_table_header={hide_table_header}
                                                                        running_total={reportItem.is_running_total}
                                                                        report_filter_info={report_filter_info}
                                                                        drill_down_values={drill_down_values}
                                                                        show_ytd={show_ytd}
                                                                        show_mtd={show_mtd}
                                                                        show_min={reportItem?.show_min}
                                                                        show_max={reportItem?.show_max}
                                                                        show_trend_line={reportItem?.show_trend_line}
                                                                        show_avg={reportItem?.show_avg}
                                                                        show_st_dev={reportItem?.show_st_dev}
                                                                        top={top1}
                                                                        left={left1}
                                                                        report_item_validation_keys={validation_keys}
                                                                        report_validation_keys={report_validation_keys}
                                                                        isMap={activeInsight.type === 'maps'}
                                                                        date_filter_columns={reportItem.date_filter_columns}
                                                                        refresh_frequency={reportItem?.frequency}
                                                                        isDashboardPrintMode={isPrintMode}
                                                                        why_addition_filter={why_addition_filter}
                                                                        isHighlightedMin={false}
                                                                    />
                                                                </div>
                                                        }
                                                    </React.Fragment> : null}
                                            </AbsoluteWrapper>
                                        </HoverWrapper>
                                    )
                                })}

                                <Footer
                                    id="footer_ignore_in_print"
                                    position="absolute"
                                    bottom={'50px'}
                                    isMobile={isMobile}
                                />

                            </React.Fragment>
                            :
                            <React.Fragment>
                                {/* <CenterWrapper>
                                    <NoInsightWrapper
                                        onClick={() => {
                                            props.history.push("/layout_editor")
                                        }}
                                    >
                                        <i className="fas fa-plus" />
                                        <div>
                                            Add Your Quick Insight
                                        </div>
                                    </NoInsightWrapper>
                                </CenterWrapper> */}
                                <Footer
                                    position="absolute"
                                    bottom="0px"
                                    isMobile={isMobile}
                                />
                            </React.Fragment>
                    }
                </Wrapper>
            </div>
        </MainWrapper>
    );
};


const mapStateToProps = state => {

    const { refreshInsightData, insightsListData, editRecordAnswers } = state.insightReducer;

    const all_reports = state.reportHelperReducer.all_reports;
    const defaultInsight = state.reportHelperReducer.defaultInsight

    const { filters_cache, global_filter_widgets_ids_cache } = state.filterReducer;
    const { width, height } = state.windowReducer;
    const { insightsMenu } = state.insightReducer;

    const drill_filter_cache = state?.filterReducer?.drill_filter_cache;
    const report_cell_clicked_info_cache = state.reportHelperReducer.report_cell_clicked_info_cache;
    const link_filter_cache = state.filterReducer.link_filter_cache;
    const advance_date_setting_cache = state?.filterReducer?.advance_date_setting_cache;

    return { global_filter_widgets_ids_cache, advance_date_setting_cache, defaultInsight, all_reports, link_filter_cache, drill_filter_cache, report_cell_clicked_info_cache, refreshInsightData, width, height, insightsListData, editRecordAnswers, filters_cache, insightsMenu }
};

export default withRouter(connect(mapStateToProps, { getDefaultInsightForDashboard, get_all_reports, reset_data_from_server, getInsightsListByUser, refreshInsight, saveInsight, setActiveEditLayout, clearInsights, setChangeInsightData, save_form_data, getDataFromReportingServer, getInsightsByInsightIdForOpenRoute, apply_filters, store_opr_credentials })(Insights));



