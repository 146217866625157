import * as actions from '../action-types/campaign'


const initialState = {
    type: actions.CAMPAIGN_INIT,
    adTechClients: undefined,
    adTechCampaign: undefined,
    adTechCampaignName: undefined,
    adTechMappingClientPlatform: undefined,
};

const CampaignReducer = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }


    switch (action.type) {

        case actions.GET_AD_TECH_CLIENTS:
            return {
                ...state,
                adTechClients: action.data
            }

        case actions.GET_AD_TECH_CAMPAIGN_MAPPINGS:
            return {
                ...state,
                type: action.type,
                adTechCampaignMappings: action.data
            }
        case actions.GET_AD_TECH_CAMPAIGN:
            return {
                ...state,
                type: action.type,
                adTechCampaign: action.data
            }
        
        case actions.GET_THE_MAPPING_AD_TECH_CLIENT:
            return {
                ...state,
                type: action.type,
                adTechMappingClientPlatform: action.data
            }
        default:
            return {
                ...state,
            }
    }
}

export default CampaignReducer;