import * as actionTypes from '../action-types/bread-crumb'

export const addBreadCrumb = breadCrumb =>  dispatch => {
    dispatch({
        type: actionTypes.BREAD_CRUMB_ADD,
        data: breadCrumb
    });
};

export const clearBreadCrumb = () =>  dispatch => {
    dispatch({
        type: actionTypes.BREAD_CRUMB_CLEAR
    });
};

export const switchBreadCrumb = (breadCrumb, breadCrumbIndex, history) =>  dispatch => {
    dispatch({
        type: actionTypes.BREAD_CRUMB_SWITCH,
        data: { index: breadCrumbIndex }
    });

    if (breadCrumb.reportId) {
        const url = `/dashboard?question=${breadCrumb.question}&id=${breadCrumb.reportId}&reportIndex=0`;
        history.push(url);
    }
    else {
        history.push('/');
    }
};
