import { generate_unique_key } from '../../utils';

export const TemplateTypes = {
    group: 1,
    table: 2,
    chart: 3,
};

export const GetDefaultObject = (template_type) => {

    switch(template_type) {
        case TemplateTypes.group:
            return get_group_object();
    }

    return get_default_object();
};


const get_default_object = () => {

    const report_item_default_object = {
        id: generate_unique_key("nf_sr"),
        is_created: true,
        question: '',
        parameters: '',
        xac: '',
        yac: '',
        title: '',
        entity_name: '',
        comparisons: '',
        chart_type: '',
        is_active: 1,
        width: .20,
        height: .20,
        display_columns: '',
        criteria: '',
        top: .40,
        left: .40,
        hide_y_axis_label: false,
        hide_x_axis_label: false,
        is_group: false
    }

    // clone and return 
    return JSON.parse(JSON.stringify(report_item_default_object))
};

const get_group_object = () => {

    const default_object = get_default_object();

    return {
        ...default_object,
        hide_y_axis_label: true,
        hide_x_axis_label: true,
        is_group: true
    };
};