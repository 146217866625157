import styled from 'styled-components';




export const TableSection = styled.div`
    width: 50%;
`;

export const ChartSection = styled.div`
    width: 50%;

`;

export const ChartSideWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    /* padding-top: 10px; */

    .apply_btn{
        border: 1px solid #9c8d8d;
        padding: 0 10px;
        margin-bottom: 6px;
        background: #d0d8e0;
        font-size: 0.8rem;
        color: #222;
        cursor: pointer;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
            opacity: 0.8;
            color: green;
        }
    }

    .i_btn{
        cursor: pointer;
        color: rgba(64, 62, 62, 0.79);
        font-size: 11px;
        background: #f1f3f5;
        width: 1rem;
        height: 1rem;
        text-align: center;
        line-height: 1rem;
        border-radius: 50%;
        border: 1px solid #ccc;

        &:hover{
            opacity: 0.8;
        }
    }

    .filter_btn{
        position: absolute;
        right: 15px;
    }
`;



export const ScrollWrapper = styled.div`
    overflow: hidden;
    max-width: ${props => props.maxWidth ? `${props.maxWidth}px` : ''};
    width:100%;
    /* display:flex;
    justify-content: flex-end; */
    /* background: #fff; */
  

    &::-webkit-scrollbar {
        background-color:#fff;
        width:16px
    }
        &::-webkit-scrollbar-track {
        background-color:#fff
    }
    &::-webkit-scrollbar-track:hover {
        background-color:#f4f4f4
    }
    
       &::-webkit-scrollbar-thumb {
        background-color:#babac0;
        border-radius:16px;
        border:5px solid #fff
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color:#a0a0a5;
        border:4px solid #f4f4f4
    }
    
    &::-webkit-scrollbar-button {display:none}

    .show{
        display: none;
    }

    &:hover{
        .show{
            display: block;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    };

    /* .filter_area{
        opacity: 0;
    } */
   
    

`;


export const SelectLable = styled.div`
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: #000;
    margin-bottom: 5px;
    padding-left: 2px;
`;


export const ReferenceShowButton = styled.div`
   display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-size: .8rem;
    font-weight: 500;
    right: 1rem;
    top: -.4rem;

`;