import React, { useRef, useState } from "react";
import styled from "styled-components";
import { CheckBox } from "../checkbox";
import { NormalButton } from "../../hoc/normal.button";

const BottomDropDownMenu = styled.div`
  background: #fff;
  position: absolute;
  top: -20px;
  width: ${ props => props.width || '220px'};
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%),
    0 3px 1px -2px rgb(0 0 0 / 20%);
  right: 112%;
  box-sizing: border-box;
  z-index: 50;
  padding: 0 4px;
  max-height: 20rem;
  overflow: auto;
  &::-webkit-scrollbar {
      background-color: #3F3F3F;
      width:4px;
  }

  &::-webkit-scrollbar-track {
      background-color: #F3F3F3;
      border: none;
  }

  &::-webkit-scrollbar-thumb {
      background-color: #dbdbdb;
      border-radius: 10px;
      height: 4px;
  }
  &::-webkit-scrollbar:horizontal{
      background-color:  #3F3F3F;
      height: 4px;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${ props => !props.isCheckable ? "10px 6px 10px 16px" : '10px 6px'};
    text-align: left;
    font-size: 0.9rem;
    border-radius: 6px;

    span {
      margin-right: 0.3rem;
    }

    svg {
      margin: 0 0.4rem;
      display: none;
    }

    &:hover {
      background: #dee6ff;
      cursor: pointer;
      border-radius: 6px;

      svg {
        display: block;
      }
    }

}
hr {
  border-color: #f3f3f340;
  margin: 0;
  padding: 0;
}
  .addList {
    display: flex;
    flex-direction: row;
    background: #cfe3fa;
    margin: 4px 0;
    padding: 4px;
    border-radius: 6px;
    input {
      width: 68%;
      border: none;
      background: #cfe3fa;
      &:focus-visible{
        outline: none;
      }
    }
  }
`;

const DropdownMenuList = ({
    isOpen = false,
    dataList,
    onCheck,
    checkedKeys,
    handleAdd,
    handleDelete,
    fieldNames={ label: 'label', key: 'key'}
}) => {

    const [inputValue, setInputValue] = useState('')

    return (
        <>
            {isOpen ? (
                <BottomDropDownMenu  isCheckable={!!onCheck}>
                    {dataList?.length &&
                        dataList.map((item) => (
                            <>
                                <div
                                    key={"manu_item" + item?.[fieldNames?.key]}
                                    className="item"
                                    onClick={(e) => { 
                                        e.stopPropagation()
                                    }}
                                >
                                    <div>
                                        {onCheck && <span>
                                            <CheckBox
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onCheck(item?.[fieldNames?.key]);
                                                }}
                                                checked={checkedKeys?.includes(item?.[fieldNames?.key])}
                                            />
                                        </span>}

                                        {item?.[fieldNames?.label]}
                                    </div>

                                   {/* {handleDelete && <Close height="0.6rem" size="0.6rem" color="#000000"  onCheck={()=>handleDelete(item?.[fieldNames?.key])}/>} */}
                                </div>
                                <hr></hr>
                            </>
                        ))}
                    {handleAdd && <div className="addList">
                        <input
                            type="text"
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={(e) => {
                                e.stopPropagation()

                            }}
                            onKeyUp={(e) => {
                                e.stopPropagation()

                            }}
                            placeholder="Type..."
                            title="Click to add"
                            onChange={(e) => {
                                e.preventDefault()
                                setInputValue(e.target.value)
                            }}
                        ></input>
                        <NormalButton
                            label="Add"
                            padding=".4rem"
                            height="2.1rem"
                            width='4rem'
                            bgColor="#337DFF"
                            hoverBg={"#337DFF"}
                            //   mR=".5rem"
                            onClick={(e) => {
                                handleAdd(inputValue);
                            }}
                            color="#fff"
                        />
                    </div>}
                </BottomDropDownMenu>
            ) : (
                <></>
            )}
        </>
    );
};

export default DropdownMenuList;
