import React, { useEffect, useRef, useState } from 'react'
import ChartWrapper from '../chart-wrapper';
import Table from '../table-wrapper';
import SingleCell from '../single-answer';
import { CHART_TYPES, RENDER_MODE } from '../../shared-with-fe/constants';
import { ChartSideWrapper, TableSection, ChartSection } from './styled';
import styled from 'styled-components';
import GreaterThenSvg from '../svg/grater.then';
import { connect } from 'react-redux';
import { raiseMenuConfig } from '../../actions/menu.config.righter'
import { set_expanded_section, set_table_data_mode } from '../../actions/helper'
import YtdMtdComponent from './ytd.mtd'
import ChartOtherSettings from './chart.other.setting';

import { CHART_CONSTANTS } from '../../utils/chart.config';
import InsightSingleData from '../insight-single-data';
import SmallBreadCrumb from '../bread-crumb/small.breadCrumb';
import AutoDescriptionPopup from './auto.description.popup';
import { showPopup } from '../../utils';
import enums from '../../utils/enums';
import TowerChart from '../charts/tower-chart';


const SmallGButton = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #eaeaea;
    svg{
        fill: #222;
    }
    &:hover{
        background-color: #eaeaea;
    }
`;


const BlankScreen = styled.div`
    width: 100%;
    height: ${props => props.height ? props.height + "px" : '100%'};
    font-size: 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #222;
`;


const ViewSmall = styled.div`
    width: 100%;
    height: 100%;;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    background-color: #fff;
    z-index: 2;
    font-size: 1rem;
    font-weight: 500;
    text-transform: capitalize;
    color: #8b8888;
    font-style: italic;
`;


const Tab = styled.div`
    height: 40px;
    padding: 0px 1rem;
    font-size: .9rem; 
    font-weight: 500;
    color: ${props => props.active ? "#005d86" : '#666'};
    background-color: ${props => props.active ? "#fff" : 'transparent'};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-right: 1px solid #e8eaed;
    box-shadow: 0 1px 3px 1px rgb(60 64 67 / 15%);
    &:hover{
        background-color: ${props => !props.active ? '#e8eaed' : undefined}
    }
`;


let __init__ = undefined;



const NormalView = (props) => {



    const {
        row_data_information,
        pivot_data_information,
    } = props;






    const {


        childDimension,
        ultimate_yacs,
        report_section_height,
        report_section_width,
        showSingleCell,
        isTrendFilters,
        insights,
        isDiscovery,
        renderMode,
        chartType,
        getDataFromReportingServer,
        dimensions,
        chartXDataKeys,
        loading,
        tableDataMode = "pivot_data",
        xDataKeys,
        yDataKeys,
        pivot_data_ultimate_yacs,
        pivot_data_grand_total,
        id,
        pivot_axis,
        raw_axis,
        pivot_data,
        raw_data,
        expandCommand,
        isHighlightedMin

    } = props;


    const {
        expandedSection,
        set_expanded_section,
    } = props;




    /***
     * new region code started here
     * by saroj kumar
     * 11 jan 2022
     */


    const tableWrapperRef = useRef(null)
    const chartWrapperRef = useRef(null)


    const [tableStyle, setTableStyle] = useState(undefined);
    const [chartStyle, setChartStyle] = useState(undefined);
    const [scale, set_scale] = useState(1);
    const [localBreadCrumbData, setLocalBreadCrumbData] = useState(undefined)
    const [show_data_label, set_show_data_label] = useState(props.show_data_label)



    useEffect(() => {
        console.log("RRRR", props)
        set_show_data_label(props.show_data_label)
    }, [props.show_data_label])

    /**
     * this useEffect for 
     * when render mode=chart and pivot data are available then 
     * this code will set the chart window full width
     */

    const pivot_yac = pivot_axis?.yac;
    const pivot_xac = pivot_axis?.xac;


    useEffect(() => {

        const is_chart_expanded = expandedSection && expandedSection.section_name === 'chart';
        const is_table_expanded = expandedSection && expandedSection.section_name === 'table';

        if (expandCommand) {
            expand_clicked(expandCommand.subtype, 'dhd')

        }
        else if ((!__init__ || (__init__ !== props.responseId)) || (props.is_pivot_apply_clicked && is_table_expanded)) {


            if (((pivot_yac && pivot_yac.length > 0) || chartType === "gantt_chart") && ((pivot_xac && pivot_xac.length <= 3) || (chartType === "gantt_chart" && pivot_xac?.length >= 3)) && pivot_data && (pivot_data.length > 0 && renderMode === 'chart')) {

                const pivot_xac_indexes_count = ((pivot_data?.length || 0) * pivot_xac?.length);

                if (props?.chartType === CHART_TYPES.scatter_chart && pivot_xac_indexes_count > CHART_CONSTANTS.MAX_SCATTER_DATA_LIMIT) {
                    expand_clicked("table", 'dhd')
                }
                else if (pivot_xac_indexes_count > CHART_CONSTANTS.MAX_CHART_DATA_LIMIT && props?.chartType !== CHART_TYPES.scatter_chart) {
                    expand_clicked("table", 'dhd')
                }
                else {
                    set_expanded_section({ section_name: '', level: '' }, id)
                    expand_clicked("chart", 'dhd')
                }
                __init__ = props.responseId;
            }

            // yDataKeys?.length < CHART_CONSTANTS.MAX_Y_AXIS && xDataKeys?.length < CHART_CONSTANTS.MAX_X_AXIS
            else {
                // set table full width 
                set_expanded_section({ section_name: '', level: '' }, id)
                expand_clicked("table", 'dhd')
                __init__ = props.responseId;
            }
        } else {

            const w = report_section_width / 2;
            const name = expandedSection && expandedSection.section_name;
            const level = expandedSection && expandedSection.level ? expandedSection.level === 'one' ? 'nothing' : 'one' : 'one';
            if (name) expand_clicked(name, level)

            else {
                set_table_wrapper_dimensions(w)
                set_chart_wrapper_dimensions(w)
                set_expanded_section({
                    section_name: '',
                    level: ''
                }, id)
            }
        }
    }, [renderMode, pivot_axis, expandCommand])


    useEffect(() => {
        setLocalBreadCrumbData(props.drill_down_data)
    }, [props.drill_down_data])


    useEffect(() => {
        const name = expandedSection && expandedSection.section_name;
        const level = expandedSection && expandedSection.level ? expandedSection.level === 'one' ? 'nothing' : 'one' : 'one';
        if (name) expand_clicked(name, level)

    }, [report_section_width])


    const set_chart_wrapper_dimensions = (w, h) => {
        if (chartWrapperRef && chartWrapperRef.current) {
            chartWrapperRef.current.style.width = w + "px"
            chartWrapperRef.current.style.height = h + "px"
            setChartStyle({
                ...chartStyle,
                width: w,
                height: h,
            })
        }
    }


    const set_table_wrapper_dimensions = (w, h) => {
        if (tableWrapperRef && tableWrapperRef.current) {
            tableWrapperRef.current.style.width = w + "px"
            tableWrapperRef.current.style.height = h + "px"
            setTableStyle({
                ...tableStyle,
                width: w,
                height: h,
            })
        }
    }


    const expand_clicked = (name, _level) => {

        if (chartType === 'table' && !insights) {
            props.changeChartType('bar_chart')
        }

        const level = _level ? _level : (expandedSection) ? expandedSection.level : undefined;
        if (name === 'table') {
            // if (level === 'one') {
            //     const w = report_section_width / 2
            //     set_table_wrapper_dimensions(w)
            //     set_chart_wrapper_dimensions(w)
            //     set_expanded_section({
            //         section_name: name,
            //         level: undefined,
            //     }, id)
            // }
            // else {
            const w = report_section_width - 50
            set_table_wrapper_dimensions(w)
            set_chart_wrapper_dimensions(50)
            set_expanded_section({
                section_name: name,
                // level: 'one'
            }, id)
            // }
        }

        if (name === 'chart') {
            // if (level === 'one') {
            //     const w = report_section_width / 2;
            //     set_table_wrapper_dimensions(w)
            //     set_chart_wrapper_dimensions(w)
            //     set_expanded_section({
            //         section_name: name,
            //         level: undefined,
            //     }, id)
            //     // then switch to level two
            // }
            // else {
            const w = report_section_width - 50;
            set_chart_wrapper_dimensions(w)
            set_table_wrapper_dimensions(50)

            set_expanded_section({
                section_name: name,
                // level: 'one'
            }, id)
            // }
        }
    }




    const { raw_data_grand_total } = props;

    // const pivot_axis = get_pivot_formatted_axis(pivot_data_information?.pivot_data_axis);


    const table_data = tableDataMode === 'pivot_data' ? pivot_data : row_data_information?.data;

    const table_xac = tableDataMode === 'pivot_data' ? pivot_axis?.xac : raw_axis?.xac;
    const table_yac = tableDataMode === 'pivot_data' ? pivot_axis?.yac : raw_axis?.yac;
    const table_alignments = tableDataMode === 'pivot_data' ? props.pivot_column_aligments : props.raw_column_aligments;
    const table_grand_total_columns = tableDataMode === 'pivot_data' ? pivot_data_grand_total : raw_data_grand_total;
    // yac_totals


    const show_chart_to_expand = expandedSection && expandedSection.section_name === 'table'
    const show_table_to_expand = expandedSection && expandedSection.section_name === 'chart'
    const process_data_updated_at = tableDataMode === 'pivot_data' ? undefined : props.process_data_updated_at

    const correlation_factor = props?.dataFromServer?.correlation_factor;
    const is_scatter_chart = props?.dataFromServer?.config_meta?.chart_type === "scatter_chart";


    const data_mode_options = [
        {
            value: 'raw_data',
            name: 'Raw Data'
        },
        {
            value: 'pivot_data',
            name: 'Pivot Data'
        }
    ];



    const open_auto_description = () => {

        console.log("PORPS", props)



        showPopup('', undefined, enums.popupType.element, AutoDescriptionPopup, {
            pivot_data: props?.pivot_data,
            pivot_axis: props?.pivot_axis,
            columnMeta: props?.pivot_column_aligments,
            file_path: props?.be_file_path,
            id: props?.reportId,
            raw_data: props?.raw_data,
            x: props?.row_data_information?.xac,
            y: props?.row_data_information?.yac,
            yac_totals: props?.row_data_information?.yac_totals

        },
            undefined,
            undefined,
            undefined, {

        },
        )

    }





    return (
        <ChartSideWrapper
            style={{ flexDirection: 'row' }} id="normal_view_slide_wrapper">
            {expandedSection && expandedSection.level !== 'one' && !showSingleCell && !loading && ((table_data && table_data.length > 0) || (pivot_data && pivot_data.length > 0)) && (
                <div style={{
                    position: 'absolute',
                    right: '0%',
                    left: '0%',
                    width: '100px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    margin: 'auto',
                    top: '-60px',
                    zIndex: 2,
                    transition: 'all .3s'
                }}>


                    {/* <div style={{ position: "relative", top: "60px" }}>
                        <SmallGButton style={{ marginLeft: '1.6rem' }} onClick={(e) => {
                            expand_clicked("table")
                        }}>
                            <GreaterThenSvg size="1rem" height="1rem" style={{
                                transform: expandedSection && expandedSection.section_name === 'table' && expandedSection.level === 'one' ? 'rotate(180deg)' : undefined
                            }} />
                        </SmallGButton>
                        <SmallGButton
                            style={{ marginLeft: '-0.1rem' }}
                            onClick={(e) => {
                                expand_clicked("chart")
                            }}>
                            <GreaterThenSvg size="1rem" height="1rem" style={{
                                transform: expandedSection && expandedSection.section_name === 'chart' && expandedSection.level === 'one' ? undefined : 'rotate(180deg)'
                            }} />
                        </SmallGButton>
                    </div> */}

                </div>
            )}

            {(!showSingleCell || props.chartType === 'gauge_chart') && !loading && (
                <React.Fragment>
                    <TableSection
                        id={"table_section_wrapper"}
                        ref={tableWrapperRef}
                        style={{
                            width: '50%', position: 'relative', transition: 'all .3s',
                            minHeight: report_section_height + "px",
                        }}
                    >



                        {show_table_to_expand && (
                            <ViewSmall onClick={() => expand_clicked("table")}>Click To Expand Table</ViewSmall>
                        )}

                        {!show_table_to_expand && (!showSingleCell) && !isTrendFilters && tableStyle &&
                            <Table
                                {...props}
                                sortedData={table_data}
                                yDataKeys={table_yac}
                                xDataKeys={table_xac}
                                ultimate_yacs={ultimate_yacs}
                                width={tableStyle && tableStyle.width}
                                height={report_section_height}
                                insights={insights || isDiscovery}
                                columnsAligments={table_alignments}
                                restricted_view={props.restricted_view}
                                dimensions={(dimensions && dimensions.length > 0) ? dimensions : undefined}
                                // grandTotalColumns={table_grand_total}
                                table_grand_total_columns={table_grand_total_columns}
                                process_data_updated_at={process_data_updated_at}
                            />
                        }
                    </TableSection>
                    <ChartSection
                        id={"chart_section_wrapper"}
                        ref={chartWrapperRef}
                        style={{
                            transition: 'all .3s',
                            position: 'relative',
                            minHeight: report_section_height + "px",
                            overflow: 'hidden',
                            // backgroundColor: 'red'
                        }}
                    >



                        {show_chart_to_expand && (
                            <ViewSmall style={{ zIndex: 1 }} onClick={() => expand_clicked("chart")}>Click To Expand Chart</ViewSmall>
                        )}



                        {
                            <div style={{
                                marginTop: '3px',
                                width: '100%',
                                marginLeft: (expandedSection?.section_name === "chart" && expandedSection?.level === "one") ? "0px" : "30px"
                            }}
                            >
                                <SmallBreadCrumb
                                    id={id}
                                    fontSize={".9rem"}
                                />
                            </div>

                        }


                        {(!pivot_data || pivot_data && pivot_data.length === 0) && !show_chart_to_expand && (!pivot_xac || (pivot_xac && pivot_xac.length === 0)) && props.chartType !== CHART_TYPES.tower_chart &&
                            <BlankScreen height={report_section_height}>
                                <div
                                    onClick={() => {
                                        set_scale(0.8);
                                        setTimeout(() => {
                                            set_scale(1)
                                            props.raiseMenuConfig("PivotTable Fields", 'column_chooser', id)
                                        }, 200);
                                    }}
                                    style={{
                                        fontSize: '70px',
                                        cursor: 'pointer',
                                        transition: 'transform 200ms',
                                        transform: 'scale(' + scale + ')'
                                    }}
                                >
                                    +
                                </div>
                                <div
                                    onClick={() => {
                                        setTimeout(() => {
                                            set_scale(1)
                                            props.raiseMenuConfig("PivotTable Fields", 'column_chooser', id)
                                        }, 200);
                                    }}
                                >
                                    Please Click Here to Add Pivot Fields
                                </div>

                            </BlankScreen>
                        }


                        {correlation_factor && <div
                            style={{
                                marginLeft: 'auto',
                                fontSize: '1rem',
                                position: 'absolute',
                                right: '1.5rem',
                                top: '10px',
                                cursor: 'auto'
                            }}
                        >Correlation Factor :&nbsp;
                            {correlation_factor ? parseFloat(correlation_factor).toFixed(2) : correlation_factor}
                        </div>}



                        {renderMode === 'chart' &&
                            <React.Fragment>

                                {/* this code is added for normal view */}
                                {!is_scatter_chart && !show_chart_to_expand && props.reportType !== 'config_query_builder' && (
                                    <div className='hidden_default' style={{ marginLeft: 'auto', position: 'absolute', right: '10px', zIndex: 2 }}>
                                        <YtdMtdComponent
                                            toggle_aggregate_option={props.toggle_aggregate_option}
                                            aggregate_options={props.aggregate_options}
                                            show_aggregate_option={true}
                                            show_min={true}
                                            show_max={true}
                                            show_avg={true}
                                            show_st_dev={true}
                                            show_trend_line={true}
                                            show_data_label={show_data_label}
                                            set_show_data_label={set_show_data_label}
                                            open_auto_description={open_auto_description}
                                        />
                                        {/* <ChartOtherSettings
                                            show_data_label={show_data_label}
                                            set_show_data_label={set_show_data_label}
                                            open_auto_description={open_auto_description}
                                        /> */}
                                    </div>
                                )}

                                {
                                    props.chartType === CHART_TYPES.tower_chart ? (
                                        <TowerChart
                                            data={row_data_information?.data}
                                            xAxis={[...raw_axis?.xac, ...raw_axis?.yac]}
                                            width={chartStyle && chartStyle.width}
                                            height={report_section_height}
                                            columnMeta={table_alignments}
                                            id={props.id}
                                            theme_json_values={props.theme_json_values}
                                            isHighlightedMin={isHighlightedMin}
                                        />
                                    ) :
                                        <ChartWrapper
                                            {...props}
                                            data={props?.is_correlation ? raw_data : (pivot_data && pivot_data.length > 0 ? pivot_data : undefined)}
                                            yDataKeys={pivot_axis?.yac ? pivot_axis?.yac : undefined}
                                            xDataKeys={pivot_axis?.xac ? pivot_axis?.xac : undefined}
                                            widthOfChart={chartStyle && chartStyle.width}
                                            getDataFromReportingServer={getDataFromReportingServer ? getDataFromReportingServer : () => { }}
                                            heightOfChart={report_section_height}
                                            dimensions={(dimensions && dimensions.length > 0) ? dimensions : undefined}
                                            isTop={chartXDataKeys && chartXDataKeys.length > 0 ? true : undefined}
                                            pivot_data_ultimate_yacs={pivot_data_ultimate_yacs}
                                            columnsAligments={props.pivot_column_aligments}
                                            breadCrumbData={localBreadCrumbData}
                                            show_data_label={show_data_label}

                                        />
                                }


                            </React.Fragment>
                        }
                    </ChartSection>
                </React.Fragment>
            )}

            {
               (showSingleCell && props.chartType !== 'gauge_chart') && !loading && !childDimension &&
                <React.Fragment>
                    <div style={{ width: '100%', height: report_section_height + 'px' }}>
                        <div style={{ marginTop: '3px', width: '100%', marginLeft: '0px' }}>
                            <SmallBreadCrumb
                                id={id}
                                fontSize={".9rem"}
                            />
                        </div>

                        {!insights && (
                            <SingleCell
                                {...props}
                                heightOfChart={report_section_height}
                                // columnsAligments={ultimate_yacs}
                                fontSize={".9rem"}
                                singleCellData={props.singleCellData}
                                columnsAligments={props?.pivot_column_aligments || props.raw_column_aligments}

                            />
                        )}

                        {insights && (
                            <div style={{
                                height: report_section_height + "px",
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                                <InsightSingleData
                                    {...props}
                                    sortedData={table_data}
                                    yDataKeys={table_yac}
                                    xDataKeys={table_xac}
                                    singleData={props.singleCellData}
                                    columnsAligments={props?.pivot_column_aligments || props.raw_column_aligments}

                                />
                                <div style={{ width: '100%', display: 'flex', }}>
                                    {data_mode_options && data_mode_options.length > 0 && data_mode_options.map((d, index) => {
                                        return (
                                            <Tab key={"tableDataMode" + index} onClick={() => {
                                                props.set_table_data_mode(d.value, id)
                                            }} active={tableDataMode && tableDataMode === d.value}>{d.name}</Tab>
                                        )
                                    })}
                                </div>
                            </div>

                        )}

                    </div>
                </React.Fragment>
            }
        </ChartSideWrapper>
    )
}





const mapStateToProps = (state, props) => {

    const expanded_section_cache = state.helperReducer.expanded_section_cache;
    const table_d_mode = state.helperReducer.tableDataMode;
    const tableDataMode = table_d_mode && table_d_mode[props.id];
    const expandedSection = expanded_section_cache && expanded_section_cache[props.id];
    const drill_down_data = state.reportReducer.drill_down_data && state.reportReducer.drill_down_data[props.id];

    return { expandedSection, tableDataMode, drill_down_data }
}


export default connect(mapStateToProps, { set_table_data_mode, raiseMenuConfig, set_expanded_section })(NormalView);