import * as actions from '../action-types/report.helper';

const initialState = {
    type: actions.REPORT_HELPER_INIT,
    editReport: undefined,
    report_cell_clicked_info_cache: {},
    all_reports: undefined,
    all_report_cache: undefined,
    defaultInsight: undefined,
    dataSources: undefined,
    editCurrentReport: undefined,
};


const reportHelperReducer = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }


    switch (action.type) {
        case actions.SET_ACTIVE_EDIT_REPORT_SUCCESS:
            const tempData = action.data;
            let dataToStore = undefined;

            if (tempData && tempData.all_reports) {
                // Taking first Element
                const reports = tempData && tempData.all_reports;

                if (reports) {
                    dataToStore = reports[0]
                }
            }
            else if (tempData) {
                dataToStore = Object.assign({}, tempData);
            }

            else {
                dataToStore = undefined;
            }

            return {
                ...state,
                type: action.type,
                editReport: dataToStore
            };


        case actions.GET_REPORT_BY_ID_V1:

            return {
                ...state,
                type: action.type,
                editCurrentReport: action?.data?.report

            }
        case actions.CLEAR_EDIT_CURRENT_REPORT_SUCCESS_V1:
            return {
                ...state,
                type: action.type,
                editCurrentReport: undefined

            }

        case actions.CLEAR_ACTIVE__EDIT_REPORT:
            return {
                ...state,
                type: action.type,
                editReport: undefined
            }

        case actions.REPORT_CELL_CLICKED_INFO_V2:
            const clone_report_cell_info_cache = state.report_cell_clicked_info_cache ? JSON.parse(JSON.stringify(state.report_cell_clicked_info_cache)) : {};
            const report_id = action?.data?.report_id;
            const insight_id = action?.data?.insight_id
            const cell_data = action?.data?.cell_data;

            if (!clone_report_cell_info_cache[insight_id]) clone_report_cell_info_cache[insight_id] = {};
            clone_report_cell_info_cache[insight_id][report_id] = cell_data;

            return {
                ...state,
                type: action.type,
                report_cell_clicked_info_cache: clone_report_cell_info_cache
            }


        case actions.CELAR_REPORT_CELL_INFO_V2:

            const clone_report_cell_info_cache1 = state.report_cell_clicked_info_cache ? JSON.parse(JSON.stringify(state.report_cell_clicked_info_cache)) : {};
            const insight_id1 = action?.data?.insight_id;
            delete clone_report_cell_info_cache1[insight_id1];

            return {
                ...state,
                type: action.type,
                report_cell_clicked_info_cache: clone_report_cell_info_cache1
            }


        case actions.MARK_REPORT_FOR_NO_REFRESH:

            const clone_report_cell_info_cache2 = state.report_cell_clicked_info_cache ? JSON.parse(JSON.stringify(state.report_cell_clicked_info_cache)) : {};

            const insight_id2 = action?.data?.insight_id;
            const report_id2 = action?.data?.report_id;

            if (clone_report_cell_info_cache2[insight_id2]) {

                const all_report_ids = clone_report_cell_info_cache2[insight_id2] && Object.keys(clone_report_cell_info_cache2[insight_id2]) || [];

                const remove_filter_index = all_report_ids?.indexOf(report_id2);

                all_report_ids?.forEach((id, index) => {
                    if (remove_filter_index < index) clone_report_cell_info_cache2[insight_id2][id].need_to_refresh = true;
                    else clone_report_cell_info_cache2[insight_id2][id].need_to_refresh = false;
                })

                // target report filter will delete clone_report_cell_info_cache2
                delete clone_report_cell_info_cache2[insight_id2][report_id2];
            }




            return {
                ...state,
                type: action.type,
                report_cell_clicked_info_cache: clone_report_cell_info_cache2
            }



        case actions.GET_ALL_REPORT_SUCCESS_V1:

            return {
                ...state,
                type: action.type,
                all_reports: action?.data?.all_reports
            }

        case actions.CLEAR_ALL_REPORTS:

            return {
                ...state,
                type: action.type,
                all_reports: undefined
            }

        case actions.GET_LATEST_DASHBOARD_FOR_INSIGHT:

            return {
                ...state,
                type: action.type,
                defaultInsight: action?.data?.report || undefined,
            }

        case actions.GET_ALL_DATA_SOUCE_SUCCESS_V1:

            return {
                ...state,
                type: action.type,
                dataSources: action?.data?.all_reports || undefined,
            }


        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
            return {
                ...state,
            };
    }
};

export default reportHelperReducer;