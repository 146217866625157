import styled from 'styled-components';
import React, { useState } from 'react'
import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils';

let selected = getSelectedTheme();

const { checkboxStyle } = themes[selected]

const Wrapper = styled.div`
    position: relative;
    height: ${props => props.size ? props.size : '40px !important'};
    width: ${props => props.size ? props.size : '40px !important'};
    margin-right: ${props => props.mR || undefined};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;

    &:hover{
        background: ${checkboxStyle.color.wrapperHoverBg};
    }
    .ripple{
            border-radius: 50%;
            background: ${checkboxStyle.color.ripple};
            position: absolute;
            top: 0px;
            left: 0px;
            bottom: 0px;
            right: 0px;
            margin: auto;
            transition: ${props => props.mode === "none" ? 'all .3s' : undefined};
            opacity: ${props => props.mode === "none" ? 1 : 0}
        }
`;



export const CheckBox = styled.span`
    position: relative;
    top: 0;
    left: 0;
    height: ${props => props.size ? props.size : '17px !important'};
    width: ${props => props.size ? props.size : '17px !important'};
    /* background-color: ${props => props.checked ? checkboxStyle.color.checkBoxBg : "transparent"}; */
    background-color: ${props => props.checked ? '#337DFF' : "transparent"};
    border: 2px solid;
    border-color: ${props => props.checked ? checkboxStyle.border.checkBox : props.bColor ? props.bColor : '#c3c3c3'};
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    z-index: 1;
    border-radius: 2px;

    
 
  &:after {
        content: '';
        display: ${props => props.checked ? "block" : "none"};
        width: 4px;
        height: 8px;
        border: ${props => props.checked ? `solid ${checkboxStyle.border.tick}` : `solid ${checkboxStyle.border.unTick}`};
        border-width: 0 2px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        right: 4px;
        top: 0px;
}
`;



// this is Radio button css 



export const Radio = styled.span`
    position: relative;
    top: 0;
    left: 0;
    height: ${props => props.w ? props.w : '17px'} !important;
    width: ${props => props.h ? props.h : '17px'} !important;
    background-color: ${props => props.checked ? '#337DFF' : "transparent"};
    border: 1px solid ${props => props.bColor || checkboxStyle.border.radio};
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    margin-right: ${props => props.mR || undefined};
    cursor: ${props => props.disabled ? "not-allowed" : undefined};

    &:hover{
        opacity: 0.8;
    }

  &:after {
        content: '';
        display: ${props => props.checked ? "block" : "none"};
        width: 4px;
        height: 8px;
        border: ${props => props.checked ? `solid ${checkboxStyle.border.radioChecked}` : `solid ${checkboxStyle.border.radioUnchecked}`};
        border-width: 0 2px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        right: ${props => props.right ? props.right : '4px'};
        top: ${props => props.top ? props.top : '0px'};;
}
`;



const CheckBoxComponent = (props) => {

    const { height, width, size, style, label, disabled } = props;

    const [effect, seteffect] = useState({
        _width: 0,
        mode: "none"
    });

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} onClick={(event) => {

            if (!disabled) {
                seteffect({
                    _width: props.width ? props.width : 40,
                    mode: "none"
                })
                props.onClick(event)
                setTimeout(() => {
                    seteffect({
                        _width: 0,
                        mode: "active"
                    })
                }, 300)
            }

        }} >
            <Wrapper style={style} mode={effect.mode} size={size} >
                <CheckBox disabled={disabled} className="checkbox" checked={props.checked} height={height} width={width} />
                <span style={{ width: effect._width + 'px', height: effect._width + 'px' }} className="ripple"></span>
            </Wrapper>
            {label ? <span style={{ textTransform: 'capitalize', fontWeight: 500, color: disabled ? "#22222259" : '#374a66', fontSize: '.9rem' }}>{label}</span> : undefined}
        </div>
    )

}


export default CheckBoxComponent;