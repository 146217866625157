export const REPORT_HELPER_INIT = 'modules/reportHelper/init';
export const SAVE_QUESTION_REQUESTED = 'modules/reportHelper/question/request';
export const SAVE_QUESTION_SUCCESS = 'modules/reportHelper/save/success';
export const SET_ACTIVE_EDIT_REPORT_REQUESTED = 'modules/reportHelper/activeEditReport/request';
export const SET_ACTIVE_EDIT_REPORT_SUCCESS = 'modules/reportHelper/activeEditReport/success';
export const CLEAR_ACTIVE__EDIT_REPORT = 'modules/reportHelper/clear_active_Edit_report';



export const REPORT_CELL_CLICKED_INFO_V2 = 'modules/reportHelper/report_cell_clicked_info_V2';
export const CELAR_REPORT_CELL_INFO_V2 = 'modules/reportHelper/report_cell_clicked_clear_info_v2'
export const MARK_REPORT_FOR_NO_REFRESH = 'modules/reportHelper/mark_report_for_no_refresh';


export const GET_LATEST_DASHBOARD_FOR_INSIGHT = 'modules/reportHelper/get_latest_dashboard_for_insight';
export const CLEAR_ALL_REPORTS = 'modules/reportHelper/clear_all_reports';


/**
 * 
 */

export const GET_ALL_REPORT_SUCCESS_V1 = 'modules/reportHelper/get_all_report_success_v1';
export const DELETE_REPORT_SUCCESS_V1 = 'modules/reportHelper/delete_report';
export const CLONE_REPORT_SUCCESS_V1 = 'modules/reportHelper/clone_report';

export const GET_ALL_DATA_SOUCE_SUCCESS_V1 = 'modules/reportHelper/get_all_data_source_success_v1';

export const GET_REPORT_BY_ID_V1 = 'modules/reportHelper/get_report_by_id_v1';

export const CLEAR_EDIT_CURRENT_REPORT_SUCCESS_V1 = 'modules/reportHelper/clear_edit_report_success_v1';
