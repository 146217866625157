import React, { PureComponent } from 'react';
import ToolTipWrapper from '../tooltip/tool.tip.wrapper';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceLine, ComposedChart, Line, ResponsiveContainer, Legend, Label } from 'recharts';
import { withRouter } from 'react-router-dom';
import CustomToolTip from '../tooltip/tooltip.custom';
import CustomizedAxisTick from '../tick';
import { getRemToPx, apply_styles, yDataValueFormatter, formatValueByDataType, splitByNf } from '../../../utils'
import { DataFormaterFixed } from '../yaxis-label';
import enums from '../../../utils/enums';
import { showPopup } from '../../../utils';
import ChartFilterPopup from '../../chart-filter-popup'
import RenderLegend from '../legend/index.new';
import { constants } from '../../../utils/constants';



class ComposedBarChart extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            selectedBar: undefined,
            toolTipData: undefined,
            localChartAdditionalInfo: undefined,
            active_bar: undefined,
        }
    }

    _openPopup = (yDataKeys, xDataKeys, currentKey, info_wrapper) => {
        showPopup(undefined, undefined, enums.popupType.element, ChartFilterPopup, {
            getDataFromReportingServer: this.props.getDataFromReportingServer,
            question: this.props.question,
            yDataKeys: yDataKeys,
            xDataKeys: xDataKeys,
            currentKey: currentKey,
            ...info_wrapper,
            b_history: this.props.history
        })
    }


    first_letter_cap = (letter) => {
        if (letter && letter.trim().length > 0) {
            let temp_letter = letter.split("")
            let first_letter = temp_letter[0].toUpperCase();
            temp_letter[0] = first_letter;
            return temp_letter.join("")
        }
    }

    componentDidMount() {

        let tempChartInfo = this.props.chartAdditionalInfo ? [...this.props.chartAdditionalInfo] : {};
        const data_to_check = this.props.data && this.props.data.length > 0 ? this.props.data[0] : undefined;

        if (data_to_check && Object.keys(data_to_check).length > 0) {
            Object.keys(data_to_check).forEach((c) => {
                tempChartInfo.forEach((column) => {
                    if ((column.column_name && column.column_name.toLowerCase()) === (c && c.toLocaleLowerCase())) {
                        column.column_name = c;
                    }

                })
            })
        }

        if (data_to_check) {
            tempChartInfo.forEach(c => {
                const { column_name } = c;
                if (typeof data_to_check[column_name] === 'undefined') {
                    c.use_first_cap = true;
                }
            })
        }
        this.setState({ chartAdditionalInfo: tempChartInfo });
    }


    render() {

        const {

            paddingOnXaxis,
            showReferenceLine, valueOfReferenceLine,
            showStacked, heightOfChart, widthOfChart,
            id, insights, isDimension, isDiscovery, yDataKeys, xDataKeys,
            hide_y_axis, hide_x_axis, hide_y_axis_border, hide_x_axis_border, hide_grid, hide_legend, hide_tooltip,
            hide_x_axis_label, hide_y_axis_label, Alignments, chartAdditionalInfo, chartStyle,tooltipTheme

        } = this.props;



        const widthTemp = widthOfChart && isDimension ? (widthOfChart - 0) : widthOfChart ? (widthOfChart - 0) : '93.1vw';

        let showRightYAxis = true;
        let tempYAxis = [];

        this.state.chartAdditionalInfo && this.state.chartAdditionalInfo.length > 0 && this.state.chartAdditionalInfo.forEach((item) => {
            if (item.axis_position === 'Y Axis Primary') {
                tempYAxis[0] = this.first_letter_cap(item.column_name)
            }
            if (item.axis_position === "Y Axis Secondary") {
                tempYAxis[1] = this.first_letter_cap(item.column_name)
            }
        })



        const xAxisInterval = 0;
        let chartMargin = {
            top: !hide_x_axis_label ? isDimension ? 0 : (insights || isDiscovery) ? 20 : 50 : heightOfChart < 100 ? 0 : 10,
            right: !hide_y_axis_label ? isDimension ? getRemToPx(1.25) : (insights || isDiscovery) ? getRemToPx(2.5) : getRemToPx(9.375) : 10,
            bottom: isDimension ? 0 : ((hide_x_axis_label && hide_x_axis) ? 0 : hide_x_axis_label ? 0 : hide_x_axis ? 20 : !insights ? (!isDiscovery ? 70 : 20) : 15),
            left: !hide_y_axis_label ? isDimension ? 0 : (insights || isDiscovery) ? getRemToPx(2.5) : getRemToPx(9.375) : 0
        }

        let yAxisLabelOffset = isDimension ? getRemToPx(1.25) : (insights || isDiscovery) ? getRemToPx(-1.5) : getRemToPx(-2.5);
        let xAxisLabelOffset = isDimension ? 7 : (insights || isDiscovery) ? -5 : -20;
        let heightOfXAxis = isDimension ? undefined : ((hide_x_axis_label && hide_x_axis) ? 7 : hide_x_axis_label ? getRemToPx(5.5) : hide_x_axis ? 20 : getRemToPx(insights ? 6 : 7));
        let widthOfYAxis = !hide_y_axis ? getRemToPx(4.1) : 5;


        const xAxisStyle = chartStyle.axis && chartStyle.axis.x;
        const yAxisStyle = chartStyle.axis && chartStyle.axis.y;

        const xAxisTickStyle = apply_styles((xAxisStyle && xAxisStyle['tick']), constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT)
        const yAxisTickStyle = apply_styles((yAxisStyle && yAxisStyle['tick']), constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT)
        const xAxisTitleStyle = apply_styles((xAxisStyle && xAxisStyle['title']), constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT)
        const yAxisTitleStyle = apply_styles((yAxisStyle && yAxisStyle['title']), constants.STYLED_COMPONENTS.FONT, constants.STYLE_COMPONENTS_ELEMENT_TYPES.SVG, constants.STYLE_TYPE.OBJECT)
        const xAxisTick = !hide_x_axis ? <CustomizedAxisTick xDataKeys={xDataKeys} Alignments={Alignments} totalTick={this.props.data.length} _style={xAxisTickStyle} /> : null;
        const legendStyle = chartStyle && chartStyle.legend;



        return (
            <React.Fragment>
                <div id={id ? id : 'chart'} style={{ position: 'relative', width: widthTemp, textTransform: 'capitalize' }}>
                    {this.state.chartAdditionalInfo && (
                        <ResponsiveContainer width='100%' height={isDimension ? heightOfChart : heightOfChart}>
                            <ComposedChart
                                data={this.props.data}
                                margin={chartMargin}
                            >
                                {!hide_grid && !isDimension && (
                                    <CartesianGrid strokeDasharray="2 1" vertical={false} horizontalY={false} />
                                )}
                                <XAxis
                                    // stroke={chartStyle.color.yAxisStock}
                                    dataKey={xDataKeys && xDataKeys.length > 0 && xDataKeys[xDataKeys.length - 1]}
                                    padding={{ right: (insights || isDiscovery) ? undefined : paddingOnXaxis, left: 10 }}
                                    style={xAxisTickStyle}
                                    height={heightOfXAxis}
                                    textAnchor="end"
                                    tick={xAxisTick}
                                    axisLine={!hide_x_axis_border ? true : false}
                                    interval={xAxisInterval}
                                >
                                    {!hide_x_axis_label &&
                                        <Label
                                            angle='0'
                                            position='insideBottom'
                                            offset={xAxisLabelOffset}
                                            style={xAxisTitleStyle}>
                                            {xDataKeys && xDataKeys.length > 0 && splitByNf(xDataKeys[0])}
                                        </Label>
                                    }
                                </XAxis>

                                <YAxis
                                    yAxisId={showRightYAxis ? 'left' : undefined}
                                    orientation={showRightYAxis ? 'left' : undefined}
                                    // stroke={chartStyle.color.yAxisStock}
                                    interval={0}
                                    style={yAxisTickStyle}
                                    tickFormatter={DataFormaterFixed}
                                    tick={!hide_y_axis && !isDimension ? true : false}
                                    width={widthOfYAxis}
                                    axisLine={!hide_y_axis_border ? true : false}
                                >
                                    {!hide_y_axis_label && tempYAxis &&
                                        <Label
                                            angle='-90'
                                            position='insideLeft'
                                            offset={yAxisLabelOffset}
                                            style={yAxisTitleStyle}>
                                            {splitByNf(yDataValueFormatter(tempYAxis[0]))}
                                        </Label>
                                    }
                                </YAxis>

                                {showRightYAxis && tempYAxis && (
                                    <YAxis
                                        yAxisId="right"
                                        orientation="right"
                                        // stroke={chartStyle.color.yAxisStock}
                                        interval={0}
                                        style={yAxisTickStyle}
                                        tickFormatter={DataFormaterFixed}
                                        tick={!hide_y_axis && !isDimension ? true : false}
                                        width={widthOfYAxis}
                                        axisLine={!hide_y_axis_border ? true : false}
                                    >
                                        {!hide_y_axis_label &&
                                            <Label
                                                angle='-90'
                                                position='insideRight'
                                                offset={yAxisLabelOffset}
                                                style={yAxisTitleStyle}>
                                                {splitByNf(yDataValueFormatter(tempYAxis[1]))}
                                            </Label>
                                        }
                                    </YAxis>
                                )}


                                {!hide_tooltip &&
                                    (<ToolTipWrapper  ToolTipWrapper active_bar={this.state.active_bar} local_x_data_keys={xDataKeys} local_y_data_keys={yDataKeys} Alignments={Alignments} tooltipTheme={tooltipTheme}  />)}

                                {this.state.chartAdditionalInfo && this.state.chartAdditionalInfo.length > 0 && this.state.chartAdditionalInfo.map((info, index) => {
                                    const { use_first_cap } = info;

                                    if (info.axis_position === 'Y Axis Primary') {
                                        if (info.chart_type === 'bar') {
                                            return (
                                                <Bar
                                                    yAxisId={"left"}
                                                    dataKey={info.column_name ? (use_first_cap ? this.first_letter_cap(info.column_name) : info.column_name) : undefined}
                                                    stackId={showStacked && 'karma'}
                                                    maxBarSize={100}

                                                    onMouseOver={(e) => {
                                                        this.setState({active_bar: info.column_name})
                                                    }}
                                                    onMouseOut={(e) => {
                                                        this.setState({active_bar: undefined})
                                                    }}

                                                    fill={this.props.colorForYData[index]}
                                                // fillOpacity={'60%'}
                                                />
                                            )
                                        }
                                        // if (info.chart_type === 'line') {
                                        else {
                                            return (<Line
                                                strokeOpacity="1"
                                                strokeWidth="1.9"
                                                onMouseOver={() => {
                                                    console.log('orchidians')
                                                }}
                                                yAxisId={"left"} type="monotone" dataKey={info.column_name ? (use_first_cap ? this.first_letter_cap(info.column_name) : info.column_name) : undefined} stroke={this.props.colorForYData[index]} />)
                                        }
                                    }

                                    if (info.axis_position === "Y Axis Secondary") {
                                        if (info.chart_type === 'bar') {
                                            return (
                                                <Bar
                                                    yAxisId={"right"}
                                                    dataKey={info.column_name ? (use_first_cap ? this.first_letter_cap(info.column_name) : info.column_name) : undefined}
                                                    stackId={showStacked && 'karma'}
                                                    maxBarSize={100}
                                                    fill={this.props.colorForYData[index]}
                                                />
                                            )
                                        }
                                        else {
                                        // if (info.chart_type === 'line') {
                                            return (<Line
                                                strokeOpacity="1"
                                                strokeWidth="1.9"
                                                onMouseOver={() => {
                                                    console.log('orchidians111111')
                                                }}

                                                yAxisId={"right"} type="monotone" dataKey={info.column_name ? (use_first_cap ? this.first_letter_cap(info.column_name) : info.column_name) : undefined} stroke={this.props.colorForYData[index]} />)
                                        }
                                    }
                                })}
                                {
                                    showReferenceLine &&
                                    <ReferenceLine y={valueOfReferenceLine} stroke="#000" stockWidth="2" style={{ strokeDasharray: '4' }} />
                                }
                                {!hide_legend && <Legend content={<RenderLegend legendStyle={legendStyle} />} verticalAlign="top" height={30} />}
                            </ComposedChart>
                        </ResponsiveContainer>
                    )}
                </div>
            </React.Fragment>


        );
    }
}


export default withRouter(ComposedBarChart);