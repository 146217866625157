import styled from 'styled-components';
import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils';
const selected = getSelectedTheme();



const { label, textbox, reportList } = themes[selected];

export const SaveButton = styled.div`
    border: 1px solid transparent;
    background: ${props => props.disable ? '#ccc' : '#8396af'} ;
    padding: 8px 23px;
    box-sizing: border-box;
    font-size: 1rem;
    font-weight: bolder;
    color: white;
    border-radius: 2px;
    width: fit-content;
    cursor: pointer;    
    box-shadow: 1px 4px 4px #ccc;


    &:hover{
        opacity: 0.8;
    }
`;




export const DivRow = styled.div`
    flex:${props => props.w ? undefined : '1'};
    width: ${props => props.w || '100%'};
    display: ${props => props.w ? 'inline-block' : 'block'};
    margin: ${props => props.margin ? props.margin : undefined};

        input{
            ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: #000;
            font-size: .9rem;
    }

        }
    .tabIndex{
        &:focus{
            /* outline: 0; */
        }
    }


    /* input{
        height: 2.857rem;
        border-radius: 5px;
        border: ${textbox.border.thickness} solid  ${textbox.border.color};
        background-color: ${textbox.background};
        width: 100%;
        font-family: "DM Sans", sans-serif;
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.286rem;
        letter-spacing: normal;
        text-align: left;
        color: ${textbox.color};
        padding: 0px 16px;
        padding-right: 25px;
        box-sizing: border-box;

        &:hover{
            border-color:${textbox.border.hoverColor};
            outline: none;
            box-shadow: 0 0 0;
        }
        &:focus{
                box-shadow: 0 0 0;
                outline: none;
        }

    }

    .input_edit{
        position: absolute;
        right: 10px;
        top: 0%;
        height: 2.857rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    label{
        font-family: "DM Sans", sans-serif;
        font-size: 1.286rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.714rem;
        letter-spacing: normal;
        text-align: left;
        color: ${label.color};
        margin-bottom: 1.071rem;
        display: block;
    } */




    select {
        font-size: 1rem;
        font-family: "DM Sans", sans-serif;
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.286rem;
        letter-spacing: normal;
        text-align: left;
        padding: 0px 16px;
        padding-right: 25px;
        box-sizing: border-box;
        height: 2.857rem;
        border-radius: 5px;
        width: 100%;
    }

    textarea{
        width: 100%;
        resize: none;
        border: 0px;
        padding: 10px 19px;
        box-sizing: border-box;
        height: 98px;
        border-radius: 5px;
        color: #222;
        font-size: 1rem;
        border: 1.4px solid #ccc;
        font-family: 'Titillium Web', sans-serif;
        transition: all 0.2s ease-in;

        &:hover{
            border-color:${textbox.border.hoverColor};
            outline: none;
            box-shadow: 0 0 0;
        }
        &:focus{
                box-shadow: 0 0 0;
                outline: none;
        }


    }
`;



export const ActionBtnGroup = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

`;