import React from 'react'
import Skeleton from 'react-loading-skeleton';

export default function TableSkeleton({ noOfRows=10, noOfCol = 6 }) {
    // let arrCol = new Array(noOfCol).fill('a');
    let arrCol = Array.from(Array(noOfCol).keys())

    return (
        <div className='historylist_wrapper'>
            <div className='tHead'>
                <Skeleton inline={true} width="100%" height={40} count={1}  containerClassName='row' className='margin_top' />
            </div>
            <div className='flex'>
                {arrCol?.map(i => <div key={'_skl__'+i} style={{ width: '100%' }}>
                    <Skeleton inline={true} width="100%" height={40} count={noOfRows} duration={2} containerClassName='grid' className='margin_top' />
                </div>)}

            </div>
        </div>
    )
}


