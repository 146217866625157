/* 
    * Header Component
    * Main Props 
        * sqlQuery => It is the string which UI shows in PopUp when user clicked on View SQL Query
        * getDataFromReportingServer => dashboard/multiple-reports -> to here
            * It is responsible for getting the data from backend when user click on any option in dropdowns
*/

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { resetStore } from '../../actions/common';
import { getParamByName } from '../../utils';
import { LogoWrapper, Wrapper } from './header.styled';
import { get_favourite_list_by_user_id, get_history } from '../../actions/favourite';
import { log_out_user, get_user_preferences, save_user_preference } from '../../actions/account';
import { FullWidthDiv } from '../full-width-div';
import OverLay from '../helper/overlay';
import { getSelectedTheme, _getDataFromReportingServer } from '../../utils';
import SearchBox from './search.box';
import HeaderPageTitle from './header.page.title';
import { get_db_info } from '../../actions/connection'
import { saveInSession, getFromSession } from '../../utils/session.helper';
import { constants } from '../../utils/constants';
import HeaderRightSection from './header.right.side';
import GreaterThenSvg from '../svg/grater.then';
import styled from 'styled-components';
import { toogleHeader } from '../../actions/helper';


const SmallGButton = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg{
        fill: #222;
    }

    &:hover{
        background-color: #eaeaea;
    }
`;


const hideSearchBoxPaths = [
    '/question_search',
    '/report_list',
    '/dashboard_list',
    '/polus_search',
    '/favourites',
    '/history',
    '/demo',
    '/demo_request',
    '/master_data',
    '/connection',
    '/demo_view',
    '/etl',
    '/etl_details',
    '/etl_editor',
    '/add_markup',
    '/campaign',
    '/tbl_edits',
    '/report_editor',
    '/sqoop_configration',
    '/sqoop_configration_editor',
    '/column_info',
    '/tbl_errors',
    '/streaming',
    '/streaming_editor',
    '/report_list'
    // '/analytics_map'
];


let _reportId = undefined;

const AppHeader = (props) => {


    const { getDataFromReportingServer, question, hints, loading, user, pathname, favouriteData, history, historyData, db_infos } = props;
    const { location } = history;
    const { search } = location;
    const [isMenuOpen, setMenuOpen] = useState(undefined);
    const [temp_question, setTempQuestion] = useState(undefined);
    const [dataBaseId, setDatabaseId] = useState(undefined);

    let db_id = getFromSession(constants.SESSION_KEYS.DATABASE_ID)

    useEffect(() => {
        setDatabaseId(db_id)
    }, [db_id])


    useEffect(() => {
        props.get_favourite_list_by_user_id();
        props.get_history();
        props.get_db_info()
        props.get_user_preferences()
    }, [])



    useEffect(() => {
        if (pathname === '/dashboard') {
            let _question = getParamByName(search, "question");
            let report_id = getParamByName(search, "id");
            _reportId = report_id
            setTempQuestion(_question)
        }
    }, [location])


    const selected_db_name = (db_id) => {
        let db_name = undefined;
        if (db_infos && db_infos.length > 0) {
            for (let i = 0; i < db_infos.length; i++) {
                let element = db_infos[i];
                if (element.id === db_id) {
                    db_name = element.connection_name;
                    break;
                }
            }
        }
        return db_name;
    }


    const click_on_change_data_source_link = () => {
        props.history.push('/connection?from=homePage')
        setMenuOpen(false)
    }


    const is_developer_mode = true //(props.userPreference && props.userPreference.setting_value && props.userPreference.setting_value === 'enable')


    return (
        <Wrapper shadowHide={props.shadowHide} style={{ position: 'fixed', zIndex: 4, top: '0px' }}>
            <FullWidthDiv style={props.style} isMobile={props.isMobile}>
                <div style={{
                    display: 'flex ',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    height: '55px'
                }}>

                    <SearchBox
                        main_question={temp_question}
                        loading={loading}
                        hints={hints}
                        getDataFromReportingServer={getDataFromReportingServer}
                        search={search}
                        temp_question={temp_question}
                        reportId={_reportId}
                        user={props.user}
                        hideSearchBoxPaths={hideSearchBoxPaths}
                        history={history}
                        pathname={pathname}
                        resetStore={props.resetStore}
                        setQuestion={setTempQuestion}
                        is_developer_mode={is_developer_mode}
                        marginLeft={(pathname === '/dashboard' && props?.headerInfo?.extraComponent) ? '13%' : undefined}

                    />

                    {
                        isMenuOpen &&
                        <OverLay onClick={() => setMenuOpen(!isMenuOpen)} />
                    }
                    {props.headerInfo && props.headerInfo.title && (
                        <HeaderPageTitle
                            marginLeft={(pathname === '/dashboard' || props.headerInfo.extraComponent) ? '6%' : undefined}
                            title={props.headerInfo.title}
                            isBack={props.headerInfo.isBack}
                            history={props.history}
                            extraComponent={props.headerInfo.extraComponent}
                            onChange={props.headerInfo.onChange}
                            isTextBox={props.headerInfo.isTextBox}
                            textBoxValue={props.headerInfo.textBoxValue}

                        />
                    )}

                    <LogoWrapper isMenuOpen={isMenuOpen}>
                        {props.headerInfo && !props.headerInfo.title && (
                            <HeaderPageTitle
                                title={props.headerInfo.title}
                                isBack={props.headerInfo.isBack}
                                history={props.history}
                                extraComponent={props.headerInfo.extraComponent}
                                onChange={props.headerInfo.onChange}
                                isTextBox={props.headerInfo.isTextBox}
                                textBoxValue={props.headerInfo.textBoxValue}

                            />
                        )}
                        <HeaderRightSection
                            setMenuOpen={setMenuOpen}
                            isMenuOpen={isMenuOpen}
                            _getDataFromReportingServer={_getDataFromReportingServer}
                            click_on_change_data_source_link={click_on_change_data_source_link}
                            dataBaseId={dataBaseId}
                            selected_db_name={selected_db_name}
                            db_infos={db_infos}
                            is_developer_mode={is_developer_mode}
                            log_out_user={props.log_out_user}
                            save_user_preference={props.save_user_preference}
                            history={props.history}

                        />
                        {/* {!props.toggleHeader && pathname === '/dashboard' && (
                            <SmallGButton
                                onClick={() => {
                                    props.toogleHeader()
                                }}
                                style={{ marginLeft: '1rem', marginBottom: '.2rem' }} title={"Hide the menu"}>
                                <GreaterThenSvg size="1rem" height="1rem" style={{ transform: 'rotate(-90deg)' }} />
                            </SmallGButton>
                        )} */}
                    </LogoWrapper>

                </div>
            </FullWidthDiv>
        </Wrapper>
    );
};












const mapStateToProps = state => ({
    favouriteData: state.favouriteReducer.favouriteData,
    historyData: state.favouriteReducer.historyData,
    headerInfo: state.commonReducer.headerInfo,
    db_infos: state.connectionReducer.db_infos,
    userPreference: state.accountReducer.userPreference,
    toggleHeader: state.helperReducer.toggleHeader

})






export default connect(mapStateToProps, { toogleHeader, save_user_preference, get_user_preferences, resetStore, get_favourite_list_by_user_id, log_out_user, get_history, get_db_info })(AppHeader);