import { dispatchAction, showError, simpleDispatch_post, simpleDispatch_get, raiseSuccess } from './helper';
import * as actionTypes from '../action-types/account';
import * as loaderTypes from '../action-types/loader';
import { saveInSession, saveOprData, getFromSession, killUser } from '../utils/session.helper';
import { _whatToDoIfTokenNotFound } from '../utils'
import { constants } from '../utils/constants';
import { post } from '../data.service';

export const login = (userName, password, reload) => async dispatch => {
    dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);

    const loginData = {
        email: userName,
        password
    };

    try {
        const url = constants.END_POINTS.AUTH_API + constants.END_POINTS.AUTHETICATE.POINT + constants.END_POINTS.AUTHETICATE.GET_AUTHENTICATE;

        const data = await post(url, loginData);

        if (data && data.code === 200) {

            if (data.skip) {
                dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
                // do nothing. we are reloading the window. It is a sham request
            };

            const token = data.data.token;
            const refresh_token = data.data.refresh_token;
            const user_id = data.data.user_id;
            const user_name = data.data.name;
            const user_email = data.data.email;
            const traking_session_id = data?.data?.user_session_id;


            const clients = data.data.clients || [];

            const client_index = (clients || []).findIndex(c => c.client_id == process.env.REACT_APP_CLIENT_ID)
            // const client_index = (clients || []).findIndex(c => c.client_id == 'euro_net')

            const default_client_id = clients?.length > 0 && client_index > -1 ? clients[client_index]?.client_id : clients[0]?.client_id;

            // if (permissions) {
            //     saveInSession(constants.SESSION_KEYS.PERMISSIONS, JSON.stringify(permissions));
            // }

            const temp = {};

            temp.user_id = user_id;
            temp.name = user_name;
            temp.email = userName;
            temp.is_developer = (userName && (userName === "saroj.k@newfangled.io") || (userName === "dev@newfangled.io"));

            if (clients) {
                saveInSession(constants.SESSION_KEYS.CLIENTS, JSON.stringify(clients));
                saveInSession(constants.SESSION_KEYS.CLIENT_ID, clients[0] && default_client_id);
            }

            if (token) {
                saveInSession(constants.SESSION_KEYS.TOKEN, token);
                saveInSession(constants.SESSION_KEYS.REFRESH_TOKEN, refresh_token);
                saveInSession(constants.SESSION_KEYS.TRAKING_SESSION_ID, traking_session_id);
                saveInSession(constants.SESSION_KEYS.REDIRECT_TO_DB_LIST, "__nf__yes__");
            }

            constants.CLIENT_ID = clients[0] && default_client_id;

            let _user = undefined;

            if ((process.env.REACT_APP_DEV_LOGIN_KEY === constants.LOGIN_DEV_KEY) && !_user) {
                _user = getFromSession(constants.SESSION_KEYS.LOGGED_USER);
            }
            else if ((process.env.REACT_APP_DEV_LOGIN_KEY !== constants.LOGIN_DEV_KEY) && !_user) {
                _user = localStorage.getItem(constants.SESSION_KEYS.KX.CURRENT_USER);
            }

            if ((process.env.REACT_APP_DEV_LOGIN_KEY === constants.LOGIN_DEV_KEY) && !_user) {
                saveInSession(constants.SESSION_KEYS.LOGGED_USER, JSON.stringify(temp));
            }
            else if ((process.env.REACT_APP_DEV_LOGIN_KEY !== constants.LOGIN_DEV_KEY) && !_user) {
                localStorage.setItem(constants.SESSION_KEYS.KX.CURRENT_USER, JSON.stringify(temp));
            }

            dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);

            if (reload) {
                reload();
            }
        }
        else {
            const error = {}
            error.message = data && data.message;

            throw new Error(error);
        }
    } catch (error) {
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        showError(dispatch, error);
    }
};


export const dispatchUserInfo = user => dispatch => {
    dispatchAction(dispatch, actionTypes.LOGIN_SUCCESS, user, null, null, '');
};


export const dispatchToken = token => dispatch => {
    dispatchAction(dispatch, actionTypes.DISPATCH_TOKEN, token, null, null, '');
};


export const getTokens = options => dispatch => {
    const url = constants.END_POINTS.AUTH_API + constants.END_POINTS.AUTHETICATE.POINT + constants.END_POINTS.AUTHETICATE.GET_AUTHENTICATE;

    const post = { ...options };

    simpleDispatch_post(dispatch, url, post, actionTypes.AUTHENTICATE_SUCCESS);
};


export const refreshToken = (user_id) => {
    const refresh_token = getFromSession(constants.SESSION_KEYS.REFRESH_TOKEN);


};


export const log_out_user = () => async (dispatch) => {
    remove_user_session()(dispatch)
    killUser();
    dispatchAction(dispatch, actionTypes.LOG_OUT_SUCCESS, undefined, undefined, undefined);
    _whatToDoIfTokenNotFound(true)
}


export const store_opr_credentials = (oprToken, reportId, clientId) => dispatch => {
    saveOprData({ oprToken, reportId, clientId });
};




export const getUsers = () => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.USERS.POINT + constants.END_POINTS.USERS.GET_USERS;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_USER_LIST);
};


export const saveUsers = (users) => async dispatch => {
    const post_body = {
        'users': users,
    }
    const url = constants.END_POINTS.API + constants.END_POINTS.USERS.POINT + constants.END_POINTS.USERS.SAVE_USERS;
    // await simpleDispatch_post(dispatch, url, post_body, actionTypes.SAVE_USERS_SUCCESS);

    try {
        const response = await post(url, post_body);
        if (response && response.code === 200) {
            raiseSuccess(dispatch, {
                type: 'success',
                message: 'Updated SuccessFully'
            })
            getUsers()(dispatch)
            dispatchAction(dispatch, actionTypes.SAVE_USERS_SUCCESS, undefined, undefined, undefined, 0)
            dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        }
    } catch (error) {
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);

    }
};





export const save_user = (user) => async dispatch => {

    const url = constants.END_POINTS.AUTH_API + constants.END_POINTS.USER.POINT + constants.END_POINTS.USER.SAVE_USER;

    try {
        const response = await post(url, user);
        if (response && response.code === 200) {
            raiseSuccess(dispatch, {
                type: 'success',
                message: 'Updated SuccessFully'
            })
            getUsers()(dispatch)
            dispatchAction(dispatch, actionTypes.SAVE_USERS_SUCCESS, undefined, undefined, undefined, 0)
            dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        }
    } catch (error) {
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);

    }
};



export const get_user_preferences = () => async (dispatch) => {
    // const url = constants.END_POINTS.API + constants.END_POINTS.USER_PREFERENCE.POINT + constants.END_POINTS.USER_PREFERENCE.GET_PREFERENCES
    // await simpleDispatch_get(dispatch, url, actionTypes.GET_USER_PREFERENCES);

}


export const save_user_preference = (preference) => async (dispatch) => {

    const post = {
        preference: preference
    }

    const url = constants.END_POINTS.API + constants.END_POINTS.USER_PREFERENCE.POINT + constants.END_POINTS.USER_PREFERENCE.SAVE_PREFERENCE
    await simpleDispatch_post(dispatch, url, post, actionTypes.SAVE_USER_PREFERENCES);
    await get_user_preferences()(dispatch);
}


export const get_user_session = (filter) => async (dispatch) => {
    const url = constants.END_POINTS.API + constants.END_POINTS.USER_SESSION.POINT + constants.END_POINTS.USER_SESSION.GET_USER_SESSION
    await simpleDispatch_post(dispatch, url, {filter}, actionTypes.GET_USER_SESSION_SUCCESS);
}


export const remove_user_session = () => async (dispatch) => {

    const id = getFromSession(constants.SESSION_KEYS.TRAKING_SESSION_ID)

    const url = constants.END_POINTS.API + constants.END_POINTS.USER_SESSION.POINT + constants.END_POINTS.USER_SESSION.REMOVE_USER_SESSION 
    await simpleDispatch_post(dispatch, url,{id: id}, 'REMOVE_USER_SESSION');
}
