import React from 'react';

const Prev = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 32 40"
            space="preserve">
            <g>
                <g data-name="030-Prev">
                    <path d="M22.29,30.71l-14-14a1,1,0,0,1,0-1.42l14-14a1,1,0,1,1,1.42,1.42L10.41,16l13.3,13.29a1,1,0,0,1-1.42,1.42Z" />
                </g>            </g>
        </svg>

    )

}


export default Prev;