
import { themes } from '../../theme/theme.config';
import { getSelectedTheme } from '../../utils'
const selected = getSelectedTheme();
const { chartStyle } = themes[selected];

export const yAxisLabelStyleConfig = [
    {
        "label": {
            "text-anchor": "middle",
            "font-size": "1.5rem",
            "font-weight": "bold",
            "fill": chartStyle.color.outerLabel
        },
        "tickLabel": {
            "font-size": "1rem",
            "fill": '#1f1f1f'
        }
    },
    {
        "label": {
            "font-size": "1rem",
            "text-anchor": "middle",
            "font-weight": "500",
            "fill": chartStyle.color.AxisLable
        },
        "tickLabel": {
            "font-size": ".8rem",
            "fill": '#1f1f1f'
        }
    }
]



export const XAxisLabelStyleConfig = [
    {
        "label": {
            "text-anchor": "left",
            "font-size": "1.5rem",
            "font-weight": "bold",
            "fill": chartStyle.color.outerLabel
        },
        "tickLabel": {
            "font-size": "1rem",
            "fill": '#1f1f1f'
        }

    },
    {
        "label": {
            "text-anchor": "left",
            "textAnchor": "left",
            "font-size": "1rem",
            "font-weight": "500",
            "fill": chartStyle.color.AxisLable,
        },
        "tickLabel": {
            "font-size": ".8rem",
            "fill": '#1f1f1f'
        }

    }
]