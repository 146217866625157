import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { get_favourite_list_by_user_id, save_favourite } from '../../actions/favourite';
import { connect } from 'react-redux';
import AddEditFavourite from './add.edit.favourit';
import { showPopup } from '../../utils';
import * as enums from '../../utils/enums';
import { _getDataFromReportingServer } from '../../utils'
import { withRouter } from 'react-router-dom';
import TitleHeader from '../title-header';
import SharedTable from '../shared-table';
import { PageInnerWrapper } from '../hoc/styled.hoc';
import { dispatchHeaderInformation } from '../../actions/common';



const Wrapper = styled.div`
   width: 100%;
   box-sizing: border-box;
   padding: 0px 10px;

`;


const Index = (props) => {
    const { favouriteData } = props;
    const [localFavoriteData, setFavoriteData] = useState(undefined);

    useEffect(() => {
        let _page_info = {
            title: 'YOUR FAVOURITES LIST'
        }

        props.dispatchHeaderInformation(_page_info)
    
        props.get_favourite_list_by_user_id();
    }, []);

    useEffect(() => {
        if (favouriteData) {
            if (favouriteData.results) {
                setFavoriteData(favouriteData.results)
            }
        }
    }, [favouriteData])



    const Edit_fav_item = (item) => {
        showPopup(undefined, undefined, enums.default.popupType.element, AddEditFavourite, item)
    }

    const delete_fav_item = (item) => {
        let _data = {
            id: item.id,
            name: item.name,
            query: item.query,
            is_active: 0,
            is_modified: true
        }
        props.save_favourite(_data)
        setTimeout(() => {
            props.get_favourite_list_by_user_id()
        }, 100)
    }

    const questionClick = (item) => {
        if (item) {
            _getDataFromReportingServer(item.query, props.history)
        }
    }


    return (
        <Wrapper style={{marginTop: '1rem'}}>
            {/* <TitleHeader title={"Your favourites List"} /> */}
            <PageInnerWrapper>
                <SharedTable
                    editAction={Edit_fav_item}
                    deleteAction={delete_fav_item}
                    tableData={(localFavoriteData && localFavoriteData.length > 0) ? localFavoriteData : undefined}
                    columnsToShow={["name", "query",]}
                    tableHead={["name", "query", "Action"]}
                    maximumWidthOfColumn={{
                        "query": '20rem'
                    }}
                    columnAction={{
                        "query": questionClick
                    }}
                />
            </PageInnerWrapper>
        </Wrapper>
    )
}


const mapStateToProps = state => ({
    favouriteData: state.favouriteReducer.favouriteData
})
export default withRouter(connect(mapStateToProps, { dispatchHeaderInformation, get_favourite_list_by_user_id, save_favourite })(Index));
