import React from 'react';

const CustomizedLabel = (payload) => {
    const { viewBox, index, label, _value } = payload;

    const x = viewBox?.width / 2 + viewBox?.x;
    const f_x = index > 0 ? x + 100 : x;

    const backgroundPadding = 5;
    const textWidth = label.length * 7;
    const backgroundWidth = textWidth + backgroundPadding * 2;
    const backgroundHeight = 20;

    return (
        <g>
            {/* <rect
                x={20}
                y={viewBox?.y - 15}
                width={80}
                height={30}
                fill="red"
                stroke="#fff"
                strokeWidth="0"
            /> */}
            {/* <text
                x={30}
                y={viewBox?.y + 5}
                fontSize=".8rem"
                textAnchor="middle"
                fill="black"
            >
                {_value}
            </text> */}
            {/* Circle at the start of the ReferenceLine */}
            <circle
                cx={90}        // Circle position (x-axis)
                cy={viewBox?.y}  // Circle position (y-axis)
                r={5}            // Circle radius
                fill="#6180ec"   // Circle fill color
            />

            {/* Background rectangle for the label */}
            <rect
                x={f_x - backgroundWidth / 2}
                y={viewBox?.y - backgroundHeight / 2}
                width={backgroundWidth}
                height={backgroundHeight}
                fill="#fff"
                stroke="#fff"
                strokeWidth="1"
            />

            {/* Text label */}
            <text
                x={f_x}
                y={viewBox?.y + 5}
                fontSize=".8rem"
                textAnchor="middle"
                fill="black"
            >
                {label}
            </text>
        </g>
    );
};

export default CustomizedLabel;
