export const HELPER_INIT = 'modules/helper/init';

export const SAVE_RECENT_OPEN_MENU = 'modules/recentMenu/save';
export const SAVE_RECENT_OPEN_MAIN_MENU = 'modules/recentMainMenu/save';

export const SAVE_WHICH_TABLE_DROP_DOWN = 'modules/whichTableDropDown/save';

export const GET_MONGO_TABLE_DATA_SUCCESS = 'modules/getMongoDbDataSuccess/success';
export const CLEAR_MONGO_TABLE_DATA_SUCCESS = 'modules/clearMongoDbDataSuccess/success';

export const GET_TAGGING_INFO_SUCCESS = 'modules/gettagging_inf0_success'
export const SAVE_TAGGING_INFO_SUCCESS = 'modules/savetagging_inf0_success'

export const TOGGLE_HEADER = 'modules/toggle/toogle_header';
export const EXPAND_SECTION_SUCCESS = 'modules/expand_section_sucess';
export const SET_TABLE_DATA_MODE_SUCCESS = 'modules/set_table_data_mode';
