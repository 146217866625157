import React from 'react';

const Back = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            {...props}
            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 512 293.8"
            space="preserve">

            <polygon points="512,146.9 365.1,0 322.7,42.4 397.2,116.9 0,116.9 0,176.9 397.2,176.9 322.7,251.4 365.1,293.8 " />

        </svg>

    )

}


export default Back;