
export const frequencyTypes = [
    {name : 'Seconds', value : 'seconds'},
    { name: 'Minutes', value: 'minutes' },
    { name: 'Hourly', value: 'hourly' },
    { name: 'Daily', value: 'daily' },
    { name: 'Weekly', value: 'weekly' },
    { name: 'Monthly', value: 'monthly' },
    { name: 'Quarterly', value: 'quarterly' },
    // { name: 'Quarterly', value: 'first_week_day_of_quarter' },
    // { name: 'Quarterly', value: 'first_week_day_of_month' },
    { name: 'One Time', value: 'one_time' },
]
