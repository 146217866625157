import React from 'react';

const Schema = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}

            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 153 148"
            space="preserve">
            <path d="M0 0 C4.41204599 3.71012958 7.51487977 7.47992031 8.3203125 13.32421875 C8.59899443 19.39019536 7.54576096 24.02672489 4 29 C-0.29300077 33.18292383 -4.3594952 35.06867042 -10.3125 35.375 C-15.93978218 35.22518883 -20.35925645 33.38605255 -24.5390625 29.609375 C-28.08124744 25.67361395 -29.96181879 21.92086289 -30.03125 16.609375 C-29.61594217 9.67373431 -27.74573339 5.53452313 -22.5625 0.75 C-15.07255087 -4.36508721 -7.88308291 -4.31338499 0 0 Z " fill={props.color} transform="translate(30,113)" />
            <path d="M0 0 C5.04592067 2.95138756 8.10129272 6.56774662 10.75 11.75 C11.7995423 17.69740637 11.35370982 22.92945248 8.5625 28.3125 C3.87348709 33.78301506 -0.33108106 36.66682105 -7.5625 37.25 C-13.10871985 37.08288226 -17.1992824 35.18895571 -21.4375 31.625 C-25.80872709 26.40381208 -27.27470369 22.21053963 -27.03125 15.46484375 C-26.36492278 10.56582429 -24.64279407 6.72392776 -20.96875 3.3671875 C-14.32823022 -1.55692674 -7.91213672 -2.27976821 0 0 Z " fill={props.color} transform="translate(84.6875,56.0625)" />
            <path d="M0 0 C4.01905459 2.80695876 6.47231076 6.51432941 7.72265625 11.203125 C8.49563158 17.97251497 7.9369384 23.6741834 3.72265625 29.203125 C-1.36019992 33.94397925 -6.13227138 35.59766532 -13.125 35.48046875 C-18.35036369 34.80714602 -22.66228978 32.2173347 -26.15234375 28.328125 C-30.02134342 22.6384196 -30.68401087 18.32045412 -29.81640625 11.6015625 C-28.54077716 5.9259374 -24.94694395 2.39212026 -20.27734375 -0.796875 C-13.65168069 -4.47779892 -6.41518521 -3.62209264 0 0 Z " fill={props.color} transform="translate(30.27734375,57.796875)" />
            <path d="M0 0 C5.04523048 2.86376892 8.66127319 6.68682862 10.67578125 12.1640625 C11.68510542 18.29146411 11.23743616 24.02848229 8.05859375 29.44140625 C4.27161521 34.15908291 -0.41182348 36.29994319 -6.32421875 37.1640625 C-12.08936706 37.44701456 -16.65176105 36.46831492 -21.08203125 32.6484375 C-25.14021309 28.29618451 -27.27698413 24.01705794 -27.3515625 17.93359375 C-26.9610909 11.95637461 -25.22595094 7.96704464 -20.83203125 3.72265625 C-14.92798388 -1.43237019 -7.46771225 -2.68586625 0 0 Z " fill={props.color} transform="translate(27.32421875,0.8359375)" />
            <path d="M0 0 C5.71488545 3.94599234 9.275917 7.94769711 10.625 14.8125 C10.8710569 21.18045269 9.85878462 25.87308461 5.625 30.8125 C1.6010562 34.62465729 -2.12925929 36.76484638 -7.76953125 36.84765625 C-14.12615243 36.45691942 -18.28015722 35.02508137 -22.8125 30.3203125 C-27.06341491 25.46557445 -27.72912186 21.6888715 -27.65625 15.44921875 C-27.07619038 10.01115982 -24.79415358 6.1606959 -20.875 2.4375 C-14.20505698 -1.89796297 -7.63462149 -2.68625571 0 0 Z " fill={props.color} transform="translate(85.375,1.1875)" />
            <path d="M0 0 C4.98357378 2.98827074 8.76503039 6.43180991 10.640625 12.05859375 C11.33186626 19.07299273 11.19258408 25.38793286 6.640625 31.05859375 C2.0692607 35.28236583 -2.81281738 36.86485599 -8.96875 37.1953125 C-14.71046327 36.86694654 -19.32155787 33.98424929 -23.359375 30.05859375 C-26.60684242 24.95543066 -27.90202666 19.76139332 -27.02734375 13.79296875 C-25.34831019 7.42347369 -21.75008859 3.71036747 -16.359375 0.05859375 C-11.10943366 -1.6913867 -5.20936337 -2.07857049 0 0 Z " fill={props.color} transform="translate(142.359375,0.94140625)" />
        </svg>

    )

}


export default Schema;