import { dispatchAction, simpleDispatch_get, simpleDispatch_post, simpleDispatch_put } from './helper';
import * as actionTypes from '../action-types/ranking';
import { constants } from '../utils/constants';


export const getConfigs = () => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.RANKING.POINT + constants.END_POINTS.RANKING.GET_CONFIGS;;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_CONFIGS_SUCCESS);

};


export const getDoctorRankingData = () => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.RANKING.POINT + constants.END_POINTS.RANKING.GET_RESULT;;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_DOCTOR_RANKIG_SUCCESS);

};


export const saveConfig = (configs) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.RANKING.POINT + constants.END_POINTS.RANKING.SAVE_CONFIG

    const post = {
        configs: configs
    }

    await simpleDispatch_put(dispatch, url, post, actionTypes.SAVE_CONFIG_SUCCESS, true);

};


export const getComparisonData = data => async dispatch => {
    if (data) {
        const url = constants.END_POINTS.API + constants.END_POINTS.RANKING.POINT + constants.END_POINTS.RANKING.GET_COMPARISON_DATA;

        const post = {
            id: data,
            "columnName": "DoctorId"
        }

        await simpleDispatch_post(dispatch, url, post, actionTypes.GET_COMPARE_DATA);
    }
    else {
        dispatchAction(dispatch, actionTypes.GET_COMPARE_DATA, undefined, undefined, undefined, undefined);
    }
};