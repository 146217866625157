import React from 'react';
import styled from 'styled-components';
import RippleEffect from '../hoc/ripple-effect';
import LeftArrow from '../svg/leftArrow';
import TextBox from '../hoc/textBox';
import CloseSvg from '../svg/close'


const TitleWrapper = styled.div`

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

        h2{
            font-size: 1.4rem;
            padding-right: 1.071rem;
            box-sizing: border-box;
            position: relative;
            z-index: 1;
            font-weight: bold;
            text-transform: uppercase;
            color: #337DFF;
            margin: 0px;
            /* position: relative; */
            text-align: center;
            position: absolute;
            right: 0px;
            width: 50%;
            left: 0px;
            right: 0px;
            margin: auto;
            z-index: 0;
        }

`;


const HeaderPageTitle = (props) => {

    const { title, isBack, extraComponent, isTextBox, onChange, textBoxValue, marginLeft } = props;

    return (
        <TitleWrapper marginLeft={marginLeft}>
            {title ? <h2>{title} </h2> : null}
            {isTextBox &&
                <div style={{ width: '100%', }}>
                    <div style={{ width: '40%', margin: 'auto' }}>
                        <TextBox
                            value={textBoxValue ? textBoxValue : ''}
                            onChange={onChange}
                            label={'Enter Title'}
                        />
                    </div>
                </div>
            }
            {isBack && (
                <div style={{ display: 'inline', marginRight: '.5rem' }}>
                    <RippleEffect
                        title="Back"
                        icon={<CloseSvg size='.9rem' height='.9rem' color="red" />}
                        Click={() => {
                            if (props.history && props.history.goBack) {
                                props.history.goBack();
                            }
                        }}
                    />
                </div>
            )}

            {extraComponent && (
                <div style={{ display: 'inline', marginRight: '2rem' }}>
                    {extraComponent}
                </div>
            )}

        </TitleWrapper>
    )
}

export default HeaderPageTitle;
