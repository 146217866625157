import * as actions from '../action-types/nf_grammar'


const initialState = {
    type: actions.NF_GRAMMAR_INIT,
    StoreNfGrammar: undefined,
    SaveNfGrammar: undefined,
    DeleteNfGrammar: undefined

};

const NfGrammarReducer = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        case actions.GET_NF_GRAMMAR_RESULTS:
            return {
                type: action.type,
                StoreNfGrammar: action.data
            };

        case actions.SAVE_NF_GRAMMARS:
            return {
                type: action.type,
                SaveNfGrammar: action.data,
                StoreNfGrammar: state.StoreNfGrammar
            };

        case actions.DELETE_NF_GRAMMARS:
            return {
                type: action.type,
                // DeleteNfGrammar: action.data,
                // StoreNfGrammar: action.data
            };

        default:
            return { ...state }
    }
}

export default NfGrammarReducer;