import { dispatchAction, simpleDispatch_post } from './helper';
import * as actionTypes from '../action-types/voice.helper';
import { generate_unique_key } from '../utils';
import { constants } from '../utils/constants';



export const dispatch_voice_command = (information, text) => async dispatch => {


    // { type: "chart", subtype: "bar" }
    console.log("information", information, text)

    if (information) {
        const type = information?.type
        switch (type) {
            case "chart":
                dispatchAction(dispatch, actionTypes.DISPATCH_CHART_TYPE_COMMAND_SUCCESS, {
                    type: information?.subtype,
                    id: generate_unique_key("chart")
                })
                break;

            case "download":
                dispatchAction(dispatch, actionTypes.DISPATCH_DOWNLOAD_COMMAND_SUCCESS, {
                    type: information?.subtype,
                    id: generate_unique_key("download")
                })
                break;
            case "data_label":
                dispatchAction(dispatch, actionTypes.DISPATCH_OTHER_VOICE_COMMAND, {
                    type: information?.subtype,
                    id: generate_unique_key("other")
                })
                break;
            default:
                break;
        }
    }
};


export const raise_text_to_voice = (text) => async dispatch => {
    dispatchAction(dispatch, actionTypes.RAISE_TEXT_TO_VOICE, {
        text: text,
        id: generate_unique_key("voice_")
    })
};




export const create_dashboard_using_voice_command = (text) => async dispatch => {

    // dispatchAction(dispatch, actionTypes.CREATE_DASHBOARD_USING_VOICE_COMMAND, {
    //     "dashboard_name": "revenue insights",
    //     "widgets": [
    //         {
    //             "question": " monthly sales",
    //             "chart_type": "stacked_bar_chart"
    //         },
    //         {
    //             "question": "compare ytd vs lytd sales",
    //             "chart_type": "stacked_bar_chart"
    //         },
    //         {
    //             "question": "comparison insight on sales zone wise",
    //             "chart_type": "stacked_bar_chart"
    //         }
    //     ]
    // })

    // VOCIE_DASHBOARD_DETAILS_FROM_JAVA

    

    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.VOCIE_DASHBOARD_DETAILS_FROM_JAVA;
    simpleDispatch_post(dispatch, url, { voice_command: text }, actionTypes.CREATE_DASHBOARD_USING_VOICE_COMMAND, true);

}


export const clear_voice_dashboard_data = () => async dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_VOICE_COMMAND_DASHBOARD)

}


