import { simpleDispatch_post, simpleDispatch_get, dispatchAction, showError, raiseSuccess } from './helper';
import * as actionTypes from '../action-types/directory_upload';
import * as loaderTypes from '../action-types/loader';
import { constants } from '../utils/constants';
import { post, axiosPostDownloadReq } from '../data.service';

import { getAllFiles } from './file.uploader'





export const scanDirectoryFiles = (dir_path) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.FILE_UPLOAD_INFO.POINT + constants.END_POINTS.FILE_UPLOAD_INFO.SCAN_DIRECTORY_GET_FILES;
    try {

        dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);

        const response = await post(url, { dir_path: dir_path });

        if (response && response.code === 200) {
            // console.log("calling file uploader")

            await dispatchAction(dispatch, actionTypes.SCAN_DIRECTORY_AND_GET_FILES, response.data)
        }

        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);

    } catch (error) {
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        const _message = typeof error === 'object' ? (error.custom_message ? error.custom_message : '') : error;
        raiseSuccess(dispatch, {
            type: 'error',
            title: 'ERROR',
            message: _message,
        })
    }
}


export const clear_scanned_dir_files = () => async dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_DIRECTORY_FILES, undefined, undefined, undefined, 0);

}

export const save_mapping_or_upload_data = (file_path, mapping_files_data) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.FILE_UPLOAD_INFO.POINT + constants.END_POINTS.FILE_UPLOAD_INFO.SAVE_FOLDER_FILE_MAPPING_DATA;


    try {
        dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);
        dispatchAction(dispatch, actionTypes.CLEAR_DIRECTORY_FILES, undefined, undefined, undefined, 0);

        const response = await post(url, { file_path: file_path, mapping_files: mapping_files_data });

        if (response && response.code === 200) {
            // console.log("calling file uploader")
            await raiseSuccess(dispatch, {
                type: 'success',
                message: "Folder Data Uploaded Successfully"
            })


            await getAllFiles()(dispatch)

        }
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);

    } catch (error) {

        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);

        const _message = typeof error === 'object' ? (error.custom_message ? error.custom_message : '') : error;

        console.log("_message", _message, error)
        raiseSuccess(dispatch, {
            type: 'error',
            title: 'ERROR',
            message: _message,
        })
    }

}

