import React, { useState, useEffect } from 'react';
import { constants } from '../../utils/constants';

import { saveConfig } from '../../actions/ranking';
import { Button } from '../hoc/button';
import { Wrapper, TitleWrapper, EditorInnerWrapper } from './ranking.styled.js';
import { DivRow } from '../styled-hoc';
import { connect } from 'react-redux';
import { saveEntities } from '../../actions/entity';
import { getFromSession } from '../../utils/session.helper';
import EditSvg from '../svg/edit';
import DeleteSvg from '../svg/delete';
import { showPopup, generate_unique_key } from '../../utils';
import AddEdit from './add.edit';
import * as enums from '../../utils/enums';
import { getConfigs } from '../../actions/ranking'
import TitleHeader from '../title-header';



function Index(props) {

    const { saveConfig } = props;

    const [localConfigs, setConfigs] = useState(undefined);

    useEffect(() => {
        props.getConfigs();
    }, []);

    useEffect(() => {
        setConfigs(props.configs)
    }, [props.configs]);


    const finalSave = finalConfig => {
        saveConfig(finalConfig);
        setConfigs(finalConfig);
    };

    const _saveConfig = config => {
        const tempLocalConfig = JSON.parse(JSON.stringify(localConfigs));


        if (config.id) {
            tempLocalConfig && tempLocalConfig.length > 0 && tempLocalConfig.forEach((c, index) => {
                if (c.id === config.id) {
                    tempLocalConfig[index] = config;
                }
            });
        }
        else {
            const id = generate_unique_key('rank_config');
            config.id = id;
            tempLocalConfig.push(config)
        }

        finalSave(tempLocalConfig)
    };


    const edit_config = (config) => {
        if (config) {
            showPopup(undefined, undefined, enums.default.popupType.element, AddEdit, {
                config: config,
                func: {
                    saveConfig: _saveConfig
                }
            })
        }

        if (!config) {
            showPopup(undefined, undefined, enums.default.popupType.element, AddEdit, {
                config: undefined,
                func: {
                    saveConfig: _saveConfig
                }
            })
        }
    };


    const delete_config = id => {
        let result = [];
        let tempConfigs = localConfigs ? [...localConfigs] : [];

        tempConfigs && tempConfigs.length > 0 && tempConfigs.map(config => {
            if (config.id !== id) {
                result.push(config);
            }
        });

        saveConfig(result);
        setConfigs(result);
    };


    return (
        <div>
            <Wrapper>

                <TitleHeader
                    title="Configure Your Ranking"
                // addFunction={edit_config}
                // showAdd={true}
                // showGoToRank={true}
                />


                <EditorInnerWrapper>
                    <div style={{ width: '100%', height: '25px' }}></div>
                    <div style={{ width: '100%', maxHeight: '500px' }} className="table_main">
                        <table className="table">
                            <thead>
                                <tr>
                                    <td>SN.</td>
                                    <td>Param</td>
                                    <td>Priority</td>
                                    <td>Linear</td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {localConfigs && localConfigs.length > 0 && localConfigs.map((config, index) => {
                                    return (
                                        <tr key={"config_list" + index}>

                                            <td>{index + 1}</td>
                                            <td>{config.param}</td>
                                            <td>{config.priority}</td>
                                            <td>{config.linear}</td>

                                            <td>
                                                <div className="action">
                                                    <div className="edit_btn" title="edit"
                                                        onClick={() =>
                                                            edit_config(config)
                                                        }>
                                                        <EditSvg size=".9rem" height=".8rem" />
                                                             &nbsp;&nbsp;Edit
                                                    </div>

                                                    <div className="delete_btn" title="delete"
                                                        onClick={() => delete_config(config.id)}
                                                    >
                                                        <DeleteSvg size=".9rem" height=".8rem" />
                                                             &nbsp;&nbsp;Delete
                                                        </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                </EditorInnerWrapper>
            </Wrapper>
        </div >
    )
}


const mapStateToProps = state => {
    return {
        configs: state.rankingReducer.configs
    }
}

export default connect(mapStateToProps, { getConfigs, saveConfig })(Index)
