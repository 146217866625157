import React from 'react';
import styled, { keyframes } from 'styled-components';

import { constants } from '../../utils/constants';

const Wrapper = styled.div`
    text-align: center;
    height: ${props => props.height ? props.height : props.error ? '80vh' : ''};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 999;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: ${props => props?.overLayColor ? props?.overLayColor : '#fdfdfd80'};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

/* background-color: red; */
`;



const Loader = styled.div`
  --size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: calc(var(--size) / 2);
  height: calc(var(--size) * 5);
`;

const wave = keyframes`
  25% {
    height: calc(var(--size) * 5);
    background-color: #fc00ff;
  }
  50% {
    height: var(--size);
    background-color: #9c73f8;
  }
`;

const Wave = styled.div`
  width: var(--size);
  height: var(--size);
  border-radius: var(--size);
  background-color: #ffd700;
  animation: ${wave} 2s infinite ease-in-out;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }

  &:nth-child(4) {
    animation-delay: 0.6s;
  }

  &:nth-child(5) {
    animation-delay: 0.8s;
  }
`;

const Loading = props => {

  const { height, v2Loader, msg, hideLoader=false, overLayColor, clickMsg } = props;

  return (
    <Wrapper height={height} id={"lo"} overLayColor={overLayColor} onClick={() => {
      if(clickMsg){
        alert(clickMsg)
      }
    }}>

      {/*  */}

      {/* v2Loader */}
      {!hideLoader && (
        <>
          {v2Loader ? <>
            <Loader>
              <Wave />
              <Wave />
              <Wave />
              <Wave />
              <Wave />
            </Loader>
            <p>{msg ? msg : 'Seeking the Data...'}</p></> : <img src={constants.CONTEXT_PATH + './loader.gif'} alt='Chart Loader' width='50' style={{ margin: 'auto' }} />}

        </>
      )}



    </Wrapper>
  )
};

export default Loading;