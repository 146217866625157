import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CloseSvg from '../svg/close';
import { apply_filters, clear_single_filter } from '../../actions/filter';
import { set_report_to_refresh } from '../../actions/report';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { saveInSession, getFromSession } from '../../utils/session.helper';
import { constants } from '../../utils/constants';
import { withRouter } from 'react-router';

export const FilterdItemRow = styled.div`
    max-width: ${props => props.scrollHide ? undefined : props.width ? props.width : '30rem'};
    display: flex;
    padding: 0px 10px;
    box-sizing: border-box;
    flex-wrap: ${props => props.multi_line ? "wrap" : "nowrap"};
    overflow-x: auto;
    align-items: center;
    padding-left: 0px;

    
    &::-webkit-scrollbar {
        background-color:red;
        width:2px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 2px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 2px;
    }

    .sub_text{
        color: #005d86;
        white-space: nowrap;
        font-size: .8rem;
        font-weight: 600;
    }
`;


export const Filterd = styled.div`
     max-width: 40rem;
     display: flex;
     align-items: center;
     justify-content: flex-start;
     box-sizing: border-box;
     margin: 2px 1px;
     font-weight: 500;
     position: relative;
     border: 1px solid #3a3838ad;
     border-radius: 13px;
     padding: 2px 4px;
     background: #fff;
    
     &:hover{
        .cross_mark{
            opacity: 1;
        }
     }
     .cross_mark{
        border-radius: 3px;
        width: 15px;
        height: 8px;
        /* margin/-top: 3px; */
        position: absolute;
        right: 1px;
        opacity: 0;
        background-color: #fff;
        cursor: pointer;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
            svg{
                fill: #308bc8;
            }
        }
     }
     .filter_name{
        font-size: .7rem;
        font-weight: 400;
        letter-spacing: normal;
        text-align: center;
        color: #005d86;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
     }
`;



const FilterDisplay = (props) => {

    const {
        id,
        width
    } = props;


    const filters = props.filters_cache && props.filters_cache[id];


    console.log("filters babu", filters)
    const [localFilter, setLocalFilter] = useState(undefined);

    useEffect(() => {

        const temp_filters = {};

        if (filters && Object.keys(filters).length > 0) {
            Object.keys(filters).forEach(key => {
                if (key === 'date_filter') {
                    const __date__filters = filters["date_filter"];
                    Object.keys(__date__filters) && Object.keys(__date__filters).length > 0 && Object.keys(__date__filters).forEach(date_f_key => {
                        temp_filters[date_f_key] = __date__filters[date_f_key];
                    })
                } else {
                    temp_filters[key] = filters[key];
                }
            })
        }
        setLocalFilter(temp_filters)
    }, [filters])


    const save_filter_in_session = (id, filter) => {
        let session_filter = getFromSession(constants.SESSION_KEYS.REPORT_FILTERS)
        let temp_filters = session_filter ? JSON.parse(session_filter) : {};
        temp_filters[id] = filter;
        saveInSession(constants.SESSION_KEYS.REPORT_FILTERS, JSON.stringify(temp_filters))

    }


    const remove_filter = (key, index, is_date_filter) => {

    };

    const filterComponent = () => {
        return (
            localFilter && Object.keys(localFilter).length > 0 &&
            <FilterdItemRow multi_line width={width}>
                {localFilter && Object.keys(localFilter).map((key, index) => {

                    let values = localFilter[key];
                    let is_date_filter = undefined;

                    if (localFilter[key] && Object.keys(localFilter[key]).length > 0 && !Array.isArray(localFilter[key])) {
                        const __date_filter__ = localFilter[key];
                        if (__date_filter__ && Object.keys(__date_filter__).length > 0) {
                            values = [];

                            Object.keys(__date_filter__).forEach((date_f_k) => {
                                is_date_filter = true;
                                if (date_f_k === 'custom_date') {
                                    const _c_date_ = __date_filter__[date_f_k]['date_from'] + " To " + __date_filter__[date_f_k]['date_to'];
                                    values.push(_c_date_)
                                } else {
                                    const _other_date_ = __date_filter__[date_f_k];
                                    const d_values = Object.values(_other_date_);
                                    values.push(...d_values.map(v => v + '__nf__' + date_f_k));
                                }
                            })
                        }
                    }
                    if (key !== 'reportId' && key && localFilter[key] && (typeof values === 'string') || (Array.isArray(values) && values.length > 0)) {
                        return (
                            <React.Fragment>
                                {Array.isArray(values) && values.length > 0 && values.map((v) => {
                                    const value = v.split('__nf__')[0];
                                    const value_1 = v.split('__nf__')[1];
                                    return (
                                        <Filterd >
                                            <div className="filter_name" title={key?.split("__nfx__db__")?.[0] + " = " + value}>{value}</div>
                                            <div
                                                className="cross_mark"
                                                title="Remove"
                                                onClick={() => {
                                                    props.clear_single_filter(id, key, value_1 ? value_1 + '_nfd_' + value : value);

                                                    setTimeout(() => {
                                                        props.set_report_to_refresh(id);
                                                    }, 200);
                                                }}
                                                style={{ height: '90%' }}
                                            > <CloseSvg size="10px" height="10px" /> </div>
                                        </Filterd>
                                    )
                                })}
                            </React.Fragment>
                        )
                    }
                })}
            </FilterdItemRow>
        )
    }

    return (
        <React.Fragment>
            {filterComponent(undefined)}
        </React.Fragment>
    )
}


const mapStateToProps = (state) => ({
    filters_cache: state.filterReducer.filters_cache
})

export default withRouter(connect(mapStateToProps, { apply_filters, set_report_to_refresh, clear_single_filter })(FilterDisplay))
