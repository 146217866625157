
export const colors = {
    primary: '#008AC7',
    // primary: '#337DFF',
    secondary: '#748891',
    tertiary: '#367188',
    forth: '#0C5672',
    fifth: '#63B6D6',
    sixth: '#005d86',
    seventh: '#BBD1D9',
    lightPrimary: '#63b6d652',
    disableColor: '#c3c3c3',
    black: '#000',
    lightBlack: '#222',
    maxPrimaryLight: '#e2f2f8',
    white: '#fff',
    gray: 'gray',
    lightGray: '#eaeaea',
    gullGray: '#848e92',
    codGray: '#1a1a1a',
    nightRiderGray: '#333333',
    red: 'red',
    green: 'green',
    pink: 'pink',
    yellow: 'yellow',
    orange: 'orange',
}



export const pallets = {
    primaryTheme: {
        color_sets: {
            // primary: '#008AC7',
            primary: '#337DFF',
            secondary: '#005d86',
            tertiary: '#63B6D6',
            forth: '#add7eb',
            fifth: '#122E39',
            sixth: '#BBD1D9',
            seventh: '#63b6d652',
            eight: '#748891',
            nine: "#c3c3c3",
            tenth: '#fff'
        },
        text_colors: {
            primary: '#1a1a1a',
            secondary: '#000',
            tertiary: '#0000008a',
            forth: '#008AC7',
            fifth: '#122E39',
            sixth: '#fff',
            seventh: '#BBD1D9'
        },

        background_colors: {
            primary: '#fff',
            secondary: '#f7f7f8',
            tertiary: '#000000',
            forth: '#eaeaea',
            fifth: '#008AC7',
            sixth: '#005d86'
        },
        border_colors: {
            primary: '#c3c3c3',
            secondary: '#eaeaea',
            tertiary: '#cccc',
            forth: '#000',
            fifth: '#eaeaea'
        },
        chart_colors: {
            primary: '#008AC7',
            secondary: '#82ca9d',
            tertiary: '#2E5E4E',
            forth: '#E6D5AA',
            fifth: "#5B443E",
            sixth: '#DADADE',
            seventh: '#CD6858',
            eighth: '#007FFBff',
            nineth: '#57A7DCff',
            tenth: "#64C5BDff"
        },
    },

    // this is secondaryTheme 
    secondaryTheme: {
        color_sets: {
            primary: '#CD6858',
            secondary: '#005d86',
            tertiary: '#63B6D6',
            forth: '#add7eb',
            fifth: '#122E39',
            sixth: '#BBD1D9',
            seventh: '#63b6d652',
            eight: '#748891',
            nine: "#c3c3c3",
            tenth: '#fff'
        },
        text_colors: {
            primary: '#222',
            secondary: '#000',
            tertiary: '#0000008a',
            forth: '#008AC7',
            fifth: '#122E39',
            sixth: '#fff',
            seventh: '#BBD1D9'
        },

        background_colors: {
            primary: '#fff',
            secondary: '#f7f7f8',
            tertiary: '#000000',
            forth: '#eaeaea',
            fifth: '#CD6858',
            sixth: '#005d86'
        },
        border_colors: {
            primary: '#c3c3c3',
            secondary: '#eaeaea',
            tertiary: '#cccc',
            forth: '#000',
            fifth: '#eaeaea'
        },
        chart_colors: {
            primary: '#CD6858',
            secondary: '#5B443E',
            tertiary: '#007FFBff',
            forth: '#64C5BDff',
            fifth: "#5B443E",
            sixth: '#DADADE',
            seventh: '#CD6858',
            eighth: '#007FFBff',
            nineth: '#57A7DCff',
            tenth: "#82ca9d"
        },
    },

    //third theme colors
    thirdTheme: {
        color_sets: {
            primary: 'green',
            secondary: '#005d86',
            tertiary: '#63B6D6',
            forth: '#add7eb',
            fifth: '#122E39',
            sixth: '#BBD1D9',
            seventh: '#63b6d652',
            eight: '#748891',
            nine: "#c3c3c3",
            tenth: '#fff'
        },
        text_colors: {
            primary: '#222',
            secondary: '#000',
            tertiary: '#0000008a',
            forth: '#008AC7',
            fifth: '#122E39',
            sixth: '#fff',
            seventh: '#BBD1D9'
        },

        background_colors: {
            primary: '#fff',
            secondary: '#f7f7f8',
            tertiary: '#000000',
            forth: '#eaeaea',
            fifth: 'green',
            sixth: '#005d86'
        },
        border_colors: {
            primary: '#c3c3c3',
            secondary: '#eaeaea',
            tertiary: '#cccc',
            forth: '#000',
            fifth: '#eaeaea'
        },
        chart_colors: {
            primary: 'green',
            secondary: '#5B443E',
            tertiary: '#007FFBff',
            forth: '#64C5BDff',
            fifth: "#5B443E",
            sixth: '#DADADE',
            seventh: 'green',
            eighth: '#007FFBff',
            nineth: '#57A7DCff',
            tenth: "#82ca9d"
        },
    }
};