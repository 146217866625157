import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TextBoxV1 from '../hoc/text.box.v1';
import TextArea from '../hoc/textArea';
import { Button } from '../hoc/button';
import HintsAdd from '../hints.add';
import HintSearch from '../hints.search';
import SelectOptionV1 from '../select-option/select.option.v1.1';
import { connect } from 'react-redux';




const PopupWrapper = styled.div`

    width: 100%;
    min-height: 30rem;
    padding: 0 0.8rem 0.8rem 0.8rem;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    background-color: #fff;
    max-height: 80rem;

    .row{
        width: 100%;
        margin-bottom: 1.5rem;
    }

    h1{
        font-size: 1.5rem;
        font-weight: 500;
        text-align: center;
        margin-top: 0px;
    }

    .create_action{
        
    }
`;

const TicketDetails = (props) => {

    // poops and popup data here
    const { close, popupKey, option, data } = props;
    const { popupData, _saveFun, } = data;


    // here are state of the component 
    const [formData, setFormData] = useState(undefined)


    const [ticket_actions, set_ticket_actions] = useState(undefined)

    const [is_add, set_is_add] = useState(undefined)






    useEffect(() => {
        setFormData(popupData)
        if (popupData && popupData.nf_rule_alert_ticket_actions) {
            set_ticket_actions(popupData.nf_rule_alert_ticket_actions)
        }
    }, [popupData])




    const _onChange = (key, value) => {
        let cloneFormData = formData ? Object.assign({}, formData) : {};
        cloneFormData[key] = value;
        setFormData(cloneFormData)
    }


    const _save = () => {

        let clone_form_data = formData ? Object.assign({}, formData) : {};
        // clone_form_data.recipients = recipients;
        // clone_form_data.view_rule_access = view_rule_access
        // clone_form_data.assigned_to = assigned_to
        // clone_form_data.alert_type = notification_methods
        // clone_form_data.modified = true;
        _saveFun(clone_form_data)
        close(option, popupKey)
    }

    const disabled = false


    const add_action = () => {

        const clone_ticket_actions = ticket_actions ? [...ticket_actions] : [];
        clone_ticket_actions.push({
            name: '',
            remarks: '',
            id: undefined,
        })
        set_is_add(true)
        set_ticket_actions(clone_ticket_actions)
    }






    return (
        <PopupWrapper>
            <div style={{
                display: 'flex'
            }}>
                <div style={{
                    width: '50%'
                }}>
                    <SelectOptionV1
                        label={"Status"}
                        options={[
                            { name: 'Open', value: 'open' },
                            { name: 'Close', value: 'close' },
                            { name: 'Pending', value: 'pending' }
                        ]}
                        display_key="name"
                        value_key="value"
                        none_value={false}
                        value={formData?.ticket_status}
                        on_change={(value) => {
                            _onChange("ticket_status", value)
                        }}
                    />
                </div>
            </div>



            {/* <div className='create_action'>

            </div> */}

            {/* <p style={{textAlign: 'center'}}>You don't have any action please create action.</p> */}

            {/* {popupData.nf_rule_alert_ticket_actions?.length > 0 && ( */}
            <h3>Ticket Actions</h3>
            <table>
                <thead>
                    <tr>
                        <td>Name</td>
                        <td>Remarks</td>
                        <td>Action</td>
                    </tr>
                </thead>
                <tbody>
                    {ticket_actions && ticket_actions?.length > 0 && ticket_actions.map((action, index) => {

                        if (is_add) {
                            return (
                                <tr>
                                    <td>
                                        <TextBoxV1

                                        />
                                    </td>
                                    <td>
                                        <TextBoxV1
                                        />
                                    </td>
                                    <td></td>
                                </tr>
                            )
                        }
                        else {
                            return (
                                <tr>
                                    <td>{action.name}</td>
                                    <td>{action.remarks}</td>
                                    <td></td>
                                </tr>
                            )
                        }
                    })}

                </tbody>
            </table>

            <div style={{ width: '200px', margin: '10px' }}>
                <Button onClick={() => {
                    add_action()
                }}>
                    + Add Action
                </Button>
            </div>
            {/* )} */}
            <div style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: 'auto',
                marginLeft: 'auto'

            }}>




                <Button
                    primary={true}
                    disabled={disabled}
                    onClick={() => _save()}
                    mR="1rem"
                >
                    Save
                </Button>

                <Button
                    bgColor={'#e6e6e6'}
                    onClick={() => {
                        setFormData(undefined);
                        close(option, popupKey)
                    }}
                    mR="1rem"
                >
                    Cancel
                </Button>
            </div>

        </PopupWrapper>
    )
}


const mapStateToProps = (state, props) => ({
    hints: state.reportReducer.hints,
    accessGroupList: state.groupAccessReducer.accessGroupList,
    usersList: state.accountReducer.usersList
})

export default connect(mapStateToProps, {})(TicketDetails);
