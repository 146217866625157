import React, { useState, useEffect, useRef } from 'react';
import { DisplayFiledWrapper, DisplayColumnData } from '../select-display-field/select.display.field.styled';
import { RadioCheckMark } from '../hoc/radiobox';
import { Button } from '../hoc/button';
import { Gap } from '../gap/index';
import TextBox from '../hoc/textBox';




const LocalFilterPicker = (props) => {

    const { close, popupKey, option, data } = props;

    const {

        options_values,
        title,
        selected_values,
        width,
        hideSearchbox = false,
        _save_

    } = data;

    const [searchValue, setSearchValue] = useState("")
    const [local_selected_values, set_local_selected_values] = useState([]);




    let prevLetter = '';
    let aTozArray = [];


    useEffect(() => {
        if (selected_values) {
            set_local_selected_values([...selected_values])
        }
    }, [selected_values]);


    const sorted_display_items = JSON.parse(JSON.stringify(options_values)).filter((data) => {

        if (typeof data === 'string') {

            if (selected_values.indexOf(data) > -1) {
                return false;
            }
            if (data === '_id') return false;
            if (data === '') return false;
            if (searchValue === '') {
                return true;
            }
            else if (data.toLowerCase().includes(searchValue.toLowerCase())) {
                return true;
            }
        } else return false;
    });


    sorted_display_items.sort(function (a, b) {

        let left = typeof a === 'string' ? a.trim().toLowerCase() : a;
        let right = typeof b === 'string' ? b.trim().toLowerCase() : b;

        return left.localeCompare(right);
    });



    const genCharArray = (charA, charZ) => {
        let a = [];
        let i = charA.charCodeAt(0);
        let j = charZ.charCodeAt(0);

        for (i; i <= j; i++) {
            a.push(String.fromCharCode(i));
        }

        return a
    }

    aTozArray = genCharArray("a", "z");


    const elRefs = useRef([]);
    // add or remove refs
    elRefs.current = Array(26).fill().map((_, i) => elRefs.current[i] || React.createRef());



    const on_item_click_v2 = (item) => {

        const temp_selected_values = local_selected_values ? [...local_selected_values] : [];

        if (temp_selected_values.indexOf(item) > -1) {
            temp_selected_values.splice(temp_selected_values.indexOf(item), 1);
        } else {
            temp_selected_values.push(item)
        }

        set_local_selected_values(temp_selected_values)

    }

    return (
        <DisplayFiledWrapper width={width}>

            <React.Fragment>
                <React.Fragment>
                    <div style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: `${hideSearchbox ? 'flex-start' : 'space-between'}`,
                        flexDirection: 'row',
                        marginBottom: '27px',
                    }}>

                        <h3 style={{ marginRight: `${hideSearchbox ? '3rem' : undefined}` }}>{title ? title : ""}</h3>

                        {!hideSearchbox && (
                            <div style={{ width: '55%', padding: '0px 15px', boxSizing: 'border-box' }}>
                                <TextBox
                                    type="text"
                                    label="Type here to quick search"
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                            </div>
                        )}
                    </div>


                    <DisplayColumnData  >
                        <ul>
                            {selected_values && Array.isArray(selected_values) && selected_values.length > 0 && (
                                <React.Fragment>
                                    <li className="sort_title">✓</li>
                                    {selected_values && selected_values.map((selected, _index) => {
                                        return (
                                            <React.Fragment key={"selected" + _index}>
                                                <li
                                                    className="item"
                                                    onClick={() => {
                                                        on_item_click_v2(selected)
                                                    }}
                                                >
                                                    <RadioCheckMark checked={local_selected_values.indexOf(selected) > -1} />
                                                    <span
                                                        title={selected}
                                                        className="item_name"
                                                    >
                                                        {selected}
                                                    </span>
                                                </li>
                                            </React.Fragment>
                                        )
                                        // }
                                    })}
                                </React.Fragment>
                            )}


                            {sorted_display_items && sorted_display_items.length > 0 &&
                                sorted_display_items.map((item, index) => {

                                    const boo = prevLetter === item.trim().substring(0, 1).toLowerCase() ? true : false;
                                    prevLetter = item.trim().substring(0, 1).toLowerCase();
                                    const firstLetter = item.trim().substring(0, 1).toLowerCase();

                                    return (
                                        <React.Fragment
                                        >
                                            {boo === false &&
                                                <li
                                                    className="sort_title"
                                                    ref={elRefs.current[aTozArray.indexOf(firstLetter)]}
                                                >
                                                    {item.substring(0, 1)}
                                                </li>
                                            }

                                            <li
                                                className="item"
                                                onClick={() => {
                                                    on_item_click_v2(item)
                                                }}
                                            >

                                                <RadioCheckMark
                                                    checked={local_selected_values.indexOf(item) > -1} />
                                                <span
                                                    className="item_name"
                                                    title={item}
                                                >
                                                    {item}
                                                </span>
                                            </li>

                                        </React.Fragment>
                                    )
                                })
                            }

                            {!(sorted_display_items && sorted_display_items.length > 0) &&
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        marginTop: '30px'

                                    }}
                                >No related fields found</div>
                            }

                        </ul>

                    </DisplayColumnData>
                </React.Fragment>
            </React.Fragment>


            <Gap h="1.643rem" />


            <div>
                <Button
                    primary={true}
                    width="6rem"
                    mR="16px"
                    onClick={() => {
                        _save_(local_selected_values)
                        close(option, popupKey)
                    }}>Save</Button>
            </div>

        </DisplayFiledWrapper>
    )
}
export default LocalFilterPicker;
