import React, { useState, useEffect } from 'react';
import { constants } from '../../utils/constants';
import { Button } from '../hoc/button';
import { Wrapper} from './entity-info.styled';
import { PageInnerWrapper } from '../hoc/styled.hoc'
import { DivRow } from '../styled-hoc';
import { connect } from 'react-redux';
import { getEntities, saveEntities } from '../../actions/entity';
import SelectOption from '../select-option';
import CheckBox from '../hoc/checkbox';
import { getFromSession } from '../../utils/session.helper';
import TitleHeader from '../title-header';

function Index(props) {

	const [localEntities, setLocalEntities] = useState(undefined);
	// const [entitiesName, setEntitiesName] = useState(undefined);
	const [selectedEntite, setSelectedEntite] = useState(undefined);
	const [selectedEntites, setSelectedEntites] = useState([]);
	const [relationshipScope, setRelationshipScope] = useState('');


	useEffect(() => {
		props.getEntities();
	}, []);


	useEffect(() => {
		setLocalEntities(props.entities)
		let tempEntitesNames = [];
		{
			props.entities && props.entities.lenght > 0 && props.entities.map((entity, index) => {

				tempEntitesNames.push(entity.name)
			})
		}
		// setEntitiesName(tempEntitesNames)
	}, [props.entities]);


	const onChange = (name) => {
		let tempEntites = [...selectedEntites];

		let index = tempEntites.indexOf(name);

		tempEntites.splice(index, 1)
		setSelectedEntites(tempEntites)

		localEntities && localEntities.length > 0 && localEntities.map((entity) => {
			if (entity.name === name) {
				setSelectedEntite(entity.name)
			}
		})
	};


	const selectEntity = (name) => {

		let tempEntites = [...selectedEntites];
		let index = tempEntites.indexOf(name);

		if (index === -1) {
			tempEntites.push(name)
		}
		else {
			tempEntites.splice(index, 1)
		}
		setSelectedEntites(tempEntites)

	};


	const onSave = () => {
		const clientIdFromSession = getFromSession(constants.SESSION_KEYS.CLIENT_ID);
		const clientId = process.env.REACT_APP_DEV_LOGIN_KEY ? (clientIdFromSession ? clientIdFromSession : constants.CLIENT_ID) : constants.CLIENT_ID;

		const data = [{
			name: selectedEntite,
			connected_to: selectedEntites,
			relationship_scope: relationshipScope,
			client_id: clientId
		}]

		props.saveEntities(data);

		setSelectedEntite('');
		setSelectedEntites([]);
		setRelationshipScope('')
	};


	return (
		<div>
			<Wrapper>
				<TitleHeader title="Create Entity Info"/>
				<PageInnerWrapper>
					<div style={{ width: '75%' }}>
						<div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: '2.571rem' }}>
							<DivRow w="47%">
								<div style={{ marginBottom: '2.571rem' }}>
									<div style={{
										position: 'relative'
									}}>

										<SelectOption
											label="Name"
											onChangeFunc={onChange}
											selections={localEntities}
											valueType="question"
											displayKey='name'
											valueKey='name'
											activeValue={selectedEntite ? selectedEntite : ''}
											width="100%"
											padding="0px 0px"
											paddingRight='25px'
											lineHeight="2.857rem"
											menuLineHeight="1.8rem"
											hideCase='true'
										/>

									</div>
								</div>

								<div>
									<label>Entity Relationship Scope</label>
									<div style={{
										position: 'relative'
									}}>
										<textarea value={relationshipScope} onChange={(e) => setRelationshipScope(e.target.value)} />
									</div>
								</div>
							</DivRow>


							<DivRow w="47%">
								<label>Connected To (Entities)</label>
								<div style={{
									position: 'relative'
								}}>
									<ul className="entitys_list">
										{localEntities && localEntities.length > 0 && localEntities.map((entity, index) => {
											if (entity.name !== selectedEntite) {
												return (
													<li>
														<CheckBox
															onClick={() => selectEntity(entity.name)}
															checked={selectedEntites && selectedEntites.indexOf(entity.name) === -1 ? false : true}
														/>
                                                        &nbsp;<span>{entity.name}</span>
													</li>
												)
											}
										})}
									</ul>

								</div>
							</DivRow>
						</div>
					</div>

					<div
						style={{
							padding: '25px 0px',
							borderTop: '1px solid #ccc',
							marginTop: 'auto',
							display: 'flex'
						}}>
						<Button
							primary={true}
							width="6rem"
							mR="1.143rem"
							onClick={onSave}
						>Save</Button>
						<Button
							width="6rem"
							bgColor={'#e6e6e6'}
							onClick={() => {
							}}
						>
							Cancel
                        </Button>
					</div>
				</PageInnerWrapper>
			</Wrapper>
		</div >
	)
}


const mapStateToProps = (state) => ({

	entities: state.entityReducer.entities
})

export default connect(mapStateToProps, { getEntities, saveEntities })(Index)
