import { simpleDispatch_post, dispatchAction, simpleDispatch_get, simpleDispatch_delete, raiseSuccess } from './helper';
import * as actionTypes from '../action-types/etl';
import { constants } from '../utils/constants';
import { get, post } from '../data.service';
import * as loaderTypes from '../action-types/loader';
import { getStreamingJobs } from './streaming'



export const get_file_logs = (file_process_date) => async dispatch => {

    dispatchAction(dispatch, actionTypes.CLEAR_QUESTION_INFO)

    const url = constants.END_POINTS.JOB_API + constants.END_POINTS.JOB_V1.POINT + constants.END_POINTS.JOB_V1.GET_FILE_LOGS_BY_DATE;

    let _body = {
        file_process_date: file_process_date,
    }


    await simpleDispatch_post(dispatch, url, _body, actionTypes.GET_ALL_FILES_LOGS);
}




export const delete_file_log = (id) => async dispatch => {

    dispatchAction(dispatch, actionTypes.CLEAR_QUESTION_INFO)

    const url = constants.END_POINTS.JOB_API + constants.END_POINTS.JOB_V1.POINT + constants.END_POINTS.JOB_V1.DELETE_FILE_LOG + `?file_id=${id}`;


    await simpleDispatch_get(dispatch, url, actionTypes.DELETE_FILE_LOG);
}





export const saveEtlConfig = (data, message, history, path_to_redirect) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.ETL.POINT + constants.END_POINTS.ETL.SAVE_ETL_CONFIG;

    // await simpleDispatch_post(dispatch, url, data, actionTypes.SAVE_ETL_CONFIG, undefined, undefined, { message: message });
    // getConfigReports()(dispatch)

    try {
        const response = await post(url, data);
        if (response && response.code === 200) {
            raiseSuccess(dispatch, {
                type: 'success',
                message: message ? message : 'config Saved Successfully'
            })
            dispatchAction(dispatch, actionTypes.SAVE_ETL_CONFIG, undefined, undefined, undefined, 0);
            dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
            getConfigReports()(dispatch)
            if (history && path_to_redirect) {
                history.push(path_to_redirect)
            }
        }

    } catch (error) {
        raiseSuccess(dispatch, {
            type: 'error',
            title: 'ERROR',
            message: error && error.message ? error.message : 'Somthing Went wrong..'
        })
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
    }


};




export const getAllInfoOfQuestion = (query, db_info_id, is_etl_making_request) => async dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_QUESTION_INFO)



    // const url = constants.END_POINTS.API + constants.END_POINTS.ETL.POINT + constants.END_POINTS.ETL.GET_ALL_INFO_OF_QUESTION;
    const url = constants.END_POINTS.API + constants.END_POINTS.INSIGHTS.POINT + constants.END_POINTS.INSIGHTS.GET_COLUMN_INFO_BY_QUESTION

    let _body = {
        question: query,
        db_info_id,
        is_etl_making_request
    }


    await simpleDispatch_post(dispatch, url, _body, actionTypes.GET_ALL_INFO_OF_QUESTION);
}



export const clearQuestionInfo = () => async (dispatch) => {
    dispatchAction(dispatch, actionTypes.CLEAR_QUESTION_INFO)
}


export const getConfigReports = (loaderHide, is_backup_and_sync) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.ETL.POINT + constants.END_POINTS.ETL.GET_CONFIG_REPORTS;

    const _post = {
        is_backup_and_sync
    }

    // await simpleDispatch_post(dispatch, url, _post, actionTypes.GET_CONFIG_REPORTS, loaderHide);
    await simpleDispatch_get(dispatch, url, actionTypes.GET_CONFIG_REPORTS, loaderHide);
}


export const deleteConfig = (id, where, is_backup_and_sync) => async (dispatch) => {
    try {
        const url = constants.END_POINTS.API + constants.END_POINTS.ETL.POINT + constants.END_POINTS.ETL.DELETE_CONFIG + `? config_id = ${id}`;
        const response = await get(url);
        if (response && response.code === 200) {
            if (where === 'streaming') {
                getStreamingJobs()(dispatch)
            }
            getConfigReports(undefined, is_backup_and_sync)(dispatch)
            dispatchAction(dispatch, actionTypes.DELETE_CONFIG_SUCCESS, undefined, undefined, undefined, 0)
            dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        }

    } catch (error) {
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
    }
}


export const getConfigInfo = (id) => async (dispatch) => {
    const url = constants.END_POINTS.API + constants.END_POINTS.ETL.POINT + constants.END_POINTS.ETL.GET_CONFIG_INFO + `? config_id = ${id} `;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_CONFIG_INFO_SUCCESS);

}


export const clearJobInformation = () => async (dispatch) => {
    dispatchAction(dispatch, actionTypes.CLEAR_JOB_INFO)
}




export const getJobLogInfo = (id) => async (dispatch) => {
    dispatchAction(dispatch, actionTypes.MT_JOB_LOG_INFO_SUCCESS)
    const url = constants.END_POINTS.API + constants.END_POINTS.ETL.POINT + constants.END_POINTS.ETL.GET_JOB_INFO + `? config_id = ${id} `;
    await simpleDispatch_get(dispatch, url, actionTypes.JOB_LOG_INFO_SUCCESS);

}

export const pushRowDataToStore = (data) => async (dispatch) => {
    await dispatchAction(dispatch, actionTypes.PUSH_EDITED_ROW_TO_STORE, data)
}



export const saveMultipalConfig = (data, type, where) => async (dispatch) => {
    let _body = {
        configs: data,
        type: type
    }

    const url = constants.END_POINTS.API + constants.END_POINTS.ETL.POINT + constants.END_POINTS.ETL.SAVE_MULTIPAL_CONFIG;
    try {
        const response = await post(url, _body);
        if (response && response.code === 200) {
            raiseSuccess(dispatch, {
                type: 'success',
                message: 'Updated SuccessFully'
            })
            if (where === 'streaming') {
                getStreamingJobs()(dispatch)
            }
            dispatchAction(dispatch, actionTypes.UPDATE_MULTIPAL_CONFIG, undefined, undefined, undefined, 0)
            dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        }

    } catch (error) {
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
    }
}




export const getTableDataOfExtractedColumn = (table_name, columns) => async (dispatch) => {
    let _body = {
        table_name,
        columns
    }

    dispatchAction(dispatch, actionTypes.CLEAR_TABLE_EXTRACTED_DATA)

    const url = constants.END_POINTS.API + constants.END_POINTS.ETL.POINT + constants.END_POINTS.ETL.GET_TABLE_DATA_OF_EXTRACTED_COLUMN;
    await simpleDispatch_post(dispatch, url, _body, actionTypes.GET_TABLE_DATA_OF_EXTRACTED_COLUMN);

}


export const updateJob = (configId, field, value, job_type, message) => async (dispatch) => {
    const url = constants.END_POINTS.API + constants.END_POINTS.ETL.POINT + constants.END_POINTS.ETL.UPDATE_JOB;
    let _body = {
        configId,
        field,
        value,
        job_type
    }

    await simpleDispatch_post(dispatch, url, _body, actionTypes.UPDATE_MULTIPAL_CONFIG, undefined, undefined, { message: message });

}





export const getTargetTableNames = (config_id) => async (dispatch) => {
    let post_data = {
        database_id: config_id
    }
    const url = constants.END_POINTS.API + constants.END_POINTS.DB_INFO.POINT + constants.END_POINTS.DB_INFO.GET_TABLE_OF_DB;
    await simpleDispatch_post(dispatch, url, post_data, actionTypes.GET_TARGET_TABLE_NAMES, true, undefined);
}


export const getTargetColumnNames = (config) => async (dispatch) => {
    const url = constants.END_POINTS.API + constants.END_POINTS.DB_INFO.POINT + constants.END_POINTS.DB_INFO.GET_COLUMNS_OF_TABLE;
    await simpleDispatch_post(dispatch, url, config, actionTypes.GET_TARGET_COLUMN_NAMES, true, undefined);
}



export const getTargetTableData = (config) => async (dispatch) => {
    dispatchAction(dispatch, actionTypes.CLEAR_TARGET_TABLE_DATA)
    const url = constants.END_POINTS.API + constants.END_POINTS.DB_INFO.POINT + constants.END_POINTS.DB_INFO.GET_TABLE_DATA;
    await simpleDispatch_post(dispatch, url, config, actionTypes.GET_TARGET_TABLE_DATA, true, undefined);
}




export const validateTheEtlData = (data, database_id) => async dispatch => {

    let post_data = {
        load_config: data,
        database_id: database_id,
    }
    dispatchAction(dispatch, loaderTypes.LOADER_SHOW, undefined, undefined, undefined, 0);

    try {
        const url = constants.END_POINTS.API + constants.END_POINTS.ETL.POINT + constants.END_POINTS.ETL.VALIDATE_ETL_CONFIG;
        const response = await post(url, post_data);

        if (response && response.code === 200) {
            let _data = response.data ? response.data : undefined;
            dispatchAction(dispatch, actionTypes.VALIDATE_THE_ETL_DATA, _data, undefined, undefined, 0)
            dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);

        }


    } catch (error) {
        dispatchAction(dispatch, loaderTypes.LOADER_HIDE, undefined, undefined, undefined, 0);
        // showError(dispatch, error);
    }
};




export const raiseValidationError = (message) => async (dispatch) => {
    raiseSuccess(dispatch, {
        type: 'error',
        title: 'ERROR',
        message: message,
    })
}


export const getCollectionsOfMongo = (name) => async (dispatch) => {
    const url = constants.END_POINTS.API + constants.END_POINTS.ETL.POINT + constants.END_POINTS.ETL.GET_COLLECTIONS_FROM_MONGO_BY_CLIENT;
    const _body = {
        name: name,
    }
    await simpleDispatch_post(dispatch, url, _body, actionTypes.GET_COLLECTIONS_FROM_MONGO_BY_CLIENT, true, undefined);
}

export const getCollectionsOfBySourcePath = (main_directory, processed_directory, rename_prefix) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.ETL.POINT + constants.END_POINTS.ETL.LOAD_FILE_IN_MONGO;
    const _body = {
        main_directory,
        processed_directory,
        rename_prefix
    }
    await simpleDispatch_post(dispatch, url, _body, actionTypes.GET_COLLECTIONS_FROM_MONGO_BY_SOURCE_PATH, true, undefined);
}



export const viewDirectoryFileAction = (path_of_folder, dirName) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.ETL.POINT + constants.END_POINTS.ETL.GET_THE_FILE_IN_FOLDER;
    const _body = {
        path_of_folder,
        directory_name: dirName
    }
    await simpleDispatch_post(dispatch, url, _body, actionTypes.GET_THE_PATH_IN_FOLDER_SUCCESS, true, undefined);
}

export const getDirectoryFileData = (path_of_folder, file_name, is_main_dir) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.ETL.POINT + constants.END_POINTS.ETL.GET_THE_FILE_DATA_FROM_FILE;
    const _body = {
        path_of_folder,
        file_name,
        is_main_dir
    }
    await simpleDispatch_post(dispatch, url, _body, actionTypes.GET_THE_FILE_DATA_FROM_FILE_SUCCESS, true, undefined);
}


export const pushSourceMappingTableRowToStore = (_index, data) => async dispatch => {
    let _payload = {
        index: _index,
        data: data,
    }

    dispatchAction(dispatch, actionTypes.PUSH_EDITED_SOURCE_MAPPING_ROW_TO_STORE, _payload)

}


export const pushSourceMappingDataToStore = (data) => async dispatch => {

    dispatchAction(dispatch, actionTypes.PUSH_SOURCE_MAPPING_DATA_TO_STORE, data)

}