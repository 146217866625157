import React, { useState, useEffect } from 'react';
import TitleHeader from '../title-header';
import { EditorMainWrapper, StepContentWrapper, CustomList } from './etl.add.edit.styled';
import { PageInnerWrapper } from '../hoc/styled.hoc';
import { Button } from '../hoc/button';
import { connect } from 'react-redux';
import {
    saveEtlConfig, getAllInfoOfQuestion,
    getConfigInfo, getConfigReports, getTableDataOfExtractedColumn, validateTheEtlData, pushSourceMappingDataToStore, getCollectionsOfMongo,
    clearJobInformation, clearQuestionInfo, getTargetTableNames, getTargetColumnNames, getTargetTableData, raiseValidationError, getCollectionsOfBySourcePath
} from '../../actions/etl';
import { get_db_info } from '../../actions/connection'
import RippleEffect from '../hoc/ripple-effect'
import SelectDisplayField from '../select-display-field';
import * as enums from '../../utils/enums';
import { showPopup, insertAtSpecificLocationInArray, getParamByName, getDbInfoId, isDefaultLiveDb } from '../../utils';
import * as _ from 'lodash';
import { getEntities } from '../../actions/entity';
import { withRouter } from 'react-router-dom'
import TablePopup from './table.popup'
import Steper from '../steper';
import BackSvg from '../svg/back';
import { frequencyTypes } from '../../utils/frequency.types';
import LeftArrow from '../svg/leftArrow';
import DraftSvg from '../svg/draft';
import FormulaCreator from '../formula-creator';
import SourceTableMapping from './source.table.mapping';
import ScheduleJob from './schedule.job';
import Extraction from './extraction';
import PrimaryPopup from './primaryPopup';
import { getFromSession } from '../../utils/session.helper';
import { constants } from '../../utils/constants';
import { dispatchHeaderInformation } from '../../actions/common';
import SelectOption from '../select-option'
import ExtractionV1 from './extraction/extraction_v1.1';
import SourceTableMappingV1 from './load_mapping';
import IncrementalSection from './extraction/incremental';
import { get_data_wrangler_config_by_job_id, save_data_wrangler_job } from '../../actions/data_wrangler';
import Processing from './processing';
import ChooseScourceType from './chooseSourceType';
import JobInputs from './job_info';
import StepperButtons from './stepper.button';



const steps = [
    'Configure Source',
    'Configure Destination',
    'Source Extraction',
    'Source & Target Mapping',
    'Schedule Your Job'
]


const disableSteps = [
    'Configure Destination',
    'Source Extraction',
    'Source & Target Mapping',
]

const Index = (props) => {

    const {

        getHintsByDbInfo, hintsByDbInfo, entities, targetTableNames, targetColumnNames, raiseValidationError,
        getAllInfoOfQuestion, questionInfo, db_infos, getTargetTableNames, getTargetColumnNames, validationData,
        getConfigInfo, history, currentConfigInfo, getConfigReports, clearQuestionInfo, get_db_info, validateTheEtlData,
        getCollectionsOfBySourcePath

    } = props;

    const IS_DEFAULT_LIVE_DB = isDefaultLiveDb()


    const { location } = history;
    const [activeStep, setActiveStep] = useState(0);
    const [innerStep, setInnerStep] = useState(0);

    const [jobType, setJobType] = useState("");

    const [config_type, setConfigType] = useState('nlp');

    const [selectedColumn, setSelectedColumn] = useState([]);

    const [localHintsByEntity, setLocalHintsByEntity] = useState(undefined);

    const [formData, setFormData] = useState({});

    const [frequencys, setFrequencys] = useState([{ type: 'e' }, { type: 't' }, { type: 'l' }]);

    const [localQuestionInfo, setQuestionInfo] = useState(undefined);
    const [whichFrequencyShow, setWhichFrequencyShow] = useState(undefined);
    const [tableData, setTableData] = useState(undefined);
    const [tableHead, setTableHead] = useState(undefined);
    const [targetColumnSuggestions, setTargetColumnSuggestions] = useState(undefined);
    const [currentTableName, setCurrentTableName] = useState(undefined);
    const [sourceColumns, setSourceColumns] = useState(undefined);
    const [transformationType, setTransformationType] = useState('Append');
    const [primaryKeyValue, setPrimaryKeyValue] = useState(undefined);
    const [localError, setLocalError] = useState(undefined);
    const [primaryError, setPrimaryError] = useState(undefined);
    const [showErrorPopup, setErrorPopup] = useState(undefined);
    const [ignoreError, setIgnoreError] = useState(undefined);
    const [isValidateData, setIsValidateData] = useState(undefined);
    const [errorPopupValue, setErrorPopupValue] = useState(undefined);

    const [meargError, setMeargError] = useState(undefined);
    const [activeClient, setActiveClient] = useState(undefined);

    const [sortOrder, setSortOrder] = useState(undefined);
    const [savedProperties, setProperties] = useState(undefined);

    const is_edit_mode = getParamByName(location.search, 'config_id');

    const [local_questionInfo, set_local_questionInfo] = useState(undefined)


    const [db_info_ids, set_db_info_ids] = useState(undefined);
    const [reporting_db_info_id, set_reporting_db_info_id] = useState(undefined);

    const [date_column_selection, set_date_column_selection] = useState(undefined)

    // =======================v2 ============

    const [job_id, set_job_id] = useState(undefined);

    //    # step1
    const [extraction_state, set_extraction_state] = useState({
        data_loading_mode: 'Append'
    });


    const [db_interaction_type, set_db_interaction_type] = useState("etl_natural_language");
    const [data_source_type, set_data_source_type] = useState("RDBMS");
    const [show_stepper, set_show_stepper] = useState(false)
    const [local_report, set_local_report] = useState(undefined);
    const [report_date_columns, set_report_date_columns] = useState(undefined);

    // step 2

    const [load_state, set_load_state] = useState({
        is_reactive: true
    });

    const [load_mapping_state, set_load_mapping_state] = useState(undefined);

    // step 3 

    // step 4

    const [incremental_state, set_incremental_state] = useState();

    const [primary_key_selections, set_primary_key_selections] = useState();



    const [pre_step, set_pre_step] = useState(0)
    const [fileMapping, setFileMapping] = useState(undefined);


    // default job state

    const [job_state, set_job_state] = useState({
        grouping_tag: 'etl',
        job_status: 'active', // only at the first time
        job_type: "etl",
        job_status: "ready", // at the first time ,
        script_type: 'node' // 
    });





    useEffect(() => {

        set_local_questionInfo(questionInfo)
        set_date_column_selection(questionInfo?.date_columns);

        const temp_mapping = [];
        const temp_load_mapping_state = [];

        const clone_load_mapping_state = load_mapping_state ? [...load_mapping_state] : [];

        const is_load_mapping_exist = (source_column) => {
            return clone_load_mapping_state?.find((s) => s.source_column === source_column);
        }


        (questionInfo?.columns_extracted || []).map((c) => {

            const is_already_exist = is_load_mapping_exist(c.table_column);

            if (is_already_exist) {
                temp_load_mapping_state.push({
                    ...is_already_exist,
                    source_table: c.column_table,
                    source_column: c.table_column,
                    source_column_alias: c.column_name,
                    source_data_type: is_already_exist?.source_data_type || c.source_data_type || c.data_type,
                    target_data_type: c.target_data_type || c.data_type,
                    character_length: is_already_exist?.character_length || c.character_length,
                    modified: true,

                })
            } else {
                temp_load_mapping_state.push({
                    source_table: c.column_table,
                    source_column: c.table_column,
                    target_table: '',
                    target_column: '',
                    source_data_type: c.source_data_type || c.data_type,
                    target_data_type: c.target_data_type || c.data_type,
                    character_length: c.character_length,
                    is_direct_mapping: 'yes',
                    source_column_alias: c.column_name
                })
            }

            // temp_mapping.push({
            //     source_column: c.table_column,
            //     source_table: c.column_table,
            //     // target_column: c.table_column,
            //     target_column: '',
            //     source_column_alias: c.column_name,
            //     target_table: '',
            //     is_direct_mapping: 'yes',
            //     source_data_type: c.data_type,
            //     target_data_type: c.data_type,
            // })
        });

        set_load_mapping_state(temp_load_mapping_state);
    }, [props.questionInfo])



    // let's create load_mapping_state 

    useEffect(() => {

        if (local_report && local_report?.report_items) {

            const temp_load_mapping_state = [];
            const temp_primary_selections = [];

            const _transformation_for_string = ["monthname", "year", "week", 'aging(days)', 'aging(months)', 'aging(quarters)', 'aging(weeks)', 'aging(years)', 'fqtr', 'fy', 'qtr']

            const columns = [];

            let column_config = [];
            const report_items = local_report.report_items;

            const clone_load_mapping_state = load_mapping_state ? [...load_mapping_state] : [];

            const is_load_mapping_exist = (source_column) => {
                return clone_load_mapping_state?.find((s) => s.source_column === source_column);
            }

            if (report_items && report_items?.length > 0) report_items.forEach((item) => column_config = [...column_config, ...(item.column_infos || [])])

            // console.log("column_config", column_config)
            if (column_config?.length > 0) column_config.filter((c => !c.deleted)).forEach((c) => {

                const data_type = c.t_name && _transformation_for_string.indexOf(c.t_name) > -1 ? 'string' : c.data_type;

                temp_primary_selections.push(c.orignal_name);
                const is_already_exist = is_load_mapping_exist(c.orignal_name);

                if (is_already_exist) {

                    temp_load_mapping_state.push({
                        ...is_already_exist,
                        source_table: c.table_name,
                        source_column: c.orignal_name,
                        source_data_type: is_already_exist?.source_data_type || c.source_data_type || data_type,
                        target_data_type: c.target_data_type || data_type,
                        character_length: is_already_exist?.character_length || c.character_length,
                        modified: true,

                    });

                } else {
                    temp_load_mapping_state.push({
                        source_table: c.table_name,
                        source_column: c.orignal_name,
                        target_table: "",
                        target_column: '',
                        // target_column: c.orignal_name,
                        source_data_type: c.source_data_type || data_type,
                        target_data_type: c.target_data_type || data_type,
                        character_length: c.character_length,
                        is_direct_mapping: 'yes'
                    })
                }

                console.log("temp_load_mapping_state", temp_load_mapping_state)
                set_load_mapping_state(temp_load_mapping_state)
                set_primary_key_selections(temp_primary_selections)

            })
        }
    }, [local_report])


    useEffect(() => {

        let job_id = getParamByName(location.search, 'config_id');
        const wrangler_config = job_id && props?.wrangler_config_cache?.[job_id] || undefined;

        if (wrangler_config) {

            const extraction_data = wrangler_config?.extraction;
            const load = wrangler_config?.load;
            const load_mapping = wrangler_config?.load?.load_mappings;
            const job_data = wrangler_config?.job_data;
            const report_data = wrangler_config?.report;
            const file_mapping = wrangler_config?.file_mapping;


            if (extraction_data?.incremental_date_column) {
                extraction_data.incremental_date_column = (extraction_data?.incremental_date_column?.length > 0 && Array.isArray(extraction_data?.incremental_date_column)) ? extraction_data?.incremental_date_column : extraction_data?.incremental_date_column?.split(',') || [];
            }

            const incremental_data = {

                incremental_date_column: extraction_data?.incremental_date_column,
                incremental_start_date: extraction_data?.incremental_start_date,
                incremental_end_date: extraction_data?.incremental_end_date,
                incremental_info: extraction_data?.incremental_info,
                incremental_frequency_type: extraction_data?.incremental_frequency_type,
                incremental_frequency_value: extraction_data?.incremental_frequency_value,
                incremental_frequency_value: extraction_data?.incremental_frequency_value, //primaryKey

            }

            const report_items = report_data?.report_items;

            let column_config = [];

            const temp_primary_selections = [];

            if (report_items && report_items?.length > 0) report_items.forEach((item) => column_config = [...column_config, ...(item.column_infos || [])])

            if (column_config?.length > 0) column_config.forEach((c) => {
                temp_primary_selections.push(c.orignal_name);
                set_primary_key_selections(temp_primary_selections)
            })

            if (load) delete load['load_mappings'];

            if (load) {

                load.incremental_date_column = (load?.incremental_date_column && Array.isArray(load?.incremental_date_column)) ? load?.incremental_date_column : load?.incremental_date_column?.split(",")
                load.delete_date_column = (load?.delete_date_column && Array.isArray(load?.delete_date_column)) ? load?.delete_date_column : load?.delete_date_column?.split(",")
            }

            load_mapping?.length > 0 && load_mapping.map((mapping) => {
                mapping.is_direct_mapping = mapping?.is_direct_mapping ? "yes" : "no"
            })

            console.log("file_mapping", file_mapping);


            set_extraction_state(extraction_data)
            set_load_mapping_state(load_mapping)
            set_load_state(load)
            set_data_source_type(extraction_data?.data_source_type)
            set_db_interaction_type(extraction_data?.db_interaction_type)
            set_job_state(job_data)
            set_local_report(report_data)
            set_incremental_state(incremental_data)
            setFileMapping(file_mapping)
            set_job_id(job_id)
            set_pre_step(pre_step + 1);
            setJobType(job_data?.job_type);

            const nlp_query = extraction_data?.nlp_query;
            if (nlp_query) {
                props.getAllInfoOfQuestion(nlp_query, reporting_db_info_id)
            }

        } else {
            set_job_id(undefined)
            set_local_questionInfo(undefined);
        }
    }, [props.wrangler_config_cache, location.search])




    useEffect(() => {

        props.getEntities()
        props.get_db_info()

        // setTableHead(['source_column', 'target_table', 'target_column', 'direct_mapping', 'criteria'])
        // setTableData([{}])
    }, [])


    useEffect(() => {
        let page_info = {
            title: job_id ? "Edit " + job_state?.name : "configure your job",
            isBack: true,
        };
        props.dispatchHeaderInformation(page_info)
    }, [job_id, job_state])



    useEffect(() => {

        let job_id = getParamByName(location.search, 'config_id');
        const job_type = getParamByName(location.search, 'job_type');
        // const sourceType = getParamByName(location.search, 'source_type');
        // set_data_source_type(sourceType)
        if (job_id && job_id !== undefined && job_id !== null) {
            props.get_data_wrangler_config_by_job_id(job_id)

        } else {
            set_job_id(undefined)
        }
        if (job_type === "google_drive_upload") {
            setJobType('google_drive_upload')
            set_job_state(
                {
                    grouping_tag: 'google_drive_upload',
                    job_status: 'active', // only at the first time
                    job_type: "google_drive_upload",
                    job_status: "ready", // at the first time ,
                    script_type: 'google_drive_upload' // 
                }
            );
        }

    }, [location])


    useEffect(() => {
        if (selectedColumn && selectedColumn.length > 0) {
            getAllInfoOfQuestion(undefined, selectedColumn)
        }
    }, [selectedColumn])


    useEffect(() => {
        if (questionInfo && questionInfo.is_mathematical_keywords_exist) {
            showPopup('Formula', '', enums.default.popupType.element, FormulaCreator, { data: props.questionInfo, hideRefresh: true, etlQuestionAction: formData && formData.question })
        }
        let temp_column = []
        let temp_table_data = [];


        questionInfo && questionInfo.columns_extracted && questionInfo.columns_extracted.length > 0 && questionInfo.columns_extracted.map((col) => {
            temp_column.push(col.column_name)
            temp_table_data.push({
                source_column: col.column_name,
                target_table: undefined,
                target_column: undefined,
            })
        })
        if (questionInfo && questionInfo.columns_extracted && questionInfo.columns_extracted.length > 0) {
            temp_table_data.push({
                source_column: undefined,
                target_table: undefined,
                target_column: undefined,
            })
        }

        // etl_config_details
        if (questionInfo && questionInfo?.etl_config_details) {
            // let's create incremental frequency here 

            const etl_config_details = questionInfo?.etl_config_details;
            const clone_extraction_state = extraction_state ? { ...extraction_state } : {};

            if (etl_config_details?.etl_start_date) {
                clone_extraction_state['incremental_start_date'] = etl_config_details?.etl_start_date;
            }
            if (etl_config_details?.etl_job_frequency_term) {
                clone_extraction_state['incremental_frequency_type'] = etl_config_details?.etl_job_frequency_term;

            }
            if (etl_config_details?.etl_job_frequency_term_value) {
                clone_extraction_state['incremental_frequency_value'] = etl_config_details?.etl_job_frequency_term_value;
            }
            if (questionInfo?.date_columns?.length === 1 && etl_config_details?.etl_job_frequency_term) {
                clone_extraction_state['incremental_date_column'] = questionInfo?.date_columns;
            }
            if (clone_extraction_state)
                set_extraction_state(clone_extraction_state)
        }

        if (!currentConfigInfo) {

            setTableData(temp_table_data)
            props.pushSourceMappingDataToStore(temp_table_data)
        }
        setSourceColumns(temp_column)
        setQuestionInfo(questionInfo)
    }, [questionInfo])


    useEffect(() => {

        if (currentConfigInfo && Object.keys(currentConfigInfo).length > 0) {
            let frequency = currentConfigInfo.frequency;
            let final_frequency = [];
            frequency && frequency.length > 0 && frequency.map((f) => {
                let _clone_object = f ? Object.assign({}, f) : {}
                if (_clone_object.frequency_type === 'minutes') {
                    _clone_object.value = f.minutes
                }
                if (_clone_object.frequency_type === 'hours') {
                    _clone_object.value = f.hours
                }
                final_frequency.push(_clone_object)
            })
            setFrequencys(final_frequency)

            if (currentConfigInfo.config_type) {
                setConfigType(currentConfigInfo.config_type)
            }

            if (currentConfigInfo.source_columns && currentConfigInfo.source_columns.length > 0) {
                setSelectedColumn(currentConfigInfo.source_columns)
            }
            if (currentConfigInfo.entity) {
                getHintsByDbInfo(currentConfigInfo.entity)
            }
            if (currentConfigInfo.question) {
                getAllInfoOfQuestion(currentConfigInfo.question)
            }
            if (currentConfigInfo.target_schema) {
                getTargetTableNames(currentConfigInfo.target_schema)
            }
            if (currentConfigInfo.transformationType) {
                setTransformationType(currentConfigInfo.transformationType)
            }

            if (currentConfigInfo.primaryKey) {
                setPrimaryKeyValue(currentConfigInfo.primaryKey)
            }
            if (currentConfigInfo.properties) {
                setProperties(currentConfigInfo.setProperties)
            }


            if (currentConfigInfo.load_config && currentConfigInfo.load_config.length > 0) {
                currentConfigInfo.load_config.forEach((column) => {
                    let post_data = {
                        database_id: currentConfigInfo.target_schema,
                        table_name: column.target_table
                    }
                    props.getTargetColumnNames(post_data)
                })
                let temp_load_config = [...currentConfigInfo.load_config]
                temp_load_config.push(get_plain_row_object())
                // setTableData(temp_load_config)
                props.pushSourceMappingDataToStore(temp_load_config)
            }
            setFormData(currentConfigInfo)
        } else {
            // setFormData({})
        }

    }, [currentConfigInfo, location])



    const onChangeFunction = (value, name, isArray) => {
        let clone_form_data = formData ? JSON.parse(JSON.stringify(formData)) : {};
        if (isArray) {
            let arrayValue = clone_form_data && clone_form_data[name] ? clone_form_data[name] : [];
            let _index = arrayValue && arrayValue.indexOf(value);

            if (_index > -1) {
                arrayValue.splice(_index, 1)
            } else {
                arrayValue.push(value)
            }

            clone_form_data[name] = arrayValue;
        }
        else {
            clone_form_data[name] = value;
        }
        setFormData(clone_form_data)
    }


    useEffect(() => {
        if (hintsByDbInfo && Object.keys(hintsByDbInfo).length > 0) {
            const tempArray = Object.keys(hintsByDbInfo);
            setLocalHintsByEntity(Array.from(new Set(tempArray)));
        }
    }, [hintsByDbInfo])


    useEffect(() => {
        if (config_type === 'config' && selectedColumn && selectedColumn.length > 0) {
            getAllInfoOfQuestion(undefined, selectedColumn)
        }
    }, [config_type])




    const onDrop = event => {
        const index = event.dataTransfer.getData("source_column");
        let currentIndex = undefined;
        let tempFilteredItems = [...selectedColumn]
        const value = event.currentTarget.textContent;
        for (let i = 0; i < tempFilteredItems.length; i++) {
            if (tempFilteredItems[i] === value) {
                currentIndex = i;
                break;
            }
        }
        const deletedItem = _.pullAt(tempFilteredItems, index);
        tempFilteredItems = insertAtSpecificLocationInArray(tempFilteredItems, currentIndex, deletedItem[0]);
        setSelectedColumn(tempFilteredItems);
    };



    const saveButtonClicked = () => {

        let job_id = getParamByName(location.search, 'config_id');

        let final_load_config = [];
        let final_load_config_data = [];
        let cloned_load_config = props.mappingTableData ? JSON.parse(JSON.stringify(props.mappingTableData)) : [];


        cloned_load_config && cloned_load_config.length > 0 && cloned_load_config.map((config) => {

            let temp_config = config ? JSON.parse(JSON.stringify(config)) : {};

            let target_table = temp_config ? (temp_config.target_table && (typeof temp_config.target_table === 'object') ? temp_config.target_table.value.trim() : (temp_config.target_table && temp_config.target_table.length > 0) ? temp_config.target_table.trim() : null) : null;
            let target_column = temp_config ? (temp_config.target_column && (typeof temp_config.target_column === 'object') ? temp_config.target_column.value.trim() : (temp_config.target_column && temp_config.target_column.length > 0) ? temp_config.target_column.trim() : null) : null;
            let source_column = temp_config ? (temp_config.source_column && (typeof temp_config.source_column === 'object') ? temp_config.source_column.value.trim() : (temp_config.source_column && temp_config.source_column.length > 0) ? temp_config.source_column.trim() : null) : null;


            if ((target_table && target_table.length > 0) && (target_column && target_column.length > 0) && (source_column && source_column.length > 0)) {
                temp_config.modified = true;
                if (sourceColumns && sourceColumns.indexOf(source_column) === -1) {
                    temp_config.is_static = true;
                }

                temp_config.source_column = temp_config.source_column;
                temp_config.target_table = temp_config.target_table;
                temp_config.target_column = temp_config.target_column;
                temp_config.direct_mapping = temp_config.direct_mapping;
                temp_config.criteria = (temp_config.criteria && temp_config.criteria.length > 0) ? (temp_config.criteria.trim().length > 0) ? temp_config.criteria.trim() : null : null;

                final_load_config.push(temp_config)
            }

            if (!target_table && !target_column && !source_column && temp_config.id) {
                temp_config.modified = true;
                temp_config.deleted = true;
                final_load_config.push(temp_config)
            }
        })


        let temp_columns_extracted = (questionInfo && questionInfo.columns_extracted) ? JSON.parse(JSON.stringify(questionInfo.columns_extracted)) : [];

        final_load_config && final_load_config.length > 0 && final_load_config.forEach((row) => {
            let row_object = row ? JSON.parse(JSON.stringify(row)) : {};
            if (Object.keys(row_object).length > 0) {
                temp_columns_extracted && temp_columns_extracted.length > 0 && temp_columns_extracted.forEach((column) => {
                    if (row.source_column == column.name) {
                        row_object.data_type = column.data_type;
                    }
                })
                final_load_config_data.push(row_object)
            }
        })



        final_load_config_data && final_load_config_data.length > 0 && final_load_config_data.map((data, index) => {
            errorPopupValue && Object.keys(errorPopupValue).length > 0 && Object.keys(errorPopupValue).map((key) => {
                if (index == parseInt(key)) {
                    final_load_config_data[index].data_length = errorPopupValue[key]
                }
            })
        })


        let final_frequency = [];
        frequencys && frequencys.length > 0 && frequencys.map((f) => {

            let _clone_object = f ? Object.assign({}, f) : {}
            if (_clone_object.frequency_type === 'minutes') {
                _clone_object.minutes = f.value
            }
            if (_clone_object.frequency_type === 'hours') {
                _clone_object.hours = f.value
            }
            final_frequency.push(_clone_object)
        })



        const { collection_name, properties } = props.collectionsColumns || {};

        let save_data = {
            name: formData.name,
            config_type: config_type,
            entity: formData.entity,
            source_columns: selectedColumn,
            criteria: formData.criteria,
            frequency: final_frequency,
            source_table: collection_name ? collection_name : formData.source_table,
            target_table_name: formData.target_table_name,
            question: formData.question,
            next_run_at: formData.next_run_at,
            job_start_date: formData.job_start_date,
            job_end_date: formData.job_end_date,
            job_period: formData.job_period,
            job_period_type: formData.job_period_type,
            config_id: job_id ? job_id : undefined,
            database_id: formData && formData['target_schema'],
            load_config: final_load_config_data,
            transformationType: transformationType,
            primaryKey: primaryKeyValue,
            e_type: formData.e_type,
            main_directory: formData.main_directory,
            processed_directory: formData.processed_directory,
            rename_prefix: formData.rename_prefix,
            extension: formData.extension,
            landing_date: formData.landing_date,
            _table_date_result: formData._table_date_result,
            is_sync: (config_type === 'sync' || (formData.e_type && formData.e_type === 'directory')) ? true : false,
            properties: is_edit_mode ? savedProperties : properties,
        };

        let save_message = currentConfigInfo ? "Config Updated SuccessFully" : "Config Saved SuccessFully";
        props.saveEtlConfig(save_data, save_message, props.history, '/etl')

        // setIsValidateData(undefined)
        // setTransformationType(undefined)
        // setPrimaryKeyValue(undefined)
        // setLocalError(undefined)
        // setErrorPopup(undefined)
        // setIgnoreError(undefined)

        // setTimeout(() => {
        // getConfigReports();
        // props.history.push('/etl')
        // }, 500)

    }



    const save_v1 = () => {

        const load = {
            name: "test-load",
            ...load_state,
        };

        load.incremental_date_column = load.incremental_date_column?.join(",")
        load.delete_date_column = load?.delete_date_column?.join(",")
        load.delete_start_date = load?.delete_start_date !== '' ? load.delete_start_date : null
        load.delete_end_date = load?.delete_end_date !== '' ? load.delete_end_date : null

        const clone_extraction_state = { ...extraction_state, }

        clone_extraction_state.data_source_type = data_source_type
        clone_extraction_state.db_interaction_type = db_interaction_type;
        clone_extraction_state.incremental_date_column = clone_extraction_state?.incremental_date_column && Array.isArray(clone_extraction_state.incremental_date_column) ? clone_extraction_state?.incremental_date_column.join(",") : clone_extraction_state?.incremental_date_column;
        clone_extraction_state.incremental_end_date = clone_extraction_state?.incremental_end_date !== '' ? clone_extraction_state?.incremental_end_date : null;
        clone_extraction_state.incremental_start_date = clone_extraction_state?.incremental_start_date !== '' ? clone_extraction_state?.incremental_start_date : null;
        const clone_local_report = local_report ? { ...local_report } : {};

        clone_local_report.type = "etl_config_query_builder"; // type will alwase etl_config_query_builder

        const final_temp_load_mapping_state = [];

        // here we are triming the mapping data 

        load_mapping_state?.length > 0 && load_mapping_state?.forEach((mapping) => {

            const source_column = mapping?.source_column?.trim();
            const target_column = mapping?.target_column?.trim();
            const target_table = mapping?.target_table?.trim();
            const source_table = mapping?.source_table?.trim();

            final_temp_load_mapping_state.push({
                ...mapping,
                source_column: source_column,
                target_column: target_column,
                target_table: target_table,
                source_table: source_table,
                is_direct_mapping: mapping?.is_direct_mapping === 'yes' ? true : false
            })
        })

        // console.log("clone_extraction_state", clone_extraction_state)
        // this is a action which will save the ETL data
        props.save_data_wrangler_job(job_state, clone_extraction_state, fileMapping, local_report, load, final_temp_load_mapping_state, props.history)
    }


    const onFrequencyChangeFunction = (value, type, value_type, _id, one_time) => {
        let clone_frequency = frequencys && JSON.parse(JSON.stringify(frequencys));
        clone_frequency && clone_frequency.length > 0 && clone_frequency.map((frequency, i) => {
            if (frequency.type === type) {
                frequency[value_type] = value;
                frequencyValidate(type, value_type, value)
            }
            else {
                if (one_time) {
                    frequency.frequency_type = 'reactive'
                }
            }
        })

        setFrequencys(clone_frequency)
    }




    const clickOnDataSource = (tablName) => {
        const columns = [];
        questionInfo.columns_extracted && questionInfo.columns_extracted.length > 0 && questionInfo.columns_extracted.map((column) => {
            let column_name = column.name.indexOf('.') > -1 ? column.name.split('.')[1] : column.name;
            if ((columns && columns.indexOf(column_name) == -1) && (column.table_name === tablName)) {
                columns.push(column_name)
            }
        })
        props.getTableDataOfExtractedColumn(tablName, columns)

        showPopup(undefined, undefined, enums.default.popupType.element, TablePopup, {
            tablName: tablName
        })

    }



    const frequencyValidate = (type, frequency_type, value) => {
        let temp_types = whichFrequencyShow ? JSON.parse(JSON.stringify(whichFrequencyShow)) : [];

        let _index = frequencyTypes.findIndex(f => f.value === frequency_type)
        if ((_index > -1 || value === 'reactive')) {
            if (type === 'e') {
                temp_types.push('t')
            }
            if (type === 't') {
                temp_types.push('l')
            }
        }
        setWhichFrequencyShow(temp_types)
    }



    const get_plain_row_object = (__table__head__) => {
        const table_head = __table__head__ ? __table__head__ : tableHead;

        const object_to_return = {
            // id: undefined,
            source_column: undefined,
            target_column: undefined,
            target_table: undefined,
            direct_mapping: undefined,
            criteria: undefined,
        };

        const existing_keys = Object.keys(object_to_return);

        table_head && table_head.forEach(head => {
            if (existing_keys.indexOf(head) === -1) {
                object_to_return[head] = [];
            }
        });

        return object_to_return;
    };



    const autoSearchOnChange = (index, key, value, type) => {
        let clone_table_data = tableData ? JSON.parse(JSON.stringify(tableData)) : [];
        let currentItem = clone_table_data[index];

        if (key === 'target_table' && index == 0) {
            setPrimaryKeyValue(undefined,)
        }

        if (key === 'target_table' && type == 'clicked') {
            currentItem.target_column = ' ';
            setCurrentTableName(value);
            let post_data = {
                database_id: formData && formData['target_schema'],
                table_name: value
            }

            props.getTargetColumnNames(post_data)
        }

        if (key === 'target_table' && type === 'typing') {
            currentItem.target_column = ' ';


        }

        if (type === 'typing') {
            currentItem[key] = {
                is_new: true,
                value: value
            };
        }

        if (type === 'clicked') {
            currentItem[key] = value;

        }

        clone_table_data[index] = currentItem;

        if (index === clone_table_data.length - 1 && is_edit_mode) clone_table_data.push(get_plain_row_object());

        if (!clone_table_data[index].source_column && !clone_table_data[index].target_table && !clone_table_data[index].target_column) {
            clone_table_data.pop();
        }
        // setTableData(clone_table_data);
        props.pushSourceMappingDataToStore(clone_table_data)
    };



    const validateTheData = () => {

        setErrorPopup(undefined)
        setLocalError(undefined)
        setIgnoreError(undefined)
        setPrimaryError(undefined)
        setErrorPopupValue(undefined)

        let final_data = [];
        let database_id = formData && formData['target_schema'] ? formData['target_schema'] : undefined;
        let temp_columns_extracted = (questionInfo && questionInfo.columns_extracted) ? JSON.parse(JSON.stringify(questionInfo.columns_extracted)) : [];

        tableData && tableData.length > 0 && tableData.forEach((row) => {
            let row_object = row ? JSON.parse(JSON.stringify(row)) : {};

            if (Object.keys(row_object).length > 0) {
                temp_columns_extracted && temp_columns_extracted.length > 0 && temp_columns_extracted.forEach((column) => {
                    if (row.source_column == column.name) {
                        if (!row_object.data_length) row_object.data_length = column.data_length;
                        if (!row_object.data_type) row_object.data_type = column.data_type;
                    }
                })
                final_data.push(row_object)
            }
        })
        validateTheEtlData(final_data, database_id)
    }


    useEffect(() => {

        let finalError = {};
        if (validationData && Object.keys(validationData).length > 0) {
            let parent_keys = Object.keys(validationData);
            parent_keys && parent_keys.length > 0 && parent_keys.forEach((key) => {
                if (key !== 'response_session_id') {
                    let item = validationData[key];
                    let itemKeys = item ? Object.keys(item) : []
                    tableData && tableData.length > 0 && tableData.forEach((row, index) => {
                        if (row) {
                            if (row.target_table === key) {
                                if (itemKeys.indexOf('id') > -1) {
                                    let _error_msg = item['id'] ? (item['id'].error_type && item['id'].error_type) : undefined
                                    setPrimaryError(_error_msg)
                                }
                                if (row.target_column !== 'id' && itemKeys && itemKeys.indexOf(row.target_column) > -1) {
                                    finalError[index] = item[row.target_column]
                                }
                            }
                        }
                    })
                }
            })
        }

        if (finalError && Object.keys(finalError).length > 0) {
            setIsValidateData(undefined)
        }
        if (validationData && Object.keys(validationData).length == 1 && Object.keys(validationData).indexOf("response_session_id") > -1) {
            setIsValidateData(true)
        }
        if (validationData && Object.keys(validationData).length == 2) {
            setIsValidateData(true)
        }

        setLocalError(finalError)
    }, [validationData])



    useEffect(() => {

        let keysOfError = errorPopupValue ? Object.keys(errorPopupValue) : [];
        let _ignoreError = ignoreError ? ignoreError : [];
        let localErrorKeys = localError ? Object.keys(localError) : [];
        let final_mearg_error = []

        let mearg_error = keysOfError.concat(_ignoreError);
        mearg_error = mearg_error.filter((item, index) => {
            return (mearg_error.indexOf(item) == index)
        })

        mearg_error && mearg_error.length > 0 && mearg_error.forEach((key) => {
            final_mearg_error.push(parseInt(key))
        })

        if (validationData && Object.keys(validationData).length > 0) {
            if ((localErrorKeys && localErrorKeys.length) == (final_mearg_error && final_mearg_error.length)) {
                setIsValidateData(true)
            }
        }
        setMeargError(final_mearg_error)

    }, [errorPopupValue, ignoreError])


    const clickOnApplyButton = (index, value) => {

        let temp_error = errorPopupValue ? JSON.parse(JSON.stringify(errorPopupValue)) : {};
        temp_error[index] = value;

        let temp_ignore_error = ignoreError ? JSON.parse(JSON.stringify(ignoreError)) : [];
        if (temp_ignore_error && temp_ignore_error.indexOf(index) > -1) {
            let _index = temp_ignore_error.indexOf(index)
            temp_ignore_error.splice(_index, 1);
        }
        setIgnoreError(temp_ignore_error)
        setErrorPopupValue(temp_error);

    }

    const clickOnIgnoreButton = (index) => {
        let temp_error = errorPopupValue ? JSON.parse(JSON.stringify(errorPopupValue)) : {};
        if (temp_error[index]) {
            delete temp_error[index];
        }

        let temp_ignore_error = ignoreError ? JSON.parse(JSON.stringify(ignoreError)) : [];
        if (temp_ignore_error && temp_ignore_error.indexOf(index) === -1) {
            temp_ignore_error.push(index);
        }
        else {
            let _index = temp_ignore_error.indexOf(index)
            temp_ignore_error.splice(_index, 1);
        }
        setIgnoreError(temp_ignore_error)
        setErrorPopupValue(temp_error);
    }



    const fixed_primary_error_clicked = () => {
        showPopup(undefined, undefined, enums.default.popupType.element, PrimaryPopup)
    }

    const clientIdFromSession = getFromSession(constants.SESSION_KEYS.CLIENT_ID);
    const clientId = process.env.REACT_APP_DEV_LOGIN_KEY ? (clientIdFromSession ? clientIdFromSession : constants.CLIENT_ID) : constants.CLIENT_ID;

    useEffect(() => {
        props.clientsInfo && props.clientsInfo.length > 0 && props.clientsInfo.forEach((client) => {
            if (client.id === clientId) {
                setActiveClient(client)
                return
            }
        })
    }, [props.clientsInfo, clientId])


    const sortMappingTable = (info, _order) => {

        let key = info && info.dataKey ? info.dataKey : undefined;
        let data_type = info && info.type ? info.type : undefined;
        let order = _order ? _order : (sortOrder && sortOrder[key]) ? ((sortOrder[key] == 'az') ? 'za' : 'az') : 'az';

        const clone_table_data = props.mappingTableData ? _.cloneDeep(props.mappingTableData) : [];

        let filterd_data = clone_table_data && clone_table_data.length > 0 && clone_table_data.filter((d) => {
            if (d.source_column) {
                return true
            }
            else return false
        })

        const sorted_data = filterd_data && filterd_data.length > 0 && filterd_data.sort((left, right) => {
            if (data_type === 'string') {
                if (order == 'az') {
                    let tempLeftValue = left[key] && typeof left[key] === 'object' ? left[key].value : left[key];
                    let tempRightValue = right[key] && typeof right[key] === 'object' ? right[key].value : right[key];
                    let leftValue = tempLeftValue ? tempLeftValue.trim().toLowerCase() : '';
                    let rightValue = tempRightValue ? tempRightValue.trim().toLowerCase() : '';
                    return leftValue.localeCompare(rightValue);
                }
                if (order == 'za') {
                    let tempLeftValue = left[key] && typeof left[key] === 'object' ? left[key].value : left[key];
                    let tempRightValue = right[key] && typeof right[key] === 'object' ? right[key].value : right[key];
                    let leftValue = tempLeftValue ? tempLeftValue.trim().toLowerCase() : '';
                    let rightValue = tempRightValue ? tempRightValue.trim().toLowerCase() : '';
                    return rightValue.localeCompare(leftValue);
                }
            }
        })
        if (sorted_data && sorted_data.length > 0 && is_edit_mode) {
            sorted_data.push(get_plain_row_object())
        }
        // setTableData(sorted_data)
        props.pushSourceMappingDataToStore(sorted_data)
        setSortOrder({ [key]: order })
    }


    const collection_name = props.collectionsColumns && props.collectionsColumns.collection_name;

    useEffect(() => {
        if (collection_name && (formData && formData['e_type'] === 'directory')) {
            props.getCollectionsOfMongo(collection_name)

        }
    }, [props.collectionsColumns])




    useEffect(() => {

        const db_id = getDbInfoId();
        set_db_info_ids([db_id])
        const db_info_to_use = IS_DEFAULT_LIVE_DB ? db_id : (db_id && db_id + "__nf__db__cached__");
        set_reporting_db_info_id(db_info_to_use);

    }, []);


    console.log("wrangler_config_cache", props.wrangler_config_cache)



    return (
        <EditorMainWrapper style={{ marginTop: '1rem', }}>
            <PageInnerWrapper style={{ background: '#F8F9FD', padding: '0px' }}>

                {pre_step === 0 && (
                    <JobInputs
                        job_state={job_state}
                        set_job_state={set_job_state}
                        pre_step={pre_step}
                        set_pre_step={set_pre_step}
                    />
                )}


                {pre_step > 0 && (
                    <React.Fragment>
                        <div className='fixed_wrapper'>
                            <Steper
                                steps={steps}
                                activeStep={activeStep}
                                setActiveStep={(t) => {
                                    if(jobType !== "google_drive_upload"){
                                        setActiveStep(t)
                                    }
                                }}
                                wrapperWidth={'100%'}
                                enable_next={job_id}
                                extraLabel={formData && formData['e_type'] ? formData['e_type'] : undefined}
                                stepper_style={{
                                    borderBottom: '1px solid rgb(210 205 205)'
                                }}
                                step_style={{
                                    background: '#fff',
                                    width: '20%',
                                    borderRight: '1px solid rgb(210 205 205)'
                                }}
                                hide_stepper_line={true}
                            />

                            <StepperButtons
                                activeStep={activeStep}
                                set_pre_step={set_pre_step}
                                setActiveStep={setActiveStep}
                                setInnerStep={setInnerStep}
                                innerStep={innerStep}
                                jobType={jobType}
                                save_v1={save_v1}
                                pre_step={pre_step}
                            />

                        </div>

                        <StepContentWrapper >

                            {activeStep == 0 && (
                                <ChooseScourceType
                                    title='Please Select The Data Source Type'
                                    desc='Select the source type you want to create your pipeline from'
                                    set_data_source_type={(v) => {
                                        set_data_source_type(v)
                                        setInnerStep(innerStep + 1)
                                    }}
                                    data_source_type={data_source_type}
                                    setInnerStep={setInnerStep}
                                    innerStep={innerStep}
                                    db_id={reporting_db_info_id}
                                    set_db_id={set_reporting_db_info_id}
                                    activeStep={activeStep}
                                    setActiveStep={setActiveStep}
                                    jobType={jobType}
                                    job_state={job_state}
                                    set_job_state={set_job_state}
                                    fileMapping={fileMapping}
                                    setFileMapping={setFileMapping}

                                />
                            )}


                            {activeStep === 1 && (
                                <ChooseScourceType
                                    title='Please Select The Target Data Type'
                                    desc='Select the target type you want to create your pipeline from'
                                    set_data_source_type={(v) => {
                                        set_data_source_type(v)
                                        setInnerStep(innerStep + 1)
                                    }}
                                    data_source_type={data_source_type}
                                    setInnerStep={setInnerStep}
                                    innerStep={innerStep}
                                    db_id={extraction_state?.target_db_id}
                                    set_db_id={(value) => {
                                        const clone_extraction_state = extraction_state ? { ...extraction_state } : {};
                                        clone_extraction_state['target_db_id'] = value;
                                        set_extraction_state(clone_extraction_state)
                                    }}
                                    activeStep={activeStep}
                                    setActiveStep={setActiveStep}
                                    jobType={jobType}
                                    job_state={job_state}
                                    fileMapping={fileMapping}

                                />
                            )}



                            {activeStep === 2 && (
                                <ExtractionV1
                                    extraction_state={extraction_state}
                                    set_extraction_state={set_extraction_state}
                                    data_source_type={data_source_type}
                                    set_data_souce_type={set_data_source_type}
                                    db_interaction_type={db_interaction_type}
                                    set_db_interaction_type={set_db_interaction_type}
                                    set_local_report={set_local_report}
                                    local_report={local_report}
                                    set_report_date_columns={set_report_date_columns}
                                    setActiveStep={setActiveStep}
                                    activeStep={activeStep}
                                    report_date_columns={report_date_columns}
                                    setInnerStep={setInnerStep}
                                    innerStep={innerStep}
                                    reporting_db_info_id={reporting_db_info_id}
                                    set_local_questionInfo={set_local_questionInfo}
                                    local_questionInfo={local_questionInfo}
                                    date_column_selection={date_column_selection}
                                    set_date_column_selection={set_date_column_selection}
                                    job_id={job_id}
                                />
                            )}


                            {
                                activeStep === 3 && (
                                    <React.Fragment>
                                        <SourceTableMappingV1
                                            load_mapping_state={load_mapping_state}
                                            set_load_mapping_state={set_load_mapping_state}
                                            data_source_type={data_source_type}
                                            set_data_souce_type={set_data_source_type}
                                            db_interaction_type={db_interaction_type}
                                            set_db_interaction_type={set_db_interaction_type}
                                            extraction_state={extraction_state}
                                            load_state={load_state}
                                            activeStep={activeStep}
                                        />
                                        <Processing
                                            extraction_state={extraction_state}
                                            set_extraction_state={set_extraction_state}
                                            data_source_type={data_source_type}
                                            set_data_souce_type={set_data_source_type}
                                            db_interaction_type={db_interaction_type}
                                            set_db_interaction_type={set_db_interaction_type}
                                            primary_key_selections={primary_key_selections}
                                            load_data={load_state}
                                            set_load_data={set_load_state}
                                            date_column_selection={date_column_selection}
                                            activeStep={activeStep}
                                        />
                                    </React.Fragment>
                                )
                            }


                            {
                                activeStep === 4 && (
                                    <ScheduleJob
                                        job_state={job_state}
                                        set_job_state={set_job_state}
                                        load_data={load_state}
                                        set_load_data={set_load_state}
                                        load_mapping_state={load_mapping_state}
                                        jobType={jobType}

                                    />
                                )
                            }

                            {
                                (primaryError || (localError && Object.keys(localError).length > 0)) && activeStep == 1 &&

                                <div>
                                    <h3 style={{ color: 'red', fontSize: '1rem', fontWeight: '600' }}>We found following errors while validating the mapping:</h3>
                                    <ul style={{ padding: '0px 1rem' }} className="list">
                                        {primaryError && <CustomList>  Primary Key not found. Please provide primary key in mapping &nbsp;
                                            <button onClick={fixed_primary_error_clicked}>
                                                Fix The Error
                                            </button> </CustomList>}
                                        {
                                            localError && Object.keys(localError).length > 0 && Object.keys(localError).map((error, i) => {
                                                return (
                                                    <CustomList
                                                        checked={meargError && meargError.indexOf(parseInt(error)) > -1 ? true : false}
                                                        key={"list" + i}>

                                                        Mapping Row&nbsp;
                                                        <span>#{parseInt(error) + 1}</span>
                                                        &nbsp;has errors, please fix that.</CustomList>
                                                )

                                            })

                                        }
                                    </ul>
                                </div>
                            }

                            {
                                data_source_type === 'sync' || (db_interaction_type) && (
                                    <div style={{
                                        padding: '1rem 0px',
                                        // background: 'rgb(246, 245, 245)',
                                        // marginTop: '-10px',
                                        // paddingTop: '20px',
                                        marginTop: 'auto'
                                    }}>
                                        {activeStep === 1 ? <p style={{ fontSize: '1rem', color: '#555', textTransform: 'capitalize' }}>{isValidateData ? "Let's Schedule your Job" : null}</p> : null}

                                    </div>
                                )
                            }


                        </StepContentWrapper >

                    </React.Fragment>

                )}

                {/* {pre_step >= 1 && (
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 'auto', marginBottom: '2rem' }}>
                       

                        {activeStep < 5 && (
                            <Button
                                primary={true}
                                onClick={() => {
                                    if (activeStep === 2 && innerStep == 0) {
                                        setInnerStep(innerStep + 1);
                                    } else {
                                        setActiveStep(activeStep + 1);
                                    }
                                }}
                                mR="1rem"
                            >
                                {activeStep < 4 ? 'Next' : 'Save'}

                            </Button>
                        )}


                        {activeStep == 5 && (
                            <Button
                                primary={true}
                                onClick={() => {
                                    // saveButtonClicked()
                                    save_v1()
                                }}
                                mR="1rem"
                            >
                                Save
                            </Button>
                        )}

                    </div>
                )} */}
            </PageInnerWrapper >
        </EditorMainWrapper >
    )
}


const mapStateToProps = (state) => ({

    config: state.EtlReducer.config,
    hintsByDbInfo: state.hintsReducer.hintsByDbInfo,
    entities: state.entityReducer.entities,
    questionInfo: state.EtlReducer.questionInfo,
    currentConfigInfo: state.EtlReducer.currentConfigInfo,
    db_infos: state.connectionReducer.db_infos,
    targetTableNames: state.EtlReducer.targetTableNames,
    targetColumnNames: state.EtlReducer.targetColumnNames,
    targetTableData: state.EtlReducer.targetTableData,
    validationData: state.EtlReducer.validationData,
    clientsInfo: state.clientInfoReducer.clientsInfo,
    collectionsColumns: state.EtlReducer.collectionsColumns,
    mappingTableData: state.EtlReducer.mappingTableData,
    wrangler_config_cache: state.dataWrangler.wrangler_config_cache

})

const actionToDispatch = {

    save_data_wrangler_job,
    get_data_wrangler_config_by_job_id,
    dispatchHeaderInformation,
    saveEtlConfig,
    getEntities,
    getAllInfoOfQuestion,
    getConfigInfo,
    getConfigReports,
    getTableDataOfExtractedColumn,
    clearJobInformation,
    clearQuestionInfo,
    get_db_info,
    getTargetTableNames,
    getTargetColumnNames,
    getTargetTableData,
    validateTheEtlData,
    raiseValidationError,
    getCollectionsOfBySourcePath,
    pushSourceMappingDataToStore,
    getCollectionsOfMongo
}

export default withRouter(connect(mapStateToProps, actionToDispatch)(Index))
