import * as actions from '../action-types/data-sync-up';

const initialState = {
    type: actions.DATA_SYNC_UP_INIT,
    tableLists: undefined,
    columnLists: undefined,
};


const dataSyncUpReducer = (state, action) => {
	if (!state || typeof action.type === 'undefined') {
		return initialState;
    }
    switch (action.type) {
        case actions.GET_TABLES_LIST_SUCCESS:
            return {
                ...state,
                type: action.type,
                tableLists: action.data && action.data.tables ? action.data.tables : undefined,
            };

        case actions.GET_COLUMNS_BY_TABLE_NAME:
            return{
                ...state,
                type: action.type,
                columnLists:  action.data && action.data.columns ? action.data.columns : undefined,
            }
        case actions.CLEAR_COLUMNS_NAMES:
            return {
                ...state,
                type: action.type,
                columnLists: undefined,
            }
        default:
			return {
                ...state,
            }
        }
};

export default dataSyncUpReducer;