import styled from 'styled-components';
import { colors } from '../../theme/colors';

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;


export const MainWrapper = styled.div`
    width: 96%;
    /* min-height: 4rem; */
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    margin-bottom: .8rem;
    padding: 1rem .8rem;
    transition: all .1s linear;
    border-radius: 0px;

    position: relative;

    &:hover {
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
    }

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

`;

export const RankWrapper = styled.div`
    color: #008AC7;
    font-size: 1rem;
    font-weight: bolder;
    margin-right: 30px;
`;

export const PictureWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    position: relative;
    margin-right: 20px;
`;

export const RightWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 40%;
`;


export const LeftWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const LowerContentWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
`;

export const ViewWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    color: #008AC7;
    font-size: 1rem;
    font-weight: bolder;
    cursor: pointer;
`;

export const SelectedWrapper = styled.div`
    position: absolute;
    top: -5px;
    left: -5px;

    width: 1rem;
    height: 1rem;

    background: ${colors.primary};
    color: white;

    border-radius: 50%;
    margin-right: .2rem;
    display: flex;
    justify-content: center;
    align-self: center;

    font-size: .7rem;
    font-weight: bolder;
`;

export const SubCategoryWrapper = styled.div`
    padding-top: 20px;
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;