import React from 'react';

const RadarChart = (props) => {
	return (
		<svg
			width={props.size ? props.size : '2.2rem'}
			height={props.height ? props.height : '2.2rem'}

			fill={props.color ? props.color : '#000'}
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 800 836"
			space="preserve">
			<path transform="translate(419,76)" d="m0 0 5 2 19 14 54 39 19 14 14 10 18 13 19 14 36 26 19 14 36 26 19 14 14 10 19 14 17 12 5 4-3 10-32 98-16 50-24 73-10 32-12 36-9 28-12 37-1 1-11 1h-373l-3-5-23-70-14-44-10-30-13-41-12-36-17-53-10-30-16-50-1-8 17-12 13-10 14-10 19-14 14-10 18-13 19-14 54-39 19-14 36-26 19-14 14-10 36-26zm-1 28-17 12-18 13-19 14-14 10-19 14-17 12-19 14-18 13-19 14-36 26-19 14-14 10-19 14-18 13-14 10v2l10 4 42 13 5-1 11-7 10-8 14-10 18-13 16-12 17-12 16-12 17-12 18-13 19-14 36-26 19-14 13-9v-59zm5 1v58l10 8 17 12 19 14 18 13 19 14 14 10 19 14 54 39 19 14 18 13 16 11 5 1 49-15 4-1-1-3-13-9-38-28-54-39-19-14-14-10-19-14-36-26-19-14-17-12-19-14-18-13-13-9zm-5 78-17 12-19 14-36 26-19 14-18 13-19 14-17 12-19 14-14 10-2 3 10-3 34-10 69-21 44-13 9-3 9-6 1-1 3-34 2-41zm20 11m1 1m1 1 2 4 7 10 13 18 10 14 14 19 52 72 7 10 9 8 5 3 9-2 59-18v-3l-14-10-19-14-36-26-19-14-36-26-19-14-36-26-6-5zm-304 116 1 8 16 50 20 61 11 35 12 36 14 44 12 36 19 59 1 2 3-1 10-14 13-18 8-11v-7l-9-29-18-55-16-50-24-73-16-50-3-5-51-17zm568 1-40 12-13 5-3 5-10 32-12 36-23 71-16 49-16 50-6 17-1 6 7 11 11 15 10 14 6 8 2-2 12-38 18-55 16-50 23-70 14-44 10-30 9-28 4-12v-2zm-76 24-33 10-29 9-5 4-4 17v153l1 29 2-3 8-24 14-43 15-46 12-37 13-40 9-27 1-2zm-347 262-4 4-20 28-11 15v1h349l-6-9-13-18-12-17-4-4z" fill="#7BDAFB" />
			<path transform="translate(59,2)" d="m0 0h6l1 1v764h734v66h-800v-830z" fill="#337CFE" />
			<path transform="translate(419,76)" d="m0 0 5 2 19 14 54 39 19 14 14 10 18 13 19 14 36 26 19 14 36 26 19 14 14 10 19 14 17 12 5 4-3 10-32 98-16 50-24 73-10 32-12 36-9 28-12 37-1 1-11 1h-373l-3-5-23-70-14-44-10-30-13-41-12-36-17-53-10-30-16-50-1-8 17-12 13-10 14-10 19-14 14-10 18-13 19-14 54-39 19-14 36-26 19-14 14-10 36-26zm4 29v58l10 8 17 12 19 14 18 13 19 14 14 10 19 14 54 39 19 14 18 13 16 11 5 1 49-15 4-1-1-3-13-9-38-28-54-39-19-14-14-10-19-14-36-26-19-14-17-12-19-14-18-13-13-9zm-5-1-17 12-18 13-19 14-14 10-19 14-17 12-19 14-18 13-19 14-36 26-19 14-14 10-19 14-18 13-14 10v2l10 4 42 13 6-1-1 5h-2v2l-8-4-41-13-8-3 1 8 16 50 20 61 11 35 12 36 14 44 12 36 19 59 1 2 3-1 10-14 13-18 8-11v-9l4 2 3 5-2 1v2h-2l-12 17-10 14-11 15v1h349l-6-9-13-18-12-17-5-5-33-1 7-2 22-2 5 1 1-16-1-1v-11l2-16 8-25 12-36 15-46 12-37 13-40 9-27 1-2-7 1-27 8-32 10-5 4-2 7-3-11-3-5 6 3 9-2 59-18v-3l-14-10-19-14-36-26-19-14-36-26-19-14-36-26-8-6-12-12-5-9-3 3-1 6-7 4-19 14-36 26-19 14-36 26-19 14-14 10-19 14-8-2-12 5-13 6-8 2 1-1 10-8 17-12 19-14 18-13 15-11 54-39 19-14 36-26 19-14 2-1v-59zm286 209-40 12-13 5-3 5-10 32-12 36-23 71-16 49-16 50-6 17-1 6 7 11 11 15 10 14 6 8 2-2 12-38 18-55 16-50 23-70 14-44 10-30 9-28 4-12v-2z" fill="#357DFE" />
			<path transform="translate(411,260)" d="m0 0h2v7l-2 6-10 8-14 10-19 14-36 26-15 11-18 13-4 2v2l10 2 38 12 15 5 5-1 11-7 21-16 9-6 2 2-3 5-17 12-4 3h-2v2l-11 8-1 2 12 3 22 7v4l-10-2-27-8-2-1 8 26 12 35 5-5 11-16 2 4-2 5-12 18 2 1 33 1 3 2v2l-43 1-8 10-12 17-8 11-10 14-1 2 138 1 7 6 1 4-155 1-13 18-7 10-10 14-11 15 254 1 10 7v-11l1-17 1-6 2 3-1 10v8l1 1-1 16-9-1-23 3-2 1 33 1v1h-278l2-2-4-6-3-1-1 2-9-29-18-55-16-50-24-73-16-50 1-5h2v-6l11-7 6-2 10-3 14-7 7-2 5 2-1 3-8 6 23-7 56-17 50-15 27-8 9-3z" fill="#5A94FE" />
			<path transform="translate(210,336)" d="m0 0 25 8 39 12 5 2 3 9 16 50 23 71 10 30-1 5-12 17-11 15-13 18-5 5-4-9-8-25-23-70-16-50-28-85z" fill="#98BCFE" />
			<path transform="translate(489,523)" d="m0 0 5 3 6 6v2l3 1 7 8 25 25 7 8 4 5v2h-254l4-7 8-10 10-14 16-23 4-5z" fill="#98BCFE" />
			<path transform="translate(292,363)" d="m0 0 16 4 42 13 7 3 3 8 15 46 4 12v7l-14 18-11 16-9 12-5 7h-2l-21-64-16-50-9-27z" fill="#98BCFE" />
			<path transform="translate(411,272)" d="m0 0h1l-1 20-4 54-10 8-19 14-13 9-4 2-9-2-57-18v-2l19-14 14-10 18-13 19-14 18-13 12-9 15-11z" fill="#98BCFE" />
			<path transform="translate(395,267)" d="m0 0 4 1-7 7-16 12-17 12-19 14-17 12-16 12-17 12-8 5-10-2-57-18v-3l11-9 11-8 6-1 15-5 31-9 42-13 47-14z" fill="#97BBFE" />
			<path transform="translate(425,457)" d="m0 0h2l9 9v2l3 1 7 8 29 29 5 7-1 1h-134l-2-1 2-5 9-12 10-14 13-18 5-6z" fill="#98BCFE" />
			<path transform="translate(424,185)" d="m0 0 15 8 2 5 6 8 11 15 12 17 28 38 10 14 26 36 9 13-1 2-16-10-16-12-17-12-19-14-36-26-15-10-1-7v-28l1-33z" fill="#A1F0FD" />
			<path transform="translate(363,385)" d="m0 0 12 3 27 8v11l-1 15-3 6-12 17-4 2-13-40-6-19z" fill="#98BCFE" />
			<path transform="translate(403,354)" d="m0 0h2l-1 31-1 7-10-2-25-8 1-3 9-7h2v-2l16-11z" fill="#98BCFE" />
			<path transform="translate(400,431)" d="m0 0 4 2 5 6 7 6 6 8v1h-33l-3-2z" fill="#98BCFE" />
			<path transform="translate(138,309)" d="m0 0 10 3 42 13h6l-1 5h-2v2l-5-2-44-14-8-4z" fill="#3C82FE" />
			<path transform="translate(422,164)" d="m0 0h1l1 7h2v2l6 2 4 12 2 1v3l4 2 4 5h-3l-4-3v-2l-4-2-8-8-5-8-1 2-1 9-4-2 1-5h1l2-12z" fill="#5A94FE" />
			<path transform="translate(553,351)" d="m0 0 4 1 2 2 1 8 1 2-2 4h-1l-3-12z" fill="#5E98FE" />
			<path transform="translate(417,241)" d="m0 0h1v15h-4v-10l2 2z" fill="#A2F1FE" />

		</svg>

	)

}


export default RadarChart;