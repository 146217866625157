import * as actions from '../action-types/client.info';

const initialState = {
    type: actions.CLIENT_INFO_INIT,
    clientsInfo: undefined
};

const clientInfoReducer = (state, action) => {
	if (!state || typeof action.type === 'undefined') {
		return initialState;
    }

    switch (action.type) {
        case actions.CLIENTS_INFO_DATA_SUCCESS:

            return {
                type: action.type,
                clientsInfo: action.data
            };

            // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
			return {
                type: state.type,
                clientsInfo: state.clientsInfo
            };
        }
};

export default clientInfoReducer;