import * as actionTypes from '../action-types/report.helper';
import { constants } from '../utils/constants';
import { simpleDispatch_post, dispatchAction, simpleDispatch_get } from './helper';
import { post, put } from '../data.service';


export const setActiveEditReport = report => dispatch => {
    dispatchAction(dispatch, actionTypes.SET_ACTIVE_EDIT_REPORT_SUCCESS, report);
};


export const clearActiveEditReport = report => dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_ACTIVE__EDIT_REPORT)
}


/**
 * 
 * @param {*} cell_data 
 * @param {*} report_id 
 * @returns 
 */

export const dispatch_report_cell_clicked_info = (cell_data, report_id, insight_id) => async dispatch => {
    const data = {
        cell_data: cell_data,
        insight_id: insight_id,
        report_id: report_id
    }
    dispatchAction(dispatch, actionTypes.REPORT_CELL_CLICKED_INFO_V2, data)
}

/**
 * 
 * @param {*} insight_id 
 * @return
 */

export const clear_report_cell_clicked_info = (insight_id) => async dispatch => {
    dispatchAction(dispatch, actionTypes.CELAR_REPORT_CELL_INFO_V2, { insight_id: insight_id })
}

/**
 * 
 * @param {*} insight_id 
 * @param {*} report_id 
 * @returns 
 */
export const mark_report_for_no_need_to_refresh = (insight_id, report_id) => async dispatch => {
    dispatchAction(dispatch, actionTypes.MARK_REPORT_FOR_NO_REFRESH, { insight_id: insight_id, report_id: report_id })
}


/**
 * 
 * @param {*} c_id 
 * @returns 
 */
export const get_all_reports = (c_id, hideLoader) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.GET_ALL_REPORTS;
    simpleDispatch_post(dispatch, url, {}, actionTypes.GET_ALL_REPORT_SUCCESS_V1, hideLoader);
};



/**
 * 
 * @param {*} report_id 
 * @returns 
 */
export const delete_report = (report_id, report_type) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.DELETE_REPORT;
    // simpleDispatch_post(dispatch, url, { report_id: report_id }, actionTypes.DELETE_REPORT_SUCCESS_V1);

    let data = await post(url, { report_id: report_id });

    if (data && data.code === 200) {

        if (report_type === "data_source") {
            setTimeout(() => {
                getAllDataSource()(dispatch)
            }, 1000)
        }
        if (report_type === "report") {
            get_all_reports()(dispatch)
        }

        dispatchAction(dispatch, actionTypes.CLONE_REPORT_SUCCESS_V1)
    }

}


/**
 * 
 * @param {*} report_id 
 * @returns 
 */

export const clone_report = (report_id, report_type,) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.CLONE_REPORT;

    let data = await post(url, { report_id: report_id, });

    if (data && data.code === 200) {
        if (report_type === "report") {
            get_all_reports()(dispatch)
        }
        dispatchAction(dispatch, actionTypes.CLONE_REPORT_SUCCESS_V1)
    }

}


export const getDefaultInsightForDashboard = (report_id, client_id, token) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.GET_DEFAULT_INSIGHT_FOR_DASHBOARD;

    simpleDispatch_post(dispatch, url, { report_id: report_id, client_id: client_id, token: token }, actionTypes.GET_LATEST_DASHBOARD_FOR_INSIGHT);

};






export const getAllDataSource = (c_id, type) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.GET_ALL_REPORTS;
    const post = {
        c_id,
        type: "data_source"
    }
    simpleDispatch_post(dispatch, url, post, actionTypes.GET_ALL_DATA_SOUCE_SUCCESS_V1, true);
};



export const getReportByReportId = (report_id) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.REPORT.POINT + constants.END_POINTS.REPORT.GET_REPORT_BY_ID_V1 + `?report_id=${report_id}`;

    simpleDispatch_get(dispatch, url, actionTypes.GET_REPORT_BY_ID_V1, true);
}

export const clearEditCurrentReport = () => async dispatch => {

    dispatchAction(dispatch, actionTypes.CLEAR_EDIT_CURRENT_REPORT_SUCCESS_V1)
}