import React, { useEffect, useState } from 'react'
import { PageInnerWrapper } from '../hoc/styled.hoc';
import { getDbInfoId, getParameterByName, isDefaultLiveDb, showPopup } from '../../utils';
import { connect } from 'react-redux';
import { Button } from '../hoc/button';
import { withRouter } from 'react-router-dom';
import TextBoxV1 from '../hoc/text.box.v1';
import styled from 'styled-components';
import SearchSuggestion from './components/search.suggestion'
import { getHintsWithTableAndColumnName } from '../../actions/hints';
import CombinationTable from './combination_table/CombinationTable';
import { getAccessGroupWithUsers, getDataPolicyById, saveDataPolicy } from '../../actions/access_group';
import { NormalButton } from '../hoc/normal.button';



const PolicyWrapper = styled.div`
    width: 100%;
    height: ${props => props.height ? props.height : 'calc(100vh - 10rem)'};
    overflow: ${props => props.overflow ? props.overflow : 'auto'};
    h2{
        color: #534f4f;
        line-height: 0;
    }
    .__row{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        margin-bottom: 1.6rem;
        align-items: start;
    }
    .label{
        font-size: 1.1rem;
        font-weight: 500;
        color: #414040;
        line-height: 1.8rem;
        margin-left:0.3rem;
    }
    /* input{
        border: none;
        background: #eaeaea;
        border-radius: 6px;
        padding: 0.9rem;
        &::placeholder{
            font-size: 1rem;
        }
    } */

`;
/**
 * 
 * @param {*} props 
 * @returns 
 */

const Index = (props) => {


    const {

        hintsWithTableColumnsName,
        editDataPolicy

    } = props;



    const location = props?.history?.location;


    useEffect(() => {

        const policy_id = getParameterByName('policyId', location.search);
        if (policy_id && (props?.editCurrentReport?.id !== policy_id)) {
            policy_id && props.getDataPolicyById(policy_id)

        }
    }, [location?.search])



    const getSelectedUsersWithAccess = (accessMenu, groupAccessOnly, includedUsersOnly) => {

        const selectedData = {};

        if (includedUsersOnly?.length > 0) {
            accessMenu.forEach((menu) => {
                menu.users.forEach((user) => {
                    if (includedUsersOnly.includes(user.id)) {
                        if (!selectedData[menu.id]) selectedData[menu.id] = [];
                        selectedData[menu.id].push(user.id);
                    }
                });
            });
        }

        if (groupAccessOnly?.length > 0) {
            accessMenu.forEach((menu) => {
                if (groupAccessOnly.includes(menu.id)) {
                    const userIds = menu.users.map((user) => user.id);
                    if (userIds.length > 0) {
                        selectedData[menu.id] = userIds;
                    }
                }
            });
        }

        return selectedData;
    }




    const getMappedAndUnmappedUsersOrRoles = (data, accessGroup) => {
        const mappedUsers = [];
        const accessGroups = [];

        if (data && Object.keys(data).length > 0) {
            Object.keys(data).forEach(key => {
                const userIds = data[key];
                if (userIds.length > 0) {
                    const groupData = accessGroup.find(group => group.id === key);

                    if (groupData?.users?.length !== userIds.length) {
                        userIds.forEach(userId => mappedUsers.push(userId));
                    } else if (userIds.length > 0 && groupData?.users?.length > 0) {
                        accessGroups.push(key);
                    }
                }
            });
        }

        return { mappedUsers, accessGroups };
    }





    useEffect(() => {

        const page_information = {
            title: 'Data Policy Editor',
        }
        props.dispatchHeaderInformation(page_information)

    }, []);



    const [cached_db_info_id, set_cached_db_info_id] = useState(undefined);

    const [active_db_info, set_active_db_info] = useState(undefined);

    const [hint_values, set_hint_values] = useState(undefined);
    const [hint_value_detail, set_hint_value_details] = useState(undefined);

    const [table_column_data, set_table_column_data] = useState(undefined);

    const [policy_main_data, set_policy_main_data] = useState(undefined)

    const [policy_rule_data, set_policy_rule_data] = useState(undefined)



    useEffect(() => {


        if (editDataPolicy?.id && props.reportAccessGroupData) {

            const data_access_control_column_mappings = editDataPolicy.data_access_control_column_mappings;
            const mappings = [];

            data_access_control_column_mappings.forEach((p) => {
                const selected_access_data = getSelectedUsersWithAccess(props.reportAccessGroupData, p?.group_ids, p?.user_ids);
                mappings.push({
                    ...p,
                    access_group_data: selected_access_data
                })
            })

            set_policy_main_data(editDataPolicy)
            set_policy_rule_data(mappings)
        }
    }, [editDataPolicy, props.reportAccessGroupData])

    useEffect(() => {

        if (props.db_infos?.length > 0) {
            const IS_DEFAULT_LIVE_DB = isDefaultLiveDb()
            const db_id = getDbInfoId()
            const db_info_id_to_use = IS_DEFAULT_LIVE_DB ? db_id : (db_id && db_id + "__nf__db__cached__");
            set_active_db_info(db_id)
            set_cached_db_info_id(db_info_id_to_use);
        }
    }, [props.db_infos])





    useEffect(() => {
        props.getHintsWithTableAndColumnName(undefined, cached_db_info_id);
        props.getAccessGroupWithUsers()
    }, [cached_db_info_id])


    useEffect(() => {

        const tempObject = {};
        const tempTagSet = new Set();
        hintsWithTableColumnsName?.forEach(d => {

            const { table_name: tableName, column_name: columnName, id, values } = d;

            if (values && values.length > 0) {
                values.forEach(v => {
                    const trimmedValue = typeof v === 'string' ? v.trim().toLowerCase() : v.trim();
                    tempTagSet.add(v);

                    if (!tempObject[trimmedValue]) {
                        tempObject[trimmedValue] = [];
                    }

                    tempObject[trimmedValue].push({ columnName, tableName, id, values });
                });
            }
        });
        set_hint_value_details(tempObject);
        set_hint_values(Array.from(tempTagSet));
    }, [hintsWithTableColumnsName])


    /**
     * 
     * @param {*} value 
     */
    const hintSuggestionClick = (value) => {
        let tempArray = [];
        const _value = Array.isArray(value) ? value.join(' ').trim() : value;
        const valueToCheck = typeof _value === 'string' ? _value.trim().toLowerCase() : _value.trim();
        if (valueToCheck) {
            if (hint_value_detail?.[valueToCheck]) {
                tempArray = [...hint_value_detail[valueToCheck]]
            }
            set_table_column_data(tempArray)

            const t = tempArray?.map((d) => {
                return {
                    ...d,
                    table_name: d?.tableName,
                    column_name: d?.columnName,
                    values: [],
                    id: undefined
                }
            })

            if (t?.length > 0) {
                set_policy_rule_data([t[0]])
            } else {
                set_policy_rule_data()
            }

        }
    }


    const policy_main_on_change = (key, value) => {
        const clone_p = policy_main_data ? { ...policy_main_data } : {};
        clone_p[key] = value;
        set_policy_main_data(clone_p)
    }

    /**
     * 
     * @param {*} rowIndex 
     */
    const deleteRule = (rowIndex) => {
        const clone_policy_rule_data = policy_rule_data ? [...policy_rule_data] : [];
        clone_policy_rule_data.splice(rowIndex, 1)
        set_policy_rule_data(clone_policy_rule_data)
    }


    /**
     * 
     * @param {*} key 
     * @param {*} value 
     * @param {*} row_index 
     */
    const onChangeRowValue = (key, value, row_index) => {

        const clone_policy_rule_data = policy_rule_data ? [...policy_rule_data] : [];
        clone_policy_rule_data[row_index][key] = value;
        set_policy_rule_data(clone_policy_rule_data)

    }


    const add_new_rule = () => {

        const clone_policy_rule_data = policy_rule_data ? [...policy_rule_data] : [];
        
        clone_policy_rule_data.push({
            table_name: '',
            column_name: ''
        })
        set_policy_rule_data(clone_policy_rule_data)

    }


    const save_data_policy = () => {

        const columns_mapping = [];

        const clone_policy_rule_data = policy_rule_data ? [...policy_rule_data] : [];

        clone_policy_rule_data?.filter((a) => {

            if (a.table_name && a.column_name && a?.access_group_data && Object.keys(a.access_group_data)?.length > 0) return true;
            else return false;

        })?.map((rule) => {

            const { mappedUsers, accessGroups } = getMappedAndUnmappedUsersOrRoles(rule?.access_group_data, props.reportAccessGroupData);;

            columns_mapping.push({
                table_name: rule?.table_name,
                column_name: rule?.column_name,
                table_column_values: rule?.table_column_values,
                group_ids: accessGroups,
                user_ids: mappedUsers
            })
        })

        const ___data = policy_main_data ? { ...policy_main_data } : {};
        ___data.columns_mapping = columns_mapping;

        props.saveDataPolicy(___data);

        if(props?.history){
            props?.history.push("/data_policy")
        }

    }

    const distinct_of_table_name = [...new Set(table_column_data?.map((t) => t.tableName) || [])];

    const removeValueItem = () => { };

    return (
        <div>
            <PageInnerWrapper padding={'10px'} style={{ marginTop: '1rem' }}>
                <PolicyWrapper>
                    <div style={{
                        width: "34%",
                        height: '13.5rem',
                    }}>
                        <div className='__row'>
                            <label className='label'>Access Policy Name</label>
                            <TextBoxV1
                                label="Enter policy name"
                                background="#f2f2f2"
                                hide_border={true}
                                hide_margin={true}
                                show_as_placholder={true}
                                height='2.9rem'
                                value={policy_main_data?.['name']}
                                onChange={(e) => {
                                    policy_main_on_change("name", e.target.value)
                                }}
                            />
                        </div>

                        <div className='__row'>
                            <label className='label'>Search Your Attribute</label>
                            <SearchSuggestion
                                items={hint_values || []}
                                suggestionOnClick={hintSuggestionClick}
                                placeholder='Enter Attribute'

                            />
                        </div>
                    </div>


                    <div style={{ marginBottom: "2rem" }}>
                        <h2>Please create your combination here</h2>
                        <CombinationTable
                            data={policy_rule_data}
                            handleAddNewRule={() => {
                                //
                                add_new_rule()
                            }}
                            cached_db_info_id={cached_db_info_id}
                            handleDelete={deleteRule}
                            onChangeRowValue={onChangeRowValue}
                            distinct_of_table_name={distinct_of_table_name}
                            access_group_selections={props.reportAccessGroupData}
                            removeValueItem={removeValueItem}
                        />
                    </div>

                    <hr style={{ background: "#eaeaea", borderColor: "#eaeaea" }} />


                </PolicyWrapper>
                <div
                    style={{ display: "flex", justifyContent: "end", textAlign: "right", padding: '2rem 1.4rem' }}
                >
                    <NormalButton
                        label="CANCEL"
                        bgColor={'#e6e6e6'}
                        onClick={() => {
                            props.history.push('/data_policy');
                        }}
                        mR="0.5rem"
                        height="2.6rem"
                        // padding="0.4rem 1rem"
                        color="#000000"
                        width='6rem'
                    />
                    <NormalButton
                        label="SAVE"
                        primary={true}
                        onClick={() => {
                            save_data_policy()
                        }}
                        color="#fff"
                        height="2.6rem"
                        width={'6rem'}
                        // padding="0.4rem 1rem"
                    />
                </div>
            </PageInnerWrapper>
        </div>
    )

}


const mapStateToProps = state => {

    const { hints, tableNameOfHints, hintsWithTableColumnsName, dublicateHints } = state.hintsReducer;
    const db_infos = state.connectionReducer.db_infos
    const reportAccessGroupData = state?.groupAccessReducer?.reportAccessGroupData;
    const editDataPolicy = state.groupAccessReducer.editDataPolicy

    return { editDataPolicy, hints, tableNameOfHints, hintsWithTableColumnsName, dublicateHints, db_infos, reportAccessGroupData }
}


export default withRouter(connect(mapStateToProps, { getDataPolicyById, saveDataPolicy, getHintsWithTableAndColumnName, getAccessGroupWithUsers })(Index))