import React from 'react';
import styled from 'styled-components';
import HomeIcon from '../../svg/home'
import { NormalButton } from '../../hoc/normal.button';
import PlaySvg from '../../svg/play'
import SaveSvg from '../../svg/save.rect';
import CloseSvg from '../../svg/close';




const Wrapper = styled.div`
    width: 100%;
    height: 80px;
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.2),0 1px 5px 0 rgba(0,0,0,0.12)!important;
    display: flex;
    align-items: center;
    justify-content: space-between;;
    padding: 0px 3rem;
    box-sizing: border-box;

    .home{
        cursor: pointer;
    }

    .search_wrapper{

        width: 30%;

        input{
            width: 100%;
            height: 2.7rem;
            border-radius: 7px;
            font-size: 1.2rem;
            border: 1px solid #b1d0de;
            padding: 1rem;
            box-sizing: border-box;
            box-shadow: 2px 3px 4px #e2e2e2;
            color: #333;
            text-align: center;
            font-weight: 600;

            &:focus{
                outline: none;
            }

            &::placeholder{
                text-align: center;
                font-weight: 600;
            }
        }
    }

    .close_btn{
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 5px;
        &:hover{
            background-color: #e2e2e2;
        }
    }

`;



const LayoutHeader = (props) => {


    const { setTitle, reportTitle, save, close } = props;

    return (
        <Wrapper>
            <div
                className='home'
                title="Home"
                onClick={() => {
                    props.history.push('/');
                }}
            >
                <HomeIcon
                    color='#337DFF'
                    size="1.571rem"
                    height="1.571rem"
                />
            </div>

            <div className='search_wrapper'>
                <input type='text'
                    placeholder='Please Provide Dashboard Name'
                    value={reportTitle}
                    onFocus={() => {
                        // setIsFocus(true);
                    }}
                    // onBlur={() => setIsFocus(false)}
                    onKeyDown={(e) => {
                        e.stopPropagation()
                    }}
                    onChange={(event) => {
                        // console.log("on_c", event);
                        setTitle(event.target.value)
                    }} />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                <div className="btn_spe">
                    <NormalButton
                        height={'2.2rem'}
                        mR="1rem"
                        onClick={() => {
                            save(true)
                        }}
                        primary={true}
                        color="#fff"
                        icon={<PlaySvg size='1rem' height='1rem' color="#fff" />}
                        label="Execute"
                        boxShadow={'2px 3px 4px #ccc'}
                        width="110px"
                    />
                </div>

                <div className="btn_spe">
                    <NormalButton
                        bgColor='transparent'
                        height={'2.2rem'}
                        mR="1rem"
                        onClick={() => {
                            save()
                        }}
                        iconStyle={{
                            marginTop: '2px'
                        }}
                        color="#374a66"
                        primary={true}
                        hoverBg={"transparent"}
                        icon={<SaveSvg size='1.2rem' height='1.2rem' color="#afc4e3" />}
                        label="Save"
                        boxShadow={'2px 3px 4px #ccc'}
                        width="110px"

                    />
                </div>

                <div className='close_btn' title="Close" onClick={() => {
                    close()
                }}>
                    <CloseSvg size='.9rem' height='.9rem' />
                </div>
            </div>

        </Wrapper>
    )
};


export default LayoutHeader;
