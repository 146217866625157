import React, { useEffect, useState } from 'react'
import TextBoxV1 from '../hoc/text.box.v1';
import SelectOptionsV1 from '../select-option/select.option.v1.1';
import styled from 'styled-components';
import CloseSvg from '../svg/close';
import { connect } from 'react-redux';
import { db_functions_config } from '../../actions/report';
import { constants } from "../../utils/constants";



const InfoWrapper = styled.div`
    width: 100%;
    /* padding: 0rem 1rem; */
    box-sizing: border-box;
    /* border: 1px solid #ccc; */
    border-top: 0px ;
    position: static;
    top: 0px;


        .default_msg{   
            display: flex;
            flex-direction: column;
            /* padding: 2rem; */
            box-sizing: border-box;
            align-items: center;
            position: relative;
            top: 19rem;
            left: 0px;
            right: 0px;
            transform: translate(0%, -50%);
            height: ${props => props.height || '24rem' };
            img{
                max-width: 3rem;
            }

            p{
                font-size: .9rem;
                text-transform: uppercase;
                font-weight: 600;
                color: #308bc8;
                margin-top: 0.5rem;
                line-height: 1.2rem;
            }
        }

    fieldset{
        border: 1px solid #ccc;
    }

     .selected_column{
        margin: 1rem 0;
        background: #EFEFEF;
        /* border-bottom: 1px solid #909090; */
        padding: 15px 16px;
        h2{
            margin: 0px;
            font-size: .8rem;
            font-weight: 600;
            line-height: 1.2rem;
            span{
                color: #222;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                display: block;
                font-weight: 400;
            }
        }
     }


        label{
            font-size: .68rem;
            color: #222;
            bottom: -6px;
            position: relative;
            text-transform: uppercase;
            font-weight: 600;
        }
          .transform{
             &::before{
                content: '';
                height: 1px;
                left: -11px;
                position: absolute;
                top: 2.6rem;
                width: 14px;
                background: #ccc;
            }
             &::after{
                content: '';
                height: 1px;
                right: -13px;
                position: absolute;
                top: 2.6rem;
                width: 15px;
                background: #ccc;
            }
        }

        .border_addon{
            position: relative;;

            &::before{
                content: '';
                height: 48%;
                border: 0.5px dashed #ccc;
                left: -11px;
                position: absolute;
                top: 2.6rem;
            }
             &::after{
                content: '';
                height: 48%;
                border: 0.5px dashed #ccc;
                right: -11px;
                position: absolute;
                top: 2.6rem;
            }
        }
        .t_values{
             &::before{
                 content: '';
                height: 1px;
                left: -11px;
                position: absolute;
                bottom: 1.7rem;
                width: 14px;
                background: #ccc;
            }
             &::after{
                content: '';
                height: 1px;
                right: -11px;
                position: absolute;
                bottom: 1.7rem;
                width: 14px;
                background: #ccc;
            }
        }
    .__row__{
        margin-bottom: 1.2rem;
        position: relative;
    }
    .right_panel_header{
            display: flex;
            padding: 12px 16px;
            text-transform: capitalize;
            font-size: .9rem;
            position: relative;
            background: linear-gradient(to right, #3E9EFF, #737DFF);
            width: 101%;
            box-sizing: border-box;
            left: -1px;
            border-radius: 8px 8px 0 0;
          
        .header_close{
            position: absolute;
            right: 1rem;
            height: 100%;
            top: 0px;
            display: flex;
            align-items: center;
            cursor: pointer;
            svg{
                fill: #7a96c3;
            }
        }
        p{
            margin: auto;
            text-overflow: ellipsis;
            overflow: hidden;
            font-weight: 600;
            font-size: .9rem;
            text-align: center;
            width: 90%;
            color: #fff;
        }
    }
    .t_values{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: .5rem;
    }
`;




const ColumnEditor = (props) => {

    const {

        activeColumn,
        setActiveColumn,
        column_proprty_on_change,
        open_aggregation_if_popup,
        column_ifs_conditions,
        hide_aggregation

    } = props;


    const get_active_transformation_type_inputs = (name) => {
        const inputs = transformation_types.filter(f => f.db_function_name.toLocaleLowerCase() === name.toLocaleLowerCase() && f.param_order > 1).map(t => {
            return {
                param_order: t.param_order,
                param_name: t.param,
                param_type: t.param_type,
                id: t.id
            }

        });
        return inputs;
    };

    const { functions_config } = props;

    const section_title = (activeColumn?.table_name ? activeColumn?.table_name + "." : '') + activeColumn?.column_name;


    const [unique_transformation_types, set_unique_transformation_types] = useState(undefined);
    const [transformation_types, set_transformation_types] = useState(undefined);


    useEffect(() => {
        if (!functions_config || functions_config.length === 0) {
            props.db_functions_config()
        }
    }, [])


    useEffect(() => {

        if (functions_config) {

            const db_type = "mysql"

            const mysql_configs = functions_config.filter(f => f.db_type === db_type);

            set_transformation_types(mysql_configs);

            const final_un_to_use = mysql_configs.filter(f => (typeof f.param_order === 'undefined' || f.param_order === 1))
            final_un_to_use.map(f => {
                if (f.function_category && f.function_category.indexOf('func()') == -1) {
                    f.function_category = f.function_category + " " + "func()"
                }
            });
            set_unique_transformation_types(final_un_to_use);
        }
    }, [functions_config]);

    const active_t_type_inputs = (activeColumn && activeColumn.t_name) ? get_active_transformation_type_inputs(activeColumn.t_name) : [];


    const ifs = column_ifs_conditions?.['if'] || [];

    let agg_title = '';

    (ifs || []).forEach((f) => {
        agg_title += f.expr?.split("__nf_comma__").join(",") + " ";
    })




    return (
        <InfoWrapper style={{ height: props?.height || undefined }}>

            <div className="right_panel_header">
                <p>COLUMN OPTIONS </p>
            </div>

            {!activeColumn && <div
                className='default_msg'>
                <img src={constants.CONTEXT_PATH + "./select.png"} />
                <p>
                    Select Column For <br></br>Aggregation AND <br></br>Transformation</p>
            </div>}

            <div style={{
                // padding: '0px 15px',
                boxSizing: 'border-box'
            }}>

                {
                    activeColumn && (
                        <React.Fragment>
                            <div
                             className='selected_column'>
                                <h2>SELECTED COLUMN <br></br>
                                    <span title={section_title} style={{ textTransform: 'capitalize' }}>{section_title}</span></h2>
                            </div>
                        <div style={{padding: '0 1rem'}}>
                            {!hide_aggregation && (
                                <div>
                                    <div className='__row__' style={{
                                        marginBottom: activeColumn && activeColumn?.aggregation ? '1.5rem' : '10px'
                                    }}>
                                        <label>Aggregation</label>
                                        <SelectOptionsV1
                                            // label=""
                                            options={[
                                                { value: 'sum', label: 'Sum' },
                                                { value: 'count', label: 'Count' },
                                                { value: 'u_count', label: 'Unique Count' },
                                                { value: 'min', label: 'Min' },
                                                { value: 'max', label: 'Max' },
                                                { value: 'avg', label: 'Avg' }
                                            ]}
                                            display_key="label"
                                            value_key="value"
                                            on_change={(value) => {
                                                column_proprty_on_change('aggregation', value, activeColumn?.id)
                                            }}
                                            value={activeColumn?.aggregation ? activeColumn.aggregation : undefined}
                                            inputStyle={{
                                                boxShadow: '2px 1px 3px 2px #cccccc4d'
                                            }}
                                        />
                                    </div>

                                    {activeColumn && activeColumn?.aggregation && (
                                        <React.Fragment>

                                            {!ifs || (ifs.length === 0) && (
                                                <div style={{
                                                    // margin: '10px 0px',
                                                    paddingBottom: active_t_type_inputs?.length > 0 ? '.5rem' : '1.2rem'
                                                }}>
                                                    <button className='rpt_primary_btn' onClick={() => {
                                                        open_aggregation_if_popup(activeColumn)
                                                    }}>
                                                        Aggregate Condition
                                                    </button>
                                                </div>
                                            )}

                                            {ifs?.length > 0 && (
                                                <p
                                                    onClick={() => {
                                                        open_aggregation_if_popup(activeColumn)
                                                    }}
                                                    title={agg_title} style={{
                                                        fontSize: ".8rem",
                                                        color: "rgb(55,74,102)",
                                                        margin: "10px 0px",
                                                        border: "1px solid #eaeaea",
                                                        padding: "9px",
                                                        cursor: 'pointer',
                                                        wordWrap: 'break-word',
                                                        whiteSpace: 'break-spaces'

                                                    }}>{agg_title}</p>
                                            )}

                                        </React.Fragment>
                                    )}
                                </div>
                            )}


                            {activeColumn && activeColumn.table_name && (
                                <div className='__row__'
                                    style={{
                                        flexDirection: 'column',
                                        borderRadius: '3px',
                                        boxSizing: 'border-box',
                                        // marginTop: active_t_type_inputs?.length > 0 ? '1rem' : '-.8rem'

                                    }}>
                                    <div className={active_t_type_inputs?.length > 0 ? "row_box_right_side border_addon" : "row_box_right_side"}>
                                        <label>Transform To</label>
                                        <div className={active_t_type_inputs?.length > 0 ? "transform" : '--'}>
                                            <SelectOptionsV1
                                                width="100%"
                                                options={unique_transformation_types && unique_transformation_types.length > 0 ? unique_transformation_types : []}
                                                on_change={(value) => {
                                                    column_proprty_on_change('t_name', value, activeColumn?.id)
                                                }}
                                                display_key={"identifier"}
                                                value_key={"db_function_name"}
                                                group_key='function_category'
                                                show_group_name={true}
                                                sort_key='desc'
                                                active_group_name={activeColumn.t_name ? activeColumn.t_name : undefined}
                                                value={activeColumn.t_name ? activeColumn.t_name : undefined}
                                                is_search={true}
                                                inputStyle={{
                                                    boxShadow: '2px 1px 3px 2px #cccccc4d'
                                                }}
                                            />
                                        </div>

                                        {activeColumn && activeColumn.t_name && active_t_type_inputs?.length > 0 && (
                                            <div className="t_values">

                                                {active_t_type_inputs && active_t_type_inputs.length > 0 && active_t_type_inputs.map((t, j) => {

                                                    const _v_key = `__trns__` + t.id;
                                                    const _value = activeColumn && activeColumn?.['user_input_values']?.[_v_key] || activeColumn?.[_v_key] || '';
                                                    const w_d_t = active_t_type_inputs?.length > 1 ? (100 - (active_t_type_inputs.length * 2)) / active_t_type_inputs.length : 100;

                                                    return (
                                                        <div className="t_col" style={{ width: w_d_t + "%" }}>
                                                            <TextBoxV1
                                                                label={t?.param_name}
                                                                type="text"
                                                                is_required={t.is_required}
                                                                value={_value}
                                                                onChange={(e) => {
                                                                    column_proprty_on_change(_v_key, e.target.value, activeColumn?.id)
                                                                }}
                                                                inputStyle={{
                                                                    boxShadow: '2px 1px 3px 2px #cccccc4d'
                                                                }}
                                                            />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        </React.Fragment>
                    )
                }
            </div>
        </InfoWrapper >
    )
}




const mapStateToProps = (state) => ({
    functions_config: state.reportReducer.functions_config,
})

export default connect(mapStateToProps, { db_functions_config })(ColumnEditor);

