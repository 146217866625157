
/***
 * title: this is a select-option component 
 * author: saroj kumar
 * date: 13 jan 2022
 * 
 * accept props =>
 * 
 *      select_option_style = string
 *              ["type1", "type2", "type3", "type4"]
 *      on_change = function
 *      value = string
 *      options = array
 *      value_key = string
 *      display_key = string
 *      label = string
 *          
 */


import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components'
import Chevron from '../svg/chevron.down';
import * as _ from 'lodash';
import { constants } from '../../utils/constants';
import RightArrowSvg from '../svg/right.arrow'
import View from '../svg/view';






/***
 * here we have the style of the component 
 * 
 */


const ripple = keyframes`
 to{
      transform: scale(2.5);
      opacity: 0;
    }
`;



const MainDiv = styled.div`
    width: 100%;
    position: relative;
    /* padding-top: ${props => (props.label) ? "6px" : '0px'}; */
    cursor: ${props => props.disabled ? "not-allowed" : undefined};
    /* height: 100px; */
    margin-top: ${props => props.hide_margin ? '0px' : '15px'};
    .none_user_input{
        opacity: 0;
        position: absolute;
        /* width: 0px; */
        /* height: 0px; */
    }
`;



const OverlayMain = styled.div`
    width: 100vw;
    height: 100vh;
    z-index: 1200;
    position: fixed;
    top: 0px;
    left: 0px;
    background: #ff000000;
`;


const SuggestionList = styled.div`

    width: ${props => (props?.width + "px") || '100%'};
    top : ${props => props?.top + "px" || "0px"};
    left : ${props => props?.left + "px" || "0px"};
    box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
    /* background-color: red; */
    background-color: #fff;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-top: 0px;
    max-height: 180px;
    min-height: 180px;
    overflow: auto;
    position: absolute;
    padding: 0.6rem;
    padding-top: 0;

    &::-webkit-scrollbar {
        background-color: #3F3F3F;
        width:4px;
    }

    &::-webkit-scrollbar-track {
        background-color: #fff;
        border: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dbdbdb;
        border-radius: 10px;
        height: 4px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color:  #3F3F3F;
        height: 4px;
    }

    .suggestion_item{

        font-size: .9rem;
        padding:  .5rem 10px ;
        box-sizing: border-box;
        color: #222;
        cursor: pointer;
        overflow: hidden;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        /* border: 1px solid red; */
        border-radius: 6px;

        .list_view_icon{
            opacity: 0;
        }
        &:hover{

            .list_view_icon{
                opacity: 1;
            }
            /* background-color: rgba(0, 0, 0, 0.04); */
            background: #E3EEFF;
        }
    }

    .ripple{
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.7);
        position: absolute;
        transform: scale(0);
        animation: ${ripple} 0.6s linear;
    }
    .active{
        /* background-color: rgba(25, 118, 210, 0.08); */
        background-color: #F3F3F3;
        /* &:hover{
            background-color: rgba(25, 118, 210, 0.08);
        } */
    }

    .suggestion_search{
        padding: 0px .2rem;
        margin-top: .5rem;
        margin-bottom: .3rem;
        /* font-family: Roboto, Helvetica, Arial, sans-serif !important; */
        
        input{
            width: 100%;
            box-sizing: border-box;
            height: 1.8rem;
            font-size: .9rem;
            color: #222;
            border: 1px solid;
            border-color: #ccc;
            padding: .5rem;
            /* font-family: Roboto, Helvetica, Arial, sans-serif !important; */
            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                /* font-family: Roboto, Helvetica, Arial, sans-serif !important; */
            }
        }
    }
    .no_record_found{
        text-align: center;
        font-size: .6rem;
        padding: .5rem;
        box-sizing: border-box;
        color: #222;
        /* font-family: Roboto, Helvetica, Arial, sans-serif !important; */
    }
    .checkbox{

        width: 13px;
        height: 13px;
        margin-right: 10px;
        cursor: pointer;
    }
    
    .group_key{
        text-transform: capitalize;
        box-sizing: border-box;
        line-height: 45px;
        list-style: none;
        color: rgba(0, 0, 0, 0.6);
        font-weight: 500;
        font-size: 0.9rem;
        padding-left: 10px;
        padding-right: 10px;
        position: sticky;
        top: 0px;
        z-index: 1;
        background-color: #fff;
        border-bottom: 1px solid #eaeaea;
        border-top: 1px solid #eaeaea;
        /* font-family: Roboto, Helvetica, Arial, sans-serif !important; */

        img {
            margin-right: .9rem;
        }
    }

    .svg_icon{
        margin-right: .9rem;

    }
`;



const UserInput = styled.div`

    width: 100%;
    height: ${props => props.size === 'small' ? '1.9rem' : (props.height || '2.4rem')};
    border: ${props => props?.is_open ? '2px solid #007FFF' : props.hide_border ? "0px" : '1px solid #0000003b'};
    border-radius: 6px;
    background: ${props => props.background ? props.background : '#fff'};
    padding: 5px .5rem;
    position: relative;
    display: flex;
    align-items: center;
    cursor: ${props => props.disabled ? "not-allowed" : 'pointer'};
    box-sizing: border-box;
    ${props => props.style ? {...props?.style} : undefined}

    .select_arrow{
        /* opacity: 0; */
    }
    &:hover{
        border: 1px solid #afc4e3;
        
        .select_arrow{
            opacity: 1;
        }
    }
    
    .value_txt{
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 1rem;
        font-weight: normal;
        color: rgb(55, 74, 102);
        max-width: 90%;
        overflow: hidden;
    }
    .label_pre{
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 1rem;
        color: #646464;
        font-weight: 300;   
        text-transform: capitalize;
        color: rgba(0, 0, 0, 0.6);
        /* font-family: Roboto, Helvetica, Arial, sans-serif !important; */
        font-weight: 400;
        line-height: 1.4375em;
        letter-spacing: 0.00938em;
        transition: all 0.2s;
        position: absolute;
        left: 10px;
        ${props => props.labelStyle ? { ...props.labelStyle } : undefined}

     }
     

     .label_post{
        color: rgba(0, 0, 0, 0.6);
        /* font-family: Roboto, Helvetica, Arial, sans-serif !important; */
        font-weight: 400;
        font-size: .9rem;
        transform: translate(-11%, -100%) scale(0.8);
        background: ${props => props.background ? props.background : '#fff'};
        padding: 0px 7px;
        /* display: ${props => props.label_as_placeholder ? 'none' : 'block'} */
     }

     .placeholder{
        white-space: nowrap;
        text-overflow: ellipsis;
        /* font-size: 1rem; */
        color: #646464;
        font-weight: 300;   
        text-transform: capitalize;
        color: rgba(0, 0, 0, 0.6);
        font-weight: 400;
        line-height: 1.4375em;
        letter-spacing: 0.00938em;
        background: ${props => props.background ? props.background : '#fff'};
     }

    .select_arrow{
        position: absolute;
        right: 0px;
        top: 0px;
        bottom: 0px;
        height: 100%;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: ${props => props.is_open ? "rotate(180deg)" : undefined};
    }
    .view_icon{
        position: absolute;
        right: 2rem;
        top: 0px;
        bottom: 0px;
        height: 100%;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: right;
    }
    .list_view_icon{
        margin-right: 2rem;
    }
`;



const SelectOptionV1 = (props) => {


    /**
     * here we are destructuring the props
     */

    const {

        select_option_style = "type1",
        options,
        defaultValue,
        value_key,
        display_key,
        icon_key,
        on_change,
        value,
        label,
        is_required,
        is_search,
        multiple,
        value_join_with,
        checkbox,
        custom_element = <button>ss</button>,
        group_key,
        group_sort_key,
        options_sort_key,
        none_value = true,
        none_display_value,
        type,
        hide_case = false,
        background,
        disabled = false,
        show_group_name = false,
        height,
        hide_margin,
        hide_border,
        labelStyle,
        search_placholder_txt,
        inputStyle,
        hide_post_label,
        label_as_placeholder,
        handleViewDetail,
        viewIconColor,
    } = props;




    const should_we_group = typeof group_key !== 'undefined' && group_key.length > 0;
    let prev_group_item = undefined;



    const find_active_group_name = (value) => {
        const found = value_key && group_key && options.find((c) => c?.[value_key] === value);
        if (found && found?.[group_key]) return found[group_key];
    }


    const sort_list_using_group_sort_key = (data_to_sort, sort_key, sort_by) => {
        const clonned_data = JSON.parse(JSON.stringify(data_to_sort));
        clonned_data && clonned_data.length > 0 && clonned_data.sort((prev_item, this_item) => {
            const desc_comp = sort_key ? this_item[sort_key].localeCompare(prev_item[sort_key]) : this_item.localeCompare(prev_item);
            const default_comp = sort_key ? prev_item[sort_key].localeCompare(this_item[sort_key]) : prev_item.localeCompare(this_item)
            if (sort_by === 'desc') return desc_comp
            return default_comp
        });
        return clonned_data;

    };


    /***
     * here we have a local state of the component
     */

    const [suggestion_list, set_suggestion_list] = useState(undefined);
    const [prev_options, set_prev_options] = useState(undefined);
    const [open_menu, set_open_menu] = useState(undefined);
    const [dropdown_position, set_dropdown_position] = useState(undefined);
    const [focused, set_focused] = useState(undefined);


    const updated_suggestion_options = () => {


        // this is patch code for stop force update 
        const need_to_update = true //(JSON.stringify(suggestion_list || {})) !== (JSON.stringify(prev_options || []))

        if (need_to_update) {
            if (should_we_group) {
                const sort_data = sort_list_using_group_sort_key(options, group_key, group_sort_key)
                set_suggestion_list(sort_data);
                set_prev_options(sort_data)

            }
            else if (options_sort_key) {
                const sort_data = sort_list_using_group_sort_key(options, (display_key ? display_key : undefined), options_sort_key)
                set_suggestion_list(sort_data)
                set_prev_options(sort_data)
            }
            else {
                set_suggestion_list(options)
                set_prev_options(options)
            }
        }
    }

    useEffect(() => {
        updated_suggestion_options()

    }, [])


    const select_input_ref = useRef(null)


    /***
     * when we click on suggestion_list item this function is called
     */
    const click_on_suggestion = (__item__, event) => {
        let __value__ = '';
        if (typeof __item__ !== "object") __value__ = __item__;

        if (typeof __item__ === "object") __value__ = __item__[value_key];
        on_change(__value__, __item__, event)
        !multiple && set_open_menu(undefined)
    }



    const on_search_list = (e) => {

        e.preventDefault();
        e.stopPropagation();

        const value = e.target.value;

        const filterd_suggestion_list = options && options.length > 0 && options.filter((op) => {
            if (value === '') return true
            if (typeof op !== 'object' && op?.toLowerCase()?.indexOf(value) > -1) return true;
            if (typeof op === 'object' && op[display_key]?.toLowerCase()?.indexOf(value) > -1) return true;
            else return false;
        })

        set_suggestion_list(filterd_suggestion_list)
    }


    const get_display_value_by_original_value = (__value__) => {

        if (multiple) {

            if (!display_key && !value_key) return value_join_with ? __value__.join(value_join_with) : __value__.join(", ");

            if (display_key && value_key) {

                let found_display_value = [];

                for (let index = 0; index < __value__.length; index++) {
                    const v = __value__[index];
                    const option = options.find((o) => o[value_key] === v);
                    if (option) found_display_value.push(option[display_key]);
                }
                return found_display_value ? (value_join_with ? found_display_value.join(value_join_with) : found_display_value.join(',')) : __value__;
            }
        } else {
            if (display_key && value_key) {
                let found_display_value = undefined;
                for (let index = 0; index < options.length; index++) {
                    const element = options[index];
                    if (element[value_key] === __value__) {
                        found_display_value = element[display_key];
                        break;
                    }
                }
                return found_display_value ? found_display_value : __value__;
            } else return __value__
        }
    }


    const find_is_this_active_element = (options, __value__) => {
        if (multiple && __value__) {
            if (!display_key && !value_key) return __value__.indexOf(options) > -1;
            if (display_key && value_key) return __value__.indexOf(options[value_key]) > -1;

        } else {
            if (!display_key && !value_key) return options === __value__;
            if (display_key && value_key) return options[value_key] === __value__
        }
    }

    // function createRipple(e){
    //     if (this.getElementsByClassName('ripple').length > 0) {
    //         this.removeChild(this.childNodes[1]);
    //     }

    //     var circle = document.createElement('div');
    //     this.appendChild(circle);
    //     var d = Math.max(this.clientWidth, this.clientHeight);
    //     circle.style.width = circle.style.height = d + 'px';
    //     circle.style.left = e.clientX - this.offsetLeft - d / 2 + 'px';
    //     circle.style.top = e.clientY - this.offsetTop - d / 2 + 'px';
    //     circle.classList.add('ripple');
    // }


    // var buttons = document.getElementsByClassName('suggestion_item');

    // Array.prototype.forEach.call(buttons, function (b) {
    //     b.addEventListener('click', createRipple);
    // })



    const close_dropdown = () => {
        set_open_menu(!open_menu)

        if (should_we_group) {
            const sort_data = sort_list_using_group_sort_key(options, group_key, group_sort_key)
            set_suggestion_list(sort_data);
            set_prev_options(sort_data)

        }
        else if (options_sort_key) {
            const sort_data = sort_list_using_group_sort_key(options, (display_key ? display_key : undefined), options_sort_key)
            set_suggestion_list(sort_data)
            set_prev_options(sort_data)
        }
    }


    const el_id = "input__id__uniqqq_auto_gen" + Math.random(100);
    const select_box_have_value = (value && value.length > 0);
    const lable_class = "label_pre" + " " + ((open_menu || select_box_have_value) ? "label_post" : 'dd');
    const is_label_post = (open_menu || select_box_have_value);

    // 
    const label_style = is_label_post ? (props.lablePostStyle || {}) : props.lablePreStyle

    const suggestion_list_width = select_input_ref?.current?.clientWidth;
    const active_group_name = show_group_name && find_active_group_name(value);

    const get_db_types = (type) => {
        const t_type = type?.toLowerCase();

        switch (t_type) {
            case "mysql":
                return "MySQL"
            case "hive":
                return "Hive"

            case "mongodb":
                return "MongoDB"

            case "elasticsearch":
                return "Elasticsearch"
            default:
                return t_type
        }

    }
    const db_types = ["mysql", "mongodb", "elasticsearch", "hive"];
    const format_string = (str) => {
        if (db_types.indexOf(str?.toLowerCase()) > -1) {
            return get_db_types(str);
        }
        else return str && isNaN(str) ? str.split("_").join(' ').split('__').join(' ') : str;

    }


    const open_dropdown_menu = (e) => {
        set_focused(true);
        const dropdown_height = 180;
        const element = document.getElementById(el_id);
        const el_bounding = element.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const element_height = el_bounding?.height;
        const element_left = el_bounding?.left;
        const element_top = el_bounding?.top;
        let drop_down_top_to_use = (element_top + element_height);
        if ((windowHeight - drop_down_top_to_use) <= dropdown_height) {
            drop_down_top_to_use = ((element_top + element_height) - dropdown_height) - 10
        }
        set_dropdown_position({
            top: drop_down_top_to_use,
            left: element_left
        })
        close_dropdown(!open_menu)
        updated_suggestion_options()
    }




    return (
        <MainDiv hide_margin={hide_margin} is_label_post={is_label_post} height={height} ref={select_input_ref} disabled={disabled} label={label}>

            {/* this is input but not visible for user */}
            <input
                className="none_user_input"
                type="text"
                value=""
                onFocus={(e) => {
                    if (!disabled) {
                        open_dropdown_menu(e)
                    }
                }}
                onKeyDown={(e) => {
                    const key_code = e.keyCode;
                    if (key_code === 9) {
                        set_focused(false)
                        close_dropdown()
                    }
                }}

            />
            {/* this is active value placholder  */}

            <UserInput
                labelStyle={labelStyle}
                hide_border={hide_border}
                height={height}
                size={props.size}
                disabled={disabled}
                background={background}
                id={el_id}
                is_open={open_menu}
                style={inputStyle}
                label_as_placeholder={label_as_placeholder}
                onClick={(e) => {
                    if (!disabled) {
                        open_dropdown_menu(e)
                    }
                }}

                onBlur={() => {
                    set_focused(false)
                }}
            >


                {/* this is for show group */}
                {active_group_name && active_group_name.length > 0 && (<span className="value_txt" style={{ textTransform: !hide_case ? "capitalize" : undefined }} >{format_string(active_group_name)}&nbsp;&nbsp;<RightArrowSvg size='.8rem' height='.8rem' /> &nbsp;&nbsp;</span>)}

                {/* {!value ? } */}
                {(value && value.length > 0) && <span className="value_txt" style={{ textTransform: !hide_case ? "capitalize" : undefined }} title={value}>{!hide_case ? format_string(get_display_value_by_original_value(value)) : get_display_value_by_original_value(value)}</span>}

                {label && !label_as_placeholder && <span className={lable_class} style={{ color: open_menu ? "#007FFF" : undefined, display: (hide_post_label && is_label_post) ? "none" : undefined, ...label_style }}>{label}</span>}
                {label && label_as_placeholder && <span className={'placeholder'} style={{ display: value ? "none" : undefined, ...label_style }}>{label}</span>}
                { handleViewDetail && value && <span onClick={(e) => {e.stopPropagation(); handleViewDetail(value)}} className='view_icon' > <View height={'1rem'} size={'1rem'} color={viewIconColor || '#3e9eff'} /></span> }
                <span className="select_arrow"> <Chevron size={'.8rem'} height={".8rem"} color="#bbbaba" /></span>

            </UserInput>


            {/* this is a dropdown menu  */}

            {open_menu && (
                <OverlayMain onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation()
                    close_dropdown(false);
                }}>
                    <SuggestionList onClick={(e) => e.stopPropagation()} top={dropdown_position?.top} left={dropdown_position?.left} width={suggestion_list_width} isViewIcon={!!handleViewDetail}>

                        {is_search && (
                            <div className="suggestion_search">
                                <input type="text" autoFocus={true} placeholder={search_placholder_txt || "Search List"} onChange={on_search_list} onKeyDown={(e) => {
                                    const key_code = e.keyCode;
                                    if (key_code === 9) {
                                        set_focused(false)
                                        close_dropdown()
                                    }
                                }} />
                            </div>
                        )}

                        {none_value && <div
                            onClick={(e) => {
                                e.stopPropagation()
                                click_on_suggestion("", e)
                            }}
                        >
                            <div className={"suggestion_item"} style={{ fontStyle: "italic", paddingLeft: group_key ? "19px" : undefined, }}>{none_display_value ? none_display_value : 'None'}</div> </div>
                        }

                        {suggestion_list && suggestion_list.length > 0 && suggestion_list.map((s, index) => {

                            const is_this_item_active = find_is_this_active_element(s, value);
                            const this_group_item = should_we_group && (s[group_key] || '');
                            const __fire__group__div__ = should_we_group && this_group_item !== prev_group_item;
                            const classes = is_this_item_active ? "suggestion_item active" : "suggestion_item";

                            prev_group_item = this_group_item;

                            const value_of_list = typeof s === 'object' ? s[display_key] : s;
                            const value_to_display = !hide_case ? format_string(value_of_list) : value_of_list

                            return (
                                <React.Fragment>
                                    {__fire__group__div__ && <div style={{ textTransform: !hide_case ? "capitalize" : undefined }} className="group_key">{format_string(this_group_item)}</div>}
                                    <div

                                        onClick={(e) => {
                                            e.stopPropagation()
                                            click_on_suggestion(s, e)
                                        }}>
                                        <div  style={{width: '100%', justifyContent: handleViewDetail && 'space-between', paddingLeft: group_key || icon_key ? "19px" : undefined, textTransform: !hide_case ? "capitalize" : undefined }} className={classes}>
                                            {checkbox && <input className="checkbox" type="checkbox" checked={is_this_item_active} />}
                                            {
                                                s && s[icon_key] && icon_key &&
                                                // <img alt={s[icon_key] || ''} src={constants.CONTEXT_PATH + './' + s[icon_key]} width='20' style={{ marginRight: '15px' }} />
                                                <span className='svg_icon'> <img alt={icon_key || ''} src={constants.CONTEXT_PATH + './' + s[icon_key]} width='20' /></span>
                                            }
                                            <span>{value_to_display}</span>
                                            {handleViewDetail && <span className='list_view_icon' onClick={(e) => {e.stopPropagation(); handleViewDetail(value_of_list)}} ><View height={'1rem'} size={'1rem'} color={viewIconColor || '#3e9eff'} /> </span> }
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })}
                        {(suggestion_list && suggestion_list.length === 0) && <div className="no_record_found">No Record Found</div>}
                    </SuggestionList>
                </OverlayMain>
            )}
        </MainDiv>
    );
}
export default SelectOptionV1;