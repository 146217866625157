import * as actionTypes from '../action-types/loader';

const initialState = {
    type: actionTypes.LOADER_INIT
};


const loaderReducer = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    // if (action && action.data && typeof action.data === 'object') {
    //     action.data.key = new Date();
    // }

    switch (action.type) {
        case actionTypes.LOADER_SHOW:
        case actionTypes.LOADER_HIDE:
        case actionTypes.LOADER_SHOW_V1:
            return {
                type: action.type
            }
        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
            return {
                type: state.type,
            };
    }
};

export default loaderReducer;