import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import TableSvg from '../svg/table';
import ViewSvg from '../svg/view';
import Chevron from '../svg/chevron.down';
import FormulaSvg from '../svg/formula';
import DeleteSvg from '../svg/delete';
import SearchSvg from '../svg/search';
import { connect } from 'react-redux';
import TableViewPopup from './table.view';
import { showPopup } from '../../utils';
import * as enums from '../../utils/enums';
import JsonViewer from '../train-your-polus/json-viewer';
import { renderIconByDataType } from './builder.shared.functions'
import { delete_report } from '../../actions/report.helper';
import { add_new_column_into_table } from '../../actions/report_builder'
import ConfirmBox from '../confirm-box';
import AddSvg from '../svg/add';
import TableColumnAddEdit from './table.column.add.edit';
import { RadioCheckMark } from '../hoc/radiobox';
import DbSwitcher from '../db.switcher';
import { getAllRelationships } from '../../actions/relationship.detail';
import { SQL_FORMULA_TEMPLETE } from '../formula_builder_v1/shared.fun';
import SyncSvg from '../svg/sync'
import ReloadIcon from '../svg/icons-v2/reloadIcon.js';
import RightClickMenu from './tableRigntClickOptions.js';
import CopyToClipboardBtn from '../hoc/copyToClipboardButton';




const LeftSideWrapper = styled.div`

    width: 100%;
    /* height: ${props => props.height || undefined}; */
    height: 10rem;

    padding-bottom: 1rem;
    /* height: 98%; */

    .sync{
        background: green;
        padding: 0px 4px;
        position: absolute;
        border-radius: 5px;
        right: 118px;
        font-size: 14px;
        color: #fff;
        font-weight: 400;
    }
    .db_function_wrapper{

    }


    .db_switch_box{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .l_bl{
            margin-left: 10px;
            display: inline-block;
            font-size: .9rem;
            color: #374a66;
        }
    }
    .delete_data_source{
        opacity: 0;
    }

    .tbl_name{
        position: relative;
        background: #fff;
        margin: 0.7rem 3px;
        padding: 0.4rem .2rem;
        svg{
            margin-left: .4rem;
        }
    }
    .tbl_name{
        &:hover{
            .delete_data_source{
                opacity: 1;
            }
        }
    }
/* here need to impliment */
    .schema_panel{

        width: 100%;
        box-sizing: border-box;
        padding-bottom: 10px;
        padding-right: 8px;
        /* overflow: auto;
        max-height: 80vh; */
    }

    .schema_tab{
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        background:  #fff;
        padding: 10px;
       
        p{
            margin: 0px;
            font-size: 1.1rem;
            /* color: #374a66;; */
            color: #fff;;
            font-weight: 500;
        }
    }


    .tbl_list_scrollder{

        width: 100%;
        /* height: calc(100vh - 18rem); */
        /* overflow: auto; */
        overflow: visible; /* it is added for tooltip visible in copy btn */
        box-sizing: border-box;
        padding-bottom: 10px;
        /* padding-right: 4px; */
        &::-webkit-scrollbar {
            background-color: #3F3F3F;
            width:4px;
        }

        &::-webkit-scrollbar-track {
            background-color: #F3F3F3;
            border: none;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #dbdbdb;
            border-radius: 10px;
            height: 4px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color:  #3F3F3F;
            height: 4px;
        }

    }

    .add_column{
        position: absolute;
        right: 10px;
    }



    /* /////////////// */

       .text_label{
        font-size: 1.1rem;
        margin-bottom: 10px;
        color: #308bc8;
        font-weight: 500;
        margin-top: 1.5rem;
        background: #f7f7f8;
        padding: 10px 1rem;
        box-sizing: border-box;
        border: 1px solid #d8d8d8;
        margin-left: -1rem;
    }
    .view_btn{
        opacity: 0;
    }  

    .report_lists{
        width: 100%;
        max-height: 200px;
        overflow: auto;

        &::-webkit-scrollbar {
            background-color: #3F3F3F;
            width:4px;
        }

        &::-webkit-scrollbar-track {
            background-color: #F3F3F3;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#dbdbdb;
            border-radius:10px;
            height: 4px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color:  #3F3F3F;
            height: 2px;
        }

    }
    
   

    .tbl_title{
        font-size: .9rem;
        font-weight: 600;
        text-transform: uppercase;
        /* margin: .5rem 1rem; */
    }
  


    .tbl_name{
        font-size: 1.1rem;
        /* padding: .3rem; */
        /* padding-left: 0rem; */
        padding-right: 0.8rem;
        font-weight: 500;
        box-sizing: border-box;
        text-overflow: ellipsis;
        /* overflow: hidden; */
        overflow: visible; /* it is added for tooltip visible in copy btn */
        white-space: nowrap;
        cursor: pointer;
        color: #374a66;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-radius: 6px;
        border: 1px solid #fff;
        /* border: 1px solid #F3F3F3; */
        /* box-shadow: 0 1px 2px rgba(56, 65, 74, .15); */
        /* box-shadow: 4px 4px 5px 0px rgba(219,219,219,1); */
        box-shadow: 1px 1px 4px 1px rgba(56,64,74,0.3);
        /* text-transform: capitalize; */
        &:hover{
            /* background-color: #f1f5fc; */
            border: 1px solid #337DFF;
            background: #fff;
            /* .tbl_view{
                opacity: 1;
            } */
            .sync{
                opacity: 1;
            }
        }
        .tbl_view{
            opacity: 1;
        }
        .sync{
            opacity: 0;
        }
    }

    .active_tbl{
        /* background-color: #f1f5fc; */
        border: 1px solid #337DFF;
    }

    .tbl_name_no_wrap{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: calc(100% - 70px);
    }

    .collapse_icon{
        margin-left: auto;
    }
    .column_list{

        margin: .1rem 0px;
        list-style: none;
        padding-left: 0px;
        max-height: 25rem;
        overflow-y: auto;
        &::-webkit-scrollbar {
            background-color: #3F3F3F;
            width:4px;
        }

        &::-webkit-scrollbar-track {
            background-color: #F3F3F3;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#dbdbdb;
            border-radius:10px;
            height: 4px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color:  #3F3F3F;
            height: 2px;
        }


        li{
            font-size: 1rem;
            line-height: 2rem;
            cursor: pointer;
            display: flex;
            color: #374a66;
            padding-left: 1.5rem;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            box-sizing: border-box;
            /* border: 2px solid #fff; */
            border-radius: 6px;
            &:hover{
                /* background-color: #f1f5fc; */
                background: #EFEFEF;
                /* border: 2px solid #EFEFEF; */
            }
            .copy_icon{
                display: flex;
                flex-direction: row-reverse;
                flex: auto;
                margin-right: 1.5rem;
            }
        }
    }

    .column_name_title{
       margin: 0px;
        font-size: .9rem;
        font-weight: 600;
        color: #444;
    }


    .column_sorting{
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        .rotate_180deg {
            transform: rotate(90deg);
        }
    }


    .d_type_icon{
        width: .9rem;
        height: .9rem;
        border: 1px solid #afc4e3;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .6rem;
        font-weight: bolder;
        border-radius: 3px;
        color: #afc4e3;
    }


    .text_box_wrapper{

        width: 100%;
        position: relative;

        span{
            position: absolute;
            right: .7rem;
            top: .6rem;
        }

        input{

            width: 100%;
            height: 2.3rem;
            /* border: none; */
            border: 1.5px solid #939393;
            padding: 1rem;
            box-sizing: border-box;
            padding-left: .5rem;
            font-size: .9rem;
            /* box-shadow: 1px 1px 4px 0px #ccccccbf; */
            background: #fff;
            border-radius: 6px;

            &:-webkit-placeholder{
                color: #ccc;
            }
            &:focus{
                outline:none;
            }
        }
    }

`;


const icon_types = {
    'number': <span>#</span>,
    'date': <span>D</span>,
    'string': <span>T</span>,
    'boolean': <span>B</span>,
    'formula': <FormulaSvg color='#afc4e3' size='.6rem' height='.6rem' />
}
const number_data_types = ['int', 'float', 'double', 'number', 'currency'];


const convert_display_fields_into_table_column = (reports, report_name) => {

    const _tbl_columns = [];
    let column_info = [];
    reports && reports.length > 0 && reports.forEach((report_item) => {
        const column_i = report_item && report_item.column_infos ? report_item.column_infos : [];
        column_info = [...column_info, ...column_i]
    })


    const find_hint_in_hints_array = (hint) => {
        for (let index = 0; index < _tbl_columns.length; index++) {
            const element = _tbl_columns[index];
            if (element.alias === hint.alias) {
                return true;
            }
        }
    }

    column_info && column_info.length > 0 && column_info.map((f) => {
        if (!find_hint_in_hints_array(f)) {
            _tbl_columns.push({
                alias: f.alias,
                column_name: f.orignal_name,
                table_name: report_name,
                data_type: f.data_type
            })
        }
    })

    return _tbl_columns

}





const LeftSidePanel = (props) => {

    // here we are destructuring the props object 
    const {

        tableColumns,
        connectionId,
        localTableColumns,
        activeTable,
        dataSources,
        searchTable,
        setActiveTable,
        activeReportId,
        elRefs,
        isColumnOpen,
        setIsColumnOpen,
        hide_db_changer = false,
        hide_search_table = false,

    } = props;

    const {

        set_open_table_column,
        open_table_column,

        reporting_db_info_id,
        set_reporting_db_info_id,

    } = props;



    const { schema_table_info, db_infos, hide_user_data_source, cached_db_information } = props;


    // here we are decaliring the state of the component 

    const [isReportOpen, setIsReportOpen] = useState(undefined);
    const [open_schema, set_open_schema] = useState({});
    const [open_table, set_open_table] = useState({});
    const [open_user_data_source, set_open_user_data_source] = useState(true);
    const [open_db_functions, set_open_db_functions] = useState(true)
    // for right click functionality
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const [rightClickedData, setRightClickedData] = useState({});



    // this is only for open table dropdown  whenever we will click over the table list 
    // it will auto open the table dropdow

    useEffect(() => {
        if (open_table_column?.schema_id && open_table_column.table_name) {

            const clone_open_table = open_table ? { ...open_table } : {};

            clone_open_table[open_table_column?.schema_id] = open_table_column.table_name;

            set_open_table(clone_open_table)
        }
    }, [open_table_column])



    // let's sey user will select only one db then we will open the default
    useEffect(() => {

        if (schema_table_info && Object.keys(schema_table_info).length === 1) {
            const __key__ = Object.keys(schema_table_info)[0];
            set_open_schema({
                ...open_schema,
                [__key__]: true
            })
        }
    }, [schema_table_info])




    const openTablePopup = (tableName, schema_name, db_type, table_schema) => {

        showPopup(undefined, undefined, enums.default.popupType.element, TableViewPopup, {
            tableName: tableName,
            connectionId: schema_name,
            db_type: db_type,
            table_schema: table_schema,
        })

    }

    const add_new_column = (tableName, schema_name, db_type, table_schema) => {

        showPopup("Add Column", undefined, enums.default.popupType.element_with_header, TableColumnAddEdit,
            {
                tableName: tableName,
                db_info_id: schema_name,
                db_type: db_type,
                schema_name: table_schema,
                save_data: (data) => {
                    props.add_new_column_into_table(data)
                }
            },
            undefined,
            undefined,
            undefined,
            {
                width: '30rem',
                height: '20rem',
            }
        )
    }

    const local_delete_data_source = (id) => {
        showPopup(undefined, 'Are you sure you want to delete this ? ', enums.default.popupType.element, ConfirmBox, { func: { setYes: () => props.delete_report(id, "data_source") } })

    }

    const is_cached_db_selected = (reporting_db_info_id?.length > 0 && reporting_db_info_id?.[0].indexOf("__nf__db__cached__") > -1);


    const sql_functions = SQL_FORMULA_TEMPLETE?.['mysql'];


    const handleContextMenu = (event, tablesData, table) => {
        setRightClickedData({ tableData: tablesData, selectedTable:table })
        event.preventDefault();
        setMenuVisible(true);
        setMenuPosition({ x: event.clientX, y: event.clientY - 30 });
      };   

    return (
        <LeftSideWrapper height={props.height}>

            {!hide_db_changer && (
                <DbSwitcher
                    db_info_id={connectionId || []}
                    set_reporting_db_info_id={(value) => {
                        set_reporting_db_info_id(value)
                        if (value && value.indexOf("__nf__db__cached__" > -1)) {
                            // let's fetch cached db table and columns
                            props.get_schema_table_info([value], connectionId[0])
                            props.getAllRelationships(value)

                        } else {
                            props.get_schema_table_info(connectionId)
                            props.getAllRelationships([connectionId])
                        }
                    }}
                    reporting_db_info_id={reporting_db_info_id}

                />
            )}

            {!schema_table_info && connectionId && connectionId.length > 0 && (
                <p style={{ color: '#374a66' }}>Please wait...</p>
            )}

            {(!connectionId || connectionId.length === 0) && (
                <p style={{ color: '#374a66' }}>Please Select Schema</p>
            )}


            {!hide_search_table && schema_table_info && Object.keys(schema_table_info).length > 0 && (
                <div style={{ width: '97%', boxSizing: 'border-box', marginBottom: '1rem' }}>
                    <div className='text_box_wrapper'>
                        <input
                            type="text"
                            placeholder="SEARCH"
                            onChange={(e) => {
                                searchTable(e.target.value)
                            }}
                        />
                        <span>
                            <SearchSvg size={'1rem'} height={'1rem'} color="#ccc" />
                        </span>
                    </div>
                </div>
            )}
            <RightClickMenu menuVisible={menuVisible} setMenuVisible={setMenuVisible} rightClickedData={rightClickedData} menuPosition={menuPosition} >

            {localTableColumns && Object.keys(localTableColumns).length > 0 && Object.keys(localTableColumns).map((s_key) => {

                const sh_tables = localTableColumns[s_key];
                const db_info = (db_infos?.length > 0 && db_infos.find(d => d.id === s_key)) || {};

                const plugin = is_cached_db_selected ? cached_db_information?.plugin_for_h_connection : db_info?.plugin_for_h_connection;
                const connection_name = is_cached_db_selected ? (cached_db_information?.connection_name || "Cached DB") : db_info?.connection_name;
                const schema_name = is_cached_db_selected ? cached_db_information?.database_name : db_info?.database_name
                const db_type = is_cached_db_selected ? (cached_db_information?.db_type || "mysql") : db_info?.db_type;
                const is_schema_open = open_schema && open_schema[s_key];

                return (
                    <div
                        className={"schema_panel"}
                        key={"schema" + s_key}
                    >
                        <div
                            style={{
                                paddingBottom: is_schema_open ? '10px' : undefined,
                                // marginBottom: is_schema_open ? '8px' : undefined,
                                borderBottom: is_schema_open ? '1px solid #ccc' : undefined,
                                // background: is_schema_open ? 'linear-gradient(to right, #3E9EFF, #737DFF)' : undefined,
                                background: 'linear-gradient(to right, #3E9EFF, #737DFF)',
                                borderRadius: '6px',
                                position: 'relative'
                            }}
                            className="schema_tab"
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                if (is_schema_open) set_open_schema({ ...open_schema, [s_key]: false })
                                else set_open_schema({ ...open_schema, [s_key]: true })

                            }}
                        >
                            <p>{connection_name || 'Your Database'}</p>

                            <div style={{
                                position: 'absolute',
                                right: '2rem',
                                top: '13px',
                                cursor: 'pointer'
                            }}
                                title='Refresh The Schema'
                                onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    showPopup(undefined, "are you sure you want to refresh the schema ?", enums.default.popupType.element, ConfirmBox, {
                                        func: {
                                            setYes: () => {
                                                props.get_schema_table_info([reporting_db_info_id], undefined, true)

                                            }
                                        }
                                    })
                                }}
                            >
                                <ReloadIcon size='1rem' height='1rem' color="#fff" />
                            </div>
                            {/* <p>Your Database</p> */}
                            <div
                                title={is_schema_open ? "collapse" : "expand"}
                                className="collapse_icon">
                                <Chevron
                                    style={{
                                        transform: is_schema_open ? "rotate(180deg)" : 'rotate(0deg)'
                                    }}
                                    size=".7rem" height=".7rem" color="rgb(175, 196, 227)" />
                            </div>
                        </div>

                        {is_schema_open && (
                            <div className="tbl_list_scrollder">

                                {sh_tables && Object.keys(sh_tables).length > 0 ? Object.keys(sh_tables).map((table, index) => {
                                    const __key__ = s_key + "__nfx__" + table;
                                    const is_open = open_table && open_table[s_key] === table;
                                    // const first_column = sh_tables?.[table] && Object.keys(sh_tables[table])?.length > 0 && Object.keys(sh_tables[table])[0];

                                    var table_schema_from_orign = null;

                                    Object.keys(sh_tables[table])?.length > 0 && Object.keys(sh_tables[table])?.map((kkk) => {
                                        if (sh_tables[table]?.[kkk]?.['table_schema'] && !table_schema_from_orign) {
                                            table_schema_from_orign = sh_tables[table][kkk]['table_schema']
                                        }
                                    })


                                    // const table_schema_from_orign = sh_tables?.[table]?.[first_column]?.table_schema || undefined;
                                    const table_schema = (typeof table_schema_from_orign !== "undefined" && typeof table_schema_from_orign !== null) ? table_schema_from_orign : undefined;

                                    return (
                                        <div
                                            ref={elRefs && elRefs.current && elRefs.current[index]}
                                            key={'table_name' + index}
                                            onClick={(e) => {
                                                // e.stopPropagation()
                                                if (is_open) set_open_table({ ...open_table, [s_key]: false })
                                                else set_open_table({ ...open_table, [s_key]: table })
                                                // set_open_table
                                                // if (activeTable && activeTable === __key__) {
                                                //     setActiveTable(undefined)
                                                //     setIsColumnOpen(false)
                                                // }
                                                // else {
                                                //     setActiveTable(__key__)
                                                //     setIsColumnOpen(true)
                                                // }
                                            }}
                                        >
                                            <div
                                                className={`tbl_name ${is_open ? 'active_tbl' : ''}`}
                                                onContextMenu={(event)=>handleContextMenu(event, sh_tables, table )}
                                                draggable={true}
                                                onDragStart={(event) => {
                                                    event.dataTransfer.effectAllowed = "move"
                                                    const column_info = {
                                                        table_name: table,
                                                        schema_id: s_key,
                                                        schema_name: schema_name,
                                                        table_schema: table_schema,
                                                        connection_name: connection_name,
                                                        plugin: plugin,
                                                        db_type: db_type,
                                                        data_transfer_mode: 'parent',
                                                        data_transfer_from: 'left_panel',
                                                    }
                                                    event.dataTransfer.setData("column_info", JSON.stringify(column_info));
                                                }}
                                            >
                                                <TableSvg size=".9rem" height=".9rem" color={'#9d9d9d'} />
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <div title={table} className="tbl_name_no_wrap">{table}</div>

                                                <CopyToClipboardBtn text={table} />
                                                <div title="View Table Data" className="tbl_view" onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation()
                                                    openTablePopup(table, s_key, db_type, table_schema)

                                                }}>
                                                    <ViewSvg size='.9rem' height=".9rem" color={'#9d9d9d'} />
                                                </div>

                                                {/* {true && ( */}
                                                {props.is_etl && false && (
                                                    <>
                                                        <div
                                                            className="sync"
                                                            style={{ background: (props?.sync === "asc" ? "#008AC7" : 'red'), right: '89px' }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                props.update_sync("asc")
                                                            }}
                                                            title='Sync By Ascending'
                                                        >
                                                            <SyncSvg
                                                                size=".7rem" height=".7rem" color="#fff" /> &nbsp;
                                                            ASC
                                                        </div>
                                                        <div
                                                            title='Sync By Descending'
                                                            style={{
                                                                right: '150px',
                                                                background: (props?.sync === "desc" ? "#008AC7" : undefined)
                                                            }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                props.update_sync("desc")
                                                            }}
                                                            className="sync">
                                                            <SyncSvg
                                                                size=".7rem" height=".7rem" color="#fff" />&nbsp;
                                                            DESC
                                                        </div>
                                                    </>
                                                )}

                                                <div
                                                    className="collapse_icon">
                                                    <Chevron
                                                        style={{
                                                            transform: (is_open) ? "rotate(180deg)" : 'rotate(0deg)'
                                                        }}
                                                        size=".8rem" height=".8rem" color="#9d9d9d" />
                                                </div>
                                                {db_type === "hive" && (
                                                    <div
                                                        onClick={(e) => {

                                                            e.preventDefault();
                                                            e.stopPropagation()
                                                            add_new_column(table, s_key, db_type, table_schema)

                                                        }}
                                                        title="Add New Column"
                                                        className="add_column">
                                                        <AddSvg
                                                            size=".7rem" height=".7rem" color="rgb(175, 196, 227)" />
                                                    </div>
                                                )}
                                            </div>


                                            {is_open && sh_tables[table] &&
                                                <div>
                                                    <ul className="column_list">
                                                        {sh_tables[table] && Object.keys(sh_tables[table]).length > 0 && Object.keys(sh_tables[table]).map((col, i) => {
                                                            return (
                                                                <li
                                                                    draggable={true}
                                                                    onDragStart={(event) => {
                                                                        const column_info = {
                                                                            column_name: col,
                                                                            table_name: table,
                                                                            schema_id: s_key,
                                                                            schema_name: schema_name,
                                                                            table_schema: table_schema,
                                                                            connection_name: connection_name,
                                                                            plugin: plugin,
                                                                            db_type: db_type,
                                                                            data_transfer_mode: 'child',
                                                                            data_transfer_from: 'left_panel',
                                                                            data_type: sh_tables[table][col].type,
                                                                            db_data_type: sh_tables[table][col].type,
                                                                            character_length: sh_tables?.[table]?.[col]?.character_length,
                                                                        }

                                                                        event.dataTransfer.setData("column_info", JSON.stringify(column_info));
                                                                        // event.dataTransfer.setData("column_name", col);
                                                                        // event.dataTransfer.setData("table_name", table);
                                                                        // event.dataTransfer.setData("schema_id", s_key);
                                                                        // event.dataTransfer.setData("schema_name", schema_name);
                                                                        // event.dataTransfer.setData("connection_name", connection_name)
                                                                        // event.dataTransfer.setData("plugin", plugin)
                                                                        // event.dataTransfer.setData("db_type", db_type);
                                                                        // event.dataTransfer.setData("data_transfer_mode", 'child')
                                                                        // event.dataTransfer.setData("data_transfer_from", "left_panel");
                                                                        // event.dataTransfer.setData('data_type', sh_tables[table][col].type)
                                                                        event.dataTransfer.effectAllowed = "move"
                                                                    }}
                                                                    onClick={(e) => {
                                                                        e.preventDefault()
                                                                        e.stopPropagation()
                                                                    }}
                                                                    key={"column" + i}>
                                                                    {renderIconByDataType(sh_tables[table][col].type)}
                                                                    &nbsp;&nbsp;{col}
                                                                    <div className='copy_icon'>
                                                                        <CopyToClipboardBtn text={col} />
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                                    : <p style={{ margin: '5px', fontSize: '.8rem' }}>No Table Found..</p>
                                }
                            </div>
                        )}

                    </div>
                )
            })}
            </RightClickMenu>
            {/*
 <>
            {!is_cached_db_selected && !hide_user_data_source && dataSources && dataSources.length > 0 && (dataSources.filter(report => report.type === 'data_source') || []).length > 0 && (props.type !== 'data_source' && props.type !== 'cache_editor') && (
                <React.Fragment>
                    <div className={"schema_panel"}>
                        <div
                            style={{
                                paddingBottom: open_user_data_source ? '0px' : undefined,
                                marginBottom: open_user_data_source ? '0px' : undefined,
                                borderBottom: open_user_data_source ? '1px solid #ccc' : undefined,
                                paddingTop: open_user_data_source ? '0px' : undefined,
                                background: open_user_data_source ? '#337DFF' : undefined,
                            }}
                            className="schema_tab"
                            onClick={() => {
                                if (open_user_data_source) set_open_user_data_source(false)
                                else set_open_user_data_source(true)
                            }}
                        >
                            <p className={open_user_data_source ? "text_label" : undefined} style={{ marginLeft: `${open_user_data_source ? "-1rem" : "0rem"}`, background: 'none', border: 'none', fontSize: '1rem' }}>User Data Source</p>
                            <div
                                className="collapse_icon">
                                <Chevron
                                    style={{
                                        transform: open_user_data_source ? "rotate(180deg)" : 'rotate(0deg)'
                                    }}
                                    size=".7rem" height=".7rem" color="rgb(175, 196, 227)" />
                            </div>
                        </div>

                        {open_user_data_source && (
                            <div className="tbl_list_scrollder" style={{ maxHeight: '200px' }}>
                                
                                {dataSources.filter((r => r.id !== activeReportId && r.name)).map((report, index) => {
                                    const report_items = report.report_items;
                                    const table_columns = convert_display_fields_into_table_column(report_items, report.name)
                                    return (
                                        <div key={'table_name' + index}>
                                            <div className="tbl_name"
                                                style={{ paddingRight: '10px' }}
                                                onClick={() => {
                                                    if (isReportOpen === report.id) {
                                                        setIsReportOpen(undefined)
                                                    }
                                                    else {
                                                        setIsReportOpen(report.id)
                                                    }
                                                }}
                                            >
                                                <TableSvg size=".9rem" height=".9rem" color={'#7CA5D9'} />
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                <div onDoubleClick={(() => {
                                                    const url = window.location.protocol + '//' + window.location.hostname + (process.env.NODE_ENV === 'production' ? '' : ':3000') + `/data_source_editor?report_id=${report.id}`;
                                                    // const url = `http://127.0.0.1:3000/data_source_editor?report_id=${report.id}`
                                                    window.open(url);

                                                })} title={report.name} className="tbl_name_no_wrap">{report.name}</div>
                                                <div
                                                    className="collapse_icon">
                                                    <Chevron
                                                        style={{
                                                            transform: (isReportOpen && isReportOpen === report.id) ? "rotate(180deg)" : 'rotate(0deg)'
                                                        }}
                                                        size=".7rem" height=".7rem" color="rgb(175, 196, 227)" />
                                                </div>
                                                <div
                                                    className="delete_data_source"
                                                    title="Delete"
                                                    style={{ marginLeft: '15px' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        local_delete_data_source(report.id)
                                                    }}
                                                >
                                                    <DeleteSvg
                                                        style={{
                                                            transform: (isReportOpen && isReportOpen === report.id) ? "rotate(180deg)" : 'rotate(0deg)'
                                                        }}
                                                        size=".7rem" height=".7rem" color="rgb(175, 196, 227)" />
                                                </div>
                                            </div>
                                            {isReportOpen && isReportOpen === report.id && (
                                                <div>
                                                    <ul className="column_list">
                                                        {table_columns && table_columns.length > 0 && table_columns.map((col, i) => {
                                                            return (
                                                                <li
                                                                    draggable={true}
                                                                    onDragStart={(event) => {
                                                                        event.dataTransfer.effectAllowed = "move"
                                                                        const column_info = {
                                                                            column_name: col.alias,
                                                                            table_name: col.table_name,
                                                                            schema_id: undefined,
                                                                            schema_name: undefined,
                                                                            connection_name: undefined,
                                                                            plugin: undefined,
                                                                            db_type: undefined,
                                                                            data_transfer_mode: 'child',
                                                                            data_transfer_from: 'left_panel',
                                                                            data_type: col.data_type,
                                                                            // db_data_type: col.data_type
                                                                        }
                                                                        event.dataTransfer.setData("column_info", JSON.stringify(column_info));
                                                                    }}
                                                                    key={"rpt_column" + i}>
                                                                    {renderIconByDataType(col.data_type)}
                                                                    &nbsp;&nbsp;{col.alias}
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </React.Fragment>
            )}
            </>
         */}


            {props.show_db_functions && (
                <div className='db_function_wrapper schema_panel'>
                    <div
                        style={{
                            paddingBottom: open_db_functions ? '0px' : undefined,
                            marginBottom: open_db_functions ? '0px' : undefined,
                            borderBottom: open_db_functions ? '1px solid #ccc' : undefined,
                            paddingTop: open_db_functions ? '0px' : undefined,
                            // background: open_db_functions ? '#337DFF' : undefined,
                            background: 'linear-gradient(to right, #3E9EFF, #737DFF)',
                        }}
                        className="schema_tab"
                        onClick={() => {
                            if (open_db_functions) set_open_db_functions(false)
                            else set_open_db_functions(true)
                        }}
                    >
                        <p className={open_db_functions ? "text_label" : undefined} style={{ marginLeft: `${open_db_functions ? "-1rem" : "0rem"}`, background: 'none', border: 'none', fontSize: '1rem' }}>
                            {/* DB FUNCTIONS */}
                            {/* utility function */}
                            UTILITY FUNCTION
                        </p>
                        <div
                            className="collapse_icon">
                            <Chevron
                                style={{
                                    transform: open_db_functions ? "rotate(180deg)" : 'rotate(0deg)'
                                }}
                                size=".7rem" height=".7rem" color="rgb(175, 196, 227)" />
                        </div>
                    </div>

                    {open_db_functions && (
                        <div className="tbl_list_scrollder" style={{ maxHeight: '200px' }}>
                            {sql_functions && Object.keys(sql_functions).map((f, index) => {
                                return (
                                    <div key={'table_name' + index}>
                                        <div className="tbl_name"
                                            style={{ paddingRight: '10px' }}
                                            draggable={true}
                                            onDragStart={(event) => {
                                                const column_info = {
                                                    column_name: f,
                                                    type: 'db_function'
                                                }
                                                event.dataTransfer.setData("column_info", JSON.stringify(column_info));
                                                event.dataTransfer.effectAllowed = "move"
                                            }}
                                        >
                                            <FormulaSvg size=".9rem" height=".9rem" color={'#7CA5D9'} />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <div title={f} className="tbl_name_no_wrap">{f}</div>
                                        </div>
                                    </div>
                                )

                            })}
                        </div>
                    )}

                </div>
            )}

        </LeftSideWrapper >
    )
}



const mapStateToProps = (state) => ({
    tableColumns: state.connectionReducer.tableColumns,
    schema_table_info: state.reportBuilder.schema_table_info,
    cached_db_information: state.reportBuilder.cached_db_information,
    db_infos: state.connectionReducer.db_infos,

})










export default connect(mapStateToProps, { getAllRelationships, delete_report, add_new_column_into_table })(LeftSidePanel)
