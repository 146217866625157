import * as actions from '../action-types/window';

const initialState = {
    type: actions.WINDOW_INIT,
    width: undefined,
    height: undefined,
    clicked: undefined,
    scrolled: undefined
};


const windowReducer = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }


    switch (action.type) {
        case actions.WINDOW_WIDTH_CHANGE:
            return {
                ...state,
                type: action.type,
                width: action.data
            };

        case actions.WINDOW_HEIGHT_CHANGE:
            return {
                ...state,
                type: action.type,
                height: action.data
            };

        case actions.WINDOW_IS_CLICKED:
            return {
                ...state,
                type: action.type
            };

        case actions.WINDOW_IS_SCROLLED:
            return {
                ...state,
                type: action.type
            };

        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
            return {
                type: state.type,
                width: state.width,
                height: state.height,
                clicked: state.clicked,
                scrolled: state.scrolled
            };
    }
};

export default windowReducer;