
export const frequencyTypes = [
    { name: 'Seconds', value: 'seconds' },
    { name: 'Minutes', value: 'minutes' },
    { name: 'Hourly', value: 'hourly' },
    { name: 'Daily', value: 'daily' },
    { name: 'Weekly', value: 'weekly' },
    { name: 'Monthly', value: 'monthly' },
    { name: 'Yearly', value: 'yearly' },
    { name: 'Quarterly', value: 'quarterly' },
    // { name: 'Quarterly', value: 'first_week_day_of_quarter' },
    // { name: 'Quarterly', value: 'first_week_day_of_month' },
    { name: 'One Time', value: 'one_time' },
]



export const delete_frequency_types = [
    { name: 'Current Month', value: 'current_month' },
    { name: 'Current Year', value: 'current_year' },
    { name: 'Last Month', value: 'last_month' },
    { name: 'Last Year', value: 'last_year' },
]

