import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import styled from 'styled-components';
import CloseSvg from '../svg/close';
import Checkbox from '../hoc/checkbox'


const OtherConfig = (props) => {

    const { localRunningTotal, setLocalRunningTotal} = props;

    return (
        <div>
           <div style={{ marginLeft: '10px', fontSize: '0.9rem' }}>Options:
            <Checkbox 
                    checked={localRunningTotal}
                    label={"Running Total"}
                    onClick={() => {
                        setLocalRunningTotal(!localRunningTotal)
                    }}
                />

           </div> 
           <br />
           {/* <div style={{ marginLeft: '10px', fontSize: '0.9rem' }}>Chart Settings:</div>  */}
        </div>
    )
};


const mapStateToProps = (state, props) => {

    return {
    };
}



export default connect(mapStateToProps, {  })(OtherConfig)
