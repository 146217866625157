import * as actions from '../action-types/filter';
import * as commonActions from '../action-types/common';
import { constants } from '../utils/constants';

const initialState = {
    type: undefined,
    recordsCount: 0,
    /**
     * format of filter
     * {
     *  name
     * }
     */
    filters_cache: {},
    dropdowns_cache: {},
    mtd_ytd_options_cache: {},
    which_option_expanded_cache: {},
    backend_filter_stored: {},
    destroy_global_filter_data_cache: {},
    drill_filter_cache: {},


    // v2 link filter
    link_filter_cache: {},
    deleted_link_filter: undefined,

    // this is use for choose filter
    filterOptions: {},

    advance_date_setting_cache: {},

    global_filter_widgets_ids_cache: [],


};



const find_existing_filter_index = (link_f_obj) => {
    let i = 1;
    if (link_f_obj && Object.keys(link_f_obj).length > 0) {
        Object.keys(link_f_obj).forEach(key => {
            if (link_f_obj[key].index > i) {
                i = link_f_obj[key].index;
            }
        })
    }
    return i

}


const filterReducer = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }
    // if (action && action.data && typeof action.data === 'object') {
    //     action.data.key = new Date();
    // }

    /**
     * base_report_id can be the report_item_id, or the report_id according to the case
     * for example: in v2.0 report builder, we will be using the report_id for reports
     * but for dashboard, we will use report_item_id
     */
    const base_report_id = action && action.data && action.data.base_report_id;

    const save_filter_data = (report_id, filters_data) => {

        const existing_filters_cache = JSON.parse(JSON.stringify(state.filters_cache));


        if (typeof filters_data === 'undefined') {
            // filter_name is undefined.
            // a flag which means we need to clear all filters
            existing_filters_cache[report_id] = {};

            return existing_filters_cache;
        }

        if (typeof existing_filters_cache[report_id] === 'undefined') {
            existing_filters_cache[report_id] = {};
        };

        existing_filters_cache[report_id] = filters_data;

        return existing_filters_cache;
    };

    const clear_filter_data = (report_id, filter_name, filter_value) => {
        const existing_filters_cache = JSON.parse(JSON.stringify(state.filters_cache));

        if (typeof filter_name === 'undefined') {
            // filter_name is undefined.
            // a flag which means we need to clear all filters
            existing_filters_cache[report_id] = {};

            return existing_filters_cache;
        }

        if (typeof existing_filters_cache[report_id] === 'undefined') {
            existing_filters_cache[report_id] = {};
        };


        const existing_filter_values = existing_filters_cache[report_id][filter_name] || [];
        let index_of_value = existing_filter_values.indexOf((filter_value + '').toLocaleUpperCase());


        if (index_of_value > -1) existing_filter_values.splice(index_of_value, 1);


        if (index_of_value === -1) {
            // may be a date filter
            const date_category = filter_value.split('_nfd_')[0];
            const date_value = filter_value.split('_nfd_')[1] && parseFloat(filter_value.split('_nfd_')[1]);


            if (date_category && date_value && existing_filters_cache[report_id]['date_filter']
                && existing_filters_cache[report_id]['date_filter'][filter_name]
                && existing_filters_cache[report_id]['date_filter'][filter_name][date_category]) {
                index_of_value = existing_filters_cache[report_id]['date_filter'][filter_name][date_category].indexOf(date_value);


                if (index_of_value > -1) existing_filters_cache[report_id]['date_filter'][filter_name][date_category].splice(index_of_value, 1);
            }
        }




        return existing_filters_cache;
    };


    switch (action.type) {

        case actions.GET_FILTER_OPTIONS_TABLE_COLUMNS_V1:

            return {
                ...state,
                type: action.type,
                filterOptions: action?.data?.filters

            }

        case actions.STORE_GLOBAL_FILTER_WIDGETS_IDS:

            const _insight_id = action?.data?.insight_id;
            const clone_global_filter_widgets_ids_cache = state.global_filter_widgets_ids_cache ? { ...state.global_filter_widgets_ids_cache } : {};
            clone_global_filter_widgets_ids_cache[_insight_id] = action?.data?.widegt_ids;


            return {
                ...state,
                type: action.type,
                global_filter_widgets_ids_cache: clone_global_filter_widgets_ids_cache
            }
        case actions.REMOVE_GLOBAL_FILTER_WIDGETS_ID:

            const __insight_id = action?.data?.insight_id;
            const ___id = action?.data?.id;

            const _global_filter_widgets_ids_cache = state.global_filter_widgets_ids_cache ? { ...state.global_filter_widgets_ids_cache } : {};

            if (___id && __insight_id) {
                const clone_global_filter_widgets_ids = _global_filter_widgets_ids_cache[__insight_id] || [];

                clone_global_filter_widgets_ids.splice(clone_global_filter_widgets_ids.indexOf(___id), 1)
                _global_filter_widgets_ids_cache[__insight_id] = clone_global_filter_widgets_ids;
            }


            return {
                ...state,
                type: action.type,
                global_filter_widgets_ids_cache: _global_filter_widgets_ids_cache
            }

        case actions.V2_GET_FILTER_DROPDOWN_DATA_SUCCESS:
            /**
             * this will get the data for dropdowns from backend
             */

            const action_data = action.data;
            const existing_dropdown_cache = JSON.parse(JSON.stringify(state.dropdowns_cache));

            if (action && action.data && action.data['base_report_id']) {
                delete action_data['base_report_id']
            }

            if (action && action.data && action.data['response_session_id']) {
                delete action_data['response_session_id']
            }

            if (action && action.data && action.data['final_response_time']) {
                delete action_data['final_response_time']
            }

            if (typeof existing_dropdown_cache[base_report_id] === 'undefined') {
                existing_dropdown_cache[base_report_id] = {};
            }

            existing_dropdown_cache[base_report_id] = {
                ...action_data,
            };

            // console.log("===existing_dropdown_cache", existing_dropdown_cache)
            return {
                ...state,
                type: action.type,
                dropdowns_cache: existing_dropdown_cache
            };

        case actions.UPDATE_REPORT_FILTER_INFO:

            const payload1 = action.data;

            const existing_dropdown_cache3 = JSON.parse(JSON.stringify(state.dropdowns_cache));
            const existing_dropdown_data = existing_dropdown_cache3[payload1.report_id];

            const filter_info = payload1?.filter_info;

            filter_info && Object.keys(filter_info)?.length > 0 && Object.keys(filter_info).forEach((k) => {
                if (existing_dropdown_data[k]) {
                    existing_dropdown_data[k]['filter_info'] = filter_info[k]
                }
            })

            existing_dropdown_cache3[payload1.report_id] = existing_dropdown_data


            return {
                ...state,
                type: action.type,
                dropdowns_cache: existing_dropdown_cache3
            }


        case actions.V2_GET_FILTER_DISTINCT_DATA:

            const _payload = action.data;
            const existing_dropdown_cache1 = JSON.parse(JSON.stringify(state.dropdowns_cache));

            if (existing_dropdown_cache1 && existing_dropdown_cache1[_payload.base_report_id]) {
                if (existing_dropdown_cache1[_payload.base_report_id][_payload.filter_key]) {
                    existing_dropdown_cache1[_payload.base_report_id][_payload.filter_key].filter_data = _payload.filter_data;
                    existing_dropdown_cache1[_payload.base_report_id][_payload.filter_key].search_value = _payload.search_value;

                }
            }

            // console.log("existing_dropdown_cache1", existing_dropdown_cache1)
            return {
                ...state,
                type: action.type,
                dropdowns_cache: existing_dropdown_cache1

            }

        case actions.V2_APPLY_FILTERS:
            const updated_filter_values = save_filter_data(base_report_id, (action.data && action.data.filters) || []);

            // console.log("updated_filter_values", updated_filter_values)
            return {
                ...state,
                type: action.type,
                filters_cache: updated_filter_values
            };
        case actions.V2_CLEAR_ALL_FILTERS:
            const updated_filter_values_v1 = clear_filter_data(base_report_id);

            return {
                ...state,
                type: action.type,
                filters_cache: updated_filter_values_v1
            };

        case actions.V2_CLEAR_SINGLE_FILTER:
            const updated_filter_values_v2 = clear_filter_data(base_report_id, action.data.filter_name, action.data.filter_value);


            return {
                ...state,
                type: action.type,
                filters_cache: updated_filter_values_v2
            };
        case actions.V2_MARK_YTD_MTD:
            const ytd_mtd_option = action.data && action.data.ytd_mtd_option;
            const existing_ytd_mtd_cache = JSON.parse(JSON.stringify(state.mtd_ytd_options_cache));
            existing_ytd_mtd_cache[base_report_id] = ytd_mtd_option;

            return {
                ...state,
                type: action.type,
                ytd_mtd_option,
                mtd_ytd_options_cache: existing_ytd_mtd_cache
            };

        case actions.V2_EXPAND_OPTION:
            const option_expanded = action.data && action.data.option_expanded;
            const existing_options_expanded = JSON.parse(JSON.stringify(state.which_option_expanded_cache));

            if (existing_options_expanded[base_report_id] === option_expanded) {
                existing_options_expanded[base_report_id] = undefined;
            }
            else {
                existing_options_expanded[base_report_id] = option_expanded;
            }
            return {
                ...state,
                type: action.type,
                which_option_expanded_cache: existing_options_expanded
            };

        case actions.V2_RAISE_BACKEND_FILTER_FOR_STORE:

            const temp_backend_filter_stored = state.backend_filter_stored ? JSON.parse(JSON.stringify(state.backend_filter_stored)) : {};

            const __payload__ = action.data;
            const bs_report_id = __payload__.base_report_id;
            const __filters__ = __payload__.filters;
            temp_backend_filter_stored[bs_report_id] = __filters__;
            // console.log("temp_backend_filter_stored", temp_backend_filter_stored[bs_report_id])

            return {
                ...state,
                type: action.type,
                backend_filter_stored: temp_backend_filter_stored

            };

        case actions.DESTORY_GLOBAL_FILTER_RAISE:

            const insight_id = action?.data?.insight_id;
            const prev_destroy_global_filter_data = state.destroy_global_filter_data ? JSON.parse(JSON.stringify(state.destroy_global_filter_data)) : {}
            prev_destroy_global_filter_data[insight_id] = action.data

            console.log("prev_destroy_global_filter_data", prev_destroy_global_filter_data)
            return {
                ...state,
                type: action.type,
                destroy_global_filter_data_cache: prev_destroy_global_filter_data
            }

        case actions.MT_DELETED_FILTER:
            return {
                ...state,
                type: action.type,
                destroy_global_filter_data_cache: undefined
            }

        case actions.RAISE_DRILL_FILTER_CACHE:


            const prev_drill_filter_data_cache = state.drill_filter_cache ? JSON.parse(JSON.stringify(state.drill_filter_cache)) : {};
            const insight_id1 = action?.data?.insight_id;
            const widget_id = action?.data?.report_id;
            const filter_data = action?.data?.filter_data;



            // let's destroy prev filter of this widget
            if (prev_drill_filter_data_cache && prev_drill_filter_data_cache[widget_id]) {
                const f_keys = prev_drill_filter_data_cache[widget_id].filter_key;
                if (f_keys && f_keys.length > 0) {
                    f_keys.forEach(f => {
                        delete prev_drill_filter_data_cache[f]
                        delete prev_drill_filter_data_cache[widget_id]
                    })
                }
            }


            // let's itreate filter data and push it into to store
            filter_data && Object.keys(filter_data)?.length > 0 && Object.keys(filter_data).forEach(f_key => {
                if (!prev_drill_filter_data_cache[insight_id1]) prev_drill_filter_data_cache[insight_id1] = {};
                prev_drill_filter_data_cache[insight_id1][f_key] = {
                    report_id: widget_id,
                    ...filter_data[f_key]
                };
            })

            prev_drill_filter_data_cache[insight_id1][widget_id] = {
                report_id: widget_id,
                filter_key: Object.keys(filter_data),
                // filter_index: filter_index,
                not_save_in_session: true
            };

            return {
                ...state,
                type: action.type,
                drill_filter_cache: prev_drill_filter_data_cache,
            }

        case actions.CLEAR_DRILL_FILTER_CACHE:

            const insight_id2 = action?.data?.insight_id;
            const report_id2 = action?.data?.report_id;
            const filter_key_to_remove = action?.data?.filter_key;
            const delete_all = action?.data?.delete_all;

            let prev_drill_filter_data_cache1 = state.drill_filter_cache ? JSON.parse(JSON.stringify(state.drill_filter_cache)) : {};

            if (insight_id2 && report_id2 && prev_drill_filter_data_cache1) {

                if (filter_key_to_remove) {
                    delete prev_drill_filter_data_cache1[insight_id2][filter_key_to_remove]
                }
                if (prev_drill_filter_data_cache1[insight_id2][report_id2]?.filter_key?.length > 0) {
                    // let only remove that key from filter
                    let i = prev_drill_filter_data_cache1[insight_id2][report_id2]?.filter_key.indexOf(filter_key_to_remove)
                    prev_drill_filter_data_cache1[insight_id2][report_id2]?.filter_key.splice(i, 1);

                    if (prev_drill_filter_data_cache1[insight_id2][report_id2]?.filter_key.length === 0) {
                        delete prev_drill_filter_data_cache1[insight_id2][report_id2];
                    }

                }

                // let delete report_id
                // if(prev_drill_filter_data_cache1[insight_id2][report_id2]?.filter_key?.length === 1){
                //     delete prev_drill_filter_data_cache1[insight_id2][report_id2];
                // }


                // if (!filter_key_to_remove) {

                //     const filter_key = prev_drill_filter_data_cache1?.[insight_id2]?.[report_id2]?.filter_key
                //     delete prev_drill_filter_data_cache1[insight_id2][report_id2];

                //     if (filter_key && filter_key.length > 0) {
                //         delete prev_drill_filter_data_cache1[insight_id2][filter_key[0]]
                //     }
                // }

            }

            if (delete_all) {
                prev_drill_filter_data_cache1 = {};
            }


            return {
                ...state,
                type: action.type,
                drill_filter_cache: prev_drill_filter_data_cache1

            }


        case actions.APPLY_LINK_FILTER:

            const d_id = action.data.insight_id;
            const wd_id = action.data.report_id;
            const link_filter = action.data.filters

            // link_filter
            // {
            //     "abc" : ["1,2"],
            //     "abc" : ["1,2"],
            // }

            const prev_link_filter_cache = state.link_filter_cache ? JSON.parse(JSON.stringify(state.link_filter_cache)) : {};
            if (!prev_link_filter_cache[d_id]) prev_link_filter_cache[d_id] = {};

            if (!prev_link_filter_cache[d_id][wd_id]) prev_link_filter_cache[d_id][wd_id] = {};

            const filter_index = find_existing_filter_index(prev_link_filter_cache[d_id]);

            prev_link_filter_cache[d_id][wd_id] = {
                filter: link_filter,
                index: filter_index ? parseInt(filter_index) + 1 : 1
            };

            return {
                ...state,
                type: action.type,
                link_filter_cache: prev_link_filter_cache,
                deleted_link_filter: undefined,
            }

        case actions.CLEAR_LINK_FILTER:

            const d_id1 = action.data.insight_id;
            const wd_id1 = action.data.report_id;
            const clear_all = action.data.clear_all;

            const prev_link_filter_cache1 = state.link_filter_cache ? JSON.parse(JSON.stringify(state.link_filter_cache)) : {};
            let deleted_link_filter = undefined;

            if (clear_all) delete prev_link_filter_cache1[d_id1];

            if (!clear_all && prev_link_filter_cache1[d_id1]) {
                deleted_link_filter = prev_link_filter_cache1[d_id1][wd_id1];
                delete prev_link_filter_cache1[d_id1][wd_id1]
            }

            return {
                ...state,
                type: action.type,
                link_filter_cache: prev_link_filter_cache1,
                deleted_link_filter: deleted_link_filter

            }

        case actions.GET_ADVANCE_DATE_CONFIG_SUCCESS:

            const report_id = action.data.report_id;
            const advanced_date_config = action?.data?.advanced_date_config
            const advance_date_setting_cache_clone = state.advanced_date_config ? JSON.parse(JSON.stringify(state.advanced_date_config)) : {};

            if (!advance_date_setting_cache_clone[report_id]) advance_date_setting_cache_clone[report_id] = [];

            advance_date_setting_cache_clone[report_id] = advanced_date_config;

            return {
                ...state,
                type: action.type,
                advance_date_setting_cache: advance_date_setting_cache_clone,
            }

        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
            return {
                ...state,
                type: state.type,

            };
    }
};




// const filterReducer = (state, action) => {
//     if (!state || typeof action.type === 'undefined') {
//         return initialState;
//     }
//     // if (action && action.data && typeof action.data === 'object') {
//     //     action.data.key = new Date();
//     // }


//     switch (action.type) {
//         case actions.SET_FILTER_SUCCESS:
//         case actions.CHANGE_ACTIVE_FILTER_SUCCESS:
//             const data = action.data;

//             let tempfilters_cache = state.filters_cache ? JSON.parse(JSON.stringify(state.filters_cache)) : {};

//             const id = data.reportId;
//             const limit = constants.LIMIT_OF_DATA_IN_REDUX_STATE;

//             if(id && id.length > 0){
//                 tempfilters_cache[id] = action.data;
//             }


//             return {
//                 ...state,
//                 type: action.type,
//                 filters_cache: tempfilters_cache
//             };

//         case actions.GET_FILTERS_SUCCESS:
//             if (action && action.data && action.data['response_session_id']) {
//                 delete action.data['response_session_id']
//             }

//             let tempDropDownsCache = state.dropDownsCache ? JSON.parse(JSON.stringify(state.dropDownsCache)) : {};

//             const data = action.data;
//             const id = data.reportId;

//             if(id && id.length > 0){
//                 dropDownsCache[id] = action.data;


//             return {
//                 ...state,
//                 type: action.type,
//                 dropDownsCache: action.data
//             };
//         }
//         else {
//             return {
//                 ...state,
//                 type: action.type,
//             }
//         }

//         case actions.CLEAR_ALL_FILTER_SUCCESS:
//             const toExclude = ['reportId', 'date_filter'];
//             const tempFilters = Object.assign({}, state.filters);
//             let tempCache = state.filters_cache;
//             //const tempCache = state.filters_cache ? [...state.filters_cache] : undefined;

//             tempFilters && Object.keys(tempFilters).length > 0 && Object.keys(tempFilters).forEach(key => {
//                 if (toExclude.indexOf(key) === -1) {
//                     delete tempFilters[key]
//                 }
//             });

//             tempCache && tempCache.length > 0 && tempCache.forEach((t, index) => {
//                 if (t.id === tempFilters.reportId) {
//                     tempCache[index] = tempFilters;
//                 }
//             })

//             return {
//                 ...state,
//                 type: action.type,
//                 filters_cache: tempCache
//             };

//         case actions.CLEAR_DROP_DOWNS_SUCCESS:
//             return {
//                 ...state,
//                 type: action.type,
//                 dropDownsCache: undefined
//             };

//         case commonActions.RESET_STORE_SUCCESS:
//             return {
//                 type: actions.FILTER_INIT,
//                 filters_cache: undefined,
//                 dropDownsCache: undefined
//             };

//         case actions.CLEAR_DROP_DOWNS_SUCCESS:
//             return {
//                 ...state,
//                 type: action.type,
//                 dropDownsCache: undefined
//             };


//         // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
//         default:
//             return {
//                 ...state,
//                 type: state.type,
//                 filters_cache: state.filters_cache,
//                 dropDowns: state.dropDowns,
//             };
//     }
// };

export default filterReducer;