import { dispatchAction } from './helper';
import * as actionTypes from '../action-types/tiles';
import { post } from '../data.service';



export const checkDataInTileServer = () => async dispatch => {
    const url =  process.env.REACT_APP_TILE_SERVER_URL + 'check_data_in_tile_server'

    const _post = {
        
    }

    try {
        const data = await post(url, _post);
        dispatchAction(dispatch, actionTypes.CEHCK_DATA_IN_TILE_SERVER, data, undefined, undefined, 0);

    } catch (error) {
        dispatchAction(dispatch, actionTypes.CEHCK_DATA_IN_TILE_SERVER, {code: 201}, undefined, undefined, 0);
    }
};