import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TitleHeader from '../../title-header';
import { PageInnerWrapper } from '../../hoc/styled.hoc';
import { getTheConfiguration, getTheConfigurationById, deleteTheConfigurationById } from '../../../actions/sqoop_configration';
import SharedTable from '../../shared-table';
import { Button } from '../../hoc/button';
import { withRouter } from 'react-router-dom'


const Index = (props) => {

    const { getTheConfiguration, configrationData } = props;

    useEffect(() => {
        getTheConfiguration()
    }, [])


    const editAction = (data) => {
        props.history.push(`rdbms_ingestion_editor?id=${data.id}`)

    }

    useEffect(() => {
        let _page_info = {title: 'RDBMS Ingestion', extraComponent: <Button
        primary={true}
        onClick={() => {
            props.history.push("/rdbms_ingestion_editor")
        }}
        >
        Add New Job 
    </Button>}
        props.dispatchHeaderInformation(_page_info)
    },[])

    return (
        <div>
            
            <PageInnerWrapper padding={'2rem 1rem'} style={{marginTop: '1rem'}}>
                {configrationData && configrationData.length > 0 ? (
                    <SharedTable
                        height={"calc(100vh - 180px)"}
                        editAction={editAction}
                        deleteAction={(item) => {
                            props.deleteTheConfigurationById(item.id)
                            setTimeout(() => {
                                getTheConfiguration()
                            }, 1000)

                        }}
                        tableData={(configrationData && configrationData.length > 0) ? configrationData : undefined}
                        tableHead={["Source Db", "Connection String", "UserName", "Query", "target Path", "Target UserName", "Actions"]}
                        columnsToShow={["source_db", "connection_string", "username", "query_in_natural_language", "target_path", "target_username"]}
                    />
                ) : <p style={{ marginTop: '5rem', textAlign: 'center' }}>
                        <span onClick={() => {
                            props.history.push("/sqoop_configration_editor")
                        }} >Please Add Your First Job Configuration Here</span>
                    </p>}

            </PageInnerWrapper>
        </div>
    )
}


const mapStateToProps = (state) => ({
    configrationData: state.sqoopConfigration.configrationData,

})

export default withRouter(connect(mapStateToProps, { getTheConfiguration, deleteTheConfigurationById })(Index));
