import { _getTokens } from '../utils';
import { constants } from '../utils/constants';


export const whatToDoIfTokenNotFound = () => {
    let userJson = window.localStorage.getItem(constants.SESSION_KEYS.KX.CURRENT_USER);
    let facilityJson = window.localStorage.getItem(constants.SESSION_KEYS.KX.FACILITY);

    let user_id = undefined;
    let account_id = undefined;
    let facility_id = undefined;

    if (userJson) {
        const parsedUserJson = JSON.parse(userJson);

        user_id = parsedUserJson ? parsedUserJson.user_id : undefined;
    }

    if (facilityJson) {
        const parsedFacilityJson = JSON.parse(facilityJson);

        account_id = parsedFacilityJson ? parsedFacilityJson.account_id : undefined;
        facility_id = parsedFacilityJson ? parsedFacilityJson.org_id : undefined;
    }

    if (user_id && account_id && facility_id) {
        const options = {
            user_id,
            account_id,
            facility_id
        };

        _getTokens(options);
    }
    else {
        redirectToLogin();
    }
};

export const redirectToLogin = () => {
    window.location.replace(window.location.origin);
};