import React from 'react';

const Exclamation = (props) => {

    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 85 131"
            space="preserve">
            <g stroke="null" id="svg_5">
                <path stroke="null" id="svg_1" d="m65.32152,19.0447a13.30159,9.77132 0 1 0 -9.40568,-11.96737a13.30173,9.77142 0 0 0 9.40568,11.96737z" />
                <path stroke="null" id="svg_2" d="m36.79291,59.50429l-21.28594,58.35703c-3.87111,10.61289 12.84927,17.57088 34.88944,9.37686c10.58374,-3.93484 25.96837,-13.77691 34.66734,-22.68135c0,0 -43.82954,18.27859 -36.5233,-1.75167l21.286,-58.35705c3.87111,-10.61289 -12.84927,-17.57088 -34.88956,-9.37674c-10.58363,3.93478 -25.96837,13.77693 -34.66723,22.68125c0,0 43.82954,-18.27859 36.52324,1.75167z" />
            </g>
        </svg>
    )
}


export default Exclamation;