import { useState } from 'react';
import { generateUniqueKey } from '../../utils';
import { s_p } from '../../utils/v1.1';
import { SQL_FORMULA_TEMPLETE } from './shared.fun';


export const useFormula = () => {

    const [formulas, setFormulas] = useState({
        if: [

        ],
        else_ifs: [
            []
        ],
        else: [],
    })

    // const [formulas, setFormulas] = useState({
    //     "if": [
    //         {
    //             "expr": "110",
    //             "type": "plain_text",
    //             "id": "fm_ld7e70ould7e942m",
    //             "inner_formula": {
    //                 "if": []
    //             }
    //         },
    //         {
    //             "expr": "+",
    //             "type": "mathematical",
    //             "id": "fm_ld7e74hrld7e8jyc",
    //             "inner_formula": {}
    //         },
    //         {
    //             "expr": "120",
    //             "type": "plain_text",
    //             "id": "fm_ld7e75j7ld7e7oj5",
    //             "inner_formula": {}
    //         },
    //         {
    //             "expr": "=",
    //             "type": "conditional",
    //             "id": "fm_ld7e79v6ld7e8y8c",
    //             "inner_formula": {}
    //         },
    //         {
    //             "expr": "100",
    //             "type": "plain_text",
    //             "id": "fm_ld7e7b94ld7e7ln5",
    //             "inner_formula": {}
    //         },
    //         {
    //             "expr": "Then",
    //             "type": "then",
    //             "id": "fm_ld7e7cqpld7e84uc",
    //             "inner_formula": {}
    //         },
    //         {
    //             "expr": "Nested If",
    //             "type": "wrapper",
    //             "id": "fm_ld7kcub8ld7kdvxh",
    //             "inner_formula": {
    //                 "if": [
    //                     {
    //                         "expr": "120",
    //                         "type": "plain_text",
    //                         "id": "fm_ld7kcvxbld7kd1ic"
    //                     },
    //                     {
    //                         "expr": "+",
    //                         "type": "mathematical",
    //                         "id": "fm_ld7kcx8ild7kesg4"
    //                     },
    //                     {
    //                         "expr": "200",
    //                         "type": "plain_text",
    //                         "id": "fm_ld7kcyatld7keg69"
    //                     },
    //                     {
    //                         "expr": "=",
    //                         "type": "conditional",
    //                         "id": "fm_ld7kd001ld7ketfd"
    //                     },
    //                     {
    //                         "expr": "100",
    //                         "type": "plain_text",
    //                         "id": "fm_ld7kd185ld7keoos"
    //                     },
    //                     {
    //                         "expr": "Then",
    //                         "type": "then",
    //                         "id": "fm_ld7kd2dwld7ke2ob"
    //                     },
    //                     {
    //                         "expr": "HELLO",
    //                         "type": "plain_text",
    //                         "id": "fm_ld7kd4fild7keakm"
    //                     }
    //                 ],
    //                 "else": [
    //                     {
    //                         "expr": "1000",
    //                         "type": "plain_text",
    //                         "id": "fm_ld7kd6awld7kebv6"
    //                     }
    //                 ],
    //                 "else_ifs": [
    //                     []
    //                 ]
    //             }
    //         }
    //     ],
    //     "else_ifs": [
    //         []
    //     ],
    //     "else": []
    // })



    const updateFormula = (parent_id, type, formula, __id) => {


        const clone_formulas = formulas ? s_p(formulas) : {};

        const find_and_update_object = (current_object) => {

            Object.keys(current_object).map((inner_type) => {

                if (current_object[inner_type]) {

                    current_object[inner_type].map((chunk_object) => {
                        // it is for else if
                        if (chunk_object && Array.isArray(chunk_object) && chunk_object.length > 0 && type === "else_ifs") {
                            chunk_object.map((c) => {
                                if (c.id === __id) {
                                    (Object.keys(formula) || []).forEach(k => {
                                        if (!c[k]) c[k] = '';
                                        c[k] = formula[k]
                                    })
                                }
                            })

                        }

                        if (chunk_object.type === "wrapper") {

                            if (!chunk_object["inner_formula"]) chunk_object["inner_formula"] = {}; // if we did't get that then let's add

                        }

                        if (chunk_object.id === __id || chunk_object.id === formula?.id) {

                            console.log("meal gaya", chunk_object, formula)

                            // let's call update function
                            if (chunk_object.type === "wrapper") {

                                if (!chunk_object?.["inner_formula"]) chunk_object["inner_formula"] = {};

                                if (!chunk_object["inner_formula"][type]) chunk_object["inner_formula"][type] = [];

                            }
                            (Object.keys(formula) || []).forEach(k => {
                                if (!chunk_object[k]) chunk_object[k] = '';
                                chunk_object[k] = formula[k]
                            })
                        }

                        else if (chunk_object["inner_formula"]?.["if"]?.length > 0) {

                            find_and_update_object(chunk_object["inner_formula"])
                        }
                    })
                }
            })
        }

        // console.log("clone_formulas", clone_formulas)
        find_and_update_object(clone_formulas)
        setFormulas(clone_formulas)
    }


    const deleteFormula = (type, formula, index) => {


    }

    const deleteFormulaRow = (parent_id, type, index, else_if_index) => {
    
        const clone_formulas = formulas ? s_p(formulas) : {};
    
        // Recursive function to find and update the target object
        const find_and_update_object = (current_object) => {
            Object.keys(current_object).map((inner_type) => {
                if (current_object[inner_type]) {
                    current_object[inner_type].map((chunk_object) => {
                        // Handle 'wrapper' type by ensuring 'inner_formula' exists
                        if (chunk_object.type === "wrapper") {
                            if (!chunk_object["inner_formula"]) chunk_object["inner_formula"] = {};
                        }
    
                        // Update the target chunk if the ID matches
                        if (chunk_object.id === parent_id) {
                            if (index > -1 && chunk_object?.["inner_formula"]?.[type]?.length > 0) {
                                var start_index = null;
                                var end_index = index;
    
                                const pre_parts = chunk_object?.["inner_formula"]?.[type].slice(0, index);
    
                                // Find the start index of the segment to delete
                                for (let j = pre_parts.length - 1; j >= 0; j--) {
                                    if (pre_parts[j].type === "and_or_operator") {
                                        if (!start_index || start_index <= 1) start_index = (j + 1);
                                        break;
                                    }
                                }
    
                                // Remove the specified row from the array
                                var new_active_formula_block = chunk_object?.["inner_formula"]?.[type]
                                    .slice(0, (start_index || 0))
                                    .concat(chunk_object?.["inner_formula"]?.[type].slice(end_index + 1));
                                chunk_object["inner_formula"][type] = new_active_formula_block;
                            }
                        } else if (chunk_object["inner_formula"]) {
                            find_and_update_object(chunk_object["inner_formula"]);
                        }
                    });
                }
            });
        };
    
        // If parent_id is provided, update the corresponding object
        if (parent_id) {
            find_and_update_object(clone_formulas);
        } else {
            // Update the main formula object
            if (index > -1 && clone_formulas?.[type]?.length > 0) {
                var start_index = null;
                var end_index = index;
                var clone_typed_data = else_if_index > -1 ? clone_formulas?.[type]?.[else_if_index] : clone_formulas?.[type];
    
                const pre_parts = clone_typed_data.slice(0, index);
    
                // Find the start index of the segment to delete
                for (let j = pre_parts.length - 1; j >= 0; j--) {
                    if (pre_parts[j].type === "and_or_operator") {
                        if (!start_index || start_index <= 1) start_index = (j + 1);
                        break;
                    }
                }
    
                // Check for 'then' element in the slice
                const slice_formula = clone_typed_data?.slice(start_index, end_index);
                const then_element = slice_formula?.find((f) => f.type === "then" || f.type == "Then");
                const then_element_index = clone_typed_data?.findIndex((f) => f.id === then_element?.id);
    
                // Adjust indices if a 'then' element is found
                if (then_element_index > -1 && then_element && start_index > 0) {
                    const is_or_and_element = clone_typed_data?.[start_index - 1];
                    if (is_or_and_element) {
                        start_index = start_index - 1;
                        end_index = then_element_index - 1;
                    }
                }
    
                var new_active_formula_block;
                if (else_if_index > -1) {
                    const current = clone_typed_data
                        .slice(0, (start_index || 0))
                        .concat(clone_typed_data.slice(end_index + 1));
                    const all_else_if = clone_formulas[type];
                    all_else_if[else_if_index] = current;
                    new_active_formula_block = all_else_if;
                } else {
                    new_active_formula_block = clone_formulas?.[type]
                        .slice(0, (start_index || 0))
                        .concat(clone_formulas?.[type].slice(end_index + 1));
                }
    
                // Update the formula block in the clone
                clone_formulas[type] = new_active_formula_block;
            }
        }
    
        // Update the state with the modified formulas
        setFormulas(clone_formulas);
    };



    const deleteFormulaBlock = (parent_id, else_if_index, type = "else_ifs") => {

        const clone_formulas = formulas ? { ...formulas } : {};


        if (clone_formulas[type] && else_if_index > -1) {

            clone_formulas[type].splice(else_if_index, 1)
            if (clone_formulas[type]?.length === 0) {
                clone_formulas[type].push([])
            }
        }


        const find_and_update_object = (current_object) => {

            Object.keys(current_object).map((inner_type) => {

                if (current_object[inner_type]) {

                    let found = -1
                    current_object[inner_type]?.length > 0 && current_object[inner_type]?.map((chunk_object, i) => {

                        if (!chunk_object["inner_formula"]) chunk_object["inner_formula"] = {}; // if we did't get that then let's add

                        if (chunk_object.id === parent_id) {
                            found = i

                        }
                        else if (chunk_object["inner_formula"]) {
                            find_and_update_object(chunk_object["inner_formula"])
                        }
                    })

                    found > -1 && current_object[inner_type].splice(found, 1)
                }
            })
        }

        parent_id && find_and_update_object(clone_formulas)

        setFormulas(clone_formulas);

    }



    const addElementAtSpecificIndex = (array, element, index) => {

        if (index > -1) {
            array.splice(index, 0, element);
            // let's add element at specific index
        } else {
            if (element.type === "wrapper") {

                const temp_element = element ? { ...element } : {};
                temp_element["inner_formula"] = {
                    "if": [],
                    "else": [],
                    "else_ifs": [[]]
                }
                array.push(temp_element)

            } else {
                array.push(element)

            }
        }
    }




    const addFormula = (parent_id, type, formula, else_if_index, prev_element_index, is_replace_existing_element, db_type = "mysql") => {
        addFormula_v1(parent_id, type, formula, else_if_index, prev_element_index, is_replace_existing_element, db_type)

    }

    const addFormula_v1 = (parent_id, type, formula, else_if_index, prev_element_index, is_replace_existing_element, db_type) => {

        var clone_formulas = formulas ? JSON.parse(JSON.stringify(formulas)) : {};

        const new_formula = formula ? { ...formula } : {};

        new_formula.id = generateUniqueKey("fm") //this is mandatory;
        new_formula.condition_type = type;

        if (else_if_index) new_formula.array_index = else_if_index;

        if (parent_id) {
            // let's call recursive to update that object 
            const find_and_update_object = (current_object) => {
                Object.keys(current_object).map((inner_type) => {

                    if (current_object[inner_type]) {
                        current_object[inner_type].map((chunk_object) => {

                            if (!chunk_object["inner_formula"]) chunk_object["inner_formula"] = {}; // if we did't get that then let's add

                            if (chunk_object.id === parent_id) {
                                // let's call update function 

                                if (!chunk_object?.["inner_formula"]) chunk_object["inner_formula"] = {};

                                if (!chunk_object["inner_formula"][type]) chunk_object["inner_formula"][type] = [];

                                // let's find 
                                const cloned_specific_type_formula = chunk_object["inner_formula"][type] ? [...chunk_object["inner_formula"][type]] : [];

                                // do else if add column
                                if (type === "else_ifs") {

                                    let cloned_specific_type_formula_of_else_if = chunk_object?.["inner_formula"][type][else_if_index] ? [...chunk_object["inner_formula"][type][else_if_index]] : [];

                                    addElementAtSpecificIndex(cloned_specific_type_formula_of_else_if, new_formula, (prev_element_index > -1 ? prev_element_index + 1 : -1))

                                    chunk_object["inner_formula"][type][else_if_index] = cloned_specific_type_formula_of_else_if;

                                    const is_then_exist = (chunk_object["inner_formula"]?.[type]?.[chunk_object?.["inner_formula"]?.[type]?.length - 1] || []).some((a) => a.type === "Then" || a.type === "then")
                                    if (is_then_exist) chunk_object["inner_formula"][type].push([])

                                } else {

                                    addElementAtSpecificIndex(cloned_specific_type_formula, new_formula, (prev_element_index > -1 ? prev_element_index + 1 : -1))
                                    chunk_object["inner_formula"][type] = cloned_specific_type_formula;

                                    console.log("chunk_object", chunk_object)
                                }
                            }
                            else if (chunk_object["inner_formula"]) {
                                find_and_update_object(chunk_object["inner_formula"])
                            }
                        })
                    }
                })
            }
            parent_id && find_and_update_object(clone_formulas)


        } else {

            if (!clone_formulas[type]) clone_formulas[type] = [];


            // do else if add column
            if (type === "else_ifs") {

                const cloned_specific_type_formula_of_else_if = clone_formulas[type][else_if_index] ? [...clone_formulas[type][else_if_index]] : [];

                addElementAtSpecificIndex(cloned_specific_type_formula_of_else_if, new_formula, (prev_element_index > -1 ? prev_element_index + 1 : -1))
                clone_formulas[type][else_if_index] = cloned_specific_type_formula_of_else_if;
                const is_then_exist = (clone_formulas?.[type]?.[clone_formulas?.[type]?.length - 1] || []).some((a) => a.type === "Then" || a.type === "then")
                if (is_then_exist) clone_formulas[type].push([])

            } else {

                const cloned_specific_type_formula = clone_formulas[type] ? [...clone_formulas[type]] : [];

                addElementAtSpecificIndex(cloned_specific_type_formula, new_formula, (prev_element_index > -1 ? prev_element_index + 1 : -1))
                clone_formulas[type] = cloned_specific_type_formula;
                // console.log("cloned_specific_type_formula", cloned_specific_type_formula)

            }
        }

        if (formula?.type === "db_function") {

            const inputs = SQL_FORMULA_TEMPLETE?.[db_type]?.[formula?.expr];

            if (type === "else_ifs") {

                const cloned_specific_type_formula_of_else_if = clone_formulas[type][else_if_index] ? [...clone_formulas[type][else_if_index]] : [];
                var t_pre_index = prev_element_index > -1 ? prev_element_index + 1 : -1;

                inputs.forEach((fm) => {
                    const new_formula_v1 = {
                        ...fm,
                        id: generateUniqueKey("fm"),
                        sql_fun_id: new_formula?.id,
                        is_sql_fun_info: true,
                        condition_type: type
                    }
                    addElementAtSpecificIndex(cloned_specific_type_formula_of_else_if, new_formula_v1, (t_pre_index > -1 ? t_pre_index + 1 : -1));
                    if (prev_element_index > -1) {
                        t_pre_index += 1;
                    }
                })

                clone_formulas[type][else_if_index] = cloned_specific_type_formula_of_else_if;

                const is_then_exist = (clone_formulas?.[type]?.[clone_formulas?.[type]?.length - 1] || []).some((a) => a.type === "Then" || a.type === "then")

                if (is_then_exist) clone_formulas[type].push([]);


            }

            else {

                const cloned_specific_type_formula = clone_formulas[type] ? [...clone_formulas[type]] : [];
                var t_pre_index = prev_element_index > -1 ? (prev_element_index + 1) : -1;

                inputs.forEach((fm) => {

                    console.log("t_pre_index", t_pre_index, fm)
                    const new_formula_v1 = {
                        ...fm,
                        id: generateUniqueKey("fm"),
                        sql_fun_id: new_formula?.id,
                        is_sql_fun_info: true,
                        condition_type: type
                    }
                    addElementAtSpecificIndex(cloned_specific_type_formula, new_formula_v1, (t_pre_index > -1 ? (t_pre_index + 1) : -1));
                    if (prev_element_index > -1) {
                        t_pre_index += 1;
                    }
                })

                clone_formulas[type] = cloned_specific_type_formula;
            }
        }
        console.log("clone_formulas", clone_formulas)
        setFormulas(clone_formulas);
    }



    return {
        formulas,
        updateFormula,
        deleteFormula,
        addFormula,
        deleteFormulaRow,
        deleteFormulaBlock,
        setFormulas,
    }
}