import React, { useState } from 'react'
import styled from 'styled-components';
import RippleEffect from '../hoc/ripple-effect';
import SearchSvg from '../svg/search';
import GridView from '../svg/grid.view';
import ListView from '../svg/list.view';
import TextBoxV1 from '../hoc/text.box.v1';
import AddSvg from '../svg/add'

const Wrapper = styled.div`

    width: 100%;
    display: flex;
    align-items: center;

  
    .text_box_wrapper{
        width: 100%;
        position: relative;

        span{
            position: absolute;
            left: .5rem;
            top: .6rem;
        }

        input{
            width: 100%;
            height: 2.3rem;
            border: 1.5px solid #ccc;
            border-radius: 3px;
            padding: 1rem;
            box-sizing: border-box;
            padding-left: 1.8rem;
            font-size: .9rem;
            &:-webkit-placeholder{
                color: #ccc;
            }
            &:focus{

            }
        }
    }
    

`;


// const AddButton = styled.div`
//     width: 2.2rem;
//     height: 2.2rem;
//     font-size: 2rem;
//     color: #fff;
//     border-radius: 50%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     background-color: #008AC7;
//     cursor: pointer;
//     box-shadow: 2px 3px 4px #cfc0c0;
//     svg{
//         fill: #fff;
//     }
// `;

const DashboardListHeader = (props) => {


    const { toggle_view_mode, on_search, view_mode, addNewAction } = props;
    const [is_search_open, set_is_seach_open] = useState(undefined);


    return (
        <Wrapper>
            <div style={{ marginRight: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ width: '95%', boxSizing: 'border-box', }}>
                    <div className='text_box_wrapper'>
                        <span>
                            <SearchSvg size={'1rem'} height={'1rem'} color="#ccc" />
                        </span>
                        <input
                            type="text"
                            placeholder="Search Dashboard"
                            onChange={(e) => {
                                on_search(e)
                            }}
                        />
                    </div>
                </div>
            </div>

            <div>
                <RippleEffect
                    icon={view_mode === 'list' ? <GridView size="1rem" height="1rem" /> : <ListView size="1rem" height="1rem" />}
                    Click={toggle_view_mode}
                    tooltip="Toggle view"
                />
            </div>
            {/* <div style={{ marginLeft: '10px' }}>
                <AddButton title="Create New Report" onClick={() => {
                    addNewAction()
                }}>
                    <AddSvg size="1rem" height="1rem" color="#fff" />
                </AddButton>
            </div> */}

        </Wrapper>
    )
}

export default DashboardListHeader;