import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import TextBox from '../hoc/textBox'
import { getDistinctDataForColumn } from '../../actions/report';
import { connect } from 'react-redux';
import SuggestionListHoc from '../hoc/suggestionListHoc';
import { calculatePositionForSuggestionBox } from '../../utils/calculatePositionAtCursor';
// import { calculatePositionForSuggestionBox } from '../../utils/calculatePositionForSuggestionBox';



export const AutoComplete = styled.div`

    position: absolute;
    top: 100%;
    left: -2px;
    max-height: 8rem;
    overflow-y: auto;
    box-sizing: border-box;
    margin-top: 1px;
    z-index: 2;


    &::-webkit-scrollbar {
        background-color:red;
        width:2px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 2px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 2px;
    }

    .word{
        box-sizing: border-box;
        font-size: .8rem;
        color: #555;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        /* &:hover{
            background-color: #eeeeee;
            color: #222;
            cursor: pointer;
        } */

        span{
            display: inline-block;
            padding: ${props => props.gap ? "7px 1.5rem" : '7px 8px'};
        }
    }

    .prev_word{
        opacity: 0;
        padding: 0px !important;
    }
    .current_word{
        background-color: #ffffff;
        cursor: pointer;
        &:hover{
            background-color: #eeeeee;
            color: #222;
            cursor: pointer;
        }
        
    }
    .selected{
        background: #eeeeee;
        color: #222;
    }

`;

const Wrapper = styled.div`
    width: 100%;
    position: relative;
`;


let current_index = undefined;

const _priority = {
    ONE: 'normal',
    TWO: 'column_value',
    THREE: 'parameters_fields'
}

const Criteria = (props) => {

    const { hints, distinct_data, db_info_id, setCriteria, criteria, parameters_fields, schema_table_info } = props;

    const suggestionBoxRef = useRef(null);
    const [priority, setPriority] = useState(_priority.ONE)
    const [inputValue, setInputValue] = useState('')
    const [autoSuggestList, setAutoSuggestList] = useState(undefined)

    const [currentTableName, setCurrentTableName] = useState(undefined)

    const [hintOpen, setHintsOpen] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [suggestionBoxPosition, setSuggestionBoxPosition] = useState({ top: 30, left: 0 });
    const [currCursorPosition, setCurrCursorPosition] = useState(undefined);

    const inputRef = useRef(null)
    const wrapperRef = useRef(null);

    // default hints 
    useEffect(() => {
        setAutoSuggestList(hints)
    }, []);

    useEffect(() => {
        setInputValue(criteria)
    }, [criteria])


    const set_hints_by_priority = (p) => {
        if (p === _priority.ONE) {
            setAutoSuggestList(hints)
        }
        if (p === _priority.TWO) {
            const temp_data = [];
            distinct_data && currentTableName && distinct_data[currentTableName] && distinct_data[currentTableName].length > 0 && distinct_data[currentTableName].map((d) => {
                const v = Object.values(d)[0]
                temp_data.push(v)
            })
            setAutoSuggestList(temp_data)
        }
        if (p === _priority.THREE) {
            setAutoSuggestList(parameters_fields)
        }
    }



    // this function will return the schema name for the pirtucal table
    const get_schema_name_by_table_name = (table_name) => {
        // schema_table_info
        const schema_keys = schema_table_info && Object.keys(schema_table_info);
        for (let index = 0; index < schema_keys.length; index++) {
            const key = schema_keys[index];
            const table_lists = schema_table_info && schema_table_info[key];
            const table_names = table_lists && Object.keys(table_lists);
            if (table_names.indexOf(table_name) > -1) return key;
        }

    }


    const get_hints_by_value = (v) => {

        const value = v && v.toString();

        if (value && value.length > 0) {
            const splitted_value = value && value.split(' ')
            const exist_tbl = [];

            if (splitted_value && splitted_value.length > 0) {
                splitted_value.map((w) => { if (w && w.indexOf('.') > -1) exist_tbl.push(w) })
            }
            if (exist_tbl && exist_tbl.length > 0) {
                const last_tbl = exist_tbl[exist_tbl.length - 1];
                if (typeof currentTableName === 'undefined' || currentTableName !== last_tbl) {
                    const tbl_name = last_tbl && last_tbl.split('.')[0];
                    const column_name = last_tbl && last_tbl.split('.')[1];
                    // call api here 
                    setCurrentTableName(last_tbl)
                    const schema_name = last_tbl && get_schema_name_by_table_name(last_tbl);
                    const db_id = schema_name ? schema_name : db_info_id && db_info_id.length > 0 && db_info_id[0]
                    // props.getDistinctDataForColumn(db_id, tbl_name, column_name)
                }
            }
        }

    }

    const _onChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        let __p__ = _priority.ONE;
        currCursorPosition && setCurrCursorPosition(undefined)

        var startPos = inputRef.current.selectionStart;
        const temp_value = value.substring(0, startPos)
        const splitted_value = temp_value && temp_value.split(' ');
        if (splitted_value &&
            (
                (splitted_value[splitted_value.length - 2] === '@') ||
                (splitted_value[splitted_value.length - 1] === '@')
            )
        ) {

            setPriority(_priority.THREE)
            set_hints_by_priority(_priority.THREE)
            __p__ = _priority.THREE;
        }

        else if (splitted_value &&
            (
                (splitted_value[splitted_value.length - 2] === 'is') ||
                (splitted_value[splitted_value.length - 2] === 'are') ||
                (splitted_value[splitted_value.length - 1] === 'is') ||
                (splitted_value[splitted_value.length - 1] === 'are') ||
                (splitted_value[splitted_value.length - 2] === 'contain') ||
                (splitted_value[splitted_value.length - 1] === 'contain')
            )
        ) {

            setPriority(_priority.TWO)
            set_hints_by_priority(_priority.TWO)
            __p__ = _priority.TWO;
        }

        else {
            setPriority(_priority.ONE)
            set_hints_by_priority(_priority.ONE)
            __p__ = _priority.ONE;

        }

        if (__p__) {
            const current_value = splitted_value[splitted_value.length - 1];
            const column_value_hints = [];
            distinct_data && distinct_data[currentTableName] && distinct_data[currentTableName].length > 0 && distinct_data[currentTableName].map((d) => {
                const v = Object.values(d)[0]
                const _v = (typeof v == 'string') ? v.toLowerCase() : (typeof v === 'number') ? v.toString() : v
                column_value_hints.push(_v)
            })

            const hints_for_filter = (__p__ === _priority.ONE) ? hints : column_value_hints;
            const filterd_hints = hints_for_filter && hints_for_filter.length > 0 && hints_for_filter.filter(hint => {
                if (hint) {
                    if (current_value === '') return hint;
                    else if (hint && current_value && hint.length > 0 && hint.toLowerCase().indexOf(current_value.toLowerCase()) > -1) return hint;
                    else return false;
                } else return false
            })
            const coordinates = calculatePositionForSuggestionBox(startPos, inputRef, wrapperRef, 'nowrap');
            setSuggestionBoxPosition({left: coordinates.left})
            setAutoSuggestList(filterd_hints)
            if (__p__ === _priority.THREE) {
                setAutoSuggestList(parameters_fields)
            }
        }
    }

    // const find_biggest_word_in_hints = (hints) => {
    //     let len = 0;
    //     hints && hints.length > 0 && hints.forEach((h) => {
    //         if (h && h.length > len) {
    //             len = h.length;
    //         }
    //     })
    //     return len;
    // }

    // const w_l = find_biggest_word_in_hints(autoSuggestList)


    // const is_letter_upper_case = (ch) => {
    //     if (!isNaN(ch * 1)) {
    //         return false
    //     }
    //     else {
    //         if (ch === ch.toUpperCase()) {
    //             return true
    //         }
    //         if (ch === ch.toLowerCase()) {
    //             return false
    //         }
    //     }
    // }

    const handleSuggestionClick = (word) =>{
        var startPos = inputRef?.current?.selectionStart;
        // var endPos = inputRef?.current?.selectionEnd;
        // const end_v = inputValue?.substring(startPos)
        const start_v = inputValue?.substring(0, currCursorPosition || startPos)
        let splitted_value = start_v && start_v.split(' ');
        splitted_value.pop();   
        !currCursorPosition && setCurrCursorPosition(splitted_value.join(" ")?.length+1);
        let last_value = splitted_value && splitted_value[splitted_value.length - 1];
        // let full_str_value = '';
        // if (splitted_value && splitted_value.indexOf(last_value) > -1 && (last_value && last_value.trim() !== '@')) {
        //     let _index = splitted_value?.indexOf(last_value);
        //     splitted_value.splice(_index, 1)
        // }

        // if (last_value && last_value?.trim() === '@') {
        //     full_str_value = (splitted_value && splitted_value.length > 0) ? splitted_value.join(' ') + word + end_v : word + end_v;
        //     // full_str_value = (splitted_value && splitted_value.length > 0) ? splitted_value.join(' ') +" " + word + end_v : word + end_v;
        // } else {
        //     full_str_value = (splitted_value && splitted_value.length > 0) ? splitted_value.join(' ') + ' ' + word + end_v : word + end_v;
        // }
        if (word && (last_value === word.split(' ')[0] || word?.includes(last_value)) ) {
            splitted_value.pop();
        }

        // const wordToConcate = is_value_container_value ? ("'" +newWord +"'"): newWord;
        const newValue = [...splitted_value, word].join(' ') + inputRef?.current?.value.slice(startPos);
        const newCursorPosition = splitted_value.join(' ')?.length + word?.length + 1;
 
        setInputValue(newValue)
        get_hints_by_value(word)
        // const newCursorPosition = splitted_value.join(' ')?.length + word?.length+1;

        if (inputRef?.current) {
            setTimeout(() => {
                if(!inputRef?.current?.value?.slice(startPos)?.trim()){
                    inputRef.current.scrollLeft = Math.max(0, inputRef.current.scrollWidth - inputRef.current.clientWidth);
                }else{
                    // inputRef.current.focus();
                    inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
                }
            }, 0)
        }
        // setHintsOpen(undefined);
    };

    const handleKeyDown = (e) => {
        if (autoSuggestList?.length === 0) return;

        if (e.key === 'ArrowDown') {
            e.preventDefault();
            handleSuggestionClick(autoSuggestList[selectedIndex < autoSuggestList.length - 1 ? selectedIndex + 1 : 0]);
            setSelectedIndex((prevIndex) =>
                prevIndex < autoSuggestList.length - 1 ? prevIndex + 1 : 0
            );
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            handleSuggestionClick(autoSuggestList[selectedIndex > 0 ? selectedIndex - 1 : autoSuggestList?.length - 1]);
            setSelectedIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : autoSuggestList.length - 1
            );
        } 
        // else if (e.key === 'Enter') {
        //     if (selectedIndex >= 0) {
        //         e.preventDefault();
        //         handleSuggestionClick(autoSuggestList[selectedIndex]);
        //         setSelectedIndex(0);
        //     }
        // }
    };

    return (
        <Wrapper ref={wrapperRef}>
            <TextBox
                refName={inputRef}
                label="Criteria"
                value={inputValue}
                labelStyle={{
                    fontSize: '1rem',
                    color: '#374a66',
                    textTransform: 'capitalize',
                    fontWeight: 600,
                }}
                inputStyle={{
                    fontSize: '.8rem',
                    color: '#374a66',
                    // textTransform: 'capitalize',
                }}
                onChange={(event) => {
                    _onChange(event)
                    setHintsOpen(true);
                    currCursorPosition && setCurrCursorPosition(undefined)
                    selectedIndex !== -1 && setSelectedIndex(-1);
                }}
                onKeyDown={(event) => {
                    handleKeyDown(event)
                    // const value = event.target.value;
                    // const keyCode = event.keyCode;

                    // if (keyCode === 32) {
                    //     var startPos = inputRef.current.selectionStart;
                    //     const temp_v = value.substring(0, startPos)
                    //     get_hints_by_value(temp_v)
                    //     current_index = undefined
                    // }

                    // let total_suggested_items_length = autoSuggestList && autoSuggestList.length;

                    // // page down
                    // if (keyCode === 40) {

                    //     let temp_index = (current_index == 0 || current_index) ? current_index + 1 : 0;
                    //     current_index = temp_index;
                    //     if (total_suggested_items_length === temp_index) {
                    //         current_index = 0;
                    //         temp_index = 0;
                    //     }
                    //     let current_item = autoSuggestList && autoSuggestList[temp_index]
                    //     var startPos = inputRef.current.selectionStart;
                    //     const end_v = inputValue.substring(startPos)
                    //     const start_v = inputValue.substring(0, startPos)
                    //     let splitted_value = start_v && start_v.split(' ');
                    //     let last_value = splitted_value && splitted_value[splitted_value.length - 1];

                    //     if (splitted_value && splitted_value.indexOf(last_value) > -1) {
                    //         let _index = splitted_value.indexOf(last_value);
                    //         splitted_value.splice(_index, 1)
                    //     }
                    //     const full_str_value = (splitted_value && splitted_value.length > 0) ? splitted_value.join(' ') + ' ' + current_item + end_v : current_item + end_v;
                    //     setInputValue(full_str_value)
                    //     get_hints_by_value(current_item)
                    // }

                    // // page up 
                    // if (keyCode === 38) {
                    //     let temp_index = current_index - 1;
                    //     if (current_index <= 0) {
                    //         temp_index = total_suggested_items_length
                    //     }
                    //     current_index = temp_index;

                    //     let current_item = autoSuggestList && autoSuggestList[temp_index]
                    //     if (current_item) {
                    //         var startPos = inputRef.current.selectionStart;
                    //         var endPos = inputRef.current.selectionEnd;
                    //         const end_v = inputValue.substring(startPos)
                    //         const start_v = inputValue.substring(0, startPos)
                    //         let splitted_value = start_v && start_v.split(' ');
                    //         let last_value = splitted_value && splitted_value[splitted_value.length - 1];

                    //         if (splitted_value && splitted_value.indexOf(last_value) > -1) {
                    //             let _index = splitted_value.indexOf(last_value);
                    //             splitted_value.splice(_index, 1)
                    //         }
                    //         const full_str_value = (splitted_value && splitted_value.length > 0) ? splitted_value.join(' ') + ' ' + current_item + end_v : current_item + end_v;
                    //         setInputValue(full_str_value)
                    //         get_hints_by_value(current_item)
                    //     }
                    // }

                }}
                onFocus={() => {
                    setHintsOpen(true);
                }}
                onBlur={() => {
                    setCriteria(inputValue)
                    setTimeout(() => {
                        setHintsOpen(false);
                    }, 500)
                }}
            />
           {hintOpen && autoSuggestList && autoSuggestList.length > 0 &&
                <SuggestionListHoc suggestionList={autoSuggestList} selectedIndex={selectedIndex} position={suggestionBoxPosition} handleSuggestionClick={handleSuggestionClick} /> 
            }

        </Wrapper>
    )
}









const mapStateToProps = (state) => ({
    distinct_data: state.reportReducer.distinct_data
})

export default connect(mapStateToProps, { getDistinctDataForColumn })(Criteria)
