import React, { useState } from 'react'
import { startRecord } from '../../utils/voice';
import { find_command_type } from '../../utils';
import styled, { keyframes } from 'styled-components';
import { save_report_items, set_report_to_refresh } from '../../actions/report';
import { connect } from 'react-redux';
import { constants } from '../../utils/constants';



const Wrapper = styled.div`
    width: 400px;
    height: 500px;
    /* box-shadow: -1px 0px 14px 0px rgba(0, 0, 0, 0.75); */
    border-radius: 30px;
    text-align: center;
    overflow: hidden;
    background: white;
    overflow: hidden;
    position: relative;

`;

const TopContent = styled.div`
    height: 320px;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TopBlueCover = styled.div`
    height: 450px;
    width: 500px;
    background: #337DFF;
    border-radius: 100%;
    position: absolute;
    bottom: 180px;
    left: -50px;
`;

const BottomContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 2rem;
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const Icon = styled.img`
    width: 90px;
    position: absolute;
`;


const IconWrapper = styled.span`
    width: 92px;
    height: 92px;
    background: #689AF0;
    border-radius: 90px;
    animation: ${pulse} 1.5s infinite;
`;


const DashboardEditVoiceCommand = (props) => {


    // Destructuring props
    const { popupKey, close, option, data } = props;
    const { reportItem, report_item_id, save_fun, } = props?.data;


    // State for storing current report
    const [current_report, set_current_report] = useState(undefined);
    const [report_modified_properties, set_report_modified_properties] = useState({})



    const [text, setText] = useState('')
    startRecord((text) => {
        const __information_ = find_command_type(text)
        console.log("VOICE TEXT", text, __information_);
        setText(text)

        if (__information_?.type === "chart") {
            onTextChange("chart_type", __information_?.subtype)
        }
        else if (__information_?.type === "data_label") {
            const v = __information_?.subtype === "show" ? true : false;
            onTextChange('show_data_label', v)
        }
        else if (__information_?.type === "tooltip") {
            const v = __information_?.subtype === "show" ? false : true;
            onTextChange('hide_tooltip', v)
        }
        else if (__information_?.type === "legend") {
            const v = __information_?.subtype === "show" ? false : true;
            onTextChange('hide_legend', v)
        }
        else if (__information_?.type === "show_percentage_in_data_labels") {
            const v = __information_?.subtype === "show" ? true : false;
            onTextChange('show_percentage_in_data_labels', v)
        }
    });




    const onTextChange = (key, value) => {

        const local_report_object = Object.assign({}, report_modified_properties);


        if (key === "chart_type") {
            if (value === 'group') {
                local_report_object.chart_type = 'group';
                local_report_object.render_mode = 'group'
                local_report_object.is_group = true;
                local_report_object.question = '';
            }
            else if (value === 'table') {
                local_report_object.chart_type = 'table';
                local_report_object.render_mode = 'table'
                local_report_object.is_group = undefined;

            }
            else {
                local_report_object.chart_type = value;
                local_report_object.render_mode = 'chart'
                local_report_object.is_group = undefined
            }
        };


        local_report_object[key] = value;

        if (key === 'question') {
            local_report_object['isQuestionModified'] = true;
        }

        // set_report_modified_properties(local_report_object);
        // set_current_report({ ...current_report, ...local_report_object });
        local_save_function_(local_report_object)

    }


    const local_save_function_ = (local_report_object) => {
        const clone_report_item = local_report_object ? { ...local_report_object } : {};
        clone_report_item.id = report_item_id;
        clone_report_item.modified = true;
        props.save_report_items([clone_report_item])

        save_fun(report_item_id, clone_report_item)
        if (clone_report_item?.isQuestionModified) {
            setTimeout(() => {
                props.set_report_to_refresh(report_item_id);
            }, 300);
        }
        setTimeout(() => {
            close(option, popupKey)
        }, 200);
    }



    return (
        <Wrapper>

            <TopBlueCover />
            <TopContent>
                <IconWrapper></IconWrapper>
                <Icon src={`${constants.CONTEXT_PATH}/editor/mike_new.png`} />

            </TopContent>
            <BottomContent>
                {text}
            </BottomContent>
        </Wrapper>
    )
}

export default connect(undefined, { save_report_items, set_report_to_refresh })(DashboardEditVoiceCommand)

