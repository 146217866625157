import React, { useState, useEffect, useRef } from 'react';
import { DisplayFiledWrapper, DisplayColumnData, ATozTabWrapper, HoverAction } from './display-field.styled';
import { RadioCheckMark } from '../hoc/radiobox';
import { Button } from '../hoc/button';
import { Gap } from '../gap/index';
import TextBox from '../hoc/textBox'
import RippleEffect from '../hoc/ripple-effect'
import ViewSvg from '../svg/view';
import EditSvg from '../svg/edit';
import DeleteSvg from '../svg/delete'

const SelectDisplayField = (props) => {

    const {

        displayItem,
        title,
        selectedFields,
        setSelectedFields,
        isCheckboxHide,
        hideSearchbox,
        titleOnHover,
        dbType,
        addNewConnection,
        onItemClick,
        viewAction,
        editAction,
        deleteAction
    } = props;

    const [searchValue, setSearchValue] = useState("")
    const [slideValue, setSlideValue] = useState(undefined)
    const [tempSelectedVal, setTempSelectedVal] = useState([]);



    let prevLetter = '';
    let aTozArray = [];

    useEffect(() => {
        if (selectedFields) {
            setTempSelectedVal([...selectedFields])
        }
    }, [selectedFields]);


    console.log("displayItem", displayItem)

    const sorted_display_items = JSON.parse(JSON.stringify(displayItem)).filter((data) => {
        if (!data.connection_name) return false;
        if (searchValue === '') {
            return true;
        }
        else if (data.connection_name.toLowerCase().includes(searchValue.toLowerCase())) {
            return true;
        }
    });

    sorted_display_items.sort(function (a, b) {
        let left = (a.connection_name || "test").trim().toLowerCase();
        let right = (b.connection_name || "testt2").trim().toLowerCase();
        return left.localeCompare(right);
    });





    const _onChangeFunction = (name, id, selectOne) => {
        let tempArray = [...tempSelectedVal];
        if (selectOne) {
            tempArray = [name]
        }
        else {
            if (tempArray.indexOf(name) > -1) {
                tempArray.splice(tempArray.indexOf(name), 1)
            }
            else {
                tempArray.push(name)
            }
        }

        setTempSelectedVal([...tempArray])
    }



    const genCharArray = (charA, charZ) => {
        let a = [];
        let i = charA.charCodeAt(0);
        let j = charZ.charCodeAt(0);

        for (i; i <= j; i++) {
            a.push(String.fromCharCode(i));
        }

        return a
    }

    aTozArray = genCharArray("a", "z");

    const scrollToView = (index, word) => {
        const element = elRefs.current[index];

        if (element && element.current) {
            element.current.scrollIntoView({ block: 'end', behavior: 'smooth', inline: 'start' });
            setSlideValue(word)
        }
    };

    const elRefs = useRef([]);

    elRefs.current = Array(26).fill().map((_, i) => elRefs.current[i] || React.createRef());

    return (
        <DisplayFiledWrapper isCheckboxHide={isCheckboxHide} >
            <div style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                marginBottom: '27px',
                position: 'relative'
            }}>

                <h3>List of Schemas</h3>
                <div style={{ width: '55%', boxSizing: 'border-box', margin: '30px 0px' }}>
                    <TextBox
                        label="Type here to quick search"
                        type="text"
                        placeholder="Type here to quick search"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                </div>

                <ATozTabWrapper >
                    {aTozArray && aTozArray.length > 0 && aTozArray.map((value, index) => {
                        let IsActive = slideValue && slideValue === value;
                        return (
                            <div
                                className="tab_value"
                                style={{ backgroundColor: IsActive ? '#eaeaea' : undefined }}
                                key={index}
                                onClick={() => scrollToView(index, value)}
                            >
                                {value}
                            </div>
                        )
                    })}
                </ATozTabWrapper>
                <div style={{ position: 'absolute', right: '10px' }}> <Button primary={true} onClick={() => addNewConnection(dbType)}>Add New</Button></div>

            </div>



            <DisplayColumnData isCheckboxHide={isCheckboxHide} >
                <ul>
                    {sorted_display_items && sorted_display_items.length > 0 &&
                        sorted_display_items.map((item, index) => {
                            const boo = prevLetter === item.connection_name.trim().substring(0, 1).toLowerCase() ? true : false;
                            prevLetter = item.connection_name.trim().substring(0, 1).toLowerCase();

                            const firstLetter = item.connection_name.trim().substring(0, 1).toLowerCase();
                            let IsActive = slideValue && slideValue === firstLetter;


                            return (
                                <React.Fragment
                                >
                                    {boo === false &&
                                        <li
                                            style={{ color: IsActive ? "#308bc8" : undefined }}
                                            className="sort_title"
                                            ref={elRefs.current[aTozArray.indexOf(firstLetter)]}
                                        >
                                            {item.connection_name.substring(0, 1)}
                                        </li>
                                    }

                                    {!isCheckboxHide ?
                                        <li
                                            className="item"
                                            onClick={() => {
                                                onItemClick(item)
                                                _onChangeFunction(item, index, undefined)
                                            }}
                                        >

                                            {/* <RadioCheckMark
                                                checked={tempSelectedVal && tempSelectedVal.indexOf(item) !== -1 ? true : false} /> */}
                                            <span
                                                className="item_name"

                                            >
                                                {item.connection_name}
                                            </span>

                                            <div className="hover_action">
                                               
                                                <div>
                                                    <RippleEffect
                                                        title="Edit Connection"
                                                        Click={() => {
                                                            editAction(item)
                                                        }}
                                                        icon={<EditSvg size={".7rem"} height={'.7rem'} />}
                                                    />
                                                </div>
                                                <div>
                                                    <RippleEffect
                                                        title='Delete This'
                                                        Click={() => {
                                                            deleteAction(item)
                                                        }}
                                                        icon={<DeleteSvg color="red" size={".7rem"} height={'.7rem'} />}
                                                    />
                                                </div>
                                            </div>


                                        </li>

                                        :

                                        <li className="item" onClick={() => {
                                            onItemClick(item)
                                            _onChangeFunction(item, index, true)
                                        }}>
                                            <span
                                                style={{
                                                    marginLeft: isCheckboxHide ? '0px' : undefined,
                                                }}

                                                className="item_name"
                                            >
                                                {item.connection_name}
                                            </span>
                                        </li>
                                    }
                                </React.Fragment>
                            )
                        })
                    }

                    {!(sorted_display_items && sorted_display_items.length > 0) &&
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                marginTop: '30px'

                            }}
                        >No related fields found</div>
                    }
                </ul>

            </DisplayColumnData>
            <Gap h="1.643rem" />

        </DisplayFiledWrapper>
    )
}
export default SelectDisplayField
