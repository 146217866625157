import React, { useState, useEffect } from 'react';
import { Wrapper } from './chart.filter.styled';
import { Button } from '../hoc/button';
import { Gap } from '../gap'
import { RadioCheckMark } from '../hoc/radiobox';
import { withRouter } from 'react-router-dom';
import { setFilters, clearAllFilter } from '../../actions/filter';
import { connect } from 'react-redux';
import { filter } from 'lodash';
import { getDimensionByEntity, hide_popus_iq, getDimensionByHierarchy } from '../../actions/new-table'
import { addBreadCrumb } from '../../actions/bread-crumb'
import { getDataFromSecondApi } from '../../actions/report';
import { _getDataFromReportingServer, getParameterByName, generate_unique_key, get_data_by_report } from '../../utils';
import { get_dimension_by_hierarchy } from '../../actions/data-hierarchy';

const Index = (props) => {

    const { close, popupKey, option, data } = props;

    const { yDataKeys, xDataKeys, drill_down_report, currentKey, history, id, question } = data;

    const [selectedValue, setSelectedValue] = useState('');
    const [localEntity, selLocalEntity] = useState(undefined);

    const title = (xDataKeys && xDataKeys[0]) + " " + '" ' + currentKey + '" ';

    const getRequireData = () => {

        let _tempXdataKey = xDataKeys[0].toLowerCase();
        let _temp_question = question ? question.replace(_tempXdataKey, selectedValue) : '';
        let final_question = _temp_question;
        let key = xDataKeys ? xDataKeys[0] : [];

        const tempFilters = props.filters ? Object.assign({}, props.filters) : {};

        if (typeof tempFilters[key.toLowerCase()] === 'undefined') {
            tempFilters[key.toLowerCase()] = {}
        }
        else {
            //pass
        }

        tempFilters[key.toLowerCase()] = currentKey;
        const newReportId = generate_unique_key();

        if (tempFilters['reportId']) {
            delete tempFilters['reportId']
        }

        if (selectedValue) {
            props.hide_popus_iq(true);
            props.addBreadCrumb({
                name: _tempXdataKey,
                question: question,
                reportId: getParameterByName('id')
            });
            props.addBreadCrumb({
                name: currentKey,
            });
        }
        return {
            question: _temp_question,
            filters: tempFilters,
            newReportId: newReportId,
            full_question: question

        }
    }
    const get_data_from_server = () => {
        get_data_by_report(drill_down_report, xDataKeys, currentKey, history)
    }

    const get_data_form_second_api = () => {

        const { question, filters, full_question } = getRequireData()
        const response_session_id = props.dataFromServer && props.dataFromServer[id] && props.dataFromServer[id].response_session_id
        const __url__ = `/dashboard?question=${full_question}&report_id=${id}&is_details_screen=true&id=${id}&response_session_id=${response_session_id}&details_filters_from_url=${JSON.stringify(filters)}`
        // window.open(__url__, '_blank')
        // props.getDataFromSecondApi(question, filters, id, response_session_id);

    }


    return (
        <Wrapper>
            <div style={{ marginTop: '0px', paddingTop: '0px', width: '100%' }}>
                <div>
                    <h1>{title}</h1>

                </div>
            </div>
            <Gap h="1.643rem" />
            <div style={{ marginTop: 'auto', display: 'flex', flexDirection: 'row' }}>
                <Button
                    title={drill_down_report && drill_down_report.title}
                    primary={true}
                    // width="8rem"
                    mR="1rem"
                    wrap={true}
                    onClick={() => {
                        get_data_from_server();
                        close(option, popupKey)
                    }}>
                    {drill_down_report && drill_down_report.title}
                </Button>

                <Button
                    width="10rem"
                    mR="1rem"
                    bgColor="#d6e0e5"
                    color='#a9a5a5'
                    onClick={() => {
                        get_data_form_second_api()
                        close(option, popupKey)
                    }}>Details</Button>
                <Button
                    width="8rem"
                    bgColor={'#e6e6e6'}
                    onClick={() => {
                        close(option, popupKey)
                    }}>Cancel</Button>
            </div>
        </Wrapper>
    )
};

const mapStateToProps = (state) => {
    return {
        filters: state.filterReducer.filters,
        dimensionByEntity: state.NewTableReducer.dimensionByEntity,
        dimensionByHierarchy: state.dataHierarchy.dimensionByHierarchy,
        dataFromServer: state.reportReducer.dataFromServer
    }
};


export default withRouter(connect(mapStateToProps, { getDimensionByEntity, hide_popus_iq, addBreadCrumb, getDataFromSecondApi, getDimensionByHierarchy })(Index))

