import React, { useState, useEffect } from 'react';
import TitleHeader from '../title-header';
import { EtlDetailsWrapper } from './etl.details.styled';
import { PageInnerWrapper } from '../hoc/styled.hoc';
import { withRouter } from 'react-router-dom'
import { getJobLogInfo } from '../../actions/etl';
import { getParamByName, convert_date_to_dd_mm_yyyy_v1 } from '../../utils';
import { connect } from 'react-redux';
import DropDownSvgDown from '../svg/dropDown';
import DropDownSvgUp from '../svg/dropDownUp'
import RippleEffect from '../hoc/ripple-effect';
import LeftArrow from '../svg/leftArrow';
import LogSvg from '../svg/log';



const remove_fields = [
    'id',
    'job_started_at',
    'job_finished_at',
    'job_id',
    'is_active',
    'created_at',
    'updated_at',
    'modified_at',
    'modified_by'
]



const Index = (props) => {

    const { history, getJobLogInfo, jobLogInfo } = props;
    const { location } = history;

    const [open_collapse, setOpenCollapse] = useState(undefined)

    useEffect(() => {
        let config_id = getParamByName(location.search, 'config_id');
        if (config_id) getJobLogInfo(config_id)
    }, [location])



    useEffect(() => {
        let _page_info = { title: 'JOB HISTORY' }
        props.dispatchHeaderInformation(_page_info)
    }, [])

    const formateObjectKeyValue = (_object) => {

        let keys = Object.keys(_object);
        let result = {};

        for (let i = 0; i < keys.length; i++) {

            // if (keys[i] && _object[keys[i]] !== undefined && _object[keys[i]] !== null && remove_fields.indexOf(keys[i]) === -1) {
            //     let new_key = _object[keys[i]];
            //     let value_key = new_key.substring()
            //     result[new_key] = _object['big_tag_1_value']
            // }

            if (keys[i] === 'big_tag_1_name' && _object[keys[i]] !== undefined && _object[keys[i]] !== null) {
                let new_key = _object[keys[i]];
                result[new_key] = _object['big_tag_1_value']
            }

            if (keys[i] === 'big_tag_2_name' && _object[keys[i]] !== undefined && _object[keys[i]] !== null) {
                let new_key = _object[keys[i]];
                result[new_key] = _object['big_tag_2_value']
            }


            if (keys[i] === 'tag_1_name' && _object[keys[i]] !== undefined && _object[keys[i]] !== null) {
                let new_key = _object[keys[i]];
                result[new_key] = _object['tag_1_value']
            }

            if (keys[i] === 'tag_2_name' && _object[keys[i]] !== undefined && _object[keys[i]] !== null) {
                let new_key = _object[keys[i]];
                result[new_key] = _object['tag_2_value']
            }

            if (keys[i] === 'tag_3_name' && _object[keys[i]] !== undefined && _object[keys[i]] !== null) {
                let new_key = _object[keys[i]];
                result[new_key] = _object['tag_3_value']
            }

            if (keys[i] === 'tag_4_name' && _object[keys[i]] !== undefined && _object[keys[i]] !== null) {
                let new_key = _object[keys[i]];
                result[new_key] = _object['tag_4_value']
            }

            if (keys[i] === 'tag_5_name' && _object[keys[i]] !== undefined && _object[keys[i]] !== null) {
                let new_key = _object[keys[i]];
                result[new_key] = _object['tag_5_value']
            }

            if (keys[i] === 'tag_6_name' && _object[keys[i]] !== undefined && _object[keys[i]] !== null) {
                let new_key = _object[keys[i]];
                result[new_key] = _object['tag_6_value']
            }

            if (keys[i] === 'tag_7_name' && _object[keys[i]] !== undefined && _object[keys[i]] !== null) {
                let new_key = _object[keys[i]];
                result[new_key] = _object['tag_7_value']
            }

            if (keys[i] === 'tag_8_name' && _object[keys[i]] !== undefined && _object[keys[i]] !== null) {
                let new_key = _object[keys[i]];
                result[new_key] = _object['tag_8_value']
            }

            if (keys[i] === 'tag_9_name' && _object[keys[i]] !== undefined && _object[keys[i]] !== null) {
                let new_key = _object[keys[i]];
                result[new_key] = _object['tag_9_value']
            }

            if (keys[i] === 'tag_10_name' && _object[keys[i]] !== undefined && _object[keys[i]] !== null) {
                let new_key = _object[keys[i]];
                result[new_key] = _object['tag_10_value']
            }
        }

        return result;
    }




    return (
        <EtlDetailsWrapper style={{ marginTop: '1rem' }}>



            <PageInnerWrapper scroll={false}>
                <div className="log_table_wrapper">
                    <table className="log_report_table">
                        <thead>
                            <tr>
                                <th>History id</th>
                                <th>Job Type</th>
                                <th>Job Started Time</th>
                                <th>Job End Time</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>

                            {jobLogInfo && jobLogInfo.length > 0 ? jobLogInfo.map((p_job, index) => {
                                let inner_groups = formateObjectKeyValue(p_job)
                                let _active = open_collapse && open_collapse === p_job.id
                                return (
                                    <React.Fragment>
                                        <tr onClick={(e) => {
                                            // e.stopPropagation();
                                            // e.preventDefault()
                                            // if (_active) {
                                            //     setOpenCollapse(undefined);
                                            // }
                                            // else {
                                            //     setOpenCollapse(p_job.id)
                                            // }
                                        }}>
                                            <td>{p_job.id}</td>
                                            <td>{p_job.job_type}</td>
                                            <td>{p_job.job_started_at ? convert_date_to_dd_mm_yyyy_v1(p_job.job_started_at, true) : null}</td>
                                            <td>{p_job.job_finished_at ? convert_date_to_dd_mm_yyyy_v1(p_job.job_finished_at, true) : null}</td>
                                            <td
                                                title={p_job.status && p_job.status === 'partial completed' ? "View Logs" : undefined}
                                                onClick={(e) => {
                                                    if (p_job.status && p_job.status === 'partial completed') {
                                                        let path_name = `tbl_errors?job_id=${p_job.job_id}`
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        props.history.push(path_name)
                                                    }
                                                }}
                                            >
                                                <span class={`status_btn ${p_job.status && (p_job.status === 'active' || p_job.status === 'completed') ? 'active' : 'stopped'}`}>{p_job.status ? p_job.status : "Stopped"}</span>
                                            </td>
                                            {/* <td>
                                                <div className="toggle_btn">
                                                        <RippleEffect
                                                            icon={
                                                                <LogSvg size=".8rem" height=".8rem" color="#308bc8" /> 
                                                            }
                                                            title={'View Logs'}
                                                            Click={(e) => {
                                                                let path_name = `tbl_errors?job_id=${p_job.job_id}`
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                props.history.push(path_name)
                                                            }}
                                                            
                                                        />
                                                    </div>
                                            </td> */}
                                            <td>
                                                <div className="toggle_btn">
                                                    <RippleEffect
                                                        icon={
                                                            _active ? <DropDownSvgUp size=".8rem" height=".8rem" color="#308bc8" /> : <DropDownSvgDown size=".8rem" height=".8rem" color="#308bc8" />
                                                        }
                                                        title={_active ? "collapse" : "expand"}
                                                        Click={(e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault()
                                                            if (_active) {
                                                                setOpenCollapse(undefined);
                                                            }
                                                            else {
                                                                setOpenCollapse(p_job.id)
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                        {_active && (
                                            <tr>
                                                <td colspan='6' style={{ textAlign: 'left' }}>
                                                    <label>Final Report</label>
                                                    <ul>
                                                        {inner_groups && Object.keys(inner_groups).length > 0 && Object.keys(inner_groups).map((key, j) => {
                                                            return (
                                                                <li key={j} ><span>{key}: <strong>{inner_groups[key]}</strong></span></li>
                                                            )
                                                        })}
                                                    </ul>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                )
                            }) : <p style={{ textAlign: 'center', margin: 'auto', marginTop: '5rem', }}>No Log Found...</p>}
                        </tbody>
                    </table>

                </div>
            </PageInnerWrapper>
        </EtlDetailsWrapper>
    )
}




const mapStateToProps = state => ({
    jobLogInfo: state.EtlReducer.jobLogInfo,
})



export default withRouter(connect(mapStateToProps, { getJobLogInfo })(Index));
