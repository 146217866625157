import React, { useState, useRef, useEffect } from 'react';
import * as _ from 'lodash';

import { SelectBox, DropDownMenu } from './select.styled';
import OverLay from '../helper/overlay';
import { constants } from '../../utils/constants';
import AngleDown from '../svg/angleDown';


const Select = props => {

    const {
        onChangeFunc,
        multiple,
        rowIndex,
        type,
        label,
        selections,
        displayKey,
        valueKey,
        activeValue,
        height,
        width,
        padding,
        paddingRight,
        lineHeight,
        dropDownItemAlign,
        menuLineHeight,
        color,
        dropDownHeight,
        border,
        disabled,
        wrapperRef
    } = props;

    const [showMenu, setShowMenu] = useState(false);
    const [dropDownProperty, setDropDownProperty] = useState(undefined);
    
    const mainRef = useRef(null);

    useEffect(() => {
        if(mainRef && mainRef.current && wrapperRef && wrapperRef.current) {
            const current = mainRef.current;
            const _width = current.offsetWidth;
            const _height = current.offsetHeight;
            const _left = current.offsetLeft;
            const scroll_top = wrapperRef.current.scrollTop;
            
            const _top = (current.offsetTop + 115) - (scroll_top > 0 ? scroll_top : 0);

            setDropDownProperty({
                top: _top,
                left: _left,
                width: _width,
            })

        }
    },[mainRef, wrapperRef])


    let nameValue = undefined;

    if (valueKey && displayKey) {
        const activeValueObject = selections && selections.length > 0 && selections.filter(data => data[valueKey] === activeValue);
        nameValue = activeValueObject && activeValueObject.length > 0 ? activeValueObject[0][displayKey] : undefined;
    }


    return (
        <div>
            {
                showMenu &&
                <OverLay zIndex="3" onClick={() => setShowMenu(!showMenu)} />
            }
            <SelectBox ref={mainRef} disabled={disabled} onClick={() => {
                if (!disabled) {
                    setShowMenu(!showMenu)
                }
            }}
                style={{ zIndex: showMenu ? '3' : '2' }}
                isDropdown={showMenu}
                height={height}
                width={width}
                padding={padding}
                paddingRight={paddingRight}
                lineHeight={lineHeight}
                color={color}
                border={border}
            >
                <span className="select_choose" > {nameValue ? _.startCase(_.toLower(nameValue)) : activeValue ? _.startCase(_.toLower(activeValue)) : (label ? label : '---Select---')}
                </span>

                <span className="select_arrow">
                    <AngleDown size=".9rem" height='.9rem' />
                </span>

                {
                    showMenu && 
                    <DropDownMenu
                        // style={{ 
                        //     top: dropDownProperty && dropDownProperty.top + 'px',
                        //     width: dropDownProperty && dropDownProperty.width + 'px',
                        //     left: dropDownProperty && dropDownProperty.left + 'px',
                        //     position: 'fixed',
                        // }}
                        dropDownHeight={dropDownHeight}
                        isDropdown={showMenu}
                        lineHeight={lineHeight}
                        menuLineHeight={menuLineHeight}
                        dropDownItemAlign={dropDownItemAlign}>
                        {
                            selections && selections.length > 0 && selections.map((data, idx) => {
                                const isObject = typeof data === 'object';
                                return (
                                    <div key={idx} className='select_result_sub'
                                        onClick={() => {
                                            if (multiple) {
                                                onChangeFunc(data[valueKey], type, rowIndex);
                                            }
                                            else {
                                                if (typeof data === 'object') {
                                                    onChangeFunc(data[valueKey]);
                                                    setShowMenu(false);
                                                }
                                                else {
                                                    onChangeFunc(data);
                                                    setShowMenu(false);
                                                }
                                            }
                                        }}

                                    >
                                        {
                                            typeof data === 'string' && !isObject &&
                                            _.startCase(_.toLower(data))
                                        }
                                        {
                                            isObject &&
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                padding: '3px 0px',
                                                fontWeight: activeValue === data[displayKey] ? 'bold' : 'normal'
                                            }}
                                            >
                                                {
                                                    data.icon &&
                                                    <img alt={data.icon} src={constants.CONTEXT_PATH + './' + data.icon} width='20' style={{ marginRight: '15px' }} />
                                                }
                                                {
                                                    _.startCase(_.toLower(data[displayKey]))
                                                }
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </DropDownMenu>
                }
            </SelectBox>
        </div>
    );
};

export default Select;