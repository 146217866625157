import { simpleDispatch_get, simpleDispatch_post, dispatchAction } from './helper';
import * as actionTypes from '../action-types/sqoop_configration';
import { constants } from '../utils/constants';



export const  saveTheConfigration = (data) => async dispatch => {
    
    const url = constants.END_POINTS.API + constants.END_POINTS.SQOOP_CONFIGRATION.POINT + constants.END_POINTS.SQOOP_CONFIGRATION.SAVE_THE_CONFIGRATION;

    await simpleDispatch_post(dispatch, url, data, actionTypes.SAVE_SQOOP_CONFIGRATION, undefined, undefined, {message: 'Saved Sqoop Configuration'});
};






export const  getTheConfiguration = () => async dispatch => {
    
    const url = constants.END_POINTS.API + constants.END_POINTS.SQOOP_CONFIGRATION.POINT + constants.END_POINTS.SQOOP_CONFIGRATION.GET_THE_CONFIGRATION;

    await simpleDispatch_get(dispatch, url, actionTypes.GET_THE_CONFIGRATION);
};



export const  getTheConfigurationById = (id) => async dispatch => {
    
    const url = constants.END_POINTS.API + constants.END_POINTS.SQOOP_CONFIGRATION.POINT + constants.END_POINTS.SQOOP_CONFIGRATION.GET_THE_CONFIGRATION_DATA_BY_ID + `?id=${id}`;

    await simpleDispatch_get(dispatch, url, actionTypes.GET_EDITED_CONFIGRATION_DATA);
};




export const  deleteTheConfigurationById = (id) => async dispatch => {
    
    const url = constants.END_POINTS.API + constants.END_POINTS.SQOOP_CONFIGRATION.POINT + constants.END_POINTS.SQOOP_CONFIGRATION.DELETE_THE_CONFIGRATION_DATA_BY_ID + `?id=${id}`;

    await simpleDispatch_get(dispatch, url, actionTypes.DELETE_THE_CONFIGRATION_DATA_BY_ID, undefined, undefined, {message: 'Configuration Deleted Successfully'});
};




export const  clearEditedData = (id) => async dispatch => {

    dispatchAction(dispatch, actionTypes.CLEAR_EDITED_CONFIGRATION_DATA)
};


export const testConnectionOne = (info) => async dispatch => {
    let temp_info = info;
    temp_info.client_id = constants.CLIENT_ID
    let config = {
        "info": temp_info
    }
    dispatchAction(dispatch, actionTypes.RESET_CONNECTION_ONE_SUCCESS)
    const url = constants.END_POINTS.API + constants.END_POINTS.CLIENT_INFO.POINT + constants.END_POINTS.CLIENT_INFO.TEST_CONNECTION;
    await simpleDispatch_post(dispatch, url, config, actionTypes.TEST_CONNECTION_ONE_SUCCESS, undefined, undefined, {message: 'Connected successfully'});
}


export const testConnectionTwo = (info) => async dispatch => {
    let temp_info = info;
    temp_info.client_id = constants.CLIENT_ID
    let config = {
        "info": temp_info
    }
    
    dispatchAction(dispatch, actionTypes.RESET_CONNECTION_TWO_SUCCESS)
    const url = constants.END_POINTS.API + constants.END_POINTS.CLIENT_INFO.POINT + constants.END_POINTS.CLIENT_INFO.TEST_CONNECTION;
    await simpleDispatch_post(dispatch, url, config, actionTypes.TEST_CONNECTION_TWO_SUCCESS, undefined, undefined, {message: 'Connected successfully'});
}









