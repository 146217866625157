import React, { useState, useEffect } from 'react';
import TextBox from '../hoc/textBox';
import { Button } from '../hoc/button';
import styled from 'styled-components';
import { Gap } from '../gap';



const Wrapper = styled.div`
    width: 30rem;
    padding: 1rem;
    border: 3px solid #ccc;
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;

    h1{
        text-align: center;
        font-size: 1.3rem;
        font-weight: 400;
        margin-top: 0px;
        color: #555;
    }


`;


function SchedulePopup(props) {

    const { close, popupKey, option, data } = props;
    const { selected, title, _save } = data

    const [date, setDate] = useState(undefined)

    const _save_clicked = () => {
        if (date) {
            _save(date)
            close(option, popupKey)

        } else alert("Please select a date")
    }


    return (
        <Wrapper>
            <h1>{title ? title : 'Schedule Your Jobs'}</h1>

            <TextBox
                label="Select Date Time"
                type={"datetime-local"}
                onChange={(e) => {
                    setDate(e.target.value)
                }}
            />

            <Gap h="3rem" />
            <div style={{ marginTop: 'auto' }}>
                <Button
                    primary={true}
                    width="8rem"
                    mR="16px"
                    onClick={() => {
                        _save_clicked()
                    }}>Save</Button>
                <Button
                    width="8rem"
                    bgColor={'#e6e6e6'}
                    onClick={() => {
                        close(option, popupKey)
                    }}>Cancel</Button>
            </div>
        </Wrapper>
    )
}

export default SchedulePopup
