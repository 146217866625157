import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import SharedTable from '../shared-table'
import CloseSvg from '../svg/close';
import { get_table_data } from '../../actions/connection';
import ReactJson from 'react-json-view';


const MainWrapper = styled.div`
    width: 60rem;
    height: 35rem;
    background-color: #fff;
    padding: 2rem .5rem;
    padding-top: 2.5rem;
    border: 3px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
    position: relative;


    h1{
        margin: 0px;
        text-transform: capitalize;
        font-size: 1.5rem;
        text-align: center;
        margin: auto;
    }
    .close_btn{
        background: #cccccc4f;
        width: 1.8rem;
        height: 1.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover{
            background-color: #eaeaea;
            svg{
                fill: rgb(0,138,199);
            }
        }

    }


    .flex_row{
        display: flex;
        align-items: center;
        margin-top: -1.5rem;
        margin-bottom: .8rem;
        width: 100%;
        justify-content: space-between;
    }
`;



const TablePopup = (props) => {

    const { close, popupKey, option, tableData, data, get_table_data } = props;
    const { tableName, connectionId, db_type, table_schema } = data;
    const [tableHead, setTableHead] = useState(undefined);
    const [tableBody, setTableBody] = useState(undefined);
    const [dataMode, setDataMode] = useState('sample data');


    // const tableDataToUse = db_type === "elasticsearch" ? tableData?.hits?.hits : tableData;
    const tableDataToUse = tableData;


    useEffect(() => {


        if (tableDataToUse && tableDataToUse.length > 0) {


            let keys = Object.keys(tableDataToUse[0]);
            let temp_key_for_header = [];
            keys && keys.length > 0 && keys.forEach((k) => {
                let final_key = k.split("_").join(" ")
                temp_key_for_header.push(final_key)
            })
            setTableBody(keys)
            setTableHead(temp_key_for_header)

        }
    }, [tableData])



    useEffect(() => {
        let _post_data = {
            table_name: tableName,
            database_id: connectionId,
            table_schema: table_schema,
        }
        get_table_data(_post_data)
    }, [tableName, connectionId])



    // console.log("tableData", tableHead, db_type, tableDataToUse)
    return (

        <MainWrapper>
            <div style={{ width: '100%', padding: '0px .5rem', boxSizing: 'border-box', borderBottom: '2px solid #ccc' }}>
                <div className="flex_row" style={{ display: 'flex', alignItems: 'center' }}>
                    <h1>Table Name: {tableName}</h1>
                    <div className="close_btn" onClick={() => close(option, popupKey)}>
                        <CloseSvg size="1rem" height="1rem" />
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <select
                        value={dataMode}
                        onChange={(e) => {
                            setDataMode(e.target.value)
                        }}
                    >
                        <option value="sample Data">Sample Data</option>
                        <option value="real time data">Real Time Data</option>
                    </select>
                    {tableDataToUse && tableDataToUse.length > 0 ? <p style={{ fontSize: '1rem', marginTop: '0rem' }}>Rows Shown Here: {tableDataToUse.length}</p> : null}
                </div>
            </div>


            {tableDataToUse && tableDataToUse.length > 0 ? (
                <React.Fragment>
                    {db_type && (db_type === 'json' || db_type === 'mongo' || db_type === 'elasticsearch') ? (
                        <div style={{ width: '100%', height: '25rem', overflow: 'auto' }}>
                            {/* <p>ss</p> */}
                            <ReactJson src={tableDataToUse} />
                        </div>
                    ) : (

                        <SharedTable
                            noOfRows={4}
                            height={'28rem'}
                            action={false}
                            tableData={(tableDataToUse && tableDataToUse.length > 0) ? tableDataToUse : undefined}
                            columnsToShow={tableBody}
                            tableHead={tableHead}
                        />
                    )}
                </React.Fragment>
            ) : tableDataToUse?.length === 0 ? <p style={{ textAlign: 'center', marginTop: '2rem' }}>No Records Found..</p> : <p style={{ textAlign: 'center', marginTop: '2rem' }}>Please wait while we are fetching data...</p>}
        </MainWrapper>
    )
}



const mapStateToProps = (state) => ({
    tableData: state.connectionReducer.tableData
})

export default connect(mapStateToProps, { get_table_data })(TablePopup)
