import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import TextBox from '../hoc/textBox';
import Overlay from '../helper/overlay';
import CloseSvg from '../svg/close'
import RippalEffect from '../hoc/ripple-effect'
import { Button } from '../hoc/button';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { get_history } from '../../actions/favourite';
import RecentSvg from '../svg/recent'
import { debounceWrapper, find_hint_in_array, showPopup } from '../../utils';
import DbSwitcher from '../db.switcher';
import CacheSettingPopup from '../editor.shared/cache.setting.popup';
import enums from '../../utils/enums';
import { getFromSession } from '../../utils/session.helper';
import { constants } from '../../utils/constants';
import { getAllInfoOfQuestion } from '../../actions/insights';
import SuggestionListHoc from '../hoc/suggestionListHoc';
import { calculatePositionForSuggestionBox } from '../../utils/calculatePositionAtCursor';


const Wrapper = styled.div`
    width: 60rem;
    background-color: #fff;
    border: 3px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 1rem;
    height: 22rem;
    position: fixed;
    z-index: 5;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    display: flex;
    flex-direction: column;

    .close{
        position: absolute;
        right: 9px;
        top: 5px;
    }
    h1{
        font-size: 1.286rem;
        font-weight: 500;
        text-align: center;
        color: #1a1a1a;
        margin: 0px;
        display: block;
        width: 100%;
        margin-bottom: 18px;
    }
       .label{
        font-size: .9rem;
        color: #374a66;
        text-transform: capitalize;
        margin-top: 0rem;
        margin-bottom: .5rem;
    }

      .cell_box{
        width: 30%;
    }
    .cell_box_border{
        height: 6rem;  
        border: 1px dashed #7A91B6;
        border-radius: 5px;
        border-style: dashed;
        width: 100%;

        .cell_flex{
            display: flex;
            align-items: center;

        }

        ul{
            padding: 0;
            margin: 0;
            width: 100%;
            overflow: auto;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            max-height:6rem;
            height:6rem;
            flex-wrap: wrap;
            align-content: flex-start;
            box-sizing: border-box;
            padding-left: 1.5rem;

            &::-webkit-scrollbar {
                background-color:red;
                width:3px
            }

            &::-webkit-scrollbar-track {
                background-color:#b1cad5;
            }

            &::-webkit-scrollbar-thumb {
                background-color:#76919d;
                border-radius:0px;
                height: 3px;
            }
            &::-webkit-scrollbar:horizontal{
                background-color: green;
                height: 3px;
            }

        li{
            max-width: 11rem;
            min-width: 11rem;
            margin-right: 3rem;
            cursor: pointer;

            &:hover{
                .close{
                    opacity: 1;
                    cursor: pointer;
                }
            }

            .column_name{
                text-align: left;
                font-size: .8rem;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                color: #374a66;
                text-transform: capitalize;
            }

            .close{
                margin-left: 10px;
                opacity: 0;
            }
            
        }
    }
}
`;


const MiniHintWrapper = styled.div`
    left: 0px;
    right: 0px;
    margin: auto;
    z-index: 222;
    top: ${props => props.top ? props.top : '4rem'};
    display: block;
    transition: all 0.3s;
    max-height: 15rem;
    position: absolute;
    overflow-x: auto;
    &::-webkit-scrollbar {
        background-color:red;
        width:0px
    }
    

    .hints_shadow{
        box-shadow: 0 4px 6px 0 rgb(32 33 36 / 28%);
        width: 100%;
        padding-top: .4rem;
        background-color: #fff;
     }
    .hint_row{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
    }

    span{
        font-size: 1rem;
        padding: .2rem 6px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .prev_hint{
        opacity: 0;
        font-size: 1.1rem;
        max-width: calc(100% - 300px);
    }
    .next_hint{

        background: #fff;
        font-size: .9rem;
        height: 1.5rem;
        line-height: 1.5rem;
        border-bottom: 1px solid #cccccc85;
        color: #333;
        font-weight: 500;
        box-shadow: 0 4px 6px 0 rgb(32 33 36 / 28%);
        &:hover{
            background: #eaeaea;
        }
    }

    .one_line_hint{
        background: #fff;
        font-size: .9rem;
        height: 1.5rem;
        width: 100%;
        margin: 0px 15px;
        line-height: 1.5rem;
        border-bottom: 1px solid #cccccc85;
        color: #333;
        font-weight: 500;
        box-shadow: 0 4px 6px 0 rgb(32 33 36 / 28%);
        display: flex;
        align-items: center;
        
        svg{
            margin-top: 5px;
            margin-right: 10px;
            fill: #008AC7;
        }
        &:hover{
            background: #eaeaea;
        }
    }

      h3{
        padding: 6px 16px;
        font-size: 1.1rem;
        font-weight: 500;
        font-style: italic;
        position: sticky;
        background: #fff;
        top: 0px;
        border-bottom: 1px solid #ccc;
        margin: 0px 15px;

    }


`;




const QuestionPopup = (props) => {

    const {

        question,
        update_question,
        selected_id,
        __selected_date_filter_columns,
        __set_selected_date_filter_columns,
        __global_filter_wtr,
        hints,
        setQuestionFocus,
        db_info_id
    } = props;



    const questionInfo = props?.questionDetailsCache?.[selected_id]

    const [showSuggestions, setShowSuggestions] = useState(undefined);
    const [historyHints, setHistoryHints] = useState(undefined);
    const [selected_date_filter_columns, set_selected_date_filter_columns] = useState(__selected_date_filter_columns);
    const [blur__, set_blur] = useState(false);
    const [local_global_filter_wtr, set_global_filter_wtr] = useState(__global_filter_wtr);
    const [activeHint, setActiveHint] = useState(undefined);
    const [localQuestion, setLocalQuestion] = useState(undefined)

    const [hintList, setHintsList] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [suggestionBoxPosition, setSuggestionBoxPosition] = useState({ top: 60, left: 0 });
    const [currCursorPosition, setCurrCursorPosition] = useState(undefined)

    const wrapperRef = useRef();


    useEffect(() => {
        setLocalQuestion(question)
    }, [question])


    const need_to_use_history_in_suggestion = (!question || question === "")

    const InputRef = useRef(null);


    useEffect(() => {
        if (!props.historyData || props.historyData.length === 0) {
            props.get_history()
        }
    }, [])




    const select_unselect_date_columns = (item) => {

        const clone_s_d_fc = selected_date_filter_columns ? [...selected_date_filter_columns] : [];

        if (clone_s_d_fc.indexOf(item) > -1) {
            clone_s_d_fc.splice(clone_s_d_fc.indexOf(item), 1)

        } else {

            clone_s_d_fc.push(item)
        }
        set_selected_date_filter_columns(clone_s_d_fc)
    }



    // useEffect(() => {
    //     if (need_to_use_history_in_suggestion) {
    //         reset_hints()
    //     }
    // }, [question, props?.historyData?.results])



    // const reset_hints = () => {

    //     if (props?.historyData?.results?.length > 0) {

    //         const hints = props?.historyData?.results.map((hint) => hint.question) || [];
    //         const unique_hints = hints ? [...new Set(hints)] : [];
    //         setHistoryHints(unique_hints)
    //     }
    // }

    useEffect(() => {

        if (props.questionInfo && props.questionInfo.columns_extracted) {
            set_blur(false)
        }

    }, [props.questionInfo])


    const is_value_container_hint = (hint) => {
        if (!props.containerValues || props.containerValues.length === 0 || !hint) return false;
        else {
            return props.containerValues?.length > 0 && hint && props.containerValues.indexOf(hint) > -1;
        }
    };

    const handleSuggestionClick = (word) => {

        var startPos = InputRef?.current?.selectionStart;
        // var endPos = InputRef?.current?.selectionEnd;
        // const end_v = localQuestion?.substring(startPos)
        const start_v = localQuestion?.substring(0, currCursorPosition || startPos)
        let splitted_value = start_v && start_v?.split(' ');
        splitted_value && splitted_value?.pop();
        !currCursorPosition && setCurrCursorPosition(splitted_value ? splitted_value?.join(" ")?.length + 1 : 1);
        let last_value = splitted_value && splitted_value?.[splitted_value?.length - 1];
        // let full_str_value = '';
        if (word && (last_value === word?.split(' ')[0] || word?.includes(last_value)) ) {
            splitted_value && splitted_value?.pop();
        }
        const is_value_container_value = is_value_container_hint(word)
        const wordToConcate = is_value_container_value ? ("'" +word +"'"): word;
        const newValue = [...splitted_value, wordToConcate].join(' ') + InputRef?.current?.value?.slice(startPos);
        const newCursorPosition = splitted_value?.join(' ')?.length + wordToConcate?.length+1;
 
        // if (splitted_value && splitted_value.indexOf(last_value) > -1 && (last_value && last_value.trim() !== '@')) {
        //     let _index = splitted_value?.indexOf(last_value);
        //     splitted_value.splice(_index, 1)
        // }

        // if (last_value && last_value?.trim() === '@') {
        //     full_str_value = (splitted_value && splitted_value.length > 0) ? splitted_value.join(' ') + word + end_v : word + end_v;
        // } else {
        //     full_str_value = (splitted_value && splitted_value.length > 0) ? splitted_value.join(' ') + ' ' + word + end_v : word + end_v;
        // }

        setLocalQuestion(newValue)
        // get_hints_by_value(word)
        if (InputRef?.current) {
            setTimeout(() => {
                if(!InputRef?.current?.value?.slice(startPos)?.trim()){
                    InputRef.current.scrollLeft = Math.max(0, InputRef.current.scrollWidth - InputRef.current.clientWidth);
                }else{
                    InputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
                    // InputRef.current.focus();
                }
            }, 0)
        }
        // setShowSuggestions(undefined);
    };


    const find_biggest_word_in_hints = (hints) => {
        let len = 0;
        hints && hints.length > 0 && hints.forEach((h) => {
            if (h && h.length > len) {
                len = h.length;
            }
        })
        return len;
    }


    // const w_l = find_biggest_word_in_hints(hints)
    // const array_of_suggestion = need_to_use_history_in_suggestion ? historyHints : hints;
    const date_columns = questionInfo?.date_columns || [];

    const global_filter_wrt__constant = [
        {
            label: 'Global Filter WRT LastYear',
            value: 'global_filter_wrt_lastyear',
        },
        {
            label: 'Global Filter WRT Last To LastYear',
            value: 'global_filter_wrt_last_to_lastyear',
        }

    ]


    return (
        <React.Fragment>
            <Overlay
                bgColor="#00000059"
                zIndex="4"
                onClick={() => {
                    setQuestionFocus(undefined);
                    if (question && question.length > 0) {
                        props.getAllInfoOfQuestion(question, db_info_id, selected_id)
                    }
                }}
            />
            <Wrapper ref={wrapperRef} onClick={(e) => {
                e.stopPropagation()
                setShowSuggestions(undefined);
            }}>
                <div className="close">
                    <RippalEffect
                        title={"Close"}
                        icon={<CloseSvg size=".8rem" height=".8rem" />}
                        Click={() => {
                            // setQuestion('')
                            setQuestionFocus(undefined);
                        }}
                    />
                </div>

                {/* <DbSwitcher
                    db_info_id={db_info_id ? [db_info_id] : undefined}
                    reporting_db_info_id={reporting_db_info_id}
                    set_reporting_db_info_id={set_reporting_db_info_id}

                /> */}

                <TextBox
                    autoFocus="true"
                    refName={InputRef}
                    label="Please Type Your Data Question Here"
                    value={localQuestion}
                    inputStyle={{
                        paddingRight: '1rem',
                        fontSize: '1.1rem'
                    }}
                    onChange={(e) => {
                        const value = e.target.value;
                        const startPos = e.target.selectionStart;
                        // on_change_question(e)
                        // if (e.target.value?.length > 0) {
                        currCursorPosition && setCurrCursorPosition(undefined)
                        selectedIndex !== -1 && setSelectedIndex(-1)
                        const get_question_info = () => {
                            props.getAllInfoOfQuestion((value || "---"), db_info_id, selected_id)
                        }

                        const debounceWrapperFun = debounceWrapper(get_question_info, 700)
                        debounceWrapperFun()
                        // }
                        setLocalQuestion(e.target.value)

                        const full_question = value.slice(0, startPos)?.split(' ');
                        const last_word = full_question[full_question.length - 1];
                        const currentWord = last_word;
                        if (hints?.length > 0) {
                            // const result = find_hint_in_array(currentWord, hints, hints);
                            const result = currentWord ? hints?.filter(el => el?.toLocaleLowerCase()?.startsWith(currentWord?.toLocaleLowerCase()))?.sort((a, b) => a?.localeCompare(b, 'en', {numeric:true, sensitivity:'accent'})) : [];
                            setHintsList([...result]);
                            const coordinates = calculatePositionForSuggestionBox(startPos, InputRef, wrapperRef, 'nowrap');
                            setSuggestionBoxPosition(prev=> ({...prev, left: coordinates?.left }))
                        }

                        if (!showSuggestions) setShowSuggestions(true);
                        // currCursorPosition && setCurrCursorPosition(undefined)
                    }}
                    onFocus={() => {
                        setShowSuggestions(true);
                    }}

                    onClick={(e) => {
                        e.stopPropagation()
                    }}

                    onBlur={() => {
                        if (question?.length > 0) {
                            // props.getAllInfoOfQuestion(question, db_info_id)
                        }
                        // set_blur(true)
                    }}

                    onKeyDown={(e) => {
                        e.stopPropagation()
                        if (hintList?.length === 0) return;


                        // onKeyDown(e)
                        // if (e.key === 'Enter') {
                        //     const temp_selected = (selected_date_filter_columns || []).filter((f) => date_columns.indexOf(f) > -1) || []
                        //     __set_selected_date_filter_columns(temp_selected)
                        // }
                        if (e.key === 'ArrowDown') {
                            e.preventDefault();
                            setSelectedIndex((prevIndex) =>
                                prevIndex < hintList.length - 1 ? prevIndex + 1 : 0
                            );
                            handleSuggestionClick(hintList[selectedIndex < hintList.length - 1 ? selectedIndex + 1 : 0 ]);
                        } else if (e.key === 'ArrowUp') {
                            e.preventDefault();
                            setSelectedIndex((prevIndex) =>
                                prevIndex > 0 ? prevIndex - 1 : hintList.length - 1
                            );
                            handleSuggestionClick(hintList[selectedIndex > 1 ? selectedIndex - 1 : hintList.length - 1]);
                        } else if (e.key === 'Enter') {
                            const temp_selected = (selected_date_filter_columns || []).filter((f) => date_columns.indexOf(f) > -1) || []
                            __set_selected_date_filter_columns(temp_selected)
                            // if (selectedIndex >= 0) {
                            //     e.preventDefault();
                            //     handleSuggestionClick(hintList[selectedIndex]);
                            //     setSelectedIndex(0);
                            // }
                        }
                    }}
                />


            {showSuggestions && hintList && hintList?.length > 0 &&
                <SuggestionListHoc suggestionList={hintList} selectedIndex={selectedIndex} position={suggestionBoxPosition} handleSuggestionClick={handleSuggestionClick} /> 
            }
                {/* {
                    showSuggestions && typeof array_of_suggestion !== 'undefined' && array_of_suggestion && array_of_suggestion.length > 0 && (
                        <MiniHintWrapper>
                            {need_to_use_history_in_suggestion && (
                                <h3>Recent History</h3>

                            )}
                            {array_of_suggestion.map((hint, index) => {

                                // let value_arr = question && question.split(" ");
                                // let searchIndex = value_arr && value_arr[value_arr.length - 1].length;
                                // let firstValue = hint.substring(0, searchIndex)
                                // let lastValue = hint.substring(searchIndex);

                                return (
                                    <div className="hint_row" index={"mini" + index}>

                                        {need_to_use_history_in_suggestion && (
                                            <span className="one_line_hint" onClick={() => {
                                                // on_change_question(undefined, hint)
                                                if (InputRef && InputRef.current) {
                                                    InputRef.current.focus();
                                                }
                                            }}>
                                                <RecentSvg size="1rem" height="1rem" color="008AC7" /> {hint}
                                            </span>
                                        )}

                                        {!need_to_use_history_in_suggestion && (
                                            <React.Fragment>
                                                <span className="prev_hint">{question}</span>
                                                <span className="next_hint"
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        // replace_hint_with_word(index, true);
                                                        if (InputRef && InputRef.current) {
                                                            InputRef.current.focus();
                                                        }
                                                    }}
                                                    style={{
                                                        width: w_l ? (w_l > 3) ? (w_l * 12) + "px" : undefined : undefined,
                                                        backgroundColor: activeHint === index ? "#eaeaea" : undefined
                                                    }}>
                                                    {hint}
                                                </span>
                                            </React.Fragment>
                                        )}
                                    </div>
                                )
                            })}
                        </MiniHintWrapper>
                    )
                } */}

                {blur__ && (
                    <div>
                        <p style={{
                            textAlign: "center",
                            fontSize: '.9rem',
                            margin: '3rem',
                        }}>Please wait while we are loading information...</p>
                    </div>
                )}


                {!blur__ && date_columns && date_columns.length > 0 && (
                    <div class="cell_box" style={{ width: '100%', marginTop: '1rem' }}>
                        <div style={{ position: 'relative' }}>
                            <h2 class="label">Map Dates with Master Date Filter</h2>
                        </div>
                        <div class="cell_box_border" >
                            <ul style={{ listStyle: 'none', paddingLeft: '0px' }}>
                                {date_columns.map((item, i) => {
                                    return (
                                        <li style={{ maxWidth: '100%', padding: '2px 0px' }}>
                                            <div className="column_name" onClick={(e) => {
                                                e.stopPropagation()
                                                select_unselect_date_columns(item)

                                            }}>
                                                <input type="checkbox"
                                                    checked={(selected_date_filter_columns || []).indexOf(item) > -1}
                                                    onChange={(e) => {
                                                        select_unselect_date_columns(item)
                                                    }}
                                                /> &nbsp;&nbsp;
                                                {item}
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                )}


                {true && !blur__ && question?.length > 0 && global_filter_wrt__constant?.length > 0 && (
                    <div class="cell_box" style={{ width: '100%', marginTop: '1rem', }}>
                        <div style={{ position: 'relative' }}>
                            <h2 class="label">Choose DATE</h2>
                        </div>
                        <div class="cell_box_border" style={{ height: '1.4rem', border: '0px' }}>
                            <ul style={{ listStyle: 'none', paddingLeft: '0px', display: 'flex', flexDirection: 'row' }}>
                                {global_filter_wrt__constant.map((item, i) => {
                                    return (
                                        <li style={{ maxWidth: '30%', padding: '2px 0px' }}>
                                            <div className="column_name" onClick={(e) => {
                                                e.stopPropagation()
                                                if (local_global_filter_wtr === item?.value) {
                                                    set_global_filter_wtr('')
                                                }
                                                else set_global_filter_wtr(item?.value)
                                            }}>
                                                <input type="checkbox"
                                                    checked={local_global_filter_wtr === item?.value}
                                                    onChange={(e) => {
                                                        if (local_global_filter_wtr === item?.value) {
                                                            set_global_filter_wtr('')
                                                        }
                                                        else set_global_filter_wtr(item?.value)
                                                    }}
                                                /> &nbsp;&nbsp;
                                                {item?.label}
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                )}

                <div style={{ marginTop: 'auto' }}>
                    {/* {!is_it_cached_db && (
                        <Button disabled={question?.length === 0 || !question} bgColor="#06577a" width="8rem" mR="1rem" onClick={() => {
                            open_cache_setting_popup()
                        }}>Wanna Cache ?</Button>
                    )} */}

                    <Button primary={true} mR="1rem" onClick={() => {
                        // __set_selected_dat   e_filter_columns(selected_date_filter_columns)
                        // __set_global_filter_wtr(local_global_filter_wtr)
                        // props.getAllInfoOfQuestion(question, db_info_id)
                        
                        update_question(localQuestion)
                        setQuestionFocus(undefined);
                    }}>
                        Save
                    </Button>
                    <Button
                        width="5rem"
                        bgColor={'#e6e6e6'}
                        onClick={() => {
                            setQuestionFocus(undefined);
                        }}>Cancel</Button>
                </div >
            </Wrapper >
        </React.Fragment >

    )
}


const mapStateToProps = state => ({

    historyData: state.favouriteReducer.historyData,
    questionDetailsCache: state.insightReducer.questionDetailsCache,
    containerValues: state.reportReducer.containerValues
});


export default withRouter(connect(mapStateToProps, { get_history, getAllInfoOfQuestion })(QuestionPopup))

