
const workarounds = {};

const kx = require('./kx.mis');
const adskom = require('./adskom');


export const add_identifier_filters_to_report = (client_id, existing_filters) => {
    const client_file = get_workaround_file_as_per_client_id(client_id);

    if (client_file && client_file.add_identifier_filters_to_report) {
        return client_file.add_identifier_filters_to_report(existing_filters);
    }

    return {};
};


const get_workaround_file_as_per_client_id = client_id => {
    switch(client_id) {
        case 'kx.mis':
            return kx;
        case 'adskom': 
        case 'ads_yahoo': 
            return adskom
    }
}