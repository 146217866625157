
export const DATA_WRANGLER_INIT = 'modules/data_wrangler/init';


export const SAVE_DATA_WRANGLER_CONFIG = 'modules/data_wrangler/save_config';
export const SAVE_DATA_WRANGLER_CONFIG_REPORTS = 'modules/data_wrangler/save_report';


export const GET_DATA_WRAGLING_JOBS = 'modules/data_wrangler/get_data_wrangler_jobs';
export const GET_DATA_WRANGLER_CONFIG = 'modules/data_wrangler/get_data_wrangler_config';
export const GET_REPORT_BY_ID = 'modules/data_wrangler/get_data_wrangler_report';
export const GET_EXTRACTION_BY_ID = 'modules/data_wrangler/get_extraction';
export const GET_LOAD_MAPPING_BY_ID = 'modules/data_wrangler/get_load_mapping_by_id';





export const GET_DATA_WRANGLER_CONFIG_BY_JOB_ID = 'modules/data_wrangler/get_data_wrangler_config_by_job_id';


export const GET_JOB_OCCURENCES_BY_JOB_ID_SUCCESS = 'modules/data_wrangler/GET_JOB_OCCURENCES_BY_JOB_ID_SUCCESS';
export const GET_JOB_LOGS_BY_OCCURANCE_ID_SUCCESS = 'modules/data_wrangler/GET_JOB_LOGS_BY_OCCURANCE_ID_SUCCESS';
export const GET_JOB_ERRORS_RECORDS = 'modules/data_wrangler/GET_JOB_ERRORS_RECORDS'