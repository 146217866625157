import React from 'react'
import styled from 'styled-components';
import DropDownSvg from '../svg/dropDown'
import SettingSvg from '../svg/setting';
import LogOutSvg from '../svg/logOut';
import ToogleButton from '../hoc/toogle.btn';
import { connect } from 'react-redux';

const RightSideWrapper = styled.div`
    width: 100%;

`;

const HeaderRightSide = (props) => {

    /***
     * here we are destructuring the props 
     */
    const {
        userPreference,
        setMenuOpen,
        isMenuOpen,
        historyData,
        _getDataFromReportingServer,
        favouriteData,
        click_on_change_data_source_link,
        dataBaseId,
        selected_db_name,
        db_infos,
        is_developer_mode

    } = props;


    return (
        <React.Fragment>
            <div className="setting" title="Setting" onClick={() => setMenuOpen(!isMenuOpen)}> <SettingSvg size={'1.2rem'} height={"1.2rem"} /></div>
            <div className="main_dropdown_menu">
                {props.user && props.user.name ? <h2 style={{ fontSize: '1.2rem', textAlign: 'center', }}>{props.user.name}</h2> : null}
                {/* <div style={{ width: '100%', padding: '5px 1rem', boxSizing: 'border-box' }}>
                    <span style={{ display: 'inline-block', marginRight: '1rem' }}>Developer Mode</span>
                    <ToogleButton
                        checked={is_developer_mode}
                        onClick={() => {
                            const __obj__ = props.userPreference ? Object.assign({}, props.userPreference) : {};
                            __obj__.setting_value = is_developer_mode ? 'disable' : 'enable';
                            __obj__.setting_name = 'Developer Mode';
                            __obj__.modified = true;
                            props.save_user_preference(__obj__)
                        }}
                    />
                </div> */}
                <ul>
                    {db_infos && db_infos.length > 1 && (
                        <div style={{ border: '1px solid #ccc',borderRadius:'6px 6px 0px 0px', margin: 'auto', padding: '1rem 0rem', width: '100%', textAlign: 'center' }}>
                            <div className="selected_db_name" title={selected_db_name(dataBaseId)}>{selected_db_name(dataBaseId)}</div>
                            <div className="change_db_link" onClick={click_on_change_data_source_link}>Change Data Source</div>
                        </div>
                    )}
                    {/* <li>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '.5rem 0rem' }}>Your favourites List <span className="drop_icon"><DropDownSvg size=".6rem" height=".6rem" /></span></div>
                        <div className="inner_dropdown">
                            <p>{favouriteData && favouriteData.results && favouriteData.results.length > 0 ? 'Your recent favorites' : 'Favorites not available'}</p>
                            <ul style={{ paddingBottom: '1.2rem' }}>
                                {favouriteData && favouriteData.results && favouriteData.results.length > 0 && favouriteData.results.map((item, i) => {
                                    if (i < 5) {
                                        return (<li
                                            key={"favourite" + i}
                                            onClick={() => {
                                                _getDataFromReportingServer(item.query, props.history);
                                                setMenuOpen(undefined)
                                            }}>{item.name}</li>)
                                    } else return null
                                })}
                            </ul>
                            {favouriteData && favouriteData.results && favouriteData.results.length > 5 &&
                                <li style={{ fontSize: '.9rem', textAlign: 'center' }} onClick={() => {
                                    props.history.push("/favourites");
                                    setMenuOpen(undefined)
                                }}>View more...</li>
                            }
                        </div>

                    </li> */}
                    <li>

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '.4rem 0rem' }}>Your History List <span className="drop_icon"><DropDownSvg size=".6rem" height=".6rem" /></span></div>
                        <div className="inner_dropdown">
                            <p>{historyData && historyData.results && historyData.results.length > 0 ? 'Your recent history' : 'History Not Available'}</p>
                            <ul>
                                {historyData && historyData.results && historyData.results.length > 0 && historyData.results.map((item, i) => {
                                    if (i < 5) {
                                        return (<li
                                            key={"history" + i}
                                            onClick={() => {
                                                _getDataFromReportingServer(item.question, undefined, undefined, undefined, undefined, undefined, props.history);
                                                setMenuOpen(undefined)
                                            }}><div>{item.question}</div></li>)
                                    }
                                })}
                            </ul>
                            {historyData && historyData.results && historyData.results.length > 5 &&
                                <li style={{ fontSize: '.9rem', textAlign: 'center',color:'#000', borderTop:'1px solid #ccc' }} onClick={() => {
                                    props.history.push("/history");
                                    setMenuOpen(undefined)
                                }}>View more...</li>
                            }
                        </div>
                    </li>
                </ul>
                <li
                    style={{ display: 'flex', alignItems: 'center' }}
                    className="log_out" onClick={() => props.log_out_user()}>
                    <LogOutSvg size="1rem" height="1rem" />&nbsp;&nbsp;
                    <span>Log Out</span>
                </li>
            </div>
        </React.Fragment>
    )
}








const mapStateToProps = state => ({
    favouriteData: state.favouriteReducer.favouriteData,
    historyData: state.favouriteReducer.historyData,
    userPreference: state.accountReducer.userPreference
})



export default connect(mapStateToProps, {})(HeaderRightSide)
