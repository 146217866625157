import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setData, setColumns, setEditedColumns, setTableState, update_width_additions } from '../../actions/table';
import Table from '../table';
import { getTableData } from '../../utils/table.helper';
import { getColumnWidths } from '../../utils/tbl.width.calculator';
import { console_logger } from '../../utils';


const PREFERANCES = {
    COLUMNS_ORDER: "report_table_column_order_v1",
    COLUMNS_WIDTH: 'report_table_column_width_v1'
}

const TableWrapper = (props) => {

    const {

        xDataKeys,
        yDataKeys,
        insights,
        setTableState,
        id,
        columnMeta,
        tableStyle,
        mainFormulas,
        windowWidth,
        isMobile,
        columnsAligments,
        hide_columns,
        tableDataMode,
        process_data_updated_at,
        width_additions_cache,

    } = props;



    /************
     * 
     * here are the plan for table pivot_data and raw_data when user will do any operation on table
     * action =>> 
     *      user can sort the table 
     *      user can change the column order of the table
     *      user can next prev the table data
     *      user can hide the column
     *  
     *
     */

    const table_render_mode = props.table_data_force_mode ? props.table_data_force_mode : tableDataMode

    const table_id_to_use = id ? (id + "__nfx__" + (table_render_mode === "pivot_data" ? 1 : 0)) : id;

    const width_additions = props?.width_additions_cache?.[table_id_to_use] || [];

    const table_data_from_reducer = props.tempData && props.tempData[table_id_to_use];

    const finalTableWidth = isMobile ? (windowWidth - 20) : props.width;

    const finalTableHeight = isMobile ? 200 : props.height;

    const [columns, setColumns] = useState(undefined);
    const [columnsWidth, setColumnsWidth] = useState(undefined);
    const [dateColumns, setDateColumns] = useState(undefined);


    const report_user_preferance = props.report_item?.report_user_preferance;



    useEffect(() => {

        const tempId = table_id_to_use;

        const input_data = props.sortedData;

        if (input_data && input_data.length > 0) {

            if (input_data) {

                let use_old_data = false;

                if (table_data_from_reducer?.last_updated_at) {
                    if (input_data && process_data_updated_at) {
                        if (table_data_from_reducer.last_updated_at === process_data_updated_at) {
                            use_old_data = true;
                        }
                    }
                }

                if (use_old_data) {
                    setColumns(table_data_from_reducer.columns);
                    setDateColumns(table_data_from_reducer.dateColumns);
                }
                else {


                    const column_width = report_user_preferance?.find((p) => p.preferance_name === (PREFERANCES.COLUMNS_WIDTH + "_nfx_" + table_render_mode))?.preferance_data;

                    // this block will update the column width 
                    if (column_width && Object.keys(column_width)?.length > 0 && Object.keys(width_additions).length === 0) {
                        props.update_width_additions(table_id_to_use, column_width)
                    }



                    const tempTableObject = getTableData(input_data, xDataKeys, yDataKeys, columnMeta, hide_columns);

                    if (tempTableObject && tempTableObject.columns && tempTableObject.columns.length > 0) {
                        setColumns(tempTableObject.columns);
                        setDateColumns(tempTableObject.dateColumns);
                    }

                    let prev_edited_column = table_data_from_reducer?.editedColumns || tempTableObject.editedColumns;

                    if ((tempTableObject.editedColumns && JSON.stringify(Object.keys(tempTableObject.editedColumns))) !== (table_data_from_reducer?.editedColumns && JSON.stringify(Object.keys(table_data_from_reducer?.editedColumns)))) {
                        prev_edited_column = tempTableObject.editedColumns;
                    }

                    // console.log("tempTableObject", tempTableObject)

                    const be_column_orders = report_user_preferance?.find((p) => p.preferance_name === (PREFERANCES.COLUMNS_ORDER + "_nfx_" + table_render_mode))?.preferance_data;
                    const be_column_orders_keys = be_column_orders ? Object.keys(be_column_orders) : [];
                    const prev_edited_column_keys = prev_edited_column ? Object.keys(prev_edited_column) : [];

                    if (be_column_orders_keys?.length === prev_edited_column_keys?.length) {
                        // one comparision passed
                        const is_eqqual = be_column_orders_keys.every((column) => prev_edited_column_keys.indexOf(column) > -1);
                        if (is_eqqual) {
                            // yeah both are eqqual and contain same item let's push be_columns for order mantaining 
                            // props.updateTableColumn(be_column_orders, table_id_to_use);
                            prev_edited_column = be_column_orders;
                        }
                    }
                    setTableState(tempTableObject.sortedData, tempId, tempTableObject.columns, prev_edited_column, tempTableObject.additionalHeader, process_data_updated_at);
                }
            }
            const tableWidth = getColumnWidths(input_data, 250, (finalTableWidth - 3), columnsAligments, props.question, xDataKeys, yDataKeys, insights, hide_columns)
            if (tableWidth) setColumnsWidth(tableWidth)

        } else {
            setTableState([], tempId, undefined);

        }

        console_logger(`TABLE WRAPPER CALCULATION DONE || ${props.sortedData?.length}`)

    }, [props.sortedData, xDataKeys, yDataKeys, tableDataMode, finalTableWidth]);

    console_logger(`TABLE WRAPPER INIT, ${table_data_from_reducer?.length}`)



    return (
        <React.Fragment>

            <Table
                {...props}
                table_id_to_use={table_id_to_use}
                tableStyle={tableStyle}
                xDataKeys={xDataKeys}
                mainFormulas={mainFormulas}
                columnsData={columns}
                dateColumns={dateColumns}
                columnsWidth={columnsWidth}
                columnsAligment={columnsAligments}
                history={props.history}
                width={finalTableWidth}
                height={finalTableHeight}
                table_grand_total_columns={props.table_grand_total_columns}
                drill_down_report={props.drill_down_report}
                drill_down_type={props.drill_down_type}
                drill_down_values={props.drill_down_values}
                comparisonKeys={props.comparisonKeys}
                comparisons={props.comparisons}

            />
        </React.Fragment>
    );
};




const mapStateToProps = (state, props) => {

    const tempData = state.tableReducer.data;
    const t_data_mode = state.helperReducer.tableDataMode;
    const tableDataMode = t_data_mode && t_data_mode[props.id];
    const width_additions_cache = state.tableReducer?.width_additions_cache

    return { tableDataMode, tempData, width_additions_cache }
}


export default connect(mapStateToProps, { update_width_additions, setColumns, setData, setEditedColumns, setTableState })(TableWrapper);