import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import { store } from './store';
import './app.scss';
import EntryPoint from './components/entry.point';
// import { theme } from './theme'
import { Helmet } from 'react-helmet';

import SpeechRecognition from './components/SpeechRecognition';
import VoiceSynthesis from './components/voice.synthesis';
function App() {


    return (
        <Provider store={store}>
            <Helmet>
                <title>Next-Gen Data Anaytics Platform</title>
            </Helmet>

            <BrowserRouter
                basename={process.env.REACT_APP_MODE === 'development' ? '' : ''}
            >
                <div>
                    {/* 
                        * This is the main EntryPoint Component
                    */}
                    <EntryPoint />
                </div>
                {/* <SpeechRecognition /> */}
                {/* <VoiceSynthesis /> */}
            </BrowserRouter>
        </Provider>
    );
}

export default App;
