import { simpleDispatch_post, dispatchAction } from './helper';
import * as actionTypes from '../action-types/entity';
import { constants } from '../utils/constants';

export const getEntities = (client_id = 'default') => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.ENTITIES.POINT + constants.END_POINTS.ENTITIES.GET_ENTITIES;

    const post = {
        client_id
    }

    await simpleDispatch_post(dispatch, url, post, actionTypes.GET_ENTITY_SUCCESS);
};

export const clearEntities = () => async dispatch => {
    dispatchAction(dispatch, actionTypes.CLEAR_ENTITY_SUCCESS, undefined, undefined, undefined);
};




export const saveEntities = (entity) => async dispatch => {

    const url = constants.END_POINTS.API + constants.END_POINTS.ENTITIES.POINT + constants.END_POINTS.ENTITIES.SAVE_ENTITIES;


    const post = {
        client_id: 'default',
        entity
    }


    await simpleDispatch_post(dispatch, url, post, actionTypes.SAVE_ENTITY_SUCCESS);


};

