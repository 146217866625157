// Exporting ALIASE
export const ALIASE = {};

// Exporting PIVOT_COLUMN_TYPE
export const PIVOT_COLUMN_TYPE = {
    PIVOT_X: 'pivot_x',
    PIVOT_COMPARISON: 'pivot_comparison',
    PIVOT_Y: 'pivot_y',
};

// Exporting REPORT_TYPES
export const REPORT_TYPES = {
    PLAIN_ENGLISH: 'plain_english',
    SQL: 'sql',
    DASHBOARD: 'dashboard',
    CONFIG_QUERY_BUILDER: 'config_query_builder',
    DATA_SOURCE: 'data_source',
    DASHBOARD_CONFIG_QUERY_BUILDER: 'dashboard_config_query_builder',
    DASHBOARD_SQL: 'dashboard_sql',
    CACHE_EDITOR: 'cache_editor',
};

export const FREQUENCY_TYPE = [
    { name: 'Seconds', value: 'seconds' },
    { name: 'Minutes', value: 'minutes' },
    { name: 'Hour', value: 'hourly' },
    { name: 'Day', value: 'daily' },
    { name: 'Week', value: 'weekly' },
    { name: 'Month', value: 'monthly' },
    { name: 'Year', value: 'yearly' },
    { name: 'Quarter', value: 'quarterly' },
];


// Exporting MONTHS
export const MONTHS = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec'
};

// Exporting FULL_MONTHS
export const FULL_MONTHS = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
};

// Exporting MONTHS_INDEXED
export const MONTHS_INDEXED = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec'
};

// Exporting FULL_MONTHS_INDEXED
export const FULL_MONTHS_INDEXED = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
};

// Exporting MONTH_NAMES
export const MONTH_NAMES = {
    SHORT: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    FULL: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
};

// Exporting CHART_TYPES

export const CHART_TYPES = {
    bar_chart: 'bar_chart',
    predictive_chart: 'predictive_chart',
    stacked_bar_chart: 'stacked_bar_chart',
    line_chart: 'line_chart',
    area_chart: 'area_chart',
    tree_map: 'tree_map',
    pie_chart: 'pie_chart',
    radar_chart: 'radar_chart',
    line_bar_chart: 'line_bar_chart',
    gauge_chart: 'gauge_chart',
    dash_line_chart: 'dash_line_chart',
    donut_chart: 'donut_chart',
    gantt_chart: 'gantt_chart',
    scatter_chart: 'scatter_chart',
    bubble_chart: 'bubble_chart',
    composed_bar_chart: 'composed_bar_chart',
    waterfall_chart: 'waterfall_chart',
    horizontal_bar_chart: 'horizontal_bar_chart',
    horizontal_waterfall_chart: 'horizontal_waterfall_chart',
    area_chart_100: 'area_chart_100_perc',
    stacked_bar_chart_100: 'stacked_bar_chart_100_perc',
    horizontal_stack_bar_chart: 'horizontal_stack_bar_chart',
    horizontal_stack_bar_chart_100: 'horizontal_stack_bar_chart_100',
    map: 'map',
    ogive_chart: 'ogive_chart',
    tower_chart: 'tower_chart',
    pareto_chart: 'pareto_chart',
    funnel_chart: 'funnel_chart',
};

// Exporting RIGHTER_MENU_MODE
export const RIGHTER_MENU_MODE = {
    CHART_OPTION: "chart_option",
    DOWNLOAD_OPTION: "download_option",
    FAVOURITE_OPTION: "favourite_option",
    DASHBOARD_OPTION: "dashboard_option",
    SETTINGS_OPTION: "settings_option",
    FILTER_OPTION: "filter_option",
    TABLE_FORMATTING_OPTION: "table_formatting_option",
    TABLE_FORMULA_EDITOR: 'table_formula_editor',
    OTHER_CONFIG: 'other_config',
    COLUMN_CHOOSER: 'column_chooser',
    REPORT_SETTINGS: 'report_settings',
};

// Exporting ELEMENT_UI_TYPES
export const ELEMENT_UI_TYPES = {
    LIST: 'list',
    CHECKBOX: 'checkbox',
    RADIO_BUTTON: 'radio',
    BUTTON: 'button',
    MULTIPLE_VALUES: "multiple_values",
    SIMPLE_INPUT_BOX: 'input'
};

// Exporting RENDER_MODE
export const RENDER_MODE = {
    chart: 'chart',
    table: 'table',
    map: 'map',
    single_cell: 'single_cell',
    box: 'group',
    label: 'label',
    report: 'report'
};

// Exporting YEAR and YEARS
export const YEAR = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023];
export const YEARS = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024];

// Exporting DATE_FILTER
export const DATE_FILTER = {
    NONE: {
        display_value: 'None',
        value: ''
    },
    TODAY: {
        display_value: 'Today',
        value: 'today'
    },
    YESTERDAY: {
        display_value: 'Yesterday',
        value: 'yesterday'
    },
    THIS_WEEK: {
        display_value: 'This Week',
        value: 'this_week'
    },
    THIS_MONTH: {
        display_value: 'This Month',
        value: 'this_month'
    },
    THIS_YEAR: {
        display_value: 'This Year',
        value: 'this_year'
    },
    LAST_15_DAYS: {
        display_value: 'Last 15 Days',
        value: 'last_15_days'
    },
    LAST_WEEK: {
        display_value: 'Last Week',
        value: 'last_week'
    },
    LAST_MONTH: {
        display_value: 'Last Month',
        value: 'last_month'
    },
    LAST_YEAR: {
        display_value: 'Last Year',
        value: 'last_year'
    },
    CUSTOM_DATE: {
        display_value: 'Custom Date',
        value: 'custom_date'
    }
};

// Exporting DATA_TYPES
export const DATA_TYPES = {
    STRING: {
        display_value: 'String',
        value: 'string'
    },
    DATE: {
        display_value: 'Date',
        value: 'date'
    },
    INT: {
        display_value: 'Integer',
        value: 'integer'
    }
};

// Exporting AGGREGATE
export const AGGREGATE = {
    SUM: {
        display_value: 'Sum',
        value: 'sum'
    },
    COUNT: {
        display_value: 'Count',
        value: 'count'
    }
};

// Exporting CHART_TYPES_WITH_ICONS
export const CHART_TYPES_WITH_ICONS = [
    {
        name: 'Bar Chart',
        dataKey: 'bar_chart',
        icon: 'editor/charts/bar_chart.png'
    },
    {
        name: 'Water Fall Chart',
        dataKey: 'waterfall_chart',
        icon: 'editor/charts/water_fall_chart.png'
    },
    {
        name: 'Stacked Bar Chart',
        dataKey: 'stacked_bar_chart',
        icon: 'editor/charts/stack_bar_chart.png'
    },
    {
        name: 'Area Chart',
        dataKey: 'area_chart',
        icon: 'editor/charts/area_chart.png'
    },
    {
        name: 'Trending Chart',
        dataKey: 'line_chart',
        icon: 'editor/charts/line_chart.png'
    },
    {
        name: 'Line Bar Chart',
        dataKey: 'line_bar_chart',
        icon: 'editor/charts/line_bar_chart.png'
    },
    {
        name: 'Dash Line Chart',
        dataKey: 'dash_line_chart',
        icon: 'editor/charts/line_chart.png'
    },
    {
        name: 'Pie Chart',
        dataKey: 'pie_chart',
        icon: 'editor/charts/pie_chart.png'
    },
    {
        name: 'Tree Map Chart',
        dataKey: 'tree_map',
        icon: 'editor/charts/tree_map_chart.png'
    },
    {
        name: 'Gauge Chart',
        dataKey: 'gauge_chart',
        icon: 'editor/charts/bg_chart/gauge_chart_s.png'
    },
    {
        name: 'Donut Chart',
        dataKey: 'donut_chart',
        icon: 'editor/charts/donut_chart.png'
    },
    {
        name: 'Radar Chart',
        dataKey: 'radar_chart',
        icon: 'editor/charts/radar_chart.png'
    },
    {
        name: 'Scatter Chart',
        dataKey: 'scatter_chart',
        icon: 'editor/charts/scatter_icon.png'
    },
    {
        name: 'Bubble Chart',
        dataKey: 'bubble_chart',
        icon: 'editor/charts/bubble_chart.png'
    },
    {
        name: 'Horizontal Stacked Bar',
        dataKey: 'horizontal_stack_bar_chart',
        icon: 'editor/charts/horizonatl_stack_bar_chart.png'
    },
    {
        name: 'Horizontal Bar Chart',
        dataKey: 'horizontal_bar_chart',
        icon: 'editor/charts/horizontal_bar_chart.png'
    },
    {
        name: 'Horizontal WaterFall Chart',
        dataKey: 'horizontal_waterfall_chart',
        icon: 'editor/charts/horizonatl_waterfall_chart.png'
    },
    {
        name: 'Pareto',
        dataKey: 'pareto_chart',
        icon: 'editor/charts/pareto_chart.png'
    },
    {
        name: 'Table',
        dataKey: 'table',
        icon: 'editor/charts/table.png'
    },
    {
        name: 'Tower',
        dataKey: 'tower_chart',
        icon: 'editor/charts/tower-icon.png'
    },
    {
        name: 'Single Cell',
        dataKey: 'single_cell',
        icon: 'editor/charts/single_cell.png'
    },
    {
        name: 'Group',
        dataKey: 'group',
        icon: 'editor/charts/group.png'
    },
    {
        name: 'Label',
        dataKey: 'label',
        icon: 'editor/charts/label.png'
    },
    {
        name: 'Funnel',
        dataKey: 'funnel_chart',
        icon: 'editor/charts/funnel_horizontal.png'
    },
];

// Exporting BUSINESS_DOMAIN
export const BUSINESS_DOMAIN = {
    AD_TECH: 'ad_tech'
};

// Exporting VALIDATION_ERROR_TYPES
export const VALIDATION_ERROR_TYPES = {
    TYPE_ONE: 'length_error',
    TYPE_TWO: 'primary_key_error'
};

// Exporting START_DATES
export const START_DATES = {
    FY: {
        QUARTER: {
            1: '%YEAR%-04-01 00:00:00',
            2: '%YEAR%-07-01 00:00:00',
            3: '%YEAR%-10-01 00:00:00',
            4: '%YEAR%-01-01 00:00:00',
        }
    }
};
