import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getImportErrorInfo, getColumnsInfo, saveColumnInfo } from '../../actions/import.error';
import SharedTable from '../shared-table';
import TitleHeader from '../title-header';
import { PageInnerWrapper } from '../hoc/styled.hoc';
import { showPopup } from '../../utils';
import AddColumnsPopup from './add.columns.popup';
import * as enums from '../../utils/enums';
import { withRouter } from 'react-router-dom';



const Index = (props) => {

    const { errorData, getImportErrorInfo, saveColumnInfo, getColumnsInfo, columnsInfo } = props;

    useEffect(() => {

        let _page_info = {
            title: 'IMPORTS ERROR'
        }
        props.dispatchHeaderInformation(_page_info)
        getImportErrorInfo()
    }, [])


    const _local_save_function = (data) => {
        saveColumnInfo(data)

        setTimeout(() => {
            getImportErrorInfo()
        }, 1000)
    }

    const addAction = (_row) => {
        let dimensions = _row.file_dimension_info
        let _nf_upload_file_info = _row.nf_upload_file_info;

        _row.main_id = _row.id;

        let _table_name = (dimensions && dimensions.table_name) ? dimensions.table_name : undefined;

        if (_table_name) {
            getColumnsInfo(dimensions.table_name)
        }

        showPopup(undefined, undefined, enums.default.popupType.element, AddColumnsPopup, {
            info: _row,
            normalError: true,
            saveColumnInfo: _local_save_function
        })
    }


    return (
        <div>
            {/* <TitleHeader title="Imports Error" /> */}
            <PageInnerWrapper padding={'10px'} style={{marginTop: '1rem'}}>
                <SharedTable
                    height={"calc(100vh - 180px)"}
                    actionOnHover={true}
                    // processAction={() => {
                    // }}

                    // updateAction={() => {
                    // }}

                    addAction={addAction}

                    rejectedAction={() => {
                    }}

                    tableData={(errorData && errorData.length > 0) ? errorData : undefined}
                    tableHead={["Title", "Description", "Status", "Type"]}
                    columnsToShow={["title", "description", "status", "type"]}
                />
                {errorData == undefined || errorData.length == 0 ? <p style={{ textAlign: "center" }}>No Record Found</p> : null}
            </PageInnerWrapper>
        </div>
    )
}

const mapStateToProps = (state) => ({
    errorData: state.ImportErrorReducer.errorData,
})

export default withRouter(connect(mapStateToProps, { getImportErrorInfo, getColumnsInfo, saveColumnInfo })(Index));
