import React from 'react';
import styled from 'styled-components';


/***
 * this is styled component for this component
 */
const Wrapper = styled.div`
    display: flex;
    align-items: center;
    .data_story{
        border: 1px solid #ccc;
        padding: 6px 10px;
        font-size: .7rem;
        font-weight: 600;
        cursor: pointer;
        background-color: #fff;
        border-radius: 6px;
        margin: 0px 2px;
        text-transform: uppercase;
    }
`;

const YtdMtdWrapper = styled.div`
 display: flex;
 align-items: center;
 margin: 0 6px;
 background-color: #f3f6ff;
 padding: 6px;
 border-radius: 6px;
 div{
     padding: 6px 8px;
     font-size: .7rem;
     font-weight: 600;
     cursor: pointer;
     border-radius: 6px;
     margin: 0px 4px;
     display: inline-block;
     color: #000;
     display: flex;
     align-items: center;
     justify-content: center;
     text-transform: uppercase;
 }
    input[type="checkbox"]{
        cursor: pointer;
    }
    .active{
        background-color:#fff;
        box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    }
    .accented {
    accent-color: #fff;
    }
    [color-scheme="dark"] .accented {
    accent-color:#fff;
    }
`;


const YtdMtdComponent = (props) => {

    const {

        show_mtd,
        show_ytd,
        ytd_mtd_option_clicked,
        change_ytd_mtd,


        // this props is for aggregation
        toggle_aggregate_option,
        aggregate_options,
        show_aggregate_option,
        show_min,
        show_max,
        show_avg,
        show_st_dev,
        show_trend_line,
        show_data_label,
        set_show_data_label,
        open_auto_description

    } = props;

    return (<Wrapper >
        <YtdMtdWrapper>
            {show_mtd && (
                <div className={ytd_mtd_option_clicked === 'mtd' ? `active` : undefined} onClick={() => {
                    change_ytd_mtd(ytd_mtd_option_clicked === 'mtd' ? '' : 'mtd');
                }}
                >
                    <input className="accented" id="accented-dark" checked={ytd_mtd_option_clicked === 'mtd' ? true : false} type="checkbox" />&nbsp;
                    MTD </div>
            )}

            {show_ytd && (
                <div className={ytd_mtd_option_clicked === 'ytd' ? `active` : undefined}
                    onClick={() => {
                        change_ytd_mtd(ytd_mtd_option_clicked === 'ytd' ? '' : 'ytd');
                    }}
                >
                    <input className="accented" id="accented-dark" type="checkbox" checked={ytd_mtd_option_clicked === 'ytd' ? true : false} />&nbsp;
                    YTD </div>
            )}


            {show_aggregate_option && (
                <React.Fragment>
                    {/* {show_min && (
                        <div className={aggregate_options && aggregate_options["is_min_checked"] ? `active` : undefined} onClick={() => toggle_aggregate_option("is_min_checked")}>
                            <input className="accented" id="accented-dark" type="checkbox" checked={aggregate_options && aggregate_options["is_min_checked"]} />&nbsp; Min
                        </div>
                    )} */}

                    {/* {show_max && (
                        <div className={aggregate_options && aggregate_options["is_max_checked"] ? `active` : undefined} onClick={() => toggle_aggregate_option("is_max_checked")}>
                            <input className="accented" id="accented-dark" type="checkbox" checked={aggregate_options && aggregate_options["is_max_checked"]} />&nbsp; Max
                        </div>
                    )} */}

                    {show_avg && (
                        <div className={aggregate_options && aggregate_options["is_avg_checked"] ? `active` : undefined} onClick={() => toggle_aggregate_option("is_avg_checked")}>
                            {/* <input className="accented" id="accented-dark" type="checkbox" checked={aggregate_options && aggregate_options["is_avg_checked"]} />&nbsp;  */}
                            Avg
                        </div>
                    )}


                    {show_st_dev && (
                        <div className={aggregate_options && aggregate_options["is_st_dev_checked"] ? `active` : undefined} onClick={() => toggle_aggregate_option("is_st_dev_checked")}>
                            {/* <input type="checkbox" checked={aggregate_options && aggregate_options["is_st_dev_checked"]} />&nbsp; */}
                            Stdev
                        </div>
                    )}

                    {/* {show_trend_line && (
                        <div className={aggregate_options && aggregate_options["is_trend_line_checked"] ? `active` : undefined} onClick={() => toggle_aggregate_option("is_trend_line_checked")}>
                            Trend Line
                        </div>
                    )} */}
                    <div className={show_data_label ? `active` : undefined} onClick={() => set_show_data_label(!show_data_label)}>
                        {/* <input className="accented" id="accented-dark" type="checkbox" checked={show_data_label} />&nbsp;  */}
                        Data Label
                    </div>



                </React.Fragment>
            )}

        </YtdMtdWrapper>
        {show_aggregate_option && (
            <div className='data_story' onClick={() => open_auto_description()}>
                {/* <input className="accented" id="accented-dark" type="checkbox" checked={show_data_label} />&nbsp;  */}
                Data Story
            </div>
        )}

    </Wrapper>
    );
}

export default YtdMtdComponent;
