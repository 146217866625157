    module.exports = {
    SUCCESS: 'NF-200',
    FIELDS_MISSING: 'NF-399',
    ERRORS: {
        ERROR: 'NF-ERR-502',
        UNAUTHORIZED: 'NF-ERR-403',
        EMAIL_SENDING: 'NF-ERR-440',

        SUPPRESS: 'NF-NO-ERR-1',

        AUTHENTICATION: {
            DEFAULT: 'NF-AUTH-403.1',
            REQUIRED_FIELDS_MISSING: 'NF-AUTH-399.2',

            TOKEN: {
                MISSING: 'NF-TOK-290.1',
                EXPIRED: 'NF-TOK-290.2',
                CORRUPTED: 'NF-TOK-290.3',
                WRONG_REFRESH_TOKEN: 'NF-TOK-290.3.1',
                CANNOT_DECODE_TOLERANCE_TOKEN: 'NF-TOK-290.3.2',
                INVALID: 'NF-TOK-290.4'
            }
        },
        DB: {
            ALREADY_EXISTS: 'NF-DB-295.01',
        },

        FILE_UPLOAD: {
            FIELDS_NOT_FOUND: 'NF_FU_OB.1',
            DATES_NOT_SAME: 'NF_FU_DNS.1',
            DIMENSION_NOT_FOUND: 'NF_FU_DNF.1',
            FILE_NOT_FOUND: 'NF_FU_FNF.1',
            UNKNOWN: 'NF_FU_UNKWN.1'
        }
    },

};