import React, { useState, useEffect } from 'react';
import { Table, Wrapper, Thead, Tbody } from './spreadsheet.styled';
import { Button } from '../hoc/button';
import TextBox from '../hoc/textBox'


const Index = (props) => {

    const { spreadsheet, keys, save_function, close_function, requiredKey, topTextBoxHide, titleValue } = props;

    const [editable, setEditable] = useState(undefined)
    const [spreadsheetData, setSpreadsheetData] = useState([])
    const [title, setTitleValue] = useState('')


    /***
     * here wr are updateing the local spredsheet data.
     * and also we are adding one  mt question
     */


    useEffect(() => {

        if(titleValue){
            setTitleValue(titleValue)
        }
        if (spreadsheet && spreadsheet.length && spreadsheet.length > 0) {
            let _temp_questions = JSON.parse(JSON.stringify(spreadsheet));
            let _obj = {};
            keys && keys.length > 0 && keys.map((key) => {
                _obj[key] = ''
            })
            _temp_questions.push(_obj)
            setSpreadsheetData(_temp_questions)
        }

        else {
            let temp_spreadsheet = [];
            let _obj = {};
            keys && keys.length > 0 && keys.map((key) => {
                _obj[key] = ''
            })
            temp_spreadsheet.push(_obj)
            setSpreadsheetData(temp_spreadsheet)
        }

    }, [spreadsheet])






    const _onChangeColumnValue = (value, index, key) => {

        let clone_spreadSheetData = JSON.parse(JSON.stringify(spreadsheetData));

        let last_index = (clone_spreadSheetData && clone_spreadSheetData.length > 0 && clone_spreadSheetData.length - 1)

        if (index >= 0) {

            clone_spreadSheetData[index][key] = value;
        }

        if (index === last_index && clone_spreadSheetData[index][requiredKey] !== '') {
            let _obj = {};
            keys && keys.length > 0 && keys.map((key) => {
                _obj[key] = ''
            })
            clone_spreadSheetData.push(_obj)
        }

        if (index === (last_index - 1) && clone_spreadSheetData[index][requiredKey] === '') {
            clone_spreadSheetData.pop("")
        }
        setSpreadsheetData(clone_spreadSheetData)

    }




    /***
     * here we are formating data to sav format
     * after that we are calling props.save_pdf_questions() function  
     */

    const localSave = () => {

        let cloneSpreadSheetData = JSON.parse(JSON.stringify(spreadsheetData));

        let filterdMTItem = cloneSpreadSheetData && cloneSpreadSheetData.length > 0 && cloneSpreadSheetData.filter(item => {
            if (item[requiredKey] && item[requiredKey].length > 1) {
                return true;
            } else return false;
        })

        if (filterdMTItem.length > 0) {
            save_function(filterdMTItem, title)
        }

        else {
            alert("Please Enter name and questions")
        }
    }



    return (
        <Wrapper>
            {!topTextBoxHide && (
                <div style={{ marginBottom: 50 }}>
                    <TextBox
                        label="Name"
                        value={title ? title : undefined}
                        onChange={(e) => {
                            setTitleValue(e.target.value)
                        }}
                    />
                </div>
            )}



            <Table cellspacing={0} cellpadding={0}>
                <Thead>
                    <tr>
                        {keys && keys.length > 0 && keys.map((key) => {
                            return <td key={key}>{key}</td>
                        })}
                    </tr>
                </Thead>
                <Tbody>
                    {
                        spreadsheetData && spreadsheetData.length > 0 && spreadsheetData.map((item, index) => {
                            let keys = Object.keys(item)
                            return (
                                <tr>
                                    {keys && keys.length > 0 && keys.map((key) => {
                                        return (
                                            <td
                                                title={item[key]}
                                                style={{
                                                    borderBottomColor: ((editable && editable.id) === index && editable.key == key) ? "#308bc8" : undefined
                                                }}
                                                onClick={() => {
                                                    setEditable({
                                                        id: index,
                                                        key: key,
                                                    })
                                                }}>

                                                {((editable && editable.id) === index && editable.key == key) &&
                                                    <input autoFocus={true} type="text" value={item[key]} style={{ width: '100%' }} onChange={(e) => {
                                                        _onChangeColumnValue(e.target.value, index, key);
                                                    }} />
                                                }

                                                {item[key] ? (
                                                    <span style={{
                                                        opacity: ((editable && editable.id) === index && editable.key == key) ? 0 : 1,
                                                        transition: 'all 0.1s'
                                                    }}>{item[key]}</span>
                                                ) : <span style={{
                                                    opacity: ((editable && editable.id) === index && editable.key == key) ? 0 : 1,
                                                    transition: 'all 0.1s',
                                                    color: '#748891'
                                                }}>Enter value</span>}

                                            </td>
                                        )
                                    })}

                                </tr>
                            )
                        })
                    }
                </Tbody>

            </Table>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '5rem' }}>
                <Button
                    width="6rem"
                    mR="1rem"
                    primary={true}
                    onClick={() => {
                        localSave()
                    }}
                >Save</Button>
                <Button width="6rem" bgColor={'#e6e6e6'} onClick={close_function}>Cancel</Button>
            </div>

        </Wrapper>
    )
}

export default Index;
