import React from "react";

const MicrophoneIcon = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 256 256"
      preserveAspectRatio="xMidYMid meet"
    >
      <path d="m128 172a44.04978 44.04978 0 0 0 44-44v-64a44 44 0 0 0 -88 0v64a44.04978 44.04978 0 0 0 44 44zm-36-108a36 36 0 0 1 72 0v64a36 36 0 0 1 -72 0zm111.53613 72.43945a75.85035 75.85035 0 0 1 -71.53613 67.44934v28.11121a4 4 0 0 1 -8 0v-28.11121a75.85035 75.85035 0 0 1 -71.53613-67.44934 3.9998 3.9998 0 1 1 7.95117-.8789 68.00735 68.00735 0 0 0 135.16992 0 3.9998 3.9998 0 1 1 7.95117.8789z" />
    </svg>
  );
};

export default MicrophoneIcon;
