import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Cell from './cell';
import ColumnEditor from './columns.menu';
import { TABLE_CONFIG } from '../../shared-with-fe/keys';
import { Wrapper, TableWrapper, TableMain, TableCell, TableHeaderRow, GrandFooter } from './table.styled';
import { designs } from './style.config.js';
import { console_logger, get_required_attributes_for_chart_click_option, saveWhichTableDropDown } from '../../utils';
import { isCellNecessary, } from '../../utils/table.helper';
import { loadMore, scrollParentWindow, pushSortFunctionDataToReducer, setSortedDataInReducer, nextData, previousData, updateTableColumn, update_sort_order, update_width_additions } from '../../actions/table';
import { formatValueByDataType, is_date } from '../../utils';
import { themeObject, bind_theme_data_to_object, apply_color_sets_to_colors } from '../../theme';
import DropDownSvgDown from '../svg/dropDown';
import DropDownSvgUp from '../svg/dropDownUp';
import * as DataTypes from '../../shared-with-fe/data.types';
import * as _ from 'lodash';
import FooterAction from './bottom.tab';
import styled from 'styled-components';
import { showPopup } from '../../utils';
import * as enums from "../../utils/enums.js";

import { check_is_condition_matched, step_1_loop_through_rows_and_sort, s_p } from '../../utils/v1.1';
import { dispatch_updated_pivot_data, dispatch_updated_process_data, save_report_items, update_report_column_state, update_report_user_preferance } from '../../actions/report';
import { apply_filters, apply_link_filter, clear_all_filters, raise_drill_filter_cache } from '../../actions/filter';
import { set_report_to_refresh } from '../../actions/report';
import { constants } from '../../utils/constants'
import ThreeDotSvg from '../svg/threeDot';
import { sum } from 'lodash';
import TableRow from './shared/row';
import { change_column_order_v1 } from '../editor.shared/shared.pivot';
import chartFilterPopup from '../chart-filter-popup';
import { DownloadTableExcel, useDownloadExcel, downloadExcel } from 'react-export-table-to-excel';
import { beautify_cell_data_v1 } from './shared/helper';

const { CONDITIONS } = constants;



const COLUMN_DRAG_TYPE = {
    COLUMN_RESIZE: 'resize_column_v1',
    COLUMN_MOVE: 'move_column_v1',
}


const PREFERANCES = {
    COLUMNS_ORDER: "report_table_column_order_v1",
    COLUMNS_WIDTH: 'report_table_column_width_v1'
}

const BlankScreen = styled.div`
    width: 100%;
    height: ${props => props.height ? props.height + "px" : '100%'};
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #222;
	overflow: hidden;
`;

// const theme = themeObject;

let previous_scroll_value = 0;
let _preventEvent = false;
let contentLastScrollLeft = 0;
let contentLastScrollTop = 0;

let prev_res_id = undefined;

const __debounce = (method, delay, scroll_left, scroll_width) => {
    clearTimeout(method._tId);
    method._tId = setTimeout(function () {
        method(scroll_left, scroll_width);
    }, delay);
}

let rendered_for = 0;

const Table = props => {

    const {

        xDataKeys,
        yDataKeys,
        loading,
        insights,
        textColor,
        height,
        isReport,
        width,
        data,
        isDual,
        id,
        columnsData,
        columnMeta,
        columnsAligment,
        hide_grand_total,
        show_percentage_total,
        isDetails,
        theme_json_values,
        hide_table_header,
        tableDataMode,
        table_grand_total_columns,
        pivot_yac_info,
        width_additions = {},
        column_row_wise_data_types

    } = props;

    let sum_of_columns = 0;


    const { pivot_data_information, row_data_information } = props;

    const pivot_columns_state = pivot_data_information?.pivot_columns_state;
    const raw_data_column_state = row_data_information?.raw_data_column_state;

    const totalofYData = {};

    let tableStyle = {};
    const theme_key_to_use = insights ? 'insight' : 'report'
    let column_color = {};

    // const [width_additions, set_width_additions] = useState({});
    const [drag_values, set_drag_values] = useState({
        start_x: 0,
        current_x: 0,
        start_y: 0,
        current_y: 0
    });


    const [virtualization, set_virtualization] = useState({
        lower: 0,
        upper: 1000
    });




    // let search = props && props.history && props.history.location && props.history.location.search;
    const search = props?.history?.location?.search;

    const apply_theme = () => {
        const __theme__ = JSON.parse(JSON.stringify(themeObject));
        column_color = __theme__ && __theme__.insight && __theme__.insight.chart && __theme__.report.chart.chart_colors
        // after we have run this function, we will have the values from client theme loaded into the default theme
        if (__theme__?.[theme_key_to_use]?.['table']) {
            tableStyle = __theme__?.[theme_key_to_use]?.['table'];
            bind_theme_data_to_object(tableStyle, theme_json_values);
            apply_color_sets_to_colors(tableStyle, __theme__.colorset);
        } else {
            tableStyle = __theme__ && __theme__.report && __theme__.report.table
        }
    }
    apply_theme()



    useEffect(() => {
        if (!prev_res_id || (prev_res_id !== props.responseId)) {
            prev_res_id = props.responseId;
            // const clone_sort_order = sort_orders;
            const report_item = props.report_item || {};
            const sort_order_values = report_item?.sort_values || undefined;
            // clone_sort_order["pivot_data"] = sort_order_values
            if (sort_order_values) {
                props.update_sort_order(props.table_id_to_use, sort_order_values)
            }
            // console.log("aa gyaa bhai", report_item?.pivot_column_information)
            // if (report_item && report_item?.pivot_column_information) {
            // 	props.update_report_column_state(props.id, report_item?.pivot_column_information);
            // }
        }
    }, [props.responseId])



    /**************New Regin Code********************
     * saroj kr
     * 20 feb 2021
     * *********************************
     * 
     * 
     * ************************************************************* */

    // END NEW REGIN CODE

    const [localTableStyle, setLocalTableStyle] = useState(designs['design2']);
    const [tableWrapperWidth, setTableWrapperWidth] = useState(0);
    // const [sortOrder, setSortOrder] = useState(undefined);
    const [beautify_render, setBeautifyRender] = useState(1);
    const [final_table_header, set_final_table_header] = useState(undefined);


    const [show_column_editor, set_show_column_editor] = useState(undefined);


    const [force_render, set_force_render] = useState(1);

    const tableRef = useRef(null);
    const tableWrapperRef = useRef(null);
    const contentScroll = useRef(null);
    const tableMainRef = useRef(null);



    const [table_meta_information, set_table_meta_information] = useState({});


    /**
     * id: rpt_item_kjzfvqb8kjzfwzv7 ==> unique key: nf_kopvmt0gkopvn1v4, st: nf_kopvmt42kopvopt6, 293 merge_headers — index.js:82
     */

    useEffect(() => {
        let whichDesign = props && props.insights ? "design1" : 'design2';
        setLocalTableStyle(designs[whichDesign])
    }, [props])





    const handleScroll = (e) => {
        const el = e.target;
        if (el.scrollTop > 0 && el.scrollTop > previous_scroll_value && el.scrollTop < 100 && !props.insights) {
            props.scrollParentWindow(el.offsetTop - 80);

            setTimeout(() => {
                props.scrollParentWindow(-1);
            }, 200);
        }

        previous_scroll_value = el.scrollTop;

        if (el.scrollTop + el.clientHeight === el.scrollHeight) {
            infiniteScroll()
        }
    }



    const infiniteScroll = () => {
        const activeTableData = (data && Object.keys(data) && Object.keys(data).length > 0) ? { ...data } : {};
        const extraData = activeTableData ? activeTableData['extraData'] : [];
        if (extraData && extraData.length > 0) {
            // this && this.props && this.props.loadMore(tempId);
        }
    };



    const onContentScroll = e => {

        if (_preventEvent) {
            _preventEvent = false;
            return;
        }
        if (e.target && e.target.scrollTop !== contentLastScrollTop) {
            _preventEvent = true;
            contentLastScrollTop = e.target.scrollTop;
        }
        if (e.target && e.target.scrollLeft !== contentLastScrollLeft) {
            _preventEvent = true;

            if (tableRef?.current?.scrollLeft) {
                tableRef.current.scrollLeft = e.target.scrollLeft;
            }
            contentLastScrollLeft = e.target.scrollLeft;
        }
    };

    const onTopScroll = e => {

        const box = document.querySelector('#tableWrapper');
        const scrollbarWidth = box.offsetWidth - box.clientWidth;

        // console.log("scrollbarWidth", scrollbarWidth, )
        if (_preventEvent) {
            _preventEvent = false;
            return;
        }
        _preventEvent = true;
        contentScroll && contentScroll.current && (contentScroll.current.scrollLeft = e.target.scrollLeft);
    };


    const onTableScroll = e => {
        const scroll_left = e.target.scrollLeft;

        __debounce(update_header_when_scroll, 30, scroll_left, e.target.scrollWidth);

        if (_preventEvent) {
            _preventEvent = false;
            return;
        }
        _preventEvent = true;
        contentScroll && contentScroll.current && (contentScroll.current.scrollLeft = e.target.scrollLeft);
    };


    const merge_headers = (headers) => {


        if (!headers || (headers && headers.length === 0)) {
            return undefined;
        }

        const temp_headers = [...headers];


        if (temp_headers?.length > 2) {
            // go for neasted 
            temp_headers.map((__header, index) => {

                if (index === (temp_headers.length - 1)) {
                    // it is last 
                    const header_to_replace = __header ? [...__header] : [];

                    header_to_replace.forEach((h, index) => {
                        if (h.trim().length === 0) {
                            header_to_replace[index] = temp_headers[0][index];
                            // header_to_check_for_replacement[index] = "";
                        }
                    })

                    temp_headers[index] = {
                        other_info: header_to_replace,
                        data: header_to_replace,
                    };
                }
                else {

                    // this is first 
                    var count = {};
                    var __order__ = [];
                    __header.forEach(function (i) {
                        count[i + ''] = (count[i + ''] || 0) + 1;
                        if (__order__.indexOf(i + "") == -1) {
                            __order__.push(i + "")
                        }
                    });
                    temp_headers[index] = {
                        other_info: count,
                        data: __order__,
                    };
                }

            })

            return temp_headers;

        }
        else {


            const header_to_replace = temp_headers[temp_headers.length - 1];


            const header_to_check_for_replacement = temp_headers[0];

            header_to_replace.forEach((h, index) => {
                if (h.trim().length === 0) {
                    header_to_replace[index] = temp_headers[0][index];
                    header_to_check_for_replacement[index] = "";
                }
            })

            var count = {};
            var __order__ = [];

            header_to_check_for_replacement.forEach(function (i) {
                count[i + ''] = (count[i + ''] || 0) + 1;
                if (__order__.indexOf(i + "") == -1) {
                    __order__.push(i + "")
                }
            });


            temp_headers[temp_headers.length - 1] = {
                data: header_to_replace,
                other_info: undefined,
            };
            temp_headers[0] = {
                data: __order__,
                other_info: count,
            };

            return temp_headers;

        }


    };


    const update_header_when_scroll = (scroll_left, scroll_width) => {
        identify_columns_in_view(scroll_left, scroll_width);
        // update_table_headers(header_to_use)
    };


    const identify_columns_number_within_width = (width) => {

        let how_may_columns_visible = 0;
        let column_total_width = 0;

        // const temp_addition_headers = additionalHeaders?.[1];

        if (sorted_columns && sorted_columns?.length > 0) {
            for (let i = 0; i < sorted_columns.length; i++) {

                if (column_total_width <= width) {
                    const key = sorted_columns[i];
                    how_may_columns_visible++;
                    column_total_width += (parseInt(columnsMaxWidth?.[key]) || 0);
                } else break;
            }
        }

        return how_may_columns_visible;
    };


    const identify_columns_in_view = (scroll_value = 0, scroll_width = 0) => {

        let wrapper_width = width;
        wrapper_width = (wrapper_width * 1.15); // we added 15%;

        let upper = identify_columns_number_within_width(wrapper_width) || 0;
        let lower = 0;


        if (scroll_value > 0) {

            const new_scroll_value = scroll_value * (totalColumnWidth / (scroll_width));

            let new_columns = identify_columns_number_within_width((new_scroll_value));


            new_columns = Math.floor(new_columns);
            upper += new_columns;
            lower += new_columns;

            lower--;
            upper++;

            // console.log("====final===", lower, upper, "scroll_value", scroll_value, new_scroll_value, totalColumnWidth, scroll_width);

        }
        else {
            lower--;
            upper++;
        }


        set_virtualization({ lower, upper: upper + 2 });

        // const header_to_use = sorted_columns?.splice((lower < 0 ? 0 : lower), upper);
        // return header_to_use
    };



    useEffect(() => {
        if (tableRef && tableRef.current && !isDual) {
            tableRef.current.addEventListener('scroll', (e) => handleScroll(e));
        }

        return () => window.removeEventListener('scroll', handleScroll);
    }, [tableWrapperWidth]);

    useEffect(() => {
        if (tableWrapperRef.current) {
            let _width = tableWrapperRef.current.offsetWidth;
            setTableWrapperWidth(_width)
        }
    }, [])

    useEffect(() => {
        // console.log("sorted_columns", sorted_columns)
        remove_dublicate_name_form_header(sorted_columns)
    }, [data])


    useEffect(() => {
        setBeautifyRender(beautify_render + 1)
    }, [props.tbl_formatter])

    let activeTableData = (data && Object.keys(data) && Object.keys(data).length > 0) ? { ...data } : {};

    let tableData = activeTableData ? (activeTableData['sortedData']?.length > 0 ? JSON.parse(JSON.stringify(activeTableData['sortedData'])) : activeTableData['activeData']) : [];

    const tableTotalData = activeTableData?.["totalData"] || activeTableData?.['activeData']
    let columns = activeTableData ? activeTableData['columns'] : [];
    let editColumns = activeTableData ? activeTableData['editedColumns'] : [];

    const additionalHeaders = (activeTableData && activeTableData['additionalHeaders'] && activeTableData['additionalHeaders'].length > 1) ? merge_headers(activeTableData['additionalHeaders']) : [];
    const columnsMaxWidth = props.columnsWidth;
    const dateColumns = props.dateColumns;

    const nextButtonEnable = activeTableData && activeTableData.totalPages > activeTableData.currentIndex
    const prevButtonEnable = activeTableData && activeTableData.currentIndex > 1 ? true : false;
    let totalColumnWidth = 0;

    /********NEW REGIN FUNCTIONS HERE *******************
     * *************
     * by saroj
     * 20 feb 2022
     * 
     * 
     */

    const find_column_sort_order = (column_name) => {
        const table_sort_orders = props.sort_orders || [];
        for (let index = 0; index < table_sort_orders.length; index++) {
            const element = table_sort_orders[index];
            if (element && element.name === column_name) return element.order_by;
        }
    }


    /**
     * 
     * @param {*} info 
     */


    const sortFunction = (info) => {

        // sort types => asc|desc

        const column_name = info?.dataKey;
        const data_type = info?.type;

        // const clone_prev_sort_orders = sort_orders ? { ...sort_orders } : {};
        // if (!clone_prev_sort_orders[tableDataMode]) clone_prev_sort_orders[tableDataMode] = [];

        // const clone_sort_order = sort_orders && clone_prev_sort_orders[tableDataMode];

        const clone_sort_order = props.sort_orders ? JSON.parse(JSON.stringify(props.sort_orders)) : [];

        const find_column_prev_sort_order = (column_name) => {
            for (let index = 0; index < clone_sort_order.length; index++) {
                const element = clone_sort_order[index];
                if (element && element.name === column_name) {
                    return { index: index, order_by: element.order_by }
                }
            }
        }

        const column_prev_info = find_column_prev_sort_order(column_name);

        console.log("column_prev_info", column_prev_info)
        if (column_prev_info?.order_by) {

            // if our data already sorted then come here 
            if (column_prev_info.order_by === 'asc') {
                // switch to desc
                clone_sort_order[column_prev_info.index] = { name: column_name, order_by: 'desc' }
            }
            if (column_prev_info.order_by === 'desc') {
                // switch to none mean's delete that column from sort_orders 
                clone_sort_order.splice(column_prev_info.index, 1)
            }

        } else {
            // sort the data first time 
            clone_sort_order.push({ name: column_name, order_by: 'asc' })
        }

        console.log("sort options", clone_sort_order, props.columnMeta)

        const sorted_data = step_1_loop_through_rows_and_sort(tableTotalData, props.columnMeta, clone_sort_order);

        // console.log("table_sort", props)
        if (props.tableDataMode && props.tableDataMode === "pivot_data") {
            // if table view mode is pivot_data then update the chart too
            props.dispatch_updated_pivot_data(sorted_data, clone_sort_order, id)

            const temp_r = {
                // ...props.report_item,
                id: props.report_item.id,
                sort_values: clone_sort_order,
                modified: true
            }
            if (insights) props.save_report_items([temp_r])
        }

        if (props.tableDataMode && props.tableDataMode === "raw_data") {
            // if table view mode is row_data then we will only update the table_data
            props.pushSortFunctionDataToReducer(sorted_data, props.table_id_to_use)
            // props.dispatch_updated_process_data(sorted_data, clone_sort_order, id)
        }
        // clone_prev_sort_orders[tableDataMode] = clone_sort_order;
        props.update_sort_order(props.table_id_to_use, clone_sort_order)
        // set_sort_orders(clone_prev_sort_orders)
    }


    /***END NEW REGIN FUNCTION HERE */

    columnsMaxWidth && Object.keys(columnsMaxWidth).length > 0 && Object.keys(columnsMaxWidth).forEach(key => {
        totalColumnWidth = totalColumnWidth + columnsMaxWidth[key];
    });


    const tableDesignWhenNotInsightPadding = {
        td: {
            right: parseInt(designs['design2']['td']['paddingRight'].split('px')[0]),
            left: parseInt(designs['design2']['td']['paddingLeft'].split('px')[0])
        },
        th: {
            right: parseInt(designs['design2']['th']['paddingRight'].split('px')[0]),
            left: parseInt(designs['design2']['th']['paddingLeft'].split('px')[0])
        }
    }


    let temoPaddingToBeRemoved = {
        td: {
            left: 10,
            right: 10
        },
        th: {
            left: 10,
            right: 10
        }
    };



    let sorted_columns = editColumns && Object.keys(editColumns).length > 0 && Object.keys(editColumns)

    if (!props.need_to_switch_row_column) {
        sorted_columns = editColumns && Object.keys(editColumns).length > 0 && Object.keys(editColumns).sort((key1, key2) => {
            if (editColumns[key1].index < editColumns[key2].index) return -1;
            else if (editColumns[key1].index > editColumns[key2].index) return + 1;
            else return 0;
        })
    }


    const remove_dublicate_name_form_header = (sorted_columns) => {
        const final_header_to_use = (sorted_columns || [])?.map((column, index) => {
            return {
                display_key: column.split('_nfx_')?.[0],
                value_key: column
            }
        })
        set_final_table_header(final_header_to_use)
        update_header_when_scroll(0, 0)
    }





    const paddingToBeRemoved = insights ? temoPaddingToBeRemoved : tableDesignWhenNotInsightPadding;
    const showCell = true //isCellNecessary(width, columnsMaxWidth, totalColumnWidth);
    const isOverFlow = (tableRef && tableRef.current) && tableRef.current.clientHeight < tableRef.current.scrollHeight;
    // 

    const footer_section_height = props.restricted_view ? 0 : 40;
    const grand_total_columns = table_grand_total_columns && table_grand_total_columns["grand_total_sum_column"];
    const grand_total_percentage_column = table_grand_total_columns && table_grand_total_columns["grand_total_percentage_column"];
    const grand_total_height = !hide_grand_total && (grand_total_columns && Object.keys(grand_total_columns).length > 0) ? 0 : 0;
    const need_to_show_percentage_total = show_percentage_total && grand_total_percentage_column && Object.keys(grand_total_percentage_column).length > 0;

    const grand_total_final_height = grand_total_height + (need_to_show_percentage_total ? 0 : 0);

    const pagination_height = props.restricted_view && activeTableData && activeTableData.showPagination ? 50 : 0
    const init_table_height = (height - footer_section_height) - (grand_total_final_height + pagination_height);
    const tableHeight = init_table_height



    /**
     * 
     * @param {*} event 
     * @param {*} _index 
     * @param {*} where_to_drop_column_name 
     */
    const change_column_order = (event, _index, new_order_column) => {

        const drag_column_name = event.dataTransfer.getData("name");
        const drag_type = event.dataTransfer.getData("drag_type");

        if (drag_type === COLUMN_DRAG_TYPE.COLUMN_MOVE) {

            const clone_edited_columns = {} // editColumns ? JSON.parse(JSON.stringify(editColumns)) : {};

            const array_in_sort_format = editColumns && Object.keys(editColumns).length > 0 && Object.keys(editColumns).sort((key1, key2) => {
                if (editColumns[key1].index < editColumns[key2].index) return -1;
                else if (editColumns[key1].index > editColumns[key2].index) return + 1;
                else return 0;
            }) || [];

            let opration_array = array_in_sort_format ? [...array_in_sort_format] : [];


            const a_order = array_in_sort_format.indexOf(drag_column_name);
            const b_order = array_in_sort_format.indexOf(new_order_column);

            if (a_order > b_order) {

                opration_array.splice(a_order, 1)
                opration_array.splice(b_order, 1, drag_column_name, new_order_column)

            }

            if (b_order > a_order) {

                const __before__ = array_in_sort_format?.slice(0, b_order) || [];
                __before__.splice(a_order, 1)

                const __after__ = array_in_sort_format?.slice(b_order) || [];
                __after__.splice(0, 1, new_order_column, drag_column_name)

                opration_array = [...__before__, ...__after__]
            }

            opration_array?.forEach((e, index) => {
                clone_edited_columns[e] = {
                    ...editColumns[e],
                    index: index,
                }
            })
            // let's swipe the column order
            // if (clone_edited_columns[drag_column_name]) clone_edited_columns[drag_column_name].index = editColumns[new_order_column]?.index;
            // if (clone_edited_columns[new_order_column]) clone_edited_columns[new_order_column].index = editColumns[drag_column_name]?.index;

            props.updateTableColumn(clone_edited_columns, props.table_id_to_use);

            if (insights) {

                const report_item = {
                    id: id,
                    layout_info: {

                        column_width: width_additions,
                        columns_order: clone_edited_columns
                    },
                    modified: true
                }
                props.save_report_items([report_item])
            }

            const preferance_name = PREFERANCES.COLUMNS_ORDER + "_nfx_" + tableDataMode;

            if (insights || props.isSchedule) {

                props.update_report_user_preferance(undefined, props.report_item.id, preferance_name, clone_edited_columns, 'all')
            }

        }
    };


    const column_drag_drop = (event, column_name) => {

        // const clone_edited_columns = editColumns ? JSON.parse(JSON.stringify(editColumns)) : {};

        const new_width_additions = Object.assign({}, width_additions);
        const old_addition_values = new_width_additions[column_name] || 0;

        const diff_x = event.clientX - drag_values.start_x;

        new_width_additions[column_name] = old_addition_values + diff_x;

        props.update_width_additions(props.table_id_to_use, new_width_additions)

        // if (insights || props.isSchedule) {

        //     const report_item = {
        //         id: id,
        //         layout_info: {
        //             column_width: new_width_additions,
        //             columns_order: editColumns,
        //         },
        //         modified: true
        //     }
        //     props.save_report_items([report_item])
        // }


        const preferance_name = PREFERANCES.COLUMNS_WIDTH + "_nfx_" + tableDataMode

        if (insights || props.isSchedule) {
            props.update_report_user_preferance(undefined, props.report_item.id, preferance_name, new_width_additions, 'all')
        }
        set_drag_values({
            current_x: 0,
            current_y: 0,
            start_x: 0,
            start_y: 0
        });

    };



    /**
     * 
     * @param {*} filter_key 
     * @param {*} filter_value 
     * 
     */



    const open_chart_options = (event, data_key, payload, current_key) => {

        const options_required_data = get_required_attributes_for_chart_click_option(event, data_key, payload, xDataKeys, props.columnMeta, undefined, current_key, "table")

        const { left, top, width, popup_bg } = options_required_data;

        showPopup(undefined, undefined, enums.default.popupType.element, chartFilterPopup, {
            ...options_required_data,
            question: props.question,
            drill_down_values: props.drill_down_values,
            column_meta_details: props.columnMeta,
            b_history: props.history,
            _id: props.id,
            insight_id: props.insight_id,
            insights: props.insights,
            containerWidth: width,
            od_report_id: props.od_report_id,
            od_report: props.od_report,
            reporting_db_info_id: props.reporting_db_info_id,
            hide_drill_down: !props.is_drill_down_applicable


        },
            top + "px",
            left + "px",
            popup_bg
        )

    }
    const apply_local_cell_clicked_filter = (event, filter_key, db_data_type, filter_value, __value__key) => {

        // if (props?.report_item?.reporting_db_info_id && props?.report_item?.reporting_db_info_id.indexOf("__nf__db__cached__") == -1) return false;

        const column_name_to_use_v1 = filter_key + "__nfx__db__" + db_data_type; // column data data type with column name

        const filter_data_to_store = {};

        if (column_name_to_use_v1 && filter_key && db_data_type) {
            filter_data_to_store[column_name_to_use_v1] = {
                key: __value__key,
                value: filter_value
            }
        }


        const popup_width = ((props?.drill_down_type && props?.drill_down_report) ? 490 : 310);
        let popup_top = event?.clientY - 100
        const popup_left = event?.clientX - (popup_width / 2);
        const popup_left_to_use = popup_left < 0 ? 0 : popup_left;

        console.log("console", filter_data_to_store,)
        showPopup(undefined, undefined, enums.default.popupType.element, chartFilterPopup, {
            question: props.question,
            dimension: __value__key,
            dimension_value: filter_value,
            comparison: props.comparison,
            comparison_value: props.comparison_value,
            drill_down_values: props.drill_down_values,
            drill_down_report: props.drill_down_report,
            drill_down_type: props.drill_down_type,
            // ...info_wrapper,
            filter_data: filter_data_to_store,
            b_history: props.history,
            _id: props.id,
            insight_id: props.insight_id,
            insights: props.insights,
            containerWidth: popup_width,
            hide_drill_down: true
        },
            popup_top + "px",
            popup_left_to_use + "px",
            "#0000"
        )
    }



    const is_this_cell_clicked = (key, db_key, value, index) => {

        const link_filter_clone = props?.link_filter?.filter;
        const db_column = props.columnMeta?.[key]?.db_column;
        const db_data_type = props.columnMeta?.[key]?.db_data_type || 5;

        const use_key = db_key + "__nfx__db__" + db_data_type;

        if (link_filter_clone && link_filter_clone[use_key] && link_filter_clone?.[use_key]?.indexOf(value) > -1) return true;
        else return false;
    }



    /**
     * save_and_apply_column_meta
     */
    const save_and_apply_column_meta = (key, meta_data) => {

        // const column_data_to_use = (tableDataMode === 'pivot_data') ? pivot_columns_state : raw_data_column_state

        const clone_meta = meta_data ? JSON.parse(JSON.stringify(meta_data)) : {};

        // console.log("clone_meta", clone_meta, tableDataMode);

        props.update_report_column_state(props.id, clone_meta, tableDataMode,);

        const preferance_name = (tableDataMode === 'pivot_data') ? 'user_column_preferance' : 'user_column_preferance_raw_data';

        if (insights || props.isSchedule) {

            const __id__to_use = props?.is_details_window ? (props.reportId + "__details") : props.report_item.id;

            props.update_report_user_preferance(undefined, __id__to_use, preferance_name, meta_data, "all")

        }
    }





    const open_column_menu_option = (event, column_name, data_type) => {

        const column_data_to_use = (tableDataMode === 'pivot_data') ? pivot_columns_state : raw_data_column_state;

        const popup_width = 220;
        const window_height = window.innerHeight;
        const window_width = window.innerWidth;
        const element_position = event?.clientY + 300;
        const popup_top_margin = window_height - element_position < 100 ? 100 : 10;
        const popup_top = event?.clientY + 20
        let popup_left = event?.clientX + 20;

        if (popup_left < 0) {
            popup_left = 10;
        }
        if ((popup_left + popup_width + 30) > window_width) {
            popup_left = (window_width - popup_width) - 30
        }

        showPopup(undefined, undefined, enums.default.popupType.element,

            ColumnEditor,
            {
                save: (meta) => {
                    save_and_apply_column_meta(column_name, meta)
                },
                column_key: column_name,
                column_data: column_data_to_use,
                dataType: data_type,
                popup_left: popup_left,
                tableDataMode: tableDataMode


            },
            popup_top + "px",
            popup_left + "px",
            "#00000000"
        )
    }

    // raw_data_column_state
    // raw_data_column_state 

    const is_show_three_dots = insights ? props.view_mode === 'expanded' ? true : false : true;

    const column_prev_value = {};




    const mergeTableData = (data, __key__ = "Month", header) => {

        let currentService = data[0][__key__];
        let currentRowSpan = 1;

        let __headers__ = header || Object.keys(data[0])

        // const row_span_data = {};
        for (let i = 0; i < data.length; i++) {

            const item = data[i];

            const condition_formating_data = beautify_cell_data_v1(props.tbl_formatter, sorted_columns, item, i);

            // console.log("condition_formating_data", condition_formating_data)

            const beautify_data_beging = (condition_formating_data && Object.keys(condition_formating_data) && Object.keys(condition_formating_data).length > 0) ? Object.keys(condition_formating_data)[0] : undefined;
            const is_row_level = beautify_data_beging && condition_formating_data && condition_formating_data[beautify_data_beging].row_level;
            const row_level_style = (beautify_data_beging && condition_formating_data && condition_formating_data[beautify_data_beging].row_level) ? condition_formating_data[beautify_data_beging].data : undefined;

            // 'let's format data by data type
            data[i].isRowLevel = is_row_level;
            data[i].rowLevelStyle = row_level_style;



            __headers__.forEach((key) => {

                const cell_formatting = columnsAligment?.[key];
                const cell_data_type = cell_formatting?.['type']
                const currency_type = cell_formatting?.['currency_type'];
                const num_format_type = cell_formatting?.['num_format_type'];
                const use_decimal = cell_formatting?.['use_decimal'];
                const use_percent = cell_formatting?.['use_percent'];
                const other_format_config = cell_formatting?.["other_format_config"];
                const finalDatatoShow = item[key];

                const afterFormatted = formatValueByDataType(finalDatatoShow, cell_data_type, 'table', undefined, undefined, undefined, currency_type, num_format_type, use_decimal, use_percent, other_format_config);

                data[i][key] = {
                    value: (afterFormatted),
                    style: condition_formating_data?.[key]?.data
                }

            })

            if (i > 0) {
                if (item[__key__] === currentService) {
                    currentRowSpan += 1;
                } else {
                    // row_span_data[i - currentRowSpan] = currentRowSpan
                    data[i - currentRowSpan].rowSpan = currentRowSpan;
                    currentService = item[__key__];
                    currentRowSpan = 1;
                }
            }
        }
        data[data.length - currentRowSpan].rowSpan = currentRowSpan;
        return data;
    }




    const __rowSpanKey = final_table_header?.[0]?.value_key;




    useEffect(() => {

        if (props.download_excel_cache === id || props.download_excel_cache === props.mainReportId) {
            const tableDataToDownloadInExcel = tableTotalData?.length > 0 && mergeTableData(JSON.parse(JSON.stringify(tableTotalData), true), __rowSpanKey, (final_table_header || []).map((v) => v.value_key)) || [];

            // console.log("yes downloading.....",  final_table_header, additionalHeaders, props)
            // let's download the excel file
            set_force_render(force_render + 1)
            setTimeout(() => {
                downloadExcel({
                    fileName: (props?.reportTitle || "data").toLocaleLowerCase(),
                    tablePayload: {
                        "header": final_table_header?.map((v) => v.value_key) || [],
                        "body": tableDataToDownloadInExcel,
                        "rowSpanKey": __rowSpanKey || "Department",
                        "additionalHeaders": additionalHeaders,
                        "yDataLen": undefined //props?.yDataKeys?.length,
                    }
                })
            }, 1000)
        }
    }, [props.download_excel_cache]);


    const get_title = (str, header_len, y_axis_len) => {
        if (!str) return str;
        const str_array = str.split("_nfx_");
        const lastTwoElements = str_array.slice(-2).join(' ');
        return y_axis_len < 2 ? lastTwoElements : str_array.length > 2 && header_len < 3 ? lastTwoElements : str_array.pop();
    };


    // console.log("virtualization", virtualization)

    return (
        <React.Fragment>
            {!loading && beautify_render && force_render &&
                <Wrapper
                    ref={tableWrapperRef}
                    style={{
                        height: height ? `${height}px` : 'inherit',
                        width: width ? `${width}px` : '',
                    }}
                >

                    {(tableWrapperWidth > 0) && tableData ? (
                        <TableWrapper
                            ref={tableRef}
                            id='tableWrapper'
                            color={textColor}
                            style={{
                                height: `${tableHeight}px`,
                                maxHeight: tableHeight + "px",
                                width: width ? `${width}px` : '',
                                minHeight: isDetails ? '400px' : undefined,
                            }}
                            insights={props.insights}
                            // onScroll={onTopScroll}
                            onScroll={onTableScroll}
                        >




                            <TableMain
                                hide_border={props.hide_border}
                                display="inline-table"
                                {...localTableStyle.table}
                                id='tableReport'
                                ref={tableMainRef}

                                style={{
                                    width: columnsData?.length > 10 ? '100%' : '100%',
                                    borderCollapse: 'collapse',
                                }}
                            >
                                {!hide_table_header && <thead>
                                    {
                                        (additionalHeaders?.length > 0) ? (additionalHeaders || []).map((headerRow, header_index) => {

                                            const header_row_array = headerRow?.data || [];
                                            const other_info = headerRow?.other_info || {};;
                                            if (!Array.isArray(headerRow) && typeof headerRow === 'object') {

                                                // header_row_array.push(...Object.keys(headerRow));
                                                // lets sort
                                                // header_row_array.sort((a, b) => {
                                                //     if (a.length === 0) {
                                                //         return 1;
                                                //     } else return 1000;
                                                // });
                                            }
                                            else if (!Array.isArray(headerRow) && typeof headerRow === 'object') {
                                                // header_row_array.push(...Object.keys(headerRow));
                                            }
                                            else {
                                                // header_row_array.push(...headerRow);
                                            }

                                            return (
                                                <tr className='table_header' id="table_header" key={header_index} bgolor='#87AFC6'>
                                                    <React.Fragment>

                                                        {header_index === 0 && header_row_array?.length > 0 && header_row_array?.map((data, index) => {

                                                            const disable_clicked_for_keys = ["Total", "% Share"]
                                                            const pivot_data_column = props?.pivot_data_columns?.length > 0 ? props?.pivot_data_columns[0] : undefined;
                                                            const filter_key = pivot_data_column && columnMeta?.[pivot_data_column]?.db_column || undefined;
                                                            const value_to_use_in_filter = data;
                                                            const is_this_cell_in_filter = is_this_cell_clicked(filter_key, value_to_use_in_filter);
                                                            const is_it_duplicate = final_table_header[index]?.value_key?.toLocaleLowerCase() === data.toLocaleLowerCase();

                                                            return (
                                                                <TableCell
                                                                    colSpan={other_info?.[data]}
                                                                    _style={tableStyle?.header?.column}
                                                                    force_bg_color={is_this_cell_in_filter ? "#e7edef" : undefined}
                                                                    position={'sticky!important'}
                                                                    zIndex={"1"}
                                                                    top={"-1px"}
                                                                    color={data && data.length > 0 ? "#000" : '#000'}
                                                                    height={'2rem'}
                                                                    key={data + '-' + index}
                                                                    className='table_cell_head'
                                                                    border={"0.25px solid #fff"}
                                                                    style={{
                                                                        position: 'relative',
                                                                    }}
                                                                    title={data + "index" + index + "kk" + is_it_duplicate}
                                                                    onClick={() => {
                                                                        // if (filter_key && filter_key.length > 0 && disable_clicked_for_keys.indexOf(data) === -1 && index > 0) {
                                                                        // 	apply_local_cell_clicked_filter(filter_key, value_to_use_in_filter)
                                                                        // }
                                                                    }}
                                                                >
                                                                    <div className="th_data">{is_it_duplicate ? '' : data}</div>

                                                                </TableCell>
                                                            )
                                                        })}

                                                        {header_index === (additionalHeaders.length - 1) && final_table_header?.length > 0 && final_table_header.map((d, index) => {

                                                            const value_key = d.value_key;

                                                            const data = get_title(value_key, additionalHeaders?.length, props?.pivot_yac_info?.length) //?.split("_nfx_")?.length > 1 ? value_key?.split("_nfx_")[value_key?.split("_nfx_").length - 1] : value_key;

                                                            if (!data) return;

                                                            let whichWidth = columnsMaxWidth[value_key] || 0;

                                                            whichWidth = whichWidth + (width_additions[value_key] || 0);

                                                            const aligmentOfColumn = columnsAligment?.[value_key];
                                                            const alignment = aligmentOfColumn?.alignment;
                                                            const type = aligmentOfColumn?.type;


                                                            const column_sort_order = find_column_sort_order(value_key);
                                                            const color_up_icon = (column_sort_order === 'asc') ? "#222" : (tableStyle?.header?.column?.font?.color || '#838080');
                                                            const color_up_down = (column_sort_order === 'desc') ? "#222" : (tableStyle?.header?.column?.font?.color || '#838080');

                                                            return (
                                                                <TableCell
                                                                    _style={tableStyle?.header?.column}
                                                                    position={'sticky!important'}
                                                                    zIndex={"1"}
                                                                    top={"-1px"} key={data + '-' + index}
                                                                    className='table_cell_head'
                                                                    style={{
                                                                        position: 'relative',
                                                                    }}

                                                                    // top={additionalHeaders.length > 0 ? "1.9rem" : undefined}
                                                                    width={(parseInt(whichWidth) + 22) + "px"}
                                                                    title={value_key?.split("_nfx_")?.join("__")}
                                                                    border={"0.25px solid #fff"}
                                                                    onClick={() => {
                                                                        sortFunction({
                                                                            dataKey: value_key,
                                                                            type: columnsAligment?.[value_key]?.type ? columnsAligment[value_key].type : undefined
                                                                        })
                                                                    }}


                                                                >
                                                                    <div style={{ width: (parseInt(whichWidth) + 21) - (index === 0 ? 10 : 0) + "px", paddingLeft: index === 0 ? '10px' : undefined }}>
                                                                        <div style={{ textAlign: alignment ? alignment : undefined, width: '100%' }} className="th_data">{data}</div>

                                                                        {((columnsAligment?.[value_key]?.type) || value_key === 'Campaign Name') && (
                                                                            <div
                                                                                title={'Sort by ' + value_key}
                                                                                className="dropdown_icon">
                                                                                <DropDownSvgUp size={".5rem"} height=".5rem" color={color_up_icon} />
                                                                                <DropDownSvgDown size={".5rem"} height=".5rem" color={color_up_down} />
                                                                            </div>
                                                                        )}

                                                                        {is_show_three_dots && (
                                                                            <div
                                                                                style={{ position: 'absolute', right: '0px', top: '50%', width: '1rem', height: '1rem', margin: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', transform: 'translate(0px,-50%)' }}
                                                                                // id={menu_option_id}
                                                                                onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    e.stopPropagation()
                                                                                    // const c_name_to_use = value_key?.split("_nfx_")?.length > 1 ? value_key?.split("_nfx_")[1] : value_key
                                                                                    // const data_type = columnMeta?.[c_name_to_use]?.['data_type']
                                                                                    open_column_menu_option(e, value_key, type)
                                                                                }}
                                                                            >
                                                                                <ThreeDotSvg size=".7rem" height=".7rem" color="#fff" />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    {/* {orignal_column_key} */}
                                                                </TableCell>
                                                            )
                                                        })}

                                                        {additionalHeaders?.length > 2 && header_index > 0 && header_index < (additionalHeaders.length - 1) && header_row_array.map((data, index) => {

                                                            const disable_clicked_for_keys = ["Total", "% Share"]
                                                            const pivot_data_column = props?.pivot_data_columns?.length > 0 ? props?.pivot_data_columns[0] : undefined;
                                                            const filter_key = pivot_data_column && columnMeta?.[pivot_data_column]?.db_column || undefined;
                                                            const value_to_use_in_filter = data;
                                                            const is_this_cell_in_filter = is_this_cell_clicked(filter_key, value_to_use_in_filter)

                                                            const d = (data?.length > 0 && data.split('_nfx_')?.length > 0) ? data.split('_nfx_')[data.split("_nfx_").length - 1] : data;


                                                            return (
                                                                <TableCell
                                                                    colSpan={other_info?.[data]}
                                                                    _style={tableStyle?.header?.column}
                                                                    force_bg_color={is_this_cell_in_filter ? "#e7edef" : undefined}
                                                                    position={'sticky!important'}
                                                                    zIndex={"1"}
                                                                    top={"-1px"}
                                                                    color={data && data.length > 0 ? "#000" : '#000'}
                                                                    height={'2rem'}
                                                                    key={data + '-' + index}
                                                                    className='table_cell_head'
                                                                    border={"0.25px solid #fff"}
                                                                    style={{
                                                                        position: 'relative',
                                                                    }}
                                                                    title={data}
                                                                    onClick={() => {
                                                                        // if (filter_key && filter_key.length > 0 && disable_clicked_for_keys.indexOf(data) === -1 && index > 0) {
                                                                        // 	apply_local_cell_clicked_filter(filter_key, value_to_use_in_filter)
                                                                        // }
                                                                    }}
                                                                >
                                                                    <div className="th_data">{d}</div>

                                                                </TableCell>
                                                            )
                                                        })}

                                                    </React.Fragment>


                                                    {
                                                        // showCell && !insights &&
                                                        showCell &&
                                                        <TableCell
                                                            position={'sticky!important'}
                                                            zIndex={"1"}
                                                            top={"-1px"}
                                                            _style={tableStyle && tableStyle.header && tableStyle.header.column}
                                                            key={'extra'}
                                                            id="extra"
                                                            className='table_cell_head'
                                                            style={{
                                                                position: 'relative',
                                                                padding: insights ? "0px" : undefined,
                                                            }}
                                                            width='100%'
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                saveWhichTableDropDown(undefined);
                                                                // sortData(tableData, -1, data)
                                                            }}
                                                        />
                                                    }


                                                </tr>
                                            )
                                        })
                                            :

                                            <tr className='table_header' id="table_header" bgcolor='#87AFC6'>

                                                {final_table_header && final_table_header.length > 0 && final_table_header.map((data, index) => {

                                                    if (data.value_key && editColumns && editColumns[data.value_key] && editColumns[data.value_key].show) {

                                                        let whichWidth = columnsMaxWidth && (columnsMaxWidth[data.value_key] - 22)
                                                        whichWidth = whichWidth + (width_additions[data.value_key] || 0);

                                                        let aligmentOfColumn = columnsAligment && columnsAligment[data.value_key];
                                                        let alignment = aligmentOfColumn?.alignment;
                                                        let type = aligmentOfColumn?.type;
                                                        let display_value = data?.display_key;


                                                        if (display_value && display_value.length > 0 && is_date(display_value) && (type === 4 || type === 4.1)) {
                                                            display_value = formatValueByDataType(display_value, type)
                                                        }

                                                        const column_sort_order = find_column_sort_order(data.value_key);
                                                        const color_up_icon = (column_sort_order === 'asc') ? "#222" : (tableStyle?.header?.column?.font?.color || '#838080');
                                                        const color_up_down = (column_sort_order === 'desc') ? "#222" : (tableStyle?.header?.column?.font?.color || '#838080');
                                                        const menu_option_id = "menu_option_rand_id" + Math.random(100)
                                                        return (
                                                            <TableCell
                                                                _style={tableStyle?.header?.column}
                                                                position={'sticky!important'}
                                                                zIndex={"1"}
                                                                top={"-1px"}
                                                                key={data.value_key + '-' + index}
                                                                className='table_cell_head'
                                                                style={{
                                                                    position: 'relative',
                                                                }}

                                                                title={data.value_key}
                                                                draggable={true}

                                                                onDragStart={(event) => {

                                                                    event.stopPropagation();
                                                                    event.dataTransfer.setData("index", index);
                                                                    event.dataTransfer.setData("name", data.value_key);
                                                                    event.dataTransfer.setData("drag_type", COLUMN_DRAG_TYPE.COLUMN_MOVE);

                                                                    event.dataTransfer.effectAllowed = "move"

                                                                }}
                                                                data_tag={index}
                                                                onDragOver={(e) => e.preventDefault()}
                                                                onDrop={(event) => {
                                                                    event.preventDefault()
                                                                    event.stopPropagation()
                                                                    change_column_order(event, index, data.value_key)
                                                                }}
                                                            >
                                                                <div
                                                                    className="th_data"
                                                                    style={{
                                                                        width: whichWidth,
                                                                        textAlign: alignment ? alignment : "left",
                                                                    }}

                                                                >
                                                                    {display_value}
                                                                </div>
                                                                <div style={{
                                                                    height: '100%',
                                                                    minHeight: '30px',
                                                                    borderRight: '1px solid transparent',
                                                                    cursor: 'col-resize',
                                                                    width: '3px',
                                                                    position: 'absolute',
                                                                    right: 0,
                                                                    top: 0,
                                                                    // backgroundColor: 'green'
                                                                }}
                                                                    // draggable={true}
                                                                    draggable="true"
                                                                    onDragStart={(event) => {

                                                                        event.stopPropagation();
                                                                        event.dataTransfer.setData("name", data.value_key);
                                                                        event.dataTransfer.setData("drag_type", COLUMN_DRAG_TYPE.COLUMN_RESIZE);

                                                                        set_drag_values({
                                                                            start_x: event.clientX,
                                                                            start_y: event.clientY
                                                                        });
                                                                    }}

                                                                    onDragEnd={(event) => column_drag_drop(event, data.value_key)}
                                                                >

                                                                </div>

                                                                {columnsAligment?.[data.value_key]?.type && (
                                                                    <div
                                                                        title={'Sort by ' + data.value_key}
                                                                        className="dropdown_icon"
                                                                        onClick={(event) => {
                                                                            event.stopPropagation();
                                                                            event.preventDefault();
                                                                            saveWhichTableDropDown(undefined);
                                                                            sortFunction({
                                                                                dataKey: data.value_key,
                                                                                type: columnsAligment?.[data.value_key]?.type
                                                                            })
                                                                        }}
                                                                    >
                                                                        <DropDownSvgUp size={".5rem"} height=".5rem" color={color_up_icon} />
                                                                        <DropDownSvgDown size={".5rem"} height=".5rem" color={color_up_down} />
                                                                    </div>
                                                                )}

                                                                {is_show_three_dots && (
                                                                    <div
                                                                        style={{ position: 'absolute', right: '0px', top: '50%', width: '1rem', height: '1rem', margin: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', transform: 'translate(0px,-50%)' }}
                                                                        // id={menu_option_id}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation()

                                                                            const c_name_to_use = data.value_key?.split("_nfx_")?.length > 1 ? data.value_key?.split("_nfx_")[1] : data.value_key
                                                                            const data_type = columnsAligment?.[data.value_key]?.['type'] || columnsAligment?.[c_name_to_use]?.['type'] || columnMeta?.[c_name_to_use]?.['data_type'];
                                                                            open_column_menu_option(e, data.value_key, data_type)

                                                                        }}
                                                                    >
                                                                        <ThreeDotSvg size=".7rem" height=".7rem" color="#fff" />
                                                                    </div>
                                                                )}
                                                            </TableCell>
                                                        )
                                                    }
                                                })}


                                                {
                                                    // showCell && !insights &&
                                                    showCell &&
                                                    <TableCell
                                                        position={'sticky!important'}
                                                        zIndex={"1"}
                                                        top={"-1px"}
                                                        _style={tableStyle?.header?.column}
                                                        key={'extra'}
                                                        id="extra"
                                                        className='table_cell_head'
                                                        style={{
                                                            position: 'relative',
                                                            padding: insights ? "0px" : undefined,
                                                        }}
                                                        width='100%'
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            saveWhichTableDropDown(undefined);
                                                        }}
                                                    // title={columnsMaxWidth && columnsMaxWidth[data]}
                                                    />
                                                }
                                            </tr>
                                    }
                                </thead>}


                                {!hide_grand_total && (
                                    <tfoot className='footer_g_total'>
                                        {!hide_grand_total && (
                                            <React.Fragment onScroll={onContentScroll}>

                                                {table_grand_total_columns && Object.keys(table_grand_total_columns).length > 0 && yDataKeys && yDataKeys.length > 0 && (
                                                    <React.Fragment>
                                                        {Object.keys(table_grand_total_columns).map((g_key) => {

                                                            const grand_totals = table_grand_total_columns[g_key];
                                                            const grand_title = g_key === "grand_total_percentage_column" ? "%" : "Total";
                                                            const need_to_render = (g_key === 'grand_total_percentage_column') ? need_to_show_percentage_total : true;


                                                            if (need_to_render && grand_totals && Object.keys(grand_totals).length > 0) {
                                                                return (
                                                                    <tr key={"grand" + g_key}>

                                                                        {final_table_header && final_table_header.length > 0 && final_table_header.map((key, index) => {

                                                                            const __value__key = key.value_key

                                                                            if (__value__key && editColumns && editColumns[__value__key] && editColumns[__value__key].show) {

                                                                                const column_formating_meta = g_key !== "grand_total_percentage_column" && columnsAligment?.[__value__key];

                                                                                const currency_type = column_formating_meta?.['currency_type'];
                                                                                const num_format_type = column_formating_meta?.['num_format_type'];
                                                                                const use_decimal = column_formating_meta?.['use_decimal'];
                                                                                const use_percent = column_formating_meta?.['use_percent'];
                                                                                const d_type = column_formating_meta?.['type'];
                                                                                const aligment = column_formating_meta?.['alignment'];
                                                                                const data_type = column_formating_meta?.['data_type'];

                                                                                // console.log("data_type", column_formating_meta)
                                                                                // if (data_type === DataTypes.percent || d_type === DataTypes.percent) {
                                                                                //     return
                                                                                // }

                                                                                const to_much_width_need_to_subtract = (additionalHeaders?.length > 1) ? 0 : 22;

                                                                                let whichWidth = columnsMaxWidth && (columnsMaxWidth[__value__key] - to_much_width_need_to_subtract);

                                                                                whichWidth = whichWidth + (width_additions[__value__key] || 0);


                                                                                if (d_type && grand_totals[__value__key] !== undefined) {

                                                                                    var pre_value = grand_totals[__value__key];
                                                                                    var hide_grand_total = undefined

                                                                                    if (tableDataMode === "pivot_data") {
                                                                                        // console.log("pivot_columns_state", pivot_columns_state)
                                                                                        // let's call the min max ag logic here 
                                                                                        const what_is_the_type = pivot_columns_state?.[__value__key]?.grand_total_type;
                                                                                        hide_grand_total = pivot_columns_state?.[__value__key]?.hide_grand_total;
                                                                                        // console.log("what_is_the_type", what_is_the_type, pivot_columns_state, __value__key, props?.yac_totals)
                                                                                        if (what_is_the_type === "avg" && props?.yac_totals?.[__value__key]?.['avg']) {
                                                                                            pre_value = props?.yac_totals?.[__value__key]?.['avg']
                                                                                        }
                                                                                        if (what_is_the_type === "min" && props?.yac_totals?.[__value__key]?.['min']) {
                                                                                            pre_value = props?.yac_totals?.[__value__key]?.['min']

                                                                                        }
                                                                                        if (what_is_the_type === "max" && props?.yac_totals?.[__value__key]?.['max']) {
                                                                                            pre_value = props?.yac_totals?.[__value__key]?.['max']

                                                                                        }
                                                                                    }

                                                                                    const value_to_print = formatValueByDataType(pre_value, d_type, 'table', undefined, undefined, undefined, currency_type, num_format_type, use_decimal)

                                                                                    return (
                                                                                        <td
                                                                                            style={{
                                                                                                textAlign: aligment ? aligment : 'left',
                                                                                                background: '#fff',
                                                                                                padding: '0px 10px',
                                                                                            }}
                                                                                        >
                                                                                            <div style={{
                                                                                                textAlign: aligment ? aligment : 'left',
                                                                                                minWidth: (whichWidth) + "px",
                                                                                                display: d_type == DataTypes.currency ? 'flex' : undefined,
                                                                                                justifyContent: d_type == DataTypes.currency ? "space-between" : undefined,
                                                                                                alignItems: 'center',
                                                                                            }}>
                                                                                                {!hide_grand_total && (
                                                                                                    <>
                                                                                                        {d_type == DataTypes.currency ?
                                                                                                            value_to_print && value_to_print.length > 0 && value_to_print.split('  ').map((__value__key) => {
                                                                                                                return <span>{__value__key}</span>
                                                                                                            }) : (data_type === DataTypes.percent || d_type === DataTypes.percent) ? " " : formatValueByDataType(pre_value, d_type, 'table', undefined, __value__key, undefined, currency_type, num_format_type, use_decimal, use_percent)}</>
                                                                                                )}

                                                                                            </div>
                                                                                        </td>
                                                                                    )
                                                                                } return <td
                                                                                    //  title={columnsMaxWidth && columnsMaxWidth[__value__key]}
                                                                                    style={{
                                                                                        padding: '0px 10px',
                                                                                        background: '#fff',
                                                                                        textAlign: "left"

                                                                                    }}>
                                                                                    <div style={{
                                                                                        minWidth: (whichWidth) + "px",
                                                                                        // whiteSpace: "nowrap"
                                                                                    }}>
                                                                                        {/* {__value__key} */}
                                                                                        {index === 0 ? grand_title : null}
                                                                                    </div>
                                                                                </td>
                                                                            }
                                                                        })}
                                                                        <td style={{ padding: '10px', backgroundColor: '#fff' }}></td>
                                                                    </tr>
                                                                )
                                                            } else return null
                                                        })}
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </tfoot>
                                )}

                                <tbody>
                                    {
                                        xDataKeys && tableData && tableData.length > 0 &&
                                        tableData.map((row, index) => {
                                            return (
                                                <TableRow
                                                    id={props.id}
                                                    rowData={JSON.parse(JSON.stringify(row))}
                                                    rowIndex={index}
                                                    pivot_yac_info={pivot_yac_info}
                                                    totalTableHeaders={final_table_header}
                                                    virtualization={virtualization}
                                                    xDataKeys={xDataKeys}
                                                    yDataKeys={yDataKeys}
                                                    showCell={showCell}
                                                    insights={insights}
                                                    tableStyle={tableStyle}
                                                    apply_local_cell_clicked_filter={apply_local_cell_clicked_filter}
                                                    open_chart_options={open_chart_options}
                                                    is_this_cell_clicked={is_this_cell_clicked}
                                                    columnMeta={columnMeta}
                                                    tableData={tableData}
                                                    tableDataMode={tableDataMode}
                                                    columnsAligment={columnsAligment}
                                                    totalofYData={totalofYData}
                                                    editColumns={editColumns}
                                                    sorted_columns={sorted_columns}
                                                    columnsMaxWidth={columnsMaxWidth}
                                                    width_additions={width_additions}
                                                    column_prev_value={column_prev_value}
                                                    column_row_wise_data_types={column_row_wise_data_types}
                                                // __rowSpanKey={__rowSpanKey}
                                                // need_to_use_virtualization={(props?.download_excel_cache === id)}

                                                />
                                            )
                                        })}

                                </tbody>

                            </TableMain>
                        </TableWrapper>
                    ) : props.tableDataMode === 'pivot_data' ? <BlankScreen style={{
                        height: `${tableHeight - 10}px`,
                        maxHeight: tableHeight + "px",
                        width: width ? `${width}px` : '',
                        minHeight: isDetails ? '400px' : undefined,
                    }}>Please Choose Pivot Data Column</BlankScreen> :
                        <div style={{ height: `${tableHeight - 10}px`, maxHeight: tableHeight + "px", width: width ? `${width}px` : '', minHeight: isDetails ? '400px' : undefined, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Please wait...</div>}


                    <GrandFooter hide_border={props.hide_border} style={{ overflow: 'hidden' }}>
                        <FooterAction
                            {...props}
                            restricted_view={props.restricted_view}
                            activeTableData={activeTableData}
                            prevButtonEnable={prevButtonEnable}
                            nextButtonEnable={nextButtonEnable}
                            previousData={props.previousData}
                            nextData={props.nextData}
                        />
                    </GrandFooter>
                </Wrapper>
            }
        </React.Fragment >
    )
};



const mapStateToProps = (state, props) => {

    const _id = props.id;
    const __id_to_use__ = props.table_id_to_use;

    let tempData = state.tableReducer.data;
    const table_format_setting_cache = state.tableFormatReducer.table_format_setting_cache;
    const tbl_formatter = table_format_setting_cache && table_format_setting_cache[_id];

    const tableDataMode = state.helperReducer?.tableDataMode?.[_id];

    const filters_cache = state.filterReducer.filters_cache?.[props.insight_id];
    const drill_filters_cache = state?.filterReducer?.drill_filter_cache?.[props.insight_id];

    const sort_order_cache = state.tableReducer.sort_orders;
    const sort_orders = sort_order_cache?.[__id_to_use__];
    const dataFromServerCache = state.reportReducer.dataFromServer;
    const dataFromServer = dataFromServerCache?.[props.id];
    const need_to_switch_row_column = dataFromServer?.need_to_switch_row_column;
    const link_filter = state.filterReducer.link_filter_cache?.[props.insight_id]?.[props.id];
    const width_additions = state.tableReducer?.width_additions_cache?.[__id_to_use__]

    const download_excel_cache = state.tableReducer?.download_excel_cache;


    if (tempData && __id_to_use__) {

        const data = tempData[__id_to_use__];
        return { width_additions, link_filter, sort_orders, data, tempData, tbl_formatter, tableDataMode, filters_cache, drill_filters_cache, need_to_switch_row_column, download_excel_cache }

    } else return {}

}



export default connect(mapStateToProps, { update_report_user_preferance, update_report_column_state, update_width_additions, apply_link_filter, update_sort_order, updateTableColumn, save_report_items, set_report_to_refresh, apply_filters, clear_all_filters, raise_drill_filter_cache, dispatch_updated_process_data, dispatch_updated_pivot_data, loadMore, setSortedDataInReducer, scrollParentWindow, pushSortFunctionDataToReducer, nextData, previousData })(Table);
