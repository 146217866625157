import React from 'react';


const BarChart = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 812 832"
            {...props}
            space="preserve">

<path transform="translate(4)" d="m0 0h58v773l75-1-3-5-1-101v-143l5-4 5-2 9-1h68l15 1 8 3 1 2v241l-2 6-2 3h61l-6-7-1-129v-171l2-5 6-4 11-2h73l13 2 6 4 2 5v159l-1 140-6 8h60l-1-3-4-2-1-2-1-168v-218l2-4 7-4 5-1h83l10 3 3 2 1 4v380l-2 7-3 3-1 4h62l-8-11v-493l7-6 4-2h87l8 4 4 5 1 5v260l-1 224-3 7-4 6 96 1 1 2v57h-809v-831z" fill="#327BFE"/>
<path transform="translate(623,261)" d="m0 0h87l8 4 4 5 1 5v260l-1 224-3 7-4 6 61 1v1h-64l-8 1h-74l-9-1-8-10-1-2v-493l7-6z" fill="#6DA3FE"/>
<path transform="translate(467,370)" d="m0 0h83l10 3 3 2 1 4v380l-2 7-3 3v5l-13 1h-77l-8-1-2-5-4-2-1-2-1-168v-218l2-4 7-4z" fill="#6DA3FD"/>
<path transform="translate(313,454)" d="m0 0h73l13 2 6 4 2 5v159l-1 140-6 8 4 2-12 1h-83l-8-1-2-4-4-5-1-129v-171l2-5 6-4z" fill="#6CA2FE"/>
<path transform="translate(152,516)" d="m0 0h68l15 1 8 3 1 2v241l-2 6-2 3 2 2-21 1h-70l-15-1v-3l-2-4-1-101v-143l5-4 5-2z" fill="#6DA3FD"/>
        </svg>
    )

}


export default BarChart;