import * as actions from '../action-types/favourite';

const initialState = {
    type: actions.FAVOURITE_INITIAL,
    favouriteData: undefined,
    message: undefined,
    historyData: undefined,
};


const favouriteReducer = (state, action) => {
    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        case actions.SAVE_FAVOURITE:
            return {
                ...state,
                type: action.type,
            };
        case actions.ADDED_MESSAGE:
            return {
                ...state,
                type: action.type,
                message: action.data
            };

        case actions.GET_ALL_FAVOURITE_LIST_BY_ID:
            return {
                ...state,
                type: action.type,
                favouriteData: action.data
            };

        case actions.GET_HISTORY_SUCCESS:
            
            // let temp_history_data = (action.data && action.data.length > 50) ? action.data.slice(0, 50) : action.data;
            return {
                ...state,
                type: action.type,
                historyData: action.data
            };

        default:
            return {
                ...state
            }
    }
};

export default favouriteReducer;