import * as actions from '../action-types/permission'


const initialState = {
    type: actions.PERMISSION_INIT,
    permissions: undefined
};

const permissionReducer = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        case actions.GET_PERMISSIONS_FOR_USER_V1:
            return {
                type: action.type,
                permissions: action?.data?.permissions
            };

        default:
            return { ...state }
    }
}

export default permissionReducer;