import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SharedTable from '../shared-table';
import TitleHeader from '../title-header';
import { PageInnerWrapper } from '../hoc/styled.hoc';

import {
    getAdTechClients, getAdTechCampaignMappings,
    getAdTechCampaignName, saveTheMapping,
    getTheMappingByAdTechClientAndPlatform
} from '../../actions/campaign';

import { Button } from '../hoc/button';
import { MainWrapper } from './campaign.styled'
import SelectOption from '../select-option';
import AutoSearch from '../auto.search';
import { generateUniqueKey } from '../../utils';
import TextBox from '../hoc/textBox';

let counter = 0;

const Index = (props) => {
    const {
        getAdTechClients, adTechClients,
        adTechCampaign, adTechCampaignName, getAdTechCampaignName,
        getAdTechCampaignMappings, saveTheMapping,
        adTechMappingClientPlatform, getTheMappingByAdTechClientAndPlatform
    } = props;

    const get_plain_row_object = (__table__head__) => {
        const table_head = __table__head__ ? __table__head__ : tableThead;

        const object_to_return = {
            id: undefined,
            client_name: undefined,
            campaign_name_to_display: undefined,
        };

        const existing_keys = Object.keys(object_to_return);

        table_head && table_head.forEach(head => {
            if (existing_keys.indexOf(head) === -1) {
                object_to_return[head] = [];
            }
        });

        return object_to_return;
    };

    const [autoSuggestValue, setAutoSuggestValue] = useState({});
    const [tableThead, setTableThead] = useState();
    const [autoSearchColumnsData, setAutoSearchColumnsData] = useState(undefined);
    const [autoSearchColumnsKeys, setAutoSearchColumnsKeys] = useState(undefined);
    const [is_proccess_request, setIsProccessRequest] = useState(true);
    const [tableData, setTableData] = useState(undefined);
    const [clientWiseMapping, setClientWiseMapping] = useState(undefined);
    const [unMappedCampaigns, setUnMappedCampaigns] = useState(undefined);
    const [mappedAndUnMappedCampaigns, setMappedAndUnMappedCampaigns] = useState([]);
    const [platforms, setPlatforms] = useState([]);
    const [platformWiseCampaigns, setPlatformWiseCampaigns] = useState({});

    useEffect(() => {
        let _page_info = {
            title: 'MAP CAMPAIGN WITH CLIENTS'
        }
        props.dispatchHeaderInformation(_page_info)

        getAdTechClients();
        getAdTechCampaignName();
        getAdTechCampaignMappings();
    }, []);

    useEffect(() => {
        const __adTechCampaigns__ = [];
        const __platform_wise_campaigns__ = {};
        const __platforms__ = {};

        adTechCampaign && adTechCampaign.forEach(campaign => {
            campaign.group_key = 'Campaigns | Other Clients';
            const platform_name = campaign.platform_name.toLocaleLowerCase();

            if (typeof __platform_wise_campaigns__[platform_name] === 'undefined') {
                __platform_wise_campaigns__[platform_name] = [];
            }

            __platform_wise_campaigns__[platform_name].push(campaign);
            __platforms__[platform_name] = 1;
        });

        const table_columns = ["client_name", "campaign_name_to_display", ...Object.keys(__platforms__)];
        const search_keys_for_dropdowns = {};

        search_keys_for_dropdowns["client_name"] = {
            valueKey: 'id',
            displayKey: 'ad_tech_client'
        };

        Object.keys(__platforms__).forEach(platform => {
            search_keys_for_dropdowns[platform] = {
                valueKey: 'id',
                displayKey: 'name'
            };
        });

        setTimeout(() => {
            setPlatforms(Object.keys(__platforms__));
            setPlatformWiseCampaigns(__platform_wise_campaigns__)
            setTableThead(table_columns);
            setTableData([get_plain_row_object(table_columns)]);
            setAutoSearchColumnsKeys(search_keys_for_dropdowns)
        }, 100);
    }, [adTechCampaign]);


    /**
     * 
     * @param {*} index 
     * @param {*} key 
     * @param {*} value 
     * @param {*} type 
     * @param {*} displayKey 
     * 
     * the function fired after the dropdown is changed. We will use Index (not ID) for assigning the values in table row accordingly
     */
    const autoSearchOnChange = (index, key, value, type, displayKey, valueKey, parent_object) => {
        const table_data_clonned = JSON.parse(JSON.stringify(tableData));

        if (type === 'array') {
            const index_of_item_in_array = table_data_clonned[index][key].indexOf(value);

            if (index_of_item_in_array > -1) {
                // if the index exists, it means it is uncheck
                table_data_clonned[index][key].splice(index_of_item_in_array, 1);
            }
            else {
                // it is a check
                table_data_clonned[index][key].push(value);
            }
        }
        else {
            //single input
            table_data_clonned[index][key] = typeof value === 'object' ? value[valueKey] : value;
        }

        table_data_clonned[index]['base_object_' + key] = parent_object;

        if (index === table_data_clonned.length - 1) table_data_clonned.push(get_plain_row_object());

        setTableData(table_data_clonned);
    };


    /**
     * 
     * @param {*} client_name 
     * 
     * When we change client name, we will change the collection to the client selection
     */
    const push_client_campaigns_in_campaigns_list = (client_id) => {
        const final_campaigns = {};
        platforms.forEach(platform => {
            const platform_wise_campaigns = JSON.parse(JSON.stringify(platformWiseCampaigns[platform] || []));

            platform_wise_campaigns.forEach(campaign => {

                if (campaign.ad_tech_client && campaign.ad_tech_client.id === client_id) {
                    campaign.group_key = 'Campaign Mapped to ' + (campaign.ad_tech_client && campaign.ad_tech_client.ad_tech_client);
                }
                else if (campaign.ad_tech_client && campaign.ad_tech_client.id && campaign.ad_tech_client.id !== client_id) {
                    campaign.group_key = 'Campaign | Other Clients';
                }
                else {
                    campaign.group_key = 'Unmapped Campaigns';
                }
            });

            final_campaigns[platform] = platform_wise_campaigns;
        });

        setPlatformWiseCampaigns(final_campaigns);
    };

    useEffect(() => {
        if (adTechCampaignName) {
            if (Object.keys(adTechCampaignName)) {
                let cloneTableHead = ["client_name", "campaign_name_to_display"];

                Object.keys(adTechCampaignName).length > 0 && Object.keys(adTechCampaignName).map((key) => {
                    if (key !== 'response_session_id') cloneTableHead.push(key)
                });
            }
        };
    }, [adTechCampaignName])

    const _save_data = () => {
        saveTheMapping(tableData.filter(i => !!i.base_object_client_name));
        setTimeout(() => {
            setAutoSuggestValue(undefined)
        }, 1000)
    };


    useEffect(() => {
        let _clone_value = autoSuggestValue ? JSON.parse(JSON.stringify(autoSuggestValue)) : {};
        if (adTechMappingClientPlatform && tableThead && tableThead.length > 0) {
            tableThead.forEach((row) => {
                if (!_clone_value[row]) {
                    _clone_value[row] = {
                        name: adTechMappingClientPlatform[row]
                    }
                }
            })
        }
        setAutoSuggestValue(_clone_value)
    }, [adTechMappingClientPlatform])

    


    return (
        <MainWrapper style={{marginTop: '1rem'}}>
            {/* <TitleHeader title="Map Campaign with Clients" /> */}
            <PageInnerWrapper padding={'1rem 1.8rem'}>
                <table>
                    <thead>
                        <tr>
                            {tableThead && tableThead.length > 0 && tableThead.map((k) => {
                                let real_name = k.split("_").join(" ");
                                return (
                                    <td style={{ textTransform: 'capitalize' }}>{real_name}</td>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData && tableData.length > 0 && tableData.map((row, i) => {
                            return (
                                <tr>
                                    {tableThead && tableThead.length > 0 && tableThead.map((c) => {
                                        let rowValue = autoSuggestValue && autoSuggestValue[row.id];
                                        let column_key = autoSearchColumnsKeys && autoSearchColumnsKeys[c];
                                        let _value_key = column_key && column_key.valueKey;
                                        let _display_key = column_key && column_key.displayKey;

                                        let is_multi_select = c === "client_name" ? false : true;
                                        let is_input_field = c === 'campaign_name_to_display' ? true : false;

                                        if (is_input_field) {
                                            return (
                                                <td style={{ maxWidth: '100px', paddingTop: '.5rem' }}>
                                                    <TextBox
                                                        size='small'
                                                        height={'2.5rem'}
                                                        borderColor='#eaeaea'

                                                        onChange={(e) => {
                                                            // autoSearchOnChange(row.id, c, e.target.value, "string");
                                                            autoSearchOnChange(i, c, e.target.value, "string");
                                                        }}

                                                        value={row[c]}
                                                    />
                                                </td>
                                            )
                                        }

                                        if (is_multi_select) {

                                            return (
                                                <td style={{ maxWidth: '100px' }}>
                                                    <SelectOption
                                                        selections={platformWiseCampaigns[c.toLocaleLowerCase()] || []}
                                                        onChangeFunc={(value, type, data) => {
                                                            autoSearchOnChange(i, c, value, "array", _display_key, _value_key, data);
                                                        }}
                                                        // size='small'
                                                        displayKey={_display_key}
                                                        valueKey={_value_key}
                                                        groupKey='group_key'
                                                        sortKey='desc'
                                                        activeValue={row[c]}
                                                        isCheckBox={is_multi_select}
                                                        isMultiSelect={is_multi_select}
                                                        isSearch={is_multi_select}
                                                        width="100%"
                                                        height="15.857rem"
                                                        lineHeight="1.857rem"
                                                        borderColor='#eaeaea'
                                                        color='#7c7c7c'
                                                    />
                                                </td>
                                            )
                                        }

                                        
                                        else {
                                            return (
                                                <td style={{ maxWidth: '100px', paddingTop: '.5rem' }}>
                                                    <AutoSearch
                                                        selections={adTechClients || []}
                                                        onItemClick={(val) => {
                                                            autoSearchOnChange(i, c, val['ad_tech_client'], "object", _display_key, 'ad_tech_client', val);
                                                            push_client_campaigns_in_campaigns_list(val[_value_key]);
                                                        }}
                                                        setActiveValue={(value) => {
                                                            autoSearchOnChange(i, c, value['ad_tech_client'], "object", _display_key, 'ad_tech_client', value);
                                                            push_client_campaigns_in_campaigns_list(value[_value_key]);
                                                        }}
                                                        displayKey={_display_key}
                                                        valueKey={_value_key}
                                                        autoSearchColumnsKeys={autoSearchColumnsKeys}
                                                        activeValue={rowValue && rowValue[c] ? rowValue[c] : undefined}
                                                        width="100%"
                                                        height="1.857rem"
                                                        lineHeight="1.857rem"
                                                        borderColor='#eaeaea'
                                                        color='#7c7c7c'
                                                    />
                                                </td>
                                            )
                                        }
                                    })}
                                </tr>
                            )
                        })}

                    </tbody>

                </table>
                {/* <SharedTable
                    height={"calc(100vh - 180px)"}
                    tableData={[{ id: 'campaign' },{ id: 'campaign' }]}
                    tableHead={tableThead}
                    columnsToShow={tableThead}
                    isArrayDropDown={true}
                    autoSearchColumns={tableThead}
                    autoSearchColumnsData={autoSearchColumnsData}
                    autoSearchOnChange={autoSearchOnChange}
                    autoSearchColumnsValue={autoSuggestValue}
                    autoSearchColumnsKeys={autoSearchColumnsKeys}
                /> */}

                <div style={{ marginBottom: '1rem', marginTop: 'auto' }}>
                    <Button
                        primary={true}
                        onClick={() => {
                            _save_data()
                        }}
                    >
                        Save
                    </Button>
                </div>

            </PageInnerWrapper>
        </MainWrapper>
    )
}


const mapStateToProps = (state) => 
({
    adTechClients: state.CampaignReducer.adTechClients,
    adTechCampaignMappings: state.CampaignReducer.adTechCampaignMappings,
    adTechCampaign: state.CampaignReducer.adTechCampaign,
    adTechMappingClientPlatform: state.CampaignReducer.adTechMappingClientPlatform
})

export default connect(mapStateToProps, { getAdTechClients, getAdTechCampaignMappings, getAdTechCampaignName, saveTheMapping, getTheMappingByAdTechClientAndPlatform })(Index);
