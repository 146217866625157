import styled from 'styled-components';

import { themes } from '../../../theme/theme.config'
import { getSelectedTheme } from '../../../utils';
const selected = getSelectedTheme();


const {  textbox } = themes[selected];




export const HintsWrapper = styled.div`
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        box-sizing: border-box;
        padding: 15px 27px;
        background: white;
        min-width: 22.857rem;
        max-width: 22.857rem;
        min-height: 27.143rem;
        max-height: 27.143rem;
        border-radius: 8px;
        box-shadow: 0 3px 6px 0 rgba(0,0,0,0.25);
        /* border: solid 5px rgb(234,234,234); */
        background-color: rgb(255,255,255);
        overflow: auto;

    input{
        height: 2.857rem;
        border-radius: 6px;
        border: 0px;
        background-color: #eaeaea;
        width: 100%;
        font-family: "DM Sans", sans-serif;
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.286rem;
        letter-spacing: normal;
        text-align: left;
        color: ${textbox.color};
        padding: 0px 16px;
        padding-right: 25px;
        box-sizing: border-box;

        &:hover{
            border-color:${textbox.border.hoverColor};
            outline: none;
            box-shadow: 0 0 0;
        }

        &:focus{
            box-shadow: 0 0 0;
            outline: none;
        }

    }
`;


export const AddNewTag = styled.div`
    width: 100%;
    padding: ${props => props.padding || '12px 0rem'};
    /* cursor: pointer; */
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .add_title{
        font-size: 1rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.286rem;
        letter-spacing: normal;
        text-align: left;
        color: #1a1a1a;
        margin: 0px;
        padding-left: 10px;
        text-transform: capitalize;
    }   

    .add{
        font-size: ${props => props.size || '35px' } ;
        line-height: 17px;
    }

`;


export const ExistingTags = styled.div`
    width: 100%;
    .e_tags{
        font-size: 1rem;
        font-weight: 500;
        font-stretch: normal;
        line-height: 1.286rem;
        text-align: left;
        color: #1a1a1a;
        margin: 0px;
    }
`;




export const FilterdItemRow = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: auto;
    box-sizing: border-box;
    max-width: 100%;
    max-height: 12rem;
    flex-wrap: wrap;
    border-radius: 6px;
    padding: 0.3rem;
    gap: 4px;
    &::-webkit-scrollbar {
        background-color: #3F3F3F;
        width:4px;
    }

    &::-webkit-scrollbar-track {
        background-color: #F3F3F3;
        border: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dbdbdb;
        border-radius: 10px;
        height: 4px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color:  #3F3F3F;
        height: 4px;
    }

`;


export const Filterd = styled.div`
        height: 1.571rem;
        /* border: solid 1px #9caeb5; */
        border-radius: 6px;
        background-color: #dae9ff;
        /* padding: 0px 7px; */
        /* margin-bottom: 5px;
        margin-right: 6px; */
        box-sizing: border-box;
        position: relative;
        min-width: 35px;
        cursor: pointer;
        font-size: 0.714rem;
        font-weight: normal;
        text-align: center;
        color: rgb(0, 22, 31);
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0px 8px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1.6rem;

        &:hover{
            .filter_hover{ 
                display: flex;
                
            }

        }


  


     .filter_hover{
         display: none;
         position: absolute;
         top: 0px;
         left: 0px;
         width: 100%;
         height: 100%;
         align-items: center;
         justify-content: center;
         padding: 0px 0px;
         box-sizing: border-box;
         background-color: #f3f3f3;

         div{
             cursor: pointer;
             width: 35px;
             display: flex;
             align-items: center;
             justify-content: center;
         }
     }

`;
