import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as _ from 'lodash';
import { saveReport, getAllReports, getReportById, getFormulasByReportId, clearFormulas, get_tbl_formulas, save_tbl_formulas } from '../../../actions/report';
import { setActiveEditReport } from '../../../actions/report.helper';
import { getHintsByDbInfo, clearHintsByEntity, getHintsWithTableAndColumnName } from '../../../actions/hints';
import { getEntities, clearEntities } from '../../../actions/entity';
import { getMenus } from '../../../actions/menu.group';
import { final_groups_list } from '../menu-group-list';
import SelectOption from '../../select-option';
import SelectDisplayField from '../../select-display-field';
import ConfimBox from '../../confirm-box';
import DragDrop from '../../drag-drop';
import { Button } from '../../hoc/button';
import { DivRow } from '../../styled-hoc';
import { Wrapper, EditorInnerWrapper } from '../report.editor.styled';
import { constants } from '../../../utils/constants';
import * as enums from '../../../utils/enums';
import { getParameterByName, makeCopyReport, showPopup, insertAtSpecificLocationInArray, capitalizeFirstLetter } from '../../../utils';
import TextBox from '../../hoc/textBox';
import RippleEffect from '../../hoc/ripple-effect'
import EditSvg from '../../svg/edit'
import TitleHeader from '../../title-header'
import ReportSvg from '../../svg/report';
import { getDomainConfigByPageName } from '../../../actions/domain_config';
import { clear_all_filters, clear_single_filter } from '../../../actions/filter';
import FormulaCreater from '../../formula-creator/mini.formula.creator';
import SharedTable from '../../shared-table';
import DeleteSvg from '../../svg/delete';
import CloseSvg from '../../svg/close';
import ConfirmBox from '../../confirm-box'
import { dispatchHeaderInformation } from '../../../actions/common';
import FormulaEditorPopup from '../../formatting/formula.editor.popup';


const MiniEditor = props => {

    const { saveReport, getHintsByDbInfo, hintsByDbInfo, getEntities, getMenus, location, domainConfigData, clear_all_filters, apply_filters, getFormulasByReportId, reportFormulas, tbl_formulas } = props;
    const { search } = location;

    const [report, setReport] = useState(undefined);
    const [filteredItems, setfilteredItems] = useState([]);
    const [filteredDisplayItems, setfilteredDisplayItems] = useState([]);
    const [meargFieldTodisplay, setMeargFieldTodisplay] = useState(undefined);
    const [filteredComparisons, setfilteredComparisons] = useState([]);
    const [groupedColumns, setGroupedColumns] = useState([]);
    const [filterValue, setFilterValue] = useState();
    const [showError, setShowError] = useState(false);
    const [showFilterError, setShowFilterError] = useState(false);
    const [localHintsByEntity, setLocalHintsByEntity] = useState(undefined);
    const [localDomainConfig, setLocalDomainConfig] = useState(undefined);
    const [formulas, setFormulas] = useState([]);
    const [aggregateColumns, setAggregateColumns] = useState(undefined);
    const [errorObject, setErrorObject] = useState(undefined);

    const [dbInfoId, setDbInfoId] = useState(undefined);

    const [localHints, setLocalHints] = useState(undefined);

    const _save = report => {
        saveReport(report);
        props.history.push('/report_list')
    };

    const _save_and_run_report = report => {
        clear_all_filters()
        saveReport(report, props.history, apply_filters, true);
    }


    useEffect(() => {
        let array = filteredDisplayItems ? [...filteredDisplayItems] : [];
        let temp_formula = tbl_formulas ? [...tbl_formulas] : [];
        temp_formula && temp_formula.length > 0 && temp_formula.forEach((f) => {
            const _index = array.indexOf(f.name);
            if(_index > -1){
                array[_index] = f;
            }
        })
        setMeargFieldTodisplay(array)
    }, [filteredDisplayItems, tbl_formulas])


    // useEffect(() => {
    //     let temp_filteredDisplayItems = filteredDisplayItems ? [...filteredDisplayItems] : []
    //     tbl_formulas && tbl_formulas.length > 0 && tbl_formulas.forEach((f, i) => {
    //         if (filteredDisplayItems && filteredDisplayItems.indexOf(f.name) > -1) {
    //             let _index = filteredDisplayItems.indexOf(f.name);
    //             temp_filteredDisplayItems.splice(_index, 1)
    //         }
    //     })
    //     setfilteredDisplayItems(temp_filteredDisplayItems)
    // }, [tbl_formulas])


    useEffect(() => {
        const __hints = [];
        props.hintsWithTableColumnsName && props.hintsWithTableColumnsName.length > 0 && props.hintsWithTableColumnsName.map((h) => {
            __hints.push(h.column_name)
        })
        setLocalHints(__hints)
    }, [props.hintsWithTableColumnsName])

    useEffect(() => {

        if (domainConfigData && domainConfigData.length > 0) {
            setLocalDomainConfig(domainConfigData[0])
        } else {
            setLocalDomainConfig(undefined)
        }

    }, [domainConfigData])


    useEffect(() => {
        props.getDomainConfigByPageName("report_editor")
    }, [])

    useEffect(() => {
        if (!props.menus) {
            getMenus();
        }
        const { report } = props;
        const reportId = getParameterByName('report_id', search);
        if (reportId && search && search.length > 0) {
            props.getReportById(reportId);
            props.get_tbl_formulas(reportId, undefined, "report_editor")
        }
        return () => {
            props.clearHintsByEntity();
            props.setActiveEditReport(undefined);
        }
        //eslint-disable-next-line
    }, [search]);


    useEffect(() => {
        let _page_info = {
            title: props.report ? "Edit report" : "Create new report",
            isBack: true,
        };
        props.dispatchHeaderInformation(_page_info)

    }, [props.report])


    useEffect(() => {
        if (hintsByDbInfo && Object.keys(hintsByDbInfo).length > 0) {
            let tempArray = Object.keys(hintsByDbInfo);
            tbl_formulas && tbl_formulas.length > 0 && tbl_formulas.forEach((f, i) => {
                if (tempArray.indexOf(f.name) > -1) {
                    let _index = tempArray.indexOf(f.name);
                    tempArray.splice(_index, 1);
                }
            })
            let tempAggregateColumns = [];
            let aggregateColumnsDataTypes = ['int', 'number', 'currency']
            Object.keys(hintsByDbInfo) && Object.keys(hintsByDbInfo).length > 0 && Object.keys(hintsByDbInfo).map((item, index) => {
                if (aggregateColumnsDataTypes.indexOf(hintsByDbInfo[item].data_type) > -1) {
                    tempAggregateColumns.push(item)
                }
            })

            setAggregateColumns(tempAggregateColumns)
            setLocalHintsByEntity(Array.from(new Set(tempArray)));
        }
    }, [hintsByDbInfo, tbl_formulas])



    useEffect(() => {
        if (props.report && props.report.report_items && props.report.report_items.length > 0) {
            let report_item = props.report.report_items[0];
            props.get_tbl_formulas(props.report.id, undefined, "report_editor")
        }
    }, [props.report])



    useEffect(() => {

        let formulaArray = [];

        if (tbl_formulas && tbl_formulas.length > 0) {
            tbl_formulas.forEach((f) => {
                if (f.name) {
                    formulaArray.push(f)
                }
            })
        }

        setFormulas(formulaArray)
    }, [tbl_formulas])



    useEffect(() => {
        if ((props.report && props.report.id) || (props.report && props.report.is_active)) {

            const duplicate = getParameterByName('duplicate', search);

            let tempReport = Object.assign({}, props.report);

            if (duplicate) {
                tempReport = makeCopyReport(tempReport);
            }

            if (tempReport && tempReport.report_items && tempReport.report_items.length > 0) {
                const reportParameters = tempReport.report_items[0].parameters;
                const reportDisplayColumns = tempReport.report_items[0].display_columns;
                const reportComparisons = tempReport.report_items[0].comparisons;
                const groupedColumns = tempReport.report_items[0].grouped_columns;
                let aggregateColumnsDataTypes = ["Min", "Max", "Average", 'Total']

                const filters = (reportParameters && reportParameters.length > 0) ? reportParameters.filter(data => data.length > 0 && data) : [];

                const display_columns = reportDisplayColumns && reportDisplayColumns.length > 0 && reportDisplayColumns.filter((item) => {
                    let _value_array = item && item.length > 0 ? item.split(' ') : undefined;

                    if (_value_array && _value_array.length > 0 && aggregateColumnsDataTypes.indexOf(_value_array[0]) === -1) {
                        return true
                    } else return false

                })

                const aggregateColumns = [];

                reportDisplayColumns && reportDisplayColumns.length > 0 && reportDisplayColumns.map((item) => {
                    let _value_array = item && item.length > 0 ? item.split(' ') : undefined;

                    if (aggregateColumnsDataTypes && aggregateColumnsDataTypes.length > 0 && aggregateColumnsDataTypes.indexOf(_value_array[0]) > -1) {

                        aggregateColumns.push({
                            type: _value_array[0],
                            value: _value_array.slice(1).join(' ')
                        })
                    }
                })

                setGroupedColumns(aggregateColumns)
                setfilteredItems(filters);
                setfilteredDisplayItems(display_columns);


                setfilteredComparisons(reportComparisons)
                // setGroupedColumns(groupedColumns)

                if (tempReport.report_items[0].db_info_id) {
                    setDbInfoId(tempReport.report_items[0].db_info_id)
                    getHintsByDbInfo(tempReport.report_items[0].db_info_id);
                    props.getHintsWithTableAndColumnName(['number', 'int', 'currency'], tempReport.report_items[0].db_info_id)
                }
            }
            setReport(tempReport);
        }


        else {
            const template = {
                name: '',
                title: '',
                type: 'report',

                report_items: [
                    {
                        question: '1',
                        parameters: '',
                        xac: '',
                        yac: '',
                        title: '',
                        entity_name: '',
                        comparisons: '',
                        chart_type: '',
                        render_mode: '',
                        width: '',
                        height: '',
                        display_columns: '',
                        criteria: ''
                    }
                ]
            }
            if (props.db_infos && props.db_infos.length === 1) {
                const value = props.db_infos[0].id
                setDbInfoId(value)
                getHintsByDbInfo(value);
                props.getHintsWithTableAndColumnName(['number', 'int', 'currency'], value)


            }
            setReport(template);
            setfilteredDisplayItems([])
            setfilteredComparisons([])
            setGroupedColumns([])
            setfilteredItems([])
            // _getNewHints(template['report_items'][0]['db_info_id'])
        }

        // eslint-disable-next-line
    }, [props.report]);


    const groups = props.menus ? final_groups_list(props.menus, 0) : [];

    const _getNewHints = value => {
        // if (value !== '-1' && filteredDisplayItems && filteredDisplayItems.length > 0) {
        //     showPopup(undefined, 'This will clear all the Selected Fields ', enums.default.popupType.element, ConfimBox,
        //         {
        //             func: {
        //                 setYes: () => setEntity(value)
        //             }
        //         }
        //     )
        // }

        setEntity(value)
    };

    useEffect(() => {
        if (props.db_infos && props.db_infos.length === 1) {
            let value = props.db_infos[0].id
            setDbInfoId(value)
            getHintsByDbInfo(value);
            props.getHintsWithTableAndColumnName(['number', 'int', 'currency'], value)

        }
    }, [props.db_infos])


    const setEntity = value => {
        const tempReports = Object.assign({}, report);
        if (typeof tempReports['report_items'] === 'undefined') {
            tempReports['report_items'] = [];
        }

        // tempReports['report_items'][0]['entity_name'] = value;

        if (tempReports['report_items'][0]['id'] && typeof tempReports['report_items'][0]['modified'] === 'undefined') {
            tempReports['report_items'][0]['modified'] = true
        }

        if (tempReports['id'] && typeof tempReports['modified'] === 'undefined') {
            tempReports['modified'] = true
        }

        setReport(tempReports);
        setfilteredDisplayItems([]);
        setfilteredComparisons([])
        setGroupedColumns([])
        setfilteredItems([]);
        getHintsByDbInfo(value);
    };


    const onTextChange = (key, isMain, __value__) => (event) => {

        const tempReports = Object.assign({}, report);
        const _value = event.target.value;

        const value = _value && _value.length > 0 ? _value : null;


        if (key === 'title') {
            if (showError) {
                setShowError(false);
            }
        }



        if (!isMain) {
            tempReports['report_items'][0][key] = value;

            if (tempReports['report_items'][0]['id'] && typeof tempReports['report_items'][0]['modified'] === 'undefined') {
                tempReports['report_items'][0]['modified'] = true
            }

            if (tempReports['id'] && typeof tempReports['modified'] === 'undefined') {
                tempReports['modified'] = true
            }
        }
        else {
            if (tempReports['id'] && typeof tempReports['modified'] === 'undefined') {
                tempReports['modified'] = true
            }

            // if(key === 'name'){
            //     tempReports['title'] = value
            // }
            tempReports[key] = value;
            if (__value__) {
                tempReports[key] = __value__;
            }
        }


        setReport(tempReports);
    };


    const onChangeCategory = (val) => {
        let tempReports = Object.assign({}, report);

        if (tempReports['id'] && typeof tempReports['modified'] === 'undefined') {
            tempReports['modified'] = true
        }

        tempReports['menu_group_id'] = val;

        setReport(tempReports);
    };


    const removeFilter = index => {
        let tempfilteredItems = [...filteredItems];

        tempfilteredItems.splice(index, 1);

        setfilteredItems(tempfilteredItems);
    };


    const addFilters = value => {
        const shouldProceed = filteredDisplayItems && filteredDisplayItems.length > 0 && filteredDisplayItems.includes(value);

        if (shouldProceed) {
            const tempfilteredItems = [...filteredItems];

            tempfilteredItems.push(value);

            setfilteredItems(tempfilteredItems);
            setFilterValue(undefined);
        }
        else {
            setShowFilterError(true);
        }

    };


    const preSave = (is_run_report) => {

        const tempReport = Object.assign({}, report);
        const tempArray = [];
        const finalFilteredDisplayItems = [];
        meargFieldTodisplay && meargFieldTodisplay.length > 0 && meargFieldTodisplay.forEach((item, i) => {
            if (typeof item === 'object') {
                if (!item.deleted) {
                    let _title = item.name;
                    finalFilteredDisplayItems.push(_title);
                }
            } else {
                finalFilteredDisplayItems.push(item);
            }
        })

        groupedColumns && groupedColumns.length > 0 && groupedColumns.forEach((column, i) => {
            let _str = column.type + " " + column.value
            finalFilteredDisplayItems.splice(0, 0, _str);
        })

        let tempFormulas = [];

        if (tempReport.id) {
            tempReport.modified = true;
        }
        tempReport.type = 'report';

        // if (formulas) {
        //     tempReport.formulas = formulas;
        // }

        meargFieldTodisplay && meargFieldTodisplay.length > 0 && meargFieldTodisplay.forEach((formula) => {
            if (typeof formula === 'object') {
                tempFormulas.push(formula)
            }
        })


  

        tempReport && tempReport.report_items && tempReport.report_items.length > 0 && tempReport.report_items.forEach(report_item => {
            report_item.db_info_id = dbInfoId

            if (typeof report_item['modified'] === 'undefined') {
                report_item.modified = true;
            }

            Object.keys(report_item).forEach(key => {
                if ((key === 'parameters')) {
                    report_item[key] = filteredItems;
                }
                else if (key === 'display_columns') {
                    report_item[key] = finalFilteredDisplayItems;
                }
                else if (key === 'comparisons') {
                    let temp_comparisons = [];
                    filteredComparisons && filteredComparisons.length && filteredComparisons.forEach((letter) => {
                        let splitted_letter = letter && letter.split(" ");
                        splitted_letter = splitted_letter && splitted_letter.map((l) => capitalizeFirstLetter(l));
                        temp_comparisons.push(splitted_letter.join(" "))
                    })

                    report_item[key] = temp_comparisons;
                }
            })
            report_item.grouped_columns = groupedColumns

        })


        let proceed = true;

        const allReports = props.allReports;

        const title = report.report_items && report.report_items[0] && report.report_items[0].title ? report.report_items[0].title.split(' ').join('').toLowerCase() : '';

        const entity_name = report.report_items && report.report_items[0] && (report.report_items[0].entity_name === '-1' || !report.report_items[0].entity_name) ? false : true;

        // if (entity_name) {
        allReports && allReports.length > 0 && allReports.forEach(data => {
            if (
                data.report_items[0] && data.report_items[0].title && data.report_items[0].title.split(' ').join('').toLowerCase() === title
                && report.id !== data.id && data.id
            ) {
                setShowError(true);
                proceed = false;
            }
        });

        tempFormulas && tempFormulas.length > 0 && tempFormulas.forEach((f) => {
            const nf_formula_conditions = f.nf_formula_conditions ? [...f.nf_formula_conditions] : [];
            const l_element = nf_formula_conditions && nf_formula_conditions[nf_formula_conditions.length - 1];
            if(l_element && (l_element.condition == '' || l_element.value == '')) {
                nf_formula_conditions.pop()
            }
            f.nf_formula_conditions = nf_formula_conditions;
        })

        if (tempFormulas) {
            tempReport.formulas = tempFormulas;
        }

        tempArray.push(tempReport);

        if (proceed) {
            if (is_run_report) {
                _save_and_run_report(tempArray)
                // save_tbl_formulas(tempFormulas, )
            }
            else {
                _save(tempArray);
            }
        }
        // }
        else {
            // setEntityError(true);
        }
    };

    const onDrop = event => {

        const index = event.dataTransfer.getData("fieldToDisplay");

        let currentIndex = undefined;
        let tempFilteredItems = meargFieldTodisplay ? [...meargFieldTodisplay] : [];
        const value = event.currentTarget.textContent;

        for (let i = 0; i < tempFilteredItems.length; i++) {
            if (typeof tempFilteredItems[i] === 'object') {
                let _prev_title = tempFilteredItems[i].name && tempFilteredItems[i].name.trim().toLowerCase();
                let current_value = value && value.trim().toLowerCase();
                if (_prev_title === current_value) {
                    currentIndex = i;
                    break;
                }
            } else {
                if (tempFilteredItems[i] === value) {
                    currentIndex = i;
                    break;
                }
            }
        }
        const deletedItem = _.pullAt(tempFilteredItems, index);
        tempFilteredItems = insertAtSpecificLocationInArray(tempFilteredItems, currentIndex, deletedItem[0]);
        setMeargFieldTodisplay(tempFilteredItems);

    };


    const onDropTransposeColumns = event => {
        const index = event.dataTransfer.getData("transposeColumns");
        let currentIndex = undefined;
        let tempFilteredItems = [...filteredComparisons]
        const value = event.currentTarget.textContent;
        for (let i = 0; i < tempFilteredItems.length; i++) {
            if (tempFilteredItems[i] === value) {
                currentIndex = i;
                break;
            }
        }
        const deletedItem = _.pullAt(tempFilteredItems, index);
        tempFilteredItems = insertAtSpecificLocationInArray(tempFilteredItems, currentIndex, deletedItem[0]);
        setfilteredComparisons(tempFilteredItems);

    };


    const onDropGroupedColumns = event => {
        const index = event.dataTransfer.getData("grouped_column");
        let currentIndex = undefined;
        let tempFilteredItems = [...groupedColumns]
        const value = event.currentTarget.textContent;
        for (let i = 0; i < tempFilteredItems.length; i++) {
            if ((tempFilteredItems[i].type + '(' + tempFilteredItems[i].value + ')') === value) {
                currentIndex = i;
                break;
            }
        }
        const deletedItem = _.pullAt(tempFilteredItems, index);
        tempFilteredItems = insertAtSpecificLocationInArray(tempFilteredItems, currentIndex, deletedItem[0]);
        setGroupedColumns(tempFilteredItems);

    };


    const _open_add_edit_popup = (menus, displayItem, selectedFields, title, setSelectedFields, titleOnHover, titleHeading, smallPopup) => {
        showPopup(undefined, undefined, enums.default.popupType.element, SelectDisplayField, {
            menus: menus,
            displayItem: displayItem,
            selectedFields: selectedFields ? selectedFields : [],
            title: title,
            setSelectedFields: setSelectedFields,
            titleOnHover: titleOnHover,
            titleHeading: titleHeading,
            smallPopup: smallPopup ? smallPopup : undefined,
            hide_selected_object: true,
        })
    };


    const save_formula = (formula, index) => {
        const clone_m_f_d = meargFieldTodisplay ? [...meargFieldTodisplay] : [];
        if(index > -1){
            let t_element = clone_m_f_d[index];
            t_element = formula;
            clone_m_f_d[index] = t_element;
        }
        else{
            clone_m_f_d.push(formula)
        }
        setMeargFieldTodisplay(clone_m_f_d)
    }

    const __hints__for_formula = (meargFieldTodisplay && meargFieldTodisplay.length > 0) && meargFieldTodisplay.filter((f) => {
        if(typeof f === 'string'){
            return true
        } else return false
    }) || [];


    const openFormulaCreatorPopup = (item, index) => {
        const clone_item = item ? Object.assign({}, item) : {};
        const item_condition = clone_item.nf_formula_conditions ?  [...clone_item.nf_formula_conditions] : [];
        const l_element = item_condition && item_condition[item_condition.length - 1];
        if (l_element && l_element.condition !== '' && l_element.value !== '' && l_element.display_value !== '' || item_condition.length == 0) {
            item_condition.push({
                condition: '',
                value: '',
                display_value: ''
            })
        }

        clone_item.nf_formula_conditions = item_condition;
        showPopup(undefined, undefined, enums.default.popupType.element, FormulaEditorPopup, {
            items: clone_item ? clone_item : undefined,
            index: index,
            save_formula: save_formula,
            columns: __hints__for_formula
        })
    }


    const delete_formula_fun = (index) => {
        const clone_m_f = meargFieldTodisplay ? [...meargFieldTodisplay] : [];
        const t_f = clone_m_f[index];
        t_f.deleted = true;
        t_f.modified = true;
        setMeargFieldTodisplay(clone_m_f)
    }


    const delete_element = (which_array, index, key) => {

        if (which_array === 'display_field') {
            if (key !== undefined) {
                let clone_array = meargFieldTodisplay ? [...meargFieldTodisplay] : [];
                if (clone_array && clone_array.indexOf(key) > -1) {
                    let _idx = clone_array.indexOf(key);
                    clone_array.splice(_idx, 1)
                    setMeargFieldTodisplay(clone_array)
                }
            }
        }
        if (which_array === 'grouped_column') {
            let clone_array = groupedColumns ? [...groupedColumns] : [];
            clone_array.splice(index, 1)
            setGroupedColumns(clone_array)
        }
        if (which_array === 'filter_comparisons') {
            let clone_array = filteredComparisons ? [...filteredComparisons] : [];
            clone_array.splice(index, 1)
            setfilteredComparisons(clone_array)
        }
        if (which_array === 'selected_filter_field') {
            let clone_array = filteredItems ? [...filteredItems] : [];
            clone_array.splice(index, 1)
            setfilteredItems(clone_array)
        }
    }

    const remove_pirtual_element_from_array = (which_array, index, key) => {
        showPopup(undefined, 'Are you sure you want to delete this ? ', enums.default.popupType.element, ConfirmBox, { func: { setYes: () => delete_element(which_array, index, key) } })
    }

    return (
        <div>
            <Wrapper style={{ marginTop: '1rem' }}>
                <EditorInnerWrapper scroll={false}>
                    <div style={{ width: '100%' }} className="scrolled_div">
                        {props.db_infos && props.db_infos.length > 1 && (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '2.1rem' }}>

                                <DivRow w="47%">
                                    <SelectOption
                                        label="Select Your Data Source"
                                        onChangeFunc={(value, key) => {
                                            setDbInfoId(value)
                                            _getNewHints(value)
                                            props.getHintsWithTableAndColumnName(['number', 'int', 'currency'], value)

                                        }}
                                        selections={props.db_infos ? props.db_infos : []}
                                        displayKey='connection_name'
                                        valueType="db_info_id"
                                        valueKey='id'
                                        activeValue={dbInfoId ? dbInfoId : null}
                                        width="100%"
                                        padding="0px 0px"
                                        paddingRight='25px'
                                        lineHeight="2.857rem"
                                        menuLineHeight="1.8rem"
                                        hideCase='true'
                                        groupKey='db_type'
                                        sortKey='desc'
                                        isSearch={true}
                                        isMainLabel={true}
                                    />
                                </DivRow>
                            </div>
                        )}

                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '2.1rem' }}>
                            <DivRow w="47%">
                                <TextBox
                                    type="text"
                                    label={(localDomainConfig && localDomainConfig["name"]) ? localDomainConfig["name"].field_label : "Please Provide The Report Name"}
                                    value={report ? report.name : ''}
                                    error={errorObject && errorObject['name']}
                                    errorMessage={errorObject && errorObject['name']}
                                    onChange={onTextChange('name', true)}
                                    onBlur={(e) => {
                                        let tempError = errorObject ? JSON.parse(JSON.stringify(errorObject)) : {};
                                        if (e.target.value === '') {
                                            tempError['name'] = "Pleae Enter Report Name"

                                        }
                                        if (e.target.value && e.target.value.length > 0) {
                                            delete tempError['name']
                                        }
                                        setErrorObject(tempError)
                                    }}
                                    icon={(report && report.name) ? <img src={constants.CONTEXT_PATH + "./icon/edit.png"} alt="edit" style={{ width: '0.857rem' }} /> : undefined}
                                />
                            </DivRow>

                        </div>
                        {/* 
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '2.1rem' }}>
                            <DivRow w="47%" >
                                <SelectOption
                                    label="Category"
                                    onChangeFunc={onChangeCategory}
                                    selections={groups}
                                    displayKey='name'
                                    valueKey='id'
                                    activeValue={report && report.menu_group_id}
                                    width="100%"

                                />
                            </DivRow>
                            
                        </div> */}


                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '2.1rem' }}>
                            {/* <DivRow w="47%" >
                                <SelectOption
                                    isMainLabel={true}
                                    label={(localDomainConfig && localDomainConfig["entity"]) ? localDomainConfig["entity"].field_label : "Business Entity"}
                                    onChangeFunc={_getNewHints}
                                    selections={props.entities}
                                    displayKey='name'
                                    valueKey='name'
                                    activeValue={report && report.report_items && report.report_items[0] && report.report_items[0].entity_name}
                                    width="100%"
                                />

                            </DivRow> */}
                            <DivRow w="47%">
                                <TextBox
                                    type="text"
                                    label={(localDomainConfig && localDomainConfig["criteria"]) ? localDomainConfig["criteria"].field_label : "Please Provide Data Selection Criteria / Condition"}
                                    value={(report && report.report_items && report.report_items[0]) ? report.report_items[0].criteria : ''}
                                    onChange={onTextChange('criteria')}
                                    icon={(report && report.report_items[0].criteria) ? <img src={constants.CONTEXT_PATH + "./icon/edit.png"} alt="edit" style={{ width: '0.857rem' }} /> : undefined}
                                />
                                <span style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: '.7rem' }} className="example">Example:- client name is hyundai</span>
                            </DivRow>

                        </div>


                        <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: '2.1rem', marginTop: '.2rem', width: '100%' }}>
                            <DivRow w="100%">
                                <div
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
                                ><span style={{ marginRight: '10px', fontSize: '1rem', color: '#0000008a', textTransform: 'capitalize' }}>Choose Report Columns</span>
                                    {meargFieldTodisplay && meargFieldTodisplay.length > 0 && (
                                        <RippleEffect
                                            icon={<EditSvg size=".9rem" height=".9rem" color="#222" />}
                                            title="edit"
                                            Click={() => {
                                                let _title = "Choose Report Columns"
                                                let _title_on_hover = hintsByDbInfo && Object.keys(hintsByDbInfo).length > 0 ? hintsByDbInfo : undefined;
                                                if (report && report.report_items[0] && dbInfoId) {
                                                    _open_add_edit_popup(props.menus, localHintsByEntity, meargFieldTodisplay, _title, setMeargFieldTodisplay, _title_on_hover, "Table")
                                                }

                                            }}
                                        />

                                    )}
                                </div>


                                {(!meargFieldTodisplay || (meargFieldTodisplay && meargFieldTodisplay.length === 0)) && (
                                    <div className="add_btn_new_field" onClick={() => {
                                        let _title = "Choose Report Columns"
                                        let _title_on_hover = hintsByDbInfo && Object.keys(hintsByDbInfo).length > 0 ? hintsByDbInfo : undefined;
                                        if (report && report.report_items[0] && dbInfoId) {
                                            _open_add_edit_popup(props.menus, localHintsByEntity, meargFieldTodisplay, _title, setMeargFieldTodisplay, _title_on_hover, "Table")
                                        }
                                    }}>+&nbsp;Add Report Columns</div>
                                )}


                                {meargFieldTodisplay && meargFieldTodisplay.length > 0 &&
                                    <div style={{
                                        width: '100%',
                                        maxHeight: '15.071rem',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        position: 'relative',
                                        borderBottom: '1px solid #ccc'
                                    }}
                                        onDragOver={(event) => event.preventDefault()}
                                    >
                                        <ul className="fields_item_ul" style={{ paddingBottom: '3rem' }}>
                                            <React.Fragment>
                                                {meargFieldTodisplay.map((item, index) => {
                                                    let _is_object = typeof item === 'object' ? true : false;
                                                    if (_is_object) {
                                                        if (item && !item.deleted) {
                                                            return (
                                                                <li
                                                                    title={item.nf_string_formula && item.nf_string_formula.split('nf_item').join(" ")}
                                                                    key={"formula" + index}
                                                                    style={{
                                                                        fontSize: '1rem',
                                                                        lineHeight: '1.714rem',
                                                                        marginRight: '3rem',
                                                                        minWidth: '10.786rem',
                                                                        cursor: 'pointer',
                                                                        fontWeight: 600,
                                                                        fontStyle: 'italic',
                                                                        color: '#000',
                                                                    }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        openFormulaCreatorPopup(item, index);
                                                                    }}
                                                                    draggable={true}
                                                                    onDragStart={(event) => {
                                                                        event.dataTransfer.setData("fieldToDisplay", index);
                                                                        event.dataTransfer.effectAllowed = "move"
                                                                    }}
                                                                    onDrop={(event) => onDrop(event)}
                                                                >
                                                                    <span >{item.name}</span>
                                                                    <div title="remove" className="hover_element" onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        showPopup(undefined, 'Are you sure you want to delete this ? ', enums.default.popupType.element, ConfirmBox, { func: { setYes: () => delete_formula_fun(index, item.name) } })

                                                                    }}>
                                                                        <CloseSvg size=".6rem" height=".6rem" color="#222" />
                                                                    </div>

                                                                </li>
                                                            )
                                                        } else {
                                                            return null
                                                        }

                                                    }
                                                    return (
                                                        <li style={{
                                                            fontSize: '1rem',
                                                            lineHeight: '1.714rem',
                                                            marginRight: '3rem',
                                                            minWidth: '10.786rem',
                                                        }}
                                                            draggable={true}
                                                            key={"formula" + index}
                                                            onDragStart={(event) => {
                                                                event.dataTransfer.setData("fieldToDisplay", index);
                                                                event.dataTransfer.effectAllowed = "move"
                                                            }}
                                                            onDrop={(event) => onDrop(event)}
                                                        ><span>{item}</span>
                                                            <div title="remove" className="hover_element" onClick={(e) => {
                                                                remove_pirtual_element_from_array('display_field', index, item);
                                                            }}>
                                                                <CloseSvg size=".6rem" height=".6rem" color="#222" />
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </React.Fragment>
                                        </ul>

                                        <div style={{ position: 'absolute', left: '0%', bottom: '1rem' }} className="add_btn_new_field" onClick={() => {
                                            openFormulaCreatorPopup()
                                        }}>+&nbsp;Add Formula</div>
                                    </div>
                                }
                            </DivRow>
                        </div>


                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '2.1rem', marginTop: '.2rem' }}>
                            <DivRow w="100%">
                                <div
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
                                ><span style={{ marginRight: '10px', fontSize: '1rem', color: '#0000008a', textTransform: 'capitalize' }}>Apply Aggregate Functions</span>
                                    {groupedColumns && groupedColumns.length > 0 && (
                                        <RippleEffect
                                            icon={<EditSvg size=".9rem" height=".9rem" color="#222" />}
                                            title="edit"
                                            Click={() => {
                                                let _title = "Select Columns"
                                                let _title_on_hover = hintsByDbInfo && Object.keys(hintsByDbInfo).length > 0 ? hintsByDbInfo : undefined;
                                                if (report && report.report_items[0] && dbInfoId) {
                                                    _open_add_edit_popup(props.menus, aggregateColumns, groupedColumns, _title, setGroupedColumns, _title_on_hover, "Table", true)
                                                }

                                            }}
                                        />

                                    )}
                                </div>


                                {(!groupedColumns || (groupedColumns && groupedColumns.length === 0)) && (
                                    <div className="add_btn_new_field" onClick={() => {
                                        let _title = "Select Columns"
                                        let _title_on_hover = hintsByDbInfo && Object.keys(hintsByDbInfo).length > 0 ? hintsByDbInfo : undefined;
                                        if (report && report.report_items[0] && dbInfoId) {
                                            _open_add_edit_popup(props.menus, aggregateColumns, groupedColumns, _title, setGroupedColumns, _title_on_hover, "Table", true)
                                        }
                                    }}>+&nbsp;Add Columns</div>
                                )}



                                {groupedColumns && groupedColumns.length > 0 &&
                                    <div style={{
                                        width: '100%',
                                        maxHeight: '15.071rem',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        borderBottom: '1px solid #ccc'
                                    }}
                                        onDragOver={(event) => event.preventDefault()}
                                    >
                                        <ul className="fields_item_ul">
                                            {groupedColumns.map((item, index) => {
                                                return (
                                                    <li style={{
                                                        fontSize: '1rem',
                                                        lineHeight: '1.714rem',
                                                        marginRight: '3rem',
                                                        minWidth: '10.786rem',
                                                    }}
                                                        draggable={true}
                                                        onDragStart={(event) => {
                                                            event.dataTransfer.setData("grouped_column", index);
                                                            event.dataTransfer.effectAllowed = "move"
                                                        }}
                                                        onDrop={(event) => onDropGroupedColumns(event)}
                                                        key={'gpd' + index}><span>{item.type + '(' + item.value + ')'}</span>
                                                        <div title="remove" className="hover_element" onClick={(e) => {
                                                            remove_pirtual_element_from_array('grouped_column', index, item);
                                                        }}>
                                                            <CloseSvg size=".6rem" height=".6rem" color="#222" />
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                }
                            </DivRow>
                        </div>




                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '2.1rem', marginTop: '.2rem' }}>
                            <DivRow w="100%">
                                <div
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
                                ><span style={{ marginRight: '10px', fontSize: '1rem', color: '#0000008a', textTransform: 'capitalize' }}>Transpose Rows into Columns</span>
                                    {filteredComparisons && filteredComparisons.length > 0 && (
                                        <RippleEffect
                                            icon={<EditSvg size=".9rem" height=".9rem" color="#222" />}
                                            title="edit"
                                            Click={() => {
                                                let _title = "Select Values As Headers"
                                                let _title_on_hover = hintsByDbInfo && Object.keys(hintsByDbInfo).length > 0 ? hintsByDbInfo : undefined;
                                                if (report && report.report_items[0] && dbInfoId) {
                                                    _open_add_edit_popup(props.menus, filteredDisplayItems, filteredComparisons, _title, setfilteredComparisons, _title_on_hover, "Table")
                                                }

                                            }}
                                        />

                                    )}
                                </div>


                                {(!filteredComparisons || (filteredComparisons && filteredComparisons.length === 0)) && (
                                    <div className="add_btn_new_field" onClick={() => {
                                        let _title = "Select Fields to display"
                                        let _title_on_hover = hintsByDbInfo && Object.keys(hintsByDbInfo).length > 0 ? hintsByDbInfo : undefined;
                                        if (report && report.report_items[0] && dbInfoId) {
                                            _open_add_edit_popup(props.menus, filteredDisplayItems, filteredComparisons, _title, setfilteredComparisons, _title_on_hover, "Table")
                                        }
                                    }}>+&nbsp;Add Columns</div>
                                )}



                                {filteredComparisons && filteredComparisons.length > 0 &&
                                    <div style={{
                                        width: '100%',
                                        maxHeight: '15.071rem',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        borderBottom: '1px solid #ccc'
                                    }}
                                        onDragOver={(event) => event.preventDefault()}
                                    >
                                        <ul className="fields_item_ul">
                                            {filteredComparisons.map((item, index) => {
                                                return (
                                                    <li style={{
                                                        fontSize: '1rem',
                                                        lineHeight: '1.714rem',
                                                        marginRight: '3rem',
                                                        minWidth: '10.786rem',
                                                    }}
                                                        draggable={true}
                                                        onDragStart={(event) => {
                                                            event.dataTransfer.setData("transposeColumns", index);
                                                            event.dataTransfer.effectAllowed = "move"
                                                        }}
                                                        onDrop={(event) => onDropTransposeColumns(event)}
                                                        key={index}><span>{item}</span>
                                                        <div title="remove" className="hover_element" onClick={(e) => {
                                                            remove_pirtual_element_from_array('filter_comparisons', index, item);
                                                        }}>
                                                            <CloseSvg size=".6rem" height=".6rem" color="#222" />
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                }
                            </DivRow>
                        </div>





                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: filteredItems && filteredItems.length > 0 ? "10px" : "25px", }}>
                            <DragDrop
                                inputLabel='Filters: '
                                inputLabelEx='(Press Enter)'
                                selectedFieldsLabel='Choose Report Filters'
                                selectedFields={filteredItems ? filteredItems : []}
                                setSelectedFields={setfilteredItems}
                                inputField={filterValue}
                                setInputField={setFilterValue}
                                addFunction={addFilters}
                                onDropIndexName='filterIndex'
                                removeFunction={removeFilter}
                                showError={showFilterError}
                                errorMessage='Column Not Present'
                                setShowError={setShowFilterError}
                                autoSuggestArray={filteredDisplayItems || []}
                                filteredDisplayItems={localHintsByEntity}
                                titleOnHover={hintsByDbInfo && Object.keys(hintsByDbInfo).length > 0 ? hintsByDbInfo : undefined}
                                titleHeading='Table'
                                remove_pirtual_element_from_array={remove_pirtual_element_from_array}
                            />

                        </div>
                    </div>



                    <div style={{
                        padding: '0px 2.1rem',
                        boxSizing: 'border-box',
                        width: '100%',
                        marginTop: 'auto',


                    }}>
                        <div
                            style={{
                                padding: '2.1rem 0px',
                                borderTop: '1px solid #ccc',
                                display: 'flex',
                            }}>
                            <Button
                                primary={true}
                                mR="1.143rem"
                                onClick={() => {
                                    preSave(true)
                                }}
                            >Save & Run</Button>

                            <Button
                                primary={true}
                                mR="1.143rem"
                                bgColor="#1e5d86"
                                onClick={() => {
                                    preSave(false)
                                }}
                            >Save</Button>

                            <Button
                                bgColor={'#e6e6e6'}
                                onClick={() => {
                                    if (props.history && props.history.goBack) {
                                        props.history.goBack();
                                    }
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </EditorInnerWrapper>
            </Wrapper>
        </div>
    );
};

const mapStateToProps = state => {

    const { hintsByDbInfo } = state.hintsReducer;
    const { menus } = state.menuGroupReducer;
    const { entities } = state.entityReducer;
    const { domainConfigData } = state.domainConfigReducer;
    const report = state.reportHelperReducer.editReport;
    const reportFormulas = state.reportReducer.reportFormulas;
    const db_infos = state.connectionReducer.db_infos
    const allReports = state.reportReducer.reports ? state.reportReducer.reports.all_reports : [];
    const tbl_formulas = state.reportReducer.tbl_formulas
    const hintsWithTableColumnsName = state.hintsReducer.hintsWithTableColumnsName;

    return { hintsWithTableColumnsName, hintsByDbInfo, menus, entities, allReports, report, domainConfigData, reportFormulas, db_infos, tbl_formulas }
}

export default withRouter(connect(mapStateToProps, { save_tbl_formulas, getHintsWithTableAndColumnName, get_tbl_formulas, dispatchHeaderInformation, saveReport, getAllReports, getHintsByDbInfo, setActiveEditReport, clearHintsByEntity, clearEntities, getMenus, getEntities, getReportById, getDomainConfigByPageName, clear_all_filters, clear_single_filter, getFormulasByReportId, clearFormulas })(MiniEditor));