import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import { Wrapper, SelectBox, DropDownMenu, DefaultLabel } from './select.option.new.styled';
import OverLay from '../helper/overlay';
import { constants } from '../../utils/constants';
import CheckBox from '../hoc/checkbox';
import { Button } from '../hoc/button';
import AddSvg from '../svg/add';
import RightArrowSvg from '../svg/right.arrow'
import Chevron from '../svg/chevron.down';
import SearchSvg from '../svg/search';
import MarkSvg from '../svg/mark';
import CloseSvg from '../svg/close'


const SelectOption = props => {

    const {

        onChangeFunc, isRequired, label, selections,
        displayKey, valueKey, activeValue, height, width,
        padding, paddingRight, lineHeight, dropDownItemAlign, backgroundColor,
        menuLineHeight, hideCase, valueType, isCheckBox, borderColor,
        disabled, isMultiSelect, setSelectBoxClose, isSelecctAll, isSearch,
        groupKey, sortKey, addAction, removeInputHeight, borderNone, dropdownShadow, isMainLabel,
        _activeGroupName, add_action_value, add_action_on_change, show_add_input

    } = props;


    const should_we_group = typeof groupKey !== 'undefined' && groupKey.length > 0;
    let fire_group = true;
    let prev_group_item = undefined;

    const [showMenu, setShowMenu] = useState(undefined);
    const [activeHint, setActiveHint] = useState(undefined);
    const [localSelection, setLocalSelection] = useState(undefined);
    const [activeGroupName, setActiveGroupName] = useState(undefined);


    const sort_using_group_key = (data_to_sort, group_key) => {
        const clonned_data = JSON.parse(JSON.stringify(data_to_sort));
        clonned_data && clonned_data.length > 0 && clonned_data.sort((prev_item, this_item) => {
            if (sortKey === 'desc') return ('' + this_item[group_key]).localeCompare(prev_item[group_key]);
            return ('' + prev_item[group_key]).localeCompare(this_item[group_key]);
        });

        return clonned_data;
    };


    useEffect(() => {
        if (!_activeGroupName) {
            setActiveGroupName(undefined);
        }
    }, [_activeGroupName, activeValue])

    let nameValue = undefined;

    useEffect(() => {

        if (should_we_group) {
            setLocalSelection(sort_using_group_key(selections, props.groupKey));
        }
        else {
            setLocalSelection(selections)
        }

    }, [selections])


    useEffect(() => {
        if (setSelectBoxClose) {
            setSelectBoxClose(showMenu);
        }
    }, [showMenu])


    useEffect(() => {
        setActiveHint(activeValue)

        if (activeValue && selections && selections.length > 0) {
            let c_group_name = '';
            selections.map((s) => {
                if (s[valueKey] == activeValue && groupKey) {
                    c_group_name = s[groupKey];
                }
            })
            setActiveGroupName(c_group_name)
        }
    }, [activeValue])



    const onFilterValue = (e) => {
        let value = e.target.value;
        let search_value = value && value.length > 0 && value.toLowerCase();
        let cloneMasterData = (selections && selections.length > 0) ? JSON.parse(JSON.stringify(selections)) : [];
        let filterd_data = cloneMasterData && cloneMasterData.length > 0 && cloneMasterData.filter((item) => {
            if (value == '') {
                return true
            }
            if (typeof item === 'object') {
                if (item[displayKey].toLowerCase().indexOf(search_value) > -1) {
                    return true
                }
            }
            if (typeof item == 'string') {
                if (item.toLowerCase().indexOf(search_value) > -1) {
                    return true
                }
            }
            else return false
        });

        if (should_we_group) {
            setLocalSelection(sort_using_group_key(filterd_data, groupKey));
        }
        else {
            setLocalSelection(filterd_data)
        }
    }


    if (valueKey && displayKey) {
        const activeValueObject = localSelection && localSelection.length > 0 && Array.isArray(localSelection) && localSelection.filter(data => data[valueKey] === activeValue);
        nameValue = activeValueObject && activeValueObject.length > 0 ? activeValueObject[0][displayKey] : undefined;
    }


    let convertArrayValueToString = Array.isArray(activeValue) && activeValue.map((id, i) => {
        let _string = ''
        localSelection && localSelection.length > 0 && localSelection.map((select, j) => {
            if (((valueKey && select[valueKey] === id) || select === id)) {
                _string = _string + (displayKey ? select[displayKey] : select)
                if (i !== (activeValue.length - 1)) {
                    _string = _string + "," + " "
                }
            }
        })
        return _string;
    })


    let _active_value_show = nameValue ? (!hideCase ? _.startCase(_.toLower(nameValue)) : nameValue) : activeValue ? (!hideCase ?
        (isMultiSelect ? (Array.isArray(activeValue) ? convertArrayValueToString : activeValue) :
            _.startCase(_.toLower(activeValue)))
        : activeValue) : '';


    let is_menu_process = addAction ? true : localSelection && localSelection.length > 0;
    let previous_group = undefined;

    return (
        <Wrapper style={{ height: removeInputHeight ? 'auto' : undefined }}>
            <input
                className="none_user_input"
                style={{ background: backgroundColor ? backgroundColor : undefined }}
                type="text"
                value={''}
                onFocus={() => {
                    if (is_menu_process) {
                        setShowMenu(true);
                    }
                }}
                onBlur={() => {
                    setTimeout(() => {
                        setShowMenu(false);
                    }, 200)
                }}
            />
            {
                showMenu &&
                <OverLay zIndex={props.overlay_z_index ? props.overlay_z_index : undefined} onClick={() => {
                    // if (!disabled && is_menu_process) {
                    setShowMenu(!showMenu)
                    // }
                }} />
            }
            <SelectBox
                disabled={disabled}
                borderColor={borderColor}
                borderNone={borderNone}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    // if (!disabled && is_menu_process) {
                    setShowMenu(true)
                    // }
                }}
                isDropdown={showMenu}
                style={{
                    zIndex: showMenu ? 3 : 0,
                    cursor: disabled ? "not-allowed" : undefined
                }}
                width={width}
                padding={padding}
                paddingRight={paddingRight}
                lineHeight={lineHeight}>
                {!_active_value_show && (
                    <DefaultLabel
                        isMainLabel={isMainLabel}
                        disabled={disabled}
                        onClick={() => {
                            if (!disabled && is_menu_process) {
                                setShowMenu(!showMenu)
                            }
                        }}
                        isOpen={showMenu}
                        LabelTop={showMenu || (_active_value_show && _active_value_show.length > 0)} >
                        {
                            label ? isRequired ? <span>{label}&nbsp;&nbsp;<sup>*</sup></span> : label : ''
                        }
                    </DefaultLabel>
                )}
                <span className="select_choose" >
                    {activeGroupName && <React.Fragment>
                        {_.startCase(_.toLower(activeGroupName))}
                        &nbsp;&nbsp;<RightArrowSvg size='.8rem' height='.8rem' /> &nbsp;&nbsp;
                    </React.Fragment>}
                    <span style={{ maxWidth: '190px', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                        {
                            _active_value_show ? _active_value_show : ''
                        }
                    </span>
                </span>
                <span className="select_arrow"> <Chevron size={'.7rem'} height={".7rem"} color="rgb(175, 196, 227)" /> </span>

                {
                    showMenu &&
                    <DropDownMenu
                        dropdownShadow={dropdownShadow}
                        height={height}
                        isDropdown={showMenu}
                        lineHeight={lineHeight}
                        menuLineHeight={menuLineHeight}
                        isCheckBox={isCheckBox}
                        dropDownItemAlign={dropDownItemAlign}>

                        {isSearch && (
                            <div
                                style={{ position: 'relative', padding: '0rem 0.6rem', boxSizing: 'border-box', borderBottom: '1px solid #ccc', marginBottom: '.4rem', marginTop: '.4rem', paddingBottom: '.4rem' }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                            >
                                <div className='text_box_wrapper'>
                                    <span>
                                        <SearchSvg size={'.9rem'} height={'.9rem'} color="#afc4e3" />
                                    </span>
                                    <input
                                        className="text_box"
                                        type="text"
                                        placeholder="search this list..."
                                        onChange={(e) => {
                                            onFilterValue(e)
                                        }}
                                    />
                                </div>
                            </div>

                        )}
                        <div className="scroll_div">
                            {isSelecctAll && (
                                <div
                                    className='select_result_sub'>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        padding: '3px 0px',
                                        fontWeight: 'normal',
                                        width: '100%',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        boxSizing: 'border-box',
                                    }}

                                        onClick={() => {
                                            onChangeFunc(undefined, ((localSelection && localSelection.length) === (activeValue && activeValue.length)) ? "unSelectAll" : "selectAll");
                                        }}

                                    >
                                        <CheckBox
                                            size={"35px"}
                                            checked={((localSelection && localSelection.length) === (activeValue && activeValue.length))}
                                            onClick={() => {
                                                onChangeFunc(undefined, ((localSelection && localSelection.length) === (activeValue && activeValue.length)) ? "unSelectAll" : "selectAll");
                                            }}
                                        />
                                        All
                                    </div>
                                </div>
                            )}

                            {props.mt_selection && (
                                <div
                                    key={"option"}
                                    className='select_result_sub'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        onChangeFunc('', valueType)
                                        setShowMenu(false);
                                    }}
                                    style={{ padding: '0px 8px' }}
                                >

                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        padding: '0px 0px',
                                        fontWeight: 'normal',
                                        // color: _is_active ? '#000' : undefined,
                                        width: '100%',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        boxSizing: 'border-box',
                                        fontSize: '.9rem'
                                    }}>
                                        None
                                    </div>
                                </div>

                            )}


                            {
                                localSelection && localSelection.length > 0 && localSelection.map((data, i) => {
                                    const isObject = typeof data === 'object';
                                    let _is_active = isMultiSelect ? (activeValue && activeValue.length > 0 && activeValue.indexOf(data && (valueKey ? data[valueKey] : data)) > -1) : (activeValue == (data && (valueKey ? data[valueKey] : data)))
                                    const this_group_item = should_we_group && (data[groupKey] || '');
                                    const __fire__group__div__ = should_we_group && this_group_item !== prev_group_item;

                                    prev_group_item = this_group_item;

                                    return (
                                        <div>
                                            {__fire__group__div__ && <div style={{ padding: '0rem .4rem', fontSize: '.9rem', paddingBottom: '.6rem', textTransform: 'capitalize' }}>{this_group_item}</div>}
                                            <div
                                                style={{ backgroundColor: _is_active ? '#f0f5fc' : undefined }}
                                                key={"option" + i}
                                                className='select_result_sub'
                                                onClick={(e) => {
                                                    setActiveGroupName(this_group_item)
                                                    e.stopPropagation();
                                                    e.preventDefault();

                                                    if (!isCheckBox && onChangeFunc) {
                                                        if (typeof data === 'object' && !valueType) {
                                                            onChangeFunc(data[valueKey], valueType, data);
                                                            if (!isMultiSelect) {
                                                                setShowMenu(false);
                                                            }
                                                        }
                                                        if (typeof data === 'object' && valueType) {
                                                            onChangeFunc(data[valueKey], valueType, data);
                                                            if (!isMultiSelect) {
                                                                setShowMenu(false);
                                                            }
                                                        }
                                                        if (typeof data !== 'object') {
                                                            onChangeFunc(data);
                                                            if (!isMultiSelect) {
                                                                setShowMenu(false);
                                                            }
                                                        }
                                                    }
                                                }}

                                            >


                                                {

                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center',
                                                        padding: '0px 5px',
                                                        fontWeight: 'normal',
                                                        color: _is_active ? 'rgb(48 139 200)' : undefined,
                                                        width: '100%',
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                        boxSizing: 'border-box',
                                                    }}

                                                        onClick={() => {
                                                            // if(addAction){
                                                            //     addAction()
                                                            // }
                                                            // if(add_action_on_change){
                                                            //     add_action_on_change('')
                                                            // }
                                                            if (onChangeFunc) {
                                                                if (typeof data === 'object' && !valueType) {
                                                                    onChangeFunc(data[valueKey]);

                                                                    if (!isMultiSelect) {
                                                                        setShowMenu(false);
                                                                    }
                                                                }
                                                                if (typeof data === 'object' && valueType) {
                                                                    onChangeFunc(data[valueKey], valueType);
                                                                    if (!isMultiSelect) {
                                                                        setShowMenu(false);
                                                                    }
                                                                }
                                                                if (typeof data !== 'object') {
                                                                    onChangeFunc(data);
                                                                    if (!isMultiSelect) {
                                                                        setShowMenu(false);
                                                                    }
                                                                }
                                                            }
                                                        }}

                                                    >
                                                        {isCheckBox && (
                                                            <CheckBox size="30px"
                                                                checked={_is_active} onClick={() => {
                                                                    if (typeof data === 'object' && !valueType) {
                                                                        onChangeFunc(data[valueKey]);

                                                                        if (!isMultiSelect) {
                                                                            setShowMenu(false);
                                                                        }
                                                                    }
                                                                    if (typeof data === 'object' && valueType) {
                                                                        onChangeFunc(data[valueKey], valueType);
                                                                        if (!isMultiSelect) {
                                                                            setShowMenu(false);
                                                                        }
                                                                    }
                                                                    if (typeof data !== 'object') {
                                                                        onChangeFunc(data);
                                                                        if (!isMultiSelect) {
                                                                            setShowMenu(false);
                                                                        }
                                                                    }
                                                                }} />
                                                        )}

                                                        {
                                                            !isObject &&
                                                            _.startCase(_.toLower(data))
                                                        }

                                                        {
                                                            data && data.icon &&
                                                            <img alt={data.icon ? data.icon : ''} src={constants.CONTEXT_PATH + './' + data.icon} width='20' style={{ marginRight: '15px' }} />
                                                        }
                                                        {
                                                            !hideCase ? _.startCase(_.toLower(data && data[displayKey])) : data && data[displayKey]
                                                        }

                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                (localSelection === undefined || localSelection.length == 0) && (
                                    <p style={{ textTransform: 'uppercase', textAlign: 'center', fontSize: '.7rem', margin: '0px', marginBottom: '.3rem' }}>no Record Found</p>
                                )
                            }
                        </div>

                        {addAction &&
                            <React.Fragment>

                                {(!show_add_input) && (
                                    <div className="add_btn" onClick={() => addAction()} style={{ width: '100%', padding: '.7rem', boxSizing: 'border-box', marginTop: '.3rem', borderTop: '1px solid #eaeaea' }}>
                                        <AddSvg size=".8rem" height=".8rem" color="#308bc8" /> &nbsp;&nbsp;&nbsp;
                                        <span style={{ color: '#308bc8' }}>{props.addActionName ? props.addActionName : 'Add'}</span>
                                    </div>
                                )}

                                {(show_add_input) && (
                                    <div style={{ position: 'relative' }}>
                                        <input autoFocus={true} placeholder="Type Your Menu Here" style={{ height: '2.3rem', width: '100%', border: '1px solid #ccc', boxSizing: 'border-box', padding: '1rem 1.2rem', paddingRight: '3.5rem' }} type="text" value={add_action_value} onChange={(e) => {
                                            add_action_on_change(e.target.value)
                                            // onChangeFunc(e.target.value);
                                        }} />

                                        {add_action_value && add_action_value.length > 0 && (
                                            <span onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                onChangeFunc(add_action_value);
                                                addAction()
                                                setShowMenu(!showMenu);
                                            }} title="Save" className="mark_icon" style={{ right: '2rem' }}>
                                                <MarkSvg size="1rem" height="1rem" color="green" />
                                            </span>
                                        )}

                                        <span title="Close" className="mark_icon" onClick={(e) => {

                                            addAction()
                                            add_action_on_change('')

                                        }}>
                                            <CloseSvg size=".8rem" height=".8rem" />
                                        </span>


                                    </div>
                                )}
                            </React.Fragment>
                        }

                    </DropDownMenu>
                }
            </SelectBox>
        </Wrapper>
    );
};

export default SelectOption;