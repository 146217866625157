import * as actions from '../action-types/report_builder';

const initialState = {
    type: actions.INIT,
    schema_table_info: undefined,
    hints_table: undefined,
    cached_db_information: undefined,
    edit_report_cache: {},
    report_sql_cache: {},
};


const reportBuilder = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }



    switch (action.type) {

        case actions.GET_SCHEMA_TABLE_COLUMN_INFO:

            const schemaTableInfo = action?.data?.schema_table_info;
            const hints_table = action?.data?.hints_table;
            const cached_db_information = action?.data?.cached_db_info

            return {
                ...state,
                type: action.type,
                schema_table_info: schemaTableInfo,
                hints_table: hints_table,
                cached_db_information: cached_db_information
            }

        case actions.NEW_COLUMN_ADDED_SUCCESS:

            const payload_data = action.data
            const prev_table_schema_info = state.schema_table_info ? JSON.parse(JSON.stringify(state.schema_table_info)) : {};
            const current_schema = payload_data && prev_table_schema_info?.[payload_data?.["db_info_id"]];

            if (current_schema && payload_data?.table_name && current_schema?.[payload_data?.table_name] && payload_data.column_name) {

                // let's update the table 

                if (!payload_data.deleted) {

                    if (!current_schema[payload_data.table_name][payload_data.column_name]) {
                        current_schema[payload_data.table_name][payload_data.column_name] = {}
                    }
                    current_schema[payload_data.table_name][payload_data.column_name] = {
                        ...payload_data
                    }
                }
                if (payload_data.deleted) {

                    // delete the column from table

                    delete current_schema[payload_data?.table_name]?.[payload_data?.column_name]

                }

                prev_table_schema_info[payload_data?.["db_info_id"]] = current_schema;
            }

            return {
                ...state,
                type: action.type,
                schema_table_info: prev_table_schema_info,
            }



        case actions.CLEAR_SCHEMA_TABLE_INFO:

            return {
                ...state,
                type: action.type,
                schema_table_info: undefined,
                hints_table: undefined,
                cached_db_information: undefined,
            }

        case actions.SET_ACTIVE_REPORT_FOR_EDIT:

            const clone_edit_report = state?.edit_report_cache ? { ...state.edit_report_cache } : {};
            const report_id = action?.data?.report_id;
            const report = action?.data?.report;
            clone_edit_report[report_id] = report;

            return {
                ...state,
                type: action.type,
                edit_report_cache: clone_edit_report,
            }

        case actions.CLEAR_ACTIVE_EDIT_REPORTS:
            return {
                ...state,
                type: action.type,
                edit_report_cache: {}
            }


        case actions.GET_REPORT_SQL_QUERY:

            const payload = action.data;
            const clone_report_sql_cache = {};
            clone_report_sql_cache[payload.base_report_id] = payload;

            return {
                ...state,
                type: action.type,
                report_sql_cache: clone_report_sql_cache

            }

        case actions.CLEAR_REPORT_SQL_QUERY:

            // const report_id1 = action.data.report_id;
            // const clone_report_sql_cache1 = {};

            return {
                ...state,
                type: action.type,
                report_sql_cache: {}
            }

        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying

        default:
            return {
                ...state
            };
    }
};

export default reportBuilder;