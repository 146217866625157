import React from 'react';

const EnableSvg = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 200.000000 200.000000"
            space="preserve">
            <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
                fill={props.color ? props.color : '#000'} stroke="none">
                <path d="M777 1875 c-166 -45 -299 -175 -348 -337 -29 -93 -24 -254 10 -341
31 -79 104 -172 173 -220 51 -36 60 -39 83 -29 18 9 25 19 25 41 0 24 -10 36
-50 65 -27 20 -65 57 -85 83 -176 240 -58 572 230 649 151 41 328 -21 424
-149 100 -132 116 -289 45 -430 -28 -56 -26 -92 6 -102 36 -11 57 4 83 63 37
85 47 128 47 217 0 97 -16 162 -61 246 -62 116 -183 211 -313 245 -64 17 -205
17 -269 -1z"/>
                <path d="M830 1697 c-91 -26 -173 -97 -213 -186 -32 -70 -30 -189 3 -256 18
-35 32 -51 48 -53 49 -7 65 35 37 101 -57 137 55 306 204 307 109 0 197 -63
226 -164 14 -47 15 -64 5 -104 -25 -99 -13 -132 43 -118 58 14 74 184 27 293
-26 62 -101 136 -165 164 -57 25 -157 33 -215 16z"/>
                <path d="M865 1510 c-17 -5 -44 -23 -60 -40 l-30 -31 -3 -335 c-1 -184 -4
-334 -7 -334 -2 0 -36 18 -77 39 -97 53 -140 59 -197 29 -63 -33 -85 -67 -85
-131 0 -67 -1 -66 221 -302 186 -198 235 -239 331 -276 79 -31 245 -38 328
-14 181 52 323 196 368 371 23 87 23 392 1 435 -24 45 -70 69 -135 69 -50 0
-56 3 -72 30 -33 59 -115 86 -183 60 -20 -8 -27 -7 -31 5 -10 25 -92 58 -130
52 l-34 -6 0 127 c0 143 -15 193 -67 230 -36 25 -96 35 -138 22z m86 -107 c11
-12 15 -84 19 -327 l5 -311 40 0 40 0 5 123 c4 107 8 126 24 138 26 18 45 18
68 -3 16 -14 18 -33 18 -139 0 -130 8 -154 50 -154 40 0 50 26 50 130 0 109
11 134 59 128 40 -5 51 -39 51 -160 l0 -108 27 -6 c45 -12 57 5 63 85 4 57 9
76 24 87 27 19 42 18 66 -6 18 -18 20 -33 20 -155 0 -155 -11 -217 -52 -299
-69 -138 -230 -236 -386 -236 -59 0 -152 28 -211 63 -66 39 -431 421 -431 451
0 26 28 56 52 56 11 0 64 -24 119 -52 l99 -53 0 -40 c0 -46 14 -65 45 -65 45
0 45 3 45 425 0 346 2 404 16 423 18 26 56 29 75 5z"/>
            </g>

        </svg>

    )

}


export default EnableSvg;