import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { get_job_occurences, get_errors_records } from '../../actions/data_wrangler';
import styled from 'styled-components';
import { convert_date_to_dd_mm_yyyy_v1, showPopup } from '../../utils';
import JobLogsPopup from './job.logs.popup';
import * as enums from '../../utils/enums';
import ViewSvg from '../svg/view'
import ErrorRecordsPopup from './job_error_records.popup';



const TableMain = styled.table`
    box-sizing: border-box;
    width: 100% !important;
    margin: auto;
    border-collapse: collapse;
    td, th{
        font-size: .9rem !important;
        padding: .5rem;
        height: 1.8rem;
        border: 0px;
        border-spacing: 0px;
        border: 1px solid #ccc;
    }
 
    thead{
        th{
            z-index: 1 !important;
            background-color: #2173F7;
            color: #fff;
            text-align: left;
            position: sticky;
            top: 0px;
        }
    }

    tr:hover{
        box-shadow: none !important;
        border: 0px !important;
    }
    .view_btn{
        width: 1rem;
        height: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

`;




const JobOccurence = (props) => {

    const { job_id, job_occurrences_cache, job_name } = props;

    const [table_data, set_table_data] = useState(undefined)

    const [active_occurrence_id, set_active_occurrenc_id] = useState()

    const job_occurrences = job_occurrences_cache?.[job_id];

    useEffect(() => {

        job_id && props.get_job_occurences(job_id)

    }, [job_id])


    useEffect(() => {
        const clone_job_occurrences = job_occurrences ? [...job_occurrences] : [];

        clone_job_occurrences.map((r) => {
            const date1 = new Date(r.job_start_time); // Replace this with your first date
            const date2 = new Date(r.job_end_time); // Replace this with your second date

            // Calculate the difference in milliseconds
            const differenceMs = Math.abs(date2 - date1);
            r.duration = differenceMs;
        })
        set_table_data(clone_job_occurrences);

    }, [job_occurrences_cache]);


    const viewJobLogs = (job_occurence_id) => {
        showPopup(undefined, undefined, enums.default.popupType.element, JobLogsPopup, {
            job_occurence_id: job_occurence_id,
            job_name: job_name
        })
    }

    const viewErrorRecords = (job_occurence_id) => {
        showPopup(undefined, undefined, enums.default.popupType.element, ErrorRecordsPopup, {
            job_occurence_id: job_occurence_id,
            job_id: job_id
        })
    }


    const JobLogInfo = () => {
        // job_logs

        const job_log_occurance = table_data?.find((log) => log.id === active_occurrence_id);

        const is_file_job = job_log_occurance?.file_logs?.length > 0;
        const job_log_data = is_file_job ? job_log_occurance?.file_logs : job_log_occurance?.job_logs;


        return (
            <div style={{ padding: '10px 40px', boxSizing: 'border-box' }}>
                {active_occurrence_id && job_occurrences && job_log_data?.length > 0 ? (
                    <React.Fragment>
                        {is_file_job ?
                            <table>

                                <thead>
                                    <tr>
                                        <th>#Id</th>
                                        <th>Created At</th>
                                        <th>Db Table Name</th>
                                        <th>File Status</th>
                                        <th>Full File Path</th>
                                        <th style={{ maxWidth: '70%', width: '70%' }}>Log Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {job_log_data?.map((row, index) => {
                                        return (
                                            <tr >
                                                <td>{row.id}</td>
                                                <td>{row.created_at && convert_date_to_dd_mm_yyyy_v1(row.created_at, true)}</td>
                                                <td>{row.db_table_name}</td>
                                                <td>{row.status}</td>
                                                <td>{row.full_file_name}</td>
                                                <td>{row.error_description}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table> :

                            <table>

                                <thead>
                                    <tr>
                                        <th>#Id</th>
                                        <th>Created At</th>
                                        <th>Log Type</th>
                                        <th>Log Title</th>
                                        <th style={{ maxWidth: '70%', width: '70%' }}>Log Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {job_log_data?.map((row, index) => {
                                        return (
                                            <tr >
                                                <td>{row.id}</td>
                                                <td>{row.created_at && convert_date_to_dd_mm_yyyy_v1(row.created_at, true)}</td>
                                                <td>{row.log_type}</td>
                                                <td>{row.log_header}</td>
                                                <td>{row.log_description}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        }
                    </React.Fragment>

                ) : <p style={{ textAlign: 'center' }}>No Logs</p>}
            </div>
        )

    }


    // console.log("table_data", table_data)

    return (
        <React.Fragment>
            {job_occurrences && table_data?.length > 0 ? (
                <TableMain>
                    <thead>
                        <tr>
                            <th>#Id</th>
                            <th>Start Time</th>
                            <th>End Time</th>

                            <th>Status</th>
                            <th>Total Records</th>
                            <th>Proccesed Records</th>
                            <th>Error Records</th>
                        </tr>
                    </thead>
                    <tbody>
                        {table_data?.map((row, index) => {
                            const is_odd = index % 2 === 0;
                            return (
                                <React.Fragment>
                                    <tr
                                        style={{ cursor: 'pointer', background: is_odd ? '#F7F7FC' : '#fff' }}
                                        onClick={(e) => {
                                            // e.preventDefault();
                                            viewJobLogs(row?.id)
                                            // console.log("CLCIEKKEKEKEKE")
                                        }}>
                                        <td>{row.id}</td>
                                        <td>{row.job_start_time && convert_date_to_dd_mm_yyyy_v1(row.job_start_time, true)}</td>
                                        <td>{row.job_end_time && convert_date_to_dd_mm_yyyy_v1(row.job_end_time, true)}</td>
                                        <td>{row.job_status}</td>
                                        <td>{row.total_records}</td>
                                        <td>{row.processed_records}</td>
                                        <td>
                                            <div title="view error records" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                                {row.error_records}
                                                <div className='view_btn' onClick={(e) => {
                                                    e.stopPropagation()
                                                    viewErrorRecords(row?.id)
                                                }}>
                                                    <ViewSvg size='1rem' />
                                                </div>
                                            </div>
                                        </td>
                                    </tr >
                                    {active_occurrence_id && row.id === active_occurrence_id && (
                                        <tr>
                                            <td colSpan={7}>
                                                <JobLogInfo />
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment >
                            )
                        })}


                    </tbody >
                </TableMain >
            ) : <p style={{ textAlign: 'center' }}>No Occurance</p>}

        </React.Fragment >
    )
}



const mapStateToProps = (state, props) => ({

    job_occurrences_cache: state.dataWrangler.job_occurrences_cache

});


export default connect(mapStateToProps, { get_job_occurences, get_errors_records })(JobOccurence)


