import React from 'react'
import styled from 'styled-components'
import TableSvg from '../svg/table';
import BarChartSvg from '../svg/barChart';
import LineBarChartSvg from '../svg/lineBarChart';
import LineChartSvg from '../svg/lineChart';
import OgiveChaartSvg from '../svg/ogiv.chart.js';
import PieChartSvg from '../svg/pieChart';
import AreaChartSvg from '../svg/areaChart';
import RadarChartSvg from '../svg/radarChart';
import ScatterchartSvg from '../svg/scatterChart'
import StackedChartSvg from '../svg/stackedChart';
import GaugeChart from '../svg/gauge.chart';
import { disableChart } from '../../utils/chart.helper';
import { RENDER_MODE, CHART_TYPES } from '../../shared-with-fe/constants';
import { apply_styles } from '../../utils'
import { constants } from '../../utils/constants';
import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils'
import AreaChart100 from '../svg/area.chart.100%';
import StackedChart100SVG from '../svg/stacked_chart_100.js';
import TreeMapSvg from '../svg/tree.map';
import DonutChart from '../svg/donut';
import GanttChart from '../svg/ganttChart.js'
import WaterFallChart from '../svg/waterFallChart.js';
import HorizontalBarChartSVG from '../svg/horizontalBarChart.js';
import TrendingChartSVG from '../svg/trendingChart.js';
import HorizontalWaterFallChart from '../svg/horizontalWaterFallChart.js';
import BubbleIcon from '../svg/icons-v2/bubbleIcon.js';
import ParetoIcon from '../svg/icons-v2/paretoIcon.js';
import FunnelIcon from '../svg/icons-v2/funnelIcon.js';


const selected = getSelectedTheme();

const { righterMenuConfig } = themes[selected];
const { chartOptions } = righterMenuConfig;
const { chartIcon } = chartOptions;


const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 250px;

`;

const MenuWrapper = styled.div`
    ${props => apply_styles(props._style, constants.STYLED_COMPONENTS.ALL)}
    background: ${props => props.active ? (props._style && props._style.colors && props._style.colors.active_background) : undefined};
    box-shadow: ${props => props.active ? (props._style && props._style.shadow && props._style.shadow.active) : undefined};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-direction: column;


    border-radius: ${props => props.active ? '3px' : '3px'};

    &:hover{
        background: ${props => props._style && props._style.colors && props._style.colors.active_background };
        /* box-shadow: ${props => (props.disable || props.active) ? '0px' : (props._style && props._style.shadow && props._style.shadow.hover)}; */
        cursor: ${props => props.disable ? 'not-allowed' : 'pointer'};

        div{
            font-weight: ${props => (props.disable) ? '300' : '500'};
        }
    }
`;


const Label = styled.div`
    ${props => apply_styles(props._style, constants.STYLED_COMPONENTS.ALL)}
    min-height: 1rem;
    max-height: 1.6rem;
    height: 1.4rem;
    /* font-size: 15px;
    margin-top: 6px;
    color: ${chartOptions.color.labelColor};
    font-weight: 300;
    text-align: center; */
`;


const ChartOption = (props) => {

    const { changeChartType, chartType, renderMode, trendFilters, numberOfData, showSingleCell, drawerMenuStyle, raw_axis } = props;

    const chart_types = [

        {
            label: 'Table',
            type: RENDER_MODE.table,
            icon: <TableSvg
                color={disableChart(RENDER_MODE.table, undefined, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === RENDER_MODE.table ? chartIcon.color.iconActive : chartIcon.color.icon)}
                disable={disableChart(RENDER_MODE.table, undefined, showSingleCell, trendFilters)}
            />
        },
        {
            label: 'Bar',
            type: CHART_TYPES.bar_chart,
            icon: <BarChartSvg
                color={disableChart(CHART_TYPES.bar_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.bar_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                disable={disableChart(CHART_TYPES.bar_chart, numberOfData, showSingleCell, trendFilters)}
            />
        },
        {
            label: 'Line Bar',
            type: CHART_TYPES.line_bar_chart,
            icon: <LineBarChartSvg
                size="24px"
                height="24px"
                color={disableChart(CHART_TYPES.line_bar_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.line_bar_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                disable={disableChart(CHART_TYPES.line_bar_chart, numberOfData, showSingleCell, trendFilters)}
            />
        },
        {
            label: 'Stacked',
            type: CHART_TYPES.stacked_bar_chart,
            icon: <StackedChartSvg
                color={disableChart(CHART_TYPES.stacked_bar_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.stacked_bar_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                disable={disableChart(CHART_TYPES.stacked_bar_chart, numberOfData, showSingleCell, trendFilters)}
                size="24px"
                height="24px"
            />
        },
        {
            label: 'Trending',
            type: CHART_TYPES.line_chart,
            icon: <LineChartSvg
                color={disableChart(CHART_TYPES.line_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.line_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                size="24px"
                height="24px"
                style={{ marginBottom: '1px' }}
                disable={disableChart(CHART_TYPES.line_chart, numberOfData, showSingleCell, trendFilters)}
            />
        },
        {
            label: 'Pie',
            type: CHART_TYPES.pie,
            icon: <PieChartSvg
                color={disableChart(CHART_TYPES.pie_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.pie_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                size="24px"
                height="24px"
                disable={disableChart(CHART_TYPES.pie_chart, numberOfData, showSingleCell, trendFilters)}
            />
        },
        {
            label: 'TreeMap',
            type: "tree_map",
            icon: <TreeMapSvg
                color={disableChart("tree_map", numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === "tree_map" ? chartIcon.color.iconActive : chartIcon.color.icon)}
                size="24px"
                height="24px"
                disable={disableChart("tree_map", numberOfData, showSingleCell, trendFilters)}
            />
        },
        {
            label: 'Area',
            type: CHART_TYPES.area_chart,
            icon: <AreaChartSvg
                color={disableChart(CHART_TYPES.area_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.area_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                size="24px"
                height="24px"
                disable={disableChart(CHART_TYPES.area_chart, numberOfData, showSingleCell, trendFilters)}
            />
        },
        {
            label: 'Radar',
            type: CHART_TYPES.radar_chart,
            icon: <RadarChartSvg
                color={disableChart(CHART_TYPES.radar_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.radar_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                size="24px"
                height="24px"
                disable={disableChart(CHART_TYPES.radar_chart, numberOfData, showSingleCell, trendFilters)}
            />
        },
        {
            label: 'Scatter',
            type: CHART_TYPES.scatter_chart,
            icon: <ScatterchartSvg
                color={disableChart(CHART_TYPES.scatter_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.scatter_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                size="24px"
                height="24px"
                disable={disableChart(CHART_TYPES.scatter_chart, numberOfData, showSingleCell, trendFilters)}
            />
        },
        {
            label: 'Gauge Chart',
            type: CHART_TYPES.scatter_chart,
            icon: <ScatterchartSvg
                color={disableChart(CHART_TYPES.scatter_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.scatter_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                size="24px"
                height="24px"
                disable={disableChart(CHART_TYPES.scatter_chart, numberOfData, showSingleCell, trendFilters)}
            />
        }
    ];

    
    return (
        <Wrapper>

            {/* {chart_types && chart_types.length > 0 && chart_types.map((type, index) => {
                return (
                    <MenuWrapper
                        _style={drawerMenuStyle}
                        title="Table"
                        onClick={() => changeChartType(type.type)}
                        active={chartType === type.type}
                    // disable={disableChart(type.type, undefined, showSingleCell, trendFilters)}
                    >
                        {type.icon}
                        <Label _style={drawerMenuStyle}>{type.label}</Label>
                    </MenuWrapper>
                )
            })} */}


            {/* <MenuWrapper
                _style={drawerMenuStyle}
                title="Table"
                onClick={() => changeChartType(RENDER_MODE.table)}
                active={chartType === RENDER_MODE.table}
                disable={disableChart(RENDER_MODE.table, undefined, showSingleCell, trendFilters)}
            >
                <TableSvg
                    color={disableChart(RENDER_MODE.table, undefined, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === RENDER_MODE.table ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    size="24px"
                    height="24px"
                    disable={disableChart(RENDER_MODE.table, undefined, showSingleCell, trendFilters)}
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Table</Label>
            </MenuWrapper> */}

            <MenuWrapper
                _style={drawerMenuStyle}
                title="Bar"
                active={(chartType === CHART_TYPES.bar_chart)}
                disable={disableChart(CHART_TYPES.bar_chart, numberOfData, showSingleCell, trendFilters)}
                onClick={() => !disableChart(CHART_TYPES.bar_chart, numberOfData, showSingleCell, trendFilters) && changeChartType(CHART_TYPES.bar_chart)}
            >
                <BarChartSvg
                    size="2.1rem"
                    height="2.1rem"
                    color={disableChart(CHART_TYPES.bar_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.bar_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    disable={disableChart(CHART_TYPES.bar_chart, numberOfData, showSingleCell, trendFilters)}
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Bar</Label>
            </MenuWrapper>

            {/* <MenuWrapper
                _style={drawerMenuStyle}
                title="Line Bar"
                active={(chartType === CHART_TYPES.line_bar_chart)}
                disable={disableChart(CHART_TYPES.line_bar_chart, numberOfData, showSingleCell, trendFilters)}
                onClick={() => !disableChart(CHART_TYPES.line_bar_chart, numberOfData, showSingleCell, trendFilters) && changeChartType(CHART_TYPES.line_bar_chart)}
            >
                <LineBarChartSvg
                    size="24px"
                    height="24px"
                    color={disableChart(CHART_TYPES.line_bar_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.line_bar_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    disable={disableChart(CHART_TYPES.line_bar_chart, numberOfData, showSingleCell, trendFilters)}
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Line Bar</Label>
            </MenuWrapper> */}
            <MenuWrapper
                _style={drawerMenuStyle}
                title="Water Fall"
                active={(chartType === CHART_TYPES.waterfall_chart)}
                disable={disableChart(CHART_TYPES.waterfall_chart, numberOfData, showSingleCell, trendFilters)}
                onClick={() => !disableChart(CHART_TYPES.waterfall_chart, numberOfData, showSingleCell, trendFilters) && changeChartType(CHART_TYPES.waterfall_chart)}
            >
                <WaterFallChart
                    size="2.1rem"
                    height="2.1rem"
                    color={disableChart(CHART_TYPES.waterfall_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.waterfall_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    disable={disableChart(CHART_TYPES.waterfall_chart, numberOfData, showSingleCell, trendFilters)}
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Water Fall</Label>
            </MenuWrapper>

            <MenuWrapper
                _style={drawerMenuStyle}
                title="Horizontal Water Fall"
                active={(chartType === CHART_TYPES.horizontal_waterfall_chart)}
                disable={disableChart(CHART_TYPES.horizontal_waterfall_chart, numberOfData, showSingleCell, trendFilters)}
                onClick={() => !disableChart(CHART_TYPES.horizontal_waterfall_chart, numberOfData, showSingleCell, trendFilters) && changeChartType(CHART_TYPES.horizontal_waterfall_chart)}
            >
                <HorizontalWaterFallChart
                    size="2.1rem"
                    height="2.1rem"
                    color={disableChart(CHART_TYPES.horizontal_waterfall_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.horizontal_waterfall_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    disable={disableChart(CHART_TYPES.horizontal_waterfall_chart, numberOfData, showSingleCell, trendFilters)}
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Horizontal Water Fall</Label>
            </MenuWrapper>
            <MenuWrapper
                _style={drawerMenuStyle}
                title="Horizontal Bar"
                active={(chartType === CHART_TYPES.horizontal_bar_chart)}
                disable={disableChart(CHART_TYPES.horizontal_bar_chart, numberOfData, showSingleCell, trendFilters)}
                onClick={() => !disableChart(CHART_TYPES.horizontal_bar_chart, numberOfData, showSingleCell, trendFilters) && changeChartType(CHART_TYPES.horizontal_bar_chart)}
            >
                <HorizontalBarChartSVG
                    size="2.1rem"
                    height="2.1rem"
                    color={disableChart(CHART_TYPES.horizontal_bar_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.horizontal_bar_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    disable={disableChart(CHART_TYPES.horizontal_bar_chart, numberOfData, showSingleCell, trendFilters)}
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Horizontal Bar</Label>
            </MenuWrapper>



            <MenuWrapper
                _style={drawerMenuStyle}
                title="Stacked"
                active={(chartType === CHART_TYPES.stacked_bar_chart)}
                disable={disableChart(CHART_TYPES.stacked_bar_chart, numberOfData, showSingleCell, trendFilters)}
                onClick={() => !disableChart(CHART_TYPES.stacked_bar_chart, numberOfData, showSingleCell, trendFilters) && changeChartType(CHART_TYPES.stacked_bar_chart)}
            >
                <StackedChartSvg
                    color={disableChart(CHART_TYPES.stacked_bar_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.stacked_bar_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    disable={disableChart(CHART_TYPES.stacked_bar_chart, numberOfData, showSingleCell, trendFilters)}
                    size="2.1rem"
                    height="2.1rem"
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Stacked</Label>
            </MenuWrapper>

            {/* <MenuWrapper
                _style={drawerMenuStyle}
                title="Stacked_Bar_100%"
                active={(chartType === CHART_TYPES.stacked_bar_chart_100)}
                disable={disableChart(CHART_TYPES.stacked_bar_chart_100, numberOfData, showSingleCell, trendFilters)}
                onClick={() => !disableChart(CHART_TYPES.stacked_bar_chart_100, numberOfData, showSingleCell, trendFilters) && changeChartType(CHART_TYPES.stacked_bar_chart_100)}
            >
                <StackedChart100SVG
                    color={disableChart(CHART_TYPES.stacked_bar_chart_100, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.stacked_bar_chart_100 ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    disable={disableChart(CHART_TYPES.stacked_bar_chart_100, numberOfData, showSingleCell, trendFilters)}
                    size="24px"
                    height="24px"
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Stacked 100%</Label>
            </MenuWrapper> */}

            {/* <MenuWrapper
                _style={drawerMenuStyle}
                title="Horizontal Stacked"
                active={(chartType === CHART_TYPES.horizontal_stack_bar_chart)}
                disable={disableChart(CHART_TYPES.horizontal_stack_bar_chart, numberOfData, showSingleCell, trendFilters)}
                onClick={() => !disableChart(CHART_TYPES.horizontal_stack_bar_chart, numberOfData, showSingleCell, trendFilters) && changeChartType(CHART_TYPES.horizontal_stack_bar_chart)}
            >
                <StackedChart100SVG
                    color={disableChart(CHART_TYPES.horizontal_stack_bar_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.horizontal_stack_bar_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    disable={disableChart(CHART_TYPES.horizontal_stack_bar_chart, numberOfData, showSingleCell, trendFilters)}
                    size="24px"
                    height="24px"
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Horizontal Stacked</Label>
            </MenuWrapper>


            <MenuWrapper
                _style={drawerMenuStyle}
                title="Horizontal Stacked 100%"
                active={(chartType === CHART_TYPES.horizontal_stack_bar_chart_100)}
                disable={disableChart(CHART_TYPES.horizontal_stack_bar_chart_100, numberOfData, showSingleCell, trendFilters)}
                onClick={() => !disableChart(CHART_TYPES.horizontal_stack_bar_chart_100, numberOfData, showSingleCell, trendFilters) && changeChartType(CHART_TYPES.horizontal_stack_bar_chart_100)}
            >
                <StackedChart100SVG
                    color={disableChart(CHART_TYPES.horizontal_stack_bar_chart_100, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.horizontal_stack_bar_chart_100 ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    disable={disableChart(CHART_TYPES.horizontal_stack_bar_chart_100, numberOfData, showSingleCell, trendFilters)}
                    size="24px"
                    height="24px"
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Horizontal Stacked 100%</Label>
            </MenuWrapper> */}

            <MenuWrapper
                _style={drawerMenuStyle}
                title="Trending"
                onClick={() => !disableChart(CHART_TYPES.line_chart, numberOfData, showSingleCell, trendFilters) && changeChartType(CHART_TYPES.line_chart)}
                active={chartType === CHART_TYPES.line_chart}
                disable={disableChart(CHART_TYPES.line_chart, numberOfData, showSingleCell, trendFilters)}
            >
                <TrendingChartSVG
                    color={disableChart(CHART_TYPES.line_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.line_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    size="2.1rem"
                    height="2.1rem"
                    style={{ marginBottom: '1px' }}
                    disable={disableChart(CHART_TYPES.line_chart, numberOfData, showSingleCell, trendFilters)}
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Trending</Label>
            </MenuWrapper>

            <MenuWrapper
                _style={drawerMenuStyle}
                title="Ogive"
                onClick={() => !disableChart(CHART_TYPES.ogive_chart, numberOfData, showSingleCell, trendFilters) && changeChartType(CHART_TYPES.ogive_chart)}
                active={chartType === CHART_TYPES.ogive_chart}
                disable={disableChart(CHART_TYPES.ogive_chart, numberOfData, showSingleCell, trendFilters)}
            >
                <OgiveChaartSvg
                    color={disableChart(CHART_TYPES.ogive_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.ogive_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    size="2.1rem"
                    height="2.1rem"
                    style={{ marginBottom: '1px' }}
                    disable={disableChart(CHART_TYPES.ogive_chart, numberOfData, showSingleCell, trendFilters)}
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Ogive</Label>
            </MenuWrapper>



            <MenuWrapper
                _style={drawerMenuStyle}
                title="Area"
                onClick={() => !disableChart(CHART_TYPES.area_chart, numberOfData, showSingleCell, trendFilters) && changeChartType(CHART_TYPES.area_chart)}
                active={chartType === CHART_TYPES.area_chart}
                disable={disableChart(CHART_TYPES.area_chart, numberOfData, showSingleCell, trendFilters)}
            >
                <AreaChartSvg
                    color={disableChart(CHART_TYPES.area_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.area_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    size="2.1rem"
                    height="2.1rem"
                    disable={disableChart(CHART_TYPES.area_chart, numberOfData, showSingleCell, trendFilters)}
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Area </Label>
            </MenuWrapper>

            {/* <MenuWrapper
                _style={drawerMenuStyle}
                title="Area_Chart_100%"
                onClick={() => !disableChart(CHART_TYPES.area_chart_100, numberOfData, showSingleCell, trendFilters) && changeChartType(CHART_TYPES.area_chart_100)}
                active={chartType === CHART_TYPES.area_chart_100}
                disable={disableChart(CHART_TYPES.area_chart_100, numberOfData, showSingleCell, trendFilters)}
            >
                <AreaChart100
                    color={disableChart(CHART_TYPES.area_chart_100, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.area_chart_100 ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    size="24px"
                    height="24px"
                    disable={disableChart(CHART_TYPES.area_chart_100, numberOfData, showSingleCell, trendFilters)}
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Area 100% </Label>
            </MenuWrapper> */}

            <MenuWrapper
                _style={drawerMenuStyle}
                title='Pie Chart'
                onClick={() => !disableChart(CHART_TYPES.pie_chart, numberOfData, showSingleCell, trendFilters) && changeChartType(CHART_TYPES.pie_chart)}
                active={chartType === CHART_TYPES.pie_chart}
                disable={disableChart(CHART_TYPES.pie_chart, numberOfData, showSingleCell, trendFilters)}
            >
                <PieChartSvg
                    color={disableChart(CHART_TYPES.pie_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.pie_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    size="2.1rem"
                    height="2.1rem"
                    disable={disableChart(CHART_TYPES.pie_chart, numberOfData, showSingleCell, trendFilters)}
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Pie </Label>
            </MenuWrapper>

            <MenuWrapper
                _style={drawerMenuStyle}
                title="Pareto"
                onClick={() => !disableChart(CHART_TYPES.pareto_chart, numberOfData, showSingleCell, trendFilters, raw_axis) && changeChartType(CHART_TYPES.pareto_chart)}
                active={chartType === CHART_TYPES.pareto_chart}
                disable={disableChart(CHART_TYPES.pareto_chart, numberOfData, showSingleCell, trendFilters, raw_axis)}
            >
                <ParetoIcon
                    color={disableChart(CHART_TYPES.pareto_chart, numberOfData, showSingleCell, trendFilters, raw_axis) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.pareto_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    size="3rem"
                    height="3rem"
                    style={{ marginBottom: '1px' }}
                    disable={disableChart(CHART_TYPES.pareto_chart, numberOfData, showSingleCell, trendFilters, raw_axis)}
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Pareto</Label>
            </MenuWrapper>


            <MenuWrapper
                _style={drawerMenuStyle}
                title='Tree Map Chart'
                onClick={() => !disableChart("tree_map", numberOfData, showSingleCell, trendFilters) && changeChartType("tree_map")}
                active={chartType === "tree_map"}
                disable={disableChart("tree_map", numberOfData, showSingleCell, trendFilters)}
            >
                <TreeMapSvg
                    color={disableChart("tree_map", numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === "tree_map" ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    size="2.1rem"
                    height="2.1rem"
                    disable={disableChart("tree_map", numberOfData, showSingleCell, trendFilters)}
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Tree Map </Label>
            </MenuWrapper>

            <MenuWrapper
                _style={drawerMenuStyle}
                title="Radar"
                onClick={() => !disableChart(CHART_TYPES.radar_chart, numberOfData, showSingleCell, trendFilters) && changeChartType(CHART_TYPES.radar_chart)}
                active={chartType === CHART_TYPES.radar_chart}
                disable={disableChart(CHART_TYPES.radar_chart, numberOfData, showSingleCell, trendFilters)}
            >
                <RadarChartSvg
                    color={disableChart(CHART_TYPES.radar_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.radar_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    size="2.1rem"
                    height="2.1rem"
                    disable={disableChart(CHART_TYPES.radar_chart, numberOfData, showSingleCell, trendFilters)}
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Radar </Label>
            </MenuWrapper>

            {/* <MenuWrapper
                _style={drawerMenuStyle}
                title="Gauge"
                onClick={() => !disableChart(CHART_TYPES.gauge_chart, numberOfData, showSingleCell, trendFilters) && changeChartType(CHART_TYPES.gauge_chart)}
                active={chartType === CHART_TYPES.gauge_chart}
                disable={disableChart(CHART_TYPES.gauge_chart, numberOfData, showSingleCell, trendFilters)}
            >
                <GaugeChartSvg
                    color={disableChart(CHART_TYPES.gauge_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.gauge_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    size="25px"
                    height="25px"
                    disable={disableChart(CHART_TYPES.gauge_chart, numberOfData, showSingleCell, trendFilters)}
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Gauge</Label>
            </MenuWrapper> */}


            <MenuWrapper
                _style={drawerMenuStyle}
                title="Scatter"
                onClick={() => !disableChart(CHART_TYPES.scatter_chart, numberOfData, showSingleCell, trendFilters) && changeChartType(CHART_TYPES.scatter_chart)}
                active={chartType === CHART_TYPES.scatter_chart}
                disable={disableChart(CHART_TYPES.scatter_chart, numberOfData, showSingleCell, trendFilters)}
            >
                <ScatterchartSvg
                    color={disableChart(CHART_TYPES.scatter_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.scatter_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    size="2.1rem"
                    height="2.1rem"
                    disable={disableChart(CHART_TYPES.scatter_chart, numberOfData, showSingleCell, trendFilters)}
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Scatter</Label>
            </MenuWrapper>

            <MenuWrapper
                _style={drawerMenuStyle}
                title="Bubble"
                onClick={() => !disableChart(CHART_TYPES.bubble_chart, numberOfData, showSingleCell, trendFilters) && changeChartType(CHART_TYPES.bubble_chart)}
                active={chartType === CHART_TYPES.bubble_chart}
                disable={disableChart(CHART_TYPES.bubble_chart, numberOfData, showSingleCell, trendFilters)}
            >
                <BubbleIcon
                    color={disableChart(CHART_TYPES.bubble_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.bubble_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    size="2.1rem"
                    height="2.1rem"
                    disable={disableChart(CHART_TYPES.bubble_chart, numberOfData, showSingleCell, trendFilters)}
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Bubble</Label>
            </MenuWrapper>

            <MenuWrapper
                _style={drawerMenuStyle}
                title="Guage Chart"
                active={(chartType === CHART_TYPES.gauge_chart)}
                disable={disableChart(CHART_TYPES.gauge_chart, numberOfData, showSingleCell, trendFilters)}
                onClick={() => !disableChart(CHART_TYPES.gauge_chart, numberOfData, showSingleCell, trendFilters) && changeChartType(CHART_TYPES.gauge_chart)}
            >
                <GaugeChart
                    size="2.1rem"
                    height="2.1rem"
                    color={disableChart(CHART_TYPES.gauge_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.gauge_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    disable={disableChart(CHART_TYPES.gauge_chart, numberOfData, showSingleCell, trendFilters)}
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Gauge</Label>
            </MenuWrapper>

            <MenuWrapper
                _style={drawerMenuStyle}
                title="Donut Chart"
                active={(chartType === CHART_TYPES.donut_chart)}
                disable={disableChart(CHART_TYPES.donut_chart, numberOfData, showSingleCell, trendFilters)}
                onClick={() => !disableChart(CHART_TYPES.donut_chart, numberOfData, showSingleCell, trendFilters) && changeChartType(CHART_TYPES.donut_chart)}
            >
                <DonutChart
                    size="2.1rem"
                    height="2.1rem"
                    color={disableChart(CHART_TYPES.donut_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.donut_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    disable={disableChart(CHART_TYPES.gauge_chart, numberOfData, showSingleCell, trendFilters)}
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Donut</Label>
            </MenuWrapper>


            <MenuWrapper
                _style={drawerMenuStyle}
                title="Gantt Chart"
                active={(chartType === CHART_TYPES.gantt_chart)}
                disable={disableChart(CHART_TYPES.gantt_chart, numberOfData, showSingleCell, trendFilters)}
                onClick={() => !disableChart(CHART_TYPES.gantt_chart, numberOfData, showSingleCell, trendFilters) && changeChartType(CHART_TYPES.gantt_chart)}
            >
                <GanttChart
                    size="2.1rem"
                    height="2.1rem"
                    color={disableChart(CHART_TYPES.gantt_chart, numberOfData, showSingleCell, trendFilters) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.gantt_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    disable={disableChart(CHART_TYPES.gantt_chart, numberOfData, showSingleCell, trendFilters)}
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Gantt</Label>
            </MenuWrapper>

            <MenuWrapper
                _style={drawerMenuStyle}
                title="Funnel Chart"
                active={(chartType === CHART_TYPES.funnel_chart)}
                disable={disableChart(CHART_TYPES.funnel_chart, numberOfData, showSingleCell, trendFilters, raw_axis)}
                onClick={() => !disableChart(CHART_TYPES.funnel_chart, numberOfData, showSingleCell, trendFilters, raw_axis) && changeChartType(CHART_TYPES.funnel_chart)}
            >
                <FunnelIcon
                    size="2.1rem"
                    height="2.1rem"
                    color={disableChart(CHART_TYPES.funnel_chart, numberOfData, showSingleCell, trendFilters, raw_axis) ? chartIcon.color.iconDisable : (chartType === CHART_TYPES.funnel_chart ? chartIcon.color.iconActive : chartIcon.color.icon)}
                    disable={disableChart(CHART_TYPES.funnel_chart, numberOfData, showSingleCell, trendFilters,raw_axis)}
                />
                <Label _style={drawerMenuStyle && drawerMenuStyle.label}>Funnel</Label>
            </MenuWrapper>

        </Wrapper>



    )
}


export default ChartOption
