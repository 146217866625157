import React from 'react';
import { connect } from 'react-redux';

import { hideOverlay } from '../../actions/overlay'

const Overlay = (props) => {


    const { onClick, zIndex, bgColor, global, top } = props;


    return (
        <div
            style={{
                position: 'fixed',
                top: top || 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                zIndex: zIndex ? zIndex : '1',
                backgroundColor: bgColor ? bgColor : 'transparent'
            }}
            onClick={() => {
                if (onClick) {
                    onClick();
                }

                if (global) {
                    props.hideOverlay();
                }
            }}
        >

        </div>
    );
}


export default connect(undefined, { hideOverlay })(Overlay);