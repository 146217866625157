import * as actions from '../action-types/entity';

const initialState = {
    type: actions.ENTITY_INIT,
    entities: undefined,
};


const entityReducer = (state, action) => {
	if (!state || typeof action.type === 'undefined') {
		return initialState;
    }

    switch (action.type) {
        case actions.GET_ENTITY_SUCCESS:
            return {
                ...state,
                type: action.type,
                entities: action.data
            };

        case actions.CLEAR_ENTITY_SUCCESS:
            return {
                ...state,
                type: action.type,
                entities: undefined
            };

        case actions.SAVE_ENTITY_SUCCESS:
            return {
                ...state,

            //    do stuff what u wnt 
            
            };

        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
			return {
                type: state.type,
                entities: state.entities
            };
        }
};

export default entityReducer;