import { get_formated_cell_data } from "../../utils";

export const determineSingleCellDisplay = (dataFromServer, columnMeta) => {
    const clone_data = dataFromServer ? JSON.parse(JSON.stringify(dataFromServer)) : {};

    let selectedData = undefined;
    let singleCellTitle = '';
    let showSingleCell = false;
    let singleCellData = undefined;

    if (clone_data?.pivot_data?.data?.length > 0) {
        selectedData = clone_data?.pivot_data?.data;
    } else {
        selectedData = clone_data?.raw_data?.data;
    }

    if (selectedData?.length === 1 && Object.keys(selectedData[0])?.length <= 2) {
        const firstItem = Object.keys(selectedData[0]);

        if (selectedData[0][firstItem[0]] === 'No Values Found') {
            showSingleCell = false;
        } else {
            showSingleCell = true;
            singleCellData = selectedData[0];
        }
    }

    if (singleCellData && Object.keys(singleCellData)?.length > 0) {
        Object.keys(singleCellData).forEach((k) => {
            const value = get_formated_cell_data(columnMeta, k, singleCellData[k]);

            if (!singleCellTitle) {
                singleCellTitle += 'Here is your ' + k + ": " + `<strong>${value}</strong>`;
            } else {
                singleCellTitle += " And " + k + ": " + `<strong>${value}</strong>`;
            }
        });
    }

    const parsed_text = `<p style="margin:0px; font-weight: 300; color: #333; font-size: 1rem">${singleCellTitle}</p>`;

    return { showSingleCell, singleCellData, singleCellTitle: parsed_text };
};




export const generateFileMetaHTML = (fileData) => {
    let result = `<div style="padding: 0px;">`;

    console.log("fileData", fileData)
    if (fileData?.length > 0) {
        result += `<p style="color: green; font-weight: 300;margin-top: 0px">File Uploaded Successfully! Here are the details:</p>`;

        fileData.forEach((d) => {

            const sheet_title = d?.sheet_name ? (" And Sheet Name " + `<strong>${d?.sheet_name}</strong>`) : ''

            result += `
          <p style="font-size: 17px; color: #333;"><strong>File Name:</strong> ${d.file_name} ${sheet_title}</p>
          <ul style="padding-left: 1rem; font-weight: 300">
            <li>Total Records: ${d?.['total_records'] || 0}</li>
            <li>Processed Records: ${d?.['processed'] || 0}</li>
            <li>Error Records: ${d?.['errored'] || 0}</li>
          </ul>
        `;
            result += `<p style="font-size: 17px; color: #333;">Here are the Attributes Names</p>`
            // result +=`<p style="margin-left:10px">${d?.headers?.join(",")}</p>`
            result += `<ul style="padding-left: 1rem; font-weight: 300">`
            d?.final_columns_set?.map((p) => {
                result += `<li>${p?.column_name}</li>`
            })
            result += "</ul>"
        });



    } else {
        result += `<p style="color: red;">No data available to display.</p>`;
    }

    result += '</div>';
    return result;
};

const regexToCheckAndMatchDashboardNameAndQuestion = /(?:add\s+(?:this\s+kpi|question)\s*(?:and\s*)?)?(.*?\S)(?:\s+to|for)\s+([a-zA-Z0-9_ ]+?)\.?$/i;
// const regexToCheckAndMatchDashboardNameAndQuestion =  /(?:add\s+(?:this\s+kpi|question)\s*(?:and\s*)?)?(.*?)(?:\s+(?:to|for))?\s+([a-zA-Z0-9_]+)\.?$/i;

export const parseDashboardQuery = (query) => {
    const regex = /related to ([^,]+?(?=\b(?: and|created by|before|on|after|$)))/g;
    const dateRegex = /(?:before|on|after) "([^"]+)"/g;
    const createdByRegex = /created by ([^,]+(?: and [^,]+)*)/g;

    const results = {
        relatedTo: [],
        dates: [],
        createdBy: []
    };
    // Extract "related to" keywords
    let match;
    while ((match = regex.exec(query)) !== null) {
        results.relatedTo.push(...match[1].split(" and ").map(item => item.trim()));
    }
    // Extract dates
    while ((match = dateRegex.exec(query)) !== null) {
        results.dates.push(match[1]);
    }
    // Extract "created by" names
    while ((match = createdByRegex.exec(query)) !== null) {
        results.createdBy.push(...match[1].split(" and ").map(item => item.trim()));
    }
    return results;
};

export const static_response_parser = (input) => {

    if (!input) return;



    const normalizedInput = input.trim().toLowerCase();
    const userName = '{}';
    // for adding a chart to dashboard
    const findKpiName = normalizedInput?.match(regexToCheckAndMatchDashboardNameAndQuestion);
    const dashboardQuestions = findKpiName?.[1];
    const dashboardName = findKpiName?.[2];

    // for dashboard list and its filter
    // const dashboardListSearch = normalizedInput.match(regexFOrDashboardListAndFilter)
    // const filterList = parseDashboardQuery(normalizedInput);
    const patterns = [
        { 
            regex: /add this kpi to|add this kpi|add this question|add this question to|add question to|add question to|update this to/i, 
            response: "Link to Updated Dashboard",
            action: 'UPDATE_A_DASHBOARD',
            subType:'link',
            kpiName: dashboardName,
        },
        // {
        //     regex: /.*dashboard.*/i,
        //     response: "Sure, please wait...",
        //     action: 'OPEN_DASHBOARD_FORM'
        // },
        {
            regex: /.*upload.*/i,
            response: "Sure, please wait...",
            action: 'OPEN_FILE_UPLOAD'
        }


        // { regex: /hi|hello|hey|howdy|Hi Gitti/i, response: "Hello! How are you today?" },
        // { regex: /ok|okey|okhh|done|sure/i, response: " Alright!" },
        // { regex: /how are you|how's it going|how are things/i, response: "I am good, what about you?" },
        // { regex: /thank you|thanks|thanku|thankuu|thankuuhh/i, response: "You're welcome! If you need anything else, feel free to ask." },
        // { regex: /please create a dashboard for me|create dashboard|create a dashboard|i want to create the dashboard| please open dashboard form|i want to create a dashboard/i, response: "Sure, please wait...", action: 'OPEN_DASHBOARD_FORM' },
        // { regex: /upload a file|upload a file for me| upload the data| upload data|i want to upload a file|upload file|i want to upload csv/i, response: "Sure, please wait...", action: 'OPEN_FILE_UPLOAD' },
        // { regex: /create a dashboard|open dashboard/i, response: "Sure, please wait...", action: 'OPEN_DASHBOARD_FORM' },
        // { regex: /what is my name/i, response: `Your name is ${userName}` },
        // { regex: /what time is it|current time|what is the time current time|what is the time/i, response: `The current time is ${new Date().toLocaleTimeString()}.` },
        // { regex: /what day is it|today|what is the date|what date is it/i, response: `Today is ${new Date().toLocaleDateString()}.` },
        // { regex: /tell me a joke/i, response: "Why don't scientists trust atoms? Because they make up everything!" },
        // { regex: /how's the weather|what is the weather like/i, response: "I don't have access to real-time weather data, but you can check a weather app." },
        // { regex: /goodbye|bye|see you|byee/i, response: "Goodbye! Have a great day!" },
        // { regex: /what is your name/i, response: "I am your Data Bot" },
        // { regex: /what can you do/i, response: "I can answer questions, provide information, and help with various tasks." },
        // { regex: /help/i, response: "How can I assist you? You can ask me questions or request specific actions." },

        // { regex: /repeat that/i, response: "Sure, here's what I said: [previous response]." },

        // { regex: /what is the meaning of life/i, response: "The meaning of life is a philosophical question that has been debated for centuries." },
        // { regex: /what is your purpose/i, response: "My purpose is to assist you with your questions and tasks." },

        // { regex: /how old are you/i, response: "I don't have an age, but I'm here to help you!" },
        // { regex: /where are you located/i, response: "I exist in the digital realm, so I am everywhere and nowhere at the same time." },

        // { regex: /what is the capital of [a-z ]+/i, response: "I can help with geographical questions if you specify the country." },

        // { regex: /define [a-z ]+/i, response: "Please provide a specific term you want defined." },

        // { regex: /how can I contact support/i, response: "You can contact support by visiting our support page or emailing support@example.com." },

        // { regex: /what is your favorite color/i, response: "As an AI, I don't have personal preferences, but I can help you with information on colors." },

        // { regex: /how do I reset my password/i, response: "To reset your password, follow the instructions on the 'Forgot Password' page." },
        // { regex: /what are your working hours/i, response: "I am available 24/7 to assist you." },
        // { regex: /what is the use of you/i, response: "I am here to assist you with information, answer questions, and help with various tasks." },
        // { regex: /how do you work/i, response: "I use algorithms and data to understand and respond to your queries based on predefined patterns and logic." },
        // { regex: /what languages do you speak/i, response: "I can understand and respond in multiple languages, but my primary language is English." },
    ];


    for (const pattern of patterns) {
        if (pattern.regex.test(normalizedInput)) {
            return {
                text: pattern.response,
                action: pattern?.action,
                dashboardQuestions: [dashboardQuestions],
            }
        }
    }

    return false;
}



export const extract_the_filter_from_question = (q = "why my revenue generating more than East for fy'24") => {

    const question = q?.toLocaleLowerCase();

    const ui_filter = [
        "East", "North", "Others", "South", "West",
        "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar",
        "Chandigarh", "Chhattisgarh", "Delhi", "Goa", "Gujarat",
        "Haryana", "Himachal Pradesh", "Jammu and Kashmir",
        "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh",
        "Maharashtra", "Manipur", "Meghalaya", "Mizoram",
        "Nagaland", "Orissa", "Punjab", "Rajasthan", "Sikkim",
        "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh",
        "Uttarakhand", "West Bengal", "Smartphone",
        "Laptop",
        "Tablet",
        "Smartwatch",
        "Wireless earbuds",
        "Bluetooth speaker",
        "4K TV",
        "Digital camera",
        "Game console",
        "E-reader",
        "Coffee maker",
        "Air fryer",
        "Vacuum cleaner",
        "Blender",
        "Instant pot",
        "Electric kettle",
        "Microwave oven",
        "Slow cooker",
        "Robot vacuum",
        "Food processor",
        "Skincare set",
        "Hairdryer",
        "Fitness tracker",
        "Electric toothbrush",
        "Makeup brushes",
        "Hiking backpack",
        "Yoga mat",
        "Camping tent",
        "Bicycle",
        "Portable grill",
        "Water bottle"
    ];

    // Convert the question to lowercase for case-insensitive comparison
    const lowerCaseQuestion = question.toLowerCase();

    // Filter the ui_filter based on the presence in the question (case-insensitive)
    const filtersFound = ui_filter.filter(location =>
        lowerCaseQuestion.includes(location.toLowerCase())
    );

    // Regex to extract various fiscal year formats (e.g., fy24, fy'24, fy 2023, fy'23, fy23)
    const yearMatch = lowerCaseQuestion.match(/(fy'? ?(\d{2}|\d{4})|\b(\d{4})\b)/);
    var yearFound = yearMatch ? yearMatch[0] : '2024'; // Get the matched year or null if not found

    if (yearFound.indexOf("'") > -1) {
        const fy = yearFound.split("'")[1];
        yearFound = (20 + fy)
    }
    else if (yearFound.indexOf("fy") > -1) {
        const fy = yearFound?.substring(2, 4)
        yearFound = (20 + fy)
    }


    return { dim: filtersFound, dim_date: yearFound }; // Return an object with filters and year
};






export const getFilterdDashoardList = (data, criteria) => {
    
    if (!criteria || Object.keys(criteria).length === 0) {
        return data; 
    }

    return data.filter(item =>
        
        Object.entries(criteria).every(([key, value]) => {

            if (key === "created_at" || key === 'modified_at') {
                const itemDate = new Date(item[key]);
                const criteriaDate = new Date(value);
                return !isNaN(itemDate) && !isNaN(criteriaDate) &&
                       itemDate.toDateString() === criteriaDate.toDateString();
            }
            if(key === "created_by"){
                return item?.created_user?.name?.toLocaleLowerCase() === value?.toLocaleLowerCase();
            }
            else {
                return item[key] === value;
            }
        })
    );
};


export const getFilterdDashoardList1 = (all_reports, filter) => {
    return all_reports?.filter(dashboard => {
      // 1. Filter by `createdBy`
      const createdByMatch = filter?.createdBy?.length
        ? dashboard?.created_user?.name?.toLowerCase() === filter?.created_by?.toLowerCase()
        : true;

      const dateMatch = filter?.dates?.length > 0
        ? new Date(dashboard?.created_at).getTime() === new Date(filter?.dates[0]).getTime()
        : true;

      if (filter?.created_by) {
        return createdByMatch; // Only filter by `createdBy`
      }
      if (filter?.dates?.length) {
        return dateMatch; // Only filter by `dates`
      }

      // If no filters, return all dashboards
      return true;
    }).sort((a, b) => {
      // Trim, convert to string, and safely compare
      const aStr = a?.title ? String(a?.title)?.trim().toLocaleLowerCase() : '';
      const bStr = b?.title ? String(b?.title)?.trim().toLocaleLowerCase() : '';
      return aStr?.localeCompare(bStr);
    });
  };

