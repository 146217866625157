import React from "react";

const PlayCircledIcon = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#3E9DFF"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
       viewBox="0 0 968 968"
      preserveAspectRatio="xMidYMid meet"
    >
      <path transform="translate(462,1)" d="m0 0h38l29 2 37 5 30 6 33 9 34 12 27 12 16 8 17 9 18 11 15 10 17 12 16 13 11 9 13 12 3 2v2l4 2 14 14 7 8 10 11 9 11 10 13 13 18 14 22 13 23 14 28 13 32 10 30 8 32 5 26 4 31 2 26v43l-2 27-5 35-5 27-10 36-11 31-13 30-11 22-14 24-11 17-10 14-12 16-11 13-12 14-30 30-11 9-10 9-13 10-18 13-17 11-24 14-27 14-28 12-28 10-28 8-26 6-30 5-36 4h-56l-46-5-33-6-31-8-36-12-26-11-25-12-20-11-21-13-20-14-13-10-11-9-13-11-16-15-17-17-7-8-12-14-13-17-9-12-12-18-9-15-9-16-11-21-12-28-9-25-8-25-7-30-5-28-4-33-2-27v-23l3-35 5-38 7-32 7-25 10-30 10-24 12-25 9-16 6-11 10-16 11-16 12-16 11-14 11-12 7-8 30-30 8-7 16-13 16-12 19-13 19-12 26-14 25-12 27-11 31-10 32-8 33-6 33-4z" fill="#3CD788" />
      <path transform="translate(361,259)" d="m0 0 11 1 12 5 20 11 24 14 14 8 97 56 24 14 28 16 24 14 21 12 24 14 23 13 22 13 12 8 7 7 4 9 1 13-3 10-6 9-9 8-16 9-24 14-56 32-17 10-24 14-28 16-26 15-21 12-17 10-26 15-28 16-24 14-21 12-12 5h-14l-10-4-10-9-5-10-1-4-1-137v-247l2-15 6-11 7-6 8-4z" fill="#FEFEFE" />
    </svg>
  );
};

export default PlayCircledIcon;
