import styled from 'styled-components';

export const MainWrapper = styled.div`
    width: 100%;
    margin: auto;
    position: relative;
    height: calc(100vh - 80px);
    overflow: auto;
    padding: ${props => props.padding ? props.padding : '0px 15px'};
    box-sizing: border-box;
    &::-webkit-scrollbar {
        background-color:#b1cad5;
        width: 7px
    }

    &::-webkit-scrollbar-track {
        background-color:#b1cad5;
    }

    &::-webkit-scrollbar-thumb {
        background-color:#76919d;
        border-radius:0px;
        height: 7px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color: green;
        height: 7px;
    }

`;