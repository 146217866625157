import React from "react";

const DataCapture = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -500 2026 2048"
    >
      <path
        transform="translate(1191,142)"
        d="m0 0 17 1 15 4 17 8 9 7 9 9 9 14 8 15 11 23 16 32 7 6 29 14 32 16 15 9 10 8 10 10 8 14 6 16 2 18v12l-3 14-4 12-7 12-6 8 1 5 10 25 9 29 6 27 4 23 5 51v16l-5 51-5 28-8 32-8 24-10 24-15 31-15 25-11 16-9 12-8 10-10 11-9 11-17 17-11 9-10 9-17 13-19 13-18 11-24 13-22 10-24 9-26 8-29 7-40 7-7 1h-10l-4-2-2 2v-2l-6 2-2 1h-10l-2-1-5 1-9-3v2l-8-1-1 1h-9l-3-1-2 1-42-7-22-5-31-9-29-11-20-9-28-15-19-12-19-14-14-11-12-11-8-7-23-23-7-8-9-10-15-20-12-18-12-20-14-27-12-29-9-27-8-32-6-36-3-35-1-4v-19l2-14 2-27 4-27 7-31 9-29 10-25 8-18 10-19 9-16 9-14 10-14 10-13 9-11 12-14 16-17 10-9 11-9 13-11 19-14 19-12 21-12 23-11 15-7 36-12 26-7 34-6 16-2 26-2h30l37 3 30 4 11 3 3 1 8-13 11-13 9-8 14-8 12-4zm9 57-9 2-12 6-9 12-6 12-11 23-8 16-8 15-5 9-9 10-5 4-17 9-40 20-17 8-8 4-5 5-4 13 1 9 3 6 4 5 24 13 60 30 10 8 7 11 26 52 10 19 5 9 7 6 16 2 9-4 5-6 16-32 14-27 8-16 9-14 9-7 41-21 26-13 14-8 10-9 2-3v-10l-7-14-7-6-19-10-23-11-28-14-13-8-5-5-6-9-15-29-12-26-10-21-8-11-5-3zm-207 237-18 5-15 6-21 11-11 9-20 18-9 10-10 15-8 16-8 19-4 16-3 22v19l3 21 5 19 9 21 11 18 8 10 11 12 7 7 15 11 13 8 16 8 21 7 14 3 19 2h13l22-3 16-4 21-8 18-10 14-10 13-11 7-8 10-12 10-16 9-19 6-20 3-13 1-9 2-3-1-5-7-3-18-8-13-8-10-9-8-10-12-21-8-16-12-25-9-16-8-7-14-6-19-10-26-13-16-9z"
        fill="#D6DCDE"
      />
      <path
        transform="translate(998,6)"
        d="m0 0h60l54 3 46 4 64 8 53 9 50 11 51 13 43 13 36 12 35 13 37 15 57 26 19 10 32 17 24 14 23 14 23 15 15 10 17 12 18 13 28 22 13 11 11 9 11 10 8 7 10 9 8 7 41 41 7 8 11 12 9 11 13 15 26 34 12 17 18 27 21 35 13 24 20 40 5 14-2 9-22 45-26 46-13 20-8 12-15 22-16 21-11 14-11 13-9 11-24 26-7 8-34 34-8 7-13 12-14 12-11 9-14 12-24 18-13 10-19 13-36 24-24 15-26 15-22 12-27 14-28 14-36 16-27 11-39 15-41 14-48 14-35 9-34 8-45 9-49 8-52 6-47 4-52 3h-68l-67-4-44-4-48-6-49-8-44-9-46-11-43-12-35-11-42-15-30-12-29-12-26-12-25-12-32-16-23-13-18-10-28-17-39-26-18-13-19-14-14-11-22-18-14-12-12-11-13-12-12-11-35-35-7-8-12-13-7-8-9-11-13-16-13-17-11-15-11-16-19-29-9-15-16-28-15-29-11-24-2-6 1-8 10-23 8-16 14-26 20-34 12-19 11-16 10-14 18-24 11-14 13-15 9-11 13-14 7-8 11-12 28-28 8-7 10-9 8-7 28-24 14-11 13-10 20-15 17-12 19-13 20-13 21-13 24-14 18-10 42-22 36-17 28-12 38-15 36-13 49-16 44-12 42-10 44-9 42-7 54-7 48-4 46-3zm-18 84-39 2-47 4-61 8-44 8-47 10-35 9-35 10-46 15-40 15-43 18-26 12-50 25-48 28-24 15-18 12-14 10-11 8-16 12-18 14-10 8-10 9-11 9-15 14-8 7-29 29-7 8-15 15-9 11-8 9-11 14-10 12-26 36-10 16-9 14-13 22-11 20-6 12 1 5 10 19 10 18 14 23 22 33 10 14 14 18 10 13 11 13 10 11 7 8 14 15 35 35 8 7 11 10 11 9 10 9 14 11 18 14 18 13 14 10 12 8 20 13 21 13 26 15 22 12 18 10 25 12 31 14 33 14 35 13 47 16 33 10 46 12 47 10 33 6 40 6 43 5 37 3 32 2 40 1h29l33-1 5-1 40-2 42-4 52-7 50-9 49-11 31-8 34-10 43-14 48-18 51-22 34-16 32-17 23-13 18-11 23-14 31-21 19-14 16-12 14-11 11-9 15-13 14-12 15-14 43-43 7-8 13-15 11-13 11-14 15-20 10-14 10-15 12-19 13-22 12-22 5-10-1-6-14-28-17-28-9-15-17-25-12-16-10-13-20-25-10-11-7-8-9-9-7-8-33-33-8-7-13-12-11-9-14-12-13-10-18-14-34-24-15-10-22-14-15-9-19-11-18-10-27-14-32-16-37-16-27-11-38-14-52-17-44-12-34-8-44-9-47-8-39-5-43-4-35-2-38-1z"
        fill="#073460"
      />
      <path
        transform="translate(1191,142)"
        d="m0 0 17 1 15 4 17 8 9 7 9 9 9 14 8 15 11 23 16 32 7 6 29 14 32 16 15 9 10 8 10 10 8 14 6 16 2 18v12l-3 14-4 12-7 12-9 12-15 10-16 9-23 12-16 8-19 10-5 5-6 10-14 28-8 17-12 21-8 10-10 10-13 9-11 5-17 4h-23l-10-1-19-9-13-9-10-10-10-15-8-15-11-23-12-24-7-10-5-4-14-6-19-10-26-13-16-9-3-1-6-8-8-9-9-14-6-17-2-9v-21l4-18 8-16 9-11 7-7 12-9 19-10 23-11 24-12 7-4 5-5 10-19 18-36 5-12 7-11 11-13 9-8 14-8 12-4zm9 57-9 2-12 6-9 12-6 12-11 23-8 16-8 15-5 9-9 10-5 4-17 9-40 20-17 8-8 4-5 5-4 13 1 9 3 6 4 5 24 13 60 30 10 8 7 11 26 52 10 19 5 9 7 6 16 2 9-4 5-6 16-32 14-27 8-16 9-14 9-7 41-21 26-13 14-8 10-9 2-3v-10l-7-14-7-6-19-10-23-11-28-14-13-8-5-5-6-9-15-29-12-26-10-21-8-11-5-3z"
        fill="#073460"
      />
    </svg>
  );
};

export default DataCapture;
