import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { getHintsWithTableAndColumnName } from '../../actions/hints';
import { saveFormulas } from '../../actions/formulas';
import { Gap } from '../gap';

import { Wrapper, Content, ContentTitle, ButtonWrapper, InputWrapper, AutoComplete } from './styled';
import { get_hints_in_object, find_hint_in_array, check_brackets } from '../../utils';
import { Button } from '../hoc/button';
import Overlay from '../helper/overlay';



const arrayOfOperators = ['+', '-', '*', '/', '%', '='];

let current_index = undefined;


const FormulaCreator = props => {

    const { getHintsWithTableAndColumnName, hintsWithTableColumnsName, data, saveFormulas, close, option, popupKey } = props;

    const { hideRefresh, etlQuestionAction } = data;
    const { mathematical_keywords_in_query } = data.data;

    const [hints, setHints] = useState(undefined);
    const [hintsKeys, setHintsKeys] = useState(undefined);
    const [autoCompleteWords, setAutoCompleteWords] = useState(undefined);
    const [currentWord, setCurrentWord] = useState(undefined);

    const [currentInputValue, setCurrentInputValue] = useState('');

    const [err, setErr] = useState({});

    const [formData, setFormData] = useState({})

    useEffect(() => {
        getHintsWithTableAndColumnName(['number', 'int', 'currency']);
    }, [])


    const inputRef = useRef(null)


    useEffect(() => {
        if (hintsWithTableColumnsName && hintsWithTableColumnsName.length > 0) {
            const _hints = get_hints_in_object(hintsWithTableColumnsName);

            setHints(_hints);
            setHintsKeys(Object.keys(_hints));
        }
    }, [hintsWithTableColumnsName]);



    const _onChange = (value, key) => {

        current_index = undefined;
        // const tempFormData = Object.assign({}, formData);
        const tempErr = Object.assign({}, err);

        if (tempErr[key]) {
            delete tempErr[key];
        }

        // if (value && value.length === 0) {
        //     if (tempFormData[key]) {
        //         delete tempFormData[key];
        //     }
        // }
        // else {
        //     tempFormData[key] = value;
        // }

        // const splitted = value.split(' ');


        const hints_from_array = find_hint_in_array(value, undefined, hintsKeys);

        setAutoCompleteWords(hints_from_array)
        // setFormData(tempFormData);
        setCurrentInputValue(value)
        setErr(tempErr)
    };



    const _setErr = (key, value) => {
        const tempErr = Object.assign({}, err);

        if (typeof tempErr[key] === 'undefined') {
            tempErr[key] = []
        }

        tempErr[key].push(value)

        setErr(tempErr);
    };


    const _saveFormulas = () => {
        const tempFormData = Object.assign({}, formData);
        const result = {}

        const shouldProceed = tempFormData && Object.keys(tempFormData).length > 0;

        let finalSave = true;

        if (shouldProceed) {
            Object.keys(tempFormData).forEach(key => {
                const temp_data = tempFormData[key];
                const data = temp_data && temp_data.split("nf_item").join(' ');

                const brackets_are_in_place = check_brackets(data);

                const data_without_brackets = data.replaceAll(/[\(\)\[\]\{\}]/gi, '');

                const data_without_number = data_without_brackets.replaceAll(/[0-9]/g, '');

                const data_without_maths_symbols = data_without_number.replaceAll(/[+*\/-]/g, '_nf_');

                const splitted_data = data_without_maths_symbols.split('_nf_').filter(f => f.trim().length > 0 && f);


                if (brackets_are_in_place) {
                    splitted_data && splitted_data.length > 0 && splitted_data.forEach(s => {
                        const word = typeof s === 'string' ? s.trim().toLocaleLowerCase() : s.trim();

                        if (word && word.trim().length > 0 && hints && typeof hints[word] === 'undefined') {

                            _setErr(key, '')

                            finalSave = false;
                        }
                        else {
                            result[key] = {
                                ...result[key],
                                [word]: {
                                    meta: hints[word],
                                    formula: data
                                }
                            }
                        }
                    })

                    if (finalSave) {
                        /*
                            Check arthematic operator after hints
                        */
                        const words_to_check = splitted_data.slice(0, splitted_data.length - 1);
                        let isErr = false;

                        words_to_check && words_to_check.length > 0 && words_to_check.forEach(w => {
                            const maths = ['+', '/', '*', '-'];

                            const index = data_without_brackets.indexOf(w);

                            let next_index_to_check = index + w.length;


                            while (true) {
                                if (data_without_brackets[next_index_to_check].trim().length > 0) {
                                    break;
                                }

                                next_index_to_check++;
                            }

                            if (maths.indexOf(data_without_brackets[next_index_to_check]) === -1) {
                                isErr = true;
                            }
                            else if ((maths.indexOf(data_without_brackets[next_index_to_check + 1]) > -1) || (maths.indexOf(data_without_brackets[next_index_to_check + 2]) > -1)) {
                                isErr = true
                            }

                        })

                        if (isErr) {
                            finalSave = false;
                            _setErr(key, 'Formula is not Correct')
                        }
                    }
                }
                else {
                    finalSave = false;
                    _setErr(key, 'Bracket are not in place');
                }

            })
        }

        if (finalSave) {
            saveFormulas(result, close(option, popupKey), hideRefresh, etlQuestionAction);
        }
    };



    return (
        <Wrapper>
            <Content>
                <ContentTitle>
                    Formula Creator
                </ContentTitle>
                {autoCompleteWords && <Overlay onClick={() => setAutoCompleteWords(undefined)} />}
                <Gap h='2rem' />


                {
                    mathematical_keywords_in_query && mathematical_keywords_in_query.length > 0 && mathematical_keywords_in_query.map(m => {
                        return (
                            <div style={{ position: 'relative', zIndex: '2' }}>
                                {/* <p style={{ margin: '2px 0px' }}>We couldn't understand what <strong>Net Profit</strong> is</p> */}
                                <div className='q_word'>Can you please help us understand  <strong>{m}</strong></div>


                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                                    <div style={{ textTransform: 'uppercase', whiteSpace: 'nowrap', marginRight: '1rem' }}>{m}&nbsp;=
                                </div>

                                    <InputWrapper>
                                        {formData && formData[m] && formData[m].split('nf_item').map((t, j) => {
                                            let _is_oprator = arrayOfOperators.indexOf(t) > -1;
                                            return (
                                                <div style={{ borderBottomColor: _is_oprator ? 'transparent' : '#000' }} className='tag'>{t}</div>
                                            )
                                        })}
                                        <div style={{ position: 'relative', flex: 1, minWidth: '100px' }}>
                                            <input
                                                ref={inputRef}
                                                autoFocus={true}
                                                onChange={(event) => _onChange(event.target.value, m)} value={formData[m]

                                                }
                                                // onFocus={() => {
                                                //     setInputFocus(true)
                                                // }}
                                                // onBlur={() => {
                                                //     setInputFocus(false)
                                                // }}
                                                value={currentInputValue}
                                                onKeyDown={(event) => {


                                                    let keyCode = event.keyCode;
                                                    let _value = event.target.value;

                                                    let temp_formula = formData ? JSON.parse(JSON.stringify(formData)) : {};
                                                    let formula_array_value = temp_formula[m] ? temp_formula[m].split("nf_item") : [];



                                                    // when user press Enter add current value
                                                    if (keyCode === 13) {
                                                        formula_array_value.push(_value);
                                                        temp_formula[m] = formula_array_value.join("nf_item");
                                                        setFormData(temp_formula);
                                                        setCurrentInputValue('');
                                                        current_index = undefined;
                                                        // setAutoCompleteWords(undefined);
                                                        if (arrayOfOperators.indexOf(_value) === -1) {
                                                            setAutoCompleteWords(arrayOfOperators);
                                                        } else {
                                                            setAutoCompleteWords(undefined);
                                                            setCurrentInputValue('');
                                                            current_index = undefined;
                                                        }
                                                    }


                                                    // when user Click on BackSpace remove last word from string
                                                    if (keyCode === 8) {
                                                        if (!formula_array_value) {
                                                            setAutoCompleteWords(undefined);
                                                        }
                                                        if (!_value && formula_array_value && formula_array_value.length > 0) {
                                                            formula_array_value.pop();
                                                            temp_formula[m] = formula_array_value.join("nf_item");
                                                            setFormData(temp_formula);
                                                            setCurrentInputValue('');
                                                            // setAutoCompleteWords(undefined);
                                                        }
                                                    }


                                                    let total_suggested_items_length = autoCompleteWords && autoCompleteWords.length;

                                                    // page down
                                                    if (keyCode === 40) {

                                                        let temp_index = (current_index == 0 || current_index) ? current_index + 1 : 0;
                                                        current_index = temp_index;
                                                        if (total_suggested_items_length === temp_index) {
                                                            current_index = 0;
                                                            temp_index = 0;
                                                        }
                                                        let current_item = autoCompleteWords && autoCompleteWords[temp_index]
                                                        setCurrentInputValue(current_item)
                                                    }
                                                    // page up 
                                                    if (keyCode === 38) {

                                                        // current_index = current_index - 1;
                                                        let temp_index = current_index - 1;
                                                        if (current_index <= 0) {
                                                            temp_index = total_suggested_items_length
                                                        }
                                                        current_index = temp_index;

                                                        let current_item = autoCompleteWords && autoCompleteWords[temp_index]
                                                        if (current_item) {
                                                            setCurrentInputValue(current_item)
                                                        }

                                                    }

                                                    // setCurrentInputValue('')
                                                }}
                                            />
                                            {
                                                autoCompleteWords && autoCompleteWords.length > 0 &&

                                                <AutoComplete
                                                    gap={autoCompleteWords[0] === '+'}
                                                >
                                                    {autoCompleteWords && autoCompleteWords.map((word, i) => {

                                                        return (
                                                            <div
                                                                onClick={() => {
                                                                    let temp_formula = formData ? JSON.parse(JSON.stringify(formData)) : {};

                                                                    let formula_value_array = temp_formula[m] ? temp_formula[m].split("nf_item") : [];

                                                                    formula_value_array.push(word);
                                                                    temp_formula[m] = formula_value_array.join("nf_item");
                                                                    setFormData(temp_formula);
                                                                    setCurrentInputValue('');
                                                                    if (arrayOfOperators.indexOf(word) === -1) {
                                                                        setAutoCompleteWords(arrayOfOperators);
                                                                    } else {
                                                                        setAutoCompleteWords(undefined);
                                                                    }
                                                                    current_index = 0;
                                                                    inputRef.current.focus();
                                                                    // const temp = Object.assign({}, formData);
                                                                    // const data = temp[m];
                                                                    // const splitted_data = data ? data.split(' ') : [];

                                                                    // const opening_brackets = ['(', '{', '['];
                                                                    // const closing_brackets = [')', '}', ']'];

                                                                    // const find_bracket = (word, brackets) => {
                                                                    //     let a = '';
                                                                    //     brackets.forEach(b => {
                                                                    //         if (word && word.indexOf(b) > -1) {
                                                                    //             a += b + ' ';
                                                                    //         }
                                                                    //     })

                                                                    //     return a;
                                                                    // };

                                                                    // const last_word = splitted_data[splitted_data.length - 1];

                                                                    // let open_bracket = find_bracket(last_word, opening_brackets);
                                                                    // let close_bracket = find_bracket(last_word, closing_brackets);

                                                                    // splitted_data[splitted_data.length - 1] = open_bracket + word + (close_bracket && close_bracket.length > 0 ? close_bracket + ' ' : ' ');

                                                                    // temp[m] = splitted_data.join(' ')
                                                                    // setFormData(temp);
                                                                    // setAutoCompleteWords(undefined);
                                                                    // setCurrentInputValue('');
                                                                }}
                                                                className={`word ${(currentInputValue && currentInputValue === word) ? ' selected' : 'none_selected'}`}
                                                            >{word}</div>
                                                        )
                                                    })}
                                                </AutoComplete>
                                            }
                                        </div>
                                    </InputWrapper>
                                </div>
                                <div className="example">
                                    Example:- &nbsp;&nbsp;<span>revenue</span> <strong>&nbsp;-&nbsp;</strong><span>discount</span>
                                </div>


                                {
                                    err && err[m] &&
                                    <div className='q_error'>
                                        {
                                            err[m].join('').trim().length > 0 ? err[m] : 'Field is not present'
                                        }
                                    </div>
                                }



                            </div>
                        )
                    })
                }


            </Content>
            <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Button
                    onClick={_saveFormulas}
                    primary={true}
                >
                    Save Formula
                </Button>
            </div>
        </Wrapper>
    )
};


const mapStateToProps = state => {
    const { hintsWithTableColumnsName } = state.hintsReducer;

    return { hintsWithTableColumnsName }
};

export default connect(mapStateToProps, { getHintsWithTableAndColumnName, saveFormulas })(FormulaCreator);