import styled from 'styled-components';


export const MainWrapper = styled.div`
    width: 100%;
    

    .row{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content:space-between;

        .column_48{
            width: 48%;
        }
    }

    .title_{
        font-size: 1.1rem;
        font-weight: 600;
        color: #222;
    }

    .table_wrapper{
        max-height: 35%;
        overflow: auto;    
        width: 80%;
        &::-webkit-scrollbar {
            background-color:red;
            width:3px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 3px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 3px;
        }
        table{
            width: 100%;
            border: 0px;
            border-collapse: collapse;
            table-layout: fixed ;
            border: 1px solid #ccc;
            border-radius: 3px;
            

            thead{

                td{
                    background: #308BC8;
                    color: #fff;
                    padding: 0rem .9rem;
                    font-size: .9rem;
                    border: 1px solid #ccc;
                    position: sticky;
                    font-weight: 600;
                    z-index: 3;
                    top: -1px; /* Don't forget this, required for the stickiness */
                }
            }

            td{
                padding: .2rem .9rem;
                font-size: .9rem;
                font-weight: 500;
                position: relative;
                border: 1px solid #ccc;
            }
        }
    }
`;