import React from "react";

const Loginpagebg = (props) => {
    return (
        <svg
            width={props.size ? props.size : "2.2rem"}
            height={props.height ? props.height : "2.2rem"}
            fill={props.color ? props.color : "#000"}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 2048 1489"
            preserveAspectRatio="xMidYMid meet"
        >
      <path transform="translate(1310,191)" d="m0 0 9 3 61 21 245 85 98 34 43 15 28 10 70 24 150 52 25 9 6 2v1h-219l-130-1h-208l-170 1h-80l2-10 15-52 15-53 38-133z" fill="#B7F2FC"/>
<path transform="translate(842,235)" d="m0 0 276 34 48 6 6 1-2 4-57 57-7 8-67 67-1 2h-2l-2 4-79 79-6 7-5 4-29 29-4 5-5 4-7 8-26 26h-2l-30-340z" fill="#726DB9"/>
<path transform="translate(1852,119)" d="m0 0h5l21 28 16 21 14 19 16 21 14 19 16 21 14 19 16 21 14 19 16 21 14 19 5 7v2l-10-3-45-14-63-20-61-19-328-104-53-17-5-2v-2l14-1 66-10 143-21z" fill="#57ACDE"/>
<path transform="translate(1310,191)" d="m0 0 4 1 3 5 8 7 12 9 17 13 14 11 18 14 17 13 15 12 17 13 18 14 13 10 14 11 13 10 36 28 17 13 14 11 36 28 15 12 13 10 10 8v1l-316 2h-80l2-10 15-52 15-53 38-133z" fill="#40D2E8"/>
<path transform="translate(905,807)" d="m0 0 6 1 34 15 30 13 41 18 60 26 71 31 6 3-1 3-14 9-33 22-14 9-82 54-15 10-85 56-38 25-24 16-4 3h-2l4-23 18-87 15-74 23-112z" fill="#40D2E9"/>
<path transform="translate(930,1321)" d="m0 0h14v2l-11 8-13 11-14 11-17 14-14 11-13 11-14 11-32 26-14 11-17 14-16 13-14 11-17 14-3-1-12-19-16-25-12-19-32-50-12-19-11-17v-2l252-14z" fill="#8D63DB"/>
<path transform="translate(248,290)" d="m0 0h2l8 12 14 19 12 17 26 36 10 14 13 18 20 28 12 17 13 18 4 5v2l-25-3-151-20-37-5-6-1 6-11 12-23 12-22 15-28 14-26 15-28 10-18z" fill="#58B9E4"/>
<path transform="translate(1077)" d="m0 0 17 3 122 16 12 2 3 16 8 57 18 125v3h-2l-11-14-9-11-13-16-11-14-11-13-8-10-13-16-11-14-28-34-11-14-11-13-18-22-11-13-12-15z" fill="#4E88CF"/>
<path transform="translate(552,119)" d="m0 0 6 5 7 8 9 10 7 8 11 12 9 11 7 7 7 8 10 11 7 8 18 20 12 14 14 15 9 11 8 8 7 8 9 10 7 8 11 12 5 6v2h2l2 5-105-33-50-16-35-11-2-2v-14l3-50z" fill="#40D3E9"/>
<path transform="translate(467,1149)" d="m0 0 3 2 11 23 22 45 11 23 23 46 7 15-2 2-95-18-73-14v-3l11-14 14-18 15-20 12-15 12-16 11-14 16-21z" fill="#4E88CF"/>
<path transform="translate(522,670)" d="m0 0 12 2 140 35 3 1-1 5-23 45-15 29-17 33h-2l-19-29-13-20-32-49-26-40-7-10z" fill="#40D3E9"/>
<path transform="translate(569,947)" d="m0 0 8 1 141 33v3l-21 11-22 12-88 47-16 9-18 9v-11l12-87z" fill="#57ACDE"/>
<path transform="translate(340,111)" d="m0 0 2 1v58l-5-2-25-14-56-32-3-3 18-2z" fill="#8D63DB"/>
<path transform="translate(904,21)" d="m0 0 30 11 54 21v3l-41 19-26 12-3-1-10-42-4-19z" fill="#40D2E8"/>
<path transform="translate(329,627)" d="m0 0 4 2 23 23 8 7 9 9-1 3-48 20-15 6 2-10 16-55z" fill="#41D2E9"/>
<path transform="translate(248,290)" d="m0 0h2l8 12 14 19 12 17 26 36 10 14 13 18 20 28 12 17 13 18 4 5v2l-25-3-23-3-1-2 7-1 8 1 1 1 17 1 3-1-1-8-5-4v-2l-3-1-8-10-1-3h-2l-5-8v-2h-2l-7-12-7-10-2-1-4-8-4-4-3-5v-2h-2v-3h-2l-3-7 1-2-5-1-7-11-3-4-1-7-5-1-3-4-4-7-5-9-2-1v-2l-3-1-3-5-2-6-5-7v-2l-4-2-6-5h-6l-8 17-7 13-5 10-7 12-3 6-3 7h-2l-1 5-5 8-4 10-8 14-3 7-2 5h-2l-1 5-5 7-3 5-3 8v2l5 1 7 3h14l11 3 2 2h-14l-35-5 6-11 12-23 12-22 15-28 14-26 15-28 10-18z" fill="#58AEDF"/>
<path transform="translate(0,68)" d="m0 0 11 2 65 16 1 2-27 13-23 11-15 7-3-1-8-45z" fill="#726DB9"/>
<path transform="translate(243,301)" d="m0 0 1 4-8 15-11 22-7 12-3 6-3 7h-2l-1 5-5 8-4 10-8 14-3 7-2 5h-2l-1 5-5 7-3 5-3 8v2l5 1 7 3h14l11 3 2 2h-14l-35-5 6-11 12-23 12-22 15-28 14-26 15-28 4-7z" fill="#57ADDE"/>
<path transform="translate(218,451)" d="m0 0 11 1 2 1 21 2 14 2 11 1 9 2h13v2l3-1 13 3 11 1 4 3h-11l-98-13-4-2z" fill="#58AFDF"/>
<path transform="translate(258,457)" d="m0 0 19 1 9 3v2l-19-2-16-2v-1z" fill="#57B0E0"/>
<path transform="translate(339,467)" d="m0 0 9 1 6 2v2l-13-1-7-1-1-2z" fill="#59B0E0"/>
<path transform="translate(243,454)" d="m0 0 9 1 6 2v1h-14l-3-1v-2z" fill="#57ADDE"/>
<path transform="translate(316,464)" d="m0 0 10 1 4 3h-11l-4-2z" fill="#57ADDE"/>
        </svg>
    );
};

export default Loginpagebg;
