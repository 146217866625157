import React from 'react'
import { constants } from '../../utils/constants'

const StepperButtons = ({
    activeStep,
    set_pre_step,
    setActiveStep,
    setInnerStep,
    innerStep,
    jobType,
    pre_step,
    save_v1

}) => {


    return (
        <React.Fragment>
            <div className='go_back_btn_wrapper'>

                {jobType === "google_drive_upload" ? <>
                    <div className='go_back_btn' onClick={() => {
                        // if (activeStep == 0) {
                        //     set_pre_step(pre_step - 1)
                        // }
                        // else if (innerStep === 1 && activeStep === 2) {
                        //     setInnerStep(innerStep - 1)
                        // }
                        // else {
                        //     setActiveStep(activeStep - 1)
                        // }
                        
                        if (activeStep == 0) {
                            set_pre_step(pre_step - 1)
                        }else{
                            setActiveStep(0)
                        }


                    }}>
                        <div className='icon'>
                            <img src={constants.CONTEXT_PATH + `./etl/back.png`}></img>
                        </div>
                        <span>Back</span>
                    </div>

                    {activeStep < 4 && (
                        <div className='go_back_btn' onClick={() => {
                            // if (activeStep === 2 && innerStep == 0) {
                            //     setInnerStep(innerStep + 1);
                            // } else {
                            //     setActiveStep(activeStep + 1);
                            // }
                            setActiveStep(4)
                        }}>

                            <div className='icon'>
                                <img style={{ transform: 'rotate(180deg)' }} src={constants.CONTEXT_PATH + `./etl/back.png`}></img>
                            </div>
                            <span>Next</span>
                        </div>
                    )}
                    {activeStep == 4 && (
                        <div className='go_back_btn' onClick={() => {
                            save_v1()
                        }}>
                            <div className='icon'>
                                <img style={{ transform: 'rotate(180deg)' }} src={constants.CONTEXT_PATH + `./etl/save.png`}></img>
                            </div>
                            <span>Save</span>
                        </div>
                    )}
                </> : <>


                    <div className='go_back_btn' onClick={() => {
                        if (activeStep == 0) {
                            set_pre_step(pre_step - 1)
                        }
                        else if (innerStep === 1 && activeStep === 2) {
                            setInnerStep(innerStep - 1)
                        }
                        else {
                            setActiveStep(activeStep - 1)
                        }
                    }}>
                        <div className='icon'>
                            <img src={constants.CONTEXT_PATH + `./etl/back.png`}></img>
                        </div>
                        <span>Back</span>
                    </div>

                    {activeStep < 4 && (
                        <div className='go_back_btn' onClick={() => {
                            if (activeStep === 2 && innerStep == 0) {
                                setInnerStep(innerStep + 1);
                            } else {
                                setActiveStep(activeStep + 1);
                            }
                        }}>

                            <div className='icon'>
                                <img style={{ transform: 'rotate(180deg)' }} src={constants.CONTEXT_PATH + `./etl/back.png`}></img>
                            </div>
                            <span>Next</span>
                        </div>
                    )}
                    {activeStep == 4 && (
                        <div className='go_back_btn' onClick={() => {
                            save_v1()
                        }}>
                            <div className='icon'>
                                <img style={{ transform: 'rotate(180deg)' }} src={constants.CONTEXT_PATH + `./etl/save.png`}></img>
                            </div>
                            <span>Save</span>
                        </div>
                    )}</>}
            </div>
        </React.Fragment>

    )
}


export default StepperButtons;