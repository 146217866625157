import { formatValueByDataType } from "../../../utils";

const TrendLineLabel = ({ x, y, width, value, index, date_length, data_key, equation }) => {

    const total_data_length = (date_length - 1);
    const radius = 10;

    // const preapred_value = formatValueByDataType(value, 1, undefined, undefined, undefined, "insights");
    const x_to_apply = x + (width ? width / 2 : 0);

    if (total_data_length === index) {
        return (
            <g>
                <text
                    x={x_to_apply - 100}
                    y={y - radius}
                    fill="#333"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontSize="13px"
                >
                    Eq of {data_key?.split("_nfx_")?.join(" ")} {equation}
                </text>
            </g>
        );
    } else return null;

}



export default TrendLineLabel;