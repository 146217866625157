/***
 * Version v2
 * code of rright side panel for layout editor
 * author - Polus Ai 
 * writen by - saroj kumar
 * date - 18 Apr 2024
 */



import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AngleUp from '../svg/angleUp';
import AngleDown from '../svg/angleDown';
import SelectOption from '../select-option';
import { DivRow } from '../styled-hoc';
import { CHART_TYPES_WITH_ICONS } from '../../shared-with-fe/constants';
import { save_report_items, set_report_to_refresh } from '../../actions/report';
import { connect } from 'react-redux';
import { Button } from '../hoc/button';
import DataModeTab from './data.mode.tab'
import { NormalButton } from '../hoc/normal.button';
import CheckBox from '../hoc/checkbox';
import ThemeSetting from './theme.setting';
import { Wrapper, Grouped, FixedBottomSection } from './editor.styled';
import QuestionPopup from './question.popup';
import { hideRighter, showPopup } from '../../utils';
import ConfirmBox from '../confirm-box';
import * as enums from '../../utils/enums.js';
import NoCodePopup from './no-code'
import SelectOptionV1 from '../select-option/v2/index.js';
import TextBoxV1 from '../hoc/v2/text.box.js';
import { RadioCheckMark } from '../hoc/radiobox.js';
import RippalEffect from '../hoc/ripple-effect'


const default_charts = [
    'bar_chart',
    'stacked_bar_chart',
    'line_chart',
    'area_chart',
    'line_bar_chart',
    'composed_bar_chart',
    'horizontal_bar_chart',
    'waterfall_chart',
    'horizontal_waterfall_chart',
    "dash_line_chart",
    "stacked_bar_chart_100_perc",
    "horizontal_stack_bar_chart",
    "horizontal_stack_bar_chart_100",
    "stacked_bar_chart_100_perc",
    "tree_map",
    "scatter_chart",
    'funnel_chart',
    'pareto_chart',

];


const pie_charts = [
    'pie_chart',
    'donut_chart',
    "gauge_chart",
]



const map_level = [
    { name: 'Customer Data', value: 'customer_data' },
    { name: 'Branch Data', value: 'branch_data' },
    { name: 'HSL Data', value: 'hsl_data' },

    { name: 'Districts', value: 'district_data' },
    { name: 'Divisions', value: 'division_data' },
    { name: 'Mandalas', value: 'mandala_data' },
];





const chart_axis_setting = [
    {
        name: "Hide X Axis Tick",
        value: 'hide_x_axis',
        render_mode: ['chart'],
        chart_type: [...default_charts, ...pie_charts]
    },
    {
        name: "Hide Primary X Axis Tick",
        value: 'hide_x_axis_secondry',
        render_mode: ['chart'],
        chart_type: [...default_charts, ...pie_charts]
    },
    {
        name: "Hide Y Axis Tick",
        value: 'hide_y_axis',
        render_mode: ['chart'],
        chart_type: [...default_charts, ...pie_charts]
    },
    {
        name: "Hide Y Axis Label",
        value: 'hide_y_axis_label',
        render_mode: ['chart'],
        chart_type: [...default_charts, ...pie_charts]
    },
    {
        name: "Hide X Axis Label ",
        value: 'hide_x_axis_label',
        render_mode: ['chart'],
        chart_type: [...default_charts, ...pie_charts]
    },
    {
        name: "Hide Y Axis Border ",
        value: 'hide_y_axis_border',
        render_mode: ['chart'],
        chart_type: [...default_charts, ...pie_charts]
    },
    {
        name: "Hide X Axis Border",
        value: 'hide_x_axis_border',
        render_mode: ['chart'],
        chart_type: [...default_charts, ...pie_charts]
    },
]


const appearance_type = [
    {
        name: "Hide Tooltip",
        value: 'hide_tooltip',
        render_mode: ['chart'],
        chart_type: [...default_charts, ...pie_charts,]
    },

    {
        name: "Hide Legend",
        value: 'hide_legend',
        render_mode: ['chart'],
        chart_type: [...default_charts, ...pie_charts,]
    },
    {
        name: "Hide Border ",
        value: 'hide_border',
        render_mode: ['chart', 'table', 'single_cell', 'group', 'label'],
        chart_type: [...default_charts, ...pie_charts, ...['single_cell', 'table', 'group', 'label']]
    },

    {
        name: "Hide Headers",
        value: 'hide_headers',
        render_mode: ['table'],
        chart_type: [...default_charts, ...pie_charts, ...['table']]
    },

    {
        name: "Hide Label",
        value: 'hide_label',
        render_mode: ['chart'],
        chart_type: [...default_charts]
    },

    {
        name: "Hide Grid",
        value: 'hide_grid',
        render_mode: ['chart'],
        chart_type: [...default_charts,]
    },
    {
        name: "Hide Grand Total",
        value: 'hide_grand_total',
        render_mode: ['table'],
        chart_type: [...default_charts, ...pie_charts, ...['table']]
    },
    {
        name: "Show Percentage Total",
        value: 'show_percentage_total',
        render_mode: ['table'],
        chart_type: [...default_charts, ...pie_charts, ...['table']]
    },
    {
        name: 'Show Percentage in Data Labels',
        value: 'show_percentage_in_data_labels',
        render_mode: ['chart'],
        chart_type: [...pie_charts, ...default_charts]
    },
    {
        name: 'Show Percentage in Legends',
        value: 'show_prcentage_in_legends',
        render_mode: ['chart'],
        chart_type: [...pie_charts, ...default_charts]
    },


    {
        name: "Show Trend",
        value: 'show_trend',
        render_mode: ['chart'],
        chart_type: ['single_cell']
    },
    {
        name: 'Show Data Label',
        value: 'show_data_label',
        options: ["none", 'sum', "count", "avg", "difference", "percentage", "percentage difference"],
        render_mode: ['chart'],
        chart_type: [...default_charts, ...pie_charts]
    },
    {
        name: "Show Brush",
        value: 'show_brush',
        render_mode: ['chart'],
        chart_type: ["bar_chart"]
    },
]




const default_number_data_types = ['number', 'int', 'double', 'currency', 'integer',]


// DashboardEditor component
const DashboardEditor = (props) => {

    // Destructuring props
    const { reportItem, report_item_id, save_fun } = props;


    // State for storing current report
    const [current_report, set_current_report] = useState(undefined);
    const [report_modified_properties, set_report_modified_properties] = useState({})
    const [setting_col, set_setting_col] = useState({
        "additional_setting": false,
        "interactive": false,
        "filter_setting": false,
        "date_setting": false,
        "widget_setting": true,
    });



    //
    const [questionFocus, setQuestionFocus] = useState(undefined);


    const render_title_by_chart_type = (type) => {
        const charts_t = [...default_charts, ...pie_charts];
        if (charts_t.indexOf(type) > -1) {
            return 'Chart';
        }
        if (type === 'label') return 'Label';
        if (type === 'group') return 'Box';
        if (type === 'single_cell') return 'Single Cell';
        if (type === 'table') return 'Table';
    }



    const onTextChange = (key, value) => {

        const local_report_object = Object.assign({}, report_modified_properties);


        if (key === "chart_type") {
            if (value === 'group') {
                local_report_object.chart_type = 'group';
                local_report_object.render_mode = 'group'
                local_report_object.is_group = true;
                local_report_object.question = '';
            }
            else if (value === 'table') {
                local_report_object.chart_type = 'table';
                local_report_object.render_mode = 'table'
                local_report_object.is_group = undefined;

            }
            else {
                local_report_object.chart_type = value;
                local_report_object.render_mode = 'chart'
                local_report_object.is_group = undefined
            }
        };


        local_report_object[key] = value;

        if (key === 'question') {
            local_report_object['isQuestionModified'] = true;
        }

        set_report_modified_properties(local_report_object);
        set_current_report({ ...current_report, ...local_report_object });
    }



    const update_theme_value = (__values__) => {
        const local_report_object = Object.assign({}, report_modified_properties);
        local_report_object['theme_json_values'] = __values__;

        set_report_modified_properties(local_report_object);
        set_current_report({ ...current_report, ...local_report_object })
    };



    // console.log("report_modified_properties", report_modified_properties)


    const local_save_function_ = () => {
        const clone_report_item = report_modified_properties ? { ...report_modified_properties } : {};
        clone_report_item.id = report_item_id;
        clone_report_item.modified = true;
        if (report_modified_properties?.isQuestionModified) {
            clone_report_item.call_java_forcefully = true;
        }

        props.save_report_items([clone_report_item], report_item_id, report_modified_properties?.isQuestionModified)

        save_fun(report_item_id, report_modified_properties)

        // if (report_modified_properties?.isQuestionModified) {
        //     setTimeout(() => {
        //         props.set_report_to_refresh(report_item_id);
        //     }, 1000);
        // }
        hideRighter()
    }





    // useEffect to update current report when report_item_id changes
    useEffect(() => {

        reportItem && set_current_report(reportItem);

    }, [report_item_id, reportItem]);



    const update_report_item = (modefied_properties) => {
        console.log("modefied_properties", modefied_properties)
        const local_report_object = modefied_properties ? Object.assign(report_modified_properties, modefied_properties) : {}
        set_report_modified_properties(local_report_object);
        set_current_report({ ...current_report, ...local_report_object })
    }



    const open_no_code_popup = (type, report_item_to_use) => {

        const temp_current_report = report_item_to_use ? JSON.parse(JSON.stringify(report_item_to_use)) : {};

        delete temp_current_report['top'];
        delete temp_current_report['left'];
        delete temp_current_report['top_px'];
        delete temp_current_report['left_px'];
        delete temp_current_report['width'];
        delete temp_current_report['height'];
        delete temp_current_report['width_px'];
        delete temp_current_report['height_px'];

        // const report_item = report_items?.length > 0 && report_items[0];

        const report_item_title = type === 'dashboard_config_query_builder' ? 'Drag & Drop' : "NoteBook";

        showPopup(report_item_title, undefined, enums.default.popupType.element_with_header, NoCodePopup, {

            db_info_id: temp_current_report?.db_info_id,
            __reporting_db_info_id: temp_current_report?.reporting_db_info_id,
            chart_type: temp_current_report?.db_info_id,
            render_mode: temp_current_report?.chart_type,
            current_report: temp_current_report,
            report_type: type,
            save_current_report: update_report_item

        },
            undefined,
            undefined,
            undefined, {
            width: '95vw',
            height: '85vh'
        },
            true
        )
    }


    const change_report_item_type = (type, need_to_remove, need_to_show_popup = false) => {

        console.log("type", type)
        const local_report_object = report_modified_properties && Object.keys(report_modified_properties)?.length > 0 ? Object.assign({}, report_modified_properties) : {}

        const prev_report = { ...(current_report || {}), ...local_report_object }

        local_report_object['report_item_type'] = type;
        local_report_object['isQuestionModified'] = true;

        if (need_to_remove) {

            local_report_object['display_columns'] = []
            local_report_object['column_infos'] = [];
            local_report_object['parameters'] = [];
            local_report_object['json_data'] = '{}';
            local_report_object['criteria'] = '';
            local_report_object['delete_from_storage'] = true;
            local_report_object['call_java_forcefully'] = true;

        }

        set_report_modified_properties(local_report_object);
        set_current_report({ ...current_report, ...local_report_object })
        // saveLocalReport(local_report_object);
        const report_item_to_use = { ...current_report, ...local_report_object }

        if (type === 'dashboard_sql' && prev_report?.report_item_type !== 'dashboard_sql') {
            open_no_code_popup(type, report_item_to_use)
        }

        // const report_columns = (report_item_to_use?.column_infos?.filter((r => !r.deleted)) || [])

        if (type === 'dashboard_config_query_builder' && prev_report?.report_item_type !== 'dashboard_config_query_builder') {
            open_no_code_popup(type, report_item_to_use)
        }

        if (type === 'dashboard' && prev_report?.report_item_type !== 'dashboard') {
            setQuestionFocus(true)
        }
    }

    const clear_report_fields = (type) => {

        let need_to_show_popup = !current_report.id;

        if (current_report && current_report.column_infos?.length > 0) {
            need_to_show_popup = true;
        }
        if (current_report?.report_item_type === type) {
            need_to_show_popup = false;
        }
        if (need_to_show_popup) {
            showPopup(undefined, 'Changing the mode we clear all the data', enums.default.popupType.element, ConfirmBox, { func: { setYes: () => change_report_item_type(type, true) } })

        }
        else {
            change_report_item_type(type, undefined)
        }
    };



    const setting_toggle = (key) => {
        const temp_setting_col = setting_col ? { ...setting_col } : {};
        temp_setting_col && Object.keys(setting_col).map((k) => {
            if (k !== key) temp_setting_col[k] = false;
        })
        temp_setting_col[key] = temp_setting_col[key] ? false : true;
        set_setting_col(temp_setting_col)
    }




    const chart_type = current_report?.chart_type;
    const render_mode = current_report?.render_mode;
    const is_grouped = current_report && current_report.is_group;
    const final_render_type = render_mode ? render_mode : is_grouped ? is_grouped : chart_type;
    const show_question = final_render_type === 'chart' || final_render_type === 'table'



    const chart_axis_setting_to_use = ( !["funnel_chart"].includes(chart_type) ) && chart_axis_setting?.length > 0 && chart_axis_setting.filter(item => {
        if (item.render_mode && item.render_mode.indexOf(render_mode) > -1 && item.chart_type.indexOf(chart_type) > -1) return true;
        else return false
    });


    const chart_apperance_to_use = appearance_type?.length > 0 && appearance_type.filter(item => {
        if (item.render_mode && item.render_mode.indexOf(render_mode) > -1 && item.chart_type.indexOf(chart_type) > -1) return true;
        else return false
    })



    console.log("bhai mere", current_report);



    return (
        <Wrapper>

            {questionFocus && (
                <QuestionPopup
                    hints={props.hints}
                    setQuestionFocus={setQuestionFocus}
                    question={current_report?.question}
                    update_question={(val) => {
                        onTextChange('question', val)
                    }}
                    selected_id={current_report?.id}
                    __selected_date_filter_columns={undefined}
                    __set_selected_date_filter_columns={() => {
                        // __set_selected_date_filter_columns
                    }}
                    db_info_id={current_report?.reporting_db_info_id}
                />
            )}


            <DivRow w="100%"
                style={{
                    padding: '10px 1rem',
                    borderBottom: '1px solid #ccc',
                    background: setting_col?.['widget_setting'] ? '#337dff' : undefined,
                    color: setting_col?.['widget_setting'] ? '#fff' : '#333333',
                    boxSizing: 'border-box'
                }}>
                <div
                    style={{ display: 'flex', cursor: 'pointer', alignItems: 'center', justifyContent: 'space-between', width: '100%', fontSize: '.9rem', fontWeight: 600, }}
                    onClick={() => {
                        setting_toggle("widget_setting")
                    }}
                >
                    Widget Inputs
                    <RippalEffect
                        width="30"
                        title={setting_col['widget_setting'] ? "Close" : "Open"}
                        icon={setting_col['widget_setting'] ? <AngleUp size="0.8rem" height="0.8rem" /> : <AngleDown style={{ color: 'red' }} size="0.8rem" height="0.8rem" />}
                        Click={() => { }}
                    />

                </div>
            </DivRow>

            {setting_col?.['widget_setting'] && (

                <Grouped>
                    <DivRow w="100%" style={{ marginBottom: '1rem' }}>
                        <TextBoxV1
                            label="Name Your Widget"
                            type="text"
                            value={(current_report && current_report?.title) ? current_report?.title : ''}
                            onChange={(e) => {
                                onTextChange('title', e.target.value)
                            }}
                        />
                    </DivRow>
                    <DivRow w="100%" style={{ marginBottom: '1rem' }}>
                        <SelectOptionV1
                            label="Choose Your Chart Type"
                            options={CHART_TYPES_WITH_ICONS}
                            value={current_report && current_report.chart_type ? current_report.chart_type : undefined}
                            value_key="dataKey"
                            display_key='name'
                            icon_key={"icon"}
                            none_value={false}
                            is_search={true}
                            is_chart_selection={true}
                            disabled={(current_report && (current_report.chart_type === "map" || current_report.chart_type === "report"))}
                            on_change={(value, item) => {
                                onTextChange('chart_type', value)
                            }}

                        />
                    </DivRow>


                    {/* {current_report && ['label', 'group', 'report', 'map'].indexOf(current_report.chart_type) == -1 && (
                        <div style={{ width: '100%', marginBottom: '1rem' }}>
                            <DataModeTab
                                active_report_item_type={current_report && current_report?.report_item_type ? current_report.report_item_type : undefined}
                                change_type={(type) => {
                                    // clear_report_fields(type);
                                }}
                            />
                        </div>
                    )} */}

                    {show_question && current_report?.report_item_type === 'dashboard' && (
                        <DivRow w="100%" style={{ marginTop: '0px', marginBottom: '1rem', position: 'relative' }}>
                            <TextBoxV1
                                label="Ask Your Data Question"
                                type="text"
                                id={"input_box_question"}
                                value={current_report?.question}
                                onChange={(e) => {
                                    onTextChange('question', e.target.value)
                                }}
                                onFocus={() => {
                                    setQuestionFocus(true)
                                }}
                                onBlur={(e) => {
                                    // setTimeout(() => {
                                    //     setHintShow(undefined)
                                    // }, 1000)

                                }}
                                fontSize='0.9rem'
                            />
                        </DivRow>
                    )}

                    {show_question && current_report?.report_item_type === 'dashboard_sql' && (
                        <DivRow w="100%" style={{ marginBottom: '1rem', position: 'relative' }}>
                            <TextBoxV1
                                label="Your SQL"
                                type="text"
                                id={"input_box_question_ff"}
                                // refName={_inputRef}
                                onChange={() => {

                                }}
                                value={current_report?.sql_query}
                                onFocus={() => {
                                    // open_report_editor('dashboard_sql');
                                }}
                                fontSize='0.9rem'
                            />
                        </DivRow>
                    )}


                    {show_question && current_report?.report_item_type === 'dashboard_config_query_builder' && (
                        <DivRow w="100%" style={{ marginBottom: '1rem', position: 'relative' }}>

                            {(current_report?.column_infos && current_report?.column_infos.length > 0 && current_report?.column_infos.filter((f => !f.deleted)) || []).length > 0 ? (
                                <React.Fragment>
                                    <label style={{
                                        display: 'block',
                                        fontSize: '0.9rem',
                                        fontWeight: '600',
                                        marginBottom: '7px'
                                    }}>Selected Columns</label>
                                    <p
                                        className="link2"
                                        onClick={() => {
                                            change_report_item_type('dashboard_config_query_builder');
                                        }}

                                    >
                                        {(current_report?.column_infos && current_report?.column_infos.length > 0 && current_report?.column_infos.filter((f => !f.deleted)) || []).map(c => c.alias).join(',')}
                                    </p>
                                </React.Fragment>

                            ) : (
                                <p onClick={() => {
                                    change_report_item_type('dashboard_config_query_builder');
                                }}
                                    className="link">Add Columns</p>
                            )}
                        </DivRow>
                    )}
                </Grouped>
            )}


            {current_report && ['label', 'group', 'report', 'map'].indexOf(current_report.chart_type) == -1 && (
                <DivRow w="100%"
                    style={{
                        padding: '10px 1rem',
                        borderBottom: '1px solid #ccc',
                        background: setting_col?.['agg_settings'] ? '#337dff' : undefined,
                        color: setting_col?.['agg_settings'] ? '#fff' : '#333333',
                        boxSizing: 'border-box'
                    }}
                >
                    <div
                        style={{ display: 'flex', cursor: 'pointer', alignItems: 'center', justifyContent: 'space-between', width: '100%', fontSize: '.9rem', fontWeight: 600, }}
                        onClick={() => {
                            setting_toggle("agg_settings")
                        }}
                    >
                        Aggregate Settings
                        <RippalEffect
                            width="30"
                            title={setting_col["agg_settings"] ? "Close" : "Open"}
                            icon={setting_col["agg_settings"] ? <AngleUp size="0.8rem" height="0.8rem" /> : <AngleDown style={{ color: 'red' }} size="0.8rem" height="0.8rem" />}
                            Click={() => { }}
                        />
                    </div>
                </DivRow>

            )}



            {setting_col["agg_settings"] && ( 

                <Grouped style={{ padding: '0px' }}>
                    <DivRow style={{ marginBottom: '1rem' }}>
                        <div className="setting_header">
                            Aggregate Settings
                        </div>
                        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', padding: '10px 15px', boxSizing: 'border-box' }}>
                            {[{ value: 'show_min', name: 'MIN' }, { value: 'show_max', name: 'MAX' }, { value: 'show_avg', name: 'AVG' }, { value: 'show_st_dev', name: 'STDEV' }, { value: 'show_trend_line', name: 'TREND LINE' }].map((item, index) => {
                                const value = (current_report && current_report[item.value]);
                                return (
                                    <div style={{
                                        marginBottom: '10px',
                                        color: '#333333',
                                        cursor: 'pointer',
                                    }} key={"Appearance" + index} className="flex_row" onClick={() => {
                                        onTextChange(item.value, value ? false : true)

                                    }}>
                                        <RadioCheckMark
                                            outerSize={"25px"}
                                            innerSize={"15px"}
                                            dColor='#337dff'
                                            mR="10px" onClick={() => {
                                                onTextChange(item.value, value ? false : true)
                                            }}
                                            checked={(current_report && current_report[item.value]) ? true : false}
                                        />
                                        <span className='____label'>{item.name}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </DivRow>


                </Grouped>
            )}



            <DivRow w="100%"
                style={{
                    padding: '10px 1rem',
                    borderBottom: '1px solid #ccc',
                    background: setting_col?.['additional_setting'] ? '#337dff' : undefined,
                    color: setting_col?.['additional_setting'] ? '#fff' : '#333333',
                    boxSizing: 'border-box'
                }}
            >
                <div
                    style={{ display: 'flex', cursor: 'pointer', alignItems: 'center', justifyContent: 'space-between', width: '100%', fontSize: '.9rem', fontWeight: 600, }}
                    onClick={() => {
                        setting_toggle("additional_setting")
                    }}
                >
                    Advanced Display Settings
                    <RippalEffect
                        width="30"
                        title={setting_col["additional_setting"] ? "Close" : "Open"}
                        icon={setting_col["additional_setting"] ? <AngleUp size="0.8rem" height="0.8rem" /> : <AngleDown style={{ color: 'red' }} size="0.8rem" height="0.8rem" />}
                        Click={() => { }}
                    />

                </div>
            </DivRow>


            {setting_col["additional_setting"] && (

                <Grouped style={{ padding: '0px' }}>
                    <React.Fragment>
                        {chart_apperance_to_use && chart_apperance_to_use.length > 0 && (
                            <DivRow style={{ marginBottom: '1rem' }}>
                                <div className="setting_header">
                                    {render_title_by_chart_type(current_report.chart_type)} Settings
                                </div>
                                <div style={{ display: 'flex', flexWrap: 'wrap', padding: '10px 15px', boxSizing: 'border-box' }}>
                                    {chart_apperance_to_use.map((item, index) => {
                                        const is_select_option = item && item.options && item.options.length > 0;
                                        const value = (current_report && current_report[item.value]);

                                        return (
                                            <div style={{
                                                marginBottom: '10px',
                                                color: '#333333',
                                                cursor: 'pointer',
                                            }} key={"Appearance" + index} className="flex_row" onClick={() => {
                                                onTextChange(item.value, value ? false : true)

                                            }}>
                                                <RadioCheckMark
                                                    outerSize={"25px"}
                                                    innerSize={"15px"}
                                                    dColor='#337dff'
                                                    onClick={() => {
                                                        onTextChange(item.value, value ? false : true)
                                                    }}
                                                    checked={(current_report && current_report[item.value]) ? true : false}
                                                />
                                                <span className='____label'>{item.name}</span>

                                            </div>
                                        )
                                        // }
                                    })}
                                </div>
                            </DivRow>
                        )}


                        {chart_axis_setting_to_use && chart_axis_setting_to_use.length > 0 && (
                            <DivRow style={{ marginBottom: '1rem' }}>
                                <div className="setting_header">Chart Axis Settings</div>
                                <div style={{ display: 'flex', flexWrap: 'wrap', padding: '10px 15px', boxSizing: 'border-box' }}>
                                    {chart_axis_setting_to_use.map((item, index) => {
                                        const value = (current_report && current_report[item.value]);
                                        return (
                                            <div style={{
                                                marginBottom: '10px',
                                                color: '#333333',
                                                cursor: 'pointer',
                                            }} key={"Appearance" + index} className="flex_row" onClick={() => {
                                                onTextChange(item.value, value ? false : true)

                                            }}>
                                                <RadioCheckMark
                                                    outerSize={"25px"}
                                                    innerSize={"15px"}
                                                    dColor='#337dff'
                                                    mR="10px" onClick={() => {
                                                        onTextChange(item.value, value ? false : true)

                                                    }}
                                                    checked={(current_report && current_report[item.value]) ? true : false}
                                                />
                                                <span className='____label'>{item.name}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </DivRow>
                        )}


                        <DivRow w="100%" style={{ marginBottom: '1rem' }}>
                            <div className="setting_header">Other Settings</div>
                            <ThemeSetting
                                render_mode={render_mode}
                                chart_type={chart_type}
                                themeValues={current_report?.theme_json_values}
                                report_item_id={current_report?.id}
                                update_theme_value={update_theme_value}
                            />

                        </DivRow>
                    </React.Fragment>
                </Grouped>


            )}
            <div style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}>


                {/* <Grouped>

                    <DivRow style={{ marginBottom: '1rem' }}>
                        <div className="setting_header">
                            Aggregate Settings
                        </div>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {[{ value: 'show_mtd', name: 'MTD' }, { value: 'show_ytd', name: 'YTD' }].map((item, index) => {
                                const value = (current_report && current_report[item.value]);
                                return (
                                    <div key={"Appearance" + index} className="flex_row" onClick={() => {
                                        onTextChange(item.value, value ? false : true)
                                    }}>
                                        <CheckBox
                                            size={"30px"}
                                            mR="10px" onClick={() => {
                                                onTextChange(item.value, value ? false : true)

                                            }}
                                            checked={(current_report && current_report[item.value]) ? true : false}
                                        />
                                        {item.name}
                                    </div>
                                )
                            })}
                        </div>
                    </DivRow>


                </Grouped> */}

            </div>
            <FixedBottomSection>
                <Button
                    bgColor="rgb(51, 125, 255)"
                    primary={true}
                    width="120px"
                    onClick={() => {
                        local_save_function_()
                    }}>
                    Save
                </Button>

                <Button
                    bgColor="rgb(136 146 163)"
                    width="120px"
                    onClick={() => {
                        hideRighter()
                    }}>
                    Close
                </Button>
            </FixedBottomSection>
        </Wrapper>
    );
};


export default connect(undefined, { save_report_items, set_report_to_refresh })(DashboardEditor)
