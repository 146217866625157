import React from 'react';
import styled from 'styled-components';
import CloseSvg from '../svg/close';
import AddSvg from '../svg/add';
import EditSvg from '../svg/edit'
import FormulaSvg from '../svg/formula';
import { renderIconByDataType } from './builder.shared.functions'
import DraggerIcon from '../svg/icons-v2/draggerIcon';


const Wrapper = styled.div`

    width: ${props => props.width ? props.width : '100px'};
    box-sizing: border-box;
    overflow: hidden;

    .drag_box{
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: auto;
        margin-top: ${props => props.dropColumnMargin ? props.dropColumnMargin : '0rem'};
        flex-direction: column;
        p{
            margin: 0px;
            margin: auto;
            font-size: .9rem;
            color: rgba(0,0,0,0.54);
            font-weight: 500;
        }
    }
    .textContainer{
        height: 2rem;
        display: flex;
        justify-content: left;
        align-items: end;
        h2{
            font-size: .9rem;
        }
    }
    
    h2{
        font-size: 1rem;
        color: #374a66;
        text-transform: capitalize;
        margin: 0rem;
        /* margin-bottom: .5rem; */
        /* margin-bottom: 1rem; */
        line-height: 1.5rem;
    }
    .dashedBorder{
        border: 1px dashed #58c0fd;
        border-radius: 5px;
        padding: 0.5rem;
        background-color: #f6f8fe;
        margin: 0;
        width: 93%;
        height: ${props => props.wrapperHeight ? props.wrapperHeight : '100px'};
        width: 100%;
        margin: 0;
        padding: 0.5rem;
        box-sizing: border-box;

    }

    ul{
       
        overflow: auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
        height: ${props => props.wrapperHeight ? `calc(${props.wrapperHeight} - 1rem)` : '90px'};
        flex-wrap: wrap;
        align-content: flex-start;
        list-style: none;
        padding: 0;
        margin: 0;
        &::-webkit-scrollbar {
            background-color: #ccc0eb;
            width:2px
        }

        &::-webkit-scrollbar-track {
            background-color: #f6f8fe;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #ccc0eb;
            border-radius:0px;
            height: 3px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: #ccc0eb;
            height: 3px;
        }

        .active{
                border: 1px solid #308bc8;
            }
            
        li{
            cursor: pointer;
            /* border: 1px solid #C5D4EA; */
            background-color: #fff;
            margin: 5px;
            padding: 2px 3px;
            vertical-align: middle;
            border-radius: 6px;
            /* box-shadow: 0px 2px 2px 0px rgba(243,243,243,1); */
            box-shadow: 0 1px 2px rgba(56, 65, 74, .15);
            /* box-shadow: 2px 2px 10px 1px rgba(207,207,207,1); */
            select{
                margin-left: auto;
            }
            .li_flex{
                display: inline-block;
                padding: .0px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .column_name{
                text-align: left;
                font-size: .9rem;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                color: #374a66;
                text-transform: capitalize;
                line-height: 1rem
                
            }
            .close {
                cursor: pointer;
                /* opacity: 0; */
                display: flex;
                width: 1rem;
                height: 1.3rem;
                align-items: center;
                justify-content: center;
                border-radius: 6px;
                &:hover{
                    background: #F3F3F3;
                }
            }

            /* &:hover{
                .close {
                    opacity: 1;
                     background: #ccc;
                 }
            } */
        }
    }
`;


const ElementBox = (props) => {

    const {
        addElementFunction,
        index,
        dropColumnMargin,
        open_formula_creator,
        deleteFormula,
        change_column_order,
        activeColumn,
        margin,
        onColumnClick,
        width,
        hidePlacholder,
        hideDeleteAction,
        wrapperHeight,
        onDragOver,
        drop_column,
        elementData,
        onDelete,
        name,
        area_key,
        isAggregateSelection,
        splitKey,
        aggregateOnChange,
        setActiveColumn,
        pivot_type

    } = props;



    return (
        <Wrapper dropColumnMargin={dropColumnMargin} margin={margin} width={width} wrapperHeight={wrapperHeight}
            onDragOver={onDragOver}
            onDrop={(e) => {
                if (drop_column) {
                    drop_column(e, area_key, pivot_type, index);
                }
            }}>
               {name &&  
                    <div className='textContainer'>           
                        <h2>{name}</h2>
                    </div>
                }

           <div className='dashedBorder'>
            <ul>
                {elementData && elementData.length === 0 || (elementData === undefined) && !hidePlacholder ?
                    <div className="drag_box">
                        <DraggerIcon size={"3rem"} height={'3rem'} color={"#fff"} />
                        <p>Drop Columns Here</p>
                    </div> : null}

                {elementData && elementData.length > 0 && elementData.map((el, i) => {

                    if (el) {

                        const is_formula = el.is_formula;
                        const temp_d_value = el.name ? el.name : el.column_name;
                        const display_value = (temp_d_value && temp_d_value.length > 0 && temp_d_value.indexOf('__nf_comma__') > - 1) ? temp_d_value.split('__nf_comma__').join(',') : temp_d_value;
                        const _data_type = el.is_formula ? 'formula' : el.data_type;
                        const title = el.name ? el.name : el.column_name;

                        if (!el.deleted && !el.is_repeater) {
                            return (
                                <li
                                    draggable={true}
                                    onDragStart={(event) => {
                                        event.dataTransfer.setData("table_name", el.table_name)
                                        event.dataTransfer.setData("column_name", el.column_name)
                                        event.dataTransfer.setData("data_transfer_from", "element_box");
                                        event.dataTransfer.setData("index", el.index);
                                        event.dataTransfer.setData("column_info_index", el?.index);

                                        const column_info = {
                                            ...el
                                        }

                                        // new regin code
                                        event.dataTransfer.setData("column_info", JSON.stringify(column_info));
                                        event.dataTransfer.effectAllowed = "move"
                                        if (setActiveColumn) {
                                            setActiveColumn(undefined);
                                        }
                                    }}
                                    onDrop={(event) => {

                                        event.preventDefault();
                                        event.stopPropagation();
                                        change_column_order(event, area_key, index)

                                    }}
                                    title={title} key={"el" + i}
                                    val={el?.index || i}
                                    column_index={el?.index}
                                    className={(activeColumn && activeColumn?.table_name === el?.table_name && activeColumn?.index === el?.index) ? "active" : undefined}
                                >
                                    {/* {process.env.NODE_ENV !== "production" && el?.id} */}
                                    <div className="li_flex" onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        if (onColumnClick) {
                                            onColumnClick(undefined, el, i, index);
                                        }
                                    }}>
                                        {renderIconByDataType(_data_type)}&nbsp;&nbsp;
                                        <div onClick={(e) => {
                                        }} className="column_name">
                                            {typeof el === 'object' ? display_value : el}
                                        </div>&nbsp;

                                        {is_formula && (
                                            <div title="Edit" className="close" onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation()
                                                open_formula_creator(el, el?.index, index);
                                            }}>
                                                <EditSvg size=".5rem" height=".5rem" />
                                            </div>
                                        )}

                                        &nbsp;&nbsp;{!hideDeleteAction && <div title="remove" className="close" onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation()
                                            onDelete(area_key, (el.index > -1 ? el.index : i), el.table_name, index)
                                        }}>
                                            <CloseSvg size=".5rem" height=".5rem" />
                                        </div>}


                                    </div>
                                </li>
                            )
                        }
                    }
                })}
            </ul>
            </div>
        </Wrapper>
    )
}

export default ElementBox;
