import { simpleDispatch_get } from './helper';
import * as actionTypes from '../action-types/sqlHistory';
import { constants } from '../utils/constants';



export const get_sql_history = (id) => async dispatch => {
    const url = constants.END_POINTS.API + constants.END_POINTS.HISTORY.POINT + constants.END_POINTS.HISTORY.GET_SQL_HISTORY;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_HISTORY_SUCCESS, true);
};
