import React from "react";

const ReloadIcon = (props) => {
  return (
    <svg
      width={props.size ? props.size : "2.2rem"}
      height={props.height ? props.height : "2.2rem"}
      fill={props.color ? props.color : "#000"}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 43 49"
      preserveAspectRatio="xMidYMid meet"
    >
<path d="M0 0 C4.88097273 4.88097273 7.32436671 10.55670138 7.5625 17.4375 C7.48895297 23.8045716 5.17453783 29.05816525 1 33.8125 C0.4225 34.286875 -0.155 34.76125 -0.75 35.25 C-1.74 34.92 -2.73 34.59 -3.75 34.25 C-3.13394021 30.24561138 -0.99558699 27.54820589 1.25 24.25 C3.41693367 17.749199 2.0865942 13.15011594 -0.75 7.25 C-1.245 5.765 -1.245 5.765 -1.75 4.25 C-3.48142854 3.09077872 -3.48142854 3.09077872 -5.75 2.25 C-6.636875 1.858125 -7.52375 1.46625 -8.4375 1.0625 C-14.12960087 -0.33367569 -18.81354515 0.30004102 -24.0625 2.875 C-28.13797798 6.47658519 -30.36059192 9.55195832 -31.1328125 14.90234375 C-31.39210218 21.20308294 -30.76670644 25.32953461 -26.75 30.25 C-24.18868246 31.89237156 -21.58091796 33.14374322 -18.75 34.25 C-18.75 33.26 -18.75 32.27 -18.75 31.25 C-14.36999924 31.75057152 -11.90830185 33.18193534 -8.75 36.25 C-8.75 37.24 -8.75 38.23 -8.75 39.25 C-10.4296875 40.671875 -10.4296875 40.671875 -12.625 42 C-13.70394531 42.67289063 -13.70394531 42.67289063 -14.8046875 43.359375 C-15.76761719 43.80023438 -15.76761719 43.80023438 -16.75 44.25 C-17.41 43.92 -18.07 43.59 -18.75 43.25 C-18.42 41.93 -18.09 40.61 -17.75 39.25 C-19.0184375 39.1571875 -19.0184375 39.1571875 -20.3125 39.0625 C-26.39188395 37.6255547 -30.28755095 34.47478046 -33.6875 29.25 C-36.90773693 22.86284133 -36.97919295 15.6895557 -35.0859375 8.8515625 C-32.3122639 2.55652937 -27.59127569 -1.63378455 -21.25 -4.125 C-13.01989749 -5.59466116 -6.98461826 -4.44475708 0 0 Z " fill={props.color} transform="translate(35.75,4.75)"/>
</svg>
  );
};

export default ReloadIcon;
