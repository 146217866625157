
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { IconSmall, ActionWrapper, HeaderWrapper, FromEditor } from './layout.editor.styled';
import { constants } from '../../utils/constants';
import RippleEffect from '../hoc/ripple-effect';
import CrossSvg from '../svg/close'
import styled from 'styled-components';
import { apply_styles } from '../../utils';


const MainDragDiv = styled.div`
    border: 1px solid #ccc;
    ${props => props.theme ? apply_styles(props.theme, constants.STYLED_COMPONENTS.ALL) : null};
    margin: 0px;
`;

const Title = styled.div`
    font-size: 1rem;
    color: #222;;
    ${props => props.theme ? apply_styles(props.theme, constants.STYLED_COMPONENTS.ALL) : null};

`;


const enum_movement_mode = {
    resizing: 1,
    dragging: 2,
    none: 0
};

const key_codes = {
    keyUp: 38,
    keyDown: 40,
    keyLeft: 37,
    keyRight: 39
}



const start_dimensions = { height: 0, width: 0 };
const sum = { x: 0, y: 0 };

const DraggableDiv = (props) => {

    const {

        type,
        top,
        left,
        width,
        height,
        index,
        deleted,
        reportItem,
        is_group,
        select_report_item,
        selected_ids,
        saveLocalReport,
        current_dragging_index,
        changeReportItemsValues,
        current_position,
        shiftKeyPressed,
        currentKeyCode,
        key_event_counter,
        startDragPosition,
        setRightPanelOpen,
        delete_report_item,
        setShowMenu,
        scroll_position,
        theme,
        render_mode

    } = props;


    const report_id = reportItem && reportItem.id;

    const am_i_selected = selected_ids.length === 1 && selected_ids[0] === report_id;
    const am_i_selected_for_movement = selected_ids.indexOf(report_id) > -1;

    const [movement_mode, set_movement_mode] = useState(enum_movement_mode.none);
    const [dragged_called_from_outside, set_dragged_called_from_outside] = useState(false);

    const [dimensions, setDimensions] = useState({
        width: 0,
        height: 0
    });

    const [position, setPosition] = useState({
        top: 0,
        left: 0
    });

    const scroll_top = scroll_position && scroll_position.top;
    const scroll_left = scroll_position && scroll_position.left;


    const [start_position, set_start_position] = useState({ x: 0, y: 0 });

    const elementRef = useRef(null);

    useEffect(() => {
        setDimensions({ width, height });
        setPosition({ top, left });
    }, []);

    useEffect(() => {
        if (current_dragging_index > -1 && am_i_selected_for_movement && startDragPosition && startDragPosition.x > 0) {

            set_start_position({ ...startDragPosition });
            set_dragged_called_from_outside(true);
            set_movement_mode(enum_movement_mode.dragging);

        }
    }, [startDragPosition]);


    useEffect(() => {
        if (am_i_selected_for_movement) {
            // let's move the item
            if (shiftKeyPressed) {
                resize_element(currentKeyCode);
            }
            else {
                move_element(currentKeyCode);
            }
        }
    }, [currentKeyCode, shiftKeyPressed, key_event_counter]);


    useEffect(() => {

        if (movement_mode === enum_movement_mode.resizing && current_dragging_index > -1 && am_i_selected_for_movement && current_position && current_position.x > 0) {
            const delta = { x: current_position.x - start_position.x, y: current_position.y - start_position.y };

            setDimensions({ height: start_dimensions.height + delta.y, width: start_dimensions.width + delta.x })
        }
        else if (movement_mode === enum_movement_mode.dragging && current_dragging_index > -1 && am_i_selected_for_movement && current_position && current_position.x > 0) {
            const delta = { x: current_position.x - start_position.x, y: current_position.y - start_position.y };

            set_start_position({ ...current_position });
            setPosition({ top: position.top + delta.y, left: position.left + delta.x })
        }
        else if ((typeof current_dragging_index === 'undefined' || current_dragging_index === -1) && movement_mode !== enum_movement_mode.none) {

            stopMovement(undefined, movement_mode);
        }
    }, [current_position]);


    const initMovement = (e, index, movement_type = enum_movement_mode.none) => {
        e.preventDefault();
        e.stopPropagation();

        select_report_item(report_id, false);

        set_start_position({ x: e.clientX, y: e.clientY });

        start_dimensions.height = dimensions.height;
        start_dimensions.width = dimensions.width;

        set_movement_mode(movement_type);

        if (movement_type === enum_movement_mode.resizing) {
            props.initResize(index);
        }
        else {
            props.initDrag(index, e.clientX, e.clientY);
        }
    };


    const stopMovement = (e, movement_type = enum_movement_mode.none) => {
        // console.log("stop bhi", movement_type);
        e && e.preventDefault();
        e && e.stopPropagation();

        // console.log('stop the movement boxxxxx:', report_id, position);

        if (movement_type === enum_movement_mode.dragging) {

            // let's place the item
            // const delta = { x: e.clientX - start_position.x, y: e.clientY - start_position.y };
            // setPosition({ top: position.top + delta.y, left: position.left + delta.x });
            // console.log('delta: ', delta);

            saveLocalReport({ top_px: position.top, left_px: position.left });

            changeReportItemsValues(position.top, position.left, undefined, undefined, index);
        }
        else {
            changeReportItemsValues(undefined, undefined, dimensions.height, dimensions.width, index);
        }

        set_start_position({ x: 0, y: 0 });

        start_dimensions.height = 0;
        start_dimensions.width = 0;

        set_movement_mode(enum_movement_mode.none);

        if (movement_type === enum_movement_mode.resizing) {
            props.stopResize();
        }
        else {
            props.stopDrag();
        }
    };


    const move_element = (key_code, move_by_pixels = 1) => {

        const move = ({ top = 0, left = 0 }) => {
            setPosition({ top: top + position.top, left: position.left + left })
        };

        switch (key_code) {
            case key_codes.keyDown:
                move({ top: move_by_pixels });
                break;
            case key_codes.keyUp:
                move({ top: -1 * move_by_pixels });
                break;
            case key_codes.keyRight:
                move({ left: 1 * move_by_pixels });
                break;
            case key_codes.keyLeft:
                move({ left: -1 * move_by_pixels });
                break;
        }
        changeReportItemsValues(position.top, position.left, undefined, undefined, index)

    };


    const resize_element = (key_code, resize_by_pixels = 1) => {
        const resize = ({ height = 0, width = 0, top = 0, left = 0 }) => {
            setDimensions({ height: dimensions.height + height, width: dimensions.width + width });
            setPosition({ top: position.top + top, left: position.left + left });
        };

        switch (key_code) {
            case key_codes.keyDown:
                resize({ height: -1 * resize_by_pixels, top: 0.5 * resize_by_pixels });
                break;
            case key_codes.keyUp:
                resize({ height: 1 * resize_by_pixels, top: -0.5 * resize_by_pixels });
                break;
            case key_codes.keyRight:
                resize({ width: 1 * resize_by_pixels, left: -0.5 * resize_by_pixels });
                break;
            case key_codes.keyLeft:
                resize({ width: -1 * resize_by_pixels, left: 0.5 * resize_by_pixels });
                break;
        }

        changeReportItemsValues(position.top, position.left, dimensions.height, dimensions.width, index)
    };



    // const theme_json_values = props?.reportItem?.theme_json_values;

    // const z_index = theme_json_values?.


    // console.log("props,", theme)


    const label_style = render_mode === 'table' ? theme?.['table_title'] : render_mode === 'chart' ? theme?.['chart_title'] : theme


    return (
        <React.Fragment>
            <MainDragDiv
                theme={theme}
                style={{
                    top: position.top + "px",
                    left: position.left + "px",
                    height: dimensions.height + 'px',
                    width: dimensions.width + 'px',
                    // background: '#fff',
                    opacity: am_i_selected ? '80%' : undefined,
                    display: deleted ? 'none' : 'block',
                    border: (report_id && selected_ids.indexOf(report_id) > -1) ? '1px solid #2f93f7' : undefined,
                    zIndex: theme?.z_index || (am_i_selected ? 5 : (is_group ? '1' : '2'))
                    // borderRadius: '6px',
                    // zIndex: (am_i_selected ? 5 : (is_group ? '1' : '2'))
                }}
                onContextMenu={(e) => {
                    // onContextMenu(e, am_i_selected)
                }}
                onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setShowMenu(undefined);
                }}
                tabIndex={0}
                onKeyDown={(event) => {
                    // console.log('fired form here1');
                    // onKeyDown(event, index, elementRef)
                }}
                onKeyUp={(event) => {
                    // onKeyUp(event, index, elementRef)
                }}
                onMouseDown={(e) => {
                    select_report_item(report_id, e.shiftKey);
                }}

                id="dragA"
                draggable="true"
                key={index}

                onDragStart={(e) => {
                    initMovement(e, index, enum_movement_mode.dragging);
                }}

                onDrop={(e) => {

                }}
                onDragEnd={(e) => {
                    // console.log('drag end ====> ', e);
                    stopMovement(e, enum_movement_mode.dragging);
                }}
                onDrag={(e) => {
                }}
                onDoubleClick={(e) => {
                    setRightPanelOpen(true);
                }}

                ref={elementRef}>


                {report_id && selected_ids.indexOf(report_id) > -1 &&
                    <div className="delete_item">
                        <RippleEffect
                            width="25"
                            icon={<CrossSvg size=".8rem" height=".8rem" />}
                            Click={() => {
                                delete_report_item(report_id);
                            }}
                        />
                    </div>
                }
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        flexDirection: 'column',
                        color: 'red'
                    }}
                    draggable={false}
                >


                    {reportItem.title && <Title theme={label_style}>{reportItem.title}</Title>}

                    {reportItem && reportItem.chart_type !== 'label' && <div style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        backgroundImage: !is_group ? `url( ${constants.CONTEXT_PATH}/editor/charts/bg_chart/${type}_s.png)` : null,
                        backgroundSize: '50%',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                    }}> </div>}

                </div>

                <div className="resizer"
                    draggable={false}
                    onMouseDown={(e) => initMovement(e, index, enum_movement_mode.resizing)}
                    onMouseUp={(e) => stopMovement(e, enum_movement_mode.resizing)}
                />

                {am_i_selected && <div id='helper_line_horizontal_top' style={{ top: `calc(0.3rem + ${(80 + 5 - (scroll_top || 0) + position.top)}px )`, height: '1px', position: 'fixed', left: '0px', width: 'calc(100vw + 1000px)', borderTop: '0.5px dashed black', zIndex: 10 }} />}
                {am_i_selected && <div id='helper_line_horizontal_bottom' style={{ top: `calc(0.3rem + ${(80 + 5 - (scroll_top || 0) + position.top + dimensions.height)}px )`, height: '1px', position: 'fixed', left: '0px', width: 'calc(100vw + 1000px)', borderBottom: '0.5px dashed black', zIndex: 10 }} />}
                {am_i_selected && <div id='helper_line_horizontal_left' style={{ top: '0px', height: '500vh', position: 'fixed', left: `calc(0.3rem + ${position.left + 5 + (scroll_left || 0)}px`, width: '1px', borderLeft: '0.5px dashed black', zIndex: 10 }} />}
                {am_i_selected && <div id='helper_line_horizontal_right' style={{ top: '0px', height: '500vh', position: 'fixed', left: `calc(0.3rem + ${position.left + 5 + (scroll_left || 0) + dimensions.width}px`, width: '1px', borderRight: '0.5px dashed black', zIndex: 10 }} />}

            </MainDragDiv>
        </React.Fragment>

    )
}

export default DraggableDiv;