import styled from 'styled-components';
import { themes } from '../../theme/theme.config';
import { getSelectedTheme } from '../../utils';

const selected = getSelectedTheme();

const { connectStyle } = themes[selected];


export const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    margin: auto;
    position: relative;
    padding-bottom: 40px;

`;



export const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 10px;
    padding-bottom: 20px;
    box-sizing: border-box;  

    h2{
        font-size: 1.714rem;
        line-height: 1.29;
        text-align: left;
        margin: 0px;
        position: relative;
        width: 100%;
        font-weight: normal;


        span{
            padding-right: 30px;
            box-sizing: border-box;
            position: relative;;
            z-index: 1;
            background: #fff;
        }

        &::after{
            content: "";
            width: 100%;
            height: 1px;
            background:#eaeaea;
            position: absolute;
            right: 0px;
            top: 50%;
        }
    }

`;



export const EditorInnerWrapper = styled.div`
    width: 100%;
    padding: 25px;
    box-sizing: border-box;
    background: ${connectStyle.color.innerBoxBg};
    /* border-radius: 10px; */
    box-sizing: border-box;
    /* border: 10px solid #eaeaea; */
    overflow: auto;
    /* height:${props => props.scroll ? " calc(100vh - 180px)" : '100%'}; */
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 180px);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 6px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;


    
    &::-webkit-scrollbar {
            background-color:red;
            width:5px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }

`;


