
export const HINTS_INIT = 'modules/config/hints/init';

export const HINTS_REQUESTED = 'modules/config/hints/request';
export const HINTS_SUCCESS = 'modules/config/hints/success';
export const TABLES_HINTS_REQUESTED = 'modules/config/tableHints/request';
export const TABLES_HINTS_SUCCESS = 'modules/config/tableHints/success';
export const HINTS_BY_ENTITY_REQUESTED = 'modules/config/hintsByDbInfo/request';
export const HINTS_BY_ENTITY_SUCCESS = 'modules/config/hintsByDbInfo/success';
export const HINTS_WITH_TABLE_COLUMN_NAME_SUCCESS = 'modules/config/getHintsWithTableColumnName/success';
export const CLEAR_HINTS_BY_ENTITY_SUCCESS = 'modules/config/hintsByDbInfo/clear/success';
export const SAVE_HINTS_REQUESTED = 'modules/config/saveHints/request';
export const SAVE_HINTS_SUCCESS = 'modules/config/saveHints/success';
export const CLEAR_HINTS_SUCCESS = 'modules/config/clearHints/success';
export const CLEAR_CONFIG_EDITOR_STATE = 'modules/config/configEditor/clear/success'
export const SAVE_HINTS_IN_SESSION =  'modules/config/clearHints/SAVE_HINTS_IN_SESSION'
export const GET_HINTS_FROM_SESSION =  'modules/config/clearHints/GET_HINTS_FROM_SESSION';
export const GET_HINTS_BY_DB_INFO_ID_SUCCESS = 'modules/config/get_hint_by_db_info_id';
export const CLEAR_HINTS_OF_DB_INFO_ID = 'modules/config/clearHints/by_db_info_id';
export const SAVE_VALUE_CONTAINER = 'module/SAVE_VALUE_CONTAINER'


/***
 * Rules
 */

export const GET_HINTS_FOR_RULES = 'modules/config/hints/get_hints_for_rules';
export const SAVE_HINTS_FOR_RULES = 'modules/config/hints/save_hints'
export const GET_TABLE_COLUMN_NAME_HINTS = 'modules/config/table_column_name_hints';

export const GET_TBL_INFO_BY_TBL_NAME = 'modules/config/get_tbl_info_by_tbl_name';
export const CLEAR_TBL_INFO = 'modules/config/clear_tbl_info';
export const SAVE_TBL_INFO = 'modules/config/save_tbl_info';

export const RAISE_DUBLICATE_HINTS_FOUND = 'modules/config/raise_dublicate_hints_found';
export const MT_DUBLICATE_HINTS_FOUND = 'modules/config/mt_dublicate_hints_found';
export const CLOSE_HINTS_POPUP  = 'modules/config/close_hints_popup';
