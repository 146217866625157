import * as actions from '../action-types/ranking';

const initialState = {
    type: actions.RANKING_INIT,
    configs: undefined,
    tableResults: undefined,
    comparisonData: undefined
};

const rankingReducer = (state, action) => {

    if (!state || typeof action.type === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        case actions.GET_CONFIGS_SUCCESS:
            return {
                type: action.type,
                configs: action.data
            };
        
        case actions.GET_DOCTOR_RANKIG_SUCCESS:
            return {
                type: action.type,
                tableResults: action.data
            };

        case actions.GET_COMPARE_DATA:
            return {
                type: action.type,
                comparisonData: action.data
            };


        default:
            return { ...state };
    }
};

export default rankingReducer;