import React from 'react';
import styled from 'styled-components';

const toggleSize = {
    small : {
        height: '12px',
        width: '22px',
        slider: '10px'
    },
    medium : {
        height: '18px',
        width: '36px',
        slider: '14px'

    },
    large : {
        height:'27px',
        width: '51px',
        slider: '21px'
    },
}


const LabelWrapper = styled.label`
        position: relative;
        display: inline-block;
        /* width: 50px;
        height: 26px; */
        width: ${(props) => toggleSize[props.size].width || '52px'};
        height: ${(props) => toggleSize[props.size].height || '27px'};
       

     .slider { 
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #eaeaea;
    }

    .slider:before {
        position: absolute;
        content: "";
        /* height: 21px;
        width: 21px; */
        height: ${(props) => toggleSize[props.size].slider || '27px'};
        width: ${(props) => toggleSize[props.size].slider    || '27px'};
        left: 2px;
        bottom: .16rem;
        background-color: white;
        transition: 0.4s;
    }
    input:checked+.slider {
        background: ${props => props.activeColor ? props.activeColor : "#308bc8"};
    }
    .slider {
        background:${props => props.bothSide ? props.activeColor ? props.activeColor :  "#308bc8" : undefined};
    }

    input:checked+.slider:before {
         /* transform: translateX(22px); */
        left: .4rem;
        transform: translateX(100%);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 20px;
        width: ${(props) => toggleSize[props.size].width || '51px'};
        /* width: 50px; */

    }

    .slider.round:before {
        border-radius: 50%;
    }

`;

const ToogleButton = (props) => {

    const { checked, onClick, bothSide, activeColor, size='large' } = props;

    return (
        <LabelWrapper size={size} onClick={onClick} bothSide={bothSide} activeColor={activeColor}>
            <input type="checkbox" checked={checked} />
            <span className="slider round"></span>
        </LabelWrapper>
    )
}

export default ToogleButton
