import { constants } from '../utils/constants';

export const get_client_info = async () => {
    const client_id = constants.CLIENT_ID;

    switch(client_id) {
        case 'kx.mis':
            const client = await import('./kx');
            return client.get_client_info();

        default: break;
    }

    return undefined;
};