export const INSIGHTS_INIT = 'modules/insights/init';

export const INSIGHTS_DATA_REQUESTED = 'modules/insights/request';
export const INSIGHTS_DATA_SUCCESS = 'modules/insights/success';

export const REFRESH_INSIGHTS_DATA_REQUESTED = 'modules/insights/refresh/request';
export const REFRESH_INSIGHTS_DATA_SUCCESS = 'modules/insights/refresh/success';

export const SAVE_INSIGHTS_DATA_REQUESTED = 'modules/insights/save/request';
export const SAVE_INSIGHTS_DATA_SUCCESS = 'modules/insights/save/success';

export const CLEAR_INSIGHTS_DATA_REQUESTED = 'modules/insights/clear/request';
export const CLEAR_INSIGHTS_DATA_SUCCESS = 'modules/insights/clear/success';

export const INSIGHTS_LIST_BY_USER_REQUESTED = 'modules/insights/list/request';
export const INSIGHTS_LIST_BY_USER_SUCCESS = 'modules/insights/list/success';

export const INSIGHTS_MENU_REQUESTED = 'modules/insights/menu/request';
export const INSIGHTS_MENU_SUCCESS = 'modules/insights/menu/success';

export const SET_ACTIVE_INSIGHTS_REQUESTED = 'modules/activeInsight/request';
export const SET_ACTIVE_INSIGHTS_SUCCESS = 'modules/activeInsight/success';

export const SET_CHANGE_INSIGHTS_DATA_REQUESTED = 'modules/changeInsight/data/request';
export const SET_CHANGE_INSIGHTS_DATA_SUCCESS = 'modules/changeInsight/data/success';

export const FORM_TABLE_CREATE_SUCCESS = 'modules/formtable/create/success';


export const SAVE_ANSWER_FORM_UI = 'modules/form_ui/save_answer_form_ui'

export const GET_ANSWERS_LIST_BY_TABLE_NAME = 'modules/get_answer_list_by/table_name'

export const EDIT_TABLE_RECORD = 'modules/edit_table/record'


export const DELETE_ANSWER_SUCCESS = 'modules/delete_answer/success'
export const GET_HINTS_BT_CLIENT_ID_SUCCESS = 'modules/get_hints_by_client_id_success';


export const GET_COLUMN_INFO_BY_QUESTION = 'modules/insight/get_column_info_by_question';
export const CLEAR_COLUMN_OF_QUESTION = 'modules/insight/clear_column_of_question'


export const GET_QUESTION_COLUMN_FOR_DASHBOARD_V2 = 'modules/insight/GET_QUESTION_COLUMN_FOR_DASHBOARD_V2';
