import {  simpleDispatch_get, simpleDispatch_post } from './helper';
import * as actionTypes from '../action-types/security';
import { constants } from '../utils/constants';


export const save_sensitive_data = (data) => async dispatch => {

    const post = {
        data: data,
    }

    const url = constants.END_POINTS.API + constants.END_POINTS.SENSITIVE.POINT + constants.END_POINTS.SENSITIVE.SAVE_SENSITIVE;
    await simpleDispatch_post(dispatch, url, post, actionTypes.SAVE_SENSITIVE, true);
};


// export const checkedToggle = () => async dispatch => {


// }




export const get_access_group = () => async dispatch => {
    // const url = constants.END_POINTS.API + constants.END_POINTS.SENSITIVE.POINT + constants.END_POINTS.SENSITIVE.GET_ACCESS_GROUP;

    // try {
    //     simpleDispatch_get(dispatch, url, actionTypes.GET_ACCESS_GROUP);

    // } catch (error) {

    // }
    const url = constants.END_POINTS.API + constants.END_POINTS.ACCESS_GROUP.POINT + constants.END_POINTS.ACCESS_GROUP.GET_ACCESS_GROUP;
    await simpleDispatch_get(dispatch, url, actionTypes.GET_ACCESS_GROUP);

}