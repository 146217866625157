import styled from 'styled-components';


export const TableMainDiv = styled.div`
    width: 75%;
    margin-bottom: 40px;

    &::-webkit-scrollbar {
            background-color: #3F3F3F;
            width:4px;
        }

    &::-webkit-scrollbar-track {
        background-color: #F3F3F3;
        border: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dbdbdb;
        border-radius: 10px;
        height: 4px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color:  #3F3F3F;
        height: 4px;
    }

    
    &::-webkit-scrollbar-button {
        display:none;
    }


    .column_list{
        font-size: 1rem;
        color: #969696;
        line-height: 3.286rem;
        font-weight: 500;
        padding: 0px 10px;
    }


    .sticky_top{
        position: sticky;
        z-index: 0;
        margin-top: 25px;
        background: #fff;
        top: calc(3.517rem - 25px);
        background: #fff;
    }
`;






export const RowDiv = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    border-bottom: ${props => props.border ? undefined : '1px solid #eaeaea'};
    box-sizing: border-box;
    justify-content: ${props => props.justify || undefined};

    &:hover{
        .remove_column{
            opacity: 1;
        }
    }

    cursor: pointer;

    &:hover:not(:nth-child(-1n+2)){
        /* background-color: ${props => props.hover === false ? undefined : '#ccdde3'}; */
        border: 2px solid #3E9EFF;
        border-radius: 6px;
    }

    .table_name{
        height: 2.214rem;
        color: #fff;
        background: #24a8d6;
        font-size: 1.286rem;
        text-transform: capitalize;
        display: flex;
        align-items: center;
    
    }

    .column{
        font-size: 1rem;
        color: #1a1a1a;
        line-height: 2.857rem;
        font-weight: normal;
    }


    .tag_name{
        padding: 1.4rem 1rem;
        box-sizing: border-box;
        height: 36px;
        border: none;
        background: #cde0fffa;
        opacity:1;
        font-size: 1rem;
        font-weight: normal;
        line-height: 2.1rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;

    }


    .tag_action{
        display: flex;
        align-items: center;
        justify-content: flex-start;

        div{
            margin-right: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #969696;
            svg{
                margin-right: 6px;
                fill: #969696;
            }
            &:hover{
                svg{
                    fill: #1a75bb;
                }
                color: #1a75bb;
            }
        }

        .edit{
            margin-right: 15px;
        }
    }



    .remove_column{
        padding: 0px 1rem;
        opacity: 0;
        svg{
            fill: #fff;
        }
    }

`;




export const TdDiv = styled.div`
    font-size: 0.9em;
    border: 0px;
    border-collapse: collapse;
    box-sizing: border-box;
    padding: 2px 25px;
    width: ${props => props.w || '100%'};
    flex:${props => props.w ? undefined : 1};
    text-align: ${props => props.align || 'left'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
`;
