import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { get_history, delete_history } from '../../actions/favourite';
import { connect } from 'react-redux';
import { _getDataFromReportingServer } from '../../utils'
import { withRouter } from 'react-router-dom';
import SharedTable from '../shared-table';
import { PageInnerWrapper } from '../hoc/styled.hoc'
import { dispatchHeaderInformation } from '../../actions/common';


const Wrapper = styled.div`
   width: 100%;
   box-sizing: border-box;
   padding: 0px 10px;
`;


const Index = (props) => {

    const { historyData } = props;

    const [localData, setLocalData] = useState(undefined);

    useEffect(() => {
        let _page_info = {
            title: 'Your History List'
        }
        props.dispatchHeaderInformation(_page_info)
        props.get_history();
    }, []);


    useEffect(() => {
        if (historyData) {
            if (historyData.results) {
                setLocalData(historyData.results)
            }
        }
    }, [historyData])


    const deleteHistory = (item) => {
        let temp_item = item;
        temp_item.is_active = 0;
        temp_item.is_modified = true;
        props.delete_history(temp_item)

        setTimeout(() => {
            props.get_history();

        }, 200)
    }


    const questionClick = (item) => {
        if (item) {
            _getDataFromReportingServer(item.question, undefined, undefined, undefined, undefined, undefined, props.history)
        }
    }



    return (
        <Wrapper style={{ marginTop: '1rem' }}>
            <PageInnerWrapper padding="0px">
                <SharedTable
                    deleteAction={deleteHistory}
                    tableData={(localData && localData.length > 0) ? localData : undefined}
                    tableHead={["Question", "Created At", "Action"]}
                    columnsToShow={["question", "created_at",]}
                    isDateColumn={"created_at"}
                    maximumWidthOfColumn={{
                        "question": '20rem'
                    }}
                    columnAction={{
                        "question": questionClick
                    }}
                />
            </PageInnerWrapper>
        </Wrapper>
    )
}


const mapStateToProps = state => ({
    historyData: state.favouriteReducer.historyData,
})

export default withRouter(connect(mapStateToProps, { dispatchHeaderInformation, get_history, delete_history })(Index));
