import React,  { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import { saveRecentColors } from '../../actions/chart.helper';
import { colorsForLegend } from '../../utils/colors';
import { debounceWrapper, saveRecentColorInSession } from '../../utils';
import { ColorCell, RecentColorCell } from './color.box.styled';
import { LegendColorWrapper, LegendContentWrapper } from '../charts/legend/legend.styled';


const ColorBox = (props) => {

    const { changeColor, forWhat, colors, recentColors, type, heightOfChart, saveRecentColors }  = props;
    const [legendPosition, setLegendPosition] = useState('down');
    const [extraHeight, setExtraHeight] = useState(0);
    const sizeRef = useRef(null);

    useEffect(() => {
        /* 
            * Here we are deciding whether the colorBox should open on bottom or top 
        */
       

        if (sizeRef) {
            const windowHeight = heightOfChart ? heightOfChart : window.innerHeight;
            const elementData = sizeRef.current.getBoundingClientRect();
            const { height, y } = elementData;
            
            if ((windowHeight - y) < height) {
                setLegendPosition('up');
                if (height !== 160) {
                    setExtraHeight((160 - height))
                }
            }
        }
        
        // eslint-disable-next-line
    }, []);


    const addRecentColors = color => {
        const tempRecentColors = recentColors ? recentColors : [];

        if (tempRecentColors.indexOf(color) === -1) {
            tempRecentColors.push(color);

            saveRecentColors(tempRecentColors);
            saveRecentColorInSession(tempRecentColors);
        }
    };


    const _changeColor = (color, forWhat, recentColor) => {
        changeColor(color, forWhat);

        if (recentColor) addRecentColors(color);
    };

    return ( 
        <LegendContentWrapper style={{ top: legendPosition === 'down' ? '100%' : `${-166 + extraHeight}px`, left: type === 'table' ? '-1px' : '-117px' }} ref={sizeRef}
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            <LegendColorWrapper>
                {
                    colorsForLegend && colorsForLegend.length > 0 && colorsForLegend.map((color, colorLegendIndex) => {
                        return (
                            <div style={{ marginRight: '5px' }} key={(color.name || colorLegendIndex)}>
                                {
                                    color && color.data && color.data.length > 0 && color.data.map(data => {
                                        return (
                                            <ColorCell
                                                color={data}
                                                onClick={() => _changeColor(data, forWhat)}
                                                key={data}
                                            />
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </LegendColorWrapper>
            <div>
            <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
            >
                <label>More Colors</label>
                <input 
                    type='color' 
                    style={{
                        border: 'none',
                        outline: 'none'
                    }}
                    onChange={(event) => {
                        const debounceOnChange = debounceWrapper(_changeColor, 1000);
                        debounceOnChange(event.target.value, forWhat, true)
                    }}
                    defaultValue={Array.isArray(colors) ? colors[forWhat] : colors }
                />
            </div>
            {
                recentColors && 
                    <div
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}
                    >
                        <label style={{ marginRight: '5px' }}>Recent</label>
                        <div
                            style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', flexWrap: 'wrap' }}
                        >
                            {
                                 recentColors.length > 0 && recentColors.map((color, recentColorIndex) => {
                                    return <RecentColorCell
                                                color={color}
                                                onClick={() => changeColor(color, forWhat, true)}
                                                key={recentColorIndex}
                                            />
                                })
                            }
                        </div>
                    </div>
            }
            </div>
        </LegendContentWrapper>
    );
};

const mapStateToProps = state => {
    const { recentColors } = state.chartHelperReducer;

    return { recentColors };
}

export default connect(mapStateToProps, { saveRecentColors }) (ColorBox);