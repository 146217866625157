import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import OverLay from '../helper/overlay';
import { constants } from '../../utils/constants';
import CheckBox from '../hoc/checkbox';
import { Button } from '../hoc/button';
import AddSvg from '../svg/add';
import RightArrowSvg from '../svg/right.arrow'
import Chevron from '../svg/chevron.down';
import SearchSvg from '../svg/search';
import MarkSvg from '../svg/mark';
import CloseSvg from '../svg/close'

import styled from 'styled-components';

import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils';
const selected = getSelectedTheme();
const { selectBoxConfig } = themes[selected];



export const Wrapper = styled.div`


    position: relative;
    display: flex;
    flex-direction: column; 
    width: 99%;
    border-radius: 3px;
    height: ${(props) => props.inpContHeight || 'auto'};

    .apply_btn{
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        color: #fff;
        text-align: center;
        background: linear-gradient(to right, #3E9EFF, #737DFF);
        /* width: 100%; */
        /* margin-top: 10px; */
        /* padding: 0px 15px; */
        /* border-top: 1px solid #ccc; */
    }
    .mark_icon{
        position: absolute;
        right: 4px;
        top: 10px;
        width: 1rem;
        height: 1rem;
        svg{
            fill: #727c80;
        }
    }
    
    .none_user_input{
        height: 100%;
        position: absolute;
        width: 90%;
        box-sizing: border-box;
        border: 0px;
        border: 0px !important;

        &:focus{
            outline: none;
            border: 0px;
        }
    }
`;


export const SelectLable = styled.div`
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: #000;
    margin-bottom: 5px;
    padding-left: 2px;
    box-shadow: ${props => props.isDropdown ? '0 0 0 0.2rem rgba(0,123,255,.25)' : undefined};
`;

export const SelectBox = styled.div`
    margin-top: auto;
    width: ${props => props.width || '160px'};
    height: 2rem;
    display: flex;
    align-items: center;
    border-radius: 3px;
    position: relative;
    font-size: .9rem;
    font-weight: 500;
    text-decoration: none;
    background-clip: padding-box;
    user-select: none;
    z-index: 3;
    cursor: pointer;
    box-sizing: border-box;
    .select_choose{
        margin-right: 26px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: .9rem;
        font-weight: normal;
        color:  ${props => props.disabled ? "#eaeaea" : '#374a66'};
        line-height: 1.7rem;
        display: flex;
        align-items: center;
        margin-left: 0.8rem;
    }

    .select_arrow{
        position: absolute;
        right: 10px;
        top: 0rem;
        height: 100%;
        color: #334a53;
        font-size: 1.2rem;
        text-align: center;
        transform: ${props => props.isDropdown ? "rotate(180deg)" : undefined};
        line-height: ${props => props.lineHeight || '2.2rem'};
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        width: 20px;
    }
`;



export const DefaultLabel = styled.div`
        /* position: absolute; */
        z-index: 1;
        transition: 0.2s;
        /* color: ${props => props.isOpen ? selectBoxConfig.color.labelFocus : props.LabelTop ? selectBoxConfig.color.label : selectBoxConfig.color.label}; */
        font-size: .9rem;
        color: #374a66;
        text-transform: capitalize;

        sup{
            color: red;
        }
`;


export const DropDownMenu = styled.div`
    width: 100%;
    background: #fff;
    position: absolute;
    top: 100%;
    left: 0px;
    right: 0px;
    box-shadow: ${props => props.dropdownShadow ? props.dropdownShadow : selectBoxConfig.shadow.dropdownBox};
    z-index: 10;
    
    .search_box_main{
        position: relative;
        padding: 0rem 0.6rem;
        box-sizing: border-box;
        margin-bottom: .4rem; 
        margin-top: .4rem; 
        padding-bottom: .4rem;
    
    }

    .text_box_wrapper{
        width: 100%;
        position: relative;
        border: 1px solid #ccc;
        border-radius: 6px;
        span{
            position: absolute;
            left: .5rem;
            top: .8rem;
        }

        input{
            width: 96%;
            height: 2.3rem;
            border: 0px;
            border-radius: 6px;
            padding: .9rem 0rem;
            box-sizing: border-box;
            padding-left: 1.8rem;
            font-size: .9rem;
            font-family: 'Lato', sans-serif;
            color: #374a66;
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: #374a66;
                font-size: .9rem;
                font-family: 'Lato', sans-serif;
            }
            &:focus{
                outline: none;
            }
        }
    }
    .scroll_div{
        overflow-y: auto;
        width: 90%;
        margin: auto;
        padding-right: 0.5rem;
        max-height: ${props => props.height ? props.height : '10rem'};

        &::-webkit-scrollbar {
            background-color: #fff;
            width:4px;
        }

        &::-webkit-scrollbar-track {
            background-color: #F3F3F3;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#dbdbdb;
            border-radius:10px;
            height: 4px;
        }
    }

    .group_name{
        padding: .3rem 1rem;
        font-size: .9rem;
        /* padding-bottom: .6rem; */
        text-transform: capitalize;
        background: #E3EEFF;
        display: flex;
        align-items: center;
        svg{
            margin-bottom: 0;
        }
    }

    .select_result{
        padding: 3px 11px 5px 10px;
        text-transform: uppercase;
        letter-spacing: .06em;
        font-size: .8rem;
        color: #071c26;
        font-weight: 600;
        background: red;
        border: 1px solid blue;
    }
    .select_result_sub{
        font-size: .9rem;
        font-weight: 600;
        /* color: #727c80; */
        padding: ${props => props.isCheckBox ? "0px" : '0px 1rem'};
        white-space: nowrap;
        line-height: ${props => props.menuLineHeight || '2.2rem'};
        cursor: pointer;
        margin-right: 0px;
        /* background: red;
        border: 1px solid blue; */
        /* &:hover{
            color: #000;
        } */
        
    }
    .add_btn{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        font-size: 1rem;
        font-weight: 400;
        cursor: pointer;
    }

`;



const SelectOption = props => {

    const {

        onChangeFunc, isRequired, label, selections,
        displayKey, valueKey, activeValue, height, width, inpContHeight,
        padding, paddingRight, lineHeight, dropDownItemAlign, backgroundColor,
        menuLineHeight, hideCase, valueType, isCheckBox, borderColor,
        disabled, isMultiSelect, setSelectBoxClose, isSelecctAll, isSearch,
        groupKey, sortKey, addAction, removeInputHeight, borderNone, dropdownShadow, isMainLabel,
        _activeGroupName, add_action_value, add_action_on_change, show_add_input, applyFunction, groupIcon, groupIconFn

    } = props;


    const should_we_group = typeof groupKey !== 'undefined' && groupKey.length > 0;
    let fire_group = true;
    let prev_group_item = undefined;

    const [showMenu, setShowMenu] = useState(undefined);
    const [activeHint, setActiveHint] = useState(undefined);
    const [localSelection, setLocalSelection] = useState(undefined);
    const [activeGroupName, setActiveGroupName] = useState(undefined);

    const sort_using_group_key = (data_to_sort, group_key) => {
        const clonned_data = JSON.parse(JSON.stringify(data_to_sort));
        clonned_data && clonned_data.length > 0 && clonned_data.sort((prev_item, this_item) => {
            if (sortKey === 'desc') return ('' + this_item[group_key]).localeCompare(prev_item[group_key]);
            return ('' + prev_item[group_key]).localeCompare(this_item[group_key]);
        });

        return clonned_data;
    };


    useEffect(() => {
        if (!_activeGroupName) {
            setActiveGroupName(undefined);
        }
    }, [_activeGroupName, activeValue])

    let nameValue = undefined;

    useEffect(() => {

        if (should_we_group) {
            setLocalSelection(sort_using_group_key(selections, props.groupKey));
        }
        else {
            setLocalSelection(selections)
        }

    }, [selections])


    useEffect(() => {
        if (setSelectBoxClose) {
            setSelectBoxClose(showMenu);
        }
    }, [showMenu])



    useEffect(() => {
        setActiveHint(activeValue)

        if (activeValue && selections && selections.length > 0) {
            let c_group_name = '';
            selections.map((s) => {
                if (s[valueKey] == activeValue && groupKey) {
                    c_group_name = s[groupKey];
                }
            })
            setActiveGroupName(c_group_name)
        }
    }, [activeValue])



    const onFilterValue = (e) => {
        let value = e.target.value;
        let search_value = value && value.length > 0 && value.toLowerCase();
        let cloneMasterData = (selections && selections.length > 0) ? JSON.parse(JSON.stringify(selections)) : [];
        let filterd_data = cloneMasterData && cloneMasterData.length > 0 && cloneMasterData.filter((item) => {
            if (value == '') {
                return true
            }
            if (typeof item === 'object') {
                if (item[displayKey].toLowerCase().indexOf(search_value) > -1) {
                    return true
                }
            }
            if (typeof item == 'string') {
                if (item.toLowerCase().indexOf(search_value) > -1) {
                    return true
                }
            }
            else return false
        });

        if (should_we_group) {
            setLocalSelection(sort_using_group_key(filterd_data, groupKey));
        }
        else {
            setLocalSelection(filterd_data)
        }
    }


    if (valueKey && displayKey) {
        const activeValueObject = localSelection && localSelection.length > 0 && Array.isArray(localSelection) && localSelection.filter(data => data[valueKey] === activeValue);
        nameValue = activeValueObject && activeValueObject.length > 0 ? activeValueObject[0][displayKey] : undefined;
    }


    let convertArrayValueToString = Array.isArray(activeValue) && activeValue.map((id, i) => {
        let _string = ''
        selections && selections.length > 0 && selections.map((select, j) => {
            if (((valueKey && select[valueKey] === id) || select === id)) {
                _string = _string + (displayKey ? select[displayKey] : select)
                if (i !== (activeValue.length - 1)) {
                    _string = _string + "," + " "
                }
            }
        })
        return _string;
    })


    let _active_value_show = nameValue ? (!hideCase ? _.startCase(_.toLower(nameValue)) : nameValue) : activeValue ? (!hideCase ?
        (isMultiSelect ? (Array.isArray(activeValue) ? convertArrayValueToString : activeValue) :
            _.startCase(_.toLower(activeValue)))
        : activeValue) : '';


    let is_menu_process = addAction ? true : localSelection && localSelection.length > 0;
    let previous_group = undefined;


    return (
        <Wrapper inpContHeight={inpContHeight} style={{ height: removeInputHeight ? 'auto' : undefined }}>
            <input
                className="none_user_input"
                style={{ background: backgroundColor ? backgroundColor : undefined, width: '100%' }}
                type="text"
                value={''}
                onFocus={() => {
                    if (is_menu_process) {
                        setShowMenu(true);
                    }
                }}
                onBlur={() => {
                    setTimeout(() => {
                        setShowMenu(false);
                    }, 200)
                }}
            />
            {
                showMenu &&
                <OverLay zIndex={props.overlay_z_index ? props.overlay_z_index : undefined} onClick={() => {
                    // if (!disabled && is_menu_process) {
                    setShowMenu(!showMenu)
                    // }
                }} />
            }
            <SelectBox
                disabled={disabled}
                borderColor={borderColor}
                borderNone={borderNone}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    // if (!disabled && is_menu_process) {
                    setShowMenu(true)
                    // }
                }}
                isDropdown={showMenu}
                style={{
                    zIndex: showMenu ? 3 : 0,
                    cursor: disabled ? "not-allowed" : undefined
                }}
                width={width}
                padding={padding}
                paddingRight={paddingRight}
                lineHeight={lineHeight}>
                {(!_active_value_show || (_active_value_show && _active_value_show.length == 0)) && (
                    <DefaultLabel
                        isMainLabel={isMainLabel}
                        disabled={disabled}
                        onClick={() => {
                            if (!disabled && is_menu_process) {
                                setShowMenu(!showMenu)
                            }
                        }}
                        isOpen={showMenu}
                        LabelTop={showMenu || (_active_value_show && _active_value_show.length > 0)}
                    >
                        {
                            label ? isRequired ? <span>{label}&nbsp;&nbsp;<sup>*</sup></span> : label : ''
                        }
                    </DefaultLabel>
                )}

                <span className="select_choose" >
                    {/* {activeGroupName && <React.Fragment>
                        {_.startCase(_.toLower(activeGroupName))}
                        &nbsp;&nbsp;<RightArrowSvg size='.8rem' height='.8rem' /> &nbsp;&nbsp;
                    </React.Fragment>} */}
                    <span style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                        {
                            _active_value_show ? _active_value_show : ''
                        }
                    </span>
                </span>
                <span className="select_arrow"> <Chevron size={'.7rem'} height={".7rem"} color="rgb(175, 196, 227)" /> </span>

                {
                    showMenu &&
                    <DropDownMenu
                        dropdownShadow={dropdownShadow}
                        height={height}
                        isDropdown={showMenu}
                        lineHeight={lineHeight}
                        menuLineHeight={menuLineHeight}
                        isCheckBox={isCheckBox}
                        dropDownItemAlign={dropDownItemAlign}>

                        {isSearch && (
                            <div
                                className='search_box_main'
                                style={{}}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                            >
                                <div className='text_box_wrapper'>
                                    <span>
                                        <SearchSvg size={'.9rem'} height={'.9rem'} color="#afc4e3" />
                                    </span>
                                    <input
                                        className="text_box"
                                        type="text"
                                        placeholder="search this list..."
                                        onChange={(e) => {
                                            onFilterValue(e)
                                        }}
                                    />
                                </div>
                            </div>

                        )}
                        <div className="scroll_div">
                            {isSelecctAll && (
                                <div
                                    className='select_result_sub'>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        padding: '3px 0px',
                                        fontWeight: 'normal',
                                        width: '100%',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        boxSizing: 'border-box',
                                    }}

                                        onClick={() => {
                                            onChangeFunc(undefined, ((localSelection && localSelection.length) === (activeValue && activeValue.length)) ? "unSelectAll" : "selectAll");
                                        }}

                                    >
                                        <CheckBox
                                            size={"35px"}
                                            // bColor='#337DFF'
                                            checked={((localSelection && localSelection.length) === (activeValue && activeValue.length))}
                                            onClick={() => {
                                                onChangeFunc(undefined, ((localSelection && localSelection.length) === (activeValue && activeValue.length)) ? "unSelectAll" : "selectAll");
                                            }}
                                        />
                                        All
                                    </div>
                                </div>
                            )}

                            {props.mt_selection && (
                                <div
                                    key={"option"}
                                    className='select_result_sub'
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        onChangeFunc('', valueType)
                                        setShowMenu(false);
                                    }}
                                    style={{ padding: '0px 8px' }}
                                >

                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        padding: '0px 0px',
                                        fontWeight: 'normal',
                                        // color: _is_active ? '#000' : undefined,
                                        width: '100%',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        boxSizing: 'border-box',
                                        fontSize: '.9rem'
                                    }}>
                                        None
                                    </div>
                                </div>

                            )}


                            {
                                localSelection && localSelection.length > 0 && localSelection.map((data, i) => {
                                    const isObject = typeof data === 'object';
                                    let _is_active = isMultiSelect ? (activeValue && activeValue.length > 0 && activeValue.indexOf(data && (valueKey ? data[valueKey] : data)) > -1) : (activeValue === (data && (valueKey ? data[valueKey] : data)))
                                    const this_group_item = should_we_group && (data[groupKey] || '');
                                    const __fire__group__div__ = should_we_group && this_group_item !== prev_group_item;

                                    prev_group_item = this_group_item;

                                    return (
                                        <div>
                                            {__fire__group__div__ && <div className='group_name' >
                                                {(groupIcon || groupIconFn) && <span style={{ marginRight: '1rem' }}>
                                                    {(groupIconFn && groupIconFn(this_group_item)) || (groupIcon && groupIcon)}
                                                </span>}
                                                {this_group_item}</div>}
                                            <div
                                                // style={{ backgroundColor: _is_active ? '#f0f5fc' : undefined }}
                                                key={"option" + i}
                                                className='select_result_sub'
                                                onClick={(e) => {
                                                    setActiveGroupName(this_group_item)
                                                    e.stopPropagation();
                                                    e.preventDefault();

                                                    if (!isCheckBox && onChangeFunc) {
                                                        if (typeof data === 'object' && !valueType) {
                                                            onChangeFunc(data[valueKey], valueType, data);
                                                            if (!isMultiSelect) {
                                                                setShowMenu(false);
                                                            }
                                                        }
                                                        if (typeof data === 'object' && valueType) {
                                                            onChangeFunc(data[valueKey], valueType, data);
                                                            if (!isMultiSelect) {
                                                                setShowMenu(false);
                                                            }
                                                        }
                                                        if (typeof data !== 'object') {
                                                            onChangeFunc(data);
                                                            if (!isMultiSelect) {
                                                                setShowMenu(false);
                                                            }
                                                        }
                                                    }
                                                }}

                                            >


                                                {

                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center',
                                                        padding: '0px 0  0 1rem',
                                                        fontWeight: 'normal',
                                                        // color: _is_active ? 'rgb(48 139 200)' : undefined,
                                                        width: '100%',
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                        boxSizing: 'border-box',
                                                    }}

                                                        onClick={() => {
                                                            // if(addAction){
                                                            //     addAction()
                                                            // }
                                                            // if(add_action_on_change){
                                                            //     add_action_on_change('')
                                                            // }
                                                            if (onChangeFunc) {
                                                                if (typeof data === 'object' && !valueType) {
                                                                    onChangeFunc(data[valueKey]);

                                                                    if (!isMultiSelect) {
                                                                        setShowMenu(false);
                                                                    }
                                                                }
                                                                if (typeof data === 'object' && valueType) {
                                                                    onChangeFunc(data[valueKey], valueType);
                                                                    if (!isMultiSelect) {
                                                                        setShowMenu(false);
                                                                    }
                                                                }
                                                                if (typeof data !== 'object') {
                                                                    onChangeFunc(data);
                                                                    if (!isMultiSelect) {
                                                                        setShowMenu(false);
                                                                    }
                                                                }
                                                            }
                                                        }}

                                                    >
                                                        {isCheckBox && (
                                                            <CheckBox size="30px"
                                                                checked={_is_active} onClick={() => {
                                                                    if (typeof data === 'object' && !valueType) {
                                                                        onChangeFunc(data[valueKey]);

                                                                        if (!isMultiSelect) {
                                                                            setShowMenu(false);
                                                                        }
                                                                    }
                                                                    if (typeof data === 'object' && valueType) {
                                                                        onChangeFunc(data[valueKey], valueType);
                                                                        if (!isMultiSelect) {
                                                                            setShowMenu(false);
                                                                        }
                                                                    }
                                                                    if (typeof data !== 'object') {
                                                                        onChangeFunc(data);
                                                                        if (!isMultiSelect) {
                                                                            setShowMenu(false);
                                                                        }
                                                                    }
                                                                }} />
                                                        )}

                                                        {
                                                            !isObject &&
                                                            _.startCase(_.toLower(data))
                                                        }

                                                        {
                                                            data && data.icon &&
                                                            <img alt={data.icon ? data.icon : ''} src={constants.CONTEXT_PATH + './' + data.icon} width='20' style={{ marginRight: '15px' }} />
                                                        }
                                                        {
                                                            !hideCase ? _.startCase(_.toLower(data && data[displayKey])) : data && data[displayKey]
                                                        }

                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                (localSelection === undefined || localSelection.length == 0) && (
                                    <p style={{ textTransform: 'uppercase', textAlign: 'center', fontSize: '.7rem', margin: '0px', marginBottom: '.3rem' }}>no Record Found</p>
                                )
                            }
                        </div>

                        {addAction &&
                            <React.Fragment>

                                {(!show_add_input) && (
                                    <div className="add_btn" onClick={() => addAction()} style={{ width: '100%', padding: '.7rem', boxSizing: 'border-box', marginTop: '.3rem', borderTop: '1px solid #eaeaea' }}>
                                        <AddSvg size=".8rem" height=".8rem" color="#308bc8" /> &nbsp;&nbsp;&nbsp;
                                        <span style={{ color: '#308bc8' }}>{props.addActionName ? props.addActionName : 'Add'}</span>
                                    </div>
                                )}

                                {(show_add_input) && (
                                    <div style={{ position: 'relative' }}>
                                        <input autoFocus={true} placeholder="Type Your Menu Here" style={{ height: '2.3rem', width: '100%', border: '1px solid #ccc', boxSizing: 'border-box', padding: '1rem 1.2rem', paddingRight: '3.5rem' }} type="text" value={add_action_value} onChange={(e) => {
                                            add_action_on_change(e.target.value)
                                            // onChangeFunc(e.target.value);
                                        }} />

                                        {add_action_value && add_action_value.length > 0 && (
                                            <span onClick={(e) => {
                                                e.preventDefault()
                                                e.stopPropagation()
                                                onChangeFunc(add_action_value);
                                                addAction()
                                                setShowMenu(!showMenu);
                                            }} title="Save" className="mark_icon" style={{ right: '2rem' }}>
                                                <MarkSvg size="1rem" height="1rem" color="green" />
                                            </span>
                                        )}

                                        <span title="Close" className="mark_icon" onClick={(e) => {

                                            addAction()
                                            add_action_on_change('')

                                        }}>
                                            <CloseSvg size=".8rem" height=".8rem" />
                                        </span>


                                    </div>
                                )}


                            </React.Fragment>
                        }
                        {applyFunction && (
                            <div className="apply_btn" onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                applyFunction(activeValue)
                                setShowMenu(false);
                            }}>
                                Apply
                            </div>
                        )}
                    </DropDownMenu>
                }
            </SelectBox>
        </Wrapper>
    );
};

export default SelectOption;