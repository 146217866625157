'use strict'
import React, { useState, useEffect, useRef } from 'react'
import { ChromePicker } from 'react-color'

const popover = {
    position: 'absolute',
    zIndex: 2,
    right: '0px',
    top: '105%',
}
const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
}


const ColorPickerNew = (props) => {

    const { onChange, value } = props;

    const [displayColorPicker, setDisplayColorPicker] = useState(undefined)
    const [color, setColor] = useState(undefined)
    const [legendPosition, setLegendPosition] = useState('down')

    const buttonRef = useRef(null)

    useEffect(() => {
        if (buttonRef && displayColorPicker) {
            const windowHeight = window.innerHeight;
            const elementData = buttonRef.current.getBoundingClientRect();
            const { height, y } = elementData;
            const piker_h = 300;
            const cal = piker_h + y + height;
            if (parseInt(windowHeight) < parseInt(cal)) {
                setLegendPosition('up');
            }
        }
    }, [displayColorPicker]);




    useEffect(() => {
        if (value) {
            setColor(convertHexToRGBA(value))
        }
    }, [])



    const convertHexToRGBA = (hexCode) => {

        let hex = hexCode.replace('#', '');
        let hex_array = hex.split('')
        let alpha = undefined;
        let _a_ = undefined;

        if (hex_array && hex_array.length === 8) {
            alpha = hex_array[6] + hex_array[7]
        }
        if (typeof alpha !== 'undefined') {
            const cal = (parseInt((alpha + '00'), 16) >> 8) / 255;
            _a_ = Math.round(cal * 100) / 100
        }

        if (hex.length === 3) {
            hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
        }

        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        let __rgba__ = {};
        __rgba__['r'] = r;
        __rgba__['g'] = g;
        __rgba__['b'] = b;
        __rgba__['a'] = _a_;
        return __rgba__;
    };



    const convert_rgba_to_string = (rgba) => {
        const clone_rgb = rgba ? { ...rgba } : {};
        const r = clone_rgb.r;
        const g = clone_rgb.g;
        const b = clone_rgb.b;
        const a = clone_rgb.a;
        let str = '';
        str = `rgba(${r},${g},${b},${a || 1})`

        return str;
    }

    const rgba2hex = (orig) => {

        let a = undefined;
        let isPercent = false;
        let rgb = undefined;
        let alpha = undefined;
        let hex = undefined;

        rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i);

        alpha = (rgb && rgb[4] || "").trim();
        hex = rgb ?
            (rgb[1] | 1 << 8).toString(16).slice(1) +
            (rgb[2] | 1 << 8).toString(16).slice(1) +
            (rgb[3] | 1 << 8).toString(16).slice(1) : orig;

        if (alpha !== "") {
            a = alpha;
        } else {
            a = 1;
        }
        // multiply before convert to HEX
        a = ((a * 255) | 1 << 8).toString(16).slice(1)
        hex = hex + a

        return `#${hex}`;
    }


    const convert_rgba_to_hex = (rgba) => {
        const clone_rgb = rgba ? { ...rgba } : {};
        const r = clone_rgb.r;
        const g = clone_rgb.g;
        const b = clone_rgb.b;
        const a = clone_rgb.a;
        let str = '';
        str = `rgba(${r}, ${g}, ${b}, ${a || 1})`
        let result = rgba2hex(str)
        return result
    }


    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker)
    };

    const handleClose = () => {
        setDisplayColorPicker(undefined)
    };


    return (

        <div style={{ position: 'relative' }}>
            <button
                ref={buttonRef}
                style={{
                    width: `100px`,
                    height: '20px',
                    backgroundColor: color ? convert_rgba_to_hex(color) : 'transparent',
                    border: '2px solid #000',
                }}
                onClick={handleClick}>{color ? convert_rgba_to_hex(color) : null}</button>
                
            {displayColorPicker ? <div style={{
                position: 'absolute',
                bottom: legendPosition && legendPosition === 'up' ? '101%' : undefined,
                top: legendPosition && legendPosition === 'down' ? '101%' : undefined,
                zIndex: 2,
                right: '10px'
            }}>
                <div style={cover} onClick={handleClose} />
                <ChromePicker
                    color={color}
                    onChange={(color) => {
                        setColor(color.rgb)
                    }}
                    onChangeComplete={(color) => {
                        onChange(convert_rgba_to_hex(color.rgb))
                    }}
                />
            </div> : null}
        </div>
    );
}

export default ColorPickerNew;
