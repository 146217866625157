import React from 'react';

const Expand = (props) => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg"
        width={props.size ? props.size : '2.2rem'}
        height={props.height ? props.height : '2.2rem'}
        fill={props.color ? props.color : '#EAEAEA'}
        viewBox="0 0 512 512"
        // fill="#EAEAEA"
        {...props}
        space="preserve">

        
        <path fill={!props.disable ? props.color : "#63B6D6"}  
            d="M212.686 315.314L120 408l32.922 31.029c15.12 15.12 4.412 40.971-16.97 40.971h-112C10.697 480 0 469.255 0 456V344c0-21.382 25.803-32.09 40.922-16.971L72 360l92.686-92.686c6.248-6.248 16.379-6.248 22.627 0l25.373 25.373c6.249 6.248 6.249 16.378 0 22.627zm22.628-118.628L328 104l-32.922-31.029C279.958 57.851 290.666 32 312.048 32h112C437.303 32 448 42.745 448 56v112c0 21.382-25.803 32.09-40.922 16.971L376 152l-92.686 92.686c-6.248 6.248-16.379 6.248-22.627 0l-25.373-25.373c-6.249-6.248-6.249-16.378 0-22.627z">
            </path>
        </svg>


    )

}


export default Expand;