

export const CHART_ALIASES = {
    "line_chart": ["li", "line", "line chart", "Trending", "trending chart"],
    "bar_chart": ["bar", "bar chart"],
    "area_chart": ["area", "area chart", "area start"],
    "stacked_bar_chart": ["stacked", "stacked bar chart", "stack bar chart", "stack"],
    "tree_map": ["tree", "three map chart", "3 map chart", "tree map chart", "three map", "train map chart", 'treemap', 'treemap chart', 'free map'],
    "pie_chart": ["pie", "pieeee", "peee", "pieee"],
    "radar_chart": ["radar", "radarrr"],
    "gauge_chart": ["gauge"],
    "donut_chart": ["donut", 'doughnut', "doughnut chart", "doug chart"],
    "scatter_chart": ["scatter"],
    "waterfall_chart": ["waterfall", "waterfall chart"],
    "horizontal_bar_chart": ["horizontal", "horizontal bar chart", "horizontal bar"],
    "ogive_chart": ["ogive", "ogev", "ogiv",]
};



export const DOWNLOAD_ALIASES = {
    "csv": ["csv", "download csv", "donwload csv", "export csv"],
    "excel": ["excel", "xlsx", "xls", 'download excel', 'excl', 'pixel', "export excel", "print excel", "Download Excellence"],
    "pdf": ["pdf", "portable document format", "download pdf", "this", "export pdf", "print", "print pdf", "Download"],
    // Add more download types and their aliases as needed
};
