import React, { memo } from "react";
import "gantt-task-react/dist/index.css";
import { ViewMode } from "gantt-task-react";

export const ViewSwitcher = memo(({
  onViewModeChange,
  view,
}) => {
  return (
    <div>
      {ViewMode &&
        Object.values(ViewMode).slice(3).map((i) => {
          return (
            <button
              key={i}
              style={{
                background: view === i ? "#3a9bd0" : '#e2e2e2',
                color: view === i ? "#fff" : '#222',
                border: view === i ? '1px solid #3a9bd0' : '1px solid #ccc',
                height: '30px',
                width: '73px',
                fontSize: '.9rem',
                marginRight: '8px',
                cursor: 'pointer'
              }}
              onClick={() => onViewModeChange(i)}
            >
              {i}
            </button>
          );
        })}
    </div>
  );
});