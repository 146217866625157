import React, { useState, useEffect } from 'react';
import { FormattingWrapper } from './formatting.styled';
import { CheckBox } from '../hoc/checkbox';
import RippleEffect from '../hoc/ripple-effect';
import DeleteSvg from '../svg/delete';
import AddSvg from '../svg/add';
import { connect } from 'react-redux';

import { save_tbl_format_setting } from '../../actions/table_format';

import { Button } from '../hoc/button'
import { constants } from '../../utils/constants'
import { change_column_order_v1 } from '../editor.shared/shared.pivot';


const { CONDITIONS } = constants;



const TableFormating = (props) => {

    const { close, popupKey, option, data, save_tbl_format_setting, table_format_setting_cache } = props;
    const { columns, id, report_item_id, sortedData } = data;

    // here we have formate

    const tbl_formatter = table_format_setting_cache && table_format_setting_cache[id];

    const [tableData, setTableData] = useState(undefined)

    const add_plain_object = () => {
        return {
            column_name: undefined,
            condition: undefined,
            value: undefined,
            cell_color: undefined,
            apply_to_row: undefined,
            apply_to_chart: undefined,
            font_size: undefined,
            font_color: undefined,
            field_order: 0
        }
    }

    useEffect(() => {
        if (tbl_formatter && tbl_formatter.length > 0) {
            const sorted_data = tbl_formatter.sort((a, b) => a.field_order - b.field_order)
            sorted_data.map((item, i) => item.index = i)
            setTableData(sorted_data)
        } else {
            setTableData([add_plain_object()])
        }

    }, [tbl_formatter])


    const _add_new_rule = () => {
        const temp_rule = tableData ? [...tableData] : [];
        temp_rule.push({
            field_order: tableData.length,

        })
        temp_rule.map((item, i) => item.index = i)
        setTableData(temp_rule)
    }


    const _onChange = (index, key, event, v) => {
        let value = event ? event.target.value : v;
        let temp_rule = tableData ? [...tableData] : [];
        let temp_obj = temp_rule[index] || {};
        temp_obj[key] = value;
        temp_rule[index] = temp_obj
        setTableData(temp_rule)
    }

    const delete_row = (index) => {
        const temp_rule = tableData ? [...tableData] : [];
        temp_rule.splice(index, 1)
        console.log("temp_rule", temp_rule)
        setTableData(temp_rule)
    }


    const _save = () => {

        const clone_data_to_save = tableData ? JSON.parse(JSON.stringify(tableData)) : [];
        clone_data_to_save.map((c, i) => {
            c.field_order = i;
        })

        console.log("clone_data_to_save", clone_data_to_save)
        save_tbl_format_setting(clone_data_to_save, id, report_item_id)
        close(option, popupKey)
    }


    const _get_dist_values = (column_name) => {

        const __dist__values__ = [];
        
        if (sortedData && sortedData.length > 0 && column_name) {
            for (let index = 0; index < sortedData.length; index++) {
                const element = sortedData[index];
                const __v__ = element[column_name];
                // if (__dist__values__.indexOf(__v__) === -1) {
                //     __dist__values__.push(__v__);
                // }
                if (__dist__values__.indexOf(__v__) === -1 && __v__ !== '' && typeof __v__ !== "undefined") {
                    __dist__values__.push(__v__);
                }
            }
        }
        return __dist__values__;
    }


    const sorted_condition_data = tableData?.length > 0 ? (tableData?.sort((a, b) => a.field_order - b.field_order) || []) : [];

    const change_condition_order = (event) => {

        const final_form_data = change_column_order_v1(sorted_condition_data, event, 'field_order')
        setTableData(final_form_data)

        // props.save_columns_info(final_form_data, (report_item_id ? report_item_id : id), id)
    }


    console.log("polusCommand1", props.polusCommand)
    return (
        <FormattingWrapper>
            <div className="table_wrapper">
                <table>
                    <thead>
                        <tr>
                            <td>Column Name</td>
                            <td>Condition</td>
                            <td>Value</td>
                            <td>Cell Color</td>
                            <td>Font Size(px)</td>
                            <td>Font Color</td>
                            <td>Apply to Row</td>
                            <td>Apply to Chart</td>
                            <td>Actions</td>
                        </tr>
                    </thead>

                    <tbody>
                        {tableData && tableData.length > 0 && tableData.sort((a, b) => a.field_order - b.field_order).map((_row, __i) => {
                            const i = _row?.index > -1 ? _row.index : __i;
                            const __distance_values__ = _row?.column_name && _get_dist_values(_row.column_name)
                            return (
                                <tr key={"row" + i}
                                    draggable={true}
                                    column_index={_row?.['index']}
                                    onDragStart={(event) => {
                                        event.dataTransfer.setData("column_info_index", _row?.['index']);
                                        event.dataTransfer.effectAllowed = "move"
                                    }}
                                    onDragOver={(event) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                    }}
                                    onDrop={(event) => {
                                        change_condition_order(event)
                                    }}
                                >
                                    <td>
                                        <select
                                            value={_row.column_name ? _row.column_name : '--select--'}
                                            onChange={(e) => {
                                                _onChange(i, 'column_name', e)
                                            }}>
                                            <option value="">{`--select--`}</option>

                                            {columns && columns.length > 0 && columns.map((c, j) => {
                                                return (
                                                    <option key={"col" + j} value={c}>{
                                                        c && c.split("_nfx_").join(" ")
                                                    }</option>
                                                )
                                            })}
                                        </select>
                                    </td>

                                    <td>
                                        <select
                                            value={_row.condition ? _row.condition : '--select--'}
                                            onChange={(e) => {
                                                _onChange(i, 'condition', e)
                                            }}>
                                            <option value="">{`--select--`}</option>
                                            {CONDITIONS && Object.keys(CONDITIONS).map((key) => {
                                                if (key !== 'ELSE') {
                                                    return (
                                                        <option value={CONDITIONS[key].VALUE_KEY}>{CONDITIONS[key].DISPLAY_KEY}</option>
                                                    )
                                                }
                                            })}
                                        </select>
                                    </td>
                                    <td>

                                        {_row.condition && _row.condition === CONDITIONS.EQUAL_TO.VALUE_KEY ? (
                                            <select value={_row.value ? _row.value : '--select--'} onChange={(e) => {
                                                _onChange(i, 'value', e)
                                            }}>
                                                <option value={'--select--'}>--select--</option>
                                                {__distance_values__?.length > 0 && __distance_values__.map((op) => {
                                                    return (
                                                        <option value={op}>{op}</option>
                                                    )
                                                })}
                                            </select>
                                        )
                                            : <>

                                                <input
                                                    type="text"
                                                    value={_row.value ? _row.value : ''}
                                                    style={{ width: _row.condition === CONDITIONS.BETWEEN.VALUE_KEY ? '45%' : undefined, marginRight: _row.condition === CONDITIONS.BETWEEN.VALUE_KEY ? '5%' : undefined }}
                                                    placeholder={_row.condition === CONDITIONS.BETWEEN.VALUE_KEY ? "From" : ''}
                                                    onChange={(e) => _onChange(i, 'value', e)}
                                                />
                                                {_row.condition === CONDITIONS.BETWEEN.VALUE_KEY && (
                                                    <input
                                                        style={{ marginTop: '.2rem', width: '45%' }}
                                                        value={_row?.value2 ? _row.value2 : ''}
                                                        placeholder='To'
                                                        type="text" onChange={(e) => {
                                                            _onChange(i, 'value2', e)
                                                        }} />
                                                )}
                                            </>}

                                    </td>
                                    <td style={{ textAlign: 'center' }}> <input type="color" value={_row.cell_color ? _row.cell_color : ''} onChange={(e) => _onChange(i, 'cell_color', e)} /></td>

                                    <td> <input type="number" min="10" max="30" value={_row.font_size ? _row.font_size : ''} onChange={(e) => _onChange(i, 'font_size', e)} /> </td>
                                    <td style={{ textAlign: 'center' }}><input type="color" value={_row.font_color ? _row.font_color : ''} onChange={(e) => _onChange(i, 'font_color', e)} /> </td>
                                    <td style={{ textAlign: 'center' }}>
                                        <CheckBox
                                            checked={_row.apply_to_row ? _row.apply_to_row : ''}
                                            onClick={() => {
                                                _onChange(i, 'apply_to_row', undefined, (_row.apply_to_row ? false : true))
                                            }}
                                        />
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        <CheckBox
                                            checked={_row.apply_to_chart ? _row.apply_to_chart : ''}
                                            onClick={() => {
                                                _onChange(i, 'apply_to_chart', undefined, (_row.apply_to_chart ? false : true))
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <div>
                                            <RippleEffect
                                                title="delete"
                                                width="30"
                                                icon={<DeleteSvg size=".8rem" height=".8rem" />}
                                                Click={() => {
                                                    delete_row(i)
                                                }}
                                            />

                                        </div>

                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            <div class="add_btn">
                <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => {
                    _add_new_rule()
                }}>
                    <AddSvg size=".9rem" height=".9rem" /> &nbsp; Add New Rule
                </div>
            </div>


            <div
                style={{
                    padding: '1rem .5rem',
                    paddingBottom: '0px',
                    // borderTop: '1px solid #ccc',
                    marginTop: 'auto',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    boxSizing: 'border-box',
                }}>

                <Button
                    primary={true}
                    // mR="1.143rem"
                    onClick={() => {
                        _save()
                    }}
                >Save</Button>

                {/* <Button
                    bgColor="#9caeb5"
                    onClick={() => {
                        close(option, popupKey)
                    }}
                >
                    Cancel
                </Button> */}
            </div>

        </FormattingWrapper>
    )
}

const mapStateToProps = (state) => ({
    table_format_setting_cache: state.tableFormatReducer.table_format_setting_cache,
     polusCommand : state.InterpretReducer?.polusCommand

})


export default connect(mapStateToProps, { save_tbl_format_setting })(TableFormating)
