import styled from 'styled-components';

export const Wrapper = styled.div`
    /* height: 70vh; */
    box-sizing: border-box;
    overflow-x: auto;
    overflow-y: auto;
    /* width: 93vw; */

    fieldset {
        
        width: 50%;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px 15px;

        legend {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 20px;
        }
    }

    table {
        border: 1px solid #c1c1c2;
        border-collapse: collapse;
        width: 100%;

        tr, td, th {
            border: 1px solid #c1c1c2;
            padding: 5px 10px;
            font-size: 0.8rem;
        }

        th {
            white-space: nowrap;
            font-size: 0.8rem;
            border-collapse: collapse;
            text-align: center;
            vertical-align: middle;
        }
    }
    &::-webkit-scrollbar {
        background-color:#fff;
        width:16px
    }
        &::-webkit-scrollbar-track {
        background-color:#fff
    }
    &::-webkit-scrollbar-track:hover {
        background-color:#f4f4f4
    }
    
       &::-webkit-scrollbar-thumb {
        background-color:#babac0;
        border-radius:16px;
        border:5px solid #fff
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color:#a0a0a5;
        border:4px solid #f4f4f4
    }
    
    /* &::-webkit-scrollbar-button {display:none} */
`;  

export const AnswerWrapper = styled.div`
    font-size: ${props => props.isDiscovery ? '1.5rem':  '2.5rem'};
    font-weight: bold;
    padding: 0 1rem;
    text-align: center;
    padding-bottom: 1rem;
`;


export const DivRow = styled.div`
    flex:${props => props.w ? undefined: '1'};
    margin-bottom: 15px;
    width: ${props => props.w || '100%'};
    display: ${props => props.w ? 'inline-block' : 'block'};
`;
