import React from 'react';

const Format = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1"

            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}

            fill={props.color ? props.color : '#000'}
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            space="preserve"

        >

            <g>
                <g id="svg_1">
                    <g id="svg_2">
                        <g id="svg_3">
                            <path id="svg_4" d="m124,128l-120,0c-2.209,0 -4,-1.791 -4,-4l0,-120c0,-2.209 1.791,-4 4,-4l120,0c2.209,0 4,1.791 4,4l0,120c0,2.209 -1.791,4 -4,4zm-116,-8l112,0l0,-112l-112,0l0,112z" />
                        </g>
                        <g id="svg_5">
                            <path id="svg_6" d="m294,128l-120,0c-2.209,0 -4,-1.791 -4,-4l0,-120c0,-2.209 1.791,-4 4,-4l120,0c2.209,0 4,1.791 4,4l0,120c0,2.209 -1.791,4 -4,4zm-116,-8l112,0l0,-112l-112,0l0,112z" />
                        </g>
                        <g id="svg_7">
                            <path id="svg_8" d="m466,128l-122,0c-2.209,0 -4,-1.791 -4,-4l0,-120c0,-2.209 1.791,-4 4,-4l122,0c2.209,0 4,1.791 4,4l0,120c0,2.209 -1.791,4 -4,4zm-118,-8l114,0l0,-112l-114,0l0,112z" />
                        </g>
                        <g id="svg_9">
                            <path id="svg_10" d="m466,298l-122,0c-2.209,0 -4,-1.791 -4,-4l0,-120c0,-2.209 1.791,-4 4,-4l122,0c2.209,0 4,1.791 4,4l0,120c0,2.209 -1.791,4 -4,4zm-118,-8l114,0l0,-112l-114,0l0,112z" />
                        </g>
                        <g id="svg_11">
                            <path id="svg_12" d="m466,468l-122,0c-2.209,0 -4,-1.791 -4,-4l0,-120c0,-2.209 1.791,-4 4,-4l122,0c2.209,0 4,1.791 4,4l0,120c0,2.209 -1.791,4 -4,4zm-118,-8l114,0l0,-112l-114,0l0,112z" />
                        </g>
                        <g id="svg_13">
                            <path id="svg_14" d="m294,468l-290,0c-2.209,0 -4,-1.791 -4,-4l0,-290c0,-2.209 1.791,-4 4,-4l290,0c2.209,0 4,1.791 4,4l0,290c0,2.209 -1.791,4 -4,4zm-286,-8l282,0l0,-282l-282,0l0,282z" />
                        </g>
                    </g>
                </g>
                <rect id="svg_15" height="128" width="128" y="170" x="340" stroke="#000" fill="#ff7f00" />
                <rect id="svg_16" height="0" width="1" y="325" x="507" stroke="#000" fill="#ff7f00" />
            </g>
        </svg>

    )

}


export default Format;