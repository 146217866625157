import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import * as _ from "lodash";
import { calculatePositionForSuggestionBox } from "../../utils/calculatePositionAtCursor";

const MainDiv = styled.div`
  width: 100%;
  margin-top: ${(props) => (props.hide_margin ? "0px" : "15px")};
  position: relative;
  .none_user_input {
    opacity: 0;
    width: 0px;
    height: 0px;
  }
`;

const UserInput = styled.div`
  width: 100%;
  height: ${(props) => props.height || "2.4rem"};
  background: ${(props) => (props.background ? props.background : "#fff")};
  /* background-color: #F0F3FF; */
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 6px;

  input {
    width: 100%;
    height: 100%;
    border: 0px;
    border-radius: 2px;

    /* border: ${(props) =>
        props.hide_border
            ? "0px"
            : props?.focused
                ? "2px solid #007FFF"
                : "1px solid #0000003b"}; */
    border-radius: 6px;
    padding: 0px 10px;
    box-sizing: border-box;
    font-size: 0.9rem;
    font-weight: normal;
    color: rgb(55, 74, 102);
    background-color: transparent;
    z-index: 2;
    box-shadow: ${(props) =>
        props.enable_shadow ? "5px 2px 2px 2px #eaeaea" : undefined};
    /* border: 1px solid #afc4e3;  */
    border: 1px solid #e2e2e2; 
    &:hover {
      /* border: 1px solid #afc4e3; */
    }
    &:focus {
      outline: none;
      /* border: ${(props) =>
        props?.focused ? "2px solid #007FFF" : "1px solid #0000003b"}; */
    }
  }

  .value_txt {
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.9rem;
    font-weight: normal;
    color: rgb(55, 74, 102);
  }
  .label_pre {
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.9rem;
    color: #646464;
    font-weight: 300;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    transition: all 0.2s;
    position: absolute;
    left: 10px;
    background-color: ${(props) =>
        props.background ? props.background : "#fff"};
    z-index: 1;
    ${(props) => (props.labelStyle ? { ...props.labelStyle } : undefined)}
  }

  .label_post {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    font-size: 0.9rem;
    transform: translate(-11%, -100%) scale(0.8);
    background-color: ${(props) =>
        props.background ? props.background : "#fff"};
    padding: 0px 7px;
    z-index: 3;
    ${(props) => (props.labelStyle ? { ...props.labelStyle } : undefined)}

    &::before {
      content: "";
      height: 14px;
      width: 100%;
      position: absolute;
      background: ${(props) =>
        props.labelBgColor ? props.labelBgColor : props.background};
      ${(props) => (props.labelStyle ? { ...props.labelStyle } : undefined)}

      top: 0px;
      left: 0px;
      z-index: -1;
    }
  }

  .select_arrow {
    position: absolute;
    right: 0px;
    top: 0px;
    bottom: 0px;
    height: 100%;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: ${(props) => (props.is_open ? "rotate(180deg)" : undefined)};
  }
`;

const SuggestionList = styled.ul`
/* .suggestions-list { */
    position: absolute;
    top: ${props => (props?.position?.top + 10) + 'px'};
    left:  ${props => props?.position?.left + 'px'};
    background: #fff;
    /* border: 1px solid #ddd; */
    box-sizing: border-box;
    border: 1px solid #e7e7e7;
    list-style-type: none;
    padding: 0;
    margin: 0;
    z-index: 500;
    max-height: ${props => props.height || '200px'};
    overflow-y: auto;
    font-size: 0.8rem;
    border-radius: 6px;
    &::-webkit-scrollbar {
        background-color: #3F3F3F;
        width:4px;
    }

    &::-webkit-scrollbar-track {
        background-color: #F3F3F3;
        border: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dbdbdb;
        border-radius: 10px;
        height: 4px;
    }
    &::-webkit-scrollbar:horizontal{
        background-color:  #3F3F3F;
        height: 4px;
    }

    li{
        width: 190px;
        padding: 8px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
            background: #F3F3F3;
        }
    }
    .selected {
    background-color: #F3F3F3;
    }
    
`;

const InputBoxSuggestion = (props, ref) => {
    const {
        value,
        defultValue,
        type,
        label,
        onChange,
        onFocus,
        onBlur,
        background,
        labelStyle,
        hide_case,
        autoFocus,
        hide_margin,
        hide_border,
        height,
        id,
        inputStyle,
        labelBgColor,
        hint_list,
        valueHints,
        prefix,
        display_value_key = 'display_value',
        value_key = 'value'
    } = props;
    const hints = [
        "revenues",
        "sumw",
        "rendomb",
        "revenuee",
        "sumv",
        "rendomg",
        "revenue",
        "sum",
        "rendom",
        "revenuesr",
        "sumwr",
        "rendombr",
        "revenueer",
        "sumvr",
        "rendomgr",
        "revenuer",
        "sumr",
        "rendomr",
    ];
    // const regexForValueSuggestion = /\b(?:sum|count|max|avg|abs)\(\s*[^\)]*\s*\)?\s*/gi;
    const regexForValueSuggestion = /^(?:\s*(sum|count|max|avg|abs)\(\s*|\[\w+\]|\d+|".*?"|[-+*/()])*\s*$/;

    const formulaHints = [
        {
            value: "sum()",
            display_value: "sum"
        },
        {
            value: "count()",
            display_value: "count"
        },
        {
            value: "max()",
            display_value: "max"
        },
        {
            value: "avg()",
            display_value: "avg"
        },
        {
            value: "abs()",
            display_value: "abs"
        },
        {
            value: "min()",
            display_value: "min"
        },
        {
            value: "neg()",
            display_value: "neg"
        }
    ];

    const valueHintsData = valueHints?.[0] && Object.keys(valueHints?.[0]).map(i => ({ [value_key]: i, [display_value_key]: i }));


    const [focused, set_focused] = useState(undefined);
    const is_input_have_value =
        value && (value.length > 0 || defultValue?.length > 0);
    const lable_class =
        "label_pre" + " " + (focused || is_input_have_value ? "label_post" : "dd");

    const is_label_post = focused || is_input_have_value;
    const label_style_temp = is_label_post
        ? props.lablePostStyle || {}
        : props.lablePreStyle || {};
    const label_style = { ...(labelStyle || {}), ...label_style_temp };



    //
    const inputRef = useRef(null);
    const wrapperRef = useRef();
    const suggestionBoxRef = useRef(null);
    const [localQuestion, setLocalQuestion] = useState(value);
    const [hintList, setHintsList] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [suggestionBoxPosition, setSuggestionBoxPosition] = useState({
        top: 60,
        left: 0,
    });
    const [currCursorPosition, setCurrCursorPosition] = useState(undefined);

    useEffect(() => {
        if (selectedIndex >= 0 && suggestionBoxRef.current) {
            const selectedItem = suggestionBoxRef.current.children[selectedIndex];
            if (selectedItem) {
                const boxTop = suggestionBoxRef.current.scrollTop;
                const boxBottom = boxTop + suggestionBoxRef.current.clientHeight;
                const itemTop = selectedItem.offsetTop;
                const itemBottom = itemTop + selectedItem.clientHeight;
                if (itemBottom > boxBottom) {
                    suggestionBoxRef.current.scrollTop = itemBottom - suggestionBoxRef.current.clientHeight;
                } else if (itemTop < boxTop) {
                    suggestionBoxRef.current.scrollTop = itemTop;
                }
            }
        }
    }, [selectedIndex]);

    const is_value_container_hint = (hint) => {
        if (!valueHintsData || valueHintsData?.length === 0 || !hint) return false;
        else {
            return valueHintsData?.findIndex(el => el?.[display_value_key] === hint) > -1;
        };
    };


    const correctSquareBrackets = (text) => {
        let correctedText = '';
        let openBracketsCount = 0;

        for (const char of text) {
            if (char === '[') {
                // Add an opening bracket and increase the count
                correctedText += char;
                openBracketsCount++;
            } else if (char === ']') {
                // Only add a closing bracket if there's an unmatched opening bracket
                if (openBracketsCount > 0) {
                    correctedText += char;
                    openBracketsCount--;
                }
            } else {
                // For all other characters, just add them to the result
                correctedText += char;
            };
        };
        // Close any remaining unmatched opening brackets
        correctedText += ']'.repeat(openBracketsCount);
        return correctedText;
    }

    const handleSuggestionClick = (word) => {
        let wordToConcate = word?.[value_key];
        var cursorPosition = inputRef?.current?.selectionStart;
        var string = inputRef?.current?.value;
        const substringBeforeCursor = string.slice(0, currCursorPosition?.end || cursorPosition).trim();
        const tokens = substringBeforeCursor.split(/\s+|,|\(|\)/).filter(token => token);
        const lastToken = tokens[tokens.length - 1] || '';
        const lastTokenIndex = substringBeforeCursor.lastIndexOf(lastToken);
        const is_value_container_value = is_value_container_hint(wordToConcate)
        wordToConcate = is_value_container_value ? ("[" + wordToConcate + "]") : wordToConcate;

        const newSubstringBeforeCursor = substringBeforeCursor.slice(0, currCursorPosition?.start || lastTokenIndex) + wordToConcate + substringBeforeCursor.slice((currCursorPosition?.end || lastTokenIndex) + lastToken?.length);

        !currCursorPosition && setCurrCursorPosition({ start: lastTokenIndex, end: lastTokenIndex + wordToConcate?.length });

        const newText = newSubstringBeforeCursor + string.slice(cursorPosition);

        //correcting () and [];
        // let correctedText = newText?.replace(/\[\[|\]\]/g, match => match === '[[' ? '[' : ']');
        // correctedText = correctedText?.replace(/\[?(\w+)\]?/g, '[$1]');
        let correctedText = correctSquareBrackets(newText)

        const newCursorPosition = newSubstringBeforeCursor?.length;

        setLocalQuestion(correctedText);
        onChange(null, correctedText);

        if (inputRef?.current) {
            setTimeout(() => {
                if (!inputRef?.current?.value?.slice(cursorPosition)?.trim()) {
                    inputRef.current.scrollLeft = Math.max(
                        0,
                        inputRef.current.scrollWidth - inputRef.current.clientWidth
                    );
                } else {
                    inputRef.current.setSelectionRange(
                        newCursorPosition,
                        newCursorPosition
                    );
                }
            }, 0);
        }
    };

    const getLastWordBeforeCursor = (text, cursorPosition) => {
        const substringBeforeCursor = text?.slice(0, cursorPosition)?.trim();
        const tokens = substringBeforeCursor?.split(/\s+|,|\(|\)|\[|\]/)?.filter(token => token);
        return tokens?.[tokens?.length - 1] || '';
    };

    const handleChangeText = (e) => {
        const value = e.target.value;
        const startPos = e.target.selectionStart;
        currCursorPosition && setCurrCursorPosition(undefined);
        selectedIndex !== -1 && setSelectedIndex(-1);

        const currentWord = getLastWordBeforeCursor(value, startPos)

        let hintdata = [...formulaHints, ...valueHintsData]

        console.log("hintdata", hintdata)

        if (hintdata?.length > 0) {
            const result = currentWord
                ? hintdata?.filter((el) =>
                    el?.[display_value_key]?.toLocaleLowerCase().startsWith(currentWord?.toLocaleLowerCase())
                )?.sort((a, b) =>
                    a?.[display_value_key]?.localeCompare(b?.[display_value_key], 'en', { numeric: true, sensitivity: 'accent' })
                )
                : [];
            setHintsList([...result]);
            const coordinates = calculatePositionForSuggestionBox(
                startPos,
                inputRef,
                wrapperRef,
                "nowrap"
            );
            setSuggestionBoxPosition(coordinates);
        }

    };

    const handleKeyDown = (e) => {
        e.stopPropagation();
        if (hintList?.length === 0) return;
        if (e.key === "ArrowDown") {
            e.preventDefault();
            setSelectedIndex((prevIndex) =>
                prevIndex < hintList.length - 1 ? prevIndex + 1 : 0
            );
            handleSuggestionClick(
                hintList[selectedIndex < hintList?.length - 1 ? selectedIndex + 1 : 0]
            );
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            setSelectedIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : hintList.length - 1
            );
            handleSuggestionClick(
                hintList[selectedIndex > 1 ? selectedIndex - 1 : hintList.length - 1]
            );
        } else if (e.key === "Enter") {
        }
    };

    return (
        <MainDiv
            height={height}
            hide_border={hide_border}
            hide_margin={hide_margin}
            ref={wrapperRef}
        >
            <UserInput
                hide_border={hide_border}
                background={background}
                labelBgColor={labelBgColor}
                height={height}
                id={id}
                focused={focused}
                onBlur={() => {
                    set_focused(false);
                    setHintsList([]);
                }}
                labelStyle={label_style}
                style={inputStyle}
            >
                {props.label && !props?.show_as_placholder && (
                    <span
                        className={lable_class}
                    >
                        {!hide_case ? _.startCase(_.toLower(label)) : label}
                    </span>
                )}

                <input
                    placeholder={
                        props?.show_as_placholder &&
                        (!hide_case ? _.startCase(_.toLower(label)) : label)
                    }
                    autoFocus={autoFocus}
                    ref={inputRef}
                    value={localQuestion}
                    // value={value}
                    // defultValue={defultValue}
                    onFocus={(e) => {
                        if (onFocus) onFocus(e);
                        set_focused(true);
                        setHintsList([]);
                    }}
                    onBlur={(e) => {
                        if (onBlur) onBlur(e);
                        set_focused(false);
                    }}
                    onChange={(e) => {
                        onChange(e, e.target?.value);
                        handleChangeText(e);
                        setLocalQuestion(e.target?.value);
                        currCursorPosition && setCurrCursorPosition(undefined);
                    }}
                    onKeyDown={(e) => {
                        if (e && props.onKeyDown) {
                            props.onKeyDown(e);
                        }
                        handleKeyDown(e);
                    }}
                    type={type || "text"}
                    style={inputStyle}
                />
            </UserInput>
            {hintList && hintList?.length > 0 && (
                <SuggestionList ref={suggestionBoxRef} position={suggestionBoxPosition} height={height}>
                    {hintList?.map((item, index) => (
                        <li key={'_lst_suggest_' + index} className={index === selectedIndex ? 'selected' : ''} onClick={() => handleSuggestionClick(item, index)}>
                            {prefix}   {/* can be icon or some text   */}
                            {item?.[display_value_key]}
                        </li>
                    ))}
                </SuggestionList>
            )}
        </MainDiv>
    );
};
export default InputBoxSuggestion;
