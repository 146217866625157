import styled from 'styled-components';
import { themes } from '../../theme/theme.config'
import { getSelectedTheme } from '../../utils';
const selected = getSelectedTheme();

const { selectBoxConfig } = themes[selected];


export const SelectLable = styled.div`
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: #000;
    margin-bottom: 5px;
    padding-left: 2px;
`;

export const SelectBox = styled.div`
    width: ${props => props.width || '160px'};
    height: ${props => props.height || '2.4rem'};
    padding:${props => props.padding || ' 0 0 0 15px'};
    padding-right: ${props => props.paddingRight || undefined};
    position: relative;
    border: ${props => props.border || '1px solid #d9dcde'};
    white-space: nowrap;
    line-height: 2.2rem;
    font-size: .9rem;
    font-weight: 500;
    color: ${props => props.color || '#071c26'};
    text-decoration: none;
    border-radius: 4px;
    background-clip: padding-box;
    user-select: none;
    background-color: #fff;
    z-index: 2;
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
    box-shadow: ${props => props.isDropdown ? "0 4px 8px 0 rgba(0,0,0,.15)" : undefined};
    box-sizing: border-box;

   

    .select_choose{
        margin-right: 26px;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 1rem;
        font-weight: normal;
        color: ${props => props.color || '#071c26'};
        line-height: ${props => props.lineHeight || '2.2rem'};

    }

    .select_arrow{
        position: absolute;
        right: 10px;
        top: 0;
        height: 100%;
        color: ${props => props.color || '#334a53'};
        font-size: 1.2rem;
        text-align: center;
        line-height: ${props => props.lineHeight || '2.2rem'};
        transition: all 0.3s;
        transform: ${props => props.isDropdown ? 'rotate(-180deg)' : undefined};

        svg{
            fill: ${props => props.color || '#334a53'};
        }

    }


`;



export const DropDownMenu = styled.div`
    width: 100%;
    max-height: ${props => props.dropDownHeight || '300px'};
    background: #fff;
    position: absolute;
    top: 100%;
    left: 0px;
    right: 0px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.15);    
    border: 1px solid #eff3f6;
    overflow-y: auto;
    z-index: 10;
    padding-top: 5px;
    &::-webkit-scrollbar {
            background-color:red;
            width:5px
        }

        &::-webkit-scrollbar-track {
            background-color:#b1cad5;
        }

        &::-webkit-scrollbar-thumb {
            background-color:#76919d;
            border-radius:0px;
            height: 5px;
        }
        &::-webkit-scrollbar:horizontal{
            background-color: green;
            height: 5px;
        }


        

    .select_result{
        padding: 3px 11px 5px 10px;
        text-transform: uppercase;
        letter-spacing: .06em;
        font-size: .8rem;
        color: #071c26;
        font-weight: 600;
    }

    .select_result_sub{
        font-size: .9rem;
        font-weight: 600;
        color: #727c80;
        padding: 0px 1rem;
        /* padding-left: 1.6rem; */
        white-space: nowrap;
        line-height: ${props => props.menuLineHeight || '2.2rem'};
        cursor: pointer;
        margin-right: 0px;

        &:hover{
            color: #000;
        }
    }

`;

