import React from 'react';

const TreeMapSvg = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            fill={props.color ? props.color : '#000'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1728 1728"
            space="preserve">
            <path transform="translate(321,553)" d="m0 0 908 1h294l20 1 4 2v490l-1 74-2 95-1 108-4 2h-189l-253 1h-774l-3-1-1-2-1-12-1-554v-84l1-93 1-26z" fill="#649FFC" />
            <path transform="translate(92,120)" d="m0 0h113l3 2v85l-1 1281h1331l4 2 1 112-3 3-3 1h-285l-1061 1h-97l-6-2-1-2-1-18v-1424l1-37 2-3z" fill="#2A76FE" />
            <path transform="translate(321,553)" d="m0 0 353 1 175 1 1 301 2 69 1 135v33l-177-1-351-3-1-73-1-6-1-55-1-171z" fill="#1A5EC4" />
            <path transform="translate(674,554)" d="m0 0h849l20 1 4 2v312l-5-1h-314l-144-1h-228l-6-1-1-10v-301h-175z" fill="#44A4B0" />
            <path transform="translate(851,865)" d="m0 0 5 1h236l136 1h318l1 2v178l-1 74-1 22-629 3h-50l-9-1-2-4-3-81-1-135-1-33v-26z" fill="#6DF1FD" />
            <path transform="translate(1543,1142)" d="m0 0 2 1v18l-1 55-1 108-4 2h-676l-1-2-3-100-1-48-1-30 1-1h61l288-2z" fill="#2A76FE" />
            <path transform="translate(321,553)" d="m0 0 2 1v318l10-1 130-1h332l34 1 19 1 1-6h1l2 59 1 135v33l-177-1-351-3-1-73-1-6-1-55-1-171z" fill="#2A76FE" />
            <path transform="translate(1702)" d="m0 0h4v1675l1 52-5 1-2-2-11-1h-121l-50-1v-1l128-1h55l-1-23-1-77v-1421l1-141 1-53z" fill="#2C519D" />
            <path transform="translate(212,1723)" d="m0 0h1227l-4 2-44 1-1 2h-30-2-1128l1-2-18-1z" fill="#7295C0" />
            <path transform="translate(320,554)" d="m0 0h1l1 230 1 171 1 55v186l1 79 1 49 498 2v1h-501l-3-1-1-2-1-12-1-554v-84l1-93 1-26z" fill="#F7F7F8" />
            <path transform="translate(205,121)" d="m0 0 3 1v85l-1 1281h-1l-1-62-1-988v-216z" fill="#EAEAEB" />
            <path transform="translate(1704,156)" d="m0 0h1l1 518v110l-1 885h-1l-1-163v-887z" fill="#729DBF" />
            <path transform="translate(1546,896)" d="m0 0h1v151l-1 74-1 11h-1v-169l1-63z" fill="#62A6FA" />
            <path transform="translate(1644,1722)" d="m0 0h57l-1 4-11-1h-121l-50-1v-1z" fill="#7998C3" />
            <path transform="translate(1702)" d="m0 0h4v91h-1l-1-8-1-60-2 2v-18z" fill="#6A9CBD" />
            <path transform="translate(1703,1670)" d="m0 0h1l1 5h1l1 52-5 1 1-2-1-12z" fill="#76A8BD" />
            <path transform="translate(207,1488)" d="m0 0h40l-2 2-22 1h-15z" fill="#EEEEEF" />
            <path transform="translate(1669,1722)" d="m0 0h32l-1 4-11-1-20-2z" fill="#4C7AAE" />
            <path transform="translate(1702)" d="m0 0h4v11l-3 1-1 13h-1v-18z" fill="#7998C3" />
            <path transform="translate(197,1726)" d="m0 0h32v2h-33z" fill="#879DC5" />
            <path transform="translate(1392,1726)" d="m0 0h22l-3 2h-19z" fill="#7E97C5" />
            <path transform="translate(1701,7)" d="m0 0h2l2 5h-2l-1 13h-1z" fill="#5795B6" />
            <path transform="translate(1440,1723)" d="m0 0h23v1l-24 1z" fill="#7E97C5" />
            <path transform="translate(1483,1723)" d="m0 0h24v1h-24z" fill="#7E97C5" />
            <path transform="translate(1428,1726)" d="m0 0h10l-1 2h-9z" fill="#7E97C5" />
            <path transform="translate(1417,1726)" d="m0 0h9l-2 2h-9z" fill="#7E97C5" />
            <path transform="translate(178,1726)" d="m0 0h8l-1 2h-10z" fill="#8A9EC6" />
            <path transform="translate(189,1723)" d="m0 0h11l-1 2h-11z" fill="#7E97C5" />
            <path transform="translate(1442,1726)" d="m0 0h8l-4 2h-7z" fill="#7E97C5" />
            <path transform="translate(166,1726)" d="m0 0h8l-1 2h-7z" fill="#879DC5" />
            <path transform="translate(111,1723)" d="m0 0h14v1h-14z" fill="#7E97C5" />
            <path transform="translate(146,1726)" d="m0 0h9v1l-11 1z" fill="#A4B0C8" />
            <path transform="translate(82,1723)" d="m0 0h13v1h-13z" fill="#7E97C5" />
            <path transform="translate(1453,1726)" d="m0 0h7l-4 2-4-1z" fill="#7E97C5" />
            <path transform="translate(190,1726)" d="m0 0h5l-2 2h-6z" fill="#98A8C7" />
            <path transform="translate(157,1726)" d="m0 0h5l-3 2-4-1z" fill="#7E97C5" />
            <path transform="translate(1546,1726)" d="m0 0h8v1h-8z" fill="#7E97C5" />
            <path transform="translate(1534,1726)" d="m0 0h8v1h-8z" fill="#879CC5" />
            <path transform="translate(1473,1726)" d="m0 0h8v1h-8z" fill="#7E97C5" />
            <path transform="translate(1511,1726)" d="m0 0h7v1h-7z" fill="#7E97C5" />
            <path transform="translate(1465,1726)" d="m0 0h5l-3 2h-2z" fill="#7E97C5" />
            <path transform="translate(201,1723)" d="m0 0 4 1h-4z" fill="#7E97C5" />
            <path transform="translate(67,1723)" d="m0 0h6v1h-6z" fill="#7E97C5" />
            <path transform="translate(137,1726)" d="m0 0 1 2-4-1z" fill="#C6C6CB" />
            <path transform="translate(1508,1723)" d="m0 0h5v1h-5z" fill="#7E97C5" />
            <path transform="translate(1464,1723)" d="m0 0h5v1h-5z" fill="#7E97C5" />
            <path transform="translate(207,1723)" d="m0 0 3 1-4 1z" fill="#7E97C5" />
            <path transform="translate(99,1723)" d="m0 0h5v1h-5z" fill="#7E97C5" />
            <path transform="translate(75,1723)" d="m0 0h5v1h-5z" fill="#7E97C5" />
            <path transform="translate(1526,1726)" d="m0 0 4 1z" fill="#7E97C5" />
            <path transform="translate(106,1723)" d="m0 0 4 1z" fill="#7E97C5" />
            <path transform="translate(1558,1726)" d="m0 0 3 1z" fill="#96A6C7" />
            <path transform="translate(40,1724)" d="m0 0 3 1z" fill="#C6C6CB" />
            <path transform="translate(158,1723)" d="m0 0 3 1z" fill="#7E97C5" />
            <path transform="translate(126,1723)" d="m0 0 3 1z" fill="#7E97C5" />
            <path transform="translate(1581,1726)" d="m0 0 2 1z" fill="#C6C6CB" />
            <path transform="translate(1570,1726)" d="m0 0 2 1z" fill="#C6C6CB" />
            <path transform="translate(1502,1726)" d="m0 0 2 1z" fill="#7E97C5" />
            <path transform="translate(1490,1726)" d="m0 0 2 1z" fill="#7E97C5" />
            <path transform="translate(1487,1726)" d="m0 0 2 1z" fill="#7E97C5" />
            <path transform="translate(140,1726)" d="m0 0 2 1z" fill="#C6C6CB" />
            <path transform="translate(45,1724)" d="m0 0 2 1z" fill="#C6C6CB" />
            <path transform="translate(36,1724)" d="m0 0 2 1z" fill="#C6C6CB" />
            <path transform="translate(170,1723)" d="m0 0 2 1z" fill="#7E97C5" />
            <path transform="translate(167,1723)" d="m0 0 2 1z" fill="#7E97C5" />
            <path transform="translate(146,1723)" d="m0 0 2 1z" fill="#7E97C5" />
            <path transform="translate(135,1723)" d="m0 0 2 1z" fill="#7E97C5" />
            <path transform="translate(1685,4)" d="m0 0 2 1z" fill="#A2AEC8" />
            <path transform="translate(1677,4)" d="m0 0 2 1z" fill="#7E97C5" />
            <path transform="translate(1593,1726)" d="m0 0" fill="#C6C6CB" />
            <path transform="translate(1579,1726)" d="m0 0" fill="#C6C6CB" />
            <path transform="translate(1523,1726)" d="m0 0" fill="#7E97C5" />
            <path transform="translate(1461,1726)" d="m0 0" fill="#7E97C5" />
            <path transform="translate(163,1726)" d="m0 0" fill="#7E97C5" />
            <path transform="translate(1694,1725)" d="m0 0" fill="#7E97C5" />
            <path transform="translate(1691,1725)" d="m0 0" fill="#7E97C5" />
            <path transform="translate(200,1724)" d="m0 0" fill="#7E97C5" />
            <path transform="translate(185,1724)" d="m0 0" fill="#7E97C5" />
            <path transform="translate(50,1724)" d="m0 0" fill="#C6C6CB" />
            <path transform="translate(1515,1723)" d="m0 0" fill="#7E97C5" />
            <path transform="translate(1480,1723)" d="m0 0" fill="#7E97C5" />
            <path transform="translate(1475,1723)" d="m0 0" fill="#7E97C5" />
            <path transform="translate(1470,1723)" d="m0 0" fill="#7E97C5" />
            <path transform="translate(165,1723)" d="m0 0" fill="#7E97C5" />
            <path transform="translate(150,1723)" d="m0 0" fill="#7E97C5" />
            <path transform="translate(138,1723)" d="m0 0" fill="#7E97C5" />
            <path transform="translate(132,1723)" d="m0 0" fill="#7E97C5" />
            <path transform="translate(97,1723)" d="m0 0" fill="#7E97C5" />
            <path transform="translate(59,1723)" d="m0 0" fill="#C6C6CB" />
            <path transform="translate(12,1719)" d="m0 0" fill="#C6C6CB" />
            <path transform="translate(1680,4)" d="m0 0" fill="#7E97C5" />
            <path transform="translate(1693,3)" d="m0 0" fill="#C6C6CB" />
        </svg>

    )

}


export default TreeMapSvg;