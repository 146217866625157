import React from 'react';


const HorizontalBarChartSVG = (props) => {
    return (
        <svg
            width={props.size ? props.size : '2.2rem'}
            height={props.height ? props.height : '2.2rem'}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1728 1728"
            {...props}
            space="preserve">

<path transform="translate(178,112)" d="m0 0h102l15 1 1 2v44l-1 122-1 43-2 64v91l1 28v102l-1 34v102l2 60v44l-2 76v120l1 28v79l-1 32v117l2 47 1 51v80h273l1019 1 14 1 2 5v110l-5 2-262 1h-1159l-1-1-1-7-1-32-1-256v-425l1-722 1-34 1-8z" fill="#2B75FC"/>
<path transform="translate(389,596)" d="m0 0h568l45 1 19 1 10 2 10 5 5 5 3 5 1 4 1 20v119l-1 14-5 10-5 4-18 6-21 2-48 1h-590l-25-1-12-2-18-10-5-4-4-6-1-4-1-16-1-64v-21l1-48 4-8 11-4 17-8 7-2z" fill="#6AA0FC"/>
<path transform="translate(341,1156)" d="m0 0h653l23 1 9 2 6 8 2 9 1 31v103l-1 21-3 8-5 5-8 4-7 2-11 1h-656l-23-1-7-3-10-6-5-11-1-5-1-22-1-56v-34l1-37 1-11 4-4 12-3 6-1z" fill="#6AA0FD"/>
<path transform="translate(364,875)" d="m0 0h445l41 1 12 1 3 3 2 16v131l-1 28-2 11-4 3-4 1-38 1h-457l-36-1-16-3-6-4-3-4-2-16-1-26v-105l1-22 3-9 5-3 5-1z" fill="#6AA0FC"/>
<path transform="translate(364,320)" d="m0 0h158l34 1 15 2 5 4 2 8 1 24v124l-2 16-4 9-5 4-8 2-31 1h-183l-25-1-17-3-4-4-2-8-1-24v-110l1-29 1-9 5-3 12-2 13-1z" fill="#699FFC"/>
<path transform="translate(1719,1695)" d="m0 0h1l2 6 6 1v3h-111l-240-1h-1349l-28-1v-3l5-3 1 2 53 1h1350l279 1 31 1z" fill="#7CA6F1"/>
<path transform="translate(178,112)" d="m0 0h17v1l-16 1v29l-1 166v1289h213v1h-214l-1-1-1-7-1-32-1-256v-425l1-722 1-34 1-8z" fill="#F9F9F9"/>
<path transform="translate(1725,161)" d="m0 0 2 4v753l-1 25h-1l-1-81v-634z" fill="#6AA0FD"/>
<path transform="translate(5,650)" d="m0 0h1v755h-1l-1-188v-440z" fill="#ABB2C1"/>
<path transform="translate(1725,161)" d="m0 0 2 4v753l-1 25h-1v-684l-1-31z" fill="#AAB1C1"/>
<path transform="translate(299,1164)" d="m0 0h1v164l-2-3-1-22-1-56v-34l1-37 1-11z" fill="#327AFD"/>
<path transform="translate(4,63)" d="m0 0 1 2h2l1 15v35l-1 7-2 81h-2v-98zm1 9m0 7 1 2zm0 8m0 2v6h1v-6z" fill="#ABB2C1"/>
<path transform="translate(299,616)" d="m0 0h1v50l-1 97h-1l-1-11-1-64v-21l1-48z" fill="#357CFD"/>
<path transform="translate(364,320)" d="m0 0h158v1l-136 2h-70v-1l13-1z" fill="#EEEEEF"/>
<path transform="translate(299,889)" d="m0 0h1v68l-1 71h-1l-1-11v-105l1-22z" fill="#4084FD"/>
<path transform="translate(5,226)" d="m0 0h1v307h-1z" fill="#ABB2C1"/>
<path transform="translate(299,347)" d="m0 0h1v27l-1 112h-1l-1-11v-110l1-16z" fill="#4386FD"/>
<path transform="translate(52,3)" d="m0 0h4 44l-3 4h-32l-19-1 4-2 2 1z" fill="#ABB2C1"/>
<path transform="translate(297,1281)" d="m0 0h1v6h2v41l-2-3-1-22z" fill="#3C81FD"/>
<path transform="translate(1720,1628)" d="m0 0h1l1 18-1 46h-1z" fill="#9DAECD"/>
<path transform="translate(4,63)" d="m0 0 1 2h2v15l-3 1z" fill="#AEB4C1"/>
<path transform="translate(100,4)" d="m0 0 29 1v1l-28 1-3-2z" fill="#ABB2C1"/>
<path transform="translate(5,542)" d="m0 0h1v26h-1z" fill="#ABB2C1"/>
<path transform="translate(5,614)" d="m0 0h1v25h-1z" fill="#ABB2C1"/>
<path transform="translate(5,574)" d="m0 0h1v20h-1z" fill="#ABB2C1"/>
<path transform="translate(414,4)" d="m0 0h19v1h-19z" fill="#ABB2C1"/>
<path transform="translate(5,596)" d="m0 0h1v16h-1z" fill="#ABB2C1"/>
<path transform="translate(401,4)" d="m0 0h12v1h-12z" fill="#ABB2C1"/>
<path transform="translate(5,640)" d="m0 0h1v9h-1z" fill="#ABB2C1"/>
<path transform="translate(5,57)" d="m0 0h2v4h-2z" fill="#C3C2C7"/>
<path transform="translate(5,534)" d="m0 0h1v7h-1z" fill="#ABB2C1"/>
<path transform="translate(437,4)" d="m0 0h7v1h-7z" fill="#ABB2C1"/>
<path transform="translate(393,4)" d="m0 0h7v1h-7z" fill="#ABB2C1"/>
<path transform="translate(1722,1691)" d="m0 0h1v6h-1z" fill="#ABB2C1"/>
<path transform="translate(132,5)" d="m0 0h6v1h-6z" fill="#ABB2C1"/>
<path transform="translate(5,1409)" d="m0 0" fill="#ABB2C1"/>
<path transform="translate(5,570)" d="m0 0" fill="#ABB2C1"/>
<path transform="translate(5,220)" d="m0 0" fill="#ABB2C1"/>
<path transform="translate(5,51)" d="m0 0" fill="#C3C2C7"/>
<path transform="translate(42,5)" d="m0 0 3 1z" fill="#ABB2C1"/>
<path transform="translate(35,4)" d="m0 0 1 2-2-1z" fill="#ABB2C1"/>
<path transform="translate(5,1406)" d="m0 0" fill="#ABB2C1"/>
<path transform="translate(273,5)" d="m0 0 2 1z" fill="#ABB2C1"/>
<path transform="translate(153,5)" d="m0 0 2 1z" fill="#ABB2C1"/>
<path transform="translate(38,5)" d="m0 0 2 1z" fill="#ABB2C1"/>
<path transform="translate(387,4)" d="m0 0 2 1z" fill="#ABB2C1"/>
<path transform="translate(1722,1699)" d="m0 0" fill="#ABB2C1"/>
<path transform="translate(1720,1626)" d="m0 0" fill="#ABB2C1"/>
<path transform="translate(5,224)" d="m0 0" fill="#ABB2C1"/>
<path transform="translate(5,218)" d="m0 0" fill="#ABB2C1"/>
<path transform="translate(3,210)" d="m0 0" fill="#ABB2C1"/>
<path transform="translate(3,207)" d="m0 0" fill="#ABB2C1"/>
<path transform="translate(3,205)" d="m0 0" fill="#ABB2C1"/>
<path transform="translate(6,55)" d="m0 0" fill="#C3C2C7"/>
<path transform="translate(299,5)" d="m0 0" fill="#ABB2C1"/>
<path transform="translate(297,5)" d="m0 0" fill="#ABB2C1"/>
<path transform="translate(265,5)" d="m0 0" fill="#ABB2C1"/>
<path transform="translate(147,5)" d="m0 0" fill="#ABB2C1"/>
<path transform="translate(145,5)" d="m0 0" fill="#ABB2C1"/>
<path transform="translate(142,5)" d="m0 0" fill="#ABB2C1"/>
<path transform="translate(139,5)" d="m0 0" fill="#ABB2C1"/>
<path transform="translate(385,4)" d="m0 0" fill="#ABB2C1"/>
<path transform="translate(379,4)" d="m0 0" fill="#ABB2C1"/>
<path transform="translate(357,4)" d="m0 0" fill="#ABB2C1"/>
        </svg>
    )

}


export default HorizontalBarChartSVG;