
export const DEPARTMENTS = {
    "Aerospace Medicine": 1,
    "Anaesthesia": 2,
    "Bariatric Surgery": 3,
    "Cardiology - Interventional": 4,
    "Cardiology - Non Interventional": 5,
    "Cardiothoracic And Vascular Surgery": 6,
    "Centre For Spinal Diseases": 7,
    "Clinical Haematology And BMT": 8,
    "Corneal Transplant": 9,
    "Critical Care Medicine": 10,
    "Dermatology And Cosmetology": 11,
    "Ear Nose Throat Head And Neck Surgery": 12,
    "Emergency Medicine": 13,
    "Endocrinology": 14,
    "General Surgery": 15,
    "Infectious Diseases": 16,
    "Internal Medicine": 17,
    "In-Vitro Fertilisation (IVF)": 18,
    "Laboratory Medicine": 19,
    "Liver Transplant & Hepatic Surgery": 20,
    "Maxillofacial Surgery": 21,
    "Medical Gastroenterology": 22,
    "Medical Oncology & Clinical Hematology": 23,
    "Medical Oncology": 24,
    "Minimally Invasive Gynecology": 25,
    "Neonatology": 26,
    "Nephrology": 27,
    "Neuro Modulation": 28,
    "Nutrition & Dietetics": 29,
    "Neurology": 30,
    "Neurosurgery": 31,
    "Obstetrics And Gynecology": 32,
    "Ophthalmology": 33,
    "Orthopedics & Joint Replacement": 34,
    "Pain Management": 35,
    "Pediatric Surgery": 36,
    "Physiotherapy": 37,
    "Plastic Surgery": 38,
    "Psychiatry": 39,
    "Pulmonology": 40,
    "Renal Transplant": 41,
    "Reproductive Medicine & IVF": 42,
    "Rheumatology": 43,
    "Robotic Surgery": 44,
    "Surgical Gastroenterology": 45,
    "Surgical Oncology": 46,
    "Urology": 47,
    "Vascular and endovascular surgery": 48,
}

