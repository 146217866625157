import React from 'react';
import { Card, ImageWrapper, ConetentSection } from './action.card.styled';
import CardAction from './card.action';
import { convert_date_to_dd_mm_yyyy_v1, } from '../../utils';


const ActionCard = (props) => {

    const {
        titleFontSize, cardStyle, iconStyle, deleteAction,
        copyAction, editAction, hoverHideTitle, overlayStyle,
        viewAction, title,addTitle, date, Image, data,
        action, titleCenter, isOverlay, parentClick, numberOfAction, enableAction,addTitleColor

    } = props;

    return (
        <Card
            bgColor={action ? '#fff'  : 'linear-gradient(to right,#92beff, #337dff)'}   
            action={action}
            style={cardStyle ? cardStyle : undefined}
            onClick={(event) => {
                if (parentClick) {
                    parentClick(event);
                }
                event.stopPropagation()
                viewAction && viewAction(data);
            }}>

            <ImageWrapper >
                {Image ? Image : null}
            </ImageWrapper>

                <ConetentSection hide={hoverHideTitle} className={hoverHideTitle ? "hide_title_box" : undefined}>
                        <>
                        {title && (
                            <h3 style={{ textAlign: titleCenter || 'center', fontSize: titleFontSize, color: addTitleColor }} className="subTitle">{title ? title : ''}</h3>
                        )}
                        {date && <div className="date">
                            <span>Created : {date ? convert_date_to_dd_mm_yyyy_v1(date) : null}</span>
                        </div>}
    
                        </>

                </ConetentSection>
        
            {/* {isOverlay && <div className="overlay_hover" onClick={() => {
                viewAction(data);
            }} style={overlayStyle}>{title ? title : undefined}</div>} */}

       
            {action && (
                <div className="hover_action">
                    <CardAction
                        DeleteAction={deleteAction}
                        data={data}
                        DuplicateAction={copyAction}
                        ViewAction={viewAction}
                        EditAction={editAction}
                        numberOfAction={numberOfAction}
                        enableAction={enableAction}
                    />
                </div>
            )} 

        </Card>
    )
}

export default ActionCard;
