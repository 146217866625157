import * as actions from '../action-types/multiple.reports';

const initialState = {
    type: actions.MULTIPLE_REPORT_INIT,
    recordsCount: 0,
    reportData: undefined
};


const reportReducer = (state, action) => {
	if (!state || typeof action.type === 'undefined') {
		return initialState;
    }

    // if (action && action.data && typeof action.data === 'object') {
    //     action.data.key = new Date();
    // }


    switch (action.type) {
        case actions.MULTIPLE_REPORT_SUCCESS:
            return {
                ...state,
                type: action.type,
                reportData: action.data,
            };

        case actions.CLEAR_ALL_DATA:
            return {
                type: action.type,
                reportData: undefined,
                recordsCount: undefined
            };

        // important: we should always give a default, otherwise React gives a cheap warning and it is very annoying
        default:
			return {
                type: state.type,
                recordsCount: state.recordsCount,
                reportData: state.reportData
            };
        }
};

export default reportReducer;